
 
  export const roundDecimalToInt = value => {
    return Math.round(value);
  };
  
  export const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  export const generateOptionsArray = (qid, optionsAnswerIds,optionsText) => {
    
    let optionArray = [];
    for(let answerIndex = 0; answerIndex < optionsAnswerIds.length; answerIndex++){
      let objOption =  {qid, aid: optionsAnswerIds[answerIndex], text: optionsText[answerIndex]}
      optionArray.push(objOption);
    }
    return optionArray;
  };
  
  export const GetQuestionsWithOptions = async questions => {
    try {
      let questionsWithOptions = [];
      for (
        let questionIndex = 0;
        questionIndex < questions.length;
        questionIndex++
      ) {
        let quesObj = questions[questionIndex];
        // let optionsAnswerIds = questions[questionIndex].aid.split('#');
        // let optionsText = questions[questionIndex].text.split('#');

        let optionsAnswerIdsWithDuplicate = questions[questionIndex].aid.split('#');

        let optionsAnswerIds = [];
        for(let optIdsIndex = 0; optIdsIndex < optionsAnswerIdsWithDuplicate.length; optIdsIndex++){
          if (!optionsAnswerIds.includes(optionsAnswerIdsWithDuplicate[optIdsIndex])) {
            optionsAnswerIds.push(optionsAnswerIdsWithDuplicate[optIdsIndex]);
        }
        }

        let optionsTextWithDuplicate = questions[questionIndex].text.split('#');
        let optionsText = [];

        for(let optTextIndex = 0; optTextIndex < optionsTextWithDuplicate.length; optTextIndex++){
          if (!optionsAnswerIds.includes(optionsTextWithDuplicate[optTextIndex])) {
            optionsText.push(optionsTextWithDuplicate[optTextIndex]);
        }
        }


        let optionsArray = generateOptionsArray(
          questions[questionIndex].qid,
          optionsAnswerIds,
          optionsText,
        );
        quesObj['options'] = optionsArray;
        questionsWithOptions.push(quesObj);
      }
      // console.log(
      //   `questionsWithOptions ${JSON.stringify(questionsWithOptions, null, 2)}`,
      // );
      return questionsWithOptions;
    } catch (error) {
      console.log(error);
    }
  };
  
  /*  
  export const generateOptionsArray = (qid, questions) => {
    let questionsFilterByQid = questions.filter(item => item.qid === qid);
    let optionArray = questionsFilterByQid.map(item => {
      return {qid: item.qid, aid: item.aid, text: item.text};
    });
    return optionArray;
  };
  
  export const GetQuestionsWithOptions = async questions => {
    var QuestionsIds = questions.map(item => {
      return item.qid;
    });
    var UniqueQuestionsIds = [...new Set(QuestionsIds)];
    try {
      let questionsWithOptions = [];
      for (
        let questionIndex = 0;
        questionIndex < UniqueQuestionsIds.length;
        questionIndex++
      ) {
        let quesObj = questions.find(
          item => item.qid === UniqueQuestionsIds[questionIndex],
        );
  
        let optionsArray = generateOptionsArray(
          UniqueQuestionsIds[questionIndex],
          questions,
        );
        quesObj['options'] = optionsArray;
        delete quesObj.aid;
        questionsWithOptions.push(quesObj);
      }
      // console.log(
      //   `questionsWithOptions ${JSON.stringify(questionsWithOptions, null, 2)}`,
      // );
      return questionsWithOptions;
    } catch (error) {
      console.log(error);
    }
  };
  */