import React from "react";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import { isMobile } from "react-device-detect";
import { AppUrlIOS, AppUrlAndroid } from "../constants/Globals/Globals";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {Link} from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer disable-copy-element-text">
      {/* <footer className="py-3 bg-dark fixed-bottom"> */}
      <footer className="py-2 bg-info fixed-bottom">
        <CookieConsent
          location="bottom"
          buttonText="Okay"
          declineButtonText="Decline"
          style={{ background: "black" /*"#242424"*/, color: "#FFF" }}
          buttonStyle={{
            color: "#000",
            backgroundColor: "#fff",
            fontSize: "14px",
          }}
          declineButtonStyle={{ fontSize: "14px" }}
          expires={150}
        >
          We use cookies to personalize content and ads, and to analyze our traffic. See our <Link to="/privacy">privacy policy</Link> for more.
        </CookieConsent>
        <div
          className="justify-content-around"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {isMobile ? null : (
            <AppStoreButton
              url={AppUrlIOS}
              theme={"dark"}
              className={"custom-style"}
            />
          )}
          <h6
            className="text-black"
            style={{ marginTop: "15px", marginBottom: "10px" }}
          >
            Copyright &copy; dmvpermit-test.com 2024
          </h6>
          {isMobile ? null : (
            <GooglePlayButton
              url={AppUrlAndroid}
              theme={"dark"}
              className={"custom-style"}
              width={GooglePlayButton}
            />
          )}
        </div>
      </footer>
    </div>
  );
};

export default Footer;

/*
const NavBar = () => {
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            Practice Test
          </NavLink>
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            
          </div>
        </div>
      </nav>
    </div>
  );
}
*/

/*
<Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav>
                    <NavLink  eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink  eventKey="2" as={Link} to="/about">About</NavLink>
                    <NavLink  eventKey="3" as={Link} to="/contact">Contact</NavLink>
                </Nav>
            </Navbar.Collapse>     
        </Navbar> 
        */

{
  /*
        We use cookies to personalize content and ads, and to analyze our traffic. See our <a href="/privacy">privacy policy</a> for more.

*/
}
