import React, { useEffect, useState } from "react";
import { GetQuestionsWithOptions } from "../../../src/utils/Helper";
import { getQuestions, getQuestionsOfMockTest } from "../../helper/DBHelper";
import { Button } from "react-bootstrap";
import "./PracticeTest.css";
import { images } from "../../constants/images";
import STATES from "../../json-data/state";
import STATESAPPLinks from "../../json-data/stateAppLinks";
import StoreLinks from "../StoreLinks";
import ModalWithProceed from "../ModalWithProceed";
import PracticeScore from "./PracticeScore";
import Fade from "react-reveal/Fade";

function PracticeTest(props) {
  /*
  const tmp = {
    stateId: 1,
    vehicleCategory: { id: 1 },
    testType: {
      id: 1002,
      colors: "#FF8552",
      categoryName: "Practice Tests",
      endorsementCode: "",
      categoryType: 10,
      vehicleCategoryId: 1,
      imageIconName: "practiceIcon",
    },
    test: {
      stateId: 1,
      testId: 1,
      type: 10,
      title: "Practice Test 1",
      numberOfQuestions: 40,
      allowedMistakes: 8,
      endorsementCode: "",
    },
  };
*/
  const [test, setTest] = useState({});
  const [questions, setQuestions] = useState([]);
  const [questionsIndexArray, setQuestionsIndexArray] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [wrongs, setWrongs] = useState(0);
  const [corrects, setCorrects] = useState(0);
  // const [qindex, setQIndex] = useState(1);
  // const questionsNumberIndexView = useRef();
  const [completedTest, setCompletedTest] = useState({});
  const [isLastCompletedTest, setIsLastCompletedTest] = useState(false);
  const [isModalShow, setisModalShow] = useState(false);

  const handleClose = () => {
    // console.log(`Completed Test`);
    setisModalShow(false);
  };

  const handleProceed = () => {
    setCompletedTest({
      date_id: new Date().getTime(),
      test: test,
      questions: questions,
      answers: answers,
      corrects: corrects,
      wrongs: wrongs,
    });
    setisModalShow(false);
    setIsLastCompletedTest(true);
    // console.log(`Completed Test`);
  };

  const onRestartTest = () => {
    setisModalShow(false);
    setIsLastCompletedTest(false);
    setCompletedTest({});
    setAnswers([]);
    setIndex(0);
    setCorrects(0);
    setWrongs(0);
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  };

  useEffect(() => {
    //console.log(`Test ID: ${JSON.stringify(props.test.testId)}`);
    setTest(props.test);
    if (props.testType.id === 1001 || props.testType.id === 2001) {
      getQuestionsOfMockTest(props.test, props.testType.id).then(
        (questions) => {
          GetQuestionsWithOptions(questions).then((questionsWithOptions) => {
            setQuestions(questionsWithOptions);
            const questionsIndex = [];
            for (let qINum = 0; qINum < questionsWithOptions.length; qINum++) {
              const objQuestion = {
                indexNumber: qINum + 1,
                qid: questionsWithOptions[qINum].qid,
                correctAnswer: questionsWithOptions[qINum].correctAnswer,
              };
              questionsIndex.push(objQuestion);
            }
            setQuestionsIndexArray(questionsIndex);
            initialize();
          });
        }
      );
    } else {
      getQuestions(props.test).then((questions) => {
        GetQuestionsWithOptions(questions).then((questionsWithOptions) => {
          setQuestions(questionsWithOptions);
          const questionsIndex = [];
          for (let qINum = 0; qINum < questionsWithOptions.length; qINum++) {
            const objQuestion = {
              indexNumber: qINum + 1,
              qid: questionsWithOptions[qINum].qid,
              correctAnswer: questionsWithOptions[qINum].correctAnswer,
            };
            questionsIndex.push(objQuestion);
          }
          setQuestionsIndexArray(questionsIndex);
          initialize();
        });
      });
    }
  }, []);

  const initialize = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(setisLoading(false));
      }, 1000);
    });
    // setIsLoading(false)
  };

  const renderQuestion = ({ item }) => {
    let backgroundForIndexItem = "lightgray";
    let txtColor = "black";
    let isMockTest = props.testType.id === 1001 || props.testType.id === 2001;
    answers.find((ansItem) => {
      if (
        ansItem.qid === item.qid &&
        ansItem.uaid === item.correctAnswer.toString()
      ) {
        backgroundForIndexItem = isMockTest ? "#A9A9A9" : "#21BA45";
        txtColor = "white";
      } else if (
        ansItem.qid === item.qid &&
        ansItem.uaid !== item.correctAnswer.toString()
      ) {
        backgroundForIndexItem = isMockTest ? "#A9A9A9" : "#DA2828";
        txtColor = "white";
      }
    });

    return (
      <button
        key={item.qid}
        onClick={() => {
          setIndex(item.indexNumber - 1);
          //showInterstitialOrPurchaseModal()
        }}
        style={{
          backgroundColor: backgroundForIndexItem,
          color: txtColor,
          borderColor: index === item.indexNumber - 1 ? "black" : "white",
        }}
      >
        <div className="item">{item.indexNumber}</div>
      </button>
    );
  };

  const showOption = (option) => {
    if (option.text === null) {
      return;
    }
    let cssClass = "";
    let state = "none";
    let userAnswer = answers.find((item) => item.qid === questions[index].qid);
    let isDotCircle = false;
    if (userAnswer?.uaid === option.aid) {
      isDotCircle = true;
    }
    let isExplanationDisplay = false;
    if (
      (props.testType.id === 1001 || props.testType.id === 2001) &&
      isDotCircle
    ) {
      if (userAnswer !== undefined) {
        cssClass = "mock";
      }
    } else if (
      (props.testType.id === 1001 || props.testType.id === 2001) &&
      !isDotCircle
    ) {
    } else {
      isExplanationDisplay = true;
      if (userAnswer !== undefined) {
        if (questions[index].correctAnswer.toString() === option.aid) {
          cssClass = "correct";
          state = "correct";
          // } else if (answers[index] === option.aid) {
        } else if (userAnswer.uaid === option.aid) {
          cssClass = "wrong";
          state = "wrong";
        }
      }
    }
    return (
      <ul style={{ listStyleType: "none", marginBottom: "5px" }}>
        {/* <label>
          <input type="radio" name="optionRadio" value={option.aid} onSelect={()=>updateAnswers(option.aid)}/>
          {option.text}
        </label> */}
        <li key={option.aid} className="answer">
          <button
            onClick={() => updateAnswers(option.aid)}
            className={cssClass}
            /*disabled={userAnswer ? true : false}*/
            style={{ textAlign: "left", fontSize: 16 }}
          >
            {option.text}
          </button>
          {state === "correct" &&
          option.explanation !== null &&
          isExplanationDisplay ? (
            <Fade>
              <div style={{ margin: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Explanation:</div>
                <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                  {option.explanation}
                </div>
              </div>
            </Fade>
          ) : null}
        </li>
      </ul>
    );
  };

  const updateAnswers = (answer) => {
    //console.log(`Answer : ${answer}`);
    // let currentAnswers = [...answers];

    // if (currentAnswers[index] != undefined) {
    //   return;
    // }
    // if (questions[index].correctAnswer === answer) {
    //   updateStatus(questions[index], 'correct');
    //   setCorrects(corrects + 1);
    // } else {
    //   updateStatus(questions[index], 'wrong');
    //   setWrongs(wrongs + 1);
    // }
    // currentAnswers[index] = answer;
    // setAnswers(currentAnswers);

    let currentAnswers = [...answers];

    if (currentAnswers.find((item) => item.qid === questions[index].qid)) {
      return;
    }

    if (questions[index].correctAnswer.toString() === answer) {
      // updateStatus(questions[index], 'correct');
      setCorrects(corrects + 1);
    } else {
      // updateStatus(questions[index], 'wrong');
      setWrongs(wrongs + 1);
    }
    // currentAnswers[index] = answer;
    currentAnswers.push({ qid: questions[index].qid, uaid: answer });
    setAnswers(currentAnswers);
  };

  const showNext = () => {
    if (index === questions.length - 1) {
      // if (!isLastCompletedTest) {
      // onSubmit();
      // }
      return;
    }
    setIndex(index + 1);
    // questionView.current.scrollTo({
    //   x: 0,
    //   y: 0,
    //   animated: false,
    // });

    // questionsNumberIndexView.current.scrollTo({
    //   x: index * scale(30),
    //   y: 0,
    //   animated: true,
    // });
  };

  const showPrevious = () => {
    if (index === 0) {
      return;
    }
    setIndex(index - 1);
    // questionView.current.scrollTo({
    //   x: 0,
    //   y: 0,
    //   animated: false,
    // });
    // let movetoIndex = 1;
    // if (index >= 0 && index < 6) {
    //   movetoIndex = 0;
    // } else if (index > questions.length - 5) {
    //   movetoIndex = (index - 1) * scale(30);
    //   // movetoIndex = index * scale(30);
    // } else {
    //   movetoIndex = (index - 1) * scale(30);
    // }
    // questionsNumberIndexView.current.scrollTo({
    //   x: movetoIndex,
    //   y: 0,
    //   animated: true,
    // });
  };
  //console.log(`Practice Test : ${JSON.stringify(props)}`);
  return (
    <div className="row disable-copy-element-text">
      <div className="column " style={{ width: "20%" }}>
      {!isLoading ? (
        <>
        <h3>{STATES[props.stateId - 1].shortName} {STATES[props.stateId - 1].governmentAgency} Test</h3>
        {!isLastCompletedTest? 
        <div>
        <h4>Question List</h4>
        <div className="quesion-index-wrapper ">
          {questionsIndexArray.map((item) => {
            return renderQuestion({ item });
          })}
        </div>
        </div>
        : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "10px",
          }}
        >
          <StoreLinks stateData={STATESAPPLinks[props.stateId - 1]} />
        </div>
        </> ) : null }
      </div>
      <div
        className="column"
        style={{ width: "60%", border: "2px solid darkgrey" }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "10px",
          }}
        >
          <StoreLinks stateData={STATESAPPLinks[props.stateId - 1]} />
        </div> */}
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "10px" }}
        >
          <h4 style={{ marginLeft: "15px" }}>
            {STATES[props.stateId - 1].name}{" "}
          </h4>

          <h3>
            {props.testType.id === 1001 || props.testType.id === 2001
              ? props.test.title
              : props.test.title}
          </h3>

          <h3 style={{ marginRight: "15px" }}>{props.vehicleCategory.name}</h3>
        </div>
        <hr
          style={{
            color: "darkgrey",
            backgroundColor: "darkgrey",
            height: "1.5px",
            marginTop: "0px" 
          }}
        />
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            {/* <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div> */}
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            <ModalWithProceed
              titleText={"Submit Test"}
              bodyText={"Would you like to submit test right now?"}
              show={isModalShow}
              isLoading={false}
              close={handleClose}
              proceed={handleProceed}
            />
            {isLastCompletedTest ? (
              <PracticeScore
                restartTest={onRestartTest}
                completedTestData={completedTest}
              />
            ) : (
              <div>
                {/* <div className="quesion-index-wrapper">
                  {questionsIndexArray.map((item) => {
                    return renderQuestion({ item });
                  })}
                </div> */}
                {/* <hr
                  style={{
                    color: "darkgrey",
                    backgroundColor: "darkgrey",
                    height: "1.5px",
                  }}
                /> */}
                <div
                  style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginRight: "20px",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <h5>Question : {index + 1}</h5>
                    <div>
                      {props.testType.id !== 1001 &&
                      props.testType.id !== 2001 ? (
                        <>
                          {/* <Button
                  className="previous-next-question btn-sm"
                  style={{backgroundColor:'#21BA45'}}
                >
                  {corrects}
                </Button> */}

                          <button
                            type="button"
                            className="btn btn-sucess"
                            style={{
                              backgroundColor: "#21BA45",
                              marginRight: "2px",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            {corrects}
                          </button>
                          {/* <Button
                  className="previous-next-question btn-sm"
                  
                >
                  {wrongs}
                </Button> */}
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{
                              backgroundColor: "#DA2828",
                              borderRadius: "5px",
                            }}
                          >
                            {wrongs}
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>

                  {questions[index].imageName != "" &&
                  questions[index].imageName != null ? (
                    <div className="text-center">
                      {/* <img
                      src={images[questions[index].imageName]}
                      alt=""
                      height={200}
                      width={'70%'}
                    /> */}
                      {/* <Image src={images[questions[index].imageName]} rounded /> */}
                      <img
                        src={images[questions[index].imageName]}
                        className="img-fluid"
                        alt=""
                        width={"70%"}
                      ></img>
                    </div>
                  ) : null}

                  <div
                    style={{
                      marginLeft: "0px",
                      marginTop: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {questions[index].description}
                  </div>
                </div>

                <div style={{ marginTop: "10px", marginRight: "20px" }}>
                  {questions[index].options.map((optItem) => {
                    return showOption({
                      aid: optItem.aid,
                      text: optItem.text,
                      explanation: questions[index].explanation,
                      optColor: "#fff",
                    });
                  })}
                </div>
                <hr
                  style={{
                    color: "darkgrey",
                    backgroundColor: "darkgrey",
                    height: "1.5px",
                  }}
                />
                {/* <div className="d-flex justify-content-between" style={{ textAlign: "center", width:"90%", alignContent:'center'}}>
              <button className="sumbit-test" style={{width:"15%", marginLeft:"15%"}}>
                Previous
              </button>
              
              <button className="sumbit-test" style={{  width:"30%", marginLeft:"10%", marginRight:"10%"}}>
                Submit
              </button>
             
              <button className="sumbit-test" style={{width:"15%"}}>
                Next
              </button>
            </div> */}
                <div
                  className="d-flex justify-content-between"
                  style={{
                    marginTop: "10px",
                    marginBottom: "15px",
                    width: "100%",
                    marginLeft: "0%",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className=" btn-lg btn-primary"
                    style={{ width: "120px" }}
                    onClick={() => showPrevious()}
                  >
                    Previous
                  </Button>
                  <Button
                    className=" btn-lg"
                    style={{ width: "130px" }}
                    onClick={() => setisModalShow(true)}
                  >
                    Submit
                  </Button>

                  <Button
                    className=" btn-lg btn-primary"
                    style={{ width: "120px" }}
                    onClick={() => showNext()}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        {/* {isLastCompletedTest ? <PracticeScore completedTestData={completedTest}/>: null} */}
      </div>
      <div className="column" style={{ width: "20%" }}></div>
    </div>
  );
}

export default PracticeTest;

{
  /* <StroeLinksWithQRCodes stateData={STATESAPPLinks[props.stateId - 1]}/> */
}
