import React, { useState, useRef } from "react";
import "./CategoryList.css";
import { images } from "../../constants/images";
import TestTypeList from "./TestTypeList";
import TestList from "./TestList";
import Lottie from "lottie-react";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";

import { Button } from "react-bootstrap";
import StroeLinksWithQRCodes from "../StroeLinksWithQRCodes";
import STATESAPPLinks from "../../json-data/stateAppLinks";
import STATES from "../../json-data/state";

export const categoryList = [
  {
    id: 1,
    name: "Car",
    imageName: "car",
  },
  {
    id: 2,
    name: "Moto",
    imageName: "motorbike",
  },
  {
    id: 3,
    name: "CDL",
    imageName: "truck",
  },
];

function CategoryList({ onSelectCategory, stateId }) {
  const [category, setCategory] = useState();
  const [testType, setTestType] = useState();
  const [test, setTest] = useState();
  const [categoryKey, setCategoryKey] = useState("");
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const [isShowDownloadAppPage, setIsShowDownloadAppPage] = useState(false);

  //console.log(`---Category List ${JSON.stringify(props)}`);

  const targetTestTypeDivRef = useRef(null);
  const targetTestDivRef = useRef(null);

  //   useEffect(() => {
  //     if(category && test){
  //     test.current.focus();
  //     }else if(category) {
  //       targetTestTypeDivRef.current.focus();
  //     }
  // }, []);

  const onCickCategory = ({ category }) => {
    setCategoryKey(category.id);
    setIsTypeLoading(true);
    setCategory(undefined);
    setTestType(undefined);
    setTest(undefined);
    setTimeout(() => {
      setCategory(category);
      setIsTypeLoading(false);
      setTimeout(() => {
        //targetTestTypeDivRef.current.focus();
        if (
          targetTestTypeDivRef &&
          targetTestTypeDivRef?.current &&
          !isMobile
        ) {
          window.scrollTo({
            behavior: "smooth",
            top: targetTestTypeDivRef.current.offsetTop,
          });
        }
      }, 1000);
    }, 1000);
  };
  const onSetTestType = (props) => {
    setIsTestLoading(true);
    setTestType(undefined);
    setTest(undefined);
    setTimeout(() => {
      setTestType(props.testType);
      setIsTestLoading(false);
      setTimeout(() => {
        // targetTestDivRef.current.focus();
        if (targetTestDivRef && targetTestDivRef?.current && !isMobile) {
          window.scrollTo({
            behavior: "smooth",
            top: targetTestDivRef.current.offsetTop,
          });
        }
      }, 10);
    }, 1000);
  };
  const onSetTest = (props) => {
    //console.log(`testtt ${props.test}`);
    setTest(props.test);
    //callPracticeTest(props.test)
  };
  const callPracticeTest = () => {
    // console.log(`Test Titles : ${test.title}`);
    if (
      test.title.includes("Exam Simulator") ||
      test.title === "Practice Test 1" ||
      test.title === "Signs & Situations" ||
      test.title === "Fines & Limits" ||
      test.title === "General Knowledge Test 1" ||
      test.title === "Moto Practice Test 1" ||
      test.title === "Moto Practice Test 4"
    ) {
      // console.log(`practicetest Test Title: ${test.title}`);
      onSelectCategory({
        currentPage: "practicetest",
        category,
        testType,
        test,
      });
    } else {
      //  console.log(`downloadapp Test Title: ${test.title}`);
      // onSelectCategory({
      //   currentPage: "downloadapp",
      //   category,
      //   testType,
      //   test,
      // });
      setIsShowDownloadAppPage(true);
    }
  };

  const onClickBack = () => {
    // console.log(`Back cat ${category}`);
    setTest(undefined);
    setIsShowDownloadAppPage(false);
    setTimeout(() => {
      // targetTestDivRef.current.focus();
      if (targetTestDivRef && targetTestDivRef?.current && !isMobile) {
        window.scrollTo({
          behavior: "smooth",
          top: targetTestDivRef.current.offsetTop,
        });
      }
    }, 1000);
  };

  return isShowDownloadAppPage ? (
    <div className="row disable-copy-element-text">
      <div className="column" style={{ width: "10%" }}></div>
      <div
        className="column"
        style={{ width: "80%", border: "0px solid darkgrey" }}
      >
        <div>
          <StroeLinksWithQRCodes stateData={STATESAPPLinks[stateId - 1]} />
          <div
            className="d-flex justify-content-center"
            style={{
              marginTop: "10px",
              marginBottom: "15px",
              width: "100%",
              marginLeft: "0%",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className=" btn-lg btn-primary"
              style={{ width: "150px" }}
              onClick={() => onClickBack()}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      <div className="column" style={{ width: "10%" }}></div>
    </div>
  ) : (
    <div className="row disable-copy-element-text">
      <div className="column" style={{ width: "10%" }}></div>
      <div
        className="column"
        style={{ width: "80%", border: "0px solid darkgrey" }}
      >
        <div
          className="d-flex flex-column justify-content-center"
          style={{ marginBottom: "20px" }}
        >
          <div className="py-2">
          <div className=" text-center align-items-center">
            <h2>
              {STATESAPPLinks[stateId - 1].name}{" "}
              {STATES[stateId - 1].governmentAgency} PRACTICE TEST 
            </h2>
          </div>
            <h6>
              To drive on {STATESAPPLinks[stateId - 1].name}'s streets and
              roads, you must have a valid driver's license or learner's permit.{" "}
              {STATESAPPLinks[stateId - 1].name}{" "}
              {STATES[stateId - 1].governmentAgency} Practice Test is an
              all-inclusive learning resource that was developed to assist
              individuals in preparing for the{" "}
              {STATESAPPLinks[stateId - 1].name}{" "}
              {STATES[stateId - 1].governmentAgency} certification examination.
              Users have the opportunity to participate in a learning experience
              in preparation for the test. Uses can prepare for their car,
              motorcycle, or commercial driver's license (CDL) test.
            </h6>
            <h6>
              {STATES[stateId - 1].shortName}{" "}
              {STATES[stateId - 1].governmentAgency} test includes questions on
              Road Signs, Fine & Limits, General Knowledge, Hazmat, Passenger
              Vehicles, Combination Vehicle, etc. based on vehicle category.
            </h6>
          </div>
          <div className=" text-center align-items-center">
           
          </div>

          <div>
            <h3>Select Vehicle Category</h3>
          </div>
          {/* <div className="d-flex justify-content-center gap-5" style={{marginBottom:'20px' }}> */}
          {/* <div
            className="category-type-scrolling-list-flexbox justify-content-center"
            style={{ marginBottom: "20px" }}
          > */}
          <div
            className="category-type-scrolling-list-flexbox"
            style={{ marginBottom: "20px", marginLeft: "40px" }}
          >
            {categoryList.map((category) => {
              return (
                <Fade left>
                  <button
                    key={category.id}
                    onClick={() => onCickCategory({ category })}
                    className="card"
                    style={{
                      backgroundColor:
                        categoryKey === category.id ? "lightblue" : "",
                    }}
                  >
                    <div style={{ justifyContent: "center" }}>
                      <div>
                        {/* <img
                        width={250}
                        height={175}
                        src={images[`${category.imageName}.png`]}
                        //src={images[`car.png`]}
                        alt={category.imageName}
                      />{" "} */}
                        <img
                          width={185}
                          height={145}
                          src={images[`${category.imageName}.png`]}
                          //src={images[`car.png`]}
                          alt={category.imageName}
                        />{" "}
                      </div>
                      <div>
                        <h4>{category.name}</h4>
                      </div>
                    </div>
                  </button>
                </Fade>
              );
            })}
          </div>

          {category ? (
            <div ref={targetTestTypeDivRef} className="target-div">
              <TestTypeList
                onSelectTestType={onSetTestType}
                stateId={stateId}
                vehicleCategory={category}
                selectedTestType={testType}
              />
            </div>
          ) : null}

          {/* {isTypeLoading ? (
            
              <div className="spinner-border text-primary text-center" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
           
          ) : null} */}

          {isTypeLoading ? (
            <Lottie
              style={{ height: "10rem", width: "100%", marginTop: 0 }}
              animationData={require("../../lottie/loading-dots.json")}
              loop={true}
            />
          ) : null}

          {testType ? (
            <div
              ref={targetTestDivRef}
              className="target-div"
              style={{ marginBottom: "20px" }}
            >
              <TestList
                onSelectTest={onSetTest}
                stateId={stateId}
                vehicleCategory={category}
                testType={testType}
              />
            </div>
          ) : null}

          {/* {isTestLoading ? (
            
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            
          ) : null} */}
          {isTestLoading ? (
            <Lottie
              style={{ height: "10rem", width: "100%", marginTop: 0 }}
              animationData={require("../../lottie/loading-dots.json")}
              loop={true}
            />
          ) : null}
          {test ? callPracticeTest() : null}
        </div>
      </div>
      <div
        className="column"
        style={{ width: "10%", marginBottom: "20px" }}
      ></div>
    </div>
  );
}

export default CategoryList;

/*
import React from 'react';
import './CategoryList.css';
import {images} from '../../constants/images';
import car from '../../assets/images/car.png'
export const categoryList = [
    {
      id: 1,
      name: 'car',
      imageName: 'car',
    },
    {
      id: 2,
      name: 'moto',
      imageName: 'motorbike',
    },
    {
      id: 3,
      name: 'cdl',
      imageName: 'truck',
    },
  ];

function CategoryList({ onSelectCategory }) {
   
   const onCickCategory = ({category}) => {
    onSelectCategory({currentPage:'testtype', category})
   }
  return (
    <div className="d-flex flex-column justify-content-center">
    <div>
    <h1>Category</h1>
    </div>
    <div className="d-flex justify-content-center gap-5">
      {categoryList.map((category) => {
        return(
        <button key={category.id} onClick={() => onCickCategory({category})}className="category-list-item">
          <div>
            <div><h1>{category.name}</h1></div>
            <div><img width={100} src={images[`${category.imageName}.png`]} alt={category.imageName} /> </div>
          </div>
        </button>
      )})}
    </div>
    </div>
  );
}

export default CategoryList;
*/

/*
<div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}

          */
