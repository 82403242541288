import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalWithProceed = ({ titleText, bodyText, show, isLoading, close, proceed }) => {
  return (
    <Modal show={show} onHide={close}>
      {/* <Modal.Header closeButton> */}
      <Modal.Header >
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close} disabled={isLoading}>
          No
        </Button>
        <Button variant="primary" onClick={proceed}>
          {isLoading ? "Saving..." : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWithProceed;
