const QUESTIONS = [
  {"testId":2,"qid":41,"description":"When you are backing up a car, you shouldn't go faster than:","explanation":"When backing up, the State of Alaska Driver Manual suggests not exceeding 5 mph.","imageName":"","correctAnswer":2,"isBookmarked":0,"aid":"1#2#3#4","text":"3 mph#5 mph#7 mph#10 mph"},
{"testId":2,"qid":42,"description":"To prevent being involved in a rear-end accident, you should follow the:","explanation":"The State of Alaska Driver Manual advises a four-second following distance to prevent a rear-end accident with the car ahead of you. Pay attention to the vehicle's brake lights as well so you know when it is slowing down.","imageName":"","correctAnswer":7,"isBookmarked":0,"aid":"5#6#7#8","text":"two-second interval method#three-second interval method#four-second interval method#five-second interval method"},
{"testId":2,"qid":43,"description":"This traffic sign is a:","explanation":"Drivers are warned by warning signs of real or potentially harmful situations ahead. The backdrop of warning signs is yellow or yellow-green. The majority of warning signs are diamond shaped. Slow-moving farm vehicles may cross the road, according to this warning notice.","imageName":"202002101207437888.jpg","correctAnswer":10,"isBookmarked":0,"aid":"9#10#11#12","text":"Guide sign#warning sign#Service sign#Regulatory sign"},
{"testId":2,"qid":44,"description":"At 40 mph, the impact of a collision is ______ greater than at 20 mph.","explanation":"A moving object's kinetic energy grows as the square of its speed. The kinetic energy of a vehicle going at 40 mph is four times that of a vehicle traveling at 20 mph. As a result, the impact with a stationary item will be four times as powerful.","imageName":"","correctAnswer":13,"isBookmarked":0,"aid":"13#14#15#16","text":"four times#two times#three times#five times"},
{"testId":2,"qid":45,"description":"When the sun is shining brightly or when a line of automobiles behind you may cover your turn signal light, you must use:","explanation":"When the sun is shining brightly or when a line of automobiles behind you potentially cover your turn signal light, you must utilize hand signals. Hand signals, however, may not be seen at night except in well-lit regions.","imageName":"","correctAnswer":20,"isBookmarked":0,"aid":"20#296#1334#34917","text":"hand signals#parking lights#brake lights#4-way flashers"},
{"testId":2,"qid":46,"description":"When you see this sign, you must _________before you can go into the intersection.","explanation":"When you see a stop sign, you must stop completely before the stop line, crosswalk, or junction, whichever comes first. Then, give way to pedestrians and other cars, and advance only when it is safe to do so.","imageName":"202001301744345845.jpg","correctAnswer":758,"isBookmarked":0,"aid":"757#758#14064#29749","text":"slow down#stop#speed up#turn"},
{"testId":2,"qid":47,"description":"Use the three-point turn to _____ unless it is prohibited.","explanation":"If the street is too small to do a U-turn, you may turn around by making a three-point turn, unless it is banned.","imageName":"","correctAnswer":26,"isBookmarked":0,"aid":"25#26#27#28","text":"Pass another vehicle on a narrow road#Turn around on a narrow street#Change lanes#Make a left turn"},
{"testId":2,"qid":48,"description":"Two vehicles come to an intersection at the same time from two different roadways. Each vehicle encounters a stop sign. Which vehicle must yield?","explanation":"When two cars approach an uncontrolled junction or an all-way stop at about the same time, the driver on the left must yield to the motorist on the right.","imageName":"","correctAnswer":38735,"isBookmarked":0,"aid":"38732#38735#42361#42362","text":"The vehicle on the right must yield to the vehicle on the left#The vehicle on the left must yield to the vehicle on the right#The vehicle with more passengers must yield to the vehicle with fewer passengers#The smaller vehicle must yield to the larger vehicle"},
{"testId":2,"qid":49,"description":"Why are the flashing arrow boards used in work zones?","explanation":"In work zones, flashing arrow panels may be utilized to direct cars into certain traffic lanes. These panels are installed on the road or mounted on vehicles to warn approaching motorists of lane closures.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#36#8687#8774","text":"Guide drivers into certain traffic lanes#Warn drivers against careless driving#Direct vehicles into certain parking areas#Divert drivers into work zones"},
{"testId":2,"qid":50,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the split roadway is coming to an end ahead. The road will be converted to a two-way street. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"54#8873#19179#22656","text":"A one-way road is ahead#The divided highway ends ahead#Traffic is merging ahead#A divided highway starts ahead"},
{"testId":2,"qid":51,"description":"Which of the following is NOT TRUE about train crossings?","explanation":"Do not attempt to cross the rails unless you can do so without stopping or changing gears (because your vehicle might stall on the tracks). If the crossing gates are down, come to a complete stop and stay there until the gates are lifted. When a train approaches a crossing, it must always come to a complete stop. A train cannot give you the right-of-way.","imageName":"202003161136554337.jpg","correctAnswer":42363,"isBookmarked":0,"aid":"41#44#42363#42364","text":"Watch out for more than one train#Always stop when a train is close, even if the crossing is unmarked#You can shift gears on the railroad crossing#Never drive around crossing gates"},
{"testId":2,"qid":52,"description":"When driving during the day in Alaska, you must turn on your headlights if visibility is:","explanation":"The Alaska Administrative Code requires you to turn on your headlights throughout the day if individuals or vehicles on the route are not readily apparent at a distance of 1,000 feet due to inadequate light or other atmospheric circumstances. [13 AAC 04 .010]","imageName":"","correctAnswer":47,"isBookmarked":0,"aid":"45#47#48#38515","text":"2,000 feet or less#1,000 feet or less#3,000 feet or less#500 feet or less"},
{"testId":2,"qid":54,"description":"What is the meaning of this sign?","explanation":"This sign advises you that you are about to enter a two-way undivided street. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101251206942.jpg","correctAnswer":56,"isBookmarked":0,"aid":"54#55#56#15563","text":"A one-way road is ahead#A no-passing zone is ahead#A two-way road is ahead#A divided highway is ahead"},
{"testId":2,"qid":55,"description":"You should use ______ to get up to highway speed before attempting to merge into traffic on a controlled-access highway.","explanation":"Enter the acceleration lane from the entrance ramp of a controlled access highway and raise your speed to match that of highway traffic. Then, give a signal and safely merge into the highway lane.","imageName":"","correctAnswer":60,"isBookmarked":0,"aid":"57#58#59#60","text":"the deceleration lane#the merging lane#the traffic lane#the acceleration lane"},
{"testId":2,"qid":56,"description":"Turn the front wheels________,if your vehicle is skidding.","explanation":"If your rear wheels begin to slide, DON'T PANIC and STOP YET. Instead, spin the steering wheel in the desired direction of your car. If your rear wheels are sliding to the left, steer to the left; if they are sliding to the right, steer to the right. The rear wheels may begin to skid in the opposite direction if they overshoot. To avoid this, move the steering wheel in the other direction as soon as your car begins to straighten out. This approach, known as \"steering into the skid,\" will bring your vehicle's rear into level with the front.","imageName":"","correctAnswer":63,"isBookmarked":0,"aid":"61#62#63#64","text":"in the opposite direction of the skid#toward the right#in the direction of the skid#toward the left"},
{"testId":2,"qid":57,"description":"In _______, the wheels must be positioned within 12 inches of the curb.","explanation":"When parallel parking in Alaska, the wheels must be within 12 inches of the curb.","imageName":"","correctAnswer":65,"isBookmarked":0,"aid":"65#66#67#68","text":"Parallel parking#double parking#downhill parking#uphill parking"},
{"testId":2,"qid":58,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Before beginning the descent, check your brakes. Check your mirrors regularly while driving down a long or steep slope. Vehicles often gain speed when they descend a steep incline. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":70,"isBookmarked":0,"aid":"69#70#71#72","text":"Trucks turning ahead#A steep downgrade ahead#A slippery road ahead#A one-lane bridge ahead"},
{"testId":2,"qid":59,"description":"When parking a vehicle facing downhill near to a curb, the front wheels should be turned:","explanation":"When parallel parking downhill, keep your front wheels pointed toward the curb. If your brakes fail, the curb will prevent your vehicle from moving forward. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse with a manual transmission).","imageName":"","correctAnswer":73,"isBookmarked":0,"aid":"64#73#74#9705","text":"toward the left#toward the curb#parallel to the curb#away from the curb"},
{"testId":2,"qid":60,"description":"Upon the imminent arrival of an authorized emergency vehicle showing a red flashing light or sounding a siren, all approaching and overtaking vehicles must :","explanation":"When an authorized emergency vehicle with a flashing red light or siren approaches, all vehicles must cede the right-of-way, clear any junction, pull over to the curb or side of the road, and stop. (Never, even if an emergency vehicle is coming, block an intersection.)","imageName":"","correctAnswer":42367,"isBookmarked":0,"aid":"42365#42366#42367#42368","text":"slow down and let the emergency vehicle pass#yield the right-of-way, pull over to the left, and stop#yield the right-of-way, pull over to the right, and stop#speed up to pass the emergency vehicle sooner"},
{"testId":2,"qid":61,"description":"When you go close to a yield sign, you must:","explanation":"When you come to a yield sign, you must slow down and yield to pedestrians and cars at the junction or roadway you are about to enter. You may need to stop for them as well, so be prepared.","imageName":"","correctAnswer":81,"isBookmarked":0,"aid":"81#7651#8954#42369","text":"prepare to slow down#Come to a complete stop#take a detour#turn around"},
{"testId":2,"qid":62,"description":"What is the meaning of this sign?","explanation":"This caution sign advises that a sudden right turn is required here.","imageName":"202004071602097345.jpg","correctAnswer":84,"isBookmarked":0,"aid":"84#86#28726#42370","text":"Make a sharp right turn#Prepare to stop#The road ahead curves to the right#A rest area is open on the right"},
{"testId":2,"qid":63,"description":"The Mandatory Insurance Law requires either the driver or the owner of the vehicle to keep __________ in the vehicle.","explanation":"The owner or driver of the vehicle must have liability insurance under Alaska's Mandatory Insurance Law. The vehicle must be equipped with proof of liability insurance. Proof of current registration is also needed to be stored in the vehicle, although this is governed by a separate statute. [AS 28.10.461], [AS 28.22 .019]","imageName":"","correctAnswer":42373,"isBookmarked":0,"aid":"42371#42372#42373#42374","text":"proof of the person's date of birth#proof of health insurance#proof of vehicle liability insurance#proof of current registration"},
{"testId":2,"qid":64,"description":"Under Alaska law, you are considered to be driving under the influence if your blood alcohol concentration (BAC) is _____ or higher.","explanation":"Driving under the influence is suspected in Alaska if you drive a motor vehicle with a blood or breath alcohol concentration of 0.08 percent or more (DUI). If convicted, you might face harsh consequences, including a required minimum prison term.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#11768#13112","text":"0.08%#0.09%#0.10%#0.16%"},
{"testId":2,"qid":66,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending school zone. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there. Except as otherwise marked, the speed limit in a school zone in Alaska is 20 mph.","imageName":"202003151740461521.jpg","correctAnswer":102,"isBookmarked":0,"aid":"100#101#102#103","text":"A library is ahead#Pedestrians are not allowed#A school zone is ahead#A school bus stop is ahead"},
{"testId":2,"qid":67,"description":"When you see a flashing red light, you must:","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a full stop before yielding the right-of-way.","imageName":"","correctAnswer":42377,"isBookmarked":0,"aid":"42369#42375#42376#42377","text":"turn around#stop until the light turns green#slow down, yield, and proceed when safe#stop, yield, and proceed when safe"},
{"testId":2,"qid":68,"description":"When approaching individuals who are walking or crossing the street, you must:","explanation":"When approaching people on the road or crossing it, you must slow down, yield, and be prepared to stop. Pedestrians have the right of way in marked or unmarked crosswalks.","imageName":"","correctAnswer":108,"isBookmarked":0,"aid":"108#109#110#42378","text":"slow down, yield, and be prepared to stop#accelerate and move quickly#blow your horn, slow down, and stop#use your 4-way flashers"},
{"testId":2,"qid":69,"description":"If you're travelling slower than other traffic on a multilane highway, utilize:","explanation":"On multilane roadways, the center lanes generally have the smoothest traffic flow. The left lane is designated for passing. Slower cars should stay in the right lane.","imageName":"","correctAnswer":42381,"isBookmarked":0,"aid":"115#42379#42380#42381","text":"the right or outside lane when passing#the left or inside lane except when passing#the center lane except when passing#the right or outside lane except when passing"},
{"testId":2,"qid":70,"description":"Which of the following statements is true about this traffic light?","explanation":"Once the junction is clear, proceed. Make any lawful movement that is not expressly forbidden by a traffic control device. Yield to pedestrians and vehicles that are still in or entering the junction with the right-of-way, such as people crossing the street with the green light. If you're making a left turn, give way to incoming traffic.","imageName":"202005071630024434.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"117#23029#39724#42382","text":"Make any legal maneuver not specifically prohibited by a traffic control device#All of the above are true#Proceed once the intersection is clear#Yield to oncoming vehicles if you are making a left turn"},
{"testId":2,"qid":71,"description":"You must enter the ______ of the central island as you approach a roundabout.","explanation":"A roundabout is a traffic management device that circulates traffic counterclockwise around a central island. Vehicles approaching the circular must yield to traffic in the circle. Always approach the centre island from the right.","imageName":"","correctAnswer":121,"isBookmarked":0,"aid":"120#121#122#123","text":"Center#Right#Left#corner"},
{"testId":2,"qid":72,"description":"To safely pass another vehicle, you must maintain a safe distance and NOT _____ until the road is clear.","explanation":"You must keep a safe distance. Wait until you can see both headlights of the passing car in your rear-view mirror before attempting to return to your previous lane. Turn on your turn signal, check your blind areas, and then return to your previous lane.","imageName":"","correctAnswer":34401,"isBookmarked":0,"aid":"124#125#126#34401","text":"return to the passing lane#increase your speed#go straight#return to your original lane"},
{"testId":2,"qid":73,"description":"You should not pass:","explanation":"A no-passing zone is denoted by a solid yellow line. If there is a solid yellow line adjacent to your lane, you are not permitted to pass. Passing a stopped school bus with flashing red lights and an extended stop arm is likewise prohibited. Also, do not pass while approaching a slope or bend when vision is limited.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#31291#32169#32170","text":"In any of the above situations#if there is a solid yellow line next to your lane#a school bus ahead with flashing red lights and an extended stop arm#when approaching a hill or curve where there is no clear visibility"},
{"testId":2,"qid":74,"description":"Flashing red lights, lowered crossing gates, or ringing bells at a railroad crossing indicate that:","explanation":"A train is coming when there are flashing red lights, lowered crossing gates, or ringing bells at a railroad crossing. You must come to a halt 15 to 50 feet away from the closest rail. Before crossing, wait for the lights to stop and the crossing gates to completely open. [13 AAC 02 .240]","imageName":"202003181410217334.jpg","correctAnswer":32815,"isBookmarked":0,"aid":"30938#32815#37925#42383","text":"the road ahead is closed#you must stop#you must slow down and proceed with caution#you may not turn left"},
{"testId":2,"qid":75,"description":"To avoid a collision, you must ______ when a train is within 1,500 feet of the railroad crossing.","explanation":"Never attempt to outrun a train to a railroad crossing. If a train is within 1,500 feet of the crossing, you must come to a complete stop. Stopping lengths for trains are unusually lengthy. [13 AAC 02 .240]","imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"758#14064#29749#42384","text":"stop#speed up#turn#cross quickly"},
{"testId":2,"qid":76,"description":"If your vehicle breaks down at night and you need to stop, utilize ________ to avoid an accident.","explanation":"If your car breaks down at night and you need to stop, employ extra caution; you risk getting struck from behind. Turn on your 4-way flashers to alert other drivers of your presence. Place emergency flares or warning triangles behind your car as an extra caution to other drivers.","imageName":"","correctAnswer":34917,"isBookmarked":0,"aid":"143#34917#39022#39023","text":"higher gears first and then lower gears#4-way flashers#high-beam headlights and then low-beam headlights#hand signals and high-beam headlights"},
{"testId":2,"qid":77,"description":"Which of the following is an example of an uncontrolled intersection?","explanation":"Uncontrolled junctions are those that do not have stop signs, yield signs, or traffic lights. To identify which cars may advance first at an uncontrolled junction, follow the right-of-way regulations.","imageName":"","correctAnswer":144,"isBookmarked":0,"aid":"144#145#42385#42386","text":"Intersections that are not protected by stop signs, yield signs, or traffic lights#Intersections that are not protected by a police officer#Intersections where the speed limit is not posted#Intersections that are not controlled by a flashing red light"},
{"testId":2,"qid":78,"description":"What is the meaning of this sign?","explanation":"This service sign indicates that a rest space is available on the right.","imageName":"202003301709031995.jpg","correctAnswer":150,"isBookmarked":0,"aid":"148#149#150#151","text":"Construction zone ahead#Forest zone ahead#Rest area ahead#Parking zone ahead"},
{"testId":11,"qid":78,"description":"What is the meaning of this sign?","explanation":"This service sign indicates that a rest space is available on the right.","imageName":"202003301709031995.jpg","correctAnswer":150,"isBookmarked":0,"aid":"148#149#150#151","text":"Construction zone ahead#Forest zone ahead#Rest area ahead#Parking zone ahead"},
{"testId":2,"qid":79,"description":"To turn left from a one-way street onto a two-way street, you have to:","explanation":"To turn left from a one-way street into a two-way street, begin the turn in the one-way street's leftmost lane, unless signs, signals, or pavement markings indicate otherwise.","imageName":"","correctAnswer":42387,"isBookmarked":0,"aid":"42387#42388#42389#42390","text":"start the turn from the leftmost lane#start the turn from the rightmost lane#start the turn from a middle lane#give a left-turn signal for at least 200 feet"},
{"testId":2,"qid":80,"description":"Which one of these is NOT a safe way to drive?","explanation":"Driving while texting on an electronic device is forbidden in Alaska. It's also prohibited to just have an electronic visual display (such as a mobile phone screen) in your line of sight. If you are convicted of either offense, you might face significant penalties such as up to a year in prison. [AS 12.55.135], [AS 28.35 .161]","imageName":"","correctAnswer":42393,"isBookmarked":0,"aid":"156#42391#42392#42393","text":"Looking forward and sideways while parking#Laughing and humming to music#Looking in your mirrors for a moment#Looking at an electronic visual display"},
{"testId":1,"qid":81,"description":"This symbol can be found on the back of:","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22520#29177#29774","text":"a slow-moving vehicle#a school bus#a vehicle carrying hazardous materials#an emergency vehicle"},
{"testId":1,"qid":82,"description":"This sign's shape implies:","explanation":"The only form of sign that is pennant shaped is the No Passing Zone sign. It marks the beginning of a no-passing zone for all cars heading in your way. In this zone, you may not pass other cars. This sign may be seen on the left side of the road.","imageName":"202003151738521917.jpg","correctAnswer":8482,"isBookmarked":0,"aid":"8482#19384#40041#42613","text":"a no-passing zone#a one-way road#merging traffic#you're going the wrong way"},
{"testId":1,"qid":83,"description":"What should you do when you see this sign?","explanation":"This sign indicates the presence of a traffic island or other impediment ahead. Maintain your position.","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"37#19246#29761#30329","text":"Merge right#Keep right#Keep left#Make a right turn"},
{"testId":1,"qid":84,"description":"What is the meaning of this sign?","explanation":"This sign warns drivers that livestock may be crossing the road ahead. Slow down and keep an eye out for calves crossing the road.","imageName":"202002101203434405.jpg","correctAnswer":172,"isBookmarked":0,"aid":"172#173#175#2394","text":"Cattle crossing ahead#Veterinary hospital ahead#Zoo ahead#Deer crossing ahead"},
{"testId":1,"qid":85,"description":"What is the meaning of this sign?","explanation":"This work zone sign shows that employees are undertaking road repair. Slow down, drive carefully, and change lanes whenever feasible to preserve a safe distance from the employees.","imageName":"202002101222518967.jpg","correctAnswer":178,"isBookmarked":0,"aid":"178#179#38605#38781","text":"Workers on or near the roadway#Mowing equipment ahead#Survey crew at work#Flagperson ahead"},
{"testId":1,"qid":86,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for falling vehicles like trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":28065,"isBookmarked":0,"aid":"24470#28065#41728#42614","text":"Trucks are not allowed on this route#There is a downgrade ahead#Trucks carrying heavy loads are not allowed#Watch for trucks entering this roadway"},
{"testId":1,"qid":87,"description":"What is the meaning of this sign?","explanation":"This sign denotes a substantial drop from the pavement's edge to the shoulder. Take extreme precautions not to fall off the pavement. Slow down and steer forcefully if you must leave the roadway for the shoulder.","imageName":"202002101217083259.jpg","correctAnswer":42615,"isBookmarked":0,"aid":"19186#19187#19569#42615","text":"The road is slippery and wet#There is gravel on the road#There is a bump in the road#There is a significant drop from the pavement edge to the shoulder"},
{"testId":1,"qid":88,"description":"What is the meaning of this sign?","explanation":"This sign may be seen at the end of various T-intersections. It indicates you have to cede the right-of-way before turning right or left onto the through route.","imageName":"202003301727587672.jpg","correctAnswer":41563,"isBookmarked":0,"aid":"22656#41561#41562#41563","text":"A divided highway starts ahead#The roadway is closed#You must bypass an obstacle on either side#You must yield and then turn right or left"},
{"testId":1,"qid":89,"description":"This symbol denotes:","explanation":"This sign warns of an impending two-lane bridge or culvert. Approach it slowly and with care.","imageName":"202002101245562248.jpg","correctAnswer":8964,"isBookmarked":0,"aid":"8662#8964#37431#42616","text":"an underpass ahead#a narrow bridge ahead#a narrow one-way road ahead#an acceleration lane ahead"},
{"testId":1,"qid":90,"description":"This symbol alerts drivers that they are approaching a :","explanation":"This sign notifies you that the one-way street you're on will be split into two lanes. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101251206942.jpg","correctAnswer":27097,"isBookmarked":0,"aid":"27094#27095#27096#27097","text":"divided highway#one-lane bridge#one-way roadway#two-way roadway"},
{"testId":1,"qid":91,"description":"When leaving a parallel parking space, you should:","explanation":"When leaving a parallel parking place, check over your shoulder as well as in your mirrors. Then, before entering traffic, wait until the road is clear. You should signal your intention, enter traffic in the adjacent lane, and stay in that lane until it is safe to shift to another lane.","imageName":"202006041749104526.jpg","correctAnswer":10656,"isBookmarked":0,"aid":"9994#10656#24278#30595","text":"look over your shoulder#Do all of the above#indicate your intention by signaling#look in your mirrors"},
{"testId":1,"qid":92,"description":"To turn left from a two-way street onto another two-way street at an intersection, begin the turn from:","explanation":"Unless signs or pavement markings indicate otherwise, begin a left turn from the lane closest to the center line or traffic divider. During the turn, avoid swinging wide. Enter the crosswalk to the right of the centerline.","imageName":"","correctAnswer":42617,"isBookmarked":0,"aid":"6624#22428#42617#42618","text":"Any lane#The rightmost lane#the lane nearest to the center line or traffic divider#an opposing lane when it's safe to do so"},
{"testId":1,"qid":93,"description":"You should never drive motorcyle together in the same lane because:","explanation":"A motorbike, like any other motor vehicle, requires a full lane width to drive safely. Even if the road is wide and the motorcyclist is riding to one side, never drive alongside a motorbike in the same lane.","imageName":"202006041913488192.jpg","correctAnswer":26354,"isBookmarked":0,"aid":"22650#22651#26354#29821","text":"motorcycles do not have signal lights#motorcyclists do not need to follow traffic rules#the motorcycle needs the full width of the lane#the motorcyclist might misguide you"},
{"testId":1,"qid":94,"description":"Under perfect driving conditions, the stopping distance of a car traveling at 55 mph is approximately:","explanation":null,"imageName":"","correctAnswer":215,"isBookmarked":0,"aid":"212#213#214#215","text":"293 feet#278 feet#100 feet#193 feet"},
{"testId":1,"qid":95,"description":"Which of the following documents must you submit to a police officer if you are stopped for a traffic violation?","explanation":"Every motor vehicle in Alabama is required by law to have vehicle liability insurance. A police officer may request your driver's licence, proof of insurance, and car registration if you are pulled over for a traffic infraction or equipment inspection. Every time you drive, you must have this evidence of insurance on hand. A digital licence may be downloaded to your smartphone and used as identity.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#217#218#37412","text":"All of the above#Proof of insurance#Vehicle registration#Driver license"},
{"testId":1,"qid":96,"description":"If you are experiencing emotional or physical stress while driving, you should:","explanation":"Emotions might impair your ability to drive safely. If you are tired, sleep deprived, anxious, unwell, nervous, terrified, furious, or depressed, either stop driving or delegate driving to someone else.","imageName":"","correctAnswer":8475,"isBookmarked":0,"aid":"8475#8476#8478#24280","text":"stop driving or have someone else drive#drive fast and reach your destination as soon as possible#start talking on your cell phone while driving#take medication and drive"},
{"testId":1,"qid":97,"description":"Which of the following is NOT true about using a cell phone while driving in Alabama?","explanation":null,"imageName":"","correctAnswer":42620,"isBookmarked":0,"aid":"42619#42620#42621#42622","text":"An adult driver may talk on a handheld cell phone#An adult driver may send a text message from a handheld cell phone#An adult driver may talk on a hands-free cell phone#A newly licensed driver under 18 years of age may not use a handheld cell phone"},
{"testId":1,"qid":98,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.02 percent or higher, his or her driver license will be suspended for:","explanation":"For a first offense, minor drivers risk the same penalties as adult drivers for driving with a BAC of 0.02 percent: a $600 to $2,100 fine, up to one year in jail, or both, plus a 90-day license suspension.","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"24049#25857#25858#26650","text":"90 days#120 days#180 days#One year"},
{"testId":1,"qid":99,"description":"If your vehicle collides with an unattended vehicle on a public route in Alabama, you must :","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#41013#42623#42624","text":"do any of the above#Leave a note identifying yourself#notify the police#locate the owner of the vehicle"},
{"testId":1,"qid":100,"description":"The areas surrounding trucks and buses where accidents are most likely to occur are referred to as :","explanation":"Do not congregate in a No-Zone! No-Zones are vast regions surrounding trucks, buses, and other big vehicles where automobiles might vanish into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. There are no-zones in the vehicle's front, back, and sides. It is hard to totally avoid the No-Zones of a heavy vehicle. However, do not stay in a No-Zone for any longer than is absolutely required to safely pass the vehicle. Never, ever tailgate a truck.","imageName":"","correctAnswer":26081,"isBookmarked":0,"aid":"26081#42625#42626#42627","text":"No-Zones#Empty-Zones#Round-Zones#No-Passing Zones"},
{"testId":1,"qid":101,"description":"If an emergency vehicle with flashing red or blue lights and a siren approaches, you must:","explanation":"If an emergency vehicle with flashing red or blue lights and a siren approaches from either direction, you must pull over to the curb or side of the road and come to a full stop.","imageName":"","correctAnswer":6280,"isBookmarked":0,"aid":"6280#22436#22544#42628","text":"pull over and stop#reduce your speed and continue slowly#continue at the same speed#speed up to get out of its way"},
{"testId":1,"qid":103,"description":"When two vehicles approach an uncontrolled intersection at roughly the same moment, the vehicle _____ has the right-of-way.","explanation":"When two cars reach an uncontrolled junction (one not regulated by signs or signals) from opposite highways at about the same time, the driver of the vehicle on the left must yield to the driver of the vehicle on the right.","imageName":"","correctAnswer":249,"isBookmarked":0,"aid":"249#250#5212#8597","text":"On the right#On the left#With more passengers#Signaling first"},
{"testId":1,"qid":104,"description":"To go from a private road to a public road,:","explanation":"Cars approaching from a private road or driveway must come to a complete stop and yield to vehicles on public roads.","imageName":"","correctAnswer":34692,"isBookmarked":0,"aid":"34691#34692#34693#42631","text":"you can enter the roadway without signaling#you must stop and yield to the vehicles on the roadway#you can make a sharp turn and enter the roadway while blowing the horn#you must switch on your four-way flashers first"},
{"testId":1,"qid":105,"description":"You may pass a car on the left___________ on a two-lane road with traffic moving in both directions.","explanation":"You are not permitted to exceed the speed limit in Alabama for any reason. You may pass a vehicle on the left on a two-lane road with traffic flowing in both directions only if the pass can be accomplished without exceeding the speed limit.","imageName":"","correctAnswer":42634,"isBookmarked":0,"aid":"42632#42633#42634#42635","text":"if the pass can be completed by exceeding the speed limit#if the pass can be completed in less than 4 seconds#if the pass can be completed without exceeding the speed limit#if the pass can be completed in less than 10 seconds"},
{"testId":1,"qid":106,"description":"If you see an old person crossing a street, you should :","explanation":"At junctions and crosswalks, you must surrender the right of way to pedestrians.","imageName":"","correctAnswer":29819,"isBookmarked":0,"aid":"24155#29093#29095#29819","text":"blow your horn to stop the person#keep driving#increase your speed and cross the intersection quickly#stop and yield the right-of-way"},
{"testId":1,"qid":107,"description":"What's the right-turn hand signal?","explanation":"Make sure the other drivers are aware of your intentions. Until you are prepared to make the turn, keep signalling. The right hand and arm signal for a right turn is an upward-facing hand and arm.","imageName":"","correctAnswer":22623,"isBookmarked":0,"aid":"22622#22623#22624#22625","text":"Hand and arm extended downward#Hand and arm extended upward#Hand and arm extended outward#Hand and arm extended backward"},
{"testId":1,"qid":108,"description":"When do high-beam headlights need to be dimmed in Alabama?","explanation":"When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, Alabama law requires you to reduce your headlights to low beam. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate.","imageName":"","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":1,"qid":109,"description":"You're driving on a slippery wet road without antilock brakes (ABS). What is the most effective approach to stop your vehicle?","explanation":"Apply the brakes carefully while stopping on packed snow or ice. Braking too forcefully in a car without ABS may lock the wheels and cause a skid. Instead, slam on the brakes. To begin, apply the brakes. If your wheels lock, let off of the brakes to get them going again, then squeeze again. Continue pumping until the car comes to a halt. Please keep in mind that if your car has ABS, you should NOT do this. ABS will conduct its own brake pumping to protect the wheels from locking, allowing you to press the brake pedal hard until the car comes to a complete stop. Pumping the brakes will just disable the ABS.","imageName":"","correctAnswer":21287,"isBookmarked":0,"aid":"21287#32984#38130#42636","text":"Pump the brakes#Take your foot off the accelerator#Shift into neutral#Apply the brake pedal firmly"},
{"testId":1,"qid":110,"description":"You must exit the freeway at a _________ before making a left turn.","explanation":"A diamond interchange is often utilized when a motorway intersects an ordinary road, and it is distinguished by four ramps. After exiting the highway, left turns are made.","imageName":"","correctAnswer":277,"isBookmarked":0,"aid":"275#277#278#42637","text":"directional interchange#diamond interchange#trumpet interchange#cloverleaf interchange"},
{"testId":1,"qid":111,"description":"40% of traffic-related fatalities are the result of hitting the windshield, windshield frame, or instrument panel. The majority of these tragedies may be avoided by using :","explanation":"According to statistics, 30 percent of traffic fatalities are caused by hitting the steering assembly, while another 40 percent are caused by impacting the windshield, windshield frame, or instrument panel. Wearing a lap/shoulder belt significantly minimizes your chances of being killed in an accident. The lap belt covers your lower body and prevents ejection. The shoulder belt prevents your head and chest from colliding with the steering column or windshield.","imageName":"","correctAnswer":42638,"isBookmarked":0,"aid":"42638#42639#42640#42641","text":"a shoulder belt#a lap belt#safety glasses#a windshield coating"},
{"testId":1,"qid":112,"description":"What will lead to the suspension of your driver license on public roads ?","explanation":null,"imageName":"","correctAnswer":12723,"isBookmarked":0,"aid":"848#3452#11944#12723","text":"Any of the above#Tailgating#Speeding#Drag racing"},
{"testId":1,"qid":113,"description":"Which of the following denotes the road's outer boundary, and it may only be passed by vehicles driving to or from the shoulder?","explanation":"Many roads have a solid white line marking the right edge. This line denotes the roadway's outside border and may only be crossed by cars travelling to or from the shoulder. A yellow line delineates the left margin.","imageName":"","correctAnswer":2876,"isBookmarked":0,"aid":"2873#2874#2876#22626","text":"Broken yellow line#Solid yellow line#Solid white line#Broken white line"},
{"testId":1,"qid":114,"description":"If convicted of driving uninsured vehicle for the first time, you may face fine of up to:","explanation":"You are not permitted to operate, register, or retain registration of a motor vehicle to be driven on public roadways in Alabama unless it is covered by a liability insurance policy. If you are convicted of breaking this legislation for the first time, you will be fined up to $500.","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23888#42642#42643","text":"$500#$600#$700#$800"},
{"testId":1,"qid":115,"description":"If you're travelling in the rain, snow, or fog, use your:","explanation":"Turn on your low-beam headlights to increase visibility in fog, rain, or snow. High lights may produce glare by reflecting off precipitation.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#42644","text":"Parking lights#High-beam headlights#Low-beam headlights#four-way flashers"},
{"testId":1,"qid":116,"description":"What should you do to get brack on track after hydroplaning?","explanation":"When you drive too quickly on a wet road, you may experience hydroplaning. If your car begins to hydroplane, do not use the brakes. Braking too hard may cause your car to slip out of control. Instead, remove your foot off the accelerator and allow your car to slow down until hydroplaning no longer happens.","imageName":"","correctAnswer":32984,"isBookmarked":0,"aid":"21273#21287#32984#42645","text":"Brake hard#Pump the brakes#Take your foot off the accelerator#Apply more pressure to the accelerator"},
{"testId":1,"qid":117,"description":"If you detect an approaching vehicle in your lane, you should:","explanation":"If you observe an incoming automobile in your lane, pull to the right, brake to slow down, and blow your horn loudly. Flash your lights at night. This may rouse a sleepy or inattentive motorist who is approaching you. Do not go into the left lane; the driver of the approaching vehicle may awaken and swing back into your path. To escape a head-on accident, be prepared to drive completely off the road to the right. Accidents involving a head-on collision are often deadly.","imageName":"","correctAnswer":42647,"isBookmarked":0,"aid":"8575#8955#42646#42647","text":"keep your steering wheel straight#stop#pull to the left and slow down#pull to the right and slow down"},
{"testId":1,"qid":118,"description":"If your wheels stray onto the road's shoulder, you should :","explanation":"If your vehicle's wheels stray into the road shoulder, do not attempt to swerve back onto the pavement immediately soon. This may cause the vehicle to become unbalanced. Instead, remain on the shoulder and take it slowly. Then, slowly return to the road.","imageName":"","correctAnswer":42651,"isBookmarked":0,"aid":"42648#42649#42650#42651","text":"apply the brakes and stop the vehicle#increase your speed and return to the roadway#swerve back onto the roadway as soon as possible#stay on the shoulder and slow down"},
{"testId":1,"qid":119,"description":"As you get closer to and enter an acceleration lane,:","explanation":"For accessing highways, entrance ramps are provided. Typically, the entry ramp leads to an acceleration lane. Increase your speed as you approach and enter the acceleration lane to match the cars in the through lanes. Look for a gap in motorway traffic, engage your turn signal, and join seamlessly with the rest of the traffic. Unless absolutely required, do not stop or slow down in the acceleration lane.","imageName":"","correctAnswer":42652,"isBookmarked":0,"aid":"42652#42653#42654#42655","text":"increase your speed to match that of vehicles in the through lanes#decrease your speed to match that of vehicles in the through lanes#merge slowly onto the freeway#stop before trying to merge onto the freeway"},
{"testId":1,"qid":120,"description":"In inclement weather, you should increase your following distance to:","explanation":"For favorable driving circumstances, the Alabama Driver Manual recommends the two-second rule. If the road or weather conditions are bad, the handbook advises you to increase your following distance to four or five seconds.","imageName":"","correctAnswer":14310,"isBookmarked":0,"aid":"14310#22149#26443#42656","text":"5 seconds#7 seconds#3 seconds#9 seconds"},
{"testId":3,"qid":121,"description":"This symbol alerts you that you are approaching:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":9211,"isBookmarked":0,"aid":"8482#9211#22101#22110","text":"a no-passing zone#a railroad crossing#a dead end#a four-way intersection"},
{"testId":3,"qid":122,"description":"Which of the following signs tells you that a school is coming up?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Their emblems are black on a yellow or brilliant yellow-green backdrop.","imageName":"202004071552224342.jpg","correctAnswer":322,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":3,"qid":123,"description":"What does this symbol indicates?","explanation":"This worldwide emblem of access for people with physical impairments appears on designated parking signs, placards, and license plates. Parking spots designated with this symbol may be used only by vehicles showing a valid placard or license plate bearing this symbol, and only while carrying the person who was granted the placard or plate. Anyone else parking in these places is breaking the law.","imageName":"202003151732554338.jpg","correctAnswer":31991,"isBookmarked":0,"aid":"8836#22102#22753#31991","text":"A pedestrian crosswalk#Restroom availability#A hospital service#A place reserved for persons with disabilities"},
{"testId":3,"qid":124,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these locations, they wear orange vests or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":3277,"isBookmarked":0,"aid":"1346#3277#34894#43381","text":"A crosswalk ahead#A flagger ahead#A police officer ahead#A hitchhiker ahead"},
{"testId":52,"qid":124,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these locations, they wear orange vests or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":3277,"isBookmarked":0,"aid":"1346#3277#34894#43381","text":"A crosswalk ahead#A flagger ahead#A police officer ahead#A hitchhiker ahead"},
{"testId":3,"qid":125,"description":"This symbol alerts vehicles to:","explanation":"This sign alerts cars to the presence of a crossroads or four-way junction ahead. Slow down and keep an eye out for oncoming vehicles.","imageName":"202002101146277756.jpg","correctAnswer":22110,"isBookmarked":0,"aid":"9151#22108#22109#22110","text":"a divided highway#a hidden intersection#a two-way intersection#a four-way intersection"},
{"testId":3,"qid":126,"description":"This figure's broken white line denotes:","explanation":"White lines divide traffic lanes traveling in the same direction. Crossing broken white lines should be done with care.","imageName":"202003161219208832.jpg","correctAnswer":24576,"isBookmarked":0,"aid":"8482#24575#24576#33418","text":"a no-passing zone#lanes of traffic moving in opposite directions#lanes of traffic moving in the same direction#a high-occupancy vehicle (HOV) lane"},
{"testId":3,"qid":127,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101233137248.jpg","correctAnswer":5666,"isBookmarked":0,"aid":"1527#5666#5667#43484","text":"A narrow bridge ahead#Low clearance ahead#A narrow road ahead#A side road ahead at an angle"},
{"testId":3,"qid":128,"description":"This symbol is:","explanation":"This is a No-Entry sign. It signifies that you are not permitted to enter the road where the sign is displayed. Such signs will be seen at road openings that you should not enter or where you would be going the wrong way on a one-way street.","imageName":"202003151714361449.jpg","correctAnswer":19269,"isBookmarked":0,"aid":"8733#8734#19269#42414","text":"a stop sign#a yield sign#a Do Not Enter sign#a No Passing Zone sign"},
{"testId":3,"qid":129,"description":"What is the meaning of this sign?","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you have the option of going straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":38498,"isBookmarked":0,"aid":"30133#38498#40024#40732","text":"You must go straight#You may either go straight or turn left#You must turn left if you are keeping left#You must merge left if you are keeping left"},
{"testId":3,"qid":130,"description":"If you see this sign, you must:","explanation":"The arrow represents a right turn. \"No\" always indicates a red circle with a diagonal red slash. As a result, you are not permitted to do a U-turn here.","imageName":"202003151744407638.jpg","correctAnswer":22119,"isBookmarked":0,"aid":"6365#8992#22118#22119","text":"Turn left#make a U-turn#not turn left#not make a U-turn"},
{"testId":3,"qid":131,"description":"Which one of the following is NOT true about parking?","explanation":"It is unlawful in Arizona to park within 15 feet of a fire hydrant. (There are several more sites where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.)","imageName":"","correctAnswer":8910,"isBookmarked":0,"aid":"8907#8908#8909#8910","text":"It is illegal to park on a freeway#In an emergency, it is legal to park on the shoulder of the road as far away from traffic as possible#When parking on a downhill grade, turn your wheels toward the curb#It is legal to park within 15 feet of a fire hydrant"},
{"testId":3,"qid":132,"description":"For the first time, a driver under the age of 18 with a Class G graduated driver license is convicted of a traffic offence. Which of the following sanctions will be imposed on this individual?","explanation":"A motorist under the age of 18 with a graded driving license who is convicted for the first time of a traffic offence must attend Traffic Survival School. This infraction is also recorded on his or her driving record. If the motorist is guilty of another traffic offense, his or her license will be suspended.","imageName":"","correctAnswer":43485,"isBookmarked":0,"aid":"43485#43486#43487#43488","text":"The driver must attend Traffic Survival School#The driver's license will be suspended#The driver's license will be revoked#The driver must perform community service"},
{"testId":3,"qid":133,"description":"Your ________ keeps your head and chest from hitting the dashboard or windshield in a crash.","explanation":"Wearing a lap and shoulder belt minimizes your chances of getting badly hurt in a collision. The lap belt covers your lower body and prevents ejection from the car. The shoulder belt prevents your head and chest from collapsing into the dashboard or windshield.","imageName":"202007141456463146.jpg","correctAnswer":366,"isBookmarked":0,"aid":"365#366#367#9000","text":"head restraint#shoulder belt#lap belt#steering wheel"},
{"testId":32,"qid":133,"description":"Your ________ keeps your head and chest from hitting the dashboard or windshield in a crash.","explanation":"Wearing a lap and shoulder belt minimizes your chances of getting badly hurt in a collision. The lap belt covers your lower body and prevents ejection from the car. The shoulder belt prevents your head and chest from collapsing into the dashboard or windshield.","imageName":"202007141456463146.jpg","correctAnswer":366,"isBookmarked":0,"aid":"365#366#367#9000","text":"head restraint#shoulder belt#lap belt#steering wheel"},
{"testId":3,"qid":134,"description":"Children ______ shouldn't ride in the front seat.","explanation":"Little ones should not use airbags. Never let a child under the age of 12 sit in the front seat of an airbag-equipped vehicle without first deactivating the passenger airbag.","imageName":"","correctAnswer":368,"isBookmarked":0,"aid":"368#369#370#371","text":"age 12 years and under#age 14 years and under#age 16 years and under#age 13 years and under"},
{"testId":3,"qid":135,"description":"Allow at least _____ of clearance between you and a bicycle when sharing a lane.","explanation":"Allow at least 3 feet of room between you and a bicycle while sharing a lane. When passing the bicycle, slow down and be cautious.","imageName":"","correctAnswer":374,"isBookmarked":0,"aid":"372#373#374#375","text":"1 foot#2 feet#3 feet#4 feet"},
{"testId":3,"qid":136,"description":"The appropriate left turn hand signal is:","explanation":"When you wish to make a turn, make sure other drivers are aware of your intentions. Continue to flash the signals until you are ready to make the actual turn. Hand and arm extended outward is the right hand signal for a left turn.","imageName":"","correctAnswer":22129,"isBookmarked":0,"aid":"22127#22128#22129#22130","text":"hand and arm extended downward#hand and arm extended upward#hand and arm extended outward#hand and arm extended backward"},
{"testId":3,"qid":137,"description":"When a vehicle in front of you flashes its lights with the purpose to pass, you should :","explanation":"When another vehicle approaches you and blows its horn or flashes its lights, shift to the right when safe and allow it to pass safely. When another car is passing you, never accelerate.","imageName":"","correctAnswer":22132,"isBookmarked":0,"aid":"6515#22131#22132#22133","text":"Increase your speed#move to the left when safe and allow the vehicle to pass#move to the right when safe and allow the vehicle to pass#Move to the shoulder of the road and yield"},
{"testId":3,"qid":138,"description":"Vehicles approaching a roundabout are required to:","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Vehicles approaching the roundabout must cede the right-of-way to traffic already in the roundabout. Always enter the roundabout on the right (counterclockwise around the central island).","imageName":"","correctAnswer":22628,"isBookmarked":0,"aid":"22137#22627#22628#22629","text":"enter the roundabout to the left of the central island#come to a complete stop and wait for traffic in the roundabout to clear#yield to the traffic in the roundabout#enter the roundabout at a speed of 35 mph"},
{"testId":3,"qid":139,"description":"You've parked against the curb, uphill. What direction should your front wheels be pointed?","explanation":"When parallel parking uphill close to a curb, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"6656#9713#14638#35158","text":"Parallel to the curb#Away from the curb#Toward the curb#Toward the right"},
{"testId":3,"qid":140,"description":"Which of the following things is NOT TRUE about freeways?","explanation":"Freeways and interstate roads are built to safely handle high-speed traffic. Do not cross a solid line while approaching or leaving a highway.","imageName":"","correctAnswer":8912,"isBookmarked":0,"aid":"388#389#8911#8912","text":"Freeways are designed to handle higher-speed traffic safely#Enter a freeway smoothly by accelerating on the entrance ramp to match the speed of the traffic#Most freeways have deceleration lanes to assist the driver in exiting#While using a freeway, cross a solid line immediately after entering or before exiting"},
{"testId":3,"qid":141,"description":"The space between a through route and an entry or exit ramp on a freeway is referred to as the:","explanation":"The gore area on a highway is the region between a through route and an entry or exit ramp. Driving over or parking in a gore area is unlawful.","imageName":"","correctAnswer":22142,"isBookmarked":0,"aid":"22142#22143#43489#43490","text":"gore area#uncontrolled area#shoulder#median"},
{"testId":3,"qid":142,"description":"When you see an oncoming vehicle, you prepare to turn left at an intersection. What should you do?","explanation":"When preparing to turn left, you must give pedestrians and approaching cars the right-of-way.","imageName":"","correctAnswer":28078,"isBookmarked":0,"aid":"28078#28079#28080#28081","text":"Yield the right-of-way to the oncoming vehicle#Move into the adjacent lane#Warn the oncoming vehicle to stop#Move to the shoulder of the road and yield"},
{"testId":3,"qid":143,"description":"You must not follow any emergency vehicle with a siren or flashing lights within ______ feet.","explanation":null,"imageName":"","correctAnswer":400,"isBookmarked":0,"aid":"214#399#400#401","text":"100 feet#200 feet#300 feet#400 feet"},
{"testId":3,"qid":145,"description":"What should you do if you enter an intersection to turn left and the traffic signal turns yellow?","explanation":"A continuous yellow traffic light indicates that it is ready to turn red. You must proceed if you have already reached the junction. Execute the planned motion and safely clear the junction.","imageName":"202007210441576834.jpg","correctAnswer":15671,"isBookmarked":0,"aid":"15671#15672#29203#40721","text":"Turn left#Turn right#Go straight and then make a U-turn#Stop and wait for the light to turn green"},
{"testId":3,"qid":146,"description":"What is used to indicate that passing is not allowed in either direction?","explanation":"Passing is not permitted on either side of a double solid yellow line. You may cross the line only to turn left.","imageName":"","correctAnswer":5350,"isBookmarked":0,"aid":"5109#5110#5350#26329","text":"A broken yellow line#A broken white line#A double solid yellow line#One broken yellow line and one solid yellow line"},
{"testId":3,"qid":147,"description":"What does a yellow curb represent in Arizona means ?","explanation":"Colored curb markings may be used in addition to or instead of regulation signs to limit parking. In Arizona, a yellow curb denotes that you may only stop there to load or unload persons or freight. (Please keep in mind that there is no national standard for curb colors.) Each state has the option of determining how to employ curb colors. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":34403,"isBookmarked":0,"aid":"34402#34403#34404#34405","text":"You may stop only long enough to make a quick turn#You may stop only long enough to load or unload#You may not stop, stand, or park#You may stop until the road is clear to pass"},
{"testId":3,"qid":150,"description":"On slick roads, the Arizona Driver License Manual says to stay at least _____ behind the vehicle in front of you.","explanation":"In most regular driving situations, the Arizona Driver License Manual advises a following distance of at least 3 seconds and at least 6 seconds on icy roads.","imageName":"","correctAnswer":428,"isBookmarked":0,"aid":"426#427#428#429","text":"4 seconds#5 seconds#6 seconds#7 seconds"},
{"testId":3,"qid":151,"description":"What should you do if you face a heavy dust storm while driving?","explanation":"Dust storms are widespread in Arizona and may limit a driver's sight to nil at times. If you come across a dust cloud, slow down quickly, drive off the road gently, stop as far to the right as possible, switch off your lights, and take your foot off the brake. Stay in your car, seat belt secured, and wait for the dust storm to pass.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#43491#43492#43493","text":"Do all of the above#Slow down immediately and pull off the roadway#Stop as far to the right as possible#Stay in your vehicle with your seat belt buckled"},
{"testId":3,"qid":152,"description":"You should use________,while driving in fog.","explanation":"High beams' strong light may bounce off fog or precipitation, generating glare. Use low beams (and fog lights if your car has them) and search for road edge markers to aid you while driving in fog.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#34037#35389","text":"High-beam headlights#Low-beam headlights#Emergency flashers#interior lights"},
{"testId":3,"qid":153,"description":"What should you do if you start to feel sleepy while driving?","explanation":"If you see yourself growing drowsy while driving, pull over and rest. Sleep is the only safe remedy for tiredness.","imageName":"","correctAnswer":8830,"isBookmarked":0,"aid":"8828#8829#8830#43494","text":"Stare straight ahead#Change lanes frequently#Pull over and take a nap#Increase your speed to reach your destination faster"},
{"testId":3,"qid":154,"description":"If your engine quits while you are driving around a turn, you should:","explanation":"If your engine quits while driving around a bend, grab the steering wheel with both hands and drive to the right side of the road. (If your vehicle has power steering, a loss of engine power will result in a loss of power assist, requiring you to apply greater effort to the steering wheel.) Stop the car. (If your vehicle has power brakes, a lack of engine power will result in a loss of power assist, requiring you to push the brake pedal extra hard.) Shift the gearbox into park (for automatic transmissions) or neutral (for manual transmissions) and attempt to restart the engine.","imageName":"","correctAnswer":33029,"isBookmarked":0,"aid":"440#441#33029#40327","text":"ease off the gas pedal#try to restart the engine on the road#pull over to the right side of the road#hold your steering wheel tightly and keep your vehicle going straight"},
{"testId":3,"qid":155,"description":"If you are stopped at a traffic light and another vehicle is approaching you from behind at high speed, you should take the following precautions:","explanation":"If you are stopped at a junction and another car is coming from behind at high speed, you should move slightly forward (but not into the intersection!) to allow the approaching vehicle additional space to stop.","imageName":"202003201650198553.jpg","correctAnswer":22158,"isBookmarked":0,"aid":"22158#22159#22160#22161","text":"pull your vehicle forward#pull your vehicle to the right#pull your vehicle to the left#make a quick U-turn"},
{"testId":3,"qid":156,"description":"What should you do if you are the first person to arrive at the site of a collision?","explanation":"If you are one of the first people to arrive at a crash site, the first thing you should do is pull your car off the road. Then, turn off the ignition of any vehicles involved in the accident. If there is no threat of fire, do not transfer wounded people. Notify emergency personnel and avoid standing in traffic lanes. Inquire of those who have stopped to warn oncoming cars.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"8485#22163#23000#30958","text":"Notify the local police#Turn off the ignition of vehicles involved in the crash#Do all of the above#Move your vehicle off the traveled portion of the roadway"},
{"testId":3,"qid":157,"description":"Steam rising from beneath your vehicle's bonnet could indicate:","explanation":"Underhood steam might indicate that the cooling system has overheated. Pull over to the side of the road and instantly switch off your engine. Carrying additional coolant or water in your vehicle to add to the radiator after it cools down is a wise precaution. Modern automobiles often need a 50/50 balance of coolant and water, so just pouring water into the radiator may cause the engine to overheat again in the future. If you just have water, fill the radiator with it, but also add coolant as quickly as possible.","imageName":"202003161941366205.jpg","correctAnswer":22165,"isBookmarked":0,"aid":"22164#22165#28235#38786","text":"there is a danger of fire#your cooling system has overheated#there is a short circuit in the electrical system#your antilock brakes have failed"},
{"testId":4,"qid":161,"description":"Adjust the headrests so that the restraint meets the back of the head. This avoids:","explanation":"Head restraints are intended to protect your neck if you are assaulted from behind. Headrests should be positioned such that in the case of an accident, the head restraint contacts the back of the head.","imageName":"202007150229037758.jpg","correctAnswer":8604,"isBookmarked":0,"aid":"8604#8605#24231","text":"neck injuries if you are hit from behind#accidents from behind#bodily injuries in a head-on collision"},
{"testId":4,"qid":162,"description":"In Arkansas, you can't drive or ride in the front seat:","explanation":"Driving or riding in the front seat without a seat belt is banned in Arkansas. A driver's license is not necessary for a front-seat passenger.","imageName":"","correctAnswer":22174,"isBookmarked":0,"aid":"22172#22174#42970","text":"with a radio turned on#without wearing a safety belt#while using a hands-free cell phone"},
{"testId":4,"qid":163,"description":"If there are no marked lower speed limits in a work zone, you should:","explanation":"Many work zones have decreased speed limits and limited lane use for safety reasons. If no lower speed restrictions are stated, you must respect the posted speed limit.","imageName":"","correctAnswer":8707,"isBookmarked":0,"aid":"8707#8708#41259","text":"obey the normal posted speed limit#drive at a speed of less than 50 mph#drive at a speed of more than 70 mph"},
{"testId":4,"qid":164,"description":"When you see a flashing yellow signal at a junction, what should you do?","explanation":"A flashing yellow light indicates a potential threat. Slow down and take your time. Flashing yellow lights are placed in potentially dangerous areas.","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#22176#22177","text":"Slow down and proceed with caution#Take a detour#Increase your speed to 45 mph"},
{"testId":4,"qid":165,"description":"If you come across a sign with a horizontal white line inside a red circle, you must :","explanation":"A Do Not Enter sign has a horizontal white line within a red circle. You are not permitted to enter a highway with a Do Not Enter sign. Such signs will be seen at road openings that you should not enter or where you would be going the wrong way on a one-way street. These signs may be seen at exit ramps, crossovers on split highways, and several points on one-way roads.","imageName":"","correctAnswer":42972,"isBookmarked":0,"aid":"6476#22180#42972","text":"Slow down#yield the right-of-way to oncoming traffic#not enter this roadway"},
{"testId":4,"qid":166,"description":"To make a turn, you must turn the steering wheel by the:","explanation":"Use the hand-over-hand method to control the steering wheel while making a quick turn, such as around a curve. When you've finished the turn, return the steering wheel to its original position in the direction the car should be heading.","imageName":"","correctAnswer":22185,"isBookmarked":0,"aid":"22184#22185#22186","text":"single-hand drive and reverse technique#hand-over-hand technique#hand-off-hand technique"},
{"testId":4,"qid":167,"description":"In a car, how far away from the air bag should the driver position himself?","explanation":"Because the danger zone for driver air bags is the first 2-3 inches of inflation, positioning oneself 10 inches away from your driver air bag gives a clear margin of safety. This is the distance between the center of the steering wheel and your breastbone.","imageName":"","correctAnswer":10045,"isBookmarked":0,"aid":"816#10045#44699","text":"25 inches#10 inches#7 inches"},
{"testId":4,"qid":168,"description":"What is the meaning of this sign?","explanation":"This sign denotes a fork in the road ahead. A crossroad is a junction where two roads converge. Slow down and keep an eye out for cross traffic on the right and left.","imageName":"202002101146277756.jpg","correctAnswer":4655,"isBookmarked":0,"aid":"1346#1584#4655","text":"A crosswalk ahead#A hospital ahead#An intersection ahead"},
{"testId":4,"qid":169,"description":"When driving in the city, you should look ahead at least _____ to avoid unexpected braking or turning.","explanation":"The Arkansas Driver License Study Guide suggests that you glance ahead at least one city block in the city or one-quarter mile on highways and interstates to prevent last-minute braking or the need to turn quickly.","imageName":"","correctAnswer":496,"isBookmarked":0,"aid":"496#497#498","text":"one city block#two city blocks#three city blocks"},
{"testId":4,"qid":170,"description":"Make sure there is no traffic approaching _______ before turning right at an intersection.","explanation":"Make sure there is no traffic arriving from the left and no incoming traffic turning left into your path before turning right at a junction. Also, give way to people crossing the road you're about to enter.","imageName":"202003070857473536.jpg","correctAnswer":22190,"isBookmarked":0,"aid":"22190#22191#24349","text":"from your left and no oncoming traffic turning left into your path#from your right and no oncoming traffic turning right into your path#from your right and no oncoming traffic turning left into your path"},
{"testId":4,"qid":171,"description":"When you want to change lanes, look over your shoulder in the direction you want to go to make sure there are no vehicles in :","explanation":"When changing lanes, ensure sure there are no cars in your blind spots by looking over your shoulder in the direction you want to drive. These spots are not visible in your mirrors.","imageName":"","correctAnswer":507,"isBookmarked":0,"aid":"505#506#507","text":"shoulder zones#no passing zones#blind spots"},
{"testId":4,"qid":172,"description":"What is the meaning of this sign?","explanation":"The arrow represents a turn to the right. A red slash within a red circle, on the other hand, implies \"no.\" This regulatory sign forbids automobiles from turning right. This sign is usually seen on the right side of the road or over a driving lane.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"30162#40269#41553","text":"The right side of the road is closed#You may not turn right#You may not merge right"},
{"testId":4,"qid":173,"description":"On a hot day, if it begins to rain, proceed with care as the pavement may become slick for the first few minutes. This is due to ________.","explanation":"Heat causes asphalt oil to rise to the surface, and precipitation dissolves those oil deposits. This makes the road more slippery until the oil is washed away by additional rain.","imageName":"","correctAnswer":22198,"isBookmarked":0,"aid":"22196#22197#22198","text":"oil in the vehicle#melting of tires#oil in the asphalt"},
{"testId":4,"qid":174,"description":"If there is traffic behind you when you get off a two-lane, high-speed road, you should:","explanation":"If you abruptly slow down while exiting a high-speed, two-lane highway, you may get rear-ended by the car ahead of you. Use your turn signals to communicate your intentions to other vehicles. Use your brakes to slow down swiftly yet safely.","imageName":"202007151857193635.jpg","correctAnswer":31287,"isBookmarked":0,"aid":"125#3767#31287","text":"increase your speed#try to slow down as soon as possible#try not to slow down suddenly"},
{"testId":4,"qid":175,"description":"If you can't see more than 200 feet in front of you when driving in inclement weather, you should not go faster than:","explanation":"If you can't see a safe distance ahead at your current pace, you're probably traveling too fast for safety. Traveling too quickly will not give you enough time to halt for unexpected risks. If you can't see more than 200 feet ahead, don't attempt to drive faster than 30 mph. At this rate, you'll cover 200 feet in 4.5 seconds.","imageName":"","correctAnswer":9969,"isBookmarked":0,"aid":"9969#22200#22201","text":"30 mph#15 mph#25 mph"},
{"testId":4,"qid":176,"description":"What is the meaning of this sign?","explanation":"This is a construction (work) zone warning sign. It suggests that a flagger may be stationed to direct traffic. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":42974,"isBookmarked":0,"aid":"22202#22203#42974","text":"Flagger is stationed to control traffic at an intersection#Flagger is stationed to control traffic near a school#Flagger is stationed to control traffic at a construction zone"},
{"testId":4,"qid":177,"description":"In severe rain, your vehicle's tires might lose touch with the road and ride on a layer of water. This threat is also known as:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" Hydroplaning may cause entire loss of braking and steering control at speeds of 50 mph or higher. To avoid hydroplaning, make sure your tires have adequate tread, that they are properly inflated, and that you drive slowly. In rainy conditions, always increase your following distance.","imageName":"202003201654212060.jpg","correctAnswer":8583,"isBookmarked":0,"aid":"8583#9002#9003","text":"Hydroplaning#rainplaning#waterplaning"},
{"testId":4,"qid":179,"description":"To overtake a motorbike, you must:","explanation":"A motorbike has the legal right to the whole width of a lane. To pass a motorbike, you must do it in an adjacent lane. You are not permitted to share the motorbike lane.","imageName":"","correctAnswer":22577,"isBookmarked":0,"aid":"22577#22579#26274","text":"pass in an adjacent lane#use the rightmost lane to pass#use the same lane as the motorcycle"},
{"testId":4,"qid":180,"description":"This symbol cautions drivers that:","explanation":"This sign indicates that the road ahead is slick in the rain. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. Roads are often slickest within the first half hour of rain. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":41401,"isBookmarked":0,"aid":"34663#41401#42975","text":"a steep hill is ahead#the road ahead is slippery when wet#a work zone is ahead"},
{"testId":4,"qid":181,"description":"It is best to stay _____ of your lane.","explanation":"Driving in the middle of your lane is the safest option. Maintain as much space as possible between your car and the vehicles on both sides.","imageName":"","correctAnswer":22633,"isBookmarked":0,"aid":"22630#22631#22633","text":"on the left side#on the right side#in the center"},
{"testId":4,"qid":182,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":"Sometimes you have to deal with risks one by one. If you are going to pass a bicycle and an incoming vehicle approaches, slow down and let the car pass first to give the cyclist more space. Then move to the left to provide enough space to pass the bicycle.","imageName":"","correctAnswer":30622,"isBookmarked":0,"aid":"30622#38606#38607","text":"Slow down and let the vehicle pass first#Speed up and pass the bicycle first#Sound your horn to alert the oncoming vehicle"},
{"testId":4,"qid":183,"description":"When changing lanes, entering a roadway, or merging with another lane, you must leave a ______ gap.","explanation":"When merging with other traffic, a four-second buffer is required. If you move in the midst of a four-second gap, your car and the vehicle behind you will both have the proper minimum following distance of two seconds.","imageName":"","correctAnswer":550,"isBookmarked":0,"aid":"549#550#551","text":"Two-second#Four-second#three-second"},
{"testId":4,"qid":184,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This sign states that U-turns are not permitted in this area.","imageName":"202003151744407638.jpg","correctAnswer":42976,"isBookmarked":0,"aid":"28692#30330#42976","text":"Make a U-turn#Make a left turn#Don't make a U-turn"},
{"testId":4,"qid":185,"description":"You must not pass at 55 mph if you are within ______ of a hill or curve.","explanation":null,"imageName":"","correctAnswer":5535,"isBookmarked":0,"aid":"5535#37391#42977","text":"one-third of a mile#two miles#one-half mile"},
{"testId":4,"qid":186,"description":"You are at a stop sign, awaiting a right turn. A vehicle approaching from the left has its turn signal activated. Which of the following should you do?","explanation":"If a car approaches from the left with its turn signal on while you are waiting at a stop sign to turn right, do not attempt to turn until the other vehicle has actually begun to turn. Then and only then should you begin your turn. Drivers are prone to making errors or changing their minds. The driver of the other car may, at the last second, alter his or her mind and opt to go straight through the junction. Or the driver of the other car may have neglected to turn off the prior turn signal. (This is particularly possible with motorbikes, which may not have self-canceling turn signals.) You risk colliding with the other car if you rely on it turning as its turn signal indicates. Always be on the lookout for faulty signals and dangerous drivers.","imageName":"202007210435464863.jpg","correctAnswer":32183,"isBookmarked":0,"aid":"32181#32183#34706","text":"Quickly turn on your headlights#Wait until the other vehicle actually starts to turn and then start your turn#Press the gas pedal immediately"},
{"testId":4,"qid":187,"description":"At 55 mph, you'll cross ______ in ten seconds.","explanation":"55 miles per hour = 80.7 feet per second. At 55 mph, you'll cover 807 feet in 10 seconds.","imageName":"","correctAnswer":565,"isBookmarked":0,"aid":"565#4432#22223","text":"800 feet#1,000 feet#1,600 feet"},
{"testId":4,"qid":188,"description":"What should you do if your vehicles's engine fails on the highway?","explanation":"If your engine dies while driving and your car comes to a halt, you should maintain a firm hold on the steering wheel. Be advised that turning the steering may be tough, but it is possible. Get off the road. The brakes will still function, but you may have to apply considerable force to the brake pedal.","imageName":"","correctAnswer":44700,"isBookmarked":0,"aid":"44700#44701#44702","text":"Keep a strong hold of the wheel and pull off the highway#Slam on the brakes and stop immediately#Blow your horn to warn the other drivers and then stop"},
{"testId":4,"qid":189,"description":"What is the meaning of this sign?","explanation":"This warning sign advises that traffic from the right lane is going to merge into your lane. It is the responsibility of drivers on both roads to merge smoothly.","imageName":"202002101239016186.jpg","correctAnswer":3126,"isBookmarked":0,"aid":"3123#3124#3126","text":"Merging from the left#The end of two-way traffic#Merging from the right"},
{"testId":4,"qid":191,"description":"When making a right turn, avoid ________ before beginning the turn.","explanation":"If you swing wide to the left, the vehicle behind you may mistakenly believe you are turning left and attempt to overtake you on your right. This may result in a collision.","imageName":"","correctAnswer":22225,"isBookmarked":0,"aid":"22225#29133#39319","text":"swinging wide to the left#using your mirrors#using your signals"},
{"testId":4,"qid":192,"description":"You must pull over to________ ,if you see or hear an emergency vehicle approaching from any direction.","explanation":"A police car, fire truck, ambulance, or other emergency vehicle with an air horn, siren, or red or blue flashing light must always surrender the right of way. Before stopping, get clear of any intersections, and then stop as close to the right side of the road as you can.","imageName":"","correctAnswer":10120,"isBookmarked":0,"aid":"10120#24945#41131","text":"the right edge of the road#the center of the road#the left edge of the road"},
{"testId":4,"qid":193,"description":"You must ______ for a school bus that has come to a halt with its red lights flashing on the opposite side of an undivided road.","explanation":null,"imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"588#757#758","text":"not stop#slow down#stop"},
{"testId":4,"qid":194,"description":"What is the meaning of this sign?","explanation":"This sign shows that the road ahead is blocked, but there is an other route accessible.","imageName":"202002101218598750.jpg","correctAnswer":41012,"isBookmarked":0,"aid":"41010#41011#41012","text":"There is construction 1,000 feet ahead#A parking zone is 1,000 feet ahead#An alternate route is 1,000 feet ahead"},
{"testId":4,"qid":195,"description":"In Arkansas, You must turn on your headlights :","explanation":null,"imageName":"","correctAnswer":31574,"isBookmarked":0,"aid":"31572#31574#42979","text":"from one hour after sunset to one hour before sunrise#from half an hour after sunset to half an hour before sunrise#from two hours after sunset to ten minutes before sunrise"},
{"testId":4,"qid":196,"description":"You shouldn't drive in a tractor-trailer's \"No-Zones\", which are located:","explanation":"No-Zones (blind spots) on a tractor-trailer may be up to 20 feet in front of the cab, on each side of the tractor-trailer, and up to 200 feet behind the vehicle. The truck driver will be unable to see you if you are traveling in one of these No-Zones.","imageName":"","correctAnswer":42984,"isBookmarked":0,"aid":"42981#42983#42984","text":"up to 300 feet behind the truck#up to 100 feet in front of the cab, on either side of the tractor-trailer, and up to 300 feet behind the truck#up to 20 feet in front of the cab, on either side of the tractor-trailer, and up to 200 feet behind the truck"},
{"testId":4,"qid":197,"description":"If you are involved in a traffic accident or commit a significant traffic crime, you can be charged with DWI if your blood alcohol concentration is ____________.","explanation":"In Arkansas, if you are involved in a traffic accident or commit a significant traffic crime, you may be charged with DWI if your blood alcohol concentration is between 0.08 and 0.04 percent.","imageName":"","correctAnswer":42946,"isBookmarked":0,"aid":"22236#25879#42946","text":"below 0.04%#below 0.004%#between 0.04 and 0.08%"},
{"testId":4,"qid":198,"description":"Diabetic drivers who ______ should avoid driving if they are at danger of falling into shock.","explanation":"Diabetics who use insulin should not drive if they are at danger of falling into shock. This might happen if you miss a meal or snack.","imageName":"","correctAnswer":40016,"isBookmarked":0,"aid":"40015#40016#40017","text":"eat healthy meals#take insulin#eat food with sugars"},
{"testId":4,"qid":199,"description":"This symbol indicates that:","explanation":"All cars in this lane must do a U-turn, according to this unique lane control sign. This sign may be supplemented with an equally unique traffic signal, with LED U-turn arrows indicating when cars can do U-turns.","imageName":"202003301747426660.jpg","correctAnswer":41377,"isBookmarked":0,"aid":"41375#41376#41377","text":"You must go straight and take a detour at the intersection#You may not make a left turn from this lane#You must make a U-turn from this lane"},
{"testId":4,"qid":200,"description":"The Arkansas Driver License Study Guide says that your hands must be on opposite sides of the wheel when you are driving.","explanation":"Make sure you have both hands on the driving wheel. Maintain opposite hands on the driving wheel.","imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":5,"qid":201,"description":"Which of the signs below symbolizes two-way traffic?","explanation":"This is a caution sign indicating a two-way street. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202005071638082339.jpg","correctAnswer":324,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":5,"qid":202,"description":"This symbol warns you about:","explanation":"A curving route is indicated by this caution sign. (There are at least three turns on a winding route.) [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202002061310151663.jpg","correctAnswer":19238,"isBookmarked":0,"aid":"19238#19386#23818","text":"a winding road#a two-way road#a slippery road"},
{"testId":5,"qid":203,"description":"The broken white lines on the road indicate that:","explanation":"Passing is permitted when the white lines are broken, but only when it can be done safely. [Lane Controls; California Driver Handbook; Line Colors]","imageName":"202003161201498577.jpg","correctAnswer":9139,"isBookmarked":0,"aid":"9139#19203#32912","text":"passing is allowed if done safely#no passing is allowed#traffic moves in opposite directions"},
{"testId":5,"qid":204,"description":"Which kind of sign is this?","explanation":"A yield sign is a red-and-white triangle with a downward arrow. It means that vehicles should slow down and be prepared to halt if required to allow any vehicle or pedestrian to pass. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202007120454477868.jpg","correctAnswer":628,"isBookmarked":0,"aid":"627#628#629","text":"Stop#Yield#Slow-moving vehicle"},
{"testId":5,"qid":205,"description":"What is the meaning of this sign?","explanation":"This is a warning sign indicating that you are approaching an airport. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202003301735414233.jpg","correctAnswer":19205,"isBookmarked":0,"aid":"19205#19207#23822","text":"You are approaching an airport#Airplanes fly in this direction#Airplanes fly at low altitude in this area"},
{"testId":5,"qid":206,"description":"What is the significance of this regulation sign?","explanation":"No always indicates a red circle with a diagonal red slash. Because the image within the circle portrays a U-turn, the sign indicates that you are not permitted to do a U-turn. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202003151744407638.jpg","correctAnswer":634,"isBookmarked":0,"aid":"634#635#636","text":"No U-turn#Left turn#Road ends"},
{"testId":5,"qid":207,"description":"This symbol stands for:","explanation":"A school zone is indicated by this five-sided sign representing people carrying schoolbooks. When there are youngsters at the crosswalk, come to a complete stop. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202003151740461521.jpg","correctAnswer":6933,"isBookmarked":0,"aid":"6933#8836#8838","text":"A school zone#A pedestrian crosswalk#a playground"},
{"testId":5,"qid":208,"description":"This caution symbol denotes:","explanation":"This sign denotes the end of a split roadway. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202002101249362358.jpg","correctAnswer":19287,"isBookmarked":0,"aid":"19287#23823#23824","text":"the end of a divided highway#the start of a divided highway#the start of a two-way road"},
{"testId":5,"qid":209,"description":"What is the meaning of this sign?","explanation":"A stop sign is ahead, as indicated by this warning sign. Before going, the car should come to a full halt near the stop sign. [Traffic Controls; Traffic Signs; California Driver Handbook]","imageName":"202002101139461433.jpg","correctAnswer":23827,"isBookmarked":0,"aid":"19208#19209#23827","text":"Slow-moving vehicles should not move as directed#Slow-moving vehicles should move as directed#There is a stop sign ahead"},
{"testId":5,"qid":210,"description":"When approaching an intersection with a through road but no stop or yield signs, you must :","explanation":"At crossings without a stop sign or a yield sign, give way to through-traffic cars, cyclists, and pedestrians. The right of way belongs to them. [California Driver Handbook, Traffic Controls, and Traffic Signs]","imageName":"202002101135262507.jpg","correctAnswer":650,"isBookmarked":0,"aid":"650#23828#23829","text":"yield to traffic and pedestrians on the through road#make a sharp turn to match the speed of the traffic on the through road#accelerate to merge with traffic"},
{"testId":5,"qid":211,"description":"A constant red arrow signal indicates:","explanation":"A red arrow indication implies you must come to a complete stop and cannot turn in the direction of the arrow. Only continue until the red arrow disappears and a green arrow or light appears. [Traffic Controls; Traffic Signal Lights; California Driver Handbook]","imageName":"","correctAnswer":24194,"isBookmarked":0,"aid":"24194#30307#42209","text":"you must stop and you cannot turn in the direction of the arrow#you must stop and then proceed when it is safe to do so#you should prepare to stop and yield to oncoming traffic before turning"},
{"testId":5,"qid":212,"description":"You must cross at an unmarked crosswalk.","explanation":"Drivers must surrender the right of way to pedestrians inside any crosswalk, marked or unmarked. [Pedestrian Responsibilities; Licensing Information in General; California Driver Handbook]","imageName":"","correctAnswer":8713,"isBookmarked":0,"aid":"8713#23835#23836","text":"Yield the right-of-way to the pedestrians#Increase your speed and cross before the pedestrians#stop and ask the pedestrians to cross quickly"},
{"testId":5,"qid":213,"description":"If all back seats are filled by children under 7, a youngster under 8 may:","explanation":"When all rear seats are already filled by children aged 7 or less, a kid under the age of 8 may travel in the front seat of a vehicle if properly secured in a federally authorized child passenger restraint system. [Child Safety Seats and Restraint Systems; Occupant Protection; California Driver Handbook]","imageName":"","correctAnswer":42212,"isBookmarked":0,"aid":"42210#42211#42212","text":"sit on the lap of an elder child, with a seat belt fastened to both#ride in the front seat without a seat belt#ride in the front seat if properly secured in a federally approved child passenger restraint system"},
{"testId":5,"qid":214,"description":"A traffic signal with a flashing yellow arrow indicates that you should:","explanation":"A flashing yellow arrow indicates that turns are allowed. You must, however, yield to pedestrians and oncoming cars before proceeding with care. [Traffic Controls; Traffic Signal Lights; California Driver Handbook]","imageName":"","correctAnswer":42213,"isBookmarked":0,"aid":"23840#23841#42213","text":"stop and be prepared to obey the next signal#proceed with caution but turns are not permitted in the direction of the arrow#yield and then proceed with caution"},
{"testId":5,"qid":215,"description":"If a cyclist on your right arrives at an interserction at the same time as you, you must:","explanation":"At junctions, you should always yield to the car or bicycle that comes first, or if you arrive at the intersection simultaneously, to the car or bicycle on your right. [Share the Road; Bicycles in Travel Lanes; California Driver Handbook]","imageName":"202007210355497919.jpg","correctAnswer":9141,"isBookmarked":0,"aid":"9141#9142#9143","text":"yield to the bicycle#move because you have the right-of-way#move slowly along with the bicycle"},
{"testId":5,"qid":216,"description":"In a roundabout, you must drive in :","explanation":"A roundabout is a junction where traffic round the center island counterclockwise. [Roundabouts; Traffic Laws and Rules; California Driver Handbook]","imageName":"","correctAnswer":23844,"isBookmarked":0,"aid":"8839#8841#23844","text":"a clockwise direction#the direction of the oncoming vehicles#a counterclockwise direction"},
{"testId":5,"qid":217,"description":"Who has the right of way when two cars meet on a steep mountain road where neither can pass?","explanation":"When two cars collide on a high mountain road and neither can pass, the downhill vehicle must relinquish the right-of-way by backing up until the uphill vehicle can pass. When backing up, the car facing downhill has more control. [On Mountain Roads; Traffic Laws; California Driver Handbook]","imageName":"202006041801247936.jpg","correctAnswer":679,"isBookmarked":0,"aid":"679#680#681","text":"The vehicle traveling uphill#The vehicle traveling downhill#Both vehicles"},
{"testId":5,"qid":218,"description":"Unless otherwise indicated, the maximum speed restriction for cars carrying trailers on a two-lane divided highway is:","explanation":"Unless otherwise specified, the maximum speed restriction for cars carrying trailers on two-lane undivided roads is 55 mph. [Maximum Speed Limit; Traffic Laws; California Driver Handbook]","imageName":"","correctAnswer":682,"isBookmarked":0,"aid":"682#683#684","text":"55 mph#60 mph#65 mph"},
{"testId":5,"qid":219,"description":"If you notice livestock near the road, you should :","explanation":"Drivers should slow down and obey the person in charge of the animals if they notice animals or livestock. [Near Animals; Traffic Laws and Regulations; California Driver Handbook]","imageName":"","correctAnswer":23850,"isBookmarked":0,"aid":"23847#23848#23850","text":"turn safely away from these animals#blow your horn continuously until the animals cross the road#follow directions from the person in charge of the animals"},
{"testId":5,"qid":220,"description":"Two sets of two or more feet apart double solid yellow lines indicate:","explanation":"A barrier is defined as two sets of solid double yellow lines that are two or more feet apart. Except at approved openings, do not drive over or over this barrier, nor perform a left or U-turn across it. [Lane Controls; California Driver Handbook; Line Colors]","imageName":"","correctAnswer":23854,"isBookmarked":0,"aid":"23851#23852#23854","text":"two-way traffic#a parking lot#a barrier"},
{"testId":5,"qid":221,"description":"There are marked \"turnout\" areas on a two-lane road that:","explanation":null,"imageName":"","correctAnswer":23857,"isBookmarked":0,"aid":"23855#23856#23857","text":"allow vehicles to make U-turns#allow vehicles to make left turns#allow vehicles to pass"},
{"testId":5,"qid":222,"description":"On a red signal, you can turn left:","explanation":"Turning left at a red light is only permitted from a one-way street onto a left-moving, one-way street if there is no notice prohibiting the turn. Give way to pedestrians, bicycles, and other vehicles that have a green signal. (Note: Some states' regulations regarding left turns at red lights may vary.) [Turns; Left Turns; California Driver Handbook]","imageName":"","correctAnswer":42214,"isBookmarked":0,"aid":"23859#42214#42215","text":"only from a one-way street onto a two-way street#only from a one-way street onto another one-way street#only from a two-way street onto another two-way street"},
{"testId":5,"qid":223,"description":"A __ painted curb is reserved for handicapped people who present placards.","explanation":"Special parking requirements are indicated by painted curbs. Blue parking is only allowed for handicapped people who have a placard or a special license plate for disabled people or disabled veterans. Disabled persons who have placards or special plates may park in designated spots for an indefinite amount of time, regardless of time limits. [California Driver Handbook, Parking at Colored Curbs]","imageName":"","correctAnswer":705,"isBookmarked":0,"aid":"702#704#705","text":"white#green#blue"},
{"testId":5,"qid":224,"description":"Anyone who knowingly flees or seeks to elude a peace officer doing their responsibilities commits a misdemeanor punishable by:","explanation":"Any driver who knowingly tries to elude or run from a peace officer while doing their responsibilities while driving a motor vehicle is guilty of a misdemeanour punished by up to one year in a county jail. [Rules; Administrative; California Driver Handbook; Evading a Peace Officer]","imageName":"","correctAnswer":45045,"isBookmarked":0,"aid":"21229#45044#45045","text":"neither of the above#a maximum fine of up to $1,000#1 year of imprisonment in a county jail"},
{"testId":5,"qid":225,"description":"To avoid tailgating, adhere to the:","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":23864,"isBookmarked":0,"aid":"710#23863#23864","text":"four-second rule#two-second rule#three-second rule"},
{"testId":5,"qid":226,"description":"You should use________,while driving in fog.","explanation":"It is not advisable to drive in fog. If you must travel in fog, slow down and utilize your low-beam headlights. High-beam light will likely to bounce back and generate glare. [Use Your Headlights; Drive Safely; California Driver Handbook]","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#34037","text":"High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":5,"qid":227,"description":"What is the first thing you should do if your vehicle breaks down and you need to stop?","explanation":null,"imageName":"","correctAnswer":23868,"isBookmarked":0,"aid":"23867#23868#23869","text":"Stop your vehicle over a hill or just around a curve for visibility#Pull off the road and away from traffic#Lift the hood to signal an emergency"},
{"testId":5,"qid":228,"description":"If you are in a junction and hear the siren of an emergency vehicle, you should proceed with caution.","explanation":"Even if an emergency vehicle is coming, never block an intersection. If you are approaching a junction and see or hear an emergency vehicle, go through the intersection. Then, as soon as you can, move to the right and come to a complete halt. [Emergency Vehicles; Road Safety; California Driver Handbook]","imageName":"","correctAnswer":23872,"isBookmarked":0,"aid":"8715#23871#23872","text":"move to the right and stop in the intersection#continue through the intersection, then move to the left and stop#continue through the intersection, then move to the right and stop"},
{"testId":5,"qid":229,"description":"A(n) _________ sign on a truck indicates that the load on the truck is possibly hazardous.","explanation":"Diamond-shaped labels on a truck indicate that it is transporting hazardous items (gas, explosives, etc.). If the cargo is accidently spilled, California Highway Patrol (CHP) or fire department officials know what to do. [Hazardous Load Vehicles; Sharing the Road; California Driver Handbook]","imageName":"","correctAnswer":723,"isBookmarked":0,"aid":"723#23874#23875","text":"diamond-shaped#octagonal#rectangular white"},
{"testId":5,"qid":230,"description":"If your vehicle begins to hydroplane, you should :","explanation":"If your car begins to hydroplane, gently slow down but do not engage the brakes. [Driving Dangers; Special Driving Circumstances; California Driver Handbook]","imageName":"","correctAnswer":23876,"isBookmarked":0,"aid":"8359#23876#23877","text":"apply the brakes#slow down gradually#press your gas pedal"},
{"testId":5,"qid":231,"description":"Locked wheel skids are frequently caused by:","explanation":"If you stop forcefully at high speed, the power of the brakes may surpass the grip of the tires on the road. No matter which direction the steering wheel is cranked, the wheels will lock and the car will slide. To recover from a skid, depress the brake pedal to free the wheels. Then, as the car starts to straighten out, straighten the front wheels. Slow down gradually until you reach a safe pace at which you may continue driving. [Locked Wheel Skids; Emergency Handling; California Driver Handbook]","imageName":"","correctAnswer":22569,"isBookmarked":0,"aid":"22568#22569#23879","text":"pressing the gas and brake pedals at the same time#braking too hard at a high speed#turning the ignition to the lock position while the vehicle is still in motion"},
{"testId":5,"qid":232,"description":"What shoul you do if your parked car rolls away and collides with an unattended parked vehicle?","explanation":"If you strike a parked car or other property, you must leave a notice with your name and address, the owner of your vehicle's name and address, and a description of the incident. Attach it securely to the car or property you strike. Report the crash to local police or the California Highway Patrol in unincorporated regions (CHP). [Collision; Collisions Are Not Accidents; California Driver Handbook]","imageName":"","correctAnswer":23881,"isBookmarked":0,"aid":"8915#23881#23882","text":"Remove your car and go on your way#Report the incident to the police#Sound your horn to attract attention"},
{"testId":5,"qid":233,"description":"A driver who is involved in a severe collision must notify the DMV within:","explanation":"If you are involved in an accident, you must report it to the DMV within 10 days if more than $1,000 in property damage was done to anybody's property or if anyone was hurt (however little) or died. [Collision Reporting; Collisions Are Not Accidents; California Driver Handbook]","imageName":"202006141611292838.jpg","correctAnswer":23883,"isBookmarked":0,"aid":"23883#23884#23885","text":"10 days#15 days#20 days"},
{"testId":5,"qid":234,"description":"Which of the following is true about the lines on the roads?","explanation":"A strong white line between traffic lanes indicates that you should remain in your lane unless there is an emergency that compels you to change lanes. [Lane Control; California Driver Handbook; Line Colors]","imageName":"","correctAnswer":22360,"isBookmarked":0,"aid":"22360#34697#42216","text":"A solid white line between lanes of traffic means that you should stay in your lane unless a special situation requires you to change lanes#A broken yellow line between opposing lanes of traffic means that you may not cross the line#A double solid line between opposing lanes of traffic means that vehicles on either side can pass"},
{"testId":5,"qid":235,"description":"_______ after sunset, you must switch on your headlights.","explanation":"Turn on your headlights 30 minutes after nightfall and keep them on on until 30 minutes before dawn. [Required Actions; Additional Driving Laws/Rules; California Driver Handbook]","imageName":"","correctAnswer":747,"isBookmarked":0,"aid":"746#747#748","text":"20 minutes#30 minutes#Immediately"},
{"testId":5,"qid":236,"description":"What should you do first when a police officer pulls you over?","explanation":"Turning on your right turn signal indicates to the officer that you are aware of his or her presence. Do not come to a halt in the middle median. [What to Do During a Traffic Stop; Special Driving Situations; California Driver Handbook]","imageName":"","correctAnswer":23893,"isBookmarked":0,"aid":"23891#23892#23893","text":"Stop on the center median#Stop on the left shoulder#Turn on your right turn signal"},
{"testId":5,"qid":237,"description":"Under normal conditions, your vehicle will travel approximately ___ feet in the time it takes you to react to an object in your path and bring your vehicle to a complete stop at 55 mph.","explanation":"When travelling at 55 mph in ideal circumstances, it takes around 400 feet for you to respond to an item in your path and bring your car to a full stop. A football field is 360 feet long when the two end zones are included. It could take considerably longer to stop your car in severe driving circumstances (such as bad weather or worn tyres). Visual search, \"How Well Can You Stop?\" and the California Driver Handbook","imageName":"","correctAnswer":14369,"isBookmarked":0,"aid":"7570#9975#14369","text":"200#300#400"},
{"testId":5,"qid":238,"description":"A driver in front of you is indicating by raising his or her hand and arm. This driver desires to:","explanation":"You may utilize the signal lights or hand signals on your car. Use hand signals if the signal lights are out of commission or if strong sunshine makes them difficult to see. A car in front of you is ready to turn right and is signaling with his or her hand and arm pointing up. [Signaling; Driving Safety; California Driver Handbook]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8955","text":"Turn left#Turn right#stop"},
{"testId":5,"qid":239,"description":"What does a double solid white line indicates?","explanation":"A double solid white line divides two lanes driving in the same direction, but crossing it is banned. Double solid white lines are often used on roads to separate a high-occupancy vehicle (HOV) lane from other lanes traveling in the same direction.","imageName":"202003161937198796.jpg","correctAnswer":39740,"isBookmarked":0,"aid":"33720#39740#39741","text":"Separates opposing lanes, and crossing the line is prohibited#Separates two lanes traveling in the same direction, but crossing the line is prohibited#Separates two lanes traveling in the same direction, and crossing the line is permitted"},
{"testId":5,"qid":240,"description":"When you want to get on a freeway, you should:","explanation":"Enter the motorway at or near traffic speed. Remember that the speed limit on most highways is 65 mph. Unless absolutely required, do not stop before entering into highway traffic. The right-of-way is reserved for freeway traffic. [Merging Space; In/Out of Traffic; California Driver Handbook]","imageName":"202007210423335122.jpg","correctAnswer":23901,"isBookmarked":0,"aid":"8922#8923#23901","text":"stop before merging into freeway traffic#stop and yield to the traffic on the freeway#enter the freeway at or near the speed of freeway traffic"},
{"testId":6,"qid":241,"description":"If the traffic signals at an intersection are not working properly, the intersection should be treated as a(n):","explanation":"If the traffic light is broken or not working, consider the junction as a four-way stop. Before going, the motorist must come to a full stop.","imageName":"","correctAnswer":23902,"isBookmarked":0,"aid":"23902#23903#23904#23905","text":"a four-way stop#a two-way stop#an uncontrolled intersection#a roundabout"},
{"testId":6,"qid":242,"description":"A flashing red arrow indicates that:","explanation":"A illuminated red arrow indicates that you are not permitted to advance in the direction indicated by the arrow.","imageName":"","correctAnswer":9005,"isBookmarked":0,"aid":"9005#9006#9007#23906","text":"you may not proceed in the direction indicated by the arrow#you may slowly proceed in the direction indicated by the arrow#you may proceed only after yielding to pedestrians and other traffic#you may not increase the speed of your vehicle in the direction indicated by the arrow"},
{"testId":6,"qid":243,"description":"A steady yellow traffic signal at an intersection signifies that drivers must :","explanation":"A continuous yellow traffic light signifies that the signal is soon to turn red. If you are not already at the junction, come to a complete stop.","imageName":"","correctAnswer":23908,"isBookmarked":0,"aid":"23907#23908#23909#23910","text":"increase their speed#stop unless they are already in the intersection#turn back#take a detour to the left"},
{"testId":6,"qid":244,"description":"What does this regulatory symbol mean?","explanation":"Drivers must slow down or even come to a complete halt to give way to any traffic on the road they are crossing or entering.","imageName":"202007120454477868.jpg","correctAnswer":23913,"isBookmarked":0,"aid":"23911#23912#23913#23914","text":"Drivers must maintain a maximum speed on the way#Drivers must take a right turn at the intersection#Drivers must reduce speed or stop if necessary#Drivers must not pass other vehicles"},
{"testId":6,"qid":245,"description":"When two vehicles meet on a steep, narrow road that is too small for both, the vehicle going downhill :","explanation":"When two cars collide on a steep, narrow route that is too small for two, the vehicle travelling downhill must cede the right-of-way. Unless it is more practicable for the vehicle traveling uphill to return to a larger area, it must stop or back up to a wider space to provide enough room for the vehicle travelling downhill.","imageName":"","correctAnswer":23916,"isBookmarked":0,"aid":"23915#23916#23917#23918","text":"must go first#must yield the right-of-way to the vehicle going uphill#must move simultaneously with the vehicle going uphill#must make a U-turn"},
{"testId":6,"qid":246,"description":"You're driving down a multilane highway when you come across an emergency vehicle stopped on the side of the road. You have to:","explanation":"Unless otherwise requested by a police officer, vehicles on a multilane highway must change lanes to allow a one-lane buffer between their vehicle and the emergency vehicle.","imageName":"","correctAnswer":23921,"isBookmarked":0,"aid":"7651#23919#23920#23921","text":"Come to a complete stop#increase your speed to pass the emergency vehicle quickly#maintain a following distance of about 50 feet from the emergency vehicle#leave at least one lane between your vehicle and the emergency vehicle, if possible"},
{"testId":6,"qid":247,"description":"The stopping distance for a lightweight passenger car traveling at 55 mph under ideal conditions is:","explanation":"A lightweight passenger automobile moving at 55 mph can stop in roughly 200 feet under perfect circumstances, according to the National Safety Council.","imageName":"","correctAnswer":23922,"isBookmarked":0,"aid":"23922#23923#23924#23925","text":"about 200 feet#less than 100 feet#more than 200 feet#about 400 feet"},
{"testId":6,"qid":249,"description":"When a school bus is stopped on the other side of the road with its red lights flashing, the driver must:","explanation":"Drivers must come to a complete stop at least 20 feet before approaching a stopped school bus with its red lights flashing, whether on your side of the road, the opposing side of the road, or at a junction.","imageName":"","correctAnswer":23930,"isBookmarked":0,"aid":"23930#23931#23932#23933","text":"stop the vehicle at least 20 feet before reaching the school bus#stop the vehicle at least 2 feet before reaching the school bus#not stop his or her vehicle#pass the school bus at reduced speed"},
{"testId":6,"qid":250,"description":"What does the diamond-shaped symbol represent?","explanation":"A median or divider separates the highway ahead into two distinct lanes, and cars must stay to the right.","imageName":"202002101247507801.jpg","correctAnswer":23934,"isBookmarked":0,"aid":"23934#23935#23936#23937","text":"Drivers must keep to the right of the divided highway#Drivers must keep to the left of the divided highway#Drivers must stop at the divider#Drivers must merge into the traffic"},
{"testId":6,"qid":251,"description":"If you want to make a left turn, you must first:","explanation":"If you're making a left turn, you must start in the far left lane.","imageName":"","correctAnswer":9011,"isBookmarked":0,"aid":"9009#9010#9011#9012","text":"in the rightmost lane#in the center lane#in the leftmost lane#in the intersection"},
{"testId":6,"qid":252,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the presence of a flag person ahead. Flaggers assist traffic in moving safely through construction sites.","imageName":"202002101220385703.jpg","correctAnswer":23938,"isBookmarked":0,"aid":"812#3177#23938#23939","text":"A pedestrian zone#A right turn ahead#A flag person ahead#A stop ahead"},
{"testId":6,"qid":253,"description":"Your vehicle must be parked within _______ of the curb where parking is permitted.","explanation":"Vehicles must be parked within 12 inches of the curb or as near to the outer edge of the shoulder as practicable so that traffic is not obstructed.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"815#816#817#818","text":"15 inches#25 inches#14 inches#12 inches"},
{"testId":6,"qid":254,"description":"Turn the steering wheel using the _______ approach when turning curves.","explanation":"Always maintain two hands on the wheel of the vehicle. Use the hand-over-hand or grip-and-slide technique while turning.","imageName":"","correctAnswer":23940,"isBookmarked":0,"aid":"819#820#23940#23941","text":"hand-off-hand method#hand-by-hand method#grip-and-slide method#two-hand-button method"},
{"testId":6,"qid":255,"description":"To enter a high-speed roadways, drivers must use :","explanation":"Acceleration ramps are used on high-speed highways to allow you time to increase your speed.","imageName":"","correctAnswer":824,"isBookmarked":0,"aid":"823#824#825#826","text":"Deceleration ramps#Acceleration ramps#metered ramps#blind curves"},
{"testId":6,"qid":256,"description":"This traffic symbol indicates:","explanation":"This sign cautions motorists of a twisting route ahead.","imageName":"202002061310151663.jpg","correctAnswer":19534,"isBookmarked":0,"aid":"19532#19534#23942#23943","text":"a narrow road ahead#a winding road ahead#a slippery road ahead#a mountain highway ahead"},
{"testId":6,"qid":257,"description":"When passing and reentering the lane in front of trucks or buses, drivers must keep a constant speed because trucks and buses need :","explanation":"Trucks and buses need greater deceleration distances due to their size. When reentering the lane in front of trucks or buses, other motorists must travel at a consistent pace.","imageName":"","correctAnswer":23946,"isBookmarked":0,"aid":"23944#23945#23946#23947","text":"shorter distances to slow down than cars do#less time to stop than cars do#longer distances to slow down than cars do#less room to maneuver than cars do"},
{"testId":6,"qid":258,"description":"No-Zones are zones around trucks and buses where automobiles vanish into:","explanation":"No-Zones are zones surrounding trucks and buses where automobiles either vanish into blind spots or approach so closely that the truck or bus driver's ability to stop is hampered.","imageName":"","correctAnswer":9136,"isBookmarked":0,"aid":"9136#23948#23949#23950","text":"blind spots#blind curves#invisible spots#passing areas"},
{"testId":6,"qid":259,"description":"Which of the statements below is false?","explanation":"Bicycles and motorbikes are smaller and more difficult to notice, and they may move and stop more quickly than anticipated.","imageName":"202007150246439133.jpg","correctAnswer":22337,"isBookmarked":0,"aid":"22337#22338#22340#23951","text":"Bicycles and motorcycles move slower and stop slower than expected#When driving around bicycles, increase your following distance#Motorcycles are entitled to the same full lane width as other vehicles#Never drive beside a motorcycle in the same lane"},
{"testId":6,"qid":260,"description":"This symbol indicates that:","explanation":"This lane control sign shows that the lane has divided into two. Vehicles must either travel straight or turn right.","imageName":"202004071533482418.jpg","correctAnswer":23953,"isBookmarked":0,"aid":"14780#23952#23953#23954","text":"traffic must go straight#traffic must merge at the arrows#the lane is splitting#there is a no-passing zone ahead"},
{"testId":6,"qid":261,"description":"When it looks like there might be a crash, drivers must act to:","explanation":"Drivers in accident scenarios must instantly attempt one of three options: slow or stop their cars, make turns, or accelerate up their vehicles if it is safe to do so.","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"8843#8845#22471#23955","text":"stop or slow down their vehicles#speed up their vehicles#do any of the above#make turns"},
{"testId":6,"qid":262,"description":"When an animal darts in front of your vehicle, you must :","explanation":"If an animal suddenly rushes in front of your car, you must do all in your power to prevent a collision.","imageName":"","correctAnswer":22570,"isBookmarked":0,"aid":"22570#22571#22572#22573","text":"concentrate on retaining control of your vehicle#apply the brakes as hard as you can#quickly drive around the animal#use the horn and proceed"},
{"testId":6,"qid":263,"description":"The motorist with a blood alcohol content (BAC) between _______ is deemed to be driving while intoxicated (DWAI).","explanation":"The Colorado Driving Act presumes a motorist with a blood alcohol content (BAC) between 0.05 and 0.07 percent to be Driving While Ability to Drive is Impaired (DWAI). If a motorist's blood alcohol concentration (BAC) is 0.08 percent or more, the driver is deemed to be driving under the influence (DUI).","imageName":"","correctAnswer":853,"isBookmarked":0,"aid":"853#854#855#856","text":"0.05% and 0.07%#0.05% and 0.06%#0.01% and 0.07%#0.07% and 0.09%"},
{"testId":6,"qid":264,"description":"What is the meaning of this sign?","explanation":"This sign designates a dedicated parking place for people with disabilities who have a permission or car plates.","imageName":"202003151732554338.jpg","correctAnswer":31992,"isBookmarked":0,"aid":"857#860#23956#31992","text":"A hospital zone#A bicycle parking area#A school zone parking area#A place reserved for persons with disabilities"},
{"testId":6,"qid":265,"description":"When there is a shared center turn lane on a street, the driver:","explanation":"Drivers are not authorized to turn left from any other lane if a roadway has a shared center turn lane.","imageName":"","correctAnswer":23959,"isBookmarked":0,"aid":"23918#23957#23958#23959","text":"must make a U-turn#may turn left from any other lane#may use the lane for passing#may not turn left from any other lane"},
{"testId":6,"qid":266,"description":"Which of the following is a safe thing to do while driving?","explanation":"Defensive driving safeguards you and others against potentially hazardous and unexpected driving scenarios. It is critical that you learn to drive defensively.","imageName":"","correctAnswer":865,"isBookmarked":0,"aid":"864#865#866#22512","text":"Tailgating#Defensive driving#Aggressive driving#Distracted driving"},
{"testId":6,"qid":267,"description":"Drivers must maintain windshield wipers because:","explanation":"Windshield wipers prevent rain and snow from accumulating on the windshield. They must be maintained in excellent operating order.","imageName":"","correctAnswer":23960,"isBookmarked":0,"aid":"22441#22443#22444#23960","text":"they absorb moisture from the atmosphere#they increase the stopping distance of the vehicle#they increase the fuel efficiency of the vehicle#they keep rain and snow off the windshield"},
{"testId":6,"qid":268,"description":"What is the meaning of this sign?","explanation":"In front of a railroad crossing, this sign shows the symbol \"R X R.\" It might also be painted on the pavement and accompanied with a stop line. While waiting for a train, vehicles must remain behind the marked stop line.","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#19179#23961#23962","text":"There is a railroad crossing ahead#Traffic is merging ahead#Vehicles must slow down for pedestrians#There is a forest zone ahead"},
{"testId":6,"qid":269,"description":"As the size of the buses and trucks is large, they may move________ to make a right turn.","explanation":"Due of their size, trucks and buses frequently need a left lane move in order to make a right turn. Cutting in front of the vehicle or between it and the curb increases the risk of a collision.","imageName":"","correctAnswer":8491,"isBookmarked":0,"aid":"877#8490#8491#8492","text":"beyond the intersection#into the right lane#into the left lane#onto the shoulder"},
{"testId":6,"qid":270,"description":"The penalty for driving while intoxicated with a blood alcohol level (BAC) of 0.08 percent is between:","explanation":"For the first offense, the punishment for drinking and driving with a blood alcohol content (BAC) of 0.08 percent is between $600 and $1000. The fine for the second infraction might reach $1500.","imageName":"","correctAnswer":23963,"isBookmarked":0,"aid":"23963#23964#23965#23966","text":"$600 and $1000 for the first offense#$600 and $2000 for the second offense#$100 and $200 for the first offense#$500 and $700 for the second offense"},
{"testId":6,"qid":271,"description":"Which one of the following is correct?","explanation":"If it is safe to do so, broken white lines indicate that you may change lanes.","imageName":"","correctAnswer":23969,"isBookmarked":0,"aid":"23967#23968#23969#23970","text":"Solid yellow lines indicate that only yellow school buses are allowed to cross them#Double solid yellow lines indicate that passing is allowed#A broken white line indicates that you may change lanes if it is safe to do so#Solid white lines indicate the beginning of a highway"},
{"testId":6,"qid":272,"description":"This orange triangular reflecting sign represents:","explanation":"A triangular orange reflector sign is put on the back of a vehicle traveling at less than 25 mph.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22518#23971#23972","text":"a slow-moving vehicle#a fast-moving vehicle#a vehicle carrying dangerous goods#a heavy truck"},
{"testId":6,"qid":273,"description":"When traveling in rural regions, you must pay special attention to:","explanation":"There are various particular scenarios that demand your attention while driving in rural or country locations. You can come upon an unmarked railroad crossing or a concealed crossroads.","imageName":"","correctAnswer":9145,"isBookmarked":0,"aid":"9145#9146#9148#23973","text":"unmarked railroad crossings and hidden intersections#steep hills#sunset and sunrise conditions#acceleration ramps"},
{"testId":6,"qid":274,"description":"All trucks and buses have _______","explanation":"Side and rear blind areas are greater on trucks and buses. Tailgating a truck makes it hard for the truck driver to see you. To prevent an accident in the vehicle's blind area, pass a truck as quickly as feasible.","imageName":"","correctAnswer":23974,"isBookmarked":0,"aid":"8626#23974#23975#23976","text":"None of the above#front, side, and rear blind spots#front and rear blind spots#front and side blind spots"},
{"testId":6,"qid":275,"description":"A loaded truck moving at 55 mph on a clear, dry highway with decent tires and properly calibrated brakes requires a minimum of _______ to come to a complete stop.","explanation":"A loaded truck moving at 55 mph on a clear, dry road with decent tires and properly calibrated brakes needs at least 290 feet to come to a full stop. [Colorado Driver Handbook, Section 13.1 Large trucks and buses]","imageName":"","correctAnswer":900,"isBookmarked":0,"aid":"399#898#899#900","text":"200 feet#250 feet#280 feet#290 feet"},
{"testId":6,"qid":276,"description":"What does this image represent?","explanation":"This image depicts the most efficient location of the hands on the steering wheel of a car without airbags (10 o'clock and 2 o'clock).","imageName":"202005140338434562.jpg","correctAnswer":23978,"isBookmarked":0,"aid":"902#904#23977#23978","text":"The recommended position of the hands on the steering wheel while turning#The recommended position of the hands on the steering wheel while changing lanes#The recommended position of the hands on the steering wheel of a vehicle with airbags#The recommended position of the hands on the steering wheel of a vehicle without airbags"},
{"testId":6,"qid":277,"description":"To ensure a safe following distance, drivers must adhere to:","explanation":"To maintain a safe following distance from the car ahead, drivers must observe the three-second rule under typical conditions: 1) Choose a landmark on the road ahead, such as a road sign or a telephone pole. 2) Begin counting \"one thousand one, one thousand two, one thousand three\" when the back of the car ahead crosses the marker. 3) You are following the car ahead too closely if the front of your vehicle touches the marker before you complete counting.","imageName":"","correctAnswer":531,"isBookmarked":0,"aid":"531#532#533#534","text":"three-second rule#two-second rule#four-second rule#five-second rule"},
{"testId":6,"qid":278,"description":"If you are within 200 feet of the vehicle ahead of you, you must use your ______ with your fog lights turned off.","explanation":"If you are within 200 feet of another vehicle, you must use your low-beam headlights with your fog lights turned off while following.","imageName":"","correctAnswer":294,"isBookmarked":0,"aid":"294#295#296#297","text":"Low-beam headlights#High-beam headlights#parking lights#flashing lights"},
{"testId":6,"qid":279,"description":"When overtaking or passing a bike, motorists must provide a minimum of ___  of space.","explanation":"Bicyclists and pedestrians are more susceptible road users, and they may shift their positions to avoid road dangers. When overtaking or passing a bike, motorists must leave at least a three-foot gap.","imageName":"","correctAnswer":23980,"isBookmarked":0,"aid":"23979#23980#23981#23982","text":"two feet#three feet#four feet#one foot"},
{"testId":6,"qid":280,"description":"What is the meaning of this sign?","explanation":"This is a Don't Walk sign that may be seen at junctions. When this sign is shown, pedestrians are not permitted to cross the roadway.","imageName":"202004071542087933.jpg","correctAnswer":19217,"isBookmarked":0,"aid":"19215#19216#19217#19218","text":"Do not merge#Do not pass#Do not walk#Do not make a right turn"},
{"testId":7,"qid":281,"description":"Signs with vertical rectangular black letters on a white background are :","explanation":"To manage traffic, vertical rectangular signs with black characters on a white backdrop give information about traffic rules. Some examples are speed limit signs and lane control signs.","imageName":"202004071544201833.jpg","correctAnswer":8876,"isBookmarked":0,"aid":"8874#8876#38132#38134","text":"warning signs#regulatory signs#destination signs#service signs"},
{"testId":7,"qid":282,"description":"What is the meaning of this sign?","explanation":"Regulatory signs indicate what drivers may and may not do. The arrow on this regulatory sign indicates a right turn. A red slash within a red circle, on the other hand, implies \"no.\" This regulation sign forbids automobiles from turning right. Regulatory signs are often seen on the right side of the road or above a driving lane. In addition to the sign, arrows are sometimes painted on the road.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"30162#40267#40269#41553","text":"The right side of the road is closed#You may not turn left#You may not turn right#You may not merge right"},
{"testId":7,"qid":283,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22423#22424#22426#27864","text":"A gravel road is ahead#A winding road is ahead#The road ahead is slippery when wet#A curved road is ahead"},
{"testId":7,"qid":284,"description":"What is the meaning of this sign?","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you have the option of going straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":38498,"isBookmarked":0,"aid":"30133#38498#40024#40732","text":"You must go straight#You may either go straight or turn left#You must turn left if you are keeping left#You must merge left if you are keeping left"},
{"testId":7,"qid":285,"description":"What does this sign probably mean?","explanation":"This sign alerts you to a change in direction or a road narrowing. Several of these signs may be found on the outside of a sharp curve or on approaches to a narrow bridge.","imageName":"202003301742532030.jpg","correctAnswer":41611,"isBookmarked":0,"aid":"132#22176#33028#41611","text":"The road ahead is closed#Take a detour#There is merging traffic ahead#The road ahead curves sharply"},
{"testId":7,"qid":286,"description":"This symbol indicates that you are getting close to:","explanation":"This sign forewarns of a four-way junction ahead. Another path will intersect yours. Slow down, take a peek to the left and right, and get ready to enter the crossroads.","imageName":"202002101146277756.jpg","correctAnswer":9152,"isBookmarked":0,"aid":"9150#9151#9152#9211","text":"a hospital#a divided highway#a crossroad#a railroad crossing"},
{"testId":7,"qid":287,"description":"What is the meaning of this sign?","explanation":"This is a route marker sign for the United States. A route marker sign's form and color identify the sort of road you're on: interstate, U.S., state, and so on. Shield-shaped signs designate US Routes and interstate routes. This sign shows that you are on US Highway 40. The United States Routes are a network of roads and highways that were built decades before the Interstate Highway System. US Route 40 was built in 1926 and goes from Silver Summit, Utah to Atlantic City, New Jersey.","imageName":"202004071606057403.jpg","correctAnswer":41249,"isBookmarked":0,"aid":"41246#41247#41248#41249","text":"The maximum speed limit is 40 mph#The distance to the state line or the start of the road is 40 miles#You are approaching a junction with Interstate Route 40#You are on U.S. Route 40"},
{"testId":7,"qid":288,"description":"What do these road markers mean?","explanation":"This is a two-way left-turn lane with a shared center turning lane. This lane allows vehicles going in either direction to perform left turns (or U-turns where authorized). Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing. A solid yellow line and a dashed yellow line indicate either side of this lane. During rush hour, certain shared center lanes become reversible lanes. At such instances, be certain that entering the lane is safe.","imageName":"202001301806403967.jpg","correctAnswer":43367,"isBookmarked":0,"aid":"19229#19230#43367#43368","text":"This is a three-way road#This is a one-way road#Vehicles traveling in either direction can make left turns#Vehicles traveling in either direction can make right turns"},
{"testId":7,"qid":289,"description":"The number on this symbol denotes:","explanation":"This sign indicates an exit number. This is the number allocated to a highway exit at a junction. If an interchange has more than one exit, a letter may be appended to designate which exit it is: 44A, 44B, and so on. You can check how far you are from the exit you wish to take by entering the milepost number and the exit number.","imageName":"202003301732572089.jpg","correctAnswer":19233,"isBookmarked":0,"aid":"19228#19233#19234#19235","text":"a US route marker#an exit number#the number of miles to the exit#the speed limit on the exit ramp"},
{"testId":7,"qid":290,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a double bend ahead. The road turns to the right and then to the left ahead. (A winding road sign would be placed instead if there was a triple bend coming.) Slow down, stay to the right, and do not pass.","imageName":"202002061305216978.jpg","correctAnswer":28727,"isBookmarked":0,"aid":"22424#28726#28727#39745","text":"A winding road is ahead#The road ahead curves to the right#The road ahead curves to the right and then to the left#Sharp right and left turns are ahead"},
{"testId":47,"qid":290,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a double bend ahead. The road turns to the right and then to the left ahead. (A winding road sign would be placed instead if there was a triple bend coming.) Slow down, stay to the right, and do not pass.","imageName":"202002061305216978.jpg","correctAnswer":28727,"isBookmarked":0,"aid":"22424#28726#28727#39745","text":"A winding road is ahead#The road ahead curves to the right#The road ahead curves to the right and then to the left#Sharp right and left turns are ahead"},
{"testId":7,"qid":291,"description":"To pass the DMV vision exam, you must have _____ or superior visual acuity.","explanation":"The minimum acceptable vision for the DMV vision test is 20/40 with or without corrective lenses. If one eye is blind, the other eye must have visual acuity of 20/40 or greater and a horizontal field of view of at least 100 degrees.","imageName":"","correctAnswer":14054,"isBookmarked":0,"aid":"14053#14054#43109#43842","text":"20/20#20/40#20/30#20/50"},
{"testId":7,"qid":292,"description":"If your driver's license requires you to wear corrective lenses and you are stopped by a police officer while not wearing them,:","explanation":"You may be given a penalty if your driver's license requires you to wear corrective glasses and you are stopped by a law enforcement officer while not wearing them.","imageName":"","correctAnswer":43845,"isBookmarked":0,"aid":"43843#43844#43845#43846","text":"you'll be let off with a warning#your driver's license may be suspended#you may be issued a citation#you may be required to submit to a DMV vision test"},
{"testId":7,"qid":293,"description":"Except for church, school, job, medical necessity, or emergency, a licensed driver under the age of 18 may not drive between:","explanation":"A licensed motorist under the age of 18 may not drive after 11 p.m. and 5 a.m. except for church, school, employment, medical necessity, or emergencies; or if the person is a Safe Ride Program assigned driver.","imageName":"","correctAnswer":953,"isBookmarked":0,"aid":"953#954#955#956","text":"11 p.m. and 5 a.m#10 p.m. and 6 a.m#11 p.m. and 6 a.m#10 p.m. and 5 a.m"},
{"testId":7,"qid":294,"description":"If your vehicle fails an emissions inspection, you will have _______ to correct the problem and have the emissions assessed again.","explanation":"An emissions check at an approved inspection facility may be necessary for your vehicle. If the vehicle fails the inspection, you will be given 60 days to rectify the issue and have the emissions rechecked.","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"742#1020#12360#18239","text":"30 days#60 days#7 days#14 days"},
{"testId":7,"qid":295,"description":"Which one of the following is NOT TRUE about turning?","explanation":null,"imageName":"","correctAnswer":43849,"isBookmarked":0,"aid":"24004#43847#43848#43849","text":"If your turn signals are not working, use hand signals#Try to signal at least three seconds before you make a turn#If you intend to turn just after an intersection, wait until you reach the intersection before signaling#You don't have to signal if you don't see anyone else around"},
{"testId":7,"qid":297,"description":"What should you do if your vehicle lacks antilock braking (ABS) and your brakes fail while you're driving?","explanation":"Whether your car has antilock brakes (ABS), push the brake pedal hard and watch to see if your vehicle slows down. If your car lacks ABS, try pumping the brake pedal to increase braking pressure. If the brakes continue to fail, try carefully using the parking brake to avoid locking the wheels and causing a skid. If it doesn't work, try shifting into a lower gear (even if you have an automatic gearbox) to slow down your car. Then seek for a safe area to pull over, get off the road, and phone for assistance. Do not attempt to drive to get help.","imageName":"","correctAnswer":43850,"isBookmarked":0,"aid":"24007#28454#28664#43850","text":"Pump the brake pedal several times#Apply the parking brake#Shift into lower gears#Do any of the above until your vehicle stops"},
{"testId":7,"qid":298,"description":"You are not permitted to park _______ in Connecticut.","explanation":"You may not park within 25 feet of a pedestrian safety zone, 25 feet of a stop sign, or 10 feet of a fire hydrant in Connecticut. (Please keep in mind that there are other areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.)","imageName":"","correctAnswer":24010,"isBookmarked":0,"aid":"24010#24011#24012#24013","text":"within 25 feet of a pedestrian safety zone#within 30 feet of a pedestrian safety zone#within 50 feet of a stop sign#within 15 feet of a fire hydrant"},
{"testId":7,"qid":299,"description":"A driver must be at least 18 years old in Connecticut:","explanation":"Except in an emergency or while the vehicle is securely parked, a driver may not use a hand-held cell phone or hand-held mobile electronic device while driving in Connecticut. The motorist may use a hands-free device if he or she is above the age of 18.","imageName":"","correctAnswer":43852,"isBookmarked":0,"aid":"43851#43852#43853#43854","text":"may use a hand-held cell phone while driving#may use a hands-free cell phone while driving#may use a hand-held cell phone while stopped at a red light#may use a hand-held cell phone if traffic is at a standstill"},
{"testId":7,"qid":300,"description":"Which of the following things about seat belts is NOT TRUE?","explanation":null,"imageName":"","correctAnswer":38794,"isBookmarked":0,"aid":"9056#9057#30280#38794","text":"They keep you from being thrown from the car#They slow your body down with your car#They should be worn low and snug on the hips and tight across the shoulder#You will be trapped after an accident if you wear a seat belt"},
{"testId":7,"qid":301,"description":"When going around sharp turns, turn the steering wheel by:","explanation":"When turning a sharp bend, utilize the hand-over-hand steering wheel method. If you spin the wheel with just one hand, you may lose control. Straighten out the steering wheel by hand after you've completed the turn. Allowing it to slip between your fingers might be risky.","imageName":"","correctAnswer":22185,"isBookmarked":0,"aid":"22185#24022#24023#24024","text":"hand-over-hand technique#one-hand technique#push/pull technique#hands-free technique"},
{"testId":7,"qid":302,"description":"Drivers who are more cautious tend to gaze at least ________ ahead of their vehicles.","explanation":"Drivers who are more cautious tend to glance at least 12 seconds ahead of their cars. This is the distance traveled by a vehicle in 12 seconds. In city driving, 12 seconds equals about one block. On the highway, 12 seconds equals about four city blocks (or a quarter of a mile).","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#985#986#987","text":"5 seconds#10 seconds#12 seconds#15 seconds"},
{"testId":7,"qid":304,"description":"If you see or hear an emergency vehicle approaching from any direction with a siren, air horn, or a red or blue flashing light, you must :","explanation":"A police car, fire truck, ambulance, or other emergency vehicle with an air horn, siren, or red or blue flashing light must always surrender the right of way. Before stopping, get clear of any intersections, and then stop as close to the right side of the road as you can. Till the emergency vehicle has passed or a police or fireman commands you to go on, stay halted.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"6475#10069#22546#24031","text":"Speed up#slow to 10 mph#pull over to the right edge of the road#pull over to the left edge of the road"},
{"testId":7,"qid":305,"description":"You risk impeding traffic if you enter a junction but there isn't enough room for your car on the other side of the intersection. __________ is the term used to describe this occurrence.","explanation":null,"imageName":"","correctAnswer":24032,"isBookmarked":0,"aid":"24032#24034#43855#43856","text":"blocking the box#blocking the way#blocking the cars#blocking the drivers"},
{"testId":7,"qid":306,"description":"Blind pedestrians may utilize _____ canes or guide dogs for help.","explanation":"Blind pedestrians may utilize white canes or guide dogs. Always cede the right-of-way to a blind pedestrian holding a white cane or accompanied by a guiding dog.","imageName":"","correctAnswer":702,"isBookmarked":0,"aid":"702#703#704#1000","text":"white#red#green#black"},
{"testId":7,"qid":307,"description":"When must you cede the right-of-way to a bike in Connecticut?","explanation":"When riding a bicycle on the road, it is considered a vehicle. Bicyclists have the same rights and obligations as drivers. You must give the right-of-way to a biker who is (1) approaching a junction, (2) in a bicycle lane that crosses your route, or (3) in a bicycle lane that you intend to cross. Bicycles and motor vehicles have the same right-of-way laws at junctions without signs or signals.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#43857#43858#43859","text":"In all of the above situations#When the bicyclist is in an intersection that you are approaching#When the bicyclist is in a bicycle lane that intersects your road#When the bicyclist is in a bicycle lane that you plan to turn across"},
{"testId":7,"qid":308,"description":"What should you do if a horse and rider are on the road?","explanation":"When approaching a horse being ridden or led along a road in Connecticut, you must use caution. You must drive at a slower pace and keep a safe distance from the horse. When approaching or passing a horse, it is prohibited to blast your horn.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#24042#43860#43861","text":"Reduce your speed#Increase your speed to pass the horse quickly#Shout a warning to the rider#Use your horn to alert the rider"},
{"testId":7,"qid":309,"description":"You should______,if you're being tailgated (following closely).","explanation":"If you are being tailgated, pull to the right and let the car in front of you to pass. Never accelerate to please or outdistance a tailgater. Some tailgaters believe that no speed is too fast.","imageName":"202003201758501730.jpg","correctAnswer":24045,"isBookmarked":0,"aid":"6475#24043#24044#24045","text":"Speed up#stop on the shoulder of the road#pull to the left#pull to the right"},
{"testId":7,"qid":310,"description":"Passing another vehicle safely at 55 mph on a two-lane road takes about ______","explanation":"You need roughly 10 seconds to pass at 55 mph. In order to pass, you thus need a 10-second gap in incoming traffic as well as sight distance. You must determine whether there will be sufficient room to pass securely. You'll cover more than 800 feet in 10 seconds at 55 mph.","imageName":"","correctAnswer":985,"isBookmarked":0,"aid":"427#985#986#1012","text":"5 seconds#10 seconds#12 seconds#14 seconds"},
{"testId":7,"qid":311,"description":"If your tires appear to be losing traction on the road, you should ________.","explanation":"If you feel like your tires are losing grip on the road, take your foot off the throttle pedal and maintain the steering wheel straight. Except in an emergency, do not turn. (If you have to turn, do it carefully or your vehicle may slide.) Do not attempt to stop or turn until your tires have regained traction on the road.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"8616#8617#8618#10656","text":"Ease your foot off the gas pedal#keep the steering wheel straight#not stop until your tires are gripping the road again#Do all of the above"},
{"testId":7,"qid":312,"description":"If a flashing yellow light is at a crossroads, you must :","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you encounter a flashing yellow light at a junction.","imageName":"","correctAnswer":9181,"isBookmarked":0,"aid":"9181#24046#24047#34708","text":"slow down and proceed with caution#come to a full stop and proceed when it is safe to do so#stop and change lanes#increase your speed and proceed through the intersection"},
{"testId":7,"qid":314,"description":"A dashed yellow line next to a solid yellow line implies:","explanation":"A dashed yellow line next to a solid yellow line indicates that passing is allowed on the dashed line but not on the solid line.","imageName":"","correctAnswer":33356,"isBookmarked":0,"aid":"22742#22745#33355#33356","text":"passing is not permitted on either side#passing is permitted on both sides#passing is permitted on the side of the solid line, but not on the side of the dashed line#passing is permitted on the side of the dashed line, but not on the side of the solid line"},
{"testId":7,"qid":315,"description":"You are not permitted to park within _______ of a fire hydrant in Connecticut.","explanation":"You may not park within 25 feet of a pedestrian safety zone, 25 feet of a stop sign, or 10 feet of a fire hydrant in Connecticut. (Please keep in mind that there are other areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.)","imageName":"","correctAnswer":1028,"isBookmarked":0,"aid":"214#1026#1027#1028","text":"100 feet#50 feet#25 feet#10 feet"},
{"testId":7,"qid":316,"description":"When driving at night with no other vehicles within 500 feet, you should:","explanation":null,"imageName":"","correctAnswer":22735,"isBookmarked":0,"aid":"22735#24055#29041#43862","text":"use your high-beam headlights#use your parking lights#use your emergency flashers#use your low-beam headlights"},
{"testId":7,"qid":317,"description":"Which of the following is NOT TRUE about train crossings?","explanation":"You have to wait till there is space for your car on the other side of the railroad crossing. When a train is crossing, you must yield. Never halt on the rails. It's best to avoid changing gears while crossing the rails since it might cause your car to stall.","imageName":"202003161136554337.jpg","correctAnswer":8720,"isBookmarked":0,"aid":"8720#8721#29214#34694","text":"It is wise to shift gears when crossing railroad tracks#It is always important to check for more than one track before crossing#You must yield to crossing trains#Never start to cross if there isn't room for your vehicle on the far side"},
{"testId":8,"qid":317,"description":"Which of the following is NOT TRUE about train crossings?","explanation":"You have to wait till there is space for your car on the other side of the railroad crossing. When a train is crossing, you must yield. Never halt on the rails. It's best to avoid changing gears while crossing the rails since it might cause your car to stall.","imageName":"202003161136554337.jpg","correctAnswer":8720,"isBookmarked":0,"aid":"8720#8721#29214#34694","text":"It is wise to shift gears when crossing railroad tracks#It is always important to check for more than one track before crossing#You must yield to crossing trains#Never start to cross if there isn't room for your vehicle on the far side"},
{"testId":7,"qid":319,"description":"The appropriate left turn hand signal is:","explanation":"The hand and arm extended upward is the proper hand signal for a right turn.","imageName":"","correctAnswer":22128,"isBookmarked":0,"aid":"22127#22128#22129#22130","text":"hand and arm extended downward#hand and arm extended upward#hand and arm extended outward#hand and arm extended backward"},
{"testId":7,"qid":320,"description":"Which of the following is NOT a rule about right-of-way?","explanation":"Give way to right-turning traffic at uncontrolled intersections (those without traffic lights or signs).","imageName":"","correctAnswer":33819,"isBookmarked":0,"aid":"8493#8496#33818#33819","text":"You must obey signals given by a law enforcement officer even if the officer's signals contradict the traffic signals#Pedestrians using a guide dog or carrying a white cane have the absolute right-of-way#You must yield the right-of-way to pedestrians who are in a marked or unmarked crosswalk#At an intersection where there are no traffic signs or signals, you must yield to vehicles coming from the left"},
{"testId":8,"qid":322,"description":"A solid white line separating traffic lanes indicates that:","explanation":"A strong white line between traffic lanes indicates that you should remain in your lane unless there is an emergency that compels you to change lanes.","imageName":"202004281851466397.jpg","correctAnswer":24436,"isBookmarked":0,"aid":"24436#24437#24438#24439","text":"you should stay in your lane#you should reduce your speed#you may cross it to change lanes if it is safe to do so#you are allowed to make a U-turn"},
{"testId":8,"qid":323,"description":"You should never back up your vehicle in a traffic lane except:","explanation":"Backing up is risky since it is difficult for you to see everything behind your car and other drivers sometimes don't anticipate a car backing up in their direction. Refrain from backing into oncoming traffic. However, unless signs specifically state otherwise, reverse into any driveways or parallel parking spaces. In this manner, as you draw away, you will be moving ahead.","imageName":"","correctAnswer":24161,"isBookmarked":0,"aid":"6645#24160#24161#35407","text":"At intersections#to make a U-turn#to parallel park#at lane crossings and on curves"},
{"testId":8,"qid":324,"description":"What is the meaning of this sign?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians.","imageName":"202003070834498321.jpg","correctAnswer":35385,"isBookmarked":0,"aid":"19241#19243#35384#35385","text":"Passing is prohibited#Pedestrians are allowed to pass on the left#Vehicles going straight through or turning left must prepare to stop#Vehicles making left turns may proceed if it's safe, but all other vehicles must stop"},
{"testId":8,"qid":325,"description":"The goal of deploying traffic cameras at busy Delaware crossings is to cite drivers who:","explanation":"Delaware's Red Light Reinforcement Program keeps an eye out for vehicles who run red lights at congested junctions. They are photographed, their car tag numbers are logged, and citations are instantly sent through USPS.","imageName":"","correctAnswer":43321,"isBookmarked":0,"aid":"38057#43321#43322#43323","text":"exceed the speed limit#run red lights#fail to yield the right-of-way#fail to signal turns in advance"},
{"testId":8,"qid":326,"description":"In Delaware, you are not compelled to stop for a school bus that has come to a stop on the opposite side of the road in:","explanation":"You must normally stop for a school bus with flashing red lights, regardless of which side of the road the bus is on. In Delaware, however, there is an exception: you do not have to stop if the bus is stopped on the other side of a four-lane highway. Even so, you should take your time and approach with prudence. Because children may not understand the laws of the road, they may do something unexpected.","imageName":"","correctAnswer":43325,"isBookmarked":0,"aid":"43324#43325#43326#43327","text":"at least two lanes#at least four lanes#at least three lanes#a shared center lane"},
{"testId":8,"qid":327,"description":"At an intersection with no signs or signals, you must yield to:","explanation":"Drivers must yield to cars coming from the right while approaching an uncontrolled junction (one without signs or signals). However, rather than risking an accident, always consider ceding to other cars.","imageName":"","correctAnswer":8522,"isBookmarked":0,"aid":"8522#8523#8524#8525","text":"vehicles coming from the right#vehicles coming from the left#vehicles passing straight ahead#vehicles approaching from behind"},
{"testId":8,"qid":329,"description":"On multilane roadways, the leftmost lane is designated for:","explanation":"The leftmost lane on multilane roadways is for overtaking slower cars. If you pass on the right, the other vehicle may not notice you and may abruptly change lanes in front of you.","imageName":"","correctAnswer":22740,"isBookmarked":0,"aid":"22738#22740#25916#42693","text":"passing faster vehicles#passing slower vehicles#passing emergency vehicles#carrying hazardous materials"},
{"testId":8,"qid":330,"description":"When you park on the street and leave your vehicle there, you should NOT:","explanation":"When parking and leaving your car on the street, turn off the engine, remove the ignition key, and engage the parking brake. Close the windows and lock the doors as well.","imageName":"","correctAnswer":24083,"isBookmarked":0,"aid":"24081#24082#24083#38618","text":"set the parking brake#stop the engine#lower the windows#remove the ignition key"},
{"testId":8,"qid":331,"description":"You are not permitted to park _______ in Delaware.","explanation":"You may not park within 15 feet of a fire hydrant, 50 feet of a railroad crossing, or 30 feet of a stop sign or traffic signal in Delaware. A yellow curb is similar to a No Parking sign. You are also not permitted to park there.","imageName":"","correctAnswer":43328,"isBookmarked":0,"aid":"24012#28020#31559#43328","text":"within 50 feet of a stop sign#Within 100 feet of a railroad crossing#within 30 feet of a fire hydrant#at a curb painted yellow"},
{"testId":8,"qid":332,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":43330,"isBookmarked":0,"aid":"43329#43330#43331#43332","text":"Vehicles must keep left#Vehicles must keep right#Vehicles must merge right#Vehicles must make a sharp right turn"},
{"testId":8,"qid":333,"description":"If a truck is attempting to pass you, you can assist the driver by:","explanation":"If a truck is attempting to pass you, you may assist the truck driver by staying to the far side of your lane and slowing down somewhat. Never accelerate while a car is attempting to pass you. When possible, avoid driving in the No-Zones of heavy vehicles.","imageName":"","correctAnswer":25929,"isBookmarked":0,"aid":"7857#25929#25930#43333","text":"increasing your speed#keeping to the far side of your lane#keeping to the near side of your lane#turning on your emergency flashers"},
{"testId":8,"qid":336,"description":"What is the meaning of this sign?","explanation":"This sign may be seen at the end of various T-intersections. It indicates you have to cede the right-of-way before turning right or left onto the through route.","imageName":"202003301727587672.jpg","correctAnswer":41563,"isBookmarked":0,"aid":"22656#25937#41563#43338","text":"A divided highway starts ahead#The roadway is closed on both sides#You must yield and then turn right or left#You may bypass an upcoming obstacle on either side"},
{"testId":8,"qid":338,"description":"You should avoid driving alongside other vehicles on multilane highways because:","explanation":"Avoid driving alongside other cars on multilane highways. Someone may attempt to jam your lane or change lanes and drive into you.","imageName":"202007152024341579.jpg","correctAnswer":25941,"isBookmarked":0,"aid":"25938#25939#25940#25941","text":"you may not see the vehicles following you#you may not see the vehicles in front of you#someone may hit you from behind#someone may crowd your lane"},
{"testId":8,"qid":339,"description":"You should______,if you're being tailgated (following closely).","explanation":"If you're being tailgated and there's a right lane, move into it to let the tailgater to pass. If there is no right lane, wait until the road ahead is free, then gradually lower your speed. This will encourage the tailgater to pass you. Never accelerate to please or outdistance a tailgater. Some tailgaters believe that no speed is too fast.","imageName":"202003201758501730.jpg","correctAnswer":43339,"isBookmarked":0,"aid":"6515#25945#43339#43340","text":"Increase your speed#reduce your speed quickly#move into the right lane if it is available#move into the left lane if it is available"},
{"testId":8,"qid":340,"description":"What is the meaning of this sign?","explanation":"Instead, this sign signals a deer crossing. Deer are most active at night and early in the morning. Keep an eye out for deer on both sides of the road and slow down if you come across one. Herds of deer, elk, and other species roam in groups. If you spot one, keep an eye out for others.","imageName":"202002101200345356.jpg","correctAnswer":1237,"isBookmarked":0,"aid":"149#173#1237#40538","text":"Forest zone ahead#Veterinary hospital ahead#Deer crossing#Farm ahead"},
{"testId":8,"qid":341,"description":"Which of the following you must use, if you are in heavy traffic or following another vehicle at night?","explanation":"Use your low lights at night in congested areas, while pursuing or merging with another vehicle. When you are within 200 feet of a vehicle you are following or within 500 feet of an approaching vehicle, you are required by Delaware law to turn your headlights to low beam.","imageName":"","correctAnswer":25951,"isBookmarked":0,"aid":"25950#25951#30262#30263","text":"High beams#Low beams#Emergency flashers#Interior lights"},
{"testId":8,"qid":342,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":"Sometimes you have to deal with risks one by one. If you are going to pass a bicycle and an incoming vehicle approaches, slow down and let the car pass first to give the cyclist more space. Then move to the left to provide enough space to pass the bicycle. When passing a bicycle in Delaware, you must allow at least three feet of space between your vehicle and the bicycle.","imageName":"202007151849158328.jpg","correctAnswer":30622,"isBookmarked":0,"aid":"30622#30623#30625#32173","text":"Slow down and let the vehicle pass first#Speed up and quickly pass the bicycle#Alert the oncoming vehicle to slow down#Alert the bicyclist to the oncoming vehicle"},
{"testId":8,"qid":343,"description":"When a driver in front of you wishes to pass, you should:","explanation":"If a motorist behind you want to pass, gradually slow down to provide greater room in front of your car. This will allow that driver to finish the pass faster and give him or her more space to safely merge back in front of your car.","imageName":"","correctAnswer":43341,"isBookmarked":0,"aid":"25956#25959#43341#43342","text":"accelerate to leave more space behind your vehicle#move into the left lane to allow the driver to pass#slow down to leave more space in front of your vehicle#stop to allow the driver to pass"},
{"testId":8,"qid":344,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to come to a halt and surrender. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2515#3572#41364","text":"A stop sign ahead#A yield sign ahead#A work zone ahead#A merge sign ahead"},
{"testId":8,"qid":345,"description":"The Delaware Driver Manual suggests following the three-second rule to avoid:","explanation":"Tailgating is a common cause of rear-end crashes (following too closely). The car ahead often brakes quickly, and the tailgater does not have enough space to avoid crashing with it. To prevent this, the Delaware Driver Manual suggests keeping a three-second following distance in good driving circumstances and a wider following distance in unfavorable driving conditions.","imageName":"","correctAnswer":26280,"isBookmarked":0,"aid":"8583#26280#43343#43344","text":"Hydroplaning#rear-end collisions#glare#skidding"},
{"testId":8,"qid":348,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending school zone. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there.","imageName":"202003151740461521.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1252#1346#1347#43348","text":"A school zone ahead#A crosswalk ahead#A library ahead#The end of a school zone"},
{"testId":8,"qid":350,"description":"Passing another vehicle safely at 55 mph on a two-lane road takes about ______","explanation":"You need around 10 seconds to safely pass another car at 55 mph. You'll cover more than 800 feet in 10 seconds travelling at that pace.","imageName":"","correctAnswer":985,"isBookmarked":0,"aid":"401#985#2780#2781","text":"400 feet#10 seconds#700 feet#600 feet"},
{"testId":8,"qid":351,"description":"If you're driving at 55 mph, you shouldn't utilize the opposing lane to pass another vehicle if you're close to_______ from a hill or bend.","explanation":null,"imageName":"","correctAnswer":5535,"isBookmarked":0,"aid":"5532#5534#5535#37391","text":"half a mile#one mile#one-third of a mile#two miles"},
{"testId":8,"qid":353,"description":"While the vehicle is moving, if you turn your ignition key to the lock position,:","explanation":"When your car is in motion, never turn your ignition key to the lock position. As a consequence, the steering wheel will lock, resulting in loss of control. Since 1969, the steering lock has been standard equipment. Its purpose is to prevent a burglar from directing your automobile if he starts it without the key.","imageName":"","correctAnswer":43350,"isBookmarked":0,"aid":"43349#43350#43351#43352","text":"the brakes will fail#the steering wheel will lock#the engine will fail#the gas pedal will lock"},
{"testId":8,"qid":355,"description":null,"explanation":null,"imageName":"","correctAnswer":43354,"isBookmarked":0,"aid":"43218#43220#43353#43354","text":"Pedestrians may leave the curb to cross the street#The signal is out of order#Pedestrians should not start to cross the street, but those who have already started to cross should finish crossing#Pedestrians should not cross the street"},
{"testId":8,"qid":356,"description":"What is the meaning of this sign?","explanation":"One or more lanes on certain roads may be designated for specific types of vehicles. To show that a lane is reserved, white diamond marks are usually painted on the pavement. This sign shows that the lane is allocated for high-occupancy vehicles (HOVs) Monday through Friday between 3:30 p.m. and 6:00 p.m.","imageName":"202004150006034023.jpg","correctAnswer":43355,"isBookmarked":0,"aid":"43355#43356#43357#43358","text":"This lane is reserved for high-occupancy vehicles at specified times#This lane is reserved for trucks at specified times#High-occupancy vehicles are prohibited in this lane at specified times#Cars and buses are prohibited in this lane at specified times"},
{"testId":8,"qid":357,"description":"At 0.06 percent BAC, your chances of being involved in an accident are ________ as if you were sober.","explanation":"According to the Delaware Driver Manual, a blood alcohol content (BAC) of 0.06 percent increases your chances of being involved in an accident by half. It is worth noting that 0.06 percent is less than the legal adult limit of 0.08 percent. According to studies, even with a BAC of less than 0.05 percent, driving abilities begin to decline.","imageName":"","correctAnswer":43359,"isBookmarked":0,"aid":"5343#43359#43360#43361","text":"the same#twice as high#three times as high#four times as high"},
{"testId":8,"qid":358,"description":"Large flashing arrow panels may be used in work zones to help drivers get into:","explanation":"Large LED flashing arrow panels may be deployed in work zones at any time of day or night to direct cars into certain traffic lanes. These panels are installed on the road or mounted on vehicles to warn approaching motorists of lane closures.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#8774#28712#43362","text":"Guide drivers into certain traffic lanes#Divert drivers into work zones#Guide drivers into certain parking areas#guide drivers into rest areas"},
{"testId":8,"qid":359,"description":"Unless otherwise indicated, the speed limit ________ in Delaware is 20 miles per hour.","explanation":"Never go faster than the stated speed limit. Except as otherwise stated, the speed limit in Delaware school zones is 20 mph.","imageName":"","correctAnswer":2511,"isBookmarked":0,"aid":"2511#2513#34624#43363","text":"in school zones#in residential districts#in business districts#on two-lane roads"},
{"testId":8,"qid":360,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automated transmission, change into a low gear to avoid fading (losing effectiveness) of your brakes due to misuse.","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"15602#31340#31341#42521","text":"Only trucks can use the road ahead; find another route#There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":10,"qid":362,"description":"This symbol denotes:","explanation":"This sign notifies you that the one-way street you're on will be split into two lanes. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101251206942.jpg","correctAnswer":33775,"isBookmarked":0,"aid":"8621#8622#33775#33776","text":"merging traffic ahead#a divided highway ahead#the beginning of a two-way road#the beginning of a one-way road"},
{"testId":10,"qid":363,"description":"If you see this sign, you must:","explanation":"On a regulatory sign, a red slash within a red circle denotes \"no.\" This sign says that U-turns are banned here.","imageName":"202003151744407638.jpg","correctAnswer":22119,"isBookmarked":0,"aid":"28#8992#22119#31277","text":"Make a left turn#make a U-turn#not make a U-turn#not make a left turn"},
{"testId":10,"qid":364,"description":"If you see this sign on a limited-access highway, you should:","explanation":"This sort of sign is often spotted near highway exits. It specifies the maximum safe speed (in this example, 30 mph) under perfect driving circumstances. Slow down below this suggested speed in bad or dangerous driving conditions.","imageName":"202004071518063006.jpg","correctAnswer":41726,"isBookmarked":0,"aid":"19253#41007#41726#41727","text":"increase your speed to 30 mph and pass the vehicle in front of you#drive another 30 miles to the next exit#not exceed 30 mph when exiting the highway#drive at over 30 mph when exiting the highway"},
{"testId":10,"qid":365,"description":"Which of the following signs directs you to a hospital?","explanation":"White lettering or symbols are shown on a blue backdrop on service signs. These signs indicate the whereabouts of different amenities such as petrol stations, restaurants, accommodation, and hospitals. A service sign with the letter \"H\" denotes the presence of a hospital ahead.","imageName":"202003301712515520.jpg","correctAnswer":323,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":10,"qid":366,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Before beginning the descent, check your brakes. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":41730,"isBookmarked":0,"aid":"24470#41728#41729#41730","text":"Trucks are not allowed on this route#Trucks carrying heavy loads are not allowed#Watch for trucks entering the roadway#The roadway goes downhill ahead"},
{"testId":10,"qid":367,"description":"What is the meaning of this sign?","explanation":"A diamond-shaped pavement marker denotes that this lane is dedicated for certain purposes or vehicles, such as buses or carpool cars during rush hour traffic.","imageName":"202003301716013379.jpg","correctAnswer":19257,"isBookmarked":0,"aid":"19257#19259#30891#30893","text":"The lane is reserved for certain purposes or certain vehicles#The lane is reserved for right turns#This lane has toll booths ahead#This lane may be used by vehicles in both directions to make left turns"},
{"testId":10,"qid":369,"description":"What is the meaning of this sign?","explanation":"This is a warning sign indicating the presence of a small bridge or overpass ahead. The bridge or overpass is broad enough for two lanes of traffic but has very little clearance. Maintain your lane and keep an eye out for incoming vehicles.","imageName":"202002101245562248.jpg","correctAnswer":1527,"isBookmarked":0,"aid":"1527#1701#3176#4416","text":"A narrow bridge ahead#A railroad crossing ahead#A one-way road ahead#A hill ahead"},
{"testId":10,"qid":371,"description":"This symbol denotes:","explanation":"This work zone sign indicates that there will be a flagger ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through certain locations, they wear orange vests, shirts, or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":41163,"isBookmarked":0,"aid":"8895#27013#31299#41163","text":"a school zone ahead#an emergency vehicle ahead#a work crew ahead#a flagger ahead"},
{"testId":10,"qid":372,"description":"What is the meaning of this sign?","explanation":"This is a crossing sign for animals. The animal shown on the sign (in this example, a deer) is prevalent in this region. Keep an eye out for these creatures crossing the road, especially at dawn and twilight. Herds of deer, elk, and other species roam in groups. If you spot one, keep an eye out for others. A collision with a big animal has the potential to kill the animal, damage your car, and perhaps hurt someone in your vehicle.","imageName":"202002101200345356.jpg","correctAnswer":1237,"isBookmarked":0,"aid":"175#1237#40538#41732","text":"Zoo ahead#Deer crossing#Farm ahead#Cattle crossing"},
{"testId":10,"qid":373,"description":"What is the meaning of this sign?","explanation":"This sign indicates a bicycle crossing. This sign advises you that a bikeway will cross the road ahead.","imageName":"202002101148377306.jpg","correctAnswer":40553,"isBookmarked":0,"aid":"40551#40553#40554#41733","text":"Bicyclists should not use this lane#A bikeway crosses the roadway ahead#A no-passing zone for bicyclists is ahead#Bicyclists may use the lane only where the sign is posted"},
{"testId":10,"qid":374,"description":"This symbol indicates that:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8526,"isBookmarked":0,"aid":"8526#30938#30939#41734","text":"there is a railroad crossing ahead#the road ahead is closed#the road ahead is under repair#there is a highway junction ahead"},
{"testId":10,"qid":375,"description":"This symbol alerts drivers to:","explanation":"This sign alerts vehicles to the presence of a divider or other impediment ahead. Continue straight.","imageName":"202003151752201841.jpg","correctAnswer":22593,"isBookmarked":0,"aid":"22593#41215#41216#41217","text":"keep right#enter a one-lane bridge#keep left at a roundabout#keep left on a one-way road"},
{"testId":10,"qid":376,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151740461521.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1252#1347#1945#2185","text":"A school zone ahead#A library ahead#A pedestrian crosswalk ahead#A bus stop ahead"},
{"testId":10,"qid":378,"description":"If you see this sign, you must:","explanation":"This sign indicates that the road ahead is slick in the rain. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. Roads are often slickest within the first half hour of rain. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6475#6476#27020#31595","text":"Speed up#Slow down#Turn left and then right#make sharp turns"},
{"testId":10,"qid":379,"description":"What is the meaning of this sign?","explanation":"This sign denotes a fork in the route. Another route runs parallel to the main road ahead. Slow down and keep an eye out for oncoming vehicles on the left and right.","imageName":"202002101146277756.jpg","correctAnswer":34717,"isBookmarked":0,"aid":"2528#9573#34717#41075","text":"A railroad crossing#A hospital#A crossroad#A crosswalk"},
{"testId":10,"qid":381,"description":"Except where otherwise posted, the speed limit in business and residential areas in Florida is:","explanation":"Except when otherwise marked, the speed limit in commercial and residential zones in Florida is 30 mph. Except when otherwise marked, the speed limit on rural roads is 55 mph.","imageName":"","correctAnswer":521,"isBookmarked":0,"aid":"521#1676#1677#1745","text":"30 mph#35 mph#45 mph#40 mph"},
{"testId":10,"qid":382,"description":"The appropriate left turn hand signal is:","explanation":"To indicate that you are going to turn, change lanes, or overtake a vehicle, you must employ hand signals or directional signals. Hand and arm extended upward is the proper hand signal for a right turn.","imageName":"","correctAnswer":22128,"isBookmarked":0,"aid":"22127#22128#22129#22130","text":"hand and arm extended downward#hand and arm extended upward#hand and arm extended outward#hand and arm extended backward"},
{"testId":10,"qid":384,"description":"A blind person's primary traveling aids include:","explanation":"A white cane and a trained guide dog are frequently the major traveling aids for a blind person. You must stop and surrender the right-of-way to blind pedestrians in Florida.","imageName":"","correctAnswer":22639,"isBookmarked":0,"aid":"22637#22638#22639#22640","text":"a white cane and a wheelchair#a red cane and a trained guide dog#a white cane and a trained guide dog#a red cane and a trained guide cat"},
{"testId":10,"qid":385,"description":"If you enter the main road from a driveway, alley, or roadside, you must:","explanation":"Drivers approaching a road from a driveway, alley, or the roadside must yield to cars on the main road as well as bikers and pedestrians on the sidewalk, shared-use path, or bike lanes.","imageName":"202006041755464618.jpg","correctAnswer":8933,"isBookmarked":0,"aid":"8930#8933#24343#26178","text":"Not make a right turn#Yield to vehicles already on the main road#reduce your speed and enter the main road#Enter the main road at increased speed"},
{"testId":10,"qid":386,"description":"Which of the following statements is NOT TRUE?","explanation":"Littering is an offense in Florida that carries a minimum fine of $100. You may also be required by the court to clear up rubbish near highways. When you collide with a vehicle, you must promptly notify the appropriate law enforcement agency. When backing up, rest your right arm on the back of the seat and spin around to have a clean view of the rear. Driving too slowly is also illegal. Always strive to keep up with traffic while without exceeding the speed limit.","imageName":"","correctAnswer":27026,"isBookmarked":0,"aid":"27025#27026#41736#41737","text":"When you back up, place your right arm on the back of the seat and turn around to look directly through the rear window#Very slow driving is considered safe and prevents many accidents on the highway#People who throw trash on public streets and highways can be fined at least $100#If you collide with an unoccupied vehicle, you must immediately notify law enforcement"},
{"testId":10,"qid":387,"description":"If this is your first offense, your license will be immediately suspended for _______ if you refuse to submit to a blood alcohol level test.","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1274#1275","text":"6 months#12 months#24 months#36 months"},
{"testId":10,"qid":388,"description":"All of the following don't have to wear seat belts under Florida law, except:","explanation":null,"imageName":"","correctAnswer":27031,"isBookmarked":0,"aid":"27029#27031#41738#41739","text":"farm equipment#passenger cars manufactured after 1968#an employee delivering newspapers#buses"},
{"testId":10,"qid":390,"description":"The driver of a vehicle being passed must _____ until the pass is complete.","explanation":"The driver of the passing vehicle should not change lanes or accelerate until the pass is complete.","imageName":"","correctAnswer":1285,"isBookmarked":0,"aid":"758#1284#1285#22057","text":"stop#increase speed#not increase speed#move to the left"},
{"testId":10,"qid":391,"description":"Maintain a ________ following distance under typical weather and traffic circumstances to avoid colliding with the vehicle in front of you.","explanation":"Tailgating is the most common cause of rear-end crashes (following the vehicle in front of you too closely). Maintain a four-second trailing distance behind the car in front of you to assist prevent such incidents. Increase your following distance even further under difficult driving situations.","imageName":"","correctAnswer":550,"isBookmarked":0,"aid":"549#550#552#3640","text":"Two-second#Four-second#six-second#One-second"},
{"testId":10,"qid":392,"description":"You are on an interstate highway. What should you do if you miss your exit?","explanation":"Don't stop, back up, or attempt to turn around if you miss your exit on a limited-access highway. This dramatically increases the possibility of a collision. Instead, take the next exit and rejoin the motorway to return to the missed exit.","imageName":"202007210432373628.jpg","correctAnswer":34698,"isBookmarked":0,"aid":"24257#24258#32570#34698","text":"Stop and ask for help#Back up to reach your exit#Make a U-turn to reach your exit#Continue to the next exit"},
{"testId":10,"qid":393,"description":"When you are within _____ of an oncoming vehicle, Florida law requires you to dim your headlights to low beam.","explanation":"When you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following, Florida law requires you to reduce your headlights to low beam.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"399#1294#1295#1296","text":"200 feet#350 feet#450 feet#500 feet"},
{"testId":10,"qid":394,"description":"When you get close to a drawbridge with a red light, you must:","explanation":"You must come to a full stop at the indicated stop line while approaching a drawbridge with a red light. A red indicator indicates that the bridge is open and that the highway is closed to all pedestrian and vehicle traffic.","imageName":"","correctAnswer":7651,"isBookmarked":0,"aid":"7651#8992#27036#41740","text":"Come to a complete stop#make a U-turn#slow down and look for the signal#proceed with caution and watch for the traffic gates"},
{"testId":10,"qid":395,"description":"A shattered yellow line on the road:","explanation":"A yellow line divides traffic lanes traveling in opposing directions. If there is a broken yellow line, you may cross it momentarily if it is safe to do so. If the line is solid yellow, you may not cross it unless to perform a left turn over it.","imageName":"","correctAnswer":31610,"isBookmarked":0,"aid":"27038#27040#27041#31610","text":"separates lanes of traffic moving in the same direction#marks the left edge of the pavement#marks the right edge of the pavement#separates lanes of traffic moving in opposite directions"},
{"testId":10,"qid":396,"description":"Drivers from either direction can ________ from the center lane on a two-way road having a center lane.","explanation":"A shared center lane (also known as a two-way left-turn lane) is designated for cars moving in either direction to turn left (and U-turns where permitted). When entering this lane, exercise care since cars moving in the other way may also be utilizing it.","imageName":"","correctAnswer":3055,"isBookmarked":0,"aid":"3055#9293#9295#9587","text":"make left turns#make right turns#make frequent stops#back up"},
{"testId":10,"qid":397,"description":"When two cars arrive at an open junction about the same time,:","explanation":"An open intersection (also known as an uncontrolled intersection) is one without traffic signs or signals. When two vehicles enter an open intersection at about the same time, the driver on the left must yield to the vehicle on the right.","imageName":"","correctAnswer":41742,"isBookmarked":0,"aid":"27043#41741#41742#41743","text":"the driver who signals first has the right-of-way#both cars can move at the same time#the driver on the left must yield to the car on the right#the driver on the right must yield to the car on the left"},
{"testId":10,"qid":398,"description":"What is the significance of a flashing yellow arrow?","explanation":"After yielding to pedestrians and incoming cars, a flashing yellow arrow indicates that you may drive with care in the direction of the arrow. Here, oncoming traffic has a green signal.","imageName":"","correctAnswer":41746,"isBookmarked":0,"aid":"39011#41744#41745#41746","text":"You may turn in the direction of the arrow; oncoming traffic is stopped#You must stop immediately#The yellow arrow is about to turn red; you must not turn in the direction of the arrow#You may turn in the direction of the arrow but only after you yield to pedestrians and oncoming vehicles"},
{"testId":10,"qid":400,"description":"Vehicle registrations and license plates must be renewed:","explanation":"Vehicle registrations and license plates must be renewed annually or biennially, on or before the birthdate of the first owner specified on the registration form.","imageName":"","correctAnswer":41753,"isBookmarked":0,"aid":"41750#41751#41752#41753","text":"(a) every year#(b) every two years#every three years#either (a) or (b)"},
{"testId":12,"qid":401,"description":"In Hawaii, the minimum age to receive a driver's license is _______","explanation":"The minimum age in Hawaii for obtaining a temporary license is 16 years, while the minimum age for obtaining a driver's license is 17 years.","imageName":"","correctAnswer":28013,"isBookmarked":0,"aid":"28011#28012#28013#28014","text":"15 years#15 1/2 years#17 years#21 years"},
{"testId":12,"qid":402,"description":"A red octagonal (eight-sided) sign is:","explanation":"A stop sign is an octagonal (eight-sided) sign with the words \"STOP\" in white lettering on a red backdrop. Before advancing, you must always come to a full stop and yield to oncoming vehicles.","imageName":"202003301719091470.jpg","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#19269#19270","text":"a stop sign#a yield sign#a Do Not Enter sign#a road construction sign"},
{"testId":12,"qid":403,"description":"What is the meaning of this sign?","explanation":"Lane usage control signs are black-and-white rectangular signs that indicate whether or not turning is needed from certain lanes at a junction. You must only drive in the directions specified for your traffic lane.","imageName":"202004071526567037.jpg","correctAnswer":19272,"isBookmarked":0,"aid":"19271#19272#19273#19274","text":"Only left turns are allowed#Move only in the directions indicated#Do not pass a vehicle on the left#Passing is allowed in these directions"},
{"testId":12,"qid":404,"description":"If your vehicle's directional (turn) signals stop working, use ________ until they can be repaired.","explanation":"You may communicate with other drivers via directional signals. Ensure that they work properly at all times. Any change in the dashboard light signal indicates that the system is not working properly. If the directional signals stop working, utilize hand signals until they can be fixed.","imageName":"","correctAnswer":20,"isBookmarked":0,"aid":"19#20#1333#1334","text":"emergency signals#hand signals#headlights#brake lights"},
{"testId":12,"qid":405,"description":"When travelling slower than normal traffic, you should drive in the:","explanation":"When travelling slower than normal traffic, drive in the far right lane.","imageName":"","correctAnswer":28017,"isBookmarked":0,"aid":"28015#28016#28017#28018","text":"center lane#extreme left lane#extreme right lane#shoulder of the road"},
{"testId":12,"qid":406,"description":"When must you not drive on the left side of the road?","explanation":"When you are 1) approaching the top of a hill or on a curve; 2) approaching within 100 feet (30.5 meters) of or passing through an intersection or railroad crossing; or 3) approaching within 100 feet (30.5 meters) of a bridge or tunnel and you are unable to see vehicles coming from the opposite direction, you must drive on the left half of the roadway.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#28019#28020#28021","text":"In any of the above situations#Approaching the top of a hill#Within 100 feet of a railroad crossing#Within 100 feet of a bridge"},
{"testId":12,"qid":407,"description":"This exclusion symbol denotes that:","explanation":"Bicycles are not permitted on this route, as shown by this exclusion notice. Exclusion signs are rectangular signs with either a red circle on a white backdrop (for example, a Do Not Enter sign) or a red circle with a red diagonal on a black and white background (for example, a No Smoking sign) (for example, this No Bicycles sign). These signs limit or ban cars, vehicle movements, or other activities.","imageName":"202003301726014561.jpg","correctAnswer":28022,"isBookmarked":0,"aid":"28022#28023#28024#28025","text":"bicycles are prohibited on this route#passenger cars should yield to bicycles#bicyclists should not use the shoulder of the road#bicyclists are not allowed to cross at the intersection"},
{"testId":12,"qid":408,"description":"This symbol denotes:","explanation":"This sign alerts you to the fact that you are entering a school zone.","imageName":"202003151740461521.jpg","correctAnswer":8895,"isBookmarked":0,"aid":"8895#28026#28027#28028","text":"a school zone ahead#a crosswalk ahead#a library ahead#a playground ahead"},
{"testId":12,"qid":409,"description":"You are stuck in a lot of traffic, and right behind you is a flashing emergency vehicle. What do you need to do?","explanation":"If you are stuck in traffic and hear a siren or see the flashing lights of an emergency vehicle behind you, slow down until you can move out of the way.","imageName":"202003201645072047.jpg","correctAnswer":28029,"isBookmarked":0,"aid":"28029#28030#28031#28032","text":"Keep moving slowly until you can get out of the way#Continue moving in the same lane and let the emergency vehicle change lanes#Increase your speed and let the emergency vehicle pass you#Stop your vehicle immediately"},
{"testId":12,"qid":410,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road ahead is split by an island; you must stay to the right.","imageName":"202003151752201841.jpg","correctAnswer":19276,"isBookmarked":0,"aid":"15668#19276#19277#19570","text":"There is a divided highway ahead#Keep to the right of the traffic island#Turn right for a low-clearance underpass#There is a detour ahead"},
{"testId":12,"qid":411,"description":"You can't pass another vehicle:","explanation":"You may pass when there is a broken yellow line adjacent to your lane.","imageName":"","correctAnswer":28033,"isBookmarked":0,"aid":"22642#22644#24346#28033","text":"on the right shoulder of the highway#when you are within 100 feet of a railroad crossing#when there is a school bus with flashing lights on the same roadway#when there is a broken (dashed) yellow line next to your lane"},
{"testId":12,"qid":412,"description":"If you notice this sign while driving, you should:","explanation":"This alignment sign controls traffic and cautions you not to do a U-turn with a diagonal red slash.","imageName":"202003151744407638.jpg","correctAnswer":22119,"isBookmarked":0,"aid":"6365#8992#22118#22119","text":"Turn left#make a U-turn#not turn left#not make a U-turn"},
{"testId":12,"qid":413,"description":"All regulatory gadgets on the road inform you:","explanation":"Regulatory gadgets instruct you to halt, go in a certain direction, or slow down. All regulatory devices specify the measures you must perform. Failure to comply carries a penalty.","imageName":"","correctAnswer":8624,"isBookmarked":0,"aid":"8624#8626#24085#28034","text":"to stop, proceed in a certain direction, or limit your speed#None of the above#about hazardous conditions or the possibility of hazardous conditions#how to find your way safely or make your trip more comfortable"},
{"testId":12,"qid":414,"description":"When you notice a flashing yellow signal at a junction, you should :","explanation":"A flashing yellow indicator indicates that you should continue with care.","imageName":"","correctAnswer":8499,"isBookmarked":0,"aid":"8497#8498#8499#8500","text":"increase your speed and rush through the intersection#stop and enter the intersection only when it is safe to do so#slow down and cross the intersection carefully#remain stopped until the light turns green"},
{"testId":12,"qid":415,"description":"You will often find flag persons in highways and street work zones, whose task is to:","explanation":"Flag personnel (flaggers) are often stationed on roads and in construction zones to safely halt, slow, or direct vehicles through the regions. Flaggers wear orange vests, shirts, or jackets and guide traffic with red flags or stop/slow paddles.","imageName":"","correctAnswer":22517,"isBookmarked":0,"aid":"22514#22517#22645#22646","text":"to slow vehicles to a lower speed limit posted ahead#to stop, slow, or guide traffic safely through construction areas#to report accidents to the police department and insurance companies#to determine the amount of alcohol in a driver's blood"},
{"testId":12,"qid":416,"description":"What should you do if you hit an unattended vehicle?","explanation":"If you hit an unattended car, either stop and look for the owner or leave a written note with your name, address, and a description of the accident.","imageName":"","correctAnswer":28035,"isBookmarked":0,"aid":"6392#28035#28036#28037","text":"Call the police#Leave a written note containing your name and address#Ignore the accident and continue on your way#Stop other vehicles and ask for the owner"},
{"testId":12,"qid":417,"description":"Accidents on the road that cause property damage of _______ or more must be reported to the police right away.","explanation":"Traffic accidents resulting in $3,000 or more in physical injury, death, or property damage must be reported to the police promptly.","imageName":"202006141611292838.jpg","correctAnswer":28038,"isBookmarked":0,"aid":"20167#28038#28039#28040","text":"2000#3000#1500#5000"},
{"testId":12,"qid":418,"description":"This figure's single white dashed line suggests:","explanation":"White lines divide traffic lanes traveling in the same direction.","imageName":"202003161219208832.jpg","correctAnswer":24576,"isBookmarked":0,"aid":"19278#19279#24575#24576","text":"the center of a roadway where passing is prohibited#the curb of the road#lanes of traffic moving in opposite directions#lanes of traffic moving in the same direction"},
{"testId":12,"qid":419,"description":"It is illegal for anybody under the age of _____ who has a BAC of 0.02 percent or more to operate a motor vehicle.","explanation":"Drivers under the age of 21 are not permitted to operate a motor vehicle with a blood alcohol concentration of 0.02 percent or more. These drivers carefully adhere to the zero tolerance guideline.","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"9658#11360#11361#12455","text":"55#21#16#35"},
{"testId":12,"qid":420,"description":"If you need to read a map or instructions while driving, you should :","explanation":"Pull over to the side of the road in a safe location if you need to read a map or instructions. Continue to halt until you are ready to offer your whole concentration to driving.","imageName":"","correctAnswer":8501,"isBookmarked":0,"aid":"8501#8502#8503#8504","text":"pull over to the side of the road in a safe place and then read the map#reduce your vehicle's speed and then read the map#have a quick glance while driving and then move ahead#stop your vehicle on the road and then read the map"},
{"testId":12,"qid":421,"description":"Which of the following is NOT a defensive driving technique?","explanation":"Even if you have \"hands-free\" technology, chatting on the phone diverts your attention away from driving and makes you less likely to spot a potentially hazardous scenario.","imageName":"","correctAnswer":40819,"isBookmarked":0,"aid":"28041#28042#28044#40819","text":"Keep your eyes moving to be aware of traffic situations developing around you at all times#Have an alternate plan of action ready in case an unexpected event develops#Stay alert and maintain an appropriate following distance#Call your family while driving to keep you awake"},
{"testId":12,"qid":422,"description":"If you are thrown from the vehicle and do not use a seat belt, your chances of death are ________ higher.","explanation":"Safety belts prevent you from being thrown from your vehicle. Wearing a seat belt increases your risk of being thrown from your automobile by thirty times. And if you are thrown from your automobile, your odds of dying are five times higher.","imageName":"","correctAnswer":16,"isBookmarked":0,"aid":"13#14#15#16","text":"four times#two times#three times#five times"},
{"testId":12,"qid":423,"description":"When exiting a roundabout, always use:","explanation":"A roundabout is a one-way circular crossroads where traffic travels counterclockwise around an island in the center. To leave the roundabout, use your right turn signal.","imageName":"202007161124338470.jpg","correctAnswer":8858,"isBookmarked":0,"aid":"8858#8859#8860#8861","text":"your right turn signal#your left turn signal#both your right and left turn signals#no signal"},
{"testId":12,"qid":424,"description":"Before changing lanes, you must signal for at least ________ :","explanation":"If you need to change lanes, make your intentions clear ahead of time. Before changing lanes, you must indicate for at least 100 feet (30 meters) according to the legislation.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#1397","text":"100 feet#50 feet#25 feet#150 feet"},
{"testId":12,"qid":425,"description":"It is NOT SAFE to proceed:","explanation":"When approaching or going through an intersection within 100 feet, you should not try to pass another vehicle.","imageName":"","correctAnswer":28046,"isBookmarked":0,"aid":"28045#28046#28047#28048","text":"when the vehicle ahead of you is moving at less than a safe speed#when you are moving through an intersection#when there is a dashed (broken) yellow line next to your lane#when there is a dashed (broken) white line next to your lane"},
{"testId":12,"qid":426,"description":"All motor vehicles operating on public roadways must undergo an official Periodic Motor Vehicle Inspection (PMVI) at least once:","explanation":"Almost all motor vehicles on public roadways are required to undergo an official Periodic Motor Vehicle Inspection (PMVI) at least once a year. Ambulances, which must be examined every six months, and new cars, which do not need to be inspected for two years following their first inspection on the day they are sold, are exceptions.","imageName":"","correctAnswer":28051,"isBookmarked":0,"aid":"28049#28050#28051#28052","text":"every 3 years#every 2 years#every year#every six months"},
{"testId":12,"qid":427,"description":"Two-way left turn channelization marks combine _____. Left-turn lanes are marked in the road's middle.","explanation":"Two-way left turn channelization markers consist of a yellow solid line and a yellow dashed line on either side of a roadway's center. These lines denote a lane in the middle of the road that cars moving in either direction may use to perform left turns. This is also referred to as a center shared turning lane. This lane should never be utilized to pass.","imageName":"","correctAnswer":28053,"isBookmarked":0,"aid":"28053#28054#28055#28056","text":"yellow solid lines and yellow dashed lines#white solid lines and white dashed lines#yellow solid lines and white solid lines#yellow solid lines and white dashed lines"},
{"testId":12,"qid":428,"description":"According to statistics, drivers ________ have more accidents than drivers of other ages.","explanation":"According to statistics, the youngest drivers (those under the age of 20) experience around twice as many accidents as would be predicted given the number of drivers in this age range. They continue to have fewer accidents as they mature until they are 50 to 54 years old. These drivers have the lowest accident rate of any age group. Accidents become more common as one gets older, beginning at the age of 55. Drivers beyond the age of 75 are engaged in more accidents, but still less than drivers under the age of 25.","imageName":"","correctAnswer":1410,"isBookmarked":0,"aid":"1410#1411#1412#1413","text":"under age 20#between 30 and 40 years of age#between 50 and 55 years of age#above age 70"},
{"testId":12,"qid":429,"description":"Apply ________ until normal braking action is restored if your brakes have become less effective due to water or rain.","explanation":"Always check the brakes' responsiveness after driving through water. Apply the brakes softly many times until the normal braking action is restored if the brakes are no longer as effective.","imageName":"202007141847013359.jpg","correctAnswer":1416,"isBookmarked":0,"aid":"1416#28057#28058#28059","text":"the brakes lightly several times#more pressure to the steering wheel#more pressure to the gas pedal#heat to your brakes"},
{"testId":12,"qid":430,"description":"If you park on the right side of a road with a curb and face downhill, you should turn your front wheels:","explanation":"Turn your front wheels to the right if you park downhill with or without a curb. Turn your front wheels away from the curb if you park uphill with a curb. If you're parking uphill without a curb, point your front wheels toward the road's edge. If your automobile begins to roll downhill, the curb will either stop it or it will move away from traffic.","imageName":"","correctAnswer":28061,"isBookmarked":0,"aid":"9705#28060#28061#28062","text":"away from the curb#to the left#to the right#away from the edge of the road"},
{"testId":12,"qid":431,"description":"Never use ________ while the car is in motion.","explanation":"Hazard warning lights (4-way flashers) are used to alert other drivers when a vehicle is in a dangerous situation. When the vehicle is moving, this signal should never be utilized. The operation of this signal is indicated by all turn signal indicators flashing. There is no comparable hand signal.","imageName":"","correctAnswer":1422,"isBookmarked":0,"aid":"1422#1424#1425#28063","text":"Hazard warning signals#Turn signals#Parking signals#Brake signals"},
{"testId":12,"qid":432,"description":"What is the meaning of this sign?","explanation":"The sign forewarns of a slope or a descent. Slow down and be prepared to change into a lower gear to regulate your speed and minimize braking.","imageName":"202002101212538783.jpg","correctAnswer":28065,"isBookmarked":0,"aid":"24470#24471#28064#28065","text":"Trucks are not allowed on this route#Trucks with heavy loads are not allowed to go downhill#Trucks carrying dangerous goods are prohibited from going downhill#There is a downgrade ahead"},
{"testId":12,"qid":433,"description":"When the weight of a vehicle's trailer hitch is insufficient,:","explanation":"Check that the trailer load is balanced appropriately. Between 10% and 15% of the trailer's weight should be supported by the vehicle's trailer hitch. You will have difficulties guiding the car if the weight is too heavy. If the weight is insufficient, the trailer will fishtail and swerve from side to side.","imageName":"","correctAnswer":28067,"isBookmarked":0,"aid":"28066#28067#28068#28069","text":"steering the vehicle will be more difficult#the trailer will fishtail#applying the brakes will be more difficult#the trailer will become detached from the vehicle"},
{"testId":12,"qid":434,"description":"Allow at least ________ following distance when driving behind a motorcycle.","explanation":"Allow at least a 2-second following space while driving behind a motorbike. This gives the motorcycle rider extra space to maneuver or stop in an emergency.","imageName":"","correctAnswer":1433,"isBookmarked":0,"aid":"1433#1434#1435#1436","text":"2-second#3-second#4-second#1-second"},
{"testId":12,"qid":435,"description":"What is the meaning of this sign?","explanation":"This warning sign advises that the right lane is coming to an end ahead. All vehicles in the right lane must merge to the left.","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"28070#28071#28072#28073","text":"The right lane ends ahead#Traffic merges ahead#There is an acceleration lane ahead#There is a narrow bridge ahead"},
{"testId":12,"qid":436,"description":"You should _____ to slow down when driving down a steep downhill.","explanation":"Continuous use of brakes to limit speed on downgrades will render the brakes useless. Instead, release the accelerator (gas) pedal and move into a lower gear to regulate your speed on downhills (even with an automatic transmission). When the throttle is closed, a partial vacuum is formed in the engine's intake and cylinders, causing the vehicle to slow down. This is referred to as engine braking. The stronger the engine braking impact, the lower the gear. Only use the brakes to come to a complete stop.","imageName":"","correctAnswer":24391,"isBookmarked":0,"aid":"24388#24389#24390#24391","text":"apply your brakes continuously#press the accelerator hard#use a higher gear#use a lower gear"},
{"testId":12,"qid":437,"description":"_____ is a way to drive in which you look out for potentially dangerous situations and take steps to avoid an accident.聽:","explanation":"Driving defensively recognizes hazardous driving scenarios and takes measures to mitigate the risk before an accident occurs You must take protective measures to avoid cars driven by aggressive, offensive, discourteous, reckless, inattentive, impulsive, uninformed, and drunken individuals, as well as pedestrians who may exhibit some of the same traits.","imageName":"","correctAnswer":865,"isBookmarked":0,"aid":"865#1444#1445#1446","text":"Defensive driving#Offensive driving#Safe driving#Good driving"},
{"testId":12,"qid":438,"description":"The right lane of a motorway should be kept as clear as possible for:","explanation":"Allow room for cars entering the motorway if you are in the right lane. The right lane should be kept as clear as possible for freeway vehicles arriving and exiting.","imageName":"","correctAnswer":28075,"isBookmarked":0,"aid":"8747#28074#28075#28076","text":"heavy vehicles#through traffic#traffic entering and leaving the freeway#passing traffic"},
{"testId":12,"qid":439,"description":"This caution symbol denotes:","explanation":"A split roadway is ahead, as indicated by this caution sign. Warning signs alert you to potential hazards on or near the road. When you encounter such signals, you should be prepared to take safe and appropriate action.","imageName":"202002101247507801.jpg","correctAnswer":8622,"isBookmarked":0,"aid":"8622#8662#19284#28077","text":"a divided highway ahead#an underpass ahead#traffic merging ahead#a one-lane road ahead"},
{"testId":12,"qid":440,"description":"You can renew your license up to _______ days before it runs out.","explanation":"You may renew your driver's license at any Hawaii driver licensing office. Your license may be renewed up to 6 months before it expires. After the expiry date, every Hawaii state driver's license becomes null and worthless. There is no waiting time.","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1273#1453#1454","text":"6 months#12 months#3 months#9 months"},
{"testId":13,"qid":441,"description":"When you encounter a school bus _____, you must come to a complete stop.","explanation":"When approaching a school bus that has flashing red lights and is stopped to pick up or drop off students, you must come to a complete stop. You must stay stopped until all children have been safely removed from the highway and the bus.","imageName":"","correctAnswer":44766,"isBookmarked":0,"aid":"44763#44764#44765#44766","text":"parked empty on the side of the road#coming your way even if its lights are not flashing#and the driver gives you a signal to stop#with flashing red lights on a two-lane highway"},
{"testId":13,"qid":442,"description":"When slowing down or coming to a complete stop, you must:","explanation":"When slowing or halting your vehicle, you must use either the brake-operated signal lights or a hand signal (if your vehicle's signals are inoperative).","imageName":"","correctAnswer":25869,"isBookmarked":0,"aid":"22296#25868#25869#32929","text":"b) use the brake-operated signal lights to alert the driver behind you#do both a) and b)#do either a) or b)#a) use a hand signal to alert the driver behind you if your vehicle's turn signals don't work"},
{"testId":13,"qid":443,"description":null,"explanation":null,"imageName":"","correctAnswer":1465,"isBookmarked":0,"aid":"1464#1465#28565#28566","text":"not less than 50 mph#reasonable and prudent#equal to the maximum posted speed limit#equal to the minimum posted speed limit"},
{"testId":13,"qid":444,"description":"What is the meaning of this sign?","explanation":"This traffic regulation sign informs that no left turns are permitted in this area.","imageName":"202003151710362609.jpg","correctAnswer":19281,"isBookmarked":0,"aid":"19280#19281#19282#19283","text":"U-turns are not allowed#Left turns are not allowed#Do not merge left#You must make a right turn"},
{"testId":13,"qid":445,"description":"Which of the following claims concerning Idaho interstate highway speed restrictions is true?","explanation":null,"imageName":"","correctAnswer":28570,"isBookmarked":0,"aid":"28567#28568#28569#28570","text":"The maximum speed limit is 65 mph for passenger vehicles and 55 mph for heavy commercial vehicles#The maximum speed limit is 65 mph for passenger vehicles and 60 mph for heavy commercial vehicles#The maximum speed limit is 75 mph for passenger vehicles and 55 mph for heavy commercial vehicles#The maximum speed limit is 75 mph for passenger vehicles and 65鈥70 mph for heavy commercial vehicles"},
{"testId":13,"qid":446,"description":"If an emergency or police vehicle approaches you with a siren or flashing lights, you must:","explanation":"If an emergency or police vehicle approaches you with a siren or flashing red or blue lights, you must instantly pull over and stop. Continue to stand still until the emergency car has past you.","imageName":"","correctAnswer":28571,"isBookmarked":0,"aid":"28571#28572#28573#28574","text":"immediately pull over and stop#move into the left lane and stop#maintain a 400-foot distance from the emergency vehicle and stop#increase your speed and get out of the way"},
{"testId":13,"qid":447,"description":"Passing is not allowed in the following places or while approaching them:","explanation":"Within 100 feet of an intersection, passing is banned.","imageName":"","correctAnswer":28577,"isBookmarked":0,"aid":"28020#28575#28576#28577","text":"Within 100 feet of a railroad crossing#within 100 feet of a bridge or tunnel#where a school bus is stopped to load or unload children#within 300 feet of an intersection unless otherwise indicated by traffic control devices"},
{"testId":13,"qid":448,"description":"This symbol denotes:","explanation":null,"imageName":"202002101203434405.jpg","correctAnswer":28579,"isBookmarked":0,"aid":"28578#28579#28580#28581","text":"a forest zone#a livestock area#a wild animal passing zone#a veterinary hospital"},
{"testId":13,"qid":449,"description":"You must park parallel to and within ___ inches of the curb or edge of the highway while parking on a public road.","explanation":"When parking on a public road, you must park parallel to and within 18 inches of the curb or edge of the road, facing the traffic on your side of the road.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#5448#11359#12359","text":"15#20#18#80"},
{"testId":13,"qid":450,"description":"When should you signal if you intend to turn just past an intersection?","explanation":"If you want to turn just beyond an intersection, signal after passing past it. If you signal early, other road users may believe you intend to turn at the junction.","imageName":"202007210445346932.jpg","correctAnswer":28583,"isBookmarked":0,"aid":"28363#28582#28583#28584","text":"Not at all#Just before you pass through the intersection#Just after you pass through the intersection#When you are in the intersection"},
{"testId":13,"qid":452,"description":"This symbol denotes:","explanation":"This sign denotes that there is a school zone ahead. Slow down and keep an eye out for youngsters.","imageName":"202003151740461521.jpg","correctAnswer":8895,"isBookmarked":0,"aid":"8895#28027#28585#28586","text":"a school zone ahead#a library ahead#a hospital crosswalk ahead#a pedestrian crosswalk ahead"},
{"testId":13,"qid":454,"description":"When you make a U-turn near a hill or curve, the law says you must be able to see :","explanation":"In no-passing zones, U-turns are not permitted. Before doing a U-turn, come to a complete stop and yield to all vehicles. When doing a U-turn near a slope or curve, you must be able to see 500 feet in each direction.","imageName":"","correctAnswer":28589,"isBookmarked":0,"aid":"28587#28588#28589#28590","text":"300 feet in either direction#500 feet in the direction of travel#500 feet in either direction#250 feet in the direction of travel"},
{"testId":13,"qid":455,"description":"In which of the following situations, backing of a vehicle is always prohibited?","explanation":"On motorways, expressways, and other controlled-access roadways, including the shoulder, backing is never permitted.","imageName":"","correctAnswer":23789,"isBookmarked":0,"aid":"10847#23789#28591#28592","text":"On freeways#In all of the above locations#On expressways#On controlled-access highways"},
{"testId":13,"qid":456,"description":"What is the meaning of this sign?","explanation":"This sign indicates that a significant descent is imminent. Check your brakes and continue with care, ideally in a lower gear to lessen brake stress.","imageName":"202002101212538783.jpg","correctAnswer":28596,"isBookmarked":0,"aid":"28593#28594#28595#28596","text":"There is a hill ahead; increase your speed#Slippery when wet; passing a truck is prohibited#A low area ahead; trucks are not allowed#A steep downgrade ahead; check your brakes and proceed with caution"},
{"testId":13,"qid":457,"description":"You're on the freeway. What should you do if you accidentally leave at a single-point urban interchange and want to return to the freeway?","explanation":"Left turns off the highway from both directions as well as traffic on the intersecting road are all managed by traffic lights at a single central location at a single-point urban interchange (SPUI). You cannot access the motorway within the interchange if you accidently exit a SPUI and want to get back on it. Instead, make a left or right turn onto the crossing road, then look for a secure spot to do a U-turn. then make your way back to the proper highway exit.","imageName":"","correctAnswer":40822,"isBookmarked":0,"aid":"40820#40821#40822#40823","text":"Drive across the road and reenter the freeway#Make a sharp U-turn#Turn onto the intersecting road and find a safe place to turn around#Slow down and stop"},
{"testId":13,"qid":458,"description":"In a roundabout, drivers must_______ whether they will make a right turn, a left turn, a U-turn, or continue straight ahead.","explanation":"A roundabout is a one-way circular junction where traffic flows clockwise around a central island. To perform any sort of turn in a roundabout, you must always turn right. To enter the roundabout, you must cede the right-of-way to cars already present.","imageName":"","correctAnswer":28089,"isBookmarked":0,"aid":"1518#1520#28088#28089","text":"turn left and then turn right#turn right and then turn left#turn left and merge into traffic#turn right and merge into traffic"},
{"testId":13,"qid":460,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the road ahead is slick when wet. Slow down and proceed with care in damp weather.","imageName":"202002101214576723.jpg","correctAnswer":28602,"isBookmarked":0,"aid":"24092#28073#28601#28602","text":"There is a one-way street ahead#There is a narrow bridge ahead#The road curves ahead#The road is slippery when it's wet"},
{"testId":13,"qid":461,"description":"Prepare to respond quickly to situations by driving with your hands on the steering wheel between :","explanation":"Driving with your hands at around the nine and three o'clock positions on the steering wheel can help you be ready to respond quickly to crises. If your car has airbags, see the owner's handbook for the right way to hold your hands.","imageName":"","correctAnswer":28603,"isBookmarked":0,"aid":"28603#28604#28605#28606","text":"the nine o'clock and three o'clock positions#the nine o'clock and four o'clock positions#the ten o'clock and three o'clock positions#the eight o'clock and two o'clock positions"},
{"testId":13,"qid":462,"description":"In order to avoid a collision,:","explanation":"Keep enough distance between your car and those on all sides so that you may safely stop or pass.","imageName":"","correctAnswer":27100,"isBookmarked":0,"aid":"8829#27098#27100#28607","text":"Change lanes frequently#try to follow other vehicles closely#try to keep a space cushion#try to stay on the left side of your lane"},
{"testId":13,"qid":463,"description":"For an extra safety margin, you should increase the usual three-second following distance:","explanation":"Your normal three-second following distance should be increased to many times that much at high speeds or in adverse weather.","imageName":"","correctAnswer":28609,"isBookmarked":0,"aid":"28608#28609#28610#28611","text":"at railroad crossings and in parking areas#at high speeds or in bad weather#on one-way streets#on two-way streets"},
{"testId":13,"qid":464,"description":"What is the meaning of this sign?","explanation":"This warning sign informs that there is a traffic merge coming. Expect other automobiles and trucks to enter your lane.","imageName":"202002101239016186.jpg","correctAnswer":5632,"isBookmarked":0,"aid":"5632#28612#28613#28614","text":"Traffic merging ahead#A two-way street#A narrow lane#The end of a divided highway"},
{"testId":13,"qid":465,"description":"Which of the following statements is true about passing a vehicle?","explanation":"On a two-lane highway, if you are driving a passenger car, motorbike, or truck that is not pulling another vehicle, you may exceed the legal speed limit by up to 15 miles per hour when passing another vehicle that is going below the posted speed limit. The specified speed limit must be at least 55 miles per hour. This does not apply in construction zones. You must immediately return to the right lane and lower your speed to the stated speed limit. You must execute a pass before approaching oncoming traffic within 200 feet. Within 100 feet of a railroad crossing, passing is banned. When a car is turning left, you may pass on the right.","imageName":"","correctAnswer":32880,"isBookmarked":0,"aid":"28615#28616#28618#32880","text":"You must complete the pass before coming within 300 feet of oncoming traffic#Passing is prohibited within 200 feet of a railroad crossing#Passing on the right is always prohibited#At speeds 55 mph or greater, you may exceed the posted speed limit by up to 15 mph, while passing another vehicle that is traveling below the posted speed limit on a two lane roadway"},
{"testId":13,"qid":466,"description":"The distance you can see ahead while driving at night is:","explanation":"At night, your eyesight is impaired. Always drive slowly enough to stop within the distance you can see ahead to give yourself adequate time to respond to risks. This distance is around 350 feet when using high beams and just approximately 100 feet when using low beams. At 60 mph, your car will move 100 feet in 1.1 seconds, giving you very little time to respond to a problem you've just seen. That is why, where practical, high lights should be used at night.","imageName":"","correctAnswer":28620,"isBookmarked":0,"aid":"28619#28620#28621#28622","text":"650 feet when using high beams and 350 feet when using low beams#350 feet when using high beams and 100 feet when using low beams#450 feet when using high beams and 150 feet when using low beams#550 feet when using high beams and 200 feet when using low beams"},
{"testId":13,"qid":467,"description":"In order to lessen the chance of hydroplaning, you should:","explanation":"Slow down in rainstorms or on slippery roads to lessen your risks of hydroplaning.","imageName":"","correctAnswer":28625,"isBookmarked":0,"aid":"25944#28623#28624#28625","text":"increase the speed of your vehicle#not take off your foot off the gas pedal#try to stop or turn your vehicle quickly#slow down in rainstorms"},
{"testId":13,"qid":468,"description":"This symbol denotes:","explanation":"This sign allows you to turn around.","imageName":"202003151704081572.jpg","correctAnswer":28628,"isBookmarked":0,"aid":"28626#28627#28628#28629","text":"there is a sharp left turn ahead#there is a sharp U-turn ahead#U-turns are permitted#U-turns are prohibited"},
{"testId":13,"qid":469,"description":"To exit a freeway or expressway, move into:","explanation":"To exit the motorway, signal and move into the lane closest to the exit approximately half a mile before the exit. Signal, then go into the deceleration lane to slow down without disrupting traffic flow.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"23853#26083#26085#28630","text":"An acceleration lane#a deceleration lane#a merging area#a blending area"},
{"testId":13,"qid":470,"description":"You should not _____ if you want to avoid a collision.","explanation":null,"imageName":"","correctAnswer":28632,"isBookmarked":0,"aid":"6475#6476#28631#28632","text":"Speed up#Slow down#change direction#move fast by removing your seat belt"},
{"testId":13,"qid":471,"description":"On motorways and interstate roads, median crossovers are designed for usage by :","explanation":"A median crossover is only for emergency and maintenance vehicles.","imageName":"","correctAnswer":22600,"isBookmarked":0,"aid":"8748#22600#28633#28634","text":"slow-moving vehicles#emergency vehicles#racing vehicles#high-occupancy vehicles"},
{"testId":13,"qid":472,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the presence of a railroad crossing ahead. If a train is nearing the crossing, prepare to halt before reaching the tracks.","imageName":"202001282004545744.jpg","correctAnswer":28636,"isBookmarked":0,"aid":"28635#28636#28637#28638","text":"A no-passing zone ahead; you must not pass#A railroad crossing ahead; be prepared to stop#A freeway ahead; use caution#A work zone ahead; slow down and follow the directions provided"},
{"testId":13,"qid":473,"description":"What is the significance of a V-shaped pattern on a barricade?","explanation":"The V-shaped pattern on a barrier indicates that the route is blocked and no further traffic is permitted.","imageName":"","correctAnswer":28639,"isBookmarked":0,"aid":"28639#28640#28641#28642","text":"Proceed no farther#Make a left turn and go straight#Make a right turn and go straight#Continue moving and slow down if needed"},
{"testId":13,"qid":474,"description":"Which of the following statement is TRUE?","explanation":"People who attempt to text or call mobile phones while driving cause many accidents. Driving while texting is six times more harmful than driving drunk. If you must use a mobile phone, park in a safe location before making a call or texting.","imageName":"","correctAnswer":44629,"isBookmarked":0,"aid":"44628#44629#44630#44631","text":"In Idaho, reading, writing, sending, or receiving of written communication (text messaging) while driving is legal#Many crashes are caused by people who try to text or dial cell phones while driving#In Idaho, use of cell phones while driving is rare#Talking on a cell phone while driving can keep the driver attentive"},
{"testId":13,"qid":475,"description":"When approaching a snowplow vehicle while driving in the winter, you should:","explanation":"For every 10 mph you travel, stay two vehicle widths behind a snowplow truck. Snowplow trucks might harm your car by spreading sand.","imageName":"","correctAnswer":28649,"isBookmarked":0,"aid":"28647#28648#28649#28650","text":"leave a 10-foot distance between your vehicle and the snowplow truck#remain one car length behind the snowplow truck for every 10 mph you drive#remain two car lengths behind the snowplow truck for every 10 mph you drive#leave a 5-foot distance between your vehicle and the snowplow truck"},
{"testId":13,"qid":476,"description":"What is the meaning of this sign?","explanation":"This warning sign warns the presence of a traffic light ahead. These signs are used on routes with higher speed restrictions or when the view of a junction or traffic signal is obstructed. Prepare to pass through an intersection and potentially a red light.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"1583#1584#1585#1586","text":"Slow-moving vehicles ahead#A hospital ahead#A traffic signal ahead#An uncontrolled intersection ahead"},
{"testId":13,"qid":477,"description":"When an animal moves quickly and unexpectedly into the path of your vehicle, you should:","explanation":"It may be hard to stop a car in time to safely avoid striking an animal because of how swiftly it may move into the path of a moving vehicle. The safest course of action in such situation could be to run it over. Prior to, during, and after the impact, focus on maintaining vehicle control.","imageName":"","correctAnswer":28652,"isBookmarked":0,"aid":"28651#28652#28653#28654","text":"change direction to avoid a collision#concentrate on keeping control of your vehicle#use a lower gear to control your speed#brake hard to avoid a collision"},
{"testId":13,"qid":478,"description":"When passing a bicycle riding in the same direction, you must allow a minimum safe distance of:","explanation":"Be on the lookout for bicycles on the highway. Since they are often difficult to spot, more attention is required. When passing a bicycle moving in the same direction, you must leave a safe passing distance of at least 3 feet.","imageName":"202007210403288679.jpg","correctAnswer":374,"isBookmarked":0,"aid":"374#1028#1591#1592","text":"3 feet#10 feet#5 feet#7 feet"},
{"testId":13,"qid":479,"description":"You've come to a stop at an intersection with no crosswalk or stop line. When is it safe for you to continue?","explanation":"When approaching a stop sign, you must stop at a clearly indicated stop line. If no stop line, then stop before approaching the crossing on the near side of the junction. If no crosswalk, then halt at the point closest the crossing highway where the motorist has a view of incoming traffic on the intersecting highway before entering it.","imageName":"","correctAnswer":44664,"isBookmarked":0,"aid":"44661#44662#44663#44664","text":"After slowing down and making sure there is no traffic#After decreasing your speed to 5 mph#After stopping at least 20 feet before the intersection#After coming to a full stop"},
{"testId":13,"qid":480,"description":"What is the meaning of this sign?","explanation":"Of course, this is a stop sign. Before approaching the junction, you must come to a full stop.","imageName":"202001301744345845.jpg","correctAnswer":28656,"isBookmarked":0,"aid":"132#26076#28656#28657","text":"The road ahead is closed#The road ahead is under repair#You must come to a complete stop#Vehicles carrying hazardous materials must stop here"},
{"testId":11,"qid":481,"description":"If traffic from another road merges onto the one you're on, you should:","explanation":"Whenever traffic from another route merges into the roadway you are driving on, safely change lanes away from the merging traffic if feasible, according to the Georgia Drivers Manual. If doing so is unsafe, modify your speed and vehicle position to enable the traffic to combine safely.","imageName":"","correctAnswer":40118,"isBookmarked":0,"aid":"8955#22445#33343#40118","text":"stop#sound your horn to let the merging traffic know you are there#ignore the merging traffic because you have the right-of-way#change lanes if possible"},
{"testId":11,"qid":482,"description":"When a school bus stops on a split highway, drivers on the opposite side of the road are NOT required to:","explanation":"When a school bus comes to a halt on a split highway, drivers on the other side of the road are not compelled to stop. Drivers should, however, keep an eye out for youngsters walking or crossing the road. Because children may not understand the laws of the road, they may do something unexpected.","imageName":"","correctAnswer":7651,"isBookmarked":0,"aid":"6476#7651#27778#27779","text":"Slow down#Come to a complete stop#pay special attention to children#remain attentive"},
{"testId":11,"qid":483,"description":"Drivers in Georgia are required to _____ when an emergency vehicle with flashing lights stops on the side of the road.","explanation":"According to Georgia's \"Move Over\" Law, you must move over one lane if practicable for an emergency vehicle, sanitation vehicle, or utility service vehicle that is stopped on the side of the road with flashing lights. If this is impossible (i.e., there are no alternative lanes in your direction) or dangerous, you must slow down to the speed limit and be prepared to stop if required. Nota bene: Although every state in the United States currently has its own Move Over Law, some of them demand drivers to perform various things. Always verify the local driving regulations while going out of state.","imageName":"","correctAnswer":43167,"isBookmarked":0,"aid":"1608#43167#43168#43169","text":"move over two lanes if possible#move over one lane if possible#increase their speed if possible#pull over if possible"},
{"testId":11,"qid":484,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending traffic light. If the light is yellow or red, prepare to come to a complete stop. These signs are posted on roads with higher speed restrictions or in areas where your view of the traffic light ahead may be obstructed.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"1585#1586#1612#3572","text":"A traffic signal ahead#An uncontrolled intersection ahead#Merging traffic ahead#A work zone ahead"},
{"testId":11,"qid":485,"description":"Two vehicles come to an uncontrolled intersection at the same time from two different roadways. Which of the following is true?","explanation":"When two cars arrive at an uncontrolled junction (one without stop signs, yield signs, or traffic lights) about the same time, the driver on the left must yield to the driver on the right.","imageName":"","correctAnswer":43170,"isBookmarked":0,"aid":"43170#43171#43172#43173","text":"The driver of the vehicle on the left must yield to the driver of the vehicle on the right#The driver of the vehicle on the right must yield to the driver of the vehicle on the left#The driver of the vehicle that turns left must yield to the driver of the vehicle that turns right#The driver of the vehicle that turns right must yield to the driver of the vehicle that turns left"},
{"testId":11,"qid":486,"description":"If you want to pass a motorcycle, you must:","explanation":"A motorbike has the legal right to the whole width of a lane. To pass a motorbike, you must do it in an adjacent lane. You are not permitted to share the motorbike lane.","imageName":"202006041920372833.jpg","correctAnswer":22577,"isBookmarked":0,"aid":"22576#22577#22579#26274","text":"not pass in an adjacent lane#pass in an adjacent lane#use the rightmost lane to pass#use the same lane as the motorcycle"},
{"testId":11,"qid":487,"description":"The speed limit __________ in Georgia is 65 miles.","explanation":"The maximum speed restriction on an urban interstate or a multilane divided highway in Georgia is 65 mph. A rural interstate has a speed restriction of 70 miles per hour.","imageName":"","correctAnswer":43174,"isBookmarked":0,"aid":"1622#1623#43174#43175","text":"on a rural interstate#on an unpaved county road#on an urban interstate#in a residential district"},
{"testId":11,"qid":488,"description":"This symbol denotes:","explanation":"This sign alerts cars to the presence of a divider, median, or other obstruction ahead. Continue straight.","imageName":"202003151752201841.jpg","correctAnswer":8963,"isBookmarked":0,"aid":"8963#19345#22298#22299","text":"a divider ahead#a roundabout ahead#a blind curve ahead#a right turn ahead"},
{"testId":11,"qid":489,"description":"If you cannot be seen by ____________, do not do a U-turn on a curve or near the top of a hill.","explanation":"Make a U-turn on a curve or at the top of a hill if other cars coming from either direction cannot see you. Also, do not do a U-turn when signs forbid it.","imageName":"","correctAnswer":22342,"isBookmarked":0,"aid":"22341#22342#22344#28693","text":"drivers ahead of you#drivers approaching from either direction#drivers stopped on the shoulder#drivers on your right"},
{"testId":11,"qid":490,"description":"When there are two or more lanes of traffic going in the same direction, slower cars should use :","explanation":"Slower cars should utilize the rightmost lane when there are two or more lanes of traffic travelling in the same direction, except passing or making a left turn.","imageName":"","correctAnswer":9153,"isBookmarked":0,"aid":"8639#8640#9153#9154","text":"The left lane#The center lane#the right lane, except when passing or making a left turn#the left lane, except when passing or making a left turn"},
{"testId":11,"qid":491,"description":"If a train is approaching, you must always stop ______ from the nearest rail of a railroad crossing.","explanation":"If a train is coming, you must always stop between 15 and 50 feet from the closest rail of a railroad crossing. (Trains are at least six feet wider than the rails they travel on, so stay away from them.) Flashing lights, lowered crossing gates, or the sound of an incoming train's horn are all indicators that a train is approaching.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#5593#31175#43176","text":"between 15 and 50 feet#between 5 and 15 feet#between 10 and 15 feet#between 10 and 50 feet"},
{"testId":11,"qid":492,"description":"The white line placed across each approach lane at this crossroad signifies:","explanation":"At a junction, a stop line is a solid white line painted across the approach lane. Before the crosswalk, there is a stop line. If you are required to stop by a stop sign or traffic light, you must halt before the stop line. The stop line is normally four feet before the crossing in metropolitan areas.","imageName":"202002211517161385.jpg","correctAnswer":31300,"isBookmarked":0,"aid":"19291#31300#31301#43177","text":"the point beyond which the road is closed#the point before which your vehicle must stop#the point beyond which you must not increase your speed#the point beyond which you must not make a right turn"},
{"testId":11,"qid":493,"description":"When you drive through a work zone, you shouldn't:","explanation":null,"imageName":"","correctAnswer":6515,"isBookmarked":0,"aid":"6515#22447#22448#34709","text":"Increase your speed#Prepare for the unexpected#Watch for speed limit signs#change lanes to leave more room for workers"},
{"testId":11,"qid":494,"description":"Which of the following are the penalties in Georgia for a first offense of driving under the influence of intoxicants?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41831#43178#43179","text":"All of the above#A fine of up to $1,000#Up to 12 months in jail#Mandatory suspension of driving privileges"},
{"testId":11,"qid":495,"description":"You must use your headlights in Georgia:","explanation":"In Georgia, you are required to use your headlights from half an hour after nightfall to half an hour before daybreak, as well as when it is raining or visibility is poor.","imageName":"","correctAnswer":31574,"isBookmarked":0,"aid":"31572#31573#31574#43180","text":"from one hour after sunset to one hour before sunrise#from two hours after sunset to two hours before sunrise#from half an hour after sunset to half an hour before sunrise#from 45 minutes after sunset to 45 minutes before sunrise"},
{"testId":11,"qid":497,"description":"Horizontal rectangular signs are most commonly used as:","explanation":"Generally, horizontal rectangular signs are utilized as guidance signs. They display specific places, instructions, or other information. Roads at the next highway junction, roadside services ahead, or other sites of interest may be indicated with guide signs.","imageName":"","correctAnswer":8877,"isBookmarked":0,"aid":"8874#8875#8876#8877","text":"warning signs#instruction signs#regulatory signs#guide signs"},
{"testId":11,"qid":498,"description":"Two solid yellow lines in the center of the road signify that passing is:","explanation":"Two solid yellow lines divide lanes of traffic flowing in opposing directions and identify the center of a road. Passing is prohibited on both sides of the lines. You may only cross the lines to perform a left turn.","imageName":"","correctAnswer":40286,"isBookmarked":0,"aid":"40284#40285#40286#40287","text":"allowed on both sides#allowed on one side#not allowed on either side#allowed only for heavy vehicles"},
{"testId":41,"qid":498,"description":"Two solid yellow lines in the center of the road signify that passing is:","explanation":"Two solid yellow lines divide lanes of traffic flowing in opposing directions and identify the center of a road. Passing is prohibited on both sides of the lines. You may only cross the lines to perform a left turn.","imageName":"","correctAnswer":40286,"isBookmarked":0,"aid":"40284#40285#40286#40287","text":"allowed on both sides#allowed on one side#not allowed on either side#allowed only for heavy vehicles"},
{"testId":11,"qid":499,"description":"Each occupant in the front seat of a passenger car in Georgia is required by law to:","explanation":null,"imageName":"","correctAnswer":43181,"isBookmarked":0,"aid":"27796#27798#27799#43181","text":"use proper helmets#have a valid driver's license#carry a proper toolbox#be secured by a safety belt"},
{"testId":11,"qid":500,"description":"What is the meaning of this sign?","explanation":"This sign informs that the road ahead takes an abrupt right bend. Slow down and continue with care.","imageName":"202002061258239664.jpg","correctAnswer":1669,"isBookmarked":0,"aid":"1074#1669#1670#1671","text":"No right turn#Sharp turn to the right#Curve to the right#Traffic merging from the right"},
{"testId":11,"qid":501,"description":"Keep your hands at ________ on the steering wheel at all times while driving.","explanation":"Generally, you should maintain your hands on the steering wheel at 9 o'clock and 3 o'clock. Some manufacturers, however, advocate keeping your hands at 8 o'clock and 4 o'clock. To find out which position is optimal for your car, see your owner's handbook or contact the manufacturer. Nota bene: For many years, the preferred positions were 10 a.m. and 2 p.m. However, in modern autos equipped with airbags, these positions may be dangerous. The driver's airbag deploys quickly from the steering wheel. If your hands are high on the steering wheel, the deploying airbag may push them into your face or harm them.","imageName":"","correctAnswer":43182,"isBookmarked":0,"aid":"41219#41220#41222#43182","text":"the 9 o'clock and 2 o'clock positions#the 10 o'clock and 3 o'clock positions#the 10 o'clock and 2 o'clock positions#the 9 o'clock and 3 o'clock positions"},
{"testId":11,"qid":503,"description":"On a split roadway, unless directed otherwise by a sign, traffic control device, or police officer, you must:","explanation":"On a divided roadway, unless ordered differently by a sign, traffic control device, or police officer, you must maintain to the right of the median.","imageName":"","correctAnswer":28488,"isBookmarked":0,"aid":"26337#28486#28488#28489","text":"drive on the center of the median#keep left of the median#keep right of the median#not keep right of the median"},
{"testId":11,"qid":504,"description":"What is the meaning of this sign?","explanation":"No is represented as a red circle with a diagonal red slash. The red slash across the truck denotes that trucks are not permitted on this route. Truck drivers must use a different route.","imageName":"202003301723251644.jpg","correctAnswer":31632,"isBookmarked":0,"aid":"31632#43183#43184#43185","text":"Trucks are not allowed on this road#Heavy trucks should take a detour#High-occupancy vehicles (HOVs) are not allowed on this road#Emergency vehicles are not allowed on this road"},
{"testId":11,"qid":505,"description":"On long expressway trips, you should _____ to stay alert and avoid highway hypnosis.","explanation":"Highway hypnosis is a state of lethargy or unawareness caused by monotony, the sound of the wind, the tires hitting the pavement, and the continuous hum of the engine. Exercise your eyes on lengthy travels to help keep you attentive. Continue to adjust your gaze from one region of the road to another, focusing on numerous items both close and distant, left and right.","imageName":"","correctAnswer":32085,"isBookmarked":0,"aid":"26261#32085#32086#43186","text":"take stimulants#exercise your eyes#send text messages#talk on the phone"},
{"testId":11,"qid":507,"description":"If you're following a motorcycle, keep a following distance of at least:","explanation":"Normally, you should allow two seconds of following space behind the car in front of you. If the vehicle in question is a motorcycle, however, increase your following distance to three or four seconds to allow the biker adequate time to maneuver or stop in an emergency.","imageName":"","correctAnswer":31499,"isBookmarked":0,"aid":"31499#31500#32925#43187","text":"three seconds#Two seconds#One second#four car lengths"},
{"testId":11,"qid":508,"description":"What is the meaning of this sign?","explanation":"This warning sign indicates that you are about to approach a railroad crossing. Slow down, look about, listen carefully, and be prepared to stop if required.","imageName":"202001282004545744.jpg","correctAnswer":133,"isBookmarked":0,"aid":"54#133#27808#27809","text":"A one-way road is ahead#A railroad crossing is ahead#The road is closed ahead#Road repairs are ahead"},
{"testId":11,"qid":509,"description":"When you're on the road with a big truck or bus, you need to watch out for:","explanation":"Large commercial vehicles, such as trucks, have significant blind areas known as No-Zones. There are no-zones in the vehicle's front, back, and sides. It is difficult to avoid all No-Zones on a vehicle. However, do not stay in a No-Zone for any longer than is required to safely pass a truck. Never, ever tailgate a truck.","imageName":"","correctAnswer":43188,"isBookmarked":0,"aid":"32118#32119#32120#43188","text":"the large mirrors of the vehicle#the weight of the vehicle#the length of the vehicle#the side, rear, and front No-Zones of the vehicle"},
{"testId":11,"qid":510,"description":"When you stop for a person in a crosswalk on a road with more than two lanes, you should stop at least :","explanation":"When overtaking stopped automobiles on multilane roadways, proceed with utmost care. Unseen pedestrians may be crossing in a designated or unmarked crosswalk. This is a common cause of pedestrian accidents. Stop at least 10 feet before a crossing on a multilane road so that a car in the adjacent lane can see the pedestrian.","imageName":"","correctAnswer":43191,"isBookmarked":0,"aid":"43189#43190#43191#43192","text":"10 feet after the crosswalk#15 feet before the crosswalk#10 feet before the crosswalk#20 feet before the crosswalk"},
{"testId":11,"qid":512,"description":"What is the meaning of this sign?","explanation":"The right lane is shutting ahead, according to this construction zone sign. When cars may merge into your lane or employees are working near traffic on the road, change lanes away from them if feasible or move slightly inside your lane to create some extra space between you and those risks.","imageName":"202004281837539992.jpg","correctAnswer":26319,"isBookmarked":0,"aid":"26318#26319#28073#28666","text":"The left lane is closed ahead#The right lane is closed ahead#There is a narrow bridge ahead#Traffic is merging on the right"},
{"testId":11,"qid":513,"description":"When the flashing yellow lights are on, all drivers coming up to _____ must follow the speed limit on the sign.","explanation":"School zones are vulnerable regions because children may cross the street from all directions when school is in session. Signs that read \"Speed Restriction When Flashing\" are placed in front of schools to signal a lower speed limit in a school zone. Above a speed limit, these signs contain yellow lights. When the yellow lights flash, you must respect the reduced speed restriction.","imageName":"","correctAnswer":1246,"isBookmarked":0,"aid":"319#1246#1717#38568","text":"a railroad crossing#a school zone#an expressway#a work zone"},
{"testId":11,"qid":514,"description":"If a person is 21 years of age or older and has a blood alcohol content of ________ per 100 mL of blood, the person is considered to be under the influence of alcohol.","explanation":"It is illegal for anybody to drive a car while under the influence of alcohol, drugs, or any other substance. A person above the age of 21 is deemed to be under the influence of alcohol if their blood alcohol content is 0.08 grams or more per 100 mL of blood. This number is often stated as a percentage: 0.08 percent.","imageName":"","correctAnswer":43194,"isBookmarked":0,"aid":"43193#43194#43195#43196","text":"less than 0.08 gm#0.08 gm or more#0.02 gm#between 0.02 and 0.06 gm"},
{"testId":11,"qid":516,"description":"This symbol can be found on the back of:","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22520#29177#29774","text":"a slow-moving vehicle#a school bus#a vehicle carrying hazardous materials#an emergency vehicle"},
{"testId":11,"qid":517,"description":"When there is a turning lane for vehicles going in both directions, you should use :","explanation":"Use considerable care as you move into the middle left-turn lane to perform a left turn. It's possible that cars moving the other way are also using the lane to make left turns. In crowded locations, this is extremely difficult.","imageName":"","correctAnswer":26015,"isBookmarked":0,"aid":"26013#26015#34055#41014","text":"extreme caution to make a right turn#extreme caution to make a left turn#a left turn signal to make a right turn#hazard lights to make a right turn"},
{"testId":11,"qid":519,"description":"In the event that a tire blows out, you should:","explanation":"DO NOT PANIC if you have a sudden tire rupture. Also, don't stop too hard or you'll lock the wheels and lose control. Only use the brakes softly if required and safe to do so. Take a tight grip on the steering wheel and release your foot from the pedal to enable your car to come to a complete halt. Do not go to the road's shoulder until you have slowed significantly. If the blowout has led your car to veer into the shoulder, do not attempt to return to the pavement right away. Allow the car to come to a complete stop first.","imageName":"","correctAnswer":43198,"isBookmarked":0,"aid":"43197#43198#43199#43200","text":"immediately brake hard#grasp the steering wheel firmly#keep your foot on the accelerator#immediately move onto the shoulder of the road"},
{"testId":11,"qid":520,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist is indicating a right turn.","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":14,"qid":521,"description":"Except when otherwise posted, the top speed limit in Illinois on interstates, tollways, freeways, and certain four-lane roads is:","explanation":"Except otherwise otherwise marked, the maximum speed limit for all vehicles in Illinois is 70 mph on interstates, tollways, freeways, and certain four-lane roads.","imageName":"","correctAnswer":685,"isBookmarked":0,"aid":"523#685#1676#1745","text":"20 mph#70 mph#35 mph#40 mph"},
{"testId":14,"qid":522,"description":"When an emergency vehicle approaches, you must:","explanation":"When an emergency vehicle approaches you utilizing audible and visible indications, you must immediately pull over to the right side of the road and allow the emergency vehicle to pass.","imageName":"","correctAnswer":29019,"isBookmarked":0,"aid":"29018#29019#29020#29021","text":"pull your vehicle to the left side of the road#pull your vehicle to the right side of the road#increase your speed and pass the emergency vehicle#reduce your speed and keep moving until the emergency vehicle passes you"},
{"testId":14,"qid":524,"description":"A driver who causes physical damage to a kid in a school crossing zone is subject to_______.","explanation":"A motorist who causes physical injury to a kid or crossing guard in a school crossing zone faces up to a year in jail, a $25,000 fine, and a one-year suspension of driving privileges.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#23210#29022#29023","text":"All of the above#fines#suspension of driving privileges#imprisonment"},
{"testId":14,"qid":525,"description":"What is the meaning of this sign?","explanation":"A speed zone is indicated by the bigger sign ahead. The speed restriction is shown by the smaller sign.","imageName":"202002061119294360.jpg","correctAnswer":19296,"isBookmarked":0,"aid":"19295#19296#19298#29024","text":"Drive at a speed of 45 mph#A speed zone is ahead; be prepared to reduce your speed to 45 mph#Construction ahead; reduce your speed to 45 mph#Vehicles are merging ahead; do not increase your speed to 45 mph"},
{"testId":14,"qid":526,"description":"When more than one vehicle approaches at a four-way stop, who has the right of way?","explanation":"When more than one vehicle comes to a four-way stop, the first car to halt shall be the first to go. When two cars on separate roads come at a four-way stop at the same moment, the vehicle on the left must yield to the vehicle on the right.","imageName":"","correctAnswer":26340,"isBookmarked":0,"aid":"26340#26341#26342#26343","text":"The first vehicle to stop should be the first to go#The last vehicle to stop should be the first to go#The vehicle that signals first should be the first to go#The larger vehicle should be the first to go"},
{"testId":14,"qid":527,"description":"If you see a pedestrian crossing an unmarked crosswalk without a traffic light, you should :","explanation":"When pedestrians are in a designated or unmarked crossing on your side of the road and there are no traffic control signals, you must surrender the right of way to them.","imageName":"","correctAnswer":29028,"isBookmarked":0,"aid":"29025#29026#29027#29028","text":"proceed slowly because there are no signals#warn the pedestrian and proceed with caution#turn on your emergency flashers and proceed with caution#yield the right of way to the pedestrian"},
{"testId":14,"qid":528,"description":"You may pass on a two-lane, two-way street.","explanation":"Within 100 feet of an intersection or railroad crossing, passing is prohibited. Passing is prohibited in school zones and when a vehicle has come to a complete stop at a crosswalk or crossroads to enable a pedestrian to cross.","imageName":"","correctAnswer":29031,"isBookmarked":0,"aid":"7856#29029#29030#29031","text":"in a school zone#when passing a bicyclist or pedestrian who is at least 2 feet from your vehicle#when a vehicle has stopped at a crosswalk or intersection to allow a pedestrian to cross#when you are more than 200 feet from an intersection"},
{"testId":14,"qid":529,"description":"What is the meaning of this sign?","explanation":"Before twists and bends, several warning signs are put. The arrow's form indicates the twisty route ahead. This route will shortly bend right, then left, then right again, as indicated by this sign.","imageName":"202002061310151663.jpg","correctAnswer":29033,"isBookmarked":0,"aid":"27865#29032#29033#29034","text":"The road is slippery when wet#There are a right merge and left merge ahead#There is a winding road ahead#There is a steep incline ahead"},
{"testId":14,"qid":530,"description":"You might come upon this warning sign:","explanation":"This sign alerts you to a change in direction or a road narrowing. Several of these signs may be found on the outside of a sharp curve or on approaches to a narrow bridge.","imageName":"202003301742532030.jpg","correctAnswer":29038,"isBookmarked":0,"aid":"29035#29036#29037#29038","text":"where a lane ends#near a winding road#in a construction zone#on the outside of a sharp curve"},
{"testId":14,"qid":531,"description":"A driver with his or her left arm bent at 90 degrees and pointed downward aims to:","explanation":"If your vehicle's turn signals are not functioning, use hand signals. Your hand and arm should be bent at 90 degrees and pointing up for a right turn. For a left turn, stretch your hand and arm straight out to the left. To slow down or halt, bend your hand and arm 90 degrees and point down.","imageName":"","correctAnswer":22345,"isBookmarked":0,"aid":"28#8990#10156#22345","text":"Make a left turn#make a right turn#Go straight#slow down or stop"},
{"testId":14,"qid":532,"description":"On curves and slopes, you must not make a U-turn unless you can see for at least ________ in all directions.","explanation":"On curves and slopes, you must not do a U-turn unless you can see for at least 500 feet in all directions.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":14,"qid":533,"description":"You may _________ on your cell phone while driving.","explanation":"You may not compose, send, or view a text message while driving unless the vehicle is stopped on the shoulder of the road and the gearshift is in neutral or park. These limitations do not apply to GPS devices or navigational equipment.","imageName":"","correctAnswer":29039,"isBookmarked":0,"aid":"1782#1783#1784#29039","text":"type a text message#read a text message#send a text message#not type, send, or read a text message"},
{"testId":14,"qid":534,"description":"When following a vehicle at night, you should always:","explanation":"Always lower your headlights while following any vehicle at night. When trailing a truck, this is extremely critical. Because they reflect off the huge side mirrors, bright lights will blind the driver of the enormous car.","imageName":"","correctAnswer":29040,"isBookmarked":0,"aid":"22735#29040#29041#29042","text":"use your high-beam headlights#dim your headlights#use your emergency flashers#drive in the No-Zones"},
{"testId":14,"qid":535,"description":"You should maintain a _____ following distance behind a motorcycle.","explanation":"To allow the biker ample time to react in an emergency, maintain a following distance of at least three to four seconds. Motorcycles can stop more rapidly than cars in dry circumstances.","imageName":"","correctAnswer":1789,"isBookmarked":0,"aid":"427#1788#1789#1790","text":"5 seconds#1 to 2 seconds#3 to 4 seconds#5 to 6 seconds"},
{"testId":14,"qid":536,"description":"When turning left when a bike enters the junction from the opposite direction, you should :","explanation":"You must surrender the right of way to incoming vehicles while turning left.","imageName":"","correctAnswer":29045,"isBookmarked":0,"aid":"29043#29044#29045#29046","text":"increase your speed and cross the intersection#honk your horn several times to alert the bicyclist#yield the right of way to the bicyclist#go straight and make a turn at the next intersection"},
{"testId":14,"qid":537,"description":"What is the meaning of this sign?","explanation":"A two-way left turn lane in the middle of a roadway is indicated by this regulation sign. Yellow lines and white turning arrows designate the two-way left turn lane as well.","imageName":"202003301721209480.jpg","correctAnswer":16066,"isBookmarked":0,"aid":"3175#16066#29047#29048","text":"A two-way road ahead#A two-way left turn lane#A two-way right turn lane#Two-way merging traffic ahead"},
{"testId":14,"qid":538,"description":"You are exceeding the posted speed limit in a construction zone when no personnel are present. The fine for your first infraction is:","explanation":"Whether or not employees are present, the minimum punishment for speeding in a construction zone is $375 for a first offense and $1,000 for a second offense.","imageName":"","correctAnswer":29049,"isBookmarked":0,"aid":"8626#29049#29050#29051","text":"None of the above#at least $375#at least $500#at least $1,000"},
{"testId":14,"qid":539,"description":"It is prohibited to drive with a blood alcohol content (BAC) of :","explanation":"Driving is banned if your blood alcohol content (BAC) is 0.08 percent or above. However, if your BAC is less than 0.08 percent and your driving ability is compromised, you might be convicted of Driving Under the Influence (DUI).","imageName":"","correctAnswer":29055,"isBookmarked":0,"aid":"29052#29053#29054#29055","text":"0.06% or more#0.04% or more#between 0.06% and 0.08%#0.08% or more"},
{"testId":14,"qid":540,"description":"This symbol indicates that you are currently on a:","explanation":"This warning sign indicates that you are leaving a divided highway and will be traveling on a two-way street.","imageName":"202002101251206942.jpg","correctAnswer":19200,"isBookmarked":0,"aid":"19200#27094#27095#29056","text":"two-way road#divided highway#one-lane bridge#one-way road"},
{"testId":14,"qid":541,"description":"What should you do if you are involved in a collision that damages an unattended vehicle or other property?","explanation":"If you are involved in an accident that damages an unattended car (no driver or passenger present) or other property, you must stop your vehicle in a safe location and leave your name, address, phone number, and license plate number on the vehicle or property. If you cannot locate the owner, call the police and fill out the necessary accident reports.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#29057#29058#29059","text":"Do all of the above#Stop your vehicle in an area away from traffic#Leave your name, address, phone number, and license plate number on the vehicle or property, if you cannot find the owner#Notify the police"},
{"testId":14,"qid":542,"description":"A traffic signal with a flashing yellow arrow indicates that you should:","explanation":"A flashing yellow light indicates that you should slow down and continue with care. When extreme care is necessary, this signal is used.","imageName":"","correctAnswer":8214,"isBookmarked":0,"aid":"27#8214#23840#29060","text":"Change lanes#proceed with caution#stop and be prepared to obey the next signal#stop and yield to oncoming traffic"},
{"testId":14,"qid":543,"description":"Which of the following is NOT TRUE about lines on the road?","explanation":"Double solid white lines divide traffic lanes traveling in the same direction. They are often used to differentiate between high-occupancy vehicle (HOV) lanes and other lanes. It is illegal to cross a double solid white line.","imageName":"","correctAnswer":29062,"isBookmarked":0,"aid":"22586#29061#29062#29063","text":"Broken yellow lines separate traffic moving in opposite directions#Broken white lines separate lanes of traffic moving in the same direction#You are allowed to cross a double solid white line on a highway#You are allowed to cross double solid yellow lines to make a left turn to or from an alley"},
{"testId":14,"qid":544,"description":"You should _______  through a railroad crossing if it has no warning devices or if the only warning is a crossbuck sign.","explanation":"If there are no warning devices or merely a crossbuck sign at a railroad grade crossing, slow down and look and listen for a train before continuing. Any approaching train must be given the right of way.","imageName":"202007150313427489.jpg","correctAnswer":9181,"isBookmarked":0,"aid":"8516#9181#28734#29064","text":"stop within 100 feet of the railroad crossing#slow down and proceed with caution#increase your speed and cross the railroad crossing#shift gears and move through the railroad grade crossing"},
{"testId":14,"qid":545,"description":"This symbol denotes:","explanation":"No is represented as a red circle with a diagonal red slash. The bicycle image below the slash indicates that bicycles are not permitted.","imageName":"202003301726014561.jpg","correctAnswer":29067,"isBookmarked":0,"aid":"29065#29066#29067#29068","text":"only bicyclists are allowed to use this road#a no-passing zone for bicyclists#bicyclists must not use this road#vehicles are not allowed to pass bicyclists"},
{"testId":14,"qid":546,"description":"When you are _____, you may drive on the left side of a multilane highway.","explanation":"Driving on the left is only permissible in certain situations, such as on one-way streets and when passing. On a multilane highway, unless passing, turning left, or avoiding an impediment, you must travel on the right side of the road.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"8979#8980#8981#23040","text":"passing another vehicle#turning left#avoiding an obstruction#Doing any of the above"},
{"testId":14,"qid":547,"description":"This symbol can be found on:","explanation":"This insignia may be seen on the back of any slow-moving vehicle (defined in Illinois as a vehicle with a top speed of no more than 20 mph). When approaching a slow-moving vehicle, you must slow down.","imageName":"202003181417388899.jpg","correctAnswer":8748,"isBookmarked":0,"aid":"8748#29069#29070#29071","text":"slow-moving vehicles#express highways#one-way streets#vehicles carrying hazardous materials"},
{"testId":14,"qid":548,"description":"If a driver behind you continuously flashes his or her headlights, you should:","explanation":"Driving aggressively refers to any behaviour that puts other people or property in danger or poses a high risk of doing so. If a car behind you is flashing his or her headlights frequently, you should not respond or interact with the other driver in any manner. Allow the motorist to pass by by moving out of the way.","imageName":"202006141732221727.jpg","correctAnswer":8631,"isBookmarked":0,"aid":"8631#22716#22717#26185","text":"get out of the way#switch on your high-beam headlights#switch on your low-beam headlights#increase your speed and move ahead"},
{"testId":14,"qid":549,"description":"You should________,while driving in fog.","explanation":"It is not advisable to drive in fog. If you must drive, turn on your low-beam headlights or fog lights. Glare is caused by droplets of moisture from fog or rain reflecting the intense brightness of high-beam headlights.","imageName":"","correctAnswer":29072,"isBookmarked":0,"aid":"24203#29072#29073#29074","text":"Use high-beam headlights#Use low-beam headlights#use parking lights#use emergency flashers"},
{"testId":14,"qid":550,"description":"This sign is frequently seen when:","explanation":"This sign alerts you to an alternate route that has been constructed due to the closure of the main road.","imageName":"202004150009425678.jpg","correctAnswer":26276,"isBookmarked":0,"aid":"8529#26276#29075#29076","text":"traffic is merging ahead#the road is closed ahead#one-way traffic starts#a two-way highway starts"},
{"testId":14,"qid":551,"description":"You should ________ if you start hydroplaning and start to skid.","explanation":"Hydroplaning often occurs at speeds more than 35 mph. Slow down while driving in the rain to prevent hydroplaning. If you slide while hydroplaning, try steering into the skid to restore control of the car. Otherwise, let off the gas and ride out the skid.","imageName":"","correctAnswer":24443,"isBookmarked":0,"aid":"6475#24442#24443#29078","text":"Speed up#switch off the engine#release the accelerator#Brake hard"},
{"testId":14,"qid":552,"description":"What should you do if you abruptly lose control of the steering wheel?","explanation":"If you find yourself losing control of the steering wheel, take your foot off the gas pedal. Turn on your emergency flashers and come to a gradual halt in your car. To keep your car from spinning, use the brakes softly.","imageName":"","correctAnswer":8616,"isBookmarked":0,"aid":"8616#21428#24166#24167","text":"Ease your foot off the gas pedal#Apply the parking brake#Ease your foot off the brake pedal#Shift gears"},
{"testId":14,"qid":553,"description":"Every child passenger under the age of ___ must be restrained in an approved child restraint system in Illinois.","explanation":"Every kid passenger under the age of eight must be fastened in an adequate child restraint system affixed to the vehicle's back seat, according to Illinois law.","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6478#7620#11361","text":"10#12#8#16"},
{"testId":14,"qid":554,"description":"This route marking sign denotes:","explanation":"This sign directs you to turn right onto Route 47 and go north.","imageName":"202003301745219405.jpg","correctAnswer":24237,"isBookmarked":0,"aid":"19307#19308#19309#24237","text":"the end of Route 47#the beginning of Route 47 north#one-way traffic on Route 47 north#you need to make a right turn to enter Route 47 north"},
{"testId":14,"qid":555,"description":"If you pass your exit on an expressway by mistake, you must:","explanation":"If you travel beyond your exit while exiting an expressway, you must go to the following exit. Backing up on expressways is hazardous and illegal.","imageName":"","correctAnswer":8521,"isBookmarked":0,"aid":"8518#8519#8520#8521","text":"back up and return to the exit#make a U-turn and return to the exit#reduce your speed and move into the rightmost lane#continue to the next exit"},
{"testId":14,"qid":556,"description":"It is safer to __________ as you approach a curve.","explanation":"Before going around a bend, slow down. Avoid abrupt braking while on a curve as this might result in locking wheels or skidding. Never cross the centre line while driving.","imageName":"","correctAnswer":8535,"isBookmarked":0,"aid":"6515#8535#29081#29082","text":"Increase your speed#Reduce your speed#apply brakes continuously#drive over the center line"},
{"testId":14,"qid":557,"description":"Loads extending four feet or more to the rear of a vehicle must have a visible red light for _______at night or in low visibility.","explanation":"Loads that extend four feet or more to the back of a vehicle must be identified with a red flag throughout the day. The load must be indicated with a red light visible for 500 feet at night or when visibility is poor.","imageName":"","correctAnswer":20796,"isBookmarked":0,"aid":"14313#20794#20795#20796","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":14,"qid":558,"description":"When driving at 20 mph on the highway, you should drive:","explanation":"Slow-moving cars may travel at rates ranging from 5 to 20 miles per hour. Slower traffic must stay in the correct lane. The left lane is used for passing and turning left.","imageName":"","correctAnswer":29084,"isBookmarked":0,"aid":"7312#9010#29083#29084","text":"on the shoulder of the road#in the center lane#in the left lane#in the right lane"},
{"testId":14,"qid":559,"description":"Except in the cases listed below, a motorist must yield.","explanation":"Right-of-way regulations dictate who goes first in certain scenarios. To yield is to offer another vehicle or pedestrian the right-of-way. When there are cars at the junction, you must yield even after the signal turns green. When making a right turn after stopping at a red signal, you must surrender. When approaching emergency vehicles, you must surrender using both auditory and visual indications. When a road bends to the right or left, there is no need to yield.","imageName":"","correctAnswer":29085,"isBookmarked":0,"aid":"29085#29086#29087#29088","text":"when roads curve to the right or left#when making a right turn on a red light after stopping#when the light is green and there are vehicles already in the intersection#when approaching emergency vehicles using audible and visual signals"},
{"testId":14,"qid":560,"description":"It is legal to pass another vehicle:","explanation":"On a one-way street or a highway with two or more clear lanes in each direction, you may pass on either the left or right (but not on the shoulder) (but not on the shoulder).","imageName":"","correctAnswer":29091,"isBookmarked":0,"aid":"29089#29090#29091#29092","text":"within 100 feet of an intersection#in a construction zone where there are no workers#on a one-way street#in a school zone where there is no school bus"},
{"testId":19,"qid":561,"description":"To avoid tiredness, you should not drive more than _____ and relax every two hours.","explanation":"Do not overwork yourself to the point that you are not physically and mentally attentive at all times. You should take a two-hour break after two hours and limit your driving time to six to eight hours each day.","imageName":"","correctAnswer":31171,"isBookmarked":0,"aid":"31170#31171#31172#31173","text":"12 to 14 hours per day#6 to 8 hours per day#10 to 13 hours per day#10 to 14 hours per day"},
{"testId":19,"qid":562,"description":"If someone is following you too closely, you must:","explanation":"Slow down and urge the motorist behind you to pass if you are being tailgated. If this fails, pull over, come to a complete stop, and allow the tailgater pass.","imageName":"202003201758501730.jpg","correctAnswer":8978,"isBookmarked":0,"aid":"8978#30898#30900#31174","text":"slow down and encourage the driver to pass#increase your speed to avoid the driver#beep your horn to warn the driver#stop your car on the road and argue with the driver"},
{"testId":19,"qid":563,"description":"In inclement weather or on a slippery surface, you should extend your following distance to ___ seconds.","explanation":"Your vehicle's stopping distance may increase in inclement weather or on wet pavement. As a result, extend your typical three-second following distance to at least four seconds.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":19,"qid":565,"description":"If a train is approaching, you must stop ________ from the nearest rail at a railroad crossing.","explanation":"When a train is coming, you need to halt between 15 and 50 feet away from the closest rail at a railroad crossing.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#31175#31176#31177","text":"between 15 and 50 feet#between 10 and 15 feet#between 15 and 20 feet#between 10 and 40 feet"},
{"testId":19,"qid":567,"description":"When challenged by an aggressive driver, do not:","explanation":"When challenged with an aggressive motorist, maintain your cool, avoid eye contact, ignore the driver's gestures, and refuse to reciprocate them. Disengage emotionally and psychologically from the circumstance. Never accelerate up to confront an aggressive motorist.","imageName":"","correctAnswer":31183,"isBookmarked":0,"aid":"26892#31181#31182#31183","text":"Avoid eye contact#ignore gestures#emotionally and mentally disconnect from the situation#challenge the driver by speeding up"},
{"testId":19,"qid":568,"description":"This green traffic light signal means that:","explanation":"After yielding to pedestrians and cars already at the junction, a green light allows you to go through the intersection.","imageName":"202003070857473536.jpg","correctAnswer":31185,"isBookmarked":0,"aid":"31184#31185#31186#31187","text":"you must not proceed through the intersection#you may proceed through the intersection#you must come to a complete stop at the intersection and proceed with caution#you must come to a complete stop and make a right turn at the intersection"},
{"testId":19,"qid":569,"description":"Which of the following is an indication of road rage?","explanation":"Road rage is shown by cutting off or swerving in front of other cars. Excessive horn use, pushing a vehicle off the road, and tailgating are all symptoms of road rage.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#1905#31188","text":"All of the above#Tailgating#Forcing a car off the road#Sounding the horn excessively"},
{"testId":19,"qid":570,"description":"Your estimated stopping distance at 50 mph in ideal conditions is:","explanation":"Under ideal circumstances, a motor vehicle will normally need 268 feet to stop from a speed of 50 mph. The stopping distance may dramatically lengthen in bad weather or on slick roadways.","imageName":"","correctAnswer":31190,"isBookmarked":0,"aid":"31189#31190#31191#31192","text":"106 feet#268 feet#305.7 feet#161.5 feet"},
{"testId":19,"qid":571,"description":"What kind of parking are common in parking lots and retail centers?","explanation":"In parking lots and commercial malls, angle parking is often employed. Keep a safe distance from any oncoming or departing vehicles as you pull into an angle parking place.","imageName":"","correctAnswer":1912,"isBookmarked":0,"aid":"1910#1911#1912#1913","text":"Right-side parking#Parallel parking#Angle parking#Left-side parking"},
{"testId":19,"qid":572,"description":"What does this panel of successive arrows mean?","explanation":"Around the clock, sequential arrow panels may be employed in work zones. This one indicates that the lane ahead is closed and that you should use the lane to your left.","imageName":"202004281747478346.jpg","correctAnswer":31193,"isBookmarked":0,"aid":"19319#31193#31194#31195","text":"The left lane is winding ahead#The lane is closed ahead#The lane is open to traffic ahead#Flag persons (flaggers) are ahead"},
{"testId":19,"qid":573,"description":"While the vehicle is moving, if you turn your ignition key to the lock position,you may:","explanation":"When your car is in motion, never turn your ignition key to the lock position. This will cause the steering to lock, resulting in a loss of control. Since 1969, the steering lock has been standard equipment. Its purpose is to prevent a burglar from directing your automobile if he starts it without the key.","imageName":"","correctAnswer":31197,"isBookmarked":0,"aid":"8535#31196#31197#31198","text":"Reduce your speed#shorten the stopping distance#lose control of the vehicle#easily make turns"},
{"testId":19,"qid":574,"description":"When approaching or following another vehicle within 500 feet of another vehicle, you must use _________ according to Louisiana law.","explanation":"When you are within 500 feet of an incoming vehicle or following another vehicle within 200 feet, you must use low-beam headlights. Because they reflect off the vehicle's mirrors, high beams may blind the driver.","imageName":"","correctAnswer":294,"isBookmarked":0,"aid":"294#295#296#1923","text":"Low-beam headlights#High-beam headlights#parking lights#bright lights"},
{"testId":19,"qid":575,"description":"A double solid white line on the road separates two lanes of vehicle moving:","explanation":"Crossing a double solid white line divides two lanes of traffic driving in the same direction. Double solid white lines are often used on interstates to separate a high-occupancy vehicle (HOV) lane from other lanes heading in the same direction.","imageName":"202003202134094720.jpg","correctAnswer":9028,"isBookmarked":0,"aid":"9028#9029#9031#26362","text":"Moving in the same direction#Merging left#Merging right#Moving in opposite directions"},
{"testId":19,"qid":576,"description":"What is the meaning of this sign?","explanation":"This construction zone sign shows that employees are undertaking road repair. Reduce your speed and, if feasible, change lanes to preserve a safe distance from the employees.","imageName":"202002101222518967.jpg","correctAnswer":31201,"isBookmarked":0,"aid":"31199#31200#31201#31202","text":"Deep excavation ahead; stop your vehicle#Flag person (flagger) ahead; take a detour#Workers ahead; slow down#Pedestrians ahead; yield the right-of-way"},
{"testId":19,"qid":577,"description":"You must come to a complete stop ___________away from a halted school bus that is loading or unloading students.","explanation":"Whether or whether you are heading in the same direction as the school bus, you must stop at least 30 feet away from a halted school bus that is loading or unloading students.","imageName":"","correctAnswer":31204,"isBookmarked":0,"aid":"3091#4182#31203#31204","text":"at least 25 feet#at least 15 feet#at least 20 feet#at least 30 feet"},
{"testId":19,"qid":579,"description":"Which of the following blind spot statements is FALSE?","explanation":"Every automobile has blind zones. Blind zones are often greater in larger vehicles. Tractor-trailers and buses have significant blind areas known as No-Zones, where your vehicle might seem to vanish from the driver's perspective.","imageName":"","correctAnswer":9034,"isBookmarked":0,"aid":"9034#31205#31206#31207","text":"Larger vehicles have smaller blind spots#Don't drive in another vehicle's blind spot#The best way to compensate for blind spots is to turn and glance over your shoulder#There are blind spots to the left rear and right rear of your vehicle"},
{"testId":19,"qid":580,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending school zone. Slow down, continue with care, and be prepared to stop if schoolchildren cross the road ahead.","imageName":"202003151740461521.jpg","correctAnswer":102,"isBookmarked":0,"aid":"102#19347#31208#31209","text":"A school zone is ahead#A playground is ahead#A pedestrian crosswalk is ahead#A bus stop is ahead"},
{"testId":19,"qid":581,"description":"When two vehicles arrive at an uncontrolled intersection at almost the same time,:","explanation":"When two cars approach an uncontrolled junction at about the same moment, the one on the left must yield to the one on the right.","imageName":"","correctAnswer":31213,"isBookmarked":0,"aid":"31210#31211#31212#31213","text":"the vehicle on the left must go first#the larger vehicle must go first#the vehicle turning left must go first#the vehicle on the right must go first"},
{"testId":19,"qid":582,"description":"When parallel parked, your vehicle's right wheels must be within ________ of the street's right curb.","explanation":"When parallel parking, your vehicle's right wheels must be within 18 inches of the street's right curb.","imageName":"","correctAnswer":4921,"isBookmarked":0,"aid":"816#4921#5486#31214","text":"25 inches#18 inches#20 inches#30 inches"},
{"testId":19,"qid":583,"description":"A flashing yellow arrow indicates:","explanation":"A flashing yellow arrow indicates that you should continue with care in the direction indicated by the arrow.","imageName":"","correctAnswer":30869,"isBookmarked":0,"aid":"24459#30868#30869#30870","text":"you must not proceed in the direction of the arrow#you must come to a complete stop in the direction of the arrow#you may proceed with caution in the direction of the arrow#you may go straight"},
{"testId":19,"qid":584,"description":"What is the meaning of this sign?","explanation":"This sign advises you that you are about to exit a divided highway and enter a two-way undivided route. Keep an eye out for cars coming from the other way.","imageName":"202002101251206942.jpg","correctAnswer":19324,"isBookmarked":0,"aid":"54#19323#19324#19325","text":"A one-way road is ahead#Lane crossing is allowed#Two-way traffic is ahead#An expressway or freeway is ahead"},
{"testId":19,"qid":585,"description":"It is prohibited in Louisiana for more than ________ people to occupy the front seat of a moving vehicle.","explanation":"It is prohibited for more than three people to occupy the front seat of a moving car in Louisiana. You must not operate a vehicle that is overloaded with people or anything else that may obscure your vision or interfere with your control of the vehicle in any direction. Also, according to Louisiana law, the driver and all passengers must wear seat belts or proper child restraints, and most automobiles have no more than three pairs of seat belts in the front seat.","imageName":"","correctAnswer":1963,"isBookmarked":0,"aid":"1962#1963#1964#3406","text":"two persons#three persons#one person#the driver"},
{"testId":19,"qid":586,"description":"Which of the following things about speed limits is NOT TRUE?","explanation":"Try to maintain a constant pace when driving. Drive at a speed that is acceptable for the road and weather conditions at the time and within the stated speed limit. However, avoid driving so slowly that you impede other vehicles.","imageName":"","correctAnswer":31218,"isBookmarked":0,"aid":"31215#31216#31217#31218","text":"A frontal impact at 35 mph is one-third more violent than one at 30 mph#You must not drive slower than the posted minimum limit#In Louisiana, it is illegal to drive at more than 70 mph except on portions of I-49#As long as you are not exceeding the posted speed limit, you can drive at any speed"},
{"testId":19,"qid":587,"description":"The most common sort of collisions on interstate highways are:","explanation":"The most common form of accident on interstates is a rear-end collision. Tailgating is the most common cause of rear-end crashes (following the vehicle ahead too closely). Maintain a three-second following space between yourself and the car ahead. Increase the following distance to at least four seconds when the weather is severe or the pavement is slippery.","imageName":"","correctAnswer":1970,"isBookmarked":0,"aid":"1969#1970#1971#1972","text":"Head-on collisions#Rear-end collisions#Side collisions#Truck collisions"},
{"testId":19,"qid":588,"description":"This symbol indicates that:","explanation":"This sign forewarns of a steep right bend. Slow down and continue with care.","imageName":"202002061258239664.jpg","correctAnswer":31222,"isBookmarked":0,"aid":"31219#31220#31221#31222","text":"you must stop at the end of the curve#you must maintain a speed of 50 mph on the curve#you must merge into the right lane#you must prepare for a sharp right turn"},
{"testId":19,"qid":589,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":"Sometimes you have to deal with risks one by one. If you are going to pass a bicycle and an incoming automobile is approaching, slow down and let the car pass first to give the bicycle more space. Then move to the left to provide enough space to pass the bicycle.","imageName":"202007151849158328.jpg","correctAnswer":31225,"isBookmarked":0,"aid":"24142#31223#31224#31225","text":"Sound your horn to alert the bicyclist#Pass the bicycle first to leave extra room for the oncoming car#Leave a little room for the oncoming car while passing the bicycle#Slow down and let the car pass first to leave extra room for the bicycle"},
{"testId":19,"qid":590,"description":"You must not _________ if you miss your exit on an interstate highway.","explanation":"Do not stop or back up if you miss your exit on an interstate highway. This is illegal since it raises the likelihood of an accident. Instead, take the next exit.","imageName":"202007210432373628.jpg","correctAnswer":31226,"isBookmarked":0,"aid":"27#9891#31226#31227","text":"Change lanes#take the next exit#stop or back up#brake"},
{"testId":19,"qid":591,"description":"In dense fog or severe precipitation, you must reduce your speed and :","explanation":"Slow down and put on your low-beam headlights if there is fog or heavy rain. (High-beam light tends to bounce off precipitation, generating glare.) If the fog is so thick that it hinders your eyesight, you should cautiously get off the road at a safe location and refrain from driving at all.","imageName":"","correctAnswer":31228,"isBookmarked":0,"aid":"8970#9989#29080#31228","text":"turn off your headlights#Turn on your emergency flashers#turn on your high-beam headlights#turn on your low-beam headlights"},
{"testId":19,"qid":592,"description":"What is the meaning of this sign?","explanation":"This sign instructs all cars to go straight. This lane is not for turning.","imageName":"202004071531435210.jpg","correctAnswer":31229,"isBookmarked":0,"aid":"23827#24092#31229#31230","text":"There is a stop sign ahead#There is a one-way street ahead#You must go straight ahead#Look straight ahead while driving"},
{"testId":19,"qid":593,"description":"If your vehicle becomes entangled on a railroad track, you must:","explanation":"If your car becomes stuck on a railroad track and a train approaches, do not waste time attempting to release it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction of the incoming train. If your vehicle is hit, you and your passengers will be protected from flying debris.","imageName":"","correctAnswer":31234,"isBookmarked":0,"aid":"31231#31232#31233#31234","text":"try to move your vehicle off the tracks as soon as possible#shift gears continuously to move your vehicle off the tracks#turn on your emergency flashers to warn the train's engineer#get everyone, including yourself, out of your vehicle and get away from it"},
{"testId":19,"qid":595,"description":"If an authorized emergency vehicle approaches with audible or visual signals, you must:","explanation":"If an authorized emergency vehicle approaches you utilizing audible or visible indications, you must surrender the right-of-way to it. Pull over to the right-hand curb or road edge, halt, and stay stopped until the emergency vehicle passes.","imageName":"","correctAnswer":31235,"isBookmarked":0,"aid":"31235#31236#31237#31238","text":"immediately pull over to the right-hand curb or edge of the road and stop#increase your speed and move into the left lane#slow down and proceed#continue driving in the same lane and let the emergency vehicle pass you"},
{"testId":19,"qid":596,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the legal maximum speed restriction is 40 miles per hour. This is the fastest you can lawfully go under perfect circumstances.","imageName":"202003301757383903.jpg","correctAnswer":31240,"isBookmarked":0,"aid":"31239#31240#31241#31242","text":"You must not drive slower than 40 mph#You must not drive faster than 40 mph#You must drive slower than 40 mph#Slow-moving vehicles must not exceed a speed of 40 mph"},
{"testId":19,"qid":597,"description":"If you're 21 or older and your BAC is ___, you'll lose your license for 90 days.","explanation":"If you are 21 or older and chemical testing reveals that you have a blood alcohol content (BAC) of 0.08 percent or more, your driving privileges will be banned for 90 days. Your driving privileges will be banned for 180 days if you are under the age of 21 and your BAC is 0.02 percent or higher. (The least level detected by today's most routinely used chemical tests is 0.02 percent. This practically means there is no tolerance for minors drinking and driving.)","imageName":"","correctAnswer":31244,"isBookmarked":0,"aid":"31243#31244#31245#31246","text":"0.06% or above#0.08% or above#0.02% or above#0.09% or above"},
{"testId":19,"qid":599,"description":"Before merging with traffic on an expessway or freeway, you must:","explanation":"Before merging with highway traffic, move into the acceleration lane to join an expressway or freeway.","imageName":"","correctAnswer":24173,"isBookmarked":0,"aid":"1455#24172#24173#24174","text":"reduce your speed#move into the deceleration lane#move into the acceleration lane#move into the right-hand lane"},
{"testId":19,"qid":600,"description":"What is the meaning of this sign?","explanation":"This caution sign signals that a stop sign is ahead. Slow down and ready to come to a complete halt at the stop sign.","imageName":"202002101139461433.jpg","correctAnswer":19211,"isBookmarked":0,"aid":"19211#30254#31247#31248","text":"A stop sign is ahead#A traffic signal is ahead#Lanes merge ahead#A dead end is ahead"},
{"testId":15,"qid":601,"description":"What should you do initially if you have a blowout or a flat tire?","explanation":"If you get a flat tire or a blowout, keep the car straight by gripping the steering wheel tightly. Remove your foot from the accelerator but do not touch the brakes. Allow the vehicle to gradually slow down, pull off the road, and apply the brakes when it is practically stopped.","imageName":"","correctAnswer":19085,"isBookmarked":0,"aid":"19085#29687#29688#29689","text":"Hold the steering wheel firmly#Pull off the road#Apply the brakes#Maintain your speed"},
{"testId":15,"qid":603,"description":"When performing a U-turn, you must NOT:","explanation":"When performing a U-turn, you must give way to approaching traffic and pedestrians. Never do a U-turn on a bend in the road or as you near the top of a hill or gradient.","imageName":"","correctAnswer":29692,"isBookmarked":0,"aid":"8637#29690#29691#29692","text":"give a turn signal#yield the right of way to pedestrians#yield the right of way to oncoming vehicles#make it on a curve of the road or near a hill"},
{"testId":15,"qid":604,"description":"What is the meaning of this sign?","explanation":"A railroad crossing is indicated by the signal ahead. If the signal is flashing, slow down and prepare to stop.","imageName":"202003181410217334.jpg","correctAnswer":133,"isBookmarked":0,"aid":"133#19330#19331#19332","text":"A railroad crossing is ahead#A train station is ahead#A railroad track parallel to the roadway is ahead#An abandoned railroad track is ahead"},
{"testId":15,"qid":605,"description":"The maximum speed restriction in a highway construction zone is at least _____ lower than the maximum speed limit for the region.","explanation":"The maximum speed restriction in a highway work zone is at least 10 mph lower than the area's maximum authorized speed limit.","imageName":"","correctAnswer":2429,"isBookmarked":0,"aid":"520#523#2429#15720","text":"15 mph#20 mph#10 mph#5 mph"},
{"testId":15,"qid":606,"description":"To give yourself enough distance to stop, you should stay at least _____ behind the vehicle in front of you.","explanation":"To give yourself enough time to stop, keep at least two to three seconds behind the vehicle in front of you. To ascertain this, observe when the car in front of you passes a fixed item and estimate how much time it takes you to reach that same thing.","imageName":"","correctAnswer":29693,"isBookmarked":0,"aid":"1288#4097#29693#29694","text":"four seconds#five seconds#two to three seconds#nine to ten seconds"},
{"testId":15,"qid":607,"description":"If there is a tornado in the area, you are required to:","explanation":"Stop and leave your car immediately if a tornado is close. Find a structure with a basement or cellar and seek refuge inside if feasible. Otherwise, find a low-lying spot, squat, and protect your head.","imageName":"","correctAnswer":29696,"isBookmarked":0,"aid":"29695#29696#29697#29698","text":"drive as fast as possible to avoid it#stop, exit your vehicle immediately, and go to a low-lying area#take a detour to the top of a bridge#close all the doors and turn on your headlights"},
{"testId":15,"qid":608,"description":"What is the meaning of this sign?","explanation":"This is a regulatory indicator. You have the option of continuing straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":29702,"isBookmarked":0,"aid":"29699#29700#29701#29702","text":"Merge with the traffic on the right#Merge with the traffic on the left#Yield to the traffic on the left#You may either turn left or go straight"},
{"testId":15,"qid":609,"description":"What should you do if fog totally closes in while you're driving, reducing visibility to near zero?","explanation":"Use your low-beam headlights and fog lights while driving in fog. However, if fog totally closes in and visibility is reduced to near zero, drive as far off the road as possible and come to a complete stop.","imageName":"202006041923008386.jpg","correctAnswer":22349,"isBookmarked":0,"aid":"9161#9164#22349#29703","text":"Use your low beams#Use your high beams#Carefully pull as far off the road as possible and stop#Slow down and take a detour"},
{"testId":15,"qid":610,"description":"When vision is reduced, you must use low beams when driving:","explanation":"When you are within 500 feet of an incoming vehicle or following another vehicle within 200 feet, you must use low beams. Because they reflect off the vehicle's mirrors, high beams may blind the driver.","imageName":"","correctAnswer":29706,"isBookmarked":0,"aid":"29704#29705#29706#29707","text":"within 200 feet of an oncoming vehicle#approaching a railroad crossing at night#within 500 feet of an oncoming vehicle#approaching an intersection or traffic island at night"},
{"testId":15,"qid":611,"description":"If your disc or drum brakes fail unexpectedly, you should:","explanation":"If your vehicle's disc or drum brakes suddenly fail, move to a lower gear as soon as feasible to slow down. Then, multiple times, quickly and forcefully press the brake pedal. This may provide enough braking pressure to bring your car to a halt. You may also try using the parking brake, but be prepared to remove it if the back wheels lock.","imageName":"","correctAnswer":29708,"isBookmarked":0,"aid":"29708#29709#29710#29711","text":"shift to a lower gear if possible, and pump the brake pedal fast and hard several times#shift to a higher gear if possible, and coast#shift to a higher gear if possible, and pump the brake pedal fast and hard several times#pull off the road and stop"},
{"testId":15,"qid":612,"description":"This symbol denotes:","explanation":"Traffic advice signs notify drivers of the sort of route they are on, future highway entrances and exits, and distances to different destinations. This sign directs you to Interstate Highway 22.","imageName":"202003301737564419.jpg","correctAnswer":8802,"isBookmarked":0,"aid":"8802#8803#19333#19334","text":"the interstate highway number#the exit number#the interstate highway speed limit#the distance to the nearest exit"},
{"testId":15,"qid":613,"description":"To reduce glare from incoming headlights, avoid looking straight at the lights of the approaching vehicle. Instead,:","explanation":"The glare of approaching headlights may impair eyesight. Prevent looking straight into the headlights of an oncoming car to avoid glare. Instead, concentrate on the right edge of the road to help you steer.","imageName":"","correctAnswer":29712,"isBookmarked":0,"aid":"29712#29713#29714#29715","text":"focus on the right side of the road#focus on the left side of the road#focus on the center of the road#focus on the speed of the vehicle"},
{"testId":15,"qid":614,"description":"Slow down to ____ for the school zone while driving near a school.","explanation":"If you are driving in a school zone, you must slow down to the designated school zone speed limit. Local governments may, however, impose reduced speed restrictions in school zones while children are present.","imageName":"","correctAnswer":29716,"isBookmarked":0,"aid":"1676#1677#2074#29716","text":"35 mph#45 mph#10 mph below the established speed limit#the posted speed limit"},
{"testId":15,"qid":615,"description":"You must proceed _____, to enter a roundabout.","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. The roundabout is entered and exited by turning right.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"64#29717#29718#29719","text":"toward the left#in the opposite direction of traffic#in a clockwise direction#in a counterclockwise direction"},
{"testId":15,"qid":616,"description":"This service indicator informs you that:","explanation":"This is a service announcement. There are lodging options available.","imageName":"202004071555209170.jpg","correctAnswer":19338,"isBookmarked":0,"aid":"19335#19336#19338#29720","text":"hospital service is available#a service area is available#lodging is available#parking is available"},
{"testId":15,"qid":617,"description":"If a driver is under the influence of ____________, the likelihood of an accident increases.","explanation":"Drowsiness and other adverse effects from alcohol, certain illegal substances, some prescription pharmaceuticals, and some over-the-counter medicines may make it difficult to drive.","imageName":"","correctAnswer":29721,"isBookmarked":0,"aid":"9037#9038#9039#29721","text":"a cup of coffee#a cup of tea#softly playing music#alcohol, some illicit drugs, or some prescription medications"},
{"testId":15,"qid":618,"description":"When you get on an on-ramp to an interstate, stay to the right and ______ to let your car join the traffic.","explanation":"To merge effectively with fast-moving traffic on highways, good judgment and timing are required. Stay to the right while entering an interstate on-ramp and raise your speed in the acceleration lane to enable your automobile to join with traffic when your path is clear.","imageName":"","correctAnswer":2087,"isBookmarked":0,"aid":"2087#2088#2089#2090","text":"increase your speed in the acceleration lane#decrease your speed in the acceleration lane#increase your speed in the deceleration lane#decrease your speed in the deceleration lane"},
{"testId":15,"qid":619,"description":"Indiana law mandates the use of seat belts by the driver and all passengers whenever a vehicle is :","explanation":"Every year, seat belts and kid safety devices such as car seats save thousands of lives and boost the odds of surviving an accident. When a vehicle is in operation, Indiana law requires the driver and all passengers to wear seat belts at all times.","imageName":"","correctAnswer":29725,"isBookmarked":0,"aid":"29722#29723#29724#29725","text":"traveling at a speed greater than 50 mph#traveling at a speed greater than 35 mph#traveling on highways#in operation"},
{"testId":15,"qid":620,"description":"What does this symbol indicates?","explanation":"Yellow lines divide traffic lanes traveling in opposing directions. Except to turn, do not cross a solid yellow line.","imageName":"202004281845489896.jpg","correctAnswer":29726,"isBookmarked":0,"aid":"8626#29726#29727#29728","text":"None of the above#Vehicles must not cross the solid yellow line, except to turn#Vehicles must not cross the solid yellow line, except to pass#Vehicles must not cross the dashed white line under any circumstances"},
{"testId":15,"qid":621,"description":"When crossing railroad tracks, you must avoid:","explanation":"Never begin crossing railroad tracks until they can be cleared without pausing. If you alter gears while crossing, your car may stall.","imageName":"","correctAnswer":2101,"isBookmarked":0,"aid":"2099#2101#28698#28700","text":"using brakes#stopping or shifting gears#Using low-beam headlights#Using high-beam headlights"},
{"testId":15,"qid":622,"description":"When a vehicle turns, the rear wheels will follow a ________ than the front wheels.","explanation":"When a vehicle rotates, the rear wheels travel a lesser distance than the front wheels. The larger the difference, the longer the vehicle. Before turning, tractor-trailers may swing out. When you notice a tractor-trailer moving to the left, it might be going to turn right. Examine its turn signals.","imageName":"","correctAnswer":2102,"isBookmarked":0,"aid":"2102#2103#2104#2105","text":"shorter path#longer path#slower path#faster path"},
{"testId":15,"qid":623,"description":"When you see an emergency vehicle with flashing lights that is stopped, you must :","explanation":"You must slow down if you observe a halted emergency vehicle with flashing lights. Then, if it is safe to do so, move into a lane that is not adjacent to the emergency vehicle. Otherwise, move slowly and with care.","imageName":"","correctAnswer":29729,"isBookmarked":0,"aid":"6515#8657#22544#29729","text":"Increase your speed#move into the left lane#continue at the same speed#slow down and move into a nonadjacent lane"},
{"testId":15,"qid":624,"description":"This symbol denotes:","explanation":"This is a sign indicating a construction zone. It warns of a 1,000-foot detour.","imageName":"202002101218598750.jpg","correctAnswer":29730,"isBookmarked":0,"aid":"29730#29731#29732#29733","text":"a detour in 1,000 feet#the closing of the lane in 1,000 feet#road work in 1,000 feet#a stop sign in 1,000 feet"},
{"testId":15,"qid":625,"description":"Passing other vehicles within ________ of an intersection, railroad crossing, bridge, viaduct, or tunnel is unlawful and hazardous.","explanation":"When you are within 100 feet of an intersection, railroad crossing, bridge, viaduct, or tunnel, you are not permitted to pass other cars.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#1296#2781","text":"100 feet#200 feet#500 feet#600 feet"},
{"testId":15,"qid":626,"description":"While approaching a STOP sign, you are required to:","explanation":"When approaching a stop sign, you must come to a complete stop at the solid white stop line or perpendicular to the stop sign. If there is no stop line, you should come to a complete stop before approaching the crossing on your side of the junction.","imageName":"","correctAnswer":24290,"isBookmarked":0,"aid":"24095#24096#24289#24290","text":"stop at the broken yellow stop line#stop at the edge of the road#stop at the broken white stop line#stop at the solid white stop line"},
{"testId":15,"qid":627,"description":"Which of these statements is true about driving on freeway?","explanation":"Slower cars should utilize the right lane on the highway, leaving the left lane open for quicker or overtaking vehicles.","imageName":"","correctAnswer":29735,"isBookmarked":0,"aid":"29734#29735#29736#29737","text":"Slower vehicles should use the left lane, and faster vehicles should use the right lane#Slower vehicles should use the right lane, and faster vehicles should use the left lane#Faster vehicles should pass slower vehicles on the right#Faster vehicles must enter the highway through a deceleration lane"},
{"testId":15,"qid":628,"description":"What is the meaning of this sign?","explanation":"This is a warning sign indicating the presence of a small bridge ahead. Slow down and continue with care.","imageName":"202002101245562248.jpg","correctAnswer":29740,"isBookmarked":0,"aid":"29033#29738#29739#29740","text":"There is a winding road ahead#The road ahead will merge; use caution#There is an underpass ahead; do not stop#There is a narrow bridge ahead; use caution and go slow"},
{"testId":15,"qid":629,"description":"At an intersection, a flashing red light is similar to:","explanation":"At an intersection, a flashing red light is comparable to a stop sign. You must come to an immediate halt.","imageName":"","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#14127#19269","text":"a stop sign#a yield sign#a flashing yellow light#a Do Not Enter sign"},
{"testId":15,"qid":630,"description":"Allow the other car to pass safely and _________ if another vehicle is overtaking you in the left lane.","explanation":"If another car passes you on the left, let the other vehicle to pass safely and do not accelerate.","imageName":"","correctAnswer":8939,"isBookmarked":0,"aid":"8657#8938#8939#29741","text":"move into the left lane#increase your speed to follow the vehicle#do not increase your speed#move to the right edge of the road"},
{"testId":15,"qid":631,"description":"Most residential areas in cities have a speed limit of:","explanation":"There are at least 50,000 individuals in urban areas. Do not exceed 30 mph or the stated speed limit in most urban residential neighborhoods.","imageName":"","correctAnswer":29743,"isBookmarked":0,"aid":"24347#29742#29743#29744","text":"50 mph#60 mph or the posted speed limit#30 mph or the posted speed limit#45 mph or the posted speed limit"},
{"testId":15,"qid":632,"description":"This symbol denotes:","explanation":"This sign indicates that there is a yield sign ahead. Prepare to slow down and yield to existing cars on the road.","imageName":"202002101144205880.jpg","correctAnswer":19343,"isBookmarked":0,"aid":"19342#19343#19344#19345","text":"A stop sign ahead#A yield sign ahead#a traffic signal ahead#a roundabout ahead"},
{"testId":15,"qid":633,"description":"What is the first rule for turning legally and safely?","explanation":"The first guideline of making a safe and legal turn is to enter the correct lane long before the turn. Unless otherwise indicated by signs, signals, or road markings, move into the leftmost lane to turn left. Unless otherwise indicated by signs, signals, or road markings, move into the rightmost lane to turn right.","imageName":"","correctAnswer":24100,"isBookmarked":0,"aid":"6238#10578#24098#24100","text":"Reduce your speed#Increase your speed#Cut corners while turning#Move into the proper lane well before the turn"},
{"testId":15,"qid":634,"description":"In the event of an accident, you must:","explanation":"If you are engaged in an accident, you must stop and submit information to the other parties involved. Leaving the scene of an accident is a serious offense.","imageName":"202006141611292838.jpg","correctAnswer":29745,"isBookmarked":0,"aid":"29745#29746#29747#29748","text":"stop and provide information to others involved in the accident#leave the scene of the accident#not provide information to others involved in the accident#stop other vehicles on the road"},
{"testId":15,"qid":635,"description":"A yield sign warns drivers to slow down and be prepared to ________ if a vehicle or pedestrian with the right-of-way approaches from the opposite direction.","explanation":"A yield sign signals that you should slow down as you approach a junction and be prepared to stop if a vehicle or pedestrian having the right-of-way approaches from the other direction. When approaching a yield sign, a vehicle driving from the other direction with the right of way should not have to stop to prevent colliding with you.","imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"125#354#758#29749","text":"increase your speed#make a U-turn#stop#turn"},
{"testId":15,"qid":636,"description":"What is the meaning of this sign?","explanation":"This is a roundabout speed advisory sign. This roundabout's suggested speed restriction is 15 mph.","imageName":"202002061123479108.jpg","correctAnswer":19346,"isBookmarked":0,"aid":"2154#2155#2156#19346","text":"Speed advisory at exit#Speed limit ahead#Speed advisory on ramp#Speed advisory at roundabout"},
{"testId":15,"qid":637,"description":"Never turn ________ while driving a vehicle equipped with a steering wheel interlock device.","explanation":"Never switch the ignition to the lock position while driving a car equipped with a steering wheel interlock device. As the steering wheel is twisted, the steering will lock, resulting in a loss of vehicle control.","imageName":"","correctAnswer":2160,"isBookmarked":0,"aid":"2160#29750#29751#29752","text":"the ignition to the lock position#the gearshift into a higher position#the headlights off#the headlights on"},
{"testId":15,"qid":638,"description":"If you approach an intersection with a non-working signal, you should:","explanation":"If you're approaching an intersection with a broken signal, you should come to a complete stop before proceeding. After coming to a complete stop, give the right of way to cross traffic and continue with great care.","imageName":"","correctAnswer":29753,"isBookmarked":0,"aid":"29753#29754#29755#29756","text":"stop before entering the intersection#stop after entering the intersection#slow down at the intersection and proceed with caution#make a right turn at the intersection"},
{"testId":15,"qid":639,"description":"What does the flashing arrow panels indicate?","explanation":null,"imageName":"","correctAnswer":29757,"isBookmarked":0,"aid":"19342#19343#22595#29757","text":"A stop sign ahead#A yield sign ahead#No entry ahead#A detour ahead"},
{"testId":15,"qid":640,"description":"What does this number represent?","explanation":"Drivers are warned of impending road conditions and risks via warning signs. This sign suggests that there is a playground ahead.","imageName":"202002101157359063.jpg","correctAnswer":1348,"isBookmarked":0,"aid":"1252#1348#2168#38939","text":"A school zone ahead#A playground ahead#A child care center ahead#A 鈥淭鈥 intersection ahead"},
{"testId":17,"qid":641,"description":"Under normal circumstances, the two-second rule should be followed to maintain a safe following distance. However, in adverse conditions, you should use:","explanation":"A safe following distance between your automobile and the car ahead is at least two seconds under normal circumstances. It is at least four seconds in difficult circumstances.","imageName":"","correctAnswer":30201,"isBookmarked":0,"aid":"30201#30202#30203#30541","text":"the four-second rule#the three-second rule#the two-second rule#the five-second rule"},
{"testId":17,"qid":642,"description":"You are NOT required to halt when:","explanation":"A flashing yellow light indicates that you should slow down and use care. Yield to any existing pedestrians or traffic at the junction. But you don't have to quit.","imageName":"","correctAnswer":30543,"isBookmarked":0,"aid":"30542#30543#30544#30545","text":"directed to do so by a flag person or traffic control device at railroad crossings#you see a flashing yellow light#a school crossing guard is displaying an official flag in a STOP position#an emergency vehicle is approaching you while displaying flashing red lights or sounding a siren"},
{"testId":17,"qid":643,"description":"Stopping on the shoulder is authorized only:","explanation":"Except in an emergency or if your car is inoperable, stopping on the shoulder is banned.","imageName":"","correctAnswer":30546,"isBookmarked":0,"aid":"24481#24482#24483#30546","text":"to make a right turn#to make a left turn#to pick up or let off passengers#in an emergency or if your vehicle is disabled"},
{"testId":17,"qid":644,"description":"What is the meaning of this sign?","explanation":"This sign denotes that there is a school zone ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead.","imageName":"202003151742324658.jpg","correctAnswer":30547,"isBookmarked":0,"aid":"15581#30547#30548#30549","text":"There is a hospital ahead#There is a school ahead#There is a park ahead#There is a bus stop ahead"},
{"testId":17,"qid":645,"description":"You must travel in the right lane on the highway since the left lane is reserved for :","explanation":"Generally, you should stay in the right lane. The left lane is used to pass or turn left.","imageName":"","correctAnswer":30550,"isBookmarked":0,"aid":"8749#30550#30551#30552","text":"trucks#passing or turning left#slower vehicles#merging or exiting"},
{"testId":17,"qid":647,"description":"To perform a right turn from a four-lane divided highway, drivers must join the right lane in advance of the turn and maintain :","explanation":"To turn right from a four-lane highway, join the right lane far ahead of time and make a tight turn onto the cross street's right lane.","imageName":"","correctAnswer":8944,"isBookmarked":0,"aid":"8944#8945#8946#24403","text":"a tight turn into the right lane of the cross street#a wide turn into the right lane of the cross street#a tight turn into the left lane of the cross street#a right turn at high speed"},
{"testId":17,"qid":648,"description":"What is the meaning of this sign?","explanation":"This insignia is shown on the back of all slow-moving vehicles, which are defined in Kansas as vehicles with a maximum speed of less than 25 miles per hour.","imageName":"202003181417388899.jpg","correctAnswer":28778,"isBookmarked":0,"aid":"19487#22176#28778#30553","text":"You must slow down#Take a detour#This is a slow-moving vehicle#There is a vehicle ahead carrying hazardous materials"},
{"testId":17,"qid":651,"description":"When driving in inclement weather, you should:","explanation":"In inclement weather, your ability to see ahead and your vehicle's stopping distance may be diminished. Increase your following distance to address these difficulties.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#24203#30554","text":"Increase your speed#Increase your following distance#Use high-beam headlights#make frequent lane changes"},
{"testId":17,"qid":652,"description":"What is the meaning of this sign?","explanation":"A two-way left-turn lane, also known as a shared center left-turn lane, is indicated by this sign. This is a lane in the middle of the road that cars moving in either direction may use to perform left turns. This lane should never be utilized to pass.","imageName":"202003301721209480.jpg","correctAnswer":30557,"isBookmarked":0,"aid":"27859#30555#30556#30557","text":"No turns are allowed in either direction#There is a passing lane in the center of the road#There is a right-turn lane in the center of the road#There is a left-turn lane in the center of the road"},
{"testId":17,"qid":653,"description":"Although most warning signs are diamond-shaped, railroad crossing signs are:","explanation":"While most warning signs are diamond-shaped, railroad crossing signs are circular. They contain black letters or symbols on a yellow backdrop, much as conventional warning signs.","imageName":"","correctAnswer":30558,"isBookmarked":0,"aid":"8626#30558#30559#30560","text":"None of the above#round#triangular#rectangular"},
{"testId":17,"qid":655,"description":"When you parallel park, you must park your vehicle no more than _____ from the curb.","explanation":"When parallel parking, keep your car no more than 12 inches from the curb.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"815#818#5420#10045","text":"15 inches#12 inches#50 inches#10 inches"},
{"testId":17,"qid":656,"description":"This symbol denotes:","explanation":"This sign alerts you to the fact that you are approaching an underpass with a vertical clearance of 13 feet 6 inches.","imageName":"202002061128193125.jpg","correctAnswer":30561,"isBookmarked":0,"aid":"19351#19352#19353#30561","text":"a median ahead; the width of the road is 13 feet 6 inches#a parking area ahead; the width of the road is 13 feet 6 inches#a bridge ahead; the width of the road is 13 feet 6 inches#an underpass ahead; vertical clearance is 13 feet 6 inches"},
{"testId":17,"qid":657,"description":"When approaching or overtaking a school bus, church bus, or daycare bus that has stopped _____, you must come to a complete stop.","explanation":"When approaching or passing a school bus, church bus, or daycare bus that has stopped to pick up or drop off children, you must come to a complete stop. You must stay stopped until the STOP signal is retracted and the red lights turn off. The sole exception is if the bus has come to a halt on the other side of a split roadway. You are not compelled to halt in such scenario.","imageName":"","correctAnswer":30565,"isBookmarked":0,"aid":"30562#30563#30564#30565","text":"on the opposite side of a divided highway#for repair#with its red lights turned off#to pick up or let off children"},
{"testId":17,"qid":658,"description":"When an emergency vehicle approaches from either direction, flashing red lights or sounding a siren, you must :","explanation":"When an emergency vehicle approaches from either side, you must slow down, pull over to the side of the road, and come to a full stop as quickly as possible.","imageName":"","correctAnswer":30566,"isBookmarked":0,"aid":"6515#8657#22544#30566","text":"Increase your speed#move into the left lane#continue at the same speed#pull over to the right and stop"},
{"testId":17,"qid":659,"description":"Which of the following things is NOT TRUE about roundabouts?","explanation":"Yield to automobiles and bikers on the circulating route while approaching a roundabout. Look to your left to determine if there is a suitable traffic gap. (If none are available, you may need to pause until a suitable gap becomes available.) Always enter the roundabout on the right and go around the center island.","imageName":"","correctAnswer":22357,"isBookmarked":0,"aid":"22354#22355#22356#22357","text":"Yield to vehicles and bicyclists within the circulating roadway#Put on your right turn signal to tell drivers that you intend to exit#Don't try to pass bicyclists within the roundabout#Always enter the roundabout to the left and proceed on the left side of the central island"},
{"testId":17,"qid":660,"description":"What is the meaning of this sign?","explanation":"A disabled crossing is indicated by the sign ahead. Slow down and take your time.","imageName":"202002101155455423.jpg","correctAnswer":19354,"isBookmarked":0,"aid":"19354#19355#19356#19357","text":"A handicapped crossing is ahead#A hospital is ahead#Handicapped parking is ahead#Pedestrians are crossing ahead"},
{"testId":17,"qid":661,"description":"If you're 21 or older and your BAC is ____ or more, 聽you may lose your driving privileges.","explanation":"If you are 21 or older and have a blood alcohol content (BAC) of 0.08 percent or greater, your driver's license may be suspended for 30 days.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4253","text":"0.08%#0.02%#0.05%#0.03%"},
{"testId":17,"qid":662,"description":"When turning left at a junction, you must cede the right-of-way to :","explanation":"When turning left at a junction, you must give way to pedestrians, other cars already in the intersection, and approaching traffic. The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You must take all precaution to avoid hitting a person, property, or another vehicle.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#8750#22400#27050","text":"All of the above#pedestrians#oncoming traffic#other vehicles already in the intersection"},
{"testId":17,"qid":663,"description":"When should you use high beams while driving at night?","explanation":"When traveling in wide terrain with no other vehicles close, use high lights. Even with your high lights turned on, you should travel slower than you would during the day.","imageName":"","correctAnswer":30567,"isBookmarked":0,"aid":"10010#29169#30567#30568","text":"On an expressway#Under the influence of alcohol#In open country with no other cars nearby#In the city"},
{"testId":17,"qid":664,"description":"This symbol denotes:","explanation":"This sign indicates that there will be a flag person (flagger) ahead to direct traffic. Prepare to stop and slow down.","imageName":"202002101220385703.jpg","correctAnswer":30570,"isBookmarked":0,"aid":"24373#28586#30569#30570","text":"a police officer ahead#a pedestrian crosswalk ahead#schoolchildren ahead#a flag person (flagger) ahead"},
{"testId":17,"qid":665,"description":"Many rear-end incidents occur on slippery streets throughout the winter because drivers fail to:","explanation":"Many rear-end crashes occur unnecessarily in the winter because many drivers fail to allow a sufficient spacing cushion. Increase your following distance while driving in the winter, particularly in bad weather.","imageName":"","correctAnswer":30572,"isBookmarked":0,"aid":"24167#30571#30572#30573","text":"Shift gears#brake properly#leave a space cushion#turn properly"},
{"testId":17,"qid":666,"description":"Which of the following statements is true about hydroplaning?","explanation":null,"imageName":"","correctAnswer":30576,"isBookmarked":0,"aid":"30574#30575#30576#30577","text":"Partial hydroplaning can start at about 25 mph#Hydroplaning can occur on unpaved roads#Partial hydroplaning can start at about 35 mph#Hydroplaning decreases at high speeds"},
{"testId":17,"qid":667,"description":"When following another vehicle within ____________, you must dim your headlights.","explanation":"When following another vehicle within 300 feet, you must lower your headlights.","imageName":"","correctAnswer":20794,"isBookmarked":0,"aid":"20794#20795#20796#30578","text":"300 feet#400 feet#500 feet#250 feet"},
{"testId":17,"qid":668,"description":"What is the meaning of this sign?","explanation":"This sign generally advises that there will be repair or public utility activities ahead. Slow down and keep an eye out for employees on or near the road.","imageName":"202002101222518967.jpg","correctAnswer":2266,"isBookmarked":0,"aid":"2266#2268#2269#30579","text":"Public utility operations ahead#Channelizing devices ahead#Deep excavation ahead#Road surveyors ahead"},
{"testId":17,"qid":669,"description":"To exit an interstate highway, you should:","explanation":"When exiting an interstate highway, enter the deceleration lane and slow down. However, do not abruptly slow down; this might result in a rear-end crash.","imageName":"","correctAnswer":30582,"isBookmarked":0,"aid":"30580#30581#30582#30583","text":"slow down and exit the lane#increase your speed and exit the lane#move into the deceleration lane and then slow down#use the acceleration lane to exit"},
{"testId":17,"qid":670,"description":"You should never drive motorcyle together in the same lane because:","explanation":"A motorbike, like any other vehicle, requires a complete lane width to drive safely. Even if the road is wide and the motorcyclist is riding to one side, never drive alongside a motorbike in the same lane.","imageName":"202006041913488192.jpg","correctAnswer":26354,"isBookmarked":0,"aid":"22650#22651#26354#29821","text":"motorcycles do not have signal lights#motorcyclists do not need to follow traffic rules#the motorcycle needs the full width of the lane#the motorcyclist might misguide you"},
{"testId":17,"qid":671,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are vast spaces surrounding trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is compromised. No-Zones significantly enhance the chance of a collision.","imageName":"","correctAnswer":236,"isBookmarked":0,"aid":"236#24369#30584#30585","text":"No-Zones#Slow-Zones#Zero-Zones#Safe-Zones"},
{"testId":17,"qid":672,"description":"This symbol indicates that:","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. If you notice a train approaching, prepare to halt. You must also come to a complete stop if you see a set of red flashing lights, a sign, a traffic cop, or a lowered gate.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9044#9045#9046#9047","text":"you are approaching a work zone#traffic is prohibited in this area#you are approaching a railroad crossing#you are approaching an intersection"},
{"testId":17,"qid":673,"description":"Which of the following is true about interstate highways' minimum speed limits?","explanation":"Some roads may have minimum speed limitations. Under normal weather, road, and traffic circumstances, any speed below that specified limit is deemed illegal. Even if no minimum speed limit is stated, it is illegal to drive so slowly that it impedes or blocks regular traffic flow. The only exception is when driving slowly is required for safety reasons.","imageName":"","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30586#30587#30588","text":"All of the above are true#In order not to impede or block the normal movement of traffic, you are not permitted to travel slower than the posted minimum speed#Any speed below the posted minimum speed is unlawful under normal conditions#Where a minimum speed is not posted, it is unlawful to drive a vehicle so slowly as to impede or block the normal movement of traffic"},
{"testId":17,"qid":674,"description":"If no traffic control signals are present, you must slow down or stop for pedestrians in:","explanation":"In Kansas, you must always slow down or stop for pedestrians. You must stop for pedestrians even if they are not at crosswalks or intersections or are jaywalking (crossing illegally or without respect for motor traffic).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6934#8615#22522#22523","text":"An intersection#All of the above#a marked crosswalk#an unmarked crosswalk"},
{"testId":17,"qid":675,"description":"Before making a left or right turn in an urban area, you must signal for at least:","explanation":"Before changing lanes, you must use the proper turn signal. Before making a turn in a city, you must signal for at least 100 feet, or 3 to 5 seconds. When traveling at speeds more than 45 mph, you must signal for at least 200 feet, or 3 to 5 seconds, before making the turn.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#400#2292","text":"100 feet#200 feet#300 feet#60 feet"},
{"testId":17,"qid":676,"description":"A flashing red light signifies that you must ________ before proceeding.","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a full stop before yielding the right-of-way.","imageName":"202003051722347868.jpg","correctAnswer":758,"isBookmarked":0,"aid":"588#758#1018#29749","text":"not stop#stop#slow down and proceed with caution#turn"},
{"testId":17,"qid":677,"description":"Which of the following is TRUE about driving past other vehicles?","explanation":"When there is a solid yellow line adjacent to your lane, you must not pass. A double solid yellow line prevents cars from passing in both directions from crossing the centerline.","imageName":"","correctAnswer":30592,"isBookmarked":0,"aid":"30589#30590#30591#30592","text":"Passing at intersections is allowed#Passing at railroad crossings is allowed if necessary#Passing is allowed within 100 feet of a bridge#Passing is not allowed when there is a solid yellow line next to your lane"},
{"testId":17,"qid":678,"description":"Never make a U-turn on a curve, near the top of a hill, or where another car driver within ______ cannot see you :","explanation":"Do not do a U-turn on a curve, at the top of a hill, or anywhere else where another vehicle cannot see you within 500 feet. Local rules and regulatory signs addressing such turns must be followed.","imageName":"","correctAnswer":20796,"isBookmarked":0,"aid":"20794#20795#20796#30593","text":"300 feet#400 feet#500 feet#1,000 feet"},
{"testId":17,"qid":679,"description":"You're driving down a two-lane road with traffic coming from both directions. You must _____ to pass a vehicle on the left.","explanation":"On a two-lane road with traffic moving in both directions, you may pass a vehicle on the left if it is not prohibited and the pass can be completed safely without exceeding the speed limit. To do this, first signal a left turn. Then when it is safe, move into the left lane to start the pass. After passing the vehicle, signal a right turn. When you have completely cleared the vehicle you passed, return to the right lane.","imageName":"202007141416097956.jpg","correctAnswer":29824,"isBookmarked":0,"aid":"29822#29823#29824#29825","text":"give a right-turn signal before passing and a right-turn signal after passing#give a left-turn signal before passing and a left-turn signal after passing#give a left-turn signal before passing and a right-turn signal after passing#give a right-turn signal before passing and a left-turn signal after passing"},
{"testId":17,"qid":680,"description":"What does this service sign mean?","explanation":"This symbol denotes a service. A hospital service is represented by the letter \"H.\"","imageName":"202003151737026825.jpg","correctAnswer":4774,"isBookmarked":0,"aid":"4774#29219#29220#30594","text":"A hospital service#A help line#A health center#A handicapped service"},
{"testId":27,"qid":681,"description":"To make a turn, you must turn the steering wheel by:","explanation":"When turning bends, use the hand-over-hand approach to turn the steering wheel. If you spin the wheel with just one hand, you may lose control. Straighten out the steering wheel by hand after you've completed the turn. Allowing it to slip between your fingers might be risky.","imageName":"","correctAnswer":22452,"isBookmarked":0,"aid":"22450#22451#22452#34298","text":"the two-hand technique#the hand-to-hand technique#the hand-over-hand technique#the one-hand technique"},
{"testId":27,"qid":682,"description":"You can keep from panicking by:","explanation":"You may prevent panic pauses by spotting threats far in advance and preparing how to cope with them. Stopping abruptly is risky and is frequently an indication of a motorist who is not paying attention. If you stop quickly, you may be rear-ended by the car behind you or you may slide and lose control of your vehicle.","imageName":"","correctAnswer":24553,"isBookmarked":0,"aid":"24553#24554#24555#34299","text":"seeing hazards well in advance#changing lanes quickly#following other vehicles closely#braking quickly"},
{"testId":27,"qid":683,"description":"Maintain both hands on the steering wheel at _______ when driving.","explanation":"Keep both hands on opposing sides of the steering wheel when driving: the nine and three o'clock positions, or the eight and four o'clock positions in newer cars equipped with airbags. The driver's side airbag deploys quickly from the steering wheel. If your hands are higher than nine and three o'clock on the steering wheel, the deploying airbag might shove your hands into your face, harming you.","imageName":"","correctAnswer":28603,"isBookmarked":0,"aid":"28603#28604#28606#34300","text":"the nine o'clock and three o'clock positions#the nine o'clock and four o'clock positions#the eight o'clock and two o'clock positions#the ten o'clock and two o'clock positions"},
{"testId":27,"qid":684,"description":"This symbol denotes:","explanation":"A triangular orange symbol on the back of a car marks it as a slow-moving vehicle, which Montana defines as one that moves at less than 25 miles per hour. Except while engaged in genuine construction or maintenance work and guarded by a flagman or warning signs, such vehicles include horse-drawn vehicles and some road construction equipment.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#8733#8734#29177","text":"a slow-moving vehicle#a stop sign#a yield sign#a vehicle carrying hazardous materials"},
{"testId":27,"qid":686,"description":"Which of the following things about seat belts is NOT TRUE?","explanation":"Every person (including the driver) of a moving vehicle is required by Montana law to wear a safety belt or proper child restraint. Drivers who break the law will be fined $20. According to studies, wearing a safety belt reduces your risks of being harmed or killed in the event of an accident.","imageName":"","correctAnswer":34303,"isBookmarked":0,"aid":"34301#34302#34303#34304","text":"If you are in an accident, your chances of being hurt or killed will be greatly reduced if you wear a safety belt#A driver who violates the seat belt law will be fined $20#Rear-seat passengers don't have to wear seat belts#If your vehicle has both lap belts and shoulder belts, wear both"},
{"testId":27,"qid":688,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This sign states that no right turns are permitted at this location.","imageName":"202003151734593912.jpg","correctAnswer":34306,"isBookmarked":0,"aid":"31591#34305#34306#34307","text":"You must not make a U-turn#You may make a right turn#You must not make a right turn#You must not merge right"},
{"testId":27,"qid":689,"description":"You approach an intersection and notice a yield sign. You have to:","explanation":"You must reduce your speed as you get close to a yield sign and stop for pedestrians and cars coming from another route before continuing. Be prepared to pause if you need to for them as well.","imageName":"","correctAnswer":34309,"isBookmarked":0,"aid":"9048#28740#34308#34309","text":"Slow down and take a detour#Slow down and change lanes#not proceed through the intersection#slow down and yield the right-of-way to all vehicles"},
{"testId":27,"qid":690,"description":"You should look _____ ahead on the road to avoid sudden turns and last-minute braking.","explanation":"You should glance down the road at least 10 seconds ahead of your car to minimize last-minute brakes or quick turns. In the city, 10 seconds equals about one block. On the roadway, 10 seconds equals a quarter mile.","imageName":"","correctAnswer":2336,"isBookmarked":0,"aid":"2336#2337#2338#2339","text":"at least 10 seconds#at least 20 seconds#at least 5 seconds#at least 2 seconds"},
{"testId":32,"qid":690,"description":"You should look _____ ahead on the road to avoid sudden turns and last-minute braking.","explanation":"You should glance down the road at least 10 seconds ahead of your car to minimize last-minute brakes or quick turns. In the city, 10 seconds equals about one block. On the roadway, 10 seconds equals a quarter mile.","imageName":"","correctAnswer":2336,"isBookmarked":0,"aid":"2336#2337#2338#2339","text":"at least 10 seconds#at least 20 seconds#at least 5 seconds#at least 2 seconds"},
{"testId":27,"qid":691,"description":"A \"Speed Zone Ahead\" sign indicates that you are approaching:","explanation":"This is a regulatory indicator. This sign indicates that you are entering an area with a reduced speed restriction. Prepare to slow down so you don't go over the speed limit.","imageName":"","correctAnswer":24178,"isBookmarked":0,"aid":"22300#22303#24178#33374","text":"an expressway or freeway#an area where the minimum speed limit is 70 mph#an area where a lower speed limit has been established#an area where the legal speed limit is 75 mph"},
{"testId":27,"qid":693,"description":"When an emergency vehicle approaches from either direction, flashing red lights or sounding a siren, you must :","explanation":"When a police car, authorized emergency vehicle, or other emergency service vehicle approaches you with its lights flashing or siren active, you must pull over to the right and stop, regardless of which way the emergency vehicle is headed. (However, if you're at a junction, don't stop there. Continue through the junction, then quickly pull over to the right and come to a complete stop.) Continue to stand still until the emergency vehicle has past.","imageName":"","correctAnswer":30566,"isBookmarked":0,"aid":"30566#34316#45381#45382","text":"pull over to the right and stop#increase your speed and yield the right-of-way#stop and wait for the traffic light to change to red or green#stop where you are immediately"},
{"testId":27,"qid":694,"description":"In construction or work zones, the speed limit is:","explanation":"There is no longer a defined speed restriction of 35 mph in construction or work zones. The speed limit is determined by the Department of Transportation, a municipal government, a utility company, or a private contractor. Speeding fines in work zones are doubled.","imageName":"","correctAnswer":34317,"isBookmarked":0,"aid":"8626#14165#24347#34317","text":"None of the above#35 mph#50 mph#set by various organizations"},
{"testId":27,"qid":695,"description":"Which of the following things about school zones is NOT TRUE?","explanation":"In school crosswalks, you must always yield to pedestrians. There may or may not be school crossing guards to help pedestrians cross safely.","imageName":"","correctAnswer":34319,"isBookmarked":0,"aid":"29847#34318#34319#34320","text":"In a school zone, you must reduce your speed to the posted speed limit#Fines are doubled in all school zones throughout Montana#You should not yield to pedestrians in a school crosswalk that lacks a school crossing guard#You should be aware that children have minimal perception of car speeds and distances"},
{"testId":27,"qid":696,"description":"This caution symbol denotes:","explanation":"This sign denotes the presence of a double bend ahead. The road turns to the right and then to the left ahead. (A winding road sign would be placed instead if there was a triple bend coming.) Slow down, stay to the right, and do not pass.","imageName":"202002061305216978.jpg","correctAnswer":22099,"isBookmarked":0,"aid":"22099#45362#45363#45364","text":"the road ahead curves to the right and then to the left#the road ahead curves to the left and then to the right#the road ahead curves sharply to the right#the road ahead curves only to the right"},
{"testId":27,"qid":697,"description":"What should you do if you come across a constant yellow signal as you approach an intersection?","explanation":"A continuous yellow indication indicates that the signal is soon to become red. If you can safely stop, do so. Otherwise, travel cautiously through the crossing.","imageName":"","correctAnswer":28524,"isBookmarked":0,"aid":"15671#28148#28524#34322","text":"Turn left#Proceed with caution#Stop if it is safe to do so#Speed up to get through the light before it turns red"},
{"testId":27,"qid":698,"description":"When approaching or departing a roundabout, you must:","explanation":"When approaching a roundabout, remain to the right of the center island. If the path is clear, do not pause. Circumnavigate the circle until you reach your chosen exit. After that, use your right turn signal to leave the roundabout.","imageName":"","correctAnswer":22593,"isBookmarked":0,"aid":"27#6515#22592#22593","text":"Change lanes#Increase your speed#keep left#keep right"},
{"testId":27,"qid":699,"description":"When a train is clearly visible or within ________ of a railroad crossing, you must come to a complete stop.","explanation":"If a train is plainly visible or within 1,500 feet of a railroad crossing, you must come to a complete stop. (A train traveling at 50 mph will cover 1,500 feet in 20.5 seconds.)","imageName":"","correctAnswer":30190,"isBookmarked":0,"aid":"4433#30190#34323#34324","text":"2,000 feet#1,500 feet#4,500 feet#2,500 feet"},
{"testId":27,"qid":700,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a crossroads or four-way junction ahead. Slow down and be cautious for cross traffic.","imageName":"202002101146277756.jpg","correctAnswer":2372,"isBookmarked":0,"aid":"1584#1701#2372#2373","text":"A hospital ahead#A railroad crossing ahead#A four-way intersection ahead#A side road ahead"},
{"testId":27,"qid":701,"description":"You are now driving inside a roundabout. A vehicle equipped with a siren and/or flashing lights is approaching. What ought you to do?","explanation":"If you are at a junction and an emergency vehicle approaches you with a siren, an air horn, or a red or blue flashing light, you must clear the intersection and move over to the right to allow the emergency vehicle to pass. A roundabout is one example of an intersection. Continue to your exit if you're on a roundabout, then pull over to the right and allow the emergency car pass.","imageName":"","correctAnswer":28093,"isBookmarked":0,"aid":"28093#28094#28095#28096","text":"Continue to your exit, then pull over to the right#Pull over to the right in the roundabout#Pull over to the left in the roundabout#Stop in the roundabout"},
{"testId":27,"qid":702,"description":"A dashed white line adjacent to your lane indicates that:","explanation":"If there is a dashed line adjacent to your lane, you may cross it if it is safe to do so to pass or change lanes.","imageName":"","correctAnswer":34328,"isBookmarked":0,"aid":"34325#34326#34327#34328","text":"you must stay in your lane#you may cross the line only to make a left turn#you may cross the line only to make a right turn#you may cross the line to change lanes"},
{"testId":27,"qid":703,"description":"Separating lanes of traffic going in opposing directions are denoted by _____ lines. :","explanation":"White lines divide traffic lanes traveling in the same direction. Yellow lines divide opposing traffic lanes (lanes of traffic moving in opposite directions).","imageName":"","correctAnswer":34329,"isBookmarked":0,"aid":"14758#14761#34329#34330","text":"red#white#yellow#black"},
{"testId":27,"qid":704,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":34332,"isBookmarked":0,"aid":"34331#34332#34333#34334","text":"A winding road ahead; slow down and use caution#The road ahead is slippery when wet; slow down and use caution#A gravel road ahead; slow down and use caution#A steep ascent ahead; slow down"},
{"testId":27,"qid":705,"description":"Which of the following is true about the lines on the roads?","explanation":"A strong white line between traffic lanes indicates that you should remain in your lane unless there is an emergency that compels you to change lanes.","imageName":"","correctAnswer":22360,"isBookmarked":0,"aid":"22358#22360#34335#34336","text":"A dashed yellow line between opposing lanes of traffic means that you may not cross the line#A solid white line between lanes of traffic means that you should stay in your lane unless a special situation requires you to change lanes#A double solid line between opposing lanes of traffic means that either side can pass#Where there are both a solid yellow line and a dashed yellow line between opposing lanes of traffic, you may pass if the solid line is on your side"},
{"testId":27,"qid":706,"description":"A shared middle lane may be utilized by vehicles:","explanation":"Vehicles moving in both directions may utilize a shared middle lane to perform left turns (and U-turns when permitted). Left-turn arrows for traffic arriving from one way alternate with left-hand arrows for traffic coming from the other direction on the pavement. Never pass in the shared middle lane.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30214#45373#45374","text":"All of the above#making left turns#making U-turns#traveling in both directions"},
{"testId":27,"qid":707,"description":"Except when passing, stay in the ________ lane on a road having three or more lanes traveling in the same way.","explanation":"If there are three or more lanes in your path, remain in the rightmost lane until passing another vehicle. Use one of the center lanes if there is a lot of traffic entering the rightmost lane.","imageName":"","correctAnswer":24431,"isBookmarked":0,"aid":"2392#2393#24431#24432","text":"middle#shoulder#rightmost#leftmost"},
{"testId":27,"qid":708,"description":"What is the meaning of this sign?","explanation":"This notice indicates that deer often cross the road in this location. Be on the lookout for deer and slow down if you spot one. Herds of deer, elk, and other species roam in groups. If you spot one, keep an eye out for others.","imageName":"202002101200345356.jpg","correctAnswer":2394,"isBookmarked":0,"aid":"149#172#175#2394","text":"Forest zone ahead#Cattle crossing ahead#Zoo ahead#Deer crossing ahead"},
{"testId":27,"qid":709,"description":"You can make a U-turn when no other cars are coming if you have:","explanation":"If you have 500 feet (nearly two ordinary city blocks) of uninterrupted visibility in both directions and no cars are coming, you may do a U-turn. It should be noted that most local rules ban U-turns in the center of a block, and others outright prohibit U-turns.","imageName":"","correctAnswer":34343,"isBookmarked":0,"aid":"34340#34341#34342#34343","text":"200 feet of unobstructed view in both directions#300 feet of unobstructed view in both directions#400 feet of unobstructed view in both directions#500 feet of unobstructed view in both directions"},
{"testId":27,"qid":710,"description":"At an intersection with no signs or signals, you must yield to:","explanation":"You must yield to cars coming from the right while approaching an uncontrolled junction (one without signs or signals). However, in order to prevent an accident, you should constantly be prepared to yield.","imageName":"","correctAnswer":8522,"isBookmarked":0,"aid":"8522#8523#8525#26130","text":"vehicles coming from the right#vehicles coming from the left#vehicles approaching from behind#vehicles going straight ahead"},
{"testId":27,"qid":712,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these places, they utilize red flags or stop/slow paddles.","imageName":"202002101220385703.jpg","correctAnswer":45336,"isBookmarked":0,"aid":"45336#45337#45338#45339","text":"Flag person ahead who will stop, slow down, or guide traffic through the area#Police officer ahead who will regulate traffic through the area#You cannot proceed straight ahead, take a detour#Traffic signal ahead; prepare to stop"},
{"testId":27,"qid":713,"description":"Unless otherwise marked, the daytime speed limit on _________ is 70 miles per hour.","explanation":"Except when otherwise marked, the speed limit on two-lane roads during the day is 70 mph.","imageName":"","correctAnswer":2413,"isBookmarked":0,"aid":"2411#2413#34344#34345","text":"interstate highways#two-lane highways#urban area highways#residential streets"},
{"testId":27,"qid":716,"description":"This symbol indicates that:","explanation":"Flaggers are often stationed in highway and street work zones to halt, delay, or direct vehicles through certain areas. Flaggers wear orange vests, shirts, or jackets and guide traffic with red flags or stop/slow paddles. This flagger is allowing traffic to go forward.","imageName":"202002270946508485.jpg","correctAnswer":34348,"isBookmarked":0,"aid":"34346#34347#34348#34349","text":"traffic must come to a complete stop#traffic must slow down#traffic must proceed#traffic must make a U-turn"},
{"testId":27,"qid":717,"description":"Before changing lanes, watch for traffic:","explanation":"Before changing lanes, check that there are no vehicles already in the lane that you want to enter. This means that you must check for traffic behind and to the side of your vehicle.","imageName":"","correctAnswer":9054,"isBookmarked":0,"aid":"9052#9054#28494#28495","text":"behind your vehicle#both behind and to the side of your vehicle#to the side of your vehicle#neither behind nor to the side of your vehicle"},
{"testId":27,"qid":718,"description":"Avoid gazing straight at an incoming vehicle's headlights while encountering traffic at night. Instead, keep an eye on:","explanation":"Avoid gazing straight at incoming cars' headlights while encountering traffic at night. Instead, keep an eye on the right side of the road. Furthermore, if an incoming car flashes his or her headlights at you, double-check that you have muted yours.","imageName":"","correctAnswer":26074,"isBookmarked":0,"aid":"6746#9155#26074#26075","text":"the side mirrors#The center of the roadway#the right edge of the roadway#the left edge of the roadway"},
{"testId":27,"qid":719,"description":"When the road is slick, reduce your speed by about _______ to keep your tires on the road.","explanation":"When the road is slick, your car's tires will not grip as well as they would on a dry road. You should slow down by around 10 mph. When driving on compacted snow, however, reduce your speed by half. When driving on ice, slow down to a crawl or stop completely.","imageName":"","correctAnswer":2429,"isBookmarked":0,"aid":"521#522#1745#2429","text":"30 mph#25 mph#40 mph#10 mph"},
{"testId":27,"qid":720,"description":"What is the meaning of this sign?","explanation":"All public crossings have this railroad crossbuck sign. If a train is approaching, plan to halt at least 15 feet away from the tracks.","imageName":"202003181410217334.jpg","correctAnswer":874,"isBookmarked":0,"aid":"874#2430#2431#34350","text":"Railroad crossing ahead#Road closed ahead due to abandoned crossing#Railroad repair work ahead#No traffic allowed through this crossing"},
{"testId":20,"qid":721,"description":"Your odds of being in an accident increase________, if your blood alcohol level is 0.15 grams per 100 milliliters of blood.","explanation":"Your chance of being in an accident rapidly rises as your level of intoxication rises. Nearly twice as much alcohol is consumed per 100 millilitres of blood as required by law (0.15 grammes) (0.08 grammes per 100 millilitres of blood). Your risk of having an accident is 25 times greater at 0.15 grammes than it is when you are sober. (Note: It takes only four normal drinks for a 180-pound guy to attain that degree of intoxication before his liver begins to break down the alcohol. 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of hard liquor constitute one standard drink.)","imageName":"","correctAnswer":31522,"isBookmarked":0,"aid":"31521#31522#31523#31524","text":"12 times#25 times#5 times#10 times"},
{"testId":20,"qid":722,"description":"A red light with a green arrow indicates that:","explanation":"A red light with a green arrow indicates that you may go gently in the arrow's direction, yielding to all other cars and pedestrians.","imageName":"","correctAnswer":8644,"isBookmarked":0,"aid":"8643#8644#26186#26187","text":"you must stop at the signal, yielding to pedestrians#you may go cautiously in the direction of the arrow, yielding to pedestrians#you must stop and wait until the light turns yellow#you must stop and then proceed slowly, yielding to pedestrians"},
{"testId":20,"qid":723,"description":"If you are caught driving with a blood alcohol level of 0.08 grams per 100 milliliters of blood or 210 liters of breath, you will be arrested.","explanation":"In all jurisdictions, a blood alcohol concentration of 0.08 grams per 100 milliliters of blood, sometimes stated as the percentage 0.08 percent, is the legal criterion for drunkenness. If you are caught driving with that level of intoxication, you will very certainly be arrested and transported to prison. Following your arrest, the Secretary of State will immediately suspend your license based only on the police report and the alcohol level test result, even before you appear in court.","imageName":"","correctAnswer":31527,"isBookmarked":0,"aid":"31525#31526#31527#31528","text":"you will not be charged with operating under the influence (OUI)#your vehicle will be seized by the state authorities#you will be charged with operating under the influence (OUI)#none of the above will be true"},
{"testId":20,"qid":725,"description":"If two vehicles arrive at an uncontrolled intersection about the same time,:","explanation":"When two vehicles approach an uncontrolled junction at about the same moment, the vehicle on the left must yield.","imageName":"","correctAnswer":27044,"isBookmarked":0,"aid":"27044#27045#31529#31530","text":"the driver on the left must yield to the driver on the right#the driver on the right must yield to the driver on the left#the driver on the left must yield to the driver turning right#the driver on the right must yield to the driver turning left"},
{"testId":20,"qid":726,"description":"When an emergency vehicle approaches you from any direction, wailing a siren and flashing a light, you must:","explanation":"When an emergency vehicle approaches from any direction and emits a siren and a flashing light, you must yield. You must immediately drive to the right side of the road, clear of any intersections, and come to a complete stop until the emergency vehicle has past. Failure to do so is a Class E misdemeanor punishable by up to six months in prison and a $1,000 fine.","imageName":"","correctAnswer":31532,"isBookmarked":0,"aid":"31531#31532#31533#31534","text":"immediately drive to the left side of the roadway#immediately drive to the right side of the roadway#follow the emergency vehicle to offer help#increase your speed to clear the way"},
{"testId":20,"qid":727,"description":"The ________ typically provides the smoothest traffic flow on a highway with three or more lanes in each direction.","explanation":"You may maintain a greater gap between your car and other moving objects by driving smoothly. It also aids in gas savings. When driving normally, stay in the lane with the least chaotic flow of traffic. In situations when there are three or more lanes in a single direction, the middle lanes often provide the most efficient traffic flow.","imageName":"","correctAnswer":2456,"isBookmarked":0,"aid":"2456#3594#5477#29770","text":"middle lanes#right lane#left lane#Acceleration lanes"},
{"testId":20,"qid":728,"description":"What is the meaning of this sign?","explanation":"Lane direction signs are what these regulatory signs are. Cars in the left lane must turn left, while vehicles in the right lane may either continue straight or turn right.","imageName":"202004071522592483.jpg","correctAnswer":31538,"isBookmarked":0,"aid":"31535#31536#31538#36505","text":"Vehicles in the left lane must turn left, and vehicles in the right lane must turn right#Vehicles in the left lane must turn left, and vehicles in the right lane must go straight#Vehicles in the left lane must turn left, and vehicles in the right lane may go straight or turn right#Vehicles in the left lane must turn left, and vehicles in the right lane must merge left"},
{"testId":20,"qid":729,"description":"Which of the following conditions allows you to pass a vehicle on the right side?","explanation":"Except in the following two conditions, you shall not overtake another vehicle on the right: 1) The car you are overtaking is in the left lane on a roadway or highway where there are two or more designated lanes for traffic to travel in the same direction; 2) the vehicle you are passing is making a left turn where there is space for two or more lanes to move in the same direction.","imageName":"","correctAnswer":31541,"isBookmarked":0,"aid":"31539#31540#31541#31542","text":"The vehicle you are passing is making a right turn, and there is room for two or more lanes to move in the same direction#The vehicle you are passing is in the left lane on a street or highway with one lane of traffic in each direction#The vehicle you are passing is in the left lane on a street or highway with two or more marked lanes for traffic to move in the same direction#The vehicle you are passing is a truck or other heavy vehicle"},
{"testId":20,"qid":730,"description":"Which of the following is true about turning around?","explanation":"Begin in the lane closest to your destination. If you're turning left, pull out to the intersection's middle and wait with your wheels straight until it's clear to turn. As you turn, keep to the left of the halfway. If you're turning right, begin in the right lane as near to the curb as feasible.","imageName":"","correctAnswer":9166,"isBookmarked":0,"aid":"9166#9167#9168#26291","text":"Start from the lane closest to where you want to go#Always start from the rightmost lane#Always start from the leftmost lane#Start from the lane farthest from where you want to go"},
{"testId":20,"qid":731,"description":"At rotaries (huge traffic circles), vehicles go slowly ___________ around the central island until they reach the desired street.","explanation":"Unless otherwise instructed by a police officer or traffic control equipment, all cars entering a traffic circle or roundabout must give the right of way to vehicles already in the circle or roundabout. In a roundabout, traffic circles the elevated centre island to the right or counterclockwise. At rotaries (huge traffic circles), traffic moves slowly around the central island to the right or counterclockwise until it reaches the target roadway.","imageName":"","correctAnswer":31543,"isBookmarked":0,"aid":"2470#2471#31543#31544","text":"to the left or clockwise#to the right or clockwise#to the right or counterclockwise#to the left or counterclockwise"},
{"testId":20,"qid":732,"description":"What is the meaning of this sign?","explanation":"This sign denotes that there is a school zone ahead. Kids may be crossing the road ahead, so reduce your speed to 15 mph and continue with care when there are schoolchildren present.","imageName":"202003151742324658.jpg","correctAnswer":638,"isBookmarked":0,"aid":"638#640#2473#31545","text":"A school zone#A library#A park#A day-care center"},
{"testId":20,"qid":733,"description":"When you park near a curb, you must park ________from it.","explanation":null,"imageName":"","correctAnswer":2477,"isBookmarked":0,"aid":"2475#2476#2477#2478","text":"no more than 12 inches#no more than 25 inches#no more than 18 inches#no more than 32 inches"},
{"testId":20,"qid":734,"description":"Slow down and glance toward _____ if an incoming car approaches you with its high lights on.","explanation":"Slow down and look to the right side of the road if an incoming car is using its high lights. This will block the vehicle's headlights from blinding you. You should also be able to see enough of the lane's edge to keep on track until the car passes you.","imageName":"","correctAnswer":31547,"isBookmarked":0,"aid":"24945#31546#31547#31548","text":"the center of the road#the left side of the road#the right side of the road#the opposite side of the road"},
{"testId":20,"qid":735,"description":"To maintain your vehicle on a stable course and prevent the necessity for evasive manoeuvres, you need to look:","explanation":"To prevent having to make last-minute adjustments, you must plan ahead of time. Looking 10 to 15 seconds ahead allows you to spot possible risks early and maintain a stable course.","imageName":"","correctAnswer":31551,"isBookmarked":0,"aid":"31549#31550#31551#31552","text":"2 to 5 seconds ahead#40 to 50 seconds ahead#10 to 15 seconds ahead#15 to 30 seconds ahead"},
{"testId":20,"qid":736,"description":"This hand signal signifies the driver's intention to:","explanation":"This hand gesture signifies that the motorist wants to slow down or come to a complete stop. This hand gesture is analogous to a vehicle's brake lights.","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"28#8990#8992#22345","text":"Make a left turn#make a right turn#make a U-turn#slow down or stop"},
{"testId":20,"qid":737,"description":"If you observe a pedestrian using a white cane or a guiding dog on the road, you must ________ and wait until the person is safe.","explanation":"A blind or visually impaired person may use a white cane to navigate safely and independently. A guiding dog may also accompany this person. In any situation, this individual has the right-of-way. Stop at least 10 feet away from a pedestrian using a white cane or a guiding dog in the street and wait until the person is no longer in danger.","imageName":"","correctAnswer":2494,"isBookmarked":0,"aid":"2491#2492#2493#2494","text":"stop at least four feet away#stop at least six feet away#stop at least eight feet away#stop at least ten feet away"},
{"testId":20,"qid":738,"description":"Which of the following is NOT TRUE about train crossings?","explanation":"While crossing the train lines, do not change a manual gearbox. Being in gear allows you to manage the rough terrain easier. There is also the possibility that you may stall on the tracks.","imageName":"202003161136554337.jpg","correctAnswer":31555,"isBookmarked":0,"aid":"31553#31554#31555#31556","text":"If you have to stop in heavy traffic, stop before the tracks, not on them#If red lights are flashing, stop at least 10 feet from the railroad crossing#You may shift a manual transmission while crossing the railroad tracks#If there is more than one set of tracks, don't start to move as soon as one train passes"},
{"testId":20,"qid":739,"description":"Some municipal ordinances in Maine prohibit parking:","explanation":"Parking in a space designated for people with disabilities, next to a fire hydrant, or within 15 feet of a crosswalk are prohibited under several municipal regulations in Maine.","imageName":"","correctAnswer":31560,"isBookmarked":0,"aid":"31557#31558#31559#31560","text":"within 25 feet of a crosswalk#within 20 feet of a fire hydrant#within 30 feet of a fire hydrant#within 15 feet of a crosswalk"},
{"testId":20,"qid":740,"description":"What is the meaning of this sign?","explanation":"This sign advises drivers that the road ahead is slick in the rain. These signs are often seen near bridges and overpasses. The road surface may become slippery due to falling or drifting snow, damp leaves, or wet gravel. Slow down and proceed with care in damp weather.","imageName":"202002101214576723.jpg","correctAnswer":27865,"isBookmarked":0,"aid":"27865#29033#31330#31561","text":"The road is slippery when wet#There is a winding road ahead#There is a hill or slope ahead#Heavy vehicles are not allowed"},
{"testId":20,"qid":741,"description":"High-beam headlights should be used in all of the following situations, except:","explanation":"When there are no other cars around, use high beams. High lights provide double the visibility of low beams. Use high beams while driving on unknown routes, in construction zones, or when there may be people on the road. In fog, snow, or severe rain, however, utilize low lights. Light from high beams bounces off of such precipitation, causing glare.","imageName":"","correctAnswer":9172,"isBookmarked":0,"aid":"9170#9171#9172#28496","text":"On unfamiliar roads#In construction areas#in fog, snow, or heavy rain#Where there may be people alongside the road"},
{"testId":20,"qid":742,"description":"In Maine Unless otherwise marked, the speed limit _________   is 25 mph.","explanation":"Unless otherwise indicated, the speed limit in commercial and residential zones is 25 mph.","imageName":"","correctAnswer":2513,"isBookmarked":0,"aid":"1505#2511#2513#31562","text":"on freeways#in school zones#in residential districts#on state routes"},
{"testId":20,"qid":743,"description":"In heavy rain, your tires may ride on a layer of water and lose touch with the road at higher speeds. This danger is recognized as:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" It might lead to a loss of braking and steering control. Slow down to avoid hydroplaning.","imageName":"202003201654212060.jpg","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8626#9003#31563","text":"Hydroplaning#None of the above#waterplaning#iceplaning"},
{"testId":20,"qid":744,"description":"This symbol denotes:","explanation":"This caution sign signals that a stop sign is ahead. Slow down and ready to come to a complete halt at the stop sign.","imageName":"202002101139461433.jpg","correctAnswer":19342,"isBookmarked":0,"aid":"19342#19343#22594#22595","text":"A stop sign ahead#A yield sign ahead#the lane is closed ahead#No entry ahead"},
{"testId":20,"qid":745,"description":"If the road ahead is slick, you must slow down:","explanation":"If the road ahead is slick, your tires will not have enough traction. You must drive more slowly than you would on a dry road. You must reduce your speed by at least 5 to 10 mph. Reduce your speed in compacted snow by half. Slow down to a crawl on ice.","imageName":"","correctAnswer":31566,"isBookmarked":0,"aid":"31564#31565#31566#31567","text":"by one-third#by half#by 5 to 10 mph#by 15 to 20 mph"},
{"testId":20,"qid":746,"description":"Large flashing arrow panels may be used in work zones to help drivers get:","explanation":"Sequencing or large flashing In work zones, arrow panels may be used to direct cars into certain traffic lanes and to notify them that a portion of the road ahead is blocked. Drivers must slow down and move into the specified lanes.","imageName":"202007210439127901.jpg","correctAnswer":31568,"isBookmarked":0,"aid":"31568#31569#31570#31571","text":"into certain traffic lanes and inform them that part of the road ahead is closed#into certain parking lanes and to inform them that part of the road ahead is closed#into deceleration lanes#into acceleration lanes"},
{"testId":20,"qid":747,"description":"Your headlights must be on:","explanation":"Turning on your headlights shortly before it becomes dark can assist other drivers see you. When traveling from sunset till dawn, you must keep your headlights on.","imageName":"","correctAnswer":34292,"isBookmarked":0,"aid":"31572#31573#31575#34292","text":"from one hour after sunset to one hour before sunrise#from two hours after sunset to two hours before sunrise#from half an hour before sunset to half an hour before sunrise#from sunset to sunrise"},
{"testId":20,"qid":748,"description":"What is the meaning of this sign?","explanation":"This sign warns that there will be a traffic light ahead. These signs are used on routes with higher speed restrictions or when your view of the junction or traffic signal ahead is obscured. Prepare for a junction and a possible red light.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"1585#1701#2021#31576","text":"A traffic signal ahead#A railroad crossing ahead#A stop sign ahead#A flashing light ahead"},
{"testId":20,"qid":749,"description":"On highways, rear-end collisions are frequent because many drivers:","explanation":"Tailgating (following too closely) is the leading cause of rear-end incidents on highways.","imageName":"","correctAnswer":8562,"isBookmarked":0,"aid":"8562#24292#24293#31577","text":"follow too closely#do not use headlights#drive under the influence of alcohol#take too long to apply their brakes"},
{"testId":20,"qid":750,"description":"You will be better protected against accidents on slick roads if you follow the:","explanation":"On slick roads, a three- to four-second following distance may be required to prevent accidents.","imageName":"","correctAnswer":533,"isBookmarked":0,"aid":"532#533#534#2535","text":"two-second rule#four-second rule#five-second rule#one-second rule"},
{"testId":20,"qid":751,"description":"If you observe an oncoming car to your immediate left and a bike to your immediate right while driving, you should:","explanation":"Sometimes dealing with one danger at a time is necessary. If you are going to pass a bicycle and an incoming automobile is approaching, slow down and let the car pass first to give the bicycle more space. Then move to the left to provide enough space to pass the bicycle.","imageName":"","correctAnswer":24356,"isBookmarked":0,"aid":"24355#24356#29172#29826","text":"increase your speed and pass the bicyclist#slow down and let the car pass#stop your vehicle on the shoulder#drive between the car and the bicycle"},
{"testId":20,"qid":752,"description":"This symbol indicates that:","explanation":"Flag personnel (also known as flaggers) often wear orange vests, shirts, or jackets and utilize red flags or stop/slow paddles to safely guide traffic through work zones. In highway and street construction zones, flag personnel are often deployed to halt, slow, or direct vehicles safely through certain locations.","imageName":"202002101220385703.jpg","correctAnswer":30132,"isBookmarked":0,"aid":"30132#30133#30134#31849","text":"There is a flag person ahead, whose signals you must obey#You must go straight#You must increase your speed#You must take a detour"},
{"testId":20,"qid":753,"description":"When changing lanes, entering a highway from an entry lane, or merging with another road, you must maintain a ________ following distance.","explanation":null,"imageName":"","correctAnswer":550,"isBookmarked":0,"aid":"549#550#551#2544","text":"Two-second#Four-second#three-second#Five-second"},
{"testId":20,"qid":754,"description":"When leaving a highway, you should first enter:","explanation":"When departing an expressway, you join a deceleration lane and continue slowing down until you reach the start of the exit ramp.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"8626#23853#26083#31578","text":"None of the above#An acceleration lane#a deceleration lane#an emergency lane"},
{"testId":20,"qid":755,"description":"Which of the following things is NOT TRUE about safety belts?","explanation":"Unrestrained in a collision is much more harmful, regardless of the form of the event. Staying within the safety of your car increases your chances of survival by up to five times. If you wear your safety belt, you are considerably more likely to remain aware and unharmed, and so able to escape if you get trapped.","imageName":"","correctAnswer":31581,"isBookmarked":0,"aid":"31579#31580#31581#31582","text":"In a crash, your safety belt keeps you from being thrown from your vehicle#In a crash, your safety belt slows your body down with your vehicle#If you wear a safety belt, you will be trapped after an accident#Your safety belt helps you stay alert by keeping you from slouching while you drive"},
{"testId":20,"qid":756,"description":"A car with a red-edged orange triangle on the back indicates that it is:","explanation":"A triangular orange insignia on the back of a car designates it as a slow-moving vehicle, which is defined in Maine as a vehicle with a maximum speed of less than 25 mph. Farm tractors, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. If you're stuck behind one of these automobiles, don't get frustrated. Usually, the vehicle will attempt to pull over to avoid a traffic jam.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22518#22520#29774","text":"a slow-moving vehicle#a fast-moving vehicle#a school bus#an emergency vehicle"},
{"testId":20,"qid":757,"description":"When a solid centerline is visible on your side of the road, you must:","explanation":"Many highways include lane markers that indicate when you can't see far enough ahead to pass. When there is a strong center line on your side of the road, you must not pass.","imageName":"","correctAnswer":6287,"isBookmarked":0,"aid":"27#6287#6515#8955","text":"Change lanes#not pass#Increase your speed#stop"},
{"testId":20,"qid":758,"description":"When a fire truck is responding to a fire alarm, it is illegal to follow it within :","explanation":"You are not permitted to follow a fire truck within 500 feet while it is responding to a fire alarm in Maine.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"1296#4432#30190#30192","text":"500 feet#1,000 feet#1,500 feet#1,200 feet"},
{"testId":20,"qid":759,"description":"Bicycles should ride_________,  when a route is too narrow for vehicles and bicycles to ride safely side by side.","explanation":"Bicycles should \"take the travel lane\" when a road is too narrow for vehicles and bicycles to ride safely side by side. This implies riding in or near the middle of the lane.","imageName":"","correctAnswer":31585,"isBookmarked":0,"aid":"31583#31584#31585#31586","text":"in or near the left of the lane#in or near the right of the lane#in or near the center of the lane#somewhere else"},
{"testId":20,"qid":760,"description":"What is the meaning of this sign?","explanation":"This is a No-Entry sign. It signifies that you are not permitted to enter the road where the sign is displayed. Such signs may be seen at road openings that you should not enter or while driving the opposite way on a one-way street. They may be found on exit ramps, at crossovers on split highways, and in a variety of positions on one-way streets.","imageName":"202003151714361449.jpg","correctAnswer":19371,"isBookmarked":0,"aid":"132#19370#19371#31587","text":"The road ahead is closed#An abandoned road is ahead#You are not allowed to enter#You must stop at the sign and yield to traffic and pedestrians"},
{"testId":16,"qid":761,"description":"You approach a pedestrian while carrying a white cane or a white cane with a red tip. This individual is going to cross the street. You must:","explanation":"A person who walks with a white cane or a white cane with a red tip is most certainly blind. You must come to a halt and take whatever action is required to protect that individual.","imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"8954#8955#8956#8957","text":"take a detour#stop#use your horn#go slow"},
{"testId":16,"qid":762,"description":"If the speed limit is ________, you must begin signaling at least 100 feet before a turn.","explanation":"If the speed limit is 45 mph or less, you must begin signaling at least 100 feet before a turn. If the speed limit is higher than 45 mph, you must begin signaling at least 300 feet before turning. Your car will go 100 feet in 1.5 seconds at 45 mph.","imageName":"","correctAnswer":30160,"isBookmarked":0,"aid":"30158#30159#30160#30161","text":"45 mph or greater#between 50 mph and 60 mph#45 mph or less#60 mph or greater"},
{"testId":16,"qid":763,"description":"On a red signal, you may turn left while turning:","explanation":"If you are going from the left lane of a one-way street into another one-way street, you may perform a left turn at a red light. (Note: Various states have different regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states outright ban left turns on red. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":29828,"isBookmarked":0,"aid":"29827#29828#29829#29830","text":"from the right lane of a one-way street onto another one-way street#from the left lane of a one-way street onto another one-way street#from the left lane of a one-way street onto a two-way street#from the right lane of a one-way street onto a two-way street"},
{"testId":16,"qid":764,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. These signs provide advance notice of a railroad crossing. If a train is nearing the crossing, prepare to halt before reaching the tracks.","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#19179#30162#30163","text":"There is a railroad crossing ahead#Traffic is merging ahead#The right side of the road is closed#There is road repair work ahead"},
{"testId":16,"qid":765,"description":"When you see a school bus coming toward you with flashing amber lights, you must :","explanation":"When approaching a school bus with flashing amber lights, you must slow down to no more than 20 mph and ready to stop. If the red lights are flashing or the stop arm is extended, you must stop at least 15 feet away from the school bus.","imageName":"","correctAnswer":30165,"isBookmarked":0,"aid":"27#30164#30165#30166","text":"Change lanes#pass the school bus with caution#slow down to no more than 20 mph and be prepared to stop#slow down to no more than 40 mph and be prepared to stop"},
{"testId":16,"qid":766,"description":"In Iowa, it is unsafe and illegal to _________ while driving.","explanation":"You must pull your vehicle to a full stop off the traveled area of the highway before using a handheld electronic communication device to read, compose, or send a text message. It is illegal in Iowa to read, write, or send a text message while driving.","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"1783#1784#23817#30167","text":"read a text message#send a text message#do any of the above#write a text message"},
{"testId":16,"qid":767,"description":"When approaching a stopped vehicle with flashing lights, such as a police car, tow truck, utility vehicle, or maintenance vehicle parked alongside the road, you must :","explanation":"If you are approaching a stationary vehicle, such as a police car, tow truck, utility vehicle, or maintenance vehicle stopped beside the road and flashing lights, you must change lanes, but only if it is safe to do so given the road and traffic circumstances.","imageName":"","correctAnswer":30168,"isBookmarked":0,"aid":"30168#30169#30170#30171","text":"make a lane change if it is safe to do so#pass the vehicle at an increased speed#stop and wait for the police officer or maintenance personnel#go slow and offer help"},
{"testId":16,"qid":768,"description":"What is the meaning of this sign?","explanation":"This sign warns drivers that agricultural equipment may cross the road. Prepare to slow down for such slow-moving machinery.","imageName":"202002101207437888.jpg","correctAnswer":30174,"isBookmarked":0,"aid":"19373#30172#30173#30174","text":"Farm equipment is not allowed beyond this sign#There is a country road ahead; you must slow down#The road is slippery ahead; use special tires#Farm machinery may cross the road"},
{"testId":16,"qid":769,"description":"To make a turn, you must turn the steering wheel by:","explanation":"When turning bends, use either the push-pull or hand-over-hand approach to move the steering wheel. You should not spin the wheel with only one hand since you may lose control. Straighten out the steering wheel by hand after you've completed the turn. Allowing it to slip between your fingers might be risky.","imageName":"","correctAnswer":33689,"isBookmarked":0,"aid":"30175#30176#30177#33689","text":"the long-turning technique#the hand-on-hand technique#the push-hand technique#the 鈥渉and-over-hand鈥漮r the 'push-pull' technique"},
{"testId":16,"qid":770,"description":"Hydroplaning is more likely to occur at:","explanation":"Your tyres begin to ride up on the road's water surface as you go quicker. The term for this is hydroplaning. At speeds above 35 mph, hydroplaning is more likely to occur.","imageName":"","correctAnswer":30181,"isBookmarked":0,"aid":"30178#30179#30180#30181","text":"10鈥15 mph#20鈥25 mph#20鈥30 mph#35鈥55 mph"},
{"testId":16,"qid":771,"description":"You should look down the road at least _____ to avoid last-minute braking or the need to turn.","explanation":"You should glance down the road at least 10 seconds ahead to prevent last-minute braking or turning. (Keep in mind that your car will go 880 feet in 10 seconds at 60 mph.) You will be able to drive more securely if you glance ahead and are prepared to stop or change lanes if necessary.","imageName":"","correctAnswer":30183,"isBookmarked":0,"aid":"30182#30183#30184#30185","text":"30 seconds ahead#10 seconds ahead#40 seconds ahead#20 seconds ahead"},
{"testId":16,"qid":772,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a pedestrian crossing ahead. In both marked and unmarked crosswalks, you must yield to pedestrians.","imageName":"202002101154005452.jpg","correctAnswer":30186,"isBookmarked":0,"aid":"30186#30187#30188#30189","text":"A pedestrian crossing is ahead; you must yield#A crew is at work; you must slow down#A flag person (flagger) is ahead; slow down#A school crossing is ahead; you must stop"},
{"testId":16,"qid":773,"description":"When you are within ________ of an incoming vehicle while driving at night, you must switch to low-beam headlights.","explanation":"When you are within 1,000 feet of an incoming vehicle at night, Iowa law compels you to switch to low-beam headlights.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"4432#30190#30191#30192","text":"1,000 feet#1,500 feet#1,400 feet#1,200 feet"},
{"testId":16,"qid":774,"description":"All of the following activities are correct while changing lanes EXCEPT:","explanation":"When checking your blind areas, keep your head straight and your hands on the driving wheel. (It is normal for people to turn their arms in the same direction as they tilt their heads.)","imageName":"","correctAnswer":30194,"isBookmarked":0,"aid":"8649#28097#30193#30194","text":"not taking your eyes off the road ahead for more than an instant#looking in your rear-view and side mirrors#looking over your shoulder in the direction you plan to move to check your blind spots#turning the steering wheel when you turn your head to check your blind spots"},
{"testId":16,"qid":775,"description":"You must ________ and keep an eye out for approaching traffic before reaching the peak of a hill or entering a curve.","explanation":"Rural road hills and curves are often steeper and sharper than highway hills and bends. Slow down before reaching the top of a hill or approaching a bend, shift to the right side of the road, and keep an eye out for incoming traffic.","imageName":"","correctAnswer":8653,"isBookmarked":0,"aid":"8651#8653#26360#26361","text":"slow down, move to the left side of the road,#slow down, move to the right side of the road,#speed up, turn on your headlights,#speed up, shift gears,"},
{"testId":16,"qid":776,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Check your brakes and take it easy.","imageName":"202002101212538783.jpg","correctAnswer":30196,"isBookmarked":0,"aid":"26054#30195#30196#30197","text":"Only trucks are allowed#Trucks are not allowed#The road ahead goes downhill#There is a dip in the road"},
{"testId":16,"qid":777,"description":"You should_________ when approaching an uncontrolled rural crossroads.","explanation":"Uncontrolled rural crossings may be very hazardous if approached with care. Slow down and be prepared to stop for incoming traffic while approaching such a crossroads.","imageName":"","correctAnswer":30200,"isBookmarked":0,"aid":"22727#30198#30199#30200","text":"Do none of the above#blow your horn continuously and proceed#turn on your headlights and proceed#slow down and be prepared to stop for oncoming traffic"},
{"testId":16,"qid":778,"description":"To keep a safe distance behind the vehicle in front of you, use:","explanation":"Follow the two-second rule to keep a safe following distance. For every 10 mph of speed, the two-second rule offers you around 1 1/2 car widths between you and the vehicle ahead of you.","imageName":"","correctAnswer":30203,"isBookmarked":0,"aid":"30201#30202#30203#30204","text":"the four-second rule#the three-second rule#the two-second rule#the one-second rule"},
{"testId":16,"qid":779,"description":"What should you do if someone is following you too closely?","explanation":"If you are being tailgated, shift slightly to the right to allow the tailgater a better view of what is ahead, and indicate for turns, stops, or lane changes as soon as possible. Slow down and urge the car in front of you to pass.","imageName":"202003201758501730.jpg","correctAnswer":30206,"isBookmarked":0,"aid":"30205#30206#30207#30208","text":"Stop your vehicle immediately to warn the driver following you#Try to slow down and encourage the driver behind you to pass#Pull over to the curb or edge of the roadway#Call the police immediately"},
{"testId":16,"qid":780,"description":"What is the meaning of this sign?","explanation":"This sign marks the conclusion of a divided highway and the beginning of a two-way route. Keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":22419,"isBookmarked":0,"aid":"19179#19324#22419#22656","text":"Traffic is merging ahead#Two-way traffic is ahead#A divided highway ends ahead#A divided highway starts ahead"},
{"testId":16,"qid":781,"description":"An uncontrolled intersection is defined as:","explanation":"An intersection not protected by stop signs, yield signs, or traffic lights is called a \"uncontrolled intersection.\" At an uncontrolled intersection, you should slow down and prepare to stop if necessary.","imageName":"","correctAnswer":30209,"isBookmarked":0,"aid":"30209#30210#30211#30212","text":"an intersection not protected by stop signs, yield signs, or traffic lights#an intersection not protected by a police officer#an intersection without a posted speed limit#an intersection not controlled by a flashing red light"},
{"testId":16,"qid":782,"description":"In the event that a tire blows out, you should:","explanation":"If a tire goes flat unexpectedly, keep your car straight by gripping the steering wheel securely. Slow down gently by removing your foot from the gas pedal and softly using the brakes.","imageName":"","correctAnswer":30213,"isBookmarked":0,"aid":"24294#24295#24296#30213","text":"increase your speed to control your vehicle#stop your vehicle on the road#move to the left side of the road#hold the steering wheel tightly and keep your vehicle straight"},
{"testId":16,"qid":783,"description":"On a two-way street, the shared middle lane is reserved for:","explanation":"Left turns are allowed in shared center lanes (or U-turns when permitted). Vehicles approaching from opposing directions may utilize them.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30214#30215#30216","text":"All of the above#making left turns#making U-turns when permitted#vehicles coming from opposite directions"},
{"testId":16,"qid":784,"description":"What does a yellow flashing signal mean?","explanation":"A flashing yellow light signifies that you should continue with care. Allow for cars and people, and advance only when it is safe to do so.","imageName":"202003070823014609.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"26363#28148#30217#30218","text":"Stop#Proceed with caution#Proceed without stopping#There is a traffic island ahead"},
{"testId":16,"qid":785,"description":null,"explanation":"A luminous orange triangle on the back of a vehicle indicates that it is driving slowly. A slow-moving vehicle in Iowa is one that goes at 35 mph or less. This sign may be seen on road construction equipment, agricultural vehicles, or horse-drawn carts or carriages.","imageName":"","correctAnswer":30219,"isBookmarked":0,"aid":"30219#30220#30221#30222","text":"The vehicle is traveling at 35 mph or less#The vehicle is traveling at 45 mph or less#The vehicle is traveling at 55 mph#The vehicle is traveling at 65 mph"},
{"testId":16,"qid":786,"description":"Large flashing arrow panels may be used in work zones to help drivers get into:","explanation":"In work zones, large flashing arrow panels may be utilized to guide cars into certain traffic lanes. These panels also notify cars when a section of the road is closed to traffic.","imageName":"202007210439127901.jpg","correctAnswer":30224,"isBookmarked":0,"aid":"30223#30224#30225#30226","text":"certain freeways#certain traffic lanes#certain parking lots#certain acceleration lanes"},
{"testId":16,"qid":787,"description":"High-Occupancy Vehicle (HOV) lanes are designated for:","explanation":"High-occupancy vehicle (HOV) lanes are designated for carpools and cars transporting more than one passenger. Signs specify how many people must be in the car, as well as the days and hours when this is required.","imageName":"202003051709096729.jpg","correctAnswer":22599,"isBookmarked":0,"aid":"22597#22599#22600#28099","text":"farm machinery vehicles and trucks#car pools and vehicles with more than one person#emergency vehicles#tractor-trailers with fewer than three persons"},
{"testId":16,"qid":788,"description":"This image indicates:","explanation":"White lines divide traffic lanes traveling in the same direction. Yellow lines divide traffic lanes traveling in opposing directions. On certain two-way roads, you'll observe a solid yellow line with a broken yellow line next to it. On the side with the solid yellow line, passing is not permitted.","imageName":"202002211536409341.jpg","correctAnswer":19386,"isBookmarked":0,"aid":"6934#19384#19386#30539","text":"An intersection#a one-way road#a two-way road#cross traffic"},
{"testId":16,"qid":789,"description":"Do not back up on the travel lane or shoulder if you miss your turn or exit. What should you do in its place?","explanation":"If you miss your turn or exit, do not reverse up on the traffic lane or shoulder; you may be rear-ended. Continue driving until you reach the next exit or junction.","imageName":"202007210432373628.jpg","correctAnswer":30230,"isBookmarked":0,"aid":"30227#30228#30229#30230","text":"Come to a complete stop#Increase your speed and make a U-turn#Reduce your speed and move into an acceleration lane#Continue traveling to the next exit"},
{"testId":16,"qid":790,"description":"When you see or hear an ambulance coming from any direction, you must:","explanation":"When an emergency vehicle approaches from any direction, move over to the right side of the road, or as close to the right as feasible.","imageName":"","correctAnswer":30234,"isBookmarked":0,"aid":"30231#30232#30233#30234","text":"keep driving without stopping#pull over to the left side of the road or as near to the left as possible#stop your vehicle in your lane#pull over to the right edge of the road or as near to the right as possible"},
{"testId":16,"qid":791,"description":"If you are going to stop or slow down in an area where other cars may not expect it, you should ________ to let those behind you know.","explanation":"Your brake lights alert others to the fact that you are slowing down. If you plan to stop or slow down in an area where other cars may not anticipate it, tap your brake pedal three or four times rapidly to alert others behind you that you are about to slow down.","imageName":"","correctAnswer":2674,"isBookmarked":0,"aid":"2672#2673#2674#2675","text":"switch on your emergency lights#blow your horn#tap your brake pedal three or four times quickly#change lanes quickly"},
{"testId":16,"qid":792,"description":"This driver's left arm is stretched, bent, and pointed straight up. This motorist plans to:","explanation":"This hand gesture signifies that the motorist want to turn right.","imageName":"202002181150078740.jpg","correctAnswer":8990,"isBookmarked":0,"aid":"28#6476#8955#8990","text":"Make a left turn#Slow down#stop#make a right turn"},
{"testId":16,"qid":793,"description":"If the road or street is too narrow to make a U-turn and you can't go around the block to turn around, you can make a :","explanation":"When the road or street is too small to do a U-turn and you can't proceed around the block, make a three-point turn.","imageName":"","correctAnswer":2683,"isBookmarked":0,"aid":"2680#2681#2682#2683","text":"four-point turn#single-point turn#two-point turn#three-point turn"},
{"testId":16,"qid":794,"description":"You should not begin passing if you are _____ of a hill or curve.","explanation":"You need roughly 10 seconds to pass at 55 mph. If there is an approaching car in the passing lane, the two vehicles will get close in less than ten seconds, covering a distance of more than 1,600 feet (a third of a mile). You need to be able to see that far ahead to be able to escape a head-on collision. So, if you are within a third of a mile of a slope or bend where your vision would be limited, you shouldn't start to pass.","imageName":"","correctAnswer":559,"isBookmarked":0,"aid":"559#2685#2686#30235","text":"within one-third of a mile#within half of a mile#within a quarter of a mile#within a mile"},
{"testId":16,"qid":795,"description":"What does a two-lane road with double solid yellow lines mean?","explanation":"Passing is not permitted in any lane of a two-lane road with double solid yellow lines in the middle.","imageName":"","correctAnswer":30238,"isBookmarked":0,"aid":"30236#30237#30238#30239","text":"Passing is permitted anytime#Passing is permitted during the day#Passing is not permitted#Passing is permitted at night"},
{"testId":16,"qid":797,"description":"Looking forward, to the sides, and behind the vehicle to foresee problems is part of  the:","explanation":"To be a defensive driver, you must be aware of what is going on around you. You must keep your eyes forward, to the sides, and behind your car. Scanning allows you to detect potential issues and react to them more quickly.","imageName":"","correctAnswer":8703,"isBookmarked":0,"aid":"8703#8705#24123#24182","text":"defensive driving#good driving#aggressive driving#distracted driving"},
{"testId":16,"qid":798,"description":"You must stop ________ from the nearest rail at a railroad crossing with a lowered gate and flashing red signals.","explanation":"You must stop between 15 and 50 feet from the closest rail while approaching a railroad crossing.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#5593#30240#30241","text":"between 15 and 50 feet#between 5 and 15 feet#between 10 and 20 feet#between 5 and 100 feet"},
{"testId":16,"qid":799,"description":"A truck or bus has big blind areas on each side that a car would not be able to notice. These blind spots are known as:","explanation":"A truck or bus has big blind areas on either side that a vehicle would not be able to notice. These are referred to as \"No-Zones.\" A passenger car may totally vanish within a No-Zone, increasing the likelihood of an accident.","imageName":"","correctAnswer":26081,"isBookmarked":0,"aid":"26078#26081#30242#30243","text":"Zero-Zones#No-Zones#Blind-Zones#Hide-Zones"},
{"testId":16,"qid":800,"description":"What does this sign at a junction mean?","explanation":"A surrender sign has a downward-pointing triangular form. It indicates you must slow down and cede the right-of-way to vehicles already in the junction or route you are about to enter.","imageName":"202007120454477868.jpg","correctAnswer":30244,"isBookmarked":0,"aid":"22176#26363#29174#30244","text":"Take a detour#Stop#Proceed at high speed#Slow down and yield the right-of-way"},
{"testId":21,"qid":801,"description":"Which of the following will NOT help stop aggressive driving and road rage?","explanation":"One kind of aggressive driving is tailgating. Maintain a sufficient following distance from the car in front of you so that you can stop in time to prevent a collision.","imageName":"","correctAnswer":864,"isBookmarked":0,"aid":"864#32076#32077#32078","text":"Tailgating#Allowing extra travel time#Obeying posted speed limits#Yielding the right of way"},
{"testId":21,"qid":802,"description":"At speeds as low as _____, a normal passenger car can begin hydroplaning.","explanation":null,"imageName":"","correctAnswer":14165,"isBookmarked":0,"aid":"9970#9971#14164#14165","text":"45 mph#65 mph#55 mph#35 mph"},
{"testId":21,"qid":803,"description":"What is the safe following distance behind the vehicle in front of you?","explanation":"Under ideal driving circumstances, the Maryland Driver's Manual recommends a following distance of 3 to 4 seconds. Increase your following distance while driving in bad circumstances or when following a vehicle (such as a bus) that makes frequent stops.","imageName":"","correctAnswer":32079,"isBookmarked":0,"aid":"32079#32080#32081#32082","text":"3 to 4 seconds#2 to 3 seconds#5 to 6 seconds#1 to 2 seconds"},
{"testId":21,"qid":804,"description":"What is the meaning of this sign?","explanation":"This sign shows that traffic is merging from the right ahead.","imageName":"202002101239016186.jpg","correctAnswer":32084,"isBookmarked":0,"aid":"8872#8873#32083#32084","text":"Merge left#The divided highway ends ahead#The highway splits ahead#Traffic merges from the right ahead"},
{"testId":21,"qid":805,"description":"You should ____ to avoid tiredness and highway hypnosis by being alert when driving.","explanation":"Long-distance highway driving may result in highway hypnosis, a state of tiredness or unawareness caused by boredom, the noises of the wind and tires hitting the pavement, and the continuous hum of the engine. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right.","imageName":"","correctAnswer":32085,"isBookmarked":0,"aid":"26261#32085#32086#32087","text":"take stimulants#exercise your eyes#send text messages#keep the interior of your vehicle as warm as possible"},
{"testId":21,"qid":806,"description":"Which of the following is true about cell phones and driving in Maryland?","explanation":"Hand-held mobile phone usage while driving a motor vehicle is illegal in Maryland. Only use your mobile phone in an emergency. Allow a passenger to make the call for you if feasible. If you must make a phone call, pull safely off the road and come to a complete stop before making the call.","imageName":"","correctAnswer":9321,"isBookmarked":0,"aid":"9321#32088#32089#32090","text":"If you must make a call, pull safely off the road and stop before making the call#Reduce your speed while talking on the phone#Keep your telephone conversations long to avoid highway hypnosis#Use a hand-held cell phone while driving"},
{"testId":21,"qid":807,"description":"It is unlawful to pass a motorbike:","explanation":"A motorbike, like a vehicle or truck, requires a complete lane width for safe operation and is legally entitled to the whole width of the lane. As a result, passing a motorbike requires crossing into another lane.","imageName":"","correctAnswer":32092,"isBookmarked":0,"aid":"24131#29083#32091#32092","text":"In all of the above situations#in the left lane#in an adjacent lane#in the same lane"},
{"testId":21,"qid":808,"description":"Which one of the following is correct?","explanation":"Keep in mind that ramps and bridges freeze first, followed by highways and roads. Also, plowed roads may refreeze at night or have icy spots from the melting of snow throughout the day.","imageName":"","correctAnswer":32756,"isBookmarked":0,"aid":"897#32754#32755#32756","text":"None of the above#Four-wheel drive vehicles never slide on ice or snow#Plowed roads never refreeze at night#Ramps and bridges freeze first before highways and roads"},
{"testId":21,"qid":809,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":30282,"isBookmarked":0,"aid":"15654#28685#30281#30282","text":"There is a roundabout ahead#There is a right turn ahead#There is a blind curve ahead#There is a traffic island or divider ahead"},
{"testId":21,"qid":810,"description":"When you can't see objects _________ ahead clearly, you must use your headlights.","explanation":"When you can't see more than 1,000 feet ahead, you must switch on your headlights. At 60 mph, your car will go 1,000 feet in around 11 seconds.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"399#401#1296#4432","text":"200 feet#400 feet#500 feet#1,000 feet"},
{"testId":21,"qid":811,"description":"If you are driving slower than the other vehicles on a highway with two or more lanes in your direction, you should:","explanation":"Slower cars should utilize the right lane when there are two or more lanes of traffic travelling in the same direction, unless passing or making a left turn.","imageName":"","correctAnswer":9892,"isBookmarked":0,"aid":"9892#32096#32097#32098","text":"stay in the right lane#stay in the left lane#keep changing lanes#stay in the center lane"},
{"testId":21,"qid":812,"description":"This symbol forbids you from making :","explanation":"No is represented as a red circle with a red slash. This is a regulation sign indicating that right turns are not permitted in this area.","imageName":"202003151734593912.jpg","correctAnswer":19430,"isBookmarked":0,"aid":"19430#32099#32100#32101","text":"a right turn#a left turn#a U-turn#a right exit"},
{"testId":21,"qid":813,"description":"You must not park your vehicle within _________ of a railroad crossing's nearest rail.","explanation":"Except to temporarily load or unload products or people, do not park your vehicle within 50 feet of the closest rail of a railroad crossing.","imageName":"","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1027#7370#8417","text":"50 feet#25 feet#40 feet#30 feet"},
{"testId":21,"qid":814,"description":"Which of the following is NOT TRUE about driving in the winter?","explanation":"To enhance grip and minimize stopping times on snow-covered roadways, use chains, winter tires, or radial tires. Even with these equipment, driving at normal speeds on ice or snow is dangerous. You need to slow down.","imageName":"","correctAnswer":32103,"isBookmarked":0,"aid":"32102#32103#32104#32105","text":"At normal speeds, four-wheel drive vehicles can slide on ice and snow#Chains or snow tires make it safe for you to drive on ice or snow-covered roadways at normal speeds#When driving on ice or packed snow, you should leave a much greater following distance#Ramps and bridges tend to freeze before highways and roads"},
{"testId":21,"qid":815,"description":"If you are caught driving under the influence of alcohol or other controlled substance, you will receive ____ points on your license.","explanation":"If you are caught driving under the influence of alcohol or other controlled drug, you will get 12 points on your license. If you accrue 12 points in two years, you will be notified that your license has been revoked.","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6388#6390#6478","text":"10#2#4#12"},
{"testId":21,"qid":816,"description":"What is the meaning of this sign?","explanation":"This sign states that all vehicles are not permitted to pass through (buses, trucks, passenger cars, etc.).","imageName":"202003151718184521.jpg","correctAnswer":19393,"isBookmarked":0,"aid":"19391#19392#19393#19394","text":"A no-passing zone for trucks#A no-passing zone for high-occupancy vehicles#A no-passing zone for all vehicles#A no-passing zone for school buses"},
{"testId":21,"qid":818,"description":"If you are driving on a two-lane road and see two solid yellow lines, you must :","explanation":"On a two-lane road, a double solid yellow line indicates that passing is strongly banned in both directions.","imageName":"","correctAnswer":6287,"isBookmarked":0,"aid":"27#6287#8930#28410","text":"Change lanes#not pass#Not make a right turn#not brake"},
{"testId":21,"qid":819,"description":"If the driver is under 18 and has a provisional license, Maryland law says that _____ must wear a seat belt or a child restraint.","explanation":"If a driver is under the age of 18 and has a provisional license, he or she, as well as any passengers, must use a seat belt or appropriate child restraint.","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#2764#30886#32106","text":"The driver and all passengers#Only the driver#The driver and front-seat passengers#only the passengers"},
{"testId":21,"qid":820,"description":"What is the meaning of this sign?","explanation":"This sign informs that the road ahead takes an abrupt right bend.","imageName":"202002061258239664.jpg","correctAnswer":32107,"isBookmarked":0,"aid":"1074#1670#14994#32107","text":"No right turn#Curve to the right#Keep right#Sharp right turn"},
{"testId":21,"qid":821,"description":"In a roundabout, you must drive in :","explanation":"A roundabout is a circular crossroads where all traffic is directed counterclockwise (to the right) around a central island.","imageName":"","correctAnswer":23844,"isBookmarked":0,"aid":"8839#8841#23844#32108","text":"a clockwise direction#the direction of the oncoming vehicles#a counterclockwise direction#none of the above directions"},
{"testId":21,"qid":822,"description":"Most Interstates and other roads with limited access have an entrance ramp and:","explanation":"An entry ramp and an acceleration lane are often used to approach interstate and other limited-access roads. The entry ramp offers you access to the highway, while the acceleration lane enables you to accelerate up to meet interstate traffic.","imageName":"","correctAnswer":23853,"isBookmarked":0,"aid":"23853#26083#32109#32110","text":"An acceleration lane#a deceleration lane#a slow-moving lane#a quick lane"},
{"testId":21,"qid":823,"description":"When a school bus has stopped on the road and its red flashing lights are on, you must stop _________, no matter which direction you are going.","explanation":"You must stop at least 20 feet from the front or back of a school vehicle that has stopped on the highway and is flashing red lights, regardless of which direction you are going in. You are not permitted to advance until the school vehicle is in motion or the flashing red lights are switched off. The sole exception to this law is if the school bus has come to a complete stop on the other side of a split highway. You do not need to stop for the school car in such instance.","imageName":"","correctAnswer":2769,"isBookmarked":0,"aid":"2769#2770#2771#2772","text":"at least 20 feet from the front or rear of the school vehicle#at least 20 feet from the front or 40 feet from the rear of the school vehicle#at least 30 feet from the front or rear of the school vehicle#at least 30 feet from the front or 20 feet from the rear of the school vehicle"},
{"testId":21,"qid":824,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the fact that you are entering a school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead.","imageName":"202003151740461521.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1250#1251#1252#1945","text":"A school bus stop ahead#A bus bay ahead#A school zone ahead#A pedestrian crosswalk ahead"},
{"testId":21,"qid":825,"description":"When following a vehicle at night, you should always:","explanation":"Always lower your headlights while following a truck at night. When bright lights from behind bounce off the truck's huge side mirrors, they might blind the driver.","imageName":"","correctAnswer":29040,"isBookmarked":0,"aid":"111#29040#32111#32112","text":"use your emergency lights#dim your headlights#use your bright lights#turn off your headlights for a few seconds"},
{"testId":21,"qid":826,"description":"When coming out of an alley, driveway, private road, or building, you must:","explanation":"When exiting an alley, driveway, private road, or building, you must stop and yield to pedestrians and other vehicles before proceeding over a sidewalk or into a roadway or highway.","imageName":"","correctAnswer":32114,"isBookmarked":0,"aid":"26178#31277#32113#32114","text":"Enter the main road at increased speed#not make a left turn#stop and turn right only#stop and yield to vehicles already on the main road"},
{"testId":21,"qid":827,"description":"You can't make a U-turn where other drivers can't see your vehicle from _______ both directions.","explanation":"You may not do a U-turn on a curve or a slope when the driver of another vehicle travelling in either direction cannot see your vehicle from at least 500 feet away. On interstate highways and several municipal streets, u-turns are also restricted.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"1296#2779#2780#2781","text":"500 feet#900 feet#700 feet#600 feet"},
{"testId":21,"qid":828,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. Keep an eye out for crosswalks and regulatory signs or signals.","imageName":"202002101146277756.jpg","correctAnswer":32116,"isBookmarked":0,"aid":"26022#32115#32116#32117","text":"A Y-intersection is ahead; you must bear either right or left ahead#A side road enters from the right; adjust your speed#Another road crosses your road ahead; be alert for cross traffic#A T-intersection is ahead; your roadway ends ahead"},
{"testId":21,"qid":829,"description":"When passing a truck or other commercial vehicle, a driver must watch out for:","explanation":"Large commercial vehicles, such as trucks, have significant blind areas known as No-Zones. There are no-zones in the vehicle's front, back, and sides. It is difficult to avoid all of a truck's blind areas. However, do not stay in a No-Zone for any longer than is absolutely required to safely pass a large vehicle.","imageName":"","correctAnswer":22724,"isBookmarked":0,"aid":"22724#32118#32119#32120","text":"the side, rear, and front No-Zones#the large mirrors of the vehicle#the weight of the vehicle#the length of the vehicle"},
{"testId":21,"qid":830,"description":"Before turning, you must continually signal your intention to turn for at least ________ before turning.","explanation":"Before turning, you must consistently communicate your desire to turn for at least 100 feet. (Keep in mind that at 30 mph, your car will cover 100 feet in 2.3 seconds.) You have the option of using your vehicle's turn signals or hand and arm signals. At faster speeds, you should signal for a longer distance.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#898#1397","text":"100 feet#200 feet#250 feet#150 feet"},
{"testId":21,"qid":831,"description":"You may pass another vehicle on a two-lane road:","explanation":"You may pass other cars when there is a broken yellow line adjacent to your lane.","imageName":"","correctAnswer":32121,"isBookmarked":0,"aid":"22364#22365#32121#32122","text":"while crossing or within 100 feet of a railroad crossing#while crossing or within 100 feet of an intersection#if there is a broken yellow line on your side of the center dividing line#near the crest of a hill or on a curve where there is no clear view ahead"},
{"testId":21,"qid":832,"description":"If you see this sign, you must:","explanation":"This sign advises drivers that the road ahead is slick in the rain. Slow down and proceed with care in damp weather.","imageName":"202002101214576723.jpg","correctAnswer":8535,"isBookmarked":0,"aid":"6515#8535#27020#32123","text":"Increase your speed#Reduce your speed#Turn left and then right#Make a sharp turn"},
{"testId":21,"qid":833,"description":"It is prohibited to drive with a blood alcohol content (BAC) of :","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated. (Note: A 180-pound guy will have a BAC of 0.10 percent after just three standard drinks before his liver can begin to break down the alcohol.) A typical drink is defined as 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of hard liquor.)","imageName":"","correctAnswer":32127,"isBookmarked":0,"aid":"32124#32125#32126#32127","text":"0.06% or higher#0.04% or higher#0.05% or higher#0.08% or higher"},
{"testId":21,"qid":834,"description":"_____ are built on steep mountain hills to safely halt runaway vehicles without hurting drivers or passengers.","explanation":"Many steep mountain gradients have escape ramps. Escape ramps are designed to safely stop speeding automobiles without hurting drivers or passengers. Escape ramps use a lengthy bed of loose, soft material (pea gravel) to slow a fleeing vehicle, which is sometimes combined with an upgrade.","imageName":"","correctAnswer":26241,"isBookmarked":0,"aid":"823#824#5176#26241","text":"Deceleration ramps#Acceleration ramps#Weaving zones#Escape ramps"},
{"testId":21,"qid":835,"description":"A car's stopping distance is equal to:","explanation":"Your stopping distance is the sum of your response and braking distances. If you are driving quickly, weary, or your car has faulty brakes, you will require a longer stopping distance.","imageName":"","correctAnswer":32128,"isBookmarked":0,"aid":"32128#32129#32130#32131","text":"the sum of its reaction distance and braking distance#the sum of its braking distance and following distance#the sum of its reaction distance and following distance#its braking distance"},
{"testId":21,"qid":836,"description":"This symbol stands for:","explanation":"This insignia is shown on the back of all slow-moving cars, which are classified in Maryland as vehicles with a maximum speed of 25 miles per hour or below. When approaching one of these cars, slow down.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22518#22519#29177","text":"a slow-moving vehicle#a fast-moving vehicle#a high-occupancy vehicle#a vehicle carrying hazardous materials"},
{"testId":21,"qid":838,"description":"The only traffic signs with eight side is:","explanation":"The stop sign is the only octagonal (eight-sided) traffic sign. When you come to a stop sign, you must come to a full stop at the stop line, before entering the crosswalk, or before entering the junction.","imageName":"","correctAnswer":32134,"isBookmarked":0,"aid":"32132#32133#32134#32135","text":"no-entry sign#yield sign#stop sign#one-way sign"},
{"testId":21,"qid":839,"description":"In the event that a tire blows out, you must not:","explanation":"Hold the steering wheel securely, drive straight ahead, and gradually take your foot off the pedal if you have a blowout. Do not use the brakes until you regain control of the car. Remove the car off the road and park it in a safe spot.","imageName":"","correctAnswer":26195,"isBookmarked":0,"aid":"26193#26194#26195#29143","text":"hold the steering wheel tightly#steer straight ahead#brake immediately#slowly ease your foot off the accelerator"},
{"testId":21,"qid":840,"description":"What is the meaning of this sign?","explanation":"This sign is often placed at a junction with many signals, including a green arrow pointing left. When the green arrow is illuminated, you may make a protected left turn, but it is NOT what this sign says. When the green arrow goes out, the sign signals that you may still make a left turn as long as the circular green signal is lighted and you cede the right-of-way to any incoming vehicles.","imageName":"202003151656044873.jpg","correctAnswer":32136,"isBookmarked":0,"aid":"32136#32137#32138#32139","text":"Left turns are allowed when the circular green signal is lit and there are no approaching vehicles#Left turns are allowed when the green arrow is on#Left turns are not allowed when the green arrow goes out#Left turns are allowed only when the circular green signal is not lit"},
{"testId":22,"qid":841,"description":"If your vehicle becomes entangled on a railroad track, you must:","explanation":"A train running at 50 mph may take more than a mile to come to a complete halt. And the chances of death or severe injury are 40 times greater if your vehicle is struck by a train than if it is hit by a car. As a result, if your car has stopped on railroad tracks and you know a train is coming, you and your passengers should exit the vehicle as soon as possible. Then, move at a 45-degree angle to the direction from where the train is approaching. That way, if the train collides with your car, the resulting rubble and shrapnel will not strike you.","imageName":"202007210447482035.jpg","correctAnswer":32411,"isBookmarked":0,"aid":"32411#32412#32413#32414","text":"get yourself and all passengers out of your vehicle and get as far away from the tracks as possible#keep trying to start the engine. Then drive your vehicle off the tracks#shift into neutral and try to push the vehicle off the tracks#roll down your window and open your doors"},
{"testId":22,"qid":842,"description":"If you get a flat tire or a tire blows out while driving, you should:","explanation":"If you acquire a flat tire or a tire blows out while driving, do not brake. Maintain a firm hold on the steering wheel and take your foot off the gas pedal to allow your car to slow down.","imageName":"","correctAnswer":32416,"isBookmarked":0,"aid":"22490#31227#32415#32416","text":"Turn the steering wheel in the opposite direction of the skid#brake#put your foot on the gas pedal#keep a tight grip on the steering wheel"},
{"testId":22,"qid":843,"description":"If you are approaching a stopped emergency vehicle with flashing lights on a multilane road in Massachusetts, you must:","explanation":"If you are approaching a stopped emergency vehicle with flashing lights on a multilane road in Massachusetts, you must shift over into a lane that is not close to the lane that the emergency vehicle is occupying.","imageName":"","correctAnswer":45215,"isBookmarked":0,"aid":"24584#32417#32418#45215","text":"increase your speed and pass quickly#proceed with caution and pass the emergency vehicle in the same lane#stop and offer help#slow down and leave a vacant lane between your vehicle and the emergency vehicle and proceed with caution"},
{"testId":22,"qid":844,"description":"This symbol denotes:","explanation":"This sign tells you that you are about to enter a school zone. The speed restriction in and near a school is 20 mph. Slow down and take your time.","imageName":"202003151742324658.jpg","correctAnswer":6933,"isBookmarked":0,"aid":"6933#19399#19401#26197","text":"A school zone#pedestrian crosswalks at an intersection#a school bus stop#a children's playground"},
{"testId":22,"qid":845,"description":"You must ________ and continue if the path is clear while approaching an uncontrolled intersection.","explanation":"You must slow down, scan the left and right for approaching traffic, and then go forward if it is safe to do so at an uncontrolled junction.","imageName":"","correctAnswer":32420,"isBookmarked":0,"aid":"125#2840#32420#32421","text":"increase your speed#not reduce your speed#slow down, look left and right for oncoming traffic,#use hand signals"},
{"testId":22,"qid":846,"description":"When planning to exit a highway, signal ________ before reaching the exit ramp.","explanation":"When exiting a highway, plan ahead of time. Signal your intentions to others at least 500 feet (approximately a tenth of a mile) before you reach the exit. Remember that your car will go 500 feet in 5.2 seconds at 65 mph.","imageName":"","correctAnswer":2843,"isBookmarked":0,"aid":"2026#2028#2842#2843","text":"at least 100 feet#at least 200 feet#at least 400 feet#at least 500 feet"},
{"testId":22,"qid":847,"description":"When there isn't enough room for a U-turn, you can make :","explanation":"If there isn't enough space for a U-turn, try a three-point turn. This technique will result in your car facing the other way. This turn should be utilized only when the roadway is small, visibility is excellent, traffic is light, the turn is legal, and there are no other options.","imageName":"","correctAnswer":8879,"isBookmarked":0,"aid":"8878#8879#8880#8881","text":"a two-point turn#a three-point turn#a four-point turn#a five-point turn"},
{"testId":22,"qid":848,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a circular junction (such as a roundabout or rotary) ahead. All traffic flows counterclockwise around a central island in a roundabout or rotary.","imageName":"202002101237092503.jpg","correctAnswer":32424,"isBookmarked":0,"aid":"30300#32422#32423#32424","text":"There is a one-way road ahead#There is a parking area near the sign#There is a residential parking area ahead#There is a circular intersection ahead"},
{"testId":22,"qid":849,"description":"When passing on a multilane highway with many lanes in one direction, you must use:","explanation":"When passing on a multilane highway with many lanes in one direction, you must utilize the center or left lanes.","imageName":"","correctAnswer":29797,"isBookmarked":0,"aid":"2853#2855#5324#29797","text":"the middle lane#any lane#the rightmost lane#the middle or left lanes"},
{"testId":22,"qid":850,"description":"If you're driving on a one- or two-lane road and come to an intersection with a divided highway or a road with three or more lanes, you must stop.","explanation":"When you arrive to a junction with a split highway or a roadway with three or more lanes on a single- or two-lane road, you must surrender the right-of-way to traffic on the throughway or main route.","imageName":"202003161924289737.jpg","correctAnswer":9175,"isBookmarked":0,"aid":"9175#22727#32425#32426","text":"yield the right-of-way to other traffic#Do none of the above#stop and make a right turn to merge with traffic#make a left turn to merge with traffic"},
{"testId":22,"qid":851,"description":"A rotary is a crossroads with:","explanation":"Rotaries are much more prevalent in Massachusetts than in the rest of the nation. A rotary junction is a circular intersection where traffic flows counterclockwise around a central island. Traffic on the larger rotaries may reach speeds of up to 40 mph. Roundabouts are similar, but smaller, and traffic moves more slowly (at 25 mph or less).","imageName":"","correctAnswer":32427,"isBookmarked":0,"aid":"8733#19269#32427#32428","text":"a stop sign#a Do Not Enter sign#a central island#traffic lights"},
{"testId":22,"qid":852,"description":"This symbol indicates that:","explanation":"This sign signifies that a stop sign is ahead. When you see a stop sign, you must come to a full stop before the stop line or crosswalk.","imageName":"202002101139461433.jpg","correctAnswer":32430,"isBookmarked":0,"aid":"32429#32430#32431#32432","text":"vehicles must go only straight or right#vehicles must stop at the intersection ahead#vehicles are not allowed to enter the roadway#vehicles are allowed to travel in one direction"},
{"testId":22,"qid":853,"description":"If you miss your exit on the highway, do not stop or back up. Instead,:","explanation":"If you miss your exit on the highway, do not stop or back up. You will enhance your chances of getting struck by another car if you do so. Instead, take the next exit off the freeway and find a way to double back.","imageName":"202007210432373628.jpg","correctAnswer":32433,"isBookmarked":0,"aid":"32433#32434#32435#32436","text":"proceed to the next exit#stop other vehicles on the highway and ask for help#pull over to the left edge of the road and search for help#pull over to the right edge of the road and search for help"},
{"testId":22,"qid":854,"description":"What is the posted speed limit on Massachusetts highways in optimal driving conditions?","explanation":"The majority of the state's highways have posted speed restrictions. In Massachusetts, limited-access roads, like interstate highways, have speed restrictions ranging from 50 to 65 miles per hour. (Note: The listed speed restrictions on limited-access highways in other states may vary.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":32439,"isBookmarked":0,"aid":"32437#32438#32439#32440","text":"Between 25 and 40 mph#Around 80 mph#Between 50 and 65 mph#Not less than 75 mph"},
{"testId":22,"qid":855,"description":"A ________ on a two-lane, two-way road permits you to briefly cross into the opposing lane to pass a vehicle if it is safe to do so.","explanation":"A broken yellow line splits traffic lanes traveling in opposing directions. If it is safe to do so, you may briefly cross a broken yellow line to pass.","imageName":"","correctAnswer":2873,"isBookmarked":0,"aid":"2873#2874#2876#32441","text":"Broken yellow line#Solid yellow line#Solid white line#left arrow"},
{"testId":22,"qid":857,"description":"If another vehicle is passing you while you are driving, you must ________ and let the other driver to pass safely.","explanation":"When another car passes you, you must slow down, remain right, and let the other driver to pass safely.","imageName":"","correctAnswer":32445,"isBookmarked":0,"aid":"32442#32443#32444#32445","text":"speed up, move into the right lane,#continue at the same speed#pull your vehicle to the left, slow down,#slow down, keep right,"},
{"testId":22,"qid":858,"description":"If there are two or more lanes in your direction, you must utilize the right lane unless:","explanation":"Use the right lane on highways with two or more lanes in your travel direction unless you are passing another vehicle, making a left turn, or the right lane is closed.","imageName":"","correctAnswer":23388,"isBookmarked":0,"aid":"7975#23388#32446#32447","text":"you are making a left turn#any of the above are true#you are passing another vehicle#the right lane is blocked"},
{"testId":22,"qid":859,"description":"If you come accross a sign or signal that instructs you to stop, you must stop:","explanation":"If you are required to stop by a sign or signal, you must do so behind the stop line. If there is no stop line, you must come to a complete stop before approaching the crossing.","imageName":"","correctAnswer":32450,"isBookmarked":0,"aid":"32448#32449#32450#32451","text":"20 feet after passing stop lines and crosswalk lines#15 feet after passing stop lines and crosswalk lines#behind stop lines and crosswalk lines#between stop lines and crosswalk lines"},
{"testId":22,"qid":860,"description":"This symbol indicates the availability of:","explanation":"This is a wayfinding sign. This sign implies that food is available.","imageName":"202004071604007685.jpg","correctAnswer":19405,"isBookmarked":0,"aid":"19404#19405#19406#19407","text":"telephones#food#a gas station#lodging"},
{"testId":22,"qid":861,"description":"You must ________聽approaching a school bus with flashing lights and a stop sign.","explanation":"When approaching a school bus or a school student transport vehicle with flashing lights and a stop sign extended, you must come to a complete stop until the warning lights cease flashing. This is true regardless of which side of the road the school bus or student transport vehicle is on.","imageName":"","correctAnswer":32453,"isBookmarked":0,"aid":"8723#9181#32452#32453","text":"change lanes immediately#slow down and proceed with caution#pass the vehicle at increased speed#stop until the warning lights stop flashing"},
{"testId":22,"qid":862,"description":null,"explanation":"Large commercial vehicles, such as trucks, have massive blind areas known as No-Zones, where a car may totally vanish from the driver's perspective. There are no-zones in front, back, and sides of a huge vehicle. It is hard to totally avoid the blind areas of a huge vehicle. As a result, do not tailgate a bus or truck, and do not stay in a No-Zone for any longer than is required to safely pass a big vehicle.","imageName":"","correctAnswer":1460,"isBookmarked":0,"aid":"1460#2424#2899#32454","text":"both a) and b)#neither a) nor b)#a) blind spots#b) No-Zones"},
{"testId":22,"qid":863,"description":"When you notice a yield sign as you approach a road, you must :","explanation":"Slow down and ready to stop if you notice a yield sign. Allow automobiles, bikes, and pedestrians to pass before proceeding. If traffic circumstances warrant it, you must come to a full stop.","imageName":"","correctAnswer":2904,"isBookmarked":0,"aid":"210#2901#2904#32455","text":"change lanes#proceed at a normal speed#slow down and be prepared to stop#not proceed farther"},
{"testId":22,"qid":864,"description":"What is the meaning of this sign?","explanation":"This is a work zone sign, indicating that road construction or maintenance is underway. To retain your distance from the workers, you must slow down, be cautious, and, if feasible, change lanes.","imageName":"202002101222518967.jpg","correctAnswer":31663,"isBookmarked":0,"aid":"857#30668#30669#31663","text":"A hospital zone#A flag person (flagger) at a work zone#A crosswalk for blind pedestrians#A road crew at work"},
{"testId":22,"qid":865,"description":"If you __________, you are tailgating a truck.","explanation":null,"imageName":"202006041818594397.jpg","correctAnswer":38915,"isBookmarked":0,"aid":"32456#32458#32459#38915","text":"cannot see the truck's rear lights#cannot see the truck driver#cannot see the truck's headlights#cannot see the truck's side mirrors"},
{"testId":22,"qid":866,"description":"A white diamond emblem on the lane's pavement denotes that:","explanation":null,"imageName":"202001291436063384.jpg","correctAnswer":32463,"isBookmarked":0,"aid":"32460#32461#32462#32463","text":"the lane is reserved for pedestrians only#the lane is reserved for emergency vehicles only#the lane is reserved for school buses only#the lane is reserved for buses or high-occupancy vehicles only"},
{"testId":22,"qid":867,"description":"In typical situations, how far ahead can you see with high-beam and low-beam headlights?","explanation":"High-beam headlights enable you to see roughly 350 feet ahead in typical circumstances, whereas low-beam headlights only allow you to see about 100 feet ahead. This is why, when practical, high lights should be used at night. At 65 mph, your car will cover 100 feet in one second.","imageName":"","correctAnswer":2918,"isBookmarked":0,"aid":"2917#2918#2919#2920","text":"250 feet and 100 feet respectively#350 feet and 100 feet respectively#500 feet and 120 feet respectively#350 feet and 200 feet respectively"},
{"testId":22,"qid":868,"description":"What is the meaning of this sign?","explanation":"This is a service announcement. A hospital service is represented by the letter \"H.\"","imageName":"202003151737026825.jpg","correctAnswer":9573,"isBookmarked":0,"aid":"9573#10187#28541#29218","text":"A hospital#Handicapped parking#A high-occupancy vehicle (HOV) lane#A heliport"},
{"testId":26,"qid":868,"description":"What is the meaning of this sign?","explanation":"This is a service announcement. A hospital service is represented by the letter \"H.\"","imageName":"202003151737026825.jpg","correctAnswer":9573,"isBookmarked":0,"aid":"9573#10187#28541#29218","text":"A hospital#Handicapped parking#A high-occupancy vehicle (HOV) lane#A heliport"},
{"testId":22,"qid":869,"description":"Studies by the National Safety Council and the Insurance Institute for Highway Safety show that a fully loaded tractor trailer may take ____ than a passenger vehicle.","explanation":"According to research conducted by the National Safety Council and the Insurance Institute for Highway Safety, a fully loaded tractor trailer may take twice as long to stop as a passenger car.","imageName":"","correctAnswer":2928,"isBookmarked":0,"aid":"2925#2926#2927#2928","text":"50 feet more to stop#100 feet more to stop#triple the distance to stop#double the distance to stop"},
{"testId":22,"qid":870,"description":"When approaching a main road from a private road, a driveway, or a parking lot, you must:","explanation":"If you are approaching a paved highway from a private road, a driveway, or an unpaved road, you must stop and allow pedestrians and other vehicles the right-of-way.","imageName":"","correctAnswer":22312,"isBookmarked":0,"aid":"22312#22313#32464#32465","text":"come to a complete stop and then give the right-of-way to pedestrians, bicyclists, or vehicles on the road you are entering#slow down and then give the right-of-way to pedestrians, bicyclists, or vehicles on the road you are entering#come to a complete stop and make a right turn onto the road you are entering#come to a complete stop and make a left turn onto the road you are entering"},
{"testId":22,"qid":871,"description":"Do not stare directly at oncoming headlights to lessen the glare they cause. Instead,:","explanation":"Do not gaze straight at the headlights of approaching cars to lessen the impact of glare. Look at the bottom right side of your driving lane instead.","imageName":"","correctAnswer":32468,"isBookmarked":0,"aid":"32466#32467#32468#32469","text":"look to the lower left side of your traffic lane#look straight ahead#look to the lower right side of your traffic lane#look at the opposite side of your traffic lane"},
{"testId":22,"qid":872,"description":"This symbol is:","explanation":"This is a highway interchange sign. This sign signifies that you are getting close to an interchange.","imageName":"202003161219208832.jpg","correctAnswer":19408,"isBookmarked":0,"aid":"19408#19409#30599#30600","text":"a freeway interchange sign#a highway exit sign#a destination direction sign#a destination distance sign"},
{"testId":22,"qid":873,"description":"It is against the law to get ______ behind a police car or fire truck that is responding to an alarm.","explanation":"It is prohibited to follow an emergency vehicle responding to an alert closer than 300 feet.","imageName":"","correctAnswer":2943,"isBookmarked":0,"aid":"2941#2942#2943#2944","text":"closer than 400 feet#closer than 600 feet#closer than 300 feet#closer than 450 feet"},
{"testId":22,"qid":874,"description":"You must turn on your headlights when driving:","explanation":"From one-half hour after dusk to one-half hour before daybreak, you must use your headlights.","imageName":"","correctAnswer":32471,"isBookmarked":0,"aid":"32470#32471#32472#32473","text":"from one hour after sunset until one hour before sunrise#from one-half hour after sunset until one-half hour before sunrise#from one hour after sunset until one-half hour before sunrise#from one-half hour after sunset until one hour before sunrise"},
{"testId":22,"qid":875,"description":"What is the entire stopping distance at 60 miles per hour?","explanation":"At 60 mph, it takes an average of 292 feet (almost the length of a football field) to respond to a danger, put on the brake, and safely come to a stop. This is why, when practical, utilize your high-beam headlights at night. Your low-beam headlights barely allow you to see roughly 100 feet ahead.","imageName":"","correctAnswer":2952,"isBookmarked":0,"aid":"2949#2950#2951#2952","text":"104 feet#192 feet#392 feet#292 feet"},
{"testId":22,"qid":876,"description":"This symbol indicates that:","explanation":"This one-way sign advises that traffic on this route must only proceed in the direction indicated by the arrow.","imageName":"202004071557283378.jpg","correctAnswer":32475,"isBookmarked":0,"aid":"19412#32474#32475#32476","text":"all vehicles must stop to make a right turn#all traffic must go on the left side only#all traffic must move in the direction of the arrow#the lane ahead is reserved for trucks to make right turns"},
{"testId":22,"qid":877,"description":"Turn the front wheels________,if your vehicle is skidding.","explanation":"If your car starts to skid, turn the steering wheel towards the skid's direction. Turn your steering wheel to the left if your rear tires are slipping to the left. Steer right if the tires are sliding to the right.","imageName":"","correctAnswer":63,"isBookmarked":0,"aid":"61#63#28060#28061","text":"in the opposite direction of the skid#in the direction of the skid#to the left#to the right"},
{"testId":22,"qid":878,"description":"When driving behind another vehicle, preserve a safe distance from the vehicle ahead by using :","explanation":null,"imageName":"","correctAnswer":2170,"isBookmarked":0,"aid":"2170#2171#2961#2962","text":"the three-second rule#the five-second rule#the ten-second rule#the seven-second rule"},
{"testId":22,"qid":879,"description":"What is the Massachusetts Turnpike's minimum speed limit?","explanation":"Some roads may have speed limits. You may not drive slower than the minimal speed. The Massachusetts Turnpike, for example, has a minimum limit of 40 mph.","imageName":"","correctAnswer":1745,"isBookmarked":0,"aid":"523#683#1745#2135","text":"20 mph#60 mph#40 mph#50 mph"},
{"testId":22,"qid":880,"description":"This symbol is:","explanation":"This is a forewarning sign for an impending railroad crossing. Typically, these signs are posted 350 to 500 feet ahead of a railroad crossing. Keep an eye out for warning lights and oncoming trains. Slow down and prepare to come to a halt.","imageName":"202001282004545744.jpg","correctAnswer":32477,"isBookmarked":0,"aid":"31319#32477#32478#32479","text":"a road repair sign#an advance warning sign for a railroad crossing#a crossroad sign#a one-way road sign"},
{"testId":24,"qid":881,"description":"In accordance with Minnesota's fundamental speed rule, you are required to drive __________ in present circumstances.","explanation":"Minnesota's fundamental speed rule mandates you to drive no faster than is reasonable given the circumstances. Weather, traffic, and road conditions are examples of these. (Several other states have similar legislation.) Even if you are not breaking the official speed limit, you might be penalized for driving too fast for the circumstances.","imageName":"","correctAnswer":33256,"isBookmarked":0,"aid":"2969#2970#33255#33256","text":"at a speed greater than the posted speed limit#at a speed of 70 mph#at a speed faster than is reasonable#at a speed no faster than is reasonable"},
{"testId":24,"qid":882,"description":"When changing lanes or turning, you must ________ to alert other drivers of your intentions.","explanation":"If you want to change lanes or make a turn, you must signal at least 100 feet ahead of time to alert other drivers of your intentions. (A car traveling at 40 mph will go 100 feet in less than two seconds.)","imageName":"","correctAnswer":2973,"isBookmarked":0,"aid":"2971#2972#2973#2974","text":"signal at least 50 feet in advance#signal at least 75 feet in advance#signal at least 100 feet in advance#signal at least 25 feet in advance"},
{"testId":24,"qid":883,"description":"When a vehicle is properly parallel parked, its wheels must be positioned :","explanation":"A vehicle's wheels on the curb side should be no more than 12 inches from the curb if it is correctly parallel parked.","imageName":"","correctAnswer":33260,"isBookmarked":0,"aid":"33257#33258#33259#33260","text":"no less than 12 inches from the curb#no less than 40 inches from the curb#no more than 30 inches from the curb#no more than 12 inches from the curb"},
{"testId":24,"qid":884,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the presence of a railroad crossing ahead. Proceed with cautiously, keeping an eye out for incoming trains and preparing to stop if required.","imageName":"202002101119357461.jpg","correctAnswer":133,"isBookmarked":0,"aid":"133#8594#8595#8596","text":"A railroad crossing is ahead#The railroad ahead is closed for repairs#A new railroad is under construction#The roadway ahead is closed for repairs"},
{"testId":24,"qid":885,"description":"Unless otherwise indicated by traffic signs, the legal speed limit on urban or town roads in optimum driving conditions is:","explanation":"Under ideal driving circumstances, the legal speed limit on urban or town roads is 30 mph, unless traffic signs indicate otherwise.","imageName":"","correctAnswer":521,"isBookmarked":0,"aid":"521#682#2135#2429","text":"30 mph#55 mph#50 mph#10 mph"},
{"testId":24,"qid":886,"description":"Never pass on a curve or hill if you can't see the road ahead clearly for:","explanation":"Pass with additional care at night, when visibility is low, and the road is slick. You are not permitted to pass on a curve or slope when you cannot see the road ahead for at least 700 feet.","imageName":"","correctAnswer":33264,"isBookmarked":0,"aid":"33261#33262#33263#33264","text":"at least 100 feet#at least 300 feet#at least 500 feet#at least 700 feet"},
{"testId":24,"qid":887,"description":"When making a right turn, look both ways, cede the right-of-way, and spin the driving wheel with :","explanation":"When turning right, look both ways, cede the right-of-way, then move the steering wheel hand-over-hand to complete the turn in the lane close to the curb.","imageName":"","correctAnswer":22452,"isBookmarked":0,"aid":"22452#22453#22728#22729","text":"the hand-over-hand technique#the single-hand technique#the hand-off-hand technique#the double-hand technique"},
{"testId":24,"qid":888,"description":"What is the meaning of this sign?","explanation":"This sign denotes that there is a school zone ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead.","imageName":"202003151740461521.jpg","correctAnswer":638,"isBookmarked":0,"aid":"638#5395#9573#33265","text":"A school zone#A pedestrian crossing#A hospital#A bus stop"},
{"testId":24,"qid":889,"description":"Except for ________________, reversing is not permitted freeways or expressways.","explanation":"On motorways or expressways, save for emergency vehicles doing their job, backing up is not permitted. You run the danger of being struck by another car or receiving a penalty for careless or reckless driving if you back up on a motorway or expressway.","imageName":"","correctAnswer":22600,"isBookmarked":0,"aid":"22600#27030#28748#33266","text":"emergency vehicles#motorcycles#slow-moving farm equipment#large trucks"},
{"testId":24,"qid":890,"description":"Do not put your hands on the steering wheel in _________ positions while driving an airbag-equipped vehicle.","explanation":"When driving an airbag-equipped car, keep at least 10 inches between yourself and the steering wheel. Maintain your hands on the steering wheel at the 8 and 4 o'clock or 9 and 3 o'clock positions. The 10 and 2 o'clock locations should be avoided. Airbags deploy at breakneck speed. If your hands are high on the steering wheel, the airbag might push them directly into your face or damage them seriously.","imageName":"","correctAnswer":33269,"isBookmarked":0,"aid":"848#33267#33268#33269","text":"Any of the above#the 8 o'clock and 4 o'clock#the 9 o'clock and 3 o'clock#the 10 o'clock and 2 o'clock"},
{"testId":25,"qid":890,"description":"Do not put your hands on the steering wheel in _________ positions while driving an airbag-equipped vehicle.","explanation":"When driving an airbag-equipped car, keep at least 10 inches between yourself and the steering wheel. Maintain your hands on the steering wheel at the 8 and 4 o'clock or 9 and 3 o'clock positions. The 10 and 2 o'clock locations should be avoided. Airbags deploy at breakneck speed. If your hands are high on the steering wheel, the airbag might push them directly into your face or damage them seriously.","imageName":"","correctAnswer":33269,"isBookmarked":0,"aid":"848#33267#33268#33269","text":"Any of the above#the 8 o'clock and 4 o'clock#the 9 o'clock and 3 o'clock#the 10 o'clock and 2 o'clock"},
{"testId":24,"qid":891,"description":"What should you do if you come across or pass a stopped emergency vehicle with its lights flashing on a two-lane road in your direction?","explanation":"If you approach a stopped emergency vehicle with its lights flashing on a road having two lanes in your direction, slow down and move into the lane furthest from the stopped emergency vehicle, according to Minnesota's Move Over Law. Reduce your speed and pass with care if you are unable to move a lane away, or if you are on a street or highway with just one lane in your direction of travel.","imageName":"","correctAnswer":45178,"isBookmarked":0,"aid":"26363#28692#41026#45178","text":"Stop#Make a U-turn#Turn on your hazard lights#Slow down and move into the lane farthest from the emergency vehicle"},
{"testId":24,"qid":892,"description":"What is the meaning of this sign?","explanation":"This sign denotes a T-junction. The journey you're on will shortly come to an end. After yielding to cross traffic, prepare to turn right or left.","imageName":"202002101135262507.jpg","correctAnswer":19418,"isBookmarked":0,"aid":"19416#19417#19418#33272","text":"A four-way intersection is ahead; prepare to yield#A side road is ahead; watch for vehicles entering the roadway#A T-intersection is ahead; yield to cross traffic#A tourist information center is ahead; stop if desired"},
{"testId":24,"qid":894,"description":"You must _____ when passing another vehicle.","explanation":"When overtaking another vehicle, you must return to the right side of the road before approaching an incoming vehicle within 100 feet. (Even the legal minimum distance is a little short.) If your car and the approaching vehicle are both moving at 40 mph, the two vehicles will cover a 100-foot gap in less than one second before colliding head-on.)","imageName":"","correctAnswer":33275,"isBookmarked":0,"aid":"33273#33274#33275#33276","text":"return to the left side of the road before coming within 100 feet of an oncoming vehicle#return to the left side of the road before coming within 200 feet of an oncoming vehicle#return to the right side of the road before coming within 100 feet of an oncoming vehicle#return to the right side of the road before coming within 200 feet of an oncoming vehicle"},
{"testId":24,"qid":895,"description":"Anyone who flees a police officer in a motor vehicle faces a sentenced to imprisonment for not more than ______.","explanation":"Using a motor vehicle to evade a police officer on official duty is a crime in Minnesota. The punishment is imprisonment for no more than three years and one day, a $5,000 fine, or both.","imageName":"","correctAnswer":33278,"isBookmarked":0,"aid":"33277#33278#33279#33280","text":"four years and one day#three years and one day#five years and one day#two years and one day"},
{"testId":24,"qid":896,"description":"What is the meaning of this sign?","explanation":"This sign indicates that a steep right turn is coming. Reduce your speed to a safe level.","imageName":"202003301742532030.jpg","correctAnswer":19423,"isBookmarked":0,"aid":"19420#19422#19423#33281","text":"Narrow bridge ahead; go slow#Right lane exits, buses excepted#Road turns ahead; slow to a safe speed#Merging traffic ahead; stop and then proceed"},
{"testId":24,"qid":897,"description":"Which of the following is not allowed to be done on a cell phone while driving with a provisional license?","explanation":"Provisional license holders are not permitted to use a mobile phone while driving, even if it is hands-free.","imageName":"","correctAnswer":24516,"isBookmarked":0,"aid":"24452#24516#33282#33283","text":"Neither a) nor b)#Both a) and b)#A) Texting#B) Having a conversation"},
{"testId":24,"qid":898,"description":"If you notice a school bus stopped with its red lights flashing and stop arm extended, you must ________ unless it is halted on the other side of a divided (separated) roadway.","explanation":"You must stop at least 20 feet away from a school bus that has its red lights flashing and its stop arm extended. However, if a school bus with flashing red lights is on the other side of a separated (split) highway, you are not compelled to stop.","imageName":"","correctAnswer":33285,"isBookmarked":0,"aid":"1018#4080#33284#33285","text":"slow down and proceed with caution#change lanes and proceed with caution#stop at least 40 feet from the bus#stop at least 20 feet from the bus"},
{"testId":24,"qid":899,"description":"When two vehicles arrive at the same moment at a crossroads and there is no traffic light or signal,:","explanation":"When two cars arrive at an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right.","imageName":"","correctAnswer":33286,"isBookmarked":0,"aid":"33286#33287#33288#33289","text":"the vehicle on the left must yield to the vehicle on the right#the vehicle on the right must yield to the vehicle on the left#the vehicle that signals first has the right-of-way#the heavier vehicle has the right-of-way"},
{"testId":24,"qid":900,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful brakes in rainy conditions. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":33291,"isBookmarked":0,"aid":"29852#33290#33291#33292","text":"There is a sharp curve near a hill; go slow#There is a gravel road with sharp curves ahead; proceed with caution#The road is slippery; go slow#There is a winding road ahead; follow the signs"},
{"testId":24,"qid":901,"description":"When another driver tries to overtake you, you should:","explanation":"Check behind you to see whether other cars are ready to pass you. When another motorist tries to pass you, remain in your own lane and do not accelerate.","imageName":"","correctAnswer":33296,"isBookmarked":0,"aid":"33293#33294#33295#33296","text":"move into the adjacent lane and increase your speed#stay in your lane and increase your speed#move into the adjacent lane and reduce your speed#stay in your own lane and not increase your speed"},
{"testId":24,"qid":902,"description":"You are at a four-way stop. An emergency vehicle with flashing lights and a siren is coming up behind you. What do you need to do?","explanation":"When an emergency vehicle (ambulance, fire truck, police car, etc.) with flashing red lights and a siren or bell approaches you on a two-way road, you must draw to the right and come to a complete stop. If you are going on a one-way road, you must pull over to the closest side and halt. However, even if an emergency vehicle is coming, you must not block a junction. If you get to an intersection, go straight through it and then pull over. Continue to stand still until all emergency vehicles have passed.","imageName":"","correctAnswer":33299,"isBookmarked":0,"aid":"33297#33298#33299#33300","text":"Pull over to the left in the intersection#Increase your speed and continue on your way#Proceed through the intersection and then pull over#Pull over to the right in the intersection"},
{"testId":24,"qid":903,"description":"You must use caution to keep a safe distance between your vehicle and the vehicle in front of you by using:","explanation":"You must keep a safe gap between your car and the car in front of you. The three-second rule will assist you in maintaining a safe following distance. The three-second rule states that at least three seconds must occur between when the vehicle in front of you passes a fixed item and when your vehicle arrives at that same thing.","imageName":"","correctAnswer":30202,"isBookmarked":0,"aid":"30201#30202#30203#30204","text":"the four-second rule#the three-second rule#the two-second rule#the one-second rule"},
{"testId":24,"qid":904,"description":"What is the meaning of this sign?","explanation":"This symbol denotes a Y-intersection. The road ahead divides into two routes. Prepare for vehicles crossing in your way and be ready to turn in any direction.","imageName":"202002101137336370.jpg","correctAnswer":3042,"isBookmarked":0,"aid":"2373#2515#3042#3043","text":"A side road ahead#A yield sign ahead#A Y-intersection ahead#A youth hostel ahead"},
{"testId":24,"qid":905,"description":"If a pedestrian is in a crosswalk, whether it is clearly marked or not, you must:","explanation":"At junctions and crosswalks, give pedestrians the right of way. When a pedestrian is in a crosswalk, whether marked or unmarked, you must stop and wait until the person has crossed through your lane.","imageName":"","correctAnswer":33301,"isBookmarked":0,"aid":"22659#22660#22661#33301","text":"not stop your vehicle#reduce your speed and proceed with caution#increase your speed and cross the crosswalk quickly#stop and wait until the pedestrian has passed"},
{"testId":24,"qid":906,"description":"On a level highway, passing a commercial vehicle takes ________ than passing a passenger car.","explanation":"On a flat roadway, passing a commercial truck takes three to five seconds longer than passing a car. Allow ample time to pass the commercial vehicle and return to the right lane before approaching traffic within 100 feet.","imageName":"","correctAnswer":3049,"isBookmarked":0,"aid":"3047#3048#3049#33302","text":"2 seconds longer#1 second longer#3 to 5 seconds longer#5 to 8 seconds longer"},
{"testId":24,"qid":907,"description":"When you observe a reflective triangular sign on the back of a vehicle, you should :","explanation":"A car with a reflective triangle symbol on the back designates it as a slow-moving vehicle, which Minnesota defines as one that cannot exceed 30 mph. (These vehicles include horse-drawn carriages and agricultural machinery.) Slow down or ready to change lanes when you approach one of these cars.","imageName":"","correctAnswer":33303,"isBookmarked":0,"aid":"7651#22471#33303#33304","text":"Come to a complete stop#do any of the above#adjust your speed or prepare to change lanes#increase your speed and pass the vehicle"},
{"testId":24,"qid":908,"description":"What is the meaning of this sign?","explanation":"This sign warns that the bridge's roadway ahead is small. Slow down and take your time.","imageName":"202002101245562248.jpg","correctAnswer":1527,"isBookmarked":0,"aid":"1527#33305#33306#33307","text":"A narrow bridge ahead#A construction zone ahead#A divider ahead#A snowplow ahead"},
{"testId":24,"qid":909,"description":"A dashed (broken) white line adjacent to your lane indicates that you can:","explanation":"If you see a dashed (broken) white line adjacent to your lane, it signifies you may cross it to change lanes.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6515#8535#33308","text":"Change lanes#Increase your speed#Reduce your speed#make left turns"},
{"testId":24,"qid":911,"description":"If you're traveling slower than other traffic, remain in the lane:","explanation":"When feasible, maintain the same approximate speed as other cars, but do not exceed the posted speed restrictions. Stay in the lane closest to the right side of the road if you are going slower than other vehicles.","imageName":"","correctAnswer":22466,"isBookmarked":0,"aid":"22463#22464#22465#22466","text":"farthest from the shoulder of the road#nearest to the left side of the road#nearest to the center of the road#nearest to the right side of the road"},
{"testId":24,"qid":912,"description":"What is the meaning of this sign?","explanation":"This sign shows that employees are undertaking road repair. Slow down and choose a lane away from the employees.","imageName":"202002101222518967.jpg","correctAnswer":31663,"isBookmarked":0,"aid":"5650#30668#31663#33309","text":"A pedestrian crosswalk#A flag person (flagger) at a work zone#A road crew at work#An excavation site"},
{"testId":24,"qid":913,"description":"What should you do if you are within 1,000 feet of an oncoming vehicle or within 200 feet of another vehicle at night?","explanation":"You must turn down your headlights at night while you are following another vehicle within 200 feet or within 1,000 feet of an approaching vehicle. High lights may cause the driver of the car in front of you to get distracted because they can reflect off the rearview mirror. The driver of an approaching car may get blinded by high lights.","imageName":"","correctAnswer":29072,"isBookmarked":0,"aid":"24203#24503#29072#33310","text":"Use high-beam headlights#Use emergency lights#Use low-beam headlights#Not use your headlights"},
{"testId":24,"qid":914,"description":"You'll find ________ at the end of most highway entry ramps, which permits you to accelerate up.","explanation":"Most freeway entry ramps include an acceleration lane at the end, which you may use to accelerate up to the speed of oncoming traffic before merging.","imageName":"","correctAnswer":2545,"isBookmarked":0,"aid":"2545#2547#3069#3070","text":"An acceleration lane#A deceleration lane#a slow lane#an access ramp"},
{"testId":24,"qid":915,"description":"Towing a camper or trailer requires a following distance of at least ________ from other vehicles.","explanation":"Towing a camper or trailer requires a following distance of at least 500 feet from other vehicles.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#1296#2781","text":"100 feet#200 feet#500 feet#600 feet"},
{"testId":24,"qid":916,"description":"This hand signal signifies the driver's intention to:","explanation":"Drivers in Minnesota may employ hand and arm signals in addition to or instead of turn signals during daylight hours. This hand gesture signifies that the motorist want to make a right turn.","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#22345","text":"Turn left#Turn right#make a U-turn#slow down or stop"},
{"testId":24,"qid":917,"description":"Normal passenger cars can start to hydroplane at speeds as low as:","explanation":"When there is standing water on a highway, hydroplaning happens. Most tires will channel water away at speeds up to 30 mph, similar to how a windshield wiper cleans the windshield. However, when your speed rises, the tires will be unable to channel the water as efficiently. Your tires may lose touch with the road and begin to ride over the water like a pair of water skis. Partial hydroplaning may occur in a conventional passenger automobile at speeds as low as 35 mph.","imageName":"","correctAnswer":14165,"isBookmarked":0,"aid":"9970#9971#14164#14165","text":"45 mph#65 mph#55 mph#35 mph"},
{"testId":24,"qid":918,"description":"If a blind pedestrian with a white cane or a guide dog is waiting at a crosswalk,:","explanation":"A blind pedestrian must be given the right of way. If you use your horn or rev your engine, you may frighten a pedestrian or guide dog.","imageName":"","correctAnswer":33311,"isBookmarked":0,"aid":"22368#22369#30919#33311","text":"increase your speed and move quickly#reduce your speed and proceed slowly#use your horn to alert them to your presence#do not use your horn or rev your engine"},
{"testId":24,"qid":919,"description":"A middle lane between opposing traffic lanes may be designated for:","explanation":"A middle lane between traffic lanes heading in opposing directions may be reserved for exclusively left turns. This style of lane is denoted by parallel solid and dashed yellow lines on the pavement, which are often accompanied by white arrows.","imageName":"202003241903254880.jpg","correctAnswer":8968,"isBookmarked":0,"aid":"8615#8966#8967#8968","text":"All of the above#right turns only#passing#left turns only"},
{"testId":24,"qid":920,"description":"You must _________ when you observe a continuous yellow signal as you approach an intersection.","explanation":"A persistent yellow indication indicates that the signal is soon to become red. If you are approaching a junction, you must stop if it is safe to do so. If this is not the case, continue with care.","imageName":"202003051752141237.jpg","correctAnswer":33053,"isBookmarked":0,"aid":"6515#8214#22544#33053","text":"Increase your speed#proceed with caution#continue at the same speed#stop if possible"},
{"testId":25,"qid":921,"description":"When following another vehicle, allow at least ________ between the vehicles for every 10 miles per hour of speed.","explanation":"Your car will cover around 15 feet in a second at a speed of 10 mph. The majority of automobiles are at least 15 feet long. Therefore, for every ten miles per hour of speed, you should leave at least one car length between your vehicle and the one in front of you if you are following another vehicle.","imageName":"","correctAnswer":4090,"isBookmarked":0,"aid":"4090#4091#4092#33694","text":"one car length#two car lengths#three car lengths#half a car length"},
{"testId":25,"qid":922,"description":"If you use arm signals, make sure to indicate for at least _______ before slowing down, turning, stopping, or changing lanes.","explanation":"When employing arm signals, be sure to indicate for at least 100 feet before slowing down, turning, stopping, or changing lanes.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#4061","text":"100 feet#50 feet#25 feet#75 feet"},
{"testId":25,"qid":923,"description":"Which of the following scenarios allows you to pass a vehicle on the right?","explanation":"When the vehicle you are passing is making or about to make a left turn, you may pass on the right. If you are on a highway with two or more lanes heading in your direction, you may also pass a car on the right.","imageName":"","correctAnswer":27107,"isBookmarked":0,"aid":"897#27106#27107#27108","text":"None of the above#When the vehicle you are passing is making or about to make a right turn#When the vehicle you are passing is making or about to make a left turn#When the vehicle you are passing is trying to change lanes"},
{"testId":25,"qid":924,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful brakes in rainy conditions. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":28131,"isBookmarked":0,"aid":"28131#33695#33696#33697","text":"The road ahead is slippery when wet; drive carefully#A gravel road ahead; go slow#A winding road ahead; drive carefully#A curved road ahead; follow instructions"},
{"testId":25,"qid":925,"description":"On roads with three or more lanes in each direction, keep _____ unless when passing.","explanation":"If there are three or more lanes in your path, remain in the rightmost lane until passing another vehicle.","imageName":"","correctAnswer":807,"isBookmarked":0,"aid":"807#809#3103#9343","text":"in the rightmost lane#in the leftmost lane#on the shoulder of the road#in the middle lanes"},
{"testId":25,"qid":926,"description":"When a school bus comes to a complete stop with flashing red lights or an extended stop sign, you must ________ regardless of which direction you are heading.","explanation":"You must stop if a school bus is halted and children are boarding or disembarking, regardless of which way you are heading. You must stay stopped until the children have crossed the street or highway and the school bus has started motion, or until the flashing red lights have ceased blinking and the hand-operated stop sign has been retracted.","imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"757#758#33698#33699","text":"slow down#stop#proceed at your usual speed#proceed at increased speed"},
{"testId":25,"qid":927,"description":"What should you do if you come across a car at night with bright or dazzling headlights?","explanation":"When you come across a car with strong or dazzling headlights at night, the best thing to do is lower your lights and keep your gaze on the right side of the road.","imageName":"","correctAnswer":33702,"isBookmarked":0,"aid":"33700#33701#33702#33703","text":"Dim your headlights and keep your eyes on the center of the road#Dim your headlights and keep your eyes on the left side of the road#Dim your headlights and keep your eyes on the right side of the road#Turn on your high-beam headlights and keep your eyes on the center of the road"},
{"testId":25,"qid":928,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":33704,"isBookmarked":0,"aid":"33704#33705#33706#33707","text":"There is an obstacle on the left; keep right#There is a roundabout ahead; keep right#There is a rotary (traffic circle) ahead; keep right#The left lane is closed; keep right"},
{"testId":25,"qid":929,"description":"When you are within ________ of an oncoming vehicle, Mississippi law requires you to dim your headlights.","explanation":"When you are within 500 feet of an incoming vehicle or a vehicle you are pursuing, Mississippi law requires you to lower your headlights.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"565#1296#2779#2781","text":"800 feet#500 feet#900 feet#600 feet"},
{"testId":25,"qid":930,"description":"When you see a lowered crossing gate, a flashing electric signal, a written STOP sign, a flag person giving you a signal, or a swiftly approaching train, you must stop within ________ of the nearest rail at a railroad crossing.","explanation":"When you notice a lowered crossing gate, a flashing electric signal, a written STOP sign, a flag person giving you a signal, or a quickly approaching train, you must stop within 10 to 50 feet of the closest rail at a railroad crossing. (Trains are at least six feet wider than the rails they travel on, so stay away from them.)","imageName":"","correctAnswer":33709,"isBookmarked":0,"aid":"33708#33709#33710#33711","text":"5 to 10 feet#10 to 50 feet#1 to 2 feet#3 to 5 feet"},
{"testId":25,"qid":931,"description":"Which of the following is NOT true about making turns at intersections?","explanation":"Turn on your turn signal and begin slowing down at least 100 feet away from where you want to turn.","imageName":"","correctAnswer":33712,"isBookmarked":0,"aid":"33712#33713#33714#33715","text":"Activate your turn signal at least 20 feet before the actual turn#Complete the turn in the proper lane#Avoid last-minute turns#Keep right while making a right turn"},
{"testId":25,"qid":932,"description":"What is the meaning of this sign?","explanation":"This sign alerts you that traffic from the right lane is going to merge into your lane.","imageName":"202002101239016186.jpg","correctAnswer":3126,"isBookmarked":0,"aid":"3123#3124#3125#3126","text":"Merging from the left#The end of two-way traffic#The beginning of one-way traffic#Merging from the right"},
{"testId":25,"qid":933,"description":"What does a pennant-shaped sign mean?","explanation":"The only form of sign that is pennant shaped is the \"No Passing\" sign. It denotes a no-passing zone for any cars approaching you. A solid yellow line adjacent to your lane may also suggest the same thing.","imageName":"","correctAnswer":33717,"isBookmarked":0,"aid":"638#2528#33716#33717","text":"A school zone#A railroad crossing#A possible hazard#A no-passing zone"},
{"testId":28,"qid":933,"description":"What does a pennant-shaped sign mean?","explanation":"The only form of sign that is pennant shaped is the \"No Passing\" sign. It denotes a no-passing zone for any cars approaching you. A solid yellow line adjacent to your lane may also suggest the same thing.","imageName":"","correctAnswer":33717,"isBookmarked":0,"aid":"638#2528#33716#33717","text":"A school zone#A railroad crossing#A possible hazard#A no-passing zone"},
{"testId":25,"qid":934,"description":"What does a double solid white line indicates?","explanation":"A double solid white line is used on roads to distinguish a high-occupancy vehicle (HOV) lane from other lanes driving in the same direction.","imageName":"202003161937198796.jpg","correctAnswer":33718,"isBookmarked":0,"aid":"33718#33719#33720#33721","text":"Separates lanes traveling in the same direction, but crossing the line is prohibited#Separates lanes traveling in the same direction, and crossing the line is permitted#Separates opposing lanes, and crossing the line is prohibited#Separates opposing lanes, but crossing the line is permitted"},
{"testId":25,"qid":936,"description":"What is the meaning of this sign?","explanation":"The end of a split roadway is indicated by this sign. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":28104,"isBookmarked":0,"aid":"28103#28104#28105#28106","text":"A divided highway begins#A divided highway ends#One-way traffic begins#One-way traffic ends"},
{"testId":25,"qid":937,"description":"When there is water on the road, you must slow down to avoid :","explanation":"Reduce your speed when there is water on the road. Speeds in excess of 35 mph might cause your car to hydroplane and lose control.","imageName":"202002061923072507.jpg","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8585#22732#22733","text":"Hydroplaning#wear and tear#traction of the tires on the road#overheating of the tires"},
{"testId":25,"qid":938,"description":"If your vehicle experiences a flat tire or a blowout, you should:","explanation":"Do not stop abruptly if you have a flat tire or a blowout. Remove your foot from the throttle pedal and carefully and safely use the brakes. Allow the car to come to a halt on its own if you have enough of stopping space. If you run off the pavement for whatever reason, softly use the brakes before returning to the road.","imageName":"","correctAnswer":33723,"isBookmarked":0,"aid":"33722#33723#33724#33725","text":"not take your foot off the gas pedal#not brake suddenly#run off the pavement first#not hold the steering wheel"},
{"testId":25,"qid":939,"description":"Unless otherwise directed by law enforcement personnel, parallel park your vehicle within _______ of the curb in cities and municipalities.","explanation":"Unless otherwise directed by local ordinances or law enforcement officers, parallel park your vehicle within 12 inches of the curb in cities and towns.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#5420#14569#31214","text":"12 inches#50 inches#22 inches#30 inches"},
{"testId":25,"qid":940,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. If a train is approaching the crossing, prepare to halt between 10 and 50 feet from the closest rail.","imageName":"202001282004545744.jpg","correctAnswer":2528,"isBookmarked":0,"aid":"2528#33726#33727#33728","text":"A railroad crossing#Road repairs#Road work#A right turn"},
{"testId":25,"qid":941,"description":"On rural interstates, the legal speed limit is:","explanation":"On rural interstates, the speed limit is 70 mph. In adverse driving circumstances, however, you should drop down below this speed limit.","imageName":"","correctAnswer":23846,"isBookmarked":0,"aid":"23845#23846#24347#33729","text":"60 mph#70 mph#50 mph#80 mph"},
{"testId":25,"qid":942,"description":"To exit an interstate highway, drive at least one-quarter mile into:","explanation":"To leave an interstate highway, pull into the deceleration lane at least a quarter mile before the exit. The exit ramp is reached through the deceleration lane.","imageName":"","correctAnswer":57,"isBookmarked":0,"aid":"57#60#1634#3554","text":"the deceleration lane#the acceleration lane#the left lane#The shoulder"},
{"testId":25,"qid":943,"description":"What must you do, when following another vehicle within 500 feet at night.","explanation":"Mississippi law requires you to use your low lights while you are within 500 feet of an incoming vehicle or a vehicle you are pursuing. As they reflect from the car's mirrors, bright lights may dazzle the driver of the opposite vehicle.","imageName":"","correctAnswer":33730,"isBookmarked":0,"aid":"8970#24203#33730#33731","text":"turn off your headlights#Use high-beam headlights#dim your headlights to low beam#follow it more closely"},
{"testId":25,"qid":944,"description":"Whenever you see this sign, you have to:","explanation":"This is a surrender sign, which has a downward-pointing triangular form. It implies you must slow down and surrender the right of way to cars already in the junction or on the route you are approaching.","imageName":"202007120454477868.jpg","correctAnswer":33733,"isBookmarked":0,"aid":"6515#26062#33732#33733","text":"Increase your speed#proceed at the same speed#stop immediately and look for oncoming traffic#slow down and yield the right-of-way"},
{"testId":25,"qid":945,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"Your braking distance will be four times as lengthy if you double your speed. If you treble your speed, your braking distance will increase by nine times.","imageName":"202006141747275945.jpg","correctAnswer":21080,"isBookmarked":0,"aid":"8591#8592#21080#22749","text":"two times#three times#four times#five times"},
{"testId":25,"qid":946,"description":"Blood alcohol content (BAC) levels of ______ or above are deemed to be under the influence of intoxicating liquor in cases when the driver is at least 21 years old.","explanation":"The legal limit for blood alcohol content (BAC) for drivers over the age of 21 is less than 0.08 percent. If you are caught driving with a blood alcohol concentration (BAC) of 0.08 percent or more, you will be deemed inebriated and may face charges of driving under the influence (DUI). (Note: Three 12-ounce cans of beer, three shots of 80-proof whiskey, or 15 ounces of wine will give an average 180-pound guy a BAC of 0.10 percent before his body begins to break down the alcohol.)","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318#44110","text":"0.05%#0.08%#0.02%#any of the above values"},
{"testId":25,"qid":947,"description":"When waiting to turn left at an intersection,:","explanation":"Keep your wheels straight while waiting to make a left turn at an intersection. If you mistakenly rear-end another car while pointing your front wheels to the left, you may be forced into the path of an incoming vehicle.","imageName":"","correctAnswer":33737,"isBookmarked":0,"aid":"24167#33735#33736#33737","text":"Shift gears#keep your wheels turned toward the left#keep your wheels turned toward the right#keep your wheels straight"},
{"testId":25,"qid":948,"description":"What is the meaning of this sign?","explanation":"This sign advises you that you are about to enter a two-way undivided street. Keep to the right and keep an eye out for vehicles coming from the other way.","imageName":"202002101251206942.jpg","correctAnswer":3175,"isBookmarked":0,"aid":"3175#3176#3177#3178","text":"A two-way road ahead#A one-way road ahead#A right turn ahead#A left turn ahead"},
{"testId":25,"qid":949,"description":"A two-headed arrow with one head pointing straight forward and the other pointing to the left indicates:","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you may travel straight or turn left.","imageName":"202007141955357915.jpg","correctAnswer":33739,"isBookmarked":0,"aid":"22664#22665#33738#33739","text":"you may only turn left#you may only turn right#you may either go straight or merge left#you may either go straight or turn left"},
{"testId":25,"qid":950,"description":"DO NOT_______ if you miss your exit on an interstate highway.","explanation":"Do not stop or back up if you miss your exit on an interstate highway. This dramatically raises the likelihood of an accident occurring. Instead, take the next exit and find a means to return.","imageName":"202007210432373628.jpg","correctAnswer":31226,"isBookmarked":0,"aid":"27#9891#31226#31227","text":"Change lanes#take the next exit#stop or back up#brake"},
{"testId":25,"qid":951,"description":"Which of the four types of interstate interchanges is correct?","explanation":"Interstate interchanges are classified into four types: Directional, Cloverleaf, Diamond, and Trumpet.","imageName":"","correctAnswer":33742,"isBookmarked":0,"aid":"33740#33741#33742#33743","text":"Directional, Cloverleaf, Diamond, and Square#Trumpet, Cloverleaf, Diamond, and Circular#Directional, Cloverleaf, Diamond, and Trumpet#Directional, Circular, Diamond, and Square"},
{"testId":25,"qid":952,"description":"What is the meaning of this sign?","explanation":"This is a regulatory indicator. This sign states that motorists must only turn left.","imageName":"202003301804574348.jpg","correctAnswer":19433,"isBookmarked":0,"aid":"19431#19432#19433#19434","text":"Traffic must make a right turn only#Traffic must merge right#Traffic must make a left turn only#Traffic must merge left"},
{"testId":25,"qid":953,"description":"In Mississippi, your property damage liability insurance coverage must be at least _______ per accident.","explanation":"In Mississippi, your property damage liability insurance coverage must be at least $25,000 per accident.","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#3197#3198#12048","text":"$50,000#$12,000#$30,000#$25,000"},
{"testId":25,"qid":954,"description":"A red arrow indicates that:","explanation":"A red arrow indicator indicates that turning traffic must come to a complete stop. Before turning, they must wait for the next green arrow or green light.","imageName":"","correctAnswer":32165,"isBookmarked":0,"aid":"32163#32164#32165#32166","text":"turning traffic must increase speed and turn quickly#turning traffic must proceed at the same speed#turning traffic must stop#turning traffic must slow down"},
{"testId":25,"qid":955,"description":"If you're traveling slower than other traffic, remain in the lane:","explanation":"Use the right lane if you are travelling slower than normal traffic on an interstate. Drivers that desire to travel quicker or pass other cars choose the left lane.","imageName":"","correctAnswer":33745,"isBookmarked":0,"aid":"8891#28015#33744#33745","text":"The shoulder#center lane#left lane#right lane"},
{"testId":25,"qid":956,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle indicates \"no.\" This sign indicates that cars are not permitted to conduct a U-turn here.","imageName":"202003151744407638.jpg","correctAnswer":33749,"isBookmarked":0,"aid":"33746#33747#33748#33749","text":"Trucks are not allowed to make U-turns#Passenger cars are not allowed to make U-turns#Bicycles are not allowed to make U-turns#No vehicles are allowed to make U-turns"},
{"testId":25,"qid":957,"description":"If you come up to a junction with a persistent yellow light, you must:","explanation":"A persistent yellow indication indicates that the signal is soon to become red. If you are approaching a junction, you must stop if it is safe to do so. If this is not the case, continue with care.","imageName":"","correctAnswer":33751,"isBookmarked":0,"aid":"22695#24573#33750#33751","text":"increase your speed and clear the intersection#stop in the middle of the intersection and wait for the red light#yield to pedestrians and proceed with caution#prepare to stop if you can do so safely"},
{"testId":25,"qid":958,"description":"When there is a solid line on your side of the center dash stripe, you must :","explanation":"When there is a solid line on your side of the center dash stripe, you must remain in your lane and not cross it.","imageName":"","correctAnswer":33752,"isBookmarked":0,"aid":"8930#8990#24126#33752","text":"Not make a right turn#make a right turn#cross the line#not cross the line"},
{"testId":25,"qid":959,"description":"Which of the following locations permits parking?","explanation":"You may not park within 20 feet of a crosswalk at an intersection, on the roadway side of any vehicle already stopped or parked at the edge of a road (\"double parking\"), or in front of a public or private driveway, among other places, unless necessary to avoid conflict with other traffic or traffic control devices. Vehicles may be parked in public parking lots.","imageName":"","correctAnswer":33756,"isBookmarked":0,"aid":"33753#33754#33755#33756","text":"Within 20 feet of a crosswalk at an intersection#On the roadway side of any vehicle already stopped or parked at the edge of a road#In front of a public or private driveway#In a public parking lot"},
{"testId":25,"qid":960,"description":"What is the meaning of this sign?","explanation":"This sign indicates that unless you have a handicap parking sticker placed to the bottom left corner of your license plate, you are not permitted to park here.","imageName":"202003151732554338.jpg","correctAnswer":31993,"isBookmarked":0,"aid":"1584#3523#31993#33336","text":"A hospital ahead#Wheelchair availability#A place reserved for persons with disabilities#A daycare center"},
{"testId":18,"qid":961,"description":"When passing a large vehicle, don't merge back in front of it until you can see its :","explanation":"Trucks and other heavy vehicles have a much greater stopping distance. Entering a highway straight in front of a huge vehicle is not permitted. Do not come to a complete stop in front of heavy trucks. When passing a huge vehicle, wait until you can see its headlights in your rear-view mirror before merging back in front of it.","imageName":"","correctAnswer":29145,"isBookmarked":0,"aid":"9178#9179#9180#29145","text":"headlights in your left side mirror#headlights in your right side mirror#driver in your left side mirror#headlights in your rear-view mirror"},
{"testId":18,"qid":962,"description":"If you're in an intersection when the light turns yellow, you must:","explanation":"A yellow light indicates that the signal is going to turn red. If you are already driving through an intersection when the traffic light turns yellow, you must proceed through the junction and clear it. Please do not obstruct the junction.","imageName":"","correctAnswer":26029,"isBookmarked":0,"aid":"82#8955#26029#29831","text":"not proceed farther#stop#follow through and proceed to clear the intersection#make a left or right turn"},
{"testId":18,"qid":964,"description":"This caution symbol denotes:","explanation":"This sign warns you that you are about to enter an underpass with a vertical clearance of barely 12 feet 6 inches. Vehicles that are taller than the specified clearance should not use the underpass.","imageName":"202002101233137248.jpg","correctAnswer":22111,"isBookmarked":0,"aid":"22111#30845#30846#30847","text":"low clearance ahead#high clearance ahead#the length of the bridge ahead#the width of the road ahead"},
{"testId":18,"qid":965,"description":"When you are within ________ of an oncoming vehicle, you must decrease your headlights.","explanation":"Dim your headlights anytime you are within 500 feet of an incoming vehicle or when you are within 200 feet of another vehicle.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"565#1296#2779#2781","text":"800 feet#500 feet#900 feet#600 feet"},
{"testId":18,"qid":967,"description":"What should you do before entering a curve?","explanation":"Before entering a curve, slow down so you can avoid braking in the curve. Braking in a curve can cause a skid.","imageName":"","correctAnswer":8535,"isBookmarked":0,"aid":"6515#8535#9197#21302","text":"Increase your speed#Reduce your speed#Turn on your headlights#Shift into a lower gear"},
{"testId":18,"qid":968,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for heavy vehicles, such as trucks, descending. Reduce brake wear by driving in low gear (even if you have an automatic).","imageName":"202002101212538783.jpg","correctAnswer":30848,"isBookmarked":0,"aid":"28552#30195#30848#30849","text":"This is a truck route#Trucks are not allowed#A steep descent is ahead#A truck stop is ahead"},
{"testId":18,"qid":969,"description":"You should keep your eyes at least ________ ahead of you while driving.","explanation":null,"imageName":"","correctAnswer":5866,"isBookmarked":0,"aid":"1790#4833#5864#5866","text":"5 to 6 seconds#4 to 5 seconds#30 to 40 seconds#10 to 15 seconds"},
{"testId":18,"qid":970,"description":"When departing an interstate highway, do not begin to slow down until you have entered :","explanation":"When leaving an interstate highway, do not begin slowing down until you have entered the deceleration lane.","imageName":"","correctAnswer":24149,"isBookmarked":0,"aid":"8639#24149#30850#30851","text":"The left lane#the deceleration lane#the merging lane#the acceleration lane"},
{"testId":18,"qid":971,"description":"To see around a huge truck that is blocking your view ahead, you will need to :","explanation":"Increase your following distance (to four or five seconds) while following a huge vehicle that restricts your vision ahead so you can see around it more easily.","imageName":"","correctAnswer":3252,"isBookmarked":0,"aid":"295#3251#3252#30852","text":"High-beam headlights#a curved mirror#a greater following distance#two rear-view mirrors"},
{"testId":18,"qid":972,"description":"What is the meaning of this sign?","explanation":"This emblem, which appears on designated parking signs, placards, and license plates, is the worldwide symbol for disabled access. Only cars with this mark on their license plates or special cards are permitted to park in disabled parking spots.","imageName":"202003151732554338.jpg","correctAnswer":3253,"isBookmarked":0,"aid":"3253#3254#9573#30289","text":"A reserved parking area for disabled people#A reserved parking area for patients#A hospital#A wheelchair service"},
{"testId":18,"qid":973,"description":"When you come across a school bus or church bus that has stopped to pick up or drop off passengers, you must :","explanation":"There are generally youngsters where there are school buses. Children are prone to doing unexpected things, so be prepared. You must stop if you come across a school bus or church bus that has stopped to load or unload people. You must stay halted by law until all passengers have cleared the street and the bus has begun to move.","imageName":"","correctAnswer":9184,"isBookmarked":0,"aid":"9181#9182#9184#22544","text":"slow down and proceed with caution#increase your speed and pass the bus quickly#stop and remain stopped until all people have cleared the roadway#continue at the same speed"},
{"testId":18,"qid":974,"description":"Kentucky law requires that you complete a pass when:","explanation":"When you are within 100 feet (approximately five or six car widths) of an incoming vehicle, Kentucky law requires you to perform a pass. However, in many cases, you should accomplish the pass much sooner. If your car and the incoming vehicle are both moving at 50 mph, they will cover a 100-foot gap in less than one second.","imageName":"","correctAnswer":30854,"isBookmarked":0,"aid":"30853#30854#30855#30856","text":"you are within 90 feet of an oncoming vehicle#you are within 100 feet of an oncoming vehicle#you are within 50 feet of an oncoming vehicle#you are within 76 feet of an oncoming vehicle"},
{"testId":18,"qid":975,"description":"You may experience hydroplaning when your tires:","explanation":null,"imageName":"","correctAnswer":30859,"isBookmarked":0,"aid":"30857#30858#30859#30860","text":"are properly inflated#have a lot of traction with the roadway#are moving at an excessive speed on ice, snow, or rain#are moving slowly on ice, snow or rain"},
{"testId":18,"qid":976,"description":"This symbol denotes:","explanation":"This sign denotes that there is a school zone ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead.","imageName":"202003151740461521.jpg","correctAnswer":6933,"isBookmarked":0,"aid":"6933#8836#8838#30861","text":"A school zone#A pedestrian crosswalk#a playground#a crosswalk for blind people"},
{"testId":18,"qid":977,"description":"You should never turn your ignition key to the \"lock\" position while your vehicle is in motion, because it will cause:","explanation":"While the car is in motion, never turn the ignition key to the lock position. This would cause the steering to lock, resulting in vehicle control loss. Since 1969, most automobiles have come equipped with steering locks.","imageName":"","correctAnswer":24354,"isBookmarked":0,"aid":"24351#24353#24354#30862","text":"the brakes to fail#the engine to fail#the steering wheel to lock#the accelerator (gas) pedal to lock"},
{"testId":18,"qid":978,"description":"When two cars arrive at the same time at an uncontrolled intersection,:","explanation":"When two vehicles arrive at an uncontrolled junction at the same moment, the vehicle on the right has the right-of-way.","imageName":"","correctAnswer":30863,"isBookmarked":0,"aid":"30863#30864#30865#30866","text":"the car on the right has the right-of-way#the car on the left has the right-of-way#the car turning right has the right-of-way#the car turning left has the right-of-way"},
{"testId":18,"qid":979,"description":"The most crucial vehicle control device in a skid is:","explanation":"The steering wheel is the most crucial vehicle control mechanism in a skid. If you begin to slide sideways, spin the steering wheel in the same direction as the vehicle's rear. Straighten the wheels when you believe you have recovered control of the vehicle.","imageName":"","correctAnswer":24189,"isBookmarked":0,"aid":"24186#24188#24189#29146","text":"the accelerator#the headlights#the steering wheel#the anti-lock braking system (ABS)"},
{"testId":18,"qid":980,"description":"What is the meaning of this sign?","explanation":"This flagger sign is often seen in or near work or construction zones. It indicates that there is a flag person (flagger) ahead controlling traffic.","imageName":"202002101220385703.jpg","correctAnswer":30867,"isBookmarked":0,"aid":"3278#3279#3280#30867","text":"A traffic island ahead#Traffic police ahead#An emergency vehicle ahead#A flag person (flagger) ahead"},
{"testId":18,"qid":981,"description":"If your blood alcohol content (BAC) is at least _____, you are deemed inebriated in Kentucky.","explanation":"A Breathalyzer or other chemical tests are used to determine blood alcohol content (BAC). In Kentucky, like in other states, you are deemed inebriated if your blood alcohol concentration (BAC) is at least 0.08 percent.","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23194#23319#27697#27698","text":"0.08%#0.07%#0.06%#0.04%"},
{"testId":18,"qid":982,"description":"The legal speed limit on Kentucky state highways is:","explanation":"On Kentucky state roads, the legal speed limit is 55 mph. The speed limit on interstate roads is 65 miles per hour.","imageName":"","correctAnswer":14164,"isBookmarked":0,"aid":"9971#14164#14165#22200","text":"65 mph#55 mph#35 mph#15 mph"},
{"testId":18,"qid":983,"description":"A flashing yellow arrow indicates:","explanation":"A flashing yellow arrow indicates that you should continue with care in the direction indicated by the arrow.","imageName":"","correctAnswer":30869,"isBookmarked":0,"aid":"24459#30868#30869#30870","text":"you must not proceed in the direction of the arrow#you must come to a complete stop in the direction of the arrow#you may proceed with caution in the direction of the arrow#you may go straight"},
{"testId":18,"qid":984,"description":"This insignia on the back of a vehicle indicates that:","explanation":"This insignia is shown on the back of all slow-moving vehicles, which are classified in Kentucky as vehicles with a maximum speed of 25 miles per hour or below. When approaching one of these cars, slow down.","imageName":"202003181417388899.jpg","correctAnswer":9069,"isBookmarked":0,"aid":"9068#9069#30871#30872","text":"the vehicle can travel faster than 25 mph#the vehicle cannot travel faster than 25 mph#the vehicle can travel faster than 55 mph#the vehicle is carrying hazardous materials"},
{"testId":18,"qid":985,"description":"On a red signal, you may only turn left:","explanation":"Unless otherwise posted, you may only make a left turn at a red light in Kentucky if you are going from one one-way street into another one-way street. You must first come to a complete stop, then turn left when there is no vehicle or pedestrian traffic in the path. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":30874,"isBookmarked":0,"aid":"30873#30874#30875#30876","text":"when turning from a two-way street onto another two-way street#when turning from a one-way street onto another one-way street#when turning from a one-way street onto a two-way street#in none of the above situations"},
{"testId":18,"qid":986,"description":"What should you do if you miss an expressway exit?","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This would significantly increase the chance of a collision. Instead, go to the next exit.","imageName":"202007210432373628.jpg","correctAnswer":28757,"isBookmarked":0,"aid":"24257#24258#28692#28757","text":"Stop and ask for help#Back up to reach your exit#Make a U-turn#Continue on to the next exit"},
{"testId":18,"qid":987,"description":"A turnabout is a legal way to reverse your vehicle. It is also referred as:","explanation":"A turnabout, also known as a two-point turn, is a legal technique to do a U-turn. A three-point turn, often known as a broken U-turn, may also be used to turn around.","imageName":"","correctAnswer":8878,"isBookmarked":0,"aid":"8878#8879#8880#8999","text":"a two-point turn#a three-point turn#a four-point turn#a single-point turn"},
{"testId":18,"qid":988,"description":"What is the meaning of this sign?","explanation":"A green arrow light pointing upward indicates that you may proceed after deferring to pedestrians and cars already at the junction.","imageName":"202003202120148631.jpg","correctAnswer":19438,"isBookmarked":0,"aid":"19435#19436#19437#19438","text":"You are not allowed to go straight#You must stop before going straight#Only passenger vehicles are allowed to go straight#You may go straight ahead"},
{"testId":18,"qid":989,"description":"When vision is poor due to inclement weather, you should utilize ________ to improve your ability to see ahead.","explanation":"In poor visibility conditions, utilize low-beam headlights to help you see ahead and make your car more apparent to other drivers. Glare is caused by high lights reflecting off precipitation.","imageName":"","correctAnswer":294,"isBookmarked":0,"aid":"294#295#296#30262","text":"Low-beam headlights#High-beam headlights#parking lights#Emergency flashers"},
{"testId":18,"qid":990,"description":"When an emergency vehicle approaches from either direction, flashing red lights or sounding a siren, you must :","explanation":"When an emergency vehicle approaches from either direction, pull over to the curb or right side of the road, clear of any junction, and come to a full stop. Unless ordered otherwise by a police officer or a fireman, you must stay stopped until the emergency vehicle has passed.","imageName":"","correctAnswer":30879,"isBookmarked":0,"aid":"9181#30877#30878#30879","text":"slow down and proceed with caution#move toward the left side of the road and continue at the same speed#change lanes and increase your speed#immediately pull over, clear of any intersection, and stop"},
{"testId":18,"qid":991,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are vast spaces surrounding trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is compromised. No-Zones significantly enhance the chance of a collision. If you're following a truck and can't see its side mirrors, the driver probably can't see you too.","imageName":"","correctAnswer":236,"isBookmarked":0,"aid":"236#24369#30584#30585","text":"No-Zones#Slow-Zones#Zero-Zones#Safe-Zones"},
{"testId":18,"qid":992,"description":"What is the meaning of this sign?","explanation":"This sign warns drivers that agricultural equipment may cross the road. Prepare to slow down for such sluggish cars.","imageName":"202002101207437888.jpg","correctAnswer":30880,"isBookmarked":0,"aid":"19373#30172#30880#30881","text":"Farm equipment is not allowed beyond this sign#There is a country road ahead; you must slow down#Farm equipment may cross the road#Road construction equipment may cross the road"},
{"testId":18,"qid":993,"description":"In a roundabout, you must drive in ___________from the right side of the middle island.","explanation":"A roundabout is a circular crossroads where traffic flows counterclockwise around a central island.","imageName":"","correctAnswer":23844,"isBookmarked":0,"aid":"8839#8841#8842#23844","text":"a clockwise direction#the direction of the oncoming vehicles#any direction#a counterclockwise direction"},
{"testId":18,"qid":994,"description":"You should ________ before driving out into traffic if you are parallel parked on the right side of a street.","explanation":"Before pulling out into traffic when parallel parked on the right side of a street, you must indicate a left turn and glance over your left shoulder for incoming traffic. Before pulling out into traffic when parallel parked on the left side of a one-way street, indicate a right turn and glance over your right shoulder for incoming vehicles.","imageName":"","correctAnswer":22669,"isBookmarked":0,"aid":"22666#22667#22668#22669","text":"signal a right turn and look over your right shoulder#signal a left turn and look over your right shoulder#signal a right turn and look over your left shoulder#signal a left turn and look over your left shoulder"},
{"testId":18,"qid":995,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear.","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":18,"qid":996,"description":"What is the meaning of this sign?","explanation":"You have the option of turning left or straight.","imageName":"202003151725207480.jpg","correctAnswer":30884,"isBookmarked":0,"aid":"30882#30883#30884#30885","text":"You must go straight; a left turn is not allowed#You must go left#You must either go straight or turn left#You must neither go straight nor turn left"},
{"testId":18,"qid":998,"description":"You must maintain a _____ following distance to avoid a rear-end collision.","explanation":"Though it is not required by Kentucky law, the Kentucky Driver Manual advises a following distance of at least 3 seconds under ideal circumstances. If the car in front of you comes to a sudden halt, you may crash with it if you are within 2.5 seconds of it. In bad weather or on slick roads, you should increase your following distance.","imageName":"","correctAnswer":551,"isBookmarked":0,"aid":"549#550#551#3640","text":"Two-second#Four-second#three-second#One-second"},
{"testId":18,"qid":999,"description":"Kentucky law requires ________ to wear safety belts or appropriate kid restraints.","explanation":"All vehicle passengers must wear safety belts or appropriate kid restraints, according to Kentucky law. The driver is responsible for appropriately restraining all vehicle passengers (including himself or herself).","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#3330#30886#30887","text":"The driver and all passengers#All passengers#The driver and front-seat passengers#The driver and rear-seat passengers"},
{"testId":18,"qid":1000,"description":"What is the meaning of this sign?","explanation":"This sign indicates that a healthcare service is nearby.","imageName":"202003151737026825.jpg","correctAnswer":3331,"isBookmarked":0,"aid":"3331#3332#3333#5900","text":"A hospital service ahead#An interstate highway ahead#A hill area ahead#A handicapped parking area ahead"},
{"testId":26,"qid":1001,"description":"If your lane has two solid yellow lines next to it, you must:","explanation":"Yellow lines divide opposing traffic lanes. Passing or changing lanes on either side of two solid yellow lines (also known as a double solid yellow line) is prohibited. You may only cross the lines to turn left onto or off the road.","imageName":"","correctAnswer":33997,"isBookmarked":0,"aid":"33996#33997#33998#33999","text":"treat them as a single broken yellow line#never cross the lines to pass or change lanes#cross the lines only during permitted times#cross the lines only to pass"},
{"testId":26,"qid":1002,"description":"The leftmost lane on a roadway with three or more lanes driving in the same direction is meant to be used:","explanation":"The leftmost lane on multilane roadways is for overtaking slower cars. If you pass on the right, the other vehicle may not notice you and may abruptly change lanes in front of you.","imageName":"","correctAnswer":23786,"isBookmarked":0,"aid":"23785#23786#29856#29857","text":"by heavy trucks#for passing slower vehicles#by slower vehicles#for stopping in an emergency"},
{"testId":26,"qid":1003,"description":"On interstate highways, you may not drive faster than _______ under normal conditions.","explanation":"Under normal road conditions, you may not travel faster than 40 mph on interstate roads. If this minimum speed is too fast for you, choose a another route. In adverse driving conditions (such as snowy or slippery roads), you should slow down to less than 40 mph if required for safety.","imageName":"","correctAnswer":1745,"isBookmarked":0,"aid":"522#683#1745#2135","text":"25 mph#60 mph#40 mph#50 mph"},
{"testId":26,"qid":1004,"description":"This symbol denotes:","explanation":"This sign indicates that there will be a traffic light ahead. Prepare to come to a halt.","imageName":"202002101142048449.jpg","correctAnswer":19344,"isBookmarked":0,"aid":"19344#34000#34001#34002","text":"a traffic signal ahead#an oncoming train#an oncoming school bus#an oncoming emergency vehicle"},
{"testId":26,"qid":1005,"description":"You must stop ________ before the railroad tracks at a railroad crossing.","explanation":"Slow down when you approach a railroad crossing, watch for a train, and be prepared to stop. If you notice a railroad crossing sign, a signal with flashing red lights, or a downed gate, you must stop between 15 and 50 feet from the closest rail. Trains are at least six feet wider than the tracks they operate on, so stay away from them.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#31176#34003#34004","text":"between 15 and 50 feet#between 15 and 20 feet#between 5 and 50 feet#between 10 and 45 feet"},
{"testId":26,"qid":1007,"description":"When is it permissible to pass on the right?","explanation":"In general, you may pass a vehicle on the right if it is either a) making or about to make a left turn, or b) moving on a road having two or more lanes heading in your direction. (This may occur on a multilane highway or a one-way street.)","imageName":"","correctAnswer":34005,"isBookmarked":0,"aid":"26211#34005#34006#34007","text":"In none of the above situations#If the vehicle is making or about to make a left turn#If the vehicle is making or about to make a right turn#On a two-lane road"},
{"testId":26,"qid":1008,"description":"This symbol alerts vehicles to:","explanation":"This sign denotes that there is a school zone ahead. Slow down, drive carefully, and keep an eye out for youngsters crossing the street.","imageName":"202003151742324658.jpg","correctAnswer":6933,"isBookmarked":0,"aid":"6933#8836#9211#34008","text":"A school zone#A pedestrian crosswalk#a railroad crossing#a work zone"},
{"testId":26,"qid":1009,"description":"The first step in Missouri's Graduated Driver License Program for new drivers is :","explanation":"Obtaining an instruction permit is the first stage in Missouri's Graduated Driver License Program for new drivers. This permit allows the young driver to practice driving while being supervised by a licensed adult.","imageName":"","correctAnswer":34010,"isBookmarked":0,"aid":"34009#34010#34011#34012","text":"to obtain an intermediate license#to obtain an instruction permit#to obtain automobile insurance#to obtain a full driver license"},
{"testId":26,"qid":1010,"description":"You must_____ when approaching a roundabout.","explanation":"When approaching a roundabout, you must yield to oncoming vehicles as well as pedestrians in crosswalks. Enter the roundabout from the right and go counterclockwise until you reach your exit. Then flip on your right turn signal and leave the roundabout on the right.","imageName":"","correctAnswer":34014,"isBookmarked":0,"aid":"32758#32760#34013#34014","text":"enter from the left#activate your left turn signal to exit#move in a clockwise direction#yield to traffic in the roundabout and pedestrians in crosswalks"},
{"testId":26,"qid":1011,"description":"When you see or hear an emergency vehicle coming from any direction with a siren or air horn and a red or blue flashing light, you must :","explanation":"You must give the right of way to any police, fire, ambulance, or other emergency vehicle equipped with a siren or air horn and a flashing red or blue light. No matter which way the emergency vehicle is heading, you must pull over to the right side of the road or as close to the right as practicable.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"22546#24031#34015#34016","text":"pull over to the right edge of the road#pull over to the left edge of the road#move to the left edge of the road and increase your speed#move to the center of the road"},
{"testId":26,"qid":1012,"description":"This symbol is:","explanation":"This is an item marker sign, which is intended to alert drivers about things in the road or extremely near to the road's edge. Stay in your lane if you see one of these signs.","imageName":"202003301703128289.jpg","correctAnswer":34019,"isBookmarked":0,"aid":"34017#34018#34019#34020","text":"a warning sign at a work zone#a guide sign on an interstate highway#an object marker on a roadway#a barricade at a construction zone"},
{"testId":26,"qid":1013,"description":"You are approaching a school bus that has stopped to pick up or drop off students. You are NOT required to stop for a school bus if:","explanation":"You are not required to stop for a stopped school bus on the opposite side of a divided highway (a highway in which a median or barrier separates opposing lanes). You are also not required to stop if you are traveling in the opposite direction of the bus on a four-lane highway or if the bus is stopped in a school loading zone where children are not permitted to cross the roadway.","imageName":"","correctAnswer":34024,"isBookmarked":0,"aid":"34021#34022#34023#34024","text":"you are traveling on a roadway in the same direction as the bus#you are traveling on a one-way street#you are traveling on an undivided roadway in the opposite direction of the bus#you are traveling on a divided highway in the opposite direction of the bus"},
{"testId":26,"qid":1014,"description":"Signal at least _____ before beginning a left or right turn.","explanation":"Before turning, signal at least 100 feet ahead of time to let other vehicles know you want to turn. (A car traveling at 40 mph will go 100 feet in less than two seconds.) Wait until you have passed any other streets, driveways, or entrances between you and where you wish to turn before signaling. If you signal before passing specific locations, other cars may believe you intend to turn there.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#1026#1027","text":"100 feet#200 feet#50 feet#25 feet"},
{"testId":26,"qid":1015,"description":"Which of the following statements is FALSE?","explanation":"You had to come up with a bogus assertion. It says, \"Always do a U-turn at a spot designated 'No U-Turn.'\" Only do a U-turn when it is safe to do so. Do not do a U-turn on a curve or on the peak of a hill where other cars may not notice your vehicle. Some cities and municipalities prohibit U-turns entirely. Never do a U-turn at a spot designated \"No U-Turn.\" Never make a U-turn at a traffic light or a police officer-controlled junction.","imageName":"","correctAnswer":29151,"isBookmarked":0,"aid":"24410#26199#29150#29151","text":"Do not make a U-turn on a curve or near the crest of a hill#You should only make a U-turn when it is safe to do so#Some cities and towns do not allow U-turns#Always make a U-turn at a location marked with a \"No U-Turn\" sign"},
{"testId":26,"qid":1016,"description":"What is the meaning of this sign?","explanation":"A fluorescent orange triangle on the back of a car marks it as a slow-moving vehicle, which Missouri defines as one that goes less than 25 miles per hour. Horse-drawn vehicles, agricultural equipment, and construction equipment are examples of such vehicles. Slow down and be patient if you find yourself stuck behind one of these cars. To avoid traffic jams, the motorist would normally attempt to pull over whenever feasible.","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#29192#33805#33806","text":"Slow-moving vehicle#Detour#Taxi#Vehicle carrying hazardous materials"},
{"testId":26,"qid":1017,"description":"To earn a driving license, you must pass:","explanation":"A eye exam, a written test, a road sign test, and a driving (road) test are all required to earn a driver license.","imageName":"","correctAnswer":34027,"isBookmarked":0,"aid":"34025#34026#34027#34028","text":"a vision test, a written test, a driving test, and a vehicle test#a vision test, a written test, a vehicle test, and a fitness test#a vision test, a written test, a road sign test, and a driving test#a vision test, a road sign test, a vehicle test, and a fitness test"},
{"testId":26,"qid":1018,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your car begins to slide downhill, the curb will prevent it from going any farther. Make that your parking brake is still engaged and your gearbox is in the proper gear.","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":26,"qid":1019,"description":"When passing another vehicle, get through the driver's blind spot as quickly as you can:","explanation":"When passing another vehicle, go as rapidly as possible past the other driver's blind area without exceeding the speed limit. The longer you linger in the blind region, the more likely it is that you may collide. However, you are never permitted to exceed the speed limit.","imageName":"202006041744335390.jpg","correctAnswer":22380,"isBookmarked":0,"aid":"22378#22380#22381#30287","text":"by increasing your speed#without exceeding the speed limit#by reducing your speed#by shifting gears"},
{"testId":26,"qid":1020,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending roundabout. Slow down and be ready to yield to cars already in the roundabout.","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#33152#33153#33154","text":"There is a roundabout ahead#Only left turns are allowed here#There is a shared left-turn lane ahead#There is a 270-degree freeway ramp ahead"},
{"testId":26,"qid":1021,"description":"Use the entry ramp and _________ to match the pace of highway traffic while entering a highway.","explanation":"Entrance ramps are one-way, short ramps used to enter the motorway. An acceleration lane is located at the end of the majority of entry ramps. Before merging with interstate traffic, use the entry ramp and acceleration lane to boost your speed to match it.","imageName":"","correctAnswer":3058,"isBookmarked":0,"aid":"3058#3402#3403#3404","text":"Acceleration lane#shoulder lane#deceleration lane#uncontrolled access path"},
{"testId":26,"qid":1022,"description":"In order to avoid highway hypnosis, you should:","explanation":"Highway hypnosis is a state of tiredness or unawareness caused by monotony, the noises of the wind and tires hitting the pavement, and the continuous hum of the motor. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right. Talking or sending text messages on a mobile phone while driving is unsafe.","imageName":"","correctAnswer":32085,"isBookmarked":0,"aid":"22336#26261#32085#32086","text":"talk on a cell phone#take stimulants#exercise your eyes#send text messages"},
{"testId":26,"qid":1023,"description":"Who is required to wear seat belts if the driver holds an intermediate driver license?","explanation":"Normally, Missouri law requires the driver and front-seat passengers to wear seat belts. If the driver just has an intermediate license, all passengers must wear seat belts.","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#2764#30886#34029","text":"The driver and all passengers#Only the driver#The driver and front-seat passengers#The driver and backseat passengers"},
{"testId":26,"qid":1025,"description":"To overtake a motorbike, you must:","explanation":"To function properly, a motorbike needs the whole width of the lane. When passing a motorbike, give it the whole lane width. Do not attempt to pass a motorbike. Wait for an open length of road before overtaking a motorcyclist in a lane too narrow to share.","imageName":"","correctAnswer":22577,"isBookmarked":0,"aid":"22576#22577#26274#26275","text":"not pass in an adjacent lane#pass in an adjacent lane#use the same lane as the motorcycle#use the rightmost lane"},
{"testId":26,"qid":1026,"description":"To make a turn, you must turn the steering wheel by:","explanation":"When turning bends, use the hand-over-hand approach to turn the steering wheel. You should not spin the wheel with only one hand since you may lose control. Straighten out the steering wheel by hand as you finish a turn.","imageName":"","correctAnswer":22452,"isBookmarked":0,"aid":"22452#22453#22728#34030","text":"the hand-over-hand technique#the single-hand technique#the hand-off-hand technique#one hand"},
{"testId":26,"qid":1027,"description":"When following a vehicle at night in Missouri, you must use your low lights:","explanation":"When you are within 500 feet of an incoming vehicle, you must activate your low beams. When following another vehicle within 300 feet, use your low beams as well. (It should be noted that other US states may have differing restrictions about when low lights must be employed.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":34032,"isBookmarked":0,"aid":"34031#34032#34033#34034","text":"within 350 feet of the vehicle ahead#within 300 feet of the vehicle ahead#within 450 feet of the vehicle ahead#within 400 feet of the vehicle ahead"},
{"testId":26,"qid":1028,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these places, they utilize red flags or stop/slow paddles.","imageName":"202002101220385703.jpg","correctAnswer":33800,"isBookmarked":0,"aid":"16154#33800#34035#34036","text":"School zone ahead#Flag person (flagger) ahead#Playground ahead#Road closed"},
{"testId":26,"qid":1029,"description":"If you're travelling in the fog at night, use your:","explanation":"High lights normally allow you to see farther ahead, but they might reflect off precipitation, generating glare. In fog, rain, or snow, use low lights. If the fog gets so thick that visibility is reduced to near zero, you should stop off the road and wait for it to partly lift. Remember, you can't drive if you can't see.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#34037","text":"Parking lights#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":26,"qid":1030,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":"Sometimes you have to deal with risks one by one. If you are going to pass a bicycle and an incoming vehicle approaches, slow down and let the car pass first to give the cyclist more space. Then move to the left to provide enough space to pass the bicycle.","imageName":"202007151849158328.jpg","correctAnswer":30622,"isBookmarked":0,"aid":"30622#34038#34039#34040","text":"Slow down and let the vehicle pass first#Increase your speed and pass the bicycle first#Sound your horn to warn the bicyclist of the oncoming vehicle#Drive between the vehicle and the bicycle"},
{"testId":26,"qid":1031,"description":"To keep a safe following distance behind the vehicle in front of you, follow:","explanation":"The three-second rule is a useful technique to calculate your safe following distance. Select a stationary item like a sign or a telephone pole. Start counting slowly as the car ahead of you passes it, \"one thousand one, one thousand two, one thousand three.\" If you approach the item before you complete counting, you are too near to the vehicle ahead.","imageName":"","correctAnswer":2170,"isBookmarked":0,"aid":"2170#2172#2173#3041","text":"the three-second rule#the two-second rule#the four-second rule#the one-second rule"},
{"testId":26,"qid":1033,"description":"Turning left from a two-way street onto a one-way street requires you to:","explanation":"When turning left from a two-way street into a one-way street, start with your left wheel as near to the yellow dividing line as feasible.","imageName":"","correctAnswer":22468,"isBookmarked":0,"aid":"22468#22469#22471#34041","text":"begin the turn with your left wheel as close as possible to the yellow dividing line#begin the turn with your right wheel as close as possible to the yellow dividing line#do any of the above#swing wide to the right before turning"},
{"testId":26,"qid":1034,"description":"If no traffic control signals are present, you must slow down or stop for pedestrians in:","explanation":"If no traffic signals are present, you must slow down or stop for pedestrians in a designated or unmarked crossing. When making a left or right turn at any junction, you must give pedestrians the right-of-way.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6934#8615#22522#22523","text":"An intersection#All of the above#a marked crosswalk#an unmarked crosswalk"},
{"testId":26,"qid":1035,"description":"If your vehicle begins to hydroplane, you should :","explanation":"When there is standing water on a highway, hydroplaning happens. Modern tires will channel water away from the vehicle at speeds of up to 35 mph, similar to how a windshield wiper cleans the windshield. As your speed rises, your tires' ability to channel water decreases. The tires may lose touch with the road and begin to ride over the water like a pair of water skis, perhaps leading you to lose control of your car. If your car begins to hydroplane, do not brake; doing so may result in a skid. Instead, let your car slow down by taking your foot off the pedal.","imageName":"","correctAnswer":23876,"isBookmarked":0,"aid":"10656#23876#29078#34042","text":"Do all of the above#slow down gradually#Brake hard#depress the accelerator"},
{"testId":26,"qid":1036,"description":"This driver's hand signal implies that he or she plans to:","explanation":"This motorist plans to slow down or come to a complete stop.","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6475#8990#8992#22345","text":"Speed up#make a right turn#make a U-turn#slow down or stop"},
{"testId":26,"qid":1037,"description":"If you are under the age of 21 and are detected driving with a BAC of ________, your driver's license may be suspended.","explanation":"If you are under the age of 21 and are detected driving with a BAC of 0.02 percent or more, your driver license may be suspended. (Note: An average 180-pound guy will have a BAC of 0.03 percent from one 12-ounce can of beer, one shot of 80-proof whiskey, or one 5-ounce glass of wine before his body begins to break down the alcohol.) As a result, the legal limit of less than 0.02 percent is practically zero tolerance.)","imageName":"","correctAnswer":2009,"isBookmarked":0,"aid":"2007#2008#2009#3441","text":"0.06% or more#0.08% or more#0.02% or more#0.04% or more"},
{"testId":26,"qid":1038,"description":"The stopping distance of a vehicle is equal to:","explanation":"Your stopping distance is equal to the sum of your response distance (the distance your vehicle travels while responding) and your braking distance (how far your vehicle travels before it stops). If you are driving quickly, weary, or your car has faulty brakes, you will require a longer stopping distance.","imageName":"","correctAnswer":24110,"isBookmarked":0,"aid":"9111#24108#24109#24110","text":"the braking distance#the sum of the braking distance and following distance#the sum of the reaction distance and following distance#the sum of the braking distance and reaction distance"},
{"testId":26,"qid":1039,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"A truck or bus has significant blind areas on either side, in the back, and in the front that a car cannot see. These are referred to as \"No-Zones.\" Only drive in a No-Zone if absolutely required. When passing a vehicle or bus, go through its No-Zone as fast and securely as feasible.","imageName":"","correctAnswer":236,"isBookmarked":0,"aid":"236#24370#30584#34043","text":"No-Zones#Blind-Zones#Zero-Zones#Stop-Zones"},
{"testId":26,"qid":1040,"description":"What is the meaning of this sign?","explanation":"No is represented as a red circle with a diagonal red slash. The red slash across the truck denotes that trucks are not permitted on this route.","imageName":"202003301723251644.jpg","correctAnswer":34045,"isBookmarked":0,"aid":"34044#34045#34046#34047","text":"Only large trucks are allowed on this road#No trucks are allowed on this road#Drivers can park their trucks here#No parking is allowed here"},
{"testId":23,"qid":1041,"description":"When driving behind another vehicle, which headlights should you use?","explanation":"When driving behind other cars, you should not use high lights. Bright lights reflected in a rearview mirror might distract the motorist.","imageName":"","correctAnswer":18694,"isBookmarked":0,"aid":"897#18694#18695#32976","text":"None of the above#Low beams#High beams#High beams if you are close to the other vehicle and low beams if you are far away from it"},
{"testId":23,"qid":1042,"description":"On a wet road, you may lose control of your vehicle because a layer of water forms between the tires and the road surface, causing the vehicle to slide. This is known as the:","explanation":"Most tires will channel water away from the tire at speeds up to 30 mph, similar to how a windshield wiper cleans the windshield. However, when your speed rises, the tires will be unable to channel the water as efficiently. Your tires may lose touch with the road and ride over the water like a pair of water skis, resulting in a skid. This is known as hydroplaning. It is more probable if your tires are worn or have low tire pressures.","imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8584#8693#9003","text":"Hydroplaning#tailgating#weaving#waterplaning"},
{"testId":23,"qid":1043,"description":"Do not back up if you miss your exit ramp on a motorway. Instead,:","explanation":"Never back up, turn around, or utilize a median crossover if you miss your exit ramp. (It is unlawful in Michigan to back up on a highway or utilize a median crossover.) Instead, take the next exit and attempt to double back from there.","imageName":"202007210432373628.jpg","correctAnswer":32433,"isBookmarked":0,"aid":"28740#32433#32977#32978","text":"Slow down and change lanes#proceed to the next exit#use a median for crossover#turn around on the shoulder"},
{"testId":23,"qid":1044,"description":"What is the meaning of this sign?","explanation":"Traffic in the right lane must turn right, while traffic in the left lane must either continue straight or turn right.","imageName":"202004071528591564.jpg","correctAnswer":32980,"isBookmarked":0,"aid":"32979#32980#32981#32982","text":"Traffic in the right lane must go straight, and traffic in the left lane must turn right#Traffic in the right lane must turn right, and traffic in the left lane must go straight or turn right#Traffic in the right lane must merge right, and traffic in the left lane must go straight or merge right#Traffic in the right lane must turn right, and traffic in the left lane must go straight or turn left"},
{"testId":23,"qid":1045,"description":"What should you do if you are being tailgated?","explanation":"Tailgating is an aggressive driving characteristic. Pull over and let another motorist through if they tailgate you. It is much preferable to have these dangerous drivers at the front where you can see them rather than on your back bumper.","imageName":"202001302249003541.jpg","correctAnswer":32983,"isBookmarked":0,"aid":"24111#24112#24114#32983","text":"Encourage the tailgater#Do not allow the tailgater to pass you#Increase your speed to discourage the tailgater#Pull over to the side and allow the tailgater to pass"},
{"testId":23,"qid":1046,"description":"Stay calm and do the following ____________,if your vehicle begins to slide.","explanation":"If your car starts to slide, be cool and follow these steps: 1) Take your foot off the gas pedal. 2) Only turn the front wheels far enough to keep them oriented in the desired direction. 3) Expect a second skid in the other direction. 4) If you have a secondary skid in the other direction, spin the wheels in the desired direction, then straighten the wheels to bring the vehicle under control.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#32984#32985#32986","text":"Do all of the above#Take your foot off the accelerator#Turn the front wheels only enough to keep them pointed in the direction you want to go and no farther. Be prepared for a secondary skid in the opposite direction#If a secondary skid in the opposite direction occurs, turn the wheels in the direction you want to go, then straighten the wheels to bring the vehicle under control"},
{"testId":23,"qid":1047,"description":"if a vehicle that is passing you has to cut back into the lane in front of you too quickly in order to avoid oncoming traffic, you should:","explanation":"Slow down and enable the other driver to complete the passing move safely if you are being passed by another car that has to cut back into your lane in front of you to avoid oncoming traffic.","imageName":"","correctAnswer":38576,"isBookmarked":0,"aid":"22544#38576#45660#45661","text":"continue at the same speed#slow down and keep right#use your horn to alert them of oncoming traffic#increase your speed and not let the vehicle in"},
{"testId":23,"qid":1048,"description":"What is the meaning of this sign?","explanation":"A red circle with a diagonal red slash indicates \"no.\" This sign indicates that cars are not permitted to conduct a U-turn here.","imageName":"202003151744407638.jpg","correctAnswer":32990,"isBookmarked":0,"aid":"19455#23403#32989#32990","text":"All vehicles must turn around at the sign#None of the above are true#Trucks are not allowed to turn around at the sign#Vehicles must not turn around at the sign"},
{"testId":23,"qid":1049,"description":"In poor weather(such as fog), you must maintain ________ while driving.","explanation":"In inclement weather, you should drive more slowly and maintain a higher following space between your car and others.","imageName":"","correctAnswer":3252,"isBookmarked":0,"aid":"295#3252#26230#32991","text":"High-beam headlights#a greater following distance#a smaller following distance#a higher speed"},
{"testId":23,"qid":1050,"description":"You have to let other vehicles go first in all of the situations below, except:","explanation":"When approaching a roundabout, you must surrender the right-of-way to other automobiles and respect all traffic signs. When merging and entering a highway from an alley, you must also surrender the right-of-way to other cars. A green arrow indicates that you may turn in that direction. This is referred regarded as a \"protected turn\" because incoming traffic is halted at a red light while the green arrow is illuminated for you.","imageName":"","correctAnswer":32992,"isBookmarked":0,"aid":"9065#9066#9067#32992","text":"when entering a highway from an alley#when entering a roundabout#when merging right or left#when making a left turn at a green arrow pointing left"},
{"testId":23,"qid":1052,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if your car has an automatic gearbox, choose a low gear to decrease brake wear.","imageName":"202002101212538783.jpg","correctAnswer":32993,"isBookmarked":0,"aid":"32993#32994#32995#32996","text":"A steep downgrade ahead; shift into a lower gear#A narrow passage near a hill; go slow#A bump ahead; maintain a constant speed#An acceleration ramp near a freeway; merge with freeway traffic"},
{"testId":23,"qid":1053,"description":"Is it recommended to cross a solid white line?","explanation":"In an area where lane changes are potentially problematic, a strong white line divides two lanes of traffic travelling in the same direction. Acceleration and deceleration lanes on freeways are examples of such sites.","imageName":"202004281851466397.jpg","correctAnswer":32998,"isBookmarked":0,"aid":"32997#32998#32999#33000","text":"Recommended#Not recommended#Occasionally recommended#Highly recommended"},
{"testId":23,"qid":1054,"description":"When turning left at a junction, you must cede the right-of-way to :","explanation":"Check for cross traffic and yield to approaching cars and pedestrians while turning left at a junction.","imageName":"","correctAnswer":32554,"isBookmarked":0,"aid":"32554#33001#33002#33003","text":"oncoming vehicles#vehicles behind you#vehicles moving ahead#vehicles on your right"},
{"testId":23,"qid":1055,"description":"To lessen the possibility of a rear-end collision, you must maintain a ________ following distance.","explanation":"Maintain a three-to-four-second trailing distance to avoid colliding with the car ahead of you.","imageName":"","correctAnswer":33007,"isBookmarked":0,"aid":"33004#33005#33006#33007","text":"six- to seven-second#five- to six-second#four- to five-second#three- to four-second"},
{"testId":23,"qid":1056,"description":"This symbol indicates that:","explanation":"This sign denotes a traffic island or other impediment. You must visit both sides of it.","imageName":"202002101234545593.jpg","correctAnswer":24411,"isBookmarked":0,"aid":"24411#24412#24413#24414","text":"you must go to either side#two-way traffic starts at the sign#a two-lane highway starts at the sign#you must merge right or left"},
{"testId":23,"qid":1057,"description":"A commercial vehicle moving at 55 mph has a stopping distance of approximately:","explanation":null,"imageName":"","correctAnswer":20795,"isBookmarked":0,"aid":"20794#20795#33008#33009","text":"300 feet#400 feet#130 feet#140 feet"},
{"testId":23,"qid":1058,"description":"What's the right-turn hand signal?","explanation":"Left arm and hand pointing up is the traditional hand signal for a right turn.","imageName":"","correctAnswer":33011,"isBookmarked":0,"aid":"897#3503#33010#33011","text":"None of the above#Left arm and hand straight out#Left arm and hand pointing down#Left arm and hand pointing up"},
{"testId":23,"qid":1059,"description":"A triangular orange symbol on the back of a vehicle denotes:","explanation":"A triangular orange insignia on the back of a car marks it as a slow-moving vehicle, one that cannot exceed 25 miles per hour. Certain agricultural equipment and construction equipment are examples of such vehicles. Don't get frustrated if you find yourself stuck behind one of these automobiles. To avoid traffic congestion, the motorist will normally attempt to move over to the side whenever feasible.","imageName":"","correctAnswer":9069,"isBookmarked":0,"aid":"9068#9069#9070#9071","text":"the vehicle can travel faster than 25 mph#the vehicle cannot travel faster than 25 mph#the vehicle cannot travel faster than 45 mph#the vehicle must travel slower than 55 mph"},
{"testId":23,"qid":1060,"description":"What is the meaning of this sign?","explanation":"This warning sign informs that there will be a pedestrian crossing ahead. Take precautions.","imageName":"202002101154005452.jpg","correctAnswer":33012,"isBookmarked":0,"aid":"102#28499#31209#33012","text":"A school zone is ahead#A play area is ahead#A bus stop is ahead#Pedestrian crosswalks are ahead"},
{"testId":23,"qid":1061,"description":"When traveling on a roundabout, you must always drive:","explanation":"A roundabout is a circular crossroads with traffic flowing counterclockwise around an island in the center. Vehicles approaching the circular must yield to traffic already in the circle. Always approach the centre island from the right.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"29718#29719#33013#33014","text":"in a clockwise direction#in a counterclockwise direction#to the left of the center island#on white lines in the center island"},
{"testId":23,"qid":1062,"description":"On a two-lane highway with two lanes in each direction, drive in the right lane EXCEPT:","explanation":"Drive in the right lane on a motorway with two lanes in each direction unless passing, leaving to the left, or allowing another vehicle to join onto the freeway. If you're driving at or near the speed limit, remain in the lane with slower traffic.","imageName":"","correctAnswer":24131,"isBookmarked":0,"aid":"24131#33015#33016#33017","text":"In all of the above situations#when passing#when exiting to the left#when allowing another vehicle to merge onto the freeway"},
{"testId":23,"qid":1063,"description":"Yield lines are commonly observed at roundabouts and mid-block crosswalks to :","explanation":"When you come across yield lines, ready to yield to pedestrians and other cars, halting if required. Yield lines are often placed near roundabouts and mid-block crosswalks to assist manage traffic flow. Stop behind the yield line when you yield.","imageName":"","correctAnswer":33018,"isBookmarked":0,"aid":"33018#33019#33020#33021","text":"help regulate the flow of traffic#allow high-speed vehicles#allow heavy vehicles#help damaged vehicles"},
{"testId":23,"qid":1064,"description":"What is the meaning of this sign?","explanation":"This emblem designates areas for vehicles driven by or used to transport people with impairments.","imageName":"202003151732554338.jpg","correctAnswer":31993,"isBookmarked":0,"aid":"1584#3523#3525#31993","text":"A hospital ahead#Wheelchair availability#A crosswalk for pedestrians#A place reserved for persons with disabilities"},
{"testId":23,"qid":1065,"description":"When you see white triangles painted across a traffic lane, you must :","explanation":"One sort of yield line is a white triangle painted across a driving lane. Prepare to stop and yield to oncoming vehicles.","imageName":"","correctAnswer":33022,"isBookmarked":0,"aid":"10656#24030#33022#33023","text":"Do all of the above#increase your vehicle speed#stop and yield to traffic#Get out of your vehicle"},
{"testId":23,"qid":1066,"description":"Turning requires you to signal at least ________ ahead of where you intend to turn.","explanation":"Turning requires you to indicate at least 100 feet ahead of where you want to turn. Drivers behind you will have more time to alter their speed if you signal early. (A car traveling at 40 mph will go 100 feet in less than two seconds.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#2292#3529","text":"100 feet#200 feet#60 feet#70 feet"},
{"testId":23,"qid":1067,"description":"When approaching a railroad crossing when a train approaches, you must ________ from the tracks.","explanation":"A moving train takes substantially longer to halt (up to a mile in most circumstances) than a passenger vehicle. If a train is approaching, all vehicles must come to a halt no more than 50 feet from the tracks and no less than 15 feet from the tracks. Trains are at least six feet wider than the tracks, so avoid getting too near to them.","imageName":"","correctAnswer":3533,"isBookmarked":0,"aid":"3530#3531#3532#3533","text":"no more than 100 feet and no less than 5 feet#no more than 50 feet and no less than 5 feet#no more than 25 feet and no less than 10 feet#no more than 50 feet and no less than 15 feet"},
{"testId":23,"qid":1068,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the impending arrival of a stop sign. Prepare to halt. You must come to a complete stop before any crosswalk or stop line marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":29987,"isBookmarked":0,"aid":"132#29987#33024#33025","text":"The road ahead is closed#You must stop ahead#The road ahead is slippery#Vehicles are allowed to travel in only one direction"},
{"testId":23,"qid":1069,"description":"Which of the following is NOT an illegal way to park?","explanation":"You are required to park within 12 inches of the curb. If your parked automobile is too far out in a travel lane, it may obstruct traffic.","imageName":"","correctAnswer":33026,"isBookmarked":0,"aid":"3538#3540#3541#33026","text":"Parking within 500 feet of a fire or a crash#Parking in a highway lane outside the city#Parking within 20 feet of a marked crosswalk#Parking within 12 inches of the curb"},
{"testId":23,"qid":1070,"description":"What is the safe or recommended speed limit when passing through a designated work zone?","explanation":"Unless otherwise stated, the safe or advised speed restriction while travelling through a designated work area is 45 mph. Fines for any driving offences in work zones are doubled.","imageName":"","correctAnswer":3544,"isBookmarked":0,"aid":"3542#3543#3544#3545","text":"15 mph or the posted speed#25 mph or the posted speed#45 mph or the posted speed#70 mph or the posted speed"},
{"testId":23,"qid":1071,"description":"For a driver who is at least 21 years old, what is the legal blood alcohol concentration (BAC) limit?","explanation":"The legal limit for blood alcohol concentration (BAC) for a motorist over the age of 21 is 0.08 percent. The legal limit for a motorist under the age of 21 is 0.02 percent.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#11768#11769#33027","text":"0.08%#0.10%#0.12%#0.80%"},
{"testId":23,"qid":1072,"description":"What is the meaning of this sign?","explanation":"The end of a split roadway is indicated by this sign. You must keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":22419,"isBookmarked":0,"aid":"22419#22656#30320#33028","text":"A divided highway ends ahead#A divided highway starts ahead#There is a two-way road ahead#There is merging traffic ahead"},
{"testId":23,"qid":1073,"description":"When you see or hear an ambulance coming from any direction, you must:","explanation":"Give way to an emergency vehicle arriving from any direction with flashing warning lights or sounding a siren or other audible signal. Allow the emergency vehicle to pass safely on the right side of the road.","imageName":"","correctAnswer":33029,"isBookmarked":0,"aid":"30231#33029#33030#33031","text":"keep driving without stopping#pull over to the right side of the road#stop your vehicle in the travel lane#move your vehicle to the left side of the road"},
{"testId":23,"qid":1074,"description":"When leaving a highway, you should first enter:","explanation":"Most highway exits include deceleration lanes that allow motorists to progressively slow down as they leave the freeway.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"8891#23853#26083#26085","text":"The shoulder#An acceleration lane#a deceleration lane#a merging area"},
{"testId":23,"qid":1075,"description":"You can avoid highway hypnosis on lengthy travels by:","explanation":"On lengthy rides, you may prevent tiredness by changing your gaze from one side of the road to the other. Continue to check your mirrors and gaze at items close and distant, left and right.","imageName":"","correctAnswer":33033,"isBookmarked":0,"aid":"30976#33032#33033#33034","text":"talking on your cell phone#drinking coffee or taking stimulants#shifting your eyes from one area of the road to another#frequently changing lanes"},
{"testId":23,"qid":1076,"description":"This traffic light urges drivers to:","explanation":"This five-section head signal (sometimes known as a \"doghouse signal\") is used to manage junction turns. When the green arrow illuminates, you have a protected turn; incoming traffic comes to a complete stop at a red light.","imageName":"202005121115486231.jpg","correctAnswer":8990,"isBookmarked":0,"aid":"27#28#8955#8990","text":"Change lanes#Make a left turn#stop#make a right turn"},
{"testId":23,"qid":1077,"description":"What does a steady yellow arrow pointing left at an intersection indicates?","explanation":"A constant yellow arrow indicates that the arrow is ready to become red. You must come to a complete stop as you approach the junction.","imageName":"","correctAnswer":33035,"isBookmarked":0,"aid":"8660#22673#33035#33036","text":"You must increase your speed#None of the above are true#The arrow is about to change to red#Oncoming traffic is stopped and you may turn left"},
{"testId":23,"qid":1079,"description":"If another vehicle is passing you while you are driving,:","explanation":"Do not raise your speed or race the motorist who is passing you if you are being passed. Allow the other motorist to safely execute the passing motion.","imageName":"","correctAnswer":8939,"isBookmarked":0,"aid":"8939#14688#45658#45659","text":"do not increase your speed#keep to the left#do not reduce your speed#race the driver that is passing you"},
{"testId":23,"qid":1080,"description":"What does this device denotes?","explanation":"Active railroad control systems notify drivers when a train is approaching. They utilize some combination of signs, signals, elevator gates, and bells or other aural alerts. When the bell rings and the lights flash, come to a halt. Never pass until the train has passed and the warnings have stopped.","imageName":"202003202123051932.jpg","correctAnswer":1701,"isBookmarked":0,"aid":"1701#3571#3572#4963","text":"A railroad crossing ahead#A tunnel ahead#A work zone ahead#A crossroad ahead"},
{"testId":28,"qid":1081,"description":"If you come upon a yield sign,:","explanation":"A yield sign indicates that you do not have the right of way. Before proceeding, you must slow down and surrender to cars arriving from other routes as you approach a yield sign. You may need to stop for them as well, so be prepared.","imageName":"","correctAnswer":34609,"isBookmarked":0,"aid":"34608#34609#34610#34611","text":"you must turn#you must slow down or stop if necessary#you must make a U-turn#you must not drive at less than the posted speed limit"},
{"testId":28,"qid":1082,"description":"Generally, work and construction zone signs are:","explanation":"Work zone signs are either rectangular or diamond in form. Work zone signs, on the other hand, contain black text or symbols on an orange backdrop, as opposed to permanent warning signs.","imageName":"","correctAnswer":34615,"isBookmarked":0,"aid":"34612#34613#34614#34615","text":"red and octagonal (eight-sided)#yellow and pennant shaped#green and triangular (three-sided)#orange with black letters or symbols"},
{"testId":28,"qid":1085,"description":"Rumble strips vibrate and make noise to alert sleepy or careless drivers that they are:","explanation":"Rumble strips are indentation features that are erected on a paved highway shoulder near the travel lane, on a two-lane roadway along the center line, or in a traffic lane approaching a stop sign or signal. Rumble strips use vibration and sound to inform fatigued or inattentive drivers that their cars have left the travel lane or are approaching a stop sign or signal.","imageName":"202007210451187730.jpg","correctAnswer":8754,"isBookmarked":0,"aid":"8751#8752#8753#8754","text":"approaching a freeway#approaching a parking zone or playground#approaching a school zone#approaching a stop sign or signal"},
{"testId":28,"qid":1086,"description":"A roundabout rotates traffic:","explanation":"Before entering a roundabout, you must yield to pedestrians and bicyclists in the crosswalk and to traffic approaching from your left. Then enter the roundabout, heading toward the right. Proceed to drive counterclockwise around the central island until you reach your exit.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"29718#29719#34616#34617","text":"in a clockwise direction#in a counterclockwise direction#in both directions#in the direction indicated by traffic signals"},
{"testId":28,"qid":1087,"description":"Which of the following should you NOT do when approaching a halted emergency vehicle or roadside assistance vehicle?","explanation":null,"imageName":"","correctAnswer":34618,"isBookmarked":0,"aid":"28148#34618#34619#34620","text":"Proceed with caution#Pull over and offer help to the vehicle#Slow down to a safe speed to pass the vehicle#Move at least one lane away from the vehicle"},
{"testId":28,"qid":1088,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into two lanes. Between opposing lanes, a physical barrier or median will arise. Keep to the right of this stumbling block.","imageName":"202002101247507801.jpg","correctAnswer":34622,"isBookmarked":0,"aid":"1960#2649#34621#34622","text":"Two-way traffic ahead#Traffic island ahead#End of a divided roadway#Start of a divided roadway"},
{"testId":28,"qid":1089,"description":"When you get close to a pedestrian with a white cane or guide dog, you must ______ until the person is well away from the road or path of travel.","explanation":"You must give way to blind or visually challenged pedestrians. When approaching a pedestrian with a white cane or guide dog, you must come to a complete stop until the pedestrian is far away from the roadway or line of travel.","imageName":"","correctAnswer":590,"isBookmarked":0,"aid":"210#590#3603#3604","text":"change lanes#come to a complete stop#not stop or slow down#pull your vehicle to the edge of the road"},
{"testId":28,"qid":1090,"description":"Unless otherwise specified, the maximum speed restriction ________ is 25 mph.","explanation":"Unless otherwise marked, the maximum speed restriction in Nebraska's residential districts is 25 mph.","imageName":"","correctAnswer":2513,"isBookmarked":0,"aid":"2513#34623#34624#34625","text":"in residential districts#on rural interstates#in business districts#on hard-surface county roads"},
{"testId":28,"qid":1091,"description":"Before turning or going right or left in Nebraska, you must signal at least before:","explanation":"Before turning or going right or left in Nebraska, you must signal for at least 100 feet. (Keep in mind that at 30 mph, your car will cover 100 feet in 2.3 seconds.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1397#3609","text":"100 feet#50 feet#150 feet#120 feet"},
{"testId":28,"qid":1092,"description":"This symbol can be found on the back of:","explanation":"A triangular orange insignia on the back of a car marks it as a slow-moving vehicle, which Nebraska defines as one that moves at less than 25 miles per hour. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#22520#29177#29774","text":"a slow-moving vehicle#a school bus#a vehicle carrying hazardous materials#an emergency vehicle"},
{"testId":28,"qid":1093,"description":"When you parallel park your vehicle, you must stop ___ the curb or road's edge.","explanation":"When parallel parking, you must park within 12 inches of the curb or the road's edge. Unless the street is one-way, always park on the right side of the road.","imageName":"","correctAnswer":34626,"isBookmarked":0,"aid":"34626#34627#34628#34629","text":"within 12 inches of#within 24 inches of#within 21 inches of#within 15 inches of"},
{"testId":28,"qid":1094,"description":"If you must stop at a railroad crossing that lacks a stop line, stop _________ from the nearest rail.","explanation":"Stop at the stop line if you must stop at a railroad crossing. Stop between 15 and 50 feet from the closest rail if there is no stop line. Trains are at least six feet wider than the tracks they operate on, so stay away from them.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"3615#3616#3618#5592","text":"less than 15 feet#more than 50 feet#less than 15 feet or more than 100 feet#between 15 and 50 feet"},
{"testId":28,"qid":1095,"description":"If you fail to take an exit on an interstate highway, you need to:","explanation":"Do not stop or back up if you miss your exit on an interstate highway. This dramatically raises the likelihood of an accident occurring. Backing up or utilizing emergency crossover points on an interstate highway is illegal in Nebraska. Instead, take the next exit and find a method to return from there.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8521#9110#34630#34631","text":"continue to the next exit#stop and ask for help#back up your vehicle to reach your exit#use an emergency crossover point to reach your exit"},
{"testId":28,"qid":1096,"description":"What does this highway sign mean?","explanation":"This sign serves as a reference point. These signs are placed in one-mile increments along Nebraska roadways to serve as a reference point for drivers in need of help.","imageName":"202004142320185224.jpg","correctAnswer":34632,"isBookmarked":0,"aid":"3624#3625#34632#34633","text":"The exit number#The distance to the next exit#A reference marker#The legal speed limit"},
{"testId":28,"qid":1097,"description":"The speed limit on an interstate route is:","explanation":"The speed limit on interstate highways is 40 miles per hour.","imageName":"","correctAnswer":20793,"isBookmarked":0,"aid":"14164#20793#23845#24347","text":"55 mph#40 mph#60 mph#50 mph"},
{"testId":28,"qid":1098,"description":"When approaching another vehicle at night from the front or back, use:","explanation":"When approaching an oncoming vehicle or following another vehicle within 200 feet, Nebraska law requires you to lower your headlights. (Note: Different states may have different laws regarding headlight dimming.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#34037","text":"Parking lights#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":28,"qid":1099,"description":"When approaching or passing a school bus that has its overhead amber warning lights flashing, you must:","explanation":"When approaching or passing a school bus with its overhead amber warning lights flashing, you must reduce your speed to 25 mph and ready to stop. You must come to a complete stop when the school bus's red stop lights and STOP arm are engaged.","imageName":"","correctAnswer":34636,"isBookmarked":0,"aid":"34634#34635#34636#34637","text":"slow to 35 mph#slow to 30 mph and prepare to stop#slow to 25 mph and prepare to stop#increase your speed and pass the school bus"},
{"testId":28,"qid":1100,"description":"This sign indicates a designated parking spot for:","explanation":"This sign bears the international emblem of impaired access. This signifies that these parking places are only available to people having disabled parking permits.","imageName":"202004071444232622.jpg","correctAnswer":34135,"isBookmarked":0,"aid":"29184#29185#34134#34135","text":"hospital purposes#wheelchair-bound patients#seniors#disabled persons"},
{"testId":28,"qid":1101,"description":"A school learner's permit (LPE) applicant must be at least:","explanation":"To receive a school learner's permit, a person must be at least 14 years old (LPE). He or she must also be qualified for and fulfill all School Permit (SCP) standards. After obtaining an LPE, a person may apply for a SCP at the age of 14 years and 2 months.","imageName":"","correctAnswer":34638,"isBookmarked":0,"aid":"34638#34639#34640#34641","text":"14 years old#15 years old#12 years old#16 years old"},
{"testId":28,"qid":1102,"description":"No-Zones are places near trucks or buses:","explanation":"Do not congregate in a No-Zone! No-Zones are locations surrounding trucks or buses where automobiles slip into blind spots or are so near that the truck or bus driver's ability to stop or move safely is compromised. Driving in a No-Zone significantly increases the likelihood of an accident. It is difficult to consistently avoid the No-Zones of a huge vehicle. When passing a huge vehicle, though, don't stay in one of its No-Zones for any longer than absolutely necessary.","imageName":"","correctAnswer":34645,"isBookmarked":0,"aid":"34642#34643#34644#34645","text":"where cars disappear into blind spots#where cars are so close that they restrict the truck or bus driver's ability to stop or maneuver safely#that greatly increase the potential for a crash#for which all of the above are true"},
{"testId":28,"qid":1103,"description":"You must maintain a minimum following distance of ________ between your vehicle and the vehicle in front of you.","explanation":null,"imageName":"","correctAnswer":551,"isBookmarked":0,"aid":"549#550#551#3640","text":"Two-second#Four-second#three-second#One-second"},
{"testId":28,"qid":1104,"description":"These highway signs are known as:","explanation":"Travelers may get critical information from dynamic message signs. Messages may be modified to suit current weather, route traffic, construction schedules, incidents, and so forth.","imageName":"202002211526586480.jpg","correctAnswer":19466,"isBookmarked":0,"aid":"19463#19464#19465#19466","text":"route marker signs#crossroads indicators#work zone markers#dynamic message signs"},
{"testId":28,"qid":1105,"description":"Point the front wheels _______ if you park uphill near to one.","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9705,"isBookmarked":0,"aid":"73#9705#28061#34646","text":"toward the curb#away from the curb#to the right#in any of the above directions"},
{"testId":28,"qid":1106,"description":"If the headlights of an approaching car are blinding you, you should:","explanation":"Don't gaze straight at an incoming car if its headlights are blinding you. Look instead to the right side of the road.","imageName":"","correctAnswer":34650,"isBookmarked":0,"aid":"34647#34648#34649#34650","text":"look directly at the driver of the vehicle#look toward the left edge of the road#look toward the center of the road#look toward the right edge of the road"},
{"testId":28,"qid":1107,"description":"Who is required to use a safety belt or adequate child restraint under Nebraska's Occupant Protection law?","explanation":"The driver and front-seat passengers must be fastened with a safety belt or proper child restraint under Nebraska's Occupant Protection statute. However, under Nebraska's Child Passenger Restraint statute, all passengers under the age of 18 must wear a safety belt or other child restraint, whether in the front or back seat.","imageName":"","correctAnswer":26667,"isBookmarked":0,"aid":"4420#16365#26667#34651","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The front-seat passengers"},
{"testId":28,"qid":1108,"description":"What does this figure represent?","explanation":"Two lanes of traffic heading in opposing directions are separated by a broken yellow line. When the path ahead is clear, a broken line enables passage on the left. However, you should exercise care and keep an eye out for incoming traffic.","imageName":"202002211536409341.jpg","correctAnswer":30151,"isBookmarked":0,"aid":"9186#9187#9188#30151","text":"Passing is allowed only during the daytime#Passing on the left is prohibited#Passing is not allowed in either direction#Passing on the left is allowed when the way ahead is clear"},
{"testId":28,"qid":1109,"description":"A double solid yellow line separates which of the following two lanes of traffic?","explanation":"A double solid yellow line divides opposing lanes of traffic and marks the center of the road; passing is not permitted in either direction; and you may cross a double solid yellow line only to make a left turn into or from an alley, private road, or driveway.","imageName":"","correctAnswer":26362,"isBookmarked":0,"aid":"9028#9029#9031#26362","text":"Moving in the same direction#Merging left#Merging right#Moving in opposite directions"},
{"testId":28,"qid":1110,"description":"If another vehicle is passing you while you are driving, you should:","explanation":"If another car passes you, remain in your lane and do not raise your speed. Allow the other motorist to pass safely.","imageName":"","correctAnswer":29188,"isBookmarked":0,"aid":"6515#8657#29188#32145","text":"Increase your speed#move into the left lane#stay in your lane and not increase your speed#pull your vehicle to the right and not increase your speed"},
{"testId":28,"qid":1111,"description":"At an intersection with no stop signs or traffic lights, two vehicles arrives at the same time,:","explanation":"If two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right.","imageName":"","correctAnswer":33286,"isBookmarked":0,"aid":"33286#33287#34652#34653","text":"the vehicle on the left must yield to the vehicle on the right#the vehicle on the right must yield to the vehicle on the left#both vehicles can proceed at the same time#both vehicles must stop completely, then the vehicle on the left may go first"},
{"testId":28,"qid":1112,"description":"This symbol can be used to:","explanation":"In work zones, flashing arrow panels may be used to direct cars into certain traffic lanes and to notify them that a portion of the road ahead is blocked.","imageName":"202003161205325987.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#19469#19470#34654","text":"Guide drivers into certain traffic lanes#warn drivers of a one-way roadway ahead#inform drivers of a school zone#require drivers to make a U-turn"},
{"testId":28,"qid":1113,"description":"When following another vehicle within ____________, you must dim your headlights.","explanation":"When approaching an oncoming vehicle or following another vehicle within 200 feet, Nebraska law requires you to lower your headlights. (Note: Different states may have different laws regarding headlight dimming.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":14313,"isBookmarked":0,"aid":"14313#20794#20795#34655","text":"200 feet#300 feet#400 feet#600 feet"},
{"testId":28,"qid":1114,"description":"When driving an airbag-equipped car, keep your hands on the steering wheel at :","explanation":null,"imageName":"","correctAnswer":34659,"isBookmarked":0,"aid":"34656#34657#34658#34659","text":"the 9 o'clock and 2 o'clock position#the 8 o'clock and 3 o'clock position#the 10 o'clock and 2 o'clock position#the 9 o'clock and 3 o'clock position"},
{"testId":28,"qid":1115,"description":"Which of the following blood alcohol concentration (BAC) statements is correct?","explanation":"If you drive with a BAC of 0.08 percent or above, you are deemed legally inebriated and may be prosecuted with driving under the influence (DUI). Driving with a BAC of 0.04 percent or more is also banned if you are driving a commercial motor vehicle (CMV), such as a bus or truck. Driving with a BAC of 0.02 percent or above is also banned if you are under the age of 21. After consuming one 12-ounce can of beer, an average 180-pound male will have a BAC of more than 0.02 percent before his body begins to break down the alcohol. As a result, for drivers under the age of 21, a BAC level of less than 0.02 percent is virtually zero tolerance.","imageName":"","correctAnswer":32659,"isBookmarked":0,"aid":"32659#34660#34661#34662","text":"Both a) and b) are true#A) It is illegal for a driver under 21 years of age to drive with a BAC of 0.02% or above#B) It is illegal for a driver at any age to drive with a BAC of 0.08% or above#Neither a) nor b) are true"},
{"testId":28,"qid":1116,"description":"This symbol cautions drivers that:","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful brakes in rainy conditions. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":9890,"isBookmarked":0,"aid":"9890#34663#34664#34665","text":"the road is slippery when wet#a steep hill is ahead#a gravel road is ahead#a winding road is ahead"},
{"testId":28,"qid":1117,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"If your car becomes stuck on a railroad track, do not waste time attempting to dislodge it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction you believe a train to arrive. If your vehicle is hit, you and your passengers will be protected from flying debris. Call the number listed on the railroad crossing sign or 911.","imageName":"202007210447482035.jpg","correctAnswer":34669,"isBookmarked":0,"aid":"34666#34667#34668#34669","text":"Try to push your vehicle off the tracks#Shift gears continuously to move the vehicle off the tracks#Switch on your emergency flashers to indicate your presence to the train crew#Get everyone out and get clear of the tracks"},
{"testId":28,"qid":1118,"description":"To exit a freeway or expressway, use _____ to slow down.","explanation":"Enter the deceleration lane before you begin to slow down to leave a motorway. Slow down in the deceleration lane to reach an exit ramp.","imageName":"","correctAnswer":2547,"isBookmarked":0,"aid":"2545#2547#3554#3680","text":"An acceleration lane#A deceleration lane#The shoulder#A merging lane"},
{"testId":28,"qid":1119,"description":"In fog, it is not advised to travel on roads with stated speeds of 40 mph or greater when visibility is:","explanation":"When visibility is less than 1/4 mile, it is not safe to drive on roads with stated speeds of 40 mph or higher in fog.","imageName":"","correctAnswer":34671,"isBookmarked":0,"aid":"34670#34671#34672#34673","text":"less than 1/2 mile#less than 1/4 mile#more than 1/4 mile#less than 1/3 mile"},
{"testId":28,"qid":1120,"description":"What is the meaning of this sign?","explanation":"This caution sign signals that a stop sign is ahead. Slow down and ready to come to a complete halt at the stop sign.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2018#2019#2021#3686","text":"A traffic merging area ahead#A stop signal ahead#A stop sign ahead#A closed road ahead"},
{"testId":32,"qid":1121,"description":"Which of the following things is NOT TRUE about the right-of-way?","explanation":"Right-of-way is given to vehicles already at a junction. A traffic circle, often known as a rotary, is a circular junction. Cars approaching a traffic circle or rotary must surrender to vehicles already inside the circle.","imageName":"","correctAnswer":37360,"isBookmarked":0,"aid":"31315#37359#37360#37361","text":"Vehicles turning left must yield to oncoming vehicles#Vehicles that are crossing a sidewalk or entering or exiting an alley, a driveway, or a parking lot must yield to pedestrians#Vehicles that are already in a traffic circle or rotary must yield to vehicles that are entering it#At a four-way stop, the vehicle that reaches the intersection first gets to proceed first"},
{"testId":32,"qid":1122,"description":"When you see an orange triangle sign on the back of a vehicle in front of you, you should :","explanation":"A reflective triangle orange insignia on the back of a car identifies it as a slow-moving vehicle, which New Mexico defines as one that moves at less than 25 miles per hour. Farm machinery, animal-drawn vehicles, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and be patient if you come across one of these cars. If feasible, change lanes.","imageName":"","correctAnswer":6476,"isBookmarked":0,"aid":"6476#8955#37362#37363","text":"Slow down#stop#pass the vehicle faster than the posted speed limit#share the vehicle's lane"},
{"testId":32,"qid":1123,"description":"A dashed white line between traffic lanes indicates that you may:","explanation":"A white line divides two lanes of traffic traveling in the same direction. If there is a dashed white line between lanes, you may cross it to change lanes if it is safe to do so.","imageName":"","correctAnswer":37365,"isBookmarked":0,"aid":"37364#37365#37366#37367","text":"not cross it#cross it to change lanes, if it is safe to do so#cross it only to make a left turn#cross it only to make a right turn"},
{"testId":32,"qid":1124,"description":"This symbol is posted:","explanation":"This sign warns of approaching merging traffic. If you notice this sign while driving on the main road, be prepared for cars approaching your lane from the right. To enable these cars to combine safely, adjust your speed and location.","imageName":"202002101239016186.jpg","correctAnswer":37368,"isBookmarked":0,"aid":"30629#37368#37369#37370","text":"where passing and turning are permitted#where two traffic lanes merge#where a freeway divides#where the right lane is closed due to construction"},
{"testId":32,"qid":1126,"description":"What is the speed limit in commercial and residential areas in New Mexico, unless otherwise posted?","explanation":"Except when otherwise marked, the speed limit in commercial and residential zones of New Mexico is 30 mph.","imageName":"","correctAnswer":521,"isBookmarked":0,"aid":"520#521#522#682","text":"15 mph#30 mph#25 mph#55 mph"},
{"testId":32,"qid":1127,"description":"Drivers must yield to _________, at an intersection where there are no stop signs or traffic signals.","explanation":"Drivers must yield to cars coming from the right while approaching an uncontrolled junction (one without signs or signals). Drivers should, however, always be prepared to yield if required to prevent an accident.","imageName":"","correctAnswer":8522,"isBookmarked":0,"aid":"8522#8523#8747#22402","text":"vehicles coming from the right#vehicles coming from the left#heavy vehicles#vehicles turning right"},
{"testId":32,"qid":1128,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"15575#22424#22426#37371","text":"A narrow bridge is ahead#A winding road is ahead#The road ahead is slippery when wet#The road ahead is covered in gravel"},
{"testId":32,"qid":1129,"description":"When driving around a traffic circle or rotary, you must drive slowly:","explanation":"A traffic circle, often known as a rotary, is a circular junction where traffic flows counterclockwise around a central island. Keep to the right of the center island in the traffic circle.","imageName":"","correctAnswer":37373,"isBookmarked":0,"aid":"26268#29718#37372#37373","text":"in any direction#in a clockwise direction#on the left side of the island#on the right side of the island"},
{"testId":32,"qid":1130,"description":"You parked with your back to the hill. Which way should your front wheels be pointed?","explanation":"When parallel parking downhill, angle your wheels toward the curb or the side of the road. Your car may roll forward if your brakes fail, but not into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse with a manual transmission).","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"6656#9713#14638#25321","text":"Parallel to the curb#Away from the curb#Toward the curb#In any direction"},
{"testId":32,"qid":1132,"description":"What is the meaning of this sign?","explanation":"This sign denotes the end of the split roadway. On the same road ahead, traffic flows in both directions. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#22656#26319#37374","text":"The divided highway ends ahead#A divided highway starts ahead#The right lane is closed ahead#You are entering a one-way roadway"},
{"testId":32,"qid":1134,"description":"Which of the following sites is legal to park in New Mexico?","explanation":"In New Mexico, parallel parking must be done within 18 inches of the curb. You are not permitted to park on the roadside of another parked car (double parking). (Please keep in mind that there are several other areas where you are not permitted to park.) Look for signs prohibiting or limiting parking.)","imageName":"","correctAnswer":37376,"isBookmarked":0,"aid":"3830#10188#37375#37376","text":"Within 15 feet of a fire hydrant#On the road side of a parked vehicle#Within 25 feet of a crosswalk#Within 18 inches of the curb"},
{"testId":32,"qid":1135,"description":"To make a turn, you must turn the steering wheel by the:","explanation":"Use the hand-over-hand method to spin the steering wheel while rounding a bend. If you spin the wheel with just one hand, you may lose control. Straighten out the steering wheel by hand after you've completed the turn. Allowing it to slip between your fingers might be risky.","imageName":"","correctAnswer":22185,"isBookmarked":0,"aid":"22185#24022#37377#37378","text":"hand-over-hand technique#one-hand technique#hand-to-hand technique#no-hand technique"},
{"testId":32,"qid":1136,"description":"This symbol warns of:","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Even if your car has an automatic gearbox, use low gear to avoid brake wear. Keep an eye out for vehicles that are slowly descending, such as buses and trucks. Vehicles may gain speed as they descend a steep incline.","imageName":"202002101212538783.jpg","correctAnswer":37379,"isBookmarked":0,"aid":"23818#29861#37379#37380","text":"a slippery road#trucks carrying hazardous materials#a steep descent#trucks entering from a steep side road"},
{"testId":32,"qid":1138,"description":"When is it appropriate to use your high-beam headlights?","explanation":"When driving on unknown roads, in construction zones, or where there may be people on the road, use your high lights. High lights provide double the visibility of low beams. However, New Mexico law requires you to lower your headlights when you are 500 feet or less from an incoming vehicle or 200 feet from a vehicle you are following.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"3743#3744#22962#28468","text":"When you drive on unfamiliar roads#When you drive in construction areas#In all of the above situations#Where there may be people alongside the road"},
{"testId":32,"qid":1139,"description":"Before you change lanes, turn right or left, merge into traffic, park, or stop, you should start to signal from at least _____ away.","explanation":"You should begin signaling at least 100 feet before changing lanes, turning right or left, merging into traffic, or parking. This gives other drivers enough time to respond to your moves.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#898#1026#1028","text":"100 feet#250 feet#50 feet#10 feet"},
{"testId":32,"qid":1140,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, survey the road ahead, and drive cautiously.","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#22176#26784#29963","text":"Slow down and proceed with caution#Take a detour#Stop if safe; the light will soon turn red#Stop, yield, and proceed when it is safe to do so"},
{"testId":32,"qid":1141,"description":"A steady red arrow indicates:","explanation":"A red arrow indicates that you must stop and cannot go in the direction of the arrow. You may advance only after the red arrow disappears and a green arrow or light appears.","imageName":"","correctAnswer":9191,"isBookmarked":0,"aid":"8658#8659#9190#9191","text":"you must come to a full stop and then proceed when it is safe to do so#you can safely turn in the direction of the arrow#you should prepare to stop and give the right-of-way to oncoming traffic before you turn#you must stop, and you cannot go in the direction of the arrow"},
{"testId":32,"qid":1142,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are huge spaces surrounding trucks and other big vehicles where automobiles may slip into the truck driver's blind spots or come so near that the truck driver's ability to stop or maneuver safely is hampered. No-Zones significantly enhance the chance of a collision.","imageName":"","correctAnswer":2281,"isBookmarked":0,"aid":"2278#2281#3755#3756","text":"Zero-zones#No-zones#Slow zones#Blind zones"},
{"testId":32,"qid":1144,"description":"This symbol signifies that you are permitted to:","explanation":"The arrow represents a right turn. If it is safe to do so, this regulation sign authorizes you to do a U-turn here.","imageName":"202003151704081572.jpg","correctAnswer":8992,"isBookmarked":0,"aid":"28#8990#8992#10156","text":"Make a left turn#make a right turn#make a U-turn#Go straight"},
{"testId":32,"qid":1145,"description":"You may experience hydroplaning when it is raining or the road is wet and you are driving at the speed of:","explanation":null,"imageName":"","correctAnswer":37383,"isBookmarked":0,"aid":"37381#37382#37383#37384","text":"less than 35 mph#more than 15 mph#more than 35 mph#more than 22 mph"},
{"testId":32,"qid":1147,"description":"If you can't see 400 feet ahead, you're probably not driving safely at:","explanation":"You may respond to what you observe and come to a full stop at a speed of 50 mph in roughly 400 feet. If you can't see 400 feet in front of you, it could be too late for you to avoid striking anything by the time you realise it's in your path.","imageName":"","correctAnswer":24347,"isBookmarked":0,"aid":"9969#20793#22201#24347","text":"30 mph#40 mph#25 mph#50 mph"},
{"testId":32,"qid":1148,"description":"What is the meaning of this sign?","explanation":"This symbol indicates that a pedestrian crossing is ahead. Pedestrians must be given the right of way by vehicles.","imageName":"202002101154005452.jpg","correctAnswer":1945,"isBookmarked":0,"aid":"1945#3769#3770#34384","text":"A pedestrian crosswalk ahead#A hospital zone ahead#A flag person in a work zone#A school crossing"},
{"testId":32,"qid":1149,"description":"Which of the following you must use, if you are in heavy traffic or following another vehicle within 200 feets at night?","explanation":"High lights allow you to see farther ahead, but they might also blind a close motorist. When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, New Mexico law requires you to lower your headlights.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9855#22155#22156#34037","text":"Any of the above#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":32,"qid":1150,"description":"If your tires appear to be losing traction on the road, you should ________.","explanation":"If you feel like your tires are losing grip on the road, take your foot off the throttle pedal and maintain the steering wheel straight. Except in an emergency, do not turn. (If you have to turn, do it carefully or your vehicle may slide.) Do not attempt to stop or turn until your tires have regained traction on the road.","imageName":"","correctAnswer":8617,"isBookmarked":0,"aid":"8617#9198#37385#37386","text":"keep the steering wheel straight#press hard on the gas pedal#make a sharp right turn#try to stop"},
{"testId":32,"qid":1151,"description":"Which of the following claims about using a cell phone while driving is TRUE in New Mexico?","explanation":null,"imageName":"","correctAnswer":37390,"isBookmarked":0,"aid":"37387#37388#37389#37390","text":"Slow down to talk on a cell phone#You may send text messages if you hold an unrestricted driver license#It is illegal to talk on a handheld cell phone#If you must make a call, pull over to the side of the road in a safe place"},
{"testId":32,"qid":1152,"description":"What is the meaning of this sign?","explanation":"This sign warns that there will be a traffic light ahead. If the light is yellow or red, prepare to come to a complete stop. These signs are posted on roads with higher speed restrictions or in areas where your view of the traffic signal ahead may be obstructed.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"897#1585#2021#35371","text":"None of the above#A traffic signal ahead#A stop sign ahead#A flashing signal ahead"},
{"testId":32,"qid":1153,"description":null,"explanation":"You must not drive faster than the visible distance. For safe driving, use the four-second sight distance guideline. Choose a stationary item as far ahead as you can see. Then time how long it takes you to get to that thing. You're driving too quickly if it takes you fewer than four seconds to get there. This guideline should also be used at night to ensure that you are not \"overdriving\" your headlights.","imageName":"","correctAnswer":1102,"isBookmarked":0,"aid":"1100#1101#1102#1103","text":"two-second sight distance rule#three-second sight distance rule#four-second sight distance rule#one-second sight distance rule"},
{"testId":32,"qid":1154,"description":"The act of tailgating is:","explanation":"Tailgating is the practice of following too closely. Do not attempt it. The most prevalent form of accident on interstate roads is a rear-end collision, and tailgating is the most common cause of rear-end crashes. The car ahead of the tailgater abruptly slows down, leaving the tailgater with little distance to avoid colliding with it. Maintain a safe following distance at all times.","imageName":"","correctAnswer":8757,"isBookmarked":0,"aid":"8756#8757#8758#33066","text":"driving in blind spots#following too closely#making sharp lane changes#crossing a railroad crossing gate"},
{"testId":32,"qid":1155,"description":"If you're driving at 55 mph, you shouldn't utilize the opposing lane to pass another vehicle if you're close to_______ from a hill or bend.","explanation":"It takes roughly 10 seconds to securely pass in the opposite lane. Assume you're travelling at 55 mph and there's an incoming car at the same speed that you can't see because it's hidden by a hill or bend. In around 10 seconds, the two cars will cover one-third of a mile. As a result, if you move into the opposite lane to pass while you are within a third of a mile of that hill or bend, you risk a head-on accident.","imageName":"","correctAnswer":5535,"isBookmarked":0,"aid":"5532#5534#5535#37391","text":"half a mile#one mile#one-third of a mile#two miles"},
{"testId":32,"qid":1156,"description":"This posted sign denotes:","explanation":"This sign states that the vertical clearance of this route is 12' 6\" \" (twelve feet six inches). Vehicles higher than this should avoid using this route. Some tractor-trailers are 13' 6\" in length \"foot tall","imageName":"202002101233137248.jpg","correctAnswer":37392,"isBookmarked":0,"aid":"24197#37392#37393#37394","text":"the distance from the curb#a low underpass#the width of a lane#the distance from the railroad tracks"},
{"testId":32,"qid":1157,"description":"A driver of any age can be arrested in New Mexico for driving with a blood alcohol concentration (BAC) of 0.","explanation":"A motorist of any age may be arrested in every US state if their blood alcohol concentration (BAC) is 0.08 percent or above. A motorist under the age of 21 in New Mexico may also be arrested for driving with a BAC of 0.02 percent or higher. If the driver is found guilty, the sanctions might be harsh.","imageName":"","correctAnswer":29055,"isBookmarked":0,"aid":"29055#32753#37395#37396","text":"0.08% or more#0.02% or more#0.01% or more#0.008% or more"},
{"testId":32,"qid":1158,"description":"The Graduated Driver Licensing (GDL) System's third and final level is:","explanation":null,"imageName":"","correctAnswer":37400,"isBookmarked":0,"aid":"37397#37398#37399#37400","text":"an instructional permit#a provisional license#a learner's permit#an unrestricted driver license"},
{"testId":32,"qid":1159,"description":"If a tire goes flat unexpectedly, you should ________ and keep driving straight until it stops.","explanation":"If a tire goes flat unexpectedly, you should grip the steering wheel securely, remove your foot off the gas pedal, and drive straight until it stops. If at all, use the brakes softly.","imageName":"","correctAnswer":3795,"isBookmarked":0,"aid":"564#2106#3795#29154","text":"move into the right lane#move into the left lane#hold the steering wheel tightly#brake hard"},
{"testId":32,"qid":1160,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Another path will intersect yours. Slow down, take a peek to the left and right, and get ready to enter the crossroads.","imageName":"202002101146277756.jpg","correctAnswer":4963,"isBookmarked":0,"aid":"1584#1701#4963#35405","text":"A hospital ahead#A railroad crossing ahead#A crossroad ahead#A helipad ahead"},
{"testId":37,"qid":1161,"description":"Which occupants of a vehicle are required by Oklahoma law to use seat belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":41595,"isBookmarked":0,"aid":"16365#34838#41594#41595","text":"The driver and all passengers#The driver and rear-seat passengers#The driver and children under 13 years of age#The driver, front-seat passengers, and children under 13 years of age"},
{"testId":37,"qid":1162,"description":"The correct hand position on the steering wheel for a firm and secure grip is:","explanation":"You should keep your left hand between 9:00 and 10:30 and your right hand between 2:00 and 4:00 to generate a significant turning force on the steering wheel.","imageName":"","correctAnswer":41599,"isBookmarked":0,"aid":"41596#41597#41598#41599","text":"the left hand at 11:00 and the right hand at 2:00#the left hand at 8:00 and the right hand at 2:00#the left hand between 8:00 and 9:00 and the right hand between 4:00 and 5:00#the left hand between 9:00 and 10:30 and the right hand between 2:00 and 4:00"},
{"testId":37,"qid":1163,"description":"When driving at night in fog, you should use your :","explanation":"Dim your headlights while driving at night in fog. The intense light from high beams may generate glare when it reflects off fog, rain, or snow, preventing you from seeing far ahead.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#34037#35389","text":"High-beam headlights#Low-beam headlights#Emergency flashers#interior lights"},
{"testId":37,"qid":1164,"description":"This symbol denotes:","explanation":"The maximum speed limit under normal driving conditions is indicated by this regulatory sign. You are not permitted to exceed 65 mph in this area. This does not imply that driving at 65 mph is safe in this area; it is dependent on the present driving conditions. Slow down below the maximum speed limit under dangerous driving circumstances.","imageName":"202004281823541604.jpg","correctAnswer":41601,"isBookmarked":0,"aid":"41600#41601#41602#41603","text":"the lowest speed at which you can travel under ideal conditions#the highest speed at which you can travel under ideal conditions#the lowest speed at which you can travel in bad weather#the highest speed at which you can travel in bad weather"},
{"testId":37,"qid":1165,"description":"What should you do when approaching a vehicle with a triangular orange reflective sign on the back?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":15493,"isBookmarked":0,"aid":"15493#17333#25445#26363","text":"Slow down#Turn on your headlights#Speed up#Stop"},
{"testId":37,"qid":1166,"description":"If a train is approaching, you must stop ________ from the nearest rail at a railroad crossing.","explanation":"If a train is coming, you must come to a complete stop at a railroad crossing between 15 and 50 feet from the closest rail. (You don't want to get too near; trains are at least six feet wider than the rails they operate on.) Hold your position until the train has passed and no further trains are on their way.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#30241#40843#41604","text":"between 15 and 50 feet#between 5 and 100 feet#between 15 and 30 feet#between 10 and 200 feet"},
{"testId":37,"qid":1167,"description":"If a truck is attempting to pass you, you can assist the driver by:","explanation":"If a truck is attempting to pass you, you may assist the truck driver by staying to the far side of your lane and slowing down somewhat. Never accelerate while a car is attempting to pass you. A vehicle has significant blind areas to the back and sides. Avoid driving in such blind zones as much as possible.","imageName":"","correctAnswer":28705,"isBookmarked":0,"aid":"7857#28705#28706#39378","text":"increasing your speed#keeping to the far side of your lane and reducing your speed slightly#keeping to the near side of your lane and reducing your speed slightly#reducing your speed and driving in a blind spot"},
{"testId":37,"qid":1168,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to halt. You must come to a complete stop before any crosswalk or stop line marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":35830,"isBookmarked":0,"aid":"35828#35829#35830#41605","text":"Prepare to turn ahead#Prepare to yield ahead#Prepare to stop ahead#Prepare to merge ahead"},
{"testId":37,"qid":1169,"description":"When you are within _______ of an oncoming vehicle, you must dim your headlights.","explanation":"When you are within 1,000 feet of an incoming vehicle or within 600 feet of a vehicle you are pursuing, you must reduce your headlights to low beam.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"401#1296#2781#4432","text":"400 feet#500 feet#600 feet#1,000 feet"},
{"testId":37,"qid":1170,"description":"In which of the following locations is it prohibited to park a vehicle?","explanation":"Parking on a sidewalk, at an intersection, or within 20 feet of a crosswalk is unlawful in Oklahoma. Parking within 15 feet of a fire hydrant is likewise prohibited.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#3830#3831#3832","text":"All of the above#Within 15 feet of a fire hydrant#Within 20 feet of a crosswalk#On a sidewalk"},
{"testId":37,"qid":1171,"description":"If another car is tailgating you, you must ________.","explanation":"Tailgating is a common reason for rear-end crashes. Slow down and urge the motorist behind you to pass if you are being tailgated. If this fails, pull over, come to a complete stop, and allow the tailgater pass. Don't try to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202001302249003541.jpg","correctAnswer":8978,"isBookmarked":0,"aid":"6515#8657#8978#39020","text":"Increase your speed#move into the left lane#slow down and encourage the driver to pass#not allow the driver to pass"},
{"testId":37,"qid":1173,"description":"To have sufficient safety cushion with the vehicle ahead of you, use:","explanation":"Many sources propose the \"three-second rule\" for providing a enough cushion of safety in front of your vehicle: Maintain a three-second following distance behind the car in front of you. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance.","imageName":"","correctAnswer":30202,"isBookmarked":0,"aid":"30201#30202#30203#30204","text":"the four-second rule#the three-second rule#the two-second rule#the one-second rule"},
{"testId":37,"qid":1174,"description":"What is the legal limit for blood alcohol concentration for a driver who is at least 21 years old?","explanation":null,"imageName":"","correctAnswer":38260,"isBookmarked":0,"aid":"38260#40728#40729#40730","text":"Less than 0.08%#0.10% or less#Less than 0.12%#0.09% or less"},
{"testId":37,"qid":1175,"description":"In severe rain, your vehicle's tires might lose touch with the road and ride on a layer of water. This threat is also known as:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" (At low speeds, wide tires may begin to hydroplane.) Hydroplaning may cause entire loss of braking and steering control at speeds of 50 mph or higher. Slow down to avoid hydroplaning.","imageName":"202003201654212060.jpg","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8626#9002#9003","text":"Hydroplaning#None of the above#rainplaning#waterplaning"},
{"testId":37,"qid":1177,"description":"To avoid a skid when braking a car without antilock brakes on ice, snow, sleet, gravel, sand, and other slippery surfaces, tap your brakes slightly at ________intervals.","explanation":"Hard braking of a car without antilock brakes on ice, snow, sleet, gravel, sand, and other slippery surfaces may lock the drive wheels and cause a skid. Instead, softly touch your brakes at one-second intervals. (An antilock braking system pumps the brakes for you, allowing you to apply strong braking pressure even on a slick terrain.)","imageName":"","correctAnswer":3640,"isBookmarked":0,"aid":"550#551#2544#3640","text":"Four-second#three-second#Five-second#One-second"},
{"testId":37,"qid":1179,"description":"Unless otherwise indicated, the maximum speed limit on four-lane split roads and two-lane superhighways in Oklahoma is :","explanation":"Unless otherwise marked, the maximum speed restriction on four-lane divided roads and two-lane superhighways in Oklahoma is 70 mph. Under dangerous driving circumstances, you should, of course, slow down below this maximum limit.","imageName":"","correctAnswer":685,"isBookmarked":0,"aid":"684#685#1677#3852","text":"65 mph#70 mph#45 mph#75 mph"},
{"testId":37,"qid":1180,"description":"This symbol denotes:","explanation":"This sign warns of an impending two-lane small bridge or culvert. Approach it slowly and with care.","imageName":"202002101245562248.jpg","correctAnswer":8964,"isBookmarked":0,"aid":"8662#8663#8964#37431","text":"an underpass ahead#a railroad crossing ahead#a narrow bridge ahead#a narrow one-way road ahead"},
{"testId":37,"qid":1182,"description":"Before making a turn, slowing down, or stopping in a city, you should signal for at least _________ seconds.","explanation":"Before you turn, slow down, or stop, you should start signaling for at least 100 feet. 100 feet is about one-third of a block in the city.","imageName":"","correctAnswer":41607,"isBookmarked":0,"aid":"5489#41606#41607#41608","text":"one block#a half block#a third of a block#a quarter of a block"},
{"testId":37,"qid":1183,"description":"Use _____ and adjust your speed before changing lanes to get on an expressway.","explanation":"To enter an expressway, use an acceleration lane and stay to the right. Accelerate to keep up with highway traffic. When it is safe to do so, signal left and merge into the correct lane.","imageName":"","correctAnswer":2545,"isBookmarked":0,"aid":"2545#2547#3862#4002","text":"An acceleration lane#A deceleration lane#the extreme left lane#an exit ramp"},
{"testId":37,"qid":1185,"description":"If you are within ______ of an obscured view, do not attempt to pass another vehicle.","explanation":"The Oklahoma Driver's Manual states that you shall not attempt to pass if you are within 100 feet of an obscured view or if cars or pedestrians may cross your path. (Note: Even 100 feet is a little short. Remember that your car will move 100 feet in 1.7 seconds at 40 mph. That is the only margin of safety you will have to escape an accident.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#4061","text":"100 feet#50 feet#25 feet#75 feet"},
{"testId":37,"qid":1186,"description":"What should you do if you miss your exit on an expressway?","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Instead, take the next exit, leave the highway, and rejoin it in the other direction.","imageName":"202007210432373628.jpg","correctAnswer":34698,"isBookmarked":0,"aid":"24258#32570#34698#38138","text":"Back up to reach your exit#Make a U-turn to reach your exit#Continue to the next exit#Pull over to the shoulder and call for help"},
{"testId":37,"qid":1187,"description":"If you're traveling slower than other traffic, remain in the lane:","explanation":"Vehicles driving slower than the rest of the traffic on an expressway should stay to the right.","imageName":"","correctAnswer":8892,"isBookmarked":0,"aid":"8640#8891#8892#41609","text":"The center lane#The shoulder#the right-hand lane#the left-hand lane"},
{"testId":37,"qid":1188,"description":"What is this object?","explanation":"According to the Oklahoma Driver's Manual, this item is a nighttime reflector that indicates the location of the road.","imageName":"202004142346021549.jpg","correctAnswer":19486,"isBookmarked":0,"aid":"19483#19484#19486#41610","text":"A traffic light#An interstate route marker#A nighttime reflector#A police officer's radar gun"},
{"testId":37,"qid":1189,"description":"Do not pass in the other lane until you have at least ___ of open road.","explanation":"According to the Oklahoma Driver's Manual, you should not pass another vehicle in the opposite lane unless you have at least 200 feet of unobstructed highway with no no-passing zones or approaching cars. (Note: Even 200 feet is a little short.) If your car and an incoming vehicle are both moving at 40 mph, the two vehicles will cover a 200-foot gap in only 0.9 seconds, at which point the two vehicles may hit head-on. Passing should be avoided until the opposite lane is free for a long distance.)","imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"214#399#1026#4582","text":"100 feet#200 feet#50 feet#20 feet"},
{"testId":37,"qid":1190,"description":"The minimum speed limit on Oklahoma's expressways is generally:","explanation":"The minimum speed restriction on Oklahoma's expressways is normally 40 mph. Unless driving circumstances are bad or dangerous, you may not drive slower than this minimal speed. Minimum speeds are imposed to prevent cars from moving too slowly and obstructing traffic or posing a danger.","imageName":"","correctAnswer":20793,"isBookmarked":0,"aid":"9969#14165#20793#24347","text":"30 mph#35 mph#40 mph#50 mph"},
{"testId":37,"qid":1191,"description":"If you observe ________ next to your lane on a multilane road, you may pass when it is safe to do so.","explanation":"A dashed white line divides two lanes of traffic traveling in the same direction on a multilane road. When it is safe to do so, you may cross the line to pass or change lanes.","imageName":"","correctAnswer":3883,"isBookmarked":0,"aid":"3883#3884#3885#8810","text":"a dashed white line#A solid yellow line#A solid white line#a double solid yellow line"},
{"testId":37,"qid":1192,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the road ahead rapidly turns to the right. Slow down, stay to the right, and don't try to pass.","imageName":"202003301742532030.jpg","correctAnswer":41611,"isBookmarked":0,"aid":"132#22176#33028#41611","text":"The road ahead is closed#Take a detour#There is merging traffic ahead#The road ahead curves sharply"},
{"testId":37,"qid":1193,"description":"What types of parking are typically seen in parking lots, retail centers, and on the street?","explanation":"Angle parking is often seen in parking lots, retail malls, and even near curbs. Keep an eye out for pedestrians and oncoming traffic before and after you pull into an angle parking place, and avoid parking too near to other cars.","imageName":"","correctAnswer":1912,"isBookmarked":0,"aid":"1911#1912#26206#41612","text":"Parallel parking#Angle parking#Hill parking#Downhill parking"},
{"testId":37,"qid":1194,"description":"You are driving on a road with no dividers. If you see flashing red lights or a red STOP sign on a school bus, you must:","explanation":"If you approach a school bus with flashing red lights or a STOP sign on an undivided highway, you must come to a full stop, regardless of which direction the bus is driving. You must stay stopped until either (1) the bus begins to move; (2) the bus driver indicates for you to go; or (3) the flashing red lights turn off and the STOP sign retracts.","imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"27#8955#41613#41614","text":"Change lanes#stop#pass the bus at a higher speed#pass the bus at a lower speed"},
{"testId":37,"qid":1195,"description":"Which vehicle has the right-of-way when two vehicles arrive at an intersection without traffic signs or signals at roughly the same time?","explanation":"When two cars approach at an uncontrolled junction (one that lacks signs or signals) about the same time, the driver on the left must yield to the driver on the right.","imageName":"","correctAnswer":14664,"isBookmarked":0,"aid":"14664#35963#41615#41616","text":"The vehicle on the right#The vehicle on the left#The vehicle turning left#The vehicle turning right"},
{"testId":37,"qid":1196,"description":"What is the meaning of this sign?","explanation":"Most railroad crossings have Crossbuck signs. When the lights begin to flash, it indicates that a train is coming and that you must come to a halt between 15 and 50 feet from the closest rail.","imageName":"202003181410217334.jpg","correctAnswer":133,"isBookmarked":0,"aid":"133#19330#19331#19332","text":"A railroad crossing is ahead#A train station is ahead#A railroad track parallel to the roadway is ahead#An abandoned railroad track is ahead"},
{"testId":37,"qid":1197,"description":"As you approach an intersection, you notice a pedestrian crossing the street with a white cane. You must halt:","explanation":null,"imageName":"","correctAnswer":41618,"isBookmarked":0,"aid":"41617#41618#41619#41620","text":"5 feet away#15 feet away#2 feet away#10 feet away"},
{"testId":37,"qid":1198,"description":"You must turn on your headlights _____ and whenever visibility is limited.","explanation":"From 30 minutes after nightfall to 30 minutes before dawn, and anytime visibility is reduced, you must use your headlights.","imageName":"","correctAnswer":6050,"isBookmarked":0,"aid":"6048#6049#6050#41621","text":"from 120 minutes after sunset to 120 minutes before sunrise#from 60 minutes after sunset to 60 minutes before sunrise#from 30 minutes after sunset to 30 minutes before sunrise#from 90 minutes after sunset to 90 minutes before sunrise"},
{"testId":37,"qid":1199,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"If an emergency vehicle approaches you with a siren and flashing lights, you must pull over to the right side of the road and stay stopped until the emergency vehicle has passed. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and then pull over promptly.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"22434#22544#22545#22546","text":"increase your speed and clear the lane#continue at the same speed#stop immediately wherever you are#pull over to the right edge of the road"},
{"testId":37,"qid":1200,"description":"What does this image represent?","explanation":"This flagperson is telling traffic to slow down and be cautious.","imageName":"202002270946508485.jpg","correctAnswer":19487,"isBookmarked":0,"aid":"19487#26322#34681#41622","text":"You must slow down#You must stop#You must change lanes#You may proceed at your usual speed"},
{"testId":38,"qid":1202,"description":"_________on roadways need drivers' heightened vigilance and strict adherence to all traffic restrictions in these places.","explanation":"The Oregon Department of Transportation may designate a segment of roadway as a \"safety corridor\" if it has had more fatal and severe injury collisions than the norm. The goal is to urge drivers to exercise additional caution and strictly adhere to all traffic regulations in the area. Fines for traffic offenses may be doubled in a safety corridor, traffic law enforcement may be intensified, and small technical changes may be implemented to improve safety. Turn on your lights for safety while driving in a safety corridor.","imageName":"","correctAnswer":3921,"isBookmarked":0,"aid":"3919#3920#3921#3922","text":"Safety zones#Rest areas#Safety corridors#Acceleration lanes"},
{"testId":38,"qid":1203,"description":"When approaching a junction with a flashing yellow light, you must:","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and take your time.","imageName":"","correctAnswer":9181,"isBookmarked":0,"aid":"9181#22695#26062#39279","text":"slow down and proceed with caution#increase your speed and clear the intersection#proceed at the same speed#stop your vehicle 15 feet before the intersection"},
{"testId":38,"qid":1204,"description":"This caution symbol warns drivers that:","explanation":"This sign alerts drivers to a multi-use path crossing. Bicyclists and pedestrians cross the street often in this neighborhood. Proceed with care and be prepared to halt at any time.","imageName":"202002101151189304.jpg","correctAnswer":19493,"isBookmarked":0,"aid":"19491#19492#19493#19494","text":"a single-use path crossing is ahead#the lane must be shared with bicyclists#a multi-use path crossing is ahead#a school zone is ahead"},
{"testId":38,"qid":1206,"description":"What does a double solid white line indicates?","explanation":"A double solid white line divides two lanes moving in the same direction, but crossing the line to pass or change lanes is banned. On certain roads, a high-occupancy vehicle (HOV) lane is separated from other lanes traveling in the same direction by a double solid white line.","imageName":"202003161937198796.jpg","correctAnswer":33718,"isBookmarked":0,"aid":"33718#33719#33720#33721","text":"Separates lanes traveling in the same direction, but crossing the line is prohibited#Separates lanes traveling in the same direction, and crossing the line is permitted#Separates opposing lanes, and crossing the line is prohibited#Separates opposing lanes, but crossing the line is permitted"},
{"testId":38,"qid":1207,"description":"What is the maximum speed limit in residential areas of Oregon, unless otherwise posted?","explanation":"Unless otherwise marked, the maximum speed restriction in residential areas in Oregon is 25 miles per hour.","imageName":"","correctAnswer":3343,"isBookmarked":0,"aid":"2518#3343#3937#5967","text":"40 miles per hour#25 miles per hour#30 miles per hour#35 miles per hour"},
{"testId":38,"qid":1209,"description":"When you drive slower than the average speed of traffic, you should drive:","explanation":"If you drive slower than the typical pace of traffic in Oregon, you must travel in the right lane or as near to the right curb or edge of the road as feasible, unless you are about to make a left turn. If you break this legislation, you might be fined $265. [ORS 811], [ORS 153.019] .315]","imageName":"","correctAnswer":39280,"isBookmarked":0,"aid":"9010#39280#39281#39282","text":"in the center lane#in the right lane or close to the right edge of the roadway#in any lane or close to either edge of the roadway#in the left lane or close to the left edge of the roadway"},
{"testId":38,"qid":1210,"description":"What is the safe following distance behind the vehicle in front of you?","explanation":null,"imageName":"","correctAnswer":39286,"isBookmarked":0,"aid":"39283#39284#39285#39286","text":"1鈥2 seconds#1鈥3 seconds#2鈥3 seconds#2鈥4 seconds"},
{"testId":38,"qid":1211,"description":"If another vehicle is passing you, you should _______ until the pass is completed.","explanation":"Allow another car to pass you safely if you are being passed. You are not permitted to raise your speed until the pass is completed, according to Oregon law. You must also stay right if you are being passed on the left. [ORS 811 .410]","imageName":"","correctAnswer":28729,"isBookmarked":0,"aid":"125#758#22057#28729","text":"increase your speed#stop#move to the left#not increase your speed"},
{"testId":38,"qid":1212,"description":"This symbol can be found:","explanation":"This sign may be located near a motorway exit. It recommends the maximum safe speed (in this example, 25 mph) under perfect driving circumstances. Slow down below this suggested speed in bad or dangerous driving conditions.","imageName":"202004071520203237.jpg","correctAnswer":10847,"isBookmarked":0,"aid":"6645#10847#39287#39288","text":"At intersections#On freeways#at school crossings#in residential areas"},
{"testId":38,"qid":1214,"description":"If you are in traffic, you must signal _________ before turning or changing lanes.","explanation":"Before you turn, change lanes, shift right or left, or pull away from a curb, you must signal. If you are driving in traffic, you must signal at least 100 feet before turning or changing lanes.","imageName":"","correctAnswer":2026,"isBookmarked":0,"aid":"2026#2027#3091#3092","text":"at least 100 feet#at least 50 feet#at least 25 feet#at least 75 feet"},
{"testId":38,"qid":1216,"description":"A sign bearing this symbol denotes:","explanation":"This is the worldwide emblem of access for individuals with disabilities. A sign with this emblem indicates that a facility or parking lot for people with impairments is ahead.","imageName":"202003151732554338.jpg","correctAnswer":39289,"isBookmarked":0,"aid":"22393#22394#39289#39290","text":"emergency services are available ahead#a hospital is ahead#a facility or parking area ahead for persons with disabilities#a store selling wheelchairs is ahead"},
{"testId":38,"qid":1217,"description":"In Oregon, you have to turn down your headlights when you are within ______ of another car.","explanation":"When an incoming vehicle is within 500 feet or you are following another vehicle within 350 feet, you must reduce your headlights to low beam under Oregon law.","imageName":"","correctAnswer":39291,"isBookmarked":0,"aid":"20796#39291#39292#39293","text":"500 feet#350 feet#700 feet#650 feet"},
{"testId":38,"qid":1218,"description":"You parked parallel to the curb and faced downhill. Which way should your front wheels be pointed?","explanation":"When parallel parking facing downhill, position your wheels toward the curb. If your brakes fail, the curb will prevent you from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse with a manual transmission).","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"6656#9713#14638#25321","text":"Parallel to the curb#Away from the curb#Toward the curb#In any direction"},
{"testId":38,"qid":1219,"description":"What kind of parking are typically seen in parking lots, retail centers, and on wide streets?","explanation":"Angle parking is often seen in parking lots, retail malls, and even on broad roadways. Keep an eye out for pedestrians and oncoming traffic before and after you pull into an angle parking place, and avoid parking too near to other cars.","imageName":"","correctAnswer":1912,"isBookmarked":0,"aid":"1911#1912#26205#26206","text":"Parallel parking#Angle parking#Emergency parking#Hill parking"},
{"testId":38,"qid":1221,"description":"In Oregon, parallel parking requires you to park your vehicle no more than _____ from the curb.","explanation":"In parallel parking in Oregon, you must park your car parallel to and no more than 12 inches from the curb. Park in the direction that cars in the lane are traveling.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"815#818#4921#5420","text":"15 inches#12 inches#18 inches#50 inches"},
{"testId":38,"qid":1222,"description":"A roundabout rotates traffic:","explanation":"Traffic flows counterclockwise around a central island in a roundabout. Enter or depart a roundabout by turning right.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"64#26066#29718#29719","text":"toward the left#toward the center#in a clockwise direction#in a counterclockwise direction"},
{"testId":38,"qid":1225,"description":"To enter a freeway, use _____ to accelerate and merge with already-moving traffic.","explanation":"Use an on-ramp to accelerate and combine with fast-moving traffic already on the road. Before merging, you must yield to oncoming traffic, but try to avoid halting if feasible.","imageName":"","correctAnswer":32935,"isBookmarked":0,"aid":"2547#3554#4002#32935","text":"A deceleration lane#The shoulder#an exit ramp#an on-ramp"},
{"testId":38,"qid":1226,"description":"When visibility is reduced, a vehicle stopped or parked on a road or shoulder in Oregon must have its _____ on. :","explanation":"When visibility is reduced, a vehicle stopped or parked on a road or shoulder in Oregon must have its parking lights turned on. However, it is prohibited to use parking lights in lieu of headlights while driving at night or in severe weather.","imageName":"","correctAnswer":296,"isBookmarked":0,"aid":"294#295#296#4003","text":"Low-beam headlights#High-beam headlights#parking lights#fog lights"},
{"testId":38,"qid":1227,"description":"You should _______ to be able to stop safely in wet, icy, or snowy circumstances.","explanation":"Low visibility necessitates slower speeds. You need to follow other cars further behind. Rain, snow, and ice reduce your field of vision and lengthen the braking distance needed to stop your car. Increase your following distance and stop earlier and more softly than normal in these situations.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#8829#24203","text":"Increase your speed#Increase your following distance#Change lanes frequently#Use high-beam headlights"},
{"testId":38,"qid":1228,"description":"What is the meaning of this pentagonal (five-sided) sign?","explanation":"School zone and school crossing signs are the only pentagonal (five-sided) signs. Slow down, be cautious, and be prepared to yield to pedestrians. Follow the instructions of the school crossing guards.","imageName":"202004180808539704.jpg","correctAnswer":39296,"isBookmarked":0,"aid":"19503#19504#39295#39296","text":"A public library ahead; watch for pedestrians#A school bus stop ahead; stop your vehicle#Pedestrian crosswalk ahead; proceed with caution#A school or school crossing ahead; slow down and watch for children"},
{"testId":38,"qid":1229,"description":"If your vehicle begins to slide, turn the steering wheel:","explanation":"If you begin to skid, be cool, take your foot off the accelerator pedal, and slowly guide your vehicle in the direction you want the front of your vehicle to travel. Avoid braking, particularly if your vehicle lacks antilock brakes (ABS). Overshooting might cause your car to slide in the other direction. To avoid this, move the steering wheel in the other direction as soon as your car begins to straighten out.","imageName":"","correctAnswer":39298,"isBookmarked":0,"aid":"62#64#39297#39298","text":"toward the right#toward the left#opposite to the direction in which you want your vehicle to go#in the direction in which you want your vehicle to go"},
{"testId":38,"qid":1232,"description":"This orange sign can be found at:","explanation":"Work zone signs alert motorists to unusual or possibly hazardous situations on or around the traveled route. The lettering or symbols on these signs are black on an orange backdrop. Slow down and pay careful attention if you encounter these indicators.","imageName":"202002101224499681.jpg","correctAnswer":8763,"isBookmarked":0,"aid":"7695#8763#8765#39299","text":"school zones#work zones#railroad intersections#intersections not controlled by signs or signals"},
{"testId":38,"qid":1233,"description":"You want to turn at an intersection with a traffic light, but a pedestrian is crossing in the lane you want to turn into. Before you start to turn, the pedestrian must be at least _____ from the lane you will be turning into.","explanation":null,"imageName":"","correctAnswer":39300,"isBookmarked":0,"aid":"5685#23979#23980#39300","text":"five feet#two feet#three feet#six feet"},
{"testId":38,"qid":1234,"description":"When the vehicle is in motion, Oregon law requires _________ to wear seat belts or be in child safety seats.","explanation":null,"imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#3330#30886#39301","text":"The driver and all passengers#All passengers#The driver and front-seat passengers#only front-seat passengers"},
{"testId":38,"qid":1235,"description":"You are traveling on a divided highway with a painted median strip separating the two roadways. You approach a stopped school bus with flashing lights on the opposite side of the road. What should you do?","explanation":"In Oregon, you must stop for a stopped school bus with flashing lights, regardless of which side of the road it is on. Continue to stand still until the bus driver shuts off the flashing red lights. There is one exception: If the school bus is stopped on the other side of a split highway and the roadway is separated by an unpaved median strip or barrier, you are not required to stop. A painted median strip or a two-way left turn lane is not considered a barrier. (Note: This exemption is not available in all states.) Other exclusions are provided by certain states. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":39302,"isBookmarked":0,"aid":"19471#28714#39302#39303","text":"Slow down and proceed with caution#Proceed at the same speed#Stop for the school bus#Increase your speed and pass the bus"},
{"testId":38,"qid":1236,"description":"This symbol is:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":31318,"isBookmarked":0,"aid":"31318#39304#39305#39306","text":"a railroad crossing sign#an interstate merging area sign#a road repair warning sign#a work area detour sign"},
{"testId":38,"qid":1237,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"If an emergency vehicle approaches you with a siren and flashing lights, you must pull over to the right side of the road and stay stopped until the emergency vehicle has passed. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and then pull over promptly.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"22434#22545#22546#24031","text":"increase your speed and clear the lane#stop immediately wherever you are#pull over to the right edge of the road#pull over to the left edge of the road"},
{"testId":38,"qid":1238,"description":"What exactly is a sharrow?","explanation":"A sharrow is a pavement marker that indicates that the lane is shared by automobiles and bicycles. It is made up of two chevrons placed over a bicycle symbol.","imageName":"","correctAnswer":39308,"isBookmarked":0,"aid":"39307#39308#39309#39310","text":"A pavement marking indicating a bike box#A pavement marking indicating that the lane is shared by bicycles and cars#A crosswalk used by bicycles#An unmarked crosswalk shared by bicycles and pedestrians"},
{"testId":38,"qid":1239,"description":"What is the legal limit for blood alcohol concentration (BAC) for drivers who are 21 years or older?","explanation":"A chemical test for drunkenness will fail someone over the age of 21 who has a blood alcohol concentration (BAC) of 0.08 percent or greater.","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23194#33734#39311#39312","text":"0.08%#0.09%#0.11%#0.12%"},
{"testId":38,"qid":1240,"description":"What is the meaning of this sign?","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you have the option of going straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":39315,"isBookmarked":0,"aid":"39313#39314#39315#39316","text":"Vehicles must go straight#Vehicles must make a left turn#Vehicles may either go straight or turn left#Vehicles going straight must merge left"},
{"testId":31,"qid":1241,"description":"________ is the most common style of parking on city streets.","explanation":"Parallel parking is the most popular style of parking on city streets. Each car is parallel-parked to the curb. Its tires must be within six inches of the curb in New Jersey, which is closer than in many other US states.","imageName":"","correctAnswer":35809,"isBookmarked":0,"aid":"35808#35809#35810#35811","text":"Angle parking#Parallel parking#Downhill parking#Uphill parking"},
{"testId":31,"qid":1242,"description":"According to New Jersey law,________ in every passenger in a vehicle must wear a seat belt or a proper child restraint.","explanation":"Seat belts or appropriate kid restraints are required to be worn by all passengers of any passenger vehicle in New Jersey. The driver is legally required to ensure that all passengers under the age of 18 are properly restrained. Each passenger above the age of 18 may be penalized for not wearing a seat belt.","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#2764#30886#35812","text":"The driver and all passengers#Only the driver#The driver and front-seat passengers#only the rear-seat passengers"},
{"testId":31,"qid":1243,"description":"Maintain both hands on the steering wheel at _____ when driving.","explanation":"When driving a contemporary automobile with airbags, keep both hands on opposite sides of the steering wheel: nine o'clock and three o'clock, or even eight o'clock and four o'clock. The driver's side airbag deploys quickly from the steering wheel. If your hands are higher than nine and three o'clock on the steering wheel, the deploying airbag might shove your hands into your face, harming you.","imageName":"","correctAnswer":28603,"isBookmarked":0,"aid":"28603#28605#35813#35814","text":"the nine o'clock and three o'clock positions#the ten o'clock and three o'clock positions#the eight o'clock and three o'clock positions#the nine o'clock and two o'clock positions"},
{"testId":31,"qid":1244,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist is indicating a need to slow down or halt.","imageName":"202002181152514351.jpg","correctAnswer":31425,"isBookmarked":0,"aid":"31425#35815#35816#35817","text":"The driver intends to slow down or stop#The driver intends to make a right turn#The driver intends to make a left turn#The driver intends to make a U-turn"},
{"testId":31,"qid":1245,"description":"If you want to turn, you must begin signaling at least _______ before the turn.","explanation":"According to New Jersey law, you must begin signaling at least 100 feet before turning.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#4061","text":"100 feet#50 feet#25 feet#75 feet"},
{"testId":31,"qid":1246,"description":"When driving near large vehicles, keep in aware that they may take as much as ____ longer to stop.","explanation":"A truck, tractor-trailer, or bus might take up to 25% longer to halt in poor weather.","imageName":"","correctAnswer":44222,"isBookmarked":0,"aid":"44221#44222#44223#44224","text":"35 percent#25 percent#30 percent#50 percent"},
{"testId":31,"qid":1247,"description":"What should you do if you park your vehicle uphill next to a curb?","explanation":"When parallel parking uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your hand brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":35819,"isBookmarked":0,"aid":"35818#35819#35820#35821","text":"Set the hand brake and turn the wheels toward the curb#Set the hand brake and turn the wheels away from the curb#Set the hand brake and keep the wheels straight#Put the transmission in first gear"},
{"testId":31,"qid":1248,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":35822,"isBookmarked":0,"aid":"161#4070#4071#35822","text":"A slow-moving vehicle#A tow truck#A slippery road#A steep descent"},
{"testId":31,"qid":1249,"description":"Passing is not permitted on either side of a road if there is _________ in the center.","explanation":"Passing is not allowed in either way, and you may only cross a double solid yellow line to perform a left turn.","imageName":"","correctAnswer":8810,"isBookmarked":0,"aid":"848#8810#35823#35824","text":"Any of the above#a double solid yellow line#a double broken line#a broken yellow line alongside a solid yellow line"},
{"testId":31,"qid":1250,"description":"When performing a left turn at a controlled junction, a driver must yield to :","explanation":"You must yield to incoming cars and stop for pedestrians inside a crosswalk while making a left turn at a controlled junction.","imageName":"202007151952104222.jpg","correctAnswer":32554,"isBookmarked":0,"aid":"14206#22402#32554#33001","text":"vehicles on the right#vehicles turning right#oncoming vehicles#vehicles behind you"},
{"testId":31,"qid":1251,"description":"When you come across a stopped school bus with flashing red lights on either side of a road without a divider, you must:","explanation":"When a school bus stops for passengers, it will flash red lights. Vehicles moving in either direction must stop at least 25 feet apart on an undivided route. They must stay halted until the flashing red lights go off.","imageName":"","correctAnswer":35825,"isBookmarked":0,"aid":"9181#35825#35826#35827","text":"slow down and proceed with caution#stop at least 25 feet away#change lanes and proceed with caution#stop at least 15 feet away"},
{"testId":31,"qid":1253,"description":"Which of the following things about headlights is not true?","explanation":"When traveling in wide terrain with no traffic in sight, you should utilize high lights. High lights allow you to see farther ahead, but they might also blind a close motorist. They may also cause glare by reflecting off precipitation. When you are within 500 feet of an incoming vehicle, New Jersey law compels you to use low lights. In the event of fog, rain, or snow, utilize low beams.","imageName":"","correctAnswer":22549,"isBookmarked":0,"aid":"22549#22550#30923#30924","text":"High beams are used when traveling behind other vehicles#Low beams are used when traveling in fog, rain, or snow#High beams are used in open country driving when there is no traffic in sight#Low beams are used in city driving and driving in traffic"},
{"testId":31,"qid":1254,"description":"Following a car too closely is also reffered to as:","explanation":"Maintain a safe distance from other cars on the road to allow yourself enough time to respond to the unexpected. Tailgating is the practice of following a vehicle too closely. This is a primary contributor to rear-end crashes.","imageName":"202003201758501730.jpg","correctAnswer":8584,"isBookmarked":0,"aid":"8584#8703#24122#24123","text":"tailgating#defensive driving#sidegating#aggressive driving"},
{"testId":31,"qid":1255,"description":"Stay at least _____ behind the vehicle ahead of you for every 10 mph of speed to reduce the chance of a collision.","explanation":"For every 10 mph of speed, you should keep at least one car length (approximately 20 feet) behind the vehicle in front of you. Increase your following distance at high speeds or in severe conditions. Many drivers, however, have difficulties assessing distances while driving. As a result, the three-seconds-plus rule is often a superior approach to calculate a safe following distance.","imageName":"","correctAnswer":4090,"isBookmarked":0,"aid":"4090#4091#4092#4093","text":"one car length#two car lengths#three car lengths#four car lengths"},
{"testId":31,"qid":1256,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending surrender sign. Prepare to slow down and yield to oncoming traffic from other roads.","imageName":"202002101144205880.jpg","correctAnswer":35829,"isBookmarked":0,"aid":"35828#35829#35830#35831","text":"Prepare to turn ahead#Prepare to yield ahead#Prepare to stop ahead#Prepare to pass ahead"},
{"testId":31,"qid":1257,"description":"When driving at 20 mph on packed snow, keep a following distance of at least:","explanation":null,"imageName":"","correctAnswer":31501,"isBookmarked":0,"aid":"31499#31501#31502#34530","text":"three seconds#six seconds#Four seconds#Five seconds"},
{"testId":31,"qid":1258,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#33656#35832","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + reaction distance#perception distance + braking distance"},
{"testId":31,"qid":1259,"description":"While your car is in motion, do not turn the ignition key to the lock position because it will:","explanation":"When your car is in motion, never turn your ignition key to the lock position. This will cause the steering to lock and you to lose control. Since 1969, the steering lock has been standard equipment. Its purpose is to prevent a burglar from directing your automobile if he starts it without the key.","imageName":"","correctAnswer":35835,"isBookmarked":0,"aid":"35833#35834#35835#35836","text":"lock the transmission#lock the brakes#lock the steering wheel#lock the gas pedal"},
{"testId":31,"qid":1260,"description":"What is the meaning of this sign?","explanation":"The lettering or symbols on guide or service signs are white on a blue backdrop. This service sign indicates that a hospital is ahead.","imageName":"202003151737026825.jpg","correctAnswer":4774,"isBookmarked":0,"aid":"4774#35837#35838#35839","text":"A hospital service#A rest area#A high school#A handicapped service area"},
{"testId":31,"qid":1262,"description":"If a vehicle approaches you with its high lights turned on, you should look _________ until the vehicle passes.","explanation":"If an incoming car has its high-beam headlights turned on, avoid staring at the lights. To avoid being briefly blinded, keep your gaze on the right side of the road.","imageName":"","correctAnswer":35842,"isBookmarked":0,"aid":"35840#35841#35842#35843","text":"toward the left edge of the road#at the vehicle's headlights#toward the right edge of the road#at the center of the road"},
{"testId":31,"qid":1263,"description":"In the event that a tire blows out, you should:","explanation":"If you have a flat tire or a blowout, remove your foot off the accelerator pedal, grip the steering wheel tightly, and maintain your car straight while gently slowing down. Only gradually brake after regaining control of the car.","imageName":"","correctAnswer":35845,"isBookmarked":0,"aid":"10656#29078#35844#35845","text":"Do all of the above#Brake hard#keep your foot on the gas pedal#keep your vehicle going straight"},
{"testId":31,"qid":1264,"description":"This symbol indicates that:","explanation":"This sign shows that employees are undertaking road repair. Slow down and take your time. Move into a lane away from the employees if feasible.","imageName":"202002101222518967.jpg","correctAnswer":35847,"isBookmarked":0,"aid":"32544#32546#35846#35847","text":"a pedestrian crosswalk is ahead#an icy road is ahead#a flagger is ahead#a work crew is ahead"},
{"testId":31,"qid":1265,"description":"Driving with a blood alcohol content (BAC) of _____ or above is banned in New Jersey for anybody over the age of 21.","explanation":"Driving with a blood alcohol content (BAC) of 0.08 percent or above is unlawful in all 50 states. It is also unlawful in New Jersey for anybody under the age of 21 to drive with a BAC of 0.01 percent or higher.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4653","text":"0.08%#0.02%#0.05%#0.01%"},
{"testId":31,"qid":1266,"description":"What is a \"no-zone\" area?","explanation":"Do not congregate in a No-Zone! No-Zones are vast zones around trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. The greater the vehicle's size, the bigger its No-Zones. No-Zones significantly enhance the chance of a collision. It is difficult to consistently avoid the No-Zones of a huge vehicle. However, do not stay in its No-Zones for any longer than required. Never, ever tailgate a truck.","imageName":"","correctAnswer":35851,"isBookmarked":0,"aid":"35848#35849#35850#35851","text":"A free parking space#A speed zone on a highway#A work zone on a highway#A blind spot of a large vehicle"},
{"testId":31,"qid":1268,"description":"This symbol stands for:","explanation":"Route marker signs show the kind of route and the specific road on which you are going. Typically, highway marker signs are shield-shaped. This sign denotes that you are on Interstate Highway 22. (I-22 for short).","imageName":"202003301737564419.jpg","correctAnswer":8802,"isBookmarked":0,"aid":"8802#8804#19233#19333","text":"the interstate highway number#the number of miles to the next exit#an exit number#the interstate highway speed limit"},
{"testId":31,"qid":1269,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"You are not permitted to drive on the shoulder to pass another car in New Jersey at any time.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#34188#34387#34388","text":"Never#If the vehicle is turning left#If the vehicle is turning right#If the vehicle has stopped for an emergency"},
{"testId":31,"qid":1270,"description":"Unless otherwise indicated, the speed limit in an urban school zone, business district, or residential district in New Jersey is .","explanation":"Unless otherwise marked, the speed limit in a school zone or most commercial or residential areas in New Jersey is 25 mph. However, unless otherwise indicated, the speed limit in many low-density suburban commercial or residential zones is 35 mph.","imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"522#682#1676#2135","text":"25 mph#55 mph#35 mph#50 mph"},
{"testId":31,"qid":1271,"description":"At a red light, you may turn right, but only after you:","explanation":"In New Jersey, unless there is a No Turn on Red sign barring it, you may make a right turn on a red light. However, you must come to a full stop before turning and yield to pedestrians and oncoming cars.","imageName":"","correctAnswer":35852,"isBookmarked":0,"aid":"9197#10656#35852#35853","text":"Turn on your headlights#Do all of the above#stop and yield to pedestrians and approaching vehicles#slow down and yield to pedestrians and approaching vehicles"},
{"testId":31,"qid":1272,"description":"This symbol indicates that:","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you have the option of going straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":33739,"isBookmarked":0,"aid":"33739#35854#35855#35856","text":"you may either go straight or turn left#you may either go straight or turn right#there is a sharp turn ahead#you must make a left turn if you are keeping left"},
{"testId":31,"qid":1273,"description":"Which of the following is a Do Not Enter Sign?","explanation":"A Do Not Enter sign is a white square with a red circle within, with a horizontal white band and the words \"DO NOT ENTER\" in white letters on the top and bottom of the circle.","imageName":"","correctAnswer":35858,"isBookmarked":0,"aid":"35857#35858#35859#35860","text":"A red octagon (eight-sided figure)#A red circle inside a white square#A yellow triangle#A yellow diamond"},
{"testId":31,"qid":1274,"description":"When driving at night in fog, you should use your :","explanation":"High lights allow you to see farther ahead, but they may reflect off precipitation and cause glare. In fog, rain, or snow, use low lights. If your vehicle is equipped with fog lights, you may use them in addition to low beams. However, if the fog gets so dense that you can't see well, pull off the road in a safe location and wait for conditions to improve. Remember, you can't drive if you can't see.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#34037","text":"Parking lights#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":31,"qid":1275,"description":"Which activity should you AVOID on a highway?","explanation":"Stopping in an acceleration lane to join into highway traffic is not permitted. (Inexperienced and nervous drivers are more prone to do this.) Continue driving until you discover a break in traffic where you can merge without stopping.","imageName":"","correctAnswer":35863,"isBookmarked":0,"aid":"35861#35862#35863#35864","text":"Drive in an acceleration lane to speed up to the flow of highway traffic#Yield to highway traffic and enter the right-hand lane when it is safe to do so#Stop in the acceleration lane and wait for a large gap in highway traffic#Obey posted advisory speed limits on the entrance ramp"},
{"testId":31,"qid":1277,"description":"With the _____ method, you can turn the steering wheel up to a half turn while keeping both hands on it.","explanation":"Hand-over-hand steering allows you to turn the steering wheel up to a half turn while maintaining both hands on the wheel. Hand-over-hand steering is ideal for precise moves, curve steering, junction entrance and departure, and skid recovery.","imageName":"","correctAnswer":707,"isBookmarked":0,"aid":"707#708#4162#35865","text":"hand-over-hand#one-hand#hand-to-hand#no-hand"},
{"testId":31,"qid":1279,"description":"You may not use a portable cell phone to _____ while driving in New Jersey.","explanation":"Except in an emergency, New Jersey law forbids the use of portable electronic devices (e.g., cellular phones) while driving a motor vehicle on any public road or highway. Using a portable device is a main violation, and a police officer may stop you and charge you for it. For a first offense, you will be fined between $200 and $400.","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"1783#1784#23817#35866","text":"read a text message#send a text message#do any of the above#make a phone call"},
{"testId":31,"qid":1280,"description":"What is the meaning of this sign?","explanation":"This sign warns drivers that livestock may be crossing the road ahead. Slow down and keep an eye out for calves crossing the road.","imageName":"202002101203434405.jpg","correctAnswer":172,"isBookmarked":0,"aid":"172#175#2394#35867","text":"Cattle crossing ahead#Zoo ahead#Deer crossing ahead#Farms ahead"},
{"testId":31,"qid":1281,"description":"If you have a suspended license and cause a collision that causes harm to another person, you may face which of the following penalties?","explanation":"Driving while a license or registration is suspended and injuring another person will result in a fine, prolonged suspension, and a minimum 45-day prison term.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35437#45619","text":"All of the above#A fine#A mandatory jail sentence#Additional license suspension"},
{"testId":31,"qid":1282,"description":"To exit a freeway or expressway, use _____ to slow down.","explanation":"To leave a motorway or expressway, enter a deceleration lane and begin slowing down. The exit ramp will be reached through the deceleration lane.","imageName":"","correctAnswer":2547,"isBookmarked":0,"aid":"2545#2547#5323#35868","text":"An acceleration lane#A deceleration lane#an entrance ramp#an escape ramp"},
{"testId":31,"qid":1283,"description":"You will soon reach a railroad crossing. If flashing lights, lowered gates, or other indications indicate an impending train, you must stop _______ away from the tracks.","explanation":"If a train is approaching a railroad crossing and there are flashing lights, lowered gates, or other warning signs, you must stop between 15 and 50 feet from the closest rail. You should avoid getting too near to trains since they are at least six feet wider than the tracks they travel on.","imageName":"","correctAnswer":4182,"isBookmarked":0,"aid":"3747#4182#4183#4184","text":"at least 10 feet#at least 15 feet#at least 5 feet#at least 1 foot"},
{"testId":31,"qid":1285,"description":"You must turn on your headlights:","explanation":"From one-half hour after dusk to one-half hour before daybreak, you must use your headlights. You must also use your headlights anytime sight is impaired at 500 feet and whenever you use your windshield wipers, according to New Jersey law.","imageName":"","correctAnswer":35871,"isBookmarked":0,"aid":"31572#35869#35870#35871","text":"from one hour after sunset to one hour before sunrise#from two hours after sunset to one hour before sunrise#from three hours after sunset to one hour before sunrise#from one-half hour after sunset to one-half hour before sunrise"},
{"testId":31,"qid":1287,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"If your car becomes stuck on a railroad track, do not waste time attempting to dislodge it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction you anticipate trains to arrive. If your vehicle is hit, you and your passengers will be protected from flying debris. Call the number listed on the railroad crossing sign, or 911. A train will not stop for you. It may take more than a mile for a train running at 60 mph to come to a full stop.","imageName":"202007210447482035.jpg","correctAnswer":35196,"isBookmarked":0,"aid":"34666#34929#34930#35196","text":"Try to push your vehicle off the tracks#Try to restart the engine#Wait for help to arrive#Get everyone out and clear of the tracks"},
{"testId":31,"qid":1288,"description":"This symbol warns of:","explanation":"The No Passing Zone sign indicates that you must not pass other cars beginning at the placement of the sign.","imageName":"202003151718184521.jpg","correctAnswer":22482,"isBookmarked":0,"aid":"8626#22482#22483#22484","text":"None of the above#the beginning of a no-passing zone#the end of a no-passing zone#a no-passing zone for trucks"},
{"testId":31,"qid":1289,"description":"You can avoid highway hypnosis on an expressway by:","explanation":"Highway hypnosis is a state of tiredness or unawareness caused by monotony, the noises of the wind and tires hitting the pavement, and the continuous hum of the motor. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right. Talking on a mobile phone or often changing lanes might be hazardous.","imageName":"","correctAnswer":35876,"isBookmarked":0,"aid":"30636#35874#35875#35876","text":"changing lanes frequently#frequently talking on your cell phone#taking stimulants#shifting your eyes frequently"},
{"testId":31,"qid":1290,"description":"What should you do if you have consumed too much alcohol to drive safely?","explanation":"Take a cab, utilize public transit, or arrange for a designated driver to drive you if you are unable to drive safely.","imageName":"","correctAnswer":35878,"isBookmarked":0,"aid":"35877#35878#35879#35880","text":"Take stimulants to counteract the alcohol#Ask a designated driver to drive you#Drive anyway but very slowly#Drink coffee to keep you awake while you drive"},
{"testId":30,"qid":1291,"description":"When approaching a school bus that has stopped while flashing its red lights, you must:","explanation":"When approaching a school bus from any direction that has stopped to pick up or drop off passengers while flashing its red lights, you must stop at least 25 feet away. You must stay halted until either the bus driver turns off the red lights or the vehicle begins motion.","imageName":"","correctAnswer":35368,"isBookmarked":0,"aid":"27#35367#35368#35369","text":"Change lanes#slow down and pass the school bus#stop at least 25 feet from the school bus#stop at least 5 feet from the school bus"},
{"testId":30,"qid":1292,"description":"You should signal at least ____ before turning on the highway.","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#401#1296","text":"100 feet#200 feet#400 feet#500 feet"},
{"testId":30,"qid":1293,"description":"What is the recommended minimum following distance behind the vehicle in front of you in New Hampshire?","explanation":"The State of New Hampshire Driver's Manual suggests keeping a four-second following distance from other vehicles. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If driving circumstances are less than perfect, increase your following distance by one second for each danger factor, such as restricted vision, slick roads, and so on.","imageName":"","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":30,"qid":1294,"description":"What is the meaning of this sign?","explanation":"This sign indicates that there will be a traffic light ahead. If the light is yellow or red, prepare to stop.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"1585#1701#35370#35371","text":"A traffic signal ahead#A railroad crossing ahead#A pedestrian signal ahead#A flashing signal ahead"},
{"testId":30,"qid":1297,"description":"To lessen the glare from an incoming vehicle's headlights at night, move your eyes to _________ and navigate by the white edge lines.","explanation":"Shift your gaze to the lower right border of the road and steer by the white edge markers to escape the glare from an incoming vehicle's headlights at night.","imageName":"","correctAnswer":4236,"isBookmarked":0,"aid":"1396#2481#4235#4236","text":"None of the above#the center of the road#the lower left edge of the road#the lower right edge of the road"},
{"testId":30,"qid":1298,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":35170,"isBookmarked":0,"aid":"35168#35169#35170#35171","text":"A winding road ahead; reduce your speed#A sharp curve ahead; use caution#The road is slippery when wet; slow down#A narrow bridge ahead; watch for oncoming traffic"},
{"testId":30,"qid":1299,"description":"When meeting or following a vehicle at night in New Hampshire, you must ________ at least 150 feet away.","explanation":"When encountering or following a vehicle at night in New Hampshire, you must dim your headlights at least 150 feet away. Do not wait for other cars to decrease their headlights before you do.","imageName":"","correctAnswer":1786,"isBookmarked":0,"aid":"1786#1984#4241#4610","text":"dim your headlights#turn off your headlights#brighten your headlights#turn on your emergency flashers"},
{"testId":30,"qid":1301,"description":"If you miss an expressway exit, you should:","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Backing up on a limited-access highway is unlawful in New Hampshire. Instead, take the next exit and find a method to return from there.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8521#9110#26306#35172","text":"continue to the next exit#stop and ask for help#back up to reach your exit#make a U-turn to reach your exit"},
{"testId":30,"qid":1302,"description":"If you want to pass a truck, you should:","explanation":"Do not congregate in a No-Zone! No-Zones are vast zones around trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. The bigger the vehicle, the more No-Zones it has. No-Zones significantly enhance the chance of a collision. It is difficult to consistently avoid the No-Zones of a huge vehicle. However, do not stay in its No-Zones for any longer than required.","imageName":"","correctAnswer":35372,"isBookmarked":0,"aid":"22604#35372#35373#35374","text":"use the one-second rule#not remain in its blind spots any longer than necessary#pass it on the right#slow down and allow more time for the truck driver to see you"},
{"testId":30,"qid":1303,"description":"It is prohibited for drivers 21 and older to have a blood alcohol content (BAC) of聽:","explanation":"Driving with a blood alcohol content (BAC) of 0.08 percent or above is banned in New Hampshire for anybody 21 or older. It is also prohibited for anybody under the age of 21 to drive with a BAC of 0.02 percent or higher.","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"8626#23192#23194#23318","text":"None of the above#0.05%#0.08%#0.02%"},
{"testId":30,"qid":1304,"description":"If you're travelling in the fog at night, use your:","explanation":"High lights allow you to see farther ahead, but they may reflect off precipitation and cause glare. In fog, rain, or snow, use low lights. However, if the fog gets so dense that you can't see well, pull off the road in a safe location and wait for conditions to improve. Remember, you can't drive if you can't see.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#34037#35375","text":"High-beam headlights#Low-beam headlights#Emergency flashers#either high-beam headlights or low-beam headlights"},
{"testId":30,"qid":1305,"description":"When you see an eight-sided sign, you must:","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. It is most often encountered around junctions. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe.","imageName":"202003151731031579.jpg","correctAnswer":8955,"isBookmarked":0,"aid":"8955#34339#35376#35377","text":"stop#merge#yield#turn"},
{"testId":30,"qid":1306,"description":"What is the meaning of this sign?","explanation":"This sign has the worldwide emblem of impaired access. The sign in New Hampshire indicates that you are not permitted to park here unless you have a walking handicap placard or license plate.","imageName":"202003151732554338.jpg","correctAnswer":31993,"isBookmarked":0,"aid":"3523#9573#31993#33336","text":"Wheelchair availability#A hospital#A place reserved for persons with disabilities#A daycare center"},
{"testId":30,"qid":1307,"description":"If someone is following you too closely, you must:","explanation":"Tap your brakes to flash your brake lights if you are being tailgated. Slow down and urge the tailgater to pass if he or she does not drop back. If it doesn't work, pull over and let the tailgater pass. Don't attempt to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202003201758501730.jpg","correctAnswer":35378,"isBookmarked":0,"aid":"6515#8657#35378#35379","text":"Increase your speed#move into the left lane#reduce your speed and let the tailgater pass#discourage the tailgater from passing"},
{"testId":30,"qid":1308,"description":"To safely pass the vehicle ahead of you at 55 mph on a two-lane road, you will need _______ .","explanation":null,"imageName":"","correctAnswer":4268,"isBookmarked":0,"aid":"4266#4267#4268#4269","text":"over 160 feet#over 600 feet#over 1,600 feet#over 1,000 feet"},
{"testId":30,"qid":1309,"description":"Except otherwise stated, the speed limit in any business or urban residential district in New Hampshire is .","explanation":"Except as otherwise indicated, the speed limit in any commercial or urban residential area in New Hampshire is 30 mph.","imageName":"","correctAnswer":521,"isBookmarked":0,"aid":"521#1676#1745#2429","text":"30 mph#35 mph#40 mph#10 mph"},
{"testId":30,"qid":1310,"description":"This symbol stands for:","explanation":"A slow-moving vehicle is identified by a triangular orange insignia on the back. Typically, this refers to a vehicle that cannot move faster than 25 mph. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. Don't get frustrated if you find yourself stuck behind one of these automobiles. The motorist may slow down to let you to pass.","imageName":"202003181417388899.jpg","correctAnswer":8731,"isBookmarked":0,"aid":"8731#19269#24561#35380","text":"a slow-moving vehicle#a Do Not Enter sign#a heavy vehicle#a tow truck"},
{"testId":35,"qid":1313,"description":"Large flashing arrow panels may be used in work zones to help drivers get into:","explanation":"Large LED flashing arrow panels may be deployed in work zones at any time of day or night to direct cars into certain traffic lanes. These panels are installed on the road or mounted on vehicles to warn approaching motorists of lane closures.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#8776#28712#38731","text":"Guide drivers into certain traffic lanes#Stop speeding drivers#Guide drivers into certain parking areas#Direct drivers into work zones"},
{"testId":35,"qid":1314,"description":"What is the meaning of this sign?","explanation":"Even under perfect driving circumstances, the legal maximum speed restriction here is 55 mph, according to this sign. It should be noted that this does NOT imply that driving at 55 mph is safe in this area; this is dependent on current driving circumstances. In bad or dangerous driving circumstances, you must reduce your speed to less than this limit. Speed limits are often posted on the right side of roadways and highways.","imageName":"202003301759421414.jpg","correctAnswer":35150,"isBookmarked":0,"aid":"35150#35151#35152#35153","text":"The maximum speed under ideal road conditions is 55 mph#The maximum speed under hazardous road conditions is 55 mph#The minimum speed under hazardous road conditions is 55 mph#The minimum speed under ideal road conditions is 55 mph"},
{"testId":35,"qid":1315,"description":"When a train approaches a railroad crossing regulated by a crossbuck sign, you must ________ away from the crossing.","explanation":"When approaching a railroad crossing regulated by a crossbuck sign, you must come to a halt between 15 and 50 feet from the closest rail. Trains are at least six feet wider than the tracks they travel on, so don't go too near.","imageName":"","correctAnswer":4182,"isBookmarked":0,"aid":"3747#4182#4183#4287","text":"at least 10 feet#at least 15 feet#at least 5 feet#at least 2 feet"},
{"testId":35,"qid":1316,"description":"If you intend to make a turn, you must begin signaling at least _______ before turning.","explanation":"To express your desire to turn, you must provide a continuous turn signal for the last 100 feet before the turn.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#4061","text":"100 feet#50 feet#25 feet#75 feet"},
{"testId":35,"qid":1317,"description":"Two vehicles come to an intersection at the same time from two different roadways. Which of the following is true?","explanation":"When two cars approach or enter a junction at about the same moment, the vehicle on the left must yield to the one on the right.","imageName":"","correctAnswer":38735,"isBookmarked":0,"aid":"38732#38733#38734#38735","text":"The vehicle on the right must yield to the vehicle on the left#The vehicle turning right must yield to the vehicle turning left#The vehicle turning left must yield to the vehicle turning right#The vehicle on the left must yield to the vehicle on the right"},
{"testId":35,"qid":1318,"description":"This symbol indicates that:","explanation":"This pennant-shaped warning sign is usually located on the left side of a road. You may not pass from this point forward unless you see a \"Pass with Care\" sign stating that you may.","imageName":"202003151718184521.jpg","correctAnswer":38737,"isBookmarked":0,"aid":"27149#27151#38736#38737","text":"vehicles are allowed to pass on the right#vehicles are allowed to pass on the left#vehicles are allowed to pass#vehicles are not allowed to pass"},
{"testId":35,"qid":1319,"description":"Which of the following things is NOT TRUE about the right-of-way?","explanation":"At an unmanaged T-intersection (i.e., one without signs or signals), the vehicle on the through route must yield to cars on the terminating road, is FALSE. The vehicle on the terminating road must surrender to cars on the through route at an uncontrolled T-intersection.","imageName":"","correctAnswer":38738,"isBookmarked":0,"aid":"22488#34110#34111#38738","text":"Vehicles turning left must yield to oncoming traffic#Vehicles entering or crossing a roadway from a private driveway must yield to all approaching vehicles#Vehicles must yield to pedestrians in unmarked crosswalks#At an uncontrolled T-intersection (i.e., one without signs or signals), the vehicle on the through road must yield to vehicles on the terminating road"},
{"testId":35,"qid":1320,"description":"If an emergency vehicle with flashing red, blue or white lights and a siren approaches, you must:","explanation":"If an emergency vehicle approaches you with its siren or flashing lights on, you must pull over to the right side of the road and remain stopped until the emergency vehicle has passed. If you are at an intersection as an emergency vehicle arrives, do not stop. Continue past the junction and then immediately turn right.","imageName":"","correctAnswer":35215,"isBookmarked":0,"aid":"22545#35215#38739#38740","text":"stop immediately wherever you are#pull over to the right edge of the road and stop#change lanes and continue at the same speed#pull over to the left edge of the road and stop"},
{"testId":35,"qid":1321,"description":"A flashing yellow light, commonly seen on tow trucks and low-speed vehicles, instructs other vehicles to:","explanation":"Some tow trucks and low-speed vehicles utilize a flashing yellow light. It is a warning to slow down.","imageName":"","correctAnswer":6476,"isBookmarked":0,"aid":"6476#8955#22676#22677","text":"Slow down#stop#pass quickly#share the lane"},
{"testId":35,"qid":1322,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the divided highway is coming to an end ahead. On the same road ahead, traffic flows in both directions. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"54#8873#38741#38742","text":"A one-way road is ahead#The divided highway ends ahead#A divided highway begins ahead#A traffic merging area is ahead"},
{"testId":35,"qid":1323,"description":"When a school bus has flashing red lights and is stopped, drivers coming from either direction must :","explanation":"Vehicles going in either direction must stop in North Dakota if a school bus has stopped with its red lights flashing. They must stay stopped until either 1) the bus begins to move; 2) the bus driver indicates for cars to pass; or 3) the red lights cease to blink.","imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"27#6368#8955#9181","text":"Change lanes#Turn right#stop#slow down and proceed with caution"},
{"testId":35,"qid":1324,"description":"Unless otherwise indicated, the speed limit on North Dakota's paved and divided multi-lane highways is:","explanation":"Unless otherwise marked, the speed limit on North Dakota's paved and split multi-lane roads is 70 mph.","imageName":"","correctAnswer":685,"isBookmarked":0,"aid":"682#684#685#3852","text":"55 mph#65 mph#70 mph#75 mph"},
{"testId":35,"qid":1326,"description":"What is the meaning of this sign?","explanation":"You have entered a school zone, as indicated by this sign. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there.","imageName":"202003151742324658.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1252#3769#38743#38744","text":"A school zone ahead#A hospital zone ahead#A recreation area ahead#A public library ahead"},
{"testId":35,"qid":1329,"description":"When visibility is less than ________ because to rain, snow, sleet, hail, smoke, or fog, you must use your headlights.","explanation":"When visibility is less than 1,000 feet due to rain, snow, sleet, hail, smoke, or fog, you must use your headlights, according to North Dakota law. A thousand feet is around two-tenths of a mile.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"4432#4433#4434#4435","text":"1,000 feet#2,000 feet#3,000 feet#4,000 feet"},
{"testId":35,"qid":1330,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"54#22424#22426#27866","text":"A one-way road is ahead#A winding road is ahead#The road ahead is slippery when wet#Only passenger vehicles are allowed"},
{"testId":35,"qid":1331,"description":"Which of the following you should use when you are following another vehicle within 300 feet at night?","explanation":"High lights allow you to see farther ahead, but they might also blind a close motorist. When you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following, North Dakota law requires you to lower your headlights.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#34037#38745","text":"High-beam headlights#Low-beam headlights#Emergency flashers#Either low-beam or high-beam headlights"},
{"testId":35,"qid":1332,"description":"When you park your vehicle parallel to the right curb, your wheels should be parallel to the right curb and within _____ from the right curb.","explanation":"When parallel parking, your vehicle's wheels should be parallel to and within one foot of the right-hand curb.","imageName":"","correctAnswer":23982,"isBookmarked":0,"aid":"23979#23980#23981#23982","text":"two feet#three feet#four feet#one foot"},
{"testId":35,"qid":1333,"description":"When you come up behind a vehicle that doesn't turn off its headlights, look to the ________ so you don't get blinded.","explanation":"To prevent getting dazzled by a car that does not lower its lights, glance to the right edge of the road. Remember that it takes time for your eyes to adapt after being hit by a car at night.","imageName":"","correctAnswer":4334,"isBookmarked":0,"aid":"4334#4335#4336#4337","text":"right edge of the road#left edge of the road#driver of the vehicle#center of the road"},
{"testId":35,"qid":1334,"description":"This symbol denotes:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required.","imageName":"202001282004545744.jpg","correctAnswer":9211,"isBookmarked":0,"aid":"9211#19384#23905#38746","text":"a railroad crossing#a one-way road#a roundabout#road repair work"},
{"testId":35,"qid":1336,"description":"You should _____ to slow down when driving down a steep downhill.","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, let out of the throttle and move into a lower gear (even with an automatic transmission). This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":24391,"isBookmarked":0,"aid":"24390#24391#35893#37857","text":"use a higher gear#use a lower gear#keep applying your brakes#shift into neutral"},
{"testId":35,"qid":1337,"description":"Which vehicle occupants in North Dakota are obliged to wear seat belts or appropriate child restraints?","explanation":"Seat belts are required for all front-seat passengers (including the driver) and all occupants under the age of 18 in North Dakota, unless the individual is physically or medically incapable of wearing one.","imageName":"","correctAnswer":38747,"isBookmarked":0,"aid":"35474#38747#38748#38749","text":"All occupants#Rear-seat occupants under 18 and all front-seat occupants#Front-seat occupants only#The driver and rear-seat occupants"},
{"testId":35,"qid":1338,"description":"What does this flagperson mean?","explanation":"This flagger is indicating to cars to slow down.","imageName":"202002270946508485.jpg","correctAnswer":15493,"isBookmarked":0,"aid":"15493#25445#26363#35392","text":"Slow down#Speed up#Stop#Turn around"},
{"testId":35,"qid":1339,"description":"If you don't want to slip and slide on a wet road, you should:","explanation":"If you exceed 35 mph on a wet road, your tires may lose traction and begin to ride over the water, a situation known as hydroplaning. Slow down to lessen your risks of hydroplaning.","imageName":"","correctAnswer":6476,"isBookmarked":0,"aid":"6475#6476#22593#24167","text":"Speed up#Slow down#keep right#Shift gears"},
{"testId":35,"qid":1340,"description":"What is the recommended following distance behind the vehicle in front of you in North Dakota?","explanation":"The \"three-second rule,\" a following distance of at least three seconds behind the vehicle in front of you, is recommended by the North Dakota Noncommercial Drivers License Manual. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. Increase your following distance if driving conditions are bad or traffic is heavy.","imageName":"","correctAnswer":10880,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":35,"qid":1341,"description":"If you're over 21 and have a BAC of ____ or more, you're legal considered as intoxicated.","explanation":"You are legally under the influence of alcohol if you are either (1) above the age of 21 and your alcohol concentration (AC) is 0.08 percent or higher, or (2) under the age of 21 and your AC is 0.02 percent or higher. If you are convicted of driving under the influence, you might face serious consequences.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":35,"qid":1342,"description":"What is the meaning of this sign?","explanation":"This is a milepost marker. (Mileposts are sometimes referred to as mile markers.) The characters on milepost signs are white on a green backdrop. The number on the sign indicates how many miles you are from the southern or western state boundary, or from the route's southern or western endpoint.","imageName":"202004142317126205.jpg","correctAnswer":29836,"isBookmarked":0,"aid":"4366#4368#4369#29836","text":"An interstate highway number#A posted speed limit#A route marker#A milepost"},
{"testId":35,"qid":1343,"description":"A _____ person is frequently seen walking with a white cane with a crimson tip.","explanation":"Blind or visually challenged pedestrians use white canes or guide dogs. Use utmost care while approaching such pedestrians.","imageName":"202007200420026434.jpg","correctAnswer":26245,"isBookmarked":0,"aid":"8887#26243#26245#38750","text":"a crew person#a schoolchild#blind#a flagperson"},
{"testId":35,"qid":1344,"description":"In an emergency, your vehicle will take approximately _______ seconds to stop at 55 mph on dry pavement.","explanation":"In an emergency, it will take your car around 300 feet or one city block to stop travelling at 55 mph on dry roads. The size of a football field is also 300 feet long (not including the two end zones). The normal motorist may take a few seconds to begin braking in response to a danger that they have observed. Your car will cover 161 feet in two seconds at 55 mph.","imageName":"","correctAnswer":400,"isBookmarked":0,"aid":"214#400#497#498","text":"100 feet#300 feet#two city blocks#three city blocks"},
{"testId":35,"qid":1345,"description":"A student driver who is at least 16 years old but less than 18 years old must hold an instruction permit for at least _______ before taking the driver's license road test.","explanation":"Before taking the road test for a driver's license, a student driver must have had an instruction permit for at least six months and be at least 16 years old. A student driver must possess an instruction permit for at least one year if he or she is at least 14 years old but less than 16 years old.","imageName":"","correctAnswer":4374,"isBookmarked":0,"aid":"230#4373#4374#4375","text":"one year#three months#six months#four months"},
{"testId":35,"qid":1346,"description":"What is the meaning of this sign?","explanation":"This symbol denotes a service. It suggests that phone service will be available shortly.","imageName":"202003151712528763.jpg","correctAnswer":34911,"isBookmarked":0,"aid":"19355#19389#34911#38751","text":"A hospital is ahead#A gas station is ahead#A telephone service is ahead#An area for using cell phones is ahead"},
{"testId":35,"qid":1347,"description":"When approaching a vehicle with a triangular orange reflective sign on the back, you must:","explanation":null,"imageName":"","correctAnswer":8560,"isBookmarked":0,"aid":"8559#8560#8561#28507","text":"pass the vehicle quickly#slow down and use caution#share the same lane with the vehicle#follow it closely"},
{"testId":35,"qid":1349,"description":"The only traffic signs with eight sides are:","explanation":"Stop signs are the only eight-sided traffic signs. At a stop sign, you must come to a complete stop before the stop line or (in the absence of a stop line) before the crosswalk.","imageName":"","correctAnswer":38755,"isBookmarked":0,"aid":"38752#38753#38754#38755","text":"yield signs#merge signs#Do Not Enter signs#stop signs"},
{"testId":35,"qid":1350,"description":"What is the meaning of this sign?","explanation":"This caution sign warns a pothole ahead. To prevent losing control, slow down.","imageName":"202002101228423346.jpg","correctAnswer":28710,"isBookmarked":0,"aid":"28708#28709#28710#28711","text":"A median is ahead#The road ahead has a dip#The road ahead has a bump#A hill grade is ahead"},
{"testId":39,"qid":1351,"description":null,"explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A constant yellow \"X\" above your lane indicates that the lane's direction of movement is soon to be reversed, at which time the signal will change to a steady red \"X.\" Before this occurs, you must change lanes.","imageName":"","correctAnswer":39691,"isBookmarked":0,"aid":"28148#33548#39691#39692","text":"Proceed with caution#You can only make a left turn from this lane#Change lanes as soon as it is safe to do so#You may only use this lane in an emergency"},
{"testId":39,"qid":1353,"description":"In Pennsylvania, if you park next to a curb, your car shouldn't be more than _____ from the curb.","explanation":"When parking near to a curb in Pennsylvania, your car should be no more than 12 inches away from the curb. Park in the direction that cars on that side of the road are traveling.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#18169#39693","text":"12 inches#18 inches#6 inches#24 inches"},
{"testId":39,"qid":1354,"description":"What is the meaning of this sign?","explanation":"The main road will bend to the left, with a side road arriving from the right, according to this sign. Take extreme care while approaching this crossroads. A car preparing to join the main road from a side road may not notice you coming around the bend and may pull out in front of you.","imageName":"202002061234299815.jpg","correctAnswer":39695,"isBookmarked":0,"aid":"29870#38146#39694#39695","text":"Left turns are prohibited#You must turn left#There is a series of curves ahead#There is a side road entering from the right"},
{"testId":39,"qid":1355,"description":"When approaching a pedestrian with a white cane or walking with a guiding dog, you must :","explanation":"Blind pedestrians often walk with white canes or with guide dogs. A blind pedestrian must always be given the right of way. Don't honk your horn since you could scare a pedestrian or a guide dog.","imageName":"","correctAnswer":30653,"isBookmarked":0,"aid":"26062#30653#39696#39697","text":"proceed at the same speed#yield the right-of-way to the pedestrian#accelerate and pass the pedestrian quickly#sound your horn to alert the pedestrian"},
{"testId":39,"qid":1356,"description":"A driver who is at least 21 years old and has a blood alcohol content (BAC) of ______ or more may be charged with driving while intoxicated (DUI).","explanation":"A motorist who has a blood alcohol percentage of 0.08 percent or above in Pennsylvania may be prosecuted with driving under the influence (DUI). If a motorist under the age of 21 has a blood alcohol content of 0.02 percent or greater, he or she might be prosecuted with DUI. With only one alcoholic drink, most persons will attain a level of 0.02 percent or greater.","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23194#23318#27698#31711","text":"0.08%#0.02%#0.04%#Zero"},
{"testId":39,"qid":1357,"description":"A single broken yellow centerline on a two-way, two-lane road indicates that:","explanation":"A single broken yellow centerline on a two-way, two-lane road indicates that overtaking is permitted on either side provided safe circumstances exist.","imageName":"","correctAnswer":39698,"isBookmarked":0,"aid":"34430#39698#39699#39700","text":"Passing is not allowed on either side#passing is allowed on either side#passing is allowed on only one side#passing is allowed on either side but only in emergencies"},
{"testId":39,"qid":1358,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a small bridge or underpass ahead. Slow down, remain in your lane, and drive cautiously.","imageName":"202002101245562248.jpg","correctAnswer":4415,"isBookmarked":0,"aid":"1701#3176#4415#4416","text":"A railroad crossing ahead#A one-way road ahead#A narrow bridge or underpass ahead#A hill ahead"},
{"testId":39,"qid":1359,"description":"Which occupants of a vehicle are required by Pennsylvania law to use seat belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":39701,"isBookmarked":0,"aid":"4420#35472#35474#39701","text":"Only the driver#All front-seat occupants#All occupants#All front-seat occupants and all passengers under the age of 18"},
{"testId":39,"qid":1360,"description":"Where there is both a solid and a broken yellow line separating opposing traffic lanes,:","explanation":"You may not pass when there is both a solid and a broken yellow line between opposing lanes of traffic if the solid yellow line is on your side.","imageName":"","correctAnswer":39703,"isBookmarked":0,"aid":"39702#39703#39704#39705","text":"you may pass if the solid line is on your side#you may pass if the broken line is on your side#you may not pass if the broken line is on your side#you may pass on either side"},
{"testId":39,"qid":1361,"description":"It is advised that you keep a following distance of at least ___ second(s) behind the vehicle ahead of you.","explanation":"The \"four-second rule\" is suggested by the Pennsylvania Driver's Manual so that you may safely avoid risks in front of you: Keep a four-second following distance from the car in front of you. By noting when the car in front of you passes a stationary item and then calculating the seconds until you get to the same thing, you may calculate your following distance. Increase your following distance if the road is risky or in poor condition.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":39,"qid":1362,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Check your brakes and drive carefully. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":39706,"isBookmarked":0,"aid":"71#5666#39706#39707","text":"A slippery road ahead#Low clearance ahead#A steep descent ahead#Emergency truck escape ramp ahead"},
{"testId":39,"qid":1363,"description":"The areas of the road that you cannot see in your mirrors are referred to as:","explanation":"Blind spots are portions of the road that are hidden from view by your side mirrors. You might need to turn your head and look over your shoulder to see these areas.","imageName":"","correctAnswer":9136,"isBookmarked":0,"aid":"9136#23949#39708#39709","text":"blind spots#invisible spots#zero spots#blank spots"},
{"testId":39,"qid":1365,"description":"You must use your headlights if you cannot see pedestrians or vehicles at least ________ ahead, according to Pennsylvania law.","explanation":"You must use your headlights if you cannot see people or cars at least 1,000 feet ahead, according to Pennsylvania law. You must also use your headlights between the hours of sunset and dawn, as well as whenever you use your windshield wipers in severe weather.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"400#1296#2781#4432","text":"300 feet#500 feet#600 feet#1,000 feet"},
{"testId":39,"qid":1367,"description":"If an approaching car is using its high lights, you should look _________ until the vehicle has past.","explanation":"If an incoming car is using high-beam headlights, flash your headlights several times fast to alert the driver. Don't gaze at the lights if the driver still doesn't dim them. To avoid being briefly blinded, keep your gaze on the right side of the road. Steer along the road's right edge until the car passes.","imageName":"202006041857066362.jpg","correctAnswer":35842,"isBookmarked":0,"aid":"34104#35840#35842#39710","text":"in your side mirrors#toward the left edge of the road#toward the right edge of the road#at the oncoming vehicle"},
{"testId":39,"qid":1368,"description":"If you must go slower than 40 mph on a Pennsylvania limited-access highway, use ________ to alert the drivers behind you.","explanation":"Before making a turn, changing lanes, or slowing down, alert other cars. If you must travel slower than 40 mph on a Pennsylvania limited-access highway, use your danger (four-way) flashers to notify traffic behind you. (Note: In some other states, using danger flashers while driving is unlawful. Instead of slowing down, tap your brake pedal a few times to flash your brake lights. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":39711,"isBookmarked":0,"aid":"1396#4444#4445#39711","text":"None of the above#your low-beam headlights#your high-beam headlights#your hazard flashers"},
{"testId":39,"qid":1369,"description":"Which of the following is NOT TRUE about who has the right-of-way at an intersection?","explanation":"Cars already at a junction have the right of way over incoming vehicles. A traffic circle, often known as a roundabout, is a circular junction. Cars entering a roundabout must give way to vehicles already in the roundabout.","imageName":"","correctAnswer":39715,"isBookmarked":0,"aid":"39712#39713#39714#39715","text":"Drivers turning left must yield to oncoming vehicles going straight ahead#A vehicle entering a public road from a private road must stop and wait until the public road is clear#At a four-way stop, all approaching vehicles must stop#Vehicles already in a roundabout must yield the right-of-way to vehicles entering the roundabout"},
{"testId":39,"qid":1370,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to halt. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2515#3686#5632","text":"A stop sign ahead#A yield sign ahead#A closed road ahead#Traffic merging ahead"},
{"testId":33,"qid":1371,"description":"You are not permitted to a vehicle on the left if:","explanation":"If your lane has a solid yellow center line, you may not pass a vehicle on the left. Even though your lane has a broken yellow center line, you may not pass on the left if you cannot return to the right lane before it has a solid yellow line.","imageName":"","correctAnswer":9201,"isBookmarked":0,"aid":"9200#9201#9203#37920","text":"You are far from a curve#your lane has a solid yellow center line#your lane has a broken yellow center line#your lane has a broken white center line"},
{"testId":33,"qid":1372,"description":"At an intersection, two vehicles coming from different directions may arrive at the same moment. Then:","explanation":"If two cars travelling from different directions arrive at the same time at a junction, the vehicle turning left must yield to the vehicle driving straight or turning right.","imageName":"","correctAnswer":34064,"isBookmarked":0,"aid":"8677#33287#34064#34066","text":"the vehicle with more passengers must go first#the vehicle on the right must yield to the vehicle on the left#the vehicle turning left must yield to the vehicle going straight or turning right#the vehicle turning right must yield to the vehicle turning left"},
{"testId":33,"qid":1373,"description":"You must ________ for an emergency vehicle approaching you from either direction that has flashing lights, a siren, or an air horn.","explanation":"When reacting to an emergency, you must give way to fire, ambulance, police, and other authorized emergency vehicles. Even if an emergency vehicle is coming from the other way, you must pull over to the right and halt. However, if you are at an intersection and an emergency vehicle approaches, DO NOT STOP. Continue past the junction and then pull over quickly.","imageName":"","correctAnswer":5250,"isBookmarked":0,"aid":"3909#5250#37921#37922","text":"increase your speed and clear the lane#pull over to the right and stop#slow down and move into the left lane#change lanes and proceed at the same speed"},
{"testId":33,"qid":1374,"description":"What is the meaning of this sign?","explanation":"This sign denotes a roundabout, which is a circular crossroads with traffic flowing counterclockwise around a central island. Roundabouts have less traffic delays and accidents than traditional four-way junctions.","imageName":"202002101237092503.jpg","correctAnswer":14737,"isBookmarked":0,"aid":"4464#4465#14737#34717","text":"A divider#An interstate highway#A roundabout#A crossroad"},
{"testId":33,"qid":1375,"description":"According to New York State law, you must signal at least _______ before turning.","explanation":"According to New York State law, you must indicate a turn or lane change at least 100 feet ahead. You may utilize hand gestures or your vehicle's turn signals. A smart safety suggestion is to begin signaling before beginning to brake for the turn.","imageName":"","correctAnswer":4469,"isBookmarked":0,"aid":"4466#4467#4468#4469","text":"25 feet ahead#50 feet ahead#75 feet ahead#100 feet ahead"},
{"testId":33,"qid":1376,"description":"You can't make a U-turn where other drivers can't see your vehicle from _______ both directions.","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"565#1296#2781#4432","text":"800 feet#500 feet#600 feet#1,000 feet"},
{"testId":33,"qid":1377,"description":"Vehicles approaching from either direction must stop at least ________ from a stopped school bus with its red lights flashing in New York State.","explanation":"Vehicles coming from either direction must stop at least 20 feet away from a stopped school bus with its red lights flashing, even if it is in front of the school or in a school parking lot. This legislation applies to all New York State roads. Unlike many other states, New York requires you to stop for a school bus even if it is on the other side of a split roadway.","imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":33,"qid":1378,"description":"Flashing red lights, lowered crossing gates, or ringing bells at a railroad crossing indicate that:","explanation":"A train is approaching or has already passed a railroad crossing if there are flashing red lights, lowered crossing gates, or ringing bells. Stopping distance from the closest rail of the tracks must be at least 15 feet. (You don't want to get too near; trains are at least six feet wider than the rails they operate on.) Stay still until the crossing gates are completely up and the lights or bells have ceased.","imageName":"202003181410217334.jpg","correctAnswer":37924,"isBookmarked":0,"aid":"32814#37923#37924#37925","text":"you must change lanes#a train has just passed#you must stop at least 15 feet from the railroad tracks#you must slow down and proceed with caution"},
{"testId":33,"qid":1379,"description":"When approaching a roundabout, rotary, or traffic circle, you must cede the right-of-way to :","explanation":"Right-of-way is given to pedestrians and cars already at a junction. A rotary or roundabout, on the other hand, is a circular junction. You must cede the right-of-way to pedestrians and cars already in the circle while approaching a roundabout, rotary, or traffic circle.","imageName":"","correctAnswer":37926,"isBookmarked":0,"aid":"8750#29776#29777#37926","text":"pedestrians#vehicles already in the circle#neither pedestrians nor vehicles already in the circle#both pedestrians and vehicles already in the circle"},
{"testId":33,"qid":1380,"description":"If there are two solid lines next to your lane, you are :","explanation":"You are not permitted to pass or change lanes when there are two solid lines adjacent to your lane.","imageName":"","correctAnswer":8566,"isBookmarked":0,"aid":"8563#8564#8565#8566","text":"allowed to pass#allowed to turn#allowed to change lanes#not allowed to pass or change lanes"},
{"testId":33,"qid":1381,"description":"If another driver passes you on the left, _________ until the vehicle has safely passed you.","explanation":"Slow down slightly and keep centered in your lane if another car is passing you on the left. Only then should you return to your normal pace.","imageName":"","correctAnswer":42944,"isBookmarked":0,"aid":"4460#4488#4489#42944","text":"pull over and stop#slow down slightly and keep to the left#increase your speed and keep to the right#slow down slightly and stay centered in your lane"},
{"testId":33,"qid":1382,"description":"The signal from this flag person means that you must:","explanation":"Discover the numerous signals that a flag person may use. This flag person's signal tells traffic to slow down.","imageName":"202002211455535430.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6476#8954#8955#37927","text":"Slow down#take a detour#stop#drive at the posted speed"},
{"testId":33,"qid":1383,"description":"You can do _____ to turn around on a narrow two-way street.","explanation":"If the street is too small for a U-turn, you may turn around by performing a three-point turn. This move will also send you in the other direction. A three-point turn is only permissible when the roadway is small, there is excellent sight, traffic is light on both sides of the street, the turn is permitted, and there are no other options.","imageName":"","correctAnswer":8879,"isBookmarked":0,"aid":"8878#8879#8880#8999","text":"a two-point turn#a three-point turn#a four-point turn#a single-point turn"},
{"testId":33,"qid":1385,"description":"Except where otherwise indicated, the speed limit in New York City is .","explanation":"There are several cities with relatively low posted speed restrictions. For instance, the posted limit in New York City is 25 mph unless otherwise indicated.","imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"522#682#1745#2135","text":"25 mph#55 mph#40 mph#50 mph"},
{"testId":33,"qid":1386,"description":"This service marker indicates the location of:","explanation":"White lettering or symbols are shown on a blue backdrop on service signs. A gas station is indicated by this service sign.","imageName":"202003301730484180.jpg","correctAnswer":19406,"isBookmarked":0,"aid":"19406#19529#19530#19531","text":"a gas station#a campground#a rest area#a telephone service"},
{"testId":33,"qid":1387,"description":"To have sufficient safety cushion with the vehicle ahead of you, use:","explanation":"Rear-end accidents account for four out of every 10 crashes. And tailgating (following too closely) is the leading cause of rear-end crashes (following too closely). The two-second rule is recommended by the New York State Department of Motor Vehicles Driver Manual for an acceptable distance cushion between your car and the vehicle ahead of you: At least two seconds should occur between when the car in front of you passes a stationary item and when you arrive at the same thing. Increase your space cushion to three or even four seconds in bad or dangerous driving circumstances.","imageName":"","correctAnswer":23863,"isBookmarked":0,"aid":"710#23863#23864#37928","text":"four-second rule#two-second rule#three-second rule#one-second rule"},
{"testId":33,"qid":1388,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If you are faced by an aggressive motorist, do not engage in a challenge. Make no eye contact. Avoid yelling and making unpleasant gestures. Such acts have sometimes resulted in real violence. Stay in your car for your own safety. Continue driving, but slow down and switch lanes to allow the aggressive motorist to pass you.","imageName":"","correctAnswer":26892,"isBookmarked":0,"aid":"26892#33023#37929#37930","text":"Avoid eye contact#Get out of your vehicle#Engage in name calling#Make rude gestures"},
{"testId":33,"qid":1389,"description":"If someone is following you too closely, you must:","explanation":"If you're being tailgated, try to move into another lane or pull over to allow the tailgater pass. Don't slam on the brakes to alert the tailgater. This might exacerbate an already perilous situation. And don't try to outrun or outrun the tailgater by increasing your pace. Some tailgaters believe that no speed is too fast.","imageName":"202003201758501730.jpg","correctAnswer":37931,"isBookmarked":0,"aid":"6515#8657#37931#37932","text":"Increase your speed#move into the left lane#slow down and pull off the road if necessary#apply the brakes and discourage the driver"},
{"testId":33,"qid":1390,"description":"This symbol is:","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe.","imageName":"202001301744345845.jpg","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#22617#35393","text":"a stop sign#a yield sign#a merge sign#a One Way sign"},
{"testId":33,"qid":1391,"description":"Which vehicle occupants in New York State are required to wear seat belts or appropriate kid restraints?","explanation":"Seat belts or appropriate kid restraints are required by New York State law for all passengers under the age of 16. In addition, all front-seat passengers (including the driver) are required to wear seat belts. Even grownups in the back seat should consider using seat belts. According to a 2015 research done by the Insurance Institute for Highway Safety and the Children's Hospital of Philadelphia, unrestrained rear-seat occupants were roughly eight times more likely than restrained rear-seat passengers to experience a severe injury in an accident.","imageName":"","correctAnswer":37933,"isBookmarked":0,"aid":"35472#37933#37934#37935","text":"All front-seat occupants#All rear-seat occupants under 16 and all front-seat occupants#All front-seat occupants under 16 and all rear-seat occupants#All rear-seat occupants"},
{"testId":33,"qid":1392,"description":"A steady yellow traffic signal at an intersection indicates that the driver must:","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. Prepare to come to a complete halt at a red light. (Never attempt to \"race the light\" by speeding up before it turns red.) However, if a car is directly behind you, do not come to a complete stop and risk being rear-ended. If you cannot safely stop, drive through the junction with care.","imageName":"","correctAnswer":37936,"isBookmarked":0,"aid":"9181#22544#30667#37936","text":"slow down and proceed with caution#continue at the same speed#speed up to get through the signal before it turns red#prepare to stop for a red light"},
{"testId":33,"qid":1393,"description":"It is prohibited in New York State to _____ while driving.","explanation":"It is against the law in New York State to talk on a portable cell phone while driving. The penalty include five points on your license and a fine of up to $200.","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"23817#41358#41359#41360","text":"do any of the above#talk on a handheld mobile phone#listen on a handheld mobile phone#send text messages on a handheld mobile phone"},
{"testId":33,"qid":1394,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Continue straight.","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"22419#22656#35482#37940","text":"A divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#One-way traffic starts ahead"},
{"testId":33,"qid":1395,"description":"Which of the following things about work zones is NOT TRUE?","explanation":"Work zones may be dangerous to both drivers and workers. Unlike in other jurisdictions, penalties for speeding in work zones in New York are doubled whether or not personnel or work vehicles are present. It should be noted that certain work zones might shift, such as when workers are painting road markings or mending potholes. Follow all work zone signs until you encounter one that says the work zone has finished.","imageName":"","correctAnswer":37943,"isBookmarked":0,"aid":"37941#37942#37943#37944","text":"Work zone warning signs are often posted ahead of the work zone#You must obey a flag person's directions#The fine for speeding in a work zone is doubled only when workers are there#Some work zones are mobile"},
{"testId":33,"qid":1396,"description":"If your blood alcohol level (BAC) is above ______ in New York State, you are deemed legally impaired.","explanation":"According to New York State law, you are legally impaired if your BAC is more than 0.05 percent and legally inebriated if your BAC is greater than 0.08 percent. Driving while legally impaired, even if not legally inebriated, is illegal in New York State, unlike in several other states. If you are caught driving with a BAC of 0.18 percent or above, you may be charged with aggravated driving while intoxicated (aggravated DWI), which carries harsher penalties if you are convicted.","imageName":"","correctAnswer":23192,"isBookmarked":0,"aid":"23192#23194#23319#37945","text":"0.05%#0.08%#0.07%#1.00%"},
{"testId":33,"qid":1398,"description":"This symbol denotes:","explanation":"This sign denotes that there is a school zone ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead. You may need to stop for them as well, so be prepared.","imageName":"202003151740461521.jpg","correctAnswer":6933,"isBookmarked":0,"aid":"6933#8838#9150#30660","text":"A school zone#a playground#a hospital#a bus stop"},
{"testId":33,"qid":1400,"description":"What should you do if your rear wheels begin to skid?","explanation":"If your back wheels begin to slide, turn the steering wheel in the direction you want to drive. Steer left if your rear wheels are slipping to the left. Steer right if they're slipping right. The rear wheels may begin to skid in the opposite direction if they overshoot. If this is the case, turn the steering wheel in that direction as well. This approach, known as \"steering into the skid,\" should return your car to your control.","imageName":"","correctAnswer":37948,"isBookmarked":0,"aid":"37946#37947#37948#37949","text":"Turn the steering wheel toward the left#Turn the steering wheel in the opposite direction of the skid#Turn the steering wheel in the direction of the skid#Turn the steering wheel toward the right"},
{"testId":33,"qid":1402,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these places, they utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":3277,"isBookmarked":0,"aid":"3277#3800#33349#33350","text":"A flagger ahead#A roundabout ahead#A traffic officer ahead#A work crew ahead"},
{"testId":33,"qid":1403,"description":"When you are within _______ of an incoming car, turn your headlights to low beam.","explanation":"High lights let you to see further than low beams, but they might blind a close motorist. Low beams are required in New York State anytime you are within 500 feet of an incoming vehicle or 200 feet of a vehicle ahead of you.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":33,"qid":1404,"description":"In severe rain, your vehicle's tires might lose touch with the road and ride on a layer of water. This threat is also known as:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" Hydroplaning may cause entire loss of braking and steering control at speeds of 50 mph or higher. Slow down to avoid hydroplaning.","imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#9002#9003#9004","text":"Hydroplaning#rainplaning#waterplaning#frictionplaning"},
{"testId":33,"qid":1405,"description":"You should use ___________ to improve visibility durng rain, fog and snow.","explanation":"High lights enable you to see farther ahead. They may, however, reflect off fog, rain, or falling snow, generating glare and making it even more difficult to see. In fog, rain, or snow, use low lights.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#35389#37950","text":"High-beam headlights#Low-beam headlights#interior lights#emergency lights"},
{"testId":42,"qid":1405,"description":"You should use ___________ to improve visibility durng rain, fog and snow.","explanation":"High lights enable you to see farther ahead. They may, however, reflect off fog, rain, or falling snow, generating glare and making it even more difficult to see. In fog, rain, or snow, use low lights.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#35389#37950","text":"High-beam headlights#Low-beam headlights#interior lights#emergency lights"},
{"testId":33,"qid":1406,"description":"This symbol warns of:","explanation":"This sign forewarns of a twisty road ahead. (A winding road is defined as having at least three bends.) You should slow down since this route will bend to the right and left.","imageName":"202002061307305376.jpg","correctAnswer":19534,"isBookmarked":0,"aid":"19532#19533#19534#19535","text":"a narrow road ahead#a sharp curve ahead#a winding road ahead#a median ahead"},
{"testId":33,"qid":1407,"description":"Two-thirds of all deer-vehicle incidents occur in the months of:","explanation":"The months of October, November, and December account for two-thirds of all deer-vehicle accidents. That is the breeding season (for the deer). When you notice a deer crossing sign, proceed with care.","imageName":"","correctAnswer":37954,"isBookmarked":0,"aid":"37951#37952#37953#37954","text":"June, July, and August#January, February, and March#March, April, and May#October, November, and December"},
{"testId":33,"qid":1408,"description":"What should you do if a tire blows out while you're driving?","explanation":"If a tire blows out unexpectedly, keep your hands firmly on the steering wheel and take your foot off the gas pedal. Only once you have regained control of your car, gently brake to a halt.","imageName":"","correctAnswer":19085,"isBookmarked":0,"aid":"19085#21273#26499#37955","text":"Hold the steering wheel firmly#Brake hard#Shift into a higher gear#Put your foot on the gas pedal"},
{"testId":33,"qid":1409,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"If your car becomes stuck on a railroad track while a train approaches, don't waste time attempting to remove it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction from where the train is coming. If your vehicle is hit, you and your passengers will be protected from flying debris. Call 911 or the number listed on the train crossing sign.","imageName":"202007210447482035.jpg","correctAnswer":37957,"isBookmarked":0,"aid":"34666#34929#37956#37957","text":"Try to push your vehicle off the tracks#Try to restart the engine#Open your doors and try to warn the train#Get everyone out of the vehicle and far away"},
{"testId":33,"qid":1410,"description":"What is the meaning of this sign?","explanation":"This is a traffic control sign. A red slash inside a red circle denotes \"no.\" This sign indicates that a right turn is not permitted here.","imageName":"202003151734593912.jpg","correctAnswer":34392,"isBookmarked":0,"aid":"34389#34390#34391#34392","text":"Drivers must not yield right#Drivers must not pass on the right#Drivers must not make a left turn#Drivers must not make a right turn"},
{"testId":34,"qid":1411,"description":"When you're _____, you can legally drive in the far left lane of a highway with more than two lanes.","explanation":"Normally, you should continue to the right. Driving in the far left lane is permissible only in certain situations, such as on one-way streets and while passing. You may need to shift lanes to avoid a danger or barrier.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"8979#8980#8981#23040","text":"passing another vehicle#turning left#avoiding an obstruction#Doing any of the above"},
{"testId":34,"qid":1412,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"Even when you begin to brake, your car will continue to move for a short distance before coming to a complete stop. This is known as the braking distance. It is generally proportional to your speed squared. For example, increasing your speed from 30 mph to 60 mph will result in a fourfold increase in braking distance, from 46 feet to 180 feet. Leave a larger trailing space behind the car in front of you at higher speeds.","imageName":"","correctAnswer":13,"isBookmarked":0,"aid":"13#15#11672#38497","text":"four times#three times#twice#just"},
{"testId":34,"qid":1413,"description":"Except when otherwise marked, the maximum speed limit in cities and municipalities in North Carolina is .","explanation":"Unless otherwise marked, the speed limit in cities and towns is 35 mph. In certain places, special speed limitations may be imposed.","imageName":"","correctAnswer":1676,"isBookmarked":0,"aid":"522#1676#1677#2135","text":"25 mph#35 mph#45 mph#50 mph"},
{"testId":34,"qid":1414,"description":"What is the meaning of this sign?","explanation":"A two-headed arrow with one head pointing straight ahead and the other pointing left indicates that you have the option of going straight or turning left.","imageName":"202003151725207480.jpg","correctAnswer":38498,"isBookmarked":0,"aid":"28122#38498#38499#38500","text":"There is a sharp turn ahead#You may either go straight or turn left#You may either go straight or turn right#You must make a left turn if you are keeping left"},
{"testId":34,"qid":1415,"description":"When driving on a one-way roadway with three or more lanes of traffic, you must use:","explanation":"Use the center lanes for through driving and the outer lanes for turning on a one-way roadway with three or more lanes of traffic.","imageName":"","correctAnswer":8984,"isBookmarked":0,"aid":"8982#8983#8984#8985","text":"the outside lanes for through travel and the center lanes for turning#the center lanes for through travel and turning#the outside lanes for turning and the center lanes for through travel#the outside lanes for through travel and turning"},
{"testId":34,"qid":1416,"description":"You're traveling on a road with a speed limit of 35 miles per hour. If you intend to make a turn, you must begin signaling at least _______ before turning.","explanation":null,"imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1028#4061#4582","text":"100 feet#10 feet#75 feet#20 feet"},
{"testId":34,"qid":1417,"description":"Who has the right-of-way when two or more vehicles reach an uncontrolled intersection (one without signs or signals) at about the same time?","explanation":"When two or more cars arrive at an uncontrolled junction (one that lacks signs or signals) at the same time, the vehicle on the right has the right-of-way.","imageName":"","correctAnswer":38502,"isBookmarked":0,"aid":"38501#38502#38503#38504","text":"The vehicle to the left#The vehicle to the right#The vehicle with the most passengers#The largest vehicle"},
{"testId":34,"qid":1418,"description":"This symbol is found on the road's left side. What does it imply?","explanation":null,"imageName":"202003151718184521.jpg","correctAnswer":38505,"isBookmarked":0,"aid":"37435#37437#37438#38505","text":"Vehicles are allowed to pass on the right#Vehicles are allowed to pass on the left#Heavy vehicles are not allowed to pass#Vehicles are not allowed to pass"},
{"testId":34,"qid":1419,"description":"To enter an interstate highway, accelerate and merge with the fast-moving traffic already on the interstate using:","explanation":"To properly merge onto an interstate highway, utilize an acceleration lane until your vehicle's speed equals that of highway traffic, then merge.","imageName":"","correctAnswer":2545,"isBookmarked":0,"aid":"2545#2547#3554#4002","text":"An acceleration lane#A deceleration lane#The shoulder#an exit ramp"},
{"testId":34,"qid":1420,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must move over to the right side of the road, regardless of which way the emergency vehicle is heading. (There is one exemption in North Carolina: you do not have to pull over if you are on the opposite side of a divided highway with at least four lanes.) If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over.","imageName":"","correctAnswer":30566,"isBookmarked":0,"aid":"30566#31282#38506#38507","text":"pull over to the right and stop#stop immediately where you are#pull over to the left and stop#increase your speed and clear the lane quickly"},
{"testId":34,"qid":1422,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the divided highway is coming to an end ahead. On the same road ahead, traffic flows in both directions. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#22656#26319#37374","text":"The divided highway ends ahead#A divided highway starts ahead#The right lane is closed ahead#You are entering a one-way roadway"},
{"testId":34,"qid":1423,"description":"When a school bus with flashing red lights is stopped in the middle of a two-lane road, vehicles coming from either direction must :","explanation":"Vehicles going in either direction must stop in North Carolina if a school bus has stopped with its red lights flashing on a two-lane highway. They must stay halted until the mechanical stop signal is removed, the flashing red lights are turned off, and the school bus resumes normal operation.","imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"27#6365#8955#9181","text":"Change lanes#Turn left#stop#slow down and proceed with caution"},
{"testId":34,"qid":1424,"description":"If you can't see at least _______ ahead of you, you can't pass on a curve or the crest of a hill.","explanation":"If you can't see 500 feet ahead, you can't pass on a curve or the summit of a hill.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"1296#38508#38509#38510","text":"500 feet#750 feet#850 feet#650 feet"},
{"testId":34,"qid":1425,"description":"Check for approaching traffic and _____________, before driving out of a parallel parking place on the right side of the road.","explanation":"Check for incoming traffic and give a left-turn signal before driving out of a parallel parking spot on the right side of the road. You would give a right-turn signal instead if the parking place was on the left side of a one-way street.","imageName":"","correctAnswer":38511,"isBookmarked":0,"aid":"9989#22535#38511#38512","text":"Turn on your emergency flashers#not give any turn signal#give a left-turn signal#give a right-turn signal"},
{"testId":34,"qid":1426,"description":"What is the meaning of this sign?","explanation":"You have entered a school zone, as indicated by this sign. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there.","imageName":"202003151742324658.jpg","correctAnswer":638,"isBookmarked":0,"aid":"638#857#5650#34876","text":"A school zone#A hospital zone#A pedestrian crosswalk#A public library"},
{"testId":34,"qid":1427,"description":"A red arrow indicator at a crosswalk means that:","explanation":"A red arrow indicates that turning traffic must come to a complete stop. Before going in that direction, they must wait for the next green arrow or green light.","imageName":"","correctAnswer":32165,"isBookmarked":0,"aid":"32163#32164#32165#32166","text":"turning traffic must increase speed and turn quickly#turning traffic must proceed at the same speed#turning traffic must stop#turning traffic must slow down"},
{"testId":34,"qid":1428,"description":"This symbol is:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required.","imageName":"202001282004545744.jpg","correctAnswer":31318,"isBookmarked":0,"aid":"30334#31318#31319#38513","text":"A work zone sign#a railroad crossing sign#a road repair sign#a Wrong Way sign"},
{"testId":34,"qid":1429,"description":"Headlights are required in North Carolina from dusk to sunrise and whenever visibility is ________.","explanation":"North Carolina law requires you to use your headlights from dusk to dawn and anytime visibility is less than 400 feet. (Note: Different states have different laws about when you must use your headlights.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":38516,"isBookmarked":0,"aid":"47#38514#38515#38516","text":"1,000 feet or less#700 feet or less#500 feet or less#400 feet or less"},
{"testId":34,"qid":1430,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22424#22426#37371#38517","text":"A winding road is ahead#The road ahead is slippery when wet#The road ahead is covered in gravel#A hill is ahead"},
{"testId":36,"qid":1430,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22424#22426#37371#38517","text":"A winding road is ahead#The road ahead is slippery when wet#The road ahead is covered in gravel#A hill is ahead"},
{"testId":34,"qid":1431,"description":"When you are within _______ of an oncoming vehicle, you must dim your headlights.","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"399#401#1296#4432","text":"200 feet#400 feet#500 feet#1,000 feet"},
{"testId":34,"qid":1432,"description":"In which of the following circumstances is it NOT permissible to overtake another vehicle on the right?","explanation":"The passing lane must be kept free at all times. You should only pass a car on the right in a few scenarios. When a car is in a left-turn lane, you may pass it on the right. When there are two or more lanes travelling in the same direction as the vehicle, you may also pass it on the right. If you are in a left-turn lane, you may not pass another vehicle on the right. A left-turn lane is just for turning left (and U-turns where permitted).","imageName":"","correctAnswer":38519,"isBookmarked":0,"aid":"38518#38519#38520#38521","text":"On a highway with at least two lanes traveling in each direction#When you're in a left-turn lane#When the other vehicle is in a left-turn lane#On a one-way street where all lanes of traffic are moving in your direction"},
{"testId":34,"qid":1433,"description":"What are the areas near your vehicle's left and right rear corners that you cannot see in your mirrors?","explanation":"Your mirrors do not show you all that is going on around you. There are \"blind spots,\" which are places at your vehicle's left and right rear corners that you cannot see in your mirrors. To observe these places before turning or changing lanes, you must glance over your shoulder. No-zones are significant blind areas in the vicinity of a big commercial vehicle, such as a bus or truck.","imageName":"","correctAnswer":4630,"isBookmarked":0,"aid":"2281#4630#38522#38523","text":"No-zones#Blind spots#Hidden spots#Corner spots"},
{"testId":34,"qid":1434,"description":"What is the meaning of this sign?","explanation":"This sign advises you that you are about to enter a two-way undivided street. Keep to the right and keep an eye out for vehicles coming from the other way.","imageName":"202002101251206942.jpg","correctAnswer":1960,"isBookmarked":0,"aid":"1466#1612#1960#9459","text":"No U-turns allowed#Merging traffic ahead#Two-way traffic ahead#Divided highway begins"},
{"testId":34,"qid":1435,"description":"On split highways, which of the following are utilized as left-edge lines?","explanation":"On split roads, single solid yellow lines are utilized as left-edge lines.","imageName":"","correctAnswer":4641,"isBookmarked":0,"aid":"4638#4639#4640#4641","text":"Single solid white lines#Double solid white lines#Double solid red lines#Single solid yellow lines"},
{"testId":34,"qid":1436,"description":"When you pass a moped, you have to stop at least:","explanation":"Mopeds should ride on the right side of the road. You must keep at least two feet to the left of a moped while passing it.","imageName":"","correctAnswer":38525,"isBookmarked":0,"aid":"38524#38525#38526#38527","text":"three feet to the left of it#two feet to the left of it#two feet to the right of it#one foot to the left of it"},
{"testId":34,"qid":1437,"description":"Which occupants of a vehicle are required by North Carolina law to use seat belts or appropriate child restraints?","explanation":"The seat belt legislation in North Carolina requires the driver and all passengers in a motor vehicle equipped with seat belts to wear a seat belt or a proper child restraint anytime the vehicle is in motion.","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#26667#34838#38528","text":"The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers#The driver and passengers under 16"},
{"testId":34,"qid":1439,"description":"You may experience hydroplaning at speeds as low as:","explanation":"When a thin layer of water gets between the road surface and a vehicle's tires, the tires lose touch with the road. The truck suddenly starts sliding across the road. Hydroplaning may begin at speeds as low as 30 mph and in water as shallow as 1/8 inch.","imageName":"","correctAnswer":9969,"isBookmarked":0,"aid":"9969#9970#20793#24347","text":"30 mph#45 mph#40 mph#50 mph"},
{"testId":34,"qid":1440,"description":"Use ________ to maintain a safe following distance behind the car in front of you under typical driving circumstances.","explanation":"The two-second rule is recommended by the North Carolina Driver's Handbook for maintaining a safe following distance behind the vehicle in front of you. (Some experts advocate a three-second rule.) Increase your following distance even more under bad or dangerous driving conditions.","imageName":"","correctAnswer":2172,"isBookmarked":0,"aid":"2170#2172#2173#3041","text":"the three-second rule#the two-second rule#the four-second rule#the one-second rule"},
{"testId":34,"qid":1441,"description":"If you're caught driving with a blood alcohol concentration (BAC) of ______ or higher, your license will be suspended for at least 30 days.","explanation":"If you are caught driving with a BAC of 0.08 percent or above, you will be charged with impaired driving and your license will be suspended for at least 30 days. Following that, you must apply for a new driver's license. You may face criminal penalties if you are convicted of the charge.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#4653","text":"0.08%#0.04%#0.02%#0.01%"},
{"testId":34,"qid":1442,"description":"What is the meaning of this sign?","explanation":"This sign signifies that a stop sign is ahead. Prepare to come to a halt. When you see a stop sign, you must come to a full stop before the stop line or crosswalk.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"1250#2021#4654#4655","text":"A school bus stop ahead#A stop sign ahead#A passenger bus stop ahead#An intersection ahead"},
{"testId":34,"qid":1443,"description":"________ points will be added to your driving record if you are found guilty of running a red light.","explanation":"Driver license points will be added to your driving record if you are guilty of certain motor vehicle offenses in North Carolina. The penalty for a violation varies from one to five points. Three points will be added if you are guilty of running a red light. You may face fines if you accumulate too many points on your driving record.","imageName":"","correctAnswer":4657,"isBookmarked":0,"aid":"4656#4657#4658#4659","text":"2 points#3 points#4 points#1 point"},
{"testId":34,"qid":1444,"description":"You are not permitted to park within:","explanation":"You may not park your car within 25 feet of a crossing street's curb line, or within 15 feet of intersecting right-of-way lines if there is no curb.","imageName":"","correctAnswer":38529,"isBookmarked":0,"aid":"38529#38530#38531#38532","text":"25 feet of the curb line of an intersecting street#65 feet of a fire hydrant#35 feet of the intersecting right-of-way lines if there is no curb#50 feet of the entrance to a fire station"},
{"testId":34,"qid":1445,"description":"Which of the following is NOT true about talking on a cell phone while driving?","explanation":"Texting while driving is prohibited in North Carolina. Making a phone call while driving is not against the law if you are at least 18 years old (but it is still a risky kind of distracted driving). In an emergency, any motorist under the age of 18 may make a phone call.","imageName":"","correctAnswer":38534,"isBookmarked":0,"aid":"38533#38534#38535#38536","text":"It's advisable for a passenger to make a phone call for you#It's better to send a text message rather than place a phone call#If you must use your cell phone, stop in a safe place before doing so#Even drivers under 18 may use a cell phone in an emergency"},
{"testId":34,"qid":1446,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This sign states that U-turns are not permitted in this area.","imageName":"202003151744407638.jpg","correctAnswer":38218,"isBookmarked":0,"aid":"897#10146#38216#38218","text":"None of the above#No right turns#No left turns#No U-turns"},
{"testId":34,"qid":1447,"description":"Signs with Symbols or letters in black on an orange background provide:","explanation":"Temporary signs with black text and symbols on an orange backdrop give specialized information and guidance to cars in construction zones.","imageName":"","correctAnswer":22609,"isBookmarked":0,"aid":"22609#22610#22611#22612","text":"specific information and directions to drivers in work zones#directions, routes, and distances#traffic laws and regulations#special conditions or hazards ahead"},
{"testId":34,"qid":1449,"description":"This symbol alerts vehicles to:","explanation":"This sign informs cars that there will be work or maintenance ahead, where a flag person (flagger) will be stationed. A flagger directs traffic safely through the area by waving a red flag or waving a STOP/SLOW paddle. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":30570,"isBookmarked":0,"aid":"9072#27013#30570#38539","text":"a rest area ahead#an emergency vehicle ahead#a flag person (flagger) ahead#a traffic officer ahead"},
{"testId":34,"qid":1450,"description":"This symbol is:","explanation":"White lettering or symbols are shown on a blue backdrop on service signs. This service sign denotes the presence of a hospital ahead.","imageName":"202003151737026825.jpg","correctAnswer":35406,"isBookmarked":0,"aid":"22540#24424#24426#35406","text":"a handicapped parking sign#a guide sign for a highway#a warning sign for a two-way road#a service sign for a hospital"},
{"testId":29,"qid":1451,"description":"For how much duration a Nevada instruction permit is valid?","explanation":"A one-year Nevada instructor permit is valid. You must have the permit with you at all times while driving. You may renew your permit if you haven't gotten a driver's license by the time it expires. If you are under the age of 18, your parent or guardian must sign another financial responsibility statement.","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23253#23254#23255#26650","text":"Three months#Six months#Nine months#One year"},
{"testId":29,"qid":1452,"description":"Which vehicle occupants in Nevada are required to wear seat belts or appropriate kid restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#26667#34838","text":"Just the driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers"},
{"testId":29,"qid":1453,"description":"Under usual weather conditions, when must you use your headlights?","explanation":"You must use your headlights from a half hour after nightfall to a half hour before dawn, according to Nevada law. You must also use your headlights anytime there is inadequate light to drive safely and when visibility is less than 1,000 feet.","imageName":"","correctAnswer":34840,"isBookmarked":0,"aid":"34839#34840#34841#34842","text":"From sunset until sunrise#From a half hour after sunset until a half hour before sunrise#From one hour after sunset until one hour before sunrise#From two hours after sunset until two hours before sunrise"},
{"testId":29,"qid":1454,"description":"What is the meaning of this sign?","explanation":"This sign indicates that there will be a traffic light ahead. If the light is yellow or red, prepare to stop.","imageName":"202002101142048449.jpg","correctAnswer":30254,"isBookmarked":0,"aid":"28498#30254#34843#34844","text":"A construction zone is ahead#A traffic signal is ahead#An unexpected hazard is ahead#An uncontrolled intersection is ahead"},
{"testId":29,"qid":1455,"description":"You must stop before _________ at an intersection with a red traffic signal.","explanation":"In Nevada, a broken traffic signal must be treated as a stop sign. That is, you must stop, yield to pedestrians and other cars, and advance only when it is safe to do so (with great care). If none of the junction's lights are functional, regard the intersection as an all-way stop.","imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"125#210#757#758","text":"increase your speed#change lanes#slow down#stop"},
{"testId":29,"qid":1456,"description":"When approaching a roundabout, you must cede the right-of-way to oncoming traffic and drive in:","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. When approaching a roundabout, you must cede the right-of-way to oncoming vehicles and go counterclockwise.","imageName":"","correctAnswer":23844,"isBookmarked":0,"aid":"8626#8839#8842#23844","text":"None of the above#a clockwise direction#any direction#a counterclockwise direction"},
{"testId":29,"qid":1457,"description":"To perform legal and safe turns, you are required to indicate your turn at least:","explanation":"You must indicate your turn at least 100 feet ahead on city streets and 300 feet ahead on open highways to make a safe and legal turn. (A car traveling at 65 mph will traverse 300 feet in 3.1 seconds.)","imageName":"","correctAnswer":34845,"isBookmarked":0,"aid":"34845#34846#34847#34848","text":"100 feet ahead on city streets and 300 feet ahead on open highways#75 feet ahead on city streets and 250 feet ahead on open highways#50 feet ahead on city streets and 200 feet ahead on open highways#25 feet ahead on city streets and 150 feet ahead on open highways"},
{"testId":29,"qid":1458,"description":"This symbol stands for:","explanation":"Route marker signs show the kind of route and the specific road on which you are going. Typically, highway marker signs are shield-shaped. This sign denotes that you are on Interstate Highway 22. (I-22 for short).","imageName":"202003301737564419.jpg","correctAnswer":8802,"isBookmarked":0,"aid":"8802#8804#19233#34730","text":"the interstate highway number#the number of miles to the next exit#an exit number#the highway speed limit"},
{"testId":29,"qid":1459,"description":"When overtaking on a two-lane road, turn on your left signal light at least ________ in commercial or residential zones.","explanation":"When passing on a two-lane road, turn on your left signal light at least 100 feet ahead in commercial or residential zones, and at least 300 feet ahead in other areas. Keep in mind that your car will go 100 feet in 2.7 seconds at 25 mph (the legal speed limit in Nevada commercial and residential sectors).","imageName":"","correctAnswer":4469,"isBookmarked":0,"aid":"4467#4469#4704#4705","text":"50 feet ahead#100 feet ahead#45 feet ahead#85 feet ahead"},
{"testId":29,"qid":1460,"description":"When a driver extends his or her left arm horizontally out the window, it implies that he or she intends to:","explanation":"When a motorist extends his or her left arm straight out, he or she is indicating a left turn.","imageName":"","correctAnswer":28,"isBookmarked":0,"aid":"28#8955#8990#8992","text":"Make a left turn#stop#make a right turn#make a U-turn"},
{"testId":29,"qid":1463,"description":"To exit a roundabout, you must first:","explanation":"Turn on your right turn signal to leave a roundabout.","imageName":"","correctAnswer":34849,"isBookmarked":0,"aid":"27#34849#34850#34851","text":"Change lanes#use a right turn signal#use a left turn signal#not use a signal"},
{"testId":29,"qid":1464,"description":"Never turn the ignition to the lock position while your vehicle is in motion, as this will result in :","explanation":"When your car is in motion, never turn your ignition key to the lock position. If you do this, the steering will lock and you will lose control of your car. Since 1969, the steering lock has been standard equipment. Its purpose is to prevent a burglar from directing your automobile if he starts it without the key.","imageName":"","correctAnswer":24354,"isBookmarked":0,"aid":"24353#24354#34852#34853","text":"the engine to fail#the steering wheel to lock#the brakes to lock#the lights to fail"},
{"testId":29,"qid":1465,"description":"Your vehicle is considered as legally parallel parked if:","explanation":"The front and rear wheels must be within 18 inches of the curb while parallel parking.","imageName":"","correctAnswer":34857,"isBookmarked":0,"aid":"34854#34855#34856#34857","text":"its front and rear wheels must be within 12 inches of the curb#its front wheels must be within 6 inches of the curb#its rear wheels must be within 24 inches of the curb#its front and rear wheels must be within 18 inches of the curb"},
{"testId":29,"qid":1466,"description":"What is the meaning of this sign?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight sided). When you come to a full stop in front of this sign, you must yield to pedestrians and traffic. When the junction is clear, you may continue.","imageName":"202001301744345845.jpg","correctAnswer":26322,"isBookmarked":0,"aid":"54#26322#27808#32168","text":"A one-way road is ahead#You must stop#The road is closed ahead#An emergency stop is ahead"},
{"testId":29,"qid":1467,"description":"You can't park your car anywhere on this list except:","explanation":"At no point should you park your automobile on a sidewalk, at a crossroads, or in front of a private driveway. Unless otherwise banned, you may park on the side of a street (parallel parking).","imageName":"","correctAnswer":8788,"isBookmarked":0,"aid":"8785#8786#8787#8788","text":"on a sidewalk#in front of a private driveway#in an intersection#on the side of a street"},
{"testId":29,"qid":1468,"description":"Don't stare at incoming headlights. Instead, you should look:","explanation":"Don't gaze directly into incoming automobiles' headlights; the intense lights might dazzle you. Look down and to the right side of your lane instead.","imageName":"","correctAnswer":34860,"isBookmarked":0,"aid":"34858#34859#34860#34861","text":"down and to the left side of your lane#down and to the center of your lane#down and to the right side of your lane#into the side mirrors"},
{"testId":29,"qid":1469,"description":"When driving by a stopped emergency vehicle, you must:","explanation":"To pass a stopped emergency vehicle in Nevada, you must slow down to a reasonable and appropriate pace that is less than the stated speed limit. You must also move into a non-adjacent lane if feasible, leaving at least one lane between you and the emergency vehicle. This is true for all emergency vehicles, including tow trucks. (Note: All US states now have their own Move Over statutes, however some demand various actions from drivers.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":34863,"isBookmarked":0,"aid":"28646#34092#34862#34863","text":"pull over#move into a non-adjacent lane if possible; otherwise, slow down#proceed as usual#slow down; also move into a non-adjacent lane if possible"},
{"testId":29,"qid":1470,"description":"This hand signal signifies the driver's intention to:","explanation":"This motorist plans to slow down or come to a complete stop.","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"28#8990#8992#22345","text":"Make a left turn#make a right turn#make a U-turn#slow down or stop"},
{"testId":29,"qid":1471,"description":"When there is insufficient light or when people or cars cannot be seen clearly from ________ away, you must use your headlights.","explanation":"When there is inadequate light or when people or vehicles cannot be seen clearly from a distance of 1,000 feet, you must use your headlights (about two-tenths of a mile).","imageName":"","correctAnswer":30593,"isBookmarked":0,"aid":"30593#34864#34865#34866","text":"1,000 feet#4,000 feet#3,000 feet#2,000 feet"},
{"testId":29,"qid":1472,"description":"You notice a railroad train approaching a railroad grade crossing as you drive toward it. You must come to a complete stop ________ from the nearest rail of the railroad track.","explanation":"If you see a train coming and are approaching a railroad grade crossing, you must halt between 15 and 50 feet away from the rail closest to you. You should avoid getting too near to trains since they are at least six feet wider than the tracks they travel on.","imageName":"","correctAnswer":34870,"isBookmarked":0,"aid":"34867#34868#34869#34870","text":"between 5 feet and 100 feet#between 10 feet and 150 feet#between 15 feet and 200 feet#between 15 feet and 50 feet"},
{"testId":29,"qid":1473,"description":"When following another vehicle in adverse conditions, you should:","explanation":"In inclement weather, your vision of the road may be obstructed. Additionally, on a slick road, your stopping distance will rise. As a result, while following another vehicle in inclement weather, increase your following distance for safety.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#8829#34871","text":"Increase your speed#Increase your following distance#Change lanes frequently#reduce your braking distance"},
{"testId":29,"qid":1474,"description":"What does this image represent?","explanation":"If there is a broken or dashed line adjacent to your lane, you may pass on the left if it is safe to do so.","imageName":"202004281841132779.jpg","correctAnswer":4745,"isBookmarked":0,"aid":"4745#4746#19540#19541","text":"A broken white line that allows passing#A broken white line that prohibits passing#An accident situation due to reduced following distance#A car making a U-turn"},
{"testId":29,"qid":1475,"description":"In Nevada, the legal speed limit on rural interstate roadways is at least _______.","explanation":"In Nevada, the legal speed limit on rural interstate roadways is at least 70 mph, although it may be higher in certain areas. The speed limit for one portion of Interstate 80 was lifted to 80 mph in May 2017.","imageName":"","correctAnswer":23846,"isBookmarked":0,"aid":"9970#9971#23846#33671","text":"45 mph#65 mph#70 mph#75 mph"},
{"testId":29,"qid":1476,"description":"Your headlights must be dimmed:","explanation":"When you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following, Nevada law requires you to reduce your headlights to low beam.","imageName":"","correctAnswer":34873,"isBookmarked":0,"aid":"34872#34873#34874#34875","text":"within 300 feet of an oncoming vehicle or within 100 feet of a vehicle you are following#within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following#within 200 feet of an oncoming vehicle or within 200 feet of a vehicle you are following#within 400 feet of an oncoming vehicle or within 200 feet of a vehicle you are following"},
{"testId":29,"qid":1478,"description":"What is the meaning of this sign?","explanation":"You have entered a school zone, as indicated by this sign. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there.","imageName":"202003151742324658.jpg","correctAnswer":638,"isBookmarked":0,"aid":"638#641#9573#34876","text":"A school zone#A playground#A hospital#A public library"},
{"testId":29,"qid":1479,"description":"To get access to a freeway, you must:","explanation":"Enter the motorway at or near traffic speed. Remember that the speed limit on highways is at least 65 mph, and it may be higher on certain rural parts. The right-of-way is reserved for freeway traffic. However, unless absolutely essential, do not stop before entering into highway traffic.","imageName":"202007210423335122.jpg","correctAnswer":23901,"isBookmarked":0,"aid":"8922#8923#23900#23901","text":"stop before merging into freeway traffic#stop and yield to the traffic on the freeway#enter the freeway at a minimum speed#enter the freeway at or near the speed of freeway traffic"},
{"testId":29,"qid":1480,"description":"The speed restriction in school zones is:","explanation":"The speed restriction in a school zone is 15 mph. The speed restriction in a school crossing zone is 25 mph. Unless otherwise indicated, these speed limitations are in place on school days from half an hour before school starts to half an hour after school concludes.","imageName":"","correctAnswer":34879,"isBookmarked":0,"aid":"34877#34878#34879#34880","text":"either 50 or 60 mph#either 30 or 40 mph#either 15 or 25 mph#either 5 or 10 mph"},
{"testId":29,"qid":1481,"description":"Hydroplaning occurs when a vehicle loses traction and slides across the road surface, often _________ in heavy rain.","explanation":"Most automobiles will begin to lose traction and hydroplane at speeds ranging from 35 to 55 mph in severe rain. You may lose control of your car if this occurs to you. Slow down to less than 35 mph in poor weather to avoid this.","imageName":"","correctAnswer":34884,"isBookmarked":0,"aid":"34881#34882#34883#34884","text":"between 10 and 15 mph#between 20 and 25 mph#between 25 and 35 mph#between 35 and 55 mph"},
{"testId":29,"qid":1482,"description":"What is the meaning of this sign?","explanation":"This is the worldwide emblem of access for individuals with disabilities. It may be seen on street signs, placards, and license plates. In Nevada, you may park in a place designated with this symbol only if the person who received the disability placard or license plate is either operating or being carried in the vehicle.","imageName":"202003151732554338.jpg","correctAnswer":31993,"isBookmarked":0,"aid":"4774#5650#30289#31993","text":"A hospital service#A pedestrian crosswalk#A wheelchair service#A place reserved for persons with disabilities"},
{"testId":29,"qid":1483,"description":"If a highway entry has ramp meters and they are turned on, you must:","explanation":"If a highway entry has ramp meters that are turned on, you must pull up to the stop line and stop on red. When the signal turns green, go down the ramp and safely merge into the motorway.","imageName":"","correctAnswer":34886,"isBookmarked":0,"aid":"22727#34885#34886#34887","text":"Do none of the above#wait for the flashing yellow light to proceed#pull up to the stop line and stop on red#proceed along the ramp when the light turns red"},
{"testId":29,"qid":1484,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance becomes four times as long, not twice as long. If you quadruple your speed, your braking distance increases by nine times.","imageName":"","correctAnswer":21080,"isBookmarked":0,"aid":"8591#8592#21080#22749","text":"two times#three times#four times#five times"},
{"testId":29,"qid":1485,"description":"If you miss a freeway exit, you should:","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Instead, take the next exit and find a method to return from there.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8518#8519#8521#9110","text":"back up and return to the exit#make a U-turn and return to the exit#continue to the next exit#stop and ask for help"},
{"testId":29,"qid":1486,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. Stop between 15 and 50 feet from the closest rail if a train is coming.","imageName":"202001282004545744.jpg","correctAnswer":1701,"isBookmarked":0,"aid":"1701#3176#5689#34888","text":"A railroad crossing ahead#A one-way road ahead#Road repair work ahead#A high-occupancy vehicle (HOV) lane ahead"},
{"testId":29,"qid":1487,"description":"When driving at motorway speeds, you should look ahead at least :","explanation":null,"imageName":"","correctAnswer":34890,"isBookmarked":0,"aid":"34889#34890#34891#34892","text":"10鈥12 seconds#20鈥30 seconds#15鈥20 seconds#30鈥40 seconds"},
{"testId":29,"qid":1489,"description":"If a driver over the age of 21 is caught driving with a blood alcohol concentration (BAC) of _________, his or her license will be suspended.","explanation":null,"imageName":"","correctAnswer":31244,"isBookmarked":0,"aid":"1396#31244#31245#34893","text":"None of the above#0.08% or above#0.02% or above#0.04% or above"},
{"testId":29,"qid":1490,"description":"What is the meaning of this sign?","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through these places, they utilize red flags or stop/slow paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":3277,"isBookmarked":0,"aid":"1346#3277#3280#34894","text":"A crosswalk ahead#A flagger ahead#An emergency vehicle ahead#A police officer ahead"},
{"testId":42,"qid":1491,"description":"Where there is both a solid and a broken yellow line between two lanes of traffic, you can't pass:","explanation":"You may not pass when there is both a solid and a dashed yellow line between opposing lanes of traffic if the solid yellow line is on your side.","imageName":"202007161221019734.jpg","correctAnswer":9208,"isBookmarked":0,"aid":"9207#9208#9209#39380","text":"if the dashed yellow line is on your side#if the solid yellow line is on your side#if the solid yellow line is not on your side#on either side"},
{"testId":42,"qid":1492,"description":"Sharing of the middle lanes is reserved for:","explanation":"A shared middle lane is designated for left turns (or U-turns where permitted). Vehicles driving in both directions may utilize this lane. Left-turn arrows for traffic arriving from one way alternate with left-turn arrows for traffic coming from the opposite direction on the pavement. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30214#40516#40517","text":"All of the above#making left turns#making U-turns where permitted#vehicles traveling in both directions"},
{"testId":42,"qid":1493,"description":"The ________ lane on multilane roadways is used for passing slower vehicles.","explanation":"The leftmost lane on multilane roadways is for overtaking slower cars. If you pass on the right, the other vehicle may not notice you and may abruptly change lanes in front of you.","imageName":"","correctAnswer":24432,"isBookmarked":0,"aid":"120#2393#24431#24432","text":"Center#shoulder#rightmost#leftmost"},
{"testId":42,"qid":1494,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a crossroads or four-way junction ahead. Slow down and be cautious for cross traffic.","imageName":"202002101146277756.jpg","correctAnswer":40519,"isBookmarked":0,"aid":"2528#9573#40518#40519","text":"A railroad crossing#A hospital#A three-way intersection#A four-way intersection"},
{"testId":42,"qid":1496,"description":"If an emergency vehicle with flashing red or blue lights and a siren approaches, you must:","explanation":"If an emergency vehicle approaches you with its siren or flashing lights on, you must pull over to the right and stay stopped until the emergency vehicle has passed. If you are at an intersection as an emergency vehicle arrives, do not stop. Continue past the junction and then immediately turn right.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"22544#22546#24031#40520","text":"continue at the same speed#pull over to the right edge of the road#pull over to the left edge of the road#stop your vehicle wherever you are"},
{"testId":42,"qid":1497,"description":"South Dakota law compels you to_____ for a pedestrian who is using a white cane or a guiding dog, regardless of whether the person is in a crosswalk.","explanation":"A blind or visually handicapped pedestrian uses a white cane or a guide dog. Whether or whether the individual is in a crosswalk, South Dakota law compels you to stop for a blind pedestrian.","imageName":"","correctAnswer":758,"isBookmarked":0,"aid":"758#3106#4814#4815","text":"stop#move slowly#sound your horn#pull over"},
{"testId":42,"qid":1498,"description":"This symbol is:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":31318,"isBookmarked":0,"aid":"31318#31319#31321#35203","text":"a railroad crossing sign#a road repair sign#a stop sign at a roundabout#a No Passing sign at a work zone"},
{"testId":42,"qid":1499,"description":"Keep _____ on the steering wheel, especially during turns.","explanation":"Place your left hand between 8 and 10 o'clock and your right hand between 2 and 4 o'clock to grasp the steering wheel. This posture is said to allow for high-speed maneuvers. Keep your hands below 10 o'clock and 2 o'clock if your car has a driver's side airbag. When the driver's side airbag deploys, it may push your hands towards your face or potentially harm them if they are higher than that.","imageName":"","correctAnswer":40524,"isBookmarked":0,"aid":"40521#40522#40523#40524","text":"your left hand between 10 and 12 o'clock and your right hand between 1 and 3 o'clock#your left hand between 7 and 9 o'clock and your right hand between 3 and 5 o'clock#your left hand at 11 o'clock and your right hand at 2 o'clock#your left hand between 8 and 10 o'clock and your right hand between 2 and 4 o'clock"},
{"testId":42,"qid":1500,"description":"When approaching a crossroads with no traffic signs or signals, you must yield to:","explanation":"When there are no signs or signals at a junction, you must let traffic coming from the right pass.","imageName":"","correctAnswer":8522,"isBookmarked":0,"aid":"8522#8523#8747#40525","text":"vehicles coming from the right#vehicles coming from the left#heavy vehicles#vehicles with more passengers"},
{"testId":42,"qid":1502,"description":"What is the meaning of this sign?","explanation":"This sign shows that employees are undertaking road repair. Slow down and take your time. Move into a lane away from the employees if feasible.","imageName":"202002101222518967.jpg","correctAnswer":31663,"isBookmarked":0,"aid":"638#5650#30668#31663","text":"A school zone#A pedestrian crosswalk#A flag person (flagger) at a work zone#A road crew at work"},
{"testId":42,"qid":1503,"description":"Which of the following activities can cause you to become distracted while driving?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#36191#40526#40527","text":"All of the above#Using a handheld cell phone#Talking on a CB radio#Reading a map"},
{"testId":42,"qid":1504,"description":"When driving in the city, you should look at least _____ ahead to avoid unexpected braking or turning.","explanation":null,"imageName":"","correctAnswer":985,"isBookmarked":0,"aid":"427#985#987#4832","text":"5 seconds#10 seconds#15 seconds#20 seconds"},
{"testId":42,"qid":1505,"description":"When traveling at high speeds, you should begin to signal _________ before turning.","explanation":"Always provide ample advance notice of your intentions to other drivers. You must begin to signal at least 100 feet before making a turn, according to South Dakota law. However, while travelling at faster speeds, you should signal for a longer time, perhaps 4 to 5 seconds. (At 45 mph, it will take your car only 1.5 seconds to go 100 feet.)","imageName":"","correctAnswer":4833,"isBookmarked":0,"aid":"1788#4833#4834#4835","text":"1 to 2 seconds#4 to 5 seconds#6 to 8 seconds#2 to 3 seconds"},
{"testId":42,"qid":1506,"description":"What is this posted sign?","explanation":null,"imageName":"202004071547559027.jpg","correctAnswer":39742,"isBookmarked":0,"aid":"19484#39742#39743#39744","text":"An interstate route marker#A speed limit sign on freeways#A speed limit sign on hills#A speed limit sign at roundabouts"},
{"testId":42,"qid":1508,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are vast zones around trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. No-Zones significantly enhance the chance of a collision.","imageName":"","correctAnswer":236,"isBookmarked":0,"aid":"236#24369#24370#24371","text":"No-Zones#Slow-Zones#Blind-Zones#Side-Zones"},
{"testId":42,"qid":1509,"description":"When is it OK to blow your horn?","explanation":"When you need to warn of an impending hazard, such as when a pedestrian is going to walk, run, or ride onto the roadway, another vehicle is about to strike you, or you have lost control of your car and may hit someone, sound your horn loudly.","imageName":"","correctAnswer":11220,"isBookmarked":0,"aid":"11220#40528#40529#40530","text":"In any of the above situations#If another vehicle is in danger of hitting you#If a child is about to run into the street#If you have lost control of your vehicle and are moving toward someone"},
{"testId":42,"qid":1510,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This prohibitory sign indicates that U-turns are not permitted in this area.","imageName":"202003151744407638.jpg","correctAnswer":19280,"isBookmarked":0,"aid":"19280#19281#26318#40013","text":"U-turns are not allowed#Left turns are not allowed#The left lane is closed ahead#Do not enter this roadway"},
{"testId":42,"qid":1511,"description":"What should you do if you see a slow-moving (low-speed) vehicle in your lane?","explanation":null,"imageName":"","correctAnswer":8796,"isBookmarked":0,"aid":"8795#8796#33050#33051","text":"Alert the driver by blowing your horn#Adjust your speed and slow down safely#Pass it at high speed#Stop and then take a detour"},
{"testId":42,"qid":1512,"description":"When there is water on the road, you must slow down to less than 35 mph to avoid:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" Hydroplaning may cause entire loss of braking and steering control at speeds of 50 mph or higher. Slow down to avoid hydroplaning.","imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8584#40531#40532","text":"Hydroplaning#tailgating#traction#overheating"},
{"testId":42,"qid":1513,"description":"What should you do if you miss your exit on an interstate highway?","explanation":null,"imageName":"202007210432373628.jpg","correctAnswer":33386,"isBookmarked":0,"aid":"24257#24258#32570#33386","text":"Stop and ask for help#Back up to reach your exit#Make a U-turn to reach your exit#Take the next exit"},
{"testId":42,"qid":1515,"description":"Your vehicle will travel ________ at 75 mph in the time it takes you to react to a hazard and bring the vehicle to a stop.","explanation":"In the time it takes you to respond to a danger and slow down your car at 75 mph, it will have travelled around 600 feet. This distance is more than 0.2 miles.","imageName":"","correctAnswer":4863,"isBookmarked":0,"aid":"4860#4861#4862#4863","text":"about 475 feet#about 500 feet#about 275 feet#about 600 feet"},
{"testId":42,"qid":1516,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":42,"qid":1517,"description":"When joining an interstate highway from an acceleration lane, you must:","explanation":"You must keep up with other traffic or respect stated speed restrictions while approaching an interstate highway from an acceleration lane.","imageName":"","correctAnswer":33052,"isBookmarked":0,"aid":"6757#24314#24464#33052","text":"decrease your speed#increase your speed beyond the posted speed limit to merge#stop and wait for the space to merge#keep up with other traffic or obey posted speed limits"},
{"testId":42,"qid":1518,"description":"This symbol is:","explanation":"Service signs direct people to the locations of different services such as rest stops, petrol stations, and hospitals. The lettering on service signs are white on a blue backdrop. One mile ahead, this service sign denotes a rest stop.","imageName":"202004142323537192.jpg","correctAnswer":19547,"isBookmarked":0,"aid":"19546#19547#19548#24497","text":"a route marker sign#A service sign#a warning sign#A regulatory sign"},
{"testId":42,"qid":1519,"description":"If you are being tailgated you should not:","explanation":"If you are being followed too closely (tailgated), gently slow down to let the tailgater to pass. If this doesn't work, move to the right and let the tailgater pass. Don't slow down abruptly. The tailgater may not have enough space in front of his or her car to decelerate or stop before colliding with you from behind.","imageName":"202003201758501730.jpg","correctAnswer":40533,"isBookmarked":0,"aid":"9892#23876#40533#40534","text":"stay in the right lane#slow down gradually#slow down suddenly#encourage the tailgater to pass"},
{"testId":42,"qid":1520,"description":"Which passengers in a vehicle are needed to use proper child restraints or seat belts in South Dakota?","explanation":null,"imageName":"","correctAnswer":35237,"isBookmarked":0,"aid":"26667#34838#35237#40535","text":"The driver and front-seat passengers#The driver and rear-seat passengers#The driver, front-seat passengers, and passengers under 18#The driver, rear-seat passengers, and passengers under 18"},
{"testId":42,"qid":1521,"description":"Use _________ to make sure you're not driving faster than you can see ahead.","explanation":"You must not drive faster than the distance visible front of you. The four-second sight distance guideline is recommended by the South Dakota Driver License Manual for safe driving. Choose a stationary item as far ahead as you can see. Then time how long it takes you to get to that thing. If you can get there in less than four seconds, you're driving too quickly and may not be able to stop in time to escape a danger that far ahead. This guideline should also be used at night to ensure that you are not \"overdriving\" your headlights.","imageName":"","correctAnswer":4880,"isBookmarked":0,"aid":"4880#4881#4882#4883","text":"the four-second sight distance rule#the three-second sight distance rule#the two-second sight distance rule#the one-second sight distance rule"},
{"testId":42,"qid":1523,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":null,"imageName":"","correctAnswer":30622,"isBookmarked":0,"aid":"24142#30622#38606#38607","text":"Sound your horn to alert the bicyclist#Slow down and let the vehicle pass first#Speed up and pass the bicycle first#Sound your horn to alert the oncoming vehicle"},
{"testId":42,"qid":1524,"description":"When you are within _______ of an oncoming vehicle, you should dim your headlights.","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"401#1296#2780#2781","text":"400 feet#500 feet#700 feet#600 feet"},
{"testId":42,"qid":1525,"description":"Between ________ and any other hour when visibility is poor, you must drive in South Dakota with your headlights on.","explanation":"South Dakota law requires you to use your headlights from a half hour after sunset to a half hour before dawn, and at any other time when there is insufficient light to see a person 200 feet ahead on the highway.","imageName":"","correctAnswer":4689,"isBookmarked":0,"aid":"4189#4689#4690#4893","text":"one hour after sunset and one hour before sunrise#a half hour after sunset and a half hour before sunrise#two hours after sunset and two hours before sunrise#three hours after sunset and three hours before sunrise"},
{"testId":42,"qid":1526,"description":"This symbol may be found:","explanation":null,"imageName":"202003151708065204.jpg","correctAnswer":31837,"isBookmarked":0,"aid":"31835#31837#40536#40537","text":"on highways#in work zones#in roundabouts#at no-parking zones"},
{"testId":42,"qid":1528,"description":"On a two-lane road, you must stop at least _______ from a stopped school bus with flashing red lights.","explanation":"If a school bus stops with its red lights flashing on a two-lane road in South Dakota, you must come to a full stop at least 15 feet away, regardless of which direction you are heading. Continue to stand still until the flashing red lights go off. On a road having two or more lanes in each direction, you must stop in this manner only if you are on the same side of the road as the school bus.","imageName":"","correctAnswer":8416,"isBookmarked":0,"aid":"1028#8416#18446#35934","text":"10 feet#15 feet#12 feet#8 feet"},
{"testId":42,"qid":1529,"description":"If a motorist is at least 21 years old and has a blood alcohol content (BAC) of ______ or above, he or she can be charged with DUI.","explanation":"If a motorist is at least 21 years old and has a blood alcohol content (BAC) of 0.08 percent or more, he or she may be charged with DUI. A motorist under the age of 21 in South Dakota may be prosecuted with DUI if his or her BAC is 0.02 percent or higher.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#3282#4252","text":"0.08%#0.07%#0.06%#0.05%"},
{"testId":42,"qid":1530,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#38780","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a bump ahead"},
{"testId":36,"qid":1531,"description":"In which of the following scenarios is driving in the leftmost lane prohibited?","explanation":null,"imageName":"","correctAnswer":38948,"isBookmarked":0,"aid":"38945#38946#38947#38948","text":"When you are overtaking and passing another vehicle#When you are on a roadway with three or more lanes#When you are on a one-way road#When you are driving slower than the rest of the traffic"},
{"testId":36,"qid":1532,"description":"If an emergency vehicle approaches with flashing lights and an audible signal, you must:","explanation":"When an emergency vehicle with flashing lights and an audible signal approaches, you must pull over to the right edge or curb of the road or highway and stay stopped until the emergency vehicle passes by or a law enforcement official tells you to continue. However, if you are at an intersection and an emergency vehicle approaches, do not stop. Instead, go through the junction and immediately pull over.","imageName":"","correctAnswer":34688,"isBookmarked":0,"aid":"34688#38949#38950#38951","text":"pull over to the right#immediately clear the right lane and pull over to the left#stop where you are#increase your speed to stay ahead of the emergency vehicle"},
{"testId":36,"qid":1533,"description":"After stopping at a red traffic signal on a two-way road in Ohio, you may:","explanation":"You may lawfully turn right on a red light in Ohio, like in much of the rest of the country, unless there are signs banning so. You must stop and yield to pedestrians and cross traffic before turning right. In Ohio, you can only turn left on a red light if you're going from one one-way street to another one-way one.","imageName":"","correctAnswer":759,"isBookmarked":0,"aid":"353#759#38952#38953","text":"turn left#turn right#proceed through the intersection#not turn right"},
{"testId":36,"qid":1535,"description":"When children are on their way to or from school, the speed limit in a school zone is:","explanation":"During school recess or while children are coming to or leaving school, the speed restriction in a school zone is 20 mph.","imageName":"","correctAnswer":9968,"isBookmarked":0,"aid":"9968#9969#22200#22201","text":"20 mph#30 mph#15 mph#25 mph"},
{"testId":36,"qid":1537,"description":"You may park your car parallel to and no more than__________ from the right or left curb on a one-way street.","explanation":"In Ohio, a vehicle must be parked parallel to and no more than 12 inches from the right curb, facing the direction of traffic on that side of the roadway.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"815#816#818#4921","text":"15 inches#25 inches#12 inches#18 inches"},
{"testId":36,"qid":1539,"description":"If you intend to make a turn, you must begin signaling at least _______ before turning.","explanation":"A turn signal must be given at least 100 feet before the turn. You may utilize hand gestures or the turn signals on your car.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#3529#4926","text":"100 feet#50 feet#70 feet#80 feet"},
{"testId":36,"qid":1540,"description":"On a street or road with fewer than four lanes, a school bus is stopped with its red lights flashing. Which of the following statements is correct?","explanation":"When a school bus is stopped on a street or road with less than four lanes, all cars approaching the bus from either direction must stop at least 10 feet away from the bus, according to Ohio law. They must stay halted until the bus begins to move or the bus driver indicates that they may continue.","imageName":"","correctAnswer":38957,"isBookmarked":0,"aid":"38957#38958#38959#38960","text":"All vehicles approaching the bus from either direction must stop#All vehicles approaching the bus from behind must stop. Other vehicles must slow down#All vehicles approaching the bus from either direction must slow down#All vehicles approaching the bus from either direction must speed up"},
{"testId":36,"qid":1541,"description":"To keep a safe distance behind the vehicle in front of you, use:","explanation":"Many experts advocate the \"three-second rule,\" which states that you should maintain a following distance of at least three seconds behind the car in front of you. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance.","imageName":"","correctAnswer":30202,"isBookmarked":0,"aid":"30201#30202#30203#30541","text":"the four-second rule#the three-second rule#the two-second rule#the five-second rule"},
{"testId":36,"qid":1542,"description":"What is the meaning of this sign?","explanation":"According to Ohio law, all front-seat occupants, including the driver, and all passengers under the age of 15 must wear safety belts or appropriate child restraints. This particular information sign is shown to notify motorists of the legislation.","imageName":"202003151755527296.jpg","correctAnswer":38963,"isBookmarked":0,"aid":"38961#38962#38963#38964","text":"Use a cell phone while you're driving#Don't use a cell phone while you're driving#Wear your safety belt#Don't exceed the speed limit"},
{"testId":36,"qid":1543,"description":"Which of the following claims about the speed limits in Ohio is correct?","explanation":null,"imageName":"","correctAnswer":37915,"isBookmarked":0,"aid":"37915#37916#37917#37918","text":"Outside business districts, the speed limit on urban state routes other than controlled-access highways is 35 mph except where otherwise posted#The maximum speed limit on freeways within municipal corporations is 75 mph#Under adverse conditions, you should drive at the posted speed limit#The speed limit in urban alleys is 35 mph except where otherwise posted"},
{"testId":36,"qid":1544,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"If your car becomes stuck on a railroad track while a train approaches, don't waste time attempting to remove it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction from where the train is coming. If your vehicle is hit, you and your passengers will be protected from flying debris. Call 911 or the number listed on the train crossing sign.","imageName":"202007210447482035.jpg","correctAnswer":38578,"isBookmarked":0,"aid":"38578#38965#38966#38967","text":"Get everyone out of the vehicle#Try to restart your vehicle#Turn on your flashers and try to warn the train#Call 911 and wait for emergency assistance"},
{"testId":36,"qid":1545,"description":"When another vehicle passes you, you must ________ until the other vehicle has safely passed.","explanation":null,"imageName":"","correctAnswer":38968,"isBookmarked":0,"aid":"4815#38968#38969#38970","text":"pull over#maintain a constant speed and keep right#increase your speed and keep right#maintain a constant speed and keep left"},
{"testId":36,"qid":1546,"description":"What is the meaning of this sign?","explanation":"This sign informs that the road ahead takes an abrupt right bend.","imageName":"202002061258239664.jpg","correctAnswer":22422,"isBookmarked":0,"aid":"22422#38971#38972#38973","text":"A sharp right turn is ahead#A curve to the right is ahead#Keep right ahead#Only right turns are allowed ahead"},
{"testId":36,"qid":1547,"description":"It is prohibited to drive with a blood alcohol content (BAC) of _____ or higher.","explanation":"The legal drinking age in Ohio is 21. It is unlawful to drive in Ohio, as well as the rest of the country, if your BAC is 0.08 percent or more.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3282#11768#11769","text":"0.08%#0.06%#0.10%#0.12%"},
{"testId":36,"qid":1548,"description":"Which of the following traffic infractions in Ohio receives six penalty points?","explanation":null,"imageName":"","correctAnswer":38974,"isBookmarked":0,"aid":"11621#18625#37495#38974","text":"Reckless driving#Speeding#Failing to stop at a red traffic light#Fleeing from a police officer"},
{"testId":36,"qid":1549,"description":"What should you do if you see a flashing yellow light?","explanation":"Flashing yellow signals function similarly to warning signs in that they alert vehicles to possible risks. Slow down and continue with care if you observe a flashing yellow signal.","imageName":"","correctAnswer":19471,"isBookmarked":0,"aid":"14188#19471#25445#38975","text":"Yield#Slow down and proceed with caution#Speed up#Stop and yield"},
{"testId":36,"qid":1551,"description":"When you are __________, you may not use the opposing lane to pass another vehicle.","explanation":"When you are within 100 feet of an intersection, a railroad crossing, a bridge, a viaduct, or a tunnel in Ohio, you are not permitted to pass in the opposite lane. You are also not permitted to pass if your vision is obscured when on a curve or at the peak of a hill.","imageName":"","correctAnswer":28021,"isBookmarked":0,"aid":"28021#38976#38977#38978","text":"Within 100 feet of a bridge#within 300 feet of an intersection#within 200 feet of a railroad grade crossing#within 300 feet of a railroad grade crossing"},
{"testId":36,"qid":1552,"description":"The speed limit in alleys within a city or town is _______.","explanation":"Alleys inside a municipal corporation have a speed restriction of 15 mph.","imageName":"","correctAnswer":22200,"isBookmarked":0,"aid":"9969#20793#22200#22201","text":"30 mph#40 mph#15 mph#25 mph"},
{"testId":36,"qid":1553,"description":"What should you do when approaching a vehicle with a triangular orange reflective sign on the back?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"10578#19471#38979#38980","text":"Increase your speed#Slow down and proceed with caution#Pass the vehicle quickly#Blow your horn to alert its driver"},
{"testId":36,"qid":1554,"description":"What is the meaning of this sign?","explanation":"This sign signifies that a stop sign is ahead. Prepare to come to a halt. When you see a stop sign, you must come to a full stop before the stop line or crosswalk.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2185#2515#38981","text":"A stop sign ahead#A bus stop ahead#A yield sign ahead#A Do Not Enter sign ahead"},
{"testId":36,"qid":1555,"description":"It is illegal in Ohio to make a left turn at a red light EXCEPT:","explanation":"Except from a one-way street into another one-way street, it is unlawful in Ohio to make a left turn at a red signal. To turn right at a red signal, you must first come to a full stop and yield to oncoming vehicles and pedestrians.","imageName":"","correctAnswer":24217,"isBookmarked":0,"aid":"24217#24218#24219#24220","text":"from a one-way street onto another one-way street#from a two-way street onto a one-way street#from a one-way street onto a two-way street#from a two-way street onto another two-way street"},
{"testId":36,"qid":1557,"description":"If you hit and kill a deer, Ohio law compels you to :","explanation":null,"imageName":"","correctAnswer":38983,"isBookmarked":0,"aid":"28036#38982#38983#38984","text":"Ignore the accident and continue on your way#stop at the accident scene#report the collision or let the meat go#stop other drivers and seek their help"},
{"testId":36,"qid":1558,"description":"This sign and the pavement markers allow:","explanation":"The middle lane is a two-way left-turn lane, also known as a shared center turning lane, according to the sign and pavement markings. Vehicles coming from either direction may enter this lane solely to turn left. This lane should never be utilized to pass.","imageName":"202004142352587013.jpg","correctAnswer":19558,"isBookmarked":0,"aid":"8626#19558#19559#38985","text":"None of the above#vehicles from either direction to make a left turn#vehicles from either direction to make a right turn#vehicles from either direction to pass"},
{"testId":36,"qid":1559,"description":"When following another vehicle closely at night, you must employ:","explanation":"Dim your headlights to low brightness while following another vehicle closely or when an incoming vehicle is approaching to prevent distracting or blinding the other driver.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#37950#38986","text":"High-beam headlights#Low-beam headlights#emergency lights#fog lights"},
{"testId":36,"qid":1560,"description":"If an approaching car is using its high lights, you should look _________ until the vehicle has past.","explanation":"If an incoming car has its high-beam headlights turned on, avoid staring at the lights. To avoid being briefly blinded, keep your gaze on the right side of the road. Follow the line on the right side of the road.","imageName":"202006041857066362.jpg","correctAnswer":35842,"isBookmarked":0,"aid":"35840#35842#35843#38987","text":"toward the left edge of the road#toward the right edge of the road#at the center of the road#at the driver of the vehicle"},
{"testId":36,"qid":1561,"description":"What should you do if the rear of your vehicle begins to skid on an icy road?","explanation":null,"imageName":"","correctAnswer":37948,"isBookmarked":0,"aid":"37946#37947#37948#37949","text":"Turn the steering wheel toward the left#Turn the steering wheel in the opposite direction of the skid#Turn the steering wheel in the direction of the skid#Turn the steering wheel toward the right"},
{"testId":36,"qid":1562,"description":"What does the following signal at an intersection indicates?","explanation":"Pedestrian signals are solely used to direct pedestrian traffic. This pedestrian signal indicates that people may enter the crosswalk to cross the road. (Older signals displayed the word \"WALK\" instead.) A signal with an upraised hand warns pedestrians not to enter the crosswalk. (Older signals had the words \"DO NOT WALK\" instead.)","imageName":"202004071614175768.jpg","correctAnswer":28681,"isBookmarked":0,"aid":"19606#28681#38988#38989","text":"Drivers must slow down and proceed with caution#Pedestrians may enter the crosswalk#Pedestrians may not enter the crosswalk#Pedestrians to wait until the light turns green"},
{"testId":36,"qid":1563,"description":"What should you do if you miss your exit on an interstate highway?","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Such behavior is prohibited in Ohio. Instead, take the next exit and figure out how to return from there.","imageName":"202007210432373628.jpg","correctAnswer":33386,"isBookmarked":0,"aid":"24257#24258#32570#33386","text":"Stop and ask for help#Back up to reach your exit#Make a U-turn to reach your exit#Take the next exit"},
{"testId":36,"qid":1564,"description":"Children under the age of _______ or weighing less than 40 pounds are required to use an authorized child safety seat under Ohio law.","explanation":"Infants and children under the age of four and weighing less than 40 pounds must be securely secured in an authorized child safety seat when traveling in a motor vehicle.","imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#5000#5001#5002","text":"Four#Six#eight#Five"},
{"testId":36,"qid":1565,"description":"When you can't see objects _________ ahead clearly, you must use your headlights.","explanation":"When inclement weather or inadequate light prevents you from seeing things 1,000 feet ahead clearly, you must activate your headlights. (A thousand feet corresponds to roughly two-tenths of a mile.) You must also use your headlights between the hours of sunset and dawn, as well as while using your windshield wipers.","imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"4432#4433#4434#4435","text":"1,000 feet#2,000 feet#3,000 feet#4,000 feet"},
{"testId":36,"qid":1566,"description":"This symbol denotes:","explanation":"This construction or work zone sign indicates the presence of a flag person (flagger) ahead. Flaggers are often seen in highway or street repair zones. To direct traffic safely through these places, they utilize red flags or STOP/SLOW paddles. You must obey the flag person's instructions.","imageName":"202002101220385703.jpg","correctAnswer":30570,"isBookmarked":0,"aid":"9072#24373#30570#31299","text":"a rest area ahead#a police officer ahead#a flag person (flagger) ahead#a work crew ahead"},
{"testId":36,"qid":1567,"description":"Which of the following is true about lines on the pavement?","explanation":"Passing is permitted on broken yellow lines. Passing is banned when there are solid yellow lines.","imageName":"","correctAnswer":38991,"isBookmarked":0,"aid":"38990#38991#38992#38993","text":"Solid yellow lines allow passing; broken yellow lines prohibit passing#Broken yellow lines allow passing; solid yellow lines prohibit passing#Both broken yellow lines and solid yellow lines prohibit passing#Both broken yellow lines and solid yellow lines allow passing"},
{"testId":36,"qid":1568,"description":"Which of the following claims concerning fog driving is TRUE?","explanation":"Fog is one of the most dangerous driving situations a driver may encounter. Reduce your speed and increase your following distance. Turn off your headlights. High beams' strong light may reflect off precipitation or fog, causing glare. Close your window and turn off the radio. You might hear an approaching vehicle before you see it.","imageName":"","correctAnswer":38996,"isBookmarked":0,"aid":"15493#38994#38995#38996","text":"Slow down#Drive with your window open#Maintain a greater following distance#Use your high-beam headlights"},
{"testId":36,"qid":1569,"description":"You reach the end of the acceleration lane While getting onto a freeway. You should be traveling:","explanation":"When you approach the end of a highway on-ramp, you should be moving as near to highway traffic speed as practicable, weather and ramp conditions allowing.","imageName":"","correctAnswer":22613,"isBookmarked":0,"aid":"22613#22614#22615#30266","text":"as close as possible to the speed of traffic#at 30 miles per hour#at 50 miles per hour#at a speed less than the speed of traffic"},
{"testId":36,"qid":1570,"description":"What is the meaning of this sign?","explanation":"You have entered a school zone, as indicated by this sign. Schoolchildren may be crossing the road ahead, so slow down and take extra precautions while they are there. In Ohio, the speed restriction in a school zone is 20 mph during recess and while students are entering or leaving school.","imageName":"202003151742324658.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1252#1348#1945#38744","text":"A school zone ahead#A playground ahead#A pedestrian crosswalk ahead#A public library ahead"},
{"testId":44,"qid":1571,"description":"If a school bus is flashing red lights alternately, you must:","explanation":null,"imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"6515#8535#8955#40836","text":"Increase your speed#Reduce your speed#stop#pass it with caution"},
{"testId":44,"qid":1573,"description":"Both day and night, ________ may be used in construction zones to direct vehicles into certain traffic lanes.","explanation":"Sequencing or large flashing Arrow panels may be used in work zones both during the day and at night to direct cars into certain traffic lanes and to notify them that a portion of the road or street ahead is blocked.","imageName":"","correctAnswer":5021,"isBookmarked":0,"aid":"5018#5019#5021#37411","text":"Flag persons#Barricades#Large flashing or sequencing arrow panels#Railroad crossing signals"},
{"testId":44,"qid":1574,"description":"This symbol denotes:","explanation":"This sign denotes the presence of a shared central turn lane (also known as a two-way left-turn lane) in the middle of the road. This lane is for left turns only and may be used by cars moving in either direction. A solid yellow line and a broken yellow line designate the lane on either side. This lane should never be utilized for passing or navigating through traffic. Use care in this lane since cars heading in the other way may be utilizing it as well. [Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers; Table 21: Regulatory and Warning Signs]","imageName":"202003301721209480.jpg","correctAnswer":33043,"isBookmarked":0,"aid":"19386#33043#33044#33045","text":"a two-way road#a two-way left-turn lane#a two-way right-turn lane#two-way merging traffic"},
{"testId":44,"qid":1575,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":null,"imageName":"","correctAnswer":40837,"isBookmarked":0,"aid":"22434#22545#40837#40838","text":"increase your speed and clear the lane#stop immediately wherever you are#pull over to the right edge of the road if possible#pull over to the left edge of the road if possible"},
{"testId":44,"qid":1576,"description":"In which of the following scenarios is driving on the left side of the road NOT appropriate?","explanation":"Except when passing, turning left, or avoiding a barrier on the right side of the road, you should normally remain to the right. When there are two or more traffic lanes in each direction, when pavement markings restrict driving on the left, or when you are within 100 feet of an intersection, railroad crossing, bridge, viaduct, or tunnel, never drive on the left side of the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#5027#40839#40840","text":"All of the above#When there are two or more traffic lanes in each direction#When you're within 100 feet of an intersection#When pavement markings prohibit it"},
{"testId":44,"qid":1577,"description":"In Texas, you must park your vehicle within ______ of the curb or edge of the road when you park it parallel.","explanation":"In Texas, parallel parking must be done within 18 inches of the curb or edge of the highway.","imageName":"","correctAnswer":4921,"isBookmarked":0,"aid":"815#4921#5420#10045","text":"15 inches#18 inches#50 inches#10 inches"},
{"testId":44,"qid":1578,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the split roadway is coming to an end ahead. The road will be converted to a two-way street. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#19179#22656#30218","text":"The divided highway ends ahead#Traffic is merging ahead#A divided highway starts ahead#There is a traffic island ahead"},
{"testId":47,"qid":1578,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the split roadway is coming to an end ahead. The road will be converted to a two-way street. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#19179#22656#30218","text":"The divided highway ends ahead#Traffic is merging ahead#A divided highway starts ahead#There is a traffic island ahead"},
{"testId":44,"qid":1579,"description":"You can avoid highway hypnosis while driving on the highway by:","explanation":"Highway hypnosis is a state of lethargy or unawareness caused by monotony, wind and tire noises on the road, and the continuous hum of the motor. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right. Continue to read traffic signs and check your mirrors. Cell phone conversations may be hazardous.","imageName":"","correctAnswer":35876,"isBookmarked":0,"aid":"30976#35875#35876#40678","text":"talking on your cell phone#taking stimulants#shifting your eyes frequently#listening to loud music"},
{"testId":44,"qid":1580,"description":"When approaching a railroad crossing with a train, you must stop your vehicle ________ from the nearest rail.","explanation":"When approaching a railroad crossing when a train is coming, Texas law requires you to stop between 15 and 50 feet from the closest rail.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#40841#40842#40843","text":"between 15 and 50 feet#between 5 and 25 feet#between 5 and 40 feet#between 15 and 30 feet"},
{"testId":44,"qid":1581,"description":"In most driving circumstances, if your vehicle has airbags, you should use_____to turn the steering wheel.","explanation":"The hand-over-hand method and the push-pull (also known as hand-to-hand) method are two prevalent strategies for turning the steering wheel. When using push-pull steering, one hand pushes the wheel up and the other hand pulls the wheel down. As a result, unlike with hand-over-hand steering, your hands never cross over the steering wheel. If you have your hands in front of the steering wheel when the airbag deploys, it might drive your hands into your face or potentially damage them. Still, hand-over-hand steering may help you recover from a skid or park your car. Use one-handed steering only when you must remove one hand from the steering wheel to handle controls such as headlights or windshield wipers.","imageName":"","correctAnswer":2598,"isBookmarked":0,"aid":"2598#5046#5047#5048","text":"the push-pull technique#the push-off technique#the one-hand technique#the no-hands technique"},
{"testId":44,"qid":1582,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22423#22424#22426#40844","text":"A gravel road is ahead#A winding road is ahead#The road ahead is slippery when wet#A steep downgrade is ahead"},
{"testId":44,"qid":1583,"description":"In the event that a tire blows out, you should:","explanation":"Don't slam on the brakes if you have a flat tire or a blowout. Instead, remove your foot off the accelerator pedal, grip the steering wheel tightly, and maintain your car straight while gently slowing down. Only gradually brake after regaining control of the car.","imageName":"","correctAnswer":35845,"isBookmarked":0,"aid":"6475#24167#29078#35845","text":"Speed up#Shift gears#Brake hard#keep your vehicle going straight"},
{"testId":44,"qid":1584,"description":"You must drive through a roundabout:","explanation":"A roundabout is a circular crossroads where traffic travels counterclockwise around an island in the center. Keep to the right of the middle island at the roundabout.","imageName":"","correctAnswer":37373,"isBookmarked":0,"aid":"29718#37372#37373#40845","text":"in a clockwise direction#on the left side of the island#on the right side of the island#toward the center of the island"},
{"testId":44,"qid":1585,"description":"When driving at night, keep your headlights on low beam whenever you are:","explanation":null,"imageName":"","correctAnswer":24131,"isBookmarked":0,"aid":"24131#29706#40846#40847","text":"In all of the above situations#within 500 feet of an oncoming vehicle#within 300 feet of a vehicle you are following#driving on a well-lit road"},
{"testId":44,"qid":1586,"description":"What is the meaning of this sign?","explanation":"This sign alerts you that you are about to approach a T-intersection from the terminating road. You must turn left or right at the T-intersection after ceding the right-of-way to through traffic if required.","imageName":"202004071610214952.jpg","correctAnswer":40848,"isBookmarked":0,"aid":"23490#27808#40848#40849","text":"A Y-intersection is ahead#The road is closed ahead#A T-intersection is ahead#The rails of a narrow bridge are ahead"},
{"testId":44,"qid":1588,"description":"When driving faster than the other vehicles on a motorway, which lane should you use?","explanation":"If you want to travel quicker than the rest of the traffic on a motorway, stay in the center or left lane.","imageName":"","correctAnswer":27130,"isBookmarked":0,"aid":"6624#8641#8891#27130","text":"Any lane#The right lane#The shoulder#The middle or left lane"},
{"testId":44,"qid":1589,"description":"The areas of the road that you cannot see in your mirrors are referred to as:","explanation":"Blind spots are portions of the road that are hidden from view by your side mirrors. You might need to turn your head and look over your shoulder to see these areas. Big blind areas, sometimes known as \"No-Zones,\" are common in large vehicles like buses and trucks. Drive as little as possible in a huge vehicle's No-Zones.","imageName":"","correctAnswer":9136,"isBookmarked":0,"aid":"9136#23949#33254#39709","text":"blind spots#invisible spots#special zones#blank spots"},
{"testId":44,"qid":1590,"description":"What is the meaning of this sign?","explanation":"This sign warns that the bridge's roadway ahead is small. Slow down and take your time.","imageName":"202002101245562248.jpg","correctAnswer":1527,"isBookmarked":0,"aid":"1527#1701#3176#5072","text":"A narrow bridge ahead#A railroad crossing ahead#A one-way road ahead#An underpass ahead"},
{"testId":44,"qid":1591,"description":"What is the legal limit for blood alcohol concentration (BAC) for drivers who are 21 years or older?","explanation":"A person is deemed legally inebriated in Texas if his or her blood alcohol content (BAC) is 0.08 percent or above. Driving while drunk may be levied against a motorist who is proven to be legally inebriated (DWI).","imageName":"","correctAnswer":40853,"isBookmarked":0,"aid":"40850#40851#40852#40853","text":"Below 0.12%#Below 0.10%#Below 0.09%#Below 0.08%"},
{"testId":44,"qid":1592,"description":"An typical passenger car moving at 55 mph has a stopping distance of approximately:","explanation":"Stopping a truck takes longer than stopping a vehicle driving at the same speed. A passenger automobile moving at 55 mph may come to a halt in around 240 feet. A fully laden tractor-trailer, on the other hand, may need more than 400 feet to come to a complete stop. (A football field's length, including the two end zones, is 360 feet.) Keep this in mind if a truck is tailgating you.","imageName":"","correctAnswer":40855,"isBookmarked":0,"aid":"40854#40855#40856#40857","text":"164 feet#240 feet#303 feet#387 feet"},
{"testId":44,"qid":1593,"description":"Unless otherwise indicated, the speed limit in metropolitan areas of Texas is:","explanation":"Unless otherwise marked, the speed limit in metropolitan areas in Texas is 30 mph.","imageName":"","correctAnswer":521,"isBookmarked":0,"aid":"521#522#1745#2135","text":"30 mph#25 mph#40 mph#50 mph"},
{"testId":44,"qid":1594,"description":"What is the meaning of this sign?","explanation":"This sign denotes a substantial drop from the pavement's edge to the shoulder. Take extreme precautions not to fall off the pavement. Slow down and steer forcefully if you must leave the roadway for the shoulder.","imageName":"202002101217083259.jpg","correctAnswer":30267,"isBookmarked":0,"aid":"29116#29117#29118#30267","text":"A bump in the road#Slippery conditions on the road#A hard-surfaced pavement#A significant drop from the pavement edge to the shoulder"},
{"testId":44,"qid":1595,"description":"When a vehicle's tires lose contact with the road and float above the water, this is called :","explanation":"When it rains or the road is wet, the lower grip may cause your tires to ride up on the water like water skis as you accelerate. This is referred to as hydroplaning. On wet roads, go no faster than 35 mph to prevent this problem.","imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8584#8693#40858","text":"Hydroplaning#tailgating#weaving#tunnel vision"},
{"testId":44,"qid":1596,"description":"In Texas, who must wear safety belts or the right kind of child restraints in a passenger vehicle?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#26667#34838#40859","text":"The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers#The driver, front-seat passengers, and rear-seat passengers under 17"},
{"testId":44,"qid":1597,"description":"A pennant-shaped symbol means:","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that the distance ahead is so short that overtaking other cars is banned. The sign is situated on the left side of the road and reads \"NO PASSING ZONE\" in black characters on a yellow backdrop.","imageName":"202003151738521917.jpg","correctAnswer":8482,"isBookmarked":0,"aid":"8482#23902#40860#40861","text":"a no-passing zone#a four-way stop#an emergency stop#you must keep right"},
{"testId":44,"qid":1599,"description":"When descending a steep slope, you should:","explanation":null,"imageName":"","correctAnswer":21302,"isBookmarked":0,"aid":"8222#21302#32178#35893","text":"Shift into a higher gear#Shift into a lower gear#turn on your hazard flashers#keep applying your brakes"},
{"testId":44,"qid":1600,"description":"Maintain a following distance of at least _______ behind the vehicle ahead of you when driving at speeds more than 30 mph.","explanation":"You should keep a trailing distance of at least two seconds behind the car in front of you while travelling at speeds of 30 mph or less. You should maintain a following distance of at least four seconds at speeds greater than 30 mph.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#427#428#985","text":"4 seconds#5 seconds#6 seconds#10 seconds"},
{"testId":44,"qid":1601,"description":"In Texas, headlights are required one half hour after dusk until:","explanation":"Headlights are required in Texas from one-half hour after dusk to one-half hour before dawn.","imageName":"","correctAnswer":40867,"isBookmarked":0,"aid":"40864#40865#40866#40867","text":"two hours before sunrise#one hour before sunrise#one-and-a-half hours before sunrise#one half hour before sunrise"},
{"testId":44,"qid":1602,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to come to a halt and surrender. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"1585#2021#3572#3686","text":"A traffic signal ahead#A stop sign ahead#A work zone ahead#A closed road ahead"},
{"testId":44,"qid":1604,"description":"When approaching a crossroads with no traffic signs or signals, you must:","explanation":"When approaching a junction with no traffic signals or signs, you must yield to cars on the right before proceeding.","imageName":"","correctAnswer":8813,"isBookmarked":0,"aid":"8812#8813#8814#9181","text":"Yield to vehicles on the left#Yield to vehicles on the right#Yield to heavy vehicles#slow down and proceed with caution"},
{"testId":44,"qid":1605,"description":"If you wish to turn in Texas, you must signal for at least ________ before turning.","explanation":"When you wish to turn, explain your purpose ahead of time. If you plan to turn in Texas, you must signal continuously for at least 100 feet before turning. Make sure your turn signal is turned off once you turn. (Note: At greater speeds, you should signal for a longer period of time. At 40 mph, a car will move 100 feet in 1.7 seconds, giving the driver little early notice.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#400#401#1296","text":"100 feet#300 feet#400 feet#500 feet"},
{"testId":44,"qid":1607,"description":"If you are traveling at night in fog or severe rain, use:","explanation":"Dim your headlights to low beam if you're traveling at night in fog, severe rain, sleet, snow, or dust. High beams' strong light may bounce back from dust or precipitation, causing glare.","imageName":"","correctAnswer":40870,"isBookmarked":0,"aid":"40870#40871#40872#40873","text":"your low-beam headlights#your hazard flashers#your high-beam headlights#your parking lights"},
{"testId":44,"qid":1608,"description":"According to Texas law, you may not park your vehicle:","explanation":"A vehicle may not be parked, stopped, or stood on a sidewalk, inside an intersection, or in a crosswalk. (There are several more sites where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.)","imageName":"","correctAnswer":34732,"isBookmarked":0,"aid":"8785#28246#34732#40874","text":"on a sidewalk#within an intersection#in any of the above locations#in a crosswalk"},
{"testId":44,"qid":1609,"description":"To reduce glare from oncoming headlights, avoid looking straight at the lights of the approaching vehicle. Instead, shift your eyes:","explanation":"An incoming vehicle's bright lights might be blinding. If an incoming vehicle's headlights have not been muted, slow down, stay right, look away from the oncoming car, and transfer your gaze down to the lower right side of your lane until the vehicle has past.","imageName":"","correctAnswer":40877,"isBookmarked":0,"aid":"40875#40876#40877#40878","text":"toward the driver of the oncoming vehicle#down to the lower left side of your lane#down to the lower right side of your lane#toward the center of your lane"},
{"testId":44,"qid":1610,"description":"This symbol indicates that:","explanation":"This sign bears the international emblem of impaired access. The sign indicates that it is forbidden to park, halt, or stand in a handicapped parking place unless your vehicle displays a disabled license plate or windshield identification card.","imageName":"202003151732554338.jpg","correctAnswer":40879,"isBookmarked":0,"aid":"40879#40880#40881#40882","text":"this place is reserved for persons with disabilities#this place is reserved for hospital parking#this place is reserved for elderly people#this place is reserved for unloading passengers"},
{"testId":46,"qid":1612,"description":"When you want to turn left, start indicating ________ ahead of time to inform other drivers.","explanation":null,"imageName":"","correctAnswer":5135,"isBookmarked":0,"aid":"1788#4835#5135#5136","text":"1 to 2 seconds#2 to 3 seconds#3 to 5 seconds#10 to 12 seconds"},
{"testId":46,"qid":1613,"description":"When approaching a railroad crossing,you must stop at least _______ away from the nearest rail.","explanation":null,"imageName":"","correctAnswer":8416,"isBookmarked":0,"aid":"1026#1027#1028#8416","text":"50 feet#25 feet#10 feet#15 feet"},
{"testId":46,"qid":1614,"description":"What is the meaning of this sign?","explanation":"This sign indicates that a roundabout is approaching. A roundabout is a circular crossroads where traffic travels counterclockwise around an island in the center. Roundabouts are designed to decrease traffic accidents, delays, fuel use, and air pollution.","imageName":"202004180803429786.jpg","correctAnswer":3800,"isBookmarked":0,"aid":"1525#3572#3800#5141","text":"A curved road ahead#A work zone ahead#A roundabout ahead#An interstate traffic merging area ahead"},
{"testId":46,"qid":1616,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"When approaching a school bus with flashing red lights on an undivided route, you must come to a full stop, regardless of which direction the bus is heading. You must halt until the bus driver switches off the flashing red lights.","imageName":"","correctAnswer":34196,"isBookmarked":0,"aid":"34195#34196#41003#41004","text":"Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in#Slow down and pass the bus with caution#Stop and then pass the bus at 15 mph or less"},
{"testId":46,"qid":1617,"description":"When visibility is low, such as when there is fog, severe rain, or snow, you should use your:","explanation":null,"imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#35389#41024","text":"High-beam headlights#Low-beam headlights#interior lights#hazard lights"},
{"testId":46,"qid":1619,"description":"In poor driving conditions, maintain a following distance of:","explanation":"When driving, the Vermont Driver's Manual suggests keeping a following distance of at least four seconds behind the car in front of you. However, in bad weather or at faster speeds, you should extend your following distance to more than four seconds.","imageName":"","correctAnswer":41122,"isBookmarked":0,"aid":"32925#41120#41121#41122","text":"One second#more than two seconds#less than three seconds#more than four seconds"},
{"testId":46,"qid":1620,"description":"Vermont law requires you to turn on your headlights whenever you cannot see people or vehicles :","explanation":null,"imageName":"","correctAnswer":41125,"isBookmarked":0,"aid":"41123#41124#41125#41126","text":"200 feet ahead#300 feet ahead#500 feet ahead#600 feet ahead"},
{"testId":46,"qid":1621,"description":"What should you do to get back on track after a skid?","explanation":null,"imageName":"","correctAnswer":39954,"isBookmarked":0,"aid":"39954#39955#39956#39957","text":"Ease your foot off the gas and carefully steer in the direction in which you want your vehicle to go#Ease your foot off the gas and carefully steer opposite to the direction in which you want your vehicle to go#Brake immediately and carefully steer in the direction in which you want your vehicle to go#Brake immediately and carefully steer opposite to the direction in which you want your vehicle to go"},
{"testId":46,"qid":1622,"description":"This symbol denotes:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8663,"isBookmarked":0,"aid":"8663#41127#41128#41129","text":"a railroad crossing ahead#road repair work ahead#a curve on the right ahead#a rural road ahead"},
{"testId":46,"qid":1623,"description":"What should you do if you miss your exit on a freeway?","explanation":"Don't stop, back up, or attempt to turn around if you miss your exit on a motorway. This dramatically increases the possibility of a collision. Instead, take the next exit and figure out how to return from there.","imageName":"202007210432373628.jpg","correctAnswer":23315,"isBookmarked":0,"aid":"23315#24257#24258#32570","text":"Go on to the next exit#Stop and ask for help#Back up to reach your exit#Make a U-turn to reach your exit"},
{"testId":46,"qid":1624,"description":"Which occupants of a passenger vehicle are required by Vermont law to wear safety belts or suitable child restraints?","explanation":null,"imageName":"","correctAnswer":35474,"isBookmarked":0,"aid":"4420#35472#35474#37935","text":"Only the driver#All front-seat occupants#All occupants#All rear-seat occupants"},
{"testId":46,"qid":1625,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are vast zones around trucks and other big vehicles where automobiles may slip into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. There are no-zones in front, back, and sides of the enormous truck. No-Zones significantly enhance the chance of a collision.","imageName":"","correctAnswer":236,"isBookmarked":0,"aid":"236#24370#24371#41130","text":"No-Zones#Blind-Zones#Side-Zones#Big Zones"},
{"testId":46,"qid":1628,"description":"You should face _____ to prevent being temporarily blinded by an oncoming vehicle's headlights.","explanation":"An incoming vehicle's bright lights might be blinding. Slow down, stay right, gaze away from the incoming car, and stare toward the right edge of the road until the vehicle has gone by to prevent being momentarily blinded.","imageName":"","correctAnswer":10120,"isBookmarked":0,"aid":"10120#24945#41131#41132","text":"the right edge of the road#the center of the road#the left edge of the road#the driver of the oncoming vehicle"},
{"testId":46,"qid":1629,"description":"A car parked on a one-way street must be within _______ of the curb or the road's edge.","explanation":"When parking, keep your car within 12 inches of the curb or the side of the road.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#31214#39693","text":"12 inches#18 inches#30 inches#24 inches"},
{"testId":46,"qid":1630,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#40948","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This road is for trucks only"},
{"testId":40,"qid":1631,"description":"Which vehicle passengers are required by Rhode Island law to wear seat belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":35474,"isBookmarked":0,"aid":"4420#35472#35474#39944","text":"Only the driver#All front-seat occupants#All occupants#All rear-seat occupants under 18 and all front-seat occupants"},
{"testId":40,"qid":1632,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of perception distance (the distance your vehicle travels between when your eyes see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#35832#40663","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + braking distance#perception distance - reaction distance"},
{"testId":40,"qid":1633,"description":"On wet roads, ________ occurs when your front tires begin to ride on water rather than the road.","explanation":null,"imageName":"","correctAnswer":5202,"isBookmarked":0,"aid":"864#5202#5203#5204","text":"Tailgating#Hydroplaning#Weaving#Channelizing"},
{"testId":40,"qid":1634,"description":"Which kind of sign is this?","explanation":"Of course, this is a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe.","imageName":"202001301744345845.jpg","correctAnswer":2316,"isBookmarked":0,"aid":"2316#2317#29816#35219","text":"A stop sign#A yield sign#A Do Not Enter sign#A One Way sign"},
{"testId":40,"qid":1635,"description":"Which of the following things is NOT TRUE about turnabouts?","explanation":"Turnabouts are moves that require you to reverse your car and travel in the other direction. A turnabout is not permitted within 200 feet of any junction. Although No U-Turn signs ban U-turns, other sorts of turnabouts may be permitted.","imageName":"","correctAnswer":39945,"isBookmarked":0,"aid":"39945#39946#39947#39948","text":"You can perform a turnabout within 100 feet of an intersection#You cannot cross a double solid yellow line when you are performing a turnabout#You can perform some turnabouts even where a No U-Turn sign is posted#To perform a U-turn, you must have clear visibility for 500 feet in each direction"},
{"testId":40,"qid":1636,"description":"You must yield to the vehicle _____________ if they approach at an uncontrolled intersection about the same time as you.","explanation":"If additional cars arrive at an uncontrolled junction at the same time as you, you must give the right-of-way to the vehicle on your right.","imageName":"","correctAnswer":39951,"isBookmarked":0,"aid":"39949#39950#39951#39952","text":"on your left#with more passengers#on your right#with fewer passengers"},
{"testId":40,"qid":1637,"description":"You've parked on the side of the road, facing downhill. There is no curb. Which way should your front wheels be pointed?","explanation":"If you're parking on a slope without a curb, aim your front wheels toward the road's edge. If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque.","imageName":"","correctAnswer":39492,"isBookmarked":0,"aid":"9906#25321#39491#39492","text":"Straight#In any direction#Away from the edge of the road#Toward the edge of the road"},
{"testId":40,"qid":1638,"description":"What is the meaning of this sign?","explanation":"This warning sign advises that the right lane is coming to an end ahead. All vehicles in the right lane must merge to the left.","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"28070#31641#39730#39953","text":"The right lane ends ahead#The left lane ends ahead#You are in an acceleration lane#Do not enter the left lane"},
{"testId":40,"qid":1639,"description":"When parking on a two-way road, your vehicle must be parked within _______ of the curb or on the right side of the road.","explanation":"When parking on a two-way street, keep your vehicle's right wheels within 12 inches of the curb or the right side of the road.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#31214#39693","text":"12 inches#18 inches#30 inches#24 inches"},
{"testId":40,"qid":1640,"description":"To pass other vehicles on a multilane highway, you must use:","explanation":"Rhode Island law mandates you to utilize the left lane to pass and the right lane for regular driving on multilane highways.","imageName":"","correctAnswer":1634,"isBookmarked":0,"aid":"1634#2201#2855#3554","text":"the left lane#the right lane#any lane#The shoulder"},
{"testId":40,"qid":1641,"description":"What should you do to get back on track after a skid?","explanation":null,"imageName":"","correctAnswer":39954,"isBookmarked":0,"aid":"39954#39955#39956#39957","text":"Ease your foot off the gas and carefully steer in the direction in which you want your vehicle to go#Ease your foot off the gas and carefully steer opposite to the direction in which you want your vehicle to go#Brake immediately and carefully steer in the direction in which you want your vehicle to go#Brake immediately and carefully steer opposite to the direction in which you want your vehicle to go"},
{"testId":40,"qid":1643,"description":"The spaces around your vehicle where you can't see other vehicles in your mirrors are called:","explanation":"Blind spots are places that are not visible in your rear-view or side mirrors. Every vehicle has blind zones. The only method to inspect these spots is to glance over your shoulder and gaze straight at them.","imageName":"","correctAnswer":9136,"isBookmarked":0,"aid":"9136#39709#39958#39959","text":"blind spots#blank spots#invisible zones#zero zones"},
{"testId":45,"qid":1643,"description":"The spaces around your vehicle where you can't see other vehicles in your mirrors are called:","explanation":"Blind spots are places that are not visible in your rear-view or side mirrors. Every vehicle has blind zones. The only method to inspect these spots is to glance over your shoulder and gaze straight at them.","imageName":"","correctAnswer":9136,"isBookmarked":0,"aid":"9136#39709#39958#39959","text":"blind spots#blank spots#invisible zones#zero zones"},
{"testId":40,"qid":1644,"description":"In which of the following circumstances is it permissible to pass a vehicle on the right?","explanation":"In Rhode Island, you may pass a vehicle on the right if (1) the vehicle is making or about to make a left turn, or (2) there are two or more lanes of traffic traveling in the same direction as you. (This may occur on a multilane highway or a one-way street.) Never drive over a hill or over a bridge.","imageName":"","correctAnswer":33056,"isBookmarked":0,"aid":"33055#33056#39338#39339","text":"When the vehicle is making or about to make a right turn#When the vehicle is making or about to make a left turn#On a hill or curve#When you're entering a tunnel or bridge"},
{"testId":40,"qid":1645,"description":"When you're driving on a freeway, and you feel like you're going slower than you really are, this is called:","explanation":"Anti-lock brakes are mandatory on vehicles manufactured after 1998. In addition, they contain a yellow ABS malfunction light.","imageName":"","correctAnswer":9096,"isBookmarked":0,"aid":"8583#8584#8694#9096","text":"Hydroplaning#tailgating#highway hypnosis#velocitizing"},
{"testId":40,"qid":1646,"description":"This symbol denotes:","explanation":"This work zone sign indicates that there will be a flagperson (flagger) ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through certain locations, they wear orange vests, shirts, or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":39960,"isBookmarked":0,"aid":"8895#8896#24373#39960","text":"a school zone ahead#a hitchhiker ahead#a police officer ahead#a flagperson ahead"},
{"testId":40,"qid":1647,"description":"________ on a freeway lets a driver departing the freeway to slow down to a safe pace for the exit ramp.","explanation":"A deceleration lane on a motorway leads to an exit ramp. To depart the motorway, enter a deceleration lane and reduce your speed to a safe level for the exit ramp.","imageName":"","correctAnswer":2547,"isBookmarked":0,"aid":"2545#2547#3554#3680","text":"An acceleration lane#A deceleration lane#The shoulder#A merging lane"},
{"testId":40,"qid":1648,"description":"Most roadways in Rhode Island have speed limit of:","explanation":null,"imageName":"","correctAnswer":39964,"isBookmarked":0,"aid":"39961#39962#39963#39964","text":"30 鈥 45 mph#40 鈥 50 mph#70 鈥 75 mph#50 鈥 65 mph"},
{"testId":40,"qid":1649,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":null,"imageName":"","correctAnswer":30566,"isBookmarked":0,"aid":"22434#30566#38506#39965","text":"increase your speed and clear the lane#pull over to the right and stop#pull over to the left and stop#stop immediately in your lane"},
{"testId":40,"qid":1650,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Check your brakes, drive slowly, and keep an eye on your speed. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":39706,"isBookmarked":0,"aid":"71#33093#39706#39966","text":"A slippery road ahead#Heavy trucks ahead#A steep descent ahead#An emergency truck escape ramp ahead"},
{"testId":40,"qid":1651,"description":"Which of these is not one of the four ways to turn things around?","explanation":"There is no such thing as a four-point turn method. The other three options, as well as the three-point turn, are all viable turnabout procedures. (A two-point turn is when you turn around by backing into a driveway on the right side.)","imageName":"","correctAnswer":5253,"isBookmarked":0,"aid":"5253#5254#5255#39967","text":"A four-point turn#A U-turn#Backing into a driveway on the right side#Pulling into a driveway on the left side"},
{"testId":40,"qid":1652,"description":"You cannot stop or park your car in Rhode Island:","explanation":"You are not permitted to park your car inside the following areas in Rhode Island: (1) within 8 feet of a fire hydrant; (2) within 50 feet of the closest rail of a railroad crossing; or (3) within 20 feet of a crosswalk at an intersection. (There are also additional areas where parking is prohibited.) Look for any signs or road markings that ban or limit parking.)","imageName":"","correctAnswer":34732,"isBookmarked":0,"aid":"34732#39968#39969#39970","text":"in any of the above locations#within 20 feet of a crosswalk at an intersection#within 8 feet of a fire hydrant#within 50 feet of the nearest rail of a railroad crossing"},
{"testId":40,"qid":1653,"description":"Which of the following is a FALSE statement about road markings?","explanation":"Lanes of traffic flowing in opposing directions are separated by yellow lines. A broken yellow line, a solid yellow line, a double solid yellow line, or a broken yellow line beside a solid yellow line may divide the lanes. If it is safe to do so, you may cross a broken yellow line on your side to pass or change lanes.","imageName":"","correctAnswer":39973,"isBookmarked":0,"aid":"39971#39972#39973#39974","text":"White lines separate lanes of traffic traveling in the same direction#A broken line on your side allows passing#Yellow lines separate lanes of traffic traveling in the same direction#A solid line on your side prohibits passing"},
{"testId":40,"qid":1655,"description":"What should you do if you miss your exit on a highway?","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Instead, take the next exit and figure out how to return from there.","imageName":"202007210432373628.jpg","correctAnswer":23315,"isBookmarked":0,"aid":"23315#24258#32570#39975","text":"Go on to the next exit#Back up to reach your exit#Make a U-turn to reach your exit#Pull over and ask for help"},
{"testId":40,"qid":1656,"description":"What's the left-turn hand signal?","explanation":"The proper hand signal for a left turn is straight out left hand and arm, hand and arm motionless.","imageName":"","correctAnswer":39976,"isBookmarked":0,"aid":"39976#39977#39978#39979","text":"Left hand and arm straight out, keeping hand and arm still#Left hand and arm up, keeping hand and arm still#Right hand and arm up, keeping hand and arm still#Left hand and arm down, keeping hand and arm still"},
{"testId":40,"qid":1657,"description":"A roundabout rotates traffic:","explanation":"Traffic flows counterclockwise around a central island in a roundabout. When entering or exiting a roundabout, keep to the right.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"64#26066#29718#29719","text":"toward the left#toward the center#in a clockwise direction#in a counterclockwise direction"},
{"testId":40,"qid":1658,"description":"A flashing red signal must be treated like:","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so.","imageName":"202003051722347868.jpg","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#19269#35393","text":"a stop sign#a yield sign#a Do Not Enter sign#a One Way sign"},
{"testId":40,"qid":1659,"description":"A steady yellow traffic signal at an intersection signifies that drivers must :","explanation":"A continuous yellow traffic light signifies that the signal is soon to turn red. If it is safe to do so, stop. However, don't come to a complete stop and risk being rear-ended by the car in front of you. If you are unable to stop safely, go cautiously into the junction, execute the move you meant to conduct there, then clear the intersection. Never accelerate to \"beat the light\" before it turns red.","imageName":"","correctAnswer":31298,"isBookmarked":0,"aid":"9181#30295#31298#34895","text":"slow down and proceed with caution#stop, yield to pedestrians, and proceed with caution#stop if you can do so safely#increase your speed to get past the yellow light before it turns red"},
{"testId":42,"qid":1659,"description":"A steady yellow traffic signal at an intersection signifies that drivers must :","explanation":"A continuous yellow traffic light signifies that the signal is soon to turn red. If it is safe to do so, stop. However, don't come to a complete stop and risk being rear-ended by the car in front of you. If you are unable to stop safely, go cautiously into the junction, execute the move you meant to conduct there, then clear the intersection. Never accelerate to \"beat the light\" before it turns red.","imageName":"","correctAnswer":31298,"isBookmarked":0,"aid":"9181#30295#31298#34895","text":"slow down and proceed with caution#stop, yield to pedestrians, and proceed with caution#stop if you can do so safely#increase your speed to get past the yellow light before it turns red"},
{"testId":40,"qid":1661,"description":"What is the legal limit for blood alcohol concentration (BAC) for drivers who are 21 years or older?","explanation":"A motorist of any age who has a blood alcohol content (BAC) of 0.08 percent or more is deemed legally inebriated in Rhode Island and may be prosecuted with Driving Under the Influence (DUI). If you are convicted of DUI, the consequences will vary based on how high your BAC was at the time you were tested.","imageName":"","correctAnswer":38260,"isBookmarked":0,"aid":"38260#39980#39981#39982","text":"Less than 0.08%#Less than 0.18%#Less than 0.10%#Less than 0.09%"},
{"testId":40,"qid":1662,"description":"What is the meaning of this sign?","explanation":"This sign denotes a traffic island or other obstructive feature. You must drive on both sides.","imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"19403#30927#32664#39106","text":"Traffic may flow on both sides#Two-way traffic starts at the sign#There is a Y-intersection ahead#You may pass a vehicle on either side"},
{"testId":40,"qid":1663,"description":"If an approaching car is using its high lights, you should look _________ until the vehicle has past.","explanation":"If an incoming car has its high-beam headlights turned on, avoid staring at the lights. To avoid being briefly blinded, look toward the right edge of the road. Steer by the right edge or the fog line (the white line on the right side of most highways) until the car passes and your eyesight returns to normal.","imageName":"202006041857066362.jpg","correctAnswer":39985,"isBookmarked":0,"aid":"38987#39983#39984#39985","text":"at the driver of the vehicle#toward the left edge of the roadway#in your rear-view mirror#toward the right edge of the roadway"},
{"testId":41,"qid":1663,"description":"If an approaching car is using its high lights, you should look _________ until the vehicle has past.","explanation":"If an incoming car has its high-beam headlights turned on, avoid staring at the lights. To avoid being briefly blinded, look toward the right edge of the road. Steer by the right edge or the fog line (the white line on the right side of most highways) until the car passes and your eyesight returns to normal.","imageName":"202006041857066362.jpg","correctAnswer":39985,"isBookmarked":0,"aid":"38987#39983#39984#39985","text":"at the driver of the vehicle#toward the left edge of the roadway#in your rear-view mirror#toward the right edge of the roadway"},
{"testId":40,"qid":1664,"description":"To maintain a safe following distance behind the vehicle in front of you, the Rhode Island Driver's Licensing Handbook suggests using:","explanation":"The \"three-second rule\" is suggested by the Rhode Island Driver's Manual so that you may safely avoid risks in front of you: Keep a three-second following distance from the car in front of you. By noting when the car in front of you passes a stationary item and then calculating the seconds until you get to the same thing, you may calculate your following distance. Increase your following distance if the road is risky or in poor condition.","imageName":"","correctAnswer":30202,"isBookmarked":0,"aid":"30201#30202#30203#30204","text":"the four-second rule#the three-second rule#the two-second rule#the one-second rule"},
{"testId":40,"qid":1666,"description":"This symbol denotes:","explanation":"This sign indicates an exit number. This is the number allocated to a highway exit at a junction. If an interchange has more than one exit, a letter may be appended to designate which exit it is: 117A, 117B, and so on.","imageName":"202004142357034407.jpg","correctAnswer":39986,"isBookmarked":0,"aid":"8626#39986#39987#39988","text":"None of the above#exit number 117 is ahead#the next exit is 117 miles away#you must take this exit to enter route 117"},
{"testId":40,"qid":1667,"description":"In Rhode Island, whenever you desire to turn, you must start to signal the turn at least ______ in advance.","explanation":"When turning in Rhode Island, you must begin signaling the turn at least 100 feet in advance. You may be cited and punished if you do not comply.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#2292#4926#5297","text":"100 feet#60 feet#80 feet#35 feet"},
{"testId":40,"qid":1668,"description":"An uncontrolled intersection must be treated as:","explanation":"Uncontrolled junctions are those that lack signs or signals. In Rhode Island, an uncontrolled junction must be treated as if it had a yield sign. That is, you must yield to pedestrians in the crosswalk, cars already in the junction, and vehicles that came earlier than you.","imageName":"","correctAnswer":39990,"isBookmarked":0,"aid":"39989#39990#39991#39992","text":"a controlled intersection#if there were a yield sign present#if there were a stop sign present#if there were a traffic island present"},
{"testId":40,"qid":1669,"description":"If there is an oncoming vehicle in your lane, you should _____ to avoid a head-on accident.","explanation":"Steer right toward the shoulder or curb-line to avoid a head-on collision. Do not turn left; the other car may attempt to return to the correct lane. To escape a head-on accident, be prepared to drive completely off the road to the right. Accidents involving a head-on collision are often deadly.","imageName":"202006121435309851.jpg","correctAnswer":24535,"isBookmarked":0,"aid":"6515#8575#8576#24535","text":"Increase your speed#keep your steering wheel straight#steer left toward the median#steer right toward the shoulder or curb-line"},
{"testId":40,"qid":1670,"description":"This sign indicates a designated parking spot for:","explanation":"This sign incorporates the worldwide emblem of accessibility for people with impairments. The sign designates a parking spot reserved for cars with a handicapped parking permit.","imageName":"202003151732554338.jpg","correctAnswer":31996,"isBookmarked":0,"aid":"19625#31996#34134#38202","text":"patients#people with disabilities#seniors#bicyclists"},
{"testId":45,"qid":1671,"description":"On a two-lane road, you come upon a school bus with alternating flashing red light signals. What are your options?","explanation":"If you approach a school bus on a two-lane road with alternating flashing red light signals, you must come to a full stop immediately before approaching the bus. Continue to stand still until the flashing red lights go off.","imageName":"","correctAnswer":30227,"isBookmarked":0,"aid":"30227#41065#41066#41067","text":"Come to a complete stop#Pass the bus at the same speed#Pass the bus at increased speed#Reduce your speed and pass the bus slowly"},
{"testId":45,"qid":1673,"description":"Highway hypnosis, a state of tiredness or unawareness, can result from freeway driving. This condition can be avoided by:","explanation":"Highway hypnosis is a state of tiredness or unawareness caused by monotony, the noises of the wind and tires hitting the pavement, and the continuous hum of the motor. Avoid the scenario by shifting your eyes from one side of the road to the other and focusing on a variety of stuff near and far, left and right. You may also try looking at the landscape as long as it doesn't distract you from driving. Long durations of mobile phone use may be hazardous.","imageName":"","correctAnswer":35876,"isBookmarked":0,"aid":"35875#35876#40892#41068","text":"taking stimulants#shifting your eyes frequently#talking frequently on your cell phone#driving faster to reach your destination sooner"},
{"testId":45,"qid":1674,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":28656,"isBookmarked":0,"aid":"28656#29880#41069#41070","text":"You must come to a complete stop#You must not enter the road ahead#You must come to a complete stop only if pedestrians or approaching vehicles are present#You must stop or slow down to less than 5 mph"},
{"testId":45,"qid":1675,"description":"What must you do when you get close to a stopped emergency vehicle with flashing lights?","explanation":"When approaching a stopped emergency vehicle with flashing lights, you must lower your speed, leave as much distance as feasible to the emergency vehicle, and if practical and it is safe to do so, perform a lane change into a lane away from the emergency vehicle.","imageName":"","correctAnswer":35047,"isBookmarked":0,"aid":"35047#38441#40026#42945","text":"Slow down; also move into a non-adjacent lane if possible#Speed up and pass the emergency vehicle as quickly as possible#Pull over and stop behind the emergency vehicle#Stop in your lane and wait until the lights stop flashing"},
{"testId":45,"qid":1677,"description":"In聽Utah,聽it聽is聽advised聽that聽you聽maintain聽a聽minimum聽following聽distance聽of聽_________聽behind聽the聽car聽in聽front聽of聽you.","explanation":"Tailgating is a common cause of rear-end crashes (following the vehicle ahead too closely). According to the Utah Driver Handbook, you should maintain a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance even more at night or in dangerous driving situations.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":45,"qid":1678,"description":"What is the meaning of this sign?","explanation":"This symbol denotes a service. It suggests that phone service will be available shortly.","imageName":"202003151712528763.jpg","correctAnswer":19388,"isBookmarked":0,"aid":"19355#19388#19389#41135","text":"A hospital is ahead#Telephone service is available ahead#A gas station is ahead#A camping area is ahead"},
{"testId":45,"qid":1680,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"A train cannot give you the right-of-way. It may take more than a mile for a train running at 60 mph to come to a full stop. As a result, if your car becomes stuck on a railroad track while a train is coming, don't waste time attempting to remove it. Instead, get everyone out of your car, including yourself, and walk at a 45-degree angle to the tracks in the direction the train is coming. If your car is hit, you and your passengers will be protected from flying debris. Call 911 or the number shown on the Emergency Notification System (ENS) sign.","imageName":"202007210447482035.jpg","correctAnswer":40279,"isBookmarked":0,"aid":"38596#38965#40278#40279","text":"Try to signal the approaching train#Try to restart your vehicle#Ask other drivers to help move your vehicle#Get everyone out of your vehicle"},
{"testId":45,"qid":1681,"description":"When approaching an intersection with broken traffic signals, you must first _________ before entering the intersection and then cede the right-of-way.","explanation":"In Utah, a broken traffic signal must be treated as a stop sign. That is, you must stop, yield to pedestrians and other cars, and advance only when it is safe to do so (with great care). If none of the junction's lights are functional, regard the intersection as an all-way stop.","imageName":"","correctAnswer":590,"isBookmarked":0,"aid":"125#590#1455#4610","text":"increase your speed#come to a complete stop#reduce your speed#turn on your emergency flashers"},
{"testId":45,"qid":1682,"description":"What is the meaning of this sign?","explanation":"This sign alerts you that traffic from the right lane is going to merge into your lane. Adjust your speed and location to enable merging cars to safely combine.","imageName":"202002101239016186.jpg","correctAnswer":3126,"isBookmarked":0,"aid":"3123#3124#3126#40731","text":"Merging from the left#The end of two-way traffic#Merging from the right#The start of one-way traffic"},
{"testId":45,"qid":1683,"description":"What should you do if you enter an intersection to turn left and the traffic signal turns yellow?","explanation":"A continuous yellow traffic light indicates that it is ready to turn red. Do not block the junction if you have already entered it. Execute the planned motion and safely clear the junction.","imageName":"202007210441576834.jpg","correctAnswer":15671,"isBookmarked":0,"aid":"85#15671#28692#40721","text":"Go straight#Turn left#Make a U-turn#Stop and wait for the light to turn green"},
{"testId":45,"qid":1684,"description":"When there are people working in a work zone (construction zone), the speeding fine in Utah is :","explanation":null,"imageName":"","correctAnswer":5340,"isBookmarked":0,"aid":"5340#41159#41160#41161","text":"doubled#increased by 50%#increased by $50#increased by $100"},
{"testId":45,"qid":1685,"description":"You must dim your headlights if you are within ______ of an oncoming vehicle, according to Utah law.","explanation":"You must lower your headlights if you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following, according to Utah law.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#400#401#1296","text":"100 feet#300 feet#400 feet#500 feet"},
{"testId":45,"qid":1687,"description":"What does a sign with black letters and orange background indicates?","explanation":"Work zone signs alert motorists to unusual or possibly hazardous situations on or around the traveled route. The lettering or symbols on these signs are black on an orange backdrop. Slow down and pay careful attention if you encounter these indicators.","imageName":"","correctAnswer":30334,"isBookmarked":0,"aid":"19547#24497#24498#30334","text":"A service sign#A regulatory sign#A guide sign#A work zone sign"},
{"testId":47,"qid":1687,"description":"What does a sign with black letters and orange background indicates?","explanation":"Work zone signs alert motorists to unusual or possibly hazardous situations on or around the traveled route. The lettering or symbols on these signs are black on an orange backdrop. Slow down and pay careful attention if you encounter these indicators.","imageName":"","correctAnswer":30334,"isBookmarked":0,"aid":"19547#24497#24498#30334","text":"A service sign#A regulatory sign#A guide sign#A work zone sign"},
{"testId":45,"qid":1688,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"6656#9713#14638#25321","text":"Parallel to the curb#Away from the curb#Toward the curb#In any direction"},
{"testId":45,"qid":1691,"description":"If an adult driver in Utah has a blood alcohol concentration of_____ or higher after December 30, 2018, they will be charged with driving under the influence (DUI).聽:","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3168#3282#4252#4253","text":"0.08%#0.06%#0.05%#0.03%"},
{"testId":45,"qid":1692,"description":"In the event that a tire blows out, you should:","explanation":"Don't slam on the brakes if you have a flat tire or a blowout. Instead, remove your foot off the accelerator pedal, grip the steering wheel tightly, and maintain your car straight while gently slowing down. Only gradually brake after regaining control of the car. Turn off your engine and activate your emergency flashers.","imageName":"","correctAnswer":35845,"isBookmarked":0,"aid":"6475#24167#29078#35845","text":"Speed up#Shift gears#Brake hard#keep your vehicle going straight"},
{"testId":45,"qid":1693,"description":"On wet roads, ________ occurs when your front tires begin to ride on water rather than the road.","explanation":null,"imageName":"","correctAnswer":5202,"isBookmarked":0,"aid":"864#5202#5203#5362","text":"Tailgating#Hydroplaning#Weaving#Velocitizing"},
{"testId":45,"qid":1694,"description":"This symbol denotes:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8663,"isBookmarked":0,"aid":"8663#41127#41128#41162","text":"a railroad crossing ahead#road repair work ahead#a curve on the right ahead#a rough road ahead"},
{"testId":45,"qid":1695,"description":"Which passengers in a passenger vehicle are required to use safety belts or child restraints in Utah?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#34838","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers"},
{"testId":45,"qid":1696,"description":"When you come to a complete stop at a railroad crossing, you must be at least ________ away from the nearest rail.","explanation":"If you must stop at a railroad crossing, you must do so between 15 and 50 feet from the closest rail.","imageName":"","correctAnswer":8416,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":45,"qid":1697,"description":"In Utah, when parallel parking a vehicle near a curb, the vehicle must be within_________ the curb.聽:","explanation":"When parallel parking near a curb, your car must be within 12 inches of the curb.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#18169#39693","text":"12 inches#18 inches#6 inches#24 inches"},
{"testId":45,"qid":1698,"description":"This symbol denotes:","explanation":"This work zone sign indicates that there will be a flagger ahead. Flaggers are often seen in highway or street construction zones. To direct traffic safely through certain locations, they wear orange vests, shirts, or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions.","imageName":"202002101220385703.jpg","correctAnswer":41163,"isBookmarked":0,"aid":"8895#38539#41163#41164","text":"a school zone ahead#a traffic officer ahead#a flagger ahead#a parking lot ahead"},
{"testId":45,"qid":1699,"description":"If two vehicles arrive at a intersection about the same time,:","explanation":"If you approach at a junction about the same time as other cars, you must surrender the right-of-way to the vehicle on the right.","imageName":"","correctAnswer":41167,"isBookmarked":0,"aid":"41165#41166#41167#41168","text":"the vehicle on the right shall yield the right-of-way to the vehicle on the left#the vehicle with fewer passengers shall yield the right-of-way to the vehicle with more passengers#the vehicle on the left shall yield the right-of-way to the vehicle on the right#the commercial vehicle shall yield the right-of-way to the passenger vehicle"},
{"testId":45,"qid":1700,"description":"If you can't see ________ ahead due to fog, bad weather, dust, or darkness, you must turn on your headlights in Utah.","explanation":null,"imageName":"","correctAnswer":4432,"isBookmarked":0,"aid":"399#1296#2780#4432","text":"200 feet#500 feet#700 feet#1,000 feet"},
{"testId":45,"qid":1702,"description":"What is the meaning of this sign?","explanation":"Mileposts are spaced at regular intervals so that vehicles are constantly aware of them. They are installed along the road's edge to provide drivers with information about their position on the highway for navigation and emergency reasons. The distance in miles to the state border or the end of the road is generally indicated by the number on the milepost.","imageName":"202004142317126205.jpg","correctAnswer":41170,"isBookmarked":0,"aid":"41169#41170#41171#41172","text":"You are on Route 444#You are 444 miles from the state line or the end of this road#You are approaching Exit 444#It indicates none of the above"},
{"testId":45,"qid":1703,"description":null,"explanation":"The rural interstate speed limits in Utah are now 75 mph and 80 mph.","imageName":"","correctAnswer":33729,"isBookmarked":0,"aid":"9971#14164#33671#33729","text":"65 mph#55 mph#75 mph#80 mph"},
{"testId":45,"qid":1704,"description":"You are driving on a two-lane road and need to go back the way you came. If you can't make a U-turn because the road is too narrow, you can make a:","explanation":"If a two-lane road is too narrow to do a U-turn, you may turn around by making a three-point turn. This move will also send you in the other direction. In Utah, you should only do a three-point turn on a small two-lane road with excellent visibility, little traffic on both sides of the road, and the turn is authorized.","imageName":"","correctAnswer":8879,"isBookmarked":0,"aid":"8879#8880#8999#41173","text":"a three-point turn#a four-point turn#a single-point turn#a single-point turn or a four-point turn"},
{"testId":45,"qid":1705,"description":"You've gone into the other lane on a two-lane road to pass another vehicle. Before any incoming vehicle comes within ______ of you, you must return to your previous lane.","explanation":null,"imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":45,"qid":1706,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151740461521.jpg","correctAnswer":41174,"isBookmarked":0,"aid":"1347#1348#1945#41174","text":"A library ahead#A playground ahead#A pedestrian crosswalk ahead#A school zone or school crosswalk ahead"},
{"testId":45,"qid":1708,"description":"Except as otherwise posted, the maximum speed limit in Utah's commercial and residential areas is:","explanation":null,"imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":45,"qid":1709,"description":"You must drive through a roundabout:","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Keep to the right of the center island in the roundabout and drive counterclockwise around it.","imageName":"","correctAnswer":29719,"isBookmarked":0,"aid":"29718#29719#40845#41175","text":"in a clockwise direction#in a counterclockwise direction#toward the center of the island#toward the left side of the island"},
{"testId":48,"qid":1711,"description":"Passing another vehicle takes about _________ at 55 mph.","explanation":null,"imageName":"","correctAnswer":5100,"isBookmarked":0,"aid":"1287#4097#5100#5400","text":"two seconds#five seconds#ten seconds#seven seconds"},
{"testId":48,"qid":1712,"description":"In the state of Washington, you may be arrested if your blood alcohol concentration (BAC) is ___ or above.","explanation":"If you are caught driving with a blood alcohol concentration (BAC) of 0.08 percent or more in Washington State, you may be arrested and prosecuted with driving under the influence (DUI). If you are under the age of 21, you may be arrested for a BAC of 0.02 percent or more.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":48,"qid":1713,"description":"When responding to a fire alarm, you must not follow a fire truck any closer than:","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":48,"qid":1714,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a crossroads or four-way junction ahead. Another route intersects the one you're on. Slow down and be cautious for cross traffic.","imageName":"202002101146277756.jpg","correctAnswer":4963,"isBookmarked":0,"aid":"1584#1701#4963#41142","text":"A hospital ahead#A railroad crossing ahead#A crossroad ahead#A T-intersection ahead"},
{"testId":48,"qid":1715,"description":"At speeds more than 30 mph, the Washington Driver Guide suggests using _____ to maintain a safe following distance behind the vehicle ahead of you.","explanation":"At speeds greater than 30 mph, the Washington Driver Guide suggests using the four-second rule to maintain a safe following distance behind the vehicle ahead of you. Allow at least four seconds of following space behind the car in front of you. (A three-second following distance may be acceptable at speeds of 30 mph or less.) Increase your following distance even further under more risky driving circumstances.","imageName":"","correctAnswer":2173,"isBookmarked":0,"aid":"2170#2172#2173#3041","text":"the three-second rule#the two-second rule#the four-second rule#the one-second rule"},
{"testId":48,"qid":1717,"description":"On two-way roads, _________ designate the left margin of one-way roads and divide opposing traffic lanes.","explanation":"On one-way and split highways, solid yellow lines indicate the left border, dividing opposing lanes of traffic on two-way roadways.","imageName":"","correctAnswer":5410,"isBookmarked":0,"aid":"5408#5410#41362#41363","text":"Solid white lines#Solid yellow lines#Dashed white lines#Dashed yellow lines"},
{"testId":48,"qid":1718,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to come to a halt and surrender. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2515#3572#41364","text":"A stop sign ahead#A yield sign ahead#A work zone ahead#A merge sign ahead"},
{"testId":48,"qid":1719,"description":"When following another vehicle within 300 feet at night in Washington State, you must use :","explanation":"Under Washington State law, you must dim your headlights to low beam whenever you're within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following. High beams can blind the driver of the vehicle you are following by reflecting off the vehicle's mirrors.","imageName":"","correctAnswer":25951,"isBookmarked":0,"aid":"296#25950#25951#30262","text":"parking lights#High beams#Low beams#Emergency flashers"},
{"testId":48,"qid":1720,"description":"Keep _____ on the steering wheel, especially during turns.","explanation":"Quick turns are made easier by keeping your hands on opposing sides of the steering wheel. Place your left hand between 8 and 9 o'clock and your right hand between 3 and 4 o'clock while driving a contemporary car equipped with airbags. When the driver's side airbag deploys, it may push your hands towards your face or potentially harm them if they are higher than that. This posture also enables you to perform maneuvers while keeping your hands on the steering wheel.","imageName":"","correctAnswer":41366,"isBookmarked":0,"aid":"41365#41366#41367#41368","text":"your left hand between 10 and 11 o'clock and your right hand between 1 and 2 o'clock#your left hand between 8 and 9 o'clock and your right hand between 3 and 4 o'clock#your left hand between 9 and 10 o'clock and your right hand between 5 and 6 o'clock#your left hand between 9 and 10 o'clock and your right hand between 3 and 4 o'clock"},
{"testId":48,"qid":1721,"description":"Unless otherwise indicated, the maximum speed limit on Washington State roadways is :","explanation":"Unless otherwise indicated, the maximum speed restriction on Washington State roadways is 60 mph. Some rural interstate routes have a speed restriction of 70 miles per hour.","imageName":"","correctAnswer":683,"isBookmarked":0,"aid":"682#683#1745#2135","text":"55 mph#60 mph#40 mph#50 mph"},
{"testId":48,"qid":1722,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. A separator or median will be used to split opposing lanes. Continue straight.","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#26319#41369","text":"The divided highway ends ahead#A divided highway starts ahead#The right lane is closed ahead#The road curves here"},
{"testId":48,"qid":1723,"description":"In Washington State, you must park within ____ of the curb when you parallel park.","explanation":"Under Washington State law, when you parallel park, you must park within 12 inches of the curb.","imageName":"","correctAnswer":32858,"isBookmarked":0,"aid":"32855#32858#41370#41371","text":"18 inches#12 inches#6 inches#24 inches"},
{"testId":48,"qid":1724,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"","correctAnswer":34196,"isBookmarked":0,"aid":"28148#33169#34196#34928","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":48,"qid":1725,"description":"If you encounter a pedestrian using a guiding dog or holding a white cane at a junction, you must:","explanation":null,"imageName":"","correctAnswer":8589,"isBookmarked":0,"aid":"8586#8587#8588#8589","text":"use your horn to alert the pedestrian#reduce your speed and pass slowly#switch on your headlights to alert the pedestrian#stop your vehicle"},
{"testId":48,"qid":1726,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for falling vehicles like trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":5537,"isBookmarked":0,"aid":"4903#5537#33093#41372","text":"A bump ahead#A downgrade ahead#Heavy trucks ahead#A truck escape ramp ahead"},
{"testId":48,"qid":1727,"description":"A solid white line separating traffic lanes indicates that you should:","explanation":"A strong white line between traffic lanes indicates that you should remain in your lane unless there is an emergency that compels you to change lanes.","imageName":"202004281851466397.jpg","correctAnswer":26052,"isBookmarked":0,"aid":"8955#8990#26052#26053","text":"stop#make a right turn#stay in your lane#not stay in your lane"},
{"testId":48,"qid":1728,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill close to a curb, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"6656#9713#14638#35158","text":"Parallel to the curb#Away from the curb#Toward the curb#Toward the right"},
{"testId":48,"qid":1729,"description":"On some roads, lanes labeled \"Transit\" are designated as:","explanation":"One or more lanes on certain roads may be designated for special vehicles. Reserved lanes are identified by signs indicating that the lane is for special use, as well as a white diamond displayed on the side of the road or painted on the concrete surface. The words \"Transit\" or \"Bus\" indicate that the lane is solely for bus usage.","imageName":"","correctAnswer":22507,"isBookmarked":0,"aid":"22504#22505#22507#26255","text":"bicycles only#trucks only#buses only#high-occupancy vehicles (HOVs) only"},
{"testId":48,"qid":1730,"description":"This symbol denotes:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8663,"isBookmarked":0,"aid":"8663#19532#22299#41127","text":"a railroad crossing ahead#a narrow road ahead#a right turn ahead#road repair work ahead"},
{"testId":48,"qid":1731,"description":"A flashing red signal must be treated like:","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so.","imageName":"","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#19269#22617","text":"a stop sign#a yield sign#a Do Not Enter sign#a merge sign"},
{"testId":48,"qid":1732,"description":"Which occupants of a passenger vehicle in Washington State are required to wear safety belts or proper child restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#34769#37513","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#The driver and passengers under 18"},
{"testId":48,"qid":1733,"description":"If an approaching car does not dim its headlights, you should look toward:","explanation":"An incoming vehicle's bright lights might be blinding. Slow down, maintain right, gaze away from the incoming car, and glance toward the right edge of the road to remain on course until the vehicle has gone by.","imageName":"","correctAnswer":10120,"isBookmarked":0,"aid":"10120#24945#41131#41132","text":"the right edge of the road#the center of the road#the left edge of the road#the driver of the oncoming vehicle"},
{"testId":48,"qid":1735,"description":"In the event that a tire blows out, you must not:","explanation":"If you have a blowout, keep your hands on the steering wheel, drive straight ahead, and gradually take your foot off the gas pedal. If you stop too forcefully, you may lock the wheels and lose control. Only mildly brake after regaining control of the car. Pull over to the side of the road at the closest safe spot.","imageName":"","correctAnswer":29078,"isBookmarked":0,"aid":"26193#29078#41373#41374","text":"hold the steering wheel tightly#Brake hard#keep the vehicle going straight#slowly ease your foot off the gas pedal"},
{"testId":50,"qid":1736,"description":"If you come across a stopped school bus with its red lights flashing on your side of the road, you must stop at least _______ away.","explanation":"If you come across a stopped school bus with its red lights flashing on each side of the road in Wisconsin, you must stop at least 20 feet away. You must stay halted until either the bus driver switches off the red lights or the school bus starts its move. However, according to the Wisconsin Department of Transportation Motorist's Handbook, you should continue to wait until all pupils have safely off the highway.","imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":50,"qid":1737,"description":"Which of the following crossroads is regarded as the safest and most efficient?","explanation":"Roundabouts offer a more secure and efficient traffic flow than traditional junctions. Traffic continues to flow one-way in a circular manner, resulting in fewer conflict sites and smoother traffic flow.","imageName":"","correctAnswer":43706,"isBookmarked":0,"aid":"43705#43706#43707#43708","text":"Uncontrolled intersection#Roundabout#T-intersection#Blind intersection"},
{"testId":50,"qid":1738,"description":"What should you do if you miss your exit on a freeway?","explanation":"Don't stop, back up, or attempt to turn around if you miss your exit on a motorway. This dramatically increases the possibility of a collision. Instead, take the next exit and rejoin the motorway to return to the missed exit.","imageName":"202007210432373628.jpg","correctAnswer":34698,"isBookmarked":0,"aid":"24257#24258#32570#34698","text":"Stop and ask for help#Back up to reach your exit#Make a U-turn to reach your exit#Continue to the next exit"},
{"testId":50,"qid":1739,"description":"This symbol indicates the location of:","explanation":"White lettering or symbols are shown on a blue backdrop on service signs. This service sign directs you to a hospital service.","imageName":"202003151737026825.jpg","correctAnswer":22753,"isBookmarked":0,"aid":"22752#22753#22754#22755","text":"a hill area#A hospital service#an interstate highway#a handicapped parking area"},
{"testId":50,"qid":1740,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"If an emergency vehicle approaches you with its siren, air horn, or red or blue flashing light activated, you must move clear of any junction, yield to the emergency vehicle, pull over to the right side of the road, and stop, according to Wisconsin law. Pay attention to any instructions given over the emergency vehicle's loudspeaker. Otherwise, continue to stand still until the emergency vehicle has past.","imageName":"","correctAnswer":35215,"isBookmarked":0,"aid":"6476#22434#22544#35215","text":"Slow down#increase your speed and clear the lane#continue at the same speed#pull over to the right edge of the road and stop"},
{"testId":50,"qid":1741,"description":"The ________ is intended to be used on multilane roads to pass slower vehicles.","explanation":"The leftmost lane on multilane roadways is for overtaking slower cars. If you pass on the right, the other vehicle may not notice you and may abruptly change lanes in front of you.","imageName":"","correctAnswer":43709,"isBookmarked":0,"aid":"2393#5478#43709#43710","text":"shoulder#center lane#leftmost lane#rightmost lane"},
{"testId":50,"qid":1742,"description":"In Wisconsin, if you are driving in a roundabout and an emergency vehicle with a siren, air horn, or red or blue flashing lights approaches you, you must :","explanation":"Even if an emergency vehicle is coming, do not block a roundabout or any other sort of junction. If an emergency vehicle approaches you while driving around a roundabout, proceed to the next exit, then pull over and let the emergency vehicle pass. (Note: According to the Wisconsin Department of Transportation Motorists' Handbook, you may continue driving in the roundabout until you reach your chosen exit and then depart.) However, according to the official pamphlet \"What to Do When an... Emergency Vehicle Approaches a Roundabout,\" you must evacuate quickly (even if it is not your exit). [\"What to Do When an... Emergency Vehicle Approaches a Roundabout,\" Wisconsin Department of Transportation, https://wisconsindot.gov/Documents/safety/safety-eng/roundabouts/br-emergencyvehicles/br-emergencyvehicles/br-emergencyvehicles/br-emergencyvehicles/ .pdf]","imageName":"","correctAnswer":43711,"isBookmarked":0,"aid":"22415#42664#42994#43711","text":"stop in the middle of the roundabout#slow down, pull over to the right, and let the emergency vehicle pass#move into the center of the roadway and let the emergency vehicle pass#continue to the next exit, pull over, and let the emergency vehicle pass"},
{"testId":50,"qid":1743,"description":"This symbol warns of:","explanation":"This work zone sign shows that employees are undertaking road repair. Slow down, drive carefully, and change lanes whenever feasible to preserve a safe distance from the employees.","imageName":"202002101222518967.jpg","correctAnswer":43712,"isBookmarked":0,"aid":"24373#41163#43712#43713","text":"a police officer ahead#a flagger ahead#road workers ahead#a deep hole ahead"},
{"testId":50,"qid":1745,"description":"Except when passing, you should stay in the ________ lane on a road having two or more lanes traveling in the same direction.","explanation":null,"imageName":"","correctAnswer":3594,"isBookmarked":0,"aid":"3402#3594#5477#5478","text":"shoulder lane#right lane#left lane#center lane"},
{"testId":50,"qid":1746,"description":"If you encounter a pedestrian in the roadway with a guiding dog or a white cane, you must stop for at least ________ until the pedestrian is off the road.","explanation":null,"imageName":"","correctAnswer":5481,"isBookmarked":0,"aid":"5479#5480#5481#5482","text":"two feet away#five feet away#ten feet away#eight feet away"},
{"testId":50,"qid":1747,"description":"This symbol indicates that:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8526,"isBookmarked":0,"aid":"8526#30938#30939#30940","text":"there is a railroad crossing ahead#the road ahead is closed#the road ahead is under repair#there is a roundabout ahead"},
{"testId":50,"qid":1748,"description":"In Wisconsin, you must park within _____ of the curb when you parallel park.","explanation":"When parallel parking in Wisconsin, you must park within 12 inches of the curb.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#18169#39693","text":"12 inches#18 inches#6 inches#24 inches"},
{"testId":50,"qid":1749,"description":"Except where otherwise indicated, the speed limit in Wisconsin's suburban or semi-urban areas outside of cities is:","explanation":"Except when otherwise marked, the speed limit in Wisconsin is 35 mph in outlying areas of towns or villages or in semi-urban districts outside cities or villages.","imageName":"","correctAnswer":1676,"isBookmarked":0,"aid":"520#522#1676#1745","text":"15 mph#25 mph#35 mph#40 mph"},
{"testId":50,"qid":1750,"description":"When you're driving at night, you have to turn down your headlights when you're within _____ of a vehicle coming the other way.","explanation":"When you are within 500 feet of an incoming vehicle or a vehicle you are pursuing, Wisconsin law requires you to lower your headlights. One block is about 500 feet.","imageName":"","correctAnswer":5489,"isBookmarked":0,"aid":"5489#5490#5491#5492","text":"one block#three blocks#two blocks#four blocks"},
{"testId":50,"qid":1752,"description":"When driving in the city, you should look at least _____ ahead to avoid unexpected braking or turning.","explanation":"The Wisconsin Department of Transportation Motorists' Handbook suggests seeing at least 10 to 15 seconds ahead to minimize last-minute braking or the need to turn unexpectedly. Your car will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":5866,"isBookmarked":0,"aid":"5865#5866#39562#43714","text":"20 to 25 seconds#10 to 15 seconds#30 seconds#20 to 30 seconds"},
{"testId":50,"qid":1753,"description":"You have parked next to a curb on the right side of the street and are looking up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front tire rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6217#9906#25321","text":"Left#Right#Straight#In any direction"},
{"testId":50,"qid":1754,"description":"The appropriate hand and arm gesture for a left turn is:","explanation":"If your vehicle's turn signals have failed or are obscured by intense sunlight, use hand and arm gestures. The proper hand and arm signal for a left turn is a straight out left hand.","imageName":"","correctAnswer":43716,"isBookmarked":0,"aid":"43715#43716#43717#43718","text":"the left hand pointing upward#the left hand pointing straight out#the right hand pointing downward#the left hand pointing downward"},
{"testId":50,"qid":1755,"description":"What is the meaning of this sign?","explanation":"When children or school crossing guards are present, this sign advises cars not to exceed the official speed limit (15 mph as displayed on the sign). Except as otherwise indicated, the speed limit in school zones in Wisconsin is 15 mph.","imageName":"202004281803062534.jpg","correctAnswer":33767,"isBookmarked":0,"aid":"33767#33768#33769#33770","text":"The maximum allowable speed in a school zone when children are present#The minimum allowable speed in a school zone when children are present#The maximum allowable speed in a school zone at any time#The minimum allowable speed in a school zone at any time"},
{"testId":50,"qid":1756,"description":"In Wisconsin, a person under 21 may be arrested for driving with a blood alcohol content of 0.02 percent or more:","explanation":null,"imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"2242#5401#14075#35229","text":"0.03#0.04#0.01#Zero"},
{"testId":50,"qid":1757,"description":"On a wet road, you should slow down by about:","explanation":null,"imageName":"","correctAnswer":4,"isBookmarked":0,"aid":"4#14165#22200#22201","text":"10 mph#35 mph#15 mph#25 mph"},
{"testId":50,"qid":1758,"description":"When driving at 50 mph, it can take _________ to react to a hazard and bring your vehicle to a complete stop.","explanation":"If you're driving at 50 mph, it may take you 400 feet to respond to anything you notice and come to a complete stop. (A football field's length, including the two end zones, is 360 feet.) If you can't see 400 feet ahead, you won't be able to detect a threat and stop in time to escape it.","imageName":"","correctAnswer":5512,"isBookmarked":0,"aid":"5510#5511#5512#5513","text":"about 200 feet#about 300 feet#about 400 feet#about 100 feet"},
{"testId":50,"qid":1759,"description":"What is the meaning of this sign?","explanation":"On a regulatory sign, a red slash within a red circle denotes \"no.\" This sign says that U-turns are banned here.","imageName":"202003151744407638.jpg","correctAnswer":31591,"isBookmarked":0,"aid":"31590#31591#31849#34306","text":"You must not make a left turn#You must not make a U-turn#You must take a detour#You must not make a right turn"},
{"testId":50,"qid":1760,"description":"Which occupants of a motor vehicle are required by Wisconsin law to wear safety belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#26667#37513","text":"Just the driver#The driver and all passengers#The driver and front-seat passengers#The driver and passengers under 18"},
{"testId":50,"qid":1761,"description":"You should follow the ________ at night to avoid over-driving your headlights.","explanation":"The Wisconsin Department of Transportation Motorists' Handbook recommends the four-second sight distance limit for safe driving. Choose a stationary item that is as far ahead as you can see. Then time how long it takes you to reach that thing. If it takes you fewer than four seconds to get there, you're going too quickly and may not be able to stop in time to escape a danger that far ahead. This guideline should also be used at night to ensure that you are not \"over-driving\" your headlights.","imageName":"","correctAnswer":4880,"isBookmarked":0,"aid":"4880#4881#4882#4883","text":"the four-second sight distance rule#the three-second sight distance rule#the two-second sight distance rule#the one-second sight distance rule"},
{"testId":50,"qid":1762,"description":"If another vehicle is passing you while you are driving,:","explanation":"If another car passes you, remain in your lane and do not raise your speed. Allow the other car to pass safely.","imageName":"","correctAnswer":34108,"isBookmarked":0,"aid":"10578#26363#34108#34109","text":"Increase your speed#Stop#Stay in your lane and do not increase your speed#Move into the left lane"},
{"testId":50,"qid":1763,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":161,"isBookmarked":0,"aid":"161#28274#40895#40896","text":"A slow-moving vehicle#A high-occupancy vehicle#An electric personal assistive mobility device#An emergency vehicle"},
{"testId":50,"qid":1764,"description":"Before turning at a junction, you should signal at least _______ ahead of time.","explanation":"Before making a turn at an intersection, you should signal at least 100 feet before your intended turn.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#1026#1397","text":"100 feet#200 feet#50 feet#150 feet"},
{"testId":50,"qid":1765,"description":"If a vehicle is approaching as you pass a bicycle, you must:","explanation":"Sometimes you have to deal with risks one by one. If you're going to meet or pass a bicycle or motorbike and an incoming vehicle is approaching, slow down and let the vehicle pass first to give the bicycle or motorcycle more space. Then, move to the left to provide enough space to pass the bicycle or motorbike.","imageName":"202007151849158328.jpg","correctAnswer":30622,"isBookmarked":0,"aid":"30622#30623#30625#43719","text":"Slow down and let the vehicle pass first#Speed up and quickly pass the bicycle#Alert the oncoming vehicle to slow down#Stop your vehicle until the oncoming vehicle has passed you"},
{"testId":50,"qid":1766,"description":"If you're driving at 55 mph, you shouldn't utilize the opposing lane to pass another vehicle if you're close to_______ from a hill or bend.","explanation":null,"imageName":"","correctAnswer":5535,"isBookmarked":0,"aid":"5532#5533#5534#5535","text":"half a mile#one-fourth of a mile#one mile#one-third of a mile"},
{"testId":50,"qid":1769,"description":"When the drivers should use high-beam headlights at night?","explanation":"When there are no other cars around, use high beams. High lights provide double the visibility of low beams. Use high beams while driving on unknown routes, in construction zones, or when there may be people on the road. In fog, rain, or snow, however, utilize low lights. Light from high beams bounces off of such precipitation, causing glare.","imageName":"","correctAnswer":32511,"isBookmarked":0,"aid":"9170#9171#28788#32511","text":"On unfamiliar roads#In construction areas#Where there may be people alongside the roadway#In all of these situations"},
{"testId":50,"qid":1770,"description":"You should drive _____ on a wet road to avoid hydroplaning.","explanation":null,"imageName":"","correctAnswer":43721,"isBookmarked":0,"aid":"43720#43721#43722#43723","text":"faster than 50 mph#no faster than 35 mph#no faster than 50 mph#faster than 65 mph"},
{"testId":50,"qid":1771,"description":"What is the meaning of this sign?","explanation":"This notice indicates that deer often cross the road in this location. Deer are most active at night and early in the morning. Be on the lookout for deer and slow down if you spot one. Herds of deer, elk, and other species roam in groups. If you spot one, keep an eye out for others. A collision with a big animal has the potential to kill the animal, damage your car, and perhaps hurt someone in your vehicle.","imageName":"202002101200345356.jpg","correctAnswer":1237,"isBookmarked":0,"aid":"175#1237#40538#40539","text":"Zoo ahead#Deer crossing#Farm ahead#Animals prohibited ahead"},
{"testId":50,"qid":1772,"description":"In Wisconsin, you must use your headlights:","explanation":"Wisconsin law requires that your headlights be turned on from 30 minutes after nightfall to 30 minutes before dawn. When you can't see a person or vehicle on the road clearly from 500 feet away, turn on your headlights.","imageName":"","correctAnswer":26091,"isBookmarked":0,"aid":"26089#26090#26091#40705","text":"from 120 minutes after sunset to 120 minutes before sunrise#from 60 minutes after sunset to 60 minutes before sunrise#from 30 minutes after sunset to 30 minutes before sunrise#from 90 minutes after sunset to 90 minutes before sunrise"},
{"testId":50,"qid":1773,"description":"To exit a freeway or expressway, move into:","explanation":"To leave a freeway or interstate highway, enter a deceleration lane and begin slowing down. The exit ramp will be reached through the deceleration lane.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"23853#26083#26084#26085","text":"An acceleration lane#a deceleration lane#an entrance ramp#a merging area"},
{"testId":50,"qid":1774,"description":"Which of the following is NOT TRUE about driving on the highway?","explanation":"According to Wisconsin law, you must not drive so slowly that you hinder traffic. You may face a citation for blocking traffic. Some highways have posted minimum speed restrictions. Unless otherwise required by dangerous circumstances, you must not travel slower than the prescribed minimum speed. Wisconsin law also requires you to travel at a speed reasonable for the present driving circumstances. Even if you are not exceeding the official speed limit, you might be charged for driving too fast in dangerous circumstances.","imageName":"","correctAnswer":43724,"isBookmarked":0,"aid":"43724#43725#43726#43727","text":"You may drive at any speed that doesn't exceed the posted speed limit#Don't tailgate#You may not exceed the speed limit to pass another vehicle#You must reduce your speed under hazardous conditions"},
{"testId":50,"qid":1775,"description":"What does this blinking yellow light mean?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, look for cross traffic, and continue with care if you observe a flashing yellow light.","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#35286#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Stop and wait for the light to change#Yield as you would at a yield sign"},
{"testId":43,"qid":1776,"description":"Under perfect driving conditions, the stopping distance of a car traveling at 50 mph is approximately:","explanation":"When driving at 50 mph in perfect circumstances, your perception distance is around 37 feet, your response distance is approximately 55 feet, and your vehicle's braking distance is approximately 146 feet. As a result, your total stopping distance is 238 feet (37 + 55 + 146). This indicates you won't be able to stop in time to avert a danger if it happens fewer than 238 feet ahead.","imageName":"","correctAnswer":14313,"isBookmarked":0,"aid":"14311#14313#20795#40672","text":"100 feet#200 feet#400 feet#150 feet"},
{"testId":43,"qid":1777,"description":"To keep a safe distance behind the vehicle in front of you, use:","explanation":"The Tennessee Comprehensive Driver License Manual suggests using the \"two-second rule\" to keep a safe following distance behind the car ahead of you: Allow at least two seconds of following space behind the car in front of you. Increase your following distance while driving in bad or dangerous circumstances.","imageName":"","correctAnswer":30203,"isBookmarked":0,"aid":"30201#30202#30203#30204","text":"the four-second rule#the three-second rule#the two-second rule#the one-second rule"},
{"testId":43,"qid":1778,"description":"What should you do if you miss your exit on an interstate highway?","explanation":"Don't stop, back up, or attempt to turn around if you miss your exit on an interstate highway. This dramatically increases the possibility of a collision. Backing up or doing a U-turn on an interstate highway is prohibited. Instead, take the next exit and figure out how to return from there.","imageName":"202007210432373628.jpg","correctAnswer":24259,"isBookmarked":0,"aid":"24257#24258#24259#32570","text":"Stop and ask for help#Back up to reach your exit#Proceed to the next exit#Make a U-turn to reach your exit"},
{"testId":43,"qid":1779,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":874,"isBookmarked":0,"aid":"874#1700#15187#40673","text":"Railroad crossing ahead#Road repairs ahead#Roundabout ahead#Left and right lanes closed ahead"},
{"testId":43,"qid":1780,"description":"Two frequent forms of interchanges are:","explanation":"An interchange is a road junction where two or more roads cross over one another so as not to impede one another's traffic flow. Instead, the roads are connected through a series of connecting ramps. These ramps allow you to leave one road and enter another one safely, without disrupting the flow of traffic. Two common types of interchanges are cloverleaf and diamond. A disadvantage of cloverleaf interchanges is that vehicles entering the cloverleaf may have to merge with vehicles exiting the cloverleaf. The section of roadway where this occurs is known as a weave lane.","imageName":"","correctAnswer":40677,"isBookmarked":0,"aid":"40674#40675#40676#40677","text":"diamond and weave#rotary and weave#cloverleaf and weave#cloverleaf and diamond"},
{"testId":43,"qid":1781,"description":"You can avoid highway hypnosis on an interstate route by:","explanation":"Highway (interstate) hypnosis is a drowsy or unconscious state caused by monotony, the noises of the wind and tires on the road, and the continuous hum of the engine. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right. Talking on a mobile phone or often changing lanes might be hazardous.","imageName":"","correctAnswer":35876,"isBookmarked":0,"aid":"30636#30976#35876#40678","text":"changing lanes frequently#talking on your cell phone#shifting your eyes frequently#listening to loud music"},
{"testId":43,"qid":1782,"description":"When you are within ______ of a vehicle you are meeting or trailing at night in Tennessee, you must lower your headlights.","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#400#1296","text":"100 feet#200 feet#300 feet#500 feet"},
{"testId":43,"qid":1783,"description":"What is the meaning of this sign?","explanation":"A No Passing Zone sign signifies a no-passing zone for any vehicles driving in this direction (buses, automobiles, trucks, etc.). It is well marked on the left side of the road.","imageName":"202003151718184521.jpg","correctAnswer":19393,"isBookmarked":0,"aid":"19391#19392#19393#40679","text":"A no-passing zone for trucks#A no-passing zone for high-occupancy vehicles#A no-passing zone for all vehicles#A no-passing zone for four wheelers"},
{"testId":43,"qid":1784,"description":"A motorist who is at least 21 years old and has a blood alcohol content (BAC) of _____ or above is not allowed to operate a vehicle in Tennessee.","explanation":"Driving with a blood alcohol content (BAC) of 0.08 percent or above is unlawful in most states, including Tennessee. (0.08 percent signifies that a person's body has eight drops of alcohol for every 10,000 drops of blood.) It is prohibited in Tennessee for a motorist under the age of 21 to drive with a BAC of 0.02 percent or higher.","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318#27700","text":"0.05%#0.08%#0.02%#0.03%"},
{"testId":43,"qid":1785,"description":"If you observe a pedestrian with a guiding dog or a white cane, you must :","explanation":null,"imageName":"","correctAnswer":40035,"isBookmarked":0,"aid":"8587#8588#39697#40035","text":"reduce your speed and pass slowly#switch on your headlights to alert the pedestrian#sound your horn to alert the pedestrian#stop and yield to the pedestrian"},
{"testId":43,"qid":1786,"description":"If you want to go through an intersection with a turn, you should:","explanation":"If you want to turn beyond a junction, wait until you approach the intersection before signaling. If you signal too early, another car may mistakenly believe you are turning at the junction.","imageName":"202007210445346932.jpg","correctAnswer":40680,"isBookmarked":0,"aid":"22535#38226#38227#40680","text":"not give any turn signal#signal before you pass through the intersection#signal just before you make the turn#signal after you enter the intersection"},
{"testId":43,"qid":1787,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a four-way junction ahead. Another path will intersect yours. Slow down, take a peek to the left and right, and get ready to enter the crossroads.","imageName":"202002101146277756.jpg","correctAnswer":4963,"isBookmarked":0,"aid":"1584#1701#2021#4963","text":"A hospital ahead#A railroad crossing ahead#A stop sign ahead#A crossroad ahead"},
{"testId":43,"qid":1788,"description":"You must stop ________ from the nearest rail as a train approaches a railroad crossing.","explanation":"When approaching a railroad crossing, you must come to a complete stop between 15 and 50 feet from the closest rail.","imageName":"","correctAnswer":5592,"isBookmarked":0,"aid":"5592#5593#5594#5595","text":"between 15 and 50 feet#between 5 and 15 feet#between 50 and 100 feet#between 10 and 35 feet"},
{"testId":43,"qid":1789,"description":"A roundabout's speed limit is normally:","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. In most roundabouts, the speed limit is between 15 and 25 mph.","imageName":"","correctAnswer":40684,"isBookmarked":0,"aid":"40681#40682#40683#40684","text":"30 to 40 mph#20 to 35 mph#25 to 35 mph#15 to 25 mph"},
{"testId":43,"qid":1790,"description":"If an emergency vehicle with flashing red or blue lights and a siren approaches, you must:","explanation":"If an emergency vehicle approaches you with its siren or flashing lights on, you must yield to the emergency vehicle, pull over to the right edge or curb of the road, and stay stopped until the emergency vehicle passes or a police officer tells you to go, according to Tennessee law. However, if you are at an intersection and an emergency vehicle arrives, do not stop. Continue past the junction, then immediately turn right.","imageName":"","correctAnswer":34688,"isBookmarked":0,"aid":"6476#34688#38950#40685","text":"Slow down#pull over to the right#stop where you are#pull over to the left"},
{"testId":43,"qid":1791,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful brakes in rainy conditions. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22426#28685#29033#29834","text":"The road ahead is slippery when wet#There is a right turn ahead#There is a winding road ahead#There is a hill ahead"},
{"testId":43,"qid":1792,"description":"You parked parallel to the curb and faced downhill. Which way should your front wheels be pointed?","explanation":"When parallel parking downhill, keep your front wheels pointed toward the curb. If your brakes fail, the curb will prevent you from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse with a manual transmission).","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"6656#9713#14638#25321","text":"Parallel to the curb#Away from the curb#Toward the curb#In any direction"},
{"testId":43,"qid":1793,"description":"Keep _____ on the steering wheel, especially during turns.","explanation":"Quick turns are made easier by keeping your hands on opposing sides of the steering wheel. When driving a contemporary car equipped with airbags, put your left hand at 9 o'clock and your right hand around 3 o'clock. When the driver's side airbag deploys, it may push your hands towards your face or potentially harm them if they are higher than that.","imageName":"","correctAnswer":40689,"isBookmarked":0,"aid":"40686#40687#40688#40689","text":"your left hand at the 10 o'clock position and your right hand at the 2 o'clock position#your left hand at the 8 o'clock position and your right hand at the 2 o'clock position#your left hand at the 11 o'clock position and your right hand at the 4 o'clock position#your left hand at the 9 o'clock position and your right hand at the 3 o'clock position"},
{"testId":43,"qid":1794,"description":"The maximum speed limit on interstate routes in Tennessee is:","explanation":"Tennessee law sets the maximum speed limit for interstate roads in the state at 70 miles per hour. However, in crowded metropolitan areas, the speed restriction on certain interstate highways may be as low as 55 mph.","imageName":"","correctAnswer":23846,"isBookmarked":0,"aid":"20793#23846#24347#33729","text":"40 mph#70 mph#50 mph#80 mph"},
{"testId":43,"qid":1795,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#15946#35258#38872","text":"Slow-moving vehicle#Dead end#Work zone#Sharp curve or crest"},
{"testId":43,"qid":1796,"description":"If you intend to turn in Tennessee, you must begin signaling ________ before you turn.","explanation":"When you wish to turn, explain your purpose ahead of time. If you want to turn, Tennessee law requires you to begin signaling at least 50 feet before the turn. (Note: At the speeds that today's automobiles and roads are capable of, 50 feet may not be sufficient. At 40 mph, a car will move 50 feet in less than one second, giving the driver little early notice. At greater speeds, you should begin signaling sooner.)","imageName":"","correctAnswer":2027,"isBookmarked":0,"aid":"2027#31203#31204#40690","text":"at least 50 feet#at least 20 feet#at least 30 feet#at least 40 feet"},
{"testId":43,"qid":1798,"description":"You must stop on an undivided road for a school bus that has stopped with its red lights flashing and its stop arm extended. If you are found to have failed to do so, you will be penalized:","explanation":"You must stop for a school bus that has stopped on an undivided road with its red lights flashing and its stop arm extended, according to Tennessee law. (The flashing red lights indicate that the school bus has come to a halt to pick up or drop off students.) If you are found to be in violation, you will be fined between $250 and $1,000. In addition, you will receive eight points on your driving record. The maximum number of points you can receive for a single violation is eight. You will receive a letter informing you that your license may be suspended if you accumulate four more points within the next twelve months.","imageName":"","correctAnswer":40694,"isBookmarked":0,"aid":"40691#40692#40693#40694","text":"between $100 and $250#between $150 and $800#between $250 and $500#between $250 and $1,000"},
{"testId":43,"qid":1799,"description":"What is the meaning of this sign?","explanation":"This warning sign advises that another lane of traffic is about to merge into your highway. Be ready for other cars to enter your lane. Change lanes if feasible to provide merging cars a clear route. If not, slow down and change your position to enable these cars to merge safely.","imageName":"202002101239016186.jpg","correctAnswer":5632,"isBookmarked":0,"aid":"3177#5631#5632#28614","text":"A right turn ahead#A curve on the right ahead#Traffic merging ahead#The end of a divided highway"},
{"testId":43,"qid":1800,"description":"When driving in severe fog, heavy rain, or snow during the day, you must turn on your :","explanation":"When driving in severe fog, heavy rain, or snow during the day, Tennessee law requires you to turn on your low-beam headlights. High beams often allow you to see farther than low beams. High lights, on the other hand, may reflect off thick precipitation and generate glare.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"22155#22156#35389#37950","text":"High-beam headlights#Low-beam headlights#interior lights#emergency lights"},
{"testId":43,"qid":1801,"description":"If your vehicle begins to slide, turn the steering wheel:","explanation":"If you begin to skid, be cool, take your foot off the accelerator pedal, and slowly guide your vehicle in the direction you want the front of your vehicle to travel. Avoid using your brakes. Overshooting might cause your car to slide in the other direction. To avoid this, move the steering wheel in the other direction as soon as your car begins to straighten out.","imageName":"","correctAnswer":39298,"isBookmarked":0,"aid":"62#64#39297#39298","text":"toward the right#toward the left#opposite to the direction in which you want your vehicle to go#in the direction in which you want your vehicle to go"},
{"testId":43,"qid":1802,"description":"On a split highway, ________ denotes the left border of traffic lanes heading in the same direction as you.","explanation":"A solid white line on a split highway denotes the right boundary of traffic lanes heading in your direction. The left boundary of the traffic lanes is indicated by a solid or broken yellow line (i.e., between the leftmost lane and the divider).","imageName":"","correctAnswer":9106,"isBookmarked":0,"aid":"9103#9104#9105#9106","text":"A red line#a solid or broken white line#A black line#a solid or broken yellow line"},
{"testId":43,"qid":1803,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island, median, or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"8873#19246#22420#22422","text":"The divided highway ends ahead#Keep right#A blind curve is ahead#A sharp right turn is ahead"},
{"testId":43,"qid":1806,"description":"To enter an interstate highway, accelerate and merge with the fast-moving traffic already on the interstate using:","explanation":"Enter an interstate highway via an exit ramp. The on-ramp (entrance ramp) will lead to an acceleration lane. Before merging with interstate traffic, increase your speed in this lane to match it.","imageName":"","correctAnswer":2545,"isBookmarked":0,"aid":"2545#2547#3554#4002","text":"An acceleration lane#A deceleration lane#The shoulder#an exit ramp"},
{"testId":43,"qid":1807,"description":"What is the meaning of this sign?","explanation":"This sign incorporates the worldwide emblem of accessibility for people with impairments. It is displayed at a parking area to signify that the space is reserved for handicapped people to use. To park there, you must have a handicapped or disabled veteran license plate or a disability parking sticker.","imageName":"202003151732554338.jpg","correctAnswer":40695,"isBookmarked":0,"aid":"4774#5649#5650#40695","text":"A hospital service#A wheelchair facility#A pedestrian crosswalk#A parking space for the disabled"},
{"testId":43,"qid":1808,"description":"Which passengers in a vehicle must wear seat belts or appropriate child restraints if a driver has a learner permit or an intermediate limited driver license?","explanation":"All passengers of your vehicle, including yourself, must wear seat belts or appropriate child restraints if you have a learner permit or an intermediate limited driver license.","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#26667#34838","text":"Just the driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers"},
{"testId":43,"qid":1809,"description":"Unless otherwise posted, the speed limit on state and federal roads that are not part of the interstate highway system is:","explanation":"Except when otherwise marked, the speed limit on state and federal roads that are not part of the interstate highway system is 55 mph.","imageName":"","correctAnswer":682,"isBookmarked":0,"aid":"682#684#1677#3852","text":"55 mph#65 mph#45 mph#75 mph"},
{"testId":43,"qid":1810,"description":"If another driver attempts to pass you on your left, you should:","explanation":"Stay in your lane, maintain your speed, and shift to the right if another motorist attempts to pass you on your left.","imageName":"","correctAnswer":40697,"isBookmarked":0,"aid":"40696#40697#40698#40699","text":"move to the left, maintain your speed, and allow the driver to pass on your right#move to the right, maintain your speed, and allow the driver to pass on your left#stop and allow the driver to pass on your left#stop and allow the driver to pass on your right"},
{"testId":43,"qid":1811,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to come to a halt and surrender. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2515#3572#38981","text":"A stop sign ahead#A yield sign ahead#A work zone ahead#A Do Not Enter sign ahead"},
{"testId":43,"qid":1812,"description":"At an uncontrolled intersection, two vehicles coming from different directions may arrive at the same moment. Then:","explanation":"When two cars arrive at an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right.","imageName":"","correctAnswer":33286,"isBookmarked":0,"aid":"33286#33287#40700#40701","text":"the vehicle on the left must yield to the vehicle on the right#the vehicle on the right must yield to the vehicle on the left#the heavier vehicle must yield to the other vehicle#the vehicle with more passengers must yield to the other vehicle"},
{"testId":43,"qid":1813,"description":"You are NOT permitted to make a U-turn:","explanation":"A U-turn is not permitted on an interstate highway, between city junctions, or at an intersection controlled by a police officer or traffic lights. Furthermore, you are not permitted to perform a U-turn anyplace where you cannot observe traffic in both ways for at least 500 feet in each direction.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#40702#40703#40704","text":"In any of the above situations#at an intersection controlled by a police officer or traffic lights#when visibility is less than 500 feet in either direction#on an interstate highway"},
{"testId":43,"qid":1814,"description":"In Tennessee, _______, your headlights must be on.","explanation":"Tennessee law requires that your headlights be switched on from 30 minutes after nightfall to 30 minutes before dawn. After you can't see a person on the road 200 feet ahead, in smoke, or when you've switched on your windshield wipers to deal with terrible weather, your headlights must be turned on.","imageName":"","correctAnswer":26091,"isBookmarked":0,"aid":"26089#26090#26091#40705","text":"from 120 minutes after sunset to 120 minutes before sunrise#from 60 minutes after sunset to 60 minutes before sunrise#from 30 minutes after sunset to 30 minutes before sunrise#from 90 minutes after sunset to 90 minutes before sunrise"},
{"testId":43,"qid":1815,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101233137248.jpg","correctAnswer":5666,"isBookmarked":0,"aid":"1527#5666#5667#40277","text":"A narrow bridge ahead#Low clearance ahead#A narrow road ahead#Large trucks ahead"},
{"testId":49,"qid":1816,"description":"A solid yellow line beside your lane indicates that:","explanation":"Yellow lines divide traffic lanes traveling in opposing directions. You must not pass if there is a solid yellow line adjacent to your lane.","imageName":"","correctAnswer":41518,"isBookmarked":0,"aid":"8660#41517#41518#41519","text":"You must increase your speed#you may pass#you must not pass#you must reduce your speed"},
{"testId":49,"qid":1817,"description":null,"explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A persistent yellow \"X\" above your lane indicates that traffic in your way is soon to be diverted elsewhere. The indicator will then shift to a constant red \"X.\" Before this occurs, you must change lanes.","imageName":"","correctAnswer":39691,"isBookmarked":0,"aid":"28148#34510#39691#41520","text":"Proceed with caution#This lane is only for making turns#Change lanes as soon as it is safe to do so#The lane is closed in your direction"},
{"testId":49,"qid":1818,"description":"You are not permitted to park in West Virginia.","explanation":"You may not park within 15 feet of a fire hydrant, 20 feet of a crosswalk at a junction, or 50 feet of the closest rail of a railroad crossing in West Virginia. (Please keep in mind that there are several other areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or restricting parking.)","imageName":"","correctAnswer":34732,"isBookmarked":0,"aid":"24013#34732#39968#39970","text":"within 15 feet of a fire hydrant#in any of the above locations#within 20 feet of a crosswalk at an intersection#within 50 feet of the nearest rail of a railroad crossing"},
{"testId":49,"qid":1819,"description":"What is the meaning of this sign?","explanation":"This sort of warning sign alerts drivers to the possibility of fewer lanes ahead. This sign warns that the right lane is coming to an end ahead. When a gap opens up in the right lane, drivers must merge left. Drivers in the left lane should give cars in the right lane plenty of room to merge.","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"8818#15575#28070#31641","text":"The road ahead has two-way traffic#A narrow bridge is ahead#The right lane ends ahead#The left lane ends ahead"},
{"testId":49,"qid":1820,"description":"To maintain a safe following distance behind the vehicle in front of you, the West Virginia Driver's Licensing Handbook suggests using:","explanation":"The most prevalent cause of rear-end crashes is following too closely (tailgating). The three-to-four-second guideline is recommended by the State of West Virginia Driver's Licensing Handbook for maintaining a safe following distance behind the vehicle ahead of you. Allow at least three seconds of following space behind the car in front of you. Increase your following distance even further under more risky driving circumstances.","imageName":"","correctAnswer":41521,"isBookmarked":0,"aid":"30203#41521#41522#41523","text":"the two-second rule#the three-to-four-second rule#the two-to-three-second rule#the one-to-two-second rule"},
{"testId":49,"qid":1821,"description":"When you get close to a stopped school bus with flashing red lights on a single-lane road, you must come to a complete stop and stay still until :","explanation":null,"imageName":"","correctAnswer":41524,"isBookmarked":0,"aid":"9839#33672#33673#41524","text":"the bus starts moving#the red lights stop flashing#the driver signals you to proceed#any of the above happens"},
{"testId":49,"qid":1822,"description":"When driving at night, use ________ anytime you are within 200 feet of another vehicle.","explanation":"When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, West Virginia law requires you to reduce your headlights to low beam. Because they reflect from the car's mirrors, bright lights may dazzle the driver of the vehicle ahead of you.","imageName":"","correctAnswer":294,"isBookmarked":0,"aid":"294#295#4003#30262","text":"Low-beam headlights#High-beam headlights#fog lights#Emergency flashers"},
{"testId":49,"qid":1823,"description":"This symbol denotes:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":8663,"isBookmarked":0,"aid":"8663#19599#22299#41127","text":"a railroad crossing ahead#the right lane is closed ahead#a right turn ahead#road repair work ahead"},
{"testId":49,"qid":1824,"description":"When parallel parking on a West Virginia street, the wheels closest to the curb must be within _____ of the curb.","explanation":"Parallel parking on a West Virginia roadway requires the curbside wheels to be parallel to and within 18 inches of the curb.","imageName":"","correctAnswer":4921,"isBookmarked":0,"aid":"816#4921#5485#42592","text":"25 inches#18 inches#35 inches#21 inches"},
{"testId":49,"qid":1825,"description":"If your vehicle's rear begins to slide, turn the steering wheel:","explanation":null,"imageName":"","correctAnswer":63,"isBookmarked":0,"aid":"61#62#63#64","text":"in the opposite direction of the skid#toward the right#in the direction of the skid#toward the left"},
{"testId":49,"qid":1826,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"If an emergency vehicle approaches you while sounding a siren and flashing warning lights, you must move over to the right side of the road. Stay stopped until the emergency vehicle has passed.","imageName":"","correctAnswer":22546,"isBookmarked":0,"aid":"6476#22546#24031#41525","text":"Slow down#pull over to the right edge of the road#pull over to the left edge of the road#stop wherever you are"},
{"testId":49,"qid":1827,"description":"This octagonal (eight-sided) symbol is:","explanation":"This is a stop sign, the only octagonal form of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must come to a complete stop before the stop line, crosswalk, or intersection, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe.","imageName":"202003151731031579.jpg","correctAnswer":8733,"isBookmarked":0,"aid":"8733#8734#19269#38513","text":"a stop sign#a yield sign#a Do Not Enter sign#a Wrong Way sign"},
{"testId":49,"qid":1828,"description":"When you get to an uncontrolled intersection (one with no signs or signals), you have to let the other driver go :","explanation":"When approaching an uncontrolled junction, you must yield to cars on the right.","imageName":"","correctAnswer":14206,"isBookmarked":0,"aid":"14206#24406#40525#41526","text":"vehicles on the right#vehicles on the left#vehicles with more passengers#large vehicles"},
{"testId":49,"qid":1829,"description":"Which of the following you should use at night for better visibility in fog, rain or snow?","explanation":"High lights enable you to see farther ahead. They may, however, reflect off fog, rain, or falling snow, generating glare and making it even more difficult to see. In fog, rain, or snow, use low lights.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#34037","text":"Parking lights#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":49,"qid":1830,"description":"When you stop or park your vehicle facing downhill, which way should the front wheels point?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse for a manual transmission).","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"6656#9713#14638#25321","text":"Parallel to the curb#Away from the curb#Toward the curb#In any direction"},
{"testId":49,"qid":1832,"description":"A driver who is at least 21 years old in West Virginia is not permitted to drive with a blood alcohol concentration (BAC) of :","explanation":"Driving with a blood alcohol content (BAC) of 0.08 percent or above is illegal in West Virginia. Driving with a BAC of 0.02 percent or above is also illegal for drivers under the age of 21.","imageName":"","correctAnswer":32127,"isBookmarked":0,"aid":"32124#32127#35564#41527","text":"0.06% or higher#0.08% or higher#0.02% or higher#0.01% or higher"},
{"testId":49,"qid":1833,"description":"Keep _____ on the steering wheel to have a proper grip.","explanation":null,"imageName":"","correctAnswer":41530,"isBookmarked":0,"aid":"41528#41529#41530#41531","text":"your left hand at 9 o'clock and your right hand at 1 o'clock#your left hand at 10 o'clock and your right hand at 2 o'clock#your left hand at 8 o'clock and your right hand at 4 o'clock#your left hand at 11 o'clock and your right hand at 4 o'clock"},
{"testId":49,"qid":1834,"description":"When you want to turn, you must start to signal ________ before you turn.","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn, you must begin signaling at least 100 feet in advance, according to West Virginia law. Even if you don't see any other cars, you must signal. The most deadly car is one you cannot see.","imageName":"","correctAnswer":2026,"isBookmarked":0,"aid":"2026#2027#3091#5714","text":"at least 100 feet#at least 50 feet#at least 25 feet#at least 70 feet"},
{"testId":49,"qid":1835,"description":"What is the meaning of this sign?","explanation":"This sign indicates an exit number. This is the number allocated to a highway exit at a junction. (In this instance, it is exit 44.) If an interchange has more than one exit, a letter indicating the specific exit may be added: 44A, 44B, etc.","imageName":"202003301732572089.jpg","correctAnswer":41532,"isBookmarked":0,"aid":"34958#41532#41533#41534","text":"The next exit is 44 miles ahead#This is exit number 44#Take this exit to enter Route 44#It means none of the above"},
{"testId":49,"qid":1836,"description":"Except when passing, slower vehicles should travel ________ on interstate highways.","explanation":"Except when passing, slower cars should drive in the right lane on interstate routes.","imageName":"","correctAnswer":5719,"isBookmarked":0,"aid":"4309#5718#5719#9343","text":"on the shoulder#in the left-hand lane#in the right-hand lane#in the middle lanes"},
{"testId":49,"qid":1837,"description":"Drive no slower than ______ on interstate roads unless driving circumstances are hazardous or a lower speed limit has been set.","explanation":"Interstate highway speed limits in West Virginia now range from 55 to 70 miles per hour. You present a danger if you drive substantially slower than other drivers. On an interstate, do not travel less than 55 mph unless the weather or road conditions are poor or a lower speed restriction has been posted.","imageName":"","correctAnswer":682,"isBookmarked":0,"aid":"522#682#1676#1677","text":"25 mph#55 mph#35 mph#45 mph"},
{"testId":49,"qid":1838,"description":"Which occupants of a vehicle are required by West Virginia law to wear safety belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":35237,"isBookmarked":0,"aid":"16365#26667#35237#37513","text":"The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and passengers under 18#The driver and passengers under 18"},
{"testId":49,"qid":1839,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for falling vehicles like trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":5537,"isBookmarked":0,"aid":"4903#5537#35176#41372","text":"A bump ahead#A downgrade ahead#No trucks allowed#A truck escape ramp ahead"},
{"testId":49,"qid":1840,"description":"To leave an interstate highway, you should use:","explanation":"On an exit ramp, you leave a controlled-access roadway (such as an interstate). A deceleration lane on most controlled-access motorways allows you to slow down before reaching the exit ramp. Rather of slowing down on the highway, enter the deceleration lane and slowly brake until your speed equals the speed limit marked on the exit ramp.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"23853#26083#35191#41535","text":"An acceleration lane#a deceleration lane#a merging lane#an exit gore"},
{"testId":41,"qid":1841,"description":"If you come across a stopped school bus with flashing red lights on a two-lane road, you must:","explanation":null,"imageName":"","correctAnswer":40254,"isBookmarked":0,"aid":"9181#31657#40254#40255","text":"slow down and proceed with caution#not stop#stop and remain stopped#stop, yield to pedestrians, and then proceed"},
{"testId":41,"qid":1842,"description":"When parallel parking next to a curb, you have to park within _____ of the curb.","explanation":"Parallel parking must be done within 18 inches of the curb in South Carolina. Parking too far from the curb might endanger oncoming cars.","imageName":"","correctAnswer":4921,"isBookmarked":0,"aid":"818#4921#5730#5731","text":"12 inches#18 inches#26 inches#32 inches"},
{"testId":41,"qid":1843,"description":"Which of the following things about speed limits is NOT TRUE?","explanation":"Driving too slowly is just as hazardous as driving too quickly. You will hinder traffic, and other drivers may take unsafe risks in an attempt to overtake you. Driving slower than the prescribed minimum speed is forbidden unless severe driving circumstances make it impossible. Vehicles that cannot reach the minimum speed restriction of a highway are not permitted to use it.","imageName":"","correctAnswer":40256,"isBookmarked":0,"aid":"40256#40257#40258#40259","text":"Driving below the minimum speed limit can help prevent accidents on highways#The maximum un-posted speed limit in a residential area is 30 mph#The legal maximum speed limit is based on ideal traffic conditions#The maximum un-posted speed limit in a rural area is 55 mph except when a higher limit is allowed by federal law"},
{"testId":41,"qid":1844,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island, median, or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":40261,"isBookmarked":0,"aid":"8873#35207#40260#40261","text":"The divided highway ends ahead#Driving on the right is prohibited#You must make a sharp right turn#You must keep right"},
{"testId":41,"qid":1845,"description":"When you are within _______ of an oncoming vehicle, you must dim your headlights.","explanation":"You must lower your headlights if you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, according to South Carolina law.","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#401#1296","text":"100 feet#200 feet#400 feet#500 feet"},
{"testId":41,"qid":1846,"description":"If you are engaged in a collision that was not your fault, you must:","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":40262,"isBookmarked":0,"aid":"40262#40263#40264#40265","text":"remain at the accident scene and notify law enforcement#stop other vehicles and ask their drivers for help#decide whether it's worth it to stop at the accident scene#notify law enforcement and then leave the accident scene"},
{"testId":41,"qid":1847,"description":"You must not leave your vehicle parked :","explanation":"It is unlawful in South Carolina to stop or park your car within 20 feet of an intersection crosswalk, 50 feet of the closest rail at a railroad crossing, or 15 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Check for signs prohibiting or limiting parking.)","imageName":"","correctAnswer":34732,"isBookmarked":0,"aid":"24013#34732#39968#40266","text":"within 15 feet of a fire hydrant#in any of the above locations#within 20 feet of a crosswalk at an intersection#within 50 feet of a railroad crossing"},
{"testId":41,"qid":1848,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign forbids automobiles from turning right. This sign is usually seen on the right side of the road or over a driving lane.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"22422#40267#40268#40269","text":"A sharp right turn is ahead#You may not turn left#You may turn left#You may not turn right"},
{"testId":41,"qid":1849,"description":"You are on a road with two lanes. The lights and siren of an emergency vehicle are on and it is coming toward you. You must:","explanation":null,"imageName":"","correctAnswer":33029,"isBookmarked":0,"aid":"22434#22545#33029#40270","text":"increase your speed and clear the lane#stop immediately wherever you are#pull over to the right side of the road#pull over to the left side of the road"},
{"testId":41,"qid":1852,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"22423#22424#22426#38517","text":"A gravel road is ahead#A winding road is ahead#The road ahead is slippery when wet#A hill is ahead"},
{"testId":41,"qid":1853,"description":"You should _____ on the steering wheel to have a proper grip.","explanation":null,"imageName":"","correctAnswer":40273,"isBookmarked":0,"aid":"40271#40272#40273#40274","text":"place your left hand at the 10 o鈥檆lock position and your right hand at the 2 o鈥檆lock position#place your left hand at the 8 to 10 o鈥檆lock position and your right hand at the 3 to 5 o鈥檆lock position#place your left hand at the 7 to 9 o鈥檆lock position and your right hand at the 3 to 5 o鈥檆lock position#place your left hand at the 7 to 9 o鈥檆lock position and your right hand at the 2 to 4 o鈥檆lock position"},
{"testId":41,"qid":1854,"description":"When turning in South Carolina, you must begin signaling the turn at least ______ in advance.","explanation":"When turning in South Carolina, you must begin signaling the turn at least 100 feet in advance. You may be cited and punished if you do not comply.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#3529#4926","text":"100 feet#50 feet#70 feet#80 feet"},
{"testId":41,"qid":1856,"description":"What is the meaning of this sign?","explanation":"The majority of warning signs are diamond-shaped and have a yellow backdrop. This sign alerts you to the impending arrival of a stop sign. Prepare to come to a halt and surrender. You must come to a complete stop before any stop line or crosswalk marked on the pavement.","imageName":"202002101139461433.jpg","correctAnswer":2021,"isBookmarked":0,"aid":"2021#2515#3176#3686","text":"A stop sign ahead#A yield sign ahead#A one-way road ahead#A closed road ahead"},
{"testId":41,"qid":1857,"description":"Two vehicles come to an uncontrolled intersection at the same time from two different roadways. Which vehicle has the right-of-way?","explanation":"When two cars approaching an uncontrolled junction (one without signs or signals) from opposite highways at about the same time, the driver of the vehicle on the left must yield to the driver of the vehicle on the right.","imageName":"","correctAnswer":249,"isBookmarked":0,"aid":"249#250#5212#8597","text":"On the right#On the left#With more passengers#Signaling first"},
{"testId":41,"qid":1858,"description":"It is advised that you keep a following distance of at least ______ behind the car in front of you in South Carolina.","explanation":"Rear-end crashes are quite prevalent. Tailgating is the most common cause of rear-end collisions (following the vehicle ahead too closely). According to the South Carolina Driver's Manual, you should maintain a following distance of at least 4 seconds behind the vehicle in front of you. You should increase your following distance even more under difficult driving circumstances.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#1790#7321#7690","text":"4 seconds#5 to 6 seconds#2 seconds#1 second"},
{"testId":41,"qid":1859,"description":"In South Carolina, you must have your headlights switched on:","explanation":"According to South Carolina law, you must keep your headlights on from half an hour after dusk until half an hour before daybreak. In addition, if you use your windshield wipers constantly (rather than intermittently) in inclement weather and visibility is poor at 500 feet, you must switch on your headlights.","imageName":"","correctAnswer":40275,"isBookmarked":0,"aid":"31573#34292#40275#40276","text":"from two hours after sunset to two hours before sunrise#from sunset to sunrise#from a half hour after sunset to a half hour before sunrise#from an hour after sunset to an hour before sunrise"},
{"testId":41,"qid":1860,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101233137248.jpg","correctAnswer":5666,"isBookmarked":0,"aid":"1527#5666#5667#40277","text":"A narrow bridge ahead#Low clearance ahead#A narrow road ahead#Large trucks ahead"},
{"testId":41,"qid":1861,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"A train cannot give you the right-of-way. It may take more than a mile for a train running at 60 mph to come to a full stop. As a result, if your car becomes stuck on a railroad track while a train is coming, don't waste time attempting to remove it. Instead, get everyone out of your car, including yourself, and run at a 45-degree angle to the tracks towards the direction the train is coming. If your car is hit, you and your passengers will be protected from flying debris. Call 911 or the number shown on the Emergency Notification System (ENS) sign.","imageName":"202007210447482035.jpg","correctAnswer":40279,"isBookmarked":0,"aid":"38596#39379#40278#40279","text":"Try to signal the approaching train#Wait for emergency help to arrive#Ask other drivers to help move your vehicle#Get everyone out of your vehicle"},
{"testId":41,"qid":1862,"description":"If you want to turn the wheel at a crossroads, you should use:","explanation":"Turn the steering wheel hand-over-hand while turning at moderate speeds, such as at an intersection or when parking.","imageName":"","correctAnswer":22452,"isBookmarked":0,"aid":"2309#22452#22453#22729","text":"the hand-to-hand technique#the hand-over-hand technique#the single-hand technique#the double-hand technique"},
{"testId":41,"qid":1863,"description":"When a driver lowers his or her left hand and arm, it indicates that he or she wants to:","explanation":"Extending the hand and arm downward is the equivalent of the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop.","imageName":"","correctAnswer":22345,"isBookmarked":0,"aid":"27#6365#6368#22345","text":"Change lanes#Turn left#Turn right#slow down or stop"},
{"testId":41,"qid":1865,"description":"If you're travelling in the rain, snow, or fog, use your:","explanation":null,"imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#37950","text":"Parking lights#High-beam headlights#Low-beam headlights#emergency lights"},
{"testId":41,"qid":1866,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":41,"qid":1868,"description":"What is the meaning of this sign?","explanation":"This sign shows that employees are undertaking road repair. Slow down and take your time. If at all feasible, move into a lane away from the employees.","imageName":"202002101222518967.jpg","correctAnswer":33350,"isBookmarked":0,"aid":"3769#30867#33350#40280","text":"A hospital zone ahead#A flag person (flagger) ahead#A work crew ahead#A blind pedestrian crosswalk ahead"},
{"testId":41,"qid":1870,"description":"A driver under 21 in South Carolina will have their driving privileges terminated if they are found guilty of:","explanation":"A motorist under the age of 21 in South Carolina will have his or her driving privileges terminated if he or she is found to have a blood alcohol content (BAC) of 0.02 percent or higher. If he or she is convicted of possessing alcohol (beer, wine, or liquor), his or her driving privileges will be revoked as well, even if the infraction did not occur in a motor vehicle. It should be noted that most people will have a BAC of 0.02 percent or more after just one alcoholic drink. As a result, a BAC limit of 0.02 percent basically equals zero tolerance.","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#40281#40282#40283","text":"Any of the above#possession of alcohol when he or she was driving#driving with a blood alcohol concentration (BAC) of 0.02% or higher#possession of alcohol when he or she was not driving"},
{"testId":41,"qid":1872,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a crossroads or four-way junction ahead. Slow down and be cautious for cross traffic.","imageName":"202002101146277756.jpg","correctAnswer":2372,"isBookmarked":0,"aid":"1584#1701#2372#5805","text":"A hospital ahead#A railroad crossing ahead#A four-way intersection ahead#A three-way intersection ahead"},
{"testId":41,"qid":1873,"description":"To use a traffic circle or roundabout, you must:","explanation":"Roundabouts and traffic circles are circular junctions with counterclockwise traffic flow. To utilize a roundabout or traffic circle, enter on the right but turn left (i.e., in a counterclockwise direction).","imageName":"","correctAnswer":40291,"isBookmarked":0,"aid":"40288#40289#40290#40291","text":"enter to the left and steer to the left#enter to the right and steer to the right#enter to the left but steer to the right#enter to the right but steer to the left"},
{"testId":41,"qid":1874,"description":"A route marker sign for the Interstate System is:","explanation":"A shield-shaped marking with red, white, and blue colors is used to indicate an Interstate System route. The sign indicates the highway number and the state in which the marker is placed.","imageName":"","correctAnswer":40292,"isBookmarked":0,"aid":"40292#40293#40294#40295","text":"red, white, and blue#red, yellow, and blue#red, blue, and black#orange and green"},
{"testId":41,"qid":1875,"description":"Most entry ramps on freeways or interstate highways have ________ that allows you to boost your speed.","explanation":"Most freeways and interstate roads include acceleration lanes at the end of the entry ramps, allowing you to accelerate to the speed of the highway traffic before merging with it.","imageName":"","correctAnswer":2545,"isBookmarked":0,"aid":"2545#2547#4002#5727","text":"An acceleration lane#A deceleration lane#an exit ramp#an exit gore"},
{"testId":41,"qid":1876,"description":"What is the meaning of this sign?","explanation":"This sign alerts drivers that a nearby side road crosses a train track. When turning into the side road, proceed with care.","imageName":"202002101124556825.jpg","correctAnswer":3408,"isBookmarked":0,"aid":"3408#32525#40296#40297","text":"A side road at a railroad crossing#An underpass#A service station for trucks#A narrow bridge"},
{"testId":41,"qid":1877,"description":"On a limited-access highway, if you miss your exit, you should:","explanation":"Don't stop, back up, or attempt to turn around if you miss your exit on a limited-access highway. This dramatically increases the possibility of a collision. Instead, take the next exit and find a method to return from there.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8518#8519#8521#9110","text":"back up and return to the exit#make a U-turn and return to the exit#continue to the next exit#stop and ask for help"},
{"testId":41,"qid":1878,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are huge spaces surrounding trucks and other big vehicles where automobiles may slip into the truck driver's blind spots or come so near that the truck driver's ability to stop or maneuver safely is hampered. There are no-zones in front, sides, and back of huge trucks. Driving in the No-Zone of a huge vehicle increases your chances of a collision.","imageName":"","correctAnswer":2281,"isBookmarked":0,"aid":"2278#2279#2281#5817","text":"Zero-zones#Slow-zones#No-zones#Fast-zones"},
{"testId":41,"qid":1879,"description":"Which of the following is an example of hydroplaning?","explanation":null,"imageName":"","correctAnswer":40301,"isBookmarked":0,"aid":"40298#40299#40300#40301","text":"Tailgating in the rain#Using high-beam headlights in the rain#Applying the brakes on a wet road#Moving over the surface of water on a wet road"},
{"testId":41,"qid":1880,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Prepare to slow down and yield to traffic on the roundabout.","imageName":"202002101237092503.jpg","correctAnswer":23583,"isBookmarked":0,"aid":"23583#35194#40302#40303","text":"A roundabout is ahead#U-turns are allowed#A highway interchange is ahead#A parking lot is ahead"},
{"testId":51,"qid":1881,"description":"When this symbol is on the back of a vehicle, it means that the car usually doesn't go faster than:","explanation":"A triangular orange insignia on the back of a car marks it as a slow-moving vehicle, which Wyoming defines as one that moves no faster than 25 miles per hour. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and be patient if you come across one of these cars. Slow-moving vehicles have the legal right to utilize the road.","imageName":"202003181417388899.jpg","correctAnswer":22201,"isBookmarked":0,"aid":"9971#14164#14165#22201","text":"65 mph#55 mph#35 mph#25 mph"},
{"testId":51,"qid":1882,"description":"What are the four steps in the IPDE Defensive Driving Method?","explanation":null,"imageName":"","correctAnswer":35149,"isBookmarked":0,"aid":"35146#35147#35148#35149","text":"Identify, Predict, Drive, Establish#Isolate, Prevent, Decide, Execute#Identify, Prevent, Develop, Establish#Identify, Predict, Decide, Execute"},
{"testId":51,"qid":1883,"description":"Which one of the following is NOT TRUE about passing a vehicle?","explanation":"If signs or pavement markings prevent passing, do not proceed. Also, do not pass if you observe any cars, pedestrians, bridges, bends, slopes, crossroads, or train crossings ahead. Wait for better circumstances before attempting the pass.","imageName":"","correctAnswer":35052,"isBookmarked":0,"aid":"22696#22697#22699#35052","text":"Do not pass more than one vehicle at a time#Do not follow another vehicle that is passing a car in front of you#Avoid passing on the right unless you can do it safely#You can pass on a curve if you wish"},
{"testId":51,"qid":1885,"description":"Wyoming's interstate highways have a speed limit of at least:","explanation":"Except when otherwise specified, the legal speed limit on interstate roads in Wyoming is at least 75 mph. The speed limit on certain rural interstates is 80 miles per hour.","imageName":"","correctAnswer":33671,"isBookmarked":0,"aid":"9971#14164#24347#33671","text":"65 mph#55 mph#50 mph#75 mph"},
{"testId":51,"qid":1887,"description":"If you want to turn, you must begin signaling _______ before the turn.","explanation":"If you want to turn at an intersection in Wyoming, you must signal at least 100 feet ahead. At 45 mph, a car travels 100 feet in approximately 1.5 seconds. Consider signaling four or five seconds ahead rather than simply 100 feet ahead while traveling at greater speeds.","imageName":"","correctAnswer":2026,"isBookmarked":0,"aid":"2026#2027#3091#3092","text":"at least 100 feet#at least 50 feet#at least 25 feet#at least 75 feet"},
{"testId":51,"qid":1888,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This sign states that U-turns are not permitted. U-turns are illegal in Wyoming at crossroads, on motorways, at railroad crossings, and on busy streets.","imageName":"202003151744407638.jpg","correctAnswer":35155,"isBookmarked":0,"aid":"26145#35154#35155#35156","text":"No passing is allowed at the sign#No left turns are allowed at the sign#No U-turns are allowed at the sign#No right turns are allowed at the sign"},
{"testId":51,"qid":1889,"description":"When a school bus stops with its red lights flashing on a road without a divider, vehicles coming from either direction must :","explanation":"Vehicles moving in either direction on an undivided route must stop for a stopped school bus with flashing red lights. They must stay halted until the red lights turn off or the school bus continues its journey.","imageName":"","correctAnswer":8955,"isBookmarked":0,"aid":"6476#8955#35053#35054","text":"Slow down#stop#clear the lane quickly#pass the bus on the left"},
{"testId":51,"qid":1890,"description":"What are the big blind areas around trucks where the driver cannot see a car?","explanation":"Do not congregate in a No-Zone! No-Zones are huge spaces surrounding trucks and other big vehicles where automobiles may slip into the truck driver's blind spots or come so near that the truck driver's ability to stop or maneuver safely is hampered. No-Zones significantly enhance the chance of a collision.","imageName":"","correctAnswer":2281,"isBookmarked":0,"aid":"2278#2281#2799#35157","text":"Zero-zones#No-zones#Weaving zones#Gore areas"},
{"testId":51,"qid":1891,"description":"Which way should you point your front wheels if you park your car facing uphill near to a curb?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"6656#9713#14638#35158","text":"Parallel to the curb#Away from the curb#Toward the curb#Toward the right"},
{"testId":51,"qid":1892,"description":"Which kind of sign is this?","explanation":"This is a yield symbol. Before proceeding, you must slow down and yield to pedestrians and cars arriving from other routes as you approach a yield sign. You may need to stop for them as well, so be prepared.","imageName":"202007120454477868.jpg","correctAnswer":35162,"isBookmarked":0,"aid":"35159#35160#35161#35162","text":"Stop sign#Do Not Enter sign#Construction sign#Yield sign"},
{"testId":51,"qid":1893,"description":"A fire truck, police car, or ambulance approaching from behind will sound its siren and show its lights, signaling that you must:","explanation":"If you hear a siren or see the flashing lights of an emergency vehicle coming from behind, you must draw as near to the right-hand curb or edge of the highway as feasible and come to a complete stop. If you are in the center of an intersection, however, do not stop. Clear the junction and then pull over promptly. Continue to stand still until the emergency vehicle has past.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34688#35163#35164","text":"Do all of the above#pull over to the right#clear the intersection before you pull over#remain stopped until the emergency vehicle has passed"},
{"testId":51,"qid":1894,"description":"A normal stopping distance for a vehicle moving at 55 mph is:","explanation":null,"imageName":"","correctAnswer":35165,"isBookmarked":0,"aid":"20795#35165#35166#35167","text":"400 feet#130 鈥 140 feet#190 鈥 200 feet#320 鈥 350 feet"},
{"testId":51,"qid":1895,"description":"To avoid falling asleep when driving continuously on an interstate highway, you should:","explanation":"To avoid fatigue when driving on an interstate highway, continually changing your gaze from one section of the road to another and focused on numerous items near and distant, left and right. Talking on a mobile phone or often changing lanes might be hazardous. Stop and take a rest break if you grow sleepy nevertheless.","imageName":"","correctAnswer":26260,"isBookmarked":0,"aid":"8829#22336#26260#26261","text":"Change lanes frequently#talk on a cell phone#keep shifting your eyes#take stimulants"},
{"testId":51,"qid":1896,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful brakes in rainy conditions. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":35170,"isBookmarked":0,"aid":"35168#35169#35170#35171","text":"A winding road ahead; reduce your speed#A sharp curve ahead; use caution#The road is slippery when wet; slow down#A narrow bridge ahead; watch for oncoming traffic"},
{"testId":51,"qid":1897,"description":"If you fail to take an exit on an interstate highway, you need to:","explanation":"If you miss an expressway exit, do not stop, back up, or attempt to turn around. This dramatically increases the possibility of a collision. Backing up on a limited-access highway is banned in Wyoming. Instead, take the next exit and find a method to return from there.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8521#26306#35172#35173","text":"continue to the next exit#back up to reach your exit#make a U-turn to reach your exit#cross over the median to reach your exit"},
{"testId":51,"qid":1898,"description":"Fast-moving cars should use ______ of a highway with three or more lanes travelling in one direction.","explanation":"When there are three or more lanes in one direction, the center lanes generally provide the smoothest traffic flow. Drivers that wish to travel quicker, pass, or turn left should use the left lane. Slower cars and right turns should utilize the right lane.","imageName":"","correctAnswer":8639,"isBookmarked":0,"aid":"8639#8641#26083#34937","text":"The left lane#The right lane#a deceleration lane#A middle lane"},
{"testId":51,"qid":1899,"description":"While driving, you should scan at least ________ ahead of you to anticipate potential dangers and avoid accidents.","explanation":null,"imageName":"","correctAnswer":5866,"isBookmarked":0,"aid":"5863#5864#5865#5866","text":"40 to 60 seconds#30 to 40 seconds#20 to 25 seconds#10 to 15 seconds"},
{"testId":51,"qid":1901,"description":"Allow at least a ________ following distance for every 20 feet of vehicle length.","explanation":"Allow at least a two-second following distance for every 20 feet of vehicle length. Drivers of buses and tractor-trailers must maintain a higher following distance than vehicle drivers.","imageName":"","correctAnswer":549,"isBookmarked":0,"aid":"549#550#551#3640","text":"Two-second#Four-second#three-second#One-second"},
{"testId":51,"qid":1903,"description":"When you notice a flashing red light at an intersection, you must :","explanation":"Consider a flashing red light to be a stop sign. That is, before approaching the junction, you must stop, yield to pedestrians and traffic, and then continue when it is safe to do so.","imageName":"","correctAnswer":35174,"isBookmarked":0,"aid":"22471#22695#35174#35175","text":"do any of the above#increase your speed and clear the intersection#come to a complete stop before entering the intersection#slow down and enter the intersection carefully"},
{"testId":51,"qid":1904,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for falling vehicles like trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":5537,"isBookmarked":0,"aid":"5537#5878#33093#35176","text":"A downgrade ahead#A truck parking area ahead#Heavy trucks ahead#No trucks allowed"},
{"testId":51,"qid":1905,"description":"Your headlights must be dimmed:","explanation":"When you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following in Wyoming, you must reduce your headlights to low beam. Avoid flashing your high lights on any other car that is occupied. Bright lights may bounce off the mirrors of other vehicles, blinding the driver.","imageName":"","correctAnswer":35177,"isBookmarked":0,"aid":"35177#35178#35179#35180","text":"whenever you are within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following#whenever you are within 300 feet of an oncoming vehicle or within 500 feet of a vehicle you are following#whenever you are within 500 feet of another vehicle#whenever you are within 1,000 feet of another vehicle"},
{"testId":51,"qid":1907,"description":"If there is an oncoming vehicle in your lane, you should _____ to avoid a head-on accident.","explanation":"If an incoming car is in your lane, consider flashing your headlights to notify the other motorist. If the car continues to approach, pull as far to the right as possible. To prevent a collision, turn completely off the road if necessary. To limit the effect of the crash, hit the brakes firmly and slow down as much as possible.","imageName":"202006121435309851.jpg","correctAnswer":10656,"isBookmarked":0,"aid":"10656#14711#35055#35181","text":"Do all of the above#flash your headlights#pull as far right as possible#slow down and sound your horn"},
{"testId":51,"qid":1909,"description":"Wyoming law requires you to utilize your headlights ______","explanation":"From one-half hour after dusk to one-half hour before daybreak, you must use your headlights. When vision is poor at 1,000 feet, you must additionally use your headlights (perhaps because of insufficient light or bad weather).","imageName":"","correctAnswer":32471,"isBookmarked":0,"aid":"32470#32471#35184#35185","text":"from one hour after sunset until one hour before sunrise#from one-half hour after sunset until one-half hour before sunrise#from three hours after sunset until three hours before sunrise#from two hours after sunset until two hours before sunrise"},
{"testId":51,"qid":1910,"description":"What should you do if you begin to slide?","explanation":null,"imageName":"","correctAnswer":35187,"isBookmarked":0,"aid":"6475#29078#35186#35187","text":"Speed up#Brake hard#Turn your steering wheel in the opposite direction of the skid#Turn your steering wheel in the direction of the skid"},
{"testId":51,"qid":1911,"description":"Which vehicle occupants in Wyoming are required to use seat belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#34769#35189","text":"Only the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#None (seat belts are optional in Wyoming)"},
{"testId":51,"qid":1912,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required.","imageName":"202001282004545744.jpg","correctAnswer":1701,"isBookmarked":0,"aid":"1701#3177#5890#5891","text":"A railroad crossing ahead#A right turn ahead#Road maintenance work ahead#A road closing ahead"},
{"testId":51,"qid":1913,"description":"What is the legal limit for the amount of alcohol in a driver under 21 years old's blood?","explanation":"It is prohibited in Wyoming for a motorist under the age of 21 to drive with a blood alcohol content (BAC) of 0.02 percent or higher. A 180-pound guy may have a BAC of more than 0.02 percent after consuming only one 12-ounce can of beer before his body begins to break down the alcohol. As a result, for drivers under the age of 21, a BAC level of less than 0.02 percent is virtually zero tolerance.","imageName":"","correctAnswer":34143,"isBookmarked":0,"aid":"4251#10236#34143#35190","text":"0.02%#Less than 0.08%#Less than 0.02%#Less than 0.04%"},
{"testId":51,"qid":1914,"description":"To leave an interstate highway, you must first enter :","explanation":"Enter a deceleration lane before beginning to slow down to leave a limited-access highway. Slow down in the deceleration lane to reach an exit ramp.","imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"23853#26083#26084#35191","text":"An acceleration lane#a deceleration lane#an entrance ramp#a merging lane"},
{"testId":51,"qid":1916,"description":"What is the meaning of this sign?","explanation":"Blue flags indicate the presence of available services ahead. This service sign denotes the presence of a hospital ahead.","imageName":"202003151737026825.jpg","correctAnswer":1584,"isBookmarked":0,"aid":"1584#4416#5900#5901","text":"A hospital ahead#A hill ahead#A handicapped parking area ahead#A help line available ahead"},
{"testId":51,"qid":1917,"description":"If you're travelling in the fog, use your:","explanation":"When vision is decreased, such as in severe weather, turn on your low-beam headlights. (In the event of fog, fog lights are an option.) In adverse conditions, avoid using high-beam headlights. Glare may be caused by bright lights reflecting off fog, rain, or snow. If the fog grows so thick that you can't see more than 10 seconds ahead, pull off the road and halt in a safe location. Remember, you can't drive if you can't see.","imageName":"","correctAnswer":22156,"isBookmarked":0,"aid":"9758#22155#22156#34037","text":"Parking lights#High-beam headlights#Low-beam headlights#Emergency flashers"},
{"testId":51,"qid":1918,"description":"What does a broken line on the road mean?","explanation":"If the road is clear, a broken line indicates that passing or changing lanes is permitted.","imageName":"","correctAnswer":35193,"isBookmarked":0,"aid":"35192#35193#35194#35195","text":"Traffic goes in opposite directions#Passing or changing lanes is allowed if the way is clear#U-turns are allowed#Passing or changing lanes is not allowed"},
{"testId":51,"qid":1919,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"If your car becomes stuck on a railroad track, do not waste time attempting to dislodge it. Instead, get everyone out of your car, including yourself, and walk swiftly at a 45-degree angle to the tracks in the direction you anticipate trains to arrive. If your vehicle is hit, you and your passengers will be protected from flying debris. Call 911 or the number listed on the train crossing sign.","imageName":"202007210447482035.jpg","correctAnswer":35196,"isBookmarked":0,"aid":"34666#34929#34930#35196","text":"Try to push your vehicle off the tracks#Try to restart the engine#Wait for help to arrive#Get everyone out and clear of the tracks"},
{"testId":51,"qid":1920,"description":"What does this green traffic light mean?","explanation":"This green arrow indicates that you may turn in the direction indicated by the arrow. This is a \"protected\" turn, which means that incoming traffic is halted at a red light while the green arrow is illuminated for you.","imageName":"202003051809194011.jpg","correctAnswer":19616,"isBookmarked":0,"aid":"19223#19615#19616#19617","text":"Do not make a left turn#Clear the intersection immediately#Turn in the direction of the arrow#Stop at the sign"},
{"testId":47,"qid":1921,"description":"The Virginia Driver's Manual recommends that you keep your hands at_________when driving.","explanation":null,"imageName":"","correctAnswer":41221,"isBookmarked":0,"aid":"41219#41220#41221#41222","text":"the 9 o'clock and 2 o'clock positions#the 10 o'clock and 3 o'clock positions#the 8 o'clock and 4 o'clock positions#the 10 o'clock and 2 o'clock positions"},
{"testId":47,"qid":1922,"description":"The speed limit on Virginia interstate routes can be as high as:","explanation":"The speed limit on Virginia's interstate routes is 55 to 70 mph.","imageName":"","correctAnswer":23846,"isBookmarked":0,"aid":"23846#33671#33729#41883","text":"70 mph#75 mph#80 mph#85 mph"},
{"testId":47,"qid":1923,"description":"If you come across a school bus with flashing red lights and an extended stop sign on an undivided roadway, you must:","explanation":"A school bus with flashing red lights and an extended stop arm is picking up or dropping off students. You must come to a complete stop, regardless of which way you are heading. Continue to stand still until all people have cleared the street and the school bus has resumed movement. There is one exemption in Virginia: if the school bus is stopped on the other side of a split roadway, you are not required to stop.","imageName":"","correctAnswer":41223,"isBookmarked":0,"aid":"9181#9182#22544#41223","text":"slow down and proceed with caution#increase your speed and pass the bus quickly#continue at the same speed#stop and remain stopped until the bus starts moving again"},
{"testId":47,"qid":1925,"description":"A sign of this shape denotes the start of:","explanation":"The only form of sign that is pennant shaped is the \"No Passing Zone\" sign. It denotes a no-passing zone for any cars approaching you. A solid yellow line adjacent to your lane may also suggest the same thing.","imageName":"202003151738521917.jpg","correctAnswer":8482,"isBookmarked":0,"aid":"8482#9211#41224#41225","text":"a no-passing zone#a railroad crossing#unusual conditions near a work zone#a speed zone"},
{"testId":47,"qid":1927,"description":"At an uncontrolled intersection, two vehicles coming from different directions may arrive at the same moment. the vehicle ________ has the right-of-way.","explanation":"If two cars arrive at an uncontrolled junction (one without signs or signals) at about the same time, the driver of the vehicle on the left must yield to the driver of the vehicle on the right.","imageName":"","correctAnswer":249,"isBookmarked":0,"aid":"249#250#28769#41226","text":"On the right#On the left#that signals first#that weighs less"},
{"testId":47,"qid":1929,"description":"If an emergency vehicle with flashing lights approaches from either way, you must ________ until it passes.","explanation":"If an emergency vehicle approaches you with flashing lights, Virginia law requires you to yield to the emergency vehicle, pull over to the right edge or curb of the road, and stay stopped until the emergency vehicle has passed. However, if you are at an intersection and an emergency vehicle arrives, do not stop. Continue past the junction, then immediately turn right.","imageName":"","correctAnswer":41228,"isBookmarked":0,"aid":"757#41227#41228#41229","text":"slow down#pull over to the left#pull over to the right#stop where you are"},
{"testId":47,"qid":1930,"description":"What does a double solid yellow line in the center of the road indicates?","explanation":null,"imageName":"","correctAnswer":34430,"isBookmarked":0,"aid":"22701#22702#34429#34430","text":"Traffic can go in only one direction#All traffic must stop at the line#Passing is allowed on both sides#Passing is not allowed on either side"},
{"testId":47,"qid":1932,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":28656,"isBookmarked":0,"aid":"28656#29880#41069#41070","text":"You must come to a complete stop#You must not enter the road ahead#You must come to a complete stop only if pedestrians or approaching vehicles are present#You must stop or slow down to less than 5 mph"},
{"testId":47,"qid":1933,"description":"When you want to turn, you must signal at least _____ before you turn.","explanation":"When you wish to turn, explain your purpose ahead of time. If you want to turn and are going faster than 30 mph, you must signal continuously for at least 100 feet before turning. The Virginia Driver's Manual suggests signaling for three or four seconds before turning, which is particularly important at high speeds. (At 40 mph, a car will move 100 feet in 1.7 seconds, providing little early notice to the driver.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#1027#4061","text":"100 feet#50 feet#25 feet#75 feet"},
{"testId":47,"qid":1935,"description":"When driving less than 35 mph, keep a trailing distance of _______ behind the vehicle in front of you.","explanation":"The Virginia Driver's Manual recommends two seconds under 35 mph, three seconds between 35 and 45 mph, and four seconds beyond 45 mph for following distance. Take note of when the car in front of you passes a stationary item to calculate your following distance. Then count the seconds (\"1,000 one, 1,000 two,...\") until you reach the identical thing.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":47,"qid":1937,"description":"A road with the broken white line:","explanation":"Two lanes of traffic flowing in the same direction are separated by a broken white line. If it is safe to do so, you may cross the line to pass or change lanes.","imageName":"","correctAnswer":28119,"isBookmarked":0,"aid":"28118#28119#41230#41231","text":"separates two lanes of traffic moving in opposite directions#separates two lanes of traffic moving in the same direction#marks the left edge of the roadway#marks the right edge of the roadway"},
{"testId":47,"qid":1938,"description":"When you park next to a curb, you can't be more than ___ away from it.","explanation":"Pull up close to the curb while parking. You may not park more than one foot from the curb in Virginia.","imageName":"","correctAnswer":23982,"isBookmarked":0,"aid":"23979#23980#23981#23982","text":"two feet#three feet#four feet#one foot"},
{"testId":47,"qid":1939,"description":"Which of the following you should use when you are following another vehicle within 200 feet at night?","explanation":null,"imageName":"","correctAnswer":294,"isBookmarked":0,"aid":"294#295#30263#39725","text":"Low-beam headlights#High-beam headlights#Interior lights#Hazard flashers"},
{"testId":47,"qid":1942,"description":"Which occupants of a vehicle are required by Virginia law to wear safety belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":35237,"isBookmarked":0,"aid":"35237#37513#40535#41232","text":"The driver, front-seat passengers, and passengers under 18#The driver and passengers under 18#The driver, rear-seat passengers, and passengers under 18#Only the passengers under 18"},
{"testId":47,"qid":1943,"description":"In a Virginia school zone, the maximum speed limit is:","explanation":"The maximum speed restriction in a school zone, commercial sector, or residential district in Virginia is 25 mph. Slow down, proceed with care, and be prepared to yield to crossing youngsters in a school zone. Follow the instructions of the school crossing guards.","imageName":"","correctAnswer":22201,"isBookmarked":0,"aid":"4#9968#22200#22201","text":"10 mph#20 mph#15 mph#25 mph"},
{"testId":47,"qid":1944,"description":"What is the meaning of this sign?","explanation":"This sort of warning sign alerts drivers to the possibility of fewer lanes ahead. This sign warns that the right lane is coming to an end ahead. When a gap opens up in the right lane, drivers must merge left. Drivers in the left lane should give cars in the right lane plenty of room to merge.","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"54#15575#28070#31641","text":"A one-way road is ahead#A narrow bridge is ahead#The right lane ends ahead#The left lane ends ahead"},
{"testId":47,"qid":1945,"description":"A driver under the age of 21 might be charged with illicit alcohol consumption if his or her blood alcohol content (BAC) is at least _____ but less than______.","explanation":"A motorist under the age of 21 might be charged with illicit alcohol consumption if his or her blood alcohol concentration (BAC) is at least 0.02 percent but less than 0.08 percent. If the adolescent driver's BAC is 0.08 percent or more, he or she might be charged with driving under the influence (DUI) just like an adult. (Note: Most people will have a BAC of 0.02 percent or more after only one alcoholic drink, therefore a legal limit of less than 0.02 percent is essentially zero tolerance.)","imageName":"","correctAnswer":5972,"isBookmarked":0,"aid":"5969#5970#5972#41233","text":"0.00%; 0.01%#0.01%; 0.02%#0.02%; 0.08%#0.04%; 0.10%"},
{"testId":47,"qid":1947,"description":"A left turn on a red light is permitted in Virginia if you are turning:","explanation":"If you are going from one one-way street to another one-way street in Virginia, you may turn left on a red light unless signs forbid it. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding left turns on red. Always verify the local driving regulations while going out of state.","imageName":"","correctAnswer":24217,"isBookmarked":0,"aid":"24217#24218#24219#24220","text":"from a one-way street onto another one-way street#from a two-way street onto a one-way street#from a one-way street onto a two-way street#from a two-way street onto another two-way street"},
{"testId":47,"qid":1949,"description":"A car going at 65 mph on dry, flat pavement has an average stopping distance of:","explanation":"A vehicle's stopping distance is the product of the driver's perception distance, the driver's response distance, and the vehicle's braking distance. A automobile going at 65 mph on dry, flat pavement has a stopping distance of 344 feet. (A football field's length, including the two end zones, is 360 feet.) When driving conditions are bad, stopping distance might rise dramatically.","imageName":"","correctAnswer":41236,"isBookmarked":0,"aid":"40857#41234#41235#41236","text":"387 feet#195 feet#265 feet#344 feet"},
{"testId":47,"qid":1950,"description":"Which of the following indicates a space for disabled people on the pavement?","explanation":"Parking places for people with impairments are indicated by blue pavement markings.","imageName":"","correctAnswer":5985,"isBookmarked":0,"aid":"5985#5986#5987#5988","text":"Blue pavement markings#Yellow pavement markings#Red pavement markings#White pavement markings"},
{"testId":47,"qid":1951,"description":"You've parked parallel to the curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse for a manual transmission).","imageName":"","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":47,"qid":1952,"description":"This symbol denotes:","explanation":"This sign denotes a railroad crossing with little ground clearance. The railroad crossing is elevated to the point that a vehicle with a long wheelbase or limited ground clearance may get caught on the rails. Unless the motorist is towing a trailer, this sort of train crossing should not pose a difficulty for a car driver.","imageName":"202002101128275846.jpg","correctAnswer":19622,"isBookmarked":0,"aid":"19620#19621#19622#19623","text":"a side road near a railroad crossing#a railroad crossing under repair#a low ground clearance railroad crossing#a closed railroad crossing"},
{"testId":47,"qid":1953,"description":"Which one of the following is NOT TRUE about passing a vehicle?","explanation":"It is prohibited in Virginia to exceed the speed limit even while overtaking another vehicle. The pass must be completed within the speed limit.","imageName":"","correctAnswer":41238,"isBookmarked":0,"aid":"41237#41238#41239#41240","text":"You must complete the pass before you reach a no-passing zone#You may exceed the speed limit to pass the vehicle safely#You may not drive off the pavement to pass on the right#You may pass on the right if the vehicle you are passing is making a left turn"},
{"testId":47,"qid":1954,"description":"If you're traveling slower than other traffic, remain in the lane:","explanation":"When feasible, maintain the same approximate speed as other cars, but never exceed the posted speed restrictions. Keep right if you are traveling slower than the rest of the traffic.","imageName":"","correctAnswer":22428,"isBookmarked":0,"aid":"8891#22427#22428#34937","text":"The shoulder#The leftmost lane#The rightmost lane#A middle lane"},
{"testId":47,"qid":1955,"description":"What must you do if your car stalls on railroad tracks and you see a train approaching?","explanation":"A train cannot give you the right-of-way. It may take more than a mile for a train running at 60 mph to come to a full stop. As a result, if your car becomes stuck on a railroad track while a train is coming, don't waste time attempting to remove it. Instead, get everyone out of your car, including yourself, and walk at a 45-degree angle to the tracks in the direction the train is coming. If your car is hit, you and your passengers will be protected from flying debris. Call 911 or the number shown on the Emergency Notification System (ENS) sign.","imageName":"202007210447482035.jpg","correctAnswer":40279,"isBookmarked":0,"aid":"38596#38965#40278#40279","text":"Try to signal the approaching train#Try to restart your vehicle#Ask other drivers to help move your vehicle#Get everyone out of your vehicle"},
{"testId":47,"qid":1957,"description":"If convicted of violating the posted speed limit in a highway work zone while workers are present, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24977#31696#33102","text":"$500#$200#$400#$300"},
{"testId":47,"qid":1958,"description":"When an emergency vehicle's lights are flashing, never follow it any closer than:","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#400#567#1296","text":"100 feet#300 feet#1000 feet#500 feet"},
{"testId":47,"qid":1959,"description":"Which areas can be categorized as blind spots?","explanation":"Blind spots are dangerous places that are not visible in your vehicle's mirrors. Every automobile has blind zones. The most prevalent blind spots are at the vehicle's left and right rear corners. Blind zones are often greater in larger vehicles. No-Zones are big blind zones on trucks. The easiest approach to notice what's in your blind spots is to cast a brief peek over your shoulder.","imageName":"","correctAnswer":41242,"isBookmarked":0,"aid":"41242#41243#41244#41245","text":"Areas around a vehicle that cannot be seen in its mirrors#Areas on a freeway where vehicles can be stopped for emergencies#Roadways at an intersection that are hidden by trees, parked cars, buildings, etc#Areas in school zones where school children cross the road"},
{"testId":47,"qid":1960,"description":"This sign indicates a designated parking spot for:","explanation":"The International Symbol of Access for Disabled People is included on this sign. This signifies that certain parking places are dedicated for people with disabilities. You may apply for a disability parking placard or disabled license plates in Virginia. To do so, complete and return the form \"Disabled Parking Placard or License Plates Application\" (MED 10) along with the required charge. In order to obtain a disabled license plate, you must also submit the \"License Plate Application\" (VSA 10) form along with the MED 10 form. [\"How to Apply for a Disabled Parking Placard or License Plates,\" Virginia Department of Motor Vehicles, https://www.dmv.virginia.gov/drivers/#disability/apply assist .asp]","imageName":"202004071444232622.jpg","correctAnswer":31996,"isBookmarked":0,"aid":"19624#19625#19626#31996","text":"senior citizens#patients#cyclists#people with disabilities"},
{"testId":9,"qid":1961,"description":"When a vehicle's tires are submerged in water on the road It's called:","explanation":null,"imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8584#8693#8694","text":"Hydroplaning#tailgating#weaving#highway hypnosis"},
{"testId":9,"qid":1962,"description":"To keep a safe gap between your vehicle and the one in front of you, follow the _________ rule.","explanation":"Drive at a pace that allows you to stop safely at any time. To ensure that you leave adequate distance between your car and the one ahead, use the four-second rule.","imageName":"","correctAnswer":550,"isBookmarked":0,"aid":"549#550#551#2544","text":"Two-second#Four-second#three-second#Five-second"},
{"testId":9,"qid":1963,"description":"If you miss an interstate exit, you must:","explanation":"If you miss an interstate exit, you must go to the next exit, where you may exit and re-enter the highway to return to the missed exit.","imageName":"202007210432373628.jpg","correctAnswer":8521,"isBookmarked":0,"aid":"8521#23909#26058#26059","text":"continue to the next exit#turn back#cross over#stop and seek help"},
{"testId":9,"qid":1964,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a median ahead. You must maintain your position.","imageName":"202003151752201841.jpg","correctAnswer":26060,"isBookmarked":0,"aid":"3177#3800#26060#26061","text":"A right turn ahead#A roundabout ahead#A median ahead#A right curve ahead"},
{"testId":9,"qid":1965,"description":"If an emergency vehicle comes at you while blaring its siren and flashing its lights, you must:","explanation":"When they are giving an audible (siren) or visible signal, authorised emergency vehicles such as police cars, ambulances, and fire engines have the right of way (flashing lights). When you hear or see an emergency vehicle coming, you should stop right away, pull over to the curb, and stay there until it has past.","imageName":"","correctAnswer":22433,"isBookmarked":0,"aid":"22433#22434#22436#26062","text":"pull over to the curb and stop#increase your speed and clear the lane#reduce your speed and continue slowly#proceed at the same speed"},
{"testId":9,"qid":1966,"description":"It is permissible to pass ______ in the District of Columbia if there is capacity for more than one lane of traffic.","explanation":"It is permitted in the District of Columbia to pass on either the left or right side of a one-way street if there is enough clearance for more than one lane of traffic. On roads with four or more lanes, it is also permissible to pass on either the left or right.","imageName":"","correctAnswer":6024,"isBookmarked":0,"aid":"6024#6025#6026#26063","text":"on either the left or right#only on the left#only on the right#in none of the above ways"},
{"testId":9,"qid":1967,"description":"A roundabout is a circular crossroads with all traffic moving:","explanation":"A roundabout is a circular crossroads where all traffic flows counterclockwise around a central island, always to the right.","imageName":"","correctAnswer":26065,"isBookmarked":0,"aid":"64#26064#26065#26066","text":"toward the left#clockwise#counterclockwise#toward the center"},
{"testId":9,"qid":1968,"description":"This symbol warns you of a:","explanation":"This is a yield symbol. You must slow down or halt if required, and provide cars crossing your route the right-of-way.","imageName":"202007120454477868.jpg","correctAnswer":26069,"isBookmarked":0,"aid":"26067#26068#26069#26070","text":"fast-moving vehicle#slow-moving vehicle#yield zone ahead#stop ahead"},
{"testId":9,"qid":1969,"description":"When approaching an uncontrolled crossroads with no signage or functioning traffic lights, you must:","explanation":"If you approach a junction with no signs or operational traffic lights, you must stop as if it were a stop sign.","imageName":"","correctAnswer":26071,"isBookmarked":0,"aid":"8992#22695#26071#26072","text":"make a U-turn#increase your speed and clear the intersection#come to a stop as you would at a stop sign#reduce your speed and watch for other vehicles"},
{"testId":9,"qid":1970,"description":"Except when passing, you must stay in the ________ lane on a road having two or more lanes traveling in the same direction.","explanation":"On a road with two or more lanes traveling in the same direction, stay in the right lane except to pass. On a road with three or more lanes traveling in the same direction, stay as far to the right as possible.","imageName":"","correctAnswer":3594,"isBookmarked":0,"aid":"3594#5477#5478#26073","text":"right lane#left lane#center lane#busiest lane"},
{"testId":9,"qid":1971,"description":"On a divided roadway, yellow pavement markings indicate ________.","explanation":"Yellow lines separate opposing traffic lanes and denote the left travel edge of all traffic lanes traveling in the same direction.","imageName":"","correctAnswer":35554,"isBookmarked":0,"aid":"8482#22413#26074#35554","text":"a no-passing zone#A stop line#the right edge of the roadway#the left edge of all lanes moving in the same direction"},
{"testId":9,"qid":1972,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the presence of a railroad crossing.","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"132#15550#15654#26076","text":"The road ahead is closed#There is a railroad crossing ahead#There is a roundabout ahead#The road ahead is under repair"},
{"testId":9,"qid":1973,"description":"When you park your vehicle on a two-way street, you must do so parallel to the curb and no more than _____ from it.","explanation":"You must park parallel to and within 12 inches of the curb while parking on a two-way street.","imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"816#818#5420#5486","text":"25 inches#12 inches#50 inches#20 inches"},
{"testId":9,"qid":1974,"description":"When parking a vehicle on a downward slope, the front wheels should be turned:","explanation":"When parking on a downhill incline, you must turn your front wheels toward the curb or the road's edge. If your car begins to roll downhill, it will slide against the curb or off the road rather than into traffic.","imageName":"","correctAnswer":26077,"isBookmarked":0,"aid":"64#74#9705#26077","text":"toward the left#parallel to the curb#away from the curb#toward the curb or the edge of the road"},
{"testId":9,"qid":1975,"description":"The blind spots around a vehicle are referred to as:","explanation":"No-Zones are the blind regions surrounding vehicles.","imageName":"","correctAnswer":26081,"isBookmarked":0,"aid":"26078#26079#26080#26081","text":"Zero-Zones#Slow-Zones#Blank-Zones#No-Zones"},
{"testId":9,"qid":1976,"description":"What is the meaning of this sign?","explanation":"This sign cautions that a difficult incline lies ahead. Check your brakes before driving down a slope. Check your mirrors regularly as you down a long or steep slope. Vehicles often gain speed when they descend a steep incline.","imageName":"202002101212538783.jpg","correctAnswer":3244,"isBookmarked":0,"aid":"3244#5536#5538#26082","text":"A steep hill ahead#A dip ahead#A parking area for trucks ahead#Only trucks allowed"},
{"testId":9,"qid":1977,"description":"If you want to get off an interstate, you have to get into:","explanation":null,"imageName":"","correctAnswer":26083,"isBookmarked":0,"aid":"23853#26083#26084#26085","text":"An acceleration lane#a deceleration lane#an entrance ramp#a merging area"},
{"testId":9,"qid":1978,"description":"If you observe a vehicle approaching you in your lane, you should :","explanation":null,"imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6476#10656#26086#26087","text":"Slow down#Do all of the above#move to the right#sound your horn and flash your headlights"},
{"testId":9,"qid":1979,"description":"Which of the following is the right way to show that you want to turn left?","explanation":"When your turn signals don't function or are difficult to see in strong sunshine, you must utilize hand signals. A left turn is indicated by maintaining your left hand straight out.","imageName":"","correctAnswer":6043,"isBookmarked":0,"aid":"6042#6043#6044#6045","text":"The left hand pointing up#The left hand pointing straight out#The right hand pointing down#Both hands pointing down"},
{"testId":9,"qid":1980,"description":"What is the meaning of this pentagonal (five-sided) sign?","explanation":"This five-sided pentagonal form alerts you of school zones and denotes school crossings.","imageName":"202003151742324658.jpg","correctAnswer":1252,"isBookmarked":0,"aid":"1252#1347#2992#6046","text":"A school zone ahead#A library ahead#A pedestrian crossing ahead#A play area ahead"},
{"testId":9,"qid":1981,"description":"When you want to turn, you must use a turn signal, a hand signal, or both for at least ________ before turning.","explanation":"Before turning, you must use a turn signal, hand signal, or both for at least 100 feet. When going at high speeds, you should signal for a significantly longer distance and time gap.","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#2292#4061","text":"100 feet#50 feet#60 feet#75 feet"},
{"testId":9,"qid":1982,"description":"You must turn on your headlights when driving:","explanation":"From one-half hour after dusk to one-half hour before daybreak, you must switch on your headlights. You must also switch on your headlights if you cannot see people or cars on the roadway from 500 feet or less.","imageName":"","correctAnswer":26091,"isBookmarked":0,"aid":"26088#26089#26090#26091","text":"from 180 minutes after sunset to 180 minutes before sunrise#from 120 minutes after sunset to 120 minutes before sunrise#from 60 minutes after sunset to 60 minutes before sunrise#from 30 minutes after sunset to 30 minutes before sunrise"},
{"testId":9,"qid":1983,"description":"You must come to a complete stop EXCEPT:","explanation":"When you see a flashing yellow traffic light, you should slow down, check for cross traffic, and continue with care.","imageName":"","correctAnswer":26093,"isBookmarked":0,"aid":"8733#26092#26093#26094","text":"a stop sign#a flashing red traffic signal#a flashing yellow traffic signal#a steady red traffic signal"},
{"testId":9,"qid":1984,"description":"What is the meaning of this octagonal (eight-sided) sign?","explanation":"This is a red light. At a stop sign, stop line, or pedestrian crossing, you must come to a full stop.","imageName":"202001301744345845.jpg","correctAnswer":627,"isBookmarked":0,"aid":"165#627#628#26095","text":"Do not enter#Stop#Yield#One way"},
{"testId":9,"qid":1985,"description":"It is compulsory for _____ of passenger automobiles, lorries, tractors, multi-purpose vehicles, and passenger buses to wear seat belts.","explanation":"Seat belts are required under District of Columbia traffic legislation for the driver and all passengers in passenger automobiles, lorries, tractors, multi-purpose vehicles, and passenger buses. You are not permitted to drive any of these vehicles without you and all passengers are wearing seat belts.","imageName":"","correctAnswer":763,"isBookmarked":0,"aid":"763#6056#26096#26097","text":"all of the above#all drivers#all front-seat passengers#all rear-seat passengers"},
{"testId":52,"qid":1986,"description":"This symbol denotes:","explanation":"This sign warns of an impending school zone. Slow down, continue with care, and be prepared to stop if required since schoolchildren may be crossing the road ahead. Please keep in mind that an earlier version of this sign has a yellow backdrop.","imageName":"202003151740461521.jpg","correctAnswer":8895,"isBookmarked":0,"aid":"8895#22620#28026#28028","text":"a school zone ahead#a hospital zone ahead#a crosswalk ahead#a playground ahead"},
{"testId":52,"qid":1988,"description":"Which of the following is required as a bare minimum for a street-legal motorcycle?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#37542#39525#43641","text":"All of the above#Horn and at least one mirror#At least one brake#Headlight, brake light, and rear reflector"},
{"testId":52,"qid":1989,"description":"What is the meaning of this sign?","explanation":"This work zone sign shows that employees are undertaking road repair. Reduce your speed and, if feasible, change lanes to preserve a safe distance from the employees.","imageName":"202002101222518967.jpg","correctAnswer":6067,"isBookmarked":0,"aid":"2268#2269#6067#6068","text":"Channelizing devices ahead#Deep excavation ahead#Road maintenance ahead#Road survey activity ahead"},
{"testId":52,"qid":1990,"description":"Which of the following penalties may you potentially face for a first offense if you are found guilty of driving while under the influence of alcohol or drugs?","explanation":null,"imageName":"","correctAnswer":11272,"isBookmarked":0,"aid":"11272#18218#23073#43235","text":"Any or all of the above#Jail#Mandatory license suspension#A large fine"},
{"testId":52,"qid":1991,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":438,"qid":1991,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":462,"qid":1991,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":479,"qid":1991,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":503,"qid":1991,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":52,"qid":1992,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"When looking for dangers on the road, look 12 seconds ahead on your route of travel. This allows you to prepare for and plan for a problem before it becomes an imminent threat. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#6077#6078#6079","text":"12 seconds#60 seconds#40 seconds#25 seconds"},
{"testId":52,"qid":1995,"description":"Normal turns require the rider and motorcycle to:","explanation":"In conventional turns, the rider and motorbike should lean in the same direction.","imageName":"","correctAnswer":24949,"isBookmarked":0,"aid":"24949#24950#24951#30806","text":"lean together at the same angle#lean at different angles#not lean at all#lean at different times"},
{"testId":371,"qid":1995,"description":"Normal turns require the rider and motorcycle to:","explanation":"In conventional turns, the rider and motorbike should lean in the same direction.","imageName":"","correctAnswer":24949,"isBookmarked":0,"aid":"24949#24950#24951#30806","text":"lean together at the same angle#lean at different angles#not lean at all#lean at different times"},
{"testId":396,"qid":1995,"description":"Normal turns require the rider and motorcycle to:","explanation":"In conventional turns, the rider and motorbike should lean in the same direction.","imageName":"","correctAnswer":24949,"isBookmarked":0,"aid":"24949#24950#24951#30806","text":"lean together at the same angle#lean at different angles#not lean at all#lean at different times"},
{"testId":416,"qid":1995,"description":"Normal turns require the rider and motorcycle to:","explanation":"In conventional turns, the rider and motorbike should lean in the same direction.","imageName":"","correctAnswer":24949,"isBookmarked":0,"aid":"24949#24950#24951#30806","text":"lean together at the same angle#lean at different angles#not lean at all#lean at different times"},
{"testId":422,"qid":1995,"description":"Normal turns require the rider and motorcycle to:","explanation":"In conventional turns, the rider and motorbike should lean in the same direction.","imageName":"","correctAnswer":24949,"isBookmarked":0,"aid":"24949#24950#24951#30806","text":"lean together at the same angle#lean at different angles#not lean at all#lean at different times"},
{"testId":52,"qid":1996,"description":"Keep a safe distance between your motorcycle and the vehicle in front of you because it will give you:","explanation":"The distance between your motorbike and the car ahead of you allows you to respond and navigate. You can brake and come to a complete stop without risking an accident.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10897#10898#10899","text":"All of the above#time to react#time to brake#time to stop"},
{"testId":52,"qid":1998,"description":"The Arizona Motorcycle Operator Manual recommends keeping a following distance of at least _______ behind the vehicle in front of you.","explanation":"The Arizona Motorcycle Operator Manual recommends keeping a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":52,"qid":2000,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you're being followed closely, the easiest strategy to deal with the tailgater is to get the tailgater in front of you by making room. Never accelerate to please or outdistance the tailgater. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":35063,"isBookmarked":0,"aid":"6286#6515#35063#43642","text":"Flash your brake light#Increase your speed#get the tailgater in front of you#not allow the tailgater to pass you"},
{"testId":52,"qid":2001,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. A separator or median will be used to split opposing lanes. Continue straight.","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#26319#29972","text":"The divided highway ends ahead#A divided highway starts ahead#The right lane is closed ahead#There is an underpass ahead"},
{"testId":52,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":334,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":431,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":462,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":491,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":495,"qid":2002,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#34814#37534","text":"you might be in a blind spot#your motorcycle might skid#a car driver might not be able to turn#a car driver's visibility might be reduced"},
{"testId":52,"qid":2003,"description":"You shouldn't drive in the same lane as a car or another vehicle because:","explanation":null,"imageName":"","correctAnswer":33951,"isBookmarked":0,"aid":"33951#33952#33953#43643","text":"cars and motorcycles each need a full lane width#most of the roads are too narrow for that#you can be hit by a sudden wind blast#car drivers might not allow you to share their lane"},
{"testId":8,"qid":2005,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign forbids automobiles from turning right. This sign is usually seen on the right side of the road or over a driving lane.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"40269#40334#41552#41553","text":"You may not turn right#You may not make a U-turn#You may turn right#You may not merge right"},
{"testId":52,"qid":2005,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign forbids automobiles from turning right. This sign is usually seen on the right side of the road or over a driving lane.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"40269#40334#41552#41553","text":"You may not turn right#You may not make a U-turn#You may turn right#You may not merge right"},
{"testId":52,"qid":2006,"description":"While riding through a potentially hazardous region, you should cover:","explanation":"When riding in high-risk locations, cover the clutch and both brakes to reduce the amount of time you need to respond.","imageName":"","correctAnswer":6122,"isBookmarked":0,"aid":"6121#6122#6123#6124","text":"your eyes with goggles#the clutch and both brakes#your head with a helmet#your whole travel lane"},
{"testId":429,"qid":2006,"description":"While riding through a potentially hazardous region, you should cover:","explanation":"When riding in high-risk locations, cover the clutch and both brakes to reduce the amount of time you need to respond.","imageName":"","correctAnswer":6122,"isBookmarked":0,"aid":"6121#6122#6123#6124","text":"your eyes with goggles#the clutch and both brakes#your head with a helmet#your whole travel lane"},
{"testId":52,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":351,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":367,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":422,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":475,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":479,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":495,"qid":2007,"description":"When approaching a junction, select a lane position to:","explanation":"When approaching a junction, adopt a lane position to enhance your visibility to other drivers.","imageName":"","correctAnswer":24583,"isBookmarked":0,"aid":"10656#24583#24584#24585","text":"Do all of the above#increase your visibility to other vehicle operators#increase your speed and pass quickly#reduce your following distance"},
{"testId":52,"qid":2008,"description":"How should you place your motorbike while parking beside a curb?","explanation":"Park at an angle with the back wheel towards the curb to park near to a curb. (Note: Some localities require motorbikes to be parked parallel to the curb.)","imageName":"","correctAnswer":40983,"isBookmarked":0,"aid":"6656#33921#40981#40983","text":"Parallel to the curb#At an angle with the rear wheel on the curb#At an angle with the front wheel to the curb#At an angle with the rear wheel to the curb"},
{"testId":52,"qid":2009,"description":"This symbol warns of:","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as buses and trucks. Vehicles may gain speed as they descend a steep incline. Keep an eye out for heavy vehicles and buses traveling at excessive speeds. Reduce brake wear by using engine braking. Shift down one gear at a time, easing out the clutch through the friction zone between each downshift, to use engine braking. Maintain the clutch in the friction zone until the engine speed is stable. Then, completely extend the lever until you are ready for the next downshift.","imageName":"202002101212538783.jpg","correctAnswer":37379,"isBookmarked":0,"aid":"23818#29861#37379#37380","text":"a slippery road#trucks carrying hazardous materials#a steep descent#trucks entering from a steep side road"},
{"testId":52,"qid":2010,"description":"To increase your visibility to other drivers, you should wear a helmet and bright clothes that are:","explanation":"Wearing luminous, brightly colored clothes and accessories may help you stand out from the crowd.","imageName":"","correctAnswer":43644,"isBookmarked":0,"aid":"43644#43645#43646#43647","text":"reflective and brightly colored#drab#dark-colored#non-reflective"},
{"testId":52,"qid":2014,"description":"To quickly and safely stop your motorcycle, you should apply:","explanation":"To swiftly stop your motorbike, use both the front and rear brakes at the same time, without locking any wheel.","imageName":"","correctAnswer":31116,"isBookmarked":0,"aid":"6504#7749#31116#31117","text":"the front brake only#the rear brake only#both the front and rear brakes at the same time#neither brake"},
{"testId":52,"qid":2017,"description":"This image's pavement markings imply that:","explanation":"A two-lane, two-way road may have a single broken yellow line. If it is safe to do so, vehicles on either side may pass.","imageName":"202003161208077161.jpg","correctAnswer":19629,"isBookmarked":0,"aid":"19628#19629#34939#41787","text":"passing is not allowed#passing is allowed#drivers must take a detour#drivers must turn"},
{"testId":52,"qid":2019,"description":"A group of motorcycles may pass through slow traffic on a motorway or interstate route:","explanation":"If a group in a staggered pattern has to pass sluggish traffic on a motorway or interstate route, they may do it as a unit. Riders should pass one at a time on a two-lane road.","imageName":"","correctAnswer":32932,"isBookmarked":0,"aid":"32931#32932#32933#43648","text":"only one at a time#as a unit#in a circular formation#only two at a time"},
{"testId":53,"qid":2021,"description":"Motorcycles are hard for vehicle drivers to see. Which of the following will raise their awareness of you?","explanation":"You should ride in a reasonable lane position, wear appropriate clothes, and use your lights to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6170#6171#23000#31451","text":"Wear proper clothing#Use your headlight#Do all of the above#Ride in a suitable lane position"},
{"testId":53,"qid":2022,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. This aids in maintaining equilibrium. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":6175,"isBookmarked":0,"aid":"6173#6174#6175#6176","text":"with your toes pointed downward#to drag lightly on the ground#firmly on the footrests#as far forward as possible"},
{"testId":483,"qid":2022,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. This aids in maintaining equilibrium. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":6175,"isBookmarked":0,"aid":"6173#6174#6175#6176","text":"with your toes pointed downward#to drag lightly on the ground#firmly on the footrests#as far forward as possible"},
{"testId":53,"qid":2023,"description":"Which of the following is NOT a component of the process of upshifting?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":6180,"isBookmarked":0,"aid":"6178#6179#6180#37552","text":"Lift the shift lever as far as it will go#Roll off the throttle as you squeeze the clutch#Gently apply the front brake while adjusting the throttle#Ease out the clutch and adjust the throttle"},
{"testId":53,"qid":2024,"description":"Downshifting is advised if you:","explanation":"When you need to halt or slow down, or if you need more power to accelerate, you should downshift.","imageName":"","correctAnswer":31101,"isBookmarked":0,"aid":"6181#6182#6183#31101","text":"are approaching a stop sign#need to slow down#need more power to accelerate#are in any of the above situations"},
{"testId":429,"qid":2024,"description":"Downshifting is advised if you:","explanation":"When you need to halt or slow down, or if you need more power to accelerate, you should downshift.","imageName":"","correctAnswer":31101,"isBookmarked":0,"aid":"6181#6182#6183#31101","text":"are approaching a stop sign#need to slow down#need more power to accelerate#are in any of the above situations"},
{"testId":52,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":53,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":299,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":314,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":318,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":343,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":458,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":491,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":503,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":507,"qid":2025,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":53,"qid":2026,"description":"When you need to stop, which brakes should you use?","explanation":"When you need to come to a complete stop, use both the front and back brakes. This will enable you to come to a safe halt.","imageName":"","correctAnswer":34526,"isBookmarked":0,"aid":"6189#6190#6191#34526","text":"The front brake#The rear brake#The emergency brake#Both the front and rear brakes"},
{"testId":318,"qid":2026,"description":"When you need to stop, which brakes should you use?","explanation":"When you need to come to a complete stop, use both the front and back brakes. This will enable you to come to a safe halt.","imageName":"","correctAnswer":34526,"isBookmarked":0,"aid":"6189#6190#6191#34526","text":"The front brake#The rear brake#The emergency brake#Both the front and rear brakes"},
{"testId":450,"qid":2026,"description":"When you need to stop, which brakes should you use?","explanation":"When you need to come to a complete stop, use both the front and back brakes. This will enable you to come to a safe halt.","imageName":"","correctAnswer":34526,"isBookmarked":0,"aid":"6189#6190#6191#34526","text":"The front brake#The rear brake#The emergency brake#Both the front and rear brakes"},
{"testId":495,"qid":2026,"description":"When you need to stop, which brakes should you use?","explanation":"When you need to come to a complete stop, use both the front and back brakes. This will enable you to come to a safe halt.","imageName":"","correctAnswer":34526,"isBookmarked":0,"aid":"6189#6190#6191#34526","text":"The front brake#The rear brake#The emergency brake#Both the front and rear brakes"},
{"testId":53,"qid":2027,"description":"What should you do to safely stop in a curve?","explanation":"When coming to a halt on a curve, attempt to keep your motorbike upright. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6196#10884#26482#30098","text":"Never stop in a curve#Use your rear brake only#Use your lean to enhance braking#Try to straighten your motorcycle upright"},
{"testId":347,"qid":2027,"description":"What should you do to safely stop in a curve?","explanation":"When coming to a halt on a curve, attempt to keep your motorbike upright. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6196#10884#26482#30098","text":"Never stop in a curve#Use your rear brake only#Use your lean to enhance braking#Try to straighten your motorcycle upright"},
{"testId":495,"qid":2027,"description":"What should you do to safely stop in a curve?","explanation":"When coming to a halt on a curve, attempt to keep your motorbike upright. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6196#10884#26482#30098","text":"Never stop in a curve#Use your rear brake only#Use your lean to enhance braking#Try to straighten your motorcycle upright"},
{"testId":53,"qid":2028,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, examine where you want to go, squeeze the handlegrip on the side of the direction you want to turn, then roll on the throttle to perform a good turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":347,"qid":2028,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, examine where you want to go, squeeze the handlegrip on the side of the direction you want to turn, then roll on the throttle to perform a good turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":53,"qid":2029,"description":"Maintaining or accelerating during a turn:","explanation":"Maintaining or slightly increasing your speed in a turn aids with motorcycle stability.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7596#24796#33607","text":"helps stabilize the motorcycle#should never be done#should only be done when passing others#is safer because you'll complete the turn sooner"},
{"testId":82,"qid":2029,"description":"Maintaining or accelerating during a turn:","explanation":"Maintaining or slightly increasing your speed in a turn aids with motorcycle stability.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7596#24796#33607","text":"helps stabilize the motorcycle#should never be done#should only be done when passing others#is safer because you'll complete the turn sooner"},
{"testId":53,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":420,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":434,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":462,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":479,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":507,"qid":2030,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30472,"isBookmarked":0,"aid":"7176#7178#10986#30472","text":"The center lane position#the left lane position#the right lane position#the one in which you are most likely to be seen"},
{"testId":53,"qid":2031,"description":"The Alabama Motorcycle Manual suggests that you keep a following distance of at least ________ behind the vehicle ahead.","explanation":"According to the Alabama Motorcycle Manual, you should maintain a following distance of at least two seconds behind the vehicle in front of you. If your motorbike takes longer to stop than normal, increase your following distance.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1287#1288#4096#4097","text":"two seconds#four seconds#three seconds#five seconds"},
{"testId":53,"qid":2032,"description":"When preparing to pass the vehicle in front of you, ride in the _______ portion of your lane to increase your field of sight.","explanation":"Ride on the left side of your lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":53,"qid":2033,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":53,"qid":2034,"description":"When is it safe to share a lane with a car?","explanation":"Never share a lane with another vehicle. A motorbike needs the whole width of the lane to move safely. It is prohibited in Alabama for an automobile to share your lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#8087#35117","text":"Never#In heavy traffic#In designated areas#When you're riding in a group"},
{"testId":53,"qid":2035,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE approach consists of three steps: SEARCH your surroundings, EVALUATE the threats around you, and EXECUTE your choice.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":53,"qid":2036,"description":"Why should you ride in the left lane if you're passing parked cars?","explanation":"Ride in the left lane while passing parked automobiles to prevent difficulties caused by car doors opening into your direction of travel, drivers getting out of cars, or individuals stepping from between cars.","imageName":"","correctAnswer":6226,"isBookmarked":0,"aid":"6226#22790#22791#35084","text":"You may avoid problems from doors opening#You are more likely to hit a car in the right lane position#The center lane position is too slippery#You should always ride in the left lane position"},
{"testId":53,"qid":2037,"description":"When you park your motorcycle in a parallel space, it should be:","explanation":"When parking in a parallel parking place, position your motorbike at an angle with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":53,"qid":2038,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#28385","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#using your turn signals"},
{"testId":306,"qid":2038,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#28385","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#using your turn signals"},
{"testId":467,"qid":2038,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#28385","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#using your turn signals"},
{"testId":53,"qid":2039,"description":"To improve your nighttime safety, you should:","explanation":"When riding at night, you may improve your safety by utilizing your high beam, slowing down, increasing your following distance, and relying on the lights of the automobile ahead of you.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6920#8535#10656#37538","text":"Increase your following distance#Reduce your speed#Do all of the above#use the lights of the car ahead of you to help you see"},
{"testId":310,"qid":2039,"description":"To improve your nighttime safety, you should:","explanation":"When riding at night, you may improve your safety by utilizing your high beam, slowing down, increasing your following distance, and relying on the lights of the automobile ahead of you.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6920#8535#10656#37538","text":"Increase your following distance#Reduce your speed#Do all of the above#use the lights of the car ahead of you to help you see"},
{"testId":434,"qid":2039,"description":"To improve your nighttime safety, you should:","explanation":"When riding at night, you may improve your safety by utilizing your high beam, slowing down, increasing your following distance, and relying on the lights of the automobile ahead of you.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6920#8535#10656#37538","text":"Increase your following distance#Reduce your speed#Do all of the above#use the lights of the car ahead of you to help you see"},
{"testId":53,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":314,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":434,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":479,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":487,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":503,"qid":2040,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":53,"qid":2041,"description":"Which lanes could be dangerous when it starts to rain?","explanation":"When it begins to rain, the center lane position might be dangerous due to the accumulation of oil and fluid. Riding in the tire tracks of other cars is the safest option.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":450,"qid":2041,"description":"Which lanes could be dangerous when it starts to rain?","explanation":"When it begins to rain, the center lane position might be dangerous due to the accumulation of oil and fluid. Riding in the tire tracks of other cars is the safest option.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":467,"qid":2041,"description":"Which lanes could be dangerous when it starts to rain?","explanation":"When it begins to rain, the center lane position might be dangerous due to the accumulation of oil and fluid. Riding in the tire tracks of other cars is the safest option.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":53,"qid":2042,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"With a passenger, your motorbike will react more slowly. As a result, you should ride a little slower, particularly when approaching curves, bends, or bumps.","imageName":"","correctAnswer":6250,"isBookmarked":0,"aid":"6250#6251#30769#40461","text":"Ride slower#Ride faster#Ride only in the left lane position#Ride only in the center lane position"},
{"testId":53,"qid":2043,"description":"When transporting cargo in saddlebags,you should:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":53,"qid":2044,"description":"Is sharing a lane with another motorbike safe?","explanation":"Motorcycles may lawfully go two abreast (pair up) in a single lane in Alabama. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":53,"qid":2045,"description":"Motorcyclists should ride _____, when riding in a group.","explanation":"Motorcyclists should normally ride in a staggered arrangement while riding in a group. It keeps the group together while yet providing enough space.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":54,"qid":2046,"description":"Your arms should be positioned _________, while clutching the handlebars.","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":6269,"isBookmarked":0,"aid":"6266#6267#6268#6269","text":"with elbows at 90 degrees#anywhere on the handlegrips#with elbows locked#so they are slightly bent"},
{"testId":54,"qid":2047,"description":"When starting a motorcycle uphill, switch to ______ to hold the bike while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":330,"qid":2047,"description":"When starting a motorcycle uphill, switch to ______ to hold the bike while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":499,"qid":2047,"description":"When starting a motorcycle uphill, switch to ______ to hold the bike while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":507,"qid":2047,"description":"When starting a motorcycle uphill, switch to ______ to hold the bike while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":54,"qid":2049,"description":"If someone is following you too closely, you must:","explanation":"If you are being tailgated, touch the brake before slowing down. This will cause your brake light to flash to notify the tailgater.","imageName":"202003201758501730.jpg","correctAnswer":41049,"isBookmarked":0,"aid":"6278#6280#41048#41049","text":"Beep your horn#pull over and stop#slam on the brakes to warn the driver#tap the brake before you start to slow down"},
{"testId":298,"qid":2049,"description":"If someone is following you too closely, you must:","explanation":"If you are being tailgated, touch the brake before slowing down. This will cause your brake light to flash to notify the tailgater.","imageName":"202003201758501730.jpg","correctAnswer":41049,"isBookmarked":0,"aid":"6278#6280#41048#41049","text":"Beep your horn#pull over and stop#slam on the brakes to warn the driver#tap the brake before you start to slow down"},
{"testId":54,"qid":2050,"description":"Which lane location is optimal for being seen by the driver of the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":511,"qid":2050,"description":"Which lane location is optimal for being seen by the driver of the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":54,"qid":2051,"description":"If you intend to pass another vehicle but believe it may move into your lane, you should :","explanation":"If you want to pass another car but believe it may move into your lane, use your horn to alert the driver.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#6287#6288","text":"Beep your horn#Flash your brake light#not pass#pass as fast as possible"},
{"testId":54,"qid":2053,"description":"When riding around a curve, you should:","explanation":"When riding through a curve, adjust your lane position as required to enhance your line of sight and provide the greatest picture of what is ahead.","imageName":"","correctAnswer":31135,"isBookmarked":0,"aid":"31133#31134#31135#31136","text":"stay in the center lane position#stay in the left lane position#Change your lane position as needed#stay in the right lane position"},
{"testId":54,"qid":2054,"description":"A following distance of at least ________ is suggested for safe riding in Alaska.","explanation":"According to the Alaska Motorcycle Operator Manual, a following distance of at least four seconds behind the vehicle ahead is recommended. Increase your following distance even further in difficult riding conditions.","imageName":"","correctAnswer":1288,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":54,"qid":2057,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Unless a tire is flat, you should always use both the front and rear brakes while stopping. When one tire goes flat, just use the brake on the other tire.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#6307","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternately apply the front and rear brakes"},
{"testId":54,"qid":2058,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6309#24831#24832","text":"Swerving from side to side#rising slightly off the seat#applying the brakes lightly#taking your feet off the foot pegs"},
{"testId":54,"qid":2059,"description":"Your motorcycle may tremble if you travel on metal bridge gratings. You ought to:","explanation":"If you find yourself swaying when riding over a metal grating, stop, keep on course, maintain your pace, and ride straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":42531,"isBookmarked":0,"aid":"6311#6476#7482#42531","text":"grip the handlebars tightly#Slow down#swerve from side to side#stay on course"},
{"testId":54,"qid":2060,"description":"If you're about to hit something and want the bike to lean right, you should:","explanation":"If you're going to crash with anything and want the motorbike to tilt to the right rapidly, press the inside of the right handlegrip.","imageName":"","correctAnswer":6317,"isBookmarked":0,"aid":"6315#6316#6317#6318","text":"push the inside of the left handlegrip#pull the left handlegrip toward you#push the inside of the right handlegrip#pull the right handlegrip toward you"},
{"testId":54,"qid":2061,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"With a passenger, your motorbike will react more slowly. As a result, you should ride a little slower, particularly when approaching curves, bends, or bumps.","imageName":"","correctAnswer":6319,"isBookmarked":0,"aid":"6319#6321#6322#42532","text":"Go slower when taking curves or corners#Increase the throttle when making turns#Make sure the gas tank is at least half full#Don't use saddlebags"},
{"testId":54,"qid":2062,"description":"When using saddlebags, be sure to:","explanation":"To keep your motorbike balanced while utilizing saddlebags, spread the weight equally across the saddlebags.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6323#6324#6325#6326","text":"fill one bag before the other one#only use them if they are completely full#only fill them with approved contents#Distribute the load evenly"},
{"testId":80,"qid":2062,"description":"When using saddlebags, be sure to:","explanation":"To keep your motorbike balanced while utilizing saddlebags, spread the weight equally across the saddlebags.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6323#6324#6325#6326","text":"fill one bag before the other one#only use them if they are completely full#only fill them with approved contents#Distribute the load evenly"},
{"testId":347,"qid":2062,"description":"When using saddlebags, be sure to:","explanation":"To keep your motorbike balanced while utilizing saddlebags, spread the weight equally across the saddlebags.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6323#6324#6325#6326","text":"fill one bag before the other one#only use them if they are completely full#only fill them with approved contents#Distribute the load evenly"},
{"testId":54,"qid":2063,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6327#6328#6329#40986","text":"at the end of the group#behind the leader#at the front of the group#in front of the sweep rider"},
{"testId":54,"qid":2064,"description":"When is it safe to ride in the same lane with another motorcycle?","explanation":"The key word here is \"safely.\" Two motorbikes may lawfully share a lane in Alaska. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. To avoid a danger, you may need to utilize the whole width of the lane. [13 AAC 02 .425]","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6331#6334#8034#42533","text":"On straight highways#Never#When you are riding in a group#On multilane roadways"},
{"testId":54,"qid":2065,"description":"When should you avoid the staggered formation when riding in a group?","explanation":"When riding in a group, avoid the staggered configuration when entering or leaving highways, taking bends, turning, or traveling anyplace else where your lane position may need to be changed. In such cases, you should bike in single line.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"6336#6337#23040#34233","text":"Taking curves#Making turns#Doing any of the above#Entering or exiting highways"},
{"testId":479,"qid":2065,"description":"When should you avoid the staggered formation when riding in a group?","explanation":"When riding in a group, avoid the staggered configuration when entering or leaving highways, taking bends, turning, or traveling anyplace else where your lane position may need to be changed. In such cases, you should bike in single line.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"6336#6337#23040#34233","text":"Taking curves#Making turns#Doing any of the above#Entering or exiting highways"},
{"testId":507,"qid":2065,"description":"When should you avoid the staggered formation when riding in a group?","explanation":"When riding in a group, avoid the staggered configuration when entering or leaving highways, taking bends, turning, or traveling anyplace else where your lane position may need to be changed. In such cases, you should bike in single line.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"6336#6337#23040#34233","text":"Taking curves#Making turns#Doing any of the above#Entering or exiting highways"},
{"testId":54,"qid":2066,"description":"While riding a motorcycle. head checks:","explanation":"Before changing lanes, do a head check.","imageName":"","correctAnswer":6342,"isBookmarked":0,"aid":"6339#6342#35085#35086","text":"only need to be done at night#should be performed before changing lanes#are not needed; motorcycles don't have blind spots#are only needed when you're preparing to turn left"},
{"testId":54,"qid":2067,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle so that you can see effectively in both ways.","imageName":"","correctAnswer":26493,"isBookmarked":0,"aid":"6344#6346#26493#26494","text":"Parallel to the roadside#With the rear tire off the road#At an angle to the roadside#Facing the roadside"},
{"testId":339,"qid":2067,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle so that you can see effectively in both ways.","imageName":"","correctAnswer":26493,"isBookmarked":0,"aid":"6344#6346#26493#26494","text":"Parallel to the roadside#With the rear tire off the road#At an angle to the roadside#Facing the roadside"},
{"testId":54,"qid":2068,"description":"If you detect a car on an entry ramp about to merge into your lane on the highway, you should:","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#6349","text":"Change lanes#Beep your horn#Flash your brake light#accelerate to pass the car"},
{"testId":82,"qid":2068,"description":"If you detect a car on an entry ramp about to merge into your lane on the highway, you should:","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#6349","text":"Change lanes#Beep your horn#Flash your brake light#accelerate to pass the car"},
{"testId":294,"qid":2068,"description":"If you detect a car on an entry ramp about to merge into your lane on the highway, you should:","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#6349","text":"Change lanes#Beep your horn#Flash your brake light#accelerate to pass the car"},
{"testId":507,"qid":2068,"description":"If you detect a car on an entry ramp about to merge into your lane on the highway, you should:","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#6349","text":"Change lanes#Beep your horn#Flash your brake light#accelerate to pass the car"},
{"testId":54,"qid":2069,"description":"The pavement is most likely to be slick:","explanation":"When it begins to rain, the pavement might become slick. Rainwater dissolves oil and other fluids that have accumulated in the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":6352,"isBookmarked":0,"aid":"6350#6351#6352#35072","text":"at night#when it is hot from the sun#just after it starts to rain#where other vehicles have been traveling"},
{"testId":54,"qid":2070,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____ angle.","explanation":null,"imageName":"","correctAnswer":35303,"isBookmarked":0,"aid":"35303#35304#35305#35306","text":"45 degrees#90 degrees#20 degrees#120 degrees"},
{"testId":55,"qid":2071,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake is responsible for 75% of your motorcycle's stopping force.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":55,"qid":2072,"description":"Taking hold of the front brake lever:","explanation":"The wheel may lock if you grab the front brake. Instead, use consistent pressure.","imageName":"","correctAnswer":6363,"isBookmarked":0,"aid":"6361#6362#6363#22246","text":"is the most effective way to stop#should only be done in emergencies#could cause the wheel to lock#is only possible when you are already applying the rear brake"},
{"testId":55,"qid":2073,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"When countersteering, you steer in the opposite direction that you wish to turn. As a result, the motorbike leans for the turn.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6366#6368#10156","text":"Turn left#make a U-turn only#Turn right#Go straight"},
{"testId":55,"qid":2074,"description":"What should you do on the third step of passing?","explanation":"When passing, indicate left, then go into the left lane and accelerate when safe. Step 3: Signal right and check your mirrors and headlights before returning to your previous lane and canceling the signal. Passing must be done within established speed limits and only when authorized.","imageName":"moto12-2.png","correctAnswer":45568,"isBookmarked":0,"aid":"45565#45566#45567#45568","text":"Use your horn#Cancel the signal#Signal left#Signal right"},
{"testId":55,"qid":2075,"description":"When performing a gradual, tight turn, the rider should:","explanation":"When performing a gradual, tight turn, the rider should maintain a straight torso while leaning the motorbike. This will assist in keeping the motorbike from flipping over.","imageName":"","correctAnswer":6375,"isBookmarked":0,"aid":"6375#10686#22248#22249","text":"keep his or her body straight while leaning the motorcycle#lean in the opposite direction of the motorcycle#lean toward the front of the motorcycle#lean in the same direction as the motorcycle"},
{"testId":55,"qid":2076,"description":"How many lane positions are available for a motorcycle in a traffic lane?","explanation":"A motorbike has three lane locations in each lane of traffic: left, center, and right.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":55,"qid":2077,"description":"Changing your lane position may ____________.","explanation":"Changing lanes may express your intentions to other drivers, keep you out of a blind zone, and give an escape route.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6381#10656#22250#22251","text":"communicate your intentions to other drivers#Do all of the above#help you avoid being in a blind spot#provide a possible escape route"},
{"testId":55,"qid":2078,"description":"In areas with oil and grease accumulation, the optimal lane position is:","explanation":"In areas where oil and grease have accumulated, the optimal lane position is to the left or right, where previous tires have driven.","imageName":"","correctAnswer":22252,"isBookmarked":0,"aid":"6248#6385#6387#22252","text":"the center#In the blind spot#Not important#the left or the right"},
{"testId":55,"qid":2079,"description":"Maintain a _____-second following distance while following a vehicle.","explanation":"Maintain a two-second following gap while following a vehicle.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":55,"qid":2080,"description":"The most effective technique to deal with a tailgater is to:","explanation":"Allowing a tailgater to pass you is the greatest approach to deal with him or her.","imageName":"202006041812161292.jpg","correctAnswer":22253,"isBookmarked":0,"aid":"6278#6280#6392#22253","text":"Beep your horn#pull over and stop#Call the police#let him or her pass you"},
{"testId":55,"qid":2081,"description":"The Class M1/M2 permit carries the following restrictions:","explanation":"The following limitations apply to the Class M1/M2 permit: 1) no transporting passengers, 2) no interstate driving, and 3) no midnight driving.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#22254#22255#22256","text":"All of the above#No carrying passengers#No freeway driving#No nighttime driving"},
{"testId":55,"qid":2082,"description":"What are you thinking about when you performing the Evaluate phase of the SEE procedure?","explanation":"You consider cars that may move into your path, dangerous road conditions, and traffic markings and signals during the Evaluate part of the SEE method.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#6398#10831","text":"All of the above#Vehicles that may move into your path#Traffic markings and signals#Possibly hazardous road conditions"},
{"testId":55,"qid":2083,"description":"When someone is passing you from behind,:","explanation":"Stay in the middle of your lane if you are being passed from behind. Riding too near to a passing car might put you in danger.","imageName":"moto13.png","correctAnswer":45571,"isBookmarked":0,"aid":"33641#45569#45570#45571","text":"Accelerate#move into the portion of the lane farthest from the passing vehicle#move to the left portion of your lane#stay in the center portion of your lane"},
{"testId":55,"qid":2084,"description":"Which lane position should you ride in if there is incoming traffic and you are passing a lane of parked cars?","explanation":"If there is incoming traffic and you are passing a line of parked automobiles, ride in the middle lane to put the most distance between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":55,"qid":2085,"description":"When parking along the side of the road, you should park:","explanation":"When parked on the side of the road, your motorbike should be angled 45 to 90 degrees to the curb, with a wheel or fender contacting the curb.","imageName":"","correctAnswer":6406,"isBookmarked":0,"aid":"6404#6406#6407#22257","text":"parallel to the road#at an angle of 45 to 90 degrees to the curb#with your front wheel on the curb#at a 25-degree angle"},
{"testId":55,"qid":2086,"description":"Which part of the lane should you be in before passing?","explanation":"Passing and being passed by another vehicle is similar to driving a car. However, visibility is more important. Before beginning to pass, bike in the left lane at a safe following distance to maximize your line of sight and visibility. Check for incoming traffic and signal left. Check your mirrors and swivel your head to check for oncoming vehicles.","imageName":"moto12-1.png","correctAnswer":45573,"isBookmarked":0,"aid":"45572#45573#45574#45575","text":"The right portion of the lane at a safe following distance#The left portion of the lane at a safe following distance#The center portion of the lane at a safe following distance#The left portion of the lane, as close as possible to the vehicle you are going to pass"},
{"testId":55,"qid":2087,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"On a road with numerous lanes, examine both the distant lane and the one close to you. A motorist in the distant lane may move into the same place you want to take.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6411#6412#10812#10813","text":"your signal light#the lane next to you and the far lane#only the lane next to you#only the far lane"},
{"testId":55,"qid":2088,"description":"Increase your nighttime safety by:","explanation":"You can remain safe at night by increasing your following distance, riding with your high beams on, and utilising the lights of the vehicle in front of you to help you see.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6414#6415#6416#10777","text":"using the lights of the car ahead to help you see#riding with your high beam#increasing your following distance#Doing all of the above"},
{"testId":55,"qid":2089,"description":"The quickest method to come to a halt is to:","explanation":"Apply both brakes at the same moment to come to a complete halt.","imageName":"","correctAnswer":6419,"isBookmarked":0,"aid":"6419#22259#22260#22261","text":"apply both brakes at the same time#use only the front brake#use only the rear brake#try to skid the rear tire"},
{"testId":55,"qid":2090,"description":"As you get closer to the center of a curve, you should:","explanation":"You should shift toward the inside of the curve as you get closer to the turn's centre. You should go toward the outside of the curve after you have passed the turn's centre.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#6424#10833#10834","text":"Apply the rear brake#decrease the throttle#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":55,"qid":2091,"description":"If you cannot avoid an obstacle and must drive over it, approach it at a _____ angle.","explanation":"If you cannot avoid an obstruction and must drive over it, approach it at a 90-degree angle. This reduces your chances of losing traction.","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6354#6355#6425#6426","text":"45#90#25#135"},
{"testId":55,"qid":2092,"description":"Surfaces that provide limited traction for a motorcycle include:","explanation":"Motorcycles have low grip on gravel roads with loose pebbles and mud.","imageName":"","correctAnswer":6429,"isBookmarked":0,"aid":"6428#6429#6430#22262","text":"lanes that are not often used#gravel roads#pavement that is hot from the sun#tire markings, where other vehicles have traveled"},
{"testId":55,"qid":2093,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If the motorbike begins to wobble, carefully close the throttle. Fighting the wobble, speeding, or braking fast might aggravate it.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6431#6432#6433#6434","text":"Grip the handlebars tightly and fight the wobble#Close the throttle gradually#Accelerate out of the wobble#Apply the brakes quickly"},
{"testId":55,"qid":2094,"description":"The optimum formation for biking in a group is the ___________ arrangement.","explanation":"When riding in a group, the staggered arrangement is the optimum. It allows the group to be near while maintaining a safe spacing cushion.","imageName":"","correctAnswer":22265,"isBookmarked":0,"aid":"6436#22263#22264#22265","text":"side-by-side#single-file#V-shaped#staggered"},
{"testId":55,"qid":2095,"description":"When you have a passenger on board, your motorcycle will:","explanation":"Your motorbike will be heavier and react slower than usual while carrying a passenger.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#22266","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same way as it does without a passenger"},
{"testId":56,"qid":2097,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#22267#33589","text":"holding yourself up#steering#rolling the throttle#making hand signals"},
{"testId":56,"qid":2099,"description":"How can you maximize stopping power?","explanation":"Maximum stopping power may be obtained by completely using both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":43142,"isBookmarked":0,"aid":"10838#24897#38694#43142","text":"Apply the rear brake only#Alternate between the front and rear brakes#Apply the front brake only#Apply the front and rear brakes at the same time"},
{"testId":56,"qid":2101,"description":"You should turn _____ when riding through a turn.","explanation":"Corner your head solely when cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":6465,"isBookmarked":0,"aid":"6463#6464#6465#9855","text":"your head and shoulders#your whole upper body#your head only#Any of the above"},
{"testId":467,"qid":2101,"description":"You should turn _____ when riding through a turn.","explanation":"Corner your head solely when cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":6465,"isBookmarked":0,"aid":"6463#6464#6465#9855","text":"your head and shoulders#your whole upper body#your head only#Any of the above"},
{"testId":503,"qid":2101,"description":"You should turn _____ when riding through a turn.","explanation":"Corner your head solely when cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":6465,"isBookmarked":0,"aid":"6463#6464#6465#9855","text":"your head and shoulders#your whole upper body#your head only#Any of the above"},
{"testId":56,"qid":2103,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"Each traffic lane features three lane places where a motorbike may travel: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":503,"qid":2103,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"Each traffic lane features three lane places where a motorbike may travel: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":56,"qid":2104,"description":"Which lane should you ride in if there are hazards on your right?","explanation":"If there are risks to your right, ride in lane number one (left) or two (middle) to avoid them.","imageName":"","correctAnswer":28388,"isBookmarked":0,"aid":"28388#28389#28390#28391","text":"One (left) or two (center)#One (left)#Two (center)#Three (right)"},
{"testId":503,"qid":2104,"description":"Which lane should you ride in if there are hazards on your right?","explanation":"If there are risks to your right, ride in lane number one (left) or two (middle) to avoid them.","imageName":"","correctAnswer":28388,"isBookmarked":0,"aid":"28388#28389#28390#28391","text":"One (left) or two (center)#One (left)#Two (center)#Three (right)"},
{"testId":56,"qid":2105,"description":"The Arkansas Motorcycle Operator Manual recommends keeping a following distance of at least _______ behind the vehicle in front of you.","explanation":"The Arkansas Motorcycle Operator Manual recommends keeping a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":318,"qid":2105,"description":"The Arkansas Motorcycle Operator Manual recommends keeping a following distance of at least _______ behind the vehicle in front of you.","explanation":"The Arkansas Motorcycle Operator Manual recommends keeping a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":56,"qid":2108,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"When looking for dangers on the road, look 12 seconds ahead on your route of travel. This allows you to prepare for and plan for a problem before it becomes an imminent threat. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#7322#39562","text":"5 seconds#12 seconds#3 seconds#30 seconds"},
{"testId":56,"qid":2111,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#43143","text":"leather and tough#bright and reflective#colorful and big#comfortable and loose"},
{"testId":56,"qid":2112,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":429,"qid":2112,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":438,"qid":2112,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":499,"qid":2112,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":511,"qid":2112,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":56,"qid":2113,"description":"The majority of motorbikes include convex side mirrors. Convex mirrors make automobiles seem :","explanation":"Convex side mirrors on a motorbike provide a broad field of vision, but they make automobiles and other things seem farther away than they are.","imageName":"","correctAnswer":10429,"isBookmarked":0,"aid":"10426#10428#10429#39853","text":"closer than they are#smaller than they are#farther away than they are#larger than they are"},
{"testId":56,"qid":2114,"description":"Which of the following is NOT a method of increasing your nighttime safety?","explanation":"Wearing luminous clothes, utilizing your high beam, increasing your following distance, and slowing down can improve your nighttime safety. Shifting gears fast will not improve your nighttime safety.","imageName":"","correctAnswer":22282,"isBookmarked":0,"aid":"6238#6239#8123#22282","text":"Reduce your speed#Increase your following distance#Use your high beam#Shift gears quickly"},
{"testId":503,"qid":2114,"description":"Which of the following is NOT a method of increasing your nighttime safety?","explanation":"Wearing luminous clothes, utilizing your high beam, increasing your following distance, and slowing down can improve your nighttime safety. Shifting gears fast will not improve your nighttime safety.","imageName":"","correctAnswer":22282,"isBookmarked":0,"aid":"6238#6239#8123#22282","text":"Reduce your speed#Increase your following distance#Use your high beam#Shift gears quickly"},
{"testId":56,"qid":2115,"description":"To come to a complete stop as quickly as feasible, apply:","explanation":"Apply both the front and rear brakes at the same moment to come to a complete stop as fast as feasible.","imageName":"","correctAnswer":31116,"isBookmarked":0,"aid":"6504#31116#43144#43145","text":"the front brake only#both the front and rear brakes at the same time#the front brake and then the rear brake#the rear brake and then the front brake"},
{"testId":56,"qid":2119,"description":"If you're riding in a group and need to pass the vehicle ahead of you, you should:","explanation":"When cycling in a group, bikers should pass one at a time. Wait for the cyclist in front of you to finish their pass before beginning yours.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":57,"qid":2121,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"Start with your right wrist down to avoid mistakenly utilizing too much throttle.","imageName":"","correctAnswer":6526,"isBookmarked":0,"aid":"6525#6526#6527#6528","text":"up#down#straight#locked"},
{"testId":57,"qid":2123,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"By flashing your brake light, wearing bright clothes, and utilizing your signals, you may boost your visibility to other vehicles.","imageName":"","correctAnswer":6241,"isBookmarked":0,"aid":"6241#10784#10785#22771","text":"All these answers are correct#Wear bright clothing#Use signal lights#Flash your brake lights to warn drivers"},
{"testId":57,"qid":2124,"description":"In which of these situations is checking your mirrors the LEAST important?","explanation":"When descending a big slope, it is least vital to check your mirrors.","imageName":"","correctAnswer":24065,"isBookmarked":0,"aid":"6537#6661#24064#24065","text":"Before you change lanes#When you are stopped at an intersection#When you are starting to slow down#When you are descending a large hill"},
{"testId":57,"qid":2125,"description":"How many lane places are available to a motorcycle in a single lane of traffic?","explanation":"A motorbike has three lane slots available in a single lane of traffic.","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":57,"qid":2126,"description":"Which lane position will make you the most noticeable to the driver if you are following a car?","explanation":"Riding in the middle lane makes you the most conspicuous. Drivers are looking in their rearview mirror more often, increasing the likelihood that they may spot you.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":57,"qid":2127,"description":"If the vehicle ahead of you suddenly stops, a ______-second following distance offers you the bare minimum amount of distance to stop or swerve.","explanation":"By allowing at least two seconds between yourself and the car in front of you, you give yourself enough distance to stop or swerve if the motorist ahead abruptly stops.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#5002#6209#10514","text":"Two#Five#Three#One"},
{"testId":57,"qid":2128,"description":"You can keep a sufficient spacing cushion by:","explanation":"You may maintain an appropriate space cushion by altering your lane position as you ride to be as far away from risks as possible.","imageName":"","correctAnswer":6541,"isBookmarked":0,"aid":"6541#6542#6543#6544","text":"adjusting your lane position as you ride#speeding up when approached by tailgaters#always riding in the center lane position#beeping your horn at close drivers"},
{"testId":57,"qid":2129,"description":"When going by a line of parked cars, it is best to ride in the _____ lane position.","explanation":"Driving in the left lane is preferable when overtaking a line of parked automobiles. This helps to prevent the risks that come with parked autos.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":57,"qid":2130,"description":"The most effective technique to deal with a tailgater is to:","explanation":"Allow tailgaters to pass you if possible.","imageName":"202006041812161292.jpg","correctAnswer":6391,"isBookmarked":0,"aid":"6278#6391#6476#6548","text":"Beep your horn#allow them to pass you#Slow down#speed up to match their speed"},
{"testId":57,"qid":2131,"description":"Which of the following surfaces is not safe for motorcycles to ride on?","explanation":"Motorcyclists should avoid riding across metal grates, gravel roads, and steel plates. Use extreme care while walking on such surfaces.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6549#6551#6717","text":"All of the above#Metal grates#Steel plates#Gravel roads"},
{"testId":57,"qid":2132,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"USE BOTH BRAKES TO RIDE SAFELY ON SLIPPERY SURFACE. Even on a slick surface, the front brake is more effective than the rear brake. Apply it slowly to prevent locking the front wheel.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6554#6555","text":"riding in the left lane position#only using one brake#using both brakes#riding in the right lane position"},
{"testId":57,"qid":2133,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety on ice. You may use your feet to keep the motorbike from falling.","imageName":"","correctAnswer":24066,"isBookmarked":0,"aid":"24066#24067#24068#24069","text":"When you are riding on ice#When you are riding in a parking lot#When you are riding on a metal grating#When you are crossing railroad tracks"},
{"testId":57,"qid":2134,"description":"You may improve your nighttime safety by:","explanation":"Increase your following distance while riding at night to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":57,"qid":2135,"description":"To come to a complete stop as quickly as feasible, you should:","explanation":"To come to a complete stop as rapidly as feasible, utilize both brakes at the same moment.","imageName":"","correctAnswer":22813,"isBookmarked":0,"aid":"6564#22259#22260#22813","text":"use either brake while swerving the motorcycle#use only the front brake#use only the rear brake#use both brakes at the same time"},
{"testId":57,"qid":2136,"description":"When going around a turn, your lane position should :","explanation":"When approaching a turn, adjust your lane position to provide the greatest view of any dangers ahead.","imageName":"","correctAnswer":6565,"isBookmarked":0,"aid":"6565#6566#6567#6568","text":"change to give you the best view#be in the center of the lane#stay as close to the center line as possible#stay as close to the end of the road as possible"},
{"testId":57,"qid":2137,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":57,"qid":2138,"description":"If a dog begins to chase you while driving, you should:","explanation":"If a dog starts after you, you should approach the beast and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":6571,"isBookmarked":0,"aid":"6570#6571#6572#24070","text":"kick the dog#approach the animal and speed away#stop until the owner is found#call Animal Protection"},
{"testId":57,"qid":2139,"description":"When transporting cargo, it is critical to:","explanation":"When transporting goods, it is critical to keep the load light in order to maintain a low center of gravity.","imageName":"","correctAnswer":6576,"isBookmarked":0,"aid":"6574#6576#6577#24071","text":"weigh it before loading it on the motorcycle#keep the load low#not carry a passenger as well#secure it just to the sides of the motorcycle"},
{"testId":57,"qid":2140,"description":"Which member in a riding group sets the pace?","explanation":"The tail rider sets the pace while cycling in a group. If the person in front of you slows down, you should slow down as well to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":57,"qid":2141,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, try twisting it back and forth.","imageName":"","correctAnswer":6583,"isBookmarked":0,"aid":"6423#6569#6582#6583","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Twist it back and forth to free it"},
{"testId":57,"qid":2142,"description":"What approaches can be employed to avoid a roadblock?","explanation":"You may dodge an obstruction on the road by stopping or swerving.","imageName":"","correctAnswer":6585,"isBookmarked":0,"aid":"6584#6585#6586#6587","text":"Stopping or sliding#Stopping or swerving#Swerving or sliding#Swerving or leaning"},
{"testId":57,"qid":2143,"description":"What's the best way to find bumps in the road when you're riding at night?","explanation":"The taillights of the car ahead of you are the greatest method to detect irregularities in the road while riding at night. If you see the taillights bouncing up and down, the car is travelling over bumps.","imageName":"","correctAnswer":32044,"isBookmarked":0,"aid":"8123#10682#22970#32044","text":"Use your high beam#Scan the road often#Only drive where there are street lights#Use the taillights of the car ahead of you"},
{"testId":57,"qid":2144,"description":"Crossing an unavoidable obstruction at a _____-degree angle is the safest option.","explanation":"A 90-degree angle is the safest approach to pass a barrier.","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5448#6354#6355#6591","text":"20#45#90#100"},
{"testId":57,"qid":2145,"description":"The road's edge can be extremely dangerous because:","explanation":"The road's edge may be highly hazardous since mud and sand accumulate and give minimal grip for motorcyclists.","imageName":"","correctAnswer":24072,"isBookmarked":0,"aid":"6593#6594#6595#24072","text":"it is difficult to initiate turns#the right lane position has the most hazards#the right edge is driven on the least#dirt and sand build up at the edge"},
{"testId":58,"qid":2148,"description":"To maintain proper control of your motorcycle, position your arms:","explanation":"To retain proper control of the motorbike, place your arms with your elbows slightly bent.","imageName":"","correctAnswer":6605,"isBookmarked":0,"aid":"6268#6604#6605#22958","text":"with elbows locked#with wrists locked#with elbows slightly bent#At a 90-degree angle"},
{"testId":330,"qid":2148,"description":"To maintain proper control of your motorcycle, position your arms:","explanation":"To retain proper control of the motorbike, place your arms with your elbows slightly bent.","imageName":"","correctAnswer":6605,"isBookmarked":0,"aid":"6268#6604#6605#22958","text":"with elbows locked#with wrists locked#with elbows slightly bent#At a 90-degree angle"},
{"testId":58,"qid":2153,"description":"The best lane position should:","explanation":"The best lane location should prevent other cars' blind areas and wind blasts while also making you visible to other road users.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43961#43962#43963","text":"Do all of the above#avoid other vehicles' blind spots#avoid wind blasts from other vehicles#make yourself visible to other road users"},
{"testId":58,"qid":2154,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, you should bike in lane two (middle) or three (right) to avoid them.","imageName":"","correctAnswer":29452,"isBookmarked":0,"aid":"28389#28390#29452#43964","text":"One (left)#Two (center)#Two (center) or three (right)#One (left) or three (right)"},
{"testId":58,"qid":2155,"description":"You should keep a following distance of at least _______ behind the vehicle in front of you.","explanation":"You should maintain a following distance of at least two seconds behind the vehicle in front of you, according to the Connecticut Motorcycle Operator Manual. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":330,"qid":2155,"description":"You should keep a following distance of at least _______ behind the vehicle in front of you.","explanation":"You should maintain a following distance of at least two seconds behind the vehicle in front of you, according to the Connecticut Motorcycle Operator Manual. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":58,"qid":2160,"description":"When is it safe to share a lane with another vehicle?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#35515#35517#43297","text":"Never#If the other vehicle is a motorcycle#If you're riding in the right lane position#If you're riding between two lanes of moving traffic"},
{"testId":58,"qid":2163,"description":"What can you do to reduce your reaction time?","explanation":"Cover the clutch and the brakes to shorten your response time and allow you to respond more rapidly.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"10818#10819#27089#30514","text":"Decrease your following distance#Hover your feet above the ground#Cover the clutch and both brakes#Lock your elbows"},
{"testId":58,"qid":2165,"description":"How should you park your motorcycle when it's next to a curb?","explanation":"Park at an angle with the back wheel towards the curb to park near to a curb.","imageName":"","correctAnswer":40983,"isBookmarked":0,"aid":"6656#33921#40981#40983","text":"Parallel to the curb#At an angle with the rear wheel on the curb#At an angle with the front wheel to the curb#At an angle with the rear wheel to the curb"},
{"testId":58,"qid":2166,"description":"Which colors of clothing can make you more visible to other drivers?","explanation":"Orange, red, yellow, and green are the greatest apparel colors for improving your visibility to others.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#1147#1148#6659","text":"All of the above#Green#Orange#Yellow"},
{"testId":58,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":85,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":291,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":303,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":434,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":442,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":446,"qid":2167,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":59,"qid":2172,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":59,"qid":2173,"description":"Lower your risks of an accident by:","explanation":"You may reduce your chances of being involved in an accident by selecting an effective lane position, detecting several risks, and wearing bright clothes that allows you to be seen more readily.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6682#6683#6684#10777","text":"choosing an effective lane position#identifying multiple hazards#wearing bright clothing#Doing all of the above"},
{"testId":475,"qid":2173,"description":"Lower your risks of an accident by:","explanation":"You may reduce your chances of being involved in an accident by selecting an effective lane position, detecting several risks, and wearing bright clothes that allows you to be seen more readily.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6682#6683#6684#10777","text":"choosing an effective lane position#identifying multiple hazards#wearing bright clothing#Doing all of the above"},
{"testId":491,"qid":2173,"description":"Lower your risks of an accident by:","explanation":"You may reduce your chances of being involved in an accident by selecting an effective lane position, detecting several risks, and wearing bright clothes that allows you to be seen more readily.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6682#6683#6684#10777","text":"choosing an effective lane position#identifying multiple hazards#wearing bright clothing#Doing all of the above"},
{"testId":59,"qid":2174,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":59,"qid":2178,"description":"You should keep a following distance of at least _______ behind the vehicle in front of you.","explanation":"The Delaware Motorcycle Operator Manual recommends keeping a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance to at least three seconds if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":59,"qid":2180,"description":"When is it safe to share a lane with another vehicle?","explanation":"The key word here is \"safely.\" Sharing a lane with another motorbike is not illegal in Delaware. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. To avoid a danger, you may need to utilize the whole width of the lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#35515#43297#43439","text":"Never#If the other vehicle is a motorcycle#If you're riding between two lanes of moving traffic#If you're being passed"},
{"testId":59,"qid":2181,"description":"How should you place your motorbike while parking beside a curb?","explanation":"Park at an angle with the back wheel towards the curb to park near to a curb.","imageName":"","correctAnswer":40983,"isBookmarked":0,"aid":"6656#33921#40981#40983","text":"Parallel to the curb#At an angle with the rear wheel on the curb#At an angle with the front wheel to the curb#At an angle with the rear wheel to the curb"},
{"testId":59,"qid":2183,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":59,"qid":2185,"description":"When approaching an obstacle that must be ridden over, _______ and approach it at a _______ angle.","explanation":"Slowing down and crossing an unavoidable obstruction head-on, or at a 90-degree angle, is the safest option. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":26114,"isBookmarked":0,"aid":"26112#26113#26114#26115","text":"speed up; 90-degree#slow down; 45-degree#slow down; 90-degree#speed up; 45-degree"},
{"testId":59,"qid":2188,"description":"What should you do if you have a weaving sensation while riding over metal grating?","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":24723,"isBookmarked":0,"aid":"24723#43440#43441#43442","text":"Relax and maintain your speed#Swerve to compensate for the weave#Decrease your speed until the feeling disappears#Increase the throttle"},
{"testId":59,"qid":2189,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of the wobble may make the motorbike even more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":434,"qid":2189,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of the wobble may make the motorbike even more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":507,"qid":2189,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of the wobble may make the motorbike even more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":59,"qid":2191,"description":"You should _____ when transporting a burden.","explanation":"When carrying a load, distribute the weight equally to keep your motorbike balanced.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":462,"qid":2191,"description":"You should _____ when transporting a burden.","explanation":"When carrying a load, distribute the weight equally to keep your motorbike balanced.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":491,"qid":2191,"description":"You should _____ when transporting a burden.","explanation":"When carrying a load, distribute the weight equally to keep your motorbike balanced.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":499,"qid":2191,"description":"You should _____ when transporting a burden.","explanation":"When carrying a load, distribute the weight equally to keep your motorbike balanced.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":59,"qid":2192,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6327#6328#6329#43443","text":"at the end of the group#behind the leader#at the front of the group#in front of the sweep rider (tailender)"},
{"testId":59,"qid":2194,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"You are deemed inebriated and may be prosecuted with driving under the influence if your blood alcohol content (BAC) is 0.08 percent or above (DUI). If you are under the age of 21, it is prohibited in Delaware to operate or even be in charge of a motor vehicle if your BAC is 0.02 percent or higher.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#4653","text":"0.08%#0.09%#0.05%#0.01%"},
{"testId":60,"qid":2197,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the foot pegs. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":6748,"isBookmarked":0,"aid":"6173#6747#6748#24605","text":"with your toes pointed downward#hovering just above the ground#firmly on the foot pegs#in any of the above positions"},
{"testId":499,"qid":2197,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the foot pegs. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":6748,"isBookmarked":0,"aid":"6173#6747#6748#24605","text":"with your toes pointed downward#hovering just above the ground#firmly on the foot pegs#in any of the above positions"},
{"testId":60,"qid":2200,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed to assist keep the motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":60,"qid":2202,"description":"How many lane positions do you have in a single lane of traffic?","explanation":"In a single lane of traffic, you have three lane locations to choose from: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":60,"qid":2205,"description":"The Florida Motorcycle Handbook says that you should stay at least ____________ behind the vehicle in front of you.","explanation":"According to the Florida Motorcycle Handbook, you should maintain a following distance of at least two seconds behind the vehicle in front of you. Increase your following distance in heavy traffic or difficult riding conditions.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":60,"qid":2206,"description":"When passing another vehicle, begin your pass from the _______ section of your lane.","explanation":"When ready to pass, riding in the left lane will provide you a better line of sight. This lane position allows you to look beyond the car in front of you and detect impending dangers. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6547#6768","text":"any#The left#The center#the middle"},
{"testId":499,"qid":2206,"description":"When passing another vehicle, begin your pass from the _______ section of your lane.","explanation":"When ready to pass, riding in the left lane will provide you a better line of sight. This lane position allows you to look beyond the car in front of you and detect impending dangers. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6547#6768","text":"any#The left#The center#the middle"},
{"testId":503,"qid":2206,"description":"When passing another vehicle, begin your pass from the _______ section of your lane.","explanation":"When ready to pass, riding in the left lane will provide you a better line of sight. This lane position allows you to look beyond the car in front of you and detect impending dangers. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6547#6768","text":"any#The left#The center#the middle"},
{"testId":60,"qid":2208,"description":"What can you do to reduce your reaction time?","explanation":"You may shorten your response time by covering the clutch and the brakes and being prepared to use them if required.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"6772#6775#27089#35325","text":"Keep the clutch pulled in slightly#Carry very little cargo#Cover the clutch and both brakes#Ride only in lower gears"},
{"testId":60,"qid":2209,"description":"The most effective approach to improve your visibility at an intersection is to:","explanation":"To improve your visibility at a junction, adopt a lane location that allows others to see you easily.","imageName":"","correctAnswer":6778,"isBookmarked":0,"aid":"6286#6776#6778#29447","text":"Flash your brake light#beep your horn to get attention#choose a lane position that lets others see you easily#use hand signals to warn others"},
{"testId":359,"qid":2209,"description":"The most effective approach to improve your visibility at an intersection is to:","explanation":"To improve your visibility at a junction, adopt a lane location that allows others to see you easily.","imageName":"","correctAnswer":6778,"isBookmarked":0,"aid":"6286#6776#6778#29447","text":"Flash your brake light#beep your horn to get attention#choose a lane position that lets others see you easily#use hand signals to warn others"},
{"testId":60,"qid":2210,"description":"When you get to a blind intersection, you should:","explanation":"When approaching a blind junction, ride in the lane that will allow you to be the most visible.","imageName":"","correctAnswer":41861,"isBookmarked":0,"aid":"6779#8992#41861#41862","text":"yield to all other traffic#make a U-turn#ride in the portion of the lane that will let you be most visible#proceed past the stop line"},
{"testId":60,"qid":2212,"description":"When parking on the side of the road, you should park :","explanation":"To park near the roadside, park at a 90-degree angle with your back tire contacting the curb.","imageName":"","correctAnswer":27093,"isBookmarked":0,"aid":"27092#27093#41863#41864","text":"at a 45-degree angle with your front tire touching the curb#at a 90-degree angle with your rear tire touching the curb#parallel to the curb with both tires touching the curb#parallel to the curb with no tires touching the curb"},
{"testId":60,"qid":2219,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the foot pegs even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6810#6812#6813#41865","text":"wear a motorcycle lap belt#lean as you lean#lean back to balance the motorcycle#also have a motorcycle endorsement or license"},
{"testId":61,"qid":2222,"description":"Crashes are quite prevalent among motorcycle riders who.","explanation":"Crashes are very prevalent among motorcycle riders who have been riding for less than six months.","imageName":"","correctAnswer":43284,"isBookmarked":0,"aid":"43282#43283#43284#43285","text":"are experienced#ride in groups#are inexperienced#ride at high speeds"},
{"testId":61,"qid":2225,"description":"If your motorcycle does not have a windscreen, you must :","explanation":null,"imageName":"","correctAnswer":43287,"isBookmarked":0,"aid":"6834#43286#43287#43288","text":"not ride the motorcycle at night#wear sunglasses whenever you ride it#wear eye protection whenever you ride it#have a windshield installed before you ride it"},
{"testId":61,"qid":2226,"description":"Which of the following is NOT a correct way to shift down to a lower gear?","explanation":null,"imageName":"","correctAnswer":43290,"isBookmarked":0,"aid":"6838#6840#43289#43290","text":"Roll off or close the throttle as you squeeze in the clutch lever#Ease the clutch out to avoid skidding the rear tire#Press down firmly on the shift lever#Lift the shift lever"},
{"testId":61,"qid":2227,"description":"Lane positioning helps you to _________.","explanation":"Selecting the ideal lane location allows you to avoid blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":297,"qid":2227,"description":"Lane positioning helps you to _________.","explanation":"Selecting the ideal lane location allows you to avoid blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":467,"qid":2227,"description":"Lane positioning helps you to _________.","explanation":"Selecting the ideal lane location allows you to avoid blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":479,"qid":2227,"description":"Lane positioning helps you to _________.","explanation":"Selecting the ideal lane location allows you to avoid blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":61,"qid":2228,"description":"The Georgia Motorcycle Operators Manual says that you should stay at least ________ behind the vehicle in front of you.","explanation":"According to the Georgia Motorcycle Operators Manual, you should maintain a following distance of at least four seconds behind the vehicle in front of you.","imageName":"","correctAnswer":1288,"isBookmarked":0,"aid":"1286#1287#1288#4097","text":"one second#two seconds#four seconds#five seconds"},
{"testId":347,"qid":2228,"description":"The Georgia Motorcycle Operators Manual says that you should stay at least ________ behind the vehicle in front of you.","explanation":"According to the Georgia Motorcycle Operators Manual, you should maintain a following distance of at least four seconds behind the vehicle in front of you.","imageName":"","correctAnswer":1288,"isBookmarked":0,"aid":"1286#1287#1288#4097","text":"one second#two seconds#four seconds#five seconds"},
{"testId":61,"qid":2230,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6848,"isBookmarked":0,"aid":"6846#6847#6848#33955","text":"changing lanes#avoiding hazards from parked cars#being seen by the driver in front of you#preparing to pass the vehicle ahead of you"},
{"testId":61,"qid":2231,"description":"When is it permissible to travel beside another motorcycle in the same lane?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8034#24682#24684","text":"Never#When you are riding in a group#When traffic is heavy#When you are riding with a new rider"},
{"testId":61,"qid":2232,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"When prepared to pass the car in front of you, ride in the left lane to expand your line of sight. This allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6552#6555#6853#6854","text":"riding in the left lane position#riding in the right lane position#pulling into oncoming traffic#riding in the center lane position"},
{"testId":61,"qid":2234,"description":"How can you ensure that a car driver notices you at an intersection?","explanation":"There is no way to ensure that motorists will see you. Even when staring straight at a motorcycle, car drivers often fail to detect it. Even if a motorist establishes eye contact with you, there is no guarantee that the driver has observed you. At crossroads, always proceed with care.","imageName":"","correctAnswer":38908,"isBookmarked":0,"aid":"10603#10604#31148#38908","text":"Wave at the driver#Make eye contact with the driver#Beep your horn#You can't"},
{"testId":61,"qid":2236,"description":"When parked next to a curb, _________ should contact the curb.","explanation":"When parking near to a curb, park at an angle with the rear tire parallel to the curb. This will enable you to securely depart the parking place.","imageName":"","correctAnswer":6862,"isBookmarked":0,"aid":"6862#6863#6864#6865","text":"the rear wheel#the front wheel#both wheels#no wheels"},
{"testId":61,"qid":2238,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10813#30828#43291","text":"the lane next to you and the far lane#only the far lane#Your signal lights#the lane next to you and your throttle"},
{"testId":61,"qid":2239,"description":"What can you do to improve your chances of avoiding potential hazards at night?","explanation":"Reduce your speed and increase your following distance at night to increase your chances of avoiding dangers. This provides you with more time to respond to the unexpected.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#11171#11173#34564","text":"Reduce your speed#Ride in a group#Stay in the same lane position#Don't wear a helmet"},
{"testId":464,"qid":2239,"description":"What can you do to improve your chances of avoiding potential hazards at night?","explanation":"Reduce your speed and increase your following distance at night to increase your chances of avoiding dangers. This provides you with more time to respond to the unexpected.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#11171#11173#34564","text":"Reduce your speed#Ride in a group#Stay in the same lane position#Don't wear a helmet"},
{"testId":61,"qid":2243,"description":"If you can't cross an obstacle straight on, cross at an angle of at least ____ degrees.","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If this is not practicable, pass the obstruction at a 45-degree angle.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"6354#6355#6425#6802","text":"45#90#25#180"},
{"testId":61,"qid":2244,"description":"You should _________, if your front tire blows our.","explanation":"If your front tire becomes flat, use just the back brake to slow down. Avoid using the brake on a flat tire.","imageName":"","correctAnswer":43294,"isBookmarked":0,"aid":"6890#43292#43293#43294","text":"just keep riding carefully#slow down by using the front brake only#slow down by using both brakes#slow down by using the rear brake only"},
{"testId":61,"qid":2245,"description":"Who determines the group's pace when riding in a group?","explanation":"The sweep rider (tailender) sets the pace while riding in a group. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":35300,"isBookmarked":0,"aid":"6578#6581#27933#35300","text":"The group leader#The middle group rider#The least experienced rider#The sweep rider (tailender)"},
{"testId":62,"qid":2246,"description":"Mirrors on your motorcycle:","explanation":"After mounting your motorbike but before you begin riding, you should adjust the mirrors. Adjusting your mirrors while biking may be risky.","imageName":"","correctAnswer":28352,"isBookmarked":0,"aid":"6897#28351#28352#28353","text":"cannot be adjusted#should be adjusted before you mount the motorcycle#should be adjusted before you ride the motorcycle#should be adjusted while you are riding"},
{"testId":62,"qid":2248,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is controlled by the lever on the left. The right foot pedal operates the rear brake, while the left foot pedal operates the gear changer.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#7701#7702","text":"the left hand lever#the right hand lever#the left foot pedal#the right foot pedal"},
{"testId":62,"qid":2250,"description":"In which of the following situations should you roll on the throttle?","explanation":"When turning or navigating a curve, roll on the throttle to assist steady the motorbike.","imageName":"","correctAnswer":24929,"isBookmarked":0,"aid":"6981#24929#28354#28355","text":"When you are descending a hill#To help stabilize your bike in a turn#When you are preparing to stop#When you are passing a row of parked cars"},
{"testId":62,"qid":2251,"description":"Which lane should you stay away from?","explanation":"You should choose the safest lane position for the current circumstance. No lane position should ever be avoided.","imageName":"","correctAnswer":28358,"isBookmarked":0,"aid":"6912#28356#28357#28358","text":"The left, because of oncoming traffic#The right, because of the edge of the road#The center, because of oil buildup#None, because they all have their uses"},
{"testId":62,"qid":2252,"description":"Leave a following gap of at least _____ seconds between you and the vehicle ahead of you.","explanation":"Allow at least 2 seconds between yourself and the car in front of you.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6916","text":"5#2#3#6"},
{"testId":62,"qid":2253,"description":"In which of the following circumstances should you increase your following distance?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. Slippery roads, high traffic, and being tailgated are all examples of this.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"6769#6999#22962#28359","text":"In heavy traffic#On slippery roads#In all of the above situations#When you are being tailgated"},
{"testId":62,"qid":2254,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being trailed by a tailgater who is unable to pass you, increase your following distance so that both of you have adequate space to stop if a danger appears.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6286#6392#6920","text":"pull over and stop#Flash your brake light#Call the police#Increase your following distance"},
{"testId":62,"qid":2255,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Motorcycles need a full lane width to function properly, even though they are smaller than vehicles.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":62,"qid":2256,"description":"When another vehicle operator attempts to share your lane:","explanation":"When passing you, a vehicle operator is more likely to attempt to share your lane than any other scenario.","imageName":"","correctAnswer":28215,"isBookmarked":0,"aid":"28215#28216#28217#28218","text":"the vehicle operator wants to pass you#you are riding up a hill#you are on a multilane highway#you are in a residential area"},
{"testId":351,"qid":2256,"description":"When another vehicle operator attempts to share your lane:","explanation":"When passing you, a vehicle operator is more likely to attempt to share your lane than any other scenario.","imageName":"","correctAnswer":28215,"isBookmarked":0,"aid":"28215#28216#28217#28218","text":"the vehicle operator wants to pass you#you are riding up a hill#you are on a multilane highway#you are in a residential area"},
{"testId":62,"qid":2257,"description":"Because drivers merging onto the highway may not notice you, what should you do?","explanation":"You should try to move into a more distant lane so that drivers of vehicles entering the highway don't miss you.","imageName":"","correctAnswer":28360,"isBookmarked":0,"aid":"6286#6582#6928#28360","text":"Flash your brake light#Pull in the clutch#Move into the center lane position#Move into a farther lane if possible"},
{"testId":62,"qid":2258,"description":"When evaluating potential hazards, you should be considering:","explanation":"Consider road features, other vehicles, and traffic management while assessing possible dangers. They are all potentially dangerous.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6929#6930#6931#8615","text":"road characteristics#other vehicles#traffic controls#All of the above"},
{"testId":62,"qid":2259,"description":"The most likely location of a collision is:","explanation":"An junction is the most probable location for a collision since numerous cars are attempting to perform various tasks at the same time.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":62,"qid":2260,"description":"You should ride in the _______ lane when riding behind a line of parked cars and next to oncoming traffic.","explanation":"When riding beside a row of parked automobiles and adjacent to incoming traffic, ride in the middle lane to avoid the risks on both sides.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7693","text":"Center#Right#Left#left or center"},
{"testId":62,"qid":2261,"description":"When you park next to the curb, _________ should touch the curb.","explanation":"When parking close to the curb, your back wheel should be in contact with the curb.","imageName":"","correctAnswer":6937,"isBookmarked":0,"aid":"6864#6937#6938#6939","text":"both wheels#your rear wheel#your front wheel#neither wheel"},
{"testId":62,"qid":2262,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6940#6941#10572#10573","text":"Avoid driving in heavy traffic#wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles"},
{"testId":62,"qid":2263,"description":"When should you flash your brake light?","explanation":"If you need to slow down in an area where others may not anticipate it, flash your brake light to alert the driver behind you.","imageName":"","correctAnswer":24931,"isBookmarked":0,"aid":"6945#6946#24686#24931","text":"You slow down in rural areas#The speed limit changes#You are going to turn left#You slow down where others might not expect it"},
{"testId":62,"qid":2264,"description":"How should you pass a vehicle in a group ride?","explanation":"Passing a vehicle in a group should be done one rider at a time.","imageName":"","correctAnswer":28362,"isBookmarked":0,"aid":"6264#28361#28362#28363","text":"In pairs#As many at the same time as is safe#One at a time#Not at all"},
{"testId":62,"qid":2265,"description":"You should _____ to  transport cargo.","explanation":"To carry a load, put it front and low, and equally distribute the weight. Maintain your motorcycle's balance and low center of gravity.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6953#10656#28364#28365","text":"distribute the weight evenly#Do all of the above#secure it forward#keep it low"},
{"testId":62,"qid":2267,"description":"What should you do if you are riding your motorcycle over a metal grating that causes it to weave?","explanation":"The weaving that occurs while cycling over a metal grating is often not dangerous. Relax, keep a steady pace, and ride straight across.","imageName":"","correctAnswer":24723,"isBookmarked":0,"aid":"22927#24723#28366#28367","text":"Pull in the clutch#Relax and maintain your speed#Try to correct the weave#Apply the rear brake"},
{"testId":62,"qid":2268,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. When you have reached the curve's centre, you should turn away from it.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6511#6582#10833#10834","text":"Increase the throttle#Pull in the clutch#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":62,"qid":2269,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":28368,"isBookmarked":0,"aid":"6244#6417#10748#28368","text":"use the rear brake only#Use the front brake only#alternate between the rear brake and front brake#use the front and rear brakes at the same time"},
{"testId":62,"qid":2270,"description":"What's the best way to detecting bumps in the road at night?","explanation":"If the taillights of the vehicle ahead of you are bouncing up and down, the vehicle is most certainly going over bumps.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"10581#22794#22795#28204","text":"Wear reflective clothing#Watch the taillights of the car ahead of you#Stay in low gears#Use your low-beam headlight"},
{"testId":63,"qid":2271,"description":"In Idaho, you must wear a helmet _______when riding a motorcycle.","explanation":"If you are under the age of 18, you must wear a helmet when operating or riding a motorbike in Idaho. Although it is not required by law, motorcyclists of all ages should wear helmets.","imageName":"","correctAnswer":6966,"isBookmarked":0,"aid":"6966#6967#6968#7433","text":"if you are under the age of 18#if you are over the age of 18#while riding in designated helmet areas#if you are under the age of 21"},
{"testId":63,"qid":2272,"description":"Mirrors on your motorcycle:","explanation":"After mounting your motorbike but before you begin riding, you should adjust the mirrors. Adjusting your mirrors while biking may be risky.","imageName":"","correctAnswer":28896,"isBookmarked":0,"aid":"6897#28894#28895#28896","text":"cannot be adjusted#should be adjusted at your first stop#should be adjusted while you are riding the motorcycle#should be adjusted after you mount the motorcycle"},
{"testId":359,"qid":2272,"description":"Mirrors on your motorcycle:","explanation":"After mounting your motorbike but before you begin riding, you should adjust the mirrors. Adjusting your mirrors while biking may be risky.","imageName":"","correctAnswer":28896,"isBookmarked":0,"aid":"6897#28894#28895#28896","text":"cannot be adjusted#should be adjusted at your first stop#should be adjusted while you are riding the motorcycle#should be adjusted after you mount the motorcycle"},
{"testId":63,"qid":2273,"description":"You should begin riding the motorcycle with _____.","explanation":"To avoid using too much throttle, start the motorbike with your right wrist flat.","imageName":"","correctAnswer":6976,"isBookmarked":0,"aid":"6973#6974#6975#6976","text":"your right wrist low#your elbows locked#your hands above your elbows#your right wrist flat"},
{"testId":63,"qid":2274,"description":"The gearshift lever can be found :","explanation":"The shifting lever is situated just in front of the left footrest.","imageName":"","correctAnswer":6980,"isBookmarked":0,"aid":"6979#6980#10789#10790","text":"in front of the right footrest#in front of the left footrest#by the left handlegrip#by the right handlegrip"},
{"testId":63,"qid":2276,"description":"How can you maximize stopping power?","explanation":"Using both the front and rear brakes at the same time provides the most stopping force.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#11174#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Pull in the clutch while you brake#Alternate between the front brake and the rear brake"},
{"testId":63,"qid":2277,"description":"To safely stop on a curve, you should:","explanation":"To safely stop on a curve, straighten the motorbike so that it is as perpendicular to the road surface as feasible. Stopping while leaning might be risky.","imageName":"","correctAnswer":23022,"isBookmarked":0,"aid":"6901#10686#23022#24685","text":"Keep your knees against the gas tank#lean in the opposite direction of the motorcycle#straighten the motorcycle so that it is perpendicular to the surface of the road#take your feet off the footrests for balance"},
{"testId":63,"qid":2278,"description":"How do you turn properly? What are the four steps?","explanation":"The four stages for optimal turn control are as follows: Slow down, watch where you're going, squeeze the handlegrip in the turn's direction, and roll on the throttle for balance.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6199#6991#6992#6994","text":"Slow, look, press, roll#Slow, shift, roll, lean#Shift, slow, press, lean#Shift, slow, roll, lean"},
{"testId":63,"qid":2279,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane: 1 (left), 2 (middle), and 3 (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":63,"qid":2280,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, ride in lane 2 (middle) or 3 (outside) (right).","imageName":"","correctAnswer":28899,"isBookmarked":0,"aid":"28898#28899#28900#28901","text":"2 (center)#2 (center) or 3 (right)#3 (right)#1 (left) or 2 (center)"},
{"testId":63,"qid":2281,"description":"You should have at least _____ seconds between you and the car in front of you.","explanation":"There should be at least 3 seconds between you and the car in front of you.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":63,"qid":2283,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles to cut in front of you.","imageName":"","correctAnswer":7000,"isBookmarked":0,"aid":"6769#6919#6999#7000","text":"In heavy traffic#When being tailgated#On slippery roads#When riding in a group"},
{"testId":63,"qid":2284,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing, keep an eye out for extended side mirrors, things flung out of windows, and gusts of wind.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#8615#28902#28903","text":"extended side mirrors#All of the above#objects thrown out of windows#blasts of wind, particularly if the passing vehicle is large"},
{"testId":63,"qid":2285,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside vehicles may be risky since a motorbike, like a car, needs a full lane width to function securely. Lane sharing is risky.","imageName":"","correctAnswer":28904,"isBookmarked":0,"aid":"24841#24842#24844#28904","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be distracted by what is happening inside the vehicles#a motorcycle requires a full lane width to operate safely"},
{"testId":63,"qid":2286,"description":"What can you do to reduce your reaction time?","explanation":"By covering the clutch and brake, you may shorten your response time.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"11172#24744#28905#28906","text":"Do not wear a helmet#Cover the clutch and the brake#Keep your foot on the brake#Use low gears only"},
{"testId":63,"qid":2287,"description":"When can you proceed if you feel your motorcycle did not trigger a light sensor when stopped at a traffic signal?","explanation":"Motorcycles, due to their size, may not usually activate traffic control signals while approaching a junction. The rule was changed on July 1, 2006, to enable a motorcycle rider to go with care through a red light at an intersection after coming to a full stop. You may do so, however, only if the signal fails to work after one full cycle of that traffic light, and you must surrender to any vehicles in or approaching the junction.","imageName":"","correctAnswer":31903,"isBookmarked":0,"aid":"31900#31901#31902#31903","text":"A) After coming to a complete stop#B) After waiting for some time#C) After waiting for one light cycle#D) After you do a) and c)"},
{"testId":63,"qid":2288,"description":"Riding beside a line of parked cars might be hazardous because:","explanation":"Riding behind a row of parked automobiles might be risky since a motorist may not notice you approaching and may open a door into your lane.","imageName":"","correctAnswer":7015,"isBookmarked":0,"aid":"7014#7015#7016#7017","text":"you may run into a car#doors may be opened into your lane#there will be more oil on the road#the cars make your lane narrower"},
{"testId":63,"qid":2289,"description":"When parking along the side of the road, you should park:","explanation":"When parking along the road, park with your back tire touching the curb.","imageName":"","correctAnswer":7019,"isBookmarked":0,"aid":"74#7018#7019#7020","text":"parallel to the curb#with your front tire touching the curb#with your rear tire touching the curb#on the curb"},
{"testId":63,"qid":2291,"description":"When you are about to stop, you should flash your brake light if you are:","explanation":"When you are going to stop and are being followed too closely, stopping in an odd location, or stopping faster than others may anticipate, you should flash your brake light.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7025#7026#22688#24754","text":"being followed too closely#stopping in an unusual place#In any of the above situations#stopping more quickly than others might expect"},
{"testId":63,"qid":2292,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to ride more safely.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":63,"qid":2293,"description":"Which of the following surfaces increases your chances of falling?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":63,"qid":2294,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23103#26561#26562#28911","text":"Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane#Increase your speed to maintain your balance#Lean in the opposite direction of the motorcycle during turns"},
{"testId":63,"qid":2295,"description":"If your front tire blows our while riding, you should_________ to slow down.","explanation":"If your front tire blows out while you're riding, use the rear brake to slow down. Do not use the flat tire's brake.","imageName":"","correctAnswer":6423,"isBookmarked":0,"aid":"6423#6569#6582#7040","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Apply both brakes"},
{"testId":64,"qid":2296,"description":"Motorcycle riders in Illinois are :","explanation":"Motorcycle riders in Illinois are not obliged to wear helmets. However, wearing one while riding is always a good idea since it may lower the risk of significant head damage in an accident.","imageName":"","correctAnswer":7042,"isBookmarked":0,"aid":"7041#7042#7043#29360","text":"required to wear a helmet in designated areas#not required to wear a helmet#are required to wear a helmet at all times#required to wear a helmet only at night"},
{"testId":64,"qid":2297,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents involve riders who have been riding for less than 6 months.","imageName":"","correctAnswer":29361,"isBookmarked":0,"aid":"7045#7046#29361#29362","text":"on multilane highways#when changing lanes#with riders who have been riding for less than 6 months#with riders who have been riding for more than 6 months"},
{"testId":64,"qid":2298,"description":"When you sit properly on a motorcycle, your arms are largely used for:","explanation":"When you sit correctly on a motorbike, your arms are largely utilized for steering and not for supporting yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#7050#20973#29363","text":"holding yourself up#hand signals#steering#braking"},
{"testId":64,"qid":2299,"description":"You're about to make a left turn. If you need to change gears, you should do it ________.","explanation":"If you need to swap gears before approaching a turn, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the rear wheel.","imageName":"","correctAnswer":6187,"isBookmarked":0,"aid":"6185#6187#24858#29364","text":"after entering the turn#before entering the turn#At any time#after completing the turn"},
{"testId":64,"qid":2302,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake.","imageName":"","correctAnswer":7702,"isBookmarked":0,"aid":"6818#6819#7701#7702","text":"the left hand lever#the right hand lever#the left foot pedal#the right foot pedal"},
{"testId":64,"qid":2303,"description":"You should _____ in a turn to aid in maintaining balance.","explanation":"Maintain or increase your pace to assist maintain balance in a turn. At slower speeds, motorcycles are less stable.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6758#6759#7068#7069","text":"maintain or increase your speed#maintain or decrease your speed#drag your inside foot for support#drag your outside foot for support"},
{"testId":64,"qid":2304,"description":"What is the minimum seconds of distance is a safe space cushion between you and the vehicle ahead of you?","explanation":"At least 3 seconds should elapse between you and the car ahead of you.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":64,"qid":2305,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: 1 (left), 2 (middle), and 3 (right) (right).","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":64,"qid":2306,"description":"What is the best strategy to deal with tailgaters?","explanation":"Allowing tailgaters to pass you is the greatest approach to deal with them.","imageName":"202006041812161292.jpg","correctAnswer":29365,"isBookmarked":0,"aid":"10578#23301#26686#29365","text":"Increase your speed#Pull over and stop#Call the police#Encourage them to pass you"},
{"testId":64,"qid":2307,"description":"How much of a traffic lane does a motorcycle require to operate safely?","explanation":"To drive properly, a motorbike, like any other vehicle, needs the whole lane width.","imageName":"","correctAnswer":7075,"isBookmarked":0,"aid":"7074#7075#7076#7077","text":"Half a lane width#A full lane width#A third of a lane width#A quarter of a lane width"},
{"testId":64,"qid":2309,"description":"When parking against a curb, you should park at an angle of :","explanation":"Parking against the curb should be done at a 90-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#22892","text":"45-degree#90-degree#25-degree#130-degree"},
{"testId":64,"qid":2310,"description":"If you slow down unexpectedly, you should:","explanation":"If you are going to slow down in an area where others may not anticipate it, flash your brake light.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":359,"qid":2310,"description":"If you slow down unexpectedly, you should:","explanation":"If you are going to slow down in an area where others may not anticipate it, flash your brake light.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":396,"qid":2310,"description":"If you slow down unexpectedly, you should:","explanation":"If you are going to slow down in an area where others may not anticipate it, flash your brake light.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":64,"qid":2311,"description":"When should you check your mirrors?","explanation":"When you are about to slow down, changing lanes, or stopping at an intersection, you should check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"6701#22688#24713#29366","text":"Stopped at an intersection#In any of the above situations#About to change lanes#Going to slow down"},
{"testId":64,"qid":2313,"description":"The best way around a curve:","explanation":"The optimum way across a curve may not match the road's curvature. Change your lane position as required to aid you.","imageName":"","correctAnswer":7829,"isBookmarked":0,"aid":"7828#7829#7830#28994","text":"is on the inside of the curve#may not follow the curve of the road#is on the outside of the curve#is to stay in the center lane position"},
{"testId":64,"qid":2315,"description":"What is the ideal lane position for a motorbike while it is raining?","explanation":"You should ride in the left or right lane while it is raining. Because of the accumulation of oil and other car fluids in the middle lane, it may be slippery.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6216#6763#7096#7097","text":"Center#Left or right#Center or left#Center or right"},
{"testId":64,"qid":2316,"description":"The easiest method to get around an inevitable roadblock is to:","explanation":"Approaching an unavoidable road obstruction head-on, or at a 90-degree angle, is the best method to cross it. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":29368,"isBookmarked":0,"aid":"7100#24611#29367#29368","text":"put your feet down for support#Walk your motorcycle across it#cross it at a 45-degree angle#cross it at a 90-degree angle"},
{"testId":64,"qid":2317,"description":"If your motorcycle does not have a windshield, you must :","explanation":"If your motorbike lacks a windshield, you must protect your eyes using goggles, glasses, or (best of all) a face shield. (Your complete face, including your eyes, will be protected by a shatter-resistant face shield.)","imageName":"","correctAnswer":7102,"isBookmarked":0,"aid":"7102#7103#7104#7105","text":"wear goggles or glasses#only ride it during the day#wear an approved helmet#not ride the motorcycle"},
{"testId":64,"qid":2318,"description":"What is the correct swerving technique?","explanation":"To swerve properly, squeeze the handlegrip in the direction you wish to veer and lean with the motorbike.","imageName":"","correctAnswer":26538,"isBookmarked":0,"aid":"26537#26538#29369#29370","text":"Pull the handlegrip in the direction in which you want to swerve#Press the handlegrip in the direction in which you want to swerve#Pull the handlegrip in the direction opposite to the one in which you want to swerve#Press the handlegrip in the direction opposite to the one in which you want to swerve"},
{"testId":64,"qid":2319,"description":"If your blood alcohol content (BAC) is _____ or above, you are regarded to be inebriated.","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated. An typical 180-pound male will have a BAC of 0.10 percent after three standard drinks before his liver begins to break down the alcohol. A normal drink is one 12-ounce can of beer, half a glass of wine, or 1.5 ounces of whiskey.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#4653","text":"0.08%#0.09%#0.05%#0.01%"},
{"testId":64,"qid":2320,"description":"When riding  in a group, what is the best formation?","explanation":"When riding in a group, the staggered arrangement is the optimum. It allows you to keep near while yet providing appropriate space cushion.","imageName":"","correctAnswer":6262,"isBookmarked":0,"aid":"6262#29371#29372#29373","text":"The staggered formation#None of the above#Single file#In pairs"},
{"testId":65,"qid":2321,"description":"What is the best way to protect your eyes while riding a motorcycle?","explanation":"A plastic, shatter-resistant face shield is the best way to protect your eyes when riding a motorbike.","imageName":"","correctAnswer":7113,"isBookmarked":0,"aid":"7112#7113#7114#7115","text":"Goggles#A plastic face shield#Protective glasses#A helmet"},
{"testId":65,"qid":2322,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handgrip.","imageName":"","correctAnswer":7118,"isBookmarked":0,"aid":"7116#7117#7118#7119","text":"right handgrip#right foot pedal#left handgrip#left foot pedal"},
{"testId":65,"qid":2323,"description":"What should you examine on your motorcycle before going on a journey?","explanation":"Before each ride, inspect the tires, clutch, throttle, and brakes for safety. Inspect the fluids, lights, mirrors, horn, and fuel supply valve as well.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#30007","text":"All of the above#The tires#The fluids#The mirrors"},
{"testId":65,"qid":2324,"description":"To aid in balance, your feet should:","explanation":"Your feet should be firmly planted on the footrests to aid with balance.","imageName":"","correctAnswer":26571,"isBookmarked":0,"aid":"7123#7124#24932#26571","text":"be against the gas tank#skim the ground during turns#point down toward the road#be placed firmly on the footrests"},
{"testId":400,"qid":2324,"description":"To aid in balance, your feet should:","explanation":"Your feet should be firmly planted on the footrests to aid with balance.","imageName":"","correctAnswer":26571,"isBookmarked":0,"aid":"7123#7124#24932#26571","text":"be against the gas tank#skim the ground during turns#point down toward the road#be placed firmly on the footrests"},
{"testId":65,"qid":2325,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"Three-quarters of your motorcycle's stopping force is provided by the front brake.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":367,"qid":2325,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"Three-quarters of your motorcycle's stopping force is provided by the front brake.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":65,"qid":2326,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most motorcyclists involved in incidents underbraked the front tire and overbraked the rear, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23077#23079#23080#30008","text":"underbraked the front tire and overbraked the rear#lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so"},
{"testId":65,"qid":2327,"description":"When performing a gradual, tight turn, the rider should:","explanation":"When performing a gradual, tight turn, keep your torso straight and lean exclusively on the motorbike to maintain balance.","imageName":"","correctAnswer":30009,"isBookmarked":0,"aid":"10629#30009#30010#30011","text":"Lean in the opposite direction of the motorcycle#Keep your body straight while leaning only the motorcycle#Keep your feet on the footrests#Lean in the same direction as the motorcycle"},
{"testId":65,"qid":2328,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and repair your own errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":65,"qid":2329,"description":"Which lane should you ride in if there are hazards on your right?","explanation":"When there is a danger on the right side of the road, ride in the left or center lane.","imageName":"","correctAnswer":7136,"isBookmarked":0,"aid":"7097#7136#7137#7138","text":"Center or right#Left or center#Right or left#Center only"},
{"testId":65,"qid":2330,"description":"Maintain a following distance of at least _____ seconds for an enought space cushion.","explanation":"Maintain a following distance of at least two seconds for an acceptable spacing cushion.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":65,"qid":2331,"description":"If someone is passing you on the left, you should:","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":25842,"isBookmarked":0,"aid":"7334#25842#26469#26470","text":"ride on the shoulder of the road#avoid the left lane position#move closer to the passing vehicle#avoid the center lane position"},
{"testId":383,"qid":2331,"description":"If someone is passing you on the left, you should:","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":25842,"isBookmarked":0,"aid":"7334#25842#26469#26470","text":"ride on the shoulder of the road#avoid the left lane position#move closer to the passing vehicle#avoid the center lane position"},
{"testId":65,"qid":2332,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you suspect another car is attempting to share your lane, ride in the middle lane to deny the vehicle ample space for lane sharing.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":65,"qid":2333,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in the blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#30012#30013","text":"Ride faster than the speed limit#Find a place without traffic on either side#Ride at the same speed as the vehicles you are beside#Ride in the left lane position"},
{"testId":65,"qid":2334,"description":"You must be extremely cautious at intersections for:","explanation":"Particular attention must be paid to vehicles turning left into your path at intersections. Often, car drivers fail to see approaching motorbikes.","imageName":"","correctAnswer":7150,"isBookmarked":0,"aid":"7149#7150#30014#30015","text":"irregular traffic signals#cars turning left in front of you#vehicles trying to share your lane#parked cars"},
{"testId":65,"qid":2335,"description":"When going past a row of parked cars, you need to be on the lookout for:","explanation":"When driving through a line of parked automobiles, keep an eye out for persons emerging from between the cars, drivers opening their doors, and cars departing their parking slots. Car drivers often fail to see bikes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7153#7154#8615#30016","text":"people emerging from between cars#drivers opening their doors#All of the above#cars exiting their parking spaces"},
{"testId":65,"qid":2336,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#24755#30017","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#put his or her feet down when the motorcycle is stopped#get on the motorcycle before you start the engine"},
{"testId":65,"qid":2337,"description":"A motorcycle group should ride in a staggered pattern:","explanation":"On lengthy sections of road, the staggered pattern should be employed.","imageName":"","correctAnswer":29438,"isBookmarked":0,"aid":"29438#30018#30019#30020","text":"on long stretches of road#when passing as a group#through curves and turns#when merging onto the highway"},
{"testId":65,"qid":2338,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is stuck and twisting it back and forth does not release it, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":7162,"isBookmarked":0,"aid":"7162#7163#10574#28359","text":"When your throttle is stuck#When you need to stop quickly#When you need to avoid slipping on slippery surfaces#When you are being tailgated"},
{"testId":65,"qid":2339,"description":"Which wheels should touch the curb when you park?","explanation":"The rear wheel should be hitting the curb when you park along the roadside at a 90-degree angle.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":65,"qid":2340,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are far more difficult to spot than vehicles. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":65,"qid":2341,"description":"On slick areas, you should:","explanation":"When driving on slick conditions, utilize both the front and rear brakes. The most efficient approach to stop is to use both brakes.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#7173#28935","text":"use the rear brake only#Use the front brake only#avoid using brakes#use both the front and rear brakes"},
{"testId":65,"qid":2342,"description":"The safest place to ride in the rain is:","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are less prone to slipping.","imageName":"","correctAnswer":28999,"isBookmarked":0,"aid":"7310#7312#28999#30021","text":"in the center lane position#on the shoulder of the road#in the tire tracks of other vehicles#in the left lane position"},
{"testId":65,"qid":2343,"description":"A wobble is likely to be exacerbated by:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike less stable. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":7180,"isBookmarked":0,"aid":"7179#7180#7181#7182","text":"unpaved roads#trying to accelerate out of it#closing the throttle gradually#riding beside another vehicle"},
{"testId":65,"qid":2344,"description":"Where should an inexperienced rider be positioned when riding in a group?","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":7186,"isBookmarked":0,"aid":"7183#7184#7185#7186","text":"At the end of the group#In the middle of the group#At the front of the group#Behind the leader"},
{"testId":65,"qid":2345,"description":"What is the best strategy to avoid riding fatigue?","explanation":"Taking regular rest stops is the greatest approach to avoid road tiredness. Every 2 hours, you should stop and get off your motorbike.","imageName":"","correctAnswer":30022,"isBookmarked":0,"aid":"30022#30023#30024#30025","text":"Take frequent rest breaks#Drink lots of fluids#Drive only during the day#Do not wear a face shield"},
{"testId":66,"qid":2347,"description":"When you are properly seated on a motorcycle, your:","explanation":"When sitting correctly on a motorbike, your arms should be slightly bent. This allows you to steer rather than support yourself with your arms.","imageName":"","correctAnswer":7195,"isBookmarked":0,"aid":"7193#7195#7196#30437","text":"elbows should be locked#arms should be bent slightly#hands should be above your elbows#elbows should be at a 90-degree angle"},
{"testId":66,"qid":2348,"description":"More than one motorcycle should ride together:","explanation":"Riders on two or more motorcycles should ride in a staggered configuration. This allows the group to remain near while yet retaining an acceptable spacing cushion.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":66,"qid":2349,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"If you believe the driver behind you is not aware that you are slowing down, you should flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10754#10755","text":"Beep your horn to warn the driver#Move into another lane#Accelerate to make it through the light#Tap your brake to flash your brake light"},
{"testId":383,"qid":2349,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"If you believe the driver behind you is not aware that you are slowing down, you should flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10754#10755","text":"Beep your horn to warn the driver#Move into another lane#Accelerate to make it through the light#Tap your brake to flash your brake light"},
{"testId":66,"qid":2351,"description":"If _____, you can be charged with driving under the influence of alcohol (DUI).","explanation":"If your blood alcohol concentration (BAC) is 0.08 percent or higher, you can be charged with driving under the influence. (Note: An average 180-pound guy will have a BAC of 0.10 percent after three standard drinks before his liver begins to break it down.) One normal drink is equivalent to one 12-ounce can of beer, half a glass of wine, or 1.5 ounces of whiskey.)","imageName":"","correctAnswer":30438,"isBookmarked":0,"aid":"7208#7209#10636#30438","text":"You have consumed at least two beers#You have consumed any amount of alcohol#You are seen exiting a drinking establishment#your blood alcohol concentration (BAC) is 0.08% or above"},
{"testId":66,"qid":2352,"description":"Blind spots of a motorcyclea are:","explanation":"A motorbike, like a vehicle, has blind areas. Because the motorcycle's blind spots are on the left and right sides, constantly check your shoulders before changing lanes.","imageName":"","correctAnswer":24757,"isBookmarked":0,"aid":"24757#24758#24759#24760","text":"on the right and left of the motorcycle#nonexistent#only on the left of the motorcycle#only on the right of the motorcycle"},
{"testId":66,"qid":2353,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. A motorist turning left may not see a motorbike coming.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":66,"qid":2355,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, it is important to be prepared in case of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":66,"qid":2356,"description":"What should you look for when merging into the middle lane of a three-lane road?","explanation":"When prepared to merge into the center lane of a three-lane road, examine both the middle and far lanes. Someone in the far lane may intend to move into the center lane.","imageName":"","correctAnswer":24616,"isBookmarked":0,"aid":"7225#7226#24616#30440","text":"Your throttle, to make sure it is not stuck#Your speed limit, to make sure you are not exceeding it#The lane beside you and the far lane#Your signal lights, to make sure they are working"},
{"testId":66,"qid":2358,"description":"When should a motorcycle passenger board?","explanation":"Only after you have started and mounted your motorbike should a passenger board it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7234#8153#24934","text":"Before the engine has been started#Before the motorcycle is taken off the stand#After the engine has been started#Before the motorcyclist"},
{"testId":66,"qid":2359,"description":"Which lane position should motorcycle riders avoid?","explanation":"None of the three lane locations must be avoided at all times. They are all useful in their own way. Always choose the safest and most effective lane position for the scenario at hand.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"6214#6216#6217#17889","text":"Left#Center#Right#None"},
{"testId":66,"qid":2361,"description":"What is the best strategy to absorb shock when cycling over a road obstacle?","explanation":"When riding over a road obstruction, the ideal approach to absorb shock is to elevate your seat slightly and allow your knees and elbows absorb the impact.","imageName":"","correctAnswer":30443,"isBookmarked":0,"aid":"10578#30441#30442#30443","text":"Increase your speed#Avoid using the brakes#Crouch down to lower the center of gravity#Rise off the seat to absorb shock with your knees and elbows"},
{"testId":66,"qid":2362,"description":"Which brake needs to be used more?","explanation":"Because it delivers 70% of the stopping force, the front brake is more critical than the rear brake. The most effective braking approach, however, is to constantly engage both brakes.","imageName":"","correctAnswer":30444,"isBookmarked":0,"aid":"7247#7248#30444#30445","text":"The front, because it prevents skidding#The rear, because it prevents skidding#The front, because it provides 70% of the stopping power#The rear, because it provides 70% of the stopping power"},
{"testId":66,"qid":2364,"description":"When riding through curves, what kind of group formation should you use?","explanation":"It is advisable to ride in single file through bends.","imageName":"","correctAnswer":6263,"isBookmarked":0,"aid":"6263#6264#7254#7255","text":"Single file#In pairs#Staggered formation#V-shaped formation"},
{"testId":66,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":367,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":383,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":396,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":404,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":420,"qid":2365,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":66,"qid":2366,"description":"How can you improve your visibility when approaching a blind intersection?","explanation":"At a blind junction, choose a lane location that allows other vehicle operators to notice you as quickly as feasible.","imageName":"","correctAnswer":30446,"isBookmarked":0,"aid":"10718#24901#24902#30446","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Ride in the lane position in which you will be seen earliest"},
{"testId":66,"qid":2368,"description":"Cross railroad rails at a 45-degree angle or more because:","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":29444,"isBookmarked":0,"aid":"10314#29444#30049#30051","text":"a larger angle would be unsafe#at a smaller angle, you may catch your tires#this will give you the best view of oncoming trains#this will allow you to cross the tracks quickly"},
{"testId":66,"qid":2369,"description":"What is the best way to come to a complete stop?","explanation":"Applying both brakes equally is the best approach to come to a swift halt. Always utilize both brakes for the most efficient braking.","imageName":"","correctAnswer":30448,"isBookmarked":0,"aid":"6304#6306#30447#30448","text":"Use the front brake only#Use the rear brake only#Pull the engine cut-off switch#Apply both brakes evenly"},
{"testId":66,"qid":2370,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware that you are following them?","explanation":"If you feel that the driver of the car ahead of you is unaware that you are following, ride in the middle lane. In such lane, you will be seen in the middle of the vehicle's rear-view mirror. Drivers check their rearview mirrors more often than their side mirrors.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"22921#26553#26554#30449","text":"Ride in the center lane position#Increase your following distance to 5 seconds#Try to pass the vehicle as soon as possible#Slow down and ride in the left lane position"},
{"testId":67,"qid":2371,"description":"Riding a motorcycle while wearing a face shield:","explanation":"On a motorbike, wearing a face shield is the greatest type of face and eye protection since it covers your whole face and will not blow off in the wind. Kansas state law requires all passengers under the age of 18 to wear eye protection (goggles, face shields, etc.). If the motorbike does not have a windshield, all riders, regardless of age, must wear some type of eye protection (goggles, face shields, etc.).","imageName":"","correctAnswer":7280,"isBookmarked":0,"aid":"7280#7282#28971#30524","text":"is the best form of face and eye protection#is not as effective as wearing goggles#is only required of riders under the age of 18#is legally required of all riders"},
{"testId":67,"qid":2373,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"6942#7217#7288#7289","text":"drive only during the day#drink caffeinated beverages#avoid making long trips#take frequent rest breaks"},
{"testId":67,"qid":2374,"description":"Which of the following factors has an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content is affected by how quickly you drink, how many drinks you've had, and how much you weigh (BAC).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#7291#7292","text":"All of the above#How fast you drink#The number of drinks you've had#How much you weigh"},
{"testId":67,"qid":2375,"description":"When should you bike in a single file when riding in a group?","explanation":"When biking in a group, ride single file when approaching or leaving a highway and when navigating bends.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":511,"qid":2375,"description":"When should you bike in a single file when riding in a group?","explanation":"When biking in a group, ride single file when approaching or leaving a highway and when navigating bends.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":67,"qid":2376,"description":"Which of the following statement about carrying cargo on your motorcycle is correct?","explanation":"Fill your saddlebags evenly. If one side is heavier than the other, the bike may drift.","imageName":"","correctAnswer":10762,"isBookmarked":0,"aid":"10759#10760#10761#10762","text":"Use ropes to secure the load#Attach the cargo to your gas tank#Never carry cargo on a motorcycle#Fill saddlebags evenly to prevent drifting"},
{"testId":67,"qid":2377,"description":"In order to safely carry a passenger on your motorcycle, a motorcycle must have:","explanation":"To transport a passenger, the motorbike must have sufficient seat space and footrests.","imageName":"","correctAnswer":30767,"isBookmarked":0,"aid":"7204#7302#8224#30767","text":"A windshield#a backrest for the passenger#special shocks#enough seat room and a set of footrests for the passenger"},
{"testId":67,"qid":2378,"description":"How should you cross railroad tracks that are parallel to your course if you need to do so?","explanation":"Crossing railroad lines that run parallel to your path should be done at an angle of at least 45 degrees. Crossing at a smaller angle may result in your tires catching on the tracks.","imageName":"","correctAnswer":10575,"isBookmarked":0,"aid":"7304#10575#10576#24762","text":"Wait until the next intersection#Cross the tracks at a 45-degree angle#Cross the tracks at a 90-degree angle#Cross the tracks at the smallest possible angle"},
{"testId":67,"qid":2379,"description":"What should you do if you have a weaving sensation while riding over metal grating?","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":24723,"isBookmarked":0,"aid":"10578#10579#10580#24723","text":"Increase your speed#Decrease your speed#Grip the handlebars tightly to stop the feeling#Relax and maintain your speed"},
{"testId":67,"qid":2380,"description":"The safest place to ride in the rain is:","explanation":"The best spot to bike in the rain is in the tire tracks made by other cars. These are the least likely to be slick.","imageName":"","correctAnswer":30768,"isBookmarked":0,"aid":"7310#7312#7313#30768","text":"in the center lane position#on the shoulder of the road#on main roads only#in the tire tracks left by other vehicles"},
{"testId":67,"qid":2381,"description":"What can you do to increase your safety when riding at night?","explanation":"Increase your nighttime safety by utilizing your high beam, slowing down, and increasing your following distance.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6239#8123#10579#23000","text":"Increase your following distance#Use your high beam#Decrease your speed#Do all of the above"},
{"testId":67,"qid":2382,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If there is a driver inside a parked car you are passing, you should sound your horn to alert the driver. In this manner, he or she will not open the door directly into your path.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":67,"qid":2383,"description":"What can you do to reduce your reaction time when approaching an intersection?","explanation":"Cover the clutch and brake to shorten your response time and ready to stop swiftly.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"24741#24742#24743#24744","text":"Maintain or increase your speed#Look both ways#Point your toes downward#Cover the clutch and the brake"},
{"testId":67,"qid":2384,"description":"When following another vehicle, keep a following distance of at least:","explanation":"Maintain a following distance of at least 2 seconds while following another vehicle.","imageName":"","correctAnswer":26444,"isBookmarked":0,"aid":"14176#14178#26443#26444","text":"10 feet#20 feet#3 seconds#2 seconds"},
{"testId":67,"qid":2385,"description":"Which lane should you ride in to improve your visibility?","explanation":"Ride in the left lane to improve your line of sight. It allows you to see beyond the vehicle in front of you and anticipate potential hazards.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":67,"qid":2386,"description":"How else can you boost your visibility besides wearing bright clothing?","explanation":"In addition to wearing bright clothes, you may boost your visibility by wearing reflective apparel, particularly at night.","imageName":"","correctAnswer":10581,"isBookmarked":0,"aid":"10579#10581#10583#30769","text":"Decrease your speed#Wear reflective clothing#Wear a full-face helmet#Ride only in the left lane position"},
{"testId":67,"qid":2387,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake and smoothly reapply it to recover control.","imageName":"","correctAnswer":30770,"isBookmarked":0,"aid":"7326#7327#30770#30771","text":"Pull the kill engine switch#avoid applying the rear brake#release the front brake immediately and reapply it smoothly#keep it locked until you come to a complete stop"},
{"testId":67,"qid":2388,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If braking and swerving are both required to avoid colliding with an object, you should brake either before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30047,"isBookmarked":0,"aid":"30044#30045#30046#30047","text":"Brake before swerving, never after swerving#Brake after swerving, never before swerving#Brake while swerving#Brake before or after swerving"},
{"testId":67,"qid":2391,"description":"Why is pulling over to the shoulder more dangerous for motorbikes than for cars?","explanation":"Pulling over to the side of the road is more dangerous for motorbikes than for vehicles because the sand and gravel that accumulates there provides less traction.","imageName":"","correctAnswer":30773,"isBookmarked":0,"aid":"10690#10691#30772#30773","text":"It is more difficult for motorcycles to stop quickly#Motorcycles require a curb to park against#It is illegal to park a motorcycle on the side of the road#The side of the road is often sandy and may provide less traction"},
{"testId":67,"qid":2392,"description":"Your passenger's feet should remain on the foot pegs:","explanation":"Passengers should always keep their feet on the footrests. They do not need to put their feet down at junctions, unlike motorcyclists.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7344#28933#30774","text":"At all times#while the motorcycle is moving#unless you are stopped at an intersection#unless you are riding on a slippery surface"},
{"testId":67,"qid":2394,"description":"You should never drive motorcyle together in the same lane because:","explanation":"Motorcycles, like other vehicles, need a full lane width to function properly, thus you should never ride alongside another motorbike in the same lane. Never ever couple up.","imageName":"","correctAnswer":30778,"isBookmarked":0,"aid":"30775#30776#30777#30778","text":"you would be distracted by the other rider's movements#the other rider might swerve into you#it would make it difficult to ride through curves#motorcycles each require a full lane width to operate safely"},
{"testId":67,"qid":2395,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers may not be able to see you from certain regions.","imageName":"","correctAnswer":27941,"isBookmarked":0,"aid":"7352#7353#24647#27941","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicles will have difficulty seeing you in certain areas"},
{"testId":68,"qid":2396,"description":"What should you do to increase safety while driving at night?","explanation":"You should do the following to enhance your safety while biking at night: 1. Slow down to provide yourself more time to respond to the unexpected. 2. Increase your following distance to offer yourself extra maneuvering space if the car ahead of you comes to a halt. 3. Make use of the taillights of the car in front of you. Its headlights might assist you see better on the road. Furthermore, if the vehicle's taillights seem to bounce up and down, it is most likely going over bumps.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6920#8535#10656#32045","text":"Increase your following distance#Reduce your speed#Do all of the above#Use the taillights of the vehicle ahead"},
{"testId":68,"qid":2398,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will not reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"6600#7593#15460#31088","text":"Maintaining an adequate space cushion#Wearing protective gear#Using your turn signals#Wearing highly visible clothing"},
{"testId":68,"qid":2399,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Make sure your headlights are turned on and ride in a lane that allows you to be seen by other drivers. Never rely on eye contact to indicate that a motorist will yield.","imageName":"","correctAnswer":31089,"isBookmarked":0,"aid":"7367#7368#23087#31089","text":"Always ride with your headlight off#Increase your speed as you approach an intersection#Always count on eye contact as a sign that a driver will yield#Select a lane position that will most increase your visibility to other vehicle operators"},
{"testId":68,"qid":2400,"description":"When travelling, what distance behind you is required to give yourself a safe buffer zone?","explanation":"According to the Kentucky Motorcycle Manual, a minimum following distance of 4 seconds is recommended.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#4582#7321#7370","text":"4 seconds#20 feet#2 seconds#40 feet"},
{"testId":68,"qid":2401,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and brake to decrease response time.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"22927#24744#26499#26500","text":"Pull in the clutch#Cover the clutch and the brake#Shift into a higher gear#Skim your feet along the ground"},
{"testId":383,"qid":2401,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and brake to decrease response time.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"22927#24744#26499#26500","text":"Pull in the clutch#Cover the clutch and the brake#Shift into a higher gear#Skim your feet along the ground"},
{"testId":392,"qid":2401,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and brake to decrease response time.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"22927#24744#26499#26500","text":"Pull in the clutch#Cover the clutch and the brake#Shift into a higher gear#Skim your feet along the ground"},
{"testId":68,"qid":2403,"description":"When going past a row of parked cars, you need to be on the lookout for:","explanation":"When driving through a line of parked automobiles, keep an eye out for persons emerging from between the cars, drivers opening their doors, and cars departing their parking slots. Car drivers often fail to see bikes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7153#7154#8615#30016","text":"people emerging from between cars#drivers opening their doors#All of the above#cars exiting their parking spaces"},
{"testId":68,"qid":2404,"description":"To park against the curb, it is necessary to park:","explanation":"According to the Kentucky Motorcycle Manual, while parking against the curb, you should park at a 60- to 90-degree angle with your rear wheel contacting the curb.","imageName":"","correctAnswer":31090,"isBookmarked":0,"aid":"74#6407#7381#31090","text":"parallel to the curb#with your front wheel on the curb#with your rear wheel on the curb#at a 60- to 90-degree angle"},
{"testId":68,"qid":2405,"description":"Your brake light should blink:","explanation":"To alert people that you are slowing down in an unexpected or unusual location, flash your brake light.","imageName":"","correctAnswer":31091,"isBookmarked":0,"aid":"7382#31091#31092#31093","text":"every time you stop#when you are slowing down in an unusual place#when you exit a parking space#when you go straight through an intersection instead of turning"},
{"testId":68,"qid":2406,"description":"If you lock the back wheel on a traction-rich surface,:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop. If, on the other hand, you lock the front wheel, you must instantly release the front brake.","imageName":"","correctAnswer":7388,"isBookmarked":0,"aid":"7388#24659#24660#29379","text":"you can keep it locked until you come to a complete stop#release the rear brake and use only the front brake#release both the front and rear brakes and coast to a stop#release the rear brake and reapply it"},
{"testId":68,"qid":2407,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If an unanticipated obstruction forces you to veer left, use the left handgrip. This will force the motorbike to rapidly tilt to the left.","imageName":"","correctAnswer":24804,"isBookmarked":0,"aid":"6423#6569#24803#24804","text":"Apply the rear brake#Apply the front brake#press the right handgrip#press the left handgrip"},
{"testId":68,"qid":2408,"description":"When should you get up slightly from your seat?","explanation":"Rising slightly from your seat might help you absorb shock with your knees and elbows. When riding over obstacles, do this.","imageName":"","correctAnswer":31095,"isBookmarked":0,"aid":"7393#31094#31095#31096","text":"When preparing to pass another vehicle#When riding on a slippery surface#When riding over obstacles#To increase your visibility"},
{"testId":68,"qid":2409,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Motorcycles have poor grip on wet pavement, gravel roads, and lane lines.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#7396","text":"All of the above#Gravel roads#Wet pavement#Lane markings"},
{"testId":68,"qid":2410,"description":"You should _______, when braking on slick conditions.","explanation":"You should utilize both the front and rear brakes while stopping on slippery ground. Using both brakes is the most efficient.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#28935#30065","text":"use the rear brake only#Use the front brake only#use both the front and rear brakes#coast in neutral"},
{"testId":68,"qid":2411,"description":"When traveling with others, you should NEVER ride:","explanation":"When traveling in a group, never pair up (ride two abreast). To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7399,"isBookmarked":0,"aid":"6736#7198#7399#31097","text":"single file#in a staggered formation#two abreast#with more than two other motorcyclists"},
{"testId":68,"qid":2412,"description":"When you have a passenger or cargo on your motorcycle,:","explanation":"When you ride a motorbike with baggage or a passenger, the stopping distance increases. You should adjust your riding appropriately.","imageName":"","correctAnswer":30824,"isBookmarked":0,"aid":"7404#22982#30823#30824","text":"you must carry a special license#you must ride only during the day#the stopping distance will decrease#the stopping distance will increase"},
{"testId":68,"qid":2413,"description":"Which lane location is usually the greatest for seeing and being seen?","explanation":"The left lane position is typically the greatest for seeing and being seen, according to the Kentucky Motorcycle Manual.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":68,"qid":2414,"description":"Motorcycles usually need:","explanation":"Motorcycles, according to the Kentucky Motorcycle Manual, have a longer stopping distance than vehicles.","imageName":"","correctAnswer":26460,"isBookmarked":0,"aid":"26459#26460#26461#26462","text":"the same distance to stop as cars do#more distance to stop than cars do#less distance to stop than cars do#more room to operate safely than cars do"},
{"testId":68,"qid":2415,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":74,"qid":2415,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":375,"qid":2415,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":396,"qid":2415,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":404,"qid":2415,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":68,"qid":2416,"description":"Which of the following you should do before pulling over to the side of the road in an emergency?","explanation":"Before making an emergency stop on the side of the road, you should check if the surface is firm enough to ride on. Motorcycles need a firm surface with a lot of traction.","imageName":"","correctAnswer":31098,"isBookmarked":0,"aid":"6928#7414#7415#31098","text":"Move into the center lane position#Skim your feet along the road#Make sure your luggage is secure#Check if the surface is firm enough to ride on"},
{"testId":68,"qid":2418,"description":"When traveling in a staggered configuration, keep a following gap of ____ second(s) between yourself and the motorcycle in front of you.","explanation":"When riding in a staggered formation, keep a 2 second following space between yourself and the motorbike in front of you.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":68,"qid":2419,"description":"Riding along the road's side can be hazardous because:","explanation":"Riding along the road's side is risky for motorcyclists because dirt, gravel, and sand accumulate and give minimal traction.","imageName":"","correctAnswer":7422,"isBookmarked":0,"aid":"7422#7423#24857#28376","text":"dirt collects there and provides little traction#you may hit pedestrians#the pavement is of poor quality#riding there is prohibited"},
{"testId":68,"qid":2420,"description":"You are deemed legally intoxicated, if:","explanation":"If your blood alcohol content is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":31099,"isBookmarked":0,"aid":"7425#22775#31099#31100","text":"you have had at least one drink#you had one drink and are over the age of 21#your blood alcohol concentration is 0.08% or above#your blood alcohol concentration is 0.05% or above"},
{"testId":69,"qid":2422,"description":"A helmet is required in Louisiana:","explanation":"Every biker in Louisiana is required by law to wear a helmet at all times when riding a motorbike. In most kinds of incidents, a Department of Transportation-compliant helmet can protect a biker from significant head and neck injuries.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"6966#7343#7433#11119","text":"if you are under the age of 18#At all times#if you are under the age of 21#only at night"},
{"testId":69,"qid":2425,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":77,"qid":2425,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":388,"qid":2425,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":404,"qid":2425,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":416,"qid":2425,"description":"When another vehicle is coming up behind you, watch out for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":69,"qid":2426,"description":"What should you do if someone is following you too closely?","explanation":"If you are being trailed by a tailgater, change lanes or slow down to allow the tailgater to pass you.","imageName":"202006041812161292.jpg","correctAnswer":31444,"isBookmarked":0,"aid":"10578#10718#23301#31444","text":"Increase your speed#Flash your brake light#Pull over and stop#Encourage the tailgater to pass you"},
{"testId":69,"qid":2428,"description":"Allow at least ____ seconds of distance when traveling behind other vehicles.","explanation":"Allow at least 2 seconds of room while riding behind other cars.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":69,"qid":2429,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22797#22798#22799#24618","text":"Try to jump off the bike before hitting the ground#Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear"},
{"testId":69,"qid":2430,"description":"The available lane locations for a motorcycle inside a lane are:","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":69,"qid":2431,"description":"The front brake system:","explanation":"The front brake produces 70% of the stopping force, but unless one of the tires is flat, you should use both front and rear brakes to stop.","imageName":"","correctAnswer":7458,"isBookmarked":0,"aid":"7458#7459#7461#31445","text":"provides 70% of the stopping power#provides 30% of the stopping power#is controlled by a foot pedal#should only be used in emergencies"},
{"testId":69,"qid":2432,"description":"What happens if you bike too fast through a turn?","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":28212,"isBookmarked":0,"aid":"28211#28212#31446#31447","text":"You may tip over#You may veer into another lane#Other vehicle operators may become distracted#Your motorcycle may suffer mechanical failure"},
{"testId":69,"qid":2433,"description":"Which of the following is prohibited when riding downhill?","explanation":"All motor vehicles in Louisiana are prohibited from coasting downhill in neutral.","imageName":"","correctAnswer":17394,"isBookmarked":0,"aid":"7466#7468#7469#17394","text":"Shifting gears#Increasing your speed#Slowing down#Coasting in neutral"},
{"testId":69,"qid":2434,"description":"In order to safely carry a passenger on your motorcycle, you need to have:","explanation":"Your motorbike must have passenger footrests and a seat that can accommodate two persons.","imageName":"","correctAnswer":31122,"isBookmarked":0,"aid":"31122#36264#36265#36267","text":"Adequate seat room and separate footrests#A special passenger license or permit#Heavy-duty shocks and a certain type of suspension#A bright orange vest, leather gloves and heavy-duty boots"},
{"testId":69,"qid":2435,"description":"Which of the following you should NOT do when driving at night?","explanation":"To keep safe at night, you should reduce your pace rather than raise it.","imageName":"","correctAnswer":6515,"isBookmarked":0,"aid":"6515#6920#22923#31135","text":"Increase your speed#Increase your following distance#Use your high beam#Change your lane position as needed"},
{"testId":383,"qid":2435,"description":"Which of the following you should NOT do when driving at night?","explanation":"To keep safe at night, you should reduce your pace rather than raise it.","imageName":"","correctAnswer":6515,"isBookmarked":0,"aid":"6515#6920#22923#31135","text":"Increase your speed#Increase your following distance#Use your high beam#Change your lane position as needed"},
{"testId":69,"qid":2436,"description":"Riding beside a line of parked cars is risky because:","explanation":"Riding behind a line of parked automobiles is risky because someone inside a car may not see you and may open a door in your path.","imageName":"","correctAnswer":7478,"isBookmarked":0,"aid":"7478#31448#31449#31450","text":"car doors may be opened into your path#the road may be more slippery#your vision may be reduced#traffic may be heavier"},
{"testId":69,"qid":2437,"description":"If you must navigate a slick surface, you should:","explanation":"If you must coast over a slick area, let your feet to skim the surface as well. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":7481,"isBookmarked":0,"aid":"6423#7480#7481#10584","text":"Apply the rear brake#Lean back as far as possible#Allow your feet to skim the surface#Rise slightly off the seat"},
{"testId":69,"qid":2439,"description":"How do you keep your motorcycle stable in slow, tight turns?","explanation":"Stabilize your motorbike in slow, tight bends by maintaining your torso straight while leaning into the turn.","imageName":"","correctAnswer":28992,"isBookmarked":0,"aid":"7901#10645#24741#28992","text":"Lean with the motorcycle#Lean forward as much as possible#Maintain or increase your speed#Keep your body straight while leaning the motorcycle"},
{"testId":69,"qid":2445,"description":"When should you avoid taking the right lane?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you are in the right lane, you may have less room for error on your right side if you are struck by a blast of wind from a passing car, which impairs your control.","imageName":"","correctAnswer":31123,"isBookmarked":0,"aid":"6334#7509#30478#31123","text":"Never#When there is a hazard in the center of the road#When you are riding through a school zone#When another vehicle is passing you on the left"},
{"testId":70,"qid":2446,"description":"Motorcycle helmets are required in Maine:","explanation":"If you are under the age of 18, riding with a learner's permit, or completed your on-cycle competency test within the last year, Maine law requires you to wear a motorcycle helmet. If your passengers are under the age of 18 or if you are obliged to wear a helmet, they must also wear one.","imageName":"","correctAnswer":24131,"isBookmarked":0,"aid":"6966#7511#24131#31747","text":"if you are under the age of 18#if you are operating under a learner's permit#In all of the above situations#for one year after you pass your driving test"},
{"testId":70,"qid":2447,"description":"What is controlled by the right foot pedal?","explanation":"The rear brake is controlled by the right foot pedal. The right handgrip operates the front brake.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":70,"qid":2448,"description":"When should your mirrors be adjusted?","explanation":"Adjust your mirrors after mounting your motorbike but before you begin riding. Adjusting them while riding might be risky.","imageName":"","correctAnswer":31750,"isBookmarked":0,"aid":"7513#31748#31749#31750","text":"Before mounting your motorcycle#While your motorcycle is still on the stand#At the first stop after you start to ride#After mounting your motorcycle"},
{"testId":70,"qid":2449,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at an equal height with or below your elbows for precise steering.","imageName":"","correctAnswer":7520,"isBookmarked":0,"aid":"7517#7518#7519#7520","text":"at even height with your elbows#above or below your elbows#even with or above your elbows#even with or below your elbows"},
{"testId":70,"qid":2450,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most motorcyclists involved in incidents underbraked the front tire and overbraked the rear, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23077#23079#23080#30008","text":"underbraked the front tire and overbraked the rear#lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so"},
{"testId":425,"qid":2450,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most motorcyclists involved in incidents underbraked the front tire and overbraked the rear, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23077#23079#23080#30008","text":"underbraked the front tire and overbraked the rear#lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so"},
{"testId":70,"qid":2452,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since a motorbike tends to travel wide on a curve and may end up in another lane if the driver is not cautious.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":404,"qid":2452,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since a motorbike tends to travel wide on a curve and may end up in another lane if the driver is not cautious.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":425,"qid":2452,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since a motorbike tends to travel wide on a curve and may end up in another lane if the driver is not cautious.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":70,"qid":2455,"description":"The easiest strategy to reduce reaction time when approaching an intersection is to:","explanation":"When in a dangerous scenario, covering the clutch and brake is the greatest approach to decrease response time.","imageName":"","correctAnswer":7539,"isBookmarked":0,"aid":"7537#7538#7539#7540","text":"Keep the clutch pulled in when not accelerating#Avoid wearing a full-face helmet#Cover the clutch and the brake#Avoid using the highest gear"},
{"testId":70,"qid":2456,"description":"How many seconds of space is a least safe following distance between you and the vehicle ahead of you?","explanation":"A safe following distance is at least two seconds between you and the car in front of you.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":70,"qid":2460,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, gently shut the throttle and allow the machine to slow down. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of a wobble may make the motorbike even more unstable.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6432#6433#7326#31751","text":"Close the throttle gradually#Accelerate out of the wobble#Pull the kill engine switch#Use only the rear brake to slow and stop"},
{"testId":70,"qid":2462,"description":"Which of the following claims concerning freight transportation is true?","explanation":"When transporting stuff, strive to uniformly distribute the weight so that the motorbike stays balanced.","imageName":"","correctAnswer":30795,"isBookmarked":0,"aid":"11183#30795#31494#31752","text":"Ropes are the best method to secure cargo#You should distribute the weight evenly#You should not carry cargo while carrying a passenger#You may carry loads on the sissy bar"},
{"testId":396,"qid":2462,"description":"Which of the following claims concerning freight transportation is true?","explanation":"When transporting stuff, strive to uniformly distribute the weight so that the motorbike stays balanced.","imageName":"","correctAnswer":30795,"isBookmarked":0,"aid":"11183#30795#31494#31752","text":"Ropes are the best method to secure cargo#You should distribute the weight evenly#You should not carry cargo while carrying a passenger#You may carry loads on the sissy bar"},
{"testId":70,"qid":2464,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Motorcycles need a full lane width to function properly, despite being smaller than vehicles.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":70,"qid":2465,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for one-quarter of the stopping power of a motorbike. When stopping, always engage both the front and back brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":70,"qid":2468,"description":"Signal at least _____ before beginning a left or right turn.","explanation":"Maine law requires you to signal consistently for at least 100 feet before turning or changing lanes to give the cars behind you ample notice of your intentions. (Note: Some states have differing laws on this.) Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#1026#4061","text":"100 feet#200 feet#50 feet#75 feet"},
{"testId":70,"qid":2469,"description":"How frequenty should you inspect the tires on your motorcycle?","explanation":"Every time you ride your motorbike, you should inspect the tires. A motorbike tire issue might be even more dangerous than a vehicle tire problem.","imageName":"","correctAnswer":22776,"isBookmarked":0,"aid":"7242#7572#22776#31753","text":"At the start of every season#Monthly#Every time before you ride#Weekly"},
{"testId":70,"qid":2470,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"When you wear 1) a motorcycle safety helmet that is US DOT compliant; 2) face or eye protection; and 3) protective clothing such as a leather or long-sleeved jacket, long, heavy pants over ankle boots, and full-fingered leather gloves, you have a far better chance of avoiding serious injury in any collision.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":71,"qid":2471,"description":"If you are ________ in Maryland, you must wear a helmet.","explanation":"Whether you are the driver or a passenger, Maryland state law requires you to wear a helmet at all times when riding.","imageName":"","correctAnswer":7580,"isBookmarked":0,"aid":"7579#7580#7581#7582","text":"under 21#the driver or the passenger#riding at night#under 18"},
{"testId":71,"qid":2473,"description":"What should you do if a dog chases you while you're riding?","explanation":null,"imageName":"","correctAnswer":32342,"isBookmarked":0,"aid":"32339#32340#32341#32342","text":"Kick the dog away#Stop riding until the dog goes away#Approach the animal at full speed#Slowly approach the dog and then accelerate away from it"},
{"testId":71,"qid":2476,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":71,"qid":2477,"description":"What could be causing your motorcycle to lurch?","explanation":"If you move into a lower gear too quickly, your motorbike may lurch. Instead, slow down to the right pace for the lower gear you're going to change into.","imageName":"","correctAnswer":7602,"isBookmarked":0,"aid":"7599#7600#7602#23025","text":"Braking too slowly#Shifting into a higher gear too soon#Shifting into a lower gear too soon#Braking with only one brake"},
{"testId":71,"qid":2479,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, squeeze the handgrip to lean in the direction you want to go, then roll on the throttle to perform a controlled turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6199#32343#32344#32345","text":"Slow, look, press, roll#Slow, search, tilt, roll#Squeeze, look, press, tilt#Search, press, tilt, roll"},
{"testId":71,"qid":2480,"description":"How many seconds is a least safe following distance behind the vehicle ahead of you?","explanation":"At least two seconds behind the car in front of you is a safe following distance.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":71,"qid":2482,"description":"To detect road bumps at night, you should:","explanation":"Watch the taillights of the automobile in front of you at night. If they seem to be up and down shifting, the vehicle is probably travelling over humps.","imageName":"","correctAnswer":32346,"isBookmarked":0,"aid":"7482#32346#32347#32348","text":"swerve from side to side#watch the taillights of the vehicle ahead of you#drive only in residential areas#avoid driving near other vehicles"},
{"testId":71,"qid":2483,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers to your left, you should use lane positions 2 (middle) or 3 (right) to avoid them.","imageName":"","correctAnswer":28899,"isBookmarked":0,"aid":"28899#28901#32349#32350","text":"2 (center) or 3 (right)#1 (left) or 2 (center)#1 (left)#1 (left) or 3 (right)"},
{"testId":71,"qid":2484,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":71,"qid":2485,"description":"Experienced riders limit their riding time to _____ hours per day.","explanation":"To minimize exhaustion, limit your riding time to no more than six hours every day. Take regular breaks, ideally every two hours.","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":71,"qid":2486,"description":"The most effective strategy to avoid weariness while riding is to:","explanation":"Taking regular rest breaks, at least once every two hours, is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":32351,"isBookmarked":0,"aid":"7622#7624#30796#32351","text":"Drink energy drinks#Avoid wearing a helmet#Drink lots of water#stop at least once every two hours"},
{"testId":71,"qid":2487,"description":"Which member in a riding group sets the pace?","explanation":"When riding in a group, the pace is set by the tail rider. If the person in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#7625#7627","text":"The group leader#The tail rider#The rider with the least experience#Whoever rides the fastest"},
{"testId":71,"qid":2488,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots can untie.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":71,"qid":2489,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":32352,"isBookmarked":0,"aid":"7802#24853#32352#32353","text":"sit as far back as possible#put his or her feet down at stops#keep his or her feet on the footpegs at all times#avoid holding on to you"},
{"testId":71,"qid":2491,"description":"What should you do if you have a weaving sensation while riding over metal grating?","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation might be dangerous.","imageName":"","correctAnswer":32356,"isBookmarked":0,"aid":"22823#32354#32355#32356","text":"Pull the kill engine switch#Try to fight the weave#Grip the handlebars firmly#Relax and stay on course"},
{"testId":71,"qid":2493,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows you to utilize your knees and elbows to absorb impact. This is possible while biking over obstacles or on uneven terrain.","imageName":"","correctAnswer":32357,"isBookmarked":0,"aid":"7646#27957#32357#32358","text":"should be done to fix a wobble#should only be done by experienced riders#helps you use your knees and elbows to absorb shock#is prohibited on a motorcycle"},
{"testId":71,"qid":2494,"description":"When passing a parked car with a driver inside, you should:","explanation":"If you are going to pass a parked vehicle with a driver inside, you should sound your horn to alert the driver that you are approaching. Drivers often fail to see approaching motorcycles.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#7650#7651","text":"Beep your horn#Flash your brake light#wave your arms#Come to a complete stop"},
{"testId":71,"qid":2495,"description":"To park parallel to a curb, you must park at a _____ angle.","explanation":"Parking against a curb should be done at a 45-degree angle, with the back wheel hitting the curb.","imageName":"","correctAnswer":10674,"isBookmarked":0,"aid":"10674#10675#10677#10683","text":"45-degree#20-degree#90-degree#180-degree"},
{"testId":72,"qid":2496,"description":"Riding across lanes of traffic is:","explanation":"Riding between traffic lanes (lane splitting) is illegal in Massachusetts at all times.","imageName":"","correctAnswer":7652,"isBookmarked":0,"aid":"7652#7655#22874#32672","text":"prohibited at all times#prohibited at night#allowed in designated areas#permitted only when traffic is at a standstill"},
{"testId":72,"qid":2497,"description":"A helmet is needed to be worn in Massachusetts ________.","explanation":"Riders and passengers in Massachusetts must wear helmets at all times while riding.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7656#7658#32673","text":"At all times#only on freeways#by those under the age of 18#by the rider only"},
{"testId":72,"qid":2498,"description":"A motorcycle cannot be driven on a highway:","explanation":"You cannot ride a motorbike on a highway unless you can drive at the posted speed safely.","imageName":"","correctAnswer":32674,"isBookmarked":0,"aid":"7659#7661#32674#32675","text":"during rush hour#at night, because your lights aren't bright enough#unless you can travel safely at the minimum posted speeds#faster than 45 mph"},
{"testId":72,"qid":2500,"description":"Your ability to ride can be impaired with as few as ___ alcoholic drink(s).","explanation":"Even one alcoholic drink might impair your ability to bike.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":72,"qid":2501,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"While all of your options are essential, you should be extra cautious about automobiles turning left into your path. Car drivers often fail to see approaching motorcyclists.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":72,"qid":2502,"description":"When you are properly seated on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This enables you to turn securely and precisely.","imageName":"","correctAnswer":22887,"isBookmarked":0,"aid":"6827#7671#22886#22887","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent at the elbows"},
{"testId":72,"qid":2503,"description":"What should you avoid when turning your motorcycle?","explanation":"Deceleration should be avoided while turning a motorbike. Maintain your pace or accelerate to assist the motorbike remain stable.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":72,"qid":2505,"description":"Riding __________ lowers your visibility.","explanation":"Travelling alongside other cars reduces your visibility since you may be riding in their blind areas.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7681#7682#7683#7684","text":"while using your high beam#alongside other vehicles#near other motorcycles#in residential areas"},
{"testId":72,"qid":2507,"description":"Most bikes utilize convex mirrors. What makes these mirrors different from others?","explanation":"Convex mirrors vary from conventional mirrors in that the things in them seem to be farther away than they are. Convex mirrors, on the other hand, can provide a wider field of view.","imageName":"","correctAnswer":32678,"isBookmarked":0,"aid":"32676#32677#32678#32679","text":"Objects in convex mirrors appear closer than they really are#Convex mirrors do not give a good view of what is behind you#Objects in convex mirrors appear farther away than they really are#Convex mirrors will reflect light into your eyes when it's sunny"},
{"testId":72,"qid":2508,"description":"What is an adequate space cushion between you and the vehicle ahead of you when riding in heavy, congested traffic?","explanation":null,"imageName":"","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":72,"qid":2509,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If a tailgater is tailgating you and is unable to pass you, extend the spacing cushion in front of you to allow both of you plenty of area to stop.","imageName":"202006041812161292.jpg","correctAnswer":7691,"isBookmarked":0,"aid":"6280#6286#7691#7692","text":"pull over and stop#Flash your brake light#increase the space cushion in front of you#take the next exit from the road"},
{"testId":72,"qid":2510,"description":"You should ride in the ______ lane when being passed by another vehicle.","explanation":"You should ride in the middle lane while being passed by another car. This helps to avoid risks from passing vehicles and keeps them from attempting to share your lane.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7693","text":"Center#Right#Left#left or center"},
{"testId":72,"qid":2511,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by other drivers.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"638#30816#31758#31759","text":"A school zone#An intersection#A freeway#A rural road"},
{"testId":72,"qid":2512,"description":"To prevent other vehicles from attempting to share your lane, you should ride in this lane position?","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It takes up the least amount of space on each side.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":72,"qid":2513,"description":"If you are riding on a small, slick surface, you should:","explanation":"When riding on a tiny, slick surface, you should engage the clutch.","imageName":"","correctAnswer":6582,"isBookmarked":0,"aid":"6582#7698#7700#26438","text":"Pull in the clutch#use the highest gear possible#avoid using the rear brake#use your low-beam headlight"},
{"testId":72,"qid":2515,"description":"When should a motorcycle passenger board?","explanation":"After the rider has mounted and the engine has started, a passenger should mount the motorbike.","imageName":"","correctAnswer":32680,"isBookmarked":0,"aid":"7704#7705#32680#32681","text":"Before the engine is started#While it is still on the kickstand#After the engine has started#Before the rider"},
{"testId":72,"qid":2516,"description":"If you want to ride at night, you should:","explanation":"Increase your following distance while biking at night. This provides you with more time to respond to the unexpected.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#7706#7707","text":"Increase your speed#Increase your following distance#use your low beam#decrease your following distance"},
{"testId":72,"qid":2517,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":72,"qid":2518,"description":"Motorcyclists should ride _____, when riding in a group.","explanation":"Motorcyclists should ride in a staggered arrangement while riding in a group. It keeps the group close while also providing some breathing room.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"7111#7198#7712#23023","text":"in pairs#in a staggered formation#in single file#in an alternate formation"},
{"testId":72,"qid":2520,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, it is important to check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":73,"qid":2521,"description":"When is it legal to ride a motorcycle between traffic lanes?","explanation":"It is never safe to ride a motorbike between lanes of traffic, even if there seems to be enough space. It's against the law in Michigan.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#7722#7723#7724","text":"Never#During rush hour#Whenever it is safe to do so#Only in designated areas"},
{"testId":73,"qid":2522,"description":"You should use _______ to hold the motorcycle while you start the engine and transfer into first gear while starting on a climb.","explanation":"Use the front brake to hold your motorbike while you start the engine and transfer into first gear while starting on a climb. Switch to the rear brake to keep your car stable while you use your right hand to handle the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6146,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":73,"qid":2524,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing durable clothes does not reduce your odds of being involved in an accident, but it will assist protect you if you are.","imageName":"","correctAnswer":33189,"isBookmarked":0,"aid":"7591#10801#33189#33190","text":"Using your signal lights#Maintaining a space cushion#Wearing sturdy clothing#Scanning the road for hazards"},
{"testId":73,"qid":2526,"description":"What causes lugging in a motorcycle engine?","explanation":"You should shift up quickly enough to prevent the engine from overrevving as your motorbike gains speed, but not so quickly that the engine starts to lug. The engine may lug if you go into a higher gear too quickly. Ensure that you are shifting when it is appropriate.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":73,"qid":2528,"description":"What exactly is engine braking?","explanation":"Instead of depending only on the brakes, engine braking is accomplished by downshifting through the gears to slow the motorbike.","imageName":"","correctAnswer":7746,"isBookmarked":0,"aid":"7745#7746#10916#29390","text":"Upshifting to gain speed on hills#Downshifting through gears to slow the motorcycle#Using the engine kill switch to stop the motorcycle#When your engine stops working"},
{"testId":431,"qid":2528,"description":"What exactly is engine braking?","explanation":"Instead of depending only on the brakes, engine braking is accomplished by downshifting through the gears to slow the motorbike.","imageName":"","correctAnswer":7746,"isBookmarked":0,"aid":"7745#7746#10916#29390","text":"Upshifting to gain speed on hills#Downshifting through gears to slow the motorcycle#Using the engine kill switch to stop the motorcycle#When your engine stops working"},
{"testId":446,"qid":2528,"description":"What exactly is engine braking?","explanation":"Instead of depending only on the brakes, engine braking is accomplished by downshifting through the gears to slow the motorbike.","imageName":"","correctAnswer":7746,"isBookmarked":0,"aid":"7745#7746#10916#29390","text":"Upshifting to gain speed on hills#Downshifting through gears to slow the motorcycle#Using the engine kill switch to stop the motorcycle#When your engine stops working"},
{"testId":471,"qid":2528,"description":"What exactly is engine braking?","explanation":"Instead of depending only on the brakes, engine braking is accomplished by downshifting through the gears to slow the motorbike.","imageName":"","correctAnswer":7746,"isBookmarked":0,"aid":"7745#7746#10916#29390","text":"Upshifting to gain speed on hills#Downshifting through gears to slow the motorcycle#Using the engine kill switch to stop the motorcycle#When your engine stops working"},
{"testId":73,"qid":2529,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to activate both the front and back brakes at the same time.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":73,"qid":2530,"description":"You can assist yourself maintain your balance while cycling by:","explanation":"Keep your knees against the gas tank to help keep your balance while riding.","imageName":"","correctAnswer":7751,"isBookmarked":0,"aid":"7751#7752#10828#10829","text":"keeping your knees against the gas tank#locking your elbows#sitting as far back as possible#wearing lightweight riding gear"},
{"testId":73,"qid":2533,"description":"How many seconds of following distance should you leave when following another vehicle?","explanation":"When following another vehicle, you should leave enough distance to allow yourself ample time to respond. Different authorities vary on how much is acceptable. At least four seconds is recommended per the Michigan Motorcycle Operator Manual.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":73,"qid":2534,"description":"When prepared to pass a vehicle in front of you, ride in ______ lane.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":122,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":73,"qid":2535,"description":"How much of a traffic lane does a motorcycle require to operate safely?","explanation":"Motorcycles, while being smaller than vehicles, need a full lane width to function properly.","imageName":"","correctAnswer":7075,"isBookmarked":0,"aid":"7074#7075#7076#7077","text":"Half a lane width#A full lane width#A third of a lane width#A quarter of a lane width"},
{"testId":73,"qid":2536,"description":"When you observe a car on an entry ramp ready to merge into your lane, you should :","explanation":"When you observe a vehicle on an entry ramp ready to enter into your lane, shift lanes to give the driver enough space to join.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#7651","text":"Change lanes#Beep your horn#Flash your brake light#Come to a complete stop"},
{"testId":73,"qid":2537,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"Look 12 seconds ahead for hazards and possible threats while scanning the road. Twelve seconds may not sound like much, but keep in mind that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":73,"qid":2538,"description":"You should cover the clutch and brake whenever possible to:","explanation":"When you wish to shorten your response time, cover the clutch and brake.","imageName":"","correctAnswer":7763,"isBookmarked":0,"aid":"27#6476#7763#24790","text":"Change lanes#Slow down#reduce your reaction time#alert others to your actions"},
{"testId":396,"qid":2538,"description":"You should cover the clutch and brake whenever possible to:","explanation":"When you wish to shorten your response time, cover the clutch and brake.","imageName":"","correctAnswer":7763,"isBookmarked":0,"aid":"27#6476#7763#24790","text":"Change lanes#Slow down#reduce your reaction time#alert others to your actions"},
{"testId":400,"qid":2538,"description":"You should cover the clutch and brake whenever possible to:","explanation":"When you wish to shorten your response time, cover the clutch and brake.","imageName":"","correctAnswer":7763,"isBookmarked":0,"aid":"27#6476#7763#24790","text":"Change lanes#Slow down#reduce your reaction time#alert others to your actions"},
{"testId":420,"qid":2538,"description":"You should cover the clutch and brake whenever possible to:","explanation":"When you wish to shorten your response time, cover the clutch and brake.","imageName":"","correctAnswer":7763,"isBookmarked":0,"aid":"27#6476#7763#24790","text":"Change lanes#Slow down#reduce your reaction time#alert others to your actions"},
{"testId":73,"qid":2539,"description":"When you get to a blind intersection, you should:","explanation":"When approaching a blind junction, ride in the lane position that allows you to be seen the best.","imageName":"","correctAnswer":33192,"isBookmarked":0,"aid":"7766#33191#33192#33193","text":"swerve from side to side to get attention#ride in the right lane position#ride in the lane position in which you can be seen most easily#use hand signals in addition to your motorcycle's signal lights"},
{"testId":73,"qid":2540,"description":"When you pass a row of cars parked on your right, you should:","explanation":"When passing a line of parked automobiles on your right, ride in the left lane to stay as far away from the risks connected with parked cars as possible.","imageName":"","correctAnswer":10407,"isBookmarked":0,"aid":"7770#10407#33194#33195","text":"swerve from side to side so no one will try to share your lane#Ride in the left lane position#ride close to the cars so their drivers can see you#ride as slowly as possible"},
{"testId":73,"qid":2541,"description":"When parking on the side of the road, you should park :","explanation":"When parking on the side of the road, park at an angle with the back tire hitting the curb.","imageName":"","correctAnswer":7773,"isBookmarked":0,"aid":"74#7773#7774#7775","text":"parallel to the curb#at an angle with the rear wheel touching the curb#at an angle with the front wheel touching the curb#perpendicular to the curb without wheels touching"},
{"testId":73,"qid":2542,"description":"What should you do if you're braking and your front wheel locks?","explanation":"If your front wheel locks up during braking, remove the front brake and reapply it. If your back wheel locks, you may keep it locked until you come to a full stop.","imageName":"","correctAnswer":33196,"isBookmarked":0,"aid":"22823#33196#33197#33198","text":"Pull the kill engine switch#Release the front brake and apply it again#Keep the front wheel locked until you come to a complete stop#Release the front brake and only use the rear brake"},
{"testId":73,"qid":2543,"description":"Which of the following surfaces is the most dangerous for riding a motorcycle?","explanation":"Heat causes oil deposits in the asphalt to rise to the top, which rains loosens. This makes the road more slippery until the oil is washed away by additional rain.","imageName":"","correctAnswer":7781,"isBookmarked":0,"aid":"7779#7780#7781#7782","text":"Hot pavement in the sun#Dry, cold pavement#Wet pavement right after it has started to rain#Pavement in northern climates"},
{"testId":73,"qid":2544,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#30078","text":"45-degree#90-degree#180-degree#120-degree"},
{"testId":73,"qid":2545,"description":"When transporting stuff with saddlebags, you should:","explanation":"When carrying goods in saddlebags, divide the weight equally across bags to keep the motorbike balanced.","imageName":"","correctAnswer":6953,"isBookmarked":0,"aid":"6953#7783#7784#25121","text":"distribute the weight evenly#secure the saddlebags with rope#ensure the saddlebags are full#not carry passengers"},
{"testId":75,"qid":2546,"description":"How should you cross railroad tracks that are parallel to your course if you need to do so?","explanation":"If you need to cross railroad lines that run parallel to your path, go far enough away from the tracks to cross at a 45-degree angle. If you cross at a smaller angle, your tires may grab on the tracks and knock you off balance.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":75,"qid":2547,"description":"If you want to ride at night, you should:","explanation":"You should ride slower at night than you would during the day to give yourself more time to respond to the unexpected.","imageName":"","correctAnswer":7791,"isBookmarked":0,"aid":"7791#10418#32738#33909","text":"ride slower than you would during the day#avoid wearing a tinted face shield#stay close behind the vehicle in front of you#always use your high-beam headlight"},
{"testId":75,"qid":2548,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22797#22798#24618#33910","text":"Try to jump off the bike before hitting the ground#Roll away from the motorcycle#Wear protective motorcycle clothing and gear#Tip the motorcycle if you know you will crash"},
{"testId":75,"qid":2549,"description":"Which of the following statement concerning braking is correct?","explanation":"Always utilize both the front and rear brakes for the most efficient braking.","imageName":"","correctAnswer":31457,"isBookmarked":0,"aid":"22875#22876#31457#33911","text":"The rear brake has most of the stopping power#The front brake is controlled by a foot lever#Stop quickly by using both the front and rear brakes#The rear brake should only be used for slowing down"},
{"testId":75,"qid":2550,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":7805,"isBookmarked":0,"aid":"7802#7805#32353#33582","text":"sit as far back as possible#lean with you during turns#avoid holding on to you#put his or her feet down when the motorcycle stops"},
{"testId":75,"qid":2552,"description":"What should you do if your motorcycle begins to weave when riding over a metal grating?","explanation":"If your motorbike begins to weave when traveling over a metal grating, relax, maintain your speed, and ride straight over. Attempting to correct for weaving might be dangerous.","imageName":"","correctAnswer":32356,"isBookmarked":0,"aid":"10578#10838#32356#33912","text":"Increase your speed#Apply the rear brake only#Relax and stay on course#Coast over the rest of the grating"},
{"testId":75,"qid":2553,"description":"You should _______,when traversing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#7814#7815#28975","text":"Use the front brake only#stop and walk the bike across#pull in the clutch and coast#accelerate or maintain your speed"},
{"testId":75,"qid":2555,"description":"To transport a passenger,:","explanation":"To transport a passenger, your motorbike must have passenger footrests and a seat large enough for two persons. Your passenger should wear the same safety equipment as you.","imageName":"","correctAnswer":33914,"isBookmarked":0,"aid":"33913#33914#33915#33916","text":"you must have a special license#the motorcycle must have footrests for the passenger#the passenger must wear a face shield#the motorcycle must be equipped with special shocks"},
{"testId":75,"qid":2556,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content may be affected by how much alcohol you consume, how quickly you drink it, and your body weight (BAC).","imageName":"","correctAnswer":7290,"isBookmarked":0,"aid":"7290#7825#7826#29011","text":"How fast you drink#The type of alcohol#The time of day#The location at which you are drinking"},
{"testId":75,"qid":2557,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":7829,"isBookmarked":0,"aid":"7828#7829#7830#24625","text":"is on the inside of the curve#may not follow the curve of the road#is on the outside of the curve#is in the center lane position"},
{"testId":75,"qid":2558,"description":"What is the best strategy to avoid riding fatigue?","explanation":"You should take regular rests, at least once every two hours, since operating a motorbike is more exhausting than operating a vehicle.","imageName":"","correctAnswer":10715,"isBookmarked":0,"aid":"10715#33917#33918#33919","text":"Take frequent breaks#Drink caffeinated beverages#Avoid tinted glasses and face shields#Ride in the afternoon"},
{"testId":75,"qid":2560,"description":"When riding in a group, participants should communicate by:","explanation":"Hand signals should be used by group members while riding in a group. Before you leave, make sure that everyone in your party is comfortable with the hand gestures.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":442,"qid":2560,"description":"When riding in a group, participants should communicate by:","explanation":"Hand signals should be used by group members while riding in a group. Before you leave, make sure that everyone in your party is comfortable with the hand gestures.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":511,"qid":2560,"description":"When riding in a group, participants should communicate by:","explanation":"Hand signals should be used by group members while riding in a group. Before you leave, make sure that everyone in your party is comfortable with the hand gestures.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":75,"qid":2561,"description":"Using _____ is the best way to secure cargo on a motorcycle.","explanation":"Elastic ropes are the finest method to secure baggage to a motorbike. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":8225,"isBookmarked":0,"aid":"8225#23057#23058#23059","text":"Elastic cords#strong rope#the sissy bar#strips of cloth"},
{"testId":75,"qid":2562,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7846#7847#24857","text":"dirt and gravel provide little traction#there is poor visibility#it is not driven on often#the pavement is of poor quality"},
{"testId":75,"qid":2563,"description":"Which of the following statements is FALSE?","explanation":"Motorcycles, like vehicles, have blind areas.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10707#30825#33596","text":"You must be in good physical condition to ride a motorcycle#Motorcycles do not have blind spots#You should fill saddlebags evenly#The center lane position is usually safe to ride in"},
{"testId":75,"qid":2564,"description":"When riding beside a line of parked cars, keep an eye out for:","explanation":"When riding alongside a row of parked automobiles, keep an eye out for risks such as doors opening into your path, cars pulling out in front of you, and persons appearing from between cars.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7153#7851#7852#8615","text":"people emerging from between cars#doors opening in your path#cars pulling out in front of you#All of the above"},
{"testId":75,"qid":2565,"description":"The most likely location of a collision is:","explanation":"An junction is the most probable location for a collision. Car drivers often fail to see motorcycles.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6402#7854#7855#7856","text":"at an intersection#on a rural road#on a dark highway#in a school zone"},
{"testId":75,"qid":2568,"description":"You should leave a trailing gap of at least ___ seconds when riding behind another vehicle.","explanation":"When following another vehicle, maintain a following distance of at least two seconds. This will allow you to respond to the unexpected.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5446#6388#6389#6916","text":"10#2#3#6"},
{"testId":75,"qid":2569,"description":"If you have to stop on a curve, try to:","explanation":"When coming to a halt on a curve, strive to keep your motorbike upright. Stopping while leaning is dangerous.","imageName":"","correctAnswer":31161,"isBookmarked":0,"aid":"6760#7861#7862#31161","text":"lean with the motorcycle#maintain a center lane position#only use the front brake#straighten your motorcycle upright"},
{"testId":75,"qid":2570,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The shifting lever is operated by your left foot. The rear brake pedal is operated by your right foot.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#7863#7864#31788","text":"right foot pedal#rear brake lever#front brake lever#left foot lever"},
{"testId":74,"qid":2571,"description":"You must wear safety glasses:","explanation":"Wind and debris transported by the wind are not effectively protected by a windshield. Even if your motorbike has a windshield, you must wear protective eyewear in Minnesota. In Minnesota, however, even eyeglasses (but not contact lenses) are considered protective eyewear.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7866#33568#33569","text":"At all times#only while riding on highways and freeways#only if you're riding a motorcycle without a windshield#only if your passenger insists on it"},
{"testId":74,"qid":2572,"description":"Is it mandatory for passengers to wear helmets?","explanation":"Helmets are compulsory for all motorcyclists and passengers under the age of 18 in Minnesota. Motorcyclists with motorcycle teaching licences are also exempt.","imageName":"","correctAnswer":33570,"isBookmarked":0,"aid":"18118#21234#33570#33571","text":"No#Yes#Only those under 18 years of age#Only those under 16 years of age"},
{"testId":74,"qid":2573,"description":"When is it legal to ride a motorcycle between traffic lanes?","explanation":"It is never safe to ride a motorbike between lanes of traffic, even if there seems to be enough space. It's against the law in Minnesota.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8087#28377#28979","text":"Never#In designated areas#When traffic is at a standstill#Only if both lanes are headed in the same direction"},
{"testId":74,"qid":2574,"description":"You may go only if_______ and you believe your motorcycle has not activated a traffic light.","explanation":"If you feel your motorbike has not activated a traffic signal, you may continue if you have totally stopped, no one is approaching the route you want to cross, and the red light has been on for an unusually lengthy period of time.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"7874#7875#24643#33572","text":"you have stopped completely#no one is approaching the roadway#all of the above are true#the red light has been on for an unreasonably long time"},
{"testId":74,"qid":2575,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group. Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":404,"qid":2575,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group. Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":74,"qid":2576,"description":"Which of the following statement concerning drinking alcohol and riding a motorcycle is correct?","explanation":null,"imageName":"","correctAnswer":33576,"isBookmarked":0,"aid":"33573#33574#33575#33576","text":"Alcohol does not affect motorcycle riders#Motorcycle riders are not allowed to drink any alcohol#Alcohol affects car drivers more than motorcycle riders#Alcohol impairs a motorcycle rider's skills sooner and more severely than a car driver鈥檚 skills"},
{"testId":74,"qid":2581,"description":"You will soon make a turn. If shifting gears is required, you must do so:","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6531,"isBookmarked":0,"aid":"6186#6530#6531#10807","text":"During the turn#After the turn#Before the turn#Before, during, or after the turn"},
{"testId":404,"qid":2581,"description":"You will soon make a turn. If shifting gears is required, you must do so:","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6531,"isBookmarked":0,"aid":"6186#6530#6531#10807","text":"During the turn#After the turn#Before the turn#Before, during, or after the turn"},
{"testId":74,"qid":2585,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":74,"qid":2586,"description":"Always keep a safe following distance of at least ___ seconds.","explanation":"Always maintain at least a two-second safe following distance.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":74,"qid":2588,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6245#6725#24700#28929","text":"release the rear brake only#Apply the rear brake only#release the front brake and reapply it smoothly#continue applying the front brake"},
{"testId":74,"qid":2590,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn anyone behind you, flash your brake light.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#30062#33578#33579","text":"Flash your brake light#avoid riding in heavy traffic#honk your horn#use hand signals at all times"},
{"testId":74,"qid":2591,"description":"When you look over your shoulder before changing lanes, you are checking:","explanation":"Before changing lanes, you should do a head check of the lane beside you and the far lane to see if other vehicles are planning to move into the lane beside you.","imageName":"","correctAnswer":7920,"isBookmarked":0,"aid":"7917#7920#31154#33580","text":"the traction of the road surface#the lane beside you and the far lane#areas of vision that are blocked by your helmet#the sizes of the vehicles that are close to you"},
{"testId":454,"qid":2591,"description":"When you look over your shoulder before changing lanes, you are checking:","explanation":"Before changing lanes, you should do a head check of the lane beside you and the far lane to see if other vehicles are planning to move into the lane beside you.","imageName":"","correctAnswer":7920,"isBookmarked":0,"aid":"7917#7920#31154#33580","text":"the traction of the road surface#the lane beside you and the far lane#areas of vision that are blocked by your helmet#the sizes of the vehicles that are close to you"},
{"testId":74,"qid":2593,"description":"It is most secure to traverse an obstruction:","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":22958,"isBookmarked":0,"aid":"22958#23001#23003#23004","text":"At a 90-degree angle#While maintaining your speed#With your feet skimming the ground#While keeping your elbows locked"},
{"testId":74,"qid":2594,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Motorcycles have insufficient grip on lane lines, gravel roads, and falling leaves.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#7396#33581","text":"All of the above#Gravel roads#Lane markings#Fallen leaves"},
{"testId":74,"qid":2595,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#7802#7930#33582","text":"sit as far forward as possible#sit as far back as possible#remain upright when the motorcycle leans#put his or her feet down when the motorcycle stops"},
{"testId":76,"qid":2596,"description":"To avoid weariness, you should stop riding and rest at least once every _____ hours.","explanation":"Motorcycling may be more exhausting than driving a vehicle. To avoid weariness, you should get off your motorbike and relax at least every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#5002","text":"Two#Four#Six#Five"},
{"testId":420,"qid":2596,"description":"To avoid weariness, you should stop riding and rest at least once every _____ hours.","explanation":"Motorcycling may be more exhausting than driving a vehicle. To avoid weariness, you should get off your motorbike and relax at least every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#5002","text":"Two#Four#Six#Five"},
{"testId":76,"qid":2597,"description":"Which one of the following doesn't have an impact on your blood alcohol content (BAC)?","explanation":"The time of day has no bearing on your blood alcohol levels (BAC). At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":7826,"isBookmarked":0,"aid":"7290#7826#7932#7933","text":"How fast you drink#The time of day#The amount of alcohol#Your weight"},
{"testId":408,"qid":2597,"description":"Which one of the following doesn't have an impact on your blood alcohol content (BAC)?","explanation":"The time of day has no bearing on your blood alcohol levels (BAC). At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":7826,"isBookmarked":0,"aid":"7290#7826#7932#7933","text":"How fast you drink#The time of day#The amount of alcohol#Your weight"},
{"testId":76,"qid":2599,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#7939#26445","text":"Taking curves#riding in a group#parking parallel to the curb#in an emergency situation"},
{"testId":408,"qid":2599,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#7939#26445","text":"Taking curves#riding in a group#parking parallel to the curb#in an emergency situation"},
{"testId":76,"qid":2604,"description":"Which lanes could be dangerous when it starts to rain?","explanation":"When it begins to rain, the center lane position might be dangerous due to the accumulation of oil and fluid. Riding in the paths of other cars is the safest option. These are less prone to slipping.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":76,"qid":2605,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"For the optimum traction, motorcycles need a hard, solid surface. Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6720#7396#7958","text":"Gravel roads#Warm pavement#Lane markings#The edge of the road"},
{"testId":76,"qid":2607,"description":"What should you do if your front wheel locks?","explanation":"If your front wheel locks, remove the front brake immediately and smoothly reapply it to recover control.","imageName":"","correctAnswer":31498,"isBookmarked":0,"aid":"10710#31496#31497#31498","text":"Pull the engine kill switch#Release the front brake and apply the rear brake only#Keep the front brake applied until you come to a complete stop#Release the front brake and reapply it smoothly"},
{"testId":76,"qid":2608,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":76,"qid":2609,"description":"Distances are more difficult to assess at night, therefore:","explanation":"To allow yourself more time to respond at night, you should slow down and widen your following distance.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#22782#22783","text":"Increase your speed#Increase your following distance#avoid riding at night#drive only on rural roads"},
{"testId":76,"qid":2611,"description":"If you intend to slow down in an odd location, you should:","explanation":"Tap the brake pedal to flash your brake light to the car behind you to alert them that you are slowing down in an odd location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7650#7977#7978","text":"Flash your brake light#wave your arms#slow down as fast as possible#Use engine braking"},
{"testId":76,"qid":2613,"description":"To park in a parallel parking space, you need to park at an angle:","explanation":"Normally, you should park at an angle in a parallel parking place, with your back tire contacting the curb.","imageName":"","correctAnswer":7019,"isBookmarked":0,"aid":"7019#7381#7981#34208","text":"with your rear tire touching the curb#with your rear wheel on the curb#within two feet of the curb#wherever there is a parking meter"},
{"testId":76,"qid":2614,"description":"How can you improve your visibility when approaching a blind intersection?","explanation":"Move into a lane position that allows you to be spotted as soon as possible at blind crossings to increase your visibility.","imageName":"","correctAnswer":34209,"isBookmarked":0,"aid":"10718#34209#34210#34211","text":"Flash your brake light#Move into a lane position that lets you be seen as soon as possible#Make eye contact with other vehicle operators#Wear extra clothing"},
{"testId":76,"qid":2615,"description":"Keeping eye contact with motorists:","explanation":"Drivers will not yield to you if you make eye contact. Even when they are gazing straight towards motorcycles, they often fail to detect them.","imageName":"","correctAnswer":23007,"isBookmarked":0,"aid":"7987#23005#23006#23007","text":"should be avoided to discourage aggression#ensures that they will stop and let you through#should be made before crossing any intersection#is not a guarantee that they will yield to you"},
{"testId":76,"qid":2616,"description":"You may share a lane safely with:","explanation":"You should not be sharing a lane with other cars. To function properly, a motorbike needs the whole width of the lane. Sharing a lane with another motorbike (pairing up) is not prohibited in Missouri, but it is still a terrible idea. To navigate, each motorbike may use the whole width of the lane.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30482#34212","text":"no one#other motorcycles only#motorcycles and small cars#any other vehicles"},
{"testId":76,"qid":2617,"description":"How much space should you leave between you and the bike in front of you?","explanation":"When following another motorbike, allow at least 2 seconds of distance, just as you would when following any other motor vehicle.","imageName":"","correctAnswer":7321,"isBookmarked":0,"aid":"426#428#7321#7322","text":"4 seconds#6 seconds#2 seconds#3 seconds"},
{"testId":76,"qid":2618,"description":"In tight, slow turn, you should:","explanation":"Stay upright when leaning the motorbike in gradual, tight corners.","imageName":"","correctAnswer":7994,"isBookmarked":0,"aid":"6760#7994#7995#10686","text":"lean with the motorcycle#remain upright while leaning the motorcycle#lean forward as much as possible#lean in the opposite direction of the motorcycle"},
{"testId":76,"qid":2620,"description":"The rear brake is operated by the:","explanation":"The right foot pedal is used to operate the rear brake.","imageName":"","correctAnswer":7117,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":77,"qid":2622,"description":"Motorcycle riders must approach junctions with caution. Since bikes :","explanation":"When approaching a junction, motorcycle riders must use extreme care. Motorcycles seem to be farther away than they are because they are smaller than vehicles. At junctions, automobile drivers making left turns against incoming motorbikes often overestimate their turn time.","imageName":"","correctAnswer":33218,"isBookmarked":0,"aid":"8006#8008#30811#33218","text":"appear closer than they are#are much faster than cars#travel much slower than cars#appear farther away than they are"},
{"testId":77,"qid":2627,"description":"Lane positioning helps you to _________.","explanation":"Choosing an appropriate lane position allows you to keep out of blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6842#8026#8028#10656","text":"avoid road hazards#avoid riding in blind spots#communicate what you plan to do next#Do all of the above"},
{"testId":77,"qid":2628,"description":"It is essential to keep a safe distance behind the vehicle in front of you:","explanation":"At all times, keep a distance cushion behind the car in front of you. Even if you are stopped, dangerous circumstances might arise to which you do not want to be too near.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7448#8029#33931","text":"At all times#In heavy traffic#Unless you are stopped#When you're riding through school zones"},
{"testId":429,"qid":2628,"description":"It is essential to keep a safe distance behind the vehicle in front of you:","explanation":"At all times, keep a distance cushion behind the car in front of you. Even if you are stopped, dangerous circumstances might arise to which you do not want to be too near.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7448#8029#33931","text":"At all times#In heavy traffic#Unless you are stopped#When you're riding through school zones"},
{"testId":77,"qid":2629,"description":"In which of the following scenarios is another vehicle most likely to try to share your lane?","explanation":"If another driver notices that you intend to turn, he or she can attempt to share your lane with you. By taking the middle lane position, you can stop this from occurring. It gives you the smallest amount of space on each side.","imageName":"","correctAnswer":34517,"isBookmarked":0,"aid":"24683#34517#34518#34519","text":"If you are riding in a group#If you are preparing to turn at an intersection#If you are preparing to pass#If you are riding at night"},
{"testId":77,"qid":2631,"description":"What is the most effective strategy to reduce your reaction time?","explanation":"Cover the clutch and the brakes to reduce response time in dangerous circumstances.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"24744#34520#34521#34522","text":"Cover the clutch and the brake#Hold the clutch in at all times#Hover your hand over the kill engine switch#Keep your toe on the rear brake pedal"},
{"testId":77,"qid":2632,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":77,"qid":2635,"description":"The quickest method to come to a halt is to:","explanation":"To come to a complete stop, use both the front and back brakes firmly without locking any wheel.","imageName":"","correctAnswer":31164,"isBookmarked":0,"aid":"6244#7326#8049#31164","text":"use the rear brake only#Pull the kill engine switch#apply the front brake quickly#apply both the front and rear brakes firmly"},
{"testId":77,"qid":2638,"description":"The pavement is most likely to be slick:","explanation":"The pavement is most likely to be slick shortly after it begins to rain. Rainwater dissolves oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"6646#7659#8060#8430","text":"in parking lots#during rush hour#in rural areas#soon after it has started to rain"},
{"testId":77,"qid":2639,"description":"When passing another vehicle on a highway that goes in your direction and has three or more lanes, you must:","explanation":"Normally, you must not exceed the speed limit while passing another vehicle. However, there is one exemption in Montana: overtaking another car on a two-lane road. In such case, you may exceed the speed limit by up to 10 mph in order to overtake the car and safely return to the right lane.","imageName":"","correctAnswer":34523,"isBookmarked":0,"aid":"8064#23008#34523#34524","text":"ride in the highest gear#ride close to the edge of the road#not exceed the speed limit#turn on your high-beam headlight"},
{"testId":77,"qid":2643,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":412,"qid":2643,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":78,"qid":2646,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever. The clutch is controlled by the lever on the left. The rear brake is controlled by the right foot pedal, while the gear shifter is controlled by the left foot lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":78,"qid":2647,"description":"When is it safe to share a lane with another vehicle?","explanation":"Never drive in the same lane as another car. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#8086#8087","text":"Never#In heavy traffic#When passing another vehicle#In designated areas"},
{"testId":78,"qid":2648,"description":"Before each ride, which of the following should be checked?","explanation":"To ensure safety, check the tires, clutch, throttle, and brakes before each ride.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#8089#8090","text":"All of the above#Tires#Clutch and throttle#Brakes"},
{"testId":78,"qid":2650,"description":"How can you maximize straight-line braking?","explanation":"By completely using both brakes without locking each wheel, you may obtain maximum straight-line braking.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"6306#22930#24897#30525","text":"Use the rear brake only#Apply the front brake quickly#Alternate between the front and rear brakes#Fully apply both the front and rear brakes"},
{"testId":78,"qid":2654,"description":"While riding, you see hazards on the left side of the lane. What lane position should you take?","explanation":"If there are risks to your left, pick a center or right lane position to avoid them.","imageName":"","correctAnswer":7097,"isBookmarked":0,"aid":"6214#7097#24809#28403","text":"Left#Center or right#Whichever lane position you are currently in#Whichever lane position you usually prefer"},
{"testId":78,"qid":2656,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in the blind areas of other cars by avoiding riding alongside such vehicles.","imageName":"","correctAnswer":34792,"isBookmarked":0,"aid":"8111#28948#34791#34792","text":"Never pass other vehicles#Use your high-beam headlight#Avoid riding on multilane roads#Don't ride alongside other vehicles"},
{"testId":78,"qid":2657,"description":"Intersections are particularly hazardous to motorcycle riders because:","explanation":"Bikes are particularly vulnerable at intersections because other drivers often fail to see motorcycles coming. A vehicle driver may fail to see an approaching motorbike and swerve into its path.","imageName":"","correctAnswer":34795,"isBookmarked":0,"aid":"22811#34793#34794#34795","text":"motorcycles are slower than other vehicles#motorcycles are not allowed to cross intersections#motorcyclists don't have to obey traffic lights#other vehicle operators often do not see motorcycles approaching"},
{"testId":78,"qid":2658,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop behind the stop line first. After halting, you may go ahead until you can see better.","imageName":"","correctAnswer":8117,"isBookmarked":0,"aid":"8117#8118#8119#8120","text":"behind the stop line#where you can see both ways#with your rear tire on the stop line#straddling the stop line"},
{"testId":78,"qid":2660,"description":"What can you do to improve your chances of identifying hazards at night?","explanation":"Use your high beam and avoid wearing a tinted face shield at night to see better. If the taillights of the vehicle in front of you seem to be bouncing up and down, the vehicle is most likely riding over bumps.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"8123#22794#22882#23000","text":"Use your high beam#Watch the taillights of the car ahead of you#Avoid wearing a tinted face shield#Do all of the above"},
{"testId":78,"qid":2661,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"8126#8127#8128#30834","text":"locking the rear wheel#riding too fast#leaning while turning#locking the front wheel"},
{"testId":78,"qid":2662,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum route across a curve may not be the curve itself. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":28223,"isBookmarked":0,"aid":"28223#28224#34796#34797","text":"is different for each curve#is close to the edge of the road#is close to the center line#is the center of the road"},
{"testId":78,"qid":2664,"description":"The most appropriate method of navigating wet roads is to:","explanation":"Following the tire tracks of other cars is the safest method to negotiate wet roads. These are less prone to slipping.","imageName":"","correctAnswer":34798,"isBookmarked":0,"aid":"7707#8140#34798#34799","text":"decrease your following distance#stay close to lane markings#follow the tire tracks of other vehicles#ride at the edge of the road"},
{"testId":78,"qid":2665,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____-degree angle.","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"6354#6355#6425#8141","text":"45#90#25#60"},
{"testId":78,"qid":2667,"description":"The most effective technique to cope with a wobble is to:","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6432#8147#29078#34801","text":"Close the throttle gradually#slow down using engine braking#Brake hard#accelerate out of it"},
{"testId":78,"qid":2668,"description":"Which arrangement is the most secure when traveling in a group?","explanation":"When traveling in a group, the staggered arrangement is the safest. It keeps the group close together while providing some breathing room.","imageName":"","correctAnswer":6262,"isBookmarked":0,"aid":"6262#6263#6264#6265","text":"The staggered formation#Single file#In pairs#A V-formation"},
{"testId":78,"qid":2669,"description":"When getting off the road, you must check:","explanation":"When getting off the road, you should check to determine whether the surface is suitable for bikes. Motorcycles need a sturdy, traction-rich terrain.","imageName":"","correctAnswer":34803,"isBookmarked":0,"aid":"8152#10857#34802#34803","text":"if there is room to pull off the road#If it is a designated pull-off area#if you are being tailgated#if the surface is safe enough to ride on"},
{"testId":78,"qid":2670,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only get on a motorbike once the rider has started and mounted it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#8153#8154#24934","text":"Before the engine has been started#After the engine has been started#While the motorcycle is on the kickstand#Before the motorcyclist"},
{"testId":293,"qid":2670,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only get on a motorbike once the rider has started and mounted it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#8153#8154#24934","text":"Before the engine has been started#After the engine has been started#While the motorcycle is on the kickstand#Before the motorcyclist"},
{"testId":301,"qid":2670,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only get on a motorbike once the rider has started and mounted it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#8153#8154#24934","text":"Before the engine has been started#After the engine has been started#While the motorcycle is on the kickstand#Before the motorcyclist"},
{"testId":79,"qid":2671,"description":"According to Nevada law, which of the following is true for the Motorcyclists?","explanation":"Motorcycles may travel two abreast in the same lane in Nevada. It is, however, advised that you ride in a staggered configuration. To navigate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":35061,"isBookmarked":0,"aid":"8157#35059#35060#35061","text":"Must ride only during the day#Must ride single file#Are not allowed to travel on main highways#Are allowed to pair up"},
{"testId":79,"qid":2672,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#8160","text":"The front brake#The rear brake#The clutch#The gear shift"},
{"testId":79,"qid":2673,"description":"When should your mirrors be adjusted?","explanation":"After mounting the motorbike but before starting it, adjust your mirrors.","imageName":"","correctAnswer":7516,"isBookmarked":0,"aid":"7516#8161#10851#30513","text":"After mounting the motorcycle#Before mounting the motorcycle#After you have started riding#After starting the engine"},
{"testId":79,"qid":2677,"description":"Taking a curve too rapidly could force you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":35062,"isBookmarked":0,"aid":"8173#8175#8176#35062","text":"stall the motorcycle#lose your balance#wobble#veer into another lane of traffic"},
{"testId":79,"qid":2679,"description":"Maintain a following distance of at least _____ seconds for an enought space cushion.","explanation":"Maintain a following distance of at least two seconds between you and the car ahead of you for appropriate space cushion. This applies whether the vehicle in front of you is a car or another motorbike. Increase your following distance while biking in difficult circumstances.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":79,"qid":2680,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, attempt to get in front of the tailgater. (You're safer with the tailgater ahead of you than behind you.) Change lanes, slow down, and let the tailgater in front of you to pass. Don't attempt to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":35063,"isBookmarked":0,"aid":"6286#6392#6515#35063","text":"Flash your brake light#Call the police#Increase your speed#get the tailgater in front of you"},
{"testId":79,"qid":2681,"description":"Which lane position should you ride in when another vehicle passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6763#7097","text":"Left#Center#Left or right#Center or right"},
{"testId":79,"qid":2682,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":22778,"isBookmarked":0,"aid":"8003#22778#35064#35065","text":"Search, examine, execute#Search, evaluate, execute#Signal, execute, evaluate#Signal, examine, evaluate"},
{"testId":79,"qid":2683,"description":"Half of all motorcycle accidents are the result of:","explanation":"Drivers entering the rider's right-of-way cause half of all motorcycle accidents. When approaching a junction, proceed with care.","imageName":"","correctAnswer":8186,"isBookmarked":0,"aid":"8186#8187#8188#10721","text":"drivers entering the rider's right-of-way#faulty traffic signals#not wearing proper gear#riding while under the influence"},
{"testId":79,"qid":2684,"description":"If you are __________, you may need to adjust your lane location.","explanation":"When approaching a blind junction, ride in the lane that allows you to be noticed the quickest.","imageName":"","correctAnswer":8190,"isBookmarked":0,"aid":"8190#8191#8192#35066","text":"approaching a blind intersection#riding on a multilane road#riding in a residential area#being tailgated"},
{"testId":79,"qid":2685,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":35067,"isBookmarked":0,"aid":"8193#8194#8196#35067","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Turn the headlight on"},
{"testId":79,"qid":2687,"description":"Head checks are________.","explanation":"Checking your mirrors isn't enough. Motorcycles, like vehicles, have blind areas. To examine your blind spots, you must do head checks.","imageName":"","correctAnswer":8201,"isBookmarked":0,"aid":"8201#35068#35069#35070","text":"necessary to check your blind spots#only necessary for car drivers#only done in heavy traffic#only done by passengers"},
{"testId":79,"qid":2688,"description":"The front brake is more crucial because it:","explanation":null,"imageName":"","correctAnswer":8205,"isBookmarked":0,"aid":"8205#8206#8207#8208","text":"has the most stopping power#is used most often#is the only brake#has the least stopping power"},
{"testId":79,"qid":2690,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. You should turn toward the outside of the curve after the curve's midpoint. Your path's effective radius will grow as a result.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"8213#8214#10833#10834","text":"maintain a right lane position#proceed with caution#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":79,"qid":2691,"description":"The pavement is most likely to be slick:","explanation":"When it begins to rain, the pavement might become slick. Rainwater dissolves oil and other fluids that have accumulated in the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8059,"isBookmarked":0,"aid":"6351#8059#35071#35072","text":"when it is hot from the sun#right after it has started to rain#after a long period of rain#where other vehicles have been traveling"},
{"testId":79,"qid":2692,"description":"Weaving while traversing a metal grate:","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#8221#24711","text":"is not hazardous#is dangerous to motorcycle riders#is harmful to the motorcycle#is caused by poor motorcycle skills"},
{"testId":79,"qid":2693,"description":"What should you do if your throttle is stuck and turning it back and forth doesn't work?","explanation":"If your throttle is stuck and twisting it back and forth isn't working, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":7326,"isBookmarked":0,"aid":"7326#7978#8222#8223","text":"Pull the kill engine switch#Use engine braking#Shift into a higher gear#Jump off the motorcycle"},
{"testId":80,"qid":2699,"description":"Which of the following impacts your motorcycle riding abilities?","explanation":"Prescription pharmaceuticals, over-the-counter medications, illicit substances, and, of course, alcohol may all impair your riding skills. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":80,"qid":2700,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"When you have a passenger on board, your motorbike will react more slowly. As a result, you should ride more slowly, particularly when approaching bumps, bends, or curves.","imageName":"","correctAnswer":29479,"isBookmarked":0,"aid":"24799#29479#29480#36077","text":"Ride in the center lane position#Ride slower#Ride faster#Ride as usual"},
{"testId":80,"qid":2702,"description":"Riding across lanes of traffic is:","explanation":"A motorbike need the whole width of the lane to move safely. Riding between traffic lanes (also known as lane splitting) is currently only permitted in California.","imageName":"","correctAnswer":7652,"isBookmarked":0,"aid":"7652#8250#29395#36078","text":"prohibited at all times#permitted only in designated areas#permitted in heavy traffic#prohibited only when riding in a group"},
{"testId":80,"qid":2703,"description":"If a vehicle comes into your path, you should:","explanation":"If a car comes into your path, go as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":80,"qid":2704,"description":"Head checks should be performed for:","explanation":"Motorcycles, like vehicles, have blind areas. Perform a head check to assess your blind areas before executing moves like as changing lanes. Frequent head checks should be included into your normal road-scanning practice.","imageName":"","correctAnswer":8254,"isBookmarked":0,"aid":"6337#8254#36079#36080","text":"Making turns#checking blind spots#checking the gauges#shifting gears"},
{"testId":80,"qid":2705,"description":"When starting your motorcycle uphill, use _______ to hold the bike while you start the engine and change into first gear.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your motorbike stable while you use your right hand to control the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6146,"isBookmarked":0,"aid":"6146#6147#34533#35310","text":"the front brake#the rear brake#neither the front nor the rear brake#either the front or the rear brake"},
{"testId":80,"qid":2707,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6309#24831#36081","text":"Swerving from side to side#rising slightly off the seat#applying the brakes lightly#taking your feet off the footpegs"},
{"testId":80,"qid":2708,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footpegs. This aids in maintaining equilibrium. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":32359,"isBookmarked":0,"aid":"6173#6174#6176#32359","text":"with your toes pointed downward#to drag lightly on the ground#as far forward as possible#firmly on the footpegs"},
{"testId":299,"qid":2708,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footpegs. This aids in maintaining equilibrium. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":32359,"isBookmarked":0,"aid":"6173#6174#6176#32359","text":"with your toes pointed downward#to drag lightly on the ground#as far forward as possible#firmly on the footpegs"},
{"testId":80,"qid":2709,"description":"Maintain a following distance of at least ________ behind the vehicle ahead of you to ensure ample room in front of you.","explanation":"Maintain a following distance of at least two seconds behind the car ahead of you to ensure ample room in front of you. Increase your following distance while riding in difficult weather or when your motorbike may take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1287#1288#4096#4097","text":"two seconds#four seconds#three seconds#five seconds"},
{"testId":80,"qid":2710,"description":"Why should you ride in the left lane when passing parked cars?","explanation":"Ride in the left lane while passing parked automobiles to prevent difficulties caused by car doors opening into your direction of travel, drivers getting out of cars, or individuals stepping from between cars.","imageName":"","correctAnswer":6226,"isBookmarked":0,"aid":"6226#22790#22791#35084","text":"You may avoid problems from doors opening#You are more likely to hit a car in the right lane position#The center lane position is too slippery#You should always ride in the left lane position"},
{"testId":446,"qid":2710,"description":"Why should you ride in the left lane when passing parked cars?","explanation":"Ride in the left lane while passing parked automobiles to prevent difficulties caused by car doors opening into your direction of travel, drivers getting out of cars, or individuals stepping from between cars.","imageName":"","correctAnswer":6226,"isBookmarked":0,"aid":"6226#22790#22791#35084","text":"You may avoid problems from doors opening#You are more likely to hit a car in the right lane position#The center lane position is too slippery#You should always ride in the left lane position"},
{"testId":80,"qid":2712,"description":"How many motorcycle lane slots are there in each lane of traffic?","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":80,"qid":2713,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, attempt to get in front of the tailgater. (You're safer with the tailgater ahead of you than behind you.) Change lanes, slow down, and let the tailgater in front of you to pass. Don't attempt to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":33608,"isBookmarked":0,"aid":"6278#6392#6515#33608","text":"Beep your horn#Call the police#Increase your speed#let the tailgater pass you"},
{"testId":80,"qid":2714,"description":"When transporting cargo, it is critical to:","explanation":"Keep the load low while transporting goods to maintain a low center of gravity. A wobble may be caused by a high center of gravity.","imageName":"","correctAnswer":6576,"isBookmarked":0,"aid":"6576#36082#36083#36084","text":"keep the load low#weigh it first#only secure it to the sides of your motorcycle#not carry a passenger"},
{"testId":80,"qid":2715,"description":"If your throttle becomes stuck and you are unable to dislodge it, you should:","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If this fails, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":36085,"isBookmarked":0,"aid":"6423#6569#6582#36085","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#flip the engine cutoff switch and pull in the clutch"},
{"testId":80,"qid":2716,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"The road's edge is problematic for motorcyclists because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#35511#36086","text":"dirt and gravel provide little traction#it is not driven on often#visibility is poor#it is difficult to make turns"},
{"testId":80,"qid":2717,"description":"You should _____ when passing another vehicle.","explanation":"You should go fast across the driver's blind area while passing another car.","imageName":"","correctAnswer":24596,"isBookmarked":0,"aid":"6629#6632#24596#36087","text":"move as far left as possible#make eye contact with the driver#move quickly through the driver's blind spot#start the pass from the center lane position"},
{"testId":80,"qid":2718,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Passing slower cars does not boost your nighttime safety. It is risky and should be approached with care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6665#6666#10470#10804","text":"Decreasing your speed#Changing your lane position#Passing slower vehicles#Wearing reflective clothing"},
{"testId":450,"qid":2718,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Passing slower cars does not boost your nighttime safety. It is risky and should be approached with care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6665#6666#10470#10804","text":"Decreasing your speed#Changing your lane position#Passing slower vehicles#Wearing reflective clothing"},
{"testId":80,"qid":2719,"description":"Handlebars that reach above your shoulders are:","explanation":"Handlebars in New Jersey must be no higher than the rider's shoulders. Some nations argue that any higher handlegrips are dangerous.","imageName":"","correctAnswer":8272,"isBookmarked":0,"aid":"8269#8271#8272#36088","text":"required in New Jersey#required on some motorcycles#illegal in New Jersey#your personal choice"},
{"testId":80,"qid":2720,"description":"When should you adjust the side mirrors on your motorcycle?","explanation":"Before each ride, clean and adjust the side mirrors. It can be difficult and dangerous to adjust them while you're riding.","imageName":"","correctAnswer":36089,"isBookmarked":0,"aid":"9435#24787#36089#36090","text":"At any time#While you are riding#Before you start to ride#At the first stop after you've started to ride"},
{"testId":81,"qid":2723,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If braking and swerving are both required to avoid colliding with an object, you should brake either before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30047,"isBookmarked":0,"aid":"30044#30045#30046#30047","text":"Brake before swerving, never after swerving#Brake after swerving, never before swerving#Brake while swerving#Brake before or after swerving"},
{"testId":81,"qid":2724,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you're riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6746#38442","text":"the clutch#the throttle#the side mirrors#your foot pegs"},
{"testId":81,"qid":2731,"description":"To aid in balancing your turns, you should:","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns. This allows you and the motorbike to travel in tandem.","imageName":"","correctAnswer":6901,"isBookmarked":0,"aid":"6900#6901#6902#10686","text":"take your feet off the foot pegs for balance#Keep your knees against the gas tank#turn your torso in the direction of the turn#lean in the opposite direction of the motorcycle"},
{"testId":81,"qid":2732,"description":"Which lane position should be always avoided?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"6912#17889#38443#38444","text":"The left, because of oncoming traffic#None#The right, because of poor traction near the edge of the road#The center, because of the buildup of oil and other fluids"},
{"testId":81,"qid":2733,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":483,"qid":2733,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":81,"qid":2734,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for an accident since many individuals are attempting to perform many things at once.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"638#30816#38445#38446","text":"A school zone#An intersection#A residential area#A multilane highway"},
{"testId":81,"qid":2735,"description":"What is the proper way for a group of motorcycles to pass a vehicle?","explanation":"Motorcyclists should pass in single file. Each motorcycle may need the whole width of the lane to navigate.","imageName":"","correctAnswer":6263,"isBookmarked":0,"aid":"6263#6264#6948#38447","text":"Single file#In pairs#As many at once as is safe#In a staggered formation"},
{"testId":81,"qid":2736,"description":"To safely stop on a curve, you should:","explanation":"Straighten your motorbike as much as possible to stop safely on a curve. Stopping while leaning might be risky.","imageName":"","correctAnswer":31161,"isBookmarked":0,"aid":"6900#6901#6990#31161","text":"take your feet off the foot pegs for balance#Keep your knees against the gas tank#lean the opposite way of the motorcycle#straighten your motorcycle upright"},
{"testId":81,"qid":2739,"description":"Which of the following surfaces has less traction than the others?","explanation":"The following are examples of traction-deficient surfaces: 1) wet pavement, especially just after it begins to rain and before surface oil washes to the side of the road; 2) gravel roads or areas where sand and gravel gather; 3) mud, leaves, snow, and ice; and 4) wet lane markers, steel plates, and manhole covers.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":81,"qid":2740,"description":"When parking against a curb, you should park at an angle of :","explanation":"When parking against the curb, do a 90-degree turn with the back wheel hitting the curb.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#22892","text":"45-degree#90-degree#25-degree#130-degree"},
{"testId":81,"qid":2742,"description":"Which of the following is a risk of passing a line of parked cars?","explanation":"People may not see you if you are traveling through a line of parked automobiles. Someone may pull out in front of you, open a door into your path, or walk out from between automobiles. Select an appropriate lane position to preserve your distance from the parked automobiles.","imageName":"","correctAnswer":33925,"isBookmarked":0,"aid":"30505#30506#32688#33925","text":"A car might pull out in front of you#A door might be opened into your path#People might step out from between cars#All of the above are hazards"},
{"testId":81,"qid":2744,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6327#6328#6329#6330","text":"at the end of the group#behind the leader#at the front of the group#in the middle of the group"},
{"testId":81,"qid":2745,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7289#8300#10572#38448","text":"take frequent rest breaks#Drink lots of fluids#Ride only during the day#avoid wearing a face shield"},
{"testId":82,"qid":2748,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":82,"qid":2749,"description":"What is the best way to come to a complete stop?","explanation":"The easiest approach to come to a complete stop is to use both the front and back brakes without locking any wheel. This is the most efficient form of braking.","imageName":"","correctAnswer":28950,"isBookmarked":0,"aid":"10838#28950#38694#38695","text":"Apply the rear brake only#Apply both the front and rear brakes#Apply the front brake only#Operate the engine cut-off switch"},
{"testId":291,"qid":2749,"description":"What is the best way to come to a complete stop?","explanation":"The easiest approach to come to a complete stop is to use both the front and back brakes without locking any wheel. This is the most efficient form of braking.","imageName":"","correctAnswer":28950,"isBookmarked":0,"aid":"10838#28950#38694#38695","text":"Apply the rear brake only#Apply both the front and rear brakes#Apply the front brake only#Operate the engine cut-off switch"},
{"testId":82,"qid":2750,"description":"Who is required to wear a helmet while riding a motorcycle in North Carolina?","explanation":"In most crashes, a motorcycle rider or passenger may avoid severe head and neck injuries by wearing an appropriate helmet. North Carolina law mandates that everyone riding a motorbike, including the rider and any passengers, wear a helmet.","imageName":"","correctAnswer":38698,"isBookmarked":0,"aid":"38696#38697#38698#38699","text":"Motorcyclists#Passengers under 21#Motorcyclists and all passengers#Motorcyclists and passengers under 21"},
{"testId":82,"qid":2752,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware of your presence?","explanation":"Ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"22921#26553#26554#30013","text":"Ride in the center lane position#Increase your following distance to 5 seconds#Try to pass the vehicle as soon as possible#Ride in the left lane position"},
{"testId":82,"qid":2753,"description":"What can you do to increase your safety when riding at night?","explanation":"You may boost your safety when riding at night by utilizing your high beam, slowing down, increasing your following distance, and using the lights of the vehicle ahead of you to help you see.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6239#8123#10579#23000","text":"Increase your following distance#Use your high beam#Decrease your speed#Do all of the above"},
{"testId":82,"qid":2757,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, carefully shut the throttle to slow down. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of the wobble will just make the motorbike more unstable.","imageName":"","correctAnswer":38701,"isBookmarked":0,"aid":"38695#38700#38701#38702","text":"Operate the engine cut-off switch#Accelerate out of the wobble#Close the throttle gradually#Stop by applying only the rear brake"},
{"testId":82,"qid":2758,"description":"When parking against a curb, you should park at an angle of :","explanation":"When parking against a curb, park at a 90-degree angle with the back tire contacting the curb.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10677#10683","text":"45-degree#20-degree#90-degree#180-degree"},
{"testId":82,"qid":2759,"description":"The most effective defense against flying items when riding is:","explanation":"A shatter-resistant face shield is the greatest protection from flying objects when cycling. It has the most surface area and is unlikely to fly away in the wind. It will shield your whole face from flying objects, rather than just your eyes.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7584#8336#10355","text":"A windshield#sunglasses#goggles#A face shield"},
{"testId":83,"qid":2759,"description":"The most effective defense against flying items when riding is:","explanation":"A shatter-resistant face shield is the greatest protection from flying objects when cycling. It has the most surface area and is unlikely to fly away in the wind. It will shield your whole face from flying objects, rather than just your eyes.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7584#8336#10355","text":"A windshield#sunglasses#goggles#A face shield"},
{"testId":82,"qid":2761,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":7054,"isBookmarked":0,"aid":"6467#7054#8316#9435","text":"During the turn#Before entering the turn#After entering the turn#At any time"},
{"testId":446,"qid":2761,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":7054,"isBookmarked":0,"aid":"6467#7054#8316#9435","text":"During the turn#Before entering the turn#After entering the turn#At any time"},
{"testId":82,"qid":2763,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#6237","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#Using signal lights"},
{"testId":82,"qid":2764,"description":"Is sharing a lane with another motorbike safe?","explanation":"Motorcycles may lawfully go two abreast (pair up) in a single lane in North Carolina. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":442,"qid":2764,"description":"Is sharing a lane with another motorbike safe?","explanation":"Motorcycles may lawfully go two abreast (pair up) in a single lane in North Carolina. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":82,"qid":2765,"description":"While driving, head checks:","explanation":"Motorcycles, like vehicles, have blind areas. Perform a head check to assess your blind zones before changing lanes. Frequent head checks should be included into your normal road-scanning practice.","imageName":"","correctAnswer":24798,"isBookmarked":0,"aid":"6339#24798#35085#35086","text":"only need to be done at night#should be performed before you change lanes#are not needed; motorcycles don't have blind spots#are only needed when you're preparing to turn left"},
{"testId":82,"qid":2767,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":82,"qid":2770,"description":"Which of the following surfaces has less traction than the others?","explanation":"Motorcycles have low traction on gravel roads, which might contain loose stones and mud.","imageName":"","correctAnswer":6717,"isBookmarked":0,"aid":"6717#6720#38703#38704","text":"Gravel roads#Warm pavement#The tire tracks of other vehicles#Roadway lanes that aren't often used"},
{"testId":83,"qid":2772,"description":"If you can't cross an obstacle straight on, cross at an angle of at least ____ degrees.","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#6477","text":"20#45#90#30"},
{"testId":83,"qid":2777,"description":"What should you do to safely stop in a curve?","explanation":"When coming to a halt on a curve, attempt to straighten the motorbike out. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6196#10884#26482#30098","text":"Never stop in a curve#Use your rear brake only#Use your lean to enhance braking#Try to straighten your motorcycle upright"},
{"testId":83,"qid":2778,"description":"The pavement is most likely to be slick:","explanation":"When it begins to rain, the pavement might become slick. Rainwater dissolves oil and other fluids that have accumulated in the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":6352,"isBookmarked":0,"aid":"6350#6351#6352#35072","text":"at night#when it is hot from the sun#just after it starts to rain#where other vehicles have been traveling"},
{"testId":294,"qid":2778,"description":"The pavement is most likely to be slick:","explanation":"When it begins to rain, the pavement might become slick. Rainwater dissolves oil and other fluids that have accumulated in the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":6352,"isBookmarked":0,"aid":"6350#6351#6352#35072","text":"at night#when it is hot from the sun#just after it starts to rain#where other vehicles have been traveling"},
{"testId":83,"qid":2779,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"The time of day has no effect on your alcohol concentration (AC). At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":83,"qid":2785,"description":"Increase your nighttime safety by:","explanation":"Use your high beams, slow down, increase your following distance, and rely on the lights of the vehicle in front of you to help you see to be safe at night.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6416#6800#10777#10814","text":"increasing your following distance#using your high beam#Doing all of the above#using the lights of the car ahead of you to help you see"},
{"testId":83,"qid":2786,"description":"You should leave at least ___ seconds between you and the vehicle in front of you.","explanation":"Whether you're driving a car or riding a motorbike, the North Dakota Department of Transportation suggests leaving a following distance of at least three seconds behind the vehicle in front of you.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":83,"qid":2791,"description":"Rolling the throttle through a turn will:","explanation":"Through a turn, rolling on the throttle will assist steady the motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#8362","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help the motorcycle slow down"},
{"testId":83,"qid":2792,"description":"What is the most secure manner to carry saddlebags?","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":8363,"isBookmarked":0,"aid":"8363#8364#38889#38890","text":"Distribute the weight evenly between them#Make sure all saddlebags are full#Use only one saddlebag at a time#Don't carry a passenger at the same time"},
{"testId":83,"qid":2793,"description":"Other drivers pose a hazard when ______.","explanation":"A motorist in a vehicle you are passing may fail to see you and open a door directly into your path.","imageName":"","correctAnswer":24735,"isBookmarked":0,"aid":"8369#23070#23071#24735","text":"you are making a right turn#you are parking your motorcycle#you are riding through a school zone#You are passing a line of parked cars"},
{"testId":83,"qid":2794,"description":"_____ are two strategies to avoid obstructions in your path.","explanation":"You may bypass a roadblock by either stopping or swerving. Learn which option is superior in different scenarios.","imageName":"","correctAnswer":31463,"isBookmarked":0,"aid":"31463#31464#38891#38892","text":"Stopping and swerving#Stopping and sliding#Swerving and sliding#Swerving and leaning"},
{"testId":84,"qid":2804,"description":"You should maintain a _____ second following distance behind the vehicle in front of you.","explanation":"By leaving at least two seconds between yourself and the car in front of you, you allow yourself enough time to stop or swerve if the vehicle in front of you abruptly stops.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":84,"qid":2805,"description":"What do you press in the \"press\" step of turning?","explanation":"The \"push\" part of turning involves pressing the handgrip on the side of the direction you desire to turn. The motorbike will tilt in that way as a result.","imageName":"","correctAnswer":33597,"isBookmarked":0,"aid":"8404#23051#31459#33597","text":"The front or rear brake#The clutch lever#Your feet on the footrests#The handgrip on the side of the direction in which you want to turn"},
{"testId":84,"qid":2808,"description":"If someone is following you too closely, you must:","explanation":"If you are being tailgated, switch lanes and let the tailgater to pass. Don't try to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":39118,"isBookmarked":0,"aid":"6286#6475#39117#39118","text":"Flash your brake light#Speed up#ignore the tailgater#change lanes and let the tailgater pass"},
{"testId":84,"qid":2809,"description":"To prevent another motorist from attempting to share your lane, you should:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":7861,"isBookmarked":0,"aid":"7482#7861#8410#8411","text":"swerve from side to side#maintain a center lane position#always ride with a partner#maintain a left lane position"},
{"testId":84,"qid":2810,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"You should always travel with your headlamp on to increase visibility to other road users. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started. If your motorbike was manufactured before to 1978, you must remember to switch on the headlight manually.","imageName":"","correctAnswer":39122,"isBookmarked":0,"aid":"39119#39120#39121#39122","text":"You need a special license#You can only ride during the day#You must have extra protective gear#You must turn on the headlight whenever you ride"},
{"testId":84,"qid":2812,"description":"While driving, head checks:","explanation":"Motorcycles, like vehicles, have blind areas. Perform a head check to assess your blind zones before changing lanes. Frequent head checks should be included into your normal road-scanning practice.","imageName":"","correctAnswer":39123,"isBookmarked":0,"aid":"6339#39123#39124#39125","text":"only need to be done at night#are necessary to check your blind spots#are not needed#are useful when you're turning right"},
{"testId":84,"qid":2813,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Brightly colored and luminous apparel is ideal for being spotted. Durable leather apparel is protective, but it does not always boost visibility.","imageName":"","correctAnswer":8421,"isBookmarked":0,"aid":"8420#8421#8422#8423","text":"leather and durable#brightly colored#government approved#loose and comfortable"},
{"testId":84,"qid":2815,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":39126,"isBookmarked":0,"aid":"22821#22927#39126#39127","text":"Apply only the front brake#Pull in the clutch#Apply both the front and rear brakes without locking the wheels#Apply both the front and rear brakes until the wheels lock"},
{"testId":84,"qid":2817,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":22260,"isBookmarked":0,"aid":"7326#22259#22260#28935","text":"Pull the kill engine switch#use only the front brake#use only the rear brake#use both the front and rear brakes"},
{"testId":84,"qid":2818,"description":"If your throttle becomes stuck and you are unable to dislodge it, you should:","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":10903,"isBookmarked":0,"aid":"6417#7978#10903#31486","text":"Use the front brake only#Use engine braking#Pull the engine cut-off switch#Apply both the front and rear brakes"},
{"testId":84,"qid":2819,"description":"A passenger under the age of 18 who rides on your motorcycle must have his or her own:","explanation":"Passengers on your motorbike must have their own footrests and seat. If the passenger is under the age of 18, he or she must also wear a helmet, according to Ohio law.","imageName":"","correctAnswer":10854,"isBookmarked":0,"aid":"8333#8435#8436#10854","text":"helmet#footrests#seat#footrests, seat, and helmet"},
{"testId":85,"qid":2821,"description":"Motorcyclists should ride _____, when riding in a group.","explanation":"Motorcyclists should normally ride in a staggered arrangement while riding in a group. It keeps the group close while also providing some breathing room.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":85,"qid":2835,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6846#6996#33955#33957","text":"changing lanes#being seen by the driver ahead of you#preparing to pass the vehicle ahead of you#preparing to pull off the road"},
{"testId":85,"qid":2839,"description":"Crossing an obstacle should be done at an angle of at least ____ degrees.","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"6354#6425#6802#12455","text":"45#25#180#35"},
{"testId":3,"qid":2849,"description":"In severe rain, your vehicle's tires might lose touch with the road and ride on a layer of water. This threat is also known as:","explanation":"On a wet road, contemporary tires will normally channel water away to keep contact with the road at speeds of up to 35 mph. However, at greater speeds in deep water, the tire's channeling action becomes less efficient, and the tires begin to ride on a layer of water like water skis. This is known as \"hydroplaning.\" Hydroplaning may cause entire loss of braking and steering control at speeds of 50 mph or higher. To avoid hydroplaning, make sure your tires have adequate tread, that they are properly inflated, and that you drive slowly. In rainy conditions, always increase your following distance.","imageName":"202003201654212060.jpg","correctAnswer":8583,"isBookmarked":0,"aid":"8583#9002#9003#9004","text":"Hydroplaning#rainplaning#waterplaning#frictionplaning"},
{"testId":8,"qid":2853,"description":"When you meet a truck coming from the other way, stay as far to the side as you can to avoid :","explanation":"When meeting a truck approaching from the other way, remain as far to the side as possible to prevent a sideswipe accident and to decrease wind turbulence between the two cars.","imageName":"","correctAnswer":25935,"isBookmarked":0,"aid":"25933#25934#25935#25936","text":"a head-on collision#a rear-end collision#a sideswipe accident#a direct hit"},
{"testId":27,"qid":2869,"description":"If you must park on the road, park:","explanation":"If at all feasible, always park in an allocated spot. If parking on a road is necessary, position your car as far from the flow of traffic as you can. Park as close to the curb as you can if there is one.","imageName":"202006141720465598.jpg","correctAnswer":27104,"isBookmarked":0,"aid":"26233#27103#27104#27105","text":"as close to the center of the road as possible#as far away from the curb as possible#as far away from traffic as possible#as far away from the yellow line as possible"},
{"testId":38,"qid":2875,"description":"What is the meaning of this sign?","explanation":"The arrow's form indicates that you're about to enter a winding route. There are at least three turns on a winding road. Slow down and take your time.","imageName":"202002061310151663.jpg","correctAnswer":37410,"isBookmarked":0,"aid":"28122#37408#37409#37410","text":"There is a sharp turn ahead#The road ahead curves to the right, then to the left#The road ahead curves to the left, then to the right#You are entering a winding road"},
{"testId":31,"qid":2878,"description":"When approaching a highway from an entry ramp, you must cede the right of way to:","explanation":"When approaching a highway via an entry ramp, you must give way to cars already on the highway before merging into the correct lane.","imageName":"202007151827308939.jpg","correctAnswer":35873,"isBookmarked":0,"aid":"28258#28259#35872#35873","text":"other vehicles on the entrance ramp#no one#heavy trucks on the entrance ramp#vehicles already traveling on the highway"},
{"testId":37,"qid":2879,"description":"This symbol denotes:","explanation":"This sign indicates a side road ahead. Keep an eye out for automobiles approaching from that way.","imageName":"202002101131224927.jpg","correctAnswer":34720,"isBookmarked":0,"aid":"8663#34720#34721#34722","text":"a railroad crossing ahead#a side road ahead#a three-way stop ahead#an overpass ahead"},
{"testId":11,"qid":2900,"description":"When making a left turn at an intersection, alley, or driveway, you must surrender the right-of-way to :","explanation":"When turning left at a crossroads, alley, or driveway, give way to any vehicles approaching from the other direction.","imageName":"202001241717155576.jpg","correctAnswer":8508,"isBookmarked":0,"aid":"8508#8509#8510#8511","text":"all traffic coming from the opposite direction#all traffic coming from the same direction#all traffic to the right of your vehicle#all traffic behind your vehicle"},
{"testId":11,"qid":2901,"description":"What do double solid yellow lines indicate in four or more lanes with opposing traffic?","explanation":"Two solid yellow lines mark the center of a two-way road with four or more lanes. On both sides of these lines, passing is normally prohibited. Only enter or exit an alley, a private road, a driveway, or another road may you cross two solid yellow lines.","imageName":"","correctAnswer":8512,"isBookmarked":0,"aid":"897#8512#8513#38766","text":"None of the above#The center of the roadway#The individual lanes of travel#The edge of the roadway"},
{"testId":10,"qid":2905,"description":"This symbol warns you:","explanation":"This symbol alerts you to a soft shoulder. The soil on the roadside is soft. Except in an emergency, never leave the pavement.","imageName":"202002101226532079.jpg","correctAnswer":26223,"isBookmarked":0,"aid":"26223#26224#26225#41731","text":"not to leave the pavement#to move onto the shoulder at high speed#to move onto the shoulder at reduced speed#to increase your following distance to six seconds"},
{"testId":52,"qid":2910,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending traffic light. If the light is yellow or red, prepare to come to a complete stop. These signs are posted on roads with higher speed restrictions or in areas where your view of the traffic light ahead may be obstructed.","imageName":"202002101142048449.jpg","correctAnswer":1585,"isBookmarked":0,"aid":"1585#1586#3572#40333","text":"A traffic signal ahead#An uncontrolled intersection ahead#A work zone ahead#A railroad crossing signal ahead"},
{"testId":23,"qid":2911,"description":"A single broken yellow line typically marks the centerline of a two-way roadway where:","explanation":"A single broken yellow line normally represents the centerline of a two-way road, where a vehicle may pass if it is safe to do so.","imageName":"202007151939184445.jpg","correctAnswer":8548,"isBookmarked":0,"aid":"8547#8548#8549#8550","text":"a vehicle may not pass#a vehicle may pass if it is safe#a vehicle is allowed to park#a vehicle may make a U-turn"},
{"testId":38,"qid":2913,"description":"When overtaking and passing another vehicle on a two-lane road, you should pass :","explanation":"When passing another car on a two-lane road, pass only on the left side of the vehicle.","imageName":"202003201627209152.jpg","correctAnswer":8552,"isBookmarked":0,"aid":"8551#8552#8553#8554","text":"only on the right of the vehicle#only on the left of the vehicle#on either the left or the right of the vehicle#on neither the left nor the right of the vehicle"},
{"testId":29,"qid":2918,"description":"Only pass on the left on a two-lane road with vehicles moving in opposing directions when:","explanation":"On a two-lane road where traffic moves in opposite directions, you may pass on the left when a broken yellow line is next to your lane.","imageName":"","correctAnswer":30632,"isBookmarked":0,"aid":"8571#28759#30632#30633","text":"a double solid yellow line is in the center#a solid yellow line is next to your lane#a broken yellow line is next to your lane#a broken yellow line is not next to your lane"},
{"testId":45,"qid":2921,"description":"The freeway section beyond the solid white line is designated for:","explanation":"A solid white line marks the freeway's outside boundary in either direction. Following that comes the shoulder, which should only be utilized in an emergency. Never drive or pass on the shoulder of the road.","imageName":"202003161937198796.jpg","correctAnswer":8581,"isBookmarked":0,"aid":"8578#8579#8580#8581","text":"parking only#U-turns only#stopping only#emergency use only"},
{"testId":48,"qid":2922,"description":"When it's raining or the road is wet, you should slow down so you don't get into :","explanation":"When it rains or the road is wet, when you accelerate, your tires will begin to ride up on the water like water skis due to a lack of grip with the road. This is referred to as hydroplaning. On wet roads, go no faster than 35 mph to prevent this problem.","imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8582#8583#8584#8585","text":"traction with the surface#Hydroplaning#tailgating#wear and tear"},
{"testId":50,"qid":2924,"description":"Talking on a cell phone while driving may increase your odds of being in a crash by:","explanation":"Talking on a mobile phone while driving increases your chances of getting in a collision by up to four times. This is because the chat is causing you to get distracted while driving. Sending text messages (texting) while driving increases your chances of being in an accident by up to eight times.","imageName":"","correctAnswer":21080,"isBookmarked":0,"aid":"8591#8592#8593#21080","text":"two times#three times#some other factor#four times"},
{"testId":2,"qid":2926,"description":"Which of the following provides the finest protection in a car accident and is hence your best defense against a drunk driver?","explanation":null,"imageName":"","correctAnswer":30245,"isBookmarked":0,"aid":"96#98#99#30245","text":"Car-sensitive toolkit#Curved cushion seat#Windshield wiper#Safety belt"},
{"testId":3,"qid":2930,"description":"When approaching and entering an acceleration lane, _________ to match the speed of vehicles in through lanes.","explanation":"For accessing highways, entrance ramps are provided. Typically, the entry ramp leads to an acceleration lane. Increase your speed as you approach and enter the acceleration lane to match the cars in the through lanes. Look for a gap in motorway traffic, engage your turn signal, and join seamlessly with the rest of the traffic. Unless absolutely required, do not stop or slow down in the acceleration lane.","imageName":"","correctAnswer":125,"isBookmarked":0,"aid":"125#454#28127#42668","text":"increase your speed#suddenly decrease your speed#change lanes and increase your speed#slowly decrease your speed"},
{"testId":3,"qid":2953,"description":"Which of the following things influences your vehicle's stopping distance?","explanation":"Total stopping distance is the sum of perception distance (the distance your vehicle travels between when your eyes see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#3849#3850#3851","text":"All of the above#Perception distance#Reaction distance#Braking distance"},
{"testId":37,"qid":2953,"description":"Which of the following things influences your vehicle's stopping distance?","explanation":"Total stopping distance is the sum of perception distance (the distance your vehicle travels between when your eyes see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#3849#3850#3851","text":"All of the above#Perception distance#Reaction distance#Braking distance"},
{"testId":35,"qid":2956,"description":"If you want to turn right at an intersection, you must stay _______ and turn close to the right curb.","explanation":"Stay as near to the right as possible while approaching a junction to make a right turn. Make a right turn near the curb.","imageName":"202007141909455801.jpg","correctAnswer":4310,"isBookmarked":0,"aid":"808#4309#4310#4311","text":"in the center lane#on the shoulder#as close to the right as possible#as close to the left as possible"},
{"testId":35,"qid":2957,"description":"You must start the turn _____ to turn left from a one-way street onto another one-way street.","explanation":"Unless signs, signals, or pavement markings indicate otherwise, turning left from one one-way street into another one-way street must begin in the left lane and be made near to the left curb.","imageName":"202007141917443020.jpg","correctAnswer":8672,"isBookmarked":0,"aid":"8670#8671#8672#8673","text":"from the left lane and make the turn close to the right curb#from the right lane and make the turn close to the left curb#from the left lane and make the turn close to the left curb#from the right lane and make the turn close to the right curb"},
{"testId":45,"qid":2962,"description":"You can't use ______ to get off a freeway, even if there's a traffic jam.","explanation":"Even if there is a traffic delay, you must not leave the motorway through an entry ramp or acceleration lane.","imageName":"","correctAnswer":5323,"isBookmarked":0,"aid":"2547#4002#5323#5324","text":"A deceleration lane#an exit ramp#an entrance ramp#the rightmost lane"},
{"testId":2,"qid":2966,"description":"Before you change lanes:","explanation":"Before changing lanes, you should check your mirrors and then check your blind spot by looking over your shoulder toward the rear.","imageName":"","correctAnswer":24318,"isBookmarked":0,"aid":"24318#24319#24320#31323","text":"check your mirrors and look over your shoulder toward the rear#check behind you and look in your side mirrors#check your speed and look over your shoulder toward the rear#check your rear-view and side mirrors"},
{"testId":3,"qid":2967,"description":"Constantly being aware of driving situations, planning ahead, anticipating hazards, and taking appropriate actions to avoid obstacles or other vehicles is referred to as ________.","explanation":"Defensive driving is defined by the National Safety Council as \"driving to save lives, time, and money regardless of the circumstances around you or the conduct of others.\" Defensive driving is about anticipating potentially harmful events, such as driving conditions and other people's errors, and preparing how to cope with them. This involves being aware of the driving circumstances at all times, planning ahead, anticipating hazards, and taking the appropriate measures to avoid obstructions or other cars. You owe it to yourself to learn to drive defensively.","imageName":"","correctAnswer":8703,"isBookmarked":0,"aid":"8703#8704#8705#8706","text":"defensive driving#safe driving#good driving#positive driving"},
{"testId":11,"qid":2973,"description":"On streets and highways, diagonal yellow stripes indicate that ___________.","explanation":"On streets and highways, diagonal yellow striping denotes a limited path or an impending impediment.","imageName":"202003161916467426.jpg","correctAnswer":37187,"isBookmarked":0,"aid":"37184#37185#37186#37187","text":"(a) the road is narrowing#(b) there is an obstruction on the roadway#neither (a) nor (b)#either (a) or (b)"},
{"testId":38,"qid":2985,"description":"Signal and check for oncoming traffic when changing lanes, passing, or entering traffic. First, check mirrors. Then:","explanation":"When changing lanes, preparing to pass another vehicle, or joining traffic, use your mirrors to indicate and check for passing vehicles. Once the mirrors show that the move is safe, check your vehicle's blind spots by looking over your shoulder to the back in the direction of the motion.","imageName":"202007141857203811.jpg","correctAnswer":24207,"isBookmarked":0,"aid":"24204#24206#24207#29193","text":"check the front of your vehicle#check for oncoming traffic#check your vehicle's blind spots#check the rear of your vehicle"},
{"testId":30,"qid":2987,"description":"When stopped behind another vehicle on a hill, you should back up at least :","explanation":"When stopping behind another car on a slope, remain roughly one vehicle length back. If you are too near to the car, when it begins, it may roll back into your vehicle.","imageName":"202003241907368667.jpg","correctAnswer":8766,"isBookmarked":0,"aid":"8766#8767#8768#8769","text":"one vehicle length#two vehicle lengths#three vehicle lengths#four vehicle lengths"},
{"testId":46,"qid":2996,"description":"When two or more lanes are moving in the same direction, a broken white line is used:","explanation":"A broken white line is used to split either side of the highway into distinct lanes for cars moving in the same direction when two or more lanes are travelling in the same direction.","imageName":"","correctAnswer":41002,"isBookmarked":0,"aid":"8797#8798#41001#41002","text":"to indicate the left edge of the roadway#to indicate the right edge of the roadway#to divide each side of the roadway into separate lanes for vehicles traveling in opposite directions#to divide each side of the roadway into separate lanes for vehicles traveling in the same direction"},
{"testId":40,"qid":2997,"description":"This symbol stands for:","explanation":"Guide signs educate drivers on the sort of route they are on, forthcoming highway entrances and exits, and distances to different destinations. Shield-shaped guide signs are often used to indicate US Routes and interstate routes. This sign informs you that you are on Interstate 95 (I-95), which spans from Maine to Florida.","imageName":"202003301739541577.jpg","correctAnswer":8802,"isBookmarked":0,"aid":"8802#8803#8804#31628","text":"the interstate highway number#the exit number#the number of miles to the next exit#the interstate highway's speed limit"},
{"testId":40,"qid":2998,"description":"If someone is following you too closely, you must:","explanation":"If you are being tailgated, go into the right lane and slow down to let the tailgater to pass. Don't try to outrun or outpace the tailgater by driving faster. Some tailgaters believe that no speed is too fast.","imageName":"","correctAnswer":29877,"isBookmarked":0,"aid":"29876#29877#29878#34718","text":"move into the left lane and increase your speed#move into the right lane and reduce your speed#move into the left lane and reduce your speed#move into the right lane and stop your vehicle"},
{"testId":43,"qid":3003,"description":"The purpose of ________ is to provide drivers with advanced notice about construction zones or other unexpected situations.","explanation":"Mobile devices such as electronic message signs and flashing arrow panels are used on certain roadways to provide early notice of work zones or unusual circumstances at or near work zones.","imageName":"","correctAnswer":30974,"isBookmarked":0,"aid":"5019#5643#5644#30974","text":"Barricades#Vertical panels#Drums#Flashing arrow panels"},
{"testId":10,"qid":3012,"description":"In a roundabout, you must drive in :","explanation":"A roundabout is a circular crossroads where traffic flows anticlockwise around a central island.","imageName":"","correctAnswer":23844,"isBookmarked":0,"aid":"8839#8841#8842#23844","text":"a clockwise direction#the direction of the oncoming vehicles#any direction#a counterclockwise direction"},
{"testId":8,"qid":3016,"description":"Which of the following is NOT a correct step for parallel parking on the right side of the road?","explanation":"The following are the stages for parallel parking on the right side of the road. Turn on your turn signal to alert other cars that you are about to park. Signal and come to a complete stop with the rear of your car, even if it is directly in front of the parking place. Rear up carefully, rotating your steering wheel to the right to direct the back of your vehicle toward the front of the vehicle in front of you. Turn your wheels abruptly to the left as the front of your vehicle clears the rear of the automobile in front of you, and continue retreating gently until the back of your car nearly reaches the car behind you. Pull forward and straighten your wheels to center your car in the parking place. Put your automatic gearbox in Park, first gear, or reverse (for a manual). Apply the parking brake.","imageName":"202007161153196086.jpg","correctAnswer":38225,"isBookmarked":0,"aid":"8850#8852#8853#38225","text":"Put on your turn signal to warn other drivers that you intend to park#As the front of your vehicle clears the back of the vehicle in front of you, turn your wheels sharply to the left and continue backing slowly until the back of your vehicle almost touches the vehicle behind you#Straighten your wheels and pull forward to center the vehicle in the parking space#Back up slowly, turning your steering wheel to the left to aim the back of your vehicle toward the front of the vehicle behind you"},
{"testId":45,"qid":3041,"description":"If there is ________ next to your lane, you may pass another vehicle.","explanation":"If there is a dashed line (white or yellow) adjacent to your lane, you may pass another car.","imageName":"","correctAnswer":3883,"isBookmarked":0,"aid":"3883#3885#8810#8811","text":"a dashed white line#A solid white line#a double solid yellow line#a single solid yellow line"},
{"testId":8,"qid":3054,"description":"Which of the following are two different kinds of speed signs?","explanation":"Speed limit signs and advisory (recommended) speed signs are the two kinds of speed signs. The legal speed limit is shown in black letters and numerals on a white backdrop on speed limit signs. On a yellow backdrop, advisory speed signs provide a suggested speed in black characters and numerals. Advisory speed signs are sometimes located behind warning signs.","imageName":"","correctAnswer":8926,"isBookmarked":0,"aid":"8926#8927#8928#8929","text":"Speed limit signs and advisory speed signs#Speed limit signs and accident speed signs#Accident speed signs and advisory speed signs#Advisory speed signs and informational speed signs"},
{"testId":34,"qid":3114,"description":"You are driving through a crossroads where the light is green. A person starts to cross your path even though the light is red. How should you start?","explanation":"If you have a green light and a pedestrian is breaking the law by crossing against a red signal, honk your horn to warn the person. Of course, you must take all precaution to avoid striking the person. If the pedestrian does not stop after you blast your horn, you must stop for them.","imageName":"202003161228069107.jpg","correctAnswer":38538,"isBookmarked":0,"aid":"28678#37528#38537#38538","text":"Confront the pedestrian#Yield to the pedestrian#Proceed because you have the right-of-way#Sound a warning with your horn"},
{"testId":47,"qid":3124,"description":"The distance your vehicle moves from the time you see a danger to the time you start to brake is called:","explanation":"Total stopping distance is the sum of perception distance (the distance between when your eyes see the hazard and when your brain processes it), reaction distance (the distance between when your brain orders your foot to move onto the brake pedal and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":9112,"isBookmarked":0,"aid":"9111#9112#9114#30312","text":"the braking distance#the reaction distance#the stopping distance#the perception distance"},
{"testId":3,"qid":3129,"description":"A vehicle carrying a trailer stays to the right on a roadway with three lanes in each direction because:","explanation":"The rightmost lane on a highway with at least three lanes in each direction is for cars arriving or departing the highway as well as vehicles moving slowly. The leftmost lane is designated for passing. Slower cars should always stay in the right lane.","imageName":"202007150255488709.jpg","correctAnswer":31255,"isBookmarked":0,"aid":"9125#9128#26263#31255","text":"of lack of visibility#of the high speed of the vehicle#a No-Zone exists behind this vehicle#it moves slower than other vehicles"},
{"testId":30,"qid":3154,"description":"When driving down a steep downhill, you should take the following precautions:","explanation":"The brakes may fade if you continue to use them to manage your speed on a steep slope (lose their effectiveness). Instead, let out of the throttle and move into a lower gear (even with an automatic transmission). This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"202006041738281552.jpg","correctAnswer":21302,"isBookmarked":0,"aid":"9197#21302#22060#33820","text":"Turn on your headlights#Shift into a lower gear#remove your seat belt#keep applying the brakes"},
{"testId":45,"qid":3163,"description":"The left lane on a roadway should be used for:","explanation":"On a roadway, the left lane is utilized for passing. If a car wishes to pass you, you must move to the right safely. Do not accelerate until the other car has past you.","imageName":"","correctAnswer":8967,"isBookmarked":0,"aid":"8967#9215#9216#9217","text":"passing#backing up#crossing over#exiting"},
{"testId":47,"qid":3189,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Slow down and prepare to yield to oncoming traffic on the roundabout.","imageName":"202002101237092503.jpg","correctAnswer":23583,"isBookmarked":0,"aid":"23583#35194#40302#40303","text":"A roundabout is ahead#U-turns are allowed#A highway interchange is ahead#A parking lot is ahead"},
{"testId":34,"qid":3193,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":35,"qid":3196,"description":"The ________ are a pair of white or longitudinal lines that indicate pedestrian walkways.","explanation":"Crosswalk lines are two parallel white lines that indicate where pedestrians may walk. Do not park your car at a crosswalk. Always yield to pedestrians at crosswalks, whether marked or unmarked.","imageName":"","correctAnswer":4339,"isBookmarked":0,"aid":"4339#4340#4341#4342","text":"Crosswalk lines#Stop lines#Pavement markings#Walking lines"},
{"testId":41,"qid":3203,"description":"You can do _____ to turn around on a narrow two-way street.","explanation":"If the street is too small for a U-turn, you may turn around by performing a three-point turn. This move will also send you in the other direction. A three-point turn is authorized only when the roadway is small, there is adequate sight, traffic is light on both sides of the street, and the turn is permitted.","imageName":"","correctAnswer":8879,"isBookmarked":0,"aid":"8878#8879#8880#8999","text":"a two-point turn#a three-point turn#a four-point turn#a single-point turn"},
{"testId":8,"qid":3212,"description":"If you need to switch lanes on a multilane roadway, you should:","explanation":"If you need to switch lanes, do it one at a time to lessen the likelihood of an accident.","imageName":"","correctAnswer":24335,"isBookmarked":0,"aid":"24332#24333#24335#28485","text":"change them two at a time#change them at a higher speed#change them one at a time#change them at a lower speed"},
{"testId":10,"qid":3213,"description":"The areas surrounding trucks and buses where accidents are most likely to occur are referred to as :","explanation":"Do not congregate in a No-Zone! No-Zones are vast regions surrounding trucks, buses, and other big vehicles where automobiles might vanish into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. There are no-zones in the vehicle's front, back, and sides. It is hard to totally avoid the No-Zones of a heavy vehicle. However, do not stay in a No-Zone for any longer than is absolutely required to safely pass the vehicle. Never, ever tailgate a truck.","imageName":"","correctAnswer":26081,"isBookmarked":0,"aid":"8854#8856#8857#26081","text":"empty spots#round spots#no-passing zones#No-Zones"},
{"testId":8,"qid":3233,"description":"This symbol cautions drivers that:","explanation":"This sign indicates that the road ahead is slick in the rain. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. Roads are often slickest within the first half hour of rain. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":41401,"isBookmarked":0,"aid":"34663#34664#34665#41401","text":"a steep hill is ahead#a gravel road is ahead#a winding road is ahead#the road ahead is slippery when wet"},
{"testId":52,"qid":3233,"description":"This symbol cautions drivers that:","explanation":"This sign indicates that the road ahead is slick in the rain. Slow down, increase your following distance, continue with care, and avoid unexpected movements in rainy conditions. Roads are often slickest within the first half hour of rain. This style of sign may often be seen on bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":41401,"isBookmarked":0,"aid":"34663#34664#34665#41401","text":"a steep hill is ahead#a gravel road is ahead#a winding road is ahead#the road ahead is slippery when wet"},
{"testId":44,"qid":3238,"description":"When approaching a junction with a flashing yellow light, you must:","explanation":"A flashing yellow light indicates a potential threat. Slow down and continue with care while approaching a flashing yellow light.","imageName":"","correctAnswer":9181,"isBookmarked":0,"aid":"7651#9181#26062#28114","text":"Come to a complete stop#slow down and proceed with caution#proceed at the same speed#proceed at increased speed"},
{"testId":46,"qid":3280,"description":"Why are the flashing arrow boards used in work zones?","explanation":"In work zones, flashing arrow panels may be used to direct cars into certain traffic lanes and to notify them that a portion of the road ahead is blocked.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#8685#8687#8688","text":"Guide drivers into certain traffic lanes#Stop all vehicles near a work zone#Direct vehicles into certain parking areas#Indicate the permissible speed limit"},
{"testId":42,"qid":3317,"description":"Which of the following turning rules is NOT correct?","explanation":"Where there are no signs or lane markings to guide you, turn from the lane closest to the direction you wish to travel and into the lane closest to the one you came from. You will cross the fewest traffic lanes this way.","imageName":"","correctAnswer":8678,"isBookmarked":0,"aid":"8678#8679#8680#8681","text":"Where there are no signs to control turning, you must turn into the lane farthest from the one you came from#When making a left turn, avoid cutting the corner sharply#When making a right turn, avoid swinging wide to the left#When making turns, go from one lane to the other as directly as possible without crossing lane lines"},
{"testId":44,"qid":3321,"description":"What indicates a no-passing zone next to your lane?","explanation":"A no-passing zone is denoted by a solid yellow line on your side of the road.","imageName":"","correctAnswer":5111,"isBookmarked":0,"aid":"897#5109#5110#5111","text":"None of the above#A broken yellow line#A broken white line#A solid yellow line"},
{"testId":13,"qid":3777,"description":"Which of the following is a legal place to park?","explanation":"A legal parking zone might be defined as the curb or border of a public highway. Make sure your parking spot is (1) far enough away from any travel lane to prevent interfering with traffic and (2) visible to motorists entering from both directions.","imageName":"","correctAnswer":24162,"isBookmarked":0,"aid":"1494#1496#24162#24392","text":"A crosswalk or sidewalk#A bridge or overpass or in a tunnel or underpass#The curb or edge of a public road#Within 30 feet of a stop sign, yield sign, or traffic signal"},
{"testId":17,"qid":3783,"description":"You can avoid highway hypnosis on an expressway by:","explanation":"Highway hypnosis is a state of tiredness or unawareness caused by monotony, the noises of the wind and tires hitting the pavement, and the continuous hum of the motor. Avoid the situation by moving your gaze from one region of the road to another and concentrating on numerous items close and distant, left and right.","imageName":"","correctAnswer":24401,"isBookmarked":0,"aid":"9041#24175#24401#24402","text":"listening to very loud music#constantly talking on your cell phone#constantly shifting your eyes from one area of the roadway to another#constantly changing lanes"},
{"testId":35,"qid":3798,"description":"A roundabout is a circular crossroads in which vehicles drive in the ________ direction around a central island.","explanation":"A roundabout is a circular crossroads in which cars go counterclockwise around a central island. These junctions are intended to minimize the amount and severity of accidents while also increasing operating efficiency.","imageName":"","correctAnswer":24208,"isBookmarked":0,"aid":"4315#4316#4318#24208","text":"a straight-line#a clockwise#any#a counterclockwise"},
{"testId":8,"qid":3813,"description":"Generally, work and construction zone signs are:","explanation":"Work zone signs are often orange in color. Ordinary warning signs are yellow or yellow-green fluorescent.","imageName":"","correctAnswer":23306,"isBookmarked":0,"aid":"14758#23305#23306#23307","text":"red#blue#orange#green"},
{"testId":34,"qid":3876,"description":"How should you deal with a curve?","explanation":"Slow down before entering a curve so you don't have to brake in the curve. In a curve, braking might induce a skid.","imageName":"","correctAnswer":24264,"isBookmarked":0,"aid":"24261#24263#24264#34699","text":"Stay on your side of the road and drive as far to the left as possible#Gradually increase your speed before entering the curve#Slow down before entering the curve#Start to turn the vehicle just before the curve and quickly increase your speed"},
{"testId":41,"qid":3884,"description":"Usually, the work zone signs have:","explanation":"Work zone temporary signage are all orange with black wording or symbols. Drivers in or around work zones may use these signs for information and guidance. On each side of the street, these could be posted.","imageName":"","correctAnswer":24277,"isBookmarked":0,"aid":"24274#24275#24276#24277","text":"white letters on a blue background#white letters on a red background#black letters on a yellow background#black letters on an orange background"},
{"testId":39,"qid":3919,"description":"When you get to an intersection and want to move, you should follow the _____ rule before pulling out.","explanation":"When approaching a junction, remember the left-right-left rule: look to your left first since cars approaching from the left are closer. Then take a peek to your right. Finally, before you proceed, take another look to your left. You may see a car on your left that you did not notice the first time you glanced.","imageName":"","correctAnswer":24306,"isBookmarked":0,"aid":"24305#24306#24307#24308","text":"right-left-right rule#left-right-left rule#left-left-right rule#left-right rule"},
{"testId":10,"qid":3935,"description":"This sign's shape implies:","explanation":"The only form of sign that is pennant shaped is the No Passing Zone sign. It marks the beginning of a no-passing zone for all cars heading in your way. This sign may be seen on the left side of the road.","imageName":"202003151738521917.jpg","correctAnswer":24545,"isBookmarked":0,"aid":"8621#24543#24545#34695","text":"merging traffic ahead#two-way road ahead#no-passing zone ahead#No-Zone ahead"},
{"testId":38,"qid":3952,"description":"If you notice a ________ sign in your lane, you are driving in the incorrect direction.","explanation":"You are not permitted to enter a highway with a Do Not Enter sign. Such signs will be seen at road openings that you should not enter or where you would be going the wrong way on a one-way street. These signs may be seen at exit ramps, crossovers on split highways, and several points on one-way roads.","imageName":"","correctAnswer":29189,"isBookmarked":0,"aid":"29189#29192#39277#39278","text":"Do Not Enter#Detour#Road Closed#One Way"},
{"testId":41,"qid":3964,"description":"Slower traffic should always use  ______ on a four-lane motorway with four or more lanes.","explanation":"Slower vehicles should always take the rightmost lane on a four-lane roadway (the lane that is usually closest to the shoulder). Except when passing, stay in the left lane.","imageName":"","correctAnswer":22428,"isBookmarked":0,"aid":"8640#8891#22427#22428","text":"The center lane#The shoulder#The leftmost lane#The rightmost lane"},
{"testId":38,"qid":4024,"description":"What is the meaning of this sign?","explanation":"This caution sign indicates that there is a right-hand bend ahead. A safe speed for the curve may be shown right below the sign.","imageName":"202002061027163210.jpg","correctAnswer":33786,"isBookmarked":0,"aid":"3985#31598#33786#33787","text":"The posted speed limit for a sharp right turn#The posted speed limit for an S-shaped curve#A recommended safe speed for an upcoming curve to the right#A recommended minimum speed on a highway"},
{"testId":36,"qid":4031,"description":"The letters \"RR\" painted on the road indicate that you are getting close to:","explanation":"There may be pavement markings on the approach to a railroad crossing that resemble the X and the letters \"RR\" on the advance warning sign for a railroad crossing.","imageName":"202007210455028682.jpg","correctAnswer":9211,"isBookmarked":0,"aid":"9211#9212#9213#9214","text":"a railroad crossing#a road repair zone#a closed road#a curve on the right"},
{"testId":45,"qid":4037,"description":"Opposing traffic lanes are separated by:","explanation":"Yellow lines divide opposing traffic lanes. White lines divide traffic lanes traveling in the same direction.","imageName":"202007150237332094.jpg","correctAnswer":24146,"isBookmarked":0,"aid":"24145#24146#24147#24148","text":"white lines#yellow lines#black lines#red lines"},
{"testId":50,"qid":4041,"description":"When approaching a crossroads with no traffic signs or signals, you must:","explanation":"When approaching a junction with no traffic signals or signs, you must yield to cars on the right before proceeding.","imageName":"","correctAnswer":8813,"isBookmarked":0,"aid":"8812#8813#8814#8815","text":"Yield to vehicles on the left#Yield to vehicles on the right#Yield to heavy vehicles#Yield to slow-moving vehicles"},
{"testId":50,"qid":4042,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Before beginning the descent, check your brakes. Check your mirrors regularly while driving down a long or steep slope. Vehicles often gain speed when they descend a steep incline. Even if your car has an automatic gearbox, use low gear to avoid brake wear.","imageName":"202002101212538783.jpg","correctAnswer":26056,"isBookmarked":0,"aid":"26054#26055#26056#26057","text":"Only trucks are allowed#A dip is ahead#A downgrade is ahead#A parking area for trucks is ahead"},
{"testId":11,"qid":4058,"description":"Partial hydroplaning can occur in a conventional passenger car at speeds as low as:","explanation":"When there is standing water on a highway, hydroplaning happens. Most tires will channel water away from the vehicle at speeds up to 35 mph, similar to how a windshield wiper cleans the windshield. As your speed grows, the tires' ability to channel water decreases, and your tires may lose touch with the road and ride over the water like a pair of water skis. Partial hydroplaning may occur in a conventional passenger automobile at speeds as low as 35 mph.","imageName":"202002061923072507.jpg","correctAnswer":14165,"isBookmarked":0,"aid":"9970#9971#14164#14165","text":"45 mph#65 mph#55 mph#35 mph"},
{"testId":11,"qid":4059,"description":"Where are car and motorcycle accidents most likely to occur?","explanation":"Car and motorbike crashes are more likely to occur at intersections. Usually, the automobile driver fails to see the motorbike and turns into its path.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1690#1691#1692#1693","text":"Roundabouts#Intersections#Expressways#One-way roads"},
{"testId":42,"qid":4064,"description":"To make a turn, you must turn the steering wheel by:","explanation":"When turning bends, use the hand-over-hand approach to turn the steering wheel. If you spin the wheel with just one hand, you may lose control. Straighten out the steering wheel by hand after you've completed the turn. Allowing it to slip between your fingers might be risky.","imageName":"","correctAnswer":22452,"isBookmarked":0,"aid":"22450#22451#22452#22453","text":"the two-hand technique#the hand-to-hand technique#the hand-over-hand technique#the single-hand technique"},
{"testId":27,"qid":4100,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":34310,"isBookmarked":0,"aid":"34310#34311#34312#34313","text":"A divider or an obstruction is ahead; keep right#Oncoming traffic is ahead; take a detour#A curve is ahead; move right and then left#An obstruction is ahead; merge right"},
{"testId":33,"qid":4117,"description":"Typically, diamond-shaped _________ have black wording or symbols on a yellow backdrop.","explanation":"Diamond-shaped warning signs with black lettering or symbols on a yellow backdrop are common.","imageName":"","correctAnswer":4541,"isBookmarked":0,"aid":"4541#4542#4543#4544","text":"Warning signs#Destination signs#Regulatory signs#Service signs"},
{"testId":43,"qid":4123,"description":"A road with the broken white line:","explanation":"Two lanes of traffic flowing in the same direction are separated by a broken white line. If it is safe to do so, you may cross the line to pass or change lanes.","imageName":"","correctAnswer":28119,"isBookmarked":0,"aid":"28118#28119#31611#32180","text":"separates two lanes of traffic moving in opposite directions#separates two lanes of traffic moving in the same direction#marks an edge of the roadway#is used as a stop line at intersections"},
{"testId":18,"qid":4150,"description":"To avoid glare from a vehicle's headlights behind you, use _________ and adjust it before driving.","explanation":"Use a day/night mirror to avoid glare from a vehicle's headlights behind you. Before you drive, make any necessary adjustments.","imageName":"","correctAnswer":26198,"isBookmarked":0,"aid":"3228#3229#3230#26198","text":"a reflective mirror#a dark-colored mirror#a lighting filter#a day/night mirror"},
{"testId":32,"qid":4153,"description":"When driving in traffic in a city, you should try to look at least:","explanation":"When driving in city traffic, try to glance at least one block ahead. In the city, 10 seconds equals about one block.","imageName":"202003201638346586.jpg","correctAnswer":26203,"isBookmarked":0,"aid":"26201#26202#26203#26204","text":"three blocks ahead#two blocks ahead#one block ahead#four blocks ahead"},
{"testId":31,"qid":4156,"description":"If your vehicle's wheels stray onto the road's shoulder, you must :","explanation":"If your vehicle's wheels stray into the road shoulder, do not attempt to turn back onto the pavement straight soon. This may cause the vehicle to become unbalanced. Instead, slow down, restore control, and gradually return to the road.","imageName":"202006041843397112.jpg","correctAnswer":24422,"isBookmarked":0,"aid":"9198#24420#24421#24422","text":"press hard on the gas pedal#try to turn back onto the pavement right away#not reduce your speed#not try to turn back onto the pavement right away"},
{"testId":17,"qid":4175,"description":"When following a car that frequently stops, you should allow ________ more than usual.","explanation":"Buses and post office vans are among the vehicles that make regular stops. Maintain a higher following distance than normal while following one of them.","imageName":"202007141507419863.jpg","correctAnswer":3252,"isBookmarked":0,"aid":"2203#2204#3252#26230","text":"more space on your left#less space on your right#a greater following distance#a smaller following distance"},
{"testId":17,"qid":4176,"description":"Before backing up, look to the front, sides, and back of your car, and continue to look ________ while backing.","explanation":"Before backing up your vehicle, you should look to the front, sides, and rear, and continue to look to the rear while backing.","imageName":"","correctAnswer":2217,"isBookmarked":0,"aid":"2217#2218#2220#24101","text":"to the rear#to the front#to the sides#in the side mirrors"},
{"testId":13,"qid":4253,"description":"Things that can cause you to become distracted while driving include:","explanation":"Anything that takes your focus off the road might be a distraction. Texting, chatting on the phone, interacting with kids, and smoking are a few instances of distractions while driving.","imageName":"","correctAnswer":8505,"isBookmarked":0,"aid":"8254#8505#8506#8507","text":"checking blind spots#text messaging and talking on the phone#always checking mirrors#checking the traffic behind you frequently"},
{"testId":31,"qid":4315,"description":"When a traffic signal at a crossing turns green and you are proceeding straight through, you must:","explanation":"When a traffic light becomes green at a junction, check sure there are no other cars in or coming through the intersection. Yield to any remaining pedestrians on the road. The majority of traffic accidents occur during the first few seconds after the light has changed.","imageName":"202003070857473536.jpg","correctAnswer":31333,"isBookmarked":0,"aid":"26298#31333#31599#32176","text":"stop and wait for the next signal#make sure there are no more vehicles in or coming through the intersection#watch for turning vehicles#drive through the intersection as quickly as possible"},
{"testId":46,"qid":4322,"description":"You are on a road with two lanes. If a vehicle in front of you stops for a pedestrian, you must:","explanation":"When a car ahead of you stops for a pedestrian, even if there are two lanes available, you must not overtake the stopped vehicle. This is a common danger to pedestrians.","imageName":"202006141707163460.jpg","correctAnswer":9095,"isBookmarked":0,"aid":"9092#9093#9094#9095","text":"change lanes and pass the vehicle quickly#reduce your speed and pass the vehicle slowly#alert the stopped vehicle to take a detour#not pass the stopped vehicle"},
{"testId":36,"qid":4353,"description":"What is the meaning of this sign?","explanation":"A red circle and slash on a prohibitory sign means \"no.\" This sign indicates that left turns are not permitted in this area.","imageName":"202003151710362609.jpg","correctAnswer":29870,"isBookmarked":0,"aid":"26318#26319#29870#35903","text":"The left lane is closed ahead#The right lane is closed ahead#Left turns are prohibited#Right turns are prohibited"},
{"testId":36,"qid":4354,"description":"This symbol is used:","explanation":"This is a No-Entry sign. It signifies that you are not permitted to access the road where the sign is located. Such signs will be seen at road openings that you should not enter or where you would be going the wrong way on a one-way street. These signs may be seen at exit ramps, crossovers on split highways, and several points on one-way roads.","imageName":"202003151714361449.jpg","correctAnswer":38955,"isBookmarked":0,"aid":"27125#38954#38955#38956","text":"to prohibit heavy trucks from entering a roadway#to allow vehicles in certain lanes#to prohibit vehicles from entering a roadway#to prohibit vehicles from making a U-turn"},
{"testId":43,"qid":4361,"description":"Generally, work and construction zone signs have:","explanation":"Signs for construction, maintenance, or emergency operations are usually rectangular or diamond-shaped, with black characters or symbols on an orange backdrop. They alert drivers that construction is taking place on or near the route.","imageName":"","correctAnswer":24277,"isBookmarked":0,"aid":"24276#24277#29784#29785","text":"black letters on a yellow background#black letters on an orange background#white letters on an orange background#white letters on a yellow background"},
{"testId":46,"qid":4474,"description":"What is the meaning of this sign?","explanation":"A green light indicates that you may go into the junction after yielding to pedestrians and cars already present.","imageName":"202003070857473536.jpg","correctAnswer":26324,"isBookmarked":0,"aid":"19487#26322#26324#41119","text":"You must slow down#You must stop#You may proceed if the intersection is clear#You may speed up"},
{"testId":47,"qid":4582,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the fact that you are on the terminating (ending) road of a T-intersection. After yielding to cross traffic, prepare to turn right or left.","imageName":"202002101135262507.jpg","correctAnswer":41241,"isBookmarked":0,"aid":"40906#40907#40908#41241","text":"A public transportation stop is ahead#A side road is ahead#A tourist information center is ahead#The roadway you are traveling on ends ahead"},
{"testId":21,"qid":4790,"description":"When approaching a work zone or driving through it, you must NOT:","explanation":"When approaching or driving through a construction zone, keep an eye out for other cars and prevent unexpected movements. Do not weave from one lane to the next. Slow down gently to allow vehicles behind you enough time to slow down. Keep up with the traffic flow.","imageName":"","correctAnswer":8537,"isBookmarked":0,"aid":"8534#8535#8536#8537","text":"watch for slower speed limits#Reduce your speed#keep up with the flow of traffic#weave from lane to lane"},
{"testId":14,"qid":4902,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"A pennant-shaped sign informs that no passing is permitted in this region.","imageName":"202003151718184521.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#30137#30138","text":"Right turn ahead#No passing#One-way street#Merging right"},
{"testId":32,"qid":4957,"description":"The ________ lane on multilane roadways is used for passing slower vehicles.","explanation":"On multilane highways, the leftmost lane is designated for passing slower cars. If you pass on the right, the other vehicle may lose sight of you and abruptly change lanes in front of you.","imageName":"","correctAnswer":24432,"isBookmarked":0,"aid":"120#2393#24431#24432","text":"Center#shoulder#rightmost#leftmost"},
{"testId":18,"qid":5110,"description":"If you want to go through an intersection with a turn, you must:","explanation":"If you want to turn beyond a junction, signal immediately after passing past it. If you signal too early, another car may mistakenly believe you are turning at the junction.","imageName":"202007210445346932.jpg","correctAnswer":22533,"isBookmarked":0,"aid":"22532#22533#22535#24106","text":"signal before passing through the intersection#signal just after you pass through the intersection#not give any turn signal#signal just before making the turn"},
{"testId":18,"qid":5149,"description":"At railroad crossings with merely a stop sign, you should stop and then advance only when it is safe to do so.","explanation":"You must stop if the lights are flashing or the gates are lowered at crossings with automated flasher units or crossing gates, and you must stay stopped until the lights cease flashing and the gates are lifted. When there is simply a stop sign at a crossing, you must halt and only go forward after confirming there are no oncoming trains. Make sure there is space for your car on the other side of the crossing and that you yield to pedestrians there.","imageName":"202007151919134112.jpg","correctAnswer":24643,"isBookmarked":0,"aid":"24643#29803#29804#38937","text":"all of the above are true#you determine that no train is approaching#you determine that no pedestrians are approaching#you determine that there is enough room for your vehicle on the opposite side"},
{"testId":19,"qid":5178,"description":"When a median or barrier divides a highway into two roadways,:","explanation":"When a highway is separated into two highways by a median or barrier, you may only cross the median or barrier at an allowed crossover.","imageName":"202006041826114842.jpg","correctAnswer":24238,"isBookmarked":0,"aid":"24169#24170#24171#24238","text":"you can drive across the median or barrier in emergencies#you can drive across the median or barrier to make a left turn#you must not drive across the median or barrier except to make a U-turn#you must not drive across the median or barrier except at an approved crossover"},
{"testId":19,"qid":5179,"description":"What must you do if you begin to slide?","explanation":null,"imageName":"202006041906425451.jpg","correctAnswer":24395,"isBookmarked":0,"aid":"24393#24394#24395#24396","text":"Brake immediately and carefully steer in the direction in which you want the car to go#Ease your foot off the gas and carefully steer opposite to the direction in which you want the car to go#Ease your foot off the gas and carefully steer in the direction in which you want the car to go#Brake immediately and carefully steer opposite to the direction in which you want the car to go"},
{"testId":17,"qid":5341,"description":"If you wish to exit a multilane roundabout less than halfway around it, use:","explanation":"Do not overtake or pass any vehicle on a contemporary, multilane roundabout. To leave the roundabout, be prepared to yield to cars turning in front of you from the inner lane. Use the right lane if you want to leave the roundabout less than halfway around.","imageName":"202007161144073258.jpg","correctAnswer":8641,"isBookmarked":0,"aid":"8626#8639#8640#8641","text":"None of the above#The left lane#The center lane#The right lane"},
{"testId":21,"qid":5462,"description":"Signs with vertical rectangular black letters on a white background are :","explanation":"Vehicles on both sides of the road must stop for a school bus that has stopped to pick up passengers. To inform cars, the bus driver will activate stop signs and flashing red lights. [School Buses, Giving Problem Drivers a Space Cushion, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":8876,"isBookmarked":0,"aid":"8874#8875#8876#38134","text":"warning signs#instruction signs#regulatory signs#service signs"},
{"testId":38,"qid":5553,"description":"When driving on a two-way street with no centerline markers, you must:","explanation":"When driving on a two-way road without a centerline, you must leave at least half the road open for cars traveling in the opposing direction. When driving to the left of the centerline is impossible, you must still yield to approaching traffic.","imageName":"","correctAnswer":29862,"isBookmarked":0,"aid":"22727#29862#29863#29864","text":"Do none of the above#leave at least half the road for vehicles going in the opposite direction#use the full road when there is no oncoming traffic#always drive on the left"},
{"testId":33,"qid":5675,"description":"Which of the following allows vehicles to slow down and leave expressways?","explanation":"Exit ramps enable cars to exit highways. At an exit ramp, a reduced speed is often posted.","imageName":"","correctAnswer":4549,"isBookmarked":0,"aid":"1690#3922#4548#4549","text":"Roundabouts#Acceleration lanes#Turnpikes#Exit ramps"},
{"testId":26,"qid":5708,"description":"Mile markers are reference markers, and they are put along the outside shoulder of the road to indicate where the road boundary is. They provide drivers with information regarding:","explanation":"Every two-tenths of a mile, a reference marker is placed so that cars are constantly in sight of one. They are placed along the outer shoulder to indicate the highway's border and provide vehicles with information about their position on the route for navigation and emergency reasons.","imageName":"","correctAnswer":30963,"isBookmarked":0,"aid":"24253#29858#29859#30963","text":"upcoming expressways#the condition of the roadway#the posted speed limits on the roadway#their locations on the roadway"},
{"testId":24,"qid":5864,"description":"If you use ________ together, you have a better chance of surviving an car accident.","explanation":"When you use the lap and shoulder belts simultaneously, you have a better chance of surviving a vehicle accident. Adjust the lap belt to fit snugly over your hipbones or upper thighs. It should never be placed over your abdomen or on the soft portion of your stomach. The shoulder belt should fit tightly over your chest and across the center of your shoulder.","imageName":"","correctAnswer":29851,"isBookmarked":0,"aid":"1396#3006#22462#29851","text":"None of the above#the shoulder belt and emergency lights#the lap belt and a helmet#the lap belt and shoulder belt"},
{"testId":27,"qid":5980,"description":"In a vehicle with a steering wheel interlock system, do not switch the ignition key to the lock position while driving because it will cause:","explanation":"When your car is in motion, never turn your ignition key to the lock position. This will cause the steering to lock and you to lose control. Since 1969, the steering lock has been standard equipment. Its purpose is to prevent a burglar from directing your automobile if he starts it without the key.","imageName":"","correctAnswer":24354,"isBookmarked":0,"aid":"24351#24353#24354#31635","text":"the brakes to fail#the engine to fail#the steering wheel to lock#the gas pedal to lock"},
{"testId":24,"qid":6023,"description":"Which of the following is a sequence of ramps that connects a motorway to a road or another freeway?","explanation":"An interchange is a road junction where two or more roads cross over one another (i.e., at different levels) so as not to impede one another's traffic flow. Instead, the roads are connected through a series of connecting ramps. These ramps allow you to leave one road and enter another one safely, without disrupting the flow of traffic. There are diamond, cloverleaf, and folded diamond interchanges, among others.","imageName":"202007141929112186.jpg","correctAnswer":3056,"isBookmarked":0,"aid":"3056#3057#3058#30657","text":"Interchange#Exit ramp#Acceleration lane#Entrance ramp"},
{"testId":26,"qid":6304,"description":"What is the meaning of this sign?","explanation":"This sign alerts drivers that a nearby side road crosses a train track. Take cautious while turning into the side road.","imageName":"202002101124556825.jpg","correctAnswer":3408,"isBookmarked":0,"aid":"3407#3408#3409#32525","text":"An intersection for crossroads#A side road at a railroad crossing#A T-junction of a highway#An underpass"},
{"testId":38,"qid":6470,"description":"You must ________, when backing up your vehicle.","explanation":"Before backing up, be sure it is safe and will not interfere with other traffic. It is a good idea to stroll behind the car to ensure that there is no kid, pet, or toy in the path. Turn your head to see over your shoulder and through the back window. You should not rely on your mirrors since they have blind zones.","imageName":"","correctAnswer":33078,"isBookmarked":0,"aid":"8666#28109#33078#33079","text":"depend on your side mirrors#depend on your rear-view mirror#not depend on your mirrors. Instead, turn your head to look through the rear window#not depend on your mirrors. Instead, honk your horn to alert pedestrians behind your vehicle"},
{"testId":29,"qid":6556,"description":"What should you do if you see an oncoming vehicle in your lane?","explanation":"If you detect a car approaching you in your lane, stop hard, blow your horn, flash your headlights, and swerve to the right as far as you can safely. Try not to swerve to the left. The other vehicle may suddenly recognize his or her error and return to the correct lane.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#24366#24367#24368","text":"Do all of the above#Slow down and sound your horn#Flash your headlights#Veer as far to the right as you safely can"},
{"testId":27,"qid":6744,"description":"Before approaching a junction, keep an eye out for:","explanation":"Before entering an intersection, look both left and right for approaching vehicles and crossing pedestrians. If you are stopped, look both left and right just before you start to move. Also look across the intersection to make sure that your way is clear through the intersection.","imageName":"202007151927217904.jpg","correctAnswer":8615,"isBookmarked":0,"aid":"8615#26018#28254#28255","text":"All of the above#Crossing pedestrians#Vehicles approaching from the left#Vehicles approaching from the right"},
{"testId":28,"qid":6826,"description":"A broken yellow line next to a solid yellow line implies:","explanation":"A broken yellow line next to a solid yellow line indicates that passing is permissible on the broken line but not on the solid line.","imageName":"","correctAnswer":22744,"isBookmarked":0,"aid":"22742#22743#22744#22745","text":"passing is not permitted on either side#passing is permitted on the side of the solid line, but not on the side of the broken line#passing is permitted on the side of the broken line, but not on the side of the solid line#passing is permitted on both sides"},
{"testId":30,"qid":6831,"description":"If there are three or more lanes travelling in one direction, the left lane is for drivers who desire to:","explanation":"Where there are three or more lanes traveling in one direction, vehicles that wish to go faster, pass, or turn left utilize the left lane.","imageName":"","correctAnswer":9081,"isBookmarked":0,"aid":"9080#9081#9082#9083","text":"go slower or enter the road#go faster or turn left#go slower or turn left#go faster or turn right"},
{"testId":29,"qid":6879,"description":"What is the meaning of this sign?","explanation":"This is a warning sign for emergency vehicles. It signifies that emergency vehicles from fire stations or other emergency facilities may access the route. When an emergency vehicle coming from any direction sounds its siren or flashes its lights, you must surrender.","imageName":"202002101253183254.jpg","correctAnswer":34070,"isBookmarked":0,"aid":"34068#34069#34070#34071","text":"Farm vehicles may enter the roadway#Trucks carrying hazardous materials may enter the roadway#Emergency vehicles may enter the roadway#Heavy trucks may enter the roadway"},
{"testId":29,"qid":6923,"description":"A wide white line painted across a traffic lane before an intersection is referred to as a:","explanation":"A stop line is a broad white line painted across the middle of a traffic lane. If there is a stop sign or a red traffic light, you must stop before the stop line.","imageName":"","correctAnswer":31905,"isBookmarked":0,"aid":"24133#24134#24135#31905","text":"merge line#crosswalk line#yield line#stop line"},
{"testId":37,"qid":7069,"description":"What is the meaning of this sign?","explanation":"This sign advises that there is a low spot ahead on the road. Slow down for your own comfort and control. Proceed with care and be prepared to halt if the dip becomes flooded.","imageName":"202002101230287150.jpg","correctAnswer":34386,"isBookmarked":0,"aid":"3572#4416#34385#34386","text":"A work zone ahead#A hill ahead#A bump in the road ahead#A low place in the road ahead"},
{"testId":35,"qid":7072,"description":"Along the highways, warning signs are posted to:","explanation":"Warning signs are designed to alert you to potentially dangerous situations ahead, allowing you to continue with care. These signs are often composed of black lettering or symbols on a yellow backdrop. (The backdrop of newer school zone and school crossing signs is neon yellow-green.)","imageName":"","correctAnswer":8558,"isBookmarked":0,"aid":"8555#8556#8557#8558","text":"control and regulate traffic#provide information about directions, distances, and available services#indicate points of interest and geographical information#indicate potentially hazardous conditions ahead"},
{"testId":31,"qid":7191,"description":"At an intersection, a single solid white line across the road indicates that you must ________ for a traffic light or sign.","explanation":"A stop line is a single solid white line across the road at a junction. For a traffic light or sign, you must stop behind the stop line. If there is no stop line, you must come to a complete stop before approaching the crossing.","imageName":"202001301744345845.jpg","correctAnswer":4165,"isBookmarked":0,"aid":"4165#4166#4167#4168","text":"stop behind the line#stop after the line#cross the line#slow down after crossing the line"},
{"testId":51,"qid":7204,"description":"When driving in heavy rain on a highway, you need to reduce your speed in order to prevent the following:","explanation":null,"imageName":"","correctAnswer":8583,"isBookmarked":0,"aid":"8583#8584#8693#8694","text":"Hydroplaning#tailgating#weaving#highway hypnosis"},
{"testId":47,"qid":7244,"description":"Which of the following indications warns you of potential hazards ahead?","explanation":"Warning signs warn you of potential risks ahead. Slow down and keep an eye out for any more indicators or warnings.","imageName":"","correctAnswer":4541,"isBookmarked":0,"aid":"4541#4543#5961#5962","text":"Warning signs#Regulatory signs#Informational signs#Guide signs"},
{"testId":32,"qid":7385,"description":"If there is traffic behind you when you get off a two-lane, high-speed road, you should:","explanation":"When exiting a high-speed, two-lane highway, try not to slow down abruptly; you may be rear-ended by the car ahead of you. Use your turn signals to communicate your intentions to other vehicles. Use your brakes to slow down swiftly yet safely.","imageName":"202007151857193635.jpg","correctAnswer":31287,"isBookmarked":0,"aid":"125#3767#29154#31287","text":"increase your speed#try to slow down as soon as possible#brake hard#try not to slow down suddenly"},
{"testId":51,"qid":7401,"description":"if another vehicle is tailgating your vehicle,you should not:","explanation":"Slow down and urge the motorist behind you to pass if you are being tailgated. If this fails, pull over, come to a complete stop, and allow the tailgater pass. Don't attempt to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202001302249003541.jpg","correctAnswer":6515,"isBookmarked":0,"aid":"6515#8535#24433#34400","text":"Increase your speed#Reduce your speed#move into the right lane#let the tailgater pass"},
{"testId":51,"qid":7444,"description":"A grass, concrete, or other material barrier that separates opposing lanes of traffic on a split roadway is known as a:","explanation":"A median is a barrier made of grass, concrete, or another material that separates opposing lanes of traffic on a split highway. Highways and important streets include medians. Driving over, across, or on a median is unlawful.","imageName":"202003161933517360.jpg","correctAnswer":22429,"isBookmarked":0,"aid":"22429#22430#22431#22432","text":"a median#a shoulder#a gore area#a crosswalk"},
{"testId":35,"qid":7511,"description":"You must __________  at a yield sign in your lane.","explanation":"If you notice a yield sign in your lane, you must slow down (and, if necessary, come to a complete stop) to yield to all pedestrians and vehicles on the route you are crossing or entering.","imageName":"202007120502165773.jpg","correctAnswer":28113,"isBookmarked":0,"aid":"8657#28113#28114#28646","text":"move into the left lane#slow down and stop if necessary#proceed at increased speed#pull over"},
{"testId":33,"qid":7513,"description":"If the vehicle you are passing ________, you must NOT pass on the right.","explanation":"If a vehicle on the right is making or about to make a right turn, you must not pass it. The passing lane must be free of obstructions.","imageName":"","correctAnswer":44370,"isBookmarked":0,"aid":"44369#44370#44371#44372","text":"is making a left turn#is making a right turn#is going straight#is driving on a one-way road with two lanes of traffic"},
{"testId":51,"qid":7563,"description":"What is the meaning of this sign?","explanation":"This pennant-shaped sign states that no cars are permitted to pass.","imageName":"202003151718184521.jpg","correctAnswer":19393,"isBookmarked":0,"aid":"19392#19393#35182#35183","text":"A no-passing zone for high-occupancy vehicles#A no-passing zone for all vehicles#A no-passing zone for heavy trucks#A no-passing zone for passenger vehicles"},
{"testId":31,"qid":7574,"description":"What is the meaning of this sign?","explanation":"This sign advises that the road is slick when wet. Slow down, increase your following distance, and avoid rapid movements or forceful braking if the road seems wet. These signs are often seen at bridges and overpasses.","imageName":"202002101214576723.jpg","correctAnswer":22426,"isBookmarked":0,"aid":"15575#22424#22426#34902","text":"A narrow bridge is ahead#A winding road is ahead#The road ahead is slippery when wet#A steep hill is ahead"},
{"testId":32,"qid":7590,"description":"When driving over gravel or dirt, you must slow down because:","explanation":"You don't have as much traction on gravel or dirt roads as you do on concrete or asphalt roads. Stopping will take significantly longer, and you may skid while turning. Take it easy.","imageName":"202006041839551229.jpg","correctAnswer":34061,"isBookmarked":0,"aid":"9192#34061#34062#34063","text":"the dirt may cover the windshield#you don't have much traction#it's unpleasant to drive fast#your vehicle will get dirtier at higher speeds"},
{"testId":31,"qid":7592,"description":"This symbol is:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required.","imageName":"202001282004545744.jpg","correctAnswer":31318,"isBookmarked":0,"aid":"31318#31319#32478#35413","text":"a railroad crossing sign#a road repair sign#a crossroad sign#a roundabout sign"},
{"testId":51,"qid":7641,"description":"If a flag person signals you near a railroad crossing or work site, you must obey.","explanation":"If a flag person signals you at or near a railroad crossing or construction site, you must heed the flag person's signals regardless of which lane you are in.","imageName":"","correctAnswer":28510,"isBookmarked":0,"aid":"9110#28508#28509#28510","text":"stop and ask for help#follow the flag person's signals if you are in the right lane#follow the flag person's signals if you are in the left lane#follow the flag person's signals no matter which lane you are in"},
{"testId":47,"qid":7843,"description":"A flashing yellow arrow indicates that you should:","explanation":"A flashing yellow light indicates that you should slow down and use care. Flashing yellow lights are placed in potentially dangerous areas.","imageName":"202003070823014609.jpg","correctAnswer":9181,"isBookmarked":0,"aid":"7651#8992#9181#24317","text":"Come to a complete stop#make a U-turn#slow down and proceed with caution#speed up to clear the intersection"},
{"testId":51,"qid":7885,"description":"What is the meaning of this sign?","explanation":"This sign denotes the end of the split roadway. On the same road ahead, traffic flows in both directions. Keep to the right and keep an eye out for incoming traffic.","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#19179#22656#28188","text":"The divided highway ends ahead#Traffic is merging ahead#A divided highway starts ahead#There is a sharp right turn ahead"},
{"testId":51,"qid":7965,"description":"What is the meaning of this sign?","explanation":"Even under perfect driving circumstances, the legal maximum speed restriction here is 55 mph, according to this sign. It should be noted that this does NOT imply that driving at 55 mph is safe in this area; this is dependent on current driving circumstances. In bad or dangerous driving circumstances, you must reduce your speed to less than this limit.","imageName":"202003301759421414.jpg","correctAnswer":35150,"isBookmarked":0,"aid":"35150#35151#35152#35153","text":"The maximum speed under ideal road conditions is 55 mph#The maximum speed under hazardous road conditions is 55 mph#The minimum speed under hazardous road conditions is 55 mph#The minimum speed under ideal road conditions is 55 mph"},
{"testId":35,"qid":8035,"description":"Which of the following things is NOT TRUE about highway interchanges?","explanation":"Don't attempt to back up if you miss your exit or leave at the incorrect junction. Backing up raises the possibility of an accident. Turn around without using a median crossover. Only approved vehicles, such as emergency and maintenance vehicles, may utilize median crossovers.","imageName":"","correctAnswer":37403,"isBookmarked":0,"aid":"22552#28469#30608#37403","text":"If you exit at the wrong interchange, you must follow it out#If you miss your exit, go on to the next one#The two most common types of interchanges are cloverleaf and diamond#If you miss your exit, use a median crossover to turn around"},
{"testId":37,"qid":8073,"description":"What is the meaning of this sign?","explanation":"This sign informs that trucks are about to enter or cross the road ahead. Slow down and keep an eye out for trucks.","imageName":"202002101210401827.jpg","correctAnswer":38592,"isBookmarked":0,"aid":"38592#38593#38594#38595","text":"Trucks may enter or cross this roadway#Trucks may not enter or cross this roadway#High-occupancy vehicles may enter or cross this roadway#Vehicles carrying hazardous materials may enter or cross this roadway"},
{"testId":37,"qid":8391,"description":"When turning from a one-way street onto a two-way street or a road with two lanes, you should always turn from :","explanation":"Turn from the lane closest to the curb when turning from a one-way street into a two-way street or split highway, unless signs or surface markings indicate otherwise.","imageName":"202007161212064082.jpg","correctAnswer":27142,"isBookmarked":0,"aid":"8639#8641#27142#27143","text":"The left lane#The right lane#the lane nearest to the curb#the lane farthest from the curb"},
{"testId":38,"qid":8437,"description":"Turning left from one one-way street onto another one-way road,:","explanation":"Unless signs, signals, or pavement markings indicate otherwise, while turning left from one-way road into another one-way road, move your car into the closest left lane for traffic on the road you are entering.","imageName":"","correctAnswer":8884,"isBookmarked":0,"aid":"8882#8883#8884#8885","text":"bring your vehicle into the nearest right lane for traffic on the road you are entering#turn into the lane just to the right of the center line#bring your vehicle into the nearest left lane for traffic on the road you are entering#turn into the lane just to the left of the center line"},
{"testId":39,"qid":8559,"description":"Large flashing arrow panels may be used in work zones to help drivers get into:","explanation":"Large LED flashing arrow panels may be deployed in work zones at any time of day or night to direct cars into certain traffic lanes. These panels are installed on the road or mounted on vehicles to warn approaching motorists of lane closures.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#8776#28712#38731","text":"Guide drivers into certain traffic lanes#Stop speeding drivers#Guide drivers into certain parking areas#Direct drivers into work zones"},
{"testId":40,"qid":8599,"description":"If your brakes fail while driving, DO NOT :","explanation":"Unless your car has antilock brakes, if your brakes fail, push the brake pedal (ABS). Pumping may restore enough brake fluid pressure to slow or stop your car briefly. If your car has ABS, just compress the brake pedal hard and see if you begin to slow down. Change to a lower gear. This will utilize the engine's braking force to slow your car. Apply the parking or emergency brake gently, but be prepared to let off if you begin to slide. Rub against anything as a final option to slow you down. Attempt to sideswipe the guardrail, scrape your tires on a curb, or drive onto grass. Never allow a head-on collision to occur. Accidents involving a head-on collision are often deadly.","imageName":"","correctAnswer":33359,"isBookmarked":0,"aid":"33359#33360#34415#36977","text":"stop by colliding head-on with a solid object#downshift into a lower gear#rub the wheels against a curb#pump the brake pedal"},
{"testId":41,"qid":8599,"description":"If your brakes fail while driving, DO NOT :","explanation":"Unless your car has antilock brakes, if your brakes fail, push the brake pedal (ABS). Pumping may restore enough brake fluid pressure to slow or stop your car briefly. If your car has ABS, just compress the brake pedal hard and see if you begin to slow down. Change to a lower gear. This will utilize the engine's braking force to slow your car. Apply the parking or emergency brake gently, but be prepared to let off if you begin to slide. Rub against anything as a final option to slow you down. Attempt to sideswipe the guardrail, scrape your tires on a curb, or drive onto grass. Never allow a head-on collision to occur. Accidents involving a head-on collision are often deadly.","imageName":"","correctAnswer":33359,"isBookmarked":0,"aid":"33359#33360#34415#36977","text":"stop by colliding head-on with a solid object#downshift into a lower gear#rub the wheels against a curb#pump the brake pedal"},
{"testId":41,"qid":8922,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a pedestrian crossing ahead. Slow down, be cautious, and be prepared to yield to pedestrians.","imageName":"202002101154005452.jpg","correctAnswer":1945,"isBookmarked":0,"aid":"1348#1945#5785#5968","text":"A playground ahead#A pedestrian crosswalk ahead#A camping area ahead#A school crossing ahead"},
{"testId":42,"qid":8959,"description":"What is the meaning of this sign?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and surrender to oncoming vehicles and pedestrians before proceeding. You may need to stop for them as well, so be prepared.","imageName":"202007120454477868.jpg","correctAnswer":40012,"isBookmarked":0,"aid":"22176#28714#40012#40013","text":"Take a detour#Proceed at the same speed#Slow down and yield#Do not enter this roadway"},
{"testId":44,"qid":9159,"description":"If there is a broken yellow line on your side of the centerline,:","explanation":"You may cross a broken yellow line on your side of the centerline for passing if it is safe to do so.","imageName":"","correctAnswer":22502,"isBookmarked":0,"aid":"22500#22502#22503#40316","text":"you may not cross the line#you may cross the line to pass other vehicles#none of the above apply#you may cross the line only if the other side has a broken yellow line"},
{"testId":45,"qid":9163,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight.","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#26319#40318","text":"The divided highway ends ahead#A divided highway starts ahead#The right lane is closed ahead#Traffic must turn left"},
{"testId":48,"qid":9245,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign forbids automobiles from turning right. This sign is usually seen on the right side of the road or over a driving lane.","imageName":"202003151734593912.jpg","correctAnswer":40269,"isBookmarked":0,"aid":"38076#38146#40269#40334","text":"You must turn right#You must turn left#You may not turn right#You may not make a U-turn"},
{"testId":45,"qid":9599,"description":"What is the meaning of this sign?","explanation":"This sort of warning sign alerts drivers to the possibility of fewer lanes ahead. This sign warns that the right lane is coming to an end ahead. All vehicles in the right lane must merge to the left.","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"54#15575#28070#31641","text":"A one-way road is ahead#A narrow bridge is ahead#The right lane ends ahead#The left lane ends ahead"},
{"testId":47,"qid":9658,"description":"What is the meaning of this sign?","explanation":"This sign informs that the road ahead takes an abrupt right bend. Slow down and continue with care.","imageName":"202002061258239664.jpg","correctAnswer":22422,"isBookmarked":0,"aid":"22422#28726#38972#39374","text":"A sharp right turn is ahead#The road ahead curves to the right#Keep right ahead#Merge right ahead"},
{"testId":47,"qid":9842,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island, median, or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":5640,"isBookmarked":0,"aid":"2506#3800#5640#5641","text":"A winding road ahead#A roundabout ahead#A traffic island or divider ahead#A sharp right turn ahead"},
{"testId":50,"qid":9842,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island, median, or divider ahead. Keep to the right of this stumbling block.","imageName":"202003151752201841.jpg","correctAnswer":5640,"isBookmarked":0,"aid":"2506#3800#5640#5641","text":"A winding road ahead#A roundabout ahead#A traffic island or divider ahead#A sharp right turn ahead"},
{"testId":47,"qid":9845,"description":"Which way should you point your front wheels while waiting for incoming traffic to clear before making a left turn?","explanation":"While waiting for approaching traffic to clear before making a left turn, keep your front wheels pointing straight ahead until the turn begins. If you have your wheels facing left and another car strikes you from behind, you may be pushed into oncoming traffic.","imageName":"","correctAnswer":9906,"isBookmarked":0,"aid":"6214#6217#9906#25321","text":"Left#Right#Straight#In any direction"},
{"testId":49,"qid":9921,"description":"What does this image represent?","explanation":"Flaggers are often seen in highway or street construction zones. To direct traffic safely through certain locations, they wear orange vests, shirts, or jackets and utilize red flags or STOP/SLOW paddles. You must obey the flagger's instructions. This flagger allows traffic to go slowly.","imageName":"202002270946508485.jpg","correctAnswer":41136,"isBookmarked":0,"aid":"39384#39386#41136#41137","text":"A police officer stopping traffic#A police officer allowing traffic to proceed slowly#A flagger allowing traffic to proceed slowly#A flagger stopping traffic"},
{"testId":611,"qid":10256,"description":"THC is the main psychoactive component in marijuana. In Washington State, a driver who is at least 21 years old can be arrested for driving with a THC level of ____ nanograms per milliliter of blood or greater.","explanation":null,"imageName":"","correctAnswer":41392,"isBookmarked":0,"aid":"41392#41393#41394#41395","text":"5.00#4.00#3.00#2.00"},
{"testId":1078,"qid":10256,"description":"THC is the main psychoactive component in marijuana. In Washington State, a driver who is at least 21 years old can be arrested for driving with a THC level of ____ nanograms per milliliter of blood or greater.","explanation":null,"imageName":"","correctAnswer":41392,"isBookmarked":0,"aid":"41392#41393#41394#41395","text":"5.00#4.00#3.00#2.00"},
{"testId":291,"qid":11048,"description":"What can you do to improve your visibility at blind intersections?","explanation":"At blind crossroads, ride in whatever lane position allows you to be spotted as quickly as feasible. This may change depending on the junction.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"22921#24902#28445#30013","text":"Ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible#Ride in the left lane position"},
{"testId":291,"qid":11052,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10887#23083#39499","text":"Slow down before reaching the slippery section#Stop your motorcycle and walk it across the slippery section#Use only your rear brake#Take an alternate route"},
{"testId":291,"qid":11053,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware of your presence?","explanation":"Ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"22921#26553#30013#39500","text":"Ride in the center lane position#Increase your following distance to 5 seconds#Ride in the left lane position#Pass the vehicle as soon as possible"},
{"testId":458,"qid":11053,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware of your presence?","explanation":"Ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"22921#26553#30013#39500","text":"Ride in the center lane position#Increase your following distance to 5 seconds#Ride in the left lane position#Pass the vehicle as soon as possible"},
{"testId":467,"qid":11053,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware of your presence?","explanation":"Ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"22921#26553#30013#39500","text":"Ride in the center lane position#Increase your following distance to 5 seconds#Ride in the left lane position#Pass the vehicle as soon as possible"},
{"testId":291,"qid":11060,"description":"In Oregon, when are you legally authorized to share a lane with another vehicle?","explanation":"A vehicle driver may not deny a motorbike the whole width of the lane in Oregon, although mopeds and motorcycles may lawfully ride two abreast (couple up) in the same lane. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger. ORS 811.385 and 814 .250]","imageName":"","correctAnswer":39502,"isBookmarked":0,"aid":"6334#8358#39502#39503","text":"Never#At an intersection#If the other vehicle is a moped or motorcycle#In bumper-to-bumper traffic"},
{"testId":291,"qid":11061,"description":"You should ride in the ______ lane when being passed by another car.","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#4318","text":"Center#Right#Left#any"},
{"testId":291,"qid":11062,"description":"You should maintain a ___ second following distance behind the vehicle in front of you.","explanation":"According to the Oregon Motorcycle & Moped Manual, you should maintain a four-second following distance behind the vehicle in front of you. This will provide you enough room to halt, swerve, and maintain a respectable distance cushion. In a staggered formation, however, you should leave a two-second trailing gap behind the motorbike in front of you. This allows the group to remain close together.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":291,"qid":11064,"description":"To park along the side of the road, you should park at an angle with your:","explanation":"Your motorbike should be parked by backing it up. You should ride forward out of the parking place with your back tyre towards the curb. Aim your motorbike such that you can see both directions clearly without effort.","imageName":"","correctAnswer":39504,"isBookmarked":0,"aid":"39504#39505#39506#39507","text":"rear wheel to the curb#front wheel to the curb#rear wheel on the curb#front wheel on the curb"},
{"testId":292,"qid":11071,"description":"Maintain a following distance of at least ___ second(s) behind the vehicle ahead of you for enough space cushion.","explanation":"In most riding scenarios, the Motorbike Operator Manual suggests keeping a trailing distance of at least four seconds behind the vehicle (car, motorcycle, etc.) ahead of you. In a staggered formation, however, you should leave a two-second trailing gap behind the motorbike in front of you. This allows the group to remain close together.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":292,"qid":11072,"description":"After how many drinks does your body start to feel the effects of the alcohol?","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":292,"qid":11074,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":39834,"isBookmarked":0,"aid":"24649#39832#39833#39834","text":"Before mounting your motorcycle#At your first stop after you start riding#While you are riding your motorcycle#After mounting your motorcycle"},
{"testId":292,"qid":11075,"description":"One advantage of riding in the center lane is that it:","explanation":"One advantage of riding in the middle lane is that it inhibits other vehicle operators from attempting to share your lane. The middle lane location leaves very little room on each side. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#22835#29469#31460","text":"allows you to turn easily#helps you maintain a consistent speed#discourages others from sharing your lane#keeps you hidden from other vehicle operators"},
{"testId":467,"qid":11075,"description":"One advantage of riding in the center lane is that it:","explanation":"One advantage of riding in the middle lane is that it inhibits other vehicle operators from attempting to share your lane. The middle lane location leaves very little room on each side. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#22835#29469#31460","text":"allows you to turn easily#helps you maintain a consistent speed#discourages others from sharing your lane#keeps you hidden from other vehicle operators"},
{"testId":292,"qid":11076,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#39130#39131#39132","text":"Never#When you're riding through sharp curves#When you're approaching an intersection#When you're riding at night"},
{"testId":292,"qid":11081,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footpegs even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":495,"qid":11081,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footpegs even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":292,"qid":11082,"description":"Make use of both the front and rear brakes:","explanation":"When you come to a complete stop, use both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":7382,"isBookmarked":0,"aid":"7382#9710#10383#39835","text":"every time you stop#in emergency situations#when swerving#in normal stops only"},
{"testId":483,"qid":11082,"description":"Make use of both the front and rear brakes:","explanation":"When you come to a complete stop, use both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":7382,"isBookmarked":0,"aid":"7382#9710#10383#39835","text":"every time you stop#in emergency situations#when swerving#in normal stops only"},
{"testId":292,"qid":11084,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove the front brake and reapply it smoothly to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#10390#24700#39836","text":"use the rear brake only#keep it locked until you stop#release the front brake and reapply it smoothly#release both brakes and slow to a stop"},
{"testId":292,"qid":11085,"description":"You should ______, in a slow, tight turn.","explanation":"The motorbike may lean towards and perhaps tumble over in a slow, tight turn. To counteract this propensity, maintain a straight torso while leaning just on the motorbike.","imageName":"","correctAnswer":10392,"isBookmarked":0,"aid":"6582#10368#10392#10685","text":"Pull in the clutch#lean at the same angle as the motorcycle#keep your body straight while leaning the motorcycle#lean in the opposite direction of the turn"},
{"testId":292,"qid":11086,"description":"Half of all motorcycle accidents are the result of:","explanation":"Cars entering a motorbike's right-of-way cause half of all motorcycle accidents. A vehicle driver may fail to see an approaching motorbike and turn left into its path.","imageName":"","correctAnswer":39838,"isBookmarked":0,"aid":"31770#39837#39838#39839","text":"motorcycles exceeding the speed limit#vehicles following too closely#cars entering the motorcycle's right-of-way#motorcycles not yielding to traffic in the intersection"},
{"testId":292,"qid":11092,"description":"What's the best way to detecting bumps in the road at night?","explanation":"Keep an eye out for the taillights of the vehicle ahead of you. The automobile is most likely traveling over bumps if they seem to bounce up and down.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"22794#28204#39840#39841","text":"Watch the taillights of the car ahead of you#Use your low-beam headlight#Tailgate the car ahead of you#Ride only on paved roads"},
{"testId":293,"qid":11096,"description":"Who is required to wear a helmet in South Carolina?","explanation":"Under South Carolina law, operators and passengers under the age of 21 must wear helmets.","imageName":"","correctAnswer":40445,"isBookmarked":0,"aid":"10423#40444#40445#40446","text":"All passengers#All operators and passengers#Operators under 21 and passengers under 21#Operators under 21"},
{"testId":293,"qid":11097,"description":"Who is required to wear eye protection when riding a motorbike without a windscreen?","explanation":null,"imageName":"","correctAnswer":40446,"isBookmarked":0,"aid":"40444#40445#40446#40447","text":"All operators and passengers#Operators under 21 and passengers under 21#Operators under 21#All operators"},
{"testId":293,"qid":11112,"description":"Head checks should be performed for:","explanation":"Motorcycles, like vehicles, have blind areas. Perform a head check to assess your blind areas before executing moves like as changing lanes. Frequent head checks should be included into your normal road-scanning practice.","imageName":"","correctAnswer":8254,"isBookmarked":0,"aid":"6337#8254#36079#36080","text":"Making turns#checking blind spots#checking the gauges#shifting gears"},
{"testId":293,"qid":11119,"description":"You can lawfully share a lane in South Carolina with:","explanation":null,"imageName":"","correctAnswer":40448,"isBookmarked":0,"aid":"28259#34212#40448#40449","text":"no one#any other vehicles#one other motorcycle#a motorcycle or a small car"},
{"testId":294,"qid":11127,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible to help stabilize the motorcycle; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#6730#34535#40637","text":"sit as far forward as possible#lean opposite to the way you do#lean backward#put his or her feet down when you stop"},
{"testId":294,"qid":11128,"description":"When should you flash your brake light?","explanation":"If you're going to slow down in an area where others may not anticipate it, tap your brake to flash your brake light to alert them.","imageName":"","correctAnswer":6947,"isBookmarked":0,"aid":"6944#6946#6947#40638","text":"Are going to turn left#The speed limit changes#You are slowing down in an unexpected place#You are slowing down in rural areas"},
{"testId":294,"qid":11129,"description":"What can you do to reduce your reaction time?","explanation":"By covering the clutch and brakes, you may reduce your response time.","imageName":"","correctAnswer":35301,"isBookmarked":0,"aid":"10819#11172#28906#35301","text":"Hover your feet above the ground#Do not wear a helmet#Use low gears only#Cover the clutch and the brakes"},
{"testId":294,"qid":11140,"description":"Eye protection with a tint:","explanation":null,"imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":294,"qid":11142,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6309#6310#24831","text":"Swerving from side to side#rising slightly off the seat#taking your feet off the footrests#applying the brakes lightly"},
{"testId":295,"qid":11146,"description":"The use of drugs or alcohol while operating a motorcycle is _______聽in Tennessee.","explanation":"Tennessee's drunk driving rules make no distinction between automobile drivers and motorcycle riders. The operator of any motor-driven vehicle in Tennessee may be prosecuted with DUI. A biker who is convicted of DUI will suffer the same penalty as a vehicle driver.","imageName":"","correctAnswer":10448,"isBookmarked":0,"aid":"10448#10450#40789#40790","text":"always prohibited#is allowed only during the day#allowed more often than is driving a car while impaired#is allowed as long as you're not also driving a car or truck"},
{"testId":295,"qid":11148,"description":"If your motorbike is not fitted with a windscreen in Tennessee, you must:","explanation":null,"imageName":"","correctAnswer":35080,"isBookmarked":0,"aid":"8194#30073#35079#35080","text":"Avoid carrying passengers#ride only on paved roads#have a windshield installed before you ride the motorcycle#wear eye protection"},
{"testId":295,"qid":11150,"description":"When riding in Tennessee, you must wear a helmet:","explanation":"Every rider and passenger in Tennessee is required by law to wear a helmet while riding a motorbike.","imageName":"","correctAnswer":28977,"isBookmarked":0,"aid":"10461#28977#40791#40792","text":"when riding in the city#at all times when riding#when riding on multilane roadways#at all times when riding but only if you're under 18"},
{"testId":295,"qid":11156,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding impediments in your path. Never brake while swerving, only before or after.","imageName":"","correctAnswer":6585,"isBookmarked":0,"aid":"6584#6585#6586#40793","text":"Stopping or sliding#Stopping or swerving#Swerving or sliding#Swerving or skidding"},
{"testId":295,"qid":11159,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#40794#40795","text":"behind the leader#at the end#In the middle#at the front"},
{"testId":295,"qid":11160,"description":"You should _____ to  transport cargo.","explanation":"Keep the load modest and balanced while transporting items. Maintaining a large load raises the motorcycle's center of gravity and disrupts its balance.","imageName":"","correctAnswer":6576,"isBookmarked":0,"aid":"6574#6576#6577#40796","text":"weigh it before loading it on the motorcycle#keep the load low#not carry a passenger as well#secure it only to the sides of the motorcycle"},
{"testId":295,"qid":11163,"description":"The most appropriate method of dealing with tailgaters is to:","explanation":null,"imageName":"202006041812161292.jpg","correctAnswer":8180,"isBookmarked":0,"aid":"6286#6392#6475#8180","text":"Flash your brake light#Call the police#Speed up#get them in front of you"},
{"testId":295,"qid":11165,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, try twisting it back and forth. If it doesn't work, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel. Pull off the road and come to a complete stop once you have regained control of your motorcycle.","imageName":"","correctAnswer":40798,"isBookmarked":0,"aid":"28367#38695#40797#40798","text":"Apply the rear brake#Operate the engine cut-off switch#Apply the front brake#Twist it back and forth to free it"},
{"testId":295,"qid":11169,"description":"The quickest method to come to a halt is to:","explanation":"To come to a complete stop, use both the front and back brakes firmly without locking any wheel.","imageName":"","correctAnswer":31164,"isBookmarked":0,"aid":"6725#8049#10601#31164","text":"Apply the rear brake only#apply the front brake quickly#Pull the engine kill switch#apply both the front and rear brakes firmly"},
{"testId":295,"qid":11170,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks, say every two hours, is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7289#8300#10572#40799","text":"take frequent rest breaks#Drink lots of fluids#Ride only during the day#avoid wearing goggles or a face shield"},
{"testId":296,"qid":11171,"description":"When is a Texas motorcycle rider who is at least 21 years old exempt from wearing a helmet while operating a motorbike?","explanation":"Under Texas law, if you are under the age of 21, you must wear a helmet. If you are at least 21 years old and have finished an authorized motorcycle training course or are insured by a medical insurance plan for any injuries sustained in an accident, you are not needed to wear a helmet.","imageName":"","correctAnswer":40958,"isBookmarked":0,"aid":"40956#40957#40958#40959","text":"(a) If he or she has completed an approved motorcycle training course#(b) If he or she is covered by medical insurance for injuries sustained in accidents#Either (a) or (b)#Neither (a) or (b)"},
{"testId":296,"qid":11172,"description":"If you are under the age of 21 in Texas,:","explanation":"If you are under the age of 21, you must wear a helmet while riding a motorbike in Texas. Motorcyclists of any age are not required by Texas law to wear eye protection.","imageName":"","correctAnswer":10478,"isBookmarked":0,"aid":"10478#40960#40961#40962","text":"you must wear a helmet when riding a motorcycle#you may not ride a motorcycle#you must wear eye protection when riding a motorcycle#you may not carry passengers"},
{"testId":296,"qid":11173,"description":"When is it permissible to park a motorcycle in a disability parking space?","explanation":"In Texas, unless you have a disability license plate or windshield identification card, you may not park your motorbike in a handicapped parking area.","imageName":"","correctAnswer":40963,"isBookmarked":0,"aid":"38479#38480#38481#40963","text":"Only if you're parking for five minutes or less#Only if you're unloading cargo#Only if you're dropping off a passenger#Only if you have a license plate or identification card for persons with disabilities"},
{"testId":296,"qid":11174,"description":"According to Texas law, if a motorcycle was manufactured after 1975,:","explanation":null,"imageName":"","correctAnswer":40965,"isBookmarked":0,"aid":"10485#10486#40964#40965","text":"it requires a special license plate#it requires a yearly mechanical inspection#you may not ride it at night#you must keep the headlight on whenever you ride"},
{"testId":296,"qid":11175,"description":"When riding at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":296,"qid":11179,"description":"If another vehicle is passing you, you should ride in the ______ lane.","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7693","text":"Center#Right#Left#left or center"},
{"testId":296,"qid":11180,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":296,"qid":11184,"description":"If your front wheel becomes locked, you should:","explanation":"A locked front wheel is dangerous. If your front wheel locks, remove it and reapply it forcefully while also applying the back brake.","imageName":"","correctAnswer":40966,"isBookmarked":0,"aid":"6515#7326#40966#40967","text":"Increase your speed#Pull the kill engine switch#release the front brake and reapply it firmly#keep the front wheel locked until you come to a complete stop"},
{"testId":296,"qid":11185,"description":"To transport cargo in saddlebags,:","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":6671,"isBookmarked":0,"aid":"6671#7783#40968#40969","text":"distribute the weight evenly between them#secure the saddlebags with rope#make sure the saddlebags are full#do not carry a passenger at the same time"},
{"testId":296,"qid":11187,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7430#40970#40971#40972","text":"they want to pass you#you're riding uphill#you're riding on a multilane highway#you're riding in a residential area"},
{"testId":296,"qid":11190,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":296,"qid":11195,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":297,"qid":11196,"description":"The most common cause of motorcycle accidents in Utah in 2014 was:","explanation":"Speeding was the cause of 12.0 percent of motorcycle accidents in Utah in 2014. Failure to remain in the appropriate lane caused 11.2 percent of accidents, while tailgating other cars caused 11.1 percent.","imageName":"","correctAnswer":27183,"isBookmarked":0,"aid":"27183#41096#41097#41098","text":"speeding#tailgating other vehicles#failing to stay in the proper lane#driving off the road"},
{"testId":297,"qid":11197,"description":"Your motorcycle's handlebars may not exceed ________ in Utah.","explanation":"Handlebars that raise your hands over shoulder height (\"ape hangers\") are illegal in Utah.","imageName":"","correctAnswer":35291,"isBookmarked":0,"aid":"35289#35290#35291#35292","text":"elbow height#chest height#shoulder height#waist height"},
{"testId":297,"qid":11198,"description":"Who must wear a helmet while riding a motorcycle in Utah?","explanation":null,"imageName":"","correctAnswer":41102,"isBookmarked":0,"aid":"41099#41100#41101#41102","text":"Every motorcyclist with a motorcycle learner permit#Every motorcyclist and passenger#Every motorcyclist and passenger under the age of 18#Every motorcyclist and passenger under the age of 21"},
{"testId":297,"qid":11200,"description":"Starting on May 15, 2019, it will be illegal to ride between lanes in Utah:","explanation":"Riding between two lanes of traffic (also known as lane splitting) is forbidden in Utah. However, as of May 15, 2019, it is permitted to ride at no more than 15 mph between two lanes of stopped traffic or pass a stopped vehicle in your lane (a practice known as lane filtering), but only if the speed limit on the road is no more than 45 mph.","imageName":"","correctAnswer":42356,"isBookmarked":0,"aid":"8247#8250#41103#42356","text":"never permitted#permitted only in designated areas#permitted except in group riding#permitted only if traffic is stopped"},
{"testId":297,"qid":11201,"description":"Is it legal for motorcycles to ride side by side in a single lane?","explanation":"Motorcycles may lawfully go two abreast (pair up) in the same lane in Utah. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":37907,"isBookmarked":0,"aid":"21234#32812#37907#41104","text":"Yes#Yes, but only in rural areas#Yes, but only two abreast#Yes, but no more than three abreast"},
{"testId":297,"qid":11205,"description":"Taking hold of the front brake lever:","explanation":"The front wheel might lock if you grab the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":41105,"isBookmarked":0,"aid":"6361#6362#22246#41105","text":"is the most effective way to stop#should only be done in emergencies#is only possible when you are already applying the rear brake#can cause the front wheel to lock"},
{"testId":297,"qid":11215,"description":"In聽Utah,聽it聽is聽advised聽that聽you聽maintain聽a聽minimum聽following聽distance聽of聽_________聽behind聽the聽car聽in聽front聽of聽you.","explanation":"According to the Texas Motorcycle Operator Manual, you should maintain a following distance of at least two seconds behind the vehicle ahead. This will offer you the smallest amount of room to safely stop or swerve if the car ahead of you suddenly stops. Increase your following distance to three seconds or more if your motorbike will take longer to stop than normal.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":297,"qid":11216,"description":"When is it permissible in Utah to share a lane with another vehicle?","explanation":"Motorcycles may lawfully go two abreast (pair up) in the same lane in Utah. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":35515,"isBookmarked":0,"aid":"6334#6769#8087#35515","text":"Never#In heavy traffic#In designated areas#If the other vehicle is a motorcycle"},
{"testId":297,"qid":11219,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7136","text":"Left#Center#Right#Left or center"},
{"testId":300,"qid":11219,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7136","text":"Left#Center#Right#Left or center"},
{"testId":298,"qid":11221,"description":"Is it legal in Vermont for motorcycles to ride in the same lane as cars?","explanation":null,"imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#32812#37907#41039","text":"No#Yes, but only in rural areas#Yes, but only two abreast#Yes, but at most three abreast"},
{"testId":298,"qid":11224,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect critical components such as the tires, headlamp, signal lights, and chassis.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#40974#40975","text":"All of the above#The tires#The lights#The chassis"},
{"testId":507,"qid":11224,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect critical components such as the tires, headlamp, signal lights, and chassis.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#40974#40975","text":"All of the above#The tires#The lights#The chassis"},
{"testId":298,"qid":11225,"description":"If your motorcycle is equipped with a windscreen in Vermont,:","explanation":null,"imageName":"","correctAnswer":41042,"isBookmarked":0,"aid":"41040#41041#41042#41043","text":"you鈥檙e required to wear eye protection#you're not required to wear a helmet#you're not required to wear eye protection#your passenger is not required to wear a helmet"},
{"testId":298,"qid":11226,"description":"Helmets are ________ in Vermont.","explanation":null,"imageName":"","correctAnswer":41047,"isBookmarked":0,"aid":"41044#41045#41046#41047","text":"not required if you're at least 21 years old#not required if you're at least 18 years old#not required if you've completed a Vermont Rider Education Program (VREP) course#required"},
{"testId":298,"qid":11227,"description":"When performing a gradual, tight turn, you should:","explanation":"Maintain a straight body while leaning the motorbike into gradual, tight spins. This will counteract the motorcycle's inclination to overturn.","imageName":"","correctAnswer":10392,"isBookmarked":0,"aid":"6761#10392#10686#22249","text":"lean forward#keep your body straight while leaning the motorcycle#lean in the opposite direction of the motorcycle#lean in the same direction as the motorcycle"},
{"testId":298,"qid":11230,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":298,"qid":11233,"description":"When should your mirrors be adjusted?","explanation":"Adjust your mirrors after mounting your motorbike but before you begin riding. Adjusting them while riding might be risky.","imageName":"","correctAnswer":7516,"isBookmarked":0,"aid":"7513#7514#7516#31749","text":"Before mounting your motorcycle#While the motorcycle is still on the stand#After mounting the motorcycle#At the first stop after you start to ride"},
{"testId":298,"qid":11234,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, gently shut the throttle and allow it to calm down. Braking or accelerating to get out of the wobble might make matters worse.","imageName":"","correctAnswer":38701,"isBookmarked":0,"aid":"10710#38700#38701#38702","text":"Pull the engine kill switch#Accelerate out of the wobble#Close the throttle gradually#Stop by applying only the rear brake"},
{"testId":298,"qid":11238,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents happen on short excursions at speeds of less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":298,"qid":11244,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only climb on a motorbike once the rider has mounted and started it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7705#8153#24934","text":"Before the engine has been started#While it is still on the kickstand#After the engine has been started#Before the motorcyclist"},
{"testId":298,"qid":11245,"description":"Your feet should be _______ while biking.","explanation":"While cycling, keep your feet firmly planted on the footrests. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":31760,"isBookmarked":0,"aid":"6176#7735#31760#36102","text":"as far forward as possible#hovering above the ground in case you tip#positioned firmly on the footrests#pointing downward for easy shifting"},
{"testId":491,"qid":11245,"description":"Your feet should be _______ while biking.","explanation":"While cycling, keep your feet firmly planted on the footrests. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":31760,"isBookmarked":0,"aid":"6176#7735#31760#36102","text":"as far forward as possible#hovering above the ground in case you tip#positioned firmly on the footrests#pointing downward for easy shifting"},
{"testId":299,"qid":11246,"description":"A passenger in Virginia is:","explanation":null,"imageName":"","correctAnswer":10541,"isBookmarked":0,"aid":"7042#10541#10543#41314","text":"not required to wear a helmet#required to wear a helmet#required to take a riding course#required to wear a helmet only if he or she is under 21"},
{"testId":299,"qid":11247,"description":"In Virginia, if your motorcycle does not have a windshield, you must :","explanation":null,"imageName":"","correctAnswer":35080,"isBookmarked":0,"aid":"8194#30073#35079#35080","text":"Avoid carrying passengers#ride only on paved roads#have a windshield installed before you ride the motorcycle#wear eye protection"},
{"testId":299,"qid":11248,"description":"When riding in a group, the optimal arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room. Ride in single file when you need to change lanes, such as while navigating bends, turning, or entering or exiting a highway.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":299,"qid":11249,"description":"You're stopped at a red light that doesn't seem to have been turned on by your motorcycle. There are pedestrians or other vehicles around. When is it okay to go through a red light?","explanation":null,"imageName":"","correctAnswer":41317,"isBookmarked":0,"aid":"41106#41315#41316#41317","text":"(a) After you've waited for two full light cycles#(b) After you've waited for two minutes#Immediately#Either (a) or (b)"},
{"testId":299,"qid":11260,"description":"What is the most secure manner to carry saddlebags?","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":8363,"isBookmarked":0,"aid":"8363#8366#38889#41318","text":"Distribute the weight evenly between them#Never carry a passenger at the same time#Use only one saddlebag at a time#Make sure the saddlebags are full"},
{"testId":299,"qid":11263,"description":"The chain on your motorcycle should be inspected,:","explanation":"According to the Virginia Motorcycle Operator Manual, you should inspect your motorcycle's chain at least once a week.","imageName":"","correctAnswer":8239,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":495,"qid":11263,"description":"The chain on your motorcycle should be inspected,:","explanation":"According to the Virginia Motorcycle Operator Manual, you should inspect your motorcycle's chain at least once a week.","imageName":"","correctAnswer":8239,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":299,"qid":11266,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles are unsafe at the road's side because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"6594#7845#24857#26509","text":"the right lane position has the most hazards#dirt and gravel provide little traction#the pavement is of poor quality#visibility is poor there"},
{"testId":299,"qid":11268,"description":"If you're riding in a group and need to pass the vehicle ahead of you, you should:","explanation":"When cycling in a group, bikers should pass one at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#41319#41320","text":"pass in pairs#pass one rider at a time#pass as many at once as is safe#not pass while riding in a group"},
{"testId":299,"qid":11270,"description":"The most effective strategy to avoid fatigue while riding a motorcycle is to:","explanation":"Taking frequent rest breaks, say every two hours, is the best way to avoid fatigue while riding a motorcycle. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7289#8300#10572#40799","text":"take frequent rest breaks#Drink lots of fluids#Ride only during the day#avoid wearing goggles or a face shield"},
{"testId":300,"qid":11271,"description":"Motorcycle handlebars _____, under Washington State law.","explanation":null,"imageName":"","correctAnswer":41465,"isBookmarked":0,"aid":"41464#41465#41466#41467","text":"must not be higher than 15 inches above the seat#must not be higher than 30 inches above the seat#must not be higher than your shoulders#can be of any height"},
{"testId":300,"qid":11272,"description":"When parking beside a curb, you must:","explanation":"When parking near a curb, park at an angle with your back wheel contacting the curb. This will enable you to escape while seeing both directions.","imageName":"","correctAnswer":10557,"isBookmarked":0,"aid":"10555#10557#41468#41469","text":"park parallel to the curb#park at an angle with your rear wheel touching the curb#park at an angle with your front wheel touching the curb#turn your hazard lights on"},
{"testId":300,"qid":11275,"description":"What should you do to safely stop in a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt. Stopping while leaning might be risky.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"8296#8297#30098#40973","text":"Take your feet off the foot pegs for balance#Keep your knees against the gas tank#Try to straighten your motorcycle upright#Lean the opposite way of the motorcycle"},
{"testId":300,"qid":11278,"description":"You should _____ to use caution when crossing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":6582,"isBookmarked":0,"aid":"6582#7698#7700#26438","text":"Pull in the clutch#use the highest gear possible#avoid using the rear brake#use your low-beam headlight"},
{"testId":300,"qid":11279,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":31795,"isBookmarked":0,"aid":"6736#7111#31795#41470","text":"single file#in pairs#a staggered formation#an alternate formation"},
{"testId":300,"qid":11280,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective gear does not reduce your odds of being involved in an accident, but it does assist protect you if you are.","imageName":"","correctAnswer":33189,"isBookmarked":0,"aid":"10801#10802#15460#33189","text":"Maintaining a space cushion#Scanning your path of travel#Using your turn signals#Wearing sturdy clothing"},
{"testId":300,"qid":11282,"description":"To function safely, a motorcycle need ____.","explanation":"Motorcycles, while being smaller than vehicles, need a full lane width to function properly.","imageName":"","correctAnswer":41474,"isBookmarked":0,"aid":"41471#41472#41473#41474","text":"a quarter of a lane width#a third of a lane width#half a lane width#a full lane width"},
{"testId":300,"qid":11283,"description":"Which of the following surfaces is the most dangerous for riding a motorcycle?","explanation":"Motorcycles are dangerous on slick conditions. Because precipitation causes oil deposits in the asphalt to become more lubricated, wet pavement is most dangerous shortly after it starts to rain. This very slick situation won't end until more rain removes those oil deposits.","imageName":"","correctAnswer":7781,"isBookmarked":0,"aid":"7779#7780#7781#7782","text":"Hot pavement in the sun#Dry, cold pavement#Wet pavement right after it has started to rain#Pavement in northern climates"},
{"testId":300,"qid":11285,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only climb on a motorbike once the rider has mounted and started it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7705#8153#24934","text":"Before the engine has been started#While it is still on the kickstand#After the engine has been started#Before the motorcyclist"},
{"testId":300,"qid":11286,"description":"If your motorcycle does not have a windshield, you must_______ install one in Washington State.","explanation":null,"imageName":"","correctAnswer":35080,"isBookmarked":0,"aid":"8194#30073#35079#35080","text":"Avoid carrying passengers#ride only on paved roads#have a windshield installed before you ride the motorcycle#wear eye protection"},
{"testId":300,"qid":11287,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22798#24618#40980#41475","text":"Roll away from the motorcycle#Wear protective motorcycle clothing and gear#Try to jump off the motorcycle before you hit the ground#Tip the motorcycle if you know you're about to crash"},
{"testId":300,"qid":11288,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":null,"imageName":"","correctAnswer":24756,"isBookmarked":0,"aid":"7204#8224#8615#24756","text":"A windshield#special shocks#All of the above#foot pegs for the passenger"},
{"testId":300,"qid":11291,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#7864#8001#31788","text":"right foot pedal#front brake lever#left hand lever#left foot lever"},
{"testId":301,"qid":11303,"description":"The rear brake is operated by the:","explanation":"The right hand lever controls the front brake. The right foot pedal operates the back brake.","imageName":"","correctAnswer":7117,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":301,"qid":11307,"description":"What could be causing your motorcycle to lurch?","explanation":"Make sure your motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":7602,"isBookmarked":0,"aid":"7600#7602#8172#30519","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Turning too fast#Riding over gravel roads"},
{"testId":301,"qid":11311,"description":"To operate properly, a motorcycle requires _________ lane width.","explanation":"Motorcycles, while being smaller than vehicles, need a full lane width to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6473#6474","text":"a full#a third of a#half a#a quarter of a"},
{"testId":301,"qid":11316,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. You should turn toward the outside of the curve after the curve's midpoint. Your path's effective radius will grow as a result.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#8213#10833#10834","text":"Apply the rear brake#maintain a right lane position#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":301,"qid":11318,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":302,"qid":11318,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":302,"qid":11323,"description":"How can you maximize stopping power?","explanation":"Maximum stopping power may be obtained by completely using both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":43794,"isBookmarked":0,"aid":"11174#22821#28897#43794","text":"Pull in the clutch while you brake#Apply only the front brake#Alternate between the front brake and the rear brake#Apply both the front and rear brakes at the same time"},
{"testId":302,"qid":11324,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"Each traffic lane features three lane places where a motorbike may travel: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":302,"qid":11331,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10677#10866","text":"45-degree#20-degree#90-degree#135-degree"},
{"testId":302,"qid":11334,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6846#6847#6996#33955","text":"changing lanes#avoiding hazards from parked cars#being seen by the driver ahead of you#preparing to pass the vehicle ahead of you"},
{"testId":303,"qid":11346,"description":"How much of a traffic lane does a motorcycle require to operate safely?","explanation":"A motorcycle, despite being smaller than a car, requires a full lane width to operate safely. A motorbike is legally entitled to the whole width of the lane.","imageName":"","correctAnswer":7075,"isBookmarked":0,"aid":"7074#7075#7076#7077","text":"Half a lane width#A full lane width#A third of a lane width#A quarter of a lane width"},
{"testId":303,"qid":11347,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#24702#24703#28221","text":"ride under 30 mph#ride only in residential areas#ride only in first gear#you must not ride a motorcycle"},
{"testId":511,"qid":11347,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#24702#24703#28221","text":"ride under 30 mph#ride only in residential areas#ride only in first gear#you must not ride a motorcycle"},
{"testId":303,"qid":11349,"description":"The handlebars on your motorcycle must be no taller than:","explanation":"Handlebars that raise your hands over shoulder height are illegal in Wyoming.","imageName":"","correctAnswer":35291,"isBookmarked":0,"aid":"35289#35290#35291#35292","text":"elbow height#chest height#shoulder height#waist height"},
{"testId":303,"qid":11350,"description":"When prepared to pass a vehicle in front of you, ride in ______ lane.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":303,"qid":11353,"description":"When parking on the side of the road, you should park :","explanation":"According to the Wyoming Rules of the Road for Motorcyclists, you should park at a 90-degree angle with your back tire contacting the curb.","imageName":"","correctAnswer":35296,"isBookmarked":0,"aid":"35293#35294#35295#35296","text":"at a 45-degree angle, with your front tire touching the curb#parallel to the curb, with both tires touching the curb#parallel to the curb, with neither tire touching the curb#at a 90-degree angle, with your rear tire touching the curb"},
{"testId":303,"qid":11354,"description":"Texting while riding is _____ in Wyoming:","explanation":null,"imageName":"","correctAnswer":35298,"isBookmarked":0,"aid":"22874#35297#35298#35299","text":"allowed in designated areas#never allowed#allowed only in emergency situations#allowed at designated times"},
{"testId":303,"qid":11355,"description":"Who determines the group's pace when riding in a group?","explanation":"When biking in a group, the tailender dictates the pace. If the person in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":35300,"isBookmarked":0,"aid":"6578#7625#7627#35300","text":"The group leader#The rider with the least experience#Whoever rides the fastest#The sweep rider (tailender)"},
{"testId":303,"qid":11358,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten the motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":29483,"isBookmarked":0,"aid":"10835#10836#22273#29483","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten the motorcycle upright"},
{"testId":303,"qid":11360,"description":"During your ride, the front tire becomes flat. To decelerate, you should:","explanation":"If your front tire becomes flat, use the rear brake to slow down. Never use your brakes on a flat tire.","imageName":"","correctAnswer":6423,"isBookmarked":0,"aid":"6423#6569#6582#31486","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Apply both the front and rear brakes"},
{"testId":303,"qid":11362,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":31782,"isBookmarked":0,"aid":"6423#6569#30821#31782","text":"Apply the rear brake#Apply the front brake#Operate the engine cut-off switch#Try to twist it back and forth"},
{"testId":303,"qid":11364,"description":"What can you do to reduce your reaction time?","explanation":"When approaching an intersection, shorten your response time by covering the clutch and brakes so you can stop fast.","imageName":"","correctAnswer":35301,"isBookmarked":0,"aid":"10818#10819#30514#35301","text":"Decrease your following distance#Hover your feet above the ground#Lock your elbows#Cover the clutch and the brakes"},
{"testId":303,"qid":11366,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch lever is on the left handlebar, while the front brake lever is on the right.","imageName":"","correctAnswer":31776,"isBookmarked":0,"aid":"31773#31774#31775#31776","text":"The clutch is on the right and the front brake lever is on the left#The clutch and front brake lever are both on the left#The clutch and front brake lever are both on the right#The clutch is on the left and the front brake lever is on the right"},
{"testId":303,"qid":11367,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Rolling on the throttle to maintain or gain speed can assist stabilize the motorbike in a turn.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":303,"qid":11369,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect the tires, signal lights, and oil and other fluids.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27170#35302","text":"All of the above#The tires#The signal lights#The oil and other fluids"},
{"testId":52,"qid":11372,"description":"You should usually ride your motorcycle _____ within your lane when crossing railroad tracks.","explanation":null,"imageName":"","correctAnswer":6153,"isBookmarked":0,"aid":"6153#6154#10570#10571","text":"straight#by leaning into a side#at a 10-degree angle#at a 20-degree angle"},
{"testId":54,"qid":11374,"description":"What kind of clothing is better for ridingduring the day?","explanation":"Wear neon apparel when riding during the day to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":6277,"isBookmarked":0,"aid":"6274#6275#6276#6277","text":"Leather clothing#Colorful clothing#Any type of clothing#Fluorescent clothing"},
{"testId":334,"qid":11374,"description":"What kind of clothing is better for ridingduring the day?","explanation":"Wear neon apparel when riding during the day to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":6277,"isBookmarked":0,"aid":"6274#6275#6276#6277","text":"Leather clothing#Colorful clothing#Any type of clothing#Fluorescent clothing"},
{"testId":471,"qid":11374,"description":"What kind of clothing is better for ridingduring the day?","explanation":"Wear neon apparel when riding during the day to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":6277,"isBookmarked":0,"aid":"6274#6275#6276#6277","text":"Leather clothing#Colorful clothing#Any type of clothing#Fluorescent clothing"},
{"testId":475,"qid":11374,"description":"What kind of clothing is better for ridingduring the day?","explanation":"Wear neon apparel when riding during the day to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":6277,"isBookmarked":0,"aid":"6274#6275#6276#6277","text":"Leather clothing#Colorful clothing#Any type of clothing#Fluorescent clothing"},
{"testId":61,"qid":11376,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, change lanes or slow down to let the tailgater to pass you. With the tailgater in front of you, you'll be safer. Don't try to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":33608,"isBookmarked":0,"aid":"6278#6286#6475#33608","text":"Beep your horn#Flash your brake light#Speed up#let the tailgater pass you"},
{"testId":298,"qid":11376,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, change lanes or slow down to let the tailgater to pass you. With the tailgater in front of you, you'll be safer. Don't try to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":33608,"isBookmarked":0,"aid":"6278#6286#6475#33608","text":"Beep your horn#Flash your brake light#Speed up#let the tailgater pass you"},
{"testId":301,"qid":11376,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, change lanes or slow down to let the tailgater to pass you. With the tailgater in front of you, you'll be safer. Don't try to outrun or outrun the tailgater by increasing your speed. Some tailgaters believe that no speed is too fast.","imageName":"202006041812161292.jpg","correctAnswer":33608,"isBookmarked":0,"aid":"6278#6286#6475#33608","text":"Beep your horn#Flash your brake light#Speed up#let the tailgater pass you"},
{"testId":60,"qid":11377,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#40126","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#only when other vehicles are around"},
{"testId":507,"qid":11377,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#40126","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#only when other vehicles are around"},
{"testId":60,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":302,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":318,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":347,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":503,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":511,"qid":11378,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":60,"qid":11379,"description":"When packing saddlebags,you should:","explanation":"When filling saddlebags, load them uniformly and with the same weight to keep your motorbike balanced.","imageName":"","correctAnswer":6814,"isBookmarked":0,"aid":"6814#6817#24839#24840","text":"load them evenly with the same weight#fill one completely before loading others#fill all of them completely#not exceed 20 pounds per bag"},
{"testId":61,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":330,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":343,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":375,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":431,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":499,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":511,"qid":11380,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of the wobble might exacerbate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":314,"qid":11381,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear luminous apparel to boost your chances of being spotted by another vehicle operator.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6941#10572#10573#30062","text":"wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles#avoid riding in heavy traffic"},
{"testId":347,"qid":11381,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear luminous apparel to boost your chances of being spotted by another vehicle operator.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6941#10572#10573#30062","text":"wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles#avoid riding in heavy traffic"},
{"testId":471,"qid":11381,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear luminous apparel to boost your chances of being spotted by another vehicle operator.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6941#10572#10573#30062","text":"wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles#avoid riding in heavy traffic"},
{"testId":467,"qid":11382,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, you should bike in lane two (middle) or three (right) to avoid them.","imageName":"","correctAnswer":29452,"isBookmarked":0,"aid":"28388#28390#28391#29452","text":"One (left) or two (center)#Two (center)#Three (right)#Two (center) or three (right)"},
{"testId":296,"qid":11383,"description":"Because motorcycle_____________, it is less noticeable than a car.","explanation":"Because of its lower profile, a motorbike is less noticeable than a vehicle. Take additional precautions to be visible when riding.","imageName":"","correctAnswer":7728,"isBookmarked":0,"aid":"7727#7728#35529#35530","text":"has a quieter engine#has a smaller profile#has fewer lights#travels faster"},
{"testId":297,"qid":11383,"description":"Because motorcycle_____________, it is less noticeable than a car.","explanation":"Because of its lower profile, a motorbike is less noticeable than a vehicle. Take additional precautions to be visible when riding.","imageName":"","correctAnswer":7728,"isBookmarked":0,"aid":"7727#7728#35529#35530","text":"has a quieter engine#has a smaller profile#has fewer lights#travels faster"},
{"testId":425,"qid":11383,"description":"Because motorcycle_____________, it is less noticeable than a car.","explanation":"Because of its lower profile, a motorbike is less noticeable than a vehicle. Take additional precautions to be visible when riding.","imageName":"","correctAnswer":7728,"isBookmarked":0,"aid":"7727#7728#35529#35530","text":"has a quieter engine#has a smaller profile#has fewer lights#travels faster"},
{"testId":495,"qid":11383,"description":"Because motorcycle_____________, it is less noticeable than a car.","explanation":"Because of its lower profile, a motorbike is less noticeable than a vehicle. Take additional precautions to be visible when riding.","imageName":"","correctAnswer":7728,"isBookmarked":0,"aid":"7727#7728#35529#35530","text":"has a quieter engine#has a smaller profile#has fewer lights#travels faster"},
{"testId":487,"qid":11385,"description":"How should you cross railroad tracks that are parallel to your course if you need to do so?","explanation":"Cross railroad lines parallel to your path at an angle of at least 45 degrees and then make a purposeful turn. If you cross at a shallower angle, your tires may snag and throw you off balance.","imageName":"","correctAnswer":41112,"isBookmarked":0,"aid":"41110#41111#41112#41113","text":"Wait until the next intersection#Cross at the smallest angle possible#Cross the tracks at a 45-degree angle#Cross the tracks at a 90-degree angle"},
{"testId":68,"qid":11388,"description":"Your motorcycle's cargo should be secured:","explanation":"Elastic ropes should be used to secure cargo on your motorbike. Rope should not be used to secure cargo because it may become loose or knots can fall undone.","imageName":"","correctAnswer":7420,"isBookmarked":0,"aid":"7417#7418#7419#7420","text":"with rope#on the sissy bar#behind the rear axle#with elastic cords"},
{"testId":371,"qid":11388,"description":"Your motorcycle's cargo should be secured:","explanation":"Elastic ropes should be used to secure cargo on your motorbike. Rope should not be used to secure cargo because it may become loose or knots can fall undone.","imageName":"","correctAnswer":7420,"isBookmarked":0,"aid":"7417#7418#7419#7420","text":"with rope#on the sissy bar#behind the rear axle#with elastic cords"},
{"testId":495,"qid":11388,"description":"Your motorcycle's cargo should be secured:","explanation":"Elastic ropes should be used to secure cargo on your motorbike. Rope should not be used to secure cargo because it may become loose or knots can fall undone.","imageName":"","correctAnswer":7420,"isBookmarked":0,"aid":"7417#7418#7419#7420","text":"with rope#on the sissy bar#behind the rear axle#with elastic cords"},
{"testId":334,"qid":11389,"description":"If you must navigate a slick surface, you should:","explanation":"If you must coast over a slick area, let your feet to skim the surface as well. You may use your feet to keep your motorbike from tipping over.","imageName":"","correctAnswer":7481,"isBookmarked":0,"aid":"6423#7480#7481#10584","text":"Apply the rear brake#Lean back as far as possible#Allow your feet to skim the surface#Rise slightly off the seat"},
{"testId":438,"qid":11389,"description":"If you must navigate a slick surface, you should:","explanation":"If you must coast over a slick area, let your feet to skim the surface as well. You may use your feet to keep your motorbike from tipping over.","imageName":"","correctAnswer":7481,"isBookmarked":0,"aid":"6423#7480#7481#10584","text":"Apply the rear brake#Lean back as far as possible#Allow your feet to skim the surface#Rise slightly off the seat"},
{"testId":442,"qid":11389,"description":"If you must navigate a slick surface, you should:","explanation":"If you must coast over a slick area, let your feet to skim the surface as well. You may use your feet to keep your motorbike from tipping over.","imageName":"","correctAnswer":7481,"isBookmarked":0,"aid":"6423#7480#7481#10584","text":"Apply the rear brake#Lean back as far as possible#Allow your feet to skim the surface#Rise slightly off the seat"},
{"testId":310,"qid":11392,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both the front and rear brakes at all times. This is the most efficient form of braking.","imageName":"","correctAnswer":28397,"isBookmarked":0,"aid":"7522#10591#24769#28397","text":"using the front brake only in emergencies#alternating between the front and rear brakes#using only the front brake in most stopping situations#using both the front and rear brakes every time you stop"},
{"testId":495,"qid":11392,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both the front and rear brakes at all times. This is the most efficient form of braking.","imageName":"","correctAnswer":28397,"isBookmarked":0,"aid":"7522#10591#24769#28397","text":"using the front brake only in emergencies#alternating between the front and rear brakes#using only the front brake in most stopping situations#using both the front and rear brakes every time you stop"},
{"testId":483,"qid":11393,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":503,"qid":11393,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":296,"qid":11394,"description":"If you take a bend too quickly, you may end up:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":318,"qid":11394,"description":"If you take a bend too quickly, you may end up:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":330,"qid":11394,"description":"If you take a bend too quickly, you may end up:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":487,"qid":11394,"description":"If you take a bend too quickly, you may end up:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":511,"qid":11394,"description":"If you take a bend too quickly, you may end up:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":60,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":81,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":293,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":303,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":314,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":343,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":454,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":467,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":479,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":495,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":507,"qid":11395,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":420,"qid":11396,"description":"What's the best way to detecting bumps in the road at night?","explanation":"Keep an eye out for the taillights of the vehicle ahead of you. The automobile is most likely traveling over bumps if they seem to bounce up and down.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"10581#22794#22795#28204","text":"Wear reflective clothing#Watch the taillights of the car ahead of you#Stay in low gears#Use your low-beam headlight"},
{"testId":491,"qid":11397,"description":"The front brake is more crucial because:","explanation":"The front brake is more critical since it delivers about 70% of the stopping force. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7898,"isBookmarked":0,"aid":"7897#7898#7899#7900","text":"it provides all of the stopping power#it provides most of the stopping power#it is used most often#it provides less of the stopping power"},
{"testId":429,"qid":11399,"description":"Motorcycle riders must approach junctions with caution. Since bikes :","explanation":"When approaching a junction, motorcycle riders must use extreme care. Motorcycles seem to be farther away than they are because they are smaller than vehicles. At junctions, automobile drivers making left turns against incoming motorbikes often overestimate their turn time.","imageName":"","correctAnswer":33218,"isBookmarked":0,"aid":"8006#8008#33218#35522","text":"appear closer than they are#are much faster than cars#appear farther away than they are#are much slower than cars"},
{"testId":310,"qid":11400,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your riding skills.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":318,"qid":11400,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your riding skills.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":330,"qid":11400,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your riding skills.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":507,"qid":11400,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your riding skills.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":80,"qid":11403,"description":"What tire inspections should you perform?","explanation":"Before mounting your motorcycle, you should check your tires for worn treads and any cracks or scrapes that could cause a tire to blow out. Also check the tire pressures.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#31793","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressures"},
{"testId":299,"qid":11403,"description":"What tire inspections should you perform?","explanation":"Before mounting your motorcycle, you should check your tires for worn treads and any cracks or scrapes that could cause a tire to blow out. Also check the tire pressures.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#31793","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressures"},
{"testId":434,"qid":11403,"description":"What tire inspections should you perform?","explanation":"Before mounting your motorcycle, you should check your tires for worn treads and any cracks or scrapes that could cause a tire to blow out. Also check the tire pressures.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#31793","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressures"},
{"testId":507,"qid":11403,"description":"What tire inspections should you perform?","explanation":"Before mounting your motorcycle, you should check your tires for worn treads and any cracks or scrapes that could cause a tire to blow out. Also check the tire pressures.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#31793","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressures"},
{"testId":442,"qid":11404,"description":"When starting your motorcycle uphill, use _______ brake to keep the motorcycle stable while you start the engine and move into first gear.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your motorbike stable while you use your right hand to control the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":8258,"isBookmarked":0,"aid":"8257#8258#8259#8260","text":"the rear#the front#either#neither"},
{"testId":450,"qid":11404,"description":"When starting your motorcycle uphill, use _______ brake to keep the motorcycle stable while you start the engine and move into first gear.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your motorbike stable while you use your right hand to control the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":8258,"isBookmarked":0,"aid":"8257#8258#8259#8260","text":"the rear#the front#either#neither"},
{"testId":84,"qid":11409,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should monitor the road 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not seem like a long time, but at 60 mph, your motorbike will cover 1,056 feet in that time.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#7321#8416#8417","text":"12 seconds#2 seconds#15 feet#30 feet"},
{"testId":446,"qid":11409,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should monitor the road 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not seem like a long time, but at 60 mph, your motorbike will cover 1,056 feet in that time.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#7321#8416#8417","text":"12 seconds#2 seconds#15 feet#30 feet"},
{"testId":85,"qid":11411,"description":"How can you ensure that a car driver notices you at an intersection controlled by signs or signals?","explanation":"There is no way to ensure that motorists will see you. Even when staring straight at a motorcycle, car drivers often fail to detect it. Even if a motorist establishes eye contact with you, there is no guarantee that the driver has observed you. At crossroads, always proceed with care.","imageName":"","correctAnswer":38908,"isBookmarked":0,"aid":"10603#10604#31148#38908","text":"Wave at the driver#Make eye contact with the driver#Beep your horn#You can't"},
{"testId":302,"qid":11411,"description":"How can you ensure that a car driver notices you at an intersection controlled by signs or signals?","explanation":"There is no way to ensure that motorists will see you. Even when staring straight at a motorcycle, car drivers often fail to detect it. Even if a motorist establishes eye contact with you, there is no guarantee that the driver has observed you. At crossroads, always proceed with care.","imageName":"","correctAnswer":38908,"isBookmarked":0,"aid":"10603#10604#31148#38908","text":"Wave at the driver#Make eye contact with the driver#Beep your horn#You can't"},
{"testId":467,"qid":11411,"description":"How can you ensure that a car driver notices you at an intersection controlled by signs or signals?","explanation":"There is no way to ensure that motorists will see you. Even when staring straight at a motorcycle, car drivers often fail to detect it. Even if a motorist establishes eye contact with you, there is no guarantee that the driver has observed you. At crossroads, always proceed with care.","imageName":"","correctAnswer":38908,"isBookmarked":0,"aid":"10603#10604#31148#38908","text":"Wave at the driver#Make eye contact with the driver#Beep your horn#You can't"},
{"testId":291,"qid":11413,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch is located on the left handlebar, while the front brake lever is located on the right.","imageName":"","correctAnswer":31776,"isBookmarked":0,"aid":"31773#31774#31775#31776","text":"The clutch is on the right and the front brake lever is on the left#The clutch and front brake lever are both on the left#The clutch and front brake lever are both on the right#The clutch is on the left and the front brake lever is on the right"},
{"testId":293,"qid":11413,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch is located on the left handlebar, while the front brake lever is located on the right.","imageName":"","correctAnswer":31776,"isBookmarked":0,"aid":"31773#31774#31775#31776","text":"The clutch is on the right and the front brake lever is on the left#The clutch and front brake lever are both on the left#The clutch and front brake lever are both on the right#The clutch is on the left and the front brake lever is on the right"},
{"testId":301,"qid":11415,"description":"How can you start a swerve?","explanation":"Swerves may be initiated by pushing the handlegrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result.","imageName":"","correctAnswer":32737,"isBookmarked":0,"aid":"32737#33591#33592#38706","text":"Press the handlegrip on the side of the direction in which you want to swerve#Press the footrest on the side of the direction in which you want to swerve#Press the footrest on the opposite side of the direction in which you want to swerve#Press the handlegrip on the opposite side of the direction in which you want to swerve"},
{"testId":78,"qid":11416,"description":"_____ is  the finest approach to protect your eyes.","explanation":"A shatter-resistant face shield is the best approach to safeguard your eyes. Select a face shield-equipped helmet.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":491,"qid":11416,"description":"_____ is  the finest approach to protect your eyes.","explanation":"A shatter-resistant face shield is the best approach to safeguard your eyes. Select a face shield-equipped helmet.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":495,"qid":11416,"description":"_____ is  the finest approach to protect your eyes.","explanation":"A shatter-resistant face shield is the best approach to safeguard your eyes. Select a face shield-equipped helmet.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":82,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":298,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":310,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":464,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":471,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":479,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":491,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":503,"qid":11418,"description":"Keeping your knees pressed up against the gas tank:","explanation":"Keeping your knees against the gas tank assists you in maintaining your balance on the motorbike.","imageName":"","correctAnswer":7598,"isBookmarked":0,"aid":"7595#7596#7597#7598","text":"helps turn the motorcycle#should never be done#should only be done to help steady cargo#helps you keep your balance"},
{"testId":302,"qid":11419,"description":"The most effective approach to improve your visibility at an intersection is to:","explanation":"Ride in a lane position that allows people to see you easily at an intersection to boost your visibility.","imageName":"","correctAnswer":6778,"isBookmarked":0,"aid":"6286#6776#6778#29447","text":"Flash your brake light#beep your horn to get attention#choose a lane position that lets others see you easily#use hand signals to warn others"},
{"testId":343,"qid":11422,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":52,"qid":11423,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":450,"qid":11423,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":454,"qid":11423,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":507,"qid":11423,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":56,"qid":11425,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"6506#6507#8126#30834","text":"turning too quickly#only using the rear brake#locking the rear wheel#locking the front wheel"},
{"testId":56,"qid":11426,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissy bar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22289,"isBookmarked":0,"aid":"22289#22290#22291#22292","text":"Fastening excess cargo to the sissy bar#Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords"},
{"testId":442,"qid":11426,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissy bar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22289,"isBookmarked":0,"aid":"22289#22290#22291#22292","text":"Fastening excess cargo to the sissy bar#Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords"},
{"testId":59,"qid":11428,"description":"To aid with shock absorption while biking over significant obstacles, you should:","explanation":"When riding over a major barrier, you may absorb the stress by rising slightly off the seat and allowing your knees and elbows to take the impact.","imageName":"","correctAnswer":10584,"isBookmarked":0,"aid":"6511#6569#6582#10584","text":"Increase the throttle#Apply the front brake#Pull in the clutch#Rise slightly off the seat"},
{"testId":434,"qid":11428,"description":"To aid with shock absorption while biking over significant obstacles, you should:","explanation":"When riding over a major barrier, you may absorb the stress by rising slightly off the seat and allowing your knees and elbows to take the impact.","imageName":"","correctAnswer":10584,"isBookmarked":0,"aid":"6511#6569#6582#10584","text":"Increase the throttle#Apply the front brake#Pull in the clutch#Rise slightly off the seat"},
{"testId":60,"qid":11429,"description":"How can you assist balance the motorcycle during gradual turns?","explanation":"By maintaining your torso straight in gradual bends, you may aid the motorcycle's balance.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10630#10631","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean forward#Keep your body straight"},
{"testId":334,"qid":11429,"description":"How can you assist balance the motorcycle during gradual turns?","explanation":"By maintaining your torso straight in gradual bends, you may aid the motorcycle's balance.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10630#10631","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean forward#Keep your body straight"},
{"testId":302,"qid":11432,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":24843,"isBookmarked":0,"aid":"24841#24842#24843#24844","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be riding in their blind spots#you may be distracted by what is happening inside the vehicles"},
{"testId":343,"qid":11432,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":24843,"isBookmarked":0,"aid":"24841#24842#24843#24844","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be riding in their blind spots#you may be distracted by what is happening inside the vehicles"},
{"testId":347,"qid":11432,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":24843,"isBookmarked":0,"aid":"24841#24842#24843#24844","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be riding in their blind spots#you may be distracted by what is happening inside the vehicles"},
{"testId":396,"qid":11432,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":24843,"isBookmarked":0,"aid":"24841#24842#24843#24844","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be riding in their blind spots#you may be distracted by what is happening inside the vehicles"},
{"testId":475,"qid":11432,"description":"Riding alongside cars might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":24843,"isBookmarked":0,"aid":"24841#24842#24843#24844","text":"operating your motorcycle may become more difficult#your visibility may be reduced#you may be riding in their blind spots#you may be distracted by what is happening inside the vehicles"},
{"testId":334,"qid":11434,"description":"The best method to avoid unforeseen roadblocks is to:","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10633,"isBookmarked":0,"aid":"7099#7100#10632#10633","text":"walk your motorcycle across them#put your feet down for support#cross them at a 45-degree angle#cross them at a 90-degree angle"},
{"testId":450,"qid":11434,"description":"The best method to avoid unforeseen roadblocks is to:","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10633,"isBookmarked":0,"aid":"7099#7100#10632#10633","text":"walk your motorcycle across them#put your feet down for support#cross them at a 45-degree angle#cross them at a 90-degree angle"},
{"testId":454,"qid":11434,"description":"The best method to avoid unforeseen roadblocks is to:","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10633,"isBookmarked":0,"aid":"7099#7100#10632#10633","text":"walk your motorcycle across them#put your feet down for support#cross them at a 45-degree angle#cross them at a 90-degree angle"},
{"testId":302,"qid":11435,"description":"Which wheel should touch the curb when you park on the side of the road?","explanation":"To park on the side of the road, park at a 90-degree angle to the curb, with the back wheel contacting the curb.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":291,"qid":11438,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7257#7258#7259#39501","text":"large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles#large vehicles may swerve into your lane"},
{"testId":314,"qid":11438,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7257#7258#7259#39501","text":"large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles#large vehicles may swerve into your lane"},
{"testId":330,"qid":11438,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7257#7258#7259#39501","text":"large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles#large vehicles may swerve into your lane"},
{"testId":347,"qid":11438,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Because motorcycles are smaller than vehicles, they are more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7257#7258#7259#39501","text":"large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles#large vehicles may swerve into your lane"},
{"testId":291,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":297,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":301,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":314,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":343,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":347,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":462,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":495,"qid":11439,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#31506#38693","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#use both the front and rear brakes, and stop on the shoulder of the road#operate the engine cut-off switch, and roll to a stop"},
{"testId":491,"qid":11440,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure your motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":34229,"isBookmarked":0,"aid":"10639#29383#31791#34229","text":"Never shift below second gear#Downshift only if you are an experienced rider#Brake while downshifting#Make sure your motorcycle is traveling slowly enough for the lower gear"},
{"testId":454,"qid":11441,"description":"How do you keep your motorcycle stable in slow, tight turns?","explanation":"Maintain a straight body while leaning the motorbike into gradual, tight spins.","imageName":"","correctAnswer":28992,"isBookmarked":0,"aid":"7901#10645#24741#28992","text":"Lean with the motorcycle#Lean forward as much as possible#Maintain or increase your speed#Keep your body straight while leaning the motorcycle"},
{"testId":69,"qid":11442,"description":"When riding as a group, you should NEVER:","explanation":"When cycling in a group, never ride in pairs. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7504,"isBookmarked":0,"aid":"7503#7504#7505#7506","text":"ride single file#pair up#put a beginner near the front#pass vehicles in front of you"},
{"testId":416,"qid":11442,"description":"When riding as a group, you should NEVER:","explanation":"When cycling in a group, never ride in pairs. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7504,"isBookmarked":0,"aid":"7503#7504#7505#7506","text":"ride single file#pair up#put a beginner near the front#pass vehicles in front of you"},
{"testId":420,"qid":11443,"description":"You should check at least _______ ahead while riding in the city and lookout for hazards.","explanation":"When cycling in the city, you should look at least one-half block ahead for potential risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":471,"qid":11443,"description":"You should check at least _______ ahead while riding in the city and lookout for hazards.","explanation":"When cycling in the city, you should look at least one-half block ahead for potential risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":294,"qid":11444,"description":"What causes lugging in a motorcycle engine?","explanation":"You should change into the next gear when your motorbike picks up speed, but not too soon to prevent the engine from lugging. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":446,"qid":11444,"description":"What causes lugging in a motorcycle engine?","explanation":"You should change into the next gear when your motorbike picks up speed, but not too soon to prevent the engine from lugging. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":491,"qid":11444,"description":"What causes lugging in a motorcycle engine?","explanation":"You should change into the next gear when your motorbike picks up speed, but not too soon to prevent the engine from lugging. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":487,"qid":11445,"description":"You should cover the clutch and brake whenever possible to:","explanation":"When you wish to reduce your response time, cover the clutch and brakes.","imageName":"","correctAnswer":37548,"isBookmarked":0,"aid":"27#6476#24790#37548","text":"Change lanes#Slow down#alert others to your actions#shorten your reaction time"},
{"testId":334,"qid":11447,"description":"When riding in a group, participants should communicate by:","explanation":"Hand signals should be used by group members while riding in a group. Before you leave, make sure that everyone in your party is comfortable with the hand gestures.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":507,"qid":11447,"description":"When riding in a group, participants should communicate by:","explanation":"Hand signals should be used by group members while riding in a group. Before you leave, make sure that everyone in your party is comfortable with the hand gestures.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":336,"qid":11448,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging cars.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"22997#22998#23000#26416","text":"Give them plenty of room#Move into another lane if one is open#Do all of the above#If you cannot change lanes, adjust your speed to open up space for the merging vehicles"},
{"testId":363,"qid":11448,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging cars.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"22997#22998#23000#26416","text":"Give them plenty of room#Move into another lane if one is open#Do all of the above#If you cannot change lanes, adjust your speed to open up space for the merging vehicles"},
{"testId":76,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":322,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":438,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":458,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":499,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":507,"qid":11449,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#7975#22824#22929","text":"you pull the kill engine switch#you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks"},
{"testId":76,"qid":11450,"description":"When riding in a group, you can assist in keeping the group together by:","explanation":"Checking your mirrors regularly when riding in a group will assist guarantee the group remains together.","imageName":"","correctAnswer":7935,"isBookmarked":0,"aid":"6738#7935#7936#24729","text":"riding in pairs#checking your mirrors frequently#riding single file#placing beginners at the front"},
{"testId":425,"qid":11450,"description":"When riding in a group, you can assist in keeping the group together by:","explanation":"Checking your mirrors regularly when riding in a group will assist guarantee the group remains together.","imageName":"","correctAnswer":7935,"isBookmarked":0,"aid":"6738#7935#7936#24729","text":"riding in pairs#checking your mirrors frequently#riding single file#placing beginners at the front"},
{"testId":61,"qid":11452,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":454,"qid":11452,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":487,"qid":11452,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":507,"qid":11452,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":299,"qid":11454,"description":"The most effective defense against flying items when riding is:","explanation":"A shatter-resistant face shield is the greatest protection from flying objects when cycling. It will shield your whole face from flying objects, rather than just your eyes.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7584#8336#8337#10355","text":"sunglasses#goggles#headlights#A face shield"},
{"testId":464,"qid":11454,"description":"The most effective defense against flying items when riding is:","explanation":"A shatter-resistant face shield is the greatest protection from flying objects when cycling. It will shield your whole face from flying objects, rather than just your eyes.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7584#8336#8337#10355","text":"sunglasses#goggles#headlights#A face shield"},
{"testId":83,"qid":11455,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#26445#36104","text":"Taking curves#riding in a group#in an emergency situation#parking at the roadside"},
{"testId":314,"qid":11455,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#26445#36104","text":"Taking curves#riding in a group#in an emergency situation#parking at the roadside"},
{"testId":429,"qid":11455,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#26445#36104","text":"Taking curves#riding in a group#in an emergency situation#parking at the roadside"},
{"testId":438,"qid":11455,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#26445#36104","text":"Taking curves#riding in a group#in an emergency situation#parking at the roadside"},
{"testId":450,"qid":11455,"description":"Staggered configuration is the optimal option when:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":7938,"isBookmarked":0,"aid":"6336#7938#26445#36104","text":"Taking curves#riding in a group#in an emergency situation#parking at the roadside"},
{"testId":84,"qid":11456,"description":"_____ should not be used to fasten the load of motorcycles.","explanation":"Rope should not be used to secure motorcycle baggage. Knots and ropes may both come undone. Elastic cords are a superior alternative.","imageName":"","correctAnswer":8437,"isBookmarked":0,"aid":"8225#8228#8334#8437","text":"Elastic cords#Tank bags#Saddlebags#Rope"},
{"testId":77,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":83,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":85,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":301,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":310,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":347,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":471,"qid":11458,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#33959","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders but not passengers"},
{"testId":318,"qid":11459,"description":"When should you use both the front and rear brakes? :","explanation":"When you come to a complete stop, use both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":7382,"isBookmarked":0,"aid":"7382#9710#10383#31503","text":"every time you stop#in emergency situations#when swerving#only in normal stops"},
{"testId":292,"qid":11460,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#31772","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot lever"},
{"testId":314,"qid":11460,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#31772","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot lever"},
{"testId":416,"qid":11460,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#31772","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot lever"},
{"testId":420,"qid":11460,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#31772","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot lever"},
{"testId":471,"qid":11460,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#31772","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot lever"},
{"testId":292,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":310,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":343,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":408,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":412,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":425,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":475,"qid":11461,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you aren't yet experiencing additional impacts, you can be making risky choices.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":306,"qid":11462,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"When approaching a blind junction with a stop line, you must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24768#30064#38899","text":"at the stop line only#with the front wheel over the stop line#at the stop line first, then edge forward#wherever you have the most visibility"},
{"testId":343,"qid":11462,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"When approaching a blind junction with a stop line, you must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24768#30064#38899","text":"at the stop line only#with the front wheel over the stop line#at the stop line first, then edge forward#wherever you have the most visibility"},
{"testId":458,"qid":11462,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"When approaching a blind junction with a stop line, you must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24768#30064#38899","text":"at the stop line only#with the front wheel over the stop line#at the stop line first, then edge forward#wherever you have the most visibility"},
{"testId":483,"qid":11462,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"When approaching a blind junction with a stop line, you must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24768#30064#38899","text":"at the stop line only#with the front wheel over the stop line#at the stop line first, then edge forward#wherever you have the most visibility"},
{"testId":294,"qid":11463,"description":"You must be extremely cautious at intersections for:","explanation":"You need to be extra careful to watch out for vehicles turning left in front of you at intersections. Drivers of cars often underestimate the amount of time they have to make a turn or fail to see approaching motorbikes.","imageName":"","correctAnswer":7150,"isBookmarked":0,"aid":"7149#7150#30014#35098","text":"irregular traffic signals#cars turning left in front of you#vehicles trying to share your lane#lines of parked cars"},
{"testId":310,"qid":11463,"description":"You must be extremely cautious at intersections for:","explanation":"You need to be extra careful to watch out for vehicles turning left in front of you at intersections. Drivers of cars often underestimate the amount of time they have to make a turn or fail to see approaching motorbikes.","imageName":"","correctAnswer":7150,"isBookmarked":0,"aid":"7149#7150#30014#35098","text":"irregular traffic signals#cars turning left in front of you#vehicles trying to share your lane#lines of parked cars"},
{"testId":467,"qid":11463,"description":"You must be extremely cautious at intersections for:","explanation":"You need to be extra careful to watch out for vehicles turning left in front of you at intersections. Drivers of cars often underestimate the amount of time they have to make a turn or fail to see approaching motorbikes.","imageName":"","correctAnswer":7150,"isBookmarked":0,"aid":"7149#7150#30014#35098","text":"irregular traffic signals#cars turning left in front of you#vehicles trying to share your lane#lines of parked cars"},
{"testId":79,"qid":11464,"description":"The best defense against other vehicle is:","explanation":"Maintaining an appropriate distance cushion between you and other cars provides the greatest protection from other vehicles.","imageName":"","correctAnswer":34221,"isBookmarked":0,"aid":"34221#34222#34223#34224","text":"to maintain an adequate space cushion#to use your high beam#to wear protective clothing#to choose the right helmet"},
{"testId":420,"qid":11464,"description":"The best defense against other vehicle is:","explanation":"Maintaining an appropriate distance cushion between you and other cars provides the greatest protection from other vehicles.","imageName":"","correctAnswer":34221,"isBookmarked":0,"aid":"34221#34222#34223#34224","text":"to maintain an adequate space cushion#to use your high beam#to wear protective clothing#to choose the right helmet"},
{"testId":487,"qid":11464,"description":"The best defense against other vehicle is:","explanation":"Maintaining an appropriate distance cushion between you and other cars provides the greatest protection from other vehicles.","imageName":"","correctAnswer":34221,"isBookmarked":0,"aid":"34221#34222#34223#34224","text":"to maintain an adequate space cushion#to use your high beam#to wear protective clothing#to choose the right helmet"},
{"testId":491,"qid":11464,"description":"The best defense against other vehicle is:","explanation":"Maintaining an appropriate distance cushion between you and other cars provides the greatest protection from other vehicles.","imageName":"","correctAnswer":34221,"isBookmarked":0,"aid":"34221#34222#34223#34224","text":"to maintain an adequate space cushion#to use your high beam#to wear protective clothing#to choose the right helmet"},
{"testId":295,"qid":11465,"description":"When is it permissible to ride a motorcycle in a high-occupancy vehicle (HOV) lane?","explanation":"A motorbike may ride in a high-occupancy vehicle (HOV) lane at all times, even if it is not carrying a passenger. It is thought that riding smoothly in an HOV lane is safer than being caught in stop-and-go interstate traffic.","imageName":"202003051709096729.jpg","correctAnswer":6492,"isBookmarked":0,"aid":"6492#30516#30517#30518","text":"At all times#Only if you are carrying a passenger#Only during designated time periods#Only if you are riding with another motorcycle"},
{"testId":297,"qid":11465,"description":"When is it permissible to ride a motorcycle in a high-occupancy vehicle (HOV) lane?","explanation":"A motorbike may ride in a high-occupancy vehicle (HOV) lane at all times, even if it is not carrying a passenger. It is thought that riding smoothly in an HOV lane is safer than being caught in stop-and-go interstate traffic.","imageName":"202003051709096729.jpg","correctAnswer":6492,"isBookmarked":0,"aid":"6492#30516#30517#30518","text":"At all times#Only if you are carrying a passenger#Only during designated time periods#Only if you are riding with another motorcycle"},
{"testId":429,"qid":11465,"description":"When is it permissible to ride a motorcycle in a high-occupancy vehicle (HOV) lane?","explanation":"A motorbike may ride in a high-occupancy vehicle (HOV) lane at all times, even if it is not carrying a passenger. It is thought that riding smoothly in an HOV lane is safer than being caught in stop-and-go interstate traffic.","imageName":"202003051709096729.jpg","correctAnswer":6492,"isBookmarked":0,"aid":"6492#30516#30517#30518","text":"At all times#Only if you are carrying a passenger#Only during designated time periods#Only if you are riding with another motorcycle"},
{"testId":462,"qid":11465,"description":"When is it permissible to ride a motorcycle in a high-occupancy vehicle (HOV) lane?","explanation":"A motorbike may ride in a high-occupancy vehicle (HOV) lane at all times, even if it is not carrying a passenger. It is thought that riding smoothly in an HOV lane is safer than being caught in stop-and-go interstate traffic.","imageName":"202003051709096729.jpg","correctAnswer":6492,"isBookmarked":0,"aid":"6492#30516#30517#30518","text":"At all times#Only if you are carrying a passenger#Only during designated time periods#Only if you are riding with another motorcycle"},
{"testId":503,"qid":11465,"description":"When is it permissible to ride a motorcycle in a high-occupancy vehicle (HOV) lane?","explanation":"A motorbike may ride in a high-occupancy vehicle (HOV) lane at all times, even if it is not carrying a passenger. It is thought that riding smoothly in an HOV lane is safer than being caught in stop-and-go interstate traffic.","imageName":"202003051709096729.jpg","correctAnswer":6492,"isBookmarked":0,"aid":"6492#30516#30517#30518","text":"At all times#Only if you are carrying a passenger#Only during designated time periods#Only if you are riding with another motorcycle"},
{"testId":297,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":310,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":330,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":471,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":491,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":499,"qid":11466,"description":"What is controlled by the right foot pedal?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":299,"qid":11467,"description":"To discourage others from sharing your lane, ride in the _____ lane.","explanation":"The middle lane location is the greatest for discouraging lane sharing by others. It gives you the most space on each side of you.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":301,"qid":11468,"description":"Intersections are particularly hazardous to motorcycle riders because:","explanation":"Motorcyclists are particularly vulnerable at intersections because other drivers often fail to detect them coming.","imageName":"","correctAnswer":35326,"isBookmarked":0,"aid":"8113#8114#22811#35326","text":"motorcycles are not permitted to cross intersections#they do not have to obey traffic lights#motorcycles are slower than other vehicles#other vehicle operators often fail to notice them approaching"},
{"testId":77,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":295,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":302,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":334,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":464,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":495,"qid":11469,"description":"When you pull over to the side of the road, you need to make sure :","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":8073,"isBookmarked":0,"aid":"8073#8074#8075#34525","text":"that the surface is firm enough to ride on#that your brakes are working#that it is a well-lit area#that it is a designated pull-off area"},
{"testId":59,"qid":11470,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"For the optimum traction, motorcycles need a hard, solid surface. Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":471,"qid":11470,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"For the optimum traction, motorcycles need a hard, solid surface. Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":306,"qid":11471,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Your high-beam headlight allows you to see farther ahead, but it may also blind or disturb other drivers. When you are not following or meeting a vehicle at night, utilize your high-beam headlight. When following or meeting a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":314,"qid":11471,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Your high-beam headlight allows you to see farther ahead, but it may also blind or disturb other drivers. When you are not following or meeting a vehicle at night, utilize your high-beam headlight. When following or meeting a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":52,"qid":11472,"description":"This symbol alerts drivers that:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9044#9046#9047#26376","text":"you are approaching a work zone#you are approaching a railroad crossing#you are approaching an intersection#you are approaching a dead end"},
{"testId":306,"qid":11472,"description":"This symbol alerts drivers that:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9044#9046#9047#26376","text":"you are approaching a work zone#you are approaching a railroad crossing#you are approaching an intersection#you are approaching a dead end"},
{"testId":318,"qid":11472,"description":"This symbol alerts drivers that:","explanation":"This sign warns of an impending train crossing. (At the approach to certain crossings, there may additionally be an X and the initials \"RR\" on the pavement.) Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9044#9046#9047#26376","text":"you are approaching a work zone#you are approaching a railroad crossing#you are approaching an intersection#you are approaching a dead end"},
{"testId":306,"qid":11474,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":479,"qid":11474,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":487,"qid":11474,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":503,"qid":11474,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":507,"qid":11474,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":306,"qid":11475,"description":"What is the optimal lane position in areas where there is oil and grease buildup?","explanation":"Oil and grease accumulate in the middle of the lane. Ride in the left or right lane in areas where there is oil and grease accumulation. Ride in the tire tracks left by other cars for the most traction.","imageName":"","correctAnswer":30028,"isBookmarked":0,"aid":"6385#6387#7176#30028","text":"In the blind spot#Not important#The center lane position#The left or right lane position"},
{"testId":306,"qid":11476,"description":"In which of these situations is checking your mirrors the LEAST important?","explanation":"When descending a major slope, checking your mirrors is the least critical of these four conditions.","imageName":"","correctAnswer":35501,"isBookmarked":0,"aid":"6537#35499#35500#35501","text":"Before you change lanes#When you're stopped at an intersection#When you're starting to slow down#When you're descending a large hill"},
{"testId":422,"qid":11476,"description":"In which of these situations is checking your mirrors the LEAST important?","explanation":"When descending a major slope, checking your mirrors is the least critical of these four conditions.","imageName":"","correctAnswer":35501,"isBookmarked":0,"aid":"6537#35499#35500#35501","text":"Before you change lanes#When you're stopped at an intersection#When you're starting to slow down#When you're descending a large hill"},
{"testId":475,"qid":11476,"description":"In which of these situations is checking your mirrors the LEAST important?","explanation":"When descending a major slope, checking your mirrors is the least critical of these four conditions.","imageName":"","correctAnswer":35501,"isBookmarked":0,"aid":"6537#35499#35500#35501","text":"Before you change lanes#When you're stopped at an intersection#When you're starting to slow down#When you're descending a large hill"},
{"testId":85,"qid":11477,"description":"What is the best way to recognize road bumps when riding at night?","explanation":"When riding at night, the simplest approach to recognize road bumps is to look at the taillights of the car ahead of you. If the taillights seem to be bouncing up and down, the car is most likely driving over bumps.","imageName":"","correctAnswer":30039,"isBookmarked":0,"aid":"8123#10682#30039#30040","text":"Use your high beam#Scan the road often#Watch the taillights of the vehicle ahead of you#Ride only where there are street lights"},
{"testId":306,"qid":11477,"description":"What is the best way to recognize road bumps when riding at night?","explanation":"When riding at night, the simplest approach to recognize road bumps is to look at the taillights of the car ahead of you. If the taillights seem to be bouncing up and down, the car is most likely driving over bumps.","imageName":"","correctAnswer":30039,"isBookmarked":0,"aid":"8123#10682#30039#30040","text":"Use your high beam#Scan the road often#Watch the taillights of the vehicle ahead of you#Ride only where there are street lights"},
{"testId":487,"qid":11477,"description":"What is the best way to recognize road bumps when riding at night?","explanation":"When riding at night, the simplest approach to recognize road bumps is to look at the taillights of the car ahead of you. If the taillights seem to be bouncing up and down, the car is most likely driving over bumps.","imageName":"","correctAnswer":30039,"isBookmarked":0,"aid":"8123#10682#30039#30040","text":"Use your high beam#Scan the road often#Watch the taillights of the vehicle ahead of you#Ride only where there are street lights"},
{"testId":60,"qid":11478,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#10684","text":"45-degree#90-degree#180-degree#25-degree"},
{"testId":306,"qid":11478,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#10684","text":"45-degree#90-degree#180-degree#25-degree"},
{"testId":306,"qid":11479,"description":"The Alabama Motorcycle Manual suggests that you keep a following distance of at least ________ behind the vehicle ahead.","explanation":"According to the Alabama Motorcycle Manual, you should maintain a following distance of at least two seconds behind the vehicle in front of you. If your motorbike takes longer to stop than normal, increase your following distance.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4097","text":"one second#two seconds#four seconds#five seconds"},
{"testId":62,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":306,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":347,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":412,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":416,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":446,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":487,"qid":11480,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":63,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":302,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":306,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":310,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":458,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":479,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":483,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":503,"qid":11481,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6982#6983#26117","text":"When you are descending a hill#whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road"},
{"testId":63,"qid":11482,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":306,"qid":11482,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":359,"qid":11482,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":442,"qid":11482,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":471,"qid":11482,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":59,"qid":11483,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":306,"qid":11483,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":330,"qid":11483,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":336,"qid":11483,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":355,"qid":11483,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":306,"qid":11484,"description":"Riding a motorcycle while wearing a face shield:","explanation":"Every biker and passenger in Alabama is required by law to wear an authorized helmet. Even though it is not legally compulsory, the state of Alabama urges all motorcycle riders to wear a face shield. On a motorbike, wearing a face shield is the greatest type of face and eye protection since it covers your whole face and will not blow off in the wind.","imageName":"","correctAnswer":7280,"isBookmarked":0,"aid":"7280#7282#40639#40640","text":"is the best form of face and eye protection#is not as effective as wearing goggles#is legally required of all motorcyclists#is only required of motorcyclists under the age of 18"},
{"testId":306,"qid":11485,"description":"Which lane should you ride in to improve your visibility?","explanation":"Ride in the left lane to improve your line of sight. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":306,"qid":11486,"description":"To reduce the risk of slipping on a slick surface, you should:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":38714,"isBookmarked":0,"aid":"7332#7334#10656#38714","text":"drag your feet on the ground#ride on the shoulder of the road#Do all of the above#slow down before you get to it"},
{"testId":343,"qid":11486,"description":"To reduce the risk of slipping on a slick surface, you should:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":38714,"isBookmarked":0,"aid":"7332#7334#10656#38714","text":"drag your feet on the ground#ride on the shoulder of the road#Do all of the above#slow down before you get to it"},
{"testId":306,"qid":11487,"description":"Why is pulling over to the shoulder more dangerous for motorbikes than for cars?","explanation":"Pulling over to the side of the road may be more dangerous for motorbikes than it is for vehicles since the shoulder is typically covered in sand or gravel, both of which give poor traction for motorcycles.","imageName":"","correctAnswer":10689,"isBookmarked":0,"aid":"10688#10689#10690#10691","text":"It is illegal for motorcycles to park on the side of the road#The shoulder is often sandy and may provide less traction#It is more difficult for motorcycles to stop quickly#Motorcycles require a curb to park against"},
{"testId":300,"qid":11489,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#40795#40986","text":"behind the leader#at the end#at the front#in front of the sweep rider"},
{"testId":306,"qid":11489,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#40795#40986","text":"behind the leader#at the end#at the front#in front of the sweep rider"},
{"testId":495,"qid":11489,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#40795#40986","text":"behind the leader#at the end#at the front#in front of the sweep rider"},
{"testId":306,"qid":11490,"description":"Which of the following you should do before pulling over to the side of the road in an emergency?","explanation":"Before making an emergency stop on the side of the road, you should check that the surface is firm enough to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":38907,"isBookmarked":0,"aid":"6928#7414#7415#38907","text":"Move into the center lane position#Skim your feet along the road#Make sure your luggage is secure#Check that the surface is firm enough to ride on"},
{"testId":507,"qid":11490,"description":"Which of the following you should do before pulling over to the side of the road in an emergency?","explanation":"Before making an emergency stop on the side of the road, you should check that the surface is firm enough to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":38907,"isBookmarked":0,"aid":"6928#7414#7415#38907","text":"Move into the center lane position#Skim your feet along the road#Make sure your luggage is secure#Check that the surface is firm enough to ride on"},
{"testId":69,"qid":11491,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7429#7430#7431#7432","text":"you are in the left lane position#they want to pass you#they know you are a beginner rider#you are riding on a multilane highway"},
{"testId":306,"qid":11491,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7429#7430#7431#7432","text":"you are in the left lane position#they want to pass you#they know you are a beginner rider#you are riding on a multilane highway"},
{"testId":431,"qid":11491,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7429#7430#7431#7432","text":"you are in the left lane position#they want to pass you#they know you are a beginner rider#you are riding on a multilane highway"},
{"testId":442,"qid":11491,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7429#7430#7431#7432","text":"you are in the left lane position#they want to pass you#they know you are a beginner rider#you are riding on a multilane highway"},
{"testId":467,"qid":11491,"description":"When you are driving, Other car drivers are more inclined to try to share your lane when:","explanation":"When passing you, car drivers are more likely to attempt to share your lane. Select the center lane position to deter them from sharing your lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":7430,"isBookmarked":0,"aid":"7429#7430#7431#7432","text":"you are in the left lane position#they want to pass you#they know you are a beginner rider#you are riding on a multilane highway"},
{"testId":306,"qid":11492,"description":"What happens if you ride too fast through a turn?","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":41325,"isBookmarked":0,"aid":"41324#41325#41326#41327","text":"You may tip over#You may veer into another lane#Other vehicle operators may be distracted#Your motorcycle may suffer mechanical failure"},
{"testId":310,"qid":11492,"description":"What happens if you ride too fast through a turn?","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":41325,"isBookmarked":0,"aid":"41324#41325#41326#41327","text":"You may tip over#You may veer into another lane#Other vehicle operators may be distracted#Your motorcycle may suffer mechanical failure"},
{"testId":306,"qid":11493,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":487,"qid":11493,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":491,"qid":11493,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":499,"qid":11493,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":70,"qid":11494,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":306,"qid":11494,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":400,"qid":11494,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":404,"qid":11494,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":58,"qid":11495,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most riders involved in incidents underbraked the front tire and overbraked the rear tire, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23079#23080#30008#33206","text":"lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so#underbraked the front tire and overbraked the rear tire"},
{"testId":81,"qid":11495,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most riders involved in incidents underbraked the front tire and overbraked the rear tire, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23079#23080#30008#33206","text":"lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so#underbraked the front tire and overbraked the rear tire"},
{"testId":306,"qid":11495,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most riders involved in incidents underbraked the front tire and overbraked the rear tire, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23079#23080#30008#33206","text":"lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so#underbraked the front tire and overbraked the rear tire"},
{"testId":306,"qid":11496,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"While all four options are crucial while cycling through an intersection, be particularly cautious of automobiles turning left into your path. Car drivers often fail to see approaching motorcyclists.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":330,"qid":11496,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"While all four options are crucial while cycling through an intersection, be particularly cautious of automobiles turning left into your path. Car drivers often fail to see approaching motorcyclists.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":75,"qid":11497,"description":"Which of the followings are rules of riding in a group?","explanation":"Checking your mirrors regularly, riding in a staggered configuration when appropriate, and not following too closely are all rules of group riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28399#28400#28401","text":"All of the above#Maintaining a staggered formation#Not following too closely#Checking your mirrors frequently"},
{"testId":306,"qid":11497,"description":"Which of the followings are rules of riding in a group?","explanation":"Checking your mirrors regularly, riding in a staggered configuration when appropriate, and not following too closely are all rules of group riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28399#28400#28401","text":"All of the above#Maintaining a staggered formation#Not following too closely#Checking your mirrors frequently"},
{"testId":318,"qid":11497,"description":"Which of the followings are rules of riding in a group?","explanation":"Checking your mirrors regularly, riding in a staggered configuration when appropriate, and not following too closely are all rules of group riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28399#28400#28401","text":"All of the above#Maintaining a staggered formation#Not following too closely#Checking your mirrors frequently"},
{"testId":464,"qid":11497,"description":"Which of the followings are rules of riding in a group?","explanation":"Checking your mirrors regularly, riding in a staggered configuration when appropriate, and not following too closely are all rules of group riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28399#28400#28401","text":"All of the above#Maintaining a staggered formation#Not following too closely#Checking your mirrors frequently"},
{"testId":483,"qid":11497,"description":"Which of the followings are rules of riding in a group?","explanation":"Checking your mirrors regularly, riding in a staggered configuration when appropriate, and not following too closely are all rules of group riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28399#28400#28401","text":"All of the above#Maintaining a staggered formation#Not following too closely#Checking your mirrors frequently"},
{"testId":306,"qid":11498,"description":"Which of the following statements is FALSE?","explanation":"Motorcycles, like vehicles, have blind areas. Before changing lanes, do a mental check.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10706#10707#33596","text":"You must be in good physical condition to ride a motorcycle#Saddlebags should be filled with evenly distributed weight#Motorcycles do not have blind spots#The center lane position is usually safe to ride in"},
{"testId":434,"qid":11498,"description":"Which of the following statements is FALSE?","explanation":"Motorcycles, like vehicles, have blind areas. Before changing lanes, do a mental check.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10706#10707#33596","text":"You must be in good physical condition to ride a motorcycle#Saddlebags should be filled with evenly distributed weight#Motorcycles do not have blind spots#The center lane position is usually safe to ride in"},
{"testId":503,"qid":11498,"description":"Which of the following statements is FALSE?","explanation":"Motorcycles, like vehicles, have blind areas. Before changing lanes, do a mental check.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10706#10707#33596","text":"You must be in good physical condition to ride a motorcycle#Saddlebags should be filled with evenly distributed weight#Motorcycles do not have blind spots#The center lane position is usually safe to ride in"},
{"testId":306,"qid":11499,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short rides (less than five miles in length), only a few minutes after setting out.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":301,"qid":11500,"description":"What should you do if your front wheel locks?","explanation":"A locked front wheel is hazardous. If your front wheel locks, remove the front brake immediately and smoothly reapply it to recover control.","imageName":"","correctAnswer":31498,"isBookmarked":0,"aid":"10710#31496#31497#31498","text":"Pull the engine kill switch#Release the front brake and apply the rear brake only#Keep the front brake applied until you come to a complete stop#Release the front brake and reapply it smoothly"},
{"testId":306,"qid":11500,"description":"What should you do if your front wheel locks?","explanation":"A locked front wheel is hazardous. If your front wheel locks, remove the front brake immediately and smoothly reapply it to recover control.","imageName":"","correctAnswer":31498,"isBookmarked":0,"aid":"10710#31496#31497#31498","text":"Pull the engine kill switch#Release the front brake and apply the rear brake only#Keep the front brake applied until you come to a complete stop#Release the front brake and reapply it smoothly"},
{"testId":306,"qid":11501,"description":"What can you do to maintain your balance during a turn?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#10712#10713#10714","text":"Keep your knees against the gas tank#Crouch down low#Pull in the clutch and coast#Slow down before the turn"},
{"testId":318,"qid":11501,"description":"What can you do to maintain your balance during a turn?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#10712#10713#10714","text":"Keep your knees against the gas tank#Crouch down low#Pull in the clutch and coast#Slow down before the turn"},
{"testId":306,"qid":11502,"description":"How can you stay awake while riding?","explanation":"You can help prevent fatigue by riding no more than six hours per day, taking at least one two-hour break, and protecting yourself from the elements by wearing appropriate clothing and gear.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":78,"qid":11503,"description":"What can you do to make your brake light more visible to others?","explanation":"By flashing your brake light, you may make it more visible to others.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10719#10720","text":"Use your high beam#Flash your brake light#Avoid tailgating#Ride in lighted areas"},
{"testId":306,"qid":11503,"description":"What can you do to make your brake light more visible to others?","explanation":"By flashing your brake light, you may make it more visible to others.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10719#10720","text":"Use your high beam#Flash your brake light#Avoid tailgating#Ride in lighted areas"},
{"testId":475,"qid":11503,"description":"What can you do to make your brake light more visible to others?","explanation":"By flashing your brake light, you may make it more visible to others.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10719#10720","text":"Use your high beam#Flash your brake light#Avoid tailgating#Ride in lighted areas"},
{"testId":495,"qid":11503,"description":"What can you do to make your brake light more visible to others?","explanation":"By flashing your brake light, you may make it more visible to others.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10719#10720","text":"Use your high beam#Flash your brake light#Avoid tailgating#Ride in lighted areas"},
{"testId":306,"qid":11504,"description":"Half of all motorcycle accidents are the result of:","explanation":"Cars entering the rider's right-of-way cause half of all motorcycle accidents. When approaching a junction, proceed with care.","imageName":"","correctAnswer":33932,"isBookmarked":0,"aid":"8187#8188#10721#33932","text":"faulty traffic signals#not wearing proper gear#riding while under the influence#cars entering the rider's right-of-way"},
{"testId":294,"qid":11505,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":306,"qid":11505,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":58,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":295,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":298,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":299,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":302,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":306,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":412,"qid":11506,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":83,"qid":11507,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":306,"qid":11507,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":343,"qid":11507,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":422,"qid":11507,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":507,"qid":11507,"description":"When you're on a motorcycle, you mostly use your arms for:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":84,"qid":11508,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"234#7961#10725#10726","text":"Any of the above#Rolling on the throttle#Applying the rear brake#Applying the front brake"},
{"testId":306,"qid":11508,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"234#7961#10725#10726","text":"Any of the above#Rolling on the throttle#Applying the rear brake#Applying the front brake"},
{"testId":475,"qid":11508,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"234#7961#10725#10726","text":"Any of the above#Rolling on the throttle#Applying the rear brake#Applying the front brake"},
{"testId":511,"qid":11508,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"234#7961#10725#10726","text":"Any of the above#Rolling on the throttle#Applying the rear brake#Applying the front brake"},
{"testId":306,"qid":11509,"description":"If a dog begins to chase you while driving, you should:","explanation":"If a dog begins after you, you should approach it and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":28227,"isBookmarked":0,"aid":"6570#6572#28227#28228","text":"kick the dog#stop until the owner is found#approach the animal and then speed away#call animal control services"},
{"testId":306,"qid":11510,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. If you cross at a smaller angle, you may lose your equilibrium or topple over.","imageName":"","correctAnswer":35536,"isBookmarked":0,"aid":"10906#10909#32687#35536","text":"It gives you the best view of oncoming trains#A larger angle would be unsafe#It lets you cross the tracks quickly#At a smaller angle, you might catch your tires"},
{"testId":291,"qid":11511,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":306,"qid":11511,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":343,"qid":11511,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":392,"qid":11511,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":434,"qid":11511,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":28204,"isBookmarked":0,"aid":"6239#10581#10730#28204","text":"Increase your following distance#Wear reflective clothing#Change your lane position#Use your low-beam headlight"},
{"testId":299,"qid":11512,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7583#10355#10356","text":"A windshield#approved goggles#A face shield#approved sunglasses"},
{"testId":302,"qid":11512,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7583#10355#10356","text":"A windshield#approved goggles#A face shield#approved sunglasses"},
{"testId":306,"qid":11512,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7583#10355#10356","text":"A windshield#approved goggles#A face shield#approved sunglasses"},
{"testId":503,"qid":11512,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7583#10355#10356","text":"A windshield#approved goggles#A face shield#approved sunglasses"},
{"testId":507,"qid":11512,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#7583#10355#10356","text":"A windshield#approved goggles#A face shield#approved sunglasses"},
{"testId":306,"qid":11513,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#39130#39131#39132","text":"Never#When you're riding through sharp curves#When you're approaching an intersection#When you're riding at night"},
{"testId":84,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":293,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":306,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":475,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":487,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":499,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":511,"qid":11514,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more time.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":295,"qid":11515,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#26116","text":"single file#the staggered formation#in pairs#a V-formation"},
{"testId":306,"qid":11515,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#26116","text":"single file#the staggered formation#in pairs#a V-formation"},
{"testId":79,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":295,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":306,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":363,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":396,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":446,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":487,"qid":11516,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footrests.","imageName":"","correctAnswer":8164,"isBookmarked":0,"aid":"8164#8165#8167#10733","text":"keeping them on the footrests#skimming them above the ground#holding them away from the motorcycle#pointing your toes downward"},
{"testId":306,"qid":11517,"description":"If you want to ride at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":314,"qid":11517,"description":"If you want to ride at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":54,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":297,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":306,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":343,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":347,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":507,"qid":11518,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":306,"qid":11519,"description":"The chain on your motorcycle should be inspected,:","explanation":null,"imageName":"","correctAnswer":8236,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":306,"qid":11520,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":454,"qid":11520,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":487,"qid":11520,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":54,"qid":11524,"description":"In which of the following circumstances should you switch lanes?","explanation":"If you are being passed by another vehicle, passing parked autos, or a car attempting to share your lane, you should modify your lane position to safeguard your safety.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#24589#26454#26455","text":"All of the above#When you are passing parked cars#When you are being passed by other vehicles#When a car is trying to share your lane"},
{"testId":54,"qid":11525,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#24799#26431#32398","text":"Beep your horn#Ride in the center lane position#change lanes periodically#avoid riding on narrow roads"},
{"testId":318,"qid":11525,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#24799#26431#32398","text":"Beep your horn#Ride in the center lane position#change lanes periodically#avoid riding on narrow roads"},
{"testId":416,"qid":11525,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#24799#26431#32398","text":"Beep your horn#Ride in the center lane position#change lanes periodically#avoid riding on narrow roads"},
{"testId":422,"qid":11525,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#24799#26431#32398","text":"Beep your horn#Ride in the center lane position#change lanes periodically#avoid riding on narrow roads"},
{"testId":58,"qid":11529,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#41321","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Blasts of wind"},
{"testId":314,"qid":11529,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#41321","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Blasts of wind"},
{"testId":330,"qid":11529,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#41321","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Blasts of wind"},
{"testId":454,"qid":11529,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#41321","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Blasts of wind"},
{"testId":503,"qid":11529,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#41321","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Blasts of wind"},
{"testId":58,"qid":11530,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":318,"qid":11530,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":442,"qid":11530,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":471,"qid":11530,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":483,"qid":11530,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":58,"qid":11531,"description":"You are about to hit something and need to change direction to avoid it. How do you start a swerve in the best way?","explanation":"If you are in danger of colliding with anything, the best approach to commence a swerve is to apply little pressure on the handgrip on the side of the targeted swerve. The motorbike will tilt sharply in that direction as a result.","imageName":"","correctAnswer":24752,"isBookmarked":0,"aid":"10742#24597#24752#24753","text":"Lean sharply in the opposite direction from which you need to swerve#Lean sharply in the direction in which you need to swerve#Apply a small amount of pressure to the handgrip on the side of your intended swerve#Apply a small amount of pressure to the handgrip on the side opposite to your intended swerve"},
{"testId":314,"qid":11532,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":6175,"isBookmarked":0,"aid":"6173#6175#6747#24605","text":"with your toes pointed downward#firmly on the footrests#hovering just above the ground#in any of the above positions"},
{"testId":467,"qid":11532,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":6175,"isBookmarked":0,"aid":"6173#6175#6747#24605","text":"with your toes pointed downward#firmly on the footrests#hovering just above the ground#in any of the above positions"},
{"testId":61,"qid":11533,"description":"Which colors of clothing are most visible to other road users?","explanation":"Bright hues like orange, red, yellow, and green are ideal for improving your visibility to others.","imageName":"","correctAnswer":28414,"isBookmarked":0,"aid":"28411#28413#28414#35087","text":"Orange, yellow, and white#White, red, and orange#Orange, red, yellow, and green#Red, green, and brown"},
{"testId":467,"qid":11534,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":28368,"isBookmarked":0,"aid":"6244#6417#28368#31783","text":"use the rear brake only#Use the front brake only#use the front and rear brakes at the same time#alternate between the rear brake and the front brake"},
{"testId":491,"qid":11534,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":28368,"isBookmarked":0,"aid":"6244#6417#28368#31783","text":"use the rear brake only#Use the front brake only#use the front and rear brakes at the same time#alternate between the rear brake and the front brake"},
{"testId":81,"qid":11535,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":35117,"isBookmarked":0,"aid":"6769#6999#35117#35527","text":"In heavy traffic#On slippery roads#When you're riding in a group#When you're being tailgated"},
{"testId":295,"qid":11535,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":35117,"isBookmarked":0,"aid":"6769#6999#35117#35527","text":"In heavy traffic#On slippery roads#When you're riding in a group#When you're being tailgated"},
{"testId":302,"qid":11535,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":35117,"isBookmarked":0,"aid":"6769#6999#35117#35527","text":"In heavy traffic#On slippery roads#When you're riding in a group#When you're being tailgated"},
{"testId":425,"qid":11535,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":35117,"isBookmarked":0,"aid":"6769#6999#35117#35527","text":"In heavy traffic#On slippery roads#When you're riding in a group#When you're being tailgated"},
{"testId":464,"qid":11535,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":35117,"isBookmarked":0,"aid":"6769#6999#35117#35527","text":"In heavy traffic#On slippery roads#When you're riding in a group#When you're being tailgated"},
{"testId":446,"qid":11537,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handgrip.","imageName":"","correctAnswer":7118,"isBookmarked":0,"aid":"7116#7117#7118#31788","text":"right handgrip#right foot pedal#left handgrip#left foot lever"},
{"testId":462,"qid":11537,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handgrip.","imageName":"","correctAnswer":7118,"isBookmarked":0,"aid":"7116#7117#7118#31788","text":"right handgrip#right foot pedal#left handgrip#left foot lever"},
{"testId":479,"qid":11537,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handgrip.","imageName":"","correctAnswer":7118,"isBookmarked":0,"aid":"7116#7117#7118#31788","text":"right handgrip#right foot pedal#left handgrip#left foot lever"},
{"testId":400,"qid":11538,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and repair your own errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":446,"qid":11538,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and repair your own errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":454,"qid":11538,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and repair your own errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":495,"qid":11538,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and repair your own errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":355,"qid":11539,"description":"A wobble is likely to be exacerbated by:","explanation":"Attempting to speed out of a wobble is likely to exacerbate it. Instead, gently close the throttle.","imageName":"","correctAnswer":7180,"isBookmarked":0,"aid":"7179#7180#7181#7182","text":"unpaved roads#trying to accelerate out of it#closing the throttle gradually#riding beside another vehicle"},
{"testId":467,"qid":11539,"description":"A wobble is likely to be exacerbated by:","explanation":"Attempting to speed out of a wobble is likely to exacerbate it. Instead, gently close the throttle.","imageName":"","correctAnswer":7180,"isBookmarked":0,"aid":"7179#7180#7181#7182","text":"unpaved roads#trying to accelerate out of it#closing the throttle gradually#riding beside another vehicle"},
{"testId":347,"qid":11540,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"If you feel that the driver behind you is not aware that you are slowing down, you should flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10755#35075","text":"Beep your horn to warn the driver#Move into another lane#Tap your brake to flash your brake light#Accelerate to get through the light before it turns red"},
{"testId":471,"qid":11540,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"If you feel that the driver behind you is not aware that you are slowing down, you should flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10755#35075","text":"Beep your horn to warn the driver#Move into another lane#Tap your brake to flash your brake light#Accelerate to get through the light before it turns red"},
{"testId":347,"qid":11542,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, you must keep yourself safe in the event of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":446,"qid":11542,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, you must keep yourself safe in the event of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":450,"qid":11542,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, you must keep yourself safe in the event of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":462,"qid":11542,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, you must keep yourself safe in the event of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":467,"qid":11542,"description":"Which of the following is appropriate motorcycle protection clothing?","explanation":"Wear a robust jacket, boots, and gloves while riding a motorbike. Even in hot weather, you must keep yourself safe in the event of an accident or collision.","imageName":"","correctAnswer":7221,"isBookmarked":0,"aid":"7221#10757#10758#30439","text":"Sturdy jacket, boots, and gloves#Gloves, bright shoes, and a denim jacket#Boots, shorts, and a leather jacket#Jeans, T-shirt, and a helmet"},
{"testId":479,"qid":11543,"description":"Which of the following statement about carrying cargo on your motorcycle is correct?","explanation":"Fill your saddlebags evenly. If one side is heavier than the other, the motorbike may slide.","imageName":"","correctAnswer":10762,"isBookmarked":0,"aid":"10759#10760#10761#10762","text":"Use ropes to secure the load#Attach the cargo to your gas tank#Never carry cargo on a motorcycle#Fill saddlebags evenly to prevent drifting"},
{"testId":416,"qid":11545,"description":"Which of the following things is NOT TRUE about riding downhill?","explanation":"Gravity increases the amount of braking effort necessary to slow or stop the motorbike while going downhill. As a result, begin slowing down early for cornering or stopping.","imageName":"","correctAnswer":34809,"isBookmarked":0,"aid":"32053#33942#34809#34810","text":"Motorcycles tend to pick up speed#Start to slow down earlier#You should increase your speed#More braking force will be needed to slow down or stop"},
{"testId":420,"qid":11545,"description":"Which of the following things is NOT TRUE about riding downhill?","explanation":"Gravity increases the amount of braking effort necessary to slow or stop the motorbike while going downhill. As a result, begin slowing down early for cornering or stopping.","imageName":"","correctAnswer":34809,"isBookmarked":0,"aid":"32053#33942#34809#34810","text":"Motorcycles tend to pick up speed#Start to slow down earlier#You should increase your speed#More braking force will be needed to slow down or stop"},
{"testId":487,"qid":11545,"description":"Which of the following things is NOT TRUE about riding downhill?","explanation":"Gravity increases the amount of braking effort necessary to slow or stop the motorbike while going downhill. As a result, begin slowing down early for cornering or stopping.","imageName":"","correctAnswer":34809,"isBookmarked":0,"aid":"32053#33942#34809#34810","text":"Motorcycles tend to pick up speed#Start to slow down earlier#You should increase your speed#More braking force will be needed to slow down or stop"},
{"testId":72,"qid":11546,"description":"Which of the following statement about starting your motorcycle on an upgrade is correct?","explanation":"Use the rear brake to restrain you until the engine takes hold while starting your motorbike on an upgrade.","imageName":"","correctAnswer":30066,"isBookmarked":0,"aid":"10763#10765#30066#30067","text":"Position yourself at a 90-degree angle against the curb#Start in second gear#Use the rear brake to hold you until the engine takes hold#Never try to start a motorcycle on an upgrade"},
{"testId":425,"qid":11548,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in a blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#22793#30012","text":"Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position#Ride at the same speed as the vehicles you are beside"},
{"testId":467,"qid":11548,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in a blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#22793#30012","text":"Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position#Ride at the same speed as the vehicles you are beside"},
{"testId":503,"qid":11548,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in a blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#22793#30012","text":"Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position#Ride at the same speed as the vehicles you are beside"},
{"testId":330,"qid":11549,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles are unsafe at the road's side because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#24857#26509","text":"dirt and gravel provide little traction#it is not driven on often#the pavement is of poor quality#visibility is poor there"},
{"testId":334,"qid":11549,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles are unsafe at the road's side because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#24857#26509","text":"dirt and gravel provide little traction#it is not driven on often#the pavement is of poor quality#visibility is poor there"},
{"testId":471,"qid":11549,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles are unsafe at the road's side because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#24857#26509","text":"dirt and gravel provide little traction#it is not driven on often#the pavement is of poor quality#visibility is poor there"},
{"testId":487,"qid":11549,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"Motorcycles are unsafe at the road's side because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#24857#26509","text":"dirt and gravel provide little traction#it is not driven on often#the pavement is of poor quality#visibility is poor there"},
{"testId":314,"qid":11551,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"The time of day has no bearing on your blood alcohol levels (BAC). At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":76,"qid":11552,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":85,"qid":11552,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":293,"qid":11552,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":503,"qid":11552,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":334,"qid":11553,"description":"You should inspect _________  before mounting the motorcycle.","explanation":"Before mounting your motorcycle, you should check the tires and the lights. These must all be in good working order on each trip because their failure could be extremely dangerous.","imageName":"","correctAnswer":10768,"isBookmarked":0,"aid":"8016#8017#10768#34255","text":"the mirrors and the exhaust#the windshield and the kickstand#the tires and the lights#the footrests and the gas level"},
{"testId":479,"qid":11553,"description":"You should inspect _________  before mounting the motorcycle.","explanation":"Before mounting your motorcycle, you should check the tires and the lights. These must all be in good working order on each trip because their failure could be extremely dangerous.","imageName":"","correctAnswer":10768,"isBookmarked":0,"aid":"8016#8017#10768#34255","text":"the mirrors and the exhaust#the windshield and the kickstand#the tires and the lights#the footrests and the gas level"},
{"testId":420,"qid":11554,"description":"How do you keep yourself from overusing the throttle?","explanation":"Starting with your right wrist flat can help you avoid using too much throttle.","imageName":"","correctAnswer":10771,"isBookmarked":0,"aid":"10769#10770#10771#10772","text":"Start with your right wrist raised#Keep the clutch pulled in#Start with your right wrist flat#Only ride in lower gears"},
{"testId":77,"qid":11555,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn the driver behind you, flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#32708","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid riding at night"},
{"testId":301,"qid":11555,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn the driver behind you, flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#32708","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid riding at night"},
{"testId":487,"qid":11555,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn the driver behind you, flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#32708","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid riding at night"},
{"testId":507,"qid":11556,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"5000#5002#6209#10878","text":"Six#Five#Three#Seven"},
{"testId":52,"qid":11557,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":58,"qid":11557,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":334,"qid":11557,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":483,"qid":11557,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":60,"qid":11558,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":310,"qid":11558,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":52,"qid":11559,"description":"Wearing an approved helmet can save a motorcyclist from which of the following injuries?","explanation":"In most crashes, a motorcycle rider may avoid significant head and neck injuries by wearing an appropriate helmet. Riders who wear helmets are three times more likely to survive head injuries in collisions than those who do not.","imageName":"","correctAnswer":28408,"isBookmarked":0,"aid":"6060#6061#6062#28408","text":"Hitting other vehicles#Fire#Skidding#Serious head and neck injuries"},
{"testId":347,"qid":11559,"description":"Wearing an approved helmet can save a motorcyclist from which of the following injuries?","explanation":"In most crashes, a motorcycle rider may avoid significant head and neck injuries by wearing an appropriate helmet. Riders who wear helmets are three times more likely to survive head injuries in collisions than those who do not.","imageName":"","correctAnswer":28408,"isBookmarked":0,"aid":"6060#6061#6062#28408","text":"Hitting other vehicles#Fire#Skidding#Serious head and neck injuries"},
{"testId":422,"qid":11559,"description":"Wearing an approved helmet can save a motorcyclist from which of the following injuries?","explanation":"In most crashes, a motorcycle rider may avoid significant head and neck injuries by wearing an appropriate helmet. Riders who wear helmets are three times more likely to survive head injuries in collisions than those who do not.","imageName":"","correctAnswer":28408,"isBookmarked":0,"aid":"6060#6061#6062#28408","text":"Hitting other vehicles#Fire#Skidding#Serious head and neck injuries"},
{"testId":438,"qid":11559,"description":"Wearing an approved helmet can save a motorcyclist from which of the following injuries?","explanation":"In most crashes, a motorcycle rider may avoid significant head and neck injuries by wearing an appropriate helmet. Riders who wear helmets are three times more likely to survive head injuries in collisions than those who do not.","imageName":"","correctAnswer":28408,"isBookmarked":0,"aid":"6060#6061#6062#28408","text":"Hitting other vehicles#Fire#Skidding#Serious head and neck injuries"},
{"testId":81,"qid":11561,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6846#6847#6996#33955","text":"changing lanes#avoiding hazards from parked cars#being seen by the driver ahead of you#preparing to pass the vehicle ahead of you"},
{"testId":347,"qid":11561,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6846#6847#6996#33955","text":"changing lanes#avoiding hazards from parked cars#being seen by the driver ahead of you#preparing to pass the vehicle ahead of you"},
{"testId":425,"qid":11561,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6846#6847#6996#33955","text":"changing lanes#avoiding hazards from parked cars#being seen by the driver ahead of you#preparing to pass the vehicle ahead of you"},
{"testId":292,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":318,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":334,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":420,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":434,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":462,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":495,"qid":11562,"description":"You should ______ to use a motorcycle to transport cargo.","explanation":"Continue to push the burden. Place the weight over or in front of the rear axle if possible. (Loads placed behind the rear axle may produce a wobble.) Loads should be secured low or in saddlebags. Attaching a weight to the sissy bar elevates the center of gravity of the motorbike and might cause it to wobble.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6326#10656#10778#10779","text":"Distribute the load evenly#Do all of the above#Keep the load forward#Secure the load with elastic cords"},
{"testId":334,"qid":11563,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. These include riding on slick roads, travelling in congested areas, and being tailgated.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#6999#35527","text":"All of the above#In heavy traffic#On slippery roads#When you're being tailgated"},
{"testId":475,"qid":11563,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. These include riding on slick roads, travelling in congested areas, and being tailgated.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#6999#35527","text":"All of the above#In heavy traffic#On slippery roads#When you're being tailgated"},
{"testId":495,"qid":11563,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. These include riding on slick roads, travelling in congested areas, and being tailgated.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#6999#35527","text":"All of the above#In heavy traffic#On slippery roads#When you're being tailgated"},
{"testId":483,"qid":11564,"description":"The LEAST effective technique of attaching baggage to your motorcycle is to utilise:","explanation":"Never use ropes to secure luggage to a motorbike. Over time, ropes might get looser or knots can unravel. A better option would be elastic cords.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":297,"qid":11566,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and brakes as you approach an intersection where a vehicle may cross your path to decrease response time.","imageName":"","correctAnswer":38713,"isBookmarked":0,"aid":"10521#10522#28931#38713","text":"come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#Beep your horn to warn other vehicle operators#cover the clutch and the brakes"},
{"testId":495,"qid":11566,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and brakes as you approach an intersection where a vehicle may cross your path to decrease response time.","imageName":"","correctAnswer":38713,"isBookmarked":0,"aid":"10521#10522#28931#38713","text":"come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#Beep your horn to warn other vehicle operators#cover the clutch and the brakes"},
{"testId":297,"qid":11567,"description":"When you're going around a curve, the best place to be in your lane is:","explanation":"When riding through a curve, the best lane position will fluctuate along the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":314,"qid":11567,"description":"When you're going around a curve, the best place to be in your lane is:","explanation":"When riding through a curve, the best lane position will fluctuate along the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":429,"qid":11567,"description":"When you're going around a curve, the best place to be in your lane is:","explanation":"When riding through a curve, the best lane position will fluctuate along the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":431,"qid":11567,"description":"When you're going around a curve, the best place to be in your lane is:","explanation":"When riding through a curve, the best lane position will fluctuate along the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":292,"qid":11568,"description":"When should you maintain a space cushion behind the vehicle in front of you?","explanation":"You should always have an appropriate space cushion around your motorbike. This keeps you secure and provides you time to respond.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#10364#10365#24786","text":"At all times#When carrying passengers#When carrying cargo#When you first learn to ride"},
{"testId":299,"qid":11568,"description":"When should you maintain a space cushion behind the vehicle in front of you?","explanation":"You should always have an appropriate space cushion around your motorbike. This keeps you secure and provides you time to respond.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#10364#10365#24786","text":"At all times#When carrying passengers#When carrying cargo#When you first learn to ride"},
{"testId":491,"qid":11568,"description":"When should you maintain a space cushion behind the vehicle in front of you?","explanation":"You should always have an appropriate space cushion around your motorbike. This keeps you secure and provides you time to respond.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#10364#10365#24786","text":"At all times#When carrying passengers#When carrying cargo#When you first learn to ride"},
{"testId":300,"qid":11569,"description":"Using _____ is the best way to secure cargo on a motorcycle.","explanation":"Elastic ropes are the finest method to secure baggage to a motorbike. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":8225,"isBookmarked":0,"aid":"8225#23057#23058#23059","text":"Elastic cords#strong rope#the sissy bar#strips of cloth"},
{"testId":347,"qid":11569,"description":"Using _____ is the best way to secure cargo on a motorcycle.","explanation":"Elastic ropes are the finest method to secure baggage to a motorbike. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":8225,"isBookmarked":0,"aid":"8225#23057#23058#23059","text":"Elastic cords#strong rope#the sissy bar#strips of cloth"},
{"testId":52,"qid":11572,"description":"When riding a motorcycle on a slippery surface, you should _____ to keep from skidding.","explanation":"On a slippery surface, it takes longer to stop and a skid is more likely. Therefore, you should slow down and increase your following distance. It is particularly important to reduce your speed on curves. Remember, speed limits posted on curves only apply to good surface conditions.","imageName":"","correctAnswer":757,"isBookmarked":0,"aid":"757#14064#22864#29448","text":"slow down#speed up#lean your motorcycle in any direction#shift into a higher gear"},
{"testId":292,"qid":11574,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#30820","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same as it would without a passenger"},
{"testId":379,"qid":11574,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#30820","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same as it would without a passenger"},
{"testId":400,"qid":11574,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#30820","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same as it would without a passenger"},
{"testId":487,"qid":11574,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#30820","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same as it would without a passenger"},
{"testId":507,"qid":11574,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":6439,"isBookmarked":0,"aid":"6439#6440#6441#30820","text":"respond slower than normal#easily tip over#respond quicker than normal#respond the same as it would without a passenger"},
{"testId":491,"qid":11575,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"You may make yourself more visible to other drivers by flashing your brake light, wearing brightly colored clothes, and utilizing your lights and turn signals.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10718#10784#23000#30451","text":"Flash your brake light#Wear bright clothing#Do all of the above#Use your signal lights"},
{"testId":58,"qid":11577,"description":"When stopping at a stop sign on a regular basis, you should:","explanation":"When coming to a halt at a stop sign, utilize both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"22259#22260#28935#34234","text":"use only the front brake#use only the rear brake#use both the front and rear brakes#downshift until you're rolling to a stop"},
{"testId":425,"qid":11577,"description":"When stopping at a stop sign on a regular basis, you should:","explanation":"When coming to a halt at a stop sign, utilize both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"22259#22260#28935#34234","text":"use only the front brake#use only the rear brake#use both the front and rear brakes#downshift until you're rolling to a stop"},
{"testId":61,"qid":11581,"description":"To slow down, which brakes should you use?","explanation":"Use both the front and rear brakes to slow down. Despite the fact that the front brake is more powerful than the rear brake, both should be utilized.","imageName":"","correctAnswer":24827,"isBookmarked":0,"aid":"24827#34235#36109#36110","text":"Both the front and rear brakes at the same time#Either the front brake or the rear brake#Only the front brake#Only the rear brake"},
{"testId":314,"qid":11581,"description":"To slow down, which brakes should you use?","explanation":"Use both the front and rear brakes to slow down. Despite the fact that the front brake is more powerful than the rear brake, both should be utilized.","imageName":"","correctAnswer":24827,"isBookmarked":0,"aid":"24827#34235#36109#36110","text":"Both the front and rear brakes at the same time#Either the front brake or the rear brake#Only the front brake#Only the rear brake"},
{"testId":347,"qid":11581,"description":"To slow down, which brakes should you use?","explanation":"Use both the front and rear brakes to slow down. Despite the fact that the front brake is more powerful than the rear brake, both should be utilized.","imageName":"","correctAnswer":24827,"isBookmarked":0,"aid":"24827#34235#36109#36110","text":"Both the front and rear brakes at the same time#Either the front brake or the rear brake#Only the front brake#Only the rear brake"},
{"testId":464,"qid":11581,"description":"To slow down, which brakes should you use?","explanation":"Use both the front and rear brakes to slow down. Despite the fact that the front brake is more powerful than the rear brake, both should be utilized.","imageName":"","correctAnswer":24827,"isBookmarked":0,"aid":"24827#34235#36109#36110","text":"Both the front and rear brakes at the same time#Either the front brake or the rear brake#Only the front brake#Only the rear brake"},
{"testId":499,"qid":11587,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure your motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":34229,"isBookmarked":0,"aid":"24808#29383#31791#34229","text":"Never downshift below second gear#Downshift only if you are an experienced rider#Brake while downshifting#Make sure your motorcycle is traveling slowly enough for the lower gear"},
{"testId":58,"qid":11590,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will NOT reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":71,"qid":11590,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will NOT reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":334,"qid":11590,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will NOT reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":396,"qid":11590,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will NOT reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":300,"qid":11591,"description":"Riding __________ lowers your visibility.","explanation":"Riding alongside other cars reduces your visibility since you may be in a vehicle's blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":431,"qid":11591,"description":"Riding __________ lowers your visibility.","explanation":"Riding alongside other cars reduces your visibility since you may be in a vehicle's blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":464,"qid":11591,"description":"Riding __________ lowers your visibility.","explanation":"Riding alongside other cars reduces your visibility since you may be in a vehicle's blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":487,"qid":11591,"description":"Riding __________ lowers your visibility.","explanation":"Riding alongside other cars reduces your visibility since you may be in a vehicle's blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":491,"qid":11591,"description":"Riding __________ lowers your visibility.","explanation":"Riding alongside other cars reduces your visibility since you may be in a vehicle's blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":343,"qid":11592,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is located on the left hand lever. The rear brake is controlled by the right foot pedal, while the gear shifter is controlled by the left foot lever.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":78,"qid":11603,"description":"What is the connection between gravel, lane markers, and road edges?","explanation":"Motorcycles have little grip on gravel, lane lines, and road margins.","imageName":"","correctAnswer":10810,"isBookmarked":0,"aid":"10808#10809#10810#10811","text":"They are present on all roads#They are areas where motorcycles cannot ride#They provide poor traction for motorcycles#They are the safest places to ride"},
{"testId":318,"qid":11603,"description":"What is the connection between gravel, lane markers, and road edges?","explanation":"Motorcycles have little grip on gravel, lane lines, and road margins.","imageName":"","correctAnswer":10810,"isBookmarked":0,"aid":"10808#10809#10810#10811","text":"They are present on all roads#They are areas where motorcycles cannot ride#They provide poor traction for motorcycles#They are the safest places to ride"},
{"testId":363,"qid":11603,"description":"What is the connection between gravel, lane markers, and road edges?","explanation":"Motorcycles have little grip on gravel, lane lines, and road margins.","imageName":"","correctAnswer":10810,"isBookmarked":0,"aid":"10808#10809#10810#10811","text":"They are present on all roads#They are areas where motorcycles cannot ride#They provide poor traction for motorcycles#They are the safest places to ride"},
{"testId":511,"qid":11603,"description":"What is the connection between gravel, lane markers, and road edges?","explanation":"Motorcycles have little grip on gravel, lane lines, and road margins.","imageName":"","correctAnswer":10810,"isBookmarked":0,"aid":"10808#10809#10810#10811","text":"They are present on all roads#They are areas where motorcycles cannot ride#They provide poor traction for motorcycles#They are the safest places to ride"},
{"testId":82,"qid":11604,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10812#10813#30828","text":"the lane next to you and the far lane#only the lane next to you#only the far lane#Your signal lights"},
{"testId":299,"qid":11604,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10812#10813#30828","text":"the lane next to you and the far lane#only the lane next to you#only the far lane#Your signal lights"},
{"testId":438,"qid":11604,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10812#10813#30828","text":"the lane next to you and the far lane#only the lane next to you#only the far lane#Your signal lights"},
{"testId":450,"qid":11604,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10812#10813#30828","text":"the lane next to you and the far lane#only the lane next to you#only the far lane#Your signal lights"},
{"testId":503,"qid":11604,"description":"On a multilane road, before changing lanes, driver should inspect:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":6412,"isBookmarked":0,"aid":"6412#10812#10813#30828","text":"the lane next to you and the far lane#only the lane next to you#only the far lane#Your signal lights"},
{"testId":499,"qid":11607,"description":"Which of the following best describes how to speed up your reflexes?","explanation":"Cover the clutch and the brakes to shorten your response time and allow you to respond more rapidly.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"10818#10819#27089#30514","text":"Decrease your following distance#Hover your feet above the ground#Cover the clutch and both brakes#Lock your elbows"},
{"testId":291,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":330,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":359,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":422,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":431,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":438,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":446,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":454,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":467,"qid":11608,"description":"For most effective steering, position your hands_______________.","explanation":"Your hands should be at or below elbow height for the most efficient steering.","imageName":"","correctAnswer":10820,"isBookmarked":0,"aid":"6268#6685#6686#10820","text":"with elbows locked#above your elbows#below your elbows#at or below elbow height"},
{"testId":291,"qid":11609,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":454,"qid":11609,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":464,"qid":11609,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":499,"qid":11609,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":293,"qid":11611,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright clothes, riding in a proper lane position, and utilizing your lights may help you be noticed.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#31451","text":"Use your headlight#Wear bright clothing#Do all of the above#Ride in a suitable lane position"},
{"testId":318,"qid":11611,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright clothes, riding in a proper lane position, and utilizing your lights may help you be noticed.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#31451","text":"Use your headlight#Wear bright clothing#Do all of the above#Ride in a suitable lane position"},
{"testId":462,"qid":11611,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright clothes, riding in a proper lane position, and utilizing your lights may help you be noticed.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#31451","text":"Use your headlight#Wear bright clothing#Do all of the above#Ride in a suitable lane position"},
{"testId":438,"qid":11613,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the foot pegs.","imageName":"","correctAnswer":24871,"isBookmarked":0,"aid":"8165#8167#10733#24871","text":"skimming them above the ground#holding them away from the motorcycle#pointing your toes downward#keeping them on the foot pegs"},
{"testId":475,"qid":11614,"description":"The quickest method to come to a halt is to:","explanation":"To come to a complete stop, use both the front and back brakes firmly without locking any wheel.","imageName":"","correctAnswer":31164,"isBookmarked":0,"aid":"6244#8049#10601#31164","text":"use the rear brake only#apply the front brake quickly#Pull the engine kill switch#apply both the front and rear brakes firmly"},
{"testId":483,"qid":11614,"description":"The quickest method to come to a halt is to:","explanation":"To come to a complete stop, use both the front and back brakes firmly without locking any wheel.","imageName":"","correctAnswer":31164,"isBookmarked":0,"aid":"6244#8049#10601#31164","text":"use the rear brake only#apply the front brake quickly#Pull the engine kill switch#apply both the front and rear brakes firmly"},
{"testId":499,"qid":11614,"description":"The quickest method to come to a halt is to:","explanation":"To come to a complete stop, use both the front and back brakes firmly without locking any wheel.","imageName":"","correctAnswer":31164,"isBookmarked":0,"aid":"6244#8049#10601#31164","text":"use the rear brake only#apply the front brake quickly#Pull the engine kill switch#apply both the front and rear brakes firmly"},
{"testId":467,"qid":11618,"description":"Motorcyclists should ride _____, when riding in a group.","explanation":"Motorcyclists should normally ride in a staggered arrangement while riding in a group. It keeps the group close while also providing some breathing room.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#23023","text":"single file#in pairs#in a staggered formation#in an alternate formation"},
{"testId":300,"qid":11619,"description":"You can assist yourself maintain your balance while cycling by:","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":7751,"isBookmarked":0,"aid":"7751#7752#10828#10829","text":"keeping your knees against the gas tank#locking your elbows#sitting as far back as possible#wearing lightweight riding gear"},
{"testId":458,"qid":11619,"description":"You can assist yourself maintain your balance while cycling by:","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":7751,"isBookmarked":0,"aid":"7751#7752#10828#10829","text":"keeping your knees against the gas tank#locking your elbows#sitting as far back as possible#wearing lightweight riding gear"},
{"testId":503,"qid":11619,"description":"You can assist yourself maintain your balance while cycling by:","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":7751,"isBookmarked":0,"aid":"7751#7752#10828#10829","text":"keeping your knees against the gas tank#locking your elbows#sitting as far back as possible#wearing lightweight riding gear"},
{"testId":56,"qid":11621,"description":"You're about to make a left turn. When is it appropriate to slow down?","explanation":"Before entering a turn, you should slow down. Hard braking in a turn increases the likelihood of a skid, especially in adverse weather.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":434,"qid":11621,"description":"You're about to make a left turn. When is it appropriate to slow down?","explanation":"Before entering a turn, you should slow down. Hard braking in a turn increases the likelihood of a skid, especially in adverse weather.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":454,"qid":11621,"description":"You're about to make a left turn. When is it appropriate to slow down?","explanation":"Before entering a turn, you should slow down. Hard braking in a turn increases the likelihood of a skid, especially in adverse weather.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":293,"qid":11622,"description":"You should position your feet _______ while riding a motorcycle.","explanation":"When riding a motorbike, keep your feet firmly planted on the foot pegs. This aids in maintaining equilibrium. If you point your feet downward, they may grab on anything.","imageName":"","correctAnswer":6748,"isBookmarked":0,"aid":"6173#6174#6176#6748","text":"with your toes pointed downward#to drag lightly on the ground#as far forward as possible#firmly on the foot pegs"},
{"testId":483,"qid":11624,"description":"In the SEE procedure's \"assess\" stage, what are you considering?","explanation":"You examine cars that may move into your path, potentially dangerous road conditions, traffic lights, and pavement markings during the \"evaluate\" stage of the SEE approach.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#10831#40646","text":"All of the above#Vehicles that may move into your path#Possibly hazardous road conditions#Traffic signals and pavement markings"},
{"testId":318,"qid":11625,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. You should turn toward the outside of the curve after the curve's midpoint. Your path's effective radius will grow as a result.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#6424#10833#10834","text":"Apply the rear brake#decrease the throttle#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":56,"qid":11626,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt. If this is not practicable, stop gently and gradually, but with less power than you would if the motorbike were straight. As you slow down, your lean angle decreases, and more traction becomes available for braking.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#10838#30098","text":"Lean over as much as possible#Increase your speed before stopping#Apply the rear brake only#Try to straighten your motorcycle upright"},
{"testId":464,"qid":11627,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If a tailgater is unable to pass you, increase your following distance so that both of you have adequate area to stop if a danger occurs.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6286#6392#6920","text":"pull over and stop#Flash your brake light#Call the police#Increase your following distance"},
{"testId":434,"qid":11629,"description":"You can keep a sufficient spacing cushion by:","explanation":"To avoid risks, alter your lane position as you ride to maintain an acceptable space cushion.","imageName":"","correctAnswer":6541,"isBookmarked":0,"aid":"6541#6543#35108#35109","text":"adjusting your lane position as you ride#always riding in the center lane position#speeding up when you're being tailgated#beeping your horn at vehicles that come too close"},
{"testId":471,"qid":11629,"description":"You can keep a sufficient spacing cushion by:","explanation":"To avoid risks, alter your lane position as you ride to maintain an acceptable space cushion.","imageName":"","correctAnswer":6541,"isBookmarked":0,"aid":"6541#6543#35108#35109","text":"adjusting your lane position as you ride#always riding in the center lane position#speeding up when you're being tailgated#beeping your horn at vehicles that come too close"},
{"testId":58,"qid":11630,"description":"You can balance your motorcycle while making a turn by:","explanation":"By keeping your knees against the gas tank, you can help keep your balance while turning. It makes it easier for you and the motorcycle to move together.","imageName":"","correctAnswer":6608,"isBookmarked":0,"aid":"6606#6607#6608#10777","text":"leaning the opposite way of the motorcycle#dragging your feet on the ground#keeping both knees against the gas tank#Doing all of the above"},
{"testId":450,"qid":11630,"description":"You can balance your motorcycle while making a turn by:","explanation":"By keeping your knees against the gas tank, you can help keep your balance while turning. It makes it easier for you and the motorcycle to move together.","imageName":"","correctAnswer":6608,"isBookmarked":0,"aid":"6606#6607#6608#10777","text":"leaning the opposite way of the motorcycle#dragging your feet on the ground#keeping both knees against the gas tank#Doing all of the above"},
{"testId":58,"qid":11631,"description":"The most secure way to use saddlebags is to:","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":6671,"isBookmarked":0,"aid":"6671#6674#24925#24926","text":"distribute the weight evenly between them#not carry a passenger at the same time#make sure all of them are full#use only one at a time"},
{"testId":400,"qid":11631,"description":"The most secure way to use saddlebags is to:","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":6671,"isBookmarked":0,"aid":"6671#6674#24925#24926","text":"distribute the weight evenly between them#not carry a passenger at the same time#make sure all of them are full#use only one at a time"},
{"testId":475,"qid":11631,"description":"The most secure way to use saddlebags is to:","explanation":"To keep your motorbike balanced, the best approach to utilize saddlebags is to divide the weight equally between them.","imageName":"","correctAnswer":6671,"isBookmarked":0,"aid":"6671#6674#24925#24926","text":"distribute the weight evenly between them#not carry a passenger at the same time#make sure all of them are full#use only one at a time"},
{"testId":60,"qid":11632,"description":"Your motorcycle should ______ while you are stopped at a stop sign.","explanation":"While you're stopped at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":6752,"isBookmarked":0,"aid":"6749#6750#6751#6752","text":"remain in neutral#remain in second gear#remain in third gear#remain in first gear"},
{"testId":347,"qid":11632,"description":"Your motorcycle should ______ while you are stopped at a stop sign.","explanation":"While you're stopped at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":6752,"isBookmarked":0,"aid":"6749#6750#6751#6752","text":"remain in neutral#remain in second gear#remain in third gear#remain in first gear"},
{"testId":471,"qid":11632,"description":"Your motorcycle should ______ while you are stopped at a stop sign.","explanation":"While you're stopped at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":6752,"isBookmarked":0,"aid":"6749#6750#6751#6752","text":"remain in neutral#remain in second gear#remain in third gear#remain in first gear"},
{"testId":420,"qid":11633,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Roll on the throttle to maintain or increase your speed to assist keep the motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":64,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":298,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":334,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":383,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":388,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":429,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":450,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":483,"qid":11636,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":66,"qid":11638,"description":"How frequently should you inspect your motorcycle's tires?","explanation":"Every time you ride your motorbike, you should inspect the tires. A motorbike tire issue might be even more dangerous than a vehicle tire problem.","imageName":"","correctAnswer":7241,"isBookmarked":0,"aid":"7239#7240#7241#7242","text":"Once a week#Once a month#Every time you ride#At the start of every season"},
{"testId":291,"qid":11638,"description":"How frequently should you inspect your motorcycle's tires?","explanation":"Every time you ride your motorbike, you should inspect the tires. A motorbike tire issue might be even more dangerous than a vehicle tire problem.","imageName":"","correctAnswer":7241,"isBookmarked":0,"aid":"7239#7240#7241#7242","text":"Once a week#Once a month#Every time you ride#At the start of every season"},
{"testId":429,"qid":11638,"description":"How frequently should you inspect your motorcycle's tires?","explanation":"Every time you ride your motorbike, you should inspect the tires. A motorbike tire issue might be even more dangerous than a vehicle tire problem.","imageName":"","correctAnswer":7241,"isBookmarked":0,"aid":"7239#7240#7241#7242","text":"Once a week#Once a month#Every time you ride#At the start of every season"},
{"testId":442,"qid":11638,"description":"How frequently should you inspect your motorcycle's tires?","explanation":"Every time you ride your motorbike, you should inspect the tires. A motorbike tire issue might be even more dangerous than a vehicle tire problem.","imageName":"","correctAnswer":7241,"isBookmarked":0,"aid":"7239#7240#7241#7242","text":"Once a week#Once a month#Every time you ride#At the start of every season"},
{"testId":297,"qid":11639,"description":"To swerve right, you have to:","explanation":"To swerve correctly, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":491,"qid":11639,"description":"To swerve right, you have to:","explanation":"To swerve correctly, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":454,"qid":11643,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"In any accident, wearing 1) a US DOT-compliant motorcycle safety helmet; 2) face or eye protection; and 3) protective gear such as a leather or long-sleeved jacket, long, heavy trousers over ankle boots, and full-fingered leather gloves will give you a significantly higher chance of avoiding severe harm.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":507,"qid":11643,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"In any accident, wearing 1) a US DOT-compliant motorcycle safety helmet; 2) face or eye protection; and 3) protective gear such as a leather or long-sleeved jacket, long, heavy trousers over ankle boots, and full-fingered leather gloves will give you a significantly higher chance of avoiding severe harm.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":347,"qid":11644,"description":"When should you use the front brake only?","explanation":"To stop, you should always engage both the front and rear brakes. If the back tire is flat, use just the front brake. Never use your brakes on a flat tire.","imageName":"","correctAnswer":29439,"isBookmarked":0,"aid":"6492#7498#29439#35521","text":"At all times#On surfaces with low traction#If the rear tire is flat#When you must stop quickly"},
{"testId":458,"qid":11644,"description":"When should you use the front brake only?","explanation":"To stop, you should always engage both the front and rear brakes. If the back tire is flat, use just the front brake. Never use your brakes on a flat tire.","imageName":"","correctAnswer":29439,"isBookmarked":0,"aid":"6492#7498#29439#35521","text":"At all times#On surfaces with low traction#If the rear tire is flat#When you must stop quickly"},
{"testId":314,"qid":11645,"description":"When should you avoid taking the right lane?","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":30477,"isBookmarked":0,"aid":"6334#7509#30477#30478","text":"Never#When there is a hazard in the center of the road#When you are being passed by another vehicle#When you are riding through a school zone"},
{"testId":425,"qid":11645,"description":"When should you avoid taking the right lane?","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":30477,"isBookmarked":0,"aid":"6334#7509#30477#30478","text":"Never#When there is a hazard in the center of the road#When you are being passed by another vehicle#When you are riding through a school zone"},
{"testId":442,"qid":11645,"description":"When should you avoid taking the right lane?","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":30477,"isBookmarked":0,"aid":"6334#7509#30477#30478","text":"Never#When there is a hazard in the center of the road#When you are being passed by another vehicle#When you are riding through a school zone"},
{"testId":70,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":297,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":334,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":383,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":400,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":479,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":487,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":507,"qid":11646,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7389#10601#24661#24872","text":"release both brakes and coast to a stop#Pull the engine kill switch#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":58,"qid":11653,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop ahead of the stop line. After halting, you may go ahead until you can see better.","imageName":"","correctAnswer":40645,"isBookmarked":0,"aid":"8118#8119#8120#40645","text":"where you can see both ways#with your rear tire on the stop line#straddling the stop line#before the stop line"},
{"testId":301,"qid":11655,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6763#7097","text":"Left#Center#Left or right#Center or right"},
{"testId":59,"qid":11660,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":23029,"isBookmarked":0,"aid":"10333#10334#23029#24600","text":"You are not in a blind spot#Your space cushion is maximized#All of the above are true#You are avoiding hazards"},
{"testId":291,"qid":11661,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"Rolling on the throttle to maintain or slightly increase your speed can assist steady your motorbike in a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8280#10354","text":"decreasing the throttle#pulling in the clutch#increasing the throttle#leaning forward"},
{"testId":483,"qid":11661,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"Rolling on the throttle to maintain or slightly increase your speed can assist steady your motorbike in a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8280#10354","text":"decreasing the throttle#pulling in the clutch#increasing the throttle#leaning forward"},
{"testId":491,"qid":11661,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"Rolling on the throttle to maintain or slightly increase your speed can assist steady your motorbike in a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8280#10354","text":"decreasing the throttle#pulling in the clutch#increasing the throttle#leaning forward"},
{"testId":294,"qid":11663,"description":"You can boost your visibility to others at a junction by:","explanation":"At a junction, increase your visibility by selecting the most visible lane position so that cars may see you.","imageName":"","correctAnswer":7081,"isBookmarked":0,"aid":"7078#7080#7081#32370","text":"coming to a complete stop#looking in your mirrors#choosing the most visible lane position#riding in a staggered formation"},
{"testId":296,"qid":11664,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":467,"qid":11664,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":491,"qid":11664,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":503,"qid":11664,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":330,"qid":11669,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":334,"qid":11669,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":442,"qid":11669,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":446,"qid":11669,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":483,"qid":11669,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The gear shifter is located on the left foot lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#8000#8001#31788","text":"right foot pedal#right hand lever#left hand lever#left foot lever"},
{"testId":64,"qid":11670,"description":"Reflective apparel is essential for safety:","explanation":"Reflective apparel is essential for safety both during the day and at night. You should always wear bright, luminous apparel.","imageName":"","correctAnswer":28958,"isBookmarked":0,"aid":"6350#7087#7089#28958","text":"at night#in rural areas only#in urban areas#in day and night"},
{"testId":302,"qid":11670,"description":"Reflective apparel is essential for safety:","explanation":"Reflective apparel is essential for safety both during the day and at night. You should always wear bright, luminous apparel.","imageName":"","correctAnswer":28958,"isBookmarked":0,"aid":"6350#7087#7089#28958","text":"at night#in rural areas only#in urban areas#in day and night"},
{"testId":52,"qid":11671,"description":"You should _____ to create more space and reduce the risk of hazards.","explanation":"You should announce your presence with your lights or horn to create more space and limit danger from hazards; alter your speed by accelerating, pausing, or halting; and adjust your position or direction to avoid hazards.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34815#34816#34817","text":"Do all of the above#use your lights and horn#adjust your speed#adjust your direction or position"},
{"testId":310,"qid":11671,"description":"You should _____ to create more space and reduce the risk of hazards.","explanation":"You should announce your presence with your lights or horn to create more space and limit danger from hazards; alter your speed by accelerating, pausing, or halting; and adjust your position or direction to avoid hazards.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34815#34816#34817","text":"Do all of the above#use your lights and horn#adjust your speed#adjust your direction or position"},
{"testId":334,"qid":11671,"description":"You should _____ to create more space and reduce the risk of hazards.","explanation":"You should announce your presence with your lights or horn to create more space and limit danger from hazards; alter your speed by accelerating, pausing, or halting; and adjust your position or direction to avoid hazards.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34815#34816#34817","text":"Do all of the above#use your lights and horn#adjust your speed#adjust your direction or position"},
{"testId":503,"qid":11671,"description":"You should _____ to create more space and reduce the risk of hazards.","explanation":"You should announce your presence with your lights or horn to create more space and limit danger from hazards; alter your speed by accelerating, pausing, or halting; and adjust your position or direction to avoid hazards.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34815#34816#34817","text":"Do all of the above#use your lights and horn#adjust your speed#adjust your direction or position"},
{"testId":507,"qid":11671,"description":"You should _____ to create more space and reduce the risk of hazards.","explanation":"You should announce your presence with your lights or horn to create more space and limit danger from hazards; alter your speed by accelerating, pausing, or halting; and adjust your position or direction to avoid hazards.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#34815#34816#34817","text":"Do all of the above#use your lights and horn#adjust your speed#adjust your direction or position"},
{"testId":52,"qid":11672,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Use your high-beam headlight at night when you are not approaching or following another vehicle. When approaching or following a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":310,"qid":11672,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Use your high-beam headlight at night when you are not approaching or following another vehicle. When approaching or following a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":334,"qid":11672,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Use your high-beam headlight at night when you are not approaching or following another vehicle. When approaching or following a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":454,"qid":11672,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Use your high-beam headlight at night when you are not approaching or following another vehicle. When approaching or following a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":471,"qid":11672,"description":"When driving at night, use your high beam whenever you are _________ another vehicle.","explanation":"Use your high-beam headlight at night when you are not approaching or following another vehicle. When approaching or following a vehicle, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":310,"qid":11673,"description":"Maintaining or increasing your speed slightly during a turn:","explanation":"Maintaining or slightly increasing your speed in a turn aids with motorcycle stability.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7596#24796#33607","text":"helps stabilize the motorcycle#should never be done#should only be done when passing others#is safer because you'll complete the turn sooner"},
{"testId":310,"qid":11674,"description":"When preparing to pass the vehicle in front of you, ride in the _______ of your lane to increase your field of sight.","explanation":"Ride on the left side of your lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats.","imageName":"","correctAnswer":6211,"isBookmarked":0,"aid":"6210#6211#6212#6213","text":"the center portion#the left portion#the right portion#any portion"},
{"testId":334,"qid":11674,"description":"When preparing to pass the vehicle in front of you, ride in the _______ of your lane to increase your field of sight.","explanation":"Ride on the left side of your lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats.","imageName":"","correctAnswer":6211,"isBookmarked":0,"aid":"6210#6211#6212#6213","text":"the center portion#the left portion#the right portion#any portion"},
{"testId":310,"qid":11676,"description":"Which lane should you be in if there are incoming automobiles to your left and parked cars to your right?","explanation":"If you have incoming traffic on your left and a line of parked automobiles to your right, ride in the middle lane to provide a space cushion between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":442,"qid":11676,"description":"Which lane should you be in if there are incoming automobiles to your left and parked cars to your right?","explanation":"If you have incoming traffic on your left and a line of parked automobiles to your right, ride in the middle lane to provide a space cushion between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":479,"qid":11676,"description":"Which lane should you be in if there are incoming automobiles to your left and parked cars to your right?","explanation":"If you have incoming traffic on your left and a line of parked automobiles to your right, ride in the middle lane to provide a space cushion between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":491,"qid":11676,"description":"Which lane should you be in if there are incoming automobiles to your left and parked cars to your right?","explanation":"If you have incoming traffic on your left and a line of parked automobiles to your right, ride in the middle lane to provide a space cushion between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":507,"qid":11676,"description":"Which lane should you be in if there are incoming automobiles to your left and parked cars to your right?","explanation":"If you have incoming traffic on your left and a line of parked automobiles to your right, ride in the middle lane to provide a space cushion between you and both risks.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":310,"qid":11677,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#10866","text":"45-degree#90-degree#25-degree#135-degree"},
{"testId":56,"qid":11678,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#39856","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility to others"},
{"testId":310,"qid":11678,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#39856","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility to others"},
{"testId":495,"qid":11678,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#39856","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility to others"},
{"testId":56,"qid":11679,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":310,"qid":11679,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":429,"qid":11679,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":467,"qid":11679,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":487,"qid":11679,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":310,"qid":11680,"description":"When riding at night, you can improve your safety by:","explanation":"You may boost your safety while riding at night by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":416,"qid":11680,"description":"When riding at night, you can improve your safety by:","explanation":"You may boost your safety while riding at night by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":479,"qid":11680,"description":"When riding at night, you can improve your safety by:","explanation":"You may boost your safety while riding at night by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":487,"qid":11680,"description":"When riding at night, you can improve your safety by:","explanation":"You may boost your safety while riding at night by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":310,"qid":11681,"description":"How should you park your motorcycle when it's next to a curb?","explanation":"When parked along the road, your motorbike should be angled with the back wheel towards the curb.","imageName":"","correctAnswer":10869,"isBookmarked":0,"aid":"6655#6656#10869#40982","text":"At an angle with the front wheel touching the curb#Parallel to the curb#At an angle with the rear wheel touching the curb#Lying on the curb"},
{"testId":310,"qid":11683,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"Motorcycle brake lights are less visible than automobile brake lights. When you believe your brake light is not being noticed, flash it.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#30809#31755","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#flash your brake light whenever you think it may not be seen#brake only when absolutely necessary"},
{"testId":61,"qid":11684,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"On a slippery surface, ride as slowly as possible and let your feet skim the surface. If the motorcycle starts to tip over, you can steady it with your feet.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":310,"qid":11684,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"On a slippery surface, ride as slowly as possible and let your feet skim the surface. If the motorcycle starts to tip over, you can steady it with your feet.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":420,"qid":11684,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"On a slippery surface, ride as slowly as possible and let your feet skim the surface. If the motorcycle starts to tip over, you can steady it with your feet.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":467,"qid":11684,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"On a slippery surface, ride as slowly as possible and let your feet skim the surface. If the motorcycle starts to tip over, you can steady it with your feet.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":310,"qid":11686,"description":"When you are about to stop, you should flash your brake light if you are:","explanation":"When you are going to stop and are being followed too closely, stopping in an odd location, or stopping faster than others may anticipate, you should flash your brake light.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7025#7026#7027#22688","text":"being followed too closely#stopping in an unusual place#stopping more quickly than others will expect#In any of the above situations"},
{"testId":310,"qid":11687,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":310,"qid":11688,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handlebar.","imageName":"","correctAnswer":32685,"isBookmarked":0,"aid":"7117#31788#32684#32685","text":"right foot pedal#left foot lever#right handlegrip#left handlegrip"},
{"testId":343,"qid":11688,"description":"The clutch is situated at:","explanation":"The clutch is the lever on the left handlebar.","imageName":"","correctAnswer":32685,"isBookmarked":0,"aid":"7117#31788#32684#32685","text":"right foot pedal#left foot lever#right handlegrip#left handlegrip"},
{"testId":310,"qid":11689,"description":"For the most stable riding, position your knees and feet__________.","explanation":"For the most secure riding posture, place your knees against the gas tank and your feet firmly on the foot pegs.","imageName":"","correctAnswer":7229,"isBookmarked":0,"aid":"7229#36100#36101#41488","text":"against the gas tank and firmly on the foot pegs#against the gas tank and pointing downward#away from the gas tank and pointing downward#away from the gas tank and firmly on the foot pegs"},
{"testId":298,"qid":11690,"description":"How else can you boost your visibility besides wearing bright clothing?","explanation":"In addition to wearing bright clothes, you may improve your visibility to others by wearing reflective clothing. This is particularly useful at night.","imageName":"","correctAnswer":10581,"isBookmarked":0,"aid":"6238#10581#10583#30769","text":"Reduce your speed#Wear reflective clothing#Wear a full-face helmet#Ride only in the left lane position"},
{"testId":310,"qid":11690,"description":"How else can you boost your visibility besides wearing bright clothing?","explanation":"In addition to wearing bright clothes, you may improve your visibility to others by wearing reflective clothing. This is particularly useful at night.","imageName":"","correctAnswer":10581,"isBookmarked":0,"aid":"6238#10581#10583#30769","text":"Reduce your speed#Wear reflective clothing#Wear a full-face helmet#Ride only in the left lane position"},
{"testId":499,"qid":11690,"description":"How else can you boost your visibility besides wearing bright clothing?","explanation":"In addition to wearing bright clothes, you may improve your visibility to others by wearing reflective clothing. This is particularly useful at night.","imageName":"","correctAnswer":10581,"isBookmarked":0,"aid":"6238#10581#10583#30769","text":"Reduce your speed#Wear reflective clothing#Wear a full-face helmet#Ride only in the left lane position"},
{"testId":56,"qid":11691,"description":"Which of the following is a risk of passing a line of parked cars?","explanation":"People may not see you if you are traveling through a line of parked automobiles. Someone may pull out in front of you, open a door into your path, or walk out from between automobiles. Select an appropriate lane position to preserve your distance from the parked automobiles.","imageName":"","correctAnswer":33925,"isBookmarked":0,"aid":"31453#31455#33925#39858","text":"A car may pull out in front of you#People may step out from between cars#All of the above are hazards#A door may be opened into your path"},
{"testId":300,"qid":11691,"description":"Which of the following is a risk of passing a line of parked cars?","explanation":"People may not see you if you are traveling through a line of parked automobiles. Someone may pull out in front of you, open a door into your path, or walk out from between automobiles. Select an appropriate lane position to preserve your distance from the parked automobiles.","imageName":"","correctAnswer":33925,"isBookmarked":0,"aid":"31453#31455#33925#39858","text":"A car may pull out in front of you#People may step out from between cars#All of the above are hazards#A door may be opened into your path"},
{"testId":302,"qid":11691,"description":"Which of the following is a risk of passing a line of parked cars?","explanation":"People may not see you if you are traveling through a line of parked automobiles. Someone may pull out in front of you, open a door into your path, or walk out from between automobiles. Select an appropriate lane position to preserve your distance from the parked automobiles.","imageName":"","correctAnswer":33925,"isBookmarked":0,"aid":"31453#31455#33925#39858","text":"A car may pull out in front of you#People may step out from between cars#All of the above are hazards#A door may be opened into your path"},
{"testId":310,"qid":11691,"description":"Which of the following is a risk of passing a line of parked cars?","explanation":"People may not see you if you are traveling through a line of parked automobiles. Someone may pull out in front of you, open a door into your path, or walk out from between automobiles. Select an appropriate lane position to preserve your distance from the parked automobiles.","imageName":"","correctAnswer":33925,"isBookmarked":0,"aid":"31453#31455#33925#39858","text":"A car may pull out in front of you#People may step out from between cars#All of the above are hazards#A door may be opened into your path"},
{"testId":310,"qid":11692,"description":"Riding along the road's side can be hazardous because:","explanation":"The road's edge is problematic for motorcyclists because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7423#7845#24857#28376","text":"you may hit pedestrians#dirt and gravel provide little traction#the pavement is of poor quality#riding there is prohibited"},
{"testId":347,"qid":11692,"description":"Riding along the road's side can be hazardous because:","explanation":"The road's edge is problematic for motorcyclists because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7423#7845#24857#28376","text":"you may hit pedestrians#dirt and gravel provide little traction#the pavement is of poor quality#riding there is prohibited"},
{"testId":479,"qid":11692,"description":"Riding along the road's side can be hazardous because:","explanation":"The road's edge is problematic for motorcyclists because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7423#7845#24857#28376","text":"you may hit pedestrians#dirt and gravel provide little traction#the pavement is of poor quality#riding there is prohibited"},
{"testId":310,"qid":11695,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"5000#5002#6209#10878","text":"Six#Five#Three#Seven"},
{"testId":495,"qid":11695,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"5000#5002#6209#10878","text":"Six#Five#Three#Seven"},
{"testId":296,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":310,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":462,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":475,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":487,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":507,"qid":11696,"description":"Which lane position is optimal for visibility?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":310,"qid":11698,"description":"you must to wear eye protection:","explanation":"If your motorbike lacks a windshield that reaches at least 15 inches above the tallest point of the handlebars, Alaska law requires you to wear eye protection. Even though your motorbike has a windshield, you should consider wearing eye protection. A windshield is ineffective in comparison to a face shield or goggles. [13 AAC 04:345], [13 AAC 04:345], .355]","imageName":"","correctAnswer":42538,"isBookmarked":0,"aid":"7866#33930#36106#42538","text":"only while riding on highways and freeways#at all times while riding#only while riding in bad weather#only if you're riding a motorcycle without a tall windshield"},
{"testId":77,"qid":11699,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":310,"qid":11699,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":343,"qid":11699,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":408,"qid":11699,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":464,"qid":11699,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":310,"qid":11701,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#10572#26512","text":"Get a special license#Avoid carrying passengers#Ride only during the day#Turn the headlight on whenever you ride"},
{"testId":318,"qid":11701,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#10572#26512","text":"Get a special license#Avoid carrying passengers#Ride only during the day#Turn the headlight on whenever you ride"},
{"testId":429,"qid":11701,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#10572#26512","text":"Get a special license#Avoid carrying passengers#Ride only during the day#Turn the headlight on whenever you ride"},
{"testId":479,"qid":11701,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#10572#26512","text":"Get a special license#Avoid carrying passengers#Ride only during the day#Turn the headlight on whenever you ride"},
{"testId":310,"qid":11702,"description":"Using a motorbike to navigate between traffic lanes:","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes is forbidden in Alaska. [13 AAC 02 .427]","imageName":"","correctAnswer":39138,"isBookmarked":0,"aid":"33613#39138#41329#42539","text":"allowed only in designated areas#is never allowed#is allowed only when traffic is at a standstill#is not allowed when riding in a group"},
{"testId":310,"qid":11703,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake accounts for around 70% of your motorcycle's stopping force. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":82,"qid":11704,"description":"The road ahead appears to be slick. What should you do?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10884#10885#10886#10887","text":"Use your rear brake only#Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section"},
{"testId":310,"qid":11704,"description":"The road ahead appears to be slick. What should you do?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10884#10885#10886#10887","text":"Use your rear brake only#Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section"},
{"testId":388,"qid":11704,"description":"The road ahead appears to be slick. What should you do?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10884#10885#10886#10887","text":"Use your rear brake only#Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section"},
{"testId":310,"qid":11705,"description":"Is sharing a lane with another motorbike safe?","explanation":"Motorcycles may lawfully go two abreast (pair up) in a single lane in Alaska. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane. [13 AAC 02.427] .427]","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":83,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":299,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":310,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":379,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":392,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":471,"qid":11706,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of stuff that adds a lot of weight to the motorbike, you may need to modify the suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":310,"qid":11707,"description":"Drivers in other vehicle present a special hazard when:","explanation":"A motorist inside a parked vehicle you are passing may miss you and open a door into your path.","imageName":"","correctAnswer":24735,"isBookmarked":0,"aid":"8369#23070#23071#24735","text":"you are making a right turn#you are parking your motorcycle#you are riding through a school zone#You are passing a line of parked cars"},
{"testId":56,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":85,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":310,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":314,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":334,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":383,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":392,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":416,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":434,"qid":11708,"description":"As you get closer to an obstacle that you'll have to ride over, you should:","explanation":"You should try to slow down as much as you can when you approach a barrier.","imageName":"","correctAnswer":6514,"isBookmarked":0,"aid":"6513#6514#6515#7651","text":"maintain the same speed#slow down as much as possible#Increase your speed#Come to a complete stop"},
{"testId":310,"qid":11709,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":293,"qid":11710,"description":"Before you start your motorcycle engine, be sure your transmission is in ________.","explanation":"The gearbox on your motorbike should be in neutral before you start the engine. Start your motorbike in neutral.","imageName":"","correctAnswer":10439,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":310,"qid":11710,"description":"Before you start your motorcycle engine, be sure your transmission is in ________.","explanation":"The gearbox on your motorbike should be in neutral before you start the engine. Start your motorbike in neutral.","imageName":"","correctAnswer":10439,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":458,"qid":11710,"description":"Before you start your motorcycle engine, be sure your transmission is in ________.","explanation":"The gearbox on your motorbike should be in neutral before you start the engine. Start your motorbike in neutral.","imageName":"","correctAnswer":10439,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":471,"qid":11710,"description":"Before you start your motorcycle engine, be sure your transmission is in ________.","explanation":"The gearbox on your motorbike should be in neutral before you start the engine. Start your motorbike in neutral.","imageName":"","correctAnswer":10439,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":475,"qid":11710,"description":"Before you start your motorcycle engine, be sure your transmission is in ________.","explanation":"The gearbox on your motorbike should be in neutral before you start the engine. Start your motorbike in neutral.","imageName":"","correctAnswer":10439,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":295,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":310,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":334,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":347,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":467,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":471,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":495,"qid":11712,"description":"Rolling the throttle through a turn will:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#40122","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help slow the motorcycle"},
{"testId":310,"qid":11714,"description":"If your motorcycle has a tall windscreen,:","explanation":"Both you and your passenger are required by Alaska law to wear helmets at all times when riding. In addition, if your motorbike lacks a windshield that reaches at least 15 inches above the highest point of the handlebars, you must wear eye protection. Even though your motorbike has a windshield, you should consider wearing eye protection. A windshield is ineffective in comparison to a face shield or goggles. [13 AAC 04:345], [13 AAC 04:345], .355]","imageName":"","correctAnswer":10533,"isBookmarked":0,"aid":"10533#10891#33622#42540","text":"you are not required to wear eye protection#you are still required to wear eye protection#you are not required to wear a helmet#you are not required to wear either a helmet or eye protection"},
{"testId":58,"qid":11716,"description":"When should you adjust the side mirrors on your motorcycle?","explanation":"Before each ride, clean and adjust the side mirrors. It can be difficult and dangerous to adjust them while you're riding.","imageName":"","correctAnswer":36089,"isBookmarked":0,"aid":"9435#24787#36089#36090","text":"At any time#While you are riding#Before you start to ride#At the first stop after you've started to ride"},
{"testId":299,"qid":11716,"description":"When should you adjust the side mirrors on your motorcycle?","explanation":"Before each ride, clean and adjust the side mirrors. It can be difficult and dangerous to adjust them while you're riding.","imageName":"","correctAnswer":36089,"isBookmarked":0,"aid":"9435#24787#36089#36090","text":"At any time#While you are riding#Before you start to ride#At the first stop after you've started to ride"},
{"testId":310,"qid":11716,"description":"When should you adjust the side mirrors on your motorcycle?","explanation":"Before each ride, clean and adjust the side mirrors. It can be difficult and dangerous to adjust them while you're riding.","imageName":"","correctAnswer":36089,"isBookmarked":0,"aid":"9435#24787#36089#36090","text":"At any time#While you are riding#Before you start to ride#At the first stop after you've started to ride"},
{"testId":347,"qid":11716,"description":"When should you adjust the side mirrors on your motorcycle?","explanation":"Before each ride, clean and adjust the side mirrors. It can be difficult and dangerous to adjust them while you're riding.","imageName":"","correctAnswer":36089,"isBookmarked":0,"aid":"9435#24787#36089#36090","text":"At any time#While you are riding#Before you start to ride#At the first stop after you've started to ride"},
{"testId":310,"qid":11718,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"According to Alaskan law, drivers of moving vehicles must give way to pedestrians who are crossing the street legally, whether a crosswalk is designated or not. If a pedestrian has just begun to cross the street at a crosswalk, stop before the crosswalk and wait for the person to complete the crossing. [13 AAC 02 .155]","imageName":"","correctAnswer":39142,"isBookmarked":0,"aid":"23036#23037#23038#39142","text":"Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian#Stop and wait for the pedestrian to cross your half of the roadway"},
{"testId":310,"qid":11719,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Larger, heavier vehicles need a greater stopping distance than motorbikes. If you abruptly slow down in front of a huge car, the large vehicle may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10565#10894#34232","text":"slowing down suddenly when you merge back in front of the vehicle#passing without a dedicated passing lane#flashing your headlight#beeping your horn when you're alongside the vehicle"},
{"testId":310,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":416,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":454,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":462,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":479,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":483,"qid":11720,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both the front and rear brakes to travel safely on slick roads. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":343,"qid":11721,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handlegrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":422,"qid":11721,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handlegrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":471,"qid":11724,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"With a passenger, your motorbike will react more slowly. As a result, you should ride a little slower, particularly when approaching curves, bends, or bumps.","imageName":"","correctAnswer":6250,"isBookmarked":0,"aid":"6250#6251#30769#40461","text":"Ride slower#Ride faster#Ride only in the left lane position#Ride only in the center lane position"},
{"testId":56,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":294,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":422,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":425,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":429,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":475,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":491,"qid":11726,"description":"On a straightaway, if the back wheel locks, you should:","explanation":"Do not remove the back wheel if it locks on a straightaway. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":60,"qid":11730,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, twist it back and forth to dislodge it. If it doesn't work, turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":39523,"isBookmarked":0,"aid":"29687#30447#39523#41322","text":"Pull off the road#Pull the engine cut-off switch#Twist it back and forth#Apply both brakes smoothly"},
{"testId":334,"qid":11730,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, twist it back and forth to dislodge it. If it doesn't work, turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":39523,"isBookmarked":0,"aid":"29687#30447#39523#41322","text":"Pull off the road#Pull the engine cut-off switch#Twist it back and forth#Apply both brakes smoothly"},
{"testId":425,"qid":11731,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6821#30790","text":"the left hand lever#the right hand lever#a foot pedal#a button on the handlegrip"},
{"testId":507,"qid":11732,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"When ready to pass, riding in the left lane will provide you a better line of sight. This lane position allows you to look beyond the car in front of you and detect impending dangers. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6552#6555#6853#6854","text":"riding in the left lane position#riding in the right lane position#pulling into oncoming traffic#riding in the center lane position"},
{"testId":61,"qid":11733,"description":"Riding beside another vehicle is risky because:","explanation":"Riding beside a vehicle may be risky since you might be riding in a blind zone, things could be flung out of a window and strike you, or you could crash with an extended side mirror.","imageName":"","correctAnswer":34563,"isBookmarked":0,"aid":"6855#34561#34562#34563","text":"you may be riding in a blind spot#objects may be thrown from a window#you may be hit by an extended side mirror#any of the above may happen"},
{"testId":458,"qid":11733,"description":"Riding beside another vehicle is risky because:","explanation":"Riding beside a vehicle may be risky since you might be riding in a blind zone, things could be flung out of a window and strike you, or you could crash with an extended side mirror.","imageName":"","correctAnswer":34563,"isBookmarked":0,"aid":"6855#34561#34562#34563","text":"you may be riding in a blind spot#objects may be thrown from a window#you may be hit by an extended side mirror#any of the above may happen"},
{"testId":431,"qid":11739,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":438,"qid":11739,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":483,"qid":11742,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":495,"qid":11742,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":499,"qid":11742,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":67,"qid":11743,"description":"The most effective approach to reduce your risks of skidding on a slick surface is to:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":7333,"isBookmarked":0,"aid":"7332#7333#7334#10656","text":"drag your feet on the ground#slow down before you get to the slippery surface#ride on the shoulder of the road#Do all of the above"},
{"testId":326,"qid":11743,"description":"The most effective approach to reduce your risks of skidding on a slick surface is to:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":7333,"isBookmarked":0,"aid":"7332#7333#7334#10656","text":"drag your feet on the ground#slow down before you get to the slippery surface#ride on the shoulder of the road#Do all of the above"},
{"testId":392,"qid":11743,"description":"The most effective approach to reduce your risks of skidding on a slick surface is to:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":7333,"isBookmarked":0,"aid":"7332#7333#7334#10656","text":"drag your feet on the ground#slow down before you get to the slippery surface#ride on the shoulder of the road#Do all of the above"},
{"testId":425,"qid":11743,"description":"The most effective approach to reduce your risks of skidding on a slick surface is to:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":7333,"isBookmarked":0,"aid":"7332#7333#7334#10656","text":"drag your feet on the ground#slow down before you get to the slippery surface#ride on the shoulder of the road#Do all of the above"},
{"testId":334,"qid":11744,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":343,"qid":11744,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":495,"qid":11744,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":487,"qid":11746,"description":"You should _______, when braking on slick conditions.","explanation":"You should utilize both the front and rear brakes while stopping on slippery ground. It is always preferable to utilize both brakes. To prevent locking the front wheel, use the front brake gradually and the rear brake softly.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#7398#28935","text":"use the rear brake only#Use the front brake only#always coast in neutral#use both the front and rear brakes"},
{"testId":507,"qid":11746,"description":"You should _______, when braking on slick conditions.","explanation":"You should utilize both the front and rear brakes while stopping on slippery ground. It is always preferable to utilize both brakes. To prevent locking the front wheel, use the front brake gradually and the rear brake softly.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#7398#28935","text":"use the rear brake only#Use the front brake only#always coast in neutral#use both the front and rear brakes"},
{"testId":454,"qid":11747,"description":"Curved acceleration:","explanation":"Rolling on the throttle to maintain or slightly increase your speed in a bend helps steady the motorbike. At lower speeds, motorcycles are less stable.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7489#7490#7491","text":"helps stabilize the motorcycle#should not be done#should be done only in small curves#is for experienced riders only"},
{"testId":458,"qid":11747,"description":"Curved acceleration:","explanation":"Rolling on the throttle to maintain or slightly increase your speed in a bend helps steady the motorbike. At lower speeds, motorcycles are less stable.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7489#7490#7491","text":"helps stabilize the motorcycle#should not be done#should be done only in small curves#is for experienced riders only"},
{"testId":302,"qid":11748,"description":"To minimize weariness as you ride, you should:","explanation":"Riding a motorbike is more exhausting than driving a vehicle. You should stop and relax at least once every two hours to prevent weariness.","imageName":"","correctAnswer":10914,"isBookmarked":0,"aid":"7622#7624#10914#30796","text":"Drink energy drinks#Avoid wearing a helmet#Stop at least every two hours#Drink lots of water"},
{"testId":507,"qid":11748,"description":"To minimize weariness as you ride, you should:","explanation":"Riding a motorbike is more exhausting than driving a vehicle. You should stop and relax at least once every two hours to prevent weariness.","imageName":"","correctAnswer":10914,"isBookmarked":0,"aid":"7622#7624#10914#30796","text":"Drink energy drinks#Avoid wearing a helmet#Stop at least every two hours#Drink lots of water"},
{"testId":483,"qid":11755,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your judgment and riding ability.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":491,"qid":11755,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your judgment and riding ability.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":78,"qid":11758,"description":"In the event that a tire blows out, you should:","explanation":"If a tire fails, avoid utilizing that tire's brake. Only use the good tire to brake.","imageName":"","correctAnswer":8142,"isBookmarked":0,"aid":"8142#10601#29013#34800","text":"avoid using the brake of the flat tire#Pull the engine kill switch#slow down with both the front and rear brakes#use the brake of the flat tire"},
{"testId":479,"qid":11758,"description":"In the event that a tire blows out, you should:","explanation":"If a tire fails, avoid utilizing that tire's brake. Only use the good tire to brake.","imageName":"","correctAnswer":8142,"isBookmarked":0,"aid":"8142#10601#29013#34800","text":"avoid using the brake of the flat tire#Pull the engine kill switch#slow down with both the front and rear brakes#use the brake of the flat tire"},
{"testId":507,"qid":11758,"description":"In the event that a tire blows out, you should:","explanation":"If a tire fails, avoid utilizing that tire's brake. Only use the good tire to brake.","imageName":"","correctAnswer":8142,"isBookmarked":0,"aid":"8142#10601#29013#34800","text":"avoid using the brake of the flat tire#Pull the engine kill switch#slow down with both the front and rear brakes#use the brake of the flat tire"},
{"testId":76,"qid":11763,"description":"How may your clothing help you avoid collisions?","explanation":"By making you more apparent to others, colorful clothes may help avoid collisions.","imageName":"","correctAnswer":10784,"isBookmarked":0,"aid":"10784#23062#23063#26417","text":"Wear bright clothing#Wear durable clothing#Wear extra clothing to get the attention of drivers#Your choice of clothing does not help prevent collisions"},
{"testId":300,"qid":11763,"description":"How may your clothing help you avoid collisions?","explanation":"By making you more apparent to others, colorful clothes may help avoid collisions.","imageName":"","correctAnswer":10784,"isBookmarked":0,"aid":"10784#23062#23063#26417","text":"Wear bright clothing#Wear durable clothing#Wear extra clothing to get the attention of drivers#Your choice of clothing does not help prevent collisions"},
{"testId":396,"qid":11763,"description":"How may your clothing help you avoid collisions?","explanation":"By making you more apparent to others, colorful clothes may help avoid collisions.","imageName":"","correctAnswer":10784,"isBookmarked":0,"aid":"10784#23062#23063#26417","text":"Wear bright clothing#Wear durable clothing#Wear extra clothing to get the attention of drivers#Your choice of clothing does not help prevent collisions"},
{"testId":293,"qid":11766,"description":"Convex motorcycle side mirrors make automobiles appear ________.","explanation":"The convex side mirrors of a motorbike provide a broad field of vision, but they make automobiles seem farther away than they are.","imageName":"","correctAnswer":10429,"isBookmarked":0,"aid":"10426#10428#10429#39853","text":"closer than they are#smaller than they are#farther away than they are#larger than they are"},
{"testId":318,"qid":11768,"description":"The importance of the rear brake is _____ because _____.","explanation":"The rear brake is less critical since it only provides 25% of the stopping force. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":34266,"isBookmarked":0,"aid":"34266#34267#34268#34269","text":"less; it has 25% of the stopping power#less; it has 75% of the stopping power#more; it has 75% of the stopping power#more; it has 25% of the stopping power"},
{"testId":300,"qid":11769,"description":"You should stop riding at least once every ____ hour(s) to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":301,"qid":11769,"description":"You should stop riding at least once every ____ hour(s) to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":450,"qid":11769,"description":"You should stop riding at least once every ____ hour(s) to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":507,"qid":11769,"description":"You should stop riding at least once every ____ hour(s) to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":343,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":359,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":367,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":412,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":420,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":434,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":479,"qid":11770,"description":"Which of the following surfaces makes it harder to move?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":82,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":295,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":343,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":420,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":446,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":479,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":507,"qid":11773,"description":"Changing your lane position may ____________.","explanation":"Changing your lane position may indicate your intentions to other drivers, keep you out of a blind zone, and give an escape path.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#22250#22251#34226","text":"Do all of the above#help you avoid being in a blind spot#provide a possible escape route#communicate your intentions to other vehicle operators"},
{"testId":420,"qid":11774,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":429,"qid":11774,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":458,"qid":11774,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":499,"qid":11774,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":318,"qid":11776,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":330,"qid":11776,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":458,"qid":11776,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":475,"qid":11776,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":503,"qid":11776,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":434,"qid":11783,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":318,"qid":11784,"description":"You should begin riding the motorcycle with _____.","explanation":"To avoid using too much throttle, start the motorbike with your right wrist flat.","imageName":"","correctAnswer":6976,"isBookmarked":0,"aid":"6973#6974#6975#6976","text":"your right wrist low#your elbows locked#your hands above your elbows#your right wrist flat"},
{"testId":429,"qid":11790,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22773#22979","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Ride in the right lane position so you will be close to the vehicle#Beep your horn to let the driver know you're there"},
{"testId":375,"qid":11791,"description":"If you lock the back wheel on a traction-rich surface,:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop. If, on the other hand, you lock the front wheel, you must instantly release the front brake.","imageName":"","correctAnswer":7388,"isBookmarked":0,"aid":"7388#7389#24659#29379","text":"you can keep it locked until you come to a complete stop#release both brakes and coast to a stop#release the rear brake and use only the front brake#release the rear brake and reapply it"},
{"testId":438,"qid":11791,"description":"If you lock the back wheel on a traction-rich surface,:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop. If, on the other hand, you lock the front wheel, you must instantly release the front brake.","imageName":"","correctAnswer":7388,"isBookmarked":0,"aid":"7388#7389#24659#29379","text":"you can keep it locked until you come to a complete stop#release both brakes and coast to a stop#release the rear brake and use only the front brake#release the rear brake and reapply it"},
{"testId":450,"qid":11791,"description":"If you lock the back wheel on a traction-rich surface,:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop. If, on the other hand, you lock the front wheel, you must instantly release the front brake.","imageName":"","correctAnswer":7388,"isBookmarked":0,"aid":"7388#7389#24659#29379","text":"you can keep it locked until you come to a complete stop#release both brakes and coast to a stop#release the rear brake and use only the front brake#release the rear brake and reapply it"},
{"testId":60,"qid":11792,"description":"Motorcycles usually need:","explanation":"Motorcycles normally need the same stopping distance as vehicles in traffic. Make sure to provide enough room cushion.","imageName":"","correctAnswer":39860,"isBookmarked":0,"aid":"39859#39860#39861#39862","text":"a smaller stopping distance than cars do#the same stopping distance as cars do#a larger stopping distance than cars do#a larger or smaller stopping distance than cars do, depending on the models of the motorcycles"},
{"testId":334,"qid":11794,"description":"Which of the following claims concerning freight transportation is true?","explanation":"When transporting stuff, strive to uniformly distribute the weight so that the motorbike stays balanced. Carrying anything on the sissy bar raises the motorcycle's center of gravity and throws off its balance.","imageName":"","correctAnswer":30795,"isBookmarked":0,"aid":"30795#31494#31752#34811","text":"You should distribute the weight evenly#You should not carry cargo while carrying a passenger#You may carry loads on the sissy bar#Ropes are the best way to secure cargo"},
{"testId":475,"qid":11794,"description":"Which of the following claims concerning freight transportation is true?","explanation":"When transporting stuff, strive to uniformly distribute the weight so that the motorbike stays balanced. Carrying anything on the sissy bar raises the motorcycle's center of gravity and throws off its balance.","imageName":"","correctAnswer":30795,"isBookmarked":0,"aid":"30795#31494#31752#34811","text":"You should distribute the weight evenly#You should not carry cargo while carrying a passenger#You may carry loads on the sissy bar#Ropes are the best way to secure cargo"},
{"testId":454,"qid":11795,"description":"How frequenty should you inspect the tires on your motorcycle?","explanation":"Before each journey, inspect the tires on your motorbike. A motorbike rider is more vulnerable to a tire failure than a vehicle driver.","imageName":"","correctAnswer":22776,"isBookmarked":0,"aid":"7239#7240#7573#22776","text":"Once a week#Once a month#At the beginning of the season#Every time before you ride"},
{"testId":467,"qid":11795,"description":"How frequenty should you inspect the tires on your motorcycle?","explanation":"Before each journey, inspect the tires on your motorbike. A motorbike rider is more vulnerable to a tire failure than a vehicle driver.","imageName":"","correctAnswer":22776,"isBookmarked":0,"aid":"7239#7240#7573#22776","text":"Once a week#Once a month#At the beginning of the season#Every time before you ride"},
{"testId":58,"qid":11799,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":343,"qid":11799,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":347,"qid":11799,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":495,"qid":11799,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":79,"qid":11802,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"To transport a passenger, your motorbike must have passenger footrests. In addition, the seat must be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#8224#8615","text":"A windshield#footrests for the passenger#special shocks#All of the above"},
{"testId":330,"qid":11803,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, push the handgrip on the side of the direction you want to turn, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":450,"qid":11803,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, push the handgrip on the side of the direction you want to turn, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":467,"qid":11803,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, push the handgrip on the side of the direction you want to turn, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":499,"qid":11803,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, push the handgrip on the side of the direction you want to turn, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":84,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":396,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":404,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":412,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":462,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":467,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":475,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":491,"qid":11806,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to the fact that precipitation loosens oil and other fluids that have accumulated on the pavement. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":292,"qid":11810,"description":"At a junction, if you make eye contact with a driver,:","explanation":"Drivers will not yield to you if you make eye contact. Even when drivers are gazing straight towards motorcycles, they often fail to spot them. At crossroads, always proceed with care.","imageName":"","correctAnswer":10384,"isBookmarked":0,"aid":"10384#22785#24651#34806","text":"it does not mean the driver has seen you#you must yield the right-of-way#it means the driver will yield the right-of-way#it means this person is an aggressive driver"},
{"testId":347,"qid":11810,"description":"At a junction, if you make eye contact with a driver,:","explanation":"Drivers will not yield to you if you make eye contact. Even when drivers are gazing straight towards motorcycles, they often fail to spot them. At crossroads, always proceed with care.","imageName":"","correctAnswer":10384,"isBookmarked":0,"aid":"10384#22785#24651#34806","text":"it does not mean the driver has seen you#you must yield the right-of-way#it means the driver will yield the right-of-way#it means this person is an aggressive driver"},
{"testId":59,"qid":11814,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch is located on the left handlebar, while the front brake lever is located on the right.","imageName":"","correctAnswer":31776,"isBookmarked":0,"aid":"31773#31774#31775#31776","text":"The clutch is on the right and the front brake lever is on the left#The clutch and front brake lever are both on the left#The clutch and front brake lever are both on the right#The clutch is on the left and the front brake lever is on the right"},
{"testId":81,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":85,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":293,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":462,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":467,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":475,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":495,"qid":11815,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"On slick conditions, you may ride safely by slowing down and avoiding unexpected movements that might result in loss of control.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":70,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":330,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":425,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":446,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":450,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":483,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":499,"qid":11819,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for extended periods of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":294,"qid":11827,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If the motorbike begins to wobble, carefully close the throttle. Fighting the wobble, speeding, or braking fast might aggravate it.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6431#6432#6433#6434","text":"Grip the handlebars tightly and fight the wobble#Close the throttle gradually#Accelerate out of the wobble#Apply the brakes quickly"},
{"testId":483,"qid":11830,"description":"When your bike is parked next to a curb, it should be:","explanation":"When parked along the road, your motorbike should be angled with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":487,"qid":11830,"description":"When your bike is parked next to a curb, it should be:","explanation":"When parked along the road, your motorbike should be angled with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":499,"qid":11830,"description":"When your bike is parked next to a curb, it should be:","explanation":"When parked along the road, your motorbike should be angled with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":434,"qid":11832,"description":"What is the most secure way of braking?","explanation":"Using both the front and rear brakes at the same time is the safest and most effective stopping strategy.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6305#28897#30030#30031","text":"Use both the front and rear brakes at the same time#Alternate between the front brake and the rear brake#Use only the front brake#Use only the rear brake"},
{"testId":464,"qid":11832,"description":"What is the most secure way of braking?","explanation":"Using both the front and rear brakes at the same time is the safest and most effective stopping strategy.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6305#28897#30030#30031","text":"Use both the front and rear brakes at the same time#Alternate between the front brake and the rear brake#Use only the front brake#Use only the rear brake"},
{"testId":81,"qid":11833,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are far more difficult to see than vehicles or trucks. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":293,"qid":11833,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are far more difficult to see than vehicles or trucks. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":299,"qid":11833,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are far more difficult to see than vehicles or trucks. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":464,"qid":11833,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are far more difficult to see than vehicles or trucks. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":66,"qid":11834,"description":"What is controlled by the left-hand lever?","explanation":"The clutch is controlled by the lever on the left. The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":7191,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":491,"qid":11834,"description":"What is controlled by the left-hand lever?","explanation":"The clutch is controlled by the lever on the left. The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":7191,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":464,"qid":11835,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only get on a motorbike once the rider has started and mounted it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7234#8153#24934","text":"Before the engine has been started#Before the motorcycle is taken off the stand#After the engine has been started#Before the motorcyclist"},
{"testId":479,"qid":11835,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only get on a motorbike once the rider has started and mounted it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7234#8153#24934","text":"Before the engine has been started#Before the motorcycle is taken off the stand#After the engine has been started#Before the motorcyclist"},
{"testId":347,"qid":11837,"description":"Which lane should you bike in to improve your visibility ahead?","explanation":"Ride in the left lane to improve your forward visibility. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":438,"qid":11837,"description":"Which lane should you bike in to improve your visibility ahead?","explanation":"Ride in the left lane to improve your forward visibility. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":483,"qid":11839,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22798#22799#24618#40980","text":"Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear#Try to jump off the motorcycle before you hit the ground"},
{"testId":487,"qid":11840,"description":"A motorcycle's available lane positions are:","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":80,"qid":11847,"description":"What exactly does \"SIPDE\" stand for?","explanation":"SIPDE is an acronym that stands for Scan, Identify, Predict, Decide, and Execute.","imageName":"","correctAnswer":28405,"isBookmarked":0,"aid":"28404#28405#28406#28407","text":"Scan, Identify, Proceed, Direct, Execute#Scan, Identify, Predict, Decide, Execute#See, Identify, Proceed, Direct, Examine#See, Identify, Predict, Decide, Examine"},
{"testId":412,"qid":11847,"description":"What exactly does \"SIPDE\" stand for?","explanation":"SIPDE is an acronym that stands for Scan, Identify, Predict, Decide, and Execute.","imageName":"","correctAnswer":28405,"isBookmarked":0,"aid":"28404#28405#28406#28407","text":"Scan, Identify, Proceed, Direct, Execute#Scan, Identify, Predict, Decide, Execute#See, Identify, Proceed, Direct, Examine#See, Identify, Predict, Decide, Examine"},
{"testId":467,"qid":11847,"description":"What exactly does \"SIPDE\" stand for?","explanation":"SIPDE is an acronym that stands for Scan, Identify, Predict, Decide, and Execute.","imageName":"","correctAnswer":28405,"isBookmarked":0,"aid":"28404#28405#28406#28407","text":"Scan, Identify, Proceed, Direct, Execute#Scan, Identify, Predict, Decide, Execute#See, Identify, Proceed, Direct, Examine#See, Identify, Predict, Decide, Examine"},
{"testId":487,"qid":11847,"description":"What exactly does \"SIPDE\" stand for?","explanation":"SIPDE is an acronym that stands for Scan, Identify, Predict, Decide, and Execute.","imageName":"","correctAnswer":28405,"isBookmarked":0,"aid":"28404#28405#28406#28407","text":"Scan, Identify, Proceed, Direct, Execute#Scan, Identify, Predict, Decide, Execute#See, Identify, Proceed, Direct, Examine#See, Identify, Predict, Decide, Examine"},
{"testId":371,"qid":11848,"description":"If a vehicle comes into your path, you should:","explanation":"If a vehicle comes into your path, move as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":388,"qid":11848,"description":"If a vehicle comes into your path, you should:","explanation":"If a vehicle comes into your path, move as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":392,"qid":11848,"description":"If a vehicle comes into your path, you should:","explanation":"If a vehicle comes into your path, move as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":483,"qid":11848,"description":"If a vehicle comes into your path, you should:","explanation":"If a vehicle comes into your path, move as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":301,"qid":11849,"description":"Maximum braking in a straight line is obtained by:","explanation":"Applying both the front and back brakes without locking any wheel results in maximum straight-line braking.","imageName":"","correctAnswer":22932,"isBookmarked":0,"aid":"8278#22808#22932#23053","text":"pulling in the clutch#applying both brakes until the wheels lock#applying both brakes without locking the wheels#applying only the front brake"},
{"testId":363,"qid":11850,"description":"_____ should not be used to fasten the load of motorcycles.","explanation":"Rope should not be used to secure motorcycle baggage. Knots and ropes may both come undone.","imageName":"","correctAnswer":8437,"isBookmarked":0,"aid":"8225#8228#8334#8437","text":"Elastic cords#Tank bags#Saddlebags#Rope"},
{"testId":400,"qid":11850,"description":"_____ should not be used to fasten the load of motorcycles.","explanation":"Rope should not be used to secure motorcycle baggage. Knots and ropes may both come undone.","imageName":"","correctAnswer":8437,"isBookmarked":0,"aid":"8225#8228#8334#8437","text":"Elastic cords#Tank bags#Saddlebags#Rope"},
{"testId":56,"qid":11851,"description":"When starting your motorcycle, your right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#8444#28226","text":"flat, so you won't use too much throttle#high, to use the throttle the most#in the most comfortable position#low, to increase control"},
{"testId":85,"qid":11851,"description":"When starting your motorcycle, your right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#8444#28226","text":"flat, so you won't use too much throttle#high, to use the throttle the most#in the most comfortable position#low, to increase control"},
{"testId":491,"qid":11851,"description":"When starting your motorcycle, your right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#8444#28226","text":"flat, so you won't use too much throttle#high, to use the throttle the most#in the most comfortable position#low, to increase control"},
{"testId":294,"qid":11854,"description":"The ____ brake is the most crucial since it _______.","explanation":"The front brake is more crucial since it delivers 70% of braking power. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":28442,"isBookmarked":0,"aid":"28440#28441#28442#28443","text":"front; is the emergency brake#rear; provides 70% of the stopping power#front; provides 70% of the stopping power#rear; is the emergency brake"},
{"testId":363,"qid":11854,"description":"The ____ brake is the most crucial since it _______.","explanation":"The front brake is more crucial since it delivers 70% of braking power. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":28442,"isBookmarked":0,"aid":"28440#28441#28442#28443","text":"front; is the emergency brake#rear; provides 70% of the stopping power#front; provides 70% of the stopping power#rear; is the emergency brake"},
{"testId":375,"qid":11854,"description":"The ____ brake is the most crucial since it _______.","explanation":"The front brake is more crucial since it delivers 70% of braking power. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":28442,"isBookmarked":0,"aid":"28440#28441#28442#28443","text":"front; is the emergency brake#rear; provides 70% of the stopping power#front; provides 70% of the stopping power#rear; is the emergency brake"},
{"testId":295,"qid":11856,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10465#10466#10467#29426","text":"flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you#make eye contact with other vehicle operators"},
{"testId":330,"qid":11856,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10465#10466#10467#29426","text":"flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you#make eye contact with other vehicle operators"},
{"testId":442,"qid":11856,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10465#10466#10467#29426","text":"flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you#make eye contact with other vehicle operators"},
{"testId":458,"qid":11856,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10465#10466#10467#29426","text":"flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you#make eye contact with other vehicle operators"},
{"testId":293,"qid":11857,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":31795,"isBookmarked":0,"aid":"6736#7111#26116#31795","text":"single file#in pairs#a V-formation#a staggered formation"},
{"testId":294,"qid":11857,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":31795,"isBookmarked":0,"aid":"6736#7111#26116#31795","text":"single file#in pairs#a V-formation#a staggered formation"},
{"testId":503,"qid":11857,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":31795,"isBookmarked":0,"aid":"6736#7111#26116#31795","text":"single file#in pairs#a V-formation#a staggered formation"},
{"testId":334,"qid":11863,"description":"When you ride at night, you should:","explanation":"Riding at night requires you to increase your following distance and ride slower than during the day. This will allow you to detect threats earlier and give you more time to respond to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"7790#10559#10560#24714","text":"stay close behind the car in front of you#not use your headlight#wear a tinted face shield#ride slower than you do during the day"},
{"testId":301,"qid":11867,"description":"Your passenger's feet should remain on the foot pegs:","explanation":"Passengers should always keep their feet on the footrests. They do not need to put their feet down at junctions, unlike motorcyclists.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7344#34544#34545","text":"At all times#while the motorcycle is moving#unless you're riding on a slippery surface#unless you're stopped at an intersection"},
{"testId":454,"qid":11867,"description":"Your passenger's feet should remain on the foot pegs:","explanation":"Passengers should always keep their feet on the footrests. They do not need to put their feet down at junctions, unlike motorcyclists.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7344#34544#34545","text":"At all times#while the motorcycle is moving#unless you're riding on a slippery surface#unless you're stopped at an intersection"},
{"testId":454,"qid":11870,"description":"When passing another vehicle, begin your pass from the _______ section of your lane.","explanation":"When ready to pass, riding in the left lane will provide you a better line of sight. This lane position allows you to look beyond the car in front of you and detect impending dangers.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6547#6768","text":"any#The left#The center#the middle"},
{"testId":314,"qid":11872,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#28385","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#using your turn signals"},
{"testId":314,"qid":11873,"description":"When transporting cargo in saddlebags,you should:","explanation":"When filling saddlebags, load them uniformly and with the same weight to keep your motorbike balanced.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":343,"qid":11873,"description":"When transporting cargo in saddlebags,you should:","explanation":"When filling saddlebags, load them uniformly and with the same weight to keep your motorbike balanced.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":491,"qid":11873,"description":"When transporting cargo in saddlebags,you should:","explanation":"When filling saddlebags, load them uniformly and with the same weight to keep your motorbike balanced.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":314,"qid":11874,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handgrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":471,"qid":11874,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handgrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":314,"qid":11875,"description":"To come to a complete stop as quickly as feasible, you should:","explanation":"To come to a complete stop as fast as possible, utilize both the front and rear brakes at the same time. This is the most efficient form of braking.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"22259#22260#30063#33221","text":"use only the front brake#use only the rear brake#use both the front and rear brakes at the same time#use either the front or rear brake while swerving"},
{"testId":454,"qid":11875,"description":"To come to a complete stop as quickly as feasible, you should:","explanation":"To come to a complete stop as fast as possible, utilize both the front and rear brakes at the same time. This is the most efficient form of braking.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"22259#22260#30063#33221","text":"use only the front brake#use only the rear brake#use both the front and rear brakes at the same time#use either the front or rear brake while swerving"},
{"testId":487,"qid":11875,"description":"To come to a complete stop as quickly as feasible, you should:","explanation":"To come to a complete stop as fast as possible, utilize both the front and rear brakes at the same time. This is the most efficient form of braking.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"22259#22260#30063#33221","text":"use only the front brake#use only the rear brake#use both the front and rear brakes at the same time#use either the front or rear brake while swerving"},
{"testId":495,"qid":11875,"description":"To come to a complete stop as quickly as feasible, you should:","explanation":"To come to a complete stop as fast as possible, utilize both the front and rear brakes at the same time. This is the most efficient form of braking.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"22259#22260#30063#33221","text":"use only the front brake#use only the rear brake#use both the front and rear brakes at the same time#use either the front or rear brake while swerving"},
{"testId":59,"qid":11876,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable the motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":314,"qid":11876,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable the motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":499,"qid":11876,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable the motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":314,"qid":11879,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may not see you and open a door directly into your path.","imageName":"","correctAnswer":43649,"isBookmarked":0,"aid":"6785#6786#24681#43649","text":"it decreases visibility#traction is poor beside parked cars#you may collide with a car#someone may open a door into your path"},
{"testId":314,"qid":11881,"description":"How can you increase chances of avoiding hazards at night?","explanation":"By slowing down and increasing your following distance at night, you may improve your chances of avoiding potential dangers. As a result, you'll have more time to respond if anything unexpected occurs.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#11171#11172#11173","text":"Reduce your speed#Ride in a group#Do not wear a helmet#Stay in the same lane position"},
{"testId":511,"qid":11881,"description":"How can you increase chances of avoiding hazards at night?","explanation":"By slowing down and increasing your following distance at night, you may improve your chances of avoiding potential dangers. As a result, you'll have more time to respond if anything unexpected occurs.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#11171#11172#11173","text":"Reduce your speed#Ride in a group#Do not wear a helmet#Stay in the same lane position"},
{"testId":314,"qid":11885,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content may be affected by how quickly you drink, how many drinks you've had, and how much you weigh (BAC).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#7291#7292","text":"All of the above#How fast you drink#The number of drinks you've had#How much you weigh"},
{"testId":314,"qid":11886,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":458,"qid":11886,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":467,"qid":11886,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":314,"qid":11887,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If an unanticipated obstruction forces you to turn left fast, hold the left handgrip. This will force your motorbike to momentarily steer right, causing it to tilt to the left.","imageName":"","correctAnswer":24804,"isBookmarked":0,"aid":"6423#6569#24803#24804","text":"Apply the rear brake#Apply the front brake#press the right handgrip#press the left handgrip"},
{"testId":314,"qid":11889,"description":"Before making a turn in Arizona, you must begin signaling at least ______ in advance.","explanation":null,"imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#399#1026#4061","text":"100 feet#200 feet#50 feet#75 feet"},
{"testId":314,"qid":11890,"description":"What exactly is engine braking?","explanation":"Instead of depending only on the brakes, engine braking is accomplished by downshifting through the gears to slow the motorbike.","imageName":"","correctAnswer":22988,"isBookmarked":0,"aid":"7745#10915#10916#22988","text":"Upshifting to gain speed on hills#Where your engine stops working#Using the engine kill switch to stop the motorcycle#Downshifting through the gears to slow the motorcycle"},
{"testId":314,"qid":11891,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":347,"qid":11891,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":446,"qid":11891,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":475,"qid":11891,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":491,"qid":11891,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#33908","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that run parallel to your course"},
{"testId":314,"qid":11892,"description":"When is it permissible to ride a motorcycle between two lanes of traffic?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#7724#28979#38718","text":"Never#Only in designated areas#Only if both lanes are headed in the same direction#Only when traffic is at a standstill"},
{"testId":74,"qid":11895,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"10710#22821#22822#30525","text":"Pull the engine kill switch#Apply only the front brake#Apply only the rear brake#Fully apply both the front and rear brakes"},
{"testId":314,"qid":11895,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"10710#22821#22822#30525","text":"Pull the engine kill switch#Apply only the front brake#Apply only the rear brake#Fully apply both the front and rear brakes"},
{"testId":314,"qid":11896,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7975#22824#22928#22929","text":"you are making a left turn#you think someone may enter your lane without seeing you#you pull the engine kill switch#you are crossing railroad tracks"},
{"testId":318,"qid":11896,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7975#22824#22928#22929","text":"you are making a left turn#you think someone may enter your lane without seeing you#you pull the engine kill switch#you are crossing railroad tracks"},
{"testId":334,"qid":11896,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7975#22824#22928#22929","text":"you are making a left turn#you think someone may enter your lane without seeing you#you pull the engine kill switch#you are crossing railroad tracks"},
{"testId":314,"qid":11897,"description":"You may share a lane safely with:","explanation":"The key word here is \"safely.\" You should not be sharing a lane with other cars. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger. Instead of riding in a line with other bikes, ride in a staggered pattern.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30482#34212","text":"no one#other motorcycles only#motorcycles and small cars#any other vehicles"},
{"testId":314,"qid":11898,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are not as visible as automobile brake lights, but if they flash, they become more visible. Tap the brake to flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#32708","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid riding at night"},
{"testId":85,"qid":11900,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop ahead of the stop line. You may proceed after pausing until you can see more clearly.","imageName":"","correctAnswer":40645,"isBookmarked":0,"aid":"8118#8119#8120#40645","text":"where you can see both ways#with your rear tire on the stop line#straddling the stop line#before the stop line"},
{"testId":314,"qid":11900,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop ahead of the stop line. You may proceed after pausing until you can see more clearly.","imageName":"","correctAnswer":40645,"isBookmarked":0,"aid":"8118#8119#8120#40645","text":"where you can see both ways#with your rear tire on the stop line#straddling the stop line#before the stop line"},
{"testId":475,"qid":11900,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop ahead of the stop line. You may proceed after pausing until you can see more clearly.","imageName":"","correctAnswer":40645,"isBookmarked":0,"aid":"8118#8119#8120#40645","text":"where you can see both ways#with your rear tire on the stop line#straddling the stop line#before the stop line"},
{"testId":479,"qid":11900,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop ahead of the stop line. You may proceed after pausing until you can see more clearly.","imageName":"","correctAnswer":40645,"isBookmarked":0,"aid":"8118#8119#8120#40645","text":"where you can see both ways#with your rear tire on the stop line#straddling the stop line#before the stop line"},
{"testId":79,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":314,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":343,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":458,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":479,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":483,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":507,"qid":11901,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":80,"qid":11902,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake is responsible for 75% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":314,"qid":11902,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake is responsible for 75% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":334,"qid":11902,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake is responsible for 75% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":450,"qid":11902,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake is responsible for 75% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":314,"qid":11903,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"In the following instances, a vehicle may attempt to share your lane: (1) in heavy, bumper-to-bumper traffic; (2) when the driver wishes to pass you; (3) while you are about to turn at a junction; or (4) when you are going into an exit lane on a highway. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":298,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":314,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":334,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":347,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":462,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":464,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":475,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":491,"qid":11904,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#10887#23083","text":"Slow down before reaching the slippery section#Stop and turn around#Stop your motorcycle and walk it across the slippery section#Use only your rear brake"},
{"testId":82,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":294,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":314,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":412,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":429,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":438,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":462,"qid":11905,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":314,"qid":11908,"description":"What do you press in the \"press\" step of turning?","explanation":"The \"push\" part of turning involves pressing the handgrip on the side of the direction you desire to turn. The motorbike will tilt in that way as a result.","imageName":"","correctAnswer":33597,"isBookmarked":0,"aid":"8404#23051#31459#33597","text":"The front or rear brake#The clutch lever#Your feet on the footrests#The handgrip on the side of the direction in which you want to turn"},
{"testId":314,"qid":11910,"description":"If a dog begins to chase you while driving, you should:","explanation":"If a dog begins after you, you should approach it and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":6571,"isBookmarked":0,"aid":"6570#6571#6572#28228","text":"kick the dog#approach the animal and speed away#stop until the owner is found#call animal control services"},
{"testId":491,"qid":11910,"description":"If a dog begins to chase you while driving, you should:","explanation":"If a dog begins after you, you should approach it and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":6571,"isBookmarked":0,"aid":"6570#6571#6572#28228","text":"kick the dog#approach the animal and speed away#stop until the owner is found#call animal control services"},
{"testId":314,"qid":11911,"description":"One advantage of riding in the center lane is that it:","explanation":"One advantage of riding in the middle lane is that it inhibits other vehicle operators from attempting to share your lane. The middle lane location leaves very little room on each side. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#22835#29469#31460","text":"allows you to turn easily#helps you maintain a consistent speed#discourages others from sharing your lane#keeps you hidden from other vehicle operators"},
{"testId":314,"qid":11912,"description":"When going by another vehicle,:","explanation":null,"imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":314,"qid":11914,"description":"Which lane position should be avoided most of the time?","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"6214#6216#6217#17889","text":"Left#Center#Right#None"},
{"testId":293,"qid":11915,"description":"Before you downshift, you should:","explanation":"Before downshifting, you should slow to a speed that is appropriate for the gear that you're about to shift into. Traveling too fast when downshifting can cause the motorcycle to lurch.","imageName":"","correctAnswer":10438,"isBookmarked":0,"aid":"6286#6423#6761#10438","text":"Flash your brake light#Apply the rear brake#lean forward#decelerate"},
{"testId":314,"qid":11915,"description":"Before you downshift, you should:","explanation":"Before downshifting, you should slow to a speed that is appropriate for the gear that you're about to shift into. Traveling too fast when downshifting can cause the motorcycle to lurch.","imageName":"","correctAnswer":10438,"isBookmarked":0,"aid":"6286#6423#6761#10438","text":"Flash your brake light#Apply the rear brake#lean forward#decelerate"},
{"testId":343,"qid":11915,"description":"Before you downshift, you should:","explanation":"Before downshifting, you should slow to a speed that is appropriate for the gear that you're about to shift into. Traveling too fast when downshifting can cause the motorcycle to lurch.","imageName":"","correctAnswer":10438,"isBookmarked":0,"aid":"6286#6423#6761#10438","text":"Flash your brake light#Apply the rear brake#lean forward#decelerate"},
{"testId":314,"qid":11916,"description":"When it begins to rain, the best location to ride is :","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are the least likely to be slick.","imageName":"","correctAnswer":28999,"isBookmarked":0,"aid":"7310#7312#28999#34553","text":"in the center lane position#on the shoulder of the road#in the tire tracks of other vehicles#on a main road"},
{"testId":412,"qid":11916,"description":"When it begins to rain, the best location to ride is :","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are the least likely to be slick.","imageName":"","correctAnswer":28999,"isBookmarked":0,"aid":"7310#7312#28999#34553","text":"in the center lane position#on the shoulder of the road#in the tire tracks of other vehicles#on a main road"},
{"testId":314,"qid":11919,"description":"You should stop riding at least once every ____ hours to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#5002","text":"Two#Four#Six#Five"},
{"testId":61,"qid":11932,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":347,"qid":11932,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":454,"qid":11932,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":462,"qid":11932,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":450,"qid":11938,"description":"If you're about to stop and you're _________, you should flash your brake light.","explanation":"If you are stopping in an odd location, stopping faster than others may anticipate, or stopping while being tailgated, you should flash your brake light.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7026#22688#24754#35066","text":"stopping in an unusual place#In any of the above situations#stopping more quickly than others might expect#being tailgated"},
{"testId":334,"qid":11942,"description":"Blind spots of a motorcyclea are:","explanation":"A motorbike, like a vehicle, has blind areas. Because the motorcycle's blind spots are on the left and right sides, constantly check your shoulders before changing lanes.","imageName":"","correctAnswer":24757,"isBookmarked":0,"aid":"24757#24758#29007#29008","text":"on the right and left of the motorcycle#nonexistent#on the left only#on the right only"},
{"testId":458,"qid":11942,"description":"Blind spots of a motorcyclea are:","explanation":"A motorbike, like a vehicle, has blind areas. Because the motorcycle's blind spots are on the left and right sides, constantly check your shoulders before changing lanes.","imageName":"","correctAnswer":24757,"isBookmarked":0,"aid":"24757#24758#29007#29008","text":"on the right and left of the motorcycle#nonexistent#on the left only#on the right only"},
{"testId":404,"qid":11944,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Wet pavement, gravel roads, and lane markers may all cause motorbikes to lose traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#7396","text":"All of the above#Gravel roads#Wet pavement#Lane markings"},
{"testId":422,"qid":11944,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Wet pavement, gravel roads, and lane markers may all cause motorbikes to lose traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#7396","text":"All of the above#Gravel roads#Wet pavement#Lane markings"},
{"testId":69,"qid":11945,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":322,"qid":11945,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":326,"qid":11945,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":475,"qid":11945,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":507,"qid":11945,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":69,"qid":11947,"description":"To alert drivers that you are slowing down in an unexpected spot, you should:","explanation":"Tap the brake pedal to flash your brake light to the car behind you to alert them that you are slowing down in an odd location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7482#7483#22897","text":"Flash your brake light#swerve from side to side#shift gears instead of braking#rise slightly off your seat"},
{"testId":431,"qid":11951,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective gear does not reduce your odds of being involved in an accident, but it does assist protect you if you are.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":83,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":299,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":392,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":408,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":434,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":467,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":491,"qid":11959,"description":"Which of the following is not part of the upshifting process?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":22853,"isBookmarked":0,"aid":"22851#22852#22853#30803","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":291,"qid":11960,"description":"The greatest approach to absorb shock when cycling over a road obstruction is to:","explanation":"When riding over a road impediment, the easiest approach to absorb shock is to raise slightly off the seat and allow your knees and elbows absorb the impact.","imageName":"","correctAnswer":28429,"isBookmarked":0,"aid":"7480#22854#22855#28429","text":"Lean back as far as possible#increase your speed to minimize bumps#avoid using the brakes#rise off the seat"},
{"testId":467,"qid":11960,"description":"The greatest approach to absorb shock when cycling over a road obstruction is to:","explanation":"When riding over a road impediment, the easiest approach to absorb shock is to raise slightly off the seat and allow your knees and elbows absorb the impact.","imageName":"","correctAnswer":28429,"isBookmarked":0,"aid":"7480#22854#22855#28429","text":"Lean back as far as possible#increase your speed to minimize bumps#avoid using the brakes#rise off the seat"},
{"testId":292,"qid":11964,"description":"You should make a conventional turn,:","explanation":"Lean with your motorbike during a regular turn to assist maintain balance.","imageName":"","correctAnswer":38909,"isBookmarked":0,"aid":"10369#10685#31489#38909","text":"avoid leaning in either direction#lean in the opposite direction of the turn#remain upright#lean at the same angle as your motorcycle"},
{"testId":79,"qid":11965,"description":"How can you start a swerve?","explanation":"Swerves may be initiated by pushing the handgrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result.","imageName":"","correctAnswer":32390,"isBookmarked":0,"aid":"32390#33230#33591#33592","text":"Press the handgrip on the side of the direction in which you want to swerve#Press the handgrip on the opposite side of the direction in which you want to swerve#Press the footrest on the side of the direction in which you want to swerve#Press the footrest on the opposite side of the direction in which you want to swerve"},
{"testId":295,"qid":11965,"description":"How can you start a swerve?","explanation":"Swerves may be initiated by pushing the handgrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result.","imageName":"","correctAnswer":32390,"isBookmarked":0,"aid":"32390#33230#33591#33592","text":"Press the handgrip on the side of the direction in which you want to swerve#Press the handgrip on the opposite side of the direction in which you want to swerve#Press the footrest on the side of the direction in which you want to swerve#Press the footrest on the opposite side of the direction in which you want to swerve"},
{"testId":59,"qid":11967,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#30792#32399#32400","text":"In any of the above situations#When you are about to change lanes#When you are about to slow down#While you are stopped at an intersection"},
{"testId":298,"qid":11967,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#30792#32399#32400","text":"In any of the above situations#When you are about to change lanes#When you are about to slow down#While you are stopped at an intersection"},
{"testId":347,"qid":11967,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#30792#32399#32400","text":"In any of the above situations#When you are about to change lanes#When you are about to slow down#While you are stopped at an intersection"},
{"testId":479,"qid":11967,"description":"When should you check your mirrors?","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#30792#32399#32400","text":"In any of the above situations#When you are about to change lanes#When you are about to slow down#While you are stopped at an intersection"},
{"testId":76,"qid":11970,"description":"What should you expect when riding with a passenger?","explanation":"A passenger adds weight to the motorbike. As a result, it will react more slowly to changes such as speeding up, turning, or halting.","imageName":"","correctAnswer":22860,"isBookmarked":0,"aid":"22858#22859#22860#22861","text":"You will get better gas mileage#You will be unable to carry cargo#Your motorcycle will take longer to stop#You will be unable to pass other vehicles"},
{"testId":301,"qid":11970,"description":"What should you expect when riding with a passenger?","explanation":"A passenger adds weight to the motorbike. As a result, it will react more slowly to changes such as speeding up, turning, or halting.","imageName":"","correctAnswer":22860,"isBookmarked":0,"aid":"22858#22859#22860#22861","text":"You will get better gas mileage#You will be unable to carry cargo#Your motorcycle will take longer to stop#You will be unable to pass other vehicles"},
{"testId":438,"qid":11977,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle to the roadside so that you can see properly in both ways.","imageName":"","correctAnswer":26493,"isBookmarked":0,"aid":"6344#6346#26493#26494","text":"Parallel to the roadside#With the rear tire off the road#At an angle to the roadside#Facing the roadside"},
{"testId":301,"qid":11983,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":343,"qid":11983,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":347,"qid":11983,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":434,"qid":11983,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":487,"qid":11983,"description":"Normally in turns,:","explanation":"The rider should lean with the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#33224","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and the motorcycle should remain upright"},
{"testId":301,"qid":11984,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"For motorbikes, snow, slick surfaces, and gravel roads provide minimal traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#6719","text":"All of the above#Gravel roads#Wet pavement#Snow"},
{"testId":434,"qid":11986,"description":"If another driver attempts to pass you on your left, you should:","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":25842,"isBookmarked":0,"aid":"7334#25842#26469#26470","text":"ride on the shoulder of the road#avoid the left lane position#move closer to the passing vehicle#avoid the center lane position"},
{"testId":495,"qid":11986,"description":"If another driver attempts to pass you on your left, you should:","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":25842,"isBookmarked":0,"aid":"7334#25842#26469#26470","text":"ride on the shoulder of the road#avoid the left lane position#move closer to the passing vehicle#avoid the center lane position"},
{"testId":442,"qid":11990,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7217#7289#10572#31493","text":"drink caffeinated beverages#take frequent rest breaks#Ride only during the day#avoid taking long trips"},
{"testId":446,"qid":11990,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7217#7289#10572#31493","text":"drink caffeinated beverages#take frequent rest breaks#Ride only during the day#avoid taking long trips"},
{"testId":450,"qid":11990,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7217#7289#10572#31493","text":"drink caffeinated beverages#take frequent rest breaks#Ride only during the day#avoid taking long trips"},
{"testId":462,"qid":11990,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7217#7289#10572#31493","text":"drink caffeinated beverages#take frequent rest breaks#Ride only during the day#avoid taking long trips"},
{"testId":330,"qid":11992,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Gravel roads may be risky for motorcycle riders due to the lack of grip provided by the loose sand and gravel.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10589#10590#22872","text":"They do not have posted speed limits#Lane markings are not present#Gravel provides less traction than pavement#Car drivers are known to drive recklessly"},
{"testId":334,"qid":11994,"description":"For tiredness prevention, try not to ride for more than _____ hours every day.","explanation":"To avoid exhaustion, limit your riding time to no more than six hours every day. Also, take a break every two hours at the absolute least.","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":438,"qid":11994,"description":"For tiredness prevention, try not to ride for more than _____ hours every day.","explanation":"To avoid exhaustion, limit your riding time to no more than six hours every day. Also, take a break every two hours at the absolute least.","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":450,"qid":11994,"description":"For tiredness prevention, try not to ride for more than _____ hours every day.","explanation":"To avoid exhaustion, limit your riding time to no more than six hours every day. Also, take a break every two hours at the absolute least.","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":294,"qid":11999,"description":"Which of the following statement concerning braking is correct?","explanation":"Always utilize both the front and rear brakes for the most efficient braking.","imageName":"","correctAnswer":34230,"isBookmarked":0,"aid":"22875#22878#34230#38470","text":"The rear brake has most of the stopping power#The rear brake should only be used to slow down#You can stop quickly by using both the front and rear brakes#The front brake is controlled by a foot pedal"},
{"testId":300,"qid":12000,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":408,"qid":12000,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":334,"qid":12003,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#28382","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footrests for balance"},
{"testId":454,"qid":12003,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#28382","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footrests for balance"},
{"testId":292,"qid":12009,"description":"How can you make yourself more visible to others at night?","explanation":"Wear luminous clothing at night to increase your visibility. Fluorescent clothes may be difficult to spot in the dark.","imageName":"","correctAnswer":22884,"isBookmarked":0,"aid":"22882#22883#22884#22885","text":"Avoid wearing a tinted face shield#Wear fluorescent clothing#Wear reflective gear#Wear a bright helmet"},
{"testId":458,"qid":12009,"description":"How can you make yourself more visible to others at night?","explanation":"Wear luminous clothing at night to increase your visibility. Fluorescent clothes may be difficult to spot in the dark.","imageName":"","correctAnswer":22884,"isBookmarked":0,"aid":"22882#22883#22884#22885","text":"Avoid wearing a tinted face shield#Wear fluorescent clothing#Wear reflective gear#Wear a bright helmet"},
{"testId":499,"qid":12009,"description":"How can you make yourself more visible to others at night?","explanation":"Wear luminous clothing at night to increase your visibility. Fluorescent clothes may be difficult to spot in the dark.","imageName":"","correctAnswer":22884,"isBookmarked":0,"aid":"22882#22883#22884#22885","text":"Avoid wearing a tinted face shield#Wear fluorescent clothing#Wear reflective gear#Wear a bright helmet"},
{"testId":347,"qid":12011,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":40802,"isBookmarked":0,"aid":"6827#7671#22886#40802","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent"},
{"testId":71,"qid":12018,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":10893,"isBookmarked":0,"aid":"7644#10893#22889#29400","text":"you cannot be seen when riding near the edge#of dirt and gravel buildup#the road edge markings are often inaccurate#the pavement is of poor quality near the edge"},
{"testId":298,"qid":12018,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":10893,"isBookmarked":0,"aid":"7644#10893#22889#29400","text":"you cannot be seen when riding near the edge#of dirt and gravel buildup#the road edge markings are often inaccurate#the pavement is of poor quality near the edge"},
{"testId":302,"qid":12018,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":10893,"isBookmarked":0,"aid":"7644#10893#22889#29400","text":"you cannot be seen when riding near the edge#of dirt and gravel buildup#the road edge markings are often inaccurate#the pavement is of poor quality near the edge"},
{"testId":388,"qid":12018,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":10893,"isBookmarked":0,"aid":"7644#10893#22889#29400","text":"you cannot be seen when riding near the edge#of dirt and gravel buildup#the road edge markings are often inaccurate#the pavement is of poor quality near the edge"},
{"testId":503,"qid":12018,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles may be risky on the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":10893,"isBookmarked":0,"aid":"7644#10893#22889#29400","text":"you cannot be seen when riding near the edge#of dirt and gravel buildup#the road edge markings are often inaccurate#the pavement is of poor quality near the edge"},
{"testId":330,"qid":12022,"description":"To quickly and safely stop your motorcycle, you should apply:","explanation":"To come to a complete stop on your motorbike, use both the front and rear brakes at the same time.","imageName":"","correctAnswer":31116,"isBookmarked":0,"aid":"6504#7749#31116#31117","text":"the front brake only#the rear brake only#both the front and rear brakes at the same time#neither brake"},
{"testId":60,"qid":12025,"description":"When riding at night, you can improve your safety by:","explanation":"Riding at night may be made safer by wearing fluorescent clothes, utilizing your high beam, increasing your following distance, and slowing down.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":392,"qid":12029,"description":"What should you do if you have a weaving sensation while riding over metal grating?","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":24723,"isBookmarked":0,"aid":"10578#10579#10580#24723","text":"Increase your speed#Decrease your speed#Grip the handlebars tightly to stop the feeling#Relax and maintain your speed"},
{"testId":503,"qid":12033,"description":"To alert drivers that you are slowing down in an unexpected spot, you should:","explanation":"Tap the brake pedal to flash your brake light to the car behind you to alert them that you are slowing down in an odd location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7482#7483#22897","text":"Flash your brake light#swerve from side to side#shift gears instead of braking#rise slightly off your seat"},
{"testId":302,"qid":12041,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots can untie.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":420,"qid":12041,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots can untie.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":343,"qid":12043,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"When looking for dangers on the road, look 12 seconds ahead on your route of travel. This allows you to prepare for and plan for a problem before it becomes an imminent threat. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":467,"qid":12043,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"When looking for dangers on the road, look 12 seconds ahead on your route of travel. This allows you to prepare for and plan for a problem before it becomes an imminent threat. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":74,"qid":12045,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"22997#22998#22999#23000","text":"Give them plenty of room#Move into another lane if one is open#If you cannot change lanes, adjust your speed to open up space for the merging drivers#Do all of the above"},
{"testId":351,"qid":12045,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"22997#22998#22999#23000","text":"Give them plenty of room#Move into another lane if one is open#If you cannot change lanes, adjust your speed to open up space for the merging drivers#Do all of the above"},
{"testId":450,"qid":12045,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"22997#22998#22999#23000","text":"Give them plenty of room#Move into another lane if one is open#If you cannot change lanes, adjust your speed to open up space for the merging drivers#Do all of the above"},
{"testId":334,"qid":12048,"description":"If your back tire goes flat while riding,:","explanation":"If your rear tire becomes flat while cycling, stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":442,"qid":12048,"description":"If your back tire goes flat while riding,:","explanation":"If your rear tire becomes flat while cycling, stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":483,"qid":12048,"description":"If your back tire goes flat while riding,:","explanation":"If your rear tire becomes flat while cycling, stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":499,"qid":12048,"description":"If your back tire goes flat while riding,:","explanation":"If your rear tire becomes flat while cycling, stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":503,"qid":12048,"description":"If your back tire goes flat while riding,:","explanation":"If your rear tire becomes flat while cycling, stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":479,"qid":12049,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":483,"qid":12049,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":59,"qid":12060,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If braking and swerving are both required to avoid colliding with an object, you should brake either before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30047,"isBookmarked":0,"aid":"30046#30047#39497#39498","text":"Brake while swerving#Brake before or after swerving#Brake before swerving, not after swerving#Brake after swerving, not before swerving"},
{"testId":291,"qid":12060,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If braking and swerving are both required to avoid colliding with an object, you should brake either before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30047,"isBookmarked":0,"aid":"30046#30047#39497#39498","text":"Brake while swerving#Brake before or after swerving#Brake before swerving, not after swerving#Brake after swerving, not before swerving"},
{"testId":296,"qid":12066,"description":"How often should you stop and stretch while riding long distances?","explanation":"When cycling lengthy distances, you should stop and stretch every two hours at the very least. This will aid in the prevention of weariness.","imageName":"","correctAnswer":7663,"isBookmarked":0,"aid":"7662#7663#7664#7665","text":"Every 50 miles#Every two hours#Every five hours#Once per trip"},
{"testId":318,"qid":12071,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#22915#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#wear dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":59,"qid":12076,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":318,"qid":12076,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"If another car is passing you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":318,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":467,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":471,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":475,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":487,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":507,"qid":12080,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#26479","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts"},
{"testId":318,"qid":12081,"description":"When is it safe to share a lane with another vehicle?","explanation":"The key word here is \"safely.\" Arkansas law does not clearly prohibit motorcyclists from riding together. A staggered pattern, on the other hand, is a safer bet. You should not be sharing a lane with other cars. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#35515#35517#43149","text":"Never#If the other vehicle is a motorcycle#If you're riding in the right lane position#If you're riding between two lanes of vehicles"},
{"testId":318,"qid":12083,"description":"When do you need to utilize your side mirrors?","explanation":"Before changing lanes, slowing down, or stopping at an intersection, check your side mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"6537#6661#6662#22962","text":"Before you change lanes#When you are stopped at an intersection#Before you slow down#In all of the above situations"},
{"testId":60,"qid":12084,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you're riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":318,"qid":12084,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you're riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":458,"qid":12084,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you're riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":491,"qid":12084,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you're riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":318,"qid":12085,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":318,"qid":12087,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"6244#6417#30063#31783","text":"use the rear brake only#Use the front brake only#use both the front and rear brakes at the same time#alternate between the rear brake and the front brake"},
{"testId":383,"qid":12087,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"6244#6417#30063#31783","text":"use the rear brake only#Use the front brake only#use both the front and rear brakes at the same time#alternate between the rear brake and the front brake"},
{"testId":318,"qid":12089,"description":"How can you maximize stopping power?","explanation":"Maximum stopping power may be obtained by completely using both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":43142,"isBookmarked":0,"aid":"6304#11174#24897#43142","text":"Use the front brake only#Pull in the clutch while you brake#Alternate between the front and rear brakes#Apply the front and rear brakes at the same time"},
{"testId":59,"qid":12090,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":318,"qid":12090,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":462,"qid":12090,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":318,"qid":12091,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":446,"qid":12091,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":318,"qid":12092,"description":"The vast majority of motorcycle accidents occur.","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":318,"qid":12093,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"Checking the coolant and brake hydraulic fluid levels at least once a week is recommended by the Arkansas Motorcycle Operator Manual.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":318,"qid":12094,"description":"You may improve your nighttime safety by:","explanation":"There are various strategies to improve your nighttime safety: Wear luminous apparel, slow down, turn on your high-beam headlight, and extend your following distance.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6416#6800#6801#10777","text":"increasing your following distance#using your high beam#reducing your speed#Doing all of the above"},
{"testId":70,"qid":12095,"description":"A _______ angle should be used to cross railroad lines if it is not feasible to ride straight over them.","explanation":null,"imageName":"","correctAnswer":10674,"isBookmarked":0,"aid":"10674#10677#10799#10800","text":"45-degree#90-degree#30-degree#10-degree"},
{"testId":318,"qid":12095,"description":"A _______ angle should be used to cross railroad lines if it is not feasible to ride straight over them.","explanation":null,"imageName":"","correctAnswer":10674,"isBookmarked":0,"aid":"10674#10677#10799#10800","text":"45-degree#90-degree#30-degree#10-degree"},
{"testId":318,"qid":12096,"description":"What should you do if the throttle becomes stuck and you are unable to dislodge it?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If this fails, turn off the engine and engage the clutch. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":22925,"isBookmarked":0,"aid":"22925#22926#22927#39852","text":"Flip the engine cut-off switch and pull in the clutch at the same time#Jump off the motorcycle#Pull in the clutch#Stop the motorcycle by applying only the rear brake"},
{"testId":503,"qid":12096,"description":"What should you do if the throttle becomes stuck and you are unable to dislodge it?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If this fails, turn off the engine and engage the clutch. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":22925,"isBookmarked":0,"aid":"22925#22926#22927#39852","text":"Flip the engine cut-off switch and pull in the clutch at the same time#Jump off the motorcycle#Pull in the clutch#Stop the motorcycle by applying only the rear brake"},
{"testId":318,"qid":12097,"description":"When passing a parked car with a driver inside, you should:","explanation":"Drivers do not always notice you approaching. If you are going to pass a parked vehicle with the driver inside, blow your horn to alert the driver that you are approaching. In this manner, he or she will not open the door directly into your path.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#7650#7651","text":"Beep your horn#Flash your brake light#wave your arms#Come to a complete stop"},
{"testId":73,"qid":12099,"description":"Both the motorcycle and the rider should be leaning at the same angle in:","explanation":"In regular turns, the motorbike and rider should lean at the same angle.","imageName":"","correctAnswer":7760,"isBookmarked":0,"aid":"7758#7759#7760#8615","text":"slow, tight turns#quick, sharp turns#normal turns#All of the above"},
{"testId":318,"qid":12099,"description":"Both the motorcycle and the rider should be leaning at the same angle in:","explanation":"In regular turns, the motorbike and rider should lean at the same angle.","imageName":"","correctAnswer":7760,"isBookmarked":0,"aid":"7758#7759#7760#8615","text":"slow, tight turns#quick, sharp turns#normal turns#All of the above"},
{"testId":462,"qid":12099,"description":"Both the motorcycle and the rider should be leaning at the same angle in:","explanation":"In regular turns, the motorbike and rider should lean at the same angle.","imageName":"","correctAnswer":7760,"isBookmarked":0,"aid":"7758#7759#7760#8615","text":"slow, tight turns#quick, sharp turns#normal turns#All of the above"},
{"testId":318,"qid":12104,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"6306#22930#28897#30525","text":"Use the rear brake only#Apply the front brake quickly#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":334,"qid":12104,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"6306#22930#28897#30525","text":"Use the rear brake only#Apply the front brake quickly#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":318,"qid":12106,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"When packing your motorbike, place heavier stuff in the tank bag's front. Loads mounted at the back of the motorbike might impact its handling and even create a wobble.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":318,"qid":12108,"description":"Grabbing the front brake can cause:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":334,"qid":12108,"description":"Grabbing the front brake can cause:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":495,"qid":12108,"description":"Grabbing the front brake can cause:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":34555,"isBookmarked":0,"aid":"8304#8305#34554#34555","text":"stopping quickly#popping a tire#a locked rear wheel#a locked front wheel"},
{"testId":318,"qid":12109,"description":"When should you turn off the engine?","explanation":"If the throttle is locked and you have previously attempted to remove it by twisting it back and forth, you should pull the engine stop switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"8340#8341#10595#35119","text":"If your front tire fails#If your rear tire fails#If your motorcycle is wobbling#If your throttle is stuck and you can't free it"},
{"testId":84,"qid":12110,"description":"A motorcycle is suitable for you if:","explanation":"If both of your feet can touch the ground when sitting on a motorbike, it is the perfect size for you.","imageName":"","correctAnswer":22993,"isBookmarked":0,"aid":"7893#8387#22993#32366","text":"you can reach the handgrips#your height and weight have been taken into account#both of your feet can reach the ground while you are seated#the seat is waist high"},
{"testId":318,"qid":12110,"description":"A motorcycle is suitable for you if:","explanation":"If both of your feet can touch the ground when sitting on a motorbike, it is the perfect size for you.","imageName":"","correctAnswer":22993,"isBookmarked":0,"aid":"7893#8387#22993#32366","text":"you can reach the handgrips#your height and weight have been taken into account#both of your feet can reach the ground while you are seated#the seat is waist high"},
{"testId":471,"qid":12110,"description":"A motorcycle is suitable for you if:","explanation":"If both of your feet can touch the ground when sitting on a motorbike, it is the perfect size for you.","imageName":"","correctAnswer":22993,"isBookmarked":0,"aid":"7893#8387#22993#32366","text":"you can reach the handgrips#your height and weight have been taken into account#both of your feet can reach the ground while you are seated#the seat is waist high"},
{"testId":511,"qid":12110,"description":"A motorcycle is suitable for you if:","explanation":"If both of your feet can touch the ground when sitting on a motorbike, it is the perfect size for you.","imageName":"","correctAnswer":22993,"isBookmarked":0,"aid":"7893#8387#22993#32366","text":"you can reach the handgrips#your height and weight have been taken into account#both of your feet can reach the ground while you are seated#the seat is waist high"},
{"testId":300,"qid":12111,"description":"What causes an engine to lock up?","explanation":"If an engine \"locks up\" or \"freezes,\" it is most likely low on oil. Before embarking on a trip, always check your oil. If oil is required, apply it as soon as possible or the engine may seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#22933#23024#23025","text":"Being low on oil#Changing gears improperly#The front tire skidding#Braking with only one brake"},
{"testId":318,"qid":12111,"description":"What causes an engine to lock up?","explanation":"If an engine \"locks up\" or \"freezes,\" it is most likely low on oil. Before embarking on a trip, always check your oil. If oil is required, apply it as soon as possible or the engine may seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#22933#23024#23025","text":"Being low on oil#Changing gears improperly#The front tire skidding#Braking with only one brake"},
{"testId":59,"qid":12112,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of your motorcycle's stopping force; the rear brake accounts for the remaining 30%. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#24688","text":"The front brake#The rear brake#The emergency brake#The front and rear brakes equally"},
{"testId":318,"qid":12112,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of your motorcycle's stopping force; the rear brake accounts for the remaining 30%. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#24688","text":"The front brake#The rear brake#The emergency brake#The front and rear brakes equally"},
{"testId":292,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":318,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":334,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":412,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":422,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":487,"qid":12114,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent dangerous circumstances, you should scan the road 12 seconds ahead for potential risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":318,"qid":12117,"description":"When can you move in Arkansas if you feel your motorcycle did not trigger a light sensor when stopped at a traffic signal?","explanation":null,"imageName":"","correctAnswer":43151,"isBookmarked":0,"aid":"32895#32899#43150#43151","text":"After coming to a complete stop#After making sure it is safe to do so#After exercising due care#After all of the above requirements are met"},
{"testId":318,"qid":12119,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#31799","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":479,"qid":12119,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#31799","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":507,"qid":12119,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#31799","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":71,"qid":12120,"description":"The best face shield is:","explanation":"A shatter-resistant face shield is the greatest kind of face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":7585,"isBookmarked":0,"aid":"7204#7583#7584#7585","text":"A windshield#approved goggles#sunglasses#a shatter-resistant face shield"},
{"testId":318,"qid":12120,"description":"The best face shield is:","explanation":"A shatter-resistant face shield is the greatest kind of face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":7585,"isBookmarked":0,"aid":"7204#7583#7584#7585","text":"A windshield#approved goggles#sunglasses#a shatter-resistant face shield"},
{"testId":367,"qid":12120,"description":"The best face shield is:","explanation":"A shatter-resistant face shield is the greatest kind of face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":7585,"isBookmarked":0,"aid":"7204#7583#7584#7585","text":"A windshield#approved goggles#sunglasses#a shatter-resistant face shield"},
{"testId":375,"qid":12120,"description":"The best face shield is:","explanation":"A shatter-resistant face shield is the greatest kind of face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":7585,"isBookmarked":0,"aid":"7204#7583#7584#7585","text":"A windshield#approved goggles#sunglasses#a shatter-resistant face shield"},
{"testId":464,"qid":12120,"description":"The best face shield is:","explanation":"A shatter-resistant face shield is the greatest kind of face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":7585,"isBookmarked":0,"aid":"7204#7583#7584#7585","text":"A windshield#approved goggles#sunglasses#a shatter-resistant face shield"},
{"testId":471,"qid":12125,"description":"When riding around a curve, you should:","explanation":"While going through a curve, adjust your lane position as required to have the greatest perspective of what is ahead.","imageName":"","correctAnswer":31135,"isBookmarked":0,"aid":"31133#31134#31135#31136","text":"stay in the center lane position#stay in the left lane position#Change your lane position as needed#stay in the right lane position"},
{"testId":412,"qid":12127,"description":"If you're about to hit something and want the bike to lean right, you should:","explanation":"If you're going to hit with anything and want the motorbike to tilt to the right rapidly, press the inside of the right handgrip.","imageName":"","correctAnswer":22941,"isBookmarked":0,"aid":"22939#22940#22941#22942","text":"push the inside of the left handgrip#pull the left handgrip toward you#push the inside of the right handgrip#pull the right handgrip toward you"},
{"testId":293,"qid":12136,"description":"What is the ideal lane location while it's raining?","explanation":"You should ride in either the left or right lane while it is raining. The middle lane position may be slick due to the accumulation of oil and other vehicle fluids.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6216#6763#7096#7097","text":"Center#Left or right#Center or left#Center or right"},
{"testId":479,"qid":12136,"description":"What is the ideal lane location while it's raining?","explanation":"You should ride in either the left or right lane while it is raining. The middle lane position may be slick due to the accumulation of oil and other vehicle fluids.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6216#6763#7096#7097","text":"Center#Left or right#Center or left#Center or right"},
{"testId":503,"qid":12148,"description":"When transporting merchandise or a passenger,:","explanation":"The stopping distance increases while carrying goods or a passenger. You should adjust your riding appropriately.","imageName":"","correctAnswer":39855,"isBookmarked":0,"aid":"22982#35101#39854#39855","text":"you must ride only during the day#you must hold a special license#your stopping distance will decrease#your stopping distance will increase"},
{"testId":450,"qid":12149,"description":"The most effective strategy to swerve is to:","explanation":"The best approach to swerve is to push the handgrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30084,"isBookmarked":0,"aid":"7437#7438#30083#30084","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side opposite to the direction in which you want to swerve#avoid braking and press the handgrip on the side of the direction in which you want to swerve"},
{"testId":454,"qid":12149,"description":"The most effective strategy to swerve is to:","explanation":"The best approach to swerve is to push the handgrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30084,"isBookmarked":0,"aid":"7437#7438#30083#30084","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side opposite to the direction in which you want to swerve#avoid braking and press the handgrip on the side of the direction in which you want to swerve"},
{"testId":70,"qid":12152,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#26437","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep his or her feet on the footrests"},
{"testId":412,"qid":12152,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#26437","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep his or her feet on the footrests"},
{"testId":425,"qid":12155,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":7829,"isBookmarked":0,"aid":"7828#7829#7830#28994","text":"is on the inside of the curve#may not follow the curve of the road#is on the outside of the curve#is to stay in the center lane position"},
{"testId":471,"qid":12155,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":7829,"isBookmarked":0,"aid":"7828#7829#7830#28994","text":"is on the inside of the curve#may not follow the curve of the road#is on the outside of the curve#is to stay in the center lane position"},
{"testId":330,"qid":12157,"description":"Which lane location is optimal for being seen by the driver of the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":61,"qid":12161,"description":"To ride safely on surfaces that are wet or slippery, you should:","explanation":"Slow down and prevent rapid movements that might result in losing control of the motorbike while riding on slick conditions.","imageName":"","correctAnswer":31128,"isBookmarked":0,"aid":"6758#31126#31127#31128","text":"maintain or increase your speed#hold in the clutch#lean back#avoid sudden moves"},
{"testId":471,"qid":12163,"description":"In the SEE procedure's \"assess\" stage, what are you considering?","explanation":"You analyze cars that may move into your path, possibly dangerous road conditions, including traffic lights and road markings during the \"evaluate\" stage of the SEE approach.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#10831#30783","text":"All of the above#Vehicles that may move into your path#Possibly hazardous road conditions#Traffic signals and road markings"},
{"testId":458,"qid":12166,"description":"How can you stay awake while riding?","explanation":"You may assist reduce weariness by riding no more than six hours each day, taking regular rest stops, and dressing appropriately for the weather.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":467,"qid":12166,"description":"How can you stay awake while riding?","explanation":"You may assist reduce weariness by riding no more than six hours each day, taking regular rest stops, and dressing appropriately for the weather.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":479,"qid":12166,"description":"How can you stay awake while riding?","explanation":"You may assist reduce weariness by riding no more than six hours each day, taking regular rest stops, and dressing appropriately for the weather.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":298,"qid":12167,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors and then look over your shoulder to check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":343,"qid":12167,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors and then look over your shoulder to check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":429,"qid":12167,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors and then look over your shoulder to check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":454,"qid":12167,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors and then look over your shoulder to check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":61,"qid":12168,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly sitting on a motorbike. This will provide you the most steering control.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6826#6827#6829#22958","text":"positioned with your elbows locked#positioned with your hands above your elbows#bent slightly#At a 90-degree angle"},
{"testId":334,"qid":12168,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly sitting on a motorbike. This will provide you the most steering control.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6826#6827#6829#22958","text":"positioned with your elbows locked#positioned with your hands above your elbows#bent slightly#At a 90-degree angle"},
{"testId":294,"qid":12170,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for longer lengths of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":464,"qid":12170,"description":"Motorcycles might lose control on roads soiled by automobile grease. Where are these deposits likely to be located?","explanation":"Motorcycles may slide on oil left on the road by automobiles. These grease strips may be seen near busy crossroads and toll booths, where motorists tend to stop for longer lengths of time.","imageName":"","correctAnswer":7533,"isBookmarked":0,"aid":"7533#7534#7536#22788","text":"At busy intersections#On bridges and overpasses#On gravel roads#On multilane highways"},
{"testId":58,"qid":12182,"description":"You should _____ when passing another vehicle.","explanation":"You should go fast across the driver's blind area while passing another car.","imageName":"","correctAnswer":24596,"isBookmarked":0,"aid":"6629#6630#6632#24596","text":"move as far left as possible#begin your pass from the center lane position#make eye contact with the driver#move quickly through the driver's blind spot"},
{"testId":388,"qid":12184,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#10333#10334#24600","text":"All of the above are correct#You are not in a blind spot#Your space cushion is maximized#You are avoiding hazards"},
{"testId":396,"qid":12184,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#10333#10334#24600","text":"All of the above are correct#You are not in a blind spot#Your space cushion is maximized#You are avoiding hazards"},
{"testId":62,"qid":12190,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike starts to wobble, do not attempt to speed out of the wobble. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":351,"qid":12205,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"You must brake before or after swerving if you need to brake and swerve. Never swerve and brake at the same time.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#7330#7331","text":"Brake while swerving#Brake before or after swerving#Brake after swerving#Brake before swerving"},
{"testId":78,"qid":12206,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the footrests, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24641#24642#24643","text":"your arms are slightly bent#your feet are resting firmly on the footrests#your knees are against the gas tank#all of the above are true"},
{"testId":301,"qid":12206,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the footrests, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24641#24642#24643","text":"your arms are slightly bent#your feet are resting firmly on the footrests#your knees are against the gas tank#all of the above are true"},
{"testId":336,"qid":12206,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the footrests, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24641#24642#24643","text":"your arms are slightly bent#your feet are resting firmly on the footrests#your knees are against the gas tank#all of the above are true"},
{"testId":416,"qid":12206,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the footrests, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24641#24642#24643","text":"your arms are slightly bent#your feet are resting firmly on the footrests#your knees are against the gas tank#all of the above are true"},
{"testId":326,"qid":12210,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers may be unable to see you from certain spots.","imageName":"","correctAnswer":24646,"isBookmarked":0,"aid":"7352#7353#24646#24647","text":"U-turns are not permitted#they are not controlled by traffic signs#other vehicles will have difficulty seeing you from certain locations#they are high-speed intersections"},
{"testId":72,"qid":12218,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":412,"qid":12218,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":416,"qid":12218,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":454,"qid":12218,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":511,"qid":12218,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":367,"qid":12227,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissybar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22963,"isBookmarked":0,"aid":"22290#22291#22292#22963","text":"Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords#Fastening excess cargo to the sissybar"},
{"testId":57,"qid":12228,"description":"If you need to change gears as you approach a turn,:","explanation":"If you need to swap gears as you approach a corner, do it ahead of time. Shifting gears in a turn may be risky.","imageName":"","correctAnswer":22966,"isBookmarked":0,"aid":"22964#22965#22966#22967","text":"shift gears in the turn#shift gears after the turn#shift gears before the turn#you do not need to shift gears for a turn"},
{"testId":326,"qid":12234,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Maintain or increase your speed to assist keep the motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":339,"qid":12234,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Maintain or increase your speed to assist keep the motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":351,"qid":12234,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Maintain or increase your speed to assist keep the motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":434,"qid":12252,"description":"When riding, your attire should be both protective and :","explanation":"When riding, your gear should be safe and totally cover your arms and legs. This is critical in the event of a collision.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":371,"qid":12256,"description":"Dirt and gravel are likely to accumulate __________on a paved road.","explanation":"Take care near the road's edge. Particularly on bends and ramps going to and from roads, dirt and gravel amass along the sides of the road.","imageName":"","correctAnswer":10966,"isBookmarked":0,"aid":"10964#10965#10966#10967","text":"in the left wheel track#near the center of the road#along the sides of the road#in all of the above areas"},
{"testId":355,"qid":12261,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":322,"qid":12271,"description":"When you are ______ a vehicle at night, you must use your motorcycle's high-beam headlight.","explanation":"You must use your motorcycle's high beam at nighttime while you are not pursuing or approaching a vehicle. If you're approaching a car, use your low beam.","imageName":"","correctAnswer":24668,"isBookmarked":0,"aid":"6139#6140#24668#24669","text":"following#meeting#neither following nor meeting#following or meeting"},
{"testId":322,"qid":12272,"description":"When prepared to pass a vehicle in front of you, ride in ______ lane.","explanation":"When prepared to pass a car in front of you, ride in the left lane. That lane position will allow the vehicle you are passing to view you in his or her side mirror more easily.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":322,"qid":12273,"description":"What should you do to prevent colliding with a cutting vehicle?","explanation":"Even if you utilize both brakes correctly, you may not always have enough distance to stop. An item may materialize unexpectedly in your path, or the car ahead may abruptly halt. To escape a collision, you may have to turn swiftly or swerve around it. The wisest course of action in this case would be to veer into the left lane.","imageName":"moto10.png","correctAnswer":43667,"isBookmarked":0,"aid":"43666#43667#43668","text":"Grab at the front brake#Swerve into the left lane#Brake abruptly while swerving"},
{"testId":322,"qid":12274,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you make a turn too quickly, you may stray into the other lane. Before beginning the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":322,"qid":12275,"description":"While driving on a curving road, your lane position should:","explanation":"When riding on a curving road, you should frequently shift lane positions to provide you the greatest perspective of what's ahead.","imageName":"","correctAnswer":6295,"isBookmarked":0,"aid":"6295#24671#24672#24673","text":"change constantly to give you the best view#remain the center position#remain the left position#remain the right position"},
{"testId":322,"qid":12276,"description":"What should you do when an object appears unexpectedly in your path as you ride?","explanation":"Even if you utilize both brakes correctly, you may not always have enough distance to stop. An item may materialize unexpectedly in your path, or the car ahead may abruptly halt. To escape a collision, you may have to turn swiftly or swerve around it. Because there is traffic in the left lane, the safest approach in this case is to veer onto the road's shoulder.","imageName":"moto11.png","correctAnswer":43669,"isBookmarked":0,"aid":"43667#43668#43669","text":"Swerve into the left lane#Brake abruptly while swerving#Swerve onto the shoulder of the road"},
{"testId":322,"qid":12277,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24675#24676","text":"Never#When he or she makes eye contact with you#When he or she is starting to slow down#When you are legally entitled to the right-of-way"},
{"testId":322,"qid":12278,"description":"What should you do if the rear wheel skids when braking on a good traction聽surface?","explanation":"If the back wheel locks, do not try to unlock it. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":42607,"isBookmarked":0,"aid":"42604#42605#42606#42607","text":"Release the brake#Grab the front brake#Increase the throttle#Do not release the rear brake and gradually apply the front brake"},
{"testId":322,"qid":12279,"description":"You can keep a sufficient spacing cushion by:","explanation":"You may preserve an appropriate space cushion by altering your lane position as you ride to keep you as far away from risks as possible.","imageName":"","correctAnswer":6541,"isBookmarked":0,"aid":"6541#6543#24678#24679","text":"adjusting your lane position as you ride#always riding in the center lane position#speeding up when you are approached by tailgaters#beeping your horn at drivers who come too close"},
{"testId":322,"qid":12280,"description":"When you come to a complete stop at a stop sign, your bike should remain in :","explanation":"When you come to a complete stop at a stop sign, keep your bike in first gear so you can simply restart.","imageName":"","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":322,"qid":12281,"description":"In which lane position should you ride to avoid hitting with a hazard in the middle of the lane?","explanation":"To avoid colliding with a danger in the middle of the lane, ride in the left or right lane.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":322,"qid":12282,"description":"When is the ideal time to'straighten' the handlebars if you must brake while leaning?","explanation":null,"imageName":"","correctAnswer":43670,"isBookmarked":0,"aid":"43670#43671#43672","text":"In the last few feet of stopping#While applying the brakes#While reducing the throttle"},
{"testId":322,"qid":12283,"description":"When is it safe for you to travel next to another motorcycle?","explanation":"Motorcycles need a full lane width for safe driving even though they are smaller than vehicles. As a result, riding beside another motorbike is never safe.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24682#24683#24684","text":"Never#When traffic is heavy#If you are riding in a group#When you are riding with a new rider"},
{"testId":322,"qid":12284,"description":"When you are riding through turns and curves, you should:","explanation":"Be careful while navigating bends and turns. To go where you want to go, look through the turn. Keep your gaze level with the horizon and just turn your head, not your shoulders.","imageName":"","correctAnswer":10723,"isBookmarked":0,"aid":"10723#45521#45522#45523","text":"keep your eyes level with the horizon#look forward#lean at the same angle with your motorcycle to counterbalance#turn your shoulders with your head"},
{"testId":322,"qid":12285,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by other motorists.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6940#6941#6942#6943","text":"Avoid driving in heavy traffic#wear reflective clothing#drive only during the day#drive in areas where there are lots of motorcycles"},
{"testId":322,"qid":12286,"description":"When you slow down in places where other people might not expect it, you should:","explanation":"When slowing down in an area where other cars may not anticipate it, touch your brake, which will flash your brake light to inform other drivers.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6278#6286#6408#22258","text":"Beep your horn#Flash your brake light#change lanes before slowing down#check your blind spot"},
{"testId":322,"qid":12287,"description":"You should park __________, along the side of the road.","explanation":"When parked on the side of the road, keep your motorbike at a 45- to 90-degree angle to the curb, with the wheel or fender contacting the curb.","imageName":"","correctAnswer":6406,"isBookmarked":0,"aid":"74#6406#7020#10968","text":"parallel to the curb#at an angle of 45 to 90 degrees to the curb#on the curb#with your front tire on the curb"},
{"testId":322,"qid":12288,"description":"For the track and road seams running parallel to your course,:","explanation":"Move far enough away from tracks, ruts, or pavement seams to cross at an angle of at least 45 degrees for track and road seams that run parallel to your route. Then, as seen in picture B, make a quick, sharp turn. When you edge across, your tires may grab and throw you off balance.","imageName":"2moto2.png","correctAnswer":45564,"isBookmarked":0,"aid":"45559#45562#45563#45564","text":"move closer to tracks, ruts, or pavement seams#cross at an angle of up to 25 degrees#edge carefully across, as shown in image A#make a quick, sharp turn, as shown in image B"},
{"testId":322,"qid":12289,"description":"How do you refrain from using too much throttle?","explanation":"By beginning with your right wrist flat, you may avoid applying too much throttle.","imageName":"","correctAnswer":10771,"isBookmarked":0,"aid":"10769#10770#10771#24689","text":"Start with your right wrist raised#Keep the clutch pulled in#Start with your right wrist flat#Ride in lower gears"},
{"testId":322,"qid":12290,"description":"When it is raining, the optimal position for traction is:","explanation":"Ride in the tracks that previous cars have left when it is pouring.","imageName":"","correctAnswer":24690,"isBookmarked":0,"aid":"7176#7177#7178#24690","text":"The center lane position#the shoulder of the road#the left lane position#the tracks left by other drivers"},
{"testId":322,"qid":12291,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"In any case when the car behind you may not see that you are slowing down, flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10754#10755","text":"Beep your horn to warn the driver#Move into another lane#Accelerate to make it through the light#Tap your brake to flash your brake light"},
{"testId":322,"qid":12292,"description":"To carry a passenger on your motorbike, you must have:","explanation":"To transport a passenger on your motorbike, you must have footrests for the individual. The seat must also be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#7206#22946","text":"A windshield#footrests for the passenger#side saddlebags#high-quality shocks"},
{"testId":322,"qid":12293,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. A motorist turning left may not see a motorbike coming.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":322,"qid":12294,"description":"Which of the following statement about carrying cargo on your motorcycle is correct?","explanation":"Fill your saddlebags equally to spread the weight. If one side is heavier than the other, the bike may drift.","imageName":"","correctAnswer":10762,"isBookmarked":0,"aid":"10759#10760#10761#10762","text":"Use ropes to secure the load#Attach the cargo to your gas tank#Never carry cargo on a motorcycle#Fill saddlebags evenly to prevent drifting"},
{"testId":322,"qid":12295,"description":"The most effective approach to reduce your risks of skidding on a slick surface is to:","explanation":"Slowing down as much as possible before you reach a slippery surface is the best way to reduce your chances of skidding.","imageName":"","correctAnswer":7333,"isBookmarked":0,"aid":"7332#7333#7334#10656","text":"drag your feet on the ground#slow down before you get to the slippery surface#ride on the shoulder of the road#Do all of the above"},
{"testId":322,"qid":12296,"description":"Pulling over to the side of the road is more risky for motorcycles than it is for cars because:","explanation":"Pulling over to the side of the road may be more dangerous for motorbikes than for vehicles since the shoulder is typically filled with sand or gravel, which gives motorcycles poor traction.","imageName":"","correctAnswer":24693,"isBookmarked":0,"aid":"24692#24693#24694#24695","text":"it is illegal for motorcycles to park on the side of the road#the shoulder is often sandy and may provide less traction#it is more difficult for motorcycles to stop quickly#motorcycles require a curb to park against"},
{"testId":322,"qid":12298,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep the motorbike from falling.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#24697","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on metal gratings"},
{"testId":322,"qid":12299,"description":"You may improve your nighttime safety by:","explanation":"Here are a few things you can do to improve your nighttime safety: Reduce your speed, and increase your following distance by using your high-beam headlight.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6416#6800#6801#10777","text":"increasing your following distance#using your high beam#reducing your speed#Doing all of the above"},
{"testId":322,"qid":12300,"description":"Choose the best answer for transporting passengers.","explanation":"Passengers should always keep their feet on their footrests, even if the motorbike is stopped at a signal.","imageName":"","correctAnswer":24698,"isBookmarked":0,"aid":"7632#22801#24698#24699","text":"Passengers should sit as far back as possible#Passengers should put their feet down at stops#Passengers should keep their feet on their footrests at all times#Passengers should avoid holding on to the rider"},
{"testId":322,"qid":12301,"description":"When you observe a car on an entry ramp ready to merge into your lane, you should :","explanation":"If you observe a vehicle on an entry ramp ready to enter into your lane, shift lanes to allow the driver enough space to join.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#7651","text":"Change lanes#Beep your horn#Flash your brake light#Come to a complete stop"},
{"testId":322,"qid":12302,"description":"Motorcyclists should communicate to each other when riding in a group by:","explanation":"Motorcyclists should communicate via hand signals while riding in a group. Before you go on your tour, make sure that everyone in your party is acquainted with the hand signals.","imageName":"","correctAnswer":7050,"isBookmarked":0,"aid":"6738#7050#7838#7840","text":"riding in pairs#hand signals#changing lane positions#making eye contact"},
{"testId":322,"qid":12303,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6242#6244#6245#24700","text":"continue applying the brake#use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly"},
{"testId":322,"qid":12305,"description":"_____ might cause your motorbike to shake.","explanation":"Improper cargo loading might cause your motorbike to wobble. To keep the motorbike balanced, make sure the baggage is appropriately distributed.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":322,"qid":12306,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"Do not ride a motorbike if you are carrying a cargo that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":24701,"isBookmarked":0,"aid":"8101#24701#24702#24703","text":"ride under 30 mph#do not ride a motorcycle#ride only in residential areas#ride only in first gear"},
{"testId":322,"qid":12307,"description":"What is the best way for you to find out what is happening on behind you?","explanation":"Checking your side mirrors regularly is the most effective approach for you to know what is going on behind you.","imageName":"","correctAnswer":22827,"isBookmarked":0,"aid":"22825#22826#22827#22828","text":"Turn around and scan the area#Carry a passenger to check for you#Check your side mirrors frequently#Stop and look around"},
{"testId":322,"qid":12308,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"When you wear a U.S. DOT-compliant motorcycle safety helmet, face or eye protection, and protective gear such as a leather or long-sleeved jacket, long, heavy trousers over ankle boots, and full-fingered leather gloves, you have a significantly higher chance of avoiding major injury in any crash.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#24704","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is U.S. Department of Transportation (DOT) compliant"},
{"testId":322,"qid":12309,"description":"What should you do before you take a turn?","explanation":"Turn your head, not your shoulders, to gaze where you wish to go. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#32884#32885#32886","text":"Do all of the above#Turn your head, not your shoulders#Keep your eyes level with the horizon#Reduce your speed before the turn"},
{"testId":322,"qid":12310,"description":"If you feel the driver in front of you is unaware that you are following him or her, you should :","explanation":"If you feel the motorist in front of you is unaware that you are following him or her, ride in the middle lane. This posture puts you in the driver's rear-view mirror, which is the mirror that drivers check the most.","imageName":"","correctAnswer":8312,"isBookmarked":0,"aid":"8312#8313#8314#24706","text":"move into the center lane position to increase your visibility#Increase your following distance to 5 seconds#Try to pass the vehicle as soon as possible#Decrease your speed and ride in the left lane position"},
{"testId":322,"qid":12311,"description":"If you can't cross an obstacle straight on, approach it at a ____-degree angle as possible.","explanation":"If you can't cross a barrier straight on, try to approach it at a 90-degree angle.","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5448#6354#6355#8331","text":"20#45#90#105"},
{"testId":322,"qid":12312,"description":"You can boost your visibility by:","explanation":"Position yourself in the lane where you are most likely to be spotted and where there is enough space around you. As traffic conditions change, adjust your position.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":322,"qid":12313,"description":"Which member in a riding group sets the pace?","explanation":"The tail rider sets the pace while cycling in a group. To remain in a group, if the person in front of you slows down, you should likewise slow down.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":322,"qid":12314,"description":"When changing lanes on a multilane highway, check the lane next to you and:","explanation":"When changing lanes on a multilane road, always sure to scan both the distant lane and the lane next to you. There might be cars attempting to join into the same lane as you.","imageName":"","correctAnswer":8326,"isBookmarked":0,"aid":"8326#8451#8452#8453","text":"the far lane#behind you#in front of you#your throttle"},
{"testId":322,"qid":12315,"description":"How should you cross rain channels or bridge gratings?","explanation":null,"imageName":"3moto3.png","correctAnswer":45552,"isBookmarked":0,"aid":"45551#45552#45553#45554","text":"As shown in image B#As shown in image A#Either as shown in image A or B#Crossing at an angle"},
{"testId":322,"qid":12316,"description":"When transporting a passenger, instruct the individual to:","explanation":"Instruct the passenger to keep both feet on the footrests, even when the motorcycle is stopped; keep legs away from the muffler(s), belts, chains, or moving parts; sit as far forward as possible without crowding you; stay directly behind you, leaning as you lean; and avoid unnecessary talk or motion.","imageName":"","correctAnswer":24709,"isBookmarked":0,"aid":"10381#24708#24709#24710","text":"grip the backrest#wear a seat belt#hold firmly on to your waist, hips, or belt#put his or her feet on the ground when the motorcycle is stopped"},
{"testId":322,"qid":12317,"description":"Weaving while traversing a metal grate:","explanation":"Weaving from cycling over a metal grating is not dangerous. Stay calm and focused.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#8221#24711","text":"is not hazardous#is dangerous to motorcycle riders#is harmful to the motorcycle#is caused by poor motorcycle skills"},
{"testId":322,"qid":12318,"description":"When should you adjust your mirrors when riding with a passenger?","explanation":null,"imageName":"","correctAnswer":42611,"isBookmarked":0,"aid":"42608#42609#42610#42611","text":"When stopped at a first intersection#Before your trip, but with your passenger standing beside the motorcycle#Shortly after you start riding with your passenger#Before your trip, but with your passenger on the seat with you"},
{"testId":322,"qid":12319,"description":"If you are riding at night, you should:","explanation":"It is preferable to ride slower at night than during the day so you can identify risks ahead of time and respond promptly to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"7790#10559#10560#24714","text":"stay close behind the car in front of you#not use your headlight#wear a tinted face shield#ride slower than you do during the day"},
{"testId":322,"qid":12320,"description":"How should you cross railroad or trolley tracks and pavement seams?","explanation":"To cross tracks, it is usually safer to ride straight inside your lane, as seen in figure A. Turning at a 90-degree angle to use tracks might be more perilous since your route may lead you into another lane of traffic.","imageName":"1moto1.png","correctAnswer":45557,"isBookmarked":0,"aid":"45555#45556#45557#45558","text":"By edging across#By making a quick, sharp turn#Straight within your lane as shown in image A#Turning to take tracks head-on (at a 90-degree angle) as shown in image B"},
{"testId":388,"qid":12325,"description":"Rolling the throttle through a turn will:","explanation":"Through a turn, rolling on the throttle will assist steady the motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#22247","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help stop the motorcycle"},
{"testId":66,"qid":12335,"description":"You should________________ while riding a motorcycle because it is more exhausting than driving a car.","explanation":"Take regular rests since operating a motorbike is more strenuous than operating a vehicle.","imageName":"","correctAnswer":7216,"isBookmarked":0,"aid":"7216#7217#7218#7219","text":"take frequent breaks#drink caffeinated beverages#avoid drinking water#avoid wearing dark eyewear"},
{"testId":67,"qid":12336,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22894#22895#22896","text":"No, the rider should not lean#Yes, the rider should lean in the opposite direction#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward"},
{"testId":363,"qid":12336,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22894#22895#22896","text":"No, the rider should not lean#Yes, the rider should lean in the opposite direction#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward"},
{"testId":326,"qid":12350,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#8083","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot pedal"},
{"testId":375,"qid":12350,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#8083","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot pedal"},
{"testId":379,"qid":12350,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#8083","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot pedal"},
{"testId":295,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":375,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":388,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":396,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":422,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":507,"qid":12367,"description":"Riding a motorcycle with both legs on the same side is:","explanation":"Riding on the same side of the motorbike with both legs is never authorized. Both the rider and the passenger must always straddle the motorbike.","imageName":"","correctAnswer":8247,"isBookmarked":0,"aid":"8247#10451#10452#10453","text":"never permitted#permitted for passengers only#permitted only if you are carrying large items#permitted for children only"},
{"testId":72,"qid":12398,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#7701#7702","text":"the left hand lever#the right hand lever#the left foot pedal#the right foot pedal"},
{"testId":400,"qid":12398,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#7701#7702","text":"the left hand lever#the right hand lever#the left foot pedal#the right foot pedal"},
{"testId":383,"qid":12421,"description":"Which of the following should be standard equipment on a street-legal motorcycle?","explanation":"A street-legal motorbike must include all of the standard controls, such as a headlight, taillight, brake light, turn signals, horn, two mirrors, and front and rear brakes, for safe riding.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6063#10983#24749","text":"All of the above#Front and rear brakes#Horn and two mirrors#Headlight, taillight, brake light, and turn signals"},
{"testId":464,"qid":12423,"description":"If you intend to pass another vehicle but believe it may move into your lane, you should slow down.","explanation":"If you want to pass another car but believe it may move into your lane, use your horn to alert the driver.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#6287#6288","text":"Beep your horn#Flash your brake light#not pass#pass as fast as possible"},
{"testId":412,"qid":12429,"description":"Lane positioning helps you to _________.","explanation":"Selecting the ideal lane location allows you to avoid blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":70,"qid":12443,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both brakes at all times. This is the most efficient form of braking.","imageName":"","correctAnswer":7524,"isBookmarked":0,"aid":"7522#7524#10591#24769","text":"using the front brake only in emergencies#using both brakes every time you stop#alternating between the front and rear brakes#using only the front brake in most stopping situations"},
{"testId":74,"qid":12451,"description":"The front brake is more crucial because:","explanation":"The front brake is more crucial since it provides the majority of the stopping force (70 percent).","imageName":"","correctAnswer":7898,"isBookmarked":0,"aid":"7897#7898#7899#7900","text":"it provides all of the stopping power#it provides most of the stopping power#it is used most often#it provides less of the stopping power"},
{"testId":359,"qid":12451,"description":"The front brake is more crucial because:","explanation":"The front brake is more crucial since it provides the majority of the stopping force (70 percent).","imageName":"","correctAnswer":7898,"isBookmarked":0,"aid":"7897#7898#7899#7900","text":"it provides all of the stopping power#it provides most of the stopping power#it is used most often#it provides less of the stopping power"},
{"testId":294,"qid":12463,"description":"The best defense against other vehicle is:","explanation":"Maintaining an appropriate distance cushion between you and other cars provides the greatest protection from other vehicles.","imageName":"","correctAnswer":6443,"isBookmarked":0,"aid":"6443#6800#8178#8179","text":"maintaining an adequate space cushion#using your high beam#wearing protective clothing#choosing the right helmet"},
{"testId":326,"qid":12471,"description":"Which of the following is NOT a component of the process of upshifting?","explanation":"To upshift, 1) roll off the throttle while clutching, 2) raise the shift lever as far as it will go, and 3) ease out of the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":24795,"isBookmarked":0,"aid":"24792#24793#24794#24795","text":"Easing out of the clutch and adjusting the throttle#Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle"},
{"testId":326,"qid":12472,"description":null,"explanation":"Maintaining or increasing your speed while turning helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6202#7596#24796","text":"helps stabilize the motorcycle#is safer because you complete the turn quickly#should never be done#should only be done when passing others"},
{"testId":326,"qid":12473,"description":"If your front wheel locks, the best thing you can do is:","explanation":"If your front wheel locks, the simplest way to restore control is to let off of the brake and gently reapply it.","imageName":"","correctAnswer":6243,"isBookmarked":0,"aid":"6242#6243#6244#6245","text":"continue applying the brake#release the brake and reapply it smoothly#use the rear brake only#release the rear brake only"},
{"testId":326,"qid":12474,"description":"Which lane location is optimal for being spotted by the driver ahead of you?","explanation":"If you want the vehicle ahead of you to see you, the middle lane is the best option. It increases your visibility in the rear-view mirror of the motorist in front of you.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":326,"qid":12475,"description":"Head checks_______, while riding a motorcycle:","explanation":"Head checks should be conducted before changing lanes when riding a motorbike.","imageName":"","correctAnswer":24798,"isBookmarked":0,"aid":"6339#6340#24797#24798","text":"only need to be done at night#are not required because motorcycles do not have blind spots#only need to be done when you are preparing to make a left turn#should be performed before you change lanes"},
{"testId":326,"qid":12476,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle so that you can see effectively in both ways.","imageName":"","correctAnswer":6343,"isBookmarked":0,"aid":"6343#6344#6345#6346","text":"At an angle#Parallel to the roadside#Perpendicular to the roadside#With the rear tire off the road"},
{"testId":363,"qid":12476,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle so that you can see effectively in both ways.","imageName":"","correctAnswer":6343,"isBookmarked":0,"aid":"6343#6344#6345#6346","text":"At an angle#Parallel to the roadside#Perpendicular to the roadside#With the rear tire off the road"},
{"testId":379,"qid":12476,"description":"How should you position your motorcycle as you prepare to enter the traffic from the roadside?","explanation":"When approaching the highway from the roadside, position yourself at an angle so that you can see effectively in both ways.","imageName":"","correctAnswer":6343,"isBookmarked":0,"aid":"6343#6344#6345#6346","text":"At an angle#Parallel to the roadside#Perpendicular to the roadside#With the rear tire off the road"},
{"testId":326,"qid":12477,"description":"The easiest technique to deter another driver from sharing your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other drivers from attempting to share the lane with you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":326,"qid":12478,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissybar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22963,"isBookmarked":0,"aid":"22290#22291#22292#22963","text":"Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords#Fastening excess cargo to the sissybar"},
{"testId":326,"qid":12479,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep the motorbike from falling.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#24697","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on metal gratings"},
{"testId":326,"qid":12481,"description":"Which lane position should you ride in to prevent a collision with a hazard in the center of the lane?","explanation":"To prevent a collision with a danger in the middle of the lane, ride on the left or right side of the road.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":326,"qid":12482,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is a lot of traffic, when a motorist wants to pass you, or when you are getting ready to make a turn at an intersection, another car can want to share a lane with you. To deter other cars from using your lane, ride in the middle of the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":326,"qid":12483,"description":"Which brake should you use to slow down?","explanation":"Use both the front and rear brakes to slow down. Although the front brake is more powerful, both brakes should be engaged at all times.","imageName":"","correctAnswer":6833,"isBookmarked":0,"aid":"6830#6831#6832#6833","text":"Front brake only#Rear brake only#Front or rear brakes#Both front and rear brakes at the same time"},
{"testId":326,"qid":12484,"description":"When is it permissible to travel side by side with another motorcycle?","explanation":"Despite being smaller than vehicles, motorbikes nevertheless use the whole lane. As a result, riding beside another motorbike is never safe.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8034#24682#24684","text":"Never#When you are riding in a group#When traffic is heavy#When you are riding with a new rider"},
{"testId":326,"qid":12485,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6940#6941#6942#6943","text":"Avoid driving in heavy traffic#wear reflective clothing#drive only during the day#drive in areas where there are lots of motorcycles"},
{"testId":326,"qid":12486,"description":"You should have at least _____ seconds between you and the car in front of you.","explanation":"There should be at least two seconds between you and the car in front of you.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":326,"qid":12487,"description":"Which wheel(s) should touch the curb when you park?","explanation":"Your motorbike should be parked perpendicular to the curb, with the back wheel contacting the curb.","imageName":"","correctAnswer":7167,"isBookmarked":0,"aid":"7165#7167#10634#24802","text":"The front#The rear#Neither#Both"},
{"testId":326,"qid":12488,"description":"What is controlled by the left-hand lever?","explanation":"The clutch is controlled by the lever on the left. The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":7191,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":326,"qid":12489,"description":"When should you bike in a single file when riding in a group?","explanation":"When biking in a group, ride single file when approaching or leaving a highway and when navigating bends.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":326,"qid":12490,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car while driving by, blow your horn to alert the driver that you are approaching. Otherwise, the driver may do something unexpected that puts you in risk.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22773#22979","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Ride in the right lane position so you will be close to the vehicle#Beep your horn to let the driver know you're there"},
{"testId":326,"qid":12492,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If an unanticipated obstruction forces you to swerve or turn left suddenly, squeeze the left handgrip to begin the swerve or turn.","imageName":"","correctAnswer":24804,"isBookmarked":0,"aid":"6423#6569#24803#24804","text":"Apply the rear brake#Apply the front brake#press the right handgrip#press the left handgrip"},
{"testId":326,"qid":12494,"description":"Why are motorcycle accidents so common in curves?","explanation":"Motorcycle accidents are prevalent on bends because the rider may go wide and hit with anything.","imageName":"","correctAnswer":24805,"isBookmarked":0,"aid":"7526#7527#24805#24806","text":"It is difficult to steer a motorcycle#Leaning in curves causes motorcycles to tip over#Motorcyclists tend to run wide on curves if they are not careful#Motorcycles cannot stay in one lane position while moving through a turn"},
{"testId":326,"qid":12495,"description":"When passing a parked car with a driver inside, you should:","explanation":"If you are going to pass a parked automobile with the driver inside, you should sound your horn. Drivers often fail to notice you approaching.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#7650#7651","text":"Beep your horn#Flash your brake light#wave your arms#Come to a complete stop"},
{"testId":326,"qid":12496,"description":"Motorcyclists must use eye protection.","explanation":"Even though the motorbike has a windshield, riders must always wear protective eyewear. A windscreen DOES NOT replace goggles or a face shield.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7866#7867#24807","text":"At all times#only while riding on highways and freeways#only if they choose to do so#when riding a motorcycle without a windshield"},
{"testId":326,"qid":12497,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure the motorbike is moving slowly enough for the gear you're going to downshift into to prevent lurching.","imageName":"","correctAnswer":24644,"isBookmarked":0,"aid":"10640#10798#24644#24808","text":"Only downshift if you are an experienced rider#Brake while shifting#Make sure the motorcycle is traveling slowly enough for the lower gear#Never downshift below second gear"},
{"testId":326,"qid":12498,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent using too much throttle, start your motorbike with your right wrist down.","imageName":"","correctAnswer":6526,"isBookmarked":0,"aid":"6525#6526#6528#10850","text":"up#down#locked#as comfortable as you wish"},
{"testId":326,"qid":12499,"description":"Eye contact with other drivers:","explanation":"Making eye contact with drivers does not ensure they will yield to you. Even when cars gaze straight towards motorcycles, they often do not notice them.","imageName":"","correctAnswer":7989,"isBookmarked":0,"aid":"7986#7987#7988#7989","text":"ensures they will stop and let you through#should be avoided to discourage aggression#should be made before crossing every intersection#is not a guarantee they will yield to you"},
{"testId":326,"qid":12500,"description":"How do you refrain from using too much throttle?","explanation":"Starting with your right wrist down can help you avoid using too much throttle.","imageName":"","correctAnswer":10921,"isBookmarked":0,"aid":"10769#10770#10772#10921","text":"Start with your right wrist raised#Keep the clutch pulled in#Only ride in lower gears#Start with your right wrist down"},
{"testId":326,"qid":12501,"description":"Motorcycle groups with more than _____ riders should be divided into smaller groups.","explanation":"Motorcycle groups of more than four or five riders should separate apart.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":326,"qid":12503,"description":"While riding, you see hazards on the left side of the lane. What lane position should you take?","explanation":"If you observe risks on the left side of the lane while riding, pick a center or right lane position to make yourself more visible and avoid the hazards.","imageName":"","correctAnswer":7097,"isBookmarked":0,"aid":"7097#11008#11010#24809","text":"Center or right#Left, closer to the hazard#Whichever lane position you generally prefer#Whichever lane position you are currently in"},
{"testId":326,"qid":12504,"description":"Which lane position provides the greatest visibility, when following another vehicle?","explanation":"When following another vehicle, the center lane position allows you to be seen the best.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#8108","text":"Center#Right#Left#Left or right"},
{"testId":326,"qid":12505,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you should always ride with the headlight on, even in daylight. Since 1978, motorcycles have been developed to turn on the headlamp whenever the engine is started.","imageName":"","correctAnswer":24810,"isBookmarked":0,"aid":"8193#8194#8196#24810","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Keep the headlight on at all times while riding"},
{"testId":326,"qid":12506,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"If you want to transport a passenger, your motorbike must include foot pegs and a seat for the individual.","imageName":"","correctAnswer":24756,"isBookmarked":0,"aid":"7204#8224#8615#24756","text":"A windshield#special shocks#All of the above#foot pegs for the passenger"},
{"testId":326,"qid":12507,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake provides 75 percent of your motorcycle's stopping force.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":326,"qid":12509,"description":"If you have a lot of stuff on your motorcycle, you may need to modify your :","explanation":"If you are carrying a hefty load on your motorbike, you may need to modify the suspension to manage the extra weight.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":326,"qid":12510,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Motorcycle riders should never ride in groups. A motorbike needs the whole width of the lane to function correctly.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#17347#24811#24812","text":"Never#When approaching intersections#When turning sharp corners#When riding at night"},
{"testId":326,"qid":12511,"description":"When transporting a passenger, instruct the individual to:","explanation":"Instruct the passenger to maintain both feet on the pegs, even while the motorbike is stopped; keep legs away from the muffler(s), belts, chains, or moving components; sit as far forward as feasible without crowding you; remain squarely behind you, leaning as you lean; and prevent needless chatter or movements.","imageName":"","correctAnswer":24709,"isBookmarked":0,"aid":"10381#24708#24709#24813","text":"grip the backrest#wear a seat belt#hold firmly on to your waist, hips, or belt#put his or feet on the ground when the motorcycle is stopped"},
{"testId":326,"qid":12512,"description":"To prevent potentially dangerous situations, you should:","explanation":"To prevent potentially dangerous circumstances, you should scan the road ahead for any risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":326,"qid":12513,"description":"Convex motorcycle side mirrors make automobiles appear ________.","explanation":"Convex side mirrors on motorcycles make automobiles seem farther away than they are.","imageName":"","correctAnswer":10429,"isBookmarked":0,"aid":"10426#10427#10428#10429","text":"closer than they are#bigger than they are#smaller than they are#farther away than they are"},
{"testId":326,"qid":12514,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards for motorbikes. When driving through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":326,"qid":12515,"description":"Where should you stop while approaching a blind junction with a stop line?","explanation":"Stop at the stop line initially while approaching a blind junction with a stop line. Then move forward and come to a halt in a more visible position.","imageName":"","correctAnswer":24076,"isBookmarked":0,"aid":"7374#10668#10670#24076","text":"At the stop line only#Past the stop line, where you can see clearly#On the stop line#At the stop line first, then farther to help see"},
{"testId":326,"qid":12516,"description":"If your motorcycle does not have a windshield, you must :","explanation":"If your motorbike lacks a windshield, both the rider and any passengers must wear safety goggles or a face protection. (According to the Handbook, even if your motorbike has a windshield, there is no replacement for a face protection or goggles.)","imageName":"","correctAnswer":10456,"isBookmarked":0,"aid":"8194#10456#24818#24819","text":"Avoid carrying passengers#wear safety goggles or a face shield#get your motorcycle equipped with a windshield before you ride it#drive only on paved roads"},
{"testId":326,"qid":12517,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":326,"qid":12518,"description":"The quickest method to come to a halt is to:","explanation":"Applying both brakes firmly is the fastest method to stop.","imageName":"","correctAnswer":8050,"isBookmarked":0,"aid":"7326#8049#8050#22260","text":"Pull the kill engine switch#apply the front brake quickly#apply both brakes firmly#use only the rear brake"},
{"testId":326,"qid":12519,"description":"When can you move when stopped at a traffic light if you feel your motorcycle has not activated the light sensor?","explanation":"If you feel your motorbike has not activated the light sensor while stopped at a traffic light, you may continue after waiting at least 120 seconds or two complete light cycles, but only when it is safe to do so.","imageName":"","correctAnswer":37195,"isBookmarked":0,"aid":"37192#37193#37194#37195","text":"(a) When it is safe to do so#(b) After waiting for 120 seconds#(c) After waiting for two light cycles#(a) and either (b) or (c)"},
{"testId":326,"qid":12520,"description":"The most likely location of a collision is:","explanation":"An junction is the most probable location for a collision since numerous cars are attempting to perform many tasks at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":367,"qid":12532,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":454,"qid":12573,"description":"When your motorcycle is parked on the side of the road, it should be:","explanation":"When parked along the road, your motorbike should be angled with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":60,"qid":12582,"description":"Which of the following surfaces is the most dangerous for riding a motorcycle?","explanation":"Gravel roads are the most dangerous for a motorcycle to ride on because they give the least amount of traction.","imageName":"","correctAnswer":6717,"isBookmarked":0,"aid":"6717#6803#6805#24691","text":"Gravel roads#Newly paved roads#Rural roads#Multilane highways"},
{"testId":330,"qid":12584,"description":"When parking beside a curb, you should park _________ touching the curb.","explanation":"When parking near to a curb, park at an angle with the rear tire parallel to the curb. This will enable you to securely depart the parking place.","imageName":"","correctAnswer":6862,"isBookmarked":0,"aid":"6862#6863#6864#6865","text":"the rear wheel#the front wheel#both wheels#no wheels"},
{"testId":450,"qid":12587,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6996#6998#33955#33957","text":"being seen by the driver ahead of you#pulling up to blind intersections#preparing to pass the vehicle ahead of you#preparing to pull off the road"},
{"testId":454,"qid":12587,"description":"The middle lane location is optimal for:","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6996#6998#33955#33957","text":"being seen by the driver ahead of you#pulling up to blind intersections#preparing to pass the vehicle ahead of you#preparing to pull off the road"},
{"testId":454,"qid":12596,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since a motorbike tends to travel wide on a curve and may end up in another lane if the driver is not attentive.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":503,"qid":12596,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since a motorbike tends to travel wide on a curve and may end up in another lane if the driver is not attentive.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":343,"qid":12600,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":446,"qid":12600,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":479,"qid":12600,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the back brake to keep your car stable as you utilize the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":295,"qid":12608,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"Your blood alcohol content may be affected by how much alcohol you consume, how quickly you drink it, and how much you weigh (BAC). Your BAC is unaffected by the time of day. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":471,"qid":12608,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"Your blood alcohol content may be affected by how much alcohol you consume, how quickly you drink it, and how much you weigh (BAC). Your BAC is unaffected by the time of day. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":84,"qid":12609,"description":"Since a motorcycle has a smaller profile than a car,:","explanation":"A motorbike is less noticeable than a vehicle because of its lower profile. When operating a motorbike, use care.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":450,"qid":12609,"description":"Since a motorcycle has a smaller profile than a car,:","explanation":"A motorbike is less noticeable than a vehicle because of its lower profile. When operating a motorbike, use care.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":464,"qid":12609,"description":"Since a motorcycle has a smaller profile than a car,:","explanation":"A motorbike is less noticeable than a vehicle because of its lower profile. When operating a motorbike, use care.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":487,"qid":12610,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could become tangled up in something while you're riding.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":296,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":302,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":400,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":420,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":491,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":511,"qid":12619,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23102#23103#26561","text":"Increase your speed to maintain balance#Lean opposite to the motorcycle during turns#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane"},
{"testId":60,"qid":12632,"description":"Which lane position should you ride in to prevent a collision with a hazard in the center of the lane?","explanation":"To prevent a collision with a danger in the middle of your lane, ride in the left or right lane to avoid hitting it.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":487,"qid":12632,"description":"Which lane position should you ride in to prevent a collision with a hazard in the center of the lane?","explanation":"To prevent a collision with a danger in the middle of your lane, ride in the left or right lane to avoid hitting it.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":347,"qid":12635,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If a wider lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging cars.","imageName":"","correctAnswer":40127,"isBookmarked":0,"aid":"10718#22927#40127#40128","text":"Flash your brake light#Pull in the clutch#Move into a farther lane if possible#Move into the center lane position"},
{"testId":495,"qid":12635,"description":"On the highway, you notice additional automobiles merging into your lane from an entrance ramp. What are your options?","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If a wider lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging cars.","imageName":"","correctAnswer":40127,"isBookmarked":0,"aid":"10718#22927#40127#40128","text":"Flash your brake light#Pull in the clutch#Move into a farther lane if possible#Move into the center lane position"},
{"testId":475,"qid":12639,"description":"When should you rise slightly from your seat?","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":31095,"isBookmarked":0,"aid":"7392#7393#31095#31096","text":"When riding on slippery surfaces#When preparing to pass another vehicle#When riding over obstacles#To increase your visibility"},
{"testId":434,"qid":12645,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to apply both the front and back brakes.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":442,"qid":12645,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to apply both the front and back brakes.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":450,"qid":12645,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to apply both the front and back brakes.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":487,"qid":12645,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to apply both the front and back brakes.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":503,"qid":12645,"description":"The most efficient approach for halting or slowing down is to employ:","explanation":"The most efficient way to stop or slow down is to apply both the front and back brakes.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6504#7748#7749#24624","text":"the front brake only#engine braking only#the rear brake only#both the front and rear brakes"},
{"testId":479,"qid":12647,"description":"How do you alert the driver behind聽you that you are about to slow down?","explanation":"Flash your brake light to alert the motorist behind you that you are going to slow down. Tap the brakes to do this.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"10718#10917#10918#10919","text":"Flash your brake light#Turn around and make eye contact#Slam on the brakes#Wave your arms"},
{"testId":82,"qid":12657,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers will be unable to see you from certain regions. Ride in a lane that allows you to be spotted as quickly as possible.","imageName":"","correctAnswer":32408,"isBookmarked":0,"aid":"7352#7353#24647#32408","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicle operators will have difficulty seeing you in certain areas"},
{"testId":503,"qid":12657,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers will be unable to see you from certain regions. Ride in a lane that allows you to be spotted as quickly as possible.","imageName":"","correctAnswer":32408,"isBookmarked":0,"aid":"7352#7353#24647#32408","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicle operators will have difficulty seeing you in certain areas"},
{"testId":347,"qid":12662,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":420,"qid":12662,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":454,"qid":12662,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":464,"qid":12662,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":475,"qid":12662,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":294,"qid":12663,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"When approaching a blind junction with a stop line, you must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6651#6652#6654#30064","text":"past the stop line, where you can see clearly#at the stop line only#on the stop line#at the stop line first, then edge forward"},
{"testId":330,"qid":12673,"description":"When is it safe to share a lane with another vehicle?","explanation":"Sharing a lane with an automobile is never a good idea. A motorbike, like a vehicle, needs a complete lane width to move safely.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#35117#40800","text":"Never#In heavy traffic#When you're riding in a group#When you're riding in a designated area"},
{"testId":334,"qid":12673,"description":"When is it safe to share a lane with another vehicle?","explanation":"Sharing a lane with an automobile is never a good idea. A motorbike, like a vehicle, needs a complete lane width to move safely.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#35117#40800","text":"Never#In heavy traffic#When you're riding in a group#When you're riding in a designated area"},
{"testId":347,"qid":12673,"description":"When is it safe to share a lane with another vehicle?","explanation":"Sharing a lane with an automobile is never a good idea. A motorbike, like a vehicle, needs a complete lane width to move safely.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#35117#40800","text":"Never#In heavy traffic#When you're riding in a group#When you're riding in a designated area"},
{"testId":330,"qid":12675,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handgrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":347,"qid":12675,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handgrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":375,"qid":12675,"description":"In countersteering to begin a turn, pushing the right handgrip will enable the motorbike to :","explanation":"Countersteering is a method for starting a turn in one direction by steering in the opposite direction for a brief duration. Countersteering is the application of steering torque in the opposing direction of the intended turn. For a right turn, for example, begin by pressing the right handgrip. This will force the motorbike to momentarily steer left, causing it to tilt to the right.","imageName":"","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#8992#10156","text":"Turn left#Turn right#make a U-turn#Go straight"},
{"testId":330,"qid":12677,"description":"When should you slow down when you approach a turn?","explanation":"Before entering a turn, you should slow down. Hard braking in a turn increases the likelihood of a skid, especially in adverse weather.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":330,"qid":12678,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":40806,"isBookmarked":0,"aid":"40806#40807#40808#40809","text":"When you're riding on ice#When you're riding in a parking lot#When you're riding on a metal grating#When you're crossing railroad tracks"},
{"testId":479,"qid":12678,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":40806,"isBookmarked":0,"aid":"40806#40807#40808#40809","text":"When you're riding on ice#When you're riding in a parking lot#When you're riding on a metal grating#When you're crossing railroad tracks"},
{"testId":60,"qid":12683,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may fail to see you and open his or her door directly into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":330,"qid":12683,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may fail to see you and open his or her door directly into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":438,"qid":12683,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may fail to see you and open his or her door directly into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":458,"qid":12683,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may fail to see you and open his or her door directly into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":330,"qid":12686,"description":"The gearshift lever can be found :","explanation":"The shifting lever is situated just in front of the left footrest. The rear brake is controlled by the pedal in front of the right footrest.","imageName":"","correctAnswer":6980,"isBookmarked":0,"aid":"6979#6980#24908#24909","text":"in front of the right footrest#in front of the left footrest#by the left handgrip#by the right handgrip"},
{"testId":330,"qid":12688,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":408,"qid":12688,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":412,"qid":12688,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":330,"qid":12689,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in a blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10791#10792#10793#22793","text":"Ride at the same speed as vehicles you are beside#Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position"},
{"testId":330,"qid":12692,"description":"For optimal traction while driving in rain, you should drive:","explanation":"For the most traction while it's raining, ride in the tracks left by other automobiles. These are less likely to slide.","imageName":"","correctAnswer":28968,"isBookmarked":0,"aid":"7310#7312#28968#30021","text":"in the center lane position#on the shoulder of the road#in the tracks left by other vehicles#in the left lane position"},
{"testId":458,"qid":12692,"description":"For optimal traction while driving in rain, you should drive:","explanation":"For the most traction while it's raining, ride in the tracks left by other automobiles. These are less likely to slide.","imageName":"","correctAnswer":28968,"isBookmarked":0,"aid":"7310#7312#28968#30021","text":"in the center lane position#on the shoulder of the road#in the tracks left by other vehicles#in the left lane position"},
{"testId":330,"qid":12693,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"In any case when folks behind you may not see you slowing down, flash your brake light to alert them.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10753#10754#10755","text":"Beep your horn to warn the driver#Move into another lane#Accelerate to make it through the light#Tap your brake to flash your brake light"},
{"testId":330,"qid":12694,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Frequently, a car fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":330,"qid":12696,"description":"Why is pulling over to the shoulder more dangerous for motorbikes than for cars?","explanation":"Pulling over to the side of the road may be more dangerous for motorbikes than it is for vehicles since the shoulder is typically covered in sand or gravel, both of which give poor traction for motorcycles.","imageName":"","correctAnswer":10689,"isBookmarked":0,"aid":"10688#10689#10690#10691","text":"It is illegal for motorcycles to park on the side of the road#The shoulder is often sandy and may provide less traction#It is more difficult for motorcycles to stop quickly#Motorcycles require a curb to park against"},
{"testId":330,"qid":12697,"description":"Which of the following you should NOT do when driving at night?","explanation":"To keep safe at night, you should reduce your pace rather than raise it.","imageName":"","correctAnswer":6515,"isBookmarked":0,"aid":"6515#6920#22923#32699","text":"Increase your speed#Increase your following distance#Use your high beam#Adjust your lane position when necessary"},
{"testId":330,"qid":12698,"description":"To alert drivers that you are slowing down in an unexpected spot, you should:","explanation":"Tap the brake pedal to flash your brake light to the car behind you to alert them that you are slowing down in an odd location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7482#7483#22897","text":"Flash your brake light#swerve from side to side#shift gears instead of braking#rise slightly off your seat"},
{"testId":330,"qid":12699,"description":"When should you use the front brake only?","explanation":"To stop, you should always engage both the front and rear brakes. If the back tire is flat, use just the front brake. Never use your brakes on a flat tire.","imageName":"","correctAnswer":29439,"isBookmarked":0,"aid":"6492#7496#7498#29439","text":"At all times#When you want to stop quickly#On surfaces with low traction#If the rear tire is flat"},
{"testId":70,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":330,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":334,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":375,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":408,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":458,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":495,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":499,"qid":12701,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is about one-third the width of a traffic lane. As a result, each traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":31147,"isBookmarked":0,"aid":"7532#31145#31146#31147","text":"a special lane designated for motorcycles only#approximately half of a traffic lane#a full traffic lane#approximately one-third of a traffic lane"},
{"testId":73,"qid":12703,"description":"Which of the following motorcycle components should you inspect before each ride?","explanation":"Before every ride, you should check your motorcycle's tires, lights, and chassis.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#26544#26545","text":"All of the above#Tires#Lights#Chassis"},
{"testId":330,"qid":12703,"description":"Which of the following motorcycle components should you inspect before each ride?","explanation":"Before every ride, you should check your motorcycle's tires, lights, and chassis.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#26544#26545","text":"All of the above#Tires#Lights#Chassis"},
{"testId":343,"qid":12703,"description":"Which of the following motorcycle components should you inspect before each ride?","explanation":"Before every ride, you should check your motorcycle's tires, lights, and chassis.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#26544#26545","text":"All of the above#Tires#Lights#Chassis"},
{"testId":379,"qid":12703,"description":"Which of the following motorcycle components should you inspect before each ride?","explanation":"Before every ride, you should check your motorcycle's tires, lights, and chassis.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#26544#26545","text":"All of the above#Tires#Lights#Chassis"},
{"testId":330,"qid":12705,"description":"You should _____ to use caution when crossing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#6758#7815#35103","text":"Use the front brake only#maintain or increase your speed#pull in the clutch and coast#stop and walk the motorcycle across"},
{"testId":347,"qid":12705,"description":"You should _____ to use caution when crossing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#6758#7815#35103","text":"Use the front brake only#maintain or increase your speed#pull in the clutch and coast#stop and walk the motorcycle across"},
{"testId":499,"qid":12705,"description":"You should _____ to use caution when crossing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#6758#7815#35103","text":"Use the front brake only#maintain or increase your speed#pull in the clutch and coast#stop and walk the motorcycle across"},
{"testId":330,"qid":12707,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group. Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":479,"qid":12707,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group. Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":491,"qid":12707,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group. Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":330,"qid":12709,"description":"When is it permissible in Connecticut to share a lane with another vehicle?","explanation":null,"imageName":"","correctAnswer":35515,"isBookmarked":0,"aid":"6334#6769#8087#35515","text":"Never#In heavy traffic#In designated areas#If the other vehicle is a motorcycle"},
{"testId":330,"qid":12710,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"When traveling with a passenger, go slower around bends. Your motorbike will respond slower as a result of the extra weight.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8244#8245#28381","text":"faster#at normal speed#slower#at the speed limit"},
{"testId":442,"qid":12710,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"When traveling with a passenger, go slower around bends. Your motorbike will respond slower as a result of the extra weight.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8244#8245#28381","text":"faster#at normal speed#slower#at the speed limit"},
{"testId":487,"qid":12710,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"When traveling with a passenger, go slower around bends. Your motorbike will respond slower as a result of the extra weight.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8244#8245#28381","text":"faster#at normal speed#slower#at the speed limit"},
{"testId":330,"qid":12711,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable the motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":446,"qid":12711,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable the motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":330,"qid":12713,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":292,"qid":12714,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so that you can see behind you as well as as much of the lane alongside you as feasible. A motorbike still has blind zones, so glance over your shoulder whenever possible.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10372#23098#23099#32930","text":"behind you and part of the lane beside you#only beside your vehicle#only behind your vehicle#everything in front of you"},
{"testId":330,"qid":12714,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so that you can see behind you as well as as much of the lane alongside you as feasible. A motorbike still has blind zones, so glance over your shoulder whenever possible.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10372#23098#23099#32930","text":"behind you and part of the lane beside you#only beside your vehicle#only behind your vehicle#everything in front of you"},
{"testId":330,"qid":12715,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright clothes, adopting a proper lane location, and utilizing your headlight may help you be spotted.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#26559","text":"Use your headlight#Wear bright clothing#Do all of the above#Choose a suitable lane position"},
{"testId":330,"qid":12719,"description":"Intersections are particularly hazardous to motorcycle riders because:","explanation":"Motorcyclists are particularly vulnerable at intersections because other drivers often fail to detect them coming.","imageName":"","correctAnswer":35326,"isBookmarked":0,"aid":"8113#8114#22811#35326","text":"motorcycles are not permitted to cross intersections#they do not have to obey traffic lights#motorcycles are slower than other vehicles#other vehicle operators often fail to notice them approaching"},
{"testId":330,"qid":12720,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"A 90-degree angle is the safest approach to pass a barrier. This will prevent your front wheel from being deflected by the obstruction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10676#10677","text":"45-degree#20-degree#100-degree#90-degree"},
{"testId":507,"qid":12730,"description":"In which of the following scenarios should you increase your speed?","explanation":"Roll on the throttle to maintain or slightly increase your speed through a bend or curve to steady your motorbike.","imageName":"","correctAnswer":39844,"isBookmarked":0,"aid":"39842#39844#39845#40810","text":"When you're preparing to stop#When you're in a turn#When you're descending a hill#When you're passing a line of parked cars"},
{"testId":74,"qid":12741,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent using too much throttle, start your motorbike with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":404,"qid":12741,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent using too much throttle, start your motorbike with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":503,"qid":12741,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent using too much throttle, start your motorbike with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":334,"qid":12742,"description":"When you look over your shoulder before changing lanes, you are checking:","explanation":"Before changing lanes, you should do a head check of the lane next to you and the far lane to see whether vehicles in the far lane are planning to move into the same lane that you are.","imageName":"","correctAnswer":7920,"isBookmarked":0,"aid":"7917#7920#31154#33580","text":"the traction of the road surface#the lane beside you and the far lane#areas of vision that are blocked by your helmet#the sizes of the vehicles that are close to you"},
{"testId":343,"qid":12742,"description":"When you look over your shoulder before changing lanes, you are checking:","explanation":"Before changing lanes, you should do a head check of the lane next to you and the far lane to see whether vehicles in the far lane are planning to move into the same lane that you are.","imageName":"","correctAnswer":7920,"isBookmarked":0,"aid":"7917#7920#31154#33580","text":"the traction of the road surface#the lane beside you and the far lane#areas of vision that are blocked by your helmet#the sizes of the vehicles that are close to you"},
{"testId":301,"qid":12745,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your riding ability and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":83,"qid":12750,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by car drivers.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"936#30814#30815#30816","text":"A one-way road#A curve#A hill#An intersection"},
{"testId":297,"qid":12750,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by car drivers.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"936#30814#30815#30816","text":"A one-way road#A curve#A hill#An intersection"},
{"testId":495,"qid":12750,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by car drivers.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"936#30814#30815#30816","text":"A one-way road#A curve#A hill#An intersection"},
{"testId":347,"qid":12755,"description":"Who determines the group's pace when riding in a group?","explanation":"The sweep rider (tailender) sets the pace while riding in a group. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":35300,"isBookmarked":0,"aid":"6578#6580#6581#35300","text":"The group leader#The traffic in front of the leader#The middle group rider#The sweep rider (tailender)"},
{"testId":446,"qid":12783,"description":"When riding at night, you can improve your safety by:","explanation":"Wearing luminous clothes, utilizing your high beam, slowing down, and increasing your following distance will help you ride safely at night.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":471,"qid":12784,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6810#6812#6813#28967","text":"wear a motorcycle lap belt#lean as you lean#lean back to balance the motorcycle#also have a motorcycle license"},
{"testId":475,"qid":12784,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6810#6812#6813#28967","text":"wear a motorcycle lap belt#lean as you lean#lean back to balance the motorcycle#also have a motorcycle license"},
{"testId":85,"qid":12793,"description":"Motorcyclists may find it tough to maintain safe control on surfaces with weak traction such as:","explanation":"Motorcyclists may find it difficult to retain safe control on slick surfaces such as the following: 1) wet pavement, especially just after it begins to rain and before surface oil washes to the side of the road; 2) gravel roads or areas where sand and gravel gather; 3) mud, leaves, snow, and ice; and 4) wet lane markers, steel plates, and manhole covers.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#22974#22976#26383","text":"All of the above#wet pavement and gravel roads#lane markings, steel plates, and manhole covers#mud, leaves, snow, and ice"},
{"testId":467,"qid":12793,"description":"Motorcyclists may find it tough to maintain safe control on surfaces with weak traction such as:","explanation":"Motorcyclists may find it difficult to retain safe control on slick surfaces such as the following: 1) wet pavement, especially just after it begins to rain and before surface oil washes to the side of the road; 2) gravel roads or areas where sand and gravel gather; 3) mud, leaves, snow, and ice; and 4) wet lane markers, steel plates, and manhole covers.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#22974#22976#26383","text":"All of the above#wet pavement and gravel roads#lane markings, steel plates, and manhole covers#mud, leaves, snow, and ice"},
{"testId":434,"qid":12803,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":450,"qid":12803,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":297,"qid":12809,"description":"If you're riding in a group and need to pass the vehicle ahead of you, you should:","explanation":"When cycling in a group, bikers should pass one at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":298,"qid":12818,"description":"What's the left-turn hand signal?","explanation":"A straight left arm implies a left turn.","imageName":"","correctAnswer":10824,"isBookmarked":0,"aid":"10824#10826#37550#37551","text":"Left arm straight out#Right arm straight out#Left arm bent at 90 degrees, pointing upward#Left arm bent at 90 degrees, pointing downward"},
{"testId":83,"qid":12822,"description":"Which of the following can make it easier for other drivers to see you?","explanation":"Use your headlight, wear bright, reflective clothes, and adopt an appropriate lane location to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6170#6171#23000#31451","text":"Wear proper clothing#Use your headlight#Do all of the above#Ride in a suitable lane position"},
{"testId":499,"qid":12845,"description":"The front brake system:","explanation":"Three-quarters of the stopping force is provided by the front brake. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":11131,"isBookmarked":0,"aid":"7460#7461#11131#11132","text":"should only be used in emergency situations#is controlled by a foot pedal#provides three-quarters of the stopping power#provides one-quarter of the stopping power"},
{"testId":79,"qid":12857,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"AVOID using ropes to secure items to a motorbike. Ropes may weaken and knots can come undone over time. Elastic cords are a superior alternative.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":295,"qid":12857,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"AVOID using ropes to secure items to a motorbike. Ropes may weaken and knots can come undone over time. Elastic cords are a superior alternative.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":507,"qid":12857,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"AVOID using ropes to secure items to a motorbike. Ropes may weaken and knots can come undone over time. Elastic cords are a superior alternative.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":450,"qid":12861,"description":"While driving, head checks:","explanation":"Before changing lanes, do a head check.","imageName":"","correctAnswer":24798,"isBookmarked":0,"aid":"6339#24798#35085#35086","text":"only need to be done at night#should be performed before you change lanes#are not needed; motorcycles don't have blind spots#are only needed when you're preparing to turn left"},
{"testId":292,"qid":12866,"description":"Which position in the lane should NEVER be taken?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"6214#6216#6217#17889","text":"Left#Center#Right#None"},
{"testId":458,"qid":12866,"description":"Which position in the lane should NEVER be taken?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"6214#6216#6217#17889","text":"Left#Center#Right#None"},
{"testId":299,"qid":12867,"description":"The importance of the rear brake is _____ because _____.","explanation":"Because it only has 25% of the stopping force of the front brake, the rear brake is less critical. Even yet, unless a tire is flat, you should always apply both the front and rear brakes to stop. In such situation, solely apply the brake on the opposite tire.","imageName":"","correctAnswer":40114,"isBookmarked":0,"aid":"40114#40115#40116#40117","text":"less; it has 25% of the stopping power#less; it has 75% of the stopping power#more; it has 75% of the stopping power#more; it has 25% of the stopping power"},
{"testId":334,"qid":12878,"description":"Motorcycles usually need:","explanation":"Motorcycles normally need the same stopping distance as vehicles in traffic. Make sure to provide enough room cushion.","imageName":"","correctAnswer":39860,"isBookmarked":0,"aid":"26462#39859#39860#39861","text":"more room to operate safely than cars do#a smaller stopping distance than cars do#the same stopping distance as cars do#a larger stopping distance than cars do"},
{"testId":334,"qid":12879,"description":"Approaching barriers from a ______ angle is the safest option.","explanation":"It is best to face challenges front on (i.e., at a 90-degree angle). This will prevent your front wheel from being deflected by the obstruction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#10684","text":"45-degree#90-degree#180-degree#25-degree"},
{"testId":347,"qid":12879,"description":"Approaching barriers from a ______ angle is the safest option.","explanation":"It is best to face challenges front on (i.e., at a 90-degree angle). This will prevent your front wheel from being deflected by the obstruction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#10684","text":"45-degree#90-degree#180-degree#25-degree"},
{"testId":302,"qid":12881,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":334,"qid":12881,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":343,"qid":12881,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":487,"qid":12881,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":491,"qid":12881,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":297,"qid":12884,"description":"When should a motorcycle group ride in a staggered formation?","explanation":"On lengthy sections of road, the staggered pattern should be employed. Motorcyclists should ride single file through bends, turns, and anyplace else where they may need to alter lane position.","imageName":"","correctAnswer":7293,"isBookmarked":0,"aid":"7157#7158#7293#29409","text":"When passing as a group#Through curves and turns#On long stretches of road#When merging onto the highway"},
{"testId":334,"qid":12884,"description":"When should a motorcycle group ride in a staggered formation?","explanation":"On lengthy sections of road, the staggered pattern should be employed. Motorcyclists should ride single file through bends, turns, and anyplace else where they may need to alter lane position.","imageName":"","correctAnswer":7293,"isBookmarked":0,"aid":"7157#7158#7293#29409","text":"When passing as a group#Through curves and turns#On long stretches of road#When merging onto the highway"},
{"testId":334,"qid":12885,"description":"Which wheel should touch the curb when you park on the side of the road?","explanation":"To park on the side of the road, park at an angle to the curb, with the back wheel contacting the curb.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":458,"qid":12885,"description":"Which wheel should touch the curb when you park on the side of the road?","explanation":"To park on the side of the road, park at an angle to the curb, with the back wheel contacting the curb.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":471,"qid":12885,"description":"Which wheel should touch the curb when you park on the side of the road?","explanation":"To park on the side of the road, park at an angle to the curb, with the back wheel contacting the curb.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":334,"qid":12887,"description":"What should you look for when merging into the middle lane of a three-lane highway?","explanation":"When prepared to merge into the center lane of a three-lane highway, examine both the middle and far lanes. Someone in the far lane may be preparing to move into the center lane as well.","imageName":"","correctAnswer":24616,"isBookmarked":0,"aid":"7225#24616#24617#38898","text":"Your throttle, to make sure it is not stuck#The lane beside you and the far lane#Your signal light, to make sure it is working#Your speed, to make sure you are not exceeding the speed limit"},
{"testId":499,"qid":12887,"description":"What should you look for when merging into the middle lane of a three-lane highway?","explanation":"When prepared to merge into the center lane of a three-lane highway, examine both the middle and far lanes. Someone in the far lane may be preparing to move into the center lane as well.","imageName":"","correctAnswer":24616,"isBookmarked":0,"aid":"7225#24616#24617#38898","text":"Your throttle, to make sure it is not stuck#The lane beside you and the far lane#Your signal light, to make sure it is working#Your speed, to make sure you are not exceeding the speed limit"},
{"testId":334,"qid":12890,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Motorcycles have poor grip on wet pavement, gravel roads, and lane lines.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#7396","text":"All of the above#Gravel roads#Wet pavement#Lane markings"},
{"testId":334,"qid":12906,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Try to keep the loads front while loading your motorbike. Heavy objects should be placed at the front of the tank bag. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":296,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":334,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":347,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":438,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":475,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":499,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":511,"qid":12913,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of it. This might make the motorbike even more unsteady. Instead, gently reduce your speed and pull off the road when it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":334,"qid":12916,"description":"The chain on your motorcycle should be inspected,:","explanation":null,"imageName":"","correctAnswer":8236,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":77,"qid":12919,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":334,"qid":12919,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":400,"qid":12919,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":454,"qid":12919,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":499,"qid":12919,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8039#33602#33603#33604","text":"is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit#prevents drunkenness if you're drinking beer"},
{"testId":491,"qid":12935,"description":"When should you flash your brake light?","explanation":"When you slow down unexpectedly, notify the person behind you by flashing your brake light.","imageName":"","correctAnswer":24931,"isBookmarked":0,"aid":"6945#6946#24686#24931","text":"You slow down in rural areas#The speed limit changes#You are going to turn left#You slow down where others might not expect it"},
{"testId":442,"qid":12940,"description":"To reduce the risk of slipping on a slick surface, you should:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":38714,"isBookmarked":0,"aid":"7332#7334#10656#38714","text":"drag your feet on the ground#ride on the shoulder of the road#Do all of the above#slow down before you get to it"},
{"testId":503,"qid":12940,"description":"To reduce the risk of slipping on a slick surface, you should:","explanation":"Slow down as much as possible before approaching a slick area to reduce your chances of skidding.","imageName":"","correctAnswer":38714,"isBookmarked":0,"aid":"7332#7334#10656#38714","text":"drag your feet on the ground#ride on the shoulder of the road#Do all of the above#slow down before you get to it"},
{"testId":296,"qid":12947,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is located on the left hand lever. The rear brake is controlled by the right foot pedal, while the gear shifter is controlled by the left foot lever.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":74,"qid":12948,"description":"It takes _____ to ride a motorcycle than to drive a car.","explanation":"It takes more effort to ride a motorbike than it does to drive a vehicle. To prevent weariness, take regular rests.","imageName":"","correctAnswer":7885,"isBookmarked":0,"aid":"7885#7886#7888#31495","text":"more work#less work at night#less work during the day#no more work"},
{"testId":450,"qid":12948,"description":"It takes _____ to ride a motorcycle than to drive a car.","explanation":"It takes more effort to ride a motorbike than it does to drive a vehicle. To prevent weariness, take regular rests.","imageName":"","correctAnswer":7885,"isBookmarked":0,"aid":"7885#7886#7888#31495","text":"more work#less work at night#less work during the day#no more work"},
{"testId":462,"qid":12948,"description":"It takes _____ to ride a motorcycle than to drive a car.","explanation":"It takes more effort to ride a motorbike than it does to drive a vehicle. To prevent weariness, take regular rests.","imageName":"","correctAnswer":7885,"isBookmarked":0,"aid":"7885#7886#7888#31495","text":"more work#less work at night#less work during the day#no more work"},
{"testId":487,"qid":12948,"description":"It takes _____ to ride a motorcycle than to drive a car.","explanation":"It takes more effort to ride a motorbike than it does to drive a vehicle. To prevent weariness, take regular rests.","imageName":"","correctAnswer":7885,"isBookmarked":0,"aid":"7885#7886#7888#31495","text":"more work#less work at night#less work during the day#no more work"},
{"testId":499,"qid":12948,"description":"It takes _____ to ride a motorcycle than to drive a car.","explanation":"It takes more effort to ride a motorbike than it does to drive a vehicle. To prevent weariness, take regular rests.","imageName":"","correctAnswer":7885,"isBookmarked":0,"aid":"7885#7886#7888#31495","text":"more work#less work at night#less work during the day#no more work"},
{"testId":343,"qid":12964,"description":"What is the most common reason for motorcycle accidents at intersections?","explanation":"Cars entering a motorbike's right-of-way is the most common cause of motorcycle collisions involving several vehicles. A vehicle driver may fail to see an approaching motorbike and turn left into its path.","imageName":"","correctAnswer":33965,"isBookmarked":0,"aid":"10395#10396#33965#40811","text":"Motorcycles exceeding the speed limit#Motorcycles not yielding to traffic in the intersection#Cars entering the motorcycle's right-of-way#Cars following too closely"},
{"testId":503,"qid":12964,"description":"What is the most common reason for motorcycle accidents at intersections?","explanation":"Cars entering a motorbike's right-of-way is the most common cause of motorcycle collisions involving several vehicles. A vehicle driver may fail to see an approaching motorbike and turn left into its path.","imageName":"","correctAnswer":33965,"isBookmarked":0,"aid":"10395#10396#33965#40811","text":"Motorcycles exceeding the speed limit#Motorcycles not yielding to traffic in the intersection#Cars entering the motorcycle's right-of-way#Cars following too closely"},
{"testId":301,"qid":12970,"description":"What can you do to reduce your reaction time when approaching an intersection?","explanation":"When approaching an intersection, shorten your response time by covering the clutch and brakes so you can stop fast.","imageName":"","correctAnswer":35301,"isBookmarked":0,"aid":"24741#24742#24743#35301","text":"Maintain or increase your speed#Look both ways#Point your toes downward#Cover the clutch and the brakes"},
{"testId":336,"qid":12971,"description":"You must keep a following distance of at least ________ behind the vehicle in front of you.","explanation":"Maintain a safe following distance of at least two seconds behind the car in front of you.","imageName":"","correctAnswer":1287,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":336,"qid":12972,"description":"When riding on the road, you should leave more space and take precautions to avoid being hit by :","explanation":"You should announce your presence with your lights or horn, modify your speed by accelerating, halting, or decreasing, and adjust your position or direction to provide more space and reduce danger from hazards.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"10777#22862#22863#26411","text":"Doing all of the above#Adjusting your speed#Adjusting your direction or position#Using your lights or horn"},
{"testId":336,"qid":12973,"description":"If you want to cross parallel railroad tracks safely, you should do so at an angle of at least _____ degrees.","explanation":"To cross railroad lines safely, make sure you have enough traction and maintain your motorbike inside your lane if feasible. Changing direction to cross the tracks head-on may cause you to enter another lane of traffic. Cross railroad lines parallel to your path at a 45-degree angle or more.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#6356","text":"20#45#90#120"},
{"testId":336,"qid":12974,"description":"If you cannot avoid an obstacle and must drive over it, approach it at a _____ angle.","explanation":"If you cannot avoid an obstruction and must drive over it, approach it at a 90-degree angle. This reduces the likelihood of losing traction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#10866","text":"45-degree#90-degree#25-degree#135-degree"},
{"testId":336,"qid":12976,"description":"The most secure way to use saddlebags is to:","explanation":"The most secure approach to utilize saddlebags is to equally balance the weight between them.","imageName":"","correctAnswer":6671,"isBookmarked":0,"aid":"6671#6674#24926#26412","text":"distribute the weight evenly between them#not carry a passenger at the same time#use only one at a time#make sure that both of them are full"},
{"testId":336,"qid":12977,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is perilous because someone may not notice you and open his or her door into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":336,"qid":12978,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6940#6941#10572#26413","text":"Avoid driving in heavy traffic#wear reflective clothing#Ride only during the day#ride in areas where there are lots of other motorcycles"},
{"testId":336,"qid":12981,"description":"Which lane should you ride in to improve your visibility?","explanation":"You should ride in the left lane to improve your line of sight. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":336,"qid":12982,"description":"Motorcycle riding groups should not exceed____ riders.","explanation":"Motorcycle groups should be no more than four or five riders. Divide them into smaller groups if they are bigger.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#10878","text":"Two#Six#Five#Seven"},
{"testId":339,"qid":12982,"description":"Motorcycle riding groups should not exceed____ riders.","explanation":"Motorcycle groups should be no more than four or five riders. Divide them into smaller groups if they are bigger.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#10878","text":"Two#Six#Five#Seven"},
{"testId":336,"qid":12983,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots might come undone.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":336,"qid":12984,"description":"Which of the following is the most dangerous to a motorcycle rider?","explanation":"An intersection can be hazardous to a motorcyclist because many drivers are doing different things and may not see the motorcycle.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#6805#26414#26415","text":"Intersections#Rural roads#Freeways#School zones"},
{"testId":336,"qid":12986,"description":"How may your clothing help you avoid collisions?","explanation":"By making you more apparent to others, colorful clothes may help avoid collisions.","imageName":"","correctAnswer":10784,"isBookmarked":0,"aid":"10784#23063#24634#26417","text":"Wear bright clothing#Wear extra clothing to get the attention of drivers#Wear protective clothing#Your choice of clothing does not help prevent collisions"},
{"testId":336,"qid":12988,"description":"You should _______, if one of your tires fails.","explanation":"If a tire fails, avoid applying the flat tire's brake. Only use the good tire to brake.","imageName":"","correctAnswer":8142,"isBookmarked":0,"aid":"8142#8143#10601#26418","text":"avoid using the brake of the flat tire#slow down with both brakes#Pull the engine kill switch#use only the brake of the flat tire"},
{"testId":336,"qid":12989,"description":"It may be necessary to adjust your lane position if you are:","explanation":"If you're approaching a blind crossing, adjust your lane position as needed to see as much as feasible and to be seen by others.","imageName":"","correctAnswer":8190,"isBookmarked":0,"aid":"8189#8190#8191#8192","text":"being followed#approaching a blind intersection#riding on a multilane road#riding in a residential area"},
{"testId":336,"qid":12990,"description":"If you're riding in a group and need to pass the vehicle ahead of you, you should:","explanation":"If you're travelling as a group and need to pass the car in front of you, pass one at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":336,"qid":12991,"description":"The pavement is most likely to be slick:","explanation":"The pavement is expected to get slick shortly after it begins to rain. This is due to accumulated oil and other fluids washing off the pavement.","imageName":"","correctAnswer":8430,"isBookmarked":0,"aid":"8060#8429#8430#8431","text":"in rural areas#on a windy day#soon after it has started to rain#on multilane roads"},
{"testId":336,"qid":12992,"description":"What should you do if your throttle is stuck and turning it back and forth doesn't work?","explanation":"If your throttle is stuck and twisting it does not release it, turn off the engine.","imageName":"","correctAnswer":10601,"isBookmarked":0,"aid":"6417#7040#7978#10601","text":"Use the front brake only#Apply both brakes#Use engine braking#Pull the engine kill switch"},
{"testId":336,"qid":12993,"description":"Eye protection with a tint:","explanation":"Wearing tinted eye protection at night can further dim your eyesight and make objects more difficult to see.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#26419","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders, not passengers"},
{"testId":336,"qid":12994,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of the motorcycle's stopping force. The rear brake contributes 30%. To stop or slow down, always apply both brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#22934","text":"The front brake#The rear brake#The emergency brake#Both brakes equally"},
{"testId":336,"qid":12995,"description":"Where should you stop when approaching a blind stop line?","explanation":"Stop at the stop line initially if you are approaching a blind junction with a stop line. Then move forward and come to a halt in a more visible position.","imageName":"","correctAnswer":26420,"isBookmarked":0,"aid":"7374#10668#10670#26420","text":"At the stop line only#Past the stop line, where you can see clearly#On the stop line#At the stop line first, then farther forward"},
{"testId":339,"qid":13009,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle sticks, try twisting it back and forth first. If it does not work, turn off the engine.","imageName":"","correctAnswer":6807,"isBookmarked":0,"aid":"6806#6807#6809#10903","text":"Apply both brakes smoothly#Twist it back and forth#Pull off the road#Pull the engine cut-off switch"},
{"testId":62,"qid":13011,"description":"To aid in balancing your turns, you should:","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns.","imageName":"","correctAnswer":6901,"isBookmarked":0,"aid":"6901#6902#10686#24685","text":"Keep your knees against the gas tank#turn your torso in the direction of the turn#lean in the opposite direction of the motorcycle#take your feet off the footrests for balance"},
{"testId":412,"qid":13011,"description":"To aid in balancing your turns, you should:","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns.","imageName":"","correctAnswer":6901,"isBookmarked":0,"aid":"6901#6902#10686#24685","text":"Keep your knees against the gas tank#turn your torso in the direction of the turn#lean in the opposite direction of the motorcycle#take your feet off the footrests for balance"},
{"testId":66,"qid":13016,"description":"To transport a passenger, your motorbike must have:","explanation":"To transport a passenger, your motorbike must have a set of footrests for the individual. In addition, the seat must be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#7206#22946","text":"A windshield#footrests for the passenger#side saddlebags#high-quality shocks"},
{"testId":383,"qid":13016,"description":"To transport a passenger, your motorbike must have:","explanation":"To transport a passenger, your motorbike must have a set of footrests for the individual. In addition, the seat must be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#7206#22946","text":"A windshield#footrests for the passenger#side saddlebags#high-quality shocks"},
{"testId":64,"qid":13036,"description":"Increase your exposure at a crossroads by:","explanation":"At a junction, increase your visibility by selecting the most visible lane position so that cars may see you.","imageName":"","correctAnswer":7081,"isBookmarked":0,"aid":"7078#7079#7080#7081","text":"coming to a complete stop#riding in the staggered formation#looking in your mirrors#choosing the most visible lane position"},
{"testId":339,"qid":13044,"description":"Eye protection with a tint:","explanation":"Wearing tinted eye protection at night can further dim your eyesight and make objects more difficult to see.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#8013","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for drivers only"},
{"testId":400,"qid":13078,"description":"Eye contact with other drivers:","explanation":"Drivers will not yield to you if you make eye contact. Even when they are gazing straight towards motorcycles, they often fail to detect them.","imageName":"","correctAnswer":23007,"isBookmarked":0,"aid":"7987#23005#23006#23007","text":"should be avoided to discourage aggression#ensures that they will stop and let you through#should be made before crossing any intersection#is not a guarantee that they will yield to you"},
{"testId":339,"qid":13096,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"If an item unexpectedly arrives in your path, you should swerve around it to prevent a collision.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":339,"qid":13097,"description":"Why is it vital to ride in the left lane while driving alongside a line of parked cars?","explanation":"When driving alongside a line of parked automobiles, remain in the left lane to prevent complications caused by car doors opening in your way.","imageName":"","correctAnswer":26489,"isBookmarked":0,"aid":"22791#26489#26490#26491","text":"The center lane position is too slippery#You may avoid problems from car doors opening#Motorcycles should always travel in the left lane position#In the right lane position, you are more likely to hit a car"},
{"testId":339,"qid":13098,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#6307","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternately apply the front and rear brakes"},
{"testId":339,"qid":13099,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"When cycling on an uneven terrain, rise slightly off your seat to absorb additional shocks.","imageName":"","correctAnswer":26492,"isBookmarked":0,"aid":"6236#6310#24831#26492","text":"Swerving from side to side#taking your feet off the footrests#applying the brakes lightly#rising slightly off your seat"},
{"testId":339,"qid":13101,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Fastening baggage to the sissybar elevates the motorcycle's center of gravity, making it unstable. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22963,"isBookmarked":0,"aid":"22290#22291#22292#22963","text":"Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords#Fastening excess cargo to the sissybar"},
{"testId":339,"qid":13102,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"By flashing your brake light, wearing bright clothes, and utilizing your signals, you may boost your visibility to other vehicles.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10783#10784#10785#23000","text":"Flash your brake light to warn drivers#Wear bright clothing#Use signal lights#Do all of the above"},
{"testId":339,"qid":13103,"description":"You may improve your nighttime safety by:","explanation":"You may improve your nighttime safety by extending your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":339,"qid":13104,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Braking or accelerating out of a wobble might aggravate it.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":339,"qid":13106,"description":"How many lane slots are available inside a traffic lane?","explanation":"Each lane has three lane locations accessible to you: left, center (middle), and right.","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":339,"qid":13107,"description":"Because drivers merging onto the highway may not notice you, what should you do?","explanation":"If you can, go into another lane so that drivers entering the highway can see you.","imageName":"","correctAnswer":26495,"isBookmarked":0,"aid":"6286#6582#6928#26495","text":"Flash your brake light#Pull in the clutch#Move into the center lane position#Move into another lane if possible"},
{"testId":339,"qid":13108,"description":"You should begin riding the motorcycle with _____.","explanation":"To avoid mistakenly using too much throttle, start the motorbike with your right wrist flat.","imageName":"","correctAnswer":6976,"isBookmarked":0,"aid":"6973#6974#6975#6976","text":"your right wrist low#your elbows locked#your hands above your elbows#your right wrist flat"},
{"testId":339,"qid":13109,"description":"If you are approaching a stop, you must flash your brake light if you are:","explanation":"When approaching a halt, flash your brake light if you are being followed too closely, stopping in an unusual location, or stopping faster than others may anticipate.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7025#7026#22688#26496","text":"being followed too closely#stopping in an unusual place#In any of the above situations#stopping more quickly than others may expect"},
{"testId":339,"qid":13110,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to ride more safely.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":339,"qid":13112,"description":"Which of the following statement about carrying cargo on your motorcycle is correct?","explanation":"Fill your saddlebags evenly. If one side is heavier than the other, the bike may drift.","imageName":"","correctAnswer":26497,"isBookmarked":0,"aid":"10759#10760#10761#26497","text":"Use ropes to secure the load#Attach the cargo to your gas tank#Never carry cargo on a motorcycle#Fill saddlebags evenly to keep your bike from drifting"},
{"testId":339,"qid":13114,"description":"Check the brake hydraulic fluid and coolant levels _______","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":339,"qid":13115,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and brake to reduce response time.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"22927#24744#26499#26500","text":"Pull in the clutch#Cover the clutch and the brake#Shift into a higher gear#Skim your feet along the ground"},
{"testId":339,"qid":13116,"description":"You are approaching a stop-lined blind intersection. You should stop _____.","explanation":"If there is a stop line or stop sign at the blind junction, you must stop there first. You may go slowly forward if it is tough for you to see from that point until your eyesight becomes better.","imageName":"","correctAnswer":24767,"isBookmarked":0,"aid":"6652#24766#24767#24768","text":"at the stop line only#where you have the most visibility#at the stop line first, and then pull forward#with the front wheel over the stop line"},
{"testId":339,"qid":13117,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22797#22798#22799#24618","text":"Try to jump off the bike before hitting the ground#Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear"},
{"testId":339,"qid":13119,"description":"Passengers need to:","explanation":"Passengers should always maintain their feet on the footrests, even while the motorbike is stopped.","imageName":"","correctAnswer":26502,"isBookmarked":0,"aid":"7802#7804#26501#26502","text":"sit as far back as possible#avoid holding on to the driver#put their feet down at stops#keep their feet on the footrests at all times"},
{"testId":339,"qid":13120,"description":"A motorcycle cannot be driven on a highway:","explanation":"You are not permitted to ride a motorbike on the highway unless you can go at the specified minimum speed safely.","imageName":"","correctAnswer":26504,"isBookmarked":0,"aid":"7661#10997#26503#26504","text":"at night, because your lights aren't bright enough#without a helmet and proper protective gear#during the rush hour#unless you can travel safely at the posted minimum speed"},
{"testId":339,"qid":13121,"description":"Which lane positions are the most secure for motorcycles?","explanation":"The safest lane location is determined by the circumstances. There is no need to avoid any lane position at any costs.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#26506#26507#26508","text":"It depends on the situation#The left and the right are safest#The center is safest#The left is safest"},
{"testId":339,"qid":13122,"description":"When riding in a group, participants should communicate by:","explanation":"When biking in a group, participants should communicate with one another by utilizing hand signals. Before you go on your tour, be sure that everyone in your party is acquainted with the necessary hand signals.","imageName":"","correctAnswer":7839,"isBookmarked":0,"aid":"6738#7838#7839#7840","text":"riding in pairs#changing lane positions#using hand signals#making eye contact"},
{"testId":339,"qid":13123,"description":"Motorcycles might be at risk on the side of the road because:","explanation":"Motorcycles are at risk by the side of the road because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7845#7847#26509#26510","text":"dirt and gravel provide little traction#it is not driven on often#visibility is poor there#the quality of the pavement is poor"},
{"testId":339,"qid":13124,"description":"What is one cause of turning skidding?","explanation":"The motorbike may slide if you stop too forcefully in a corner. Slow down before the turn to help prevent this.","imageName":"","correctAnswer":26511,"isBookmarked":0,"aid":"7967#7969#7970#26511","text":"Pulling in the clutch too quickly#Using the wrong gear#Using the wrong brake#Braking too hard"},
{"testId":339,"qid":13125,"description":"_____ might cause your motorbike to shake.","explanation":"Improper cargo loading might cause your motorbike to wobble. To keep the motorbike balanced, make sure the baggage is appropriately distributed.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":339,"qid":13126,"description":"When you are being passed by another vehicle, which lane should you ride in?","explanation":"Ride in the middle lane if you are being passed by another car. This protects you from being struck by a passing car while also providing you with enough space on both sides to cope with a rush of wind from the passing vehicle.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6763#7097","text":"Left#Center#Left or right#Center or right"},
{"testId":339,"qid":13127,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically switch on the headlight when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#8196#26512","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Turn the headlight on whenever you ride"},
{"testId":351,"qid":13127,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically switch on the headlight when the motor is started.","imageName":"","correctAnswer":26512,"isBookmarked":0,"aid":"8193#8194#8196#26512","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Turn the headlight on whenever you ride"},
{"testId":339,"qid":13128,"description":"What should you look for when you check your tires?","explanation":"When inspecting your tires, examine the tire pressure and tread wear, as well as any cracks or scratches that might cause the tire to fail.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#8235","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressure"},
{"testId":339,"qid":13130,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of goods, you may need to modify your suspension to withstand the increased weight.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":339,"qid":13131,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"The time of day has no bearing on your blood alcohol levels (BAC). Alcohol may impair your driving ability at any moment.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":339,"qid":13132,"description":"Maximum braking in a straight line is obtained by:","explanation":"Applying both brakes without locking the wheels results in maximum straight-line braking.","imageName":"","correctAnswer":22932,"isBookmarked":0,"aid":"8278#22808#22932#23053","text":"pulling in the clutch#applying both brakes until the wheels lock#applying both brakes without locking the wheels#applying only the front brake"},
{"testId":339,"qid":13134,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Motorcycles should never be driven in groups. To function properly, a motorbike needs the whole width of the lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8035#26513#26514","text":"Never#When you are riding at night#When you are turning a sharp corner#When you are approaching an intersection"},
{"testId":339,"qid":13135,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so you can see behind you as well as as much of the lane behind you as feasible. However, a motorbike still has blind spots, so be careful to peek over your shoulder when required.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10371#10372#23098#23099","text":"everything to the sides and rear#behind you and part of the lane beside you#only beside your vehicle#only behind your vehicle"},
{"testId":339,"qid":13136,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#24710#26515","text":"grip the backrest#wear a seat belt#put his or her feet on the ground when the motorcycle is stopped#hold on firmly to your waist, hips, or belt"},
{"testId":351,"qid":13136,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#24710#26515","text":"grip the backrest#wear a seat belt#put his or her feet on the ground when the motorcycle is stopped#hold on firmly to your waist, hips, or belt"},
{"testId":339,"qid":13137,"description":"Convex motorcycle side mirrors make automobiles appear ________.","explanation":"Convex side mirrors on a motorbike make automobiles seem farther away than they are.","imageName":"","correctAnswer":10429,"isBookmarked":0,"aid":"10426#10427#10428#10429","text":"closer than they are#bigger than they are#smaller than they are#farther away than they are"},
{"testId":434,"qid":13137,"description":"Convex motorcycle side mirrors make automobiles appear ________.","explanation":"Convex side mirrors on a motorbike make automobiles seem farther away than they are.","imageName":"","correctAnswer":10429,"isBookmarked":0,"aid":"10426#10427#10428#10429","text":"closer than they are#bigger than they are#smaller than they are#farther away than they are"},
{"testId":339,"qid":13138,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch lever is located on the left handlebar, the front brake lever is located on the right handlebar, and your right foot controls the rear brake pedal.","imageName":"","correctAnswer":22787,"isBookmarked":0,"aid":"22786#22787#26516#26517","text":"The clutch is on the right and the brake is on the left#The clutch is on the left and the brake is on the right#The clutch and the brake are on the left#The clutch and the brake are on the right"},
{"testId":339,"qid":13139,"description":"Which drugs can impair your ability to ride a motorcycle?","explanation":"Illegal, prescription, and over-the-counter substances may all impair your riding skills.","imageName":"","correctAnswer":25760,"isBookmarked":0,"aid":"8240#8241#25760#26518","text":"Illegal#Prescription#All#Over the counter"},
{"testId":339,"qid":13140,"description":"If you have a windscreen on your motorcycle,:","explanation":null,"imageName":"","correctAnswer":26519,"isBookmarked":0,"aid":"10532#26519#26520#26521","text":"a helmet is not required#you still must wear goggles or a faceshield#you must not wear goggles or a faceshield#you may wear glasses instead of a faceshield"},
{"testId":339,"qid":13142,"description":"The most effective approach to improve your visibility at an intersection is to:","explanation":"To improve your visibility at a junction, adopt a lane location that allows others to see you easily.","imageName":"","correctAnswer":6778,"isBookmarked":0,"aid":"6286#6776#6778#23393","text":"Flash your brake light#beep your horn to get attention#choose a lane position that lets others see you easily#use hand signals"},
{"testId":339,"qid":13143,"description":"The best face shield is:","explanation":"A shatter-resistant faceshield provides the highest face protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":26522,"isBookmarked":0,"aid":"7204#7583#7584#26522","text":"A windshield#approved goggles#sunglasses#a shatter-resistant faceshield"},
{"testId":339,"qid":13144,"description":"When you pull off the road to check, you should check:","explanation":"When pulling over to the side of the road, make sure the surface is stable enough to ride on.","imageName":"","correctAnswer":26524,"isBookmarked":0,"aid":"26523#26524#26525#26526","text":"if it is a designated rest area#if the surface is firm enough to ride on#if your brakes are working#if the area is well lit"},
{"testId":339,"qid":13145,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"Use both brakes to bike safely on slick conditions. Even on a slick surface, the front brake is more effective than the rear brake. Apply it slowly to prevent locking the front wheel.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6554#6555","text":"riding in the left lane position#only using one brake#using both brakes#riding in the right lane position"},
{"testId":367,"qid":13164,"description":"The ideal attire to wear to make yourself conspicuous is:","explanation":"Bright and luminous apparel is ideal for making oneself noticeable.","imageName":"","correctAnswer":7022,"isBookmarked":0,"aid":"7021#7022#7023#7024","text":"leather and tough#bright and reflective#colorful and big#dark and durable"},
{"testId":351,"qid":13166,"description":"Being passed by a huge vehicle is particularly dangerous because ___________.","explanation":"Being passed by a huge vehicle is extremely dangerous since the passing vehicle's enormous blast of wind may damage your control. Because motorcycles are smaller than vehicles, wind gusts have a greater impact on them.","imageName":"","correctAnswer":26541,"isBookmarked":0,"aid":"7256#7259#26541#26542","text":"large vehicles tend to swerve into your lane#large vehicles cannot accelerate as fast as motorcycles#a large blast of wind can affect your control#the drivers of large vehicles are often tired from working long hours"},
{"testId":355,"qid":13166,"description":"Being passed by a huge vehicle is particularly dangerous because ___________.","explanation":"Being passed by a huge vehicle is extremely dangerous since the passing vehicle's enormous blast of wind may damage your control. Because motorcycles are smaller than vehicles, wind gusts have a greater impact on them.","imageName":"","correctAnswer":26541,"isBookmarked":0,"aid":"7256#7259#26541#26542","text":"large vehicles tend to swerve into your lane#large vehicles cannot accelerate as fast as motorcycles#a large blast of wind can affect your control#the drivers of large vehicles are often tired from working long hours"},
{"testId":429,"qid":13205,"description":"When you park your motorcycle in a parallel space, it should be:","explanation":"Your motorbike should be placed in a parallel parking area at a 90-degree angle, with the rear wheel contacting the curb.","imageName":"","correctAnswer":28986,"isBookmarked":0,"aid":"74#28985#28986#35513","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel touching the curb#positioned at an angle with the front wheel touching the curb"},
{"testId":442,"qid":13205,"description":"When you park your motorcycle in a parallel space, it should be:","explanation":"Your motorbike should be placed in a parallel parking area at a 90-degree angle, with the rear wheel contacting the curb.","imageName":"","correctAnswer":28986,"isBookmarked":0,"aid":"74#28985#28986#35513","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel touching the curb#positioned at an angle with the front wheel touching the curb"},
{"testId":503,"qid":13205,"description":"When you park your motorcycle in a parallel space, it should be:","explanation":"Your motorbike should be placed in a parallel parking area at a 90-degree angle, with the rear wheel contacting the curb.","imageName":"","correctAnswer":28986,"isBookmarked":0,"aid":"74#28985#28986#35513","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel touching the curb#positioned at an angle with the front wheel touching the curb"},
{"testId":503,"qid":13223,"description":"A motorcycle cannot be driven on a highway:","explanation":"A motorbike cannot be driven on a highway unless it can move safely at the speed limit.","imageName":"","correctAnswer":41591,"isBookmarked":0,"aid":"7659#7661#27362#41591","text":"during rush hour#at night, because your lights aren't bright enough#without proper sunglasses#unless you can travel safely at the minimum speed limit"},
{"testId":77,"qid":13229,"description":"How do you keep yourself from overusing the throttle?","explanation":"Starting with your right wrist flat might help you avoid applying too much throttle.","imageName":"","correctAnswer":10771,"isBookmarked":0,"aid":"10769#10770#10771#10772","text":"Start with your right wrist raised#Keep the clutch pulled in#Start with your right wrist flat#Only ride in lower gears"},
{"testId":412,"qid":13229,"description":"How do you keep yourself from overusing the throttle?","explanation":"Starting with your right wrist flat might help you avoid applying too much throttle.","imageName":"","correctAnswer":10771,"isBookmarked":0,"aid":"10769#10770#10771#10772","text":"Start with your right wrist raised#Keep the clutch pulled in#Start with your right wrist flat#Only ride in lower gears"},
{"testId":438,"qid":13230,"description":"When going by another vehicle,:","explanation":"You must not exceed the speed limit while passing another vehicle.","imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":450,"qid":13230,"description":"When going by another vehicle,:","explanation":"You must not exceed the speed limit while passing another vehicle.","imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":499,"qid":13230,"description":"When going by another vehicle,:","explanation":"You must not exceed the speed limit while passing another vehicle.","imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":507,"qid":13230,"description":"When going by another vehicle,:","explanation":"You must not exceed the speed limit while passing another vehicle.","imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":503,"qid":13238,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":511,"qid":13238,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":438,"qid":13244,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the foot pegs, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24642#24643#28930","text":"your arms are slightly bent#your knees are against the gas tank#all of the above are true#your feet are resting firmly on the foot pegs"},
{"testId":511,"qid":13244,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the foot pegs, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24642#24643#28930","text":"your arms are slightly bent#your knees are against the gas tank#all of the above are true#your feet are resting firmly on the foot pegs"},
{"testId":343,"qid":13251,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____ angle.","explanation":null,"imageName":"","correctAnswer":35303,"isBookmarked":0,"aid":"35303#35304#35305#35306","text":"45 degrees#90 degrees#20 degrees#120 degrees"},
{"testId":302,"qid":13256,"description":"You are about to hit something and need to change direction to avoid it. How do you start a swerve in the best way?","explanation":"If you are in danger of colliding with anything, the easiest approach to commence a swerve is to apply little pressure to the handlegrip on the side of the targeted swerve. The motorbike will tilt sharply in that direction as a result.","imageName":"","correctAnswer":24598,"isBookmarked":0,"aid":"10742#24597#24598#24599","text":"Lean sharply in the opposite direction from which you need to swerve#Lean sharply in the direction in which you need to swerve#Apply a small amount of pressure to the handlegrip on the side of your intended swerve#Apply a small amount of pressure to the handlegrip on the side opposite to your intended swerve"},
{"testId":462,"qid":13260,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6492#6493#14655#30033","text":"At all times#At night and in fog#Between sunset and sunrise#Only at night"},
{"testId":442,"qid":13280,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"22821#22822#22823#30525","text":"Apply only the front brake#Apply only the rear brake#Pull the kill engine switch#Fully apply both the front and rear brakes"},
{"testId":343,"qid":13296,"description":"Which of the following punishments may you face if you are convicted of driving under the influence of alcohol or drugs (DUI)?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12377#18218#26643","text":"All of the above#License revocation#Jail#A fine"},
{"testId":343,"qid":13298,"description":"When preparing to pass the vehicle in front of you, ride in the _______ of your lane to increase your field of sight.","explanation":"Ride on the left side of your lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":6211,"isBookmarked":0,"aid":"6210#6211#6212#6213","text":"the center portion#the left portion#the right portion#any portion"},
{"testId":343,"qid":13303,"description":"Which colors of clothing are most visible to other road users?","explanation":"Bright hues like orange, red, yellow, and green are ideal for improving your visibility to others.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#1147#1148#6659","text":"All of the above#Green#Orange#Yellow"},
{"testId":343,"qid":13312,"description":"Riding a motorcycle while wearing a face shield:","explanation":null,"imageName":"","correctAnswer":7280,"isBookmarked":0,"aid":"7280#7282#40640#41867","text":"is the best form of face and eye protection#is not as effective as wearing goggles#is only required of motorcyclists under the age of 18#is legally required of all motorcyclists and their passengers"},
{"testId":343,"qid":13317,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the foot pegs even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#23033","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep his or her feet on the foot pegs"},
{"testId":343,"qid":13319,"description":"Which of the following assertions about the transport of a person is correct?","explanation":"Even while the motorbike is stopped at a traffic light, the passenger should maintain his or her feet on the foot pegs at all times.","imageName":"","correctAnswer":38455,"isBookmarked":0,"aid":"30052#30053#38455#38456","text":"The passenger should sit as far back as possible#The passenger should put his or her feet down at stops#The passenger should keep his or her feet on the foot pegs at all times#The passenger should avoid holding on to the rider"},
{"testId":343,"qid":13320,"description":"What should you do if the throttle is stuck and turning it back and forth does not dislodge it?","explanation":"If the throttle becomes stuck, try twisting it back and forth numerous times. If the throttle cable gets jammed, try this. If the throttle remains stuck, quickly depress the engine cut-off switch and engage the clutch. This will reduce power to the back wheel. Pull off and come to a complete stop after you have regained control of the motorbike. After you've come to a complete stop, inspect the throttle cable to determine the source of the issue.","imageName":"","correctAnswer":22925,"isBookmarked":0,"aid":"22924#22925#22926#22927","text":"Stop the motorcycle using the rear brake only#Flip the engine cut-off switch and pull in the clutch at the same time#Jump off the motorcycle#Pull in the clutch"},
{"testId":343,"qid":13325,"description":"In Florida, you can lawfully share a lane with :","explanation":null,"imageName":"","correctAnswer":41868,"isBookmarked":0,"aid":"28259#41868#41869#41870","text":"no one#another motorcycle#a small car or another motorcycle#any other vehicle"},
{"testId":343,"qid":13328,"description":"It is essential to keep a safe distance behind the vehicle in front of you:","explanation":"At all times, you should leave enough distance behind the car in front of you. Hazards may arise around you even if you are stopped.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#8029#8031#40643","text":"At all times#Unless you are stopped#When riding through school zones#When riding in heavy traffic"},
{"testId":343,"qid":13330,"description":"Weaving while traversing a metal grate:","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#24711#37536","text":"is not hazardous#is dangerous to motorcycle riders#is caused by poor motorcycle skills#is harmful to motorcycles"},
{"testId":483,"qid":13330,"description":"Weaving while traversing a metal grate:","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#24711#37536","text":"is not hazardous#is dangerous to motorcycle riders#is caused by poor motorcycle skills#is harmful to motorcycles"},
{"testId":343,"qid":13331,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware that you are following them?","explanation":"If you feel that the driver of the car ahead of you is unaware that you are following, you should ride in the middle lane. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6928,"isBookmarked":0,"aid":"6928#8313#8314#24706","text":"Move into the center lane position#Increase your following distance to 5 seconds#Try to pass the vehicle as soon as possible#Decrease your speed and ride in the left lane position"},
{"testId":343,"qid":13333,"description":"You can start a swerve by:","explanation":"Swerves may be initiated by pushing the handlegrip on the side of the desired direction. As a result, your motorbike will tilt sharply in that direction.","imageName":"","correctAnswer":23015,"isBookmarked":0,"aid":"8279#23014#23015#30461","text":"applying the rear brake#pressing the handlegrip on the side opposite to the direction in which you want to swerve#pressing the handlegrip on the side of the direction in which you want to swerve#downshifting"},
{"testId":343,"qid":13338,"description":"When should a motorcycle passenger board?","explanation":"A passenger should only climb on a motorbike once the rider has mounted and started it.","imageName":"","correctAnswer":8153,"isBookmarked":0,"aid":"7233#7234#8153#24934","text":"Before the engine has been started#Before the motorcycle is taken off the stand#After the engine has been started#Before the motorcyclist"},
{"testId":343,"qid":13341,"description":"When can you go in Florida if you feel your motorcycle did not trigger the light sensor when stopped at a red light?","explanation":"As of the time of this writing, 18 US states have passed \"dead red\" legislation, which let drivers who are riding motorcycles through red lights that have become \"stuck\" if their vehicles fail to activate the light sensor. Florida, however, is not one of those states.","imageName":"","correctAnswer":41871,"isBookmarked":0,"aid":"37206#38458#38459#41871","text":"Both (a) and (b)#(a) After waiting for 120 seconds#(b) After waiting for two light cycles#(c) If some other vehicle triggers the light sensor for you"},
{"testId":343,"qid":13342,"description":"Which of the following option can reduce the chain slippage?","explanation":"Tightening the chain should help to decrease slippage. If the issue continues, look for a worn or stretched chain or bent sprockets, which may need replacement.","imageName":"","correctAnswer":10999,"isBookmarked":0,"aid":"8626#10999#11001#31160","text":"None of the above#Tightening the chain#Loosening the chain#Replacing the chain monthly"},
{"testId":503,"qid":13342,"description":"Which of the following option can reduce the chain slippage?","explanation":"Tightening the chain should help to decrease slippage. If the issue continues, look for a worn or stretched chain or bent sprockets, which may need replacement.","imageName":"","correctAnswer":10999,"isBookmarked":0,"aid":"8626#10999#11001#31160","text":"None of the above#Tightening the chain#Loosening the chain#Replacing the chain monthly"},
{"testId":343,"qid":13343,"description":"What should you do to safely stop in a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt. Stopping while leaning might be risky.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6901#8296#30098#40973","text":"Keep your knees against the gas tank#Take your feet off the foot pegs for balance#Try to straighten your motorcycle upright#Lean the opposite way of the motorcycle"},
{"testId":343,"qid":13344,"description":"You should stop riding at least once every ____ hour(s) to avoid weariness.","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, take a rest break at least once every two hours.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#5002","text":"Two#Four#Six#Five"},
{"testId":491,"qid":13366,"description":"What should you look for when merging into the middle lane of a three-lane highway?","explanation":"When prepared to merge into the center lane of a three-lane highway, examine both the middle and far lanes. Someone in the far lane may be preparing to move into the center lane as well.","imageName":"","correctAnswer":33600,"isBookmarked":0,"aid":"7225#24617#33600#38898","text":"Your throttle, to make sure it is not stuck#Your signal light, to make sure it is working#The middle lane and the far lane#Your speed, to make sure you are not exceeding the speed limit"},
{"testId":499,"qid":13378,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":511,"qid":13378,"description":"What is one cause of turning skidding?","explanation":"When you use too much braking force during a turn, the wheel may lock and the motorbike may slide.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":438,"qid":13383,"description":"Before each ride, which of the following should be checked?","explanation":"Tires, clutch, throttle, and brakes are just a few of the safety features you should inspect before each trip.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#8089#8090","text":"All of the above#Tires#Clutch and throttle#Brakes"},
{"testId":471,"qid":13383,"description":"Before each ride, which of the following should be checked?","explanation":"Tires, clutch, throttle, and brakes are just a few of the safety features you should inspect before each trip.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8088#8089#8090","text":"All of the above#Tires#Clutch and throttle#Brakes"},
{"testId":302,"qid":13393,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handlegrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#10685","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean in the opposite direction of the turn"},
{"testId":383,"qid":13415,"description":"For optimal traction while driving in rain, you should drive:","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are less prone to slipping.","imageName":"","correctAnswer":28968,"isBookmarked":0,"aid":"7310#7312#28968#30021","text":"in the center lane position#on the shoulder of the road#in the tracks left by other vehicles#in the left lane position"},
{"testId":85,"qid":13434,"description":"When changing lanes on a multilane highway, check the lane next to you and:","explanation":"When changing lanes on a multilane road, always sure to scan both the distant lane and the lane next to you. Other cars may be attempting to merge into the same lane as you.","imageName":"","correctAnswer":8326,"isBookmarked":0,"aid":"8326#8451#8452#8453","text":"the far lane#behind you#in front of you#your throttle"},
{"testId":292,"qid":13436,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. This includes riding on slick roads, travelling in congested areas, and being tailgated.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#6999#35527","text":"All of the above#In heavy traffic#On slippery roads#When you're being tailgated"},
{"testId":462,"qid":13436,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. This includes riding on slick roads, travelling in congested areas, and being tailgated.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#6999#35527","text":"All of the above#In heavy traffic#On slippery roads#When you're being tailgated"},
{"testId":302,"qid":13480,"description":"Which of following is the most likely locations for a collision?","explanation":"An junction is the most probable location for an accident since many individuals are attempting to perform many things at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":404,"qid":13480,"description":"Which of following is the most likely locations for a collision?","explanation":"An junction is the most probable location for an accident since many individuals are attempting to perform many things at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":420,"qid":13480,"description":"Which of following is the most likely locations for a collision?","explanation":"An junction is the most probable location for an accident since many individuals are attempting to perform many things at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":303,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":347,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":425,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":438,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":454,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":499,"qid":13498,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To help keep the motorbike balanced, load goods equally.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":347,"qid":13503,"description":"How should you place your motorbike while parking beside a curb?","explanation":"Park at an angle with the rear tire hitting the curb to park near to a curb.","imageName":"","correctAnswer":10869,"isBookmarked":0,"aid":"6655#6656#10869#33921","text":"At an angle with the front wheel touching the curb#Parallel to the curb#At an angle with the rear wheel touching the curb#At an angle with the rear wheel on the curb"},
{"testId":347,"qid":13524,"description":"What can you do to increase your safety when riding at night?","explanation":"You may improve your nighttime safety by reducing your speed, using your high-beam headlight, and increasing your following distance.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6238#6239#8123#23000","text":"Reduce your speed#Increase your following distance#Use your high beam#Do all of the above"},
{"testId":347,"qid":13525,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective gear does not reduce your odds of being involved in an accident, but it does assist protect you if you are.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":295,"qid":13526,"description":"Which of the following impacts your motorcycle riding abilities?","explanation":"Not only may drinking impair your ability to ride a motorbike. Some illicit, prescription, and OTC substances may potentially impair your riding ability. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":347,"qid":13526,"description":"Which of the following impacts your motorcycle riding abilities?","explanation":"Not only may drinking impair your ability to ride a motorbike. Some illicit, prescription, and OTC substances may potentially impair your riding ability. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":347,"qid":13529,"description":"Which lane positions are the most secure for motorcycles?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":431,"qid":13529,"description":"Which lane positions are the most secure for motorcycles?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":495,"qid":13529,"description":"Which lane positions are the most secure for motorcycles?","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":347,"qid":13531,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake accounts for 70% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":483,"qid":13531,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake accounts for 70% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":347,"qid":13532,"description":"Is sharing a lane with another motorbike safe?","explanation":null,"imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":347,"qid":13534,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#32394","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around"},
{"testId":454,"qid":13534,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#32394","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around"},
{"testId":301,"qid":13571,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":38457,"isBookmarked":0,"aid":"7646#27957#28419#38457","text":"should be done to fix a wobble#should only be done by experienced riders#is prohibited while riding a motorcycle#allows your knees and elbows to absorb shock"},
{"testId":479,"qid":13571,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":38457,"isBookmarked":0,"aid":"7646#27957#28419#38457","text":"should be done to fix a wobble#should only be done by experienced riders#is prohibited while riding a motorcycle#allows your knees and elbows to absorb shock"},
{"testId":487,"qid":13571,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":38457,"isBookmarked":0,"aid":"7646#27957#28419#38457","text":"should be done to fix a wobble#should only be done by experienced riders#is prohibited while riding a motorcycle#allows your knees and elbows to absorb shock"},
{"testId":495,"qid":13573,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"When approaching a crossing where a pedestrian has just entered, you must yield to the pedestrian. Stop before the crosswalk if required and wait for the pedestrian to complete crossing the street.","imageName":"","correctAnswer":41050,"isBookmarked":0,"aid":"23036#23037#23038#41050","text":"Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian#Stop for the pedestrian"},
{"testId":507,"qid":13579,"description":"When following another motorcycle, keep a following distance of at least :","explanation":"When following another motorbike, allow at least four seconds between you and it, exactly as if you were following a vehicle.","imageName":"","correctAnswer":22147,"isBookmarked":0,"aid":"14310#22147#22148#26443","text":"5 seconds#4 seconds#6 seconds#3 seconds"},
{"testId":292,"qid":13587,"description":"The best technique to attract the attention of other vehicle operators is to:","explanation":"Make sure your headlights are switched on if you want to be noticed by other drivers. (Note: In the United States, bikes produced after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":10405,"isBookmarked":0,"aid":"8196#10405#10406#10407","text":"Only ride during the day#make sure your headlight is turned on#wear a bright helmet#Ride in the left lane position"},
{"testId":425,"qid":13587,"description":"The best technique to attract the attention of other vehicle operators is to:","explanation":"Make sure your headlights are switched on if you want to be noticed by other drivers. (Note: In the United States, bikes produced after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":10405,"isBookmarked":0,"aid":"8196#10405#10406#10407","text":"Only ride during the day#make sure your headlight is turned on#wear a bright helmet#Ride in the left lane position"},
{"testId":71,"qid":13619,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":359,"qid":13619,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":363,"qid":13619,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":412,"qid":13619,"description":"What are two strategies for avoiding roadblocks?","explanation":"Stopping and swerving are two methods for avoiding hazards. Never brake while swerving, only before or after.","imageName":"","correctAnswer":7610,"isBookmarked":0,"aid":"7610#7611#7612#7613","text":"Stopping and swerving#Stopping and sliding#Sliding and swerving#Sliding and skidding"},
{"testId":367,"qid":13629,"description":"When deciding where to turn, you should:","explanation":"Turn your head, not your shoulders, towards the direction of the planned turn while gazing where you wish to turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended. Maintain a level gaze with the horizon and slow down before the curve.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#10722#10723#10724","text":"Do all of the above#turn your head, not your shoulders, in the direction of the intended turn#keep your eyes level with the horizon#reduce your speed before the turn"},
{"testId":363,"qid":13653,"description":"When riding at night, you can improve your safety by:","explanation":"Wear fluorescent clothes, use your high beam, and slow down while riding at night to maximize your safety.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":396,"qid":13653,"description":"When riding at night, you can improve your safety by:","explanation":"Wear fluorescent clothes, use your high beam, and slow down while riding at night to maximize your safety.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":74,"qid":13670,"description":"In a turn, rolling on the throttle:","explanation":"Turning the throttle helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#23043#28402","text":"helps stabilize the motorcycle#should only be done in emergencies#is done to pass other vehicles#is done while shifting gears"},
{"testId":355,"qid":13683,"description":"You can boost your visibility by:","explanation":"Position yourself in the lane where you are most likely to be spotted and where there is enough space around you. As traffic conditions change, adjust your position.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":400,"qid":13690,"description":"When you are properly seated on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":22887,"isBookmarked":0,"aid":"6827#7671#22886#22887","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent at the elbows"},
{"testId":379,"qid":13692,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10464#10465#10466#10467","text":"make eye contact with other drivers#flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you"},
{"testId":351,"qid":13696,"description":"Look ________ ahead on your travel path to maintain a safe riding distance on the road.","explanation":"Look 12 seconds ahead on your travel route to maintain a safe riding distance on the road. At 60 mph, you will cover 1,056 feet in 12 seconds.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#6077#6078#6079","text":"12 seconds#60 seconds#40 seconds#25 seconds"},
{"testId":351,"qid":13697,"description":"As you approach an intersection while being closely pursued, the light turns yellow. What ought you to do?","explanation":"If you suspect the car behind you may not see you slowing down, flash your brake light to alert the motorist.","imageName":"","correctAnswer":10755,"isBookmarked":0,"aid":"10752#10754#10755#28200","text":"Beep your horn to warn the driver#Accelerate to make it through the light#Tap your brake to flash your brake light#Change lanes"},
{"testId":351,"qid":13699,"description":"When you are behind a car, in which lane will the driver be able to see you the best?","explanation":"Riding in the middle lane makes you the most noticeable. Drivers glance more frequently in their rearview mirrors than in their side mirrors, so you will be noticed.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":351,"qid":13700,"description":"As you get closer to the center of a curve, you should:","explanation":"You should shift toward the inside of the curve as you get closer to the turn's centre. When you get to the turn's centre, you should turn away from it.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#6424#10833#10834","text":"Apply the rear brake#decrease the throttle#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":351,"qid":13701,"description":"You may improve your nighttime safety by:","explanation":"When riding at night, you may maximize your safety by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":351,"qid":13703,"description":"You should _____ when passing another vehicle.","explanation":"You should go fast across the driver's blind area while passing another car.","imageName":"","correctAnswer":24596,"isBookmarked":0,"aid":"6629#6630#6632#24596","text":"move as far left as possible#begin your pass from the center lane position#make eye contact with the driver#move quickly through the driver's blind spot"},
{"testId":351,"qid":13705,"description":"What distance is the bare minimum space cushion for safe riding?","explanation":"A two-second following distance is the bare minimum for safe riding.","imageName":"","correctAnswer":549,"isBookmarked":0,"aid":"549#550#2544#3640","text":"Two-second#Four-second#Five-second#One-second"},
{"testId":351,"qid":13706,"description":"In which of the following situations should you roll on the throttle?","explanation":"When turning or navigating a curve, roll on the throttle to assist steady your motorbike.","imageName":"","correctAnswer":26467,"isBookmarked":0,"aid":"26467#28201#28202#28203","text":"To help stabilize your motorcycle in a turn#When preparing to stop#When passing a row of parked cars#When descending a hill"},
{"testId":351,"qid":13707,"description":"What's the best way to detecting bumps in the road at night?","explanation":"Keep an eye out for the taillights of the vehicle ahead of you. If they are bouncing up and down, it is probable that the automobile is travelling over bumps.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"10581#22794#22795#28204","text":"Wear reflective clothing#Watch the taillights of the car ahead of you#Stay in low gears#Use your low-beam headlight"},
{"testId":351,"qid":13709,"description":"How can you control the rear brake?","explanation":"The right foot pedal is used to operate the rear brake.","imageName":"","correctAnswer":28205,"isBookmarked":0,"aid":"28205#28206#28207#28208","text":"With the right foot pedal#With the left foot pedal#With the right hand lever#With the left hand lever"},
{"testId":351,"qid":13710,"description":"If someone is passing you on the left, you should:","explanation":"Avoid driving in the left lane if you are being passed. You might be struck by an extended mirror or be harmed by other risks caused by a passing car. In this case, the center lane position is preferable since it provides greater room for error on both sides if you are struck by a gust of wind from a passing car.","imageName":"","correctAnswer":25842,"isBookmarked":0,"aid":"7334#25842#26469#26470","text":"ride on the shoulder of the road#avoid the left lane position#move closer to the passing vehicle#avoid the center lane position"},
{"testId":351,"qid":13711,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"To transport a passenger, your motorbike must have footrests for the individual. In addition, the seat must be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#7206#22946","text":"A windshield#footrests for the passenger#side saddlebags#high-quality shocks"},
{"testId":351,"qid":13712,"description":"Which of the following factors has an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content may be affected by how quickly you drink, how many drinks you've had, and how much you weigh (BAC).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#7291#7292","text":"All of the above#How fast you drink#The number of drinks you've had#How much you weigh"},
{"testId":351,"qid":13713,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Motorcycle riders should never ride in groups. To function properly, a motorbike needs the whole width of the lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8035#26513#26514","text":"Never#When you are riding at night#When you are turning a sharp corner#When you are approaching an intersection"},
{"testId":351,"qid":13714,"description":"Which lane should you ride in to improve your visibility?","explanation":"Ride in the left lane to improve your line of sight. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":351,"qid":13715,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike in a regular turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22894#22895#22896","text":"No, the rider should not lean#Yes, the rider should lean in the opposite direction#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward"},
{"testId":351,"qid":13716,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"Beginner riders should follow behind the leader while riding in a group. This permits them to keep up with the rest of the group.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#7413","text":"behind the leader#at the end#in the front#in front of the last rider"},
{"testId":351,"qid":13717,"description":"The most effective strategy to swerve is to:","explanation":"To swerve, push the handgrip on the opposite side of the direction you wish to turn. Never swerve and brake at the same time.","imageName":"","correctAnswer":28210,"isBookmarked":0,"aid":"7437#7438#28209#28210","text":"brake while you are swerving#pull in the clutch and apply the rear brake#press the handgrip on the side opposite to the direction in which you want to turn#avoid braking and press the handgrip on the side of the direction in which you want to turn"},
{"testId":351,"qid":13718,"description":"A motorcycle's available lane positions are:","explanation":"A motorbike may take one of three places in each lane of traffic: left, center, or right.","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":351,"qid":13719,"description":"What happens if you bike too fast through a turn?","explanation":"If you ride too rapidly through a curve, you may wind yourself drifting into another lane.","imageName":"","correctAnswer":28212,"isBookmarked":0,"aid":"28211#28212#28213#28214","text":"You may tip over#You may veer into another lane#Other drivers may become distracted#The motorcycle may suffer mechanical failure"},
{"testId":351,"qid":13721,"description":"The mini-lane of the motorcycle is:","explanation":"A motorcycle mini-lane is one-third the width of a standard traffic lane. As a result, a motorbike has three lane locations accessible in each lane: left, center, and right.","imageName":"","correctAnswer":28220,"isBookmarked":0,"aid":"7532#10035#28219#28220","text":"a special lane designated for motorcycles only#a full lane width#one-half the width of a lane#one-third the width of a lane"},
{"testId":351,"qid":13722,"description":"What is an adequate space cushion between you and the vehicle ahead of you when riding in heavy, congested traffic?","explanation":"An sufficient spacing cushion is at least four seconds while riding in severe, crowded traffic.","imageName":"","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":351,"qid":13723,"description":"You should use _______ to hold you while you wait for the engine to take hold while starting on a slope.","explanation":"When beginning on a slope, use the rear brake to keep you steady while you wait for the engine to catch up.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":351,"qid":13724,"description":"How do you alert the driver behind聽you that you are about to slow down?","explanation":"Flash your brake light to alert the motorist behind you that you are going to slow down. Tap the brakes to do this.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"10718#10917#10918#10919","text":"Flash your brake light#Turn around and make eye contact#Slam on the brakes#Wave your arms"},
{"testId":351,"qid":13726,"description":"When following other motorcycles, keep a following distance of at least:","explanation":"When following other motorcyclists, allow at least 2 seconds between them.","imageName":"","correctAnswer":26444,"isBookmarked":0,"aid":"22147#22148#26443#26444","text":"4 seconds#6 seconds#3 seconds#2 seconds"},
{"testId":351,"qid":13727,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are not as visible as automobile brake lights, but if they flash, they become more visible. Tap the brake to flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#10774","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid driving at night"},
{"testId":351,"qid":13729,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever. The clutch is controlled by the lever on the left. The right foot pedal operates the rear brake, while the left foot pedal operates the gear changer.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#8083","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot pedal"},
{"testId":351,"qid":13730,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#8102#24703#28221","text":"ride under 30 mph#ride in residential areas only#ride only in first gear#you must not ride a motorcycle"},
{"testId":351,"qid":13731,"description":"The best way around a curve:","explanation":"The optimal route across a curve varies depending on the curvature.","imageName":"","correctAnswer":28223,"isBookmarked":0,"aid":"28222#28223#28224#28225","text":"is close to the centerline#is different for each curve#is close to the edge of the road#is along the center of the road"},
{"testId":351,"qid":13733,"description":"Weaving while traversing a metal grate:","explanation":"Weaving from cycling over a metal grating is not dangerous. Relax, keep on track, and ride right through it.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#8221#24711","text":"is not hazardous#is dangerous to motorcycle riders#is harmful to the motorcycle#is caused by poor motorcycle skills"},
{"testId":351,"qid":13734,"description":"When you turn on your motorcycle, you right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#8444#28226","text":"flat, so you won't use too much throttle#high, to use the throttle the most#in the most comfortable position#low, to increase control"},
{"testId":438,"qid":13734,"description":"When you turn on your motorcycle, you right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#8444#28226","text":"flat, so you won't use too much throttle#high, to use the throttle the most#in the most comfortable position#low, to increase control"},
{"testId":351,"qid":13735,"description":"If a dog starts chasing you while you're driving, you should:","explanation":"If a dog starts after you, you should approach the beast and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":28227,"isBookmarked":0,"aid":"6570#6572#28227#28228","text":"kick the dog#stop until the owner is found#approach the animal and then speed away#call animal control services"},
{"testId":351,"qid":13736,"description":"Which of the following is NOT a method of increasing your visibility at night?","explanation":"Using your low beam does not improve your vision at night. For the greatest vision at night, utilize your high beam.","imageName":"","correctAnswer":10729,"isBookmarked":0,"aid":"6239#10581#10729#10730","text":"Increase your following distance#Wear reflective clothing#Use your low beam headlight#Change your lane position"},
{"testId":351,"qid":13738,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally situated near the right handgrip.","imageName":"","correctAnswer":10665,"isBookmarked":0,"aid":"10663#10664#10665#10666","text":"Near the left handgrip#Near the right foot pedal#Near the right handgrip#Near the left foot pedal"},
{"testId":351,"qid":13739,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent potentially dangerous circumstances, you should scan the road ahead for any risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":351,"qid":13740,"description":"The group of riders riding in a staggered formation should pass:","explanation":"Riders should pass one at a time in a staggered pattern.","imageName":"","correctAnswer":28229,"isBookmarked":0,"aid":"28229#28230#28231#28232","text":"one at a time#two at a time#all together#in a group no larger than 4 or 5 riders"},
{"testId":351,"qid":13741,"description":"Look for _____ when monitoring road conditions.","explanation":"When scanning road conditions, search for escape routes, traffic situations, and potentially hazardous road conditions.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":351,"qid":13742,"description":"Which of the following option can reduce the chain slippage?","explanation":"Tightening the chain should help to decrease slippage. If the issue continues, look for a worn or stretched chain or bent sprockets, which may need chain replacement.","imageName":"","correctAnswer":10999,"isBookmarked":0,"aid":"8626#10999#11000#11001","text":"None of the above#Tightening the chain#Replacing the chain every month#Loosening the chain"},
{"testId":351,"qid":13743,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The left foot pedal is used to change gears.","imageName":"","correctAnswer":7119,"isBookmarked":0,"aid":"7117#7119#7863#7864","text":"right foot pedal#left foot pedal#rear brake lever#front brake lever"},
{"testId":351,"qid":13744,"description":"Intersections are particularly hazardous to motorcycle riders because:","explanation":"Motorcycles are particularly vulnerable at intersections because other drivers often fail to see them coming.","imageName":"","correctAnswer":28233,"isBookmarked":0,"aid":"8113#8114#8115#28233","text":"motorcycles are not permitted to cross intersections#they do not have to obey traffic lights#they are slower than other vehicles#other vehicle operators often do not see them approaching"},
{"testId":396,"qid":13744,"description":"Intersections are particularly hazardous to motorcycle riders because:","explanation":"Motorcycles are particularly vulnerable at intersections because other drivers often fail to see them coming.","imageName":"","correctAnswer":28233,"isBookmarked":0,"aid":"8113#8114#8115#28233","text":"motorcycles are not permitted to cross intersections#they do not have to obey traffic lights#they are slower than other vehicles#other vehicle operators often do not see them approaching"},
{"testId":351,"qid":13745,"description":"The most likely location of a collision is:","explanation":"An junction is the most probable location for a collision since numerous cars are attempting to perform many tasks at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":64,"qid":13758,"description":"During the course of regular turns,:","explanation":"During regular turns, the rider should lean with the motorbike. The rider should stay upright when the motorbike leans in gentle turns.","imageName":"","correctAnswer":7057,"isBookmarked":0,"aid":"7055#7057#24879#28415","text":"the rider should remain upright while leaning the motorcycle#the rider should lean with the motorcycle#the rider should lean in the opposite direction of the motorcycle#both the rider and motorcycle should remain upright"},
{"testId":69,"qid":13768,"description":"Curved acceleration:","explanation":"Accelerating in a curve aids with the stability of the motorbike. Turning too slowly is more risky.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7489#7490#7491","text":"helps stabilize the motorcycle#should not be done#should be done only in small curves#is for experienced riders only"},
{"testId":71,"qid":13773,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect the tires, fluids, and signal lights.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#27170","text":"All of the above#The tires#The fluids#The signal lights"},
{"testId":367,"qid":13773,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect the tires, fluids, and signal lights.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#27170","text":"All of the above#The tires#The fluids#The signal lights"},
{"testId":383,"qid":13773,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect the tires, fluids, and signal lights.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#27170","text":"All of the above#The tires#The fluids#The signal lights"},
{"testId":458,"qid":13773,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before mounting your motorbike, inspect the tires, fluids, and signal lights.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#27170","text":"All of the above#The tires#The fluids#The signal lights"},
{"testId":355,"qid":13781,"description":"_____ is  the finest approach to protect your eyes.","explanation":"A face shield is the greatest approach to protect your eyes. Select a face shield-equipped helmet.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":367,"qid":13793,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors, look over your shoulder, and check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":388,"qid":13793,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors, look over your shoulder, and check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":392,"qid":13793,"description":"Before you change lanes:","explanation":"Before every lane change, you should check your mirrors, look over your shoulder, and check your blind spots.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"9719#9720#9994#10656","text":"check your blind spots#check your mirrors#look over your shoulder#Do all of the above"},
{"testId":359,"qid":13796,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":367,"qid":13796,"description":"If you are planning to ride an unfamiliar motorcycle, you must first .","explanation":"If you are planning to ride an unknown motorbike, you must do all of the necessary inspections and get acquainted with all of the controls before you begin riding.","imageName":"","correctAnswer":6074,"isBookmarked":0,"aid":"6073#6074#10620#26527","text":"add more accessories for safety#make all the checks and get familiar with the controls#put on dark-colored clothes and a helmet#allow a passenger to sit behind you"},
{"testId":303,"qid":13820,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22797#22798#22799#24618","text":"Try to jump off the bike before hitting the ground#Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear"},
{"testId":450,"qid":13820,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22797#22798#22799#24618","text":"Try to jump off the bike before hitting the ground#Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear"},
{"testId":371,"qid":13833,"description":"You can take someone with you if:","explanation":"If your motorbike is intended to accommodate another person, you may transport a passenger. This implies it must feature a passenger seat as well as dedicated footrests.","imageName":"","correctAnswer":8097,"isBookmarked":0,"aid":"8096#8097#8099#23047","text":"the passenger has special shoes#your motorcycle is designed to carry another person#all of the above conditions are met#the passenger is wearing protective clothing"},
{"testId":367,"qid":13882,"description":"Which of following is the most likely locations for a collision?","explanation":"An junction is the most probable location for an accident since many individuals are attempting to perform many things at once.","imageName":"","correctAnswer":6934,"isBookmarked":0,"aid":"6932#6933#6934#22812","text":"A residential area#A school zone#An intersection#A multilane highway"},
{"testId":355,"qid":13892,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#8013","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for drivers only"},
{"testId":371,"qid":13892,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#8013","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for drivers only"},
{"testId":355,"qid":13896,"description":"If you are found guilty of driving while intoxicated in Idaho, you will be punished with:","explanation":"If you are found to be driving under the influence of alcohol or drugs, you will face one or more of the following penalties: license suspension, a large fine, or prison time.","imageName":"","correctAnswer":28961,"isBookmarked":0,"aid":"26392#28959#28960#28961","text":"mandatory license suspension#a heavy fine#jail#one or more of the above"},
{"testId":355,"qid":13897,"description":"This symbol indicates that you are getting close to:","explanation":"This sign serves as a forewarning of an impending train crossing. If a train is nearing the crossing, prepare to halt before reaching the tracks.","imageName":"202001282004545744.jpg","correctAnswer":9211,"isBookmarked":0,"aid":"6934#9211#22101#28369","text":"An intersection#a railroad crossing#a dead end#a construction zone"},
{"testId":355,"qid":13898,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"If an item unexpectedly arrives in your path, you should swerve around it to prevent a collision.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":355,"qid":13899,"description":"Maintaining or accelerating during a turn:","explanation":"Maintaining or increasing your speed while turning helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6202#7596#26529","text":"helps stabilize the motorcycle#is safer because you complete the turn quickly#should never be done#should only be done when passing"},
{"testId":355,"qid":13900,"description":"_____ will alert other drivers that you are reducing your speed.","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly important when slowing down suddenly or in odd areas.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#10901","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#Using your signal lights"},
{"testId":355,"qid":13901,"description":"When you use saddlebags, you need to:","explanation":"When employing saddlebags, the load should be distributed equally across the saddlebags.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6323#6326#26463#28962","text":"fill one bag before the other one#Distribute the load evenly#fill both of them completely#fill them only with approved contents"},
{"testId":355,"qid":13902,"description":"When you have a passenger on board, your motorcycle will:","explanation":"Your motorbike will be heavier and react slower than normal while carrying a passenger.","imageName":"","correctAnswer":28963,"isBookmarked":0,"aid":"6440#28963#28964#28965","text":"easily tip over#respond slower than usual#respond quicker than usual#respond as it would without a passenger"},
{"testId":355,"qid":13903,"description":"Which lane should you ride in if there are hazards on your right?","explanation":"If there are risks to your right, ride in positions one (left) or two (middle), which will put you farther away from the hazards.","imageName":"","correctAnswer":28388,"isBookmarked":0,"aid":"28388#28389#28390#28391","text":"One (left) or two (center)#One (left)#Two (center)#Three (right)"},
{"testId":355,"qid":13904,"description":"If you need to change gears as you approach a turn,:","explanation":"If you need to swap gears as you approach a corner, do it ahead of time. Shifting gears in a turn may be risky.","imageName":"","correctAnswer":22966,"isBookmarked":0,"aid":"22964#22965#22966#22967","text":"shift gears in the turn#shift gears after the turn#shift gears before the turn#you do not need to shift gears for a turn"},
{"testId":355,"qid":13905,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":355,"qid":13906,"description":"At a stop sign, your motorcycle should remain in:","explanation":"When you come to a complete stop at a stop sign, keep the motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":355,"qid":13907,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A single traffic lane has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":355,"qid":13908,"description":"Your companion should:","explanation":"Your passenger should tilt in the same direction as you.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6812#6813#28966#28967","text":"lean as you lean#lean back to balance the motorcycle#wear a lap belt#also have a motorcycle license"},
{"testId":355,"qid":13909,"description":"When evaluating potential hazards, you should be considering:","explanation":"Consider road features, other vehicles, and traffic management while assessing possible dangers. They may all contribute to dangerous circumstances.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6929#6930#6931#8615","text":"road characteristics#other vehicles#traffic controls#All of the above"},
{"testId":355,"qid":13910,"description":"You should begin riding the motorcycle with _____.","explanation":"To avoid using too much throttle, start the motorbike with your right wrist flat.","imageName":"","correctAnswer":6976,"isBookmarked":0,"aid":"6973#6974#6975#6976","text":"your right wrist low#your elbows locked#your hands above your elbows#your right wrist flat"},
{"testId":355,"qid":13911,"description":"You should have at least _____ seconds between you and the car in front of you.","explanation":"There should be at least 3 seconds between you and the car in front of you.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":355,"qid":13912,"description":"What should you do if another vehicle attempts to share your lane?","explanation":"By riding in the center lane, you can deter other vehicles from trying to share your lane.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":355,"qid":13914,"description":"When it is raining, the optimal position for traction is:","explanation":"When it's raining, ride in the tracks left by other cars for increased traction.","imageName":"","correctAnswer":28968,"isBookmarked":0,"aid":"7176#7177#7178#28968","text":"The center lane position#the shoulder of the road#the left lane position#in the tracks left by other vehicles"},
{"testId":355,"qid":13916,"description":"Riding a motorcycle while wearing a face shield:","explanation":"A face shield is the greatest kind of face and eye protection since it covers your whole face and will not blow away in the wind.","imageName":"","correctAnswer":28970,"isBookmarked":0,"aid":"7282#28969#28970#28971","text":"is not as effective as wearing goggles#is legally required#is the best way to protect your face and eyes#is only required of riders under the age of 18"},
{"testId":355,"qid":13917,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content (BAC) may be altered by how quickly you drink, how many drinks you've had, and your weight.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#7291#7292","text":"All of the above#How fast you drink#The number of drinks you've had#How much you weigh"},
{"testId":355,"qid":13918,"description":"You are deemed legally intoxicated, if:","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":28972,"isBookmarked":0,"aid":"7425#24849#28972#28973","text":"you have had at least one drink#you have had one drink and are over the age of 21#your blood alcohol concentration (BAC) is 0.08% or more#your blood alcohol concentration (BAC) is 0.05% or more"},
{"testId":355,"qid":13919,"description":"The easiest strategy to reduce reaction time when approaching an intersection is to:","explanation":"Covering the clutch and brake is the greatest strategy to decrease response time in a potentially dangerous scenario.","imageName":"","correctAnswer":7539,"isBookmarked":0,"aid":"7537#7538#7539#7540","text":"Keep the clutch pulled in when not accelerating#Avoid wearing a full-face helmet#Cover the clutch and the brake#Avoid using the highest gear"},
{"testId":355,"qid":13920,"description":"Which of the following restrictions apply to a bearer of a motorcycle instruction permit?","explanation":"Anyone with a valid Idaho Class A, B, C, or D license may get a motorcycle instruction permit. This permit is valid for 180 days and permits motorcycle riders to ride under the following conditions: Only ride during the day; no highway driving; no passengers.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33987#33988#33989","text":"All of the above#Daylight riding only#No freeway riding#No passengers"},
{"testId":355,"qid":13921,"description":"Which of the following does NOT reduce your chances of being involved in a collision?","explanation":"Wearing protective equipment can assist protect you if you are in an accident, but it will NOT reduce your odds of being in one.","imageName":"","correctAnswer":7593,"isBookmarked":0,"aid":"7591#7593#10801#10802","text":"Using your signal lights#Wearing protective gear#Maintaining a space cushion#Scanning your path of travel"},
{"testId":355,"qid":13922,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"Crossing railroad lines parallel to your path should be done at an angle of at least 45 degrees to prevent snagging your tires on the tracks. However, crossing at a considerably greater angle (approaching 90 degrees) may result in you being carried into another lane of traffic, which might be hazardous.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#28974","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that are parallel to your course"},
{"testId":371,"qid":13922,"description":"Which of the following is a proper way to cross the railroad tracks that are parallel to your course?","explanation":"Crossing railroad lines parallel to your path should be done at an angle of at least 45 degrees to prevent snagging your tires on the tracks. However, crossing at a considerably greater angle (approaching 90 degrees) may result in you being carried into another lane of traffic, which might be hazardous.","imageName":"","correctAnswer":26440,"isBookmarked":0,"aid":"26439#26440#26442#28974","text":"Cross them at an angle of exactly 90 degrees#Cross them at an angle of at least 45 degrees#Edge across the tracks#Never cross railroad tracks that are parallel to your course"},
{"testId":355,"qid":13923,"description":"You should _______,when traversing a slick surface.","explanation":"When crossing a slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#7814#7815#28975","text":"Use the front brake only#stop and walk the bike across#pull in the clutch and coast#accelerate or maintain your speed"},
{"testId":355,"qid":13924,"description":"The regulations of riding in a group include:","explanation":"Checking your mirrors regularly, riding in a staggered configuration when feasible, and not following too closely are all guidelines of group riding.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7935#8615#25872#25873","text":"checking your mirrors frequently#All of the above#maintaining a staggered formation#not following too closely"},
{"testId":355,"qid":13925,"description":"When should you wear protective eyewear? :","explanation":"Even though the motorbike has a windshield, you should always wear protective eyewear. A windscreen DOES NOT replace a face shield or goggles.","imageName":"","correctAnswer":28977,"isBookmarked":0,"aid":"7866#28976#28977#28978","text":"only while riding on highways and freeways#if your motorcycle has no windshield#at all times when riding#only if you choose to do so"},
{"testId":355,"qid":13926,"description":"When is it permissible to ride your motorcycle between two lanes of traffic?","explanation":"Even if there seems to be space, never ride a motorbike between lanes of traffic.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8087#28377#28979","text":"Never#In designated areas#When traffic is at a standstill#Only if both lanes are headed in the same direction"},
{"testId":355,"qid":13927,"description":"What can you do to maintain your balance during a turn?","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns. It allows you and your motorbike to move as one unit.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#10712#10713#10714","text":"Keep your knees against the gas tank#Crouch down low#Pull in the clutch and coast#Slow down before the turn"},
{"testId":355,"qid":13928,"description":"What are the three steps of the SEE strategy?","explanation":"The acronym \"SEE\" stands for Search, Evaluate, and Execute.","imageName":"","correctAnswer":22778,"isBookmarked":0,"aid":"8003#8004#8005#22778","text":"Search, examine, execute#See, evaluate, examine#See, execute, examine#Search, evaluate, execute"},
{"testId":355,"qid":13929,"description":"When you ride a motorcycle, you should wear protective and ________clothes.","explanation":"When riding a motorbike, your clothes should be safe and totally cover your arms and legs. This is critical to keeping you safe in the event of an accident.","imageName":"","correctAnswer":8019,"isBookmarked":0,"aid":"8018#8019#8020#8021","text":"made of an approved material#completely cover your arms and legs#tight fitting#loose fitting"},
{"testId":355,"qid":13931,"description":"The _______ lane position is optimal for being seen by the driver ahead.","explanation":"The middle lane is ideal for being seen to the motorist ahead. You will be visible in the driver's rearview mirror. Drivers scan their rearview mirrors more often than their side mirrors.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#8108","text":"Center#Right#Left#Left or right"},
{"testId":355,"qid":13932,"description":"When should your mirrors be adjusted?","explanation":"Adjust your mirrors after mounting the motorbike but before you begin riding.","imageName":"","correctAnswer":7516,"isBookmarked":0,"aid":"7516#8161#8162#10851","text":"After mounting the motorcycle#Before mounting the motorcycle#After starting the motorcycle engine#After you have started riding"},
{"testId":355,"qid":13934,"description":"A motorcycle has a narrower profile than a car. Therefore,:","explanation":"Because a motorbike has a lower profile than a vehicle, it is less noticeable. When riding a motorbike, use caution.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":355,"qid":13937,"description":"Which of the following statements is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28980#28981#28982","text":"All of the above are correct#While riding, if you encounter a relatively large animal (such as a deer, an elk, or a cow), brake and prepare to stop#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you are in traf铿乧, do not change lanes to avoid hitting an animal"},
{"testId":355,"qid":13939,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so that you can see behind you as well as as much of the lane alongside you as feasible. A motorbike still has blind zones, so glance over your shoulder whenever possible.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10372#23098#23099#32930","text":"behind you and part of the lane beside you#only beside your vehicle#only behind your vehicle#everything in front of you"},
{"testId":355,"qid":13940,"description":"The best technique to attract the attention of other vehicle operators is to:","explanation":"Make sure your headlights are switched on if you want to be noticed by other drivers. (If the bike is in good operating order, motorcycles produced after 1978 will automatically switch on the low-beam headlamp once the engine is started.)","imageName":"","correctAnswer":10405,"isBookmarked":0,"aid":"8196#10405#10407#28983","text":"Only ride during the day#make sure your headlight is turned on#Ride in the left lane position#wear a bright-colored helmet"},
{"testId":355,"qid":13942,"description":"Where should you stop as you approach a blind junction with a stop line?","explanation":"When approaching a blind junction with a stop line, stop first at the stop line, then go ahead and halt at a more visible position.","imageName":"","correctAnswer":26420,"isBookmarked":0,"aid":"7374#10668#10670#26420","text":"At the stop line only#Past the stop line, where you can see clearly#On the stop line#At the stop line first, then farther forward"},
{"testId":355,"qid":13943,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":355,"qid":13945,"description":"To prevent weariness, you should stop riding at least once every _____ hour(s).","explanation":"Riding a motorbike is more exhausting than driving a vehicle. To prevent weariness, you should take at least two hours off from cycling.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":383,"qid":13958,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6940#6941#10572#10573","text":"Avoid driving in heavy traffic#wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles"},
{"testId":371,"qid":14013,"description":"The most effective strategy to swerve is to:","explanation":"To swerve, squeeze the handlegrip on the opposite side of the direction you wish to turn. Never swerve and brake at the same time.","imageName":"","correctAnswer":22984,"isBookmarked":0,"aid":"7437#7438#22984#29382","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handlegrip on the side of the direction in which you want to turn#avoid braking and press the handlegrip on the side opposite to the direction in which you want to turn"},
{"testId":442,"qid":14029,"description":"Use your feet to keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footpegs.","imageName":"","correctAnswer":29393,"isBookmarked":0,"aid":"8165#8167#10733#29393","text":"skimming them above the ground#holding them away from the motorcycle#pointing your toes downward#keeping them on the footpegs"},
{"testId":72,"qid":14043,"description":"How often should you stop and stretch while riding long distances?","explanation":"When biking lengthy distances, you should stop every two hours to stretch. This helps to avoid weariness.","imageName":"","correctAnswer":7663,"isBookmarked":0,"aid":"7662#7663#7664#7665","text":"Every 50 miles#Every two hours#Every five hours#Once per trip"},
{"testId":367,"qid":14059,"description":"The greatest apparel colors for visibility are:","explanation":"Orange, red, yellow, and green are the greatest hues to be noticed in.","imageName":"","correctAnswer":6869,"isBookmarked":0,"aid":"6869#10745#10746#10747","text":"orange, red, yellow, and green#orange, yellow, and white#red, green, and yellow#white, red, and orange"},
{"testId":375,"qid":14061,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"You should downshift not just when you slow or stop, but also when you need more power to accelerate.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6981#6983#26117#29407","text":"When you are descending a hill#when you need more power to accelerate#while riding on a multilane road#when you pull in the clutch"},
{"testId":511,"qid":14062,"description":"What is the most secure way of braking?","explanation":"Using both the front and rear brakes at the same time is the safest and most effective stopping strategy.","imageName":"","correctAnswer":26569,"isBookmarked":0,"aid":"7059#7060#7061#26569","text":"Using only the front brake#Using only the rear brake#Alternating between using the front brake and the rear brake#Using both the front and rear brakes at the same time"},
{"testId":371,"qid":14084,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn the driver behind you, flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"8123#10718#10773#10774","text":"Use your high beam#Flash your brake light#Install extra lights#Avoid driving at night"},
{"testId":375,"qid":14092,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Motorcycles need a full lane width to function properly, despite being smaller than vehicles.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":359,"qid":14097,"description":"You should never ride your motorcycle next to a car because:","explanation":"You should not ride your motorbike alongside automobiles since you may be in the blind area of a car.","imageName":"","correctAnswer":11165,"isBookmarked":0,"aid":"11165#29427#29428#29429","text":"you could be in a blind spot#your bike might skid#the car driver will not be able to turn#of some other reason"},
{"testId":359,"qid":14098,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, squeeze the handlegrip in the direction of the turn to lean in that direction, then roll on the throttle to create a correct turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":359,"qid":14099,"description":"When starting a motorcycle uphill, switch to ______ to hold the bike while operating the throttle.","explanation":"When starting a motorbike uphill, you must protect it from rolling backward and maybe colliding with anything. 1) Hold the motorbike with the front brake while you start the engine and transfer into first gear. 2) Apply the back brake to keep the motorbike in place while you engage the throttle. 3) To increase power, slightly open the throttle. 4) Gradually release the clutch. (If the clutch is released too rapidly, the front tire may fall off the ground or the engine may stop.) 5) When the engine starts to slow and the motorbike begins to go forward, release the rear brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#6271#29430","text":"the front brake#the rear brake#both brakes#either the front brake or rear brake"},
{"testId":359,"qid":14100,"description":"What is the most effective braking strategy on a slick surface?","explanation":"On a slippery surface, the most effective braking method is to apply both the front and rear brakes.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#6307","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternately apply the front and rear brakes"},
{"testId":359,"qid":14101,"description":"Head checks________.","explanation":"Before changing lanes, do a head check.","imageName":"","correctAnswer":6342,"isBookmarked":0,"aid":"6339#6340#6341#6342","text":"only need to be done at night#are not required because motorcycles do not have blind spots#only need to be done when preparing for a left turn#should be performed before changing lanes"},
{"testId":359,"qid":14102,"description":"If you cannot avoid an obstacle and must drive over it, approach it at a _____ angle.","explanation":"If you can't avoid an impediment and have to drive over it, approach it head-on, or at a 90-degree angle. This reduces the possibility of losing traction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#10866","text":"45-degree#90-degree#25-degree#135-degree"},
{"testId":359,"qid":14103,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten the bike out. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":29431,"isBookmarked":0,"aid":"10835#10836#22273#29431","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Straighten the bike upright"},
{"testId":367,"qid":14103,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten the bike out. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":29431,"isBookmarked":0,"aid":"10835#10836#22273#29431","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Straighten the bike upright"},
{"testId":359,"qid":14104,"description":"Which of the following will NOT provide your nighttime safety?","explanation":"By making you more visible at night, utilising your high beams will improve your safety. Your safety will enhance by allowing you more time to respond if you raise your following distance and decrease your speed. Moving through the gears fast won't improve your safety.","imageName":"","correctAnswer":24751,"isBookmarked":0,"aid":"22837#22838#24750#24751","text":"Reducing your speed#Increasing your following distance#Using your high beam#Shifting gears quickly"},
{"testId":359,"qid":14105,"description":"If the rear wheel becomes locked, you should:","explanation":"If the back wheel locks, do not try to unlock it. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":24677,"isBookmarked":0,"aid":"6509#6510#6511#24677","text":"release the brake#Grab the front brake#Increase the throttle#not release the brake"},
{"testId":359,"qid":14106,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To avoid mistakenly applying too much throttle, start with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":483,"qid":14106,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To avoid mistakenly applying too much throttle, start with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":359,"qid":14107,"description":"In which of these situations is checking your mirrors the LEAST important?","explanation":"When descending a big slope, it is least vital to check your mirrors.","imageName":"","correctAnswer":24065,"isBookmarked":0,"aid":"6537#6661#24064#24065","text":"Before you change lanes#When you are stopped at an intersection#When you are starting to slow down#When you are descending a large hill"},
{"testId":359,"qid":14108,"description":"You can keep a sufficient spacing cushion by:","explanation":"To avoid risks, alter your lane position as you ride to maintain an acceptable space cushion.","imageName":"","correctAnswer":6541,"isBookmarked":0,"aid":"6541#6543#29432#29433","text":"adjusting your lane position as you ride#always riding in the center lane position#speeding up when you are being tailgated#beeping your horn at close vehicles"},
{"testId":359,"qid":14109,"description":"You may improve your nighttime safety by:","explanation":"You may improve your nighttime safety by extending your following distance to provide yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":359,"qid":14110,"description":"What is the ideal lane position for being seen by the driver of the vehicle ahead of you ?","explanation":"Riding in the center lane places your picture in the center of the car ahead's rear-view mirror. Drivers are more likely to check their rearview mirrors than their side mirrors. Another benefit of driving in the middle lane is that it prevents others from sharing your lane.","imageName":"","correctAnswer":4805,"isBookmarked":0,"aid":"4805#6215#6282#6283","text":"The center#Any#The left#The right"},
{"testId":359,"qid":14111,"description":"Lower your risks of an accident by:","explanation":"You may reduce your risks of an accident by selecting an appropriate lane position, detecting several dangers, and wearing bright apparel to make oneself more visible.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6683#6684#10777#29434","text":"identifying multiple hazards#wearing bright clothing#Doing all of the above#choosing a suitable lane position"},
{"testId":359,"qid":14112,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle to slow down. Do not brake as this may exacerbate the wobbling. Attempting to speed out of a wobble may make the motorbike more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":359,"qid":14113,"description":"When should you use both the front and rear brakes at the same time when riding?","explanation":"Unless you have a flat tire, you should always engage both the front and rear brakes to stop. The most efficient approach to stop is to use both brakes. However, never brake a wheel with a flat tire. In such case, gently apply the opposite brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"6334#22815#25870#29435","text":"Never#On hills and curves#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":359,"qid":14114,"description":"How many lane positions do you have in a single lane of traffic?","explanation":"In a single lane of traffic, there are three lane locations available: 1 (left), 2 (middle), and 3 (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":359,"qid":14115,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":359,"qid":14116,"description":"_____ seconds is the minimum spacing cushion required for safe riding.","explanation":"A three-second following distance is the bare minimum for safe riding.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6469","text":"5#2#3#1"},
{"testId":359,"qid":14117,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"If you can't avoid riding on a slick surface, ride gently and let your feet skim the surface. This will assist to stabilize the motorbike if it starts to tip over.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":359,"qid":14118,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated by someone who is unable to pass you, increase your following distance to provide both of you ample space to stop if you come across a danger.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6286#6392#6920","text":"pull over and stop#Flash your brake light#Call the police#Increase your following distance"},
{"testId":359,"qid":14121,"description":"Which brake is the strongest?","explanation":"Three-quarters of your motorcycle's stopping force is provided by the front brake.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#19944#29437","text":"The front brake#The rear brake#Impossible to tell without more information#Neither (they are equally powerful)"},
{"testId":359,"qid":14122,"description":"What is/are the frequent cause of collisions?","explanation":"Motorcycles are smaller than vehicles and hence more difficult to spot. Not being spotted is a frequent cause of motorbike accidents.","imageName":"","correctAnswer":7170,"isBookmarked":0,"aid":"7169#7170#7171#7172","text":"Traffic signals are#Not being seen is#Seasonal changes are#Bad drivers are"},
{"testId":359,"qid":14124,"description":"When riding with others, you should stay in a single file:","explanation":"When approaching or exiting a roadway, riding bends, or turning in a group, you should ride single file.","imageName":"","correctAnswer":22959,"isBookmarked":0,"aid":"7343#8060#22959#29438","text":"At all times#in rural areas#when entering or leaving a highway#on long stretches of road"},
{"testId":359,"qid":14125,"description":"When should you use the front brake only?","explanation":"To stop, you should normally apply both the front and rear brakes. Never, however, apply the brakes on a flat tire. Apply just the front brake if the back tire is flat.","imageName":"","correctAnswer":29439,"isBookmarked":0,"aid":"6492#7498#10845#29439","text":"At all times#On surfaces with low traction#When stopping quickly#If the rear tire is flat"},
{"testId":69,"qid":14126,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Motorcyclists should avoid gravel roads because the loose sand and gravel give minimal traction.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10588#10589#10590","text":"They do not have posted speed limits#Other drivers are known to drive recklessly#Lane markings are not present#Gravel provides less traction than pavement"},
{"testId":359,"qid":14126,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Motorcyclists should avoid gravel roads because the loose sand and gravel give minimal traction.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10588#10589#10590","text":"They do not have posted speed limits#Other drivers are known to drive recklessly#Lane markings are not present#Gravel provides less traction than pavement"},
{"testId":359,"qid":14128,"description":"Which of the following is the most dangerous to a motorcycle rider?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by drivers.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#6805#26414#26415","text":"Intersections#Rural roads#Freeways#School zones"},
{"testId":359,"qid":14129,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most motorcyclists involved in incidents underbraked the front tire and overbraked the rear, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23077#23078#23079#23080","text":"underbraked the front tire and overbraked the rear#did not separate braking from swerving or who did not swerve when it was appropriate to do so#lacked one of the above skills#lacked none of the above skills"},
{"testId":359,"qid":14131,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7973#22824#29440#29441","text":"you pull the kill engine switch#you think someone may enter your lane without seeing you#you make a left turn#you cross railroad tracks"},
{"testId":359,"qid":14132,"description":"You might need to make some adjustments to your_____  if you are transporting a substantial amount of cargo.","explanation":"If you have a lot of baggage that adds a lot of weight, you may need to modify your suspension.","imageName":"","correctAnswer":8335,"isBookmarked":0,"aid":"8332#8333#8334#8335","text":"handlebars#helmet#Saddlebags#suspension"},
{"testId":359,"qid":14133,"description":"A motorcycle has a narrower profile than a car. Therefore,:","explanation":"Because a motorbike has a lower profile than a vehicle, it is less noticeable. When riding a motorbike, use caution.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":359,"qid":14134,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#29442","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders only"},
{"testId":379,"qid":14134,"description":"Eye protection with a tint:","explanation":"Tinted eye protection should not be used at night since it makes visibility worse.","imageName":"","correctAnswer":8012,"isBookmarked":0,"aid":"8010#8011#8012#29442","text":"should be worn at all times#is mandatory during the day#should not be worn at night#is mandatory for riders only"},
{"testId":359,"qid":14135,"description":"Cross railroad rails at a 45-degree angle or more because:","explanation":"Cross railroad tracks at a 45-degree angle to avoid snagging your tires on the tracks. If you cross at a smaller angle, you may lose your equilibrium or topple over.","imageName":"","correctAnswer":29444,"isBookmarked":0,"aid":"10314#29443#29444#29445","text":"a larger angle would be unsafe#it will give you the best view of oncoming trains#at a smaller angle, you may catch your tires#it will allow you to cross the tracks quickly"},
{"testId":359,"qid":14137,"description":"After how many drinks does your body start to feel the effects of the alcohol?","explanation":"Alcohol may have an effect on your health even after only one drink. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#5000#10514","text":"Two#Four#Six#One"},
{"testId":359,"qid":14138,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright attire, picking the ideal lane location, and utilizing your headlight may all help you be noticed.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#26559","text":"Use your headlight#Wear bright clothing#Do all of the above#Choose a suitable lane position"},
{"testId":359,"qid":14139,"description":"How can you improve your visibility when approaching a blind intersection?","explanation":"When approaching a blind junction, ride in the lane position that allows you to be spotted as soon as feasible.","imageName":"","correctAnswer":29446,"isBookmarked":0,"aid":"10718#24901#24902#29446","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Ride in the lane position that lets you be seen earliest"},
{"testId":359,"qid":14140,"description":"When riding a curve, the best place to be in a lane is:","explanation":"When riding a curve, the best lane position will alter during the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":359,"qid":14142,"description":"Your passenger's feet should remain on the foot pegs:","explanation":"Passengers should always keep their feet on the footpegs. They do not need to put their feet down at junctions, unlike motorcyclists.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#7344#28932#28933","text":"At all times#while the motorcycle is moving#unless you are riding over a slippery surface#unless you are stopped at an intersection"},
{"testId":359,"qid":14144,"description":"How do you ride safely on slick terrain?","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23103#26561#28911","text":"Increase your speed to maintain balance#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane#Lean in the opposite direction of the motorcycle during turns"},
{"testId":379,"qid":14153,"description":"The most likely cause of a collision with another vehicle is:","explanation":"At a junction, you have the highest probability of colliding with another car.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":392,"qid":14174,"description":"Your feet should ________ while riding.","explanation":"Your feet should be firmly planted on the footpegs when riding.","imageName":"","correctAnswer":29460,"isBookmarked":0,"aid":"6176#7735#10803#29460","text":"as far forward as possible#hovering above the ground in case you tip#pointing down for easy shifting#positioned firmly on the footpegs"},
{"testId":78,"qid":14180,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#8102#24703#28221","text":"ride under 30 mph#ride in residential areas only#ride only in first gear#you must not ride a motorcycle"},
{"testId":379,"qid":14180,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#8102#24703#28221","text":"ride under 30 mph#ride in residential areas only#ride only in first gear#you must not ride a motorcycle"},
{"testId":400,"qid":14192,"description":"Using _____ is the best way to secure cargo on a motorcycle.","explanation":"Elastic ropes are the finest method to secure baggage to a motorbike. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":8225,"isBookmarked":0,"aid":"8225#23057#23059#29477","text":"Elastic cords#strong rope#strips of cloth#cable ties"},
{"testId":363,"qid":14202,"description":"When your bike is parked next to a curb, it should be:","explanation":"When parked next to a curb, put your motorbike at an angle with the back wheel towards the curb. (Note: Some localities require motorbikes to be parked parallel to the curb.)","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28984#28985#30027","text":"parallel to the curb#facing the curb with the front wheel touching the curb#lying on the curb#positioned at an angle with the rear wheel to the curb"},
{"testId":367,"qid":14209,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Avoid braking as this may exacerbate the wobbling. Attempting to speed out of a wobble may cause the motorbike to become even more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6725#6726#6727","text":"Open the throttle slowly#Apply the rear brake only#Apply the front brake only#Close the throttle slowly"},
{"testId":375,"qid":14210,"description":"What is the most secure way of braking?","explanation":"Using both the front and rear brakes at the same time is the safest and most effective stopping strategy.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6305#30030#30031#30032","text":"Use both the front and rear brakes at the same time#Use only the front brake#Use only the rear brake#Alternate between using the front brake and the rear brake"},
{"testId":67,"qid":14214,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"A motorbike has three lane locations in a single lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":404,"qid":14219,"description":"Changing to a lower gear to slow down is known as :","explanation":"Shifting into a lower gear has the same effect as braking. This is referred to as engine braking. Downshift one gear at a time and ease out the clutch through the friction zone between each downshift to apply engine braking. Maintain the clutch in the friction zone until the engine speed is stable. Then, completely extend the lever until you are ready for the next downshift.","imageName":"","correctAnswer":23067,"isBookmarked":0,"aid":"8626#23067#23068#23069","text":"None of the above#engine braking#ABS braking#quick braking"},
{"testId":367,"qid":14220,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":371,"qid":14221,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"Look 12 seconds ahead for hazards and possible threats while scanning the road. 12 seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":404,"qid":14227,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers may not be able to see you from certain regions.","imageName":"","correctAnswer":27941,"isBookmarked":0,"aid":"7352#7353#24647#27941","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicles will have difficulty seeing you in certain areas"},
{"testId":383,"qid":14241,"description":"When correctly seated on a motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly sitting on a motorbike. This gives you the greatest power.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6826#6827#6829#22958","text":"positioned with your elbows locked#positioned with your hands above your elbows#bent slightly#At a 90-degree angle"},
{"testId":363,"qid":14246,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, squeeze the handgrip to lean in the direction you want to go, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":400,"qid":14246,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, squeeze the handgrip to lean in the direction you want to go, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":73,"qid":14274,"description":"Because motorcycle_____________, it is less noticeable than a car.","explanation":"Because of its lower profile, a motorbike is less noticeable than a vehicle. Take additional precautions to be visible when riding.","imageName":"","correctAnswer":7728,"isBookmarked":0,"aid":"7727#7728#7729#28949","text":"has a quieter engine#has a smaller profile#does not have as many lights#travels faster than a car"},
{"testId":371,"qid":14279,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":379,"qid":14284,"description":"When riding a motorcycle, you mostly use your arms to:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#7050#20973#29363","text":"holding yourself up#hand signals#steering#braking"},
{"testId":388,"qid":14284,"description":"When riding a motorcycle, you mostly use your arms to:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#7050#20973#29363","text":"holding yourself up#hand signals#steering#braking"},
{"testId":66,"qid":14292,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use the flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#24747#24748","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#pull the kill engine switch, and roll to a stop#use both brakes, and stop on the shoulder of the road"},
{"testId":363,"qid":14296,"description":"When ready to pass another vehicle, ride in _________ lane position.","explanation":"You should ride in the left lane while prepared to pass a car in front of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":75,"qid":14297,"description":"Which lane position should you ride in when another vehicle passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":363,"qid":14297,"description":"Which lane position should you ride in when another vehicle passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":400,"qid":14297,"description":"Which lane position should you ride in when another vehicle passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":363,"qid":14298,"description":"Allow other drivers to realize that you are slowing down by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly important when slowing suddenly or in unexpected regions.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#6237","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#Using signal lights"},
{"testId":363,"qid":14299,"description":"Which lane location is optimal for being seen by the driver of the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. Drivers scan their rearview mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":66,"qid":14301,"description":"For the most stable riding, position your knees and feet__________.","explanation":"To ensure the most stable riding, set your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"7230#7231#30058#30059","text":"against the gas tank and pointing down#away from the gas tank and pointing down#away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests"},
{"testId":363,"qid":14301,"description":"For the most stable riding, position your knees and feet__________.","explanation":"To ensure the most stable riding, set your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"7230#7231#30058#30059","text":"against the gas tank and pointing down#away from the gas tank and pointing down#away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests"},
{"testId":367,"qid":14301,"description":"For the most stable riding, position your knees and feet__________.","explanation":"To ensure the most stable riding, set your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"7230#7231#30058#30059","text":"against the gas tank and pointing down#away from the gas tank and pointing down#away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests"},
{"testId":363,"qid":14302,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a complete stop on a curve, attempt to straighten the motorbike as much as possible. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":30060,"isBookmarked":0,"aid":"10835#10836#22273#30060","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Straighten the motorcycle upright"},
{"testId":363,"qid":14303,"description":"The gearshift lever can be found :","explanation":"The shifting lever is situated just in front of the left footrest.","imageName":"","correctAnswer":6980,"isBookmarked":0,"aid":"6979#6980#24908#24909","text":"in front of the right footrest#in front of the left footrest#by the left handgrip#by the right handgrip"},
{"testId":367,"qid":14303,"description":"The gearshift lever can be found :","explanation":"The shifting lever is situated just in front of the left footrest.","imageName":"","correctAnswer":6980,"isBookmarked":0,"aid":"6979#6980#24908#24909","text":"in front of the right footrest#in front of the left footrest#by the left handgrip#by the right handgrip"},
{"testId":363,"qid":14305,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"In any accident, wearing 1) a U.S. DOT-compliant motorcycle safety helmet; 2) face or eye protection; and 3) protective gear such as a leather or long-sleeved jacket, long, heavy trousers over ankle boots, and full-fingered leather gloves gives you a significantly higher chance of avoiding catastrophic harm.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10841#10842#10843","text":"All of the above#a motorcycle safety helmet that is U.S. DOT compliant#face or eye protection#protective clothing"},
{"testId":363,"qid":14306,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissy bar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22289,"isBookmarked":0,"aid":"22289#22290#22291#22292","text":"Fastening excess cargo to the sissy bar#Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords"},
{"testId":400,"qid":14306,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the center of gravity, attaching baggage to the sissy bar may make the motorbike unsteady. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22289,"isBookmarked":0,"aid":"22289#22290#22291#22292","text":"Fastening excess cargo to the sissy bar#Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords"},
{"testId":363,"qid":14307,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them as you ride.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":363,"qid":14308,"description":"At a stop sign, your motorcycle should remain in:","explanation":"When you come to a complete stop at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#10441#10442","text":"neutral#First gear#second gear#third gear"},
{"testId":363,"qid":14309,"description":"A motorcycle usually necessitates:","explanation":"Motorcycles normally need the same stopping distance as vehicles. Make sure to give enough room for expansion.","imageName":"","correctAnswer":24606,"isBookmarked":0,"aid":"24606#24607#24608#24609","text":"the same distance to stop as a car does#more distance to stop than a car does#less distance to stop than a car does#more room to operate safely than a car does"},
{"testId":363,"qid":14311,"description":"When you load saddlebags, you should:","explanation":"To maintain the motorbike balanced, fill saddlebags equally and evenly with the same weight.","imageName":"","correctAnswer":6814,"isBookmarked":0,"aid":"6814#24840#26463#30061","text":"load them evenly with the same weight#not exceed 20 pounds per bag#fill both of them completely#fill one completely before loading the other one"},
{"testId":363,"qid":14312,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6941#10572#10573#30062","text":"wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles#avoid riding in heavy traffic"},
{"testId":438,"qid":14312,"description":"To make it more likely that other vehicles will see you, you should:","explanation":"Wear fluorescent apparel to boost your chances of being spotted by another vehicle.","imageName":"","correctAnswer":6941,"isBookmarked":0,"aid":"6941#10572#10573#30062","text":"wear reflective clothing#Ride only during the day#ride in areas where there are lots of motorcycles#avoid riding in heavy traffic"},
{"testId":363,"qid":14313,"description":"The most effective way of braking is to:","explanation":"Using both the front and rear brakes at the same time is the most effective braking strategy.","imageName":"","correctAnswer":30063,"isBookmarked":0,"aid":"6244#6417#10748#30063","text":"use the rear brake only#Use the front brake only#alternate between the rear brake and front brake#use both the front and rear brakes at the same time"},
{"testId":363,"qid":14314,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another vehicle may attempt to share your lane, ride in the middle lane to give that car extra space.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":363,"qid":14315,"description":"If two or more motorcycles are riding together, they should ride:","explanation":"When travelling with two or more motorcycles, they should ride in a staggered manner. This permits the group to be close while yet having some breathing room.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":68,"qid":14317,"description":"When approaching an blind intersection with a stop line, you should stop:","explanation":"You must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24766#24768#30064","text":"at the stop line only#where you have the most visibility#with the front wheel over the stop line#at the stop line first, then edge forward"},
{"testId":363,"qid":14317,"description":"When approaching an blind intersection with a stop line, you should stop:","explanation":"You must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24766#24768#30064","text":"at the stop line only#where you have the most visibility#with the front wheel over the stop line#at the stop line first, then edge forward"},
{"testId":396,"qid":14317,"description":"When approaching an blind intersection with a stop line, you should stop:","explanation":"You must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24766#24768#30064","text":"at the stop line only#where you have the most visibility#with the front wheel over the stop line#at the stop line first, then edge forward"},
{"testId":408,"qid":14317,"description":"When approaching an blind intersection with a stop line, you should stop:","explanation":"You must first come to a complete stop at the stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":30064,"isBookmarked":0,"aid":"6652#24766#24768#30064","text":"at the stop line only#where you have the most visibility#with the front wheel over the stop line#at the stop line first, then edge forward"},
{"testId":363,"qid":14318,"description":"If you lock the rear brake on a traction-rich surface,:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop. If, on the other hand, you lock the front wheel, you must instantly release the front brake.","imageName":"","correctAnswer":7388,"isBookmarked":0,"aid":"7386#7388#7389#24659","text":"you should release the rear brake and reapply it#you can keep it locked until you come to a complete stop#release both brakes and coast to a stop#release the rear brake and use only the front brake"},
{"testId":363,"qid":14319,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If an unanticipated obstruction forces you to veer left, use the left handgrip. This will force the motorbike to rapidly tilt to the left.","imageName":"","correctAnswer":24804,"isBookmarked":0,"aid":"6423#6569#24803#24804","text":"Apply the rear brake#Apply the front brake#press the right handgrip#press the left handgrip"},
{"testId":363,"qid":14320,"description":"You should _______, when braking on slick conditions.","explanation":"You should apply both brakes while stopping on slick conditions. It is always preferable to utilize both brakes.","imageName":"","correctAnswer":7397,"isBookmarked":0,"aid":"6244#6417#7397#30065","text":"use the rear brake only#Use the front brake only#use both brakes#coast in neutral"},
{"testId":363,"qid":14321,"description":"Why do motorcycle accidents happen so often when there are curves?","explanation":"Curves are a typical location for motorcycle accidents since bikes tend to drive wide on a curve and may end up in another lane if the rider is not cautious.","imageName":"","correctAnswer":28436,"isBookmarked":0,"aid":"7526#28436#28437#28438","text":"It is difficult to steer a motorcycle#A motorcycle may run wide in a curve if the motorcyclist isn't careful#Leaning in a curve causes a motorcycle to tip over#A motorcyclist can't stay in one lane position while moving through a curve"},
{"testId":363,"qid":14323,"description":"What should you do if the throttle is stuck and turning it back and forth does not dislodge it?","explanation":"If the throttle is stuck and twisting it back and forth does not release it, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":28993,"isBookmarked":0,"aid":"22924#22926#22927#28993","text":"Stop the motorcycle using the rear brake only#Jump off the motorcycle#Pull in the clutch#Operate the engine cut-off switch and pull in the clutch at the same time"},
{"testId":363,"qid":14324,"description":"What should you do to start your motorcycle on an upgrade?","explanation":"Use the rear brake to restrain you until the engine takes hold while starting your motorbike on an upgrade.","imageName":"","correctAnswer":30066,"isBookmarked":0,"aid":"10763#10765#30066#30067","text":"Position yourself at a 90-degree angle against the curb#Start in second gear#Use the rear brake to hold you until the engine takes hold#Never try to start a motorcycle on an upgrade"},
{"testId":363,"qid":14325,"description":"When riding in the city, look for risks at least ________ ahead of time.","explanation":"When cycling in the city, you should look at least one-half block ahead for potential risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":363,"qid":14326,"description":"Which lane positions are the most secure for motorcycles?","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":363,"qid":14327,"description":"When riding in a group,:","explanation":"Don't tailgate the cyclist in front of you while riding in a group (or anyone else for that matter). Maintain a proper spacing cushion at all times. Ride toward the front of the group if you want to go quicker.","imageName":"","correctAnswer":24628,"isBookmarked":0,"aid":"24626#24627#24628#24629","text":"ride in pairs whenever possible#put the newest riders at the rear#don't tailgate the rider in front of you#never ride in groups of more than three"},
{"testId":363,"qid":14328,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent mistakenly applying too much throttle, start your motorbike with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":363,"qid":14332,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"The time of day has no bearing on your blood alcohol levels (BAC). At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22833#22834#22880#30048","text":"Your weight#How fast you drink#The time of day#The amount of alcohol you consume"},
{"testId":363,"qid":14333,"description":"Since a motorcycle has a smaller profile than a car,:","explanation":"A motorbike is less noticeable than a vehicle because of its lower profile. When operating a motorbike, use caution.","imageName":"","correctAnswer":8375,"isBookmarked":0,"aid":"8375#8376#8377#8378","text":"it is less visible#it is faster#shoulder checks are not required#special lights are required"},
{"testId":363,"qid":14335,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#30070","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traf铿乧, however, remain in your lane"},
{"testId":363,"qid":14336,"description":"Which of the following check is required of your motorcycle before embarking on a trip?","explanation":"Before each ride, inspect the tires, clutch, throttle, and brakes for safety. Inspect the fluids, lights, mirrors, horn, and fuel supply valve as well.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25491#27169#27170","text":"All of the above#The tires#The fluids#The signal lights"},
{"testId":363,"qid":14339,"description":"The easiest strategy to reduce reaction time when approaching an intersection is to:","explanation":"Covering the clutch and braking is the greatest strategy to decrease response time in a dangerous circumstance, such as an intersection.","imageName":"","correctAnswer":30071,"isBookmarked":0,"aid":"7537#7538#7540#30071","text":"Keep the clutch pulled in when not accelerating#Avoid wearing a full-face helmet#Avoid using the highest gear#cover the clutch and brake"},
{"testId":363,"qid":14341,"description":"If your motorcycle does not have a windshield, you should :","explanation":"This is a deceptive question. Whether or whether the motorbike has a windshield, both the rider and any passengers (if any) should wear safety goggles or a face shield. A windshield is not a replacement for a face protection or goggles. Windshields do not shelter your sight from the elements.","imageName":"","correctAnswer":10456,"isBookmarked":0,"aid":"8194#10456#30072#30073","text":"Avoid carrying passengers#wear safety goggles or a face shield#have your motorcycle fitted with a windshield before you ride it#ride only on paved roads"},
{"testId":363,"qid":14344,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"If you cannot escape an impediment and must ride over it, approach it head-on (i.e., at a 90-degree angle). This will prevent your front wheel from being deflected by the obstruction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10676#10677","text":"45-degree#20-degree#100-degree#90-degree"},
{"testId":363,"qid":14345,"description":"To turn your motorcycle, you should press on the handgrip in the direction you want to go and:","explanation":"To turn your motorbike, push the handgrip in the direction of the turn and lean in the same way.","imageName":"","correctAnswer":6907,"isBookmarked":0,"aid":"6582#6905#6907#30074","text":"Pull in the clutch#keep your body straight#lean in the direction of the turn#lean opposite to the direction of the turn"},
{"testId":367,"qid":14351,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must protect it from rolling backward and maybe colliding with anything. 1) Hold the motorbike with the front brake while you start the engine and transfer into first gear. 2) Apply the back brake to keep the motorbike in place while you engage the throttle. 3) To increase power, slightly open the throttle. 4) Gradually release the clutch. (If the clutch is released too rapidly, the front tire may fall off the ground or the engine may stop.) 5) When the engine starts to slow and the motorbike begins to go forward, release the rear brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#6271#29430","text":"the front brake#the rear brake#both brakes#either the front brake or rear brake"},
{"testId":425,"qid":14353,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of it might aggravate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":375,"qid":14355,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"To stop, utilize both the front and back brakes. The most efficient approach to stop is to use both brakes. Never, however, brake a wheel that has a flat tire. If you know which tire is flat, gently apply the other wheel's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"6334#22814#22815#25870","text":"Never#In emergencies only#On hills and curves#At all times, unless you have a flat tire"},
{"testId":375,"qid":14356,"description":"What should you do if you can't avoid cycling on a slick surface?","explanation":"If you can't avoid riding on a slick surface, ride as gently as you can. Allow your feet to glide over the surface. This will help to stabilize the motorcycle if it begins to tip over.","imageName":"","correctAnswer":10871,"isBookmarked":0,"aid":"10871#10872#24723#29436","text":"Travel as slowly as possible and let your feet skim the surface#Walk your motorcycle across it#Relax and maintain your speed#Ride in the slipperiest part of the lane"},
{"testId":69,"qid":14365,"description":"When should you use the front brake only?","explanation":"Use just the front brake if the back tire is flat. Never use a flat tire's brake.","imageName":"","correctAnswer":29439,"isBookmarked":0,"aid":"6492#7498#10845#29439","text":"At all times#On surfaces with low traction#When stopping quickly#If the rear tire is flat"},
{"testId":70,"qid":14367,"description":"To improve your nighttime safety, you should:","explanation":"You may improve your nighttime safety by slowing down, utilizing your high-beam headlight, and increasing your following distance.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6920#8535#10656#22923","text":"Increase your following distance#Reduce your speed#Do all of the above#Use your high beam"},
{"testId":375,"qid":14370,"description":"Which of these is NOT a result of drinking alcohol?","explanation":"Alcohol may dull judgement, slow response times, diminish awareness, and affect eyesight. Alcohol may affect your coordination, eyesight, balance, and all other critical mental and physical abilities required for safe motorcycle riding after only one drink.","imageName":"","correctAnswer":10040,"isBookmarked":0,"aid":"10040#25449#25846#25848","text":"Increased alertness#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":371,"qid":14390,"description":"Increase your exposure at a crossroads by:","explanation":"At a junction, increase your visibility by selecting the most visible lane position so that cars may see you.","imageName":"","correctAnswer":7081,"isBookmarked":0,"aid":"7078#7079#7080#7081","text":"coming to a complete stop#riding in the staggered formation#looking in your mirrors#choosing the most visible lane position"},
{"testId":400,"qid":14430,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":375,"qid":14474,"description":"You may share a lane safely with:","explanation":"A motorbike needs a full lane width to navigate safely. Never, ever share your lane.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30482#30483","text":"no one#other motorcycles only#motorcycles and small cars#all other vehicles"},
{"testId":379,"qid":14494,"description":"Reflective apparel is essential for safety:","explanation":"Reflective apparel is essential for safety both during the day and at night. Always wear bright, luminous clothes.","imageName":"","correctAnswer":28958,"isBookmarked":0,"aid":"6350#7087#7089#28958","text":"at night#in rural areas only#in urban areas#in day and night"},
{"testId":367,"qid":14496,"description":"When driving behind an automobile, you should ride in the ________ so the driver can see you.","explanation":"When following a vehicle, you should ride in the middle lane. This should position your picture in the center of the rear-view mirror. Drivers scan their rearview mirrors more often than their side mirrors.","imageName":"","correctAnswer":30491,"isBookmarked":0,"aid":"30489#30490#30491#30492","text":"right lane position#driver's blind spot#center lane position#left lane position"},
{"testId":367,"qid":14498,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, push the handgrip in the direction of the turn to lean in that direction, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":367,"qid":14499,"description":"To swerve right, you have to:","explanation":"To swerve correctly, you must first brake and then swerve. Avoid braking while swerving, since this may cause the motorbike to slide.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#7330#7331","text":"Brake while swerving#Brake before or after swerving#Brake after swerving#Brake before swerving"},
{"testId":367,"qid":14501,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, being visible to others, and communicating your intentions to other vehicle operators can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6444#6445#10777","text":"maintaining an adequate space cushion#being visible#communicating your intentions#Doing all of the above"},
{"testId":367,"qid":14502,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":367,"qid":14503,"description":"When someone from an on-ramp is moving into your lane, you should:","explanation":"When someone merges into your lane from an on-ramp, shift lanes to give the vehicle enough space to join.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6475#6476","text":"Change lanes#Beep your horn#Speed up#Slow down"},
{"testId":367,"qid":14506,"description":"You should park on the roadside at a _________ angle, with your _____ tire contacting the curb.","explanation":"You should pull over to the side of the road and park with your back tyre on the curb at a 90-degree angle.","imageName":"","correctAnswer":26106,"isBookmarked":0,"aid":"26106#26107#26108#26109","text":"90-degree; rear#90-degree; front#45-degree; rear#45-degree; front"},
{"testId":367,"qid":14509,"description":"You should _____ when transporting a burden.","explanation":"To keep your motorbike balanced when carrying a load, spread the weight equally.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":388,"qid":14509,"description":"You should _____ when transporting a burden.","explanation":"To keep your motorbike balanced when carrying a load, spread the weight equally.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":367,"qid":14510,"description":"Which lane position should you ride in to prevent a collision with a hazard in the center of the lane?","explanation":"To prevent a collision with a danger in the middle of the lane, ride on the left or right side of the road.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":367,"qid":14511,"description":"When are you allowed to ride beside another motorcycle?","explanation":"Despite being smaller than vehicles, motorbikes nevertheless require the whole lane to function properly. As a result, riding beside another motorbike is never safe.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8034#24682#24684","text":"Never#When you are riding in a group#When traffic is heavy#When you are riding with a new rider"},
{"testId":367,"qid":14517,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another vehicle may attempt to share your lane, ride in the middle lane to give that car extra space.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":367,"qid":14519,"description":"Which of the following statements is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#30070","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traf铿乧, however, remain in your lane"},
{"testId":367,"qid":14521,"description":"How should you cross railroad tracks that are parallel to your course if you need to do so?","explanation":"Crossing railroad lines that run parallel to your path should be done at an angle of at least 45 degrees. Crossing at a smaller angle may result in your tires catching on the tracks.","imageName":"","correctAnswer":10575,"isBookmarked":0,"aid":"7304#10575#10576#30493","text":"Wait until the next intersection#Cross the tracks at a 45-degree angle#Cross the tracks at a 90-degree angle#Cross the tracks at the smallest angle possible"},
{"testId":367,"qid":14522,"description":"Which of the following is NOT necessary for a motorcycle passenger?","explanation":"Passenger equipment should contain the following: 1) A seat that is spacious enough to accommodate both of you without crowding. You shouldn't have to sit any more forward than normal. 2) A passenger's footrest. 3) The same protective equipment that is suggested for operators.","imageName":"","correctAnswer":23794,"isBookmarked":0,"aid":"7356#11031#23793#23794","text":"Passenger footrests#Designated seat room#A helmet and eye protection#A backpack"},
{"testId":367,"qid":14523,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. No lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":367,"qid":14524,"description":"You're turning right onto a road with one traffic lane going either way. Taking a right turn too quickly could cause:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane.","imageName":"","correctAnswer":30495,"isBookmarked":0,"aid":"30494#30495#30496#30497","text":"you might tip over#you might veer into the opposing lane#you might distract other vehicle operators#your motorcycle might break down"},
{"testId":367,"qid":14525,"description":"Which of the following is prohibited when riding downhill?","explanation":"Coasting in neutral is prohibited per Iowa Code 321.365.","imageName":"","correctAnswer":17394,"isBookmarked":0,"aid":"7466#7468#7469#17394","text":"Shifting gears#Increasing your speed#Slowing down#Coasting in neutral"},
{"testId":367,"qid":14527,"description":"If you cannot avoid an obstacle and must drive over it, approach it at a _____ angle.","explanation":"If you can't avoid an impediment and have to ride over it, approach it head-on, or at a 90-degree angle. This will assist prevent it from deflecting the front tire.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10866#30078","text":"45-degree#90-degree#135-degree#120-degree"},
{"testId":367,"qid":14529,"description":"The best way around a curve:","explanation":"The optimum way across a curve may not match the road's curvature. Change your lane position as necessary to assist you.","imageName":"","correctAnswer":7829,"isBookmarked":0,"aid":"7828#7829#7830#28994","text":"is on the inside of the curve#may not follow the curve of the road#is on the outside of the curve#is to stay in the center lane position"},
{"testId":367,"qid":14530,"description":"Which of these is NOT a result of drinking alcohol?","explanation":"Alcohol may affect judgement, diminish attentiveness, slow response times, and impair eyesight. Alcohol may affect your coordination, eyesight, balance, and all other critical mental and physical abilities required for safe motorcycle riding after only one drink.","imageName":"","correctAnswer":10040,"isBookmarked":0,"aid":"10040#25449#30498#30499","text":"Increased alertness#Impaired vision#Increased reaction time#Impaired judgment"},
{"testId":367,"qid":14531,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground and you can reach the handgrips and footrests, your motorbike is a good match for you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"7893#22993#22996#30500","text":"you can reach the handgrips#both of your feet can reach the ground while you are seated#you can do all of the above#you can reach the footrests"},
{"testId":367,"qid":14532,"description":"When riding over obstacles, _________ helps you absorb shock.","explanation":"Raise your seat slightly to lessen impact while riding over obstacles. It permits your elbows and knees to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":367,"qid":14533,"description":"What can you do to maintain your balance during a turn?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#10712#10713#10714","text":"Keep your knees against the gas tank#Crouch down low#Pull in the clutch and coast#Slow down before the turn"},
{"testId":367,"qid":14535,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"AVOID using ropes to secure items to a motorbike. Ropes may weaken and knots can come undone over time. Elastic cords are a superior alternative.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":367,"qid":14538,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"Before entering a corner, change gears. A abrupt shift in power to the rear wheel during the turn might cause a skid.","imageName":"","correctAnswer":7054,"isBookmarked":0,"aid":"6467#7054#8316#30501","text":"During the turn#Before entering the turn#After entering the turn#At any point before, during, or after the turn"},
{"testId":367,"qid":14539,"description":"When riding a motorcycle, you mostly use your arms to:","explanation":"When riding a motorbike, your arms are mostly utilized for steering, not for support.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#30502","text":"holding yourself up#steering#braking#giving hand signals"},
{"testId":367,"qid":14540,"description":"You can ride safely on slick roads by:","explanation":"On slick conditions, you may ride securely by slowing down and avoiding rapid movements that might result in loss of control.","imageName":"","correctAnswer":8294,"isBookmarked":0,"aid":"8291#8292#8293#8294","text":"maintaining or increasing your speed#holding in the clutch#leaning back#avoiding sudden moves"},
{"testId":367,"qid":14541,"description":"Which of the following drugs can affect your ability to ride?","explanation":"Illegal, prescription, and over-the-counter substances may all impair your riding skills.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8240#8241#30036","text":"All of the above#Illegal#Prescription#Over-the-counter"},
{"testId":367,"qid":14542,"description":"Grabbing the front brake can lead to:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":30460,"isBookmarked":0,"aid":"8304#8305#30459#30460","text":"stopping quickly#popping a tire#a rear-wheel lock#a front-wheel lock"},
{"testId":69,"qid":14565,"description":"After ingesting alcohol, you may ensure that you will not be driving under the influence if you:","explanation":"The more alcohol that enters your body to get you drunk, the longer it takes your liver to break it down. One typical drink (one ounce of hard liquor, one-half glass of wine, or a 12-ounce can of beer) may be broken down by the liver in one hour. If you drink a lot of alcohol, it might take up to a day or two for your body to completely recover.","imageName":"","correctAnswer":39256,"isBookmarked":0,"aid":"7492#7494#10585#39256","text":"wait at least 30 minutes#wait at least an hour#were drinking beer or wine, but not hard liquor#wait for a day or two"},
{"testId":511,"qid":14592,"description":"What could be causing your motorcycle to lurch?","explanation":"If you move into a lower gear too quickly, your motorbike may lurch. Instead, slow down to the right pace for the lower gear you're going to change into.","imageName":"","correctAnswer":7602,"isBookmarked":0,"aid":"7602#8170#8172#30519","text":"Shifting into a lower gear too soon#Speeding up too quickly#Turning too fast#Riding over gravel roads"},
{"testId":379,"qid":14635,"description":"When you turn on your motorcycle, you right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#28226#30087","text":"flat, so you won't use too much throttle#high, to use the throttle the most#low, to increase control#comfortably"},
{"testId":375,"qid":14639,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. Ride with care at all times.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":371,"qid":14642,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before beginning a vacation, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7949#7952#23025#30038","text":"Skidding the front tire#Being low on oil#Braking with only one brake#Shifting gears improperly"},
{"testId":71,"qid":14653,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"When you wear 1) a motorcycle safety helmet that is US DOT compliant; 2) face or eye protection; and 3) protective clothing such as a leather or long-sleeved jacket, long, heavy pants over ankle boots, and full-fingered leather gloves, you have a far better chance of avoiding serious injury in any collision.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":392,"qid":14653,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"When you wear 1) a motorcycle safety helmet that is US DOT compliant; 2) face or eye protection; and 3) protective clothing such as a leather or long-sleeved jacket, long, heavy pants over ankle boots, and full-fingered leather gloves, you have a far better chance of avoiding serious injury in any collision.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":511,"qid":14653,"description":"When you wear _______ in a crash, you have a far greater chance of avoiding major injuries.","explanation":"When you wear 1) a motorcycle safety helmet that is US DOT compliant; 2) face or eye protection; and 3) protective clothing such as a leather or long-sleeved jacket, long, heavy pants over ankle boots, and full-fingered leather gloves, you have a far better chance of avoiding serious injury in any collision.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10842#10843#29484","text":"All of the above#face or eye protection#protective clothing#a motorcycle safety helmet that is US DOT compliant"},
{"testId":68,"qid":14664,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"The fluid levels in your motorbike should be checked once a week.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":379,"qid":14674,"description":"If you _____, the motorcycle's engine may lug.","explanation":"You should shift up quickly enough to prevent the engine from overrevving as your motorbike gains speed, but not so quickly that the engine starts to lug. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":30800,"isBookmarked":0,"aid":"30798#30799#30800#30801","text":"Shift into a lower gear too soon#Lean while turning#Shift into a higher gear too soon#Carry cargo on the sissy bar"},
{"testId":491,"qid":14683,"description":"Maximum braking in a straight line is obtained by:","explanation":"Applying both the front and rear brakes without locking the wheels results in maximum straight-line braking.","imageName":"","correctAnswer":22932,"isBookmarked":0,"aid":"8278#22808#22932#23053","text":"pulling in the clutch#applying both brakes until the wheels lock#applying both brakes without locking the wheels#applying only the front brake"},
{"testId":371,"qid":14697,"description":"When riding a motorcycle on a slippery surface, you should _____ to keep from skidding.","explanation":"If you are riding on slick pavement, you should slow down to prevent a skid.","imageName":"","correctAnswer":757,"isBookmarked":0,"aid":"757#6142#22864#29448","text":"slow down#Accelerate#lean your motorcycle in any direction#shift into a higher gear"},
{"testId":371,"qid":14698,"description":"When should you share a lane with another vehicle?","explanation":"Never drive in the same lane as another car. To function properly, a motorbike needs the whole width of the lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#8034#30807","text":"Never#In heavy traffic#When you are riding in a group#In a designated area"},
{"testId":371,"qid":14699,"description":"To transport cargo in saddlebags,:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags. A motorbike that is imbalanced may slide or wobble.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":371,"qid":14700,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"When cycling on an uneven terrain, rise slightly off the seat to absorb additional stress.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6308#6309#6310","text":"Swerving from side to side#applying the brake lightly#rising slightly off the seat#taking your feet off the footrests"},
{"testId":371,"qid":14701,"description":"If you slow down unexpectedly, you should:","explanation":"Tap the brake pedal to flash your brake light to alert cars that you are slowing down in an unexpected location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6278#6286#6408#6409","text":"Beep your horn#Flash your brake light#change lanes before slowing down#shoulder check your blind spot"},
{"testId":371,"qid":14702,"description":"What should you do if you come to a stop on a curve?","explanation":"When coming to a halt on a curve, attempt to keep your motorbike upright. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"10835#10836#22273#30098","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten your motorcycle upright"},
{"testId":85,"qid":14703,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":371,"qid":14703,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":379,"qid":14703,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":388,"qid":14703,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":416,"qid":14703,"description":"How any lane positions are there in each traffic lane, where a motorcycle can travel?","explanation":"A motorbike may drive in one of three lane locations in each traffic lane: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":371,"qid":14704,"description":"When riding through a curve, you should ________","explanation":"Your lane position should alter as you ride over a curve to improve your line of sight, reduce the possibility of going wide, and perhaps drifting into another lane.","imageName":"","correctAnswer":30808,"isBookmarked":0,"aid":"6567#11160#24799#30808","text":"stay as close to the center line as possible#stay as close to the edge of the road as possible#Ride in the center lane position#vary your lane position depending on conditions"},
{"testId":371,"qid":14705,"description":"You should _____ to carry a load.","explanation":"To keep the motorbike balanced when carrying a load, spread the weight equally.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":371,"qid":14706,"description":"Because a motorcycle brake light is not as apparent as a car brake light, you should :","explanation":"You should flash your brake light anytime you believe it may not be noticed since a motorcycle's brake light is less noticeable than the brake lights on a vehicle.","imageName":"","correctAnswer":30809,"isBookmarked":0,"aid":"6795#6796#6798#30809","text":"beep your horn whenever you're about to stop#install extra lights on your motorcycle#only brake when absolutely necessary#flash your brake light whenever you think it may not be seen"},
{"testId":371,"qid":14707,"description":"To park next to a curb, you must park such that your _____ touches the curb.","explanation":"Parking near to a curb should be done with the rear wheel contacting the curb. This will give you a nice view in both directions as you depart the parking area.","imageName":"","correctAnswer":6862,"isBookmarked":0,"aid":"6862#6863#6864#6865","text":"the rear wheel#the front wheel#both wheels#no wheels"},
{"testId":379,"qid":14707,"description":"To park next to a curb, you must park such that your _____ touches the curb.","explanation":"Parking near to a curb should be done with the rear wheel contacting the curb. This will give you a nice view in both directions as you depart the parking area.","imageName":"","correctAnswer":6862,"isBookmarked":0,"aid":"6862#6863#6864#6865","text":"the rear wheel#the front wheel#both wheels#no wheels"},
{"testId":371,"qid":14708,"description":"The rear brake is operated by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":7702,"isBookmarked":0,"aid":"6818#6819#7701#7702","text":"the left hand lever#the right hand lever#the left foot pedal#the right foot pedal"},
{"testId":371,"qid":14710,"description":"For optimal traction while driving in rain, you should drive:","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are less prone to slipping.","imageName":"","correctAnswer":30768,"isBookmarked":0,"aid":"7310#7312#30021#30768","text":"in the center lane position#on the shoulder of the road#in the left lane position#in the tire tracks left by other vehicles"},
{"testId":371,"qid":14711,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks, at least once every two hours, is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"6942#7217#7288#7289","text":"drive only during the day#drink caffeinated beverages#avoid making long trips#take frequent rest breaks"},
{"testId":371,"qid":14712,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist in a parked car while driving by, blow your horn to alert the driver that you are approaching. In this manner, he or she will not open the door directly into your path.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":371,"qid":14714,"description":"You are deemed legally intoxicated, if:","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":28972,"isBookmarked":0,"aid":"7425#22775#28972#28973","text":"you have had at least one drink#you had one drink and are over the age of 21#your blood alcohol concentration (BAC) is 0.08% or more#your blood alcohol concentration (BAC) is 0.05% or more"},
{"testId":371,"qid":14716,"description":"Why should you stay in the left lane when you're riding next to parked cars?","explanation":"When riding alongside parked automobiles, remain to the left lane to avoid any car doors that may be opened into your path. Beep your horn if you observe a motorist inside a parked automobile to warn him or her to your presence.","imageName":"","correctAnswer":30076,"isBookmarked":0,"aid":"22790#22791#30076#30077","text":"You are more likely to hit a car in the right lane position#The center lane position is too slippery#You may avoid a door opening into your path#Motorcyclists should always ride in the left lane position"},
{"testId":371,"qid":14718,"description":"What is the best approach to recognize road bumps while riding at night?","explanation":"Watch the taillights of the car ahead of you to locate bumps in the road at night. If the taillights seem to bounce up and down, the car is most likely travelling over bumps.","imageName":"","correctAnswer":32046,"isBookmarked":0,"aid":"8123#10681#10682#32046","text":"Use your high beam#Only ride where there are street lights#Scan the road often#Use the taillights of the vehicle ahead of you"},
{"testId":74,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":371,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":375,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":458,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":467,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":475,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":483,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":511,"qid":14721,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":371,"qid":14722,"description":"How should you position your body in a slow, sharp curve?","explanation":"In a gradual, tight turn, keep your torso straight as the motorbike leans.","imageName":"","correctAnswer":30085,"isBookmarked":0,"aid":"7901#10629#24772#30085","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean back as far as possible#Keep your body upright"},
{"testId":371,"qid":14723,"description":"What is one cause of turning skidding?","explanation":"The motorbike may slide if you stop too forcefully in a corner.","imageName":"","correctAnswer":26511,"isBookmarked":0,"aid":"7967#7969#7970#26511","text":"Pulling in the clutch too quickly#Using the wrong gear#Using the wrong brake#Braking too hard"},
{"testId":371,"qid":14724,"description":"Since motorcycles are smaller in size than cars, they:","explanation":"Motorcycles look further away and appear to be moving more slowly than automobiles because of their smaller size. In order to avoid colliding with approaching motorbikes, automobile drivers sometimes underestimate the amount of time they have to make a left turn at junctions. Approaching a junction should always be done with care.","imageName":"","correctAnswer":30812,"isBookmarked":0,"aid":"8008#30810#30811#30812","text":"are much faster than cars#appear closer than they really are#travel much slower than cars#appear farther away than they really are"},
{"testId":379,"qid":14724,"description":"Since motorcycles are smaller in size than cars, they:","explanation":"Motorcycles look further away and appear to be moving more slowly than automobiles because of their smaller size. In order to avoid colliding with approaching motorbikes, automobile drivers sometimes underestimate the amount of time they have to make a left turn at junctions. Approaching a junction should always be done with care.","imageName":"","correctAnswer":30812,"isBookmarked":0,"aid":"8008#30810#30811#30812","text":"are much faster than cars#appear closer than they really are#travel much slower than cars#appear farther away than they really are"},
{"testId":371,"qid":14728,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike was constructed before 1978, you must always ride with the headlight on. Motorcycles manufactured after 1978 automatically turn on the headlight when the engine is started.","imageName":"","correctAnswer":30813,"isBookmarked":0,"aid":"8193#8194#8196#30813","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Turn on the headlight whenever you ride"},
{"testId":371,"qid":14730,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns. This will make it easier for you and the motorbike to move together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#28382","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footrests for balance"},
{"testId":371,"qid":14731,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by other drivers.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"936#30814#30815#30816","text":"A one-way road#A curve#A hill#An intersection"},
{"testId":371,"qid":14732,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":371,"qid":14733,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the throttle as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"234#7961#10725#10726","text":"Any of the above#Rolling on the throttle#Applying the rear brake#Applying the front brake"},
{"testId":371,"qid":14734,"description":"Who determines the group's pace when riding in a group?","explanation":"When riding in a group, the pace is set by the tail rider. If the person in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":371,"qid":14736,"description":"When should you check your mirrors?","explanation":"When changing lanes, slowing down, or stopping at an intersection, it is critical to check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":383,"qid":14736,"description":"When should you check your mirrors?","explanation":"When changing lanes, slowing down, or stopping at an intersection, it is critical to check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":412,"qid":14736,"description":"When should you check your mirrors?","explanation":"When changing lanes, slowing down, or stopping at an intersection, it is critical to check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#23097#30817#30818","text":"In all of the above situations#While you are stopped at an intersection#When you are about to slow down#When you are about to change lanes"},
{"testId":371,"qid":14737,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"To assist steady the motorbike, boost the throttle during a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8280#10354","text":"decreasing the throttle#pulling in the clutch#increasing the throttle#leaning forward"},
{"testId":371,"qid":14738,"description":"If you make eye contact with a motorist at a crossroads,:","explanation":"If you make eye contact with a motorist at an intersection, it does not always indicate the driver sees you. At a crossroads, always proceed with care.","imageName":"","correctAnswer":10384,"isBookmarked":0,"aid":"10384#22785#24651#30819","text":"it does not mean the driver has seen you#you must yield the right-of-way#it means the driver will yield the right-of-way#it suggests that the driver is an aggressive driver"},
{"testId":371,"qid":14740,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"When prepared to pass the car in front of you, ride in the left lane to expand your line of sight. You'll be able to look beyond the car in front of you and notice impending traffic as a result.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":371,"qid":14742,"description":"If your motorcycle does not have a windshield, you should :","explanation":"Whether or whether the motorbike has a windshield, both the rider and any passengers (if any) should wear safety goggles or a face shield. A windshield is not a replacement for a face protection or goggles. Windshields do not shelter your sight from the elements.","imageName":"","correctAnswer":10456,"isBookmarked":0,"aid":"8194#10456#30072#30073","text":"Avoid carrying passengers#wear safety goggles or a face shield#have your motorcycle fitted with a windshield before you ride it#ride only on paved roads"},
{"testId":371,"qid":14743,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Driving at night does not boost your safety. It may be harmful and should only be attempted with extreme care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6663#6665#6666#10470","text":"Wearing reflective coating#Decreasing your speed#Changing your lane position#Passing slower vehicles"},
{"testId":77,"qid":14744,"description":"How can you stay awake while riding?","explanation":"Take regular pauses, restrict the duration of your ride, and protect yourself from the weather by wearing appropriate clothes to help minimize tiredness when riding.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10715#10716#10717#23000","text":"Take frequent breaks#Limit the length of your trip#Protect yourself from the elements#Do all of the above"},
{"testId":85,"qid":14744,"description":"How can you stay awake while riding?","explanation":"Take regular pauses, restrict the duration of your ride, and protect yourself from the weather by wearing appropriate clothes to help minimize tiredness when riding.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10715#10716#10717#23000","text":"Take frequent breaks#Limit the length of your trip#Protect yourself from the elements#Do all of the above"},
{"testId":371,"qid":14744,"description":"How can you stay awake while riding?","explanation":"Take regular pauses, restrict the duration of your ride, and protect yourself from the weather by wearing appropriate clothes to help minimize tiredness when riding.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10715#10716#10717#23000","text":"Take frequent breaks#Limit the length of your trip#Protect yourself from the elements#Do all of the above"},
{"testId":462,"qid":14746,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":495,"qid":14746,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":383,"qid":14751,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. You should turn away from the curve's centre and go outdoors.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#6424#10833#10834","text":"Apply the rear brake#decrease the throttle#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":396,"qid":14751,"description":"As you get closer to the center of a curve, you should:","explanation":"You should go toward the inside of the curve as you get closer to the curve's centre. You should turn away from the curve's centre and go outdoors.","imageName":"","correctAnswer":10834,"isBookmarked":0,"aid":"6423#6424#10833#10834","text":"Apply the rear brake#decrease the throttle#move toward the outside of the curve#move toward the inside of the curve"},
{"testId":392,"qid":14771,"description":"Which of the following statements is FALSE?","explanation":"Motorcycles, like vehicles, have blind areas.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10707#29419#30825","text":"You must be in good physical condition to ride a motorcycle#Motorcycles do not have blind spots#The center lane position is safe to ride in#You should fill saddlebags evenly"},
{"testId":434,"qid":14772,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground when sitting and you can reach the handlegrips and footrests, your motorbike suits you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"22993#22994#22996#30500","text":"both of your feet can reach the ground while you are seated#you can reach the handlegrips#you can do all of the above#you can reach the footrests"},
{"testId":416,"qid":14780,"description":"If you're riding with others and need to pass the vehicle in front of you,:","explanation":"If you're riding as a group and need to pass the vehicle in front of you, pass one rider at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":425,"qid":14780,"description":"If you're riding with others and need to pass the vehicle in front of you,:","explanation":"If you're riding as a group and need to pass the vehicle in front of you, pass one rider at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":379,"qid":14834,"description":"Who determines the group's pace when riding in a group?","explanation":"When riding in a group, the pace is set by the tail rider. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":388,"qid":14854,"description":"What is the most effective braking strategy on a slick surface?","explanation":"On a slippery surface, the most effective braking method is to apply both the front and rear brakes at the same time.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#6307","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternately apply the front and rear brakes"},
{"testId":69,"qid":14865,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure the motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":24644,"isBookmarked":0,"aid":"10798#24644#24808#29383","text":"Brake while shifting#Make sure the motorcycle is traveling slowly enough for the lower gear#Never downshift below second gear#Downshift only if you are an experienced rider"},
{"testId":383,"qid":14890,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Motorcycles should never be driven in groups. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8035#26513#26514","text":"Never#When you are riding at night#When you are turning a sharp corner#When you are approaching an intersection"},
{"testId":375,"qid":14896,"description":"While riding through a potentially hazardous region, you should cover:","explanation":"Cover the clutch and both brakes when riding your motorbike in high-risk places such as intersections to reduce response time.","imageName":"","correctAnswer":6122,"isBookmarked":0,"aid":"6121#6122#6123#6124","text":"your eyes with goggles#the clutch and both brakes#your head with a helmet#your whole travel lane"},
{"testId":375,"qid":14897,"description":"You can make yourself safer when riding at night by:","explanation":"When riding at night, you may improve your safety by slowing down, increasing your following distance, and relying on the headlights of the car ahead of you to see.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6416#6801#10777#31132","text":"increasing your following distance#reducing your speed#Doing all of the above#using the lights of the vehicle ahead to help you see"},
{"testId":375,"qid":14898,"description":"you should __________, while riding through a curve, :","explanation":"While going around bends, adjust your lane position as required to have the greatest perspective of what's ahead.","imageName":"","correctAnswer":31135,"isBookmarked":0,"aid":"31133#31134#31135#31136","text":"stay in the center lane position#stay in the left lane position#Change your lane position as needed#stay in the right lane position"},
{"testId":375,"qid":14899,"description":"If you're about to hit something and want the bike to lean right, you should:","explanation":"If you're going to hit with anything and want the motorbike to tilt to the right rapidly, press the inside of the right handgrip.","imageName":"","correctAnswer":22941,"isBookmarked":0,"aid":"22939#22940#22941#22942","text":"push the inside of the left handgrip#pull the left handgrip toward you#push the inside of the right handgrip#pull the right handgrip toward you"},
{"testId":396,"qid":14899,"description":"If you're about to hit something and want the bike to lean right, you should:","explanation":"If you're going to hit with anything and want the motorbike to tilt to the right rapidly, press the inside of the right handgrip.","imageName":"","correctAnswer":22941,"isBookmarked":0,"aid":"22939#22940#22941#22942","text":"push the inside of the left handgrip#pull the left handgrip toward you#push the inside of the right handgrip#pull the right handgrip toward you"},
{"testId":375,"qid":14900,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#24799#31137","text":"Beep your horn#Flash your brake light#Ride in the center lane position#wave at the drivers"},
{"testId":375,"qid":14901,"description":"Which of the following will NOT provide your nighttime safety?","explanation":"Using your high beam, increasing your following distance, and slowing down can improve your nighttime safety. Changing gears fast will not work.","imageName":"","correctAnswer":24751,"isBookmarked":0,"aid":"22837#22838#24750#24751","text":"Reducing your speed#Increasing your following distance#Using your high beam#Shifting gears quickly"},
{"testId":375,"qid":14902,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To avoid mistakenly applying too much throttle, start with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6527#6528#7896","text":"up#straight#locked#flat"},
{"testId":383,"qid":14902,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To avoid mistakenly applying too much throttle, start with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6527#6528#7896","text":"up#straight#locked#flat"},
{"testId":408,"qid":14902,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To avoid mistakenly applying too much throttle, start with your right wrist flat.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6527#6528#7896","text":"up#straight#locked#flat"},
{"testId":375,"qid":14903,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"Wearing bright clothes, flashing your brake light, and utilizing your turn signals may help you be seen by other drivers.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10718#10784#23000#31138","text":"Flash your brake light#Wear bright clothing#Do all of the above#Use your turn signals"},
{"testId":375,"qid":14904,"description":"Which lane location is optimal for being seen by the driver of the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#31139","text":"Left#Center#Right#Whichever one is most comfortable"},
{"testId":375,"qid":14906,"description":"How can you find bumps in the road when you are riding at night?","explanation":"When riding at night, the simplest approach to recognize road bumps is to look at the taillights of the car ahead of you. If the taillights seem to bounce up and down, the car is most likely driving over bumps.","imageName":"","correctAnswer":32046,"isBookmarked":0,"aid":"8123#10682#30040#32046","text":"Use your high beam#Scan the road often#Ride only where there are street lights#Use the taillights of the vehicle ahead of you"},
{"testId":375,"qid":14907,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"Warm pavement gives the greatest traction for bikes of the four options.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":375,"qid":14908,"description":"If your blood alcohol content (BAC) is _____ or above, you are regarded to be inebriated.","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are deemed inebriated.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#4653","text":"0.08%#0.09%#0.05%#0.01%"},
{"testId":375,"qid":14909,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you ride.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":375,"qid":14912,"description":"In which of the following situations should you roll on the throttle?","explanation":"When turning or navigating a curve, roll on the throttle to assist steady the motorbike.","imageName":"","correctAnswer":31141,"isBookmarked":0,"aid":"24735#31140#31141#31142","text":"You are passing a line of parked cars#You are preparing to stop#You want to stabilize your motorcycle in a turn#You are descending a hill"},
{"testId":375,"qid":14915,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in a blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#22793#30012","text":"Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position#Ride at the same speed as the vehicles you are beside"},
{"testId":375,"qid":14916,"description":"Your companion should:","explanation":"To assist balance the motorbike, a passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":379,"qid":14916,"description":"Your companion should:","explanation":"To assist balance the motorbike, a passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":375,"qid":14917,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Often, the driver of a car turning left does not notice an approaching motorbike.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":375,"qid":14918,"description":"What should you look for when merging into the middle lane of a three-lane road?","explanation":"When prepared to merge into the center lane of a three-lane road, examine both the middle and far lanes. Someone in the far lane may intend to move into the center lane.","imageName":"","correctAnswer":24616,"isBookmarked":0,"aid":"7225#7226#24616#30440","text":"Your throttle, to make sure it is not stuck#Your speed limit, to make sure you are not exceeding it#The lane beside you and the far lane#Your signal lights, to make sure they are working"},
{"testId":375,"qid":14920,"description":"When traveling with others, you should NEVER ride:","explanation":"When traveling in a group, never pair up (i.e., ride two abreast). To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7399,"isBookmarked":0,"aid":"6736#7198#7399#31143","text":"single file#in a staggered formation#two abreast#in groups of three or more"},
{"testId":69,"qid":14922,"description":"The most effective strategy to swerve is to:","explanation":"To swerve, push the handgrip on the opposite side of the direction you wish to turn. Never swerve and brake at the same time.","imageName":"","correctAnswer":28210,"isBookmarked":0,"aid":"7437#7438#28210#31144","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side of the direction in which you want to turn#avoid braking and press the handgrip on the side opposite to the direction in which you want to turn"},
{"testId":375,"qid":14922,"description":"The most effective strategy to swerve is to:","explanation":"To swerve, push the handgrip on the opposite side of the direction you wish to turn. Never swerve and brake at the same time.","imageName":"","correctAnswer":28210,"isBookmarked":0,"aid":"7437#7438#28210#31144","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side of the direction in which you want to turn#avoid braking and press the handgrip on the side opposite to the direction in which you want to turn"},
{"testId":379,"qid":14922,"description":"The most effective strategy to swerve is to:","explanation":"To swerve, push the handgrip on the opposite side of the direction you wish to turn. Never swerve and brake at the same time.","imageName":"","correctAnswer":28210,"isBookmarked":0,"aid":"7437#7438#28210#31144","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side of the direction in which you want to turn#avoid braking and press the handgrip on the side opposite to the direction in which you want to turn"},
{"testId":375,"qid":14925,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":375,"qid":14928,"description":"Which one of the following doesn't have an impact on your blood alcohol content (BAC)?","explanation":"Your blood alcohol content is unaffected by the time of day. At any moment, alcohol may impair your ability to ride a motorbike.","imageName":"","correctAnswer":7826,"isBookmarked":0,"aid":"7290#7826#7933#30509","text":"How fast you drink#The time of day#Your weight#The amount of alcohol consumed"},
{"testId":375,"qid":14930,"description":"When approaching an intersection, proceed with caution. Because motorcycles are smaller than cars, they:","explanation":"When approaching a junction, proceed with care. Motorcycles are smaller than vehicles, so they look farther away and faster than they really are. At junctions, automobile drivers making left turns against incoming motorbikes often overestimate their turn time.","imageName":"","correctAnswer":30812,"isBookmarked":0,"aid":"8008#30810#30811#30812","text":"are much faster than cars#appear closer than they really are#travel much slower than cars#appear farther away than they really are"},
{"testId":375,"qid":14931,"description":"How might the car ahead of you assist you in detecting obstacles at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they seem to be up and down shifting, the vehicle is probably travelling over humps.","imageName":"","correctAnswer":30035,"isBookmarked":0,"aid":"10924#10925#30034#30035","text":"Watch the driver's movements#Follow the driver very closely#Follow the path of the car#Watch the taillights to detect bumps on the road"},
{"testId":375,"qid":14932,"description":"More than ______ motorcycle riders should be divided into smaller groups.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":375,"qid":14934,"description":"How many motorcycle lane slots are there in each lane of traffic?","explanation":"A motorbike may drive in one of three lane locations in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":375,"qid":14935,"description":"When ______, drivers inside the car present a special hazard.","explanation":"If a motorist inside a parked automobile does not see you coming, he or she may open a door into your path.","imageName":"","correctAnswer":8368,"isBookmarked":0,"aid":"8368#8369#23070#23071","text":"you are passing a row of parked cars#you are making a right turn#you are parking your motorcycle#you are riding through a school zone"},
{"testId":375,"qid":14936,"description":"How can you ensure that a driver sees you at a controlled intersection?","explanation":"You can never be certain that drivers have noticed you. Always proceed with care at junctions.","imageName":"","correctAnswer":30831,"isBookmarked":0,"aid":"10603#10604#30831#31148","text":"Wave at the driver#Make eye contact with the driver#There is no way to be sure#Beep your horn"},
{"testId":375,"qid":14937,"description":"Cross railroad rails at a 45-degree angle or more because:","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. If you cross at a smaller angle, you may lose your equilibrium or topple over.","imageName":"","correctAnswer":30050,"isBookmarked":0,"aid":"10314#30049#30050#30051","text":"a larger angle would be unsafe#this will give you the best view of oncoming trains#at a smaller angle, you might catch your tires#this will allow you to cross the tracks quickly"},
{"testId":375,"qid":14943,"description":"When approaching a crosswalk where a vehicle may be in your way,:","explanation":"Cover the clutch and brake as you approach an intersection where a vehicle may cross your path to decrease response time.","imageName":"","correctAnswer":10520,"isBookmarked":0,"aid":"10520#10521#10522#10523","text":"Cover the clutch and brake to reduce your reaction time#come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#beep your horn to warn other drivers"},
{"testId":375,"qid":14944,"description":"You can assist yourself maintain your balance while cycling by:","explanation":"Keep your knees against the gas tank to help keep your balance while riding. This allows you and the motorbike to travel in tandem.","imageName":"","correctAnswer":7751,"isBookmarked":0,"aid":"7751#7752#10828#10829","text":"keeping your knees against the gas tank#locking your elbows#sitting as far back as possible#wearing lightweight riding gear"},
{"testId":392,"qid":14952,"description":"When riding at night, you can improve your safety by:","explanation":"Wear fluorescent clothes, use your high beam, and slow down while riding at night to maximize your safety.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":383,"qid":14957,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. Ride with care at all times.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6643#6644#6679#22857","text":"on curves#on hills#at high speeds#at low speeds"},
{"testId":400,"qid":14967,"description":"To alert drivers that you are slowing down in an unexpected spot, you should:","explanation":"Tap the brake pedal to flash your brake light to alert cars that you are slowing down in an unexpected location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7482#7483#22897","text":"Flash your brake light#swerve from side to side#shift gears instead of braking#rise slightly off your seat"},
{"testId":70,"qid":14969,"description":"The most significant aspect of swerving is to:","explanation":"The most crucial aspect of swerving is to avoid braking and swerving at the same time, since this might result in loss of control. Brake before or after swerving if necessary.","imageName":"","correctAnswer":7546,"isBookmarked":0,"aid":"6710#7546#7547#22986","text":"Brake while swerving#not brake and swerve at the same time#never swerve on a curve#only swerve toward the inside of the road"},
{"testId":79,"qid":14987,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright clothes, adopting a proper lane location, and utilizing your headlight may help you be spotted.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#26559","text":"Use your headlight#Wear bright clothing#Do all of the above#Choose a suitable lane position"},
{"testId":396,"qid":15004,"description":"When a vehicle enters your lane from an on-ramp, you should :","explanation":"When a vehicle enters your lane from an on-ramp, you should change lanes to provide the vehicle enough space to merge.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6475#6476","text":"Change lanes#Beep your horn#Speed up#Slow down"},
{"testId":392,"qid":15011,"description":"You should make adjustments before each motorcycle trip to your:","explanation":"Before each ride, you should adjust the side mirrors. It can be dangerous to adjust them while you are riding.","imageName":"","correctAnswer":6746,"isBookmarked":0,"aid":"6360#6744#6745#6746","text":"the clutch#the throttle#your seat#the side mirrors"},
{"testId":383,"qid":15020,"description":"Which of the following risks are associated with passing a line of parked cars?","explanation":"People inside parked automobiles may not see you as you pass them in a queue. People could step out from between automobiles, someone might open a door into your path, or a motorist might pull out in front of you.","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#31453#31454#31455","text":"All of the above are correct#A car may pull out in front of you#A car door may be opened into your path#People may step out from between cars"},
{"testId":396,"qid":15022,"description":"Which of the following you should do before pulling over to the side of the road in an emergency?","explanation":"Before making an emergency stop on the side of the road, you should check whether the surface is firm enough to ride on.","imageName":"","correctAnswer":31456,"isBookmarked":0,"aid":"6928#7414#7415#31456","text":"Move into the center lane position#Skim your feet along the road#Make sure your luggage is secure#Check whether the surface is firm enough to ride on"},
{"testId":408,"qid":15029,"description":"In the SEE procedure's \"assess\" stage, what are you considering?","explanation":"You analyze cars that may move into your path, possibly dangerous road conditions, including traffic lights and road markings during the \"evaluate\" stage of the SEE approach.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#30782#30783","text":"All of the above#Vehicles that may move into your path#Potentially hazardous road conditions#Traffic signals and road markings"},
{"testId":420,"qid":15029,"description":"In the SEE procedure's \"assess\" stage, what are you considering?","explanation":"You analyze cars that may move into your path, possibly dangerous road conditions, including traffic lights and road markings during the \"evaluate\" stage of the SEE approach.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#30782#30783","text":"All of the above#Vehicles that may move into your path#Potentially hazardous road conditions#Traffic signals and road markings"},
{"testId":400,"qid":15038,"description":"One advantage of riding in the center lane is that it:","explanation":"Riding in the middle lane inhibits other drivers from attempting to share your lane. To function properly, a motorbike needs the whole width of the lane.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#29469#29471#31460","text":"allows you to turn easily#discourages others from sharing your lane#helps you maintain a constant speed#keeps you hidden from other vehicle operators"},
{"testId":404,"qid":15038,"description":"One advantage of riding in the center lane is that it:","explanation":"Riding in the middle lane inhibits other drivers from attempting to share your lane. To function properly, a motorbike needs the whole width of the lane.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#29469#29471#31460","text":"allows you to turn easily#discourages others from sharing your lane#helps you maintain a constant speed#keeps you hidden from other vehicle operators"},
{"testId":400,"qid":15052,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6308#6309#6310","text":"Swerving from side to side#applying the brake lightly#rising slightly off the seat#taking your feet off the footrests"},
{"testId":383,"qid":15075,"description":"Rolling on the throttle in a turn:","explanation":"Rolling on the throttle helps steady the motorbike during a turn.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#23043#28402","text":"helps stabilize the motorcycle#should only be done in emergencies#is done to pass other vehicles#is done while shifting gears"},
{"testId":379,"qid":15096,"description":"You should never ride your motorcycle next to a car because:","explanation":"Because you may be in a blind area, you should not ride your motorbike alongside a vehicle.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#31474#31475","text":"you might be in a blind spot#your motorcycle might skid#the car might not be able to turn#your visibility might be reduced"},
{"testId":379,"qid":15097,"description":"If possible, ride your motorcycle _____ within your lane when crossing railroad tracks.","explanation":"To cross railroad tracks, it is usually safer to ride straight inside your lane. If there are tracks, ruts, or road seams parallel to your path, move far enough away from them to cross them at an angle of at least 45 degrees.","imageName":"","correctAnswer":6153,"isBookmarked":0,"aid":"6153#10570#10571#30075","text":"straight#at a 10-degree angle#at a 20-degree angle#by leaning"},
{"testId":379,"qid":15098,"description":"When you want to stop, you should use:","explanation":"When stopping, use both the front and rear brakes. This permits you to come to a safe and speedy halt.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6357#6358#6360#24624","text":"the rear brake#the front brake#the clutch#both the front and rear brakes"},
{"testId":379,"qid":15100,"description":"In the SEE procedure's \"assess\" stage, what are you considering?","explanation":"You analyze cars that may move into your path, possibly dangerous road conditions, including traffic lights and road markings during the \"evaluate\" stage of the SEE approach.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6396#6398#30782","text":"All of the above#Vehicles that may move into your path#Traffic markings and signals#Potentially hazardous road conditions"},
{"testId":379,"qid":15103,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Since 1978, new bikes have turned on the headlight automatically once the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":383,"qid":15103,"description":"When should you turn on your headlights?","explanation":"To maximize your visibility to other drivers, keep your headlights on at all times. (Note: Since 1978, new bikes have turned on the headlight automatically once the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":379,"qid":15104,"description":"When riding at night, you can improve your safety by:","explanation":"Increase your following distance while riding at night to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6561#6854#31476","text":"increasing your following distance#following vehicles closely so you can see better#riding in the center lane position#skimming the road with your feet"},
{"testId":379,"qid":15106,"description":"When will you be able to join forces with another motorcycle?","explanation":"Motorcycles, like vehicles, need a full lane width to function properly. As a result, you should never ride with another motorbike (or any other vehicle).","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8034#24682#24684","text":"Never#When you are riding in a group#When traffic is heavy#When you are riding with a new rider"},
{"testId":379,"qid":15107,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"When prepared to pass the car in front of you, ride in the left lane to expand your line of sight. You'll be able to look beyond the car in front of you and notice impending traffic as a result.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6552#6555#6853#6854","text":"riding in the left lane position#riding in the right lane position#pulling into oncoming traffic#riding in the center lane position"},
{"testId":379,"qid":15108,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":31477,"isBookmarked":0,"aid":"6769#24800#24801#31477","text":"In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection#All of the above situations"},
{"testId":392,"qid":15108,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":31477,"isBookmarked":0,"aid":"6769#24800#24801#31477","text":"In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection#All of the above situations"},
{"testId":396,"qid":15108,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":31477,"isBookmarked":0,"aid":"6769#24800#24801#31477","text":"In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection#All of the above situations"},
{"testId":408,"qid":15108,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":31477,"isBookmarked":0,"aid":"6769#24800#24801#31477","text":"In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection#All of the above situations"},
{"testId":379,"qid":15112,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is stuck and twisting it back and forth does not release it, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":7162,"isBookmarked":0,"aid":"7162#7163#10574#28359","text":"When your throttle is stuck#When you need to stop quickly#When you need to avoid slipping on slippery surfaces#When you are being tailgated"},
{"testId":379,"qid":15113,"description":"Which brake needs to be used more?","explanation":"Because it delivers 70% of the stopping force, the front brake is more critical than the rear brake. The most effective stopping approach, however, is to engage both the front and rear brakes.","imageName":"","correctAnswer":30444,"isBookmarked":0,"aid":"7247#7248#30444#30445","text":"The front, because it prevents skidding#The rear, because it prevents skidding#The front, because it provides 70% of the stopping power#The rear, because it provides 70% of the stopping power"},
{"testId":379,"qid":15115,"description":"When you're riding a motorcycle with baggage or a passenger,:","explanation":"The stopping distance of your motorbike rises while it is carrying luggage or a passenger. You should adjust your riding appropriately.","imageName":"","correctAnswer":31479,"isBookmarked":0,"aid":"7404#22982#31478#31479","text":"you must carry a special license#you must ride only during the day#the stopping distance decreases#the stopping distance increases"},
{"testId":379,"qid":15117,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one ordinary drink may impair your ability to bike. A normal drink is one ounce of hard liquor, half a glass of wine, or a 12-ounce can of beer.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":379,"qid":15118,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"You need to be extra careful to watch out for automobiles turning left into your path at intersections. Often, car drivers fail to see approaching motorbikes.","imageName":"","correctAnswer":31480,"isBookmarked":0,"aid":"7666#7667#10700#31480","text":"pedestrians crossing the road#unexpected light changes#the position of stop lines#vehicles turning left across your path"},
{"testId":379,"qid":15119,"description":"What is an adequate space cushion between you and the vehicle ahead of you when riding in heavy, congested traffic?","explanation":"An sufficient spacing cushion is at least three seconds while riding in severe, crowded traffic.","imageName":"","correctAnswer":10880,"isBookmarked":0,"aid":"897#10880#10881#10882","text":"None of the above#Three seconds#Two seconds#One second"},
{"testId":379,"qid":15121,"description":"The motorcycle and rider should both lean in the same direction in _________.聽:","explanation":"The motorbike and rider should lean at the same degree in regular turns.","imageName":"","correctAnswer":31483,"isBookmarked":0,"aid":"31481#31482#31483#31484","text":"slow, tight turns#quick, sharp turns#normal turns#all turns"},
{"testId":74,"qid":15122,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground when sitting and you can reach the handgrips and footrests, your motorbike suits you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"7893#22993#22996#30500","text":"you can reach the handgrips#both of your feet can reach the ground while you are seated#you can do all of the above#you can reach the footrests"},
{"testId":379,"qid":15122,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground when sitting and you can reach the handgrips and footrests, your motorbike suits you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"7893#22993#22996#30500","text":"you can reach the handgrips#both of your feet can reach the ground while you are seated#you can do all of the above#you can reach the footrests"},
{"testId":383,"qid":15122,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground when sitting and you can reach the handgrips and footrests, your motorbike suits you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"7893#22993#22996#30500","text":"you can reach the handgrips#both of your feet can reach the ground while you are seated#you can do all of the above#you can reach the footrests"},
{"testId":379,"qid":15124,"description":"You may share a lane safely with:","explanation":"A motorbike needs a full lane width to navigate safely. Never, ever share your lane.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30482#30483","text":"no one#other motorcycles only#motorcycles and small cars#all other vehicles"},
{"testId":379,"qid":15125,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":22778,"isBookmarked":0,"aid":"8003#8004#8005#22778","text":"Search, examine, execute#See, evaluate, examine#See, execute, examine#Search, evaluate, execute"},
{"testId":379,"qid":15126,"description":"Which lane should you be in when you're going by parked cars?","explanation":"When passing parked automobiles, ride in the left lane to stay clear of them. You will prevent issues caused by doors opening, people getting out of automobiles, or individuals stepping out from between cars.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":379,"qid":15129,"description":"When approaching an blind intersection with a stop line, you should stop:","explanation":"You should stop behind the stop line while approaching a blind junction with a stop line. If you can't see well from where you are, go ahead till you can.","imageName":"","correctAnswer":8117,"isBookmarked":0,"aid":"8117#8118#8119#8120","text":"behind the stop line#where you can see both ways#with your rear tire on the stop line#straddling the stop line"},
{"testId":379,"qid":15131,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake immediately and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#28929","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#continue applying the front brake"},
{"testId":379,"qid":15132,"description":"Increase your nighttime safety by:","explanation":"Utilizing your high beam, the headlights of the vehicle in front of you to aid in visibility, and extending your following distance can assist keep you safe at night.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6416#6800#10777#10814","text":"increasing your following distance#using your high beam#Doing all of the above#using the lights of the car ahead of you to help you see"},
{"testId":379,"qid":15134,"description":"If you can't get the throttle to move and twisting it back and forth doesn't work, you should:","explanation":"If the throttle is stuck and twisting it back and forth does not release it, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":30086,"isBookmarked":0,"aid":"6417#7978#30086#31486","text":"Use the front brake only#Use engine braking#Operate the engine kill switch#Apply both the front and rear brakes"},
{"testId":379,"qid":15137,"description":"You should make a conventional turn,:","explanation":"You should lean at the same angle as the motorbike in a typical turn.","imageName":"","correctAnswer":10368,"isBookmarked":0,"aid":"10368#31487#31488#31489","text":"lean at the same angle as the motorcycle#lean opposite to the turn#lean at a different angle from the motorcycle#remain upright"},
{"testId":379,"qid":15138,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. Even if you are not yet experiencing additional consequences, you can be making risky judgments.","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":379,"qid":15139,"description":"The best technique to attract the attention of other vehicle operators is to:","explanation":"Make sure your headlights are switched on if you want to be noticed by other drivers. (Note: Since 1978, new bikes have turned on the headlight automatically once the engine is started.)","imageName":"","correctAnswer":10405,"isBookmarked":0,"aid":"8196#10405#10407#28983","text":"Only ride during the day#make sure your headlight is turned on#Ride in the left lane position#wear a bright-colored helmet"},
{"testId":379,"qid":15140,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more room to stop.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":408,"qid":15140,"description":"If your motorcycle takes longer than usual to stop, you should:","explanation":"If your motorbike takes longer to stop, increase your following distance to give yourself more room to stop.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"27#6515#6920#7707","text":"Change lanes#Increase your speed#Increase your following distance#decrease your following distance"},
{"testId":379,"qid":15141,"description":"If the rear wheel locks on a traction-rich surface, you should:","explanation":"If you lock the back wheel on a traction-rich surface, you may keep it locked until you reach a full stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":379,"qid":15142,"description":"The most effective approach to keep oneself safe from other vehicles is to:","explanation":"Maintaining an appropriate distance cushion between you and other cars is the greatest approach to protect yourself from other vehicles.","imageName":"","correctAnswer":30486,"isBookmarked":0,"aid":"22923#30486#30487#31490","text":"Use your high beam#maintain an adequate space cushion#wear protective clothing#wear the right helmet"},
{"testId":383,"qid":15180,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":400,"qid":15180,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":404,"qid":15197,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake immediately and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#28929","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#continue applying the front brake"},
{"testId":416,"qid":15201,"description":"To maintain proper control of your motorcycle, position your arms:","explanation":"To retain proper control of the motorbike, place your arms with your elbows slightly bent.","imageName":"","correctAnswer":6605,"isBookmarked":0,"aid":"6268#6604#6605#22958","text":"with elbows locked#with wrists locked#with elbows slightly bent#At a 90-degree angle"},
{"testId":429,"qid":15201,"description":"To maintain proper control of your motorcycle, position your arms:","explanation":"To retain proper control of the motorbike, place your arms with your elbows slightly bent.","imageName":"","correctAnswer":6605,"isBookmarked":0,"aid":"6268#6604#6605#22958","text":"with elbows locked#with wrists locked#with elbows slightly bent#At a 90-degree angle"},
{"testId":408,"qid":15209,"description":"Lane positioning helps you to _________.","explanation":"Choosing an appropriate lane position allows you to keep out of blind areas, avoid road dangers, and signal your next action.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6841#6842#6843#10656","text":"stay out of blind spots#avoid road hazards#communicate what you plan to do#Do all of the above"},
{"testId":416,"qid":15212,"description":"What is the ideal lane location while it's raining?","explanation":"You should ride in either the left or right lane while it is raining. The middle lane position may be slick due to the accumulation of oil and other vehicle fluids.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6216#6763#7096#7097","text":"Center#Left or right#Center or left#Center or right"},
{"testId":442,"qid":15212,"description":"What is the ideal lane location while it's raining?","explanation":"You should ride in either the left or right lane while it is raining. The middle lane position may be slick due to the accumulation of oil and other vehicle fluids.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6216#6763#7096#7097","text":"Center#Left or right#Center or left#Center or right"},
{"testId":404,"qid":15216,"description":"In order to safely carry a passenger on your motorcycle, you need to have:","explanation":"Your motorbike must have passenger footrests and a seat that can accommodate two persons.","imageName":"","correctAnswer":31122,"isBookmarked":0,"aid":"31122#36264#36265#36267","text":"Adequate seat room and separate footrests#A special passenger license or permit#Heavy-duty shocks and a certain type of suspension#A bright orange vest, leather gloves and heavy-duty boots"},
{"testId":412,"qid":15216,"description":"In order to safely carry a passenger on your motorcycle, you need to have:","explanation":"Your motorbike must have passenger footrests and a seat that can accommodate two persons.","imageName":"","correctAnswer":31122,"isBookmarked":0,"aid":"31122#36264#36265#36267","text":"Adequate seat room and separate footrests#A special passenger license or permit#Heavy-duty shocks and a certain type of suspension#A bright orange vest, leather gloves and heavy-duty boots"},
{"testId":73,"qid":15220,"description":"Your feet should ________ while riding.","explanation":"Your feet should be firmly placed on the footrests when cycling.","imageName":"","correctAnswer":31760,"isBookmarked":0,"aid":"6176#7735#10803#31760","text":"as far forward as possible#hovering above the ground in case you tip#pointing down for easy shifting#positioned firmly on the footrests"},
{"testId":420,"qid":15221,"description":"What causes lugging in a motorcycle engine?","explanation":"You should shift up quickly enough to prevent the engine from overrevving as your motorbike gains speed, but not so quickly that the engine starts to lug. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":422,"qid":15221,"description":"What causes lugging in a motorcycle engine?","explanation":"You should shift up quickly enough to prevent the engine from overrevving as your motorbike gains speed, but not so quickly that the engine starts to lug. The engine may lug if you go into a higher gear too quickly.","imageName":"","correctAnswer":7600,"isBookmarked":0,"aid":"7600#7602#10646#10648","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Leaning while turning#Carrying cargo on the sissy bar"},
{"testId":78,"qid":15228,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":434,"qid":15228,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":79,"qid":15235,"description":"What could be causing your motorcycle to lurch?","explanation":"If you move into a lower gear too quickly, your motorbike may lurch. Instead, slow down to the right pace for the lower gear you're going to change into.","imageName":"","correctAnswer":7602,"isBookmarked":0,"aid":"7600#7602#8172#30519","text":"Shifting into a higher gear too soon#Shifting into a lower gear too soon#Turning too fast#Riding over gravel roads"},
{"testId":420,"qid":15240,"description":"What can you do to improve your visibility at blind intersections?","explanation":"Move into a lane position that allows you to be spotted as soon as possible at blind crossings to increase your visibility.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#24901#24902#28445","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":76,"qid":15249,"description":"You should ride _____ within your lane to cross railroad tracks.","explanation":null,"imageName":"","correctAnswer":6153,"isBookmarked":0,"aid":"6153#10570#10571#30075","text":"straight#at a 10-degree angle#at a 20-degree angle#by leaning"},
{"testId":408,"qid":15253,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep the motorbike from slipping.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#22968","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on a metal grating"},
{"testId":400,"qid":15257,"description":"A motorcycle's rear brake is controlled by:","explanation":"The right foot pedal operates the back brake. The right hand lever controls the front brake.","imageName":"","correctAnswer":6821,"isBookmarked":0,"aid":"6818#6819#6820#6821","text":"the left hand lever#the right hand lever#a button on the handgrip#a foot pedal"},
{"testId":404,"qid":15261,"description":"When you are about to stop, you should flash your brake light if you are:","explanation":"When you are going to stop and are being followed too closely, stopping in an odd location, or stopping faster than others may anticipate, you should flash your brake light.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7025#7026#22688#24754","text":"being followed too closely#stopping in an unusual place#In any of the above situations#stopping more quickly than others might expect"},
{"testId":475,"qid":15261,"description":"When you are about to stop, you should flash your brake light if you are:","explanation":"When you are going to stop and are being followed too closely, stopping in an odd location, or stopping faster than others may anticipate, you should flash your brake light.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"7025#7026#22688#24754","text":"being followed too closely#stopping in an unusual place#In any of the above situations#stopping more quickly than others might expect"},
{"testId":78,"qid":15275,"description":"You can take someone with you if:","explanation":"If your motorbike is capable of carrying another person, you may transport a passenger. This implies it must feature a passenger seat as well as dedicated footrests.","imageName":"","correctAnswer":29493,"isBookmarked":0,"aid":"8096#8099#23047#29493","text":"the passenger has special shoes#all of the above conditions are met#the passenger is wearing protective clothing#your motorcycle is equipped to carry another person"},
{"testId":383,"qid":15298,"description":"When prepared to pass a vehicle in front of you, ride in:","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":7178,"isBookmarked":0,"aid":"7176#7178#10986#31777","text":"The center lane position#the left lane position#the right lane position#any lane position"},
{"testId":383,"qid":15299,"description":"If you intend to pass another vehicle but believe it may move into your lane, you should slow down.","explanation":"If you want to pass another car but believe it may move into your lane, use your horn to alert the driver.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#6287#6288","text":"Beep your horn#Flash your brake light#not pass#pass as fast as possible"},
{"testId":404,"qid":15299,"description":"If you intend to pass another vehicle but believe it may move into your lane, you should slow down.","explanation":"If you want to pass another car but believe it may move into your lane, use your horn to alert the driver.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#6287#6288","text":"Beep your horn#Flash your brake light#not pass#pass as fast as possible"},
{"testId":383,"qid":15301,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, change lanes or slow down to let the tailgater to pass you.","imageName":"202006041812161292.jpg","correctAnswer":31778,"isBookmarked":0,"aid":"6278#31778#31779#31780","text":"Beep your horn#allow the tailgater to pass you#speed up to match the tailgater's speed#stop and argue with the tailgater"},
{"testId":383,"qid":15306,"description":"Maintain a following distance of at least _____ seconds for an enought space cushion.","explanation":"Maintain a following distance of at least two seconds for an acceptable spacing cushion.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":383,"qid":15315,"description":"Which of the following is prohibited when riding downhill?","explanation":"All motor vehicles in Maine are prohibited from coasting downhill in neutral.","imageName":"","correctAnswer":17394,"isBookmarked":0,"aid":"7466#7468#7469#17394","text":"Shifting gears#Increasing your speed#Slowing down#Coasting in neutral"},
{"testId":383,"qid":15318,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your judgment and ability to bike.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":383,"qid":15319,"description":"Riding __________ lowers your visibility.","explanation":"Travelling alongside other cars reduces your visibility since you may be riding in a blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7681#7682#7683#7684","text":"while using your high beam#alongside other vehicles#near other motorcycles#in residential areas"},
{"testId":400,"qid":15319,"description":"Riding __________ lowers your visibility.","explanation":"Travelling alongside other cars reduces your visibility since you may be riding in a blind area.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7681#7682#7683#7684","text":"while using your high beam#alongside other vehicles#near other motorcycles#in residential areas"},
{"testId":72,"qid":15320,"description":"You should check at least _______ ahead while riding in the city and lookout for hazards.","explanation":"When biking in the city, you should glance one-half block ahead for risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":383,"qid":15320,"description":"You should check at least _______ ahead while riding in the city and lookout for hazards.","explanation":"When biking in the city, you should glance one-half block ahead for risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":383,"qid":15321,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"Pedestrians have the right of way in marked or unmarked crosswalks. If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":383,"qid":15323,"description":"Most motorcycle accidents occur:","explanation":"The majority of motorcycle accidents occur on short excursions at speeds of less than 35 mph.","imageName":"","correctAnswer":7890,"isBookmarked":0,"aid":"6646#7889#7890#7891","text":"in parking lots#on long trips#on short trips#on gravel roads"},
{"testId":383,"qid":15326,"description":"At what angle should you cross unavoidable obstacles?","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":22958,"isBookmarked":0,"aid":"22958#23001#23003#23004","text":"At a 90-degree angle#While maintaining your speed#With your feet skimming the ground#While keeping your elbows locked"},
{"testId":383,"qid":15327,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":383,"qid":15329,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"Your blood alcohol content is unaffected by the time of day. Alcohol may have an effect on your riding at any moment.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":383,"qid":15330,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Motorcycles are often overlooked by other drivers.","imageName":"","correctAnswer":8358,"isBookmarked":0,"aid":"8355#8356#8357#8358","text":"On a curve#On a hill#On a one-way road#At an intersection"},
{"testId":383,"qid":15332,"description":"To transport cargo in saddlebags,:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":383,"qid":15336,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so that you can see behind you as well as as much of the lane alongside you as feasible. A motorbike still has blind zones, so glance over your shoulder whenever possible.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10372#31130#31131#32930","text":"behind you and part of the lane beside you#only beside your motorcycle#only behind your motorcycle#everything in front of you"},
{"testId":438,"qid":15336,"description":"Adjust your side mirrors so that you can see:","explanation":"Adjust your side mirrors so that you can see behind you as well as as much of the lane alongside you as feasible. A motorbike still has blind zones, so glance over your shoulder whenever possible.","imageName":"","correctAnswer":10372,"isBookmarked":0,"aid":"10372#31130#31131#32930","text":"behind you and part of the lane beside you#only beside your motorcycle#only behind your motorcycle#everything in front of you"},
{"testId":383,"qid":15337,"description":"If your motorcycle does not have a windshield, you should :","explanation":"This is a deceptive question. The state of Maine does not require you to use eye protection. Regardless of whether the motorbike has a windshield, you and your passenger should wear safety goggles or a face protection. A windshield is not a replacement for a face protection or goggles. Windshields do not shelter your eyes from the wind or from things thrown by the wind.","imageName":"","correctAnswer":10456,"isBookmarked":0,"aid":"8194#10456#24819#30072","text":"Avoid carrying passengers#wear safety goggles or a face shield#drive only on paved roads#have your motorcycle fitted with a windshield before you ride it"},
{"testId":383,"qid":15338,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, it is important to check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":383,"qid":15339,"description":"What is controlled by the right foot pedal?","explanation":"The rear brake is controlled by the right foot pedal. A lever on the right handgrip controls the front brake.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":396,"qid":15339,"description":"What is controlled by the right foot pedal?","explanation":"The rear brake is controlled by the right foot pedal. A lever on the right handgrip controls the front brake.","imageName":"","correctAnswer":6190,"isBookmarked":0,"aid":"6189#6190#7191#7192","text":"The front brake#The rear brake#The clutch#The gear shifter"},
{"testId":383,"qid":15342,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7629#31785#31786#31787","text":"Elastic cords#Strong rope#The sissy bar#Strips of cloth"},
{"testId":396,"qid":15342,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Even tough rope may become slack, and knots can unravel.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7629#31785#31786#31787","text":"Elastic cords#Strong rope#The sissy bar#Strips of cloth"},
{"testId":383,"qid":15343,"description":"You change gears by depressing the clutch and pressing the:","explanation":"The left foot lever is used to change gears. The rear brake is controlled by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#7863#7864#31788","text":"right foot pedal#rear brake lever#front brake lever#left foot lever"},
{"testId":383,"qid":15345,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Motorcycles have shorter stopping distances than large, heavier vehicles. If you abruptly slow down in front of a huge car, it may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10565#10894#26488","text":"slowing down suddenly when you merge back in front of the vehicle#passing without a dedicated passing lane#flashing your headlight#beeping your horn when you are alongside the vehicle"},
{"testId":396,"qid":15347,"description":"To come to a complete stop, you should use:","explanation":"When stopping, use both the front and rear brakes. This permits you to come to a safe and speedy halt.","imageName":"","correctAnswer":24624,"isBookmarked":0,"aid":"6357#6358#24624#28914","text":"the rear brake#the front brake#both the front and rear brakes#the emergency brake"},
{"testId":446,"qid":15365,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Motorcyclists should avoid gravel roads because the loose sand and gravel give minimal traction.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10588#10589#10590","text":"They do not have posted speed limits#Other drivers are known to drive recklessly#Lane markings are not present#Gravel provides less traction than pavement"},
{"testId":75,"qid":15366,"description":"Which lane positions are the most secure for motorcycles?","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":392,"qid":15366,"description":"Which lane positions are the most secure for motorcycles?","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#29461#29462#30068","text":"It depends on the situation#The left and right lane positions are safest#The center lane position is safest#The left lane position is safest"},
{"testId":76,"qid":15467,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before beginning a vacation, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#23024#23025#30038","text":"Being low on oil#The front tire skidding#Braking with only one brake#Shifting gears improperly"},
{"testId":429,"qid":15503,"description":"If you are __________, you may need to adjust your lane location.","explanation":"If you are approaching a blind crossing, you may need to change lanes.","imageName":"","correctAnswer":8190,"isBookmarked":0,"aid":"8189#8190#8191#8192","text":"being followed#approaching a blind intersection#riding on a multilane road#riding in a residential area"},
{"testId":388,"qid":15521,"description":"When driving behind an automobile, you should ride ________ so the driver can see you.","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":32392,"isBookmarked":0,"aid":"6097#32391#32392#32393","text":"in the driver's blind spot#in the right lane position#in the center lane position#in the left lane position"},
{"testId":388,"qid":15522,"description":"This symbol alerts drivers that:","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. If a train is nearing the crossing, slow down and ready to halt before you reach the rails.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9046#9047#10678#26376","text":"you are approaching a railroad crossing#you are approaching an intersection#you are approaching a construction zone#you are approaching a dead end"},
{"testId":388,"qid":15524,"description":"How do you turn properly? What are the four steps?","explanation":"Slow down, look where you want to go, squeeze the handgrip to lean in the direction you want to go, then roll on the throttle to perform a perfect turn.","imageName":"","correctAnswer":6199,"isBookmarked":0,"aid":"6197#6198#6199#6200","text":"Look, press, roll, slow#Slow, press, look, roll#Slow, look, press, roll#Press, look, slow, roll"},
{"testId":388,"qid":15526,"description":"When using saddlebags, be sure to:","explanation":"When employing saddlebags, the load should be distributed equally across the saddlebags.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6323#6324#6325#6326","text":"fill one bag before the other one#only use them if they are completely full#only fill them with approved contents#Distribute the load evenly"},
{"testId":388,"qid":15529,"description":"If the vehicle ahead of you suddenly stops, a ______-second following distance offers you the bare minimum amount of distance to stop or swerve.","explanation":"By allowing at least two seconds between yourself and the car in front of you, you give yourself enough distance to stop or swerve if the vehicle ahead of you abruptly stops.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#5002#6209#10514","text":"Two#Five#Three#One"},
{"testId":388,"qid":15533,"description":"What tire checks should you complete before mounting your motorcycle?","explanation":"Before mounting your motorcycle, you should check your tires for worn treads and any cracks or scrapes that could cause a tire to blow out. Also check the tire pressures.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8233#8234#31793","text":"All of the above#Cuts and scrapes#Tread wear#Tire pressures"},
{"testId":388,"qid":15534,"description":"You must activate your signal lights:","explanation":"Every time you want to turn or change lanes, you must use your turn signals.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#32394","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around"},
{"testId":388,"qid":15535,"description":"What can you do to improve your chances of avoiding potential hazards at night?","explanation":"Reduce your speed and increase your following distance at night to increase your chances of avoiding dangers. This gives you more time to respond to the unexpected.","imageName":"","correctAnswer":6238,"isBookmarked":0,"aid":"6238#11171#11172#11173","text":"Reduce your speed#Ride in a group#Do not wear a helmet#Stay in the same lane position"},
{"testId":388,"qid":15536,"description":"If someone is following you too closely, you must:","explanation":"If you are being tailgated, increase your following distance so that both of you have more space to stop in the event of a danger.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6392#6920#6921","text":"pull over and stop#Call the police#Increase your following distance#flash your brake lights"},
{"testId":388,"qid":15537,"description":"Because drivers merging onto the highway may not notice you, what should you do?","explanation":"You should try to move into a further lane to allow the vehicles more room since they may not be able to see you when they merge onto the highway.","imageName":"","correctAnswer":28360,"isBookmarked":0,"aid":"6286#6582#24799#28360","text":"Flash your brake light#Pull in the clutch#Ride in the center lane position#Move into a farther lane if possible"},
{"testId":388,"qid":15539,"description":"Which of the following should you concentrate on looking for possible escape routes?","explanation":null,"imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#6804#45492#45493","text":"Intersections#Multi-lane highways#Alleys#Curves"},
{"testId":388,"qid":15540,"description":"When large vehicles pass you, you are especially at risk because:","explanation":"You are particularly vulnerable while being passed by heavy cars because strong winds may impair your control. Motorcycles are smaller than automobiles and are hence more vulnerable to wind gusts.","imageName":"","correctAnswer":7257,"isBookmarked":0,"aid":"7256#7257#7258#7259","text":"large vehicles tend to swerve into your lane#large blasts of wind can affect your control#their drivers are often tired from working long hours#large vehicles cannot accelerate as fast as motorcycles"},
{"testId":388,"qid":15541,"description":"For optimal traction while driving in rain, you should drive:","explanation":"The safest location to ride in the rain is in the tire tracks of other cars. These are less prone to slipping.","imageName":"","correctAnswer":28968,"isBookmarked":0,"aid":"7310#7312#28968#30021","text":"in the center lane position#on the shoulder of the road#in the tracks left by other vehicles#in the left lane position"},
{"testId":388,"qid":15542,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both brakes at all times. This is the most efficient form of braking.","imageName":"","correctAnswer":28397,"isBookmarked":0,"aid":"7522#10591#24769#28397","text":"using the front brake only in emergencies#alternating between the front and rear brakes#using only the front brake in most stopping situations#using both the front and rear brakes every time you stop"},
{"testId":388,"qid":15543,"description":"What should you do if the throttle is stuck and turning it back and forth does not dislodge it?","explanation":"If the throttle becomes locked, try twisting it back and forth to dislodge it. If this fails, turn off the engine and engage the clutch at the same moment. This will reduce power to the back wheel.","imageName":"","correctAnswer":22925,"isBookmarked":0,"aid":"22924#22925#22926#22927","text":"Stop the motorcycle using the rear brake only#Flip the engine cut-off switch and pull in the clutch at the same time#Jump off the motorcycle#Pull in the clutch"},
{"testId":388,"qid":15544,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"Keep a sharp eye out for cars turning left into your path. Frequently, motorists in cars fail to see bikes coming.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":388,"qid":15545,"description":"What should you do to start your motorcycle on an upgrade?","explanation":"To avoid from sliding backward while starting your motorbike on an upgrade, use the rear brake to hold you until the engine takes hold.","imageName":"","correctAnswer":30066,"isBookmarked":0,"aid":"10763#10765#30066#30067","text":"Position yourself at a 90-degree angle against the curb#Start in second gear#Use the rear brake to hold you until the engine takes hold#Never try to start a motorcycle on an upgrade"},
{"testId":388,"qid":15546,"description":"You should check at least _______ ahead while riding in the city and lookout for hazards.","explanation":"When biking in the city, look at least one-half block ahead for potential risks.","imageName":"","correctAnswer":7685,"isBookmarked":0,"aid":"5489#5490#5491#7685","text":"one block#three blocks#two blocks#one-half block"},
{"testId":388,"qid":15547,"description":"What is an adequate space cushion between you and the vehicle ahead of you when riding in heavy, congested traffic?","explanation":"In heavy traffic, the MVA Motorcycle Operator Manual advises at least a three-second following distance. (Other experts advocate at least a four-second gap buffer.)","imageName":"","correctAnswer":10880,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":388,"qid":15548,"description":"Which of the following braking statements is correct?","explanation":"Apply both the front and rear brakes at the same time for the most efficient braking.","imageName":"","correctAnswer":23039,"isBookmarked":0,"aid":"22875#22876#22878#23039","text":"The rear brake has most of the stopping power#The front brake is controlled by a foot lever#The rear brake should only be used to slow down#Stop quickly by using both brakes"},
{"testId":388,"qid":15549,"description":"If you need to change gears and you are getting close to a turn, you should do so:","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the rear wheel.","imageName":"","correctAnswer":6531,"isBookmarked":0,"aid":"6186#6530#6531#10807","text":"During the turn#After the turn#Before the turn#Before, during, or after the turn"},
{"testId":388,"qid":15550,"description":"Which lane postions might be dangerous when it begins to rain?","explanation":"When it begins to rain, the middle lane may become dangerous due to the accumulation of oil and fluids. It is best to ride in the tracks of other cars until the fluids are washed away by additional rain.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":294,"qid":15552,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, twist it back and forth to dislodge it. If it doesn't work, turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":6583,"isBookmarked":0,"aid":"6423#6569#6582#6583","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Twist it back and forth to free it"},
{"testId":388,"qid":15552,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, twist it back and forth to dislodge it. If it doesn't work, turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":6583,"isBookmarked":0,"aid":"6423#6569#6582#6583","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Twist it back and forth to free it"},
{"testId":388,"qid":15553,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to assist you balance yourself in turns.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#24898","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footpegs for balance"},
{"testId":388,"qid":15555,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers may be unable to see you from certain regions.","imageName":"","correctAnswer":32395,"isBookmarked":0,"aid":"7352#7353#24647#32395","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicle operators may have difficulty seeing you in certain areas"},
{"testId":388,"qid":15557,"description":"Which of the following can help people see you?","explanation":"Choose a lane location where you will be visible and where you will have enough space around you. As traffic conditions change, adjust your lane position.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":388,"qid":15558,"description":"For riding curves, the optimal group formation is:","explanation":"It is advisable to ride in single file through bends. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":388,"qid":15559,"description":"When should you check your mirrors?","explanation":"When changing lanes, slowing down, or stopping at an intersection, it is critical to check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"10339#22962#23097#24650","text":"When you are preparing to change lanes#In all of the above situations#While you are stopped at an intersection#When you are slowing down"},
{"testId":388,"qid":15560,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Motorcycles should never be driven in groups. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8035#26513#26514","text":"Never#When you are riding at night#When you are turning a sharp corner#When you are approaching an intersection"},
{"testId":388,"qid":15561,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"Look 12 seconds ahead for hazards and possible threats while scanning the road. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":388,"qid":15562,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike was constructed before 1978, keep the headlight on at all times. According to studies, a motorbike with its lights on is twice as likely to be spotted by others during the day. Motorcycles manufactured after 1978 automatically turn on the headlight when the engine is started.","imageName":"","correctAnswer":32396,"isBookmarked":0,"aid":"8193#8194#8196#32396","text":"Get a special license#Avoid carrying passengers#Only ride during the day#Turn the headlight on at all times when riding"},
{"testId":388,"qid":15564,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10465#10466#10467#29426","text":"flash your headlights when accelerating#leave a larger space cushion#be careful not to run into the vehicle in front of you#make eye contact with other vehicle operators"},
{"testId":388,"qid":15565,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Driving at night does not boost your safety. It may be harmful and should only be attempted with extreme care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6665#6666#10470#10804","text":"Decreasing your speed#Changing your lane position#Passing slower vehicles#Wearing reflective clothing"},
{"testId":388,"qid":15567,"description":"To safely stop on a curve, you should:","explanation":"Straighten your motorbike upright to safely stop on a curve. Stopping while leaning might be risky.","imageName":"","correctAnswer":31161,"isBookmarked":0,"aid":"6901#10686#31161#32379","text":"Keep your knees against the gas tank#lean in the opposite direction of the motorcycle#straighten your motorcycle upright#take your feet off the footpegs for balance"},
{"testId":388,"qid":15569,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"A 90-degree angle is the safest approach to pass a barrier. This will prevent your front wheel from being deflected by the obstruction.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10676#10677","text":"45-degree#20-degree#100-degree#90-degree"},
{"testId":404,"qid":15654,"description":"You can boost your visibility by:","explanation":"Each lane location has a purpose. Ride in whatever lane position allows you to be noticed while maintaining an acceptable spacing cushion. As traffic conditions change, adjust your lane position.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":412,"qid":15658,"description":"The best technique to attract the attention of other vehicle operators is to:","explanation":"Make sure your headlights are switched on if you want to be noticed by other cars. (Note: In the United States, bikes produced after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":10405,"isBookmarked":0,"aid":"8196#10405#10406#10407","text":"Only ride during the day#make sure your headlight is turned on#wear a bright helmet#Ride in the left lane position"},
{"testId":431,"qid":15690,"description":"The most significant aspect of swerving is to:","explanation":"The most crucial aspect of swerving is to avoid braking and swerving at the same time. Brake before or after swerving if necessary.","imageName":"","correctAnswer":7546,"isBookmarked":0,"aid":"6710#7546#7547#22986","text":"Brake while swerving#not brake and swerve at the same time#never swerve on a curve#only swerve toward the inside of the road"},
{"testId":483,"qid":15690,"description":"The most significant aspect of swerving is to:","explanation":"The most crucial aspect of swerving is to avoid braking and swerving at the same time. Brake before or after swerving if necessary.","imageName":"","correctAnswer":7546,"isBookmarked":0,"aid":"6710#7546#7547#22986","text":"Brake while swerving#not brake and swerve at the same time#never swerve on a curve#only swerve toward the inside of the road"},
{"testId":400,"qid":15707,"description":"Grabbing the front brake can cause:","explanation":"A front-wheel lock might arise by grabbing the front brake. Apply consistent pressure to the brake.","imageName":"","correctAnswer":30460,"isBookmarked":0,"aid":"8304#8305#30459#30460","text":"stopping quickly#popping a tire#a rear-wheel lock#a front-wheel lock"},
{"testId":392,"qid":15721,"description":"To cross railroad tracks, keep your motorcycle _____ within its lane.","explanation":null,"imageName":"","correctAnswer":6153,"isBookmarked":0,"aid":"6153#6154#10570#10571","text":"straight#by leaning into a side#at a 10-degree angle#at a 20-degree angle"},
{"testId":392,"qid":15723,"description":"When should you turn on your headlights?","explanation":"To improve your visibility to others, keep your headlights on at all times. (Note: Motorcycles built after 1978 automatically switch on the low-beam headlamp when the engine is started.)","imageName":"","correctAnswer":6492,"isBookmarked":0,"aid":"6491#6492#6493#30033","text":"Between dusk and dawn#At all times#At night and in fog#Only at night"},
{"testId":392,"qid":15725,"description":"When should you use your side mirrors?","explanation":"Before changing lanes, slowing down, or stopping at an intersection, check your side mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"6537#6661#6662#22962","text":"Before you change lanes#When you are stopped at an intersection#Before you slow down#In all of the above situations"},
{"testId":392,"qid":15726,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#10333#10334#24600","text":"All of the above are correct#You are not in a blind spot#Your space cushion is maximized#You are avoiding hazards"},
{"testId":392,"qid":15727,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should always apply both the front and rear brakes to stop. The most efficient approach to stop is to use both brakes. However, if you have a flat tire, progressively brake using just the other tire.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22815#22816#25870#29435","text":"On hills and curves#Never at the same time#At all times, unless you have a flat tire#Only in emergencies"},
{"testId":392,"qid":15728,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"You should flash your brake light anytime you believe it may not be noticed since a motorbike brake light is less visible than the brake lights of a vehicle.","imageName":"","correctAnswer":32717,"isBookmarked":0,"aid":"32715#32716#32717#32718","text":"Beep your horn whenever you're about to stop#Install extra lights on your motorcycle#Flash your brake light whenever you think it may not be seen#Brake only when absolutely necessary"},
{"testId":392,"qid":15730,"description":"How can you maximize stopping power?","explanation":"Using both the front and rear brakes at the same time provides the most stopping force.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#11174#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Pull in the clutch while you brake#Alternate between the front brake and the rear brake"},
{"testId":392,"qid":15731,"description":"If your motorcycle starts to wobble, you should not:","explanation":"If your motorbike begins to wobble, do not attempt to speed out of the wobble. This might make the motorbike even more unsteady. Avoid braking as this may exacerbate the wobbling. Instead, move your weight forward and down as much as possible to avoid vibration, gently lower the throttle, and get off the road as soon as it is safe.","imageName":"","correctAnswer":6433,"isBookmarked":0,"aid":"6311#6433#6954#6955","text":"grip the handlebars tightly#Accelerate out of the wobble#close the throttle#adjust your weight"},
{"testId":392,"qid":15733,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist in a parked car as you pass, blow your horn to alert the driver and prevent the danger of him opening the door into your path.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22773#22979","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Ride in the right lane position so you will be close to the vehicle#Beep your horn to let the driver know you're there"},
{"testId":392,"qid":15736,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If you have to swerve left due to an unanticipated obstruction, squeeze the left handlegrip. This will force the motorbike to rapidly tilt to the left.","imageName":"","correctAnswer":29381,"isBookmarked":0,"aid":"6423#6569#29380#29381","text":"Apply the rear brake#Apply the front brake#press the right handlegrip#press the left handlegrip"},
{"testId":392,"qid":15737,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footpegs even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#32719","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep his or her feet on the footpegs"},
{"testId":392,"qid":15739,"description":"If you take a bend too quickly, you may end up:","explanation":"If you take a bend too quickly, you risk straying into another lane of traffic.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":75,"qid":15740,"description":"How do you alert the driver behind聽you that you are about to slow down?","explanation":"Flash your brake light to alert the motorist behind you that you are going to slow down. Tap the brakes to do this.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"10718#10917#10918#10919","text":"Flash your brake light#Turn around and make eye contact#Slam on the brakes#Wave your arms"},
{"testId":392,"qid":15740,"description":"How do you alert the driver behind聽you that you are about to slow down?","explanation":"Flash your brake light to alert the motorist behind you that you are going to slow down. Tap the brakes to do this.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"10718#10917#10918#10919","text":"Flash your brake light#Turn around and make eye contact#Slam on the brakes#Wave your arms"},
{"testId":392,"qid":15742,"description":"Which position(s) in the lane can be dangerous when it starts to rain?","explanation":"Because rains loosens oil and other fluids that have accumulated in the middle of the pavement, the center lane position is likely to be slippery. This slick condition will last until further rain wipes away the fluids.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#17289#24895","text":"Left#Center#Left and right#Right and center"},
{"testId":392,"qid":15743,"description":"Motorcycle groups with more than _____ riders should be divided into smaller groups.","explanation":"Motorcycle groups of more than five or six riders should separate off.","imageName":"","correctAnswer":11064,"isBookmarked":0,"aid":"6389#6390#6622#11064","text":"3#4#2 or 3#5 or 6"},
{"testId":392,"qid":15744,"description":"You may transport a passenger if:","explanation":"If your motorbike is intended to accommodate another person, you may transport a passenger. That is, the motorbike must include footpegs for the passenger and a seat big enough to accommodate two persons.","imageName":"","correctAnswer":8097,"isBookmarked":0,"aid":"8096#8097#8099#32720","text":"the passenger has special shoes#your motorcycle is designed to carry another person#all of the above conditions are met#the person is wearing protective clothing"},
{"testId":392,"qid":15745,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"When traveling with a passenger, go slower around bends. Your motorbike will respond slower as a result of the extra weight.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8244#8245#28381","text":"faster#at normal speed#slower#at the speed limit"},
{"testId":429,"qid":15745,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"When traveling with a passenger, go slower around bends. Your motorbike will respond slower as a result of the extra weight.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8244#8245#28381","text":"faster#at normal speed#slower#at the speed limit"},
{"testId":392,"qid":15747,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Increase the throttle while turning to assist steady the motorbike in a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":392,"qid":15748,"description":"If you can't cross an obstacle straight on, cross at an angle of at least ____ degrees.","explanation":"If you can't cross a barrier straight on, cross at an angle of at least 45 degrees.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#8331","text":"20#45#90#105"},
{"testId":392,"qid":15751,"description":"What do you press in the \"press\" step of turning?","explanation":"In the \"push\" stage of turning, you press the handlegrip of the turning direction. This causes the motorbike to tilt in that direction.","imageName":"","correctAnswer":23052,"isBookmarked":0,"aid":"8404#8405#23051#23052","text":"The front or rear brake#Your feet on the footpegs#The clutch lever#The handlegrip of the direction in which you are turning"},
{"testId":392,"qid":15752,"description":"When should you select the center lane?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. Drivers scan their rearview mirrors more often than their side mirrors.","imageName":"","correctAnswer":31467,"isBookmarked":0,"aid":"8033#8345#8346#31467","text":"When you are preparing to pass#When you are riding beside a row of parked cars#When there is a hazard on the left side of the road#When you want to be most visible to the driver of the vehicle ahead of you"},
{"testId":392,"qid":15753,"description":"To transport cargo in saddlebags,:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6255#6257#8439#8440","text":"distribute the weight evenly between both bags#make sure both bags are full#fill the left bag before the right#fill the right bag before the left"},
{"testId":392,"qid":15755,"description":"When you turn on your motorcycle, you right wrist should be positioned:","explanation":"Position your right wrist flat to avoid using too much throttle while starting off.","imageName":"","correctAnswer":6452,"isBookmarked":0,"aid":"6452#6453#28226#32721","text":"flat, so you won't use too much throttle#high, to use the throttle the most#low, to increase control#in whatever position is most comfortable"},
{"testId":392,"qid":15757,"description":"Cross railroad rails at a 45-degree angle or more because:","explanation":"Cross railroad tracks at a 45-degree angle to avoid snagging your tires on the tracks. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":29444,"isBookmarked":0,"aid":"10314#29444#32722#32723","text":"a larger angle would be unsafe#at a smaller angle, you may catch your tires#this gives you the best view of oncoming trains#this allows you to cross the tracks quickly"},
{"testId":392,"qid":15760,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Even though it is not against the law in Massachusetts, you should never ride in pairs. A motorbike needs the whole width of the lane to function correctly.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#17347#24812#32724","text":"Never#When approaching intersections#When riding at night#When riding through sharp curves"},
{"testId":392,"qid":15761,"description":"You must be extremely cautious at intersections for:","explanation":"You need to be alert for vehicles turning left in front of you at intersections. Often, car drivers fail to see approaching motorbikes.","imageName":"","correctAnswer":7150,"isBookmarked":0,"aid":"7149#7150#7151#30014","text":"irregular traffic signals#cars turning left in front of you#rows of parked cars#vehicles trying to share your lane"},
{"testId":392,"qid":15763,"description":"As a motorcycle rider, you can keep your balance by:","explanation":"Maintain your balance by keeping your feet on the footpegs.","imageName":"","correctAnswer":29393,"isBookmarked":0,"aid":"8165#8167#10733#29393","text":"skimming them above the ground#holding them away from the motorcycle#pointing your toes downward#keeping them on the footpegs"},
{"testId":392,"qid":15764,"description":"When is it permissible to park a motorcycle in a disability parking space?","explanation":"You may park your motorbike in a handicapped parking area only if it is equipped with disabled vehicle plates.","imageName":"","correctAnswer":32726,"isBookmarked":0,"aid":"10482#10781#32725#32726","text":"When dropping off a passenger#When parking for five minutes or less#When loading or unloading#If your motorcycle is equipped with disabled person plates"},
{"testId":392,"qid":15765,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and stop while approaching an intersection where a vehicle may cross your path to decrease response time.","imageName":"","correctAnswer":32727,"isBookmarked":0,"aid":"10521#10522#10523#32727","text":"come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#beep your horn to warn other drivers#cover the clutch and the brake to reduce your reaction time"},
{"testId":392,"qid":15767,"description":"The most effective approach to improve your visibility at an intersection is to:","explanation":"To improve your visibility at a junction, adopt a lane location that allows others to see you easily.","imageName":"","correctAnswer":6778,"isBookmarked":0,"aid":"6286#6778#29447#32728","text":"Flash your brake light#choose a lane position that lets others see you easily#use hand signals to warn others#beep your horn to attract attention"},
{"testId":392,"qid":15768,"description":"How do you ride safely on slick terrain?","explanation":"You can bike safely on slick conditions by slowing down and avoiding rapid movements.","imageName":"","correctAnswer":23103,"isBookmarked":0,"aid":"23101#23103#26561#32729","text":"Increase your speed to maintain balance#Reduce your speed and avoid sudden moves#Ride in the slipperiest portion of the lane#Lean opposite to the motorcycle in turns"},
{"testId":392,"qid":15769,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Car drivers often fail to see motorcycles.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"936#30814#30815#30816","text":"A one-way road#A curve#A hill#An intersection"},
{"testId":412,"qid":15833,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated and the tailgater is unable to pass you, increase your following distance so that both of you have ample area to stop if a danger appears.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6392#6920#6921","text":"pull over and stop#Call the police#Increase your following distance#flash your brake lights"},
{"testId":429,"qid":15833,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated and the tailgater is unable to pass you, increase your following distance so that both of you have ample area to stop if a danger appears.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6392#6920#6921","text":"pull over and stop#Call the police#Increase your following distance#flash your brake lights"},
{"testId":74,"qid":15852,"description":"How should you position your body in a slow, sharp curve?","explanation":"In a gradual, tight turn, keep your torso straight as the motorbike leans.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10631#24772","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Keep your body straight#Lean back as far as possible"},
{"testId":429,"qid":15852,"description":"How should you position your body in a slow, sharp curve?","explanation":"In a gradual, tight turn, keep your torso straight as the motorbike leans.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10631#24772","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Keep your body straight#Lean back as far as possible"},
{"testId":420,"qid":15865,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":396,"qid":15897,"description":"In which of the following scenarios should the following distance be increased?","explanation":"In circumstances when you may require additional space to stop, increase your following distance. Slippery roads, high traffic, and being tailgated are all examples of this.","imageName":"","correctAnswer":31477,"isBookmarked":0,"aid":"6769#6999#28359#31477","text":"In heavy traffic#On slippery roads#When you are being tailgated#All of the above situations"},
{"testId":76,"qid":15898,"description":"What can you do to maintain your balance during a turn?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#10712#10713#10714","text":"Keep your knees against the gas tank#Crouch down low#Pull in the clutch and coast#Slow down before the turn"},
{"testId":77,"qid":15901,"description":"If your back tire goes flat while riding,:","explanation":"If your back tire becomes flat while riding, you should stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":408,"qid":15901,"description":"If your back tire goes flat while riding,:","explanation":"If your back tire becomes flat while riding, you should stop using just the front brake. Never use a flat tire's brake.","imageName":"","correctAnswer":8067,"isBookmarked":0,"aid":"7978#8065#8067#33215","text":"Use engine braking#stop with the rear brake only#stop with the front brake only#use both the front and rear brakes to stop"},
{"testId":396,"qid":15923,"description":"Maintaining or accelerating during a turn:","explanation":"Maintaining or increasing your speed in a turn aids in the stabilization of the motorcycle.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7596#24796#33216","text":"helps stabilize the motorcycle#should never be done#should only be done when passing others#is safer because you complete the turn sooner"},
{"testId":396,"qid":15924,"description":"Allow other drivers to realize that you are slowing down by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6234#6235#6236#6237","text":"Beeping your horn#Flashing your brake light#Swerving from side to side#Using signal lights"},
{"testId":396,"qid":15925,"description":"Which lane position should you use while riding on a winding road?","explanation":"While riding on a curving road, your lane position should shift often to provide you the greatest perspective of what is ahead.","imageName":"","correctAnswer":33217,"isBookmarked":0,"aid":"6214#6216#6217#33217","text":"Left#Center#Right#Whichever one gives you the best view"},
{"testId":422,"qid":15925,"description":"Which lane position should you use while riding on a winding road?","explanation":"While riding on a curving road, your lane position should shift often to provide you the greatest perspective of what is ahead.","imageName":"","correctAnswer":33217,"isBookmarked":0,"aid":"6214#6216#6217#33217","text":"Left#Center#Right#Whichever one gives you the best view"},
{"testId":396,"qid":15927,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It takes up the least amount of space on each side.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":396,"qid":15930,"description":"When riding at night, you can improve your safety by:","explanation":"You may boost your safety while riding at night by increasing your following distance to allow yourself more time to respond.","imageName":"","correctAnswer":6416,"isBookmarked":0,"aid":"6416#6560#6561#6854","text":"increasing your following distance#skimming the road with your feet as you ride#following vehicles closely so you can see better#riding in the center lane position"},
{"testId":396,"qid":15931,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike starts to wobble, the easiest approach to stop it is to gently shut the throttle. Applying the brakes or attempting to accelerate out of it might aggravate the situation.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":396,"qid":15932,"description":"When do you need to utilize your side mirrors?","explanation":"Before changing lanes, slowing down, or stopping at an intersection, check your side mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"6536#6660#22962#28392","text":"When stopped at an intersection#Before changing lanes#In all of the above situations#Before slowing down"},
{"testId":396,"qid":15935,"description":"Which of the following roads is the most dangerous to ride a motorcycle on?","explanation":"Gravel roads are the most dangerous to travel on a motorbike because they give the least amount of traction.","imageName":"","correctAnswer":6717,"isBookmarked":0,"aid":"6717#6803#6805#24691","text":"Gravel roads#Newly paved roads#Rural roads#Multilane highways"},
{"testId":396,"qid":15937,"description":"To park along the side of the road, you should park:","explanation":"Many other states advise parking at an angle with the rear tire hitting the curb while using a roadside parking place. The Michigan Motorcycle Operator Manual, on the other hand, suggests parking parallel to the curb.","imageName":"","correctAnswer":74,"isBookmarked":0,"aid":"74#7018#7019#7020","text":"parallel to the curb#with your front tire touching the curb#with your rear tire touching the curb#on the curb"},
{"testId":396,"qid":15939,"description":"Maintain a following distance of at least _____ seconds for an enought space cushion.","explanation":"Maintain a following distance of at least two seconds for an acceptable spacing cushion. (However, the Michigan Motorcycle Operator Manual recommended that rookie motorcycle operators leave at least four seconds of following space.)","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":396,"qid":15940,"description":"How do you avoid driving in the blind spots of a another vehicle?","explanation":"You may avoid riding in the blind area of another vehicle by altering your speed to travel in a place without traffic on either side.","imageName":"","correctAnswer":10793,"isBookmarked":0,"aid":"10792#10793#22793#30012","text":"Ride faster than the speed limit#Find a place without traffic on either side#Only ride in the left lane position#Ride at the same speed as the vehicles you are beside"},
{"testId":396,"qid":15943,"description":"Which of the following surfaces is hardest for motorcycles to grip?","explanation":"Motorcycles have poor grip on wet pavement, gravel roads, and lane lines.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6717#6718#7396","text":"All of the above#Gravel roads#Wet pavement#Lane markings"},
{"testId":396,"qid":15947,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for one-quarter of the stopping power of the motorbike. When stopping, always engage both the front and back brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":396,"qid":15948,"description":"The optimal lane location for visibility:","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":396,"qid":15951,"description":"When is it legal to ride a motorcycle between traffic lanes?","explanation":"It is never safe to ride a motorbike between lanes of traffic, even if there seems to be enough space. It's against the law in Michigan.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8087#28377#28439","text":"Never#In designated areas#When traffic is at a standstill#Only if all traffic is headed in the same direction"},
{"testId":396,"qid":15952,"description":"In a turn, rolling on the throttle:","explanation":"Turning the throttle helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#7906#23043","text":"helps stabilize the motorcycle#should only be done in emergencies#is done while changing gears#is done to pass other vehicles"},
{"testId":396,"qid":15953,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6242#6244#6245#24700","text":"continue applying the brake#use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly"},
{"testId":396,"qid":15954,"description":"When riding over obstacles, __________ helps to absorb shock.","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":7960,"isBookmarked":0,"aid":"7960#7961#7962#7963","text":"Rising slightly off the seat#Rolling on the throttle#Using a higher gear#Pulling in the clutch"},
{"testId":396,"qid":15956,"description":"Which of the following is prohibited when riding downhill?","explanation":"All motor vehicles in Michigan are prohibited from coasting downhill in neutral.","imageName":"","correctAnswer":17394,"isBookmarked":0,"aid":"7466#7468#7469#17394","text":"Shifting gears#Increasing your speed#Slowing down#Coasting in neutral"},
{"testId":396,"qid":15957,"description":"When approaching an intersection, riders must exercise extreme caution. Because motorcycles are smaller in size than other vehicles, they:","explanation":"When approaching a junction, riders must use extreme care. Motorcycles seem to be farther away than they are because they are smaller than vehicles. At junctions, automobile drivers making left turns against incoming motorbikes often overestimate their turn time.","imageName":"","correctAnswer":33218,"isBookmarked":0,"aid":"8006#8008#30811#33218","text":"appear closer than they are#are much faster than cars#travel much slower than cars#appear farther away than they are"},
{"testId":396,"qid":15960,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should monitor the road 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not sound like much, but keep in mind that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#7321#8416#8417","text":"12 seconds#2 seconds#15 feet#30 feet"},
{"testId":396,"qid":15964,"description":"Because motorcycles move more quickly than other cars on the road, you must :","explanation":"You must be cautious to avoid colliding with the vehicle in front of you since motorcyclists accelerate more quickly than other cars on the road. Trucks in particular, which accelerate slowly, illustrate this point.","imageName":"","correctAnswer":10467,"isBookmarked":0,"aid":"10464#10466#10467#33219","text":"make eye contact with other drivers#leave a larger space cushion#be careful not to run into the vehicle in front of you#flash your headlight when accelerating"},
{"testId":396,"qid":15968,"description":"What can you do to reduce your reaction time when approaching an intersection?","explanation":"When approaching an intersection, you may shorten your response time by covering the clutch and brake to ensure a speedy stop.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"24741#24742#24743#24744","text":"Maintain or increase your speed#Look both ways#Point your toes downward#Cover the clutch and the brake"},
{"testId":416,"qid":15968,"description":"What can you do to reduce your reaction time when approaching an intersection?","explanation":"When approaching an intersection, you may shorten your response time by covering the clutch and brake to ensure a speedy stop.","imageName":"","correctAnswer":24744,"isBookmarked":0,"aid":"24741#24742#24743#24744","text":"Maintain or increase your speed#Look both ways#Point your toes downward#Cover the clutch and the brake"},
{"testId":396,"qid":15969,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10676#10677","text":"45-degree#20-degree#100-degree#90-degree"},
{"testId":446,"qid":15969,"description":"Crossing an unavoidable obstruction at a _____ angle is the safest option.","explanation":"Crossing an inevitable barrier head-on, at a 90-degree angle, is the safest method. This will prevent your front wheel from being deflected by the obstruction. If that is not an option, cross it at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10675#10676#10677","text":"45-degree#20-degree#100-degree#90-degree"},
{"testId":396,"qid":15970,"description":"What should you do if you come to a stop on a curve?","explanation":"When coming to a halt on a curve, strive to keep your motorbike upright. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":26481,"isBookmarked":0,"aid":"10835#10836#22273#26481","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Straighten your motorcycle upright"},
{"testId":491,"qid":16016,"description":"The _______ lane position is the best for discouraging lane sharing by others.","explanation":"The middle lane location is the greatest for discouraging lane sharing by others. It gives you the most space on each side of you.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":408,"qid":16063,"description":"When riding a motorcycle, you mostly use your arms to:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":412,"qid":16063,"description":"When riding a motorcycle, you mostly use your arms to:","explanation":"When riding a motorbike, your arms should be used to guide, not to support yourself.","imageName":"","correctAnswer":20973,"isBookmarked":0,"aid":"6448#20973#29363#33589","text":"holding yourself up#steering#braking#making hand signals"},
{"testId":77,"qid":16066,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":416,"qid":16079,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, you should bike in lane two (middle) or three (right) to avoid them.","imageName":"","correctAnswer":29452,"isBookmarked":0,"aid":"28388#28390#28391#29452","text":"One (left) or two (center)#Two (center)#Three (right)#Two (center) or three (right)"},
{"testId":404,"qid":16097,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"While all of these options are crucial while cycling through an intersection, keep an eye out for automobiles turning left into your path. Car drivers often fail to see approaching motorcyclists.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":75,"qid":16110,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":400,"qid":16121,"description":"When driving behind an automobile, you should ride ________ so the driver can see you.","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":32392,"isBookmarked":0,"aid":"6097#32391#32392#32393","text":"in the driver's blind spot#in the right lane position#in the center lane position#in the left lane position"},
{"testId":400,"qid":16125,"description":"What kind of clothing is better for ridingduring the day?","explanation":"The majority of collisions happen in broad daylight. Wear brightly colored apparel to boost your visibility. Keep in mind that your body accounts for half of the apparent surface area of the rider/motorcycle unit. Jackets/vests in bright orange, red, yellow, or green will help you stand out. Your helmet can do more than just keep you safe in an accident. Brightly colored helmets may also aid with visibility.","imageName":"","correctAnswer":45636,"isBookmarked":0,"aid":"6274#6276#45635#45636","text":"Leather clothing#Any type of clothing#Dark clothing#Bright-colored clothing"},
{"testId":400,"qid":16127,"description":"If you slow down unexpectedly, you should:","explanation":"If you are going to slow down in an area where others may not anticipate it, touch the brake pedal to flash your brake light.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6278#6286#6408#6409","text":"Beep your horn#Flash your brake light#change lanes before slowing down#shoulder check your blind spot"},
{"testId":400,"qid":16128,"description":"If you unintentionally lock the back wheel on a good traction surface, you should:","explanation":"If the back wheel locks, do not try to unlock it. Maintain the lock until the motorbike comes to a complete stop.","imageName":"","correctAnswer":28372,"isBookmarked":0,"aid":"6510#6511#28371#28372","text":"Grab the front brake#Increase the throttle#Release the rear brake#Not release the rear brake"},
{"testId":400,"qid":16130,"description":"If the vehicle ahead of you suddenly stops, a ______-second following distance offers you the bare minimum amount of distance to stop or swerve.","explanation":"By leaving at least two seconds between you and the car in front of you, you allow yourself enough distance to stop or swerve if the vehicle in front of you abruptly stops.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#5002#6209#10514","text":"Two#Five#Three#One"},
{"testId":400,"qid":16131,"description":"What is the best approach to detect road bumps at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they seem to be bouncing up and down, the car is probably going over some bumps.","imageName":"","correctAnswer":30039,"isBookmarked":0,"aid":"8123#10682#22970#30039","text":"Use your high beam#Scan the road often#Only drive where there are street lights#Watch the taillights of the vehicle ahead of you"},
{"testId":400,"qid":16133,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"You are deemed inebriated if your blood alcohol content (BAC) is 0.08 percent or above.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#4653","text":"0.08%#0.09%#0.05%#0.01%"},
{"testId":400,"qid":16135,"description":"Crossing barriers at a _____ angle is safer.","explanation":"Crossing an inevitable road impediment at a 90-degree angle is the best option. This will prevent your front wheel from being deflected by the obstruction. If this is not practicable, pass the obstruction at a 45-degree angle.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10683#10684","text":"45-degree#90-degree#180-degree#25-degree"},
{"testId":400,"qid":16139,"description":"What should you do if you suspect another vehicle is attempting to share your lane?","explanation":"If you believe another car may attempt to share your space, ride in the middle lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22920#22921#22922","text":"Flash your brake light#Swerve from side to side#Ride in the center lane position#Use hand signals"},
{"testId":400,"qid":16140,"description":"What should you look for when merging into the middle lane of a three-lane road?","explanation":"When prepared to merge into the center lane of a three-lane road, examine both the middle and far lanes. Someone in the far lane may be preparing to move into the center lane as well.","imageName":"","correctAnswer":33600,"isBookmarked":0,"aid":"7225#7226#30440#33600","text":"Your throttle, to make sure it is not stuck#Your speed limit, to make sure you are not exceeding it#Your signal lights, to make sure they are working#The middle lane and the far lane"},
{"testId":400,"qid":16143,"description":"________ as you approach an intersection is the greatest technique to decrease response time.","explanation":"When in a dangerous scenario, covering the clutch and brake is the greatest approach to decrease response time.","imageName":"","correctAnswer":7539,"isBookmarked":0,"aid":"7537#7538#7539#7540","text":"Keep the clutch pulled in when not accelerating#Avoid wearing a full-face helmet#Cover the clutch and the brake#Avoid using the highest gear"},
{"testId":400,"qid":16146,"description":"It is dangerous for motorcycle riders  to travel:","explanation":"Intersections may be dangerous for motorcycle riders since many automobile operators are distracted and may not notice the motorbike.","imageName":"","correctAnswer":6645,"isBookmarked":0,"aid":"6645#10847#10848#10849","text":"At intersections#On freeways#In school zones#On rural roads"},
{"testId":400,"qid":16149,"description":"You should _______,when traversing a slick surface.","explanation":"When crossing a really slick surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#7815#28975#33601","text":"Use the front brake only#pull in the clutch and coast#accelerate or maintain your speed#stop and walk your motorcycle across"},
{"testId":400,"qid":16150,"description":"What is one cause of turning skidding?","explanation":"If you use too much braking in a turn, the wheel may lock and produce a skid.","imageName":"","correctAnswer":7968,"isBookmarked":0,"aid":"7967#7968#7969#7970","text":"Pulling in the clutch too quickly#Applying too much brake#Using the wrong gear#Using the wrong brake"},
{"testId":400,"qid":16154,"description":"What exactly does \"SEE\" stand for?","explanation":"The SEE approach consists of three steps: SEARCH your surroundings, EVALUATE the threats around you, and EXECUTE your choice.","imageName":"","correctAnswer":45611,"isBookmarked":0,"aid":"33616#33618#45610#45611","text":"Search, Examine, Execute#See, Execute, Examine#See, Evaluate, Examine#Search, Evaluate, Execute"},
{"testId":400,"qid":16155,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#28382","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footrests for balance"},
{"testId":400,"qid":16156,"description":"Position the motorcycle in a parallel parking area near the curb while parking it:","explanation":"When parking in a parallel parking area next to a curb, angle your motorbike such that the rear wheel is parallel to the curb. (Please keep in mind that certain localities require motorbikes to be parked parallel to the curb.)","imageName":"","correctAnswer":31983,"isBookmarked":0,"aid":"31982#31983#31984#31985","text":"at an angle with the front wheel to the curb#at an angle with the rear wheel to the curb#at an angle with the rear wheel on the curb#at an angle with the front wheel on the curb"},
{"testId":400,"qid":16157,"description":"Which of the following is the most likely place for a crash to happen?","explanation":"An junction is the most probable location for a collision. Car drivers often fail to see motorcycles.","imageName":"","correctAnswer":8358,"isBookmarked":0,"aid":"8355#8356#8357#8358","text":"On a curve#On a hill#On a one-way road#At an intersection"},
{"testId":400,"qid":16164,"description":"The best way around a curve is:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":24943,"isBookmarked":0,"aid":"7176#24942#24943#24944","text":"The center lane position#close to the center line#different for each curve#close to the edge of the road"},
{"testId":400,"qid":16165,"description":"You should double-check _____ when plans to slow down.","explanation":"Check to see whether there is traffic behind you before slowing down. If this is the case, you should flash your brake light to alert them that you are slowing down.","imageName":"","correctAnswer":24658,"isBookmarked":0,"aid":"10492#10493#10494#24658","text":"to make sure you are in neutral before stopping#to your left and right#the gauges on your motorcycle#if there is traffic behind you"},
{"testId":400,"qid":16166,"description":"When can you move in Minnesota if you feel your motorcycle has not activated a light sensor when stopped at a traffic signal?","explanation":"If you feel your motorbike did not activate a light sensor when stopped at a traffic signal in Minnesota, you may continue if all of the following requirements are met: (1) You have come to a complete stop; (2) The traffic signal has been flashing red for an unreasonable amount of time or appears to be malfunctioning; and (4) No motor vehicle or pedestrian is approaching on the roadway to be crossed or entered, or the vehicles and pedestrians are so far away that they do not pose an immediate hazard. As of this writing, 15 additional US states have implemented similar \"dead red\" rules that enable you to ride through a red light that has failed to identify your motorbike. Other states, however, have not. Always verify the local driving regulations while going out of state.","imageName":"","correctAnswer":32898,"isBookmarked":0,"aid":"32895#32898#33605#33606","text":"After coming to a complete stop#If all of the above conditions are met#If no motor vehicle or pedestrian is approaching on the roadway to be crossed or entered#If the traffic signal continues to show a red light for an unreasonable amount of time or is apparently malfunctioning"},
{"testId":400,"qid":16167,"description":"Check the chain on your motorcycle,:","explanation":null,"imageName":"","correctAnswer":8236,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":400,"qid":16168,"description":"If you are riding at night, you should:","explanation":"Riding at night should be done at a slower speed than during the day in order to notice risks ahead of time and respond promptly to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"7790#10559#10560#24714","text":"stay close behind the car in front of you#not use your headlight#wear a tinted face shield#ride slower than you do during the day"},
{"testId":404,"qid":16179,"description":"In which lane will the driver of the vehicle in front of you be able to see you the best?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":408,"qid":16179,"description":"In which lane will the driver of the vehicle in front of you be able to see you the best?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":412,"qid":16200,"description":"The regulations of riding in a group include:","explanation":"Checking your mirrors regularly, riding in a staggered configuration when feasible, and not following too closely are all guidelines of group riding.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7935#8615#25872#25873","text":"checking your mirrors frequently#All of the above#maintaining a staggered formation#not following too closely"},
{"testId":442,"qid":16200,"description":"The regulations of riding in a group include:","explanation":"Checking your mirrors regularly, riding in a staggered configuration when feasible, and not following too closely are all guidelines of group riding.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"7935#8615#25872#25873","text":"checking your mirrors frequently#All of the above#maintaining a staggered formation#not following too closely"},
{"testId":85,"qid":16212,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6235#8441#8442#28385","text":"Flashing your brake light#honking your horn#swerving from left to right#using your turn signals"},
{"testId":487,"qid":16212,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6235#8441#8442#28385","text":"Flashing your brake light#honking your horn#swerving from left to right#using your turn signals"},
{"testId":503,"qid":16212,"description":"You can let other vehicles know you are going slower by:","explanation":"By flashing your brake light, you may alert other drivers that you are slowing down. This is particularly critical when slowing down suddenly or in unfamiliar locations.","imageName":"","correctAnswer":6235,"isBookmarked":0,"aid":"6235#8441#8442#28385","text":"Flashing your brake light#honking your horn#swerving from left to right#using your turn signals"},
{"testId":412,"qid":16217,"description":"What effect would carrying a passenger have on your motorcycle?","explanation":"A passenger will add weight to the motorbike. As a result, the motorbike will take longer to respond to changes such as slowing or stopping.","imageName":"","correctAnswer":30093,"isBookmarked":0,"aid":"30093#30464#30465#33620","text":"The motorcycle will take longer to react to changes#It will help balance the motorcycle#The motorcycle will go faster#The motorcycle will react faster to changes"},
{"testId":420,"qid":16217,"description":"What effect would carrying a passenger have on your motorcycle?","explanation":"A passenger will add weight to the motorbike. As a result, the motorbike will take longer to respond to changes such as slowing or stopping.","imageName":"","correctAnswer":30093,"isBookmarked":0,"aid":"30093#30464#30465#33620","text":"The motorcycle will take longer to react to changes#It will help balance the motorcycle#The motorcycle will go faster#The motorcycle will react faster to changes"},
{"testId":416,"qid":16232,"description":"Which colors of clothing are most visible to other road users?","explanation":"Orange, red, yellow, and green are the greatest apparel colors for improving your visibility to others.","imageName":"","correctAnswer":28414,"isBookmarked":0,"aid":"28411#28413#28414#33923","text":"Orange, yellow, and white#White, red, and orange#Orange, red, yellow, and green#Red, yellow, and brown"},
{"testId":412,"qid":16242,"description":"The easiest strategy to reduce reaction time when approaching an intersection is to:","explanation":"When in a dangerous scenario, covering the clutch and brake is the greatest approach to decrease response time.","imageName":"","correctAnswer":7539,"isBookmarked":0,"aid":"7537#7538#7539#7540","text":"Keep the clutch pulled in when not accelerating#Avoid wearing a full-face helmet#Cover the clutch and the brake#Avoid using the highest gear"},
{"testId":416,"qid":16257,"description":"Half of all motorcycle accidents are the result of:","explanation":"Cars entering the rider's right-of-way cause half of all motorcycle accidents. When approaching a junction, proceed with care.","imageName":"","correctAnswer":33932,"isBookmarked":0,"aid":"8187#8188#28953#33932","text":"faulty traffic signals#not wearing proper gear#riding under the influence#cars entering the rider's right-of-way"},
{"testId":425,"qid":16257,"description":"Half of all motorcycle accidents are the result of:","explanation":"Cars entering the rider's right-of-way cause half of all motorcycle accidents. When approaching a junction, proceed with care.","imageName":"","correctAnswer":33932,"isBookmarked":0,"aid":"8187#8188#28953#33932","text":"faulty traffic signals#not wearing proper gear#riding under the influence#cars entering the rider's right-of-way"},
{"testId":442,"qid":16284,"description":"Which brake is the strongest?","explanation":"The front brake accounts for around three-quarters of the stopping power of your motorbike. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":33935,"isBookmarked":0,"aid":"26505#33934#33935#33936","text":"It depends on the situation#The rear brake is more powerful#The front brake is more powerful#The front and rear brakes are equally powerful"},
{"testId":450,"qid":16284,"description":"Which brake is the strongest?","explanation":"The front brake accounts for around three-quarters of the stopping power of your motorbike. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":33935,"isBookmarked":0,"aid":"26505#33934#33935#33936","text":"It depends on the situation#The rear brake is more powerful#The front brake is more powerful#The front and rear brakes are equally powerful"},
{"testId":404,"qid":16323,"description":"Riding beside an automobile might be hazardous because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":33943,"isBookmarked":0,"aid":"11166#30522#33943#33944","text":"your motorcycle may skid#the car driver won't be able to turn#you may be in a blind spot#your view may be obstructed"},
{"testId":404,"qid":16324,"description":"You should _________ if one of your motorcycle's tires goes flat while you are riding.","explanation":"If one of your motorcycle tires goes flat while you're riding, hold the handgrips firmly, ease off the throttle, and maintain a straight path. Allow the motorbike to slow down or gently use the brake of the non-flat tire.","imageName":"","correctAnswer":22866,"isBookmarked":0,"aid":"6515#11159#22866#33945","text":"Increase your speed#change your direction#hold the handgrips firmly#apply both brakes quickly"},
{"testId":404,"qid":16325,"description":"Which lane position should you ride in when prepared to pass a vehicle?","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":404,"qid":16327,"description":"Your arms should be positioned _________, while clutching the handlebars.","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":6269,"isBookmarked":0,"aid":"6268#6269#22937#24587","text":"with elbows locked#so they are slightly bent#anywhere on the handgrips#with your elbows at 90 degrees"},
{"testId":404,"qid":16328,"description":"What kind of clothing is better for ridingduring the day?","explanation":"Wear neon apparel when cycling during the day to maximize your visibility to other cars.","imageName":"","correctAnswer":6277,"isBookmarked":0,"aid":"6274#6275#6276#6277","text":"Leather clothing#Colorful clothing#Any type of clothing#Fluorescent clothing"},
{"testId":404,"qid":16330,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other vehicles from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":404,"qid":16331,"description":"Which of the following is prohibited when riding downhill?","explanation":"All motor vehicles in Mississippi are prohibited from coasting downhill in neutral.","imageName":"","correctAnswer":17394,"isBookmarked":0,"aid":"7466#7468#7469#17394","text":"Shifting gears#Increasing your speed#Slowing down#Coasting in neutral"},
{"testId":404,"qid":16332,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, the simplest approach to stop it is to gently shut the throttle. Braking may exacerbate the wobble, and attempting to accelerate out of it might make the motorbike more unstable.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6423#6432#6433#6569","text":"Apply the rear brake#Close the throttle gradually#Accelerate out of the wobble#Apply the front brake"},
{"testId":404,"qid":16333,"description":"How can you assist balance the motorcycle during gradual turns?","explanation":"By keeping your body straight in slow turns, you can help balance your motorcycle.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10630#10631","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean forward#Keep your body straight"},
{"testId":475,"qid":16333,"description":"How can you assist balance the motorcycle during gradual turns?","explanation":"By keeping your body straight in slow turns, you can help balance your motorcycle.","imageName":"","correctAnswer":10631,"isBookmarked":0,"aid":"7901#10629#10630#10631","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean forward#Keep your body straight"},
{"testId":404,"qid":16334,"description":"What's the best way to detecting bumps in the road at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they seem to be up and down shifting, the vehicle is probably travelling over humps.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"10581#22794#22795#28204","text":"Wear reflective clothing#Watch the taillights of the car ahead of you#Stay in low gears#Use your low-beam headlight"},
{"testId":404,"qid":16338,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7287#7288#7289#10572","text":"drink beverages with caffeine#avoid making long trips#take frequent rest breaks#Ride only during the day"},
{"testId":404,"qid":16339,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist in a parked car while driving by, blow your horn to alert the driver that you are approaching. Then he or she will not open a door in your direction.","imageName":"","correctAnswer":22979,"isBookmarked":0,"aid":"10910#10912#22979#30453","text":"Stop until the driver has exited the vehicle#Speed up to pass the vehicle quickly#Beep your horn to let the driver know you're there#Ride in the right lane position to be close to the vehicle"},
{"testId":404,"qid":16340,"description":"Which of the following is NOT required to transport a passenger?","explanation":"To transport a passenger, your motorbike must have a set of footrests for the individual. In addition, the seat must be large enough to accommodate two persons. All motorcyclists and passengers in Mississippi are required by law to wear helmets.","imageName":"","correctAnswer":23794,"isBookmarked":0,"aid":"7115#7356#11031#23794","text":"A helmet#Passenger footrests#Designated seat room#A backpack"},
{"testId":404,"qid":16341,"description":"Check the brake hydraulic fluid and coolant levels _______","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":404,"qid":16342,"description":"If an unanticipated barrier forces you to make a rapid left turn, you should:","explanation":"If an unanticipated obstruction forces you to turn left fast, hold the left handgrip. This will force your motorbike to momentarily steer right, causing it to tilt to the left. Then, with the motorbike, lean.","imageName":"","correctAnswer":24804,"isBookmarked":0,"aid":"6423#6569#24803#24804","text":"Apply the rear brake#Apply the front brake#press the right handgrip#press the left handgrip"},
{"testId":404,"qid":16344,"description":"When traveling with others, you should NEVER ride:","explanation":"When traveling in a group, never pair up (ride two abreast). To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7399,"isBookmarked":0,"aid":"6736#7198#7399#33946","text":"single file#in a staggered formation#two abreast#with more than three other motorcyclists"},
{"testId":408,"qid":16344,"description":"When traveling with others, you should NEVER ride:","explanation":"When traveling in a group, never pair up (ride two abreast). To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7399,"isBookmarked":0,"aid":"6736#7198#7399#33946","text":"single file#in a staggered formation#two abreast#with more than three other motorcyclists"},
{"testId":404,"qid":16348,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your ability to bike.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":404,"qid":16350,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":404,"qid":16351,"description":"Your feet should be positioned__________ while riding a motorcycle.","explanation":"Your feet should be firmly placed on the footrests when cycling.","imageName":"","correctAnswer":6175,"isBookmarked":0,"aid":"6175#6176#7735#10803","text":"firmly on the footrests#as far forward as possible#hovering above the ground in case you tip#pointing down for easy shifting"},
{"testId":84,"qid":16358,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":404,"qid":16358,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":446,"qid":16358,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":467,"qid":16358,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":479,"qid":16358,"description":"When should you adjust your side mirrors?","explanation":"After mounting your motorbike but before starting it, adjust your side mirrors.","imageName":"","correctAnswer":24648,"isBookmarked":0,"aid":"8381#8383#24648#24649","text":"While riding your motorcycle#At a designated service center#Before starting your motorcycle#Before mounting your motorcycle"},
{"testId":404,"qid":16363,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":26515,"isBookmarked":0,"aid":"10381#24708#26515#33947","text":"grip the backrest#wear a seat belt#hold on firmly to your waist, hips, or belt#put his or her feet on the ground when the motorcycle stops"},
{"testId":404,"qid":16364,"description":"What effect would carrying a passenger have on your motorcycle?","explanation":"Passengers add weight to the motorbike. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":30093,"isBookmarked":0,"aid":"30093#30464#30465#33620","text":"The motorcycle will take longer to react to changes#It will help balance the motorcycle#The motorcycle will go faster#The motorcycle will react faster to changes"},
{"testId":404,"qid":16365,"description":"When getting ready to pass, _____ will give you a better view.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":404,"qid":16367,"description":"When should you share a lane with another vehicle?","explanation":"You should never ride directly beside another motorbike in the same lane, even though Mississippi state law does not clearly ban it. If you do, you may be unable to move in time to escape a danger on the road. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#33948#33949#33950","text":"Never#If you've agreed beforehand to do so#If traffic is too heavy#If you need to speak to the other motorcyclist"},
{"testId":404,"qid":16369,"description":"You change gears by depressing the clutch and pressing the:","explanation":"Your left foot is used to manipulate the shifting lever. The rear brake is operated by the right foot pedal.","imageName":"","correctAnswer":31788,"isBookmarked":0,"aid":"7117#7863#7864#31788","text":"right foot pedal#rear brake lever#front brake lever#left foot lever"},
{"testId":404,"qid":16370,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Motorcycles have shorter stopping distances than large, heavier vehicles. If you abruptly slow down in front of a huge car, it may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10565#10894#26488","text":"slowing down suddenly when you merge back in front of the vehicle#passing without a dedicated passing lane#flashing your headlight#beeping your horn when you are alongside the vehicle"},
{"testId":412,"qid":16370,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Motorcycles have shorter stopping distances than large, heavier vehicles. If you abruptly slow down in front of a huge car, it may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10565#10894#26488","text":"slowing down suddenly when you merge back in front of the vehicle#passing without a dedicated passing lane#flashing your headlight#beeping your horn when you are alongside the vehicle"},
{"testId":408,"qid":16385,"description":"If your front tire blows out while riding, you should reduce your speed,:","explanation":"If your front tire fails while you're riding, slow down and use the rear brake to get to the side of the road. Never use a flat tire's brake.","imageName":"","correctAnswer":24745,"isBookmarked":0,"aid":"24745#24746#24747#31506","text":"use the rear brake, and move to the side of the road#use the front brake, and move to the side of the road#pull the kill engine switch, and roll to a stop#use both the front and rear brakes, and stop on the shoulder of the road"},
{"testId":76,"qid":16396,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":294,"qid":16396,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":462,"qid":16396,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":467,"qid":16396,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":77,"qid":16449,"description":"What are the three steps of the SEE strategy?","explanation":"The SEE method is a three-step procedure for making proper decisions and correctly executing them in diverse traffic conditions. The SEE technique consists of three steps: search, evaluate, and execute.","imageName":"","correctAnswer":33615,"isBookmarked":0,"aid":"33615#33616#33617#33618","text":"Search, Evaluate, Execute#Search, Examine, Execute#See, Evaluate, Examine#See, Execute, Examine"},
{"testId":412,"qid":16461,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards. When riding through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":408,"qid":16468,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the back wheel on a traction-rich surface, you may keep it locked until you come to a complete stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":420,"qid":16478,"description":"When you have a passenger on board, your motorcycle will:","explanation":"Your motorbike will be heavier and react slower than normal while carrying a passenger. As a result, you should ride more slowly, particularly when approaching bumps, bends, or curves.","imageName":"","correctAnswer":28963,"isBookmarked":0,"aid":"6440#28963#28964#28965","text":"easily tip over#respond slower than usual#respond quicker than usual#respond as it would without a passenger"},
{"testId":471,"qid":16493,"description":"To alert drivers that you are slowing down in an unexpected spot, you should:","explanation":"Tap the brake pedal to flash your brake light to the car behind you to alert them that you are slowing down in an odd location.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7482#7483#22897","text":"Flash your brake light#swerve from side to side#shift gears instead of braking#rise slightly off your seat"},
{"testId":412,"qid":16494,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":294,"qid":16516,"description":"When it begins to rain, the best location to ride is :","explanation":"When it begins to rain, the safest area to ride is in other cars' tire tracks. These are less prone to slipping.","imageName":"","correctAnswer":28999,"isBookmarked":0,"aid":"7310#7312#7313#28999","text":"in the center lane position#on the shoulder of the road#on main roads only#in the tire tracks of other vehicles"},
{"testId":408,"qid":16521,"description":"When riding your motorcycle in high-risk locations, you should cover ________ to reduce the amount of time it takes you to react.","explanation":"When riding in high-risk locations, cover the clutch and both brakes to reduce the amount of time you need to respond.","imageName":"","correctAnswer":6122,"isBookmarked":0,"aid":"6121#6122#6123#6124","text":"your eyes with goggles#the clutch and both brakes#your head with a helmet#your whole travel lane"},
{"testId":462,"qid":16521,"description":"When riding your motorcycle in high-risk locations, you should cover ________ to reduce the amount of time it takes you to react.","explanation":"When riding in high-risk locations, cover the clutch and both brakes to reduce the amount of time you need to respond.","imageName":"","correctAnswer":6122,"isBookmarked":0,"aid":"6121#6122#6123#6124","text":"your eyes with goggles#the clutch and both brakes#your head with a helmet#your whole travel lane"},
{"testId":408,"qid":16523,"description":"When should you avoid the staggered formation when riding in a group?","explanation":"When entering or departing highways, navigating bends, or turning in a group, avoid riding in a staggered pattern.","imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"6336#6337#23040#34233","text":"Taking curves#Making turns#Doing any of the above#Entering or exiting highways"},
{"testId":408,"qid":16524,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"6506#8126#30833#30834","text":"turning too quickly#locking the rear wheel#using only the rear brake#locking the front wheel"},
{"testId":483,"qid":16524,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"6506#8126#30833#30834","text":"turning too quickly#locking the rear wheel#using only the rear brake#locking the front wheel"},
{"testId":408,"qid":16526,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"By utilizing your headlight, signal lights, flashing your brake light, and wearing bright apparel, you may boost your visibility to other drivers.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10718#10784#10785#23000","text":"Flash your brake light#Wear bright clothing#Use signal lights#Do all of the above"},
{"testId":408,"qid":16528,"description":"When stopping at a stop sign on a regular basis, you should:","explanation":"When coming to a halt at a stop sign, utilize both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#28935#34234","text":"use the rear brake only#Use the front brake only#use both the front and rear brakes#downshift until you're rolling to a stop"},
{"testId":408,"qid":16530,"description":"How many lane positions does a motorcycle have in a traffic lane?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":408,"qid":16531,"description":"Which brake should you use to slow down?","explanation":"Use both the front and rear brakes to slow down. Despite the fact that the front brake is more powerful than the rear brake, both should be utilized.","imageName":"","correctAnswer":24827,"isBookmarked":0,"aid":"6830#6831#24827#34235","text":"Front brake only#Rear brake only#Both the front and rear brakes at the same time#Either the front brake or the rear brake"},
{"testId":408,"qid":16533,"description":"The gearshift lever can be found :","explanation":"The shifting lever is situated just in front of the left footrest.","imageName":"","correctAnswer":6980,"isBookmarked":0,"aid":"6979#6980#24908#24909","text":"in front of the right footrest#in front of the left footrest#by the left handgrip#by the right handgrip"},
{"testId":408,"qid":16534,"description":"In addition to downshifting when you slow or halt, you should also downshift :","explanation":"When you need to slow down or halt, or if you need more power to accelerate, you should downshift.","imageName":"","correctAnswer":6983,"isBookmarked":0,"aid":"6982#6983#26117#34236","text":"whenever you pull in the clutch#when you need more power to accelerate#while riding on a multilane road#when you are changing lanes"},
{"testId":408,"qid":16535,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, you should bike in lane position two (middle) or three (right) to avoid them.","imageName":"","correctAnswer":29452,"isBookmarked":0,"aid":"28388#28390#28391#29452","text":"One (left) or two (center)#Two (center)#Three (right)#Two (center) or three (right)"},
{"testId":408,"qid":16540,"description":"The most effective technique to deal with a tailgater is to:","explanation":"If you are being tailgated, change lanes or slow down to let the tailgater to pass you.","imageName":"202006041812161292.jpg","correctAnswer":31778,"isBookmarked":0,"aid":"6278#31778#31779#31780","text":"Beep your horn#allow the tailgater to pass you#speed up to match the tailgater's speed#stop and argue with the tailgater"},
{"testId":408,"qid":16541,"description":"In a turn, rolling on the throttle:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a curve or bend. This aids in the stability of the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#7489#7490#7491","text":"helps stabilize the motorcycle#should not be done#should be done only in small curves#is for experienced riders only"},
{"testId":408,"qid":16544,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":34237,"isBookmarked":0,"aid":"7802#32353#33582#34237","text":"sit as far back as possible#avoid holding on to you#put his or her feet down when the motorcycle stops#keep his or her feet on the footrests at all times"},
{"testId":408,"qid":16545,"description":"If you notice a motorist inside a parked vehicle while driving by, you should :","explanation":"Car drivers often fail to see bikes. If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. Then he or she will not open a door in your direction.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#7650#7651","text":"Beep your horn#Flash your brake light#wave your arms#Come to a complete stop"},
{"testId":408,"qid":16546,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"Crosswalk pedestrians have the right of way. If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":408,"qid":16547,"description":"When starting your motorcycle on a hill, utilize _______ to keep you steady while you wait for the engine to catch.","explanation":"When starting your motorbike on a slope, use the front brake to keep you steady while you wait for the engine to catch up. Switch to the rear brake to keep the motorbike in place while you utilize the throttle.","imageName":"","correctAnswer":6146,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":77,"qid":16551,"description":"How might the car ahead of you assist you in detecting obstacles at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they bounce, you should be aware that there are potholes in the road.","imageName":"","correctAnswer":34238,"isBookmarked":0,"aid":"10924#24732#24734#34238","text":"Watch the driver's movements#Follow in the path of the car#Follow the car very closely#Watch its taillights to detect bumps in the road"},
{"testId":408,"qid":16551,"description":"How might the car ahead of you assist you in detecting obstacles at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they bounce, you should be aware that there are potholes in the road.","imageName":"","correctAnswer":34238,"isBookmarked":0,"aid":"10924#24732#24734#34238","text":"Watch the driver's movements#Follow in the path of the car#Follow the car very closely#Watch its taillights to detect bumps in the road"},
{"testId":77,"qid":16553,"description":"Your ability to ride a motorbike may be affected by:","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":303,"qid":16553,"description":"Your ability to ride a motorbike may be affected by:","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":408,"qid":16553,"description":"Your ability to ride a motorbike may be affected by:","explanation":"Prescription and over-the-counter medicines, as well as illicit substances and alcohol, may all impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":408,"qid":16555,"description":"If a vehicle comes into your path, you should:","explanation":"If a vehicle comes into your path, move as far away from it as possible.","imageName":"","correctAnswer":22806,"isBookmarked":0,"aid":"6278#6286#8251#22806","text":"Beep your horn#Flash your brake light#tailgate the vehicle#move away from the vehicle"},
{"testId":408,"qid":16556,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8297#8298#10629#28382","text":"Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle#Take your feet off the footrests for balance"},
{"testId":408,"qid":16561,"description":"You must activate your signal lights:","explanation":"When making a turn or changing lanes, you must utilize your turn signals.","imageName":"","correctAnswer":34239,"isBookmarked":0,"aid":"6793#23028#32394#34239","text":"only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around#whenever you plan to turn or change lanes"},
{"testId":408,"qid":16562,"description":"Your motorcycle's tires should be inspected:","explanation":"Every time you ride your motorbike, you should inspect the tires. A tire issue may be even more dangerous for a motorbike rider than it is for a vehicle driver.","imageName":"","correctAnswer":10331,"isBookmarked":0,"aid":"8237#8239#10331#10332","text":"Once a month#Once a week#Every time you ride#At the start of every season"},
{"testId":408,"qid":16564,"description":"How can you make yourself more visible to others at night?","explanation":"Wear luminous clothing at night to increase your visibility. Fluorescent clothes may be difficult to spot in the dark.","imageName":"","correctAnswer":22884,"isBookmarked":0,"aid":"22882#22883#22884#22885","text":"Avoid wearing a tinted face shield#Wear fluorescent clothing#Wear reflective gear#Wear a bright helmet"},
{"testId":408,"qid":16565,"description":"If you want to ride at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":420,"qid":16565,"description":"If you want to ride at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":408,"qid":16566,"description":"Should two or more motorcyclists ever ride in a single lane abreast?","explanation":"Never ride with another motorcycle. To operate properly, each motorbike need the whole width of the lane. To avoid a danger on the road, you may need to use the whole width of the lane.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#32812#34240#34241","text":"No#Yes, but only in rural areas#Yes, but only two at a time#Yes, whenever they wish"},
{"testId":408,"qid":16567,"description":"When can you move in Missouri if you feel your motorcycle did not trigger a light sensor when stopped at a traffic signal?","explanation":"If you think your motorbike did not activate a light sensor while stopped at a traffic signal in Missouri, you may go only if all of the following requirements are met: (1) You have come to a complete stop; (2) the traffic light has remained red for an unreasonable period of time; (3) the traffic light appears to be malfunctioning or has failed to detect the arrival of the motorcycle; and (4) no pedestrian or motor vehicle is approaching on the street or highway to be crossed or entered or is so far away from the intersection that it does not pose an immediate hazard. At the time of writing, only 16 states, including Missouri, had implemented \"dead red\" rules that allowed you to go through a red light under specific situations. And several of these governments have set other restrictions. Always verify the local driving regulations while going out of state.","imageName":"","correctAnswer":32898,"isBookmarked":0,"aid":"32898#34242#34243#34244","text":"If all of the above conditions are met#After you have come to a complete stop#If no pedestrian or motor vehicle is approaching#If the traffic light stays red for an unreasonable time or is apparently malfunctioning"},
{"testId":80,"qid":16568,"description":"The chain on your motorcycle should be inspected,:","explanation":null,"imageName":"","correctAnswer":8236,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":408,"qid":16568,"description":"The chain on your motorcycle should be inspected,:","explanation":null,"imageName":"","correctAnswer":8236,"isBookmarked":0,"aid":"8236#8237#8238#8239","text":"before every ride#Once a month#at the start of each season#Once a week"},
{"testId":408,"qid":16569,"description":"To safely stop on a curve, you should:","explanation":"When coming to a halt on a curve, strive to keep your motorbike upright. Stopping while leaning is dangerous.","imageName":"","correctAnswer":33207,"isBookmarked":0,"aid":"6901#6990#24685#33207","text":"Keep your knees against the gas tank#lean the opposite way of the motorcycle#take your feet off the footrests for balance#straighten the motorcycle upright"},
{"testId":408,"qid":16570,"description":"You should NOT _____  to transport cargo.","explanation":"Attaching baggage to the sissy bar elevates the motorcycle's center of gravity, which might throw the bike off balance. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":34245,"isBookmarked":0,"aid":"34245#34246#34247#34248","text":"fasten cargo to the sissy bar#secure cargo as low as possible#fill saddlebags evenly#secure cargo with elastic cords"},
{"testId":412,"qid":16574,"description":"You should turn _____ when riding through a turn.","explanation":"Corner your head only while cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":6465,"isBookmarked":0,"aid":"6463#6464#6465#8615","text":"your head and shoulders#your whole upper body#your head only#All of the above"},
{"testId":420,"qid":16581,"description":"Which of the following can aggravate a wobble?","explanation":"If you have a wobble, don't attempt to speed out of it; doing so may make the motorbike much more unstable. Also, don't attempt to stop; doing so may exacerbate the wobbling. Instead, gently shut the throttle and let your motorbike to slow down.","imageName":"","correctAnswer":34251,"isBookmarked":0,"aid":"34250#34251#34252#34253","text":"Unpaved roads#Braking#Closing the throttle gradually#Riding beside another vehicle"},
{"testId":442,"qid":16591,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#10878","text":"Two#Six#Five#Seven"},
{"testId":425,"qid":16596,"description":"How can you maximize straight-line braking?","explanation":"To obtain maximum straight-line braking, completely engage both the front and rear brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"22821#22822#22823#30525","text":"Apply only the front brake#Apply only the rear brake#Pull the kill engine switch#Fully apply both the front and rear brakes"},
{"testId":77,"qid":16598,"description":"You should inspect _________  before mounting the motorcycle.","explanation":"Before mounting your motorcycle, you should check the tires and the lights. These must all be in good working order on each trip because their failure could be extremely dangerous.","imageName":"","correctAnswer":10768,"isBookmarked":0,"aid":"8016#8017#10768#34255","text":"the mirrors and the exhaust#the windshield and the kickstand#the tires and the lights#the footrests and the gas level"},
{"testId":434,"qid":16598,"description":"You should inspect _________  before mounting the motorcycle.","explanation":"Before mounting your motorcycle, you should check the tires and the lights. These must all be in good working order on each trip because their failure could be extremely dangerous.","imageName":"","correctAnswer":10768,"isBookmarked":0,"aid":"8016#8017#10768#34255","text":"the mirrors and the exhaust#the windshield and the kickstand#the tires and the lights#the footrests and the gas level"},
{"testId":450,"qid":16598,"description":"You should inspect _________  before mounting the motorcycle.","explanation":"Before mounting your motorcycle, you should check the tires and the lights. These must all be in good working order on each trip because their failure could be extremely dangerous.","imageName":"","correctAnswer":10768,"isBookmarked":0,"aid":"8016#8017#10768#34255","text":"the mirrors and the exhaust#the windshield and the kickstand#the tires and the lights#the footrests and the gas level"},
{"testId":412,"qid":16607,"description":"For riding curves, the optimal group formation is:","explanation":"Riding through bends should be done in single file. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":293,"qid":16615,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":22887,"isBookmarked":0,"aid":"6827#7671#22886#22887","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent at the elbows"},
{"testId":425,"qid":16615,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":22887,"isBookmarked":0,"aid":"6827#7671#22886#22887","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent at the elbows"},
{"testId":442,"qid":16615,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":22887,"isBookmarked":0,"aid":"6827#7671#22886#22887","text":"positioned with your hands above your elbows#locked at the elbows#bent at a 90-degree angle#slightly bent at the elbows"},
{"testId":416,"qid":16646,"description":"A group of motorcycle riders should not exceed ______ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#10878","text":"Two#Six#Five#Seven"},
{"testId":420,"qid":16696,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, remove the front brake and smoothly reapply it to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#28929","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#continue applying the front brake"},
{"testId":412,"qid":16721,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should examine your course of travel 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not seem like a long time, but at 60 mph, your motorbike will cover 1,056 feet in that time.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#6077#6078#6079","text":"12 seconds#60 seconds#40 seconds#25 seconds"},
{"testId":416,"qid":16721,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should examine your course of travel 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not seem like a long time, but at 60 mph, your motorbike will cover 1,056 feet in that time.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#6077#6078#6079","text":"12 seconds#60 seconds#40 seconds#25 seconds"},
{"testId":412,"qid":16723,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"When you have a passenger on board, your motorbike will react more slowly. As a result, you should ride more slowly, particularly when approaching bumps, bends, or curves.","imageName":"","correctAnswer":29479,"isBookmarked":0,"aid":"29479#29480#29481#29482","text":"Ride slower#Ride faster#Only ride in the center lane position#Only ride in the left lane position"},
{"testId":412,"qid":16724,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your car stable while you use your right hand to handle the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":412,"qid":16729,"description":"When should you engage both the front and rear brakes simultaneously?","explanation":"Unless a tire is flat, you should always apply both the front and rear brakes to stop. This is the most successful method of stopping. Never, however, apply the brakes on a flat tire. If you know which tire is flat, gently apply the other tire's brake.","imageName":"","correctAnswer":25870,"isBookmarked":0,"aid":"22814#22815#22816#25870","text":"In emergencies only#On hills and curves#Never at the same time#At all times, unless you have a flat tire"},
{"testId":412,"qid":16735,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to allow yourself more time to respond.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":412,"qid":16738,"description":"The most effective approach to be aware while riding a motorcycle is to:","explanation":"Taking regular rest breaks is the greatest method to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7289,"isBookmarked":0,"aid":"7217#7289#10572#31493","text":"drink caffeinated beverages#take frequent rest breaks#Ride only during the day#avoid taking long trips"},
{"testId":412,"qid":16739,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Ride with your headlights on and in a lane position that allows you to see incoming traffic. Never rely on eye contact to indicate that a motorist will yield. Even when cars gaze straight at motorcyclists, they often fail to spot them.","imageName":"","correctAnswer":34551,"isBookmarked":0,"aid":"34548#34549#34550#34551","text":"You can count on eye contact as a sign that a driver will yield#You should ride with your headlight off#You should increase your speed as you approach the intersection#You should ride in a lane position that will most increase your visibility to other road users"},
{"testId":416,"qid":16739,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Ride with your headlights on and in a lane position that allows you to see incoming traffic. Never rely on eye contact to indicate that a motorist will yield. Even when cars gaze straight at motorcyclists, they often fail to spot them.","imageName":"","correctAnswer":34551,"isBookmarked":0,"aid":"34548#34549#34550#34551","text":"You can count on eye contact as a sign that a driver will yield#You should ride with your headlight off#You should increase your speed as you approach the intersection#You should ride in a lane position that will most increase your visibility to other road users"},
{"testId":412,"qid":16740,"description":"The available lane locations for a motorcycle inside a lane are:","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":425,"qid":16740,"description":"The available lane locations for a motorcycle inside a lane are:","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":412,"qid":16746,"description":"What is a reasonable following distance in heavy, congested traffic?","explanation":"In excellent riding circumstances, the Montana Motorcycle Supplement advises a two-second following distance and a three-second following distance in heavy traffic.","imageName":"","correctAnswer":10880,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":412,"qid":16747,"description":"In a turn, rolling on the throttle:","explanation":"Turning the throttle helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#28402#34552","text":"helps stabilize the motorcycle#should only be done in emergencies#is done while shifting gears#is done to pass another vehicle"},
{"testId":416,"qid":16747,"description":"In a turn, rolling on the throttle:","explanation":"Turning the throttle helps to steady the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#28402#34552","text":"helps stabilize the motorcycle#should only be done in emergencies#is done while shifting gears#is done to pass another vehicle"},
{"testId":412,"qid":16751,"description":"Which one of the following controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":412,"qid":16754,"description":"Intersections that are blind are risky because:","explanation":"Blind crossings are risky because other drivers may not be able to see you from certain regions. Ride in a lane that allows you to be spotted as quickly as possible.","imageName":"","correctAnswer":27941,"isBookmarked":0,"aid":"7352#7353#24647#27941","text":"U-turns are not permitted#they are not controlled by traffic signs#they are high-speed intersections#other vehicles will have difficulty seeing you in certain areas"},
{"testId":412,"qid":16755,"description":"Is sharing a lane with another motorbike safe?","explanation":"Sharing a lane with another motorbike is dangerous (or any other vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":412,"qid":16765,"description":"When riding in a group, the most secure arrangement is:","explanation":"When riding in a group, the staggered configuration is the ideal option. It allows you to keep near while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":412,"qid":16766,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"Ropes should not be used to secure goods to a motorbike. Ropes may weaken and knots can come undone over time. It is preferable to utilize elastic cords.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":412,"qid":16768,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":412,"qid":16769,"description":"To safely stop on a curve, you should:","explanation":"Straighten the motorbike upright to safely stop on a curve. Stopping while leaning might be risky.","imageName":"","correctAnswer":33207,"isBookmarked":0,"aid":"6901#6990#24685#33207","text":"Keep your knees against the gas tank#lean the opposite way of the motorcycle#take your feet off the footrests for balance#straighten the motorcycle upright"},
{"testId":511,"qid":16784,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6531,"isBookmarked":0,"aid":"6186#6530#6531#10807","text":"During the turn#After the turn#Before the turn#Before, during, or after the turn"},
{"testId":450,"qid":16788,"description":"You are about to hit something and need to change direction to avoid it. How do you start a swerve in the best way?","explanation":"If you are in danger of colliding with anything, the best approach to commence a swerve is to apply little pressure on the handgrip on the side of the targeted swerve. As a result, your motorbike will tilt sharply in that direction.","imageName":"","correctAnswer":24752,"isBookmarked":0,"aid":"10742#24597#24752#34228","text":"Lean sharply in the opposite direction from which you need to swerve#Lean sharply in the direction in which you need to swerve#Apply a small amount of pressure to the handgrip on the side of your intended swerve#Apply a small amount of pressure to the handgrip on the opposite side of your intended swerve"},
{"testId":429,"qid":16795,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":10907,"isBookmarked":0,"aid":"10906#10907#10909#32687","text":"It gives you the best view of oncoming trains#At a lesser angle, you may catch your tires#A larger angle would be unsafe#It lets you cross the tracks quickly"},
{"testId":446,"qid":16803,"description":"The front brake is more crucial because:","explanation":"The front brake is more crucial since it provides the majority of the stopping force (70 percent). To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7898,"isBookmarked":0,"aid":"7897#7898#7899#7900","text":"it provides all of the stopping power#it provides most of the stopping power#it is used most often#it provides less of the stopping power"},
{"testId":420,"qid":16830,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#32394","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around"},
{"testId":425,"qid":16830,"description":"Make use of your turn signals:","explanation":"Use your turn signals if you intend to turn or change lanes.","imageName":"","correctAnswer":6792,"isBookmarked":0,"aid":"6792#6793#23028#32394","text":"every time you plan to turn or change lanes#only if you may not be seen by other vehicles#in cities but not in rural areas#whenever other vehicles are around"},
{"testId":434,"qid":16873,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your car stable while you use your right hand to handle the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":511,"qid":16873,"description":"When starting a motorcycle uphill, switch to ________ to hold the motorcycle while operating the throttle.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your car stable while you use your right hand to handle the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6147,"isBookmarked":0,"aid":"6146#6147#34546#34547","text":"the front brake#the rear brake#both the front and rear brakes#either the front brake or the rear brake"},
{"testId":303,"qid":16920,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Larger, heavier vehicles need a greater stopping distance than motorbikes. If you abruptly slow down in front of a huge car, the large vehicle may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10564#10565#10894","text":"slowing down suddenly when you merge back in front of the vehicle#beeping your horn when alongside the vehicle#passing without a dedicated passing lane#flashing your headlight"},
{"testId":416,"qid":16924,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake provides 75 percent of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":416,"qid":16925,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, carefully close the throttle. Fighting the wobble, speeding, or braking fast might aggravate it.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6431#6432#6433#6434","text":"Grip the handlebars tightly and fight the wobble#Close the throttle gradually#Accelerate out of the wobble#Apply the brakes quickly"},
{"testId":303,"qid":16926,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#34527","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility"},
{"testId":416,"qid":16926,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#34527","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility"},
{"testId":450,"qid":16926,"description":"Reduce the likelihood of a collision by________.","explanation":"Maintaining an adequate space cushion, increasing your visibility to others, and communicating your intentions to them can all help to reduce your chances of being involved in a collision.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6443#6445#10777#34527","text":"maintaining an adequate space cushion#communicating your intentions#Doing all of the above#increasing your visibility"},
{"testId":416,"qid":16931,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6810#6812#34535#34808","text":"wear a motorcycle lap belt#lean as you lean#lean backward#have a motorcycle license or endorsement"},
{"testId":416,"qid":16933,"description":"What's the best way to detecting bumps in the road at night?","explanation":"Keep an eye out for the taillights of the vehicle ahead of you. The automobile is most likely riding over bumps if they seem to be jumping up and down.","imageName":"","correctAnswer":22794,"isBookmarked":0,"aid":"10581#22794#22795#28204","text":"Wear reflective clothing#Watch the taillights of the car ahead of you#Stay in low gears#Use your low-beam headlight"},
{"testId":416,"qid":16934,"description":"How can you maximize stopping power?","explanation":"Maximum stopping power is obtained by completely using both the front and rear brakes without locking any wheel.","imageName":"","correctAnswer":11176,"isBookmarked":0,"aid":"6304#11174#11176#28897","text":"Use the front brake only#Pull in the clutch while you brake#Use both the front and rear brakes#Alternate between the front brake and the rear brake"},
{"testId":416,"qid":16936,"description":"In which of the following scenarios should you NOT increase your distance from the vehicle ahead?","explanation":"You should not increase your following distance while riding in a group since it may encourage vehicles and trucks to cut in front of you.","imageName":"","correctAnswer":8034,"isBookmarked":0,"aid":"6769#6999#8034#33937","text":"In heavy traffic#On slippery roads#When you are riding in a group#If you are being tailgated"},
{"testId":416,"qid":16938,"description":"Maintain a following distance of at least _____ seconds for an enought space cushion.","explanation":"Maintain a following distance of at least two seconds between you and the car ahead of you for appropriate space cushion. This applies whether the vehicle in front of you is a car or another motorbike. Increase your following distance while biking in difficult circumstances.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":416,"qid":16939,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If this fails, turn off the engine and engage the clutch at the same moment. This will reduce power to the back wheel.","imageName":"","correctAnswer":8342,"isBookmarked":0,"aid":"8342#33937#33938#33939","text":"If your throttle is stuck#If you are being tailgated#If you need to stop quickly#If you need to avoid slipping on slippery surfaces"},
{"testId":416,"qid":16940,"description":"Which wheel should face the curb when parking in a parallel parking space?","explanation":"Park at an angle to the curb, with the back wheel facing the curb, in a parallel parking place.","imageName":"","correctAnswer":29411,"isBookmarked":0,"aid":"7166#11177#29410#29411","text":"Both wheels#Neither wheel#The front wheel#The rear wheel"},
{"testId":85,"qid":16941,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":416,"qid":16941,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":454,"qid":16941,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":416,"qid":16943,"description":"You should _______, when braking on slick conditions.","explanation":"You should utilize both the front and rear brakes while stopping on slippery ground. It is always preferable to utilize both brakes.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"6244#6417#28935#30065","text":"use the rear brake only#Use the front brake only#use both the front and rear brakes#coast in neutral"},
{"testId":416,"qid":16944,"description":"The most effective strategy to swerve is to:","explanation":"The best approach to swerve is to push the handgrip on the side of the desired direction. This causes the motorbike to tilt sharply in that direction. Never swerve and brake at the same time.","imageName":"","correctAnswer":30084,"isBookmarked":0,"aid":"7437#7438#30083#30084","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handgrip on the side opposite to the direction in which you want to swerve#avoid braking and press the handgrip on the side of the direction in which you want to swerve"},
{"testId":416,"qid":16947,"description":"Which of the following claims concerning freight transportation is true?","explanation":"When transporting stuff, strive to uniformly distribute the weight so that the motorbike stays balanced.","imageName":"","correctAnswer":30795,"isBookmarked":0,"aid":"30795#31494#31752#34811","text":"You should distribute the weight evenly#You should not carry cargo while carrying a passenger#You may carry loads on the sissy bar#Ropes are the best way to secure cargo"},
{"testId":416,"qid":16949,"description":"If you notice a motorist inside a parked vehicle while driving by, you should :","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":6278,"isBookmarked":0,"aid":"6278#6286#7650#7651","text":"Beep your horn#Flash your brake light#wave your arms#Come to a complete stop"},
{"testId":416,"qid":16950,"description":"Your feet should be _______ while biking.","explanation":"While cycling, keep your feet firmly planted on the footrests.","imageName":"","correctAnswer":31760,"isBookmarked":0,"aid":"6176#7735#10803#31760","text":"as far forward as possible#hovering above the ground in case you tip#pointing down for easy shifting#positioned firmly on the footrests"},
{"testId":416,"qid":16953,"description":"You may share a lane safely with:","explanation":"You should not be sharing a lane with other cars. To operate properly, each motorbike needs the whole lane width. To navigate, each motorbike may use the whole width of the lane.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30482#34212","text":"no one#other motorcycles only#motorcycles and small cars#any other vehicles"},
{"testId":416,"qid":16954,"description":"Which of these is NOT a result of drinking alcohol?","explanation":"Alcohol may dull judgement, slow response times, diminish awareness, and affect eyesight. Even one drink may affect your balance, coordination, eyesight, and other vital physical and mental abilities required for safe motorcycle operation.","imageName":"","correctAnswer":10040,"isBookmarked":0,"aid":"10040#25449#25846#25848","text":"Increased alertness#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":416,"qid":16956,"description":"How can you maximize straight-line braking?","explanation":"By completely using both brakes without locking each wheel, you may obtain maximum straight-line braking.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"6306#22930#28897#30525","text":"Use the rear brake only#Apply the front brake quickly#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":416,"qid":16959,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could pick up on something.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":291,"qid":16961,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":31129,"isBookmarked":0,"aid":"10333#10334#24600#31129","text":"You are not in a blind spot#Your space cushion is maximized#You are avoiding hazards#You are doing all of the above"},
{"testId":416,"qid":16961,"description":"\"Best lane position\" means what?","explanation":"Choosing the ideal lane position will optimize your space cushion, keep you out of blind spots, and keep you safe.","imageName":"","correctAnswer":31129,"isBookmarked":0,"aid":"10333#10334#24600#31129","text":"You are not in a blind spot#Your space cushion is maximized#You are avoiding hazards#You are doing all of the above"},
{"testId":416,"qid":16962,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"Turning the throttle helps to steady the motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8280#10354","text":"decreasing the throttle#pulling in the clutch#increasing the throttle#leaning forward"},
{"testId":416,"qid":16965,"description":"What should be avoided when attaching cargo to a motorcycle? :","explanation":"Ropes should not be used to secure goods to a motorbike. Ropes may weaken and knots can come undone over time. It is preferable to utilize elastic cords.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":416,"qid":16966,"description":"Can you share a lane with other bikes safely?","explanation":"Motorcycles may be driven two abreast in a single lane in Nebraska. However, you should not do it. To operate properly, each motorbike needs the whole lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":25224,"isBookmarked":0,"aid":"25224#32812#34812#34813","text":"No, never#Yes, but only in rural areas#Yes, but with only one other motorcycle at a time#Yes, anytime"},
{"testId":416,"qid":16967,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before embarking on a trip, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#23024#23025#24776","text":"Being low on oil#The front tire skidding#Braking with only one brake#Not shifting gears properly"},
{"testId":429,"qid":16971,"description":"You should never ride your motorcycle next to a car because:","explanation":"Riding alongside automobiles might be risky since you may be riding in their blind areas.","imageName":"","correctAnswer":31472,"isBookmarked":0,"aid":"31472#31473#31475#34814","text":"you might be in a blind spot#your motorcycle might skid#your visibility might be reduced#a car driver might not be able to turn"},
{"testId":422,"qid":16994,"description":"What should you avoid when turning a motorcycle?","explanation":"Deceleration should be avoided while turning a motorbike. Maintain or increase your speed to keep the motorbike stable.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":454,"qid":17004,"description":"Dirt and gravel are likely to accumulate __________on a paved road.","explanation":"Motorcycles may be dangerous at the road's edge. Dirt and gravel accumulate around the road's edges, particularly on bends and ramps leading to and from roads. And mud and gravel provide little traction.","imageName":"","correctAnswer":10966,"isBookmarked":0,"aid":"10964#10965#10966#10967","text":"in the left wheel track#near the center of the road#along the sides of the road#in all of the above areas"},
{"testId":507,"qid":17004,"description":"Dirt and gravel are likely to accumulate __________on a paved road.","explanation":"Motorcycles may be dangerous at the road's edge. Dirt and gravel accumulate around the road's edges, particularly on bends and ramps leading to and from roads. And mud and gravel provide little traction.","imageName":"","correctAnswer":10966,"isBookmarked":0,"aid":"10964#10965#10966#10967","text":"in the left wheel track#near the center of the road#along the sides of the road#in all of the above areas"},
{"testId":429,"qid":17008,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"Your blood alcohol content is unaffected by the time of day. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":420,"qid":17012,"description":"On a multilane road, before changing lanes, driver should inspect the lane next to you and:","explanation":"Before changing lanes, you should check not only the lane next to you but the far lane as well. Someone from the far lane may be planning to merge into the same lane that you are.","imageName":"","correctAnswer":8326,"isBookmarked":0,"aid":"8326#8451#8452#8453","text":"the far lane#behind you#in front of you#your throttle"},
{"testId":429,"qid":17041,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both the front and rear brakes at all times. This is the most efficient form of braking. (The only exception is if one of the tires is flat.) In such scenario, do not use the flat tire's brake.)","imageName":"","correctAnswer":28397,"isBookmarked":0,"aid":"7522#10591#24769#28397","text":"using the front brake only in emergencies#alternating between the front and rear brakes#using only the front brake in most stopping situations#using both the front and rear brakes every time you stop"},
{"testId":438,"qid":17041,"description":"Using your brakes correctly means:","explanation":"Utilizing your brakes correctly entails using both the front and rear brakes at all times. This is the most efficient form of braking. (The only exception is if one of the tires is flat.) In such scenario, do not use the flat tire's brake.)","imageName":"","correctAnswer":28397,"isBookmarked":0,"aid":"7522#10591#24769#28397","text":"using the front brake only in emergencies#alternating between the front and rear brakes#using only the front brake in most stopping situations#using both the front and rear brakes every time you stop"},
{"testId":83,"qid":17067,"description":"The importance of the rear brake is _____ because _____.","explanation":"The rear brake is less critical since it only provides 25% of the stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":34266,"isBookmarked":0,"aid":"34266#34267#34268#34269","text":"less; it has 25% of the stopping power#less; it has 75% of the stopping power#more; it has 75% of the stopping power#more; it has 25% of the stopping power"},
{"testId":291,"qid":17112,"description":"The vast majority of motorcycle accidents occur.","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":475,"qid":17112,"description":"The vast majority of motorcycle accidents occur.","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":499,"qid":17112,"description":"The vast majority of motorcycle accidents occur.","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. When riding at any pace, use care.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":291,"qid":17114,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"To transport a passenger, your motorbike must have footrests for the individual. The seat must also be large enough to accommodate two persons.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#32369#35097","text":"A windshield#footrests for the passenger#side saddlebags for cargo#specialized shocks"},
{"testId":420,"qid":17121,"description":"To quickly and safely stop your motorcycle, you should apply:","explanation":"To stop your motorbike fast and safely, use both the front and rear brakes simultaneously.","imageName":"","correctAnswer":31116,"isBookmarked":0,"aid":"6357#6358#8626#31116","text":"the rear brake#the front brake#None of the above#both the front and rear brakes at the same time"},
{"testId":420,"qid":17124,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Unless a tire is flat, you should always use both the front and rear brakes while stopping. When one tire goes flat, just use the brake on the other tire.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternate between the front brake and the rear brake"},
{"testId":431,"qid":17124,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Unless a tire is flat, you should always use both the front and rear brakes while stopping. When one tire goes flat, just use the brake on the other tire.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternate between the front brake and the rear brake"},
{"testId":442,"qid":17124,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Unless a tire is flat, you should always use both the front and rear brakes while stopping. When one tire goes flat, just use the brake on the other tire.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternate between the front brake and the rear brake"},
{"testId":454,"qid":17124,"description":"What is the most effective braking strategy on a slick surface?","explanation":"Unless a tire is flat, you should always use both the front and rear brakes while stopping. When one tire goes flat, just use the brake on the other tire.","imageName":"","correctAnswer":6305,"isBookmarked":0,"aid":"6304#6305#6306#28897","text":"Use the front brake only#Use both the front and rear brakes at the same time#Use the rear brake only#Alternate between the front brake and the rear brake"},
{"testId":420,"qid":17128,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"6506#6507#8126#30834","text":"turning too quickly#only using the rear brake#locking the rear wheel#locking the front wheel"},
{"testId":420,"qid":17130,"description":"To aid with shock absorption while biking over significant obstacles, you should:","explanation":"To assist absorb the shock of riding over significant obstacles, you should gently rise from your seat and let your knees and elbows to take the impact.","imageName":"","correctAnswer":10584,"isBookmarked":0,"aid":"6511#6569#6582#10584","text":"Increase the throttle#Apply the front brake#Pull in the clutch#Rise slightly off the seat"},
{"testId":420,"qid":17133,"description":"When riding at night, you can improve your safety by:","explanation":"Wearing luminous clothes, utilizing your high beam, slowing down, and increasing your following distance will help you ride safely at night.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6799#6800#6801#10777","text":"Wearing reflective clothing#using your high beam#reducing your speed#Doing all of the above"},
{"testId":420,"qid":17134,"description":"Your companion should:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":6812,"isBookmarked":0,"aid":"6810#6812#6813#34808","text":"wear a motorcycle lap belt#lean as you lean#lean back to balance the motorcycle#have a motorcycle license or endorsement"},
{"testId":420,"qid":17135,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":442,"qid":17135,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":467,"qid":17135,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":471,"qid":17135,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":511,"qid":17135,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"10601#24660#24661#24872","text":"Pull the engine kill switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":420,"qid":17140,"description":"You should________________ while riding a motorcycle because it is more exhausting than driving a car.","explanation":"Take regular rests since operating a motorbike is more strenuous than operating a vehicle. At least once every two hours, stop the motorbike and get off.","imageName":"","correctAnswer":7216,"isBookmarked":0,"aid":"7216#7217#7218#7219","text":"take frequent breaks#drink caffeinated beverages#avoid drinking water#avoid wearing dark eyewear"},
{"testId":420,"qid":17142,"description":"Which of the following is NOT required to transport a passenger?","explanation":"To transport a passenger, your motorbike must have a set of footrests for the individual. In addition, the seat must be large enough to accommodate two persons. Helmets are required under Nevada law for both motorcyclists and passengers.","imageName":"","correctAnswer":23794,"isBookmarked":0,"aid":"7115#7356#11031#23794","text":"A helmet#Passenger footrests#Designated seat room#A backpack"},
{"testId":420,"qid":17143,"description":"When transporting cargo or passengers,:","explanation":"The increased weight of baggage or a passenger will increase the stopping distance of your motorbike. Adjust your riding appropriately.","imageName":"","correctAnswer":35100,"isBookmarked":0,"aid":"22982#35099#35100#35101","text":"you must ride only during the day#your motorcycle's stopping distance decreases#your motorcycle's stopping distance increases#you must hold a special license"},
{"testId":420,"qid":17144,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____ angle.","explanation":null,"imageName":"","correctAnswer":10674,"isBookmarked":0,"aid":"10674#10677#10799#10800","text":"45-degree#90-degree#30-degree#10-degree"},
{"testId":420,"qid":17146,"description":"When riding through a crossroads, you need to pay extra attention to:","explanation":"While all four options are crucial while cycling through an intersection, be particularly cautious of automobiles turning left into your path. Car drivers often fail to see approaching motorcyclists.","imageName":"","correctAnswer":7668,"isBookmarked":0,"aid":"7666#7667#7668#10700","text":"pedestrians crossing the road#unexpected light changes#vehicles turning left into your path#the position of stop lines"},
{"testId":420,"qid":17147,"description":"Riding __________ lowers your visibility.","explanation":"Travelling alongside other cars reduces your visibility since you may be riding in their blind areas.","imageName":"","correctAnswer":7682,"isBookmarked":0,"aid":"7682#7683#7684#35102","text":"alongside other vehicles#near other motorcycles#in residential areas#with your high beam on"},
{"testId":420,"qid":17150,"description":"You should _____ to use caution when crossing a slick surface.","explanation":"To traverse a highly slippery surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6417#6758#7815#35103","text":"Use the front brake only#maintain or increase your speed#pull in the clutch and coast#stop and walk the motorcycle across"},
{"testId":420,"qid":17157,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#24676#34558","text":"Never#When he or she makes eye contact with you#When you are legally entitled to the right-of-way#When he or she starts to slow down"},
{"testId":420,"qid":17165,"description":"When is it permissible to park a motorcycle in a disability parking space?","explanation":"In Nevada, you may park a motor vehicle in a handicapped parking area only if the individual who received the disability placard or license plate is either driving or transporting the vehicle.","imageName":"","correctAnswer":35107,"isBookmarked":0,"aid":"35104#35105#35106#35107","text":"When it will be parked for no longer than five minutes#When you're unloading cargo#When you're dropping off a passenger#When it is equipped with a disabled placard or disabled license plate"},
{"testId":420,"qid":17169,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and stop while approaching an intersection where a vehicle may cross your path to decrease response time.","imageName":"","correctAnswer":7539,"isBookmarked":0,"aid":"7539#10521#10522#28931","text":"Cover the clutch and the brake#come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#Beep your horn to warn other vehicle operators"},
{"testId":425,"qid":17182,"description":"The most likely cause of a collision with another vehicle is:","explanation":"An junction is the most probable location for a collision since numerous cars are attempting to perform many tasks at once.","imageName":"","correctAnswer":6402,"isBookmarked":0,"aid":"6399#6400#6402#22971","text":"on a curve#on a hill#at an intersection#in a parking lot"},
{"testId":479,"qid":17184,"description":"Position the motorcycle in a parallel parking area near the curb while parking it:","explanation":"When parking in a parallel parking area next to a curb, angle your motorbike such that the rear wheel is parallel to the curb.","imageName":"","correctAnswer":31983,"isBookmarked":0,"aid":"31982#31983#31984#31985","text":"at an angle with the front wheel to the curb#at an angle with the rear wheel to the curb#at an angle with the rear wheel on the curb#at an angle with the front wheel on the curb"},
{"testId":303,"qid":17187,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":429,"qid":17187,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":446,"qid":17187,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":434,"qid":17196,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure your motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":34229,"isBookmarked":0,"aid":"24808#29383#31791#34229","text":"Never downshift below second gear#Downshift only if you are an experienced rider#Brake while downshifting#Make sure your motorcycle is traveling slowly enough for the lower gear"},
{"testId":450,"qid":17196,"description":"What should you do to avoid lurching when downshifting?","explanation":"Make sure your motorbike is moving slowly enough for the gear you're going to change into to prevent lurching when downshifting.","imageName":"","correctAnswer":34229,"isBookmarked":0,"aid":"24808#29383#31791#34229","text":"Never downshift below second gear#Downshift only if you are an experienced rider#Brake while downshifting#Make sure your motorcycle is traveling slowly enough for the lower gear"},
{"testId":82,"qid":17198,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most motorcyclists involved in incidents underbraked the front tire and overbraked the rear, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23079#23080#30008#33206","text":"lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so#underbraked the front tire and overbraked the rear tire"},
{"testId":422,"qid":17227,"description":"Rolling the throttle through a turn will:","explanation":"Through a turn, rolling on the throttle will assist steady the motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#22247","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help stop the motorcycle"},
{"testId":422,"qid":17230,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":7054,"isBookmarked":0,"aid":"7054#8316#9435#24878","text":"Before entering the turn#After entering the turn#At any time#After completing the turn"},
{"testId":450,"qid":17230,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":7054,"isBookmarked":0,"aid":"7054#8316#9435#24878","text":"Before entering the turn#After entering the turn#At any time#After completing the turn"},
{"testId":422,"qid":17231,"description":"The front brake system:","explanation":"The front brake accounts for 70% of your motorcycle's stopping force. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":35503,"isBookmarked":0,"aid":"7461#31445#35502#35503","text":"is controlled by a foot pedal#should only be used in emergencies#provides 30% of the motorcycle's stopping power#provides 70% of the motorcycle's stopping power"},
{"testId":422,"qid":17232,"description":"Maintain a following distance of at least _____ behind the vehicle ahead of you for enough space cushion.","explanation":"Maintain a following distance of at least two seconds behind the car ahead of you for appropriate gap cushion. Increase your following distance while riding in difficult weather or when your motorbike may take longer to stop than normal.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":422,"qid":17233,"description":"For optimal traction while driving in rain, you should drive:","explanation":"When it is raining, you should ride in the tire tracks left by other cars for the most traction. These are less prone to slipping.","imageName":"","correctAnswer":30768,"isBookmarked":0,"aid":"7310#7312#30021#30768","text":"in the center lane position#on the shoulder of the road#in the left lane position#in the tire tracks left by other vehicles"},
{"testId":429,"qid":17233,"description":"For optimal traction while driving in rain, you should drive:","explanation":"When it is raining, you should ride in the tire tracks left by other cars for the most traction. These are less prone to slipping.","imageName":"","correctAnswer":30768,"isBookmarked":0,"aid":"7310#7312#30021#30768","text":"in the center lane position#on the shoulder of the road#in the left lane position#in the tire tracks left by other vehicles"},
{"testId":422,"qid":17235,"description":"Where can I find the engine cut-off switch?","explanation":"The engine cut-off switch is normally positioned near the right handlegrip.","imageName":"","correctAnswer":32712,"isBookmarked":0,"aid":"10664#31772#32711#32712","text":"Near the right foot pedal#Near the left foot lever#Near the left handlegrip#Near the right handlegrip"},
{"testId":422,"qid":17236,"description":"Riding across lanes of traffic is:","explanation":"A motorbike need the whole width of the lane to move safely. Riding between traffic lanes (also known as lane splitting) is now illegal in all states except California.","imageName":"","correctAnswer":7652,"isBookmarked":0,"aid":"7652#7655#22874#30457","text":"prohibited at all times#prohibited at night#allowed in designated areas#allowed only when traffic is at a standstill"},
{"testId":422,"qid":17238,"description":"What is an adequate space cushion between you and the vehicle ahead of you when riding in heavy, congested traffic?","explanation":"According to the State of New Hampshire Motorcycle Operator Manual, a sufficient spacing cushion is at least three seconds while going in heavy, congested traffic.","imageName":"","correctAnswer":10880,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":85,"qid":17241,"description":"Which of the following best describes how to speed up your reflexes?","explanation":"Covering the clutch and brakes shortens your response time, allowing you to respond fast to the unexpected.","imageName":"","correctAnswer":35301,"isBookmarked":0,"aid":"10818#10819#30514#35301","text":"Decrease your following distance#Hover your feet above the ground#Lock your elbows#Cover the clutch and the brakes"},
{"testId":422,"qid":17241,"description":"Which of the following best describes how to speed up your reflexes?","explanation":"Covering the clutch and brakes shortens your response time, allowing you to respond fast to the unexpected.","imageName":"","correctAnswer":35301,"isBookmarked":0,"aid":"10818#10819#30514#35301","text":"Decrease your following distance#Hover your feet above the ground#Lock your elbows#Cover the clutch and the brakes"},
{"testId":422,"qid":17243,"description":"How do you deal with the impact of riding over an obstacle?","explanation":"If you are riding over an obstacle, you may absorb the shock by rising slightly off the seat and allowing your knees and elbows to take the impact.","imageName":"","correctAnswer":35505,"isBookmarked":0,"aid":"24772#30441#35504#35505","text":"Lean back as far as possible#Avoid using the brakes#Increase your speed to minimize bumps#Rise off the seat"},
{"testId":80,"qid":17292,"description":"Riding in the _______ lane position is the best strategy to deter other vehicles from seeking to share your lane.","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":454,"qid":17304,"description":"In areas with oil and grease accumulation, the optimal lane position is:","explanation":"Oil and grease accumulate in the middle of the lane. Ride in the left or right lane in areas where there is oil and grease accumulation. Ride in the tire tracks left by other cars for the most traction.","imageName":"","correctAnswer":30028,"isBookmarked":0,"aid":"6385#6387#7176#30028","text":"In the blind spot#Not important#The center lane position#The left or right lane position"},
{"testId":511,"qid":17315,"description":"Which of the following statement about carrying cargo on your motorcycle is correct?","explanation":"Fill your saddlebags evenly. If one side is heavier than the other, the motorbike may slide.","imageName":"","correctAnswer":10626,"isBookmarked":0,"aid":"10626#10759#10760#10761","text":"Fill saddlebags evenly#Use ropes to secure the load#Attach the cargo to your gas tank#Never carry cargo on a motorcycle"},
{"testId":511,"qid":17336,"description":"One advantage of riding in the center lane is that it:","explanation":"One advantage of riding in the middle lane is that it inhibits other vehicle operators from attempting to share your lane. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":29469,"isBookmarked":0,"aid":"10359#29469#29471#31460","text":"allows you to turn easily#discourages others from sharing your lane#helps you maintain a constant speed#keeps you hidden from other vehicle operators"},
{"testId":293,"qid":17338,"description":"Which of the following impacts your motorcycle riding abilities?","explanation":"Some prescription, over-the-counter, and illicit substances may impair your ability to ride a motorbike. Check the warning label on the medication or ask your pharmacist or doctor if you're unsure of the side effects.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":425,"qid":17346,"description":"You can absorb more shocks when riding on an uneven surface by:","explanation":"You can absorb more stress while riding on an uneven terrain by rising slightly off the seat and allowing your knees and elbows to absorb the impact.","imageName":"","correctAnswer":6309,"isBookmarked":0,"aid":"6236#6309#6310#24831","text":"Swerving from side to side#rising slightly off the seat#taking your feet off the footrests#applying the brakes lightly"},
{"testId":425,"qid":17352,"description":"Which of the following surfaces is the most dangerous for riding a motorcycle?","explanation":"Gravel roads are the most dangerous to travel on a motorbike because they give the least traction.","imageName":"","correctAnswer":6717,"isBookmarked":0,"aid":"6717#6803#6805#24691","text":"Gravel roads#Newly paved roads#Rural roads#Multilane highways"},
{"testId":425,"qid":17354,"description":"Riding beside another vehicle is risky because:","explanation":"Riding next to an automobile might be hazardous. You may be travelling in a blind area, an item could fly out of a window and strike you, or you could crash with an extended side mirror.","imageName":"","correctAnswer":34563,"isBookmarked":0,"aid":"6855#34561#34562#34563","text":"you may be riding in a blind spot#objects may be thrown from a window#you may be hit by an extended side mirror#any of the above may happen"},
{"testId":425,"qid":17355,"description":"In which of the following situations should you roll on the throttle?","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":35525,"isBookmarked":0,"aid":"29495#35524#35525#35526","text":"Passing a line of parked cars#Preparing to stop#In a turn#Descending a hill"},
{"testId":425,"qid":17356,"description":"Because drivers merging onto the highway may not notice you, what should you do?","explanation":"Keep away from this danger by shifting into a further lane if you can, since automobile drivers entering onto the highway could not see you.","imageName":"","correctAnswer":28360,"isBookmarked":0,"aid":"6286#6582#24799#28360","text":"Flash your brake light#Pull in the clutch#Ride in the center lane position#Move into a farther lane if possible"},
{"testId":425,"qid":17358,"description":"To park along the side of the road, you should park:","explanation":"Park on the roadside with your back tire contacting the curb.","imageName":"","correctAnswer":7019,"isBookmarked":0,"aid":"74#7018#7019#7020","text":"parallel to the curb#with your front tire touching the curb#with your rear tire touching the curb#on the curb"},
{"testId":296,"qid":17360,"description":"Which brake needs to be used more?","explanation":"Because it delivers 70% of the stopping force, the front brake is more critical than the rear brake. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":30444,"isBookmarked":0,"aid":"7247#7248#30444#30445","text":"The front, because it prevents skidding#The rear, because it prevents skidding#The front, because it provides 70% of the stopping power#The rear, because it provides 70% of the stopping power"},
{"testId":425,"qid":17360,"description":"Which brake needs to be used more?","explanation":"Because it delivers 70% of the stopping force, the front brake is more critical than the rear brake. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":30444,"isBookmarked":0,"aid":"7247#7248#30444#30445","text":"The front, because it prevents skidding#The rear, because it prevents skidding#The front, because it provides 70% of the stopping power#The rear, because it provides 70% of the stopping power"},
{"testId":507,"qid":17360,"description":"Which brake needs to be used more?","explanation":"Because it delivers 70% of the stopping force, the front brake is more critical than the rear brake. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":30444,"isBookmarked":0,"aid":"7247#7248#30444#30445","text":"The front, because it prevents skidding#The rear, because it prevents skidding#The front, because it provides 70% of the stopping power#The rear, because it provides 70% of the stopping power"},
{"testId":425,"qid":17361,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content may be affected by how much alcohol you consume, how quickly you drink it, and how much you weigh.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#7292#35528","text":"All of the above#How fast you drink#How much you weigh#The amount of alcohol you drink"},
{"testId":425,"qid":17362,"description":"How else can you boost your visibility besides wearing bright clothing?","explanation":"You may boost your visibility by wearing reflective gear in addition to colorful attire. This is particularly useful at night.","imageName":"","correctAnswer":10581,"isBookmarked":0,"aid":"6238#10581#10583#30769","text":"Reduce your speed#Wear reflective clothing#Wear a full-face helmet#Ride only in the left lane position"},
{"testId":425,"qid":17364,"description":"When traveling with others, you should NEVER ride:","explanation":"Even though it is not against the law in New Hampshire, you should never ride next to another motorbike in the same lane. It's possible that you won't be able to move around a danger far enough. For safe operation, each motorbike requires a full lane width.","imageName":"","correctAnswer":7399,"isBookmarked":0,"aid":"6736#7198#7399#31097","text":"single file#in a staggered formation#two abreast#with more than two other motorcyclists"},
{"testId":425,"qid":17365,"description":"Which lane position is optimal for visibility?","explanation":"There is no one lane position that is always the greatest for visibility and keeping a space cushion. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":26505,"isBookmarked":0,"aid":"26505#32381#32382#32383","text":"It depends on the situation#The left lane position is best#The center lane position is best#The right lane position is best"},
{"testId":425,"qid":17366,"description":"How can you keep yourself safe in the event of a collision?","explanation":"Wearing protective motorcycle apparel and gear may help you avoid injuries in the event of an accident.","imageName":"","correctAnswer":24618,"isBookmarked":0,"aid":"22798#22799#24618#26382","text":"Roll away from the motorcycle#Try to land with your arms first#Wear protective motorcycle clothing and gear#Try to jump off the motorcycle before hitting the ground"},
{"testId":425,"qid":17370,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Prescription pharmaceuticals, over-the-counter medications, illicit substances, and, of course, alcohol may all impair your riding skills. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":425,"qid":17371,"description":"A motorcycle cannot be driven on a highway:","explanation":"You cannot ride a motorbike on a highway unless you can drive at the posted speed safely.","imageName":"","correctAnswer":10996,"isBookmarked":0,"aid":"7659#7661#10996#27362","text":"during rush hour#at night, because your lights aren't bright enough#unless you can travel safely at the minimum posted speed#without proper sunglasses"},
{"testId":429,"qid":17371,"description":"A motorcycle cannot be driven on a highway:","explanation":"You cannot ride a motorbike on a highway unless you can drive at the posted speed safely.","imageName":"","correctAnswer":10996,"isBookmarked":0,"aid":"7659#7661#10996#27362","text":"during rush hour#at night, because your lights aren't bright enough#unless you can travel safely at the minimum posted speed#without proper sunglasses"},
{"testId":450,"qid":17371,"description":"A motorcycle cannot be driven on a highway:","explanation":"You cannot ride a motorbike on a highway unless you can drive at the posted speed safely.","imageName":"","correctAnswer":10996,"isBookmarked":0,"aid":"7659#7661#10996#27362","text":"during rush hour#at night, because your lights aren't bright enough#unless you can travel safely at the minimum posted speed#without proper sunglasses"},
{"testId":425,"qid":17372,"description":"Your motorcycle should ______ while you are stopped at a stop sign.","explanation":"While you're stopped at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":6752,"isBookmarked":0,"aid":"6749#6750#6751#6752","text":"remain in neutral#remain in second gear#remain in third gear#remain in first gear"},
{"testId":425,"qid":17374,"description":"You should _______,when traversing a slick surface.","explanation":"To traverse a highly slippery surface, engage the clutch and coast carefully.","imageName":"","correctAnswer":7815,"isBookmarked":0,"aid":"6758#7815#22259#33601","text":"maintain or increase your speed#pull in the clutch and coast#use only the front brake#stop and walk your motorcycle across"},
{"testId":425,"qid":17378,"description":"If you can't keep both hands on the handlebars while carrying a package,:","explanation":"You must not ride a motorbike if you are carrying a package that stops you from steering with both hands. Riding requires the use of both hands. It is unlawful in New Hampshire to ride a motorbike while carrying anything that stops you from maintaining both hands on the handlebars.","imageName":"","correctAnswer":28221,"isBookmarked":0,"aid":"8101#24702#24703#28221","text":"ride under 30 mph#ride only in residential areas#ride only in first gear#you must not ride a motorcycle"},
{"testId":425,"qid":17379,"description":"What must you remember if your motorcycle was constructed before 1978?","explanation":"If your motorbike is older than 1978, you must remember to always ride with the headlight on. Motorcycles made after 1978 automatically activate the low-beam headlamp when the motor is started.","imageName":"","correctAnswer":35067,"isBookmarked":0,"aid":"8193#8194#10572#35067","text":"Get a special license#Avoid carrying passengers#Ride only during the day#Turn the headlight on"},
{"testId":425,"qid":17380,"description":"To park in a parallel parking space, you need to park at an angle:","explanation":"To park in a parallel parking place, do a 90-degree turn with your back wheel hitting the curb.","imageName":"","correctAnswer":29421,"isBookmarked":0,"aid":"6407#7381#27742#29421","text":"with your front wheel on the curb#with your rear wheel on the curb#with your front wheel touching the curb#with your rear wheel touching the curb"},
{"testId":425,"qid":17381,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":425,"qid":17384,"description":"What do you press in the \"press\" step of turning?","explanation":"The \"push\" part of turning involves pressing the handlegrip on the side of the direction you wish to turn. The motorbike will tilt in that way as a result.","imageName":"","correctAnswer":35531,"isBookmarked":0,"aid":"8404#23051#31459#35531","text":"The front or rear brake#The clutch lever#Your feet on the footrests#The handlegrip on the side of the direction in which you want to turn"},
{"testId":425,"qid":17388,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while you're riding, transfer your weight to the back to assist maintain stability.","imageName":"","correctAnswer":35533,"isBookmarked":0,"aid":"7700#7716#35532#35533","text":"avoid using the rear brake#use engine braking to slow down#shift your weight to the front#shift your weight to the rear"},
{"testId":425,"qid":17393,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Passing slower cars does not boost your nighttime safety. It is risky and should be approached with care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6665#6666#10470#10804","text":"Decreasing your speed#Changing your lane position#Passing slower vehicles#Wearing reflective clothing"},
{"testId":425,"qid":17395,"description":"If you have a windscreen on your motorcycle,:","explanation":null,"imageName":"","correctAnswer":34256,"isBookmarked":0,"aid":"34256#34536#35534#35535","text":"you should still wear eye protection#you don't need to wear eye protection#you don't need to wear a helmet#eyeglasses are adequate eye protection"},
{"testId":85,"qid":17398,"description":"When packing saddlebags,you should:","explanation":"When filling saddlebags, load them uniformly and with the same weight to keep your motorbike balanced.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":81,"qid":17411,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the most secure. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#8626","text":"single file#the staggered formation#in pairs#None of the above"},
{"testId":429,"qid":17422,"description":"How far ahead should you look when scanning the road for hazards?","explanation":"When looking for dangers on the road, look 12 seconds ahead on your route of travel. Twelve seconds may not sound like much, but consider that your motorbike will go 1,056 feet in 12 seconds at 60 mph.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"427#986#4582#7370","text":"5 seconds#12 seconds#20 feet#40 feet"},
{"testId":83,"qid":17428,"description":"When should you turn off the engine?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"8340#8341#10595#35119","text":"If your front tire fails#If your rear tire fails#If your motorcycle is wobbling#If your throttle is stuck and you can't free it"},
{"testId":467,"qid":17454,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If the motorbike begins to wobble, carefully close the throttle. Fighting the wobble, speeding, or braking fast might aggravate it.","imageName":"","correctAnswer":6432,"isBookmarked":0,"aid":"6431#6432#6433#6434","text":"Grip the handlebars tightly and fight the wobble#Close the throttle gradually#Accelerate out of the wobble#Apply the brakes quickly"},
{"testId":462,"qid":17456,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#22968","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on a metal grating"},
{"testId":81,"qid":17491,"description":"Where can you find your motorcycle's clutch and brake levers?","explanation":"The clutch lever is on the left, while the front brake lever is on the right.","imageName":"","correctAnswer":31776,"isBookmarked":0,"aid":"31773#31774#31775#31776","text":"The clutch is on the right and the front brake lever is on the left#The clutch and front brake lever are both on the left#The clutch and front brake lever are both on the right#The clutch is on the left and the front brake lever is on the right"},
{"testId":85,"qid":17495,"description":"Distances are more difficult to assess at night, therefore:","explanation":"Distances are more difficult to assess at night, so increase your following distance to give yourself more time to respond.","imageName":"","correctAnswer":6920,"isBookmarked":0,"aid":"6515#6920#10662#22782","text":"Increase your speed#Increase your following distance#ride only on rural roads#avoid riding at night"},
{"testId":431,"qid":17529,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____ angle.","explanation":null,"imageName":"","correctAnswer":35303,"isBookmarked":0,"aid":"35303#35304#35316#35317","text":"45 degrees#90 degrees#30 degrees#10 degrees"},
{"testId":511,"qid":17529,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____ angle.","explanation":null,"imageName":"","correctAnswer":35303,"isBookmarked":0,"aid":"35303#35304#35316#35317","text":"45 degrees#90 degrees#30 degrees#10 degrees"},
{"testId":303,"qid":17545,"description":"When is it permissible to ride a motorcycle between two lanes of traffic?","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes (also known as lane splitting) is currently permitted only in California.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#7722#7723#7724","text":"Never#During rush hour#Whenever it is safe to do so#Only in designated areas"},
{"testId":431,"qid":17545,"description":"When is it permissible to ride a motorcycle between two lanes of traffic?","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes (also known as lane splitting) is currently permitted only in California.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#7722#7723#7724","text":"Never#During rush hour#Whenever it is safe to do so#Only in designated areas"},
{"testId":429,"qid":17551,"description":"You should turn _____ when riding through a turn.","explanation":"Corner just your head while cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":24718,"isBookmarked":0,"aid":"6463#6464#9855#24718","text":"your head and shoulders#your whole upper body#Any of the above#only your head"},
{"testId":429,"qid":17552,"description":"When a vehicle enters your lane from an on-ramp, you should :","explanation":"When a car enters your lane from an on-ramp, change lanes or make way so that the vehicle has enough room to join.","imageName":"","correctAnswer":35357,"isBookmarked":0,"aid":"6278#6475#35357#35358","text":"Beep your horn#Speed up#change lanes or make space#flash your headlight"},
{"testId":429,"qid":17558,"description":"What should you do if your motorcycle begins to wobble?","explanation":"If your motorbike begins to wobble, softly shut the throttle. Don't brake; doing so may exacerbate the wobbling. Attempting to speed out of a wobble may make the motorbike even more unstable.","imageName":"","correctAnswer":6727,"isBookmarked":0,"aid":"6724#6727#23054#36099","text":"Open the throttle slowly#Close the throttle slowly#Apply only the front brake#Apply only the rear brake"},
{"testId":429,"qid":17560,"description":"Which lane position should you ride in to prevent a collision with a hazard in the center of the lane?","explanation":"To avoid a collision with an item in the middle of the lane, ride in the left or right lane to go around the danger.","imageName":"","correctAnswer":6763,"isBookmarked":0,"aid":"6214#6216#6217#6763","text":"Left#Center#Right#Left or right"},
{"testId":429,"qid":17561,"description":"Motorcycle brake lights are not as prominent as automobile brake lights. What can you do?","explanation":"Motorcycle brake lights are less visible than automobile brake lights. To warn the driver behind you, flash your brake light.","imageName":"","correctAnswer":10718,"isBookmarked":0,"aid":"10718#10773#32715#32718","text":"Flash your brake light#Install extra lights#Beep your horn whenever you're about to stop#Brake only when absolutely necessary"},
{"testId":429,"qid":17564,"description":"For the most stable riding, position your knees and feet__________.","explanation":"Your knees should be pressed against the gas tank, and your feet should be firmly planted on the footpegs, for the most stable riding.","imageName":"","correctAnswer":29378,"isBookmarked":0,"aid":"29378#32380#36100#36101","text":"against the gas tank and firmly on the footpegs#away from the gas tank and firmly on the footpegs#against the gas tank and pointing downward#away from the gas tank and pointing downward"},
{"testId":495,"qid":17564,"description":"For the most stable riding, position your knees and feet__________.","explanation":"Your knees should be pressed against the gas tank, and your feet should be firmly planted on the footpegs, for the most stable riding.","imageName":"","correctAnswer":29378,"isBookmarked":0,"aid":"29378#32380#36100#36101","text":"against the gas tank and firmly on the footpegs#away from the gas tank and firmly on the footpegs#against the gas tank and pointing downward#away from the gas tank and pointing downward"},
{"testId":429,"qid":17570,"description":"If your throttle becomes stuck and you are unable to dislodge it, you should:","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If this fails, immediately turn off the engine and engage the clutch. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":36085,"isBookmarked":0,"aid":"6423#6582#8223#36085","text":"Apply the rear brake#Pull in the clutch#Jump off the motorcycle#flip the engine cutoff switch and pull in the clutch"},
{"testId":429,"qid":17572,"description":"Your feet should be _______ while biking.","explanation":"Your feet should be firmly planted on the footpegs when riding.","imageName":"","correctAnswer":29460,"isBookmarked":0,"aid":"6176#7735#29460#36102","text":"as far forward as possible#hovering above the ground in case you tip#positioned firmly on the footpegs#pointing downward for easy shifting"},
{"testId":429,"qid":17575,"description":"When _____ , use your horn.","explanation":"When you believe someone is about to join your lane without seeing you, sound your horn.","imageName":"","correctAnswer":22824,"isBookmarked":0,"aid":"7975#22824#22929#36103","text":"you are making a left turn#you think someone may enter your lane without seeing you#you are crossing railroad tracks#you flip the engine cutoff switch"},
{"testId":429,"qid":17576,"description":"You may share a lane safely with:","explanation":"You should not be sharing a lane with other cars. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":28259,"isBookmarked":0,"aid":"28259#30481#30483#35115","text":"no one#other motorcycles only#all other vehicles#small cars and other motorcycles"},
{"testId":429,"qid":17579,"description":"How might the car ahead of you assist you in detecting obstacles at night?","explanation":"Watch the taillights of the automobile in front of you at night. If they bounce, there are potholes on the road that you need to be careful of.","imageName":"","correctAnswer":34238,"isBookmarked":0,"aid":"10924#24732#24734#34238","text":"Watch the driver's movements#Follow in the path of the car#Follow the car very closely#Watch its taillights to detect bumps in the road"},
{"testId":429,"qid":17580,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6217#7097","text":"Left#Center#Right#Center or right"},
{"testId":429,"qid":17588,"description":"How do you deal with the impact of riding over an obstacle?","explanation":"If you are riding over an obstacle, you may absorb the shock by rising slightly off the seat and allowing your knees and elbows to take the impact.","imageName":"","correctAnswer":36105,"isBookmarked":0,"aid":"24772#30441#35504#36105","text":"Lean back as far as possible#Avoid using the brakes#Increase your speed to minimize bumps#Rise slightly off the seat"},
{"testId":291,"qid":17589,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of your motorcycle's stopping force; the rear brake accounts for the remaining 30%. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#24688","text":"The front brake#The rear brake#The emergency brake#The front and rear brakes equally"},
{"testId":429,"qid":17589,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of your motorcycle's stopping force; the rear brake accounts for the remaining 30%. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#24688","text":"The front brake#The rear brake#The emergency brake#The front and rear brakes equally"},
{"testId":446,"qid":17589,"description":"Which brake is the strongest?","explanation":"The front brake accounts for 70% of your motorcycle's stopping force; the rear brake accounts for the remaining 30%. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":6189,"isBookmarked":0,"aid":"6189#6190#6191#24688","text":"The front brake#The rear brake#The emergency brake#The front and rear brakes equally"},
{"testId":429,"qid":17594,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":438,"qid":17594,"description":"When you're scanning your surroundings, you should be looking for:","explanation":"When monitoring your surroundings, watch for traffic, possibly dangerous road conditions, and viable escape routes.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6289#6290#6291#8615","text":"escape routes#traffic conditions#road conditions#All of the above"},
{"testId":442,"qid":17604,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed to assist keep your motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":438,"qid":17609,"description":"When should a motorcycle group ride in a staggered formation?","explanation":"On lengthy sections of road, the staggered pattern should be employed.","imageName":"","correctAnswer":7293,"isBookmarked":0,"aid":"7157#7158#7293#29409","text":"When passing as a group#Through curves and turns#On long stretches of road#When merging onto the highway"},
{"testId":431,"qid":17637,"description":"Why is it necessary to keep a spacing cushion?","explanation":"Maintaining a space cushion is vital since it allows you to respond, move, and remedy your errors.","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"10749#10750#10751#26434","text":"It gives you time to react#It gives you space to maneuver#It gives you time to correct your mistakes#It does all of the above"},
{"testId":303,"qid":17645,"description":"Which surfaces are less grippy than usual?","explanation":"Riding on wet pavement, metal gratings, or uneven surfaces increases your chances of falling since they give less traction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6718#7032#7033","text":"All of the above#Wet pavement#Metal gratings#Uneven surfaces"},
{"testId":431,"qid":17648,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"For the optimum traction, motorcycles need a hard, solid surface. Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":431,"qid":17650,"description":"How can you maximize stopping power?","explanation":"Applying both the front and back brakes without locking any wheel will provide the most stopping force.","imageName":"","correctAnswer":11176,"isBookmarked":0,"aid":"11174#11176#28897#30030","text":"Pull in the clutch while you brake#Use both the front and rear brakes#Alternate between the front brake and the rear brake#Use only the front brake"},
{"testId":431,"qid":17651,"description":"More than one motorcycle should ride together:","explanation":"Two or more motorcycles travelling in the same lane together should ride in a staggered configuration. This shape enables the group to keep close together while also providing some breathing room. It is dangerous to pair up. To navigate securely, each motorcycle may need the whole width of the lane.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":431,"qid":17654,"description":"The most effective strategy to swerve is to:","explanation":"The best approach to swerve is to squeeze the handlegrip on the side of the desired direction. The motorbike will tilt sharply in that direction as a result. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":37533,"isBookmarked":0,"aid":"7437#7438#37532#37533","text":"brake while you are swerving#pull in the clutch and apply the rear brake#avoid braking and press the handlegrip on the side opposite to the direction in which you want to swerve#avoid braking and press the handlegrip on the side of the direction in which you want to swerve"},
{"testId":431,"qid":17655,"description":"When should you increase your following distance?","explanation":"Increase your following distance in circumstances where you may have to come to an abrupt halt. In heavy traffic, for example, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt. In addition, another car may abruptly cut in front of you.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":431,"qid":17659,"description":"What should you avoid when turning a motorcycle?","explanation":"Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":431,"qid":17663,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol concentration may be affected by how much alcohol you consume, how quickly you drink it, and how much you weigh.","imageName":"","correctAnswer":7290,"isBookmarked":0,"aid":"7290#7826#29011#36098","text":"How fast you drink#The time of day#The location at which you are drinking#The type of alcoholic drink"},
{"testId":431,"qid":17664,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":28223,"isBookmarked":0,"aid":"28222#28223#28224#34797","text":"is close to the centerline#is different for each curve#is close to the edge of the road#is the center of the road"},
{"testId":81,"qid":17665,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To dissuade cars from sharing your lane, ride in the middle of the lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":431,"qid":17665,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"When there is heavy traffic, a vehicle may attempt to share your lane, while the driver want to pass you, or when you are ready to turn at a junction. To dissuade cars from sharing your lane, ride in the middle of the lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#24801","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection"},
{"testId":431,"qid":17667,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is located on the left hand lever. The right foot pedal operates the rear brake, while the left foot lever operates the gear changer.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":82,"qid":17703,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":37535,"isBookmarked":0,"aid":"10906#10909#32687#37535","text":"It gives you the best view of oncoming trains#A larger angle would be unsafe#It lets you cross the tracks quickly#At a lesser angle, you might catch your tires"},
{"testId":475,"qid":17703,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":37535,"isBookmarked":0,"aid":"10906#10909#32687#37535","text":"It gives you the best view of oncoming trains#A larger angle would be unsafe#It lets you cross the tracks quickly#At a lesser angle, you might catch your tires"},
{"testId":458,"qid":17706,"description":"What part controls the front brake?","explanation":"The front brake is controlled by the right hand lever.","imageName":"","correctAnswer":8080,"isBookmarked":0,"aid":"8080#8081#8082#31784","text":"The right hand lever#The left hand lever#The right foot pedal#The left foot lever"},
{"testId":438,"qid":17708,"description":"When should your mirrors be adjusted?","explanation":"After mounting your motorbike but before starting it, you should adjust your mirrors.","imageName":"","correctAnswer":7516,"isBookmarked":0,"aid":"7516#8161#10851#34807","text":"After mounting the motorcycle#Before mounting the motorcycle#After you have started riding#After starting the motorcycle's engine"},
{"testId":458,"qid":17709,"description":"Weaving while traversing a metal grate:","explanation":"If you find yourself swaying when riding over a metal grating, relax, maintain your pace, and go straight over. Attempting to adjust for the weaving sensation is dangerous.","imageName":"","correctAnswer":8219,"isBookmarked":0,"aid":"8219#8220#24711#37536","text":"is not hazardous#is dangerous to motorcycle riders#is caused by poor motorcycle skills#is harmful to motorcycles"},
{"testId":293,"qid":17714,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":479,"qid":17714,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by:","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6552,"isBookmarked":0,"aid":"6236#6552#7857#7858","text":"Swerving from side to side#riding in the left lane position#increasing your speed#lifting your face shield"},
{"testId":83,"qid":17721,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by riding in ________ of your lane.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6211,"isBookmarked":0,"aid":"6210#6211#6212#6213","text":"the center portion#the left portion#the right portion#any portion"},
{"testId":458,"qid":17726,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":471,"qid":17726,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":475,"qid":17726,"description":"To assist with motorcycle stability during a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":6511,"isBookmarked":0,"aid":"6423#6424#6511#6582","text":"Apply the rear brake#decrease the throttle#Increase the throttle#Pull in the clutch"},
{"testId":438,"qid":17729,"description":"Which of the following is the most dangerous place to ride a motorcycle?","explanation":"Gravel roads are the most dangerous to travel on a motorbike because they give the least traction.","imageName":"","correctAnswer":6717,"isBookmarked":0,"aid":"6717#6803#6805#24691","text":"Gravel roads#Newly paved roads#Rural roads#Multilane highways"},
{"testId":83,"qid":17736,"description":"Who determines the group's pace when riding in a group?","explanation":"When biking in a group, the tail rider dictates the pace. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":85,"qid":17736,"description":"Who determines the group's pace when riding in a group?","explanation":"When biking in a group, the tail rider dictates the pace. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":446,"qid":17736,"description":"Who determines the group's pace when riding in a group?","explanation":"When biking in a group, the tail rider dictates the pace. If the cyclist in front of you slows down, you should likewise slow down to keep the group together.","imageName":"","correctAnswer":6579,"isBookmarked":0,"aid":"6578#6579#6580#6581","text":"The group leader#The tail rider#The traffic in front of the leader#The middle group rider"},
{"testId":454,"qid":17739,"description":"The road's edge can be extremely dangerous for motorcycles because:","explanation":"The road's edge is problematic for motorcyclists because the mud and gravel that accumulates there provides minimal traction.","imageName":"","correctAnswer":7845,"isBookmarked":0,"aid":"7423#7845#24857#28376","text":"you may hit pedestrians#dirt and gravel provide little traction#the pavement is of poor quality#riding there is prohibited"},
{"testId":301,"qid":17743,"description":"The optimal lane location for visibility:","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":442,"qid":17743,"description":"The optimal lane location for visibility:","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":29417,"isBookmarked":0,"aid":"7093#7567#7568#29417","text":"is the right lane position#is the left lane position#is the center lane position#depends on the situation"},
{"testId":85,"qid":17758,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before embarking on a trip, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7950#7952#22933#23024","text":"Braking using only one brake#Being low on oil#Changing gears improperly#The front tire skidding"},
{"testId":84,"qid":17760,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8380#10355#10356","text":"A windshield#specialized goggles#A face shield#approved sunglasses"},
{"testId":292,"qid":17760,"description":"The most effective eye and facial protection is offered by:","explanation":"A shatter-resistant face shield provides the finest eye and facial protection. It has the most surface area and is unlikely to fly away in the wind.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8380#10355#10356","text":"A windshield#specialized goggles#A face shield#approved sunglasses"},
{"testId":458,"qid":17769,"description":"To aid in balance, your feet should:","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":26571,"isBookmarked":0,"aid":"7123#7124#26571#35323","text":"be against the gas tank#skim the ground during turns#be placed firmly on the footrests#point downward toward the road"},
{"testId":475,"qid":17769,"description":"To aid in balance, your feet should:","explanation":"When riding a motorbike, keep your feet firmly planted on the footrests. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":26571,"isBookmarked":0,"aid":"7123#7124#26571#35323","text":"be against the gas tank#skim the ground during turns#be placed firmly on the footrests#point downward toward the road"},
{"testId":434,"qid":17771,"description":"This symbol alerts drivers that:","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. Never attempt to cross while a train is approaching.","imageName":"202001282004545744.jpg","correctAnswer":9046,"isBookmarked":0,"aid":"9046#9047#10678#26376","text":"you are approaching a railroad crossing#you are approaching an intersection#you are approaching a construction zone#you are approaching a dead end"},
{"testId":434,"qid":17773,"description":"When is it safe to share a lane with a car?","explanation":"Never share a lane with another vehicle. A motorbike needs the whole width of the lane to move safely.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#8087#35117","text":"Never#In heavy traffic#In designated areas#When you're riding in a group"},
{"testId":434,"qid":17777,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other vehicles from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":511,"qid":17777,"description":"The most effective approach to deter another vehicle from seeking to share your lane is to:","explanation":"Riding in the middle lane is the greatest strategy to deter other vehicles from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":24799,"isBookmarked":0,"aid":"6278#6286#10407#24799","text":"Beep your horn#Flash your brake light#Ride in the left lane position#Ride in the center lane position"},
{"testId":434,"qid":17778,"description":"You should turn _____ when riding through a turn.","explanation":"Corner your head only while cycling through a turn. Moving your shoulders or upper torso may cause the motorbike to turn more than you intended.","imageName":"","correctAnswer":6465,"isBookmarked":0,"aid":"6463#6464#6465#9855","text":"your head and shoulders#your whole upper body#your head only#Any of the above"},
{"testId":434,"qid":17783,"description":"Reduce the likelihood of a collision by:","explanation":"You may lower your risks of an accident by selecting an appropriate lane position, detecting several dangers, and wearing bright clothes that allows you to be seen more quickly.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6683#6684#10777#29434","text":"identifying multiple hazards#wearing bright clothing#Doing all of the above#choosing a suitable lane position"},
{"testId":81,"qid":17786,"description":"What is the minimum following distance you should maintain for safe riding?","explanation":"Maintain a following distance of at least two seconds for safe riding. If your motorbike takes longer to stop than normal, increase your following distance to three or more seconds.","imageName":"","correctAnswer":31500,"isBookmarked":0,"aid":"31500#31502#32925#34530","text":"Two seconds#Four seconds#One second#Five seconds"},
{"testId":434,"qid":17786,"description":"What is the minimum following distance you should maintain for safe riding?","explanation":"Maintain a following distance of at least two seconds for safe riding. If your motorbike takes longer to stop than normal, increase your following distance to three or more seconds.","imageName":"","correctAnswer":31500,"isBookmarked":0,"aid":"31500#31502#32925#34530","text":"Two seconds#Four seconds#One second#Five seconds"},
{"testId":434,"qid":17787,"description":"Riding beside another vehicle is risky because:","explanation":"Riding alongside another car may be risky since you might be riding in a blind zone, things could be flung out of a window and strike you, or you could crash with an extended side mirror.","imageName":"","correctAnswer":37540,"isBookmarked":0,"aid":"6855#34561#34562#37540","text":"you may be riding in a blind spot#objects may be thrown from a window#you may be hit by an extended side mirror#any of the above can happen"},
{"testId":434,"qid":17793,"description":"When should you bike in a single file when riding in a group?","explanation":"Either riding in a group, when entering or leaving a highway, as well as turning or going around bends, you should ride single file.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":434,"qid":17796,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Frequently, a vehicle driver fails to see an incoming motorbike and turns left into its path.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#6805#26414#26415","text":"Intersections#Rural roads#Freeways#School zones"},
{"testId":434,"qid":17800,"description":"Before changing lanes, watch for:","explanation":"Before changing lanes, you should do a head check of the lane beside you and the far lane to see if other vehicles are planning to move into the lane beside you.","imageName":"","correctAnswer":7920,"isBookmarked":0,"aid":"7917#7920#31154#33580","text":"the traction of the road surface#the lane beside you and the far lane#areas of vision that are blocked by your helmet#the sizes of the vehicles that are close to you"},
{"testId":434,"qid":17803,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right to prevent complications caused by doors opening, drivers getting out of cars, or pedestrians stepping from between cars.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":434,"qid":17804,"description":"When going by another vehicle,:","explanation":"When passing another vehicle, you must not exceed the speed limit (or at any other time).","imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":81,"qid":17807,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":434,"qid":17807,"description":"You can stabilize your motorcycle while making a turn by:","explanation":"Roll on the throttle to maintain or slightly increase your speed in a turn to assist stable your motorbike.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":434,"qid":17809,"description":"Which of the following can not affect your blood alcohol concentration(BAC)?","explanation":"The time of day has no effect on your blood alcohol level. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":22880,"isBookmarked":0,"aid":"22832#22833#22834#22880","text":"The amount of alcohol#Your weight#How fast you drink#The time of day"},
{"testId":434,"qid":17811,"description":"Other drivers pose a hazard when ______.","explanation":"A motorist inside a vehicle you're passing could not see you and open a door into your path.","imageName":"","correctAnswer":24735,"isBookmarked":0,"aid":"8369#23070#23071#24735","text":"you are making a right turn#you are parking your motorcycle#you are riding through a school zone#You are passing a line of parked cars"},
{"testId":434,"qid":17812,"description":"When packing your motorcycle, you should pack heavier items:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7944#11156#11157","text":"on the sissy bar#in a backpack#in saddlebags#in the front of the tank bag"},
{"testId":434,"qid":17813,"description":"A passenger under the age of 18 who rides on your motorcycle must have his or her own:","explanation":"A passenger on your motorbike in New Mexico must have his or her own footrests and seat. If the passenger is under the age of 18, he or she must also wear a helmet.","imageName":"","correctAnswer":10854,"isBookmarked":0,"aid":"8333#8435#8436#10854","text":"helmet#footrests#seat#footrests, seat, and helmet"},
{"testId":434,"qid":17818,"description":"When may you park your motorcycle in a parking space for persons with disabilities in New Mexico?","explanation":"In New Mexico, you may park a motor vehicle in a handicapped parking area only if you have a disability license plate or placard. The MVD's \"File, Print & Go Forms\" website (http://www.mvd.newmexico.gov/fill-print-go-forms.aspx) is where you may apply for a handicap placard.","imageName":"","correctAnswer":37541,"isBookmarked":0,"aid":"10481#10482#10781#37541","text":"When unloading only#When dropping off a passenger#When parking for five minutes or less#Only if you have a disability license plate or disability placard"},
{"testId":434,"qid":17819,"description":"If you are riding at night, you should:","explanation":"You should ride slower at night than you would during the day to give yourself more time to respond to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"10559#10560#24714#32738","text":"not use your headlight#wear a tinted face shield#ride slower than you do during the day#stay close behind the vehicle in front of you"},
{"testId":85,"qid":17860,"description":"If a dog begins to chase you while driving, you should:","explanation":"If a dog starts after you, you should approach the beast and then flee. This should prevent the dog from pursuing you.","imageName":"","correctAnswer":28227,"isBookmarked":0,"aid":"6570#6572#28227#28228","text":"kick the dog#stop until the owner is found#approach the animal and then speed away#call animal control services"},
{"testId":442,"qid":17862,"description":"For riding curves, the optimal group formation is:","explanation":"When biking through bends, travel in single file. Throughout the bend, you may need to adjust your lane position.","imageName":"","correctAnswer":6736,"isBookmarked":0,"aid":"6736#6737#7111#10821","text":"single file#the staggered formation#in pairs#a V-shaped formation"},
{"testId":298,"qid":17869,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6827#6829#7671#22886","text":"positioned with your hands above your elbows#bent slightly#locked at the elbows#bent at a 90-degree angle"},
{"testId":454,"qid":17884,"description":"Your companion should:","explanation":"To assist balance the motorbike, your passenger should sit as far front as feasible.","imageName":"","correctAnswer":6729,"isBookmarked":0,"aid":"6729#10686#10687#24755","text":"sit as far forward as possible#lean in the opposite direction of the motorcycle#get on before you start the engine#put his or her feet down when the motorcycle is stopped"},
{"testId":303,"qid":17920,"description":"Slippery surfaces can be considerably more risky if you are:","explanation":"USE BOTH BRAKES TO RIDE SAFELY ON SLIPPERY SURFACE. Even on a slick surface, the front brake is more effective than the rear brake. To prevent locking the front wheel, apply it gradually, and apply the rear brake lightly.","imageName":"","correctAnswer":6553,"isBookmarked":0,"aid":"6552#6553#6555#34225","text":"riding in the left lane position#only using one brake#riding in the right lane position#using both the front and rear brakes"},
{"testId":293,"qid":17955,"description":"Eye contact with other drivers:","explanation":"Drivers will not yield to you if you make eye contact. Even when cars are gazing straight at motorcyclists, they often fail to spot them.","imageName":"","correctAnswer":23007,"isBookmarked":0,"aid":"7987#23005#23007#30826","text":"should be avoided to discourage aggression#ensures that they will stop and let you through#is not a guarantee that they will yield to you#should be made before you cross any intersection"},
{"testId":464,"qid":17955,"description":"Eye contact with other drivers:","explanation":"Drivers will not yield to you if you make eye contact. Even when cars are gazing straight at motorcyclists, they often fail to spot them.","imageName":"","correctAnswer":23007,"isBookmarked":0,"aid":"7987#23005#23007#30826","text":"should be avoided to discourage aggression#ensures that they will stop and let you through#is not a guarantee that they will yield to you#should be made before you cross any intersection"},
{"testId":491,"qid":17955,"description":"Eye contact with other drivers:","explanation":"Drivers will not yield to you if you make eye contact. Even when cars are gazing straight at motorcyclists, they often fail to spot them.","imageName":"","correctAnswer":23007,"isBookmarked":0,"aid":"7987#23005#23007#30826","text":"should be avoided to discourage aggression#ensures that they will stop and let you through#is not a guarantee that they will yield to you#should be made before you cross any intersection"},
{"testId":446,"qid":17957,"description":"When you get close to a blind intersection with a stop line, you should stop :","explanation":"When approaching a blind junction with a stop line, stop behind the stop line first. You may proceed after pausing until you can see more clearly.","imageName":"","correctAnswer":8117,"isBookmarked":0,"aid":"8117#8118#8119#8120","text":"behind the stop line#where you can see both ways#with your rear tire on the stop line#straddling the stop line"},
{"testId":438,"qid":17972,"description":"To quickly and safely stop your motorcycle, you should apply:","explanation":"When you come to a complete stop, use both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":31116,"isBookmarked":0,"aid":"6504#7749#8626#31116","text":"the front brake only#the rear brake only#None of the above#both the front and rear brakes at the same time"},
{"testId":438,"qid":17973,"description":"Motorcycles are less visible than automobiles. How can you make yourself more visible to others?","explanation":"To improve your visibility to other road users, ride in the appropriate lane, wear brightly colored clothes, use your headlight and turn signals, and flash your brake light if you're going to stop or slow down in an area where others may not anticipate it.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#23000#31451","text":"Use your headlight#Wear bright clothing#Do all of the above#Ride in a suitable lane position"},
{"testId":438,"qid":17974,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, gently remove and reapply the front brake to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#28929","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#continue applying the front brake"},
{"testId":438,"qid":17976,"description":"When another vehicle enters your lane from an entrance ramp, you should :","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":38472,"isBookmarked":0,"aid":"6278#6475#38472#38473","text":"Beep your horn#Speed up#move into another lane if one is open#ignore it"},
{"testId":438,"qid":17978,"description":"When is it lawful to share your lane with another motor vehicle?","explanation":"In New York State, you have the legal right to the whole lane width. You and another biker may lawfully ride two abreast in the same lane, but no other sort of motor vehicle may share your lane. You are not permitted to ride between lanes, between traffic and parked autos, or abreast of another vehicle in the same lane.","imageName":"","correctAnswer":35515,"isBookmarked":0,"aid":"30476#35515#38474#38475","text":"Only in the right lane position#If the other vehicle is a motorcycle#If you're riding between lanes#If you're riding between traffic and parked cars"},
{"testId":438,"qid":17984,"description":"Which lane should you ride in if there are hazards on your left?","explanation":"If there are dangers on your left, you should bike in lane two (middle) or three (right) to avoid them.","imageName":"","correctAnswer":29452,"isBookmarked":0,"aid":"28388#28390#28391#29452","text":"One (left) or two (center)#Two (center)#Three (right)#Two (center) or three (right)"},
{"testId":438,"qid":17985,"description":"You should maintain a _____ second following distance behind the vehicle in front of you.","explanation":"Keep a trailing distance of at least two seconds behind the car in front of you. Increase your following distance while riding in bad or dangerous circumstances, or when your motorbike takes longer to stop than normal.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":438,"qid":17986,"description":"To park on the side of the road, you should park at a _____ angle to the curb.","explanation":"To park along the road, park at a 90-degree angle to the curb, with your back wheel contacting the curb.","imageName":"","correctAnswer":10677,"isBookmarked":0,"aid":"10674#10677#10684#22892","text":"45-degree#90-degree#25-degree#130-degree"},
{"testId":438,"qid":17992,"description":"When should you increase your following distance?","explanation":"In heavy traffic, you should increase your following distance. Heavy traffic might cause abrupt and unexpected halt.","imageName":"","correctAnswer":7448,"isBookmarked":0,"aid":"7446#7448#7449#24696","text":"During the day#In heavy traffic#When it is windy#On single-lane roads"},
{"testId":438,"qid":17995,"description":"Which of the statements below is false?","explanation":"Motorcycles, like vehicles, have blind areas. Before changing lanes, do a mental check.","imageName":"","correctAnswer":10707,"isBookmarked":0,"aid":"10704#10706#10707#38476","text":"You must be in good physical condition to ride a motorcycle#Saddlebags should be filled with evenly distributed weight#Motorcycles do not have blind spots#Riding a motorcycle can be more tiring than driving a car"},
{"testId":438,"qid":17997,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"Pedestrians in designated or unmarked crosswalks have the right-of-way, whether you're driving a vehicle or riding a motorbike. If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":438,"qid":17998,"description":"The front brake is more crucial because:","explanation":"The front brake is more crucial since it provides the majority of the stopping force (70 percent). To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7898,"isBookmarked":0,"aid":"7897#7898#7899#7900","text":"it provides all of the stopping power#it provides most of the stopping power#it is used most often#it provides less of the stopping power"},
{"testId":438,"qid":18005,"description":"Is sharing a lane with another motorbike safe?","explanation":"Motorcycles may lawfully go two abreast (pair up) in a single lane in New York State. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":438,"qid":18010,"description":"Other drivers pose a hazard when ______.","explanation":"A motorist in a vehicle you are passing may fail to see you and open a door directly into your path.","imageName":"","correctAnswer":24735,"isBookmarked":0,"aid":"8369#23070#23071#24735","text":"you are making a right turn#you are parking your motorcycle#you are riding through a school zone#You are passing a line of parked cars"},
{"testId":438,"qid":18013,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Pairing up is not unlawful in New York State. However, it is still a horrible idea. Motorcycles should never be ridden in groups. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#17347#24812#32724","text":"Never#When approaching intersections#When riding at night#When riding through sharp curves"},
{"testId":438,"qid":18016,"description":"Which colors of clothing are most visible to other road users?","explanation":"Bright hues like orange, red, yellow, and green are ideal for improving your visibility to others.","imageName":"","correctAnswer":28414,"isBookmarked":0,"aid":"28411#28413#28414#35087","text":"Orange, yellow, and white#White, red, and orange#Orange, red, yellow, and green#Red, green, and brown"},
{"testId":438,"qid":18020,"description":"Your passenger's feet should remain on the foot pegs:","explanation":"Passengers should always keep their feet on the foot pegs. They do not need to put their feet down at junctions, unlike motorcyclists.","imageName":"","correctAnswer":7343,"isBookmarked":0,"aid":"7343#34544#34545#36096","text":"At all times#unless you're riding on a slippery surface#unless you're stopped at an intersection#while your motorcycle is moving"},
{"testId":446,"qid":18047,"description":"Which of the following hazards should you be aware of when being passed by another vehicle?","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8046#8047#8048","text":"All of the above#Extended side mirrors#Objects being thrown from windows#Wind blasts from larger vehicles"},
{"testId":450,"qid":18051,"description":"You can take someone with you if:","explanation":"If your motorbike is capable of carrying another person, you may transport a passenger. This implies it must feature a passenger seat as well as dedicated footrests.","imageName":"","correctAnswer":29493,"isBookmarked":0,"aid":"8096#8099#23047#29493","text":"the passenger has special shoes#all of the above conditions are met#the passenger is wearing protective clothing#your motorcycle is equipped to carry another person"},
{"testId":83,"qid":18107,"description":"If you're riding in a group and need to pass the vehicle ahead of you,:","explanation":"If you're riding as a group and need to pass the vehicle in front of you, pass one rider at a time.","imageName":"","correctAnswer":23011,"isBookmarked":0,"aid":"23010#23011#23012#23013","text":"pass in pairs#pass one rider at a time#pass as a whole group#never pass while riding in a group"},
{"testId":446,"qid":18118,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly sitting on a motorbike. This gives you the greatest power.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6826#6827#6829#22958","text":"positioned with your elbows locked#positioned with your hands above your elbows#bent slightly#At a 90-degree angle"},
{"testId":450,"qid":18118,"description":"When you're correctly sitting on your motorcycle, your arms should be :","explanation":"Your arms should be slightly bent while correctly sitting on a motorbike. This gives you the greatest power.","imageName":"","correctAnswer":6829,"isBookmarked":0,"aid":"6826#6827#6829#22958","text":"positioned with your elbows locked#positioned with your hands above your elbows#bent slightly#At a 90-degree angle"},
{"testId":83,"qid":18125,"description":"If your front wheel becomes locked, you should:","explanation":"If your front wheel locks, gently remove and reapply the front brake to recover control.","imageName":"","correctAnswer":24700,"isBookmarked":0,"aid":"6244#6245#24700#37539","text":"use the rear brake only#release the rear brake only#release the front brake and reapply it smoothly#keep applying the front brake"},
{"testId":462,"qid":18144,"description":"Which of the following assertions about the transport of a person is correct?","explanation":"Even while the motorbike is stopped at a traffic light, the passenger should have his or her feet on the footpegs at all times.","imageName":"","correctAnswer":38712,"isBookmarked":0,"aid":"30052#30053#38456#38712","text":"The passenger should sit as far back as possible#The passenger should put his or her feet down at stops#The passenger should avoid holding on to the rider#The passenger should keep his or her feet on the footpegs at all times"},
{"testId":442,"qid":18172,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by riding in ________ portion of your lane.","explanation":"Ride on the left side of your lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats.","imageName":"","correctAnswer":6545,"isBookmarked":0,"aid":"4318#6545#6546#6547","text":"any#The left#The right#The center"},
{"testId":442,"qid":18179,"description":"The best way around a curve:","explanation":"Every curve is unique. The optimum way across a curve may not match the road's curvature. To assist you, adjust your lane position as required.","imageName":"","correctAnswer":28223,"isBookmarked":0,"aid":"7568#28222#28223#28224","text":"is the center lane position#is close to the centerline#is different for each curve#is close to the edge of the road"},
{"testId":442,"qid":18181,"description":"You should _____ when transporting a burden.","explanation":"When carrying a load, distribute the weight equally to keep the motorbike balanced.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":442,"qid":18183,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated and the tailgater is unable to pass you, increase your following distance so that both of you have ample area to stop if a danger exists.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6286#6392#6920","text":"pull over and stop#Flash your brake light#Call the police#Increase your following distance"},
{"testId":442,"qid":18184,"description":"You should have at least _____ seconds between you and the car in front of you.","explanation":"By leaving at least two seconds between yourself and the car in front of you, you allow yourself enough time to stop or swerve if the vehicle in front of you abruptly stops.","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":442,"qid":18187,"description":"If _____, you can be charged with driving while impaired (DWI).","explanation":"If you are detected operating a motor vehicle in North Carolina with a blood alcohol content (BAC) of 0.08 percent or above, you may be charged with driving while intoxicated (DWI).","imageName":"","correctAnswer":30438,"isBookmarked":0,"aid":"7208#7209#10636#30438","text":"You have consumed at least two beers#You have consumed any amount of alcohol#You are seen exiting a drinking establishment#your blood alcohol concentration (BAC) is 0.08% or above"},
{"testId":442,"qid":18190,"description":"A motorcycle passenger is not required to have ______ in North Carolina.","explanation":"To transport a passenger on your motorbike, it must have footpegs for the passenger and a seat large enough to accommodate both of you. The passenger must also wear a helmet.","imageName":"","correctAnswer":38716,"isBookmarked":0,"aid":"32713#38715#38716#38717","text":"footpegs#a helmet#a backpack#designated seat room"},
{"testId":442,"qid":18197,"description":"When is it permissible to ride a motorcycle between two lanes of traffic?","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes (also known as lane splitting) is currently only permitted in California.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#8087#29348#38718","text":"Never#In designated areas#Only in the daytime#Only when traffic is at a standstill"},
{"testId":442,"qid":18199,"description":"The motorcycle and rider should both lean in the same direction in _________.聽:","explanation":"The rider should lean at the same angle as the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":31483,"isBookmarked":0,"aid":"31481#31482#31483#31484","text":"slow, tight turns#quick, sharp turns#normal turns#all turns"},
{"testId":450,"qid":18199,"description":"The motorcycle and rider should both lean in the same direction in _________.聽:","explanation":"The rider should lean at the same angle as the motorbike in regular turns. The rider should stay upright when the motorbike leans in gradual, tight corners.","imageName":"","correctAnswer":31483,"isBookmarked":0,"aid":"31481#31482#31483#31484","text":"slow, tight turns#quick, sharp turns#normal turns#all turns"},
{"testId":442,"qid":18200,"description":"If you detect a car on an entry ramp about to merge into your lane on the highway, you should:","explanation":"Drivers on an exit ramp may not see you on the highway. Allow them lots of space. If another lane is available, take it. If you are unable to change lanes, reduce your speed to allow for merging traffic.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6286#7651","text":"Change lanes#Beep your horn#Flash your brake light#Come to a complete stop"},
{"testId":442,"qid":18204,"description":"Your motorcycle is suitable for you if:","explanation":"If both of your feet can reach the ground when sitting and you can reach the handlegrips and footpegs, your motorbike suits you.","imageName":"","correctAnswer":22996,"isBookmarked":0,"aid":"7894#22993#22994#22996","text":"you can reach the footpegs#both of your feet can reach the ground while you are seated#you can reach the handlegrips#you can do all of the above"},
{"testId":442,"qid":18207,"description":"You can take someone with you if:","explanation":"If your motorbike is capable of carrying another person, you may transport a passenger. This implies it must feature a passenger seat and certain footpegs.","imageName":"","correctAnswer":29493,"isBookmarked":0,"aid":"8096#8099#23047#29493","text":"the passenger has special shoes#all of the above conditions are met#the passenger is wearing protective clothing#your motorcycle is equipped to carry another person"},
{"testId":442,"qid":18212,"description":"How many motorcycle lane slots are there in each lane of traffic?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":442,"qid":18213,"description":"You can start a swerve by:","explanation":"Swerves may be initiated by pushing the handlegrip on the side of the desired direction. As a result, your motorbike will tilt sharply in that direction.","imageName":"","correctAnswer":23015,"isBookmarked":0,"aid":"8279#23014#23015#30461","text":"applying the rear brake#pressing the handlegrip on the side opposite to the direction in which you want to swerve#pressing the handlegrip on the side of the direction in which you want to swerve#downshifting"},
{"testId":442,"qid":18214,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"To transport a passenger, your motorbike must have footpegs for the individual. The seat must also be large enough to accommodate two persons.","imageName":"","correctAnswer":32368,"isBookmarked":0,"aid":"7204#32368#32369#35097","text":"A windshield#footpegs for the passenger#side saddlebags for cargo#specialized shocks"},
{"testId":442,"qid":18215,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"Pairing up (riding two abreast in the same lane) is permitted in North Carolina. However, it is still a horrible idea. Motorcycles should never be driven in groups. To navigate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#17347#24812#32724","text":"Never#When approaching intersections#When riding at night#When riding through sharp curves"},
{"testId":442,"qid":18216,"description":"When is it OK to utilize both the front and rear brakes?","explanation":"When you come to a complete stop, use both the front and back brakes. This is the most efficient form of braking.","imageName":"","correctAnswer":30126,"isBookmarked":0,"aid":"30126#35318#38719#38720","text":"Every time you stop#Only in normal stops#Only in emergency situations#Whenever you need to swerve"},
{"testId":446,"qid":18239,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":454,"qid":18239,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":487,"qid":18239,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":491,"qid":18239,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"Your motorcycle's fluid levels should be checked on a weekly basis.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":458,"qid":18241,"description":"Beginner riders should be positioned _____ when riding in a group:","explanation":"An novice cyclist should be positioned directly behind the leader while riding in a group. The beginner rider will no longer have to pursue the group, and more experienced riders will be able to keep an eye on the rookie rider from behind.","imageName":"","correctAnswer":6328,"isBookmarked":0,"aid":"6328#7411#7412#38454","text":"behind the leader#at the end#in the front#in front of the tail rider"},
{"testId":511,"qid":18260,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake and swerve at the same moment; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":446,"qid":18301,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"22821#22822#28897#30525","text":"Apply only the front brake#Apply only the rear brake#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":475,"qid":18301,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"22821#22822#28897#30525","text":"Apply only the front brake#Apply only the rear brake#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":293,"qid":18306,"description":"If you can't cross an obstacle straight on, cross at an angle of at least ____ degrees.","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If this is not practicable, pass the obstruction at a 45-degree angle.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#6477","text":"20#45#90#30"},
{"testId":471,"qid":18306,"description":"If you can't cross an obstacle straight on, cross at an angle of at least ____ degrees.","explanation":"Crossing an unavoidable obstruction at a 90-degree angle is the safest method. (This will prevent your front wheel from being deflected by the impediment.) If this is not practicable, pass the obstruction at a 45-degree angle.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#6477","text":"20#45#90#30"},
{"testId":464,"qid":18344,"description":"Which of these is NOT a result of drinking alcohol?","explanation":"Alcohol may dull judgement, slow response times, diminish awareness, and affect eyesight. Your coordination, eyesight, balance, and other crucial mental and physical abilities required for safe motorcycle riding may begin to decline after only one drink.","imageName":"","correctAnswer":10040,"isBookmarked":0,"aid":"10040#25449#25846#25848","text":"Increased alertness#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":471,"qid":18344,"description":"Which of these is NOT a result of drinking alcohol?","explanation":"Alcohol may dull judgement, slow response times, diminish awareness, and affect eyesight. Your coordination, eyesight, balance, and other crucial mental and physical abilities required for safe motorcycle riding may begin to decline after only one drink.","imageName":"","correctAnswer":10040,"isBookmarked":0,"aid":"10040#25449#25846#25848","text":"Increased alertness#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":84,"qid":18358,"description":"You can start a swerve by:","explanation":"Swerves may be initiated by pushing the handgrip on the side of the desired direction. As a result, your motorbike will tilt sharply in that direction.","imageName":"","correctAnswer":30463,"isBookmarked":0,"aid":"8279#30461#30462#30463","text":"applying the rear brake#downshifting#pressing the handgrip on the side opposite to the direction in which you want to swerve#pressing the handgrip on the side of the direction in which you want to swerve"},
{"testId":446,"qid":18371,"description":"You should maintain a _____ following distance behind the vehicle in front of you.","explanation":"According to the North Dakota Motorcycle Operator Manual, you should maintain a following distance of at least three seconds behind the vehicle in front of you.","imageName":"","correctAnswer":4096,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":446,"qid":18372,"description":"You shouldn't drive in the same lane as a car or another vehicle because:","explanation":"Do not share the same lane as automobiles or other motor vehicles because each motor vehicle need the whole width of the lane to function properly. It is normally illegal to share a lane with a vehicle or truck. It is not prohibited in North Dakota to share a lane with another motorbike (pair up), but it is still a terrible idea. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":33951,"isBookmarked":0,"aid":"33624#33951#33952#38903","text":"car drivers may not allow you to share their lane#cars and motorcycles each need a full lane width#most of the roads are too narrow for that#you could be hit by a sudden wind blast"},
{"testId":446,"qid":18375,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To maintain a good hold over uneven terrain, grasp the handgrips firmly. Begin by keeping your right wrist flat. This will assist you avoid mistakenly applying too much throttle.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6528#7896#10943","text":"up#locked#flat#comfortable"},
{"testId":446,"qid":18376,"description":"Motorcycles usually need:","explanation":"Motorcycles typically require the same amount of stopping distance as cars. Make sure to provide enough room cushion.","imageName":"","correctAnswer":26459,"isBookmarked":0,"aid":"26459#26460#26461#26462","text":"the same distance to stop as cars do#more distance to stop than cars do#less distance to stop than cars do#more room to operate safely than cars do"},
{"testId":446,"qid":18379,"description":"To park along the side of the road, you should park:","explanation":"To park on the side of the road, park at an angle with your back tire contacting the curb.","imageName":"","correctAnswer":7019,"isBookmarked":0,"aid":"74#7018#7019#7020","text":"parallel to the curb#with your front tire touching the curb#with your rear tire touching the curb#on the curb"},
{"testId":294,"qid":18386,"description":"For the most stable riding, position your knees and feet__________.","explanation":"For the most secure riding posture, place your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"30058#30059#36100#36101","text":"away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests#against the gas tank and pointing downward#away from the gas tank and pointing downward"},
{"testId":446,"qid":18386,"description":"For the most stable riding, position your knees and feet__________.","explanation":"For the most secure riding posture, place your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"30058#30059#36100#36101","text":"away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests#against the gas tank and pointing downward#away from the gas tank and pointing downward"},
{"testId":454,"qid":18386,"description":"For the most stable riding, position your knees and feet__________.","explanation":"For the most secure riding posture, place your knees against the gas tank and your feet firmly on the footrests.","imageName":"","correctAnswer":30059,"isBookmarked":0,"aid":"30058#30059#36100#36101","text":"away from the gas tank and firmly on the footrests#against the gas tank and firmly on the footrests#against the gas tank and pointing downward#away from the gas tank and pointing downward"},
{"testId":446,"qid":18388,"description":"When should you bike in a single file when riding in a group?","explanation":"Either riding in a group, when entering or leaving a highway, as well as turning or going around bends, you should ride single file.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":446,"qid":18390,"description":"When should you get up slightly from your seat?","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":31095,"isBookmarked":0,"aid":"7392#7393#31095#31096","text":"When riding on slippery surfaces#When preparing to pass another vehicle#When riding over obstacles#To increase your visibility"},
{"testId":446,"qid":18391,"description":"When traveling with others, you should NEVER ride:","explanation":"Even though it is not against the law in North Dakota, you should never ride next to another motorbike in the same lane. It's possible that you won't be able to move around a danger far enough. For safe operation, each motorbike requires a full lane width.","imageName":"","correctAnswer":38721,"isBookmarked":0,"aid":"6736#7198#31097#38721","text":"single file#in a staggered formation#with more than two other motorcyclists#two abreast in the same lane"},
{"testId":446,"qid":18392,"description":"Which of the following activities should not be performed at night?","explanation":"To keep safe at night, you should reduce your pace rather than raise it.","imageName":"","correctAnswer":10578,"isBookmarked":0,"aid":"6239#8123#10578#23089","text":"Increase your following distance#Use your high beam#Increase your speed#Adjust your lane position when necessary"},
{"testId":446,"qid":18394,"description":"Which of the following is the most dangerous place to ride a motorcycle?","explanation":"An intersection can be a hazardous place for a motorcycle because many car drivers are doing different things and may not notice the motorcycle.","imageName":"","correctAnswer":30816,"isBookmarked":0,"aid":"638#30816#31758#31759","text":"A school zone#An intersection#A freeway#A rural road"},
{"testId":446,"qid":18401,"description":"How do you refrain from using too much throttle?","explanation":"Starting with your right wrist flat might help you avoid applying too much throttle.","imageName":"","correctAnswer":10771,"isBookmarked":0,"aid":"10769#10770#10771#35325","text":"Start with your right wrist raised#Keep the clutch pulled in#Start with your right wrist flat#Ride only in lower gears"},
{"testId":84,"qid":18405,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6763#7097","text":"Left#Center#Left or right#Center or right"},
{"testId":446,"qid":18405,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6216#6763#7097","text":"Left#Center#Left or right#Center or right"},
{"testId":446,"qid":18407,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":483,"qid":18407,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":446,"qid":18411,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":37535,"isBookmarked":0,"aid":"10909#32687#37535#38904","text":"A larger angle would be unsafe#It lets you cross the tracks quickly#At a lesser angle, you might catch your tires#It gives you the best view of approaching trains"},
{"testId":458,"qid":18411,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":37535,"isBookmarked":0,"aid":"10909#32687#37535#38904","text":"A larger angle would be unsafe#It lets you cross the tracks quickly#At a lesser angle, you might catch your tires#It gives you the best view of approaching trains"},
{"testId":446,"qid":18415,"description":"The LEAST effective technique of attaching baggage to your motorcycle is to utilise:","explanation":"Never use ropes to secure luggage to a motorbike. Over time, ropes might get looser or knots can unravel. A better option would be elastic cords.","imageName":"","correctAnswer":8226,"isBookmarked":0,"aid":"8225#8226#8228#8334","text":"Elastic cords#ropes#Tank bags#Saddlebags"},
{"testId":458,"qid":18429,"description":"When can you be confident that another driver will yield to you?","explanation":"Even if a motorist makes eye contact with you or looks to be slowing down, you can never be certain that he or she will yield to you.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#24674#34558#38905","text":"Never#When he or she makes eye contact with you#When he or she starts to slow down#When you are legally entitled to the right of way"},
{"testId":454,"qid":18478,"description":"A front wheel skid can occur as a result of:","explanation":"Locking the front wheel may cause a front-wheel skid.","imageName":"","correctAnswer":30834,"isBookmarked":0,"aid":"6506#8126#30833#30834","text":"turning too quickly#locking the rear wheel#using only the rear brake#locking the front wheel"},
{"testId":464,"qid":18524,"description":"When prepared to pass the vehicle in front of you, ride in the _________ of your lane to increase your field of sight.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":6211,"isBookmarked":0,"aid":"6210#6211#6212#6213","text":"the center portion#the left portion#the right portion#any portion"},
{"testId":462,"qid":18536,"description":"When should you look in the mirror?","explanation":"When you're ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22962,"isBookmarked":0,"aid":"22962#39134#39135#39136","text":"In all of the above situations#While you're stopped at an intersection#When you're about to slow down#When you're about to change lanes"},
{"testId":450,"qid":18572,"description":"Motorcycles are hard for vehicle drivers to see. Which of the following will raise their awareness of you?","explanation":"You should ride in an appropriate lane position, wear bright clothes, and use your lights to maximize your visibility to other vehicle operators.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#24074#34214#34215","text":"All of the above#Wearing bright clothing#Riding in a suitable lane position#Using your headlight"},
{"testId":450,"qid":18581,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Roll on the throttle to maintain or slightly increase your speed to assist keep your motorbike stable in a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":450,"qid":18592,"description":"How many motorcycle lane slots are there in each lane of traffic?","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":450,"qid":18593,"description":"A motorcycle passenger under the age of 18 is not required to have ______ in Ohio.","explanation":null,"imageName":"","correctAnswer":38716,"isBookmarked":0,"aid":"8435#38715#38716#38717","text":"footrests#a helmet#a backpack#designated seat room"},
{"testId":450,"qid":18595,"description":"You should _______, when braking on slick conditions.","explanation":"You should utilize both the front and rear brakes while stopping on slippery ground. It is always preferable to utilize both brakes.","imageName":"","correctAnswer":28935,"isBookmarked":0,"aid":"22259#22260#28935#30065","text":"use only the front brake#use only the rear brake#use both the front and rear brakes#coast in neutral"},
{"testId":450,"qid":18602,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":null,"imageName":"","correctAnswer":39142,"isBookmarked":0,"aid":"23036#23037#23038#39142","text":"Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian#Stop and wait for the pedestrian to cross your half of the roadway"},
{"testId":450,"qid":18607,"description":"When following another motorcycle, keep a following distance of at least :","explanation":"When following another motorbike, allow at least two seconds between you and it, exactly as if you were following a vehicle.","imageName":"","correctAnswer":26444,"isBookmarked":0,"aid":"22147#22148#26443#26444","text":"4 seconds#6 seconds#3 seconds#2 seconds"},
{"testId":450,"qid":18618,"description":"It is critical to verify _____________ , before pulling off the road.","explanation":"Before pulling off the road, you should check the surface to see if it is safe to ride on. Motorcycles require a firm surface with a lot of traction.","imageName":"","correctAnswer":10858,"isBookmarked":0,"aid":"7721#10856#10857#10858","text":"The traffic in front of you#If your lights are working#If it is a designated pull-off area#If the surface is safe to ride on"},
{"testId":475,"qid":18631,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":483,"qid":18631,"description":"When approaching a turn, when is the best time to change gears?","explanation":"If you need to swap gears as you approach a corner, do it as soon as feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6467#6468#9435","text":"Before the turn#During the turn#After the turn#At any time"},
{"testId":467,"qid":18636,"description":"When should you flash your brake light?","explanation":"If you slow down unexpectedly, alert the person behind you by flashing your brake light.","imageName":"","correctAnswer":24931,"isBookmarked":0,"aid":"6945#6946#24686#24931","text":"You slow down in rural areas#The speed limit changes#You are going to turn left#You slow down where others might not expect it"},
{"testId":291,"qid":18730,"description":"Lower your risks of an accident by:","explanation":"You may reduce your chances of being involved in an accident by selecting an appropriate lane position, detecting several risks, and wearing bright clothes that allows you to be seen more readily.","imageName":"","correctAnswer":10777,"isBookmarked":0,"aid":"6683#6684#10777#29434","text":"identifying multiple hazards#wearing bright clothing#Doing all of the above#choosing a suitable lane position"},
{"testId":479,"qid":18751,"description":"In a turn, rolling on the throttle:","explanation":"Rolling on the throttle to maintain or slightly increase your speed during a turn helps balance the motorbike.","imageName":"","correctAnswer":6201,"isBookmarked":0,"aid":"6201#6362#28402#34552","text":"helps stabilize the motorcycle#should only be done in emergencies#is done while shifting gears#is done to pass another vehicle"},
{"testId":454,"qid":18772,"description":"When riding a motorcycle on a slippery surface, you should _____ to keep from skidding.","explanation":"On slick terrain, stopping takes longer. As a result, you should slow down and extend your following distance. It is especially vital to slow down while approaching a curve. Remember that curve speed limitations only apply under excellent surface conditions.","imageName":"","correctAnswer":757,"isBookmarked":0,"aid":"757#5098#14064#22864","text":"slow down#shift to a higher gear#speed up#lean your motorcycle in any direction"},
{"testId":454,"qid":18798,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":458,"qid":18798,"description":"The rear brake provides ________ of the stopping power of a motorcycle.","explanation":"The rear brake accounts for about one-quarter of the motorcycle's stopping power. To stop, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7563,"isBookmarked":0,"aid":"7562#7563#7565#10698","text":"three-quarters#one-quarter#all#one-half"},
{"testId":454,"qid":18801,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots can untie.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":462,"qid":18801,"description":"Which of the following is the most effective method of cargo security?","explanation":"Elastic ropes are the finest technique to secure goods. Rope may stretch and knots can untie.","imageName":"","correctAnswer":7629,"isBookmarked":0,"aid":"7628#7629#7631#22902","text":"Nylon rope#Elastic cords#Cable ties#Metal chains"},
{"testId":454,"qid":18802,"description":"You should cover the clutch and brake whenever possible to:","explanation":"Cover the clutch and brake anytime you wish to reduce your response time.","imageName":"","correctAnswer":37548,"isBookmarked":0,"aid":"27#6476#24790#37548","text":"Change lanes#Slow down#alert others to your actions#shorten your reaction time"},
{"testId":454,"qid":18810,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":39126,"isBookmarked":0,"aid":"22821#22822#39126#39127","text":"Apply only the front brake#Apply only the rear brake#Apply both the front and rear brakes without locking the wheels#Apply both the front and rear brakes until the wheels lock"},
{"testId":458,"qid":18810,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":39126,"isBookmarked":0,"aid":"22821#22822#39126#39127","text":"Apply only the front brake#Apply only the rear brake#Apply both the front and rear brakes without locking the wheels#Apply both the front and rear brakes until the wheels lock"},
{"testId":467,"qid":18810,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":39126,"isBookmarked":0,"aid":"22821#22822#39126#39127","text":"Apply only the front brake#Apply only the rear brake#Apply both the front and rear brakes without locking the wheels#Apply both the front and rear brakes until the wheels lock"},
{"testId":454,"qid":18814,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and brakes as you approach an intersection where a vehicle may cross your path to reduce response time.","imageName":"","correctAnswer":38713,"isBookmarked":0,"aid":"10521#10522#28931#38713","text":"come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#Beep your horn to warn other vehicle operators#cover the clutch and the brakes"},
{"testId":471,"qid":18814,"description":"You should ________ when approaching an intersection where a vehicle may cross your path.","explanation":"Cover the clutch and brakes as you approach an intersection where a vehicle may cross your path to reduce response time.","imageName":"","correctAnswer":38713,"isBookmarked":0,"aid":"10521#10522#28931#38713","text":"come to a complete stop even if you have the right-of-way#always yield to vehicles approaching the intersection#Beep your horn to warn other vehicle operators#cover the clutch and the brakes"},
{"testId":454,"qid":18816,"description":"If you have a windscreen on your motorcycle,:","explanation":null,"imageName":"","correctAnswer":34256,"isBookmarked":0,"aid":"34256#34536#35534#40644","text":"you should still wear eye protection#you don't need to wear eye protection#you don't need to wear a helmet#you don't need to wear either eye protection or a helmet"},
{"testId":454,"qid":18817,"description":"Motorcyclists should ride _____, when riding in a group.","explanation":"Motorcyclists should ride in a staggered arrangement while riding in a group. It keeps the group close while also providing some breathing room.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#23023","text":"single file#in pairs#in a staggered formation#in an alternate formation"},
{"testId":475,"qid":18842,"description":"You observe a driver in one of the parked vehicles as you pass by a line of them. What ought you to do?","explanation":"If you observe a motorist inside a parked car as you pass, blow your horn to alert the driver to your presence. In this manner, he or she will not open a door in your route.","imageName":"","correctAnswer":31148,"isBookmarked":0,"aid":"10718#10919#30227#31148","text":"Flash your brake light#Wave your arms#Come to a complete stop#Beep your horn"},
{"testId":291,"qid":18860,"description":"Cross railroad rails at a 45-degree angle or more because:","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":30050,"isBookmarked":0,"aid":"10314#24737#30050#39496","text":"a larger angle would be unsafe#it lets you cross the tracks quickly#at a smaller angle, you might catch your tires#it gives you the best view of approaching trains"},
{"testId":467,"qid":18908,"description":"What do you press in the \"press\" step of turning?","explanation":"The \"push\" part of turning involves pressing the handgrip on the side of the direction you desire to turn. As a result, your motorbike will tilt in that way.","imageName":"","correctAnswer":33597,"isBookmarked":0,"aid":"8404#23051#31459#33597","text":"The front or rear brake#The clutch lever#Your feet on the footrests#The handgrip on the side of the direction in which you want to turn"},
{"testId":487,"qid":18908,"description":"What do you press in the \"press\" step of turning?","explanation":"The \"push\" part of turning involves pressing the handgrip on the side of the direction you desire to turn. As a result, your motorbike will tilt in that way.","imageName":"","correctAnswer":33597,"isBookmarked":0,"aid":"8404#23051#31459#33597","text":"The front or rear brake#The clutch lever#Your feet on the footrests#The handgrip on the side of the direction in which you want to turn"},
{"testId":462,"qid":18944,"description":"You can increase your line of sight when preparing to pass the vehicle ahead of you by riding in ________ portion of your lane.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. It allows you to look beyond the car in front of you and detect potential threats.","imageName":"","correctAnswer":122,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":458,"qid":18974,"description":"When going by another vehicle,:","explanation":null,"imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":462,"qid":18974,"description":"When going by another vehicle,:","explanation":null,"imageName":"","correctAnswer":8061,"isBookmarked":0,"aid":"8061#8063#8064#23008","text":"you must not exceed the speed limit#Turn on your hazard lights#ride in the highest gear#ride close to the edge of the road"},
{"testId":458,"qid":18975,"description":"To maintain proper control of your motorcycle, position your arms:","explanation":"Position your arms with your elbows slightly bent to keep proper control of your motorbike.","imageName":"","correctAnswer":6605,"isBookmarked":0,"aid":"6268#6603#6604#6605","text":"with elbows locked#at an angle of 90 degrees#with wrists locked#with elbows slightly bent"},
{"testId":458,"qid":18980,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel. After pushing in the clutch, hold it in until you come to a complete stop or the throttle is released.","imageName":"","correctAnswer":39523,"isBookmarked":0,"aid":"28950#29687#30447#39523","text":"Apply both the front and rear brakes#Pull off the road#Pull the engine cut-off switch#Twist it back and forth"},
{"testId":458,"qid":18991,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike in a regular turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":458,"qid":18997,"description":"If you apply heavy braking on a straightaway and your back wheel locks,:","explanation":"If you mistakenly lock the rear wheel on a traction-rich surface, leave it locked until you come to a full stop. You can manage the motorbike on a straightaway even if the back wheel is locked provided it is upright and moving in a straight path.","imageName":"","correctAnswer":24661,"isBookmarked":0,"aid":"7326#24660#24661#24872","text":"Pull the kill engine switch#release both the front and rear brakes and coast to a stop#keep the rear wheel locked until you have stopped#release the rear brake and only use the front brake"},
{"testId":458,"qid":19001,"description":"When a vehicle enters your lane from an on-ramp, you should :","explanation":"When a car enters your lane from an on-ramp, shift lanes to give the vehicle enough space to join.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#7651#35358","text":"Change lanes#Beep your horn#Come to a complete stop#flash your headlight"},
{"testId":458,"qid":19008,"description":"Other drivers pose a hazard when ______.","explanation":"A motorist inside a parked vehicle you are passing may miss you and open a door into your path.","imageName":"","correctAnswer":24735,"isBookmarked":0,"aid":"8369#23070#23071#24735","text":"you are making a right turn#you are parking your motorcycle#you are riding through a school zone#You are passing a line of parked cars"},
{"testId":458,"qid":19016,"description":"When is it permissible to park a motorcycle in a disability parking space?","explanation":"In Oregon, you may park your car in a disability parking area only if you have a valid disabled parking permit.","imageName":"","correctAnswer":39524,"isBookmarked":0,"aid":"38479#38480#38481#39524","text":"Only if you're parking for five minutes or less#Only if you're unloading cargo#Only if you're dropping off a passenger#Only if you have a disabled parking permit"},
{"testId":458,"qid":19018,"description":"In correct riding position,:","explanation":"Your arms should be slightly bent, your feet should be firmly planted on the foot pegs, and your knees should be pressed against the gas tank for balance.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24640#24641#24642#24643","text":"your arms are slightly bent#your feet are resting firmly on the footrests#your knees are against the gas tank#all of the above are true"},
{"testId":511,"qid":19028,"description":"What is the optimal lane position in areas where there is oil and grease buildup?","explanation":"Oil and grease accumulate in the middle of the lane. Ride in the left or right lane in areas where there is oil and grease accumulation. Ride in the tire tracks left by other cars for the most traction.","imageName":"","correctAnswer":30028,"isBookmarked":0,"aid":"6385#6387#7176#30028","text":"In the blind spot#Not important#The center lane position#The left or right lane position"},
{"testId":467,"qid":19054,"description":"To minimize weariness, you should:","explanation":"Riding a motorbike is more exhausting than driving a vehicle. You should stop and relax at least once every two hours to prevent weariness.","imageName":"","correctAnswer":10914,"isBookmarked":0,"aid":"7622#7624#10914#30796","text":"Drink energy drinks#Avoid wearing a helmet#Stop at least every two hours#Drink lots of water"},
{"testId":296,"qid":19086,"description":"When you're getting ready to slow down, check:","explanation":"Check to see whether there is traffic behind you before slowing down. If this is the case, you should flash your brake light to alert them that you are slowing down.","imageName":"","correctAnswer":24658,"isBookmarked":0,"aid":"10492#10493#10494#24658","text":"to make sure you are in neutral before stopping#to your left and right#the gauges on your motorcycle#if there is traffic behind you"},
{"testId":462,"qid":19176,"description":"How should you adjust your motorcycle control when riding with a passenger?","explanation":"With a passenger, your motorbike will react more slowly. As a result, you should ride a little slower, particularly when approaching curves, bends, or bumps.","imageName":"","correctAnswer":6250,"isBookmarked":0,"aid":"6250#6251#22792#22793","text":"Ride slower#Ride faster#Only ride in the center lane position#Only ride in the left lane position"},
{"testId":462,"qid":19177,"description":"Your arms should be positioned _________, while clutching the handlebars.","explanation":"Your arms should be slightly bent while correctly positioned on your motorbike. This will help you to make safe and precise turns.","imageName":"","correctAnswer":6269,"isBookmarked":0,"aid":"6266#6268#6269#22937","text":"with elbows at 90 degrees#with elbows locked#so they are slightly bent#anywhere on the handgrips"},
{"testId":462,"qid":19185,"description":"If _____, you can be charged with driving while under the influence of alcohol (DWUI).","explanation":"If you are caught driving with a blood alcohol level (BAC) of 0.08 percent or more, you may be prosecuted with driving under the influence (DUI). If you are convicted, you might face heavy consequences such as prison time and a hefty fine.","imageName":"","correctAnswer":30438,"isBookmarked":0,"aid":"10636#30438#39258#39259","text":"You are seen exiting a drinking establishment#your blood alcohol concentration (BAC) is 0.08% or above#you have closed containers of alcoholic drinks in your saddlebags#you agree to take one or more chemical tests of breath, blood, or urine"},
{"testId":462,"qid":19189,"description":"Riding a motorcycle while wearing a face shield:","explanation":"Wearing eye protection is required under Pennsylvania law. Any sort of eye protection will suffice, however PennDOT strongly advises that the eye protection be shatterproof. A face shield is the greatest kind of face and eye protection since it covers your whole face and will not blow away in the wind. [\"Pennsylvania's Motorcycle Helmet Law: Fact Sheet,\" Pennsylvania Department of Transportation, July 2016, http://www.dot.state.pa.us/Public/DVSPubsForms/BDL/BDL Fact Sheets/fs-MH .pdf]","imageName":"","correctAnswer":7280,"isBookmarked":0,"aid":"7280#7282#28971#30524","text":"is the best form of face and eye protection#is not as effective as wearing goggles#is only required of riders under the age of 18#is legally required of all riders"},
{"testId":462,"qid":19191,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Make sure your headlights are on on and ride in a lane that gives you the greatest view of incoming traffic. Never rely on eye contact to indicate that a motorist will yield.","imageName":"","correctAnswer":33205,"isBookmarked":0,"aid":"7367#7368#23087#33205","text":"Always ride with your headlight off#Increase your speed as you approach an intersection#Always count on eye contact as a sign that a driver will yield#Select a lane position that will most increase your visibility to others"},
{"testId":462,"qid":19192,"description":"When transporting cargo or passengers,:","explanation":"The stopping distance increases while carrying goods or a passenger. You should adjust your riding appropriately.","imageName":"","correctAnswer":39855,"isBookmarked":0,"aid":"7404#22982#39854#39855","text":"you must carry a special license#you must ride only during the day#your stopping distance will decrease#your stopping distance will increase"},
{"testId":462,"qid":19194,"description":"What happens if you ride too fast through a turn?","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":28212,"isBookmarked":0,"aid":"28211#28212#31447#33964","text":"You may tip over#You may veer into another lane#Your motorcycle may suffer mechanical failure#Other vehicle operators may be distracted"},
{"testId":462,"qid":19195,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#10989#30069#31799","text":"All of the above are correct#If you are chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":471,"qid":19195,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#10989#30069#31799","text":"All of the above are correct#If you are chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":462,"qid":19198,"description":"Riding across lanes of traffic is:","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes (also known as lane splitting) is banned in Pennsylvania.","imageName":"","correctAnswer":7652,"isBookmarked":0,"aid":"7652#22874#30457#35096","text":"prohibited at all times#allowed in designated areas#allowed only when traffic is at a standstill#prohibited only at night"},
{"testId":462,"qid":19200,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":"Vehicle operators in Pennsylvania are required by law to cede the right-of-way to pedestrians in marked or unmarked crosswalks. If you come to a halt before a crosswalk that a pedestrian has just entered, wait for the pedestrian to complete crossing the road.","imageName":"","correctAnswer":23035,"isBookmarked":0,"aid":"23035#23036#23037#23038","text":"Stop and wait for the pedestrian to cross the road#Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian"},
{"testId":462,"qid":19202,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"Your blood alcohol content may be affected by how much alcohol you consume, how quickly you drink it, and how much you weigh (BAC). It makes no difference when or where you drink. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":7290,"isBookmarked":0,"aid":"7290#7826#29011#36098","text":"How fast you drink#The time of day#The location at which you are drinking#The type of alcoholic drink"},
{"testId":462,"qid":19205,"description":"Drinking merely one alcoholic drink per hour ________.","explanation":"There is no such thing as a safe quantity of alcohol to consume. A single drink might impair your talents and judgment. Drinking merely one drink per hour will not keep everyone under the legal limit. Alcohol is more concentrated in those who are underweight.","imageName":"","correctAnswer":33602,"isBookmarked":0,"aid":"8038#8039#33602#33603","text":"prevents drunkenness if drinking beer#is the legal limit for males#doesn't guarantee that the alcohol won't affect you#ensures that you won't be over the legal limit"},
{"testId":462,"qid":19207,"description":"More than ______ motorcycle riders should be divided into smaller groups.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#6209","text":"Two#Six#Five#Three"},
{"testId":462,"qid":19210,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":462,"qid":19218,"description":"When riding at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":479,"qid":19218,"description":"When riding at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":491,"qid":19218,"description":"When riding at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":495,"qid":19218,"description":"When riding at night, you should:","explanation":"You should reduce your speed at night so that you can stop inside the beam of your headlight. You have no means of knowing what is beyond the beam of your flashlight in the dark.","imageName":"","correctAnswer":28432,"isBookmarked":0,"aid":"28430#28431#28432#28433","text":"Wear brighter clothing so you can be seen#Follow vehicles closely#Slow down so you can stop within the range of your headlight#Avoid riding on rural roads"},
{"testId":462,"qid":19219,"description":"To discourage others from sharing your lane, ride in the _____ lane.","explanation":"Ride in the middle lane to dissuade other cars from sharing your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":475,"qid":19239,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and both brakes to reduce response time.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"22927#26499#26500#27089","text":"Pull in the clutch#Shift into a higher gear#Skim your feet along the ground#Cover the clutch and both brakes"},
{"testId":499,"qid":19239,"description":"What should you do when riding through a potentially hazardous area?","explanation":"When riding through a potentially hazardous region, cover the clutch and both brakes to reduce response time.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"22927#26499#26500#27089","text":"Pull in the clutch#Shift into a higher gear#Skim your feet along the ground#Cover the clutch and both brakes"},
{"testId":475,"qid":19242,"description":"Motorcycles usually need:","explanation":"Motorcycles normally need the same stopping distance as vehicles in traffic.","imageName":"","correctAnswer":39860,"isBookmarked":0,"aid":"39859#39860#39861#39862","text":"a smaller stopping distance than cars do#the same stopping distance as cars do#a larger stopping distance than cars do#a larger or smaller stopping distance than cars do, depending on the models of the motorcycles"},
{"testId":507,"qid":19252,"description":"How should you position your body in a slow, sharp curve?","explanation":"In a gradual, tight turn, keep your torso straight as the motorbike leans.","imageName":"","correctAnswer":30085,"isBookmarked":0,"aid":"7901#10629#24772#30085","text":"Lean with the motorcycle#Lean in the opposite direction of the motorcycle#Lean back as far as possible#Keep your body upright"},
{"testId":475,"qid":19264,"description":"It is essential to inspect your mirrors:","explanation":"When you are ready to change lanes, slow down, or stop at an intersection, check your mirrors.","imageName":"","correctAnswer":22688,"isBookmarked":0,"aid":"22688#30792#32399#32400","text":"In any of the above situations#When you are about to change lanes#When you are about to slow down#While you are stopped at an intersection"},
{"testId":464,"qid":19281,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Gravel roads may be risky for motorcycle riders due to the lack of grip provided by the loose sand and gravel.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10590#39850#40113","text":"They do not have posted speed limits#Gravel provides less traction than pavement#Drivers are known to drive recklessly#Roadway markings are not present"},
{"testId":487,"qid":19281,"description":"Why may gravel roads be hazardous for motorcyclists?","explanation":"Gravel roads may be risky for motorcycle riders due to the lack of grip provided by the loose sand and gravel.","imageName":"","correctAnswer":10590,"isBookmarked":0,"aid":"10587#10590#39850#40113","text":"They do not have posted speed limits#Gravel provides less traction than pavement#Drivers are known to drive recklessly#Roadway markings are not present"},
{"testId":464,"qid":19287,"description":"_____ is  the finest approach to protect your eyes.","explanation":"A shatter-resistant face shield is the best approach to safeguard your eyes. Select a face shield-equipped helmet. When riding a motorbike on public highways in Rhode Island, every biker is required by law to wear eye protection.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":294,"qid":19374,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":297,"qid":19374,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":299,"qid":19374,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":295,"qid":19390,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Passing slower cars at night increases your risk. It is risky and should be approached with care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6666#10470#10804#22837","text":"Changing your lane position#Passing slower vehicles#Wearing reflective clothing#Reducing your speed"},
{"testId":471,"qid":19392,"description":"When you ride at night, you should:","explanation":"To give yourself more time to respond to the unexpected while riding at night, increase your following distance and go slower than you do during the day.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"10559#10560#24714#32738","text":"not use your headlight#wear a tinted face shield#ride slower than you do during the day#stay close behind the vehicle in front of you"},
{"testId":467,"qid":19402,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#22968","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on a metal grating"},
{"testId":471,"qid":19402,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#22968","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on a metal grating"},
{"testId":467,"qid":19404,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"You are legally inebriated if your blood alcohol content (BAC) is 0.08 percent or above. Driving under the influence may be prosecuted in Rhode Island if you are operating a car (DUI).","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#4653","text":"0.08%#0.09%#0.05%#0.01%"},
{"testId":467,"qid":19417,"description":"The available lane locations for a motorcycle inside a lane are:","explanation":"A motorbike has three lane positions in each lane of traffic: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":7454,"isBookmarked":0,"aid":"7454#7455#23030#23031","text":"left, center, and right#left and right#center only#1, 2, 3, and 4"},
{"testId":467,"qid":19435,"description":"After how many alcoholic drint(s), your ability to ride can be affected.","explanation":"Even one alcoholic drink might impair your riding ability. There is no such thing as a safe quantity of alcohol to ingest.","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":467,"qid":19439,"description":"What effect does carrying a passenger have on a motorcycle?","explanation":"Carrying a passenger makes your riding work more difficult. Balance becomes more difficult, stopping distance grows, and maneuverability decreases. When you're near or inside an intersection, be cautious.","imageName":"","correctAnswer":23029,"isBookmarked":0,"aid":"23029#40119#40120#40121","text":"All of the above are true#Balance is more difficult#Stopping distance is increased#Maneuverability is reduced"},
{"testId":467,"qid":19442,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is controlled by the lever on the left. The right foot pedal operates the rear brake, while the left foot lever operates the gear changer.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":487,"qid":19442,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is controlled by the lever on the left. The right foot pedal operates the rear brake, while the left foot lever operates the gear changer.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":491,"qid":19442,"description":"The front brake is activated by:","explanation":"The right hand lever controls the front brake. The clutch is controlled by the lever on the left. The right foot pedal operates the rear brake, while the left foot lever operates the gear changer.","imageName":"","correctAnswer":6819,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":483,"qid":19532,"description":"You can boost your visibility by:","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":471,"qid":19597,"description":"To prevent a collision, you should _______ if an object unexpectedly emerges in your path.","explanation":"To prevent an accident, swerve around an item that unexpectedly emerges in your riding path. When swerving, never brake.","imageName":"","correctAnswer":6152,"isBookmarked":0,"aid":"6149#6152#10621#10622","text":"lean your motorcycle#swerve around the object#apply the front brake gently#swerve and apply the brakes"},
{"testId":471,"qid":19613,"description":"The rear brake is operated by:","explanation":"The right hand lever controls the front brake. The right foot pedal operates the back brake.","imageName":"","correctAnswer":7702,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":479,"qid":19613,"description":"The rear brake is operated by:","explanation":"The right hand lever controls the front brake. The right foot pedal operates the back brake.","imageName":"","correctAnswer":7702,"isBookmarked":0,"aid":"6818#6819#6904#7702","text":"the left hand lever#the right hand lever#the left foot lever#the right foot pedal"},
{"testId":471,"qid":19614,"description":"If you slow down unexpectedly, you should:","explanation":"If you're going to slow down in an area where others may not anticipate it, tap your brake to flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"6286#7085#24614#24615","text":"Flash your brake light#use your front brake only#use your signal lights#use the left lane position only"},
{"testId":471,"qid":19615,"description":"Motorcyclists may find it tough to maintain safe control on surfaces with weak traction such as:","explanation":"Motorcyclists may find it difficult to retain safe control on slick surfaces such as the following: 1) wet pavement, especially shortly after it begins to rain and before surface oil washes to the side of the road; 2) gravel roads or areas where sand and gravel gather; 3) mud, snow, and ice; and 4) lane markers, steel plates, and manhole covers, particularly when wet.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#22974#22975#22976","text":"All of the above#wet pavement and gravel roads#mud, snow, and ice#lane markings, steel plates, and manhole covers"},
{"testId":471,"qid":19618,"description":"When should you bike in a single file when riding in a group?","explanation":"When riding in a group, ride single file whenever you need to change lanes, such as when entering or leaving a highway or going around bends.","imageName":"","correctAnswer":7295,"isBookmarked":0,"aid":"6492#7293#7294#7295","text":"At all times#On long stretches of road#In rural areas#When entering a highway"},
{"testId":471,"qid":19627,"description":"Which lane should you ride in when passing parked cars on your right?","explanation":"Ride in the left lane while passing parked automobiles on your right. You can prevent complications caused by doors opening, drivers exiting vehicles, or persons walking between vehicles. If there is approaching traffic on your left, however, ride in the middle lane to leave room on both sides of you.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"4805#6214#6217#8043","text":"The center#Left#Right#The right or center"},
{"testId":471,"qid":19629,"description":"Which of the following impacts your motorcycle riding abilities?","explanation":"Not only may drinking impair your ability to ride a motorbike. Some illicit, prescription, and OTC substances may potentially impair your riding ability. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":471,"qid":19642,"description":"In South Carolina, if a rider is under 21 years of age and his or her motorcycle is equipped with a windshield,:","explanation":null,"imageName":"","correctAnswer":40464,"isBookmarked":0,"aid":"40462#40463#40464#40465","text":"he or she is required to wear goggles or a face shield#he or she is NOT required to wear a helmet#he or she is NOT required to wear goggles or a face shield#he or she may wear glasses instead of goggles or a face shield"},
{"testId":499,"qid":19681,"description":"How can you keep your balance while making a turn with motorcycle?","explanation":"Keep your knees against the gas tank to help you maintain your balance in turns. It makes it easier for you and the motorbike to maneuver together.","imageName":"","correctAnswer":8297,"isBookmarked":0,"aid":"8296#8297#8298#10629","text":"Take your feet off the foot pegs for balance#Keep your knees against the gas tank#Turn your torso in the direction of the turn#Lean in the opposite direction of the motorcycle"},
{"testId":487,"qid":19688,"description":"What effect would carrying a passenger have on your motorcycle?","explanation":"A passenger adds weight to the motorbike. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":30093,"isBookmarked":0,"aid":"23018#30093#32385#32386","text":"The motorcycle will slow down more quickly#The motorcycle will take longer to react to changes#A passenger will help balance the motorcycle#A passenger will make the motorcycle go faster"},
{"testId":491,"qid":19688,"description":"What effect would carrying a passenger have on your motorcycle?","explanation":"A passenger adds weight to the motorbike. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":30093,"isBookmarked":0,"aid":"23018#30093#32385#32386","text":"The motorcycle will slow down more quickly#The motorcycle will take longer to react to changes#A passenger will help balance the motorcycle#A passenger will make the motorcycle go faster"},
{"testId":479,"qid":19714,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Make sure your headlights are on on and ride in a lane that gives you the greatest view of incoming traffic. Never rely on eye contact to indicate that a motorist will yield.","imageName":"","correctAnswer":40641,"isBookmarked":0,"aid":"7367#7368#23087#40641","text":"Always ride with your headlight off#Increase your speed as you approach an intersection#Always count on eye contact as a sign that a driver will yield#Select a lane position that will most increase your visibility to other road users"},
{"testId":475,"qid":19802,"description":"Which of the following colors can make you more visible to other people?","explanation":"Orange, red, yellow, and green are the greatest apparel colors for improving your visibility to others.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#1147#1148#6659","text":"All of the above#Green#Orange#Yellow"},
{"testId":475,"qid":19815,"description":"South Dakota does not require a motorcycle passenger under the age of 18 to have:","explanation":null,"imageName":"","correctAnswer":39517,"isBookmarked":0,"aid":"8435#38715#38717#39517","text":"footrests#a helmet#designated seat room#eye protection"},
{"testId":475,"qid":19818,"description":"When transporting cargo or passengers,:","explanation":"The stopping distance increases while carrying goods or a passenger. You should adjust your riding appropriately.","imageName":"","correctAnswer":39855,"isBookmarked":0,"aid":"22982#35101#39854#39855","text":"you must ride only during the day#you must hold a special license#your stopping distance will decrease#your stopping distance will increase"},
{"testId":475,"qid":19821,"description":"How do you keep your motorcycle stable in slow, tight turns?","explanation":"By maintaining your torso straight while leaning the motorbike into the turn, you can steady the motorcycle in slow, tight bends.","imageName":"","correctAnswer":10643,"isBookmarked":0,"aid":"7901#10643#10645#22898","text":"Lean with the motorcycle#Remain straight while leaning the motorcycle#Lean forward as much as possible#Accelerate or maintain your speed"},
{"testId":475,"qid":19825,"description":"You should use ________ to hold your motorcycle while you wait for the engine to take hold while starting it on a climb.","explanation":"When starting a motorbike uphill, you must prevent it from rolling backward. Hold your motorbike with the front brake as you start the engine and move into first gear. Switch to the rear brake to keep your motorbike stable while you use your right hand to control the throttle. Carefully increase the throttle for extra power, then release the clutch slowly. (Releasing it too rapidly may cause the front tire to lift off the ground or the engine to stall.) As the engine slows and engages, ease off the back brake.","imageName":"","correctAnswer":6146,"isBookmarked":0,"aid":"6146#6147#7725#7726","text":"the front brake#the rear brake#the clutch#your feet"},
{"testId":475,"qid":19834,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If braking and swerving are both required to avoid colliding with an object, you should brake either before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":30047,"isBookmarked":0,"aid":"30046#30047#39497#39498","text":"Brake while swerving#Brake before or after swerving#Brake before swerving, not after swerving#Brake after swerving, not before swerving"},
{"testId":475,"qid":19842,"description":"Which of the following animal-related assertions is true?","explanation":null,"imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#28981#30069#31799","text":"All of the above are correct#If you are being chased by a dog, downshift and approach the animal slowly. As you approach it, accelerate away and leave the animal behind#If you encounter a relatively large animal (deer, elk, cattle) on the road, brake and prepare to stop#You should do everything you safely can to avoid hitting an animal. If you are in traffic, however, stay in your lane"},
{"testId":475,"qid":19844,"description":"When is it permissible to park a motorcycle in a disability parking space?","explanation":"In South Dakota, you may not park in a disability parking place unless you have a disabled parking permit (placard) or a disabled license plate. You may get one by completing the \"Application for Physically Disabled Parking Permit and License Plates\" form to your county treasurer's office. [South Dakota Department of Revenue, \"Disability Parking,\" http://dor.sd.gov/Motor Vehicles/Disability Parking/]","imageName":"","correctAnswer":40456,"isBookmarked":0,"aid":"38479#38480#38481#40456","text":"Only if you're parking for five minutes or less#Only if you're unloading cargo#Only if you're dropping off a passenger#Only if you have a placard or license plate for persons with disabilities"},
{"testId":487,"qid":19864,"description":"More than one motorcycle should ride together:","explanation":"Riding two abreast in the same lane might be hazardous. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger. It is safer to ride two or more bikes in a staggered arrangement, which enables the group to remain near while preserving a spacing cushion.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":499,"qid":19899,"description":"While driving, head checks:","explanation":"Before changing lanes, do a head check.","imageName":"","correctAnswer":6342,"isBookmarked":0,"aid":"6339#6342#35085#35086","text":"only need to be done at night#should be performed before changing lanes#are not needed; motorcycles don't have blind spots#are only needed when you're preparing to turn left"},
{"testId":483,"qid":19985,"description":"Which of the following can help you improve your reaction time?","explanation":"Reduce your response time by covering the clutch and both brakes, allowing you to use them more rapidly.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"10818#10819#27089#30514","text":"Decrease your following distance#Hover your feet above the ground#Cover the clutch and both brakes#Lock your elbows"},
{"testId":483,"qid":19993,"description":"When it begins to rain, the best location to ride is :","explanation":"When it begins to rain, the safest area to ride is in other cars' tire tracks. These are less prone to slipping. The middle lane position might become slick due to oil accumulation.","imageName":"","correctAnswer":28999,"isBookmarked":0,"aid":"7310#7312#7313#28999","text":"in the center lane position#on the shoulder of the road#on main roads only#in the tire tracks of other vehicles"},
{"testId":479,"qid":20003,"description":"You're about to make a turn and need to change gears. When is it appropriate to change gears?","explanation":"If you need to swap gears as you approach a corner, do it before the turn if feasible. This will assist in keeping the turn smooth and steady. A skid may be caused by an abrupt shift in power to the back wheel during a turn.","imageName":"","correctAnswer":6466,"isBookmarked":0,"aid":"6466#6468#9435#40804","text":"Before the turn#After the turn#At any time#In the turn"},
{"testId":479,"qid":20017,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#38910","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep both feet on the footrests"},
{"testId":483,"qid":20017,"description":"Inform your passenger to:","explanation":"Instruct your passenger to hold on tight to your waist, hips, or belt; to keep both feet on the footrests even when the motorcycle is stopped; to keep legs away from the muffler(s), belts, chains, or moving parts; to sit as far forward as possible without crowding you; to stay directly behind you, leaning as you lean; and to avoid unnecessary talk or motion.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"6729#6812#10656#38910","text":"sit as far forward as possible#lean as you lean#Do all of the above#keep both feet on the footrests"},
{"testId":479,"qid":20020,"description":"You are riding your motorcycle when you come to a crosswalk where a pedestrian has just entered. Which of the following should you do?","explanation":null,"imageName":"","correctAnswer":40805,"isBookmarked":0,"aid":"23036#23037#23038#40805","text":"Proceed if the pedestrian is not in your lane#Stop inside the crosswalk#Proceed as long as you will not hit the pedestrian#Stop and wait for the pedestrian to cross your side"},
{"testId":479,"qid":20032,"description":"Your motorcycle must have _____ to transport a passenger.","explanation":"To carry a passenger in Tennessee, your motorbike must have footrests and enough seat space for the individual. The passenger is required to wear a helmet. If the motorbike lacks a windshield, he or she must additionally wear eye protection. Eye protection includes safety goggles, face shields, and glasses with impact resistant lenses.","imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#8615#35097","text":"A windshield#footrests for the passenger#All of the above#specialized shocks"},
{"testId":479,"qid":20033,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes stuck while riding, twist it back and forth to dislodge it. If it doesn't work, turn off the engine and engage the clutch. This will reduce power to the back wheel.","imageName":"","correctAnswer":6583,"isBookmarked":0,"aid":"6423#6569#6582#6583","text":"Apply the rear brake#Apply the front brake#Pull in the clutch#Twist it back and forth to free it"},
{"testId":479,"qid":20035,"description":"Is sharing a lane with another motorbike safe?","explanation":null,"imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":479,"qid":20039,"description":"If your motorbike is not fitted with a windscreen in Tennessee, you must:","explanation":"You and your passenger are required by Tennessee law to wear helmets. Unless your motorbike has a windshield, you and your passenger must also wear eye protection. Eye protection includes safety goggles, face shields, and glasses with impact resistant lenses.","imageName":"","correctAnswer":35080,"isBookmarked":0,"aid":"8194#30073#35079#35080","text":"Avoid carrying passengers#ride only on paved roads#have a windshield installed before you ride the motorcycle#wear eye protection"},
{"testId":491,"qid":20055,"description":"Which of the following provide(s) the best motorcycle traction?","explanation":"For the optimum traction, motorcycles need a hard, solid surface. Warm pavement gives the greatest traction for bikes of the options available.","imageName":"","correctAnswer":6720,"isBookmarked":0,"aid":"6717#6718#6719#6720","text":"Gravel roads#Wet pavement#Snow#Warm pavement"},
{"testId":296,"qid":20088,"description":"What can you do to improve your visibility at blind intersections?","explanation":"At blind crossroads, ride in whatever lane position allows you to be spotted as quickly as feasible. This may change depending on the junction.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#22921#24902#28445","text":"Flash your brake light#Ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":483,"qid":20088,"description":"What can you do to improve your visibility at blind intersections?","explanation":"At blind crossroads, ride in whatever lane position allows you to be spotted as quickly as feasible. This may change depending on the junction.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#22921#24902#28445","text":"Flash your brake light#Ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":297,"qid":20136,"description":"How can you stay awake while riding?","explanation":"You can help prevent fatigue by riding no more than six hours per day, taking at least two hours of rest breaks, and dressing appropriately for the weather.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":491,"qid":20136,"description":"How can you stay awake while riding?","explanation":"You can help prevent fatigue by riding no more than six hours per day, taking at least two hours of rest breaks, and dressing appropriately for the weather.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"10717#23000#30022#38894","text":"Protect yourself from the elements#Do all of the above#Take frequent rest breaks#Limit how long you ride each day"},
{"testId":483,"qid":20196,"description":"You should scan the road _____ ahead of you to give yourself enough time to react to hazards.","explanation":"You should monitor the road 12 seconds ahead to allow yourself adequate time to respond to risks. Twelve seconds may not seem like a long time, but at 60 mph, your motorbike will cover 1,056 feet in that time.","imageName":"","correctAnswer":986,"isBookmarked":0,"aid":"986#6077#6078#6079","text":"12 seconds#60 seconds#40 seconds#25 seconds"},
{"testId":483,"qid":20199,"description":"What should you do to discourage a car driver from trying to share your lane?","explanation":"Riding in the middle lane is the greatest strategy to deter other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":22921,"isBookmarked":0,"aid":"10718#22921#30013#31148","text":"Flash your brake light#Ride in the center lane position#Ride in the left lane position#Beep your horn"},
{"testId":483,"qid":20204,"description":"When is it safe to share a lane with another vehicle?","explanation":"The key word here is \"safely.\" The pairing of motorbikes is not specifically prohibited under Texas law. A staggered pattern, on the other hand, is a safer bet. You should not be sharing a lane with other cars. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#35515#40978#40979","text":"Never#If the other vehicle is a motorcycle#When you're riding between two lanes of vehicles#When you're riding in the right lane position"},
{"testId":483,"qid":20211,"description":"The front brake system:","explanation":"The front brake is responsible for 70% of the stopping force. To stop or slow down, you should always engage both the front and rear brakes.","imageName":"","correctAnswer":7458,"isBookmarked":0,"aid":"7458#7459#7460#7461","text":"provides 70% of the stopping power#provides 30% of the stopping power#should only be used in emergency situations#is controlled by a foot pedal"},
{"testId":483,"qid":20215,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Not only may drinking impair your ability to ride a motorbike. Some illicit, prescription, and OTC substances may potentially impair your riding ability. If you are unsure about the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":297,"qid":20216,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":38457,"isBookmarked":0,"aid":"7646#27957#28419#38457","text":"should be done to fix a wobble#should only be done by experienced riders#is prohibited while riding a motorcycle#allows your knees and elbows to absorb shock"},
{"testId":483,"qid":20216,"description":"Slightly getting up from the seat:","explanation":"Rising slightly from your seat allows your knees and elbows to absorb impact. This approach is useful for cycling over obstacles or on uneven terrain.","imageName":"","correctAnswer":38457,"isBookmarked":0,"aid":"7646#27957#28419#38457","text":"should be done to fix a wobble#should only be done by experienced riders#is prohibited while riding a motorcycle#allows your knees and elbows to absorb shock"},
{"testId":483,"qid":20217,"description":"When prepared to pass the vehicle in front of you, ride in the _______ lane position to increase your line of sight.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":122,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":491,"qid":20217,"description":"When prepared to pass the vehicle in front of you, ride in the _______ lane position to increase your line of sight.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":122,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":499,"qid":20217,"description":"When prepared to pass the vehicle in front of you, ride in the _______ lane position to increase your line of sight.","explanation":"Ride in the left lane to improve your line of sight while passing the car ahead of you. This will allow you to look beyond the car in front of you and detect any threats. It will also make your pass more noticeable to the vehicle's driver.","imageName":"","correctAnswer":122,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":483,"qid":20223,"description":"_____ is  the finest approach to protect your eyes.","explanation":"In the case of a collision, a shatter-resistant face shield may assist protect your whole face. It also shields you from the wind, dust, filth, rain, insects, and stones thrown up by cars in front of you.","imageName":"","correctAnswer":10355,"isBookmarked":0,"aid":"7204#8105#8106#10355","text":"A windshield#Eyeglasses#A three-quarter helmet#A face shield"},
{"testId":483,"qid":20229,"description":"What should you do if you feel the driver of the vehicle ahead of you is unaware that you are following them?","explanation":"If you feel that the driver of the car ahead of you is unaware that you are following, you should ride in the middle lane. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6928,"isBookmarked":0,"aid":"6928#8313#8314#24706","text":"Move into the center lane position#Increase your following distance to 5 seconds#Try to pass the vehicle as soon as possible#Decrease your speed and ride in the left lane position"},
{"testId":483,"qid":20234,"description":"When should you go from riding in a staggered configuration to riding in pairs?","explanation":"The state of Texas does not officially ban partnering up (riding two abreast in the same lane). However, pairing up is risky. Motorcycles should never be driven in groups. To navigate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#39130#39131#39132","text":"Never#When you're riding through sharp curves#When you're approaching an intersection#When you're riding at night"},
{"testId":487,"qid":20396,"description":"Downshifting is advised if you:","explanation":"When you need to slow down or halt, or if you need more power to accelerate, you should downshift.","imageName":"","correctAnswer":31101,"isBookmarked":0,"aid":"6181#6182#6183#31101","text":"are approaching a stop sign#need to slow down#need more power to accelerate#are in any of the above situations"},
{"testId":487,"qid":20404,"description":"A motorcycle has ______ lane locations in a single lane of traffic.","explanation":"In each lane of traffic, a motorbike has three lane positions: one (left), two (middle), and three (right) (right).","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":487,"qid":20435,"description":"Is sharing a lane with another motorbike safe?","explanation":"The use of two motorbikes in a single lane is not prohibited under Utah law. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"","correctAnswer":24946,"isBookmarked":0,"aid":"6259#10888#24946#24947","text":"Yes, because motorcycles are smaller than cars#No, because it is illegal to share a lane with another vehicle#No, because sharing a lane will not leave you enough room to maneuver#Yes, because you will be able to communicate with each other better"},
{"testId":300,"qid":20491,"description":"When you ride at night, you should:","explanation":"Riding at night requires you to increase your following distance and ride slower than during the day. This will allow you to detect threats earlier and give you more time to respond to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"10559#10560#24714#32738","text":"not use your headlight#wear a tinted face shield#ride slower than you do during the day#stay close behind the vehicle in front of you"},
{"testId":503,"qid":20491,"description":"When you ride at night, you should:","explanation":"Riding at night requires you to increase your following distance and ride slower than during the day. This will allow you to detect threats earlier and give you more time to respond to the unexpected.","imageName":"","correctAnswer":24714,"isBookmarked":0,"aid":"10559#10560#24714#32738","text":"not use your headlight#wear a tinted face shield#ride slower than you do during the day#stay close behind the vehicle in front of you"},
{"testId":495,"qid":20564,"description":"When biking in a group, the safest arrangement is:","explanation":"When riding in a group, the staggered arrangement is the optimum. It enables you to be close together while preserving some breathing room.","imageName":"","correctAnswer":6737,"isBookmarked":0,"aid":"6736#6737#7111#10732","text":"single file#the staggered formation#in pairs#the V-formation"},
{"testId":491,"qid":20645,"description":"What causes an engine to lock up?","explanation":"If an engine \"locks up\" or \"freezes,\" it is most likely low on oil. Before embarking on a trip, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#23024#23025#30038","text":"Being low on oil#The front tire skidding#Braking with only one brake#Shifting gears improperly"},
{"testId":503,"qid":20741,"description":"If your front tire blows out while riding, you should:","explanation":"If your front tire blows out while riding, transfer your weight to the rear to assist maintain stability.","imageName":"","correctAnswer":7717,"isBookmarked":0,"aid":"7700#7715#7716#7717","text":"avoid using the rear brake#shift your weight to the front to help maintain stability#use engine braking to slow down#shift your weight to the rear to help maintain stability"},
{"testId":495,"qid":20801,"description":"Put your right wrist _____ to start your bike.","explanation":"To prevent using too much throttle, start your motorbike with your right wrist down.","imageName":"","correctAnswer":6526,"isBookmarked":0,"aid":"6525#6526#6527#6528","text":"up#down#straight#locked"},
{"testId":495,"qid":20805,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated by someone who is unable to pass you, increase your following distance to provide both of you ample space to stop if you come across a danger.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6392#6920#6921","text":"pull over and stop#Call the police#Increase your following distance#flash your brake lights"},
{"testId":495,"qid":20810,"description":"When parking on the side of the road, you should park :","explanation":"Place your motorbike at an angle with the back wheel towards the curb. When you depart the parking area, you will be able to see in both directions effortlessly. A good vision is especially vital if you want to turn across a traffic lane.","imageName":"","correctAnswer":11017,"isBookmarked":0,"aid":"11015#11016#11017#31997","text":"on the crosswalk#on the sidewalk#at an angle#at a place reserved for persons with disabilities"},
{"testId":495,"qid":20822,"description":"Using a motorbike to navigate between traffic lanes:","explanation":"A motorbike need the whole width of the lane to move safely. Even if there seems to be space, a motorbike should never be driven between lanes of traffic. Riding between traffic lanes (also known as lane splitting) is currently permitted only in California.","imageName":"","correctAnswer":39138,"isBookmarked":0,"aid":"39138#39141#41329#41330","text":"is never allowed#is allowed only in designated areas#is allowed only when traffic is at a standstill#is prohibited only at night"},
{"testId":495,"qid":20824,"description":"What is an adequate space cushion between yourself and the vehicle ahead when riding in heavy, crowded traffic?","explanation":"In typical riding scenarios, the Virginia Motorcycle Operator Manual recommends a three-second following distance. Increase your following distance to four or more seconds in risky conditions or when your motorbike may take longer to stop.","imageName":"","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10880#10881#10882","text":"Four seconds#Three seconds#Two seconds#One second"},
{"testId":495,"qid":20834,"description":"A passenger must have his or her own _____ to ride your motorcycle.","explanation":null,"imageName":"","correctAnswer":32714,"isBookmarked":0,"aid":"8333#8436#32713#32714","text":"helmet#seat#footpegs#footpegs, seat, and helmet"},
{"testId":495,"qid":20835,"description":"When changing lanes on a multilane highway, check the lane next to you and:","explanation":"When changing lanes on a multilane road, always sure to scan both the distant lane and the lane next to you. There might be cars attempting to join into the same lane as you.","imageName":"","correctAnswer":8326,"isBookmarked":0,"aid":"8326#8451#8452#8453","text":"the far lane#behind you#in front of you#your throttle"},
{"testId":503,"qid":20950,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake accounts for around 70% of your motorcycle's stopping force.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":499,"qid":20996,"description":"Which of the following should be standard equipment on a street-legal motorcycle?","explanation":"A street-legal motorcycle in Washington State must have all of the standard controls, such as a headlight, taillight, brake light, turn signals, horn, two mirrors, and front and rear brakes.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6063#10983#24749","text":"All of the above#Front and rear brakes#Horn and two mirrors#Headlight, taillight, brake light, and turn signals"},
{"testId":499,"qid":20997,"description":"You should maintain a _____ following distance behind the vehicle in front of you.","explanation":"Maintain a following distance of at least three seconds behind the car in front of you. When riding in bad or dangerous circumstances, you should increase your following distance even further.","imageName":"","correctAnswer":4096,"isBookmarked":0,"aid":"1286#1287#1288#4096","text":"one second#two seconds#four seconds#three seconds"},
{"testId":499,"qid":21001,"description":"If you're about to hit something and want the bike to lean right, you should:","explanation":"By pressing on the right handgrip, the motorbike will lean right, causing it to turn right. Consider attending a training course if you don't know how to employ countersteering.","imageName":"","correctAnswer":7390,"isBookmarked":0,"aid":"7390#7391#22940#22942","text":"press on the right handgrip#press on the left handgrip#pull the left handgrip toward you#pull the right handgrip toward you"},
{"testId":499,"qid":21012,"description":"How will having a passenger affect how you handle your motorcycle?","explanation":"The additional weight of a passenger may have a considerable impact on the handling of your motorbike. It may take your motorbike longer to accelerate and stop. At stops, it may be more difficult to keep the motorbike stable and upright. In bends and low-speed maneuvers, it may respond differently.","imageName":"","correctAnswer":30843,"isBookmarked":0,"aid":"30843#41481#41482#41483","text":"All of the above may happen#Your motorcycle may take longer to accelerate and longer to stop#Your motorcycle may be harder to hold steady and upright at stops#Your motorcycle may behave differently in corners and low-speed maneuvers"},
{"testId":499,"qid":21013,"description":"To aid in balance, your feet should:","explanation":"When riding a motorbike, keep your feet firmly planted on the foot pegs. If you point them downward, they could get caught on something.","imageName":"","correctAnswer":24933,"isBookmarked":0,"aid":"7123#7124#24933#35323","text":"be against the gas tank#skim the ground during turns#be placed firmly on the foot pegs#point downward toward the road"},
{"testId":499,"qid":21018,"description":"Which of the following is NOT necessary for a motorcycle passenger in Washington State?","explanation":"Under Washington State law, to carry a passenger, your motorcycle must have foot pegs and seat room for the person. The passenger must also wear a DOT-compliant helmet. However, he or she is not required to wear eye protection.","imageName":"","correctAnswer":41484,"isBookmarked":0,"aid":"7115#11031#23795#41484","text":"A helmet#Designated seat room#Passenger foot pegs#Eye protection"},
{"testId":499,"qid":21024,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, marijuana, and, of course, alcohol all have the potential to impair your ability to ride a motorbike.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":499,"qid":21030,"description":"You should ride _______ around curves when riding with a passenger.","explanation":"The additional weight of a passenger causes your motorbike to react slowly. As a result, you should ride more slowly, particularly when approaching bumps, bends, or curves.","imageName":"","correctAnswer":8245,"isBookmarked":0,"aid":"8243#8245#28380#28381","text":"faster#slower#at your usual speed#at the speed limit"},
{"testId":499,"qid":21042,"description":"When can you go in Washington State if you're stopped at a red light and feel your motorcycle hasn't activated the light sensor?","explanation":null,"imageName":"","correctAnswer":39508,"isBookmarked":0,"aid":"37194#38458#39508#39509","text":"(c) After waiting for two light cycles#(a) After waiting for 120 seconds#(b) After waiting for one light cycle#Both (a) and (c)"},
{"testId":499,"qid":21044,"description":"When is it permissible to ride a motorcycle between two lanes of traffic?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#7722#7723#7724","text":"Never#During rush hour#Whenever it is safe to do so#Only in designated areas"},
{"testId":503,"qid":21196,"description":"Don't share your lane with cars or other vehicles when riding in traffic because:","explanation":"When riding in traffic, do not share your lane with automobiles or other vehicles since cars and motorbikes need the whole width of the lane to function securely.","imageName":"","correctAnswer":41590,"isBookmarked":0,"aid":"26423#30026#40642#41590","text":"most of the roads are narrow#you might face a sudden wind blast#drivers might not allow you to share the same lane#cars and motorcycles need a full lane width to operate safely"},
{"testId":507,"qid":21196,"description":"Don't share your lane with cars or other vehicles when riding in traffic because:","explanation":"When riding in traffic, do not share your lane with automobiles or other vehicles since cars and motorbikes need the whole width of the lane to function securely.","imageName":"","correctAnswer":41590,"isBookmarked":0,"aid":"26423#30026#40642#41590","text":"most of the roads are narrow#you might face a sudden wind blast#drivers might not allow you to share the same lane#cars and motorcycles need a full lane width to operate safely"},
{"testId":503,"qid":21211,"description":"To transport a passenger, your motorbike must have:","explanation":null,"imageName":"","correctAnswer":7205,"isBookmarked":0,"aid":"7204#7205#7206#22946","text":"A windshield#footrests for the passenger#side saddlebags#high-quality shocks"},
{"testId":503,"qid":21213,"description":"Check the coolant and brake hydraulic fluid levels _______.","explanation":"The West Virginia Motorcycle Operator Manual suggests monitoring the coolant and brake hydraulic fluid levels at least once a week.","imageName":"","correctAnswer":7358,"isBookmarked":0,"aid":"7358#7359#7360#7361","text":"weekly#daily#before each ride#once a year"},
{"testId":503,"qid":21229,"description":"When is it permissible in West Virginia to share a lane with another vehicle?","explanation":"Sharing a lane with another motorbike is not illegal in West Virginia. A staggered pattern, on the other hand, is a safer bet. There should be no other cars in your lane. To operate properly, each motorbike needs the whole lane width. Each motorbike may need the whole width of the lane to move in order to avoid a danger.","imageName":"","correctAnswer":35515,"isBookmarked":0,"aid":"6334#6769#8087#35515","text":"Never#In heavy traffic#In designated areas#If the other vehicle is a motorcycle"},
{"testId":507,"qid":21401,"description":"When your bike is parked next to a curb, it should be:","explanation":"When parked beside the road, your motorbike should be at a 90-degree angle with the back wheel towards the curb.","imageName":"","correctAnswer":30027,"isBookmarked":0,"aid":"74#28985#30027#34557","text":"parallel to the curb#lying on the curb#positioned at an angle with the rear wheel to the curb#positioned at an angle with the front wheel to the curb"},
{"testId":507,"qid":21419,"description":"When riding as a group, you should NEVER:","explanation":"Although it is legal to pair up in Wisconsin, it is still a poor idea. To operate properly, each motorbike needs the whole lane width.","imageName":"","correctAnswer":7504,"isBookmarked":0,"aid":"7503#7504#7505#7506","text":"ride single file#pair up#put a beginner near the front#pass vehicles in front of you"},
{"testId":507,"qid":21443,"description":"In Wisconsin, if your motorcycle has an approved windshield,:","explanation":null,"imageName":"","correctAnswer":41042,"isBookmarked":0,"aid":"41040#41041#41042#41043","text":"you鈥檙e required to wear eye protection#you're not required to wear a helmet#you're not required to wear eye protection#your passenger is not required to wear a helmet"},
{"testId":507,"qid":21444,"description":"You've come to a halt at a red traffic signal whose light sensor has not been activated by your motorcycle. At this intersection, there are no pedestrians, cyclists, or other vehicles. When is it lawful to proceed through a red light?","explanation":null,"imageName":"","correctAnswer":43802,"isBookmarked":0,"aid":"40958#41108#43801#43802","text":"Either (a) or (b)#Immediately#(a) After you've waited for one full light cycle#(b) After you've waited for 45 seconds"},
{"testId":511,"qid":21596,"description":"If you're over 21 and convicted of DUI, you may face which penalities?","explanation":"Your license will be suspended if you are convicted of driving under the influence of alcohol or drugs. Even your first infraction is considered a misdemeanor in Wyoming. As a result, you may be required to pay a fine and spend time in prison.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23073#26643#35315","text":"All of the above#Mandatory license suspension#A fine#A jail sentence"},
{"testId":511,"qid":21603,"description":"Which of the following should you avoid when transporting cargo?","explanation":"Because it elevates the motorcycle's center of gravity, attaching goods to the sissy bar might make it unstable. To ensure safety, keep cargo low and balanced.","imageName":"","correctAnswer":22289,"isBookmarked":0,"aid":"22289#22290#22291#22292","text":"Fastening excess cargo to the sissy bar#Securing cargo as low as possible#Filling saddlebags evenly#Securing cargo with elastic cords"},
{"testId":511,"qid":21605,"description":"When should you remove your feet from the foot pegs and skim them across the road?","explanation":"Skim the road surface with your feet to preserve balance and safety while cycling on ice. You may use your feet to keep your motorbike from slipping.","imageName":"","correctAnswer":6556,"isBookmarked":0,"aid":"6556#6557#10786#22968","text":"When riding on ice#When riding in a parking lot#When crossing railroad tracks#When riding on a metal grating"},
{"testId":511,"qid":21606,"description":"When another vehicle goes by you, you need to be on the lookout for:","explanation":"When passing another car, keep an eye out for extended side mirrors, debris flung from windows, and wind blasts (especially from a larger vehicle).","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6634#6635#8615#31443","text":"extended side mirrors#blasts of wind#All of the above#objects being thrown from windows"},
{"testId":511,"qid":21607,"description":"At a stop sign, your motorcycle should:","explanation":"When you come to a complete stop at a stop sign, keep your motorbike in first gear so you can simply restart.","imageName":"","correctAnswer":6752,"isBookmarked":0,"aid":"6749#6750#6751#6752","text":"remain in neutral#remain in second gear#remain in third gear#remain in first gear"},
{"testId":511,"qid":21608,"description":"It is unsafe to ride beside a line of parked automobiles because:","explanation":"Riding beside a line of parked automobiles is unsafe. Someone inside a vehicle may fail to see you and open his or her door directly into your path.","imageName":"","correctAnswer":24680,"isBookmarked":0,"aid":"6785#6786#24680#24681","text":"it decreases visibility#traction is poor beside parked cars#someone may open his or her door into your path#you may collide with a car"},
{"testId":511,"qid":21612,"description":"If a tailgater is following you and he or she is unable to pass you, you should:","explanation":"If you are being tailgated and the tailgater is unable to pass you, increase your following distance so that both of you have ample area to stop if a danger appears.","imageName":"202006041812161292.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6280#6286#6392#6920","text":"pull over and stop#Flash your brake light#Call the police#Increase your following distance"},
{"testId":511,"qid":21615,"description":"More than one motorcycle should ride together:","explanation":"Two or more motorcycles travelling in the same lane together should ride in a staggered configuration. This shape enables the group to keep close together while also providing some breathing room. Pairing up is not illegal in Wyoming, but it is risky. Each motorcycle may need the whole width of the lane to navigate.","imageName":"","correctAnswer":7198,"isBookmarked":0,"aid":"6736#7111#7198#10635","text":"single file#in pairs#in a staggered formation#in a V-formation"},
{"testId":511,"qid":21616,"description":"Which of the following locations has the highest incidence of motorcycle collisions?","explanation":"Motorcycle accidents are particularly common near junctions. Often a vehicle driver turning left fails to detect an incoming motorbike.","imageName":"","correctAnswer":1691,"isBookmarked":0,"aid":"1691#3922#7215#24691","text":"Intersections#Acceleration lanes#Rural areas#Multilane highways"},
{"testId":511,"qid":21617,"description":"Riding a motorcycle while wearing a face shield:","explanation":"A face shield is the greatest kind of face and eye protection since it covers your whole face and will not blow away in the wind. Even though Wyoming law does not demand it, you should consider wearing a face shield.","imageName":"","correctAnswer":7280,"isBookmarked":0,"aid":"7280#7282#28971#30524","text":"is the best form of face and eye protection#is not as effective as wearing goggles#is only required of riders under the age of 18#is legally required of all riders"},
{"testId":511,"qid":21623,"description":"When you first get on your motorcycle, maintain your right wrist :","explanation":"To maintain a good hold over uneven terrain, grasp the handgrips firmly. Begin by keeping your right wrist flat. This will assist you avoid mistakenly applying too much throttle.","imageName":"","correctAnswer":7896,"isBookmarked":0,"aid":"6525#6526#6528#7896","text":"up#down#locked#flat"},
{"testId":511,"qid":21625,"description":"When overtaking parked cars on your right, which lane should you ride in?","explanation":"Ride in the left lane while passing parked automobiles on your right to prevent complications caused by doors opening, drivers getting out of cars, or pedestrians stepping from between cars.","imageName":"","correctAnswer":6214,"isBookmarked":0,"aid":"6214#6216#6217#31485","text":"Left#Center#Right#Right or center"},
{"testId":511,"qid":21626,"description":"When is it safe to share a lane with another vehicle?","explanation":"Lane sharing is typically illegal, however Wyoming law allows you to share a lane with another motorcycle provided both of you agree. Still, sharing a lane with another motorbike or any other motor vehicle is never a good idea. To operate properly, each motorbike needs the whole lane width. You may not be able to move adequately to escape a danger if you share your lane.","imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#6769#8086#8087","text":"Never#In heavy traffic#When passing another vehicle#In designated areas"},
{"testId":511,"qid":21634,"description":"The vast majority of motorcycle accidents occur.","explanation":"The majority of motorcycle accidents happen at speeds less than 35 mph. Ride with care at all times.","imageName":"","correctAnswer":22857,"isBookmarked":0,"aid":"6644#6679#6681#22857","text":"on hills#at high speeds#in curves#at low speeds"},
{"testId":511,"qid":21637,"description":"When is it OK to utilize both the front and rear brakes?","explanation":"Unless a tire is flat, you should always utilize both the front and rear brakes while stopping. This is the most efficient form of braking.","imageName":"","correctAnswer":30126,"isBookmarked":0,"aid":"30126#35318#35319#35320","text":"Every time you stop#Only in normal stops#In emergencies#Whenever you swerve"},
{"testId":511,"qid":21638,"description":"At a junction, if you make eye contact with a driver,:","explanation":"Making eye contact does not ensure that drivers will notice you. Even when drivers are gazing straight towards motorcycles, they often fail to spot them. At crossroads, always proceed with care.","imageName":"","correctAnswer":10384,"isBookmarked":0,"aid":"10384#22785#24651#35321","text":"it does not mean the driver has seen you#you must yield the right-of-way#it means the driver will yield the right-of-way#it suggests that this person is an aggressive driver"},
{"testId":511,"qid":21641,"description":"When you're going around a curve, the best place to be in your lane is:","explanation":"When riding a curve, the best lane position will alter during the curve. Take advantage of lane positioning.","imageName":"","correctAnswer":7091,"isBookmarked":0,"aid":"7091#7092#7093#7568","text":"will change throughout the curve#is any lane position as long as you maintain it#is the right lane position#is the center lane position"},
{"testId":511,"qid":21644,"description":"To discourage others from sharing your lane, ride in the _____ lane.","explanation":"You should ride in the middle lane to dissuade other cars from seeking to share your lane. It gives you the most space on each side of you.","imageName":"","correctAnswer":120,"isBookmarked":0,"aid":"120#121#122#7761","text":"Center#Right#Left#right or center"},
{"testId":52,"qid":22971,"description":"You should _________ if one of your tires goes flat while you are riding.","explanation":"If one of your tires goes flat while riding, hold the handgrips firmly, ease off the throttle, and maintain a straight path while your motorbike slows down. If you must brake, just apply the brake on the good tire.","imageName":"","correctAnswer":22866,"isBookmarked":0,"aid":"6515#11159#22866#34818","text":"Increase your speed#change your direction#hold the handgrips firmly#brake quickly"},
{"testId":298,"qid":22975,"description":"If you slow down unexpectedly, you should:","explanation":"If you're ready to slow down in an area where others may not anticipate it, tap your brake, which will flash your brake light to alert them.","imageName":"","correctAnswer":6286,"isBookmarked":0,"aid":"27#6278#6286#6409","text":"Change lanes#Beep your horn#Flash your brake light#shoulder check your blind spot"},
{"testId":58,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":336,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":351,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":363,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":367,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":392,"qid":22979,"description":"When doing a ________ turn, your motorcycle should be leaning while your body should be straight.","explanation":"During slow, tight bends, tilt your motorbike while maintaining your torso straight.","imageName":"","correctAnswer":6614,"isBookmarked":0,"aid":"6611#6612#6613#6614","text":"fast, tight#slow, wide#fast, wide#slow, tight"},
{"testId":59,"qid":22981,"description":"If you have a flat tire and you need to brake, you should:","explanation":"If you need to brake while driving with a flat tire, use the brake on the non-flat tire. Never use your brakes on a flat tire.","imageName":"","correctAnswer":6740,"isBookmarked":0,"aid":"6740#6742#6743#33586","text":"apply the brake of the tire that is not flat#apply the brake of the tire that is flat#never brake when you have a flat tire#apply the front and rear brakes equally"},
{"testId":475,"qid":22981,"description":"If you have a flat tire and you need to brake, you should:","explanation":"If you need to brake while driving with a flat tire, use the brake on the non-flat tire. Never use your brakes on a flat tire.","imageName":"","correctAnswer":6740,"isBookmarked":0,"aid":"6740#6742#6743#33586","text":"apply the brake of the tire that is not flat#apply the brake of the tire that is flat#never brake when you have a flat tire#apply the front and rear brakes equally"},
{"testId":314,"qid":22982,"description":"You should _____ to assist preserve balance during a turn:","explanation":"Roll on the throttle to maintain or slightly increase your speed to assist keep your balance in a turn. At lower speeds, motorcycles are less stable.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6758#7067#7068#7069","text":"maintain or increase your speed#decrease or maintain your speed#drag your inside foot for support#drag your outside foot for support"},
{"testId":446,"qid":22983,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":450,"qid":22983,"description":"In which of the following scenarios would the engine cut-off switch be used?","explanation":"If the throttle is locked and you cannot remove it by twisting it back and forth, you should use the engine cut-off switch.","imageName":"","correctAnswer":35119,"isBookmarked":0,"aid":"7163#10574#34538#35119","text":"When you need to stop quickly#When you need to avoid slipping on slippery surfaces#If you're being tailgated#If your throttle is stuck and you can't free it"},
{"testId":458,"qid":22984,"description":"You should________________ while riding a motorcycle because it is more exhausting than driving a car.","explanation":"Taking a rest break every two hours is the greatest approach to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7216,"isBookmarked":0,"aid":"7216#7217#7218#7219","text":"take frequent breaks#drink caffeinated beverages#avoid drinking water#avoid wearing dark eyewear"},
{"testId":487,"qid":22984,"description":"You should________________ while riding a motorcycle because it is more exhausting than driving a car.","explanation":"Taking a rest break every two hours is the greatest approach to keep aware when riding a motorbike. Riding a motorbike is more physically demanding than driving a vehicle. Take some time to unwind and stretch.","imageName":"","correctAnswer":7216,"isBookmarked":0,"aid":"7216#7217#7218#7219","text":"take frequent breaks#drink caffeinated beverages#avoid drinking water#avoid wearing dark eyewear"},
{"testId":383,"qid":22985,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before embarking on a trip, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#23024#23025#30038","text":"Being low on oil#The front tire skidding#Braking with only one brake#Shifting gears improperly"},
{"testId":458,"qid":22985,"description":"What causes an engine to lock up?","explanation":"If an engine locks up, it is most likely low on oil. Before embarking on a trip, always check your oil. If necessary, add oil as soon as possible or the engine will seize.","imageName":"","correctAnswer":7952,"isBookmarked":0,"aid":"7952#23024#23025#30038","text":"Being low on oil#The front tire skidding#Braking with only one brake#Shifting gears improperly"},
{"testId":83,"qid":22991,"description":"When is the middle lane the best choice?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":31467,"isBookmarked":0,"aid":"8033#8346#31467#31468","text":"When you are preparing to pass#When there is a hazard on the left side of the road#When you want to be most visible to the driver of the vehicle ahead of you#When you are riding beside a line of parked cars"},
{"testId":58,"qid":22993,"description":"When riding at night, which of the following does NOT make you safer?","explanation":"Driving at night does not boost your safety. It is risky and should be approached with care.","imageName":"","correctAnswer":10470,"isBookmarked":0,"aid":"6666#10470#10804#22837","text":"Changing your lane position#Passing slower vehicles#Wearing reflective clothing#Reducing your speed"},
{"testId":296,"qid":22994,"description":"What should you do to safely stop in a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten your motorbike as much as possible. This is the most secure technique to come to a halt. Stopping while leaning might be risky.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"8297#28382#30098#40973","text":"Keep your knees against the gas tank#Take your feet off the footrests for balance#Try to straighten your motorcycle upright#Lean the opposite way of the motorcycle"},
{"testId":458,"qid":22999,"description":"Which lane position should you ride in if another car passes you on the left?","explanation":"When another car passes you on the left, ride in the middle lane to avoid the danger. Riding in the correct lane may encourage the driver of that car to return to your lane too quickly. In addition, if you were in the right lane, you would have less room for error on your right side if you were struck by a blast of wind from a passing car, which would impair your control.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":503,"qid":23001,"description":"You must cross railroad tracks that are parallel to your path at a minimum _____-degree angle.","explanation":"If you must cross railroad lines that run parallel to your route, do it at a 45-degree angle to prevent snagging your tires on the rails.","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5448#6354#6355#6356","text":"20#45#90#120"},
{"testId":56,"qid":23019,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":293,"qid":23019,"description":"Motorcycles need _______ lane width to operate safely.","explanation":"Despite being smaller than vehicles, motorcyclists need the whole lane to function properly.","imageName":"","correctAnswer":6471,"isBookmarked":0,"aid":"6471#6472#6474#6922","text":"a full#a third of a#a quarter of a#half of a"},
{"testId":56,"qid":23022,"description":"The ideal tactic when someone is moving into your lane from an entry ramp is to:","explanation":"When someone enters your lane from an entry ramp, the ideal tactic is to shift lanes so that the vehicle has enough space to join. If there is no place for a lane change, slow down to provide way for the merging motorist.","imageName":"","correctAnswer":35355,"isBookmarked":0,"aid":"6278#6475#6476#35355","text":"Beep your horn#Speed up#Slow down#change lanes if one is open"},
{"testId":296,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":298,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":458,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":471,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":495,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":503,"qid":23031,"description":"What should you avoid when turning a motorcycle?","explanation":"At lower speeds, motorcycles are less stable. Deceleration should be avoided while turning a motorbike. Roll on the throttle to maintain or slightly increase your speed to stabilize the motorbike.","imageName":"","correctAnswer":7676,"isBookmarked":0,"aid":"7673#7674#7675#7676","text":"Accelerating#Leaning with the motorcycle#Turning your whole head to see where you are going#Decelerating"},
{"testId":491,"qid":23032,"description":"Studies show that most crashes are caused by riders who:","explanation":"According to studies, most riders involved in incidents underbraked the front tire and overbraked the rear tire, did not differentiate braking from swerving, or did not swerve when necessary.","imageName":"","correctAnswer":23079,"isBookmarked":0,"aid":"23077#23079#23080#30008","text":"underbraked the front tire and overbraked the rear#lacked one of the above skills#lacked none of the above skills#did not separate braking from swerving or did not swerve when it was appropriate to do so"},
{"testId":77,"qid":23036,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To keep the motorbike balanced, make sure the baggage is appropriately distributed.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":388,"qid":23036,"description":"What will be the cause of the motorcycle wobble?","explanation":"Improper cargo loading might cause your motorbike to wobble. To keep the motorbike balanced, make sure the baggage is appropriately distributed.","imageName":"","correctAnswer":8078,"isBookmarked":0,"aid":"8076#8077#8078#8079","text":"Improper braking technique#Carrying a passenger#Improper loading of cargo#Riding a motorcycle that is too big"},
{"testId":483,"qid":23037,"description":"How can you maximize straight-line braking?","explanation":"Maximum straight-line braking is achieved by completely applying both the front and back brakes without locking any wheel.","imageName":"","correctAnswer":30525,"isBookmarked":0,"aid":"22822#22930#28897#30525","text":"Apply only the rear brake#Apply the front brake quickly#Alternate between the front brake and the rear brake#Fully apply both the front and rear brakes"},
{"testId":438,"qid":23055,"description":"When evaluating potential hazards, you should be considering:","explanation":"Consider road features, other vehicles, and traffic management while assessing possible dangers. They may all contribute to many dangerous circumstances.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"6929#6930#6931#8615","text":"road characteristics#other vehicles#traffic controls#All of the above"},
{"testId":330,"qid":23056,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to allow yourself more time to respond.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":487,"qid":23056,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to allow yourself more time to respond.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":495,"qid":23056,"description":"You can ride with more safety during night by:","explanation":"Reduce your speed and increase your following distance at night to allow yourself more time to respond.","imageName":"","correctAnswer":7028,"isBookmarked":0,"aid":"7028#7029#7030#7031","text":"reducing your speed and increasing your following distance#reducing your speed and decreasing your following distance#increasing your speed and increasing your following distance#increasing your speed and decreasing your following distance"},
{"testId":487,"qid":23064,"description":"In a turn, you can help stabilize your motorcycle by:","explanation":"Rolling on the throttle to maintain or slightly increase your speed can assist steady your motorbike in a turn.","imageName":"","correctAnswer":8280,"isBookmarked":0,"aid":"8277#8278#8279#8280","text":"decreasing the throttle#pulling in the clutch#applying the rear brake#increasing the throttle"},
{"testId":84,"qid":23066,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could become tangled up in something while you're riding.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":458,"qid":23066,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could become tangled up in something while you're riding.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":471,"qid":23066,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could become tangled up in something while you're riding.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":491,"qid":23066,"description":"You should avoid______ while your feet are on the foot pegs.","explanation":"You should avoid pointing your feet downward when they are on the footrests. They could become tangled up in something while you're riding.","imageName":"","correctAnswer":10853,"isBookmarked":0,"aid":"8392#8393#10853#31125","text":"turning corners#riding on slippery surfaces#pointing your feet downward#using the front brake"},
{"testId":66,"qid":23078,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#23083#23084","text":"Slow down before reaching the slippery section#Stop and turn around#Use only your rear brake#Stop your bike and walk it across the slippery section"},
{"testId":336,"qid":23078,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#23083#23084","text":"Slow down before reaching the slippery section#Stop and turn around#Use only your rear brake#Stop your bike and walk it across the slippery section"},
{"testId":339,"qid":23078,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#23083#23084","text":"Slow down before reaching the slippery section#Stop and turn around#Use only your rear brake#Stop your bike and walk it across the slippery section"},
{"testId":383,"qid":23078,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#23083#23084","text":"Slow down before reaching the slippery section#Stop and turn around#Use only your rear brake#Stop your bike and walk it across the slippery section"},
{"testId":412,"qid":23078,"description":"What should you do if you feel the road ahead is slick?","explanation":"If you believe the road ahead is slick, slow down as much as possible before reaching the slick spot.","imageName":"","correctAnswer":10885,"isBookmarked":0,"aid":"10885#10886#23083#23084","text":"Slow down before reaching the slippery section#Stop and turn around#Use only your rear brake#Stop your bike and walk it across the slippery section"},
{"testId":67,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":339,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":359,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":434,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":438,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":450,"qid":23079,"description":"What style of gear should you wear when riding a motorcycle in hot weather?","explanation":"Even in hot weather, you should wear robust clothing that fully covers your arms and legs. In the case of a collision, your skin must be covered.","imageName":"","correctAnswer":7284,"isBookmarked":0,"aid":"7283#7284#23085#23086","text":"Denim shorts and a leather jacket#Sturdy clothing that covers your arms and legs completely#Long pants and a T-shirt#Whatever feels most comfortable"},
{"testId":379,"qid":23085,"description":"While loading your motorcycle, heavier stuff should go:","explanation":"Heavy objects should be put at the front of the tank bag while packing your motorbike. This helps to keep the motorbike balanced.","imageName":"","correctAnswer":11157,"isBookmarked":0,"aid":"7418#7941#7944#11157","text":"on the sissy bar#on the handlebars#in a backpack#in the front of the tank bag"},
{"testId":300,"qid":23131,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Ride with your headlights on and in a lane position that allows you to see incoming traffic. Never rely on eye contact to indicate that a motorist will yield. Even when drivers are gazing straight towards motorcycles, they often fail to spot them.","imageName":"","correctAnswer":34551,"isBookmarked":0,"aid":"34548#34549#34550#34551","text":"You can count on eye contact as a sign that a driver will yield#You should ride with your headlight off#You should increase your speed as you approach the intersection#You should ride in a lane position that will most increase your visibility to other road users"},
{"testId":434,"qid":23131,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Ride with your headlights on and in a lane position that allows you to see incoming traffic. Never rely on eye contact to indicate that a motorist will yield. Even when drivers are gazing straight towards motorcycles, they often fail to spot them.","imageName":"","correctAnswer":34551,"isBookmarked":0,"aid":"34548#34549#34550#34551","text":"You can count on eye contact as a sign that a driver will yield#You should ride with your headlight off#You should increase your speed as you approach the intersection#You should ride in a lane position that will most increase your visibility to other road users"},
{"testId":438,"qid":23131,"description":"Which of the following statements regarding crossing a street is correct?","explanation":"As you approach an intersection, slow down. Increase your visibility at the junction. Ride with your headlights on and in a lane position that allows you to see incoming traffic. Never rely on eye contact to indicate that a motorist will yield. Even when drivers are gazing straight towards motorcycles, they often fail to spot them.","imageName":"","correctAnswer":34551,"isBookmarked":0,"aid":"34548#34549#34550#34551","text":"You can count on eye contact as a sign that a driver will yield#You should ride with your headlight off#You should increase your speed as you approach the intersection#You should ride in a lane position that will most increase your visibility to other road users"},
{"testId":462,"qid":23135,"description":"It is dangerous for motorcycle riders  to travel:","explanation":"Intersections may be dangerous for motorcycle riders since many automobile operators are distracted and may not detect the motorbike.","imageName":"","correctAnswer":6645,"isBookmarked":0,"aid":"6645#10847#10848#10849","text":"At intersections#On freeways#In school zones#On rural roads"},
{"testId":495,"qid":23135,"description":"It is dangerous for motorcycle riders  to travel:","explanation":"Intersections may be dangerous for motorcycle riders since many automobile operators are distracted and may not detect the motorbike.","imageName":"","correctAnswer":6645,"isBookmarked":0,"aid":"6645#10847#10848#10849","text":"At intersections#On freeways#In school zones#On rural roads"},
{"testId":343,"qid":23138,"description":"While riding, you see hazards on the left side of the lane. What lane position should you take?","explanation":"If there are risks to your left, pick a center or right lane position to avoid them.","imageName":"","correctAnswer":7097,"isBookmarked":0,"aid":"6214#7097#11010#24809","text":"Left#Center or right#Whichever lane position you generally prefer#Whichever lane position you are currently in"},
{"testId":462,"qid":23138,"description":"While riding, you see hazards on the left side of the lane. What lane position should you take?","explanation":"If there are risks to your left, pick a center or right lane position to avoid them.","imageName":"","correctAnswer":7097,"isBookmarked":0,"aid":"6214#7097#11010#24809","text":"Left#Center or right#Whichever lane position you generally prefer#Whichever lane position you are currently in"},
{"testId":82,"qid":23153,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":339,"qid":23153,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":429,"qid":23153,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":471,"qid":23153,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":499,"qid":23153,"description":"Should a motorbike rider lean while making a typical turn?","explanation":"The rider should lean with the motorbike during a conventional turn. This aids in maintaining equilibrium.","imageName":"","correctAnswer":22895,"isBookmarked":0,"aid":"22893#22895#22896#26498","text":"No, the rider should not lean#Yes, the rider should lean with the motorcycle#Yes, the rider should lean forward#Yes, the rider should lean in the opposite direction of the motorcycle"},
{"testId":84,"qid":23166,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":479,"qid":23166,"description":"You're turning right onto a road with one traffic lane in either way. Taking a right turn too quickly could cause you to:","explanation":"If you ride too rapidly through a turn, you may swerve into another lane. Before entering the turn, slow down.","imageName":"","correctAnswer":24670,"isBookmarked":0,"aid":"8397#8398#23050#24670","text":"hit the vehicle in front of you#run off the right edge of the road#tip over your motorcycle#veer into the opposing lane"},
{"testId":293,"qid":23167,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards. When riding through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":347,"qid":23167,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards. When riding through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":438,"qid":23167,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards. When riding through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":458,"qid":23167,"description":"Which one(s) of these is/are the most dangerous?","explanation":"Oncoming traffic turning left in front of you is one of the most dangerous hazards. When riding through junctions, use care.","imageName":"","correctAnswer":24816,"isBookmarked":0,"aid":"24814#24815#24816#24817","text":"Bicycles on the side of the road#Overpasses and tunnels#Oncoming traffic turning left in front of you#Motorcycle chain problems"},
{"testId":483,"qid":23169,"description":"Changing to a lower gear to slow down is known as :","explanation":"Shifting down a gear produces a braking action known as engine braking. Shift down one gear at a time, easing out the clutch through the friction zone between each downshift, to apply engine braking. Maintain the clutch in the friction zone until the engine speed is stable. Then, completely extend the lever until you are ready for the next downshift.","imageName":"","correctAnswer":23067,"isBookmarked":0,"aid":"8626#23067#23068#23069","text":"None of the above#engine braking#ABS braking#quick braking"},
{"testId":64,"qid":23179,"description":"Which braking method is the most secure?","explanation":"Using both the front and rear brakes at the same time is the safest and most effective stopping strategy.","imageName":"","correctAnswer":26569,"isBookmarked":0,"aid":"7059#7060#7061#26569","text":"Using only the front brake#Using only the rear brake#Alternating between using the front brake and the rear brake#Using both the front and rear brakes at the same time"},
{"testId":383,"qid":23227,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":31782,"isBookmarked":0,"aid":"6809#30821#31781#31782","text":"Pull off the road#Operate the engine cut-off switch#Apply both the front and rear brakes smoothly#Try to twist it back and forth"},
{"testId":404,"qid":23227,"description":"What should you do first if your throttle is stuck?","explanation":"If your throttle becomes locked, try twisting it back and forth to loosen it. If it doesn't work, turn off the engine and draw in the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":31782,"isBookmarked":0,"aid":"6809#30821#31781#31782","text":"Pull off the road#Operate the engine cut-off switch#Apply both the front and rear brakes smoothly#Try to twist it back and forth"},
{"testId":355,"qid":23248,"description":"When someone from an on-ramp is moving into your lane, you should:","explanation":"When someone merges into your lane from an on-ramp, you should shift lanes to provide the vehicle enough space to join.","imageName":"","correctAnswer":27,"isBookmarked":0,"aid":"27#6278#6475#6476","text":"Change lanes#Beep your horn#Speed up#Slow down"},
{"testId":63,"qid":23253,"description":"The middle lane location is optimal for:","explanation":"The middle lane is ideal for being seen to the car ahead of you. Drivers often check their rearview mirrors.","imageName":"","correctAnswer":6996,"isBookmarked":0,"aid":"6995#6996#6998#26433","text":"preparing to pass the car ahead of you#being seen by the driver ahead of you#pulling up to blind intersections#preparing to pull over to the side of the road"},
{"testId":363,"qid":23265,"description":"How can you improve your chances of being noticed?","explanation":"Wearing bright attire, picking the ideal lane location, and utilizing your headlight may all help you be noticed.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"6171#10784#10823#23000","text":"Use your headlight#Wear bright clothing#Choose the best lane position#Do all of the above"},
{"testId":363,"qid":23268,"description":"What can you do to improve your visibility at blind intersections?","explanation":"Move into a lane position that allows you to be spotted as soon as possible at blind crossings to increase your visibility.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#24901#24902#28445","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":375,"qid":23268,"description":"What can you do to improve your visibility at blind intersections?","explanation":"Move into a lane position that allows you to be spotted as soon as possible at blind crossings to increase your visibility.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#24901#24902#28445","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":429,"qid":23268,"description":"What can you do to improve your visibility at blind intersections?","explanation":"Move into a lane position that allows you to be spotted as soon as possible at blind crossings to increase your visibility.","imageName":"","correctAnswer":28445,"isBookmarked":0,"aid":"10718#24901#24902#28445","text":"Flash your brake light#Always ride in the center lane position#Stop beyond the stop line so you will be seen#Choose a lane position that lets you be seen as soon as possible"},
{"testId":355,"qid":23270,"description":"When passing a big, heavy vehicle, it's important to avoid:","explanation":"Large, heavier vehicles need greater stopping distances than motorbikes. If you abruptly slow down in front of a huge car, it may not have enough distance to avoid rear-ending you.","imageName":"","correctAnswer":10563,"isBookmarked":0,"aid":"10563#10565#10894#26488","text":"slowing down suddenly when you merge back in front of the vehicle#passing without a dedicated passing lane#flashing your headlight#beeping your horn when you are alongside the vehicle"},
{"testId":392,"qid":23300,"description":"What should you do if you come to a stop on a curve?","explanation":"Before coming to a halt on a curve, attempt to straighten the motorbike out. This is the most secure technique to come to a halt.","imageName":"","correctAnswer":29483,"isBookmarked":0,"aid":"10835#10836#22273#29483","text":"Lean over as much as possible#Increase your speed before stopping#Grab the rear brake only#Try to straighten the motorcycle upright"},
{"testId":400,"qid":23322,"description":"The optimal lane is:","explanation":"The ideal lane location is the one where you will be noticed the most. And it is dependent on the circumstances.","imageName":"","correctAnswer":30097,"isBookmarked":0,"aid":"7176#7178#10986#30097","text":"The center lane position#the left lane position#the right lane position#the lane position in which you are most likely to be seen"},
{"testId":371,"qid":23323,"description":"Your motorcycle's _____ supply the majority of its stopping force.","explanation":"The front brake provides 75 percent of your motorcycle's stopping force.","imageName":"","correctAnswer":6358,"isBookmarked":0,"aid":"6357#6358#6360#22245","text":"the rear brake#the front brake#the clutch#the front and rear brakes equally"},
{"testId":425,"qid":23341,"description":"What should you do if you have to stop while leaning in a curve?","explanation":"When coming to a halt on a curve, attempt to straighten the motorbike out. Stopping becomes perilous while leaning over.","imageName":"","correctAnswer":30098,"isBookmarked":0,"aid":"6196#10884#26482#30098","text":"Never stop in a curve#Use your rear brake only#Use your lean to enhance braking#Try to straighten your motorcycle upright"},
{"testId":371,"qid":23370,"description":"To avoid dangerous circumstances, you should:","explanation":"To prevent potentially dangerous circumstances, you should scan the road ahead for any risks and threats.","imageName":"","correctAnswer":10415,"isBookmarked":0,"aid":"8194#10415#10416#10417","text":"Avoid carrying passengers#search the road ahead#be able to swerve#watch other vehicles"},
{"testId":71,"qid":23389,"description":"What should you do if the throttle is stuck and turning it back and forth does not dislodge it?","explanation":"If the throttle is stuck and moving it back and forth does not release it, turn off the engine and engage the clutch at the same time. This will reduce power to the back wheel.","imageName":"","correctAnswer":22925,"isBookmarked":0,"aid":"22924#22925#22926#22927","text":"Stop the motorcycle using the rear brake only#Flip the engine cut-off switch and pull in the clutch at the same time#Jump off the motorcycle#Pull in the clutch"},
{"testId":70,"qid":23404,"description":"Motorcycle riding groups should not exceed____ riders.","explanation":"A group of motorcycle riders should not exceed four or five people. If the crowd is enormous, they should divide into smaller groups.","imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2753#5000#5002#10878","text":"Two#Six#Five#Seven"},
{"testId":388,"qid":23422,"description":"Which of the following is NOT a component of the process of upshifting?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":24795,"isBookmarked":0,"aid":"24793#24794#24795#31507","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":412,"qid":23422,"description":"Which of the following is NOT a component of the process of upshifting?","explanation":"To upshift, 1) press the clutch while rolling off the gas, 2) raise the shift lever as far as it will go, and 3) ease out the clutch and adjust the throttle. There is no need to press the brake.","imageName":"","correctAnswer":24795,"isBookmarked":0,"aid":"24793#24794#24795#31507","text":"Lifting the shift lever as far as it will go#Rolling off the throttle as you squeeze the clutch#Gently applying the front brake while adjusting the throttle#Easing out the clutch and adjusting the throttle"},
{"testId":400,"qid":23424,"description":"To help maintain the motorcycle stable throughout a turn, you should:","explanation":"Maintain or increase your speed to assist keep your motorbike stable during a turn.","imageName":"","correctAnswer":6758,"isBookmarked":0,"aid":"6515#6757#6758#6759","text":"Increase your speed#decrease your speed#maintain or increase your speed#maintain or decrease your speed"},
{"testId":73,"qid":23435,"description":"If you take a bend too quickly, you may end up:","explanation":"If you make a turn too quickly, you may wind yourself in another lane of traffic.","imageName":"","correctAnswer":31113,"isBookmarked":0,"aid":"7755#7757#31112#31113","text":"obstructing the view of vehicles behind you#destroying your tires#tipping over#veering into another lane of traffic"},
{"testId":416,"qid":23438,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"7961#10725#10726#28428","text":"Rolling on the throttle#Applying the rear brake#Applying the front brake#Doing any of the above"},
{"testId":420,"qid":23438,"description":"Smoother downshifting is facilitated by:","explanation":"Rolling on the gas slightly as you release the clutch aids in smoother downshifting.","imageName":"","correctAnswer":7961,"isBookmarked":0,"aid":"7961#10725#10726#28428","text":"Rolling on the throttle#Applying the rear brake#Applying the front brake#Doing any of the above"},
{"testId":434,"qid":23448,"description":"You should _____ when passing another vehicle.","explanation":"You should go fast across the driver's blind area while passing another car.","imageName":"","correctAnswer":24596,"isBookmarked":0,"aid":"6629#6632#24596#29405","text":"move as far left as possible#make eye contact with the driver#move quickly through the driver's blind spot#start your pass from the center lane position"},
{"testId":499,"qid":23448,"description":"You should _____ when passing another vehicle.","explanation":"You should go fast across the driver's blind area while passing another car.","imageName":"","correctAnswer":24596,"isBookmarked":0,"aid":"6629#6632#24596#29405","text":"move as far left as possible#make eye contact with the driver#move quickly through the driver's blind spot#start your pass from the center lane position"},
{"testId":404,"qid":23473,"description":"When transporting cargo in saddlebags,you should:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":420,"qid":23473,"description":"When transporting cargo in saddlebags,you should:","explanation":"To keep your motorbike balanced when carrying items in saddlebags, divide the weight equally between both bags.","imageName":"","correctAnswer":6255,"isBookmarked":0,"aid":"6254#6255#6256#6257","text":"fill the left bag before the right one#distribute the weight evenly between both bags#fill the right bag before the left one#make sure both bags are full"},
{"testId":442,"qid":23526,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":454,"qid":23526,"description":"Which lane position will make you the most noticeable to the vehicle ahead of you?","explanation":"You should ride in the middle lane to be noticed by the driver of the car ahead of you. This will place your picture in the center of the rear-view mirror. If you ride in the left lane, your picture may be reflected in the vehicle's left side mirror. Drivers, on the other hand, tend to check their rear-view mirrors more often than their side mirrors.","imageName":"","correctAnswer":6216,"isBookmarked":0,"aid":"6214#6215#6216#6217","text":"Left#Any#Center#Right"},
{"testId":404,"qid":23566,"description":"Which comes first, braking or swerving if you need to do both to avoid striking something?","explanation":"If you must brake and swerve, brake before or after swerving. Never brake while swerving; doing so increases the likelihood of a skid.","imageName":"","correctAnswer":6711,"isBookmarked":0,"aid":"6710#6711#33203#33204","text":"Brake while swerving#Brake before or after swerving#Brake after swerving, not before swerving#Brake before swerving, not after swerving"},
{"testId":429,"qid":23604,"description":"You should _____ to carry a load.","explanation":"To keep your motorbike balanced when carrying a load, spread the weight equally.","imageName":"","correctAnswer":6326,"isBookmarked":0,"aid":"6326#6733#22972#22973","text":"Distribute the load evenly#secure it as far back as possible#keep it high#only use approved luggage holders"},
{"testId":84,"qid":23713,"description":"You may boost your visibility by:","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":429,"qid":23713,"description":"You may boost your visibility by:","explanation":"No lane location is always the ideal for visibility and keeping a distance buffer. And no lane position should ever be avoided. They are all useful in their own way. As traffic conditions vary, an experienced motorcyclist switches lane positions.","imageName":"","correctAnswer":24707,"isBookmarked":0,"aid":"7963#8388#8390#24707","text":"Pulling in the clutch#Riding in the left lane position#Riding with a passenger#Changing your lane position according to traffic situations"},
{"testId":299,"qid":23715,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":35536,"isBookmarked":0,"aid":"10906#10909#32687#35536","text":"It gives you the best view of oncoming trains#A larger angle would be unsafe#It lets you cross the tracks quickly#At a smaller angle, you might catch your tires"},
{"testId":495,"qid":23715,"description":"Why is it essential to cross train tracks at a minimum 45-degree angle?","explanation":"To prevent catching your tires on the tracks, cross at an angle of at least 45 degrees. This may lead you to lose your equilibrium and fall.","imageName":"","correctAnswer":35536,"isBookmarked":0,"aid":"10906#10909#32687#35536","text":"It gives you the best view of oncoming trains#A larger angle would be unsafe#It lets you cross the tracks quickly#At a smaller angle, you might catch your tires"},
{"testId":467,"qid":23750,"description":"When you have a passenger on board, your motorcycle will:","explanation":"The weight of a passenger on the motorbike causes it to react more slowly. As a result, the motorbike will take longer to respond to changes such as slowing and stopping.","imageName":"","correctAnswer":28963,"isBookmarked":0,"aid":"6440#28963#28964#30820","text":"easily tip over#respond slower than usual#respond quicker than usual#respond the same as it would without a passenger"},
{"testId":85,"qid":23761,"description":"Which one of the following doesn't have an impact on your blood alcohol content (BAC)?","explanation":"The time of day has no effect on your blood alcohol level. At any moment, alcohol may impair your riding ability.","imageName":"","correctAnswer":7826,"isBookmarked":0,"aid":"7290#7826#7932#7933","text":"How fast you drink#The time of day#The amount of alcohol#Your weight"},
{"testId":81,"qid":23766,"description":"Which of the following can help you improve your reaction time?","explanation":"Reduce your response time by covering the clutch and both brakes, allowing you to use them more rapidly.","imageName":"","correctAnswer":27089,"isBookmarked":0,"aid":"10816#10818#10819#27089","text":"Lock your elbows when riding#Decrease your following distance#Hover your feet above the ground#Cover the clutch and both brakes"},
{"testId":450,"qid":23822,"description":"Rolling the throttle through a turn will:","explanation":"Through a turn, rolling on the throttle will assist steady the motorbike.","imageName":"","correctAnswer":6372,"isBookmarked":0,"aid":"6369#6371#6372#22247","text":"cause the motorcycle to skid#cause the motorcycle to tip over#help stabilize the motorcycle#help stop the motorcycle"},
{"testId":495,"qid":24110,"description":"In which of the following scenarios is a motorist most likely to attempt lane sharing?","explanation":"In the following instances, a vehicle may attempt to share your lane: (1) in heavy, bumper-to-bumper traffic; (2) when the driver wishes to pass you; (3) while you are about to turn at a junction; or (4) when you are going into an exit lane on a highway. To deter vehicles from sharing your lane, ride in the middle lane. This lane location gives you the most space on each side of you.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6769#24800#41054","text":"All of the above#In heavy traffic#When the driver wants to pass you#When you are preparing to turn at an intersection or moving into an exit lane on a highway"},
{"testId":596,"qid":24246,"description":"Which of the following drugs may impair your driving abilities?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#45070","text":"All of the above#Prescription drugs#Marijuana#Cocaine"},
{"testId":596,"qid":24247,"description":"You should ________, if you are taking any over-the-counter medications.","explanation":null,"imageName":"","correctAnswer":45072,"isBookmarked":0,"aid":"45071#45072#45073#45074","text":"drive below the speed limit#read the label about the side effects of the medication#drive only during the daylight hours#avoid driving"},
{"testId":596,"qid":24248,"description":"Which of the following is NOT TRUE about how alcohol makes you feel?","explanation":null,"imageName":"","correctAnswer":45075,"isBookmarked":0,"aid":"45075#45076#45077#45078","text":"Alcohol decreases your reaction time#Alcohol makes you less alert#Alcohol affects your judgment of speed and distance#Alcohol may make you more likely to take chances"},
{"testId":1063,"qid":24248,"description":"Which of the following is NOT TRUE about how alcohol makes you feel?","explanation":null,"imageName":"","correctAnswer":45075,"isBookmarked":0,"aid":"45075#45076#45077#45078","text":"Alcohol decreases your reaction time#Alcohol makes you less alert#Alcohol affects your judgment of speed and distance#Alcohol may make you more likely to take chances"},
{"testId":596,"qid":24249,"description":"Which of the following will help you get sober after drinking alcohol?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#32867#45079#45080","text":"None of the above#Taking a cold shower#Drinking a few cups of coffee#Eating a large meal"},
{"testId":1063,"qid":24249,"description":"Which of the following will help you get sober after drinking alcohol?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#32867#45079#45080","text":"None of the above#Taking a cold shower#Drinking a few cups of coffee#Eating a large meal"},
{"testId":596,"qid":24250,"description":"If you receive a traffic penalty in Connecticut or New Jersey,:","explanation":null,"imageName":"","correctAnswer":38316,"isBookmarked":0,"aid":"38316#38317#38318#38319","text":"you may return to New York State and answer the citation later#your vehicle may be impounded until you answer the citation#your license may be confiscated until you answer the citation#you may have to post bail until you answer the citation"},
{"testId":596,"qid":24252,"description":"One alcoholic drink will take your body roughly _______ to process.","explanation":null,"imageName":"","correctAnswer":13842,"isBookmarked":0,"aid":"745#747#13842#18405","text":"15 minutes#30 minutes#one hour#5 minutes"},
{"testId":1063,"qid":24252,"description":"One alcoholic drink will take your body roughly _______ to process.","explanation":null,"imageName":"","correctAnswer":13842,"isBookmarked":0,"aid":"745#747#13842#18405","text":"15 minutes#30 minutes#one hour#5 minutes"},
{"testId":596,"qid":24254,"description":"Driving under the influence of alcohol or drugs may result in ________ in New York State.","explanation":null,"imageName":"","correctAnswer":27725,"isBookmarked":0,"aid":"27725#40054#45081#45082","text":"any or all of the above#a fine#loss of your driver license#a jail sentence"},
{"testId":596,"qid":24255,"description":"Which of the following offenses can result in arrest in New York State?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#38327#38328#38329","text":"Any of the above#Driving with a blood alcohol content (BAC) of 0.08% or more#Driving while intoxicated (DWI)#Driving while ability impaired (DWAI)"},
{"testId":596,"qid":24256,"description":"Vehicles approaching from either direction must stop at least ________ from a stopped school bus with its red lights flashing in New York State.","explanation":"Vehicles coming from either direction must stop at least 20 feet away from a stopped school bus with its red lights flashing, even if it is in front of the school or in a school parking lot. This legislation applies to all New York State roads. Unlike many other states, New York requires you to stop for a halted school bus even if it is on the other side of a split roadway. [New York State DMV Driver's Manual, School Buses, Chapter 6 - How to Pass]","imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":1063,"qid":24256,"description":"Vehicles approaching from either direction must stop at least ________ from a stopped school bus with its red lights flashing in New York State.","explanation":"Vehicles coming from either direction must stop at least 20 feet away from a stopped school bus with its red lights flashing, even if it is in front of the school or in a school parking lot. This legislation applies to all New York State roads. Unlike many other states, New York requires you to stop for a halted school bus even if it is on the other side of a split roadway. [New York State DMV Driver's Manual, School Buses, Chapter 6 - How to Pass]","imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":596,"qid":24257,"description":"A Canadian traffic ticket won't affect your driving record unless it was issued in:","explanation":null,"imageName":"","correctAnswer":45083,"isBookmarked":0,"aid":"45083#45084#45085#45086","text":"Ontario or Quebec#New Brunswick or Quebec#Newfoundland or Ontario#Nova Scotia or Quebec"},
{"testId":596,"qid":24258,"description":"You've had a few drinks and are ready to go home. What should you do?","explanation":null,"imageName":"","correctAnswer":45087,"isBookmarked":0,"aid":"45087#45088#45089#45090","text":"Ask a friend who hasn't been drinking to give you a ride#Have two cups of coffee before you drive#Wait a half hour before you drive#Drive slower than the posted speed limit"},
{"testId":596,"qid":24259,"description":"If you're 18 when you get your NY driver's license, you'll be on probation for:","explanation":null,"imageName":"","correctAnswer":23254,"isBookmarked":0,"aid":"23254#26650#26651#27897","text":"Six months#One year#Two years#one month"},
{"testId":596,"qid":24260,"description":"An adult motorist is considered inebriated in New York if his or her blood alcohol content (BAC) is ____ or higher.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1063,"qid":24260,"description":"An adult motorist is considered inebriated in New York if his or her blood alcohol content (BAC) is ____ or higher.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":596,"qid":24261,"description":"In New York, you must begin signaling your intention at least ______ before turning or changing lanes.","explanation":null,"imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#4061#8417","text":"100 feet#50 feet#75 feet#30 feet"},
{"testId":1063,"qid":24261,"description":"In New York, you must begin signaling your intention at least ______ before turning or changing lanes.","explanation":null,"imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#4061#8417","text":"100 feet#50 feet#75 feet#30 feet"},
{"testId":596,"qid":24262,"description":"If you are engaged in a traffic accident, you must file an accident report with the DMV if any one person's property was damaged for at least .","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":596,"qid":24263,"description":"Having coffee after consuming alcohol:","explanation":null,"imageName":"","correctAnswer":45058,"isBookmarked":0,"aid":"45056#45057#45058#45091","text":"increases your blood alcohol content#decreases your blood alcohol content#has no effect on your blood alcohol content#counteracts all the effects of alcohol"},
{"testId":1063,"qid":24263,"description":"Having coffee after consuming alcohol:","explanation":null,"imageName":"","correctAnswer":45058,"isBookmarked":0,"aid":"45056#45057#45058#45091","text":"increases your blood alcohol content#decreases your blood alcohol content#has no effect on your blood alcohol content#counteracts all the effects of alcohol"},
{"testId":596,"qid":24264,"description":"If you are caught using your cell phone to read text messages while driving, you could face a fine of up to .","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11833#12358#12817","text":"$500#$300#$200#$400"},
{"testId":596,"qid":24265,"description":"Which types of vehicles are required to stop at all railroad crossings?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14242#45092#45093","text":"All of the above#School buses#Buses carrying passengers#Vehicles carrying flammable cargo"},
{"testId":1063,"qid":24265,"description":"Which types of vehicles are required to stop at all railroad crossings?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14242#45092#45093","text":"All of the above#School buses#Buses carrying passengers#Vehicles carrying flammable cargo"},
{"testId":596,"qid":24266,"description":"You're driving on an expressway with the intention of exiting. When should you begin to indicate your plan to leave?","explanation":null,"imageName":"","correctAnswer":45094,"isBookmarked":0,"aid":"45094#45095#45096#45097","text":"At least 100 feet before the exit ramp#At least 200 feet before the exit ramp#At the exit ramp#At least 50 feet before the exit ramp"},
{"testId":596,"qid":24267,"description":"What is the most common cause of motorcycle accidents involving other vehicles?","explanation":null,"imageName":"","correctAnswer":45098,"isBookmarked":0,"aid":"45098#45099#45100#45101","text":"A vehicle entering the motorcycle's right-of-way#A vehicle tailgating the motorcycle#A vehicle being tailgated by the motorcycle#A vehicle driving too fast for the conditions"},
{"testId":596,"qid":24268,"description":"A tractor-trailer going at 55 mph can have a stopping distance of up to:","explanation":null,"imageName":"","correctAnswer":45102,"isBookmarked":0,"aid":"20794#30578#39291#45102","text":"300 feet#250 feet#350 feet#430 feet"},
{"testId":1063,"qid":24268,"description":"A tractor-trailer going at 55 mph can have a stopping distance of up to:","explanation":null,"imageName":"","correctAnswer":45102,"isBookmarked":0,"aid":"20794#30578#39291#45102","text":"300 feet#250 feet#350 feet#430 feet"},
{"testId":596,"qid":24269,"description":"Which of the following statements concerning driving impaired is true?","explanation":null,"imageName":"","correctAnswer":28163,"isBookmarked":0,"aid":"28163#45103#45104#45105","text":"All of the above statements are true#Every impaired driver is a threat to the safety of others#An impaired driver poses a risk to his own life#Safe driving is impossible if the driver is under the influence of alcohol or drugs"},
{"testId":596,"qid":24270,"description":"Which of the following is NOT TRUE about minimum speeds?","explanation":null,"imageName":"","correctAnswer":45106,"isBookmarked":0,"aid":"45106#45107#45108#45109","text":"You do not have to obey minimum speed limit signs#Driving below the posted minimum speed limit can be as dangerous as exceeding the posted maximum speed limit#Driving below the posted minimum speed limit can interfere with the flow of traffic#You can get a ticket for driving below the posted minimum speed limit"},
{"testId":596,"qid":24271,"description":"In New York State, parallel parking must be done within _______ of the curb.","explanation":null,"imageName":"","correctAnswer":818,"isBookmarked":0,"aid":"818#4921#18169#39693","text":"12 inches#18 inches#6 inches#24 inches"},
{"testId":596,"qid":24272,"description":"After only _____, your blood alcohol content (BAC) can reach 0.02%.","explanation":null,"imageName":"","correctAnswer":38355,"isBookmarked":0,"aid":"38354#38355#38356#38357","text":"three drinks#one drink#two drinks#four drinks"},
{"testId":596,"qid":24273,"description":"The minimum penalty for passing a halted school bus is:","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"23889#24978#24995#31696","text":"$1,000#$100#$150#$400"},
{"testId":1063,"qid":24273,"description":"The minimum penalty for passing a halted school bus is:","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"23889#24978#24995#31696","text":"$1,000#$100#$150#$400"},
{"testId":596,"qid":24274,"description":"What does a school bus's flashing red lights mean?","explanation":null,"imageName":"","correctAnswer":45113,"isBookmarked":0,"aid":"45110#45111#45112#45113","text":"You must stop and wait for the lights to stop flashing before you proceed#The bus is loading or unloading children#Vehicles traveling in either direction must stop at least 20 feet from the bus#They indicate all of the above"},
{"testId":1063,"qid":24274,"description":"What does a school bus's flashing red lights mean?","explanation":null,"imageName":"","correctAnswer":45113,"isBookmarked":0,"aid":"45110#45111#45112#45113","text":"You must stop and wait for the lights to stop flashing before you proceed#The bus is loading or unloading children#Vehicles traveling in either direction must stop at least 20 feet from the bus#They indicate all of the above"},
{"testId":596,"qid":24275,"description":"If you pass a stopped school bus_______ or more times in three years, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":596,"qid":24276,"description":"You are not permitted to park within ______ of a fire hydrant a licensed driver remains with the vehicle.","explanation":null,"imageName":"","correctAnswer":8416,"isBookmarked":0,"aid":"1027#4582#8416#8417","text":"25 feet#20 feet#15 feet#30 feet"},
{"testId":596,"qid":24277,"description":"Parking and standing are illegal _________ in New York State.","explanation":null,"imageName":"","correctAnswer":28247,"isBookmarked":0,"aid":"28247#45114#45115#45116","text":"in all of the above places#within 30 feet of a STOP sign#within 30 feet of a YIELD sign#within 30 feet of a traffic light"},
{"testId":1063,"qid":24277,"description":"Parking and standing are illegal _________ in New York State.","explanation":null,"imageName":"","correctAnswer":28247,"isBookmarked":0,"aid":"28247#45114#45115#45116","text":"in all of the above places#within 30 feet of a STOP sign#within 30 feet of a YIELD sign#within 30 feet of a traffic light"},
{"testId":596,"qid":24278,"description":"You are not permitted to park within ______ of a pedestrian crosswalk.","explanation":null,"imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1026#1027#4582#8417","text":"50 feet#25 feet#20 feet#30 feet"},
{"testId":596,"qid":24279,"description":"Is it legal to park in a crosswalk?","explanation":null,"imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#32812#45117#45118","text":"No#Yes, but only in rural areas#Yes, but only with your headlights on#Yes, but only if there is a licensed driver in the vehicle"},
{"testId":596,"qid":24280,"description":"What are the consequences of a parking violation?","explanation":null,"imageName":"","correctAnswer":26643,"isBookmarked":0,"aid":"203#12374#26643#35315","text":"All of the above#License suspension#A fine#A jail sentence"},
{"testId":1063,"qid":24280,"description":"What are the consequences of a parking violation?","explanation":null,"imageName":"","correctAnswer":26643,"isBookmarked":0,"aid":"203#12374#26643#35315","text":"All of the above#License suspension#A fine#A jail sentence"},
{"testId":596,"qid":24281,"description":"What does the DMV point system serve?","explanation":null,"imageName":"","correctAnswer":38376,"isBookmarked":0,"aid":"38376#38377#38378#38379","text":"To identify repeat offenders#To punish young drivers#To charge fines#To get bad drivers off the road"},
{"testId":1063,"qid":24281,"description":"What does the DMV point system serve?","explanation":null,"imageName":"","correctAnswer":38376,"isBookmarked":0,"aid":"38376#38377#38378#38379","text":"To identify repeat offenders#To punish young drivers#To charge fines#To get bad drivers off the road"},
{"testId":596,"qid":24282,"description":"The traffic regulations of New York State also apply to:","explanation":null,"imageName":"","correctAnswer":38381,"isBookmarked":0,"aid":"38380#38381#38382#38383","text":"the driveways of private homes#public parking lots#private parking decks#garages attached to homes"},
{"testId":1063,"qid":24282,"description":"The traffic regulations of New York State also apply to:","explanation":null,"imageName":"","correctAnswer":38381,"isBookmarked":0,"aid":"38380#38381#38382#38383","text":"the driveways of private homes#public parking lots#private parking decks#garages attached to homes"},
{"testId":596,"qid":24283,"description":"Driving privileges will be suspended for at least __________ if you are found guilty of operating an uninsured vehicle.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#25170#27363","text":"2 years#1 year#1 month#2 months"},
{"testId":596,"qid":24284,"description":"Which of the following will result in an indefinite suspension of your license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34994#45119#45120","text":"Any of the above#Failing to pay child support#Failing to file an accident report#Failing to pay taxes"},
{"testId":1063,"qid":24284,"description":"Which of the following will result in an indefinite suspension of your license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34994#45119#45120","text":"Any of the above#Failing to pay child support#Failing to file an accident report#Failing to pay taxes"},
{"testId":596,"qid":24285,"description":"______ results in a one-year license suspension for drivers under 21.","explanation":null,"imageName":"","correctAnswer":45122,"isBookmarked":0,"aid":"45121#45122#45123#45124","text":"Speeding at 15 mph over the speed limit#Refusing a chemical test#Illegal parking#Failing to stop at a STOP sign"},
{"testId":596,"qid":24286,"description":"You hear an emergency vehicle's siren, but you don't know where the vehicle is. What do you need to do?","explanation":null,"imageName":"","correctAnswer":45127,"isBookmarked":0,"aid":"45125#45126#45127#45128","text":"Keep driving at the same speed until you see it#Slow down until you see it#Pull over to the right and stop until you're sure it's not heading toward you#Pull over to the left and stop until you're sure it's not heading toward you"},
{"testId":1063,"qid":24286,"description":"You hear an emergency vehicle's siren, but you don't know where the vehicle is. What do you need to do?","explanation":null,"imageName":"","correctAnswer":45127,"isBookmarked":0,"aid":"45125#45126#45127#45128","text":"Keep driving at the same speed until you see it#Slow down until you see it#Pull over to the right and stop until you're sure it's not heading toward you#Pull over to the left and stop until you're sure it's not heading toward you"},
{"testId":596,"qid":24287,"description":"Who can be penalized if a 16-year-old passenger in the front seat of your vehicle is not wearing a seat belt while you're driving?","explanation":null,"imageName":"","correctAnswer":34753,"isBookmarked":0,"aid":"9642#34753#40929#40930","text":"You#The passenger#Both you and the passenger#Neither you nor the passenger"},
{"testId":596,"qid":24288,"description":"If a 15-year-old passenger in your vehicle's front seat is not wearing a seat belt while you're driving, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":596,"qid":24289,"description":"You may be fined up to _____ for driving without wearing a seat belt.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":596,"qid":24291,"description":"If you are found guilty of speaking into or listening to a hand-held cell phone while driving, you might face a fine of up to .","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11833#12358#12817","text":"$500#$300#$200#$400"},
{"testId":596,"qid":24292,"description":"Which of the following is required as proof of insurance in New York State?","explanation":null,"imageName":"","correctAnswer":38397,"isBookmarked":0,"aid":"234#34464#38396#38397","text":"Any of the above#Your driver license#A receipt from your insurance agent#An insurance identification card"},
{"testId":596,"qid":24293,"description":"Which of the following is NOT true about talking on a cell phone while driving?","explanation":null,"imageName":"","correctAnswer":45129,"isBookmarked":0,"aid":"45129#45130#45131#45132","text":"You may use a hand-held cell phone at any time#You may use a hand-held cell phone to activate, start, or end a call#It is illegal to listen to a hand-held cell phone while you're driving#You may use a hand-held cell phone to call the police"},
{"testId":596,"qid":24294,"description":"Your vehicle's registration must be renewed:","explanation":null,"imageName":"","correctAnswer":35461,"isBookmarked":0,"aid":"28051#32049#35461#38398","text":"every year#every five years#every two years#when your driver license expires"},
{"testId":596,"qid":24295,"description":"Which penalty may result from driving with a revoked license?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31378#38399#38400","text":"All of the above#Imprisonment#A stiff fine#Seizure of your vehicle"},
{"testId":564,"qid":24296,"description":"A limited driving license holder must be at least ___ years old before applying for an unrestricted driver license.","explanation":"A limited driver license holder must either (a) turn 18 or (b) be 17 years old and have had the restricted license for at least six months before applying for an unrestricted driver license. [Attention Drivers Age 15, 16, and 17!, Alabama Driver Manual]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":564,"qid":24297,"description":"Which of the following will make it impossible for someone to get a driver's license in Alabama?","explanation":null,"imageName":"","correctAnswer":42742,"isBookmarked":0,"aid":"897#11375#42741#42742","text":"None of the above#Being under the age of 18#Having paid parking tickets in another state#Being a habitual user of alcohol or narcotics"},
{"testId":1031,"qid":24297,"description":"Which of the following will make it impossible for someone to get a driver's license in Alabama?","explanation":null,"imageName":"","correctAnswer":42742,"isBookmarked":0,"aid":"897#11375#42741#42742","text":"None of the above#Being under the age of 18#Having paid parking tickets in another state#Being a habitual user of alcohol or narcotics"},
{"testId":564,"qid":24298,"description":"You will not be able to obtain an Alabama driver license if you are under the age of 19 and have not :","explanation":null,"imageName":"","correctAnswer":42743,"isBookmarked":0,"aid":"11380#42743#42744#42745","text":"obtained medical insurance#enrolled in or graduated from secondary school#leased or purchased your own vehicle#done any of the above"},
{"testId":1031,"qid":24298,"description":"You will not be able to obtain an Alabama driver license if you are under the age of 19 and have not :","explanation":null,"imageName":"","correctAnswer":42743,"isBookmarked":0,"aid":"11380#42743#42744#42745","text":"obtained medical insurance#enrolled in or graduated from secondary school#leased or purchased your own vehicle#done any of the above"},
{"testId":564,"qid":24299,"description":"All applicants seeking a driver's license must show proof of:","explanation":null,"imageName":"","correctAnswer":11383,"isBookmarked":0,"aid":"8626#11382#11383#42746","text":"None of the above#employment#legal residence#taxes paid"},
{"testId":1031,"qid":24299,"description":"All applicants seeking a driver's license must show proof of:","explanation":null,"imageName":"","correctAnswer":11383,"isBookmarked":0,"aid":"8626#11382#11383#42746","text":"None of the above#employment#legal residence#taxes paid"},
{"testId":564,"qid":24300,"description":"A 15-year-old driver must be supervised by a driving teacher or a licensed driver who is at least ___ years old while holding a learner license.","explanation":null,"imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":564,"qid":24301,"description":"A learner license in Alabama is a driver license with the restriction code:","explanation":null,"imageName":"","correctAnswer":42750,"isBookmarked":0,"aid":"42747#42748#42749#42750","text":"\"A.\"#\"B.\"#\"D.\"#\"Y.\""},
{"testId":1031,"qid":24301,"description":"A learner license in Alabama is a driver license with the restriction code:","explanation":null,"imageName":"","correctAnswer":42750,"isBookmarked":0,"aid":"42747#42748#42749#42750","text":"\"A.\"#\"B.\"#\"D.\"#\"Y.\""},
{"testId":564,"qid":24303,"description":"What kind of licenses in Alabama can be suspended but not revoked?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#42751#42752#42753","text":"None of the above#Learner license#Restricted driver license#Unrestricted (regular) driver license"},
{"testId":564,"qid":24304,"description":"The automobile used in a road test:","explanation":null,"imageName":"","correctAnswer":42757,"isBookmarked":0,"aid":"42754#42755#42756#42757","text":"must be owned by the person taking the test#is supplied by DPS#must have dual controls#must be safe to drive and properly equipped"},
{"testId":564,"qid":24305,"description":"There are four ways for an Alabama driver to lose his or her license: cancellation, suspension, revocation, and :","explanation":null,"imageName":"","correctAnswer":11399,"isBookmarked":0,"aid":"11399#11401#11402#42758","text":"disqualification#confiscation#deletion#rejection"},
{"testId":1031,"qid":24305,"description":"There are four ways for an Alabama driver to lose his or her license: cancellation, suspension, revocation, and :","explanation":null,"imageName":"","correctAnswer":11399,"isBookmarked":0,"aid":"11399#11401#11402#42758","text":"disqualification#confiscation#deletion#rejection"},
{"testId":564,"qid":24307,"description":"What must you do if your license has been revoked in order to regain your driving privileges?","explanation":null,"imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"11407#11408#23000#42759","text":"Wait until the revocation period is over#Pay the reinstatement fee#Do all of the above#Pass the knowledge and road tests"},
{"testId":1031,"qid":24307,"description":"What must you do if your license has been revoked in order to regain your driving privileges?","explanation":null,"imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"11407#11408#23000#42759","text":"Wait until the revocation period is over#Pay the reinstatement fee#Do all of the above#Pass the knowledge and road tests"},
{"testId":564,"qid":24308,"description":"After how many DUI convictions, an driver license will be revoked?","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1031,"qid":24308,"description":"After how many DUI convictions, an driver license will be revoked?","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":564,"qid":24310,"description":"If you're convicted of hit-and-run involving death or injury, your license will be:","explanation":null,"imageName":"","correctAnswer":11436,"isBookmarked":0,"aid":"11436#11437#11439#42761","text":"revoked#canceled#suspended#disqualified"},
{"testId":1031,"qid":24310,"description":"If you're convicted of hit-and-run involving death or injury, your license will be:","explanation":null,"imageName":"","correctAnswer":11436,"isBookmarked":0,"aid":"11436#11437#11439#42761","text":"revoked#canceled#suspended#disqualified"},
{"testId":564,"qid":24311,"description":"A driver's license will be revoked if he or she has _____ convictions for reckless driving within a one-year period.","explanation":null,"imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10547#11417#11418","text":"Three#Two#Five#Ten"},
{"testId":1031,"qid":24311,"description":"A driver's license will be revoked if he or she has _____ convictions for reckless driving within a one-year period.","explanation":null,"imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10547#11417#11418","text":"Three#Two#Five#Ten"},
{"testId":564,"qid":24312,"description":"Your license will be automatically reinstated if :","explanation":null,"imageName":"","correctAnswer":42762,"isBookmarked":0,"aid":"8626#42762#42763#42764","text":"None of the above#the suspension period is over#you have paid all fines#you've served your jail sentence"},
{"testId":1031,"qid":24312,"description":"Your license will be automatically reinstated if :","explanation":null,"imageName":"","correctAnswer":42762,"isBookmarked":0,"aid":"8626#42762#42763#42764","text":"None of the above#the suspension period is over#you have paid all fines#you've served your jail sentence"},
{"testId":564,"qid":24313,"description":"Which of the following can lead to your license being suspended?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#42765#42766#42767","text":"Any of the above#Temporary inability to drive#Fraudulent use of your license#Drag racing on a public roadway"},
{"testId":1031,"qid":24313,"description":"Which of the following can lead to your license being suspended?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#42765#42766#42767","text":"Any of the above#Temporary inability to drive#Fraudulent use of your license#Drag racing on a public roadway"},
{"testId":564,"qid":24314,"description":"What system does Alabama employ to identify dangerous drivers?","explanation":null,"imageName":"","correctAnswer":42769,"isBookmarked":0,"aid":"42768#42769#42770#42771","text":"The Record System#The Point System#The Ticket System#The Citation System"},
{"testId":1031,"qid":24314,"description":"What system does Alabama employ to identify dangerous drivers?","explanation":null,"imageName":"","correctAnswer":42769,"isBookmarked":0,"aid":"42768#42769#42770#42771","text":"The Record System#The Point System#The Ticket System#The Citation System"},
{"testId":564,"qid":24315,"description":"If you are convicted of a charge involving drunk driving but your license is not revoked, ___ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6388#6389#6916","text":"10#2#3#6"},
{"testId":1031,"qid":24315,"description":"If you are convicted of a charge involving drunk driving but your license is not revoked, ___ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6388#6389#6916","text":"10#2#3#6"},
{"testId":564,"qid":24316,"description":"Five points will be added to your driving record if you are convicted of which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11431#40400#42772","text":"All of the above#Passing a stopped school bus#Failing to yield the right-of-way#Speeding at 95 mph where the posted speed limit is 65 mph"},
{"testId":1031,"qid":24316,"description":"Five points will be added to your driving record if you are convicted of which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11431#40400#42772","text":"All of the above#Passing a stopped school bus#Failing to yield the right-of-way#Speeding at 95 mph where the posted speed limit is 65 mph"},
{"testId":564,"qid":24318,"description":"Your license will be suspended for _________, if you acquire 24 or more points in a two-year period.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1031,"qid":24318,"description":"Your license will be suspended for _________, if you acquire 24 or more points in a two-year period.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":564,"qid":24319,"description":"You are entitled to __________if your Alabama driver's license is suspended.","explanation":null,"imageName":"","correctAnswer":42774,"isBookmarked":0,"aid":"8626#11433#42773#42774","text":"None of the above#a new copy of your license#a trial by jury#an administrative hearing"},
{"testId":1031,"qid":24319,"description":"You are entitled to __________if your Alabama driver's license is suspended.","explanation":null,"imageName":"","correctAnswer":42774,"isBookmarked":0,"aid":"8626#11433#42773#42774","text":"None of the above#a new copy of your license#a trial by jury#an administrative hearing"},
{"testId":564,"qid":24321,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 75 mph on a road with a specified speed restriction of 65 mph.","explanation":null,"imageName":"","correctAnswer":4656,"isBookmarked":0,"aid":"4656#4657#4658#4659","text":"2 points#3 points#4 points#1 point"},
{"testId":1031,"qid":24321,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 75 mph on a road with a specified speed restriction of 65 mph.","explanation":null,"imageName":"","correctAnswer":4656,"isBookmarked":0,"aid":"4656#4657#4658#4659","text":"2 points#3 points#4 points#1 point"},
{"testId":564,"qid":24322,"description":"If a driver under the age of 18 drops out of high school without a valid reason, his or her driver's license will be:.","explanation":null,"imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11437#11438#11439","text":"revoked#canceled#confiscated#suspended"},
{"testId":1031,"qid":24322,"description":"If a driver under the age of 18 drops out of high school without a valid reason, his or her driver's license will be:.","explanation":null,"imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11437#11438#11439","text":"revoked#canceled#confiscated#suspended"},
{"testId":564,"qid":24323,"description":"Your driver's license will be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#42775#42776#42777","text":"Any of the above#fleeing from the police in a motor vehicle#DUI for the first time#drag racing on a public roadway"},
{"testId":1031,"qid":24323,"description":"Your driver's license will be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#42775#42776#42777","text":"Any of the above#fleeing from the police in a motor vehicle#DUI for the first time#drag racing on a public roadway"},
{"testId":564,"qid":24324,"description":"If convicted of driving without owner's consent, you may face all of the following penalties EXCEPT:","explanation":null,"imageName":"","correctAnswer":42778,"isBookmarked":0,"aid":"11443#40054#42778#42779","text":"jail time#a fine#points added to your driving record#license revocation"},
{"testId":1031,"qid":24324,"description":"If convicted of driving without owner's consent, you may face all of the following penalties EXCEPT:","explanation":null,"imageName":"","correctAnswer":42778,"isBookmarked":0,"aid":"11443#40054#42778#42779","text":"jail time#a fine#points added to your driving record#license revocation"},
{"testId":564,"qid":24325,"description":"Why is cancellation of a driver's license different from revocation or suspension?","explanation":null,"imageName":"","correctAnswer":11446,"isBookmarked":0,"aid":"11445#11446#11448#42780","text":"It is permanent#It is not caused by violations or citations#There is a cancellation fee#It is ordered by a police officer"},
{"testId":1031,"qid":24325,"description":"Why is cancellation of a driver's license different from revocation or suspension?","explanation":null,"imageName":"","correctAnswer":11446,"isBookmarked":0,"aid":"11445#11446#11448#42780","text":"It is permanent#It is not caused by violations or citations#There is a cancellation fee#It is ordered by a police officer"},
{"testId":564,"qid":24326,"description":"If you do not surrender your license after it has been revoked, suspended, or cancelled, you must pay an extra cost of:","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":1031,"qid":24326,"description":"If you do not surrender your license after it has been revoked, suspended, or cancelled, you must pay an extra cost of:","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":564,"qid":24327,"description":"Which type of operator license do you need to drive a passenger car for pleasure?","explanation":null,"imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":564,"qid":24328,"description":"Why do you have to make sure that ALEA has your current mailing address on file when you get your temporary license?","explanation":null,"imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"26881#42781#42782#42783","text":"For all of the above reasons#Because your permanent license will be mailed to the address on file#Because your permanent license will not be forwarded to your new address#Because your permanent license is a primary identification document, so it must have your current address"},
{"testId":1031,"qid":24328,"description":"Why do you have to make sure that ALEA has your current mailing address on file when you get your temporary license?","explanation":null,"imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"26881#42781#42782#42783","text":"For all of the above reasons#Because your permanent license will be mailed to the address on file#Because your permanent license will not be forwarded to your new address#Because your permanent license is a primary identification document, so it must have your current address"},
{"testId":564,"qid":24329,"description":"Alabama's Graduated Driver License (GDL) scheme has ___ levels.","explanation":"Stage I (Learner License or Learner's Permit), Stage II (Restricted Driver License), and Stage III are the three phases of Alabama's Graduated Driver License (GDL) programme for prospective drivers under the age of 18. (Unrestricted or Regular Driver License). Before moving on to the next phase, the driver must fulfil a number of prerequisites. [\"Graduated Driver License,\" ALEA Department of Public Safety, https://www.alea.gov/dps/driver-license/license-and-id-cards/graduated-driver-license,\" \"Attention 15, 16, and 17 Year Old Drivers!, Alabama Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":564,"qid":24330,"description":"The Alabama Graduated Driver License (GDL) Program is not for aspiring drivers who are at least ____ years old.","explanation":"Applicants who are at least 18 years old are exempt from the Alabama Graduated Driver License (GDL) Program. [Attention Drivers Age 15, 16, and 17!, Alabama Driver Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#11359#11374#11696","text":"20#18#17#19"},
{"testId":564,"qid":24331,"description":"A 16-year-old aspiring driver is exempt from the Graduated Driver License (GDL) scheme if:","explanation":"If a 16-year-old driver is married, the head of the home, or an emancipated juvenile, they are exempt from Alabama's Graduated Driver License (GDL) Program. If you are single, have small children or other dependents, you are legally regarded as the \"head of the home.\" [Attention 15, 16, & 17 Year Old Drivers!, Alabama Driver Manual]","imageName":"","correctAnswer":38061,"isBookmarked":0,"aid":"38061#42784#42785#42786","text":"either (a) or (b)#(a) the driver is caring for a minor child#(b) the driver is married#the driver is legally employed"},
{"testId":564,"qid":24332,"description":"Before applying for a restricted driver license, an aspiring driver under the age of 18 must have held a learner license for at least:","explanation":"An aspiring driver under the age of 18 holding a learner licence must hold the learner licence for at least six months before he or she may apply for a limited driver licence. However, if he or she reaches 18 while holding the learner licence, he or she may apply for an unrestricted driving licence. [Attention 15, 16, & 17 Year Old Drivers!, Alabama Driver Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1272#1454#23290","text":"30 days#6 months#9 months#One year"},
{"testId":564,"qid":24333,"description":"Before obtaining a restricted driver license, a 16-year-old driver having learner license must:","explanation":null,"imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#42787#42788#42789","text":"Do all of the above#get permission from his or her parent, grandparent, or guardian#complete supervised driving practice or a driver's ed course#pass a road test"},
{"testId":564,"qid":24334,"description":"You may have no more than _____ unrelated passenger(s) under the age of 21 in your vehicle when driving with a restricted driver's license.","explanation":"While driving with a limited license, you may have just one passenger under the age of 21 who is not a family member in your car. [Restrictions, Attention 15-, 16-, and 17-Year-Old Drivers!, Alabama Driver Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":564,"qid":24335,"description":"When can you drive with a restricted license between the hours of midnight and 6:00 a.m.?","explanation":null,"imageName":"","correctAnswer":11220,"isBookmarked":0,"aid":"11220#42790#42791#42792","text":"In any of the above situations#If you are accompanied by a responsible adult#If you are traveling to or from your place of employment#If you are traveling to or from a school-sponsored event"},
{"testId":564,"qid":24336,"description":"A driver under the age of 18 with a limited driver license is not permitted to drive while holding:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#42793#42794#42795","text":"Any of the above#a cell phone#a pager#a tablet"},
{"testId":564,"qid":24337,"description":"If a driver of 16 years of age has a restricted license and breaches any of the license's restrictions,:","explanation":"If a 16-year-old driver with a limited driver license breaches any of the license's limitations, all restrictions will be extended by six months or until the driver reaches the age of 18, whichever comes first. [Attention Drivers Age 15, 16, and 17!, Alabama Driver Manual]","imageName":"","correctAnswer":42797,"isBookmarked":0,"aid":"33850#42796#42797#42798","text":"the driver will have to pay a fine#all restrictions will be extended by 3 months or until the driver turns 18#all restrictions will be extended by 6 months or until the driver turns 18#the driver's license will be suspended for 3 months or until he or she turns 18"},
{"testId":564,"qid":24338,"description":"Which of the following traffic infractions carries the MOST points?","explanation":null,"imageName":"","correctAnswer":25886,"isBookmarked":0,"aid":"864#25886#26680#35444","text":"Tailgating#Driving on the wrong side of the road#Running a red light#Failing to obey a police officer's directions"},
{"testId":1031,"qid":24338,"description":"Which of the following traffic infractions carries the MOST points?","explanation":null,"imageName":"","correctAnswer":25886,"isBookmarked":0,"aid":"864#25886#26680#35444","text":"Tailgating#Driving on the wrong side of the road#Running a red light#Failing to obey a police officer's directions"},
{"testId":564,"qid":24339,"description":"Under Alabama's Open Container Law, you can't have an open bottle or can of alcohol _____ while it's on a public road.","explanation":null,"imageName":"","correctAnswer":42799,"isBookmarked":0,"aid":"12830#13722#13767#42799","text":"in the glove compartment#under the driver's seat#under the passenger seat#in any of the above locations"},
{"testId":564,"qid":24340,"description":"If a driver under the age of 18 with a limited driver license is convicted of a four-point traffic offense, his or her license will be suspended for ______ or until the driver reaches the age of 18.","explanation":"If an underage driver with a limited driver license is convicted of a traffic infraction that adds four or more points to his or her driving record, his or her license will be revoked for 60 days or until the age of 18. Illegal passing and driving on the wrong side of the road are examples of four-point offences. [Enforcement, Attention 15-, 16-, and 17-Year-Old Drivers!, Alabama Driver Handbook]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":564,"qid":24341,"description":"If a driver under the age of 18 with a limited driver license gets convicted of a second traffic offence, his or her license will be suspended for ______ or until the driver reaches the age of 18.","explanation":"If an underage driver with a limited driver license gets convicted of a second traffic offence, his or her license will be revoked for 60 days or until the age of 18. (It should be noted that breaching any of the limitations on the restricted license is considered a traffic infraction.) [Enforcement, Attention 15-, 16-, and 17-Year-Old Drivers!, Alabama Driver Handbook]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":564,"qid":24342,"description":"How many times can a learner's license be renewed?","explanation":null,"imageName":"","correctAnswer":11498,"isBookmarked":0,"aid":"11498#11499#11500#17889","text":"Once#Twice#Three times#None"},
{"testId":1031,"qid":24342,"description":"How many times can a learner's license be renewed?","explanation":null,"imageName":"","correctAnswer":11498,"isBookmarked":0,"aid":"11498#11499#11500#17889","text":"Once#Twice#Three times#None"},
{"testId":564,"qid":24343,"description":"For much duration an learner license is valid?","explanation":null,"imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23153#23154","text":"2 years#4 years#6 months#1 year"},
{"testId":1031,"qid":24343,"description":"For much duration an learner license is valid?","explanation":null,"imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23153#23154","text":"2 years#4 years#6 months#1 year"},
{"testId":564,"qid":24344,"description":"A _____ applicant must have a parent or guardian with them during the road test.","explanation":null,"imageName":"","correctAnswer":42800,"isBookmarked":0,"aid":"42800#42801#42802#42803","text":"16-year-old#17-year-old#16- or 17-year-old#18-year-old"},
{"testId":564,"qid":24345,"description":"Which of the following individuals are permitted to ride in the vehicle during the road test?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#42804#42805#42806","text":"None of the above#A sibling#A parent or guardian#A driving instructor"},
{"testId":565,"qid":24346,"description":"For much duration an instruction permit is valid?","explanation":"The two-year non-commercial instruction permit for Alaska may only be renewed once. [State of Alaska Driver Manual, Non-Commercial Instruction Permit, Licenses and Permits]","imageName":"","correctAnswer":26651,"isBookmarked":0,"aid":"23254#23255#26651#31351","text":"Six months#Nine months#Two years#Four years"},
{"testId":565,"qid":24347,"description":"An underage driver must have at least ___ hours of driving experience before applying for an Alaska provisional license.","explanation":"Before an underage driver may apply for an Alaska provisional licence, his or her parent, guardian, or employer must attest that he or she has had at least 40 hours of supervised driving experience including at least 10 hours in adverse weather or at night. [Provisional Licenses, Licenses and Permits, State of Alaska Driver Manual]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":565,"qid":24348,"description":"What are the three levels of the Graduated Driver License program for prospective drivers under the age of 18 in Alaska?","explanation":"Every state in the United States has a three-stage graduated driver licensing scheme that allows a young driver to obtain driving experience progressively under limitations that are gradually lifted over time. Every motorist under the age of 18 in Alaska must first get an instruction permit to practice supervised driving under the state's graduated driver licensing scheme. After turning 16, holding an instruction permit for at least six months, and practicing supervised driving for at least 40 hours, the young driver may apply for a provisional driver's license, which permits the motorist to drive unsupervised under specified conditions. After six months, or when the driver reaches the age of 18, these limitations will be lifted, and the motorist will be able to receive an unrestricted Alaska driver's license. [State of Alaska Driver Manual, Provisional Licenses, Licenses and Permits]","imageName":"","correctAnswer":42417,"isBookmarked":0,"aid":"897#42417#42418#42419","text":"None of the above#Instruction permit, provisional license, driver's license#Instruction permit, driver's license, provisional license#Provisional license, driver's license, instruction permit"},
{"testId":565,"qid":24349,"description":"A driver with a provisional license may transport ________ persons.","explanation":"While an Alaska provisional driver's license is in effect, a motorist may carry siblings as passengers. He or she may not carry additional passengers unless he or she is also transporting a parent, legal guardian, or someone over the age of 21. [State of Alaska Driver Manual, Provisional Licenses, Licenses and Permits]","imageName":"","correctAnswer":11519,"isBookmarked":0,"aid":"11518#11519#11520#11521","text":"friends#siblings#teachers#co-workers"},
{"testId":565,"qid":24350,"description":"Where can you legally drive in Alaska if you have a \"off system\" (off-highway) driver's license?","explanation":"If you have a \"off system\" (off-highway) driver's license, you may only drive on roads that are not linked to the state highway system and are not connected to any route with a daily traffic volume of 499 or more. [\"Guide to Rural Driving Information,\" Alaska Division of Motor Vehicles, http://doa.alaska.gov/dmv/akol/rural], [\"Provisional Licenses, Licenses, and Permits,\" State of Alaska Driver Manual] .htm]","imageName":"","correctAnswer":42420,"isBookmarked":0,"aid":"42420#42421#42422#42423","text":"In areas that are not connected to state public roads#On unpaved roads#Outside metropolitan areas#In areas with less than two residents per square mile"},
{"testId":565,"qid":24351,"description":"In Alaska, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for all persons involved in a single collision.","explanation":"In Alaska, your automobile liability insurance policy must provide at least $50,000 in bodily injury or death coverage for one person in any one accident, $100,000 in bodily injury or death coverage for all individuals in any one accident, and $25,000 in property damage coverage in any one accident. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":12841,"isBookmarked":0,"aid":"3196#12048#12841#42424","text":"$50,000#$25,000#$100,000#$250,000"},
{"testId":565,"qid":24352,"description":"If you ________________, you will immediately fail your driving (road) test.","explanation":"Several scenarios, such as breaking any traffic rules, failing to comply with the test examiner, being involved in a traffic accident, and driving carelessly, constitute automatic failure of the driving (road) test. [Driving, Exams, and the Alaska Driver Manual]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#42425#42426#42427","text":"do any of the above#violate any traffic laws#fail to cooperate with your test examiner#have a traffic accident"},
{"testId":565,"qid":24353,"description":"What are Alaska's Financial Responsibility and Mandatory Insurance laws for?","explanation":"Alaska has Financial Responsibility and Mandatory Insurance Laws in place to protect its drivers from uninsured motorists who may fail to pay for damages or injuries caused in accidents. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":11534,"isBookmarked":0,"aid":"11533#11534#11535#11536","text":"To make drivers pay more money#To protect drivers from uninsured motorists#To protect drivers from out-of-state motorists#To make drivers carry more paperwork"},
{"testId":1032,"qid":24353,"description":"What are Alaska's Financial Responsibility and Mandatory Insurance laws for?","explanation":"Alaska has Financial Responsibility and Mandatory Insurance Laws in place to protect its drivers from uninsured motorists who may fail to pay for damages or injuries caused in accidents. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":11534,"isBookmarked":0,"aid":"11533#11534#11535#11536","text":"To make drivers pay more money#To protect drivers from uninsured motorists#To protect drivers from out-of-state motorists#To make drivers carry more paperwork"},
{"testId":565,"qid":24354,"description":"If you do not have vehicle liability insurance and the DMV considers you may be legally accountable for a traffic accident, your license will be suspended for up to ______year(s).","explanation":"If you do not have vehicle liability insurance and the DMV accuses you of being legally culpable for a traffic collision, your license will be suspended for up to three years. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1032,"qid":24354,"description":"If you do not have vehicle liability insurance and the DMV considers you may be legally accountable for a traffic accident, your license will be suspended for up to ______year(s).","explanation":"If you do not have vehicle liability insurance and the DMV accuses you of being legally culpable for a traffic collision, your license will be suspended for up to three years. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":565,"qid":24355,"description":"How can you get your driver's license reinstated if it has been suspended due to the Financial Responsibility Law?","explanation":"According to Alaska's Financial Responsibility Law, a motorist who causes an accident must pay for the losses and injuries they have caused. Until the driver comes to a financial agreement with the other parties involved in the accident, which may take up to three years, the DMV will suspend the driver's licence. Legal Requirements for Financial Responsibility, Alaska Driver Manual","imageName":"","correctAnswer":42428,"isBookmarked":0,"aid":"42428#42429#42430#42431","text":"Reach a financial settlement with the other parties involved in the accident#Pay a $200 fine#Obtain a vehicle liability insurance policy#Pass the driving (road) test again"},
{"testId":1032,"qid":24355,"description":"How can you get your driver's license reinstated if it has been suspended due to the Financial Responsibility Law?","explanation":"According to Alaska's Financial Responsibility Law, a motorist who causes an accident must pay for the losses and injuries they have caused. Until the driver comes to a financial agreement with the other parties involved in the accident, which may take up to three years, the DMV will suspend the driver's licence. Legal Requirements for Financial Responsibility, Alaska Driver Manual","imageName":"","correctAnswer":42428,"isBookmarked":0,"aid":"42428#42429#42430#42431","text":"Reach a financial settlement with the other parties involved in the accident#Pay a $200 fine#Obtain a vehicle liability insurance policy#Pass the driving (road) test again"},
{"testId":565,"qid":24356,"description":"Who is responsible for insuring all Alaska roadway vehicles under the state's mandatory law?","explanation":"According to Alaska's Mandatory Insurance Law, the vehicle's liability insurance must be carried by either the driver or the owner. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":11542,"isBookmarked":0,"aid":"9808#11526#11541#11542","text":"The driver#The state of Alaska#The owner#Either the owner or the driver"},
{"testId":1032,"qid":24356,"description":"Who is responsible for insuring all Alaska roadway vehicles under the state's mandatory law?","explanation":"According to Alaska's Mandatory Insurance Law, the vehicle's liability insurance must be carried by either the driver or the owner. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":11542,"isBookmarked":0,"aid":"9808#11526#11541#11542","text":"The driver#The state of Alaska#The owner#Either the owner or the driver"},
{"testId":565,"qid":24357,"description":"If you are stopped by a law enforcement officer but cannot offer proof of automobile liability insurance,:","explanation":"If you are pulled over by a law enforcement officer and cannot present evidence of vehicle liability insurance, the officer may give you a penalty or possibly have your car impounded under Alaska's Mandatory Insurance Law. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":22066,"isBookmarked":0,"aid":"22066#42432#42433#42434","text":"your vehicle may be impounded#you may be arrested#you may be let off with a warning#your license may be revoked"},
{"testId":1032,"qid":24357,"description":"If you are stopped by a law enforcement officer but cannot offer proof of automobile liability insurance,:","explanation":"If you are pulled over by a law enforcement officer and cannot present evidence of vehicle liability insurance, the officer may give you a penalty or possibly have your car impounded under Alaska's Mandatory Insurance Law. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":22066,"isBookmarked":0,"aid":"22066#42432#42433#42434","text":"your vehicle may be impounded#you may be arrested#you may be let off with a warning#your license may be revoked"},
{"testId":565,"qid":24358,"description":"If you were in an accident that caused property damage worth more than ______, you must show proof of liability insurance to the DMV.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":42436,"isBookmarked":0,"aid":"42435#42436#42437#42438","text":"$251#$501#$551#$601"},
{"testId":1032,"qid":24358,"description":"If you were in an accident that caused property damage worth more than ______, you must show proof of liability insurance to the DMV.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":42436,"isBookmarked":0,"aid":"42435#42436#42437#42438","text":"$251#$501#$551#$601"},
{"testId":565,"qid":24359,"description":"If you are involved in an accident that results in death or injury, you must submit the DMV with proof of vehicle liability insurance within 30 days.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"202006141611292838.jpg","correctAnswer":23884,"isBookmarked":0,"aid":"23884#23886#35988#40739","text":"15 days#30 days#7 days#5 days"},
{"testId":565,"qid":24360,"description":"If you are involved in an accident that causes death or injury and are uninsured, your license will be suspended for at least one year.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"202006141611292838.jpg","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#27364","text":"30 days#60 days#90 days#45 days"},
{"testId":565,"qid":24361,"description":"What must a new Alaska resident do if he or she possesses an out-of-state driver's license that has been suspended or revoked in order to receive an Alaska driver's license?","explanation":"Anyone whose driving privileges are presently suspended or revoked in another state will not be issued an Alaska driver's license. If a new Alaska resident possesses a suspended or revoked out-of-state driver's license, he or she must receive documentation (such as a certified driving record from that state) that the suspension or revocation term has already ended. [Certified Driving Records, Additional Information, Alaska Driver Manual]","imageName":"","correctAnswer":42441,"isBookmarked":0,"aid":"11554#42439#42440#42441","text":"Pay a special application fee#Obtain approval from the state that issued the out-of-state license#Pass the Alaska written knowledge and driving (road) tests#Obtain proof that the suspension or revocation period has ended"},
{"testId":1032,"qid":24361,"description":"What must a new Alaska resident do if he or she possesses an out-of-state driver's license that has been suspended or revoked in order to receive an Alaska driver's license?","explanation":"Anyone whose driving privileges are presently suspended or revoked in another state will not be issued an Alaska driver's license. If a new Alaska resident possesses a suspended or revoked out-of-state driver's license, he or she must receive documentation (such as a certified driving record from that state) that the suspension or revocation term has already ended. [Certified Driving Records, Additional Information, Alaska Driver Manual]","imageName":"","correctAnswer":42441,"isBookmarked":0,"aid":"11554#42439#42440#42441","text":"Pay a special application fee#Obtain approval from the state that issued the out-of-state license#Pass the Alaska written knowledge and driving (road) tests#Obtain proof that the suspension or revocation period has ended"},
{"testId":565,"qid":24362,"description":"Which of the following infractions has the fewest points?","explanation":"Tailgating, failing to stop at a stop sign, failing to yield after stopping at a stop sign, failing to yield at a yield sign, and failing to yield to oncoming traffic while turning left are all punishable by four points. \"All other moving offenses\" (i.e., violations with no specified point value), such as backing up on a motorway, are worth two points apiece. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42443,"isBookmarked":0,"aid":"864#39081#42442#42443","text":"Tailgating#Failing to yield at a yield sign#Failing to stop at a stop sign#Backing up on a freeway"},
{"testId":1032,"qid":24362,"description":"Which of the following infractions has the fewest points?","explanation":"Tailgating, failing to stop at a stop sign, failing to yield after stopping at a stop sign, failing to yield at a yield sign, and failing to yield to oncoming traffic while turning left are all punishable by four points. \"All other moving offenses\" (i.e., violations with no specified point value), such as backing up on a motorway, are worth two points apiece. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42443,"isBookmarked":0,"aid":"864#39081#42442#42443","text":"Tailgating#Failing to yield at a yield sign#Failing to stop at a stop sign#Backing up on a freeway"},
{"testId":565,"qid":24363,"description":"If you were in an accident, you must ________ unless the accident was investigated by a police officer.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":42444,"isBookmarked":0,"aid":"20669#20671#20672#42444","text":"call local authorities for instructions#collect the other drivers' information#take photos of the damage#file a crash report with the DMV"},
{"testId":565,"qid":24364,"description":"Demerit point system is designed to punish:","explanation":"The Alaska demerit point system is intended to identify problem drivers and prevent them from harming others. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":42447,"isBookmarked":0,"aid":"42445#42446#42447#42448","text":"wealthy drivers#young drivers#problem drivers#truck drivers"},
{"testId":1032,"qid":24364,"description":"Demerit point system is designed to punish:","explanation":"The Alaska demerit point system is intended to identify problem drivers and prevent them from harming others. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":42447,"isBookmarked":0,"aid":"42445#42446#42447#42448","text":"wealthy drivers#young drivers#problem drivers#truck drivers"},
{"testId":565,"qid":24365,"description":"Your license will be suspended or cancelled if you accumulate:","explanation":"If you accrue 12 points in 12 months or 18 points in 24 months, your license will be suspended or revoked, regardless of the difficulty this may cause you. You will also not be able to get a restricted \"work purpose\" license. [A Note on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11569,"isBookmarked":0,"aid":"11569#11570#11571#11572","text":"12 points in 12 months#12 points in 24 months#10 points in 6 months#10 points in 12 months"},
{"testId":1032,"qid":24365,"description":"Your license will be suspended or cancelled if you accumulate:","explanation":"If you accrue 12 points in 12 months or 18 points in 24 months, your license will be suspended or revoked, regardless of the difficulty this may cause you. You will also not be able to get a restricted \"work purpose\" license. [A Note on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11569,"isBookmarked":0,"aid":"11569#11570#11571#11572","text":"12 points in 12 months#12 points in 24 months#10 points in 6 months#10 points in 12 months"},
{"testId":565,"qid":24366,"description":"Your license will be suspended or cancelled if you accumulate:","explanation":"If you accrue 12 points in 12 months or 18 points in 24 months, your license will be suspended or revoked, regardless of the difficulty this may cause you. You will also not be able to get a restricted \"work purpose\" license. [A Note on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11573,"isBookmarked":0,"aid":"11573#11574#42449#42450","text":"18 points in 24 months#18 points in 36 months#14 points in 20 months#24 points in 36 months"},
{"testId":1032,"qid":24366,"description":"Your license will be suspended or cancelled if you accumulate:","explanation":"If you accrue 12 points in 12 months or 18 points in 24 months, your license will be suspended or revoked, regardless of the difficulty this may cause you. You will also not be able to get a restricted \"work purpose\" license. [A Note on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11573,"isBookmarked":0,"aid":"11573#11574#42449#42450","text":"18 points in 24 months#18 points in 36 months#14 points in 20 months#24 points in 36 months"},
{"testId":565,"qid":24368,"description":"You will not be able to apply for a limited ___________ license if your license was suspended or revoked for earning too many points.","explanation":"A motorist who has had his or her license suspended or revoked due to certain offences may apply for a restricted \"work purpose\" license. This sort of license allows the motorist to drive to and from work while imposing limitations designed to safeguard the general public. You may not apply for a work purpose license if your license has been suspended or revoked due to excessive point accumulation. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11581,"isBookmarked":0,"aid":"11580#11581#11582#11583","text":"school purpose#work purpose#parental#temporary"},
{"testId":1032,"qid":24368,"description":"You will not be able to apply for a limited ___________ license if your license was suspended or revoked for earning too many points.","explanation":"A motorist who has had his or her license suspended or revoked due to certain offences may apply for a restricted \"work purpose\" license. This sort of license allows the motorist to drive to and from work while imposing limitations designed to safeguard the general public. You may not apply for a work purpose license if your license has been suspended or revoked due to excessive point accumulation. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":11581,"isBookmarked":0,"aid":"11580#11581#11582#11583","text":"school purpose#work purpose#parental#temporary"},
{"testId":565,"qid":24369,"description":"If you accumulate ___ points in less than a year, the DMV will send you a warning letter.","explanation":"If you have already acquired half of the points that would result in your license being suspended (i.e., 12 points in 12 months or 18 points in 24 months), the DMV will issue you a warning letter recommending you to improve your driving skills before losing your driving privileges. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1032,"qid":24369,"description":"If you accumulate ___ points in less than a year, the DMV will send you a warning letter.","explanation":"If you have already acquired half of the points that would result in your license being suspended (i.e., 12 points in 12 months or 18 points in 24 months), the DMV will issue you a warning letter recommending you to improve your driving skills before losing your driving privileges. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":565,"qid":24370,"description":"If you _____, two points will be taken off of your driving record.聽:","explanation":null,"imageName":"","correctAnswer":42455,"isBookmarked":0,"aid":"42455#42456#42457#42458","text":"complete a defensive driving course#pass the driving (road) test again#purchase a safer vehicle#increase your vehicle's liability insurance coverage"},
{"testId":565,"qid":24371,"description":"If a driver with a provisional license accumulates ___ points in a year, he or she must undergo a driver improvement course.","explanation":"If a motorist with a provisional license accrues half the points that would result in license suspension, he or she must take an authorized driver improvement course in addition to any additional penalties for the pointable offences. (If a motorist collects 12 points in 12 months or 18 points in 24 months, his or her license will be suspended.) [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6390#6916#11342","text":"5#4#6#9"},
{"testId":565,"qid":24373,"description":"How many points will be added to your Alaska driving record if you are found guilty of a traffic offence in another state?","explanation":"If you are found guilty of a traffic infraction in another state, you will get the same amount of points on your Alaska driving record as if the offense happened in Alaska. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42461,"isBookmarked":0,"aid":"17889#42461#42462#42463","text":"None#The same number of points as if the violation had occurred in Alaska#Half the points if the violation had occurred in Alaska#The number of points prescribed by that state's laws"},
{"testId":1032,"qid":24373,"description":"How many points will be added to your Alaska driving record if you are found guilty of a traffic offence in another state?","explanation":"If you are found guilty of a traffic infraction in another state, you will get the same amount of points on your Alaska driving record as if the offense happened in Alaska. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42461,"isBookmarked":0,"aid":"17889#42461#42462#42463","text":"None#The same number of points as if the violation had occurred in Alaska#Half the points if the violation had occurred in Alaska#The number of points prescribed by that state's laws"},
{"testId":565,"qid":24374,"description":"Driving with a suspended or revoked license will add ___ points to your driving record.","explanation":"If you are convicted of driving while your license is suspended or revoked, you will get 10 points on your license. (The maximum amount of points for any one penalty in Alaska is 10.) You may also face criminal consequences such as a fine and incarceration. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6390#6916#11342","text":"10#4#6#9"},
{"testId":1032,"qid":24374,"description":"Driving with a suspended or revoked license will add ___ points to your driving record.","explanation":"If you are convicted of driving while your license is suspended or revoked, you will get 10 points on your license. (The maximum amount of points for any one penalty in Alaska is 10.) You may also face criminal consequences such as a fine and incarceration. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6390#6916#11342","text":"10#4#6#9"},
{"testId":565,"qid":24375,"description":"Except for_______, all of the following offences carry a 10 point penalty.","explanation":"DUI, vehicular manslaughter, negligent murder in a vehicle, and escaping from police in a vehicle are all highly severe crimes in Alaska, each carrying 10 points. (The maximum amount of points for any one penalty in Alaska is 10.) Six points are deducted for failing to stop for a halted school bus. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42464,"isBookmarked":0,"aid":"30353#39801#42464#42465","text":"vehicular manslaughter#driving under the influence (DUI)#failing to stop for a stopped school bus#fleeing from the police"},
{"testId":1032,"qid":24375,"description":"Except for_______, all of the following offences carry a 10 point penalty.","explanation":"DUI, vehicular manslaughter, negligent murder in a vehicle, and escaping from police in a vehicle are all highly severe crimes in Alaska, each carrying 10 points. (The maximum amount of points for any one penalty in Alaska is 10.) Six points are deducted for failing to stop for a halted school bus. [Alaska Driver Manual, Driver's License Point Assignment, Points]","imageName":"","correctAnswer":42464,"isBookmarked":0,"aid":"30353#39801#42464#42465","text":"vehicular manslaughter#driving under the influence (DUI)#failing to stop for a stopped school bus#fleeing from the police"},
{"testId":565,"qid":24376,"description":"Which of the following is the most serious speeding offense?","explanation":null,"imageName":"","correctAnswer":42467,"isBookmarked":0,"aid":"42466#42467#42468#42469","text":"Driving at 10 mph over the speed limit#Speeding in a school zone#Driving at 15 mph over the speed limit#Driving at 5 mph over the speed limit"},
{"testId":1032,"qid":24376,"description":"Which of the following is the most serious speeding offense?","explanation":null,"imageName":"","correctAnswer":42467,"isBookmarked":0,"aid":"42466#42467#42468#42469","text":"Driving at 10 mph over the speed limit#Speeding in a school zone#Driving at 15 mph over the speed limit#Driving at 5 mph over the speed limit"},
{"testId":565,"qid":24377,"description":"If your Alaska driver's license is suspended or revoked, you must surrender it to :","explanation":"If you have a suspended or revoked Alaska driver's license, you must surrender it to a Division of Motor Vehicles (DMV) office. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42470,"isBookmarked":0,"aid":"11609#11610#42470#42471","text":"the local police station#your local courthouse#the Alaska DMV#the Alaska Department of Public Safety"},
{"testId":1032,"qid":24377,"description":"If your Alaska driver's license is suspended or revoked, you must surrender it to :","explanation":"If you have a suspended or revoked Alaska driver's license, you must surrender it to a Division of Motor Vehicles (DMV) office. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42470,"isBookmarked":0,"aid":"11609#11610#42470#42471","text":"the local police station#your local courthouse#the Alaska DMV#the Alaska Department of Public Safety"},
{"testId":565,"qid":24378,"description":"In Alaska, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for one person involved in a single collision.","explanation":"In Alaska, your automobile liability insurance policy must provide at least $50,000 in bodily injury or death coverage for one person in any one accident, $100,000 in bodily injury or death coverage for all individuals in any one accident, and $25,000 in property damage coverage in any one accident. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12047#12048#12841","text":"$50,000#$15,000#$25,000#$100,000"},
{"testId":565,"qid":24380,"description":"Which of the following violations will result in license revocation if you are convicted?","explanation":"Your license will be cancelled if you are convicted of particularly severe crimes such as reckless driving, perjuring yourself before the DMV, refusing to submit to a chemical test for blood alcohol, leaving the scene of an accident (hit and run), or escaping in a vehicle to evade arrest. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11621#42475#42476","text":"Any of the above#Reckless driving#Refusing to submit to a chemical test for blood alcohol#Giving false information to the DMV"},
{"testId":1032,"qid":24380,"description":"Which of the following violations will result in license revocation if you are convicted?","explanation":"Your license will be cancelled if you are convicted of particularly severe crimes such as reckless driving, perjuring yourself before the DMV, refusing to submit to a chemical test for blood alcohol, leaving the scene of an accident (hit and run), or escaping in a vehicle to evade arrest. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11621#42475#42476","text":"Any of the above#Reckless driving#Refusing to submit to a chemical test for blood alcohol#Giving false information to the DMV"},
{"testId":565,"qid":24381,"description":"If you're arrested for DUI with a BAC of ___ or higher, your license will be revoked.","explanation":"A blood alcohol content (BAC) of 0.08 percent or above is adequate proof in Alaska that you have been driving while intoxicated (DUI). Your driver's license will be revoked. This is a civil punishment, not a criminal penalty. It may take effect even before your trial. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#11768","text":"0.08%#0.04%#0.06%#0.10%"},
{"testId":1032,"qid":24381,"description":"If you're arrested for DUI with a BAC of ___ or higher, your license will be revoked.","explanation":"A blood alcohol content (BAC) of 0.08 percent or above is adequate proof in Alaska that you have been driving while intoxicated (DUI). Your driver's license will be revoked. This is a civil punishment, not a criminal penalty. It may take effect even before your trial. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#11768","text":"0.08%#0.04%#0.06%#0.10%"},
{"testId":565,"qid":24382,"description":"If your license is suspended again within _______ for too many points, it will be revoked.","explanation":"If your license is suspended for too many points twice in 24 months, it will be revoked. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":1320,"isBookmarked":0,"aid":"1320#1321#26635#42477","text":"two years#five years#four years#three years"},
{"testId":1032,"qid":24382,"description":"If your license is suspended again within _______ for too many points, it will be revoked.","explanation":"If your license is suspended for too many points twice in 24 months, it will be revoked. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":1320,"isBookmarked":0,"aid":"1320#1321#26635#42477","text":"two years#five years#four years#three years"},
{"testId":565,"qid":24383,"description":"If a motorist under 21 is discovered to have purchased alcohol using a fake driver's license,:","explanation":null,"imageName":"","correctAnswer":28808,"isBookmarked":0,"aid":"24987#28808#42478#42479","text":"his or her license will be suspended#his or her license will be revoked#he or she will be arrested#he or she must pay a fine of $200"},
{"testId":1032,"qid":24383,"description":"If a motorist under 21 is discovered to have purchased alcohol using a fake driver's license,:","explanation":null,"imageName":"","correctAnswer":28808,"isBookmarked":0,"aid":"24987#28808#42478#42479","text":"his or her license will be suspended#his or her license will be revoked#he or she will be arrested#he or she must pay a fine of $200"},
{"testId":565,"qid":24384,"description":"The Alaska DMV has the ability to _________ a driver's license regardless of any court's verdict.","explanation":"The Alaska DMV may revoke, suspend, or terminate your driving privileges. Administrative sanctions, rather than criminal penalties, may be imposed. That is, the DMV may take such steps even before a judge rules on the allegations brought against you. Even if you are acquitted in court, you must still pay a reinstatement fee to the DMV to have your driving privileges restored. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":11635,"isBookmarked":0,"aid":"11632#11633#11634#11635","text":"revoke#suspend#cancel#revoke, suspend, or cancel"},
{"testId":1032,"qid":24384,"description":"The Alaska DMV has the ability to _________ a driver's license regardless of any court's verdict.","explanation":"The Alaska DMV may revoke, suspend, or terminate your driving privileges. Administrative sanctions, rather than criminal penalties, may be imposed. That is, the DMV may take such steps even before a judge rules on the allegations brought against you. Even if you are acquitted in court, you must still pay a reinstatement fee to the DMV to have your driving privileges restored. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":11635,"isBookmarked":0,"aid":"11632#11633#11634#11635","text":"revoke#suspend#cancel#revoke, suspend, or cancel"},
{"testId":565,"qid":24385,"description":"If you have had your driver's license revoked, you must pay _______ before you may receive a new one.","explanation":"If your driver's license has been revoked, you must pay a reinstatement fee to the DMV before you may regain your driving rights. The reinstatement price is determined by the crimes that led to your license being revoked in the first place. You'll need to apply for a new license, which will require you to pay a licensing cost. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42482,"isBookmarked":0,"aid":"12293#42480#42481#42482","text":"a fine#a renewal fee#an application fee#a reinstatement fee"},
{"testId":1032,"qid":24385,"description":"If you have had your driver's license revoked, you must pay _______ before you may receive a new one.","explanation":"If your driver's license has been revoked, you must pay a reinstatement fee to the DMV before you may regain your driving rights. The reinstatement price is determined by the crimes that led to your license being revoked in the first place. You'll need to apply for a new license, which will require you to pay a licensing cost. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42482,"isBookmarked":0,"aid":"12293#42480#42481#42482","text":"a fine#a renewal fee#an application fee#a reinstatement fee"},
{"testId":565,"qid":24386,"description":"According to Alaska's Implied Consent Law, if you drive in Alaska, you agree to:","explanation":"By driving a motor vehicle in Alaska, you have implicitly accepted to being tested for alcohol content at the request of a law enforcement official, according to Alaska's Implied Consent Law. [Implied Consent, Alaska Driver Handbook]","imageName":"","correctAnswer":42483,"isBookmarked":0,"aid":"29272#35227#35228#42483","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol concentration"},
{"testId":1032,"qid":24386,"description":"According to Alaska's Implied Consent Law, if you drive in Alaska, you agree to:","explanation":"By driving a motor vehicle in Alaska, you have implicitly accepted to being tested for alcohol content at the request of a law enforcement official, according to Alaska's Implied Consent Law. [Implied Consent, Alaska Driver Handbook]","imageName":"","correctAnswer":42483,"isBookmarked":0,"aid":"29272#35227#35228#42483","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol concentration"},
{"testId":565,"qid":24387,"description":"If you've been arrested for DUI, a law enforcement official may ask you to submit to :","explanation":"If you are properly detained for DUI, a law enforcement officer may request that you submit to a breath test to determine your alcohol content. The Supreme Court concluded in Birchfield v. North Dakota (2016) that state Implied Consent rules cannot oblige you to submit to a blood test without a search warrant. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42485,"isBookmarked":0,"aid":"42484#42485#42486#42487","text":"a chemical sweat test#a chemical breath test#a chemical urine test#a chemical blood test"},
{"testId":1032,"qid":24387,"description":"If you've been arrested for DUI, a law enforcement official may ask you to submit to :","explanation":"If you are properly detained for DUI, a law enforcement officer may request that you submit to a breath test to determine your alcohol content. The Supreme Court concluded in Birchfield v. North Dakota (2016) that state Implied Consent rules cannot oblige you to submit to a blood test without a search warrant. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42485,"isBookmarked":0,"aid":"42484#42485#42486#42487","text":"a chemical sweat test#a chemical breath test#a chemical urine test#a chemical blood test"},
{"testId":565,"qid":24390,"description":"Which of the following infractions has the fewest points?","explanation":"The highly severe offences incurring 10 points each include refusing to submit to an alcohol breath test, assault with a motor vehicle, and racing on a public road. The hit-and-run offence carries nine points for leaving the scene of an accident without stopping. (However, failing to stop after leaving an accident site is a crime that carries penalties and prison time.) [Alaska Driver Manual: Driver's License Point Assignment, Points]","imageName":"","correctAnswer":39787,"isBookmarked":0,"aid":"37556#39787#42492#42493","text":"Drag racing on a public road#Leaving the scene of an accident (hit and run)#Assault with a motor vehicle#Refusing to take a breath test for alcohol"},
{"testId":1032,"qid":24390,"description":"Which of the following infractions has the fewest points?","explanation":"The highly severe offences incurring 10 points each include refusing to submit to an alcohol breath test, assault with a motor vehicle, and racing on a public road. The hit-and-run offence carries nine points for leaving the scene of an accident without stopping. (However, failing to stop after leaving an accident site is a crime that carries penalties and prison time.) [Alaska Driver Manual: Driver's License Point Assignment, Points]","imageName":"","correctAnswer":39787,"isBookmarked":0,"aid":"37556#39787#42492#42493","text":"Drag racing on a public road#Leaving the scene of an accident (hit and run)#Assault with a motor vehicle#Refusing to take a breath test for alcohol"},
{"testId":565,"qid":24391,"description":"The total cost to you for a DUI conviction can be about:","explanation":"When attorney's expenses, court costs, incarceration, penalties, and increased insurance rates are factored in, your first DUI conviction may cost you up to $22,000, according to the State of Alaska Driver Manual. This does not include the negative impact of being identified as a drunk driver on your future work chances. [Consequences; Alcohol, Drugs, and Driving; Alaska Driver Handbook]","imageName":"","correctAnswer":31402,"isBookmarked":0,"aid":"25033#25036#31397#31402","text":"$2,000#$5,000#$10,000#$20,000"},
{"testId":1032,"qid":24391,"description":"The total cost to you for a DUI conviction can be about:","explanation":"When attorney's expenses, court costs, incarceration, penalties, and increased insurance rates are factored in, your first DUI conviction may cost you up to $22,000, according to the State of Alaska Driver Manual. This does not include the negative impact of being identified as a drunk driver on your future work chances. [Consequences; Alcohol, Drugs, and Driving; Alaska Driver Handbook]","imageName":"","correctAnswer":31402,"isBookmarked":0,"aid":"25033#25036#31397#31402","text":"$2,000#$5,000#$10,000#$20,000"},
{"testId":565,"qid":24392,"description":"If you refuse a chemical test for alcohol six times, you face which minimum penalty?","explanation":null,"imageName":"","correctAnswer":42497,"isBookmarked":0,"aid":"42494#42495#42496#42497","text":"20 days in jail and a one-year license revocation#60 days in jail and a three-year license revocation#120 days in jail and a five-year license revocation#360 days in jail and a five-year license revocation"},
{"testId":1032,"qid":24392,"description":"If you refuse a chemical test for alcohol six times, you face which minimum penalty?","explanation":null,"imageName":"","correctAnswer":42497,"isBookmarked":0,"aid":"42494#42495#42496#42497","text":"20 days in jail and a one-year license revocation#60 days in jail and a three-year license revocation#120 days in jail and a five-year license revocation#360 days in jail and a five-year license revocation"},
{"testId":565,"qid":24393,"description":"If you're convicted of DUI time聽聽within 10 years, your license will be permanently聽revoked.","explanation":"A third DUI violation within ten years is considered a felony in Alaska. If you are convicted, your license will be permanently revoked, you will be fined at least $10,000, and you will be sentenced to at least 120 days in prison. [Court Penalties and Revocations; Alcohol, Drugs, and Driving; Alaska Driver Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":1032,"qid":24393,"description":"If you're convicted of DUI time聽聽within 10 years, your license will be permanently聽revoked.","explanation":"A third DUI violation within ten years is considered a felony in Alaska. If you are convicted, your license will be permanently revoked, you will be fined at least $10,000, and you will be sentenced to at least 120 days in prison. [Court Penalties and Revocations; Alcohol, Drugs, and Driving; Alaska Driver Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":565,"qid":24394,"description":"According to the Alaska Driver Manual, alcohol is involved in nearly ____ of all traffic deaths each year.","explanation":"In 2011, (the most current year of collision statistics on the Alaska Department of Transportation website), alcohol was implicated in 47 percent of all road deaths. In 2017, the national average was 29 percent. [Alcohol, Drugs, and Driving; State of Alaska Driver Manual], [\"Motor Vehicle Crash Data\"; Alaska Department of Transportation; http://www.dot.alaska.gov/stwdplng/transdata/crash.shtml], [\"Alcohol-Impaired Driving\"; National Highway Traffic Safety Administration; https://crashstats.nhtsa.nht","imageName":"","correctAnswer":11666,"isBookmarked":0,"aid":"11664#11665#11666#11667","text":"10%#25%#45%#50%"},
{"testId":565,"qid":24395,"description":"If convicted of DUI for the first time, you will be fined at least:","explanation":"If you are convicted of DUI or rejecting a chemical test for the first time, you will be fined at least $1,500 and sentenced to at least three days in prison. Your license will be suspended for at least 90 days. Following the restoration of your driving privileges, you will be required to install an ignition interlock device (IID) in every vehicle you drive for at least six months. The IID will detect your breath alcohol content and prohibit your car from starting if it exceeds a certain level. Finally, you will get 10 points on your driving record (just two points short of triggering a license suspension). [Ignition Interlock Device (IID); Alcohol, Drugs, and Driving; State of Alaska Driver Manual], [Court Revocations and Penalties; Alcohol, Drugs, and Driving; State of Alaska Driver Manual]","imageName":"","correctAnswer":11668,"isBookmarked":0,"aid":"1799#11668#11669#11670","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":1032,"qid":24395,"description":"If convicted of DUI for the first time, you will be fined at least:","explanation":"If you are convicted of DUI or rejecting a chemical test for the first time, you will be fined at least $1,500 and sentenced to at least three days in prison. Your license will be suspended for at least 90 days. Following the restoration of your driving privileges, you will be required to install an ignition interlock device (IID) in every vehicle you drive for at least six months. The IID will detect your breath alcohol content and prohibit your car from starting if it exceeds a certain level. Finally, you will get 10 points on your driving record (just two points short of triggering a license suspension). [Ignition Interlock Device (IID); Alcohol, Drugs, and Driving; State of Alaska Driver Manual], [Court Revocations and Penalties; Alcohol, Drugs, and Driving; State of Alaska Driver Manual]","imageName":"","correctAnswer":11668,"isBookmarked":0,"aid":"1799#11668#11669#11670","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":566,"qid":24396,"description":"Which of the following moving traffic offenses has the LEAST number of points?","explanation":"DUI, aggressive driving, reckless driving, and racing on public roadways all incur eight points in Arizona's point system. Six points are deducted for leaving the scene of an accident. However, keep in mind that they are all criminal acts. If you are convicted of any of these, you might face fines and prison time in addition to points on your license. [Arizona Driver License Manual, Suspension and Traffic School, Driver Improvement, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":39787,"isBookmarked":0,"aid":"866#11621#24985#39787","text":"Aggressive driving#Reckless driving#Driving under the influence (DUI)#Leaving the scene of an accident (hit and run)"},
{"testId":1033,"qid":24396,"description":"Which of the following moving traffic offenses has the LEAST number of points?","explanation":"DUI, aggressive driving, reckless driving, and racing on public roadways all incur eight points in Arizona's point system. Six points are deducted for leaving the scene of an accident. However, keep in mind that they are all criminal acts. If you are convicted of any of these, you might face fines and prison time in addition to points on your license. [Arizona Driver License Manual, Suspension and Traffic School, Driver Improvement, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":39787,"isBookmarked":0,"aid":"866#11621#24985#39787","text":"Aggressive driving#Reckless driving#Driving under the influence (DUI)#Leaving the scene of an accident (hit and run)"},
{"testId":566,"qid":24397,"description":"Which of the following is the classification of a graduated driving license?","explanation":"A Class G graduated driving license is awarded in Arizona to applicants who are at least 16 but under the age of 18. It allows you to drive any vehicle that does not need a motorbike or commercial license. [Arizona Driver License Manual, Classes of Licenses, Graduated License (Class G)]","imageName":"","correctAnswer":11689,"isBookmarked":0,"aid":"322#325#11689#42837","text":"A#D#G#M"},
{"testId":566,"qid":24398,"description":"For much duration an instruction permit is valid?","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":566,"qid":24399,"description":"While holding a learner's permit, your driving must be supervised by a driver who:","explanation":"While you have an instruction permit, you must drive with someone who is at least 21 years old, has a Class A, B, C, or D driver license, and is sitting next to you in the vehicle. [Instruction Permit, License Classification, Arizona Driver License Manual]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#40068#41804#43549","text":"meets all of the above requirements#is seated next to you#is at least 21 years old#holds a Class A, B, C, or D driver license"},
{"testId":566,"qid":24400,"description":"You must be _____ years old to apply for a Class G graduated license.","explanation":"Applicants who are at least 16 but under 18 are granted an Arizona Class G graduated driving licence. Applicants who are at least 18 years old are granted a Class D operator licence. [Classes of Licenses, Arizona Driver License Manual, Graduated License (Class G)]","imageName":"","correctAnswer":43550,"isBookmarked":0,"aid":"43550#43551#43552#43553","text":"at least 16 but less than 18#at least 16 but less than 21#at least 15 but less than 18#at least 15 but less than 21"},
{"testId":566,"qid":24401,"description":"The holder of a graduated driving license may not drive unattended between _________  for the first six months, except to and from job, school, or church.","explanation":"For the first six months, a graduated driver license holder may not drive between midnight and 5:00 a.m. unless accompanied by a parent or legal guardian who has a valid Class A, B, C, or D driving license. The only exceptions are when the graduated license holder is driving to or from church, school, or work, or in an emergency. [Arizona Driver License Manual, Classes of Licenses, Graduated License (Class G)]","imageName":"","correctAnswer":11797,"isBookmarked":0,"aid":"11797#12456#35454#43554","text":"12:00 a.m. and 5:00 a.m#10:00 p.m. and 4:00 a.m#12:00 a.m. and 6:00 a.m#1:00 a.m. and 7:00 a.m"},
{"testId":566,"qid":24402,"description":"How many unrelated passengers under the age of 18 can a graduated driver license holder transport within the first six months?","explanation":"For the first six months, a graduated driving license holder may not transport more than one passenger under the age of 18, unless the underage passengers are the driver's siblings or the driver's parent or legal guardian is seated next to the driver. [Arizona Driver License Manual, Classes of Licenses, Graduated License (Class G)]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6469#17889","text":"2#3#1#None"},
{"testId":566,"qid":24403,"description":"For the first six months, a graduated driving license holder may carry passengers under the age of 18 who are the driver's  :","explanation":"For the first six months, a graduated driving license holder may not transport more than one passenger under the age of 18, unless the passengers are the driver's siblings or the driver's parent or legal guardian is seated next to the driver. [Arizona Driver License Manual, Classes of Licenses, Graduated License (Class G)]","imageName":"","correctAnswer":43555,"isBookmarked":0,"aid":"43555#43556#43557#43558","text":"siblings#children#cousins#friends"},
{"testId":566,"qid":24404,"description":"If a graduated driver license holder is convicted of a second traffic offense, which of the following additional penalties will he or she face?","explanation":"If a graduated driver license holder is convicted of a second traffic infringement, his or her license will be suspended for three months and the offense will be recorded on his or her driving record. This suspension is in addition to any penalty imposed for the traffic offence. [Arizona Driver License Manual, Penalties, Graduated License (Class G), License Classes]","imageName":"","correctAnswer":43559,"isBookmarked":0,"aid":"43559#43560#43561#43562","text":"A three-month suspension of driving privileges#A six-month suspension of driving privileges#A twelve-month suspension of driving privileges#Suspension of driving privileges until the driver turns 18"},
{"testId":566,"qid":24405,"description":"In Arizona, your vehicle liability insurance policy must have at least ______ in property damage coverage for any single accident.","explanation":"In Arizona, your car liability insurance policy must contain at least $10,000 in property damage coverage for every one collision. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11704#12047","text":"$5,000#$10,000#$7,500#$15,000"},
{"testId":566,"qid":24407,"description":"A learner driver under the age of 18 with an instruction permit must either finish an MVD-approved driver education program or log at least _______ hours of supervised driving practice before applying for a graduated driver license.","explanation":"Before applying for a graduated driver licence, a learner driver under 18 years of age with an instruction permit must either complete an MVD-approved driver education programme or record at least 30 hours of supervised driving practise, including ten hours at night. The motorist's parent or legal guardian must attest in writing that the driver has obtained this much driving experience. [Classes of Licenses, Arizona Driver License Manual, Graduated License (Class G)]","imageName":"","correctAnswer":11484,"isBookmarked":0,"aid":"11483#11484#11694#11695","text":"15 hours#30 hours#20 hours#40 hours"},
{"testId":566,"qid":24408,"description":"If a driver is under the age of ____, the parent or legal guardian who signed the driver's application can cancel the driver's instruction permit or driver license.","explanation":"If you are under the age of 18, your parent or legal guardian must sign your application for an instruction permit or driving license. Until you reach the age of 18, whomever signed the application or took responsibility for your driving may have your license revoked. He or she might do so by submitting to the MVD a notarized letter or the form \"Driver License/ID Cancellation Request.\" [Parent/Guardian Approval for Under-18 Applicants, Graduated License (Class G), License Classes, Arizona Driver License Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1033,"qid":24408,"description":"If a driver is under the age of ____, the parent or legal guardian who signed the driver's application can cancel the driver's instruction permit or driver license.","explanation":"If you are under the age of 18, your parent or legal guardian must sign your application for an instruction permit or driving license. Until you reach the age of 18, whomever signed the application or took responsibility for your driving may have your license revoked. He or she might do so by submitting to the MVD a notarized letter or the form \"Driver License/ID Cancellation Request.\" [Parent/Guardian Approval for Under-18 Applicants, Graduated License (Class G), License Classes, Arizona Driver License Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":566,"qid":24409,"description":"Before you can get a Class D operator (driver) license, you must be at least ___ years old.","explanation":"You must be at least 18 years old to receive a Class D operator license. [Class D Operator License, Classes of Licenses, Arizona Driver License Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":566,"qid":24410,"description":"When a Class G graduated driver license holder reaches the age of 18, what must he or she do to exchange the graduated license for a Class D operator license?","explanation":"When a graduated driver license holder reaches the age of 18, he or she will have the same driving rights as a \"Under 21\" (vertically orientated) Class D license. The motorist may, however, swap the Class G license for an Under 21 Class D license by paying the MVD a duplicate licensing cost (currently $12). When the driver reaches the age of 21, he or she may exchange the Under 21 Class D license for a normal Class D license by paying the MVD a duplicate licensing fee. [Driver Services: Teen Drivers: Under 21,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-services/teen-drivers/Under-21]","imageName":"","correctAnswer":43564,"isBookmarked":0,"aid":"23118#42560#43563#43564","text":"Call the MVD#Nothing#Obtain approval in writing from his or her parent or legal guardian#Pay a duplicate license fee"},
{"testId":566,"qid":24411,"description":"Which of the following vehicle must have automobile liability insurance in Arizona?","explanation":"Every motor vehicle operating on Arizona's highways is required by law to be insured by one of the statutory types of financial responsibility, most often known as liability insurance. Automobiles, trucks, motorcycles, mopeds, and even powered golf carts are subject to this regulation. [Vehicle Insurance, Arizona's Driver License, Arizona Driver License Manual], [Arizona Motor Vehicle Division, \"Frequently Asked Questions: Vehicle Services: Mandatory Insurance,\" https://www.azdot.gov/motor-vehicles/faq/vehicle-services/mandatory-insurance]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43565#43566#43567","text":"All of the above#A pickup truck#A motorcycle#A golf cart"},
{"testId":1033,"qid":24411,"description":"Which of the following vehicle must have automobile liability insurance in Arizona?","explanation":"Every motor vehicle operating on Arizona's highways is required by law to be insured by one of the statutory types of financial responsibility, most often known as liability insurance. Automobiles, trucks, motorcycles, mopeds, and even powered golf carts are subject to this regulation. [Vehicle Insurance, Arizona's Driver License, Arizona Driver License Manual], [Arizona Motor Vehicle Division, \"Frequently Asked Questions: Vehicle Services: Mandatory Insurance,\" https://www.azdot.gov/motor-vehicles/faq/vehicle-services/mandatory-insurance]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43565#43566#43567","text":"All of the above#A pickup truck#A motorcycle#A golf cart"},
{"testId":566,"qid":24412,"description":"In Arizona, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for one person involved in a single collision.","explanation":"In Arizona, your auto liability insurance policy must provide at least $15,000 in bodily injury coverage for one individual in any one collision. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11704#12047#12048","text":"$10,000#$7,500#$15,000#$25,000"},
{"testId":566,"qid":24413,"description":"A police officer will ask for your driver's license, vehicle registration, and _______ at a traffic stop.","explanation":"Every motor vehicle operating on Arizona highways is required by law to be insured by one of the statutory types of financial responsibility, most often known as liability insurance. A police officer will ask for your driver's license, car registration, and evidence of vehicle liability insurance during a traffic stop. As a result, you should always have all three with you while driving. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":26674,"isBookmarked":0,"aid":"26674#43568#43569#43570","text":"proof of insurance#Social Security number#passport#nothing else"},
{"testId":1033,"qid":24413,"description":"A police officer will ask for your driver's license, vehicle registration, and _______ at a traffic stop.","explanation":"Every motor vehicle operating on Arizona highways is required by law to be insured by one of the statutory types of financial responsibility, most often known as liability insurance. A police officer will ask for your driver's license, car registration, and evidence of vehicle liability insurance during a traffic stop. As a result, you should always have all three with you while driving. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":26674,"isBookmarked":0,"aid":"26674#43568#43569#43570","text":"proof of insurance#Social Security number#passport#nothing else"},
{"testId":566,"qid":24414,"description":"The MVD has allowed some third-party driving schools to:","explanation":"The MVD has approved select third-party driving schools to conduct a final review of the student driver's driving as part of a ten-hour driving course. If the student driver passes the assessment, the driving school will provide a Training Completion Certificate to the driver (road test waiver). By submitting this certificate to the MVD, the student driver will be able to avoid taking the MVD road test when applying for a driving license. [\"Approved driving schools will offer certificates to avoid licensing examinations at MVD offices,\" Arizona Department of Transportation, January 13, 2015; https://www.azdot.gov/mobile/media/news/2015/01/13/approved-driving-schools-will-issue-certificates-to-skip-license-exams-at-mvd-offices]","imageName":"","correctAnswer":43571,"isBookmarked":0,"aid":"10656#43571#43572#43573","text":"Do all of the above#let you waive the MVD road test#give you a discount on vehicle liability insurance#remove traffic convictions from your driving record"},
{"testId":566,"qid":24415,"description":"If you ______________, you will be required to attend Traffic Survival School.","explanation":"If you are guilty of running a red light or a more severe traffic infraction such as DUI or reckless driving, you will be obliged to attend Traffic Survival School (TSS). If you have eight or more points on your driving record, you will also be forced to attend TSS. TSS is intended to alter or modify dangerous or unsafe driving behaviour. [Arizona Driver License Manual, Suspension and Traffic School, Driver Improvement, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":31101,"isBookmarked":0,"aid":"31101#43574#43575#43576","text":"are in any of the above situations#accumulate excessive points on your driving record#are convicted of running a red light#are convicted of DUI"},
{"testId":1033,"qid":24415,"description":"If you ______________, you will be required to attend Traffic Survival School.","explanation":"If you are guilty of running a red light or a more severe traffic infraction such as DUI or reckless driving, you will be obliged to attend Traffic Survival School (TSS). If you have eight or more points on your driving record, you will also be forced to attend TSS. TSS is intended to alter or modify dangerous or unsafe driving behaviour. [Arizona Driver License Manual, Suspension and Traffic School, Driver Improvement, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":31101,"isBookmarked":0,"aid":"31101#43574#43575#43576","text":"are in any of the above situations#accumulate excessive points on your driving record#are convicted of running a red light#are convicted of DUI"},
{"testId":566,"qid":24416,"description":"Alcohol consumption can:","explanation":"Drinking alcohol before or during driving impairs reflexes, physical control of the car, and awareness of road hazards. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Drinking and Driving, Use of Alcohol and Other Drugs]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43577#43578#43579","text":"Do all of the above#impair your reflexes#lessen your physical control of your vehicle#lessen your awareness of dangers on the road"},
{"testId":1033,"qid":24416,"description":"Alcohol consumption can:","explanation":"Drinking alcohol before or during driving impairs reflexes, physical control of the car, and awareness of road hazards. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Drinking and Driving, Use of Alcohol and Other Drugs]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43577#43578#43579","text":"Do all of the above#impair your reflexes#lessen your physical control of your vehicle#lessen your awareness of dangers on the road"},
{"testId":566,"qid":24417,"description":"It is unlawful in Arizona to keep an open container of an alcoholic beverage:","explanation":null,"imageName":"","correctAnswer":23137,"isBookmarked":0,"aid":"23136#23137#23138#43580","text":"on the driver's seat#anywhere in the passenger compartment#in the console#on the rear seat"},
{"testId":566,"qid":24418,"description":"If your vehicle lacks a trunk, you may lawfully keep an open container of an alcoholic beverage :","explanation":null,"imageName":"","correctAnswer":43581,"isBookmarked":0,"aid":"23138#43581#43582#43583","text":"in the console#behind the last row of seats#under your seat#under the last row of seats"},
{"testId":566,"qid":24419,"description":"First-time offenders convicted of knowingly supplying alcohol to minors face a聽license suspension for up to:","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#740#742#1020","text":"90 days#15 days#30 days#60 days"},
{"testId":1033,"qid":24419,"description":"First-time offenders convicted of knowingly supplying alcohol to minors face a聽license suspension for up to:","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#740#742#1020","text":"90 days#15 days#30 days#60 days"},
{"testId":566,"qid":24421,"description":"Drivers between the ages of ______ had the highest rate of alcohol-related car collisions.","explanation":null,"imageName":"","correctAnswer":43586,"isBookmarked":0,"aid":"43584#43585#43586#43587","text":"16 鈥 20#18 鈥 24#21 鈥 34#21 鈥 25"},
{"testId":566,"qid":24423,"description":"If you are judged to be posing an imminent harm to others by speeding AND __________, you may be cited for aggressive driving.","explanation":"The term \"aggressive driving\" is defined in complicated detail under Arizona law. If you are discovered to be speeding AND engaging in any two of the following actions: tailgating, making an improper lane change, disregarding traffic signs or signals, passing another vehicle on the right, or failing to yield to an emergency vehicle, you may get a ticket for aggressive driving. [Arizona Driver License Manual, Road Rage and Aggressive Driving, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":43590,"isBookmarked":0,"aid":"8584#43588#43589#43590","text":"tailgating#disobeying traffic signs or signals#failing to yield to an emergency vehicle#doing two of the above"},
{"testId":1033,"qid":24423,"description":"If you are judged to be posing an imminent harm to others by speeding AND __________, you may be cited for aggressive driving.","explanation":"The term \"aggressive driving\" is defined in complicated detail under Arizona law. If you are discovered to be speeding AND engaging in any two of the following actions: tailgating, making an improper lane change, disregarding traffic signs or signals, passing another vehicle on the right, or failing to yield to an emergency vehicle, you may get a ticket for aggressive driving. [Arizona Driver License Manual, Road Rage and Aggressive Driving, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":43590,"isBookmarked":0,"aid":"8584#43588#43589#43590","text":"tailgating#disobeying traffic signs or signals#failing to yield to an emergency vehicle#doing two of the above"},
{"testId":566,"qid":24424,"description":"For a first offense of aggressive driving, you may face which of the following panelties?","explanation":"If you are convicted for the first time of aggressive driving, you will be obliged to attend Traffic Survival School and your license may be revoked for 30 days. These penalties will be in addition to any penalties (such as fines) imposed for specific violations such as speeding, disregarding traffic signs or signals, and so on. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Road Rage and Aggressive Driving]","imageName":"","correctAnswer":43591,"isBookmarked":0,"aid":"43591#43592#43593#43594","text":"Required Traffic Survival School and license suspension for 30 days#Required Traffic Survival School and license suspension for 90 days#Up to six months in jail and license suspension for 90 days#A fine of up to $2,500 and license suspension for 90 days"},
{"testId":1033,"qid":24424,"description":"For a first offense of aggressive driving, you may face which of the following panelties?","explanation":"If you are convicted for the first time of aggressive driving, you will be obliged to attend Traffic Survival School and your license may be revoked for 30 days. These penalties will be in addition to any penalties (such as fines) imposed for specific violations such as speeding, disregarding traffic signs or signals, and so on. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Road Rage and Aggressive Driving]","imageName":"","correctAnswer":43591,"isBookmarked":0,"aid":"43591#43592#43593#43594","text":"Required Traffic Survival School and license suspension for 30 days#Required Traffic Survival School and license suspension for 90 days#Up to six months in jail and license suspension for 90 days#A fine of up to $2,500 and license suspension for 90 days"},
{"testId":566,"qid":24425,"description":"If you're convicted of aggressive driving twice in two years, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":1033,"qid":24425,"description":"If you're convicted of aggressive driving twice in two years, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":566,"qid":24426,"description":"The suspension of your driving privileges in Arizona is known as:","explanation":"Suspension is the temporary loss of your driving license. You may get your license restored after the suspension term is up. Revocation is the loss of your driving privilege. After the revocation term expires, you must apply for a new license. [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-services/LicenseReinstatement/revocation-and-suspension], [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-service","imageName":"","correctAnswer":30338,"isBookmarked":0,"aid":"8335#30338#30339#43595","text":"suspension#revocation#cancellation#annulment"},
{"testId":1033,"qid":24426,"description":"The suspension of your driving privileges in Arizona is known as:","explanation":"Suspension is the temporary loss of your driving license. You may get your license restored after the suspension term is up. Revocation is the loss of your driving privilege. After the revocation term expires, you must apply for a new license. [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-services/LicenseReinstatement/revocation-and-suspension], [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-service","imageName":"","correctAnswer":30338,"isBookmarked":0,"aid":"8335#30338#30339#43595","text":"suspension#revocation#cancellation#annulment"},
{"testId":566,"qid":24427,"description":"If your license has been revoked, it will remain revoked until:","explanation":"If your license has been revoked, the revocation will stay in place until the completion of an inquiry into your driving record. The investigation's goal is to assess if you have completed all of the conditions to stop the revocation. (For example, if the revocation was the result of a DUI conviction, you must complete a drug addiction assessment.) You may apply for a new license after the inquiry has been completed properly. [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-services/LicenseReinstatement/revocation-and-suspension], [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-service","imageName":"","correctAnswer":43597,"isBookmarked":0,"aid":"43596#43597#43598#43599","text":"you renew the license#an investigation of your driving record is completed#you pass the road test again#you pass the written test again"},
{"testId":1033,"qid":24427,"description":"If your license has been revoked, it will remain revoked until:","explanation":"If your license has been revoked, the revocation will stay in place until the completion of an inquiry into your driving record. The investigation's goal is to assess if you have completed all of the conditions to stop the revocation. (For example, if the revocation was the result of a DUI conviction, you must complete a drug addiction assessment.) You may apply for a new license after the inquiry has been completed properly. [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-services/LicenseReinstatement/revocation-and-suspension], [\"Driver Services: License Reinstatement: Revocation and Suspension,\" Arizona Motor Vehicle Division, https://www.azdot.gov/motor-vehicles/driver-service","imageName":"","correctAnswer":43597,"isBookmarked":0,"aid":"43596#43597#43598#43599","text":"you renew the license#an investigation of your driving record is completed#you pass the road test again#you pass the written test again"},
{"testId":566,"qid":24428,"description":"If you are engaged in a traffic collision, which of the following information must you exchange with the other drivers involved?","explanation":"If you are engaged in a traffic collision, you must disclose the following information to any other parties involved: Your license number, contact information, the name and policy number of your insurance provider, and the license plate number of your car. If you know of any witnesses to the accident, you must submit their contact information as well. [Arizona Driver License Manual, Section 6: Handling Emergencies, Reporting Crashes]","imageName":"202006141611292838.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#23169#23170#43600","text":"All of the above#Contact information#Insurance company name and policy number#Driver license number"},
{"testId":566,"qid":24429,"description":"If you assist in removing a crashed vehicle off the road before emergency personnel arrive,:","explanation":null,"imageName":"","correctAnswer":43602,"isBookmarked":0,"aid":"22673#43601#43602#43603","text":"None of the above are true#you may be required to testify in court#you will not be held responsible for damages#you will be cited by the police"},
{"testId":1033,"qid":24429,"description":"If you assist in removing a crashed vehicle off the road before emergency personnel arrive,:","explanation":null,"imageName":"","correctAnswer":43602,"isBookmarked":0,"aid":"22673#43601#43602#43603","text":"None of the above are true#you may be required to testify in court#you will not be held responsible for damages#you will be cited by the police"},
{"testId":566,"qid":24430,"description":"If you are in a car accident, you are compelled by law to:","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":43604,"isBookmarked":0,"aid":"23175#34082#43604#43605","text":"call 911#call your insurer#identify yourself#call the MVD"},
{"testId":566,"qid":24431,"description":"If you are in a car accident, you are compelled by law to:","explanation":null,"imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43606#43607#43608","text":"Do all of the above#help the injured until emergency services arrive#provide contact information to all parties involved#show your driver license upon request"},
{"testId":1033,"qid":24431,"description":"If you are in a car accident, you are compelled by law to:","explanation":null,"imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43606#43607#43608","text":"Do all of the above#help the injured until emergency services arrive#provide contact information to all parties involved#show your driver license upon request"},
{"testId":566,"qid":24432,"description":"If you fail to stop at a fatal accident you caused, you'll go to jail and lose your license for:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":1033,"qid":24432,"description":"If you fail to stop at a fatal accident you caused, you'll go to jail and lose your license for:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":566,"qid":24435,"description":"If a person commits a DUI___________________,he or she may be charged with aggravated DUI.","explanation":null,"imageName":"","correctAnswer":43612,"isBookmarked":0,"aid":"43609#43610#43611#43612","text":"while his or her license is suspended or revoked#and it's his or her third DUI in 7 years#while a passenger under 15 years of age is in the vehicle#and any of the above apply"},
{"testId":1033,"qid":24435,"description":"If a person commits a DUI___________________,he or she may be charged with aggravated DUI.","explanation":null,"imageName":"","correctAnswer":43612,"isBookmarked":0,"aid":"43609#43610#43611#43612","text":"while his or her license is suspended or revoked#and it's his or her third DUI in 7 years#while a passenger under 15 years of age is in the vehicle#and any of the above apply"},
{"testId":566,"qid":24436,"description":"Your license will be suspended for _______ if you refuse to submit to a chemical test.","explanation":"Every individual who uses a motor vehicle in Arizona implicitly consents to a chemical test for drugs or alcohol after being detained on suspicion of DUI, according to Arizona's Implied Consent legislation. If you refuse to take such a test when asked by a law enforcement officer, your license will be suspended for one year for the first refusal and two years for the second refusal within seven years. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1033,"qid":24436,"description":"Your license will be suspended for _______ if you refuse to submit to a chemical test.","explanation":"Every individual who uses a motor vehicle in Arizona implicitly consents to a chemical test for drugs or alcohol after being detained on suspicion of DUI, according to Arizona's Implied Consent legislation. If you refuse to take such a test when asked by a law enforcement officer, your license will be suspended for one year for the first refusal and two years for the second refusal within seven years. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":566,"qid":24437,"description":"You can be charged with DUI in Arizona if you are detected driving with a blood alcohol content (BAC) of _____ or higher.","explanation":"You may be charged with DUI in Arizona if you are detected driving with a blood alcohol content (BAC) of 0.08 percent or higher. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#3282#4252","text":"0.08%#0.07%#0.06%#0.05%"},
{"testId":1033,"qid":24437,"description":"You can be charged with DUI in Arizona if you are detected driving with a blood alcohol content (BAC) of _____ or higher.","explanation":"You may be charged with DUI in Arizona if you are detected driving with a blood alcohol content (BAC) of 0.08 percent or higher. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#3282#4252","text":"0.08%#0.07%#0.06%#0.05%"},
{"testId":566,"qid":24438,"description":"A driver under the age of 21 in Arizona can be charged with DUI if his or her blood alcohol content (BAC) is discovered to be greater than :","explanation":"A motorist under the age of 21 in Arizona may be prosecuted with DUI if his or her blood alcohol content (BAC) is more than zero. Any trace of alcohol discovered in the system of an underage driver will result in the suspension of his or her license. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"4252#4253#4653#35229","text":"0.05%#0.03%#0.01%#Zero"},
{"testId":1033,"qid":24438,"description":"A driver under the age of 21 in Arizona can be charged with DUI if his or her blood alcohol content (BAC) is discovered to be greater than :","explanation":"A motorist under the age of 21 in Arizona may be prosecuted with DUI if his or her blood alcohol content (BAC) is more than zero. Any trace of alcohol discovered in the system of an underage driver will result in the suspension of his or her license. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"4252#4253#4653#35229","text":"0.05%#0.03%#0.01%#Zero"},
{"testId":566,"qid":24439,"description":"If convicted of DUI for the first time, you may face which of the following minimal penalties?","explanation":null,"imageName":"","correctAnswer":11767,"isBookmarked":0,"aid":"11766#11767#43613#43614","text":"10 days in jail#10 days in jail and a fine of $1,250#7 days in jail#7 days in jail and a fine of $500"},
{"testId":566,"qid":24440,"description":"If you are detected driving with a blood alcohol concentration (BAC) of _____ or above, you can be charged with Extreme DUI in Arizona.","explanation":"If you are detected driving with a blood alcohol content (BAC) of 0.15 percent or more, you might be charged with Extreme DUI in Arizona. The consequences for extreme DUI are greater than those for regular DUI. Note: After four standard alcoholic drinks, an average 180-pound guy will have a BAC of 0.15 percent before his liver has had chance to break down any of the alcohol. 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer equals one standard drink. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Extreme DUI, Driving Under the Influence]","imageName":"","correctAnswer":11770,"isBookmarked":0,"aid":"3169#11768#11769#11770","text":"0.20%#0.10%#0.12%#0.15%"},
{"testId":1033,"qid":24440,"description":"If you are detected driving with a blood alcohol concentration (BAC) of _____ or above, you can be charged with Extreme DUI in Arizona.","explanation":"If you are detected driving with a blood alcohol content (BAC) of 0.15 percent or more, you might be charged with Extreme DUI in Arizona. The consequences for extreme DUI are greater than those for regular DUI. Note: After four standard alcoholic drinks, an average 180-pound guy will have a BAC of 0.15 percent before his liver has had chance to break down any of the alcohol. 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer equals one standard drink. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Extreme DUI, Driving Under the Influence]","imageName":"","correctAnswer":11770,"isBookmarked":0,"aid":"3169#11768#11769#11770","text":"0.20%#0.10%#0.12%#0.15%"},
{"testId":566,"qid":24441,"description":"If convicted of extreme DUI, you will receive the minimum penalty listed below for a first offense.","explanation":null,"imageName":"","correctAnswer":11772,"isBookmarked":0,"aid":"11771#11772#11774#43615","text":"30 days in jail#30 days in jail and a fine of $2,500#15 days in jail and a fine of $1,250#A fine of $2,500"},
{"testId":566,"qid":24442,"description":"The MVD will suspend your license________ if you do not pay the payment or appear in court for a traffic violation.","explanation":"If you get a ticket, you have two options: pay the fee (which indicates an admission of guilt) or appear in court to dispute the case. If you do not comply with either requirement, the MVD will suspend your license until you do. [Failure to Appear or Pay Fine for Citation, Arizona Driver License Manual, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":23184,"isBookmarked":0,"aid":"11612#23184#23185#23186","text":"for 6 months#until the matter is settled#for 30 days#for 60 days"},
{"testId":1033,"qid":24442,"description":"The MVD will suspend your license________ if you do not pay the payment or appear in court for a traffic violation.","explanation":"If you get a ticket, you have two options: pay the fee (which indicates an admission of guilt) or appear in court to dispute the case. If you do not comply with either requirement, the MVD will suspend your license until you do. [Failure to Appear or Pay Fine for Citation, Arizona Driver License Manual, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":23184,"isBookmarked":0,"aid":"11612#23184#23185#23186","text":"for 6 months#until the matter is settled#for 30 days#for 60 days"},
{"testId":566,"qid":24443,"description":"If you create or use a fake driver's license, you may face which penalty?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12374#26643#35315","text":"All of the above#License suspension#A fine#A jail sentence"},
{"testId":1033,"qid":24443,"description":"If you create or use a fake driver's license, you may face which penalty?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12374#26643#35315","text":"All of the above#License suspension#A fine#A jail sentence"},
{"testId":566,"qid":24444,"description":"According to Arizona's Implied Consent Law, if you drive in Arizona, you automatically agree to:","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"29272#35227#35228#35436","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":1033,"qid":24444,"description":"According to Arizona's Implied Consent Law, if you drive in Arizona, you automatically agree to:","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"29272#35227#35228#35436","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":566,"qid":24445,"description":"In Arizona, you can be charged with DUI if the alcohol you drank noticeably hindered your driving and your blood alcohol content (BAC) is more than :","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you might be charged with DUI in Arizona. However, everyone is affected differently by drinking. Even if your BAC is substantially lower than 0.08 percent, you might be charged with DUI if the alcohol you ingested noticeably impacted your driving. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"4252#4253#4653#35229","text":"0.05%#0.03%#0.01%#Zero"},
{"testId":1033,"qid":24445,"description":"In Arizona, you can be charged with DUI if the alcohol you drank noticeably hindered your driving and your blood alcohol content (BAC) is more than :","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you might be charged with DUI in Arizona. However, everyone is affected differently by drinking. Even if your BAC is substantially lower than 0.08 percent, you might be charged with DUI if the alcohol you ingested noticeably impacted your driving. [Arizona Driver License Manual, DUI Penalties, Driving Under the Influence, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"4252#4253#4653#35229","text":"0.05%#0.03%#0.01%#Zero"},
{"testId":567,"qid":24446,"description":"To apply for a learner's permit, you must be at least ___ years old.","explanation":"An Arkansas resident must be at least 14 years old and complete several additional conditions in order to receive a learner's license. [Learner's License, Graduated Driver License Types, The Driver's License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"11361#11783#11784","text":"16#13#14"},
{"testId":567,"qid":24447,"description":"Which of the following is required from a learner's license application who is under the age of 18?","explanation":null,"imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#43048#44730","text":"Both of the above#A consent form signed by his or her parent or legal guardian#A verification of a school enrollment and a proof that the applicant is maintaining at least a \"C\" average (2.0 GPA)"},
{"testId":567,"qid":24448,"description":"What is the name of the Arkansas driver's license provided to those aged 16 to 18?","explanation":"An intermediate licence is given to drivers in Arkansas who are at least 16 years old but under the age of 18. [Arkansas Driver License Study Guide, Graduated License Types, The Drivers License, Intermediate License]","imageName":"","correctAnswer":23198,"isBookmarked":0,"aid":"216#11392#23198","text":"Driver's license#Learner's license#Intermediate license"},
{"testId":567,"qid":24449,"description":"A driver with an intermediate license is not permitted to ________ while driving.","explanation":"A motorist with an intermediate license is not permitted to use a mobile phone while driving, even if it is hands-free. [Types of Graduated Drivers Licenses, The Drivers License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":32198,"isBookmarked":0,"aid":"32198#43052#43053","text":"use a cell phone#listen to music#eat"},
{"testId":567,"qid":24450,"description":"Except for church, school, work, or an emergency, a driver with an intermediate license may not drive unattended________","explanation":"A motorist with an intermediate license may not drive alone after 11:00 p.m. and 4:00 a.m. except while going to or from a church-related, school-related, or employment-related activity, or in an emergency. [Types of Graduated Drivers Licenses, The Drivers License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43055,"isBookmarked":0,"aid":"43054#43055#43056","text":"between 10:00 p.m. and 3:00 a.m#between 11:00 p.m. and 4:00 a.m#between 12:00 a.m. and 5:00 a.m"},
{"testId":567,"qid":24451,"description":"Which classification is the unrestricted Arkansas driver's license offered to drivers beyond the age of 18?","explanation":"Class D is the unrestricted Arkansas driver's license awarded to a driver who is at least 18 years old. Commercial driver's licenses go into three categories: A, B, and C. (CDLs). [Arkansas Driver License Study Guide, Types of Graduated Drivers Licenses, The Drivers License]","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#324#325","text":"A#C#D"},
{"testId":567,"qid":24452,"description":"If you leave the scene of an accident, you can lose your license (hit and run).","explanation":"You might lose your license due to a multitude of reasons. These include lying on your license application, failing to tell the DFA of a reportable accident in which you were involved, and convictions for major criminal crimes such as DWI, fleeing the scene of an accident (hit and run), and using a motor vehicle to commit a felony. [The Drivers License, Arkansas Driver License Study Guide, Keeping the Drivers License]","imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":567,"qid":24453,"description":"Which of the following could lead to the suspension of your driver's license?","explanation":"You may lose your driving privileges if you are found guilty of permitting someone else to use your driver's license for any purpose. [The Drivers License, Arkansas Driver License Study Guide, Keeping the Drivers License]","imageName":"","correctAnswer":43060,"isBookmarked":0,"aid":"18625#26680#43060","text":"Speeding#Running a red light#Allowing someone else to use your license"},
{"testId":567,"qid":24454,"description":"Alcohol is implicated in approximately _____ of fatal road accidents.","explanation":"About 38% of fatal car accidents include alcohol. (Note: There are situations when the driver is not at fault. A third or so of pedestrian fatalities in collisions were under the influence of alcohol at the time.) [Driver License Study Guide for Arkansas, Drinking and Driving]","imageName":"","correctAnswer":41568,"isBookmarked":0,"aid":"13852#14017#41568","text":"18%#28%#38%"},
{"testId":567,"qid":24455,"description":"What is the law of Isaac?","explanation":"If a motorist breaches Isaac's Law, he or she faces a Class C felony charge of negligent murder if he or she causes the death of another person while driving.","imageName":"","correctAnswer":44732,"isBookmarked":0,"aid":"44732#44733#44734","text":"A driver who negligently causes the death of another person is subject to a Class C Felony#A driver who runs a red light is subject to a $500 fine#A driver who negligently causes the death of another person looses their license for life"},
{"testId":567,"qid":24456,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. It usually takes around an hour to break down the alcohol in one \"normal\" drink. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [Body Metabolism, Driving Under the Influence of Alcohol, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#25081#40345","text":"Coffee#A cold shower#The passage of time"},
{"testId":567,"qid":24457,"description":"Which of the following are acceptable substitutes for drinking and driving?","explanation":"Taking a cab, utilising public transit, or travelling with a designated driver are alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises while they are drinking and walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had a blood alcohol content (BAC) of 0.08 percent or higher, but 37 percent of pedestrians did. [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]] and [\"Body Metabolism, Drinking and Driving, Arkansas Driver License Study Guide\"]","imageName":"","correctAnswer":44725,"isBookmarked":0,"aid":"23212#23213#44725","text":"A taxi#A designated driver#Any of the above"},
{"testId":567,"qid":24458,"description":"If you are involved in a traffic collision and your blood alcohol concentration (BAC) is _____ or above, you may be charged with DWI.","explanation":"DWI is usually charged if your blood alcohol content (BAC) is 0.08 percent or above. However, if you are involved in a traffic accident or commit a significant traffic crime and your blood alcohol content (BAC) is 0.04 percent or more, you may be charged with DWI. [Alcohol and the Law, Driving Under the Influence, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":3281,"isBookmarked":0,"aid":"3281#4251#4653","text":"0.04%#0.02%#0.01%"},
{"testId":567,"qid":24459,"description":"Under Arkansas law, a driver must _____ when asked by a police officer.","explanation":null,"imageName":"","correctAnswer":43062,"isBookmarked":0,"aid":"41795#43062#43063","text":"(a) and (b)#(a) submit to a chemical test for alcohol or drugs#(b) pay a citation at the time of the traffic stop"},
{"testId":567,"qid":24460,"description":"Who decides what type of blood alcohol concentration test the driver must take?","explanation":null,"imageName":"","correctAnswer":23218,"isBookmarked":0,"aid":"9808#23218#43064","text":"The driver#The law enforcement officer#A local hospital"},
{"testId":567,"qid":24461,"description":"First-time DWI offenders may face which penalities?","explanation":null,"imageName":"","correctAnswer":23223,"isBookmarked":0,"aid":"23223#43066#43067","text":"$1,000 fine, one year in jail, and license suspension for 120 days#$500 fine, one month in jail, and license suspension for 30 days#$500 fine, six months in jail, and license suspension for 90 days"},
{"testId":567,"qid":24462,"description":"A driver under the age of 21 in Arkansas can be arrested and prosecuted with Underage DUI if his or her blood alcohol content (BAC) is _____ or higher.","explanation":"In Arkansas, a motorist under the age of 21 who has a blood alcohol content (BAC) of at least 0.02 percent but less than 0.08 percent may be arrested and prosecuted with Underage DUI. (Most individuals will have a BAC of 0.02 percent or more after only one alcoholic drink.) DWI will be charged if the driver's BAC is 0.08 percent or above. [Alcohol and the Law, Driving Under the Influence, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4653","text":"0.04%#0.02%#0.01%"},
{"testId":567,"qid":24463,"description":"Which of the following punishments may be imposed on a motorist under the age of 21 who is convicted of Underage DUI?","explanation":null,"imageName":"","correctAnswer":43071,"isBookmarked":0,"aid":"43069#43070#43071","text":"A $1,000 fine and 30 days in jail#A $1,000 fine and a one-year license suspension#A $2,000 fine and license revocation until he or she turns 21"},
{"testId":567,"qid":24464,"description":"Drivers under the age of ___ who are convicted of purchasing or carrying alcohol will lose their license.","explanation":null,"imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"11359#11360#11361","text":"18#21#16"},
{"testId":567,"qid":24465,"description":"In the event of an accident, you are required to file a report with the DFA if the total estimated damage to any person's property exceeds:","explanation":"If the accident included injury or death, or damage to any person's property, you must submit an accident report with the DFA's Office of Driver Services within 30 days. [Traffic Accidents, Emergencies, and Arkansas Driver License Preparation]","imageName":"202006141611292838.jpg","correctAnswer":1799,"isBookmarked":0,"aid":"1799#23887#24978","text":"$1,000#$500#$100"},
{"testId":567,"qid":24466,"description":"If you are engaged in an accident in which someone is hurt, you must ________ right away.","explanation":"If you are engaged in a traffic collision in which someone is hurt or killed or property damage exceeds $1,000, you must call the police immediately. You must stay at the accident site until the police come and gather all necessary information. [Traffic Accidents, Emergencies, and Arkansas Driver License Preparation]","imageName":"","correctAnswer":1806,"isBookmarked":0,"aid":"1806#43073#43074","text":"notify the police#call your insurer#administer first aid"},
{"testId":567,"qid":24467,"description":"If you are obligated to file an accident report for a collision, you must do so within ______ days after the collision.","explanation":"If you are obligated to file an accident report for a collision, you must do it within 30 days after the incident with the DFA's Office of Driver Services. [Record Crash Data, Traffic Crashes, Emergencies, Arkansas Driver License Study Guide]","imageName":"202006141611292838.jpg","correctAnswer":742,"isBookmarked":0,"aid":"739#740#742","text":"10 days#15 days#30 days"},
{"testId":567,"qid":24468,"description":"If you are required to file an accident report following a collision, you must do so.","explanation":"If you are obligated to file an accident report for a collision, you must do it within 30 days after the incident with the DFA's Office of Driver Services. [Record Crash Data, Traffic Crashes, Emergencies, Arkansas Driver License Study Guide]","imageName":"202006141611292838.jpg","correctAnswer":43077,"isBookmarked":0,"aid":"43075#43076#43077","text":"the Arkansas State Police#the local police department#the Office of Driver Services"},
{"testId":567,"qid":24469,"description":"Which occupants of a car must wear seat belts if the driver has a learner's license?","explanation":"If the driver has a learner's permit, all passengers (even rear-seat adult passengers) must wear seat belts for their own safety. [Learner's License, Graduated Driver License Types, The Driver's License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#39029#43079","text":"The driver and all passengers#The driver and all front-seat passengers#The driver, all front-seat passengers, and all passengers under 15 years of age"},
{"testId":567,"qid":24470,"description":"You will be liable to ______ under Arkansas Isaac's Law if you fail to stop for a stopped school bus and accidently strike and kill a child pedestrian.","explanation":null,"imageName":"","correctAnswer":44735,"isBookmarked":0,"aid":"44735#44736#44737","text":"class C felony#assault in the third degree#a small fine"},
{"testId":567,"qid":24471,"description":"To receive an Arkansas instruction permit, you must meet which of the following requirements?","explanation":"An instruction permit will allow you to learn to drive under adult supervision. To qualify for an instruction permit, you must be at least 14 years old, you must pass a vision test to show that you can see well enough to drive, and you must pass a knowledge exam on the basics of driving. [Instruction Permit, Types of Graduated Drivers Licenses, The Drivers License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43086,"isBookmarked":0,"aid":"43084#43085#43086","text":"You must be old enough to be eligible for a learner's license#You must attempt the required vision, knowledge, and skills tests#You must pass the required vision and knowledge tests"},
{"testId":567,"qid":24472,"description":"A person may not earn an intermediate license without first receiving:","explanation":null,"imageName":"","correctAnswer":43087,"isBookmarked":0,"aid":"24970#43087#43088","text":"a high school diploma#a learner's license#vehicle liability insurance"},
{"testId":567,"qid":24473,"description":"Driving must be supervised by a licensed adult driver who is seated _______ when you have an instruction permit or learner's license.","explanation":"While you have an instruction permit or learner's license, you must be supervised by a licensed driver over the age of 21 who is sitting in the front seat next to you. [Learner's License, Graduated Driver License Types, The Driver's License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":44738,"isBookmarked":0,"aid":"8451#11857#44738","text":"behind you#anywhere in the vehicle#beside you"},
{"testId":567,"qid":24474,"description":"In order to receive an unrestricted Class D driver's license, you must not have had any significant traffic accidents or convictions for the past:","explanation":"You must not have had any significant accidents or serious traffic offenses in the preceding twelve months in order to get an unrestricted Class D driver's license. [Arkansas Driver License Study Guide, Types of Graduated Drivers Licenses, The Drivers License]","imageName":"","correctAnswer":23256,"isBookmarked":0,"aid":"23253#23254#23256","text":"Three months#Six months#twelve months"},
{"testId":567,"qid":24475,"description":"A driver who is at least 18 years old but under 21 years old and has a Class D license:","explanation":null,"imageName":"","correctAnswer":43093,"isBookmarked":0,"aid":"43092#43093#43094","text":"may send and receive text messages while driving#may not use a hand-held cell phone while driving#may not use a hands-free cell phone while driving"},
{"testId":567,"qid":24476,"description":"When a driver under the age of ___ operates a vehicle, the parent or legal guardian accepts all legal responsibility for the driver.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11361#11374","text":"18#16#17"},
{"testId":567,"qid":24477,"description":"If a driver passes a stopped school bus with its red lights flashing, he or she will be prosecuted with ______ and fined at least______upon conviction.","explanation":"Drivers who pass a halted school bus with red lights flashing will be prosecuted with a Class A misdemeanor. A minimum fine of $250, not to exceed $1000, up to ninety (90) days in prison, or both. [Right-of-Way, School Buses, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":44739,"isBookmarked":0,"aid":"44739#44740#44741","text":"misdemeanor; $250#felony; $1000#felony; $250"},
{"testId":567,"qid":24478,"description":"Under Arkansas law, you can't smoke in your car if you're transporting someone younger than _____ years old.","explanation":"If you are carrying a passenger under the age of 14, Arkansas law prohibits you from smoking in your vehicle. [AR Code 20-27-1903] [Protection from Secondhand Smoke for Children Act, Arkansas Seat Belt Law, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5446#6478#11784","text":"10#12#14"},
{"testId":567,"qid":24479,"description":"Which of the following punishments could you face for a FIRST offense if you are found guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":43101,"isBookmarked":0,"aid":"43100#43101#43102","text":"A $500 fine and eight hours of community service#A $1,000 fine and eight hours of community service#A $2,000 fine and 24 hours of community service"},
{"testId":567,"qid":24480,"description":"Which of the following punishments could you face for a SECOND offense if you are found guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":43102,"isBookmarked":0,"aid":"43101#43102#43103","text":"A $1,000 fine and eight hours of community service#A $2,000 fine and 24 hours of community service#A $1,000 fine and 48 hours in jail"},
{"testId":567,"qid":24481,"description":"Your license could be suspended for _____, if you are found guilty of failing to yield at a yield sign.","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"24048#24049#25858","text":"60 days#90 days#180 days"},
{"testId":567,"qid":24482,"description":"Under Arkansas' Move Over Law, you must move into the lane farthest from a stopped emergency vehicle, leaving at least one lane between you and it. If convicted, you'll face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":38488,"isBookmarked":0,"aid":"38488#43105#43106","text":"(a) or (b)#(a) A fine#(b) A jail sentence"},
{"testId":567,"qid":24483,"description":"Using someone else's disability parking certificate may result in fines of up to _____ for a first-time offender.","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11832#12395","text":"$500#$100#$250"},
{"testId":567,"qid":24484,"description":"If you are caught using another person's disability parking permit for the second time, you could face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":14533,"isBookmarked":0,"aid":"14533#26643#44742","text":"All of the above#A fine#License suspension and vehicle impoundment"},
{"testId":567,"qid":24485,"description":"A handicapped parking certificate must be returned to the Arkansas DMV within _____ days if a motorist from the state has moved out of the state.","explanation":"The disability parking certificate must be returned to the Arkansas Department of Motor Vehicles within 30 days after leaving the state. [April 2007, Arkansas Driver License Study Guide] [AR Governor's Commission on People with Disabilities]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#8141","text":"90#30#60"},
{"testId":567,"qid":24486,"description":"You may be fined up to _____ for driving without wearing a seat belt.","explanation":null,"imageName":"","correctAnswer":11953,"isBookmarked":0,"aid":"11953#24994#27902","text":"$25.00#$50#$75"},
{"testId":567,"qid":24487,"description":"To pass the Arkansas vision exam with corrective lenses, an applicant for a permit or license must have a visual acuity of _____ or greater.","explanation":"You must have uncorrected visual acuity of 20/40 or better or corrected visual acuity of 20/70 or better to pass the vision test. If you need corrective lenses (eyeglasses or contact lenses) to pass the vision exam, your license will require you to use them every time you drive. [Driving Fitness, Vision, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43108,"isBookmarked":0,"aid":"14054#43108#43109","text":"20/40#20/70#20/30"},
{"testId":567,"qid":24488,"description":"A person who fails the vision test while applying for an instruction permit or license:","explanation":"If you fail the Arkansas vision test, you will be given a form to take to your preferred eye doctor. The eye doctor must undertake an examination and decide if your vision impairments can be addressed in order for you to satisfy Arkansas's vision criteria. [Driving Fitness, Vision, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43110,"isBookmarked":0,"aid":"43110#43111#44743","text":"will be required to visit an eye doctor#may attempt it again the following day#will be allowed to drive during the daytime only"},
{"testId":567,"qid":24489,"description":"What are some of the consequences of drinking alcohol?","explanation":"Drinking alcohol lengthens response times, reduces visual acuity, dulls judgement, and lowers attentiveness. These are all necessary abilities for driving. You may not be aware of how inebriated you are or how the alcohol is influencing your ability to drive since your judgement is clouded. [Arkansas Driver License Study Guide: Alcohol Impairment, Drinking and Driving]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#43114#43115","text":"Both of the above#Slower reflexes#Decreased alertness"},
{"testId":567,"qid":24490,"description":"After how many drinks does your body start to feel the effects of the alcohol?","explanation":"One alcoholic drink per hour may be broken down by your body. That rate cannot be increased by coffee, exercise, or a cold shower. [Arkansas Driver License Study Guide, Drinking and Driving]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#6209#10514","text":"Two#Three#One"},
{"testId":567,"qid":24491,"description":"Which of the following can have an impact on your driving abilities?","explanation":"Many legal medicines may produce sleepiness, sedation, or poor coordination, all of which can make it difficult to drive. Several prescription pain relievers, tranquilizers, and antidepressants are examples, as are some over-the-counter sleep aids, allergy drugs, and cold treatments. You may be charged with DWI if a police officer witnesses you driving recklessly and you acknowledge to the officer that you have taken such medications. If you are unclear about the side effects of a medicine, consult the warning label or your pharmacist or doctor. [Other Drugs and Driving, Driving While Drunk, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#23288#25099","text":"Both of the above#Over-the-counter cold medicines#Marijuana"},
{"testId":567,"qid":24492,"description":"Drivers who use _________ make more driving mistakes than other drivers, according to the Arkansas Driver License Study Guide.","explanation":null,"imageName":"","correctAnswer":11901,"isBookmarked":0,"aid":"11901#11902#43117","text":"marijuana#corrective lenses#cigarettes"},
{"testId":567,"qid":24493,"description":"If you've had an epileptic seizure in Arkansas, you must be seizure-free for _______ before your driving privileges can be given or resumed.","explanation":"In Arkansas, if you have had an epileptic seizure, you must be under the supervision of a doctor for one year and be seizure free before your driving privileges may be restored or renewed. It should be noted that the regulations governing driving when epileptic differ from state to state. [Arkansas Driver License Study Guide, Epilepsy, Health, Drinking and Driving]","imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"230#959#4374","text":"one year#one month#six months"},
{"testId":567,"qid":24494,"description":"It is mandatory to use a child safety seat for children under the age of ____ and under the weight of ____ pounds while driving.","explanation":"A kid under the age of six and weighing less than sixty pounds must be properly restrained in a child passenger safety seat that is fastened to the vehicle. [Arkansas Seat Belt Law, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":44744,"isBookmarked":0,"aid":"44744#44745#44746","text":"6; 60#6; 40#4; 60"},
{"testId":567,"qid":24495,"description":"Under Arkansas law, you must _____, if you hit a parked vehicle and can't find the driver or the owner.","explanation":null,"imageName":"","correctAnswer":41013,"isBookmarked":0,"aid":"41013#43121#43122","text":"Leave a note identifying yourself#wait until the driver or owner returns#call 911 and proceed"},
{"testId":568,"qid":24496,"description":"If convicted of driving with a BAC of 0.20 percent or higher, the court may:","explanation":"Your driver license will be suspended for 10 months if you are convicted of driving with a BAC of 0.20 percent or more and the court assigns you to an advanced DUI treatment program. You might potentially be fined, imprisoned, or forced to put an ignition interlock device (IID) on your car. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":20296,"isBookmarked":0,"aid":"20296#24954#24955","text":"Do both of the above#order you to complete an enhanced DUI treatment program#suspend your license for 10 months"},
{"testId":1035,"qid":24496,"description":"If convicted of driving with a BAC of 0.20 percent or higher, the court may:","explanation":"Your driver license will be suspended for 10 months if you are convicted of driving with a BAC of 0.20 percent or more and the court assigns you to an advanced DUI treatment program. You might potentially be fined, imprisoned, or forced to put an ignition interlock device (IID) on your car. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":20296,"isBookmarked":0,"aid":"20296#24954#24955","text":"Do both of the above#order you to complete an enhanced DUI treatment program#suspend your license for 10 months"},
{"testId":568,"qid":24497,"description":"If a California driver's license has been suspended for ten months due to multiple DUI offenses, which of the following can allow that person to restore driving privileges?","explanation":"A motorist may be required to install an ignition interlock device in order to be released from a 10-month driving license suspension after numerous DUI convictions (IID). It will not start the car if the BAC reading from the driver's breath exceeds a predetermined limit. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":24958,"isBookmarked":0,"aid":"24957#24958#24959","text":"A letter to the governor#Installation of an ignition interlock device (IID)#Completion of a driver safety course"},
{"testId":568,"qid":24498,"description":"Is it legal in California to leave a child aged 6 or younger in a vehicle?","explanation":"It is never a good idea to leave a youngster in a car unaccompanied. It is prohibited to leave a youngster under the age of six in a motor vehicle unsupervised. Under California law, you may leave a kid aged 6 or less in a vehicle only if he or she is supervised by someone over the age of 12. [Occupant Protection; Unattended Children in Motor Vehicles; California Driver Handbook]","imageName":"","correctAnswer":42294,"isBookmarked":0,"aid":"25224#42294#42295","text":"No, never#Yes, but only if supervised by someone who is at least 12 years old#Yes, but only if the windows are slightly open"},
{"testId":568,"qid":24500,"description":"If you leave a child under 6 in a car in unsafe conditions, you will be fined:","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11832#12639","text":"$500#$100#$1,000.00"},
{"testId":568,"qid":24501,"description":"A driver holding a standard Class C California driver license is permitted to legally operate:","explanation":"A Class C driver license in California allows you to legally operate a housecar (RV) less than 40 feet long, a three-wheeled motorcycle, a vanpool vehicle designed to transport 10 to 15 people, a two-axle vehicle weighing less than 26,000 pounds, or a three-axle vehicle weighing less than 6,000 pounds. [Driving Classes, Getting a Driver License, California Driver Handbook]","imageName":"","correctAnswer":20787,"isBookmarked":0,"aid":"20787#24961#42297","text":"Both of the above#a housecar (RV) less than 40 feet in length#a three-wheeled motorcycle"},
{"testId":1035,"qid":24501,"description":"A driver holding a standard Class C California driver license is permitted to legally operate:","explanation":"A Class C driver license in California allows you to legally operate a housecar (RV) less than 40 feet long, a three-wheeled motorcycle, a vanpool vehicle designed to transport 10 to 15 people, a two-axle vehicle weighing less than 26,000 pounds, or a three-axle vehicle weighing less than 6,000 pounds. [Driving Classes, Getting a Driver License, California Driver Handbook]","imageName":"","correctAnswer":20787,"isBookmarked":0,"aid":"20787#24961#42297","text":"Both of the above#a housecar (RV) less than 40 feet in length#a three-wheeled motorcycle"},
{"testId":568,"qid":24502,"description":"What is the maximum number of vehicle(s), a California driver with standard Class C license is allowed to tow?","explanation":"A motorist with a normal Class C California driving license may only tow one car. [Driver License Classes, Obtaining a Driver License, California Driver Handbook, Class C Driver License]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6469","text":"2#3#1"},
{"testId":568,"qid":24503,"description":"Your vehicle liability insurance policy must provide at least _______ in coverage for the bodily injury or death of two or more people in the event of an accident in California.","explanation":"In California, your car liability insurance policy must provide at least $30,000 in bodily injury or death coverage for two or more people in any one collision. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11659#12048","text":"$30,000#$20,000#$25,000"},
{"testId":568,"qid":24504,"description":"When an animal is abandoned on a roadway, it is subject to _________.","explanation":"Animals should not be dumped or abandoned on a roadway. This offense is punished by a $1,000 fine, 6 months in prison, or both. [Do nots, Additional Driving Laws/Rules, California Driver Handbook]","imageName":"","correctAnswer":40495,"isBookmarked":0,"aid":"40493#40494#40495","text":"a fine of up to $1,000#6 months in jail#either or both of the above"},
{"testId":1035,"qid":24504,"description":"When an animal is abandoned on a roadway, it is subject to _________.","explanation":"Animals should not be dumped or abandoned on a roadway. This offense is punished by a $1,000 fine, 6 months in prison, or both. [Do nots, Additional Driving Laws/Rules, California Driver Handbook]","imageName":"","correctAnswer":40495,"isBookmarked":0,"aid":"40493#40494#40495","text":"a fine of up to $1,000#6 months in jail#either or both of the above"},
{"testId":568,"qid":24505,"description":"Your vehicle liability insurance policy must provide at least _______ in coverage for the bodily injury or death of a single person in the event of an accident in California.","explanation":"In California, your car liability insurance policy must provide at least $15,000 in bodily injury or death coverage for one individual in any one collision. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11659#12047","text":"$10,000#$20,000#$15,000"},
{"testId":568,"qid":24506,"description":"In the event that an adult motorist fails to pay a traffic ticket fine, then:","explanation":"If you get a traffic citation, you have the option of paying the fee or appearing in court to address the case. When an adult motorist fails to pay the fee for a traffic ticket, the court no longer has the authority to inform the DMV. However, if the driver fails to appear in court to face the traffic charge, the court may still inform the DMV, and the DMV can still suspend the driver's license in this scenario. [Administrative, Getting a Ticket, California Driver Handbook]","imageName":"","correctAnswer":37238,"isBookmarked":0,"aid":"24987#37238#42298","text":"his or her license will be suspended#neither of the above will happen#the fine will be increased"},
{"testId":568,"qid":24507,"description":"You have _____ to notify the DMV of a change of address.","explanation":"If you move, you must notify the DMV within 10 days of your new address. You may do this online at the DMV website or by filling out a Change of Address form. [Address Changes, Other Licensing Info, California Driver Handbook]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#35988#35989","text":"10 days#7 days#14 days"},
{"testId":1035,"qid":24507,"description":"You have _____ to notify the DMV of a change of address.","explanation":"If you move, you must notify the DMV within 10 days of your new address. You may do this online at the DMV website or by filling out a Change of Address form. [Address Changes, Other Licensing Info, California Driver Handbook]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#35988#35989","text":"10 days#7 days#14 days"},
{"testId":568,"qid":24508,"description":"A driver's license must be REAL ID compatible to be recognised as legitimate identification at which of the following places beginning October 1, 2020?","explanation":"The REAL ID Act of 2005 establishes criteria for state-issued driver licenses to be accepted forms of identification in certain sensitive locations. Your driving license must be REAL ID compatible by October 1, 2020, in order to be recognized as legitimate identification at government buildings, military facilities, TSA checkpoints at airports, and nuclear power plants. [The REAL ID Act, Getting a Driver License, and the California Driver Handbook]","imageName":"","correctAnswer":42250,"isBookmarked":0,"aid":"42250#42299#42300","text":"Either of the above#Federal buildings#Airport security checkpoints"},
{"testId":1035,"qid":24508,"description":"A driver's license must be REAL ID compatible to be recognised as legitimate identification at which of the following places beginning October 1, 2020?","explanation":"The REAL ID Act of 2005 establishes criteria for state-issued driver licenses to be accepted forms of identification in certain sensitive locations. Your driving license must be REAL ID compatible by October 1, 2020, in order to be recognized as legitimate identification at government buildings, military facilities, TSA checkpoints at airports, and nuclear power plants. [The REAL ID Act, Getting a Driver License, and the California Driver Handbook]","imageName":"","correctAnswer":42250,"isBookmarked":0,"aid":"42250#42299#42300","text":"Either of the above#Federal buildings#Airport security checkpoints"},
{"testId":568,"qid":24509,"description":"Convictions for traffic violations and collisions remain on your record for _____, or longer, depending on the type of conviction.","explanation":"Convictions for traffic violations and crashes are recorded on your record for 36 months or longer, depending on the kind of conviction. [Administrative Driver Record Points, California Driver Handbook]","imageName":"","correctAnswer":1275,"isBookmarked":0,"aid":"1273#1274#1275","text":"12 months#24 months#36 months"},
{"testId":568,"qid":24510,"description":"If you give your disability placard or license plate to a non-disabled driver, you may face which penalty?","explanation":null,"imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#26643#35315","text":"Both of the above#A fine#A jail sentence"},
{"testId":568,"qid":24511,"description":"Which of the following punishments may you face if you are found guilty of careless driving in which no one was injured?","explanation":null,"imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#26643#35315","text":"Both of the above#A fine#A jail sentence"},
{"testId":1035,"qid":24511,"description":"Which of the following punishments may you face if you are found guilty of careless driving in which no one was injured?","explanation":null,"imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#26643#35315","text":"Both of the above#A fine#A jail sentence"},
{"testId":568,"qid":24512,"description":"Is it permissible to leave a child aged 6 or younger in a heated vehicle?","explanation":null,"imageName":"","correctAnswer":25224,"isBookmarked":0,"aid":"25224#42295#42301","text":"No, never#Yes, but only if the windows are slightly open#Yes, but only if the child is supervised by another child 12 years old or older"},
{"testId":568,"qid":24513,"description":"In California, if you are found guilty of driving a car to get away from or get away from a police car, and someone was seriously hurt during the chase, you could be sentenced to which of the following?","explanation":null,"imageName":"","correctAnswer":42302,"isBookmarked":0,"aid":"42302#42303#42304","text":"Up to seven years in a state prison and a fine of up to $10,000#Up to three years in a county jail and a fine of up to $1,000#Up to five years in a county jail and a fine of up to $5,000"},
{"testId":568,"qid":24515,"description":"If you finish a traffic violator school course, the DMV will deduct ______ from your total point.","explanation":"If you finish a court-ordered traffic violator school course, the DMV will deduct one point from your overall point total on your driving record. Speeding, running a red light, and making an illegal turn are all one-point offenses.) You may retake the traffic violator school course to avoid extra points, but only once every 18 months. [Administrative, California Driver Handbook, Traffic Violator School Convictions]","imageName":"","correctAnswer":4659,"isBookmarked":0,"aid":"4656#4657#4659","text":"2 points#3 points#1 point"},
{"testId":568,"qid":24516,"description":"If you hit a parked car or other property and fled the scene, you could face up to_____in jail.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24049","text":"6 months#30 days#90 days"},
{"testId":1035,"qid":24516,"description":"If you hit a parked car or other property and fled the scene, you could face up to_____in jail.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24049","text":"6 months#30 days#90 days"},
{"testId":568,"qid":24517,"description":"If you're convicted of hit-and-run with no injuries, you may be sent to jail for up to:","explanation":null,"imageName":"","correctAnswer":23254,"isBookmarked":0,"aid":"23254#26650#26651","text":"Six months#One year#Two years"},
{"testId":568,"qid":24518,"description":"If you accumulate ___ points in a 12-month period, you will lose your driver's license under California's Negligent Operator Treatment System (NOTS).","explanation":"You will lose your driving license if you get 4 points in 12 months, 6 points in 24 months, or 8 points in 36 months under California's Negligent Operator Treatment System (NOTS). At-fault crashes and most common traffic offenses are considered one-point violations. DUI, reckless driving, fleeing the scene of an accident (hit and run), and driving when your license is suspended or revoked are all two-point offences. [Administrative Driver Record Points, California Driver Handbook]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916","text":"5#4#6"},
{"testId":568,"qid":24519,"description":"Your vehicle may be confiscated for ___ days if you lend it to an unlicensed driver.","explanation":"Never lend your car to another motorist unless you first confirm that he or she has a valid driver's license. If he or she is detected driving without a license or with a suspended or revoked license, the car will be confiscated for up to 30 days. [Unlicensed Drivers, Other Licensing Info, California Driver Handbook]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6356#6477","text":"10#120#30"},
{"testId":1035,"qid":24519,"description":"Your vehicle may be confiscated for ___ days if you lend it to an unlicensed driver.","explanation":"Never lend your car to another motorist unless you first confirm that he or she has a valid driver's license. If he or she is detected driving without a license or with a suspended or revoked license, the car will be confiscated for up to 30 days. [Unlicensed Drivers, Other Licensing Info, California Driver Handbook]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6356#6477","text":"10#120#30"},
{"testId":568,"qid":24520,"description":"If a California driver is found guilty of smoking inside a car when a minor is present, the driver faces a fine of up to :","explanation":"If a California driver is discovered to be smoking inside a car when a youngster is present, the driver faces a punishment of up to $100. [Do Nots, Additional Driving Laws/Rules, California Driver Handbook]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994","text":"$100#$25#$50"},
{"testId":568,"qid":24521,"description":"Littering is punishable by a _______ fine in California. You might also be asked to _________.","explanation":"If you are convicted of littering, you will be obliged to pay a fine of $1,000. You may also be expected to clean up trash. Things Not to Do, Additional Driving Laws/Rules, California Driver Handbook], [CA PEN 374] .4]","imageName":"","correctAnswer":42306,"isBookmarked":0,"aid":"24996#42305#42306","text":"$50; spend 3 days in jail#$500; pick up litter#$1,000; pick up litter"},
{"testId":568,"qid":24522,"description":"The California Compulsory Financial Responsibility Law requires all vehicle operators to have:","explanation":"All California drivers are required to maintain financial responsibility (liability coverage) at all times under the Compulsory Financial Responsibility Law. Most drivers prefer to obtain a vehicle insurance coverage to satisfy this need. However, there are three alternative options for being financially responsible: Deposit $35,000 with the DMV, secure a $35,000 surety bond, or receive a self-insurance certificate from the DMV. [Administrative, Financial Responsibility, California Driver Handbook]","imageName":"","correctAnswer":25001,"isBookmarked":0,"aid":"25001#25002#42307","text":"proof of financial responsibility#a job#a vehicle insurance policy"},
{"testId":568,"qid":24523,"description":"Under California's Compulsory Financial Responsibility Law, which of the following can be used instead of liability insurance?","explanation":"If you do not want to have your car insured, the Compulsory Financial Responsibility Law permits you to deposit $35,000 with the DMV, secure a $35,000 surety bond from a California-licensed corporation, or receive a self-insurance certificate from the DMV. [Administrative, Financial Responsibility, California Driver Handbook]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#25004#42308","text":"Both of the above#A deposit of $35,000 with the DMV#A surety bond of $35,000"},
{"testId":568,"qid":24524,"description":"What is the minimum amount of property damage coverage that a California vehicle insurance policy must have?","explanation":"According to California's Compulsory Financial Responsibility Law, every vehicle driven in the state must be insured with at least $5,000 in property damage coverage. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"3195#11658#12047","text":"$5,000#$10,000#$15,000"},
{"testId":568,"qid":24525,"description":"If someone was hurt or killed, or if there was more than ________ worth of property damage, you must report the crash to the DMV.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#3195#11669","text":"$1,000#$5,000#$2,000"},
{"testId":568,"qid":24526,"description":"If you are involved in an accident while uninsured, your license will be suspended for up to :","explanation":"If you are involved in an accident while uninsured, your license will be suspended for up to four years. However, after one year, your license may be restored provided you receive and submit an SR-22 Proof of Financial Responsibility certificate to the DMV. (Note: Because SR-22 insurance is considered high-risk insurance, your insurance premiums will be more than normal.) [Collisions Are Not Accidents, California Driver Handbook, Handling Emergencies]","imageName":"202006141611292838.jpg","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23183","text":"2 years#4 years#3 years"},
{"testId":568,"qid":24527,"description":"California's drunk driving legislation also includes a ________ driving provision.","explanation":"The drunk driving statute in California also applies to drugged driving. [Driving Under the Influence of Alcohol or Drugs is Dangerous, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":11976,"isBookmarked":0,"aid":"11975#11976#42309","text":"morning#drugged#reckless"},
{"testId":568,"qid":24528,"description":"In California, it is against the law to operate a motor vehicle while your driving abilities are impaired by:","explanation":"It is prohibited to take any medication that affects your ability to drive safely. It makes no difference if the medicine is prescribed, over-the-counter, or illicit. If you are unsure if a medicine may impair your driving, see your doctor or pharmacist or read the warning label. [Carrying Alcohol in a Vehicle, Driving While Under the Influence of Alcohol, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":42311,"isBookmarked":0,"aid":"8833#42310#42311","text":"Prescription drugs#over-the-counter drugs#any of the above"},
{"testId":568,"qid":24529,"description":"Which of these things is against the law in California?","explanation":"It is illegal in California to have an open container of alcohol in a vehicle's console or glove box. [Alcohol and Cannabis Products in a Vehicle, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":42312,"isBookmarked":0,"aid":"25014#25015#42312","text":"Storing alcohol inside a vehicle#Drinking alcohol when over the age of 21#Storing an open container of alcohol in a vehicle's console or glove compartment"},
{"testId":568,"qid":24530,"description":"After consuming __ alcoholic drink, a 140-pound woman will get legally inebriated (s). After consuming __ alcoholic drint, a 180-pound male will get legally inebriated.","explanation":"An average 140-pound lady will attain a blood alcohol content (BAC) of 0.08 percent after ingesting 2 alcoholic drinks. An average 180-pound male will attain the same BAC after ingesting 3 alcoholic drinks. (1 alcoholic drink = 1.5 ounces of 80-proof whiskey, 12 ounces of 5 percent beer, or 5 ounces of 12 percent wine.) [Get a DUI - Lose Your License, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":25018,"isBookmarked":0,"aid":"13745#25018#25019","text":"1; 2#2; 3#3; 4"},
{"testId":568,"qid":24531,"description":"If you are caught using a handheld cell phone while driving, you will face penalties.","explanation":null,"imageName":"","correctAnswer":31384,"isBookmarked":0,"aid":"31384#42313#42314","text":"you will be fined#one point will be added to your driving record#both of the above will happen"},
{"testId":568,"qid":24532,"description":"If you leave the scene of an accident that wounded someone, you could be sent to jail for up to:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23183","text":"2 years#1 year#3 years"},
{"testId":1035,"qid":24532,"description":"If you leave the scene of an accident that wounded someone, you could be sent to jail for up to:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23183","text":"2 years#1 year#3 years"},
{"testId":568,"qid":24533,"description":"Who can be charged  if you carry a sixteen-year-old passenger who is not wearing a seat belt?","explanation":null,"imageName":"","correctAnswer":40929,"isBookmarked":0,"aid":"9642#34753#40929","text":"You#The passenger#Both you and the passenger"},
{"testId":568,"qid":24534,"description":"It is prohibited for California drivers of age 21 and older to drive with a blood alcohol content (BAC) of :","explanation":null,"imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318","text":"0.05%#0.08%#0.02%"},
{"testId":1035,"qid":24534,"description":"It is prohibited for California drivers of age 21 and older to drive with a blood alcohol content (BAC) of :","explanation":null,"imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318","text":"0.05%#0.08%#0.02%"},
{"testId":568,"qid":24535,"description":"What is the maximum legal blood alcohol content (BAC) that a driver may have while operating a vehicle if he or she is on DUI probation?","explanation":"When driving a vehicle in California, a motorist who is on DUI probation due to past offenses may not have any alcohol in their blood. A BAC of more than 0.01 percent may be obtained with only one alcoholic drink. [BAC Limit, Alcohol/Drugs and Driving is Dangerous, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":4652,"isBookmarked":0,"aid":"3281#4251#4652","text":"0.04%#0.02%#0.00%"},
{"testId":1035,"qid":24535,"description":"What is the maximum legal blood alcohol content (BAC) that a driver may have while operating a vehicle if he or she is on DUI probation?","explanation":"When driving a vehicle in California, a motorist who is on DUI probation due to past offenses may not have any alcohol in their blood. A BAC of more than 0.01 percent may be obtained with only one alcoholic drink. [BAC Limit, Alcohol/Drugs and Driving is Dangerous, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":4652,"isBookmarked":0,"aid":"3281#4251#4652","text":"0.04%#0.02%#0.00%"},
{"testId":568,"qid":24536,"description":"Any driver who operates a vehicle conveys implied agreement to:","explanation":"When they operate a vehicle on California's roads, drivers provide their implicit agreement to chemical testing for alcohol and drugs. [Admin Per Se, Driving is Dangerous with Alcohol/Drugs, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":25025,"isBookmarked":0,"aid":"25024#25025#25026","text":"the release of his or her medical records#chemical drug and alcohol testing#searches of his or her home"},
{"testId":1035,"qid":24536,"description":"Any driver who operates a vehicle conveys implied agreement to:","explanation":"When they operate a vehicle on California's roads, drivers provide their implicit agreement to chemical testing for alcohol and drugs. [Admin Per Se, Driving is Dangerous with Alcohol/Drugs, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":25025,"isBookmarked":0,"aid":"25024#25025#25026","text":"the release of his or her medical records#chemical drug and alcohol testing#searches of his or her home"},
{"testId":568,"qid":24537,"description":"You have _________ from the date of purchase to transfer ownership of a car purchased from a person.","explanation":"If you buy a car from a private party, you must transfer ownership within 10 days. The California Certificate of Title, automobile registration fees, and perhaps additional papers must be submitted to the DMV. In addition, you must file the Notice of Transfer and Release of Liability (REG 138) form within 5 days. You may, however, be able to submit it online through the DMV website. (Note: If you buy a car from a commercial auto dealer, the dealer will take care of many of these aspects for you.) [Administrative Vehicle Registration Requirements, California Driver Handbook]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#23886#40739","text":"10 days#30 days#5 days"},
{"testId":1035,"qid":24537,"description":"You have _________ from the date of purchase to transfer ownership of a car purchased from a person.","explanation":"If you buy a car from a private party, you must transfer ownership within 10 days. The California Certificate of Title, automobile registration fees, and perhaps additional papers must be submitted to the DMV. In addition, you must file the Notice of Transfer and Release of Liability (REG 138) form within 5 days. You may, however, be able to submit it online through the DMV website. (Note: If you buy a car from a commercial auto dealer, the dealer will take care of many of these aspects for you.) [Administrative Vehicle Registration Requirements, California Driver Handbook]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#23886#40739","text":"10 days#30 days#5 days"},
{"testId":568,"qid":24538,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033","text":"$500#$1,000#$2,000"},
{"testId":1035,"qid":24538,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033","text":"$500#$1,000#$2,000"},
{"testId":568,"qid":24539,"description":"If you are convicted of DUI for the first time, you might face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25170","text":"3 months#6 months#1 month"},
{"testId":1035,"qid":24539,"description":"If you are convicted of DUI for the first time, you might face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25170","text":"3 months#6 months#1 month"},
{"testId":568,"qid":24540,"description":"First-time DUI convictions result in license suspension for _____聽for adult drivers.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272","text":"30 days#1 year#6 months"},
{"testId":568,"qid":24541,"description":"If a California driver's first DUI conviction is for a BAC of 0.20 percent or greater, his or her license will be suspended for:","explanation":"A California driver's license will be banned for 10 months if his or her first DUI conviction is for a BAC of 0.20 percent or higher. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":31875,"isBookmarked":0,"aid":"23153#31875#42315","text":"6 months#10 months#8 months"},
{"testId":1035,"qid":24541,"description":"If a California driver's first DUI conviction is for a BAC of 0.20 percent or greater, his or her license will be suspended for:","explanation":"A California driver's license will be banned for 10 months if his or her first DUI conviction is for a BAC of 0.20 percent or higher. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":31875,"isBookmarked":0,"aid":"23153#31875#42315","text":"6 months#10 months#8 months"},
{"testId":568,"qid":24542,"description":"If you injure someone while driving under the influence, your license will be suspended for:","explanation":"A California driver's license will be suspended for one year if he or she is guilty of hurting another person while driving under the influence. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#25031","text":"1 year#30 days#18 months"},
{"testId":1035,"qid":24542,"description":"If you injure someone while driving under the influence, your license will be suspended for:","explanation":"A California driver's license will be suspended for one year if he or she is guilty of hurting another person while driving under the influence. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#25031","text":"1 year#30 days#18 months"},
{"testId":568,"qid":24543,"description":"A DUI conviction in California will stay on a driver's driving record for __ years.","explanation":"A DUI conviction will stay on a driver's record for ten years. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6388","text":"10#5#2"},
{"testId":1035,"qid":24543,"description":"A DUI conviction in California will stay on a driver's driving record for __ years.","explanation":"A DUI conviction will stay on a driver's record for ten years. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6388","text":"10#5#2"},
{"testId":568,"qid":24544,"description":"If you get two or more DUI convictions in California, your license could be taken away for up to _____ years.","explanation":"Second and subsequent DUI convictions result in harsher penalties, such as a two-year license suspension or a five-year license revocation. [21+ Drivers - DUI Programs and Restricted Driver Licenses, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389","text":"5#2#3"},
{"testId":1035,"qid":24544,"description":"If you get two or more DUI convictions in California, your license could be taken away for up to _____ years.","explanation":"Second and subsequent DUI convictions result in harsher penalties, such as a two-year license suspension or a five-year license revocation. [21+ Drivers - DUI Programs and Restricted Driver Licenses, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389","text":"5#2#3"},
{"testId":569,"qid":24546,"description":"Which of the following Colorado drivers under the age of 18 may sign the Affidavit of Liability?","explanation":"A parent, stepparent, grandparent with Power of Attorney, legal guardian, or spouse who is 18 years of age or older must sign an Affidavit of Liability when a Colorado driver under the age of 18 receives a driver's license or permit. [Parent Consent for Minor Affidavit of Liability and Guardianship, Colorado Driver Handbook, The Driver License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25037#25038#25039","text":"All of the above#Parent#Stepparent#Grandparent with Power of Attorney"},
{"testId":1036,"qid":24546,"description":"Which of the following Colorado drivers under the age of 18 may sign the Affidavit of Liability?","explanation":"A parent, stepparent, grandparent with Power of Attorney, legal guardian, or spouse who is 18 years of age or older must sign an Affidavit of Liability when a Colorado driver under the age of 18 receives a driver's license or permit. [Parent Consent for Minor Affidavit of Liability and Guardianship, Colorado Driver Handbook, The Driver License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25037#25038#25039","text":"All of the above#Parent#Stepparent#Grandparent with Power of Attorney"},
{"testId":569,"qid":24547,"description":"If the person who signed the Affidavit of Liability for a state driver under 18 no longer desires to be legally accountable for the motorist, the permit or license will be:","explanation":"If the consenting adult refuses to sign the Affidavit of Liability for a Colorado driver under the age of 18, the driver's license or permit will be revoked. [Parent Consent for Minor Affidavit of Liability and Guardianship, Colorado Driver Handbook, The Driver License]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#25040#25041","text":"revoked#canceled#suspended until the driver's 18th birthday#suspended until the driver's 21st birthday"},
{"testId":1036,"qid":24547,"description":"If the person who signed the Affidavit of Liability for a state driver under 18 no longer desires to be legally accountable for the motorist, the permit or license will be:","explanation":"If the consenting adult refuses to sign the Affidavit of Liability for a Colorado driver under the age of 18, the driver's license or permit will be revoked. [Parent Consent for Minor Affidavit of Liability and Guardianship, Colorado Driver Handbook, The Driver License]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#25040#25041","text":"revoked#canceled#suspended until the driver's 18th birthday#suspended until the driver's 21st birthday"},
{"testId":569,"qid":24548,"description":"Driver education permit holders must be between _________.","explanation":"Drivers between the ages of 15 and 15 1/2 in Colorado are eligible for a driving education permit. [Driving Permits and Licenses, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25043,"isBookmarked":0,"aid":"25042#25043#25044#25045","text":"14 and 15#15 and 15 1/2#15 and 16#16 and 18"},
{"testId":569,"qid":24549,"description":"The supervising adult for a Colorado driver who merely has a learner's permit must:","explanation":"A Colorado driver education permit permits a licensed driver 21 years of age or older to sit in the passenger seat when a driver with an instruction permit, regardless of age, operates the vehicle.","imageName":"","correctAnswer":25049,"isBookmarked":0,"aid":"25046#25047#25048#25049","text":"be 21 years of age or older#be a licensed Colorado driver#occupy the passenger seat#comply with all of the above"},
{"testId":569,"qid":24550,"description":"Who can watch over a Colorado driver between the ages of 15 and 15 1/2 who has a minor's driving education permit besides a driver education teacher?聽:","explanation":"Only the driver education instructor and the adult who signed the Affidavit of Liability necessary for the instruction permit may supervise a Colorado driver between the ages of 15 and 15 1/2 who possesses a minor's driving education permit. [Driver Education Permit, Permits and Licenses for Instruction, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25052,"isBookmarked":0,"aid":"25050#25051#25052#25053","text":"A parent#A DMV official#The adult who signed the Affidavit of Liability#A teacher"},
{"testId":569,"qid":24551,"description":"When filing for a minor driver license, drivers under the age of 16 1/2 must provide their Drive Time Log Sheets and proof of which of the following?","explanation":"When applying for a minor driver license in Colorado, a motorist under the age of 18 must present a Drive Time Log form. The log sheet must demonstrate a minimum of 50 hours of driving experience, with at least 10 of those hours spent driving at night. Furthermore, drivers under the age of 16 1/2 must provide proof of having completed 6 hours of behind-the-wheel (BTW) instruction with a certified driving instructor or 12 hours with a parent, guardian, or other responsible adult if no driver training institution is within 30 miles. The driver's 6-hour BTW instruction with his or her driving education instructor may be included in the 50-hour total. If the driver completes 12 hours of BTW instruction with a parent, guardian, or alternative permit supervisor, the 12 hours are IN ADDITION to the log sheet's 50-hour requirement, for a total of 62 hours. [Minor Driver's License, Permits and Licenses for Instruction, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":12020,"isBookmarked":0,"aid":"897#12020#12021#12022","text":"None of the above#6 hours of behind-the-wheel training with an instructor or 12 hours with a parent, legal guardian, etc#3 hours of behind-the-wheel training with an instructor or 6 hours with a parent, legal guardian, etc#20 hours of total driving experience witnessed by any licensed driver"},
{"testId":569,"qid":24552,"description":"Teenagers who are _____ or older have to get a learner's permit before they can get a minor driver's license, but they don't have to keep the permit for a certain amount of time.","explanation":"If a minor in Colorado above the age of 18 files for a minor driving license, he or she must first acquire an instruction permit. However, he or she is not obligated to keep it for any period of time before applying for a license. [Minor Driver's License, Permits and Licenses for Instruction, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":569,"qid":24553,"description":"The reinstatement of a Colorado resident's driving privileges does not entail the reinstatement of his or her :","explanation":"In Colorado, restoring driving privileges does not immediately restore a resident's driver's license. [Reinstatement, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25055,"isBookmarked":0,"aid":"25054#25055#25056#25057","text":"auto insurance policy#driver's license#vehicle registration#other documents"},
{"testId":1036,"qid":24553,"description":"The reinstatement of a Colorado resident's driving privileges does not entail the reinstatement of his or her :","explanation":"In Colorado, restoring driving privileges does not immediately restore a resident's driver's license. [Reinstatement, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25055,"isBookmarked":0,"aid":"25054#25055#25056#25057","text":"auto insurance policy#driver's license#vehicle registration#other documents"},
{"testId":569,"qid":24554,"description":"In Colorado, which of the following is a restraining action that temporarily invalidates a driver's license and removes the individual's driving privileges?","explanation":"When a Colorado driver's license is suspended, the resident's driving privileges are revoked and the license is rendered invalid for a certain length of time. [Suspension, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":22011,"isBookmarked":0,"aid":"22011#23161#23162#25058","text":"Suspension#Revocation#Cancellation#Denial"},
{"testId":1036,"qid":24554,"description":"In Colorado, which of the following is a restraining action that temporarily invalidates a driver's license and removes the individual's driving privileges?","explanation":"When a Colorado driver's license is suspended, the resident's driving privileges are revoked and the license is rendered invalid for a certain length of time. [Suspension, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":22011,"isBookmarked":0,"aid":"22011#23161#23162#25058","text":"Suspension#Revocation#Cancellation#Denial"},
{"testId":569,"qid":24555,"description":"If a Colorado resident's driver's license is revoked, the resident must _________ in order to legally drive again.","explanation":"To legally operate a vehicle in Colorado, a driver whose license has been revoked must retake and pass the written and driving sections of the driver's test, get a new instruction permit, and obtain a new driver's license. [Suspension, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":6618,"isBookmarked":0,"aid":"6618#12026#12028#25059","text":"Do all of the above#pass the written driver's exam#pass the driving exam#purchase another instruction permit and driver's license"},
{"testId":1036,"qid":24555,"description":"If a Colorado resident's driver's license is revoked, the resident must _________ in order to legally drive again.","explanation":"To legally operate a vehicle in Colorado, a driver whose license has been revoked must retake and pass the written and driving sections of the driver's test, get a new instruction permit, and obtain a new driver's license. [Suspension, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":6618,"isBookmarked":0,"aid":"6618#12026#12028#25059","text":"Do all of the above#pass the written driver's exam#pass the driving exam#purchase another instruction permit and driver's license"},
{"testId":569,"qid":24556,"description":"Which of the following would require the DMV to reexamine a Colorado driver?","explanation":"If a member of a Colorado driver's family requests in writing that the driver be re-examined, the motorist must retake the visual, written, and driving components of the state's driver's examination. [Re-examination, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25060,"isBookmarked":0,"aid":"12030#12032#12374#25060","text":"2 accidents within 10 years#1 accident within 5 years#License suspension#A family member's written request"},
{"testId":1036,"qid":24556,"description":"Which of the following would require the DMV to reexamine a Colorado driver?","explanation":"If a member of a Colorado driver's family requests in writing that the driver be re-examined, the motorist must retake the visual, written, and driving components of the state's driver's examination. [Re-examination, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25060,"isBookmarked":0,"aid":"12030#12032#12374#25060","text":"2 accidents within 10 years#1 accident within 5 years#License suspension#A family member's written request"},
{"testId":569,"qid":24557,"description":"Which of the following would require the DMV to reexamine a Colorado driver?","explanation":"Involvement in two or more accidents within three years, involvement in a fatal car accident, or a doctor's medical report may necessitate a re-examination by the Division of Motor Vehicles. [Re-examination, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12033#25061#25062","text":"All of the above#2 accidents within 3 years#Involvement in a fatal accident#A doctor's medical report"},
{"testId":1036,"qid":24557,"description":"Which of the following would require the DMV to reexamine a Colorado driver?","explanation":"Involvement in two or more accidents within three years, involvement in a fatal car accident, or a doctor's medical report may necessitate a re-examination by the Division of Motor Vehicles. [Re-examination, License Retention, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12033#25061#25062","text":"All of the above#2 accidents within 3 years#Involvement in a fatal accident#A doctor's medical report"},
{"testId":569,"qid":24558,"description":"In Colorado, you start out with ___ point(s) on your driving record.","explanation":"In Colorado, you start with a clean driving record. The Colorado Point System, The Driver License, and The Colorado Driver Handbook are all available online.","imageName":"","correctAnswer":12036,"isBookmarked":0,"aid":"6388#6389#6469#12036","text":"2#3#1#0"},
{"testId":1036,"qid":24558,"description":"In Colorado, you start out with ___ point(s) on your driving record.","explanation":"In Colorado, you start with a clean driving record. The Colorado Point System, The Driver License, and The Colorado Driver Handbook are all available online.","imageName":"","correctAnswer":12036,"isBookmarked":0,"aid":"6388#6389#6469#12036","text":"2#3#1#0"},
{"testId":569,"qid":24559,"description":"A conviction happens when:","explanation":"When a Colorado driver enters a guilty plea, pays a traffic ticket, or accepts a plea agreement, a conviction is recorded on his or her driving record. [Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25066,"isBookmarked":0,"aid":"25063#25064#25065#25066","text":"you plead guilty to a charge#you pay a fine#you accept a plea bargain#any of the above takes place"},
{"testId":1036,"qid":24559,"description":"A conviction happens when:","explanation":"When a Colorado driver enters a guilty plea, pays a traffic ticket, or accepts a plea agreement, a conviction is recorded on his or her driving record. [Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25066,"isBookmarked":0,"aid":"25063#25064#25065#25066","text":"you plead guilty to a charge#you pay a fine#you accept a plea bargain#any of the above takes place"},
{"testId":569,"qid":24560,"description":"A minor driver under the age of 18 in Colorado will have his or her license suspended if he or she accumulates 6 points in a ___-month period.","explanation":"If a Colorado driver under the age of 18 accumulates 6 or more points in a 12-month period, his or her driver's license will be suspended. [Minor Driver Under 18 Years of Age, Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6478#6916#11359#12040","text":"12#6#18#24"},
{"testId":1036,"qid":24560,"description":"A minor driver under the age of 18 in Colorado will have his or her license suspended if he or she accumulates 6 points in a ___-month period.","explanation":"If a Colorado driver under the age of 18 accumulates 6 or more points in a 12-month period, his or her driver's license will be suspended. [Minor Driver Under 18 Years of Age, Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6478#6916#11359#12040","text":"12#6#18#24"},
{"testId":569,"qid":24562,"description":"A colorado driver's license will be suspended if he/she accumulates at-least ___ points in a 12-month period.","explanation":"Drivers over the age of 21 in Colorado will have their licenses suspended if they get 12 or more points in a 12-month period. [Adult Driver, Colorado Point System, Colorado Driver Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5449#6478#7559#11342","text":"5#12#7#9"},
{"testId":1036,"qid":24562,"description":"A colorado driver's license will be suspended if he/she accumulates at-least ___ points in a 12-month period.","explanation":"Drivers over the age of 21 in Colorado will have their licenses suspended if they get 12 or more points in a 12-month period. [Adult Driver, Colorado Point System, Colorado Driver Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5449#6478#7559#11342","text":"5#12#7#9"},
{"testId":569,"qid":24563,"description":"Which of the following may lead to the suspension of your driver's licence in Colorado?","explanation":"DUI convictions, failure to report an accident, or refusal to submit to drug or alcohol testing at the request of a law enforcement official may all result in your Colorado driver's license being suspended. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#23205#25067#25068","text":"Any of the above#A DUI conviction#Failing to report an accident#Refusing to submit to drug or alcohol testing"},
{"testId":1036,"qid":24563,"description":"Which of the following may lead to the suspension of your driver's licence in Colorado?","explanation":"DUI convictions, failure to report an accident, or refusal to submit to drug or alcohol testing at the request of a law enforcement official may all result in your Colorado driver's license being suspended. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#23205#25067#25068","text":"Any of the above#A DUI conviction#Failing to report an accident#Refusing to submit to drug or alcohol testing"},
{"testId":569,"qid":24564,"description":"If a driver is found guilty of driving while his or her license is suspended, revoked, or canceled, his or her license will be suspended for an additional ______ period, beginning on the date the license would have been reinstated.","explanation":"If a Colorado driver is convicted of driving while his or her license is suspended, revoked, or canceled, his or her license will be suspended for an extra year. This suspension will take effect on the day the motorist was supposed to recover driving privileges. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#1453","text":"30 days#1 year#6 months#3 months"},
{"testId":1036,"qid":24564,"description":"If a driver is found guilty of driving while his or her license is suspended, revoked, or canceled, his or her license will be suspended for an additional ______ period, beginning on the date the license would have been reinstated.","explanation":"If a Colorado driver is convicted of driving while his or her license is suspended, revoked, or canceled, his or her license will be suspended for an extra year. This suspension will take effect on the day the motorist was supposed to recover driving privileges. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#1453","text":"30 days#1 year#6 months#3 months"},
{"testId":569,"qid":24565,"description":"Are drivers need to have automobile insurance on their vehicles?","explanation":"Drivers in Colorado are obliged to have car insurance on their vehicle and evidence of insurance within the vehicle at all times. This card or document must contain the name of the insurance company, contact information, policy number, and information about the vehicle covered by the policy. [The Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws, and the Motor Vehicle Insurance Data Base Law]","imageName":"","correctAnswer":25070,"isBookmarked":0,"aid":"10738#25069#25070#25071","text":"None of these answers are correct#No, they are not required to have automobile insurance coverage#Yes, they must have automobile insurance coverage#They may need automobile insurance coverage"},
{"testId":569,"qid":24566,"description":"What is the minimum amount of property damage coverage that a Colorado car insurance policy must have?","explanation":"A Colorado driver must maintain an auto insurance policy on his or her vehicle that includes at least $15,000 in property damage coverage and at least $25,000 in bodily injury or death coverage and $50,000 in multiple bodily injury or death coverage. [Financial Responsibility Law/Compulsory Insurance, Understanding Colorado's Motor Vehicle Laws, Colorado Driver Handbook]","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":569,"qid":24567,"description":"Which of the following can exclude a driver from providing proof of insurance for his or her car following an accident?","explanation":"A Colorado motorist who is involved in any sort of collision, regardless of the circumstances, must provide evidence of insurance for his or her car. [Financial Responsibility Law/Compulsory Insurance, Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#25072#25073#25074","text":"None of the above#If the vehicle is parked#If the driver is not at fault#If damages are less than $1,000"},
{"testId":1036,"qid":24567,"description":"Which of the following can exclude a driver from providing proof of insurance for his or her car following an accident?","explanation":"A Colorado motorist who is involved in any sort of collision, regardless of the circumstances, must provide evidence of insurance for his or her car. [Financial Responsibility Law/Compulsory Insurance, Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#25072#25073#25074","text":"None of the above#If the vehicle is parked#If the driver is not at fault#If damages are less than $1,000"},
{"testId":569,"qid":24568,"description":"In Colorado, if a driver does not maintain his or her vehicle's insurance policy on, the driver must:","explanation":"If a Colorado driver does not choose to maintain a state-mandated vehicle insurance coverage, he or she must get a certificate of self-insurance from the Division of Motor Vehicles. [Financial Responsibility Law/Compulsory Insurance, Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws] This alternative, however, is only accessible to residents and enterprises with at least 25 cars. [Requirements for Registration, Colorado Department of Revenue, Division of Motor Vehicles website]","imageName":"","correctAnswer":25077,"isBookmarked":0,"aid":"25075#25076#25077#25078","text":"pay for damages out of pocket#forfeit his or her license#obtain a certificate of self-insurance from the DMV#pay a fine each time he or she is pulled over by police"},
{"testId":1036,"qid":24568,"description":"In Colorado, if a driver does not maintain his or her vehicle's insurance policy on, the driver must:","explanation":"If a Colorado driver does not choose to maintain a state-mandated vehicle insurance coverage, he or she must get a certificate of self-insurance from the Division of Motor Vehicles. [Financial Responsibility Law/Compulsory Insurance, Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws] This alternative, however, is only accessible to residents and enterprises with at least 25 cars. [Requirements for Registration, Colorado Department of Revenue, Division of Motor Vehicles website]","imageName":"","correctAnswer":25077,"isBookmarked":0,"aid":"25075#25076#25077#25078","text":"pay for damages out of pocket#forfeit his or her license#obtain a certificate of self-insurance from the DMV#pay a fine each time he or she is pulled over by police"},
{"testId":569,"qid":24569,"description":"A minor driver must hold an instruction permit for at least ________ before seeking for a driver's license in Colorado, according to the Graduated License Law.","explanation":"Before applying for a license in Colorado, a minor must obtain an instruction permit for at least one year. [Colorado's Graduated License Law, Understanding Colorado's Motor Vehicle Laws, Colorado Driver Handbook]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1274#1454","text":"6 months#12 months#24 months#9 months"},
{"testId":569,"qid":24570,"description":"Which of the following is unlawful for passengers in Colorado?","explanation":"Drivers and passengers in Colorado are not permitted to consume alcohol or have open containers of alcohol in their possession. [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":25079,"isBookmarked":0,"aid":"897#25017#25079#25080","text":"None of the above#Transporting alcohol in a vehicle#Possessing an open container of alcohol in a moving vehicle#Purchasing alcohol from a convenience store"},
{"testId":1036,"qid":24570,"description":"Which of the following is unlawful for passengers in Colorado?","explanation":"Drivers and passengers in Colorado are not permitted to consume alcohol or have open containers of alcohol in their possession. [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":25079,"isBookmarked":0,"aid":"897#25017#25079#25080","text":"None of the above#Transporting alcohol in a vehicle#Possessing an open container of alcohol in a moving vehicle#Purchasing alcohol from a convenience store"},
{"testId":569,"qid":24571,"description":"Driver's ability to drive will be impaired if his or her blood alcohol content (BAC) is:","explanation":"If a Colorado motorist has a blood alcohol concentration (BAC) of 0.05 percent to 0.07 percent, he or she will be charged with Driving While Ability to Drive is Impaired (DWAI). [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":12060,"isBookmarked":0,"aid":"12059#12060#12061#12062","text":"0.03%-0.05%#0.05%-0.07%#0.05%-0.10%#up to 0.08%"},
{"testId":1036,"qid":24571,"description":"Driver's ability to drive will be impaired if his or her blood alcohol content (BAC) is:","explanation":"If a Colorado motorist has a blood alcohol concentration (BAC) of 0.05 percent to 0.07 percent, he or she will be charged with Driving While Ability to Drive is Impaired (DWAI). [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":12060,"isBookmarked":0,"aid":"12059#12060#12061#12062","text":"0.03%-0.05%#0.05%-0.07%#0.05%-0.10%#up to 0.08%"},
{"testId":569,"qid":24572,"description":"What is the minimum blood alcohol content (BAC) for a DUI conviction for an adult Colorado driver?","explanation":"A motorist 21 years of age or older who has a BAC of 0.08 percent or above will be charged with Driving Under the Influence in Colorado (DUI). [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11768","text":"0.08%#0.02%#0.05%#0.10%"},
{"testId":1036,"qid":24572,"description":"What is the minimum blood alcohol content (BAC) for a DUI conviction for an adult Colorado driver?","explanation":"A motorist 21 years of age or older who has a BAC of 0.08 percent or above will be charged with Driving Under the Influence in Colorado (DUI). [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11768","text":"0.08%#0.02%#0.05%#0.10%"},
{"testId":569,"qid":24573,"description":"Which of the following will assist an intoxicated driver in becoming sober?","explanation":"Time is the only thing that will remove alcohol from your system. Each hour when alcohol is not consumed reduces a person's BAC by an average of 0.015 percent. [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#25082","text":"None of the above#Coffee#A cold shower#Bread"},
{"testId":1036,"qid":24573,"description":"Which of the following will assist an intoxicated driver in becoming sober?","explanation":"Time is the only thing that will remove alcohol from your system. Each hour when alcohol is not consumed reduces a person's BAC by an average of 0.015 percent. [Alcohol and Driving, Driving While Impaired, Colorado Driver Handbook]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#25082","text":"None of the above#Coffee#A cold shower#Bread"},
{"testId":569,"qid":24574,"description":"Under Colorado's Express Consent Law, a driver automatically agrees to be tested for drugs or alcohol when :","explanation":"A motorist who drives a vehicle on a Colorado highway must agree to being tested for alcohol or drugs if requested to do so by a law enforcement official, according to the Express Consent Law. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":25083,"isBookmarked":0,"aid":"25083#25084#25085#25086","text":"he or she operates a vehicle on CO roadways#he or she obtains an adult's driver's license#he or she obtains a driver's instruction permit#he or she registers a vehicle"},
{"testId":1036,"qid":24574,"description":"Under Colorado's Express Consent Law, a driver automatically agrees to be tested for drugs or alcohol when :","explanation":"A motorist who drives a vehicle on a Colorado highway must agree to being tested for alcohol or drugs if requested to do so by a law enforcement official, according to the Express Consent Law. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":25083,"isBookmarked":0,"aid":"25083#25084#25085#25086","text":"he or she operates a vehicle on CO roadways#he or she obtains an adult's driver's license#he or she obtains a driver's instruction permit#he or she registers a vehicle"},
{"testId":569,"qid":24575,"description":"Which of the following is the minimum punishment for a driver's first offense of driving while unable to do so (DWAI) in Colorado?","explanation":"If an adult Colorado driver is convicted of DWAI for the first time, his or her sentence will include at least the following: He or she will get 8 points on his or her driving record, a $200 fine, 2 days in prison, and 24 hours of community service. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25087#25088#25089","text":"All of the above#8 points on his or her driving record#A minimum of $200 in fines#A minimum of 2 days in jail"},
{"testId":1036,"qid":24575,"description":"Which of the following is the minimum punishment for a driver's first offense of driving while unable to do so (DWAI) in Colorado?","explanation":"If an adult Colorado driver is convicted of DWAI for the first time, his or her sentence will include at least the following: He or she will get 8 points on his or her driving record, a $200 fine, 2 days in prison, and 24 hours of community service. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25087#25088#25089","text":"All of the above#8 points on his or her driving record#A minimum of $200 in fines#A minimum of 2 days in jail"},
{"testId":569,"qid":24576,"description":"An adult driver's second conviction for driving while ability impaired (DWAI) within five years will result in the revocation of his or her driver's license for:","explanation":"If an adult Colorado driver is convicted of DWAI for the second time, his or her driver's license will be suspended for one year. In addition, the driver will be punished to no less than 10 days in prison and no less than 48 hours of community service, as well as a minimum fine of $600. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#24049","text":"2 years#1 year#30 days#90 days"},
{"testId":1036,"qid":24576,"description":"An adult driver's second conviction for driving while ability impaired (DWAI) within five years will result in the revocation of his or her driver's license for:","explanation":"If an adult Colorado driver is convicted of DWAI for the second time, his or her driver's license will be suspended for one year. In addition, the driver will be punished to no less than 10 days in prison and no less than 48 hours of community service, as well as a minimum fine of $600. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#24049","text":"2 years#1 year#30 days#90 days"},
{"testId":569,"qid":24578,"description":"Which of the following is part of a Colorado driver's sentence for a second DUI conviction within five years?","explanation":"A second DUI conviction in Colorado for an adult driver within five years will result in a one-year licence suspension, a minimum $600 fine, a minimum of 10 days in prison, and a minimum of 48 hours of community service. [Possible Penalties for Driving While Intoxicated or Under the Influence of Drugs, Alcohol, or Other Substances, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25092#25093#25094","text":"All of the above#License revocation for 1 year#A minimum fine of $600#A minimum of 10 days in jail"},
{"testId":1036,"qid":24578,"description":"Which of the following is part of a Colorado driver's sentence for a second DUI conviction within five years?","explanation":"A second DUI conviction in Colorado for an adult driver within five years will result in a one-year licence suspension, a minimum $600 fine, a minimum of 10 days in prison, and a minimum of 48 hours of community service. [Possible Penalties for Driving While Intoxicated or Under the Influence of Drugs, Alcohol, or Other Substances, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25092#25093#25094","text":"All of the above#License revocation for 1 year#A minimum fine of $600#A minimum of 10 days in jail"},
{"testId":569,"qid":24581,"description":"What is the punishment in Colorado for a DUI or DWAI conviction when the driver is under the age of 21?","explanation":"A Colorado motorist under the age of 21 who is convicted of DWAI or DUI will have his or her license revoked.","imageName":"","correctAnswer":25096,"isBookmarked":0,"aid":"12627#23264#25095#25096","text":"6 months in jail#A fine of $500#Driver's license suspension until age 21#Driver's license revocation"},
{"testId":1036,"qid":24581,"description":"What is the punishment in Colorado for a DUI or DWAI conviction when the driver is under the age of 21?","explanation":"A Colorado motorist under the age of 21 who is convicted of DWAI or DUI will have his or her license revoked.","imageName":"","correctAnswer":25096,"isBookmarked":0,"aid":"12627#23264#25095#25096","text":"6 months in jail#A fine of $500#Driver's license suspension until age 21#Driver's license revocation"},
{"testId":569,"qid":24583,"description":"Even if the driver is not operating a vehicle, a Colorado driver under the age of 21 will have his or her license revoked_________","explanation":"If a Colorado motorist under the age of 21 purchases or possesses any alcoholic beverage, his or her driver's license will be immediately revoked. [Buy and Possess, Express Consent Law, Driving Under the Influence, Colorado Driver Handbook]","imageName":"","correctAnswer":12087,"isBookmarked":0,"aid":"12087#12088#25097#25098","text":"if he or she possesses or purchases alcohol#if he or she is convicted of speeding#if he or she is convicted of a felony#for some other reason"},
{"testId":1036,"qid":24583,"description":"Even if the driver is not operating a vehicle, a Colorado driver under the age of 21 will have his or her license revoked_________","explanation":"If a Colorado motorist under the age of 21 purchases or possesses any alcoholic beverage, his or her driver's license will be immediately revoked. [Buy and Possess, Express Consent Law, Driving Under the Influence, Colorado Driver Handbook]","imageName":"","correctAnswer":12087,"isBookmarked":0,"aid":"12087#12088#25097#25098","text":"if he or she possesses or purchases alcohol#if he or she is convicted of speeding#if he or she is convicted of a felony#for some other reason"},
{"testId":569,"qid":24584,"description":"If a Colorado driver purchases or gives alcohol to a minor, or loans his or her identification to aid a juvenile's purchase of alcohol, his or her license will be suspended for:","explanation":"A Colorado driver who gives alcohol to a minor, purchases alcohol for a minor, or loans his or her driver's license or other identification to a minor for the purpose of acquiring alcohol of any type shall have his or her license suspended for six months.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":1036,"qid":24584,"description":"If a Colorado driver purchases or gives alcohol to a minor, or loans his or her identification to aid a juvenile's purchase of alcohol, his or her license will be suspended for:","explanation":"A Colorado driver who gives alcohol to a minor, purchases alcohol for a minor, or loans his or her driver's license or other identification to a minor for the purpose of acquiring alcohol of any type shall have his or her license suspended for six months.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":569,"qid":24585,"description":"How many points whill be added to your driving record for reckless driving in Colarado?","explanation":"A reckless driving conviction will add 8 points to a Colorado driver's driving record. [Aggressive vs. Reckless Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6388#6916#7620","text":"10#2#6#8"},
{"testId":1036,"qid":24585,"description":"How many points whill be added to your driving record for reckless driving in Colarado?","explanation":"A reckless driving conviction will add 8 points to a Colorado driver's driving record. [Aggressive vs. Reckless Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6388#6916#7620","text":"10#2#6#8"},
{"testId":569,"qid":24586,"description":"Which of the following drugs might lead to a conviction for DUI in Colorado?","explanation":"Illegal narcotics, prescription medicines, and over-the-counter pharmaceuticals may impair a driver's ability to drive and lead to DUI convictions in Colorado. [Drugs and Driving, Driving While Intoxicated, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#25100","text":"All of the above#Prescription drugs#Marijuana#Over-the-counter cold medicine"},
{"testId":1036,"qid":24586,"description":"Which of the following drugs might lead to a conviction for DUI in Colorado?","explanation":"Illegal narcotics, prescription medicines, and over-the-counter pharmaceuticals may impair a driver's ability to drive and lead to DUI convictions in Colorado. [Drugs and Driving, Driving While Intoxicated, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#25100","text":"All of the above#Prescription drugs#Marijuana#Over-the-counter cold medicine"},
{"testId":569,"qid":24587,"description":"According to the state's Express Consent Law, as a driver, you have already consented to:","explanation":"The Express Consent Law states that by driving a motor vehicle inside the state, you have accepted to submit to a chemical test of your blood, breath, or urine to assess the alcohol or drug level of your blood. [Express Consent Law, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":25101,"isBookmarked":0,"aid":"8615#25101#25102#25103","text":"All of the above#take a chemical test to determine the alcohol or drug content of your blood#install an ignition interlock device on your vehicle#never drive under the influence"},
{"testId":1036,"qid":24587,"description":"According to the state's Express Consent Law, as a driver, you have already consented to:","explanation":"The Express Consent Law states that by driving a motor vehicle inside the state, you have accepted to submit to a chemical test of your blood, breath, or urine to assess the alcohol or drug level of your blood. [Express Consent Law, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":25101,"isBookmarked":0,"aid":"8615#25101#25102#25103","text":"All of the above#take a chemical test to determine the alcohol or drug content of your blood#install an ignition interlock device on your vehicle#never drive under the influence"},
{"testId":569,"qid":24588,"description":"If a driver is suspected of DUI but refuses to submit to a chemical test, his or her license will be suspended:","explanation":"If a person refuses to take the test or fails to comply with the testing protocol, their driving license will be suspended for 60 days and they will be compelled to install an interlock device in each car they drive for the next two years. If further suspensions or revocations result from the same event, they will be added to the revocation (consecutively). [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":23186,"isBookmarked":0,"aid":"23185#23186#25860#45352","text":"for 30 days#for 60 days#for 180 days#for 6 days"},
{"testId":1036,"qid":24588,"description":"If a driver is suspected of DUI but refuses to submit to a chemical test, his or her license will be suspended:","explanation":"If a person refuses to take the test or fails to comply with the testing protocol, their driving license will be suspended for 60 days and they will be compelled to install an interlock device in each car they drive for the next two years. If further suspensions or revocations result from the same event, they will be added to the revocation (consecutively). [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":23186,"isBookmarked":0,"aid":"23185#23186#25860#45352","text":"for 30 days#for 60 days#for 180 days#for 6 days"},
{"testId":569,"qid":24589,"description":"A Colorado driver guilty of a third DWAI or DUI will face:","explanation":"If a Colorado driver is convicted of a third DUI or DWAI, the penalty will include a 2-year license suspension, 60 days to one year in prison, 48 to 120 hours of community service, and a fine of $600 to $1500. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25106#25107#25108","text":"All of the above#a revocation period of 2 years#a minimum of 60 days in jail#a minimum of 48 hours of public service"},
{"testId":1036,"qid":24589,"description":"A Colorado driver guilty of a third DWAI or DUI will face:","explanation":"If a Colorado driver is convicted of a third DUI or DWAI, the penalty will include a 2-year license suspension, 60 days to one year in prison, 48 to 120 hours of community service, and a fine of $600 to $1500. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25106#25107#25108","text":"All of the above#a revocation period of 2 years#a minimum of 60 days in jail#a minimum of 48 hours of public service"},
{"testId":569,"qid":24590,"description":"A first DUI conviction for an adult motorist could result in up to _______ in jail.","explanation":"In Colorado, the maximum prison term for a first DUI conviction is one year. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1036,"qid":24590,"description":"A first DUI conviction for an adult motorist could result in up to _______ in jail.","explanation":"In Colorado, the maximum prison term for a first DUI conviction is one year. [Possible Penalties for Drinking and Driving and/or Drugs and Driving, Alcohol and Driving, DUI, Colorado Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":569,"qid":24591,"description":"Which of the following non-driving offenses may result in a license suspension?","explanation":"A Colorado driver's license may be revoked for a variety of reasons and circumstances. Defacing the license, failing to pay court-ordered child support, and giving the license to another person are all examples. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25109#25110#25111","text":"All of the above#Defacing your driver's license#Failing to pay court-ordered child support#Lending your driver's license to another individual"},
{"testId":1036,"qid":24591,"description":"Which of the following non-driving offenses may result in a license suspension?","explanation":"A Colorado driver's license may be revoked for a variety of reasons and circumstances. Defacing the license, failing to pay court-ordered child support, and giving the license to another person are all examples. [Your Driving Privilege May Be Suspended, Revoked, or Cancelled, Colorado Driver Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25109#25110#25111","text":"All of the above#Defacing your driver's license#Failing to pay court-ordered child support#Lending your driver's license to another individual"},
{"testId":569,"qid":24592,"description":"A driver will have to register all his or her vehicles within ________ after becoming Colorado resident.","explanation":"To prevent license suspension, revocation, or cancellation in Colorado, you must register any cars you possess within 30 days after becoming a resident. [Your Driving Privilege Could Be Suspended, Revoked, or Cancelled While You Keep Your License, Colorado Driver's Manual]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1020#12100","text":"90 days#30 days#60 days#100 days"},
{"testId":1036,"qid":24592,"description":"A driver will have to register all his or her vehicles within ________ after becoming Colorado resident.","explanation":"To prevent license suspension, revocation, or cancellation in Colorado, you must register any cars you possess within 30 days after becoming a resident. [Your Driving Privilege Could Be Suspended, Revoked, or Cancelled While You Keep Your License, Colorado Driver's Manual]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1020#12100","text":"90 days#30 days#60 days#100 days"},
{"testId":569,"qid":24593,"description":"Which of the following is not a reason for a Colorado driver not to wear a safety belt?","explanation":"If a Colorado driver carries a written statement stating why he or she is physically or mentally unable to wear a safety belt, the state's safety belt law will be waived. Understanding Colorado's Motor Vehicle Laws, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":25112,"isBookmarked":0,"aid":"25112#25113#25114#25115","text":"A written medical statement from the driver's doctor#An emergency#Being a professional driver#Disliking seat belts"},
{"testId":569,"qid":24594,"description":"Cell phone usage while driving is prohibited for drivers under the age of _____, unless in an emergency or to call the police or fire department.","explanation":"Cell phones are not permitted to be used by drivers under the age of 18 in Colorado, unless in an emergency or to call the police or fire department. Drivers 18 and older are not permitted to text message while driving, unless in an emergency or to call the police or fire department. [Driving While Using a Cell Phone or Mobile Telephone, Understanding Colorado's Motor Vehicle Laws, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"897#6425#11359#11360","text":"None of the above#25#18#21"},
{"testId":1036,"qid":24594,"description":"Cell phone usage while driving is prohibited for drivers under the age of _____, unless in an emergency or to call the police or fire department.","explanation":"Cell phones are not permitted to be used by drivers under the age of 18 in Colorado, unless in an emergency or to call the police or fire department. Drivers 18 and older are not permitted to text message while driving, unless in an emergency or to call the police or fire department. [Driving While Using a Cell Phone or Mobile Telephone, Understanding Colorado's Motor Vehicle Laws, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"897#6425#11359#11360","text":"None of the above#25#18#21"},
{"testId":569,"qid":24595,"description":"License plates must:","explanation":"[License Plates, Understanding Colorado's Motor Vehicle Laws, The Driver License, Colorado Driver Handbook] A Colorado license plate must be firmly attached horizontally at least 12 inches above the ground.","imageName":"","correctAnswer":25049,"isBookmarked":0,"aid":"25049#25116#25117#25118","text":"comply with all of the above#be placed at least 12 inches above the ground#be fastened securely#be fastened horizontally"},
{"testId":1036,"qid":24595,"description":"License plates must:","explanation":"[License Plates, Understanding Colorado's Motor Vehicle Laws, The Driver License, Colorado Driver Handbook] A Colorado license plate must be firmly attached horizontally at least 12 inches above the ground.","imageName":"","correctAnswer":25049,"isBookmarked":0,"aid":"25049#25116#25117#25118","text":"comply with all of the above#be placed at least 12 inches above the ground#be fastened securely#be fastened horizontally"},
{"testId":570,"qid":24596,"description":"Which of the following limitations apply to a newly licensed driver under the age of 18?","explanation":"A freshly licensed driver under the age of 18 is subject to certain limitations. Except for employment, school, religious activities, or emergencies, he or she may not drive between 11:00 p.m. and 5:00 a.m. He or she may not use a mobile phone, even if it is hands-free. In addition, he or she may not transport passengers under the age of 20 for the first six months. [16- and 17-Year-Old Driver Restrictions, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":33508,"isBookmarked":0,"aid":"33508#43872#43873#43874","text":"All of the above restrictions apply#The driver may not use a cell phone while driving#The driver may not carry passengers under 20 years of age#The driver may not drive late at night except for work, school, religious activities, or emergencies"},
{"testId":1037,"qid":24596,"description":"Which of the following limitations apply to a newly licensed driver under the age of 18?","explanation":"A freshly licensed driver under the age of 18 is subject to certain limitations. Except for employment, school, religious activities, or emergencies, he or she may not drive between 11:00 p.m. and 5:00 a.m. He or she may not use a mobile phone, even if it is hands-free. In addition, he or she may not transport passengers under the age of 20 for the first six months. [16- and 17-Year-Old Driver Restrictions, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":33508,"isBookmarked":0,"aid":"33508#43872#43873#43874","text":"All of the above restrictions apply#The driver may not use a cell phone while driving#The driver may not carry passengers under 20 years of age#The driver may not drive late at night except for work, school, religious activities, or emergencies"},
{"testId":570,"qid":24597,"description":"A learner's permit holder under the age of 18 must complete at least ___ hours of supervised driving practice before taking the road test for a driver's license.","explanation":"Before taking the road test for a driver's license, a motorist under the age of 18 must complete at least 30 hours of classroom instruction and at least 40 hours of supervised driving practice. [Requirements for Parental Training, Driver Education, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":570,"qid":24598,"description":"If a learner's permit holder is under the age of 18, his or her parent or legal guardian _________.","explanation":"Anyone with a learner's permit, regardless of age, is required to finish an eight-hour Safe Driving Practices course. In addition to the Safe Driving Practices course, a two-hour training on adolescent driving is required of parents or legal guardians of drivers under the age of 18. [State of Connecticut Driver's Manual, Parent Training Requirements, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License]","imageName":"","correctAnswer":43876,"isBookmarked":0,"aid":"43875#43876#43877#43878","text":"must teach the person how to drive#must complete a two-hour class about teen drivers#must purchase a vehicle for the person to drive#must pay the person's application fee"},
{"testId":570,"qid":24599,"description":"While a 16- or 17-year-old driver has a learner's permit, he or she must be supervised by an authorised driving teacher or someone who _______.","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#43879#43880#43881","text":"meets all of the above requirements#is at least 20 years old#has held a driver's license for at least four consecutive years#is the driver's spouse or a close relative"},
{"testId":570,"qid":24600,"description":"Except for employment, school, religious activities, or emergencies, a licensed driver under the age of 18 may not drive ________","explanation":"A licensed motorist under the age of 18 may not drive after 11:00 p.m. and 5:00 a.m. unless while at work, school, religious activity, or in an emergency [Hour Restrictions (Curfew), 16- and 17-Year-Old Driver Restrictions, Driver Education Requirements, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":41826,"isBookmarked":0,"aid":"36064#41826#41827#43054","text":"between midnight and 6:00 a.m#between 11:00 p.m. and 5:00 a.m#between 11:00 p.m. and 6:00 a.m#between 10:00 p.m. and 3:00 a.m"},
{"testId":570,"qid":24601,"description":"If a driver under the age of 21 is convicted of having alcohol on a public street or roadway, his or her driving license will be revoked for:","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#27364#35989","text":"30 days#60 days#45 days#14 days"},
{"testId":1037,"qid":24601,"description":"If a driver under the age of 21 is convicted of having alcohol on a public street or roadway, his or her driving license will be revoked for:","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#27364#35989","text":"30 days#60 days#45 days#14 days"},
{"testId":570,"qid":24602,"description":"A motorist under the age of 18 may not take passengers other than parents, legal guardians, or_________ for the first six months after getting a driver's license.","explanation":"A motorist under the age of 18 may not transport passengers other than parents, legal guardians, or one adult aged 20 or older who has had a driver's license for at least four years during the first six months after getting a license. [16- and 17-Year-Old Driver Restrictions, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":43882,"isBookmarked":0,"aid":"25134#25135#43882#43883","text":"classmates#teachers#one other person at least 20 years old#one other person at least 18 years old"},
{"testId":570,"qid":24603,"description":"Under 18-year-olds who have a driver's license can carry extra passengers who are ___ during the second six months.","explanation":"During the second six months of having a license, a driver under the age of 18 may carry extra passengers who are members of his or her immediate family. [Passenger Restrictions, Restrictions for 16- and 17-Year-Old Drivers, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":43886,"isBookmarked":0,"aid":"43884#43885#43886#43887","text":"at least 21 years of age#at least 18 years of age#members of his or her immediate family#fellow students"},
{"testId":570,"qid":24604,"description":"A driver under the age of 18 may not use _________ while driving in Connecticut.","explanation":"A motorist under the age of 18 in Connecticut is not permitted to use any sort of mobile phone while driving, even a hands-free cell phone. Drivers above the age of 18 may use hands-free mobile phones while driving. [Other Restrictions, 16- and 17-Year-Old Driver Restrictions, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":43889,"isBookmarked":0,"aid":"43888#43889#43890#43891","text":"the radio#a cell phone#a GPS navigator#an in-car DVD player"},
{"testId":1037,"qid":24604,"description":"A driver under the age of 18 may not use _________ while driving in Connecticut.","explanation":"A motorist under the age of 18 in Connecticut is not permitted to use any sort of mobile phone while driving, even a hands-free cell phone. Drivers above the age of 18 may use hands-free mobile phones while driving. [Other Restrictions, 16- and 17-Year-Old Driver Restrictions, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":43889,"isBookmarked":0,"aid":"43888#43889#43890#43891","text":"the radio#a cell phone#a GPS navigator#an in-car DVD player"},
{"testId":570,"qid":24605,"description":"If a driver less than 18 years old is convicted of __________, his or her license will be suspended.","explanation":"If a 16 or 17-year-old driver is found guilty of any of a number of infractions, his or her license will be immediately revoked. Such charges include breaching any license limitation, exceeding the speed limit by 20 mph or more, using a mobile phone while driving, and driving with any quantity of alcohol or drugs in his or her system. [Other Suspensions for 16 and 17-Year-Old Drivers, Your License and Law, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12140#43892#43893","text":"Any of the above#driving under the influence of drugs or alcohol#speeding at 20 mph over the speed limit#violating any restriction on the license"},
{"testId":1037,"qid":24605,"description":"If a driver less than 18 years old is convicted of __________, his or her license will be suspended.","explanation":"If a 16 or 17-year-old driver is found guilty of any of a number of infractions, his or her license will be immediately revoked. Such charges include breaching any license limitation, exceeding the speed limit by 20 mph or more, using a mobile phone while driving, and driving with any quantity of alcohol or drugs in his or her system. [Other Suspensions for 16 and 17-Year-Old Drivers, Your License and Law, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12140#43892#43893","text":"Any of the above#driving under the influence of drugs or alcohol#speeding at 20 mph over the speed limit#violating any restriction on the license"},
{"testId":570,"qid":24606,"description":"If you fail to keep your vehicle insured,:","explanation":null,"imageName":"","correctAnswer":27717,"isBookmarked":0,"aid":"27714#27715#27717#43894","text":"you will have to pay an insurance compliance fine#it will be considered a violation#all of the above will happen#your insurer will inform the DMV"},
{"testId":570,"qid":24607,"description":"Which of the following passengers of the car must wear seat belts or appropriate child restraints if the driver is under the age of 18?","explanation":"If the driver is 16 or 17, all passengers must wear seat belts or appropriate kid restraints. [Seat Belt Laws, 2. Before You Get Behind the Wheel, Driver's Manual of the State of Connecticut]","imageName":"","correctAnswer":10423,"isBookmarked":0,"aid":"4420#10423#43622#43895","text":"Only the driver#All passengers#The driver, front-seat passengers, and passengers under 16 years of age#The driver and passengers under 16 years of age"},
{"testId":570,"qid":24608,"description":"When the driver is under the age of 18, the fine for failing to wear a seat belt is:","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":570,"qid":24609,"description":"A motorist under the age of 25 who is convicted of ___ moving or suspension violations must complete the DMV Operator Retraining Program.","explanation":"If a motorist under the age of 25 is convicted of two or more moving or suspension offences, they must undergo the DMV Operator Retraining Program. A driver above the age of 25 who is convicted of three or more similar offences must also finish the retraining program. This curriculum addresses the fundamentals of motor vehicle operation, how attitude influences aggressive driving behavior, and the need of practicing safe driving habits. [Repeat Offender Operator Retraining Program, Your License and the Law, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1037,"qid":24609,"description":"A motorist under the age of 25 who is convicted of ___ moving or suspension violations must complete the DMV Operator Retraining Program.","explanation":"If a motorist under the age of 25 is convicted of two or more moving or suspension offences, they must undergo the DMV Operator Retraining Program. A driver above the age of 25 who is convicted of three or more similar offences must also finish the retraining program. This curriculum addresses the fundamentals of motor vehicle operation, how attitude influences aggressive driving behavior, and the need of practicing safe driving habits. [Repeat Offender Operator Retraining Program, Your License and the Law, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":570,"qid":24610,"description":"If a driver under the age of 21 is found guilty of driving with a BAC of _____ or higher, his or her driver's license will be suspended.","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4252#4652","text":"0.08%#0.02%#0.05%#0.00%"},
{"testId":1037,"qid":24610,"description":"If a driver under the age of 21 is found guilty of driving with a BAC of _____ or higher, his or her driver's license will be suspended.","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4252#4652","text":"0.08%#0.02%#0.05%#0.00%"},
{"testId":570,"qid":24611,"description":"Which of the following impacts your riding abilities?","explanation":"You may not be able to drive if you are drowsy, sedated, or have poor coordination from alcohol, certain illicit substances, some prescription drugs, or some over-the-counter medicines. Similar to how alcohol may, certain prescription medications can impair your reflexes, judgement, eyesight, and awareness. Check the warning label on the drug or contact your pharmacist or doctor if you have questions about how it will affect you. [State of Connecticut Driver's Manual, 3. Driving Behaviors, Drugs and Driving, Drinking and Driving]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9802#14112","text":"All of the above#Illegal drugs#Prescription drugs#Over-the-counter drugs"},
{"testId":1037,"qid":24611,"description":"Which of the following impacts your riding abilities?","explanation":"You may not be able to drive if you are drowsy, sedated, or have poor coordination from alcohol, certain illicit substances, some prescription drugs, or some over-the-counter medicines. Similar to how alcohol may, certain prescription medications can impair your reflexes, judgement, eyesight, and awareness. Check the warning label on the drug or contact your pharmacist or doctor if you have questions about how it will affect you. [State of Connecticut Driver's Manual, 3. Driving Behaviors, Drugs and Driving, Drinking and Driving]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9802#14112","text":"All of the above#Illegal drugs#Prescription drugs#Over-the-counter drugs"},
{"testId":570,"qid":24612,"description":"If you're arrested for DUI, you'll be asked to take which type of聽BAC test?","explanation":"If you are arrested for driving while intoxicated, you will be required to submit to a blood, breath, or urine test to determine your blood alcohol concentration (BAC). If you fail this exam, you will have your driver's license suspended for at least 45 days. The Supreme Court concluded in Birchfield v. North Dakota (2016) that you cannot be lawfully forced to submit to a blood test unless the police first gets a search warrant. [Your License and the Law, 3. Driving Behaviors, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34975#34976#34977","text":"Any of the above#Breath#Blood#Urine"},
{"testId":1037,"qid":24612,"description":"If you're arrested for DUI, you'll be asked to take which type of聽BAC test?","explanation":"If you are arrested for driving while intoxicated, you will be required to submit to a blood, breath, or urine test to determine your blood alcohol concentration (BAC). If you fail this exam, you will have your driver's license suspended for at least 45 days. The Supreme Court concluded in Birchfield v. North Dakota (2016) that you cannot be lawfully forced to submit to a blood test unless the police first gets a search warrant. [Your License and the Law, 3. Driving Behaviors, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34975#34976#34977","text":"Any of the above#Breath#Blood#Urine"},
{"testId":570,"qid":24613,"description":null,"explanation":"In Connecticut, a motorist under the age of 18 who is charged with a crime may be considered a \"youthful offender.\" If convicted, a young criminal may get a lower sentence than an adult. Furthermore, after the young criminal has served his or her sentence, all police and court documents pertaining to the case will be destroyed until the offender reaches the age of 21. However, if a person under the age of 18 is charged with some particularly severe acts, he or she will be regarded as an adult offender rather than a juvenile offender. These include major driving charges such as negligent murder with a motor vehicle, fleeing the scene of an accident resulting in death or injury (called \"evading responsibility\" under Connecticut law), or driving under the influence of drugs or alcohol (DUI). Non-driving crimes that are class A or B felonies, such as murder and abduction, are also included. [Your License and the Law, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual], [Youthful Offenders, 2016-R-0067, Connecticut General Assembly, Office of Legislative Research, https://www.cga.ct.gov/2016/rpt/2016-R-0067] .htm]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#39786#43896#43897","text":"Any of the above#Driving under the influence of alcohol or drugs (DUI)#Leaving the scene of a serious accident (a.k.a. evading responsibility; hit and run)#Negligent homicide with a motor vehicle"},
{"testId":1037,"qid":24613,"description":null,"explanation":"In Connecticut, a motorist under the age of 18 who is charged with a crime may be considered a \"youthful offender.\" If convicted, a young criminal may get a lower sentence than an adult. Furthermore, after the young criminal has served his or her sentence, all police and court documents pertaining to the case will be destroyed until the offender reaches the age of 21. However, if a person under the age of 18 is charged with some particularly severe acts, he or she will be regarded as an adult offender rather than a juvenile offender. These include major driving charges such as negligent murder with a motor vehicle, fleeing the scene of an accident resulting in death or injury (called \"evading responsibility\" under Connecticut law), or driving under the influence of drugs or alcohol (DUI). Non-driving crimes that are class A or B felonies, such as murder and abduction, are also included. [Your License and the Law, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual], [Youthful Offenders, 2016-R-0067, Connecticut General Assembly, Office of Legislative Research, https://www.cga.ct.gov/2016/rpt/2016-R-0067] .htm]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#39786#43896#43897","text":"Any of the above#Driving under the influence of alcohol or drugs (DUI)#Leaving the scene of a serious accident (a.k.a. evading responsibility; hit and run)#Negligent homicide with a motor vehicle"},
{"testId":570,"qid":24614,"description":"If a 16- or 17-year-old motorist is found guilty of using a cell phone while driving, his or her license will be suspended for ______ and a license restoration cost of ____ will be assessed.","explanation":null,"imageName":"","correctAnswer":12151,"isBookmarked":0,"aid":"12151#12153#12154#43898","text":"30 days; $175#1 year; $125#1 year; $500#60 days; $175"},
{"testId":1037,"qid":24614,"description":"If a 16- or 17-year-old motorist is found guilty of using a cell phone while driving, his or her license will be suspended for ______ and a license restoration cost of ____ will be assessed.","explanation":null,"imageName":"","correctAnswer":12151,"isBookmarked":0,"aid":"12151#12153#12154#43898","text":"30 days; $175#1 year; $125#1 year; $500#60 days; $175"},
{"testId":570,"qid":24615,"description":"If you are found guilty of exceeding 85 mph in Connecticut, you may face which of the following fines for a first offense?","explanation":null,"imageName":"","correctAnswer":11771,"isBookmarked":0,"aid":"11771#23115#43099#43899","text":"30 days in jail#A $1,000 fine#A $500 fine#60 days in jail"},
{"testId":1037,"qid":24615,"description":"If you are found guilty of exceeding 85 mph in Connecticut, you may face which of the following fines for a first offense?","explanation":null,"imageName":"","correctAnswer":11771,"isBookmarked":0,"aid":"11771#23115#43099#43899","text":"30 days in jail#A $1,000 fine#A $500 fine#60 days in jail"},
{"testId":570,"qid":24616,"description":"If convicted of driving uninsured vehicle for the first time, your license will be suspended for :聽:","explanation":null,"imageName":"","correctAnswer":959,"isBookmarked":0,"aid":"959#4374#11553#24002","text":"one month#six months#45 days#two months"},
{"testId":1037,"qid":24616,"description":"If convicted of driving uninsured vehicle for the first time, your license will be suspended for :聽:","explanation":null,"imageName":"","correctAnswer":959,"isBookmarked":0,"aid":"959#4374#11553#24002","text":"one month#six months#45 days#two months"},
{"testId":570,"qid":24617,"description":"In Connecticut, your vehicle liability insurance policy must offer at least ______ in property damage coverage in any one accident.","explanation":"The minimum automobile liability insurance requirements in Connecticut are as follows: $25,000 for one person's bodily injuries per accident, $50,000 for all people' bodily injuries per accident, and $25,000 for property damage per accident. [http://www.ct.gov/dmv/cwp/view.asp?a=1523&q=251214] Connecticut Vehicle Insurance Requirements","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11659#12047#12048","text":"$5,000#$20,000#$15,000#$25,000"},
{"testId":570,"qid":24618,"description":"Alcohol is implicated in more than ____ of fatal traffic incidents, according to the Connecticut Driver's Manual.","explanation":"Alcohol is implicated in more than 40% of fatal automobile incidents, according to the Connecticut Driver's Manual. If you consume even one alcoholic drink, your chances of getting into an accident rise. [Driving While Intoxicated, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#12162","text":"10%#40%#20%#30%"},
{"testId":570,"qid":24619,"description":"If a 16- or 17-year-old driver is convicted of exceeding the speed limit by 20 miles per hour, his or her license will be suspended for ______ and he or she will be required to pay a ____ license restoration fee.","explanation":"If a 16- or 17-year-old driver is found guilty of exceeding the speed limit by 20 miles per hour or more, his or her license will be revoked for 60 days, and he or she will be required to pay a $175 license restoration fee as well as court costs for the violation. [Other Suspensions for 16 and 17-Year-Old Drivers, Your License and Law, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Manual]","imageName":"","correctAnswer":43898,"isBookmarked":0,"aid":"12151#12153#12154#43898","text":"30 days; $175#1 year; $125#1 year; $500#60 days; $175"},
{"testId":1037,"qid":24619,"description":"If a 16- or 17-year-old driver is convicted of exceeding the speed limit by 20 miles per hour, his or her license will be suspended for ______ and he or she will be required to pay a ____ license restoration fee.","explanation":"If a 16- or 17-year-old driver is found guilty of exceeding the speed limit by 20 miles per hour or more, his or her license will be revoked for 60 days, and he or she will be required to pay a $175 license restoration fee as well as court costs for the violation. [Other Suspensions for 16 and 17-Year-Old Drivers, Your License and Law, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Manual]","imageName":"","correctAnswer":43898,"isBookmarked":0,"aid":"12151#12153#12154#43898","text":"30 days; $175#1 year; $125#1 year; $500#60 days; $175"},
{"testId":570,"qid":24620,"description":"A driver of any age will be charged with DUI if his or her blood alcohol concentration (BAC) is proven to be _____ or higher while driving.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1037,"qid":24620,"description":"A driver of any age will be charged with DUI if his or her blood alcohol concentration (BAC) is proven to be _____ or higher while driving.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":570,"qid":24621,"description":"Those convicted of DUI after refusing a BAC test face penalties:","explanation":null,"imageName":"","correctAnswer":43901,"isBookmarked":0,"aid":"25165#43900#43901#43902","text":"for only the DUI conviction#for only the refusal#for both the DUI conviction and the refusal#for either the DUI conviction or the refusal, whichever carries the most severe penalties"},
{"testId":1037,"qid":24621,"description":"Those convicted of DUI after refusing a BAC test face penalties:","explanation":null,"imageName":"","correctAnswer":43901,"isBookmarked":0,"aid":"25165#43900#43901#43902","text":"for only the DUI conviction#for only the refusal#for both the DUI conviction and the refusal#for either the DUI conviction or the refusal, whichever carries the most severe penalties"},
{"testId":570,"qid":24622,"description":"Which of the following punishments may be imposed on a driver under the age of 21 who is found to be driving under the influence of alcohol or drugs?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35315#43903","text":"All of the above#A fine#A jail sentence#Required installation of an ignition interlock device (IID)"},
{"testId":570,"qid":24623,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.02 percent or higher, he or she must install and maintain an ignition interlock device (IID) for at least:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"25031#25032#25169#27027","text":"18 months#24 months#12 months#36 months"},
{"testId":570,"qid":24624,"description":"If a police officer asks you to take a blood, breath, or urine test to find out how much alcohol is in your blood, and you refuse, your license will be revoked for at least:","explanation":"If you refuse to submit to a blood, breath, or urine test to determine your blood alcohol content (BAC) when a law enforcement official requests it, your driver's license will be revoked for at least 45 days. As a condition of reinstatement, you will also be required to install and maintain an ignition interlock device (IID). The IID will be needed for one year for a first infraction. If your breath alcohol concentration (BrAC) exceeds a predetermined level, the IID will prohibit your car from starting. [Your License and the Law, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual, Alcohol and the Law]","imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23153#23886#27363#27364","text":"6 months#30 days#2 months#45 days"},
{"testId":1037,"qid":24624,"description":"If a police officer asks you to take a blood, breath, or urine test to find out how much alcohol is in your blood, and you refuse, your license will be revoked for at least:","explanation":"If you refuse to submit to a blood, breath, or urine test to determine your blood alcohol content (BAC) when a law enforcement official requests it, your driver's license will be revoked for at least 45 days. As a condition of reinstatement, you will also be required to install and maintain an ignition interlock device (IID). The IID will be needed for one year for a first infraction. If your breath alcohol concentration (BrAC) exceeds a predetermined level, the IID will prohibit your car from starting. [Your License and the Law, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual, Alcohol and the Law]","imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23153#23886#27363#27364","text":"6 months#30 days#2 months#45 days"},
{"testId":570,"qid":24625,"description":"A driver over the age of 25 who is convicted of ___ or more moving or suspension offences must complete the DMV Operator Retraining Program.","explanation":"If a motorist under the age of 25 is convicted of two or more moving or suspension offences, they must undergo the DMV Operator Retraining Program. A driver above the age of 25 who is convicted of three or more similar offences must also finish the retraining program. This curriculum addresses the fundamentals of motor vehicle operation, how attitude influences aggressive driving behavior, and the need of practicing safe driving habits. [Repeat Offender Operator Retraining Program, Your License and the Law, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1037,"qid":24625,"description":"A driver over the age of 25 who is convicted of ___ or more moving or suspension offences must complete the DMV Operator Retraining Program.","explanation":"If a motorist under the age of 25 is convicted of two or more moving or suspension offences, they must undergo the DMV Operator Retraining Program. A driver above the age of 25 who is convicted of three or more similar offences must also finish the retraining program. This curriculum addresses the fundamentals of motor vehicle operation, how attitude influences aggressive driving behavior, and the need of practicing safe driving habits. [Repeat Offender Operator Retraining Program, Your License and the Law, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":570,"qid":24626,"description":"If a 16- or 17-year-old driver is found guilty of _________ twice, he or she must complete the Operator Retraining Program.","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#43904#43905#43906","text":"Any of the above#using a cell phone while driving#a passenger restriction violation#a curfew violation"},
{"testId":1037,"qid":24626,"description":"If a 16- or 17-year-old driver is found guilty of _________ twice, he or she must complete the Operator Retraining Program.","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#43904#43905#43906","text":"Any of the above#using a cell phone while driving#a passenger restriction violation#a curfew violation"},
{"testId":570,"qid":24627,"description":"Which of the following punishments could you be subject to if you are found guilty of fleeing the scene of an accident without stopping (sometimes known as \"hit and run\"), but the accident involved only damage to property and no injuries to other people?","explanation":null,"imageName":"","correctAnswer":43909,"isBookmarked":0,"aid":"43907#43908#43909#43910","text":"A fine of $50鈥$500 and up to 100 hours of community service#A fine of $50鈥$500 and up to six months in jail#A fine of $75鈥$600 and up to one year in jail#A fine of $100鈥$1,000 and up to one year in jail"},
{"testId":1037,"qid":24627,"description":"Which of the following punishments could you be subject to if you are found guilty of fleeing the scene of an accident without stopping (sometimes known as \"hit and run\"), but the accident involved only damage to property and no injuries to other people?","explanation":null,"imageName":"","correctAnswer":43909,"isBookmarked":0,"aid":"43907#43908#43909#43910","text":"A fine of $50鈥$500 and up to 100 hours of community service#A fine of $50鈥$500 and up to six months in jail#A fine of $75鈥$600 and up to one year in jail#A fine of $100鈥$1,000 and up to one year in jail"},
{"testId":570,"qid":24628,"description":"If you were in an accident that resulted in ________, you must notify the police.","explanation":"If you were involved in an accident that resulted in injuries, fatalities, or property damage, you must notify the police. [Report the Accident, Traffic Collisions, Vehicle Emergencies, Connecticut Driver's Manual]","imageName":"202006141611292838.jpg","correctAnswer":848,"isBookmarked":0,"aid":"848#12171#43911#43912","text":"Any of the above#property damage#fatalities#injuries"},
{"testId":570,"qid":24629,"description":"Which day of the year will your driver's license expire in Connecticut?","explanation":"Your Connecticut driver's license will expire on your birthday after six years. [Renewing a Driver's License, State of Connecticut DMV, http://www.ct.gov/dmv/cwp/view.asp?a=805&q=244778]","imageName":"","correctAnswer":43915,"isBookmarked":0,"aid":"43913#43914#43915#43916","text":"January 1#July 1#Your birthday#An anniversary of the date that your license was issued"},
{"testId":1037,"qid":24629,"description":"Which day of the year will your driver's license expire in Connecticut?","explanation":"Your Connecticut driver's license will expire on your birthday after six years. [Renewing a Driver's License, State of Connecticut DMV, http://www.ct.gov/dmv/cwp/view.asp?a=805&q=244778]","imageName":"","correctAnswer":43915,"isBookmarked":0,"aid":"43913#43914#43915#43916","text":"January 1#July 1#Your birthday#An anniversary of the date that your license was issued"},
{"testId":570,"qid":24630,"description":"A REAL ID driver's license will be accepted as acceptable identification at which of the following places beginning October 1, 2020?","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#43917#43918#43919","text":"All of the above#Airport check-in lines#Certain federal buildings#Nuclear power plants"},
{"testId":1037,"qid":24630,"description":"A REAL ID driver's license will be accepted as acceptable identification at which of the following places beginning October 1, 2020?","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#43917#43918#43919","text":"All of the above#Airport check-in lines#Certain federal buildings#Nuclear power plants"},
{"testId":570,"qid":24631,"description":"Anyone moving to Connecticut from another state must register their automobiles with the DMV within ___ days.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#5447#6477#8141","text":"10#15#30#60"},
{"testId":1037,"qid":24631,"description":"Anyone moving to Connecticut from another state must register their automobiles with the DMV within ___ days.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#5447#6477#8141","text":"10#15#30#60"},
{"testId":570,"qid":24632,"description":"A booster seat may keep a child ________ safe.","explanation":"A booster seat may be used for a kid aged 5 to 7 or weighing 40 to 59 pounds. If any kid passengers are not securely secured, you may be penalized for breaking Connecticut's child safety regulations. [Child Safety Standards, Seat Belt Laws, 2. State of Connecticut Driver's Manual [Before You Get Behind the Wheel]","imageName":"","correctAnswer":43920,"isBookmarked":0,"aid":"43920#43921#43922#43923","text":"ages 5 through 7#ages 2 through 4#ages 1 through 3#under age 2"},
{"testId":570,"qid":24633,"description":"You must wear a seat belt:","explanation":"Even if your car has airbags, Connecticut law requires you, the driver, to wear a seat belt at all times when driving. While airbags protect against being struck by the steering wheel, dashboard, or windshield, they do not protect you if you are hit from behind or the car flips over. In certain scenarios, an airbag will not keep you behind the wheel. [Seat Belt Laws, 2. Before You Get Behind the Wheel, Driver's Manual of the State of Connecticut]","imageName":"","correctAnswer":24131,"isBookmarked":0,"aid":"24131#43924#43925#43926","text":"In all of the above situations#even when you're driving at low speeds#even if your vehicle is equipped with airbags#even when you're driving on short trips"},
{"testId":570,"qid":24634,"description":"Which occupants of a vehicle are NOT required by Connecticut law to use seat belts or proper child restraints?","explanation":"Except for rear-seat passengers who are at least 16 years old, all occupants of a vehicle must wear seat belts or proper child restraints under Connecticut law. [Seat Belt Laws, 2. Before You Get Behind the Wheel, Driver's Manual of the State of Connecticut]","imageName":"","correctAnswer":43927,"isBookmarked":0,"aid":"37461#43927#43928#43929","text":"Rear-seat passengers#Rear-seat passengers at least 16 years of age#Front-seat passengers#Front-seat passengers at least 16 years of age"},
{"testId":570,"qid":24635,"description":"If your vehicle fails an emissions inspection, you will have _______ to correct the problem and have the emissions assessed again.","explanation":"An emissions check at an approved inspection facility may be necessary for your vehicle. If the vehicle fails the inspection, you will be given 60 days to rectify the issue and have the emissions rechecked. [State of Connecticut Driver's Manual, Know Your Vehicle Inside and Out, 2. Before You Get Behind the Wheel]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"739#740#742#1020","text":"10 days#15 days#30 days#60 days"},
{"testId":1037,"qid":24635,"description":"If your vehicle fails an emissions inspection, you will have _______ to correct the problem and have the emissions assessed again.","explanation":"An emissions check at an approved inspection facility may be necessary for your vehicle. If the vehicle fails the inspection, you will be given 60 days to rectify the issue and have the emissions rechecked. [State of Connecticut Driver's Manual, Know Your Vehicle Inside and Out, 2. Before You Get Behind the Wheel]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"739#740#742#1020","text":"10 days#15 days#30 days#60 days"},
{"testId":570,"qid":24636,"description":"A limited driver's license may be awarded to a driver who has a handicap or a health condition that makes driving difficult. A restricted license may limit the driver to:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#43930#43931#43932","text":"Any of the above#driving in daylight#driving vehicles with automatic transmissions#driving vehicles with side mirrors that show the roadway 200 feet to the rear"},
{"testId":1037,"qid":24636,"description":"A limited driver's license may be awarded to a driver who has a handicap or a health condition that makes driving difficult. A restricted license may limit the driver to:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#43930#43931#43932","text":"Any of the above#driving in daylight#driving vehicles with automatic transmissions#driving vehicles with side mirrors that show the roadway 200 feet to the rear"},
{"testId":570,"qid":24637,"description":"What is the categorization of a Connecticut driver's license for driving automobiles?","explanation":"[Obtaining a Learner's Permit, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual] A Connecticut Class D driver's license permits you to operate any motor vehicle except a commercial motor vehicle. Classifications A, B, and C are commercial driver license (CDL) classes.","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":570,"qid":24638,"description":"A driver with a Connecticut class D license is not permitted to drive:","explanation":"[Obtaining a Learner's Permit, 1. Obtaining a Connecticut Driver's License, State of Connecticut Driver's Manual] A Connecticut Class D driver's license permits you to operate any motor vehicle except a commercial motor vehicle. Classifications A, B, and C are commercial driver license (CDL) classes.","imageName":"","correctAnswer":43933,"isBookmarked":0,"aid":"9855#43933#43934#43935","text":"Any of the above#a commercial vehicle#a vehicle with a manual transmission#a pickup truck"},
{"testId":570,"qid":24639,"description":"In Connecticut, your vehicle liability insurance policy must offer at least ______ in bodily injury coverage for one individual in any one accident.","explanation":"The minimum automobile liability insurance requirements in Connecticut are as follows: $25,000 for one person's bodily injuries per accident, $50,000 for all people' bodily injuries per accident, and $25,000 for property damage per accident. [http://www.ct.gov/dmv/cwp/view.asp?a=1523&q=251214] Connecticut Vehicle Insurance Requirements","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":570,"qid":24640,"description":"In Connecticut, your vehicle liability insurance policy must offer at least ______ in bodily injury coverage for all persons involved in any one accident.","explanation":"The minimum automobile liability insurance requirements in Connecticut are as follows: $25,000 for one person's bodily injuries per accident, $50,000 for all people' bodily injuries per accident, and $25,000 for property damage per accident. [http://www.ct.gov/dmv/cwp/view.asp?a=1523&q=251214] Connecticut Vehicle Insurance Requirements","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12194#12195","text":"$50,000#$30,000#$40,000#$35,000"},
{"testId":570,"qid":24641,"description":"The fine for exceeding the speed limit by 15 mph in a Connecticut work zone is :","explanation":null,"imageName":"","correctAnswer":43939,"isBookmarked":0,"aid":"43936#43937#43938#43939","text":"the usual fine for speeding#50% higher than the usual fine for speeding#double the usual fine for speeding#more than double the usual fine for speeding"},
{"testId":1037,"qid":24641,"description":"The fine for exceeding the speed limit by 15 mph in a Connecticut work zone is :","explanation":null,"imageName":"","correctAnswer":43939,"isBookmarked":0,"aid":"43936#43937#43938#43939","text":"the usual fine for speeding#50% higher than the usual fine for speeding#double the usual fine for speeding#more than double the usual fine for speeding"},
{"testId":570,"qid":24642,"description":"Which of the following cars are not permitted for the DMV road test?","explanation":"You may drive a car that has been leased long-term for the DMV road test as long as it is properly insured. You cannot hire a car on a daily basis unless you already have a driver's license. [The Road Test, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":25200,"isBookmarked":0,"aid":"25197#25198#25200#43940","text":"A vehicle with a manual transmission#An SUV#A daily rental vehicle#A long-term leased vehicle"},
{"testId":1037,"qid":24642,"description":"Which of the following cars are not permitted for the DMV road test?","explanation":"You may drive a car that has been leased long-term for the DMV road test as long as it is properly insured. You cannot hire a car on a daily basis unless you already have a driver's license. [The Road Test, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":25200,"isBookmarked":0,"aid":"25197#25198#25200#43940","text":"A vehicle with a manual transmission#An SUV#A daily rental vehicle#A long-term leased vehicle"},
{"testId":570,"qid":24643,"description":"Before applying for a learner's permit or driver's license, a person under the age of 18 must:","explanation":null,"imageName":"","correctAnswer":43941,"isBookmarked":0,"aid":"10656#43941#43942#43943","text":"Do all of the above#obtain consent from a parent or legal guardian#have a medical examination#maintain at least a \"C\" average in secondary school"},
{"testId":570,"qid":24644,"description":"Which of the following allows 16- and 17-year-old drivers to be free from licensing restrictions?","explanation":"If a 16- or 17-year-old driver is an active first responder (e.g., volunteer fireman or volunteer ambulance driver) or an assigned driver in the Connecticut Safe Ride program, he or she is exempt from the regular underage driving limitations. [Driving Restrictions for 16 and 17-Year-Olds, Driver Education Requirements, 1. Getting a Connecticut Driver's License, Connecticut Driver's Handbook]","imageName":"","correctAnswer":40958,"isBookmarked":0,"aid":"37207#40958#43944#43945","text":"Neither (a) nor (b)#Either (a) or (b)#(a) Duties as a Safe Ride driver#(b) Duties as a first responder"},
{"testId":570,"qid":24645,"description":"If a 16- or 17-year-old motorist is convicted of street racing, he or she faces up to ______ in prison for a first offense.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1037,"qid":24645,"description":"If a 16- or 17-year-old motorist is convicted of street racing, he or she faces up to ______ in prison for a first offense.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":571,"qid":24646,"description":"What is the Delaware Driver Education Certificate sometimes known as?","explanation":null,"imageName":"","correctAnswer":12209,"isBookmarked":0,"aid":"12209#12210#12211#12212","text":"Blue Certificate#Red Certificate#Yellow Certificate#Green Certificate"},
{"testId":571,"qid":24647,"description":"If your vehicle is discovered to be registered yet uninsured in Delaware, you will be fined _____ for the first 30 days.","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":1038,"qid":24647,"description":"If your vehicle is discovered to be registered yet uninsured in Delaware, you will be fined _____ for the first 30 days.","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":571,"qid":24648,"description":"If you have had your license suspended, you must pay a fee of _____ before it can be reinstated.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11832#12357","text":"$25#$50#$100#$150"},
{"testId":1038,"qid":24648,"description":"If you have had your license suspended, you must pay a fee of _____ before it can be reinstated.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11832#12357","text":"$25#$50#$100#$150"},
{"testId":571,"qid":24649,"description":"A driver who is at least 18 years old and has an out-of-state driver's license must get a Delaware driver's license within ___ days of moving to Delaware.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6354#6355#6477#8141","text":"45#90#30#60"},
{"testId":1038,"qid":24649,"description":"A driver who is at least 18 years old and has an out-of-state driver's license must get a Delaware driver's license within ___ days of moving to Delaware.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6354#6355#6477#8141","text":"45#90#30#60"},
{"testId":571,"qid":24650,"description":"A parent or legal guardian must sign the application if the driving license or learner's permit applicant is under the age of:","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1038,"qid":24650,"description":"A parent or legal guardian must sign the application if the driving license or learner's permit applicant is under the age of:","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":571,"qid":24651,"description":"A newly obtained Delaware driver's license is good for one year.","explanation":null,"imageName":"","correctAnswer":43222,"isBookmarked":0,"aid":"26652#27049#31351#43222","text":"Five years#Ten years#Four years#Eight years"},
{"testId":1038,"qid":24651,"description":"A newly obtained Delaware driver's license is good for one year.","explanation":null,"imageName":"","correctAnswer":43222,"isBookmarked":0,"aid":"26652#27049#31351#43222","text":"Five years#Ten years#Four years#Eight years"},
{"testId":571,"qid":24652,"description":"Which day of the year will your driver's license expire?","explanation":null,"imageName":"","correctAnswer":43383,"isBookmarked":0,"aid":"43382#43383#43384#43385","text":"January 1#Your birthday#July 1#The anniversary of the date on which the license was issued"},
{"testId":1038,"qid":24652,"description":"Which day of the year will your driver's license expire?","explanation":null,"imageName":"","correctAnswer":43383,"isBookmarked":0,"aid":"43382#43383#43384#43385","text":"January 1#Your birthday#July 1#The anniversary of the date on which the license was issued"},
{"testId":571,"qid":24653,"description":"You can renew your driver's license up to _______before it expires.","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1038,"qid":24653,"description":"You can renew your driver's license up to _______before it expires.","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":571,"qid":24654,"description":"The Delaware initiative that is aimed to minimize the high accident and death rates among young drivers is:","explanation":null,"imageName":"","correctAnswer":43388,"isBookmarked":0,"aid":"8626#43386#43387#43388","text":"None of the above#the Teen Driver program#the Learning Driver License program#the Graduated Driver License program"},
{"testId":571,"qid":24655,"description":"A learner's permit can be obtained if you are at least _____ years old.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":571,"qid":24656,"description":"A learner's permit applicant who is under the age of 18 must have:","explanation":null,"imageName":"","correctAnswer":26582,"isBookmarked":0,"aid":"8615#26582#41197#43389","text":"All of the above#a Blue Certificate#an approved driving instructor#proof of high school enrollment"},
{"testId":571,"qid":24657,"description":"In Delaware, your vehicle liability insurance policy must offer at least ______ in bodily injury or death coverage for one individual in any one collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":571,"qid":24658,"description":"If a driver's sponsor in the Graduated Driver License (GDL) program withdraws his or her endorsement, the driver's driving privileges will be:","explanation":null,"imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#43390","text":"revoked#canceled#suspended#postponed"},
{"testId":571,"qid":24659,"description":"For the first six months that a driver under the age of 18 has a learner's permit, he or she must be supervised while driving by someone who:","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#43391#43392#43393","text":"meets all of the above requirements#sits next to the driver#is at least 25 years old#has held a Class D driver's license for at least five years"},
{"testId":571,"qid":24660,"description":"After six months of holding a learner's permit and 50 hours of supervised driving experience, a driver under the age of 18 may drive unsupervised:","explanation":null,"imageName":"","correctAnswer":43395,"isBookmarked":0,"aid":"43394#43395#43396#43397","text":"between 5:00 a.m. and 10:00 p.m#between 6:00 a.m. and 10:00 p.m#between 6:00 a.m. and 11:00 p.m#between 7:00 a.m. and midnight"},
{"testId":571,"qid":24661,"description":"There is a reinstatement charge of ______ for drivers who have had their licenses suspended.","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11832#12357#12358#12395","text":"$100#$150#$200#$250"},
{"testId":1038,"qid":24661,"description":"There is a reinstatement charge of ______ for drivers who have had their licenses suspended.","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11832#12357#12358#12395","text":"$100#$150#$200#$250"},
{"testId":571,"qid":24662,"description":"Will you lose your Delaware license for a traffic violation in another state?","explanation":null,"imageName":"","correctAnswer":43398,"isBookmarked":0,"aid":"18118#43398#43399#43400","text":"No#Yes, if the same type of violation normally causes loss of license when it occurs in Delaware#Yes, if you're under 21 years of age#Yes, if you've held a Delaware license for less than five years"},
{"testId":1038,"qid":24662,"description":"Will you lose your Delaware license for a traffic violation in another state?","explanation":null,"imageName":"","correctAnswer":43398,"isBookmarked":0,"aid":"18118#43398#43399#43400","text":"No#Yes, if the same type of violation normally causes loss of license when it occurs in Delaware#Yes, if you're under 21 years of age#Yes, if you've held a Delaware license for less than five years"},
{"testId":571,"qid":24663,"description":"A Delaware driver's driving history includes convictions for traffic violations that happened in:","explanation":null,"imageName":"","correctAnswer":43402,"isBookmarked":0,"aid":"26600#43401#43402#43403","text":"Delaware only#Delaware and its neighboring states#all states#all states outside of Delaware"},
{"testId":1038,"qid":24663,"description":"A Delaware driver's driving history includes convictions for traffic violations that happened in:","explanation":null,"imageName":"","correctAnswer":43402,"isBookmarked":0,"aid":"26600#43401#43402#43403","text":"Delaware only#Delaware and its neighboring states#all states#all states outside of Delaware"},
{"testId":571,"qid":24664,"description":"Your driver's license will be taken away if you are convicted of which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11943#42760#43404","text":"All of the above#DUI#Attempting to flee a police officer#Hit-and-run accident involving injury or death"},
{"testId":1038,"qid":24664,"description":"Your driver's license will be taken away if you are convicted of which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11943#42760#43404","text":"All of the above#DUI#Attempting to flee a police officer#Hit-and-run accident involving injury or death"},
{"testId":571,"qid":24666,"description":"If you are convicted of _____ major traffic violations in a five-year period, you will be labeled a habitual offender.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":1038,"qid":24666,"description":"If you are convicted of _____ major traffic violations in a five-year period, you will be labeled a habitual offender.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":571,"qid":24667,"description":"If convicted of DUI for the first time, you may face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35315#43405","text":"All of the above#A fine#A jail sentence#Mandatory license revocation"},
{"testId":571,"qid":24669,"description":"Your driver's license will be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12259#12260#12723","text":"Any of the above#driving a vehicle without the owner's permission#reckless driving#Drag racing"},
{"testId":1038,"qid":24669,"description":"Your driver's license will be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12259#12260#12723","text":"Any of the above#driving a vehicle without the owner's permission#reckless driving#Drag racing"},
{"testId":571,"qid":24670,"description":"If you owe more than ___ in child support for 30 days, your license may be suspended.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#3195#11669","text":"$500#$1,000#$5,000#$2,000"},
{"testId":1038,"qid":24670,"description":"If you owe more than ___ in child support for 30 days, your license may be suspended.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#3195#11669","text":"$500#$1,000#$5,000#$2,000"},
{"testId":571,"qid":24671,"description":"If convicted of driving uninsured vehicle for the first time, your license will be suspended for :聽:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"230#1272#1320#1453","text":"one year#6 months#two years#3 months"},
{"testId":1038,"qid":24671,"description":"If convicted of driving uninsured vehicle for the first time, your license will be suspended for :聽:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"230#1272#1320#1453","text":"one year#6 months#two years#3 months"},
{"testId":571,"qid":24672,"description":"If it's your first offense, you could be fined up to _____  for driving without vehicle insurance.","explanation":null,"imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11669#12001","text":"$500#$1,000#$2,000#$3,000"},
{"testId":1038,"qid":24672,"description":"If it's your first offense, you could be fined up to _____  for driving without vehicle insurance.","explanation":null,"imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11669#12001","text":"$500#$1,000#$2,000#$3,000"},
{"testId":571,"qid":24673,"description":"If you're convicted of driving with a suspended or revoked license, the suspension or revocation period will be ____ up to one year.","explanation":null,"imageName":"","correctAnswer":5340,"isBookmarked":0,"aid":"5340#12272#12273#43407","text":"doubled#tripled#quadrupled#quintupled"},
{"testId":1038,"qid":24673,"description":"If you're convicted of driving with a suspended or revoked license, the suspension or revocation period will be ____ up to one year.","explanation":null,"imageName":"","correctAnswer":5340,"isBookmarked":0,"aid":"5340#12272#12273#43407","text":"doubled#tripled#quadrupled#quintupled"},
{"testId":571,"qid":24674,"description":"What is the purpose of Delaware's Problem Driver Improvement Program?","explanation":null,"imageName":"","correctAnswer":43408,"isBookmarked":0,"aid":"26624#26625#26626#43408","text":"To impose fines on bad drivers#To impose fines on wealthy drivers#To restrict young drivers#To make problem drivers safer"},
{"testId":1038,"qid":24674,"description":"What is the purpose of Delaware's Problem Driver Improvement Program?","explanation":null,"imageName":"","correctAnswer":43408,"isBookmarked":0,"aid":"26624#26625#26626#43408","text":"To impose fines on bad drivers#To impose fines on wealthy drivers#To restrict young drivers#To make problem drivers safer"},
{"testId":571,"qid":24675,"description":"A Delaware person under the age of 21 who is found guilty of consuming or having alcoholic drinks will have his or her driver's license revoked for ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1020#18239","text":"90 days#30 days#60 days#14 days"},
{"testId":1038,"qid":24675,"description":"A Delaware person under the age of 21 who is found guilty of consuming or having alcoholic drinks will have his or her driver's license revoked for ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1020#18239","text":"90 days#30 days#60 days#14 days"},
{"testId":571,"qid":24677,"description":"If you are found guilty of exceeding the posted speed limit by 10 to 14 mph, _____ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":1038,"qid":24677,"description":"If you are found guilty of exceeding the posted speed limit by 10 to 14 mph, _____ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":571,"qid":24678,"description":"If you are found guilty of exceeding the posted speed limit by 15 miles per hour or more, _____ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":1038,"qid":24678,"description":"If you are found guilty of exceeding the posted speed limit by 15 miles per hour or more, _____ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":571,"qid":24679,"description":"If you pass a stopped school bus for passenger, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":1038,"qid":24679,"description":"If you pass a stopped school bus for passenger, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":571,"qid":24680,"description":"Which of the following will add six points to your driving record?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#866#11621#26633","text":"All of the above#Aggressive driving#Reckless driving#Operation of a vehicle causing death"},
{"testId":1038,"qid":24680,"description":"Which of the following will add six points to your driving record?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#866#11621#26633","text":"All of the above#Aggressive driving#Reckless driving#Operation of a vehicle causing death"},
{"testId":571,"qid":24681,"description":"After _________ points have been accrued on your driving record, their value will be reduced by half.","explanation":null,"imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"230#1320#4374#26635","text":"one year#two years#six months#four years"},
{"testId":1038,"qid":24681,"description":"After _________ points have been accrued on your driving record, their value will be reduced by half.","explanation":null,"imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"230#1320#4374#26635","text":"one year#two years#six months#four years"},
{"testId":571,"qid":24682,"description":"For how much time do the points remain on a driving record in Delaware?","explanation":null,"imageName":"","correctAnswer":11511,"isBookmarked":0,"aid":"11511#23291#26574#26636","text":"Two years#Five years#Ten years#Fifteen years"},
{"testId":1038,"qid":24682,"description":"For how much time do the points remain on a driving record in Delaware?","explanation":null,"imageName":"","correctAnswer":11511,"isBookmarked":0,"aid":"11511#23291#26574#26636","text":"Two years#Five years#Ten years#Fifteen years"},
{"testId":571,"qid":24683,"description":"If you are found guilty of running a red light, you will receive _____ point(s) on your driving record.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1038,"qid":24683,"description":"If you are found guilty of running a red light, you will receive _____ point(s) on your driving record.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":571,"qid":24684,"description":"How many points will be added to the driving record if convicted of aggressive driving?","explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":1038,"qid":24684,"description":"How many points will be added to the driving record if convicted of aggressive driving?","explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#10878","text":"Four#Six#Five#Seven"},
{"testId":571,"qid":24685,"description":"If your driving record's computed point value exceeds eight within two years,:","explanation":null,"imageName":"","correctAnswer":43409,"isBookmarked":0,"aid":"31384#34563#42489#43409","text":"you will be fined#any of the above may happen#you will be jailed#you will receive a warning letter from the DMV"},
{"testId":1038,"qid":24685,"description":"If your driving record's computed point value exceeds eight within two years,:","explanation":null,"imageName":"","correctAnswer":43409,"isBookmarked":0,"aid":"31384#34563#42489#43409","text":"you will be fined#any of the above may happen#you will be jailed#you will receive a warning letter from the DMV"},
{"testId":571,"qid":24686,"description":"If your driving record accumulates 12 points in two years, you must take a driver improvement course within __ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1038,"qid":24686,"description":"If your driving record accumulates 12 points in two years, you must take a driver improvement course within __ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":571,"qid":24688,"description":"Your driver's license will be suspended for six months if the calculated point value on your driving record reaches ___ within two years.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5448#11359#11361#11784","text":"20#18#16#14"},
{"testId":1038,"qid":24688,"description":"Your driver's license will be suspended for six months if the calculated point value on your driving record reaches ___ within two years.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5448#11359#11361#11784","text":"20#18#16#14"},
{"testId":571,"qid":24691,"description":"You will receive a ________ credit on your driving record if you successfully complete an approved defensive driving course.","explanation":null,"imageName":"","correctAnswer":43414,"isBookmarked":0,"aid":"43412#43413#43414#43415","text":"one point#two points#three points#four points"},
{"testId":1038,"qid":24691,"description":"You will receive a ________ credit on your driving record if you successfully complete an approved defensive driving course.","explanation":null,"imageName":"","correctAnswer":43414,"isBookmarked":0,"aid":"43412#43413#43414#43415","text":"one point#two points#three points#four points"},
{"testId":571,"qid":24693,"description":"If you are found guilty of exceeding the posted speed limit by ___ mph, your driver's license will be suspended for one month.","explanation":null,"imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5448#6425#6477#11513","text":"20#25#30#40"},
{"testId":1038,"qid":24693,"description":"If you are found guilty of exceeding the posted speed limit by ___ mph, your driver's license will be suspended for one month.","explanation":null,"imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5448#6425#6477#11513","text":"20#25#30#40"},
{"testId":571,"qid":24694,"description":"If you are found guilty of exceeding the posted speed limit by 50 miles per hour or more, your driver's license will be suspended for one year.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"26650#26651#26652#26653","text":"One year#Two years#Five years#seven years"},
{"testId":1038,"qid":24694,"description":"If you are found guilty of exceeding the posted speed limit by 50 miles per hour or more, your driver's license will be suspended for one year.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"26650#26651#26652#26653","text":"One year#Two years#Five years#seven years"},
{"testId":571,"qid":24695,"description":"If you are guilty of exceeding the stated speed limit by ______ miles per hour, you may choose to undergo a behavioral modification/attitudinal-driving course instead of having your license suspended.","explanation":null,"imageName":"","correctAnswer":43418,"isBookmarked":0,"aid":"43417#43418#43419#43420","text":"24鈥28#25鈥29#26鈥29#30鈥35"},
{"testId":1038,"qid":24695,"description":"If you are guilty of exceeding the stated speed limit by ______ miles per hour, you may choose to undergo a behavioral modification/attitudinal-driving course instead of having your license suspended.","explanation":null,"imageName":"","correctAnswer":43418,"isBookmarked":0,"aid":"43417#43418#43419#43420","text":"24鈥28#25鈥29#26鈥29#30鈥35"},
{"testId":572,"qid":24696,"description":"A new resident must obtain a DC driver license within ___ days after moving here.","explanation":"Within 30 days after moving to DC, you must get a DC driving license. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1039,"qid":24696,"description":"A new resident must obtain a DC driver license within ___ days after moving here.","explanation":"Within 30 days after moving to DC, you must get a DC driving license. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":572,"qid":24697,"description":"What is the minimum age to receive a driver's license?","explanation":"To receive a driver's license, you must be at least 17 years old. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":1039,"qid":24697,"description":"What is the minimum age to receive a driver's license?","explanation":"To receive a driver's license, you must be at least 17 years old. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":572,"qid":24698,"description":"To obtain a learner permit or driver's license if you are under the age of 18, you must have :","explanation":"If you are under the age of 18, you must have a parental permission letter signed by your parent or guardian in order to receive a learner permit or driving license. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":11923,"isBookmarked":0,"aid":"11923#12301#26654#26655","text":"parental consent#a new vehicle#a medical report from your doctor#a letter from your teacher"},
{"testId":1039,"qid":24698,"description":"To obtain a learner permit or driver's license if you are under the age of 18, you must have :","explanation":"If you are under the age of 18, you must have a parental permission letter signed by your parent or guardian in order to receive a learner permit or driving license. [District of Columbia Driving Manual, Introduction]","imageName":"","correctAnswer":11923,"isBookmarked":0,"aid":"11923#12301#26654#26655","text":"parental consent#a new vehicle#a medical report from your doctor#a letter from your teacher"},
{"testId":572,"qid":24699,"description":"What DC program aims to help young drivers improve their driving abilities?","explanation":"Gradual Rearing of Adult Drivers (GRAD) is a DC program for drivers aged 16 to 20 that attempts to improve their safe driving abilities. [District of Columbia Driving Manual, Types of Driver Licenses and Permits, Learner Permit]","imageName":"","correctAnswer":12306,"isBookmarked":0,"aid":"12304#12305#12306#12307","text":"Graduated Raising of Adult Drivers#Limited Learning for Teen Drivers#Gradual Rearing of Adult Drivers#Steps for Young Drivers"},
{"testId":1039,"qid":24699,"description":"What DC program aims to help young drivers improve their driving abilities?","explanation":"Gradual Rearing of Adult Drivers (GRAD) is a DC program for drivers aged 16 to 20 that attempts to improve their safe driving abilities. [District of Columbia Driving Manual, Types of Driver Licenses and Permits, Learner Permit]","imageName":"","correctAnswer":12306,"isBookmarked":0,"aid":"12304#12305#12306#12307","text":"Graduated Raising of Adult Drivers#Limited Learning for Teen Drivers#Gradual Rearing of Adult Drivers#Steps for Young Drivers"},
{"testId":572,"qid":24700,"description":"The supervising passenger for a driver with a learner's permit in the District of Columbia must:","explanation":"The supervising passenger for a DC driver with a learner permit must be 21 or older, have a valid full driving license, and sit next to the driver. [District of Columbia Driving Manual, Types of Driver Licenses and Permits, Learner Permit]","imageName":"","correctAnswer":26658,"isBookmarked":0,"aid":"25046#26656#26657#26658","text":"be 21 years of age or older#be seated next to the driver#have a valid full driver license#meet all of the above requirements"},
{"testId":1039,"qid":24700,"description":"The supervising passenger for a driver with a learner's permit in the District of Columbia must:","explanation":"The supervising passenger for a DC driver with a learner permit must be 21 or older, have a valid full driving license, and sit next to the driver. [District of Columbia Driving Manual, Types of Driver Licenses and Permits, Learner Permit]","imageName":"","correctAnswer":26658,"isBookmarked":0,"aid":"25046#26656#26657#26658","text":"be 21 years of age or older#be seated next to the driver#have a valid full driver license#meet all of the above requirements"},
{"testId":572,"qid":24701,"description":"For how much duration a DC learner permit is valid?","explanation":"A DC learner permit is good for two years and is non-renewable as of March 27, 2017. [https://dmv.dc.gov/node/1119166]","imageName":"","correctAnswer":25032,"isBookmarked":0,"aid":"23152#23153#25032#27027","text":"3 months#6 months#24 months#36 months"},
{"testId":1039,"qid":24701,"description":"For how much duration a DC learner permit is valid?","explanation":"A DC learner permit is good for two years and is non-renewable as of March 27, 2017. [https://dmv.dc.gov/node/1119166]","imageName":"","correctAnswer":25032,"isBookmarked":0,"aid":"23152#23153#25032#27027","text":"3 months#6 months#24 months#36 months"},
{"testId":572,"qid":24702,"description":"How many recorded hours of driving experience do you need to receive a provisional driver's license if you have a learner permit?","explanation":"To receive a DC provisional license, you must submit a Certification of Eligibility for Provisional License form, which validates that you have at least 40 hours of driving experience under the supervision of a licensed DC driver who is 21 or older. [District of Columbia Driving Manual, Provisional Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6354#6425#8141#11513","text":"45#25#60#40"},
{"testId":1039,"qid":24702,"description":"How many recorded hours of driving experience do you need to receive a provisional driver's license if you have a learner permit?","explanation":"To receive a DC provisional license, you must submit a Certification of Eligibility for Provisional License form, which validates that you have at least 40 hours of driving experience under the supervision of a licensed DC driver who is 21 or older. [District of Columbia Driving Manual, Provisional Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6354#6425#8141#11513","text":"45#25#60#40"},
{"testId":572,"qid":24703,"description":"How long do you have to have a provisional license before you can get a full driver's license?","explanation":"You will be able to get a full driving license after 6 months of having a provisional driver license. [District of Columbia Driving Manual, Provisional Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11306","text":"30 days#1 year#6 months#3 years"},
{"testId":1039,"qid":24703,"description":"How long do you have to have a provisional license before you can get a full driver's license?","explanation":"You will be able to get a full driving license after 6 months of having a provisional driver license. [District of Columbia Driving Manual, Provisional Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11306","text":"30 days#1 year#6 months#3 years"},
{"testId":572,"qid":24704,"description":"How many hours of night driving experience do you need to show before you can get a DC driver license?","explanation":"You must have completed at least 10 hours of night driving under the supervision of a qualified driver who is at least 21 years old before you may receive a full driver license. [District of Columbia Driving Manual, Full Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6425#6477","text":"10#20#25#30"},
{"testId":1039,"qid":24704,"description":"How many hours of night driving experience do you need to show before you can get a DC driver license?","explanation":"You must have completed at least 10 hours of night driving under the supervision of a qualified driver who is at least 21 years old before you may receive a full driver license. [District of Columbia Driving Manual, Full Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6425#6477","text":"10#20#25#30"},
{"testId":572,"qid":24705,"description":"A DC driver with a learner permit and an authorized supervised passenger may drive between the hours of:","explanation":"A District of Columbia driver with a learner permit and an approved supervising passenger in the car may drive between the hours of 6:00 a.m. and 9:00 p.m. [GRAD Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":12313,"isBookmarked":0,"aid":"12311#12312#12313#12314","text":"5:00 a.m. and 8:00 p.m#6:00 a.m. and 10:00 p.m#6:00 a.m. and 9:00 p.m#7:00 a.m. and 5:00 p.m"},
{"testId":1039,"qid":24705,"description":"A DC driver with a learner permit and an authorized supervised passenger may drive between the hours of:","explanation":"A District of Columbia driver with a learner permit and an approved supervising passenger in the car may drive between the hours of 6:00 a.m. and 9:00 p.m. [GRAD Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":12313,"isBookmarked":0,"aid":"12311#12312#12313#12314","text":"5:00 a.m. and 8:00 p.m#6:00 a.m. and 10:00 p.m#6:00 a.m. and 9:00 p.m#7:00 a.m. and 5:00 p.m"},
{"testId":572,"qid":24706,"description":"During the month of June, a DC driver with a provisional license may operate a car on a Saturday between the hours of :","explanation":"During the month of June, a DC driver with a provisional license may drive between 6:01 a.m. and 11:59 p.m. on Fridays and Sundays. Driving hours for conditionally licensed drivers vary depending on the month and day of the week. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12318,"isBookmarked":0,"aid":"12315#12316#12317#12318","text":"6:01 a.m. and 10:59 p.m#5:01 a.m. and 11:59 p.m#7:01 a.m. and 9:59 p.m#6:01 a.m. and 11:59 p.m"},
{"testId":1039,"qid":24706,"description":"During the month of June, a DC driver with a provisional license may operate a car on a Saturday between the hours of :","explanation":"During the month of June, a DC driver with a provisional license may drive between 6:01 a.m. and 11:59 p.m. on Fridays and Sundays. Driving hours for conditionally licensed drivers vary depending on the month and day of the week. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12318,"isBookmarked":0,"aid":"12315#12316#12317#12318","text":"6:01 a.m. and 10:59 p.m#5:01 a.m. and 11:59 p.m#7:01 a.m. and 9:59 p.m#6:01 a.m. and 11:59 p.m"},
{"testId":572,"qid":24707,"description":"When are GRAD-restricted drivers released from the program?","explanation":"Drivers in the District of Columbia are discharged from the GRAD programme at the age of 21. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#26659","text":"25#18#21#Upon graduation from college"},
{"testId":1039,"qid":24707,"description":"When are GRAD-restricted drivers released from the program?","explanation":"Drivers in the District of Columbia are discharged from the GRAD programme at the age of 21. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#26659","text":"25#18#21#Upon graduation from college"},
{"testId":572,"qid":24708,"description":"Which of the following allows a DC driver under the age of 18 to drive even when GRAD doesn't let them?","explanation":"A educational, civic, or religious activity permits a DC driver who is limited under the GRAD program to operate a vehicle when it would otherwise be prohibited. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26660#26661#26662","text":"All of the above#A school-sponsored activity#A civic activity sponsored by the District of Columbia#A religious event"},
{"testId":1039,"qid":24708,"description":"Which of the following allows a DC driver under the age of 18 to drive even when GRAD doesn't let them?","explanation":"A educational, civic, or religious activity permits a DC driver who is limited under the GRAD program to operate a vehicle when it would otherwise be prohibited. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26660#26661#26662","text":"All of the above#A school-sponsored activity#A civic activity sponsored by the District of Columbia#A religious event"},
{"testId":572,"qid":24709,"description":"You are not permitted to use a mobile phone or other electronic device, even with hands-free attachments, if you hold :","explanation":"If you have a learner permit, you are not permitted to use a mobile phone while driving, even if you have hands-free gear. [District of Columbia Driving Manual, GRAD Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":26663,"isBookmarked":0,"aid":"848#3792#26663#26664","text":"Any of the above#a provisional license#a learner permit#a driver license"},
{"testId":1039,"qid":24709,"description":"You are not permitted to use a mobile phone or other electronic device, even with hands-free attachments, if you hold :","explanation":"If you have a learner permit, you are not permitted to use a mobile phone while driving, even if you have hands-free gear. [District of Columbia Driving Manual, GRAD Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":26663,"isBookmarked":0,"aid":"848#3792#26663#26664","text":"Any of the above#a provisional license#a learner permit#a driver license"},
{"testId":572,"qid":24710,"description":"You may transport _________ as a passenger if you have a provisional license and are between the ages of 16 1/2 and 20.","explanation":"You may transport one member of your immediate family, a licensed driver 21 years of age or older, or both as passengers if you have a provisional license and are between the ages of 16 1/2 and 20. [District of Columbia Driving Manual, Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12324,"isBookmarked":0,"aid":"12019#12323#12324#26665","text":"a teacher#a classmate#a sibling#someone you are babysitting"},
{"testId":1039,"qid":24710,"description":"You may transport _________ as a passenger if you have a provisional license and are between the ages of 16 1/2 and 20.","explanation":"You may transport one member of your immediate family, a licensed driver 21 years of age or older, or both as passengers if you have a provisional license and are between the ages of 16 1/2 and 20. [District of Columbia Driving Manual, Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12324,"isBookmarked":0,"aid":"12019#12323#12324#26665","text":"a teacher#a classmate#a sibling#someone you are babysitting"},
{"testId":572,"qid":24711,"description":"Who in the vehicle must wear seat belts if the driver is under the age of 21 and has a temporary license?","explanation":"This is a deceptive question. No matter how old the driver is, how much driving experience the driver has, or what form of permit or license the driver possesses, the driver and all passengers must wear seat belts. Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual; and Important Things to Know, District of Columbia Driving Manual]","imageName":"","correctAnswer":26666,"isBookmarked":0,"aid":"4420#26666#26667#26668","text":"Only the driver#Everyone#The driver and front-seat passengers#All passengers under the age of 18"},
{"testId":1039,"qid":24711,"description":"Who in the vehicle must wear seat belts if the driver is under the age of 21 and has a temporary license?","explanation":"This is a deceptive question. No matter how old the driver is, how much driving experience the driver has, or what form of permit or license the driver possesses, the driver and all passengers must wear seat belts. Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual; and Important Things to Know, District of Columbia Driving Manual]","imageName":"","correctAnswer":26666,"isBookmarked":0,"aid":"4420#26666#26667#26668","text":"Only the driver#Everyone#The driver and front-seat passengers#All passengers under the age of 18"},
{"testId":572,"qid":24712,"description":"You may carry up to __ persons under the age of 21 if you are under the age of 18 and have a full driver's license.","explanation":"You may carry up to two people under the age of 21 if you are under the age of 18 and have a full driver's license. [Full License with Restrictions (Under the Age of 18), Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Driver License and Permit Types, District of Columbia Driving Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1039,"qid":24712,"description":"You may carry up to __ persons under the age of 21 if you are under the age of 18 and have a full driver's license.","explanation":"You may carry up to two people under the age of 21 if you are under the age of 18 and have a full driver's license. [Full License with Restrictions (Under the Age of 18), Grad Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Driver License and Permit Types, District of Columbia Driving Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":572,"qid":24713,"description":"To receive a provisional driver license while holding a learner permit, you must have no points on your driving record for ___ months.","explanation":"To apply for a provisional driver license while holding a learner permit, you must keep a clean driving record for 6 months. [District of Columbia Driving Manual, Provisional Driver License, Types of Licenses and Permits]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6388#6389#6916","text":"5#2#3#6"},
{"testId":1039,"qid":24713,"description":"To receive a provisional driver license while holding a learner permit, you must have no points on your driving record for ___ months.","explanation":"To apply for a provisional driver license while holding a learner permit, you must keep a clean driving record for 6 months. [District of Columbia Driving Manual, Provisional Driver License, Types of Licenses and Permits]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6388#6389#6916","text":"5#2#3#6"},
{"testId":572,"qid":24715,"description":"To earn a full driver license while holding a provisional license under the GRAD program, you must have a clean driving record for ___ months.","explanation":"To go from a provisional license to a full driver license, you must have a clean driving record for a period of 12 months. [Provisional License Stage, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6388#6390#6478#7620","text":"2#4#12#8"},
{"testId":1039,"qid":24715,"description":"To earn a full driver license while holding a provisional license under the GRAD program, you must have a clean driving record for ___ months.","explanation":"To go from a provisional license to a full driver license, you must have a clean driving record for a period of 12 months. [Provisional License Stage, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6388#6390#6478#7620","text":"2#4#12#8"},
{"testId":572,"qid":24717,"description":"What sorts of cell phones are permitted to be used while driving?","explanation":"Cell phones are only permitted to be used while driving if they have hands-free capabilities. It should be noted that if you have a learner permit, you are not permitted to use a mobile phone at all. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26669,"isBookmarked":0,"aid":"234#26669#26670#26671","text":"Any of the above#Hands-free#Cell phone with speakerphone#Smartphone"},
{"testId":1039,"qid":24717,"description":"What sorts of cell phones are permitted to be used while driving?","explanation":"Cell phones are only permitted to be used while driving if they have hands-free capabilities. It should be noted that if you have a learner permit, you are not permitted to use a mobile phone at all. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26669,"isBookmarked":0,"aid":"234#26669#26670#26671","text":"Any of the above#Hands-free#Cell phone with speakerphone#Smartphone"},
{"testId":572,"qid":24718,"description":"For what duration will points remain on your driving record?","explanation":"Points are recorded on your driving record for two years. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":1039,"qid":24718,"description":"For what duration will points remain on your driving record?","explanation":"Points are recorded on your driving record for two years. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":572,"qid":24719,"description":"You may be granted a Good Point if you do not accrue any points on your driving record for :","explanation":"If you do not accrue any points for the whole calendar year, your driving record may be rewarded with one Good Point. You may get one Good Point for each year in which you do not earn any points, up to a maximum of five Good Points. These Good Points may be utilized to compensate for very minor movement offenses. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":1039,"qid":24719,"description":"You may be granted a Good Point if you do not accrue any points on your driving record for :","explanation":"If you do not accrue any points for the whole calendar year, your driving record may be rewarded with one Good Point. You may get one Good Point for each year in which you do not earn any points, up to a maximum of five Good Points. These Good Points may be utilized to compensate for very minor movement offenses. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":572,"qid":24722,"description":"Third or subsequent DUI convictions result in聽license suspension for:","explanation":"If you are convicted of three or more DUI offenses, your license will be suspended for three years. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":1039,"qid":24722,"description":"Third or subsequent DUI convictions result in聽license suspension for:","explanation":"If you are convicted of three or more DUI offenses, your license will be suspended for three years. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":572,"qid":24723,"description":"What is the minimum suspension term in the District of Columbia for a first DUI conviction?","explanation":"A DUI conviction in the District of Columbia will result in a six-month license suspension. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":1039,"qid":24723,"description":"What is the minimum suspension term in the District of Columbia for a first DUI conviction?","explanation":"A DUI conviction in the District of Columbia will result in a six-month license suspension. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":572,"qid":24724,"description":"You will be expected to submit ____________, if you are stopped by law enforcement.","explanation":"If you are stopped by law enforcement, you must provide your legal driver's license, proof of insurance, and car registration. If you do to comply, you may be fined or arrested. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#26673#26674#26675","text":"All of the above#your valid driver license#proof of insurance#your vehicle registration"},
{"testId":1039,"qid":24724,"description":"You will be expected to submit ____________, if you are stopped by law enforcement.","explanation":"If you are stopped by law enforcement, you must provide your legal driver's license, proof of insurance, and car registration. If you do to comply, you may be fined or arrested. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#26673#26674#26675","text":"All of the above#your valid driver license#proof of insurance#your vehicle registration"},
{"testId":572,"qid":24725,"description":"Traffic offences committed __________ will accrue points on your driving record.","explanation":"A traffic offense, whether committed in the District of Columbia or any other US state, will result in the addition of points to your driving record. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26678,"isBookmarked":0,"aid":"26676#26677#26678#26679","text":"only in DC#in DC or neighboring states#in DC or any US state#only in US states"},
{"testId":1039,"qid":24725,"description":"Traffic offences committed __________ will accrue points on your driving record.","explanation":"A traffic offense, whether committed in the District of Columbia or any other US state, will result in the addition of points to your driving record. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26678,"isBookmarked":0,"aid":"26676#26677#26678#26679","text":"only in DC#in DC or neighboring states#in DC or any US state#only in US states"},
{"testId":572,"qid":24726,"description":"Which of the following describes aggressive driving?","explanation":"Aggressive driving includes running a red light, following too closely (tailgating), passing on the right, speeding, and failing to cede the right-of-way. [Driver Information, District of Columbia Driving Manual, Aggressive Driving]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26680#26681#26682","text":"All of the above#Running a red light#Following too closely (tailgating)#Passing on the right"},
{"testId":1039,"qid":24726,"description":"Which of the following describes aggressive driving?","explanation":"Aggressive driving includes running a red light, following too closely (tailgating), passing on the right, speeding, and failing to cede the right-of-way. [Driver Information, District of Columbia Driving Manual, Aggressive Driving]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26680#26681#26682","text":"All of the above#Running a red light#Following too closely (tailgating)#Passing on the right"},
{"testId":572,"qid":24727,"description":"If you are involved in an accident, you are required by law to:","explanation":"If you are in a collision, you must stop, assist wounded motorists, submit insurance information, and stay at the site until first responders come. [Accidents, Driver Information, Driving Manual of the District of Columbia]","imageName":"202006141611292838.jpg","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26683#26684#26685","text":"Do all of the above#stop to provide aid#provide insurance information#remain at the scene until first responders arrive"},
{"testId":572,"qid":24728,"description":"Your vehicle collides with a domestic animal while you are driving. Which of the following should you do?","explanation":"If your car collides with and injures a domestic animal, you should call the local police immediately so that suitable measures may be made. [District of Columbia Driving Manual, Duty of a Motorist Striking a Domestic Animal with a Motor Vehicle]","imageName":"","correctAnswer":26686,"isBookmarked":0,"aid":"26686#26687#26688#26689","text":"Call the police#Stop to pick up the animal#Try to find the owner#Ignore it and keep driving"},
{"testId":1039,"qid":24728,"description":"Your vehicle collides with a domestic animal while you are driving. Which of the following should you do?","explanation":"If your car collides with and injures a domestic animal, you should call the local police immediately so that suitable measures may be made. [District of Columbia Driving Manual, Duty of a Motorist Striking a Domestic Animal with a Motor Vehicle]","imageName":"","correctAnswer":26686,"isBookmarked":0,"aid":"26686#26687#26688#26689","text":"Call the police#Stop to pick up the animal#Try to find the owner#Ignore it and keep driving"},
{"testId":572,"qid":24729,"description":"Which of the following could cause your driver's license to be taken away?","explanation":"If you are convicted of a non-driving-related drug violation, your driver's license may be revoked. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26691,"isBookmarked":0,"aid":"26690#26691#26692#26693","text":"Theft#Drug offense#Domestic violence#Insurance fraud"},
{"testId":1039,"qid":24729,"description":"Which of the following could cause your driver's license to be taken away?","explanation":"If you are convicted of a non-driving-related drug violation, your driver's license may be revoked. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26691,"isBookmarked":0,"aid":"26690#26691#26692#26693","text":"Theft#Drug offense#Domestic violence#Insurance fraud"},
{"testId":572,"qid":24730,"description":"What led to the suspension of your driving license if you failed to pay?","explanation":"If you fail to pay child support, your driver's license may be suspended. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26694,"isBookmarked":0,"aid":"26694#26695#26696#26697","text":"Child support#A traffic fine#A parking ticket#Your insurance premiums"},
{"testId":1039,"qid":24730,"description":"What led to the suspension of your driving license if you failed to pay?","explanation":"If you fail to pay child support, your driver's license may be suspended. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26694,"isBookmarked":0,"aid":"26694#26695#26696#26697","text":"Child support#A traffic fine#A parking ticket#Your insurance premiums"},
{"testId":572,"qid":24731,"description":"What must you pay to have your license reinstated if it has been suspended?","explanation":"If your license has been suspended, you must pay a (license) reinstatement fee to get it restored. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26700,"isBookmarked":0,"aid":"897#26698#26699#26700","text":"None of the above#New license fee#Suspension fee#Reinstatement fee"},
{"testId":1039,"qid":24731,"description":"What must you pay to have your license reinstated if it has been suspended?","explanation":"If your license has been suspended, you must pay a (license) reinstatement fee to get it restored. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26700,"isBookmarked":0,"aid":"897#26698#26699#26700","text":"None of the above#New license fee#Suspension fee#Reinstatement fee"},
{"testId":572,"qid":24732,"description":"Which of the following in the District of Columbia constitutes an acknowledgment of liability?","explanation":"Paying a traffic ticket is seen as admitting guilt. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26702,"isBookmarked":0,"aid":"897#26701#26702#26703","text":"None of the above#Talking to a police officer#Paying a traffic citation#Calling the DMV"},
{"testId":1039,"qid":24732,"description":"Which of the following in the District of Columbia constitutes an acknowledgment of liability?","explanation":"Paying a traffic ticket is seen as admitting guilt. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":26702,"isBookmarked":0,"aid":"897#26701#26702#26703","text":"None of the above#Talking to a police officer#Paying a traffic citation#Calling the DMV"},
{"testId":572,"qid":24733,"description":"Who must wear a seat belt in District of Columbia-registered vehicles?","explanation":"Every individual in a vehicle is required by DC law to wear a seat belt. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"9808#16365#26667#26704","text":"The driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers if the vehicle lacks airbags"},
{"testId":1039,"qid":24733,"description":"Who must wear a seat belt in District of Columbia-registered vehicles?","explanation":"Every individual in a vehicle is required by DC law to wear a seat belt. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"9808#16365#26667#26704","text":"The driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers if the vehicle lacks airbags"},
{"testId":572,"qid":24734,"description":"What is the penalty in DC for not wearing a seat belt?","explanation":"You, the driver, are in charge of making sure that everyone in your car is buckled up. $50 in fines will be imposed on you if a passenger in your car is found riding without a seatbelt. Seat Belt Law/Click It or Ticket, Traffic Rules, District of Columbia Driver's Manual, $50 Fine, and 2 Points","imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11349#11832#12357#12358","text":"$50#$100#$150#$200"},
{"testId":1039,"qid":24734,"description":"What is the penalty in DC for not wearing a seat belt?","explanation":"You, the driver, are in charge of making sure that everyone in your car is buckled up. $50 in fines will be imposed on you if a passenger in your car is found riding without a seatbelt. Seat Belt Law/Click It or Ticket, Traffic Rules, District of Columbia Driver's Manual, $50 Fine, and 2 Points","imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11349#11832#12357#12358","text":"$50#$100#$150#$200"},
{"testId":572,"qid":24735,"description":"__points will be added to your driving record for not wearing a seat belt.","explanation":"If you are convicted of not wearing a seat belt, you will be fined $50 and have two points added to your driving record. [$50 Fine and 2 Points, Seat Belt Law/Click It or Ticket, Traffic Rules, DC Driving Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1039,"qid":24735,"description":"__points will be added to your driving record for not wearing a seat belt.","explanation":"If you are convicted of not wearing a seat belt, you will be fined $50 and have two points added to your driving record. [$50 Fine and 2 Points, Seat Belt Law/Click It or Ticket, Traffic Rules, DC Driving Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":572,"qid":24736,"description":"Children under the age of _____ must be secured in child safety seats at all times while being transported.","explanation":"A suitable child safety seat must be used to restrain any kid under the age of three. Child safety seats, the click-it-or-ticket seat belt law, traffic laws, and the District of Columbia driver's manual","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1039,"qid":24736,"description":"Children under the age of _____ must be secured in child safety seats at all times while being transported.","explanation":"A suitable child safety seat must be used to restrain any kid under the age of three. Child safety seats, the click-it-or-ticket seat belt law, traffic laws, and the District of Columbia driver's manual","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":572,"qid":24737,"description":"If you're ___ or older and want to renew your license, a physician must certify that you can drive safely.","explanation":"If you are 70 or older, your physician must complete the Mature Driving component of the DC driver license application, verifying that you may safely operate a motor vehicle. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":9656,"isBookmarked":0,"aid":"7569#9656#9658#12359","text":"75#70#55#80"},
{"testId":1039,"qid":24737,"description":"If you're ___ or older and want to renew your license, a physician must certify that you can drive safely.","explanation":"If you are 70 or older, your physician must complete the Mature Driving component of the DC driver license application, verifying that you may safely operate a motor vehicle. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":9656,"isBookmarked":0,"aid":"7569#9656#9658#12359","text":"75#70#55#80"},
{"testId":572,"qid":24738,"description":"If your driver's license has been expired for more than _____ days, you must take and pass the DC online traffic school course or the knowledge test.","explanation":"To renew a driving license that has been expired for longer than 365 days, you must first take and pass the knowledge exam or complete the DC online traffic school course. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":11497,"isBookmarked":0,"aid":"6355#6802#8141#11497","text":"90#180#60#365"},
{"testId":1039,"qid":24738,"description":"If your driver's license has been expired for more than _____ days, you must take and pass the DC online traffic school course or the knowledge test.","explanation":"To renew a driving license that has been expired for longer than 365 days, you must first take and pass the knowledge exam or complete the DC online traffic school course. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":11497,"isBookmarked":0,"aid":"6355#6802#8141#11497","text":"90#180#60#365"},
{"testId":572,"qid":24739,"description":"If you fail the driver's license exam, you can retake:","explanation":"If you fail the driving license test, you must wait 72 hours before taking it again. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":26706,"isBookmarked":0,"aid":"26705#26706#26707#26708","text":"in 7 days#in 3 days#the next day#immediately"},
{"testId":1039,"qid":24739,"description":"If you fail the driver's license exam, you can retake:","explanation":"If you fail the driving license test, you must wait 72 hours before taking it again. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":26706,"isBookmarked":0,"aid":"26705#26706#26707#26708","text":"in 7 days#in 3 days#the next day#immediately"},
{"testId":572,"qid":24740,"description":"If your learner permit expires before you have your temporary license, you must renew it __________to prevent having to retake the knowledge exam.","explanation":"If your learner permit is slated to expire before you can go to the provisional license level, you MUST renew it before it expires. There is no grace period for renewing a learner permit that has expired. When your learner permit expires, you must retake and pass the knowledge exam in order to receive a new one. [District of Columbia Driver Manual, Learner Permit, Types of Driver Licenses & Permits]","imageName":"","correctAnswer":35620,"isBookmarked":0,"aid":"35619#35620#35621#35622","text":"within 180 days after the expiry date#prior to expiration#within 90 days after the expiry date#within 1 year after the expiry date"},
{"testId":1039,"qid":24740,"description":"If your learner permit expires before you have your temporary license, you must renew it __________to prevent having to retake the knowledge exam.","explanation":"If your learner permit is slated to expire before you can go to the provisional license level, you MUST renew it before it expires. There is no grace period for renewing a learner permit that has expired. When your learner permit expires, you must retake and pass the knowledge exam in order to receive a new one. [District of Columbia Driver Manual, Learner Permit, Types of Driver Licenses & Permits]","imageName":"","correctAnswer":35620,"isBookmarked":0,"aid":"35619#35620#35621#35622","text":"within 180 days after the expiry date#prior to expiration#within 90 days after the expiry date#within 1 year after the expiry date"},
{"testId":572,"qid":24741,"description":"A DC learner permit is not valid unless:","explanation":"Unless you are a part of the GRAD program or are 21 years of age or older, your learner permit will not be renewed. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":37199,"isBookmarked":0,"aid":"37196#37197#37198#37199","text":"(a) you are a member of the GRAD program#(b) you are 18 years of age or older#(c) you are 21 years of age or older#either (a) or (c) is the case"},
{"testId":1039,"qid":24741,"description":"A DC learner permit is not valid unless:","explanation":"Unless you are a part of the GRAD program or are 21 years of age or older, your learner permit will not be renewed. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"","correctAnswer":37199,"isBookmarked":0,"aid":"37196#37197#37198#37199","text":"(a) you are a member of the GRAD program#(b) you are 18 years of age or older#(c) you are 21 years of age or older#either (a) or (c) is the case"},
{"testId":572,"qid":24742,"description":"You will be unable to _____ if you are convicted of speeding while holding a learner permit.","explanation":"If you are convicted of a traffic offence that adds points to your driving record while you have a learner permit, you will not be eligible for a temporary license. Before you may apply for a temporary license, you must first possess a learner permit for 6 consecutive point-free months. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Learner Permit Stage]","imageName":"","correctAnswer":26714,"isBookmarked":0,"aid":"26713#26714#26715#26716","text":"operate a vehicle#apply for a provisional license#purchase a vehicle#graduate from high school"},
{"testId":1039,"qid":24742,"description":"You will be unable to _____ if you are convicted of speeding while holding a learner permit.","explanation":"If you are convicted of a traffic offence that adds points to your driving record while you have a learner permit, you will not be eligible for a temporary license. Before you may apply for a temporary license, you must first possess a learner permit for 6 consecutive point-free months. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Learner Permit Stage]","imageName":"","correctAnswer":26714,"isBookmarked":0,"aid":"26713#26714#26715#26716","text":"operate a vehicle#apply for a provisional license#purchase a vehicle#graduate from high school"},
{"testId":572,"qid":24743,"description":"You will not be permitted to operate a vehicle as part of your job if you are under the age of :","explanation":"You may not drive a vehicle for compensation if you are under the age of 18. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Provisional License for Drivers 16 1/2- 20 Years Old, GRAD Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":1039,"qid":24743,"description":"You will not be permitted to operate a vehicle as part of your job if you are under the age of :","explanation":"You may not drive a vehicle for compensation if you are under the age of 18. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Provisional License for Drivers 16 1/2- 20 Years Old, GRAD Program Restrictions, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":572,"qid":24744,"description":"A ________ driving license in the District of Columbia is a regular, complete, non-commercial driver license.","explanation":"A class D driver license is a typical complete non-commercial driving license in the District of Columbia. It allows you to operate non-commercial vehicles with a gross vehicle weight rating (GVWR) of 26,000 pounds or less, motor-driven cycles, and passenger vans seating up to 15 people. [District of Columbia Driving Manual, Full Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":26720,"isBookmarked":0,"aid":"26717#26718#26719#26720","text":"class A#class B#class C#class D"},
{"testId":1039,"qid":24744,"description":"A ________ driving license in the District of Columbia is a regular, complete, non-commercial driver license.","explanation":"A class D driver license is a typical complete non-commercial driving license in the District of Columbia. It allows you to operate non-commercial vehicles with a gross vehicle weight rating (GVWR) of 26,000 pounds or less, motor-driven cycles, and passenger vans seating up to 15 people. [District of Columbia Driving Manual, Full Driver License, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":26720,"isBookmarked":0,"aid":"26717#26718#26719#26720","text":"class A#class B#class C#class D"},
{"testId":572,"qid":24745,"description":"17- and 18-year-olds with a driver's license (not a learner's permit) can drive between________ 聽hours throughout聽July.","explanation":"During the months of July and August, if you are 17 or 18 years old and hold a driving license (not a learner permit), you may operate a car between 6:01 a.m. and 11:59 p.m. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12318,"isBookmarked":0,"aid":"12318#12371#12372#12373","text":"6:01 a.m. and 11:59 p.m#7:01 a.m. and 11:59 p.m#6:01 a.m. and 10:00 p.m#7:01 a.m. and 10:00 p.m"},
{"testId":1039,"qid":24745,"description":"17- and 18-year-olds with a driver's license (not a learner's permit) can drive between________ 聽hours throughout聽July.","explanation":"During the months of July and August, if you are 17 or 18 years old and hold a driving license (not a learner permit), you may operate a car between 6:01 a.m. and 11:59 p.m. [District of Columbia Driving Manual, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits]","imageName":"","correctAnswer":12318,"isBookmarked":0,"aid":"12318#12371#12372#12373","text":"6:01 a.m. and 11:59 p.m#7:01 a.m. and 11:59 p.m#6:01 a.m. and 10:00 p.m#7:01 a.m. and 10:00 p.m"},
{"testId":573,"qid":24746,"description":"Which of the following penalties may you face if you are caught speeding in a Florida school zone?","explanation":"If you are caught speeding in Florida, you will be fined and points will be added to your driving record. The penalties will be doubled if you were speeding in a school or work zone. This might cost up to $1,000. You may also be needed to attend a driving school. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":41788,"isBookmarked":0,"aid":"12374#12377#23075#41788","text":"License suspension#License revocation#Community service#Double the usual fine for speeding"},
{"testId":1040,"qid":24746,"description":"Which of the following penalties may you face if you are caught speeding in a Florida school zone?","explanation":"If you are caught speeding in Florida, you will be fined and points will be added to your driving record. The penalties will be doubled if you were speeding in a school or work zone. This might cost up to $1,000. You may also be needed to attend a driving school. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":41788,"isBookmarked":0,"aid":"12374#12377#23075#41788","text":"License suspension#License revocation#Community service#Double the usual fine for speeding"},
{"testId":573,"qid":24747,"description":"Which of the following is the non-commercial driver license class?","explanation":"The Class E driving license is intended for non-commercial vehicle operators. Commercial driver licenses are classified into three classes: A, B, and C. (CDLs). [Florida Driver License Classification, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12378,"isBookmarked":0,"aid":"322#323#324#12378","text":"A#B#C#E"},
{"testId":573,"qid":24748,"description":"A new Florida citizen who intends to continue driving must obtain a Florida driver license within :","explanation":"A motorist must get a Florida driving license within 30 days of becoming a resident of Florida, accepting a job in Florida, or enrolling his or her kid in a Florida public school. [Who Needs a Florida Driver's License? , 10. Obtaining Your License or Identification Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1040,"qid":24748,"description":"A new Florida citizen who intends to continue driving must obtain a Florida driver license within :","explanation":"A motorist must get a Florida driving license within 30 days of becoming a resident of Florida, accepting a job in Florida, or enrolling his or her kid in a Florida public school. [Who Needs a Florida Driver's License? , 10. Obtaining Your License or Identification Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":573,"qid":24749,"description":"Unmarried applicants under ______ must have a parent or guardian sign their license application.","explanation":"If a driving license applicant is unmarried and under the age of 18, his or her parent or guardian must sign the application. [Parental Consent for Minors, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":1040,"qid":24749,"description":"Unmarried applicants under ______ must have a parent or guardian sign their license application.","explanation":"If a driving license applicant is unmarried and under the age of 18, his or her parent or guardian must sign the application. [Parental Consent for Minors, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":573,"qid":24750,"description":"If a parent or guardian who signed a minor's driving license application renounces responsibility, the license will be:","explanation":"If a responsible parent or legal guardian who signed a minor's driving license application relinquishes responsibility for the individual, the license will be revoked immediately (i.e., rendered null and void). [Parental Consent for Minors, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":1040,"qid":24750,"description":"If a parent or guardian who signed a minor's driving license application renounces responsibility, the license will be:","explanation":"If a responsible parent or legal guardian who signed a minor's driving license application relinquishes responsibility for the individual, the license will be revoked immediately (i.e., rendered null and void). [Parental Consent for Minors, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":573,"qid":24751,"description":"Who is permitted in the car during the driving test?","explanation":"Only the aspiring driver, the official driving examiner, and a language translator (if required) may be present in the car during the driving skills test. Additional passengers are not authorized. [12. Driver License Testing, Official Florida Driver's Handbook, Driving Skills Test]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41789#41790#41791","text":"All of the above#The aspiring driver#The driving examiner#A language interpreter"},
{"testId":573,"qid":24752,"description":"According to Florida's No-Fault Law, every four-wheeled motor vehicle registered in the state must carry which of the following types of insurance?","explanation":"Every motor vehicle with at least four wheels registered in Florida must carry Personal Injury Protection (PIP) and Property Damage Liability (PDL) insurance, according to Florida's No-Fault statute. If you are in an accident, PIP will compensate for your injuries regardless of who is at blame. PDL is responsible for damage to other people's property. The Bodily Injury Liability (BDL) coverage covers for the injuries sustained by others in the accident. Under Florida's Personal Responsibility Law, BDL may also be needed in certain cases. 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":38691,"isBookmarked":0,"aid":"38691#41792#41793#41794","text":"(a) and (c)#(a) Personal Injury Protection#(b) Bodily Injury Liability#(c) Property Damage Liability"},
{"testId":573,"qid":24753,"description":"If you breach any of your driver license's restrictions,:","explanation":"If you break any of the limitations on your driving license, you may be penalized and fined, and your license may be suspended, according to the Florida Driver License Handbook. Three points will be added to your driving record as well. [Appendix C, Uniform Traffic Citation Manual, Florida Department of Highway Safety and Motor Vehicles], [Restriction Codes,10. Getting Your License or ID Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":41918,"isBookmarked":0,"aid":"41916#41917#41918#41919","text":"a) you may be fined#b) your license may be suspended#a) and b)#c) your license may be revoked"},
{"testId":1040,"qid":24753,"description":"If you breach any of your driver license's restrictions,:","explanation":"If you break any of the limitations on your driving license, you may be penalized and fined, and your license may be suspended, according to the Florida Driver License Handbook. Three points will be added to your driving record as well. [Appendix C, Uniform Traffic Citation Manual, Florida Department of Highway Safety and Motor Vehicles], [Restriction Codes,10. Getting Your License or ID Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":41918,"isBookmarked":0,"aid":"41916#41917#41918#41919","text":"a) you may be fined#b) your license may be suspended#a) and b)#c) your license may be revoked"},
{"testId":573,"qid":24754,"description":"If your driver license was suspended because you did not respond to a traffic citation, you must pay a charge of ____ to have it reinstated.","explanation":"If your driving license was suspended because you failed to appear in court or pay a traffic ticket charge, you must pay a $60 reinstatement fee. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12394,"isBookmarked":0,"aid":"11348#11349#12393#12394","text":"$25#$50#$40#$60"},
{"testId":1040,"qid":24754,"description":"If your driver license was suspended because you did not respond to a traffic citation, you must pay a charge of ____ to have it reinstated.","explanation":"If your driving license was suspended because you failed to appear in court or pay a traffic ticket charge, you must pay a $60 reinstatement fee. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12394,"isBookmarked":0,"aid":"11348#11349#12393#12394","text":"$25#$50#$40#$60"},
{"testId":573,"qid":24755,"description":"How much does it cost to have your driver's license reinstated if you failed to maintain PIP insurance on your vehicle for the first time?","explanation":"If your driving license was suspended because you failed to keep PIP insurance on your car and it was your first suspension, you must pay a $150 reinstatement fee. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12357,"isBookmarked":0,"aid":"12357#12358#12395#12396","text":"$150#$200#$250#$225"},
{"testId":573,"qid":24756,"description":"How much does it cost to have your driver's license reinstated if you failed to maintain PIP insurance on your vehicle for the first time?","explanation":"If your driving license was suspended because you failed to keep PIP insurance on your car, and this was your second suspension, you must pay a $250 reinstatement fee. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11833#12357#12358#12395","text":"$300#$150#$200#$250"},
{"testId":573,"qid":24757,"description":"If you have had your driver's license suspended for an alcohol or drug-related violation, you must pay an administrative charge of ____ to have it reinstated.","explanation":"If your driver's license has been suspended due to an alcohol or drug-related crime, you must pay a $130 administrative charge to get it restored. This is in addition to any additional costs or criminal penalties imposed for the violation. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12397,"isBookmarked":0,"aid":"11349#11833#12397#12398","text":"$50#$300#$130#$75"},
{"testId":1040,"qid":24757,"description":"If you have had your driver's license suspended for an alcohol or drug-related violation, you must pay an administrative charge of ____ to have it reinstated.","explanation":"If your driver's license has been suspended due to an alcohol or drug-related crime, you must pay a $130 administrative charge to get it restored. This is in addition to any additional costs or criminal penalties imposed for the violation. [Fees, Official Florida Driver's Handbook, 10. Obtaining Your License or ID Card]","imageName":"","correctAnswer":12397,"isBookmarked":0,"aid":"11349#11833#12397#12398","text":"$50#$300#$130#$75"},
{"testId":573,"qid":24758,"description":"When do you need to get Bodily Injury Liability (BIL) insurance on your vehicle?","explanation":"Under certain conditions, Florida's Financial Responsibility Law mandates you to purchase Bodily Injury Liability (BIL) coverage in addition to the standard Personal Injury Protection (PIP) and Property Damage Liability (PDL) coverage on your car. Being convicted of DUI, having your license suspended for too many points, causing an accident in which people were hurt, or having your license revoked for too many major traffic crimes are examples of these scenarios. 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":41800,"isBookmarked":0,"aid":"41797#41798#41799#41800","text":"If you were convicted of DUI#If your license was suspended for excessive points#If you caused an accident resulting in injury#If any of the above occurred"},
{"testId":573,"qid":24759,"description":"If you are required to obtain Bodily Injury Liability (BIL) insurance coverage for your car but fail to do so, which of the following fines may apply to you?","explanation":null,"imageName":"","correctAnswer":38691,"isBookmarked":0,"aid":"38691#41801#41802#41803","text":"(a) and (c)#(a) Your license may be suspended#(b) Your vehicle may be impounded#(c) Your vehicle's license plates may be suspended"},
{"testId":1040,"qid":24759,"description":"If you are required to obtain Bodily Injury Liability (BIL) insurance coverage for your car but fail to do so, which of the following fines may apply to you?","explanation":null,"imageName":"","correctAnswer":38691,"isBookmarked":0,"aid":"38691#41801#41802#41803","text":"(a) and (c)#(a) Your license may be suspended#(b) Your vehicle may be impounded#(c) Your vehicle's license plates may be suspended"},
{"testId":573,"qid":24760,"description":"While holding a learner's permit, your driving must be supervised by a licensed driver who:","explanation":"Driving must be supervised by a licensed driver who is at least 21 years old and sat next to you in the front seat when you have a learner's license. [From the Official Florida Driver's Handbook, Important Information for Minor Drivers]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#41434#41804#41805","text":"meets all of the above requirements#holds a valid driver license#is at least 21 years old#sits next to you"},
{"testId":573,"qid":24761,"description":"A person under the age of 18_____________ before being awarded a learner's license.","explanation":null,"imageName":"","correctAnswer":41795,"isBookmarked":0,"aid":"38667#41795#41806#41807","text":"(a) or (b)#(a) and (b)#(a) must complete a TLSAE course#(b) must be enrolled in or have graduated from high school or the equivalent"},
{"testId":1040,"qid":24761,"description":"A person under the age of 18_____________ before being awarded a learner's license.","explanation":null,"imageName":"","correctAnswer":41795,"isBookmarked":0,"aid":"38667#41795#41806#41807","text":"(a) or (b)#(a) and (b)#(a) must complete a TLSAE course#(b) must be enrolled in or have graduated from high school or the equivalent"},
{"testId":573,"qid":24762,"description":"You may only drive during daytime hours for the first ___ months you have a learner's license.","explanation":"You may only drive during daylight hours for the first three months you have a learner's license. You may drive till 10 p.m. after three months. [From the Official Florida Driver's Handbook, Important Information for Minor Drivers]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":573,"qid":24763,"description":"A Class E driver license can only be obtained if you are at least ___ years old.","explanation":"A Class E driving license may only be obtained if you are at least 16 years old. [Class E Driver License Requirements, 10. Obtaining Your License or ID Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11360#11361","text":"15#18#21#16"},
{"testId":573,"qid":24764,"description":"A learner driver under the age of 18 must retain a learner's license for at least _______ or until he or she reaches the age of 18.","explanation":"A learner driver under the age of 18 must have a learner's license for at least one year or until he or she reaches the age of 18. [Class E Driver License Requirements, 10. Obtaining Your License or Identification Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1453#11301","text":"6 months#12 months#3 months#18 months"},
{"testId":573,"qid":24765,"description":"A learner driver under the age of 18 will not be awarded a driver license if he or she has been convicted of more than ___ traffic infractions in the previous year.","explanation":"A learner driver under the age of 18 will not be awarded a driving license if he or she has been convicted of any traffic offenses in the previous year. If adjudication is delayed, the motorist may have incurred one traffic offense. [Class E Driver License Requirements, 10. Obtaining Your License or Identification Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":12036,"isBookmarked":0,"aid":"6388#6389#6469#12036","text":"2#3#1#0"},
{"testId":573,"qid":24766,"description":"Before a driver license is issued, a learner's license holder under the age of 18 must complete at least ____ hours of driving experience.","explanation":"A learner's license holder under the age of 18 must complete numerous prerequisites before being given a driving license. A parent, guardian, or responsible adult 21 years of age or older must verify that the learner driver has completed at least 50 hours of driving experience, including 10 hours at night. [Class E Driver License Requirements, 10. Obtaining Your License or ID Card, Official Florida Driver's Handbook]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5446#6425#6477#7571","text":"10#25#30#50"},
{"testId":573,"qid":24767,"description":"Depending on the driver's age, for how many years a Florida Class E driver license is valid?","explanation":"A driver's license in Florida must be renewed every eight years, unless the motorist is 80 years old or older, in which case the license must be renewed every six years. [License Renewal, Page 10] Official Florida Driver's Handbook, Getting Your License or ID Card], [\"Driver License Renewal Requirements for Older Drivers,\" Florida Department of Highway Safety and Motor Vehicles, https://www.flhsmv.gov/driver-licenses-id-cards/florida-granddriver/driver-license-renewal-requirements-options-older-drivers/]","imageName":"","correctAnswer":41809,"isBookmarked":0,"aid":"41808#41809#41810#41811","text":"4 or 6#6 or 8#8 or 10#10 or 12"},
{"testId":1040,"qid":24767,"description":"Depending on the driver's age, for how many years a Florida Class E driver license is valid?","explanation":"A driver's license in Florida must be renewed every eight years, unless the motorist is 80 years old or older, in which case the license must be renewed every six years. [License Renewal, Page 10] Official Florida Driver's Handbook, Getting Your License or ID Card], [\"Driver License Renewal Requirements for Older Drivers,\" Florida Department of Highway Safety and Motor Vehicles, https://www.flhsmv.gov/driver-licenses-id-cards/florida-granddriver/driver-license-renewal-requirements-options-older-drivers/]","imageName":"","correctAnswer":41809,"isBookmarked":0,"aid":"41808#41809#41810#41811","text":"4 or 6#6 or 8#8 or 10#10 or 12"},
{"testId":573,"qid":24768,"description":"When will a new Florida resident be denied a Class E driver license?","explanation":null,"imageName":"","correctAnswer":40958,"isBookmarked":0,"aid":"37207#40958#41812#41813","text":"Neither (a) nor (b)#Either (a) or (b)#(a) If his or her out-of-state driver license is suspended or revoked#(b) If he or she is addicted to drugs or alcohol"},
{"testId":1040,"qid":24768,"description":"When will a new Florida resident be denied a Class E driver license?","explanation":null,"imageName":"","correctAnswer":40958,"isBookmarked":0,"aid":"37207#40958#41812#41813","text":"Neither (a) nor (b)#Either (a) or (b)#(a) If his or her out-of-state driver license is suspended or revoked#(b) If he or she is addicted to drugs or alcohol"},
{"testId":573,"qid":24769,"description":"Which of the following offenses will not result in the suspension of your license in Florida?","explanation":null,"imageName":"","correctAnswer":41816,"isBookmarked":0,"aid":"27198#41814#41815#41816","text":"Failure to pay child support#Use of tobacco if you're under 18#Failure to maintain insurance on your vehicle#Talking on a hand-held cell phone while driving"},
{"testId":573,"qid":24770,"description":"Which of the following will result in your driver's license being suspended?","explanation":"There are several driving-related violations that will result in the suspension of your driver license. These include filing a fake driver license application, failing to pay the fee or appear in court for a traffic ticket, and being convicted of a traffic infraction if the court decides that your license be suspended. [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41817#41818#41819","text":"All of the above#Submitting a fraudulent application for a driver license#Conviction for a traffic violation, if the court orders license suspension#Failing to answer a traffic citation"},
{"testId":1040,"qid":24770,"description":"Which of the following will result in your driver's license being suspended?","explanation":"There are several driving-related violations that will result in the suspension of your driver license. These include filing a fake driver license application, failing to pay the fee or appear in court for a traffic ticket, and being convicted of a traffic infraction if the court decides that your license be suspended. [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41817#41818#41819","text":"All of the above#Submitting a fraudulent application for a driver license#Conviction for a traffic violation, if the court orders license suspension#Failing to answer a traffic citation"},
{"testId":573,"qid":24771,"description":"If you commit an immoral act while driving, your license will be聽:","explanation":"If you are guilty of an immoral behavior while driving, your driver license will be revoked. [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":11436,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":1040,"qid":24771,"description":"If you commit an immoral act while driving, your license will be聽:","explanation":"If you are guilty of an immoral behavior while driving, your driver license will be revoked. [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":11436,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":573,"qid":24772,"description":"Within five years, if you are convicted of at least ____ infractions that accrue points on your driving record, your driving privileges will be revoked.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1040,"qid":24772,"description":"Within five years, if you are convicted of at least ____ infractions that accrue points on your driving record, your driving privileges will be revoked.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":573,"qid":24773,"description":"Which of the following may result in the revocation of a driver's license?","explanation":"If a driver's vision deteriorates to the extent that he or she is unable to drive safely, his or her license will be cancelled. (It should be noted that failing to stop for a halted school bus may result in a driving license suspension rather than revocation.) [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":41820,"isBookmarked":0,"aid":"27205#31380#41820#41821","text":"A heart attack#Failure to stop for a stopped school bus#Vision below minimum standards#Filing for bankruptcy"},
{"testId":1040,"qid":24773,"description":"Which of the following may result in the revocation of a driver's license?","explanation":"If a driver's vision deteriorates to the extent that he or she is unable to drive safely, his or her license will be cancelled. (It should be noted that failing to stop for a halted school bus may result in a driving license suspension rather than revocation.) [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":41820,"isBookmarked":0,"aid":"27205#31380#41820#41821","text":"A heart attack#Failure to stop for a stopped school bus#Vision below minimum standards#Filing for bankruptcy"},
{"testId":573,"qid":24774,"description":"Your license will be ______ if you obtained it using a fraudulent ID.","explanation":"Your license will be revoked if you are found guilty of acquiring a driving license by submitting fake identity (i.e., rendered null and void). [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":1040,"qid":24774,"description":"Your license will be ______ if you obtained it using a fraudulent ID.","explanation":"Your license will be revoked if you are found guilty of acquiring a driving license by submitting fake identity (i.e., rendered null and void). [Losing Your Driving Privilege, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#28796","text":"revoked#canceled#suspended#restricted"},
{"testId":573,"qid":24775,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that resulted in property damage of more than $50 but no injuries, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":24775,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that resulted in property damage of more than $50 but no injuries, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":24776,"description":"If you are caught speeding and cause an accident, ___ points will be added to your driving record.","explanation":"If you are guilty of speeding and cause an accident, you will get six points on your driving record. The maximum number of points for a single infraction in Florida is six. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":24776,"description":"If you are caught speeding and cause an accident, ___ points will be added to your driving record.","explanation":"If you are guilty of speeding and cause an accident, you will get six points on your driving record. The maximum number of points for a single infraction in Florida is six. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":24778,"description":"If a driver less than 18 years old is convicted of driving unattended during restricted hours, he or she will receive ___ points on his or her driving record.","explanation":"The hours that a motorist under the age of 18 may drive unaccompanied are limited. If a minor is determined to have violated these rules, three points will be added to his or her driving record. In Florida, three points is the fewest amount of points for any pointable traffic offence. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":24779,"description":"If you are guilty of exceeding the speed limit by more than 50 miles per hour, you will be fined _____ for your first offense.","explanation":"If you are guilty of exceeding the speed limit by more than 50 mph, you will be penalized $1,000 for the first violation and $2,500 for the second offense. In addition, four points will be deducted from your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#11986","text":"$500#$1,000#$100#$750"},
{"testId":1040,"qid":24779,"description":"If you are guilty of exceeding the speed limit by more than 50 miles per hour, you will be fined _____ for your first offense.","explanation":"If you are guilty of exceeding the speed limit by more than 50 mph, you will be penalized $1,000 for the first violation and $2,500 for the second offense. In addition, four points will be deducted from your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#11986","text":"$500#$1,000#$100#$750"},
{"testId":573,"qid":24780,"description":"If you are guilty of exceeding the speed limit by more than 50 miles per hour, you will be fined _____ for your second offense.","explanation":"If you are guilty of exceeding the speed limit by more than 50 mph, you will be penalized $1,000 for the first violation and $2,500 for the second offense. In addition, four points will be deducted from your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":11670,"isBookmarked":0,"aid":"1799#11668#11670#12428","text":"$1,000#$1,500#$2,500#$1,750"},
{"testId":1040,"qid":24780,"description":"If you are guilty of exceeding the speed limit by more than 50 miles per hour, you will be fined _____ for your second offense.","explanation":"If you are guilty of exceeding the speed limit by more than 50 mph, you will be penalized $1,000 for the first violation and $2,500 for the second offense. In addition, four points will be deducted from your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":11670,"isBookmarked":0,"aid":"1799#11668#11670#12428","text":"$1,000#$1,500#$2,500#$1,750"},
{"testId":573,"qid":24781,"description":"If you are found guilty of exceeding the speed limit by 16 mph or more, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the speed limit by 16 mph or more, four points will be added to your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":24781,"description":"If you are found guilty of exceeding the speed limit by 16 mph or more, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the speed limit by 16 mph or more, four points will be added to your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":24782,"description":"If you are found guilty of exceeding the speed limit by 15 miles per hour or less, ___  points will be added to your driving record.","explanation":"If you are found guilty of exceeding the speed limit by 15 miles per hour or less, three points will be added to your driving record. In Florida, three points is the fewest amount of points for any pointable traffic offence. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":24782,"description":"If you are found guilty of exceeding the speed limit by 15 miles per hour or less, ___  points will be added to your driving record.","explanation":"If you are found guilty of exceeding the speed limit by 15 miles per hour or less, three points will be added to your driving record. In Florida, three points is the fewest amount of points for any pointable traffic offence. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":24783,"description":"The conviction for traffic must have occurred _____________ for points to be added to your driving record.","explanation":"Even if the conviction happens out of state or in federal court, the same amount of points will be added to your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":41823,"isBookmarked":0,"aid":"41822#41823#41824#41825","text":"Only in Florida#Anywhere in the United States#In Florida or a neighboring state#In any state but not in Washington, DC"},
{"testId":1040,"qid":24783,"description":"The conviction for traffic must have occurred _____________ for points to be added to your driving record.","explanation":"Even if the conviction happens out of state or in federal court, the same amount of points will be added to your driving record. [9. Your Driving Privilege, Official Florida Driver's Handbook, Point System]","imageName":"","correctAnswer":41823,"isBookmarked":0,"aid":"41822#41823#41824#41825","text":"Only in Florida#Anywhere in the United States#In Florida or a neighboring state#In any state but not in Washington, DC"},
{"testId":573,"qid":24784,"description":"Your license will be suspended for up to______, if you accumulate 12  points within a one-year period.","explanation":"If you acquire 12 points in a 12-month period, your license will be suspended for up to 30 days. [Point System, 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1040,"qid":24784,"description":"Your license will be suspended for up to______, if you accumulate 12  points within a one-year period.","explanation":"If you acquire 12 points in a 12-month period, your license will be suspended for up to 30 days. [Point System, 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":573,"qid":24785,"description":"Your license will be suspended for up to a year if you acquire ____ points in a 36-month period.","explanation":"If you earn 24 points in a 36-month period, your license will be suspended for up to a year. [Point System, 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"8141#12040#12432#12433","text":"60#24#36#48"},
{"testId":1040,"qid":24785,"description":"Your license will be suspended for up to a year if you acquire ____ points in a 36-month period.","explanation":"If you earn 24 points in a 36-month period, your license will be suspended for up to a year. [Point System, 9. Your Driving Privilege, Official Florida Driver's Handbook]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"8141#12040#12432#12433","text":"60#24#36#48"},
{"testId":573,"qid":24787,"description":"How many points will be added to your driving record if you are found guilty of running a red light based on evidence received from a red light camera?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#5449#6389#6390","text":"None of the above#5#3#4"},
{"testId":1040,"qid":24787,"description":"How many points will be added to your driving record if you are found guilty of running a red light based on evidence received from a red light camera?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#5449#6389#6390","text":"None of the above#5#3#4"},
{"testId":573,"qid":24788,"description":"If a motorist under the age of 18 accumulates ____ points in a 12-month period, he or she will be restricted to driving for \"Business Purposes ONLY\" for one year.","explanation":"If a motorist under the age of 18 earns six points in a 12-month period, he or she will be limited to driving for \"Business Purposes ONLY\" for one year. If the driver earns more than six points during that time, the limitation will be extended by 90 days for each point over six. [Mandatory Minor Restriction, 9. Official Florida Driver's Handbook, Your Driving Privilege]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1040,"qid":24788,"description":"If a motorist under the age of 18 accumulates ____ points in a 12-month period, he or she will be restricted to driving for \"Business Purposes ONLY\" for one year.","explanation":"If a motorist under the age of 18 earns six points in a 12-month period, he or she will be limited to driving for \"Business Purposes ONLY\" for one year. If the driver earns more than six points during that time, the limitation will be extended by 90 days for each point over six. [Mandatory Minor Restriction, 9. Official Florida Driver's Handbook, Your Driving Privilege]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":573,"qid":24789,"description":"A licensed 16-year-old driver may not drive unattended _________ unless travelling to or from work.","explanation":"A licensed 16-year-old driver is not permitted to drive alone after 11:00 p.m. and 6:00 a.m. Unless he or she is on his or her way to or from work. [Minor Time Restrictions, 9. Official Florida Driver's Handbook, Your Driving Privilege]","imageName":"","correctAnswer":41827,"isBookmarked":0,"aid":"29250#29251#41826#41827","text":"between 10:00 p.m. and 6:00 a.m#between midnight and 5:00 a.m#between 11:00 p.m. and 5:00 a.m#between 11:00 p.m. and 6:00 a.m"},
{"testId":573,"qid":24790,"description":"A 17-year-old licensed driver is not permitted to drive unattended _________ unless he or she is driving to or from work.","explanation":"A licensed 17-year-old driver is not permitted to drive unattended between the hours of 1:00 a.m. and 5:00 a.m. Unless he or she is on his or her way to or from work. [Minor Time Restrictions, 9. Official Florida Driver's Handbook, Your Driving Privilege]","imageName":"","correctAnswer":41828,"isBookmarked":0,"aid":"29251#41828#41829#41830","text":"between midnight and 5:00 a.m#between 1:00 a.m. and 5:00 a.m#between 1:00 a.m. and 7:00 a.m#between midnight and 8:00 a.m"},
{"testId":573,"qid":24791,"description":"A driver under the age of 21 whose blood alcohol Concentration (BAC) is 0.02 percent or greater will have his or her license suspended for ___ months.","explanation":"A Florida citizen under the age of 21 whose BAC level is 0.02 percent or greater will have his or her license suspended for six months. [Zero Tolerance, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6389#6478#6916#11342","text":"3#12#6#9"},
{"testId":1040,"qid":24791,"description":"A driver under the age of 21 whose blood alcohol Concentration (BAC) is 0.02 percent or greater will have his or her license suspended for ___ months.","explanation":"A Florida citizen under the age of 21 whose BAC level is 0.02 percent or greater will have his or her license suspended for six months. [Zero Tolerance, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6389#6478#6916#11342","text":"3#12#6#9"},
{"testId":573,"qid":24792,"description":"Refusing a chemical test requested by law police will result in a ________ suspension of driving privileges.","explanation":"If you refuse to submit to a chemical test for alcohol or drugs when a law enforcement officer requests it, your license will be suspended for a year. If you took the test, failed it, and were convicted of DUI, your license would be suspended for six months (but you would likely face other penalties as well). [Implied Consent Law, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24048#24049#25169","text":"6 months#60 days#90 days#12 months"},
{"testId":1040,"qid":24792,"description":"Refusing a chemical test requested by law police will result in a ________ suspension of driving privileges.","explanation":"If you refuse to submit to a chemical test for alcohol or drugs when a law enforcement officer requests it, your license will be suspended for a year. If you took the test, failed it, and were convicted of DUI, your license would be suspended for six months (but you would likely face other penalties as well). [Implied Consent Law, Official Florida Driver's Handbook, 9. Your Driving Privilege]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24048#24049#25169","text":"6 months#60 days#90 days#12 months"},
{"testId":573,"qid":24793,"description":"If you are convicted of DUI for the first time, you will face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41831#41832#41833","text":"All of the above#A fine of up to $1,000#Jail for up to 6 months#License revocation for at least 180 days"},
{"testId":1040,"qid":24793,"description":"If you are convicted of DUI for the first time, you will face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41831#41832#41833","text":"All of the above#A fine of up to $1,000#Jail for up to 6 months#License revocation for at least 180 days"},
{"testId":573,"qid":24794,"description":"Your license will be suspended if you are convicted of ___ or more major offenses within a five-year period.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1040,"qid":24794,"description":"Your license will be suspended if you are convicted of ___ or more major offenses within a five-year period.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":573,"qid":24795,"description":"If convicted of DUI for the third time, you will have your license revoked for at least:","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23154#25858#26672#31873","text":"1 year#180 days#5 years#200 days"},
{"testId":574,"qid":24796,"description":"What is the categorization of a Georgia provisional license?","explanation":null,"imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":574,"qid":24797,"description":"Which classification is an unrestricted driver's license provided to a driver over the age of 18?","explanation":"Georgia Class C licenses are unrestricted licenses awarded to noncommercial drivers over the age of 18. [Georgia Drivers Manual, Section 1: General Licensing Information, License Classes]","imageName":"","correctAnswer":324,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":574,"qid":24798,"description":"For how much duration a Georgia Class C driver's license is valid?","explanation":"A Georgia Class C driver's license is good for eight years. [Georgia Drivers Manual, Section 1: General Licensing Information, License Classes]","imageName":"","correctAnswer":43222,"isBookmarked":0,"aid":"26652#27049#31351#43222","text":"Five years#Ten years#Four years#Eight years"},
{"testId":574,"qid":24799,"description":"What must you do to renew your driver's license if it expired more than two years ago?","explanation":null,"imageName":"","correctAnswer":43223,"isBookmarked":0,"aid":"27880#43223#43224#43225","text":"Pay a fee#Pass a vision screening, knowledge exam, and driving test#Pass a driving test#Pass a vision screening and driving test"},
{"testId":574,"qid":24800,"description":"Before applying for a Class CP instructional permit, you must be at least ___ years old.","explanation":"If you are at least 15 years old, you may apply for a Class CP instructional permit. [Georgia Drivers Manual, Section 2: Obtaining a License, Permit, or Identification Card, Class C Instructional Permit (CP), Current Georgia Residents]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":574,"qid":24801,"description":"A driver's license or instructional permit applicant under the age of 18 must have:","explanation":"A parent, legal guardian, or other responsible adult must provide their written consent before a minor may apply for a Georgia driver's licence or instructional permit. Current Georgia Residents, Section 2: Obtaining a License, Permit, or Identification Card, Georgia Drivers Manual, Class C Instructional Permit (CP)","imageName":"","correctAnswer":27883,"isBookmarked":0,"aid":"8615#27883#27885#43226","text":"All of the above#written consent from a parent or legal guardian#a fuel efficient vehicle#his or her own liability insurance"},
{"testId":574,"qid":24802,"description":"A motorist under the age of 18 must have an instructional permit for _________ before applying for a provisional driver's license.","explanation":"Before applying for a Class D provisional driver's license, a motorist under the age of 18 must obtain a Class CP instructional permit for one year and one day. If the driver turns 18 while still holding the instructional permit, he or she may apply for a Class C driver's license immediately. [Georgia Drivers Manual, Section 2: Obtaining a License, Permit, or Identification Card, Class D Provisional License]","imageName":"","correctAnswer":43227,"isBookmarked":0,"aid":"230#4374#43227#43228","text":"one year#six months#one year and one day#six months and one day"},
{"testId":574,"qid":24803,"description":"A driver under the age of 18 must have completed ___ hours of driving experience before applying for a provisional license.","explanation":"Before applying for a provisional licence, a motorist under the age of 18 must have completed 40 hours of driving experience including 6 hours at night. This is in addition to the six hours of driving experience as part of the driver education course or DDS-approved Parent-Teen Driving Guide that are needed of drivers under the age of 17. [Driver Education Requirements, Class D Provisional License, Section 2: Obtaining a License, Permit or Identification Card, Georgia Drivers Manual]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6354#6477#11513#12455","text":"45#30#40#35"},
{"testId":574,"qid":24804,"description":"A provisional license holder is not permitted to drive between the hours of:","explanation":"A temporary license holder is not permitted to drive between the hours of midnight and 5:00 a.m. There are no exceptions. [Conditions of a Class D Provisional License, Georgia Drivers Manual, Section 2: Obtaining a License, Permit, or Identification Card]","imageName":"","correctAnswer":11935,"isBookmarked":0,"aid":"11934#11935#12456#12457","text":"11:00 p.m. and 5:00 a.m#midnight and 5:00 a.m#10:00 p.m. and 4:00 a.m#midnight and 6:00 a.m"},
{"testId":574,"qid":24805,"description":"A motorist with a provisional license can carry _________ during the first six months.","explanation":"During the first six months of having a provisional driver's license, a motorist may only transport close family members. \"Immediate family members\" are defined as family members who live at the driver's house. [Conditions of a Class D Provisional License, Class D Provisional License, Section 2: Obtaining a License, Permit, or Identification Card, Georgia Drivers Manual]","imageName":"","correctAnswer":43229,"isBookmarked":0,"aid":"43229#43230#43231#43232","text":"only immediate family members#only parents#only siblings#both immediate and distant relatives"},
{"testId":574,"qid":24806,"description":"How many non-relatives under the age of 21 can the driver carry during the second six months of holding a provisional driver's license?","explanation":"During the second six months of having a provisional driver's license, a motorist may carry immediate family members and one person under the age of 21 who is not an immediate family member. \"Immediate family members\" are defined as family members who live at the driver's house. [Conditions of a Class D Provisional License, Class D Provisional License, Section 2: Obtaining a License, Permit, or Identification Card, Georgia Drivers Manual]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":574,"qid":24808,"description":"In the event that you are found guilty of breaking state's Move Over Law, you will be subject to a fine of up to _______.","explanation":"When passing a stopped emergency vehicle, traffic maintenance vehicle, sanitation truck, or tow truck, Georgia's Go Over Law compels cars to move into the next lane. If you are convicted of breaking this statute, you might face a $500 fine and three points on your license. [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual], [The \"Move Over Law,\" Section 5: Traffic Laws, Georgia Drivers Manual]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1041,"qid":24808,"description":"In the event that you are found guilty of breaking state's Move Over Law, you will be subject to a fine of up to _______.","explanation":"When passing a stopped emergency vehicle, traffic maintenance vehicle, sanitation truck, or tow truck, Georgia's Go Over Law compels cars to move into the next lane. If you are convicted of breaking this statute, you might face a $500 fine and three points on your license. [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual], [The \"Move Over Law,\" Section 5: Traffic Laws, Georgia Drivers Manual]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":574,"qid":24809,"description":"If you are caught speeding at 75 mph on a two-lane road, you will face a state fee of _____ in addition to the standard penalties.","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11832#12358#12395","text":"$500#$100#$200#$250"},
{"testId":1041,"qid":24809,"description":"If you are caught speeding at 75 mph on a two-lane road, you will face a state fee of _____ in addition to the standard penalties.","explanation":null,"imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11832#12358#12395","text":"$500#$100#$200#$250"},
{"testId":574,"qid":24810,"description":"If you are caught speeding at ______ on any road, you will be fined in addition to the regular amount.","explanation":null,"imageName":"","correctAnswer":5918,"isBookmarked":0,"aid":"685#3852#5918#43233","text":"70 mph#75 mph#85 mph#80 mph"},
{"testId":1041,"qid":24810,"description":"If you are caught speeding at ______ on any road, you will be fined in addition to the regular amount.","explanation":null,"imageName":"","correctAnswer":5918,"isBookmarked":0,"aid":"685#3852#5918#43233","text":"70 mph#75 mph#85 mph#80 mph"},
{"testId":574,"qid":24811,"description":"Which of the following penalties could you face if you are convicted of aggressive driving?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35315#38399#43234","text":"All of the above#A jail sentence#A stiff fine#Points added to your driving record"},
{"testId":1041,"qid":24811,"description":"Which of the following penalties could you face if you are convicted of aggressive driving?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35315#38399#43234","text":"All of the above#A jail sentence#A stiff fine#Points added to your driving record"},
{"testId":574,"qid":24812,"description":"Which of the following punishments could you face if you are guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35315#43235#43236","text":"All of the above#A jail sentence#A large fine#Required litter collection"},
{"testId":1041,"qid":24812,"description":"Which of the following punishments could you face if you are guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35315#43235#43236","text":"All of the above#A jail sentence#A large fine#Required litter collection"},
{"testId":574,"qid":24813,"description":"An applicant under the age of ___ must present documentation of enrollment in or graduation from high school or the equivalent in order to acquire a instructional permit or driver'slicense (GED or homeschooling).","explanation":"An applicant under the age of 18 must submit documentation that he or she is enrolled in or has graduated from high school or the equivalent in order to receive an instructional permit or driver's license (GED or homeschooling). [School Enrollment Requirements, Georgia Drivers Manual, Section 6: Teen Driving Laws]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":574,"qid":24814,"description":"If you are caught speeding in a construction zone, you might face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24977#25033","text":"$500#$1,000#$200#$2,000"},
{"testId":1041,"qid":24814,"description":"If you are caught speeding in a construction zone, you might face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24977#25033","text":"$500#$1,000#$200#$2,000"},
{"testId":574,"qid":24815,"description":"If you are found guilty of speeding in a work zone, you might face up to a year in jail.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23152#23153#25169#27897","text":"3 months#6 months#12 months#one month"},
{"testId":1041,"qid":24815,"description":"If you are found guilty of speeding in a work zone, you might face up to a year in jail.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23152#23153#25169#27897","text":"3 months#6 months#12 months#one month"},
{"testId":574,"qid":24816,"description":"A driver under the age of 21 who is guilty of racing on a public road will have his or her license suspended for:","explanation":null,"imageName":"","correctAnswer":23254,"isBookmarked":0,"aid":"23254#26650#26651#27897","text":"Six months#One year#Two years#one month"},
{"testId":1041,"qid":24816,"description":"A driver under the age of 21 who is guilty of racing on a public road will have his or her license suspended for:","explanation":null,"imageName":"","correctAnswer":23254,"isBookmarked":0,"aid":"23254#26650#26651#27897","text":"Six months#One year#Two years#one month"},
{"testId":574,"qid":24817,"description":"A driver less than 21 years old who is convicted of _________ will have his or her license suspended for at least six months.","explanation":"Underage drivers who are convicted of breaching traffic regulations in Georgia face harsh consequences. If a driver under the age of 21 is convicted of a serious offense such as racing, aggressive driving, reckless driving, fleeing a police officer, or leaving the scene of an accident (hit and run), his or her license will be suspended for six months for a first offense and twelve months for a second or subsequent offense. In addition, the driver may face criminal sanctions such as a fine or incarceration. [Other Than DUI Convictions, License/Permit Suspensions, Section 6: Teen Driving Laws, Georgia Drivers Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#2695#12260#43237","text":"Any of the above#aggressive driving#reckless driving#leaving the scene of an accident (hit and run)"},
{"testId":1041,"qid":24817,"description":"A driver less than 21 years old who is convicted of _________ will have his or her license suspended for at least six months.","explanation":"Underage drivers who are convicted of breaching traffic regulations in Georgia face harsh consequences. If a driver under the age of 21 is convicted of a serious offense such as racing, aggressive driving, reckless driving, fleeing a police officer, or leaving the scene of an accident (hit and run), his or her license will be suspended for six months for a first offense and twelve months for a second or subsequent offense. In addition, the driver may face criminal sanctions such as a fine or incarceration. [Other Than DUI Convictions, License/Permit Suspensions, Section 6: Teen Driving Laws, Georgia Drivers Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#2695#12260#43237","text":"Any of the above#aggressive driving#reckless driving#leaving the scene of an accident (hit and run)"},
{"testId":574,"qid":24818,"description":"If a driver under the age of 21 is convicted of __________, his or her license is NOT likely to be suspended.","explanation":null,"imageName":"","correctAnswer":43238,"isBookmarked":0,"aid":"12260#43237#43238#43239","text":"reckless driving#leaving the scene of an accident (hit and run)#speeding at 20 mph over the posted speed limit#attempting to elude a police officer"},
{"testId":1041,"qid":24818,"description":"If a driver under the age of 21 is convicted of __________, his or her license is NOT likely to be suspended.","explanation":null,"imageName":"","correctAnswer":43238,"isBookmarked":0,"aid":"12260#43237#43238#43239","text":"reckless driving#leaving the scene of an accident (hit and run)#speeding at 20 mph over the posted speed limit#attempting to elude a police officer"},
{"testId":574,"qid":24819,"description":"Drivers under the age of 18 will have their license suspended if they acquire _____ or more points on their driving record in the next 12 months.","explanation":"If a motorist under the age of 18 earns four or more points on his or her driving record in a twelve-month period, his or her license will be suspended for six months. Individual offenses, such as careless driving, might result in four or more points. Other Than DUI Convictions, License/Permit Suspensions, Section 6: Teen Driving Laws, Georgia Drivers Manual], [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual]","imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#2755#5000#5001","text":"Four#ten#Six#eight"},
{"testId":1041,"qid":24819,"description":"Drivers under the age of 18 will have their license suspended if they acquire _____ or more points on their driving record in the next 12 months.","explanation":"If a motorist under the age of 18 earns four or more points on his or her driving record in a twelve-month period, his or her license will be suspended for six months. Individual offenses, such as careless driving, might result in four or more points. Other Than DUI Convictions, License/Permit Suspensions, Section 6: Teen Driving Laws, Georgia Drivers Manual], [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual]","imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#2755#5000#5001","text":"Four#ten#Six#eight"},
{"testId":574,"qid":24820,"description":"A driver under the age of 21 will have his or her license suspended if convicted of any one violation that adds _____ or more points to his or her driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":1041,"qid":24820,"description":"A driver under the age of 21 will have his or her license suspended if convicted of any one violation that adds _____ or more points to his or her driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":574,"qid":24822,"description":"A driver under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for at least _______ for a first offense.","explanation":null,"imageName":"","correctAnswer":4374,"isBookmarked":0,"aid":"230#959#4373#4374","text":"one year#one month#three months#six months"},
{"testId":1041,"qid":24822,"description":"A driver under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for at least _______ for a first offense.","explanation":null,"imageName":"","correctAnswer":4374,"isBookmarked":0,"aid":"230#959#4373#4374","text":"one year#one month#three months#six months"},
{"testId":574,"qid":24823,"description":"If you refuse to submit to chemical testing of your blood alcohol content (BAC) when a police officer requests it, your license will be suspended for at least :","explanation":"The Implied Consent Law in Georgia compels you to submit to chemical testing if a police officer requests it to assess if you are under the influence of alcohol or drugs. Your license will be suspended for at least a year if you refuse. Furthermore, your rejection might be used against you in court. [Implied Consent, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23253#23254#26650#27897","text":"Three months#Six months#One year#one month"},
{"testId":1041,"qid":24823,"description":"If you refuse to submit to chemical testing of your blood alcohol content (BAC) when a police officer requests it, your license will be suspended for at least :","explanation":"The Implied Consent Law in Georgia compels you to submit to chemical testing if a police officer requests it to assess if you are under the influence of alcohol or drugs. Your license will be suspended for at least a year if you refuse. Furthermore, your rejection might be used against you in court. [Implied Consent, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23253#23254#26650#27897","text":"Three months#Six months#One year#one month"},
{"testId":574,"qid":24824,"description":"If a person over the age of 21 has a blood alcohol content (BAC) of _____ or above, he or she is considered to be under the influence of alcohol.","explanation":"If a person over the age of 21 has a blood alcohol content (BAC) of 0.08 percent or above, he or she is regarded to be under the influence of alcohol. This is the normal limit in practically every state in the United States. A person under the age of 21 is regarded to be under the influence in Georgia if his or her BAC is 0.02 percent or more. [Implied Consent, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":1041,"qid":24824,"description":"If a person over the age of 21 has a blood alcohol content (BAC) of _____ or above, he or she is considered to be under the influence of alcohol.","explanation":"If a person over the age of 21 has a blood alcohol content (BAC) of 0.08 percent or above, he or she is regarded to be under the influence of alcohol. This is the normal limit in practically every state in the United States. A person under the age of 21 is regarded to be under the influence in Georgia if his or her BAC is 0.02 percent or more. [Implied Consent, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":574,"qid":24825,"description":"A driver under the age of 21 who is convicted of DUI for the second time in ten years time will have his or her license suspended for a period of at least :","explanation":null,"imageName":"","correctAnswer":25031,"isBookmarked":0,"aid":"25031#25032#25169#27027","text":"18 months#24 months#12 months#36 months"},
{"testId":574,"qid":24826,"description":"After 120 days, a driver under the age of 21 whose license has been suspended for a second DUI conviction will be eligible to drive________","explanation":null,"imageName":"","correctAnswer":43241,"isBookmarked":0,"aid":"43240#43241#43242#43243","text":"under supervision by a licensed driver#with an ignition interlock device#a vehicle that weighs less than 2,000 pounds#with a special certificate from the DDS"},
{"testId":574,"qid":24827,"description":"Which of the following moving traffic offenses is the most severe?","explanation":null,"imageName":"","correctAnswer":43245,"isBookmarked":0,"aid":"26680#43244#43245#43246","text":"Running a red light#Speeding at 20 mph over the posted speed limit#Passing on a curve when the view ahead is obstructed#Disobeying a traffic officer's directions"},
{"testId":1041,"qid":24827,"description":"Which of the following moving traffic offenses is the most severe?","explanation":null,"imageName":"","correctAnswer":43245,"isBookmarked":0,"aid":"26680#43244#43245#43246","text":"Running a red light#Speeding at 20 mph over the posted speed limit#Passing on a curve when the view ahead is obstructed#Disobeying a traffic officer's directions"},
{"testId":574,"qid":24828,"description":"Georgia's safety belt legislation is a _________ statute, which means that a police officer can stop a violator without previously noticing another infraction.","explanation":"Georgia requires the use of a main safety belt. In other words, a law enforcement officer may stop and cite a lawbreaker without sighting another infraction. [Safety Belts, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"","correctAnswer":12479,"isBookmarked":0,"aid":"12479#12480#43247#43248","text":"primary#secondary#priority#major"},
{"testId":574,"qid":24829,"description":"Every child passenger under the age of _____ in Georgia is required to be restrained in an approved child safety restraint device.","explanation":"Every kid passenger under the age of eight must be secured in an adequate child safety restraint system, according to Georgia law. [Child Safety Restraints, Section 5: Traffic Laws, Georgia Drivers Manual]","imageName":"","correctAnswer":5001,"isBookmarked":0,"aid":"2755#5000#5001#5002","text":"ten#Six#eight#Five"},
{"testId":574,"qid":24830,"description":"If you are found guilty of breaching Georgia's high-occupancy vehicle (HOV) lane limits, you will be fined up to ____ for a first offense.","explanation":"High-occupancy vehicle (HOV) lanes in Georgia are limited to cars with more than one person, buses, motorcyclists (even if not carrying a passenger), and vehicles with Alternative Fuel Vehicle license plates. If you are found guilty of breaking these limits, you may be fined up to $75 for the first offense, $100 for the second offense, and $150 for the third offense. [HOV Lanes, Expressway Driving, Georgia Drivers Manual, Section 8: Safety Guidelines]","imageName":"202003051709096729.jpg","correctAnswer":12398,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":574,"qid":24831,"description":"If you are found guilty of breaking Georgia's regulations on the use of high-occupancy vehicle (HOV) lanes on a second violation, you will be fined up to .","explanation":"High-occupancy vehicle (HOV) lanes in Georgia are limited to cars with more than one person, buses, motorcyclists (even if not carrying a passenger), and vehicles with Alternative Fuel Vehicle license plates. If you are found guilty of breaking these limits, you may be fined up to $75 for the first offense, $100 for the second offense, and $150 for the third offense. [HOV Lanes, Expressway Driving, Georgia Drivers Manual, Section 8: Safety Guidelines]","imageName":"202003051709096729.jpg","correctAnswer":11832,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":574,"qid":24832,"description":"If you are found guilty of breaking Georgia's regulations on the use of high-occupancy vehicle (HOV) lanes for the fourth time, you would face which of the following penalties?","explanation":"High-occupancy vehicle (HOV) lanes in Georgia are limited to cars with more than one person, buses, motorcyclists (even if not carrying a passenger), and vehicles with Alternative Fuel Vehicle license plates. If you violate these limits for the fourth or subsequent time, you will be fined up to $150 and one point will be added to your driving record. [HOV Lanes, Expressway Driving, Georgia Drivers Manual, Section 8: Safety Guidelines]","imageName":"202003051709096729.jpg","correctAnswer":41795,"isBookmarked":0,"aid":"38667#41795#43249#43250","text":"(a) or (b)#(a) and (b)#(a) A fine of up to $150#(b) One point added to your driving record"},
{"testId":574,"qid":24833,"description":"Bicyclists are not permitted to travel in Georgia:","explanation":"Bicyclists in Georgia have the legal right to utilize any roads except limited-access routes such as interstates. You must be prepared to share the road with bicycles on any route where they are permitted to go. [Sharing the Road With Bicycles, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"","correctAnswer":43251,"isBookmarked":0,"aid":"10849#40536#43251#43252","text":"On rural roads#in roundabouts#on limited-access highways#on four-lane roadways"},
{"testId":1041,"qid":24833,"description":"Bicyclists are not permitted to travel in Georgia:","explanation":"Bicyclists in Georgia have the legal right to utilize any roads except limited-access routes such as interstates. You must be prepared to share the road with bicycles on any route where they are permitted to go. [Sharing the Road With Bicycles, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"","correctAnswer":43251,"isBookmarked":0,"aid":"10849#40536#43251#43252","text":"On rural roads#in roundabouts#on limited-access highways#on four-lane roadways"},
{"testId":574,"qid":24834,"description":"According to the Georgia Drivers Manual, if you are caught passing a stopped school bus with flashing red lights, you will face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41831#43253#43254","text":"All of the above#A fine of up to $1,000#Six points added to your driving record#License suspension if you're under 21"},
{"testId":1041,"qid":24834,"description":"According to the Georgia Drivers Manual, if you are caught passing a stopped school bus with flashing red lights, you will face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#41831#43253#43254","text":"All of the above#A fine of up to $1,000#Six points added to your driving record#License suspension if you're under 21"},
{"testId":574,"qid":24836,"description":"If it is discovered that you provided false or erroneous information on your driver's license application, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#42761","text":"revoked#canceled#suspended#disqualified"},
{"testId":1041,"qid":24836,"description":"If it is discovered that you provided false or erroneous information on your driver's license application, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11436#11437#11439#42761","text":"revoked#canceled#suspended#disqualified"},
{"testId":574,"qid":24837,"description":"If you are convicted of _____ in Georgia, your license will be suspended.","explanation":"If you are guilty of any of a number of major traffic violations in Georgia, your license will be suspended. DUI, racing or drag racing, vehicular murder, leaving the scene of an accident (hit and run), driving without insurance, and escaping a police officer in a motor vehicle are all significant charges. All of them are criminal violations with punishments that include fines and prison time. [Georgia Drivers Manual, Types of Suspensions, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12496#12497#12498","text":"Any of the above#vehicular homicide#racing#driving without insurance"},
{"testId":1041,"qid":24837,"description":"If you are convicted of _____ in Georgia, your license will be suspended.","explanation":"If you are guilty of any of a number of major traffic violations in Georgia, your license will be suspended. DUI, racing or drag racing, vehicular murder, leaving the scene of an accident (hit and run), driving without insurance, and escaping a police officer in a motor vehicle are all significant charges. All of them are criminal violations with punishments that include fines and prison time. [Georgia Drivers Manual, Types of Suspensions, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12496#12497#12498","text":"Any of the above#vehicular homicide#racing#driving without insurance"},
{"testId":574,"qid":24838,"description":"Your driver's license will probably NOT be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":43238,"isBookmarked":0,"aid":"12499#43237#43238#43255","text":"driving with a suspended license#leaving the scene of an accident (hit and run)#speeding at 20 mph over the posted speed limit#driving with a suspended or revoked registration"},
{"testId":1041,"qid":24838,"description":"Your driver's license will probably NOT be revoked if you are found guilty of the following violation:","explanation":null,"imageName":"","correctAnswer":43238,"isBookmarked":0,"aid":"12499#43237#43238#43255","text":"driving with a suspended license#leaving the scene of an accident (hit and run)#speeding at 20 mph over the posted speed limit#driving with a suspended or revoked registration"},
{"testId":574,"qid":24839,"description":"If convicted of careless driving, you might face up to_____ in prison.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23152#23153#25169#27897","text":"3 months#6 months#12 months#one month"},
{"testId":1041,"qid":24839,"description":"If convicted of careless driving, you might face up to_____ in prison.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23152#23153#25169#27897","text":"3 months#6 months#12 months#one month"},
{"testId":574,"qid":24840,"description":"If a driver under the age of 21 is convicetd of exceeding the speed limit by ___ mph, his or her license will be suspended.","explanation":null,"imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"5446#5447#11696#12040","text":"10#15#19#24"},
{"testId":1041,"qid":24840,"description":"If a driver under the age of 21 is convicetd of exceeding the speed limit by ___ mph, his or her license will be suspended.","explanation":null,"imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"5446#5447#11696#12040","text":"10#15#19#24"},
{"testId":574,"qid":24841,"description":"If you accumulate _____ demerit points in  24 months, your license will be suspended.","explanation":"If you acquire 15 or more points in 24 months, your license will be suspended. [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1041,"qid":24841,"description":"If you accumulate _____ demerit points in  24 months, your license will be suspended.","explanation":"If you acquire 15 or more points in 24 months, your license will be suspended. [The Points System, Section 10: Losing Your Driving Privileges, Georgia Drivers Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":574,"qid":24842,"description":"If you're convicted of hit-and-run with no injuries, you may be sent to jail for up to _____for a first offense.","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1274#1453","text":"6 months#12 months#24 months#3 months"},
{"testId":1041,"qid":24842,"description":"If you're convicted of hit-and-run with no injuries, you may be sent to jail for up to _____for a first offense.","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1274#1453","text":"6 months#12 months#24 months#3 months"},
{"testId":574,"qid":24843,"description":"If your license is suspended because you have too many points, your point total will :","explanation":"If your license is suspended for accumulating too many points, your point total will be lowered to zero. [The Points System, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":27925,"isBookmarked":0,"aid":"27923#27925#43256#43257","text":"continue to accumulate#return to zero#be reduced by six points#be cut in half"},
{"testId":1041,"qid":24843,"description":"If your license is suspended because you have too many points, your point total will :","explanation":"If your license is suspended for accumulating too many points, your point total will be lowered to zero. [The Points System, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":27925,"isBookmarked":0,"aid":"27923#27925#43256#43257","text":"continue to accumulate#return to zero#be reduced by six points#be cut in half"},
{"testId":574,"qid":24845,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":1041,"qid":24845,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":575,"qid":24846,"description":"When a driver operates a vehicle on Hawaii's highways, he or she gives implied agreement to:","explanation":"Any Hawaii motorist who operates a vehicle inside the territory of the state is giving implicit agreement to a breathalyser test for alcohol use. [Official Hawaii Driver's Manual, Implied Consent, The Law, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":28277,"isBookmarked":0,"aid":"8626#28277#28278#28279","text":"None of the above#a blood alcohol content (BAC) test#having his or her vehicle confiscated#interrogation"},
{"testId":1042,"qid":24846,"description":"When a driver operates a vehicle on Hawaii's highways, he or she gives implied agreement to:","explanation":"Any Hawaii motorist who operates a vehicle inside the territory of the state is giving implicit agreement to a breathalyser test for alcohol use. [Official Hawaii Driver's Manual, Implied Consent, The Law, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":28277,"isBookmarked":0,"aid":"8626#28277#28278#28279","text":"None of the above#a blood alcohol content (BAC) test#having his or her vehicle confiscated#interrogation"},
{"testId":575,"qid":24848,"description":"In the state of Hawaii, refusing a blood alcohol content (BAC) test has the same legal implications as having a BAC of:","explanation":"A motorist in Hawaii who refuses to submit to a blood alcohol content test will face the same penalties as if he or she had a BAC of 0.08 percent. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Administrative License Revocation]","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318#27699","text":"0.05%#0.08%#0.02%#0.01%"},
{"testId":1042,"qid":24848,"description":"In the state of Hawaii, refusing a blood alcohol content (BAC) test has the same legal implications as having a BAC of:","explanation":"A motorist in Hawaii who refuses to submit to a blood alcohol content test will face the same penalties as if he or she had a BAC of 0.08 percent. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Administrative License Revocation]","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#23318#27699","text":"0.05%#0.08%#0.02%#0.01%"},
{"testId":575,"qid":24849,"description":"Drivers under 21 years of age in Hawaii may not have a blood alcohol content (BAC) of _______ or higher when operating a vehicle.","explanation":"A motorist under the age of 21 in Hawaii may not operate a vehicle with a blood alcohol concentration (BAC) of 0.02 percent or higher. This is the lowest amount of alcohol that can be measured in the human body. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4652#4653","text":"0.08%#0.02%#0.00%#0.01%"},
{"testId":1042,"qid":24849,"description":"Drivers under 21 years of age in Hawaii may not have a blood alcohol content (BAC) of _______ or higher when operating a vehicle.","explanation":"A motorist under the age of 21 in Hawaii may not operate a vehicle with a blood alcohol concentration (BAC) of 0.02 percent or higher. This is the lowest amount of alcohol that can be measured in the human body. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4652#4653","text":"0.08%#0.02%#0.00%#0.01%"},
{"testId":575,"qid":24850,"description":"If a Hawaii driver refuses to submit to BAC testing, his or her driver's license will be suspended for at least:","explanation":"A Hawaii driver's license will be suspended for at least a year if he or she refuses to submit to a blood alcohol content (BAC) test. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1042,"qid":24850,"description":"If a Hawaii driver refuses to submit to BAC testing, his or her driver's license will be suspended for at least:","explanation":"A Hawaii driver's license will be suspended for at least a year if he or she refuses to submit to a blood alcohol content (BAC) test. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":575,"qid":24852,"description":"If a Hawaii driver's BAC is greater than 0.05 percent but less than 0.08 percent, he or she is legally considered:","explanation":"A Hawaii motorist is deemed legally impaired if his or her blood alcohol level (BAC) is more than 0.05 percent but less than 0.08 percent. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":28282,"isBookmarked":0,"aid":"28280#28281#28282#28283","text":"drunk#intoxicated#impaired#under the influence"},
{"testId":1042,"qid":24852,"description":"If a Hawaii driver's BAC is greater than 0.05 percent but less than 0.08 percent, he or she is legally considered:","explanation":"A Hawaii motorist is deemed legally impaired if his or her blood alcohol level (BAC) is more than 0.05 percent but less than 0.08 percent. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":28282,"isBookmarked":0,"aid":"28280#28281#28282#28283","text":"drunk#intoxicated#impaired#under the influence"},
{"testId":575,"qid":24853,"description":"If your blood alcohol level (BAC) is at least ________, you are regarded to be driving under the influence (DUI).","explanation":"You must have a blood alcohol level (BAC) of 0.08 percent or more to be convicted of driving under the influence of alcohol. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#28284#28285","text":"0.05%#0.08%#0.10%#0.15%"},
{"testId":1042,"qid":24853,"description":"If your blood alcohol level (BAC) is at least ________, you are regarded to be driving under the influence (DUI).","explanation":"You must have a blood alcohol level (BAC) of 0.08 percent or more to be convicted of driving under the influence of alcohol. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":23194,"isBookmarked":0,"aid":"23192#23194#28284#28285","text":"0.05%#0.08%#0.10%#0.15%"},
{"testId":575,"qid":24854,"description":"It is illegal for Hawaii drivers to keep open containers of alcohol:","explanation":"Any opened containers of alcohol must be kept in a cargo compartment, trunk, or truck bed in the state of Hawaii. There may be no open containers of alcohol in the area of the vehicle where motorists or passengers are sitting. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":28288,"isBookmarked":0,"aid":"23138#28286#28287#28288","text":"in the console#under the seats#on the floorboard#in any of those places"},
{"testId":1042,"qid":24854,"description":"It is illegal for Hawaii drivers to keep open containers of alcohol:","explanation":"Any opened containers of alcohol must be kept in a cargo compartment, trunk, or truck bed in the state of Hawaii. There may be no open containers of alcohol in the area of the vehicle where motorists or passengers are sitting. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":28288,"isBookmarked":0,"aid":"23138#28286#28287#28288","text":"in the console#under the seats#on the floorboard#in any of those places"},
{"testId":575,"qid":24855,"description":"Which of the following could result in a charge of driving under the influence?","explanation":"Over-the-counter cold medications, prescription drugs, and illegal drugs can all lead to a DUI conviction. [Other Drugs, Official Hawaii Driver's Manual, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9802#28289","text":"All of the above#Illegal drugs#Prescription drugs#Over-the-counter cold medications"},
{"testId":1042,"qid":24855,"description":"Which of the following could result in a charge of driving under the influence?","explanation":"Over-the-counter cold medications, prescription drugs, and illegal drugs can all lead to a DUI conviction. [Other Drugs, Official Hawaii Driver's Manual, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9802#28289","text":"All of the above#Illegal drugs#Prescription drugs#Over-the-counter cold medications"},
{"testId":575,"qid":24856,"description":"Each year, alcohol is involved in _______ percent of all fatal car accidents in the United States.","explanation":"In the United States, there are fatal car accidents every year that include alcohol in around 40% of cases. [The Problem, Official Hawaii Driver's Manual, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":28291,"isBookmarked":0,"aid":"11418#28290#28291#28292","text":"Ten#Thirty#Forty#Fifty"},
{"testId":1042,"qid":24856,"description":"Each year, alcohol is involved in _______ percent of all fatal car accidents in the United States.","explanation":"In the United States, there are fatal car accidents every year that include alcohol in around 40% of cases. [The Problem, Official Hawaii Driver's Manual, Chapter 9: Alcohol and Other Drugs]","imageName":"","correctAnswer":28291,"isBookmarked":0,"aid":"11418#28290#28291#28292","text":"Ten#Thirty#Forty#Fifty"},
{"testId":575,"qid":24857,"description":"A motorist in Hawaii who just has an instruction permit must be supervised by another licensed driver who is at least ___ years old.","explanation":"A motorist in Hawaii with just an instruction permit must be supervised by a licensed driver who is 21 or older. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":1042,"qid":24857,"description":"A motorist in Hawaii who just has an instruction permit must be supervised by another licensed driver who is at least ___ years old.","explanation":"A motorist in Hawaii with just an instruction permit must be supervised by a licensed driver who is 21 or older. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":575,"qid":24858,"description":"Before you can apply for a regular driver's license, you must have had a provisional license for at least:","explanation":"Before you may apply for a conventional driver's license, you must first possess a provisional license for at least 6 months. [Provisional License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11301","text":"30 days#1 year#6 months#18 months"},
{"testId":1042,"qid":24858,"description":"Before you can apply for a regular driver's license, you must have had a provisional license for at least:","explanation":"Before you may apply for a conventional driver's license, you must first possess a provisional license for at least 6 months. [Provisional License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11301","text":"30 days#1 year#6 months#18 months"},
{"testId":575,"qid":24859,"description":"When must you not drive on the left side of the road?","explanation":"When 1) the road has four or more lanes for moving traffic, unless otherwise directed by signs, 2) you are on a curve or approaching the top of a hill, 3) you are approaching or passing through an intersection or railroad crossing, or 4) you are approaching or passing through a bridge or tunnel and you are unable to see vehicles coming from the opposite direction, you must drive on the left half of the roadway. [Official Hawaii Driver's Manual, Chapter 5: Traffic Laws and Regulations]","imageName":"","correctAnswer":28296,"isBookmarked":0,"aid":"28293#28294#28295#28296","text":"The road has four or more lanes for moving traffic unless otherwise directed by signs#You are on a curve or approaching the top of a hill#You are approaching within 100 feet (30.5 meters) of a bridge or tunnel and you are unable to see vehicles coming from the opposite direction#Any of the above conditions are met"},
{"testId":1042,"qid":24859,"description":"When must you not drive on the left side of the road?","explanation":"When 1) the road has four or more lanes for moving traffic, unless otherwise directed by signs, 2) you are on a curve or approaching the top of a hill, 3) you are approaching or passing through an intersection or railroad crossing, or 4) you are approaching or passing through a bridge or tunnel and you are unable to see vehicles coming from the opposite direction, you must drive on the left half of the roadway. [Official Hawaii Driver's Manual, Chapter 5: Traffic Laws and Regulations]","imageName":"","correctAnswer":28296,"isBookmarked":0,"aid":"28293#28294#28295#28296","text":"The road has four or more lanes for moving traffic unless otherwise directed by signs#You are on a curve or approaching the top of a hill#You are approaching within 100 feet (30.5 meters) of a bridge or tunnel and you are unable to see vehicles coming from the opposite direction#Any of the above conditions are met"},
{"testId":575,"qid":24860,"description":"If you are under the age of 21, your driver's license will plainly show your underage status with:","explanation":"A driver's license for someone under the age of 21 is positioned vertically, whereas a driver's license for someone beyond the age of 21 is orientated horizontally. [Hawaii Licenses, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28297,"isBookmarked":0,"aid":"28297#28298#28299#28300","text":"a vertical orientation#a blue background#large, bold, green lettering#the word \"Underage.\""},
{"testId":1042,"qid":24860,"description":"If you are under the age of 21, your driver's license will plainly show your underage status with:","explanation":"A driver's license for someone under the age of 21 is positioned vertically, whereas a driver's license for someone beyond the age of 21 is orientated horizontally. [Hawaii Licenses, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28297,"isBookmarked":0,"aid":"28297#28298#28299#28300","text":"a vertical orientation#a blue background#large, bold, green lettering#the word \"Underage.\""},
{"testId":575,"qid":24861,"description":"The program in Hawaii for drivers under 21 is called the:","explanation":"The Graduated Licensing Program is intended to help young Hawaii drivers acquire experience and improve their driving abilities. [Driver's License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28301,"isBookmarked":0,"aid":"28301#28302#28303#28304","text":"Graduated Licensing Program (GLP)#Underage Driver Program (UDP)#Underage License Program (ULP)#Young Driver Program (YDP)"},
{"testId":1042,"qid":24861,"description":"The program in Hawaii for drivers under 21 is called the:","explanation":"The Graduated Licensing Program is intended to help young Hawaii drivers acquire experience and improve their driving abilities. [Driver's License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28301,"isBookmarked":0,"aid":"28301#28302#28303#28304","text":"Graduated Licensing Program (GLP)#Underage Driver Program (UDP)#Underage License Program (ULP)#Young Driver Program (YDP)"},
{"testId":575,"qid":24862,"description":"A parent or legal guardian must be the supervising passenger if a driver in Hawaii with an instruction permit is driving between the hours of:","explanation":"A HI driver with just an instruction permit must have a parent or guardian in the front passenger seat for monitoring between the hours of 11:00 p.m. and 5:00 a.m. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11934,"isBookmarked":0,"aid":"11795#11934#12457#12533","text":"10:00 p.m. and 3:00 a.m#11:00 p.m. and 5:00 a.m#midnight and 6:00 a.m#11:00 p.m. and 6:00 a.m"},
{"testId":1042,"qid":24862,"description":"A parent or legal guardian must be the supervising passenger if a driver in Hawaii with an instruction permit is driving between the hours of:","explanation":"A HI driver with just an instruction permit must have a parent or guardian in the front passenger seat for monitoring between the hours of 11:00 p.m. and 5:00 a.m. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11934,"isBookmarked":0,"aid":"11795#11934#12457#12533","text":"10:00 p.m. and 3:00 a.m#11:00 p.m. and 5:00 a.m#midnight and 6:00 a.m#11:00 p.m. and 6:00 a.m"},
{"testId":575,"qid":24863,"description":"Who in the vehicle must wear safety belts if the driver only has an instruction permit?","explanation":"When a Hawaii driver with just an instruction permit operates a vehicle, all passengers, regardless of where they are sitting, must wear safety belts. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual] NOTE: Hawaii's new seat belt legislation, introduced in 2013, mandates all passengers of a vehicle, regardless of age or status, to wear safety belts.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9808#28305#28306","text":"All of the above#The driver#The front seat passengers#The rear seat passengers"},
{"testId":1042,"qid":24863,"description":"Who in the vehicle must wear safety belts if the driver only has an instruction permit?","explanation":"When a Hawaii driver with just an instruction permit operates a vehicle, all passengers, regardless of where they are sitting, must wear safety belts. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual] NOTE: Hawaii's new seat belt legislation, introduced in 2013, mandates all passengers of a vehicle, regardless of age or status, to wear safety belts.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9808#28305#28306","text":"All of the above#The driver#The front seat passengers#The rear seat passengers"},
{"testId":575,"qid":24864,"description":"How long do you have to have an instruction permit before applying for a provisional license?","explanation":"Before applying for a provisional license, you must have a valid instruction permit for at least 180 days. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1042,"qid":24864,"description":"How long do you have to have an instruction permit before applying for a provisional license?","explanation":"Before applying for a provisional license, you must have a valid instruction permit for at least 180 days. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":575,"qid":24865,"description":"A motorist with a provisional license in Hawaii may carry _____ passengers under the age of 18.","explanation":"A HI driver who possesses a provisional driver's license is permitted to carry just one passenger who is under the age of 18. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1042,"qid":24865,"description":"A motorist with a provisional license in Hawaii may carry _____ passengers under the age of 18.","explanation":"A HI driver who possesses a provisional driver's license is permitted to carry just one passenger who is under the age of 18. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":575,"qid":24866,"description":"A driver who holds ____________ must be accompanied by a parent or guardian between 11:00 pm and 5:00 a.m.","explanation":"A provisional driver's license holder in Hawaii must be accompanied by a parent or legal guardian between the hours of 11:00 p.m. and 5:00 a.m. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":3792,"isBookmarked":0,"aid":"1396#3792#28307#28308","text":"None of the above#a provisional license#a standard driver's license#a suspended driver's license"},
{"testId":1042,"qid":24866,"description":"A driver who holds ____________ must be accompanied by a parent or guardian between 11:00 pm and 5:00 a.m.","explanation":"A provisional driver's license holder in Hawaii must be accompanied by a parent or legal guardian between the hours of 11:00 p.m. and 5:00 a.m. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":3792,"isBookmarked":0,"aid":"1396#3792#28307#28308","text":"None of the above#a provisional license#a standard driver's license#a suspended driver's license"},
{"testId":575,"qid":24867,"description":"Before you can apply for a regular driver's license, you must have had a provisional license for at least:","explanation":"You must have a provisional driver's license for at least 6 months before applying for a regular driver's license. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1272#1273","text":"30 days#60 days#6 months#12 months"},
{"testId":1042,"qid":24867,"description":"Before you can apply for a regular driver's license, you must have had a provisional license for at least:","explanation":"You must have a provisional driver's license for at least 6 months before applying for a regular driver's license. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1272#1273","text":"30 days#60 days#6 months#12 months"},
{"testId":575,"qid":24868,"description":"You must be at least ___ years old to obtain a standard driver's license.","explanation":"To receive a normal driver's license, you must be at least 17 years old. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":1042,"qid":24868,"description":"You must be at least ___ years old to obtain a standard driver's license.","explanation":"To receive a normal driver's license, you must be at least 17 years old. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":575,"qid":24869,"description":"When is Hawaii driver's license becomes invalid?","explanation":"The expiry date of a Hawaii driver's license renders it invalid. [License Renewal, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28309,"isBookmarked":0,"aid":"28309#28310#28311#28312","text":"Immediately after the expiration date#30 days after the expiration date#40 days after the expiration date#6 months after the expiration date"},
{"testId":1042,"qid":24869,"description":"When is Hawaii driver's license becomes invalid?","explanation":"The expiry date of a Hawaii driver's license renders it invalid. [License Renewal, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28309,"isBookmarked":0,"aid":"28309#28310#28311#28312","text":"Immediately after the expiration date#30 days after the expiration date#40 days after the expiration date#6 months after the expiration date"},
{"testId":575,"qid":24870,"description":"Which of the following are possible restrictions on a Hawaii driver's license?","explanation":"Corrective lenses, prosthetic aids, and the use of an automatic transmission are just a few of the many restrictions that an HI driver's license may be subject to. [Restricted License, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28313#28314#28315","text":"All of the above#Corrective lens usage#Automatic transmission only#Prosthetic aid usage"},
{"testId":1042,"qid":24870,"description":"Which of the following are possible restrictions on a Hawaii driver's license?","explanation":"Corrective lenses, prosthetic aids, and the use of an automatic transmission are just a few of the many restrictions that an HI driver's license may be subject to. [Restricted License, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28313#28314#28315","text":"All of the above#Corrective lens usage#Automatic transmission only#Prosthetic aid usage"},
{"testId":575,"qid":24871,"description":"In Hawaii, there are two sorts of traffic citations: infractions and :","explanation":"Traffic infractions and traffic offenses are the two categories of traffic tickets issued in Hawaii. [Traffic Citations, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28318,"isBookmarked":0,"aid":"8626#28316#28317#28318","text":"None of the above#a traffic violation#a traffic ticket#a traffic crime"},
{"testId":1042,"qid":24871,"description":"In Hawaii, there are two sorts of traffic citations: infractions and :","explanation":"Traffic infractions and traffic offenses are the two categories of traffic tickets issued in Hawaii. [Traffic Citations, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28318,"isBookmarked":0,"aid":"8626#28316#28317#28318","text":"None of the above#a traffic violation#a traffic ticket#a traffic crime"},
{"testId":575,"qid":24872,"description":"Which of the following is required of a Hawaii driver accused of a traffic offense?","explanation":"A motorist from Hawaii who is charged with a traffic offense must appear in court. The charge cannot be resolved via the mail or any other method acceptable for a traffic offense. [Traffic Citations, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28320,"isBookmarked":0,"aid":"203#25096#28319#28320","text":"All of the above#Driver's license revocation#A fine of $1,000#A court appearance"},
{"testId":1042,"qid":24872,"description":"Which of the following is required of a Hawaii driver accused of a traffic offense?","explanation":"A motorist from Hawaii who is charged with a traffic offense must appear in court. The charge cannot be resolved via the mail or any other method acceptable for a traffic offense. [Traffic Citations, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28320,"isBookmarked":0,"aid":"203#25096#28319#28320","text":"All of the above#Driver's license revocation#A fine of $1,000#A court appearance"},
{"testId":575,"qid":24873,"description":"Which of the following items is not permitted in vehicles driven in the state of Hawaii?","explanation":"Except for designated emergency vehicles, Hawaii vehicles are not permitted to show a red or blue light from the front or back, or to have a siren or bell. Vehicles in Hawaii may not have overly loud mufflers. [Illegal Equipment, Official Hawaii Driver's Manual, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28321#28322#28323","text":"All of the above#A red or blue light#A siren#An excessively loud muffler"},
{"testId":1042,"qid":24873,"description":"Which of the following items is not permitted in vehicles driven in the state of Hawaii?","explanation":"Except for designated emergency vehicles, Hawaii vehicles are not permitted to show a red or blue light from the front or back, or to have a siren or bell. Vehicles in Hawaii may not have overly loud mufflers. [Illegal Equipment, Official Hawaii Driver's Manual, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28321#28322#28323","text":"All of the above#A red or blue light#A siren#An excessively loud muffler"},
{"testId":575,"qid":24874,"description":"What does the acronym PMVI mean?","explanation":"At least once a year, HI cars must undergo a Periodic Motor Vehicle Inspection (PMVI). [Official Hawaii Driver's Manual, Safety Inspection of Vehicles, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":12552,"isBookmarked":0,"aid":"12552#12553#28324#28325","text":"Periodic Motor Vehicle Inspection#Problematic Motor Vehicle Inspection#Pre-Operation Motor Vehicle Inspection#Pre-Authorized Motor Vehicle Inspection"},
{"testId":1042,"qid":24874,"description":"What does the acronym PMVI mean?","explanation":"At least once a year, HI cars must undergo a Periodic Motor Vehicle Inspection (PMVI). [Official Hawaii Driver's Manual, Safety Inspection of Vehicles, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":12552,"isBookmarked":0,"aid":"12552#12553#28324#28325","text":"Periodic Motor Vehicle Inspection#Problematic Motor Vehicle Inspection#Pre-Operation Motor Vehicle Inspection#Pre-Authorized Motor Vehicle Inspection"},
{"testId":575,"qid":24875,"description":"Child safety restraint systems are required in all vehicles with children under the age of _______.","explanation":"All children under the age of four must be restrained in a child safety restraint system in the state of Hawaii. [Official Hawaii Driver's Manual, Safety Belts, Vehicle Condition and Maintenance, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1042,"qid":24875,"description":"Child safety restraint systems are required in all vehicles with children under the age of _______.","explanation":"All children under the age of four must be restrained in a child safety restraint system in the state of Hawaii. [Official Hawaii Driver's Manual, Safety Belts, Vehicle Condition and Maintenance, Chapter 2: Vehicle Equipment and Inspection]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":575,"qid":24876,"description":"In Hawaii, children between the ages of four and ______ must be strapped in the back seat of a vehicle.","explanation":"All children between the ages of four and seventeen must be strapped in the back seat of a vehicle in the state of Hawaii. [Official Hawaii Driver's Manual, Safety Belts, Vehicle Condition and Maintenance, Chapter 2 Vehicle Equipment and Inspection]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5446#6478#11374#11784","text":"10#12#17#14"},
{"testId":575,"qid":24877,"description":"You can renew the registration of your vehicle:","explanation":"As long as the transaction is completed before the deadline, you may renew your vehicle's registration by mail, online, or at a local office. [Renewal by Mail, Official Hawaii Driver's Manual, Chapter 3: Vehicle Registration, Ownership Documents, and Application for Title]","imageName":"","correctAnswer":28329,"isBookmarked":0,"aid":"28326#28327#28328#28329","text":"by mail#online#at a local office#in any of the above ways"},
{"testId":1042,"qid":24877,"description":"You can renew the registration of your vehicle:","explanation":"As long as the transaction is completed before the deadline, you may renew your vehicle's registration by mail, online, or at a local office. [Renewal by Mail, Official Hawaii Driver's Manual, Chapter 3: Vehicle Registration, Ownership Documents, and Application for Title]","imageName":"","correctAnswer":28329,"isBookmarked":0,"aid":"28326#28327#28328#28329","text":"by mail#online#at a local office#in any of the above ways"},
{"testId":575,"qid":24878,"description":"A license plate is required to be displayed on a vehicle in Hawaii:","explanation":"Vehicles driving in Hawaii must have a license plate on both the front and back. [Display of License Plates, Official Hawaii Driver's Manual, Chapter 3: Vehicle Registration, Ownership Documents, and Application for Title]","imageName":"","correctAnswer":28330,"isBookmarked":0,"aid":"28330#28331#28332#28333","text":"on both the front and the rear#on the rear only#on the front only#on neither the front nor the rear"},
{"testId":1042,"qid":24878,"description":"A license plate is required to be displayed on a vehicle in Hawaii:","explanation":"Vehicles driving in Hawaii must have a license plate on both the front and back. [Display of License Plates, Official Hawaii Driver's Manual, Chapter 3: Vehicle Registration, Ownership Documents, and Application for Title]","imageName":"","correctAnswer":28330,"isBookmarked":0,"aid":"28330#28331#28332#28333","text":"on both the front and the rear#on the rear only#on the front only#on neither the front nor the rear"},
{"testId":575,"qid":24879,"description":"A road accident that causes ______ or more in property damage must be reported to the police as soon as possible.","explanation":"Any accident that causes $3,001 or more in property damage, including death, must be reported to the police right away. Chapter 7 of the Official Hawaii Driver's Manual addresses accident procedures.","imageName":"202006141611292838.jpg","correctAnswer":12001,"isBookmarked":0,"aid":"290#1799#11832#12001","text":"$500#$1,000#$100#$3,000"},
{"testId":575,"qid":24880,"description":"What must you do if your vehicle collides with an unattended vehicle?","explanation":"If your car collides with an unattended vehicle, you must leave a thorough note including your name, address, insurance information, and a description of the accident. [Official Hawaii Driver's Manual, Chapter 7: Accident Procedure]","imageName":"","correctAnswer":28334,"isBookmarked":0,"aid":"6392#22727#28334#28335","text":"Call the police#Do none of the above#Leave a note with information on how to contact you#Stay until the driver returns"},
{"testId":1042,"qid":24880,"description":"What must you do if your vehicle collides with an unattended vehicle?","explanation":"If your car collides with an unattended vehicle, you must leave a thorough note including your name, address, insurance information, and a description of the accident. [Official Hawaii Driver's Manual, Chapter 7: Accident Procedure]","imageName":"","correctAnswer":28334,"isBookmarked":0,"aid":"6392#22727#28334#28335","text":"Call the police#Do none of the above#Leave a note with information on how to contact you#Stay until the driver returns"},
{"testId":575,"qid":24881,"description":"What is the penalty for breaking Hawaii's Motor Vehicle Safety Responsibility Act?","explanation":"Failure to comply with Hawaii's Motor Vehicle Safety Responsibility Law will result in the suspension of your license. [Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":12374,"isBookmarked":0,"aid":"12374#12377#23188#28336","text":"License suspension#License revocation#Fines#License cancellation"},
{"testId":1042,"qid":24881,"description":"What is the penalty for breaking Hawaii's Motor Vehicle Safety Responsibility Act?","explanation":"Failure to comply with Hawaii's Motor Vehicle Safety Responsibility Law will result in the suspension of your license. [Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":12374,"isBookmarked":0,"aid":"12374#12377#23188#28336","text":"License suspension#License revocation#Fines#License cancellation"},
{"testId":575,"qid":24884,"description":"You may sue or be sued for ________ or more in medical expenses.","explanation":"You have the right to sue or be sued for at least $5,000 in medical expenditures resulting from an automobile accident. [When Am I Entitled to Sue or Be Sued? Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law, Motor Vehicle Insurance Law]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"3195#3196#11658#11704","text":"$5,000#$50,000#$10,000#$7,500"},
{"testId":1042,"qid":24884,"description":"You may sue or be sued for ________ or more in medical expenses.","explanation":"You have the right to sue or be sued for at least $5,000 in medical expenditures resulting from an automobile accident. [When Am I Entitled to Sue or Be Sued? Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law, Motor Vehicle Insurance Law]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"3195#3196#11658#11704","text":"$5,000#$50,000#$10,000#$7,500"},
{"testId":575,"qid":24885,"description":"What is the maximum punishment for violating Hawaii's vehicle insurance law?","explanation":"The highest punishment for failing to comply with Hawaii's automobile insurance statute is a $5,000 fine or thirty days in jail. [What is the Punishment? Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law, Motor Vehicle Insurance Law]","imageName":"","correctAnswer":28337,"isBookmarked":0,"aid":"28337#28338#28339#28340","text":"$5,000 fine or 30 days in jail#$2,500 or 10 days in jail#$1,000 or 3 days in jail#$10,000 or 6 months in jail"},
{"testId":1042,"qid":24885,"description":"What is the maximum punishment for violating Hawaii's vehicle insurance law?","explanation":"The highest punishment for failing to comply with Hawaii's automobile insurance statute is a $5,000 fine or thirty days in jail. [What is the Punishment? Official Hawaii Driver's Manual, Chapter 8: Motor Vehicle Safety Responsibility Law, Motor Vehicle Insurance Law]","imageName":"","correctAnswer":28337,"isBookmarked":0,"aid":"28337#28338#28339#28340","text":"$5,000 fine or 30 days in jail#$2,500 or 10 days in jail#$1,000 or 3 days in jail#$10,000 or 6 months in jail"},
{"testId":575,"qid":24886,"description":"A youngster under the age of ____ may not be left unsupervised in a vehicle for more than five minutes under Hawaii law.","explanation":"Children under the age of nine are not permitted to be left unsupervised in a car for more than five minutes in Hawaii. [Official Hawaii Driver's Manual, Chapter 10: Safe Driving Techniques]","imageName":"","correctAnswer":11342,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":1042,"qid":24886,"description":"A youngster under the age of ____ may not be left unsupervised in a vehicle for more than five minutes under Hawaii law.","explanation":"Children under the age of nine are not permitted to be left unsupervised in a car for more than five minutes in Hawaii. [Official Hawaii Driver's Manual, Chapter 10: Safe Driving Techniques]","imageName":"","correctAnswer":11342,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":575,"qid":24888,"description":"To get a driver's license, drivers between the ages of ________ must have parental authorization.","explanation":"For inhabitants between the ages of 16 and 18, the state of Hawaii needs parental approval when applying for a driver's license. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":12012,"isBookmarked":0,"aid":"12012#12571#12572#12573","text":"16 and 18#15 and 17#16 and 21#18 and 21"},
{"testId":1042,"qid":24888,"description":"To get a driver's license, drivers between the ages of ________ must have parental authorization.","explanation":"For inhabitants between the ages of 16 and 18, the state of Hawaii needs parental approval when applying for a driver's license. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":12012,"isBookmarked":0,"aid":"12012#12571#12572#12573","text":"16 and 18#15 and 17#16 and 21#18 and 21"},
{"testId":575,"qid":24889,"description":"Residents of Hawaii who are ___ years of age or older are exempt from completing the Graduated Licensing Program in order to earn a standard class 3 driver's license.","explanation":"To get a conventional class 3 driver's license, Hawaii citizens 18 and older are not needed to complete the Graduated Driver's License Program. [Driver's License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":1042,"qid":24889,"description":"Residents of Hawaii who are ___ years of age or older are exempt from completing the Graduated Licensing Program in order to earn a standard class 3 driver's license.","explanation":"To get a conventional class 3 driver's license, Hawaii citizens 18 and older are not needed to complete the Graduated Driver's License Program. [Driver's License, License Types, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":575,"qid":24890,"description":"Which of the following is NOT a prerequisite for obtaining a provisional driver's license?","explanation":"Before applying for a provisional driver's license, a Hawaii resident must complete a certified driver's education course and obtain a classroom certificate and behind-the-wheel certificate. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28341#28342#28343","text":"All of the above#Completion of a certified driver's education course#A classroom certificate#A behind-the-wheel certificate"},
{"testId":1042,"qid":24890,"description":"Which of the following is NOT a prerequisite for obtaining a provisional driver's license?","explanation":"Before applying for a provisional driver's license, a Hawaii resident must complete a certified driver's education course and obtain a classroom certificate and behind-the-wheel certificate. [Driving by Minors, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#28341#28342#28343","text":"All of the above#Completion of a certified driver's education course#A classroom certificate#A behind-the-wheel certificate"},
{"testId":575,"qid":24891,"description":"Drivers in Hawaii with a class 3 standard driver's license may operate cars carrying no more than ____ passengers.","explanation":"A driver with a class 3 Hawaii driver's license may operate a vehicle that can carry up to 15 people. [Driver License Categories, Types of Licenses, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6478#11359","text":"10#15#12#18"},
{"testId":1042,"qid":24891,"description":"Drivers in Hawaii with a class 3 standard driver's license may operate cars carrying no more than ____ passengers.","explanation":"A driver with a class 3 Hawaii driver's license may operate a vehicle that can carry up to 15 people. [Driver License Categories, Types of Licenses, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6478#11359","text":"10#15#12#18"},
{"testId":575,"qid":24892,"description":"On the day of the driving test,:","explanation":"A licensed driver must accompany the applicant to the test location on the day of the exam, the vehicle to be used must be in operational condition, and no passengers will be permitted in the car during the testing. [Official Hawaii Driver's Manual, Driver Performance Test, Chapter 1: Driver Licensing]","imageName":"","correctAnswer":28347,"isBookmarked":0,"aid":"28344#28345#28346#28347","text":"the vehicle must be in working condition#no passengers may accompany the testing driver during the exam#a licensed driver must accompany the applicant to the test site#all of the above must be true"},
{"testId":1042,"qid":24892,"description":"On the day of the driving test,:","explanation":"A licensed driver must accompany the applicant to the test location on the day of the exam, the vehicle to be used must be in operational condition, and no passengers will be permitted in the car during the testing. [Official Hawaii Driver's Manual, Driver Performance Test, Chapter 1: Driver Licensing]","imageName":"","correctAnswer":28347,"isBookmarked":0,"aid":"28344#28345#28346#28347","text":"the vehicle must be in working condition#no passengers may accompany the testing driver during the exam#a licensed driver must accompany the applicant to the test site#all of the above must be true"},
{"testId":575,"qid":24893,"description":"If you do not renew your license before it expires, :","explanation":"If you do not renew your license on or before the expiry date, it will be rendered invalid. You will need to begin the process of getting an original license all over again. You may also be asked to retake the vision, writing, and driving examinations. [License Renewal, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28348,"isBookmarked":0,"aid":"26137#28348#28349#28350","text":"none of the above are correct#you will need to reapply for it#you will need to pay an additional fine#your license will be suspended for 6 months"},
{"testId":1042,"qid":24893,"description":"If you do not renew your license before it expires, :","explanation":"If you do not renew your license on or before the expiry date, it will be rendered invalid. You will need to begin the process of getting an original license all over again. You may also be asked to retake the vision, writing, and driving examinations. [License Renewal, Chapter 1: Driver Licensing, Official Hawaii Driver's Manual]","imageName":"","correctAnswer":28348,"isBookmarked":0,"aid":"26137#28348#28349#28350","text":"none of the above are correct#you will need to reapply for it#you will need to pay an additional fine#your license will be suspended for 6 months"},
{"testId":575,"qid":24894,"description":"Most of the time, you can renew your driver's licence by mail, but every _____ time, you have to do it in person.","explanation":"Hawaii driver's licences may often be renewed by mail, however every third renewal month, you must renew your licence in person. [Official Hawaii Driver's Manual, Chapter 1: Licensing of Drivers, License Renewal]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":1042,"qid":24894,"description":"Most of the time, you can renew your driver's licence by mail, but every _____ time, you have to do it in person.","explanation":"Hawaii driver's licences may often be renewed by mail, however every third renewal month, you must renew your licence in person. [Official Hawaii Driver's Manual, Chapter 1: Licensing of Drivers, License Renewal]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":575,"qid":24895,"description":"If your address changes, you must notify the county examiner of drivers within :","explanation":"If your address changes, you must notify the county examiner of drivers within 30 days of the change. [Chapter 1: Licensing of Drivers, Official Hawaii Driver's Manual] [Change of Address or Name on Driver's License]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23884#23886#24048","text":"10 days#15 days#30 days#60 days"},
{"testId":1042,"qid":24895,"description":"If your address changes, you must notify the county examiner of drivers within :","explanation":"If your address changes, you must notify the county examiner of drivers within 30 days of the change. [Chapter 1: Licensing of Drivers, Official Hawaii Driver's Manual] [Change of Address or Name on Driver's License]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23884#23886#24048","text":"10 days#15 days#30 days#60 days"},
{"testId":576,"qid":24896,"description":"Where must a traffic infraction occur for an Idaho driver to be punished?","explanation":"If an Idaho driver is convicted of a traffic violation in any state that is a member of the Driver License Compact, the penalties will be the same as if the offense occurred in Idaho. Currently, all states except Georgia, Wisconsin, Massachusetts, Michigan, and Tennessee are members. [The Driver License Compact, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28794,"isBookmarked":0,"aid":"28792#28793#28794#28795","text":"In the state of Idaho#In neighboring states#In any state that is a member of the Driver License Compact#Only in states where the driver has lived"},
{"testId":1043,"qid":24896,"description":"Where must a traffic infraction occur for an Idaho driver to be punished?","explanation":"If an Idaho driver is convicted of a traffic violation in any state that is a member of the Driver License Compact, the penalties will be the same as if the offense occurred in Idaho. Currently, all states except Georgia, Wisconsin, Massachusetts, Michigan, and Tennessee are members. [The Driver License Compact, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28794,"isBookmarked":0,"aid":"28792#28793#28794#28795","text":"In the state of Idaho#In neighboring states#In any state that is a member of the Driver License Compact#Only in states where the driver has lived"},
{"testId":576,"qid":24897,"description":"A driver's license will be _______ if they receive a traffic ticket in another state but fail to follow the rules of the ticket or pay the fee.","explanation":"If an Idaho driver fails to address traffic citations from other states, his or her license may be suspended. [The Driver License Compact, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11438#11439#28796","text":"revoked#confiscated#suspended#restricted"},
{"testId":1043,"qid":24897,"description":"A driver's license will be _______ if they receive a traffic ticket in another state but fail to follow the rules of the ticket or pay the fee.","explanation":"If an Idaho driver fails to address traffic citations from other states, his or her license may be suspended. [The Driver License Compact, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11438#11439#28796","text":"revoked#confiscated#suspended#restricted"},
{"testId":576,"qid":24898,"description":"What punishment will a driver under the age of 17 face if he or she is guilty of one moving traffic violation?","explanation":"If an Idaho driver under the age of 17 is convicted of one moving traffic violation, he or she will receive a letter in the mail warning of possible license suspension if additional convictions occur. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28797,"isBookmarked":0,"aid":"12377#28797#28798#28799","text":"License revocation#A warning letter#A call from the Transportation Department#An email message from the Transportation Department"},
{"testId":1043,"qid":24898,"description":"What punishment will a driver under the age of 17 face if he or she is guilty of one moving traffic violation?","explanation":"If an Idaho driver under the age of 17 is convicted of one moving traffic violation, he or she will receive a letter in the mail warning of possible license suspension if additional convictions occur. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28797,"isBookmarked":0,"aid":"12377#28797#28798#28799","text":"License revocation#A warning letter#A call from the Transportation Department#An email message from the Transportation Department"},
{"testId":576,"qid":24899,"description":"If a driver under the age of 17 gets guilty of two moving traffic offences, the penalty will be ______.","explanation":"If an Idaho driver under the age of 17 is convicted of two moving traffic violations, his or her license will be suspended for 30 days. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28802,"isBookmarked":0,"aid":"28800#28801#28802#28803","text":"a $500 fine#removal from extra-curricular activities#a 30-day license suspension#a 6-month license suspension"},
{"testId":1043,"qid":24899,"description":"If a driver under the age of 17 gets guilty of two moving traffic offences, the penalty will be ______.","explanation":"If an Idaho driver under the age of 17 is convicted of two moving traffic violations, his or her license will be suspended for 30 days. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28802,"isBookmarked":0,"aid":"28800#28801#28802#28803","text":"a $500 fine#removal from extra-curricular activities#a 30-day license suspension#a 6-month license suspension"},
{"testId":576,"qid":24901,"description":"if a participant in the Graduated Driver's License Program has a suspended license, he or she:","explanation":"If an Idaho driver enrolled in the Graduated Driver's License Program has had his or her license suspended, he or she will not be permitted to drive under any circumstances. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28807,"isBookmarked":0,"aid":"28804#28805#28806#28807","text":"will still be able to drive to and from school#will still be able to drive to and from work#will still be able to drive with a parent in the vehicle#will not be able to drive under any circumstances"},
{"testId":1043,"qid":24901,"description":"if a participant in the Graduated Driver's License Program has a suspended license, he or she:","explanation":"If an Idaho driver enrolled in the Graduated Driver's License Program has had his or her license suspended, he or she will not be permitted to drive under any circumstances. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28807,"isBookmarked":0,"aid":"28804#28805#28806#28807","text":"will still be able to drive to and from school#will still be able to drive to and from work#will still be able to drive with a parent in the vehicle#will not be able to drive under any circumstances"},
{"testId":576,"qid":24902,"description":"How many points is each moving traffic violation worth?","explanation":"Moving traffic offenses in Idaho are earned between one and four points. [Idaho Driver's Handbook, Suspension and Point Violation System, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":12601,"isBookmarked":0,"aid":"12601#12602#12603#12604","text":"1 to 4#2 to 6#5 to 10#5 to 15"},
{"testId":1043,"qid":24902,"description":"How many points is each moving traffic violation worth?","explanation":"Moving traffic offenses in Idaho are earned between one and four points. [Idaho Driver's Handbook, Suspension and Point Violation System, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":12601,"isBookmarked":0,"aid":"12601#12602#12603#12604","text":"1 to 4#2 to 6#5 to 10#5 to 15"},
{"testId":576,"qid":24903,"description":"If a motorist has between 8 and 11 points on their license in a 12-month-period:","explanation":"If an Idaho driver accumulates 8 to 11 points in a 12-month period, he or she will receive a letter warning of future suspension if any additional points are added. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28811,"isBookmarked":0,"aid":"28808#28809#28810#28811","text":"his or her license will be revoked#his or her license will be suspended for 6 months#his or her license will be suspended for 30 days#he or she will receive a warning letter"},
{"testId":1043,"qid":24903,"description":"If a motorist has between 8 and 11 points on their license in a 12-month-period:","explanation":"If an Idaho driver accumulates 8 to 11 points in a 12-month period, he or she will receive a letter warning of future suspension if any additional points are added. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28811,"isBookmarked":0,"aid":"28808#28809#28810#28811","text":"his or her license will be revoked#his or her license will be suspended for 6 months#his or her license will be suspended for 30 days#he or she will receive a warning letter"},
{"testId":576,"qid":24904,"description":"A driver's license will be suspended for ______ if he or she accumulates between 12 and 17 points in a 12-month-period.","explanation":"If an Idaho driver accumulates 12 to 17 points in a 12-month period, his or her license will be suspended for 30 days. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":1043,"qid":24904,"description":"A driver's license will be suspended for ______ if he or she accumulates between 12 and 17 points in a 12-month-period.","explanation":"If an Idaho driver accumulates 12 to 17 points in a 12-month period, his or her license will be suspended for 30 days. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":576,"qid":24906,"description":"An Idaho driver may eliminate ___ points from his or her driving record by successfully completing a defensive driving course at every three years.","explanation":"An Idaho motorist may eliminate three points from his or her driving record every three years by successfully completing an authorized defensive driving course. The real convictions will remain, notwithstanding the removal of the points. [Idaho Driver's Handbook, Suspension and Point Violation System, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":576,"qid":24907,"description":"In the state of Idaho, driving privileges could be taken away for which of the following crimes?","explanation":"If an Idaho driver is convicted of an alcohol charge, fleeing from police, or leaving the scene of an accident, among other offenses, his or her driving privileges may be suspended, revoked, cancelled, or disqualified. [Reasons for Suspension, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11602#23204#28812","text":"All of the above#Fleeing from police#Leaving the scene of an accident#An alcohol violation"},
{"testId":1043,"qid":24907,"description":"In the state of Idaho, driving privileges could be taken away for which of the following crimes?","explanation":"If an Idaho driver is convicted of an alcohol charge, fleeing from police, or leaving the scene of an accident, among other offenses, his or her driving privileges may be suspended, revoked, cancelled, or disqualified. [Reasons for Suspension, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11602#23204#28812","text":"All of the above#Fleeing from police#Leaving the scene of an accident#An alcohol violation"},
{"testId":576,"qid":24908,"description":"Which of the following non-driving-related offenses in Idaho will NOT result in the loss of driving privileges?","explanation":"In the state of Idaho, there are several non-driving-related offenses that may result in the suspension of driving privileges. [Idaho Driver's Handbook, Suspension Reasons, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":28813,"isBookmarked":0,"aid":"27198#28813#28814#28815","text":"Failure to pay child support#Shoplifting#Alcohol-age violation#Failure to attend school when required"},
{"testId":1043,"qid":24908,"description":"Which of the following non-driving-related offenses in Idaho will NOT result in the loss of driving privileges?","explanation":"In the state of Idaho, there are several non-driving-related offenses that may result in the suspension of driving privileges. [Idaho Driver's Handbook, Suspension Reasons, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":28813,"isBookmarked":0,"aid":"27198#28813#28814#28815","text":"Failure to pay child support#Shoplifting#Alcohol-age violation#Failure to attend school when required"},
{"testId":576,"qid":24909,"description":"A driver whose license has been suspended may be eligible for a restricted driving permit. This permit will allow the driver to drive:","explanation":"If an Idaho driver's license is suspended, he or she may be eligible for a restricted permit that allows him or her to drive to school, work, and medical appointments. [Restricted Driving Permits, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28819,"isBookmarked":0,"aid":"28816#28817#28818#28819","text":"to school#to work#to medical appointments#to all of the above places"},
{"testId":1043,"qid":24909,"description":"A driver whose license has been suspended may be eligible for a restricted driving permit. This permit will allow the driver to drive:","explanation":"If an Idaho driver's license is suspended, he or she may be eligible for a restricted permit that allows him or her to drive to school, work, and medical appointments. [Restricted Driving Permits, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28819,"isBookmarked":0,"aid":"28816#28817#28818#28819","text":"to school#to work#to medical appointments#to all of the above places"},
{"testId":576,"qid":24910,"description":"What is the maximum fine an Idaho driver can be fined to reinstate a suspended license?","explanation":"If an Idaho driver's driving privileges are suspended, he or she must pay a reinstatement fee ranging from $25 to $285. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28820,"isBookmarked":0,"aid":"28820#28821#28822#28823","text":"285#255#185#155"},
{"testId":1043,"qid":24910,"description":"What is the maximum fine an Idaho driver can be fined to reinstate a suspended license?","explanation":"If an Idaho driver's driving privileges are suspended, he or she must pay a reinstatement fee ranging from $25 to $285. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":28820,"isBookmarked":0,"aid":"28820#28821#28822#28823","text":"285#255#185#155"},
{"testId":576,"qid":24911,"description":"A driver will need to provide proof of financial responsibility for ________ if it is their first offense to break the law requiring insurance.","explanation":"If an Idaho driver violates the state's insurance mandate for the first time, he or she must keep proof of financial responsibility for one year. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1043,"qid":24911,"description":"A driver will need to provide proof of financial responsibility for ________ if it is their first offense to break the law requiring insurance.","explanation":"If an Idaho driver violates the state's insurance mandate for the first time, he or she must keep proof of financial responsibility for one year. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":576,"qid":24913,"description":"Marijuana may affect:","explanation":"Marijuana lowers response time and affects time and distance perception. [Idaho Driver's Handbook, Impaired Driving, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10667#27080#36284","text":"All of the above#Reaction time#ability to focus#sense of speed"},
{"testId":1043,"qid":24913,"description":"Marijuana may affect:","explanation":"Marijuana lowers response time and affects time and distance perception. [Idaho Driver's Handbook, Impaired Driving, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#10667#27080#36284","text":"All of the above#Reaction time#ability to focus#sense of speed"},
{"testId":576,"qid":24914,"description":"0.15 percent raise in BAC, increases driver's chances of causing accident by how many times?","explanation":"A BAC of 0.15 percent raises a driver's chances of being involved in an accident by 25 times. [What is the limit? [Idaho Driver's Handbook, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5447#5449#6425","text":"10#15#5#25"},
{"testId":1043,"qid":24914,"description":"0.15 percent raise in BAC, increases driver's chances of causing accident by how many times?","explanation":"A BAC of 0.15 percent raises a driver's chances of being involved in an accident by 25 times. [What is the limit? [Idaho Driver's Handbook, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5447#5449#6425","text":"10#15#5#25"},
{"testId":576,"qid":24915,"description":"What is the BAC level at which underage drivers are legally regarded to be driving under the influence?","explanation":"Drivers under the age of 21 in Idaho are regarded to be driving under the influence if their blood alcohol content (BAC) is at least 0.02 percent. (Note: 0.02 percent is considered the least proportion that can be measured using standard breath testing.) [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4652#4653#12625","text":"0.02%#0.00%#0.01%#0.005%"},
{"testId":1043,"qid":24915,"description":"What is the BAC level at which underage drivers are legally regarded to be driving under the influence?","explanation":"Drivers under the age of 21 in Idaho are regarded to be driving under the influence if their blood alcohol content (BAC) is at least 0.02 percent. (Note: 0.02 percent is considered the least proportion that can be measured using standard breath testing.) [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4652#4653#12625","text":"0.02%#0.00%#0.01%#0.005%"},
{"testId":576,"qid":24916,"description":"A blood alcohol concentration (BAC) of _______ or higher will result in a charge of driving under the influence in Idaho if you are 21 years of age or older (DUI).","explanation":"A motorist who is 21 or older and has a blood alcohol content (BAC) of 0.08 percent or more is deemed to be driving under the influence in Idaho. (Keep in mind that a 180-pound guy will have a BAC of 0.10 percent after drinking three 12-ounce cans of beer.) [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4253","text":"0.08%#0.02%#0.05%#0.03%"},
{"testId":1043,"qid":24916,"description":"A blood alcohol concentration (BAC) of _______ or higher will result in a charge of driving under the influence in Idaho if you are 21 years of age or older (DUI).","explanation":"A motorist who is 21 or older and has a blood alcohol content (BAC) of 0.08 percent or more is deemed to be driving under the influence in Idaho. (Keep in mind that a 180-pound guy will have a BAC of 0.10 percent after drinking three 12-ounce cans of beer.) [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4253","text":"0.08%#0.02%#0.05%#0.03%"},
{"testId":576,"qid":24917,"description":"The penalties become more severe at a blood alcohol concentration (BAC) of _______.","explanation":"If an Idaho driver is found to have a blood alcohol concentration (BAC) of 0.20 percent or higher, the penalties will be more severe. [Driving Under the Influence, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":3169,"isBookmarked":0,"aid":"3169#11768#11770#12626","text":"0.20%#0.10%#0.15%#0.25%"},
{"testId":1043,"qid":24917,"description":"The penalties become more severe at a blood alcohol concentration (BAC) of _______.","explanation":"If an Idaho driver is found to have a blood alcohol concentration (BAC) of 0.20 percent or higher, the penalties will be more severe. [Driving Under the Influence, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":3169,"isBookmarked":0,"aid":"3169#11768#11770#12626","text":"0.20%#0.10%#0.15%#0.25%"},
{"testId":576,"qid":24918,"description":"Which of the following is the maximum penalty in Idaho for a first DUI conviction?","explanation":"In Idaho, the maximum punishment for a first DUI conviction is up to six months in prison, a $1,000 fine, a license suspension of up to 180 days, and the installation of an ignition interlock device (IID) in every car that the individual drives. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12627#23115#28824","text":"All of the above#6 months in jail#A $1,000 fine#License suspension for 180 days"},
{"testId":1043,"qid":24918,"description":"Which of the following is the maximum penalty in Idaho for a first DUI conviction?","explanation":"In Idaho, the maximum punishment for a first DUI conviction is up to six months in prison, a $1,000 fine, a license suspension of up to 180 days, and the installation of an ignition interlock device (IID) in every car that the individual drives. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12627#23115#28824","text":"All of the above#6 months in jail#A $1,000 fine#License suspension for 180 days"},
{"testId":576,"qid":24919,"description":"If an underage driver is convicted of DUI, his or her license may be suspended for ___________.","explanation":"An underage Idaho driver's license may be suspended for up to a year after his or her first DUI conviction. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#24048","text":"2 years#1 year#30 days#60 days"},
{"testId":1043,"qid":24919,"description":"If an underage driver is convicted of DUI, his or her license may be suspended for ___________.","explanation":"An underage Idaho driver's license may be suspended for up to a year after his or her first DUI conviction. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#24048","text":"2 years#1 year#30 days#60 days"},
{"testId":576,"qid":24920,"description":"After three or more DUI convictions in the last _______ years, an adult driver will be sentenced to 30 days to 10 years in jail.","explanation":"If an adult motorist is convicted of DUI three times in ten years, he or she faces a mandatory prison term of 30 days to ten years. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1043,"qid":24920,"description":"After three or more DUI convictions in the last _______ years, an adult driver will be sentenced to 30 days to 10 years in jail.","explanation":"If an adult motorist is convicted of DUI three times in ten years, he or she faces a mandatory prison term of 30 days to ten years. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":576,"qid":24921,"description":"A jail sentence of at least ______ is mandatory for an adult driver guilty of two DUI offences.","explanation":"An adult motorist who receives a second DUI conviction within 10 years will be punished to at least ten days but no more than one year in prison. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#1020#12361","text":"10 days#30 days#60 days#3 days"},
{"testId":1043,"qid":24921,"description":"A jail sentence of at least ______ is mandatory for an adult driver guilty of two DUI offences.","explanation":"An adult motorist who receives a second DUI conviction within 10 years will be punished to at least ten days but no more than one year in prison. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#1020#12361","text":"10 days#30 days#60 days#3 days"},
{"testId":576,"qid":24922,"description":"If a driver under the age of 21 is convicted of a second DUI, his or her driver's license will be suspended for:","explanation":"If a minor is convicted of two DUI offenses in Idaho, his or her license will be suspended for two years. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#24048#25856","text":"2 years#6 months#60 days#360 days"},
{"testId":1043,"qid":24922,"description":"If a driver under the age of 21 is convicted of a second DUI, his or her driver's license will be suspended for:","explanation":"If a minor is convicted of two DUI offenses in Idaho, his or her license will be suspended for two years. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#24048#25856","text":"2 years#6 months#60 days#360 days"},
{"testId":576,"qid":24923,"description":"What is the maximum fine in Idaho for a second DUI conviction?","explanation":"The maximum penalties in Idaho for a second DUI conviction within 10 years is $2,000. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11668#11669","text":"$500#$1,000#$1,500#$2,000"},
{"testId":1043,"qid":24923,"description":"What is the maximum fine in Idaho for a second DUI conviction?","explanation":"The maximum penalties in Idaho for a second DUI conviction within 10 years is $2,000. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11668#11669","text":"$500#$1,000#$1,500#$2,000"},
{"testId":576,"qid":24924,"description":"If a driver under the age of 21 is convicted of three or more DUI convictions, he or she must serve at least ______ in prison and pay a fine of up to _____.","explanation":"If an underage Idaho driver is convicted of three or more DUI offenses, he or she must serve at least ten days in prison and pay a fine of up to $2,000. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":28825,"isBookmarked":0,"aid":"28825#28826#28827#28828","text":"10 days; $2,000#10 days; $5,000#3 days; $2,000#3 days; $5,000"},
{"testId":1043,"qid":24924,"description":"If a driver under the age of 21 is convicted of three or more DUI convictions, he or she must serve at least ______ in prison and pay a fine of up to _____.","explanation":"If an underage Idaho driver is convicted of three or more DUI offenses, he or she must serve at least ten days in prison and pay a fine of up to $2,000. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":28825,"isBookmarked":0,"aid":"28825#28826#28827#28828","text":"10 days; $2,000#10 days; $5,000#3 days; $2,000#3 days; $5,000"},
{"testId":576,"qid":24925,"description":"How many or more DUI convictions within ten years constitute a felony ?","explanation":"The third and subsequent DUI offenses are felonies. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":1043,"qid":24925,"description":"How many or more DUI convictions within ten years constitute a felony ?","explanation":"The third and subsequent DUI offenses are felonies. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":576,"qid":24927,"description":"If a driver refuses to take a blood alcohol concentration (BAC) test at the request of a law enforcement officer, which of the following panelties he or she may face?","explanation":null,"imageName":"","correctAnswer":28831,"isBookmarked":0,"aid":"28829#28830#28831#28832","text":"A) he or she will be subject to a civil penalty of $250#B) he or she will be subject to a driver鈥檚 license suspension#Both a) and b) will happen#Neither a) nor b) will happen"},
{"testId":1043,"qid":24927,"description":"If a driver refuses to take a blood alcohol concentration (BAC) test at the request of a law enforcement officer, which of the following panelties he or she may face?","explanation":null,"imageName":"","correctAnswer":28831,"isBookmarked":0,"aid":"28829#28830#28831#28832","text":"A) he or she will be subject to a civil penalty of $250#B) he or she will be subject to a driver鈥檚 license suspension#Both a) and b) will happen#Neither a) nor b) will happen"},
{"testId":576,"qid":24928,"description":"In the state of Idaho, your license will be suspended for _______ if you are convicted of refusing to submit to a BAC or drug test for the first time.","explanation":"Your driver's license will be suspended for one year after your first conviction for refusing to submit to BAC or drug testing. [Idaho Driver's Handbook, Test Refusal For Alcohol, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":1043,"qid":24928,"description":"In the state of Idaho, your license will be suspended for _______ if you are convicted of refusing to submit to a BAC or drug test for the first time.","explanation":"Your driver's license will be suspended for one year after your first conviction for refusing to submit to BAC or drug testing. [Idaho Driver's Handbook, Test Refusal For Alcohol, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":576,"qid":24929,"description":"If you are convicted of the second offense of refusing to submit to testing in the state of Idaho, your driver's license will be suspended for :","explanation":"Your driver's license will be suspended for two years if you are convicted of the second offense of refusing to submit to testing. [Idaho Driver's Handbook, Test Refusal For Alcohol, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":1043,"qid":24929,"description":"If you are convicted of the second offense of refusing to submit to testing in the state of Idaho, your driver's license will be suspended for :","explanation":"Your driver's license will be suspended for two years if you are convicted of the second offense of refusing to submit to testing. [Idaho Driver's Handbook, Test Refusal For Alcohol, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":576,"qid":24930,"description":"If a driver is issued a notice of license suspension, the suspension term will start___ days after the date of receipt of the notice.","explanation":"If a motorist gets a license suspension notice, the suspension term begins 30 days after receiving the notification. [Idaho Driver's Handbook, Administrative License Suspension, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6388#6389#6477","text":"10#2#3#30"},
{"testId":1043,"qid":24930,"description":"If a driver is issued a notice of license suspension, the suspension term will start___ days after the date of receipt of the notice.","explanation":"If a motorist gets a license suspension notice, the suspension term begins 30 days after receiving the notification. [Idaho Driver's Handbook, Administrative License Suspension, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6388#6389#6477","text":"10#2#3#30"},
{"testId":576,"qid":24931,"description":"Which of the following might lead to a conviction for DUI in the state of Idaho?","explanation":"A DUI conviction in Idaho may arise from the use of illicit substances, prescription medicines, or over-the-counter pharmaceuticals, in addition to alcohol. [Idaho Driver's Handbook, Drugs and Driving, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#9802#9803#25099","text":"Any of the above#Prescription drugs#Over-the-counter medications#Marijuana"},
{"testId":1043,"qid":24931,"description":"Which of the following might lead to a conviction for DUI in the state of Idaho?","explanation":"A DUI conviction in Idaho may arise from the use of illicit substances, prescription medicines, or over-the-counter pharmaceuticals, in addition to alcohol. [Idaho Driver's Handbook, Drugs and Driving, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#9802#9803#25099","text":"Any of the above#Prescription drugs#Over-the-counter medications#Marijuana"},
{"testId":576,"qid":24932,"description":"The minimum charge for reinstatement is:","explanation":"Depending on the circumstances of the suspension, you will be required to pay a reinstatement charge ranging from $25.00 to $285.00. [How to Restore Your Driving Privileges, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24977#24993#24994#35556","text":"$200#$25#$50#$285"},
{"testId":1043,"qid":24932,"description":"The minimum charge for reinstatement is:","explanation":"Depending on the circumstances of the suspension, you will be required to pay a reinstatement charge ranging from $25.00 to $285.00. [How to Restore Your Driving Privileges, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24977#24993#24994#35556","text":"$200#$25#$50#$285"},
{"testId":576,"qid":24934,"description":"A standard, non-commercial driver's license is what type of license?","explanation":"A class D license is a conventional, non-commercial driver's license, while class A, B, and C licenses are Commercial Driver's Licenses (CDLs). To lawfully ride a motorbike on public highways, your license (in any of these four classes) must also have a class M endorsement.","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":1043,"qid":24934,"description":"A standard, non-commercial driver's license is what type of license?","explanation":"A class D license is a conventional, non-commercial driver's license, while class A, B, and C licenses are Commercial Driver's Licenses (CDLs). To lawfully ride a motorbike on public highways, your license (in any of these four classes) must also have a class M endorsement.","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":576,"qid":24935,"description":"For those 17 1/2 years old and older, an instruction permit is valid for _____ days.","explanation":"An Idaho instruction permit allows a driver to practise his or her driving skills under the supervision of an adult for 180 days for people 17 1/2 years of age and older. [Types of Credentials, One Driver, One License, One Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#12168","text":"90#30#180#360"},
{"testId":576,"qid":24936,"description":"If you are at least __ years old, you are not required to complete an approved driver's training program in order to obtain an instruction permit.","explanation":"You may receive an instruction permit if you are at least 17 years old and have not completed an authorized driver's training program. [Class D Instruction Permit (IP), Credential Types, Single Driver, Single License, Single Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":576,"qid":24937,"description":"A driver training program must include at least ___ hours of classroom instruction.","explanation":"At least 30 hours of classroom instruction are included in an Idaho driver training programme that has been authorised. [Idaho Driver's Handbook, One Driver, One License, One Record, Driver Training (DT)]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6477#7571#8141#11513","text":"30#50#60#40"},
{"testId":576,"qid":24938,"description":"A driver training program must contain at least ____ hours of behind-the-wheel time with a driver training instructor.","explanation":"A minimum of 6 hours of supervised driving with a driver education teacher are part of an Idaho driver education programme that has been authorised. [Idaho Driver's Handbook, One Driver, One License, One Record, Driver Training (DT)]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#5449#6916#7559","text":"10#5#6#7"},
{"testId":576,"qid":24939,"description":"School districts provide driver training classes to all inhabitants between the ages of:","explanation":"Idaho school districts provide driver training classes to all citizens between the ages of 14 1/2 and 21. [Driver Training (DT), Idaho Driver's Handbook, One Driver, One License, One Record]","imageName":"","correctAnswer":28835,"isBookmarked":0,"aid":"28834#28835#28836#28837","text":"15 and 19#14 1/2 and 21#16 and 21#15 and 17"},
{"testId":576,"qid":24940,"description":"A driver with a Supervised Instruction Permit issued under the GDL program must be violation-free for ____ months or the permit will be revoked.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":576,"qid":24942,"description":"If a learner's permit holder gets convicted of a traffic infraction within six months,:","explanation":null,"imageName":"","correctAnswer":28841,"isBookmarked":0,"aid":"28838#28839#28840#28841","text":"the permit will be canceled#he or she will have to reapply for a new permit#the six-month period will restart#all of the above will take place"},
{"testId":576,"qid":24943,"description":"A driver under the age of 17 may transport no more than ____ passengers under the age of 17 who are not family relatives.","explanation":"An Idaho driver under the age of 17 may carry no more than one passenger under the age of 17 who is not connected to the driver by blood, marriage, or adoption. [Types of Credentials, One Driver, One License, One Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":576,"qid":24944,"description":"An underage driver's license will be suspended for ____ days if he or she violates any age restriction on the people he or she can transport.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":576,"qid":24945,"description":"While applying for an Idaho instruction permit or Idaho driver's license if you are under the age of ____, you must have a parent or legal guardian sign consent and acknowledge legal responsibility for your activities as a driver.","explanation":"If you are under the age of 18, you must have a parent or legal guardian sign permission and bear legal responsibility for your activities as a driver while applying for an Idaho instruction permit or Idaho driver's license. [Liability Signer (if under 18); Single Driver, Single License, Single Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":1043,"qid":24945,"description":"While applying for an Idaho instruction permit or Idaho driver's license if you are under the age of ____, you must have a parent or legal guardian sign consent and acknowledge legal responsibility for your activities as a driver.","explanation":"If you are under the age of 18, you must have a parent or legal guardian sign permission and bear legal responsibility for your activities as a driver while applying for an Idaho instruction permit or Idaho driver's license. [Liability Signer (if under 18); Single Driver, Single License, Single Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":577,"qid":24946,"description":"If you're 15 to 17 and enrolled in driver's ed or will start within __ days, you can get an instruction permit.","explanation":"If you are between the ages of 15 and 17, and are currently enrolled in or will begin a driver education class within the next 30 days, you may be eligible for an instruction permit.","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":577,"qid":24947,"description":"For how much duration is an instruction permit valid for an applicant whose age is 16 or 17?","explanation":"An Illinois instruction permit is valid for two years if the applicant is age 16 or 17.","imageName":"","correctAnswer":1274,"isBookmarked":0,"aid":"1272#1273#1274#11301","text":"6 months#12 months#24 months#18 months"},
{"testId":577,"qid":24948,"description":"Before applying for a standard driver's license, 16-year-old drivers must have a valid instruction permit for_______","explanation":"After having an instruction permit for 9 months, a 16-year-old driver is eligible for a conventional driver's license.","imageName":"","correctAnswer":1454,"isBookmarked":0,"aid":"742#1021#1272#1454","text":"30 days#1 year#6 months#9 months"},
{"testId":577,"qid":24949,"description":"What is the miminum age for an Illinois citizen apply for an instruction permit without first taking a driver education course?","explanation":"A citizen of Illinois may apply for an instruction permit at the age of 17 years and 3 months without having taken a driver education course.","imageName":"","correctAnswer":29241,"isBookmarked":0,"aid":"3636#29239#29240#29241","text":"16 years#16 years and 6 months#17 years and 6 months#17 years and 3 months"},
{"testId":577,"qid":24950,"description":"Which of the following conditions must a supervising passenger of a driver with an instruction permit meet?","explanation":"The supervising passenger for a learner driver must be at least 21 years old, have a valid driver's license for the learner driver's vehicle, and have at least one year of driving experience.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29242#29243#29244","text":"All of the above#21 years of age or older#Licensed to drive the vehicle#At least one year of driving experience"},
{"testId":577,"qid":24951,"description":"How much hours of driving experience a 16-year old must have to obtain the driver's license?","explanation":"A 16-year-old driver must have practiced driving for at least 50 hours, 10 of which must have been at night, in order to receive a driver's license.","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#6477#7571#11513","text":"20#30#50#40"},
{"testId":577,"qid":24953,"description":"A 15-year-old motorist is not permitted to drive on weeknights:","explanation":"Drivers under the age of 15 are not permitted to drive after 10:00 p.m. and 6:00 a.m. throughout the weeknights","imageName":"","correctAnswer":29250,"isBookmarked":0,"aid":"29249#29250#29251#29252","text":"between 10:00 p.m. and 4:00 a.m#between 10:00 p.m. and 6:00 a.m#between midnight and 5:00 a.m#between midnight and 7:00 a.m"},
{"testId":577,"qid":24954,"description":"Except in an emergency, drivers under the age of ______ are not allowed to use their cell phones while driving.","explanation":"Except in an emergency, drivers under the age of 19 are not permitted to use a portable or hands-free mobile phone.","imageName":"","correctAnswer":11696,"isBookmarked":0,"aid":"5447#11359#11361#11696","text":"15#18#16#19"},
{"testId":1044,"qid":24954,"description":"Except in an emergency, drivers under the age of ______ are not allowed to use their cell phones while driving.","explanation":"Except in an emergency, drivers under the age of 19 are not permitted to use a portable or hands-free mobile phone.","imageName":"","correctAnswer":11696,"isBookmarked":0,"aid":"5447#11359#11361#11696","text":"15#18#16#19"},
{"testId":577,"qid":24955,"description":"A 16- or 17-year-old driver's license candidate must have:","explanation":"Drivers aged 16 or 17 must have the written agreement of a parent or guardian before applying for a driver's license. The parent or guardian has the right to revoke his or her approval at any moment, rendering the young driver's license illegal.","imageName":"","correctAnswer":29254,"isBookmarked":0,"aid":"8626#24968#29253#29254","text":"None of the above#a new car#health insurance#written consent of a parent or guardian"},
{"testId":577,"qid":24957,"description":"A driver under the age of 21 who is found guilty of an alcohol-related violation will have his or her license suspended for at least:","explanation":"If a motorist under the age of 21 is convicted of buying, having, or ingesting alcohol, his or her license will be revoked for at least six months, regardless of whether the driver was driving at the time of the incident.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":1044,"qid":24957,"description":"A driver under the age of 21 who is found guilty of an alcohol-related violation will have his or her license suspended for at least:","explanation":"If a motorist under the age of 21 is convicted of buying, having, or ingesting alcohol, his or her license will be revoked for at least six months, regardless of whether the driver was driving at the time of the incident.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":577,"qid":24959,"description":"If a driver under the age of 18 is charged with a crime as a result of a crash that causes significant injury or death to a passenger, his or her driver's license:","explanation":"If a driver under the age of 18 is charged with a crime as a consequence of a collision that causes significant damage or death to a passenger, his or her license may be refused issuance or renewal. These punishments do not need the presence of alcohol.","imageName":"","correctAnswer":29255,"isBookmarked":0,"aid":"29255#29256#29257#29258","text":"may be denied issuance or renewal#may be suspended for 30 days#may be confiscated#may be suspended for 2 years"},
{"testId":1044,"qid":24959,"description":"If a driver under the age of 18 is charged with a crime as a result of a crash that causes significant injury or death to a passenger, his or her driver's license:","explanation":"If a driver under the age of 18 is charged with a crime as a consequence of a collision that causes significant damage or death to a passenger, his or her license may be refused issuance or renewal. These punishments do not need the presence of alcohol.","imageName":"","correctAnswer":29255,"isBookmarked":0,"aid":"29255#29256#29257#29258","text":"may be denied issuance or renewal#may be suspended for 30 days#may be confiscated#may be suspended for 2 years"},
{"testId":577,"qid":24960,"description":"Which of the following people in a vehicle are not required by Illinois law to wear a seat belt?聽:","explanation":"Seat belts are required under Illinois law for all passengers aged 8 and above in a vehicle. Passengers under the age of eight must use a child restraint.","imageName":"","correctAnswer":29261,"isBookmarked":0,"aid":"25185#29259#29260#29261","text":"The driver only#The driver and front seat passengers#The driver and back seat passengers#Everyone ages 8 and older in the vehicle"},
{"testId":577,"qid":24961,"description":"What is the minimum amount of property damage coverage that Illinois's vehicle insurance law requires?","explanation":"All automobiles operated in Illinois must be insured by an automobile insurance policy that provides at least $20,000 in property damage coverage.","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12047","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":577,"qid":24962,"description":"Every vehicle driven in Illinois must be covered by an insurance policy that provides at least _______ of coverage for the injury or death of more than one person in the event of a crash.","explanation":"Every vehicle operated in Illinois must be insured by an automobile insurance policy that provides at least $50,000 in coverage for multiple severe injuries or fatalities in an accident.","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12048#12195","text":"$50,000#$30,000#$25,000#$35,000"},
{"testId":577,"qid":24963,"description":"Which of the following may occur if you do not have the required automobile insurance coverage in Illinois?","explanation":"If you do not have the appropriate insurance coverage, you will be fined and your driver's license and vehicle registration will be stopped until you present evidence of insurance.","imageName":"","correctAnswer":29265,"isBookmarked":0,"aid":"29262#29263#29264#29265","text":"You will need to pay a fine#Your driver's license will be suspended#Your vehicle's registration will be suspended#All of the above will take place"},
{"testId":577,"qid":24964,"description":"Which of the following could result in a DUI conviction?","explanation":"In Illinois, a DUI conviction may also stem from the use of other drugs. Drugs that are illegal, certain prescription medications, and some over-the-counter medicines may potentially impair your capacity to drive safely. You may check the label or ask your pharmacist or doctor if you're unclear if a specific medicine can have that effect by reading the label.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8071#17526#29266","text":"All of the above#Alcohol#Some cold medicines#Some prescription drugs"},
{"testId":1044,"qid":24964,"description":"Which of the following could result in a DUI conviction?","explanation":"In Illinois, a DUI conviction may also stem from the use of other drugs. Drugs that are illegal, certain prescription medications, and some over-the-counter medicines may potentially impair your capacity to drive safely. You may check the label or ask your pharmacist or doctor if you're unclear if a specific medicine can have that effect by reading the label.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8071#17526#29266","text":"All of the above#Alcohol#Some cold medicines#Some prescription drugs"},
{"testId":577,"qid":24965,"description":"A DUI offense in Illinois is categorised as:","explanation":"DUI is considered a violent felony in Illinois.","imageName":"","correctAnswer":29268,"isBookmarked":0,"aid":"8626#27181#29267#29268","text":"None of the above#a felony#a misdemeanor#a violent crime"},
{"testId":1044,"qid":24965,"description":"A DUI offense in Illinois is categorised as:","explanation":"DUI is considered a violent felony in Illinois.","imageName":"","correctAnswer":29268,"isBookmarked":0,"aid":"8626#27181#29267#29268","text":"None of the above#a felony#a misdemeanor#a violent crime"},
{"testId":577,"qid":24966,"description":"For what duration will a DUI conviction remain on your driving record?","explanation":"A DUI conviction is permanent on an Illinois driver's record.","imageName":"","correctAnswer":29269,"isBookmarked":0,"aid":"23290#23291#29269#29270","text":"One year#Five years#Permanently#Until the sentence is completed"},
{"testId":1044,"qid":24966,"description":"For what duration will a DUI conviction remain on your driving record?","explanation":"A DUI conviction is permanent on an Illinois driver's record.","imageName":"","correctAnswer":29269,"isBookmarked":0,"aid":"23290#23291#29269#29270","text":"One year#Five years#Permanently#Until the sentence is completed"},
{"testId":577,"qid":24967,"description":"When you drive a vehicle on an IL road, you automatically agree to:","explanation":"When you drive on an Illinois highway, you provide implicit agreement to a law enforcement officer to test you for the presence of a prohibited drug.","imageName":"","correctAnswer":29271,"isBookmarked":0,"aid":"8615#29271#29272#29273","text":"All of the above#blood, breath, or urine testing#a search of your home#investigation of your medical records"},
{"testId":1044,"qid":24967,"description":"When you drive a vehicle on an IL road, you automatically agree to:","explanation":"When you drive on an Illinois highway, you provide implicit agreement to a law enforcement officer to test you for the presence of a prohibited drug.","imageName":"","correctAnswer":29271,"isBookmarked":0,"aid":"8615#29271#29272#29273","text":"All of the above#blood, breath, or urine testing#a search of your home#investigation of your medical records"},
{"testId":577,"qid":24968,"description":"If a driver is convicted for driving with a BAC of 0.08 percent or above, his or her license will be suspended for:","explanation":"A driver's license will be banned for at least 6 months if he or she is apprehended for driving with a BAC of 0.08 percent or more.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1044,"qid":24968,"description":"If a driver is convicted for driving with a BAC of 0.08 percent or above, his or her license will be suspended for:","explanation":"A driver's license will be banned for at least 6 months if he or she is apprehended for driving with a BAC of 0.08 percent or more.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":577,"qid":24972,"description":"When you are arrested for DUI, the officer will take your driver's license and give you a temporary receipt that will allow you to continue driving for _______ until the suspension period begins.","explanation":"When you are pulled over for a DUI, the policeman will take your licence and provide you a temporary receipt that will let you drive for 45 days before the suspension period starts. On the 46th day, the suspension period will start. This will allow you some extra time to plan your transportation before the suspension period starts.","imageName":"","correctAnswer":11553,"isBookmarked":0,"aid":"11553#12291#12685#12686","text":"45 days#4 months#5 days#5 months"},
{"testId":577,"qid":24973,"description":"Your license will be ________ for at least a year if you refuse to submit to chemical testing after an accident that resulted in bodily harm or death.","explanation":"If you refuse to submit to chemical testing after a collision that caused bodily injury or death, your license will be suspended for at least a year. Revocation is more significant than suspension in that you must reapply for a license and only when you are determined to be eligible.","imageName":"","correctAnswer":11281,"isBookmarked":0,"aid":"11281#11284#27202#29274","text":"revoked#suspended#canceled#restricted"},
{"testId":1044,"qid":24973,"description":"Your license will be ________ for at least a year if you refuse to submit to chemical testing after an accident that resulted in bodily harm or death.","explanation":"If you refuse to submit to chemical testing after a collision that caused bodily injury or death, your license will be suspended for at least a year. Revocation is more significant than suspension in that you must reapply for a license and only when you are determined to be eligible.","imageName":"","correctAnswer":11281,"isBookmarked":0,"aid":"11281#11284#27202#29274","text":"revoked#suspended#canceled#restricted"},
{"testId":577,"qid":24975,"description":"Which of the following DUI convictions carries the harshest penalties?","explanation":"If a motorist is convicted of DUI with a BAC of at least 0.16 percent or when carrying a kid under the age of 16, he or she faces extra prison time, additional fines, and community service.","imageName":"","correctAnswer":29277,"isBookmarked":0,"aid":"24452#29275#29276#29277","text":"Neither a) nor b)#A) When a child under the age of 16 is in the vehicle#B) With a blood alcohol concentration (BAC) of 0.16% or more#Either a) or b)"},
{"testId":1044,"qid":24975,"description":"Which of the following DUI convictions carries the harshest penalties?","explanation":"If a motorist is convicted of DUI with a BAC of at least 0.16 percent or when carrying a kid under the age of 16, he or she faces extra prison time, additional fines, and community service.","imageName":"","correctAnswer":29277,"isBookmarked":0,"aid":"24452#29275#29276#29277","text":"Neither a) nor b)#A) When a child under the age of 16 is in the vehicle#B) With a blood alcohol concentration (BAC) of 0.16% or more#Either a) or b)"},
{"testId":577,"qid":24976,"description":"Which of the following must you do if you have been convicted of a DUI conviction before your license can be reinstated?","explanation":"Before your driving privileges may be reinstated if you have been convicted of a DUI, you must submit Financial Responsibility Insurance for three years, undergo alcohol/drug assessment, complete a drug or alcohol education program, pay a reinstatement fee, and/or fulfill other conditions.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#29278#29279#29280","text":"Do all of the above#File Financial Responsibility Insurance for 3 years#Undergo alcohol/drug evaluation#Complete an alcohol/drug education program"},
{"testId":1044,"qid":24976,"description":"Which of the following must you do if you have been convicted of a DUI conviction before your license can be reinstated?","explanation":"Before your driving privileges may be reinstated if you have been convicted of a DUI, you must submit Financial Responsibility Insurance for three years, undergo alcohol/drug assessment, complete a drug or alcohol education program, pay a reinstatement fee, and/or fulfill other conditions.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#29278#29279#29280","text":"Do all of the above#File Financial Responsibility Insurance for 3 years#Undergo alcohol/drug evaluation#Complete an alcohol/drug education program"},
{"testId":577,"qid":24977,"description":"If you are found guilty of DUI in another state,:","explanation":"If you are convicted of a DUI in another state, you will face the same penalties as if the infraction happened in Illinois.","imageName":"","correctAnswer":29281,"isBookmarked":0,"aid":"29281#29282#29283#29284","text":"you will be penalized as if the violation had happened in IL#the conviction will not appear on your IL driving record#you will not be allowed to drive in that state#it will be noted on your IL driver's license"},
{"testId":1044,"qid":24977,"description":"If you are found guilty of DUI in another state,:","explanation":"If you are convicted of a DUI in another state, you will face the same penalties as if the infraction happened in Illinois.","imageName":"","correctAnswer":29281,"isBookmarked":0,"aid":"29281#29282#29283#29284","text":"you will be penalized as if the violation had happened in IL#the conviction will not appear on your IL driving record#you will not be allowed to drive in that state#it will be noted on your IL driver's license"},
{"testId":577,"qid":24978,"description":"First-time DUI offenders with suspended licenses may use which of the following to operate a vehicle?","explanation":"If you are a first-time DUI offender with a suspended license, you may request the installation of a Breath Alcohol Ignition Interlock Device (BAIID). A BAIID uses a breath test to determine your driver's blood alcohol content (BAC). If your BAC exceeds a certain threshold, the device will prohibit your vehicle's engine from starting and will immediately notify the Illinois Secretary of State's office. If your application is accepted, you should be able to drive a vehicle throughout the suspension time by installing this device (at your own cost).","imageName":"","correctAnswer":12696,"isBookmarked":0,"aid":"897#12696#12697#12698","text":"None of the above#Breath Alcohol Ignition Interlock Device#Breath Analyzing Ignition Interlock Device#Blood Alcohol Ignition Interlock Device"},
{"testId":577,"qid":24979,"description":"What must a person do to regain driving privileges if he or she has two or three prior DUI convictions but has never participated in the BAIID program?","explanation":"If a motorist has two or three previous DUI convictions but has never participated in the state's BAIID program, he or she will be forced to install a Breath Alcohol Ignition Interlock Device (BAIID) in all cars registered in his or her name in order to restore his or her driving privileges.","imageName":"","correctAnswer":29287,"isBookmarked":0,"aid":"29285#29286#29287#29288","text":"Purchase a safer vehicle#Complete a driver education class#Install a Breath Alcohol Ignition Interlock Device#Supply the IL Department of Transportation with weekly blood alcohol concentration (BAC) readings"},
{"testId":577,"qid":24980,"description":"Which of the following in Illinois will result in a DUI charge?","explanation":"A motorist may be charged with aggravated DUI if he or she is convicted of three or more DUI offenses, commits DUI while driving a school bus or limousine, or commits DUI that results in a death or severe injury.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29289#29290#29291","text":"All of the above#Committing DUI while driving a school bus#An injury or fatality occurring as the result of DUI#Receiving 3 or more DUI convictions"},
{"testId":1044,"qid":24980,"description":"Which of the following in Illinois will result in a DUI charge?","explanation":"A motorist may be charged with aggravated DUI if he or she is convicted of three or more DUI offenses, commits DUI while driving a school bus or limousine, or commits DUI that results in a death or severe injury.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29289#29290#29291","text":"All of the above#Committing DUI while driving a school bus#An injury or fatality occurring as the result of DUI#Receiving 3 or more DUI convictions"},
{"testId":577,"qid":24981,"description":"Most Illinois people are prohibited from consuming alcoholic beverages while driving. Which of the following is NOT an exception to the law?","explanation":"Passengers aboard chartered buses, motor homes, and limos are protected from the Illinois legislation that renders driving under the influence of alcohol unlawful.","imageName":"","correctAnswer":29294,"isBookmarked":0,"aid":"29292#29293#29294#29295","text":"Motor home passengers#Chartered bus passengers#Automobile backseat passengers#Limousine passengers"},
{"testId":1044,"qid":24981,"description":"Most Illinois people are prohibited from consuming alcoholic beverages while driving. Which of the following is NOT an exception to the law?","explanation":"Passengers aboard chartered buses, motor homes, and limos are protected from the Illinois legislation that renders driving under the influence of alcohol unlawful.","imageName":"","correctAnswer":29294,"isBookmarked":0,"aid":"29292#29293#29294#29295","text":"Motor home passengers#Chartered bus passengers#Automobile backseat passengers#Limousine passengers"},
{"testId":577,"qid":24982,"description":"In which of the following is it prohibited to have open alcohol containers in a vehicle?","explanation":"Open containers of alcohol may only be kept in locations where passengers cannot access them, such as cargo sections, trunks, and truck beds.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23141#29296#29297","text":"All of the above#Under the seat#The front seat#The console"},
{"testId":1044,"qid":24982,"description":"In which of the following is it prohibited to have open alcohol containers in a vehicle?","explanation":"Open containers of alcohol may only be kept in locations where passengers cannot access them, such as cargo sections, trunks, and truck beds.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23141#29296#29297","text":"All of the above#Under the seat#The front seat#The console"},
{"testId":577,"qid":24983,"description":"Which of the following violations in Illinois will cause you to lose your license in all states?","explanation":"If you are convicted of driving in Illinois with a license that has been suspended or revoked due to a DUI conviction, reckless murder, or fleeing the scene of a fatal or injury collision, you will be barred from driving in any state.","imageName":"","correctAnswer":29299,"isBookmarked":0,"aid":"897#29298#29299#36511","text":"None of the above#Two refusal charges#Driving with a license suspended for a DUI conviction#Driving with a license suspended for speeding convictions"},
{"testId":1044,"qid":24983,"description":"Which of the following violations in Illinois will cause you to lose your license in all states?","explanation":"If you are convicted of driving in Illinois with a license that has been suspended or revoked due to a DUI conviction, reckless murder, or fleeing the scene of a fatal or injury collision, you will be barred from driving in any state.","imageName":"","correctAnswer":29299,"isBookmarked":0,"aid":"897#29298#29299#36511","text":"None of the above#Two refusal charges#Driving with a license suspended for a DUI conviction#Driving with a license suspended for speeding convictions"},
{"testId":577,"qid":24984,"description":"What is the maximum penalty for supplying alcohol to a minor?","explanation":"If you are convicted of giving alcohol to a minor, you might face a $2,500 fine, up to a year in prison, and the suspension of your license.","imageName":"","correctAnswer":11670,"isBookmarked":0,"aid":"290#1799#3195#11670","text":"$500#$1,000#$5,000#$2,500"},
{"testId":1044,"qid":24984,"description":"What is the maximum penalty for supplying alcohol to a minor?","explanation":"If you are convicted of giving alcohol to a minor, you might face a $2,500 fine, up to a year in prison, and the suspension of your license.","imageName":"","correctAnswer":11670,"isBookmarked":0,"aid":"290#1799#3195#11670","text":"$500#$1,000#$5,000#$2,500"},
{"testId":577,"qid":24985,"description":"How long must a driver's licence be suspended for a first conviction of driving under the influence if the offender is younger than 21 years old?","explanation":"If you are under the age of 21 and convicted of DUI, your driving privileges will be revoked for a minimum of two years for a first offense.","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"11285#11306#11341#32928","text":"2 years#3 years#5 years#30 months"},
{"testId":1044,"qid":24985,"description":"How long must a driver's licence be suspended for a first conviction of driving under the influence if the offender is younger than 21 years old?","explanation":"If you are under the age of 21 and convicted of DUI, your driving privileges will be revoked for a minimum of two years for a first offense.","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"11285#11306#11341#32928","text":"2 years#3 years#5 years#30 months"},
{"testId":577,"qid":24987,"description":"If a driver under the age of 21 is found guilty of driving with a BAC of ______ or higher, his or her license will be revoked for three months.","explanation":"If a motorist under the age of 21 is detected driving while under the influence of alcohol, his or her license will be suspended for three months. Chemical tests used nowadays may detect blood alcohol concentrations (BAC) as low as 0.02 percent.","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1044,"qid":24987,"description":"If a driver under the age of 21 is found guilty of driving with a BAC of ______ or higher, his or her license will be revoked for three months.","explanation":"If a motorist under the age of 21 is detected driving while under the influence of alcohol, his or her license will be suspended for three months. Chemical tests used nowadays may detect blood alcohol concentrations (BAC) as low as 0.02 percent.","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":577,"qid":24988,"description":"If you are involved in an accident that causes death, bodily harm, or property damage of more than ________, you must file a crash report.","explanation":"You must submit a crash report if you are involved in an accident that causes death, bodily harm, or more than $1,500 in property damage.","imageName":"202006141611292838.jpg","correctAnswer":23890,"isBookmarked":0,"aid":"23890#25033#25034#27889","text":"$1,500#$2,000#$3,000#$2,500"},
{"testId":577,"qid":24989,"description":"If you are involved in a collision that includes an uninsured vehicle and causes property damage of at least ______, you must file a crash report.","explanation":"You must submit a crash report if you are involved in a collision with an uninsured vehicle that causes at least $500 in property damage.","imageName":"202006141611292838.jpg","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":577,"qid":24990,"description":"When you file a crash report, you also have to file another report with the IL Department of Transportation within _______ of the crash.","explanation":"When filing a crash report, you must also submit a report with the Illinois Department of Transportation within 10 days after the incident.","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#740#12361#12685","text":"10 days#15 days#3 days#5 days"},
{"testId":1044,"qid":24990,"description":"When you file a crash report, you also have to file another report with the IL Department of Transportation within _______ of the crash.","explanation":"When filing a crash report, you must also submit a report with the Illinois Department of Transportation within 10 days after the incident.","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#740#12361#12685","text":"10 days#15 days#3 days#5 days"},
{"testId":577,"qid":24991,"description":"The maximum penalty for failing to file a crash report is:","explanation":"If you fail to submit an accident report after a collision, you might face a $2,500 fine and a year in prison.","imageName":"","correctAnswer":29303,"isBookmarked":0,"aid":"29302#29303#29304#29305","text":"$1,000 and 30 days in jail#$2,500 and 1 year in jail#$10,000 and 1 year in jail#$5,000 and 30 days in jail"},
{"testId":1044,"qid":24991,"description":"The maximum penalty for failing to file a crash report is:","explanation":"If you fail to submit an accident report after a collision, you might face a $2,500 fine and a year in prison.","imageName":"","correctAnswer":29303,"isBookmarked":0,"aid":"29302#29303#29304#29305","text":"$1,000 and 30 days in jail#$2,500 and 1 year in jail#$10,000 and 1 year in jail#$5,000 and 30 days in jail"},
{"testId":577,"qid":24992,"description":"According to Illinois law, you have ________ after a crash to report it.","explanation":"According to Illinois law, you have 30 minutes after a collision to report it. If you do not, you will be charged with abandoning the scene of an accident, which is a crime.","imageName":"","correctAnswer":747,"isBookmarked":0,"aid":"745#747#12719#12720","text":"15 minutes#30 minutes#1 hour#1 day"},
{"testId":1044,"qid":24992,"description":"According to Illinois law, you have ________ after a crash to report it.","explanation":"According to Illinois law, you have 30 minutes after a collision to report it. If you do not, you will be charged with abandoning the scene of an accident, which is a crime.","imageName":"","correctAnswer":747,"isBookmarked":0,"aid":"745#747#12719#12720","text":"15 minutes#30 minutes#1 hour#1 day"},
{"testId":577,"qid":24993,"description":"Which of the following convictions in Illinois may result in license revocation?","explanation":"A driver's licence may be revoked after convictions for aggravated DUI, aggravated flight from police, aggravated reckless driving, vehicle theft, street racing, aggravated DUI, aggravated fake ID, aggravated reckless driving, and other offences.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29306#29307#29308","text":"All of the above#Aggravated DUI#Auto theft#Drag racing"},
{"testId":1044,"qid":24993,"description":"Which of the following convictions in Illinois may result in license revocation?","explanation":"A driver's licence may be revoked after convictions for aggravated DUI, aggravated flight from police, aggravated reckless driving, vehicle theft, street racing, aggravated DUI, aggravated fake ID, aggravated reckless driving, and other offences.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29306#29307#29308","text":"All of the above#Aggravated DUI#Auto theft#Drag racing"},
{"testId":577,"qid":24994,"description":"Your driver's license may be suspended if you fail to pay for ___ or more parking tickets in any municipality.","explanation":"If you do not pay for ten or more parking tickets issued by any municipality, your license may be suspended.","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":577,"qid":24995,"description":"An Occupational Driving Permit (ODP) allows a professional driver whose license has been suspended for three moving offenses to still drive.","explanation":"A professional driver's license that has been suspended for three moving offenses may be eligible for an Occupational Driving Permit (ODP). This permit will enable the individual to continue driving as part of his or her employment. To qualify, the individual must demonstrate that he or she drives for a livelihood.","imageName":"","correctAnswer":29309,"isBookmarked":0,"aid":"24974#29309#29310#29311","text":"to and from school#as part of the person's job#for medical purposes#to and from social events"},
{"testId":1044,"qid":24995,"description":"An Occupational Driving Permit (ODP) allows a professional driver whose license has been suspended for three moving offenses to still drive.","explanation":"A professional driver's license that has been suspended for three moving offenses may be eligible for an Occupational Driving Permit (ODP). This permit will enable the individual to continue driving as part of his or her employment. To qualify, the individual must demonstrate that he or she drives for a livelihood.","imageName":"","correctAnswer":29309,"isBookmarked":0,"aid":"24974#29309#29310#29311","text":"to and from school#as part of the person's job#for medical purposes#to and from social events"},
{"testId":578,"qid":24996,"description":"If you are under the age of 75, your Indiana driver's license is valid for :","explanation":"Your Indiana driver's license is valid for six years if you are under the age of 75.","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20788#23154#23183#29881","text":"2 years#1 year#3 years#6 years"},
{"testId":1045,"qid":24996,"description":"If you are under the age of 75, your Indiana driver's license is valid for :","explanation":"Your Indiana driver's license is valid for six years if you are under the age of 75.","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20788#23154#23183#29881","text":"2 years#1 year#3 years#6 years"},
{"testId":578,"qid":24998,"description":"If you fail a chemical test for drugs or alcohol, the Bureau of Motor Vehicles may suspend your license for up to :","explanation":"If you fail a chemical test for drugs or alcohol, the Bureau of Motor Vehicles may suspend your license for up to 180 days.","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1045,"qid":24998,"description":"If you fail a chemical test for drugs or alcohol, the Bureau of Motor Vehicles may suspend your license for up to :","explanation":"If you fail a chemical test for drugs or alcohol, the Bureau of Motor Vehicles may suspend your license for up to 180 days.","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":578,"qid":24999,"description":"If your blood alcohol content (BAC) is ________or greater, you are deemed legally inebriated in Indiana.","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#11768","text":"0.08%#0.04%#0.02%#0.10%"},
{"testId":1045,"qid":24999,"description":"If your blood alcohol content (BAC) is ________or greater, you are deemed legally inebriated in Indiana.","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#11768","text":"0.08%#0.04%#0.02%#0.10%"},
{"testId":578,"qid":25000,"description":"How long do you have to have a learner's permit before applying for a driver's license?","explanation":"Before applying for a driver's license, you must first get a learner's permit and keep it for 180 days.","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#11553#12630","text":"180 days#90 days#45 days#360 days"},
{"testId":1045,"qid":25000,"description":"How long do you have to have a learner's permit before applying for a driver's license?","explanation":"Before applying for a driver's license, you must first get a learner's permit and keep it for 180 days.","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#11553#12630","text":"180 days#90 days#45 days#360 days"},
{"testId":578,"qid":25001,"description":"When a probationary period is allowed, an Indiana driver's license must be taken away for _____ before the probation period starts.","explanation":"If an Indiana driver is eligible for a probationary license, the suspension period must last 30 days before the probationary license is issued.","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"739#740#742#1020","text":"10 days#15 days#30 days#60 days"},
{"testId":1045,"qid":25001,"description":"When a probationary period is allowed, an Indiana driver's license must be taken away for _____ before the probation period starts.","explanation":"If an Indiana driver is eligible for a probationary license, the suspension period must last 30 days before the probationary license is issued.","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"739#740#742#1020","text":"10 days#15 days#30 days#60 days"},
{"testId":578,"qid":25002,"description":"If you are eligible, the court may grant you specific driving privileges after a DUI conviction. The court may also require:","explanation":"If you are qualified, the court may grant you specific driving rights after a DUI conviction. The court may also order that you install an ignition interlock device in your car. If your blood alcohol content (BAC) reaches a predefined threshold, this device will prohibit you from starting the car.","imageName":"","correctAnswer":29885,"isBookmarked":0,"aid":"29882#29883#29884#29885","text":"an auto insurance increase#a bond#assigning a supervising passenger#installation of an ignition interlock device"},
{"testId":1045,"qid":25002,"description":"If you are eligible, the court may grant you specific driving privileges after a DUI conviction. The court may also require:","explanation":"If you are qualified, the court may grant you specific driving rights after a DUI conviction. The court may also order that you install an ignition interlock device in your car. If your blood alcohol content (BAC) reaches a predefined threshold, this device will prohibit you from starting the car.","imageName":"","correctAnswer":29885,"isBookmarked":0,"aid":"29882#29883#29884#29885","text":"an auto insurance increase#a bond#assigning a supervising passenger#installation of an ignition interlock device"},
{"testId":578,"qid":25004,"description":"How long will your license be taken away for if you drive with a suspended license in Indiana?","explanation":"If you are convicted of driving while your license is suspended, your license will be suspended for at least 90 days and up to two years.","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"229#742#1021#11301","text":"90 days#30 days#1 year#18 months"},
{"testId":1045,"qid":25004,"description":"How long will your license be taken away for if you drive with a suspended license in Indiana?","explanation":"If you are convicted of driving while your license is suspended, your license will be suspended for at least 90 days and up to two years.","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"229#742#1021#11301","text":"90 days#30 days#1 year#18 months"},
{"testId":578,"qid":25005,"description":"In Indiana, which of the following convictions will NOT result in a mandatory licence suspension?","explanation":"Your license will be suspended if you are guilty of criminal recklessness, involuntary manslaughter, or criminal mischief in a vehicle.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#29886#29887#29888","text":"Speeding#Criminal recklessness#Involuntary manslaughter#Criminal mischief in a vehicle"},
{"testId":1045,"qid":25005,"description":"In Indiana, which of the following convictions will NOT result in a mandatory licence suspension?","explanation":"Your license will be suspended if you are guilty of criminal recklessness, involuntary manslaughter, or criminal mischief in a vehicle.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#29886#29887#29888","text":"Speeding#Criminal recklessness#Involuntary manslaughter#Criminal mischief in a vehicle"},
{"testId":578,"qid":25006,"description":"In the state of Indiana, if you fail to pay or resolve traffic penalties or parking tickets, your driver's license will be suspended.","explanation":"If you do not pay or handle traffic or parking penalties, your license will be suspended forever until the matter is remedied.","imageName":"","correctAnswer":29889,"isBookmarked":0,"aid":"11612#11613#23185#29889","text":"for 6 months#for 1 year#for 30 days#until you address the citations or tickets"},
{"testId":1045,"qid":25006,"description":"In the state of Indiana, if you fail to pay or resolve traffic penalties or parking tickets, your driver's license will be suspended.","explanation":"If you do not pay or handle traffic or parking penalties, your license will be suspended forever until the matter is remedied.","imageName":"","correctAnswer":29889,"isBookmarked":0,"aid":"11612#11613#23185#29889","text":"for 6 months#for 1 year#for 30 days#until you address the citations or tickets"},
{"testId":578,"qid":25007,"description":"Convictions within a ______ are considered by Indiana's Habitual Traffic Violator program.","explanation":"Drivers who have several offenses within a 10-year period are penalized under Indiana's Habitual Traffic Violator program.","imageName":"","correctAnswer":29893,"isBookmarked":0,"aid":"29890#29891#29892#29893","text":"3-year#5-year#7-year#10-year"},
{"testId":1045,"qid":25007,"description":"Convictions within a ______ are considered by Indiana's Habitual Traffic Violator program.","explanation":"Drivers who have several offenses within a 10-year period are penalized under Indiana's Habitual Traffic Violator program.","imageName":"","correctAnswer":29893,"isBookmarked":0,"aid":"29890#29891#29892#29893","text":"3-year#5-year#7-year#10-year"},
{"testId":578,"qid":25008,"description":"A driver convicted of two driving-related offences resulting in injury or death within 10 years would have his or her license suspended for _____ under Indiana's Habitual Traffic Violator program.","explanation":"A motorist who is convicted of two driving-related offences that result in injury or death within a 10-year period will have his or her license revoked for ten years under Indiana's Habitual Violator program.","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23154#26672#29894#29895","text":"1 year#5 years#7 years#10 years"},
{"testId":1045,"qid":25008,"description":"A driver convicted of two driving-related offences resulting in injury or death within 10 years would have his or her license suspended for _____ under Indiana's Habitual Traffic Violator program.","explanation":"A motorist who is convicted of two driving-related offences that result in injury or death within a 10-year period will have his or her license revoked for ten years under Indiana's Habitual Violator program.","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23154#26672#29894#29895","text":"1 year#5 years#7 years#10 years"},
{"testId":578,"qid":25010,"description":"If you have 10 moving violations in 10 years, one of which is a major offense, your license may be suspended for ____ years.","explanation":"If you are guilty of ten moving offenses in ten years, at least one of which is a serious crime, your license will be suspended for five years.","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":1045,"qid":25010,"description":"If you have 10 moving violations in 10 years, one of which is a major offense, your license may be suspended for ____ years.","explanation":"If you are guilty of ten moving offenses in ten years, at least one of which is a serious crime, your license will be suspended for five years.","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":578,"qid":25011,"description":"If a driver whose license has been suspended as part of the Habitual Traffic Violator program is convicted of driving while suspended,:","explanation":"A person guilty of operating a vehicle while suspended as an HTV in Indiana may have their other driving privileges terminated for a length of time determined by the court.","imageName":"","correctAnswer":32748,"isBookmarked":0,"aid":"22673#29897#29898#32748","text":"None of the above are true#his or her license will be suspended for an additional 5 years#his or her license will be suspended for an additional 10 years#his or her license will be suspended for a period set by the court"},
{"testId":1045,"qid":25011,"description":"If a driver whose license has been suspended as part of the Habitual Traffic Violator program is convicted of driving while suspended,:","explanation":"A person guilty of operating a vehicle while suspended as an HTV in Indiana may have their other driving privileges terminated for a length of time determined by the court.","imageName":"","correctAnswer":32748,"isBookmarked":0,"aid":"22673#29897#29898#32748","text":"None of the above are true#his or her license will be suspended for an additional 5 years#his or her license will be suspended for an additional 10 years#his or her license will be suspended for a period set by the court"},
{"testId":578,"qid":25012,"description":"What is the minimum term of suspension for a first offense of driving without mandatory insurance?","explanation":"If you are convicted of driving an uninsured car, your license will be revoked for at least 90 days.","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1045,"qid":25012,"description":"What is the minimum term of suspension for a first offense of driving without mandatory insurance?","explanation":"If you are convicted of driving an uninsured car, your license will be revoked for at least 90 days.","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":578,"qid":25013,"description":"What is the charge for a license restoration following the first no-insurance suspension after January 1, 2015?","explanation":"If your license was suspended after January 1, 2015, for the first time for driving without mandatory insurance, you must pay a $250 reinstatement fee.","imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11833#12357#12358#12395","text":"$300#$150#$200#$250"},
{"testId":1045,"qid":25013,"description":"What is the charge for a license restoration following the first no-insurance suspension after January 1, 2015?","explanation":"If your license was suspended after January 1, 2015, for the first time for driving without mandatory insurance, you must pay a $250 reinstatement fee.","imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11833#12357#12358#12395","text":"$300#$150#$200#$250"},
{"testId":578,"qid":25014,"description":"What is the charge for regaining a license following a third or subsequent no-insurance suspension after January 1, 2015?","explanation":"If your license was suspended after January 1, 2015, for a third offense of driving without mandatory insurance, you must pay a $1,000 reinstatement fee.","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#12357#12358#12395","text":"$1,000#$150#$200#$250"},
{"testId":1045,"qid":25014,"description":"What is the charge for regaining a license following a third or subsequent no-insurance suspension after January 1, 2015?","explanation":"If your license was suspended after January 1, 2015, for a third offense of driving without mandatory insurance, you must pay a $1,000 reinstatement fee.","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#12357#12358#12395","text":"$1,000#$150#$200#$250"},
{"testId":578,"qid":25015,"description":"Each moving traffic offense in Indiana carries _____ points under the state's point system.","explanation":"Each moving traffic offense in Indiana is assessed between 2 and 8 points under the Indiana points system.","imageName":"","correctAnswer":29900,"isBookmarked":0,"aid":"29899#29900#29901#29902","text":"between 1 and 5#between 2 and 8#between 3 and 9#between 4 and 10"},
{"testId":1045,"qid":25015,"description":"Each moving traffic offense in Indiana carries _____ points under the state's point system.","explanation":"Each moving traffic offense in Indiana is assessed between 2 and 8 points under the Indiana points system.","imageName":"","correctAnswer":29900,"isBookmarked":0,"aid":"29899#29900#29901#29902","text":"between 1 and 5#between 2 and 8#between 3 and 9#between 4 and 10"},
{"testId":578,"qid":25016,"description":"After obtaining _____ or more points in a 24-month period, you will be required to attend an administrative hearing.","explanation":"You will be forced to attend an administrative hearing if you accumulate 18 or more points in a 24-month period.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":1045,"qid":25016,"description":"After obtaining _____ or more points in a 24-month period, you will be required to attend an administrative hearing.","explanation":"You will be forced to attend an administrative hearing if you accumulate 18 or more points in a 24-month period.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":578,"qid":25017,"description":"Which of the following could happen if you are summoned to an administrative hearing due to an accumulation of too many points?","explanation":"You may be put on probation, have your license suspended, or be forced to take a BMV-approved driving safety course if you are compelled to attend an administrative hearing over your excess points accumulation.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12750#29903#29904","text":"All of the above#BMV-approved safety course requirement#Placement on probation#Suspension from 30 days to 1 year"},
{"testId":1045,"qid":25017,"description":"Which of the following could happen if you are summoned to an administrative hearing due to an accumulation of too many points?","explanation":"You may be put on probation, have your license suspended, or be forced to take a BMV-approved driving safety course if you are compelled to attend an administrative hearing over your excess points accumulation.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12750#29903#29904","text":"All of the above#BMV-approved safety course requirement#Placement on probation#Suspension from 30 days to 1 year"},
{"testId":578,"qid":25018,"description":"Two points will be added to your driving record for exceeding the posted speed limit by up to _____ mph.","explanation":"Driving up to 15 mph above the stated speed limit will result in 2 points being added to your driving record.","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6425#11361","text":"15#20#25#16"},
{"testId":1045,"qid":25018,"description":"Two points will be added to your driving record for exceeding the posted speed limit by up to _____ mph.","explanation":"Driving up to 15 mph above the stated speed limit will result in 2 points being added to your driving record.","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6425#11361","text":"15#20#25#16"},
{"testId":578,"qid":25019,"description":"Driving between 16 and 25 mph over the posted speed limit will result in ____ points being added to your driving record.","explanation":"Driving between 16 and 25 mph above the stated speed limit will result in 4 points being added to your driving record.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1045,"qid":25019,"description":"Driving between 16 and 25 mph over the posted speed limit will result in ____ points being added to your driving record.","explanation":"Driving between 16 and 25 mph above the stated speed limit will result in 4 points being added to your driving record.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":578,"qid":25020,"description":"____ points will be added to your driving record for exceeding the posted speed limit by at least 26 mph.","explanation":"Six points will be added to your driving record for exceeding the specified speed limit by at least 26 miles per hour.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1045,"qid":25020,"description":"____ points will be added to your driving record for exceeding the posted speed limit by at least 26 mph.","explanation":"Six points will be added to your driving record for exceeding the specified speed limit by at least 26 miles per hour.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":578,"qid":25021,"description":"Which of the following will NOT cause an Indiana driver's record to go up by two points?","explanation":"An improper U-turn will result in a four-point addition.","imageName":"","correctAnswer":29905,"isBookmarked":0,"aid":"29905#29906#29907#29908","text":"Making an improper U-turn#Failure to use headlights#Driving without brake lights#Speeding 8 mph over the posted limit"},
{"testId":1045,"qid":25021,"description":"Which of the following will NOT cause an Indiana driver's record to go up by two points?","explanation":"An improper U-turn will result in a four-point addition.","imageName":"","correctAnswer":29905,"isBookmarked":0,"aid":"29905#29906#29907#29908","text":"Making an improper U-turn#Failure to use headlights#Driving without brake lights#Speeding 8 mph over the posted limit"},
{"testId":578,"qid":25023,"description":"Which of the following is the most serious traffic violation in Indiana, according to the state's point system?","explanation":"Participating in a speed contest on Indiana roadways will add 8 points to your driving record. Tailgating and blowing through a stop sign will both get you 6 points. Driving without brake lights will result in a two-point penalty.","imageName":"","correctAnswer":29909,"isBookmarked":0,"aid":"864#29907#29909#29910","text":"Tailgating#Driving without brake lights#A speed contest on the road#Disregarding a stop sign"},
{"testId":1045,"qid":25023,"description":"Which of the following is the most serious traffic violation in Indiana, according to the state's point system?","explanation":"Participating in a speed contest on Indiana roadways will add 8 points to your driving record. Tailgating and blowing through a stop sign will both get you 6 points. Driving without brake lights will result in a two-point penalty.","imageName":"","correctAnswer":29909,"isBookmarked":0,"aid":"864#29907#29909#29910","text":"Tailgating#Driving without brake lights#A speed contest on the road#Disregarding a stop sign"},
{"testId":578,"qid":25024,"description":"Driving with a suspended or revoked license will add ___points to your driving record.","explanation":"If you are convicted of driving while your license is suspended, you will get 8 points on your license.","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1045,"qid":25024,"description":"Driving with a suspended or revoked license will add ___points to your driving record.","explanation":"If you are convicted of driving while your license is suspended, you will get 8 points on your license.","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":578,"qid":25025,"description":"You will receive a credit of ____ points on your driving record if you finish a BMV-approved defensive driving course.","explanation":"You will obtain a 4-point credit on your driving record if you complete a BMV-approved defensive driving course.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1045,"qid":25025,"description":"You will receive a credit of ____ points on your driving record if you finish a BMV-approved defensive driving course.","explanation":"You will obtain a 4-point credit on your driving record if you complete a BMV-approved defensive driving course.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":578,"qid":25026,"description":"As a substitute for ________, an Indiana judge may order a driver to take a BMV-approved defensive driving course.","explanation":"An Indiana judge has the authority to substitute mandatory completion of a BMV-approved defensive driving course for licence suspension.","imageName":"","correctAnswer":29911,"isBookmarked":0,"aid":"8615#11443#23210#29911","text":"All of the above#jail time#fines#license suspension"},
{"testId":1045,"qid":25026,"description":"As a substitute for ________, an Indiana judge may order a driver to take a BMV-approved defensive driving course.","explanation":"An Indiana judge has the authority to substitute mandatory completion of a BMV-approved defensive driving course for licence suspension.","imageName":"","correctAnswer":29911,"isBookmarked":0,"aid":"8615#11443#23210#29911","text":"All of the above#jail time#fines#license suspension"},
{"testId":578,"qid":25028,"description":"If you do not complete a mandatory driver safety course,:","explanation":"If you do not finish a compulsory driving safety course, your license will be suspended until you do.","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"22673#29912#29913#29914","text":"None of the above are true#your license will be suspended#you will be required to pay a fine of $200#your vehicle will be impounded"},
{"testId":1045,"qid":25028,"description":"If you do not complete a mandatory driver safety course,:","explanation":"If you do not finish a compulsory driving safety course, your license will be suspended until you do.","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"22673#29912#29913#29914","text":"None of the above are true#your license will be suspended#you will be required to pay a fine of $200#your vehicle will be impounded"},
{"testId":578,"qid":25029,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driver's license will be suspended until you pay or set up a payment plan.","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"203#27198#28813#29915","text":"All of the above#Failure to pay child support#Shoplifting#Failure to pay for utility services"},
{"testId":1045,"qid":25029,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driver's license will be suspended until you pay or set up a payment plan.","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"203#27198#28813#29915","text":"All of the above#Failure to pay child support#Shoplifting#Failure to pay for utility services"},
{"testId":578,"qid":25030,"description":"Graffiti can lead to license suspension for up to:","explanation":"A conviction for graffiti may result in a one-year license suspension.","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23884#23886","text":"6 months#1 year#15 days#30 days"},
{"testId":1045,"qid":25030,"description":"Graffiti can lead to license suspension for up to:","explanation":"A conviction for graffiti may result in a one-year license suspension.","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23884#23886","text":"6 months#1 year#15 days#30 days"},
{"testId":578,"qid":25031,"description":"To get a learner's permit or driver's license in Indiana, a driver who is under the age of _____ must receive agreement from a parent or guardian.","explanation":"A motorist under the age of 18 must get permission from a parent or guardian to acquire a learner's permit or driver's license in Indiana.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1045,"qid":25031,"description":"To get a learner's permit or driver's license in Indiana, a driver who is under the age of _____ must receive agreement from a parent or guardian.","explanation":"A motorist under the age of 18 must get permission from a parent or guardian to acquire a learner's permit or driver's license in Indiana.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":578,"qid":25032,"description":"Your driver's license's Restriction B indicates that you:","explanation":"You must use corrective lenses (eyeglasses or contact lenses) to operate a vehicle safely if you have Restriction B on your driver's license.","imageName":"","correctAnswer":29916,"isBookmarked":0,"aid":"29916#29917#29918#29919","text":"must wear corrective lenses#may only drive during daylight hours#must have a vehicle equipped with outside mirrors#must use a prosthetic aid"},
{"testId":1045,"qid":25032,"description":"Your driver's license's Restriction B indicates that you:","explanation":"You must use corrective lenses (eyeglasses or contact lenses) to operate a vehicle safely if you have Restriction B on your driver's license.","imageName":"","correctAnswer":29916,"isBookmarked":0,"aid":"29916#29917#29918#29919","text":"must wear corrective lenses#may only drive during daylight hours#must have a vehicle equipped with outside mirrors#must use a prosthetic aid"},
{"testId":578,"qid":25033,"description":"Restriction ____ on your driver's license restricts you to driving only during daylight hours.","explanation":"Restriction G on your driver's license says you may only operate a vehicle during daylight hours.","imageName":"","correctAnswer":11689,"isBookmarked":0,"aid":"323#11689#12378#12764","text":"B#G#E#F"},
{"testId":1045,"qid":25033,"description":"Restriction ____ on your driver's license restricts you to driving only during daylight hours.","explanation":"Restriction G on your driver's license says you may only operate a vehicle during daylight hours.","imageName":"","correctAnswer":11689,"isBookmarked":0,"aid":"323#11689#12378#12764","text":"B#G#E#F"},
{"testId":578,"qid":25034,"description":"Indiana law requires __________ to wear safety belts or appropriate child restraints.","explanation":"When the vehicle is in operation, Indiana law requires the driver and all passengers to utilize seat belts or appropriate kid restraints. Drivers of buses are likewise obliged to wear safety belts.","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#3406#12766#29920","text":"The driver and all passengers#the driver#only the passenger in the middle rear seat#rear-seat passengers"},
{"testId":1045,"qid":25034,"description":"Indiana law requires __________ to wear safety belts or appropriate child restraints.","explanation":"When the vehicle is in operation, Indiana law requires the driver and all passengers to utilize seat belts or appropriate kid restraints. Drivers of buses are likewise obliged to wear safety belts.","imageName":"","correctAnswer":2762,"isBookmarked":0,"aid":"2762#3406#12766#29920","text":"The driver and all passengers#the driver#only the passenger in the middle rear seat#rear-seat passengers"},
{"testId":578,"qid":25035,"description":"Which of the following might get you out of the state's seat belt law?","explanation":"If you acquire a medical declaration from a physician indicating the medical reasons why you should not wear a safety belt, you may be excused from the state's seat belt regulation. This declaration must accompany you anytime you ride in a vehicle.","imageName":"","correctAnswer":29921,"isBookmarked":0,"aid":"897#29921#29922#29923","text":"None of the above#A medical statement from a physician#Operating a bus#A vehicle without airbags"},
{"testId":1045,"qid":25035,"description":"Which of the following might get you out of the state's seat belt law?","explanation":"If you acquire a medical declaration from a physician indicating the medical reasons why you should not wear a safety belt, you may be excused from the state's seat belt regulation. This declaration must accompany you anytime you ride in a vehicle.","imageName":"","correctAnswer":29921,"isBookmarked":0,"aid":"897#29921#29922#29923","text":"None of the above#A medical statement from a physician#Operating a bus#A vehicle without airbags"},
{"testId":578,"qid":25036,"description":"A kid under the age of ____ must be restrained correctly using a child restraint device.","explanation":"According to Indiana law, a kid must be restrained in an appropriate child restraint system until the age of eight.","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":1045,"qid":25036,"description":"A kid under the age of ____ must be restrained correctly using a child restraint device.","explanation":"According to Indiana law, a kid must be restrained in an appropriate child restraint system until the age of eight.","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":578,"qid":25037,"description":"You must use headlights between sunset and sunrise and whenever visibility is less than _____ feet to avoid a traffic citation and 2 points on your driving record.","explanation":"You must use headlights between sunset and daybreak and anytime visibility is less than 500 feet to avoid a traffic penalty and 2 points on your driving record.","imageName":"","correctAnswer":12770,"isBookmarked":0,"aid":"7570#9975#12770#12771","text":"200#300#500#700"},
{"testId":1045,"qid":25037,"description":"You must use headlights between sunset and sunrise and whenever visibility is less than _____ feet to avoid a traffic citation and 2 points on your driving record.","explanation":"You must use headlights between sunset and daybreak and anytime visibility is less than 500 feet to avoid a traffic penalty and 2 points on your driving record.","imageName":"","correctAnswer":12770,"isBookmarked":0,"aid":"7570#9975#12770#12771","text":"200#300#500#700"},
{"testId":578,"qid":25038,"description":"Driver's licenses in Indiana for drivers under the age of 21:","explanation":"Driver's licenses in Indiana for those under the age of 21 are printed vertically.","imageName":"","correctAnswer":29925,"isBookmarked":0,"aid":"29924#29925#29926#29927","text":"have a horizontal orientation#have a vertical orientation#are black and white#glow in the dark"},
{"testId":1045,"qid":25038,"description":"Driver's licenses in Indiana for drivers under the age of 21:","explanation":"Driver's licenses in Indiana for those under the age of 21 are printed vertically.","imageName":"","correctAnswer":29925,"isBookmarked":0,"aid":"29924#29925#29926#29927","text":"have a horizontal orientation#have a vertical orientation#are black and white#glow in the dark"},
{"testId":578,"qid":25039,"description":"In the event of an accident, you must:","explanation":"Any driver involved in an accident is required by Indiana law to immediately stop at the scene of the accident or as close to the scene as possible; provide other parties with contact information; provide assistance to each person injured in the accident, as directed by a law enforcement officer, medical personnel, or 911 telephone operator; and provide notice of the accident as soon as possible.","imageName":"202006141611292838.jpg","correctAnswer":10656,"isBookmarked":0,"aid":"10656#29928#29929#29930","text":"Do all of the above#immediately stop at the scene of the accident or as close to the accident as possible#provide assistance to each person injured in the accident, as directed by a law enforcement officer, medical personnel, or 911 telephone operator#give notice of the accident as soon as possible"},
{"testId":578,"qid":25040,"description":"You must file an accident report within ____ days if a crash causes injury, death, or property damage exceeding $1,000.","explanation":"You must submit an accident report within 10 days if a collision causes injury, death, or property damage in excess of $1,000.","imageName":"202006141611292838.jpg","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":578,"qid":25041,"description":"During a traffic stop, you must present a law enforcement officer with _______.","explanation":"When pulled over for a traffic violation, you must provide your driver's license, current proof of insurance, and car registration to a law enforcement official.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#29931#29932#29933","text":"All of the above#your driver's license#current proof of insurance#your vehicle's registration"},
{"testId":1045,"qid":25041,"description":"During a traffic stop, you must present a law enforcement officer with _______.","explanation":"When pulled over for a traffic violation, you must provide your driver's license, current proof of insurance, and car registration to a law enforcement official.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#29931#29932#29933","text":"All of the above#your driver's license#current proof of insurance#your vehicle's registration"},
{"testId":578,"qid":25042,"description":"If you buy a new vehicle, you can register it online within :","explanation":"Within 45 days after purchasing a new car, you may register it online. Following that, you must go to a BMV office in person to register the car.","imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23153#23883#23886#27364","text":"6 months#10 days#30 days#45 days"},
{"testId":1045,"qid":25042,"description":"If you buy a new vehicle, you can register it online within :","explanation":"Within 45 days after purchasing a new car, you may register it online. Following that, you must go to a BMV office in person to register the car.","imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23153#23883#23886#27364","text":"6 months#10 days#30 days#45 days"},
{"testId":578,"qid":25043,"description":"In Indiana, which of the following can result in criminal charges?","explanation":"Using another person's driver's license as your own is a class C misdemeanor. A class C misdemeanor is punished by up to 60 days in prison and a $500 fine. Making or helping in the making of a fraudulent identity card is a class B misdemeanor. A class B misdemeanor is punished by up to 180 days in prison and a $1,000 fine. Falsely applying for a driver's license is considered application fraud, a category 6 crime. A level 6 felony is punished by 6 months to 2 1/2 years in prison and a $10,000 fine.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29934#29935#29936","text":"All of the above#Producing a false identification card#Using another person's driver's license as your own#Assisting in the production of a false driver's license"},
{"testId":1045,"qid":25043,"description":"In Indiana, which of the following can result in criminal charges?","explanation":"Using another person's driver's license as your own is a class C misdemeanor. A class C misdemeanor is punished by up to 60 days in prison and a $500 fine. Making or helping in the making of a fraudulent identity card is a class B misdemeanor. A class B misdemeanor is punished by up to 180 days in prison and a $1,000 fine. Falsely applying for a driver's license is considered application fraud, a category 6 crime. A level 6 felony is punished by 6 months to 2 1/2 years in prison and a $10,000 fine.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29934#29935#29936","text":"All of the above#Producing a false identification card#Using another person's driver's license as your own#Assisting in the production of a false driver's license"},
{"testId":578,"qid":25044,"description":"A driver who is under the age of 21 and has any current points on his or her driving record must ________ to renew his or her driver's license.","explanation":"An Indiana driver who is under 21 years of age and has any points on his or her driving record must take the knowledge test again to renew his or her driver's licence. An Indiana driver who is 21 years of age or older and has 6 or more points on his or her driving record must take the knowledge test again to renew his or her driver's licence.","imageName":"","correctAnswer":29938,"isBookmarked":0,"aid":"12785#27667#29937#29938","text":"pay the appropriate fines#do none of the above#have a parent or guardian present#take the knowledge test again"},
{"testId":1045,"qid":25044,"description":"A driver who is under the age of 21 and has any current points on his or her driving record must ________ to renew his or her driver's license.","explanation":"An Indiana driver who is under 21 years of age and has any points on his or her driving record must take the knowledge test again to renew his or her driver's licence. An Indiana driver who is 21 years of age or older and has 6 or more points on his or her driving record must take the knowledge test again to renew his or her driver's licence.","imageName":"","correctAnswer":29938,"isBookmarked":0,"aid":"12785#27667#29937#29938","text":"pay the appropriate fines#do none of the above#have a parent or guardian present#take the knowledge test again"},
{"testId":578,"qid":25045,"description":"For how much duration聽is a learner's permit valid in Indiana?","explanation":"An Indiana learner's permit is valid for 2 years.","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":579,"qid":25046,"description":"You could lose your license through:","explanation":"Your driver's license may be revoked, suspended, revoked, cancelled, disqualified, or denied. In addition, if you are deemed a persistent offender, your driving rights may be suspended.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8335#8615#30338#30339","text":"suspension#All of the above#revocation#cancellation"},
{"testId":1046,"qid":25046,"description":"You could lose your license through:","explanation":"Your driver's license may be revoked, suspended, revoked, cancelled, disqualified, or denied. In addition, if you are deemed a persistent offender, your driving rights may be suspended.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8335#8615#30338#30339","text":"suspension#All of the above#revocation#cancellation"},
{"testId":579,"qid":25047,"description":"In the state of Iowa, it is against the law to use a driver's license in which of the following ways?","explanation":"Using a fake identity on an Iowa driver's license, impersonating another person's license, or tampering with your driver's license in any manner can result in fines.","imageName":"","correctAnswer":30341,"isBookmarked":0,"aid":"30340#30341#30342#30343","text":"Using another person's driver's license#Keeping your driver's license in your wallet#Using a false name#Altering your driver's license"},
{"testId":1046,"qid":25047,"description":"In the state of Iowa, it is against the law to use a driver's license in which of the following ways?","explanation":"Using a fake identity on an Iowa driver's license, impersonating another person's license, or tampering with your driver's license in any manner can result in fines.","imageName":"","correctAnswer":30341,"isBookmarked":0,"aid":"30340#30341#30342#30343","text":"Using another person's driver's license#Keeping your driver's license in your wallet#Using a false name#Altering your driver's license"},
{"testId":579,"qid":25049,"description":"If you have ____ or more accidents during a 12-month period, your license will be suspended.","explanation":"Your driver's license will be suspended if you have three or more accidents in a 12-month period.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1046,"qid":25049,"description":"If you have ____ or more accidents during a 12-month period, your license will be suspended.","explanation":"Your driver's license will be suspended if you have three or more accidents in a 12-month period.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":579,"qid":25050,"description":"If a motorist under the age of 18 in fails to attend school, his or her license may be:","explanation":"Iowa's traffic rules encourage young drivers to continue their education. A motorist under the age of 18 may have his or her license suspended if he or she fails to attend school.","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11439#30344#30345","text":"revoked#suspended#cancelled#barred"},
{"testId":1046,"qid":25050,"description":"If a motorist under the age of 18 in fails to attend school, his or her license may be:","explanation":"Iowa's traffic rules encourage young drivers to continue their education. A motorist under the age of 18 may have his or her license suspended if he or she fails to attend school.","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11439#30344#30345","text":"revoked#suspended#cancelled#barred"},
{"testId":579,"qid":25051,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"Your license may be suspended if you fail to make court-ordered child support payments.","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"26692#27198#28813#30346","text":"Domestic violence#Failure to pay child support#Shoplifting#Failure to pay income taxes"},
{"testId":1046,"qid":25051,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"Your license may be suspended if you fail to make court-ordered child support payments.","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"26692#27198#28813#30346","text":"Domestic violence#Failure to pay child support#Shoplifting#Failure to pay income taxes"},
{"testId":579,"qid":25053,"description":"Which of the following will force you to lose your license?","explanation":"A conviction for vehicular homicide, failing to stop after an accident, refusing to submit to a chemical test, and other major offenses will result in the loss of driving privileges via license revocation.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30347#30348#30349","text":"All of the above#A conviction for vehicular manslaughter#Failure to stop at an accident in which you were involved#Refusal to submit to a chemical test when requested by a law enforcement officer"},
{"testId":1046,"qid":25053,"description":"Which of the following will force you to lose your license?","explanation":"A conviction for vehicular homicide, failing to stop after an accident, refusing to submit to a chemical test, and other major offenses will result in the loss of driving privileges via license revocation.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30347#30348#30349","text":"All of the above#A conviction for vehicular manslaughter#Failure to stop at an accident in which you were involved#Refusal to submit to a chemical test when requested by a law enforcement officer"},
{"testId":579,"qid":25054,"description":"How many convictions of reckless driving will lead to a suspension of the driving license ?","explanation":"Your driver's license will be revoked if you are convicted of two or more reckless driving offenses.","imageName":"","correctAnswer":10547,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":1046,"qid":25054,"description":"How many convictions of reckless driving will lead to a suspension of the driving license ?","explanation":"Your driver's license will be revoked if you are convicted of two or more reckless driving offenses.","imageName":"","correctAnswer":10547,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":579,"qid":25055,"description":"Which of the following is unlikely to result in license revocation?","explanation":"Drag racing, evading law enforcement agents, or lying about car registration may all lead to license revocation.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#29308#30350#30351","text":"Speeding#Drag racing#Attempting to elude law enforcement officers#Lying about your vehicle registration"},
{"testId":1046,"qid":25055,"description":"Which of the following is unlikely to result in license revocation?","explanation":"Drag racing, evading law enforcement agents, or lying about car registration may all lead to license revocation.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#29308#30350#30351","text":"Speeding#Drag racing#Attempting to elude law enforcement officers#Lying about your vehicle registration"},
{"testId":579,"qid":25056,"description":"Drivers who have been convicted of ___ or more major offences within a six-year period are considered habitual offenders in Iowa.","explanation":"Drivers who have been convicted of three or more major infractions within six years are considered habitual offenders in Iowa.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1046,"qid":25056,"description":"Drivers who have been convicted of ___ or more major offences within a six-year period are considered habitual offenders in Iowa.","explanation":"Drivers who have been convicted of three or more major infractions within six years are considered habitual offenders in Iowa.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":579,"qid":25058,"description":"If you're convicted of driving with a revoked license for OWI (DUI), ___ points will be added to your record.","explanation":"If you are convicted of driving while your license is suspended due to a DUI conviction, three points will be added to your driving record.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5449#6389#7620","text":"10#5#3#8"},
{"testId":1046,"qid":25058,"description":"If you're convicted of driving with a revoked license for OWI (DUI), ___ points will be added to your record.","explanation":"If you are convicted of driving while your license is suspended due to a DUI conviction, three points will be added to your driving record.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5449#6389#7620","text":"10#5#3#8"},
{"testId":579,"qid":25060,"description":"If you are convicted of, five points will be added to your driving record.","explanation":"If you are convicted of failing to stop to offer help or provide contact information at an accident in which you were involved, you will get 5 points on your license.","imageName":"","correctAnswer":30354,"isBookmarked":0,"aid":"30352#30353#30354#30355","text":"perjury to the Department of Public Safety#vehicular manslaughter#failure to stop at an accident in which you were involved#driving while your driving privileges are barred"},
{"testId":1046,"qid":25060,"description":"If you are convicted of, five points will be added to your driving record.","explanation":"If you are convicted of failing to stop to offer help or provide contact information at an accident in which you were involved, you will get 5 points on your license.","imageName":"","correctAnswer":30354,"isBookmarked":0,"aid":"30352#30353#30354#30355","text":"perjury to the Department of Public Safety#vehicular manslaughter#failure to stop at an accident in which you were involved#driving while your driving privileges are barred"},
{"testId":579,"qid":25061,"description":"Which is the only conviction that will add 6 points to your driving record?","explanation":"If you are guilty of manslaughter while operating a motor vehicle, you will get 6 points on your license.","imageName":"","correctAnswer":11603,"isBookmarked":0,"aid":"11603#24985#27919#30356","text":"Vehicular manslaughter#Driving under the influence (DUI)#Driving with a suspended license#Eluding law enforcement"},
{"testId":1046,"qid":25061,"description":"Which is the only conviction that will add 6 points to your driving record?","explanation":"If you are guilty of manslaughter while operating a motor vehicle, you will get 6 points on your license.","imageName":"","correctAnswer":11603,"isBookmarked":0,"aid":"11603#24985#27919#30356","text":"Vehicular manslaughter#Driving under the influence (DUI)#Driving with a suspended license#Eluding law enforcement"},
{"testId":579,"qid":25063,"description":"You must acquire extra points within ___ years to be considered a habitual offender.","explanation":"If you acquire too many points in a 6-year period, you may be labeled a habitual offender.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6916#7559","text":"5#3#6#7"},
{"testId":1046,"qid":25063,"description":"You must acquire extra points within ___ years to be considered a habitual offender.","explanation":"If you acquire too many points in a 6-year period, you may be labeled a habitual offender.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6916#7559","text":"5#3#6#7"},
{"testId":579,"qid":25064,"description":"Your driving privileges may be suspended for _______,If you accumulate 6 or 7 points in 6 years.","explanation":"If you acquire 6 or 7 points in a 6-year period, your driving rights may be suspended for 2 years.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1046,"qid":25064,"description":"Your driving privileges may be suspended for _______,If you accumulate 6 or 7 points in 6 years.","explanation":"If you acquire 6 or 7 points in a 6-year period, your driving rights may be suspended for 2 years.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":579,"qid":25065,"description":"If you accumulate ________ points in a 6-year period, your driving rights may be suspended for three years.","explanation":"If you acquire 8 or 9 points in a 6-year period, your driving rights may be suspended for 3 years.","imageName":"","correctAnswer":12807,"isBookmarked":0,"aid":"12806#12807#12808#12809","text":"6 to 7#8 to 9#10 to 11#12 to 13"},
{"testId":1046,"qid":25065,"description":"If you accumulate ________ points in a 6-year period, your driving rights may be suspended for three years.","explanation":"If you acquire 8 or 9 points in a 6-year period, your driving rights may be suspended for 3 years.","imageName":"","correctAnswer":12807,"isBookmarked":0,"aid":"12806#12807#12808#12809","text":"6 to 7#8 to 9#10 to 11#12 to 13"},
{"testId":579,"qid":25067,"description":"The maximum number of years your driving privileges may be suspended for accumulating excess points in a 6-year period is:","explanation":"Your driving privileges may be suspended for up to 6 years if you accumulate too many points in a 6-year period.","imageName":"","correctAnswer":30359,"isBookmarked":0,"aid":"30357#30358#30359#30360","text":"4#5#6#7"},
{"testId":579,"qid":25068,"description":"How many points in a 6-year period can lead to the maximum penalty?","explanation":"If you acquire 16 or more points on your driving record in a 6-year period, your driving privileges may be suspended for 6 years.","imageName":"","correctAnswer":12812,"isBookmarked":0,"aid":"12810#12811#12812#12813","text":"14 or more#15 or more#16 or more#20 or more"},
{"testId":1046,"qid":25068,"description":"How many points in a 6-year period can lead to the maximum penalty?","explanation":"If you acquire 16 or more points on your driving record in a 6-year period, your driving privileges may be suspended for 6 years.","imageName":"","correctAnswer":12812,"isBookmarked":0,"aid":"12810#12811#12812#12813","text":"14 or more#15 or more#16 or more#20 or more"},
{"testId":579,"qid":25069,"description":"If you drive while barred as a habitual offender, you can be imprisoned for up to:","explanation":"If you are convicted of driving a motor vehicle while prohibited as a habitual offender, you might face up to two years in prison.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23886#24048","text":"2 years#6 months#30 days#60 days"},
{"testId":1046,"qid":25069,"description":"If you drive while barred as a habitual offender, you can be imprisoned for up to:","explanation":"If you are convicted of driving a motor vehicle while prohibited as a habitual offender, you might face up to two years in prison.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23886#24048","text":"2 years#6 months#30 days#60 days"},
{"testId":579,"qid":25070,"description":"Which of the following is considered a moving traffic offense in the state of Iowa?","explanation":"A speeding conviction in Iowa is considered a moving infraction.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#30361#30362#30363","text":"Speeding#Equipment violation#Seat belt violation#Parking ticket"},
{"testId":1046,"qid":25070,"description":"Which of the following is considered a moving traffic offense in the state of Iowa?","explanation":"A speeding conviction in Iowa is considered a moving infraction.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#30361#30362#30363","text":"Speeding#Equipment violation#Seat belt violation#Parking ticket"},
{"testId":579,"qid":25071,"description":"A civil penalty of _______ must be paid before an adult driver's license that has been suspended or revoked due to a conviction may be reinstated.","explanation":"If an adult's license was suspended or revoked due to a conviction, he or she must pay a $200 civil penalty before his or her license may be restored.","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11832#11833#12358#12817","text":"$100#$300#$200#$400"},
{"testId":1046,"qid":25071,"description":"A civil penalty of _______ must be paid before an adult driver's license that has been suspended or revoked due to a conviction may be reinstated.","explanation":"If an adult's license was suspended or revoked due to a conviction, he or she must pay a $200 civil penalty before his or her license may be restored.","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11832#11833#12358#12817","text":"$100#$300#$200#$400"},
{"testId":579,"qid":25072,"description":"If a motorist aged 19 or younger has had his or her license suspended or revoked due to a conviction, he or she must pay a civil penalty of _____ before his or her license can be reinstated.","explanation":"If a motorist aged 19 or younger has had his or her license suspended or revoked due to a conviction, he or she must pay a civil penalty of $50 before his or her license may be restored.","imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11350#11832","text":"$25#$50#$125#$100"},
{"testId":1046,"qid":25072,"description":"If a motorist aged 19 or younger has had his or her license suspended or revoked due to a conviction, he or she must pay a civil penalty of _____ before his or her license can be reinstated.","explanation":"If a motorist aged 19 or younger has had his or her license suspended or revoked due to a conviction, he or she must pay a civil penalty of $50 before his or her license may be restored.","imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11350#11832","text":"$25#$50#$125#$100"},
{"testId":579,"qid":25073,"description":"What is the official phrase in Iowa for driving while under the influence of alcohol or drugs?","explanation":"Driving under the influence is officially known as Operating While Intoxicated in Iowa (OWI).","imageName":"","correctAnswer":12818,"isBookmarked":0,"aid":"12818#12819#12820#12821","text":"Operating While Intoxicated (OWI)#Operating While Impaired (OWI)#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)"},
{"testId":1046,"qid":25073,"description":"What is the official phrase in Iowa for driving while under the influence of alcohol or drugs?","explanation":"Driving under the influence is officially known as Operating While Intoxicated in Iowa (OWI).","imageName":"","correctAnswer":12818,"isBookmarked":0,"aid":"12818#12819#12820#12821","text":"Operating While Intoxicated (OWI)#Operating While Impaired (OWI)#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)"},
{"testId":579,"qid":25074,"description":"According to Iowa's Implied Consent Law, every driver who runs a vehicle on the state's highways has agreed to :","explanation":"The Implied Consent statute in Iowa compels any motorist to submit to a chemical test for alcohol or drugs if a peace officer requests it.","imageName":"","correctAnswer":30365,"isBookmarked":0,"aid":"8626#25221#30364#30365","text":"None of the above#release of his or her medical records#a search of his or her home#submit to a chemical test"},
{"testId":1046,"qid":25074,"description":"According to Iowa's Implied Consent Law, every driver who runs a vehicle on the state's highways has agreed to :","explanation":"The Implied Consent statute in Iowa compels any motorist to submit to a chemical test for alcohol or drugs if a peace officer requests it.","imageName":"","correctAnswer":30365,"isBookmarked":0,"aid":"8626#25221#30364#30365","text":"None of the above#release of his or her medical records#a search of his or her home#submit to a chemical test"},
{"testId":579,"qid":25075,"description":"A person is considered legally inebriated in Iowa if their blood alcohol content (BAC) is ___ or higher.聽:","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1046,"qid":25075,"description":"A person is considered legally inebriated in Iowa if their blood alcohol content (BAC) is ___ or higher.聽:","explanation":"If your blood alcohol content (BAC) is 0.08 percent or more, you are legally inebriated.","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":579,"qid":25077,"description":"What consequences will you face if you refuse to take a blood alcohol concentration (BAC) test?","explanation":"If you refuse to take a BAC test, your driver's license will be suspended for one to two years, you will be forced to attend a drinking driving course, and you will be evaluated for drug addiction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30366#30367#30368","text":"All of the above#One- to two-year license revocation#Required course on drinking drivers#Required substance abuse evaluation"},
{"testId":1046,"qid":25077,"description":"What consequences will you face if you refuse to take a blood alcohol concentration (BAC) test?","explanation":"If you refuse to take a BAC test, your driver's license will be suspended for one to two years, you will be forced to attend a drinking driving course, and you will be evaluated for drug addiction.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30366#30367#30368","text":"All of the above#One- to two-year license revocation#Required course on drinking drivers#Required substance abuse evaluation"},
{"testId":579,"qid":25078,"description":"First-time offenders will be sentenced to ___ 聽in jail for OWI (DUI).","explanation":"If you are convicted of OWI for the first time, you will face 48 hours in jail.","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"739#12361#12827#12828","text":"10 days#3 days#24 hours#48 hours"},
{"testId":1046,"qid":25078,"description":"First-time offenders will be sentenced to ___ 聽in jail for OWI (DUI).","explanation":"If you are convicted of OWI for the first time, you will face 48 hours in jail.","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"739#12361#12827#12828","text":"10 days#3 days#24 hours#48 hours"},
{"testId":579,"qid":25079,"description":"Second-time offenders will be sentenced to ___  in jail for OWI (DUI).","explanation":"If you are convicted of OWI for the second time, you will face 7 days in prison.","imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"12360#12361#12685#12829","text":"7 days#3 days#5 days#9 days"},
{"testId":1046,"qid":25079,"description":"Second-time offenders will be sentenced to ___  in jail for OWI (DUI).","explanation":"If you are convicted of OWI for the second time, you will face 7 days in prison.","imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"12360#12361#12685#12829","text":"7 days#3 days#5 days#9 days"},
{"testId":579,"qid":25080,"description":"If adriver under the age of 21 is convicted of OWI with a BAC more than 0.02 percent but less than 0.08 percent, his or her license will be suspended for________.","explanation":"If an Iowa driver under the age of 21 is convicted of OWI with a blood alcohol concentration (BAC) greater than 0.02 percent but less than 0.08 percent, his or her license will be suspended for 60 days.","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23884#23886#24048#27364","text":"15 days#30 days#60 days#45 days"},
{"testId":1046,"qid":25080,"description":"If adriver under the age of 21 is convicted of OWI with a BAC more than 0.02 percent but less than 0.08 percent, his or her license will be suspended for________.","explanation":"If an Iowa driver under the age of 21 is convicted of OWI with a blood alcohol concentration (BAC) greater than 0.02 percent but less than 0.08 percent, his or her license will be suspended for 60 days.","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23884#23886#24048#27364","text":"15 days#30 days#60 days#45 days"},
{"testId":579,"qid":25082,"description":"An open container of alcohol may be legally kept:","explanation":"An open container of alcohol may not be stored anywhere in the passenger compartment of a vehicle.","imageName":"","correctAnswer":30372,"isBookmarked":0,"aid":"30369#30370#30371#30372","text":"in the glove compartment#under the seat#in a passenger's lap#in the trunk"},
{"testId":1046,"qid":25082,"description":"An open container of alcohol may be legally kept:","explanation":"An open container of alcohol may not be stored anywhere in the passenger compartment of a vehicle.","imageName":"","correctAnswer":30372,"isBookmarked":0,"aid":"30369#30370#30371#30372","text":"in the glove compartment#under the seat#in a passenger's lap#in the trunk"},
{"testId":579,"qid":25083,"description":"In addition to a reinstatement fee, you will have to pay a civil penalty for which of the following?","explanation":"If you are convicted of OWI, have a BAC of 0.08 percent or above, or refuse to submit to chemical testing, you must pay a civil penalty of $200, as well as a reinstatement fee, before your driver's license may be restored.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30373#30374#30375","text":"All of the above#Refusal to submit to chemical testing#A blood alcohol concentration (BAC) of 0.08% or greater#An OWI conviction"},
{"testId":1046,"qid":25083,"description":"In addition to a reinstatement fee, you will have to pay a civil penalty for which of the following?","explanation":"If you are convicted of OWI, have a BAC of 0.08 percent or above, or refuse to submit to chemical testing, you must pay a civil penalty of $200, as well as a reinstatement fee, before your driver's license may be restored.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30373#30374#30375","text":"All of the above#Refusal to submit to chemical testing#A blood alcohol concentration (BAC) of 0.08% or greater#An OWI conviction"},
{"testId":579,"qid":25084,"description":"What is the maximum punishment for fleeing the scene of an accident that resulted in a fatality?","explanation":"In Iowa, the maximum penalty for leaving the scene of a fatal accident is a $5,000 fine and up to two years in prison.","imageName":"","correctAnswer":30378,"isBookmarked":0,"aid":"30376#30377#30378#30379","text":"$1,000 fine and 1 year in jail#$5,000 fine and 1 year in jail#$5,000 fine and 2 years in jail#$5,000 fine and 3 years in jail"},
{"testId":1046,"qid":25084,"description":"What is the maximum punishment for fleeing the scene of an accident that resulted in a fatality?","explanation":"In Iowa, the maximum penalty for leaving the scene of a fatal accident is a $5,000 fine and up to two years in prison.","imageName":"","correctAnswer":30378,"isBookmarked":0,"aid":"30376#30377#30378#30379","text":"$1,000 fine and 1 year in jail#$5,000 fine and 1 year in jail#$5,000 fine and 2 years in jail#$5,000 fine and 3 years in jail"},
{"testId":579,"qid":25085,"description":"If you are involved in an accident that causes bodily harm, death, or more than $1,500 in property damage, you must file an accident report as soon as possible.","explanation":"If you are involved in an accident that causes injury, death, or property damage of more than $1,500, you must submit an accident report within 72 hours. Your license may be suspended if you do not comply. However, if a peace officer examines the accident and files a report, you will not be required to file.","imageName":"202006141611292838.jpg","correctAnswer":20755,"isBookmarked":0,"aid":"20753#20755#23883#23886","text":"24 hours#72 hours#10 days#30 days"},
{"testId":579,"qid":25086,"description":"Iowa's financial responsibility law says that every vehicle must have at least _____ worth of property damage liability coverage on its insurance policy.","explanation":"According to Iowa's financial responsibility law, each vehicle must be insured with at least $15,000 in property damage liability coverage.","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11659#12047#12048#12194","text":"$20,000#$15,000#$25,000#$40,000"},
{"testId":579,"qid":25087,"description":"If you opt not to insure your own vehicle, you must provide cash, a surety bond, or assets in the amount of:","explanation":"If you choose not to insure a vehicle that you own, you must file $55,000 in cash, a surety bond, or securities.","imageName":"","correctAnswer":30382,"isBookmarked":0,"aid":"30380#30381#30382#30383","text":"$100,000#$75,000#$55,000#$45,000"},
{"testId":1046,"qid":25087,"description":"If you opt not to insure your own vehicle, you must provide cash, a surety bond, or assets in the amount of:","explanation":"If you choose not to insure a vehicle that you own, you must file $55,000 in cash, a surety bond, or securities.","imageName":"","correctAnswer":30382,"isBookmarked":0,"aid":"30380#30381#30382#30383","text":"$100,000#$75,000#$55,000#$45,000"},
{"testId":579,"qid":25088,"description":"After being convicted of OWI (DUI), you can't get a work permit for at least:","explanation":"If your license was suspended or revoked as a result of an OWI conviction, you may apply for a work permit after a minimum of 30 days to one year, depending on your past record.","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":1046,"qid":25088,"description":"After being convicted of OWI (DUI), you can't get a work permit for at least:","explanation":"If your license was suspended or revoked as a result of an OWI conviction, you may apply for a work permit after a minimum of 30 days to one year, depending on your past record.","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":579,"qid":25089,"description":"Which of the following adults is required by Iowa law to use a seat belt?","explanation":"According to Iowa law, the driver, all front-seat passengers, and all passengers under the age of 18 must use a safety belt or appropriate child restraint. It is, nevertheless, strongly advised that every passenger in the vehicle be properly strapped. Child restraints and safety belts do save lives.","imageName":"","correctAnswer":26667,"isBookmarked":0,"aid":"4420#26667#30384#30385","text":"Only the driver#The driver and front-seat passengers#Only the passengers#Only rear-seat passengers"},
{"testId":579,"qid":25090,"description":"A child under the age of ___ must be restrained by a child safety restraint device in Iowa.","explanation":"Each kid under the age of six must be restrained by a child safety restraint device, according to Iowa law.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":579,"qid":25091,"description":"A basic driver's license is class __ in Iowa.","explanation":"A class C driver's license permits you to operate passenger vehicles, pickup trucks, and trucks weighing less than 16,000 pounds.","imageName":"","correctAnswer":20284,"isBookmarked":0,"aid":"20282#20283#20284#30386","text":"A#B#C#D"},
{"testId":579,"qid":25092,"description":"Before obtaining for a full driver's license, a young Iowa driver with an intermediate license must drive accident- and violation-free for _______.","explanation":"To apply for a full driver's license under the Graduated Driver's License (GDL) system, an Iowa driver must have an intermediate license for 12 consecutive months without any traffic convictions or accidents.","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#742#1272#1273","text":"90 days#30 days#6 months#12 months"},
{"testId":579,"qid":25093,"description":"How long does a driver in Iowa have to have an instructional permit before he or she can apply for an intermediate license?","explanation":"An Iowa driver must hold an instructional permit for at least 12 months before applying for an intermediate licence. The final 6 months of those 12 months must be accident- and violation-free.","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1454#11285","text":"6 months#12 months#9 months#2 years"},
{"testId":579,"qid":25094,"description":"The Iowa Graduated Driver's License (GDL) system applies to drivers aged ____ and under.","explanation":"The Graduated Driver's License (GDL) system in Iowa applies to drivers aged 18 and younger.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":579,"qid":25095,"description":"In which of the following case a driver under the age of 18 year will be reffered to the Remedial Driver Improvement Program?","explanation":"If an Iowa driver under the age of 18 is convicted of a moving violation or is involved in an accident that he or she caused, he or she will be referred to the Remedial Driver Improvement program.","imageName":"","correctAnswer":30389,"isBookmarked":0,"aid":"30387#30388#30389#30390","text":"If the driver has dropped out of school before age 18#If the driver has failed to obtain parental consent#If the driver is involved in one moving violation or an accident to which he or she contributed#For none of the above reasons"},
{"testId":1046,"qid":25095,"description":"In which of the following case a driver under the age of 18 year will be reffered to the Remedial Driver Improvement Program?","explanation":"If an Iowa driver under the age of 18 is convicted of a moving violation or is involved in an accident that he or she caused, he or she will be referred to the Remedial Driver Improvement program.","imageName":"","correctAnswer":30389,"isBookmarked":0,"aid":"30387#30388#30389#30390","text":"If the driver has dropped out of school before age 18#If the driver has failed to obtain parental consent#If the driver is involved in one moving violation or an accident to which he or she contributed#For none of the above reasons"},
{"testId":580,"qid":25096,"description":"If you are under the age of 17, you must possess an instructional permit for ________ or until you reach the age of 17 before applying for a driver's license.","explanation":"If you are under the age of 17, you must possess an instructional permit for one year or until you reach the age of 17 before applying for a driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11301","text":"30 days#1 year#6 months#18 months"},
{"testId":1047,"qid":25096,"description":"If you are under the age of 17, you must possess an instructional permit for ________ or until you reach the age of 17 before applying for a driver's license.","explanation":"If you are under the age of 17, you must possess an instructional permit for one year or until you reach the age of 17 before applying for a driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11301","text":"30 days#1 year#6 months#18 months"},
{"testId":580,"qid":25097,"description":"If you are under the age of 17, you must have ____ hours of driving experience before you may get your driver's license.","explanation":"Before you may apply for a driver's license, you must have 50 hours of driving experience under the supervision of a parent, guardian, driving instructor, or other adult 21 years of age or older if you are under the age of 17. Ten of the fifty hours must be worked at night. [Kansas Driving Handbook, Age Requirements]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6425#6591#7569#7571","text":"25#100#75#50"},
{"testId":1047,"qid":25097,"description":"If you are under the age of 17, you must have ____ hours of driving experience before you may get your driver's license.","explanation":"Before you may apply for a driver's license, you must have 50 hours of driving experience under the supervision of a parent, guardian, driving instructor, or other adult 21 years of age or older if you are under the age of 17. Ten of the fifty hours must be worked at night. [Kansas Driving Handbook, Age Requirements]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6425#6591#7569#7571","text":"25#100#75#50"},
{"testId":580,"qid":25098,"description":"In Kansas, if the driver's age is between 21 years to 64 years, his or her driver's license will be valid for:","explanation":"A Kansas driver's license is valid for 6 years if he or she is between the ages of 21 and 64. [K.S.A. 8-247]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":1047,"qid":25098,"description":"In Kansas, if the driver's age is between 21 years to 64 years, his or her driver's license will be valid for:","explanation":"A Kansas driver's license is valid for 6 years if he or she is between the ages of 21 and 64. [K.S.A. 8-247]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":580,"qid":25099,"description":"If a Kansas driver is under the age of 21, his or her driver's license will expire:","explanation":"If a Kansas driver is under the age of 21, his or her license will expire on the person's 21st birthday. [K.S.A. 8-247]","imageName":"","correctAnswer":30673,"isBookmarked":0,"aid":"30671#30672#30673#30674","text":"in 3 years#in 1 year#on the driver's 21st birthday#on the driver's 18th birthday"},
{"testId":1047,"qid":25099,"description":"If a Kansas driver is under the age of 21, his or her driver's license will expire:","explanation":"If a Kansas driver is under the age of 21, his or her license will expire on the person's 21st birthday. [K.S.A. 8-247]","imageName":"","correctAnswer":30673,"isBookmarked":0,"aid":"30671#30672#30673#30674","text":"in 3 years#in 1 year#on the driver's 21st birthday#on the driver's 18th birthday"},
{"testId":580,"qid":25100,"description":"What is the minimum fee to get a driver's license back after it has been taken away because of a conviction related to alcohol?","explanation":"If a driver's license in Kansas is suspended due to an alcohol-related offense, the reinstatement cost will be at least $100. [K.S.A. 8-241]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":1047,"qid":25100,"description":"What is the minimum fee to get a driver's license back after it has been taken away because of a conviction related to alcohol?","explanation":"If a driver's license in Kansas is suspended due to an alcohol-related offense, the reinstatement cost will be at least $100. [K.S.A. 8-241]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":580,"qid":25101,"description":"To apply for a driver's license if you are under the age of _____, you must have written authorization from a parent or legal guardian.","explanation":"Before applying for a driver's license, you must receive written permission from a parent or legal guardian if you are under the age of 18. [Kansas Driving Handbook, About This Manual; Section 1: The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#11359#11360#11696","text":"20#18#21#19"},
{"testId":1047,"qid":25101,"description":"To apply for a driver's license if you are under the age of _____, you must have written authorization from a parent or legal guardian.","explanation":"Before applying for a driver's license, you must receive written permission from a parent or legal guardian if you are under the age of 18. [Kansas Driving Handbook, About This Manual; Section 1: The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#11359#11360#11696","text":"20#18#21#19"},
{"testId":580,"qid":25102,"description":"A Kansas instructional permit enables you to drive under the supervision of a licensed driver who is:","explanation":"If you have an instructional permit, you may drive at any time as long as you are supervised by a licensed driver who is 21 or older. [The Driver's License, Kansas Driving Handbook, Driver License Restrictions]","imageName":"","correctAnswer":30676,"isBookmarked":0,"aid":"30675#30676#30677#30678","text":"a parent#21 years of age or older#25 years of age or older#a certified driving instructor"},
{"testId":580,"qid":25103,"description":"During a traffic stop, you must present a law enforcement officer with _______.","explanation":"When pulled over for a traffic violation, you must provide your driver's license, current proof of insurance, and car registration to a law enforcement official. [Police/Traffic Stops, Kansas Driving Handbook, Section 9: Sharing the Road]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#29931#29932#29933","text":"All of the above#your driver's license#current proof of insurance#your vehicle's registration"},
{"testId":1047,"qid":25103,"description":"During a traffic stop, you must present a law enforcement officer with _______.","explanation":"When pulled over for a traffic violation, you must provide your driver's license, current proof of insurance, and car registration to a law enforcement official. [Police/Traffic Stops, Kansas Driving Handbook, Section 9: Sharing the Road]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#29931#29932#29933","text":"All of the above#your driver's license#current proof of insurance#your vehicle's registration"},
{"testId":580,"qid":25104,"description":"What is the penalty for not renewing a Kansas driver's license before it expires?","explanation":"If you do not renew your license before the expiry date, you will be fined $1.00. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12862,"isBookmarked":0,"aid":"11348#11349#11351#12862","text":"$25#$50#$10#$1"},
{"testId":1047,"qid":25104,"description":"What is the penalty for not renewing a Kansas driver's license before it expires?","explanation":"If you do not renew your license before the expiry date, you will be fined $1.00. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12862,"isBookmarked":0,"aid":"11348#11349#11351#12862","text":"$25#$50#$10#$1"},
{"testId":580,"qid":25105,"description":"Which of the following could cause you to lose your driving privileges?","explanation":"Your driver's license may be revoked if you are convicted of vehicular murder, leaving the scene of an accident, or trying to evade an official, among other offenses. [You may have your driving privileges suspended or revoked. Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23204#27916#30679","text":"All of the above#Leaving the scene of an accident#Vehicular homicide#Attempting to elude a police officer"},
{"testId":580,"qid":25106,"description":"Which of the following will NOT result in your driver's license being revoked immediately?","explanation":"A single ticket for failing to yield normally does not result in license revocation. [The Driver's License, Kansas Driving Handbook] [Your Driving Privileges May Be Suspended or Revoked]","imageName":"","correctAnswer":24984,"isBookmarked":0,"aid":"897#11621#24984#30680","text":"None of the above#Reckless driving#Failure to yield#Vehicular battery"},
{"testId":580,"qid":25107,"description":"How many moving violations within a twelve-month period may result in your driver's license being suspended?","explanation":"Your driver's license may be suspended if you are convicted of three driving offences during a twelve-month period. [K.S.A. 8-255; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":1047,"qid":25107,"description":"How many moving violations within a twelve-month period may result in your driver's license being suspended?","explanation":"Your driver's license may be suspended if you are convicted of three driving offences during a twelve-month period. [K.S.A. 8-255; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#10547#11417","text":"Three#Four#Two#Five"},
{"testId":580,"qid":25108,"description":"Your license will be suspended for _______ if you refuse to submit to a chemical test.","explanation":"If you refuse to submit to a chemical test for drugs or alcohol when a law enforcement officer requests it, your license will be suspended for a year. [K.S.A. 8-1001; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1047,"qid":25108,"description":"Your license will be suspended for _______ if you refuse to submit to a chemical test.","explanation":"If you refuse to submit to a chemical test for drugs or alcohol when a law enforcement officer requests it, your license will be suspended for a year. [K.S.A. 8-1001; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":580,"qid":25109,"description":"Your license will be suspended___________, if you fail to appear in court for a traffic violation.","explanation":"If you do not appear in court for a traffic penalty, your license will be terminated forever. When you address the charge, the suspension will be lifted. [K.S.A. 8-2110; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":23184,"isBookmarked":0,"aid":"11612#11613#23184#23185","text":"for 6 months#for 1 year#until the matter is settled#for 30 days"},
{"testId":1047,"qid":25109,"description":"Your license will be suspended___________, if you fail to appear in court for a traffic violation.","explanation":"If you do not appear in court for a traffic penalty, your license will be terminated forever. When you address the charge, the suspension will be lifted. [K.S.A. 8-2110; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":23184,"isBookmarked":0,"aid":"11612#11613#23184#23185","text":"for 6 months#for 1 year#until the matter is settled#for 30 days"},
{"testId":580,"qid":25110,"description":"Which of the following will result in an indefinite suspension of your license?","explanation":"If you are convicted of failing to maintain adequate car insurance or driving without insurance, your license will be terminated forever. [K.S.A. 40-3104; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":30682,"isBookmarked":0,"aid":"897#29906#30681#30682","text":"None of the above#Failure to use headlights#One speeding conviction#Failure to maintain required insurance"},
{"testId":1047,"qid":25110,"description":"Which of the following will result in an indefinite suspension of your license?","explanation":"If you are convicted of failing to maintain adequate car insurance or driving without insurance, your license will be terminated forever. [K.S.A. 40-3104; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":30682,"isBookmarked":0,"aid":"897#29906#30681#30682","text":"None of the above#Failure to use headlights#One speeding conviction#Failure to maintain required insurance"},
{"testId":580,"qid":25111,"description":"Which of the following will force you to lose your license?","explanation":"Your driver's license will be suspended if you are convicted of driving under the influence of drugs or carrying an open container of any alcoholic beverage. [K.S.A. 8-1567; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30683#30684#30685","text":"All of the above#Transporting an open container of liquor#Transporting an open container of beer#Driving under the influence of drugs"},
{"testId":580,"qid":25112,"description":"Kansas' implied consent legislation stipulates that every motorist who runs a motor vehicle inside the state must :","explanation":"Under Kansas' implied consent legislation, every motorist who runs a vehicle inside the state must submit to a chemical test if law enforcement requests it. [K.S.A. 8-1001; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":30687,"isBookmarked":0,"aid":"10656#30686#30687#30688","text":"Do all of the above#release his or her medical records to the state#submit to a chemical test upon request#complete high school"},
{"testId":1047,"qid":25112,"description":"Kansas' implied consent legislation stipulates that every motorist who runs a motor vehicle inside the state must :","explanation":"Under Kansas' implied consent legislation, every motorist who runs a vehicle inside the state must submit to a chemical test if law enforcement requests it. [K.S.A. 8-1001; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":30687,"isBookmarked":0,"aid":"10656#30686#30687#30688","text":"Do all of the above#release his or her medical records to the state#submit to a chemical test upon request#complete high school"},
{"testId":580,"qid":25113,"description":"If you are under the age of 21, you can be arrested for alcohol impairment in Kansas if your blood alcohol content (BAC) is _____ or higher.","explanation":"If you are under the age of 21, you may be arrested for alcohol impairment in Kansas if your blood alcohol content (BAC) is 0.02 percent or higher. The least level detected by today's most routinely used chemical testing is 0.02 percent. [The Driver's License, Kansas Driving Handbook, Alcohol and the Law]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1047,"qid":25113,"description":"If you are under the age of 21, you can be arrested for alcohol impairment in Kansas if your blood alcohol content (BAC) is _____ or higher.","explanation":"If you are under the age of 21, you may be arrested for alcohol impairment in Kansas if your blood alcohol content (BAC) is 0.02 percent or higher. The least level detected by today's most routinely used chemical testing is 0.02 percent. [The Driver's License, Kansas Driving Handbook, Alcohol and the Law]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":580,"qid":25114,"description":"If your BAC is 0.08 percent or higher, your license may be suspended for:","explanation":"If you are arrested for driving with a blood alcohol content (BAC) of 0.08 percent or above, your license may be revoked for 30 days and you may face criminal charges. [The Driver's License, Kansas Driving Handbook, Alcohol and the Law]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1047,"qid":25114,"description":"If your BAC is 0.08 percent or higher, your license may be suspended for:","explanation":"If you are arrested for driving with a blood alcohol content (BAC) of 0.08 percent or above, your license may be revoked for 30 days and you may face criminal charges. [The Driver's License, Kansas Driving Handbook, Alcohol and the Law]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":580,"qid":25115,"description":"Within five years of a second DUI, alcohol- or drug-related offense, or refusal charge, your license will be suspended for :","explanation":"If you are convicted of two or more DUI offenses, other drug or alcohol offenses, or refusal offenses within five years, your license will be suspended for one year. After that, you will only be permitted to drive a car fitted with an ignition interlock device for many years (IID). If your blood alcohol content (BAC) reaches a certain threshold, this device will prohibit the car from starting. [K.S.A. 8-1014; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":1047,"qid":25115,"description":"Within five years of a second DUI, alcohol- or drug-related offense, or refusal charge, your license will be suspended for :","explanation":"If you are convicted of two or more DUI offenses, other drug or alcohol offenses, or refusal offenses within five years, your license will be suspended for one year. After that, you will only be permitted to drive a car fitted with an ignition interlock device for many years (IID). If your blood alcohol content (BAC) reaches a certain threshold, this device will prohibit the car from starting. [K.S.A. 8-1014; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":580,"qid":25116,"description":"You can talk to your lawyer _____ a chemical test.","explanation":"There is no constitutional right to consult a counsel before submitting to chemical testing. However, following the exam, you may contact with an attorney. [K.S.A. 8-1001; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":8282,"isBookmarked":0,"aid":"8281#8282#8283#30689","text":"before#after#while#both before and after"},
{"testId":1047,"qid":25116,"description":"You can talk to your lawyer _____ a chemical test.","explanation":"There is no constitutional right to consult a counsel before submitting to chemical testing. However, following the exam, you may contact with an attorney. [K.S.A. 8-1001; The Driver's License; Kansas Driving Handbook]","imageName":"","correctAnswer":8282,"isBookmarked":0,"aid":"8281#8282#8283#30689","text":"before#after#while#both before and after"},
{"testId":580,"qid":25117,"description":"All children under the age of ____ must be strapped in a child safety seat in Kansas.","explanation":"All children under the age of four must be restrained in child safety seats in Kansas. Children aged 4 to 8 must be restrained in a child safety seat or booster seat unless they are taller than 4' 9\" or weigh more over 80 pounds, in which case they must use seat belts. [Kansas Driving Handbook, Child Restraints, State/Provincial/Territory Laws and Rules of the Road]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1047,"qid":25117,"description":"All children under the age of ____ must be strapped in a child safety seat in Kansas.","explanation":"All children under the age of four must be restrained in child safety seats in Kansas. Children aged 4 to 8 must be restrained in a child safety seat or booster seat unless they are taller than 4' 9\" or weigh more over 80 pounds, in which case they must use seat belts. [Kansas Driving Handbook, Child Restraints, State/Provincial/Territory Laws and Rules of the Road]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":580,"qid":25118,"description":"Which vehicle occupants in Kansas are required to wear safety restraints (seat belts or kid restraints)?","explanation":"All vehicle passengers in Kansas are required by law to wear safety restraints (seat belts or child restraints) at all times. If there is an unrestrained adult in the front seat or an unrestrained youngster elsewhere in the car, you may be pulled over and charged. [Seatbelts, State/Provincial/Territory Traffic Laws and Rules, Kansas Driving Handbook]","imageName":"","correctAnswer":30692,"isBookmarked":0,"aid":"25185#30690#30691#30692","text":"The driver only#The driver and front-seat passengers only#Underage rear-seat passengers only#All vehicle occupants"},
{"testId":1047,"qid":25118,"description":"Which vehicle occupants in Kansas are required to wear safety restraints (seat belts or kid restraints)?","explanation":"All vehicle passengers in Kansas are required by law to wear safety restraints (seat belts or child restraints) at all times. If there is an unrestrained adult in the front seat or an unrestrained youngster elsewhere in the car, you may be pulled over and charged. [Seatbelts, State/Provincial/Territory Traffic Laws and Rules, Kansas Driving Handbook]","imageName":"","correctAnswer":30692,"isBookmarked":0,"aid":"25185#30690#30691#30692","text":"The driver only#The driver and front-seat passengers only#Underage rear-seat passengers only#All vehicle occupants"},
{"testId":580,"qid":25119,"description":"Which of the following can lead to a DUI charge?","explanation":"Illegal substances, certain prescription medicines, some OTC medications, alcohol, and marijuana may all impair your ability to drive safely. In Kansas, using any of these may result in a DUI arrest. If you have any questions regarding the side effects of a prescription or over-the-counter medicine, read the warning label or see your pharmacist or doctor. [Kansas Driving Handbook, Alcohol, Other Drugs, and Driving, Chapter 3: Be in Shape to Drive]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8071#9803#29384","text":"All of the above#Alcohol#Over-the-counter medications#Prescription medications"},
{"testId":580,"qid":25120,"description":"For how聽many hours of community service could you be sentenced to for your first DUI conviction?","explanation":"For your first DUI conviction, you might face up to 48 hours in jail or 100 hours of community service. [Kansas Driving Handbook, Alcohol and the Law, Chapter 3: Be in Shape to Drive; Alcohol, Other Drugs, and Driving, Chapter 3: Be in Shape to Drive]","imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"6425#6591#7569#7571","text":"25#100#75#50"},
{"testId":1047,"qid":25120,"description":"For how聽many hours of community service could you be sentenced to for your first DUI conviction?","explanation":"For your first DUI conviction, you might face up to 48 hours in jail or 100 hours of community service. [Kansas Driving Handbook, Alcohol and the Law, Chapter 3: Be in Shape to Drive; Alcohol, Other Drugs, and Driving, Chapter 3: Be in Shape to Drive]","imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"6425#6591#7569#7571","text":"25#100#75#50"},
{"testId":580,"qid":25121,"description":"An accident that appears to inflict at least ______ in property damage must be reported as quickly as possible to the local police department.","explanation":"If you are engaged in an accident that seems to produce at least $1,000 in property damage, you must report it as quickly as possible to a police officer on the site or the local police station. [K.S.A. 8-1604]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#11668#11669#11670","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":1047,"qid":25121,"description":"An accident that appears to inflict at least ______ in property damage must be reported as quickly as possible to the local police department.","explanation":"If you are engaged in an accident that seems to produce at least $1,000 in property damage, you must report it as quickly as possible to a police officer on the site or the local police station. [K.S.A. 8-1604]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#11668#11669#11670","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":580,"qid":25122,"description":"You can renew your driver's license up to ________ before it expires.","explanation":"A driver's license in Kansas can be renewed up to twelve months before it expires. [Kansas Driving Handbook, Driver License Renewal, Chapter 1: The Driver's License]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":1047,"qid":25122,"description":"You can renew your driver's license up to ________ before it expires.","explanation":"A driver's license in Kansas can be renewed up to twelve months before it expires. [Kansas Driving Handbook, Driver License Renewal, Chapter 1: The Driver's License]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":580,"qid":25124,"description":"Who does not need permission from parent or guardian for an instructional permit?","explanation":"A 16-year-old or older application for an instructional permit is exempt from needing parental or guardian consent. [Kansas Driving Handbook, Types of Driver's Licenses and Driver's Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30694,"isBookmarked":0,"aid":"23778#23779#30693#30694","text":"14 to 15 years of age#14 to 18 years of age#14 to 15 1/2 years of age#16 years of age or older"},
{"testId":1047,"qid":25124,"description":"Who does not need permission from parent or guardian for an instructional permit?","explanation":"A 16-year-old or older application for an instructional permit is exempt from needing parental or guardian consent. [Kansas Driving Handbook, Types of Driver's Licenses and Driver's Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30694,"isBookmarked":0,"aid":"23778#23779#30693#30694","text":"14 to 15 years of age#14 to 18 years of age#14 to 15 1/2 years of age#16 years of age or older"},
{"testId":580,"qid":25125,"description":"Except in an emergency, a motorist between the years of _______ and holding an instructional permit is not permitted to use a cell phone while driving.","explanation":"A motorist aged 14 to 16 with an instructional permit is not permitted to use a mobile phone while driving, save in an emergency. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12880,"isBookmarked":0,"aid":"12009#12572#12880#12881","text":"14 and 15#16 and 21#14 and 16#14 and 21"},
{"testId":1047,"qid":25125,"description":"Except in an emergency, a motorist between the years of _______ and holding an instructional permit is not permitted to use a cell phone while driving.","explanation":"A motorist aged 14 to 16 with an instructional permit is not permitted to use a mobile phone while driving, save in an emergency. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12880,"isBookmarked":0,"aid":"12009#12572#12880#12881","text":"14 and 15#16 and 21#14 and 16#14 and 21"},
{"testId":580,"qid":25127,"description":"A restricted license allows 16-year-olds to drive unsupervised:","explanation":"A limited driver's license allows you to drive unattended to and from employment, school, or religious worship services if you are 16 or older. Driving for any other reason will need the supervision of a licensed adult driver. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30697,"isBookmarked":0,"aid":"30695#30696#30697#30698","text":"to and from work only#to and from school only#to and from work or school#to and from work, school, or entertainment"},
{"testId":1047,"qid":25127,"description":"A restricted license allows 16-year-olds to drive unsupervised:","explanation":"A limited driver's license allows you to drive unattended to and from employment, school, or religious worship services if you are 16 or older. Driving for any other reason will need the supervision of a licensed adult driver. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30697,"isBookmarked":0,"aid":"30695#30696#30697#30698","text":"to and from work only#to and from school only#to and from work or school#to and from work, school, or entertainment"},
{"testId":580,"qid":25128,"description":"A motorist under the age of 16 holding a restricted driver's license may operate a vehicle between the hours of:","explanation":"Between the hours of 5:00 a.m. and 5:00 p.m., a 16-year-old Kansas driver with a limited driver's license may operate a vehicle for any purpose. and 9:00 p.m. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12886,"isBookmarked":0,"aid":"12886#12887#30699#30700","text":"5:00 a.m. and 9:00 p.m#5:00 a.m. and 11:00 p.m#5:00 a.m. and midnight#6:00 a.m. and midnight"},
{"testId":1047,"qid":25128,"description":"A motorist under the age of 16 holding a restricted driver's license may operate a vehicle between the hours of:","explanation":"Between the hours of 5:00 a.m. and 5:00 p.m., a 16-year-old Kansas driver with a limited driver's license may operate a vehicle for any purpose. and 9:00 p.m. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":12886,"isBookmarked":0,"aid":"12886#12887#30699#30700","text":"5:00 a.m. and 9:00 p.m#5:00 a.m. and 11:00 p.m#5:00 a.m. and midnight#6:00 a.m. and midnight"},
{"testId":580,"qid":25129,"description":"A 16-year-old motorist with a restricted driver's license may carry up to ____ minor passengers who are not direct family members (s).","explanation":"A 16-year-old driver with a limited license is only permitted to carry one minor passenger who is not an immediate relative. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1047,"qid":25129,"description":"A 16-year-old motorist with a restricted driver's license may carry up to ____ minor passengers who are not direct family members (s).","explanation":"A 16-year-old driver with a limited license is only permitted to carry one minor passenger who is not an immediate relative. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":580,"qid":25130,"description":"Texting and driving is ______ in Kansas.","explanation":"Texting and driving is currently the biggest cause of mortality among teens, accounting for 25% of all vehicle accidents in the United States. It is unlawful in Kansas to send text texts while driving. [Kansas Driving Handbook, Age Requirements]","imageName":"","correctAnswer":30702,"isBookmarked":0,"aid":"30701#30702#30703#30704","text":"legal#illegal#legal only for drivers over 21#legal if you do not exceed 15 mph"},
{"testId":1047,"qid":25130,"description":"Texting and driving is ______ in Kansas.","explanation":"Texting and driving is currently the biggest cause of mortality among teens, accounting for 25% of all vehicle accidents in the United States. It is unlawful in Kansas to send text texts while driving. [Kansas Driving Handbook, Age Requirements]","imageName":"","correctAnswer":30702,"isBookmarked":0,"aid":"30701#30702#30703#30704","text":"legal#illegal#legal only for drivers over 21#legal if you do not exceed 15 mph"},
{"testId":580,"qid":25131,"description":"Every four years, drivers _____ and older must re-apply for their driver's licenses.","explanation":"Drivers in Kansas must begin renewing their licences every four years once they reach the age of 65. [K.S.A. 8-247]","imageName":"","correctAnswer":9657,"isBookmarked":0,"aid":"7571#9656#9657#11360","text":"50#70#65#21"},
{"testId":1047,"qid":25131,"description":"Every four years, drivers _____ and older must re-apply for their driver's licenses.","explanation":"Drivers in Kansas must begin renewing their licences every four years once they reach the age of 65. [K.S.A. 8-247]","imageName":"","correctAnswer":9657,"isBookmarked":0,"aid":"7571#9656#9657#11360","text":"50#70#65#21"},
{"testId":580,"qid":25132,"description":"A motorist between the ages of 14 and 16 who lives or works on a farm of at least ____ acres may be given a Kansas farm permit.","explanation":"A motorist between the ages of 14 and 16 who lives or works on a farm of at least 20 acres may be given a Kansas farm permit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":5448,"isBookmarked":0,"aid":"5448#6477#6591#7570","text":"20#30#100#200"},
{"testId":1047,"qid":25132,"description":"A motorist between the ages of 14 and 16 who lives or works on a farm of at least ____ acres may be given a Kansas farm permit.","explanation":"A motorist between the ages of 14 and 16 who lives or works on a farm of at least 20 acres may be given a Kansas farm permit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":5448,"isBookmarked":0,"aid":"5448#6477#6591#7570","text":"20#30#100#200"},
{"testId":580,"qid":25133,"description":"What is the minimum age for a Kansas resident to apply for an instructional permit?","explanation":"At the age of 14, a Kansas citizen may apply for an instructional permit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":1047,"qid":25133,"description":"What is the minimum age for a Kansas resident to apply for an instructional permit?","explanation":"At the age of 14, a Kansas citizen may apply for an instructional permit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":580,"qid":25135,"description":"Which of the following is required when a 15-year-old Kansas driver applies for a limited driver's license?","explanation":"A 15-year-old Kansas driver applying for a limited driver's license must get parental or guardian agreement, complete a driver's education course, and pass a vision exam, among other criteria. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30705#30706#30707","text":"All of the above#Consent from a parent or guardian#Completion of a driver's education course#A vision test"},
{"testId":1047,"qid":25135,"description":"Which of the following is required when a 15-year-old Kansas driver applies for a limited driver's license?","explanation":"A 15-year-old Kansas driver applying for a limited driver's license must get parental or guardian agreement, complete a driver's education course, and pass a vision exam, among other criteria. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30705#30706#30707","text":"All of the above#Consent from a parent or guardian#Completion of a driver's education course#A vision test"},
{"testId":580,"qid":25136,"description":"In Kansas, what can you do to prove the requirement of 50 hours of driving experience?","explanation":"Anyone who oversaw the driver's 50 hours of driving experience must sign an affidavit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30709,"isBookmarked":0,"aid":"897#30708#30709#30710","text":"None of the above#Word of mouth#A signed affidavit#A handwritten log"},
{"testId":1047,"qid":25136,"description":"In Kansas, what can you do to prove the requirement of 50 hours of driving experience?","explanation":"Anyone who oversaw the driver's 50 hours of driving experience must sign an affidavit. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30709,"isBookmarked":0,"aid":"897#30708#30709#30710","text":"None of the above#Word of mouth#A signed affidavit#A handwritten log"},
{"testId":580,"qid":25137,"description":"The process through which Kansas introduces novice drivers to more challenging driving scenarios as they acquire expertise is known as :","explanation":"Graduated Driver Licensing (GDL) is Kansas' strategy for gradually introducing new drivers to increasingly challenging driving scenarios as they acquire experience. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30711,"isBookmarked":0,"aid":"28302#30711#30712#30713","text":"Underage Driver Program (UDP)#Graduated Driver's Licensing (GDL)#Graduated Driver Program (GDP)#Underage Driver's Licensing (UDL)"},
{"testId":1047,"qid":25137,"description":"The process through which Kansas introduces novice drivers to more challenging driving scenarios as they acquire expertise is known as :","explanation":"Graduated Driver Licensing (GDL) is Kansas' strategy for gradually introducing new drivers to increasingly challenging driving scenarios as they acquire experience. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30711,"isBookmarked":0,"aid":"28302#30711#30712#30713","text":"Underage Driver Program (UDP)#Graduated Driver's Licensing (GDL)#Graduated Driver Program (GDP)#Underage Driver's Licensing (UDL)"},
{"testId":580,"qid":25138,"description":"If you fail either the written or driving tests, you may retake them the following working day for a charge of :","explanation":"If you fail either the written or driving tests, you may retake them the next working day for a $1.50 re-exam charge. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30714,"isBookmarked":0,"aid":"13459#13480#13481#30714","text":"$10.00#$1.00#$5.00#$1.50"},
{"testId":1047,"qid":25138,"description":"If you fail either the written or driving tests, you may retake them the following working day for a charge of :","explanation":"If you fail either the written or driving tests, you may retake them the next working day for a $1.50 re-exam charge. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30714,"isBookmarked":0,"aid":"13459#13480#13481#30714","text":"$10.00#$1.00#$5.00#$1.50"},
{"testId":580,"qid":25139,"description":"You will be able to get a farm permit in Kansas at the age of 15, which will allow you to drive:","explanation":"A Kansas driver who is 15 years old and has a farm permit may drive to and from school and for farm-related tasks on the most direct route. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30716,"isBookmarked":0,"aid":"30696#30715#30716#30717","text":"to and from school only#for farm-related work only#to and from school or for farm-related work#for other purposes"},
{"testId":1047,"qid":25139,"description":"You will be able to get a farm permit in Kansas at the age of 15, which will allow you to drive:","explanation":"A Kansas driver who is 15 years old and has a farm permit may drive to and from school and for farm-related tasks on the most direct route. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":30716,"isBookmarked":0,"aid":"30696#30715#30716#30717","text":"to and from school only#for farm-related work only#to and from school or for farm-related work#for other purposes"},
{"testId":580,"qid":25140,"description":"Which of the following are possible Kansas driver's license restrictions?","explanation":"Among the many restrictions that can be placed on a driver's license are corrective lenses, hand-operated equipment, and daytime driving. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30718#30719#30720","text":"All of the above#Corrective lenses#Hand-operated equipment#Daytime driving only"},
{"testId":1047,"qid":25140,"description":"Which of the following are possible Kansas driver's license restrictions?","explanation":"Among the many restrictions that can be placed on a driver's license are corrective lenses, hand-operated equipment, and daytime driving. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30718#30719#30720","text":"All of the above#Corrective lenses#Hand-operated equipment#Daytime driving only"},
{"testId":580,"qid":25141,"description":"What is the official name of the Kansas automobile driver's license?","explanation":"A non-commercial class C driving license is required for car drivers. [Age Requirements, Kansas Driving Handbook]","imageName":"","correctAnswer":30721,"isBookmarked":0,"aid":"897#30721#30722#30723","text":"None of the above#Non-commercial class C driver license#Commercial class C driver license#Non-commercial underage driver license"},
{"testId":1047,"qid":25141,"description":"What is the official name of the Kansas automobile driver's license?","explanation":"A non-commercial class C driving license is required for car drivers. [Age Requirements, Kansas Driving Handbook]","imageName":"","correctAnswer":30721,"isBookmarked":0,"aid":"897#30721#30722#30723","text":"None of the above#Non-commercial class C driver license#Commercial class C driver license#Non-commercial underage driver license"},
{"testId":580,"qid":25142,"description":"Certificates of marriage, court decisions, and divorce decrees are accepted forms of identification for :","explanation":"Acceptable forms of identification for changing your name on your driver's license include a government-issued marriage certificate, a court order of adoption, a court order of a legal name change, or a divorce decision. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":30725,"isBookmarked":0,"aid":"30724#30725#30726#30727","text":"an application for a driver's license#a change of name on a driver's license#renewing a driver's license#a traffic stop"},
{"testId":1047,"qid":25142,"description":"Certificates of marriage, court decisions, and divorce decrees are accepted forms of identification for :","explanation":"Acceptable forms of identification for changing your name on your driver's license include a government-issued marriage certificate, a court order of adoption, a court order of a legal name change, or a divorce decision. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":30725,"isBookmarked":0,"aid":"30724#30725#30726#30727","text":"an application for a driver's license#a change of name on a driver's license#renewing a driver's license#a traffic stop"},
{"testId":580,"qid":25143,"description":"If your name or address changes, you must notify the Division of Vehicles within ____ days.","explanation":"If you change your name or address, you must notify the Division of Vehicles within 10 days. [K.S.A. 8-248 ]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":1047,"qid":25143,"description":"If your name or address changes, you must notify the Division of Vehicles within ____ days.","explanation":"If you change your name or address, you must notify the Division of Vehicles within 10 days. [K.S.A. 8-248 ]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":580,"qid":25144,"description":"When ________ requests it, you must show your driver's license.","explanation":"For whatever reason, a court, police officer, driver's license examiner, sheriff, or constable has the authority to inspect your driver's license. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":763,"isBookmarked":0,"aid":"763#2253#12979#30728","text":"all of the above#a police officer#a judge#a driver's license examiner"},
{"testId":580,"qid":25145,"description":"You will be notified by mail at least ____ days before your driver's license expires.","explanation":"You will be alerted via letter at least 30 days before your driver's license expires. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":1047,"qid":25145,"description":"You will be notified by mail at least ____ days before your driver's license expires.","explanation":"You will be alerted via letter at least 30 days before your driver's license expires. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":581,"qid":25146,"description":"Which of the following will NOT result in the termination of driving privileges in Kentucky?","explanation":"Typically, a single conviction for slightly exceeding the speed limit will not result in the suspension of driving privileges. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":30981,"isBookmarked":0,"aid":"11943#30979#30980#30981","text":"DUI#Theft of a motor vehicle#Failure to maintain liability insurance#One conviction for speeding 10 mph over the limit"},
{"testId":1048,"qid":25146,"description":"Which of the following will NOT result in the termination of driving privileges in Kentucky?","explanation":"Typically, a single conviction for slightly exceeding the speed limit will not result in the suspension of driving privileges. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":30981,"isBookmarked":0,"aid":"11943#30979#30980#30981","text":"DUI#Theft of a motor vehicle#Failure to maintain liability insurance#One conviction for speeding 10 mph over the limit"},
{"testId":581,"qid":25147,"description":"Which of the following convictions in Kentucky may result in the loss of driving privileges?","explanation":"You may lose your driver's license if you are guilty of any of the following offenses: vehicular manslaughter, failing to address or fulfill a traffic charge, or abandoning the scene of an accident, among others. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11603#23204#30982","text":"All of the above#Vehicular manslaughter#Leaving the scene of an accident#Failure to satisfy a traffic citation"},
{"testId":1048,"qid":25147,"description":"Which of the following convictions in Kentucky may result in the loss of driving privileges?","explanation":"You may lose your driver's license if you are guilty of any of the following offenses: vehicular manslaughter, failing to address or fulfill a traffic charge, or abandoning the scene of an accident, among others. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11603#23204#30982","text":"All of the above#Vehicular manslaughter#Leaving the scene of an accident#Failure to satisfy a traffic citation"},
{"testId":581,"qid":25148,"description":"You may lose your driver's license if you have ____ or more reckless driving offenses within a 12-month period.","explanation":"You may lose your driver's license if you have three or more convictions for reckless driving during a 12-month period. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1048,"qid":25148,"description":"You may lose your driver's license if you have ____ or more reckless driving offenses within a 12-month period.","explanation":"You may lose your driver's license if you have three or more convictions for reckless driving during a 12-month period. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":581,"qid":25149,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driving privileges may be suspended or canceled until you do. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":1048,"qid":25149,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driving privileges may be suspended or canceled until you do. [License Suspension and Revocation, Protect Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":581,"qid":25150,"description":"If the parent or guardian who signed your application withdraws responsibility for you and you are under the age of _____, your license may be revoked.","explanation":"If you are under the age of 18, your driver's license application must be signed by your parent or guardian. If that individual refuses to accept responsibility, your license may be revoked. [Driving Privilege Cancellation, Protecting Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":1048,"qid":25150,"description":"If the parent or guardian who signed your application withdraws responsibility for you and you are under the age of _____, your license may be revoked.","explanation":"If you are under the age of 18, your driver's license application must be signed by your parent or guardian. If that individual refuses to accept responsibility, your license may be revoked. [Driving Privilege Cancellation, Protecting Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11361#11374#11696","text":"18#16#17#19"},
{"testId":581,"qid":25151,"description":"When an underage driver's license is revoked because his or her parent or guardian relinquishes responsibility, at what age can the driver reapply for a license?","explanation":"If a Kentucky driver under the age of 18 has had his or her license revoked because a parent or guardian has resigned responsibility, the motorist may seek for a license again at the age of 18. [Driving Privilege Cancellation, Protecting Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1048,"qid":25151,"description":"When an underage driver's license is revoked because his or her parent or guardian relinquishes responsibility, at what age can the driver reapply for a license?","explanation":"If a Kentucky driver under the age of 18 has had his or her license revoked because a parent or guardian has resigned responsibility, the motorist may seek for a license again at the age of 18. [Driving Privilege Cancellation, Protecting Your Driver's License, Kentucky Driver Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":581,"qid":25152,"description":"Failure to meet Kentucky's automotive insurance requirements may lead to:","explanation":"If you fail to comply with Kentucky's automotive insurance regulations, you may be fined, lose your driver's license, and have your vehicle's license plate taken away. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#23210#30985#30986","text":"All of the above#fines#loss of your vehicle's license plate#loss of your driver's license"},
{"testId":1048,"qid":25152,"description":"Failure to meet Kentucky's automotive insurance requirements may lead to:","explanation":"If you fail to comply with Kentucky's automotive insurance regulations, you may be fined, lose your driver's license, and have your vehicle's license plate taken away. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#23210#30985#30986","text":"All of the above#fines#loss of your vehicle's license plate#loss of your driver's license"},
{"testId":581,"qid":25153,"description":"What is the minimum liability insurance coverage for a single bodily injury for each vehicle?","explanation":"The minimum liability insurance coverage for a single bodily harm for each vehicle is $25,000. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11658#11659#12048","text":"$30,000#$10,000#$20,000#$25,000"},
{"testId":1048,"qid":25153,"description":"What is the minimum liability insurance coverage for a single bodily injury for each vehicle?","explanation":"The minimum liability insurance coverage for a single bodily harm for each vehicle is $25,000. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11658#11659#12048","text":"$30,000#$10,000#$20,000#$25,000"},
{"testId":581,"qid":25154,"description":"Each vehicle must be insured with at least _______ in property damage coverage.","explanation":"Each vehicle must be insured with at least $10,000 in property damage coverage. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1048,"qid":25154,"description":"Each vehicle must be insured with at least _______ in property damage coverage.","explanation":"Each vehicle must be insured with at least $10,000 in property damage coverage. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":581,"qid":25155,"description":"No-fault personal injury coverage restricts a driver's right to :","explanation":"A driver's right to sue or be sued is limited under no-fault personal injury coverage. It is necessary for all cars operating in Kentucky. By applying to the Kentucky Department of Insurance, you may reject no-fault coverage while retaining the ability to sue or be sued. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30988,"isBookmarked":0,"aid":"8626#30987#30988#30989","text":"None of the above#medical care#sue or be sued#operate a vehicle at night"},
{"testId":1048,"qid":25155,"description":"No-fault personal injury coverage restricts a driver's right to :","explanation":"A driver's right to sue or be sued is limited under no-fault personal injury coverage. It is necessary for all cars operating in Kentucky. By applying to the Kentucky Department of Insurance, you may reject no-fault coverage while retaining the ability to sue or be sued. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30988,"isBookmarked":0,"aid":"8626#30987#30988#30989","text":"None of the above#medical care#sue or be sued#operate a vehicle at night"},
{"testId":581,"qid":25156,"description":"You can get a \"single limit\" auto insurance coverage with a liability limit of at least ____________.","explanation":"You may choose either a \"split limit\" coverage ($25,000 for one person's injury, $50,000 for multiple person's injuries, and $10,000 for property damage) or a \"single limit\" policy with a liability maximum of at least $60,000. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30991,"isBookmarked":0,"aid":"30990#30991#30992#30993","text":"$50,000#$60,000#$70,000#$80,000"},
{"testId":1048,"qid":25156,"description":"You can get a \"single limit\" auto insurance coverage with a liability limit of at least ____________.","explanation":"You may choose either a \"split limit\" coverage ($25,000 for one person's injury, $50,000 for multiple person's injuries, and $10,000 for property damage) or a \"single limit\" policy with a liability maximum of at least $60,000. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30991,"isBookmarked":0,"aid":"30990#30991#30992#30993","text":"$50,000#$60,000#$70,000#$80,000"},
{"testId":581,"qid":25157,"description":"When you buy auto insurance, the company will give you written proof that you are covered. One copy must always be in the car, and the other must be given to:","explanation":"When you get vehicle insurance, the insurer will offer you with written evidence of coverage. One copy must be kept in the vehicle at all times and must be shown to law enforcement personnel upon request. The other one must be returned to the County Clerk for registration renewal. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30994,"isBookmarked":0,"aid":"30994#30995#30996#30997","text":"the County Clerk#the auto dealer#other drivers who operate the vehicle#your driving instructor"},
{"testId":1048,"qid":25157,"description":"When you buy auto insurance, the company will give you written proof that you are covered. One copy must always be in the car, and the other must be given to:","explanation":"When you get vehicle insurance, the insurer will offer you with written evidence of coverage. One copy must be kept in the vehicle at all times and must be shown to law enforcement personnel upon request. The other one must be returned to the County Clerk for registration renewal. [Insurance Law, Driving License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":30994,"isBookmarked":0,"aid":"30994#30995#30996#30997","text":"the County Clerk#the auto dealer#other drivers who operate the vehicle#your driving instructor"},
{"testId":581,"qid":25158,"description":"What is the goal of the Kentucky point system?","explanation":"The Kentucky points system is designed to identify and help drivers who need to improve, to penalize reckless and irresponsible drivers, and to protect safe drivers. [Kentucky Point System, Driver License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":31001,"isBookmarked":0,"aid":"30998#30999#31000#31001","text":"To identify and assist drivers who need improvement#To correct irresponsible or reckless drivers#To protect safe drivers#To do all of the above"},
{"testId":1048,"qid":25158,"description":"What is the goal of the Kentucky point system?","explanation":"The Kentucky points system is designed to identify and help drivers who need to improve, to penalize reckless and irresponsible drivers, and to protect safe drivers. [Kentucky Point System, Driver License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":31001,"isBookmarked":0,"aid":"30998#30999#31000#31001","text":"To identify and assist drivers who need improvement#To correct irresponsible or reckless drivers#To protect safe drivers#To do all of the above"},
{"testId":581,"qid":25159,"description":"Driving records in Kentucky are based on:","explanation":"Kentucky driving records are based on bail forfeitures, traffic convictions, and fine payments, with or without court appearances. [Kentucky Point System, Driver License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#31002#31003#31004","text":"All of the above#forfeitures of bail#traffic convictions#payments of fines"},
{"testId":1048,"qid":25159,"description":"Driving records in Kentucky are based on:","explanation":"Kentucky driving records are based on bail forfeitures, traffic convictions, and fine payments, with or without court appearances. [Kentucky Point System, Driver License Protection, Kentucky Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#31002#31003#31004","text":"All of the above#forfeitures of bail#traffic convictions#payments of fines"},
{"testId":581,"qid":25160,"description":"If you accumulate ____ points, the Division of Driver Licensing may send you a form letter warning you to improve your driving immediately.","explanation":"If you accumulate 6 points, the Division of Driver Licensing may send you a boilerplate letter urging you to improve your driving immediately. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":1048,"qid":25160,"description":"If you accumulate ____ points, the Division of Driver Licensing may send you a form letter warning you to improve your driving immediately.","explanation":"If you accumulate 6 points, the Division of Driver Licensing may send you a boilerplate letter urging you to improve your driving immediately. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":581,"qid":25161,"description":"If you get a letter from the Division of Driver Licensing requesting that you make driving-related improvements,___________ may be mandatory :","explanation":"You may be forced to talk with a Transportation Cabinet official about your driving habits if you get a letter from the Division of Driver Licensing urging you to improve your driving. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31005,"isBookmarked":0,"aid":"22727#31005#31006#31007","text":"Do none of the above#interview with a Transportation Cabinet official#interview with a licensed driving instructor#retake the driving exam"},
{"testId":1048,"qid":25161,"description":"If you get a letter from the Division of Driver Licensing requesting that you make driving-related improvements,___________ may be mandatory :","explanation":"You may be forced to talk with a Transportation Cabinet official about your driving habits if you get a letter from the Division of Driver Licensing urging you to improve your driving. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31005,"isBookmarked":0,"aid":"22727#31005#31006#31007","text":"Do none of the above#interview with a Transportation Cabinet official#interview with a licensed driving instructor#retake the driving exam"},
{"testId":581,"qid":25162,"description":"If you are required to meet with a Transportation Cabinet official regarding your driving but do not show up, your license will be suspended for_________ months.","explanation":"If you fail to attend for a meeting with a Transportation Cabinet official concerning your driving, your license will be revoked for 6 months. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1048,"qid":25162,"description":"If you are required to meet with a Transportation Cabinet official regarding your driving but do not show up, your license will be suspended for_________ months.","explanation":"If you fail to attend for a meeting with a Transportation Cabinet official concerning your driving, your license will be revoked for 6 months. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":581,"qid":25163,"description":"If you accumulate ____ points, you may be required to attend State Traffic School.","explanation":"You will get a warning letter if you have 6 points on your driving record, you may be compelled to speak with a Transportation Cabinet official, and you may be asked to attend State Traffic School. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1048,"qid":25163,"description":"If you accumulate ____ points, you may be required to attend State Traffic School.","explanation":"You will get a warning letter if you have 6 points on your driving record, you may be compelled to speak with a Transportation Cabinet official, and you may be asked to attend State Traffic School. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":581,"qid":25164,"description":"How many racing convictions can result in a 90-day license suspension?","explanation":"Your license may be suspended for 90 days if you are convicted of racing even once. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":10546,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":1048,"qid":25164,"description":"How many racing convictions can result in a 90-day license suspension?","explanation":"Your license may be suspended for 90 days if you are convicted of racing even once. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":10546,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":581,"qid":25165,"description":"If you are convicted of exceeding the speed limit by more than 26 miles per hour, your license may be suspended for _______ days.","explanation":"If you are convicted of exceeding the speed limit by more than 26 miles per hour, your license may be suspended for 90 days. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1048,"qid":25165,"description":"If you are convicted of exceeding the speed limit by more than 26 miles per hour, your license may be suspended for _______ days.","explanation":"If you are convicted of exceeding the speed limit by more than 26 miles per hour, your license may be suspended for 90 days. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":581,"qid":25166,"description":"Which of the following may result in a 90-day license suspension?","explanation":"Your license may be suspended for 90 days if you are guilty of escaping or dodging police. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31008,"isBookmarked":0,"aid":"26680#31008#31009#31010","text":"Running a red light#Fleeing or evading police#Speeding 10 mph over the speed limit#Speeding 15 mph over the speed limit"},
{"testId":1048,"qid":25166,"description":"Which of the following may result in a 90-day license suspension?","explanation":"Your license may be suspended for 90 days if you are guilty of escaping or dodging police. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31008,"isBookmarked":0,"aid":"26680#31008#31009#31010","text":"Running a red light#Fleeing or evading police#Speeding 10 mph over the speed limit#Speeding 15 mph over the speed limit"},
{"testId":581,"qid":25167,"description":"A conviction for exceeding the speed limit by 15 miles per hour or less will add ____ points to your driving record.","explanation":"If you are guilty of exceeding the speed limit by 15 miles per hour or less, 3 points will be added to your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6469#12036","text":"2#3#1#0"},
{"testId":1048,"qid":25167,"description":"A conviction for exceeding the speed limit by 15 miles per hour or less will add ____ points to your driving record.","explanation":"If you are guilty of exceeding the speed limit by 15 miles per hour or less, 3 points will be added to your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6469#12036","text":"2#3#1#0"},
{"testId":581,"qid":25168,"description":"If you go ______ mph or less over the speed limit on a limited-access highway, you won't get any points on your driving record.","explanation":"If you are guilty of exceeding the speed limit by 10 miles per hour or less on a limited-access highway, no points will be added to your driving record. However, you may still be required to pay a fine. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1048,"qid":25168,"description":"If you go ______ mph or less over the speed limit on a limited-access highway, you won't get any points on your driving record.","explanation":"If you are guilty of exceeding the speed limit by 10 miles per hour or less on a limited-access highway, no points will be added to your driving record. However, you may still be required to pay a fine. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":581,"qid":25169,"description":"If you go ______ mph over the speed limit on a highway with limited access, you will get 3 points on your driving record.","explanation":null,"imageName":"","correctAnswer":12944,"isBookmarked":0,"aid":"12943#12944#12945#12946","text":"10 to 14#11 to 15#10 to 20#20 to 25"},
{"testId":1048,"qid":25169,"description":"If you go ______ mph over the speed limit on a highway with limited access, you will get 3 points on your driving record.","explanation":null,"imageName":"","correctAnswer":12944,"isBookmarked":0,"aid":"12943#12944#12945#12946","text":"10 to 14#11 to 15#10 to 20#20 to 25"},
{"testId":581,"qid":25171,"description":"How many points will be addes to your driving record if you fail to stop for a stopped school bus?","explanation":"If you are guilty of failing to stop for a halted school bus, you will get 6 points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":25171,"description":"How many points will be addes to your driving record if you fail to stop for a stopped school bus?","explanation":"If you are guilty of failing to stop for a halted school bus, you will get 6 points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":25174,"description":"If you fail to stop at ________, you may be charged with a stop infraction, which carries a three-point penalty on your driving record.","explanation":"If you fail to stop at an electrical signal (such as a traffic light), a stop sign, or a railroad crossing, you may be charged with a stop infraction, which carries a three-point penalty on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"319#345#848#3875","text":"a railroad crossing#a stop sign#Any of the above#a traffic light"},
{"testId":1048,"qid":25174,"description":"If you fail to stop at ________, you may be charged with a stop infraction, which carries a three-point penalty on your driving record.","explanation":"If you fail to stop at an electrical signal (such as a traffic light), a stop sign, or a railroad crossing, you may be charged with a stop infraction, which carries a three-point penalty on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"319#345#848#3875","text":"a railroad crossing#a stop sign#Any of the above#a traffic light"},
{"testId":581,"qid":25175,"description":"On a one-way street, driving the wrong way will add ____points to your driving record.","explanation":"If you are convicted of driving the wrong way on a one-way street, you will get three points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1048,"qid":25175,"description":"On a one-way street, driving the wrong way will add ____points to your driving record.","explanation":"If you are convicted of driving the wrong way on a one-way street, you will get three points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":581,"qid":25176,"description":"Which of the following convictions will you get 3 points added to your driving record?","explanation":"Driving too slowly for the conditions, reckless driving, failing to illuminate or dim headlights, and other infractions will result in 3 points being added to your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29906#31013#31014","text":"All of the above#Failure to use headlights#Driving too slowly#Careless driving"},
{"testId":1048,"qid":25176,"description":"Which of the following convictions will you get 3 points added to your driving record?","explanation":"Driving too slowly for the conditions, reckless driving, failing to illuminate or dim headlights, and other infractions will result in 3 points being added to your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29906#31013#31014","text":"All of the above#Failure to use headlights#Driving too slowly#Careless driving"},
{"testId":581,"qid":25177,"description":"____ points will be added to your driving record if you're found guilty of two or more hazardous moving violations in one instance.","explanation":"If you are guilty of two or more hazardous moving offences in one incident, you will get six points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":25177,"description":"____ points will be added to your driving record if you're found guilty of two or more hazardous moving violations in one instance.","explanation":"If you are guilty of two or more hazardous moving offences in one incident, you will get six points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":25178,"description":"Particular conviction points will remain on your driving record for:","explanation":"Points for a specific conviction will stay on your driving record for two years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1048,"qid":25178,"description":"Particular conviction points will remain on your driving record for:","explanation":"Points for a specific conviction will stay on your driving record for two years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":581,"qid":25180,"description":"If a Kentucky driver aged 18 or older accumulates ____ or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining potential sanctions.","explanation":"If a Kentucky driver aged 18 or older earns 6 or more points in a two-year period, the Transportation Cabinet will issue the offender a warning letter outlining potential consequences if the motorist accumulates 12 points in two years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1048,"qid":25180,"description":"If a Kentucky driver aged 18 or older accumulates ____ or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining potential sanctions.","explanation":"If a Kentucky driver aged 18 or older earns 6 or more points in a two-year period, the Transportation Cabinet will issue the offender a warning letter outlining potential consequences if the motorist accumulates 12 points in two years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":581,"qid":25182,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her driver's license for a period of _______ for the first accumulation of points within the two-year term.","explanation":"If a motorist above the age of 18 collects 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for 6 months for the first accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1453#1454#11317","text":"6 months#3 months#9 months#1 month"},
{"testId":1048,"qid":25182,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her driver's license for a period of _______ for the first accumulation of points within the two-year term.","explanation":"If a motorist above the age of 18 collects 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for 6 months for the first accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1453#1454#11317","text":"6 months#3 months#9 months#1 month"},
{"testId":581,"qid":25183,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for a period of _______ for the second accumulation of points.","explanation":"If a driver above the age of 18 collects 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for one year for the second accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#1454","text":"30 days#1 year#6 months#9 months"},
{"testId":1048,"qid":25183,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for a period of _______ for the second accumulation of points.","explanation":"If a driver above the age of 18 collects 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for one year for the second accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#1454","text":"30 days#1 year#6 months#9 months"},
{"testId":581,"qid":25184,"description":"If you have your driver's license suspended by the Transportation Cabinet more than once:","explanation":"If you have your driver's license suspended by the Transportation Cabinet more than once, the suspensions will run consecutively. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31017,"isBookmarked":0,"aid":"22673#31015#31016#31017","text":"None of the above are true#your license will be revoked#you will be sentenced to jail#the suspensions will run consecutively"},
{"testId":1048,"qid":25184,"description":"If you have your driver's license suspended by the Transportation Cabinet more than once:","explanation":"If you have your driver's license suspended by the Transportation Cabinet more than once, the suspensions will run consecutively. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31017,"isBookmarked":0,"aid":"22673#31015#31016#31017","text":"None of the above are true#your license will be revoked#you will be sentenced to jail#the suspensions will run consecutively"},
{"testId":581,"qid":25186,"description":"Following a Transportation Cabinet hearing, a probation period may be substituted for a suspension time ______ within a two-year period.","explanation":"A probation term may be substituted for a suspension period only once in a two-year period after a Transportation Cabinet hearing. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":1048,"qid":25186,"description":"Following a Transportation Cabinet hearing, a probation period may be substituted for a suspension time ______ within a two-year period.","explanation":"A probation term may be substituted for a suspension period only once in a two-year period after a Transportation Cabinet hearing. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":581,"qid":25187,"description":"If __________, you may be obliged to attend the State Traffic School Program.","explanation":"If you have been put on probation or convicted of a moving traffic offence that does not warrant obligatory license suspension, you may be compelled to attend the State Driving School Program. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":31020,"isBookmarked":0,"aid":"31018#31019#31020#31021","text":"a) you have been convicted of a moving traffic violation that does not require mandatory license suspension#b) you have been placed on probation#either a) or b) is true#neither a) nor b) is true"},
{"testId":581,"qid":25188,"description":"You can take the State Traffic School Program ___________ in a year.","explanation":"You may only attend the State Traffic School Program once per twelve months. It is not possible to enlist voluntarily. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":1048,"qid":25188,"description":"You can take the State Traffic School Program ___________ in a year.","explanation":"You may only attend the State Traffic School Program once per twelve months. It is not possible to enlist voluntarily. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":581,"qid":25189,"description":"Which of the following is a benefit of participating in the State Traffic School Program?","explanation":"The following are some of the benefits of attending the State Driving School Program: If you successfully complete the program, no points will be added to your driving record for that specific infraction, and insurance companies will not be able to acquire the information. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":31023,"isBookmarked":0,"aid":"31022#31023#31024#31025","text":"All points will be erased from your driving record#No points will be added for the violation#You will be rewarded with a free tank of gasoline#None of the above are advantages"},
{"testId":1048,"qid":25189,"description":"Which of the following is a benefit of participating in the State Traffic School Program?","explanation":"The following are some of the benefits of attending the State Driving School Program: If you successfully complete the program, no points will be added to your driving record for that specific infraction, and insurance companies will not be able to acquire the information. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":31023,"isBookmarked":0,"aid":"31022#31023#31024#31025","text":"All points will be erased from your driving record#No points will be added for the violation#You will be rewarded with a free tank of gasoline#None of the above are advantages"},
{"testId":581,"qid":25191,"description":"If your driver's license was suspended due to a DUI, you must _________ before it can be reinstated.","explanation":"If your driver's license has been suspended due to a DUI, you must complete an authorized alcohol education or treatment program, wait until the suspension term is through, and pay the license reinstatement fee before your license may be restored. [Kentucky Driver Manual, Driver Substance Abuse Education Program, Protecting Your Driver's License]","imageName":"","correctAnswer":6618,"isBookmarked":0,"aid":"6618#12963#31029#31030","text":"Do all of the above#complete an approved alcohol program#wait until the suspension period is over#pay the license reinstatement fee"},
{"testId":1048,"qid":25191,"description":"If your driver's license was suspended due to a DUI, you must _________ before it can be reinstated.","explanation":"If your driver's license has been suspended due to a DUI, you must complete an authorized alcohol education or treatment program, wait until the suspension term is through, and pay the license reinstatement fee before your license may be restored. [Kentucky Driver Manual, Driver Substance Abuse Education Program, Protecting Your Driver's License]","imageName":"","correctAnswer":6618,"isBookmarked":0,"aid":"6618#12963#31029#31030","text":"Do all of the above#complete an approved alcohol program#wait until the suspension period is over#pay the license reinstatement fee"},
{"testId":581,"qid":25192,"description":"In order to apply for or renew your driver's license if you have a history of seizures, you must be seizure-free for:","explanation":"If you have seizures, you must be seizure-free for 90 days before applying for or renewing your driver's license. You must also provide a physician's statement verifying that your disease is effectively managed by therapy and specifying the nature of that treatment. [The Medical Review Board, Kentucky Driver Manual, Protecting Your Driver's License]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1048,"qid":25192,"description":"In order to apply for or renew your driver's license if you have a history of seizures, you must be seizure-free for:","explanation":"If you have seizures, you must be seizure-free for 90 days before applying for or renewing your driver's license. You must also provide a physician's statement verifying that your disease is effectively managed by therapy and specifying the nature of that treatment. [The Medical Review Board, Kentucky Driver Manual, Protecting Your Driver's License]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":581,"qid":25193,"description":"Improper lane usage will add ____ points to your driving record.","explanation":"If you are guilty of incorrect lane use, you will get 3 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":25193,"description":"Improper lane usage will add ____ points to your driving record.","explanation":"If you are guilty of incorrect lane use, you will get 3 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":25194,"description":"If you're convicted of improper left-lane use on a limited-access highway, your will have ____ points added to your driving record.","explanation":"If you are guilty of driving in the wrong lane on a limited-access highway, 3 points will be applied to your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":25194,"description":"If you're convicted of improper left-lane use on a limited-access highway, your will have ____ points added to your driving record.","explanation":"If you are guilty of driving in the wrong lane on a limited-access highway, 3 points will be applied to your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":25195,"description":"If convicted of an inappropriate start, following number of points will be added to your record:","explanation":"If you are guilty of making an incorrect start, you will get 3 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1048,"qid":25195,"description":"If convicted of an inappropriate start, following number of points will be added to your record:","explanation":"If you are guilty of making an incorrect start, you will get 3 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":583,"qid":25196,"description":"Which of the following terms does the state of Maine use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"The crime of driving a motor vehicle while impaired by alcohol or other substances is referred to as Operating Under the Influence (OUI) in Maine. [Alcohol and Other Drugs, Part 3: Driving Fit, Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12966,"isBookmarked":0,"aid":"897#12820#12821#12966","text":"None of the above#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Operating Under the Influence (OUI)"},
{"testId":1050,"qid":25196,"description":"Which of the following terms does the state of Maine use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"The crime of driving a motor vehicle while impaired by alcohol or other substances is referred to as Operating Under the Influence (OUI) in Maine. [Alcohol and Other Drugs, Part 3: Driving Fit, Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12966,"isBookmarked":0,"aid":"897#12820#12821#12966","text":"None of the above#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Operating Under the Influence (OUI)"},
{"testId":583,"qid":25197,"description":"A drunk motorist is more likely to:","explanation":"A drunk driver is more prone to drive too quickly or too slowly, to change lanes repeatedly, and to fail to lower the headlights. Even more importantly, in an emergency, an intoxicated driver may be less aware and may take too long to stop. Such errors may jeopardize both the driver and bystanders. [How Drinking Affects Your Driving, Alcohol and Other Drugs, Part 3: Staying Fit to Drive, Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"8829#10656#31664#31665","text":"Change lanes frequently#Do all of the above#fail to dim the headlights for oncoming traffic#drive too fast or too slowly"},
{"testId":1050,"qid":25197,"description":"A drunk motorist is more likely to:","explanation":"A drunk driver is more prone to drive too quickly or too slowly, to change lanes repeatedly, and to fail to lower the headlights. Even more importantly, in an emergency, an intoxicated driver may be less aware and may take too long to stop. Such errors may jeopardize both the driver and bystanders. [How Drinking Affects Your Driving, Alcohol and Other Drugs, Part 3: Staying Fit to Drive, Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"8829#10656#31664#31665","text":"Change lanes frequently#Do all of the above#fail to dim the headlights for oncoming traffic#drive too fast or too slowly"},
{"testId":583,"qid":25198,"description":"How many fatally injured drivers had a blood alcohol content (BAC) of 0.06 percent or higher?","explanation":"A blood alcohol content (BAC) of at least 0.06 grams of alcohol per 100 milliliters of blood is present in more than half of all fatally injured drivers. (This is often stated as a percentage of 0.06 percent.) [Good drivers do not consume alcohol! Alcohol and Other Drugs, Part 3: Maintaining Driving Fitness, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31666,"isBookmarked":0,"aid":"11664#11665#31666#31667","text":"10%#25%#Over 50%#5%"},
{"testId":1050,"qid":25198,"description":"How many fatally injured drivers had a blood alcohol content (BAC) of 0.06 percent or higher?","explanation":"A blood alcohol content (BAC) of at least 0.06 grams of alcohol per 100 milliliters of blood is present in more than half of all fatally injured drivers. (This is often stated as a percentage of 0.06 percent.) [Good drivers do not consume alcohol! Alcohol and Other Drugs, Part 3: Maintaining Driving Fitness, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31666,"isBookmarked":0,"aid":"11664#11665#31666#31667","text":"10%#25%#Over 50%#5%"},
{"testId":583,"qid":25199,"description":"Which of the following actions can result in an OUI conviction?","explanation":"The use of prescription pharmaceuticals, over-the-counter medications, illicit substances, or alcohol may impair a driver's ability to drive safely and may result in an OUI conviction. [Other Drugs and Driving, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#9803#12520","text":"All of the above#Prescription drugs#Over-the-counter medications#Illegal drugs"},
{"testId":1050,"qid":25199,"description":"Which of the following actions can result in an OUI conviction?","explanation":"The use of prescription pharmaceuticals, over-the-counter medications, illicit substances, or alcohol may impair a driver's ability to drive safely and may result in an OUI conviction. [Other Drugs and Driving, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#9803#12520","text":"All of the above#Prescription drugs#Over-the-counter medications#Illegal drugs"},
{"testId":583,"qid":25200,"description":"If you are caught driving with a blood alcohol content (BAC) of ______ or above, you will be charged with operating while intoxicated (OUI).","explanation":"If you are caught driving with a blood alcohol concentration (BAC) of 0.08 percent (i.e., 0.08 grams per 100 milliliters of blood), you will be charged with driving under the influence (OUI). Intoxication is legally defined at 0.08 percent in all 50 states. [Secretary of State Suspension, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":1050,"qid":25200,"description":"If you are caught driving with a blood alcohol content (BAC) of ______ or above, you will be charged with operating while intoxicated (OUI).","explanation":"If you are caught driving with a blood alcohol concentration (BAC) of 0.08 percent (i.e., 0.08 grams per 100 milliliters of blood), you will be charged with driving under the influence (OUI). Intoxication is legally defined at 0.08 percent in all 50 states. [Secretary of State Suspension, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":583,"qid":25201,"description":"How soon after being charged with OUI will your license be suspended in Maine?","explanation":"Your driver's licence will be suspended as soon as you are detained on an OUI charge and the Secretary of State has been informed. As a result, you will lose your driving rights before you ever appear before a judge in court. [Immediate Suspension by Secretary of State, State of Maine Motorist Handbook and Study Guide, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive]","imageName":"","correctAnswer":31668,"isBookmarked":0,"aid":"31668#31669#31670#31671","text":"Immediately following your arrest#After you are released from jail#Upon conviction#30 days after conviction"},
{"testId":1050,"qid":25201,"description":"How soon after being charged with OUI will your license be suspended in Maine?","explanation":"Your driver's licence will be suspended as soon as you are detained on an OUI charge and the Secretary of State has been informed. As a result, you will lose your driving rights before you ever appear before a judge in court. [Immediate Suspension by Secretary of State, State of Maine Motorist Handbook and Study Guide, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive]","imageName":"","correctAnswer":31668,"isBookmarked":0,"aid":"31668#31669#31670#31671","text":"Immediately following your arrest#After you are released from jail#Upon conviction#30 days after conviction"},
{"testId":583,"qid":25202,"description":"In Maine, police officers use two types of chemical breath tests: Sober Meter and:","explanation":"Maine police officers employ Sober Meter and Intoxilyzer breath testing to determine your blood alcohol levels. [Alcohol Level Testing, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31674,"isBookmarked":0,"aid":"31672#31673#31674#31675","text":"Breathalyzer#Alcometer#Intoxilyzer#Drink-o-meter"},
{"testId":1050,"qid":25202,"description":"In Maine, police officers use two types of chemical breath tests: Sober Meter and:","explanation":"Maine police officers employ Sober Meter and Intoxilyzer breath testing to determine your blood alcohol levels. [Alcohol Level Testing, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31674,"isBookmarked":0,"aid":"31672#31673#31674#31675","text":"Breathalyzer#Alcometer#Intoxilyzer#Drink-o-meter"},
{"testId":583,"qid":25203,"description":"Who decides which physician will give a chemical blood test for alcohol when a driver is accused of operating under the influence (OUI)?","explanation":"If the officer determines that administering a breath test is inappropriate, the motorist will be asked to submit to a blood test. In such instance, the driver has the option of selecting the physician who will give the test, as long as that physician is available at the time. [Alcohol Level Testing, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":9808,"isBookmarked":0,"aid":"9808#31676#31677#31678","text":"The driver#The police officer#A judge#The Secretary of State"},
{"testId":1050,"qid":25203,"description":"Who decides which physician will give a chemical blood test for alcohol when a driver is accused of operating under the influence (OUI)?","explanation":"If the officer determines that administering a breath test is inappropriate, the motorist will be asked to submit to a blood test. In such instance, the driver has the option of selecting the physician who will give the test, as long as that physician is available at the time. [Alcohol Level Testing, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":9808,"isBookmarked":0,"aid":"9808#31676#31677#31678","text":"The driver#The police officer#A judge#The Secretary of State"},
{"testId":583,"qid":25204,"description":"When you drive a vehicle in Maine, you automatically agree to :","explanation":null,"imageName":"","correctAnswer":31680,"isBookmarked":0,"aid":"8626#29272#31679#31680","text":"None of the above#a search of your home#seizure of your vehicle#submission to a chemical test for drugs or alcohol"},
{"testId":1050,"qid":25204,"description":"When you drive a vehicle in Maine, you automatically agree to :","explanation":null,"imageName":"","correctAnswer":31680,"isBookmarked":0,"aid":"8626#29272#31679#31680","text":"None of the above#a search of your home#seizure of your vehicle#submission to a chemical test for drugs or alcohol"},
{"testId":583,"qid":25205,"description":"If you refuse to submit to drug or alcohol testing, :","explanation":null,"imageName":"","correctAnswer":31681,"isBookmarked":0,"aid":"29914#31681#31682#31683","text":"your vehicle will be impounded#your driver's license will be suspended immediately#your parents will be notified, if you are underage#your employer will be notified"},
{"testId":1050,"qid":25205,"description":"If you refuse to submit to drug or alcohol testing, :","explanation":null,"imageName":"","correctAnswer":31681,"isBookmarked":0,"aid":"29914#31681#31682#31683","text":"your vehicle will be impounded#your driver's license will be suspended immediately#your parents will be notified, if you are underage#your employer will be notified"},
{"testId":583,"qid":25207,"description":"If you refuse to submit to drug or alcohol testing, :","explanation":null,"imageName":"","correctAnswer":31686,"isBookmarked":0,"aid":"31684#31685#31686#31687","text":"you cannot be convicted of OUI#no other penalties may be imposed on you#you can still be convicted of OUI#your refusal cannot be held against you in a court of law"},
{"testId":1050,"qid":25207,"description":"If you refuse to submit to drug or alcohol testing, :","explanation":null,"imageName":"","correctAnswer":31686,"isBookmarked":0,"aid":"31684#31685#31686#31687","text":"you cannot be convicted of OUI#no other penalties may be imposed on you#you can still be convicted of OUI#your refusal cannot be held against you in a court of law"},
{"testId":583,"qid":25208,"description":"If you refuse to submit to drug or alcohol testing and are later found NOT guilty of OUI in a court of law,:","explanation":null,"imageName":"","correctAnswer":31690,"isBookmarked":0,"aid":"31688#31689#31690#31691","text":"your license will be reinstated#the OUI charge will remain on your record#your license will remain in suspension#you will still be required to attend driving school"},
{"testId":1050,"qid":25208,"description":"If you refuse to submit to drug or alcohol testing and are later found NOT guilty of OUI in a court of law,:","explanation":null,"imageName":"","correctAnswer":31690,"isBookmarked":0,"aid":"31688#31689#31690#31691","text":"your license will be reinstated#the OUI charge will remain on your record#your license will remain in suspension#you will still be required to attend driving school"},
{"testId":583,"qid":25209,"description":"If you refuse to submit to drug or alcohol testing and are later found guilty of OUI in a court of law, the time of suspension of your driver's license:","explanation":null,"imageName":"","correctAnswer":31693,"isBookmarked":0,"aid":"31692#31693#31694#31695","text":"will be halved#will be increased#will remain the same#will be decreased"},
{"testId":1050,"qid":25209,"description":"If you refuse to submit to drug or alcohol testing and are later found guilty of OUI in a court of law, the time of suspension of your driver's license:","explanation":null,"imageName":"","correctAnswer":31693,"isBookmarked":0,"aid":"31692#31693#31694#31695","text":"will be halved#will be increased#will remain the same#will be decreased"},
{"testId":583,"qid":25210,"description":"You will be penalized at least ________ for your first OUI conviction with a BAC between 0.08 percent and 0.14 percent.","explanation":"You will be penalized at least $500 for your first OUI conviction with a BAC between 0.08 percent and 0.14 percent. If you refused to submit to chemical testing, your sentence would be increased to $600. (It should be noted that 0.08 percent is the legal limit in all states in the United States.) [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#27888#31696#31697","text":"$500#$250#$400#$650"},
{"testId":1050,"qid":25210,"description":"You will be penalized at least ________ for your first OUI conviction with a BAC between 0.08 percent and 0.14 percent.","explanation":"You will be penalized at least $500 for your first OUI conviction with a BAC between 0.08 percent and 0.14 percent. If you refused to submit to chemical testing, your sentence would be increased to $600. (It should be noted that 0.08 percent is the legal limit in all states in the United States.) [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#27888#31696#31697","text":"$500#$250#$400#$650"},
{"testId":583,"qid":25211,"description":"Your driver's license will be suspended for _____ days for your first OUI conviction with a blood alcohol content (BAC) between 0.08 percent and 0.14 percent.","explanation":"Your driver's license will be terminated for 150 days if you get your first OUI conviction with a BAC of 0.08 percent to 0.14 percent. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12996,"isBookmarked":0,"aid":"6591#7571#9975#12996","text":"100#50#300#150"},
{"testId":1050,"qid":25211,"description":"Your driver's license will be suspended for _____ days for your first OUI conviction with a blood alcohol content (BAC) between 0.08 percent and 0.14 percent.","explanation":"Your driver's license will be terminated for 150 days if you get your first OUI conviction with a BAC of 0.08 percent to 0.14 percent. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12996,"isBookmarked":0,"aid":"6591#7571#9975#12996","text":"100#50#300#150"},
{"testId":583,"qid":25212,"description":"In addition to fines and suspension, which of the following will occur for your first OUI offense?","explanation":"In addition to fines and license suspension for your first OUI conviction, you will be required to pay a license reinstatement fee and complete an alcohol or drug education and treatment program. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31700,"isBookmarked":0,"aid":"31698#31699#31700#31701","text":"You will be required to pay a license reinstatement fee#You will be required to attend an alcohol or drug education and treatment program#Both of the above will occur#Neither of the above will occur"},
{"testId":1050,"qid":25212,"description":"In addition to fines and suspension, which of the following will occur for your first OUI offense?","explanation":"In addition to fines and license suspension for your first OUI conviction, you will be required to pay a license reinstatement fee and complete an alcohol or drug education and treatment program. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31700,"isBookmarked":0,"aid":"31698#31699#31700#31701","text":"You will be required to pay a license reinstatement fee#You will be required to attend an alcohol or drug education and treatment program#Both of the above will occur#Neither of the above will occur"},
{"testId":583,"qid":25213,"description":"Which of the following will result in increased penalties for your first OUI conviction?","explanation":"Instead of paying a fine, you will serve time in jail for your first OUI conviction for any of the following: Your BAC was at least 0.15 percent; you were above the legal speed limit by 30 miles per hour or more; you attempted to elude a police officer; you refused to submit to chemical testing; or you had a passenger under the age of 21. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31702#31703#31704","text":"All of the above#A blood alcohol concentration (BAC) at or above 0.15%#Speeding 30 mph or more over the speed limit#Transporting a passenger under the age of 21"},
{"testId":1050,"qid":25213,"description":"Which of the following will result in increased penalties for your first OUI conviction?","explanation":"Instead of paying a fine, you will serve time in jail for your first OUI conviction for any of the following: Your BAC was at least 0.15 percent; you were above the legal speed limit by 30 miles per hour or more; you attempted to elude a police officer; you refused to submit to chemical testing; or you had a passenger under the age of 21. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31702#31703#31704","text":"All of the above#A blood alcohol concentration (BAC) at or above 0.15%#Speeding 30 mph or more over the speed limit#Transporting a passenger under the age of 21"},
{"testId":583,"qid":25216,"description":"You declined chemical testing for your first OUI charge and were eventually found NOT GUILTY. If you are convicted of OUI again, it will be considered.","explanation":"A past refusal is considered a prior crime for future OUI convictions, even though you were judged not guilty of OUI at the time. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31706,"isBookmarked":0,"aid":"8626#31705#31706#31707","text":"None of the above#a first offense#a second offense#a third offense"},
{"testId":1050,"qid":25216,"description":"You declined chemical testing for your first OUI charge and were eventually found NOT GUILTY. If you are convicted of OUI again, it will be considered.","explanation":"A past refusal is considered a prior crime for future OUI convictions, even though you were judged not guilty of OUI at the time. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31706,"isBookmarked":0,"aid":"8626#31705#31706#31707","text":"None of the above#a first offense#a second offense#a third offense"},
{"testId":583,"qid":25219,"description":"What is the minimum fine that must be paid for a second OUI?","explanation":"You will be penalized at least $700 for your second OUI conviction. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":292,"isBookmarked":0,"aid":"290#291#292#13560","text":"$500#$600#$700#$900"},
{"testId":1050,"qid":25219,"description":"What is the minimum fine that must be paid for a second OUI?","explanation":"You will be penalized at least $700 for your second OUI conviction. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":292,"isBookmarked":0,"aid":"290#291#292#13560","text":"$500#$600#$700#$900"},
{"testId":583,"qid":25220,"description":"What is the necessary minimum fine for a second OUI if you rejected chemical testing at the time of the offense?","explanation":"If you refused to submit to chemical testing at the time of the crime, you will be fined at least $900 for your second OUI conviction. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13560,"isBookmarked":0,"aid":"290#291#292#13560","text":"$500#$600#$700#$900"},
{"testId":1050,"qid":25220,"description":"What is the necessary minimum fine for a second OUI if you rejected chemical testing at the time of the offense?","explanation":"If you refused to submit to chemical testing at the time of the crime, you will be fined at least $900 for your second OUI conviction. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13560,"isBookmarked":0,"aid":"290#291#292#13560","text":"$500#$600#$700#$900"},
{"testId":583,"qid":25221,"description":"Your license will be suspended for at least ____ months if you're convicted of OUI a second time.","explanation":"Your driver's license will be suspended for at least 36 months if you are convicted of OUI for the second time. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12432,"isBookmarked":0,"aid":"6478#6916#12040#12432","text":"12#6#24#36"},
{"testId":1050,"qid":25221,"description":"Your license will be suspended for at least ____ months if you're convicted of OUI a second time.","explanation":"Your driver's license will be suspended for at least 36 months if you are convicted of OUI for the second time. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12432,"isBookmarked":0,"aid":"6478#6916#12040#12432","text":"12#6#24#36"},
{"testId":583,"qid":25223,"description":"Third-time OUI offenders must pay at least:","explanation":"You will be fined at least $1,100 if you are convicted of OUI for the third time. The punishment will be enhanced to $1,400 if you refused to submit to chemical testing at the time of the violation. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31708,"isBookmarked":0,"aid":"23889#23890#31708#31709","text":"$1,000#$1,500#$1,100#$1,300"},
{"testId":1050,"qid":25223,"description":"Third-time OUI offenders must pay at least:","explanation":"You will be fined at least $1,100 if you are convicted of OUI for the third time. The punishment will be enhanced to $1,400 if you refused to submit to chemical testing at the time of the violation. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31708,"isBookmarked":0,"aid":"23889#23890#31708#31709","text":"$1,000#$1,500#$1,100#$1,300"},
{"testId":583,"qid":25224,"description":"Third-time OUI convictions result in  _____聽license suspension.","explanation":"Your driver's license will be suspended for at least six years if you are convicted of OUI for the third time. You will not be given a \"work license,\" and you will be need to organize alternative transportation to and from work. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23154#29881#31710","text":"4 years#1 year#6 years#1 1/2 years"},
{"testId":1050,"qid":25224,"description":"Third-time OUI convictions result in  _____聽license suspension.","explanation":"Your driver's license will be suspended for at least six years if you are convicted of OUI for the third time. You will not be given a \"work license,\" and you will be need to organize alternative transportation to and from work. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23154#29881#31710","text":"4 years#1 year#6 years#1 1/2 years"},
{"testId":583,"qid":25225,"description":"Before your license may be reinstated for your _______ and subsequent OUI offenses, you must show proof of liability insurance.","explanation":"Before your license may be restored if you have a second or subsequent OUI conviction, you must provide evidence of liability insurance. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1050,"qid":25225,"description":"Before your license may be reinstated for your _______ and subsequent OUI offenses, you must show proof of liability insurance.","explanation":"Before your license may be restored if you have a second or subsequent OUI conviction, you must provide evidence of liability insurance. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":583,"qid":25226,"description":"If you lost your license due to an OUI conviction, your reinstated license will be conditional: your blood alcohol content (BAC) must not be higher than :","explanation":"You will be awarded a conditional license if you request to have your license restored after losing it due to a DUI conviction. The requirement is that you do not drive if your blood alcohol content (BAC) is more than zero. If this is discovered, you will face extra sanctions. [Conditional Licenses, If You Are Convicted of Driving Under the Influence or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23194#23318#27698#31711","text":"0.08%#0.02%#0.04%#Zero"},
{"testId":1050,"qid":25226,"description":"If you lost your license due to an OUI conviction, your reinstated license will be conditional: your blood alcohol content (BAC) must not be higher than :","explanation":"You will be awarded a conditional license if you request to have your license restored after losing it due to a DUI conviction. The requirement is that you do not drive if your blood alcohol content (BAC) is more than zero. If this is discovered, you will face extra sanctions. [Conditional Licenses, If You Are Convicted of Driving Under the Influence or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Staying Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23194#23318#27698#31711","text":"0.08%#0.02%#0.04%#Zero"},
{"testId":583,"qid":25227,"description":"If a driver under the age of 21 is convicted of OUI for the first time, his or her driving privileges will be revoked for ____","explanation":"If a motorist under the age of 21 is convicted of OUI, his or her license will be suspended for one year. [Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide, Blood Alcohol Level for Drivers 21 Years of Age]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#23886","text":"6 months#1 year#3 years#30 days"},
{"testId":1050,"qid":25227,"description":"If a driver under the age of 21 is convicted of OUI for the first time, his or her driving privileges will be revoked for ____","explanation":"If a motorist under the age of 21 is convicted of OUI, his or her license will be suspended for one year. [Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide, Blood Alcohol Level for Drivers 21 Years of Age]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#23886","text":"6 months#1 year#3 years#30 days"},
{"testId":583,"qid":25228,"description":"If a driver under the age of 21 is convicted of OUI and transports passengers under the age of 21, his or her license will be suspended for an extra:","explanation":"A driver under the age of 21 who is convicted of OUI and has passengers under the age of 21 in his or her vehicle at the time of the incident will have his or her license suspended for an extra 180 days. [Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide, Blood Alcohol Level for Drivers 21 Years of Age]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1050,"qid":25228,"description":"If a driver under the age of 21 is convicted of OUI and transports passengers under the age of 21, his or her license will be suspended for an extra:","explanation":"A driver under the age of 21 who is convicted of OUI and has passengers under the age of 21 in his or her vehicle at the time of the incident will have his or her license suspended for an extra 180 days. [Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide, Blood Alcohol Level for Drivers 21 Years of Age]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":583,"qid":25229,"description":"Your driving privileges may be revoked for up to ____ days if you are found guilty of street racing.","explanation":"Your license may be suspended for up to 90 days if you are convicted of street racing. [Designated Conviction Suspension Periods, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1050,"qid":25229,"description":"Your driving privileges may be revoked for up to ____ days if you are found guilty of street racing.","explanation":"Your license may be suspended for up to 90 days if you are convicted of street racing. [Designated Conviction Suspension Periods, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":583,"qid":25230,"description":"In Maine, which of the following convictions will result in a 30-day license suspension?","explanation":"Failure to stop when requested by a police officer, leaving the scene of an accident resulting in bodily injury, driving alone on a learner's permit, passing a stopped school bus whose red lights are flashing, speeding at 30 mph or more over the speed limit, falsifying or altering a license or registration certificate, loaning a license to someone else, driving under a suspended license [Designated Conviction Suspension Periods, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31712#31713#31714","text":"All of the above#Leaving the scene of an accident that resulted in bodily injury#Speeding at 30 mph or more over the speed limit#Passing a stopped school bus whose red lights are flashing"},
{"testId":1050,"qid":25230,"description":"In Maine, which of the following convictions will result in a 30-day license suspension?","explanation":"Failure to stop when requested by a police officer, leaving the scene of an accident resulting in bodily injury, driving alone on a learner's permit, passing a stopped school bus whose red lights are flashing, speeding at 30 mph or more over the speed limit, falsifying or altering a license or registration certificate, loaning a license to someone else, driving under a suspended license [Designated Conviction Suspension Periods, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31712#31713#31714","text":"All of the above#Leaving the scene of an accident that resulted in bodily injury#Speeding at 30 mph or more over the speed limit#Passing a stopped school bus whose red lights are flashing"},
{"testId":583,"qid":25231,"description":"If a motorist under the age of 21 is guilty of obtaining alcoholic drinks using false identification, his or her license will be suspended for ____ days for the first offense and ____ days for the second offense.","explanation":"If a motorist under the age of 21 is guilty of obtaining alcoholic drinks using fake identity, his or her license will be suspended for 30 days for the first offense and 90 days for the second offense. [Obtaining Liquor with False Identification, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13017,"isBookmarked":0,"aid":"13017#13018#13019#13020","text":"30; 90#60; 90#10; 30#30; 120"},
{"testId":1050,"qid":25231,"description":"If a motorist under the age of 21 is guilty of obtaining alcoholic drinks using false identification, his or her license will be suspended for ____ days for the first offense and ____ days for the second offense.","explanation":"If a motorist under the age of 21 is guilty of obtaining alcoholic drinks using fake identity, his or her license will be suspended for 30 days for the first offense and 90 days for the second offense. [Obtaining Liquor with False Identification, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13017,"isBookmarked":0,"aid":"13017#13018#13019#13020","text":"30; 90#60; 90#10; 30#30; 120"},
{"testId":583,"qid":25232,"description":"The Secretary of State will issue you a warning letter if you accumulate ____ points on your driving record.","explanation":"If you have six points on your driving record, the Secretary of State will write you a letter of warning. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1050,"qid":25232,"description":"The Secretary of State will issue you a warning letter if you accumulate ____ points on your driving record.","explanation":"If you have six points on your driving record, the Secretary of State will write you a letter of warning. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":583,"qid":25233,"description":"If you accumulate _____ or more points, your license will be suspended.","explanation":"Your license will be suspended if you acquire twelve or more points on your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1050,"qid":25233,"description":"If you accumulate _____ or more points, your license will be suspended.","explanation":"Your license will be suspended if you acquire twelve or more points on your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":583,"qid":25234,"description":"Points will stay on your record for :","explanation":"Points will stay on your driving record for one year before being deleted. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#26672#29895","text":"6 months#1 year#5 years#10 years"},
{"testId":1050,"qid":25234,"description":"Points will stay on your record for :","explanation":"Points will stay on your driving record for one year before being deleted. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#26672#29895","text":"6 months#1 year#5 years#10 years"},
{"testId":583,"qid":25235,"description":"Which of the following will result in a six-point increase on your driving record?","explanation":"Exceeding the stated speed limit by more than 15 miles per hour but less than 30 miles per hour, improper passing, driving on the wrong side of the road, and a variety of other crimes will result in the addition of six points to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25886#31011#31715","text":"All of the above#Driving on the wrong side of the road#Improper passing#Driving between 15 and 29 mph over the posted speed limit"},
{"testId":1050,"qid":25235,"description":"Which of the following will result in a six-point increase on your driving record?","explanation":"Exceeding the stated speed limit by more than 15 miles per hour but less than 30 miles per hour, improper passing, driving on the wrong side of the road, and a variety of other crimes will result in the addition of six points to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25886#31011#31715","text":"All of the above#Driving on the wrong side of the road#Improper passing#Driving between 15 and 29 mph over the posted speed limit"},
{"testId":583,"qid":25236,"description":"If you are convicted of failing to yield to an emergency vehicle, how many points will be added on your driving record?","explanation":"If you are convicted of failing to yield to an emergency vehicle, four points will be added to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1050,"qid":25236,"description":"If you are convicted of failing to yield to an emergency vehicle, how many points will be added on your driving record?","explanation":"If you are convicted of failing to yield to an emergency vehicle, four points will be added to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":583,"qid":25237,"description":"__ points will be added to your record if you fail to yield to a pedestrian.","explanation":"If you are convicted of failing to yield to a pedestrian, you will get four points on your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1050,"qid":25237,"description":"__ points will be added to your record if you fail to yield to a pedestrian.","explanation":"If you are convicted of failing to yield to a pedestrian, you will get four points on your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":583,"qid":25238,"description":"Which of the following will NOT cause you to get two points added to your driving record?","explanation":"If you are convicted of reckless driving, four points will be added to your license. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31717,"isBookmarked":0,"aid":"26681#31716#31717#31718","text":"Following too closely (tailgating)#Displaying a blue light#Imprudent driving#Making an illegal U-turn"},
{"testId":1050,"qid":25238,"description":"Which of the following will NOT cause you to get two points added to your driving record?","explanation":"If you are convicted of reckless driving, four points will be added to your license. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":31717,"isBookmarked":0,"aid":"26681#31716#31717#31718","text":"Following too closely (tailgating)#Displaying a blue light#Imprudent driving#Making an illegal U-turn"},
{"testId":583,"qid":25239,"description":"A littering conviction will result in ____ points being added to your driving record.","explanation":"A littering conviction will add two points to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1050,"qid":25239,"description":"A littering conviction will result in ____ points being added to your driving record.","explanation":"A littering conviction will add two points to your driving record. [Suspension for Demerit Point Accumulation: The Maine Point System, Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":583,"qid":25240,"description":"Which of the following violations will result in the addition of two points to your driving record if you are convicted?","explanation":"Squealing tires, blocking traffic flow, and failing to lower headlights when necessary are all offenses that will score you two points if you are guilty. There are other additional crimes that may score you two points. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31719#31720#31721","text":"All of the above#Squealing tires#Failing to dim headlights#Obstructing the flow of traffic"},
{"testId":1050,"qid":25240,"description":"Which of the following violations will result in the addition of two points to your driving record if you are convicted?","explanation":"Squealing tires, blocking traffic flow, and failing to lower headlights when necessary are all offenses that will score you two points if you are guilty. There are other additional crimes that may score you two points. [Suspension for Demerit Point Accumulation: The Maine Point System, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31719#31720#31721","text":"All of the above#Squealing tires#Failing to dim headlights#Obstructing the flow of traffic"},
{"testId":583,"qid":25241,"description":null,"explanation":"Maine's Violation-Free Credit System awards you one credit point(s) for each year you drive without accruing demerit points or having your license suspended. These credit points may be used to offset demerit points received from traffic infraction convictions once per year. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1050,"qid":25241,"description":null,"explanation":"Maine's Violation-Free Credit System awards you one credit point(s) for each year you drive without accruing demerit points or having your license suspended. These credit points may be used to offset demerit points received from traffic infraction convictions once per year. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":583,"qid":25242,"description":"How many points for Violation Free Credit can you earn?","explanation":"A Maine motorist may earn up to four Violation Free Credit points for four years of no convictions or license suspensions. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1050,"qid":25242,"description":"How many points for Violation Free Credit can you earn?","explanation":"A Maine motorist may earn up to four Violation Free Credit points for four years of no convictions or license suspensions. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":583,"qid":25244,"description":"If you have had _______ suspensions in the past three years, you might lose your license for another 120 days.","explanation":"If your license has been suspended three times in the last three years, it may be suspended again for up to 120 days. This suspension time is in addition to the suspension durations imposed for the other three crimes. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1050,"qid":25244,"description":"If you have had _______ suspensions in the past three years, you might lose your license for another 120 days.","explanation":"If your license has been suspended three times in the last three years, it may be suspended again for up to 120 days. This suspension time is in addition to the suspension durations imposed for the other three crimes. Maintaining Your Driver's License, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":583,"qid":25245,"description":"Which of the following is one of the most common licence suspension reasons?","explanation":"Failure to respond to a summons to court or pay associated charges is one of the most prevalent causes for license suspension in Maine. These violations result in complete, obligatory license suspension. There are no exceptions, and there is no \"work license.\" You will need to make other transportation arrangements to and from work. [Mandatory Suspensions, Part 1: Maine Driver's License, Motorist Handbook, and Study Guide]","imageName":"","correctAnswer":31722,"isBookmarked":0,"aid":"897#31722#31723#31724","text":"None of the above#Failure to answer a summons to court or pay a traffic-related fine#Conviction for OUI#Multiple speeding convictions"},
{"testId":1050,"qid":25245,"description":"Which of the following is one of the most common licence suspension reasons?","explanation":"Failure to respond to a summons to court or pay associated charges is one of the most prevalent causes for license suspension in Maine. These violations result in complete, obligatory license suspension. There are no exceptions, and there is no \"work license.\" You will need to make other transportation arrangements to and from work. [Mandatory Suspensions, Part 1: Maine Driver's License, Motorist Handbook, and Study Guide]","imageName":"","correctAnswer":31722,"isBookmarked":0,"aid":"897#31722#31723#31724","text":"None of the above#Failure to answer a summons to court or pay a traffic-related fine#Conviction for OUI#Multiple speeding convictions"},
{"testId":585,"qid":25246,"description":"In Massachusetts, you must respond to a traffic citation within:","explanation":"In Massachusetts, you have 20 days to reply to a traffic ticket. If you do not react by then, you will be held accountable and charged a significant late fee. [Civil Motor Vehicle Infractions; Penalties for Motor Vehicle Violations; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":23885,"isBookmarked":0,"aid":"23883#23885#23886#24048","text":"10 days#20 days#30 days#60 days"},
{"testId":1052,"qid":25246,"description":"In Massachusetts, you must respond to a traffic citation within:","explanation":"In Massachusetts, you have 20 days to reply to a traffic ticket. If you do not react by then, you will be held accountable and charged a significant late fee. [Civil Motor Vehicle Infractions; Penalties for Motor Vehicle Violations; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":23885,"isBookmarked":0,"aid":"23883#23885#23886#24048","text":"10 days#20 days#30 days#60 days"},
{"testId":585,"qid":25247,"description":"Which of the following is NOT a traffic violation?","explanation":"Parking citations are not considered traffic infractions. Failure to pay parking penalties in Massachusetts, on the other hand, may prohibit you from renewing your license or registering your car. [Civil Motor Vehicle Infractions; Penalties for Motor Vehicle Violations; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":25884,"isBookmarked":0,"aid":"25883#25884#25885#25886","text":"A speeding ticket#A parking ticket#Failure to obey traffic signals#Driving on the wrong side of the road"},
{"testId":1052,"qid":25247,"description":"Which of the following is NOT a traffic violation?","explanation":"Parking citations are not considered traffic infractions. Failure to pay parking penalties in Massachusetts, on the other hand, may prohibit you from renewing your license or registering your car. [Civil Motor Vehicle Infractions; Penalties for Motor Vehicle Violations; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":25884,"isBookmarked":0,"aid":"25883#25884#25885#25886","text":"A speeding ticket#A parking ticket#Failure to obey traffic signals#Driving on the wrong side of the road"},
{"testId":585,"qid":25248,"description":"What is the lowest possible fine for speeding?","explanation":"The most serious punishment for speeding is a fine of $105. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":13034,"isBookmarked":0,"aid":"11348#11349#11350#13034","text":"$25#$50#$125#$105"},
{"testId":1052,"qid":25248,"description":"What is the lowest possible fine for speeding?","explanation":"The most serious punishment for speeding is a fine of $105. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":13034,"isBookmarked":0,"aid":"11348#11349#11350#13034","text":"$25#$50#$125#$105"},
{"testId":585,"qid":25249,"description":"The penalty for exceeding the speed limit is $105 for the first ten miles per hour, plus _____ for each subsequent mile per hour.","explanation":"The fine for exceeding the speed limit is $105 for the first ten miles per hour, plus $10 for each subsequent mile per hour. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":11351,"isBookmarked":0,"aid":"11351#12862#13035#13036","text":"$10#$1#$5#$8"},
{"testId":1052,"qid":25249,"description":"The penalty for exceeding the speed limit is $105 for the first ten miles per hour, plus _____ for each subsequent mile per hour.","explanation":"The fine for exceeding the speed limit is $105 for the first ten miles per hour, plus $10 for each subsequent mile per hour. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":11351,"isBookmarked":0,"aid":"11351#12862#13035#13036","text":"$10#$1#$5#$8"},
{"testId":585,"qid":25250,"description":"A Massachusetts driver's license will be suspended for 30 days if he or she gets convicted of ___ speeding offences within a year.","explanation":"A Massachusetts driver's license will be suspended for 30 days if he or she is guilty of three speeding offences within a year. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1052,"qid":25250,"description":"A Massachusetts driver's license will be suspended for 30 days if he or she gets convicted of ___ speeding offences within a year.","explanation":"A Massachusetts driver's license will be suspended for 30 days if he or she is guilty of three speeding offences within a year. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":585,"qid":25251,"description":"If a Junior Operator's License holder is convicted of his or her first speeding infraction, he or she will lose driving privileges for ______.","explanation":"If a Massachusetts Junior Operator gets convicted of his or her first speeding violation, his or her license will be suspended for 90 days. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1052,"qid":25251,"description":"If a Junior Operator's License holder is convicted of his or her first speeding infraction, he or she will lose driving privileges for ______.","explanation":"If a Massachusetts Junior Operator gets convicted of his or her first speeding violation, his or her license will be suspended for 90 days. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":585,"qid":25252,"description":"If a Massachusetts motorist with a Junior Operator's License gets convicted of a second or subsequent speeding violation, his or her license will be suspended for a period of:","explanation":"A Massachusetts Junior Operator's license will be suspended for one year if he or she is guilty of a second or subsequent speeding conviction. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":1052,"qid":25252,"description":"If a Massachusetts motorist with a Junior Operator's License gets convicted of a second or subsequent speeding violation, his or her license will be suspended for a period of:","explanation":"A Massachusetts Junior Operator's license will be suspended for one year if he or she is guilty of a second or subsequent speeding conviction. [Speeding Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":585,"qid":25253,"description":"Which of the following is likely to occur if a Massachusetts driver commits a criminal traffic offense?","explanation":"If a Massachusetts driver is charged with a criminal traffic infraction, his or her car may be towed, he or she may be detained immediately, kept in prison until his or her court date, and his or her license may be revoked. [Criminal Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32601,"isBookmarked":0,"aid":"32598#32599#32600#32601","text":"He or she may be arrested immediately#His or her vehicle may be towed#He or she may be sent to jail immediately#All of the above may occur"},
{"testId":1052,"qid":25253,"description":"Which of the following is likely to occur if a Massachusetts driver commits a criminal traffic offense?","explanation":"If a Massachusetts driver is charged with a criminal traffic infraction, his or her car may be towed, he or she may be detained immediately, kept in prison until his or her court date, and his or her license may be revoked. [Criminal Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32601,"isBookmarked":0,"aid":"32598#32599#32600#32601","text":"He or she may be arrested immediately#His or her vehicle may be towed#He or she may be sent to jail immediately#All of the above may occur"},
{"testId":585,"qid":25254,"description":"Criminal charges may be brought if you refuse to do one of the following during a traffic stop:","explanation":"During a traffic stop, you must provide the officer your name and the owner's name, sign your name, and show your driver's license and registration. Refusing to undertake any of these might lead to criminal penalties. [Criminal Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#32602#32603#39241","text":"Any of the above#Tell a police officer who the owner of the vehicle is#Sign your name in front of a police officer#Tell a police officer your name and address"},
{"testId":1052,"qid":25254,"description":"Criminal charges may be brought if you refuse to do one of the following during a traffic stop:","explanation":"During a traffic stop, you must provide the officer your name and the owner's name, sign your name, and show your driver's license and registration. Refusing to undertake any of these might lead to criminal penalties. [Criminal Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#32602#32603#39241","text":"Any of the above#Tell a police officer who the owner of the vehicle is#Sign your name in front of a police officer#Tell a police officer your name and address"},
{"testId":585,"qid":25255,"description":"If a Massachusetts driver's license is suspended in another state,:","explanation":"If an MA driver's driving privileges are suspended or revoked in another state, his or her license in MA will be automatically suspended. [Out-of-State Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32604,"isBookmarked":0,"aid":"24989#31956#32604#32605","text":"none of the above will occur#he or she will still be allowed to drive in MA#his or her license will also be suspended in MA#he or she will be required to pay a fine in MA"},
{"testId":1052,"qid":25255,"description":"If a Massachusetts driver's license is suspended in another state,:","explanation":"If an MA driver's driving privileges are suspended or revoked in another state, his or her license in MA will be automatically suspended. [Out-of-State Violations; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32604,"isBookmarked":0,"aid":"24989#31956#32604#32605","text":"none of the above will occur#he or she will still be allowed to drive in MA#his or her license will also be suspended in MA#he or she will be required to pay a fine in MA"},
{"testId":585,"qid":25256,"description":"If an MA driver is found to be more than ____ at fault in an accident, his or her driving record will reflect this.","explanation":"If a Massachusetts motorist is found to be more than 50% at blame in an accident, the at-fault accident will be recorded on his or her driving record. Accidents in which you are at fault are grounds for license suspension. [At-Fault Accidents; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"202006141611292838.jpg","correctAnswer":11667,"isBookmarked":0,"aid":"11665#11667#13496#32606","text":"25%#50%#75%#90%"},
{"testId":585,"qid":25257,"description":"What is the official date of an offense for computing surchargeable conviction penalties?","explanation":"The official date for determining surchargeable conviction penalties is the day of conviction. The driver's conviction date is either the day he or she was found guilty in court or the day he or she paid the fine, admitting guilt. [Surchargeable Events; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":27215,"isBookmarked":0,"aid":"27213#27215#32607#32608","text":"The date of violation#The date of conviction#The date that the conviction was reported to the driver's insurance company#The expiration date of the driver's license"},
{"testId":1052,"qid":25257,"description":"What is the official date of an offense for computing surchargeable conviction penalties?","explanation":"The official date for determining surchargeable conviction penalties is the day of conviction. The driver's conviction date is either the day he or she was found guilty in court or the day he or she paid the fine, admitting guilt. [Surchargeable Events; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":27215,"isBookmarked":0,"aid":"27213#27215#32607#32608","text":"The date of violation#The date of conviction#The date that the conviction was reported to the driver's insurance company#The expiration date of the driver's license"},
{"testId":585,"qid":25258,"description":"The suspension period for a Junior Operator's License driver who is guilty of drag racing for the second time is ______.","explanation":"If a Massachusetts motorist with a Junior Operator's License gets convicted for the second time of drag racing, his or her license will be suspended for three years. [Surchargeable Events; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23154#23183#23886#26672","text":"1 year#3 years#30 days#5 years"},
{"testId":1052,"qid":25258,"description":"The suspension period for a Junior Operator's License driver who is guilty of drag racing for the second time is ______.","explanation":"If a Massachusetts motorist with a Junior Operator's License gets convicted for the second time of drag racing, his or her license will be suspended for three years. [Surchargeable Events; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23154#23183#23886#26672","text":"1 year#3 years#30 days#5 years"},
{"testId":585,"qid":25259,"description":"If a motorist is convicted for the second time of using a handheld mobile device while driving, he or she will _______.","explanation":"If you are discovered using a hand-held phone while driving, you will be fined $250 and must complete a distracted driving instructional program. [Law Violation Penalties; Chapter 3 Safety First; Massachusetts Driver's Manual]","imageName":"","correctAnswer":45206,"isBookmarked":0,"aid":"36879#45204#45205#45206","text":"do either of the above#have to pay a $250 fine#have to complete a mandatory Distracted Driving program#do both of the above"},
{"testId":585,"qid":25260,"description":"An adult motorist will be charged with operating under the influence(OUI) if their blood alcohol content (BAC) is ______ or higher while they are behind the wheel.","explanation":"If you are caught driving with a blood alcohol content (BAC) of 0.08 percent or more, you will be charged with operating under the influence (OUI), which carries serious consequences. [Alcohol, Drugs, and Driving; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1052,"qid":25260,"description":"An adult motorist will be charged with operating under the influence(OUI) if their blood alcohol content (BAC) is ______ or higher while they are behind the wheel.","explanation":"If you are caught driving with a blood alcohol content (BAC) of 0.08 percent or more, you will be charged with operating under the influence (OUI), which carries serious consequences. [Alcohol, Drugs, and Driving; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":585,"qid":25261,"description":"If you accumulate _____,You will be required to complete the Massachusetts Driver Retraining Course.","explanation":"If you are convicted of three or more surchargeable offenses within two years, you must complete the state's Driver Retraining Course. [Motor Vehicle Violations and Penalties; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":32611,"isBookmarked":0,"aid":"32609#32610#32611#32612","text":"4 or more surchargeable events on your record within 3 years#3 or more surchargeable events on your record within 3 years#3 or more surchargeable events on your record within 2 years#2 or more surchargeable events on your record within 5 years"},
{"testId":1052,"qid":25261,"description":"If you accumulate _____,You will be required to complete the Massachusetts Driver Retraining Course.","explanation":"If you are convicted of three or more surchargeable offenses within two years, you must complete the state's Driver Retraining Course. [Motor Vehicle Violations and Penalties; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":32611,"isBookmarked":0,"aid":"32609#32610#32611#32612","text":"4 or more surchargeable events on your record within 3 years#3 or more surchargeable events on your record within 3 years#3 or more surchargeable events on your record within 2 years#2 or more surchargeable events on your record within 5 years"},
{"testId":585,"qid":25263,"description":"If you are required to complete the Driver Retraining Course, you must do so within a certain time frame.","explanation":"If the RMV tells you that the Driver Retraining Course is required, you have 90 days to finish it. If you do not comply, your license will be suspended indefinitely until you finish the course. [Motor Vehicle Violations and Penalties; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1052,"qid":25263,"description":"If you are required to complete the Driver Retraining Course, you must do so within a certain time frame.","explanation":"If the RMV tells you that the Driver Retraining Course is required, you have 90 days to finish it. If you do not comply, your license will be suspended indefinitely until you finish the course. [Motor Vehicle Violations and Penalties; Chapter 2: Maintaining Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":585,"qid":25264,"description":"If you accumulate ____ significant moving offences in a 5-year period, you will be classified a habitual offender in Massachusetts.","explanation":"If you have three serious moving infractions within a five-year period, you will be deemed a habitual offender in Massachusetts. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1052,"qid":25264,"description":"If you accumulate ____ significant moving offences in a 5-year period, you will be classified a habitual offender in Massachusetts.","explanation":"If you have three serious moving infractions within a five-year period, you will be deemed a habitual offender in Massachusetts. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":585,"qid":25265,"description":"In Massachusetts, you are deemed a habitual offender if you have ____ major or minor moving infractions within a 5-year period.","explanation":"In Massachusetts, you are deemed a habitual offender if you have twelve major or minor traffic offences during a five-year period. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#11342#11343","text":"10#12#9#11"},
{"testId":1052,"qid":25265,"description":"In Massachusetts, you are deemed a habitual offender if you have ____ major or minor moving infractions within a 5-year period.","explanation":"In Massachusetts, you are deemed a habitual offender if you have twelve major or minor traffic offences during a five-year period. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#11342#11343","text":"10#12#9#11"},
{"testId":585,"qid":25267,"description":"A habitual offender whose license has been suspended must pay ______ to have it renewed.","explanation":"A habitual offender whose license has been suspended must pay a $500 reinstatement fee. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":1052,"qid":25267,"description":"A habitual offender whose license has been suspended must pay ______ to have it renewed.","explanation":"A habitual offender whose license has been suspended must pay a $500 reinstatement fee. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":585,"qid":25268,"description":"If your license was suspended in Massachusetts because it had been suspended in another state, you must pay a fee of ____ to get it reinstated.","explanation":"If your license was suspended in Massachusetts because your driving privileges had been suspended in another state, you will not be able to reapply for a license until the out-of-state suspension is resolved. After that, you must pay a charge of $100 to get your license reactivated. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":1052,"qid":25268,"description":"If your license was suspended in Massachusetts because it had been suspended in another state, you must pay a fee of ____ to get it reinstated.","explanation":"If your license was suspended in Massachusetts because your driving privileges had been suspended in another state, you will not be able to reapply for a license until the out-of-state suspension is resolved. After that, you must pay a charge of $100 to get your license reactivated. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":585,"qid":25270,"description":"Which of the following non-driving infractions may result in license suspension in Massachusetts?","explanation":"Your MA license may be suspended if you fail to pay child support, fail to register as a sex offender, fail to pay Massachusetts income tax, have an outstanding arrest or default warrant, have been convicted of certain drug trafficking offenses, or have made a bad payment (such as a bounced check) to the RMV. If you paint graffiti on private or public property, your MA license may be suspended. Furthermore, if your MA license was suspended or revoked out of state, it will be suspended in MA as well. [Reasons for License Suspension; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31964#31965#32613","text":"All of the above#Failure to register as a sex offender#Drug offenses#Failure to pay required child support"},
{"testId":1052,"qid":25270,"description":"Which of the following non-driving infractions may result in license suspension in Massachusetts?","explanation":"Your MA license may be suspended if you fail to pay child support, fail to register as a sex offender, fail to pay Massachusetts income tax, have an outstanding arrest or default warrant, have been convicted of certain drug trafficking offenses, or have made a bad payment (such as a bounced check) to the RMV. If you paint graffiti on private or public property, your MA license may be suspended. Furthermore, if your MA license was suspended or revoked out of state, it will be suspended in MA as well. [Reasons for License Suspension; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31964#31965#32613","text":"All of the above#Failure to register as a sex offender#Drug offenses#Failure to pay required child support"},
{"testId":585,"qid":25271,"description":"Which of the following is TRUE about using cell phones while driving in Massachusetts?","explanation":"Drivers under the age of 18 are not permitted to use any mobile electronic device while driving for any purpose. The sole exception is in the case of an emergency. Adult drivers 18 and older may only use hands-free devices. [Hands-Free Cell Phone Use; Massachusetts Driver's Manual; Chapter 3 Safety First]","imageName":"","correctAnswer":45208,"isBookmarked":0,"aid":"45207#45208#45209#45210","text":"Drivers 18 years of age or older may use their devices for text messaging#Drivers 18 years of age or older may use hands-free devices#Drivers under 18 may use hand-held devices only for navigation purposes#Drivers under 18 may use hands-free devices only"},
{"testId":585,"qid":25273,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. The Junior Operator's permit will be suspended for ________ after three or more offences for driving without adult supervision.","explanation":"If a Junior Operator with a learner's licence gets convicted three times or more of driving unaccompanied, his or her permission will be suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":1052,"qid":25273,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. The Junior Operator's permit will be suspended for ________ after three or more offences for driving without adult supervision.","explanation":"If a Junior Operator with a learner's licence gets convicted three times or more of driving unaccompanied, his or her permission will be suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":585,"qid":25274,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. The Junior Operator's permit will be suspended if he or she is convicted of driving without adult supervision twice and he or she will be required to :","explanation":"If a Junior Operator with a learner's permit is convicted twice of driving without adult supervision, his or her permission will be suspended, and he or she must successfully complete a Driver Attitudinal Retraining Course. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32614,"isBookmarked":0,"aid":"22727#31967#31968#32614","text":"Do none of the above#complete a Defensive Driving Course#meet with an RMV official#complete a Driver Attitudinal Retraining Course"},
{"testId":1052,"qid":25274,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. The Junior Operator's permit will be suspended if he or she is convicted of driving without adult supervision twice and he or she will be required to :","explanation":"If a Junior Operator with a learner's permit is convicted twice of driving without adult supervision, his or her permission will be suspended, and he or she must successfully complete a Driver Attitudinal Retraining Course. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32614,"isBookmarked":0,"aid":"22727#31967#31968#32614","text":"Do none of the above#complete a Defensive Driving Course#meet with an RMV official#complete a Driver Attitudinal Retraining Course"},
{"testId":585,"qid":25276,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit is not permitted to drive unattended between 12:00 a.m. and 5:00 a.m. If motorist is convicted for the third time of driving unsupervised between 12:00 a.m. and 5:00 a.m., motorist's driver's permit will be suspeded for:","explanation":"If a Junior Operator with a learner's permit is convicted of driving unattended for the third time between 12:00 a.m. and 5:00 a.m., his or her permission will be suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1052,"qid":25276,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit is not permitted to drive unattended between 12:00 a.m. and 5:00 a.m. If motorist is convicted for the third time of driving unsupervised between 12:00 a.m. and 5:00 a.m., motorist's driver's permit will be suspeded for:","explanation":"If a Junior Operator with a learner's permit is convicted of driving unattended for the third time between 12:00 a.m. and 5:00 a.m., his or her permission will be suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":585,"qid":25277,"description":"One speeding offense for a Massachusetts motorist with a Junior Operator License will result in a suspension for: :","explanation":"A speeding conviction in Massachusetts will result in a 90-day suspension for a motorist with a Junior Operator License. His or her license will be suspended for a year if he or she is convicted of speeding again. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1052,"qid":25277,"description":"One speeding offense for a Massachusetts motorist with a Junior Operator License will result in a suspension for: :","explanation":"A speeding conviction in Massachusetts will result in a 90-day suspension for a motorist with a Junior Operator License. His or her license will be suspended for a year if he or she is convicted of speeding again. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":585,"qid":25278,"description":"How long will a driver's license be suspended for two or more speeding offenses by a junior operator (i.e., someone under the age of 18) who holds a learner's permit?","explanation":"Two or more speeding offences for a Junior Operator with a learner's permit will result in a one-year ban. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":1052,"qid":25278,"description":"How long will a driver's license be suspended for two or more speeding offenses by a junior operator (i.e., someone under the age of 18) who holds a learner's permit?","explanation":"Two or more speeding offences for a Junior Operator with a learner's permit will result in a one-year ban. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":585,"qid":25279,"description":"After ____ speeding conviction(s), a Junior Operator (i.e., someone under the age of 18) with a learner's permit must retake the learner's permit exam.","explanation":"A Junior Operator with a learner's permit must retake and pass the learner's permit test after just one speeding offense. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1052,"qid":25279,"description":"After ____ speeding conviction(s), a Junior Operator (i.e., someone under the age of 18) with a learner's permit must retake the learner's permit exam.","explanation":"A Junior Operator with a learner's permit must retake and pass the learner's permit test after just one speeding offense. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":585,"qid":25280,"description":"How long will a driver forfeit his or her driving privileges for a first offense of drag racing by a junior operator (i.e., someone under the age of 18)?","explanation":"A Junior Operator with a learner's permit who commits a first offense of drag racing shall forfeit his or her driving privileges for one year. In addition, he or she must repeat the learner's permit test and complete a Driver Attitudinal Retraining course. He or she may be obliged to take a State Courts Against Road Rage (SCARR) course as well. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23886#24048#25032#25169","text":"30 days#60 days#24 months#12 months"},
{"testId":1052,"qid":25280,"description":"How long will a driver forfeit his or her driving privileges for a first offense of drag racing by a junior operator (i.e., someone under the age of 18)?","explanation":"A Junior Operator with a learner's permit who commits a first offense of drag racing shall forfeit his or her driving privileges for one year. In addition, he or she must repeat the learner's permit test and complete a Driver Attitudinal Retraining course. He or she may be obliged to take a State Courts Against Road Rage (SCARR) course as well. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23886#24048#25032#25169","text":"30 days#60 days#24 months#12 months"},
{"testId":585,"qid":25281,"description":"A Junior motorist (i.e., someone under the age of 18) whose learner's permit has been suspended for the second time for drag racing must pay a charge of ______ to have his or her permission reinstated.","explanation":"A Junior Operator whose learner's permit was suspended for drag racing must pay a $500 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":1052,"qid":25281,"description":"A Junior motorist (i.e., someone under the age of 18) whose learner's permit has been suspended for the second time for drag racing must pay a charge of ______ to have his or her permission reinstated.","explanation":"A Junior Operator whose learner's permit was suspended for drag racing must pay a $500 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11832#11986#12395","text":"$500#$100#$750#$250"},
{"testId":585,"qid":25282,"description":"A Junior motorist (i.e., someone under the age of 18) whose learner's permit has been suspended for the second time for drag racing must pay a charge of ______ to have his or her permission reinstated.","explanation":"A Junior Operator whose learner's permit has been suspended for the second time for drag racing must pay a $1,000 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":1052,"qid":25282,"description":"A Junior motorist (i.e., someone under the age of 18) whose learner's permit has been suspended for the second time for drag racing must pay a charge of ______ to have his or her permission reinstated.","explanation":"A Junior Operator whose learner's permit has been suspended for the second time for drag racing must pay a $1,000 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":585,"qid":25283,"description":"If a Junior Operator (i.e., someone under the age of 18) with a learner's permit gets convicted for the second time of drag racing, his or her driving permit will be suspended for a period of  ____ years.","explanation":"If a Junior Operator with a learner's licence gets convicted for the second time of drag racing, his or her permission will be suspended for three years. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1052,"qid":25283,"description":"If a Junior Operator (i.e., someone under the age of 18) with a learner's permit gets convicted for the second time of drag racing, his or her driving permit will be suspended for a period of  ____ years.","explanation":"If a Junior Operator with a learner's licence gets convicted for the second time of drag racing, his or her permission will be suspended for three years. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":585,"qid":25284,"description":"Who will be informed of the traffic offenses of a learner's permit-holding Junior Operator (i.e., a driver younger than 18)?","explanation":"A Junior Operator must receive written approval from his or her parent or guardian in order to obtain a learner's permit. The parent or guardian will be informed if the Junior Operator is convicted of a traffic offense. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32616,"isBookmarked":0,"aid":"897#31970#32615#32616","text":"None of the above#His or her teacher#His or her school principal#His or her parent or guardian"},
{"testId":1052,"qid":25284,"description":"Who will be informed of the traffic offenses of a learner's permit-holding Junior Operator (i.e., a driver younger than 18)?","explanation":"A Junior Operator must receive written approval from his or her parent or guardian in order to obtain a learner's permit. The parent or guardian will be informed if the Junior Operator is convicted of a traffic offense. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":32616,"isBookmarked":0,"aid":"897#31970#32615#32616","text":"None of the above#His or her teacher#His or her school principal#His or her parent or guardian"},
{"testId":585,"qid":25285,"description":"A driver holding a Junior Operator license will have his or her license suspended for ______ if convicted of three or more violations of the license's passenger or nighttime driving limitations.","explanation":"A Massachusetts motorist with a Junior Operator license who is guilty of three or more infractions of the license's passenger or nighttime driving limitations will have his or her license suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1052,"qid":25285,"description":"A driver holding a Junior Operator license will have his or her license suspended for ______ if convicted of three or more violations of the license's passenger or nighttime driving limitations.","explanation":"A Massachusetts motorist with a Junior Operator license who is guilty of three or more infractions of the license's passenger or nighttime driving limitations will have his or her license suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":585,"qid":25286,"description":"What is the reinstatement fee if a junior operator's driver licence is suspended for a speeding offense?","explanation":"The reinstatement cost for a Junior Operator whose license has been suspended due to excessive speed is $500. A Junior Operator whose learner's permit was suspended for speeding must pay a $100 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1052,"qid":25286,"description":"What is the reinstatement fee if a junior operator's driver licence is suspended for a speeding offense?","explanation":"The reinstatement cost for a Junior Operator whose license has been suspended due to excessive speed is $500. A Junior Operator whose learner's permit was suspended for speeding must pay a $100 reinstatement fee. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":585,"qid":25287,"description":"If a Junior Operator (someone under the age of 18) with a driver's license is convicted of reckless driving for the first time, he or she will lose his or her driving privileges for a period of:","explanation":"If a Junior Operator with a driver's license is convicted of reckless driving for the first time, his or her driving rights will be suspended for 180 days. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"20788#20789#23154#25858","text":"2 years#9 months#1 year#180 days"},
{"testId":1052,"qid":25287,"description":"If a Junior Operator (someone under the age of 18) with a driver's license is convicted of reckless driving for the first time, he or she will lose his or her driving privileges for a period of:","explanation":"If a Junior Operator with a driver's license is convicted of reckless driving for the first time, his or her driving rights will be suspended for 180 days. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"20788#20789#23154#25858","text":"2 years#9 months#1 year#180 days"},
{"testId":585,"qid":25288,"description":"If a Junior Operator (i.e., someone under the age of 18) holding a driver's license gets convicted of reckless driving for the second time within three years, his or her driver's license will be suspended for a period of:","explanation":"If a Junior Operator with a driver's license is convicted of reckless driving for the second time in three years, his or her license is suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#25031#27027","text":"2 years#1 year#18 months#36 months"},
{"testId":1052,"qid":25288,"description":"If a Junior Operator (i.e., someone under the age of 18) holding a driver's license gets convicted of reckless driving for the second time within three years, his or her driver's license will be suspended for a period of:","explanation":"If a Junior Operator with a driver's license is convicted of reckless driving for the second time in three years, his or her license is suspended for one year. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#25031#27027","text":"2 years#1 year#18 months#36 months"},
{"testId":585,"qid":25289,"description":"How long can your license be taken away if you leave the scene of an accident where someone was hurt?","explanation":"If you leave the scene of a personal injury collision, your driving privileges will be banned for one to two years. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":1052,"qid":25289,"description":"How long can your license be taken away if you leave the scene of an accident where someone was hurt?","explanation":"If you leave the scene of a personal injury collision, your driving privileges will be banned for one to two years. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":585,"qid":25290,"description":"If you are convicted of vehicular manslaughter, your license will be suspended for at least:","explanation":"Your license will be suspended for at least 15 years if you are convicted of vehicular manslaughter. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":28011,"isBookmarked":0,"aid":"28011#32617#32618#32619","text":"15 years#30 years#25 years#20 years"},
{"testId":1052,"qid":25290,"description":"If you are convicted of vehicular manslaughter, your license will be suspended for at least:","explanation":"Your license will be suspended for at least 15 years if you are convicted of vehicular manslaughter. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":28011,"isBookmarked":0,"aid":"28011#32617#32618#32619","text":"15 years#30 years#25 years#20 years"},
{"testId":585,"qid":25291,"description":"How long will a driver's license be suspended for a fifth offense of operating under the influence?","explanation":"If you are found guilty of OUI five times, your licence will be permanently suspended. [Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual; Criminal Offenses and Suspensions; Mandatory License Suspensions; License Suspension or Revocation]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"26672#28011#29895#32620","text":"5 years#15 years#10 years#Life"},
{"testId":1052,"qid":25291,"description":"How long will a driver's license be suspended for a fifth offense of operating under the influence?","explanation":"If you are found guilty of OUI five times, your licence will be permanently suspended. [Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual; Criminal Offenses and Suspensions; Mandatory License Suspensions; License Suspension or Revocation]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"26672#28011#29895#32620","text":"5 years#15 years#10 years#Life"},
{"testId":585,"qid":25292,"description":"The reinstatement fee is _______ if your license was suspended for driving while suspended or revoked.","explanation":"The reinstatement cost is $500 if your license was suspended for driving while suspended or revoked. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1052,"qid":25292,"description":"The reinstatement fee is _______ if your license was suspended for driving while suspended or revoked.","explanation":"The reinstatement cost is $500 if your license was suspended for driving while suspended or revoked. [Criminal Offenses and Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":585,"qid":25293,"description":"To get your driver's license back after it was taken away for a fourth OUI conviction, you'll need to pay a fee of _______.","explanation":"To have your license restored after a license suspension for a fourth OUI conviction, you must pay a cost of $1,200. [Chart of Criminal Offenses and Suspensions; Suspension or Revocation of a License; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":13062,"isBookmarked":0,"aid":"290#292#1799#13062","text":"$500#$700#$1,000#$1,200"},
{"testId":1052,"qid":25293,"description":"To get your driver's license back after it was taken away for a fourth OUI conviction, you'll need to pay a fee of _______.","explanation":"To have your license restored after a license suspension for a fourth OUI conviction, you must pay a cost of $1,200. [Chart of Criminal Offenses and Suspensions; Suspension or Revocation of a License; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":13062,"isBookmarked":0,"aid":"290#292#1799#13062","text":"$500#$700#$1,000#$1,200"},
{"testId":585,"qid":25294,"description":"A first offense of operating under the influence (OUI) in Massachusetts carries a maximum jail penalty of:","explanation":"In Massachusetts, a first-time OUI offender faces up to 2 1/2 years in prison. [Driving Under the Influence of Alcohol or Drugs; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":32621,"isBookmarked":0,"aid":"23153#23154#23886#32621","text":"6 months#1 year#30 days#2.5 years"},
{"testId":1052,"qid":25294,"description":"A first offense of operating under the influence (OUI) in Massachusetts carries a maximum jail penalty of:","explanation":"In Massachusetts, a first-time OUI offender faces up to 2 1/2 years in prison. [Driving Under the Influence of Alcohol or Drugs; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":32621,"isBookmarked":0,"aid":"23153#23154#23886#32621","text":"6 months#1 year#30 days#2.5 years"},
{"testId":585,"qid":25295,"description":"In Massachusetts, the _______ and subsequent OUI crimes are felonies.","explanation":"In Massachusetts, the third and subsequent OUI crimes are felonies. [Driving Under the Influence of Alcohol or Drugs; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1052,"qid":25295,"description":"In Massachusetts, the _______ and subsequent OUI crimes are felonies.","explanation":"In Massachusetts, the third and subsequent OUI crimes are felonies. [Driving Under the Influence of Alcohol or Drugs; License Suspension or Revocation; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":587,"qid":25297,"description":"You must shift over a lane or slow down when passing a stopped emergency vehicle, according to Minnesota's Ted Foss Move Over Law. If you do not comply, you will be penalized at least:","explanation":"You must shift over a lane or slow down while passing a stopped emergency vehicle, according to Minnesota's Ted Foss Move Over Law. If you do not comply, you will be fined at least $100. (The statute was named after Ted Foss, a state trooper who was hit and killed by a vehicle while conducting a traffic check on I-90.) He was married with two small children.) [Move Over Law, Minnesota Department of Public Safety, https://dps.mn.gov/divisions/ots/move-over-law/Pages/default] .aspx]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1054,"qid":25297,"description":"You must shift over a lane or slow down when passing a stopped emergency vehicle, according to Minnesota's Ted Foss Move Over Law. If you do not comply, you will be penalized at least:","explanation":"You must shift over a lane or slow down while passing a stopped emergency vehicle, according to Minnesota's Ted Foss Move Over Law. If you do not comply, you will be fined at least $100. (The statute was named after Ted Foss, a state trooper who was hit and killed by a vehicle while conducting a traffic check on I-90.) He was married with two small children.) [Move Over Law, Minnesota Department of Public Safety, https://dps.mn.gov/divisions/ots/move-over-law/Pages/default] .aspx]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":587,"qid":25298,"description":"Which of the following could cause your license to be taken away?","explanation":"A Minnesota driver's license may be suspended for multiple traffic offences, possession of a forged license, failure to pay a traffic fee when required, and a variety of other offenses. [Suspension, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33446#33447#33448","text":"All of the above#Repeatedly violating traffic laws#Possessing a fake license#Failing to pay a traffic fine when required to do so"},
{"testId":1054,"qid":25298,"description":"Which of the following could cause your license to be taken away?","explanation":"A Minnesota driver's license may be suspended for multiple traffic offences, possession of a forged license, failure to pay a traffic fee when required, and a variety of other offenses. [Suspension, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33446#33447#33448","text":"All of the above#Repeatedly violating traffic laws#Possessing a fake license#Failing to pay a traffic fine when required to do so"},
{"testId":587,"qid":25299,"description":"Which of the following non-driving offenses may result in a license suspension?","explanation":"Your driver's license may be suspended if you allow someone else to use your license to perform an unlawful crime or if you fail to pay court-ordered child support. [Suspension, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25110#33449#33450","text":"All of the above#Failing to pay court-ordered child support#Loaning a license to a minor to purchase alcohol#Loaning a license to a minor to purchase tobacco"},
{"testId":1054,"qid":25299,"description":"Which of the following non-driving offenses may result in a license suspension?","explanation":"Your driver's license may be suspended if you allow someone else to use your license to perform an unlawful crime or if you fail to pay court-ordered child support. [Suspension, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25110#33449#33450","text":"All of the above#Failing to pay court-ordered child support#Loaning a license to a minor to purchase alcohol#Loaning a license to a minor to purchase tobacco"},
{"testId":587,"qid":25300,"description":"Your driver's license will be suspended if you are found guilty of stealing:","explanation":null,"imageName":"","correctAnswer":13072,"isBookmarked":0,"aid":"13070#13071#13072#13073","text":"credit cards#personal property#gasoline#automobile parts"},
{"testId":1054,"qid":25300,"description":"Your driver's license will be suspended if you are found guilty of stealing:","explanation":null,"imageName":"","correctAnswer":13072,"isBookmarked":0,"aid":"13070#13071#13072#13073","text":"credit cards#personal property#gasoline#automobile parts"},
{"testId":587,"qid":25301,"description":"What will happen to your driver's license if you refuse to submit to chemical testing for alcohol or drugs?","explanation":"Your driver's license will be cancelled if you refuse to submit to chemical testing for drugs or alcohol. To restore your license after revocation, you will most likely need to retake and pass the written knowledge exam as well as the road test. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":33451,"isBookmarked":0,"aid":"33451#33452#33453#33454","text":"It may be revoked#It may be suspended#It may be withdrawn#None of the above will happen to it"},
{"testId":1054,"qid":25301,"description":"What will happen to your driver's license if you refuse to submit to chemical testing for alcohol or drugs?","explanation":"Your driver's license will be cancelled if you refuse to submit to chemical testing for drugs or alcohol. To restore your license after revocation, you will most likely need to retake and pass the written knowledge exam as well as the road test. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":33451,"isBookmarked":0,"aid":"33451#33452#33453#33454","text":"It may be revoked#It may be suspended#It may be withdrawn#None of the above will happen to it"},
{"testId":587,"qid":25302,"description":"Which of the following will NOT result in your minnesota driver's license being revoked immediately?","explanation":"Failure to stop at a stop sign will not result in the revocation of your driver's license. However, exceeding 100 mph, running from authorities, or driving while intoxicated may result in license revocation. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":11410,"isBookmarked":0,"aid":"11410#33098#33455#33456","text":"Running a stop sign#Fleeing from the police#Speeding at over 100 mph#Driving while impaired (DWI) by alcohol"},
{"testId":1054,"qid":25302,"description":"Which of the following will NOT result in your minnesota driver's license being revoked immediately?","explanation":"Failure to stop at a stop sign will not result in the revocation of your driver's license. However, exceeding 100 mph, running from authorities, or driving while intoxicated may result in license revocation. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":11410,"isBookmarked":0,"aid":"11410#33098#33455#33456","text":"Running a stop sign#Fleeing from the police#Speeding at over 100 mph#Driving while impaired (DWI) by alcohol"},
{"testId":587,"qid":25303,"description":"How many violations in a single year lead to having your driver's license revoked and resulting in a jail sentence if you are found guilty?","explanation":"If you plead guilty to three infractions in a single year, each of which results in a prison term, your driver's license will be suspended. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1054,"qid":25303,"description":"How many violations in a single year lead to having your driver's license revoked and resulting in a jail sentence if you are found guilty?","explanation":"If you plead guilty to three infractions in a single year, each of which results in a prison term, your driver's license will be suspended. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":587,"qid":25304,"description":"If you're charged with an offense that would have led to license revocation in Minnesota,:","explanation":"Your Minnesota driver's license will be revoked if you commit an infraction in another state that would have resulted in license revocation in Minnesota. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":31015,"isBookmarked":0,"aid":"31015#33457#33458#33459","text":"your license will be revoked#you'll have to pay a hefty fine#your license will be unaffected#you'll have to complete a driver's education course"},
{"testId":1054,"qid":25304,"description":"If you're charged with an offense that would have led to license revocation in Minnesota,:","explanation":"Your Minnesota driver's license will be revoked if you commit an infraction in another state that would have resulted in license revocation in Minnesota. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":31015,"isBookmarked":0,"aid":"31015#33457#33458#33459","text":"your license will be revoked#you'll have to pay a hefty fine#your license will be unaffected#you'll have to complete a driver's education course"},
{"testId":587,"qid":25305,"description":"If your driver's license was taken away, you must _________to get it back.","explanation":"To restore your driving rights once your revocation time has expired, you must pay a license reinstatement fee and apply for a new license. To receive a new license, you must repeat and pass the necessary tests. When taking the knowledge or road exam, you must present correct identification. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#33460#33462#45239","text":"Do all of the above#pay a reinstatement fee#apply for a new license#pass the appropriate examinations"},
{"testId":1054,"qid":25305,"description":"If your driver's license was taken away, you must _________to get it back.","explanation":"To restore your driving rights once your revocation time has expired, you must pay a license reinstatement fee and apply for a new license. To receive a new license, you must repeat and pass the necessary tests. When taking the knowledge or road exam, you must present correct identification. [Revocation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#33460#33462#45239","text":"Do all of the above#pay a reinstatement fee#apply for a new license#pass the appropriate examinations"},
{"testId":587,"qid":25306,"description":"If your driver's license was revoked due to DWI (driving while intoxicated) or rejecting a chemical test, you can apply for a limited license, which allows you to drive to and from :","explanation":"If your driver's license was revoked due to DWI (driving while intoxicated) or rejecting a chemical test, you may be eligible for a restricted license. A restricted license allows you to drive to and from job, drug or alcohol treatment programs, or college classes. You will also be able to move your dependents for medical, nutritional, or educational reasons. [Limited License, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#32019#33463#33464","text":"All of the above#work#drug or alcohol treatment programs#college courses"},
{"testId":1054,"qid":25306,"description":"If your driver's license was revoked due to DWI (driving while intoxicated) or rejecting a chemical test, you can apply for a limited license, which allows you to drive to and from :","explanation":"If your driver's license was revoked due to DWI (driving while intoxicated) or rejecting a chemical test, you may be eligible for a restricted license. A restricted license allows you to drive to and from job, drug or alcohol treatment programs, or college classes. You will also be able to move your dependents for medical, nutritional, or educational reasons. [Limited License, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#32019#33463#33464","text":"All of the above#work#drug or alcohol treatment programs#college courses"},
{"testId":587,"qid":25307,"description":"Which of the following is NOT a grounds to cancel your driver's license?","explanation":"If you do not have the legal right to drive, your license may be revoked. This might be the case if you lied on your license application, are so handicapped that you can't drive safely, or failed a road test required by the Department of Public Safety to evaluate your ability to drive safely. [Cancellation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":33467,"isBookmarked":0,"aid":"33465#33466#33467#33468","text":"Having falsified your application for a driver's license#Having a disability that prevents you from driving safely#Serving time in jail for a non-driving-related violation#Failing a road test that was requested by the Department of Public Safety"},
{"testId":1054,"qid":25307,"description":"Which of the following is NOT a grounds to cancel your driver's license?","explanation":"If you do not have the legal right to drive, your license may be revoked. This might be the case if you lied on your license application, are so handicapped that you can't drive safely, or failed a road test required by the Department of Public Safety to evaluate your ability to drive safely. [Cancellation, License Withdrawal, Minnesota Driver's Manual, Chapter 7 Your Driving Privileges]","imageName":"","correctAnswer":33467,"isBookmarked":0,"aid":"33465#33466#33467#33468","text":"Having falsified your application for a driver's license#Having a disability that prevents you from driving safely#Serving time in jail for a non-driving-related violation#Failing a road test that was requested by the Department of Public Safety"},
{"testId":587,"qid":25308,"description":"Alcohol is implicated in approximately _____ of fatal road accidents.","explanation":"More than one-third of vehicle fatalities in Minnesota are related to alcohol usage. Drunk driving is a major issue that necessitates harsh punishments. [Minnesota Driver's Manual, Alcohol and Driving, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33470,"isBookmarked":0,"aid":"10698#11884#33469#33470","text":"one-half#one-fourth#two-thirds#one-third"},
{"testId":1054,"qid":25308,"description":"Alcohol is implicated in approximately _____ of fatal road accidents.","explanation":"More than one-third of vehicle fatalities in Minnesota are related to alcohol usage. Drunk driving is a major issue that necessitates harsh punishments. [Minnesota Driver's Manual, Alcohol and Driving, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33470,"isBookmarked":0,"aid":"10698#11884#33469#33470","text":"one-half#one-fourth#two-thirds#one-third"},
{"testId":587,"qid":25309,"description":"________ are the most likely to drink and drive.","explanation":"Young boys are more prone to drive intoxicated than others. [Alcohol and Driving, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33471,"isBookmarked":0,"aid":"33471#33472#33473#33474","text":"Young males#Young females#Middle-aged males#Senior females"},
{"testId":1054,"qid":25309,"description":"________ are the most likely to drink and drive.","explanation":"Young boys are more prone to drive intoxicated than others. [Alcohol and Driving, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33471,"isBookmarked":0,"aid":"33471#33472#33473#33474","text":"Young males#Young females#Middle-aged males#Senior females"},
{"testId":587,"qid":25310,"description":"The most often killed in an alcohol-related collision include:","explanation":"The inebriated driver is the most likely to be killed in a car accident caused by drunk driving. [Alcohol and Driving, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33477,"isBookmarked":0,"aid":"33475#33476#33477#33478","text":"the driver of the other vehicle#a backseat passenger#the impaired driver#a child passenger"},
{"testId":1054,"qid":25310,"description":"The most often killed in an alcohol-related collision include:","explanation":"The inebriated driver is the most likely to be killed in a car accident caused by drunk driving. [Alcohol and Driving, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33477,"isBookmarked":0,"aid":"33475#33476#33477#33478","text":"the driver of the other vehicle#a backseat passenger#the impaired driver#a child passenger"},
{"testId":587,"qid":25311,"description":"Minnesota law forbids driving while intoxicated by:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#8833#33479#33480","text":"All of the above#Prescription drugs#illegal drugs#household products used as inhalants"},
{"testId":1054,"qid":25311,"description":"Minnesota law forbids driving while intoxicated by:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#8833#33479#33480","text":"All of the above#Prescription drugs#illegal drugs#household products used as inhalants"},
{"testId":587,"qid":25312,"description":"If you are caught driving with a blood alcohol concentration (BAC) of _____ or above, you may face charges of driving while intoxicated (DWI).","explanation":"You are legally inebriated if your blood alcohol content (BAC) is 0.08 percent or above. However, everyone is affected differently by drinking. You may be charged with DWI even if your BAC is as low as 0.04 percent if a police officer judges that your driving has become impaired. [Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":1054,"qid":25312,"description":"If you are caught driving with a blood alcohol concentration (BAC) of _____ or above, you may face charges of driving while intoxicated (DWI).","explanation":"You are legally inebriated if your blood alcohol content (BAC) is 0.08 percent or above. However, everyone is affected differently by drinking. You may be charged with DWI even if your BAC is as low as 0.04 percent if a police officer judges that your driving has become impaired. [Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":587,"qid":25313,"description":"If a Minnesota police officer can prove that alcohol was a factor in a motorist's error, that driver may be charged with DWI even if his or her blood alcohol concentration (BAC) is as low as:","explanation":"If a Minnesota police officer can establish that alcohol was a factor in a motorist's error, that driver may be charged with DWI even if his or her BAC is as low as 0.04 percent. [Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":27698,"isBookmarked":0,"aid":"23192#23319#27697#27698","text":"0.05%#0.07%#0.06%#0.04%"},
{"testId":1054,"qid":25313,"description":"If a Minnesota police officer can prove that alcohol was a factor in a motorist's error, that driver may be charged with DWI even if his or her blood alcohol concentration (BAC) is as low as:","explanation":"If a Minnesota police officer can establish that alcohol was a factor in a motorist's error, that driver may be charged with DWI even if his or her BAC is as low as 0.04 percent. [Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":27698,"isBookmarked":0,"aid":"23192#23319#27697#27698","text":"0.05%#0.07%#0.06%#0.04%"},
{"testId":587,"qid":25314,"description":"When you drive on any Minnesota roadway, you are granting implied agreement to:","explanation":"Driving on public roadways in Minnesota implies permission to being chemically tested for alcohol or drug use. [Implied Consent Law, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8615#29272#33481#33482","text":"All of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":1054,"qid":25314,"description":"When you drive on any Minnesota roadway, you are granting implied agreement to:","explanation":"Driving on public roadways in Minnesota implies permission to being chemically tested for alcohol or drug use. [Implied Consent Law, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8615#29272#33481#33482","text":"All of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":587,"qid":25316,"description":"If your driver's license was revoked due to an alcohol-related offense, you must pay ______a license reinstatement fee.","explanation":"If your driver's license was revoked due to an alcohol-related offense, the reinstatement price is $680. [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33486,"isBookmarked":0,"aid":"33483#33484#33485#33486","text":"$280#$480#$580#$680"},
{"testId":1054,"qid":25316,"description":"If your driver's license was revoked due to an alcohol-related offense, you must pay ______a license reinstatement fee.","explanation":"If your driver's license was revoked due to an alcohol-related offense, the reinstatement price is $680. [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33486,"isBookmarked":0,"aid":"33483#33484#33485#33486","text":"$280#$480#$580#$680"},
{"testId":587,"qid":25317,"description":"What do you have to do to get your license back if it was taken away because of a conviction related to alcohol?","explanation":"If your license was revoked, you must apply for a new license (which will very certainly require you to retake the written knowledge exam and road test) and pay a reinstatement fee when the term of revocation has expired. If the revocation was for an alcohol-related offense, the reinstatement cost is greater ($680), and you must also pass a DWI knowledge test. You may also be subjected to a chemical evaluation. Additional penalties may apply depending on the specific alcohol-related violation. [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#33487#33488#33489","text":"Do all of the above#Apply for a new driver's license#Pass a DWI knowledge exam#Undergo chemical assessment"},
{"testId":1054,"qid":25317,"description":"What do you have to do to get your license back if it was taken away because of a conviction related to alcohol?","explanation":"If your license was revoked, you must apply for a new license (which will very certainly require you to retake the written knowledge exam and road test) and pay a reinstatement fee when the term of revocation has expired. If the revocation was for an alcohol-related offense, the reinstatement cost is greater ($680), and you must also pass a DWI knowledge test. You may also be subjected to a chemical evaluation. Additional penalties may apply depending on the specific alcohol-related violation. [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#33487#33488#33489","text":"Do all of the above#Apply for a new driver's license#Pass a DWI knowledge exam#Undergo chemical assessment"},
{"testId":587,"qid":25318,"description":"If you have a blood alcohol content (BAC) of _____ or above, you will face much harsher consequences.","explanation":"If you are caught driving with a blood alcohol content (BAC) of 0.16 percent or more, the consequences for DWI will be significantly harsher. (An average 180-pound male will have a BAC of 0.17 percent after drinking five shots of 80-proof whiskey, five 12-ounce cans of beer, or 25 ounces of wine before his body begins to break it down.) [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":13112,"isBookmarked":0,"aid":"3169#3171#11768#13112","text":"0.20%#0.09%#0.10%#0.16%"},
{"testId":1054,"qid":25318,"description":"If you have a blood alcohol content (BAC) of _____ or above, you will face much harsher consequences.","explanation":"If you are caught driving with a blood alcohol content (BAC) of 0.16 percent or more, the consequences for DWI will be significantly harsher. (An average 180-pound male will have a BAC of 0.17 percent after drinking five shots of 80-proof whiskey, five 12-ounce cans of beer, or 25 ounces of wine before his body begins to break it down.) [Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":13112,"isBookmarked":0,"aid":"3169#3171#11768#13112","text":"0.20%#0.09%#0.10%#0.16%"},
{"testId":587,"qid":25319,"description":"A driver caught for DWI will be issued a _______ license. His or her license will be revoked after that term has expired.","explanation":"A motorist will be issued a temporary seven-day licence at the time of their DWI arrest. His or her licence will be suspended when the seven-day window has passed. [Minnesota Driver's Manual, Penalties, Chapter 8: Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33492,"isBookmarked":0,"aid":"33490#33491#33492#33493","text":"three-day#five-day#seven-day#ten-day"},
{"testId":1054,"qid":25319,"description":"A driver caught for DWI will be issued a _______ license. His or her license will be revoked after that term has expired.","explanation":"A motorist will be issued a temporary seven-day licence at the time of their DWI arrest. His or her licence will be suspended when the seven-day window has passed. [Minnesota Driver's Manual, Penalties, Chapter 8: Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33492,"isBookmarked":0,"aid":"33490#33491#33492#33493","text":"three-day#five-day#seven-day#ten-day"},
{"testId":587,"qid":25320,"description":"What is the minimum time of revocation for a first DWI if the driver pleads not guilty?","explanation":"For a first DWI conviction, the minimum revocation time is 90 days. If the motorist enters a guilty plea, the term of revocation may be shortened to 30 days. [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#11285#12630","text":"180 days#90 days#2 years#360 days"},
{"testId":1054,"qid":25320,"description":"What is the minimum time of revocation for a first DWI if the driver pleads not guilty?","explanation":"For a first DWI conviction, the minimum revocation time is 90 days. If the motorist enters a guilty plea, the term of revocation may be shortened to 30 days. [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"227#229#11285#12630","text":"180 days#90 days#2 years#360 days"},
{"testId":587,"qid":25321,"description":"A drunk motorist is more likely to:","explanation":"A drunk driver is more likely to misjudge stopping distance, drive too quickly or too slowly, weave or run off the road, or forget to lower headlights when necessary. In an emergency, an intoxicated driver may be less aware and may take too long to stop. Such errors may jeopardize both the driver and bystanders. [Types of Effects, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#31665#33494#33495","text":"do any of the above#drive too fast or too slowly#misjudge stopping distance#weave or run off the road"},
{"testId":1054,"qid":25321,"description":"A drunk motorist is more likely to:","explanation":"A drunk driver is more likely to misjudge stopping distance, drive too quickly or too slowly, weave or run off the road, or forget to lower headlights when necessary. In an emergency, an intoxicated driver may be less aware and may take too long to stop. Such errors may jeopardize both the driver and bystanders. [Types of Effects, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#31665#33494#33495","text":"do any of the above#drive too fast or too slowly#misjudge stopping distance#weave or run off the road"},
{"testId":587,"qid":25322,"description":"A first offense of DWI can result in ___ days in jail and/or a ______ fine.","explanation":"A first DWI violation may land you in prison for 90 days and/or a $1,000 fine. [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33497,"isBookmarked":0,"aid":"13164#13462#33496#33497","text":"30; $100#90; $500#60; $250#90; $1,000"},
{"testId":1054,"qid":25322,"description":"A first offense of DWI can result in ___ days in jail and/or a ______ fine.","explanation":"A first DWI violation may land you in prison for 90 days and/or a $1,000 fine. [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33497,"isBookmarked":0,"aid":"13164#13462#33496#33497","text":"30; $100#90; $500#60; $250#90; $1,000"},
{"testId":587,"qid":25323,"description":"If you are convicted of a second DWI within ___ years after your first DWI, your driver license will be revoked for at least one year.","explanation":"If a DWI offense happens within ten years of the first, it is deemed a second offense and your license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1054,"qid":25323,"description":"If you are convicted of a second DWI within ___ years after your first DWI, your driver license will be revoked for at least one year.","explanation":"If a DWI offense happens within ten years of the first, it is deemed a second offense and your license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":587,"qid":25324,"description":"When will you will be eligible for a work permit following your first DWI conviction?","explanation":"No work permit will be issued for your first DWI conviction until a 15-day revocation period has passed and you have completed all reinstatement criteria. However, if your blood alcohol level (BAC) was 0.16 percent or more, you will be ineligible for a work visa. (A 180-pound man may get a BAC of 0.17 percent by drinking five shots of 80-proof whiskey, five 12-ounce cans of beer, or 25 ounces of wine before his body starts to break down the alcohol.) [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33501,"isBookmarked":0,"aid":"33498#33499#33500#33501","text":"After a 15-day revocation period has passed#After you meet all reinstatement requirements#If your blood alcohol concentration (BAC) was below 0.16%#If all of the above are true"},
{"testId":1054,"qid":25324,"description":"When will you will be eligible for a work permit following your first DWI conviction?","explanation":"No work permit will be issued for your first DWI conviction until a 15-day revocation period has passed and you have completed all reinstatement criteria. However, if your blood alcohol level (BAC) was 0.16 percent or more, you will be ineligible for a work visa. (A 180-pound man may get a BAC of 0.17 percent by drinking five shots of 80-proof whiskey, five 12-ounce cans of beer, or 25 ounces of wine before his body starts to break down the alcohol.) [First Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":33501,"isBookmarked":0,"aid":"33498#33499#33500#33501","text":"After a 15-day revocation period has passed#After you meet all reinstatement requirements#If your blood alcohol concentration (BAC) was below 0.16%#If all of the above are true"},
{"testId":587,"qid":25325,"description":"A second DWI conviction might result in ____ months in jail.","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6388#6389#6478#6916","text":"2#3#12#6"},
{"testId":1054,"qid":25325,"description":"A second DWI conviction might result in ____ months in jail.","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6388#6389#6478#6916","text":"2#3#12#6"},
{"testId":587,"qid":25326,"description":"How much will you be fined for a second DWI offense?","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":25034,"isBookmarked":0,"aid":"23889#25033#25034#27889","text":"$1,000#$2,000#$3,000#$2,500"},
{"testId":1054,"qid":25326,"description":"How much will you be fined for a second DWI offense?","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":25034,"isBookmarked":0,"aid":"23889#25033#25034#27889","text":"$1,000#$2,000#$3,000#$2,500"},
{"testId":587,"qid":25327,"description":"Your license will be revoked for at least one year for your ______ DWI offense within the last ten years.","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":1054,"qid":25327,"description":"Your license will be revoked for at least one year for your ______ DWI offense within the last ten years.","explanation":"A second DWI violation carries a year in prison and/or a $3,000 fine, as well as the repossession of your vehicle's license plates. In addition, if the second violation occurs within ten years of the first, your driver's license will be suspended for one year. [Second Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":587,"qid":25328,"description":"How long will an ignition interlock device be fitted in your vehicle after your third DWI violation?","explanation":"For your third DWI offense, you will be required to put an ignition interlock device on your car for three years, among other penalties. If your BAC is 0.02 percent or above, the ignition interlock device will prevent your automobile from starting. (An average 180-pound man will have a BAC of 0.03 percent after one shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine before his body starts to break down the alcohol.) [Minnesota Department of Public Safety, DWI Consequences, https://dps.mn.gov/divisions/ots/educational-materials/Documents/impaired-dwi-consequences.pdf] .pdf]","imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"23254#26650#26651#31350","text":"Six months#One year#Two years#Three years"},
{"testId":1054,"qid":25328,"description":"How long will an ignition interlock device be fitted in your vehicle after your third DWI violation?","explanation":"For your third DWI offense, you will be required to put an ignition interlock device on your car for three years, among other penalties. If your BAC is 0.02 percent or above, the ignition interlock device will prevent your automobile from starting. (An average 180-pound man will have a BAC of 0.03 percent after one shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine before his body starts to break down the alcohol.) [Minnesota Department of Public Safety, DWI Consequences, https://dps.mn.gov/divisions/ots/educational-materials/Documents/impaired-dwi-consequences.pdf] .pdf]","imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"23254#26650#26651#31350","text":"Six months#One year#Two years#Three years"},
{"testId":587,"qid":25329,"description":"You may be required to surrender ______ after your third DWI conviction.","explanation":"For a third DWI offense in ten years, your driver's license will be suspended for three years, your vehicle's license plates will be impounded for three years, an ignition interlock device will be installed on your vehicle for three years, your vehicle may be forfeited, and you will pay a $3,000 fine and/or one year in jail. If your blood alcohol content (BAC) is 0.02 percent or above, the ignition interlock device will prohibit your car from starting. (One shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine will give an average 180-pound guy a BAC of 0.03 percent before his body begins to break down the alcohol.) [Third Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25034#33502#33503","text":"All of the above#$3,000#your vehicle's license plates#your vehicle"},
{"testId":1054,"qid":25329,"description":"You may be required to surrender ______ after your third DWI conviction.","explanation":"For a third DWI offense in ten years, your driver's license will be suspended for three years, your vehicle's license plates will be impounded for three years, an ignition interlock device will be installed on your vehicle for three years, your vehicle may be forfeited, and you will pay a $3,000 fine and/or one year in jail. If your blood alcohol content (BAC) is 0.02 percent or above, the ignition interlock device will prohibit your car from starting. (One shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine will give an average 180-pound guy a BAC of 0.03 percent before his body begins to break down the alcohol.) [Third Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25034#33502#33503","text":"All of the above#$3,000#your vehicle's license plates#your vehicle"},
{"testId":587,"qid":25330,"description":"After a ______ DWI conviction, you must enroll in the Ignition Interlock Device Program.","explanation":"After your third DWI conviction, you must enroll in the Ignition Interlock Device Program. Your car will be fitted with an ignition interlock device. If your blood alcohol content (BAC) is 0.02 percent or above, the ignition interlock device will prohibit your car from starting. (One shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine will give an average 180-pound guy a BAC of 0.03 percent before his body begins to break down the alcohol.) [Third Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1054,"qid":25330,"description":"After a ______ DWI conviction, you must enroll in the Ignition Interlock Device Program.","explanation":"After your third DWI conviction, you must enroll in the Ignition Interlock Device Program. Your car will be fitted with an ignition interlock device. If your blood alcohol content (BAC) is 0.02 percent or above, the ignition interlock device will prohibit your car from starting. (One shot of 80-proof whiskey, one 12-ounce can of beer, or five ounces of wine will give an average 180-pound guy a BAC of 0.03 percent before his body begins to break down the alcohol.) [Third Offense, Penalties, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":587,"qid":25331,"description":"Which of the following DWI offense within ten years is a felony?","explanation":"A fourth DWI conviction within 10 years is a felony. For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":1054,"qid":25331,"description":"Which of the following DWI offense within ten years is a felony?","explanation":"A fourth DWI conviction within 10 years is a felony. For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":587,"qid":25332,"description":"What is the maximum penalty for a felony DWI?","explanation":"For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":13125,"isBookmarked":0,"aid":"3195#13123#13124#13125","text":"$5,000#$7,000#$11,000#$14,000"},
{"testId":1054,"qid":25332,"description":"What is the maximum penalty for a felony DWI?","explanation":"For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":13125,"isBookmarked":0,"aid":"3195#13123#13124#13125","text":"$5,000#$7,000#$11,000#$14,000"},
{"testId":587,"qid":25333,"description":"Those convicted of a felony DWI face a prison sentence of __ years.","explanation":"For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":1054,"qid":25333,"description":"Those convicted of a felony DWI face a prison sentence of __ years.","explanation":"For felony DWI, the penalties include up to seven years in jail and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":587,"qid":25336,"description":"If a Minnesota driver having age below 21 is convicted of driving with alcohol in his or her system for the second time, his or her license will be suspended for ____ days.","explanation":null,"imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1054,"qid":25336,"description":"If a Minnesota driver having age below 21 is convicted of driving with alcohol in his or her system for the second time, his or her license will be suspended for ____ days.","explanation":null,"imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":587,"qid":25337,"description":"Which occupants of a vehicle may lawfully consume alcohol while it is on a public road?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#9808#32787#33504","text":"None of the above#The driver#The backseat passengers#Persons at least 21 years old"},
{"testId":1054,"qid":25337,"description":"Which occupants of a vehicle may lawfully consume alcohol while it is on a public road?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#9808#32787#33504","text":"None of the above#The driver#The backseat passengers#Persons at least 21 years old"},
{"testId":587,"qid":25338,"description":"If you fail the road test twice in a succession, you must pay a charge of ____ to retake it.","explanation":"If you fail the written knowledge exam twice in a row, you must pay a charge of $10 to retake the test. If you fail the road test twice in a row, you must pay a $20 charge to take it a third time. [Minnesota Driver's Manual Knowledge Test Checklist; Minnesota Driver's Manual Road Test Checklist]","imageName":"","correctAnswer":13054,"isBookmarked":0,"aid":"11351#12393#13054#13128","text":"$10#$40#$20#$30"},
{"testId":587,"qid":25339,"description":"If you are under ____ years of age, your parent, foster parent, legal guardian, or other responsible adult must sign and approve your driver's license application.","explanation":"If you are under the age of 18, your parent, foster parent, legal guardian, or another responsible adult must sign and authorize your driver's license application. [If under the age of 18, a road test checklist and the Minnesota Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":587,"qid":25340,"description":"A person under the age of 18 must possess an instructional permit for _____ months before taking the road test for a driver's license in Minnesota's Graduated Driver Licensing (GDL) system.","explanation":"A person under the age of 18 must possess an instructional permit for six months without any driving offenses under Minnesota's Graduated Driver Licensing (GDL) system before taking the road test for a driver's license. [If under the age of 18, a road test checklist and the Minnesota Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":587,"qid":25341,"description":"Which of the following limitations apply to a newly licensed driver under the age of 18?","explanation":"If a person under the age of 18 obtains an instructional permit, his or her driving privileges will be limited as follows: the supervising passenger must be at least 21 years old; the person may not use a cell phone of any kind except in an emergency; and all occupants must wear seat belts or use appropriate child restraints. [Chapter 1 Your License to Drive, Minnesota Driver's Manual, Under 18, Your Instruction Permit]","imageName":"","correctAnswer":33508,"isBookmarked":0,"aid":"33505#33506#33507#33508","text":"The supervising driver must be at least 21 years old#The person may not use any type of cell phone, not even a hands-free cell phone#All occupants of the vehicle must wear seat belts or use suitable child restraints#All of the above restrictions apply"},
{"testId":587,"qid":25343,"description":"If a driver causes a deadly accident while escaping a police officer, he or she may be fined up to _______ and imprisoned for up to ___ years.","explanation":"If a motorist causes a deadly accident while fleeing the police, he or she might face up to 10 years in jail and/or a $20,000 fine. [Fleeing a Police Officer, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":33511,"isBookmarked":0,"aid":"33511#33512#33513#33514","text":"$20,000; 10#$20,000; 5#$10,000; 5#$10,000; 3"},
{"testId":1054,"qid":25343,"description":"If a driver causes a deadly accident while escaping a police officer, he or she may be fined up to _______ and imprisoned for up to ___ years.","explanation":"If a motorist causes a deadly accident while fleeing the police, he or she might face up to 10 years in jail and/or a $20,000 fine. [Fleeing a Police Officer, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":33511,"isBookmarked":0,"aid":"33511#33512#33513#33514","text":"$20,000; 10#$20,000; 5#$10,000; 5#$10,000; 3"},
{"testId":587,"qid":25344,"description":"What is the maximum penalty for evading a police officer?","explanation":"If a motorist is guilty of escaping from police authorities, he or she faces up to three years and one day in jail and/or a $5,000 fine. [Fleeing a Police Officer, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#11658","text":"$500#$1,000#$5,000#$10,000"},
{"testId":1054,"qid":25344,"description":"What is the maximum penalty for evading a police officer?","explanation":"If a motorist is guilty of escaping from police authorities, he or she faces up to three years and one day in jail and/or a $5,000 fine. [Fleeing a Police Officer, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#11658","text":"$500#$1,000#$5,000#$10,000"},
{"testId":587,"qid":25345,"description":"If you are found guilty of operating a car without insurance, you face the possibility of spending up to ___ days in jail.","explanation":"If you are convicted of driving an uninsured car, you might face a $1,000 fine and up to 90 days in prison. [Insurance, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1054,"qid":25345,"description":"If you are found guilty of operating a car without insurance, you face the possibility of spending up to ___ days in jail.","explanation":"If you are convicted of driving an uninsured car, you might face a $1,000 fine and up to 90 days in prison. [Insurance, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":589,"qid":25346,"description":"What is the minimum fine in Missouri for a first offense of speeding or passing in a construction or work zone?","explanation":null,"imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11349#11832#12358#12395","text":"$50#$100#$200#$250"},
{"testId":1056,"qid":25346,"description":"What is the minimum fine in Missouri for a first offense of speeding or passing in a construction or work zone?","explanation":null,"imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11349#11832#12358#12395","text":"$50#$100#$200#$250"},
{"testId":589,"qid":25347,"description":"If you harm or kill someone while driving under the influence of alcohol or drugs, you could face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"3634#11432#11760#12296","text":"14 years#4 years#10 years#7 years"},
{"testId":1056,"qid":25347,"description":"If you harm or kill someone while driving under the influence of alcohol or drugs, you could face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"3634#11432#11760#12296","text":"14 years#4 years#10 years#7 years"},
{"testId":589,"qid":25349,"description":"You could face a fine of _______, if you harm or kill someone while driving under the influence of alcohol or drugs.","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25036#27889#31882","text":"$1,000#$5,000#$2,500#$7,500"},
{"testId":1056,"qid":25349,"description":"You could face a fine of _______, if you harm or kill someone while driving under the influence of alcohol or drugs.","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25036#27889#31882","text":"$1,000#$5,000#$2,500#$7,500"},
{"testId":589,"qid":25350,"description":"What is the legal limit for the amount of alcohol in an adult driver's blood?","explanation":null,"imageName":"","correctAnswer":10236,"isBookmarked":0,"aid":"3168#3171#10236#11768","text":"0.08%#0.09%#Less than 0.08%#0.10%"},
{"testId":1056,"qid":25350,"description":"What is the legal limit for the amount of alcohol in an adult driver's blood?","explanation":null,"imageName":"","correctAnswer":10236,"isBookmarked":0,"aid":"3168#3171#10236#11768","text":"0.08%#0.09%#Less than 0.08%#0.10%"},
{"testId":589,"qid":25351,"description":"What is Missouri's legal blood alcohol content (BAC) limit for drivers under the age of 21?","explanation":null,"imageName":"","correctAnswer":34143,"isBookmarked":0,"aid":"3168#3281#4251#34143","text":"0.08%#0.04%#0.02%#Less than 0.02%"},
{"testId":1056,"qid":25351,"description":"What is Missouri's legal blood alcohol content (BAC) limit for drivers under the age of 21?","explanation":null,"imageName":"","correctAnswer":34143,"isBookmarked":0,"aid":"3168#3281#4251#34143","text":"0.08%#0.04%#0.02%#Less than 0.02%"},
{"testId":589,"qid":25352,"description":"The officer will give you a written notice if your Blood Alcohol Concentration(BAC) exceeds the legal limit. Your license will be suspended in ____ days unless you request a hearing.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6389#6478","text":"10#15#3#12"},
{"testId":1056,"qid":25352,"description":"The officer will give you a written notice if your Blood Alcohol Concentration(BAC) exceeds the legal limit. Your license will be suspended in ____ days unless you request a hearing.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6389#6478","text":"10#15#3#12"},
{"testId":589,"qid":25354,"description":"If a further DWI violation happens within ___ years after the first offense, it is legally deemed a \"second offense.\":","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":1056,"qid":25354,"description":"If a further DWI violation happens within ___ years after the first offense, it is legally deemed a \"second offense.\":","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":589,"qid":25355,"description":"If you are convicted of your first DWI conviction, you might face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11306","text":"30 days#1 year#6 months#3 years"},
{"testId":1056,"qid":25355,"description":"If you are convicted of your first DWI conviction, you might face up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11306","text":"30 days#1 year#6 months#3 years"},
{"testId":589,"qid":25356,"description":"If you are convicted of your first DWI conviction, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#23890#25033","text":"$500#$1,000#$1,500#$2,000"},
{"testId":1056,"qid":25356,"description":"If you are convicted of your first DWI conviction, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#23890#25033","text":"$500#$1,000#$1,500#$2,000"},
{"testId":589,"qid":25357,"description":"If you are convicted of DWI violation for the second time, you will face a jail of up to:","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1454#11341","text":"1 year#6 months#9 months#5 years"},
{"testId":1056,"qid":25357,"description":"If you are convicted of DWI violation for the second time, you will face a jail of up to:","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1454#11341","text":"1 year#6 months#9 months#5 years"},
{"testId":589,"qid":25358,"description":"How long will your driver license be revoked if you are charged with a second DWI within five years?","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1056,"qid":25358,"description":"How long will your driver license be revoked if you are charged with a second DWI within five years?","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":589,"qid":25359,"description":"You will lose your driving privileges for _________ if you are convicted of driving while intoxicated for a second time during a five-year period.","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1056,"qid":25359,"description":"You will lose your driving privileges for _________ if you are convicted of driving while intoxicated for a second time during a five-year period.","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":589,"qid":25360,"description":"If convicted for DWI offense for the 3rd time, you will face jail of up to:","explanation":null,"imageName":"","correctAnswer":11432,"isBookmarked":0,"aid":"1272#11285#11432#12727","text":"6 months#2 years#4 years#6 years"},
{"testId":1056,"qid":25360,"description":"If convicted for DWI offense for the 3rd time, you will face jail of up to:","explanation":null,"imageName":"","correctAnswer":11432,"isBookmarked":0,"aid":"1272#11285#11432#12727","text":"6 months#2 years#4 years#6 years"},
{"testId":589,"qid":25361,"description":"If convicted for DWI offense for the 3rd time, you will face a fine of as much as:","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":1056,"qid":25361,"description":"If convicted for DWI offense for the 3rd time, you will face a fine of as much as:","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":589,"qid":25362,"description":"If you are convicted of a fourth DWI violation, you might face up to ______ years in prison.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"11341#11432#12296#12727","text":"5 years#4 years#7 years#6 years"},
{"testId":1056,"qid":25362,"description":"If you are convicted of a fourth DWI violation, you might face up to ______ years in prison.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"11341#11432#12296#12727","text":"5 years#4 years#7 years#6 years"},
{"testId":589,"qid":25363,"description":"If you are convicted of your fifth DWI, you will be sentenced to AT LEAST ______ days in jail.","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1272#11306#11341#11760","text":"6 months#3 years#5 years#10 years"},
{"testId":1056,"qid":25363,"description":"If you are convicted of your fifth DWI, you will be sentenced to AT LEAST ______ days in jail.","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1272#11306#11341#11760","text":"6 months#3 years#5 years#10 years"},
{"testId":589,"qid":25365,"description":"For committing which of the following offenses, a Missouri driver under the age of 21 years may have his or her license suspended for 90 days?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#13289#34144#34145","text":"Any of the above#DWI#Possession of drugs while operating a vehicle#Using a falsified driver's license"},
{"testId":1056,"qid":25365,"description":"For committing which of the following offenses, a Missouri driver under the age of 21 years may have his or her license suspended for 90 days?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#13289#34144#34145","text":"Any of the above#DWI#Possession of drugs while operating a vehicle#Using a falsified driver's license"},
{"testId":589,"qid":25366,"description":"If a Missouri driver aged 21 or older is convicted of possessing or using narcotics while operating a vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#26672","text":"2 years#1 year#30 days#5 years"},
{"testId":1056,"qid":25366,"description":"If a Missouri driver aged 21 or older is convicted of possessing or using narcotics while operating a vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23886#26672","text":"2 years#1 year#30 days#5 years"},
{"testId":589,"qid":25367,"description":"Missouri driver between the age of 15 and 21  violates Missouri's Minor in Possession legislation, when he or she:","explanation":null,"imageName":"","correctAnswer":25862,"isBookmarked":0,"aid":"25862#34146#34147#34148","text":"does any of the above#attempts to purchase alcohol#consumes alcohol#has a blood alcohol concentration (BAC) of 0.02% or above"},
{"testId":1056,"qid":25367,"description":"Missouri driver between the age of 15 and 21  violates Missouri's Minor in Possession legislation, when he or she:","explanation":null,"imageName":"","correctAnswer":25862,"isBookmarked":0,"aid":"25862#34146#34147#34148","text":"does any of the above#attempts to purchase alcohol#consumes alcohol#has a blood alcohol concentration (BAC) of 0.02% or above"},
{"testId":589,"qid":25368,"description":"A first conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1056,"qid":25368,"description":"A first conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":589,"qid":25369,"description":"A second conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"24048#24049#25857#25858","text":"60 days#90 days#120 days#180 days"},
{"testId":1056,"qid":25369,"description":"A second conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"24048#24049#25857#25858","text":"60 days#90 days#120 days#180 days"},
{"testId":589,"qid":25370,"description":"A third conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1056,"qid":25370,"description":"A third conviction of Minor in Possession will result in a ______ suspension of the driver's license.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":589,"qid":25371,"description":"If you refuse to submit to chemical testing when a police officer requests it, your license will be canceled immediately for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23183#23886","text":"2 years#1 year#3 years#30 days"},
{"testId":1056,"qid":25371,"description":"If you refuse to submit to chemical testing when a police officer requests it, your license will be canceled immediately for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23183#23886","text":"2 years#1 year#3 years#30 days"},
{"testId":589,"qid":25372,"description":"If you've been convicted of DWI more than ___ times, you must install an ignition interlock device in every vehicle you operate聽before you can drive again.","explanation":null,"imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":589,"qid":25373,"description":"After your license is reinstated, you must keep an ignition interlock device (IID) fitted in each vehicle you operate for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#26672","text":"6 months#1 year#30 days#5 years"},
{"testId":589,"qid":25374,"description":"If you were told to put an IID (ignition interlock device) in your vehicle and then caught driving without it, your license will be taken away for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1056,"qid":25374,"description":"If you were told to put an IID (ignition interlock device) in your vehicle and then caught driving without it, your license will be taken away for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":589,"qid":25376,"description":"_______ points will be assessed to your driving record if you are found guilty of breaking a state speeding statute.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1056,"qid":25376,"description":"_______ points will be assessed to your driving record if you are found guilty of breaking a state speeding statute.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":589,"qid":25377,"description":"Careless driving adds ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1056,"qid":25377,"description":"Careless driving adds ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":589,"qid":25378,"description":"__ points will be added to your driving record if you knowingly let an unlicensed driver drive.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1056,"qid":25378,"description":"__ points will be added to your driving record if you knowingly let an unlicensed driver drive.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":589,"qid":25379,"description":"If you are convicted of a felony involving the use of a motor vehicle, you will receive ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1056,"qid":25379,"description":"If you are convicted of a felony involving the use of a motor vehicle, you will receive ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":589,"qid":25380,"description":"__ points will be added to your driving record if you're caught driving with a suspended or revoked license.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1056,"qid":25380,"description":"__ points will be added to your driving record if you're caught driving with a suspended or revoked license.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":589,"qid":25381,"description":"The Department of Revenue will issue you a warning letter if you accumulate ___ points in 12 months.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1056,"qid":25381,"description":"The Department of Revenue will issue you a warning letter if you accumulate ___ points in 12 months.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":589,"qid":25384,"description":"If you aacumulat 8 points for the third time in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1056,"qid":25384,"description":"If you aacumulat 8 points for the third time in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":589,"qid":25385,"description":"If you _______, your license will be taken away for a year.","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#34149#34150#34151","text":"do any of the above#accumulate 12 points in 1 year#accumulate 18 points in 2 years#accumulate 24 points in 3 years"},
{"testId":1056,"qid":25385,"description":"If you _______, your license will be taken away for a year.","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#34149#34150#34151","text":"do any of the above#accumulate 12 points in 1 year#accumulate 18 points in 2 years#accumulate 24 points in 3 years"},
{"testId":589,"qid":25386,"description":"After your driving privileges are restored following a license suspension or revocation, the number of points on your driving record will be lowered to:","explanation":null,"imageName":"","correctAnswer":30357,"isBookmarked":0,"aid":"30357#34152#34153#34154","text":"4#1#2#3"},
{"testId":1056,"qid":25386,"description":"After your driving privileges are restored following a license suspension or revocation, the number of points on your driving record will be lowered to:","explanation":null,"imageName":"","correctAnswer":30357,"isBookmarked":0,"aid":"30357#34152#34153#34154","text":"4#1#2#3"},
{"testId":589,"qid":25387,"description":"If you drive for ______ without earning any further points, the points on your driving record will be lowered by one-third.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11341","text":"30 days#1 year#6 months#5 years"},
{"testId":1056,"qid":25387,"description":"If you drive for ______ without earning any further points, the points on your driving record will be lowered by one-third.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11341","text":"30 days#1 year#6 months#5 years"},
{"testId":589,"qid":25388,"description":"If you drive for ______ and do not earn any more points, the points on your driving record will be decreased to zero.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11306#11341#11432#12727","text":"3 years#5 years#4 years#6 years"},
{"testId":1056,"qid":25388,"description":"If you drive for ______ and do not earn any more points, the points on your driving record will be decreased to zero.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11306#11341#11432#12727","text":"3 years#5 years#4 years#6 years"},
{"testId":589,"qid":25389,"description":"If you do not pay a traffic penalty within ___ days, your driver's license will be suspended until you pay the penalty.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1056,"qid":25389,"description":"If you do not pay a traffic penalty within ___ days, your driver's license will be suspended until you pay the penalty.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":589,"qid":25390,"description":"Which of the following documents can be used as proof of insurance?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34155#34156#34157","text":"All of the above#A certificate of self-insurance#A copy of your insurance identification card#A copy of your insurance policy"},
{"testId":1056,"qid":25390,"description":"Which of the following documents can be used as proof of insurance?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34155#34156#34157","text":"All of the above#A certificate of self-insurance#A copy of your insurance identification card#A copy of your insurance policy"},
{"testId":589,"qid":25391,"description":"If you deposit ______ with the State Treasurer, the Department of Revenue will give you a certificate of self-insurance.","explanation":null,"imageName":"","correctAnswer":12842,"isBookmarked":0,"aid":"11704#12842#13123#44555","text":"$7,500#$75,000#$7,000#$17,500"},
{"testId":1056,"qid":25391,"description":"If you deposit ______ with the State Treasurer, the Department of Revenue will give you a certificate of self-insurance.","explanation":null,"imageName":"","correctAnswer":12842,"isBookmarked":0,"aid":"11704#12842#13123#44555","text":"$7,500#$75,000#$7,000#$17,500"},
{"testId":589,"qid":25392,"description":"If you are guilty of driving without insurance,:","explanation":null,"imageName":"","correctAnswer":34158,"isBookmarked":0,"aid":"24989#34158#34159#34160","text":"none of the above will occur#your driver license or your vehicle's license plates may be suspended#you may be fined $500#you may be fined $1,000"},
{"testId":1056,"qid":25392,"description":"If you are guilty of driving without insurance,:","explanation":null,"imageName":"","correctAnswer":34158,"isBookmarked":0,"aid":"24989#34158#34159#34160","text":"none of the above will occur#your driver license or your vehicle's license plates may be suspended#you may be fined $500#you may be fined $1,000"},
{"testId":589,"qid":25393,"description":"If you're convicted of driving uninsured twice in two years, your license or plates may be suspended for __ days and you'll need insurance proof and a ____ fee to reinstate them.","explanation":null,"imageName":"","correctAnswer":13162,"isBookmarked":0,"aid":"13162#13163#13164#13165","text":"90; $200#90; $100#30; $100#30; $50"},
{"testId":1056,"qid":25393,"description":"If you're convicted of driving uninsured twice in two years, your license or plates may be suspended for __ days and you'll need insurance proof and a ____ fee to reinstate them.","explanation":null,"imageName":"","correctAnswer":13162,"isBookmarked":0,"aid":"13162#13163#13164#13165","text":"90; $200#90; $100#30; $100#30; $50"},
{"testId":589,"qid":25395,"description":"If you need to file an accident report in Missouri, you must do so within ___ days after the accident.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5447#6389#6477","text":"10#15#3#30"},
{"testId":591,"qid":25396,"description":"If you fail an alcohol test and a police officer seizes your driver's license, you will be given a temporary license that will expire in ___ days.","explanation":"If you fail an alcohol test and a police officer seizes your driver's license, you will be awarded a temporary license that will expire in 15 days. [Nebraska Driver's Manual, 1E-7 Administrative License Revocation, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6389#6477","text":"10#15#3#30"},
{"testId":591,"qid":25397,"description":"Which of the following may result in the termination of driving privileges in Nebraska?","explanation":"In Nebraska, a driver's license may be suspended, cancelled, or seized if he or she is guilty of driving under the influence of alcohol or drugs, leaving the scene of an accident, or several traffic offences, among other things. Some infractions that may result in the loss of your driving privileges are not related to driving, such as failing to pay child support. [1E-1 When Your Driver's License Could Be Suspended, Revoked, or Impounded, Suspensions, revocations, impoundments, and forfeitures, Nebraska Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23204#24985#34733","text":"All of the above#Leaving the scene of an accident#Driving under the influence (DUI)#Repeated traffic violations"},
{"testId":591,"qid":25398,"description":"A driver under the age of 21 who accumulates at least ___ points on his or her driving record in a year must undergo a driver improvement course.","explanation":"A motorist under the age of 21 who has a Provisional Operator's Permit (POP) or driver's license and earns at least six points on his or her driving record in a year must successfully complete a driver improvement course. If the motorist does not comply within three months, his or her POP or license will be revoked. [1B-13 General Permit, License, and State Identification (ID) Card Information, Nebraska Driver's Manual, 1B Types of Permits and Licenses], [1E-1 When Your License May Be Suspended, Revoked, or Impounded, Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6916#7559#7620#11342","text":"6#7#8#9"},
{"testId":591,"qid":25400,"description":"If the DMV tells you that your license has been revoked for accruing too many points and you do not relinquish it, you may be sentenced to ___ days in jail.","explanation":"If the DMV tells you that your license has been canceled for accruing too many points and you refuse to surrender it, you might face a $500 fine and up to 30 days in county prison. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1058,"qid":25400,"description":"If the DMV tells you that your license has been revoked for accruing too many points and you do not relinquish it, you may be sentenced to ___ days in jail.","explanation":"If the DMV tells you that your license has been canceled for accruing too many points and you refuse to surrender it, you might face a $500 fine and up to 30 days in county prison. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":591,"qid":25401,"description":"For a reinstatement of your driving privileges after having them suspended for too many points, you must complete a state-approved driver's education and training program of at least ______ hours' duration.","explanation":"If your driver's license is revoked due to a high number of points, you must finish an authorized driver's education and training course with at least eight hours of teaching before your driving privileges may be restored. You must pay for the course on your own. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":591,"qid":25402,"description":"If your license is suspended for point accumulation, you must preserve proof of financial responsibility for _____ year(s) after the suspension is lifted.","explanation":"If your driver's license is revoked for accruing too many points, you must keep evidence of financial responsibility for three years after the revocation expires. Your insurer's Form SR-22 normally serves as proof of financial obligation. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":591,"qid":25403,"description":"If your license is revoked twice within five years for excessive point accumulation, you cannot receive a new license for _____ years.","explanation":"If your driver's license is revoked twice in five years for excessive point accumulation, you will be unable to acquire a new license for three years. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":591,"qid":25404,"description":"A conviction for vehicular murder will result in ___ points being added to your driving record.","explanation":"A conviction for vehicular homicide will result in the addition of twelve points to your driving record, which is the maximum number of points you can receive for a single offense. [1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1058,"qid":25404,"description":"A conviction for vehicular murder will result in ___ points being added to your driving record.","explanation":"A conviction for vehicular homicide will result in the addition of twelve points to your driving record, which is the maximum number of points you can receive for a single offense. [1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":591,"qid":25405,"description":"A third DUI conviction will result in ____ points being added to your driving record.","explanation":"A third DUI conviction will result in twelve points being added to your driving record. The maximum amount of points you may get for a single transgression is twelve. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1058,"qid":25405,"description":"A third DUI conviction will result in ____ points being added to your driving record.","explanation":"A third DUI conviction will result in twelve points being added to your driving record. The maximum amount of points you may get for a single transgression is twelve. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":591,"qid":25406,"description":"If you fail to report an accident to law enforcement, you will receive _____ points on your driving record.","explanation":"Leaving the site of an accident, failing to stop and offer help in an accident involving you, and failing to report an accident to law authorities will all add six points to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1058,"qid":25406,"description":"If you fail to report an accident to law enforcement, you will receive _____ points on your driving record.","explanation":"Leaving the site of an accident, failing to stop and offer help in an accident involving you, and failing to report an accident to law authorities will all add six points to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":591,"qid":25408,"description":"A reckless driving conviction will add ___ points to your driving record, but a purposeful or intentional reckless driving conviction will add ___ points.","explanation":"Reckless driving will result in a five-point penalty, while deliberate or purposeful reckless driving will result in a six-point penalty. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34734,"isBookmarked":0,"aid":"13719#25020#34734#34735","text":"5; 10#4; 5#5; 6#3; 5"},
{"testId":1058,"qid":25408,"description":"A reckless driving conviction will add ___ points to your driving record, but a purposeful or intentional reckless driving conviction will add ___ points.","explanation":"Reckless driving will result in a five-point penalty, while deliberate or purposeful reckless driving will result in a six-point penalty. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34734,"isBookmarked":0,"aid":"13719#25020#34734#34735","text":"5; 10#4; 5#5; 6#3; 5"},
{"testId":591,"qid":25410,"description":"Leaving the scene of an accident without stopping will result in the adding of ___ points to your driving record.","explanation":"Leaving the site of an accident, failing to stop and offer help in an accident involving you, and failing to report an accident to law authorities will all add six points to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1058,"qid":25410,"description":"Leaving the scene of an accident without stopping will result in the adding of ___ points to your driving record.","explanation":"Leaving the site of an accident, failing to stop and offer help in an accident involving you, and failing to report an accident to law authorities will all add six points to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":591,"qid":25411,"description":"Three points will be added to your driving record if you use a hand-held wireless device to _________ while driving.","explanation":"Texting, emailing, or reading text on a wireless handheld device will result in three points being added to your driving record. A fine of between $200 and $500 will be imposed if convicted. [1E-3 Nebraska's \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual], [2D Handheld Wireless Communication Device, 2 Important Driver Information, Nebraska Driver's Manual]","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"1784#23817#34736#34737","text":"send a text message#do any of the above#read your email#send an email message"},
{"testId":1058,"qid":25411,"description":"Three points will be added to your driving record if you use a hand-held wireless device to _________ while driving.","explanation":"Texting, emailing, or reading text on a wireless handheld device will result in three points being added to your driving record. A fine of between $200 and $500 will be imposed if convicted. [1E-3 Nebraska's \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual], [2D Handheld Wireless Communication Device, 2 Important Driver Information, Nebraska Driver's Manual]","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"1784#23817#34736#34737","text":"send a text message#do any of the above#read your email#send an email message"},
{"testId":591,"qid":25413,"description":"______ points will be added to your driving record if you are found guilty of exceeding the posted speed limit by 8 mph while driving inside city boundaries.","explanation":"If you are guilty of exceeding the stated speed limit by more than 5 mph but no more than 10 mph inside city boundaries, two points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1058,"qid":25413,"description":"______ points will be added to your driving record if you are found guilty of exceeding the posted speed limit by 8 mph while driving inside city boundaries.","explanation":"If you are guilty of exceeding the stated speed limit by more than 5 mph but no more than 10 mph inside city boundaries, two points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":591,"qid":25416,"description":"If you are guilty of exceeding the prescribed speed limit by up to ___ mph outside of city boundaries, just one point will be added to your driving record.","explanation":"If you are guilty of exceeding the official speed limit by up to ten miles per hour outside of city boundaries, just one point will be added to your driving record. If you are guilty of exceeding the prescribed speed limit by up to 5 mph inside city boundaries, just one point will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1058,"qid":25416,"description":"If you are guilty of exceeding the prescribed speed limit by up to ___ mph outside of city boundaries, just one point will be added to your driving record.","explanation":"If you are guilty of exceeding the official speed limit by up to ten miles per hour outside of city boundaries, just one point will be added to your driving record. If you are guilty of exceeding the prescribed speed limit by up to 5 mph inside city boundaries, just one point will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":591,"qid":25417,"description":"If you are caught driving with an expired driver's license, you will receive ___ point(s) on your driving record.","explanation":"Driving with an expired license results in just one point being added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":591,"qid":25418,"description":"When determining how many points to assign to a driver's licence, what date is used?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":27213,"isBookmarked":0,"aid":"26577#27213#27215#34738","text":"The driver's date of birth#The date of violation#The date of conviction#The license's expiration date"},
{"testId":591,"qid":25420,"description":"In Nebraska, how long do convictions stay on a person's driving record?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1021#11285#11341#11760","text":"1 year#2 years#5 years#10 years"},
{"testId":1058,"qid":25420,"description":"In Nebraska, how long do convictions stay on a person's driving record?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1021#11285#11341#11760","text":"1 year#2 years#5 years#10 years"},
{"testId":591,"qid":25421,"description":"How many points will an out-of-state traffic violation add to your Nebraska driving record?","explanation":"You will get the same amount of points if you are convicted of a traffic infraction in another state as if the offence happened in Nebraska. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34740,"isBookmarked":0,"aid":"897#34739#34740#34741","text":"None of the above#Twice as many points as if the violation had occurred in Nebraska#The same number of points as if the violation had occurred in Nebraska#Half as many points as if the violation had occurred in Nebraska"},
{"testId":1058,"qid":25421,"description":"How many points will an out-of-state traffic violation add to your Nebraska driving record?","explanation":"You will get the same amount of points if you are convicted of a traffic infraction in another state as if the offence happened in Nebraska. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34740,"isBookmarked":0,"aid":"897#34739#34740#34741","text":"None of the above#Twice as many points as if the violation had occurred in Nebraska#The same number of points as if the violation had occurred in Nebraska#Half as many points as if the violation had occurred in Nebraska"},
{"testId":591,"qid":25422,"description":"If you finish a DMV-approved Driver Improvement Course before accumulating 12 points, you will have ___ points deducted from your driving record.","explanation":"If you complete a DMV-approved Driver Improvement Course before accumulating twelve points, you will have two points removed from your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1058,"qid":25422,"description":"If you finish a DMV-approved Driver Improvement Course before accumulating 12 points, you will have ___ points deducted from your driving record.","explanation":"If you complete a DMV-approved Driver Improvement Course before accumulating twelve points, you will have two points removed from your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":591,"qid":25423,"description":"How frequently can you finish a DMV-approved Driver Improvement Course to remove two points from your driving record?","explanation":"You may eliminate two points from your driving record by completing a DMV-approved Driver Improvement Course every five years. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34745,"isBookmarked":0,"aid":"34742#34743#34744#34745","text":"Just once#Every year#Every two years#Every five years"},
{"testId":1058,"qid":25423,"description":"How frequently can you finish a DMV-approved Driver Improvement Course to remove two points from your driving record?","explanation":"You may eliminate two points from your driving record by completing a DMV-approved Driver Improvement Course every five years. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":34745,"isBookmarked":0,"aid":"34742#34743#34744#34745","text":"Just once#Every year#Every two years#Every five years"},
{"testId":591,"qid":25429,"description":"The Administrative License Revocation Law empowers law enforcement to instantly remove your driver's license if:","explanation":"If a motorist fails an alcohol test or rejects a drug or alcohol test, Nebraska law allows law enforcement to immediately take the driver's license and grant a temporary license valid for 15 days. After then, unless the driver asks a hearing to oppose the revocation, the license will be revoked. [Nebraska Driver's Manual, 1E-7 Administrative License Revocation, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":37202,"isBookmarked":0,"aid":"37200#37201#37202#37203","text":"(a) you refuse to submit to chemical testing for alcohol or drugs#(b) you fail a test for alcohol#either (a) or (b)#neither (a) nor (b)"},
{"testId":591,"qid":25430,"description":"If a driver under the age of 18 is convicted in juvenile court of any offense, the court may suspend his or her permit or license for a period of:","explanation":"If a motorist under the age of 18 is convicted of any infraction in juvenile court, the court may confiscate his or her permit or license for 30 days. [1E-15 Juvenile Court Offenses (17 and Under), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":591,"qid":25431,"description":"Your driver's license will be revoked for a period of up to______if you are found guilty of fleeing the scene of an accident without providing necessary assistance.","explanation":"Your driver's license may be suspended for up to a year if you are convicted of leaving the scene of an accident. [Nebraska Driver's Manual, 1E-12 Leaving the Scene, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24048#26672","text":"6 months#1 year#60 days#5 years"},
{"testId":591,"qid":25432,"description":"If a motorist under the age of 21 is convicted for the first time of carrying or drinking alcohol, the court will impound his or her permit or license for_______","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":591,"qid":25433,"description":"If a motorist under the age of 21 is convicted for the second time of possessing or drinking alcohol, the court will impound his or her permit or license for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":591,"qid":25434,"description":"If a motorist under the age of 18 is convicted of a drug offense for the first time, the court will impound his or her license or permit for a period of:","explanation":"If a motorist under the age of 18 is convicted of a drug offense for the first time, his or her license or permit will be impounded for 30 days. A second drug conviction means a 90-day impoundment. A third drug conviction means a one-year impoundment. [Nebraska Driver's Manual, 1E-14 Juvenile Court Drug-Related Offenses (17 and Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":591,"qid":25435,"description":"If you operate a motor vehicle in Nebraska, you grant your approval to _________ upon the request of a law enforcement officer under Nebraska's Implied Consent law.","explanation":"Under Nebraska's Implied Submit statute, all drivers who operate motor vehicles in Nebraska consent to chemical testing for alcohol or drugs if a law enforcement official requests it. [Implied Consent Law, Nebraska Driver's Manual, 2A Alcohol, Drugs, and Driving, Important Driver Information]","imageName":"","correctAnswer":13824,"isBookmarked":0,"aid":"763#13824#34751#34752","text":"all of the above#chemical testing for alcohol or drugs#having your home searched#the release of your medical records"},
{"testId":591,"qid":25436,"description":"The criminal consequences for refusing to submit to chemical testing for alcohol or drugs are the same as for a DUI conviction with a blood alcohol content (BAC) of ______ or higher.","explanation":"The criminal consequences for refusing to submit to chemical testing for alcohol or drugs are the same as for a DUI conviction with a BAC of 0.15 percent or higher. It should be noted that if your BAC is 0.08 percent or more, you are legally inebriated. [2A Alcohol, Drugs, and Driving, 2 Important Driver Information, Nebraska Driver's Manual, Implied Consent Law]","imageName":"","correctAnswer":11770,"isBookmarked":0,"aid":"3168#4252#11768#11770","text":"0.08%#0.05%#0.10%#0.15%"},
{"testId":1058,"qid":25436,"description":"The criminal consequences for refusing to submit to chemical testing for alcohol or drugs are the same as for a DUI conviction with a blood alcohol content (BAC) of ______ or higher.","explanation":"The criminal consequences for refusing to submit to chemical testing for alcohol or drugs are the same as for a DUI conviction with a BAC of 0.15 percent or higher. It should be noted that if your BAC is 0.08 percent or more, you are legally inebriated. [2A Alcohol, Drugs, and Driving, 2 Important Driver Information, Nebraska Driver's Manual, Implied Consent Law]","imageName":"","correctAnswer":11770,"isBookmarked":0,"aid":"3168#4252#11768#11770","text":"0.08%#0.05%#0.10%#0.15%"},
{"testId":591,"qid":25437,"description":"In the event that you are found guilty of breaking the Occupant Protection (seat belt) law in the state, you will be required to pay a fine of:","explanation":null,"imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":591,"qid":25438,"description":"____ point(s) will be added to your license if you are found guilty of breaking state's Child Passenger Restraint Law.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":591,"qid":25439,"description":"Who will be penalized if a front-seat passenger is not wearing a seat belt?","explanation":null,"imageName":"","correctAnswer":9808,"isBookmarked":0,"aid":"9808#34753#34754#34755","text":"The driver#The passenger#Both the driver and the passenger#Neither the driver nor the passenger"},
{"testId":591,"qid":25440,"description":"If you are found guilty of texting while driving, you will have to pay a minimum of _____ in fine.","explanation":"It is illegal in Nebraska to read or transmit written messages on a portable wireless device. A fine of between $200 and $500 is imposed for violations. [2D Handheld Wireless Communication Device, Nebraska Driver's Manual, 2 Important Driver Information]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#24995","text":"$200#$100#$50#$150"},
{"testId":591,"qid":25441,"description":"If a Nebraska citizen under the age of ____ must drive alone to and from school, he or she may apply for a School Learner's Permit (LPE).","explanation":null,"imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11783#11784","text":"15#16#13#14"},
{"testId":1058,"qid":25441,"description":"If a Nebraska citizen under the age of ____ must drive alone to and from school, he or she may apply for a School Learner's Permit (LPE).","explanation":null,"imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11783#11784","text":"15#16#13#14"},
{"testId":591,"qid":25442,"description":"Which of the following must be CORRECT for a motorist to acquire a School Permit (SCP)?","explanation":null,"imageName":"","correctAnswer":34759,"isBookmarked":0,"aid":"34756#34757#34758#34759","text":"The driver must be at least 14 years and 2 months of age#The driver must live at least 1.5 miles from his or her school#Either the driver must live outside a city of at least 5,000 or the school must be located outside a city of at least 5,000#All of the above must be true"},
{"testId":591,"qid":25443,"description":"An aspiring driver with a learner's permit must be supervised while driving. Which of the following standards does NOT apply to driving supervisor?","explanation":null,"imageName":"","correctAnswer":34763,"isBookmarked":0,"aid":"34760#34761#34762#34763","text":"He or she must be at least 21 years of age#He or she must be seated next to the driver#He or she must be a licensed driver#He or she must own his or her own vehicle"},
{"testId":591,"qid":25444,"description":"A provisional operator's permit (POP) allows a driver over the age of 16 to drive alone from ______ to_____ .","explanation":"A POP permits a motorist who is at least 16 years old to drive unattended between the hours of 6 a.m. and midnight. The motorist may drive alone between the hours of 12 a.m. and 6 a.m. only if he or she is driving to or from school or work. [1B-5 Provisional Operator's Permit (POP), 1B Types of Permits and Licenses, Nebraska Driver's Manual, Nebraska License to Drive]","imageName":"","correctAnswer":13195,"isBookmarked":0,"aid":"13193#13194#13195#13196","text":"7 a.m.; 10 p.m#6 a.m.; 10 p.m#6 a.m.; midnight#8 a.m.; midnight"},
{"testId":591,"qid":25445,"description":"Before seeking for a driver's license, drivers under the age of ___ must first get a provisional operator's permit (POP).","explanation":"A Nebraska driver under the age of 18 must have held a POP for at least one year and accumulated no more than three points in the previous twelve months to be eligible for a driver's license. [1B-6 Operator's License (Class O), 1B Types of Permits and Licenses, Nebraska Driver's Manual, Nebraska License to Drive]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":593,"qid":25446,"description":"According to the State of New Hampshire Driver's Manual, a driver who has consumed alcohol is ___ times more likely to be involved in an accident.","explanation":"If you consume alcohol, your chances of getting into a car accident are seven times higher than if you don't. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#6388#6389#7559","text":"10#2#3#7"},
{"testId":1060,"qid":25446,"description":"According to the State of New Hampshire Driver's Manual, a driver who has consumed alcohol is ___ times more likely to be involved in an accident.","explanation":"If you consume alcohol, your chances of getting into a car accident are seven times higher than if you don't. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#6388#6389#7559","text":"10#2#3#7"},
{"testId":593,"qid":25447,"description":"The State of New Hampshire Driver's Manual claims that more than ______ individuals die in alcohol-related accidents each year in the United States.","explanation":"According to the State of New Hampshire Driver's Manual, more than 25,000 individuals die in alcohol-related accidents in the United States each year. (Note: This number may not represent recent considerable decreases in alcohol-related driving deaths. The Centers for Disease Control and Prevention and the National Highway Driving Safety Administration estimate that alcohol-related traffic deaths have been about 10,000 per year in recent years.) [\"Impaired Driving: Get the Facts,\" Centers for Disease Control, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet.html], [Overview, \"Drunk Driving,\" National Highway Traffic Safety Administration, https://www.nhtsa.gov/risky-driving/drunk-driving]","imageName":"","correctAnswer":12623,"isBookmarked":0,"aid":"12622#12623#13197#13198","text":"10,000#25,000#15,000#75,000"},
{"testId":1060,"qid":25447,"description":"The State of New Hampshire Driver's Manual claims that more than ______ individuals die in alcohol-related accidents each year in the United States.","explanation":"According to the State of New Hampshire Driver's Manual, more than 25,000 individuals die in alcohol-related accidents in the United States each year. (Note: This number may not represent recent considerable decreases in alcohol-related driving deaths. The Centers for Disease Control and Prevention and the National Highway Driving Safety Administration estimate that alcohol-related traffic deaths have been about 10,000 per year in recent years.) [\"Impaired Driving: Get the Facts,\" Centers for Disease Control, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet.html], [Overview, \"Drunk Driving,\" National Highway Traffic Safety Administration, https://www.nhtsa.gov/risky-driving/drunk-driving]","imageName":"","correctAnswer":12623,"isBookmarked":0,"aid":"12622#12623#13197#13198","text":"10,000#25,000#15,000#75,000"},
{"testId":593,"qid":25448,"description":"Which of the following will help you get sober after drinking alcohol?","explanation":"Your body degrades alcohol at a fixed pace. This is usually one ounce of alcohol each hour. This pace cannot be increased by coffee, a cold shower, or food. You will just have to wait till the alcohol has been broken down if you have drank alcohol. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#35427","text":"None of the above#Coffee#A cold shower#Food"},
{"testId":1060,"qid":25448,"description":"Which of the following will help you get sober after drinking alcohol?","explanation":"Your body degrades alcohol at a fixed pace. This is usually one ounce of alcohol each hour. This pace cannot be increased by coffee, a cold shower, or food. You will just have to wait till the alcohol has been broken down if you have drank alcohol. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#35427","text":"None of the above#Coffee#A cold shower#Food"},
{"testId":593,"qid":25449,"description":"Every _____, the body can digest one ounce of alcohol.聽:","explanation":"The body can break down and excrete around one ounce of alcohol every hour. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35428,"isBookmarked":0,"aid":"35428#35429#35430#35431","text":"hour#half hour#fifteen minutes#day"},
{"testId":1060,"qid":25449,"description":"Every _____, the body can digest one ounce of alcohol.聽:","explanation":"The body can break down and excrete around one ounce of alcohol every hour. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35428,"isBookmarked":0,"aid":"35428#35429#35430#35431","text":"hour#half hour#fifteen minutes#day"},
{"testId":593,"qid":25450,"description":"A driver under the age of 21 who has a blood alcohol content (BAC) of ______ or higher is deemed legally inebriated in New Hampshire.","explanation":"A motorist under the age of 21 who has a blood alcohol concentration (BAC) of 0.02 percent or more is deemed legally inebriated in New Hampshire. With only one alcoholic drink, many individuals will attain or surpass a BAC of 0.02 percent. As a result, a BAC level of less than 0.02 percent virtually equals zero tolerance for young drivers. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4252#4652","text":"0.08%#0.02%#0.05%#0.00%"},
{"testId":1060,"qid":25450,"description":"A driver under the age of 21 who has a blood alcohol content (BAC) of ______ or higher is deemed legally inebriated in New Hampshire.","explanation":"A motorist under the age of 21 who has a blood alcohol concentration (BAC) of 0.02 percent or more is deemed legally inebriated in New Hampshire. With only one alcoholic drink, many individuals will attain or surpass a BAC of 0.02 percent. As a result, a BAC level of less than 0.02 percent virtually equals zero tolerance for young drivers. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4252#4652","text":"0.08%#0.02%#0.05%#0.00%"},
{"testId":593,"qid":25451,"description":"A driver who is at least 21 years old and has a BAC of ______ or higher is deemed legally inebriated.","explanation":"A motorist who is at least 21 years old and has a blood alcohol concentration (BAC) of 0.08 percent or above is deemed legally inebriated in all US states. [BAC], Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":1060,"qid":25451,"description":"A driver who is at least 21 years old and has a BAC of ______ or higher is deemed legally inebriated.","explanation":"A motorist who is at least 21 years old and has a blood alcohol concentration (BAC) of 0.08 percent or above is deemed legally inebriated in all US states. [BAC], Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":593,"qid":25452,"description":"A blood alcohol level (BAC) as low as _____ can impair a driver's ability to drive safely.","explanation":"A blood alcohol level (BAC) as little as 0.02 percent may impair a driver's ability to drive safely. There is no safe quantity of alcohol to ingest. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#3282#4251#4252","text":"0.04%#0.06%#0.02%#0.05%"},
{"testId":1060,"qid":25452,"description":"A blood alcohol level (BAC) as low as _____ can impair a driver's ability to drive safely.","explanation":"A blood alcohol level (BAC) as little as 0.02 percent may impair a driver's ability to drive safely. There is no safe quantity of alcohol to ingest. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#3282#4251#4252","text":"0.04%#0.06%#0.02%#0.05%"},
{"testId":593,"qid":25453,"description":"According to the State of New Hampshire Driver's Manual, scientific data shows that a blood alcohol content (BAC) of _____ or higher impairs everyone's ability to operate a vehicle.","explanation":"According to the State of New Hampshire Driver's Manual, scientific data shows that a BAC of 0.08 percent or above impairs everyone's ability to drive a vehicle. And, indeed, a person of any age is deemed legally inebriated if their blood alcohol concentration (BAC) is 0.08 percent or more. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11770","text":"0.08%#0.02%#0.05%#0.15%"},
{"testId":1060,"qid":25453,"description":"According to the State of New Hampshire Driver's Manual, scientific data shows that a blood alcohol content (BAC) of _____ or higher impairs everyone's ability to operate a vehicle.","explanation":"According to the State of New Hampshire Driver's Manual, scientific data shows that a BAC of 0.08 percent or above impairs everyone's ability to drive a vehicle. And, indeed, a person of any age is deemed legally inebriated if their blood alcohol concentration (BAC) is 0.08 percent or more. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11770","text":"0.08%#0.02%#0.05%#0.15%"},
{"testId":593,"qid":25454,"description":"If a driver under the age of 21 is convicted of carrying alcohol in a vehicle without the supervision of an adult, his or her driving license may be suspended for ____ days.","explanation":"A motorist under the age of 21 who is guilty of carrying alcohol in a vehicle without adult supervision may have his or her license suspended for 60 days. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual] [Transportation of Alcoholic Beverages by a Minor; Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5447#6355#6477#8141","text":"15#90#30#60"},
{"testId":1060,"qid":25454,"description":"If a driver under the age of 21 is convicted of carrying alcohol in a vehicle without the supervision of an adult, his or her driving license may be suspended for ____ days.","explanation":"A motorist under the age of 21 who is guilty of carrying alcohol in a vehicle without adult supervision may have his or her license suspended for 60 days. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual] [Transportation of Alcoholic Beverages by a Minor; Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5447#6355#6477#8141","text":"15#90#30#60"},
{"testId":593,"qid":25455,"description":"A driver who is at least 21 years old may legally transport an open container of alcohol __________, under New Hampshire's Open Container statute.","explanation":"An open container of alcohol may not be placed anywhere in the passenger section of a vehicle under New Hampshire's Open Container statute. An open container of alcohol may be stowed in the trunk or another part of the car that is inaccessible to the driver. A motorist under the age of 21 may not carry alcohol at all unless accompanied by an adult. [Part Fourteen, Alcohol, Drugs, and Driving, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35435,"isBookmarked":0,"aid":"35432#35433#35434#35435","text":"in the console of his or her vehicle#under the front seat of his or her vehicle#in the rear seat of his or her vehicle#in the trunk of his or her vehicle"},
{"testId":593,"qid":25456,"description":"The first time you are caught breaking New Hampshire's open container legislation, you might lose your driving privileges for:","explanation":"If you break New Hampshire's Open Container Law for the first time, your driving privileges may be banned for 60 days. Your driving privileges may be revoked for up to a year if this is your second or subsequent violation. [Part Fourteen, Alcohol, Drugs, and Driving, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"227#229#1020#1272","text":"180 days#90 days#60 days#6 months"},
{"testId":593,"qid":25458,"description":"By driving on public highways in New Hampshire, you expressly agree to:","explanation":"By driving on public highways in New Hampshire, you implicitly submit to chemical testing for alcohol and drugs at the request of a law enforcement officer. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":1060,"qid":25458,"description":"By driving on public highways in New Hampshire, you expressly agree to:","explanation":"By driving on public highways in New Hampshire, you implicitly submit to chemical testing for alcohol and drugs at the request of a law enforcement officer. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":593,"qid":25460,"description":"If you refuse to submit to chemical testing for the second time in the last 10 years, your driver license will be suspended for :","explanation":"If you refuse to submit to chemical testing and have a DWI (driving while intoxicated) conviction or previous refusal within the past ten years, your driver license will be revoked for two years. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":1060,"qid":25460,"description":"If you refuse to submit to chemical testing for the second time in the last 10 years, your driver license will be suspended for :","explanation":"If you refuse to submit to chemical testing and have a DWI (driving while intoxicated) conviction or previous refusal within the past ten years, your driver license will be revoked for two years. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":593,"qid":25461,"description":"If you refuse to submit to chemical testing and have been convicted of DWI within the last 10 years, your driver license will be suspended for :","explanation":"If you refuse to submit to chemical testing and have a DWI (driving while intoxicated) conviction or previous refusal within the past ten years, your driver license will be revoked for two years. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":1060,"qid":25461,"description":"If you refuse to submit to chemical testing and have been convicted of DWI within the last 10 years, your driver license will be suspended for :","explanation":"If you refuse to submit to chemical testing and have a DWI (driving while intoxicated) conviction or previous refusal within the past ten years, your driver license will be revoked for two years. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":593,"qid":25462,"description":"Which penalty may result from a DWI conviction?","explanation":"If you are convicted of DWI, you may face one or more of the following penalties: driver license suspension, a fine, a mandatory jail sentence, completion of an impaired-driver program, filing of an SR-22 financial responsibility form, and installation of an ignition interlock device (IID) on each of your vehicles. If your blood alcohol concentration (BAC) reaches a certain threshold, the IID will prohibit the car from starting. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35437#35438","text":"All of the above#A fine#A mandatory jail sentence#Mandatory completion of an impaired-driver program"},
{"testId":1060,"qid":25462,"description":"Which penalty may result from a DWI conviction?","explanation":"If you are convicted of DWI, you may face one or more of the following penalties: driver license suspension, a fine, a mandatory jail sentence, completion of an impaired-driver program, filing of an SR-22 financial responsibility form, and installation of an ignition interlock device (IID) on each of your vehicles. If your blood alcohol concentration (BAC) reaches a certain threshold, the IID will prohibit the car from starting. [Alcohol, Drugs, and Driving - Part Fourteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35437#35438","text":"All of the above#A fine#A mandatory jail sentence#Mandatory completion of an impaired-driver program"},
{"testId":593,"qid":25463,"description":"If you were impaired by _____________ while driving, you might be charged with DWI.","explanation":"You may be charged with DWI if your driving was affected by any chemical, natural or manufactured. Prescription drugs, over-the-counter pharmaceuticals such as cold or allergy treatments, marijuana, and, of course, alcohol are examples of such substances. [Drugs; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#35439#35440","text":"Any of the above#marijuana#prescription medications#over-the-counter cold medications"},
{"testId":1060,"qid":25463,"description":"If you were impaired by _____________ while driving, you might be charged with DWI.","explanation":"You may be charged with DWI if your driving was affected by any chemical, natural or manufactured. Prescription drugs, over-the-counter pharmaceuticals such as cold or allergy treatments, marijuana, and, of course, alcohol are examples of such substances. [Drugs; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#35439#35440","text":"Any of the above#marijuana#prescription medications#over-the-counter cold medications"},
{"testId":593,"qid":25466,"description":"If you are involved in an accident that causes death, injury, or property damage of more than $1,000, you must file an accident report within 24 hours.","explanation":"If you are involved in an accident that causes death, injury, or property damage of more than $1,000, you must submit an accident report within 15 days. [Part Sixteen, Insurance, Accidents, and Financial Responsibility, State of New Hampshire Driver's Manual]","imageName":"202006141611292838.jpg","correctAnswer":23884,"isBookmarked":0,"aid":"23883#23884#23885#34746","text":"10 days#15 days#20 days#3 days"},
{"testId":593,"qid":25467,"description":"Is it necessary to obtain liability insurance for your automobile?","explanation":"There is no mandated insurance requirement in New Hampshire. Even if you may lawfully drive an uninsured car, it might pose a significant financial risk to you. If you are involved in an accident, one or more of the other parties may hold you liable for the payment of their injuries and losses. Medical costs, in particular, may be exceedingly costly. Your license may be suspended until you can pay your debts. In addition, New Hampshire may compel you to insure your car for the following several years. [State of New Hampshire Driver's Manual, Part Sixteen, Reporting the Accident, Accidents and Financial Responsibility]","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#35441#35442#35443","text":"No#Yes, if the vehicle is less than 10 years old#Yes, if you're under 25 years of age#Yes, if the car is valued at over $10,000"},
{"testId":593,"qid":25468,"description":"If you are compelled to obtain auto insurance due to a prior accident, the policy must have at least ______ in property damage coverage.","explanation":"If you are in an accident, you may be compelled to have insurance for a period of time. Property damage coverage of at least $25,000 must be included in the insurance policy. [Part Sixteen, Insurance, Accidents, and Financial Responsibility, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12048#12842","text":"$50,000#$10,000#$25,000#$75,000"},
{"testId":593,"qid":25471,"description":"If convicted, which of the following offenses will add two points to your driving record?","explanation":"Driving an unregistered car, operating a vehicle with the incorrect class of license, failing to observe a police officer's directives, failing to provide your license to a police officer upon request, and disrespecting licensing limitations will all result in two points being added to your driving record. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35444#35445#35446","text":"All of the above#Failing to obey a police officer's directions#Failing to show your driver license to a police officer upon request#Disobeying license restrictions"},
{"testId":1060,"qid":25471,"description":"If convicted, which of the following offenses will add two points to your driving record?","explanation":"Driving an unregistered car, operating a vehicle with the incorrect class of license, failing to observe a police officer's directives, failing to provide your license to a police officer upon request, and disrespecting licensing limitations will all result in two points being added to your driving record. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35444#35445#35446","text":"All of the above#Failing to obey a police officer's directions#Failing to show your driver license to a police officer upon request#Disobeying license restrictions"},
{"testId":593,"qid":25472,"description":"__ points will be added to your driving record for running a red light.","explanation":"Three points will be added to your driving record if you are guilty of failing to obey a traffic control device such as a traffic light. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1060,"qid":25472,"description":"__ points will be added to your driving record for running a red light.","explanation":"Three points will be added to your driving record if you are guilty of failing to obey a traffic control device such as a traffic light. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":593,"qid":25473,"description":"Conviction as a result of driving on ________ will add three points to the driver's driving record.","explanation":"If you are convicted of driving on a sidewalk, you will get three points on your license. If you are convicted of driving on the wrong side of the road, you will get four points on your license. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":13217,"isBookmarked":0,"aid":"5899#13217#13218#35447","text":"a crosswalk#a sidewalk#an unpaved road#the wrong side of the road"},
{"testId":1060,"qid":25473,"description":"Conviction as a result of driving on ________ will add three points to the driver's driving record.","explanation":"If you are convicted of driving on a sidewalk, you will get three points on your license. If you are convicted of driving on the wrong side of the road, you will get four points on your license. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":13217,"isBookmarked":0,"aid":"5899#13217#13218#35447","text":"a crosswalk#a sidewalk#an unpaved road#the wrong side of the road"},
{"testId":593,"qid":25474,"description":"Three points will be added to your driving record if you fail to:","explanation":"Three points will be added to your driving record if you are guilty of failing to indicate a turn or failing to obey a stop sign, yield sign, or other traffic control device. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"13219#13220#23817#35448","text":"obey a yield sign#obey a stop sign#do any of the above#signal a turn"},
{"testId":1060,"qid":25474,"description":"Three points will be added to your driving record if you fail to:","explanation":"Three points will be added to your driving record if you are guilty of failing to indicate a turn or failing to obey a stop sign, yield sign, or other traffic control device. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"13219#13220#23817#35448","text":"obey a yield sign#obey a stop sign#do any of the above#signal a turn"},
{"testId":593,"qid":25475,"description":"If you are guilty of exceeding the prescribed speed limit by less than ______ miles per hour, three points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"522#523#2429#15720","text":"25 mph#20 mph#10 mph#5 mph"},
{"testId":1060,"qid":25475,"description":"If you are guilty of exceeding the prescribed speed limit by less than ______ miles per hour, three points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"522#523#2429#15720","text":"25 mph#20 mph#10 mph#5 mph"},
{"testId":593,"qid":25476,"description":"Which of the following convictions will NOT get you three points on your driving record?","explanation":"Each time you coast, fail to show your license plates, or tailgate a fire vehicle, you will get three points on your driving record. Driving without the proper insurance will result in a four-point penalty. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35451,"isBookmarked":0,"aid":"35449#35450#35451#35452","text":"Coasting#Failing to display license plates#Driving without required insurance#Tailgating a fire truck"},
{"testId":1060,"qid":25476,"description":"Which of the following convictions will NOT get you three points on your driving record?","explanation":"Each time you coast, fail to show your license plates, or tailgate a fire vehicle, you will get three points on your driving record. Driving without the proper insurance will result in a four-point penalty. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35451,"isBookmarked":0,"aid":"35449#35450#35451#35452","text":"Coasting#Failing to display license plates#Driving without required insurance#Tailgating a fire truck"},
{"testId":593,"qid":25479,"description":"___ points will be added to your driving record if you are found guilty of tampering with a vehicle's VIN number.","explanation":"Six points will be added to your driving record if you are guilty of deleting or modifying a vehicle's Vehicle Identification Number. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1060,"qid":25479,"description":"___ points will be added to your driving record if you are found guilty of tampering with a vehicle's VIN number.","explanation":"Six points will be added to your driving record if you are guilty of deleting or modifying a vehicle's Vehicle Identification Number. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":593,"qid":25480,"description":"If you lend your license to a minor to buy alcohol, following number of points will be added to your driving record:","explanation":"If you are convicted of lending your license to an underage individual in order for him or her to acquire alcohol, you will get six points on your driving record. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1060,"qid":25480,"description":"If you lend your license to a minor to buy alcohol, following number of points will be added to your driving record:","explanation":"If you are convicted of lending your license to an underage individual in order for him or her to acquire alcohol, you will get six points on your driving record. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":593,"qid":25481,"description":"If you are guilty of which of the following, six points will be added to your driving record?","explanation":"DWI, drag racing, reckless driving, and failing to stop after being involved in an accident are just a few of the highly significant offences that will result in a six-point increase on a driver's record. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#23204#29308","text":"All of the above#Reckless driving#Leaving the scene of an accident#Drag racing"},
{"testId":1060,"qid":25481,"description":"If you are guilty of which of the following, six points will be added to your driving record?","explanation":"DWI, drag racing, reckless driving, and failing to stop after being involved in an accident are just a few of the highly significant offences that will result in a six-point increase on a driver's record. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#23204#29308","text":"All of the above#Reckless driving#Leaving the scene of an accident#Drag racing"},
{"testId":593,"qid":25482,"description":"DWI convictions add ___ points to your driving record.","explanation":"If you are convicted of driving while intoxicated (DWI), you will get six points on your license. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1060,"qid":25482,"description":"DWI convictions add ___ points to your driving record.","explanation":"If you are convicted of driving while intoxicated (DWI), you will get six points on your license. The highest amount of points you may get for a single infraction is six. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":593,"qid":25486,"description":"If a driver between the ages of 18 and 20 accumulates ___ points in two consecutive years, his or her license may be suspended for up to six months.","explanation":"If a driver between the ages of 18 and 20 accrues 15 points in two consecutive years, his or her license may be suspended for up to six months. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#11361#11783","text":"15#20#16#13"},
{"testId":1060,"qid":25486,"description":"If a driver between the ages of 18 and 20 accumulates ___ points in two consecutive years, his or her license may be suspended for up to six months.","explanation":"If a driver between the ages of 18 and 20 accrues 15 points in two consecutive years, his or her license may be suspended for up to six months. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#11361#11783","text":"15#20#16#13"},
{"testId":593,"qid":25487,"description":"If you are found guilty of causing significant bodily harm to another person while driving under the influence, you face a prison term of ______ days.","explanation":null,"imageName":"","correctAnswer":12455,"isBookmarked":0,"aid":"6477#7559#11784#12455","text":"30#7#14#35"},
{"testId":1060,"qid":25487,"description":"If you are found guilty of causing significant bodily harm to another person while driving under the influence, you face a prison term of ______ days.","explanation":null,"imageName":"","correctAnswer":12455,"isBookmarked":0,"aid":"6477#7559#11784#12455","text":"30#7#14#35"},
{"testId":593,"qid":25488,"description":"If you have a Youth Operator license, you are not permitted to drive between:","explanation":"You may not drive between 1:00 a.m. and 4:00 a.m. if you have a Youth Operator license. [Youth Operator License, Driver Licensing Information - Part Two, Driver's Manual for the State of New Hampshire]","imageName":"","correctAnswer":35453,"isBookmarked":0,"aid":"12456#32260#35453#35454","text":"10:00 p.m. and 4:00 a.m#11:00 p.m. and 7:00 a.m#1:00 a.m. and 4:00 a.m#12:00 a.m. and 6:00 a.m"},
{"testId":593,"qid":25489,"description":"For the first six months of holding a Youth Operator license, you may not carry more than ___ unrelated passenger(s) under the age of 25 unless accompanied by an adult.","explanation":"Unless supervised by a driver over the age of 25, you may not carry more than one passenger under the age of 25 who is not a member of your family during the first six months of holding a Youth Operator license. [Youth Operator License, Driver Licensing Information - Part Two, Driver's Manual for the State of New Hampshire]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":593,"qid":25490,"description":"A person under the age of 18 must complete ___ hours of supervised driving practice in addition to the 10 hours of practice required by a driver education program before obtaining a New Hampshire driver license.","explanation":"Before he or she may obtain a New Hampshire driver license, a person under 18 years of age must complete 40 hours of supervised driving practice in addition to the 10 hours of practice required by a driver education program. [Practice Driving, Driver Licensing Information - Part Two, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"5448#8141#11513#12359","text":"20#60#40#80"},
{"testId":593,"qid":25491,"description":"If you need glasses or contacts to pass the vision test, your driver's license will have restriction:","explanation":"If you need corrective glasses to pass the vision exam, you will have restriction B (corrective lenses) added to your driving license. When you drive, you must wear corrective glasses. [License Restrictions, Classified License System, Driver Licensing Information - Part Two, Driver's Manual for the State of New Hampshire]","imageName":"","correctAnswer":323,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":1060,"qid":25491,"description":"If you need glasses or contacts to pass the vision test, your driver's license will have restriction:","explanation":"If you need corrective glasses to pass the vision exam, you will have restriction B (corrective lenses) added to your driving license. When you drive, you must wear corrective glasses. [License Restrictions, Classified License System, Driver Licensing Information - Part Two, Driver's Manual for the State of New Hampshire]","imageName":"","correctAnswer":323,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":593,"qid":25492,"description":"For how much duration聽is a driver's license valid in New Hampshire?","explanation":"A New Hampshire driving license is valid for five years from the day it is issued. [License Expirations, Classified License System, Driver Licensing Information - Part Two, Driver Licensing Information - Part Three, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35458,"isBookmarked":0,"aid":"35455#35456#35457#35458","text":"2 years from the date of application#4 years from the date of application#3 birthdays from the date of issuance#5 birthdays from the date of issuance"},
{"testId":1060,"qid":25492,"description":"For how much duration聽is a driver's license valid in New Hampshire?","explanation":"A New Hampshire driving license is valid for five years from the day it is issued. [License Expirations, Classified License System, Driver Licensing Information - Part Two, Driver Licensing Information - Part Three, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":35458,"isBookmarked":0,"aid":"35455#35456#35457#35458","text":"2 years from the date of application#4 years from the date of application#3 birthdays from the date of issuance#5 birthdays from the date of issuance"},
{"testId":593,"qid":25493,"description":"Every motor vehicle registered in New Hampshire is subject to inspection:","explanation":null,"imageName":"","correctAnswer":35459,"isBookmarked":0,"aid":"32049#35459#35460#35461","text":"every five years#once per year#twice per year#every two years"},
{"testId":1060,"qid":25493,"description":"Every motor vehicle registered in New Hampshire is subject to inspection:","explanation":null,"imageName":"","correctAnswer":35459,"isBookmarked":0,"aid":"32049#35459#35460#35461","text":"every five years#once per year#twice per year#every two years"},
{"testId":593,"qid":25494,"description":"Every driver under the age of ____ in New Hampshire is required to wear a safety belt.","explanation":"Under New Hampshire law, all occupants in a vehicle under the age of 18 must wear safety belts or appropriate child restraints. [Safety Equipment, Vehicle Equipment - Part Four, Driver's Manual for the State of New Hampshire]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5446#6478#11359#11361","text":"10#12#18#16"},
{"testId":593,"qid":25495,"description":"Every kid under the age of ____ must be restrained in a child passenger restraint device in New Hampshire unless he or she is at least 55 inches tall.","explanation":"Every kid under the age of six and less than 55 inches tall is required by New Hampshire law to be restrained in a child safety restraint device. [Required Child Passenger Restraints, Safety Equipment, Vehicle Equipment - Part Four, New Hampshire Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":582,"qid":25496,"description":"What is the bare minimum penalty for littering?","explanation":"Littering carries a $50 fine and 8 hours of community service cleaning up trash in Los Angeles. The maximum punishment is much greater. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":13241,"isBookmarked":0,"aid":"12463#13241#13242#13243","text":"$100 fine#$50 fine and 8 hours of community service#$100 fine and 8 hours of community service#$100 fine and 20 hours of community service"},
{"testId":1049,"qid":25496,"description":"What is the bare minimum penalty for littering?","explanation":"Littering carries a $50 fine and 8 hours of community service cleaning up trash in Los Angeles. The maximum punishment is much greater. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":13241,"isBookmarked":0,"aid":"12463#13241#13242#13243","text":"$100 fine#$50 fine and 8 hours of community service#$100 fine and 8 hours of community service#$100 fine and 20 hours of community service"},
{"testId":582,"qid":25497,"description":"What is the maximum find for littering?","explanation":"Littering carries a $5,000 fine, a one-year license suspension, 30 days in prison, and 100 hours of community service in Los Angeles. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18217#31342#31343","text":"All of the above#$5,000 fine#One-year license suspension#30 days in jail and 100 hours of community service"},
{"testId":1049,"qid":25497,"description":"What is the maximum find for littering?","explanation":"Littering carries a $5,000 fine, a one-year license suspension, 30 days in prison, and 100 hours of community service in Los Angeles. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18217#31342#31343","text":"All of the above#$5,000 fine#One-year license suspension#30 days in jail and 100 hours of community service"},
{"testId":582,"qid":25498,"description":"What is the biggest cause of death among Americans aged 16 to 24 ?","explanation":"Drunk driving is the biggest cause of mortality among Americans aged 16 to 24. As a result, the state takes steps to prevent underage drinking and intoxicated driving. [Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31346,"isBookmarked":0,"aid":"31344#31345#31346#31347","text":"Drug overdose#Obesity#Drunk driving#Cancer"},
{"testId":1049,"qid":25498,"description":"What is the biggest cause of death among Americans aged 16 to 24 ?","explanation":"Drunk driving is the biggest cause of mortality among Americans aged 16 to 24. As a result, the state takes steps to prevent underage drinking and intoxicated driving. [Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31346,"isBookmarked":0,"aid":"31344#31345#31346#31347","text":"Drug overdose#Obesity#Drunk driving#Cancer"},
{"testId":582,"qid":25499,"description":"What exactly is BAC stand for?","explanation":"The term \"BAC\" refers for blood alcohol content (legally known as Blood Alcohol Content). In Louisiana and the rest of the US, this test is used to determine the level of drunkenness caused by alcohol. The definition of BAC in North America is the mass of alcohol divided by the volume of blood, given as a percentage. [La. Class D & E Driver's Guide, Blood Alcohol Concentration, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":13252,"isBookmarked":0,"aid":"13251#13252#13253#31348","text":"Blood Alcohol Consumption#Blood Alcohol Concentration#Binging Alcohol Concentration#Blood Alcohol Count"},
{"testId":1049,"qid":25499,"description":"What exactly is BAC stand for?","explanation":"The term \"BAC\" refers for blood alcohol content (legally known as Blood Alcohol Content). In Louisiana and the rest of the US, this test is used to determine the level of drunkenness caused by alcohol. The definition of BAC in North America is the mass of alcohol divided by the volume of blood, given as a percentage. [La. Class D & E Driver's Guide, Blood Alcohol Concentration, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":13252,"isBookmarked":0,"aid":"13251#13252#13253#31348","text":"Blood Alcohol Consumption#Blood Alcohol Concentration#Binging Alcohol Concentration#Blood Alcohol Count"},
{"testId":582,"qid":25500,"description":"Which of the following terms does the state of Louisiana use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"Driving While Intoxicated (DWI) is the term used by the state of Louisiana to describe the crime of driving a motor vehicle while impaired by alcohol or other substances. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":13255,"isBookmarked":0,"aid":"897#13254#13255#13256","text":"None of the above#DUI (Driving Under the Influence)#DWI (Driving While Intoxicated)#DWA (Driving with Alcohol)"},
{"testId":1049,"qid":25500,"description":"Which of the following terms does the state of Louisiana use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"Driving While Intoxicated (DWI) is the term used by the state of Louisiana to describe the crime of driving a motor vehicle while impaired by alcohol or other substances. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":13255,"isBookmarked":0,"aid":"897#13254#13255#13256","text":"None of the above#DUI (Driving Under the Influence)#DWI (Driving While Intoxicated)#DWA (Driving with Alcohol)"},
{"testId":582,"qid":25501,"description":"What is the maximum punishment for a first DWI conviction for an adult in Louisiana?","explanation":"Your first DWI conviction may result in up to six months in prison, a $1,000 fine plus court costs, and a one-year suspension of your driving privileges. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12627#13257#31349","text":"All of the above#6 months in jail#$1,000 fine plus court costs#Loss of driver's license for 365 days"},
{"testId":1049,"qid":25501,"description":"What is the maximum punishment for a first DWI conviction for an adult in Louisiana?","explanation":"Your first DWI conviction may result in up to six months in prison, a $1,000 fine plus court costs, and a one-year suspension of your driving privileges. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12627#13257#31349","text":"All of the above#6 months in jail#$1,000 fine plus court costs#Loss of driver's license for 365 days"},
{"testId":582,"qid":25502,"description":"If a driver under the age of 21 is convicted of DWI for the first time, his or her driving privileges will be revoked for ____","explanation":"An underage driver who is convicted of DWI for the first time will have his or her driving privileges suspended for up to 180 days. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1049,"qid":25502,"description":"If a driver under the age of 21 is convicted of DWI for the first time, his or her driving privileges will be revoked for ____","explanation":"An underage driver who is convicted of DWI for the first time will have his or her driving privileges suspended for up to 180 days. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":582,"qid":25503,"description":"An adult convicted of DWI for the second time will be imprisoned for at least:","explanation":"If an adult is found guilty of DWI a second time, they might spend anywhere from 48 hours to 6 months behind bars. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":20754,"isBookmarked":0,"aid":"20753#20754#23883#23886","text":"24 hours#48 hours#10 days#30 days"},
{"testId":1049,"qid":25503,"description":"An adult convicted of DWI for the second time will be imprisoned for at least:","explanation":"If an adult is found guilty of DWI a second time, they might spend anywhere from 48 hours to 6 months behind bars. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":20754,"isBookmarked":0,"aid":"20753#20754#23883#23886","text":"24 hours#48 hours#10 days#30 days"},
{"testId":582,"qid":25505,"description":"What is the maximum number of DWI offenses that will result in a felony criminal record?","explanation":"You will have a felony criminal record after your third DWI conviction. The consequences for a third DWI are severe, ranging from 1 to 5 years in jail. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1049,"qid":25505,"description":"What is the maximum number of DWI offenses that will result in a felony criminal record?","explanation":"You will have a felony criminal record after your third DWI conviction. The consequences for a third DWI are severe, ranging from 1 to 5 years in jail. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":582,"qid":25506,"description":"What are the possible penalties for a fourth DWI conviction?","explanation":"If you are convicted of DWI for the fourth time, you may face any or all of the following penalties: a felony criminal record, vehicle seizure and sale, six weeks of inpatient and then one year of outpatient alcohol or drug treatment, up to five years of home incarceration, up to thirty years in jail, and a $5,000 fine. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31352#31353#31354","text":"All of the above#Seizure and sale of vehicle#Six weeks of inpatient alcohol treatment#Five years of home incarceration"},
{"testId":1049,"qid":25506,"description":"What are the possible penalties for a fourth DWI conviction?","explanation":"If you are convicted of DWI for the fourth time, you may face any or all of the following penalties: a felony criminal record, vehicle seizure and sale, six weeks of inpatient and then one year of outpatient alcohol or drug treatment, up to five years of home incarceration, up to thirty years in jail, and a $5,000 fine. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31352#31353#31354","text":"All of the above#Seizure and sale of vehicle#Six weeks of inpatient alcohol treatment#Five years of home incarceration"},
{"testId":582,"qid":25508,"description":"You will have a felony criminal record for your third or fourth DWI conviction. What are some of the ramifications?","explanation":"There are several implications to having a criminal felony record. You will be unable to vote. Many occupations, especially military ones, may be out of reach for you. Some insurance companies may refuse to provide you a coverage. [Penalty for Driving While Intoxicated (DWI), Chapter 9: Driving Offenses, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":31358,"isBookmarked":0,"aid":"31355#31356#31357#31358","text":"You will not be able to vote#You may not be able to join the military#You may not be able to purchase auto insurance#All of the above are consequences"},
{"testId":1049,"qid":25508,"description":"You will have a felony criminal record for your third or fourth DWI conviction. What are some of the ramifications?","explanation":"There are several implications to having a criminal felony record. You will be unable to vote. Many occupations, especially military ones, may be out of reach for you. Some insurance companies may refuse to provide you a coverage. [Penalty for Driving While Intoxicated (DWI), Chapter 9: Driving Offenses, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":31358,"isBookmarked":0,"aid":"31355#31356#31357#31358","text":"You will not be able to vote#You may not be able to join the military#You may not be able to purchase auto insurance#All of the above are consequences"},
{"testId":582,"qid":25509,"description":"If you are convicted of DWI, you must provide proof of financial responsibility for the next ____ years.","explanation":"If you are convicted of DWI, you must keep evidence of financial responsibility for the following three years. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1049,"qid":25509,"description":"If you are convicted of DWI, you must provide proof of financial responsibility for the next ____ years.","explanation":"If you are convicted of DWI, you must keep evidence of financial responsibility for the following three years. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":582,"qid":25510,"description":"Who is financially responsible聽for DWI-related counseling, driver improvement courses, and community service?","explanation":null,"imageName":"","correctAnswer":9642,"isBookmarked":0,"aid":"9642#31359#31360#31361","text":"You#The state of Louisiana#Your auto insurer#Your community"},
{"testId":1049,"qid":25510,"description":"Who is financially responsible聽for DWI-related counseling, driver improvement courses, and community service?","explanation":null,"imageName":"","correctAnswer":9642,"isBookmarked":0,"aid":"9642#31359#31360#31361","text":"You#The state of Louisiana#Your auto insurer#Your community"},
{"testId":582,"qid":25511,"description":"You might face up to ____ years in prison if you cause a deadly accident while driving under the influence of drugs or alcohol.","explanation":"You might face up to 30 years in prison and additional fines if you cause a deadly accident while driving under the influence of drugs or alcohol. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5447#5448#6477","text":"10#15#20#30"},
{"testId":1049,"qid":25511,"description":"You might face up to ____ years in prison if you cause a deadly accident while driving under the influence of drugs or alcohol.","explanation":"You might face up to 30 years in prison and additional fines if you cause a deadly accident while driving under the influence of drugs or alcohol. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5447#5448#6477","text":"10#15#20#30"},
{"testId":582,"qid":25512,"description":"If you cause a deadly automobile accident while driving under the influence, you may :","explanation":"If you cause a fatal car accident while driving under the influence, the dead person's family may sue you for more than your vehicle insurance coverage, you may lose citizenship rights such as the ability to vote, and you will lose your driver's license. All of this, in addition to doing up to 30 years in jail and having to live with the knowing that you murdered someone. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31365,"isBookmarked":0,"aid":"31362#31363#31364#31365","text":"lose your driving privileges#be sued for amounts exceeding your insurance coverage#lose certain privileges of citizenship#incur all of the above"},
{"testId":1049,"qid":25512,"description":"If you cause a deadly automobile accident while driving under the influence, you may :","explanation":"If you cause a fatal car accident while driving under the influence, the dead person's family may sue you for more than your vehicle insurance coverage, you may lose citizenship rights such as the ability to vote, and you will lose your driver's license. All of this, in addition to doing up to 30 years in jail and having to live with the knowing that you murdered someone. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31365,"isBookmarked":0,"aid":"31362#31363#31364#31365","text":"lose your driving privileges#be sued for amounts exceeding your insurance coverage#lose certain privileges of citizenship#incur all of the above"},
{"testId":582,"qid":25513,"description":"How do the punishments for DWI caused by alcohol and DWI caused by drugs compare?","explanation":"The penalties are the same in Louisiana whether you were driving under the influence of alcohol, illicit narcotics, prescription prescriptions, or even over-the-counter pharmaceuticals. [Drugs, Chapter 9: Driving Offenses, Littering, License Revocation, Louisiana Class D & E Driver's Handbook]","imageName":"","correctAnswer":31368,"isBookmarked":0,"aid":"31366#31367#31368#31369","text":"The penalties for driving under the influence of alcohol are harsher#The penalties for driving under the influence of drugs are harsher#There is no difference in penalties#Penalties are doubled for drug-related DWI"},
{"testId":1049,"qid":25513,"description":"How do the punishments for DWI caused by alcohol and DWI caused by drugs compare?","explanation":"The penalties are the same in Louisiana whether you were driving under the influence of alcohol, illicit narcotics, prescription prescriptions, or even over-the-counter pharmaceuticals. [Drugs, Chapter 9: Driving Offenses, Littering, License Revocation, Louisiana Class D & E Driver's Handbook]","imageName":"","correctAnswer":31368,"isBookmarked":0,"aid":"31366#31367#31368#31369","text":"The penalties for driving under the influence of alcohol are harsher#The penalties for driving under the influence of drugs are harsher#There is no difference in penalties#Penalties are doubled for drug-related DWI"},
{"testId":582,"qid":25514,"description":"The effects of a marijuana cigarette might last for as long as ____ hours.","explanation":"A single marijuana cigarette may have an impact that lasts up to six hours. [Marijuana, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1049,"qid":25514,"description":"The effects of a marijuana cigarette might last for as long as ____ hours.","explanation":"A single marijuana cigarette may have an impact that lasts up to six hours. [Marijuana, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":582,"qid":25515,"description":"What is the minimum penalty for vehicular homicide?","explanation":"If you are guilty of vehicular murder (deliberate or unintentional), you will be fined between $2,000 and $15,000. [Vehicular Homicide, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11668#11669","text":"$500#$1,000#$1,500#$2,000"},
{"testId":1049,"qid":25515,"description":"What is the minimum penalty for vehicular homicide?","explanation":"If you are guilty of vehicular murder (deliberate or unintentional), you will be fined between $2,000 and $15,000. [Vehicular Homicide, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"290#1799#11668#11669","text":"$500#$1,000#$1,500#$2,000"},
{"testId":582,"qid":25516,"description":"If you are found guilty of causing the death of another person by the use of a motor vehicle, you will be imprisoned for__________.","explanation":"If you are convicted of vehicular murder (whether deliberate or not), you will face a minimum of 5 years in prison and a maximum of 30 years. [Vehicular Homicide, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31370,"isBookmarked":0,"aid":"31370#31371#31372#31373","text":"between 5 years and 30 years#between 2 years and 10 years#between 5 years and 10 years#between 3 years and 10 years"},
{"testId":1049,"qid":25516,"description":"If you are found guilty of causing the death of another person by the use of a motor vehicle, you will be imprisoned for__________.","explanation":"If you are convicted of vehicular murder (whether deliberate or not), you will face a minimum of 5 years in prison and a maximum of 30 years. [Vehicular Homicide, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31370,"isBookmarked":0,"aid":"31370#31371#31372#31373","text":"between 5 years and 30 years#between 2 years and 10 years#between 5 years and 10 years#between 3 years and 10 years"},
{"testId":582,"qid":25517,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driving privileges may be suspended or canceled until you do. If your license has been revoked, you may reapply for it. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":1049,"qid":25517,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":"If you do not pay court-ordered child support, your driving privileges may be suspended or canceled until you do. If your license has been revoked, you may reapply for it. [Revocation of Your License, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":582,"qid":25518,"description":"If you are unable to present proof of insurance when asked by a law enforcement officer, your license plate will be taken, and you will have ____ working days to provide the proof to a Motor Vehicle Office.","explanation":"If you are unable to present evidence of insurance when a law enforcement official requests it, your license plate may be taken and your car may be impounded. You will then have three business days to provide the evidence to a Motor Vehicle Office. If you don't, your license plate will be destroyed, and you'll have to apply for a new one. [Proof of Insurance, Louisiana Class D & E Driver's Guide, Chapter 11: Motor Vehicle Registration and Title]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":1049,"qid":25518,"description":"If you are unable to present proof of insurance when asked by a law enforcement officer, your license plate will be taken, and you will have ____ working days to provide the proof to a Motor Vehicle Office.","explanation":"If you are unable to present evidence of insurance when a law enforcement official requests it, your license plate may be taken and your car may be impounded. You will then have three business days to provide the evidence to a Motor Vehicle Office. If you don't, your license plate will be destroyed, and you'll have to apply for a new one. [Proof of Insurance, Louisiana Class D & E Driver's Guide, Chapter 11: Motor Vehicle Registration and Title]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":582,"qid":25519,"description":"If you are stopped for a driving violation, you should:","explanation":"If you are stopped for a driving violation, stay in your car and keep your hands visible. Be nice and courteous. Upon asked, provide the police your driver's license, car registration, and proof of insurance. [Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#14709#31374#31375","text":"Do all of the above#remain in your vehicle#produce all requested documents quickly#keep your hands visible"},
{"testId":1049,"qid":25519,"description":"If you are stopped for a driving violation, you should:","explanation":"If you are stopped for a driving violation, stay in your car and keep your hands visible. Be nice and courteous. Upon asked, provide the police your driver's license, car registration, and proof of insurance. [Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#14709#31374#31375","text":"Do all of the above#remain in your vehicle#produce all requested documents quickly#keep your hands visible"},
{"testId":582,"qid":25520,"description":"In Louisiana, which of the following is illegal?","explanation":"It is illegal in Louisiana to engage in a drag race on public roads. For a first offense, you might face a $175 fine and up to 30 days in jail. If a vehicle is making or about to make a left turn, or if you are going on a multilane highway, you may pass on the right. [Drag Racing, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:57; RS 32:65]","imageName":"","correctAnswer":29308,"isBookmarked":0,"aid":"897#29308#31376#31377","text":"None of the above#Drag racing#Passing a vehicle on the right#Driving over 60 mph"},
{"testId":1049,"qid":25520,"description":"In Louisiana, which of the following is illegal?","explanation":"It is illegal in Louisiana to engage in a drag race on public roads. For a first offense, you might face a $175 fine and up to 30 days in jail. If a vehicle is making or about to make a left turn, or if you are going on a multilane highway, you may pass on the right. [Drag Racing, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:57; RS 32:65]","imageName":"","correctAnswer":29308,"isBookmarked":0,"aid":"897#29308#31376#31377","text":"None of the above#Drag racing#Passing a vehicle on the right#Driving over 60 mph"},
{"testId":582,"qid":25521,"description":"Which of the following punishments may you face if you are convicted of stealing gasoline?","explanation":"If you are convicted of stealing less than $750 in motor gasoline, you might face any or all of the following penalties: a 6-month suspension of your driver's license, a $1,000 fine, and 6 months in jail. [Other Laws You Should Know, Chapter 6: Traffic Laws and Regulations, Louisiana Class D & E Driver's Guide; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67 .17]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#31378#31379","text":"All of the above#A fine#Imprisonment#Loss of driver's license"},
{"testId":1049,"qid":25521,"description":"Which of the following punishments may you face if you are convicted of stealing gasoline?","explanation":"If you are convicted of stealing less than $750 in motor gasoline, you might face any or all of the following penalties: a 6-month suspension of your driver's license, a $1,000 fine, and 6 months in jail. [Other Laws You Should Know, Chapter 6: Traffic Laws and Regulations, Louisiana Class D & E Driver's Guide; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67; RS 14:67 .17]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#31378#31379","text":"All of the above#A fine#Imprisonment#Loss of driver's license"},
{"testId":582,"qid":25522,"description":"Between the hours of 11:00 p.m. and 5:00 a.m., drivers under the age of ____ are not permitted to operate a vehicle on their own.","explanation":"Drivers under the age of 17 are not permitted to drive a vehicle between the hours of 11:00 p.m. and 5:00 a.m. unless accompanied by a licensed parent, guardian, or adult over the age of 21 or a sibling over the age of 18. [Other Laws to Be Aware Of, Chapter 6: Traffic Laws and Regulations, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1049,"qid":25522,"description":"Between the hours of 11:00 p.m. and 5:00 a.m., drivers under the age of ____ are not permitted to operate a vehicle on their own.","explanation":"Drivers under the age of 17 are not permitted to drive a vehicle between the hours of 11:00 p.m. and 5:00 a.m. unless accompanied by a licensed parent, guardian, or adult over the age of 21 or a sibling over the age of 18. [Other Laws to Be Aware Of, Chapter 6: Traffic Laws and Regulations, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":582,"qid":25524,"description":"A Louisiana driver under the age of 21 whose blood alcohol concentration (BAC) is _____ or higher will have his or her license suspended for 180 days.","explanation":"A Louisiana motorist under the age of 21 whose BAC is 0.02 percent or above will have his or her license suspended for 180 days. The least level detected by today's most routinely used chemical testing is 0.02 percent. [Administrative Per Se (Submit or Refuse Alcohol Analysis Test), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1049,"qid":25524,"description":"A Louisiana driver under the age of 21 whose blood alcohol concentration (BAC) is _____ or higher will have his or her license suspended for 180 days.","explanation":"A Louisiana motorist under the age of 21 whose BAC is 0.02 percent or above will have his or her license suspended for 180 days. The least level detected by today's most routinely used chemical testing is 0.02 percent. [Administrative Per Se (Submit or Refuse Alcohol Analysis Test), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":582,"qid":25525,"description":"Which of the following will result in the suspension of your driver's license?","explanation":"Convictions for, among other things, failing to stop for a stopped school bus, vehicular negligent homicide, or DWI will result in license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#13289#31380#31381","text":"All of the above#DWI#Failure to stop for a stopped school bus#Vehicular negligent homicide"},
{"testId":1049,"qid":25525,"description":"Which of the following will result in the suspension of your driver's license?","explanation":"Convictions for, among other things, failing to stop for a stopped school bus, vehicular negligent homicide, or DWI will result in license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#13289#31380#31381","text":"All of the above#DWI#Failure to stop for a stopped school bus#Vehicular negligent homicide"},
{"testId":582,"qid":25526,"description":"Three convictions for careless driving within a _____ will result in licence suspension.","explanation":"Three reckless driving offences within a year will result in license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":26646,"isBookmarked":0,"aid":"26646#26647#31382#31383","text":"one-year#two-year#five-year#seven-year"},
{"testId":1049,"qid":25526,"description":"Three convictions for careless driving within a _____ will result in licence suspension.","explanation":"Three reckless driving offences within a year will result in license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":26646,"isBookmarked":0,"aid":"26646#26647#31382#31383","text":"one-year#two-year#five-year#seven-year"},
{"testId":582,"qid":25527,"description":"If you have committed an infraction outside of Louisiana that would result in a license suspension in Louisiana,:","explanation":"Your Louisiana driver's license will be suspended if you commit an infraction in another state that would result in license suspension in Louisiana. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31385,"isBookmarked":0,"aid":"31384#31385#31386#31387","text":"you will be fined#your Louisiana driver's license will be suspended#you will be required to attend a hearing#none of the above will take place"},
{"testId":1049,"qid":25527,"description":"If you have committed an infraction outside of Louisiana that would result in a license suspension in Louisiana,:","explanation":"Your Louisiana driver's license will be suspended if you commit an infraction in another state that would result in license suspension in Louisiana. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31385,"isBookmarked":0,"aid":"31384#31385#31386#31387","text":"you will be fined#your Louisiana driver's license will be suspended#you will be required to attend a hearing#none of the above will take place"},
{"testId":582,"qid":25528,"description":"Which of the following behaviors while driving will result in the suspension of your license?","explanation":"Your license will be suspended if you are convicted of texting while driving. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31388,"isBookmarked":0,"aid":"31388#31389#31390#31391","text":"Sending a text message#Making a cell phone call#Answering a cell phone call#Making a hands-free cell phone call"},
{"testId":1049,"qid":25528,"description":"Which of the following behaviors while driving will result in the suspension of your license?","explanation":"Your license will be suspended if you are convicted of texting while driving. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31388,"isBookmarked":0,"aid":"31388#31389#31390#31391","text":"Sending a text message#Making a cell phone call#Answering a cell phone call#Making a hands-free cell phone call"},
{"testId":582,"qid":25529,"description":"When will driving while using a cell phone result in a license suspension?","explanation":"Cell phone use is forbidden while driving in Louisiana if the driver is under the age of 18, has an intermediate or learner's license, or has had an operator's license for one year or less. This will result in the suspension of your license. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31392#31393#31394","text":"All of the above#If the driver is under the age of 18#If the driver holds a learner's permit#If the driver has had an operator's license for one year or less"},
{"testId":1049,"qid":25529,"description":"When will driving while using a cell phone result in a license suspension?","explanation":"Cell phone use is forbidden while driving in Louisiana if the driver is under the age of 18, has an intermediate or learner's license, or has had an operator's license for one year or less. This will result in the suspension of your license. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31392#31393#31394","text":"All of the above#If the driver is under the age of 18#If the driver holds a learner's permit#If the driver has had an operator's license for one year or less"},
{"testId":582,"qid":25530,"description":"Your license will be suspended for an extra______ for each conviction of driving while suspended.","explanation":"If you are convicted of driving while your license is suspended, your license will be suspended for an extra year. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1049,"qid":25530,"description":"Your license will be suspended for an extra______ for each conviction of driving while suspended.","explanation":"If you are convicted of driving while your license is suspended, your license will be suspended for an extra year. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":582,"qid":25531,"description":"You must provide proof of financial responsibility in the form of high-risk insurance or a _______ deposit for your second refusal to submit to an alcohol analysis test upon request.","explanation":"You must provide evidence of financial responsibility in the form of an SR-22 (high-risk insurance) or $30,000 in cash or securities for your second refusal to submit to an alcohol analysis test upon request. [Administrative Per Se (Refuse or Submit Alcohol Analysis Test, Chapter 9: Driving Offenses, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11658#11659#12194","text":"$30,000#$10,000#$20,000#$40,000"},
{"testId":1049,"qid":25531,"description":"You must provide proof of financial responsibility in the form of high-risk insurance or a _______ deposit for your second refusal to submit to an alcohol analysis test upon request.","explanation":"You must provide evidence of financial responsibility in the form of an SR-22 (high-risk insurance) or $30,000 in cash or securities for your second refusal to submit to an alcohol analysis test upon request. [Administrative Per Se (Refuse or Submit Alcohol Analysis Test, Chapter 9: Driving Offenses, Louisiana Class D & E Driver's Guide]","imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11658#11659#12194","text":"$30,000#$10,000#$20,000#$40,000"},
{"testId":582,"qid":25533,"description":null,"explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":1049,"qid":25533,"description":null,"explanation":null,"imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2753#2754#5000#5001","text":"Two#Four#Six#eight"},
{"testId":582,"qid":25534,"description":"If you are found guilty of giving false information on the application for your driver's license, your license will be:","explanation":"Your license will be suspended if you are convicted of providing false information on your license application. However, in cases like these, the Department of Public Safety and Corrections may explore whether your license should be revoked instead. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11437#11439#31395","text":"revoked#canceled#suspended#withdrawn"},
{"testId":1049,"qid":25534,"description":"If you are found guilty of giving false information on the application for your driver's license, your license will be:","explanation":"Your license will be suspended if you are convicted of providing false information on your license application. However, in cases like these, the Department of Public Safety and Corrections may explore whether your license should be revoked instead. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":11439,"isBookmarked":0,"aid":"11436#11437#11439#31395","text":"revoked#canceled#suspended#withdrawn"},
{"testId":582,"qid":25535,"description":"If you fail to follow the financial responsibility law when needed,:","explanation":"Your license will be suspended if you fail to comply with the financial responsibility legislation when necessary. However, in cases like these, the Department of Public Safety and Corrections may explore whether your license should be revoked instead. If you are declared a repeat offender or otherwise unable to operate a motor vehicle, this action may be taken. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"29912#29914#31384#31396","text":"your license will be suspended#your vehicle will be impounded#you will be fined#your license plate will be confiscated"},
{"testId":1049,"qid":25535,"description":"If you fail to follow the financial responsibility law when needed,:","explanation":"Your license will be suspended if you fail to comply with the financial responsibility legislation when necessary. However, in cases like these, the Department of Public Safety and Corrections may explore whether your license should be revoked instead. If you are declared a repeat offender or otherwise unable to operate a motor vehicle, this action may be taken. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"29912#29914#31384#31396","text":"your license will be suspended#your vehicle will be impounded#you will be fined#your license plate will be confiscated"},
{"testId":582,"qid":25536,"description":"If your blood alcohol content (BAC) was _____ or above at the time of the collision, you could be guilty of vehicular negligent injury.","explanation":"If you were under the influence of alcohol or drugs at the time of the accident, or if your blood alcohol concentration (BAC) was 0.08 percent or above, you might be convicted of vehicular negligent injury. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11768","text":"0.08%#0.02%#0.05%#0.10%"},
{"testId":1049,"qid":25536,"description":"If your blood alcohol content (BAC) was _____ or above at the time of the collision, you could be guilty of vehicular negligent injury.","explanation":"If you were under the influence of alcohol or drugs at the time of the accident, or if your blood alcohol concentration (BAC) was 0.08 percent or above, you might be convicted of vehicular negligent injury. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#11768","text":"0.08%#0.02%#0.05%#0.10%"},
{"testId":582,"qid":25537,"description":"The maximum fine for negligent driving is:","explanation":"The maximum penalties for negligent driving is $1,000. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24978#31397","text":"$500#$1,000#$100#$10,000"},
{"testId":1049,"qid":25537,"description":"The maximum fine for negligent driving is:","explanation":"The maximum penalties for negligent driving is $1,000. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24978#31397","text":"$500#$1,000#$100#$10,000"},
{"testId":582,"qid":25539,"description":"A first-degree vehicular negligence injury is one in which:","explanation":"A vehicular negligent injury of the first degree is one in which someone is gravely harmed. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31399,"isBookmarked":0,"aid":"22673#31398#31399#31400","text":"None of the above are true#someone is killed#someone is seriously injured#property is damaged"},
{"testId":1049,"qid":25539,"description":"A first-degree vehicular negligence injury is one in which:","explanation":"A vehicular negligent injury of the first degree is one in which someone is gravely harmed. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":31399,"isBookmarked":0,"aid":"22673#31398#31399#31400","text":"None of the above are true#someone is killed#someone is seriously injured#property is damaged"},
{"testId":582,"qid":25540,"description":"The maximum penalty for first-degree vehicular negligence is:","explanation":"The maximum penalties for first-degree negligent vehicular harm is $2,000. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25036#31401#31402","text":"$2,000#$5,000#$12,000#$20,000"},
{"testId":1049,"qid":25540,"description":"The maximum penalty for first-degree vehicular negligence is:","explanation":"The maximum penalties for first-degree negligent vehicular harm is $2,000. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25036#31401#31402","text":"$2,000#$5,000#$12,000#$20,000"},
{"testId":582,"qid":25542,"description":"You must have a learner's permit for _______ before applying for an intermediate license.","explanation":"If you have a learner's permit, you must keep it for at least 180 days before applying for an intermediate license. [Graduated Licensing Program, Chapter 1: Obtaining a Driver's License, Louisiana Class D and E Driver's Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#228#742#1020","text":"180 days#120 days#30 days#60 days"},
{"testId":1049,"qid":25542,"description":"You must have a learner's permit for _______ before applying for an intermediate license.","explanation":"If you have a learner's permit, you must keep it for at least 180 days before applying for an intermediate license. [Graduated Licensing Program, Chapter 1: Obtaining a Driver's License, Louisiana Class D and E Driver's Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#228#742#1020","text":"180 days#120 days#30 days#60 days"},
{"testId":582,"qid":25543,"description":"How many non-family persons under the age of 21 may you transport in your vehicle if you have an intermediate license between 6:00 p.m. and 5:00 a.m.?","explanation":"If you have an intermediate license, you may carry just one person under the age of 21 who is not a member of your immediate family between the hours of 6:00 p.m. and 5:00 a.m. [Graduated Licensing Program, Chapter 1: Obtaining a Driver's License, Louisiana Class D and E Driver's Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1049,"qid":25543,"description":"How many non-family persons under the age of 21 may you transport in your vehicle if you have an intermediate license between 6:00 p.m. and 5:00 a.m.?","explanation":"If you have an intermediate license, you may carry just one person under the age of 21 who is not a member of your immediate family between the hours of 6:00 p.m. and 5:00 a.m. [Graduated Licensing Program, Chapter 1: Obtaining a Driver's License, Louisiana Class D and E Driver's Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":582,"qid":25544,"description":"Which type of driver's license is required to operate a passenger vehicle?","explanation":"A class E license permits you to drive motor vehicles weighing less than 10,000 pounds, recreational vehicles, and some agricultural use vehicles that are not classified as commercial vehicles.","imageName":"","correctAnswer":12378,"isBookmarked":0,"aid":"323#324#325#12378","text":"B#C#D#E"},
{"testId":1049,"qid":25544,"description":"Which type of driver's license is required to operate a passenger vehicle?","explanation":"A class E license permits you to drive motor vehicles weighing less than 10,000 pounds, recreational vehicles, and some agricultural use vehicles that are not classified as commercial vehicles.","imageName":"","correctAnswer":12378,"isBookmarked":0,"aid":"323#324#325#12378","text":"B#C#D#E"},
{"testId":582,"qid":25545,"description":"You have until ____ days before the expiration date to renew your driver's license.","explanation":"You may renew your driver's license at any time up to 180 days before it expires. [License Renewals, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1049,"qid":25545,"description":"You have until ____ days before the expiration date to renew your driver's license.","explanation":"You may renew your driver's license at any time up to 180 days before it expires. [License Renewals, Louisiana Class D & E Driver's Guide, Chapter 1: Getting a Driver's License]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":584,"qid":25546,"description":"If you are caught driving with a blood alcohol content (BAC) of ______ or above, you will be charged with driving while intoxicated (DUI).","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#11768#11769#11770","text":"0.08%#0.10%#0.12%#0.15%"},
{"testId":1051,"qid":25546,"description":"If you are caught driving with a blood alcohol content (BAC) of ______ or above, you will be charged with driving while intoxicated (DUI).","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#11768#11769#11770","text":"0.08%#0.10%#0.12%#0.15%"},
{"testId":584,"qid":25547,"description":"If you are under the age of 21,:","explanation":null,"imageName":"","correctAnswer":32768,"isBookmarked":0,"aid":"32768#32769#32770#32771","text":"it is not legal for you to be drinking at all#it is legal for you to have small amounts of alcohol#it is not legal for you to be driving at all#you can't be punished for drinking and driving"},
{"testId":1051,"qid":25547,"description":"If you are under the age of 21,:","explanation":null,"imageName":"","correctAnswer":32768,"isBookmarked":0,"aid":"32768#32769#32770#32771","text":"it is not legal for you to be drinking at all#it is legal for you to have small amounts of alcohol#it is not legal for you to be driving at all#you can't be punished for drinking and driving"},
{"testId":584,"qid":25548,"description":"In Maryland, which of the following substances can result in criminal charges if consumed while driving?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#28289","text":"All of the above#Prescription drugs#Marijuana#Over-the-counter cold medications"},
{"testId":1051,"qid":25548,"description":"In Maryland, which of the following substances can result in criminal charges if consumed while driving?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#28289","text":"All of the above#Prescription drugs#Marijuana#Over-the-counter cold medications"},
{"testId":584,"qid":25549,"description":"According to Maryland's open container statute, which of the following is an open container?","explanation":"An open container is defined as any open can, bottle, container, or package under Maryland law. The following are some examples: a six-pack of alcoholic beverages with an open or missing bottle or can; an empty bottle or can that once held alcohol [Open Alcohol Container, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32189#32190#32191","text":"All of the above#An opened beer can#An empty beer bottle#A six pack with a missing can"},
{"testId":1051,"qid":25549,"description":"According to Maryland's open container statute, which of the following is an open container?","explanation":"An open container is defined as any open can, bottle, container, or package under Maryland law. The following are some examples: a six-pack of alcoholic beverages with an open or missing bottle or can; an empty bottle or can that once held alcohol [Open Alcohol Container, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32189#32190#32191","text":"All of the above#An opened beer can#An empty beer bottle#A six pack with a missing can"},
{"testId":584,"qid":25550,"description":"Which of the following can lead to a doubled sentence for drunk driving?","explanation":"If you are convicted of driving while intoxicated or under the influence of drugs while carrying a youngster, the court may treble your punishment. [Transporting Children, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32194,"isBookmarked":0,"aid":"897#32192#32193#32194","text":"None of the above#A blood alcohol concentration (BAC) of 0.07%#Operating a vehicle you do not own#Transporting a child"},
{"testId":1051,"qid":25550,"description":"Which of the following can lead to a doubled sentence for drunk driving?","explanation":"If you are convicted of driving while intoxicated or under the influence of drugs while carrying a youngster, the court may treble your punishment. [Transporting Children, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32194,"isBookmarked":0,"aid":"897#32192#32193#32194","text":"None of the above#A blood alcohol concentration (BAC) of 0.07%#Operating a vehicle you do not own#Transporting a child"},
{"testId":584,"qid":25551,"description":"Which of the following best describes Maryland's cell phone law?","explanation":"Adult drivers in Maryland are only permitted to use cell phones and other mobile devices if they are hands-free. [Cell Phones, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32196,"isBookmarked":0,"aid":"897#32195#32196#32197","text":"None of the above#No cell phone use while driving#Only hands-free devices while driving#No texting but calls are allowed while driving"},
{"testId":1051,"qid":25551,"description":"Which of the following best describes Maryland's cell phone law?","explanation":"Adult drivers in Maryland are only permitted to use cell phones and other mobile devices if they are hands-free. [Cell Phones, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32196,"isBookmarked":0,"aid":"897#32195#32196#32197","text":"None of the above#No cell phone use while driving#Only hands-free devices while driving#No texting but calls are allowed while driving"},
{"testId":584,"qid":25552,"description":"Drivers under the age of 18 are not permitted to ________ while driving in Maryland.","explanation":"Drivers under the age of 18 are not permitted to use cell phones or other mobile devices while driving in Maryland, even hands-free devices. The sole exception is when making an emergency call. [Maryland Driver's Manual, Cell Phones, C. Distracted Driving, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32198,"isBookmarked":0,"aid":"32198#32199#32200#32201","text":"use a cell phone#eat a snack#listen to the radio#talk to passengers"},
{"testId":1051,"qid":25552,"description":"Drivers under the age of 18 are not permitted to ________ while driving in Maryland.","explanation":"Drivers under the age of 18 are not permitted to use cell phones or other mobile devices while driving in Maryland, even hands-free devices. The sole exception is when making an emergency call. [Maryland Driver's Manual, Cell Phones, C. Distracted Driving, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32198,"isBookmarked":0,"aid":"32198#32199#32200#32201","text":"use a cell phone#eat a snack#listen to the radio#talk to passengers"},
{"testId":584,"qid":25553,"description":"Any driver who operates a vehicle conveys implied agreement to:","explanation":"Any motorist in Maryland who runs a vehicle does so with the understanding that they agree to an alcohol or drug test. [B. Notice to Applicant- Implied Consent, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32202,"isBookmarked":0,"aid":"8615#25221#30364#32202","text":"All of the above#release of his or her medical records#a search of his or her home#chemical testing"},
{"testId":1051,"qid":25553,"description":"Any driver who operates a vehicle conveys implied agreement to:","explanation":"Any motorist in Maryland who runs a vehicle does so with the understanding that they agree to an alcohol or drug test. [B. Notice to Applicant- Implied Consent, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32202,"isBookmarked":0,"aid":"8615#25221#30364#32202","text":"All of the above#release of his or her medical records#a search of his or her home#chemical testing"},
{"testId":584,"qid":25554,"description":"If you refuse to participate in chemical testing,:","explanation":null,"imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"10227#29912#31384#32203","text":"you will go to jail#your license will be suspended#you will be fined#you will be forced to submit to the test"},
{"testId":1051,"qid":25554,"description":"If you refuse to participate in chemical testing,:","explanation":null,"imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"10227#29912#31384#32203","text":"you will go to jail#your license will be suspended#you will be fined#you will be forced to submit to the test"},
{"testId":584,"qid":25555,"description":"You must ________, if your driver's license is suspended, revoked, or canceled.","explanation":"If your license has been suspended, revoked, or canceled, you must return it to the MVA. Only until the MVA gets your license will credit for the period of suspension or revocation begin. [D. Administrative Actions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32204,"isBookmarked":0,"aid":"22727#32204#32205#32206","text":"Do none of the above#surrender it to the MVA#shred it#mail it to your local police station"},
{"testId":1051,"qid":25555,"description":"You must ________, if your driver's license is suspended, revoked, or canceled.","explanation":"If your license has been suspended, revoked, or canceled, you must return it to the MVA. Only until the MVA gets your license will credit for the period of suspension or revocation begin. [D. Administrative Actions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32204,"isBookmarked":0,"aid":"22727#32204#32205#32206","text":"Do none of the above#surrender it to the MVA#shred it#mail it to your local police station"},
{"testId":584,"qid":25556,"description":"If a driver under the age of 18 with a provisional license is convicted of a traffic offence, he or she will be obliged to:","explanation":"If a motorist under the age of 18 with a provisional license is convicted of a driving offence, he or she must undergo a driver improvement program. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32208,"isBookmarked":0,"aid":"32207#32208#32209#32210","text":"pay a $1,000 fine#complete a driver improvement program#retake the driver knowledge examination#notify his or her parent or guardian"},
{"testId":1051,"qid":25556,"description":"If a driver under the age of 18 with a provisional license is convicted of a traffic offence, he or she will be obliged to:","explanation":"If a motorist under the age of 18 with a provisional license is convicted of a driving offence, he or she must undergo a driver improvement program. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":32208,"isBookmarked":0,"aid":"32207#32208#32209#32210","text":"pay a $1,000 fine#complete a driver improvement program#retake the driver knowledge examination#notify his or her parent or guardian"},
{"testId":584,"qid":25557,"description":"Driving privileges for a provisionally licensed driver under the age of 18 are suspended for a period of __ days and he or she is placed on work and school restrictions for an additional __ days if convicted of a second moving violation.","explanation":"If a temporarily licensed driver under the age of 18 is convicted of a driving offence for the second time, his or her license will be suspended for 30 days, with employment and education restrictions imposed for an additional 90 days. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":13017,"isBookmarked":0,"aid":"13017#13330#13331#13332","text":"30; 90#15; 30#30; 60#60; 120"},
{"testId":1051,"qid":25557,"description":"Driving privileges for a provisionally licensed driver under the age of 18 are suspended for a period of __ days and he or she is placed on work and school restrictions for an additional __ days if convicted of a second moving violation.","explanation":"If a temporarily licensed driver under the age of 18 is convicted of a driving offence for the second time, his or her license will be suspended for 30 days, with employment and education restrictions imposed for an additional 90 days. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":13017,"isBookmarked":0,"aid":"13017#13330#13331#13332","text":"30; 90#15; 30#30; 60#60; 120"},
{"testId":584,"qid":25558,"description":"If a driver under the age of 18 with a provisional license is convicted of a moving infraction for the third time, his or her license will be suspended for ____ days, and he or she will be prohibited from driving for an additional ____ days.","explanation":"If a driver under the age of 18 with a provisional license is convicted of a moving violation for the third time, his or her license will be suspended for 180 days, he or she will be required to attend a driver improvement program, and he or she will be restricted from working and going to school for an additional 180 days. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":13333,"isBookmarked":0,"aid":"13333#13334#13335#13336","text":"180; 180#180; 90#90; 90#90; 60"},
{"testId":1051,"qid":25558,"description":"If a driver under the age of 18 with a provisional license is convicted of a moving infraction for the third time, his or her license will be suspended for ____ days, and he or she will be prohibited from driving for an additional ____ days.","explanation":"If a driver under the age of 18 with a provisional license is convicted of a moving violation for the third time, his or her license will be suspended for 180 days, he or she will be required to attend a driver improvement program, and he or she will be restricted from working and going to school for an additional 180 days. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":13333,"isBookmarked":0,"aid":"13333#13334#13335#13336","text":"180; 180#180; 90#90; 90#90; 60"},
{"testId":584,"qid":25559,"description":"After a ______ traffic offense, a driver under the age of 18 with a provisional license will have his or her license revoked.","explanation":"After his or her fourth traffic offense, a motorist under the age of 18 with a temporary license will have his or her license revoked. To have his or her license restored, the motorist must pass all applicable licensing examinations. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":1051,"qid":25559,"description":"After a ______ traffic offense, a driver under the age of 18 with a provisional license will have his or her license revoked.","explanation":"After his or her fourth traffic offense, a motorist under the age of 18 with a temporary license will have his or her license revoked. To have his or her license restored, the motorist must pass all applicable licensing examinations. [E. Sanctions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":584,"qid":25560,"description":"A passenger that supervises a student driver in Maryland:","explanation":"A supervising driver must be at least 21 years old, hold a valid driver's license for at least three years, and sit alongside the student driver. [B. Supervising Driver- Requirements and Responsibilities, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":32214,"isBookmarked":0,"aid":"32211#32212#32213#32214","text":"must be at least 21 years of age#must have held a driver's license for at least 3 years#must be seated beside the student driver#must meet all of the above requirements"},
{"testId":1051,"qid":25560,"description":"A passenger that supervises a student driver in Maryland:","explanation":"A supervising driver must be at least 21 years old, hold a valid driver's license for at least three years, and sit alongside the student driver. [B. Supervising Driver- Requirements and Responsibilities, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":32214,"isBookmarked":0,"aid":"32211#32212#32213#32214","text":"must be at least 21 years of age#must have held a driver's license for at least 3 years#must be seated beside the student driver#must meet all of the above requirements"},
{"testId":584,"qid":25561,"description":"Which of the following health problems must you tell the MVA about?","explanation":"The MVA must be informed about a variety of medical problems. Although certain problems may not preclude you from driving, the MVA's Driver Wellness & Safety Division or the MVA Medical Advisory Board may impose limits or seek further medical verification. [A. Reportable Medical Conditions, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32215#32216#32217","text":"All of the above#Diabetes that causes low blood sugar#Epilepsy#Bipolar disorder"},
{"testId":1051,"qid":25561,"description":"Which of the following health problems must you tell the MVA about?","explanation":"The MVA must be informed about a variety of medical problems. Although certain problems may not preclude you from driving, the MVA's Driver Wellness & Safety Division or the MVA Medical Advisory Board may impose limits or seek further medical verification. [A. Reportable Medical Conditions, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32215#32216#32217","text":"All of the above#Diabetes that causes low blood sugar#Epilepsy#Bipolar disorder"},
{"testId":584,"qid":25562,"description":"All automobiles driven in the state of Maryland must:","explanation":"All vehicles operated in Maryland are required to have liability insurance for both property damage and bodily harm. [E. Maryland Driver's Manual, Section 10: Other Important Information, Insurance Requirements]","imageName":"","correctAnswer":32218,"isBookmarked":0,"aid":"32218#32219#32220#32221","text":"be insured#have at least a six-cylinder engine#have been manufactured after the year 1980#be registered in Maryland"},
{"testId":1051,"qid":25562,"description":"All automobiles driven in the state of Maryland must:","explanation":"All vehicles operated in Maryland are required to have liability insurance for both property damage and bodily harm. [E. Maryland Driver's Manual, Section 10: Other Important Information, Insurance Requirements]","imageName":"","correctAnswer":32218,"isBookmarked":0,"aid":"32218#32219#32220#32221","text":"be insured#have at least a six-cylinder engine#have been manufactured after the year 1980#be registered in Maryland"},
{"testId":584,"qid":25563,"description":"Who is required to wear a seat belt or child restraint in Maryland?","explanation":"The driver and all passengers in a motor vehicle must wear a seat belt or be restrained in a child safety seat, according to Maryland Motor Vehicle Law. [Maryland Driver's Manual, Section X - Other Important Information, Seat Belt Law]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#40816#40817","text":"Only the driver#The driver and all passengers#Only the occupants of the front seat, including the driver#Only passengers under the age of 16"},
{"testId":1051,"qid":25563,"description":"Who is required to wear a seat belt or child restraint in Maryland?","explanation":"The driver and all passengers in a motor vehicle must wear a seat belt or be restrained in a child safety seat, according to Maryland Motor Vehicle Law. [Maryland Driver's Manual, Section X - Other Important Information, Seat Belt Law]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#40816#40817","text":"Only the driver#The driver and all passengers#Only the occupants of the front seat, including the driver#Only passengers under the age of 16"},
{"testId":584,"qid":25564,"description":"Who is required to wear a seat belt?","explanation":"The driver and all passengers in a motor vehicle must wear a seat belt or be restrained in a child safety seat, according to Maryland Motor Vehicle Law. [F. Maryland Driver's Manual, Section 10: Other Important Information, Seat Belt Law]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#25185#42947#42948","text":"The driver and all passengers#The driver only#Passengers only#No one - seat belts are optional"},
{"testId":1051,"qid":25564,"description":"Who is required to wear a seat belt?","explanation":"The driver and all passengers in a motor vehicle must wear a seat belt or be restrained in a child safety seat, according to Maryland Motor Vehicle Law. [F. Maryland Driver's Manual, Section 10: Other Important Information, Seat Belt Law]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#25185#42947#42948","text":"The driver and all passengers#The driver only#Passengers only#No one - seat belts are optional"},
{"testId":584,"qid":25565,"description":"If a child________, then he or she should use seat belt instead of child safety seat.","explanation":"A kid must wear a seat belt instead of a child safety seat if they are at least 8 years old or 4' 9\" tall. [G. Child Safety Seats, Section 10: Other Important Information, Maryland Driver's Manual]","imageName":"","correctAnswer":32222,"isBookmarked":0,"aid":"32222#32223#32224#32225","text":"is at least 8 years old#weighs over 30 pounds#is at least 3'9\" tall#meets any of these requirements"},
{"testId":1051,"qid":25565,"description":"If a child________, then he or she should use seat belt instead of child safety seat.","explanation":"A kid must wear a seat belt instead of a child safety seat if they are at least 8 years old or 4' 9\" tall. [G. Child Safety Seats, Section 10: Other Important Information, Maryland Driver's Manual]","imageName":"","correctAnswer":32222,"isBookmarked":0,"aid":"32222#32223#32224#32225","text":"is at least 8 years old#weighs over 30 pounds#is at least 3'9\" tall#meets any of these requirements"},
{"testId":584,"qid":25566,"description":"Which of the following are not permitted when driving in Maryland?","explanation":"The use of earplugs, earphones, or headsets while driving a motor vehicle is prohibited in Maryland. [J. Prohibition on Wearing Headsets, Earphones, and Earplugs, Section 10: Other Important Information, Maryland Driver's Manual]","imageName":"","correctAnswer":32228,"isBookmarked":0,"aid":"32226#32227#32228#32229","text":"Soft drinks#Cargo lights#Earphones#Alarm wristwatches"},
{"testId":1051,"qid":25566,"description":"Which of the following are not permitted when driving in Maryland?","explanation":"The use of earplugs, earphones, or headsets while driving a motor vehicle is prohibited in Maryland. [J. Prohibition on Wearing Headsets, Earphones, and Earplugs, Section 10: Other Important Information, Maryland Driver's Manual]","imageName":"","correctAnswer":32228,"isBookmarked":0,"aid":"32226#32227#32228#32229","text":"Soft drinks#Cargo lights#Earphones#Alarm wristwatches"},
{"testId":584,"qid":25567,"description":"What should you do if you are pulled over by a police officer?","explanation":"If a police officer pulls you over, you should perform the following: Pull off to the side of the road, away from traffic. Activate your emergency flashers. Switch off the engine and the radio. Maintain your seat with your seatbelt secured. Roll the window down. Maintain visibility of your hands. You should not quarrel with the police. [B. Traffic Stops, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#32230#32231#32232","text":"Do all of the above#Do not argue with the officer#Remain seated with your seat belt fastened#Turn off your engine and radio"},
{"testId":1051,"qid":25567,"description":"What should you do if you are pulled over by a police officer?","explanation":"If a police officer pulls you over, you should perform the following: Pull off to the side of the road, away from traffic. Activate your emergency flashers. Switch off the engine and the radio. Maintain your seat with your seatbelt secured. Roll the window down. Maintain visibility of your hands. You should not quarrel with the police. [B. Traffic Stops, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#32230#32231#32232","text":"Do all of the above#Do not argue with the officer#Remain seated with your seat belt fastened#Turn off your engine and radio"},
{"testId":584,"qid":25568,"description":"What should you do if you collide with an unattended vehicle?","explanation":"If you crash with an unattended car and the driver cannot be readily discovered, you must leave a descriptive notice in a conspicuous location on the vehicle. The message must include sufficient information for the other motorist to contact you or tell his or her insurance about you. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32234,"isBookmarked":0,"aid":"32233#32234#32235#32236","text":"Call 911#Leave a detailed note with contact information in an obvious location#Stay at the scene until the vehicle's owner arrives#Call the insurance agent of the vehicle's owner"},
{"testId":1051,"qid":25568,"description":"What should you do if you collide with an unattended vehicle?","explanation":"If you crash with an unattended car and the driver cannot be readily discovered, you must leave a descriptive notice in a conspicuous location on the vehicle. The message must include sufficient information for the other motorist to contact you or tell his or her insurance about you. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32234,"isBookmarked":0,"aid":"32233#32234#32235#32236","text":"Call 911#Leave a detailed note with contact information in an obvious location#Stay at the scene until the vehicle's owner arrives#Call the insurance agent of the vehicle's owner"},
{"testId":584,"qid":25569,"description":"If you hit and hurt a domestic animal, you must :","explanation":"If you hit and hurt a domestic animal, you must tell the authorities as quickly as possible. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32237,"isBookmarked":0,"aid":"22471#32237#32238#32239","text":"do any of the above#notify the police immediately#take the animal to the nearest veterinarian#locate the animal's owners"},
{"testId":1051,"qid":25569,"description":"If you hit and hurt a domestic animal, you must :","explanation":"If you hit and hurt a domestic animal, you must tell the authorities as quickly as possible. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32237,"isBookmarked":0,"aid":"22471#32237#32238#32239","text":"do any of the above#notify the police immediately#take the animal to the nearest veterinarian#locate the animal's owners"},
{"testId":584,"qid":25570,"description":"Mopeds are permitted on roads in Maryland with a speed restriction of _____ mph or less.","explanation":"Motor scooters and mopeds are legal in Maryland on any road with a speed restriction of 50 miles per hour or less. [G. Mopeds and Scooters, Maryland Driver's Manual, Section 7: Sharing the Road]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6354#7571#11513#12455","text":"45#50#40#35"},
{"testId":1051,"qid":25570,"description":"Mopeds are permitted on roads in Maryland with a speed restriction of _____ mph or less.","explanation":"Motor scooters and mopeds are legal in Maryland on any road with a speed restriction of 50 miles per hour or less. [G. Mopeds and Scooters, Maryland Driver's Manual, Section 7: Sharing the Road]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6354#7571#11513#12455","text":"45#50#40#35"},
{"testId":584,"qid":25571,"description":"An instructional permit in Maryland is valid for:","explanation":"An instructional permit in Maryland is valid for two years. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1051,"qid":25571,"description":"An instructional permit in Maryland is valid for:","explanation":"An instructional permit in Maryland is valid for two years. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":584,"qid":25572,"description":"Before being eligible for a provisional license, a young Maryland driver must possess an instructional permit for at least ________.","explanation":"Before applying for a provisional license in Maryland, a young driver must possess an instructional permit for at least nine months. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":1454,"isBookmarked":0,"aid":"229#1021#1272#1454","text":"90 days#1 year#6 months#9 months"},
{"testId":1051,"qid":25572,"description":"Before being eligible for a provisional license, a young Maryland driver must possess an instructional permit for at least ________.","explanation":"Before applying for a provisional license in Maryland, a young driver must possess an instructional permit for at least nine months. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":1454,"isBookmarked":0,"aid":"229#1021#1272#1454","text":"90 days#1 year#6 months#9 months"},
{"testId":584,"qid":25573,"description":"You must complete at least ____ hours of driving practice while driving with an instructional permit.","explanation":"You must complete at least 60 hours of driving practice while driving with an instructional permit, ten of which must be accomplished at night. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6356#8141#12359#13364","text":"120#60#80#140"},
{"testId":1051,"qid":25573,"description":"You must complete at least ____ hours of driving practice while driving with an instructional permit.","explanation":"You must complete at least 60 hours of driving practice while driving with an instructional permit, ten of which must be accomplished at night. [B. Learner's Instructional Permit, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6356#8141#12359#13364","text":"120#60#80#140"},
{"testId":584,"qid":25574,"description":"To apply for a provisional license in Maryland, you must be at least ______ years old.","explanation":"To apply for a provisional license in Maryland, you must be at least 16 1/2 years old. [C. Provisional License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":32240,"isBookmarked":0,"aid":"5447#11361#32240#32241","text":"15#16#16 1/2#15 1/2"},
{"testId":1051,"qid":25574,"description":"To apply for a provisional license in Maryland, you must be at least ______ years old.","explanation":"To apply for a provisional license in Maryland, you must be at least 16 1/2 years old. [C. Provisional License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":32240,"isBookmarked":0,"aid":"5447#11361#32240#32241","text":"15#16#16 1/2#15 1/2"},
{"testId":584,"qid":25575,"description":"To apply for a normal unrestricted driver's license in Maryland, you must be at least ___ years old.","explanation":"To apply for a normal unrestricted driver's license, you must be at least 18 years old. [D. Driver's License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1051,"qid":25575,"description":"To apply for a normal unrestricted driver's license in Maryland, you must be at least ___ years old.","explanation":"To apply for a normal unrestricted driver's license, you must be at least 18 years old. [D. Driver's License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":584,"qid":25577,"description":"If an adult who co-signed a minor's driver's license application withdraws his or her signature,:","explanation":"When an adult co-signature signer's is revoked, a minor's license is revoked until he or she reaches 18 or finds another willing adult co-signer, whichever comes first. [F. Cancellation of Minor's License on Request of Co-Signer, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":32242,"isBookmarked":0,"aid":"26137#32242#32243#32244","text":"none of the above are correct#the license will be canceled until the minor turns 18#the minor will have to pay a fine#the minor may immediately reapply without the adult's consent"},
{"testId":1051,"qid":25577,"description":"If an adult who co-signed a minor's driver's license application withdraws his or her signature,:","explanation":"When an adult co-signature signer's is revoked, a minor's license is revoked until he or she reaches 18 or finds another willing adult co-signer, whichever comes first. [F. Cancellation of Minor's License on Request of Co-Signer, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":32242,"isBookmarked":0,"aid":"26137#32242#32243#32244","text":"none of the above are correct#the license will be canceled until the minor turns 18#the minor will have to pay a fine#the minor may immediately reapply without the adult's consent"},
{"testId":584,"qid":25578,"description":"Before applying for a driver's permit or license, a minor under the age of ___ must have the permission of a parent or legal guardian.","explanation":"An aspiring driver under the age of 18 must seek the approval of a parent or legal guardian before he or she may apply for a driver's permit or licence. [E. Co-Signer of Minor's Application for a License, Section 2: Licensing Requirements/Process, Maryland Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1051,"qid":25578,"description":"Before applying for a driver's permit or license, a minor under the age of ___ must have the permission of a parent or legal guardian.","explanation":"An aspiring driver under the age of 18 must seek the approval of a parent or legal guardian before he or she may apply for a driver's permit or licence. [E. Co-Signer of Minor's Application for a License, Section 2: Licensing Requirements/Process, Maryland Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":584,"qid":25579,"description":"What does the abbreviation CDS stand for?","explanation":"The United States Drug Enforcement Administration (DEA) and the United States Food and Drug Administration (FDA) keep a list of restricted hazardous chemicals (CDSs). These are substances that are restricted in their manufacturing, importation, possession, usage, and distribution. Schedule I drugs have a significant potential for misuse and no recognized medicinal use in the United States. As an example, consider heroin. [Controlled Dangerous Substances (CDS)- Illegal and Prescription Drugs, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":13370,"isBookmarked":0,"aid":"897#13370#13371#13372","text":"None of the above#Controlled Dangerous Substance#Controlled Drugs and Substances#Complex Drugs Substances"},
{"testId":1051,"qid":25579,"description":"What does the abbreviation CDS stand for?","explanation":"The United States Drug Enforcement Administration (DEA) and the United States Food and Drug Administration (FDA) keep a list of restricted hazardous chemicals (CDSs). These are substances that are restricted in their manufacturing, importation, possession, usage, and distribution. Schedule I drugs have a significant potential for misuse and no recognized medicinal use in the United States. As an example, consider heroin. [Controlled Dangerous Substances (CDS)- Illegal and Prescription Drugs, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":13370,"isBookmarked":0,"aid":"897#13370#13371#13372","text":"None of the above#Controlled Dangerous Substance#Controlled Drugs and Substances#Complex Drugs Substances"},
{"testId":584,"qid":25580,"description":"For how much of a period of time will a driver under 21 years of age be required to install an ignition interlock device (IID) if he or she is convicted of DUI?","explanation":null,"imageName":"","correctAnswer":13064,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1051,"qid":25580,"description":"For how much of a period of time will a driver under 21 years of age be required to install an ignition interlock device (IID) if he or she is convicted of DUI?","explanation":null,"imageName":"","correctAnswer":13064,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":584,"qid":25581,"description":"Which of the following are acceptable substitutes for drinking and driving?","explanation":"Taxis, public transit, and designated drivers are easy alternatives to drinking and driving that may save your and others' lives. [Alcohol, Drugs, and Driving, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32245#32246#32247","text":"All of the above#Taking a taxi#Taking public transportation#Riding with a designated driver"},
{"testId":1051,"qid":25581,"description":"Which of the following are acceptable substitutes for drinking and driving?","explanation":"Taxis, public transit, and designated drivers are easy alternatives to drinking and driving that may save your and others' lives. [Alcohol, Drugs, and Driving, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32245#32246#32247","text":"All of the above#Taking a taxi#Taking public transportation#Riding with a designated driver"},
{"testId":584,"qid":25582,"description":"What information should you exchange with other drivers if you are involved in a car accident that does not result in injury?","explanation":"If you are involved in a minor car accident, you should share the following information with the other drivers: insurance; the driver's name, address, phone number, and license; and the make, model, license plate, and registration of the vehicle. Record the weather and road conditions. Except for your insurance and the police, do not acknowledge blame or discuss the circumstances of the accident with anybody. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"202006141611292838.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#216#32248#32249","text":"All of the above#Driver's license#Insurance#Personal"},
{"testId":584,"qid":25583,"description":"If you are engaged in a car accident, you must call the police.","explanation":"If you are involved in a collision and someone is wounded, a motorist does not have a valid license, a driver looks to be inebriated, a driver tries to flee the scene, or public property is destroyed, you must notify the police. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"202006141611292838.jpg","correctAnswer":24846,"isBookmarked":0,"aid":"24846#32250#32251#32252","text":"any of the above occurs#someone is injured#a driver does not have a valid license#a driver leaves or tries to leave the scene"},
{"testId":584,"qid":25584,"description":"What must you do if your vehicle collides with a mailbox?","explanation":"If your car collides with unattended property (such as a mailbox), you must leave a thorough notice containing your contact information, driver's license number, and insurance information, according to Maryland law. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32254,"isBookmarked":0,"aid":"6392#22727#32253#32254","text":"Call the police#Do none of the above#Replace it#Leave a detailed note"},
{"testId":1051,"qid":25584,"description":"What must you do if your vehicle collides with a mailbox?","explanation":"If your car collides with unattended property (such as a mailbox), you must leave a thorough notice containing your contact information, driver's license number, and insurance information, according to Maryland law. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32254,"isBookmarked":0,"aid":"6392#22727#32253#32254","text":"Call the police#Do none of the above#Replace it#Leave a detailed note"},
{"testId":584,"qid":25585,"description":"If you refuse to obey a police officer's commands during a traffic stop,:","explanation":"If you refuse to obey the directives of a police officer during a traffic stop, you may be arrested. [B. Traffic Stops, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32257,"isBookmarked":0,"aid":"24989#32255#32256#32257","text":"none of the above will occur#the officer will ask you to cool off#the officer will let you go#the officer may arrest you"},
{"testId":1051,"qid":25585,"description":"If you refuse to obey a police officer's commands during a traffic stop,:","explanation":"If you refuse to obey the directives of a police officer during a traffic stop, you may be arrested. [B. Traffic Stops, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":32257,"isBookmarked":0,"aid":"24989#32255#32256#32257","text":"none of the above will occur#the officer will ask you to cool off#the officer will let you go#the officer may arrest you"},
{"testId":584,"qid":25586,"description":"In Maryland, who is required to wear a seat belt or appropriate child restraint while the driver is under the age of 18 and has a provisional license?","explanation":"When the driver is under the age of 18 and has a provisional license in Maryland, every person of the vehicle must wear a seat belt or other child restraint. [A. Restrictions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":16007,"isBookmarked":0,"aid":"4420#16007#32258#32259","text":"Only the driver#The driver and every passenger#Only the driver and the front-seat passenger next to the door#Only the backseat passengers"},
{"testId":1051,"qid":25586,"description":"In Maryland, who is required to wear a seat belt or appropriate child restraint while the driver is under the age of 18 and has a provisional license?","explanation":"When the driver is under the age of 18 and has a provisional license in Maryland, every person of the vehicle must wear a seat belt or other child restraint. [A. Restrictions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":16007,"isBookmarked":0,"aid":"4420#16007#32258#32259","text":"Only the driver#The driver and every passenger#Only the driver and the front-seat passenger next to the door#Only the backseat passengers"},
{"testId":584,"qid":25588,"description":"A driver with a provisional license in Maryland may not drive unattended between _________, unless for employment, school, sports, or volunteer service.","explanation":"A motorist with a temporary license in Maryland may not drive unattended between 12:00 a.m. and 6:00 a.m. unless while working, attending school, participating in sports, or volunteering. [A. Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":11935,"isBookmarked":0,"aid":"11935#12456#12457#32260","text":"midnight and 5:00 a.m#10:00 p.m. and 4:00 a.m#midnight and 6:00 a.m#11:00 p.m. and 7:00 a.m"},
{"testId":1051,"qid":25588,"description":"A driver with a provisional license in Maryland may not drive unattended between _________, unless for employment, school, sports, or volunteer service.","explanation":"A motorist with a temporary license in Maryland may not drive unattended between 12:00 a.m. and 6:00 a.m. unless while working, attending school, participating in sports, or volunteering. [A. Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":11935,"isBookmarked":0,"aid":"11935#12456#12457#32260","text":"midnight and 5:00 a.m#10:00 p.m. and 4:00 a.m#midnight and 6:00 a.m#11:00 p.m. and 7:00 a.m"},
{"testId":584,"qid":25589,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":null,"imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":1051,"qid":25589,"description":"Which of the following non-driving convictions may result in the loss of your driving privileges?","explanation":null,"imageName":"","correctAnswer":27198,"isBookmarked":0,"aid":"897#27198#30983#30984","text":"None of the above#Failure to pay child support#Failure to attend college#Bankruptcy"},
{"testId":584,"qid":25590,"description":"Which of the following are reasons for a driver's license suspension?","explanation":"A Maryland driver's license will be revoked if it is discovered that the driver was not qualified for it or is no longer eligible. [C. Obtaining a False or Forged Identification Card, Driver's License, or Learner's Instructional Permit, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#32261#32262#32263","text":"All of the above are correct#The driver is a minor and the co-signer has withdrawn his or her signature#The driver provided false information when applying for the license#The driver holds a false or fraudulent license"},
{"testId":1051,"qid":25590,"description":"Which of the following are reasons for a driver's license suspension?","explanation":"A Maryland driver's license will be revoked if it is discovered that the driver was not qualified for it or is no longer eligible. [C. Obtaining a False or Forged Identification Card, Driver's License, or Learner's Instructional Permit, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":6172,"isBookmarked":0,"aid":"6172#32261#32262#32263","text":"All of the above are correct#The driver is a minor and the co-signer has withdrawn his or her signature#The driver provided false information when applying for the license#The driver holds a false or fraudulent license"},
{"testId":584,"qid":25591,"description":"Which of the following medical conditions does NOT need to be reported to the MVA Driver Wellness & Safety Division?","explanation":"Any medical condition that might make it more difficult for a motorist to drive safely must be disclosed to the MVA Driver Wellness & Safety Division. [A. Maryland Driver's Manual, Reportable Medical Conditions, Section 10: Other Important Information]","imageName":"","correctAnswer":32265,"isBookmarked":0,"aid":"32216#32264#32265#32266","text":"Epilepsy#Schizophrenia#Influenza#Alcohol dependence"},
{"testId":1051,"qid":25591,"description":"Which of the following medical conditions does NOT need to be reported to the MVA Driver Wellness & Safety Division?","explanation":"Any medical condition that might make it more difficult for a motorist to drive safely must be disclosed to the MVA Driver Wellness & Safety Division. [A. Maryland Driver's Manual, Reportable Medical Conditions, Section 10: Other Important Information]","imageName":"","correctAnswer":32265,"isBookmarked":0,"aid":"32216#32264#32265#32266","text":"Epilepsy#Schizophrenia#Influenza#Alcohol dependence"},
{"testId":584,"qid":25592,"description":"In the state of Maryland, what kinds of auto insurance coverage are needed?","explanation":"All automobiles in Maryland must be insured by an auto insurance policy that covers both property damage and personal injury coverage. [E. Insurance Requirements, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37204#37205#37206#37207","text":"(a) Property damage#(b) Personal injury#Both (a) and (b)#Neither (a) nor (b)"},
{"testId":1051,"qid":25592,"description":"In the state of Maryland, what kinds of auto insurance coverage are needed?","explanation":"All automobiles in Maryland must be insured by an auto insurance policy that covers both property damage and personal injury coverage. [E. Insurance Requirements, Maryland Driver's Manual, Section 10: Other Important Information]","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37204#37205#37206#37207","text":"(a) Property damage#(b) Personal injury#Both (a) and (b)#Neither (a) nor (b)"},
{"testId":584,"qid":25593,"description":"If you are hauled over for excessive swerving and your blood alcohol concentration (BAC) is less than the legal limit of 0.08 percent, you will be fined.","explanation":"Even though your BAC is less than the legal limit of 0.08 percent, an officer may charge you with driving under the influence (DUI) if the officer judges that alcohol is impairing your ability to drive a vehicle safely. [A. Alcohol, Drugs, and Driving, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32271,"isBookmarked":0,"aid":"32271#32272#32273#32274","text":"you can be charged with driving under the influence (DUI)#you may not be charged with DUI#your mandatory suspension period will be reduced#you may have to pay an additional fine"},
{"testId":1051,"qid":25593,"description":"If you are hauled over for excessive swerving and your blood alcohol concentration (BAC) is less than the legal limit of 0.08 percent, you will be fined.","explanation":"Even though your BAC is less than the legal limit of 0.08 percent, an officer may charge you with driving under the influence (DUI) if the officer judges that alcohol is impairing your ability to drive a vehicle safely. [A. Alcohol, Drugs, and Driving, Maryland Driver's Manual, Section 6: Dangerous Driving Behaviors]","imageName":"","correctAnswer":32271,"isBookmarked":0,"aid":"32271#32272#32273#32274","text":"you can be charged with driving under the influence (DUI)#you may not be charged with DUI#your mandatory suspension period will be reduced#you may have to pay an additional fine"},
{"testId":584,"qid":25594,"description":"Which of the following could be harmed by drugs?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32275#32276#32277","text":"All of the above#Alertness#Concentration#Coordination"},
{"testId":1051,"qid":25594,"description":"Which of the following could be harmed by drugs?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32275#32276#32277","text":"All of the above#Alertness#Concentration#Coordination"},
{"testId":584,"qid":25595,"description":"If you take a prescription medication that lists side effects such as drowsiness or dizziness,:","explanation":null,"imageName":"","correctAnswer":32278,"isBookmarked":0,"aid":"32278#32279#32280#32281","text":"you should not drive#you should only drive supervised#you should be able to drive safely#inform the police before you drive"},
{"testId":1051,"qid":25595,"description":"If you take a prescription medication that lists side effects such as drowsiness or dizziness,:","explanation":null,"imageName":"","correctAnswer":32278,"isBookmarked":0,"aid":"32278#32279#32280#32281","text":"you should not drive#you should only drive supervised#you should be able to drive safely#inform the police before you drive"},
{"testId":586,"qid":25596,"description":"How long will a fatal accident conviction stay on your record?","explanation":"If you are convicted of causing a fatal accident, the conviction will be permanently recorded on your driving record. [Your Driving Record, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":29269,"isBookmarked":0,"aid":"11341#11760#13410#29269","text":"5 years#10 years#20 years#Permanently"},
{"testId":1053,"qid":25596,"description":"How long will a fatal accident conviction stay on your record?","explanation":"If you are convicted of causing a fatal accident, the conviction will be permanently recorded on your driving record. [Your Driving Record, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":29269,"isBookmarked":0,"aid":"11341#11760#13410#29269","text":"5 years#10 years#20 years#Permanently"},
{"testId":586,"qid":25597,"description":"During a traffic stop, which of the following documents must be shown to a police officer?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#216#217#218","text":"All of the above#Driver's license#Proof of insurance#Vehicle registration"},
{"testId":586,"qid":25598,"description":"Moving violation points will remain on your driving record for:","explanation":"Moving violation points will stay on your driving record for two years. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1053,"qid":25598,"description":"Moving violation points will remain on your driving record for:","explanation":"Moving violation points will stay on your driving record for two years. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":586,"qid":25599,"description":"What date is used to determine how long points for a moving violation will stay on your driving record?","explanation":"Points for a moving offense will be recorded on your driving record for two years from the date of conviction. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":27215,"isBookmarked":0,"aid":"897#27213#27215#32608","text":"None of the above#The date of violation#The date of conviction#The expiration date of the driver's license"},
{"testId":1053,"qid":25599,"description":"What date is used to determine how long points for a moving violation will stay on your driving record?","explanation":"Points for a moving offense will be recorded on your driving record for two years from the date of conviction. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":27215,"isBookmarked":0,"aid":"897#27213#27215#32608","text":"None of the above#The date of violation#The date of conviction#The expiration date of the driver's license"},
{"testId":586,"qid":25600,"description":"A conviction for manslaughter while operating a motor vehicle will result in _____ points being added to your driving record.","explanation":"Vehicles coming from either direction may make a left turn in the central lane.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":1053,"qid":25600,"description":"A conviction for manslaughter while operating a motor vehicle will result in _____ points being added to your driving record.","explanation":"Vehicles coming from either direction may make a left turn in the central lane.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":586,"qid":25601,"description":"Leaving the scene of an accident without stopping will result in the adding of ___ points to your driving record.","explanation":"If you leave the scene of an accident without stopping, you will get six points on your license. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":1053,"qid":25601,"description":"Leaving the scene of an accident without stopping will result in the adding of ___ points to your driving record.","explanation":"If you leave the scene of an accident without stopping, you will get six points on your license. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":586,"qid":25602,"description":"Which of the following violations will add six points to your driving record?","explanation":"Reckless driving, refusing to submit to chemical testing, and escaping from police are just a few of the offences that can add six points to your driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#30373#33098","text":"All of the above#Reckless driving#Refusal to submit to chemical testing#Fleeing from the police"},
{"testId":1053,"qid":25602,"description":"Which of the following violations will add six points to your driving record?","explanation":"Reckless driving, refusing to submit to chemical testing, and escaping from police are just a few of the offences that can add six points to your driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#30373#33098","text":"All of the above#Reckless driving#Refusal to submit to chemical testing#Fleeing from the police"},
{"testId":586,"qid":25603,"description":"If a driver is convicted of drag racing, he or she will receive ___ points on their driving record.","explanation":"Drag racing convictions result in four points being added to a driver's record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25603,"description":"If a driver is convicted of drag racing, he or she will receive ___ points on their driving record.","explanation":"Drag racing convictions result in four points being added to a driver's record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25604,"description":"If a driver under the age of 21 is convicted of driving while under the influence of alcohol, ___ points will be added to his or her driving record.","explanation":"If a motorist under the age of 21 is convicted of driving while under the influence of alcohol, four points will be added to his or her driving record. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1053,"qid":25604,"description":"If a driver under the age of 21 is convicted of driving while under the influence of alcohol, ___ points will be added to his or her driving record.","explanation":"If a motorist under the age of 21 is convicted of driving while under the influence of alcohol, four points will be added to his or her driving record. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":586,"qid":25605,"description":"If convicted of exceeding the posted speed limit by ___ mph, you'll get four points.","explanation":"If you are guilty of exceeding the legal speed limit by 16 mph or more, four points will be added to your driving record. [Michigan's Point System, Chapter 2 Your Driving Record, What Every Driver Should Understand]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"11343#11360#11361#13412","text":"11#21#16#26"},
{"testId":1053,"qid":25605,"description":"If convicted of exceeding the posted speed limit by ___ mph, you'll get four points.","explanation":"If you are guilty of exceeding the legal speed limit by 16 mph or more, four points will be added to your driving record. [Michigan's Point System, Chapter 2 Your Driving Record, What Every Driver Should Understand]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"11343#11360#11361#13412","text":"11#21#16#26"},
{"testId":586,"qid":25606,"description":"Which of the following will add four points to a driver's record in Michigan?","explanation":"A driver's driving record will be blemished if he or she is convicted of failing to yield to an emergency vehicle. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":23156,"isBookmarked":0,"aid":"897#23156#31014#33099","text":"None of the above#Failing to yield to an emergency vehicle#Careless driving#Eluding a police officer"},
{"testId":1053,"qid":25606,"description":"Which of the following will add four points to a driver's record in Michigan?","explanation":"A driver's driving record will be blemished if he or she is convicted of failing to yield to an emergency vehicle. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":23156,"isBookmarked":0,"aid":"897#23156#31014#33099","text":"None of the above#Failing to yield to an emergency vehicle#Careless driving#Eluding a police officer"},
{"testId":586,"qid":25607,"description":"If a driver is guilty of running a stop sign, he or she will receive ___ points on their driving record.","explanation":"Running a stop sign can result in three points being added to your Michigan driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25607,"description":"If a driver is guilty of running a stop sign, he or she will receive ___ points on their driving record.","explanation":"Running a stop sign can result in three points being added to your Michigan driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25608,"description":"If driver exceed the speed limit by _______ mph, he or she will receive three points on your driving record.","explanation":null,"imageName":"","correctAnswer":12944,"isBookmarked":0,"aid":"12944#21774#33100#33101","text":"11 to 15#10 to 15#5 to 11#11 to 19"},
{"testId":1053,"qid":25608,"description":"If driver exceed the speed limit by _______ mph, he or she will receive three points on your driving record.","explanation":null,"imageName":"","correctAnswer":12944,"isBookmarked":0,"aid":"12944#21774#33100#33101","text":"11 to 15#10 to 15#5 to 11#11 to 19"},
{"testId":586,"qid":25609,"description":"Failure to stop at a railroad crossing will result in ___ points being added to your driving record.","explanation":"If you are convicted of failing to stop at a railroad crossing, you will get three points on your driving record. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25609,"description":"Failure to stop at a railroad crossing will result in ___ points being added to your driving record.","explanation":"If you are convicted of failing to stop at a railroad crossing, you will get three points on your driving record. [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25610,"description":"If you fail to stop for a school bus, you will receive ___ points on your driving record.","explanation":"If you are convicted of failing to stop for a school bus, you will get three points on your driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25610,"description":"If you fail to stop for a school bus, you will receive ___ points on your driving record.","explanation":"If you are convicted of failing to stop for a school bus, you will get three points on your driving record. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25611,"description":"How many points聽will be added to your driving record if you're convicted of driving 5 mph over the posted speed聽limit?","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25611,"description":"How many points聽will be added to your driving record if you're convicted of driving 5 mph over the posted speed聽limit?","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25614,"description":"Which of the following punishments will a teen driver face if he or she is caught using a cell phone while driving?","explanation":"Except in emergencies, a minor driver who uses a mobile phone while driving faces a fine of up to $295. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":26643,"isBookmarked":0,"aid":"203#26643#26994#35315","text":"All of the above#A fine#Loss of driving privileges#A jail sentence"},
{"testId":1053,"qid":25614,"description":"Which of the following punishments will a teen driver face if he or she is caught using a cell phone while driving?","explanation":"Except in emergencies, a minor driver who uses a mobile phone while driving faces a fine of up to $295. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":26643,"isBookmarked":0,"aid":"203#26643#26994#35315","text":"All of the above#A fine#Loss of driving privileges#A jail sentence"},
{"testId":586,"qid":25615,"description":"Level 1 and Level 2 young drivers are barred from _______ on a cell phone while driving.","explanation":"Except in circumstances, young drivers with a Level 1 or Level 2 Graduated Driver License are barred from initiating, replying, or listening to or participating in verbal contact through a mobile phone under Kelsey's Law. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45275,"isBookmarked":0,"aid":"45272#45273#45274#45275","text":"placing a call#listening to a phone call#answering a call#doing any of the above"},
{"testId":1053,"qid":25615,"description":"Level 1 and Level 2 young drivers are barred from _______ on a cell phone while driving.","explanation":"Except in circumstances, young drivers with a Level 1 or Level 2 Graduated Driver License are barred from initiating, replying, or listening to or participating in verbal contact through a mobile phone under Kelsey's Law. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45275,"isBookmarked":0,"aid":"45272#45273#45274#45275","text":"placing a call#listening to a phone call#answering a call#doing any of the above"},
{"testId":586,"qid":25616,"description":"Is it legal in Michigan to leave a five-year-old child in a vehicle?","explanation":"It is prohibited in Michigan to leave a kid under the age of six years old alone in a vehicle if there is an undue danger of damage or injury to the child. In such instance, the small kid must be watched by someone over the age of 13. [Chapter 4: Traffic Laws, What Every Driver Must Know] [Children Left Unattended in Vehicles","imageName":"","correctAnswer":45281,"isBookmarked":0,"aid":"18118#21234#45280#45281","text":"No#Yes#Yes, but only if the child is supervised by someone who is at least 16 years old#Yes, but only if the child is supervised by someone who is at least 13 years old"},
{"testId":1053,"qid":25616,"description":"Is it legal in Michigan to leave a five-year-old child in a vehicle?","explanation":"It is prohibited in Michigan to leave a kid under the age of six years old alone in a vehicle if there is an undue danger of damage or injury to the child. In such instance, the small kid must be watched by someone over the age of 13. [Chapter 4: Traffic Laws, What Every Driver Must Know] [Children Left Unattended in Vehicles","imageName":"","correctAnswer":45281,"isBookmarked":0,"aid":"18118#21234#45280#45281","text":"No#Yes#Yes, but only if the child is supervised by someone who is at least 16 years old#Yes, but only if the child is supervised by someone who is at least 13 years old"},
{"testId":586,"qid":25617,"description":"If you are caught texting and driving in Michigan, you will be fined ____ for a second violation.","explanation":"Except for reporting accidents, crimes, or other emergencies, it is unlawful in Michigan to read, write, or send a text message while driving. The first infraction is punishable by a $100 punishment, while subsequent crimes are punishable by a $200 fine. [Distracted Driving, Chapter 4: What Every Driver Should Know]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"1799#11832#11833#12358","text":"$1,000#$100#$300#$200"},
{"testId":1053,"qid":25617,"description":"If you are caught texting and driving in Michigan, you will be fined ____ for a second violation.","explanation":"Except for reporting accidents, crimes, or other emergencies, it is unlawful in Michigan to read, write, or send a text message while driving. The first infraction is punishable by a $100 punishment, while subsequent crimes are punishable by a $200 fine. [Distracted Driving, Chapter 4: What Every Driver Should Know]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"1799#11832#11833#12358","text":"$1,000#$100#$300#$200"},
{"testId":586,"qid":25618,"description":"Children must be appropriately restrained in a car seat or booster seat ______________.","explanation":"Children must be securely strapped in a car seat or booster seat until they reach the age of eight or reach the height of four feet and nine inches. Children must ride in a safety seat until they reach the required age or height, whichever comes first. [Protecting Children and Adolescents, Chapter 4: Traffic Laws and What Every Driver Should Know]","imageName":"","correctAnswer":45288,"isBookmarked":0,"aid":"45288#45289#45290#45291","text":"until they are 8 years old or younger#only if they are 4 years old or younger#if they are 16 years old or younger#if they are taller than 4 feet 9 inches"},
{"testId":1053,"qid":25618,"description":"Children must be appropriately restrained in a car seat or booster seat ______________.","explanation":"Children must be securely strapped in a car seat or booster seat until they reach the age of eight or reach the height of four feet and nine inches. Children must ride in a safety seat until they reach the required age or height, whichever comes first. [Protecting Children and Adolescents, Chapter 4: Traffic Laws and What Every Driver Should Know]","imageName":"","correctAnswer":45288,"isBookmarked":0,"aid":"45288#45289#45290#45291","text":"until they are 8 years old or younger#only if they are 4 years old or younger#if they are 16 years old or younger#if they are taller than 4 feet 9 inches"},
{"testId":586,"qid":25619,"description":"Kelsey's Law does NOT apply to young drivers who :","explanation":"Teen drivers are not permitted to use mobile phones while driving under Kelsey's Law, except in emergencies. They may, however, utilize a voice-activated technology built inside the car. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45279,"isBookmarked":0,"aid":"45276#45277#45278#45279","text":"are driving with a supervising driver#do not know about Kelsey's law#use a hand-held cell phone to call their parents or guardians#use a hands-free integrated voice system"},
{"testId":1053,"qid":25619,"description":"Kelsey's Law does NOT apply to young drivers who :","explanation":"Teen drivers are not permitted to use mobile phones while driving under Kelsey's Law, except in emergencies. They may, however, utilize a voice-activated technology built inside the car. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45279,"isBookmarked":0,"aid":"45276#45277#45278#45279","text":"are driving with a supervising driver#do not know about Kelsey's law#use a hand-held cell phone to call their parents or guardians#use a hands-free integrated voice system"},
{"testId":586,"qid":25620,"description":"If you are caught texting and driving in Michigan, you will be fined ____ for the first violation.","explanation":"Except for reporting accidents, crimes, or other emergencies, it is unlawful in Michigan for drivers of any age to read, write, or send a text message while driving. The first infraction is punishable by a $100 punishment, while subsequent crimes are punishable by a $200 fine. [Distracted Driving, Chapter 4: What Every Driver Should Know]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#1799#11832#12358","text":"$500#$1,000#$100#$200"},
{"testId":1053,"qid":25620,"description":"If you are caught texting and driving in Michigan, you will be fined ____ for the first violation.","explanation":"Except for reporting accidents, crimes, or other emergencies, it is unlawful in Michigan for drivers of any age to read, write, or send a text message while driving. The first infraction is punishable by a $100 punishment, while subsequent crimes are punishable by a $200 fine. [Distracted Driving, Chapter 4: What Every Driver Should Know]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#1799#11832#12358","text":"$500#$1,000#$100#$200"},
{"testId":586,"qid":25621,"description":"If a teen driver with a Level 1 or Level 2 GDL is caught for using a cell phone while driving, the fine is up to _____ .","explanation":"For this infraction, a minor driver with a Level 1 or Level 2 Graduated Driver License faces a fine of up to $295. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45271,"isBookmarked":0,"aid":"13795#45269#45270#45271","text":"$95#$595#$395#$295"},
{"testId":1053,"qid":25621,"description":"If a teen driver with a Level 1 or Level 2 GDL is caught for using a cell phone while driving, the fine is up to _____ .","explanation":"For this infraction, a minor driver with a Level 1 or Level 2 Graduated Driver License faces a fine of up to $295. [Kelsey's Law, Chapter 4: What Every Driver Must Know About Traffic Laws]","imageName":"","correctAnswer":45271,"isBookmarked":0,"aid":"13795#45269#45270#45271","text":"$95#$595#$395#$295"},
{"testId":586,"qid":25622,"description":"Kelsey's Law says that _____ are not allowed to use a cell phone while driving.聽:","explanation":null,"imageName":"","correctAnswer":45266,"isBookmarked":0,"aid":"6056#45266#45267#45268","text":"all drivers#teen drivers with a Level 1 or Level 2 GDL#drivers under 25 years of age#senior drivers"},
{"testId":1053,"qid":25622,"description":"Kelsey's Law says that _____ are not allowed to use a cell phone while driving.聽:","explanation":null,"imageName":"","correctAnswer":45266,"isBookmarked":0,"aid":"6056#45266#45267#45268","text":"all drivers#teen drivers with a Level 1 or Level 2 GDL#drivers under 25 years of age#senior drivers"},
{"testId":586,"qid":25623,"description":"A \"Where Workers Present 45\" sign means that you should:","explanation":null,"imageName":"","correctAnswer":45283,"isBookmarked":0,"aid":"10656#24537#45282#45283","text":"Do all of the above#move to the right lane#reduce your speed immediately#watch for construction crew and slow down to 45 mph or lower where workers are present"},
{"testId":1053,"qid":25623,"description":"A \"Where Workers Present 45\" sign means that you should:","explanation":null,"imageName":"","correctAnswer":45283,"isBookmarked":0,"aid":"10656#24537#45282#45283","text":"Do all of the above#move to the right lane#reduce your speed immediately#watch for construction crew and slow down to 45 mph or lower where workers are present"},
{"testId":586,"qid":25624,"description":"Which one of the following is correct?","explanation":null,"imageName":"","correctAnswer":45287,"isBookmarked":0,"aid":"45284#45285#45286#45287","text":"Michigan law does not require driver鈥檚 license suspensions for drug convictions if you were not driving at the time of the offense#If someone you know has been drinking or using drugs, ride with them to make sure they get home safely#Coffee, a cold shower, exercise or fresh air can sober you up#Consuming even one drink can impair your ability to drive, slow your reaction time, dull your concentration and cause visual problems"},
{"testId":1053,"qid":25624,"description":"Which one of the following is correct?","explanation":null,"imageName":"","correctAnswer":45287,"isBookmarked":0,"aid":"45284#45285#45286#45287","text":"Michigan law does not require driver鈥檚 license suspensions for drug convictions if you were not driving at the time of the offense#If someone you know has been drinking or using drugs, ride with them to make sure they get home safely#Coffee, a cold shower, exercise or fresh air can sober you up#Consuming even one drink can impair your ability to drive, slow your reaction time, dull your concentration and cause visual problems"},
{"testId":586,"qid":25625,"description":"If your license is revoked, you must wait one to _____ years before reapplying for a driver's license.","explanation":null,"imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":1053,"qid":25625,"description":"If your license is revoked, you must wait one to _____ years before reapplying for a driver's license.","explanation":null,"imageName":"","correctAnswer":5002,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":586,"qid":25626,"description":"Which of the following may result in the termination of driving privileges in Michigan?","explanation":"Theft of gasoline, refusal to submit to a chemical test, and reckless driving are among the Michigan offenses that will result in the loss of driving privileges. [Michigan Department of State, Required SOS Licensing Action Authority, http://www.michigan.gov/documents/REQUIRED SOS LICENSING ACTION AUTHORITY 20405 7 .pdf]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#33107#33108","text":"All of the above#Reckless driving#Refusing to submit to chemical testing#Stealing gasoline"},
{"testId":1053,"qid":25626,"description":"Which of the following may result in the termination of driving privileges in Michigan?","explanation":"Theft of gasoline, refusal to submit to a chemical test, and reckless driving are among the Michigan offenses that will result in the loss of driving privileges. [Michigan Department of State, Required SOS Licensing Action Authority, http://www.michigan.gov/documents/REQUIRED SOS LICENSING ACTION AUTHORITY 20405 7 .pdf]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#33107#33108","text":"All of the above#Reckless driving#Refusing to submit to chemical testing#Stealing gasoline"},
{"testId":586,"qid":25627,"description":"Which of the following could result in a driver re-examination?","explanation":"Medical issues, numerous traffic accidents, violations of license limitations, or other factors may prompt the Department of State to reassess the driver. [Driver Reexaminations, 7 Things Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33109#33110#33111","text":"All of the above#Health issues#Violating driver's license restrictions#Multiple traffic accidents"},
{"testId":1053,"qid":25627,"description":"Which of the following could result in a driver re-examination?","explanation":"Medical issues, numerous traffic accidents, violations of license limitations, or other factors may prompt the Department of State to reassess the driver. [Driver Reexaminations, 7 Things Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33109#33110#33111","text":"All of the above#Health issues#Violating driver's license restrictions#Multiple traffic accidents"},
{"testId":586,"qid":25628,"description":"If you accumulate ____ or more points in a two-year period, you may be required to retake the driver's test.","explanation":"If you earn twelve or more points in two years, you may be required to do a driving reexamination. [Driver Reexaminations, 7 Things Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":1053,"qid":25628,"description":"If you accumulate ____ or more points in a two-year period, you may be required to retake the driver's test.","explanation":"If you earn twelve or more points in two years, you may be required to do a driving reexamination. [Driver Reexaminations, 7 Things Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":586,"qid":25629,"description":"In the state of Michigan, which of the following can result in a youngster losing his or her driver's license?","explanation":null,"imageName":"","correctAnswer":33113,"isBookmarked":0,"aid":"897#32014#33112#33113","text":"None of the above#Failure to participate in extracurricular activities#Poor grades in school#Failure to change his or her address with the Secretary of State"},
{"testId":1053,"qid":25629,"description":"In the state of Michigan, which of the following can result in a youngster losing his or her driver's license?","explanation":null,"imageName":"","correctAnswer":33113,"isBookmarked":0,"aid":"897#32014#33112#33113","text":"None of the above#Failure to participate in extracurricular activities#Poor grades in school#Failure to change his or her address with the Secretary of State"},
{"testId":586,"qid":25630,"description":"If you drive with a blood alcohol content (BAC) of ______ or above, you are deemed to be operating while intoxicated (OWI).","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":1053,"qid":25630,"description":"If you drive with a blood alcohol content (BAC) of ______ or above, you are deemed to be operating while intoxicated (OWI).","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":586,"qid":25631,"description":"It is unlawful to drive under the influence of _________alcohol if you are under the age of 21.","explanation":null,"imageName":"","correctAnswer":35564,"isBookmarked":0,"aid":"27699#35562#35563#35564","text":"0.01%#0.00%#0.002%#0.02% or higher"},
{"testId":1053,"qid":25631,"description":"It is unlawful to drive under the influence of _________alcohol if you are under the age of 21.","explanation":null,"imageName":"","correctAnswer":35564,"isBookmarked":0,"aid":"27699#35562#35563#35564","text":"0.01%#0.00%#0.002%#0.02% or higher"},
{"testId":586,"qid":25632,"description":"Which of the following may result in a conviction for Operating While Intoxicated (OWI)?","explanation":"The use of prescription pharmaceuticals, over-the-counter medications, illicit substances, or alcohol may impair a driver's ability to operate a vehicle safely and may result in a DUI conviction (OWI). [Illegal or Street Drugs and Medications, Substance Abuse and Driving: What Every Driver Needs to Know]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":1053,"qid":25632,"description":"Which of the following may result in a conviction for Operating While Intoxicated (OWI)?","explanation":"The use of prescription pharmaceuticals, over-the-counter medications, illicit substances, or alcohol may impair a driver's ability to operate a vehicle safely and may result in a DUI conviction (OWI). [Illegal or Street Drugs and Medications, Substance Abuse and Driving: What Every Driver Needs to Know]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":586,"qid":25633,"description":"Under Michigan law, drunk-driving and drugged-driving cases must be decided by the courts within ____ days.","explanation":null,"imageName":"","correctAnswer":13434,"isBookmarked":0,"aid":"9658#13432#13433#13434","text":"55#44#66#77"},
{"testId":1053,"qid":25633,"description":"Under Michigan law, drunk-driving and drugged-driving cases must be decided by the courts within ____ days.","explanation":null,"imageName":"","correctAnswer":13434,"isBookmarked":0,"aid":"9658#13432#13433#13434","text":"55#44#66#77"},
{"testId":586,"qid":25634,"description":"A driver who earns a driver's license for the first time in Michigan is placed on probation for:","explanation":null,"imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":1053,"qid":25634,"description":"A driver who earns a driver's license for the first time in Michigan is placed on probation for:","explanation":null,"imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":586,"qid":25635,"description":"A Temporary Instruction Permit (TIP) is given to a motorist above the age of 18 so that he or she can practice driving for _____ days.","explanation":"A Temporary Instruction Permit (TIP) is given to a motorist above the age of 18 so that he or she may practice driving for 180 days. The driver must be supervised by a licensed adult driver while driving. [Temporary Instruction Permits, Chapter 1: What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":1053,"qid":25635,"description":"A Temporary Instruction Permit (TIP) is given to a motorist above the age of 18 so that he or she can practice driving for _____ days.","explanation":"A Temporary Instruction Permit (TIP) is given to a motorist above the age of 18 so that he or she may practice driving for 180 days. The driver must be supervised by a licensed adult driver while driving. [Temporary Instruction Permits, Chapter 1: What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":586,"qid":25636,"description":"Before taking the driving skills test, an aspiring driver under the age of 18 must have at least ____ hours of supervised driving experience.","explanation":"An prospective driver who is under the age of 18 must record at least 50 hours of supervised driving experience (including 10 hours at night) before he or she may take the driving skills exam. [Driving Skills Test, Chapter 1 Michigan Driver's License Information, What Every Driver Must Know]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#7571#9656#11513","text":"20#50#70#40"},
{"testId":1053,"qid":25636,"description":"Before taking the driving skills test, an aspiring driver under the age of 18 must have at least ____ hours of supervised driving experience.","explanation":"An prospective driver who is under the age of 18 must record at least 50 hours of supervised driving experience (including 10 hours at night) before he or she may take the driving skills exam. [Driving Skills Test, Chapter 1 Michigan Driver's License Information, What Every Driver Must Know]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#7571#9656#11513","text":"20#50#70#40"},
{"testId":586,"qid":25637,"description":"The Graduated Driver Licensing (GDL) program in Michigan is designed for prospective drivers under the age of :","explanation":"The Graduated Driver Licensing (GDL) program in Michigan is designed for prospective drivers under the age of 18. [Graduated Driver Licensing (GDL), Chapter 1: What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":33116,"isBookmarked":0,"aid":"33114#33115#33116#33117","text":"25#21#18#16"},
{"testId":1053,"qid":25637,"description":"The Graduated Driver Licensing (GDL) program in Michigan is designed for prospective drivers under the age of :","explanation":"The Graduated Driver Licensing (GDL) program in Michigan is designed for prospective drivers under the age of 18. [Graduated Driver Licensing (GDL), Chapter 1: What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":33116,"isBookmarked":0,"aid":"33114#33115#33116#33117","text":"25#21#18#16"},
{"testId":586,"qid":25638,"description":"If a motorist under the age of 18 violates the terms and conditions of the Graduated Driver Licensing (GDL) program, the Secretary of State's office will notify the driver's________ in written.","explanation":null,"imageName":"","correctAnswer":33118,"isBookmarked":0,"aid":"33118#33119#33120#33121","text":"parent or guardian#school administrator#employer#driving instructor"},
{"testId":1053,"qid":25638,"description":"If a motorist under the age of 18 violates the terms and conditions of the Graduated Driver Licensing (GDL) program, the Secretary of State's office will notify the driver's________ in written.","explanation":null,"imageName":"","correctAnswer":33118,"isBookmarked":0,"aid":"33118#33119#33120#33121","text":"parent or guardian#school administrator#employer#driving instructor"},
{"testId":586,"qid":25640,"description":"If a new Michigan driver keeps a clean driving record for the remaining _______ of probation, he or she will be freed from probation.","explanation":"In the last ten months of probation, a new Michigan driver will be freed if he or she has no driving offenses, at-fault accidents, license suspensions, or license revocations. Otherwise, his or her probation will be prolonged until he or she has a clean driving record for 10 months. [Probationary Period, General Licensing Requirements, Chapter 1] What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":13440,"isBookmarked":0,"aid":"1021#11285#12686#13440","text":"1 year#2 years#5 months#10 months"},
{"testId":1053,"qid":25640,"description":"If a new Michigan driver keeps a clean driving record for the remaining _______ of probation, he or she will be freed from probation.","explanation":"In the last ten months of probation, a new Michigan driver will be freed if he or she has no driving offenses, at-fault accidents, license suspensions, or license revocations. Otherwise, his or her probation will be prolonged until he or she has a clean driving record for 10 months. [Probationary Period, General Licensing Requirements, Chapter 1] What Every Driver Should Know About Michigan Driver's License]","imageName":"","correctAnswer":13440,"isBookmarked":0,"aid":"1021#11285#12686#13440","text":"1 year#2 years#5 months#10 months"},
{"testId":586,"qid":25641,"description":"If a new Michigan driver commits an alcohol-related or other offense or is involved in an at-fault collision and earns nine or more points within two years of being released from probation, he or she will be required to:","explanation":null,"imageName":"","correctAnswer":33125,"isBookmarked":0,"aid":"10656#23228#25076#33125","text":"Do all of the above#pay a fine#forfeit his or her license#undergo a reexamination"},
{"testId":1053,"qid":25641,"description":"If a new Michigan driver commits an alcohol-related or other offense or is involved in an at-fault collision and earns nine or more points within two years of being released from probation, he or she will be required to:","explanation":null,"imageName":"","correctAnswer":33125,"isBookmarked":0,"aid":"10656#23228#25076#33125","text":"Do all of the above#pay a fine#forfeit his or her license#undergo a reexamination"},
{"testId":586,"qid":25642,"description":"If you accumulate _____ points in two years, you may need a reexam.","explanation":"If you acquire twelve or more points in two years, you may be required to retake the driver's test. It's worth noting that the most severe offences (such vehicular manslaughter and escaping a police officer) each add six points to your driving record. [Driver Reexaminations, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1053,"qid":25642,"description":"If you accumulate _____ points in two years, you may need a reexam.","explanation":"If you acquire twelve or more points in two years, you may be required to retake the driver's test. It's worth noting that the most severe offences (such vehicular manslaughter and escaping a police officer) each add six points to your driving record. [Driver Reexaminations, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":586,"qid":25643,"description":"A motorist in Michigan under the age of 21 has received his or her first driver's license. When is the license going to expire?","explanation":"A new Michigan driver who is under the age of 21 will have his or her driver's license expire on the individual's 21st birthday. [Chapter 1: Michigan Driver's License Information, What Every Driver Should Know]","imageName":"","correctAnswer":33127,"isBookmarked":0,"aid":"33126#33127#33128#33129","text":"On the driver's 18th birthday#On the driver's 21st birthday#Two years from the date the driver obtained it#Four years from the date the driver obtained it"},
{"testId":1053,"qid":25643,"description":"A motorist in Michigan under the age of 21 has received his or her first driver's license. When is the license going to expire?","explanation":"A new Michigan driver who is under the age of 21 will have his or her driver's license expire on the individual's 21st birthday. [Chapter 1: Michigan Driver's License Information, What Every Driver Should Know]","imageName":"","correctAnswer":33127,"isBookmarked":0,"aid":"33126#33127#33128#33129","text":"On the driver's 18th birthday#On the driver's 21st birthday#Two years from the date the driver obtained it#Four years from the date the driver obtained it"},
{"testId":586,"qid":25644,"description":"A Michigan driver's license that is at least 21 years old is valid for ____ years.","explanation":"A Michigan driver's license that is at least 21 years old is valid for four years. [Chapter 1: Michigan Driver's License Information, What Every Driver Should Know]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1053,"qid":25644,"description":"A Michigan driver's license that is at least 21 years old is valid for ____ years.","explanation":"A Michigan driver's license that is at least 21 years old is valid for four years. [Chapter 1: Michigan Driver's License Information, What Every Driver Should Know]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":586,"qid":25645,"description":"Which of the following could be on a driver's record in Michigan?","explanation":"A Michigan driving record contains the following information on a driver's driving history: at-fault traffic accidents, moving violation convictions, points accrued, and license actions such as suspensions and revocations. [Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33130#33131#33132","text":"All of the above#At-fault traffic accidents#Moving violations#License suspensions"},
{"testId":1053,"qid":25645,"description":"Which of the following could be on a driver's record in Michigan?","explanation":"A Michigan driving record contains the following information on a driver's driving history: at-fault traffic accidents, moving violation convictions, points accrued, and license actions such as suspensions and revocations. [Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33130#33131#33132","text":"All of the above#At-fault traffic accidents#Moving violations#License suspensions"},
{"testId":588,"qid":25646,"description":"A Mississippi motorist is found guilty of driving with a BAC of 0.08 percent or greater. What are the consequences for a first offense?","explanation":"Driving with a blood alcohol concentration (BAC) of 0.08 percent or above may result in a DUI conviction (DUI). A first DUI violation carries a license suspension of 120 days, a fine of $250 to $1,000, and up to 48 hours in prison. If the driver completes the Mississippi Alcohol Education and Safety Program, the penalties may be lessened (MASEP). In addition, instead of the license suspension, the court may order the installation of an ignition-interlock device (IID) in the driver's car for 120 days. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33826#33827#33828","text":"All of the above#A 120-day license suspension#A fine of at least $250#Up to 48 hours in jail"},
{"testId":588,"qid":25647,"description":"Which of the following offenses may result in the suspension of your Mississippi driver's license?","explanation":"The Commissioner of Public Safety has the authority to suspend your license without a preliminary hearing for any of the following offenses: You are a hazardous driver on a regular basis; you are mentally or physically incapable of driving; you have enabled a fraudulent use of your license; you have numerous convictions for major traffic infractions; or you have failed to pay child support. [License Suspension, License Protection, Mississippi Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33829#33830#33831","text":"All of the above#Allowing a fraudulent use of your license#Multiple convictions for serious traffic violations#Habitual reckless driving"},
{"testId":1055,"qid":25647,"description":"Which of the following offenses may result in the suspension of your Mississippi driver's license?","explanation":"The Commissioner of Public Safety has the authority to suspend your license without a preliminary hearing for any of the following offenses: You are a hazardous driver on a regular basis; you are mentally or physically incapable of driving; you have enabled a fraudulent use of your license; you have numerous convictions for major traffic infractions; or you have failed to pay child support. [License Suspension, License Protection, Mississippi Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33829#33830#33831","text":"All of the above#Allowing a fraudulent use of your license#Multiple convictions for serious traffic violations#Habitual reckless driving"},
{"testId":588,"qid":25648,"description":"To report _________, dial *HP on your cell phone.","explanation":"To report an emergency traffic issue to the Mississippi Highway Patrol, call *HP on your mobile phone. [Mississippi Driver's Manual, In Case of an Accident, Rules of the Road, and Safe Driving]","imageName":"","correctAnswer":33832,"isBookmarked":0,"aid":"6291#33832#33833#33834","text":"road conditions#a traffic accident#that someone is speeding#traffic congestion"},
{"testId":1055,"qid":25648,"description":"To report _________, dial *HP on your cell phone.","explanation":"To report an emergency traffic issue to the Mississippi Highway Patrol, call *HP on your mobile phone. [Mississippi Driver's Manual, In Case of an Accident, Rules of the Road, and Safe Driving]","imageName":"","correctAnswer":33832,"isBookmarked":0,"aid":"6291#33832#33833#33834","text":"road conditions#a traffic accident#that someone is speeding#traffic congestion"},
{"testId":588,"qid":25649,"description":"The minimum penalty for trash on a highway is:","explanation":"The minimum penalties in Mississippi for polluting a roadway is $50. [Mississippi Driver's Manual, Keep Mississippi Beautiful!]","imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24993#24994#31884#33835","text":"$25#$50#$40#$10"},
{"testId":1055,"qid":25649,"description":"The minimum penalty for trash on a highway is:","explanation":"The minimum penalties in Mississippi for polluting a roadway is $50. [Mississippi Driver's Manual, Keep Mississippi Beautiful!]","imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24993#24994#31884#33835","text":"$25#$50#$40#$10"},
{"testId":588,"qid":25650,"description":"The maximum penalty for littering on a highway is :","explanation":"The maximum penalties in Mississippi for polluting a roadway is $250. [Mississippi Driver's Manual, Keep Mississippi Beautiful!]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#27888#29301#33102","text":"$500#$250#$750#$300"},
{"testId":1055,"qid":25650,"description":"The maximum penalty for littering on a highway is :","explanation":"The maximum penalties in Mississippi for polluting a roadway is $250. [Mississippi Driver's Manual, Keep Mississippi Beautiful!]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#27888#29301#33102","text":"$500#$250#$750#$300"},
{"testId":588,"qid":25651,"description":"If you or your passenger may face up to _____ days in prison and a fine of up to _______ if you are caught with less than two grams of marijuana.","explanation":"If you or a passenger is discovered to have one gram or more of marijuana on your person or in your car, you may face up to 90 days in prison and a $1,000 fine. [Marijuana and the Driver, Driving While Intoxicated, and Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":33497,"isBookmarked":0,"aid":"13462#13464#33497#33836","text":"90; $500#180; $500#90; $1,000#180; $1,000"},
{"testId":1055,"qid":25651,"description":"If you or your passenger may face up to _____ days in prison and a fine of up to _______ if you are caught with less than two grams of marijuana.","explanation":"If you or a passenger is discovered to have one gram or more of marijuana on your person or in your car, you may face up to 90 days in prison and a $1,000 fine. [Marijuana and the Driver, Driving While Intoxicated, and Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":33497,"isBookmarked":0,"aid":"13462#13464#33497#33836","text":"90; $500#180; $500#90; $1,000#180; $1,000"},
{"testId":588,"qid":25652,"description":"When you drive on any Mississippi public road, you are granting implied approval to:","explanation":"Driving on public roadways in Mississippi involves permission to being chemically tested for alcohol or drug usage. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8615#29272#33481#33482","text":"All of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":1055,"qid":25652,"description":"When you drive on any Mississippi public road, you are granting implied approval to:","explanation":"Driving on public roadways in Mississippi involves permission to being chemically tested for alcohol or drug usage. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8615#29272#33481#33482","text":"All of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":588,"qid":25653,"description":"If you refuse to submit to chemical testing, your driver's license will be confiscated and a temporary license issued for ___ days.","explanation":"If you refuse to submit to chemical testing, your driver's license will be seized and you will be awarded a 45-day interim license. The Commissioner of Public Safety will assess your case during this time. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5446#6354#6477#8141","text":"10#45#30#60"},
{"testId":1055,"qid":25653,"description":"If you refuse to submit to chemical testing, your driver's license will be confiscated and a temporary license issued for ___ days.","explanation":"If you refuse to submit to chemical testing, your driver's license will be seized and you will be awarded a 45-day interim license. The Commissioner of Public Safety will assess your case during this time. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":6354,"isBookmarked":0,"aid":"5446#6354#6477#8141","text":"10#45#30#60"},
{"testId":588,"qid":25654,"description":"If you're over 21 and your BAC is ____ or more,聽you will be considered as intoxicated.","explanation":"You will be regarded legally inebriated if you are at least 21 years old and your blood alcohol content (BAC) is 0.08 percent or above. The police will request your license and provide you a 30-day interim license. (Note: Three 12-ounce cans of beer, three shots of 80-proof whiskey, or 15 ounces of wine will give an average 180-pound guy a BAC of 0.10 percent before his body begins to break down the alcohol.) [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1055,"qid":25654,"description":"If you're over 21 and your BAC is ____ or more,聽you will be considered as intoxicated.","explanation":"You will be regarded legally inebriated if you are at least 21 years old and your blood alcohol content (BAC) is 0.08 percent or above. The police will request your license and provide you a 30-day interim license. (Note: Three 12-ounce cans of beer, three shots of 80-proof whiskey, or 15 ounces of wine will give an average 180-pound guy a BAC of 0.10 percent before his body begins to break down the alcohol.) [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":588,"qid":25655,"description":"If you are under the age of 21 and have a blood alcohol concentration (BAC) of _____ or more, you are under the influence of intoxicating liquor.","explanation":"If you are under the age of 21 and have a blood alcohol content (BAC) of 0.02 percent or greater, you are under the influence of intoxicating liquor. The police will request your license and provide you a 30-day interim license. (Note: Before his body begins to break down the alcohol, an average 180-pound guy will have a BAC of 0.03 percent from the alcohol in one 12-ounce can of beer, one shot of 80-proof whiskey, or one 5-ounce glass of wine. As a result, a restriction of less than 0.02 percent is practically zero tolerance.) [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":1055,"qid":25655,"description":"If you are under the age of 21 and have a blood alcohol concentration (BAC) of _____ or more, you are under the influence of intoxicating liquor.","explanation":"If you are under the age of 21 and have a blood alcohol content (BAC) of 0.02 percent or greater, you are under the influence of intoxicating liquor. The police will request your license and provide you a 30-day interim license. (Note: Before his body begins to break down the alcohol, an average 180-pound guy will have a BAC of 0.03 percent from the alcohol in one 12-ounce can of beer, one shot of 80-proof whiskey, or one 5-ounce glass of wine. As a result, a restriction of less than 0.02 percent is practically zero tolerance.) [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#3281#3282#4251","text":"0.08%#0.04%#0.06%#0.02%"},
{"testId":588,"qid":25657,"description":"The fine for an underage Mississippi driver's first DUI conviction will be at least:","explanation":"The fine for an underage driver's first DUI conviction will be at least $250. [Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24995#27888","text":"$200#$100#$150#$250"},
{"testId":1055,"qid":25657,"description":"The fine for an underage Mississippi driver's first DUI conviction will be at least:","explanation":"The fine for an underage driver's first DUI conviction will be at least $250. [Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24995#27888","text":"$200#$100#$150#$250"},
{"testId":588,"qid":25658,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI offense happens within five years of the first violation, it is deemed a second offense. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5448#5449#6389","text":"10#20#5#3"},
{"testId":1055,"qid":25658,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI offense happens within five years of the first violation, it is deemed a second offense. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5448#5449#6389","text":"10#20#5#3"},
{"testId":588,"qid":25659,"description":"For a second DUI offense in five years by an underage Mississippi driver, his or her license will be suspended for:","explanation":"A Mississippi teenage driver's license will be suspended for one year for a second DUI conviction. However, instead of the one-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for one year. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1055,"qid":25659,"description":"For a second DUI offense in five years by an underage Mississippi driver, his or her license will be suspended for:","explanation":"A Mississippi teenage driver's license will be suspended for one year for a second DUI conviction. However, instead of the one-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for one year. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":588,"qid":25662,"description":"If a Mississippi driver under the age of 21 is convicted of a third DUI conviction in five years, he or she faces a fine of up to:","explanation":"If a Mississippi driver under the age of 21 is convicted of a third DUI conviction, he or she faces a $1,000 fine. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":1055,"qid":25662,"description":"If a Mississippi driver under the age of 21 is convicted of a third DUI conviction in five years, he or she faces a fine of up to:","explanation":"If a Mississippi driver under the age of 21 is convicted of a third DUI conviction, he or she faces a $1,000 fine. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":588,"qid":25663,"description":"What is the maximum jail sentence for a first-time DUI conviction in Mississippi?","explanation":"A Mississippi motorist guilty of DUI for the first time may face up to 48 hours in prison. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"12360#12361#12827#12828","text":"7 days#3 days#24 hours#48 hours"},
{"testId":1055,"qid":25663,"description":"What is the maximum jail sentence for a first-time DUI conviction in Mississippi?","explanation":"A Mississippi motorist guilty of DUI for the first time may face up to 48 hours in prison. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"12360#12361#12827#12828","text":"7 days#3 days#24 hours#48 hours"},
{"testId":588,"qid":25664,"description":"A driver's license may be suspended for ______ if he or she is convicted of DUI for the second time in the last five years.","explanation":"A Mississippi driver's license will be suspended for up to a year if he or she is convicted of DUI for the second time in five years. However, instead of the one-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for one year. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":1055,"qid":25664,"description":"A driver's license may be suspended for ______ if he or she is convicted of DUI for the second time in the last five years.","explanation":"A Mississippi driver's license will be suspended for up to a year if he or she is convicted of DUI for the second time in five years. However, instead of the one-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for one year. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":588,"qid":25665,"description":"What is the maximum fine for a second DUI conviction within 5 years by a Mississippi driver who is at least 21 years old?","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#23890#27888","text":"$500#$1,000#$1,500#$250"},
{"testId":1055,"qid":25665,"description":"What is the maximum fine for a second DUI conviction within 5 years by a Mississippi driver who is at least 21 years old?","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#23890#27888","text":"$500#$1,000#$1,500#$250"},
{"testId":588,"qid":25666,"description":"A Mississippi motorist over the age of 21 who is convicted of DUI for the second time in five years will be sentenced to at least ____ days in prison.","explanation":"A Mississippi motorist who is at least 21 years old and is convicted of DUI for the second time will face at least 10 days in prison. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1055,"qid":25666,"description":"A Mississippi motorist over the age of 21 who is convicted of DUI for the second time in five years will be sentenced to at least ____ days in prison.","explanation":"A Mississippi motorist who is at least 21 years old and is convicted of DUI for the second time will face at least 10 days in prison. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":588,"qid":25667,"description":"What is the maximum fine for a third DUI conviction within 5 years by a Mississippi driver who is at least 21 years old?","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25033#25036#27889","text":"$1,000#$2,000#$5,000#$2,500"},
{"testId":1055,"qid":25667,"description":"What is the maximum fine for a third DUI conviction within 5 years by a Mississippi driver who is at least 21 years old?","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25033#25036#27889","text":"$1,000#$2,000#$5,000#$2,500"},
{"testId":588,"qid":25668,"description":"The maximum jail sentence for an adult Mississippi driver guilty of DUI for the third or subsequent time within five years is ___ years.","explanation":"A Mississippi adult motorist who is found guilty of DUI for the third or subsequent offence will get a sentence of one to five years in prison. [Mississippi Driver's Manual, Driving Under the Influence and Implied Consent]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1055,"qid":25668,"description":"The maximum jail sentence for an adult Mississippi driver guilty of DUI for the third or subsequent time within five years is ___ years.","explanation":"A Mississippi adult motorist who is found guilty of DUI for the third or subsequent offence will get a sentence of one to five years in prison. [Mississippi Driver's Manual, Driving Under the Influence and Implied Consent]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":588,"qid":25669,"description":"Penalties for the committed DUI offenses/convictions may be lowered:","explanation":"Penalties for committed DUI offenses/convictions may be lowered if the Mississippi Alcohol Education Safety Program is completed (MASEP). [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":36291,"isBookmarked":0,"aid":"36289#36290#36291#36292","text":"if the driver admits s/he is guilty#if the driver pays the fine on the spot#if the driver completes Mississippi Alcohol Education Safety Program#if the driver hires a lawyer"},
{"testId":1055,"qid":25669,"description":"Penalties for the committed DUI offenses/convictions may be lowered:","explanation":"Penalties for committed DUI offenses/convictions may be lowered if the Mississippi Alcohol Education Safety Program is completed (MASEP). [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":36291,"isBookmarked":0,"aid":"36289#36290#36291#36292","text":"if the driver admits s/he is guilty#if the driver pays the fine on the spot#if the driver completes Mississippi Alcohol Education Safety Program#if the driver hires a lawyer"},
{"testId":588,"qid":25670,"description":"If convicted of driving with a suspended license because of a DUI, you'll spend at least ____ in jail.","explanation":null,"imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"739#12360#12827#12828","text":"10 days#7 days#24 hours#48 hours"},
{"testId":1055,"qid":25670,"description":"If convicted of driving with a suspended license because of a DUI, you'll spend at least ____ in jail.","explanation":null,"imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"739#12360#12827#12828","text":"10 days#7 days#24 hours#48 hours"},
{"testId":588,"qid":25671,"description":"If you are convicted of a DUI crime that results in significant bodily harm or death to another person, you might face up to ___ years in prison.","explanation":null,"imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5447#5449#6425","text":"10#15#5#25"},
{"testId":1055,"qid":25671,"description":"If you are convicted of a DUI crime that results in significant bodily harm or death to another person, you might face up to ___ years in prison.","explanation":null,"imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5447#5449#6425","text":"10#15#5#25"},
{"testId":588,"qid":25672,"description":"Your driver's license can be taken away by the Commissioner of Public Safety if:","explanation":"The Commissioner of Public Safety has the authority to suspend your license without a preliminary hearing for any of the following offenses: You have been engaged in a fatal accident; you are mentally or physically incapable of driving; you have been convicted of DUI in another US state; or you have failed to pay child support. [License Suspension; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Handbook]","imageName":"","correctAnswer":33840,"isBookmarked":0,"aid":"33837#33838#33839#33840","text":"you have been involved in a crash resulting in someone's death#you have been convicted of DUI in another US state#you have failed to pay child support#any of the above have occurred"},
{"testId":1055,"qid":25672,"description":"Your driver's license can be taken away by the Commissioner of Public Safety if:","explanation":"The Commissioner of Public Safety has the authority to suspend your license without a preliminary hearing for any of the following offenses: You have been engaged in a fatal accident; you are mentally or physically incapable of driving; you have been convicted of DUI in another US state; or you have failed to pay child support. [License Suspension; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Handbook]","imageName":"","correctAnswer":33840,"isBookmarked":0,"aid":"33837#33838#33839#33840","text":"you have been involved in a crash resulting in someone's death#you have been convicted of DUI in another US state#you have failed to pay child support#any of the above have occurred"},
{"testId":588,"qid":25673,"description":"If you're convicted of vehicular manslaughter, your license will be revoked for:","explanation":"If you are found guilty of manslaughter or negligent murder in a traffic accident, your license will be suspended for one year. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#26672#29895","text":"6 months#1 year#5 years#10 years"},
{"testId":1055,"qid":25673,"description":"If you're convicted of vehicular manslaughter, your license will be revoked for:","explanation":"If you are found guilty of manslaughter or negligent murder in a traffic accident, your license will be suspended for one year. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#26672#29895","text":"6 months#1 year#5 years#10 years"},
{"testId":588,"qid":25674,"description":"The Commissioner of Public Safety will cancel your license for one year if you are convicted of:","explanation":"Convictions for a felony while operating a motor vehicle, manslaughter or negligent homicide resulting from a traffic accident, three reckless driving charges within a 12-month period, failure to stop and render aid at an accident, and perjury involving statements to the Department of Public Safety will all result in a one-year license revocation. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33841#33842#33843","text":"Any of the above#a felony in which you used a motor vehicle#leaving the scene of an accident#perjury involving statements to the Department of Public Safety"},
{"testId":1055,"qid":25674,"description":"The Commissioner of Public Safety will cancel your license for one year if you are convicted of:","explanation":"Convictions for a felony while operating a motor vehicle, manslaughter or negligent homicide resulting from a traffic accident, three reckless driving charges within a 12-month period, failure to stop and render aid at an accident, and perjury involving statements to the Department of Public Safety will all result in a one-year license revocation. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33841#33842#33843","text":"Any of the above#a felony in which you used a motor vehicle#leaving the scene of an accident#perjury involving statements to the Department of Public Safety"},
{"testId":588,"qid":25675,"description":"If you are charged with careless driving three times in one year, your license will be suspended for ___ year (s).","explanation":"Convictions for a felony while operating a motor vehicle, manslaughter or negligent homicide resulting from a traffic accident, three reckless driving charges within a 12-month period, failure to stop and render aid at an accident, and perjury involving statements to the Department of Public Safety will all result in a one-year license revocation. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"5449#6389#6469#7559","text":"5#3#1#7"},
{"testId":1055,"qid":25675,"description":"If you are charged with careless driving three times in one year, your license will be suspended for ___ year (s).","explanation":"Convictions for a felony while operating a motor vehicle, manslaughter or negligent homicide resulting from a traffic accident, three reckless driving charges within a 12-month period, failure to stop and render aid at an accident, and perjury involving statements to the Department of Public Safety will all result in a one-year license revocation. [License Revocation; License Suspension, Revocation, and Reinstatement; Mississippi Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"5449#6389#6469#7559","text":"5#3#1#7"},
{"testId":588,"qid":25676,"description":"The license reinstatement cost is_______ for license suspension or revocation due to a conviction under the state's Implied Consent Law or Uniform Controlled Substances Act.","explanation":"If your license is suspended or revoked under the Implied Consent Law or the Uniform Controlled Substances Act, you must pay a $175 license reinstatement fee before you may drive again. [Mississippi Driver's Manual; License Suspension, Revocation, and Reinstatement]","imageName":"","correctAnswer":33844,"isBookmarked":0,"aid":"24977#24995#27888#33844","text":"$200#$150#$250#$175"},
{"testId":1055,"qid":25676,"description":"The license reinstatement cost is_______ for license suspension or revocation due to a conviction under the state's Implied Consent Law or Uniform Controlled Substances Act.","explanation":"If your license is suspended or revoked under the Implied Consent Law or the Uniform Controlled Substances Act, you must pay a $175 license reinstatement fee before you may drive again. [Mississippi Driver's Manual; License Suspension, Revocation, and Reinstatement]","imageName":"","correctAnswer":33844,"isBookmarked":0,"aid":"24977#24995#27888#33844","text":"$200#$150#$250#$175"},
{"testId":588,"qid":25677,"description":"If your license is suspended due to nonpayment of child support, you must pay_______ a license reinstatement fee.","explanation":"If your license is suspended due to nonpayment of child support, you must pay a $25 license reinstatement fee before you may drive again. [Mississippi Driver's Manual; License Suspension, Revocation, and Reinstatement]","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#27902#33835","text":"$25#$50#$75#$10"},
{"testId":1055,"qid":25677,"description":"If your license is suspended due to nonpayment of child support, you must pay_______ a license reinstatement fee.","explanation":"If your license is suspended due to nonpayment of child support, you must pay a $25 license reinstatement fee before you may drive again. [Mississippi Driver's Manual; License Suspension, Revocation, and Reinstatement]","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#27902#33835","text":"$25#$50#$75#$10"},
{"testId":588,"qid":25678,"description":"If it involves_________, you must notify the police.","explanation":"If there are deaths, major physical injuries, or property damage in excess of $500, you must report the accident to the appropriate law enforcement authorities. If you are unable to report the accident because you have been wounded, one of your passengers should do it on your behalf. [Mississippi Driver's Manual, In Case of an Accident, Rules of the Road, and Safe Driving]","imageName":"202006141611292838.jpg","correctAnswer":9855,"isBookmarked":0,"aid":"9855#25171#33845#33846","text":"Any of the above#a fatality#a serious injury#property damage exceeding $500"},
{"testId":588,"qid":25679,"description":"If you do not want to keep an auto insurance coverage on your vehicle, you can deposit _______ in stocks or a certificate of deposit with the state treasurer.","explanation":null,"imageName":"","correctAnswer":33847,"isBookmarked":0,"aid":"25036#31397#31402#33847","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":1055,"qid":25679,"description":"If you do not want to keep an auto insurance coverage on your vehicle, you can deposit _______ in stocks or a certificate of deposit with the state treasurer.","explanation":null,"imageName":"","correctAnswer":33847,"isBookmarked":0,"aid":"25036#31397#31402#33847","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":588,"qid":25680,"description":"If you are involved in an accident that causes death, injury, or at least $1,000 in property damage, you will have ____ days to demonstrate that you can pay for all of the damages through insurance or other means.","explanation":"If you are involved in an accident that causes death, injury, or at least $1,000 in property damage, you will have 60 days to demonstrate that you can pay for all of the losses via insurance or other means. Your driving privileges will be threatened otherwise. [Mississippi Driver's Manual, Proof of Insurance & Safety Responsibility]","imageName":"202006141611292838.jpg","correctAnswer":8141,"isBookmarked":0,"aid":"5446#6389#6477#8141","text":"10#3#30#60"},
{"testId":588,"qid":25681,"description":"Your property damage coverage on your car insurance must be at least _____ per accident.","explanation":"Property damage coverage on your vehicle insurance policy must be at least $25,000 per accident. [Proof of Insurance and Safety Responsibilities, Mississippi Driver's Manual; Auto Insurance, Consumers, Mississippi Insurance Department, http://www.mid.ms.gov/consumers/auto-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#3198#12047#12048","text":"$50,000#$30,000#$15,000#$25,000"},
{"testId":1055,"qid":25681,"description":"Your property damage coverage on your car insurance must be at least _____ per accident.","explanation":"Property damage coverage on your vehicle insurance policy must be at least $25,000 per accident. [Proof of Insurance and Safety Responsibilities, Mississippi Driver's Manual; Auto Insurance, Consumers, Mississippi Insurance Department, http://www.mid.ms.gov/consumers/auto-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#3198#12047#12048","text":"$50,000#$30,000#$15,000#$25,000"},
{"testId":588,"qid":25682,"description":"How many dollars does your auto insurance policy include at least per accident in bodily injury coverage?","explanation":"Your vehicle insurance policy must offer bodily injury coverage of at least $50,000 per accident. It is worth noting that spinal cord surgery for a single patient to avoid paralysis following an accident may cost more than $150,000. [Proof of Insurance and Safety Responsibilities, Mississippi Driver's Manual; Auto Insurance, Consumers, Mississippi Insurance Department, http://www.mid.ms.gov/consumers/auto-insurance .aspx]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3195#3196#11659#12047","text":"$5,000#$50,000#$20,000#$15,000"},
{"testId":1055,"qid":25682,"description":"How many dollars does your auto insurance policy include at least per accident in bodily injury coverage?","explanation":"Your vehicle insurance policy must offer bodily injury coverage of at least $50,000 per accident. It is worth noting that spinal cord surgery for a single patient to avoid paralysis following an accident may cost more than $150,000. [Proof of Insurance and Safety Responsibilities, Mississippi Driver's Manual; Auto Insurance, Consumers, Mississippi Insurance Department, http://www.mid.ms.gov/consumers/auto-insurance .aspx]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3195#3196#11659#12047","text":"$5,000#$50,000#$20,000#$15,000"},
{"testId":588,"qid":25683,"description":"How many dollars does your auto insurance policy include at least per accident in bodily injury coverage?","explanation":"Your vehicle insurance policy must offer bodily injury coverage of at least $50,000 per person. [Insurance Proof; Insurance Proof & Safety Responsibility; Mississippi Driver's Manual]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#11659#12047#12048","text":"$50,000#$20,000#$15,000#$25,000"},
{"testId":1055,"qid":25683,"description":"How many dollars does your auto insurance policy include at least per accident in bodily injury coverage?","explanation":"Your vehicle insurance policy must offer bodily injury coverage of at least $50,000 per person. [Insurance Proof; Insurance Proof & Safety Responsibility; Mississippi Driver's Manual]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#11659#12047#12048","text":"$50,000#$20,000#$15,000#$25,000"},
{"testId":588,"qid":25684,"description":"Who is required to wear a seat belt (or child restraint)?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#33848","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#No one (seat belts are optional)"},
{"testId":588,"qid":25685,"description":"If a child under the age of ___ rides in your automobile without being fastened in a child safety seat, you may be penalized.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":588,"qid":25686,"description":"If you are under the age of 17, you must have a learner's permit for at least ________ or become 17 before applying for a driver's license.","explanation":"If you are at least 17 years old, you do not need to have a learner's permit before applying for a driver's license. If you are under the age of 17, you must have a learner's permit for at least 12 months or turn 17 before applying for a driver's license. If you are still under the age of 17 at the end of the 12-month period, you will apply for a Class Y intermediate license rather than a full, unrestricted Class R license. [Regular Learner's Permit, License and Permit Types, Mississippi Driver's Manual]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"742#1272#1273#1454","text":"30 days#6 months#12 months#9 months"},
{"testId":588,"qid":25687,"description":"To receive a normal Class R driver's license, an MS driver must be at least _____ years of age.","explanation":"If a motorist earns an intermediate license at the age of 16 and keeps it for six months, he or she may receive a normal Class R driver's license at the age of 16 1/2. [How to Apply for a License, License Types & Permits, Mississippi Driver's Manual]","imageName":"","correctAnswer":32240,"isBookmarked":0,"aid":"11359#11361#32240#32241","text":"18#16#16 1/2#15 1/2"},
{"testId":1055,"qid":25687,"description":"To receive a normal Class R driver's license, an MS driver must be at least _____ years of age.","explanation":"If a motorist earns an intermediate license at the age of 16 and keeps it for six months, he or she may receive a normal Class R driver's license at the age of 16 1/2. [How to Apply for a License, License Types & Permits, Mississippi Driver's Manual]","imageName":"","correctAnswer":32240,"isBookmarked":0,"aid":"11359#11361#32240#32241","text":"18#16#16 1/2#15 1/2"},
{"testId":588,"qid":25688,"description":"Before applying for a learner's permit or driver's license in Mississippi, a driver under the age of ____ must produce documentation that he or she is married or enrolled in school.","explanation":"Before applying for a learner's permit or driver's license in Mississippi, a motorist under the age of 18 must produce documentation that he or she is married or enrolled in school. A marriage certificate or a Certification of Attendance form from the school administrator are examples of acceptable papers. [Persons Not Eligible for a License, Your Driving License, Mississippi Driver's Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1055,"qid":25688,"description":"Before applying for a learner's permit or driver's license in Mississippi, a driver under the age of ____ must produce documentation that he or she is married or enrolled in school.","explanation":"Before applying for a learner's permit or driver's license in Mississippi, a motorist under the age of 18 must produce documentation that he or she is married or enrolled in school. A marriage certificate or a Certification of Attendance form from the school administrator are examples of acceptable papers. [Persons Not Eligible for a License, Your Driving License, Mississippi Driver's Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":588,"qid":25689,"description":"If you are under the age of ______, you must get both parents or your legal guardian's signatures on your application for a learner's permit or driver's license.","explanation":"If you are under the age of 17, your application for a learner's permit or driver's license must contain the signatures of both parents, your legal guardian, your employer, or another responsible adult. [Signatures, Application Requirements, Mississippi Driver's Handbook]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11360#11374#11696","text":"18#21#17#19"},
{"testId":588,"qid":25690,"description":"If the parent or other responsible adult who signed an underage driver's license application withdraws his or her signature,:","explanation":"If the parent or other adult who signed an underage MS driver's license application withdraws his or her signature, the license will be revoked. [Signatures, Application Requirements, Mississippi Driver's Handbook]","imageName":"","correctAnswer":33849,"isBookmarked":0,"aid":"33849#33850#33851#33852","text":"the license will be canceled#the driver will have to pay a fine#the driver will have to find someone else to sign the application#the license will be suspended for one year"},
{"testId":1055,"qid":25690,"description":"If the parent or other responsible adult who signed an underage driver's license application withdraws his or her signature,:","explanation":"If the parent or other adult who signed an underage MS driver's license application withdraws his or her signature, the license will be revoked. [Signatures, Application Requirements, Mississippi Driver's Handbook]","imageName":"","correctAnswer":33849,"isBookmarked":0,"aid":"33849#33850#33851#33852","text":"the license will be canceled#the driver will have to pay a fine#the driver will have to find someone else to sign the application#the license will be suspended for one year"},
{"testId":588,"qid":25691,"description":"The horn of a vehicle may be heard from ______ away.","explanation":null,"imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"214#399#400#1296","text":"100 feet#200 feet#300 feet#500 feet"},
{"testId":1055,"qid":25691,"description":"The horn of a vehicle may be heard from ______ away.","explanation":null,"imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"214#399#400#1296","text":"100 feet#200 feet#300 feet#500 feet"},
{"testId":588,"qid":25692,"description":"A Mississippi driver's license may be renewed up to ______ days before its expiration date.","explanation":"A Mississippi driver's license may be renewed without penalty up to six months before its expiry date. [Mississippi Driver's Manual, Renewals]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":1055,"qid":25692,"description":"A Mississippi driver's license may be renewed up to ______ days before its expiration date.","explanation":"A Mississippi driver's license may be renewed without penalty up to six months before its expiry date. [Mississippi Driver's Manual, Renewals]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1020#1021#1272","text":"30 days#60 days#1 year#6 months"},
{"testId":588,"qid":25693,"description":"For how much duration a class Y intermediate driver's licence is valid?","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20790#23153#23154","text":"2 years#4 years#6 months#1 year"},
{"testId":588,"qid":25694,"description":"A four-year driver's license costs ____ to obtain or renew, and an eight-year driver's license costs _____","explanation":"You may apply for a four-year driver's license if you are under the age of 21. If you are at least 21 years old, you may apply for a four-year or an eight-year license, whichever you desire. A four-year driver's license costs $24 to purchase or renew, and an eight-year driver's license costs $47. [Mississippi Driver's Manual, Fees for Original and Renewal Licenses]","imageName":"","correctAnswer":33854,"isBookmarked":0,"aid":"33853#33854#33855#33856","text":"$20; $40#$24; $47#$24; $48#$28; $56"},
{"testId":1055,"qid":25694,"description":"A four-year driver's license costs ____ to obtain or renew, and an eight-year driver's license costs _____","explanation":"You may apply for a four-year driver's license if you are under the age of 21. If you are at least 21 years old, you may apply for a four-year or an eight-year license, whichever you desire. A four-year driver's license costs $24 to purchase or renew, and an eight-year driver's license costs $47. [Mississippi Driver's Manual, Fees for Original and Renewal Licenses]","imageName":"","correctAnswer":33854,"isBookmarked":0,"aid":"33853#33854#33855#33856","text":"$20; $40#$24; $47#$24; $48#$28; $56"},
{"testId":588,"qid":25695,"description":"What is the late fee for renewing an MS license that has expired?","explanation":"A late fee of $1.00 is charged to renew an expired MS driver's license. [Other Charges, Mississippi Driver's Handbook]","imageName":"","correctAnswer":13480,"isBookmarked":0,"aid":"11953#13459#13480#13481","text":"$25.00#$10.00#$1.00#$5.00"},
{"testId":1055,"qid":25695,"description":"What is the late fee for renewing an MS license that has expired?","explanation":"A late fee of $1.00 is charged to renew an expired MS driver's license. [Other Charges, Mississippi Driver's Handbook]","imageName":"","correctAnswer":13480,"isBookmarked":0,"aid":"11953#13459#13480#13481","text":"$25.00#$10.00#$1.00#$5.00"},
{"testId":590,"qid":25696,"description":"First-time DUI convictions result in聽license suspensions for:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1057,"qid":25696,"description":"First-time DUI convictions result in聽license suspensions for:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":590,"qid":25697,"description":"How long will your driver's license be suspended if you are convicted of a second or subsequent DUI within five years?","explanation":"Your driving license will be suspended for one year if you are convicted of a second or subsequent DUI within five years. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1057,"qid":25697,"description":"How long will your driver's license be suspended if you are convicted of a second or subsequent DUI within five years?","explanation":"Your driving license will be suspended for one year if you are convicted of a second or subsequent DUI within five years. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":590,"qid":25698,"description":"If you refuse to submit to drug or alcohol testing for the first time, your driver's license will be suspended for :","explanation":"Your driving license will be suspended for the same term as a DUI conviction if you refuse to submit to testing. That is, if you refuse for the first time, your license will be suspended for six months. Your license will be suspended for one year if you refuse a second or subsequent time within five years. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1057,"qid":25698,"description":"If you refuse to submit to drug or alcohol testing for the first time, your driver's license will be suspended for :","explanation":"Your driving license will be suspended for the same term as a DUI conviction if you refuse to submit to testing. That is, if you refuse for the first time, your license will be suspended for six months. Your license will be suspended for one year if you refuse a second or subsequent time within five years. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":590,"qid":25699,"description":"A motorist under the age of 21:","explanation":null,"imageName":"","correctAnswer":31861,"isBookmarked":0,"aid":"31861#34431#34432#34433","text":"is not allowed to buy or consume alcohol#can have one beer and still be legally able to drive#can have one alcoholic drink and legally drive after an hour#can legally have a blood alcohol concentration (BAC) of 0.04%"},
{"testId":1057,"qid":25699,"description":"A motorist under the age of 21:","explanation":null,"imageName":"","correctAnswer":31861,"isBookmarked":0,"aid":"31861#34431#34432#34433","text":"is not allowed to buy or consume alcohol#can have one beer and still be legally able to drive#can have one alcoholic drink and legally drive after an hour#can legally have a blood alcohol concentration (BAC) of 0.04%"},
{"testId":590,"qid":25700,"description":"If a driver under the age of 21 is convicted for the first time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or above for the first time, his or her driver license will be revoked for 90 days. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1057,"qid":25700,"description":"If a driver under the age of 21 is convicted for the first time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or above for the first time, his or her driver license will be revoked for 90 days. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":590,"qid":25701,"description":"If a driver under the age of 21 is convicted for the second time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or more for the second time, his or her driver license will be revoked for six months. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24049#25857","text":"6 months#30 days#90 days#120 days"},
{"testId":1057,"qid":25701,"description":"If a driver under the age of 21 is convicted for the second time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or more for the second time, his or her driver license will be revoked for six months. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24049#25857","text":"6 months#30 days#90 days#120 days"},
{"testId":590,"qid":25702,"description":"If a driver under the age of 21 is convicted for the third time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or more for the third time, his or her driver license will be revoked for one year. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":590,"qid":25704,"description":"Which of the following will result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34437#34438#34439","text":"All of the above#Three reckless driving convictions in one year#Use of a motor vehicle to steal motor fuel#Fraudulent application for a driver license"},
{"testId":1057,"qid":25704,"description":"Which of the following will result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34437#34438#34439","text":"All of the above#Three reckless driving convictions in one year#Use of a motor vehicle to steal motor fuel#Fraudulent application for a driver license"},
{"testId":590,"qid":25705,"description":"Which of the following non-driving violations will result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#27198#34440#34441","text":"All of the above#Failure to pay child support#Failure to appear in court when notified to do so#Failure to pay fines"},
{"testId":1057,"qid":25705,"description":"Which of the following non-driving violations will result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#27198#34440#34441","text":"All of the above#Failure to pay child support#Failure to appear in court when notified to do so#Failure to pay fines"},
{"testId":590,"qid":25706,"description":"If a motorist is found guilty of negligent killing while operating a motor vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#24049#29895","text":"1 year#3 years#90 days#10 years"},
{"testId":1057,"qid":25706,"description":"If a motorist is found guilty of negligent killing while operating a motor vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#24049#29895","text":"1 year#3 years#90 days#10 years"},
{"testId":590,"qid":25707,"description":"If a driver is convicted of a felony involving a motor vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#24049#29895","text":"1 year#3 years#90 days#10 years"},
{"testId":1057,"qid":25707,"description":"If a driver is convicted of a felony involving a motor vehicle, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#24049#29895","text":"1 year#3 years#90 days#10 years"},
{"testId":590,"qid":25708,"description":"The typical human body takes more than an hour to digest alcohol in :","explanation":null,"imageName":"","correctAnswer":38355,"isBookmarked":0,"aid":"8626#38354#38355#38356","text":"None of the above#three drinks#one drink#two drinks"},
{"testId":590,"qid":25709,"description":"If you accumulate _____ demerit points in  three-year, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5447#5448#6425#6477","text":"15#20#25#30"},
{"testId":590,"qid":25710,"description":"Which of the following will result in a Montana driver license suspension?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34446#34447#34448","text":"All of the above#Voluntary surrender of the license#Withdrawal of parental consent#Falsifying information on the application for a license"},
{"testId":1057,"qid":25710,"description":"Which of the following will result in a Montana driver license suspension?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34446#34447#34448","text":"All of the above#Voluntary surrender of the license#Withdrawal of parental consent#Falsifying information on the application for a license"},
{"testId":590,"qid":25711,"description":"You must provide proof of financial responsibility for ______ years if your license is suspended for driving without insurance.","explanation":"If your driver's license is suspended due to uninsured driving, you must provide evidence of financial responsibility. The most typical method of satisfying this requirement is to submit a Certificate of Financial Responsibility (form SR-22). This form of evidence is normally required to be valid for three years. [Montana Code Annotated, MCA 61-6-304]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1057,"qid":25711,"description":"You must provide proof of financial responsibility for ______ years if your license is suspended for driving without insurance.","explanation":"If your driver's license is suspended due to uninsured driving, you must provide evidence of financial responsibility. The most typical method of satisfying this requirement is to submit a Certificate of Financial Responsibility (form SR-22). This form of evidence is normally required to be valid for three years. [Montana Code Annotated, MCA 61-6-304]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":590,"qid":25712,"description":"A driver who successfully completes an approved driver rehabilitation program will receive a ____ discount on the license reinstatement fee.","explanation":"A motorist who is sent to the Driver Rehabilitation Program is required to pay a $50 fee to help offset the program's costs. However, if the motorist successfully completes the program, he or she will get a 50% reduction on the license reinstatement charge. [23.3.205 Driver Rehabilitation Program Fees, Montana Administrative Rules; MCA 61-5-219, Montana Code Annotated]","imageName":"","correctAnswer":11667,"isBookmarked":0,"aid":"11664#11665#11667#13496","text":"10%#25%#50%#75%"},
{"testId":1057,"qid":25712,"description":"A driver who successfully completes an approved driver rehabilitation program will receive a ____ discount on the license reinstatement fee.","explanation":"A motorist who is sent to the Driver Rehabilitation Program is required to pay a $50 fee to help offset the program's costs. However, if the motorist successfully completes the program, he or she will get a 50% reduction on the license reinstatement charge. [23.3.205 Driver Rehabilitation Program Fees, Montana Administrative Rules; MCA 61-5-219, Montana Code Annotated]","imageName":"","correctAnswer":11667,"isBookmarked":0,"aid":"11664#11665#11667#13496","text":"10%#25%#50%#75%"},
{"testId":590,"qid":25713,"description":"Suppose the court has restricted you from driving a vehicle fitted with an ignition interlock device (IID) (IID). If you're convicted of driving without it, you may be punished to _______ in jail.","explanation":"If the court orders you to drive a vehicle equipped with an ignition interlock device (IID) and you are guilty of defeating, disabling, or driving without it, you may be fined up to $500 or imprisoned for up to six months, or both. If your Breath Alcohol Concentration (BrAC) exceeds your state's standard, the IID will not allow you to start your car. [MCA 61-8-440]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1057,"qid":25713,"description":"Suppose the court has restricted you from driving a vehicle fitted with an ignition interlock device (IID) (IID). If you're convicted of driving without it, you may be punished to _______ in jail.","explanation":"If the court orders you to drive a vehicle equipped with an ignition interlock device (IID) and you are guilty of defeating, disabling, or driving without it, you may be fined up to $500 or imprisoned for up to six months, or both. If your Breath Alcohol Concentration (BrAC) exceeds your state's standard, the IID will not allow you to start your car. [MCA 61-8-440]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":590,"qid":25714,"description":"How can stress impact your driving?","explanation":null,"imageName":"","correctAnswer":45463,"isBookmarked":0,"aid":"45463#45464#45465#45466","text":"Stress can distract you from driving#Stress decreases your chances of getting into an accident#Stress can make you drowsy#Stress can help you to be more aware of your surroundings"},
{"testId":1057,"qid":25714,"description":"How can stress impact your driving?","explanation":null,"imageName":"","correctAnswer":45463,"isBookmarked":0,"aid":"45463#45464#45465#45466","text":"Stress can distract you from driving#Stress decreases your chances of getting into an accident#Stress can make you drowsy#Stress can help you to be more aware of your surroundings"},
{"testId":590,"qid":25718,"description":"Which of the following will add five points to a driver's record in Montana?","explanation":"If you are convicted of reckless driving, drag racing, or failing to comply with the state's insurance obligation, you will get five points on your driving record. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#29308#34449","text":"All of the above#Reckless driving#Drag racing#Driving an uninsured vehicle"},
{"testId":1057,"qid":25718,"description":"Which of the following will add five points to a driver's record in Montana?","explanation":"If you are convicted of reckless driving, drag racing, or failing to comply with the state's insurance obligation, you will get five points on your driving record. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#11621#29308#34449","text":"All of the above#Reckless driving#Drag racing#Driving an uninsured vehicle"},
{"testId":590,"qid":25719,"description":"If you are guilty of exceeding the prescribed speed limit by more than 10 miles per hour, ____ points will be added to your driving record.","explanation":"If you are guilty of exceeding the prescribed speed limit by more than ten miles per hour, three points will be added to your driving record. There will be no points added if you are guilty of speeding at 10 mph or less above the official speed limit during the day or 5 mph or less over the posted speed limit at night. [Montana Code Annotated, MCA 61-8-725 and MCA 61-11-203]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1057,"qid":25719,"description":"If you are guilty of exceeding the prescribed speed limit by more than 10 miles per hour, ____ points will be added to your driving record.","explanation":"If you are guilty of exceeding the prescribed speed limit by more than ten miles per hour, three points will be added to your driving record. There will be no points added if you are guilty of speeding at 10 mph or less above the official speed limit during the day or 5 mph or less over the posted speed limit at night. [Montana Code Annotated, MCA 61-8-725 and MCA 61-11-203]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":590,"qid":25721,"description":"If you have been categorized as a habitual traffic offender and continue to drive while your license is revoked, you might face up to __________ in prison.","explanation":"If you have been categorized as a chronic traffic offender and continue to drive while your license is revoked, you will face 14 days to one year in prison, a $1,000 fine, or both. Your driver's license will be revoked for an extra year. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#24048#25857","text":"1 year#30 days#60 days#120 days"},
{"testId":1057,"qid":25721,"description":"If you have been categorized as a habitual traffic offender and continue to drive while your license is revoked, you might face up to __________ in prison.","explanation":"If you have been categorized as a chronic traffic offender and continue to drive while your license is revoked, you will face 14 days to one year in prison, a $1,000 fine, or both. Your driver's license will be revoked for an extra year. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#24048#25857","text":"1 year#30 days#60 days#120 days"},
{"testId":590,"qid":25722,"description":"In Montana, you must always have ________ with you when driving a car.","explanation":null,"imageName":"","correctAnswer":34450,"isBookmarked":0,"aid":"11706#34450#34451#34452","text":"your passport#proof of liability insurance#your birth certificate#your Social Security card"},
{"testId":590,"qid":25723,"description":"If it's your first offense, you could be fined at least _____  for driving without vehicle insurance.","explanation":null,"imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11349#11832#11833#12395","text":"$50#$100#$300#$250"},
{"testId":590,"qid":25724,"description":"If it's your first offense, you could be fined as much as _____  for driving without vehicle聽insurance.","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#3195#11832","text":"$500#$1,000#$5,000#$100"},
{"testId":590,"qid":25725,"description":"If convicted a second time of driving uninsured, you may face which penalty?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34453#34454#34455","text":"All of the above#A $350 fine#Up to 10 days in jail#License plate suspension for 90 days"},
{"testId":590,"qid":25726,"description":"Montana law mandates that your headlights must be dimmed within _______ of encountering a vehicle and within _______ of a vehicle you are following.","explanation":null,"imageName":"","correctAnswer":34459,"isBookmarked":0,"aid":"34456#34457#34458#34459","text":"500 feet; 1,000 feet#500 feet; 500 feet#1,000 feet; 1,000 feet#1,000 feet; 500 feet"},
{"testId":590,"qid":25727,"description":"The consequences of driving under the influence of alcohol include ______.","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#34460#34461#34462","text":"All of the above#paying higher insurance rates#losing their driver licenses#paying heavy fines"},
{"testId":1057,"qid":25727,"description":"The consequences of driving under the influence of alcohol include ______.","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#34460#34461#34462","text":"All of the above#paying higher insurance rates#losing their driver licenses#paying heavy fines"},
{"testId":590,"qid":25728,"description":"What is the bare minimum penalty for a first DUI conviction?","explanation":"A first DUI conviction carries a sentence of 24 hours to 6 months in prison and a fine of $600 to $1,000. The penalties will be raised if there were passengers under the age of 16 in the car at the time. [MCA 61-8-714, Montana Code Annotated; Driving Under the Influence of Alcohol/Drugs: Penalties, Montana Department of Transportation, https://www.mdt.mt.gov/visionzero/docs/dui penalties .pdf]","imageName":"","correctAnswer":34463,"isBookmarked":0,"aid":"13513#13514#13515#34463","text":"72 hours in jail and a $100 fine#24 hours in jail and a $300 fine#72 hours in jail and a $300 fine#24 hours in jail and a $600 fine"},
{"testId":1057,"qid":25728,"description":"What is the bare minimum penalty for a first DUI conviction?","explanation":"A first DUI conviction carries a sentence of 24 hours to 6 months in prison and a fine of $600 to $1,000. The penalties will be raised if there were passengers under the age of 16 in the car at the time. [MCA 61-8-714, Montana Code Annotated; Driving Under the Influence of Alcohol/Drugs: Penalties, Montana Department of Transportation, https://www.mdt.mt.gov/visionzero/docs/dui penalties .pdf]","imageName":"","correctAnswer":34463,"isBookmarked":0,"aid":"13513#13514#13515#34463","text":"72 hours in jail and a $100 fine#24 hours in jail and a $300 fine#72 hours in jail and a $300 fine#24 hours in jail and a $600 fine"},
{"testId":590,"qid":25729,"description":"A first DUI conviction carries a maximum penalty of $1,000 in fines and ______ in prison.","explanation":"A first DUI conviction carries a sentence of 24 hours to 6 months in prison and a fine of $600 to $1,000. The penalties will be raised if there were passengers under the age of 16 in the car at the time. [MCA 61-8-714, Montana Code Annotated; Driving Under the Influence of Alcohol/Drugs: Penalties, Montana Department of Transportation, https://www.mdt.mt.gov/visionzero/docs/dui penalties .pdf]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1057,"qid":25729,"description":"A first DUI conviction carries a maximum penalty of $1,000 in fines and ______ in prison.","explanation":"A first DUI conviction carries a sentence of 24 hours to 6 months in prison and a fine of $600 to $1,000. The penalties will be raised if there were passengers under the age of 16 in the car at the time. [MCA 61-8-714, Montana Code Annotated; Driving Under the Influence of Alcohol/Drugs: Penalties, Montana Department of Transportation, https://www.mdt.mt.gov/visionzero/docs/dui penalties .pdf]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":590,"qid":25730,"description":"Which of the following documents must you submit to a law enforcement officer if you are pulled over for a traffic violation?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34464#34465#34466","text":"All of the above#Your driver license#Proof of liability insurance#Your vehicle's registration"},
{"testId":590,"qid":25731,"description":"Every vehicle you drive in Montana must be insured for at least _______ for bodily injury or death to one person in any one accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":590,"qid":25732,"description":"Who is eligible to use the Montana Insurance Verification System (MTIVS)?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34467#34468#34469","text":"All of the above#The Montana Motor Vehicle Division (MVD)#State troopers#The courts"},
{"testId":1057,"qid":25732,"description":"Who is eligible to use the Montana Insurance Verification System (MTIVS)?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34467#34468#34469","text":"All of the above#The Montana Motor Vehicle Division (MVD)#State troopers#The courts"},
{"testId":590,"qid":25734,"description":"When you travel on Montana's public roadways, you are granting implied agreement to:","explanation":"When you drive on Montana's public highways, you are granting implicit assent to alcohol and drug testing under Montana's Implied Consent statute. If a police officer requests that you take a test and you refuse, the officer will seize your driver's license and suspend it. [MCA 61-8-402, Annotated Montana Code]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8626#29272#33481#33482","text":"None of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":1057,"qid":25734,"description":"When you travel on Montana's public roadways, you are granting implied agreement to:","explanation":"When you drive on Montana's public highways, you are granting implicit assent to alcohol and drug testing under Montana's Implied Consent statute. If a police officer requests that you take a test and you refuse, the officer will seize your driver's license and suspend it. [MCA 61-8-402, Annotated Montana Code]","imageName":"","correctAnswer":33482,"isBookmarked":0,"aid":"8626#29272#33481#33482","text":"None of the above#a search of your home#the release of your medical records#submission to chemical testing for alcohol or drug use"},
{"testId":590,"qid":25735,"description":"How much will you have to pay if you break Montana's Open Container Law?","explanation":"The Open Container Law in Montana forbids all occupants of a motor vehicle from having alcoholic drinks within reach. If you are found guilty of breaking the law, you will be fined $100. [Montana's Open Container Law, Vision Zero, Montana Department of Transportation, http://www.mdt.mt.gov/visionzero/people/open container law.html] .shtml]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11832#11833#12358#12817","text":"$100#$300#$200#$400"},
{"testId":590,"qid":25736,"description":"Your driving privileges will be terminated for a year if you refuse to take an alcohol or drug test for the second time within ______ years.","explanation":"If you decline the exam for the second time in five years, your license will be suspended for one year, exactly as if you failed the test for the second time in five years. [MCA 61-8-402, Montana Code Annotated; Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1057,"qid":25736,"description":"Your driving privileges will be terminated for a year if you refuse to take an alcohol or drug test for the second time within ______ years.","explanation":"If you decline the exam for the second time in five years, your license will be suspended for one year, exactly as if you failed the test for the second time in five years. [MCA 61-8-402, Montana Code Annotated; Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":590,"qid":25737,"description":"The regular noncommercial driver license in Montana is:","explanation":null,"imageName":"","correctAnswer":34473,"isBookmarked":0,"aid":"34470#34471#34472#34473","text":"Class A#Class B#Class C#Class D"},
{"testId":1057,"qid":25737,"description":"The regular noncommercial driver license in Montana is:","explanation":null,"imageName":"","correctAnswer":34473,"isBookmarked":0,"aid":"34470#34471#34472#34473","text":"Class A#Class B#Class C#Class D"},
{"testId":590,"qid":25738,"description":"When does the average Montana driver license expire?","explanation":null,"imageName":"","correctAnswer":34476,"isBookmarked":0,"aid":"34474#34475#34476#34477","text":"On the anniversary of the date it was issued#On the first day of the month in which the driver was born#On the driver's birthday#On a randomly assigned day"},
{"testId":1057,"qid":25738,"description":"When does the average Montana driver license expire?","explanation":null,"imageName":"","correctAnswer":34476,"isBookmarked":0,"aid":"34474#34475#34476#34477","text":"On the anniversary of the date it was issued#On the first day of the month in which the driver was born#On the driver's birthday#On a randomly assigned day"},
{"testId":590,"qid":25739,"description":"A Montana driver's license will expire on his or her _______ birthday which driver has obtained on his or her 18th birthday.","explanation":null,"imageName":"","correctAnswer":34479,"isBookmarked":0,"aid":"34478#34479#34480#34481","text":"20th#21st#22nd#25th"},
{"testId":1057,"qid":25739,"description":"A Montana driver's license will expire on his or her _______ birthday which driver has obtained on his or her 18th birthday.","explanation":null,"imageName":"","correctAnswer":34479,"isBookmarked":0,"aid":"34478#34479#34480#34481","text":"20th#21st#22nd#25th"},
{"testId":590,"qid":25740,"description":"A Montana resident under the age of 18 must acquire permission from _________ before applying for a learner license.","explanation":null,"imageName":"","correctAnswer":34482,"isBookmarked":0,"aid":"13531#34482#34483#34484","text":"no one#his or her parent or guardian#his or her employer#his or her school administrator"},
{"testId":590,"qid":25741,"description":"Before applying for a driver license, a driver under the age of 18 must have accumulated at least ___ hours of supervised driving experience, ___ hours of which must be at night.","explanation":null,"imageName":"","correctAnswer":13532,"isBookmarked":0,"aid":"13532#13533#13534#13535","text":"50; 10#75; 10#30; 15#30; 5"},
{"testId":590,"qid":25742,"description":"What is the legal limit for the amount of alcohol in an adult driver's blood?","explanation":null,"imageName":"","correctAnswer":10236,"isBookmarked":0,"aid":"3168#3171#10236#11768","text":"0.08%#0.09%#Less than 0.08%#0.10%"},
{"testId":590,"qid":25743,"description":"A teen driver with a restricted driver license is not permitted to drive unattended between the hours of ______ and ______.","explanation":null,"imageName":"","correctAnswer":13540,"isBookmarked":0,"aid":"13389#13539#13540#13541","text":"midnight; 5:00 a.m#11:00 p.m.; 4:00 a.m#11:00 p.m.; 5:00 a.m#midnight; 7:00 a.m"},
{"testId":590,"qid":25744,"description":"If a Montana young driver with a limited driving license breaches any of the limitations for the first time, he or she faces up to ___ hours of community service.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5448#8141#11513#12359","text":"20#60#40#80"},
{"testId":590,"qid":25745,"description":"If a Montana teen driver with a limited driving license breaches any of the limitations a second time, his or her license will be suspended for:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":592,"qid":25746,"description":"In Nevada, every motor vehicle you drive must have at least _______ in bodily injury or death insurance coverage for one person in any one collision.","explanation":"Each motor vehicle's liability insurance policy must contain at least $25,000 in coverage for the bodily injury or death of one person in any one accident, according to Nevada law. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":592,"qid":25747,"description":"Every vehicle you drive in Nevada must have at least _______ in insurance coverage for the bodily injury or death of two or more people in any one accident.","explanation":"According to Nevada law, each motor vehicle's liability insurance policy must contain at least $50,000 in coverage for the bodily injury or death of two or more people in a single collision. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#11659#12048#12195","text":"$50,000#$20,000#$25,000#$35,000"},
{"testId":592,"qid":25748,"description":"Every motor vehicle you operate in Nevada must be insured for at least _______ of property damage.","explanation":"Nevada law mandates that each liability insurance policy for a motor vehicle have at least $20,000 in property damage coverage. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"1799#3195#11658#11659","text":"$1,000#$5,000#$10,000#$20,000"},
{"testId":592,"qid":25749,"description":"If your vehicle's registration is suspended due to a lapse in insurance coverage, you must pay a registration fee as well as fines totaling at least _____ to get it reinstated.","explanation":"If your vehicle's registration is stopped due to a lack in insurance coverage, you must pay a registration fee and penalties ranging from $250 to $1,750 to get it restored. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11349#11832#12357#12395","text":"$50#$100#$150#$250"},
{"testId":1059,"qid":25749,"description":"If your vehicle's registration is suspended due to a lapse in insurance coverage, you must pay a registration fee as well as fines totaling at least _____ to get it reinstated.","explanation":"If your vehicle's registration is stopped due to a lack in insurance coverage, you must pay a registration fee and penalties ranging from $250 to $1,750 to get it restored. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":12395,"isBookmarked":0,"aid":"11349#11832#12357#12395","text":"$50#$100#$150#$250"},
{"testId":592,"qid":25750,"description":"If you are involved in an accident that results in bodily harm or at least $750 in estimated property damage and is not investigated by law enforcement, you must file an accident report with the Nevada DMV within 30 days.","explanation":"You must submit an accident report with the Nevada DMV within ten days if you are involved in an accident that involves injury or at least $750 in estimated property damage and is not investigated by law enforcement. If you do not comply, your driver's license or car registration may be revoked. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"202006141611292838.jpg","correctAnswer":23883,"isBookmarked":0,"aid":"23154#23883#25170#34746","text":"1 year#10 days#1 month#3 days"},
{"testId":592,"qid":25751,"description":"Most traffic offenses are reported to insurance companies for:","explanation":"For three years, most traffic offenses are reportable to your insurance carrier. [Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":1059,"qid":25751,"description":"Most traffic offenses are reported to insurance companies for:","explanation":"For three years, most traffic offenses are reportable to your insurance carrier. [Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":592,"qid":25752,"description":"For how much duration a DUI conviction will appear on your driving record?","explanation":"A DUI conviction stays on your record for seven years. [Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":29894,"isBookmarked":0,"aid":"23153#23886#26672#29894","text":"6 months#30 days#5 years#7 years"},
{"testId":1059,"qid":25752,"description":"For how much duration a DUI conviction will appear on your driving record?","explanation":"A DUI conviction stays on your record for seven years. [Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":29894,"isBookmarked":0,"aid":"23153#23886#26672#29894","text":"6 months#30 days#5 years#7 years"},
{"testId":592,"qid":25753,"description":"The DMV accumulates demerit points over a _____ period to determine appropriate administrative penalties.","explanation":"The DMV assesses demerit points over a twelve-month period to establish appropriate administrative fines. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":34966,"isBookmarked":0,"aid":"29890#34965#34966#34967","text":"3-year#2-year#12-month#6-month"},
{"testId":1059,"qid":25753,"description":"The DMV accumulates demerit points over a _____ period to determine appropriate administrative penalties.","explanation":"The DMV assesses demerit points over a twelve-month period to establish appropriate administrative fines. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":34966,"isBookmarked":0,"aid":"29890#34965#34966#34967","text":"3-year#2-year#12-month#6-month"},
{"testId":592,"qid":25754,"description":"If you accumulate _____ demerit points in  a year, your license will be suspended.","explanation":"Your license will be suspended for six months if you acquire twelve or more points in any twelve-month period. [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints], [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints] .htm]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1059,"qid":25754,"description":"If you accumulate _____ demerit points in  a year, your license will be suspended.","explanation":"Your license will be suspended for six months if you acquire twelve or more points in any twelve-month period. [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints], [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints] .htm]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":592,"qid":25755,"description":"If you successfully finish a DMV-approved traffic safety course, your driving record may be cleared of ___ points.","explanation":"If you successfully complete a DMV-approved traffic safety course, your driving record may be cleared of three points. This may be done several times, but only once every year.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6389#6390#6916#7620","text":"3#4#6#8"},
{"testId":1059,"qid":25755,"description":"If you successfully finish a DMV-approved traffic safety course, your driving record may be cleared of ___ points.","explanation":"If you successfully complete a DMV-approved traffic safety course, your driving record may be cleared of three points. This may be done several times, but only once every year.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6389#6390#6916#7620","text":"3#4#6#8"},
{"testId":592,"qid":25756,"description":"Completing a DMV-approved traffic safety course helps you remove points from your driving record. How many times can you complete such a course?","explanation":"Once a year, you may take a DMV-approved driving safety course and have three points erased. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":34968,"isBookmarked":0,"aid":"34742#34968#34969#34970","text":"Just once#Once per year#Twice per year#Four times per year"},
{"testId":1059,"qid":25756,"description":"Completing a DMV-approved traffic safety course helps you remove points from your driving record. How many times can you complete such a course?","explanation":"Once a year, you may take a DMV-approved driving safety course and have three points erased. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":34968,"isBookmarked":0,"aid":"34742#34968#34969#34970","text":"Just once#Once per year#Twice per year#Four times per year"},
{"testId":592,"qid":25757,"description":"Conviction for the careless driving will add____points to driving record.","explanation":"A reckless driving conviction will result in eight points being added to your driving record. [Demerit Point System, Chapter 8: Your Driving Record, Nevada Driver's Handbook]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":1059,"qid":25757,"description":"Conviction for the careless driving will add____points to driving record.","explanation":"A reckless driving conviction will result in eight points being added to your driving record. [Demerit Point System, Chapter 8: Your Driving Record, Nevada Driver's Handbook]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":592,"qid":25758,"description":"Which of the following will add six points to your driving record?","explanation":"A conviction for failing to provide information or administer help at an accident scene will add six points to your driving record, as would careless driving. [Demerit Point System, Chapter 8: Your Driving Record, Nevada Driver's Handbook]","imageName":"","correctAnswer":23204,"isBookmarked":0,"aid":"18625#23204#34971#34972","text":"Speeding#Leaving the scene of an accident#Failure to dim your headlights#Failure to yield to pedestrians"},
{"testId":1059,"qid":25758,"description":"Which of the following will add six points to your driving record?","explanation":"A conviction for failing to provide information or administer help at an accident scene will add six points to your driving record, as would careless driving. [Demerit Point System, Chapter 8: Your Driving Record, Nevada Driver's Handbook]","imageName":"","correctAnswer":23204,"isBookmarked":0,"aid":"18625#23204#34971#34972","text":"Speeding#Leaving the scene of an accident#Failure to dim your headlights#Failure to yield to pedestrians"},
{"testId":592,"qid":25759,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":"If you are guilty of tailgating another vehicle, four points will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"202001302249003541.jpg","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":592,"qid":25761,"description":"__ points will be added to your driving record if you're convicted of driving too slowly and impeding traffic.","explanation":"Two points will be added to your driving record if you are guilty of impeding traffic by driving too slowly. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1059,"qid":25761,"description":"__ points will be added to your driving record if you're convicted of driving too slowly and impeding traffic.","explanation":"Two points will be added to your driving record if you are guilty of impeding traffic by driving too slowly. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":592,"qid":25762,"description":"__ point(s) will be added to your driving record if you are found guilty of exceeding the posted speed limit by five miles per hour.","explanation":"If you are guilty of exceeding the prescribed speed limit by no more than 10 miles per hour, one point will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1059,"qid":25762,"description":"__ point(s) will be added to your driving record if you are found guilty of exceeding the posted speed limit by five miles per hour.","explanation":"If you are guilty of exceeding the prescribed speed limit by no more than 10 miles per hour, one point will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":592,"qid":25763,"description":"_____ points will be added to your driving record if you are convicted of speeding 15 mph above the posted speed limit.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1059,"qid":25763,"description":"_____ points will be added to your driving record if you are convicted of speeding 15 mph above the posted speed limit.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":592,"qid":25764,"description":"A speeding ticket with three points will be issued if you exceed the prescribed limit by 21 to _______ miles per hour.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6425#6477#11513#12455","text":"25#30#40#35"},
{"testId":1059,"qid":25764,"description":"A speeding ticket with three points will be issued if you exceed the prescribed limit by 21 to _______ miles per hour.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6425#6477#11513#12455","text":"25#30#40#35"},
{"testId":592,"qid":25765,"description":"If you are guilty of exceeding the speed limit by _____ miles per hour, five points will be added to your driving record.","explanation":"If you are guilty of exceeding the legal speed limit by 41 mph or more, five points will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":13786,"isBookmarked":0,"aid":"11343#11360#13709#13786","text":"11#21#31#41"},
{"testId":1059,"qid":25765,"description":"If you are guilty of exceeding the speed limit by _____ miles per hour, five points will be added to your driving record.","explanation":"If you are guilty of exceeding the legal speed limit by 41 mph or more, five points will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":13786,"isBookmarked":0,"aid":"11343#11360#13709#13786","text":"11#21#31#41"},
{"testId":592,"qid":25766,"description":"In 2005, alcohol was a factor in more than _____% of Nevada's highway deaths, according to the Nevada Driver's Handbook.","explanation":"According to the Nevada Driver's Handbook, alcohol was a factor in more than 37 percent of Nevada roadway fatalities in 2005. Drunk driving accounts for around one-third of all road fatalities in the United States. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [Centers for Disease Control and Prevention, \"Sobering Facts: Drunk Driving in Nevada,\" https://www.cdc.gov/motorvehiclesafety/pdf/impaired driving/drunk driving in nv .pdf]","imageName":"","correctAnswer":13550,"isBookmarked":0,"aid":"11374#11808#13550#13551","text":"17#27#37#47"},
{"testId":1059,"qid":25766,"description":"In 2005, alcohol was a factor in more than _____% of Nevada's highway deaths, according to the Nevada Driver's Handbook.","explanation":"According to the Nevada Driver's Handbook, alcohol was a factor in more than 37 percent of Nevada roadway fatalities in 2005. Drunk driving accounts for around one-third of all road fatalities in the United States. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [Centers for Disease Control and Prevention, \"Sobering Facts: Drunk Driving in Nevada,\" https://www.cdc.gov/motorvehiclesafety/pdf/impaired driving/drunk driving in nv .pdf]","imageName":"","correctAnswer":13550,"isBookmarked":0,"aid":"11374#11808#13550#13551","text":"17#27#37#47"},
{"testId":592,"qid":25767,"description":"In Nevada, which of the following can result in a DUI conviction?","explanation":"Prescription pills, over-the-counter pharmaceuticals, illicit narcotics, marijuana, and, of course, alcohol may all impair your driving abilities and result in a DUI conviction. If you are unclear about the side effects of a medicine, consult the warning label or your pharmacist or doctor. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9803#25099#29384","text":"All of the above#Over-the-counter medications#Marijuana#Prescription medications"},
{"testId":1059,"qid":25767,"description":"In Nevada, which of the following can result in a DUI conviction?","explanation":"Prescription pills, over-the-counter pharmaceuticals, illicit narcotics, marijuana, and, of course, alcohol may all impair your driving abilities and result in a DUI conviction. If you are unclear about the side effects of a medicine, consult the warning label or your pharmacist or doctor. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9803#25099#29384","text":"All of the above#Over-the-counter medications#Marijuana#Prescription medications"},
{"testId":592,"qid":25768,"description":"Who will impose criminal penalties in addition to the administrative penalties imposed by the Nevada DMV for a DUI conviction?","explanation":"The Nevada court system imposes criminal penalties (juvenile courts for offenders under the age of 18), while the Nevada DMV imposes administrative punishments. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34973,"isBookmarked":0,"aid":"8626#26586#34973#34974","text":"None of the above#The driver's insurance company#The Nevada court system#The federal court system"},
{"testId":1059,"qid":25768,"description":"Who will impose criminal penalties in addition to the administrative penalties imposed by the Nevada DMV for a DUI conviction?","explanation":"The Nevada court system imposes criminal penalties (juvenile courts for offenders under the age of 18), while the Nevada DMV imposes administrative punishments. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34973,"isBookmarked":0,"aid":"8626#26586#34973#34974","text":"None of the above#The driver's insurance company#The Nevada court system#The federal court system"},
{"testId":592,"qid":25769,"description":"Which chemical tests do police officers use to find out if a driver is high on drugs or drunk?","explanation":"If a law enforcement officer suspects you of driving under the influence, you will be requested to submit to blood, breath, or urine testing under Nevada's Implied Consent Law. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":1059,"qid":25769,"description":"Which chemical tests do police officers use to find out if a driver is high on drugs or drunk?","explanation":"If a law enforcement officer suspects you of driving under the influence, you will be requested to submit to blood, breath, or urine testing under Nevada's Implied Consent Law. [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":592,"qid":25770,"description":"Your license will be revoked if chemical testing reveal that your blood alcohol concentration (BAC) is _____ or higher.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4253#4653","text":"0.08%#0.02%#0.03%#0.01%"},
{"testId":1059,"qid":25770,"description":"Your license will be revoked if chemical testing reveal that your blood alcohol concentration (BAC) is _____ or higher.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4253#4653","text":"0.08%#0.02%#0.03%#0.01%"},
{"testId":592,"qid":25771,"description":"If a driver under the age of 21 is discovered to have a blood alcohol content (BAC) of at least 0.02 percent but less than 0.08 percent,:","explanation":"If you are under the age of 21 and a chemical test reveals a blood alcohol concentration (BAC) of at least 0.02 percent but less than 0.08 percent, your license will be suspended for 90 days. This is an administrative punishment, and the officer has the authority to suspend your license immediately. In addition, you may be compelled to produce evidence of financial responsibility (form SR-22) and face criminal charges. (Note: An average 180-pound guy will have a BAC of 0.03 percent from the alcohol in only one can of beer before his body begins to break it down.) As a result, a legal BAC limit of less than 0.02 percent is virtually zero tolerance.) [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"34978#34979#34980#34981","text":"his or her license will be suspended for 90 days#he or she may be required to file proof of financial responsibility#he or she may incur criminal penalties#all of the above may happen"},
{"testId":1059,"qid":25771,"description":"If a driver under the age of 21 is discovered to have a blood alcohol content (BAC) of at least 0.02 percent but less than 0.08 percent,:","explanation":"If you are under the age of 21 and a chemical test reveals a blood alcohol concentration (BAC) of at least 0.02 percent but less than 0.08 percent, your license will be suspended for 90 days. This is an administrative punishment, and the officer has the authority to suspend your license immediately. In addition, you may be compelled to produce evidence of financial responsibility (form SR-22) and face criminal charges. (Note: An average 180-pound guy will have a BAC of 0.03 percent from the alcohol in only one can of beer before his body begins to break it down.) As a result, a legal BAC limit of less than 0.02 percent is virtually zero tolerance.) [Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"34978#34979#34980#34981","text":"his or her license will be suspended for 90 days#he or she may be required to file proof of financial responsibility#he or she may incur criminal penalties#all of the above may happen"},
{"testId":592,"qid":25772,"description":"A driver under the age of 18 may have his or her license suspended for which of the following reasons?","explanation":"Unauthorized street racing; a BAC of at least 0.02 percent but less than 0.08 percent; placing graffiti on or defacing public or private property; possessing or consuming alcohol or a controlled substance; any criminal activity involving alcohol or a controlled substance; possessing or using a firearm; or habitual truancy are all grounds for a driver under the age of 18 having his or her license suspended. [Young Drivers, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34982#34983#34984","text":"Any of the above#A blood alcohol concentration (BAC) of 0.02%#Spray-painting graffiti#Repeated truancy from school"},
{"testId":1059,"qid":25772,"description":"A driver under the age of 18 may have his or her license suspended for which of the following reasons?","explanation":"Unauthorized street racing; a BAC of at least 0.02 percent but less than 0.08 percent; placing graffiti on or defacing public or private property; possessing or consuming alcohol or a controlled substance; any criminal activity involving alcohol or a controlled substance; possessing or using a firearm; or habitual truancy are all grounds for a driver under the age of 18 having his or her license suspended. [Young Drivers, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#34982#34983#34984","text":"Any of the above#A blood alcohol concentration (BAC) of 0.02%#Spray-painting graffiti#Repeated truancy from school"},
{"testId":592,"qid":25773,"description":"If you're convicted of DUI with a BAC of 0.08 percent or higher, you'll lose your license for:","explanation":"Your license will be canceled for 90 days if you are convicted of driving with a BAC of at least 0.08 percent. [Administrative - Illegal Per Se Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1059,"qid":25773,"description":"If you're convicted of DUI with a BAC of 0.08 percent or higher, you'll lose your license for:","explanation":"Your license will be canceled for 90 days if you are convicted of driving with a BAC of at least 0.08 percent. [Administrative - Illegal Per Se Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":592,"qid":25774,"description":"If a driver less than 21 years old is convicted of driving with a BAC of at least 0.02 percent but less than 0.08 percent, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a blood alcohol concentration (BAC) of at least 0.02 percent but less than 0.08 percent, his or her license will be revoked for 90 days. [Administrative - Illegal Per Se Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1059,"qid":25774,"description":"If a driver less than 21 years old is convicted of driving with a BAC of at least 0.02 percent but less than 0.08 percent, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a blood alcohol concentration (BAC) of at least 0.02 percent but less than 0.08 percent, his or her license will be revoked for 90 days. [Administrative - Illegal Per Se Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":592,"qid":25775,"description":"First Time DUI offenders may be sentenced to jail for up to:","explanation":"A first DUI conviction carries a prison penalty ranging from two days to six months, as well as 96 hours of community service. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":1059,"qid":25775,"description":"First Time DUI offenders may be sentenced to jail for up to:","explanation":"A first DUI conviction carries a prison penalty ranging from two days to six months, as well as 96 hours of community service. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":592,"qid":25776,"description":"First Time DUI offenders may be fined up to:","explanation":"A fee of $400 to $1,000 is levied as part of the criminal penalty for a first DUI conviction. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23888#23889#34985","text":"$500#$600#$1,000#$1,200"},
{"testId":1059,"qid":25776,"description":"First Time DUI offenders may be fined up to:","explanation":"A fee of $400 to $1,000 is levied as part of the criminal penalty for a first DUI conviction. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23888#23889#34985","text":"$500#$600#$1,000#$1,200"},
{"testId":592,"qid":25777,"description":"Which of the following may be required to do by a聽first-time DUI offender?","explanation":"If you are convicted of DUI for the first time, you will be compelled to complete an alcohol and controlled drug addiction education course. You must pay the course fee yourself. (The average course fee is $150.) If you are permitted to get alcohol or drug treatment, this criterion may be avoided. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":34986,"isBookmarked":0,"aid":"897#34986#34987#34988","text":"None of the above#Pay for and attend DUI school#Retake the driver's license examination#Be supervised by another licensed driver"},
{"testId":1059,"qid":25777,"description":"Which of the following may be required to do by a聽first-time DUI offender?","explanation":"If you are convicted of DUI for the first time, you will be compelled to complete an alcohol and controlled drug addiction education course. You must pay the course fee yourself. (The average course fee is $150.) If you are permitted to get alcohol or drug treatment, this criterion may be avoided. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":34986,"isBookmarked":0,"aid":"897#34986#34987#34988","text":"None of the above#Pay for and attend DUI school#Retake the driver's license examination#Be supervised by another licensed driver"},
{"testId":592,"qid":25778,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI violation happens within seven years of the first offense, it is legally deemed a second offense. Similarly, a third DUI charge is considered if it happens within seven years of the first. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":1059,"qid":25778,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI violation happens within seven years of the first offense, it is legally deemed a second offense. Similarly, a third DUI charge is considered if it happens within seven years of the first. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":592,"qid":25779,"description":"Up to _____ hours of community service are among the criminal punishments for a second DUI conviction.","explanation":null,"imageName":"","correctAnswer":7570,"isBookmarked":0,"aid":"6425#6591#7570#7571","text":"25#100#200#50"},
{"testId":1059,"qid":25779,"description":"Up to _____ hours of community service are among the criminal punishments for a second DUI conviction.","explanation":null,"imageName":"","correctAnswer":7570,"isBookmarked":0,"aid":"6425#6591#7570#7571","text":"25#100#200#50"},
{"testId":592,"qid":25780,"description":"The criminal penalty for a second DUI conviction include up to _______ in prison or house arrest.","explanation":"A second DUI conviction within seven years carries a prison term or home confinement (\"house arrest\") of between 10 days and six months. If the motorist is permitted to get alcohol or drug treatment, the punishment may be lowered. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20790#23153#25031","text":"2 years#4 years#6 months#18 months"},
{"testId":1059,"qid":25780,"description":"The criminal penalty for a second DUI conviction include up to _______ in prison or house arrest.","explanation":"A second DUI conviction within seven years carries a prison term or home confinement (\"house arrest\") of between 10 days and six months. If the motorist is permitted to get alcohol or drug treatment, the punishment may be lowered. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20790#23153#25031","text":"2 years#4 years#6 months#18 months"},
{"testId":592,"qid":25781,"description":"How long will your driver's license be revoked for a third DUI offense?","explanation":"Your driver's license will be terminated for three years if you have a third DUI conviction within seven years. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1059,"qid":25781,"description":"How long will your driver's license be revoked for a third DUI offense?","explanation":"Your driver's license will be terminated for three years if you have a third DUI conviction within seven years. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":592,"qid":25782,"description":"A third or subsequent DUI conviction carries a maximum fine of:","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25034#25036#34989","text":"$1,000#$3,000#$5,000#$11,000"},
{"testId":1059,"qid":25782,"description":"A third or subsequent DUI conviction carries a maximum fine of:","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25034#25036#34989","text":"$1,000#$3,000#$5,000#$11,000"},
{"testId":592,"qid":25783,"description":"A driver convicted of a third or subsequent DUI offense may face imprisonment of  up to _______ .","explanation":"A motorist who is convicted of a third or subsequent DUI offense within seven years will face a jail term of one to six years. If the motorist is permitted to get alcohol or drug treatment, the punishment may be lowered. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":12727,"isBookmarked":0,"aid":"11285#11432#12448#12727","text":"2 years#4 years#8 years#6 years"},
{"testId":1059,"qid":25783,"description":"A driver convicted of a third or subsequent DUI offense may face imprisonment of  up to _______ .","explanation":"A motorist who is convicted of a third or subsequent DUI offense within seven years will face a jail term of one to six years. If the motorist is permitted to get alcohol or drug treatment, the punishment may be lowered. [Criminal Action, DUI Penalties, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence], [NRS 484C] .400]","imageName":"","correctAnswer":12727,"isBookmarked":0,"aid":"11285#11432#12448#12727","text":"2 years#4 years#8 years#6 years"},
{"testId":592,"qid":25784,"description":"Which of the following criminal punishments may be imposed for a DUI that caused an accident in which a person was killed or seriously injured?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34990#34991#34992","text":"All of the above#License revocation for three years#Twenty years in prison#A $5,000 fine"},
{"testId":1059,"qid":25784,"description":"Which of the following criminal punishments may be imposed for a DUI that caused an accident in which a person was killed or seriously injured?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34990#34991#34992","text":"All of the above#License revocation for three years#Twenty years in prison#A $5,000 fine"},
{"testId":592,"qid":25785,"description":"If a driver under the age of 18 is convicted of DUI, his or her license will be suspended for a period of:","explanation":"If a motorist under the age of 18 is convicted of driving under the influence in a juvenile court, his or her license will be revoked for 90 days. [DUI Laws for Young Drivers, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1059,"qid":25785,"description":"If a driver under the age of 18 is convicted of DUI, his or her license will be suspended for a period of:","explanation":"If a motorist under the age of 18 is convicted of driving under the influence in a juvenile court, his or her license will be revoked for 90 days. [DUI Laws for Young Drivers, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":592,"qid":25786,"description":"If you're convicted of DUI with passenger of under ______聽age, the court will consider this an aggravating factor.","explanation":"If you are guilty of DUI with a passenger under the age of 15, the court will consider this an aggravating factor in calculating your sentence. (An aggravating factor is anything that makes a heavier penalty more likely.) [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11360#11361","text":"15#18#21#16"},
{"testId":1059,"qid":25786,"description":"If you're convicted of DUI with passenger of under ______聽age, the court will consider this an aggravating factor.","explanation":"If you are guilty of DUI with a passenger under the age of 15, the court will consider this an aggravating factor in calculating your sentence. (An aggravating factor is anything that makes a heavier penalty more likely.) [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11360#11361","text":"15#18#21#16"},
{"testId":592,"qid":25787,"description":"If you're convicted of DUI with a chemical test, you'll be fined _____ to cover the cost.","explanation":"If you are convicted of DUI after taking a drug or alcohol test, you will be fined $60 to reimburse the expense of the test. [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":12394,"isBookmarked":0,"aid":"11348#11349#12394#13128","text":"$25#$50#$60#$30"},
{"testId":1059,"qid":25787,"description":"If you're convicted of DUI with a chemical test, you'll be fined _____ to cover the cost.","explanation":"If you are convicted of DUI after taking a drug or alcohol test, you will be fined $60 to reimburse the expense of the test. [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":12394,"isBookmarked":0,"aid":"11348#11349#12394#13128","text":"$25#$50#$60#$30"},
{"testId":592,"qid":25788,"description":"Which of the following may result in a license suspension or revocation in Nevada?","explanation":"A broad range of offenses may result in license revocation or suspension. Failure to keep your car insured, failure to pay child support, and failure to pay a traffic ticket or appear in court as needed are all examples. [Nevada Driver's Handbook, Chapter 10: License Suspensions and Revocations]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34993#34994#34995","text":"All of the above#Failing to keep your vehicle insured#Failing to pay child support#Failing to pay a traffic ticket"},
{"testId":1059,"qid":25788,"description":"Which of the following may result in a license suspension or revocation in Nevada?","explanation":"A broad range of offenses may result in license revocation or suspension. Failure to keep your car insured, failure to pay child support, and failure to pay a traffic ticket or appear in court as needed are all examples. [Nevada Driver's Handbook, Chapter 10: License Suspensions and Revocations]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34993#34994#34995","text":"All of the above#Failing to keep your vehicle insured#Failing to pay child support#Failing to pay a traffic ticket"},
{"testId":592,"qid":25789,"description":"Which of the following is grounds for revocation of driving privileges?","explanation":"Note that accumulating eight points in twelve months will not result in loss of driving privileges, but drag racing and unauthorized street racing are eight-point offenses that will. [Chapter 10: License Suspensions and Revocations, Nevada Driver's Handbook], [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints .htm]","imageName":"","correctAnswer":34996,"isBookmarked":0,"aid":"897#18625#34996#34997","text":"None of the above#Speeding#Unauthorized street racing#Accumulating eight points in twelve months"},
{"testId":1059,"qid":25789,"description":"Which of the following is grounds for revocation of driving privileges?","explanation":"Note that accumulating eight points in twelve months will not result in loss of driving privileges, but drag racing and unauthorized street racing are eight-point offenses that will. [Chapter 10: License Suspensions and Revocations, Nevada Driver's Handbook], [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints .htm]","imageName":"","correctAnswer":34996,"isBookmarked":0,"aid":"897#18625#34996#34997","text":"None of the above#Speeding#Unauthorized street racing#Accumulating eight points in twelve months"},
{"testId":592,"qid":25790,"description":"In which of the following areas you are NOT allowed to have open containers of alcohol?","explanation":"The presence of open containers of alcohol within reach of the driver or passengers is prohibited under Nevada's open container statute. Limousines, taxis, motor homes, buses, and other commercial vehicles that carry people are exempt. [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34999,"isBookmarked":0,"aid":"34998#34999#35000#35001","text":"The passenger area of a limousine#The passenger area of a passenger car#The living quarters of a motor home#The trunk of a passenger car"},
{"testId":1059,"qid":25790,"description":"In which of the following areas you are NOT allowed to have open containers of alcohol?","explanation":"The presence of open containers of alcohol within reach of the driver or passengers is prohibited under Nevada's open container statute. Limousines, taxis, motor homes, buses, and other commercial vehicles that carry people are exempt. [Other DUI Laws, Nevada Driver's Handbook, Chapter 9: Driving Under the Influence]","imageName":"","correctAnswer":34999,"isBookmarked":0,"aid":"34998#34999#35000#35001","text":"The passenger area of a limousine#The passenger area of a passenger car#The living quarters of a motor home#The trunk of a passenger car"},
{"testId":592,"qid":25791,"description":"A motorist with an instruction permit must drive under the supervision of a person who:","explanation":"An instruction permit holder must be monitored at all times when driving. The driving supervisor must be above the age of 21, have at least one year of licensed driving experience, and sit next to the driver. [Instruction Permits, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35002#35003#35004#35005","text":"is at least 21 years of age#has held a license for at least one year#is seated next to the driver#meets all of the above requirements"},
{"testId":592,"qid":25792,"description":"Before applying for a driver's license, a driver under the age of 18 must have held an instruction permit for at least :","explanation":"Before applying for a driver's license, a motorist under the age of 18 must possess an instruction permit for at least six months. A motorist who is at least 18 years old must also receive an instruction permission before applying for a driver's license, however there is no time limit on how long the permit must be held. [Instruction Permits, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":592,"qid":25793,"description":"A 16- or 17-year-old driver who obtains a license will be prohibited from transporting unrelated passengers under the age of 18 for the following:","explanation":"A 16- or 17-year-old driver who obtains a license will be prohibited from transporting passengers under the age of 18 for the following six months. There are exceptions for members of the driver's close family. [Passenger Restrictions and Curfews, Nevada Driver's Handbook, Chapter 1: Getting Your Nevada Driver's License]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23886#25169","text":"2 years#6 months#30 days#12 months"},
{"testId":1059,"qid":25793,"description":"A 16- or 17-year-old driver who obtains a license will be prohibited from transporting unrelated passengers under the age of 18 for the following:","explanation":"A 16- or 17-year-old driver who obtains a license will be prohibited from transporting passengers under the age of 18 for the following six months. There are exceptions for members of the driver's close family. [Passenger Restrictions and Curfews, Nevada Driver's Handbook, Chapter 1: Getting Your Nevada Driver's License]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23886#25169","text":"2 years#6 months#30 days#12 months"},
{"testId":592,"qid":25794,"description":"A Nevada motorist under the age of 18 is not permitted to drive between the hours of 10 p.m. and:","explanation":"A Nevada motorist under the age of 18 is not permitted to drive between the hours of 10:00 p.m. and 5:00 a.m. unless driving to or from a planned function such as employment or school. [Passenger Restrictions and Curfews, Nevada Driver's Handbook, Chapter 1: Getting Your Nevada Driver's License]","imageName":"","correctAnswer":13578,"isBookmarked":0,"aid":"13578#13579#13580#35006","text":"5:00 a.m#4:00 a.m#2:00 a.m#midnight"},
{"testId":592,"qid":25795,"description":"The regular noncommercial driver's license in Nevada for passenger vehicles, vans, and pickup trucks is:","explanation":"The regular noncommercial driver's license in Nevada is the Class C license. This is the kind of license required to drive passenger vehicles, vans, and pickup trucks. Classifications A and B are commercial driver's license (CDL) classes in Nevada for operating commercial motor vehicles (CMVs) such as trucks and buses. [Driver's License Classifications, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":34472,"isBookmarked":0,"aid":"34470#34471#34472#34473","text":"Class A#Class B#Class C#Class D"},
{"testId":594,"qid":25796,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. It usually takes around an hour to break down the alcohol in one \"normal\" drink. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [How Much Is Too Much? ; Chapter 6: Drugs, Alcohol, and Health; The New Jersey Driver Manual]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#25081#40345#45587","text":"Coffee#A cold shower#The passage of time#A large meal"},
{"testId":1061,"qid":25796,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. It usually takes around an hour to break down the alcohol in one \"normal\" drink. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [How Much Is Too Much? ; Chapter 6: Drugs, Alcohol, and Health; The New Jersey Driver Manual]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#25081#40345#45587","text":"Coffee#A cold shower#The passage of time#A large meal"},
{"testId":594,"qid":25797,"description":"Underage drivers are prohibited from having a blood alcohol content (BAC) of _____ or higher.","explanation":"Driving with a BAC of 0.01 percent or above is banned for anybody under the age of 21. The consequences might be catastrophic (N.J.S.A. 39:4-50.14). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":4653,"isBookmarked":0,"aid":"4652#4653#25888#25889","text":"0.00%#0.01%#0.002%#0.001%"},
{"testId":1061,"qid":25797,"description":"Underage drivers are prohibited from having a blood alcohol content (BAC) of _____ or higher.","explanation":"Driving with a BAC of 0.01 percent or above is banned for anybody under the age of 21. The consequences might be catastrophic (N.J.S.A. 39:4-50.14). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":4653,"isBookmarked":0,"aid":"4652#4653#25888#25889","text":"0.00%#0.01%#0.002%#0.001%"},
{"testId":594,"qid":25798,"description":"A driver's likelihood of causing an accident _________  if his or her blood alcohol concentration (BAC) is somewhat more than 0.05 percent.","explanation":"According to the New Jersey Driver Manual, a driver's chances of causing an accident treble if his or her BAC is marginally more than 0.05 percent. It should be noted that this figure is lower than the legal limit for adults. [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":35971,"isBookmarked":0,"aid":"35971#35972#35973#35974","text":"doubles#triples#increases by 50%#increases by 25%"},
{"testId":594,"qid":25799,"description":"A driver with a blood alcohol concentration (BAC) of 0.15 percent is ___ times more likely to cause an accident.","explanation":"A motorist with a BAC of 0.15 percent is 25 times more likely to cause an accident, according to the New Jersey Driver Manual. [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5448#6425#7571","text":"10#20#25#50"},
{"testId":594,"qid":25800,"description":"Which of the following punishments will you face if you refuse to undergo a breath test in New Jersey?","explanation":"Refusing to take a breath test is against the law in New Jersey. Loss of driving privileges, fines, referral to an Intoxicated Driver Resource Center (IDRC), and installation of an ignition interlock device are among the penalties. Drivers who refuse to undergo a breath test in New Jersey face a violation fee. Failure to pay the fee will result in the suspension of driving privileges indefinitely until the surcharge is paid. [How Much Is Too Much? ; Chapter 6: Drugs, Alcohol, and Health; The New Jersey Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#26994#45596","text":"All of the above#A fine#Loss of driving privileges#Required installation of an ignition interlock device in your vehicle"},
{"testId":594,"qid":25801,"description":"Your driver's license may be suspended for up to ________, if you refuse to submit to a chemical test.","explanation":"Refusing to submit to chemical testing carries the same penalties as a first DUI offense with a BAC of 0.10 percent. (The legal limit for adults is less than 0.08 percent.) These penalties include license revocation for seven months to one year (N.J.S.A. 39:4-50.4a) as well as a $1,000 per year violation fee for three years (N.J.S.A. 17:29A-35). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":594,"qid":25802,"description":"Those who refuse to submit to chemical testing are subject to a fine of ______ each year for the next three years.","explanation":"Refusing to submit to chemical testing carries the same penalties as a first DUI offense with a BAC of 0.10 percent. (The legal limit for adults is less than 0.08 percent.) These penalties include license revocation for seven months to one year (N.J.S.A. 39:4-50.4a) as well as a $1,000 per year violation fee for three years (N.J.S.A. 17:29A-35). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11349#11832","text":"$500#$1,000#$50#$100"},
{"testId":1061,"qid":25802,"description":"Those who refuse to submit to chemical testing are subject to a fine of ______ each year for the next three years.","explanation":"Refusing to submit to chemical testing carries the same penalties as a first DUI offense with a BAC of 0.10 percent. (The legal limit for adults is less than 0.08 percent.) These penalties include license revocation for seven months to one year (N.J.S.A. 39:4-50.4a) as well as a $1,000 per year violation fee for three years (N.J.S.A. 17:29A-35). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11349#11832","text":"$500#$1,000#$50#$100"},
{"testId":594,"qid":25803,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises when they are impaired while walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents were legally drunk, compared to 37 percent of pedestrians who died in similar crashes. [\"Traffic Safety Facts: Pedestrians,\" National Highway Traffic Safety Administration, August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748], \"How Much is Too Much?, Chapter 6: Drinking, Drugs, and Health, The New Jersey Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35976#35977","text":"All of the above#taking a taxi#taking public transportation#riding with a designated driver"},
{"testId":1061,"qid":25803,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises when they are impaired while walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents were legally drunk, compared to 37 percent of pedestrians who died in similar crashes. [\"Traffic Safety Facts: Pedestrians,\" National Highway Traffic Safety Administration, August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748], \"How Much is Too Much?, Chapter 6: Drinking, Drugs, and Health, The New Jersey Driver Manual]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35976#35977","text":"All of the above#taking a taxi#taking public transportation#riding with a designated driver"},
{"testId":594,"qid":25804,"description":"Which of the following can result in a DUI conviction?","explanation":"A motorist may be charged with DUI in New Jersey if he or she is under the influence of any alcoholic liquor, narcotic, hallucinogenic substance, or habit-forming drug (N.J.S.A. 39.4-50). Some prescription drugs and even some over-the-counter treatments in large amounts fall into this category. [Drugs and Driving, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":1061,"qid":25804,"description":"Which of the following can result in a DUI conviction?","explanation":"A motorist may be charged with DUI in New Jersey if he or she is under the influence of any alcoholic liquor, narcotic, hallucinogenic substance, or habit-forming drug (N.J.S.A. 39.4-50). Some prescription drugs and even some over-the-counter treatments in large amounts fall into this category. [Drugs and Driving, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#8071#29384","text":"All of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":594,"qid":25805,"description":"The second most prevalent substance taken by drivers involved in accidents, after alcohol, is:","explanation":"Marijuana is the second most often used substance by drivers involved in accidents, behind alcohol. Marijuana is authorized in New Jersey for some medicinal uses but not for recreational use. [Drugs and Driving, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":27076,"isBookmarked":0,"aid":"27076#35978#35979#35980","text":"marijuana#heroin#allergy medication#pain relievers"},
{"testId":1061,"qid":25805,"description":"The second most prevalent substance taken by drivers involved in accidents, after alcohol, is:","explanation":"Marijuana is the second most often used substance by drivers involved in accidents, behind alcohol. Marijuana is authorized in New Jersey for some medicinal uses but not for recreational use. [Drugs and Driving, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":27076,"isBookmarked":0,"aid":"27076#35978#35979#35980","text":"marijuana#heroin#allergy medication#pain relievers"},
{"testId":594,"qid":25806,"description":"A habitual offender is ________ in New Jersey.","explanation":null,"imageName":"","correctAnswer":45628,"isBookmarked":0,"aid":"45627#45628#45629#45630","text":"a driver who was found guilty of five traffic offenses in five years#a driver whose license was suspended three times in three years#a driver who was found guilty of four traffic offenses in five years#a driver whose license was suspended three times in five years"},
{"testId":1061,"qid":25806,"description":"A habitual offender is ________ in New Jersey.","explanation":null,"imageName":"","correctAnswer":45628,"isBookmarked":0,"aid":"45627#45628#45629#45630","text":"a driver who was found guilty of five traffic offenses in five years#a driver whose license was suspended three times in three years#a driver who was found guilty of four traffic offenses in five years#a driver whose license was suspended three times in five years"},
{"testId":594,"qid":25807,"description":"Which of the following does NOT normally result in the termination of driving privileges in New Jersey?","explanation":"You may lose your driving privileges if you use drugs or alcohol, fail to present evidence of vehicle insurance, drive while your license is suspended, or are at fault in a fatal accident. Each conviction for speeding will result in two to five points. Your license may be suspended if you acquire twelve points. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, The Driving Privilege]","imageName":"","correctAnswer":35981,"isBookmarked":0,"aid":"27919#33105#35981#44219","text":"Driving with a suspended license#Failure to provide proof of insurance#One conviction for speeding#Being at fault in a fatal crash"},
{"testId":1061,"qid":25807,"description":"Which of the following does NOT normally result in the termination of driving privileges in New Jersey?","explanation":"You may lose your driving privileges if you use drugs or alcohol, fail to present evidence of vehicle insurance, drive while your license is suspended, or are at fault in a fatal accident. Each conviction for speeding will result in two to five points. Your license may be suspended if you acquire twelve points. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, The Driving Privilege]","imageName":"","correctAnswer":35981,"isBookmarked":0,"aid":"27919#33105#35981#44219","text":"Driving with a suspended license#Failure to provide proof of insurance#One conviction for speeding#Being at fault in a fatal crash"},
{"testId":594,"qid":25808,"description":"Which of the following beverages has the most alcohol?","explanation":null,"imageName":"","correctAnswer":45586,"isBookmarked":0,"aid":"36436#36437#36438#45586","text":"A 12-ounce glass of 5% beer#A 5-ounce glass of 12% wine#A 1.5-ounce shot of 80-proof liquor#None (i.e., they all have the same amount of alcohol)"},
{"testId":1061,"qid":25808,"description":"Which of the following beverages has the most alcohol?","explanation":null,"imageName":"","correctAnswer":45586,"isBookmarked":0,"aid":"36436#36437#36438#45586","text":"A 12-ounce glass of 5% beer#A 5-ounce glass of 12% wine#A 1.5-ounce shot of 80-proof liquor#None (i.e., they all have the same amount of alcohol)"},
{"testId":594,"qid":25809,"description":"If you are guilty of DUI for the first time, your driver license will be suspended for up to _______.","explanation":"If you are convicted of your first DUI with a BAC of at least 0.08 percent but less than 0.10 percent, your driving license will be suspended for three months. Your license will be suspended for seven months to one year if your BAC was 0.10 percent or above. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":594,"qid":25810,"description":"If convicted of DUI for the first time, you might face up to _______ in jail.","explanation":"If you are convicted of your first DUI conviction, you might face up to 30 days in prison. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":1061,"qid":25810,"description":"If convicted of DUI for the first time, you might face up to _______ in jail.","explanation":"If you are convicted of your first DUI conviction, you might face up to 30 days in prison. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":594,"qid":25811,"description":"First-time DUI offenders must pay which fees?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35985#35986#35987","text":"All of the above#$100 to the drunk driving fund#$75 to Safe Neighborhood Services#$100 to the Alcohol Education and Rehabilitation Fund (AERF)"},
{"testId":1061,"qid":25811,"description":"First-time DUI offenders must pay which fees?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35985#35986#35987","text":"All of the above#$100 to the drunk driving fund#$75 to Safe Neighborhood Services#$100 to the Alcohol Education and Rehabilitation Fund (AERF)"},
{"testId":594,"qid":25812,"description":"A DUI offense is considered a second offense in New Jersey if it happens within ___ years of the first violation.","explanation":"A DUI offense is considered a second offense in New Jersey if it happens within 10 years after the first violation. Similarly, a third DUI offense is considered if it happens within 10 years after the second offense. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":1061,"qid":25812,"description":"A DUI offense is considered a second offense in New Jersey if it happens within ___ years of the first violation.","explanation":"A DUI offense is considered a second offense in New Jersey if it happens within 10 years after the first violation. Similarly, a third DUI offense is considered if it happens within 10 years after the second offense. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":594,"qid":25813,"description":"If you are convicted of DUI violation for the second time, your license will be revoked for:","explanation":"Your license will be suspended for two years if you are convicted of a second DUI conviction. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1061,"qid":25813,"description":"If you are convicted of DUI violation for the second time, your license will be revoked for:","explanation":"Your license will be suspended for two years if you are convicted of a second DUI conviction. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":594,"qid":25815,"description":"The maximum penalty for getting an unauthorized license is:","explanation":"If you get a driver's license unlawfully, you might face a $500 fine and/or jail. [The New Jersey Driver Manual, Chapter 1: The New Jersey Driver License System, Digital Driver License]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24977#24978","text":"$500#$1,000#$200#$100"},
{"testId":1061,"qid":25815,"description":"The maximum penalty for getting an unauthorized license is:","explanation":"If you get a driver's license unlawfully, you might face a $500 fine and/or jail. [The New Jersey Driver Manual, Chapter 1: The New Jersey Driver License System, Digital Driver License]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24977#24978","text":"$500#$1,000#$200#$100"},
{"testId":594,"qid":25816,"description":"If convicted of DUI offense for the 3rd time, your driver's license will be revoked for:","explanation":"Your license will be suspended for 10 years if you are convicted of a third DUI conviction. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#26672#28011#29895","text":"2 years#5 years#15 years#10 years"},
{"testId":1061,"qid":25816,"description":"If convicted of DUI offense for the 3rd time, your driver's license will be revoked for:","explanation":"Your license will be suspended for 10 years if you are convicted of a third DUI conviction. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#26672#28011#29895","text":"2 years#5 years#15 years#10 years"},
{"testId":594,"qid":25817,"description":"If convicted of DUI offense for the 3rd time, you will face jail for:","explanation":"If you are convicted of your third DUI, you will be sentenced to 180 days in prison. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"24048#24049#25857#25858","text":"60 days#90 days#120 days#180 days"},
{"testId":1061,"qid":25817,"description":"If convicted of DUI offense for the 3rd time, you will face jail for:","explanation":"If you are convicted of your third DUI, you will be sentenced to 180 days in prison. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"24048#24049#25857#25858","text":"60 days#90 days#120 days#180 days"},
{"testId":594,"qid":25818,"description":"If convicted of a third DUI offense, you will be required to pay a ______ per year violation surcharge for three years.","explanation":"If you are convicted of a third DUI conviction, you must pay a $1,500 violation fee every year for three years. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":11668,"isBookmarked":0,"aid":"3195#11658#11668#11669","text":"$5,000#$10,000#$1,500#$2,000"},
{"testId":1061,"qid":25818,"description":"If convicted of a third DUI offense, you will be required to pay a ______ per year violation surcharge for three years.","explanation":"If you are convicted of a third DUI conviction, you must pay a $1,500 violation fee every year for three years. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":11668,"isBookmarked":0,"aid":"3195#11658#11668#11669","text":"$5,000#$10,000#$1,500#$2,000"},
{"testId":594,"qid":25819,"description":"If you are guilty of drinking alcohol while driving for the first time, you will be fined:","explanation":"If you are convicted of consuming alcohol while driving, you will be fined $200 for the first offense and $250 for the second offense. This is in addition to any penalties you may face if you are also found guilty of DUI. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11349#11832#12357#12358","text":"$50#$100#$150#$200"},
{"testId":1061,"qid":25819,"description":"If you are guilty of drinking alcohol while driving for the first time, you will be fined:","explanation":"If you are convicted of consuming alcohol while driving, you will be fined $200 for the first offense and $250 for the second offense. This is in addition to any penalties you may face if you are also found guilty of DUI. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"11349#11832#12357#12358","text":"$50#$100#$150#$200"},
{"testId":594,"qid":25820,"description":"A second DUI conviction requires __ days of community service.","explanation":"If you are convicted of a second conviction of drinking and driving, you must do 10 days of community service and pay a $250 fine. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#5449#6477","text":"10#20#5#30"},
{"testId":1061,"qid":25820,"description":"A second DUI conviction requires __ days of community service.","explanation":"If you are convicted of a second conviction of drinking and driving, you must do 10 days of community service and pay a $250 fine. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#5449#6477","text":"10#20#5#30"},
{"testId":594,"qid":25821,"description":"If convicted of driving with a suspended license because of a DUI,  you will have your license suspended for at least another :","explanation":null,"imageName":"","correctAnswer":1319,"isBookmarked":0,"aid":"1272#1319#11285#11341","text":"6 months#year#2 years#5 years"},
{"testId":1061,"qid":25821,"description":"If convicted of driving with a suspended license because of a DUI,  you will have your license suspended for at least another :","explanation":null,"imageName":"","correctAnswer":1319,"isBookmarked":0,"aid":"1272#1319#11285#11341","text":"6 months#year#2 years#5 years"},
{"testId":594,"qid":25822,"description":"If convicted of driving without liability insurance for the first time, your license will be suspended for _______ days.","explanation":"If you are convicted of driving without liability insurance for the first time, your license will be revoked for one year. This suspension term, however, may be shortened or abolished if you produce confirmation to the court that you currently have liability insurance. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1061,"qid":25822,"description":"If convicted of driving without liability insurance for the first time, your license will be suspended for _______ days.","explanation":"If you are convicted of driving without liability insurance for the first time, your license will be revoked for one year. This suspension term, however, may be shortened or abolished if you produce confirmation to the court that you currently have liability insurance. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":594,"qid":25823,"description":"First-time offenders can be fined up to _____聽for driving without liability insurance.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#12395","text":"$500#$1,000#$100#$250"},
{"testId":1061,"qid":25823,"description":"First-time offenders can be fined up to _____聽for driving without liability insurance.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#12395","text":"$500#$1,000#$100#$250"},
{"testId":594,"qid":25824,"description":"If it's your second offense, you'll pay up to $2,00______聽for driving without insurance.","explanation":"If you are convicted of driving without liability insurance for the second time, you will be fined up to $5,000. You will also have to pay a $250 per year violation fee for the next three years. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#3195#11349#11668","text":"$500#$5,000#$50#$1,500"},
{"testId":1061,"qid":25824,"description":"If it's your second offense, you'll pay up to $2,00______聽for driving without insurance.","explanation":"If you are convicted of driving without liability insurance for the second time, you will be fined up to $5,000. You will also have to pay a $250 per year violation fee for the next three years. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#3195#11349#11668","text":"$500#$5,000#$50#$1,500"},
{"testId":594,"qid":25825,"description":"If you're caught driving without insurance twice, 聽you will be sentenced to jail for:","explanation":"If you are convicted of driving without liability insurance for the second time, you will be sentenced to 14 days in jail. You will also be required to do 30 days of community service. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":35989,"isBookmarked":0,"aid":"23883#34746#35988#35989","text":"10 days#3 days#7 days#14 days"},
{"testId":1061,"qid":25825,"description":"If you're caught driving without insurance twice, 聽you will be sentenced to jail for:","explanation":"If you are convicted of driving without liability insurance for the second time, you will be sentenced to 14 days in jail. You will also be required to do 30 days of community service. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":35989,"isBookmarked":0,"aid":"23883#34746#35988#35989","text":"10 days#3 days#7 days#14 days"},
{"testId":594,"qid":25826,"description":"If convicted of driving with drugs, your license will be suspended for:","explanation":"If you are caught driving while under the influence of narcotics, your license will be revoked for two years and you will be fined at least $50. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#24049#26672","text":"2 years#6 months#90 days#5 years"},
{"testId":1061,"qid":25826,"description":"If convicted of driving with drugs, your license will be suspended for:","explanation":"If you are caught driving while under the influence of narcotics, your license will be revoked for two years and you will be fined at least $50. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#24049#26672","text":"2 years#6 months#90 days#5 years"},
{"testId":594,"qid":25827,"description":"By driving on New Jersey's public highways, you imply assent to_______, the Implied Consent Law.","explanation":"The state of New Jersey has an implied consent legislation. This implies that by driving on New Jersey roads, you have implicitly consented to submit to a breath test administered by law police or hospital personnel after your arrest for drunk driving. If you refuse to undergo a breath test, you will be detained and sent to a hospital, where a blood sample may be collected. You will also suffer consequences. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Breath Test]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":594,"qid":25828,"description":"First-time DUI offenders may be required to install an ignition interlock device (IID) for at least:","explanation":"If you are convicted of DUI for the first time, the court may mandate that you install an ignition interlock device (IID) in each car you drive for at least six months, beginning when your license is restored. If your blood alcohol content (BAC) was 0.15 percent or greater at the time of the violation, you must have an IID installed. If your BAC exceeds a certain level, the IID will prohibit your car from starting. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Ignition Interlock Device]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":594,"qid":25830,"description":"It is unlawful to leave a motor vehicle on a limited-access roadway for _______ hours or more.","explanation":null,"imageName":"","correctAnswer":18590,"isBookmarked":0,"aid":"12827#13916#18590#18591","text":"24 hours#12 hours#4 hours#2 hours"},
{"testId":594,"qid":25831,"description":"You may be fined __________ if snow or ice falls from your moving car and injures someone or damages property.","explanation":null,"imageName":"","correctAnswer":35990,"isBookmarked":0,"aid":"35990#35991#35992#35993","text":"between $200 and $1,000#between $500 and $750#between $50 and $100#between $10 and $50"},
{"testId":594,"qid":25832,"description":"If a police officer asks you to do so during a traffic stop, you must turn on your interior lights. If you don't, you'll have to pay a penalty of _____.","explanation":"During a traffic stop, you must light the inside of your car if a law enforcement officer requests it. You will be fined $50 if you do not. [The New Jersey Driver Manual, Failure to Comply, Motor Vehicle Violations, Chapter 7: Driver Privileges and Penalties]","imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"23887#23889#24994#27888","text":"$500#$1,000#$50#$250"},
{"testId":594,"qid":25833,"description":"First-time hit-and-run offenders may face which of the following panelties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#13608#35994#35995","text":"All of the above#180 days in jail#A fine of up to $5,000#License suspension for one year"},
{"testId":594,"qid":25834,"description":"Which of the following scenarios could result in a teen's driver license being suspended or postponed?","explanation":null,"imageName":"","correctAnswer":44220,"isBookmarked":0,"aid":"897#23116#33112#44220","text":"None of the above#Suspension from school#Poor grades in school#Using a handheld or hands-free cellular telephone, when behind the wheel"},
{"testId":594,"qid":25835,"description":"The Motor Vehicle Commission will send you a Notice of Scheduled Suspension if you accumulate 12 to 14 points in traffic violations within:","explanation":"If you acquire between 12 and 14 points in a two-year period, the MVC will send you a Notice of Scheduled Suspension. At that time, you may either seek a hearing to dispute the suspension, enroll in a Driver Improvement Program, or accept the suspension and submit your license to the MVC. [Driver Improvement Program, Driver Programs, Chapter 7: Driver Privileges and Penalties, The New Jersey Driver Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":1061,"qid":25835,"description":"The Motor Vehicle Commission will send you a Notice of Scheduled Suspension if you accumulate 12 to 14 points in traffic violations within:","explanation":"If you acquire between 12 and 14 points in a two-year period, the MVC will send you a Notice of Scheduled Suspension. At that time, you may either seek a hearing to dispute the suspension, enroll in a Driver Improvement Program, or accept the suspension and submit your license to the MVC. [Driver Improvement Program, Driver Programs, Chapter 7: Driver Privileges and Penalties, The New Jersey Driver Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":594,"qid":25836,"description":"If you successfully complete an approved Driver Improvement Program, you can eliminate up to ___ points from your driving record.","explanation":"If you successfully complete an authorized Driver Improvement Program, you may be able to erase up to three points from your driving record. Making an incorrect turn is an example of a three-point infraction. [The New Jersey Driver Manual, Driver Improvement Program, Driver Programs, Chapter 7: Driver Privileges and Penalties]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":25836,"description":"If you successfully complete an approved Driver Improvement Program, you can eliminate up to ___ points from your driving record.","explanation":"If you successfully complete an authorized Driver Improvement Program, you may be able to erase up to three points from your driving record. Making an incorrect turn is an example of a three-point infraction. [The New Jersey Driver Manual, Driver Improvement Program, Driver Programs, Chapter 7: Driver Privileges and Penalties]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25838,"description":"Which one of the following have an impact on your blood alcohol content (BAC)?","explanation":"Your blood alcohol concentration (BAC) is governed by four factors: your body weight, the amount of alcohol consumed, the rate at which it is consumed, and if you eat anything while drinking. The greatest strategy to lessen the risk of a drunk driving accident is to not drive at all after drinking. [How Much Is Too Much? ; Chapter 6: Drugs, Alcohol, and Health; The New Jersey Driver Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#7290#32548#45585","text":"All of the above#How fast you drink#Your body weight#How much you drink"},
{"testId":594,"qid":25839,"description":"__ points will be added to your record if you fail to yield to a pedestrian in a crosswalk.","explanation":"In crosswalks, motorists must stop for pedestrians. Failure to stop may result in a $500 fine, up to 25 days in prison, community service, a 6-month suspension of your driving privileges, and 2 points. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules and Regulations, Pedestrians in a Crosswalk]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":25839,"description":"__ points will be added to your record if you fail to yield to a pedestrian in a crosswalk.","explanation":"In crosswalks, motorists must stop for pedestrians. Failure to stop may result in a $500 fine, up to 25 days in prison, community service, a 6-month suspension of your driving privileges, and 2 points. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules and Regulations, Pedestrians in a Crosswalk]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25840,"description":"On a one-way street, driving the wrong way will add ____points to your driving record.","explanation":"If you are convicted of driving the wrong way on a one-way street, you will get two points on your license. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":25840,"description":"On a one-way street, driving the wrong way will add ____points to your driving record.","explanation":"If you are convicted of driving the wrong way on a one-way street, you will get two points on your license. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25841,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":"If you are guilty of tailgating, you will get five points on your license. The maximum amount of points you may get for a single transgression is five. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"202001302249003541.jpg","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25842,"description":"Driving at such a slow speed that it obstructs or impedes traffic will add _____ points to driving record.","explanation":"Two points will be added to your driving record if you are guilty of driving so slowly that you block or hinder traffic. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":25842,"description":"Driving at such a slow speed that it obstructs or impedes traffic will add _____ points to driving record.","explanation":"Two points will be added to your driving record if you are guilty of driving so slowly that you block or hinder traffic. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25843,"description":"Failure to obey a peace officer's directives will lead to ___ points being added on your driving record.","explanation":"If you fail to respect a peace officer's instructions, two points will be added to your driving record. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":25843,"description":"Failure to obey a peace officer's directives will lead to ___ points being added on your driving record.","explanation":"If you fail to respect a peace officer's instructions, two points will be added to your driving record. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":594,"qid":25844,"description":"Which of the following violations will result in a doubled fine if found guilty?","explanation":"Speeding or other movement infractions in highway construction zones result in increased penalties. Reckless driving, careless driving, speeding, inappropriate passing, tailgating, incorrect turns, failure to respect traffic lanes, failure to observe a traffic signal or sign, and failing to heed an officer's directives are examples of infractions. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31011#44105#44106","text":"All of the above#Improper passing#Speeding in construction zones#Speeding in 65 mph speed zone"},
{"testId":1061,"qid":25844,"description":"Which of the following violations will result in a doubled fine if found guilty?","explanation":"Speeding or other movement infractions in highway construction zones result in increased penalties. Reckless driving, careless driving, speeding, inappropriate passing, tailgating, incorrect turns, failure to respect traffic lanes, failure to observe a traffic signal or sign, and failing to heed an officer's directives are examples of infractions. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#31011#44105#44106","text":"All of the above#Improper passing#Speeding in construction zones#Speeding in 65 mph speed zone"},
{"testId":594,"qid":25845,"description":"In a 65 mph speed zone, exceeding the posted speed limit by 10 mph or more will result in :","explanation":null,"imageName":"","correctAnswer":44104,"isBookmarked":0,"aid":"44101#44102#44103#44104","text":"a license suspension#a tripled fine#a doubled jail time#a doubled fine"},
{"testId":1061,"qid":25845,"description":"In a 65 mph speed zone, exceeding the posted speed limit by 10 mph or more will result in :","explanation":null,"imageName":"","correctAnswer":44104,"isBookmarked":0,"aid":"44101#44102#44103#44104","text":"a license suspension#a tripled fine#a doubled jail time#a doubled fine"},
{"testId":595,"qid":25846,"description":"A driver's license in New Mexico is valid for four or _______ years.","explanation":"A driver's license in New Mexico is generally valid for four years. You do, however, have the option of obtaining an eight-year license if you are neither younger than 21 nor older than 70 years old. You must renew your license every year if you are at least 79 years old. [The Driver License, New Mexico Driver Manual, Driver License Renewal]","imageName":"","correctAnswer":5001,"isBookmarked":0,"aid":"2755#2756#5000#5001","text":"ten#twelve#Six#eight"},
{"testId":595,"qid":25847,"description":"Your New Mexico driver's license can be renewed up to ____ days before or ____ days after it expires.","explanation":"A driving license in New Mexico may be renewed up to 90 days before or 30 days after it expires. [The Driver License, New Mexico Driver Manual, Driver License Renewal]","imageName":"","correctAnswer":13612,"isBookmarked":0,"aid":"13018#13020#13612#13613","text":"60; 90#30; 120#90; 30#60; 30"},
{"testId":595,"qid":25848,"description":"Which of the following could cause you to lose your driving privileges?","explanation":"DWI, refusing to submit to chemical testing for alcohol or drugs when requested by a police officer, leaving the scene of an accident, vehicular homicide, or simply accruing too many points on your driving record are some of the numerous offences that can result in you losing your driving privileges. [Keeping Your License, Your License, and the New Mexico Driver Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#33107#37455#37456","text":"Any of the above#Refusing to submit to chemical testing#Conviction for driving while intoxicated (DWI)#Conviction for leaving the scene of an accident"},
{"testId":1062,"qid":25848,"description":"Which of the following could cause you to lose your driving privileges?","explanation":"DWI, refusing to submit to chemical testing for alcohol or drugs when requested by a police officer, leaving the scene of an accident, vehicular homicide, or simply accruing too many points on your driving record are some of the numerous offences that can result in you losing your driving privileges. [Keeping Your License, Your License, and the New Mexico Driver Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#33107#37455#37456","text":"Any of the above#Refusing to submit to chemical testing#Conviction for driving while intoxicated (DWI)#Conviction for leaving the scene of an accident"},
{"testId":595,"qid":25849,"description":"Which of the following would NOT normally result in the loss of your driving privileges?","explanation":"Giving false information on your driver's licence application, using another person's licence to buy alcohol, and allowing someone else to use your licence are just a few of the many offences that may result in you losing your driving privileges. Your driving privileges won't be suspended for a single speeding ticket. However, up to eight points may be added to your driving record depending on where you were speeding and how fast you were going. Your driver's licence will be suspended after a year if you receive twelve or more points. Traffic Infractions, Rules of the Road, and Maintaining Your Driver's License are all topics covered in the New Mexico Driver Manual.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#37457#37458#37459","text":"Speeding#Providing false information on your application for a driver license#Using someone else's driver license to purchase alcohol#Letting someone else use your driver license"},
{"testId":595,"qid":25850,"description":"Which vehicle occupants in New Mexico are required to wear safety belts or appropriate kid restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"16365#26667#37460#37461","text":"The driver and all passengers#The driver and front-seat passengers#The driver and child passengers#Rear-seat passengers"},
{"testId":595,"qid":25851,"description":"Children under the age of _____ must be properly restrained in the vehicle at all times.","explanation":"All children under the age of 12 must wear adequate safety restraints while the vehicle is in motion, according to New Mexico law. Children under 24 months of age or weighing less than 60 pounds must be restrained in an authorized safety device. Children should be buckled up in the back seat. [The Driver License, New Mexico Driver Manual, Safety Belts and Child Restraints]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#5447#5449#6478","text":"10#15#5#12"},
{"testId":595,"qid":25852,"description":"As per the MVD data, alcohol is involved in around ____ of all fatal crashes.","explanation":"Alcohol is implicated in nearly 40% of all fatal collisions, according to the MVD. [Drinking and Driving, Driving Fitness, New Mexico Driver Manual], New Mexico Motor Vehicle Division, \"DWI Information,\" http://www.mvd.newmexico.gov/dwi-information .aspx]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#13619","text":"10%#40%#20%#60%"},
{"testId":1062,"qid":25852,"description":"As per the MVD data, alcohol is involved in around ____ of all fatal crashes.","explanation":"Alcohol is implicated in nearly 40% of all fatal collisions, according to the MVD. [Drinking and Driving, Driving Fitness, New Mexico Driver Manual], New Mexico Motor Vehicle Division, \"DWI Information,\" http://www.mvd.newmexico.gov/dwi-information .aspx]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#13619","text":"10%#40%#20%#60%"},
{"testId":595,"qid":25853,"description":"A person can become legally intoxicated with as few as _____ alcoholic drinks.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":595,"qid":25854,"description":"An average adult human body can expel _____ alcoholic drink(s) per hour.","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with a standard drink equaling 1.5 ounces of 80-proof liquor, 5 ounces of wine, or 12 ounces of beer. There is no way to raise that figure. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1062,"qid":25854,"description":"An average adult human body can expel _____ alcoholic drink(s) per hour.","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with a standard drink equaling 1.5 ounces of 80-proof liquor, 5 ounces of wine, or 12 ounces of beer. There is no way to raise that figure. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":595,"qid":25855,"description":"Which of the following are safer alternatives to driving when intoxicated?","explanation":"Utilizing a cab, taking public transit, or travelling with a designated driver are all safer alternatives than intoxicated driving. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual], [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Pedestrians,\" August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32245#32247#37462","text":"All of the above#Taking a taxi#Riding with a designated driver#Using public transportation"},
{"testId":1062,"qid":25855,"description":"Which of the following are safer alternatives to driving when intoxicated?","explanation":"Utilizing a cab, taking public transit, or travelling with a designated driver are all safer alternatives than intoxicated driving. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual], [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Pedestrians,\" August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32245#32247#37462","text":"All of the above#Taking a taxi#Riding with a designated driver#Using public transportation"},
{"testId":595,"qid":25856,"description":"A driver over the age of 21 with a blood alcohol content (BAC) of ______ or above is deemed legally inebriated and may be arrested.","explanation":"You may be arrested for driving with a blood alcohol level (BAC) of 0.08 percent or more. In New Mexico, if you are under 21, you may be arrested for driving with a BAC of 0.02 percent or more. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#11770","text":"0.08%#0.04%#0.02%#0.15%"},
{"testId":1062,"qid":25856,"description":"A driver over the age of 21 with a blood alcohol content (BAC) of ______ or above is deemed legally inebriated and may be arrested.","explanation":"You may be arrested for driving with a blood alcohol level (BAC) of 0.08 percent or more. In New Mexico, if you are under 21, you may be arrested for driving with a BAC of 0.02 percent or more. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#11770","text":"0.08%#0.04%#0.02%#0.15%"},
{"testId":595,"qid":25857,"description":"Which of the following is true for drivers under the age of 21 in New Mexico?","explanation":"Unless you are at least 21 years old, you are not permitted to purchase, drink, or be in possession of alcohol. If you are under the age of 21, you may be arrested in New Mexico for driving with a blood alcohol concentration (BAC) of 0.02 percent. One 12-ounce can of beer is enough to boost your BAC to at least 0.02 percent. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":23403,"isBookmarked":0,"aid":"23403#37463#37464#37465","text":"None of the above are true#They can buy alcohol, but they are not allowed to drink it#They can drink small amounts of alcohol but not while driving#They can't buy alcohol, but they can have it in their possession if they obtain it from someone else"},
{"testId":1062,"qid":25857,"description":"Which of the following is true for drivers under the age of 21 in New Mexico?","explanation":"Unless you are at least 21 years old, you are not permitted to purchase, drink, or be in possession of alcohol. If you are under the age of 21, you may be arrested in New Mexico for driving with a blood alcohol concentration (BAC) of 0.02 percent. One 12-ounce can of beer is enough to boost your BAC to at least 0.02 percent. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":23403,"isBookmarked":0,"aid":"23403#37463#37464#37465","text":"None of the above are true#They can buy alcohol, but they are not allowed to drink it#They can drink small amounts of alcohol but not while driving#They can't buy alcohol, but they can have it in their possession if they obtain it from someone else"},
{"testId":595,"qid":25858,"description":"You will lose your driver's license for ___________ if you refuse to be chemically tested for alcohol or drugs at the request of a law enforcement officer.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23153#23883#23886#26650","text":"6 months#10 days#30 days#One year"},
{"testId":595,"qid":25859,"description":"First-time DWI offenders face which penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18218#26643#26994","text":"All of the above#Jail#A fine#Loss of driving privileges"},
{"testId":595,"qid":25860,"description":"Which of the following has the power to suspend your driver's licence in the event that you are found guilty of DWI?","explanation":"All license revocations and suspensions in New Mexico are handled by the Motor Vehicle Division (MVD). Revocations and suspensions are administrative sanctions rather than criminal consequences. Whether or whether you are convicted in court, the MVD has the ability to cancel your license. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":37466,"isBookmarked":0,"aid":"34469#37466#37467#37468","text":"The courts#The Motor Vehicle Division (MVD)#The state police#Your automobile insurer"},
{"testId":595,"qid":25861,"description":"A driving while intoxicated (DWI) conviction will appear on your driving record for ____ years.","explanation":"A DWI conviction will stay on your driving record for 25 years. [Alcohol and the Law, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5449#6389#6425","text":"10#5#3#25"},
{"testId":1062,"qid":25861,"description":"A driving while intoxicated (DWI) conviction will appear on your driving record for ____ years.","explanation":"A DWI conviction will stay on your driving record for 25 years. [Alcohol and the Law, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5449#6389#6425","text":"10#5#3#25"},
{"testId":595,"qid":25862,"description":"If you've used ___________, you could be arrested for driving under the influence.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#35439#37469","text":"Any of the above#marijuana#prescription medications#over-the-counter allergy medications"},
{"testId":1062,"qid":25862,"description":"If you've used ___________, you could be arrested for driving under the influence.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#35439#37469","text":"Any of the above#marijuana#prescription medications#over-the-counter allergy medications"},
{"testId":595,"qid":25863,"description":"Before obtaining a driver's license, a person with epilepsy must be seizure-free for ___ months.","explanation":"Before obtaining a driver's license, a person with epilepsy must be seizure-free for six months. [Health, Driving Fitness, New Mexico Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":595,"qid":25865,"description":"When should you NOT stop in the event of an accident?","explanation":"If you are involved in an accident, you must come to a complete stop. If you were not involved in the accident but emergency professionals have not yet come and you are familiar with first aid, you may be able to assist the wounded until they arrive. Otherwise, continue driving. Rubbernecking (slowing down or stopping simply to look at the accident) clogs the road and may lead to another collision. [New Mexico Driver Manual, Accidents and Financial Responsibility Law]","imageName":"","correctAnswer":37475,"isBookmarked":0,"aid":"37473#37474#37475#37476","text":"If your vehicle was damaged#If you were directly involved and sustained injuries#Just to take a look#If emergency personnel haven't arrived"},
{"testId":1062,"qid":25865,"description":"When should you NOT stop in the event of an accident?","explanation":"If you are involved in an accident, you must come to a complete stop. If you were not involved in the accident but emergency professionals have not yet come and you are familiar with first aid, you may be able to assist the wounded until they arrive. Otherwise, continue driving. Rubbernecking (slowing down or stopping simply to look at the accident) clogs the road and may lead to another collision. [New Mexico Driver Manual, Accidents and Financial Responsibility Law]","imageName":"","correctAnswer":37475,"isBookmarked":0,"aid":"37473#37474#37475#37476","text":"If your vehicle was damaged#If you were directly involved and sustained injuries#Just to take a look#If emergency personnel haven't arrived"},
{"testId":595,"qid":25866,"description":"Who is required to carry vehicle liability insurance or the equivalent in New Mexico?","explanation":null,"imageName":"","correctAnswer":37477,"isBookmarked":0,"aid":"37477#37478#37479#37480","text":"All vehicle owners#Only vehicle owners under the age of 25#Only vehicle owners under the age of 18#Only the owners of vehicles that are less than ten years old"},
{"testId":595,"qid":25867,"description":"In New Mexico, your car liability insurance policy must include at least _______ in bodily injury coverage for one individual in any one collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1062,"qid":25867,"description":"In New Mexico, your car liability insurance policy must include at least _______ in bodily injury coverage for one individual in any one collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":595,"qid":25868,"description":"In New Mexico, your vehicle liability insurance policy must have at least _______ in bodily injury coverage for two or more people in any one collision.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#11659#12048","text":"$50,000#$30,000#$20,000#$25,000"},
{"testId":1062,"qid":25868,"description":"In New Mexico, your vehicle liability insurance policy must have at least _______ in bodily injury coverage for two or more people in any one collision.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#11659#12048","text":"$50,000#$30,000#$20,000#$25,000"},
{"testId":595,"qid":25870,"description":"Which of the following are the New Mexico Graduated Licensing System levels?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#37481#37482#37483","text":"All of the above#Instructional permit#Provisional driver license#Unrestricted driver license"},
{"testId":595,"qid":25871,"description":"While holding a learner's permit, your driving must be supervised by a driver who:","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35002#35005#37484#37485","text":"is at least 21 years of age#meets all of the above requirements#is licensed to drive that type of vehicle#is seated next to you while you're driving"},
{"testId":595,"qid":25872,"description":"To apply for an instructional permit or driver's license if you are under the age of _____, you must obtain signed approval from your parent or guardian.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":595,"qid":25873,"description":"If you are under the age of 18, you must have held an instructional permit for at least _______ before applying for a provisional driving license.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"230#742#1272#1320","text":"one year#30 days#6 months#two years"},
{"testId":595,"qid":25874,"description":"You may not drive ___________ when you have a provisional license unless accompanied by a licensed adult driver.","explanation":null,"imageName":"","correctAnswer":29251,"isBookmarked":0,"aid":"29250#29251#29252#37486","text":"between 10:00 p.m. and 6:00 a.m#between midnight and 5:00 a.m#between midnight and 7:00 a.m#between 10:00 p.m. and 5:00 a.m"},
{"testId":595,"qid":25875,"description":"While you have a temporary license, you may not transport more than _____ unrelated passengers under the age of 21, unless supervision of a licensed adult driver.","explanation":null,"imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":595,"qid":25876,"description":"To obtain a provisional driver license, you must complete at least _____ hours of supervised driving practice.","explanation":null,"imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#7571#8141#11513","text":"20#50#60#40"},
{"testId":595,"qid":25877,"description":"To apply for a provisional driver's license, you must be at least _____ years old and under the age of 18.","explanation":null,"imageName":"","correctAnswer":32241,"isBookmarked":0,"aid":"11359#11361#32240#32241","text":"18#16#16 1/2#15 1/2"},
{"testId":595,"qid":25878,"description":"If you are under the age of 18, you must keep a clean driving record for ___ days before applying for a provisional license.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":595,"qid":25879,"description":"Second-time DWI offenders face up to _____聽in jail.","explanation":null,"imageName":"","correctAnswer":37488,"isBookmarked":0,"aid":"37487#37488#37489#37490","text":"729 days#364 days#179 days#89 days"},
{"testId":595,"qid":25880,"description":"You may drive unattended at 3:00 a.m. if you have a provisional driver's license. if you are:","explanation":null,"imageName":"","correctAnswer":23040,"isBookmarked":0,"aid":"23040#37491#37492#37493","text":"Doing any of the above#driving to or from work#driving to or from school#driving because of a medical emergency"},
{"testId":595,"qid":25882,"description":"If you have a provisional driver license, you must keep your driving record clean for ___ days before applying for an unrestricted driver license.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":595,"qid":25883,"description":"Which of the following infractions has the most points?","explanation":"Each of these breaches carries the following amount of points: Passing a halted school bus that is picking up or dropping off students: six points. Four points for failing to cede the right-of-way to an emergency vehicle. Three points for failing to follow a traffic sign or signal. Three points for following the car in front of you too closely (tailgating). [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":37496,"isBookmarked":0,"aid":"37494#37495#37496#37497","text":"Tailgating (following the vehicle ahead too closely)#Failing to stop at a red traffic light#Passing a stopped school bus that is picking up or discharging passengers#Failing to yield the right-of-way to an emergency vehicle"},
{"testId":1062,"qid":25883,"description":"Which of the following infractions has the most points?","explanation":"Each of these breaches carries the following amount of points: Passing a halted school bus that is picking up or dropping off students: six points. Four points for failing to cede the right-of-way to an emergency vehicle. Three points for failing to follow a traffic sign or signal. Three points for following the car in front of you too closely (tailgating). [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":37496,"isBookmarked":0,"aid":"37494#37495#37496#37497","text":"Tailgating (following the vehicle ahead too closely)#Failing to stop at a red traffic light#Passing a stopped school bus that is picking up or discharging passengers#Failing to yield the right-of-way to an emergency vehicle"},
{"testId":595,"qid":25884,"description":"If you drive with a suspended or revoked license, you face up to _____in jail.","explanation":null,"imageName":"","correctAnswer":37488,"isBookmarked":0,"aid":"23886#24049#37488#37489","text":"30 days#90 days#364 days#179 days"},
{"testId":595,"qid":25885,"description":"You must file a written accident report if you are involved in an accident that causes at least $500 in property damage.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":37498,"isBookmarked":0,"aid":"37498#37499#37500#37501","text":"within 5 days#within 10 days#within 30 days#within 45 days"},
{"testId":595,"qid":25886,"description":"If you are involved in an accident that causes bodily harm to another person, you must tell the authorities.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":26708,"isBookmarked":0,"aid":"26708#37498#37499#37500","text":"immediately#within 5 days#within 10 days#within 30 days"},
{"testId":595,"qid":25887,"description":"Each child passenger under the age of ___ must be restrained in a rear-facing child passenger restraint system in the vehicle's rear seat, according to New Mexico law.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":595,"qid":25888,"description":"Your license will be suspended for ______, if you accumulate 12 or more points within a one-year period on your driving record.","explanation":"Your driving license will be suspended for one year if you acquire twelve or more points in a twelve-month period. [Traffic Violations, Road Rules, New Mexico Driver Manual]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"20788#23152#23153#26650","text":"2 years#3 months#6 months#One year"},
{"testId":595,"qid":25889,"description":"Which of the following two offenses has no points?","explanation":null,"imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#37502#37503","text":"Both (a) and (b)#Neither (a) nor (b)#(a) Driving a vehicle without liability insurance#(b) Driving an unregistered vehicle"},
{"testId":1062,"qid":25889,"description":"Which of the following two offenses has no points?","explanation":null,"imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#37502#37503","text":"Both (a) and (b)#Neither (a) nor (b)#(a) Driving a vehicle without liability insurance#(b) Driving an unregistered vehicle"},
{"testId":595,"qid":25890,"description":"Your driving record would聽have points added聽if you are found guilty of the following infraction:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#11944#37504#37505","text":"Any of the above#Speeding#failing to wear a safety belt#failing to yield the right-of-way"},
{"testId":595,"qid":25891,"description":"If you're caught driving 60 mph in a 30 mph zone in a residential neighborhood, you'll have ______ points added to your license.","explanation":"If you are guilty of exceeding the legal speed limit of 15, 30, or 75 mph by more than 26 mph, eight points will be added to your driving record. The maximum amount of points for any one infringement is eight. [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6388#6389#7620","text":"5#2#3#8"},
{"testId":1062,"qid":25891,"description":"If you're caught driving 60 mph in a 30 mph zone in a residential neighborhood, you'll have ______ points added to your license.","explanation":"If you are guilty of exceeding the legal speed limit of 15, 30, or 75 mph by more than 26 mph, eight points will be added to your driving record. The maximum amount of points for any one infringement is eight. [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6388#6389#7620","text":"5#2#3#8"},
{"testId":595,"qid":25892,"description":"Even ______ alcoholic drink (or drinks) can impair your ability to drive safely.","explanation":"One alcoholic beverage (such as 5 ounces of wine or 12 ounces of beer) may increase your blood alcohol concentration (BAC) to 0.02 percent or higher. Your visual system's capacity to monitor a moving object and your brain's ability to do two activities at once would be impaired at a BAC of 0.02 percent. [\"The ABCs of BAC,\" National Highway Traffic Safety Administration, https://www.nhtsa.gov/staticfiles/nti/pdf/809844-TheABCsOfBAC], [\"Effects of Alcohol, Drinking and Driving, Be In Shape to Drive, New Mexico Driver Manual] .pdf]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1062,"qid":25892,"description":"Even ______ alcoholic drink (or drinks) can impair your ability to drive safely.","explanation":"One alcoholic beverage (such as 5 ounces of wine or 12 ounces of beer) may increase your blood alcohol concentration (BAC) to 0.02 percent or higher. Your visual system's capacity to monitor a moving object and your brain's ability to do two activities at once would be impaired at a BAC of 0.02 percent. [\"The ABCs of BAC,\" National Highway Traffic Safety Administration, https://www.nhtsa.gov/staticfiles/nti/pdf/809844-TheABCsOfBAC], [\"Effects of Alcohol, Drinking and Driving, Be In Shape to Drive, New Mexico Driver Manual] .pdf]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":595,"qid":25893,"description":"One normal alcoholic drink is equivalent to:","explanation":"With only one drink, your blood alcohol level (BAC) might rise to 0.02 percent or higher. What exactly is \"one drink?\" A 1.5-ounce shot of 80-proof whiskey, a 5-ounce glass of wine, or a 12-ounce can of beer are called typical drinks. All of these beverages have the same amount of alcohol in them. One normal drink of alcohol may be broken down by an adult human body in one hour. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#37506#37507#37508","text":"Any of the above#a 1.5-ounce shot of 80-proof liquor#a 12-ounce can of beer#a 5-ounce glass of wine"},
{"testId":1062,"qid":25893,"description":"One normal alcoholic drink is equivalent to:","explanation":"With only one drink, your blood alcohol level (BAC) might rise to 0.02 percent or higher. What exactly is \"one drink?\" A 1.5-ounce shot of 80-proof whiskey, a 5-ounce glass of wine, or a 12-ounce can of beer are called typical drinks. All of these beverages have the same amount of alcohol in them. One normal drink of alcohol may be broken down by an adult human body in one hour. [Alcohol Effects, Drinking and Driving, Be Fit to Drive, New Mexico Driver Manual]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#37506#37507#37508","text":"Any of the above#a 1.5-ounce shot of 80-proof liquor#a 12-ounce can of beer#a 5-ounce glass of wine"},
{"testId":595,"qid":25894,"description":"__ points will be added to your driving record for failing to signal before turning.","explanation":"If you are guilty of failing to signal before turning, you will get two points on your license. The least amount of points for a single infraction is two. [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#7620","text":"5#2#3#8"},
{"testId":1062,"qid":25894,"description":"__ points will be added to your driving record for failing to signal before turning.","explanation":"If you are guilty of failing to signal before turning, you will get two points on your license. The least amount of points for a single infraction is two. [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#7620","text":"5#2#3#8"},
{"testId":595,"qid":25895,"description":"Which of the following is the LEAST SAFE substitute for drunk driving?","explanation":"If you are too inebriated to drive, have someone else drive for you: a bus driver, a designated driver, or a taxi driver. You should avoid walking to your location. Walking when inebriated increases the likelihood of getting involved in an accident. According to the National Highway Road Safety Administration (NHTSA), 37 percent of pedestrians killed in traffic accidents were legally inebriated, whereas only 13 percent of drivers were legally intoxicated. [\"Traffic Safety Facts: Pedestrians,\" National Highway Traffic Safety Administration, August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748], [\"Effects of Alcohol, Drinking and Driving, Be In Shape to Drive, New Mexico Driver Manual],","imageName":"","correctAnswer":37509,"isBookmarked":0,"aid":"32245#32247#37462#37509","text":"Taking a taxi#Riding with a designated driver#Using public transportation#Walking"},
{"testId":1062,"qid":25895,"description":"Which of the following is the LEAST SAFE substitute for drunk driving?","explanation":"If you are too inebriated to drive, have someone else drive for you: a bus driver, a designated driver, or a taxi driver. You should avoid walking to your location. Walking when inebriated increases the likelihood of getting involved in an accident. According to the National Highway Road Safety Administration (NHTSA), 37 percent of pedestrians killed in traffic accidents were legally inebriated, whereas only 13 percent of drivers were legally intoxicated. [\"Traffic Safety Facts: Pedestrians,\" National Highway Traffic Safety Administration, August 2013, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748], [\"Effects of Alcohol, Drinking and Driving, Be In Shape to Drive, New Mexico Driver Manual],","imageName":"","correctAnswer":37509,"isBookmarked":0,"aid":"32245#32247#37462#37509","text":"Taking a taxi#Riding with a designated driver#Using public transportation#Walking"},
{"testId":597,"qid":25896,"description":"According to the North Carolina Driver's Handbook, your chances of getting involved in an accident rise if your blood alcohol concentration (BAC) hits:","explanation":null,"imageName":"","correctAnswer":23192,"isBookmarked":0,"aid":"23192#23194#23318#28284","text":"0.05%#0.08%#0.02%#0.10%"},
{"testId":1064,"qid":25896,"description":"According to the North Carolina Driver's Handbook, your chances of getting involved in an accident rise if your blood alcohol concentration (BAC) hits:","explanation":null,"imageName":"","correctAnswer":23192,"isBookmarked":0,"aid":"23192#23194#23318#28284","text":"0.05%#0.08%#0.02%#0.10%"},
{"testId":597,"qid":25897,"description":"If you refuse to submit to drug or alcohol testing at the request of a police officer, your driver license will be suspended for at least ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1064,"qid":25897,"description":"If you refuse to submit to drug or alcohol testing at the request of a police officer, your driver license will be suspended for at least ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":597,"qid":25898,"description":"If your driver's license is revoked because you refused to submit to drug or alcohol testing, the DMV will suspend it for at least another _______ .","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#742#1272#1273","text":"90 days#30 days#6 months#12 months"},
{"testId":1064,"qid":25898,"description":"If your driver's license is revoked because you refused to submit to drug or alcohol testing, the DMV will suspend it for at least another _______ .","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#742#1272#1273","text":"90 days#30 days#6 months#12 months"},
{"testId":597,"qid":25899,"description":"If your license is revoked for refusing drug or alcohol testing, you may be eligible for limited driving privileges after:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"228#742#1272#1454","text":"120 days#30 days#6 months#9 months"},
{"testId":1064,"qid":25899,"description":"If your license is revoked for refusing drug or alcohol testing, you may be eligible for limited driving privileges after:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"228#742#1272#1454","text":"120 days#30 days#6 months#9 months"},
{"testId":597,"qid":25900,"description":"If your blood alcohol content (BAC) is 0.08 percent or more, your driver's license will be immediately canceled for at least ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1064,"qid":25900,"description":"If your blood alcohol content (BAC) is 0.08 percent or more, your driver's license will be immediately canceled for at least ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":597,"qid":25901,"description":"If _____, you can be charged with driving while impaired (DWI).","explanation":null,"imageName":"","correctAnswer":38061,"isBookmarked":0,"aid":"38061#38489#38619#38620","text":"either (a) or (b)#neither (a) nor (b)#(a) you are found to be driving with a blood alcohol concentration (BAC) of 0.08% or more#(b) you are found to be driving while your physical or mental abilities are impaired by alcohol or drugs"},
{"testId":1064,"qid":25901,"description":"If _____, you can be charged with driving while impaired (DWI).","explanation":null,"imageName":"","correctAnswer":38061,"isBookmarked":0,"aid":"38061#38489#38619#38620","text":"either (a) or (b)#neither (a) nor (b)#(a) you are found to be driving with a blood alcohol concentration (BAC) of 0.08% or more#(b) you are found to be driving while your physical or mental abilities are impaired by alcohol or drugs"},
{"testId":597,"qid":25902,"description":"If you are convicted of DWI for the_____ time, you must go to jail.","explanation":null,"imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1064,"qid":25902,"description":"If you are convicted of DWI for the_____ time, you must go to jail.","explanation":null,"imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":597,"qid":25903,"description":"Your automobile liability insurance policy in North Carolina must have at least _______ in coverage for injuries to one person in any one accident.","explanation":null,"imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11659#12047#12925","text":"$30,000#$20,000#$15,000#$60,000"},
{"testId":597,"qid":25904,"description":"If you're convicted of DWI with a revoked license due to a past DWI offense,:","explanation":null,"imageName":"","correctAnswer":38624,"isBookmarked":0,"aid":"38621#38622#38623#38624","text":"the revocation period will be doubled#your insurance policy will be canceled#you will be required to seek alcohol or drug abuse treatment#your vehicle may be seized and sold"},
{"testId":1064,"qid":25904,"description":"If you're convicted of DWI with a revoked license due to a past DWI offense,:","explanation":null,"imageName":"","correctAnswer":38624,"isBookmarked":0,"aid":"38621#38622#38623#38624","text":"the revocation period will be doubled#your insurance policy will be canceled#you will be required to seek alcohol or drug abuse treatment#your vehicle may be seized and sold"},
{"testId":597,"qid":25905,"description":"First-time DWI convictions result in聽license suspensions for:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":1064,"qid":25905,"description":"First-time DWI convictions result in聽license suspensions for:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":597,"qid":25907,"description":"Your license will be revoked_______ , if you're convicted of DWI for the third time and at least one of the prior convictions occurred within the last five years.","explanation":null,"imageName":"","correctAnswer":38625,"isBookmarked":0,"aid":"38625#38626#38627#38628","text":"permanently#for 5 years#for 10 years#for 15 years"},
{"testId":1064,"qid":25907,"description":"Your license will be revoked_______ , if you're convicted of DWI for the third time and at least one of the prior convictions occurred within the last five years.","explanation":null,"imageName":"","correctAnswer":38625,"isBookmarked":0,"aid":"38625#38626#38627#38628","text":"permanently#for 5 years#for 10 years#for 15 years"},
{"testId":597,"qid":25908,"description":"If you're convicted of DWI for the fourth time within __ years, it's a felony.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"7559#11342#11343#11783","text":"7#9#11#13"},
{"testId":1064,"qid":25908,"description":"If you're convicted of DWI for the fourth time within __ years, it's a felony.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"7559#11342#11343#11783","text":"7#9#11#13"},
{"testId":597,"qid":25909,"description":"After your first DWI conviction, you may not drive with a blood alcohol concentration (BAC) of _____ or higher.","explanation":null,"imageName":"","correctAnswer":3281,"isBookmarked":0,"aid":"3168#3281#3282#4652","text":"0.08%#0.04%#0.06%#0.00%"},
{"testId":1064,"qid":25909,"description":"After your first DWI conviction, you may not drive with a blood alcohol concentration (BAC) of _____ or higher.","explanation":null,"imageName":"","correctAnswer":3281,"isBookmarked":0,"aid":"3168#3281#3282#4652","text":"0.08%#0.04%#0.06%#0.00%"},
{"testId":597,"qid":25910,"description":"You may not drive with a blood alcohol concentration (BAC) of ____ or higher after regaining your driving privileges following your second DWI conviction.","explanation":null,"imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23318#27697#27698#31711","text":"0.02%#0.06%#0.04%#Zero"},
{"testId":597,"qid":25911,"description":"If you are convicted of DWI with a BAC of 0.15 percent or above, you will be obliged to:","explanation":null,"imageName":"","correctAnswer":38631,"isBookmarked":0,"aid":"10656#38629#38630#38631","text":"Do all of the above#purchase additional liability insurance#be supervised whenever you drive#install an ignition interlock device"},
{"testId":597,"qid":25912,"description":"If a North Carolina driver under the age of 21 is convicted of buying or attempting to buy alcohol, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23886#24049#25169","text":"6 months#30 days#90 days#12 months"},
{"testId":1064,"qid":25912,"description":"If a North Carolina driver under the age of 21 is convicted of buying or attempting to buy alcohol, his or her license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23886#24049#25169","text":"6 months#30 days#90 days#12 months"},
{"testId":597,"qid":25913,"description":"If you give your license to a minor to buy alcohol, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23886#24049#25169","text":"6 months#30 days#90 days#12 months"},
{"testId":1064,"qid":25913,"description":"If you give your license to a minor to buy alcohol, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23886#24049#25169","text":"6 months#30 days#90 days#12 months"},
{"testId":597,"qid":25914,"description":"You may be obliged to attend a driver improvement clinic if you acquire ___ points.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#7559#11342#11343","text":"5#7#9#11"},
{"testId":1064,"qid":25914,"description":"You may be obliged to attend a driver improvement clinic if you acquire ___ points.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5449#7559#11342#11343","text":"5#7#9#11"},
{"testId":597,"qid":25915,"description":"When you successfully complete a driver improvement clinic, _____ points will be deducted from your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1064,"qid":25915,"description":"When you successfully complete a driver improvement clinic, _____ points will be deducted from your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":25916,"description":"Which of the following statements is true?","explanation":null,"imageName":"","correctAnswer":41904,"isBookmarked":0,"aid":"32659#32660#41903#41904","text":"Both a) and b) are true#Neither a) nor b) is true#A) You may receive a warning letter after you accumulate 4 points within a three-year period#B) Your license may be suspended after you accumulate 12 points within a three-year period"},
{"testId":1064,"qid":25916,"description":"Which of the following statements is true?","explanation":null,"imageName":"","correctAnswer":41904,"isBookmarked":0,"aid":"32659#32660#41903#41904","text":"Both a) and b) are true#Neither a) nor b) is true#A) You may receive a warning letter after you accumulate 4 points within a three-year period#B) Your license may be suspended after you accumulate 12 points within a three-year period"},
{"testId":597,"qid":25917,"description":"If you restore your driving rights after being suspended for too many points, they may be revoked again if you acquire another ___ points within three years.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1064,"qid":25917,"description":"If you restore your driving rights after being suspended for too many points, they may be revoked again if you acquire another ___ points within three years.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":597,"qid":25920,"description":"Your license will be taken away for _____ if you get too many points.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":1064,"qid":25920,"description":"Your license will be taken away for _____ if you get too many points.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":597,"qid":25921,"description":"When your driving privileges are restored after a suspension or revocation, all of the driver license points you have accrued are:","explanation":null,"imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11437#38632#38633#38634","text":"canceled#cut in half#reduced by three quarters#reduced by one quarter"},
{"testId":1064,"qid":25921,"description":"When your driving privileges are restored after a suspension or revocation, all of the driver license points you have accrued are:","explanation":null,"imageName":"","correctAnswer":11437,"isBookmarked":0,"aid":"11437#38632#38633#38634","text":"canceled#cut in half#reduced by three quarters#reduced by one quarter"},
{"testId":597,"qid":25922,"description":"How many points will be added to your driving record, if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1064,"qid":25922,"description":"How many points will be added to your driving record, if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":25923,"description":"Aggressive driving convictions result in ______聽 points being added聽on your driving record.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1064,"qid":25923,"description":"Aggressive driving convictions result in ______聽 points being added聽on your driving record.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":25926,"description":"__ points will be added to your driving record for failing to yield to a pedestrian in a crosswalk.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1064,"qid":25926,"description":"__ points will be added to your driving record for failing to yield to a pedestrian in a crosswalk.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":597,"qid":25927,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1064,"qid":25927,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":597,"qid":25928,"description":"You'll get ____ points on your license for exceeding the 55 mph limit.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1064,"qid":25928,"description":"You'll get ____ points on your license for exceeding the 55 mph limit.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":597,"qid":25929,"description":"Which of the following offenses carries a three-point penalty?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26680#38635#38636","text":"All of the above#Running a red light#Failing to yield the right-of-way to a car#Failing to maintain liability insurance"},
{"testId":1064,"qid":25929,"description":"Which of the following offenses carries a three-point penalty?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26680#38635#38636","text":"All of the above#Running a red light#Failing to yield the right-of-way to a car#Failing to maintain liability insurance"},
{"testId":597,"qid":25931,"description":"Which of these convictions will add the LEAST number of points to your record?","explanation":null,"imageName":"","correctAnswer":38639,"isBookmarked":0,"aid":"38637#38638#38639#38640","text":"Speeding at 85 mph on an interstate highway#Failing to yield to a car#Littering#Failing to signal a turn"},
{"testId":1064,"qid":25931,"description":"Which of these convictions will add the LEAST number of points to your record?","explanation":null,"imageName":"","correctAnswer":38639,"isBookmarked":0,"aid":"38637#38638#38639#38640","text":"Speeding at 85 mph on an interstate highway#Failing to yield to a car#Littering#Failing to signal a turn"},
{"testId":597,"qid":25932,"description":"If you're caught speeding at 60mph on a 40mph road, you'll lose your license for at least __________.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1064,"qid":25932,"description":"If you're caught speeding at 60mph on a 40mph road, you'll lose your license for at least __________.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":597,"qid":25933,"description":"Your license will be cancelled for 60 days if you are convicted of both speeding and ______ on the same occasion.","explanation":null,"imageName":"","correctAnswer":12260,"isBookmarked":0,"aid":"11942#12260#12947#38641","text":"failure to yield#reckless driving#improper passing#failure to have liability insurance"},
{"testId":1064,"qid":25933,"description":"Your license will be cancelled for 60 days if you are convicted of both speeding and ______ on the same occasion.","explanation":null,"imageName":"","correctAnswer":12260,"isBookmarked":0,"aid":"11942#12260#12947#38641","text":"failure to yield#reckless driving#improper passing#failure to have liability insurance"},
{"testId":597,"qid":25934,"description":"You will lose your driver's license for 60 days if you're convicted of driving at a speed of over 55 miles per hour and exceeding聽posted speed limit by 15 mph ____聽 聽聽in a year.","explanation":null,"imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":1064,"qid":25934,"description":"You will lose your driver's license for 60 days if you're convicted of driving at a speed of over 55 miles per hour and exceeding聽posted speed limit by 15 mph ____聽 聽聽in a year.","explanation":null,"imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":597,"qid":25935,"description":"If you accidentally kill someone while driving illegally, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":1064,"qid":25935,"description":"If you accidentally kill someone while driving illegally, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":597,"qid":25936,"description":"If you kill someone while DUI, your license will be suspended:","explanation":null,"imageName":"","correctAnswer":38625,"isBookmarked":0,"aid":"38625#38627#38628#38642","text":"permanently#for 10 years#for 15 years#for 20 years"},
{"testId":1064,"qid":25936,"description":"If you kill someone while DUI, your license will be suspended:","explanation":null,"imageName":"","correctAnswer":38625,"isBookmarked":0,"aid":"38625#38627#38628#38642","text":"permanently#for 10 years#for 15 years#for 20 years"},
{"testId":597,"qid":25937,"description":"Your driver's license will be revoked for a period of______if you are found guilty of fleeing the scene of an accident without providing necessary assistance.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":1064,"qid":25937,"description":"Your driver's license will be revoked for a period of______if you are found guilty of fleeing the scene of an accident without providing necessary assistance.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":597,"qid":25939,"description":"Your driver's license will be suspended for an聽year if you are found guilty of driving recklessly_______within a period of twelve months.","explanation":null,"imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":1064,"qid":25939,"description":"Your driver's license will be suspended for an聽year if you are found guilty of driving recklessly_______within a period of twelve months.","explanation":null,"imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":597,"qid":25941,"description":"A driver under the age of 18 who is convicted of ___ driving offences within a year may have his or her provisional license suspended for 90 days.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":25942,"description":"A driver under the age of 18 who is found guilty of four moving offences within a year may have his or her provisional license suspended for:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23884#23886#24049","text":"6 months#15 days#30 days#90 days"},
{"testId":597,"qid":25943,"description":"Which of the following constitutes a moving violation?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#11431#38643","text":"All of the above#Tailgating#Passing a stopped school bus#Hit and run"},
{"testId":1064,"qid":25943,"description":"Which of the following constitutes a moving violation?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#11431#38643","text":"All of the above#Tailgating#Passing a stopped school bus#Hit and run"},
{"testId":597,"qid":25944,"description":"Before a license suspension or revocation can be lifted in North Carolina, you must pay a license restoration fee of at least .","explanation":null,"imageName":"","correctAnswer":13888,"isBookmarked":0,"aid":"11832#12358#13887#13888","text":"$100#$200#$45#$65"},
{"testId":1064,"qid":25944,"description":"Before a license suspension or revocation can be lifted in North Carolina, you must pay a license restoration fee of at least .","explanation":null,"imageName":"","correctAnswer":13888,"isBookmarked":0,"aid":"11832#12358#13887#13888","text":"$100#$200#$45#$65"},
{"testId":597,"qid":25945,"description":"If you had your license suspended or revoked but never relinquished it, you will be required to pay a ____ service fee in addition to the license restoration price in order to restore your driving rights.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":1064,"qid":25945,"description":"If you had your license suspended or revoked but never relinquished it, you will be required to pay a ____ service fee in addition to the license restoration price in order to restore your driving rights.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":598,"qid":25946,"description":"The point value of a traffic offense in North Dakota is determined by:","explanation":"Each traffic offense in North Dakota is allocated a set amount of points under the state's point system. The greater the point value, the more severe the infraction. As an example, dangerous driving is worth eight points, whereas illegal parking is only worth one. [\"Drivers License Point Schedule,\" North Dakota Department of Transportation, http://www.dot.nd.gov/divisions/driverslicense/dlpoints], [\"Point System; North Dakota Noncommercial Drivers License Manual] .htm]","imageName":"","correctAnswer":38804,"isBookmarked":0,"aid":"38804#38805#38806#38807","text":"its severity#its frequency of occurrence#its location#the age of the driver who committed it"},
{"testId":1065,"qid":25946,"description":"The point value of a traffic offense in North Dakota is determined by:","explanation":"Each traffic offense in North Dakota is allocated a set amount of points under the state's point system. The greater the point value, the more severe the infraction. As an example, dangerous driving is worth eight points, whereas illegal parking is only worth one. [\"Drivers License Point Schedule,\" North Dakota Department of Transportation, http://www.dot.nd.gov/divisions/driverslicense/dlpoints], [\"Point System; North Dakota Noncommercial Drivers License Manual] .htm]","imageName":"","correctAnswer":38804,"isBookmarked":0,"aid":"38804#38805#38806#38807","text":"its severity#its frequency of occurrence#its location#the age of the driver who committed it"},
{"testId":598,"qid":25947,"description":"After a traffic violation, points are normally added to your driving record within ___ days of the conviction.","explanation":"Following a traffic offense, points are normally added to your driving record within ten days of the conviction. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#6477","text":"10#5#3#30"},
{"testId":1065,"qid":25947,"description":"After a traffic violation, points are normally added to your driving record within ___ days of the conviction.","explanation":"Following a traffic offense, points are normally added to your driving record within ten days of the conviction. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#6477","text":"10#5#3#30"},
{"testId":598,"qid":25948,"description":"If you accumulate _____ or more points, your license will be suspended.","explanation":"Your driver's license will be suspended if you acquire twelve or more points on your driving record. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1065,"qid":25948,"description":"If you accumulate _____ or more points, your license will be suspended.","explanation":"Your driver's license will be suspended if you acquire twelve or more points on your driving record. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":598,"qid":25950,"description":"Your driver's license will be suspended for an additional_______ for each point over 11 on your driving record.","explanation":"Your driver's license will be suspended for an extra seven days for each point beyond eleven on your driving record. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23883#34746#35431#35988","text":"10 days#3 days#day#7 days"},
{"testId":1065,"qid":25950,"description":"Your driver's license will be suspended for an additional_______ for each point over 11 on your driving record.","explanation":"Your driver's license will be suspended for an extra seven days for each point beyond eleven on your driving record. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23883#34746#35431#35988","text":"10 days#3 days#day#7 days"},
{"testId":598,"qid":25951,"description":"One point will be deducted from your driving record for every ________ you do not acquire more points.","explanation":"One point will be deducted from your driving record for every three months that you do not acquire further points. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":4373,"isBookmarked":0,"aid":"4373#4374#23256#38808","text":"three months#six months#twelve months#month"},
{"testId":1065,"qid":25951,"description":"One point will be deducted from your driving record for every ________ you do not acquire more points.","explanation":"One point will be deducted from your driving record for every three months that you do not acquire further points. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":4373,"isBookmarked":0,"aid":"4373#4374#23256#38808","text":"three months#six months#twelve months#month"},
{"testId":598,"qid":25952,"description":"If you successfully complete an approved defensive driving course, you will have ___ points deducted from your driving record.","explanation":"Three points will be deducted from your driving record if you successfully complete an authorized driver development course (such as a defensive driving course). [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":598,"qid":25953,"description":"You may choose to attend an approved driver improvement course instead of having points added to your driving record every ___ months.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6478#6916#11342#12040","text":"12#6#9#24"},
{"testId":1065,"qid":25953,"description":"You may choose to attend an approved driver improvement course instead of having points added to your driving record every ___ months.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"6478#6916#11342#12040","text":"12#6#9#24"},
{"testId":598,"qid":25955,"description":"As per the the North Dakota Noncommercial Drivers License Manual, what is the leading cause of traffic deaths on America's highways?","explanation":"According to the North Dakota Noncommercial Drivers License Manual, drunk driving is the leading cause of road fatalities on the nation's highways. According to the CDC, nearly 10,000 persons were died in automobile incidents involving alcohol intoxication in 2016. This accounts for 28% of all traffic-related deaths in the United States. [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota], [Centers for Disease Control and Prevention, \"Impaired Driving: Get the Facts,\" https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet .html]","imageName":"","correctAnswer":38811,"isBookmarked":0,"aid":"897#18625#38811#38812","text":"None of the above#Speeding#Alcohol consumption#Drug use"},
{"testId":1065,"qid":25955,"description":"As per the the North Dakota Noncommercial Drivers License Manual, what is the leading cause of traffic deaths on America's highways?","explanation":"According to the North Dakota Noncommercial Drivers License Manual, drunk driving is the leading cause of road fatalities on the nation's highways. According to the CDC, nearly 10,000 persons were died in automobile incidents involving alcohol intoxication in 2016. This accounts for 28% of all traffic-related deaths in the United States. [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota], [Centers for Disease Control and Prevention, \"Impaired Driving: Get the Facts,\" https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet .html]","imageName":"","correctAnswer":38811,"isBookmarked":0,"aid":"897#18625#38811#38812","text":"None of the above#Speeding#Alcohol consumption#Drug use"},
{"testId":598,"qid":25956,"description":"According to the North Dakota Noncommercial Drivers License Manual, approximately ____ of all road fatalities involve drunk drivers.","explanation":"According to the North Dakota Noncommercial Drivers License Manual, roughly half of all highway fatalities involve drunk drivers. The CDC believes that the rate is about 28% nationwide. [Alcohol; Drinking, Drugs, and Driving; North Dakota Noncommercial Drivers License Manual], [Centers for Disease Control and Prevention, \"Impaired Driving: Get the Facts,\" https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet .html]","imageName":"","correctAnswer":11667,"isBookmarked":0,"aid":"11665#11667#11939#12162","text":"25%#50%#40%#30%"},
{"testId":598,"qid":25957,"description":"According to the North Dakota Noncommercial Drivers License Manual, even a tiny amount of alcohol increases your chances of being involved in a traffic accident by:","explanation":"According to the North Dakota Noncommercial Drivers License Manual, even a tiny quantity of alcohol increases your chances of an accident by seven times. [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota]","imageName":"","correctAnswer":38813,"isBookmarked":0,"aid":"17578#20206#38813#38814","text":"3 times#5 times#7 times#9 times"},
{"testId":1065,"qid":25957,"description":"According to the North Dakota Noncommercial Drivers License Manual, even a tiny amount of alcohol increases your chances of being involved in a traffic accident by:","explanation":"According to the North Dakota Noncommercial Drivers License Manual, even a tiny quantity of alcohol increases your chances of an accident by seven times. [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota]","imageName":"","correctAnswer":38813,"isBookmarked":0,"aid":"17578#20206#38813#38814","text":"3 times#5 times#7 times#9 times"},
{"testId":598,"qid":25958,"description":"What is the legal phrase in North Dakota for the percentage of alcohol in a person's blood?","explanation":null,"imageName":"","correctAnswer":14038,"isBookmarked":0,"aid":"897#13820#14038#38815","text":"None of the above#Blood Alcohol Concentration (BAC)#Alcohol Concentration (AC)#Blood Alcohol Content (BAC)"},
{"testId":1065,"qid":25958,"description":"What is the legal phrase in North Dakota for the percentage of alcohol in a person's blood?","explanation":null,"imageName":"","correctAnswer":14038,"isBookmarked":0,"aid":"897#13820#14038#38815","text":"None of the above#Blood Alcohol Concentration (BAC)#Alcohol Concentration (AC)#Blood Alcohol Content (BAC)"},
{"testId":598,"qid":25959,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"The quantity of alcohol drank, the length of time it was drunk, and your lean body weight may all impact your alcohol concentration (AC). (A bigger body has the ability to dilute alcohol more than a smaller body.) [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30509#38816#38817","text":"All of the above#The amount of alcohol consumed#The amount of time over which the alcohol was consumed#Your lean body weight"},
{"testId":1065,"qid":25959,"description":"Which of the following can have an impact on your blood alcohol concentration (BAC)?","explanation":"The quantity of alcohol drank, the length of time it was drunk, and your lean body weight may all impact your alcohol concentration (AC). (A bigger body has the ability to dilute alcohol more than a smaller body.) [Alcohol; Drinking, Drugs, and Driving; Noncommercial Drivers License Manual for North Dakota]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30509#38816#38817","text":"All of the above#The amount of alcohol consumed#The amount of time over which the alcohol was consumed#Your lean body weight"},
{"testId":598,"qid":25960,"description":"What effect does drinking alcohol have on your driving abilities?","explanation":"Alcohol slows down your reflexes, blurs your eyesight, clouds your judgement, hinders concentration, and gives you a false feeling of self-assurance. Your ability to drive may be affected by all of these side effects. [Manual for Noncommercial Drivers in North Dakota; Effects of Alcohol; Drinking and Driving]","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"26434#38818#38819#38820","text":"It does all of the above#It slows your reaction time#It impairs your vision#It dulls your judgment"},
{"testId":598,"qid":25961,"description":"By driving on public highways in North Dakota, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":598,"qid":25962,"description":"If you refuse to submit to chemical testing when a law enforcement officer requests it, your driver's license will be suspended for at least :","explanation":"Your driver's license will be suspended for 180 days to three years if you refuse to submit to chemical testing for alcohol or drugs. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":598,"qid":25964,"description":"North Dakota uses which of the following types of chemical testing?","explanation":"For alcohol or drugs, ND employs a variety of chemical testing, including blood, breath, and urine. The Supreme Court held in Birchfield v. North Dakota (2016) that implied consent includes breath testing but NOT blood testing. The Court ruled that in order to compel a motorist to submit to a blood test under penalty of law, police must first acquire a search warrant. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":1065,"qid":25964,"description":"North Dakota uses which of the following types of chemical testing?","explanation":"For alcohol or drugs, ND employs a variety of chemical testing, including blood, breath, and urine. The Supreme Court held in Birchfield v. North Dakota (2016) that implied consent includes breath testing but NOT blood testing. The Court ruled that in order to compel a motorist to submit to a blood test under penalty of law, police must first acquire a search warrant. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":598,"qid":25965,"description":"If you're over 21 and have a BAC of ____ or more, you're legal considered as intoxicated.","explanation":"You will be regarded legally under the influence of alcohol if you are at least 21 years old and a chemical test finds that your alcohol concentration (AC) is 0.08 percent or above. If you are under the age of 21 and your BAC is 0.02 percent or above, you will be declared legally intoxicated. Driving while in this state might result in severe fines. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3282#4251#4252","text":"0.08%#0.06%#0.02%#0.05%"},
{"testId":1065,"qid":25965,"description":"If you're over 21 and have a BAC of ____ or more, you're legal considered as intoxicated.","explanation":"You will be regarded legally under the influence of alcohol if you are at least 21 years old and a chemical test finds that your alcohol concentration (AC) is 0.08 percent or above. If you are under the age of 21 and your BAC is 0.02 percent or above, you will be declared legally intoxicated. Driving while in this state might result in severe fines. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3282#4251#4252","text":"0.08%#0.06%#0.02%#0.05%"},
{"testId":598,"qid":25966,"description":"If you are under the age of 21 and your alcohol content (AC) is proven to be ______ or above, you will be deemed legally under the influence of alcohol in North Dakota.","explanation":"If you are under the age of 21 and your alcohol content (AC) is 0.02 percent or more, you will be deemed legally under the influence of alcohol in North Dakota. The great majority of people will get an AC of 0.02 percent or more by ingesting only one can of beer or one glass of wine. As a result, an AC limit of less than 0.02 percent basically equals zero tolerance. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4652#4653","text":"0.08%#0.02%#0.00%#0.01%"},
{"testId":1065,"qid":25966,"description":"If you are under the age of 21 and your alcohol content (AC) is proven to be ______ or above, you will be deemed legally under the influence of alcohol in North Dakota.","explanation":"If you are under the age of 21 and your alcohol content (AC) is 0.02 percent or more, you will be deemed legally under the influence of alcohol in North Dakota. The great majority of people will get an AC of 0.02 percent or more by ingesting only one can of beer or one glass of wine. As a result, an AC limit of less than 0.02 percent basically equals zero tolerance. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3168#4251#4652#4653","text":"0.08%#0.02%#0.00%#0.01%"},
{"testId":598,"qid":25967,"description":"What types of consequences might you expect if you are convicted of DUI?","explanation":"If you are convicted of DUI, you will almost certainly face both administrative (such as loss of driving privileges) and criminal (i.e., court) sanctions (such as fines or jail time). License suspensions may also be imposed by the courts. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#23061#38821#38822","text":"Both of the above#Neither of the above#Administrative penalties#Court conviction penalties"},
{"testId":1065,"qid":25967,"description":"What types of consequences might you expect if you are convicted of DUI?","explanation":"If you are convicted of DUI, you will almost certainly face both administrative (such as loss of driving privileges) and criminal (i.e., court) sanctions (such as fines or jail time). License suspensions may also be imposed by the courts. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#23061#38821#38822","text":"Both of the above#Neither of the above#Administrative penalties#Court conviction penalties"},
{"testId":598,"qid":25968,"description":"If convicted of DUI for the first time, your license will be revoked for:","explanation":"Your driver's license will be suspended for 91 days if you are convicted of DUI for the first time in seven years. You'll also face criminal charges. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":38823,"isBookmarked":0,"aid":"23884#38823#38824#38825","text":"15 days#91 days#61 days#31 days"},
{"testId":1065,"qid":25968,"description":"If convicted of DUI for the first time, your license will be revoked for:","explanation":"Your driver's license will be suspended for 91 days if you are convicted of DUI for the first time in seven years. You'll also face criminal charges. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":38823,"isBookmarked":0,"aid":"23884#38823#38824#38825","text":"15 days#91 days#61 days#31 days"},
{"testId":598,"qid":25969,"description":"If you're convicted of DUI for the first time, your license will be suspended longer if your BAC was ___ or higher.","explanation":"If you are convicted of DUI for the first time in seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the incident, your driver's license will be banned for 180 days instead of the standard 91 days. In addition, you will risk greater criminal consequences. It would take six alcoholic beverages (such as six shots of vodka) for an average 180-pound guy to acquire an AC of 0.18 percent or higher before his body begins to break down the alcohol. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":9813,"isBookmarked":0,"aid":"9813#11768#11770#13710","text":"0.18%#0.10%#0.15%#0.13%"},
{"testId":1065,"qid":25969,"description":"If you're convicted of DUI for the first time, your license will be suspended longer if your BAC was ___ or higher.","explanation":"If you are convicted of DUI for the first time in seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the incident, your driver's license will be banned for 180 days instead of the standard 91 days. In addition, you will risk greater criminal consequences. It would take six alcoholic beverages (such as six shots of vodka) for an average 180-pound guy to acquire an AC of 0.18 percent or higher before his body begins to break down the alcohol. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":9813,"isBookmarked":0,"aid":"9813#11768#11770#13710","text":"0.18%#0.10%#0.15%#0.13%"},
{"testId":598,"qid":25970,"description":"If you're convicted of a first DUI and your BAC was 0.18 percent or higher, your license will be suspended for:","explanation":"Your driver's license will normally be suspended for 91 days if you are convicted of DUI for the first time in seven years. Your license will be suspended for 180 days if your AC was 0.18 percent or higher at the time of the violation. In addition, you will risk greater criminal consequences. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"25858#38823#38826#38827","text":"180 days#91 days#100 days#131 days"},
{"testId":1065,"qid":25970,"description":"If you're convicted of a first DUI and your BAC was 0.18 percent or higher, your license will be suspended for:","explanation":"Your driver's license will normally be suspended for 91 days if you are convicted of DUI for the first time in seven years. Your license will be suspended for 180 days if your AC was 0.18 percent or higher at the time of the violation. In addition, you will risk greater criminal consequences. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"25858#38823#38826#38827","text":"180 days#91 days#100 days#131 days"},
{"testId":598,"qid":25971,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI violation happens within seven years of the previous crime, it is deemed a second offense. Similarly, a third DUI charge is considered if it happens within seven years of the first. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5447#7559#11783","text":"10#15#7#13"},
{"testId":1065,"qid":25971,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"If a DUI violation happens within seven years of the previous crime, it is deemed a second offense. Similarly, a third DUI charge is considered if it happens within seven years of the first. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5447#7559#11783","text":"10#15#7#13"},
{"testId":598,"qid":25972,"description":"If you are convicted of a DUI offense for the second time within seven years, your license will be revoked for:","explanation":"Your driver's license will be suspended for 365 days if you are convicted of a second DUI violation within seven years. You will also face criminal charges, including a ten-day prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20789#23152#23154#23183","text":"9 months#3 months#1 year#3 years"},
{"testId":1065,"qid":25972,"description":"If you are convicted of a DUI offense for the second time within seven years, your license will be revoked for:","explanation":"Your driver's license will be suspended for 365 days if you are convicted of a second DUI violation within seven years. You will also face criminal charges, including a ten-day prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20789#23152#23154#23183","text":"9 months#3 months#1 year#3 years"},
{"testId":598,"qid":25975,"description":"If you are convicted of a DUI offense for the third time within seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the offense, your driver's license will be suspended for:","explanation":"If you are convicted of a third or subsequent DUI conviction within seven years, your license is normally suspended for two years. Your license will be suspended for three years if your AC was 0.18 percent or higher at the time of the incident. In addition, you will face criminal sanctions, including a required prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23183#26672#28011#29895","text":"3 years#5 years#15 years#10 years"},
{"testId":1065,"qid":25975,"description":"If you are convicted of a DUI offense for the third time within seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the offense, your driver's license will be suspended for:","explanation":"If you are convicted of a third or subsequent DUI conviction within seven years, your license is normally suspended for two years. Your license will be suspended for three years if your AC was 0.18 percent or higher at the time of the incident. In addition, you will face criminal sanctions, including a required prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23183#26672#28011#29895","text":"3 years#5 years#15 years#10 years"},
{"testId":598,"qid":25976,"description":"If you have had your driver's license suspended for DUI, you may be able to receive a work permit once your license has been suspended for at least :","explanation":"A Temporary Restricted License (TRL) (sometimes known as a work permit) in North Dakota permits a motorist whose license has been suspended or revoked to drive for certain reasons. You may drive a TRL to and from work or school, or for other necessary life maintenance requirements for yourself or your family. You may not, however, get a TRL until you have served a portion of your suspension or revocation term. Before you may get a TRL for a DUI, your license must be suspended for at least 30 days. There are also further criteria. [Driving While Impaired; Drinking, Drugs, and Driving; North Dakota Noncommercial Drivers License Manual], [\"Drivers License Suspensions,\" \"Drivers License Frequently Asked Questions,\" North Dakota Department of Transportation, http://www.dot.nd.gov/dotnet2/view/dl-faq .aspx]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24049#38826#38828","text":"30 days#90 days#100 days#35 days"},
{"testId":1065,"qid":25976,"description":"If you have had your driver's license suspended for DUI, you may be able to receive a work permit once your license has been suspended for at least :","explanation":"A Temporary Restricted License (TRL) (sometimes known as a work permit) in North Dakota permits a motorist whose license has been suspended or revoked to drive for certain reasons. You may drive a TRL to and from work or school, or for other necessary life maintenance requirements for yourself or your family. You may not, however, get a TRL until you have served a portion of your suspension or revocation term. Before you may get a TRL for a DUI, your license must be suspended for at least 30 days. There are also further criteria. [Driving While Impaired; Drinking, Drugs, and Driving; North Dakota Noncommercial Drivers License Manual], [\"Drivers License Suspensions,\" \"Drivers License Frequently Asked Questions,\" North Dakota Department of Transportation, http://www.dot.nd.gov/dotnet2/view/dl-faq .aspx]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24049#38826#38828","text":"30 days#90 days#100 days#35 days"},
{"testId":598,"qid":25977,"description":"Which of the following North Dakota drivers is NOT qualified for a temporary restricted license (commonly referred to as a work permit)?","explanation":"Provided your license has been suspended due to a DUI, you may be eligible for a Temporary Restricted License (TRL) (commonly known as a work permit) if you fulfill all of the following criteria: (1) You are at least the age of 18. (2) You've had your license suspended for at least 30 days. (3) You have not had a second DUI conviction within the last five years. (4) You have not had two or more criminal convictions in the previous three years. (5) You have not had four or more moving traffic tickets in the preceding year, or twelve or more traffic offenses in the previous three years. (6) You were never convicted of breaking a prior TRL's limits. [Driving While Impaired; Drinking, Drugs, and Driving; North Dakota Noncommercial Drivers License Manual], [\"Drivers License Suspensions,\" \"Drivers License Frequently Asked Questions,\" North Dakota Department of Transportation, http://www.dot.nd.gov/dotnet2/view/dl-faq .aspx]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#38829#38830#38831","text":"All of the above#A driver under 18 years of age#A driver convicted of a second DUI within 5 years#A driver who has not served 30 days of his or her suspension for DUI"},
{"testId":1065,"qid":25977,"description":"Which of the following North Dakota drivers is NOT qualified for a temporary restricted license (commonly referred to as a work permit)?","explanation":"Provided your license has been suspended due to a DUI, you may be eligible for a Temporary Restricted License (TRL) (commonly known as a work permit) if you fulfill all of the following criteria: (1) You are at least the age of 18. (2) You've had your license suspended for at least 30 days. (3) You have not had a second DUI conviction within the last five years. (4) You have not had two or more criminal convictions in the previous three years. (5) You have not had four or more moving traffic tickets in the preceding year, or twelve or more traffic offenses in the previous three years. (6) You were never convicted of breaking a prior TRL's limits. [Driving While Impaired; Drinking, Drugs, and Driving; North Dakota Noncommercial Drivers License Manual], [\"Drivers License Suspensions,\" \"Drivers License Frequently Asked Questions,\" North Dakota Department of Transportation, http://www.dot.nd.gov/dotnet2/view/dl-faq .aspx]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#38829#38830#38831","text":"All of the above#A driver under 18 years of age#A driver convicted of a second DUI within 5 years#A driver who has not served 30 days of his or her suspension for DUI"},
{"testId":598,"qid":25979,"description":"If you drive while your license is suspended or revoked for an alcohol-related offense, you may have to pay a fine of:","explanation":"The consequences for driving with a suspended or revoked license for an alcohol-related infraction include a required minimum prison term of four days and a fine of up to $1,000. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":1065,"qid":25979,"description":"If you drive while your license is suspended or revoked for an alcohol-related offense, you may have to pay a fine of:","explanation":"The consequences for driving with a suspended or revoked license for an alcohol-related infraction include a required minimum prison term of four days and a fine of up to $1,000. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":598,"qid":25980,"description":"During an alcohol-related license suspension, which of the following items may be impounded?","explanation":"If you have your driver's license suspended or revoked for an alcohol-related violation, the license plates and registration card for your vehicle may be seized for the duration of the suspension or revocation. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":38832,"isBookmarked":0,"aid":"897#38832#38833#38834","text":"None of the above#Your vehicle's license plates#Your vehicle's ignition key#Your vehicle"},
{"testId":1065,"qid":25980,"description":"During an alcohol-related license suspension, which of the following items may be impounded?","explanation":"If you have your driver's license suspended or revoked for an alcohol-related violation, the license plates and registration card for your vehicle may be seized for the duration of the suspension or revocation. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":38832,"isBookmarked":0,"aid":"897#38832#38833#38834","text":"None of the above#Your vehicle's license plates#Your vehicle's ignition key#Your vehicle"},
{"testId":598,"qid":25981,"description":"First Time DUI offenders may be fined:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1065,"qid":25981,"description":"First Time DUI offenders may be fined:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":598,"qid":25982,"description":"If you're convicted of a second DUI within seven years, you will spend at least _____ days in jail and you聽need to聽complete _______ months in聽the 24/7 Sobriety Program as a condition of probation.","explanation":null,"imageName":"","correctAnswer":23774,"isBookmarked":0,"aid":"13173#23773#23774#23775","text":"5; 3#3; 1#10; 12#10; 6"},
{"testId":1065,"qid":25982,"description":"If you're convicted of a second DUI within seven years, you will spend at least _____ days in jail and you聽need to聽complete _______ months in聽the 24/7 Sobriety Program as a condition of probation.","explanation":null,"imageName":"","correctAnswer":23774,"isBookmarked":0,"aid":"13173#23773#23774#23775","text":"5; 3#3; 1#10; 12#10; 6"},
{"testId":598,"qid":25983,"description":"If convicted of a third DUI within seven years, you will be fined at least:","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23889#25033#25036#31397","text":"$1,000#$2,000#$5,000#$10,000"},
{"testId":1065,"qid":25983,"description":"If convicted of a third DUI within seven years, you will be fined at least:","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23889#25033#25036#31397","text":"$1,000#$2,000#$5,000#$10,000"},
{"testId":598,"qid":25984,"description":"If convicted of a third DUI within seven years, you'll spend at least ____ days in jail.","explanation":null,"imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6354#6355#6356#6477","text":"45#90#120#30"},
{"testId":1065,"qid":25984,"description":"If convicted of a third DUI within seven years, you'll spend at least ____ days in jail.","explanation":null,"imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6354#6355#6356#6477","text":"45#90#120#30"},
{"testId":598,"qid":25985,"description":"Minors under the age of 21 are not permitted to:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#38835#38836#38837","text":"All of the above#purchasing alcoholic beverages#consuming alcoholic beverages#possessing alcoholic beverages"},
{"testId":1065,"qid":25985,"description":"Minors under the age of 21 are not permitted to:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#38835#38836#38837","text":"All of the above#purchasing alcoholic beverages#consuming alcoholic beverages#possessing alcoholic beverages"},
{"testId":598,"qid":25986,"description":"If you are convicted of a fourth DUI violation within seven years, you will face at least ____ days in prison.","explanation":null,"imageName":"","correctAnswer":23772,"isBookmarked":0,"aid":"23772#38838#38839#38840","text":"366#271#181#121"},
{"testId":1065,"qid":25986,"description":"If you are convicted of a fourth DUI violation within seven years, you will face at least ____ days in prison.","explanation":null,"imageName":"","correctAnswer":23772,"isBookmarked":0,"aid":"23772#38838#38839#38840","text":"366#271#181#121"},
{"testId":598,"qid":25987,"description":"If you are convicted of a fourth DUI conviction within seven years, you will be fined at least:","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25036#31397#31882","text":"$2,000#$5,000#$10,000#$7,500"},
{"testId":1065,"qid":25987,"description":"If you are convicted of a fourth DUI conviction within seven years, you will be fined at least:","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25036#31397#31882","text":"$2,000#$5,000#$10,000#$7,500"},
{"testId":598,"qid":25989,"description":"DUI offenders who kill someone in the offense face at least ___ years in prison.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11285#11306#11341#12296","text":"2 years#3 years#5 years#7 years"},
{"testId":1065,"qid":25989,"description":"DUI offenders who kill someone in the offense face at least ___ years in prison.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11285#11306#11341#12296","text":"2 years#3 years#5 years#7 years"},
{"testId":598,"qid":25990,"description":"In North Dakota, where may you lawfully keep open containers of alcohol in your vehicle?","explanation":"Open containers of alcohol may be lawfully placed in a vehicle's trunk. They should not be kept in areas where the driver or passengers may access them, such as a console or glove box. [North Dakota Noncommercial Drivers License Manual; Open Container Law; Driving While Under the Influence; Drinking, Drugs, and Driving]","imageName":"","correctAnswer":38843,"isBookmarked":0,"aid":"23143#38841#38842#38843","text":"In the console#Under the driver's seat#On the rear seat#In the trunk"},
{"testId":598,"qid":25991,"description":"You may renew your North Dakota driver's license without penalty up to ___ months before the expiration date.","explanation":"You may renew your North Dakota driver's license up to ten months before it expires without losing any time left on your current license. [North Dakota Noncommercial Drivers License Manual; Renewals]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6389#6916#11342","text":"10#3#6#9"},
{"testId":598,"qid":25992,"description":"The majority of passenger automobile drivers in North Dakota earn a Class ___ license.","explanation":"A Class D driver's license in North Dakota allows you to operate any single vehicle with a gross vehicle weight rating (GVWR) of less than 26,001 pounds. You may also pull a trailer with a GVWR of no more than 10,000 pounds. Commercial driving license (CDL) courses A, B, and C are available. [North Dakota Noncommercial Drivers License Manual; License Classes]","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":598,"qid":25993,"description":"If you are involved in a traffic accident that causes total damages of ______ or more, you must notify law police promptly.","explanation":"If you are engaged in a traffic collision that causes injury, death, or total damages of $1,000 or more, you must notify police authorities promptly. [North Dakota Noncommercial Drivers License Manual; Crash Reports]","imageName":"202006141611292838.jpg","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":598,"qid":25994,"description":"All drivers in North Dakota are required to have:","explanation":"Everyone who drives a motor vehicle in North Dakota is required by law to carry a valid liability insurance coverage. If you are caught driving without liability insurance, you may be fined and your license may be revoked. Note: The national Affordable Care Act (ACA) required all Americans under the age of 65 to obtain health insurance or face a penalty, but the Tax Cuts and Jobs Act of 2017 eliminated this requirement. [North Dakota Noncommercial Drivers License Manual; Crash Reports]","imageName":"","correctAnswer":38844,"isBookmarked":0,"aid":"8615#29253#38844#38845","text":"All of the above#health insurance#liability vehicle insurance#life insurance"},
{"testId":600,"qid":25996,"description":"Which of the following substances may result in a conviction for driving under the influence (DUI)?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#8071#29384","text":"Any of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":1067,"qid":25996,"description":"Which of the following substances may result in a conviction for driving under the influence (DUI)?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#8071#29384","text":"Any of the above#Illegal drugs#Alcohol#Prescription medications"},
{"testId":600,"qid":25997,"description":"Alcohol is responsible for around _______ of all road deaths in the United States.","explanation":"Alcohol is responsible for about one-fourth of all road fatalities in the United States, according to the Oklahoma Driver's Manual. According to the Centers for Disease Control and Prevention, this equates to around ten thousand deaths every year. [\"Impaired Driving: Get the Facts,\" Centers for Disease Control and Prevention, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet], [\"Impaired Driving: Get the Facts,\" Centers for Disease Control and Prevention, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet .html]","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10698#11884#33470#39868","text":"one-half#one-fourth#one-third#three-fourths"},
{"testId":600,"qid":25998,"description":"The driver's license will be revoked if he or she has a BAC of ______ or above. :","explanation":"If an Oklahoma driver is caught driving with a blood alcohol concentration (BAC) of 0.08 percent or above, his or her license will be suspended for at least 180 days, even if the case is not proven in court. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":1067,"qid":25998,"description":"The driver's license will be revoked if he or she has a BAC of ______ or above. :","explanation":"If an Oklahoma driver is caught driving with a blood alcohol concentration (BAC) of 0.08 percent or above, his or her license will be suspended for at least 180 days, even if the case is not proven in court. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":600,"qid":25999,"description":"What is the legal limit for blood alcohol concentration (BAC) for a driver under the age of 21?","explanation":"A driver's license will be cancelled if he or she is discovered to have any detectable amount of blood alcohol concentration (BAC). [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":35562,"isBookmarked":0,"aid":"23192#23194#23318#35562","text":"0.05%#0.08%#0.02%#0.00%"},
{"testId":1067,"qid":25999,"description":"What is the legal limit for blood alcohol concentration (BAC) for a driver under the age of 21?","explanation":"A driver's license will be cancelled if he or she is discovered to have any detectable amount of blood alcohol concentration (BAC). [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":35562,"isBookmarked":0,"aid":"23192#23194#23318#35562","text":"0.05%#0.08%#0.02%#0.00%"},
{"testId":600,"qid":26000,"description":"A driver's license is revoked after being charged with DUI. However, he or she is later cleared in court of the accusation. What happens to the driver's license?","explanation":"A driver's license will be revoked if he or she is charged with DUI. This is a civil punishment, not a criminal penalty. The court's ruling has no bearing on it. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":41644,"isBookmarked":0,"aid":"41644#41645#41646#41647","text":"The license will remain revoked#The license will be reinstated#The revocation period will be shortened#None of the above will happen"},
{"testId":600,"qid":26001,"description":"By driving on public highways in Oklahoma, you expressly agree to:","explanation":"By driving or otherwise being in charge of a motor vehicle on public roadways in Oklahoma, you implicitly submit to chemical testing for alcohol and drugs at the request of a law enforcement official. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1067,"qid":26001,"description":"By driving on public highways in Oklahoma, you expressly agree to:","explanation":"By driving or otherwise being in charge of a motor vehicle on public roadways in Oklahoma, you implicitly submit to chemical testing for alcohol and drugs at the request of a law enforcement official. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":600,"qid":26002,"description":"If you refuse to submit to chemical testing for alcohol or drugs when a law enforcement officer requests it, your driver's license will be suspended for at least :","explanation":"If you decline to be tested for drugs or alcohol under Oklahoma's Implied Consent statute, your driving license will be suspended for 180 days to three years. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":600,"qid":26004,"description":"You can be guilty of impaired driving in Oklahoma if you are determined to be driving with a blood alcohol content (BAC) above:","explanation":"Under Oklahoma law, you may be convicted of impaired driving if you are discovered driving with a blood alcohol level (BAC) that is more than 0.05 percent but less than the 0.08 percent legal limit for DUI. You may also be convicted of impaired driving solely on the views and facts of the arresting officer. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23192,"isBookmarked":0,"aid":"23192#23194#23319#27697","text":"0.05%#0.08%#0.07%#0.06%"},
{"testId":1067,"qid":26004,"description":"You can be guilty of impaired driving in Oklahoma if you are determined to be driving with a blood alcohol content (BAC) above:","explanation":"Under Oklahoma law, you may be convicted of impaired driving if you are discovered driving with a blood alcohol level (BAC) that is more than 0.05 percent but less than the 0.08 percent legal limit for DUI. You may also be convicted of impaired driving solely on the views and facts of the arresting officer. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23192,"isBookmarked":0,"aid":"23192#23194#23319#27697","text":"0.05%#0.08%#0.07%#0.06%"},
{"testId":600,"qid":26005,"description":"A conviction for impaired driving is punished by a fine of up to $500 or imprisonment for up to _______ :","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"960#1272#11317#41649","text":"2 months#6 months#1 month#2 weeks"},
{"testId":1067,"qid":26005,"description":"A conviction for impaired driving is punished by a fine of up to $500 or imprisonment for up to _______ :","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"960#1272#11317#41649","text":"2 months#6 months#1 month#2 weeks"},
{"testId":600,"qid":26006,"description":"If this is your first conviction for driving while impaired, the Department of Public Safety will suspend your license for:","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for 30 days if this is your first violation. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#25857","text":"10 days#30 days#60 days#120 days"},
{"testId":1067,"qid":26006,"description":"If this is your first conviction for driving while impaired, the Department of Public Safety will suspend your license for:","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for 30 days if this is your first violation. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#25857","text":"10 days#30 days#60 days#120 days"},
{"testId":600,"qid":26008,"description":"Your driver's license will be suspended for ____聽by the Department of Public Safety if you are found guilty of driving while impaired for the third time in the past ten years.","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for one year if this is your third conviction in 10 years. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1067,"qid":26008,"description":"Your driver's license will be suspended for ____聽by the Department of Public Safety if you are found guilty of driving while impaired for the third time in the past ten years.","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for one year if this is your third conviction in 10 years. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":600,"qid":26009,"description":"If convicted of DUI for the first time, you'll spend at least ___ in jail.","explanation":"A first DUI is classified as a misdemeanor. If you are found guilty, you will be fined up to $1,000 and imprisoned to 10 days to one year in prison. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#12685#18239#41650","text":"10 days#5 days#14 days#24 days"},
{"testId":1067,"qid":26009,"description":"If convicted of DUI for the first time, you'll spend at least ___ in jail.","explanation":"A first DUI is classified as a misdemeanor. If you are found guilty, you will be fined up to $1,000 and imprisoned to 10 days to one year in prison. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#12685#18239#41650","text":"10 days#5 days#14 days#24 days"},
{"testId":600,"qid":26011,"description":"Which of the following DUI offence is considered a felony?","explanation":"A second or subsequent DUI violation in 10 years is considered a felony in Oklahoma. Conviction carries serious consequences. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1067,"qid":26011,"description":"Which of the following DUI offence is considered a felony?","explanation":"A second or subsequent DUI violation in 10 years is considered a felony in Oklahoma. Conviction carries serious consequences. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":600,"qid":26012,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"The look-back period for DUI in Oklahoma is 10 years. That is, a DUI within 10 years of the first DUI is deemed a second crime, with greater consequences than a first offense. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"3635#11306#11341#11760","text":"12 years#3 years#5 years#10 years"},
{"testId":1067,"qid":26012,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"The look-back period for DUI in Oklahoma is 10 years. That is, a DUI within 10 years of the first DUI is deemed a second crime, with greater consequences than a first offense. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"3635#11306#11341#11760","text":"12 years#3 years#5 years#10 years"},
{"testId":600,"qid":26013,"description":"What is the minimum duration of jail sentence is imposed for second DUI offense?","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1067,"qid":26013,"description":"What is the minimum duration of jail sentence is imposed for second DUI offense?","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":600,"qid":26016,"description":"For a second felony DUI violation, the maximum fine is:","explanation":"A second felony DUI violation is punished by a $5,000 fine and a jail term ranging from one to seven years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25034#25036#27889","text":"$1,000#$3,000#$5,000#$2,500"},
{"testId":1067,"qid":26016,"description":"For a second felony DUI violation, the maximum fine is:","explanation":"A second felony DUI violation is punished by a $5,000 fine and a jail term ranging from one to seven years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25034#25036#27889","text":"$1,000#$3,000#$5,000#$2,500"},
{"testId":600,"qid":26017,"description":"If you are convicted of felony DUI for the second time , you might face up to ______ years in jail.","explanation":"A second felony DUI violation is punished by a $5,000 fine and a jail term ranging from one to seven years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"11341#12296#41651#41652","text":"5 years#7 years#9 years#11 years"},
{"testId":1067,"qid":26017,"description":"If you are convicted of felony DUI for the second time , you might face up to ______ years in jail.","explanation":"A second felony DUI violation is punished by a $5,000 fine and a jail term ranging from one to seven years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"11341#12296#41651#41652","text":"5 years#7 years#9 years#11 years"},
{"testId":600,"qid":26018,"description":"If convicted of DUI for the third time in ten years, you could face ______ years in prison.","explanation":"A DUI conviction for the third time in 10 years is deemed a felony, punishable by one to ten years in the state jail and a $5,000 fine. [Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"11306#11341#11760#13410","text":"3 years#5 years#10 years#20 years"},
{"testId":1067,"qid":26018,"description":"If convicted of DUI for the third time in ten years, you could face ______ years in prison.","explanation":"A DUI conviction for the third time in 10 years is deemed a felony, punishable by one to ten years in the state jail and a $5,000 fine. [Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"11306#11341#11760#13410","text":"3 years#5 years#10 years#20 years"},
{"testId":600,"qid":26019,"description":"What penalties might you face other than a fine if you are convicted of DUI?","explanation":"Aside from a fine and prison time, you may have to pay for attorney's fees, an alcohol or drug misuse examination, court charges, alternative transportation costs, the installation of an ignition interlock device, a $25 license reinstatement fee, and other expenses. Your automobile liability insurance costs may also skyrocket. Finally, having a criminal record may make it more difficult to qualify for certain employment, college admittance, or bank loans. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#40752#41653#41654","text":"All of the above#Court costs#Attorney's fees#Substance abuse evaluation"},
{"testId":1067,"qid":26019,"description":"What penalties might you face other than a fine if you are convicted of DUI?","explanation":"Aside from a fine and prison time, you may have to pay for attorney's fees, an alcohol or drug misuse examination, court charges, alternative transportation costs, the installation of an ignition interlock device, a $25 license reinstatement fee, and other expenses. Your automobile liability insurance costs may also skyrocket. Finally, having a criminal record may make it more difficult to qualify for certain employment, college admittance, or bank loans. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#40752#41653#41654","text":"All of the above#Court costs#Attorney's fees#Substance abuse evaluation"},
{"testId":600,"qid":26020,"description":"If a driver under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for six months. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#23886#35989","text":"3 months#6 months#30 days#14 days"},
{"testId":1067,"qid":26020,"description":"If a driver under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for six months. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#23886#35989","text":"3 months#6 months#30 days#14 days"},
{"testId":600,"qid":26021,"description":"If a driver under the age of 21 is convicted of DUI for the second time, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 gets convicted of DUI for the second time, his or her license will be suspended for a year. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":1067,"qid":26021,"description":"If a driver under the age of 21 is convicted of DUI for the second time, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 gets convicted of DUI for the second time, his or her license will be suspended for a year. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":600,"qid":26023,"description":"If a driver under the age of 21 gets convicted of DUI for the third time, his or her driving license will be suspended for:","explanation":"A motorist under the age of 21 who is convicted of DUI for the third time will have his or her license suspended for 36 months. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":27027,"isBookmarked":0,"aid":"23153#25032#25169#27027","text":"6 months#24 months#12 months#36 months"},
{"testId":1067,"qid":26023,"description":"If a driver under the age of 21 gets convicted of DUI for the third time, his or her driving license will be suspended for:","explanation":"A motorist under the age of 21 who is convicted of DUI for the third time will have his or her license suspended for 36 months. He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":27027,"isBookmarked":0,"aid":"23153#25032#25169#27027","text":"6 months#24 months#12 months#36 months"},
{"testId":600,"qid":26024,"description":"If a driver under the age of 21 is convicted of DUI for the first time, he or she may face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1067,"qid":26024,"description":"If a driver under the age of 21 is convicted of DUI for the first time, he or she may face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":600,"qid":26025,"description":"If a driver under the age of 21 is convicted of DUI for the second time, which of the following punishments may be imposed?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23075#23115#41655","text":"All of the above#Community service#A $1,000 fine#Enrollment in a treatment program"},
{"testId":1067,"qid":26025,"description":"If a driver under the age of 21 is convicted of DUI for the second time, which of the following punishments may be imposed?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23075#23115#41655","text":"All of the above#Community service#A $1,000 fine#Enrollment in a treatment program"},
{"testId":600,"qid":26026,"description":"If you are convicted of using a forged driver's license, you might face up to ______  in state jail.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"1323#11306#12296#41652","text":"15 years#3 years#7 years#11 years"},
{"testId":600,"qid":26027,"description":"To get a learner's permit or driver's license, a person under the age of ____ must have finished high school or the equivalent or be enrolled in it.","explanation":"A person under the age of 18 must provide the DPS with proof that he or she (1) is enrolled in or has graduated from high school; (2) is enrolled in or has successfully completed an equivalent homeschooling program; (3) is enrolled in or has successfully completed a General Equivalency Diploma (GED) program; or (4) is employed at least 24 hours per week to qualify for a learner permit or driver license. [School Withdrawal; Section 4 Violations and Penalties; Oklahoma Driver's Manual], [\"Applying for a Learner Permit,\" Oklahoma Department of Public Safety, https://www.ok.gov/dps/Obtain an Oklahoma Driver License ID Card/Applying for an Original Driver License (Under the Age of 18)/Applying for","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":600,"qid":26028,"description":"A copy of your driving record can be obtained for a charge of:","explanation":"A copy of your driving record may be obtained for $25 paid out to the Department of Public Safety. You may get your driving record by mail, in person at any DPS office in the state, or online at the DPS website. [\"Department of Public Safety,\" Oklahoma Department of Public Safety, https://www.ok.gov/dps/], [\"Driver's Record; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties; Oklahoma Driver's Manual].","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#33835#35234","text":"$25#$50#$10#$5"},
{"testId":1067,"qid":26028,"description":"A copy of your driving record can be obtained for a charge of:","explanation":"A copy of your driving record may be obtained for $25 paid out to the Department of Public Safety. You may get your driving record by mail, in person at any DPS office in the state, or online at the DPS website. [\"Department of Public Safety,\" Oklahoma Department of Public Safety, https://www.ok.gov/dps/], [\"Driver's Record; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties; Oklahoma Driver's Manual].","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#33835#35234","text":"$25#$50#$10#$5"},
{"testId":600,"qid":26029,"description":"A probationary license is one which is awarded to a driver under the age of _____","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":600,"qid":26030,"description":"For which of the following reasons may you be required to attend a driver compliance hearing?","explanation":"If you exhibit risky driving practices, you may be summoned to a Driver Compliance hearing. Careless driving, driving while impaired by or under the influence of alcohol or drugs, and a history of repeated traffic violations are all examples of unsafe driving habits. [Oklahoma Driver's Manual; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#31014#34733#41656","text":"Any of the above#Careless driving#Repeated traffic violations#Driving under the influence of alcohol (DUI)"},
{"testId":600,"qid":26031,"description":"Which of the following convictions will result in the revocation of your license?","explanation":"If you are guilty of any of the following offenses, your license will be suspended for six months to three years: Leaving the scene of an accident (hit-and-run); any crime involving the use of a motor vehicle; any drug conviction involving the use of a motor vehicle; or, of course, DUI. In addition, if a motorist under the age of 21 is discovered to be driving with any detectable blood alcohol concentration, his or her license will be canceled (BAC). [Your License Must Be Revoked; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11603#41657#41658","text":"Any of the above#Vehicular manslaughter#Hit-and-run#Perjury to obtain a license"},
{"testId":1067,"qid":26031,"description":"Which of the following convictions will result in the revocation of your license?","explanation":"If you are guilty of any of the following offenses, your license will be suspended for six months to three years: Leaving the scene of an accident (hit-and-run); any crime involving the use of a motor vehicle; any drug conviction involving the use of a motor vehicle; or, of course, DUI. In addition, if a motorist under the age of 21 is discovered to be driving with any detectable blood alcohol concentration, his or her license will be canceled (BAC). [Your License Must Be Revoked; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11603#41657#41658","text":"Any of the above#Vehicular manslaughter#Hit-and-run#Perjury to obtain a license"},
{"testId":600,"qid":26032,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26032,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26033,"description":"Careless driving adds ___ points to your driving record.","explanation":"If you are convicted of reckless driving, you will get two points on your license. Careless driving is less dangerous than reckless driving, which entails driving recklessly without concern for the safety of others. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26033,"description":"Careless driving adds ___ points to your driving record.","explanation":"If you are convicted of reckless driving, you will get two points on your license. Careless driving is less dangerous than reckless driving, which entails driving recklessly without concern for the safety of others. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26034,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 75 mph on a road with a specified speed restriction of 55 mph.","explanation":"If you are guilty of exceeding the prescribed speed limit by up to 25 mph, two points will be added to your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26034,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 75 mph on a road with a specified speed restriction of 55 mph.","explanation":"If you are guilty of exceeding the prescribed speed limit by up to 25 mph, two points will be added to your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26035,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 85 mph on a road with a specified speed restriction of 55 mph.","explanation":"If you are guilty of exceeding the legal speed limit by more than 25 mph, three points will be added to your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26035,"description":"_____ points will be added to your driving record if you are found guilty of speeding at 85 mph on a road with a specified speed restriction of 55 mph.","explanation":"If you are guilty of exceeding the legal speed limit by more than 25 mph, three points will be added to your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26036,"description":"How many points will be added to your driving record if you fail to stop for a school bus that is stopped to load or unload passengers?","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26036,"description":"How many points will be added to your driving record if you fail to stop for a school bus that is stopped to load or unload passengers?","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26037,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":"If you are convicted of failing to obey a stop sign or traffic signal, you will get two points on your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26037,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":"If you are convicted of failing to obey a stop sign or traffic signal, you will get two points on your driving record. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26038,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are guilty of failing to cede the right-of-way, you will get two points on your license. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1067,"qid":26038,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are guilty of failing to cede the right-of-way, you will get two points on your license. [Oklahoma Mandatory Point System; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":600,"qid":26039,"description":"Driving with a broken headlight will add __ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":600,"qid":26040,"description":"Two points will be deleted from your driving record if you drive for a _______ period without acquiring any more points.","explanation":"If you drive for a year without acquiring any additional points, your driving record will be reduced by two points. If you drive without a traffic conviction for three years, your point total will be decreased to zero. [Point Reduction; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":34966,"isBookmarked":0,"aid":"34966#34967#41659#41660","text":"12-month#6-month#24-month#36-month"},
{"testId":1067,"qid":26040,"description":"Two points will be deleted from your driving record if you drive for a _______ period without acquiring any more points.","explanation":"If you drive for a year without acquiring any additional points, your driving record will be reduced by two points. If you drive without a traffic conviction for three years, your point total will be decreased to zero. [Point Reduction; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":34966,"isBookmarked":0,"aid":"34966#34967#41659#41660","text":"12-month#6-month#24-month#36-month"},
{"testId":600,"qid":26041,"description":"You will have the option to complete an approved Driver Compliance or Defensive Driving Course every ___ months and erase ___ point(s) from your driving record.","explanation":"You may eliminate two points from your driving record by completing an authorized Driver Compliance or Defensive Driving Course every two years. Your point total, however, cannot go below zero. [Point Reduction; Section 4 Violations and Penalties; Oklahoma Driver's Manual; Chapter 14: Violations and Penalties]","imageName":"","correctAnswer":41664,"isBookmarked":0,"aid":"41661#41662#41663#41664","text":"12; 1#12; 2#24; 1#24; 2"},
{"testId":600,"qid":26042,"description":"If you are caught failing to maintain liability insurance on your car, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24994#27888","text":"$200#$100#$50#$250"},
{"testId":600,"qid":26043,"description":"If you are found guilty of driving without adequate liability insurance on your vehicle, you face the possibility of receiving a jail sentence of up to .","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"227#229#742#18239","text":"180 days#90 days#30 days#14 days"},
{"testId":600,"qid":26044,"description":"Your vehicle liability insurance policy in Oklahoma must have at least ______ in property damage coverage for any single accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12047#12048","text":"$50,000#$10,000#$15,000#$25,000"},
{"testId":600,"qid":26045,"description":"Your vehicle liability insurance policy in Oklahoma must offer at least ______ in coverage for the injuries or deaths of two or more people in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12841#12842#13748","text":"$50,000#$100,000#$75,000#$200,000"},
{"testId":601,"qid":26046,"description":"_______ must use a hands-free device to use a cell phone while driving.","explanation":"To use a mobile phone or other communication device while driving, drivers must utilize a hands-free attachment. Drivers under the age of 18 are not permitted to use a mobile phone while driving, even if it is hands-free. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":25216,"isBookmarked":0,"aid":"25214#25216#25217#25244","text":"Able to afford it#18 years of age or older#Easily distracted#Under 18 years of age"},
{"testId":1068,"qid":26046,"description":"_______ must use a hands-free device to use a cell phone while driving.","explanation":"To use a mobile phone or other communication device while driving, drivers must utilize a hands-free attachment. Drivers under the age of 18 are not permitted to use a mobile phone while driving, even if it is hands-free. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":25216,"isBookmarked":0,"aid":"25214#25216#25217#25244","text":"Able to afford it#18 years of age or older#Easily distracted#Under 18 years of age"},
{"testId":601,"qid":26047,"description":"In accordance with Oregon's Implied Assent statute, driving a motor vehicle in Oregon constitutes implied consent to:","explanation":"Under Oregon's Implied Permission statute, operating a motor vehicle on public roadways or on public property implies consent to chemical testing for alcohol and drugs at the request of a police officer. [Driving While Impaired; Dangerous Driving Habits; 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1068,"qid":26047,"description":"In accordance with Oregon's Implied Assent statute, driving a motor vehicle in Oregon constitutes implied consent to:","explanation":"Under Oregon's Implied Permission statute, operating a motor vehicle on public roadways or on public property implies consent to chemical testing for alcohol and drugs at the request of a police officer. [Driving While Impaired; Dangerous Driving Habits; 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":601,"qid":26048,"description":"Which of the following terms does the state of Oregon use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"Driving Under the Influence of Intoxicants (DUII) in Oregon refers to driving a vehicle while under the influence of alcohol or drugs. Other states use names like Driving While Impaired (DWI), Driving Under the Influence (DUI), or Driving While Intoxicated (DWI) (DWI). [Driving While Impaired; Dangerous Driving Habits; 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":13751,"isBookmarked":0,"aid":"12820#12821#13751#13752","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Driving While Impaired (DWI)"},
{"testId":1068,"qid":26048,"description":"Which of the following terms does the state of Oregon use to describe the crime of driving while intoxicated or under the influence of other drugs?","explanation":"Driving Under the Influence of Intoxicants (DUII) in Oregon refers to driving a vehicle while under the influence of alcohol or drugs. Other states use names like Driving While Impaired (DWI), Driving Under the Influence (DUI), or Driving While Intoxicated (DWI) (DWI). [Driving While Impaired; Dangerous Driving Habits; 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":13751,"isBookmarked":0,"aid":"12820#12821#13751#13752","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Driving While Impaired (DWI)"},
{"testId":601,"qid":26049,"description":"Is it legal to use a cell phone while driving for a driver under the age of 18?","explanation":"A motorist under the age of 18 is not permitted to use a mobile phone while driving, even if it is hands-free. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#23333#39388#39389","text":"No#Yes, at any time#Yes, but only a hands-free device#Yes, but only if the driver is accompanied by someone 18 years of age or older"},
{"testId":1068,"qid":26049,"description":"Is it legal to use a cell phone while driving for a driver under the age of 18?","explanation":"A motorist under the age of 18 is not permitted to use a mobile phone while driving, even if it is hands-free. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#23333#39388#39389","text":"No#Yes, at any time#Yes, but only a hands-free device#Yes, but only if the driver is accompanied by someone 18 years of age or older"},
{"testId":601,"qid":26050,"description":"A driver over the age of 21 will fail an alcohol chemical test if his or her blood alcohol content (BAC) is _____ or higher.","explanation":"A motorist over the age of 21 will fail an alcohol chemical test if his or her blood alcohol level (BAC) is 0.08 percent or above. If you fail an alcohol chemical test, you will be charged with driving under the influence of intoxicants (DUII), and your license will be revoked for at least 90 days if it is your first DUII. If convicted, your license will be suspended for at least another year, and you may face extra criminal consequences. [Driving Under the Influence; Dangerous Driving Behaviors; 7. Oregon Driver Manual], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":1068,"qid":26050,"description":"A driver over the age of 21 will fail an alcohol chemical test if his or her blood alcohol content (BAC) is _____ or higher.","explanation":"A motorist over the age of 21 will fail an alcohol chemical test if his or her blood alcohol level (BAC) is 0.08 percent or above. If you fail an alcohol chemical test, you will be charged with driving under the influence of intoxicants (DUII), and your license will be revoked for at least 90 days if it is your first DUII. If convicted, your license will be suspended for at least another year, and you may face extra criminal consequences. [Driving Under the Influence; Dangerous Driving Behaviors; 7. Oregon Driver Manual], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":601,"qid":26051,"description":"A driver under the age of 21 will fail an alcohol chemical test if his or her blood alcohol concentration (BAC) is higher than:","explanation":"A motorist under the age of 21 will fail an alcohol chemistry test if his or her blood alcohol concentration (BAC) is more than zero. If you fail an alcohol chemical test, you will be charged with driving under the influence of intoxicants (DUII), and your license will be revoked for at least 90 days if it is your first DUII. If convicted, your license will be suspended for at least another year, and you may face extra criminal consequences. [Driving Under the Influence; Dangerous Driving Behaviors; 7. Oregon Driver Manual], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23318#27698#27700#31711","text":"0.02%#0.04%#0.03%#Zero"},
{"testId":1068,"qid":26051,"description":"A driver under the age of 21 will fail an alcohol chemical test if his or her blood alcohol concentration (BAC) is higher than:","explanation":"A motorist under the age of 21 will fail an alcohol chemistry test if his or her blood alcohol concentration (BAC) is more than zero. If you fail an alcohol chemical test, you will be charged with driving under the influence of intoxicants (DUII), and your license will be revoked for at least 90 days if it is your first DUII. If convicted, your license will be suspended for at least another year, and you may face extra criminal consequences. [Driving Under the Influence; Dangerous Driving Behaviors; 7. Oregon Driver Manual], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23318#27698#27700#31711","text":"0.02%#0.04%#0.03%#Zero"},
{"testId":601,"qid":26052,"description":"If you fail an alcohol chemistry test, the police officer will seize your license and offer you a provisional driving permit valid for ___ days.","explanation":"If you fail an alcohol chemistry test, the police officer will seize your license and grant you a 30-day provisional driving permit. After it expires, your license suspension time (for example, 90 days for a first DUII offense) will commence. [\"Driving While Impaired\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx] ]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1068,"qid":26052,"description":"If you fail an alcohol chemistry test, the police officer will seize your license and offer you a provisional driving permit valid for ___ days.","explanation":"If you fail an alcohol chemistry test, the police officer will seize your license and grant you a 30-day provisional driving permit. After it expires, your license suspension time (for example, 90 days for a first DUII offense) will commence. [\"Driving While Impaired\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx] ]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":601,"qid":26053,"description":"If you fail an alcohol chemistry test, the DMV will suspend your license for at least:","explanation":"If you fail an alcohol chemistry test, the DMV will suspend your license for at least 90 days. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted of DUII in court, your license may be suspended for at least another year, as well as additional criminal punishments. [\"Driving While Impaired\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon_ .pdf]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1068,"qid":26053,"description":"If you fail an alcohol chemistry test, the DMV will suspend your license for at least:","explanation":"If you fail an alcohol chemistry test, the DMV will suspend your license for at least 90 days. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted of DUII in court, your license may be suspended for at least another year, as well as additional criminal punishments. [\"Driving While Impaired\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon_ .pdf]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":601,"qid":26054,"description":"If you fail an alcohol chemical test and have a prior alcohol-related charge within the last five years, the DMV will suspend your license for :","explanation":"If you fail an alcohol chemical test and have a previous alcohol-related offense within the last five years, the DMV will suspend your license for one year. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted, the court may suspend your license for another three years and impose additional criminal penalties. Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf], [ORS 809.428], [ORS 813]. .400]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1068,"qid":26054,"description":"If you fail an alcohol chemical test and have a prior alcohol-related charge within the last five years, the DMV will suspend your license for :","explanation":"If you fail an alcohol chemical test and have a previous alcohol-related offense within the last five years, the DMV will suspend your license for one year. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted, the court may suspend your license for another three years and impose additional criminal penalties. Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf], [ORS 809.428], [ORS 813]. .400]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":601,"qid":26056,"description":"If you are found guilty of fleeing the scene of an accident without stopping (often known as \"hit and run\"), you might face a jail sentence of up to ______even if no one was hurt in the collision.","explanation":"The related criminal charge in Oregon is \"failure to execute driving responsibilities while property is destroyed.\" If you are guilty of this violation, your license will be suspended for 90 days. Furthermore, this is a Class A misdemeanor punishable by up to 364 days in prison, a fine of up to $6,250, or both. However, if someone was hurt or killed in the accident, the crime will be deemed a felony, with heavier consequences. [ORS 161.615], [ORS 161.635], [ORS 809.428], and [ORS 811] are all Oregon statutes .700]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1068,"qid":26056,"description":"If you are found guilty of fleeing the scene of an accident without stopping (often known as \"hit and run\"), you might face a jail sentence of up to ______even if no one was hurt in the collision.","explanation":"The related criminal charge in Oregon is \"failure to execute driving responsibilities while property is destroyed.\" If you are guilty of this violation, your license will be suspended for 90 days. Furthermore, this is a Class A misdemeanor punishable by up to 364 days in prison, a fine of up to $6,250, or both. However, if someone was hurt or killed in the accident, the crime will be deemed a felony, with heavier consequences. [ORS 161.615], [ORS 161.635], [ORS 809.428], and [ORS 811] are all Oregon statutes .700]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":601,"qid":26057,"description":"If you are convicted of DUII violation for the second time within five years, your license will be revoked for:","explanation":"If you are convicted of a second DUII within five years, your license will be suspended for three years by the DMV or the court. [\"Oregon Suspension/Revocation/Cancellation Guide,\" Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1068,"qid":26057,"description":"If you are convicted of DUII violation for the second time within five years, your license will be revoked for:","explanation":"If you are convicted of a second DUII within five years, your license will be suspended for three years by the DMV or the court. [\"Oregon Suspension/Revocation/Cancellation Guide,\" Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":601,"qid":26058,"description":"You will be fined _______,if you refuse to take a chemical test for intoxicants and are found guilty.","explanation":"If you are found guilty of refusing to submit to a chemical test for intoxicants, the court will levy a $650 presumptive punishment. This fee is in addition to any other fines or penalties imposed as a consequence of a DUII arrest. [ORS 813 .095]","imageName":"","correctAnswer":31697,"isBookmarked":0,"aid":"23887#31697#33102#39390","text":"$500#$650#$300#$450"},
{"testId":1068,"qid":26058,"description":"You will be fined _______,if you refuse to take a chemical test for intoxicants and are found guilty.","explanation":"If you are found guilty of refusing to submit to a chemical test for intoxicants, the court will levy a $650 presumptive punishment. This fee is in addition to any other fines or penalties imposed as a consequence of a DUII arrest. [ORS 813 .095]","imageName":"","correctAnswer":31697,"isBookmarked":0,"aid":"23887#31697#33102#39390","text":"$500#$650#$300#$450"},
{"testId":601,"qid":26059,"description":"If you refuse to submit to an alcohol chemical test after being convicted of DUII within the last five years, your license will be suspended for :","explanation":"If you refuse to take an alcohol chemistry test after being convicted of DUII within the last five years, the DMV will suspend your license for three years. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted of DUII for the second time, the court may suspend your license for another three years in addition to imposing additional criminal punishments. [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/Forms/DMV/7484.html] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1068,"qid":26059,"description":"If you refuse to submit to an alcohol chemical test after being convicted of DUII within the last five years, your license will be suspended for :","explanation":"If you refuse to take an alcohol chemistry test after being convicted of DUII within the last five years, the DMV will suspend your license for three years. It is important to note that this is an administrative punishment, not a criminal one. If you are convicted of DUII for the second time, the court may suspend your license for another three years in addition to imposing additional criminal punishments. [\"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/Forms/DMV/7484.html] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":601,"qid":26060,"description":"Your license will be suspended for______ if you are convicted of DUII for the second time in five years.","explanation":"If you are convicted of DUII for the second time in five years, your license will be suspended for three years and you will face further criminal consequences. [\"Driving While Impaired\"; \"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1068,"qid":26060,"description":"Your license will be suspended for______ if you are convicted of DUII for the second time in five years.","explanation":"If you are convicted of DUII for the second time in five years, your license will be suspended for three years and you will face further criminal consequences. [\"Driving While Impaired\"; \"Oregon Suspension/Revocation/Cancellation Guide\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":601,"qid":26061,"description":null,"explanation":"Under Oregon's Implied Permission statute, operating a motor vehicle on public roadways or on public property implies consent to chemical testing for alcohol and drugs at the request of a police officer. If you fail such a test, you may face DUII charges. [\"Driving While Under the Influence of Intoxicants\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Driving While Under the Influence of Intoxicants\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon. ]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17904#39391#39392","text":"All of the above#A dirt road#A shopping center parking lot#A toll road"},
{"testId":1068,"qid":26061,"description":null,"explanation":"Under Oregon's Implied Permission statute, operating a motor vehicle on public roadways or on public property implies consent to chemical testing for alcohol and drugs at the request of a police officer. If you fail such a test, you may face DUII charges. [\"Driving While Under the Influence of Intoxicants\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Driving While Under the Influence of Intoxicants\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon. ]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17904#39391#39392","text":"All of the above#A dirt road#A shopping center parking lot#A toll road"},
{"testId":601,"qid":26063,"description":"Which of the following distracted driving statements is FALSE?","explanation":"Drivers under the age of 18 are not permitted to use a cell phone or other mobile electronic device while driving a vehicle. Turn it off or leave it in the back seat. [Distracted Driving; Dangerous Driving Habits; Safe and Responsible Driving; Oregon Driver Handbook]","imageName":"","correctAnswer":41923,"isBookmarked":0,"aid":"41922#41923#41924#41925","text":"It is illegal to play a radio that can be heard 50 feet away from your vehicle#Drivers under 18 may only use hands-free phones#Drivers over 18 may only use hands-free phones#Drivers may not hold pets on their lap while driving"},
{"testId":601,"qid":26064,"description":"It is unlawful to play the radio at a volume that can be heard ______feet away from the vehicle.","explanation":"It is forbidden to listen to a radio or other sound system that is loud enough to be heard 50 feet away from your car. Hearing horns, screaming tires, and sirens is essential. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5446#5448#6477#7571","text":"10#20#30#50"},
{"testId":601,"qid":26065,"description":"A condition in the back seat demands your attention while you are driving. You ought to:","explanation":"Turning around to deal with youngsters, other passengers, or pets should not take your eyes off the road. If you must deal with issues in the back seat, pull over and park your car first. 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":39398,"isBookmarked":0,"aid":"25228#39397#39398#39399","text":"turn around and deal with the situation while glancing ahead from time to time#move the rear-view mirror so you can see the rear seat#pull over and park your vehicle, then deal with the situation#slow down and deal with the situation while you drive"},
{"testId":601,"qid":26066,"description":"If convicted of DUII offense for the 3rd time, your driver's license will be revoked for:","explanation":"If you are convicted of a third DUII, your license will be revoked permanently, and you will face additional criminal consequences, including prison time. However, after 10 years, you may ask the court to have your driving rights restored. You must have served your prison term or be on probation or parole to be eligible to submit this petition. Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf], [ORS 809]. .235]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"28011#29895#32619#32620","text":"15 years#10 years#20 years#Life"},
{"testId":1068,"qid":26066,"description":"If convicted of DUII offense for the 3rd time, your driver's license will be revoked for:","explanation":"If you are convicted of a third DUII, your license will be revoked permanently, and you will face additional criminal consequences, including prison time. However, after 10 years, you may ask the court to have your driving rights restored. You must have served your prison term or be on probation or parole to be eligible to submit this petition. Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf], [ORS 809]. .235]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"28011#29895#32619#32620","text":"15 years#10 years#20 years#Life"},
{"testId":601,"qid":26067,"description":"If you are convicted of DUII for the first time, you could face up to_____ in jail.","explanation":"If you are convicted of DUII for the first time, your license will be suspended for one year and you will be fined at least $1,000. You will also be sentenced to between 48 hours and one year in prison, or between 80 hours and 250 hours of community service, by the court. [ORS 137.129], [ORS 813.010], [ORS 813.011], [ORS 813.012] .020]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23152#23153#23154#25170","text":"3 months#6 months#1 year#1 month"},
{"testId":1068,"qid":26067,"description":"If you are convicted of DUII for the first time, you could face up to_____ in jail.","explanation":"If you are convicted of DUII for the first time, your license will be suspended for one year and you will be fined at least $1,000. You will also be sentenced to between 48 hours and one year in prison, or between 80 hours and 250 hours of community service, by the court. [ORS 137.129], [ORS 813.010], [ORS 813.011], [ORS 813.012] .020]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23152#23153#23154#25170","text":"3 months#6 months#1 year#1 month"},
{"testId":601,"qid":26069,"description":"First-time offenders of road rage (deliberately making threats while driving) receive which of the following punishments?","explanation":"Road rage is defined as driving conduct that threatens another person. The appropriate criminal charge in Oregon is \"menacing.\" If you are found guilty of threatening while driving on your first offense, the court will suspend your license for 90 days. Furthermore, threatening is a Class A misdemeanor punishable by up to 364 days in prison, a $6,250 fine, or both. [Road Rage; Dangerous Driving Behaviors; 7. Safe and Responsible Driving; Oregon Driver Manual], [ORS 161.615], [ORS 161.635], [ORS 163.190], [ORS 809.411], [ORS 809.412], [ORS 809.413], [ORS 809.414], [ORS 809.415], [ORS 809.415], [ORS 809. .428]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#39404#39405#39406","text":"All of the above#License suspension for 90 days#A $6,000 fine#A year in jail"},
{"testId":1068,"qid":26069,"description":"First-time offenders of road rage (deliberately making threats while driving) receive which of the following punishments?","explanation":"Road rage is defined as driving conduct that threatens another person. The appropriate criminal charge in Oregon is \"menacing.\" If you are found guilty of threatening while driving on your first offense, the court will suspend your license for 90 days. Furthermore, threatening is a Class A misdemeanor punishable by up to 364 days in prison, a $6,250 fine, or both. [Road Rage; Dangerous Driving Behaviors; 7. Safe and Responsible Driving; Oregon Driver Manual], [ORS 161.615], [ORS 161.635], [ORS 163.190], [ORS 809.411], [ORS 809.412], [ORS 809.413], [ORS 809.414], [ORS 809.415], [ORS 809.415], [ORS 809. .428]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#39404#39405#39406","text":"All of the above#License suspension for 90 days#A $6,000 fine#A year in jail"},
{"testId":601,"qid":26070,"description":"Your license will be suspended for an additional _____聽if you fail to install an IID after a first DUII conviction.","explanation":"If you are convicted of your first DUII, the court may order you to install an ignition interlock device (IID) in each car you drive for a year after your driving privileges are restored. If you are convicted of neglecting to install it, your license will be suspended for another year. ORS 813.602 and 813 .620]","imageName":"","correctAnswer":27048,"isBookmarked":0,"aid":"20788#23153#23183#27048","text":"2 years#6 months#3 years#year"},
{"testId":601,"qid":26071,"description":"After a first DUII conviction, if you are guilty of tampering with an ignition interlock device (IID), your license will be revoked for an extra period of a _____.","explanation":"If you are convicted of your first DUII, the court may order you to install an ignition interlock device (IID) in each car you drive for a year after your driving privileges are restored. If you are convicted of tampering with it after you have installed it, your license will be suspended for another year. ORS 813.602 and 813 .620]","imageName":"","correctAnswer":27048,"isBookmarked":0,"aid":"20788#23153#23183#27048","text":"2 years#6 months#3 years#year"},
{"testId":601,"qid":26072,"description":"While driving, you may have an open container of alcohol _____.","explanation":"According to Oregon's Open Container law, you may not keep an open container of alcohol within your or any passengers' reach. In reality, this means keeping open containers of alcohol in the trunk. [Driving While Impaired; Dangerous Driving Habits; 7. Safe and Responsible Driving; Oregon Driver Manual]","imageName":"","correctAnswer":30372,"isBookmarked":0,"aid":"30369#30372#39407#39408","text":"in the glove compartment#in the trunk#under the passenger seat#in the rear seat"},
{"testId":601,"qid":26073,"description":"For hit and run offenses, you might face a punishment of up to _______ per dog or cat that is injured or killed by your car.","explanation":"The related criminal violation in Oregon is \"failure to execute driving responsibilities when [domestic] animal is hurt.\" This infraction is a Class B misdemeanor, as opposed to the considerably more severe charge of \"failure to execute responsibilities of driver to wounded individuals.\" Your license will be suspended for 90 days, and you might face a $2,500 fine, up to six months in prison, or both. [ORS 161.615], [ORS 161.635], [ORS 809.428], and [ORS 811] are all Oregon statutes .700]","imageName":"","correctAnswer":27889,"isBookmarked":0,"aid":"23887#23889#27888#27889","text":"$500#$1,000#$250#$2,500"},
{"testId":1068,"qid":26073,"description":"For hit and run offenses, you might face a punishment of up to _______ per dog or cat that is injured or killed by your car.","explanation":"The related criminal violation in Oregon is \"failure to execute driving responsibilities when [domestic] animal is hurt.\" This infraction is a Class B misdemeanor, as opposed to the considerably more severe charge of \"failure to execute responsibilities of driver to wounded individuals.\" Your license will be suspended for 90 days, and you might face a $2,500 fine, up to six months in prison, or both. [ORS 161.615], [ORS 161.635], [ORS 809.428], and [ORS 811] are all Oregon statutes .700]","imageName":"","correctAnswer":27889,"isBookmarked":0,"aid":"23887#23889#27888#27889","text":"$500#$1,000#$250#$2,500"},
{"testId":601,"qid":26074,"description":"If you are found to be driving under the influence of __________, you will be convicted of DUII.","explanation":"Some prescription drugs, some OTC drugs, inhalants, and marijuana may impair your ability to drive and result in a DUII conviction. [Oregon Driver Manual] [Impaired Driving; Dangerous Driving Behaviors; 7. Safe and Responsible Driving]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#39409#39410","text":"Any of the above#marijuana#over-the-counter medications#inhalants"},
{"testId":1068,"qid":26074,"description":"If you are found to be driving under the influence of __________, you will be convicted of DUII.","explanation":"Some prescription drugs, some OTC drugs, inhalants, and marijuana may impair your ability to drive and result in a DUII conviction. [Oregon Driver Manual] [Impaired Driving; Dangerous Driving Behaviors; 7. Safe and Responsible Driving]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#27076#39409#39410","text":"Any of the above#marijuana#over-the-counter medications#inhalants"},
{"testId":601,"qid":26075,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. What about just strolling? The chance of a pedestrian getting injured in an accident really rises when they are walking while intoxicated. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had a blood alcohol concentration (BAC) of 0.08 percent or higher, compared to 37 percent of pedestrians. [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]], \"Impaired Driving; Dangerous Driving Behaviors; 7. Safe and Responsible Driving\"]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":1068,"qid":26075,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. What about just strolling? The chance of a pedestrian getting injured in an accident really rises when they are walking while intoxicated. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had a blood alcohol concentration (BAC) of 0.08 percent or higher, compared to 37 percent of pedestrians. [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]], \"Impaired Driving; Dangerous Driving Behaviors; 7. Safe and Responsible Driving\"]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":601,"qid":26076,"description":"If you are involved in a crash that results in a vehicle being towed and more than ______  in vehicle damage, you must file an accident report with the DMV.","explanation":"If you are involved in a crash that results in a vehicle being towed and more than $2,500 in vehicle damage, you must file an accident report with the DMV within 72 hours. If there is more than $2,500 in property damage or someone is hurt or killed, you must additionally submit an accident report. [Congestion; Your Responsibilities; Oregon Driver Manual]","imageName":"202006141611292838.jpg","correctAnswer":11670,"isBookmarked":0,"aid":"290#11668#11670#39412","text":"$500#$1,500#$2,500#$3,500"},
{"testId":601,"qid":26077,"description":"The DMV will suspend your license for any of the following conviction EXCEPT :","explanation":"Theft in the third degree is defined by Oregon law as theft of property worth less than $100, and theft in the second degree is defined as theft of property worth at least $100 but less than $1,000. These are both minor offenses. However, if the theft was of fuel, the DMV would suspend your license. If you are convicted of giving your license to someone else, the DMV will likewise suspend it. [ORS 164.043], [ORS 164.045], [ORS 807.590], [ORS 809.310], [ORS 809] are all examples of Oregon statutes .411]","imageName":"","correctAnswer":39414,"isBookmarked":0,"aid":"11621#39413#39414#39415","text":"Reckless driving#Stealing $50 worth of gasoline#Stealing $500 worth of retail merchandise#Letting someone else use your license"},
{"testId":1068,"qid":26077,"description":"The DMV will suspend your license for any of the following conviction EXCEPT :","explanation":"Theft in the third degree is defined by Oregon law as theft of property worth less than $100, and theft in the second degree is defined as theft of property worth at least $100 but less than $1,000. These are both minor offenses. However, if the theft was of fuel, the DMV would suspend your license. If you are convicted of giving your license to someone else, the DMV will likewise suspend it. [ORS 164.043], [ORS 164.045], [ORS 807.590], [ORS 809.310], [ORS 809] are all examples of Oregon statutes .411]","imageName":"","correctAnswer":39414,"isBookmarked":0,"aid":"11621#39413#39414#39415","text":"Reckless driving#Stealing $50 worth of gasoline#Stealing $500 worth of retail merchandise#Letting someone else use your license"},
{"testId":601,"qid":26078,"description":"If you are guilty of driving without insurance, for how many years must you provide proof of financial responsibility?","explanation":"Driving without insurance is a Class B traffic offence in Oregon. If you are convicted, you may be fined up to $1,000 and your license will be revoked for the following three years until you begin to provide evidence of financial responsibility. Typically, this is accomplished by purchasing a car liability insurance coverage. A conviction for uninsured driving, on the other hand, marks you as a high-risk motorist. Each year, you must acquire SR-22 coverage (for which rates are likely to be higher) and submit an SR-22 form with the DMV. [\"SR-22 Information\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/pages/driverid/sr22.aspx], [ORS 153.018], and [ORS 806]. .010]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":1068,"qid":26078,"description":"If you are guilty of driving without insurance, for how many years must you provide proof of financial responsibility?","explanation":"Driving without insurance is a Class B traffic offence in Oregon. If you are convicted, you may be fined up to $1,000 and your license will be revoked for the following three years until you begin to provide evidence of financial responsibility. Typically, this is accomplished by purchasing a car liability insurance coverage. A conviction for uninsured driving, on the other hand, marks you as a high-risk motorist. Each year, you must acquire SR-22 coverage (for which rates are likely to be higher) and submit an SR-22 form with the DMV. [\"SR-22 Information\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/pages/driverid/sr22.aspx], [ORS 153.018], and [ORS 806]. .010]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":601,"qid":26079,"description":"Which drivers must be insured in Oregon?","explanation":"Vehicle liability insurance is required for all drivers, regardless of age, aptitude, or experience. An underage driver may be added to his or her parent's or guardian's liability insurance policy. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"1384#10424#39416#39417","text":"Drivers under 21#All drivers#Drivers under 18#Owners of vehicles that are less than five years old"},
{"testId":1068,"qid":26079,"description":"Which drivers must be insured in Oregon?","explanation":"Vehicle liability insurance is required for all drivers, regardless of age, aptitude, or experience. An underage driver may be added to his or her parent's or guardian's liability insurance policy. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"1384#10424#39416#39417","text":"Drivers under 21#All drivers#Drivers under 18#Owners of vehicles that are less than five years old"},
{"testId":601,"qid":26080,"description":"Your vehicle liability insurance policy in Oregon must offer at least ______ in coverage for physical injuries to one person in any one collision.","explanation":"Your car liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; (4) at least $15,000 per person in personal injury protection; and (5) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11658#12047#12048","text":"$5,000#$10,000#$15,000#$25,000"},
{"testId":601,"qid":26082,"description":"Which of the following penalties may you face if you cause an accident while driving uninsured?","explanation":"Driving without insurance is a Class B traffic offence in Oregon, punishable by a fine of up to $1,000. A police officer may also order the impoundment of your car until you provide evidence of financial responsibility to the DMV. (For the majority of drivers, \"financial responsibility\" takes the form of liability insurance.) If you are involved in an accident while driving uninsured, your license will be revoked until you provide the DMV with evidence of financial responsibility. ORS 806.010, 809.417, and 809.418 .720]","imageName":"202006141611292838.jpg","correctAnswer":32601,"isBookmarked":0,"aid":"32601#39418#39419#39420","text":"All of the above may occur#Your vehicle may be impounded#You may have to pay a fine#Your license may be suspended"},
{"testId":601,"qid":26083,"description":"What can happen if you get a traffic ticket but don't pay the money or show up to court?","explanation":"If you do not pay the fee or appear in court for a traffic infraction, the court may order that your license be suspended until you either pay the fine or are cleared of the offence in court. [ORS 809 .210]","imageName":"","correctAnswer":39420,"isBookmarked":0,"aid":"39420#39421#39422#39423","text":"Your license may be suspended#Your license may be revoked#The fine may be doubled#You may be sentenced to jail"},
{"testId":1068,"qid":26083,"description":"What can happen if you get a traffic ticket but don't pay the money or show up to court?","explanation":"If you do not pay the fee or appear in court for a traffic infraction, the court may order that your license be suspended until you either pay the fine or are cleared of the offence in court. [ORS 809 .210]","imageName":"","correctAnswer":39420,"isBookmarked":0,"aid":"39420#39421#39422#39423","text":"Your license may be suspended#Your license may be revoked#The fine may be doubled#You may be sentenced to jail"},
{"testId":601,"qid":26084,"description":"_______ is required to reinstate a suspended or revoked driver's license.","explanation":"If your driving privileges have been suspended or revoked, you must complete numerous conditions in order to have them restored. One of these conditions is the payment of a $75 license reinstatement fee. The other qualifications differ according on the circumstances behind the suspension or revocation. [\"Reinstatement Requirements,\" Oregon DMV, http://www.oregon.gov/ODOT/DMV/Pages/notices/Reinstatement Requirements.aspx]. ]","imageName":"","correctAnswer":12398,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":1068,"qid":26084,"description":"_______ is required to reinstate a suspended or revoked driver's license.","explanation":"If your driving privileges have been suspended or revoked, you must complete numerous conditions in order to have them restored. One of these conditions is the payment of a $75 license reinstatement fee. The other qualifications differ according on the circumstances behind the suspension or revocation. [\"Reinstatement Requirements,\" Oregon DMV, http://www.oregon.gov/ODOT/DMV/Pages/notices/Reinstatement Requirements.aspx]. ]","imageName":"","correctAnswer":12398,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":601,"qid":26087,"description":"Your vehicle liability insurance policy in Oregon must include uninsured motorist coverages of at least _________ for bodily harm.","explanation":"Your car liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; (4) at least $15,000 per person in personal injury protection; and (5) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":39426,"isBookmarked":0,"aid":"39424#39425#39426#39427","text":"$10,000 per person and $20,000 per crash#$15,000 per person and $30,000 per crash#$25,000 per person and $50,000 per crash#$20,000 per person and $50,000 per crash"},
{"testId":601,"qid":26088,"description":"If you are in an accident that causes bodily harm or death, you must file an accident report with the DMV.","explanation":"If you must file an accident report with the DMV, you must do it within 72 hours after the collision. Filing a police report DOES NOT absolve you of the need to submit your own accident report. [Traffic Accidents; Other Information; Oregon Driver Handbook]","imageName":"202006141611292838.jpg","correctAnswer":39428,"isBookmarked":0,"aid":"37206#37207#39428#39429","text":"Both (a) and (b)#Neither (a) nor (b)#(a) within 72 hours of the accident#(b) unless a police officer files a police report"},
{"testId":601,"qid":26089,"description":"Your license may be suspended ___________, if you fail to pay three months or more of court-ordered child support totaling at least $2,500.","explanation":"If you fail to pay three months or more of court-ordered child support totaling at least $2,500, your license may be revoked and additional sanctions imposed until you pay what you owe. After that, the Child Support Enforcement Division or a District Attorney will permit the DMV to restore your driving privileges for a cost of $75. [\"Failure to Pay Child Support\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Enforcement\"; Oregon Child Support Program; https://www.doj.state.oregon.us/child-support/services/enforcement/]","imageName":"","correctAnswer":39431,"isBookmarked":0,"aid":"22068#22069#39430#39431","text":"for six months#for one year#until you make your first payment#until you pay what you owe"},
{"testId":1068,"qid":26089,"description":"Your license may be suspended ___________, if you fail to pay three months or more of court-ordered child support totaling at least $2,500.","explanation":"If you fail to pay three months or more of court-ordered child support totaling at least $2,500, your license may be revoked and additional sanctions imposed until you pay what you owe. After that, the Child Support Enforcement Division or a District Attorney will permit the DMV to restore your driving privileges for a cost of $75. [\"Failure to Pay Child Support\"; \"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx], [\"Enforcement\"; Oregon Child Support Program; https://www.doj.state.oregon.us/child-support/services/enforcement/]","imageName":"","correctAnswer":39431,"isBookmarked":0,"aid":"22068#22069#39430#39431","text":"for six months#for one year#until you make your first payment#until you pay what you owe"},
{"testId":601,"qid":26090,"description":"Your license will be suspended if you have _________ within the two-year period.","explanation":"If you have four traffic accidents or convictions in a 24-month period, the DMV will suspend your license for 30 days and force you to enroll in the Driver Improvement Program. [\"Driver Improvement\"; \"Suspension, Revocation, and Cancellation\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/Pages/DriverID/suspreasons.aspx] ]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#39432#39433#39434","text":"Any of the above#four traffic accidents#four traffic convictions#two traffic accidents and two traffic convictions"},
{"testId":1068,"qid":26090,"description":"Your license will be suspended if you have _________ within the two-year period.","explanation":"If you have four traffic accidents or convictions in a 24-month period, the DMV will suspend your license for 30 days and force you to enroll in the Driver Improvement Program. [\"Driver Improvement\"; \"Suspension, Revocation, and Cancellation\"; Oregon DMV; https://www.oregon.gov/ODOT/DMV/Pages/DriverID/suspreasons.aspx] ]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#39432#39433#39434","text":"Any of the above#four traffic accidents#four traffic convictions#two traffic accidents and two traffic convictions"},
{"testId":601,"qid":26091,"description":"Which of the following traffic offenses carries the highest possible fine in Oregon?","explanation":"Failure to stop for a halted school bus with flashing red lights is a Class A traffic offence in Oregon. Class B crimes include ignoring a police officer directing traffic, disregarding One Way signs, and failing to stop for a blind person. The following are the assumed penalty for traffic offences. Class A costs $440, Class B costs $265, Class C costs $165, and Class D costs $115. The following are the maximum penalties for traffic offences. $2,000 for Class A; $1,000 for Class B; $500 for Class C; and $250 for Class D. [ORS 153.018], [ORS 153.019], [ORS 811.155], [ORS 811.270], [ORS 811] .535]","imageName":"","correctAnswer":39436,"isBookmarked":0,"aid":"38320#39435#39436#39437","text":"Driving the wrong way on a one-way street#Failing to stop for a blind pedestrian crossing the road#Failing to stop for a stopped school bus with flashing red lights on the same roadway#Disobeying a police officer directing traffic"},
{"testId":1068,"qid":26091,"description":"Which of the following traffic offenses carries the highest possible fine in Oregon?","explanation":"Failure to stop for a halted school bus with flashing red lights is a Class A traffic offence in Oregon. Class B crimes include ignoring a police officer directing traffic, disregarding One Way signs, and failing to stop for a blind person. The following are the assumed penalty for traffic offences. Class A costs $440, Class B costs $265, Class C costs $165, and Class D costs $115. The following are the maximum penalties for traffic offences. $2,000 for Class A; $1,000 for Class B; $500 for Class C; and $250 for Class D. [ORS 153.018], [ORS 153.019], [ORS 811.155], [ORS 811.270], [ORS 811] .535]","imageName":"","correctAnswer":39436,"isBookmarked":0,"aid":"38320#39435#39436#39437","text":"Driving the wrong way on a one-way street#Failing to stop for a blind pedestrian crossing the road#Failing to stop for a stopped school bus with flashing red lights on the same roadway#Disobeying a police officer directing traffic"},
{"testId":601,"qid":26092,"description":"Can the Oregon DMV restrict your driving privileges if a judge in another state convicted you of driving under the influence (DUI) in that state?","explanation":"If (1) another state suspends or revokes your driving privileges, or (2) another state convicts you of an act that would be grounds for license suspension or revocation if committed in Oregon, the Oregon DMV will suspend or revoke your license. The DMV will not reinstate your driving privileges until you have either (1) had your driving privileges restored by the other state or (2) paid all fines levied by the other state. [ORS 809 .400]","imageName":"","correctAnswer":21234,"isBookmarked":0,"aid":"18118#21234#39438#39439","text":"No#Yes#Yes, but only after two such convictions in the past five years#Yes, but only after three such convictions in the past five years"},
{"testId":1068,"qid":26092,"description":"Can the Oregon DMV restrict your driving privileges if a judge in another state convicted you of driving under the influence (DUI) in that state?","explanation":"If (1) another state suspends or revokes your driving privileges, or (2) another state convicts you of an act that would be grounds for license suspension or revocation if committed in Oregon, the Oregon DMV will suspend or revoke your license. The DMV will not reinstate your driving privileges until you have either (1) had your driving privileges restored by the other state or (2) paid all fines levied by the other state. [ORS 809 .400]","imageName":"","correctAnswer":21234,"isBookmarked":0,"aid":"18118#21234#39438#39439","text":"No#Yes#Yes, but only after two such convictions in the past five years#Yes, but only after three such convictions in the past five years"},
{"testId":601,"qid":26095,"description":"If you decline to submit to an alcohol chemical test and a police officer serves you a notice of intent to suspend your license, you have the right to :","explanation":"If a police officer provides you a notice of intent to suspend your license, you have the right to a hearing to question the legitimacy of the suspension before it takes effect under Oregon's Implied Consent legislation. Send a written request to the Oregon Department of Transportation to seek a hearing. Your license will not be suspended if you win your case at the hearing. [ORS 813.100], [ORS 813.101], .130]","imageName":"","correctAnswer":25275,"isBookmarked":0,"aid":"8626#25273#25275#39440","text":"None of the above#remain silent#a hearing#ask the officer for a postponement"},
{"testId":1068,"qid":26095,"description":"If you decline to submit to an alcohol chemical test and a police officer serves you a notice of intent to suspend your license, you have the right to :","explanation":"If a police officer provides you a notice of intent to suspend your license, you have the right to a hearing to question the legitimacy of the suspension before it takes effect under Oregon's Implied Consent legislation. Send a written request to the Oregon Department of Transportation to seek a hearing. Your license will not be suspended if you win your case at the hearing. [ORS 813.100], [ORS 813.101], .130]","imageName":"","correctAnswer":25275,"isBookmarked":0,"aid":"8626#25273#25275#39440","text":"None of the above#remain silent#a hearing#ask the officer for a postponement"},
{"testId":602,"qid":26096,"description":"To preserve your driving privileges, you must pass a special written point examination if you acquire at least ___ points for the first time.","explanation":"If you acquire at least six points for the first time, PennDOT will need you to pass a special written point assessment. The test will assess your understanding of safe driving habits, departmental punishments, and associated safety problems. If you do not pass the test within 30 days, your driver's license will be suspended until you do. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS .pdf]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":602,"qid":26097,"description":"PennDOT will ________聽if you have accumulated at least six points for the second time.","explanation":null,"imageName":"","correctAnswer":39780,"isBookmarked":0,"aid":"10656#39780#39781#39782","text":"Do all of the above#require you to attend a departmental hearing#require you to pass a special written point examination to keep your driving privileges#suspend your driver's license"},
{"testId":602,"qid":26098,"description":"A driver under the age of 18 will have his or her driving license suspended if he or she gets at least ___ points.","explanation":"A motorist under the age of 18 will have his or her license suspended for 90 days if he or she gets at least six points. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":602,"qid":26099,"description":"If a driver under the age of 18 is convicted of exceeding the posted speed limit by ___ mph, his or her license will be suspended.","explanation":"If a motorist under the age of 18 is convicted of exceeding the stated speed limit by 26 miles per hour or more, his or her license will be suspended for 90 days on the first offense. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":13412,"isBookmarked":0,"aid":"11343#11360#11361#13412","text":"11#21#16#26"},
{"testId":1069,"qid":26099,"description":"If a driver under the age of 18 is convicted of exceeding the posted speed limit by ___ mph, his or her license will be suspended.","explanation":"If a motorist under the age of 18 is convicted of exceeding the stated speed limit by 26 miles per hour or more, his or her license will be suspended for 90 days on the first offense. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":13412,"isBookmarked":0,"aid":"11343#11360#11361#13412","text":"11#21#16#26"},
{"testId":602,"qid":26100,"description":"The first suspension of a motorist under the age of 18 for excessive speeding or point accumulation will last for _____ days.","explanation":"A motorist under the age of 18 will have his or her license suspended for the first time for speeding or accumulating too many points. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1069,"qid":26100,"description":"The first suspension of a motorist under the age of 18 for excessive speeding or point accumulation will last for _____ days.","explanation":"A motorist under the age of 18 will have his or her license suspended for the first time for speeding or accumulating too many points. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":602,"qid":26101,"description":"The suspension of a driver's licence for the second time for speeding or excessive point accumulation will last _____ days.","explanation":"The suspension of a driver's license for the second time for speeding or accumulating too many points will last 120 days. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6355#6356#6802#8141","text":"90#120#180#60"},
{"testId":1069,"qid":26101,"description":"The suspension of a driver's licence for the second time for speeding or excessive point accumulation will last _____ days.","explanation":"The suspension of a driver's license for the second time for speeding or accumulating too many points will last 120 days. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6355#6356#6802#8141","text":"90#120#180#60"},
{"testId":602,"qid":26102,"description":"Even if you have a driver's license that only allows you to drive only聽with corrective lenses, if you are caught driving without them (either glasses or contacts), points will be added to your driving record.","explanation":"If you are convicted of breaching your driver's license limitations (such as the need to wear corrective glasses), two points will be added to your driving record. The fewest amount of points for any one infraction is two. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":602,"qid":26104,"description":"__ points will be added to your driving record for running a red light.","explanation":"Three points will be added to your driving record if you are guilty of failing to stop at a stop sign, a flashing red traffic light, or a steady red traffic light. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":26104,"description":"__ points will be added to your driving record for running a red light.","explanation":"Three points will be added to your driving record if you are guilty of failing to stop at a stop sign, a flashing red traffic light, or a steady red traffic light. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":26105,"description":"Each conviction for unauthorized passing often carries _____ points.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":26105,"description":"Each conviction for unauthorized passing often carries _____ points.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":26107,"description":"How many points will be added to your driving record for running a stop sign or red light?","explanation":"Three points will be added to your driving record if you are guilty of failing to stop at a stop sign, a flashing red traffic light, or a steady red traffic light. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":26107,"description":"How many points will be added to your driving record for running a stop sign or red light?","explanation":"Three points will be added to your driving record if you are guilty of failing to stop at a stop sign, a flashing red traffic light, or a steady red traffic light. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":26108,"description":"Driving past a lowered railroad crossing gate will have your driver's license suspended for ___ and ____ points added to your driving record.","explanation":null,"imageName":"","correctAnswer":39785,"isBookmarked":0,"aid":"12254#39783#39784#39785","text":"10; 3#10; 4#15; 3#30; 4"},
{"testId":1069,"qid":26108,"description":"Driving past a lowered railroad crossing gate will have your driver's license suspended for ___ and ____ points added to your driving record.","explanation":null,"imageName":"","correctAnswer":39785,"isBookmarked":0,"aid":"12254#39783#39784#39785","text":"10; 3#10; 4#15; 3#30; 4"},
{"testId":602,"qid":26111,"description":"If you are guilty of exceeding the posted speed limit by up to ___ mph, two points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1069,"qid":26111,"description":"If you are guilty of exceeding the posted speed limit by up to ___ mph, two points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":602,"qid":26112,"description":"If you're caught driving 11 mph over the speed聽limit in a work zone, your license will be suspended for __ days.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":1069,"qid":26112,"description":"If you're caught driving 11 mph over the speed聽limit in a work zone, your license will be suspended for __ days.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":602,"qid":26113,"description":"If you're caught exceeding the speed limit by up to聽____, three points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6425#11360","text":"15#20#25#21"},
{"testId":1069,"qid":26113,"description":"If you're caught exceeding the speed limit by up to聽____, three points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6425#11360","text":"15#20#25#21"},
{"testId":602,"qid":26116,"description":"If you are guilty of exceeding the posted speed limit by ___ mph, PennDOT will summon you to a departmental hearing.","explanation":null,"imageName":"","correctAnswer":13709,"isBookmarked":0,"aid":"5447#11360#13412#13709","text":"15#21#26#31"},
{"testId":602,"qid":26118,"description":"Careless driving adds ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":26118,"description":"Careless driving adds ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":26119,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that damaged a vehicle that was being driven by another person but did not result in any injuries, you could be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1069,"qid":26119,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that damaged a vehicle that was being driven by another person but did not result in any injuries, you could be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":602,"qid":26120,"description":"If you drive without a violation for 12 months in a row, ___ points will be deducted from your driving record.","explanation":"Three points will be deducted from your driving record for every 12 months of violation-free driving. If your point total stays at zero for 12 months after it has been lowered to zero, any future point accumulation will be counted as your first point accumulation. [Safe Driving Point Removal; The Pennsylvania Point System; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":26120,"description":"If you drive without a violation for 12 months in a row, ___ points will be deducted from your driving record.","explanation":"Three points will be deducted from your driving record for every 12 months of violation-free driving. If your point total stays at zero for 12 months after it has been lowered to zero, any future point accumulation will be counted as your first point accumulation. [Safe Driving Point Removal; The Pennsylvania Point System; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":26121,"description":"Once the points on a driver's record are lowered to zero and remain zero for ________, any future accumulation of points is considered the first accumulation of points.","explanation":"Three points will be deducted from your driving record for every 12 months of violation-free driving. If your point total stays at zero for 12 months after it has been lowered to zero, any future point accumulation will be counted as your first point accumulation. [Safe Driving Point Removal; The Pennsylvania Point System; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"229#230#1020#1272","text":"90 days#one year#60 days#6 months"},
{"testId":1069,"qid":26121,"description":"Once the points on a driver's record are lowered to zero and remain zero for ________, any future accumulation of points is considered the first accumulation of points.","explanation":"Three points will be deducted from your driving record for every 12 months of violation-free driving. If your point total stays at zero for 12 months after it has been lowered to zero, any future point accumulation will be counted as your first point accumulation. [Safe Driving Point Removal; The Pennsylvania Point System; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"229#230#1020#1272","text":"90 days#one year#60 days#6 months"},
{"testId":602,"qid":26122,"description":"Which of the following will cause your license to be suspended or taken away?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#27916#39786#39787","text":"All of the above#Vehicular homicide#Driving under the influence of alcohol or drugs (DUI)#Leaving the scene of an accident (hit and run)"},
{"testId":1069,"qid":26122,"description":"Which of the following will cause your license to be suspended or taken away?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#27916#39786#39787","text":"All of the above#Vehicular homicide#Driving under the influence of alcohol or drugs (DUI)#Leaving the scene of an accident (hit and run)"},
{"testId":602,"qid":26123,"description":"Which of the following conviction will not result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":39788,"isBookmarked":0,"aid":"39788#39789#39790#39791","text":"Speeding in a school zone#Speeding in an active work zone#Street racing#Careless driving"},
{"testId":1069,"qid":26123,"description":"Which of the following conviction will not result in the suspension of your driver's license?","explanation":null,"imageName":"","correctAnswer":39788,"isBookmarked":0,"aid":"39788#39789#39790#39791","text":"Speeding in a school zone#Speeding in an active work zone#Street racing#Careless driving"},
{"testId":602,"qid":26124,"description":"Your license will be suspended if you're caught driving without a valid license twice within:","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11306#11341#11432#11760","text":"3 years#5 years#4 years#10 years"},
{"testId":1069,"qid":26124,"description":"Your license will be suspended if you're caught driving without a valid license twice within:","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11306#11341#11432#11760","text":"3 years#5 years#4 years#10 years"},
{"testId":602,"qid":26125,"description":"Which drivers in Pennsylvania are required to have vehicle liability insurance on their registered vehicles?","explanation":"Every motorist in Pennsylvania is required by law to have vehicle liability insurance on his or her registered motor vehicle at all times. If PennDOT discovers that your vehicle was uninsured for more than 31 days, your registration will be suspended for three months. In addition, if PennDOT discovers that you drove the uninsured vehicle for 31 days or more, your driver's license will be suspended for three months. [Driving Without Insurance; The Pennsylvania Point System; Chapter 4: Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"10424#39792#39793#39794","text":"All drivers#Only drivers under the age of 21#Only drivers under the age of 18#Only drivers of vehicles that are less than 5 years old"},
{"testId":602,"qid":26126,"description":"If PennDOT discovers that you have been driving your car while it is uninsured, your license will be suspended for a period of:","explanation":"Every motorist in Pennsylvania is required by law to have vehicle liability insurance on his or her registered motor vehicle at all times. If PennDOT discovers that your vehicle was uninsured for more than 31 days, your registration will be suspended for three months. If PennDOT discovers that you drove an uninsured car, your driver's license will be suspended for three months. [Driving Without Insurance; The Pennsylvania Point System; Chapter 4: Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#27363#27897","text":"3 months#6 months#2 months#one month"},
{"testId":602,"qid":26127,"description":"According to the Pennsylvania Driver's Manual, intoxicated drivers account for roughly ____ of all traffic fatalities in the United States.","explanation":"According to the Pennsylvania Driver's Manual, intoxicated drivers account for roughly 40% of all road fatalities in the United States. (Note: According to the Centers for Disease Control and Prevention, this figure has dropped in recent years to 28 percent, but it still represents around 10,000 persons died each year in alcohol-related incidents.) [Alcohol and Driving, Driver Factors, Chapter 3: Learning to Drive; Pennsylvania Driver's Manual], [Centers for Disease Control and Prevention, \"Impaired Driving: Get the Facts,\" https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet .html]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#12162","text":"10%#40%#20%#30%"},
{"testId":602,"qid":26128,"description":"A driver who is at least 21 years old and has a blood alcohol content (BAC) of ______ or more may be charged with driving while intoxicated (DUI).","explanation":"A motorist over the age of 21 in Pennsylvania may be charged with driving under the influence of alcohol (DUI) if his or her blood alcohol content (BAC) is 0.08 percent or greater. Even if he or she has a lesser BAC, he or she might still be charged with DUI if stopped by police for driving recklessly. [Driving Under the Influence (DUI); Driving While Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4653","text":"0.08%#0.02%#0.05%#0.01%"},
{"testId":602,"qid":26129,"description":"A driver under the age of 21 who is found to have a blood alcohol content (BAC) of ______ or more may be prosecuted with driving while intoxicated (DUI).","explanation":"A motorist under the age of 21 in Pennsylvania may be charged with driving under the influence of alcohol (DUI) if his or her blood alcohol concentration (BAC) is 0.02 percent or greater. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (One normal drink equals roughly 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) [Driving Under the Influence (DUI); Driving While Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#13791#35229","text":"0.02%#0.01%#0.008%#Zero"},
{"testId":602,"qid":26130,"description":"To keep your blood alcohol concentration (BAC) from reaching the legal limit of 0.08%, you shouldn't drink more than ____ drink(s) per hour.","explanation":"According to the Pennsylvania Driver's Manual, it takes at least one hour for a person of average weight to digest the alcohol in one normal alcoholic drink. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) As a result, you should not consume more than one drink per hour to keep your blood alcohol concentration (BAC) below the legal limit of 0.08 percent. It should be noted that this does not imply that it is safe to drive after ingesting one drink per hour. Even if your BAC is below the legal limit, your driving skills may be impacted. Furthermore, the lighter you are, the more likely you are to be impacted by a particular quantity of alcohol. [Driving Under the Influence of Alcohol, Driver Factors, Chapter 3: Learning to Drive; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":602,"qid":26131,"description":"In accordance with Pennsylvania's Implied Agree statute, whenever you driving a vehicle in Pennsylvania, you implicitly consent to:","explanation":"By driving a vehicle in Pennsylvania, you imply permission to chemical testing for alcohol and drugs at the request of a police officer, according to Pennsylvania's Implied Consent legislation. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":602,"qid":26132,"description":"If you're arrested for DUI and refuse a chemical test, your license may be revoked for:","explanation":"If you are arrested for DUI and refuse to submit to chemical testing for alcohol or drugs, your license will be revoked for one year under Pennsylvania's Implied Consent legislation, even if you are found not guilty of DUI. If you are found guilty of DUI, your license will be suspended for up to two and a half years, and you will face further criminal consequences. It is important to note that if you consent to chemical testing, fail the test with a BAC of at least 0.08 percent but less than 0.10 percent, and are convicted of DUI, your license will not be revoked for the first offense. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23153#23886#24049#26650","text":"6 months#30 days#90 days#One year"},
{"testId":1069,"qid":26132,"description":"If you're arrested for DUI and refuse a chemical test, your license may be revoked for:","explanation":"If you are arrested for DUI and refuse to submit to chemical testing for alcohol or drugs, your license will be revoked for one year under Pennsylvania's Implied Consent legislation, even if you are found not guilty of DUI. If you are found guilty of DUI, your license will be suspended for up to two and a half years, and you will face further criminal consequences. It is important to note that if you consent to chemical testing, fail the test with a BAC of at least 0.08 percent but less than 0.10 percent, and are convicted of DUI, your license will not be revoked for the first offense. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23153#23886#24049#26650","text":"6 months#30 days#90 days#One year"},
{"testId":602,"qid":26133,"description":"Your license might be suspended for up to _________ if you are found guilty of DUI despite refusing chemical tests for alcohol or drugs.","explanation":"If you refuse to submit to chemical testing for alcohol or drugs but are found guilty of DUI based on other evidence, your license will be suspended for up to 2 1/2 years, depending on your BAC when tested. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":39796,"isBookmarked":0,"aid":"20790#23183#39795#39796","text":"4 years#3 years#3 1/2 years#2 1/2 years"},
{"testId":1069,"qid":26133,"description":"Your license might be suspended for up to _________ if you are found guilty of DUI despite refusing chemical tests for alcohol or drugs.","explanation":"If you refuse to submit to chemical testing for alcohol or drugs but are found guilty of DUI based on other evidence, your license will be suspended for up to 2 1/2 years, depending on your BAC when tested. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":39796,"isBookmarked":0,"aid":"20790#23183#39795#39796","text":"4 years#3 years#3 1/2 years#2 1/2 years"},
{"testId":602,"qid":26134,"description":"First Time DUI offenders may be fined up to:","explanation":"If you are convicted of DUI for the first time, you will be fined between $300 and $5,000, depending on your BAC at the time of testing. If your BAC was 0.10 percent or greater, you will additionally face a prison term of two days to six months. [Serious Penalties; Driving While Impaired by Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23890#25036#27889","text":"$500#$1,500#$5,000#$2,500"},
{"testId":1069,"qid":26134,"description":"First Time DUI offenders may be fined up to:","explanation":"If you are convicted of DUI for the first time, you will be fined between $300 and $5,000, depending on your BAC at the time of testing. If your BAC was 0.10 percent or greater, you will additionally face a prison term of two days to six months. [Serious Penalties; Driving While Impaired by Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23890#25036#27889","text":"$500#$1,500#$5,000#$2,500"},
{"testId":602,"qid":26135,"description":"If you park illegally in a disabled parking spot, you'll be fined up to:","explanation":"If you are convicted of unlawfully parking in a designated disabled parking spot, you will be fined between $50 and $200. In addition, your car may be hauled away, in which case you must pay the towing expenses to retrieve it. [Disabled Parking Areas; Chapter 5: Laws and Related Issues; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#24995","text":"$200#$100#$50#$150"},
{"testId":1069,"qid":26135,"description":"If you park illegally in a disabled parking spot, you'll be fined up to:","explanation":"If you are convicted of unlawfully parking in a designated disabled parking spot, you will be fined between $50 and $200. In addition, your car may be hauled away, in which case you must pay the towing expenses to retrieve it. [Disabled Parking Areas; Chapter 5: Laws and Related Issues; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#24995","text":"$200#$100#$50#$150"},
{"testId":602,"qid":26136,"description":"If you are found guilty of fleeing the scene of an accident (often known as \"hit and run\") that resulted in the serious injury of another person, you will be sentenced to spend at least________in prison.","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#24048#24049#39797","text":"6 months#60 days#90 days#4 months"},
{"testId":1069,"qid":26136,"description":"If you are found guilty of fleeing the scene of an accident (often known as \"hit and run\") that resulted in the serious injury of another person, you will be sentenced to spend at least________in prison.","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#24048#24049#39797","text":"6 months#60 days#90 days#4 months"},
{"testId":602,"qid":26137,"description":"If a motorist under the age of 21 is convicted of buying, consuming, or possessing alcohol for the first time, his or her driving license will be suspended for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":602,"qid":26138,"description":"If a motorist under the age of 21 is convicted of buying, consuming, or possessing alcohol for the second time, his or her driving license will be suspended for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":602,"qid":26139,"description":"If a motorist less than 21 years old is convicted of buying, consuming, or possessing alcohol for the third time, his or her driving license will be suspended for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":602,"qid":26140,"description":"If a motorist under the age of 21 is convicted for the first time of purchasing, consuming, or possessing alcohol, he or she may face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#23890#33102","text":"$500#$1,000#$1,500#$300"},
{"testId":602,"qid":26141,"description":"For littering from your vehicle on an urban roads, you may be fined up to ______.","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"23887#24978#24994#33102","text":"$500#$100#$50#$300"},
{"testId":1069,"qid":26141,"description":"For littering from your vehicle on an urban roads, you may be fined up to ______.","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"23887#24978#24994#33102","text":"$500#$100#$50#$300"},
{"testId":602,"qid":26142,"description":"If you receive a traffic penalty (ticket) while driving in New Jersey or New York,:","explanation":null,"imageName":"","correctAnswer":39798,"isBookmarked":0,"aid":"38317#38319#39798#39799","text":"your vehicle may be impounded until you answer the citation#you may have to post bail until you answer the citation#you may return to Pennsylvania and then answer the citation#you will receive an additional citation from Pennsylvania"},
{"testId":602,"qid":26143,"description":"If a driver accumulates 11 points on his or her driving record for the first time, irrespective of his or her age,  his or her license will be suspended for a period of:","explanation":"If a motorist of any age earns 11 or more points on his or her driving record for the first time, his or her license will be banned for five days each point. The timeframe for a second such ban will be 10 days per point. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":39800,"isBookmarked":0,"aid":"23886#24048#27364#39800","text":"30 days#60 days#45 days#55 days"},
{"testId":602,"qid":26144,"description":"If you commit certain offenses in another state, your Pennsylvania driver's license will be suspended or revoked as if the offense was committed in Pennsylvania. Among these offenses are:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30353#39801#39802","text":"All of the above#vehicular manslaughter#driving under the influence (DUI)#hit-and-run accident resulting in injury"},
{"testId":602,"qid":26145,"description":"You might be fined ______ if you are guilty of carrying a kid passenger under the age of eight who is not fastened in a suitable child restraint or booster seat.","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24994#27902#33835","text":"$100#$50#$75#$10"},
{"testId":603,"qid":26146,"description":"Driving with expired license will cost you:","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1070,"qid":26146,"description":"Driving with expired license will cost you:","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":603,"qid":26147,"description":"How much will you聽be fined for running a stop sign or red light?","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1070,"qid":26147,"description":"How much will you聽be fined for running a stop sign or red light?","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":603,"qid":26148,"description":"If you are guilty of exceeding the prescribed speed limit by up to ___ mph, you will be fined $85.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":1070,"qid":26148,"description":"If you are guilty of exceeding the prescribed speed limit by up to ___ mph, you will be fined $85.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":603,"qid":26149,"description":"If you are guilty of exceeding the stated speed limit by 11 miles per hour or more, you will be fined at least _____.","explanation":null,"imageName":"","correctAnswer":44493,"isBookmarked":0,"aid":"40046#40047#40048#44493","text":"$105#$135#$155#$195"},
{"testId":1070,"qid":26149,"description":"If you are guilty of exceeding the stated speed limit by 11 miles per hour or more, you will be fined at least _____.","explanation":null,"imageName":"","correctAnswer":44493,"isBookmarked":0,"aid":"40046#40047#40048#44493","text":"$105#$135#$155#$195"},
{"testId":603,"qid":26150,"description":"A driver under the age of 18 who is guilty of using a cell phone while driving faces a fine of _____ for the first offense.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11349#12357#12358#12395","text":"$50#$150#$200#$250"},
{"testId":603,"qid":26151,"description":"Drunk drivers are responsible for more than ____ of all fatal car accidents, according to the Rhode Island Driver's Manual.","explanation":"According to the Rhode Island Driver's Manual, intoxicated drivers are responsible for more than half of all fatal traffic incidents. (Note: According to the Centers for Disease Control and Prevention, this figure has dropped in recent years to 28 percent, but it still represents around 10,000 persons died each year in alcohol-related incidents.) [A. Effects of Alcohol and Other Drugs on the Body; Chapter X. Drinking and Driving; Rhode Island Driver's Manual], [\"Impaired Driving: Get the Facts\"; Centers for Disease Control and Prevention; https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet/impaired-drv factsheet/impaired-drv factsheet/impaired-dr .html]","imageName":"","correctAnswer":11667,"isBookmarked":0,"aid":"11665#11667#11939#12162","text":"25%#50%#40%#30%"},
{"testId":603,"qid":26152,"description":"Which of the following can damage your driving ability?","explanation":"Drugs other than alcohol might also make it difficult for you to drive. Marijuana, several prescription drugs, and some over-the-counter medicines may all make it difficult for you to drive a car. You may be charged with DUI if a chemical test reveals that you have any quantity of a restricted drug in your blood (DUI). Rhode Island Driver's Manual, Chapter X, Drinking and Driving, [A. Effect of Alcohol and Other Drugs on the Body], [R. I. G. L. 31-27-2]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14544#25099#28289","text":"All of the above#Diet pills#Marijuana#Over-the-counter cold medications"},
{"testId":603,"qid":26153,"description":"In Rhode Island, what term is used to describe driving while under the influence of alcohol or controlled substances?","explanation":"Driving Under the Influence (DUI) in Rhode Island refers to driving a vehicle while under the influence of alcohol or controlled drugs. Other states use names like Driving While Intoxicated (DWI), Operating a Vehicle While Impaired (OVI), or Driving While Under the Influence of Intoxicants (DUI) (DUII). [B. Blood Alcohol Concentration; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":1070,"qid":26153,"description":"In Rhode Island, what term is used to describe driving while under the influence of alcohol or controlled substances?","explanation":"Driving Under the Influence (DUI) in Rhode Island refers to driving a vehicle while under the influence of alcohol or controlled drugs. Other states use names like Driving While Intoxicated (DWI), Operating a Vehicle While Impaired (OVI), or Driving While Under the Influence of Intoxicants (DUI) (DUII). [B. Blood Alcohol Concentration; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":603,"qid":26154,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"A person of any age is deemed legally inebriated if their blood alcohol content (BAC) is 0.08 percent or greater. If the individual is driving at the moment, he or she may be prosecuted with Driving While Intoxicated (DUI). [B. Blood Alcohol Concentration; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":603,"qid":26155,"description":"A driver under the age of 21 whose BAC is at least _____ but less than _____ will be charged with Driving While Impaired (DWI).","explanation":"A motorist under the age of 21 who has a BAC of at least 0.02 percent but less than 0.08 percent will be prosecuted with Driving While Impaired (DWI). It should be noted that most persons will have a BAC of 0.02 percent or more after just one alcoholic drink. As a result, a legal BAC limit of 0.02 percent corresponds to practical zero tolerance. [B. Blood Alcohol Concentration; Rhode Island Driver's Manual; Chapter X. Drinking and Driving]","imageName":"","correctAnswer":5972,"isBookmarked":0,"aid":"5972#40051#40052#40053","text":"0.02%; 0.08%#0.02%; 0.10%#0.04%; 0.12%#0.10%; 0.12%"},
{"testId":603,"qid":26156,"description":"In accordance with Rhode Island's Implied Submit statute, if you operate a motor vehicle in Rhode Island, you implicitly consent to :","explanation":"By operating a motor vehicle in Rhode Island, you imply permission to chemical testing for alcohol and drugs at the request of a police officer, according to the state's Implied Consent legislation. [C. Implied Consent; Chapter X. Driving While Drunk; Rhode Island Driver's Handbook]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":603,"qid":26157,"description":"A driver over the age of 21 who has a blood alcohol content (BAC) of at least _____ but less than 0.08% may be guilty of driving while impaired (DWI) if there is further evidence that he or she was driving under the influence of alcohol.","explanation":"A motorist over the age of 21 who has a blood alcohol content (BAC) of 0.08 percent or above may be guilty of driving under the influence (DUI). Even if the driver's BAC is less than 0.08 percent, he or she may be convicted of driving while intoxicated (DWI) if there is additional proof showing he or she was under the influence of alcohol. [C. Implied Consent; Chapter X. Driving While Drunk; Rhode Island Driver's Handbook]","imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3170#3282#4251#4252","text":"0.07%#0.06%#0.02%#0.05%"},
{"testId":603,"qid":26158,"description":"If you refuse a chemical test like a Breathalyzer for the first time, your license will be revoked for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#26650#27363","text":"3 months#6 months#One year#2 months"},
{"testId":603,"qid":26159,"description":"If you refuse a chemical test for the first time, you may face all of the following penalties. EXCEPT:","explanation":null,"imageName":"","correctAnswer":28960,"isBookmarked":0,"aid":"24892#28960#40054#40055","text":"community service#jail#a fine#alcohol or drug treatment"},
{"testId":603,"qid":26160,"description":"If you refuse a chemical test like a Breathalyzer for the first time, you could get up to __ hours of community service.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6477#7571#8141#11513","text":"30#50#60#40"},
{"testId":603,"qid":26161,"description":"DUI or refusal to submit to chemical testing is considered a second offense in Rhode Island if it occurs within ___ years of the previous offense of the same type.","explanation":"If the refusal to submit to chemical testing occurs within five years of the preceding violation, it is deemed a second offense in Rhode Island. Similarly, if the refusal happened within five years of the first offense, it is deemed a third offense. (The same is true for driving under the influence.) The sanctions for a second offense are greater than those for a first offense, and they may be much severe for a third violation. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#6916#7559","text":"10#5#6#7"},
{"testId":1070,"qid":26161,"description":"DUI or refusal to submit to chemical testing is considered a second offense in Rhode Island if it occurs within ___ years of the previous offense of the same type.","explanation":"If the refusal to submit to chemical testing occurs within five years of the preceding violation, it is deemed a second offense in Rhode Island. Similarly, if the refusal happened within five years of the first offense, it is deemed a third offense. (The same is true for driving under the influence.) The sanctions for a second offense are greater than those for a first offense, and they may be much severe for a third violation. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#6916#7559","text":"10#5#6#7"},
{"testId":603,"qid":26162,"description":"If you refuse a chemical test for the third time, you will be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"20789#23152#23153#26650","text":"9 months#3 months#6 months#One year"},
{"testId":603,"qid":26163,"description":"If you refuse a chemical test for the third time, you must do at least聽___ hours of community service.","explanation":null,"imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"6356#6591#12996#31870","text":"120#100#150#160"},
{"testId":603,"qid":26165,"description":"If you refuse a chemical test three times, you could face a fine of:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":603,"qid":26167,"description":"If a driver under the age of 18 gets convicted of DWI for the second time, his or her license will be suspended:","explanation":"If a motorist under the age of 18 gets convicted of DWI for the second time, his or her license will be revoked until the person reaches the age of 21. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":40057,"isBookmarked":0,"aid":"25104#40056#40057#40058","text":"for 2 years#until he or she turns 18#until he or she turns 21#for up to 18 months"},
{"testId":603,"qid":26168,"description":"If a driver under the age of 21 but at least 18 years is convicted of DWI for the first time, he or she will be fined ____ and will be required to perform ___ hours of community service.","explanation":"If a motorist under the age of 21 is convicted of DWI for the first time, he or she will be fined $250 and compelled to conduct 30 hours of community service. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":13905,"isBookmarked":0,"aid":"13903#13905#40059#40060","text":"$100; 30#$250; 30#$100; 10#$250; 10"},
{"testId":603,"qid":26171,"description":"Drivers over the age of 21 who are found guilty of driving under the influence (DUI) and have a BAC of 0.10 percent but less than 0.15 percent face a license suspension of up to ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23154#26672","text":"2 years#9 months#1 year#5 years"},
{"testId":603,"qid":26172,"description":"For a first offense, an adult motorist who is found guilty of DUI and has a blood alcohol content (BAC) of at least 0.15%, the motorist will be fined:","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#11669#11832","text":"$500#$1,000#$2,000#$100"},
{"testId":603,"qid":26173,"description":"For the second time in five years, an adult motorist convicted of DUI will be sentenced to up to:","explanation":"If an adult motorist is convicted of DUI for the second time, he or she will face prison time. If the driver's blood alcohol content (BAC) was less than 0.15 percent at the time of the violation, the penalty will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":603,"qid":26176,"description":"DUI or refusal to take a chemical test convictions come with a ___ highway safety assessment.","explanation":"If you are convicted of DUI or refusing to undergo a chemical test, you must pay a $500 highway safety assessment in addition to additional fines. If you are convicted of DWI, you may be required to pay a highway safety assessment of up to $300, depending on your age and whether this is your first or second conviction. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11833#12357#12395","text":"$500#$300#$150#$250"},
{"testId":1070,"qid":26176,"description":"DUI or refusal to take a chemical test convictions come with a ___ highway safety assessment.","explanation":"If you are convicted of DUI or refusing to undergo a chemical test, you must pay a $500 highway safety assessment in addition to additional fines. If you are convicted of DWI, you may be required to pay a highway safety assessment of up to $300, depending on your age and whether this is your first or second conviction. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11833#12357#12395","text":"$500#$300#$150#$250"},
{"testId":603,"qid":26177,"description":"In Rhode Island, you are guilty of a felony if this is your _____ or more DUI offense.","explanation":"In Rhode Island, your first and second DUIs are misdemeanors, while your third and subsequent DUIs are felonies. The penalty for these offenses are substantially higher, including many years in prison and a license suspension. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":1070,"qid":26177,"description":"In Rhode Island, you are guilty of a felony if this is your _____ or more DUI offense.","explanation":"In Rhode Island, your first and second DUIs are misdemeanors, while your third and subsequent DUIs are felonies. The penalty for these offenses are substantially higher, including many years in prison and a license suspension. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":603,"qid":26178,"description":"For the third time in five years, an adult motorist convicted of DUI will be punished to at least ______ in jail if his or her blood alcohol content was less than 0.15%.","explanation":"A third DUI in five years is classified as a felony. If the driver's blood alcohol content (BAC) was less than 0.15 percent at the time of the violation, the penalty is one to three years in jail, or three to five years in prison if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":603,"qid":26179,"description":"For the third time in five years, an adult motorist convicted of DUI will be punished to at least ______ in jail if his or her blood alcohol content was less than 0.15%.","explanation":"A third DUI in five years is classified as a felony. If the driver's blood alcohol content (BAC) was less than 0.15 percent at the time of the violation, the penalty is one to three years in jail, or three to five years in prison if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":603,"qid":26181,"description":"If you're convicted of DUI for the first time聽resulting in serious bodily injury, you may face which of these penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35994#40061#40062","text":"All of the above#A fine of up to $5,000#Up to 10 years in jail#License revocation for up to 2 years"},
{"testId":1070,"qid":26181,"description":"If you're convicted of DUI for the first time聽resulting in serious bodily injury, you may face which of these penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35994#40061#40062","text":"All of the above#A fine of up to $5,000#Up to 10 years in jail#License revocation for up to 2 years"},
{"testId":603,"qid":26182,"description":"A second DUI conviction that results in serious physical injury to another person will result in a fine of up to: :","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11669#11704","text":"$5,000#$10,000#$2,000#$7,500"},
{"testId":1070,"qid":26182,"description":"A second DUI conviction that results in serious physical injury to another person will result in a fine of up to: :","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11669#11704","text":"$5,000#$10,000#$2,000#$7,500"},
{"testId":603,"qid":26183,"description":"If you're convicted of DUI for the first time聽resulting in someone's death , you'll be sentenced to聽at聽least ______聽in jail.","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1323#11341#11760#12727","text":"15 years#5 years#10 years#6 years"},
{"testId":1070,"qid":26183,"description":"If you're convicted of DUI for the first time聽resulting in someone's death , you'll be sentenced to聽at聽least ______聽in jail.","explanation":null,"imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"1323#11341#11760#12727","text":"15 years#5 years#10 years#6 years"},
{"testId":603,"qid":26184,"description":"If you're convicted of DUI for the second time,聽that causes someone's death, you'll spend at least _______ in jail聽:","explanation":null,"imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"1323#3635#11760#13410","text":"15 years#12 years#10 years#20 years"},
{"testId":1070,"qid":26184,"description":"If you're convicted of DUI for the second time,聽that causes someone's death, you'll spend at least _______ in jail聽:","explanation":null,"imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"1323#3635#11760#13410","text":"15 years#12 years#10 years#20 years"},
{"testId":603,"qid":26187,"description":"Parking in a disabled parking place without a disability parking permit will result in a _____ fine for the first violation.","explanation":"To park in a disability parking place in Rhode Island, you must have a disability parking placard or (for motorcycles) a disability parking sticker. If you are caught parking in a disabled parking place without a permit, you will be penalized $100 for the first violation, $175 for the second offense, and $325 for the third offense. In addition, your car may be towed at your cost. [\"New/Renewal Disability Parking Placard Application\"; Rhode Island Division of Motor Vehicles; http://www.dmv.ri.gov/documents/forms/disability/DisabilityParkingPlacard.pdf], [R. I. G. L. 31-28-7]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11349#11832#12358#12398","text":"$50#$100#$200#$75"},
{"testId":1070,"qid":26187,"description":"Parking in a disabled parking place without a disability parking permit will result in a _____ fine for the first violation.","explanation":"To park in a disability parking place in Rhode Island, you must have a disability parking placard or (for motorcycles) a disability parking sticker. If you are caught parking in a disabled parking place without a permit, you will be penalized $100 for the first violation, $175 for the second offense, and $325 for the third offense. In addition, your car may be towed at your cost. [\"New/Renewal Disability Parking Placard Application\"; Rhode Island Division of Motor Vehicles; http://www.dmv.ri.gov/documents/forms/disability/DisabilityParkingPlacard.pdf], [R. I. G. L. 31-28-7]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11349#11832#12358#12398","text":"$50#$100#$200#$75"},
{"testId":603,"qid":26189,"description":"You will be fined ______ if you are found guilty of not wearing a seat belt when carrying a kid between the age of eight to eighteen.","explanation":"If you are convicted of not wearing a seat belt when carrying a kid under the age of 18, you will be fined $85 for each violation. [R. I. G. L. 31-22-22], [R. I. G. L. 31-41.1-4], [Chapter III. Basic Driving Skills and Safety Rules; Rhode Island Driver's Manual],","imageName":"","correctAnswer":31885,"isBookmarked":0,"aid":"24978#24993#24994#31885","text":"$100#$25#$50#$85"},
{"testId":603,"qid":26190,"description":"What is the minimum age for a Rhode Island driver to obtain a full operator's license?","explanation":"If you are either 18 years old or meet all of the following requirements, you are eligible for a full operator's license: (1) you are at least 17 1/2 years old, (2) you have held a Limited Provisional License for at least the previous twelve months, and (3) you have not been convicted of a moving violation or safety belt infraction within the previous six months. [D. Full Operator's License; Chapter I. Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":40063,"isBookmarked":0,"aid":"11361#11374#32240#40063","text":"16#17#16 1/2#17 1/2"},
{"testId":1070,"qid":26190,"description":"What is the minimum age for a Rhode Island driver to obtain a full operator's license?","explanation":"If you are either 18 years old or meet all of the following requirements, you are eligible for a full operator's license: (1) you are at least 17 1/2 years old, (2) you have held a Limited Provisional License for at least the previous twelve months, and (3) you have not been convicted of a moving violation or safety belt infraction within the previous six months. [D. Full Operator's License; Chapter I. Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":40063,"isBookmarked":0,"aid":"11361#11374#32240#40063","text":"16#17#16 1/2#17 1/2"},
{"testId":603,"qid":26191,"description":"When will the validity of your initial licence expire?","explanation":"Your first license will expire on your second birthday after the day you obtained it. For example, if you get your driver's license at the age of 18, it will expire on your 20th birthday. If you're under 72 years old when your initial license expires, you may renew it for five years or until you become 75, whichever comes first. Your license will expire in two years if you are at least 75 years old. [D. Level Three: Full Operator's License; Rhode Island Driver's Manual; Chapter I. Applying for a Rhode Island Driver's License], [\"License Renewal\"; Rhode Island Division of Motor Vehicles; http://www.dmv.ri.gov/licenses/renewal/]","imageName":"","correctAnswer":40067,"isBookmarked":0,"aid":"40064#40065#40066#40067","text":"In 12 months#In 24 months#On your next birthday#On your second birthday after you obtain the license"},
{"testId":1070,"qid":26191,"description":"When will the validity of your initial licence expire?","explanation":"Your first license will expire on your second birthday after the day you obtained it. For example, if you get your driver's license at the age of 18, it will expire on your 20th birthday. If you're under 72 years old when your initial license expires, you may renew it for five years or until you become 75, whichever comes first. Your license will expire in two years if you are at least 75 years old. [D. Level Three: Full Operator's License; Rhode Island Driver's Manual; Chapter I. Applying for a Rhode Island Driver's License], [\"License Renewal\"; Rhode Island Division of Motor Vehicles; http://www.dmv.ri.gov/licenses/renewal/]","imageName":"","correctAnswer":40067,"isBookmarked":0,"aid":"40064#40065#40066#40067","text":"In 12 months#In 24 months#On your next birthday#On your second birthday after you obtain the license"},
{"testId":603,"qid":26192,"description":"What is the minimum age for a Rhode Island driver to obtain a Limited Instructional Permit?","explanation":"You must be at least 16 years old and pass a DMV computerized knowledge exam to be eligible for a Limited Instructional Permit. If you are under the age of 18, you must additionally complete a classroom driver education course and have written permission from a parent, guardian, or adult spouse. [A. Prior to Obtaining a Limited Instructional Permit; Chapter I. Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11361#11374#32241","text":"15#16#17#15 1/2"},
{"testId":603,"qid":26193,"description":"When driving with an instructional permit, you must be supervised by a passenger who:","explanation":"When driving with an instructional permit, you must be accompanied by a passenger who is at least 21 years old, has had a license for at least five years, and sits next to you. Your supervising passenger is legally accountable for any violations you make while driving. [B. Level One: Limited Instructional Permit; Chapter One: Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35002#35005#40068#40069","text":"is at least 21 years of age#meets all of the above requirements#is seated next to you#has held a license for at least 5 years"},
{"testId":603,"qid":26194,"description":"For the first year, a driver with a Limited Provisional License may carry at most ___ unrelated passengers under the age of 21.","explanation":"For the first twelve months, a driver with a Limited Provisional License may only transport one passenger under the age of 21. This limitation, however, does not apply to direct family members or household members of the driver. [C. Level Two: Limited Provisional License; Chapter I: Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":603,"qid":26195,"description":"Before applying for a Limited Provisional License, a motorist must have held a Limited Instructional Permit for at least:","explanation":"A driver must meet the following requirements to obtain a limited provisional license: (1) he or she must have held a Limited Instructional Permit for at least six months, (2) he or she must not have been convicted of a moving violation or safety belt infraction within the previous six months, (3) he or she must have practiced driving for at least 50 hours, including 10 hours at night, and (4) he or she must pass a DMV driving test. [C. Level Two: Limited Provisional License; Chapter I: Obtaining a Rhode Island Driver's License; Rhode Island Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":606,"qid":26196,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":1073,"qid":26196,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":606,"qid":26197,"description":"In Tennessee, a measurement of a person's blood alcohol content is known as the:","explanation":"The blood alcohol concentration is a measurement of the proportion of alcohol in a person's blood in Tennessee (BAC). The greater the BAC, the more inebriated the individual is. Other states use words like blood alcohol content (BAC) or alcohol concentration (AC). [What is BAC? ; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40735,"isBookmarked":0,"aid":"40734#40735#40736#40737","text":"alcohol level (AL)#blood alcohol concentration (BAC)#body alcohol level (BAL)#body alcohol percentage (BAP)"},
{"testId":1073,"qid":26197,"description":"In Tennessee, a measurement of a person's blood alcohol content is known as the:","explanation":"The blood alcohol concentration is a measurement of the proportion of alcohol in a person's blood in Tennessee (BAC). The greater the BAC, the more inebriated the individual is. Other states use words like blood alcohol content (BAC) or alcohol concentration (AC). [What is BAC? ; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40735,"isBookmarked":0,"aid":"40734#40735#40736#40737","text":"alcohol level (AL)#blood alcohol concentration (BAC)#body alcohol level (BAL)#body alcohol percentage (BAP)"},
{"testId":606,"qid":26198,"description":"If you are caught texting and driving, you might face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24994#27902#33835","text":"$100#$50#$75#$10"},
{"testId":606,"qid":26199,"description":"According to the Tennessee Comprehensive Driver License Manual, how many Americans out of five will be involved in alcohol-related accidents at some point in their lives?","explanation":"According to the Tennessee Comprehensive Driver License Manual, two out of every five Americans will be involved in an alcohol-related accident at some time in their life. [Alcohol and Traffic Crash Relationship; Drinking and Driving; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":606,"qid":26200,"description":"A driver who is engaged in a reportable accident must make a report with the Tennessee Department of Safety within:","explanation":"The motorist engaged in a reportable incident in the state of Tennessee must submit an Owner/Driver Report of the crash with the Tennessee Department of Safety and Homeland Security within 20 days. [Reporting Crashes, B-8. Driving Responsibilities, Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23885,"isBookmarked":0,"aid":"23883#23885#25170#34746","text":"10 days#20 days#1 month#3 days"},
{"testId":606,"qid":26201,"description":"Just one drink within 15 minutes might increase your blood alcohol content (BAC) to ______.","explanation":"One drink within 15 minutes can boost your blood alcohol content (BAC) to between 0.01 and 0.03 percent, depending on variables such as body weight. It should be noted that a motorist under the age of 21 who is caught driving with a BAC of 0.02 percent or more will be prosecuted with underage driving while impaired. B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27700,"isBookmarked":0,"aid":"23192#23194#27699#27700","text":"0.05%#0.08%#0.01%#0.03%"},
{"testId":606,"qid":26202,"description":"Which of the following drugs can result in a DUI conviction in Tennessee when used while driving?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#25099#29384","text":"Any of the above#Illegal drugs#Marijuana#Prescription medications"},
{"testId":606,"qid":26203,"description":"Even if your blood alcohol concentration (BAC) is as low as _______, you might be charged with DUI.","explanation":"Even if you haven't had any alcohol, other substances you've taken may affect your driving. Even if your BAC is zero, you may still be charged with DUI if a law enforcement officer obtains additional proof that your use of certain substances has rendered you incapable of safely driving a motor vehicle. B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23192#23194#23318#31711","text":"0.05%#0.08%#0.02%#Zero"},
{"testId":606,"qid":26204,"description":"By driving on public highways in Tennessee, you expressly agree to:","explanation":"By driving a motor vehicle on Tennessee's public highways, you imply permission to chemical testing for alcohol and drugs at the request of a law enforcement officer, according to Tennessee's Implied Consent legislation. [Implied Consent Law; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":606,"qid":26205,"description":"Your driver's license will be suspended for ________ for a first offense for refusing to submit to a chemical test for alcohol or drugs when requested to do so by a law enforcement official.","explanation":"If you are arrested and asked to undergo a chemical test for drugs or alcohol and refuse, the court will likely suspend your license for one year for a first violation. Because this is a distinct charge from DUI, the punishment for refusal may be applied even if you are not convicted of DUI. [Implied Consent Law; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1073,"qid":26205,"description":"Your driver's license will be suspended for ________ for a first offense for refusing to submit to a chemical test for alcohol or drugs when requested to do so by a law enforcement official.","explanation":"If you are arrested and asked to undergo a chemical test for drugs or alcohol and refuse, the court will likely suspend your license for one year for a first violation. Because this is a distinct charge from DUI, the punishment for refusal may be applied even if you are not convicted of DUI. [Implied Consent Law; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":606,"qid":26206,"description":"As a condition of probation, a DUI conviction mandates ___ hours of trash pick-up.","explanation":"A DUI conviction necessitates litter pick-up for three eight-hour stints while wearing clothes that proclaims \"I AM A DRUNK DRIVER\" as a condition of probation. [Penalties Applying to Any DUI Conviction; Driving Under the Influence of Drugs or Alcohol (The \"DUI\" Law); B-7. Tennessee Comprehensive Driver License Manual [Alcohol, Other Drugs, and Driving]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"6478#6916#12040#13770","text":"12#6#24#72"},
{"testId":1073,"qid":26206,"description":"As a condition of probation, a DUI conviction mandates ___ hours of trash pick-up.","explanation":"A DUI conviction necessitates litter pick-up for three eight-hour stints while wearing clothes that proclaims \"I AM A DRUNK DRIVER\" as a condition of probation. [Penalties Applying to Any DUI Conviction; Driving Under the Influence of Drugs or Alcohol (The \"DUI\" Law); B-7. Tennessee Comprehensive Driver License Manual [Alcohol, Other Drugs, and Driving]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"6478#6916#12040#13770","text":"12#6#24#72"},
{"testId":606,"qid":26207,"description":"If convicted of DUI for the first time, you'll spend at least ___ in jail.","explanation":null,"imageName":"","correctAnswer":40738,"isBookmarked":0,"aid":"34746#37577#40738#40739","text":"3 days#4 days#2 days#5 days"},
{"testId":606,"qid":26208,"description":"If you're convicted of a first DUI and your BAC was 0.18 percent or higher, you will be sentenced to jail for at least:","explanation":null,"imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":606,"qid":26209,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":23890,"isBookmarked":0,"aid":"23887#23890#24978#27888","text":"$500#$1,500#$100#$250"},
{"testId":606,"qid":26210,"description":"If convicted of DUI for the first time, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":606,"qid":26211,"description":"If convicted of DUI for the second time, you will be sentenced to at least _____  in prison.","explanation":null,"imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23886#24048#27364#34746","text":"30 days#60 days#45 days#3 days"},
{"testId":606,"qid":26212,"description":"If  convicted of DUI for the second time, you will face a fine of at least:","explanation":null,"imageName":"","correctAnswer":23888,"isBookmarked":0,"aid":"23888#23889#24977#31696","text":"$600#$1,000#$200#$400"},
{"testId":606,"qid":26213,"description":"If convicted of DUI for the second time, you will have your license revoked for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23183#25858#26672","text":"2 years#3 years#180 days#5 years"},
{"testId":606,"qid":26214,"description":"Your vehicle will be seized and forfeited if you're convicted of DUI for the ______ time.","explanation":"If you are convicted of DUI for the second or subsequent time, your car may be seized and forfeited. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#13064#40740#40741","text":"Second#First#first or second#first or third"},
{"testId":1073,"qid":26214,"description":"Your vehicle will be seized and forfeited if you're convicted of DUI for the ______ time.","explanation":"If you are convicted of DUI for the second or subsequent time, your car may be seized and forfeited. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":12583,"isBookmarked":0,"aid":"12583#13064#40740#40741","text":"Second#First#first or second#first or third"},
{"testId":606,"qid":26215,"description":"If convicted of DUI for the third time, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"23887#23889#25036#31397","text":"$500#$1,000#$5,000#$10,000"},
{"testId":606,"qid":26216,"description":"If convicted of DUI for the third time, you will be sentenced to at least_____ in prison.","explanation":null,"imageName":"","correctAnswer":25857,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":606,"qid":26217,"description":"If convicted of DUI for the third time, your license will be suspended for a period of up to:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#23183#26672#29895","text":"2 years#3 years#5 years#10 years"},
{"testId":606,"qid":26218,"description":"A ______ or subsequent DUI conviction is treated as a felony in Tennessee.","explanation":null,"imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":606,"qid":26220,"description":"If convicted of DUI for the fourth time, you will have your license revoked for at least :","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":606,"qid":26221,"description":"If you're convicted of DUI with a minor in the car, you'll spend at least ___ days in jail and pay at least____.","explanation":"Driving under the influence (DUI) while transporting a kid under the age of 18 is considered a kind of child endangerment. If convicted, you will face the standard DUI penalty, plus 30 days in prison and a fine of at least $1,000. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":26614,"isBookmarked":0,"aid":"13825#26614#26615#40742","text":"10; $500#30; $1,000#60; $2,000#120; $5,000"},
{"testId":606,"qid":26222,"description":"Under certain conditions, if you are caught for DUI, you may additionally be charged with:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#40743#40744#40745","text":"Any of the above#vehicular homicide#vehicular assault#child endangerment"},
{"testId":606,"qid":26223,"description":"First-time DUI offenders may be required to install an ignition interlock device (IID) for a periof of:","explanation":"If you are convicted of DUI for the first time, the court may order you to place an ignition interlock device (IID) in every motor vehicle you drive for six months to a year. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40746,"isBookmarked":0,"aid":"40746#40747#40748#40749","text":"6 months to 1 year#1 to 2 years#2 to 3 years#3 to 10 years"},
{"testId":606,"qid":26226,"description":"Which of the following expenses may be incurred in addition to a DUI charge?","explanation":"If you're charged with DUI, you'll face a slew of expenses, some of which you'll have to pay even if you're not convicted. These expenses include attorney's fees, posting bail to get out of prison, missing work to go to court, paying court charges, maybe paying a fine, missing work to go to jail, and paying for drug addiction treatment. Your liability insurance prices may also climb. Total expenses may easily exceed tens of thousands of dollars. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#40750#40751#40752","text":"All of the above#Posting bond#Higher liability insurance premiums#Court costs"},
{"testId":1073,"qid":26226,"description":"Which of the following expenses may be incurred in addition to a DUI charge?","explanation":"If you're charged with DUI, you'll face a slew of expenses, some of which you'll have to pay even if you're not convicted. These expenses include attorney's fees, posting bail to get out of prison, missing work to go to court, paying court charges, maybe paying a fine, missing work to go to jail, and paying for drug addiction treatment. Your liability insurance prices may also climb. Total expenses may easily exceed tens of thousands of dollars. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#40750#40751#40752","text":"All of the above#Posting bond#Higher liability insurance premiums#Court costs"},
{"testId":606,"qid":26227,"description":"If a motorist between the ages of 18 and 20 is convicted for the first time of purchasing or possessing alcohol, his or her license will be suspended for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":606,"qid":26228,"description":"If a motorist between the ages of 18 and 20 is convicted of purchasing or possessing alcohol for the second time, his or her driving rights will be suspended for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":606,"qid":26229,"description":"A driver under the age of 21 who is guilty of underage driving while inebriated will be punished :","explanation":"A motorist under the age of 21 who is convicted of underage driving while intoxicated faces a $250 fine and a one-year license suspension. The court may also order the driver to undertake community service. [Under 21 BAC; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":606,"qid":26230,"description":"A driver under the age of 21 is convicted of underage driving while intoxicated may have his or her license be suspended for:","explanation":"A motorist under the age of 21 who is convicted of underage driving while intoxicated faces a $250 fine and a one-year license suspension. The court may also order the driver to undertake community service. [Under 21 BAC; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#26672","text":"6 months#1 year#30 days#5 years"},
{"testId":606,"qid":26232,"description":"How long would it take your body to get rid of alcohol after you've had three beers?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. [Avoiding the Dangers; Young Driver Risks and Laws; Driving While Impaired by Drugs or Alcohol (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40755,"isBookmarked":0,"aid":"40753#40754#40755#40756","text":"One hour#Two hours#Three hours#Overnight"},
{"testId":1073,"qid":26232,"description":"How long would it take your body to get rid of alcohol after you've had three beers?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. [Avoiding the Dangers; Young Driver Risks and Laws; Driving While Impaired by Drugs or Alcohol (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40755,"isBookmarked":0,"aid":"40753#40754#40755#40756","text":"One hour#Two hours#Three hours#Overnight"},
{"testId":606,"qid":26233,"description":"A young person's driving ability might be substantially damaged even with a blood alcohol percentage (BAC) as low as____","explanation":"Driving abilities, sound judgment, and eyesight may be substantially reduced at BAC levels as low as 0.03 percent, according to research, particularly for young drivers. It should be noted that this amount is far lower than the legal limit for adult drivers. [Alcohol and Traffic Crash Relationship; Drinking and Driving; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":4253,"isBookmarked":0,"aid":"3170#4252#4253#4653","text":"0.07%#0.05%#0.03%#0.01%"},
{"testId":1073,"qid":26233,"description":"A young person's driving ability might be substantially damaged even with a blood alcohol percentage (BAC) as low as____","explanation":"Driving abilities, sound judgment, and eyesight may be substantially reduced at BAC levels as low as 0.03 percent, according to research, particularly for young drivers. It should be noted that this amount is far lower than the legal limit for adult drivers. [Alcohol and Traffic Crash Relationship; Drinking and Driving; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":4253,"isBookmarked":0,"aid":"3170#4252#4253#4653","text":"0.07%#0.05%#0.03%#0.01%"},
{"testId":606,"qid":26234,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. You may not be aware of how drunk you are. Even if you haven't yet seen other symptoms, you could choose to drive recklessly (such as speeding around curves, passing other vehicles without an adequate space cushion, or even showing off on the road). Avoiding Risks; Underage Drinking Laws; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":606,"qid":26235,"description":"Which of the following might lead to licence suspension or revocation?","explanation":"In Tennessee, your license may be suspended or revoked for a number of reasons, including a DUI conviction, leaving the scene of an accident without stopping (hit and run), illegal or fraudulent use of your license, and medical issues that limit your ability to drive safely, among others. [Loss of Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40757#40758#40759","text":"Any of the above#A conviction for leaving the scene of an accident without stopping (hit and run)#A conviction for letting someone else use your license for identification#Medical conditions that impair your ability to drive safely"},
{"testId":606,"qid":26236,"description":"Your licence may be suspended if you have _____ or more convictions for reckless driving in a 12-month period.","explanation":null,"imageName":"","correctAnswer":10547,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":1073,"qid":26236,"description":"Your licence may be suspended if you have _____ or more convictions for reckless driving in a 12-month period.","explanation":null,"imageName":"","correctAnswer":10547,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":606,"qid":26237,"description":"Revocation of your license will occur if convicted of:","explanation":null,"imageName":"","correctAnswer":12723,"isBookmarked":0,"aid":"11942#11944#12723#13076","text":"failure to yield#Speeding#Drag racing#running a stop sign"},
{"testId":1073,"qid":26237,"description":"Revocation of your license will occur if convicted of:","explanation":null,"imageName":"","correctAnswer":12723,"isBookmarked":0,"aid":"11942#11944#12723#13076","text":"failure to yield#Speeding#Drag racing#running a stop sign"},
{"testId":606,"qid":26238,"description":"A driver under the age of 18 will have his or her license suspended if he or she is absent from school for ___ days or more without being excused.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":606,"qid":26239,"description":"If you receive notification that your license has been suspended or revoked, you must return it to the Tennessee Department of Safety and Homeland Security within 30 days.","explanation":"If you get notification that your license has been suspended or revoked, you have 20 days to surrender it to the Tennessee Department of Safety and Homeland Security. You may mail it in or bring it to a Tennessee Highway Patrol Office or Driver License Service Center. [Loss of Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23885,"isBookmarked":0,"aid":"23883#23885#23886#34746","text":"10 days#20 days#30 days#3 days"},
{"testId":1073,"qid":26239,"description":"If you receive notification that your license has been suspended or revoked, you must return it to the Tennessee Department of Safety and Homeland Security within 30 days.","explanation":"If you get notification that your license has been suspended or revoked, you have 20 days to surrender it to the Tennessee Department of Safety and Homeland Security. You may mail it in or bring it to a Tennessee Highway Patrol Office or Driver License Service Center. [Loss of Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23885,"isBookmarked":0,"aid":"23883#23885#23886#34746","text":"10 days#20 days#30 days#3 days"},
{"testId":606,"qid":26240,"description":"You will be penalized_______, if you fail to submit your license to the Tennessee Department of Safety and Homeland Security after it has been suspended or revoked.","explanation":"If you fail to submit your license to the Tennessee Department of Safety and Homeland Security after it has been suspended or revoked, you will be fined $75. [Loss of Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#27888#27902","text":"$100#$25#$250#$75"},
{"testId":1073,"qid":26240,"description":"You will be penalized_______, if you fail to submit your license to the Tennessee Department of Safety and Homeland Security after it has been suspended or revoked.","explanation":"If you fail to submit your license to the Tennessee Department of Safety and Homeland Security after it has been suspended or revoked, you will be fined $75. [Loss of Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24993#27888#27902","text":"$100#$25#$250#$75"},
{"testId":606,"qid":26242,"description":"Driving with a suspended or revoked license results in _____ points being added to your driving record.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":606,"qid":26243,"description":"____ points will be added to your driving record if you are found guilty of reckless driving.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":606,"qid":26244,"description":"If you're convicted of fleeing the police, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6389#6916#7620","text":"10#3#6#8"},
{"testId":606,"qid":26245,"description":"Stop sign violations add ______ points to your driving record.","explanation":"If you are found guilty of failing to follow a traffic sign or signal, you will get four points on your driving record. B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1073,"qid":26245,"description":"Stop sign violations add ______ points to your driving record.","explanation":"If you are found guilty of failing to follow a traffic sign or signal, you will get four points on your driving record. B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":607,"qid":26247,"description":"If you are found guilty of breaching Texas' Open Container Law, you might face a fine of up to $1,000.","explanation":"If you are found guilty of breaching Texas's Open Container Law, you might face a $500 fine. [Open Container; Chapter 10: The Effects of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#23890#24978","text":"$500#$1,000#$1,500#$100"},
{"testId":607,"qid":26248,"description":"According to the Texas Driver Handbook, alcohol-related crashes accounted for about ____ of all traffic fatalities in Texas.","explanation":"According to the Texas Driver Handbook, alcohol-related collisions accounted for 29 percent of all road deaths in Texas. According to the CDC, this compared to 28 percent nationally. [\"Impaired Driving: Get the Facts,\" Centers for Disease Control and Prevention, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet.html], [\"Impaired Driving: Get the Facts,\" Centers for Disease Control and Prevention, https://www.cdc.gov/motorvehiclesafety/impaired driving/impaired-drv factsheet","imageName":"","correctAnswer":12162,"isBookmarked":0,"aid":"11664#11940#12162#13852","text":"10%#20%#30%#18%"},
{"testId":607,"qid":26249,"description":"Which of the following may result in a conviction for driving while impaired(DWI)?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#9803#25099#29384","text":"Any of the above#Over-the-counter medications#Marijuana#Prescription medications"},
{"testId":607,"qid":26250,"description":"First-time DWI聽offenders can be fined up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":1074,"qid":26250,"description":"First-time DWI聽offenders can be fined up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":607,"qid":26251,"description":"First-time DWI offenders face at least ______聽in jail.","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":20755,"isBookmarked":0,"aid":"20753#20754#20755#40915","text":"24 hours#48 hours#72 hours#12 hours"},
{"testId":1074,"qid":26251,"description":"First-time DWI offenders face at least ______聽in jail.","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":20755,"isBookmarked":0,"aid":"20753#20754#20755#40915","text":"24 hours#48 hours#72 hours#12 hours"},
{"testId":607,"qid":26252,"description":"First-time DWI offenders face jail time of up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1074,"qid":26252,"description":"First-time DWI offenders face jail time of up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":607,"qid":26255,"description":"A driver convicted of DWI on a second conviction faces a fine of up to :","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25035,"isBookmarked":0,"aid":"23887#23889#25035#40916","text":"$500#$1,000#$4,000#$6,000"},
{"testId":607,"qid":26257,"description":"For a second offense, a driver convicted of DWI faces a prison sentence of up to___.","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":607,"qid":26258,"description":"A driver who is convicted of DWI for the second time will lose his or her driving privileges for at least_____","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#12630","text":"180 days#90 days#30 days#360 days"},
{"testId":607,"qid":26260,"description":"Which of the following DWI offence is considered a felony?","explanation":null,"imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#13064#40917","text":"Second#Third#First#Second or third"},
{"testId":1074,"qid":26260,"description":"Which of the following DWI offence is considered a felony?","explanation":null,"imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#13064#40917","text":"Second#Third#First#Second or third"},
{"testId":607,"qid":26261,"description":"A driver convicted of DWI for the third time faces a fine of up to :","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"1799#3195#11658#12001","text":"$1,000#$5,000#$10,000#$3,000"},
{"testId":607,"qid":26262,"description":"For a third offense, a driver convicted of DWI faces a prison sentence of at least___.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":607,"qid":26263,"description":"For a third offense, a driver convicted of DWI faces a prison sentence of up to___.","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23154#24049#26672#29895","text":"1 year#90 days#5 years#10 years"},
{"testId":607,"qid":26265,"description":"If you're convicted of DWI for the first time with a passenger of less than 15 years in your vehicle, you can be fined up to:","explanation":"If you are convicted of DWI for the first time, the penalties are substantially severe if you have a passenger under the age of 15 in your car at the time of the violation. You will be fined up to $10,000 and sentenced to 180 days to two years in state prison. Your license will also be suspended for 90 days to one year (if you are under the age of 21) or 90 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"25034#25036#31397#33847","text":"$3,000#$5,000#$10,000#$15,000"},
{"testId":607,"qid":26266,"description":"If you're convicted of DWI for the first time with a passenger of less than 15 years in your vehicle, you can be sentenced to state jail for at least:","explanation":"If you are convicted of DWI for the first time, the penalties are substantially severe if you have a passenger under the age of 15 in your car at the time of the violation. You will be fined up to $10,000 and sentenced to 180 days to two years in state prison. Your license will also be suspended for 90 days to one year (if you are under the age of 21) or 90 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":607,"qid":26267,"description":"If you kill someone while driving drunk, you'll go to prison for:","explanation":null,"imageName":"","correctAnswer":40918,"isBookmarked":0,"aid":"40918#40919#40920#40921","text":"2 to 20 years#2 to 15 years#5 to 10 years#2 to 10 years"},
{"testId":1074,"qid":26267,"description":"If you kill someone while driving drunk, you'll go to prison for:","explanation":null,"imageName":"","correctAnswer":40918,"isBookmarked":0,"aid":"40918#40919#40920#40921","text":"2 to 20 years#2 to 15 years#5 to 10 years#2 to 10 years"},
{"testId":607,"qid":26268,"description":"If a driver under the age of 21 will face a fine of up to __________ if he or she is convicted of DUI (not DWI) for the first time.","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":607,"qid":26269,"description":"If a driver under the age of 21 is found guilty of DUI (not DWI) for the first time, he or she must conduct up to _______ of community service.","explanation":null,"imageName":"","correctAnswer":11695,"isBookmarked":0,"aid":"11482#11484#11694#11695","text":"10 hours#30 hours#20 hours#40 hours"},
{"testId":607,"qid":26270,"description":"If a motorist under the age of 18 is convicted for the first time of DUI (not DWI),:","explanation":null,"imageName":"","correctAnswer":27717,"isBookmarked":0,"aid":"27717#40922#40923#40924","text":"all of the above will happen#the driver's parent may be required to attend an alcohol awareness course along with him or her#the driver's license will be suspended for 60 days#the driver will be fined up to $500"},
{"testId":607,"qid":26271,"description":"If a driver under the age of 21 is convicted of DUI (not DWI) for the second time, he or she must conduct up to _______ hours of community service.","explanation":null,"imageName":"","correctAnswer":13917,"isBookmarked":0,"aid":"11694#11695#13917#40925","text":"20 hours#40 hours#60 hours#80 hours"},
{"testId":607,"qid":26273,"description":"If a driver under the age of 21 is convicted of DUI (not DWI) for the third time, he or she may be sentenced to prison for up to :","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23883#23886#24049#25858","text":"10 days#30 days#90 days#180 days"},
{"testId":607,"qid":26274,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Myths About Alcohol; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":1074,"qid":26274,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Myths About Alcohol; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":607,"qid":26275,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Myths About Alcohol; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6469#13861","text":"2#3#1#0.5"},
{"testId":1074,"qid":26275,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Myths About Alcohol; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6469#13861","text":"2#3#1#0.5"},
{"testId":607,"qid":26276,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"In Texas, a blood alcohol content (BAC) of 0.08 percent or more is considered legally inebriated and may result in charges of driving while intoxicated (DWI). [Know Your Legal Limit; Chapter 10: The Effects of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4251#4252","text":"0.08%#0.09%#0.02%#0.05%"},
{"testId":607,"qid":26277,"description":"A driver under the age of 21 can be charged with DUI if his or her blood alcohol content (BAC) is higher than____","explanation":"Minors are treated with zero tolerance in Texas. If a motorist under the age of 21 has any measurable level of alcohol in his or her system, he or she might be prosecuted with driving under the influence (DUI). [Texas Tough Alcohol-Related Laws for Minors; Chapter 10: The Influence of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23192#23318#27698#31711","text":"0.05%#0.02%#0.04%#Zero"},
{"testId":607,"qid":26278,"description":"What should you do if you are stopped by a police officer?","explanation":"If you are stopped by police enforcement, pull over to the right side of the road, turn off the engine, apply the emergency brake, and turn on the warning lights. Turn on the inside lights if the halt happens at night. Maintain your hands on the steering wheel until the officer instructs you otherwise. You'll probably have to show the police your license and proof of insurance, but tell him where they are before you reach for them. Don't fight with the police if he gives you a citation. A citation is not evidence of guilt. If you feel you are not guilty of the accusation, you will be able to dispute it later. [When Stopped by Cops; Chapter 14: Additional Safety Tips; Texas Driver Handbook]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#34789#40926#40927","text":"Do all of the above#Pull over to the right#Turn the engine off#Turn on your hazard flashers"},
{"testId":607,"qid":26279,"description":"You may be fined up to _____ for driving without wearing a seat belt.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":607,"qid":26280,"description":"Children must ride in child passenger safety seats in Texas if they are under the age of ____ and under 4 feet 9 inches tall.","explanation":"Children under the age of eight and less than 4' 9\" tall are required by Texas law to be fastened in child passenger safety seats. If you are found guilty of breaking this legislation, you will face a fine ranging from $25 to $250. [Safety Belts; Texas Driver Handbook; Chapter 14: Additional Safety Tips]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6478#7620#11342","text":"10#12#8#9"},
{"testId":607,"qid":26281,"description":"Who can be ticketed and fined if a 16-year-old passenger in your vehicle is not wearing a seat belt?","explanation":null,"imageName":"","correctAnswer":40929,"isBookmarked":0,"aid":"35127#40928#40929#40930","text":"Only the passenger#Only you#Both you and the passenger#Neither you nor the passenger"},
{"testId":607,"qid":26282,"description":"If you cause $200 or less in property damage in a hit-and-run collision, you might be penalized up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":607,"qid":26284,"description":"If you cause $200 or more in property damage in a hit-and-run collision, you might be penalized up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":607,"qid":26285,"description":"If you cause $200 or more in property damage in a hit-and-run accident, you might go to prison for up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23883#23886#24048#25858","text":"10 days#30 days#60 days#180 days"},
{"testId":607,"qid":26286,"description":"You should NOT drive if you are involved in an accident involving other people.","explanation":"If you are involved in an accident in Texas, you must stop at the site. For help, contact emergency services. Move your car off the road if it is drivable to help avoid another collision. Exchange your driver's license, registration, and proof of insurance with the other parties. Give wounded people fair aid. However, other than your insurance or the police, do not acknowledge blame or reveal the details of the collision. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"202006141611292838.jpg","correctAnswer":40932,"isBookmarked":0,"aid":"34083#38982#40931#40932","text":"move your vehicle off the roadway#stop at the accident scene#exchange contact and insurance information with them#discuss the nature of the accident with them"},
{"testId":607,"qid":26287,"description":"If convicted of hit-and-run causing harm, you might go to prison for up to:","explanation":"If you are involved in a collision with another person, you are required by Texas law to stop at the site. If you are found guilty of neglecting to do so and the accident causes harm to one or more people, you might face a $5,000 fine, up to 5 years in a state prison, or both. If the accident caused substantial physical harm, the maximum penalty would be increased. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":1074,"qid":26287,"description":"If convicted of hit-and-run causing harm, you might go to prison for up to:","explanation":"If you are involved in a collision with another person, you are required by Texas law to stop at the site. If you are found guilty of neglecting to do so and the accident causes harm to one or more people, you might face a $5,000 fine, up to 5 years in a state prison, or both. If the accident caused substantial physical harm, the maximum penalty would be increased. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":607,"qid":26288,"description":"If convicted of hit-and-run causing harm, you might be fined up to:","explanation":"If you are involved in a collision with another person, you are required by Texas law to stop at the site. If you are found guilty of neglecting to do so and the accident causes harm to one or more people, you might face a $5,000 fine, up to 5 years in a state prison, or both. If the accident caused substantial physical harm, the maximum penalty would be increased. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#24978#25036","text":"$500#$1,000#$100#$5,000"},
{"testId":1074,"qid":26288,"description":"If convicted of hit-and-run causing harm, you might be fined up to:","explanation":"If you are involved in a collision with another person, you are required by Texas law to stop at the site. If you are found guilty of neglecting to do so and the accident causes harm to one or more people, you might face a $5,000 fine, up to 5 years in a state prison, or both. If the accident caused substantial physical harm, the maximum penalty would be increased. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#24978#25036","text":"$500#$1,000#$100#$5,000"},
{"testId":607,"qid":26289,"description":"____ points will be added to your driving record if found guilty of a traffic infraction that caused a collision.","explanation":"If you are found guilty of a traffic offence, you will generally get two points on your driving record. If the infraction causes an accident, three points will be added to your driving record. The points will stay on your driving record for three years in any event. [DRP (Driver Responsibility Program); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1074,"qid":26289,"description":"____ points will be added to your driving record if found guilty of a traffic infraction that caused a collision.","explanation":"If you are found guilty of a traffic offence, you will generally get two points on your driving record. If the infraction causes an accident, three points will be added to your driving record. The points will stay on your driving record for three years in any event. [DRP (Driver Responsibility Program); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":607,"qid":26290,"description":"TxDPS will levy a premium if you accumulate ___ or more points on your driver record within three years.","explanation":"If you have six or more points on your driving record within three years, the TxDPS will apply a fee for each year that you have at least six points on your driver record. (Points are recorded on your driving record for three years.) The cost will be $100 for the first six points, with an extra $25 for each subsequent point. [DRP (Driver Responsibility Program); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1074,"qid":26290,"description":"TxDPS will levy a premium if you accumulate ___ or more points on your driver record within three years.","explanation":"If you have six or more points on your driving record within three years, the TxDPS will apply a fee for each year that you have at least six points on your driver record. (Points are recorded on your driving record for three years.) The cost will be $100 for the first six points, with an extra $25 for each subsequent point. [DRP (Driver Responsibility Program); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":607,"qid":26291,"description":"A motorist under the age of 18 must hold a learner license for at least _______ before applying for a provisional license.","explanation":"To apply for a provisional license under Texas's Graduated Driver License (GDL) program, a driver must be at least 16 years old but under 18 years old; hold a learner license for at least six months; pass a driver education course, a vision exam, and a driving exam; and provide proof of enrollment in or graduation from high school or the equivalent (e.g., GED). [Graduated Driver Licence (GDL); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":607,"qid":26292,"description":"A motorist with a provisional license may not transport more than ___ unrelated passengers under the age of 21.","explanation":"A motorist with a temporary license may not drive alone between the hours of midnight and 5:00 a.m. except while commuting to work or school, or when dealing with an emergency In addition, he or she may not transport more than one person under the age of 21 who is not a family member. [Graduated Driver Licence (GDL); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":607,"qid":26293,"description":"You may be fined up to _____ for driving with a suspended or revoked license.","explanation":"If you are found guilty of driving while your license is canceled, suspended, or revoked, you might face a $500 fine. There are a few exceptions where the penalty may be heavier. [Driving While License Is Invalid (DWLI); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":607,"qid":26294,"description":"You may be fined up to _____ for driving without a license.","explanation":"If you are caught driving without a license, you might face a fine of up to $200. There are a few exceptions where the penalty may be heavier. [Penalties for Driving While Unlicensed; Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":607,"qid":26295,"description":"If an unlicensed and uninsured motorist causes a collision that results in significant bodily harm or death, he or she faces a fine of up to ________.","explanation":null,"imageName":"","correctAnswer":25035,"isBookmarked":0,"aid":"23889#25033#25034#25035","text":"$1,000#$2,000#$3,000#$4,000"},
{"testId":608,"qid":26296,"description":"If you are guilty of driving while intoxicated (DUI), your license will not be suspended if this is your ______ offense.","explanation":"If you are at least 21 years old and are convicted of DUI for the first time, your license will be suspended for 120 days. If you are under the age of 21, your license will be suspended for one year or until you reach the age of 21, whichever comes first. Your license will be revoked for two years or until you reach the age of 21, whichever comes first. After the revocation term expires, you must apply for a new license. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook; The Drinking Driver/Drug User]","imageName":"","correctAnswer":13064,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1075,"qid":26296,"description":"If you are guilty of driving while intoxicated (DUI), your license will not be suspended if this is your ______ offense.","explanation":"If you are at least 21 years old and are convicted of DUI for the first time, your license will be suspended for 120 days. If you are under the age of 21, your license will be suspended for one year or until you reach the age of 21, whichever comes first. Your license will be revoked for two years or until you reach the age of 21, whichever comes first. After the revocation term expires, you must apply for a new license. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook; The Drinking Driver/Drug User]","imageName":"","correctAnswer":13064,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":608,"qid":26297,"description":"Which of the following convictions will result in the revocation of your license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11603#41081#41082","text":"Any of the above#Vehicular manslaughter#Leaving the scene of an accident (hit and run) in which someone was injured#Using a motor vehicle to commit a felony"},
{"testId":1075,"qid":26297,"description":"Which of the following convictions will result in the revocation of your license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#11603#41081#41082","text":"Any of the above#Vehicular manslaughter#Leaving the scene of an accident (hit and run) in which someone was injured#Using a motor vehicle to commit a felony"},
{"testId":608,"qid":26298,"description":"According to the Utah Driver Handbook, studies have indicated that marijuana users are more likely to:","explanation":"Driving under the influence of marijuana might be risky. According to the Utah Driver Handbook, marijuana users are more likely to commit driving errors, get arrested for traffic infractions, and be sensitive to spotlight glare. [Driving While Under the Influence of Alcohol or Drugs; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41086,"isBookmarked":0,"aid":"41083#41084#41085#41086","text":"be arrested for traffic violations#make driving mistakes#be sensitive to headlight glare#suffer all of the above"},
{"testId":1075,"qid":26298,"description":"According to the Utah Driver Handbook, studies have indicated that marijuana users are more likely to:","explanation":"Driving under the influence of marijuana might be risky. According to the Utah Driver Handbook, marijuana users are more likely to commit driving errors, get arrested for traffic infractions, and be sensitive to spotlight glare. [Driving While Under the Influence of Alcohol or Drugs; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41086,"isBookmarked":0,"aid":"41083#41084#41085#41086","text":"be arrested for traffic violations#make driving mistakes#be sensitive to headlight glare#suffer all of the above"},
{"testId":608,"qid":26299,"description":"If your BAC is 0.08% or higher, you are ___ times more likely to be involved in a traffic collision than if you are sober.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":608,"qid":26300,"description":"If a driver over the age of 21 decline to submit to a chemical test for alcohol or drugs, his or her driver's license may be suspended for ___ months on the first violation.","explanation":"By driving a motor vehicle in Utah, a motorist has implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. If a motorist over the age of 21 refuses to undergo a chemical test for alcohol or drugs, his or her license may be suspended for 18 months on the first violation. If the driver is under the age of 21, his or her license may be revoked for two years or until the age of 21, whichever comes first. [The Drinking Driver/Drug Use; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#6916#11342#11359","text":"12#6#9#18"},
{"testId":1075,"qid":26300,"description":"If a driver over the age of 21 decline to submit to a chemical test for alcohol or drugs, his or her driver's license may be suspended for ___ months on the first violation.","explanation":"By driving a motor vehicle in Utah, a motorist has implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. If a motorist over the age of 21 refuses to undergo a chemical test for alcohol or drugs, his or her license may be suspended for 18 months on the first violation. If the driver is under the age of 21, his or her license may be revoked for two years or until the age of 21, whichever comes first. [The Drinking Driver/Drug Use; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#6916#11342#11359","text":"12#6#9#18"},
{"testId":608,"qid":26301,"description":"If a driver over the age of 21 refuses to submit to a chemical test for alcohol or drugs, his or her license may be suspended for ___ months for a second offense.","explanation":"By driving a motor vehicle in Utah, a motorist has implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. If a motorist over the age of 21 refuses to submit to a chemical test for alcohol or drugs, his or her license may be suspended for 36 months for a second violation. If the driver is under the age of 21, his or her license may be suspended for 36 months or until the age of 21, whichever comes first. [Driving While Under the Influence of Alcohol or Drugs; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":12432,"isBookmarked":0,"aid":"11359#12040#12432#12433","text":"18#24#36#48"},
{"testId":1075,"qid":26301,"description":"If a driver over the age of 21 refuses to submit to a chemical test for alcohol or drugs, his or her license may be suspended for ___ months for a second offense.","explanation":"By driving a motor vehicle in Utah, a motorist has implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. If a motorist over the age of 21 refuses to submit to a chemical test for alcohol or drugs, his or her license may be suspended for 36 months for a second violation. If the driver is under the age of 21, his or her license may be suspended for 36 months or until the age of 21, whichever comes first. [Driving While Under the Influence of Alcohol or Drugs; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":12432,"isBookmarked":0,"aid":"11359#12040#12432#12433","text":"18#24#36#48"},
{"testId":608,"qid":26302,"description":"Under Utah's Implied Assent Law, driving on Utah's public highways constitutes implied consent to :","explanation":"By driving a motor vehicle in Utah, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. [The Drinking Driver/Drug Use; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":608,"qid":26303,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":"Your licence can be suspended if you're found guilty of operating a vehicle without liability insurance or are arrested for DUI. A conviction for a non-traffic charge involving failure to pay child support may also result in the suspension of a driver's licence. A fine is often imposed as the result of a speeding ticket. (However, depending on how fast you were going at the time, each conviction for speeding will result in 35 to 75 points being added to your driving record. Your licence may be suspended if you accrue enough points.) [Utah Driver Handbook: When Your Right to Drive Might Be Suspended; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#27198#41117#41118","text":"Speeding#Failure to pay child support#Your first DUI#Driving uninsured"},
{"testId":608,"qid":26304,"description":"Your license will be revoked if you are convicted _______聽of reckless driving within a year.","explanation":"Your license will be revoked if you are convicted of careless driving twice within one year. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":1075,"qid":26304,"description":"Your license will be revoked if you are convicted _______聽of reckless driving within a year.","explanation":"Your license will be revoked if you are convicted of careless driving twice within one year. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11672,"isBookmarked":0,"aid":"13#15#16#11672","text":"four times#three times#five times#twice"},
{"testId":608,"qid":26305,"description":"As on the 30th of December, 2018, if an adult motorist in Utah has a BAC of _____ or above, they will be criminally prosecuted with driving under the influence (DUI).","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3168#3282#4252#4253","text":"0.08%#0.06%#0.05%#0.03%"},
{"testId":1075,"qid":26305,"description":"As on the 30th of December, 2018, if an adult motorist in Utah has a BAC of _____ or above, they will be criminally prosecuted with driving under the influence (DUI).","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3168#3282#4252#4253","text":"0.08%#0.06%#0.05%#0.03%"},
{"testId":608,"qid":26306,"description":"If a driver under the age of 21 is convicted of DUI, his or her license will be suspended for _______ days for a first offense.","explanation":null,"imageName":"","correctAnswer":228,"isBookmarked":0,"aid":"228#229#742#1020","text":"120 days#90 days#30 days#60 days"},
{"testId":1075,"qid":26306,"description":"If a driver under the age of 21 is convicted of DUI, his or her license will be suspended for _______ days for a first offense.","explanation":null,"imageName":"","correctAnswer":228,"isBookmarked":0,"aid":"228#229#742#1020","text":"120 days#90 days#30 days#60 days"},
{"testId":608,"qid":26307,"description":"If a driver over the age of 21 is convicted of DUI, his or her license will be suspended for _______ for a second conviction.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":1075,"qid":26307,"description":"If a driver over the age of 21 is convicted of DUI, his or her license will be suspended for _______ for a second conviction.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":608,"qid":26308,"description":"A Utah citizen between the ages of 13 and 20 who is found guilty of owning or consuming alcohol will have their license suspended for ______ days for a first conviction.","explanation":"If a Utah citizen between the ages of 13 and 20 is convicted of carrying or ingesting alcohol or being in a bar for the first time, his or her license will be suspended for one year. The suspension will commence on the day of conviction and will last one year from the person's 16th birthday. If the individual is under the age of 18, the suspension will commence when the youngster becomes eligible. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#739#1021","text":"180 days#90 days#10 days#1 year"},
{"testId":1075,"qid":26308,"description":"A Utah citizen between the ages of 13 and 20 who is found guilty of owning or consuming alcohol will have their license suspended for ______ days for a first conviction.","explanation":"If a Utah citizen between the ages of 13 and 20 is convicted of carrying or ingesting alcohol or being in a bar for the first time, his or her license will be suspended for one year. The suspension will commence on the day of conviction and will last one year from the person's 16th birthday. If the individual is under the age of 18, the suspension will commence when the youngster becomes eligible. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#739#1021","text":"180 days#90 days#10 days#1 year"},
{"testId":608,"qid":26309,"description":"A Utah citizen between the ages of 13 and 20 who is found guilty of owning or consuming alcohol will have their license suspended for ______ days for a second conviction.","explanation":"If a Utah citizen between the ages of 13 and 20 is convicted of carrying or ingesting alcohol or being in a bar for the second or subsequent time, his or her license will be suspended for two years. For a driver under the age of 16, the suspension will commence on the day of conviction and will last two years. If the individual is under the age of 18, the suspension will commence when the youngster becomes eligible. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#229#1021#11285","text":"180 days#90 days#1 year#2 years"},
{"testId":1075,"qid":26309,"description":"A Utah citizen between the ages of 13 and 20 who is found guilty of owning or consuming alcohol will have their license suspended for ______ days for a second conviction.","explanation":"If a Utah citizen between the ages of 13 and 20 is convicted of carrying or ingesting alcohol or being in a bar for the second or subsequent time, his or her license will be suspended for two years. For a driver under the age of 16, the suspension will commence on the day of conviction and will last two years. If the individual is under the age of 18, the suspension will commence when the youngster becomes eligible. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#229#1021#11285","text":"180 days#90 days#1 year#2 years"},
{"testId":608,"qid":26310,"description":"Under Utah's \"Not a Drop\" Law, if a driver under 21 years of age is found guilty of driving with a blood alcohol concentration (BAC) greater than __________, his or her license will be suspended.","explanation":"Under Utah's \"Not a Drop\" Law, a minor may not drive with any measurable amount of alcohol in his or her system. If a juvenile is convicted for the first time of breaking this statute, his or her license will be revoked for six months or until the minor completes an alcohol evaluation and any recommendations given by an accredited substance addiction practitioner, whichever comes first. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"3281#4251#4252#35229","text":"0.04%#0.02%#0.05%#Zero"},
{"testId":608,"qid":26312,"description":"An adult motorist convicted of DUI for the first time will be forced to install an ignition interlock device (IID) in his or her car for:","explanation":"An ignition interlock device (IID) must be installed in any vehicle a licenced adult driver operates for a period of 18 months after a first-time DUI conviction. The IID will keep track of the driver's blood alcohol content (BAC) and stop the engine from starting if it rises beyond a certain threshold. [Utah Driver Handbook: Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Ignition Interlock Restricted Driver Suspension;]","imageName":"","correctAnswer":25031,"isBookmarked":0,"aid":"23152#23153#25031#25169","text":"3 months#6 months#18 months#12 months"},
{"testId":608,"qid":26313,"description":"For how much of a period of time will a driver under 21 years of age be required to install an ignition interlock device (IID) if he or she is convicted of DUI?","explanation":"A motorist under 21 who is convicted of DUI must install an ignition interlock device (IID) in every car he or she drives for three years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":608,"qid":26314,"description":"A driver who is convicted of DUI for the second time must install an ignition interlock device (IID) in his or her car for a period of______ time.","explanation":"A motorist convicted of DUI for the second time must install an ignition interlock device (IID) in every car he or she drives for the next three years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":608,"qid":26315,"description":"A driver convicted of felony DUI must install an ignition interlock device (IID) in his or her vehicle for:","explanation":"DUI is often classified as a Class B or Class A misdemeanor in Utah. DUI, on the other hand, will be regarded a third-degree felony under the following circumstances: (1) DUI causes a major accident, (2) two previous DUI convictions within the last 10 years, or (3) a prior conviction for criminal DUI or vehicular murder. A third-degree felony is punished by a $5,000 fine, up to five years in prison, or both. A motorist convicted of felony DUI must additionally install an ignition interlock device (IID) in every car he or she drives for the next six years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#26672#29881#29894","text":"4 years#5 years#6 years#7 years"},
{"testId":608,"qid":26316,"description":"If you had your license suspended or revoked due to an alcohol or drug-related violation, you must pay a reinstatement fee of ____ before your driving privileges can be reinstated.","explanation":"If your license was suspended or revoked as a result of a conviction, you must pay a reinstatement fee before your driving privileges may be reinstated. The license reinstatement charge in Utah is $40 for most crimes; the price for an alcohol or drug-related violation is currently $85. (Note: The 2017-2018 Utah Driver Handbook still has the previous reinstatement charge of $65.) You may also have to pay a staggering administrative cost of $255 if you are charged with an alcohol or drug-related violation. [When Your Driving Privilege Could Be Suspended; What's New in the Handbook; Utah Driver Handbook], [\"Driver License Fees,\" Utah Department of Public Safety, https://dld.utah.gov/other-resources/fees/]","imageName":"","correctAnswer":13812,"isBookmarked":0,"aid":"11348#13812#13887#13888","text":"$25#$85#$45#$65"},
{"testId":1075,"qid":26316,"description":"If you had your license suspended or revoked due to an alcohol or drug-related violation, you must pay a reinstatement fee of ____ before your driving privileges can be reinstated.","explanation":"If your license was suspended or revoked as a result of a conviction, you must pay a reinstatement fee before your driving privileges may be reinstated. The license reinstatement charge in Utah is $40 for most crimes; the price for an alcohol or drug-related violation is currently $85. (Note: The 2017-2018 Utah Driver Handbook still has the previous reinstatement charge of $65.) You may also have to pay a staggering administrative cost of $255 if you are charged with an alcohol or drug-related violation. [When Your Driving Privilege Could Be Suspended; What's New in the Handbook; Utah Driver Handbook], [\"Driver License Fees,\" Utah Department of Public Safety, https://dld.utah.gov/other-resources/fees/]","imageName":"","correctAnswer":13812,"isBookmarked":0,"aid":"11348#13812#13887#13888","text":"$25#$85#$45#$65"},
{"testId":608,"qid":26317,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":1075,"qid":26317,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":608,"qid":26318,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, you could go to jail for up to:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23152#23153#25170","text":"9 months#3 months#6 months#1 month"},
{"testId":1075,"qid":26318,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, you could go to jail for up to:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23152#23153#25170","text":"9 months#3 months#6 months#1 month"},
{"testId":608,"qid":26319,"description":"Adult drivers who have accumulated ____ or more points in the previous three years must appear for a hearing.","explanation":"Any adult driver who has racked up 200 points or more in the last three years will have to show up for a hearing. The hearing's goal is to decide what remedial measures, such as licence suspension or enrollment in a defensive driving course, should be implemented. [Point System; Utah Driver Handbook Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":7570,"isBookmarked":0,"aid":"6591#7570#7571#12996","text":"100#200#50#150"},
{"testId":1075,"qid":26319,"description":"Adult drivers who have accumulated ____ or more points in the previous three years must appear for a hearing.","explanation":"Any adult driver who has racked up 200 points or more in the last three years will have to show up for a hearing. The hearing's goal is to decide what remedial measures, such as licence suspension or enrollment in a defensive driving course, should be implemented. [Point System; Utah Driver Handbook Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":7570,"isBookmarked":0,"aid":"6591#7570#7571#12996","text":"100#200#50#150"},
{"testId":608,"qid":26322,"description":"If a driver less than 21 years old earns excessive points for offenses, his or her driving license may be suspended for up to:","explanation":"If a motorist under the age of 21 collects 70 or more points in three years, his or her license may be suspended for 30 days to one year, depending on the severity of his or her driving record. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1075,"qid":26322,"description":"If a driver less than 21 years old earns excessive points for offenses, his or her driving license may be suspended for up to:","explanation":"If a motorist under the age of 21 collects 70 or more points in three years, his or her license may be suspended for 30 days to one year, depending on the severity of his or her driving record. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":608,"qid":26324,"description":"_______ points will be added to your driving record if you are found guilty of speeding.","explanation":null,"imageName":"","correctAnswer":12455,"isBookmarked":0,"aid":"5447#9658#12455#13890","text":"15#55#35#95"},
{"testId":1075,"qid":26324,"description":"_______ points will be added to your driving record if you are found guilty of speeding.","explanation":null,"imageName":"","correctAnswer":12455,"isBookmarked":0,"aid":"5447#9658#12455#13890","text":"15#55#35#95"},
{"testId":608,"qid":26325,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are convicted of failing to cede the right-of-way, you will get 60 points on your driving record. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6591#8141#11513#12359","text":"100#60#40#80"},
{"testId":1075,"qid":26325,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are convicted of failing to cede the right-of-way, you will get 60 points on your driving record. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6591#8141#11513#12359","text":"100#60#40#80"},
{"testId":608,"qid":26326,"description":"__ points will be added to your driving record for tailgating.","explanation":"If you are guilty of tailgating (following another vehicle too closely), you will get 60 points on your license. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6591#8141#11513#12359","text":"100#60#40#80"},
{"testId":608,"qid":26327,"description":"Except for_______, all of the following offences is worth 50 point penalty.","explanation":"Driving on the wrong side of the road and driving the wrong way down a one-way street both result in a 60-point penalty. Failure to obey a red light, failure to heed a stop sign, and improper passing all result in a 50-point penalty. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41183,"isBookmarked":0,"aid":"41180#41181#41182#41183","text":"running a red light#failing to obey a stop sign#improper passing#driving the wrong way on a one-way street"},
{"testId":1075,"qid":26327,"description":"Except for_______, all of the following offences is worth 50 point penalty.","explanation":"Driving on the wrong side of the road and driving the wrong way down a one-way street both result in a 60-point penalty. Failure to obey a red light, failure to heed a stop sign, and improper passing all result in a 50-point penalty. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41183,"isBookmarked":0,"aid":"41180#41181#41182#41183","text":"running a red light#failing to obey a stop sign#improper passing#driving the wrong way on a one-way street"},
{"testId":608,"qid":26328,"description":"The court has the authority to increase or decrease _____ of points issued for a traffic offense other than speeding.","explanation":"The court has the authority to reduce the points granted for a traffic offence other than speeding by 10%. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41184,"isBookmarked":0,"aid":"41184#41185#41186#41187","text":"10%#20%#40%#50%"},
{"testId":1075,"qid":26328,"description":"The court has the authority to increase or decrease _____ of points issued for a traffic offense other than speeding.","explanation":"The court has the authority to reduce the points granted for a traffic offence other than speeding by 10%. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":41184,"isBookmarked":0,"aid":"41184#41185#41186#41187","text":"10%#20%#40%#50%"},
{"testId":608,"qid":26329,"description":"If you drive for _______ without getting a traffic ticket, half of the points on your license will be eliminated.","explanation":"If you keep a clean driving record for one year, half of the points on your record will be deleted. Individual conviction points are automatically deleted from your record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1075,"qid":26329,"description":"If you drive for _______ without getting a traffic ticket, half of the points on your license will be eliminated.","explanation":"If you keep a clean driving record for one year, half of the points on your record will be deleted. Individual conviction points are automatically deleted from your record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":608,"qid":26331,"description":"Individual conviction points are automatically deleted from your driving record _______ after the infraction date.","explanation":"Individual conviction points are automatically deleted from your driving record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"1021#1272#11285#11306","text":"1 year#6 months#2 years#3 years"},
{"testId":1075,"qid":26331,"description":"Individual conviction points are automatically deleted from your driving record _______ after the infraction date.","explanation":"Individual conviction points are automatically deleted from your driving record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"1021#1272#11285#11306","text":"1 year#6 months#2 years#3 years"},
{"testId":608,"qid":26332,"description":"___ points will be deducted from your driving record if you successfully complete an approved Defensive Driving Course.","explanation":"If you successfully complete an authorized Defensive Driving Course, you will have 50 points erased from your driving record. (You may take a course like this every three years to eliminate 50 points.) You may be eligible for a reduction on your vehicle liability insurance rates if you complete a Defensive Driving Course in person rather than online. [\"How to Clear Points From Your Record,\" Utah Safety Council, http://ddc.utahsafetycouncil.org/ProductDetails.aspx?ProductID=778], [\"Defensive Driving (DDC-4) Online Course,\" Utah Safety Council, http://ddc.utahsafetycouncil.org/ProductDetails.aspx?ProductID=778]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6591#7571#9656#12455","text":"100#50#70#35"},
{"testId":1075,"qid":26332,"description":"___ points will be deducted from your driving record if you successfully complete an approved Defensive Driving Course.","explanation":"If you successfully complete an authorized Defensive Driving Course, you will have 50 points erased from your driving record. (You may take a course like this every three years to eliminate 50 points.) You may be eligible for a reduction on your vehicle liability insurance rates if you complete a Defensive Driving Course in person rather than online. [\"How to Clear Points From Your Record,\" Utah Safety Council, http://ddc.utahsafetycouncil.org/ProductDetails.aspx?ProductID=778], [\"Defensive Driving (DDC-4) Online Course,\" Utah Safety Council, http://ddc.utahsafetycouncil.org/ProductDetails.aspx?ProductID=778]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6591#7571#9656#12455","text":"100#50#70#35"},
{"testId":608,"qid":26333,"description":"When you pass an approved Defensive Driving Course, you can get points taken off your driving record, but only once every:","explanation":"Points from your driving record may be deleted if you successfully complete an authorized Defensive Driving Course, but only once every three years. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1075,"qid":26333,"description":"When you pass an approved Defensive Driving Course, you can get points taken off your driving record, but only once every:","explanation":"Points from your driving record may be deleted if you successfully complete an authorized Defensive Driving Course, but only once every three years. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":608,"qid":26334,"description":"Offenses involving alcohol or drugs will remain on your driving record for:","explanation":"A conviction for a traffic offence involving alcohol or drugs, a licence suspension, or a licence revocation will stay on your driving record for 10 years. Your driving record will include any further traffic offences, licence suspensions, and licence revocations for three years. [Utah Driver Handbook, Driver's Record, Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23183#26672#28011#29895","text":"3 years#5 years#15 years#10 years"},
{"testId":1075,"qid":26334,"description":"Offenses involving alcohol or drugs will remain on your driving record for:","explanation":"A conviction for a traffic offence involving alcohol or drugs, a licence suspension, or a licence revocation will stay on your driving record for 10 years. Your driving record will include any further traffic offences, licence suspensions, and licence revocations for three years. [Utah Driver Handbook, Driver's Record, Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23183#26672#28011#29895","text":"3 years#5 years#15 years#10 years"},
{"testId":608,"qid":26335,"description":"Driving without liability insurance or other proof of financial responsibility carries a minimum______fine.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"24978#27888#31696#33102","text":"$100#$250#$400#$300"},
{"testId":608,"qid":26336,"description":"How can you demonstrate financial responsibility after an accident in which you were the at-fault driver?","explanation":"There are numerous methods to establish financial responsibility after an accident in which you were at fault: (1) Show evidence that you have a motor liability insurance coverage. (2) Deposit with the DPS sufficient monies to compensate any damages caused by all other parties involved in the accident. (3) Agree in writing to reimburse all other parties directly for any damages sustained. (4) Provide documentation demonstrating that all other parties have absolved you from obligation. [Providing Post-Crash Security; Appendix D: Crashes and Insurance; Utah Driver Handbook]","imageName":"","correctAnswer":39057,"isBookmarked":0,"aid":"39057#41188#41189#41190","text":"Do any of the above#Provide proof that you were covered by a vehicle liability insurance policy#Deposit funds with the DPS to cover your liability#Agree in writing to pay the other parties for all losses suffered"},
{"testId":1075,"qid":26336,"description":"How can you demonstrate financial responsibility after an accident in which you were the at-fault driver?","explanation":"There are numerous methods to establish financial responsibility after an accident in which you were at fault: (1) Show evidence that you have a motor liability insurance coverage. (2) Deposit with the DPS sufficient monies to compensate any damages caused by all other parties involved in the accident. (3) Agree in writing to reimburse all other parties directly for any damages sustained. (4) Provide documentation demonstrating that all other parties have absolved you from obligation. [Providing Post-Crash Security; Appendix D: Crashes and Insurance; Utah Driver Handbook]","imageName":"","correctAnswer":39057,"isBookmarked":0,"aid":"39057#41188#41189#41190","text":"Do any of the above#Provide proof that you were covered by a vehicle liability insurance policy#Deposit funds with the DPS to cover your liability#Agree in writing to pay the other parties for all losses suffered"},
{"testId":608,"qid":26337,"description":"If you fail to maintain financial accountability, you'll need to \"future proof\" for _______.","explanation":"If you fail to fulfill an accident-related judgment or are found guilty of failing to maintain financial responsibility, you will be compelled to provide \"future proof\" of financial responsibility with the DPS for the following three years. The most typical method is to get an SR-22 paperwork proving that you have a car liability insurance coverage. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Application D: Crashes and Insurance; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#27048","text":"2 years#4 years#3 years#year"},
{"testId":1075,"qid":26337,"description":"If you fail to maintain financial accountability, you'll need to \"future proof\" for _______.","explanation":"If you fail to fulfill an accident-related judgment or are found guilty of failing to maintain financial responsibility, you will be compelled to provide \"future proof\" of financial responsibility with the DPS for the following three years. The most typical method is to get an SR-22 paperwork proving that you have a car liability insurance coverage. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Application D: Crashes and Insurance; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#27048","text":"2 years#4 years#3 years#year"},
{"testId":608,"qid":26338,"description":"Which Utah residents are required to keep proof of financial responsibility?","explanation":"Every owner of a motor vehicle (car, truck, or motorbike) in Utah is required to keep evidence of financial responsibility. [Utah Driver Handbook; Appendix D: Crashes and Insurance; Utah Motor Vehicle Financial Responsibility Law]","imageName":"","correctAnswer":41191,"isBookmarked":0,"aid":"41191#41192#41193#41194","text":"Every owner of a motor vehicle#Every owner of a motor vehicle who is at least 21 years of age#Every owner of a motor vehicle costing more than $25,000#Every owner of a motor vehicle manufactured after 2000"},
{"testId":1075,"qid":26338,"description":"Which Utah residents are required to keep proof of financial responsibility?","explanation":"Every owner of a motor vehicle (car, truck, or motorbike) in Utah is required to keep evidence of financial responsibility. [Utah Driver Handbook; Appendix D: Crashes and Insurance; Utah Motor Vehicle Financial Responsibility Law]","imageName":"","correctAnswer":41191,"isBookmarked":0,"aid":"41191#41192#41193#41194","text":"Every owner of a motor vehicle#Every owner of a motor vehicle who is at least 21 years of age#Every owner of a motor vehicle costing more than $25,000#Every owner of a motor vehicle manufactured after 2000"},
{"testId":608,"qid":26339,"description":"if you or any of your passengers are not using a seat belt or an approved child safety seat., you can be cited and fined:","explanation":"Seat belts or proper child restraints are required by Utah law for the driver and all passengers. If you or any passenger in your car is not wearing a seat belt or an adequate child restraint, you might be penalized and fined $45. [Violations of Seat Belts; Section 5: Before You Drive; Utah Driver Handbook]","imageName":"","correctAnswer":41195,"isBookmarked":0,"aid":"24993#31885#41195#41196","text":"$25#$85#$45#$65"},
{"testId":608,"qid":26340,"description":"You may not smoke in your vehicle if any of your passengers is _____ years old or younger, according to Utah law.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#6478#11783#11784","text":"15#12#13#14"},
{"testId":608,"qid":26341,"description":"Except for job, school-related activities, or emergencies, a licensed driver under the age of 17 may not drive unattended between midnight and:","explanation":null,"imageName":"","correctAnswer":13578,"isBookmarked":0,"aid":"13578#13579#13637#13899","text":"5:00 a.m#4:00 a.m#6:00 a.m#3:00 a.m"},
{"testId":608,"qid":26342,"description":"A licensed driver under the age of 18 may not carry unrelated passengers until he or she reaches the age of 18 or has had the license for at least :","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":608,"qid":26343,"description":"Your Utah driver's licence can be renewed up to _____ before it runs out. :","explanation":"Your Utah driving license will expire five years after you get it. You have up to six months before it expires to renew it. [Utah Driver Handbook; Section 3: License Information; Driver License Renewal, Duplicates, and Lapsed/Expired Licenses]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1021#1272#1453#12291","text":"1 year#6 months#3 months#4 months"},
{"testId":1075,"qid":26343,"description":"Your Utah driver's licence can be renewed up to _____ before it runs out. :","explanation":"Your Utah driving license will expire five years after you get it. You have up to six months before it expires to renew it. [Utah Driver Handbook; Section 3: License Information; Driver License Renewal, Duplicates, and Lapsed/Expired Licenses]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1021#1272#1453#12291","text":"1 year#6 months#3 months#4 months"},
{"testId":608,"qid":26344,"description":"An aspiring driver under the age of 18 with a learner permit may only drive under the supervision of:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#41197#41198#41199","text":"Any of the above#an approved driving instructor#the driver's parent or guardian#the adult driver who co-signed the application for the learner permit"},
{"testId":608,"qid":26345,"description":"A Utah driver's license will expire in:","explanation":"Your Utah driving license will expire five years after you get it. You have up to six months before it expires to renew it. [Utah Driver Handbook; Section 3: License Information; Driver License Renewal, Duplicates, and Lapsed/Expired Licenses]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":1075,"qid":26345,"description":"A Utah driver's license will expire in:","explanation":"Your Utah driving license will expire five years after you get it. You have up to six months before it expires to renew it. [Utah Driver Handbook; Section 3: License Information; Driver License Renewal, Duplicates, and Lapsed/Expired Licenses]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20790#23183#26672#29881","text":"4 years#3 years#5 years#6 years"},
{"testId":599,"qid":26346,"description":"You'll be fined____聽for not wearing a seatbelt while driving.","explanation":null,"imageName":"","correctAnswer":33510,"isBookmarked":0,"aid":"24978#24994#27902#33510","text":"$100#$50#$75#$30"},
{"testId":599,"qid":26347,"description":"If an adult passenger is found guilty of not wearing a safety belt while seated in the front seat of your car, they will be fined:","explanation":null,"imageName":"","correctAnswer":33509,"isBookmarked":0,"aid":"24994#33509#33510#33835","text":"$50#$20#$30#$10"},
{"testId":599,"qid":26348,"description":"If you are found guilty of violating state law mandating the use of child safety restraints for the first time, you may be fined ______.","explanation":"A first infraction under Ohio's child safety restraint legislation carries a punishment of between $25 and $100. [Child Restraints; 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"23889#24978#24995#33102","text":"$1,000#$100#$150#$300"},
{"testId":599,"qid":26349,"description":"If you are found guilty of violating state law mandating the use of child safety restraints for the second time, you may be fined up to ______ or sentenced to up to ___ days in prison, or both. .","explanation":null,"imageName":"","correctAnswer":13905,"isBookmarked":0,"aid":"13902#13903#13904#13905","text":"$100; 3#$100; 30#$250; 3#$250; 30"},
{"testId":599,"qid":26350,"description":"In Ohio, what term is used to describe driving while under the influence of alcohol or controlled substances?","explanation":"Operating a Vehicle While Impaired (OVI) in Ohio refers to driving while under the influence of alcohol or controlled drugs. Other states use words like Driving Under the Influence (DUI) or Driving While Impaired (DWI) (DWI). (Note: It does not have to be a motor vehicle under Ohio law. For example, if you are detected riding a bicycle while intoxicated, you may be charged with OVI.) [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":13906,"isBookmarked":0,"aid":"897#12821#13752#13906","text":"None of the above#Driving Under the Influence (DUI)#Driving While Impaired (DWI)#Operating a Vehicle While Impaired (OVI)"},
{"testId":1066,"qid":26350,"description":"In Ohio, what term is used to describe driving while under the influence of alcohol or controlled substances?","explanation":"Operating a Vehicle While Impaired (OVI) in Ohio refers to driving while under the influence of alcohol or controlled drugs. Other states use words like Driving Under the Influence (DUI) or Driving While Impaired (DWI) (DWI). (Note: It does not have to be a motor vehicle under Ohio law. For example, if you are detected riding a bicycle while intoxicated, you may be charged with OVI.) [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":13906,"isBookmarked":0,"aid":"897#12821#13752#13906","text":"None of the above#Driving Under the Influence (DUI)#Driving While Impaired (DWI)#Operating a Vehicle While Impaired (OVI)"},
{"testId":599,"qid":26351,"description":"Which of the following chemical tests could be used by law enforcement in Ohio to establish whether you are under the influence of alcohol or drugs?","explanation":"Law enforcement may use blood, breath, plasma, or urine tests to detect if you are under the influence of alcohol or narcotics. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#39070","text":"All of the above#Breath#Blood#Plasma"},
{"testId":1066,"qid":26351,"description":"Which of the following chemical tests could be used by law enforcement in Ohio to establish whether you are under the influence of alcohol or drugs?","explanation":"Law enforcement may use blood, breath, plasma, or urine tests to detect if you are under the influence of alcohol or narcotics. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#39070","text":"All of the above#Breath#Blood#Plasma"},
{"testId":599,"qid":26352,"description":"It is prohibited to drive with a blood alcohol content (BAC) of _____ or higher.","explanation":"If your BAC is 0.08 percent or more, your driving license will be suspended. You will face criminal consequences if you are convicted of OVI. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":599,"qid":26353,"description":"A blood alcohol test must be done within ___ hours following an arrest in Ohio.","explanation":"A blood alcohol test must be given within three hours following an arrest in Ohio, according to state law. Otherwise, the test findings may not be acceptable as evidence in court. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1066,"qid":26353,"description":"A blood alcohol test must be done within ___ hours following an arrest in Ohio.","explanation":"A blood alcohol test must be given within three hours following an arrest in Ohio, according to state law. Otherwise, the test findings may not be acceptable as evidence in court. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":599,"qid":26354,"description":"If you are caught for suspected OVI and your BAC is 0.08 percent or more, the Registrar of Motor Vehicles will suspend your driver's license for at least :","explanation":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or above, the Registrar of Motor Vehicles will suspend your license for 90 days to three years. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":599,"qid":26355,"description":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or higher, the Registrar of Motor Vehicles may suspend your license for :","explanation":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or above, the Registrar of Motor Vehicles will suspend your license for 90 days to three years. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":599,"qid":26357,"description":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driver's license for up to :","explanation":"If you are stopped for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driving license for one to five years, even if you are later found not guilty of OVI in court. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Digest of Ohio Motor Vehicle Laws], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/sites/default/files/pdfs/ovichart.pdf], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/ .pdf]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"26672#29894#29895#39071","text":"5 years#7 years#10 years#9 years"},
{"testId":599,"qid":26358,"description":"If the refusal of a chemical test happens within ___ years of the first violation, it is deemed a second offense.","explanation":"If the refusal of a chemical test happens within six years of the first violation, it is deemed a second offense. A second violation may result in harsher consequences than a first one. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; The Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5447#6478#6916#7559","text":"15#12#6#7"},
{"testId":1066,"qid":26358,"description":"If the refusal of a chemical test happens within ___ years of the first violation, it is deemed a second offense.","explanation":"If the refusal of a chemical test happens within six years of the first violation, it is deemed a second offense. A second violation may result in harsher consequences than a first one. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; The Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5447#6478#6916#7559","text":"15#12#6#7"},
{"testId":599,"qid":26359,"description":"If the offense of OVI happens within ___ years of the first violation, it is deemed a second offense.","explanation":"A second offense for OVI or rejecting a chemical test is considered if it happens within six years after the previous violation. A second violation may result in harsher consequences than a first one. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; The Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#5447#6916#7559","text":"10#15#6#7"},
{"testId":1066,"qid":26359,"description":"If the offense of OVI happens within ___ years of the first violation, it is deemed a second offense.","explanation":"A second offense for OVI or rejecting a chemical test is considered if it happens within six years after the previous violation. A second violation may result in harsher consequences than a first one. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; The Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#5447#6916#7559","text":"10#15#6#7"},
{"testId":599,"qid":26360,"description":"OVI conviction can result in a fine of at least:","explanation":"If you are convicted of OVI, you will be fined at least $375. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":39073,"isBookmarked":0,"aid":"27888#33103#39072#39073","text":"$250#$350#$275#$375"},
{"testId":1066,"qid":26360,"description":"OVI conviction can result in a fine of at least:","explanation":"If you are convicted of OVI, you will be fined at least $375. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":39073,"isBookmarked":0,"aid":"27888#33103#39072#39073","text":"$250#$350#$275#$375"},
{"testId":599,"qid":26361,"description":"OVI conviction can result in a fine of up to:","explanation":"If convicted of OVI, you might face a fine of up to $10,500, depending on the circumstances of the incident and past arrests. For example, if this is your fourth or fifth OVI conviction in the last 10 years, you will face the maximum fine. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":39074,"isBookmarked":0,"aid":"23889#27889#39074#39075","text":"$1,000#$2,500#$10,500#$6,500"},
{"testId":1066,"qid":26361,"description":"OVI conviction can result in a fine of up to:","explanation":"If convicted of OVI, you might face a fine of up to $10,500, depending on the circumstances of the incident and past arrests. For example, if this is your fourth or fifth OVI conviction in the last 10 years, you will face the maximum fine. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":39074,"isBookmarked":0,"aid":"23889#27889#39074#39075","text":"$1,000#$2,500#$10,500#$6,500"},
{"testId":599,"qid":26362,"description":"First-time OVI offenders get at least ___ hours in jail.","explanation":null,"imageName":"","correctAnswer":13770,"isBookmarked":0,"aid":"6356#12040#12433#13770","text":"120#24#48#72"},
{"testId":1066,"qid":26362,"description":"First-time OVI offenders get at least ___ hours in jail.","explanation":null,"imageName":"","correctAnswer":13770,"isBookmarked":0,"aid":"6356#12040#12433#13770","text":"120#24#48#72"},
{"testId":599,"qid":26364,"description":"First-time OVI conviction can result in minimum of _____ license suspension.","explanation":"If you are convicted of OVI for the first time, the court will suspend your license for six months to three years, according to the Digest of Ohio Motor Vehicle Laws. This is in addition to any previous administrative suspension. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#23154#23183","text":"9 months#6 months#1 year#3 years"},
{"testId":1066,"qid":26364,"description":"First-time OVI conviction can result in minimum of _____ license suspension.","explanation":"If you are convicted of OVI for the first time, the court will suspend your license for six months to three years, according to the Digest of Ohio Motor Vehicle Laws. This is in addition to any previous administrative suspension. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#23154#23183","text":"9 months#6 months#1 year#3 years"},
{"testId":599,"qid":26365,"description":"If you're convicted of OVI twice in six years, you'll spend at least ___ days in jail.","explanation":"You will be punished to at least 10 days in prison if you are guilty of OVI for the second time in six years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6388#6389#7559","text":"10#2#3#7"},
{"testId":1066,"qid":26365,"description":"If you're convicted of OVI twice in six years, you'll spend at least ___ days in jail.","explanation":"You will be punished to at least 10 days in prison if you are guilty of OVI for the second time in six years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6388#6389#7559","text":"10#2#3#7"},
{"testId":599,"qid":26366,"description":"If you're convicted of OVI twice in six years, the court will suspend your license for at least:","explanation":"If you are convicted of OVI for the second time in six years, your license will be suspended for another one to seven years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1273#1454#11285#11341","text":"12 months#9 months#2 years#5 years"},
{"testId":1066,"qid":26366,"description":"If you're convicted of OVI twice in six years, the court will suspend your license for at least:","explanation":"If you are convicted of OVI for the second time in six years, your license will be suspended for another one to seven years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1273#1454#11285#11341","text":"12 months#9 months#2 years#5 years"},
{"testId":599,"qid":26367,"description":"If you're convicted of OVI three times in six years, you'll spend at least ___ days in jail.","explanation":"If you are convicted of OVI for the third time in six years, you will face a prison term of 30 days to one year. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6356#6389#6477","text":"10#120#3#30"},
{"testId":1066,"qid":26367,"description":"If you're convicted of OVI three times in six years, you'll spend at least ___ days in jail.","explanation":"If you are convicted of OVI for the third time in six years, you will face a prison term of 30 days to one year. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6356#6389#6477","text":"10#120#3#30"},
{"testId":599,"qid":26368,"description":"If convicted of OVI 3 times in ten years, the court may suspend your license for up to:","explanation":"If you are convicted of OVI for the third time in 10 years, your license will be suspended for another one to ten years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"29881#29895#39076#39077","text":"6 years#10 years#8 years#12 years"},
{"testId":1066,"qid":26368,"description":"If convicted of OVI 3 times in ten years, the court may suspend your license for up to:","explanation":"If you are convicted of OVI for the third time in 10 years, your license will be suspended for another one to ten years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"29881#29895#39076#39077","text":"6 years#10 years#8 years#12 years"},
{"testId":599,"qid":26369,"description":"If it's your _____聽OVI conviction in six years, the court may permanently suspend your license.","explanation":"If you are convicted of OVI for the fourth time in six years, your license will be suspended for three years to life. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":1066,"qid":26369,"description":"If it's your _____聽OVI conviction in six years, the court may permanently suspend your license.","explanation":"If you are convicted of OVI for the fourth time in six years, your license will be suspended for three years to life. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":599,"qid":26370,"description":"Which of the following OVI offense within ten years is a felony?","explanation":null,"imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":1066,"qid":26370,"description":"Which of the following OVI offense within ten years is a felony?","explanation":null,"imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":599,"qid":26371,"description":"If you are convicted of your fourth OVI conviction in six years, you will face at least ___ days in jail.","explanation":"A jail penalty of 60 days to 30 months is imposed for a fourth OVI violation in six years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6354#6355#6477#8141","text":"45#90#30#60"},
{"testId":1066,"qid":26371,"description":"If you are convicted of your fourth OVI conviction in six years, you will face at least ___ days in jail.","explanation":"A jail penalty of 60 days to 30 months is imposed for a fourth OVI violation in six years. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6354#6355#6477#8141","text":"45#90#30#60"},
{"testId":599,"qid":26373,"description":"If your BAC was ____ or higher when you committed OVI, you'll face harsher penalties.","explanation":"If you are convicted of OVI and your BAC was 0.17 percent or greater at the time of the incident, you will face heavier penalties than if you were not. For example, the minimum prison penalty for a first OVI violation with a BAC of 0.17 percent is six days rather than the customary three days. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":13913,"isBookmarked":0,"aid":"3170#4251#4653#13913","text":"0.07%#0.02%#0.01%#0.17%"},
{"testId":1066,"qid":26373,"description":"If your BAC was ____ or higher when you committed OVI, you'll face harsher penalties.","explanation":"If you are convicted of OVI and your BAC was 0.17 percent or greater at the time of the incident, you will face heavier penalties than if you were not. For example, the minimum prison penalty for a first OVI violation with a BAC of 0.17 percent is six days rather than the customary three days. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":13913,"isBookmarked":0,"aid":"3170#4251#4653#13913","text":"0.07%#0.02%#0.01%#0.17%"},
{"testId":599,"qid":26374,"description":"If your license was taken away because you had an OVI, you must pay a ________ fee to get it back.","explanation":"If your license was suspended due to OVI, you must pay a $475 reinstatement fee to have your driving privileges restored. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":13915,"isBookmarked":0,"aid":"1798#13910#13914#13915","text":"$375#$275#$175#$475"},
{"testId":1066,"qid":26374,"description":"If your license was taken away because you had an OVI, you must pay a ________ fee to get it back.","explanation":"If your license was suspended due to OVI, you must pay a $475 reinstatement fee to have your driving privileges restored. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":13915,"isBookmarked":0,"aid":"1798#13910#13914#13915","text":"$375#$275#$175#$475"},
{"testId":599,"qid":26375,"description":"If you kill someone while driving under the influence, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"28011#32618#32619#32620","text":"15 years#25 years#20 years#Life"},
{"testId":1066,"qid":26375,"description":"If you kill someone while driving under the influence, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"28011#32618#32619#32620","text":"15 years#25 years#20 years#Life"},
{"testId":599,"qid":26376,"description":"A person under the age of 21 who is found driving with a BAC of _____ or higher will be charged with Operating a Vehicle After Underage Consumption (OVUAC).","explanation":"A person under the age of 21 who is discovered driving with a BAC of 0.02 percent or above will be charged with Operating a Vehicle after Underage Consumption (OVUAC). (It should be noted that the great majority of persons will have a BAC of 0.02 percent or more after just one alcoholic drink. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance for drivers under the age of 21.) [.02 BAC for Under-21 Drivers; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4252#4652","text":"0.04%#0.02%#0.05%#0.00%"},
{"testId":1066,"qid":26376,"description":"A person under the age of 21 who is found driving with a BAC of _____ or higher will be charged with Operating a Vehicle After Underage Consumption (OVUAC).","explanation":"A person under the age of 21 who is discovered driving with a BAC of 0.02 percent or above will be charged with Operating a Vehicle after Underage Consumption (OVUAC). (It should be noted that the great majority of persons will have a BAC of 0.02 percent or more after just one alcoholic drink. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance for drivers under the age of 21.) [.02 BAC for Under-21 Drivers; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4252#4652","text":"0.04%#0.02%#0.05%#0.00%"},
{"testId":599,"qid":26377,"description":"If an underage driver is found guilty of Operating a Vehicle After Underage Consumption (OVUAC), his or her license may be suspended for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#24048#25858","text":"2 years#1 year#60 days#180 days"},
{"testId":1066,"qid":26377,"description":"If an underage driver is found guilty of Operating a Vehicle After Underage Consumption (OVUAC), his or her license may be suspended for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#24048#25858","text":"2 years#1 year#60 days#180 days"},
{"testId":599,"qid":26378,"description":"If you lend your license to a minor to buy alcohol, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#34985","text":"$500#$1,000#$2,000#$1,200"},
{"testId":599,"qid":26379,"description":"How many points will be added on your license if you're found guilty of driving without a seat belt?","explanation":"A breach of the safety belt legislation is not considered a moving infraction in Ohio. If you are convicted of failing to wear your seat belt, no points will be added to your driving record. However, you must pay a $30 fine. [Safety Belts; 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":39078,"isBookmarked":0,"aid":"6388#6390#6916#39078","text":"2#4#6#Zero"},
{"testId":599,"qid":26380,"description":"You will be required to pay a fine of not more than______聽if you are found guilty of littering.","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23888#23889#24978#24994","text":"$600#$1,000#$100#$50"},
{"testId":1066,"qid":26380,"description":"You will be required to pay a fine of not more than______聽if you are found guilty of littering.","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23888#23889#24978#24994","text":"$600#$1,000#$100#$50"},
{"testId":599,"qid":26381,"description":"If you are found guilty of littering, you face the possibility of receiving a jail sentence of not more than聽:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"739#742#1272#12361","text":"10 days#30 days#6 months#3 days"},
{"testId":1066,"qid":26381,"description":"If you are found guilty of littering, you face the possibility of receiving a jail sentence of not more than聽:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"739#742#1272#12361","text":"10 days#30 days#6 months#3 days"},
{"testId":599,"qid":26382,"description":"It is prohibited in Ohio to leave your vehicle on someone else's property for more than _______ without his or her permission.","explanation":null,"imageName":"","correctAnswer":12840,"isBookmarked":0,"aid":"12827#12828#12840#13916","text":"24 hours#48 hours#72 hours#12 hours"},
{"testId":1066,"qid":26382,"description":"It is prohibited in Ohio to leave your vehicle on someone else's property for more than _______ without his or her permission.","explanation":null,"imageName":"","correctAnswer":12840,"isBookmarked":0,"aid":"12827#12828#12840#13916","text":"24 hours#48 hours#72 hours#12 hours"},
{"testId":599,"qid":26383,"description":"A class ___ driver license in Ohio enables you to operate a motor vehicle or motor-driven cycle that is not a commercial motor vehicle.","explanation":"A class D driving license in Ohio permits you to operate any motor vehicle or motor-driven cycle that is not a commercial motor vehicle. This is the kind of license held by the great majority of Ohio drivers. Commercial driver licenses are classified into three classes: A, B, and C. (CDLs). [1 - Driver Licensing and Vehicle Registration (in state); Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":325,"isBookmarked":0,"aid":"322#323#324#325","text":"A#B#C#D"},
{"testId":599,"qid":26384,"description":"Which of the following moving traffic offenses has the LEAST number of points?","explanation":null,"imageName":"","correctAnswer":39079,"isBookmarked":0,"aid":"39079#39080#39081#39082","text":"Speeding at 75 mph on a freeway where the posted speed limit is 65 mph#Speeding at 45 mph on a state route where the posted speed limit is 35 mph#Failing to yield at a yield sign#Obstructing traffic by driving too slowly"},
{"testId":1066,"qid":26384,"description":"Which of the following moving traffic offenses has the LEAST number of points?","explanation":null,"imageName":"","correctAnswer":39079,"isBookmarked":0,"aid":"39079#39080#39081#39082","text":"Speeding at 75 mph on a freeway where the posted speed limit is 65 mph#Speeding at 45 mph on a state route where the posted speed limit is 35 mph#Failing to yield at a yield sign#Obstructing traffic by driving too slowly"},
{"testId":599,"qid":26385,"description":"Which of the following movement offenses has the HIGHEST number of penalty points?","explanation":null,"imageName":"","correctAnswer":39085,"isBookmarked":0,"aid":"11621#39083#39084#39085","text":"Reckless driving#Speeding at 95 mph on a freeway where the posted speed limit is 65 mph#Speeding at 65 mph on a state route where the posted speed limit is 35 mph#Street racing"},
{"testId":1066,"qid":26385,"description":"Which of the following movement offenses has the HIGHEST number of penalty points?","explanation":null,"imageName":"","correctAnswer":39085,"isBookmarked":0,"aid":"11621#39083#39084#39085","text":"Reckless driving#Speeding at 95 mph on a freeway where the posted speed limit is 65 mph#Speeding at 65 mph on a state route where the posted speed limit is 35 mph#Street racing"},
{"testId":599,"qid":26386,"description":"A student driver under the age of 18 must have a TIPIC for at least ______ before taking the driver skills test.","explanation":"A student driver under the age of 18 must have a TIPIC for at least six months before taking the skills exam for a driving license. [Restrictions; First Driver License Applicants Instructions; 1 - Driver Licensing & Vehicle Registration (in state); Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"739#742#1021#1272","text":"10 days#30 days#1 year#6 months"},
{"testId":599,"qid":26387,"description":"Which occupants of the driver's car must wear safety belts if the driver has a TIPIC?","explanation":"All passengers of the driver's vehicle (including the driver) must wear safety belts if the driver has a TIPIC. [Restrictions; First Driver License Applicants Instructions; 1 - Driver Licensing & Vehicle Registration (in state); Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#34838","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver and rear-seat passengers"},
{"testId":599,"qid":26388,"description":"TIPIC holders under the age of 18 are not permitted to drive after 10:00 p.m. and _______ unless accompanied by a qualified parent or adult.","explanation":"TIPIC holders under the age of 18 are not permitted to drive between 10:00 p.m. and 5:00 a.m. unless accompanied by a licensed parent or guardian. [Restrictions; First Driver License Applicants Instructions; 1 - Driver Licensing & Vehicle Registration (in state); Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":13578,"isBookmarked":0,"aid":"13578#13637#13638#13920","text":"5:00 a.m#6:00 a.m#7:00 a.m#9:00 a.m"},
{"testId":599,"qid":26389,"description":"Your vehicle liability insurance policy in Ohio must offer at least ______ in coverage for injuries to one person in any one accident.","explanation":"Your car liability insurance coverage in Ohio must contain the following: (1) at least $25,000 in coverage for one person's injuries in any one accident, (2) at least $50,000 in coverage for all people' injuries in any one accident, and (3) at least $25,000 in property damage coverage in any one accident. [\"Mandatory Insurance,\" Ohio Bureau of Motor Vehicles, http://bmv.ohio.gov/dl-mandatory-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":599,"qid":26390,"description":"If a licensed seventeen-year-old driver gets convicted of a moving offence within six months of getting his or her license, he or she must be supervised by a parent or guardian for the next ______, or until the age of 18.","explanation":"If a licensed seventeen-year-old driver is convicted of a driving infraction within six months of getting his or her license, he or she must be supervised by a parent or guardian for another six months, or until he or she reaches the age of 18. [Restrictions; First Driver License Applicants Instructions; 1 - Driver Licensing & Vehicle Registration (in state); Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#739#742#1272","text":"90 days#10 days#30 days#6 months"},
{"testId":599,"qid":26391,"description":"TIPIC holders under the age of 18 must complete ___ hours of supervised driving practice before taking the driver license skills exam.","explanation":"Before a TIPIC holder who is under 18 may take the skills exam for a driver licence, he or she must complete 50 hours of supervised driving practise of which 10 hours must be at night. His or her parent or guardian must provide a copy of the Fifty Hour Affidavit (Form BMV 5791), which must be duly filled out, signed, and notarized. [Driver Education Requirements; 1 - Driver Licensing & Vehicle Registration (in state); Digest of Ohio Motor Vehicle Laws], [Fifty Hour Affidavit, Ohio BMV, http://publicsafety.ohio.gov/links/bmv5791.pdf] .pdf]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#6477#7571#9656","text":"20#30#50#70"},
{"testId":599,"qid":26392,"description":"Usually, your Ohio driver's license will expire on your _____ birthday after the date it was issued.","explanation":"Your Ohio driving license will typically expire four years after it was issued. [1 - Driver Licensing & Vehicle Registration (in state); Digest of Ohio Motor Vehicle Laws; Renewing Your License]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":599,"qid":26393,"description":"Your Ohio driver's license will be extended to _____ years if you renew it more than 90 days in advance of its expiration.","explanation":"If you renew your Ohio driving license more than 90 days before it expires, it will expire in three years rather than the normal four. [1 - Driver Licensing & Vehicle Registration (in state); Digest of Ohio Motor Vehicle Laws; Renewing Your License]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5449#6388#6389","text":"10#5#2#3"},
{"testId":599,"qid":26394,"description":"If you accumulate _____ demerit points in  two-year, your license will be suspended.","explanation":"If you acquire twelve or more penalty points in a two-year period, your driving license will be revoked for six months, along with severe consequences. [Point Law; 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":599,"qid":26395,"description":"Your vehicle liability insurance policy in Ohio must offer at least ______ in property damage coverage in any one accident.","explanation":"Your car liability insurance coverage in Ohio must contain the following: (1) at least $25,000 in coverage for one person's injuries in any one accident, (2) at least $50,000 in coverage for all people' injuries in any one accident, and (3) at least $25,000 in property damage coverage in any one accident. [\"Mandatory Insurance,\" Ohio Bureau of Motor Vehicles, http://bmv.ohio.gov/dl-mandatory-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":605,"qid":26396,"description":"Driver's licenses in South Dakota are valid for _____ years.","explanation":"Your South Dakota driver's license will be valid for five years if you are at least 21 years old. If you are under the age of 21, your license will expire 30 days after you turn 21. [Operator's License; Driver License Types; South Dakota Driver License Handbook]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":605,"qid":26397,"description":"A driver over the age of 21 may renew his or her South Dakota driver's license up to ___ days before it expires.","explanation":"Unless you are under the age of 21, you may renew your South Dakota driver's license up to 180 days before it expires. In that instance, it is scheduled to expire 30 days after your 21st birthday, and you may only renew it during those 30 days. [Examination Procedures; Exams; Driver License Types; South Dakota Driver License Manual]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":605,"qid":26398,"description":"Which of the following can lead to the revocation of your driver's license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40578#40579#40580","text":"Any of the above#A conviction for a drug offense while driving#Driving while your license is suspended#Accumulating excess points"},
{"testId":605,"qid":26399,"description":"After your license has been suspended, you must pay a reinstatement fee of at least ____ before it can be restored.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11351#11832","text":"$25#$50#$10#$100"},
{"testId":605,"qid":26400,"description":"If you drive with a suspended license, the suspension period is:","explanation":null,"imageName":"","correctAnswer":40404,"isBookmarked":0,"aid":"14101#40404#40405#40581","text":"quadrupled#doubled#tripled#quintupled"},
{"testId":605,"qid":26401,"description":"A driver under the age of 16 with a limited minor's permit who is guilty of a traffic infringement will have his or her driving privileges revoked for ____ days for a first offense.","explanation":"If a motorist under the age of 16 with a limited minor's permit gets convicted of a traffic offence or breaches the permit's conditions, his or her driving rights are banned for 30 days on the first offense. [Restricted Minor Permit; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":605,"qid":26402,"description":"If a motorist under the age of 16 with a restricted minor's permit is convicted of a traffic offense for the second time, his or her driving rights will be revoked for ____ days or until the driver reaches the age of sixteen, whichever is longer.","explanation":"If a motorist under the age of 16 with a limited minor's permit is convicted of a traffic offense for the second time, his or her driving rights will be terminated for 90 days or until the driver reaches the age of sixteen, whichever comes first. [Restricted Minor Permit; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":605,"qid":26403,"description":"A vehicle purchased in another state must be registered in the purchaser's home state within:","explanation":"A car acquired outside of the state must be registered within 90 days after purchase. [Vehicle Registration and Titles; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24049#34746#35988","text":"30 days#90 days#3 days#7 days"},
{"testId":605,"qid":26404,"description":"South Dakota requires automobile liability insurance or other proof of financial responsibility for :","explanation":"Every registered vehicle used on South Dakota's public highways is required by law to carry vehicle liability insurance or other evidence of financial responsibility. [Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":40585,"isBookmarked":0,"aid":"40582#40583#40584#40585","text":"every vehicle owned by a person under the age of 18#every vehicle less than 10 years old#every vehicle worth more than $25,000#every registered vehicle"},
{"testId":1072,"qid":26404,"description":"South Dakota requires automobile liability insurance or other proof of financial responsibility for :","explanation":"Every registered vehicle used on South Dakota's public highways is required by law to carry vehicle liability insurance or other evidence of financial responsibility. [Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":40585,"isBookmarked":0,"aid":"40582#40583#40584#40585","text":"every vehicle owned by a person under the age of 18#every vehicle less than 10 years old#every vehicle worth more than $25,000#every registered vehicle"},
{"testId":605,"qid":26405,"description":"Which of the following can be used to demonstrate financial responsibility for a vehicle?","explanation":"South Dakota law requires you to provide evidence of financial responsibility for each registered car you possess. The most common approach to meet this requirement is to purchase liability insurance for each of your cars. A surety bond, a certificate of deposit or securities worth $50,000 placed with the state treasury, or a certificate of self-insurance are all options (if you own at least 26 vehicles). [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40586#40587#40588","text":"Any of the above#A liability insurance policy#A surety bond#A $50,000 certificate of deposit"},
{"testId":1072,"qid":26405,"description":"Which of the following can be used to demonstrate financial responsibility for a vehicle?","explanation":"South Dakota law requires you to provide evidence of financial responsibility for each registered car you possess. The most common approach to meet this requirement is to purchase liability insurance for each of your cars. A surety bond, a certificate of deposit or securities worth $50,000 placed with the state treasury, or a certificate of self-insurance are all options (if you own at least 26 vehicles). [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40586#40587#40588","text":"Any of the above#A liability insurance policy#A surety bond#A $50,000 certificate of deposit"},
{"testId":605,"qid":26406,"description":"If you are found guilty of failing to keep liability insurance or some other form of proof of financial responsibility for each vehicle you own, you face the possibility of receiving a jail sentence of up to:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":1072,"qid":26406,"description":"If you are found guilty of failing to keep liability insurance or some other form of proof of financial responsibility for each vehicle you own, you face the possibility of receiving a jail sentence of up to:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":605,"qid":26407,"description":"You could face a fine of up to_______ 聽if you are found guilty of failing to keep liability insurance or some other form of proof of financial responsibility for each vehicle you own.","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1072,"qid":26407,"description":"You could face a fine of up to_______ 聽if you are found guilty of failing to keep liability insurance or some other form of proof of financial responsibility for each vehicle you own.","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":605,"qid":26408,"description":"If you don't have liability insurance or other proof of financial responsibility for each vehicle you own, your license will be suspended for at least:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1072,"qid":26408,"description":"If you don't have liability insurance or other proof of financial responsibility for each vehicle you own, your license will be suspended for at least:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":605,"qid":26409,"description":"If you fail to maintain liability insurance or other proof of financial responsibility for each vehicle you own, you must file an SR-22 with the DPS for the next:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1072,"qid":26409,"description":"If you fail to maintain liability insurance or other proof of financial responsibility for each vehicle you own, you must file an SR-22 with the DPS for the next:","explanation":"If you are convicted of neglecting to keep evidence of financial responsibility on your vehicle, you might face a $100 fine, up to 30 days in prison, or both. Your license will be suspended for 30 days to a year. Finally, for the following three years, you will be required to submit proof of insurance (SR-22) with the DPS. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Are You Insured? ; Financial Responsibility; License Revocation and Suspension; South Dakota Driver License Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":605,"qid":26410,"description":"DWI convictions add ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1072,"qid":26410,"description":"DWI convictions add ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":605,"qid":26411,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1072,"qid":26411,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":605,"qid":26412,"description":"If you flee from a cop, __ points will be added to your license.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1072,"qid":26412,"description":"If you flee from a cop, __ points will be added to your license.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":605,"qid":26413,"description":"If convicted of drag racing, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":605,"qid":26415,"description":"__ points will be added to your driving record for improper passing or driving on the wrong side of the road.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":605,"qid":26417,"description":"If you are caught texting on a handheld cell phone while driving, you will be fined.","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24994#27902#39536","text":"$100#$50#$75#$125"},
{"testId":605,"qid":26420,"description":"When your license is suspended for the third time for having too many points, the suspension might last up to :","explanation":"If your license is suspended for the third or subsequent time for accumulating too many points, the suspension might last up to a year. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20789#23153#23154#24049","text":"9 months#6 months#1 year#90 days"},
{"testId":1072,"qid":26420,"description":"When your license is suspended for the third time for having too many points, the suspension might last up to :","explanation":"If your license is suspended for the third or subsequent time for accumulating too many points, the suspension might last up to a year. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20789#23153#23154#24049","text":"9 months#6 months#1 year#90 days"},
{"testId":605,"qid":26421,"description":"If you accumulate _____ demerit points in twelve-month period, your license will be suspended.","explanation":"Your license will be suspended if you acquire 15 points in 12 months or 22 points in 24 months. [South Dakota Point System; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#6478","text":"10#15#20#12"},
{"testId":1072,"qid":26421,"description":"If you accumulate _____ demerit points in twelve-month period, your license will be suspended.","explanation":"Your license will be suspended if you acquire 15 points in 12 months or 22 points in 24 months. [South Dakota Point System; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#5448#6478","text":"10#15#20#12"},
{"testId":605,"qid":26422,"description":"If you accumulate _____ demerit points in 24-month period, your license will be suspended.","explanation":"Your license will be suspended if you acquire 15 points in 12 months or 22 points in 24 months. [South Dakota Point System; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":12292,"isBookmarked":0,"aid":"5447#5448#11374#12292","text":"15#20#17#22"},
{"testId":1072,"qid":26422,"description":"If you accumulate _____ demerit points in 24-month period, your license will be suspended.","explanation":"Your license will be suspended if you acquire 15 points in 12 months or 22 points in 24 months. [South Dakota Point System; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":12292,"isBookmarked":0,"aid":"5447#5448#11374#12292","text":"15#20#17#22"},
{"testId":605,"qid":26423,"description":"At least,_______ points will be added to your driving record if you are found guilty of speeding.","explanation":"Unlike in many other states with point systems, a speeding conviction in South Dakota carries no points. You will, however, be penalized, and the infraction will remain on your driving record. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"2753#2754#5000#35229","text":"Two#Four#Six#Zero"},
{"testId":1072,"qid":26423,"description":"At least,_______ points will be added to your driving record if you are found guilty of speeding.","explanation":"Unlike in many other states with point systems, a speeding conviction in South Dakota carries no points. You will, however, be penalized, and the infraction will remain on your driving record. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"2753#2754#5000#35229","text":"Two#Four#Six#Zero"},
{"testId":605,"qid":26424,"description":"How many points will South Dakota assess for an out-of-state traffic violation?","explanation":"The same number of points are imposed for an out-of-state traffic conviction as if the conviction happened in South Dakota. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":40589,"isBookmarked":0,"aid":"897#17889#40589#40590","text":"None of the above#None#The usual number#Half the usual number"},
{"testId":1072,"qid":26424,"description":"How many points will South Dakota assess for an out-of-state traffic violation?","explanation":"The same number of points are imposed for an out-of-state traffic conviction as if the conviction happened in South Dakota. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":40589,"isBookmarked":0,"aid":"897#17889#40589#40590","text":"None of the above#None#The usual number#Half the usual number"},
{"testId":605,"qid":26425,"description":"You may be fined up to _____ for driving without wearing a seat belt.","explanation":null,"imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#27902#33835","text":"$25#$50#$75#$10"},
{"testId":605,"qid":26426,"description":"Which of you may face jail time if you let a friend use your driver's license to conduct fraud?","explanation":null,"imageName":"","correctAnswer":40594,"isBookmarked":0,"aid":"40591#40592#40593#40594","text":"Just you#Just your friend#Neither you nor your friend#Both you and your friend"},
{"testId":605,"qid":26427,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or food. You must wait until the alcohol has been broken down after being inebriated. [Being in Shape to Drive; South Dakota Driver License Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#35427","text":"None of the above#Coffee#A cold shower#Food"},
{"testId":605,"qid":26429,"description":"A blood alcohol content (BAC) of ______ or above indicates that you were driving while intoxicated.","explanation":"You will be charged with driving while drunk if chemical testing finds that your blood alcohol content (BAC) is 0.08 percent or above (DWI). [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":1072,"qid":26429,"description":"A blood alcohol content (BAC) of ______ or above indicates that you were driving while intoxicated.","explanation":"You will be charged with driving while drunk if chemical testing finds that your blood alcohol content (BAC) is 0.08 percent or above (DWI). [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":605,"qid":26430,"description":"What is the minimum value of blood alcohol concentration is an聽evidence that the driver under the age of 21 was driving under the influence of alcohol?","explanation":"If chemical testing finds that a motorist under the age of 21 has a blood alcohol content (BAC) of 0.02 percent or greater, he or she will be charged with driving under the influence of alcohol. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"1396#4251#4653#35229","text":"None of the above#0.02%#0.01%#Zero"},
{"testId":605,"qid":26431,"description":"By driving on public highways in South Dakota, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":605,"qid":26432,"description":"If convicted of DWI, you must file proof of insurance (SR-22) with the DPS or the next:","explanation":"If you are convicted of DWI, you must provide proof of insurance with the DPS for the following three years. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23183#26672#29894#29895","text":"3 years#5 years#7 years#10 years"},
{"testId":1072,"qid":26432,"description":"If convicted of DWI, you must file proof of insurance (SR-22) with the DPS or the next:","explanation":"If you are convicted of DWI, you must provide proof of insurance with the DPS for the following three years. Because SR-22 insurance is considered high-risk insurance, you can anticipate your insurance premiums to be higher than typical. [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23183#26672#29894#29895","text":"3 years#5 years#7 years#10 years"},
{"testId":605,"qid":26433,"description":"If a driver under the age of 21 is convicted of DUI, his or her license will be suspended for _______ for the first offense.","explanation":"A motorist under the age of 21 who is convicted of driving under the influence (i.e., breaking Zero Tolerance) will have his or her license suspended for 30 days on the first conviction. A fine will also be imposed on him or her. [Zero Tolerance; Alcohol and the Law; Driving Fitness; South Dakota Driver License Handbook]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":605,"qid":26434,"description":"If a driver under the age of 21 is found guilty of DUI, his or her license will be suspended for _______ for a second or subsequent conviction.","explanation":"A motorist under the age of 21 who is convicted of driving under the influence (i.e., breaking Zero Tolerance) will have his or her license suspended for 180 days for a second or subsequent conviction. A fine will also be imposed on him or her. [Zero Tolerance; Alcohol and the Law; Driving Fitness; South Dakota Driver License Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#12630","text":"180 days#90 days#30 days#360 days"},
{"testId":605,"qid":26435,"description":"A first DWI conviction can result in a fine of up to:","explanation":"A first DWI violation is classified as a Class 1 misdemeanor, punishable by a $1,000 fine, up to one year in prison, or both. In addition, your license will be suspended for at least 30 days. [DWI; Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#11668#11669#12002","text":"$1,000#$1,500#$2,000#$4,000"},
{"testId":1072,"qid":26435,"description":"A first DWI conviction can result in a fine of up to:","explanation":"A first DWI violation is classified as a Class 1 misdemeanor, punishable by a $1,000 fine, up to one year in prison, or both. In addition, your license will be suspended for at least 30 days. [DWI; Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"1799#11668#11669#12002","text":"$1,000#$1,500#$2,000#$4,000"},
{"testId":605,"qid":26437,"description":"A ______ or subsequent DWI charge is considered a felony.","explanation":null,"imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":605,"qid":26438,"description":"A third DWI conviction can result in a fine of up to:","explanation":"A third DWI violation is classified as a Class 6 felony, punishable by a $2,000 fine, up to two years in the state prison, or both. Your license will also be suspended for at least a year. [DWI; Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"11669#11670#12001#12002","text":"$2,000#$2,500#$3,000#$4,000"},
{"testId":1072,"qid":26438,"description":"A third DWI conviction can result in a fine of up to:","explanation":"A third DWI violation is classified as a Class 6 felony, punishable by a $2,000 fine, up to two years in the state prison, or both. Your license will also be suspended for at least a year. [DWI; Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":11669,"isBookmarked":0,"aid":"11669#11670#12001#12002","text":"$2,000#$2,500#$3,000#$4,000"},
{"testId":605,"qid":26439,"description":"A third DWI conviction can result in license suspension for at least:","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":1072,"qid":26439,"description":"A third DWI conviction can result in license suspension for at least:","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":605,"qid":26442,"description":"Driving under the influence can be charged if your driving has been dangerously impacted by :","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#11901#12638#13431","text":"Any of the above#marijuana#over-the-counter medications#prescription medications"},
{"testId":605,"qid":26443,"description":"If you're convicted of driving with a revoked license for a third DWI, you'll spend at least ______聽in jail.","explanation":null,"imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#12360#12361#12685","text":"10 days#7 days#3 days#5 days"},
{"testId":605,"qid":26444,"description":"If you are found guilty of fleeing the scene of an accident without stopping (often known as \"hit and run\"), you might face a jail sentence of up to ______even if no one was hurt in the collision.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1072,"qid":26444,"description":"If you are found guilty of fleeing the scene of an accident without stopping (often known as \"hit and run\"), you might face a jail sentence of up to ______even if no one was hurt in the collision.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":605,"qid":26445,"description":"First and second DWI offenses are classified as _________ for adult drivers.","explanation":null,"imageName":"","correctAnswer":40596,"isBookmarked":0,"aid":"40596#40597#40598#40599","text":"Class 1 misdemeanors#Class 2 misdemeanors#Class 3 misdemeanors#felonies"},
{"testId":609,"qid":26446,"description":"A driver under the age of 21 who is found guilty of carrying or consuming alcohol may be fined _____ for the first offense.","explanation":"If a motorist under the age of 21 is discovered to be in possession of or using alcohol, he or she must engage in the Teen Alcohol Safety Program. If he or she does not successfully complete the program, he or she will be fined $300 and his or her license will be suspended for 90 days for a first violation. [How Can You Lose Your Driver's License Or Privilege? Vermont Driver's Manual; Chapter 2: How to Lose Your License]","imageName":"","correctAnswer":11833,"isBookmarked":0,"aid":"11349#11832#11833#12358","text":"$50#$100#$300#$200"},
{"testId":609,"qid":26448,"description":"You've gone into the other lane on a two-lane road to pass another vehicle. Before any oncoming vehicle can come within ______ of you, you must return to your previous lane.","explanation":null,"imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":1076,"qid":26448,"description":"You've gone into the other lane on a two-lane road to pass another vehicle. Before any oncoming vehicle can come within ______ of you, you must return to your previous lane.","explanation":null,"imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"399#400#401#1296","text":"200 feet#300 feet#400 feet#500 feet"},
{"testId":609,"qid":26450,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that resulted in just property damage and no injuries, you could be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1076,"qid":26450,"description":"If you are found guilty of fleeing the scene of an accident (also known as \"hit and run\") that resulted in just property damage and no injuries, you could be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":609,"qid":26451,"description":"It is possible to be penalized as much as _______ if you are found guilty of lying on your driver's license application.","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#33102","text":"$500#$1,000#$2,000#$300"},
{"testId":609,"qid":26452,"description":"Your driving privileges will be revoked for ____ days if you are found guilty of providing false information on your application for a driver's license.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":609,"qid":26454,"description":"If convicted of DUI for the first time, your license will be revoked for at least____ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#8141#11784","text":"90#30#60#14"},
{"testId":1076,"qid":26454,"description":"If convicted of DUI for the first time, your license will be revoked for at least____ days.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#8141#11784","text":"90#30#60#14"},
{"testId":609,"qid":26455,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":29301,"isBookmarked":0,"aid":"23887#23889#29301#41055","text":"$500#$1,000#$750#$1,250"},
{"testId":1076,"qid":26455,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":29301,"isBookmarked":0,"aid":"23887#23889#29301#41055","text":"$500#$1,000#$750#$1,250"},
{"testId":609,"qid":26456,"description":"A Vermont license becomes invalid ___ days after it expires.","explanation":null,"imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5446#6477#7559#11784","text":"10#30#7#14"},
{"testId":1076,"qid":26456,"description":"A Vermont license becomes invalid ___ days after it expires.","explanation":null,"imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5446#6477#7559#11784","text":"10#30#7#14"},
{"testId":609,"qid":26457,"description":"To get a new license if your old one has expired for more than ______ years, you must take a new exam.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":26457,"description":"To get a new license if your old one has expired for more than ______ years, you must take a new exam.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":609,"qid":26458,"description":"If your name or address changes, you must notify the DMV within ___ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6389#6477#11784","text":"10#3#30#14"},
{"testId":1076,"qid":26458,"description":"If your name or address changes, you must notify the DMV within ___ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6389#6477#11784","text":"10#3#30#14"},
{"testId":609,"qid":26459,"description":"Which of the following can result in a DUI conviction?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#9803#29384","text":"Any of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":1076,"qid":26459,"description":"Which of the following can result in a DUI conviction?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#9803#29384","text":"Any of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":609,"qid":26460,"description":"If a learner's permit or junior driver's license holder is caught texting and driving, his permit or license will be revoked for ___ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6477#7559#8141#11784","text":"30#7#60#14"},
{"testId":609,"qid":26461,"description":"If your blood alcohol concentration (BAC) is ___ or higher, you are considered impaired or under the influence in Vermont.聽:","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":1076,"qid":26461,"description":"If your blood alcohol concentration (BAC) is ___ or higher, you are considered impaired or under the influence in Vermont.聽:","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#11768","text":"0.08%#0.07%#0.05%#0.10%"},
{"testId":609,"qid":26462,"description":"A driver under the age of 21 can be charged with DUI if his or her blood alcohol content (BAC) is higher than____","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4652#4653","text":"0.04%#0.02%#0.00%#0.01%"},
{"testId":1076,"qid":26462,"description":"A driver under the age of 21 can be charged with DUI if his or her blood alcohol content (BAC) is higher than____","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4652#4653","text":"0.04%#0.02%#0.00%#0.01%"},
{"testId":609,"qid":26463,"description":"Just _____ alcoholic drink(s) may impair driving.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":609,"qid":26464,"description":"Which of the following are alcohol's effects?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25449#25846#25848","text":"All of the above#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":1076,"qid":26464,"description":"Which of the following are alcohol's effects?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25449#25846#25848","text":"All of the above#Impaired vision#Slower reactions#Dulled judgment"},
{"testId":609,"qid":26465,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":1076,"qid":26465,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":609,"qid":26466,"description":"In accordance with Vermont's Implied Assent Law, driving on Vermont's public highways constitutes implied consent to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1076,"qid":26466,"description":"In accordance with Vermont's Implied Assent Law, driving on Vermont's public highways constitutes implied consent to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":609,"qid":26468,"description":"Alternatives to drinking and driving is ______","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#41056","text":"All of the above#taking a taxi#riding with a designated driver#spending the night where you plan to drink"},
{"testId":1076,"qid":26468,"description":"Alternatives to drinking and driving is ______","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#41056","text":"All of the above#taking a taxi#riding with a designated driver#spending the night where you plan to drink"},
{"testId":609,"qid":26469,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1076,"qid":26469,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":609,"qid":26470,"description":"In Vermont, you can't use a handheld cell phone to _____ while you're driving.","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#32086#41057#41058","text":"do any of the above#send text messages#read text messages#receive phone calls"},
{"testId":1076,"qid":26470,"description":"In Vermont, you can't use a handheld cell phone to _____ while you're driving.","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#32086#41057#41058","text":"do any of the above#send text messages#read text messages#receive phone calls"},
{"testId":609,"qid":26472,"description":"__ points will be added to your driving record if you are convicted of texting while driving.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":1076,"qid":26472,"description":"__ points will be added to your driving record if you are convicted of texting while driving.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#7559#11342","text":"5#3#7#9"},
{"testId":609,"qid":26474,"description":"If you are found guilty of running a red light, you will receive __ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":609,"qid":26475,"description":"If you are found guilty of running a stop sign, you will receive __ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":609,"qid":26476,"description":"If you disobey a police officer, ___ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1076,"qid":26476,"description":"If you disobey a police officer, ___ points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":609,"qid":26477,"description":"__ points will be added to your driving record for failing to yield to an emergency vehicle.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1076,"qid":26477,"description":"__ points will be added to your driving record for failing to yield to an emergency vehicle.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":609,"qid":26478,"description":"__ points will be added to your driving record for failing to yield to a pedestrian in a crosswalk.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1076,"qid":26478,"description":"__ points will be added to your driving record for failing to yield to a pedestrian in a crosswalk.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":609,"qid":26479,"description":"How many points will be added to your driving record if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1076,"qid":26479,"description":"How many points will be added to your driving record if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":609,"qid":26480,"description":"If you accumulate _____ or more demerit points in  two-year, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":1076,"qid":26480,"description":"If you accumulate _____ or more demerit points in  two-year, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":609,"qid":26481,"description":"If a regular driver's license holder is caught sending email while driving, _____ points will be added to his or her driving record.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#6389#6390","text":"10#5#3#4"},
{"testId":1076,"qid":26481,"description":"If a regular driver's license holder is caught sending email while driving, _____ points will be added to his or her driving record.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#6389#6390","text":"10#5#3#4"},
{"testId":609,"qid":26482,"description":"Who in Vermont needs to have liability insurance or something similar?","explanation":null,"imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"10424#41059#41060#41061","text":"All drivers#Only drivers under 21 years of age#Only drivers under 18 years of age#Only drivers of vehicles that are less than ten years old"},
{"testId":609,"qid":26483,"description":"Your vehicle liability insurance policy in Vermont must offer at least ______ in coverage for the death or injury of one person in any one accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12047#12048","text":"$50,000#$10,000#$15,000#$25,000"},
{"testId":609,"qid":26484,"description":"Your vehicle liability insurance policy in Vermont must offer at least ______ in coverage for the deaths or injuries of two or more people in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12047#12048#12194","text":"$50,000#$15,000#$25,000#$40,000"},
{"testId":609,"qid":26485,"description":"In Vermont, your vehicle liability insurance policy must offer at least ______ in property damage coverage in any one accident.","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":609,"qid":26486,"description":"Driving without vehicle liability insurance results in _____ points being added to your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":26486,"description":"Driving without vehicle liability insurance results in _____ points being added to your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":609,"qid":26487,"description":"If you have vehicle liability insurance but are unable to present your insurance card to a police officer upon request, you will have ___ additional days to provide the insurance card.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5447#6389#7559","text":"10#15#3#7"},
{"testId":1076,"qid":26487,"description":"If you have vehicle liability insurance but are unable to present your insurance card to a police officer upon request, you will have ___ additional days to provide the insurance card.","explanation":null,"imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5447#6389#7559","text":"10#15#3#7"},
{"testId":609,"qid":26489,"description":"While you have a learner's permit, you may only drive under the supervision of :","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#41143#41144#41145","text":"Any of the above#a licensed driver who is at least 25 years old#a licensed driving instructor#your licensed parent or guardian"},
{"testId":609,"qid":26492,"description":"You will be unable to drive for work for at least ______ if you are under the age of 18 and have obtained a junior driver's license.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":609,"qid":26493,"description":"For the first ____ months after receiving a junior driver's license, a motorist may not drive unaccompanied with passengers in the car.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6389#6390#6916#11342","text":"3#4#6#9"},
{"testId":609,"qid":26494,"description":"If you are involved in a significant traffic collision, you must file a crash report with the DMV as soon as possible.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":20755,"isBookmarked":0,"aid":"20754#20755#41063#41064","text":"48 hours#72 hours#four days#one week"},
{"testId":609,"qid":26495,"description":"To receive a normal Vermont driver's license, you must be at least ___ years old.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":610,"qid":26496,"description":"If you are found guilty of using a portable cell phone to send text messages in Virginia, you will be fined ____ for the first offense.","explanation":null,"imageName":"","correctAnswer":11350,"isBookmarked":0,"aid":"11349#11350#12395#12398","text":"$50#$125#$250#$75"},
{"testId":610,"qid":26497,"description":"First-time offenders will be fined ______聽for transporting a child without a safety belt or child restraint.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11832#12398","text":"$25#$50#$100#$75"},
{"testId":610,"qid":26498,"description":"For a second or subsequent offense, you can be fined up to ___ for transporting an unrestrained child.","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11349#11832#12395","text":"$500#$50#$100#$250"},
{"testId":610,"qid":26499,"description":"Which of the following convictions will result in the revocation of your license?","explanation":"There are several infractions that will result in the termination of your driving privileges if you are convicted. Driving under the influence (DUI) of alcohol or drugs, fleeing the scene of an accident without stopping (hit and run), giving a false statement to the DMV, and a drug crime whether or not a motor vehicle was involved are all examples. [Virginia Driver's Manual, Section 5: Penalties; Conviction-Related Suspensions and Revocations]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#24985#41277#41278","text":"Any of the above#Driving under the influence (DUI)#Making a false statement to the DMV#A drug offense"},
{"testId":1077,"qid":26499,"description":"Which of the following convictions will result in the revocation of your license?","explanation":"There are several infractions that will result in the termination of your driving privileges if you are convicted. Driving under the influence (DUI) of alcohol or drugs, fleeing the scene of an accident without stopping (hit and run), giving a false statement to the DMV, and a drug crime whether or not a motor vehicle was involved are all examples. [Virginia Driver's Manual, Section 5: Penalties; Conviction-Related Suspensions and Revocations]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#24985#41277#41278","text":"Any of the above#Driving under the influence (DUI)#Making a false statement to the DMV#A drug offense"},
{"testId":610,"qid":26500,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":"A speeding citation in Virginia generally results in a fine rather than the loss of driving privileges. However, depending on how fast you were driving at the time, each speeding conviction will add three, four, or six demerit points to your driving record. Your license may be suspended if you acquire enough points. [Virginia Driver's Manual, Section 5: Penalties; Conviction-Related Suspensions and Revocations], [\"Moving Violations and Points Assessments\" (DMV 115), Virginia Department of Motor Vehicles, July 1, 2015]","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#33098#41279#41280","text":"Speeding#Fleeing from the police#Involuntary vehicular manslaughter#A felony involving the use of a motor vehicle"},
{"testId":1077,"qid":26500,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":"A speeding citation in Virginia generally results in a fine rather than the loss of driving privileges. However, depending on how fast you were driving at the time, each speeding conviction will add three, four, or six demerit points to your driving record. Your license may be suspended if you acquire enough points. [Virginia Driver's Manual, Section 5: Penalties; Conviction-Related Suspensions and Revocations], [\"Moving Violations and Points Assessments\" (DMV 115), Virginia Department of Motor Vehicles, July 1, 2015]","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#33098#41279#41280","text":"Speeding#Fleeing from the police#Involuntary vehicular manslaughter#A felony involving the use of a motor vehicle"},
{"testId":610,"qid":26501,"description":"If you're convicted of a traffic offense and don't pay your fines and costs within ____, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"742#12360#12361#18239","text":"30 days#7 days#3 days#14 days"},
{"testId":1077,"qid":26501,"description":"If you're convicted of a traffic offense and don't pay your fines and costs within ____, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"742#12360#12361#18239","text":"30 days#7 days#3 days#14 days"},
{"testId":610,"qid":26502,"description":"How many demerit points will be added to your record聽for a traffic violation?","explanation":"Each traffic infringement in Virginia receives three, four, or six demerit points, depending on the severity of the crime. [Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#6389#6390#6916","text":"Any of the above#3#4#6"},
{"testId":1077,"qid":26502,"description":"How many demerit points will be added to your record聽for a traffic violation?","explanation":"Each traffic infringement in Virginia receives three, four, or six demerit points, depending on the severity of the crime. [Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#6389#6390#6916","text":"Any of the above#3#4#6"},
{"testId":610,"qid":26503,"description":"If a motorist under the age of ___ gets convicted of one traffic offence that results in demerit points being added to his or her driving record, he or she must attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 is convicted of a traffic offence that results in demerit points being added to his or her driving record, he or she must attend a driver improvement clinic. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#11359#11360#11374","text":"20#18#21#17"},
{"testId":1077,"qid":26503,"description":"If a motorist under the age of ___ gets convicted of one traffic offence that results in demerit points being added to his or her driving record, he or she must attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 is convicted of a traffic offence that results in demerit points being added to his or her driving record, he or she must attend a driver improvement clinic. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#11359#11360#11374","text":"20#18#21#17"},
{"testId":610,"qid":26506,"description":"If an underage driver is convicted of a third traffic offence, his or her license will be suspended for at least _______.","explanation":"If a motorist under the age of 18 is convicted for the third time of a traffic offense or a violation of Virginia's safety belt or child restraint rules, his or her driving rights will be removed for one year or until the driver reaches the age of 18, whichever is longer. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":1077,"qid":26506,"description":"If an underage driver is convicted of a third traffic offence, his or her license will be suspended for at least _______.","explanation":"If a motorist under the age of 18 is convicted for the third time of a traffic offense or a violation of Virginia's safety belt or child restraint rules, his or her driving rights will be removed for one year or until the driver reaches the age of 18, whichever is longer. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":610,"qid":26507,"description":"If a driver under the age of 19 and over the age of 18  is convicted for the first time of a demerit point infraction,:","explanation":"If a motorist under the age of 19 is convicted of a pointable traffic infraction or a violation of Virginia's seat belt or child restraint rules for the first time, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":41281,"isBookmarked":0,"aid":"24989#28810#34978#41281","text":"none of the above will occur#his or her license will be suspended for 30 days#his or her license will be suspended for 90 days#he or she will have to complete a driver improvement clinic"},
{"testId":1077,"qid":26507,"description":"If a driver under the age of 19 and over the age of 18  is convicted for the first time of a demerit point infraction,:","explanation":"If a motorist under the age of 19 is convicted of a pointable traffic infraction or a violation of Virginia's seat belt or child restraint rules for the first time, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":41281,"isBookmarked":0,"aid":"24989#28810#34978#41281","text":"none of the above will occur#his or her license will be suspended for 30 days#his or her license will be suspended for 90 days#he or she will have to complete a driver improvement clinic"},
{"testId":610,"qid":26508,"description":"If a motorist over the age of 18 accumulates ___ demerit points in a year period, he or she will be compelled to attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 earns 12 demerit points in 12 months or 18 demerit points in 24 months, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1077,"qid":26508,"description":"If a motorist over the age of 18 accumulates ___ demerit points in a year period, he or she will be compelled to attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 earns 12 demerit points in 12 months or 18 demerit points in 24 months, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":610,"qid":26509,"description":"If a motorist over the age of 18 accumulates ___ demerit points in the span of 24 months, he or she will be compelled to attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 earns 12 demerit points in 12 months or 18 demerit points in 24 months, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":1077,"qid":26509,"description":"If a motorist over the age of 18 accumulates ___ demerit points in the span of 24 months, he or she will be compelled to attend a driver improvement clinic.","explanation":"If a motorist under the age of 18 earns 12 demerit points in 12 months or 18 demerit points in 24 months, he or she must attend a driver improvement clinic. [Age 18 or 19; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":610,"qid":26511,"description":"If a motorist over the age of 18 obtains ___ demerit points in the next 24 months, his or her license will be suspended for 90 days.","explanation":"If a motorist under the age of 18 earns 18 demerit points in a 12-month period or 24 demerit points in a 24-month period, his or her license will be suspended for 90 days. He or she will also have to attend a driving improvement clinic. [Age 18 or Older; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"5448#11359#11361#12040","text":"20#18#16#24"},
{"testId":1077,"qid":26511,"description":"If a motorist over the age of 18 obtains ___ demerit points in the next 24 months, his or her license will be suspended for 90 days.","explanation":"If a motorist under the age of 18 earns 18 demerit points in a 12-month period or 24 demerit points in a 24-month period, his or her license will be suspended for 90 days. He or she will also have to attend a driving improvement clinic. [Age 18 or Older; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":12040,"isBookmarked":0,"aid":"5448#11359#11361#12040","text":"20#18#16#24"},
{"testId":610,"qid":26512,"description":"When you register your vehicle, you must pay a _____ uninsured motor vehicle fee if it does not have liability insurance coverage.","explanation":"In Virginia, you are not obliged by law to obtain liability insurance on your vehicle. However, if your car does not have liability insurance coverage when you register it, you must pay a $500 uninsured motor vehicle charge. By paying this cost, you will be able to drive the car in Virginia for one year. Please keep in mind that this is NOT insurance coverage. If your car is uninsured, you may be held personally accountable for damages in the case of an accident. [Insurance Monitoring Program; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":1077,"qid":26512,"description":"When you register your vehicle, you must pay a _____ uninsured motor vehicle fee if it does not have liability insurance coverage.","explanation":"In Virginia, you are not obliged by law to obtain liability insurance on your vehicle. However, if your car does not have liability insurance coverage when you register it, you must pay a $500 uninsured motor vehicle charge. By paying this cost, you will be able to drive the car in Virginia for one year. Please keep in mind that this is NOT insurance coverage. If your car is uninsured, you may be held personally accountable for damages in the case of an accident. [Insurance Monitoring Program; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":610,"qid":26513,"description":"Your license will be suspended if you fail to make child support payments totaling _____ despite being compelled to do so.","explanation":"If you are 90 days or more behind on your child support payments, the Division of Child Support Enforcement will request the DMV to suspend your driving privileges. [Suspensions for Failure to Comply with Child Support Requirements; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#12001","text":"$500#$1,000#$5,000#$3,000"},
{"testId":1077,"qid":26513,"description":"Your license will be suspended if you fail to make child support payments totaling _____ despite being compelled to do so.","explanation":"If you are 90 days or more behind on your child support payments, the Division of Child Support Enforcement will request the DMV to suspend your driving privileges. [Suspensions for Failure to Comply with Child Support Requirements; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#12001","text":"$500#$1,000#$5,000#$3,000"},
{"testId":610,"qid":26514,"description":"In accordance with Virginia's Implied Assent Law, driving on Virginia's public highways constitutes implied consent to :","explanation":"By driving a motor vehicle in Virginia, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Virginia's Implied Consent Law. [The Law and Alcohol; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1077,"qid":26514,"description":"In accordance with Virginia's Implied Assent Law, driving on Virginia's public highways constitutes implied consent to :","explanation":"By driving a motor vehicle in Virginia, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Virginia's Implied Consent Law. [The Law and Alcohol; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":610,"qid":26515,"description":"If you are involved in an accident and a law enforcement officer believes you are inebriated, the officer has the authority to arrest you for DUI without a warrant within ___ hours of the accident.","explanation":"If you are in an accident and a law enforcement officer believes you are inebriated, the officer may arrest you for DUI without a warrant within three hours of the accident. [The Law and Alcohol; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6389#6478#6916#12040","text":"3#12#6#24"},
{"testId":1077,"qid":26515,"description":"If you are involved in an accident and a law enforcement officer believes you are inebriated, the officer has the authority to arrest you for DUI without a warrant within ___ hours of the accident.","explanation":"If you are in an accident and a law enforcement officer believes you are inebriated, the officer may arrest you for DUI without a warrant within three hours of the accident. [The Law and Alcohol; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6389#6478#6916#12040","text":"3#12#6#24"},
{"testId":610,"qid":26516,"description":"If you are caught driving with a blood alcohol level (BAC) of _____ or above, your license will be suspended automatically.","explanation":"If you are caught driving with a blood alcohol level (BAC) of 0.08 percent or greater, you will be charged with DUI and your license will be revoked immediately. It is important to note that this is an administrative suspension, not a criminal consequence. This suspension will take effect even before your trial begins. [Administrative License Suspension; Alcohol and the Law; Penalties in Section 5; Virginia Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4253","text":"0.08%#0.07%#0.05%#0.03%"},
{"testId":1077,"qid":26516,"description":"If you are caught driving with a blood alcohol level (BAC) of _____ or above, your license will be suspended automatically.","explanation":"If you are caught driving with a blood alcohol level (BAC) of 0.08 percent or greater, you will be charged with DUI and your license will be revoked immediately. It is important to note that this is an administrative suspension, not a criminal consequence. This suspension will take effect even before your trial begins. [Administrative License Suspension; Alcohol and the Law; Penalties in Section 5; Virginia Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4253","text":"0.08%#0.07%#0.05%#0.03%"},
{"testId":610,"qid":26517,"description":"If you are arrested for DUI by a law enforcement officer, your driving privileges will be automatically revoked for ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":1077,"qid":26517,"description":"If you are arrested for DUI by a law enforcement officer, your driving privileges will be automatically revoked for ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":610,"qid":26519,"description":"If you are arrested for DUI for the third time and it is your third offense, your driving privileges will be automatically revoked:","explanation":null,"imageName":"","correctAnswer":41283,"isBookmarked":0,"aid":"25859#25860#41282#41283","text":"for 90 days#for 180 days#for 120 days#until your trial date"},
{"testId":1077,"qid":26519,"description":"If you are arrested for DUI for the third time and it is your third offense, your driving privileges will be automatically revoked:","explanation":null,"imageName":"","correctAnswer":41283,"isBookmarked":0,"aid":"25859#25860#41282#41283","text":"for 90 days#for 180 days#for 120 days#until your trial date"},
{"testId":610,"qid":26520,"description":"For a first offense, if you refuse a breath test, your license will be revoked for a period of聽:","explanation":null,"imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":1077,"qid":26520,"description":"For a first offense, if you refuse a breath test, your license will be revoked for a period of聽:","explanation":null,"imageName":"","correctAnswer":12360,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":610,"qid":26522,"description":"If you refuse a breath test for the third time, your driving privileges will be suspended automatically:","explanation":null,"imageName":"","correctAnswer":41283,"isBookmarked":0,"aid":"25859#25860#41282#41283","text":"for 90 days#for 180 days#for 120 days#until your trial date"},
{"testId":1077,"qid":26522,"description":"If you refuse a breath test for the third time, your driving privileges will be suspended automatically:","explanation":null,"imageName":"","correctAnswer":41283,"isBookmarked":0,"aid":"25859#25860#41282#41283","text":"for 90 days#for 180 days#for 120 days#until your trial date"},
{"testId":610,"qid":26523,"description":"A motorist in Virginia may carry an open container of alcohol:","explanation":null,"imageName":"","correctAnswer":41287,"isBookmarked":0,"aid":"41284#41285#41286#41287","text":"under the driver's seat#on the rear-seat floorboard#in an unlocked glove compartment#in none of the above places"},
{"testId":610,"qid":26524,"description":"If you're convicted of DUI with a child passenger, you'll spend at least ______聽聽in jail.","explanation":null,"imageName":"","correctAnswer":40739,"isBookmarked":0,"aid":"34746#35988#40739#41288","text":"3 days#7 days#5 days#9 days"},
{"testId":1077,"qid":26524,"description":"If you're convicted of DUI with a child passenger, you'll spend at least ______聽聽in jail.","explanation":null,"imageName":"","correctAnswer":40739,"isBookmarked":0,"aid":"34746#35988#40739#41288","text":"3 days#7 days#5 days#9 days"},
{"testId":610,"qid":26526,"description":"If you're convicted of DUI with a child passenger in the car, you will face an additional fine of at least:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#33102","text":"$500#$100#$50#$300"},
{"testId":1077,"qid":26526,"description":"If you're convicted of DUI with a child passenger in the car, you will face an additional fine of at least:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#33102","text":"$500#$100#$50#$300"},
{"testId":610,"qid":26527,"description":"If you're convicted of DUI with a child passenger in the car, you will incur an extra penalty of _______ hours of community service.","explanation":null,"imageName":"","correctAnswer":40925,"isBookmarked":0,"aid":"11482#11695#13917#40925","text":"10 hours#40 hours#60 hours#80 hours"},
{"testId":1077,"qid":26527,"description":"If you're convicted of DUI with a child passenger in the car, you will incur an extra penalty of _______ hours of community service.","explanation":null,"imageName":"","correctAnswer":40925,"isBookmarked":0,"aid":"11482#11695#13917#40925","text":"10 hours#40 hours#60 hours#80 hours"},
{"testId":610,"qid":26531,"description":"If you're convicted of DUI or causing an accident due to DUI, you may owe up to ________ of聽restitution to local first responders.","explanation":"Any local government in Virginia may establish an ordinance requiring a motorist convicted of DUI or causing an accident as a consequence of DUI to pay up to $1,000 in compensation to local first responders (police, fire, ambulance, and so on) who responded to the event. This punishment is in addition to all other administrative and criminal sanctions for DUI or DUI-related accidents. [Restitution; Alcohol and the Law; Penalties; Virginia Driver's Handbook]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11670#12395","text":"$500#$1,000#$2,500#$250"},
{"testId":1077,"qid":26531,"description":"If you're convicted of DUI or causing an accident due to DUI, you may owe up to ________ of聽restitution to local first responders.","explanation":"Any local government in Virginia may establish an ordinance requiring a motorist convicted of DUI or causing an accident as a consequence of DUI to pay up to $1,000 in compensation to local first responders (police, fire, ambulance, and so on) who responded to the event. This punishment is in addition to all other administrative and criminal sanctions for DUI or DUI-related accidents. [Restitution; Alcohol and the Law; Penalties; Virginia Driver's Handbook]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11670#12395","text":"$500#$1,000#$2,500#$250"},
{"testId":610,"qid":26532,"description":"It is illegal in Virginia for a person under the age of 21 to drive with a blood alcohol content (BAC) of _____ or higher.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [Alcohol-Related Violations and Penalties Involving Minors; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4652#4653","text":"0.04%#0.02%#0.00%#0.01%"},
{"testId":1077,"qid":26532,"description":"It is illegal in Virginia for a person under the age of 21 to drive with a blood alcohol content (BAC) of _____ or higher.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [Alcohol-Related Violations and Penalties Involving Minors; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4652#4653","text":"0.04%#0.02%#0.00%#0.01%"},
{"testId":610,"qid":26533,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.04 percent, his or her license will be suspended for:","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1077,"qid":26533,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.04 percent, his or her license will be suspended for:","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":610,"qid":26534,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.04 percent, he or she may face a punishment of at least:","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1077,"qid":26534,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.04 percent, he or she may face a punishment of at least:","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":610,"qid":26535,"description":"If a driver under the age of 21 is guilty of driving with a BAC of 0.04 percent, he or she may be sentenced to at least _______ hours of community service.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015], [Alcohol Related Violations and Penalties Involving Persons Under Age 21; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual].","imageName":"","correctAnswer":12852,"isBookmarked":0,"aid":"11482#11695#12851#12852","text":"10 hours#40 hours#25 hours#50 hours"},
{"testId":1077,"qid":26535,"description":"If a driver under the age of 21 is guilty of driving with a BAC of 0.04 percent, he or she may be sentenced to at least _______ hours of community service.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or above is banned in Virginia for anybody under the age of 21. A person under the age of 21 who is guilty of driving with a BAC of at least 0.02 percent but less than 0.08 percent will have his or her license suspended for one year. This suspension time is in addition to the person's first administrative suspension when charged with this violation. He or she will also get six penalty points on his or her driving record, as well as a $500 fine or 50 hours of community service. If a person under the age of 21 is convicted of driving with a BAC of 0.08 percent or above, he or she faces the same DUI consequences as an adult. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015], [Alcohol Related Violations and Penalties Involving Persons Under Age 21; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual].","imageName":"","correctAnswer":12852,"isBookmarked":0,"aid":"11482#11695#12851#12852","text":"10 hours#40 hours#25 hours#50 hours"},
{"testId":610,"qid":26536,"description":"If a driver under the age of 21 is convicted of driving with a BAC of _____ or higher, he or she will face the same DUI penalties as an adult driver.","explanation":"If an adult motorist has a blood alcohol concentration (BAC) of 0.08 percent or above while operating a vehicle, that person might be found guilty of DUI. If a motorist under 21 is found guilty of operating a vehicle with a blood alcohol content (BAC) of 0.08 percent or more, they will be subject to the same DUI consequences as an adult driver. [Virginia Driver's Manual, Alcohol and the Law, Section 5: Penalties, Alcohol Related Violations and Penalties Involving Persons Under Age 21]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4252#11768#11770","text":"0.08%#0.05%#0.10%#0.15%"},
{"testId":1077,"qid":26536,"description":"If a driver under the age of 21 is convicted of driving with a BAC of _____ or higher, he or she will face the same DUI penalties as an adult driver.","explanation":"If an adult motorist has a blood alcohol concentration (BAC) of 0.08 percent or above while operating a vehicle, that person might be found guilty of DUI. If a motorist under 21 is found guilty of operating a vehicle with a blood alcohol content (BAC) of 0.08 percent or more, they will be subject to the same DUI consequences as an adult driver. [Virginia Driver's Manual, Alcohol and the Law, Section 5: Penalties, Alcohol Related Violations and Penalties Involving Persons Under Age 21]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4252#11768#11770","text":"0.08%#0.05%#0.10%#0.15%"},
{"testId":610,"qid":26537,"description":"If you are found guilty of selling or giving alcohol to someone who is under the age of 21, your driver's license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1077,"qid":26537,"description":"If you are found guilty of selling or giving alcohol to someone who is under the age of 21, your driver's license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":610,"qid":26538,"description":"A person under the age of 21 who is convicted of attempting to acquire alcohol with a fraudulent ID may face a fine of at least:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1077,"qid":26538,"description":"A person under the age of 21 who is convicted of attempting to acquire alcohol with a fraudulent ID may face a fine of at least:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":610,"qid":26539,"description":"If a motorist between the ages of 18 and 20 is convicted of attempting to purchase alcohol with a fake ID, his or her license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":1077,"qid":26539,"description":"If a motorist between the ages of 18 and 20 is convicted of attempting to purchase alcohol with a fake ID, his or her license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":610,"qid":26540,"description":"If a motorist between the ages of 18 and 20 is convicted of attempting to purchase alcohol with a fake ID, his or her license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":1077,"qid":26540,"description":"If a motorist between the ages of 18 and 20 is convicted of attempting to purchase alcohol with a fake ID, his or her license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":610,"qid":26541,"description":"Your driving must be supervised by a licensed driver who ________ in Virginia while you possess a learner's permit.","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#40068#41289#41290","text":"meets all of the above requirements#is seated next to you#is at least 21 years old if he or she is unrelated to you#holds a valid license"},
{"testId":610,"qid":26542,"description":"You must notify the DMV of any changes to your postal address within:","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#35988#35989","text":"30 days#60 days#7 days#14 days"},
{"testId":1077,"qid":26542,"description":"You must notify the DMV of any changes to your postal address within:","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#35988#35989","text":"30 days#60 days#7 days#14 days"},
{"testId":610,"qid":26543,"description":"Driving drunk increases your chances of getting in an accident by ___ times that of driving sober.","explanation":"Alcohol has a negative impact on your judgement and driving skills. According to the Virginia Driver's Manual, driving while intoxicated increases accident risk by seven times compared to sober driving. About one-third of all road fatalities in the US are attributed to drunk driving, according to the Centers for Disease Control and Prevention. [\"Sobering Facts: Drunk Driving State Fact Sheets\"; Centers for Disease Control and Prevention; https://www.cdc.gov/motorvehiclesafety/impaired driving/states; Drinking and Driving; Section 3: Safe Driving; Virginia Driver's Manual] .html]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1077,"qid":26543,"description":"Driving drunk increases your chances of getting in an accident by ___ times that of driving sober.","explanation":"Alcohol has a negative impact on your judgement and driving skills. According to the Virginia Driver's Manual, driving while intoxicated increases accident risk by seven times compared to sober driving. About one-third of all road fatalities in the US are attributed to drunk driving, according to the Centers for Disease Control and Prevention. [\"Sobering Facts: Drunk Driving State Fact Sheets\"; Centers for Disease Control and Prevention; https://www.cdc.gov/motorvehiclesafety/impaired driving/states; Drinking and Driving; Section 3: Safe Driving; Virginia Driver's Manual] .html]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":610,"qid":26544,"description":"Paying the uninsured motor vehicle charge permits you to operate an uninsured motor vehicle for a period of:","explanation":"In Virginia, you are not obliged by law to obtain liability insurance on your vehicle. However, if your car does not have liability insurance coverage when you register it, you must pay a $500 uninsured motor vehicle charge. By paying this cost, you will be able to drive the car in Virginia for one year. Please keep in mind that this is NOT insurance coverage. If your car is uninsured, you may be held personally accountable for damages in the case of an accident. [Insurance Monitoring Program; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":1077,"qid":26544,"description":"Paying the uninsured motor vehicle charge permits you to operate an uninsured motor vehicle for a period of:","explanation":"In Virginia, you are not obliged by law to obtain liability insurance on your vehicle. However, if your car does not have liability insurance coverage when you register it, you must pay a $500 uninsured motor vehicle charge. By paying this cost, you will be able to drive the car in Virginia for one year. Please keep in mind that this is NOT insurance coverage. If your car is uninsured, you may be held personally accountable for damages in the case of an accident. [Insurance Monitoring Program; Other DMV Requirements, Suspensions, and Revocations; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":611,"qid":26546,"description":"Alcohol is involved in around ____of all fatal road incidents.","explanation":"Alcohol is implicated in around 40% of fatal road incidents, according to the Washington Driver Guide. Drunk driving accounts for around one-third of all road fatalities in the United States, according to the Centers for Disease Control and Prevention. [\"Sobering Facts: Drunk Driving State Fact Sheets\"; Centers for Disease Control and Prevention; https://www.cdc.gov/motorvehiclesafety/impaired driving/states], [\"Drinking Alcohol and Driving; In Shape to Drive; Washington Driver Guide] .html]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#13619","text":"10%#40%#20%#60%"},
{"testId":611,"qid":26547,"description":"Which of the following penalties will you face if convicted of driving under the influence of alcohol or drugs (DUI)?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12374#18218#26643","text":"All of the above#License suspension#Jail#A fine"},
{"testId":611,"qid":26548,"description":null,"explanation":"A single alcoholic drink may impair a person's driving ability. The degree of impairment is determined by variables such as the individual's weight. If your driving is shown to be affected after drinking alcohol, you may face charges of driving under the influence (DUI). [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1078,"qid":26548,"description":null,"explanation":"A single alcoholic drink may impair a person's driving ability. The degree of impairment is determined by variables such as the individual's weight. If your driving is shown to be affected after drinking alcohol, you may face charges of driving under the influence (DUI). [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":611,"qid":26549,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1078,"qid":26549,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":611,"qid":26550,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":1078,"qid":26550,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That rate cannot be increased by coffee, exercise, or a cold shower. You must wait till the alcohol has degraded. [Can You Drive After Drinking? ; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":611,"qid":26551,"description":"If you are detected driving with a blood alcohol level (BAC) of _____ or above, you may be arrested.","explanation":"A motorist with a BAC of 0.08 percent or above may be arrested. If the motorist is under the age of 21, he or she may also be arrested for having a BAC of 0.02 percent or more. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#3282#4251","text":"0.08%#0.07%#0.06%#0.02%"},
{"testId":1078,"qid":26551,"description":"If you are detected driving with a blood alcohol level (BAC) of _____ or above, you may be arrested.","explanation":"A motorist with a BAC of 0.08 percent or above may be arrested. If the motorist is under the age of 21, he or she may also be arrested for having a BAC of 0.02 percent or more. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#3282#4251","text":"0.08%#0.07%#0.06%#0.02%"},
{"testId":611,"qid":26552,"description":"If a driver under the age of 21 has a blood alcohol level (BAC) of _____ or higher, he or she may be arrested.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or higher is banned in Washington State for anybody under the age of 21. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4652#4653#13791","text":"0.02%#0.00%#0.01%#0.008%"},
{"testId":1078,"qid":26552,"description":"If a driver under the age of 21 has a blood alcohol level (BAC) of _____ or higher, he or she may be arrested.","explanation":"Driving with a blood alcohol concentration (BAC) of 0.02 percent or higher is banned in Washington State for anybody under the age of 21. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4652#4653#13791","text":"0.02%#0.00%#0.01%#0.008%"},
{"testId":611,"qid":26553,"description":"Alternatives to drinking and driving is ______","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":1078,"qid":26553,"description":"Alternatives to drinking and driving is ______","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":611,"qid":26554,"description":"In Washington State, what term is used to describe driving while under the influence of alcohol or drugs?","explanation":"Driving Under the Influence (DUI) in Washington State refers to driving a vehicle while under the influence of alcohol or drugs. Other states use names like Driving While Intoxicated (DWI), Operating a Vehicle While Impaired (OVI), or Driving Under the Influence of Intoxicants (DUI) (DUII). [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":1078,"qid":26554,"description":"In Washington State, what term is used to describe driving while under the influence of alcohol or drugs?","explanation":"Driving Under the Influence (DUI) in Washington State refers to driving a vehicle while under the influence of alcohol or drugs. Other states use names like Driving While Intoxicated (DWI), Operating a Vehicle While Impaired (OVI), or Driving Under the Influence of Intoxicants (DUI) (DUII). [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":611,"qid":26555,"description":"In accordance with Washington State's Implied Agree Law, by driving a motor vehicle in Washington State, you implicitly consent to :","explanation":"Under Washington State's Implied Consent Law, by operating a motor vehicle in Washington State, you have implicitly consented to being tested for alcohol or drugs upon the request of a law enforcement officer. [Alcohol/Drugs and the Law; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8615#35227#35228#35436","text":"All of the above#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":611,"qid":26556,"description":"If you refuse to submit to a blood alcohol content (BAC) breath test, your license will be suspended for at least :","explanation":"If you refuse to submit to a blood alcohol content (BAC) breath test, your license will be suspended for at least one year. It should be noted that if you fail the exam, your license will be suspended for 90 days. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1078,"qid":26556,"description":"If you refuse to submit to a blood alcohol content (BAC) breath test, your license will be suspended for at least :","explanation":"If you refuse to submit to a blood alcohol content (BAC) breath test, your license will be suspended for at least one year. It should be noted that if you fail the exam, your license will be suspended for 90 days. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":611,"qid":26557,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"A DUI offense in Washington State is considered a second offense if it happens within seven years after the first violation (and likewise for subsequent offenses). Second offense penalties are often heavier than first offense punishments. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1078,"qid":26557,"description":"If a DUI offense happens within ___ years after the first offense, it is deemed a second offense.","explanation":"A DUI offense in Washington State is considered a second offense if it happens within seven years after the first violation (and likewise for subsequent offenses). Second offense penalties are often heavier than first offense punishments. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":611,"qid":26558,"description":"First-time DUI arrests result in license suspensions of:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":611,"qid":26559,"description":"If a driver over the age of 21 gets arrested for DUI for the second time, his or her license will be suspended for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":611,"qid":26561,"description":"Offenses involving alcohol or drugs will remain on your driving record for:","explanation":"Alcohol and drug-related crimes on your driving record in Washington State are permanent. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"23154#26672#29895#32620","text":"1 year#5 years#10 years#Life"},
{"testId":1078,"qid":26561,"description":"Offenses involving alcohol or drugs will remain on your driving record for:","explanation":"Alcohol and drug-related crimes on your driving record in Washington State are permanent. [The Law and Alcohol/Drugs; Getting in Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"23154#26672#29895#32620","text":"1 year#5 years#10 years#Life"},
{"testId":611,"qid":26562,"description":"If you are convicted of DUI for the first time, you will be fined up to:","explanation":null,"imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#11670","text":"$500#$1,000#$5,000#$2,500"},
{"testId":611,"qid":26563,"description":"If you are convicted of DUI for the first time, you could face up to ______ in jail.","explanation":"If you are found guilty of driving while intoxicated for the first time in court, you might face a fine of up to $5,000, plus court fees and additional penalties. You might potentially face a prison term ranging from one to 365 days, and your license could be suspended or revoked for 90 days to two years. [Restricted Driver Licenses; Driving Fitness; Washington Driver Guide]","imageName":"","correctAnswer":19023,"isBookmarked":0,"aid":"229#742#18239#19023","text":"90 days#30 days#14 days#365 days"},
{"testId":1078,"qid":26563,"description":"If you are convicted of DUI for the first time, you could face up to ______ in jail.","explanation":"If you are found guilty of driving while intoxicated for the first time in court, you might face a fine of up to $5,000, plus court fees and additional penalties. You might potentially face a prison term ranging from one to 365 days, and your license could be suspended or revoked for 90 days to two years. [Restricted Driver Licenses; Driving Fitness; Washington Driver Guide]","imageName":"","correctAnswer":19023,"isBookmarked":0,"aid":"229#742#18239#19023","text":"90 days#30 days#14 days#365 days"},
{"testId":611,"qid":26564,"description":"If a driver fails to secure a load and causes property damage, he or she will be prosecuted with ____","explanation":"A motorist who causes property damage with unsecured objects may face misdemeanor charges. [Maria's Law; Washington Driver Guide; Before You Drive]","imageName":"","correctAnswer":12674,"isBookmarked":0,"aid":"12260#12674#45533#45534","text":"reckless driving#misdemeanor#gross misdemeanor#negligent driving"},
{"testId":611,"qid":26566,"description":"If you receive a traffic penalty while driving in Idaho,:","explanation":null,"imageName":"","correctAnswer":41424,"isBookmarked":0,"aid":"38317#38319#41423#41424","text":"your vehicle may be impounded until you answer the citation#you may have to post bail until you answer the citation#you will receive an additional citation from Washington State#you may return to Washington State and then answer the citation"},
{"testId":611,"qid":26567,"description":"First-time DUI offenders with a minor passenger must install an ignition interlock device for an additional:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#25032#25169","text":"9 months#6 months#24 months#12 months"},
{"testId":611,"qid":26568,"description":"The Open Container Law in Washington State says that you can't keep an open container of alcohol:","explanation":"An open container of alcohol may not be maintained within reach of the driver or any passengers under Washington State's Open Container Law. Furthermore, alcohol may not be stored in a beverage container labeled (falsely) as non-alcoholic. [Restricted Driver Licenses; Driving Fitness; Washington Driver Guide]","imageName":"","correctAnswer":34732,"isBookmarked":0,"aid":"30369#34732#39407#41284","text":"in the glove compartment#in any of the above locations#under the passenger seat#under the driver's seat"},
{"testId":611,"qid":26569,"description":"If you park in a charging station but do not connect to the charging equipment. , you will:","explanation":"Parking a car at an electric vehicle charging station that is not linked to the charging equipment is a parking infringement with a minimum penalty of $124. The parking violation applies to both public and private charging facilities for electric vehicles. [Electric Vehicle Charging Stations; Driving Rules; Washington Driver Guide]","imageName":"","correctAnswer":45538,"isBookmarked":0,"aid":"27729#45536#45537#45538","text":"have to do all of the above#be charged with reckless driving#have to serve 10 hours of community service#have to pay a fine for a parking infraction"},
{"testId":1078,"qid":26569,"description":"If you park in a charging station but do not connect to the charging equipment. , you will:","explanation":"Parking a car at an electric vehicle charging station that is not linked to the charging equipment is a parking infringement with a minimum penalty of $124. The parking violation applies to both public and private charging facilities for electric vehicles. [Electric Vehicle Charging Stations; Driving Rules; Washington Driver Guide]","imageName":"","correctAnswer":45538,"isBookmarked":0,"aid":"27729#45536#45537#45538","text":"have to do all of the above#be charged with reckless driving#have to serve 10 hours of community service#have to pay a fine for a parking infraction"},
{"testId":611,"qid":26570,"description":"The maximum fine for any parking offence in Washington State is:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#24978#27888#31696","text":"$500#$100#$250#$400"},
{"testId":1078,"qid":26570,"description":"The maximum fine for any parking offence in Washington State is:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#24978#27888#31696","text":"$500#$100#$250#$400"},
{"testId":611,"qid":26571,"description":"How many times can you be granted deferred prosecution for a DUI charge?","explanation":null,"imageName":"","correctAnswer":11498,"isBookmarked":0,"aid":"11498#11499#41425#41426","text":"Once#Twice#Once per decade#Twice per decade"},
{"testId":1078,"qid":26571,"description":"How many times can you be granted deferred prosecution for a DUI charge?","explanation":null,"imageName":"","correctAnswer":11498,"isBookmarked":0,"aid":"11498#11499#41425#41426","text":"Once#Twice#Once per decade#Twice per decade"},
{"testId":611,"qid":26573,"description":"For a first offense of possessing alcohol, a 16-year-old driver's license is revoked for:","explanation":"If a person aged 13 to 17 is convicted of possessing alcohol or any violation involving a handgun for the first time, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1078,"qid":26573,"description":"For a first offense of possessing alcohol, a 16-year-old driver's license is revoked for:","explanation":"If a person aged 13 to 17 is convicted of possessing alcohol or any violation involving a handgun for the first time, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":611,"qid":26574,"description":"If a fourteen-year-old driver is convicted of alcohol possession, his or her license will be revoked for:","explanation":"If a person aged 13 to 17 is convicted of possessing alcohol or any violation involving a handgun for the first time, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":14001,"isBookmarked":0,"aid":"12740#13666#14000#14001","text":"for 1 year#for 5 years#for 2 years#until he or she turns 17"},
{"testId":1078,"qid":26574,"description":"If a fourteen-year-old driver is convicted of alcohol possession, his or her license will be revoked for:","explanation":"If a person aged 13 to 17 is convicted of possessing alcohol or any violation involving a handgun for the first time, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":14001,"isBookmarked":0,"aid":"12740#13666#14000#14001","text":"for 1 year#for 5 years#for 2 years#until he or she turns 17"},
{"testId":611,"qid":26575,"description":"First-time drug offenses result in a license revocation for a period of________ for 19-year-old drivers.","explanation":"If a person between the ages of 13 and 20 is convicted of his or her first drug crime, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1078,"qid":26575,"description":"First-time drug offenses result in a license revocation for a period of________ for 19-year-old drivers.","explanation":"If a person between the ages of 13 and 20 is convicted of his or her first drug crime, his or her license will be revoked for one year or until the age of 17, whichever comes first. [Juvenile Alcohol/Drug/Firearms Violations; In Shape to Drive; Washington Driver Guide], [\"Minor in Possession\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor-in-possession/minor .html]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":611,"qid":26577,"description":"If you are found guilty of using a handheld cell phone while driving on your first offense, you will be fined at least .","explanation":"It is illegal in Washington State to use a portable personal electronic device while driving. The minimum punishment for breaking the distracted driving statute is $124, but it may be more if you cause a collision. The fine for the second and subsequent infractions is doubled. [Distracted Driving, Tips for Safe Driving, Washington Driver Guide]","imageName":"","correctAnswer":45462,"isBookmarked":0,"aid":"41429#41430#45461#45462","text":"$136#$156#$186#$124"},
{"testId":611,"qid":26578,"description":"Who can be penalized if you carry a sixteen-year-old passenger who is not wearing a seat belt?","explanation":"You are responsible in Washington State for ensuring that passengers under the age of 16 use seat belts or other child restraints. A passenger must wear a seat belt if they are at least 16 years old. [Use Seat Belts and Child Restraints at All Times; Before You Drive; Washington Driver Guide]","imageName":"","correctAnswer":34753,"isBookmarked":0,"aid":"9642#34753#40929#40930","text":"You#The passenger#Both you and the passenger#Neither you nor the passenger"},
{"testId":611,"qid":26579,"description":"If you're convicted of DUI with a minor passenger,聽:","explanation":null,"imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"34981#41431#41432#41433","text":"all of the above may happen#the ignition interlock device (IID) installation period will be increased#your jail sentence will be increased#child protective services may be notified"},
{"testId":611,"qid":26580,"description":"THC is the main psychoactive component in marijuana. In Washington State, a driver under 21 years of age may not drive with a THC level more than _____ nanograms per milliliter of blood.","explanation":null,"imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"35229#41393#41394#41395","text":"Zero#4.00#3.00#2.00"},
{"testId":1078,"qid":26580,"description":"THC is the main psychoactive component in marijuana. In Washington State, a driver under 21 years of age may not drive with a THC level more than _____ nanograms per milliliter of blood.","explanation":null,"imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"35229#41393#41394#41395","text":"Zero#4.00#3.00#2.00"},
{"testId":611,"qid":26581,"description":"It is illegal to park in a handicapped parking area without proper documentation, and the penalty for offender is:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24994#27888","text":"$200#$100#$50#$250"},
{"testId":1078,"qid":26581,"description":"It is illegal to park in a handicapped parking area without proper documentation, and the penalty for offender is:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24994#27888","text":"$200#$100#$50#$250"},
{"testId":611,"qid":26582,"description":"A ______ fine will be levied if you are found guilty of neglecting to employ tire chains in the presence of a traction advice sign.","explanation":"You will be fined $500 if you are found guilty of neglecting to employ tire chains where a traction advice sign mandates them. [Traffic Sign; Traffic Signs; Washington Driver Guide]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":611,"qid":26583,"description":"While holding a learner's permit, your driving must be supervised by a driver who:","explanation":"Driving must be supervised at all times while you have a Washington State instruction permit. The supervising passenger must be a qualified driver with at least five years of experience behind the wheel. He or she must be placed in the front right seat. [Instruction Permit; Driver License Types; Washington Driver Handbook]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#40068#41434#41435","text":"meets all of the above requirements#is seated next to you#holds a valid driver license#has at least five years of licensed driving experience"},
{"testId":611,"qid":26584,"description":"A basic driver's license is valid up to:","explanation":"A basic driving license is valid for up to six years from the driver's most recent birthday. [Basic Driver License; Driver License Types; Washington Driver Handbook]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23183#29881#39076","text":"4 years#3 years#6 years#8 years"},
{"testId":1078,"qid":26584,"description":"A basic driver's license is valid up to:","explanation":"A basic driving license is valid for up to six years from the driver's most recent birthday. [Basic Driver License; Driver License Types; Washington Driver Handbook]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#23183#29881#39076","text":"4 years#3 years#6 years#8 years"},
{"testId":611,"qid":26585,"description":"To receive an instruction permit, you must be at least ____ years old unless you are enrolled in an approved driver training school.","explanation":"You must be at least 15 years old if you are enrolled in an authorized driver training course, or at least 15 1/2 years old if you are not. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":32241,"isBookmarked":0,"aid":"5447#11361#32240#32241","text":"15#16#16 1/2#15 1/2"},
{"testId":611,"qid":26586,"description":"Which of the following medical issues can make driving dangerous?","explanation":"Certain medical issues make it risky to get behind the wheel since they may make you pass out or collapse. Diabetes, epilepsy, and numerous cardiac diseases are among them. During the medical examination required to get a driving licence in the state of Washington, you are questioned about the existence of any such conditions. If this is the case, the DOL could ask for a medical expert's examination and certification that your health condition is being effectively handled. [Washington Driver's Guide: Health; Driving Fitness], [\"Getting a Driver License: Medical and Vision Screening,\" Washington State Department of Licensing, online at https://www.dol.wa.gov/driverslicense/medicalvision .html]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32216#41436#41437","text":"All of the above#Epilepsy#Heart disease#Diabetes"},
{"testId":611,"qid":26587,"description":"Before you can get an instruction permit, your parent or guardian must sign a Parental Authorization Affidavit if you are under the age of:","explanation":"If you are under the age of 18, you must have your parent or guardian sign a Parental Authorization Affidavit before you may get an instruction permit. He or she may accompany you to the driver license office and get the affidavit notarized so you can submit it alone. [\"Steps to Getting Your First License: Instruction Permits\"; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/getpermit .html]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":611,"qid":26588,"description":"For how much duration a Washington State instruction permit is valid?","explanation":"Your Washington State teaching permit is good for one year and may be renewed. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":611,"qid":26589,"description":"To receive an instruction permit, you must be at least ____ years old if you are enrolled in an approved driver training school.","explanation":"You must be at least 15 years old if you are enrolled in an authorized driver training course, or at least 15 1/2 years old if you are not. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11361#32241#41438","text":"15#16#15 1/2#14 1/2"},
{"testId":611,"qid":26590,"description":"If you have had any traffic tickets in the past _____, you are ineligible for a Washington State intermediate driver's license.","explanation":"If you have committed or been charged with any traffic offences in the last six months, you are ineligible for a Washington State intermediate driver license. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25170#27363","text":"3 months#6 months#1 month#2 months"},
{"testId":611,"qid":26591,"description":"A driver with an intermediate license may not drive unattended between the hours of _______ and 5:00 a.m.","explanation":"A motorist with an intermediate license may not drive unattended between 1:00 a.m. and 6:00 a.m. and 5:00 a.m. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":13921,"isBookmarked":0,"aid":"13580#13921#13922#13923","text":"2:00 a.m#1:00 a.m#12:00 a.m#11:00 p.m"},
{"testId":611,"qid":26592,"description":"For the first ______, a motorist with an intermediate driver license may not carry any unrelated passengers under the age of 20.","explanation":"For the first six months, a driver with an intermediate license may not carry a passenger under the age of 20 unless that person is a member of his or her immediate family. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23886#24048#27048","text":"6 months#30 days#60 days#year"},
{"testId":611,"qid":26593,"description":"If a motorist with an intermediate driving license violates one of the restrictions, all restrictions will be extended until the driver reaches the age of:","explanation":"Normally, an intermediate driving license holder may not carry an unrelated passenger under the age of 20 for the first six months. However, if he or she commits a traffic offense or breaches any of the license limits, all restrictions will be extended until the driver reaches the age of 18. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":33116,"isBookmarked":0,"aid":"33115#33116#41440#41441","text":"21#18#17#20"},
{"testId":611,"qid":26594,"description":"The DOL will issue a warning letter to the intermediate license holder's parent or guardian if he or she:","explanation":"If an intermediate license holder violates any of the license's restrictions, the DOL will send warning letters to both the license holder and his or her parent or guardian. In addition, if an intermediate license holder is involved in an accident, the DOL will send a warning letter if he or she was at fault, was the only party involved, or under other circumstances. [Obtaining Your Driver's License; Washington Driver Guide]","imageName":"","correctAnswer":41445,"isBookmarked":0,"aid":"41442#41443#41444#41445","text":"commits a traffic violation#is at fault in an accident#violates an intermediate license restriction#is in any of the above situations"},
{"testId":1078,"qid":26594,"description":"The DOL will issue a warning letter to the intermediate license holder's parent or guardian if he or she:","explanation":"If an intermediate license holder violates any of the license's restrictions, the DOL will send warning letters to both the license holder and his or her parent or guardian. In addition, if an intermediate license holder is involved in an accident, the DOL will send a warning letter if he or she was at fault, was the only party involved, or under other circumstances. [Obtaining Your Driver's License; Washington Driver Guide]","imageName":"","correctAnswer":41445,"isBookmarked":0,"aid":"41442#41443#41444#41445","text":"commits a traffic violation#is at fault in an accident#violates an intermediate license restriction#is in any of the above situations"},
{"testId":612,"qid":26596,"description":"Alcohol-impaired drivers have been involved in around ___ of fatal traffic accidents in West Virginia.","explanation":null,"imageName":"","correctAnswer":14017,"isBookmarked":0,"aid":"13852#14017#41568#41569","text":"18%#28%#38%#48%"},
{"testId":612,"qid":26597,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":1079,"qid":26597,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#18113#25081","text":"None of the above#Coffee#Exercise#A cold shower"},
{"testId":612,"qid":26598,"description":"A motorist under the age of 21 may not operate a motor vehicle with a blood alcohol content (BAC) of ___ or higher.","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4252#4253","text":"0.04%#0.02%#0.05%#0.03%"},
{"testId":1079,"qid":26598,"description":"A motorist under the age of 21 may not operate a motor vehicle with a blood alcohol content (BAC) of ___ or higher.","explanation":null,"imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4252#4253","text":"0.04%#0.02%#0.05%#0.03%"},
{"testId":612,"qid":26599,"description":"A blood alcohol content (BAC) of greater than _____ is important evidence in West Virginia when deciding whether you have been driving under the influence.","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3170#3282#4252#4253","text":"0.07%#0.06%#0.05%#0.03%"},
{"testId":1079,"qid":26599,"description":"A blood alcohol content (BAC) of greater than _____ is important evidence in West Virginia when deciding whether you have been driving under the influence.","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3170#3282#4252#4253","text":"0.07%#0.06%#0.05%#0.03%"},
{"testId":612,"qid":26600,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1079,"qid":26600,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":null,"imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":612,"qid":26601,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1079,"qid":26601,"description":"One alcoholic drink takes an average person's body roughly ___ hour(s) to digest.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":612,"qid":26602,"description":"In West Virginia, what term is used to describe driving while under the influence of alcohol or drugs?","explanation":null,"imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":1079,"qid":26602,"description":"In West Virginia, what term is used to describe driving while under the influence of alcohol or drugs?","explanation":null,"imageName":"","correctAnswer":12821,"isBookmarked":0,"aid":"12820#12821#13751#40050","text":"Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Driving Under the Influence of Intoxicants (DUII)#Operating a Vehicle while Impaired (OVI)"},
{"testId":612,"qid":26603,"description":"If you are detected driving under the influence of _________, you may be charged with DUI.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33479#35439#39409","text":"Any of the above#illegal drugs#prescription medications#over-the-counter medications"},
{"testId":1079,"qid":26603,"description":"If you are detected driving under the influence of _________, you may be charged with DUI.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33479#35439#39409","text":"Any of the above#illegal drugs#prescription medications#over-the-counter medications"},
{"testId":612,"qid":26604,"description":"Under West Virginia's Implied Assent Law, driving in West Virginia constitutes implied consent to :","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"29272#35227#35228#35436","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":1079,"qid":26604,"description":"Under West Virginia's Implied Assent Law, driving in West Virginia constitutes implied consent to :","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"29272#35227#35228#35436","text":"a search of your home#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":612,"qid":26605,"description":"If you are caught for DUI and refuse a subsequent chemical test at the police station, your license will be canceled for at least ______.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1079,"qid":26605,"description":"If you are caught for DUI and refuse a subsequent chemical test at the police station, your license will be canceled for at least ______.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":612,"qid":26606,"description":"If you're arrested for DUI and refuse a chemical test, your license may be revoked for:","explanation":null,"imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"20788#23183#26672#32620","text":"2 years#3 years#5 years#Life"},
{"testId":1079,"qid":26606,"description":"If you're arrested for DUI and refuse a chemical test, your license may be revoked for:","explanation":null,"imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"20788#23183#26672#32620","text":"2 years#3 years#5 years#Life"},
{"testId":612,"qid":26607,"description":"A motorist under the age of 18 who is convicted of DUI will have his or her license revoked for the regular underage punishment term or until the driver reaches the age of ____, whichever comes first.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11696","text":"25#18#21#19"},
{"testId":1079,"qid":26607,"description":"A motorist under the age of 18 who is convicted of DUI will have his or her license revoked for the regular underage punishment term or until the driver reaches the age of ____, whichever comes first.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11696","text":"25#18#21#19"},
{"testId":612,"qid":26608,"description":"A driver under the age of 21 will be fined up to what amount if he or she is convicted of DUI for the first time?","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"23887#24978#24993#24994","text":"$500#$100#$25#$50"},
{"testId":1079,"qid":26608,"description":"A driver under the age of 21 will be fined up to what amount if he or she is convicted of DUI for the first time?","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"23887#24978#24993#24994","text":"$500#$100#$25#$50"},
{"testId":612,"qid":26609,"description":"If a driver under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for at least_____","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1079,"qid":26609,"description":"If a driver under the age of 21 is convicted of DUI for the first time, his or her license will be suspended for at least_____","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":612,"qid":26610,"description":"A driver under the age of 21 will be fined up to what amount if he or she is convicted of DUI for the second time?","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#24994","text":"$500#$1,000#$100#$50"},
{"testId":1079,"qid":26610,"description":"A driver under the age of 21 will be fined up to what amount if he or she is convicted of DUI for the second time?","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#24994","text":"$500#$1,000#$100#$50"},
{"testId":612,"qid":26611,"description":"A driver under the age of 21 will face imprisonment for _______ if he or she is convicted of DUI for the second time.","explanation":null,"imageName":"","correctAnswer":41570,"isBookmarked":0,"aid":"23883#34746#35988#41570","text":"10 days#3 days#7 days#1 day"},
{"testId":1079,"qid":26611,"description":"A driver under the age of 21 will face imprisonment for _______ if he or she is convicted of DUI for the second time.","explanation":null,"imageName":"","correctAnswer":41570,"isBookmarked":0,"aid":"23883#34746#35988#41570","text":"10 days#3 days#7 days#1 day"},
{"testId":612,"qid":26612,"description":"For a first offense, an adult motorist convicted of DUI with a BAC of less than 0.15 percent will be fined .","explanation":null,"imageName":"","correctAnswer":41572,"isBookmarked":0,"aid":"41571#41572#41573#41574","text":"$50 鈥 $100#$100 鈥 $500#$1,000 鈥 $2,000#$1,500 鈥 $3,000"},
{"testId":1079,"qid":26612,"description":"For a first offense, an adult motorist convicted of DUI with a BAC of less than 0.15 percent will be fined .","explanation":null,"imageName":"","correctAnswer":41572,"isBookmarked":0,"aid":"41571#41572#41573#41574","text":"$50 鈥 $100#$100 鈥 $500#$1,000 鈥 $2,000#$1,500 鈥 $3,000"},
{"testId":612,"qid":26617,"description":"For a first offense, an adult motorist convicted of DUI with a blood alcohol concentration (BAC) of 0.15 percent or more will be sentenced to at least ______ in jail:","explanation":null,"imageName":"","correctAnswer":40595,"isBookmarked":0,"aid":"739#742#40595#40913","text":"10 days#30 days#2 days#4 days"},
{"testId":1079,"qid":26617,"description":"For a first offense, an adult motorist convicted of DUI with a blood alcohol concentration (BAC) of 0.15 percent or more will be sentenced to at least ______ in jail:","explanation":null,"imageName":"","correctAnswer":40595,"isBookmarked":0,"aid":"739#742#40595#40913","text":"10 days#30 days#2 days#4 days"},
{"testId":612,"qid":26619,"description":"If you're convicted of DUI with a minor in the car, you face up to _____聽in jail.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":612,"qid":26620,"description":"DUI causing bodily injury will result in a license suspension for at least ____ for a first offense.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"229#740#1020#11553","text":"90 days#15 days#60 days#45 days"},
{"testId":1079,"qid":26620,"description":"DUI causing bodily injury will result in a license suspension for at least ____ for a first offense.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"229#740#1020#11553","text":"90 days#15 days#60 days#45 days"},
{"testId":612,"qid":26622,"description":"If convicted of DUI for the first time, which resulted in someone's death, your license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#742#1272#1273","text":"90 days#30 days#6 months#12 months"},
{"testId":1079,"qid":26622,"description":"If convicted of DUI for the first time, which resulted in someone's death, your license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#742#1272#1273","text":"90 days#30 days#6 months#12 months"},
{"testId":612,"qid":26623,"description":"Which of the following offenses has the LEAST points?","explanation":null,"imageName":"","correctAnswer":864,"isBookmarked":0,"aid":"864#38320#38639#40400","text":"Tailgating#Driving the wrong way on a one-way street#Littering#Failing to yield the right-of-way"},
{"testId":1079,"qid":26623,"description":"Which of the following offenses has the LEAST points?","explanation":null,"imageName":"","correctAnswer":864,"isBookmarked":0,"aid":"864#38320#38639#40400","text":"Tailgating#Driving the wrong way on a one-way street#Littering#Failing to yield the right-of-way"},
{"testId":612,"qid":26625,"description":"A second DUI conviction for an adult driver will result in at least _______ in prison.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1273#1453#1454","text":"6 months#12 months#3 months#9 months"},
{"testId":1079,"qid":26625,"description":"A second DUI conviction for an adult driver will result in at least _______ in prison.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1273#1453#1454","text":"6 months#12 months#3 months#9 months"},
{"testId":612,"qid":26626,"description":"An adult driver's license will be suspended for at least _______ for a third or subsequent conviction if they are found guilty of DUI.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#1021#1272#11553","text":"90 days#1 year#6 months#45 days"},
{"testId":1079,"qid":26626,"description":"An adult driver's license will be suspended for at least _______ for a third or subsequent conviction if they are found guilty of DUI.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"229#1021#1272#11553","text":"90 days#1 year#6 months#45 days"},
{"testId":612,"qid":26627,"description":"A third or subsequent DUI conviction can result in a fine of up to:","explanation":null,"imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"1799#3195#11668#11670","text":"$1,000#$5,000#$1,500#$2,500"},
{"testId":1079,"qid":26627,"description":"A third or subsequent DUI conviction can result in a fine of up to:","explanation":null,"imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"1799#3195#11668#11670","text":"$1,000#$5,000#$1,500#$2,500"},
{"testId":612,"qid":26629,"description":"Which drivers in West Virginia are required to have auto liability insurance?","explanation":null,"imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"10424#39792#39793#41718","text":"All drivers#Only drivers under the age of 21#Only drivers under the age of 18#Only drivers of vehicles worth more than $20,000"},
{"testId":1079,"qid":26629,"description":"Which drivers in West Virginia are required to have auto liability insurance?","explanation":null,"imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"10424#39792#39793#41718","text":"All drivers#Only drivers under the age of 21#Only drivers under the age of 18#Only drivers of vehicles worth more than $20,000"},
{"testId":612,"qid":26630,"description":"In West Virginia, your vehicle liability insurance policy must provide at least __________ in coverage for the physical injury or death of a single person in the event of an accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":612,"qid":26631,"description":"Your vehicle liability insurance policy in West Virginia must offer at least ______ in coverage for the physical injuries or deaths of two or more people in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#11659#12047","text":"$50,000#$30,000#$20,000#$15,000"},
{"testId":612,"qid":26632,"description":"Your vehicle liability insurance policy in West Virginia must offer at least ______ in property damage coverage in any one accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":612,"qid":26633,"description":"If you're guilty of driving an uninsured car on West Virginia roadways,:","explanation":null,"imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"29912#31016#31384#34981","text":"your license will be suspended#you will be sentenced to jail#you will be fined#all of the above may happen"},
{"testId":1079,"qid":26633,"description":"If you're guilty of driving an uninsured car on West Virginia roadways,:","explanation":null,"imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"29912#31016#31384#34981","text":"your license will be suspended#you will be sentenced to jail#you will be fined#all of the above may happen"},
{"testId":612,"qid":26634,"description":"If you file false insurance information, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1079,"qid":26634,"description":"If you file false insurance information, your license will be suspended for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":612,"qid":26635,"description":"If you are found guilty of providing incorrect or fraudulent vehicle insurance information, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24977#25033","text":"$500#$1,000#$200#$2,000"},
{"testId":1079,"qid":26635,"description":"If you are found guilty of providing incorrect or fraudulent vehicle insurance information, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24977#25033","text":"$500#$1,000#$200#$2,000"},
{"testId":612,"qid":26636,"description":"If you are convicted of providing incorrect or fraudulent vehicle insurance information, you might face up to_______ in prison.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20790#23153#23154","text":"2 years#4 years#6 months#1 year"},
{"testId":1079,"qid":26636,"description":"If you are convicted of providing incorrect or fraudulent vehicle insurance information, you might face up to_______ in prison.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20790#23153#23154","text":"2 years#4 years#6 months#1 year"},
{"testId":612,"qid":26637,"description":"How long will points for a traffic offense stay on your record?","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":1079,"qid":26637,"description":"How long will points for a traffic offense stay on your record?","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":612,"qid":26638,"description":"Your license will be suspended if you acquire at least ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1079,"qid":26638,"description":"Your license will be suspended if you acquire at least ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":612,"qid":26639,"description":"If you have at least ___ points on your driving record, you will be sent a warning letter advising you to drive more cautiously.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6916#7620","text":"5#3#6#8"},
{"testId":1079,"qid":26639,"description":"If you have at least ___ points on your driving record, you will be sent a warning letter advising you to drive more cautiously.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6916#7620","text":"5#3#6#8"},
{"testId":612,"qid":26640,"description":"If you finish an approved Defensive Driving Course (DDC), you will have ___ points deducted from your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":612,"qid":26641,"description":"If you flee from a cop, __ points will be added to your license.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1079,"qid":26641,"description":"If you flee from a cop, __ points will be added to your license.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":612,"qid":26642,"description":"If you are guilty of exceeding the speed limit by _____ miles per hour, five points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6916#11343","text":"15#20#6#11"},
{"testId":1079,"qid":26642,"description":"If you are guilty of exceeding the speed limit by _____ miles per hour, five points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#5448#6916#11343","text":"15#20#6#11"},
{"testId":612,"qid":26643,"description":"Stop sign violations add ______ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1079,"qid":26643,"description":"Stop sign violations add ______ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":612,"qid":26644,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":1079,"qid":26644,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":612,"qid":26645,"description":"If you are guilty of littering the road while driving, following number of points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1079,"qid":26645,"description":"If you are guilty of littering the road while driving, following number of points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":26646,"description":"Which of the following can result in a Wisconsin driver's license suspension?","explanation":"Depending on the nature of the conduct, conviction for a major offense may result in your driving license being canceled, suspended, or revoked. These infractions include providing false information on a license application, driving while intoxicated, fleeing the scene of an accident (hit and run), failing to report an accident to law authorities, and simply collecting too many demerit points on your driving record. [Maintaining a Driver's License; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23204#43743#43744","text":"All of the above#Leaving the scene of an accident#Driving under the influence of alcohol#Supplying false information on a license application"},
{"testId":1080,"qid":26646,"description":"Which of the following can result in a Wisconsin driver's license suspension?","explanation":"Depending on the nature of the conduct, conviction for a major offense may result in your driving license being canceled, suspended, or revoked. These infractions include providing false information on a license application, driving while intoxicated, fleeing the scene of an accident (hit and run), failing to report an accident to law authorities, and simply collecting too many demerit points on your driving record. [Maintaining a Driver's License; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23204#43743#43744","text":"All of the above#Leaving the scene of an accident#Driving under the influence of alcohol#Supplying false information on a license application"},
{"testId":613,"qid":26647,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":null,"imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#41082#43745#43746","text":"Speeding#Using a motor vehicle to commit a felony#Excessive demerit point accumulation#Refusing to be tested for alcohol or drugs"},
{"testId":1080,"qid":26647,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":null,"imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#41082#43745#43746","text":"Speeding#Using a motor vehicle to commit a felony#Excessive demerit point accumulation#Refusing to be tested for alcohol or drugs"},
{"testId":613,"qid":26648,"description":"For how much duration a regular Wisconsin driver license is valid?","explanation":"A standard Wisconsin driver's license is good for eight years. [Renewing a Driver License; Maintaining a Driver License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":39076,"isBookmarked":0,"aid":"20788#20790#29881#39076","text":"2 years#4 years#6 years#8 years"},
{"testId":1080,"qid":26648,"description":"For how much duration a regular Wisconsin driver license is valid?","explanation":"A standard Wisconsin driver's license is good for eight years. [Renewing a Driver License; Maintaining a Driver License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":39076,"isBookmarked":0,"aid":"20788#20790#29881#39076","text":"2 years#4 years#6 years#8 years"},
{"testId":613,"qid":26649,"description":"If you accumulate ____ demerit points, you'll get a letter explaining how to minimize them.","explanation":"If you have 6 demerit points on your driving record, you will get a warning letter informing you that you may lower your point total by 3 if you complete an authorized traffic safety course. [Point System; Maintaining a Driver License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1080,"qid":26649,"description":"If you accumulate ____ demerit points, you'll get a letter explaining how to minimize them.","explanation":"If you have 6 demerit points on your driving record, you will get a warning letter informing you that you may lower your point total by 3 if you complete an authorized traffic safety course. [Point System; Maintaining a Driver License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":613,"qid":26650,"description":"If you finish an approved traffic safety course, you can reduce your point total on your driving record by ___ points.","explanation":"If you finish an authorized traffic safety course, you may lower your point total on your driving record by three points. [Point System; Driver License Retention; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":26651,"description":"If you accumulate _____ demerit points in twelve-month, your license will be suspended.","explanation":"Your license will be suspended or revoked if you acquire 12 or more demerit points in a twelve-month period. [Point System; Driver License Retention; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1080,"qid":26651,"description":"If you accumulate _____ demerit points in twelve-month, your license will be suspended.","explanation":"Your license will be suspended or revoked if you acquire 12 or more demerit points in a twelve-month period. [Point System; Driver License Retention; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":613,"qid":26653,"description":"The DMV will consider聽you a habitual offender if you're convicted of __ minor traffic violations in five years.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1080,"qid":26653,"description":"The DMV will consider聽you a habitual offender if you're convicted of __ minor traffic violations in five years.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":613,"qid":26656,"description":"You may drive no more than ___ hours per week if you have an occupational license.","explanation":"If you are declared a persistent offender by the DMV, you may be eligible to apply for an occupational license after two years. An occupational license allows you to drive for a maximum of 12 hours per day and 60 hours per week. You are only permitted to drive to and from job, school, or church, or for essential domestic necessities such as obtaining food or prescriptions or attending medical appointments. [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx], [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx ]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#5448#8141#11513","text":"10#20#60#40"},
{"testId":1080,"qid":26656,"description":"You may drive no more than ___ hours per week if you have an occupational license.","explanation":"If you are declared a persistent offender by the DMV, you may be eligible to apply for an occupational license after two years. An occupational license allows you to drive for a maximum of 12 hours per day and 60 hours per week. You are only permitted to drive to and from job, school, or church, or for essential domestic necessities such as obtaining food or prescriptions or attending medical appointments. [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx], [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx ]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#5448#8141#11513","text":"10#20#60#40"},
{"testId":613,"qid":26658,"description":"Who is required to have vehicle liability insurance in Wisconsin?","explanation":"According to Wisconsin law, the driver or owner of any vehicle operated in Wisconsin must carry liability insurance for that vehicle. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":41317,"isBookmarked":0,"aid":"37206#41317#43747#43748","text":"Both (a) and (b)#Either (a) or (b)#(a) The driver of the vehicle#(b) The owner of the vehicle"},
{"testId":1080,"qid":26658,"description":"Who is required to have vehicle liability insurance in Wisconsin?","explanation":"According to Wisconsin law, the driver or owner of any vehicle operated in Wisconsin must carry liability insurance for that vehicle. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":41317,"isBookmarked":0,"aid":"37206#41317#43747#43748","text":"Both (a) and (b)#Either (a) or (b)#(a) The driver of the vehicle#(b) The owner of the vehicle"},
{"testId":613,"qid":26659,"description":"If a law enforcement officer requests proof of vehicle liability insurance and you do not have it, you might face a fine of up to:","explanation":"Failure to maintain automobile liability insurance may result in a $500 fine. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":1080,"qid":26659,"description":"If a law enforcement officer requests proof of vehicle liability insurance and you do not have it, you might face a fine of up to:","explanation":"Failure to maintain automobile liability insurance may result in a $500 fine. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":613,"qid":26660,"description":"If a law enforcement officer requests proof of automobile liability insurance and you do not have it, you may be penalized:","explanation":"Failure to present proof of insurance to a law enforcement officer may result in a $10 punishment. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":33835,"isBookmarked":0,"aid":"24978#24993#24994#33835","text":"$100#$25#$50#$10"},
{"testId":1080,"qid":26660,"description":"If a law enforcement officer requests proof of automobile liability insurance and you do not have it, you may be penalized:","explanation":"Failure to present proof of insurance to a law enforcement officer may result in a $10 punishment. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":33835,"isBookmarked":0,"aid":"24978#24993#24994#33835","text":"$100#$25#$50#$10"},
{"testId":613,"qid":26661,"description":"If you are found guilty of giving a law enforcement officer with false paperwork pertaining to your vehicle's liability insurance, you might face a fine of up to:","explanation":"Falsifying automobile liability insurance paperwork is a felony punishable by a $5,000 punishment. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#24978#25036","text":"$500#$1,000#$100#$5,000"},
{"testId":1080,"qid":26661,"description":"If you are found guilty of giving a law enforcement officer with false paperwork pertaining to your vehicle's liability insurance, you might face a fine of up to:","explanation":"Falsifying automobile liability insurance paperwork is a felony punishable by a $5,000 punishment. [Requirement for Motor Vehicle Liability Insurance; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23887#23889#24978#25036","text":"$500#$1,000#$100#$5,000"},
{"testId":613,"qid":26662,"description":"According to the Wisconsin Department of Transportation's Motorists' Handbook, alcohol is involved in _____ of all fatal road accidents.","explanation":null,"imageName":"","correctAnswer":43751,"isBookmarked":0,"aid":"43749#43750#43751#43752","text":"10鈥20%#20鈥30%#40鈥50%#60鈥70%"},
{"testId":613,"qid":26663,"description":"In Wisconsin, the amount of alcohol in a person's body is referred to as the:","explanation":"The term Alcohol Concentration (AC) is used in Wisconsin law and the Wisconsin Department of Transportation Motorists' Handbook to refer to the proportion of alcohol in a person's body. Many other states use phrases like Blood Alcohol Concentration (BAC). [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":43754,"isBookmarked":0,"aid":"43753#43754#43755#43756","text":"Blood Alcohol Concentration (BAC)#Alcohol Concentration (AC)#Alcohol Percentage (AP)#Blood Alcohol Content (BAC)"},
{"testId":1080,"qid":26663,"description":"In Wisconsin, the amount of alcohol in a person's body is referred to as the:","explanation":"The term Alcohol Concentration (AC) is used in Wisconsin law and the Wisconsin Department of Transportation Motorists' Handbook to refer to the proportion of alcohol in a person's body. Many other states use phrases like Blood Alcohol Concentration (BAC). [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":43754,"isBookmarked":0,"aid":"43753#43754#43755#43756","text":"Blood Alcohol Concentration (BAC)#Alcohol Concentration (AC)#Alcohol Percentage (AP)#Blood Alcohol Content (BAC)"},
{"testId":613,"qid":26664,"description":"Which of the following tests is most regularly performed in Wisconsin to determine a driver's alcohol concentration (AC)?","explanation":"A person's alcohol content (AC) in Wisconsin is normally measured by a breath, blood, or urine test. [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":1080,"qid":26664,"description":"Which of the following tests is most regularly performed in Wisconsin to determine a driver's alcohol concentration (AC)?","explanation":"A person's alcohol content (AC) in Wisconsin is normally measured by a breath, blood, or urine test. [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#34975#34976#34977","text":"All of the above#Breath#Blood#Urine"},
{"testId":613,"qid":26665,"description":"If you only consume ___ alcoholic drinks, your driving ability may deteriorate to the point where you can be arrested.","explanation":"Even two \"normal\" alcoholic drinks may impair your driving to the point where you can be arrested. (A typical drink contains 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1080,"qid":26665,"description":"If you only consume ___ alcoholic drinks, your driving ability may deteriorate to the point where you can be arrested.","explanation":"Even two \"normal\" alcoholic drinks may impair your driving to the point where you can be arrested. (A typical drink contains 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":26666,"description":"It may take an average adult's body up to ___ hour(s) to eliminate itself of the alcohol in one drink.","explanation":"According to the Wisconsin Department of Transportation Motorists' Handbook, it may take up to two hours for an average adult's body to eliminate itself of the alcohol in one \"normal\" drink. (A typical drink contains 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) (Note: According to some experts, it will take closer to an hour to get rid of the alcohol in one drink.) [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1080,"qid":26666,"description":"It may take an average adult's body up to ___ hour(s) to eliminate itself of the alcohol in one drink.","explanation":"According to the Wisconsin Department of Transportation Motorists' Handbook, it may take up to two hours for an average adult's body to eliminate itself of the alcohol in one \"normal\" drink. (A typical drink contains 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) (Note: According to some experts, it will take closer to an hour to get rid of the alcohol in one drink.) [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":613,"qid":26667,"description":"Which of the following will assist an intoxicated driver in becoming sober?","explanation":"Your liver degrades alcohol at a consistent pace. According to the Wisconsin Department of Transportation Motorists' Handbook, each \"normal\" drink might take up to two hours for your liver to break down the alcohol. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#18113#25081#40345","text":"Coffee#Exercise#A cold shower#The passage of time"},
{"testId":1080,"qid":26667,"description":"Which of the following will assist an intoxicated driver in becoming sober?","explanation":"Your liver degrades alcohol at a consistent pace. According to the Wisconsin Department of Transportation Motorists' Handbook, each \"normal\" drink might take up to two hours for your liver to break down the alcohol. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [When Can You Drive After Drinking? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#18113#25081#40345","text":"Coffee#Exercise#A cold shower#The passage of time"},
{"testId":613,"qid":26668,"description":"Alternatives to drinking and driving is ______","explanation":"Taking a cab, utilising public transit, or travelling with a designated driver are alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises while they are drinking and walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had an alcohol content (AC) of 0.08 or higher, but 37 percent of pedestrians did. [If You Drink, When Can You Drive? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook] and [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.gov/Api/Public/ViewPublication/811748]]]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":1080,"qid":26668,"description":"Alternatives to drinking and driving is ______","explanation":"Taking a cab, utilising public transit, or travelling with a designated driver are alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises while they are drinking and walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had an alcohol content (AC) of 0.08 or higher, but 37 percent of pedestrians did. [If You Drink, When Can You Drive? ; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook] and [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.gov/Api/Public/ViewPublication/811748]]]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":613,"qid":26669,"description":"In Wisconsin, what term is used to describe driving under the influence of alcohol or drugs?","explanation":"Operating a vehicle while under the influence of alcohol or narcotics is known as Operating While Intoxicated in Wisconsin (OWI). Other states may use other names, such as Driving While Intoxicated (DWI) or Driving Under the Influence (DUI) (DUI). [Wisconsin Alcohol Laws; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":12818,"isBookmarked":0,"aid":"12818#12820#12821#40050","text":"Operating While Intoxicated (OWI)#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Operating a Vehicle while Impaired (OVI)"},
{"testId":1080,"qid":26669,"description":"In Wisconsin, what term is used to describe driving under the influence of alcohol or drugs?","explanation":"Operating a vehicle while under the influence of alcohol or narcotics is known as Operating While Intoxicated in Wisconsin (OWI). Other states may use other names, such as Driving While Intoxicated (DWI) or Driving Under the Influence (DUI) (DUI). [Wisconsin Alcohol Laws; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":12818,"isBookmarked":0,"aid":"12818#12820#12821#40050","text":"Operating While Intoxicated (OWI)#Driving While Intoxicated (DWI)#Driving Under the Influence (DUI)#Operating a Vehicle while Impaired (OVI)"},
{"testId":613,"qid":26670,"description":"Your driver's license will be suspended for a minimum of ______聽if you are found guilty of operating a vehicle while under the influence of alcohol or drugs (OWI) for the first time.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":1080,"qid":26670,"description":"Your driver's license will be suspended for a minimum of ______聽if you are found guilty of operating a vehicle while under the influence of alcohol or drugs (OWI) for the first time.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":613,"qid":26671,"description":"If聽found guilty of operating a vehicle while intoxicated (OWI) and this was your second such violation in the previous ten years, you will have your license revoked for at least:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":1080,"qid":26671,"description":"If聽found guilty of operating a vehicle while intoxicated (OWI) and this was your second such violation in the previous ten years, you will have your license revoked for at least:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":613,"qid":26672,"description":"Under Wisconsin's Implied Consent law, by driving on Wisconsin's public highways, you implicitly consent to :","explanation":"By driving a motor vehicle on Wisconsin's public highways, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Wisconsin's Implied Consent legislation. [Implied Consent; Alcohol Laws in Wisconsin; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#35227#35228#35436","text":"None of the above#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":1080,"qid":26672,"description":"Under Wisconsin's Implied Consent law, by driving on Wisconsin's public highways, you implicitly consent to :","explanation":"By driving a motor vehicle on Wisconsin's public highways, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Wisconsin's Implied Consent legislation. [Implied Consent; Alcohol Laws in Wisconsin; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#35227#35228#35436","text":"None of the above#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":613,"qid":26673,"description":"A person under the age of 21 can be arrested for driving with an alcohol content (AC) more than:","explanation":null,"imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"2242#5401#14075#35229","text":"0.03#0.04#0.01#Zero"},
{"testId":1080,"qid":26673,"description":"A person under the age of 21 can be arrested for driving with an alcohol content (AC) more than:","explanation":null,"imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"2242#5401#14075#35229","text":"0.03#0.04#0.01#Zero"},
{"testId":613,"qid":26674,"description":"Which of the following can damage your driving ability?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your driving abilities and result in an OWI penalty. [Other Drugs and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":1080,"qid":26674,"description":"Which of the following can damage your driving ability?","explanation":"Illegal substances, prescription pharmaceuticals, over-the-counter medications, and, of course, alcohol may all impair your driving abilities and result in an OWI penalty. [Other Drugs and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#8070#9803#29384","text":"All of the above#Illegal drugs#Over-the-counter medications#Prescription medications"},
{"testId":613,"qid":26675,"description":"You must be at least _____ years old to apply for a Wisconsin instruction permit.","explanation":"You must be at least 15 years, 6 months old and complete several additional conditions to receive a Wisconsin teaching permit. [The Instruction Permit; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":43758,"isBookmarked":0,"aid":"5447#11361#43757#43758","text":"15#16#14陆#15陆"},
{"testId":613,"qid":26676,"description":"During the time that you possess a learner's permit, you must have a licensed driver with two years of experience oversee your driving.","explanation":"While you have an instruction permit, you must drive under the supervision of a licensed driver with at least two years of driving experience who is one of the following: a trained driving teacher who is at least 19 years old; a parent, guardian, or spouse who is at least 19 years old; or another person who is at least 21 years old. (If you are under the age of 18, your parent or guardian must name this other person in writing.) To monitor your driving, this individual must sit in the front passenger seat. [Instruction Permit Restrictions; The Instruction Permit; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#43759#43760#43761","text":"Any of the above#a qualified driving instructor aged 19 or older#a parent, guardian, or spouse aged 19 or older#a person aged 21 or older"},
{"testId":613,"qid":26677,"description":"You have _____ to notify the DMV of a change of address.","explanation":"If you change your residence, you must tell the DMV within ten days of the change. To send you your renewal notice and other information, the DMV must have your current address on file. You may tell the DMV through email, phone, or letter. [Address Change; Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":1080,"qid":26677,"description":"You have _____ to notify the DMV of a change of address.","explanation":"If you change your residence, you must tell the DMV within ten days of the change. To send you your renewal notice and other information, the DMV must have your current address on file. You may tell the DMV through email, phone, or letter. [Address Change; Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":613,"qid":26678,"description":"If you hit a deer, you have the right to keep it after you:","explanation":"If you hit a deer, you have the right to keep it. However, the deer must first be tagged by law enforcement. To receive the tag, contact the Sheriff's Office or the local police department. If you do not want the deer, the next driver on the scene may do so using this approach. [What to Do If You Hit a Deer? ; What to Do; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":43764,"isBookmarked":0,"aid":"43762#43763#43764#43765","text":"make sure the animal is clean#take the animal to a veterinarian#obtain a tag from law enforcement#put it out of its misery"},
{"testId":613,"qid":26679,"description":"If you must file an accident report, you must do so within ______  of the incident.","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. If law enforcement does not investigate the accident or make a report, you must do so yourself within ten days. The report may be filed online at the Wisconsin Department of Transportation's website. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#12360#12361","text":"10 days#30 days#7 days#3 days"},
{"testId":1080,"qid":26679,"description":"If you must file an accident report, you must do so within ______  of the incident.","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. If law enforcement does not investigate the accident or make a report, you must do so yourself within ten days. The report may be filed online at the Wisconsin Department of Transportation's website. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#12360#12361","text":"10 days#30 days#7 days#3 days"},
{"testId":613,"qid":26680,"description":"You have to tell someone about an accident that causes property damage of at least ____","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"202006141611292838.jpg","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#11832","text":"$500#$1,000#$2,000#$100"},
{"testId":613,"qid":26682,"description":"What should you do if you hit a parked empty vehicle?","explanation":null,"imageName":"","correctAnswer":43377,"isBookmarked":0,"aid":"26686#43377#43766#43767","text":"Call the police#Leave a note identifying yourself#Wait for its driver to return#Have it towed and repaired"},
{"testId":1080,"qid":26682,"description":"What should you do if you hit a parked empty vehicle?","explanation":null,"imageName":"","correctAnswer":43377,"isBookmarked":0,"aid":"26686#43377#43766#43767","text":"Call the police#Leave a note identifying yourself#Wait for its driver to return#Have it towed and repaired"},
{"testId":613,"qid":26683,"description":"It is illegal in Wisconsin for anyone over the age of 21 to drive with an alcohol content (AC) of ____ or higher.","explanation":"Driving with an alcohol content (AC) of 0.08 or above is banned in Wisconsin for anybody over the age of 21. (This is also the legal limit in most other US states.) In Wisconsin, it is also prohibited for anybody under the age of 21 to drive with an AC larger than zero. [Wisconsin Department of Transportation, \"Drunk Driving Law,\" https://wisconsindot.gov/Pages/safety/education/drunk-drv/ddlaw.aspx ]","imageName":"","correctAnswer":2243,"isBookmarked":0,"aid":"2243#2245#5401#5402","text":"0.08#0.02#0.04#0.06"},
{"testId":1080,"qid":26683,"description":"It is illegal in Wisconsin for anyone over the age of 21 to drive with an alcohol content (AC) of ____ or higher.","explanation":"Driving with an alcohol content (AC) of 0.08 or above is banned in Wisconsin for anybody over the age of 21. (This is also the legal limit in most other US states.) In Wisconsin, it is also prohibited for anybody under the age of 21 to drive with an AC larger than zero. [Wisconsin Department of Transportation, \"Drunk Driving Law,\" https://wisconsindot.gov/Pages/safety/education/drunk-drv/ddlaw.aspx ]","imageName":"","correctAnswer":2243,"isBookmarked":0,"aid":"2243#2245#5401#5402","text":"0.08#0.02#0.04#0.06"},
{"testId":613,"qid":26684,"description":"Your accident risk increases by a factor of _______ if your blood alcohol content (BAC) is 0.06.","explanation":"Your odds of getting involved in an accident are eight times higher if your alcohol content (AC) is 0.06. (It should be noted that an AC of 0.06 is less than the legal limit of less than 0.08.) An AC of 0.08 increases the likelihood of an accident by sixteen times. Every 0.02 rise in AC roughly doubles the chance of a catastrophic collision. [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":613,"qid":26686,"description":"Every ____ rise in blood alcohol concentration(BAC) almost doubles the probability of a fatal accident.","explanation":"Every 0.02 rise in alcohol content (AC) approximately doubles the chance of a fatal accident. [Alcohol Content; Drinking and Driving; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":2245,"isBookmarked":0,"aid":"2244#2245#5401#14075","text":"0.05#0.02#0.04#0.01"},
{"testId":613,"qid":26687,"description":"Which of the following punishments could be imposed on you if you are found guilty of operating a vehicle while under the influence of alcohol (OWI)?","explanation":"You will be penalized and your license will be canceled if you are convicted of OWI. If this is your second violation in the previous 10 years, you will be sentenced to prison and ordered to participate in a sobriety program or have an ignition interlock device (IID) put in every car you drive. If your alcohol concentration (AC) exceeds a predetermined level, the IID will prohibit your car from starting. [\"OWI and Related Alcohol and Drug Penalties\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/safety/education/drunk-drv/owi-penchrt], [\"OWI and Related Alcohol and Drug Penalties\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/safety/education/drunk-drv/owi-penchrt .pdf]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12377#18218#23188","text":"All of the above#License revocation#Jail#Fines"},
{"testId":1080,"qid":26687,"description":"Which of the following punishments could be imposed on you if you are found guilty of operating a vehicle while under the influence of alcohol (OWI)?","explanation":"You will be penalized and your license will be canceled if you are convicted of OWI. If this is your second violation in the previous 10 years, you will be sentenced to prison and ordered to participate in a sobriety program or have an ignition interlock device (IID) put in every car you drive. If your alcohol concentration (AC) exceeds a predetermined level, the IID will prohibit your car from starting. [\"OWI and Related Alcohol and Drug Penalties\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/safety/education/drunk-drv/owi-penchrt], [\"OWI and Related Alcohol and Drug Penalties\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/safety/education/drunk-drv/owi-penchrt .pdf]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12377#18218#23188","text":"All of the above#License revocation#Jail#Fines"},
{"testId":613,"qid":26688,"description":"If a law enforcement officer requests that you submit to drug or alcohol testing and you reject, your license will be revoked for ______ if this is your first refusal.","explanation":"By driving a motor vehicle on Wisconsin's public highways, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Wisconsin's Implied Consent legislation. If this is your first infraction, your license will be canceled for a year if you refuse. If you commit a second violation within ten years, your license will be revoked for two years, and a third offense would result in a three-year suspension. [Implied Consent; Alcohol Laws in Wisconsin; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1080,"qid":26688,"description":"If a law enforcement officer requests that you submit to drug or alcohol testing and you reject, your license will be revoked for ______ if this is your first refusal.","explanation":"By driving a motor vehicle on Wisconsin's public highways, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Wisconsin's Implied Consent legislation. If this is your first infraction, your license will be canceled for a year if you refuse. If you commit a second violation within ten years, your license will be revoked for two years, and a third offense would result in a three-year suspension. [Implied Consent; Alcohol Laws in Wisconsin; Drinking and Driving; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":613,"qid":26689,"description":"If you are found guilty of using a handheld cell phone while driving in a work zone with personnel present, you might face a fine of up to _____ if it is your first violation.","explanation":null,"imageName":"","correctAnswer":12393,"isBookmarked":0,"aid":"11832#12358#12393#43768","text":"$100#$200#$40#$80"},
{"testId":1080,"qid":26689,"description":"If you are found guilty of using a handheld cell phone while driving in a work zone with personnel present, you might face a fine of up to _____ if it is your first violation.","explanation":null,"imageName":"","correctAnswer":12393,"isBookmarked":0,"aid":"11832#12358#12393#43768","text":"$100#$200#$40#$80"},
{"testId":613,"qid":26690,"description":"If you are found guilty of transmitting text messages (texting) on a handheld cell phone while driving, you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"24977#24978#24994#31696","text":"$200#$100#$50#$400"},
{"testId":613,"qid":26691,"description":"For breaching state's Move Over or Slow Down legislation, you might face a fine of up to ______.","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"24977#24978#31696#33102","text":"$200#$100#$400#$300"},
{"testId":1080,"qid":26691,"description":"For breaching state's Move Over or Slow Down legislation, you might face a fine of up to ______.","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"24977#24978#31696#33102","text":"$200#$100#$400#$300"},
{"testId":613,"qid":26692,"description":"You are transporting a 16-year-old passenger in your vehicle who is not wearing a seat belt. Who can face a fine for this?","explanation":null,"imageName":"","correctAnswer":40929,"isBookmarked":0,"aid":"9642#34753#40929#40930","text":"You#The passenger#Both you and the passenger#Neither you nor the passenger"},
{"testId":613,"qid":26693,"description":"If you have probationary license and are caught driving without a seat belt, the restrictions on your license will be extended for an extra _____.","explanation":"Driving is limited in various ways when you have a probationary license. If you break any of the limitations or are convicted of any moving traffic offence (including failing to wear a seat belt), the restrictions will be extended for another six months. [Probationary License Restrictions; The Probationary License; Wisconsin Department of Transportation Motorists' Handbook], [Safety Belts and Child Restraints; Before You Drive; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":613,"qid":26694,"description":"Every child passenger must be strapped in a child safety seat until the age of:","explanation":"Every kid passenger must be properly secured in a child safety seat until the age of four, and then in a booster seat until the age of eight. [Before You Drive; Before You Drive; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":30357,"isBookmarked":0,"aid":"30357#30358#34153#34154","text":"4#5#2#3"},
{"testId":613,"qid":26695,"description":"You can get your licence back after the _____ period is over if you pay a reinstatement fee.聽:","explanation":"If your license has been suspended, you may get it restored by paying the proper reinstatement fee after the suspension time has expired. If your license has been revoked, you must not only pay the proper reinstatement fee, but you must also produce evidence of identification and submit proof of financial responsibility with the DMV before your license may be restored. [Maintenance of a Driver's License; Reinstatement of a Revoked or Suspended License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":11739,"isBookmarked":0,"aid":"11739#11740#11741#20569","text":"suspension#revocation#cancellation#disqualification"},
{"testId":1080,"qid":26695,"description":"You can get your licence back after the _____ period is over if you pay a reinstatement fee.聽:","explanation":"If your license has been suspended, you may get it restored by paying the proper reinstatement fee after the suspension time has expired. If your license has been revoked, you must not only pay the proper reinstatement fee, but you must also produce evidence of identification and submit proof of financial responsibility with the DMV before your license may be restored. [Maintenance of a Driver's License; Reinstatement of a Revoked or Suspended License; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":11739,"isBookmarked":0,"aid":"11739#11740#11741#20569","text":"suspension#revocation#cancellation#disqualification"},
{"testId":614,"qid":26696,"description":"A driver with a BAC of _____ or above is deemed mentally and physically impaired.","explanation":"A motorist with a blood alcohol concentration (BAC) of 0.08 percent or above is deemed cognitively and physically impaired, whether or not he or she exhibits it. [Drunkenness Isn't Always Visible; Alcohol Is a Major Cause of Crashes; How Alcohol and Drugs Affect You and Your Driving Ability; Wyoming Road Rules]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":1081,"qid":26696,"description":"A driver with a BAC of _____ or above is deemed mentally and physically impaired.","explanation":"A motorist with a blood alcohol concentration (BAC) of 0.08 percent or above is deemed cognitively and physically impaired, whether or not he or she exhibits it. [Drunkenness Isn't Always Visible; Alcohol Is a Major Cause of Crashes; How Alcohol and Drugs Affect You and Your Driving Ability; Wyoming Road Rules]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4252#4653","text":"0.08%#0.07%#0.05%#0.01%"},
{"testId":614,"qid":26697,"description":"If you're arrested for DWUI, your license is automatically suspended regardless of:","explanation":null,"imageName":"","correctAnswer":35224,"isBookmarked":0,"aid":"8615#35224#35225#35226","text":"All of the above#the court's decision#the type of your vehicle#your age"},
{"testId":1081,"qid":26697,"description":"If you're arrested for DWUI, your license is automatically suspended regardless of:","explanation":null,"imageName":"","correctAnswer":35224,"isBookmarked":0,"aid":"8615#35224#35225#35226","text":"All of the above#the court's decision#the type of your vehicle#your age"},
{"testId":614,"qid":26698,"description":"What is the official Wyoming phrase for drunk driving offense?","explanation":null,"imageName":"","correctAnswer":14071,"isBookmarked":0,"aid":"12818#12821#12966#14071","text":"Operating While Intoxicated (OWI)#Driving Under the Influence (DUI)#Operating Under the Influence (OUI)#Driving While Under the Influence (DWUI)"},
{"testId":1081,"qid":26698,"description":"What is the official Wyoming phrase for drunk driving offense?","explanation":null,"imageName":"","correctAnswer":14071,"isBookmarked":0,"aid":"12818#12821#12966#14071","text":"Operating While Intoxicated (OWI)#Driving Under the Influence (DUI)#Operating Under the Influence (OUI)#Driving While Under the Influence (DWUI)"},
{"testId":614,"qid":26699,"description":"For how many days an Administrative Per Se suspension for a DWUI charge is valid?","explanation":"A DWUI charge-related administrative per se suspension is valid for 90 days. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1081,"qid":26699,"description":"For how many days an Administrative Per Se suspension for a DWUI charge is valid?","explanation":"A DWUI charge-related administrative per se suspension is valid for 90 days. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":614,"qid":26700,"description":"An Administrative Per Se suspension will commence ___ days following the driver's arrest.","explanation":"After the driver is arrested, an administrative per se suspension will start 30 days later. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6388#6477","text":"10#90#2#30"},
{"testId":1081,"qid":26700,"description":"An Administrative Per Se suspension will commence ___ days following the driver's arrest.","explanation":"After the driver is arrested, an administrative per se suspension will start 30 days later. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6388#6477","text":"10#90#2#30"},
{"testId":614,"qid":26701,"description":"When a driver is arrested for DWUI, the officer will give the driver a notice of suspension and a temporary license good for ___ days.","explanation":"When a police arrests a motorist for DWUI, the officer will give the driver a notice of suspension and a 30-day temporary license. The Administrative Per Se suspension will commence after 30 days. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6389#6477#7559","text":"10#3#30#7"},
{"testId":1081,"qid":26701,"description":"When a driver is arrested for DWUI, the officer will give the driver a notice of suspension and a temporary license good for ___ days.","explanation":"When a police arrests a motorist for DWUI, the officer will give the driver a notice of suspension and a 30-day temporary license. The Administrative Per Se suspension will commence after 30 days. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6389#6477#7559","text":"10#3#30#7"},
{"testId":614,"qid":26702,"description":"If your license is suspended for DWUI, you may receive limited driving privileges if it's your first offense and you haven't had a probationary license in ______ years.","explanation":"If it is your first offense and you have not had a probationary license in the past five years, you may be eligible for restricted driving privileges if your license is suspended for DWUI. [Driving While Intoxicated; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1081,"qid":26702,"description":"If your license is suspended for DWUI, you may receive limited driving privileges if it's your first offense and you haven't had a probationary license in ______ years.","explanation":"If it is your first offense and you have not had a probationary license in the past five years, you may be eligible for restricted driving privileges if your license is suspended for DWUI. [Driving While Intoxicated; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":614,"qid":26703,"description":"By driving on public highways in Wyoming, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"8615#25218#35227#35228","text":"All of the above#chemical testing for alcohol and drugs#release of your medical records#a search of your vehicle"},
{"testId":1081,"qid":26703,"description":"By driving on public highways in Wyoming, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"8615#25218#35227#35228","text":"All of the above#chemical testing for alcohol and drugs#release of your medical records#a search of your vehicle"},
{"testId":614,"qid":26704,"description":"In Wyoming, you are required to obtain at least _______ in bodily injury or death coverage per accident for every motor vehicle you operate.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#12047#12048#12194","text":"$10,000#$15,000#$25,000#$40,000"},
{"testId":614,"qid":26705,"description":"In Wyoming, you are required to carry a minimum of _______ bodily injury or death coverage per accident for every motor vehicle you operate.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12194#12925","text":"$50,000#$30,000#$40,000#$60,000"},
{"testId":614,"qid":26706,"description":"In Wyoming, a motorist with a blood alcohol content (BAC) as low as _____ may be convicted of driving while under the influence (DWUI) if additional evidence is present.","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"4252#12625#35229#35230","text":"0.05%#0.005%#Zero#0.0005%"},
{"testId":1081,"qid":26706,"description":"In Wyoming, a motorist with a blood alcohol content (BAC) as low as _____ may be convicted of driving while under the influence (DWUI) if additional evidence is present.","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"4252#12625#35229#35230","text":"0.05%#0.005%#Zero#0.0005%"},
{"testId":614,"qid":26707,"description":"First-time DWUI convictions result in聽license suspension for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":614,"qid":26709,"description":"If you are convicted of DWUI violation for the second time, your license will be revoked for:","explanation":"Your driving license will be suspended for one year if you are convicted of a second DWUI conviction. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24049#26672","text":"6 months#1 year#90 days#5 years"},
{"testId":614,"qid":26711,"description":"Following a ______ or subsequent DWI/DUI conviction and revocation period, an alcohol or drug evaluation may be necessary to obtain a license.","explanation":"After a third or subsequent DWUI conviction and ensuing licence revocation term, an alcohol or drug assessment may be necessary in order to get a licence. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1081,"qid":26711,"description":"Following a ______ or subsequent DWI/DUI conviction and revocation period, an alcohol or drug evaluation may be necessary to obtain a license.","explanation":"After a third or subsequent DWUI conviction and ensuing licence revocation term, an alcohol or drug assessment may be necessary in order to get a licence. Losing the right to drive; Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Wyoming Traffic Laws","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":614,"qid":26712,"description":"If a driver under the age of 21 is found to be driving with a BAC of _____ or more, his or her license will be suspended for DWUI.","explanation":"If a motorist under the age of 21 is discovered to have a BAC of 0.02 percent or above while driving, his or her license will be suspended for DWUI. (Note: A 180-pound guy will have a BAC of 0.02 percent after consuming only one 12-ounce can of beer before his body begins to break down the alcohol.) [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#25888#35229","text":"0.02%#0.01%#0.002%#Zero"},
{"testId":1081,"qid":26712,"description":"If a driver under the age of 21 is found to be driving with a BAC of _____ or more, his or her license will be suspended for DWUI.","explanation":"If a motorist under the age of 21 is discovered to have a BAC of 0.02 percent or above while driving, his or her license will be suspended for DWUI. (Note: A 180-pound guy will have a BAC of 0.02 percent after consuming only one 12-ounce can of beer before his body begins to break down the alcohol.) [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#25888#35229","text":"0.02%#0.01%#0.002%#Zero"},
{"testId":614,"qid":26713,"description":"For how many days will a driver's license be suspended after a first DWUI offense by any driver under the age of 21?","explanation":"A first DWUI violation for a motorist under the age of 21 will result in a 90-day license suspension. [First Offense; Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1081,"qid":26713,"description":"For how many days will a driver's license be suspended after a first DWUI offense by any driver under the age of 21?","explanation":"A first DWUI violation for a motorist under the age of 21 will result in a 90-day license suspension. [First Offense; Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":614,"qid":26718,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the first time.","explanation":"A first offense of reckless driving will result in a 90-day license suspension. [Reckless Driving Suspension; Other Types of Suspension; Losing the Right to Drive; Wyoming Rules of the Road]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#24048#24049#34746","text":"10 days#60 days#90 days#3 days"},
{"testId":1081,"qid":26718,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the first time.","explanation":"A first offense of reckless driving will result in a 90-day license suspension. [Reckless Driving Suspension; Other Types of Suspension; Losing the Right to Drive; Wyoming Rules of the Road]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#24048#24049#34746","text":"10 days#60 days#90 days#3 days"},
{"testId":614,"qid":26720,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the third time.","explanation":"A third or subsequent reckless driving conviction within five years will result in a one-year license revocation (not suspension). [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":1081,"qid":26720,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the third time.","explanation":"A third or subsequent reckless driving conviction within five years will result in a one-year license revocation (not suspension). [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":614,"qid":26721,"description":"In the event that you are found guilty of driving recklessly and this was your ______ offense, you will be asked to complete a driving skills test once again.","explanation":"If your license is revoked for the third time for reckless driving, you must retake a driving skills exam before you may reapply. [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1081,"qid":26721,"description":"In the event that you are found guilty of driving recklessly and this was your ______ offense, you will be asked to complete a driving skills test once again.","explanation":"If your license is revoked for the third time for reckless driving, you must retake a driving skills exam before you may reapply. [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":614,"qid":26722,"description":"A first DWUI conviction can result in a fine of:","explanation":null,"imageName":"","correctAnswer":29301,"isBookmarked":0,"aid":"23887#23888#29301#33103","text":"$500#$600#$750#$350"},
{"testId":1081,"qid":26722,"description":"A first DWUI conviction can result in a fine of:","explanation":null,"imageName":"","correctAnswer":29301,"isBookmarked":0,"aid":"23887#23888#29301#33103","text":"$500#$600#$750#$350"},
{"testId":614,"qid":26723,"description":"If you are found guilty of selling or giving alcohol to someone who is under the age of 21, your driver's license will be revoked for:","explanation":"A conviction for giving alcohol to a person under the age of 21 will result in a one-year license suspension. [Transporting Liquor to a Minor Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1081,"qid":26723,"description":"If you are found guilty of selling or giving alcohol to someone who is under the age of 21, your driver's license will be revoked for:","explanation":"A conviction for giving alcohol to a person under the age of 21 will result in a one-year license suspension. [Transporting Liquor to a Minor Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":614,"qid":26724,"description":"If you do not pay any outstanding traffic citations, your license will be suspended:","explanation":"If you have outstanding traffic tickets that you have not paid, your license will be stopped until you have satisfied all of the requirements of all of the citations. This may involve paying all penalties. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35231,"isBookmarked":0,"aid":"11612#23185#25859#35231","text":"for 6 months#for 30 days#for 90 days#until you have satisfactorily addressed the citations"},
{"testId":1081,"qid":26724,"description":"If you do not pay any outstanding traffic citations, your license will be suspended:","explanation":"If you have outstanding traffic tickets that you have not paid, your license will be stopped until you have satisfied all of the requirements of all of the citations. This may involve paying all penalties. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35231,"isBookmarked":0,"aid":"11612#23185#25859#35231","text":"for 6 months#for 30 days#for 90 days#until you have satisfactorily addressed the citations"},
{"testId":614,"qid":26725,"description":"You can have up to _____ moving offenses in a year without having your license suspended.","explanation":"In Wyoming, you may have up to three traffic offenses in a year without having your license suspended. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":1081,"qid":26725,"description":"You can have up to _____ moving offenses in a year without having your license suspended.","explanation":"In Wyoming, you may have up to three traffic offenses in a year without having your license suspended. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#7559","text":"5#2#3#7"},
{"testId":614,"qid":26726,"description":"Four moving violations in a year will result in a license suspension for聽______days.","explanation":"Four moving infractions during a twelve-month period will result in a 90-day license suspension. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1081,"qid":26726,"description":"Four moving violations in a year will result in a license suspension for聽______days.","explanation":"Four moving infractions during a twelve-month period will result in a 90-day license suspension. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":614,"qid":26727,"description":"After the fourth moving violation within a twelve-month period, any subsequent moving violation within a twelve-month period shall result in an extra license suspension for ___ days.","explanation":"Following the fourth moving offense during a twelve-month period, any subsequent infraction within a twelve-month period will result in an extra 90-day license suspension. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#5447#6355#6477","text":"10#15#90#30"},
{"testId":1081,"qid":26727,"description":"After the fourth moving violation within a twelve-month period, any subsequent moving violation within a twelve-month period shall result in an extra license suspension for ___ days.","explanation":"Following the fourth moving offense during a twelve-month period, any subsequent infraction within a twelve-month period will result in an extra 90-day license suspension. [Moving Violation Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#5447#6355#6477","text":"10#15#90#30"},
{"testId":614,"qid":26728,"description":"If convicted of driving without liability insurance, your driver's license will be suspended:","explanation":"If you are convicted of driving without liability insurance, your license will be revoked until you contact an insurer and have them submit an SR-22 financial responsibility form with the Wyoming Department of Transportation on your behalf. [Compulsory Insurance Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35232,"isBookmarked":0,"aid":"11612#23185#25859#35232","text":"for 6 months#for 30 days#for 90 days#until your insurer has filed an SR-22 insurance form on your behalf"},
{"testId":1081,"qid":26728,"description":"If convicted of driving without liability insurance, your driver's license will be suspended:","explanation":"If you are convicted of driving without liability insurance, your license will be revoked until you contact an insurer and have them submit an SR-22 financial responsibility form with the Wyoming Department of Transportation on your behalf. [Compulsory Insurance Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35232,"isBookmarked":0,"aid":"11612#23185#25859#35232","text":"for 6 months#for 30 days#for 90 days#until your insurer has filed an SR-22 insurance form on your behalf"},
{"testId":614,"qid":26729,"description":"If you have no insurance and are involved in an accident, you can have your license reinstated if you pay a cash deposit for the total value of property damage plus _______ per injury.","explanation":"Provided you have no insurance and are involved in an accident, you may have your license restored if you submit a cash deposit for the entire amount of property damage plus $25,000 per injury. If there have been no judgements against you in the meantime, your monetary deposit may be repaid to you one year after the accident. [Uninsured Accident Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"202006141611292838.jpg","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11658#12047#12048","text":"$5,000#$10,000#$15,000#$25,000"},
{"testId":614,"qid":26730,"description":"If you are found guilty of allowing another person to use your driver's license, your privilege to drive could be revoked for a period of __ days.","explanation":"If you are guilty of allowing someone else to use your driver's license, you may have your license suspended for 90 days. [Unlawful Use & False Application Suspension and/or Cancellation; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1081,"qid":26730,"description":"If you are found guilty of allowing another person to use your driver's license, your privilege to drive could be revoked for a period of __ days.","explanation":"If you are guilty of allowing someone else to use your driver's license, you may have your license suspended for 90 days. [Unlawful Use & False Application Suspension and/or Cancellation; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":614,"qid":26731,"description":"Your license will be suspended ____________ if you fail to pay $5,000 or more in court-ordered child support.","explanation":"If you fail to pay $5,000 or more in court-ordered child support for 90 days in a row, your license will be stopped until you pay in full or establish a payment plan that has been authorized. A $5 license reinstatement fee is also required. [Child Support Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35233,"isBookmarked":0,"aid":"11612#11613#25104#35233","text":"for 6 months#for 1 year#for 2 years#until you pay or arrange to pay"},
{"testId":1081,"qid":26731,"description":"Your license will be suspended ____________ if you fail to pay $5,000 or more in court-ordered child support.","explanation":"If you fail to pay $5,000 or more in court-ordered child support for 90 days in a row, your license will be stopped until you pay in full or establish a payment plan that has been authorized. A $5 license reinstatement fee is also required. [Child Support Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35233,"isBookmarked":0,"aid":"11612#11613#25104#35233","text":"for 6 months#for 1 year#for 2 years#until you pay or arrange to pay"},
{"testId":614,"qid":26733,"description":"To dispute your license suspension, you may submit a request for a Contested Case Hearing accompanied with a ____ processing fee.","explanation":"To dispute your license suspension, submit a request for a Contested Case Hearing along with a $25 processing fee. [Suspension-Related Issues; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":11348,"isBookmarked":0,"aid":"11348#11349#11351#11832","text":"$25#$50#$10#$100"},
{"testId":1081,"qid":26733,"description":"To dispute your license suspension, you may submit a request for a Contested Case Hearing accompanied with a ____ processing fee.","explanation":"To dispute your license suspension, submit a request for a Contested Case Hearing along with a $25 processing fee. [Suspension-Related Issues; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":11348,"isBookmarked":0,"aid":"11348#11349#11351#11832","text":"$25#$50#$10#$100"},
{"testId":614,"qid":26734,"description":"Typically, the license reinstatement fee is:","explanation":"Typically, the license reinstatement charge is $50. The license reinstatement charge for a child support suspension, on the other hand, is just $5. [License Reinstatement Fee; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24994#27902#31885","text":"$100#$50#$75#$85"},
{"testId":1081,"qid":26734,"description":"Typically, the license reinstatement fee is:","explanation":"Typically, the license reinstatement charge is $50. The license reinstatement charge for a child support suspension, on the other hand, is just $5. [License Reinstatement Fee; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24994#27902#31885","text":"$100#$50#$75#$85"},
{"testId":614,"qid":26735,"description":"For a child support suspension, the license reinstatement fee is:","explanation":"For a child support suspension, the license reinstatement cost is $5. This is much less than the standard license reinstatement charge of $50. [License Reinstatement Fee; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35234,"isBookmarked":0,"aid":"24978#24993#24994#35234","text":"$100#$25#$50#$5"},
{"testId":1081,"qid":26735,"description":"For a child support suspension, the license reinstatement fee is:","explanation":"For a child support suspension, the license reinstatement cost is $5. This is much less than the standard license reinstatement charge of $50. [License Reinstatement Fee; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":35234,"isBookmarked":0,"aid":"24978#24993#24994#35234","text":"$100#$25#$50#$5"},
{"testId":614,"qid":26736,"description":"Which of the following convictions in Wyoming will result in the cancellation of a driver's license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#27916#35235#35236","text":"Any of the above#Vehicular homicide#A felony resulting from driving#Leaving the scene of an accident involving injury"},
{"testId":1081,"qid":26736,"description":"Which of the following convictions in Wyoming will result in the cancellation of a driver's license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#27916#35235#35236","text":"Any of the above#Vehicular homicide#A felony resulting from driving#Leaving the scene of an accident involving injury"},
{"testId":614,"qid":26737,"description":"First-time DWUI offenders with a BAC of 0.15 or higher must install an ignition interlock device (IID) for:","explanation":"If you are convicted of DWUI for the first time and your BAC was 0.15 percent or above, you will be compelled to install an ignition interlock device (IID) for six months. If your BAC exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1081,"qid":26737,"description":"First-time DWUI offenders with a BAC of 0.15 or higher must install an ignition interlock device (IID) for:","explanation":"If you are convicted of DWUI for the first time and your BAC was 0.15 percent or above, you will be compelled to install an ignition interlock device (IID) for six months. If your BAC exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":614,"qid":26738,"description":"Second-time DWUI offenders must install an ignition interlock device for:","explanation":"If you are convicted of DWUI for the second time, you must install an ignition interlock device (IID) for a year. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1081,"qid":26738,"description":"Second-time DWUI offenders must install an ignition interlock device for:","explanation":"If you are convicted of DWUI for the second time, you must install an ignition interlock device (IID) for a year. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":614,"qid":26740,"description":"Fourth-time DWUI offenders must install an ignition interlock device for:","explanation":"If the motorist is convicted of a fourth or subsequent DWUI, he or she will be obliged to install an ignition interlock device (IID) for the remainder of his or her life. If his or her blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit his or her car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"26672#28011#29895#32620","text":"5 years#15 years#10 years#Life"},
{"testId":1081,"qid":26740,"description":"Fourth-time DWUI offenders must install an ignition interlock device for:","explanation":"If the motorist is convicted of a fourth or subsequent DWUI, he or she will be obliged to install an ignition interlock device (IID) for the remainder of his or her life. If his or her blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit his or her car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"26672#28011#29895#32620","text":"5 years#15 years#10 years#Life"},
{"testId":614,"qid":26741,"description":"Which vehicle occupants in Wyoming are required to use seat belts or appropriate child restraints?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#35237","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and passengers under 18"},
{"testId":614,"qid":26742,"description":"Every Wyoming child passenger under the age of _____ must use an appropriate child safety restraint device.聽:","explanation":"Wyoming law mandates that all children under the age of nine be properly secured in an authorized child safety restraint system. [Child Restraints; Seat Belt Law; Safety Laws/Issues; Wyoming Road Rules]","imageName":"","correctAnswer":11342,"isBookmarked":0,"aid":"5446#6478#11342#11343","text":"10#12#9#11"},
{"testId":614,"qid":26743,"description":"If it's your first offense, you can be fined _____聽for failing to properly restrain a child under 9 years old.","explanation":"If you are guilty of failing to properly secure a child passenger under the age of nine in a child safety restraint device on your first violation, you will be fined up to $60. If this is your second or subsequent infraction, you will be penalized up to $110. If you show the court that you have secured an appropriate child safety restraint device for a first infraction, the court will waive the fine. [Child Restraints; Seat Belt Law; Safety Laws/Issues; Wyoming Road Rules]","imageName":"","correctAnswer":12394,"isBookmarked":0,"aid":"290#11348#11832#12394","text":"$500#$25#$100#$60"},
{"testId":614,"qid":26744,"description":"A first DWUI conviction can result in up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25170#35238","text":"3 months#6 months#1 month#1 week"},
{"testId":1081,"qid":26744,"description":"A first DWUI conviction can result in up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25170#35238","text":"3 months#6 months#1 month#1 week"},
{"testId":614,"qid":26745,"description":"If it's your second offense, you can be fined _____聽for failing to properly restrain a child under 9 years old.","explanation":"If you are guilty of failing to properly secure a child passenger under the age of nine in a child safety restraint device on your first violation, you will be fined up to $60. If this is your second or subsequent infraction, you will be penalized up to $110. If you show the court that you have secured an appropriate child safety restraint device for a first infraction, the court will waive the fine. [Child Restraints; Seat Belt Law; Safety Laws/Issues; Wyoming Road Rules]","imageName":"","correctAnswer":35239,"isBookmarked":0,"aid":"11349#11832#13794#35239","text":"$50#$100#$55#$110"},
{"testId":604,"qid":26746,"description":"If your driving ability has been impaired by _____, you may be charged with DUI.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33479#35439#35440","text":"Any of the above#illegal drugs#prescription medications#over-the-counter cold medications"},
{"testId":604,"qid":26747,"description":"By driving on public highways in South Carolina, you expressly agree to:","explanation":"By driving a motor vehicle on South Carolina's public highways, you imply permission to chemical testing for alcohol and drugs at the request of a law enforcement officer, according to the state's Implied Consent legislation. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":604,"qid":26748,"description":"If your licence was taken away because of which of the following, you may be able to get a temporary alcohol licence (TAL)?聽:","explanation":"If your license was suspended for one of the following infractions and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). - Implied Consent - Implied Consent under the age of 21 - BAC of 0.15 percent - BAC of 0.02 percent or higher under the age of 21 [Loss of Driving Privileges; South Carolina Driver's Manual; Section 1 The Driver's License]","imageName":"","correctAnswer":41317,"isBookmarked":0,"aid":"37206#41317#44829#44830","text":"Both (a) and (b)#Either (a) or (b)#(a) Implied Consent#(b) Blood Alcohol Concentration (BAC) of 0.15%"},
{"testId":1071,"qid":26748,"description":"If your licence was taken away because of which of the following, you may be able to get a temporary alcohol licence (TAL)?聽:","explanation":"If your license was suspended for one of the following infractions and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). - Implied Consent - Implied Consent under the age of 21 - BAC of 0.15 percent - BAC of 0.02 percent or higher under the age of 21 [Loss of Driving Privileges; South Carolina Driver's Manual; Section 1 The Driver's License]","imageName":"","correctAnswer":41317,"isBookmarked":0,"aid":"37206#41317#44829#44830","text":"Both (a) and (b)#Either (a) or (b)#(a) Implied Consent#(b) Blood Alcohol Concentration (BAC) of 0.15%"},
{"testId":604,"qid":26749,"description":"If you have a blood alcohol content (BAC) of _____ or above, it can be concluded that you are under the influence of alcohol.","explanation":"If you have a blood alcohol content (BAC) of 0.08 percent or greater, it is possible that you are under the influence of alcohol. If this is the case, you may be prosecuted with driving under the influence (DUI). [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#4653","text":"0.08%#0.04%#0.02%#0.01%"},
{"testId":1071,"qid":26749,"description":"If you have a blood alcohol content (BAC) of _____ or above, it can be concluded that you are under the influence of alcohol.","explanation":"If you have a blood alcohol content (BAC) of 0.08 percent or greater, it is possible that you are under the influence of alcohol. If this is the case, you may be prosecuted with driving under the influence (DUI). [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3281#4251#4653","text":"0.08%#0.04%#0.02%#0.01%"},
{"testId":604,"qid":26750,"description":"What is the definition of a Temporary Alcohol License (TAL)?","explanation":"If your license was suspended for an Implied Consent or Blood Alcohol Concentration (BAC) charge and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). You may drive with this license while you await the outcome of the hearing. A TAL costs $100 at the moment. You may apply for a Route Restricted Driver's License if the suspension is upheld. [Temporary Alcohol License; Driver's License Section 1; South Carolina Driver's Manual]","imageName":"","correctAnswer":44833,"isBookmarked":0,"aid":"44831#44832#44833#44834","text":"A license to buy alcohol#A permission to drive while impaired#A license that allows you to drive while your regular license is suspended#A license that exempts you from the Implied Consent law"},
{"testId":1071,"qid":26750,"description":"What is the definition of a Temporary Alcohol License (TAL)?","explanation":"If your license was suspended for an Implied Consent or Blood Alcohol Concentration (BAC) charge and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). You may drive with this license while you await the outcome of the hearing. A TAL costs $100 at the moment. You may apply for a Route Restricted Driver's License if the suspension is upheld. [Temporary Alcohol License; Driver's License Section 1; South Carolina Driver's Manual]","imageName":"","correctAnswer":44833,"isBookmarked":0,"aid":"44831#44832#44833#44834","text":"A license to buy alcohol#A permission to drive while impaired#A license that allows you to drive while your regular license is suspended#A license that exempts you from the Implied Consent law"},
{"testId":604,"qid":26751,"description":"A driver's license will be revoked if he or she is under the age of 21 and convicted of driving with a blood alcohol content (BAC) of ______ or more.","explanation":"If a motorist under the age of 21 is found to have a BAC of 0.02 percent or greater while driving, his or her license will be revoked for three months for the first offense and six months for a second violation within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the driver's blood alcohol concentration (BAC) was 0.08 percent or greater at the time of the violation, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#13791#35229","text":"0.02%#0.01%#0.008%#Zero"},
{"testId":604,"qid":26752,"description":"If a driver aged less than 21 is convicted of driving with a BAC above the legal limit, his or her license will be suspended for _______ for the first offense.","explanation":"If a motorist under the age of 21 is found to have a BAC of 0.02 percent or greater while driving, his or her license will be revoked for three months for the first offense and six months for a second violation within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the driver's blood alcohol concentration (BAC) was 0.08 percent or greater at the time of the violation, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1453,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":604,"qid":26753,"description":"If a driver aged less than 21 is convicted of driving with a BAC above the legal limit, his or her license will be suspended for _______ for the second offense in five years.","explanation":"If a motorist under the age of 21 is found to have a BAC of 0.02 percent or greater while driving, his or her license will be revoked for three months for the first offense and six months for a second violation within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the driver's blood alcohol concentration (BAC) was 0.08 percent or greater at the time of the violation, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":604,"qid":26754,"description":"If a motorist under the age of 21 decline to submit to chemical testing for drugs or alcohol when requested, his or her license will be suspended for a period of:","explanation":"If a driver of any age refuses to submit to chemical testing for alcohol or drugs when asked, his or her license will be suspended for six months immediately. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23883#23886#24048","text":"6 months#10 days#30 days#60 days"},
{"testId":604,"qid":26755,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. What about just strolling? The danger of getting in an accident when walking under the influence of alcohol is increased, according to research from the National Highway Traffic Safety Administration (NHTSA). [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Pedestrians,\" August 2013; available at: https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":1071,"qid":26755,"description":"Alternatives to drinking and driving is ______","explanation":"Taxis, public transit, and rides with designated drivers are all alternatives to driving after drinking. What about just strolling? The danger of getting in an accident when walking under the influence of alcohol is increased, according to research from the National Highway Traffic Safety Administration (NHTSA). [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Pedestrians,\" August 2013; available at: https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#35975#35977#39411","text":"All of the above#taking a taxi#riding with a designated driver#using public transportation"},
{"testId":604,"qid":26756,"description":"Your Temporary Alcohol Licence (TAL) is good:","explanation":"If your license was suspended for an Implied Consent or Blood Alcohol Concentration (BAC) charge and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). You may drive with this license while you await the outcome of the hearing. A TAL costs $100 at the moment. You may apply for a Route Restricted Driver's License if the suspension is upheld. [Temporary Alcohol License; Driver's License Section 1; South Carolina Driver's Manual]","imageName":"","correctAnswer":44836,"isBookmarked":0,"aid":"11613#25090#44835#44836","text":"for 1 year#for 3 months#until you pay the fine#until the SCDMV receives the results of your hearing"},
{"testId":1071,"qid":26756,"description":"Your Temporary Alcohol Licence (TAL) is good:","explanation":"If your license was suspended for an Implied Consent or Blood Alcohol Concentration (BAC) charge and you filed for an administrative hearing within 30 days, you may be eligible for a Temporary Alcohol License (TAL). You may drive with this license while you await the outcome of the hearing. A TAL costs $100 at the moment. You may apply for a Route Restricted Driver's License if the suspension is upheld. [Temporary Alcohol License; Driver's License Section 1; South Carolina Driver's Manual]","imageName":"","correctAnswer":44836,"isBookmarked":0,"aid":"11613#25090#44835#44836","text":"for 1 year#for 3 months#until you pay the fine#until the SCDMV receives the results of your hearing"},
{"testId":604,"qid":26757,"description":"If a driver under the age of 21 has a past DUI conviction and refuses to submit to a chemical test for drugs or alcohol at the request of a law enforcement official, his or her license will be suspended for:","explanation":"If a motorist under the age of 21 refuses to undergo the intoxication test and it is his or her first infraction, the driver's driving privileges will be suspended for six months. If there is a second or subsequent DUI, Felony DUI, Implied Consent, or a BAC of 0.02 percent or higher within the previous five years of the first offense, the driver's license will be suspended for one year. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23152#23154#23886#26672","text":"3 months#1 year#30 days#5 years"},
{"testId":604,"qid":26758,"description":"If you have points on your record, you can get them lowered:","explanation":"If you have points on your record, you may be able to lower them if you take an authorized Defensive Driving Course that includes eight hours of classroom instruction. After the date of violation, the course must be completed in South Carolina. A point decrease is permitted just once every three years. [South Carolina Driver's Manual; Defensive Driving Course; Loss of Driving Privileges; Section 1 The Driver's License]","imageName":"","correctAnswer":44837,"isBookmarked":0,"aid":"7361#44837#44838#44839","text":"once a year#once every three years#whenever you complete a Defensive Driving Course#when you pay the fine"},
{"testId":1071,"qid":26758,"description":"If you have points on your record, you can get them lowered:","explanation":"If you have points on your record, you may be able to lower them if you take an authorized Defensive Driving Course that includes eight hours of classroom instruction. After the date of violation, the course must be completed in South Carolina. A point decrease is permitted just once every three years. [South Carolina Driver's Manual; Defensive Driving Course; Loss of Driving Privileges; Section 1 The Driver's License]","imageName":"","correctAnswer":44837,"isBookmarked":0,"aid":"7361#44837#44838#44839","text":"once a year#once every three years#whenever you complete a Defensive Driving Course#when you pay the fine"},
{"testId":604,"qid":26759,"description":"If your South Carolina driver's license has been suspended, you must pay a reinstatement fee of :","explanation":"You may pay your South Carolina driver's license reinstatement costs online if you have one. Unless otherwise specified, each suspension requires a $100 reinstatement fee. [Reinstatement Fees; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11349#11832#11833","text":"$500#$50#$100#$300"},
{"testId":1071,"qid":26759,"description":"If your South Carolina driver's license has been suspended, you must pay a reinstatement fee of :","explanation":"You may pay your South Carolina driver's license reinstatement costs online if you have one. Unless otherwise specified, each suspension requires a $100 reinstatement fee. [Reinstatement Fees; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11349#11832#11833","text":"$500#$50#$100#$300"},
{"testId":604,"qid":26760,"description":"If you are 15 or 16 years old and have ___ points on your driving record, the SCDMV will send you a warning letter.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5446#5447#6390#6916","text":"10#15#4#6"},
{"testId":604,"qid":26761,"description":"Which of the following is NOT true about lowering the number of points on your driving record?","explanation":"If you have points on your record, you may be able to lower them if you take an authorized Defensive Driving Course that includes eight hours of classroom instruction. After the date of violation, the course must be completed in South Carolina. A point decrease may only be performed once every three years. [Defensive Driving Course; Loss of Driving Privileges; Driver's License Section 1; South Carolina Driver's Manual]","imageName":"","correctAnswer":44818,"isBookmarked":0,"aid":"44818#44819#44840#44841","text":"You may complete an online Defensive Driving Course to reduce your points#Your points may be reduced only once in a three-year period#You may complete a classroom Defensive Driving Course to reduce your points#You must complete a Defensive Driving course after the violation but before the suspension period begins"},
{"testId":604,"qid":26762,"description":"After _____ , the points on your driving record will be cut in half.","explanation":"The points on your driving record will be slashed in half after a year. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#23886#26672","text":"1 year#3 years#30 days#5 years"},
{"testId":1071,"qid":26762,"description":"After _____ , the points on your driving record will be cut in half.","explanation":"The points on your driving record will be slashed in half after a year. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23183#23886#26672","text":"1 year#3 years#30 days#5 years"},
{"testId":604,"qid":26763,"description":"If you're 17 or older and get __ points, your license will be suspended.","explanation":"If you are 17 years old or older and have six or more points on your driving record, the SCDMV will send you a letter urging you to drive more cautiously. Your license will be suspended if your point total reaches 12 or higher. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#5448#6478#6916","text":"10#20#12#6"},
{"testId":604,"qid":26764,"description":"If you are caught driving under the influence, you will face penalties.","explanation":"Certain infractions, such as driving while intoxicated, necessitate obligatory license suspensions and are not subject to the point system. [Excessive Points Suspension; Loss of Driving Privileges; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":24890,"isBookmarked":0,"aid":"24890#44842#44843#44844","text":"a mandatory license suspension#a lifetime license revocation#a warning letter#a license cancellation"},
{"testId":1071,"qid":26764,"description":"If you are caught driving under the influence, you will face penalties.","explanation":"Certain infractions, such as driving while intoxicated, necessitate obligatory license suspensions and are not subject to the point system. [Excessive Points Suspension; Loss of Driving Privileges; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":24890,"isBookmarked":0,"aid":"24890#44842#44843#44844","text":"a mandatory license suspension#a lifetime license revocation#a warning letter#a license cancellation"},
{"testId":604,"qid":26765,"description":"It is against the law to stop or park your car in South Carolina within ______ of a crosswalk at an intersection.","explanation":null,"imageName":"","correctAnswer":4582,"isBookmarked":0,"aid":"1026#1027#4582#8417","text":"50 feet#25 feet#20 feet#30 feet"},
{"testId":604,"qid":26766,"description":"Once every _______, you can minimize the number of points on your driving record by taking an approved defensive driving course.","explanation":"Four points will be deducted from your driving record if you successfully complete an authorized defensive driving course. You may repeat this process as many times as you choose, but no more than once every three years. [South Carolina Driver's Manual; Defensive Driving Course; Loss of Driving Privileges; Section 1 The Driver's License]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23183#27048","text":"2 years#6 months#3 years#year"},
{"testId":604,"qid":26767,"description":"If you put a highway worker in danger, the SCDMV will send you a warning letter.","explanation":null,"imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":604,"qid":26768,"description":"If you get a ticket in another state, it won't show up on your South Carolina driving record.","explanation":"If you commit traffic offences outside of the state, you may incur points on your South Carolina driver's license. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":1071,"qid":26768,"description":"If you get a ticket in another state, it won't show up on your South Carolina driving record.","explanation":"If you commit traffic offences outside of the state, you may incur points on your South Carolina driver's license. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":604,"qid":26769,"description":"Which of the following violations will cause points to be added to your driving record in South Carolina?","explanation":"If you commit traffic offences outside of the state, you may incur points on your South Carolina driver's license. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#44845#44846#44847","text":"All of the above#Traffic violations in South Carolina#Traffic violations in other states#Traffic violations in work zones"},
{"testId":1071,"qid":26769,"description":"Which of the following violations will cause points to be added to your driving record in South Carolina?","explanation":"If you commit traffic offences outside of the state, you may incur points on your South Carolina driver's license. [How the Point System Works; Driving Privileges Suspended; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#44845#44846#44847","text":"All of the above#Traffic violations in South Carolina#Traffic violations in other states#Traffic violations in work zones"},
{"testId":604,"qid":26770,"description":"When turning in South Carolina, you must begin signaling the turn at least ______ in advance.","explanation":"When turning in South Carolina, you must begin signaling the turn at least 100 feet in advance. You may be cited and punished if you do not comply. [Passing; General Driving; Section 7; South Carolina Driver's Manual]","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#3529#4926","text":"100 feet#50 feet#70 feet#80 feet"},
{"testId":1071,"qid":26770,"description":"When turning in South Carolina, you must begin signaling the turn at least ______ in advance.","explanation":"When turning in South Carolina, you must begin signaling the turn at least 100 feet in advance. You may be cited and punished if you do not comply. [Passing; General Driving; Section 7; South Carolina Driver's Manual]","imageName":"","correctAnswer":214,"isBookmarked":0,"aid":"214#1026#3529#4926","text":"100 feet#50 feet#70 feet#80 feet"},
{"testId":604,"qid":26771,"description":"When you are within _______ of an oncoming vehicle, you must dim your headlights.","explanation":"You must lower your headlights if you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, according to South Carolina law. [Night Driving; Special Driving Situations; Section 11; South Carolina Driver's Manual]","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#401#1296","text":"100 feet#200 feet#400 feet#500 feet"},
{"testId":1071,"qid":26771,"description":"When you are within _______ of an oncoming vehicle, you must dim your headlights.","explanation":"You must lower your headlights if you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, according to South Carolina law. [Night Driving; Special Driving Situations; Section 11; South Carolina Driver's Manual]","imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"214#399#401#1296","text":"100 feet#200 feet#400 feet#500 feet"},
{"testId":604,"qid":26772,"description":"When parallel parking next to a curb, you have to park within _____ of the curb.","explanation":"Parallel parking must be done within 18 inches of the curb in South Carolina. Parking too far from the curb might endanger oncoming cars. [Parallel Parking; General Driving Section 7; South Carolina Driver's Manual]","imageName":"","correctAnswer":4921,"isBookmarked":0,"aid":"818#4921#5730#5731","text":"12 inches#18 inches#26 inches#32 inches"},
{"testId":604,"qid":26773,"description":"It is advised that you keep a following distance of at least ______ behind the car in front of you in South Carolina.","explanation":"According to the South Carolina Driver's Manual, you should maintain a following distance of at least 4 seconds behind the vehicle in front of you. You should increase your following distance even more under difficult driving circumstances. [Space Management; Safe Driving Tips in Section 8; South Carolina Driver's Manual]","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"234#426#7321#7322","text":"Any of the above#4 seconds#2 seconds#3 seconds"},
{"testId":604,"qid":26774,"description":"Which one of the following convictions will NOT add four points to your South Carolina driving record?","explanation":null,"imageName":"","correctAnswer":40401,"isBookmarked":0,"aid":"864#40400#40401#40402","text":"Tailgating#Failing to yield the right-of-way#Driving with a broken taillight#Unlawful passing"},
{"testId":604,"qid":26775,"description":"A heavy truck's rear blind area can reach up to _______ behind the truck.","explanation":"Never tailgate a huge commercial vehicle like a truck or bus. Its blind zone in the back may stretch up to 200 feet behind it. If you are too near, the huge car's driver will be unable to see your vehicle, and you will be unable to see what is ahead of you. [Interacting with Commercial Vehicles; Sharing the Road; Section 10; South Carolina Driver's Manual]","imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"214#399#400#1296","text":"100 feet#200 feet#300 feet#500 feet"},
{"testId":604,"qid":26776,"description":"What is the minimum punishment for littering from a vehicle in South Carolina?","explanation":null,"imageName":"","correctAnswer":44851,"isBookmarked":0,"aid":"44848#44849#44850#44851","text":"A $25 fine#A $100 fine#Eight hours of community service#A $25 fine and eight hours of community service"},
{"testId":1071,"qid":26776,"description":"What is the minimum punishment for littering from a vehicle in South Carolina?","explanation":null,"imageName":"","correctAnswer":44851,"isBookmarked":0,"aid":"44848#44849#44850#44851","text":"A $25 fine#A $100 fine#Eight hours of community service#A $25 fine and eight hours of community service"},
{"testId":604,"qid":26777,"description":"When you turn 17 or have had a conditional or special restricted licence for _____, you can get a full licence with no restrictions.聽:","explanation":"If you are 17 years old or have maintained a conditional or special limited license for one year and have not committed any traffic violations or been at fault in any crashes, you will be granted full driving rights. [Requirements for Licensing; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":604,"qid":26778,"description":"Before you can apply for any form of driver's license, you must have a beginner's permit for at least:","explanation":"You cannot apply for a driver's license unless you have had a beginner's permit for at least 180 days and satisfied certain additional conditions. [Requirements for Licensing; Section 1 The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#1020#12630","text":"180 days#90 days#60 days#360 days"},
{"testId":604,"qid":26779,"description":"If you are at least ___ years old, you may be eligible for a conditional driver's license.","explanation":null,"imageName":"","correctAnswer":43758,"isBookmarked":0,"aid":"5447#11361#43758#44852","text":"15#16#15陆#16陆"},
{"testId":604,"qid":26780,"description":"First-time DUI offenders may be fined at least:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":12817,"isBookmarked":0,"aid":"1799#11833#12357#12817","text":"$1,000#$300#$150#$400"},
{"testId":1071,"qid":26780,"description":"First-time DUI offenders may be fined at least:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":12817,"isBookmarked":0,"aid":"1799#11833#12357#12817","text":"$1,000#$300#$150#$400"},
{"testId":604,"qid":26781,"description":"First-time DUI offenders may be fined up to:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12817","text":"$500#$1,000#$750#$400"},
{"testId":1071,"qid":26781,"description":"First-time DUI offenders may be fined up to:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12817","text":"$500#$1,000#$750#$400"},
{"testId":604,"qid":26782,"description":"First-time DUI offenders face up to ___聽in jail.","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#12360#18239","text":"90 days#30 days#7 days#14 days"},
{"testId":1071,"qid":26782,"description":"First-time DUI offenders face up to ___聽in jail.","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#12360#18239","text":"90 days#30 days#7 days#14 days"},
{"testId":604,"qid":26783,"description":"The penalties for driving under the influence of alcohol or illegal drugs for the first time are the same as for the first time (DUI).","explanation":"The penalties for a first conviction of illicit drug use while driving are the same as for a first offense of driving while intoxicated (DUI). That instance, if you are convicted of an illegal drug offence while driving for the first time, you might face a fine ranging from $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license will be suspended for a period of six months. Finally, you may face further criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":1071,"qid":26783,"description":"The penalties for driving under the influence of alcohol or illegal drugs for the first time are the same as for the first time (DUI).","explanation":"The penalties for a first conviction of illicit drug use while driving are the same as for a first offense of driving while intoxicated (DUI). That instance, if you are convicted of an illegal drug offence while driving for the first time, you might face a fine ranging from $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license will be suspended for a period of six months. Finally, you may face further criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":604,"qid":26784,"description":"If this is your first time being charged with a drug-related driving offense, the maximum amount of the fine you could receive is:","explanation":"If you are convicted of an illegal drug offence while driving for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license may be suspended for a period of six months. Finally, you may face additional criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#12817","text":"$500#$1,000#$100#$400"},
{"testId":1071,"qid":26784,"description":"If this is your first time being charged with a drug-related driving offense, the maximum amount of the fine you could receive is:","explanation":"If you are convicted of an illegal drug offence while driving for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license may be suspended for a period of six months. Finally, you may face additional criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11832#12817","text":"$500#$1,000#$100#$400"},
{"testId":604,"qid":26785,"description":"If it's your first drug realated聽offense, your license may be suspended for:","explanation":"If you are convicted of an illegal drug offence while driving for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license may be suspended for a period of six months. Finally, you may face additional criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11341","text":"30 days#1 year#6 months#5 years"},
{"testId":1071,"qid":26785,"description":"If it's your first drug realated聽offense, your license may be suspended for:","explanation":"If you are convicted of an illegal drug offence while driving for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Your license may be suspended for a period of six months. Finally, you may face additional criminal charges if you use or possess drugs. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#11341","text":"30 days#1 year#6 months#5 years"},
{"testId":604,"qid":26786,"description":"The penalties for a second offense of driving under the influence (DUI) are substantially higher than those for the first violation.","explanation":null,"imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":604,"qid":26787,"description":"When following a motorcycle in rainy weather, keep a following distance of at least ___ seconds.","explanation":"Maintain a following distance of at least four seconds while following a motorcycle in rainy circumstances, otherwise you may not have enough time or space to prevent a collision. [Motorcyclists; Sharing the Road; Section 10; South Carolina Driver's Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5446#6389#6390#7620","text":"10#3#4#8"},
{"testId":604,"qid":26788,"description":"Which of the following is true about using a cell phone while driving in South Carolina?","explanation":null,"imageName":"","correctAnswer":44854,"isBookmarked":0,"aid":"38428#38429#44853#44854","text":"Both (a) and (b) are true#Neither (a) nor (b) is true#(a) You may not make a call on a hand-held cell phone#(b) You may not read or send text messages on a hand-held cell phone"},
{"testId":604,"qid":26789,"description":"Children age _____ or less must be in a car seat in the backseat of the vehicle at all times.","explanation":"Children under the age of seven must ride in the back seat of a vehicle in order to prevent injuries from an air bag in the case of a collision. [South Carolina Driver's Manual; Section 4 Before You Drive; Child Passenger Safety Laws]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#7559#7620#11342","text":"10#7#8#9"},
{"testId":604,"qid":26790,"description":"If you fail your driving exam three times in a row, you must wait _______ before taking it again.","explanation":"If you fail your driving exam three times in a row, you must wait 60 days before retaking it. [Failure to Pass the Skills Test; Section 1: The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"742#1020#1453#41649","text":"30 days#60 days#3 months#2 weeks"},
{"testId":604,"qid":26791,"description":"A route-restricted driver's license enables a driver whose regular license has been suspended to travel to and from:","explanation":"A motorist whose license has been suspended may travel to and from school, employment, a court-ordered drug treatment, and an Alcohol and Drug Safety Action Program with a route-restricted driver's license (ADSAP). [South Carolina Driver's Manual; Section 1 The Driver's License; Route-Restricted Driver's License]","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#32019#32020#40409","text":"All of the above#work#school#ADSAP classes"},
{"testId":604,"qid":26792,"description":"First-time DUI offenders will have their licenses suspended for聽:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#739#742#1272","text":"90 days#10 days#30 days#6 months"},
{"testId":1071,"qid":26792,"description":"First-time DUI offenders will have their licenses suspended for聽:","explanation":"If you are convicted of driving under the influence (DUI) for the first time, you might face a fine of $400 to $1,000, plus court expenses. You might possibly face a prison term of 48 hours to 30 days. Finally, your driving privileges will be suspended for six months. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#739#742#1272","text":"90 days#10 days#30 days#6 months"},
{"testId":604,"qid":26793,"description":"Even if your South Carolina license is suspended, you can still renew it.","explanation":"If your license is suspended or is about to be suspended, it cannot be renewed. [South Carolina Driver's Manual; Section 1 The Driver's License; Driver's License Renewal]","imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":1071,"qid":26793,"description":"Even if your South Carolina license is suspended, you can still renew it.","explanation":"If your license is suspended or is about to be suspended, it cannot be renewed. [South Carolina Driver's Manual; Section 1 The Driver's License; Driver's License Renewal]","imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":604,"qid":26794,"description":"In South Carolina, you must have ________ with you at all times when driving a vehicle.","explanation":null,"imageName":"","correctAnswer":763,"isBookmarked":0,"aid":"763#11364#44855#44856","text":"all of the above#your driver's license#proof of vehicle insurance#your vehicle's registration"},
{"testId":1071,"qid":26794,"description":"In South Carolina, you must have ________ with you at all times when driving a vehicle.","explanation":null,"imageName":"","correctAnswer":763,"isBookmarked":0,"aid":"763#11364#44855#44856","text":"all of the above#your driver's license#proof of vehicle insurance#your vehicle's registration"},
{"testId":604,"qid":26795,"description":"If you fail your driving test the first or second time, you must wait _______ before retaking it.","explanation":"If you have no driving experience and fail the skills exam on your first or second try, you must wait two weeks before being retested, regardless of your age. [Failure to Pass the Skills Test; Section 1: The Driver's License; South Carolina Driver's Manual]","imageName":"","correctAnswer":41649,"isBookmarked":0,"aid":"1020#1453#12360#41649","text":"60 days#3 months#7 days#2 weeks"},
{"testId":33,"qid":26845,"description":"If you see a triangle-shaped sign on the road, you must:","explanation":"The yield sign has a triangular form. It means you should slow down and yield to oncoming vehicles.","imageName":"202007120454477868.jpg","correctAnswer":22682,"isBookmarked":0,"aid":"6515#7651#8990#22682","text":"Increase your speed#Come to a complete stop#make a right turn#reduce your speed and yield"},
{"testId":625,"qid":27196,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15474#15475#30391","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and then go#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding"},
{"testId":625,"qid":27197,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The speed limit during the day is 50 mph"},
{"testId":625,"qid":27198,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":625,"qid":27199,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":625,"qid":27200,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":625,"qid":27201,"description":"What does it mean if you see the following sign above your lane ?","explanation":null,"imageName":"202003051727375094.jpg","correctAnswer":38401,"isBookmarked":0,"aid":"28174#37706#38401#38402","text":"This lane is closed#This lane is only for left turns#This lane is open#You must move out of this lane as soon as possible"},
{"testId":625,"qid":27202,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":625,"qid":27203,"description":"Is it legal to turn right at a red light in New York City?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":38404,"isBookmarked":0,"aid":"18118#38403#38404#38405","text":"No#Yes, but only after coming to a stop and yielding to pedestrians and other vehicles#No, not unless a sign permits you to do so#Yes, but not during rush hours"},
{"testId":625,"qid":27204,"description":"You notice a highway with white diamonds. Which of the following vehicles is permitted to use this lane?","explanation":null,"imageName":"202001291436063384.jpg","correctAnswer":32628,"isBookmarked":0,"aid":"28174#32626#32627#32628","text":"This lane is closed#This is an emergency stopping lane#This is a dedicated bus lane#This is a reserved lane"},
{"testId":625,"qid":27205,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":38403,"isBookmarked":0,"aid":"15509#18118#38403#38405","text":"Yes, but only if a sign permits you to do so#No#Yes, but only after coming to a stop and yielding to pedestrians and other vehicles#Yes, but not during rush hours"},
{"testId":625,"qid":27206,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":null,"imageName":"202003051705034327.jpg","correctAnswer":38402,"isBookmarked":0,"aid":"28174#37706#38401#38402","text":"This lane is closed#This lane is only for left turns#This lane is open#You must move out of this lane as soon as possible"},
{"testId":625,"qid":27207,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":625,"qid":27208,"description":"Which of the following is true about places where you can't park?","explanation":null,"imageName":"202003151716212915.jpg","correctAnswer":32633,"isBookmarked":0,"aid":"32633#32634#38406#38407","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop to obey another rule of the road#You can stop at any time"},
{"testId":625,"qid":27209,"description":"You have come to a place where there is a STOP sign. Where do you have to stop?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#14279#14822#14823","text":"All of the above#Before the crosswalk#Before the stop line#Before entering the intersection"},
{"testId":625,"qid":27210,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":null,"imageName":"202003051707075416.jpg","correctAnswer":37706,"isBookmarked":0,"aid":"28174#37706#38401#38402","text":"This lane is closed#This lane is only for left turns#This lane is open#You must move out of this lane as soon as possible"},
{"testId":625,"qid":27211,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061247596804.jpg","correctAnswer":38410,"isBookmarked":0,"aid":"23434#38408#38409#38410","text":"Wrong way; turn around and go back#U-turns are allowed here#Oncoming vehicles are allowed to make U-turns; use caution#There is a hairpin curve ahead"},
{"testId":625,"qid":27212,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":23457,"isBookmarked":0,"aid":"23457#29962#29963#38045","text":"Prepare to stop; the light will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so#Speed through the intersection before the light turns red"},
{"testId":625,"qid":27213,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":null,"imageName":"202003051737433205.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":625,"qid":27214,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23403#28526#36008","text":"Car A must yield because it is turning left#None of the above are true#Car B must yield to all vehicles turning left#The drivers should decide which of them should go first"},
{"testId":625,"qid":27215,"description":"When is it legal to drive in a lane with this sign?","explanation":null,"imageName":"202003051709096729.jpg","correctAnswer":16971,"isBookmarked":0,"aid":"9435#16971#16972#37522","text":"At any time#When you have at least one passenger#When you have at least two passengers#Never (this lane is for buses and trucks)"},
{"testId":625,"qid":27216,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":null,"imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":625,"qid":27217,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":625,"qid":27218,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":38411,"isBookmarked":0,"aid":"25316#29330#38411#38412","text":"Proceed; you do not need to stop or yield at a flashing red signal#Proceed with caution; the traffic signal is broken#Stop, yield, and then proceed when it is safe to do so#Stop and remain stopped until the light turns green"},
{"testId":625,"qid":27219,"description":"Which of the following statement about no-standing zones is correct?","explanation":null,"imageName":"202004180814232263.jpg","correctAnswer":32634,"isBookmarked":0,"aid":"32633#32634#38406#38407","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop to obey another rule of the road#You can stop at any time"},
{"testId":625,"qid":27220,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":625,"qid":27221,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#30157#38411","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Go straight; you cannot turn here#Stop, yield, and then proceed when it is safe to do so"},
{"testId":625,"qid":27222,"description":"A school bus with flashing red lights has come to a halt ahead. What should you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":32323,"isBookmarked":0,"aid":"30005#32323#36032#38413","text":"Slow down and prepare to stop#Stop at least 20 feet away, no matter which direction you are traveling in#Slow down to 10 mph and proceed with caution#Stop at least 20 feet away, only if you are traveling in the same direction as the bus"},
{"testId":625,"qid":27223,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":null,"imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":625,"qid":27224,"description":"When may you turn left at a red light outside of New York City?","explanation":null,"imageName":"202007120412393828.jpg","correctAnswer":38414,"isBookmarked":0,"aid":"6334#9435#38414#38415","text":"Never#At any time#Only when you are turning from a one-way road onto another one-way road#Only when you are turning from a one-way or two-way road onto a one-way road"},
{"testId":625,"qid":27225,"description":"Which of the following statement about no-stopping zones is correct?","explanation":null,"imageName":"202004281827026875.jpg","correctAnswer":38406,"isBookmarked":0,"aid":"32633#32634#38406#38407","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop to obey another rule of the road#You can stop at any time"},
{"testId":625,"qid":27226,"description":"You must yield to _________ before turning left into a driveway.","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"8750#32554#33884#37520","text":"pedestrians#oncoming vehicles#pedestrians and oncoming vehicles#no one (i.e., you have the right-of-way)"},
{"testId":625,"qid":27227,"description":"On a highway, you are changing lanes. There aren't any other cars nearby. Do you need to signal yet?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23468#23470#38416","text":"No#Yes, but only briefly#Yes, for at least 100 feet#Yes, just long enough in advance to demonstrate your intentions"},
{"testId":625,"qid":27228,"description":"Above your lane, you notice a red \"X.\" What does it imply?","explanation":null,"imageName":"202003051729142462.jpg","correctAnswer":28174,"isBookmarked":0,"aid":"28174#37706#38401#38402","text":"This lane is closed#This lane is only for left turns#This lane is open#You must move out of this lane as soon as possible"},
{"testId":625,"qid":27229,"description":"You've parked facing a steep downhill slope. Which of the following actions should you take?","explanation":null,"imageName":"202002071736477410.jpg","correctAnswer":38417,"isBookmarked":0,"aid":"38417#38418#38419#38420","text":"Leave your transmission in reverse if you drive a manual#Leave your transmission in first gear if you drive a manual#Leave your transmission in reverse if you drive an automatic#Leave your transmission in first gear if you drive an automatic"},
{"testId":625,"qid":27230,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":625,"qid":27231,"description":"You're following a school bus when its yellow lights begin to flash. Which does this mean?","explanation":null,"imageName":"202003051644415234.jpg","correctAnswer":38422,"isBookmarked":0,"aid":"38421#38422#38423#38424","text":"The bus is stopping for passengers; you must stop immediately#The bus is preparing to stop for passengers; you must slow down and prepare to stop#The bus is signaling you to pass; you may pass when it is safe to do so#The bus is about to pull over and let you pass; you may proceed at your usual speed"},
{"testId":625,"qid":27232,"description":"A car is going right on a red signal somewhere outside of New York City. Simultaneously, a pedestrian begins to cross the road that the car is about to enter. Which one must give way?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":31065,"isBookmarked":0,"aid":"10634#14863#14882#31065","text":"Neither#The pedestrian#Whoever enters the intersection last#The car"},
{"testId":625,"qid":27233,"description":"Which of the following should be adhered to above all others?","explanation":null,"imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"14886#32655#32656#38425","text":"A traffic officer#A regulatory road sign#A stopped school bus with flashing red lights#A red traffic light"},
{"testId":625,"qid":27234,"description":"You come to a railroad crossing. A signal bell is ringing and red lights are blinking at the crossing. A train can be seen approaching. Which of the following statements is correct?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":38426,"isBookmarked":0,"aid":"38426#38427#38428#38429","text":"(a) You must stop at least 15 feet from the tracks#(b) You can cross the tracks as soon as the train has passed#Both (a) and (b) are true#Neither (a) nor (b) is true"},
{"testId":625,"qid":27235,"description":"Is this car parked legally?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27280#33552#33553#33554","text":"No, you must not park within 50 feet of a railroad crossing#No, you must not park within 15 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you're not on the tracks#Yes, you can park near a railroad crossing as long as you remain behind the stop line"},
{"testId":625,"qid":27236,"description":"Is it legal to park in front of a fire hydrant if you stay inside your car?","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":21234,"isBookmarked":0,"aid":"21234#38430#38431#38432","text":"Yes#No, you must park on the opposite side of the street#No, you're not allowed to park within 15 feet of a fire hydrant#No, you're not allowed to park within 30 feet of a fire hydrant"},
{"testId":625,"qid":27237,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#31341#33380","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#There is an emergency truck escape ramp ahead#This route is for trucks only; find another route"},
{"testId":625,"qid":27238,"description":"Which of the following U-turn assertions is FALSE?","explanation":null,"imageName":"202002061625343169.jpg","correctAnswer":38435,"isBookmarked":0,"aid":"38433#38434#38435#38436","text":"You can make a U-turn at a green light except where prohibited by signs#You cannot make a U-turn in New York City business districts#You can make a U-turn from any lane if it is safe to do so#You cannot make a U-turn on a hill unless drivers from either direction can see your vehicle from at least 500 feet away"},
{"testId":625,"qid":27239,"description":"You're facing up a steep hill where you've parked. You should do which of the following?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":38418,"isBookmarked":0,"aid":"38417#38418#38419#38420","text":"Leave your transmission in reverse if you drive a manual#Leave your transmission in first gear if you drive a manual#Leave your transmission in reverse if you drive an automatic#Leave your transmission in first gear if you drive an automatic"},
{"testId":625,"qid":27240,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":625,"qid":27241,"description":"You have stopped about 20 feet behind a school bus with flashing red lights. When do you get to pass it?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#38437#38438#38439","text":"All of the above#After it starts to move#If the bus driver waves you through#If a traffic officer waves you through"},
{"testId":625,"qid":27242,"description":"Two cars are parked in front of a fire station. Which of the following statements is the most correct?","explanation":null,"imageName":"202002070948543186.jpg","correctAnswer":36035,"isBookmarked":0,"aid":"36035#36036#36037#36038","text":"Car A must be parked at least 20 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 20 feet from the driveway#Car A must be parked at least 20 feet from the driveway; Car B can park anywhere#Car A can park anywhere as long as it doesn't block the driveway; Car B can park anywhere"},
{"testId":625,"qid":27243,"description":"Which of the following statement regarding this parked car is correct?","explanation":null,"imageName":"202002181141488961.jpg","correctAnswer":33560,"isBookmarked":0,"aid":"33559#33560#33561#33562","text":"The car must be at least 10 feet from the crosswalk#The car must be at least 20 feet from the crosswalk#The car is legally parked because it is not parked in a crosswalk#The car may be parked here only if a licensed driver remains with the vehicle"},
{"testId":625,"qid":27244,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":38440,"isBookmarked":0,"aid":"19471#34204#34205#38440","text":"Slow down and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the school bus resumes its motion"},
{"testId":625,"qid":27245,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"33565#34515#35047#38441","text":"Proceed as usual#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Speed up and pass the emergency vehicle as quickly as possible"},
{"testId":626,"qid":27246,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop behind the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#15474#15475","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here"},
{"testId":626,"qid":27247,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this regulatory sign. You are not permitted to exceed 50 mph in this area. [Speed Limits, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#23395#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The speed limit during the day is 50 mph#The legal speed limit is 50 mph"},
{"testId":626,"qid":27248,"description":"What is the meaning of this sign?","explanation":"The speed limit for this bend is 35 mph, according to this cautionary sign. [Speed Limits, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":626,"qid":27249,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction (one without signs or signals) at about the same moment, the vehicle on the left must yield. As a result, Car A must yield. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23403#23508#31031#32622","text":"None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B#Car B must yield because it is travelling straight through the intersection"},
{"testId":626,"qid":27250,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Slow down as you approach a yield sign and ready to yield to pedestrians and traffic ahead. Be prepared to halt if necessary. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":626,"qid":27251,"description":"Which one of the following is true about work zones?","explanation":"At all times, you must adhere to the established work zone speed limitations. Your penalties will be doubled if you are caught speeding in a work zone. [Road Respect/Sharing the Road, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002101222518967.jpg","correctAnswer":32625,"isBookmarked":0,"aid":"23403#32623#32624#32625","text":"None of the above are true#You don't need to slow down in a work zone if there are no workers present#After being waved through a work zone, you should drive quickly to avoid holding up traffic#You must observe work zone speed limits; fines for speeding are doubled"},
{"testId":626,"qid":27252,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Speed Limits, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15498#15499#26800","text":"The recommended speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph#The legal maximum speed limit is 35 mph"},
{"testId":626,"qid":27253,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic lights are not working, the junction should be treated as an all-way stop. You must stop, yield, and advance only when it is safe to do so (with utmost care). [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003051805205845.jpg","correctAnswer":28880,"isBookmarked":0,"aid":"23413#23414#28880#31436","text":"Slow down and prepare to yield to any traffic ahead#Continue through as if you had a green light#Treat the intersection as an all-way stop#If you are on the busier road, assume you have the right-of-way; otherwise yield"},
{"testId":626,"qid":27254,"description":"You notice a highway with white diamonds. Which of the following vehicles is permitted to use this lane?","explanation":"This is a limited or reserved lane. This lane is restricted to specific types of vehicles. High-occupancy vehicle (HOV) lanes and bus lanes are two examples. Nearby signage should specify which kind of vehicles are permitted to use this lane. [Pavement Markings, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001291436063384.jpg","correctAnswer":32628,"isBookmarked":0,"aid":"28174#32626#32627#32628","text":"This lane is closed#This is an emergency stopping lane#This is a dedicated bus lane#This is a reserved lane"},
{"testId":626,"qid":27255,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. You must first come to a full stop and then yield to all pedestrians and vehicles. [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003112014364009.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#23423#32629","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, but not during rush hour#No. In Massachusetts, you cannot turn right at a red light"},
{"testId":626,"qid":27256,"description":"Which of the following statements is correct?","explanation":"Everyone in your car must be wearing a safety belt or an appropriate kid restraint. Driving without a seat belt or transporting any passengers under the age of 16 without their seat belts fastened can result in a fine in Massachusetts. Passengers who are at least 16 years old and are not wearing safety belts may be punished. [Commonwealth of Massachusetts Driver's Manual, Passenger Vehicle Safety, Chapter 3: Safety First]","imageName":"202003151755527296.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#32630#32631#32632","text":"All of the above are true#You can be fined for driving without a safety belt#Any passenger who is at least 16 years of age can be fined for not wearing a safety belt#You can be fined for carrying passengers under 16 who are not wearing safety belts"},
{"testId":626,"qid":27257,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless otherwise indicated by signage, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made an erroneous turn into the far lane. Car B has made the proper turn into the nearest lane. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":626,"qid":27259,"description":"You have come to a place where there is a STOP sign. Where do you have to stop?","explanation":"When approaching a stop sign, you must come to a complete stop before the stop line. Stop before the crosswalk if there is no stop line. If there is no crosswalk, come to a complete stop before entering the crossing. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001301744345845.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#14279#14822#14823","text":"All of the above#Before the crosswalk#Before the stop line#Before entering the intersection"},
{"testId":626,"qid":27260,"description":"What must you do before leaving your parked vehicle unattended?","explanation":"Before leaving your automobile alone in Massachusetts, you must switch off the engine, set the brake, ensure the ignition is secured, remove your key, and lock the door. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002061627578233.jpg","correctAnswer":32945,"isBookmarked":0,"aid":"32640#32944#32945#32946","text":"Do none of the above#Keep the engine running#Turn off the engine, set the parking brake, make sure the ignition is locked, remove your key, and lock the doors#Turn off the engine but leave your key inside"},
{"testId":626,"qid":27261,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a hairpin turn ahead. Slow down and take your time. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":626,"qid":27263,"description":"You reach a junction with a green light. Which of the following statements is correct?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003070857473536.jpg","correctAnswer":32642,"isBookmarked":0,"aid":"15504#15505#32641#32642","text":"You are free to proceed#You cannot proceed#You must briefly come to a stop and yield, and then you can proceed#You are free to proceed after you yield to pedestrians and traffic already in the intersection"},
{"testId":626,"qid":27264,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"To cross tracks, it is usually safest to ride straight inside your lane. Turning to take tracks head-on (at a 90-degree angle) is riskier since your course may lead you into another lane of traffic. If there are tracks, ruts, or road seams parallel to your path, move far enough away from them to cross them at an angle of at least 45 degrees.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":626,"qid":27265,"description":"Is it legal to drive in a lane with this sign?","explanation":"This sign indicates that this lane is designated as a high-occupancy vehicle (HOV) lane. It specifies that a vehicle must have two or more people in order to enter the lane. As a result, you'll require at least one passenger. [Pavement Markings, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003051709096729.jpg","correctAnswer":32644,"isBookmarked":0,"aid":"21234#32643#32644#32645","text":"Yes#No, only buses are allowed in this lane#Yes, but only if you have at least one passenger#Yes, but only if you have at least two passengers"},
{"testId":626,"qid":27266,"description":"Which of the following statement concerning hydroplaning is correct?","explanation":"When you drive too rapidly over standing water, you essentially ski over its surface. Even if you have decent tires, your car may begin to hydroplane at speeds as low as 35 mph. (At speeds as low as 25 mph, poorly worn tires might cause the car to hydroplane.) This might cause your automobile to slide. If you hydroplane, try not to accelerate, steer, or brake; instead, let the automobile slow down on its own. Do not use your cruise control on wet roads. If your car loses traction, the cruise control will attempt to maintain a steady speed by spinning the wheels. This may exacerbate or even cause a skid. [Driving Defensively, Commonwealth of Massachusetts Driver's Manual, Chapter 3: Safety First]","imageName":"202002061923072507.jpg","correctAnswer":30746,"isBookmarked":0,"aid":"30746#30747#30748#30749","text":"Hydroplaning can start at speeds as low as 35 mph#The condition of your tires does not affect your likelihood of hydroplaning#If you hydroplane, you should steer straight and brake firmly#Cruise control is safe to use on wet roads"},
{"testId":626,"qid":27267,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any cars and pedestrians already present. Because Car B has already reached the junction, Car A must yield to Car B. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#23403#25313","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B because Car B is already in the intersection"},
{"testId":626,"qid":27268,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#29331","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":626,"qid":27270,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. Slow down and prepare to come to a halt. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":626,"qid":27271,"description":"What should you do if you see a flashing yellow light?","explanation":"Flashing yellow signals are intended to alert vehicles of possible risks at junctions and construction zones. When you see a flashing yellow light, you must slow down, yield to oncoming vehicles and pedestrians, and continue with care. [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30765#31848","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Stop if safe; the signal will soon turn red#Go straight when safe; you cannot turn here"},
{"testId":626,"qid":27272,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"Vehicles on either side of the road must come to a complete stop for a school bus with flashing red lights. The cars must halt until the red lights cease blinking. [Road Respect/Sharing the Road, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003051658529900.jpg","correctAnswer":23460,"isBookmarked":0,"aid":"23460#23462#23463#32646","text":"No, you must come to a complete stop#Yes, but you must slow down and prepare to stop#Yes, but you cannot exceed 20 mph while doing so#Yes, but first you must come to a complete stop and then yield before proceeding"},
{"testId":626,"qid":27273,"description":"Who has the right-of-way in this situation?","explanation":"In a crosswalk, a vehicle must yield to pedestrians. Car C must yield to the pedestrian here. After taking into account pedestrians, each vehicle must yield to the vehicle on its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":626,"qid":27274,"description":"When is it legal to turn left at a red light?","explanation":"Turning left on a red light from a one-way street into another one-way street is legal in Massachusetts, unless signage forbid it. You must come to a full stop before making the left turn and then yield to pedestrians and oncoming cars. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Unless indications indicate otherwise, so does New York City. Always verify the local traffic rules while going out of state.) [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202007120412393828.jpg","correctAnswer":31055,"isBookmarked":0,"aid":"6334#9435#14865#31055","text":"Never#At any time#When you are entering an expressway#When you are turning from a one-way road onto another one-way road"},
{"testId":626,"qid":27275,"description":"Which of the following claims concerning No-Stop zones is correct?","explanation":"You may only stop in a No Stopping zone to respect another driving regulation. You may, for example, stop to obey a traffic sign, light, or traffic police. You are not permitted to make any stops to load or unload people or products. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202004281827026875.jpg","correctAnswer":32635,"isBookmarked":0,"aid":"32633#32634#32635#32636","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop here to obey another rule of the road#You can stop here at any time"},
{"testId":626,"qid":27276,"description":"You're going to turn to the left into a driveway. What do you need to do before you can move on?","explanation":"There are two right-of-way regulations at work here: a car turning left must yield to approaching traffic. A vehicle entering a driveway or parking lot must also yield to pedestrians. As a result, when turning left into a driveway, you must yield to all pedestrians and oncoming traffic. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001241717155576.jpg","correctAnswer":31057,"isBookmarked":0,"aid":"23464#23467#31056#31057","text":"Yield to pedestrians#You have the right-of-way; proceed with caution#Yield to oncoming vehicles#Yield to pedestrians and oncoming vehicles"},
{"testId":626,"qid":27277,"description":"You're on the highway, getting ready to leave at the next exit. There are no other automobiles in the area. Is it still necessary to signal?","explanation":"On the road, you must indicate for at least 100 feet before turning, according to Massachusetts law. On highways, you must signal for at least 500 feet before exiting. Even if you don't see any other cars, you must signal. The most deadly car can be one you don't see. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001302245403150.jpg","correctAnswer":32649,"isBookmarked":0,"aid":"18118#32647#32648#32649","text":"No#Yes, but only for a moment#Yes, long enough to demonstrate your intentions#Yes, for at least 500 feet"},
{"testId":626,"qid":27278,"description":"On a four-lane highway, you are on the right lane. You see an emergency vehicle stopped with its lights flashing in front of you. Which of the following is true?","explanation":"If you are approaching a stopped emergency vehicle with flashing lights on a multilane road in Massachusetts, you must leave an empty lane between you and the emergency vehicle. If you must combine to comply with this guideline, only do so if it is safe to do so. [Stationary Emergency Vehicle, Commonwealth of Massachusetts Driver's Manual, Chapter 5: Special Driving Situations]","imageName":"202001241703386758.jpg","correctAnswer":32651,"isBookmarked":0,"aid":"32650#32651#32652#32653","text":"You can proceed as usual#You must leave an empty lane between your vehicle and the emergency vehicle#You must leave an empty lane between your vehicle and the emergency vehicle, but only at night#You must leave an empty lane between your vehicle and the emergency vehicle where the speed limit exceeds 40 mph"},
{"testId":626,"qid":27279,"description":"You parked with your back to the hill. If you have an automatic transmission, keep it in Park. You should keep your car in _________, if you drive a manual (stick shift).","explanation":"When parking a car, configure the gearbox to prevent the vehicle from rolling if the brakes fail. Your car may slide forward if it is pointing downward. As a result, you should park an automatic gearbox and reverse a manual transmission. Always use the parking brake. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002071736477410.jpg","correctAnswer":31053,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":626,"qid":27280,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"This driver intends to make a left turn. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":626,"qid":27281,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":626,"qid":27282,"description":"On a red light, a vehicle is going right. Simultaneously, a pedestrian begins to cross the road that the vehicle is going to enter. Which one must give way?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a marked or unmarked crossing, you must always yield to a pedestrian. As a result, the pedestrian has the right of way here. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002181109457082.jpg","correctAnswer":31065,"isBookmarked":0,"aid":"10634#14863#14882#31065","text":"Neither#The pedestrian#Whoever enters the intersection last#The car"},
{"testId":626,"qid":27283,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a police officer's instructions, even if it means violating other traffic devices or laws. For example, if a police officer waves you past a red light or a stop sign, you should proceed. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"14886#32654#32655#32656","text":"A traffic officer#A traffic light#A regulatory road sign#A stopped school bus with flashing red lights"},
{"testId":626,"qid":27284,"description":"You come to a railroad crossing. A signal bell is ringing and red lights are blinking at the crossing. A train can be seen approaching. Which of the following statements is correct?","explanation":"When stopping at a railway crossing, keep at least 15 feet away from the rails. You are not permitted to pass if the signal lights are flashing, the warning bell is ringing, or the boom gate is not fully up. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003161136554337.jpg","correctAnswer":32657,"isBookmarked":0,"aid":"32657#32658#32659#32660","text":"A) You must stop at least 15 feet from the tracks#B) You can cross the tracks as soon as the train has passed#Both a) and b) are true#Neither a) nor b) is true"},
{"testId":626,"qid":27285,"description":"Which vehicle is not properly using the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Unless otherwise indicated, turn left from the left lane, right from the right lane, and drive straight through using either lane in a two-lane roundabout. Car C has made an improper left turn from the right lane. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#32661","text":"Car A#Car B#Car C#None (all cars are using the roundabout correctly)"},
{"testId":626,"qid":27286,"description":"Is this car parked legally?","explanation":"Parking is prohibited within 10 feet of a fire hydrant in Massachusetts. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#25378#32662","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#No, you cannot park within 10 feet of a fire hydrant#Yes, but only for 10 minutes"},
{"testId":626,"qid":27287,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if you operate an automatic, choose low gear to avoid brake wear. [Traffic Signs, Chapter 4: Rules of the Road, Commonwealth of Massachusetts Driver's Manual]","imageName":"202002101212538783.jpg","correctAnswer":32663,"isBookmarked":0,"aid":"15602#15689#25335#32663","text":"Only trucks can use the road ahead; find another route#There is a runaway truck ramp ahead#Use caution; trucks will be entering from a steep side road or driveway#Steep descent; proceed with caution"},
{"testId":626,"qid":27288,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Traffic Signs, Chapter 4: Rules of the Road, Commonwealth of Massachusetts Driver's Manual]","imageName":"202002061255268690.jpg","correctAnswer":27285,"isBookmarked":0,"aid":"14961#14962#14963#27285","text":"Wrong way; turn around and go back#Roundabout or rotary ahead#No direct left turn ahead#270-degree turn ahead"},
{"testId":626,"qid":27289,"description":"You've parked with your back to a hill. If your vehicle has an automatic transmission, leave it in Park. If you drive a vehicle with a manual shift, you should leave it in:","explanation":"When parking a car, configure the gearbox to prevent the vehicle from rolling if the brakes fail. Your car may roll backward if it is looking uphill. As a result, for maximum forward torque, put an automatic gearbox in park and a manual transmission in first gear. Always use the parking brake. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002070945089828.jpg","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":626,"qid":27290,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"This motorist wants to make a right turn. [Lanes, Intersections, and Turns, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":626,"qid":27291,"description":"What is the meaning of this sign?","explanation":"Flag personnel (also known as flaggers) often wear orange vests, shirts, or jackets and utilize red flags or stop/slow paddles to safely guide traffic through work zones. In highway and street construction zones, flag personnel are often deployed to halt, slow, or direct vehicles safely through certain locations. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002101220385703.jpg","correctAnswer":30132,"isBookmarked":0,"aid":"30132#30133#30134#31849","text":"There is a flag person ahead, whose signals you must obey#You must go straight#You must increase your speed#You must take a detour"},
{"testId":626,"qid":27292,"description":"What is the meaning of this sign?","explanation":"This symbol indicates that there is an impending challenge. It must be passed on either the left or right. [Traffic Signs, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002101234545593.jpg","correctAnswer":32666,"isBookmarked":0,"aid":"32664#32665#32666#32667","text":"There is a Y-intersection ahead#A two-lane highway starts here#There is an obstacle ahead; pass it on either side#There is a new lane ahead"},
{"testId":626,"qid":27293,"description":"Which of the following statement regarding how this car is parked is correct?","explanation":"Parking must be at least 20 feet away from an intersection. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002181141488961.jpg","correctAnswer":32669,"isBookmarked":0,"aid":"32668#32669#32670#32671","text":"The car should be at least 10 feet from the intersection#The car should be at least 20 feet from the intersection#The car is legally parked#The car can park here only if a licensed driver stays with the vehicle"},
{"testId":626,"qid":27294,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus that has stopped to pick up passengers. This law, however, does not apply if the school bus is on the other side of a split highway (a roadway with a non-drivable median or barrier). [Road Respect/Sharing the Road, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":626,"qid":27295,"description":"Three vehicles arrive at an uncontrolled T-junction (i.e., one without signs or signals). In what order should they go?","explanation":"Cars on the through (main) route, like B and C in this instance, have the right of way at a T-intersection, hence Car A is required to give way to B and C. Car C must yield to Car B because cars turning left must give way to approaching traffic. As a result, the automobiles are in B, C, and A. [Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road, Lanes, Intersections, and Turns]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":660,"qid":27296,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":660,"qid":27297,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The speed limit during the day is 50 mph"},
{"testId":660,"qid":27298,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":660,"qid":27299,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":660,"qid":27300,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":660,"qid":27301,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":35998,"isBookmarked":0,"aid":"26798#35997#35998#39087","text":"Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the emergency vehicle is approaching you from behind#Pull over, no matter which direction the emergency vehicle is traveling in#Pull over if the emergency vehicle is coming from the opposite direction"},
{"testId":660,"qid":27302,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":660,"qid":27303,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"23414#25300#33517#37698","text":"Continue through as if you had a green light#Treat the intersection like an all-way stop#Stop as if you had a red light#Slow down and prepare to yield to traffic ahead"},
{"testId":660,"qid":27304,"description":"Car B wishes to cross the split highway that Car A is going on. Who has the right of way and why? :","explanation":null,"imageName":"202002061630063390.jpg","correctAnswer":33879,"isBookmarked":0,"aid":"31062#31063#31064#33879","text":"Car B has the right-of-way because it is to the right of Car A#Car B has the right-of-way because it arrived at the intersection first#Car B has the right-of-way because it is on a smaller road than Car A#Car A has the right-of-way because it is on the main road"},
{"testId":660,"qid":27305,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#25289#39088","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you will have the right-of-way#No. In Ohio, you cannot turn right at a red signal"},
{"testId":660,"qid":27306,"description":"Which of the following statements about safety belts is true in Ohio?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#32039#39089#39090","text":"All of the above are true#Passengers in the front seat can be fined for not wearing their safety belts#You can be fined for driving without wearing a safety belt#All passengers in a vehicle being driven by a probationary license holder must wear safety belts"},
{"testId":660,"qid":27307,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":660,"qid":27308,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block. [Section 2B.28: Keep Right and Keep Left Signs, Chapter 2B: Regulatory Signs, United States Federal Highway Administration Manual on Uniform Traffic Control Devices]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14582#14994#15166","text":"Divided highway ahead#Turn right#Keep right#No left turn ahead"},
{"testId":660,"qid":27309,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":660,"qid":27310,"description":"Two cars are parked close to where the driveway to a fire station starts. Which of these is the most accurate?","explanation":null,"imageName":"202002070948543186.jpg","correctAnswer":36035,"isBookmarked":0,"aid":"36035#36036#36037#36038","text":"Car A must be parked at least 20 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 20 feet from the driveway#Car A must be parked at least 20 feet from the driveway; Car B can park anywhere#Car A can park anywhere as long as it doesn't block the driveway; Car B can park anywhere"},
{"testId":660,"qid":27311,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061247596804.jpg","correctAnswer":38410,"isBookmarked":0,"aid":"23434#35194#38410#39091","text":"Wrong way; turn around and go back#U-turns are allowed#There is a hairpin curve ahead#Oncoming vehicles may make U-turns"},
{"testId":660,"qid":27312,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":39031,"isBookmarked":0,"aid":"28857#28858#36007#39031","text":"Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so#Prepare to stop; the signal will soon turn red"},
{"testId":660,"qid":27313,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":660,"qid":27314,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":660,"qid":27315,"description":"When the yellow lights on a school bus begin to flash, you are following it. What exactly does this mean?","explanation":null,"imageName":"202003051644415234.jpg","correctAnswer":39093,"isBookmarked":0,"aid":"23443#39092#39093#39094","text":"The bus is about to pull over and let you pass#The bus is stopping for passengers. You must stop immediately#The bus will soon stop for passengers. You must prepare to stop#The bus is signaling you to pass. Pass when it is safe to do so"},
{"testId":660,"qid":27316,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":null,"imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":660,"qid":27317,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":660,"qid":27318,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":39095,"isBookmarked":0,"aid":"25316#28864#29330#39095","text":"Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Stop, yield to pedestrians and traffic, and then proceed when it is safe to do so"},
{"testId":660,"qid":27319,"description":"Three vehicles arrive at an uncontrolled T-junction (i.e., one without signs or signals). In what order should they go?","explanation":null,"imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":660,"qid":27320,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":660,"qid":27321,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":660,"qid":27322,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":28850,"isBookmarked":0,"aid":"28850#39096#39097#39098","text":"No, you must stop#Yes, but first you must stop and then yield#Yes, but be prepared to yield to pedestrians#Yes, but first you must slow down and proceed with caution"},
{"testId":660,"qid":27323,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":null,"imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":660,"qid":27324,"description":"Is it legal in Ohio to turn left at a red light?","explanation":null,"imageName":"202007120412393828.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35269#39099","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, unless it's prohibited by a sign#No, not in Ohio"},
{"testId":660,"qid":27325,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Turn left (or do a U-turn) from the left lane, right from the right lane, then drive straight through using either lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Mid-Ohio Regional Planning Commission (MORPC), How to Drive a Roundabout, https://www.youtube.com/watch?v=qVh04m6xHxw]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":660,"qid":27326,"description":"You must yield to _________ before turning left into a driveway.","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"8750#32554#33884#33885","text":"pedestrians#oncoming vehicles#pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles"},
{"testId":660,"qid":27327,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#39100","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Ohio"},
{"testId":660,"qid":27328,"description":"On a four-lane highway, you are moving in the right lane. A halted emergency vehicle with flashing lights is visible in the distance. What ought you to do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34515,"isBookmarked":0,"aid":"34502#34515#39101#44100","text":"Stay in your lane#Move into a non-adjacent lane if possible; otherwise, slow down#Pull over and wait until it is safe to proceed#Come to a complete stop in your lane"},
{"testId":660,"qid":27329,"description":"What should you do in Ohio to pass another vehicle on a two-lane road?","explanation":null,"imageName":"202002181239175594.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"23000#39102#39103#39104","text":"Do all of the above#Signal that you are going to pass#Sound your horn to alert the other driver#Complete your pass before you come within 200 feet of any oncoming vehicle"},
{"testId":660,"qid":27330,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":660,"qid":27331,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":660,"qid":27332,"description":"On a red light, Car A is making a right turn. A pedestrian begins to cross the road that the automobile is ready to enter at the same moment. Which one has to give way?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"10634#14814#14863#14882","text":"Neither#Car A#The pedestrian#Whoever enters the intersection last"},
{"testId":660,"qid":27333,"description":"Which of the following should be adhered to above all others?","explanation":null,"imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"14884#14886#16371#32656","text":"A red traffic signal#A traffic officer#A regulatory sign#A stopped school bus with flashing red lights"},
{"testId":660,"qid":27334,"description":"You come to a railroad crossing. A signal bell is ringing and red lights are blinking at the crossing. A train can be seen approaching. Which of the following statements is correct?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":38426,"isBookmarked":0,"aid":"38426#38427#38428#38429","text":"(a) You must stop at least 15 feet from the tracks#(b) You can cross the tracks as soon as the train has passed#Both (a) and (b) are true#Neither (a) nor (b) is true"},
{"testId":660,"qid":27335,"description":"Is this car parked legally?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27279#27280#27282#33553","text":"No, you must not park within 150 feet of a railroad crossing#No, you must not park within 50 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you stay behind the stop line#Yes, you can park near a railroad crossing as long as you're not on the tracks"},
{"testId":660,"qid":27336,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":660,"qid":27337,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":660,"qid":27338,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061255268690.jpg","correctAnswer":33555,"isBookmarked":0,"aid":"15166#15187#33555#34377","text":"No left turn ahead#Roundabout ahead#A 270-degree turn ahead#Wrong way"},
{"testId":660,"qid":27339,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":660,"qid":27340,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":660,"qid":27341,"description":"You've come to a halt 10 feet behind a school bus with flashing red lights. When will you be able to pass it?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":11220,"isBookmarked":0,"aid":"11220#38437#38438#39105","text":"In any of the above situations#After it starts to move#If the bus driver waves you through#If a police officer waves you through"},
{"testId":660,"qid":27342,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"19403#32664#32667#39106","text":"Traffic may flow on both sides#There is a Y-intersection ahead#There is a new lane ahead#You may pass a vehicle on either side"},
{"testId":660,"qid":27343,"description":"Which of the following statement regarding this parked car is correct?","explanation":null,"imageName":"202002181141488961.jpg","correctAnswer":39108,"isBookmarked":0,"aid":"33561#33562#39107#39108","text":"The car is legally parked because it is not parked in a crosswalk#The car may be parked here only if a licensed driver remains with the vehicle#The car must be parked at least 10 feet from the crosswalk#The car must be parked at least 20 feet from the crosswalk"},
{"testId":660,"qid":27344,"description":"You're on a four-lane divided highway. You approach a stopped school bus with flashing red lights on the opposite side of the roadway. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":39109,"isBookmarked":0,"aid":"39109#39110#39111#39112","text":"Drive on#Slow down to 20 mph and proceed with caution#Stop at least 10 feet before the bus, yield if necessary, and then proceed#Stop at least 10 feet before the bus and remain stopped until the bus resumes its motion or the bus driver signals you to proceed"},
{"testId":660,"qid":27345,"description":"Which of the following about parking at an intersection is most accurate?","explanation":null,"imageName":"202003051731089038.jpg","correctAnswer":39116,"isBookmarked":0,"aid":"39113#39114#39115#39116","text":"(a) You may not park within 40 feet of any stop sign or traffic signal#(b) You may not park within 30 feet of any stop sign#(c) You may not park within 30 feet of any traffic signal#Both (b) and (c) are true"},
{"testId":663,"qid":27346,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. Next, give way to pedestrians and other traffic, and only go forward when it's safe to do so. [Pennsylvania Driver's Manual, Chapter 2: Signals, Signs and Pavement Markings, Regulatory Signs, Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":663,"qid":27347,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction is shown by this sign. You are not permitted to exceed 50 mph in this area. It is important to note that this does not imply that driving at 50 mph is safe in this area; it is dependent on the present driving circumstances. Slow down below the maximum speed limit under dangerous driving circumstances. [Regulatory Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The speed limit during the day is 50 mph"},
{"testId":663,"qid":27348,"description":"What is the meaning of this sign?","explanation":"The suggested speed limit for this bend is 35 mph, according to this sign. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":663,"qid":27349,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":663,"qid":27350,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Regulatory Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":663,"qid":27351,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [Pennsylvania Driver's Manual, Emergency Vehicles and Situations, Special Circumstances and Emergencies, Chapter 3: Learning to Drive]","imageName":"202001302235285631.jpg","correctAnswer":35998,"isBookmarked":0,"aid":"26798#35998#39803#39804","text":"Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over, no matter which direction the emergency vehicle is traveling in#Pull over if the emergency vehicle is traveling in the same direction as you are#Pull over if the emergency vehicle is traveling in the opposite direction"},
{"testId":663,"qid":27352,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":663,"qid":27353,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"23414#25300#33517#37698","text":"Continue through as if you had a green light#Treat the intersection like an all-way stop#Stop as if you had a red light#Slow down and prepare to yield to traffic ahead"},
{"testId":663,"qid":27354,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458#39805","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":663,"qid":27355,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Pennsylvania unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#23423#39806","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, but not during rush hour#No. In Pennsylvania, you cannot turn right at a red signal"},
{"testId":663,"qid":27356,"description":"Which of the following statements regarding seat belts is true in Pennsylvania? :","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#39807#39808#39809","text":"All of the above are true#The driver must wear a seat belt#Every front-seat passenger and every passenger under 18 must wear a seat belt or appropriate child restraint#The driver must ensure that every passenger under 18 is wearing a seat belt or appropriate child restraint"},
{"testId":663,"qid":27357,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [Turning, Merging, and Passing; Everyday Driving Skills; Learning to Drive; Pennsylvania Driver's Manual]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":663,"qid":27358,"description":"What is the meaning of this sign?","explanation":"This sign instructs cars to stay to the right of an impending island or obstruction. [Regulatory Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#14995#14996","text":"Divided highway ahead#Keep right#No right turn ahead#Mandatory right turn ahead"},
{"testId":663,"qid":27359,"description":"You've arrived at an intersection with a red traffic light. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before the indicated crossing. Otherwise, pull over before the unmarked crosswalk to avoid entering the junction. Signs; Regulatory Signs; Signals, Signs, and Pavement Markings; Pennsylvania Driver's Manual]","imageName":"202003112014364009.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#14279#14822#14823","text":"All of the above#Before the crosswalk#Before the stop line#Before entering the intersection"},
{"testId":663,"qid":27360,"description":"Is it permissible to turn left at a green signal?","explanation":"The green arrow indicates that you may turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must yield to any pedestrians or vehicles already at the junction. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003051809194011.jpg","correctAnswer":39805,"isBookmarked":0,"aid":"39458#39805#39810#39811","text":"Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way#Yes, but only if there is a Left Turn Allowed sign present#Yes, you may either turn left or go straight"},
{"testId":663,"qid":27361,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a hairpin turn ahead. Slow down and take your time. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202002061247596804.jpg","correctAnswer":38410,"isBookmarked":0,"aid":"23434#35194#38410#39091","text":"Wrong way; turn around and go back#U-turns are allowed#There is a hairpin curve ahead#Oncoming vehicles may make U-turns"},
{"testId":663,"qid":27362,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":663,"qid":27363,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":663,"qid":27364,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach a junction at about the same moment, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the vehicle turning left must yield. Car A must yield here since it is turning left. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#26796#36008#39459","text":"Car A must yield because it is turning left#Car A must yield because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":663,"qid":27365,"description":"When the yellow lights on a school bus begin to flash, you are following it. What exactly does this mean?","explanation":null,"imageName":"202003051644415234.jpg","correctAnswer":39093,"isBookmarked":0,"aid":"23443#39092#39093#39094","text":"The bus is about to pull over and let you pass#The bus is stopping for passengers. You must stop immediately#The bus will soon stop for passengers. You must prepare to stop#The bus is signaling you to pass. Pass when it is safe to do so"},
{"testId":663,"qid":27366,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [Turning, Merging, and Passing; Everyday Driving Skills; Learning to Drive; Pennsylvania Driver's Manual]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":663,"qid":27367,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":663,"qid":27368,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Flashing Signals; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003051722347868.jpg","correctAnswer":39095,"isBookmarked":0,"aid":"25316#28864#29330#39095","text":"Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Stop, yield to pedestrians and traffic, and then proceed when it is safe to do so"},
{"testId":663,"qid":27369,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":"A vehicle on a T-terminating intersection's road must yield to all vehicles on the through (main) route. Car A must yield to Cars B and C in this situation. Car C, on the other hand, must yield to oncoming traffic since it is turning left at a junction. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":663,"qid":27370,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":663,"qid":27371,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [Flashing Signals; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":663,"qid":27372,"description":"Is it legal to pass a school bus that is stopped with its red lights flashing on an open road?","explanation":"When approaching a school bus with flashing red lights on an undivided highway in Pennsylvania, you must stop at least 10 feet from the bus, regardless of which direction the vehicle is driving. [Pennsylvania Driver's Manual, School Buses, Special Circumstances and Emergencies, Chapter 3: Learning to Drive]","imageName":"202003051658529900.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#39812#39813#39814","text":"No#Yes, but you must first stop and then yield to traffic and pedestrians#Yes, but you must first slow down and yield to traffic and pedestrians#Yes, but no faster than 20 mph"},
{"testId":663,"qid":27373,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In the absence of signs or signals, each vehicle must yield to the vehicle on its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":663,"qid":27374,"description":"When is it legal to make a left turn at a red light in Pennsylvania?","explanation":"When moving from one one-way street to another one-way street in Pennsylvania, unless signs prevent it, you may turn left on a red light. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Michigan), you may also turn left on red from a two-way street into a one-way street. Other states, such as New Jersey, outright ban left turns on red. Always verify the local driving regulations while going out of state. [Red, Yellow, and Green Traffic Lights and Arrows; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#9435#29959#39815","text":"Never#At any time#When you are turning from a one-way street onto another one-way street#When you are turning from any street onto a one-way street"},
{"testId":663,"qid":27375,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":663,"qid":27376,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to all incoming traffic before turning left. You must also yield to pedestrians before turning while crossing a sidewalk or sidewalk area to enter or leave a highway. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":663,"qid":27377,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":39817,"isBookmarked":0,"aid":"18118#31059#39816#39817","text":"No#Yes, for at least 300 feet#Yes, for at least 200 feet#Yes, for at least 100 feet below 35 mph and 300 feet at or above 35 mph"},
{"testId":663,"qid":27378,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A continuous yellow \"X\" signifies that you must exit this lane as soon as possible. The signal is going to change to a steady red \"X,\" indicating that the lane in your direction is closed. [Lane Use Control Signals; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202003051705034327.jpg","correctAnswer":38402,"isBookmarked":0,"aid":"27272#28148#37706#38402","text":"This is a high-occupancy vehicle (HOV) lane#Proceed with caution#This lane is only for left turns#You must move out of this lane as soon as possible"},
{"testId":663,"qid":27379,"description":"You parked with your back to the hill. If you have an automatic transmission, keep it in Park. You should keep your vehicle in _________, if you drive a manual (stick shift).","explanation":"Set your gearbox to the opposite direction your car would roll if its brakes fail. A downhill-facing vehicle may roll forward. As a result, while parking your car facing downhill, you should choose Park (for an automatic gearbox) or Reverse (for a manual transmission). [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202002071736477410.jpg","correctAnswer":31053,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":663,"qid":27380,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This driver intends to make a left turn. [Everyday Driving Skills, Chapter 3: Learning to Drive, Pennsylvania Driver's Manual] [Communicating Your Intentions to Other Drivers, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":663,"qid":27381,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":663,"qid":27382,"description":"On a red light, Car A is making a right turn. A pedestrian begins to cross the road that the automobile is ready to enter at the same moment. Which one has to give way?","explanation":"Car A must stop and yield to traffic and pedestrians before proceeding to the red signal. As a result, the pedestrian has the right of way here. [From the Pennsylvania Driver's Manual: Negotiating Intersections, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202003051817433687.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"10634#14814#14863#14882","text":"Neither#Car A#The pedestrian#Whoever enters the intersection last"},
{"testId":663,"qid":27383,"description":"What does it mean if you see the following sign above your lane ?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. This white two-way left-turn arrow indicates that cars going in both directions may only turn left from this lane. Use this lane with care since incoming traffic may use it as well. [Lane Use Control Signals; Signals; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202005121139121942.jpg","correctAnswer":39820,"isBookmarked":0,"aid":"39818#39819#39820#39821","text":"You may turn left or right in this lane#There is an intersection ahead where you can only turn left#Vehicles traveling in both directions may turn left from this lane#There is an obstacle ahead; vehicles traveling in both directions must keep left"},
{"testId":663,"qid":27384,"description":"In which of the following conditions are you obligated to turn on your headlights in Pennsylvania?","explanation":"According to Pennsylvania law, you must turn on your headlights if visibility is decreased to less than 1,000 feet: between sunset and dawn, in terrible weather, in thick overcast, in fog, and so on. You must also turn on your headlights anytime you are driving through a construction zone or when you have turned on your windshield wipers due to weather conditions. [Managing Speed, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"1365757274_t1q39.png","correctAnswer":203,"isBookmarked":0,"aid":"203#15046#39822#39823","text":"All of the above#Whenever you are driving through a work zone#On overcast days when visibility is low#Whenever you use your windshield wipers in bad weather"},
{"testId":663,"qid":27385,"description":"Is this car parked legally?","explanation":"Parking is not permitted within 50 feet of the closest rail of a railroad crossing in Pennsylvania. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27280#27282#33553#39824","text":"No, you must not park within 50 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you stay behind the stop line#Yes, you can park near a railroad crossing as long as you're not on the tracks#No, you must not park within 100 feet of a railroad crossing"},
{"testId":663,"qid":27386,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in Pennsylvania. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":663,"qid":27387,"description":"This symbol warns of:","explanation":"This sign forewarns of a twisty road ahead. (A winding road is defined as having at least three bends.) You should slow down since this route will bend to the right and left. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202002061307305376.jpg","correctAnswer":19534,"isBookmarked":0,"aid":"19532#19533#19534#19535","text":"a narrow road ahead#a sharp curve ahead#a winding road ahead#a median ahead"},
{"testId":663,"qid":27388,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Warning Signs; Signs; Chapter 2: Signals, Signs, and Pavement Markings; Pennsylvania Driver's Manual]","imageName":"202002061255268690.jpg","correctAnswer":33555,"isBookmarked":0,"aid":"15166#15187#33555#34377","text":"No left turn ahead#Roundabout ahead#A 270-degree turn ahead#Wrong way"},
{"testId":663,"qid":27389,"description":"You've parked with your back to a hill. If your vehicle has an automatic transmission, leave it in Park. If you drive a vehicle with a manual shift, you should leave it in:","explanation":"Set your gearbox to the opposite direction your car would roll if its brakes fail. When driving uphill, a car may slide backward. As a result, while parking your car uphill, you should choose Park (for an automatic gearbox) or first gear (for maximum forward torque with a manual transmission). [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202002070945089828.jpg","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":663,"qid":27390,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Everyday Driving Skills, Chapter 3: Learning to Drive, Pennsylvania Driver's Manual] [Communicating Your Intentions to Other Drivers, Everyday Driving Skills, Chapter 3: Learning to Drive]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":663,"qid":27391,"description":"You've come to a halt behind a school bus with flashing red lights and whose stop arm is extended. When are you able to pass?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":39825,"isBookmarked":0,"aid":"14964#14965#15242#39825","text":"When you see the way is clear#When the bus sounds its horn#When the flashing red lights are switched off#When the flashing red lights are switched off and the schoolchildren who left the bus have reached places of safety"},
{"testId":663,"qid":27392,"description":"What is the meaning of this sign?","explanation":"This sign warns of a traffic island or other impending hazard. It may be passed on either side. [Warning Signs; Signs; Pennsylvania Driver's Manual; Chapter 2: Signals, Signs, and Pavement Markings]","imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"19403#32664#32667#39106","text":"Traffic may flow on both sides#There is a Y-intersection ahead#There is a new lane ahead#You may pass a vehicle on either side"},
{"testId":663,"qid":27393,"description":"Which of the following statement regarding this parked car is correct?","explanation":"You may not park within 20 feet of a crosswalk at a junction in Pennsylvania. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202002181141488961.jpg","correctAnswer":39108,"isBookmarked":0,"aid":"33561#33562#39107#39108","text":"The car is legally parked because it is not parked in a crosswalk#The car may be parked here only if a licensed driver remains with the vehicle#The car must be parked at least 10 feet from the crosswalk#The car must be parked at least 20 feet from the crosswalk"},
{"testId":663,"qid":27394,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. Do you have to come to a stop here?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There is one exemption in Pennsylvania: you do not have to stop if the school bus is stopped on the other side of a split highway and the roadway is separated by a physical barrier. You should, however, slow down and continue with care past the school bus. (Note 1: A painted median strip or a center turn lane is NOT considered a barrier. Note 2: This exemption is not available in all states. Other exclusions are provided by certain states. Always verify the local driving rules while leaving Pennsylvania.) [Pennsylvania Driver's Manual, School Buses, Special Circumstances and Emergencies, Chapter 3: Learning to Drive]","imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#36039#39826#39827","text":"No#Yes, but then you can proceed when it is safe to do so#No, but you must slow down to 20 mph as you pass the school bus#Yes, you must stop until the flashing red lights have been turned off and the schoolchildren who left the bus have reached places of safety"},
{"testId":663,"qid":27395,"description":"Which of the following about parking at an intersection is most accurate?","explanation":"You may not park within 30 feet of a stop sign, yield sign, flashing signal, or traffic signal in Pennsylvania. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Parking, Everyday Driving Skills, Learning to Drive, Chapter 3: Pennsylvania Driver's Manual]","imageName":"202003051731089038.jpg","correctAnswer":39829,"isBookmarked":0,"aid":"39828#39829#39830#39831","text":"You must not park within 10 feet of any stop sign or traffic signal#You must not park within 30 feet of any stop sign or traffic signal#You must not park within 30 feet of any traffic signal#You must not park within 30 feet of any stop sign"},
{"testId":631,"qid":27396,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to oncoming vehicles. When the junction is clear, you may continue. [California Driver Handbook, Traffic Signs, Traffic Lights, and Signs]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#15474","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go"},
{"testId":631,"qid":27397,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the speed restriction is 50 mph. That means, you are not permitted to exceed 50 mph in this area. [White Regulatory Signs, Right-of-Way Rules, Road Laws, California Driver Handbook]","imageName":"202003151749335643.jpg","correctAnswer":23394,"isBookmarked":0,"aid":"15476#23394#23395","text":"The recommended speed limit is 50 mph#The speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":631,"qid":27398,"description":"What is the meaning of this sign?","explanation":"The recommended speed for this bend is 35 mph, according to this sign. [California Driver Handbook, Warning Signs, Right-of-Way Rules, Laws and Rules of the Road]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29941#29942","text":"There is a 35-degree curve ahead#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":631,"qid":27399,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two vehicles approach an uncontrolled junction (one without signs or signals), the vehicle on the left must yield. As a result, Car A must yield. [Intersections, Right-of-Way Rules, Road Laws, California Driver Handbook]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23400#23401#31031","text":"Car B must yield because it is on car A's right#Car B must yield because it is traveling straight through the intersection#Car A must yield because it is to the left of Car B"},
{"testId":631,"qid":27400,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must slow down and ready to yield to oncoming traffic. Be prepared to halt if necessary. [California Driver Handbook, Traffic Signs, Traffic Lights, and Signs]","imageName":"202007120502165773.jpg","correctAnswer":23404,"isBookmarked":0,"aid":"23404#23405#31033","text":"Slow down and prepare to yield to any pedestrians and traffic ahead#Come to a complete stop and proceed after yielding to any pedestrians and traffic#Slow down and prepare to yield to traffic ahead, but you will not need to stop"},
{"testId":631,"qid":27401,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"You must stop and look for an emergency vehicle with flashing lights. However, even if an emergency vehicle is coming, never block a junction. When an emergency vehicle approaches a junction, clear the intersection and then pull over. When you return to the road, you may not drive within 300 feet of an emergency vehicle whose lights are flashing. [Emergency Vehicles, Road Safety, California Driver Handbook]","imageName":"202001302235285631.jpg","correctAnswer":42316,"isBookmarked":0,"aid":"15492#23409#42316","text":"Maintain speed and direction; changing lanes or stopping is unpredictable and could be dangerous#Immediately pull over and stop#Pull over and stop unless you are in an intersection"},
{"testId":631,"qid":27402,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [California Driver Handbook, Traffic Signs, Traffic Lights, and Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#23411","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The maximum speed limit during the day is 35 mph"},
{"testId":631,"qid":27403,"description":"You approach an intersection with traffic lights. The signals are all off. Which should you do?","explanation":"When a pair of traffic lights is entirely blank, drive as though the junction is controlled by STOP signs in all directions. Before proceeding, you must halt and cede as necessary. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003051805205845.jpg","correctAnswer":23415,"isBookmarked":0,"aid":"23413#23414#23415","text":"Slow down and prepare to yield to any traffic ahead#Continue through as if you had a green light#Treat the intersection as if there were STOP signs in all directions"},
{"testId":631,"qid":27404,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"Although you may activate a green signal, this is not a protected turn. You must yield to pedestrians and oncoming traffic before turning. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202001301833114961.jpg","correctAnswer":42317,"isBookmarked":0,"aid":"15530#23418#42317","text":"Yes, but only if there is a LEFT TURN PERMITTED sign present#Yes, you can turn left, since this is a \"protected\" turn where no other cars are allowed to enter the intersection#Yes, you can turn left after yielding to oncoming vehicles"},
{"testId":631,"qid":27405,"description":"Is it legal to turn right at a red light?","explanation":"You may turn right on a red light if you come to a full stop and yield to any vehicles or pedestrians. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003112014364009.jpg","correctAnswer":23420,"isBookmarked":0,"aid":"23420#23421#23422","text":"Yes, but only after coming to a stop and yielding to traffic#Yes, but only if a sign permits it#No, you can never turn right on a red light"},
{"testId":631,"qid":27406,"description":"Which of the following statements about seat belts is correct in California?","explanation":"Everyone in your car must wear a seat belt or suitable kid restraints under California law. You and your passengers must wear seat belts if you are 16 or older. [California Driver Handbook, Seat Belts]","imageName":"202003151755527296.jpg","correctAnswer":20435,"isBookmarked":0,"aid":"20435#23424#23425","text":"Both of the above are true#You must wear a seat belt while driving#Every passenger must wear a seat belt or suitable child restraint"},
{"testId":631,"qid":27407,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [California Driver Handbook, Safe Driving Practices]","imageName":"202002181152514351.jpg","correctAnswer":15493,"isBookmarked":0,"aid":"15493#15671#23427","text":"Slow down#Turn left#Increase speed"},
{"testId":631,"qid":27408,"description":"What is the meaning of this sign?","explanation":"This sign directs motorists to remain to the right of an impending island or obstruction.","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"14994#14995#14996","text":"Keep right#No right turn ahead#Mandatory right turn ahead"},
{"testId":631,"qid":27409,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a STOP sign, you must come to a complete stop before the limit (stop) line, crosswalk, or intersection, whichever comes first. [California Driver Handbook, Traffic Signs, Traffic Lights, and Signs]","imageName":"202001301744345845.jpg","correctAnswer":44536,"isBookmarked":0,"aid":"14279#42318#44536","text":"Before the crosswalk#Before the limit line#Whichever of the above you reach first"},
{"testId":631,"qid":27410,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"You may turn in the direction of the green arrow via a clean junction. You shouldn't have to yield since this is a \"protected\" turn, which means that all cars coming from the other way are halted. Nonetheless, you should constantly be vigilant and ready to concede. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003051809194011.jpg","correctAnswer":42321,"isBookmarked":0,"aid":"15530#42320#42321","text":"Yes, but only if there is a LEFT TURN PERMITTED sign present#Yes, you can turn left, but you must yield to oncoming vehicles first#Yes, you can turn left because vehicles from other directions cannot enter the intersection"},
{"testId":631,"qid":27411,"description":"What is the meaning of this sign?","explanation":"This sign alerts vehicles to a hairpin turn ahead. [California Driver Handbook, Traffic Signs, Traffic Lights, and Signs]","imageName":"202002061247596804.jpg","correctAnswer":23433,"isBookmarked":0,"aid":"23431#23433#23434","text":"U-turns are permitted ahead#A hairpin curve is ahead#Wrong way; turn around and go back"},
{"testId":631,"qid":27412,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A traffic light that is solid yellow notifies vehicles that the signal is about to turn red. If it is safe to do so, you should come to a halt here. Otherwise, travel cautiously through the crossing. Never attempt to \"beat the light\" by speeding up before it turns red. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003051752141237.jpg","correctAnswer":15525,"isBookmarked":0,"aid":"15525#15526#15527","text":"Stop if it is safe; the signal will soon turn red#Speed up to get through before the signal turns red#Drive with caution as there may be a hazard ahead"},
{"testId":631,"qid":27413,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"On a green light, you may continue. You must, however, yield to any automobiles already at the junction. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003070857473536.jpg","correctAnswer":23436,"isBookmarked":0,"aid":"15504#15505#23436","text":"You are free to proceed#You cannot proceed#You are free to proceed as long as you yield to any traffic already in the intersection"},
{"testId":631,"qid":27414,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two automobiles approach at a junction at the same moment, the car on the right has the right-of-way. If this is not the case, the vehicle turning left must yield. As a result, Car A must yield here. [Intersections, Right-of-Way Rules, Road Laws, California Driver Handbook]","imageName":"202007120513316836.jpg","correctAnswer":23439,"isBookmarked":0,"aid":"23437#23438#23439","text":"Car A has the right-of-way#Car B must yield#Car A must yield"},
{"testId":631,"qid":27415,"description":"You're following a school bus when its yellow lights begin to flash. Which does this mean?","explanation":"Just before stopping for passengers, a school bus will utilize its yellow flashing lights. Following vehicles should slow down and ready to stop. [Around Children, Reducing Speeds, Traffic Laws and Rules, California Driver Handbook]","imageName":"202003051644415234.jpg","correctAnswer":38422,"isBookmarked":0,"aid":"23440#23442#38422","text":"The bus is preparing to stop for passengers; you must immediately stop#The bus is gesturing for you to pass; pass when safe#The bus is preparing to stop for passengers; you must slow down and prepare to stop"},
{"testId":631,"qid":27416,"description":"You need to stop and pick someone up. You can see that the curb is marked with different colors. Which color(s) can you stop in front of?","explanation":"You may make a brief stop to pick up a passenger at any of the following curbs: A green curb enables for short-term parking (check the curb or posted signs for the time limit). A white curb enables for a quick stop to pick up or drop off people or mail. A yellow curb allows for a temporary pause to pick up or drop off persons or cargo. [California Driver Handbook, Parking at Colored Curbs]","imageName":"202001241520198299.jpg","correctAnswer":23446,"isBookmarked":0,"aid":"23444#23445#23446","text":"White and yellow#White and green#White, green, and yellow"},
{"testId":631,"qid":27417,"description":"Car B has entered an intersection to make a right turn on a red light. Car A then approaches a green light. Which of the following statements is true? :","explanation":"Even if you have a green light, you must yield to automobiles already at the junction. As a result, since Car B has already reached the junction, Car A must yield to Car B. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202001271450464240.jpg","correctAnswer":42322,"isBookmarked":0,"aid":"23447#23448#42322","text":"Car B should stop and let car A through#Car A should speed up to pass car B#Car A must yield to car B"},
{"testId":631,"qid":27418,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red traffic light should be treated as a STOP indication. You must stop, yield to traffic, and only continue when it is safe to do so. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003051722347868.jpg","correctAnswer":23451,"isBookmarked":0,"aid":"23451#23452#23453","text":"Stop, yield to traffic, and proceed when safe#Drive with caution because the traffic light is broken#Stop. You cannot proceed until the light has stopped flashing"},
{"testId":631,"qid":27419,"description":"Three vehicles arrive at an uncontrolled T-junction (i.e., one without signs or signals). In what order should they go?","explanation":"At T-intersections, cars on the through road have the right of way. Vehicles turning left must likewise surrender until it is safe to do so. As a result, car B may proceed first, followed by vehicle C, and finally by automobile A. [Intersections, Right-of-Way Rules, Road Laws, California Driver Handbook]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257","text":"A, B, C#C, B, A#B, C, A"},
{"testId":631,"qid":27420,"description":"What is the meaning of this sign?","explanation":"There is a railroad crossing ahead. Prepare to halt if necessary. [Traffic Signs, Traffic Lights, and Signs, California Driver Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15550#23456","text":"There is a crossroad ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":631,"qid":27421,"description":"What should you do if you see a flashing yellow light?","explanation":"When you see a flashing yellow traffic light, continue with care. You do not need to stop, but you must slow down and be extra cautious before approaching the crossing. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003070823014609.jpg","correctAnswer":23458,"isBookmarked":0,"aid":"15528#23457#23458","text":"Stop, yield, and proceed when safe#Prepare to stop; the light will soon turn red#Proceed with caution; slow down"},
{"testId":631,"qid":27422,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"When approaching a red flashing school bus, you must stop at least 10 feet away from the bus. [Around Children, Reducing Speeds, Traffic Laws and Rules, California Driver Handbook]","imageName":"202003051658529900.jpg","correctAnswer":23460,"isBookmarked":0,"aid":"23460#23461#23462","text":"No, you must come to a complete stop#Yes, but you must come to a complete stop and then yield before proceeding#Yes, but you must slow down and prepare to stop"},
{"testId":631,"qid":27423,"description":"Who has the right-of-way in this situation?","explanation":"When entering or about to enter a crosswalk, pedestrians have the right-of-way. Not every crossing has pavement markings, and not every crosswalk is on a street corner. In this case, the pedestrian has the right of way. Car B may go even if it does not have the legal right-of-way since it is not in the pedestrian's path. [Crosswalks, Right-of-Way Rules, Traffic Laws, California Driver Handbook]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14862#14863","text":"Car A#Car C#The pedestrian"},
{"testId":631,"qid":27424,"description":"When is it legal to turn left at a red light?","explanation":"When going from one one-way road to another one-way road in California, you may turn left on a red signal. You must still come to a full stop and yield to any oncoming vehicles. (Note: Some states' regulations regarding left turns on red may vary.) [Turns, Laws, and Road Rules, California Driver Handbook]","imageName":"202007120412393828.jpg","correctAnswer":15032,"isBookmarked":0,"aid":"6334#9435#15032","text":"Never#At any time#When you are turning from one one-way road onto another one-way road"},
{"testId":631,"qid":27425,"description":"Which vehicle is not properly using the roundabout?","explanation":"Car C has entered the roundabout in the incorrect lane. [Roundabouts, Right-of-Way Rules, Traffic Laws, California Driver Handbook]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862","text":"Car A#Car B#Car C"},
{"testId":631,"qid":27426,"description":"You're going to turn to the left into a driveway. What do you need to do before you can move on?","explanation":"Turning left requires you to yield to all vehicles until it is safe to continue. You must also always yield to pedestrians. [Intersections, Right-of-Way Rules, Road Laws, California Driver Handbook]","imageName":"202001241717155576.jpg","correctAnswer":23466,"isBookmarked":0,"aid":"23464#23465#23466","text":"Yield to pedestrians#Yield to traffic#Yield to pedestrians and traffic"},
{"testId":631,"qid":27427,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"When you wish to turn, explain your purpose ahead of time. If you want to turn, you must begin signaling at least 100 feet before the turn, according to California law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [California Driver Handbook, Safe Driving Practices]","imageName":"202001291433312120.jpg","correctAnswer":42324,"isBookmarked":0,"aid":"42323#42324#42325","text":"No, you only have to signal when other vehicles are present#Yes, for 100 feet before you turn#Yes, for 3 to 5 seconds before you turn"},
{"testId":631,"qid":27428,"description":"Which of the following should be adhered to above all others?","explanation":"Even if it means breaking another driving law, you must obey a traffic officer's instructions. For example, if a traffic official waves you through, you may drive through a red signal or pass a stopped school bus. [Emergency Vehicles, Road Safety, California Driver Handbook]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"14884#14885#14886","text":"A red traffic signal#A prohibitory road sign#A traffic officer"},
{"testId":631,"qid":27429,"description":"You parked your vehicle so that it faces down a steep hill. Always put on the parking brake before leaving the vehicle:","explanation":null,"imageName":"202002071736477410.jpg","correctAnswer":42326,"isBookmarked":0,"aid":"42326#42327#42328","text":"in gear or in the 鈥減ark鈥 position#unlocked#in top gear"},
{"testId":631,"qid":27430,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"The motorist plans to turn left. [Signaling, Safe Driving Practices, California Driver Handbook]","imageName":"202002181155395841.jpg","correctAnswer":15671,"isBookmarked":0,"aid":"15493#15671#23427","text":"Slow down#Turn left#Increase speed"},
{"testId":631,"qid":27431,"description":"What is the meaning of this sign?","explanation":"This sign alerts cars that a crossroads is coming. Drivers should reduce their speed and prepare for the junction. [California Driver Handbook, Warning Signs, Right-of-Way Rules, Laws and Rules of the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead"},
{"testId":631,"qid":27432,"description":"Who must give way?","explanation":"You must come to a complete stop and yield to pedestrians as they approach the road. The safety of pedestrians is critical. A pedestrian has right of way if they are on a corner or crosswalk. In addition, if a pedestrian establishes eye contact with you, it indicates that the pedestrian is ready to cross the street. Car A should yield to the pedestrian in this case. [Pedestrians, Right-of-Way Rules, Traffic Laws, California Driver Handbook]","imageName":"202002181109457082.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14863#14882","text":"Car A#The pedestrian#Whoever enters the intersection last"},
{"testId":631,"qid":27433,"description":"You notice a traffic signal with a flashing yellow arrow. What can you do here?","explanation":"You have the right of way if you turn in the direction of a flashing yellow arrow. Before turning, you must yield to oncoming traffic. [California Driver Handbook, Traffic Signal Lights, Traffic Lights and Signs]","imageName":"202003070851013728.jpg","correctAnswer":42329,"isBookmarked":0,"aid":"23472#23473#42329","text":"You can turn left freely here#You can turn left or proceed forward here#You can turn left after you yield to oncoming vehicles"},
{"testId":631,"qid":27434,"description":"This roadway's centre is delineated by solid and broken yellow lines. What is this area's function?","explanation":"This is a main turning lane. Vehicles may turn left in this lane (or make U-turns where permitted). [California Driver Handbook, Center Left Turn, Traffic Lanes]","imageName":"202001301806403967.jpg","correctAnswer":42331,"isBookmarked":0,"aid":"42330#42331#42332","text":"Vehicles from either direction may use this lane for passing#Vehicles from either direction may use this lane to turn left#Vehicles from either direction may use this lane to turn right"},
{"testId":631,"qid":27435,"description":"Which of the following is true about a lane in the middle of the road?","explanation":"In a central turning lane, you can drive for up to 200 feet but only to turn left or make a U-turn. [California Driver Handbook, Center Left Turn, Traffic Lanes]","imageName":"202001301806403967.jpg","correctAnswer":23480,"isBookmarked":0,"aid":"23479#23480#23481","text":"You cannot make a U-turn from this lane#You can only drive in this lane for 200 feet#You can use this lane as a pick-up or drop-off zone"},
{"testId":631,"qid":27436,"description":"Is it permissible for this car to park in front of a fire hydrant?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in California. [Illegal Parking, Parking, Traffic Laws and Rules, California Driver Handbook]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#21234#23482","text":"No, you cannot park within 15 feet of a fire hydrant#Yes#Yes, as long as it is moved in 10 minutes"},
{"testId":631,"qid":27437,"description":"In case you came across this sign, it signifies you are :","explanation":"The WRONG WAY sign may or may not be put beside the DO NOT ENTER sign. If you observe one or both of these signs, pull over to the side of the road and stop. You're driving against traffic and risks a head-on accident. When it is safe to do so, back out or turn around and return to the road you were on. If you are driving in the incorrect direction at night, the road reflectors will show red in your headlights. [Warning Signs, Traffic Signs, Traffic Controls, California Driver Handbook]","imageName":"202004071536046199.jpg","correctAnswer":33991,"isBookmarked":0,"aid":"10009#10010#33991","text":"in the wrong lane#On an expressway#going against traffic"},
{"testId":631,"qid":27438,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree curve ahead. Prepare to slow down. [Warning Signs, Right-of-Way Rules, Laws and Rules of the Road, California Driver Handbook]","imageName":"202002061255268690.jpg","correctAnswer":23486,"isBookmarked":0,"aid":"23434#23486#23487","text":"Wrong way; turn around and go back#There is a 270-degree turn ahead#There is a roundabout or rotary ahead"},
{"testId":631,"qid":27439,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":"Turn your front wheels away from the curb and allow your car to drift back a few inches while parking uphill. The wheel should make a gentle contact with the curb. Apply the parking brake. [Parking, Parking on a Hill, California Driver Handbook]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#14638#42333","text":"Away from the curb#Toward the curb#Toward the shoulder of the road"},
{"testId":631,"qid":27440,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"The motorist plans to turn right. [Signaling, Safe Driving Practices, California Driver Handbook]","imageName":"202002181150078740.jpg","correctAnswer":15672,"isBookmarked":0,"aid":"15493#15671#15672","text":"Slow down#Turn left#Turn right"},
{"testId":631,"qid":27441,"description":"You've stopped behind a school bus whose red lights are flashing. When do you get to pass?","explanation":"You must come to a complete stop behind any school bus with flashing red lights. You can't go any farther until the red lights are switched off. [Around Children, Reducing Speeds, Traffic Laws and Rules, California Driver Handbook]","imageName":"202003051658529900.jpg","correctAnswer":42334,"isBookmarked":0,"aid":"14964#15077#42334","text":"When you see the way is clear#When there are no children present#When the flashing red signals are turned off"},
{"testId":631,"qid":27442,"description":"What is the meaning of this sign?","explanation":"This sign informs cars that they may bypass an impending impediment on the left or right. [California Driver Handbook, Warning Signs, Right-of-Way Rules, Laws and Rules of the Road]","imageName":"202002101234545593.jpg","correctAnswer":23492,"isBookmarked":0,"aid":"23490#23491#23492","text":"A Y-intersection is ahead#You can overtake on either side#An obstacle is ahead; pass on either side"},
{"testId":631,"qid":27443,"description":"You're driving along the right side of a four-lane highway. You notice an emergency vehicle ahead with its lights blazing. What are you supposed to do here?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":42335,"isBookmarked":0,"aid":"15493#42335#42336","text":"Slow down#Change lanes if possible; otherwise, slow down#Slow down; also change lanes if possible"},
{"testId":631,"qid":27444,"description":"You come upon a lane with these markings. What kind of lane is it?","explanation":"This is a carpool lane, often known as a High-Occupancy Vehicle (HOV) lane. This lane is only for buses, motorcyclists, low-emission vehicles, and automobiles carrying a limited number of people. [Traffic Lanes, Carpool/High Occupancy Vehicle (HOV) Lanes, California Driver Handbook]","imageName":"202001291436063384.jpg","correctAnswer":23497,"isBookmarked":0,"aid":"23497#23498#42337","text":"A carpool or HOV lane#An emergency stopping lane#A left-turn lane"},
{"testId":631,"qid":27445,"description":"You come to a train crossing. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"When stopping at a railroad crossing, keep at least 15 feet away. You are not permitted to pass if the signal lights are flashing, the signal bell is ringing, or the boom gate is not fully up. A second train may be coming even after the first has gone. [Reducing Speeds, Laws and Rules of the Road, California Driver Handbook, Near Railroad Tracks]","imageName":"202003161136554337.jpg","correctAnswer":23501,"isBookmarked":0,"aid":"23501#23502#23503","text":"You must stop at least 15 feet away from the tracks#You can stop within 5 feet of the tracks#You can cross as soon as the train has passed"},
{"testId":649,"qid":27446,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to oncoming vehicles. When the junction is clear, you may continue. [Stopping, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15474#15475#30391","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and then go#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding"},
{"testId":649,"qid":27447,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the legal speed limit is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Regulatory Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15479#23395#29939","text":"The recommended speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph#The legal speed limit is 50 mph"},
{"testId":649,"qid":27448,"description":"What is the meaning of this sign?","explanation":"This advisory sign indicates that the speed limit for this bend is 35 mph. [Warning Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":649,"qid":27449,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield in this situation. [Right of Way and Yielding, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":649,"qid":27450,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Regulatory Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":649,"qid":27451,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must pull over and stop, regardless of which way the emergency vehicle is driving. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over to a complete stop. [Minnesota Driver's Manual, Chapter 4 Sharing the Road, Yield to Emergency Vehicles]","imageName":"202001302235285631.jpg","correctAnswer":31037,"isBookmarked":0,"aid":"31034#31035#31036#31037","text":"Maintain your speed and direction; changing lanes or stopping is unpredictable and possibly dangerous#Pull over and stop, if the emergency vehicle is traveling in the same direction as you are#Pull over and stop, if the emergency vehicle is traveling in the opposite direction#Pull over and stop, no matter which direction the emergency vehicle is traveling in"},
{"testId":649,"qid":27452,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Intersection Warning Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15498#15499#33516","text":"The recommended speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph#The legal speed limit is 35 mph"},
{"testId":649,"qid":27453,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic signals at a junction are damaged in Minnesota, the crossing should be treated as an uncontrolled intersection (one without signs or signals). That is, you must yield to any car that arrived at the junction before you, as well as any vehicle on your right that arrived at the same time as you. It should be noted that the restrictions for this vary per state. In some other states, for example, a junction with damaged traffic lights is considered an all-way stop. That is, you must come to a full stop before yielding. Always verify the local traffic restrictions while going out of state. [Traffic Control Signals, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003051805205845.jpg","correctAnswer":29346,"isBookmarked":0,"aid":"23414#28880#29346#33517","text":"Continue through as if you had a green light#Treat the intersection as an all-way stop#Treat the intersection as an uncontrolled intersection#Stop as if you had a red light"},
{"testId":649,"qid":27454,"description":"You come to a place where the light is green. Can you go to the left?","explanation":"On a green signal, you may turn left, but you must first yield to pedestrians and vehicles already at the junction, who have the right-of-way. You must also give way to incoming traffic. [Intersection Gridlock, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202001301833114961.jpg","correctAnswer":33521,"isBookmarked":0,"aid":"33518#33519#33520#33521","text":"Yes, but only if there is a \"Left Turn Permitted\" sign present#No, you cannot turn left unless there is a green arrow pointing left#Yes, you can turn left because this is a \"protected\" turn#Yes, you can turn left after yielding to pedestrians and vehicles already in the intersection"},
{"testId":649,"qid":27455,"description":"Is it legal to turn right at a red light?","explanation":"Unless there are indications banning it, you may turn right on a red light. You must first come to a full stop and then yield to pedestrians and traffic. [Turning on a Red Light, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#23423#33522","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, but not during rush hour#No. In Minnesota, you cannot turn right at a red signal"},
{"testId":649,"qid":27456,"description":"Which of the following statement is CORRECT about seat belts?","explanation":"Minnesota law requires that the driver and each passenger use a seat belt or a proper child restraint. [Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202003151755527296.jpg","correctAnswer":33524,"isBookmarked":0,"aid":"25356#33523#33524#33525","text":"Only the driver must wear a seat belt#The driver and every front-seat passenger must wear a seat belt or suitable child restraint#The driver and every passenger must wear a seat belt or suitable child restraint#Seat belts are optional"},
{"testId":649,"qid":27457,"description":"On a two-lane road, which of the following is true about passing?","explanation":null,"imageName":"202002181239175594.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#33526#33527#33528","text":"All of the above are true#You must return to your lane before coming within 100 feet of an oncoming vehicle#You may exceed the speed limit by 10 mph when you are passing on a two-lane highway whose posted speed limit is 55 mph or higher#You must use your left turn signal before you move into the left lane to pass"},
{"testId":649,"qid":27458,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block. [Regulatory Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"814#1353#14994#14995","text":"Right turn ahead#Divided highway ahead#Keep right#No right turn ahead"},
{"testId":649,"qid":27459,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"Stop before the stop line if you get to a junction controlled by a stop sign. Stop before the crosswalk if there is no stop line. Stop before approaching the junction if there is no crosswalk. [Stopping, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":649,"qid":27460,"description":"Which of the following is true about a green arrow that points to the left?","explanation":"You may turn in that way without risk at a green arrow. This is a \"protected\" turn, meaning that the green arrow is lighted while the incoming traffic is halted at a red signal. But you should constantly be ready for the unforeseen (such as an oncoming vehicle that illegally runs the red light). [Minnesota Driver's Manual, Chapter 5: Signs, Signals, and Pavement Markings, Intersection Gridlock]","imageName":"202003051809194011.jpg","correctAnswer":33531,"isBookmarked":0,"aid":"33529#33530#33531#33532","text":"You can only turn left if there is a \"Left Turn Permitted\" sign present#You can either turn left or go straight#You can turn left because this is a \"protected\" turn#You can turn left, but you must first yield to oncoming vehicles"},
{"testId":649,"qid":27461,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Intersection Warning Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":649,"qid":27462,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, try to come to a halt. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Intersection Warning Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003051752141237.jpg","correctAnswer":29961,"isBookmarked":0,"aid":"28857#29961#29962#29963","text":"Speed up to get through the signal before it turns red#Stop if it is safe to do so; the signal will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":649,"qid":27463,"description":"You reach a junction with a green light. Which of the following statements is correct?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Intersection Gridlock, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":649,"qid":27464,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles arrive at an uncontrolled junction or all-way stop about the same time, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the motorist turning left must yield to approaching traffic. As a result, Car A must yield here. [Right of Way and Yielding, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23401#23403#23523","text":"Car A must yield because it is turning left#Car B must yield because it is traveling straight through the intersection#None of the above are true#Car A can go first because it is turning"},
{"testId":649,"qid":27465,"description":"A school bus is what you're following. The yellow lights on it begin to flash. What do you need to do?","explanation":"A school bus will flash its yellow lights immediately before stopping for passengers. If it is legal and safe to do so, you may continue to pass the bus on the left; otherwise, you must stop. When the bus's flashing red lights appear, you must come to a complete stop. [Flashing Yellow Lights, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202003051644415234.jpg","correctAnswer":33534,"isBookmarked":0,"aid":"33533#33534#33535#33536","text":"You may pass the bus on the right if it is lawful and safe; otherwise, you must stop#You may pass the bus on the left if it is lawful and safe; otherwise, you must stop#You may pass the bus on either side if it is lawful and safe; otherwise, you must stop#You cannot pass the bus; you must stop"},
{"testId":649,"qid":27466,"description":"What is the meaning of this sign?","explanation":"This sign indicates that you may not drive slower than 40 mph under normal traffic, weather, and road conditions. Such minimum speeds are stated in order to promote traffic flow and safety. When driving conditions are bad, you may drive at a lower pace than the stated minimum. [Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202003301801385834.jpg","correctAnswer":33539,"isBookmarked":0,"aid":"33537#33538#33539#33540","text":"The legal minimum speed here is 40 mph at all times#The recommended minimum speed here is 40 mph at all times#The legal minimum speed here is 40 mph under normal weather, road, and traffic conditions#The recommended minimum speed here is 40 mph under normal weather, road and traffic conditions"},
{"testId":649,"qid":27467,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any cars and pedestrians already present. Because Car B has already reached the junction, Car A must yield to Car B. [Intersection Gridlock, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#23403#25313","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B because Car B is already in the intersection"},
{"testId":649,"qid":27468,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Intersection Gridlock, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#25316#28864#29330","text":"Stop and yield as you would at a stop sign#Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken"},
{"testId":649,"qid":27469,"description":"Which of the following statement about road repair zones is correct?","explanation":"Reduce your speed and proceed with care in work or construction zones. Always stick to the stated speed limit. If there is no posted speed restriction, you may travel no faster than 45 mph if employees are present. [Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202002101222518967.jpg","correctAnswer":33542,"isBookmarked":0,"aid":"23403#32624#33541#33542","text":"None of the above are true#After being waved through a work zone, you should drive quickly to avoid holding up traffic#You don't need to obey posted speed limits in a work zone if there are no workers present#In work zones, you must observe posted speed limits. Slow down and use caution"},
{"testId":649,"qid":27470,"description":"What is the meaning of this sign?","explanation":"There is a railroad crossing ahead. Keep an eye out for warning signs and oncoming trains. Slow down and be ready to stop if necessary. [Warning Signs, Chapter 5 Signs, Signals, and Pavement Markings, Minnesota Driver's Manual]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":649,"qid":27471,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, yield to vehicles and pedestrians, and continue with care if you observe a flashing yellow light at a junction. [Intersection Gridlock, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26784#29963#31848","text":"Slow down and proceed with caution#Stop if safe; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight when safe; you cannot turn here"},
{"testId":649,"qid":27472,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"Vehicles in both directions must stop at least 20 feet away from a school bus that has stopped with its red lights flashing and its stop arm extended. They must halt until the red lights cease blinking and the stop arm retracts. [MN Driver's Manual, Chapter 4 Sharing the Road, Passing a School Bus]","imageName":"202003051658529900.jpg","correctAnswer":23460,"isBookmarked":0,"aid":"23460#23461#23463#33543","text":"No, you must come to a complete stop#Yes, but you must come to a complete stop and then yield before proceeding#Yes, but you cannot exceed 20 mph while doing so#Yes, but you must slow down and prepare to stop for any children on the road"},
{"testId":649,"qid":27473,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In an uncontrolled crossing, each automobile must yield to the one on its right after taking into account pedestrians. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Watch for Pedestrians, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":649,"qid":27474,"description":"When is it legal to turn left at a red light?","explanation":"When going from one one-way street to another one-way street, you may turn left on a red signal. Before continuing, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Michigan), you may also turn left on red from a two-way street into a one-way street. Other states, such as South Dakota, outright ban left turns on red. Always verify the local driving rules while leaving Minnesota. [Turning on a Red Light, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#9435#14865#29959","text":"Never#At any time#When you are entering an expressway#When you are turning from a one-way street onto another one-way street"},
{"testId":649,"qid":27475,"description":"Which vehicle is not properly using the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C has made an improper left turn from the right lane. [Roundabouts, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":649,"qid":27476,"description":"You're going to turn to the left into a driveway. What do you need to do before you can move on?","explanation":"You must yield to all pedestrians and approaching cars before turning left off a road. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual] [Right of Way and Yielding, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202001241717155576.jpg","correctAnswer":31057,"isBookmarked":0,"aid":"23464#31056#31057#31058","text":"Yield to pedestrians#Yield to oncoming vehicles#Yield to pedestrians and oncoming vehicles#Proceed with caution; you have the right-of-way"},
{"testId":649,"qid":27477,"description":"You intend to turn at a junction and cannot see any other vehicles in the area. Is it still necessary to signal?","explanation":"Before turning in Minnesota, you must signal for at least 100 feet. You must keep signaling until your turn or merging is completed. Even if you can't see any people or other cars, you must signal. The most deadly car on the road may be one you don't see. [Signaling, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#33545#33546","text":"No#Yes, for at least 100 feet#Yes, for at least 300 feet if you are driving at or above 35 mph#Yes, for at least 100 feet if you are driving at less than 35 mph and 300 feet if you are driving at or above 35 mph"},
{"testId":649,"qid":27478,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A persistent yellow \"X\" above your lane indicates that traffic will soon shift, at which time the signal will change to a steady red \"X.\" Before this occurs, you must change lanes. [Lane Use Control Signals, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003051705034327.jpg","correctAnswer":33547,"isBookmarked":0,"aid":"27272#28148#33547#33548","text":"This is a high-occupancy vehicle (HOV) lane#Proceed with caution#Move into another lane as soon as it is safe to do so#You can only make a left turn from this lane"},
{"testId":649,"qid":27479,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving any farther. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking on a Hill, Parking, Traffic Laws and Vehicle Operation, Chapter 3 of the Minnesota Driver's Manual]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":649,"qid":27480,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"A left turn hand and arm signal is a straight out to the left hand and arm. [Hand and Arm Signals, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6476#33549","text":"Turn left#Turn right#Slow down#go faster"},
{"testId":649,"qid":27481,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Intersection Warning Signs, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":649,"qid":27482,"description":"On a red light, a vehicle is going right. Simultaneously, a pedestrian begins to cross the road that the vehicle is going to enter. Which one must give way?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a marked or unmarked crossing, you must always yield to a pedestrian. As a result, the pedestrian has the right of way here. [Watch for Pedestrians, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202002181109457082.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"10634#14814#14863#14882","text":"Neither#Car A#The pedestrian#Whoever enters the intersection last"},
{"testId":649,"qid":27484,"description":"You come to a railroad crossing with red flashing lights and warning bells. It's clear that a train is coming. Which of the following is true?","explanation":"If the boom gate is lowered or the flashing lights or warning bells go off at a railroad crossing, you must stop at least 10 feet away from the boom gate or the closest rail. (You don't want to get much closer since trains are at least six feet broader than the railroad track.) Stay still until the audio and visual alerts have subsided and the boom gate, if any, has fully lifted. [Chapter 4 of Minnesota Driver's Manual's Sharing the Road section on railroad crossing warning devices]","imageName":"202003161136554337.jpg","correctAnswer":33550,"isBookmarked":0,"aid":"23403#27277#33550#33551","text":"None of the above are true#You can cross the tracks as soon as the train has passed#You must stop at least 10 feet away from the tracks or boom gate#You can proceed while the red lights are flashing if there is only one railroad track and you are sure that no train is approaching"},
{"testId":649,"qid":27485,"description":"Is this car parked legally?","explanation":"Parking is not permitted within 50 feet of the closest rail of a railroad crossing in Minnesota. [Parallel Parking, Parking, Traffic Laws and Vehicle Operation, Chapter 3 of the Minnesota Driver's Manual]","imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27280#33552#33553#33554","text":"No, you must not park within 50 feet of a railroad crossing#No, you must not park within 15 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you're not on the tracks#Yes, you can park near a railroad crossing as long as you remain behind the stop line"},
{"testId":649,"qid":27486,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 10 feet of a fire hydrant is illegal in Minnesota. The fire department must have constant access to the hydrant. Other states may have different minimum distance requirements. Always verify the local driving regulations while going out of state. [Parallel Parking, Parking, Traffic Laws and Vehicle Operation, Chapter 3 of the Minnesota Driver's Manual]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":649,"qid":27487,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if you operate an automatic, choose low gear to avoid brake wear. [Intersection Warning Signs, Chapter 5 Signs, Signals, and Pavement Markings, Minnesota Driver's Manual]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#31341#33380","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#There is an emergency truck escape ramp ahead#This route is for trucks only; find another route"},
{"testId":649,"qid":27488,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Intersection Warning Signs, Chapter 5 Signs, Signals, and Pavement Markings, Minnesota Driver's Manual]","imageName":"202002061255268690.jpg","correctAnswer":33555,"isBookmarked":0,"aid":"14961#14963#15187#33555","text":"Wrong way; turn around and go back#No direct left turn ahead#Roundabout ahead#A 270-degree turn ahead"},
{"testId":649,"qid":27489,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking on a Hill, Parking, Traffic Laws and Vehicle Operation, Chapter 3 of the Minnesota Driver's Manual]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":649,"qid":27490,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"A right turn is indicated by the left forearm pointing up. [Hand and Arm Signals, Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6476#33549","text":"Turn left#Turn right#Slow down#go faster"},
{"testId":649,"qid":27491,"description":"You've come to a halt behind a school bus with flashing red lights and whose stop arm is extended. When are you able to pass?","explanation":"You must stop at least 20 feet away from a school bus that has stopped with its red lights flashing and its stop arm extended, regardless of which direction you are going in. You must stay stopped until the red lights cease blinking and the stop arm retracts. [Flashing Red Lights, Minnesota Driver's Manual, Chapter 4 Sharing the Road]","imageName":"202003051658529900.jpg","correctAnswer":33557,"isBookmarked":0,"aid":"14965#33556#33557#33558","text":"When the bus sounds its horn#When the red lights stop flashing#When the red lights stop flashing and the stop arm is retracted#When you see that the way is clear"},
{"testId":649,"qid":27492,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a police officer's instructions, even if it means violating other traffic devices or laws. For example, if a police officer waves you through a red light or past a stopped school bus, you should proceed. [Traffic Officers, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202002211500121915.jpg","correctAnswer":16212,"isBookmarked":0,"aid":"2316#2529#16212#32656","text":"A stop sign#A traffic signal#A police officer#A stopped school bus with flashing red lights"},
{"testId":649,"qid":27493,"description":"Which of the following statement regarding this parked car is correct?","explanation":"At a junction, you must park at least 20 feet away from a crossing. [Parallel Parking, Parking, Traffic Laws and Vehicle Operation, Chapter 3 of the Minnesota Driver's Manual]","imageName":"202002181141488961.jpg","correctAnswer":33560,"isBookmarked":0,"aid":"33559#33560#33561#33562","text":"The car must be at least 10 feet from the crosswalk#The car must be at least 20 feet from the crosswalk#The car is legally parked because it is not parked in a crosswalk#The car may be parked here only if a licensed driver remains with the vehicle"},
{"testId":649,"qid":27494,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction are normally required to stop for a school bus that has stopped with its red lights flashing and stop arm extended. There is, however, one exception to this rule. You do not need to stop if the school bus is on the other side of a separated (split) highway. [MN Driver's Manual, Chapter 4 Sharing the Road, Passing a School Bus]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#29354#33563#33564","text":"No, you do not need to stop here#Yes, you must briefly stop and yield to pedestrians on the road#No, but you must slow down to 20 mph or less and proceed with caution#Yes, you must stop until the red lights stop flashing and the stop arm is retracted"},
{"testId":649,"qid":27495,"description":"On a four-lane highway, you are on the right lane. You see an emergency vehicle stopped with its lights flashing in front of you. What should you do?","explanation":"To pass a stopped emergency vehicle, go into a non-adjacent lane if practicable and safe, leaving at least one empty lane between you and the emergency vehicle. If this is not possible, slow down to a relatively safe speed as you pass. If the flashing lights on maintenance vehicles, construction vehicles, or tow trucks are active, this law also applies to them. [Minnesota Driver's Manual, Chapter 4 Sharing the Road, Passing Parked Emergency/Service Vehicles]","imageName":"202001241703386758.jpg","correctAnswer":33566,"isBookmarked":0,"aid":"26363#33565#33566#33567","text":"Stop#Proceed as usual#Leave an empty lane between your car and the emergency vehicle if possible; otherwise, slow down#If the speed limit exceeds 40 mph, leave an empty lane between your car and the emergency vehicle if possible; otherwise, slow down"},
{"testId":636,"qid":27496,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. When the junction is clear, continue while giving way to pedestrians and other cars. [Fla. Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs, Traffic Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":636,"qid":27497,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Florida Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":636,"qid":27498,"description":"What is the meaning of this sign?","explanation":"The suggested speed limit for this bend is 35 mph, according to this sign. [Florida Driver License Handbook, Section 4: Traffic Controls, Warning Signs]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":636,"qid":27499,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an open junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right. Car A must yield in this situation. [Open Intersections, Intersections, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":636,"qid":27500,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Florida Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":636,"qid":27501,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"If an emergency vehicle approaches you from either direction with its siren and flashing lights on, you must go through any intersection, yield to the emergency vehicle, pull over to the nearest side of the roadway, and stay stopped until the emergency vehicle has passed. [Florida Driver License Handbook, Section 6: Sharing the Road, Emergency Vehicles]","imageName":"202001302235285631.jpg","correctAnswer":31037,"isBookmarked":0,"aid":"31037#38647#41834#41835","text":"Pull over and stop, no matter which direction the emergency vehicle is traveling in#Maintain your speed and direction#Pull over and stop, but only if the emergency vehicle is traveling in the same direction as you are#Pull over and stop, but only if the emergency vehicle is traveling in the opposite direction"},
{"testId":636,"qid":27502,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Florida Driver License Handbook, Section 4: Traffic Controls, Warning Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":636,"qid":27503,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"In Florida, if the approaching traffic light is not operating, consider the junction as an all-way stop (i.e., as if there were a stop sign at each approach to the intersection). That is, you must come to a complete stop before approaching the junction, yield according to stop sign right-of-way standards, and then continue (with great care) when it is safe to do so. [Traffic Control Signals, Florida Driver License Handbook, Section 4: Traffic Controls]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#28148#37531#41836","text":"Treat the intersection like an all-way stop#Proceed with caution#Yield as you would at a yield sign#Treat the intersection like an open intersection (i.e., one without signs or signals)"},
{"testId":636,"qid":27504,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458#39805","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":636,"qid":27505,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Florida unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#39805#41837","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you'll have the right-of-way#No. In Florida, you cannot turn right at a red signal"},
{"testId":636,"qid":27506,"description":"Which occupants of a vehicle are required by Florida law to wear safety belts or proper child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":41838,"isBookmarked":0,"aid":"4420#16365#26667#41838","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and all passengers under 18"},
{"testId":636,"qid":27507,"description":"Which of the following statements concerning passing on a two-lane road in Florida is FALSE?","explanation":"Make certain that other drivers are aware of your desire to pass. In Florida, you must begin signaling before entering the left lane. Tap your horn (during the day) or flash your headlights (at night) to alert the other car that you are passing. When passing, you are not permitted to exceed the posted speed limit. You must finish your pass before getting within 200 feet of incoming traffic. [Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202002181239175594.jpg","correctAnswer":41839,"isBookmarked":0,"aid":"27237#41839#41840#41841","text":"You must return to your lane before coming within 200 feet of oncoming traffic#You may exceed the speed limit by 10 mph to pass another vehicle safely#You must start to signal before you move into the left lane#You should use your horn or headlights to alert the driver whom you are passing"},
{"testId":636,"qid":27508,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or other impediment ahead. Maintain your position. [Florida Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs]","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"19246#26190#38076#41842","text":"Keep right#The divided highway ends#You must turn right#You must not turn right"},
{"testId":636,"qid":27509,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. Stop before the crosswalk if there is no stop line. Stop before approaching the junction if there is no crosswalk. [Florida Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":636,"qid":27510,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":"A flashing yellow arrow indicates a left turn. You, however, do not have the right-of-way. Because oncoming traffic has a green signal, you may be required to yield before turning left. [Flashing Signal Lights, Traffic Control Signals, Florida Driver License Handbook, Section 4: Traffic Controls]","imageName":"202003070851013728.jpg","correctAnswer":34199,"isBookmarked":0,"aid":"33172#34199#34200#41843","text":"The signal will soon turn red; stop if you can do so safely#You can turn left here, but you must first yield to oncoming traffic#You cannot turn left here; find another route#You can turn left here; oncoming traffic is stopped"},
{"testId":636,"qid":27511,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Florida Driver License Handbook, Section 4: Traffic Controls, Warning Signs]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":636,"qid":27512,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":636,"qid":27513,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":636,"qid":27514,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles approach at a junction about the same time, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the motorist turning left must yield to approaching traffic. Car A must yield to Car B in this situation. [Open Intersections, Intersections, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23523#36008#39459","text":"Car A must yield because it is turning left#Car A can go first because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":636,"qid":27515,"description":"Three vehicles are sequentially approaching this four-way stop. Which vehicle should go first?","explanation":"When cars arrive at an all-way stop at various times in Florida, they must advance in the order in which they arrived. Car B arrived first, thus it should take the lead after halting. [Florida Driver License Handbook, Section 4: Traffic Controls, Regulatory Signs]","imageName":"202002181231057295.jpg","correctAnswer":39472,"isBookmarked":0,"aid":"39460#39461#39472#39473","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car B, because it arrived at the intersection first#Car C, because it is to the right of Car A and Car B"},
{"testId":636,"qid":27516,"description":"In Florida, you're driving on an interstate highway with a speed restriction of 70 miles per hour. Which of the following statements is correct?","explanation":null,"imageName":"202003301803164994.jpg","correctAnswer":41845,"isBookmarked":0,"aid":"36011#41844#41845#41846","text":"The minimum speed limit is not enforceable#The minimum speed limit here is 40 mph#The minimum speed limit here is 50 mph#The minimum speed limit is enforceable only if it is posted"},
{"testId":636,"qid":27517,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":636,"qid":27518,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Flashing Signal Lights, Traffic Control Signals, Florida Driver License Handbook, Section 4: Traffic Controls]","imageName":"202003051722347868.jpg","correctAnswer":39095,"isBookmarked":0,"aid":"28864#29330#39095#40943","text":"Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Stop, yield to pedestrians and traffic, and then proceed when it is safe to do so#Proceed; you do not have to stop or yield at a flashing red signal"},
{"testId":636,"qid":27519,"description":"You parked with your back to the hill. If you have an automatic transmission, keep it in Park. You should keep your vehicle in _________, if you drive a manual (stick shift).","explanation":"Set your gearbox to the opposite direction your car would roll if its brakes fail. A downhill-facing vehicle may roll forward. As a result, while parking your car facing downhill, you should choose Park (for an automatic gearbox) or Reverse (for a manual transmission). [Parking on Hills, Parking, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202002071736477410.jpg","correctAnswer":31053,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":636,"qid":27520,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Florida Driver License Handbook, Section 4: Traffic Controls, Railroad Crossing Signs and Signals]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":636,"qid":27521,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [Flashing Signal Lights, Traffic Control Signals, Florida Driver License Handbook, Section 4: Traffic Controls]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":636,"qid":27522,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":41847,"isBookmarked":0,"aid":"23463#41847#41848#41849","text":"Yes, but you cannot exceed 20 mph while doing so#No, you must stop and remain stopped until the STOP arm is retracted#Yes, but you must stop and then yield before you proceed#Yes, but you must slow down and yield to children"},
{"testId":636,"qid":27523,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an open crossroads (one without signs or signals), each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Florida Driver License Handbook, Section 6: Sharing the Road, Rules for Motorists, Sharing the Road with Pedestrians]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":636,"qid":27524,"description":"When can you turn left on red light?","explanation":"In Florida, unless there is a \"No Turn on Red\" sign placed, you may turn left on a red light while turning from one one-way street into another one-way street. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#9435#16495#29959","text":"Never#At any time#When you are turning onto a one-way street#When you are turning from a one-way street onto another one-way street"},
{"testId":636,"qid":27525,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts and Intersections, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":636,"qid":27526,"description":"You must yield to _________ before turning left into a driveway.","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":636,"qid":27527,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#31059#41850","text":"No#Yes, for at least 100 feet#Yes, for at least 300 feet#Yes, for at least 100 feet below 35 mph and at least 300 feet at or above 35 mph"},
{"testId":636,"qid":27528,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":"Lane signals are unique overhead signals that indicate which lanes of a road may be utilized in different directions at different times. A persistent yellow \"X\" above your lane indicates that it will soon shut to traffic in your direction. The indicator will then shift to a constant red \"X.\" Before this occurs, you must change lanes. [From the Florida Driver License Handbook, Section 4: Traffic Controls, Lane Signals]","imageName":"202003051705034327.jpg","correctAnswer":39691,"isBookmarked":0,"aid":"28148#34510#39691#41583","text":"Proceed with caution#This lane is only for making turns#Change lanes as soon as it is safe to do so#This lane is closed in your direction"},
{"testId":636,"qid":27529,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse for a manual transmission). [Parking on Hills, Parking, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":636,"qid":27530,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"The motorist plans to turn left. [Making Turns, Section 5: Driving Safely, Florida Driver License Handbook]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":636,"qid":27531,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Florida Driver License Handbook, Section 4: Traffic Controls, Warning Signs]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":636,"qid":27532,"description":"On a red light, Car A is making a right turn. A pedestrian begins to cross the road that the automobile is ready to enter at the same moment. Which one has to give way?","explanation":"Car A must stop and yield to traffic and pedestrians before turning at the red signal. As a result, the pedestrian has the right of way here. [Florida Driver License Handbook, Section 4: Traffic Controls, Steady Signal Lights, Traffic Control Signals]","imageName":"202003051817433687.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"10634#14814#14863#14882","text":"Neither#Car A#The pedestrian#Whoever enters the intersection last"},
{"testId":636,"qid":27533,"description":"Which of the following about Florida's speed limits is most accurate?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":41853,"isBookmarked":0,"aid":"41851#41852#41853#41854","text":"You should always try to drive at the posted speed limit#You may drive at whatever speed you want, as long as it is below the posted speed limit#Under poor driving conditions, you must drive below the posted speed limit#You may exceed the posted speed limit only if you are passing another vehicle on a two-lane road"},
{"testId":636,"qid":27534,"description":"You come to a train crossing. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"If the railroad crossing lights and gates warn of an impending train, you must come to a complete stop between 15 and 50 feet from the closest rail. Even after the train has past, use cautious and keep an eye on the signals, gates, and tracks. There might be another train after this one. Only until the signals have ceased and the gates have been fully lifted may you continue to cross the tracks. [Florida Driver License Handbook, Section 4: Traffic Controls, Railroad Crossing Signs and Signals]","imageName":"202003161136554337.jpg","correctAnswer":41855,"isBookmarked":0,"aid":"23029#41855#41856#41857","text":"All of the above are true#You must stop at least 15 feet from the tracks#You may cross the tracks as soon as the train has passed#You may proceed across if there is only one railroad track and you're sure that no trains are approaching"},
{"testId":636,"qid":27535,"description":"Is this car parked legally?","explanation":"Parking is not permitted within 50 feet of the closest rail of a railroad crossing in Florida. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Where Parking is Not Allowed, Parking, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27280#27282#33553#39824","text":"No, you must not park within 50 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you stay behind the stop line#Yes, you can park near a railroad crossing as long as you're not on the tracks#No, you must not park within 100 feet of a railroad crossing"},
{"testId":636,"qid":27536,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 15 feet of a fire hydrant is illegal in Florida. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Where Parking is Not Allowed, Parking, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":636,"qid":27537,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car is automated, move into a low gear to keep your brakes from fading (losing their effectiveness). [Florida Driver License Handbook, Section 4: Traffic Controls, Warning Signs]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#40948","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This road is for trucks only"},
{"testId":636,"qid":27538,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Warning Signs, Traffic Signs, Section 4: Traffic Controls, Florida Driver License Handbook]","imageName":"202002061255268690.jpg","correctAnswer":33555,"isBookmarked":0,"aid":"15166#15187#33555#34377","text":"No left turn ahead#Roundabout ahead#A 270-degree turn ahead#Wrong way"},
{"testId":636,"qid":27539,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking on Hills, Parking, Florida Driver License Handbook, Section 5: Driving Safely]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":636,"qid":27540,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture denotes a turn to the right. [From the Florida Driver License Handbook, Making Turns, Section 5: Driving Safely]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":636,"qid":27541,"description":"You've come to a complete halt behind a school bus with its red lights blazing and STOP arm outstretched on an open road. The kids are almost done crossing the street. When are you able to pass?","explanation":"If you approach a school bus that is stopped on an undivided highway with its red lights flashing and its STOP arm extended, you must stop before reaching the bus, whether it is on your side of the road or the opposing side. You must maintain your halt until the Cease arm retracts and the red lights stop blinking. You should also wait until all of the youngsters have safely off the street before proceeding. [You Must Not Pass, Section 5 of the Florida Driver License Handbook, Driving Safely]","imageName":"202003051658529900.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#33556#41858#44809","text":"All of the above#When the red lights stop flashing#When the STOP arm is retracted#When the children have cleared the road"},
{"testId":636,"qid":27542,"description":"Which of the following should be adhered to above all others?","explanation":"You must always obey police officers, even if it means violating other traffic devices or norms. For example, if a police officer waves you through a red light or past a stopped school bus, you should proceed. [Florida Driver License Handbook, Section 7: Special Driving Situations, Following Law Enforcement Instructions]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#14884#14886#41859","text":"A stop sign#A red traffic signal#A traffic officer#The flashing red lights of a stopped school bus"},
{"testId":636,"qid":27543,"description":"Which of the following statement regarding this parked car is correct?","explanation":null,"imageName":"202002181141488961.jpg","correctAnswer":39108,"isBookmarked":0,"aid":"33561#33562#39107#39108","text":"The car is legally parked because it is not parked in a crosswalk#The car may be parked here only if a licensed driver remains with the vehicle#The car must be parked at least 10 feet from the crosswalk#The car must be parked at least 20 feet from the crosswalk"},
{"testId":636,"qid":27544,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. Do you have to come to a stop here?","explanation":"You are not required to stop in Florida if the school bus is stopped on the other side of a highway separated by a raised barrier or an unpaved median at least five feet wide. You should, however, proceed with care. Children are unlikely to understand the laws of the road, therefore they may do something unexpected. [Florida Driver License Handbook, Section 6: Sharing the Road, School Buses]","imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#36039#39826#41860","text":"No#Yes, but then you can proceed when it is safe to do so#No, but you must slow down to 20 mph as you pass the school bus#Yes, and you must remain stopped until the STOP arm is retracted and all children have safely left the road"},
{"testId":636,"qid":27545,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":"When you come across an emergency vehicle, sanitation vehicle, utility vehicle, or tow truck that is stopped with its flashing lights on, you must shift into a non-adjacent lane if feasible, leaving at least one unoccupied lane between you and the vehicle, according to Florida's Move Over Law. If this is difficult or dangerous (no alternative lanes in your direction), you must slow down until you have past the car. If the listed speed limit is more than 20 miles per hour, you must slow down to 20 miles per hour; otherwise, you must slow down to 5 miles per hour. (Note: Although every state in the United States currently has its own Move Over Law, some of them compel drivers to perform various things. Always verify the local traffic rules while going out of state.) [Florida Driver License Handbook, Section 6: Sharing the Road, Florida Move Over Law]","imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#35268#39336#45612","text":"Proceed with caution#Stop behind the emergency vehicle#Move into a non-adjacent lane if possible; otherwise slow down#Stop your vehicle in a non-adjacent lane"},
{"testId":644,"qid":27546,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to any oncoming vehicles or pedestrians. When the junction is clean and there are no incoming cars that may pose a problem, you can continue. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15474#15475#30391","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and then go#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding"},
{"testId":644,"qid":27547,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the legal speed limit is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Kentucky Driver Manual, Standard Shapes]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":644,"qid":27548,"description":"What is the meaning of this sign?","explanation":"The recommended speed for this bend is 35 mph, according to this caution sign. [Curves, Road Conditions, Speed Adjustment, Kentucky Driver Manual]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":644,"qid":27549,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. As a result, Car A must yield. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":644,"qid":27550,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#25283#31032#31033","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Maintain your speed since all traffic ahead must yield to you#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop"},
{"testId":644,"qid":27551,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must pull over and stop, regardless of which way the emergency vehicle is driving. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over to a complete stop. [Allowing a Defensive Driving Space Cushion, Keeping a Defensive Driving Space Cushion, Kentucky Driver Manual]","imageName":"202001302235285631.jpg","correctAnswer":31037,"isBookmarked":0,"aid":"31034#31035#31036#31037","text":"Maintain your speed and direction; changing lanes or stopping is unpredictable and possibly dangerous#Pull over and stop, if the emergency vehicle is traveling in the same direction as you are#Pull over and stop, if the emergency vehicle is traveling in the opposite direction#Pull over and stop, no matter which direction the emergency vehicle is traveling in"},
{"testId":644,"qid":27552,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Kentucky Driver Manual, Standard Shapes]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":644,"qid":27553,"description":"Which of the following statement concerning work zones is correct?","explanation":"You must adhere to the specified work zone speed limitations. If you're caught speeding in a construction zone, your punishment might be twice. [Highway Construction Zones, Speed Adjustment, Kentucky Driver Manual]","imageName":"202002101222518967.jpg","correctAnswer":31040,"isBookmarked":0,"aid":"23403#31038#31039#31040","text":"None of the above are true#Work zone speed limits are not enforced#After being directed through a work zone, you should drive quickly to avoid holding up traffic#You must observe work zone speed limits; fines for speeding there may be doubled"},
{"testId":644,"qid":27554,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must first yield to all cars and pedestrians approaching the junction, since they have the right-of-way. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202003070857473536.jpg","correctAnswer":31042,"isBookmarked":0,"aid":"26814#29951#31041#31042","text":"Yes, but only if there is a \"Left Turn Permitted\" sign posted#Yes, because this is a \"protected\" turn; no other vehicles are allowed to enter the intersection#No, you cannot turn left unless there is also a green arrow pointing left#Yes, you can turn left, but only after yielding to pedestrians and traffic"},
{"testId":644,"qid":27555,"description":"Is it legal to turn right at a red light?","explanation":"At a red light, you have the option of turning right. However, you must first come to a full stop and yield to vehicles and pedestrians. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#15510#26802","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right at a red light#Yes, this is a \"protected\" turn, so you have the right-of-way"},
{"testId":644,"qid":27556,"description":"When transporting a kid of _____ inches or less in height, any motorist must use a child restraint device that meets federal motor vehicle safety requirements.","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":45167,"isBookmarked":0,"aid":"45165#45166#45167#45168","text":"fifty (50)#sixty (60)#forty (40)#forty-five (45)"},
{"testId":644,"qid":27557,"description":"On a two-lane road, which of the following is true about passing?","explanation":"When you are within 100 feet of an incoming vehicle, Kentucky law compels you to perform a pass. However, in many cases, you should accomplish the pass much sooner. If your car and an incoming vehicle are both moving at 50 mph, a 100-foot gap will be closed in less than one second. Wait for a better chance if you are hesitant about a pass. [Pass, Sharing Space, Kentucky Driver's Handbook]","imageName":"202002181239175594.jpg","correctAnswer":31842,"isBookmarked":0,"aid":"23029#31842#31843#31844","text":"All of the above are true#You must return to your lane before coming within 100 feet of oncoming traffic#You may exceed the speed limit by 10 mph when you are passing on a two-lane road whose posted speed limit is 55 mph or higher#You must alert the driver you are passing by beeping your horn in the daytime or flashing your lights at night"},
{"testId":644,"qid":27558,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block. [Kentucky Driver Manual, Standard Shapes]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":644,"qid":27559,"description":"You have come to a place where there is a STOP sign. Where do you have to stop?","explanation":"When you come to a halt at a junction controlled by a stop sign, you must stop before the stop line, if there is one. Stop before the crosswalk if there is no stop line. If there is no crosswalk, come to a complete stop before entering the crossing. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202001301744345845.jpg","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30651#31045#31046","text":"All of the above#The intersection#The stop line if any#The crosswalk if any"},
{"testId":644,"qid":27560,"description":"Which of the following statement about a flashing yellow arrow is correct?","explanation":"After yielding to pedestrians and traffic, a flashing yellow arrow indicates that you may turn in the direction of the arrow. [Lighted Arrows, Road Rules, Kentucky Driver Manual]","imageName":"202003070851013728.jpg","correctAnswer":31050,"isBookmarked":0,"aid":"31047#31048#31049#31050","text":"You should stop if it is safe to do so; the arrow will soon turn red#You can turn left or go straight#You can turn left because this is a \"protected\" turn; no other vehicles are allowed to enter the intersection#You may turn left after yielding to pedestrians and traffic"},
{"testId":644,"qid":27561,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Regulatory Signs, Traffic Signals, and the Kentucky Driver Handbook]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":644,"qid":27562,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, try to come to a halt. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202003051752141237.jpg","correctAnswer":29961,"isBookmarked":0,"aid":"28857#29961#29962#29963","text":"Speed up to get through the signal before it turns red#Stop if it is safe to do so; the signal will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":644,"qid":27563,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":644,"qid":27564,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles approach at a junction at the same moment, the vehicle on the left must yield to the one on the right. If this is not the case, the vehicle turning left must yield to approaching traffic. As a result, Car A must yield here. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23401#23523#29964","text":"Car A must yield because it is turning left#Car B must yield because it is traveling straight through the intersection#Car A can go first because it is turning#The two drivers must decide which of them will yield"},
{"testId":644,"qid":27565,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":644,"qid":27566,"description":"You're driving at 50 miles per hour. It begins to rain heavily. Which of the following actions should you take?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"6238#23000#31051#31052","text":"Reduce your speed#Do all of the above#Switch on your headlights#Turn off the cruise control"},
{"testId":644,"qid":27567,"description":"Car B has entered an intersection to make a right turn on a red light. Following that, Car A has reached a green light and want to go straight through the junction. Which of the following statements is correct? :","explanation":"Even if the signal is green, you must yield to all pedestrians and cars already at the junction. As a result, since Car B has already reached the junction, Car A must yield to Car B here. [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202001271450464240.jpg","correctAnswer":29970,"isBookmarked":0,"aid":"15540#15541#23403#29970","text":"Car B should stop and let Car A through#Car A should speed up to pass Car B#None of the above are true#Car A must yield to Car B, because Car B is already in the intersection"},
{"testId":644,"qid":27568,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before crossing a crosswalk or junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Flashing Lights, Road Rules, Kentucky Driver Manual]","imageName":"202003051722347868.jpg","correctAnswer":31845,"isBookmarked":0,"aid":"25316#28864#29330#31845","text":"Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Stop and then yield as you would at a stop sign"},
{"testId":644,"qid":27569,"description":"You parked with your back to the hill. If you have an automatic transmission, keep it in Park. You should keep your car in _________, if you drive a manual (stick shift).","explanation":"When parking a car, configure the gearbox to prevent the vehicle from rolling if the brakes fail. Your car may slide forward if it is pointing downward. As a result, you should park an automatic gearbox and reverse a manual transmission. Always use the parking brake. [Parking, Road Rules, Kentucky Driver Manual]","imageName":"202002071736477410.jpg","correctAnswer":31053,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":644,"qid":27570,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. If you notice a train approaching, prepare to halt. You must also come to a complete stop if you see a set of red flashing lights, a sign, a traffic cop, or a lowered gate. [Regulatory Signs, Traffic Signals, and the Kentucky Driver Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":644,"qid":27571,"description":"What should you do if you see a flashing yellow light?","explanation":"You must slow down, give way to oncoming vehicles and pedestrians, and continue cautiously while a flashing yellow light is present. There may be a danger up ahead. [Kentucky Driver Manual, Rules of the Road, Flashing Lights]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":644,"qid":27572,"description":"What is the meaning of this sign?","explanation":"This sign tells you that a traffic circle is ahead and that you should turn right to join it. [Roundabouts and Traffic Circles, Kentucky Driver Manual, Rules of the Road]","imageName":"202002101237092503.jpg","correctAnswer":31846,"isBookmarked":0,"aid":"15672#23431#31846#31847","text":"Turn right#U-turns are permitted ahead#There is a traffic circle ahead; keep right#There is a flashing traffic signal ahead; proceed with caution"},
{"testId":644,"qid":27573,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In an uncontrolled crossing, each automobile must yield to the one on its right after taking into account pedestrians. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":644,"qid":27574,"description":"When is it legal to turn left at a red light?","explanation":"If you are going from the left lane of a one-way street into the left lane of another one-way roadway in Kentucky, you may make a left turn at a red light. You must come to a full stop before yielding to any vehicles. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.) [Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202007120412393828.jpg","correctAnswer":31055,"isBookmarked":0,"aid":"6334#9435#14865#31055","text":"Never#At any time#When you are entering an expressway#When you are turning from a one-way road onto another one-way road"},
{"testId":644,"qid":27575,"description":"Which vehicle is not properly using the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Turn left out of the left lane, right out of the right lane, then drive straight through utilizing either lane. Car C has made an improper left turn from the right lane. [Roundabouts and Traffic Circles, Kentucky Driver Manual, Rules of the Road]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"897#14814#14815#14862","text":"None of the above#Car A#Car B#Car C"},
{"testId":644,"qid":27576,"description":"You're going to turn to the left into a driveway. What do you need to do before you can move on?","explanation":"Before turning left, you must yield to any pedestrians and incoming cars. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202001241717155576.jpg","correctAnswer":31057,"isBookmarked":0,"aid":"23464#31056#31057#31058","text":"Yield to pedestrians#Yield to oncoming vehicles#Yield to pedestrians and oncoming vehicles#Proceed with caution; you have the right-of-way"},
{"testId":644,"qid":27577,"description":"You intend to turn at a junction and cannot see any other vehicles in the area. Is it still necessary to signal?","explanation":"Before turning at a junction in Kentucky, you must signal for at least 100 feet. (Note: Some states have differing laws on this.) Always verify the local traffic rules while going out of state.) Even if you can't see any people or other cars, you must signal. The most deadly car on the road may be one you don't see. [When Changing Directions, Communicating with Other Drivers, Kentucky Driver Manual]","imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#31059#31060","text":"No#Yes, for at least 100 feet#Yes, for at least 300 feet#Yes, for at least 100 feet if your speed is below 35 mph and 300 feet otherwise"},
{"testId":644,"qid":27578,"description":"Car B wishes to cross the split highway that Car A is going on. Who has the right of way and why? :","explanation":"Minor road vehicles must yield to big road vehicles. Car B must yield here. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202002061630063390.jpg","correctAnswer":31061,"isBookmarked":0,"aid":"31061#31062#31063#31064","text":"Car A has the right-of-way because it is on a larger road than Car B#Car B has the right-of-way because it is to the right of Car A#Car B has the right-of-way because it arrived at the intersection first#Car B has the right-of-way because it is on a smaller road than Car A"},
{"testId":644,"qid":27579,"description":"You are parking next to the curb with your back to the hill. Which way should the front wheels face?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving any farther. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking, Road Rules, Kentucky Driver Manual]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":644,"qid":27580,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. Cars moving alongside or immediately behind a truck may be entirely obscured from vision of the truck driver. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. Remember, if you can't see the truck driver, he or she can't see you either. [Kentucky Driver Manual, No-Zone for Trucks]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":644,"qid":27581,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Regulatory Signs, Traffic Signals, and the Kentucky Driver Handbook]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":644,"qid":27582,"description":"On a red light, a vehicle is going right. Simultaneously, a pedestrian begins to cross the road that the vehicle is going to enter. Which one must give way?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a marked or unmarked crossing, you must always yield to a pedestrian. As a result, the pedestrian has the right of way here. [Right-of-Way Regulations, Traffic Signs, Road Rules, Kentucky Driver Manual]","imageName":"202002181109457082.jpg","correctAnswer":31065,"isBookmarked":0,"aid":"14863#31065#31066#31067","text":"The pedestrian#The car#Whichever one enters the intersection last#Whichever one prefers to wait"},
{"testId":644,"qid":27583,"description":"Which of the following statements regarding using headlights is FALSE?","explanation":"You must use your headlights from one-half hour after dusk to one-half hour before daybreak, according to Kentucky law. When vision is poor during the day, you must also use your headlights. Your parking lights are exclusively for parking. [How to Use Your Headlights, Communicate with Other Drivers, Kentucky Driver Manual]","imageName":"202003161149128995.jpg","correctAnswer":31070,"isBookmarked":0,"aid":"31068#31069#31070#31071","text":"You must turn on your headlights no later than one-half hour after sunset#You must use your headlights in fog or rain or whenever it is difficult to see#When visibility is poor in the daytime, your parking lights are sufficient#You should use your headlights whenever you believe other drivers may have difficulty seeing you"},
{"testId":644,"qid":27584,"description":"You come to a place where two trains meet. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"You must stop if the lights are flashing or the gates are lowered at crossings with automated flasher units or crossing gates, and you must stay stopped until the lights cease flashing and the gates are lifted. Even when the tracks seem to be clear, school buses and tankers transporting volatile substances are obliged to stop at railroad crossings. You should maintain a safe distance behind these cars and be ready to stop at any time. [Kentucky Driver Manual, Railroad Crossings, Taking in the Big Picture, Seeing Well]","imageName":"202003161136554337.jpg","correctAnswer":31072,"isBookmarked":0,"aid":"23029#27277#31072#31073","text":"All of the above are true#You can cross the tracks as soon as the train has passed#School buses and certain trucks must stop at railroad crossings even when the tracks appear clear#You can proceed while the lights are flashing if there is only one track and you are sure that no trains are approaching"},
{"testId":644,"qid":27585,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead. You must always yield to pedestrians at crosswalks in school zones and everywhere else. [Pedestrian/Crosswalks, Looking Around, Seeing Well, Kentucky Driver Manual]","imageName":"202003151742324658.jpg","correctAnswer":31074,"isBookmarked":0,"aid":"15594#15595#15597#31074","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a pedestrian crossing or crosswalk ahead; prepare to stop#There is a park or playground ahead; slow down and watch for pedestrians#There is a school zone ahead; schoolchildren may be crossing the road"},
{"testId":644,"qid":27586,"description":"You've parked with your back to a hill. If your vehicle has an automatic transmission, leave it in Park. If you drive a vehicle with a manual shift, you should leave it in:","explanation":"When parking a car, configure the gearbox to prevent the vehicle from rolling if the brakes fail. Your car may roll backward if it is looking uphill. As a result, for maximum forward torque, put an automatic gearbox in park and a manual transmission in first gear. Always use the parking brake. [Parking, Road Rules, Kentucky Driver Manual]","imageName":"202002070945089828.jpg","correctAnswer":10440,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":644,"qid":27587,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if you operate an automatic, choose low gear to avoid brake wear. [Regulatory Signs, Traffic Signs, Kentucky Driver Manual]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"15689#26841#28551#31075","text":"There is a runaway truck ramp ahead#There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route; find another route"},
{"testId":644,"qid":27588,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Regulatory Signs, Traffic Signs, Kentucky Driver Manual]","imageName":"202002061255268690.jpg","correctAnswer":27285,"isBookmarked":0,"aid":"14961#14963#15187#27285","text":"Wrong way; turn around and go back#No direct left turn ahead#Roundabout ahead#270-degree turn ahead"},
{"testId":644,"qid":27589,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking, Road Rules, Kentucky Driver Manual]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":644,"qid":27590,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"Though it is not required by Kentucky law, the Kentucky Driver Manual advises a following distance of at least 3 seconds under ideal circumstances. If the car in front of you comes to a sudden halt, you may crash with it if you are within 2.5 seconds of it. In bad weather or on slick roads, you should increase your following distance. [Kentucky Driver Manual, Keeping a Space Cushion, Keeping a Space Cushion]","imageName":"202001302249003541.jpg","correctAnswer":31079,"isBookmarked":0,"aid":"31076#31077#31078#31079","text":"Just close enough to make eye contact through the other vehicle's rear-view mirror#At least 10 seconds#At least 15 feet#At least 3 seconds"},
{"testId":644,"qid":27591,"description":"You've come to a halt behind a school bus with flashing red lights and whose stop arm is extended. When are you able to pass?","explanation":"You must yield to a school bus that has stopped to pick up passengers. You cannot continue until everyone has cleared the lane and the bus is moving. The sole exception is if the school bus has stopped on the other side of a four-lane roadway. You do not need to stop for the school bus in this scenario. [Allowing a Defensive Driving Space Cushion, Keeping a Defensive Driving Space Cushion, Kentucky Driver Manual]","imageName":"202003051658529900.jpg","correctAnswer":31080,"isBookmarked":0,"aid":"14965#15192#15194#31080","text":"When the bus sounds its horn#When all the children are off the road#When the bus switches on its yellow lights#When all the children are off the road and the bus is in motion"},
{"testId":644,"qid":27592,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a traffic officer's instructions, even if it means violating other traffic devices or laws. For example, if a traffic official waves you through a red light or a stop sign, you should proceed. [Left Turns, Red Light Turns, Road Rules, Kentucky Driver Manual]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#2529#14886#15196","text":"A stop sign#A traffic signal#A traffic officer#A prohibitory sign"},
{"testId":644,"qid":27593,"description":"You are on the right side of the road and facing uphill when you park. There is no stop sign. Which way should the front wheels face?","explanation":"When parking facing uphill without a curb, turn your wheels to the right. If your brakes fail, your car will drift away from oncoming traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking, Road Rules, Kentucky Driver Manual]","imageName":"202002181226366215.jpg","correctAnswer":6217,"isBookmarked":0,"aid":"6214#6217#9906#25321","text":"Left#Right#Straight#In any direction"},
{"testId":644,"qid":27594,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus that has stopped to pick up passengers. This law, however, does not apply if you are on the other side of a four-lane roadway. [Allowing a Defensive Driving Space Cushion, Keeping a Defensive Driving Space Cushion, Kentucky Driver Manual]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#31081#31082#31083","text":"No, you do not need to stop here#No, but you must slow down to 20 mph and proceed with caution#Yes, you must stop and then proceed when it is safe to do so#Yes, you must stop and remain stopped until the bus is in motion"},
{"testId":656,"qid":27596,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to any oncoming vehicles or pedestrians. When the junction is clean and there are no incoming cars that may pose a problem, you can continue. [Controlled, Intersections, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15474#15475#30391","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and then go#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding"},
{"testId":656,"qid":27597,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Appendix A: Driver Safety, The New Jersey Driver Manual, Regulatory Signs]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":656,"qid":27598,"description":"What is the meaning of this sign?","explanation":"The speed limit for this bend is 35 mph, as shown by this sign. [The New Jersey Driver Manual, Appendix A: Driver Safety, Warning Signs]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":656,"qid":27599,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield in this situation. [Intersections, Uncontrolled, Chapter 4: Safe Driving Rules & Regulations, The New Jersey Driver Manual]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":656,"qid":27600,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Controlled, Intersections, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":656,"qid":27601,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Pull Over and Stop for Emergency Vehicles]","imageName":"202001302235285631.jpg","correctAnswer":35998,"isBookmarked":0,"aid":"15493#26798#35997#35998","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the emergency vehicle is approaching you from behind#Pull over, no matter which direction the emergency vehicle is traveling in"},
{"testId":656,"qid":27602,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [The New Jersey Driver Manual, Appendix A: Driver Safety, Warning Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":656,"qid":27603,"description":"Which of the following statement about work zones in New Jersey is correct?","explanation":"You must adhere to the specified speed restrictions in work zones. Fines for moving offenses committed in a construction zone are doubled in New Jersey. [The New Jersey Driver Manual, Construction Zones/Work Zones, Driving Situations, Chapter 5: Defensive Driving]","imageName":"202002101222518967.jpg","correctAnswer":36000,"isBookmarked":0,"aid":"23403#31038#35999#36000","text":"None of the above are true#Work zone speed limits are not enforced#After being waved through a work zone, you should drive fast to avoid holding up traffic#Fines for speeding are doubled in work zones"},
{"testId":656,"qid":27604,"description":"You come to a place where the light is green. Can you go to the left?","explanation":"On a green light, you may turn left, but you must first yield to pedestrians and approaching cars, who have the right-of-way. [The New Jersey Driver Manual, Appendix A: Driver Safety, Green Light]","imageName":"202003070857473536.jpg","correctAnswer":36002,"isBookmarked":0,"aid":"33519#33859#36001#36002","text":"No, you cannot turn left unless there is a green arrow pointing left#Yes, you can turn left because this is a \"protected\" turn; oncoming traffic is stopped#Yes, but only if there is a Left Turn Permitted sign present#Yes, you can turn left, but only after yielding to pedestrians and oncoming vehicles"},
{"testId":656,"qid":27605,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in New Jersey unless there are signs banning it. You must first come to a full stop and then yield to pedestrians and traffic. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Right Turn on Red]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#25289#35926","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you will have the right-of-way#No. In New Jersey, you cannot turn right at a red signal"},
{"testId":656,"qid":27606,"description":"Which occupants of a passenger car in New Jersey are required to use a seat belt or a proper child restraint?","explanation":"Seat belts or appropriate kid restraints are required to be worn by all passengers of any passenger vehicle in New Jersey. The driver is legally required to ensure that all passengers under the age of 18 are properly restrained. Each passenger above the age of 18 may be penalized for not wearing a seat belt. [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Buckle Up- New Jersey's Seat Belt Law]","imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#36003","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#None (seat belts are optional in New Jersey)"},
{"testId":656,"qid":27607,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Left Turns, Turning Regulations]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":656,"qid":27608,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block. [The New Jersey Driver Manual, Appendix A: Driver Safety, Traffic Signs]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":656,"qid":27609,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop within five feet of the stop line. If there is no stop line, you must come to a complete stop within five feet of the crossing. If there is no crosswalk, you must come to a complete stop before approaching the crossing. [Controlled, Intersections, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":656,"qid":27610,"description":"As you follow a school bus, its yellow lights start to flash. What ought you to do?","explanation":"A school bus flashes its yellow lights to notify cars that it will soon stop for passengers and flash its red lights. You must halt at least 25 feet away from a school bus that is stopping for passengers. Keep your distance and ready to stop if you notice flashing yellow lights. However, don't rely on these yellow and red lights; they might be broken. Keep an eye on the school bus's movements and be prepared to halt if it stops for passengers. [Stop for School Buses, Stopping Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202003051644415234.jpg","correctAnswer":36004,"isBookmarked":0,"aid":"17332#36004#36005#36006","text":"Stop immediately#Keep your distance and prepare to stop#Prepare to stop right behind the bus#Prepare to pass the bus when it stops"},
{"testId":656,"qid":27611,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a hairpin turn ahead. Slow down and take your time. [The New Jersey Driver Manual, Appendix A: Driver Safety, Traffic Signs]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":656,"qid":27612,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Stopping Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202003051752141237.jpg","correctAnswer":35025,"isBookmarked":0,"aid":"28857#28858#35025#36007","text":"Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop before the intersection if it is safe to do so#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":656,"qid":27613,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [The New Jersey Driver Manual, Appendix A: Driver Safety, Green Light]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":656,"qid":27614,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two cars approach a junction at about the same moment, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the vehicle turning left must yield to approaching traffic. Car A must yield to Car B in this situation. [Controlled, Intersections, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#36008","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#The drivers should decide which of them should go first"},
{"testId":656,"qid":27615,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must give way to cars that arrived at the junction ahead of you during an all-way stop. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Controlled, Intersections]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":656,"qid":27616,"description":"You are traveling at the permitted speed of 50 mph. It begins to rain really severely. Which action should you undertake?","explanation":"The specified speed limit is based on the assumption of safe driving conditions. Slow down when the road is wet to avoid sliding or hydroplaning. (According to New Jersey law, you must slow down under dangerous driving circumstances.) Turn on your headlights to increase visibility in bad weather. (According to New Jersey law, you must turn on your headlights anytime you use your windshield wipers.) On wet, slippery, or snowy conditions, avoid using cruise control. When your car loses traction on a slick surface, the cruise control may spin the drive wheels in an attempt to maintain a consistent speed. This may exacerbate or even cause a skid. [The New Jersey Driver Manual, Chapter 5: Defensive Driving], [N.J.S.A. 39:4-98]","imageName":"202003151749335643.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"15493#17333#23000#31052","text":"Slow down#Turn on your headlights#Do all of the above#Turn off the cruise control"},
{"testId":656,"qid":27617,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Yielding the Right-of-Way]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":656,"qid":27618,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Appendix A: Driver Safety, The New Jersey Driver Manual, Flashing Red Light]","imageName":"202003051722347868.jpg","correctAnswer":33878,"isBookmarked":0,"aid":"25316#28864#29330#33878","text":"Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Come to a complete stop, yield, and then proceed when it is safe to do so"},
{"testId":656,"qid":27619,"description":"Which of the following is NOT true about speed limits on the highway?","explanation":null,"imageName":"202003301803164994.jpg","correctAnswer":36011,"isBookmarked":0,"aid":"36009#36010#36011#36012","text":"Keep up with traffic, but don't exceed the maximum speed limit#You can be cited for driving below the minimum speed limit#The minimum speed limit is not enforceable#If vehicle trouble prevents you from driving at or above the minimum speed, you must exit the highway"},
{"testId":656,"qid":27620,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [The New Jersey Driver Manual, Appendix A: Driver Safety, Traffic Signs]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":656,"qid":27621,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and take your time. [Appendix A: Driver Safety, The New Jersey Driver Manual, Flashing Yellow Light]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26784#29963#30157","text":"Slow down and proceed with caution#Stop if safe; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":656,"qid":27622,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 10 feet of a fire hydrant is illegal in New Jersey. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Do Not Park, Parking Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":656,"qid":27623,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In an uncontrolled crossing, each automobile must yield to the one on its right after taking into account pedestrians. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Pedestrians in a Crosswalk]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":656,"qid":27624,"description":"Which of the following is most correct about parking near a crossroads?","explanation":"Parking is prohibited within 25 feet of a crosswalk or 50 feet of a stop sign in New Jersey. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Do Not Park, Parking Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202002181224517197.jpg","correctAnswer":36014,"isBookmarked":0,"aid":"36013#36014#36015#36016","text":"You must not park within 10 feet of a stop sign or crosswalk#You must not park within 50 feet of a stop sign or 25 feet of a crosswalk#You must not park within 5 feet of a stop sign or 50 feet of a crosswalk#You can park as close to a stop sign or crosswalk as you want, as long as you don't pass the stop line or enter the crosswalk"},
{"testId":656,"qid":27625,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Driver Signals]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":656,"qid":27626,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to all pedestrians and approaching cars before turning left off a road. [People, The New Jersey Driver Manual, Chapter 8: Sharing the Road with Others]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"8750#32554#33884#33885","text":"pedestrians#oncoming vehicles#pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles"},
{"testId":656,"qid":27627,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You must signal for at least 100 feet before turning in New Jersey. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [Turning, The New Jersey Driver Manual, Chapter 3: Driver Responsibility]","imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#33545#36017","text":"No#Yes, for at least 100 feet#Yes, for at least 300 feet if you are driving at or above 35 mph#Yes, for at least 100 feet if you are driving below 35 mph or at least 300 feet if you are driving at or above 35 mph"},
{"testId":656,"qid":27628,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Driver Signals]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":656,"qid":27629,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your car begins to roll forward, the curb will prevent it from moving any farther. Make that your parking brake is still engaged and your gearbox is configured to resist forward movement (Park for an automatic transmission or Reverse for a manual transmission). [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Parking]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":656,"qid":27630,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. The truck's side mirrors only allow tiny arcs of view along the sides. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember, if you can't see the driver, chances are the motorist can't see you. [The New Jersey Driver Manual, Trucks, Tractor-Trailers, and Buses, Vehicles, Chapter 8: Sharing the Road with Others]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":656,"qid":27631,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [The New Jersey Driver Manual, Appendix A: Driver Safety, Traffic Signs]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":656,"qid":27632,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. Furthermore, whether at a marked or unmarked crossing, you must always yield to a pedestrian. As a result, the pedestrian has the right of way here. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Pedestrians in a Crosswalk]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#23543","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to avoid the pedestrian"},
{"testId":656,"qid":27633,"description":"Which of the following things about headlights is not true?","explanation":"In New Jersey, you must use your headlights from 30 minutes after dusk to 30 minutes before dawn. When your windshield wipers are switched on and visibility is poor at 500 feet, you must also activate your headlights. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Using Headlights]","imageName":"202003161149128995.jpg","correctAnswer":36019,"isBookmarked":0,"aid":"15516#31432#36018#36019","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You must use your headlights whenever visibility is difficult at 500 feet#You must use your headlights whenever you use your windshield wipers#You may drive with your parking lights on whenever visibility is poor during the day"},
{"testId":656,"qid":27634,"description":"You will soon reach a railroad crossing. The flashing lights and warning bell are currently NOT enabled. Which of the following statements are true?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":36020,"isBookmarked":0,"aid":"23029#36020#36021#36022","text":"All of the above are true#School buses and gasoline trucks must still stop here#Even if the flashing lights and warning bell become activated, you can still cross the tracks if you don't see a train approaching#Because the flashing lights and warning bell have not been activated, you can cross the tracks even if you see a train approaching"},
{"testId":656,"qid":27635,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead. The maximum speed restriction in a school zone in New Jersey is 25 mph. [The New Jersey Driver Manual, Speed Control, Safe Driving Rules & Regulations], [Traffic Signs, Appendix A: Driver Safety, ibid.]","imageName":"202003151742324658.jpg","correctAnswer":33890,"isBookmarked":0,"aid":"15594#15597#33890#36023","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a school zone ahead; slow down and proceed with caution#There is a crosswalk ahead; prepare to stop"},
{"testId":656,"qid":27636,"description":"You've parked your car in a downhill position. If you have a manual transmission, which gear should you leave it in?","explanation":"When parking a manual transmission car, adjust the gearbox to the opposite direction in which the vehicle may roll if its brakes fail. Set the gearbox to Reverse if the car is facing downward. Set the gearbox in first gear for greatest forward torque if the car is headed uphill. [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Parking]","imageName":"202002071736477410.jpg","correctAnswer":36024,"isBookmarked":0,"aid":"10440#31054#36024#36025","text":"First gear#The highest gear#Reverse#Neutral"},
{"testId":656,"qid":27637,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [The New Jersey Driver Manual, Appendix A: Driver Safety, Traffic Signs]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":656,"qid":27638,"description":"What is the meaning of this sign?","explanation":"There is a 270-degree turn ahead. Slow down and continue with care. [Traffic Signs, Appendix A: Driver Safety, The New Jersey Driver Manual]","imageName":"202002061255268690.jpg","correctAnswer":33555,"isBookmarked":0,"aid":"14961#15166#15187#33555","text":"Wrong way; turn around and go back#No left turn ahead#Roundabout ahead#A 270-degree turn ahead"},
{"testId":656,"qid":27639,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [The New Jersey Driver Manual, Chapter 3: Driver Responsibility, Parking]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":656,"qid":27640,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in New Jersey?","explanation":"Under normal driving circumstances, the New Jersey Driver Manual advises a following distance of at least three seconds behind the car in front of you. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [Chapter 5: Defensive Driving, The New Jersey Driver Manual, Keep a Safe Distance/Do Not Tailgate]","imageName":"202001302249003541.jpg","correctAnswer":36029,"isBookmarked":0,"aid":"36026#36027#36028#36029","text":"Close enough to make eye contact through the other driver's rear-view mirror#At least two seconds below 30 mph or three seconds at or above 30 mph#At least 30 feet under good driving conditions#At least three seconds under good driving conditions"},
{"testId":656,"qid":27641,"description":"You are driving on an undivided road. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"Vehicles moving in either direction must stop at least 25 feet from a school bus that has stopped with its red lights flashing in New Jersey. They must stay halted until the flashing red lights are turned off. [Stop for School Buses, Stopping Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202003051658529900.jpg","correctAnswer":36031,"isBookmarked":0,"aid":"36030#36031#36032#36033","text":"Stop at least 25 feet away if you are traveling in the same direction as the bus#Stop at least 25 feet away, no matter which direction you are traveling in#Slow down to 10 mph and proceed with caution#Stop at least 25 feet away, yield to pedestrians, and proceed at 20 mph or less"},
{"testId":656,"qid":27642,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"In New Jersey, a broken traffic signal must be treated as a stop sign. That is, you must stop, surrender, and advance only when it is safe to do so (with great care). If none of the junction's lights are functional, regard the intersection as an all-way stop. [Uncontrolled, Intersections, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations], [N.J.S.A. 39:4-81]","imageName":"202003051805205845.jpg","correctAnswer":45615,"isBookmarked":0,"aid":"34496#34771#36034#45615","text":"Sound your horn and drive through#Treat the intersection like an uncontrolled intersection#Proceed as if you had a green light#Treat the intersection like an four-way stop"},
{"testId":656,"qid":27643,"description":"Two cars are parked close to where the driveway to a fire station starts. Which of these is the most accurate?","explanation":"You must not park within 20 feet of a fire station's driveway entry or within 75 feet on the other side of the roadway. Car A must be at least 20 feet away from the driveway, while Car B must be at least 75 feet away. [Do Not Park, Parking Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202002070948543186.jpg","correctAnswer":36035,"isBookmarked":0,"aid":"36035#36036#36037#36038","text":"Car A must be parked at least 20 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 20 feet from the driveway#Car A must be parked at least 20 feet from the driveway; Car B can park anywhere#Car A can park anywhere as long as it doesn't block the driveway; Car B can park anywhere"},
{"testId":656,"qid":27644,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. Do you have to come to a stop here?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There is one exception to this provision in New Jersey: You may go at no more than 10 mph if the school bus is on the other side of a split roadway. (Note: Different states' laws may apply in this case.) Here are a few such examples: If the school bus is stopped on the other side of a split roadway in New Hampshire, you may drive at your normal speed. In New York, however, you must stop even if the school bus is on the other side of a split roadway. Always verify the local traffic rules while going out of state.) [Stop for School Buses, Stopping Regulations, The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations]","imageName":"202002010826098089.jpg","correctAnswer":35929,"isBookmarked":0,"aid":"35928#35929#35931#36039","text":"No, proceed as usual#No, but you must slow down to 10 mph as you pass the school bus#Yes, you must stop until the flashing red lights have been turned off#Yes, but then you can proceed when it is safe to do so"},
{"testId":656,"qid":27645,"description":"You're in the right lane of a multilane highway. An emergency vehicle with flashing lights is halted ahead. What are your responsibilities under the \"Move Over\" law?","explanation":"When approaching a stopped emergency vehicle with its lights flashing, you must leave an empty lane between your automobile and the emergency vehicle whenever feasible, according to New Jersey's Move Over legislation. If this is difficult, illegal, or hazardous, you must reduce your speed to a reasonable level (below the official speed limit) and be prepared to stop. Motorists who breach this legislation incur a fine of between $100 and $500. [The New Jersey Driver Manual, Chapter 4: Safe Driving Rules & Regulations, Move Over Law, Stopping Regulations]","imageName":"202001241703386758.jpg","correctAnswer":33566,"isBookmarked":0,"aid":"28148#33566#36040#36041","text":"Proceed with caution#Leave an empty lane between your car and the emergency vehicle if possible; otherwise, slow down#Slow down. Also leave an empty lane between your car and the emergency vehicle if possible#Leave an empty lane between your car and the emergency vehicle only if the speed limit is over 40 mph"},
{"testId":668,"qid":27646,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. When the junction is clear, continue while giving way to pedestrians and other cars. [Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers, Regulatory and Warning Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":668,"qid":27647,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Regulatory and Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":668,"qid":27648,"description":"What is the meaning of this sign?","explanation":"The suggested speed limit for this bend is 35 mph, according to this sign. [Regulatory and Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":668,"qid":27649,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right. Car A must yield in this situation. [Intersections Not Controlled by Signs, Signals, Multi-lanes, or Pavement; Right-of-Way at Intersections; Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":668,"qid":27650,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Regulatory and Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":668,"qid":27651,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":40934,"isBookmarked":0,"aid":"15493#38647#40933#40934","text":"Slow down#Maintain your speed and direction#Pull over to the right if possible#Yield to the emergency vehicle; also pull over to the right if possible"},
{"testId":668,"qid":27652,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Regulatory and Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":668,"qid":27653,"description":"Which of the following statement concerning work zones is correct?","explanation":"In work zones, go more slowly. You must abide by any traffic control devices, such as speed limit and channelizing signs. In Texas, while there are employees present, penalties for violating any traffic control device (such as a speed limit sign) are doubled. [Texas Driver Handbook, Construction and Maintenance Devices, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101222518967.jpg","correctAnswer":40937,"isBookmarked":0,"aid":"23403#40935#40936#40937","text":"None of the above are true#Speed limits are not enforced#After you've been waved through a work zone, you should drive fast to avoid holding up traffic#Fines for speeding may be doubled"},
{"testId":668,"qid":27654,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. [Steady Green Light (Go); Traffic Signals; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458#39805","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":668,"qid":27655,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Texas unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars. [Stay Red Light (Stop); Traffic Signals; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#39805#40938","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you'll have the right-of-way#No. In Texas, you cannot turn right at a red signal"},
{"testId":668,"qid":27656,"description":"In Texas, who must wear safety belts or the right kind of child restraints in a car?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#40859","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and rear-seat passengers under 17"},
{"testId":668,"qid":27657,"description":"Three vehicles arrive at an uncontrolled T-junction (i.e., one without signs or signals). In what order should they go?","explanation":"A vehicle on a T-terminating intersection's road must yield to all vehicles on the through (main) route. Car A must yield to Cars B and C in this situation. Car C, on the other hand, must yield to oncoming traffic since it is turning left at a junction. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [T-Intersection, Right-of-Way at Intersections, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":668,"qid":27658,"description":"This symbol warns of:","explanation":"This sign forewarns of a twisty road ahead. (A winding road is defined as having at least three bends.) You should slow down since this route will bend to the right and left. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002061307305376.jpg","correctAnswer":19534,"isBookmarked":0,"aid":"19532#19533#19534#19535","text":"a narrow road ahead#a sharp curve ahead#a winding road ahead#a median ahead"},
{"testId":668,"qid":27659,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before approaching the crossing. If there is no crosswalk, come to a complete stop before entering the crossing. [Regulatory and Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":668,"qid":27660,"description":"You've come to a halt in front of a school bus with its red lights flashing. When can you go ahead?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":234,"isBookmarked":0,"aid":"234#15242#40939#40940","text":"Any of the above#When the flashing red lights are switched off#When the bus resumes its motion#When the driver signals you to proceed"},
{"testId":668,"qid":27661,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":668,"qid":27662,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Caution; Traffic Signals; Chapter 5: Signals, Signs, and Markers; Texas Driver Handbook]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":668,"qid":27663,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Steady Green Light (Go); Traffic Signals; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":668,"qid":27664,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B in this situation. [Turning Left, Right-of-Way at Intersections, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#26796#36008#39459","text":"Car A must yield because it is turning left#Car A must yield because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":668,"qid":27665,"description":"Three vehicles are sequentially approaching this four-way stop. Which vehicle should go first?","explanation":"At an all-way stop, Texas law does not define which vehicle has the right-of-way. Texas drivers generally follow the norm applied in the majority of other states: while approaching an all-way stop, yield to cars that arrived before you. In other words, cars should move in the order they came. Car B may go first, followed by Car A and finally Car C. (Of course, each vehicle must come to a complete stop before proceeding.)","imageName":"202002181231057295.jpg","correctAnswer":39472,"isBookmarked":0,"aid":"39460#39461#39472#39473","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car B, because it arrived at the intersection first#Car C, because it is to the right of Car A and Car B"},
{"testId":668,"qid":27666,"description":"On a three-lane, one-way road, these three vehicles are now going. Both vehicles A and B want to merge into the same lane. Which vehicle has the right-of-way?","explanation":null,"imageName":"202002181222524910.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#40941#40942","text":"Car A#Car B#Neither Car A nor Car B#It cannot be determined from the information given"},
{"testId":668,"qid":27667,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Steady Green Light (Go); Traffic Signals; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":668,"qid":27668,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Stay Red Light (Stop); Traffic Signals; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202003051722347868.jpg","correctAnswer":39095,"isBookmarked":0,"aid":"28864#29330#39095#40943","text":"Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Stop, yield to pedestrians and traffic, and then proceed when it is safe to do so#Proceed; you do not have to stop or yield at a flashing red signal"},
{"testId":668,"qid":27669,"description":"Car B wishes to cross the split highway that Car A is going on. Who has the right of way and why? :","explanation":"Cars on a side road must yield to vehicles on a major road unless traffic signs or signals indicate otherwise (such as a divided highway). Car B must yield here. [Single- or two-lane road intersecting a multi-lane road, Right-of-Way at Intersections, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202002061630063390.jpg","correctAnswer":33879,"isBookmarked":0,"aid":"31062#31063#31064#33879","text":"Car B has the right-of-way because it is to the right of Car A#Car B has the right-of-way because it arrived at the intersection first#Car B has the right-of-way because it is on a smaller road than Car A#Car A has the right-of-way because it is on the main road"},
{"testId":668,"qid":27670,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Railroad Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":668,"qid":27671,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [Caution; Traffic Signals; Chapter 5: Signals, Signs, and Markers; Texas Driver Handbook]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":668,"qid":27672,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 15 feet of a fire hydrant is forbidden in Texas. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Do Not Park or Stand Vehicle; Texas Driver Handbook, Chapter 7: Parking, Stopping, or Standing]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":668,"qid":27673,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In the absence of signs or signals, each vehicle must yield to the vehicle on its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Intersections Not Controlled by Signs, Signals, Multi-lanes, or Pavement; Right-of-Way at Intersections; Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":668,"qid":27674,"description":"Which of the following statements concerning parking near a intersection is most accurate?","explanation":"Parking is not permitted within 20 feet of a crosswalk or within 30 feet of a stop sign, yield sign, or traffic control signal in Texas. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Do Not Park or Stand Vehicle; Texas Driver Handbook, Chapter 7: Parking, Stopping, or Standing]","imageName":"202002181224517197.jpg","correctAnswer":40944,"isBookmarked":0,"aid":"28151#36013#36016#40944","text":"You must not park within 5 feet of a stop sign or 30 feet of a crosswalk#You must not park within 10 feet of a stop sign or crosswalk#You can park as close to a stop sign or crosswalk as you want, as long as you don't pass the stop line or enter the crosswalk#You must not park within 30 feet of a stop sign or 20 feet of a crosswalk"},
{"testId":668,"qid":27675,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [How to Signal; Signaling; Texas Driver Handbook, Chapter 6: Signaling, Passing, and Turning]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":668,"qid":27676,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to all incoming traffic before turning left. You must also yield to pedestrians before turning while crossing a sidewalk or sidewalk area to enter or leave a highway. [Private Roads and Driveways, Right-of-Way at Intersections, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":668,"qid":27677,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You must signal for at least 100 feet before turning in Texas. Even if you don't see any other cars, you must signal. The most deadly car may be one you don't see. At greater speeds, the required minimum of 100 feet may not be sufficient. At 45 mph, for example, a car will cover 100 feet in only 1.5 seconds, providing little early notice to the driver. Consider signaling for a longer amount of time at greater speeds. [How to Signal; Signaling; Texas Driver Handbook, Chapter 6: Signaling, Passing, and Turning]","imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#39816#39817","text":"No#Yes, for at least 100 feet#Yes, for at least 200 feet#Yes, for at least 100 feet below 35 mph and 300 feet at or above 35 mph"},
{"testId":668,"qid":27678,"description":"This driver is signaling with his hand and arm. The driver plans to:","explanation":"This hand and arm gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [How to Signal; Signaling; Texas Driver Handbook, Chapter 6: Signaling, Passing, and Turning]","imageName":"202002181152514351.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":668,"qid":27679,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your automobile begins to roll, the curb will prevent it from moving ahead. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or Reverse for a manual transmission). [Do Not Park a Vehicle on a Hill; Chapter 7: Parking, Stopping, or Standing; Texas Driver Handbook]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":668,"qid":27680,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"Trucks have a lot of blind areas. A automobile moving behind or to the side of the truck, immediately behind the truck's cab, may be in a blind area. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's blind zones. It is hard to avoid a truck's blind areas entirely, so do not stay in them for any longer than absolutely necessary. Remember, if you can't see the driver in one of the truck's mirrors, chances are the driver can't see you too. [Following a Truck, Share the Road with Trucks, Texas Driver Handbook, Chapter 9: Some Special Driving Situations]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":668,"qid":27681,"description":"What is the meaning of this sign?","explanation":"This sort of warning sign alerts drivers to the possibility of fewer lanes ahead. This sign warns that the right lane is coming to an end ahead. All vehicles in the right lane must merge to the left. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"54#15575#28070#31641","text":"A one-way road is ahead#A narrow bridge is ahead#The right lane ends ahead#The left lane ends ahead"},
{"testId":668,"qid":27682,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#23543","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to avoid the pedestrian"},
{"testId":668,"qid":27683,"description":"What is the meaning of this sign?","explanation":"The sign warns of oncoming two-way traffic. Keep to the right and keep an eye out for incoming vehicles. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101251206942.jpg","correctAnswer":19324,"isBookmarked":0,"aid":"54#19323#19324#26190","text":"A one-way road is ahead#Lane crossing is allowed#Two-way traffic is ahead#The divided highway ends"},
{"testId":668,"qid":27684,"description":"You come to a train crossing. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"If the railroad crossing lights and gates warn of an impending train, you must come to a complete stop between 15 and 50 feet from the closest rail. (Trains may be six feet wider than the rails they travel on, so stay away from them.) Even after the train has past, use cautious and keep an eye on the signals, gates, and tracks. There might be another train after this one. Even if you think you can outrun a train, never attempt to beat it to a crossing. The train can be closer and quicker than you expect. The principles of physics assure that the vehicle will suffer significantly worse than the train in any accident. [Texas Driver Handbook, Chapter 4: Right-of-Way, Railroad Grade Crossings]","imageName":"202003161136554337.jpg","correctAnswer":40945,"isBookmarked":0,"aid":"23403#40945#40946#40947","text":"None of the above are true#You must stop no closer than 15 feet from the tracks#You can still cross if the gates aren't completely lowered yet and you think you can make it with at least 10 seconds to spare#You can proceed to cross as soon as the train has passed"},
{"testId":668,"qid":27685,"description":"What is the meaning of this sign?","explanation":"This sign alerts you that traffic from the right lane is going to merge into your lane. Adjust your speed and location to enable merging cars to safely combine. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101239016186.jpg","correctAnswer":3126,"isBookmarked":0,"aid":"3123#3126#9460#40731","text":"Merging from the left#Merging from the right#Two-way traffic#The start of one-way traffic"},
{"testId":668,"qid":27686,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a pedestrian crossing ahead. Slow down, be cautious, and be prepared to yield to pedestrians. [Warning Signs; Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101154005452.jpg","correctAnswer":1945,"isBookmarked":0,"aid":"1945#5968#23938#31663","text":"A pedestrian crosswalk ahead#A school crossing ahead#A flag person ahead#A road crew at work"},
{"testId":668,"qid":27687,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Texas Driver Handbook, Chapter 5: Signals, Signs, and Markers]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#40948","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This road is for trucks only"},
{"testId":668,"qid":27688,"description":"This road's center is divided from the other lanes by solid and broken yellow lines. What exactly is it?","explanation":"This is a center turn lane that is shared by two vehicles (also known as a two-way left-turn lane). This lane is for left turns only and may be used by cars moving in either direction. This lane should never be utilized for passing or navigating through traffic. Use care in this lane since cars heading in the other way may be utilizing it as well. [Left Turn Lane Only; Pavement Markings; Texas Driver Handbook Chapter 5: Signals, Signs, and Markers]","imageName":"202001301806403967.jpg","correctAnswer":40949,"isBookmarked":0,"aid":"4464#15973#37681#40949","text":"A divider#A central parallel parking area#A central passing lane#A central left-turn lane"},
{"testId":668,"qid":27689,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Do Not Park a Vehicle on a Hill; Chapter 7: Parking, Stopping, or Standing; Texas Driver Handbook]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":668,"qid":27690,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Texas?","explanation":"The Texas Driver Handbook recommends a following distance of two seconds at speeds up to 30 mph and four seconds at speeds more than 30 mph for acceptable road conditions. Increase your following distance if the weather, traffic, or road conditions are bad. [Texas Driver Handbook, Chapter 8: Speed and Speed Limits]","imageName":"202001302249003541.jpg","correctAnswer":40952,"isBookmarked":0,"aid":"33894#40950#40951#40952","text":"One car length for every 10 mph of speed#Just close enough to make eye contact through the driver's rear-view mirror#Two seconds at 30 mph or less and three seconds at over 30 mph#Two seconds at 30 mph or less and four seconds at over 30 mph"},
{"testId":668,"qid":27691,"description":"You approach a school bus that has stopped with its red lights flashing on an open road. What should you do?","explanation":"If you see a school bus stopped with its red lights flashing on an undivided highway in Texas, you must stop before approaching the bus, whether it is on your side or the opposing side. [Yield Right-of-Way to School Buses, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34196#34928#40953#40954","text":"Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 10 mph and pass with caution#Come to a complete stop and then pass at 15 mph or less"},
{"testId":668,"qid":27692,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"23414#25300#34771#40635","text":"Continue through as if you had a green light#Treat the intersection like an all-way stop#Treat the intersection like an uncontrolled intersection#Assume you have the right-of-way if you're on the busiest road"},
{"testId":668,"qid":27693,"description":"Two cars are parked in front of a fire station. Which of the following statements is the most correct?","explanation":"Fire engines may need a lot of space to turn. In Texas, you must not park or halt within 20 feet of a fire station's driveway entry or within 75 feet on the other side of the road. Car A must be at least 20 feet away from the driveway, while Car B must be at least 75 feet away. [Do Not Park or Stand Vehicle; Texas Driver Handbook, Chapter 7: Parking, Stopping, or Standing]","imageName":"202002070948543186.jpg","correctAnswer":36035,"isBookmarked":0,"aid":"36035#36036#36037#36038","text":"Car A must be parked at least 20 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 20 feet from the driveway#Car A must be parked at least 20 feet from the driveway; Car B can park anywhere#Car A can park anywhere as long as it doesn't block the driveway; Car B can park anywhere"},
{"testId":668,"qid":27694,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. Do you have to come to a stop here?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There are two exceptions to this provision in Texas. (1) The school bus has come to a halt on the other side of a split roadway. (2) The school bus has come to a halt in a loading zone that is part of or close to a limited-access highway, and pedestrians are not permitted to cross the roadway. You do not have to halt in these cases, but you should continue with care. [Yield Right-of-Way to School Buses, Texas Driver Handbook, Chapter 4: Right-of-Way]","imageName":"202002010826098089.jpg","correctAnswer":26739,"isBookmarked":0,"aid":"21234#26739#36039#40955","text":"Yes#No, but you should proceed with caution#Yes, but then you can proceed when it is safe to do so#No, but you must slow to 10 mph as you pass the school bus"},
{"testId":650,"qid":27696,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to any oncoming vehicles or pedestrians. When the junction is clear, you may continue. [Regulatory Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15474#15475#30391","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and then go#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding"},
{"testId":650,"qid":27697,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the legal speed limit is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Speed Limits, Road Rules, Mississippi Driver's Manual]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15479#23395#29939","text":"The recommended speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph#The legal speed limit is 50 mph"},
{"testId":650,"qid":27698,"description":"What is the meaning of this sign?","explanation":"This advisory sign indicates that the speed limit for this bend is 35 mph. [Warning Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":650,"qid":27699,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield in this situation. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":650,"qid":27700,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Regulatory Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":650,"qid":27701,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must pull over and stop, regardless of which way the emergency vehicle is driving. Never, however, obstruct a junction. If you are in the midst of an intersection and an emergency vehicle approaches, do not stop. Instead, go through the junction and then pull over to a complete stop. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202001302235285631.jpg","correctAnswer":23410,"isBookmarked":0,"aid":"15493#15628#23301#23410","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and could be dangerous#Pull over and stop#Pull over and stop, unless you are in an intersection"},
{"testId":650,"qid":27702,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Road Signs, Traffic Rules, Mississippi Driver's Manual]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15498#15499#33516","text":"The recommended speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph#The legal speed limit is 35 mph"},
{"testId":650,"qid":27703,"description":"When driving at night, high beams might help you see better.  But when is it necessary to utilize low beams?","explanation":"When you are within 500 feet of another vehicle, you must lower your headlights. Also, always lower your headlights before shining them on an occupied car, bike, or pedestrian. [Using Headlights, Driving at Night, Road Rules, Mississippi Driver's Manual]","imageName":"202002211503506612.jpg","correctAnswer":29949,"isBookmarked":0,"aid":"29949#29950#33857#33858","text":"When you are within 500 feet of another vehicle#When you are within 1,000 feet of another vehicle#When you are within 500 feet of an oncoming vehicle or following within 1,000 feet of another vehicle#When you are within 1,000 feet of an oncoming vehicle or following within 500 feet of another vehicle"},
{"testId":650,"qid":27704,"description":"You come to a place where the light is green. Can you go to the left?","explanation":"On a green signal, you may turn left, but you must first yield to pedestrians and vehicles already at the junction, who have the right-of-way. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202003070857473536.jpg","correctAnswer":33521,"isBookmarked":0,"aid":"33518#33519#33521#33859","text":"Yes, but only if there is a \"Left Turn Permitted\" sign present#No, you cannot turn left unless there is a green arrow pointing left#Yes, you can turn left after yielding to pedestrians and vehicles already in the intersection#Yes, you can turn left because this is a \"protected\" turn; oncoming traffic is stopped"},
{"testId":650,"qid":27705,"description":"Is it legal to turn right at a red light?","explanation":"Unless there are indications banning it, you may turn right on a red light. You must first come to a full stop and then yield to pedestrians and traffic. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#33860#33861","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No. In Mississippi, you cannot turn right at a red signal#Yes, this is a \"protected\" turn so you will have the right-of-way"},
{"testId":650,"qid":27706,"description":"Which of the following about seat belts is most accurate?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":33863,"isBookmarked":0,"aid":"25356#33862#33863#33864","text":"Only the driver must wear a seat belt#Only the driver and front-seat passengers must wear seat belts#The driver and every passenger must wear a seat belt or use a suitable child restraint#Seat belts are optional in Mississippi"},
{"testId":650,"qid":27707,"description":"Which of the following statements regarding using headlights is FALSE?","explanation":"Headlights must be used between sunset and morning and when visibility is less than 500 feet. When it is cloudy or rainy, or when other drivers may have difficulties seeing your car, you should turn on your headlights. High lights allow you to see farther ahead, but they may reflect off precipitation and cause glare. In fog, rain, or snow, use low lights. [Using Headlights, Driving at Night, Road Rules, Mississippi Driver's Manual]","imageName":"202003161149128995.jpg","correctAnswer":28141,"isBookmarked":0,"aid":"28141#30154#30155#33865","text":"Your high beams can improve visibility when there is heavy rain or fog#You must use your headlights between sunset and sunrise#You must use your headlights when visibility is less than 500 feet#You can enhance your safety by using your headlights in the rain"},
{"testId":650,"qid":27708,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of this stumbling block. [Regulatory Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":650,"qid":27709,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction controlled by a STOP sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before approaching the crossing. If there is no crosswalk, you must come to a complete stop before approaching the crossing. [Regulatory Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":650,"qid":27710,"description":"You approach a halted school bus that is awaiting passengers. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":33867,"isBookmarked":0,"aid":"33866#33867#33868#33869","text":"You must come to a complete stop if you are traveling in the same direction as the bus#You must come to a complete stop no matter which direction you are traveling in#You must slow to 10 mph and pass with caution#You must come to a complete stop and then pass at 20 mph or less when there are no children on the road"},
{"testId":650,"qid":27711,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Warning Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":650,"qid":27712,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, stop. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Control, Traffic Signals, Road Rules, Traffic Signals]","imageName":"202003051752141237.jpg","correctAnswer":29961,"isBookmarked":0,"aid":"28857#29961#29962#29963","text":"Speed up to get through the signal before it turns red#Stop if it is safe to do so; the signal will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":650,"qid":27713,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":650,"qid":27714,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles arrive at an uncontrolled junction or all-way stop about the same time, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the motorist turning left must yield to approaching traffic. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23401#23523#33870","text":"Car A must yield because it is turning left#Car B must yield because it is traveling straight through the intersection#Car A can go first because it is turning#The drivers must decide which of them should yield"},
{"testId":650,"qid":27715,"description":"Which of the following statements regarding trucks and heavy vehicles is correct?","explanation":"Blind spots are common in big cars. If you can't see the driver in the enormous vehicle's mirrors, the driver can't see you either. A huge vehicle may need extra turning room and may swing left before turning right. If you notice a huge truck swinging left, do not attempt to pass it on the right; it may be going to turn right. Stopping distances are considerable for large trucks. When merging into traffic, avoid merging directly in front of a huge car. If you pull directly in front of a huge car and then have to rapidly slow down for any reason, the large vehicle may rear-end you. Before you drive in front of a huge vehicle, be sure you are far ahead of it. [Mississippi Driver's Manual, Rules of the Road & Safe Driving]","imageName":"1367326513_t1q20.png","correctAnswer":23029,"isBookmarked":0,"aid":"23029#33871#33872#33873","text":"All of the above are true#A large vehicle may swing left to make a right turn#Large vehicles have large blind spots#Large vehicles have longer stopping distances than cars do"},
{"testId":650,"qid":27716,"description":"Which of the following is NOT TRUE about passing on the right?","explanation":null,"imageName":"202002181218288995.jpg","correctAnswer":33874,"isBookmarked":0,"aid":"33874#33875#33876#33877","text":"You can drive on the shoulder of the road to pass a vehicle on the right#If a car is turning left, you may pass it on the right#On a four-lane highway, you may pass a vehicle on the right#On a one-way street, you may pass a vehicle on the right"},
{"testId":650,"qid":27717,"description":"Car B has entered an intersection to make a right turn on a red light. Following that, Car A has reached a green light and want to go straight through the junction. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any cars and pedestrians already present. Because Car B has already reached the junction, Car A must yield to Car B. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#23403#25313","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B because Car B is already in the intersection"},
{"testId":650,"qid":27718,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202003051722347868.jpg","correctAnswer":33878,"isBookmarked":0,"aid":"25316#28864#29330#33878","text":"Proceed; you do not need to stop or yield at a flashing red signal#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Come to a complete stop, yield, and then proceed when it is safe to do so"},
{"testId":650,"qid":27719,"description":"Car B wishes to cross the split highway that Car A is going on. Who has the right of way and why? :","explanation":"Cars on a side road must yield to vehicles on a major road unless traffic signs or signals indicate otherwise (such as a divided highway). Car B must yield here. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202002061630063390.jpg","correctAnswer":33879,"isBookmarked":0,"aid":"31062#31063#31064#33879","text":"Car B has the right-of-way because it is to the right of Car A#Car B has the right-of-way because it arrived at the intersection first#Car B has the right-of-way because it is on a smaller road than Car A#Car A has the right-of-way because it is on the main road"},
{"testId":650,"qid":27720,"description":"What is the meaning of this sign?","explanation":"This sign indicates that a railroad crossing is ahead. If you notice a train approaching, prepare to halt. You must also come to a complete stop if urged to do so by a set of flashing red lights, a sign, or a lowered gate. [Mississippi Driver's Manual, Railroad Crossings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":650,"qid":27721,"description":"What should you do if you see a flashing yellow light?","explanation":"Flashing yellow lights warn vehicles of possible risks ahead. Slow down, yield to vehicles and pedestrians, and continue with care if you observe a flashing yellow light at a junction. [Traffic Lights, Traffic Control, Road Rules, Mississippi Driver's Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26784#29963#30157","text":"Slow down and proceed with caution#Stop if safe; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":650,"qid":27722,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 10 feet of a fire hydrant is illegal in Mississippi. The fire department must have constant access to the hydrant. Other states may have different minimum distance requirements. Always verify the local driving regulations while going out of state. [Unlawful Parking, Mississippi Driver's Manual, Safe Driving Practices]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":650,"qid":27723,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In an uncontrolled crossing, each automobile must yield to the one on its right after taking into account pedestrians. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":650,"qid":27724,"description":"Which of the following statements concerning parking near an intersection is most accurate?","explanation":"You are not permitted to park within 20 feet of a crosswalk at an intersection. You also cannot park within 30 feet of any traffic control device's approach (sign or signal). [Unlawful Parking, Mississippi Driver's Manual, Safe Driving Practices]","imageName":"202002181224517197.jpg","correctAnswer":33881,"isBookmarked":0,"aid":"33880#33881#33882#33883","text":"You must not park within 10 feet of any STOP sign or crosswalk#You must not park within 30 feet of a STOP sign or 20 feet of a crosswalk#You must not park within 5 feet of a STOP sign or 30 feet of a crosswalk#You can park as close to a STOP sign or crosswalk as you want, as long as you don't go over the STOP sign or crosswalk"},
{"testId":650,"qid":27725,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"A right turn is indicated by the left forearm pointing up. [Signals, Road Rules, Mississippi Driver's Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":650,"qid":27726,"description":"You need to make a left turn into a driveway. Before you can move on, you have to give way to:","explanation":"You must yield to all pedestrians and approaching cars before turning left off a road. [Right-of-Way, Road Rules, Mississippi Driver's Manual]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"8750#32554#33884#33885","text":"pedestrians#oncoming vehicles#pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles"},
{"testId":650,"qid":27727,"description":"You intend to turn at a junction and cannot see any other vehicles in the area. Is it still necessary to signal?","explanation":"Before turning, you should signal for at least 100 feet. Keep signaling until your turn or merging is complete. Even if you can't see any people or other cars, you must signal. The most deadly car on the road may be one you don't see. [Signals, Road Rules, Mississippi Driver's Manual]","imageName":"202001291433312120.jpg","correctAnswer":23470,"isBookmarked":0,"aid":"18118#23470#33545#33546","text":"No#Yes, for at least 100 feet#Yes, for at least 300 feet if you are driving at or above 35 mph#Yes, for at least 100 feet if you are driving at less than 35 mph and 300 feet if you are driving at or above 35 mph"},
{"testId":650,"qid":27728,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Signals, Road Rules, Mississippi Driver's Manual]","imageName":"202002181152514351.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":650,"qid":27729,"description":"You've parked next to a curb, downhill. Which way should your front wheels be pointed?","explanation":"Point your front wheels toward the curb while parking downhill. If your car begins to slide downhill, the curb will prevent it from going any farther. Make that your parking brake is still engaged and your gearbox is in the proper gear. [Safe Driving Practices, Mississippi Driver's Manual, Parking on a Hill]","imageName":"202002071736477410.jpg","correctAnswer":14638,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":650,"qid":27730,"description":"Which vehicles are in the truck's blind spots?","explanation":"Blind spots are common in trucks and other big vehicles. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's blind zones. Remember that if you can't see the driver, the motorist can't probably see you. [Safe Driving Practices, Mississippi Driver's Manual, Sharing the Road Safely with Large Vehicles]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":650,"qid":27731,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Warning Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":650,"qid":27732,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. Furthermore, at a marked or unmarked crossing, you must always yield to a pedestrian. As a result, the pedestrian has the right of way here. [Mississippi Driver's Manual, Traffic Control, Rules of the Road; Right-of-Way to Pedestrians, Right-of-Way, Mississippi Driver's Manual]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#23543","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to avoid the pedestrian"},
{"testId":650,"qid":27733,"description":"Which of the following assertions concerning rainy driving conditions is correct?","explanation":"Wet roads need more stopping distance and increase the danger of sliding or hydroplaning. Reduce your speed to less than the legal limit and increase your following distance. When you can't see well for at least 500 feet ahead, Mississippi law compels you to switch on your headlights. Your headlights will help increase the visibility of your car to others. On rainy roads, avoid using your cruise control. If your car loses traction, the cruise control will try to maintain a steady speed by spinning the drive wheels. This may exacerbate or even cause a skid. [Safe Driving Practices, Mississippi Driver's Manual, Tips for Safer Driving]","imageName":"202002061923072507.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#28162#33886#33887","text":"All of the above are true#You should turn on your headlights to improve visibility#You must adjust your speed according to the current driving conditions#You should switch off your cruise control"},
{"testId":650,"qid":27734,"description":"You come to a railroad crossing with red flashing lights and warning bells. It's clear that a train is coming. Which of the following is true?","explanation":"If you encounter any of the following at a railroad crossing鈥攁 lowered crossing gate, a flashing electric signal, a STOP sign, a flag person giving you the signal, or a speeding train鈥攜ou must stop within 10 to 50 feet of the closest rail. Even if you don't see a train, you must stop. You must stay halted until all tracks are free of obstructions, any gates have been lifted, and the lights are out. [Driver's Manual for Railroad Crossings in Mississippi]","imageName":"202003161136554337.jpg","correctAnswer":33888,"isBookmarked":0,"aid":"23029#28166#33888#33889","text":"All of the above are true#You can drive around the gate as it starts to rise#You must stop between 10 and 50 feet from the nearest rail#You can cross the tracks if you can get across them before the gate is lowered"},
{"testId":650,"qid":27735,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead. During recess or while children are entering or leaving school, you must drive no faster than 15 mph in Mississippi. You must always yield to pedestrians at crosswalks in school zones and everywhere else. [Warning Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202003151742324658.jpg","correctAnswer":33890,"isBookmarked":0,"aid":"15594#15595#15597#33890","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a pedestrian crossing or crosswalk ahead; prepare to stop#There is a park or playground ahead; slow down and watch for pedestrians#There is a school zone ahead; slow down and proceed with caution"},
{"testId":650,"qid":27736,"description":"What is the meaning of this sign?","explanation":"This sign alerts vehicles that the bridge ahead is small. Slow down and take your time. [Warning Signs, Road Signs, Driving Rules, Mississippi Driver's Manual]","imageName":"202002101245562248.jpg","correctAnswer":28073,"isBookmarked":0,"aid":"28073#29971#29973#33891","text":"There is a narrow bridge ahead#There is a tunnel ahead#The river crossing ahead is prone to flooding#There is no shoulder ahead"},
{"testId":650,"qid":27737,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if your car has an automatic gearbox, choose a low gear to decrease brake wear. [Warning Signs, Road Signs, Rules of the Road, Mississippi Driver's Manual]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#31341#33380","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#There is an emergency truck escape ramp ahead#This route is for trucks only; find another route"},
{"testId":650,"qid":27738,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a traffic officer's instructions, even if it means breaking other traffic laws. For example, if a traffic official waves you through, you may drive through a red light or through a STOP sign. [Traffic Officers, Traffic Control, Road Rules, Mississippi Driver's Handbook]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"14884#14886#15195#33892","text":"A red traffic signal#A traffic officer#A STOP sign#Another regulatory sign"},
{"testId":650,"qid":27739,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"If the brakes of an uphill vehicle fail, it may roll backward. When parking facing uphill close to a curb on the right, angle your front wheels away from the curb and then gently roll back until the rear section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent you from rolling. Make that your parking brake is still engaged and your gearbox is in the proper gear. [Safe Driving Practices, Mississippi Driver's Manual, Parking on a Hill]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":650,"qid":27740,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Mississippi?","explanation":"In Mississippi, the minimum following distance is one vehicle length for every ten miles per hour of speed. At 30 mph, for example, you should not be more than three car widths (approximately 50 feet) behind the vehicle in front of you. When driving on an interstate highway, following a truck or other big vehicle, or when driving conditions are bad, you should increase your following distance. [Following Another Vehicle, Highway Rules, Mississippi Driver's Manual]","imageName":"202001302249003541.jpg","correctAnswer":33894,"isBookmarked":0,"aid":"1026#33893#33894#33895","text":"50 feet#Close enough to make eye contact through the other vehicle's rear-view mirror#One car length for every 10 mph of speed#Closer to a truck or bus than to a car"},
{"testId":650,"qid":27741,"description":"Before approaching a school bus that has stopped for passengers, you must come to a complete stop. When can you go ahead?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":33898,"isBookmarked":0,"aid":"15297#33896#33897#33898","text":"C) When the bus driver waves you through#A) After the children have crossed the road and the bus has resumed its motion#B) After the bus's red lights have stopped flashing and the STOP sign has been retracted#D) Either A) or B)"},
{"testId":650,"qid":27742,"description":"How much closer can this car be parked to the railroad crossing?","explanation":"At a railroad crossing, you may not park within 15 feet (approximately one vehicle length) of the closest rail. Trains are at least six feet wider than the tracks they travel on, so you wouldn't want to go too near. [Unlawful Parking, Mississippi Driver's Manual, Safe Driving Practices]","imageName":"202002181114128699.jpg","correctAnswer":33900,"isBookmarked":0,"aid":"15302#33899#33900#33901","text":"Anywhere behind the gate or stop line#No closer than 75 feet#No closer than 15 feet#Anywhere as long as it doesn't block the railroad crossing"},
{"testId":650,"qid":27743,"description":"You parked with your back to the hill. If you have an automatic transmission, keep it in Park. You should keep your vehicle in _________, if you drive a manual (stick shift).","explanation":"If the brakes of a vehicle facing downhill fail, it may roll forward. As a result, while parking your car facing downhill, you should choose Park (for an automatic gearbox) or Reverse (for a manual transmission). [Parking, Driving Safety, Mississippi Driver's Manual]","imageName":"202002071736477410.jpg","correctAnswer":31053,"isBookmarked":0,"aid":"10439#10440#31053#31054","text":"neutral#First gear#reverse#The highest gear"},
{"testId":650,"qid":27744,"description":"You are on a highway with two lanes. You get close to a school bus with flashing red lights that has stopped on the other side of the highway. Should you stop here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#33902#33903#33904","text":"No#No, but you must slow down to 10 mph as you pass#Yes, you must stop, but you can then proceed when it is safe to do so#Yes, you must stop until the bus has switched off its flashing red lights"},
{"testId":650,"qid":27745,"description":"Which of the following statements concerning railroad crossings is correct?","explanation":"Certain vehicles are obliged to stop at railroad crossings even if there are no warning indications of impending trains. Passenger buses, school buses, and trucks transporting hazardous commodities such as explosives or fuel are examples of these vehicles. Keep a safe gap between such cars and be prepared to stop. When you reach a railroad crossing, follow all signs and signals. Do not attempt to outrun or cut off an oncoming train. It may take more than a mile for a train running at 60 mph to come to a full stop. [Mississippi Driver's Manual, Railroad Crossings]","imageName":"202003161136554337.jpg","correctAnswer":33905,"isBookmarked":0,"aid":"23029#33905#33906#33907","text":"All of the above are true#Certain vehicles such as school buses and some trucks must stop at railroad crossings even when the tracks are clear#You can speed up to cross the tracks before a train arrives if the gate hasn't been lowered yet#You can drive around a lowered gate if you're certain that no trains are coming"},
{"testId":637,"qid":27746,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"If you approach a stop sign first, you must stop before the corner, crosswalk, or stop line. Then, give way to other cars and pedestrians. When the junction is clean and there aren't any incoming cars that may pose a threat, you can continue. [Laws Governing Right of Way, Section 5: Georgia Drivers Manual Traffic Laws]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":637,"qid":27747,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Additional signage may be posted to regulate minimum and nighttime speed limits. [Regulatory Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":637,"qid":27748,"description":"What is the meaning of this sign?","explanation":"The recommended speed for this bend is 35 mph, according to this sign. [Warning Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#29940#29941","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph"},
{"testId":637,"qid":27749,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right. As a result, Car A must yield. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":637,"qid":27750,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":637,"qid":27751,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":23301,"isBookmarked":0,"aid":"15493#23301#25445#38647","text":"Slow down#Pull over and stop#Speed up#Maintain your speed and direction"},
{"testId":637,"qid":27752,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Warning Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":637,"qid":27753,"description":"When do high-beam headlights need to be dimmed in Georgia?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":637,"qid":27754,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#34490#39458#40766","text":"No#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles"},
{"testId":637,"qid":27755,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Georgia unless there are signs banning it. You must first come to a full stop and then yield to pedestrians and traffic. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"15509#25289#38857#43258","text":"Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#No, not in Georgia"},
{"testId":637,"qid":27756,"description":"Which occupants of a vehicle are required by Florida law to wear safety belts or proper child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":41838,"isBookmarked":0,"aid":"4420#16365#26667#41838","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and all passengers under 18"},
{"testId":637,"qid":27757,"description":"Which of the following assertions regarding using your headlights is FALSE in Georgia?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":43259,"isBookmarked":0,"aid":"15516#28143#43259#43260","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You can improve safety by using your headlights in the rain#Your high beams can improve visibility in fog or heavy rain#You must use your headlights whenever visibility is limited"},
{"testId":637,"qid":27758,"description":"What is the meaning of this sign?","explanation":"This sign instructs cars to stay to the right of an impending island or obstruction. [Regulatory Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"19246#38076#38741#43261","text":"Keep right#You must turn right#A divided highway begins ahead#You must not turn left"},
{"testId":637,"qid":27759,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. Stop before the crosswalk if there is no stop line. If there is no crosswalk, stop near the corner. [Pavement Markings; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":637,"qid":27760,"description":"When can you turn left on red light?","explanation":"In Georgia, unless there is a \"No Turn on Red\" sign placed, you may turn left on a red light while turning from one one-way street into another one-way street. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#16495#29959#43262","text":"Never#When you are turning onto a one-way street#When you are turning from a one-way street onto another one-way street#At any time unless otherwise signed"},
{"testId":637,"qid":27761,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Warning Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"14824#14961#15098#29960","text":"U-turn permitted#Wrong way; turn around and go back#Steep crest or hump ahead#Hairpin curve ahead"},
{"testId":637,"qid":27762,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Traffic Signals and Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":637,"qid":27763,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202003070857473536.jpg","correctAnswer":15507,"isBookmarked":0,"aid":"15504#15505#15507#25287","text":"You are free to proceed#You cannot proceed#You are free to proceed, but you first must yield to any traffic already in the intersection#You must briefly stop and yield, and then you can proceed"},
{"testId":637,"qid":27764,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles approach at an uncontrolled junction (one without signs or signals) about the same time, the vehicle turning left must yield to incoming traffic. Car A must yield to Car B in this situation. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23523#36008#39459","text":"Car A must yield because it is turning left#Car A can go first because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":637,"qid":27765,"description":"Three vehicles are sequentially approaching this four-way stop. Which vehicle should go first?","explanation":"When cars arrive at an all-way stop at various times in Georgia, they must advance in the order in which they arrived. Car B was the first to get here, therefore it may go first. Car A was the first to arrive, thus it takes the lead, followed by Car C. Of course, before proceeding, each automobile must come to a full stop at its stop sign. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002181231057295.jpg","correctAnswer":39472,"isBookmarked":0,"aid":"39460#39461#39472#39473","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car B, because it arrived at the intersection first#Car C, because it is to the right of Car A and Car B"},
{"testId":637,"qid":27766,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a center turn lane that is shared by two vehicles (also known as a two-way left-turn lane). This lane is designated for left turns (and, if allowed, U-turns) and may be used by cars driving in either direction. The lane cannot be utilized for anything else. A solid yellow line and a broken yellow line designate the lane on either side. Use care in this lane since cars heading in the other way may be utilizing it as well. You may not enter the lane more than 300 feet before the left turn in Georgia. [Turn Lanes and Pavement Markings; Section 7: Signs, Signals, and Markings; Georgia Drivers Handbook]","imageName":"202001301806403967.jpg","correctAnswer":41581,"isBookmarked":0,"aid":"41451#41454#41581#43263","text":"(a) Passing#(a) and (b)#(b) Left turns#(c) Car pooling or public transportation"},
{"testId":637,"qid":27767,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15540#15541#23403#34493","text":"Car B should stop and let Car A through#Car A should speed up to pass Car B#None of the above are true#Car A must yield to Car B"},
{"testId":637,"qid":27768,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Traffic Signals and Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#40943","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed; you do not have to stop or yield at a flashing red signal"},
{"testId":637,"qid":27769,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts, Pavement Markings; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":637,"qid":27770,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Take it easy. Prepare to come to a complete stop if you see or hear a train coming. You must also come to a complete stop if you see flashing red lights, a sign, a police officer, or a lowered gate. [Sign Shapes; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":637,"qid":27772,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in Georgia. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Stopping, Standing, and Parking, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#26729","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within ten minutes"},
{"testId":637,"qid":27773,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction (one without signs or signals), each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Georgia's Pedestrian Law, Sharing the Road With Pedestrians, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":637,"qid":27774,"description":"Which of the following statements concerning parking near a intersection is most accurate?","explanation":"You may not park within 20 feet of a crosswalk at a junction in Georgia. In addition, you are not permitted to park within 30 feet of a stop sign, yield sign, or traffic control signal. (There are several more sites where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Stopping, Standing, and Parking, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002181224517197.jpg","correctAnswer":43264,"isBookmarked":0,"aid":"28149#43264#43265#43266","text":"You must not park within 50 feet of any stop sign or crosswalk#You must not park within 30 feet of a stop sign or within 20 feet of a crosswalk#You must not park within 5 feet of a stop sign or within 30 feet of a crosswalk#You can park as close to any stop sign or crosswalk as you want as long as you don't enter the intersection or the crosswalk"},
{"testId":637,"qid":27775,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"The motorist plans to turn right. [Turn Signals and Making Turns Safely, Section 5: Traffic Laws, Georgia Drivers Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":637,"qid":27776,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to oncoming traffic before turning left into an alley or driveway. In addition, anytime you enter or exit a driveway or alley, you must yield to pedestrians on the sidewalk. [Exiting and Entering Driveways, Sharing the Road With Pedestrians, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":637,"qid":27777,"description":"Which of the following is NOT TRUE about driving on an expressway?","explanation":"To keep traffic running smoothly, minimum speed restrictions will be enforced on select roadways. Maximum and minimum speed restrictions are also enforced. If you are unable to drive at the minimum speed restriction or faster, you should choose an another route. The slow lane is on the right. If you are unable to keep up with the flow of traffic, stay in the right lane to enable other cars to pass. Driving in the passing lane while a quicker car behind you wants to pass you is banned in Georgia. [Driving Too Slowly, Traveling Speed, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202003301803164994.jpg","correctAnswer":43269,"isBookmarked":0,"aid":"28159#43267#43268#43269","text":"Minimum speed limits are enforceable#If you are driving slower than other traffic, you should move into the right lane#If you cannot drive at the minimum speed limit or faster, you should exit and find another route#The minimum speed limit only applies to the leftmost lane"},
{"testId":637,"qid":27778,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Turn Signals and Making Safe Turns, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":637,"qid":27779,"description":"You notice a highway with white diamonds. Which of the following vehicles is permitted to use this lane?","explanation":"This is an HOV (High-Occupancy Vehicle) lane. The laws governing which cars may utilize an HOV lane differ from state to state. Cars with more than one person, buses, motorbikes (even without a passenger), and vehicles with Alternative Fuel Vehicle license plates may utilize this lane in Georgia. Alternative Fuel Vehicles are powered by fuels other than gasoline or diesel, such as electricity, propane, or natural gas. [HOV Lanes, Expressway Driving, Georgia Drivers Manual, Section 8: Safety Guidelines]","imageName":"1367489621_t1q34.png","correctAnswer":203,"isBookmarked":0,"aid":"203#1278#43270#43271","text":"All of the above#Motorcycles#Vehicles with one or more passengers#Electric vehicles"},
{"testId":637,"qid":27780,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"Trucks have a lot of blind areas. A automobile moving behind or to the side of the truck, immediately behind the truck's cab, may be in a blind area. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's blind zones. It is hard to avoid a truck's blind areas entirely, so do not stay in them for any longer than absolutely necessary. Remember that if you can't see the driver directly or in one of the truck's mirrors, the driver can't see you. [Sharing the Road With Commercial Motor Vehicles, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":637,"qid":27781,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Warning Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":637,"qid":27782,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to pedestrians and oncoming cars before turning right on a red signal. As a result, the pedestrian has the right of way here. [Laws Governing Right-of-Way, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#23543","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to avoid the pedestrian"},
{"testId":637,"qid":27783,"description":"Which of the following assertions concerning rainy driving conditions is correct?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"28162#28163#41147#43272","text":"You should turn on your headlights to improve visibility#All of the above statements are true#Avoid using cruise control on wet roads#Roads are usually slipperiest during the first half hour of rain"},
{"testId":637,"qid":27784,"description":"You come to a train crossing. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"If the railroad crossing lights and gates warn of an impending train, you must come to a complete stop between 15 and 50 feet from the closest rail. Even after the train has past, use cautious and keep an eye on the signals, gates, and tracks. There might be another train after this one. Only until the signals have ceased and the gates have been fully lifted may you continue to cross the tracks. Never race a train to a crossing. The massive size of a rolling train causes it to seem to move more slowly than it really does. A fast train might take more than a mile to halt completely. [Railroad Crossings, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202003161136554337.jpg","correctAnswer":43273,"isBookmarked":0,"aid":"23029#27277#43273#43274","text":"All of the above are true#You can cross the tracks as soon as the train has passed#You must stop between 15 and 50 feet from the tracks#You can drive around the gate if you're sure that you can beat the train to the crossing"},
{"testId":637,"qid":27785,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone or crossing. (In Georgia, the sign is placed between 150 and 700 feet before the school grounds or crossing.) Slow down and continue with care as schoolchildren may be crossing the road ahead. It is important to note that newer school zone or school crossing signs have a neon yellow-green backdrop. [Sign Shapes; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003151742324658.jpg","correctAnswer":39480,"isBookmarked":0,"aid":"15594#15597#36023#39480","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a crosswalk ahead; prepare to stop#There is a school zone or school crossing ahead; slow down and proceed with caution"},
{"testId":637,"qid":27786,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"In Georgia, if the traffic lights are entirely dark, the junction must be treated as an all-way stop (i.e., as if there were a stop sign at each approach to the intersection). That is, you must come to a complete stop before approaching the junction, yield according to stop sign right-of-way standards, and then continue (with great care) when it is safe to do so. [Traffic Signals and Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"23414#25300#33517#39481","text":"Continue through as if you had a green light#Treat the intersection like an all-way stop#Stop as if you had a red light#Treat the intersection as if there were no signals at all"},
{"testId":637,"qid":27787,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automated transmission, change into a low gear to avoid fading (losing effectiveness) of your brakes due to misuse. [Warning Signs; Road Signs; Georgia Drivers Manual; Section 7: Signs, Signals, and Markings]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#40948","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This road is for trucks only"},
{"testId":637,"qid":27788,"description":"Which of the following should be adhered to above all others?","explanation":"You must always obey a police officer or a fireman, even if it means ignoring other traffic devices or laws. For example, if a police officer waves you through a red light or a stop sign, you should proceed. [3 Most Basic Traffic Laws, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002211500121915.jpg","correctAnswer":16212,"isBookmarked":0,"aid":"2316#14884#15196#16212","text":"A stop sign#A red traffic signal#A prohibitory sign#A police officer"},
{"testId":637,"qid":27790,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Georgia?","explanation":"Under typical driving circumstances, the Georgia Drivers Manual advises a following distance of at least two seconds. However, while driving in heavy traffic, bad weather, poor visibility, or through a construction zone, you should maintain an even wider following distance. [Too Closely Following, Section 8: Safety Guidelines, Georgia Drivers Manual]","imageName":"202001302249003541.jpg","correctAnswer":10881,"isBookmarked":0,"aid":"7370#10879#10881#33894","text":"40 feet#Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":637,"qid":27791,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"When approaching a school bus with flashing red lights on a one-way street, you must come to a full stop, regardless of which direction the bus is heading. You must stay halted until all passengers have exited the bus and the red lights have ceased blinking or the vehicle has resumed motion. [Sharing the Road With School Buses, Georgia Drivers Manual, Section 9: Sharing the Road]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34195#34196#36032#43275","text":"Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in#Slow down to 10 mph and proceed with caution#Stop, yield to pedestrians, and pass the bus at 20 mph or less"},
{"testId":637,"qid":27792,"description":"How much closer can this car be parked to the railroad crossing?","explanation":"Parking is not permitted within 50 feet of the closest rail of a railroad crossing in Georgia. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Stopping, Standing, and Parking, Georgia Drivers Manual, Section 5: Traffic Laws]","imageName":"202002181114128699.jpg","correctAnswer":43276,"isBookmarked":0,"aid":"43276#43277#43278#43279","text":"Not within 50 feet#Not within 100 feet#Anywhere off the tracks#Anywhere before the crossing gate or stop line"},
{"testId":637,"qid":27794,"description":"On the opposite side of a divided roadway, you notice a school bus stopped with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40787#43281","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus, yield if necessary, and then proceed#Stop before you reach the bus. Remain stopped until all passengers have cleared the roadway and either the red lights stop flashing or the bus resumes its motion"},
{"testId":637,"qid":27795,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#35268#39336#45612","text":"Proceed with caution#Stop behind the emergency vehicle#Move into a non-adjacent lane if possible; otherwise slow down#Stop your vehicle in a non-adjacent lane"},
{"testId":641,"qid":27796,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must come to a full stop and yield to any oncoming vehicles or pedestrians. When the junction is clean and there are no incoming cars that may pose a problem, you can continue. [Traffic Regulation Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#15474#15475","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here"},
{"testId":641,"qid":27797,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the legal speed limit is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Traffic Regulation Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15479#23395#29939","text":"The recommended speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph#The legal speed limit is 50 mph"},
{"testId":641,"qid":27798,"description":"What is the meaning of this sign?","explanation":"The recommended speed for this bend is 35 mph, according to this caution sign. [Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202002061303124131.jpg","correctAnswer":29942,"isBookmarked":0,"aid":"26806#29940#29941#29942","text":"There is a 35-degree curve ahead#The minimum speed for this curve is 35 mph#The maximum speed for this curve is 35 mph#The recommended speed for this curve is 35 mph"},
{"testId":641,"qid":27799,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction at the same moment, the vehicle on the left must yield to the one on the right. As a result, Car A must yield. [Indiana Code, IC 9-21-8-29]","imageName":"202007120521353376.jpg","correctAnswer":23509,"isBookmarked":0,"aid":"23401#23403#23509#29943","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car A is to the left of Car B and therefore must yield#Car B must yield because it is to the right of Car A"},
{"testId":641,"qid":27800,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"You must slow down as you get closer to a yield sign and become ready to give way to oncoming traffic. Be prepared to pause if you need to as well. [Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals, Approaching a Yield Sign, Traffic Signals]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#29944","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to line up behind traffic already in the intersection"},
{"testId":641,"qid":27801,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle's flashing lights or siren is activated, you must pull over to the right, come to a complete stop, and allow the emergency vehicle to pass. [Indiana Driver's Manual, Emergency Vehicles, Sharing the Road with Other Vehicles, Chapter 5: Safe Vehicle Operation]","imageName":"202001302235285631.jpg","correctAnswer":29946,"isBookmarked":0,"aid":"15493#26798#29945#29946","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Speed up and quickly find somewhere to pull over#Slow down, pull over to the right, and stop"},
{"testId":641,"qid":27802,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals, Speed Advisory Signs, Warning Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":641,"qid":27803,"description":"You are driving with your high-beam headlights on at night. A vehicle is seen ahead. When must high beams be dimmed?","explanation":"When you are near 500 feet of an incoming vehicle or following another vehicle within 200 feet, you must decrease your high lights. [Indiana Driver's Manual, Headlights, Driving at Night, Chapter 5: Safe Vehicle Operation]","imageName":"202002211503506612.jpg","correctAnswer":29947,"isBookmarked":0,"aid":"29947#29948#29949#29950","text":"When you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#When you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#When you are within 500 feet of another vehicle#When you are within 1,000 feet of another vehicle"},
{"testId":641,"qid":27804,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must yield to all cars and pedestrians approaching the junction, since they have the right-of-way. At any one moment, only one vehicle may reach the junction to turn left. [Turning Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003070857473536.jpg","correctAnswer":29953,"isBookmarked":0,"aid":"26814#29951#29952#29953","text":"Yes, but only if there is a \"Left Turn Permitted\" sign posted#Yes, because this is a \"protected\" turn; no other vehicles are allowed to enter the intersection#No, you cannot turn left on a green light under any circumstances#Yes, one vehicle can turn left at a time after yielding to all pedestrians and traffic"},
{"testId":641,"qid":27805,"description":"Is it legal to turn right at a red light?","explanation":"At a red light, you have the option of turning right. However, you must first come to a full stop and yield to vehicles and pedestrians. [Turning Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#15510#26802","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right at a red light#Yes, this is a \"protected\" turn, so you have the right-of-way"},
{"testId":641,"qid":27806,"description":"Which of the following about seat belts is most accurate?","explanation":"Seat belts are required by Indiana law for the driver and all passengers aged 8 and above. Children under the age of eight must be restrained in appropriate child restraints. [Chapter 5: Safe Vehicle Operation, Indiana Driver's Manual, Seat Belts and Child Safety Restraints]","imageName":"202003151755527296.jpg","correctAnswer":29955,"isBookmarked":0,"aid":"25356#29954#29955#29956","text":"Only the driver must wear a seat belt#Only the driver and front-seat passengers who are 8 years old or older must wear seat belts#The driver and all passengers who are 8 years old or older must wear seat belts#Seat belts are optional in Indiana"},
{"testId":641,"qid":27807,"description":"Which one of the following is NOT TRUE about using your headlights?","explanation":"From sundown till dawn, and anytime visibility is less than 500 feet, you must use your headlights. When you believe other drivers may have problems seeing you, turn on your headlights. You should not use your high lights while you are within 500 feet of an incoming vehicle or following another vehicle within 200 feet. Use low beams instead. Also utilize low lights in fog, snow, or severe rain. Light from high beams bounces back off such precipitation and generates glare. [Driving in Uncertain Weather Conditions, Driving at Night; Chapter 5: Safe Vehicle Operation; Indiana Driver's Manual]","imageName":"202003161149128995.jpg","correctAnswer":28141,"isBookmarked":0,"aid":"28141#30154#30155#30156","text":"Your high beams can improve visibility when there is heavy rain or fog#You must use your headlights between sunset and sunrise#You must use your headlights when visibility is less than 500 feet#For safety, use your headlights when it is raining"},
{"testId":641,"qid":27808,"description":"What is the meaning of this sign?","explanation":"This sign indicates the presence of a traffic island or divider ahead. Keep to the right of the divider. [Traffic Regulation Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":641,"qid":27809,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before approaching the crosswalk or junction. [Approaching a Red Light or Stop Sign, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":641,"qid":27810,"description":"When is it legal to turn left at a red light?","explanation":"If you are turning from one one-way street to another one-way street in Indiana, you may perform a left turn at a red light. To begin, you must come to a full stop and surrender to all vehicles. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.) [Turning Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#14865#29958#29959","text":"Never#When you are entering an expressway#At any time, unless otherwise posted#When you are turning from a one-way street onto another one-way street"},
{"testId":641,"qid":27811,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to a hairpin turn ahead. Slow down and take your time. [Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202002061247596804.jpg","correctAnswer":29960,"isBookmarked":0,"aid":"1166#14824#14961#29960","text":"Steep hill ahead#U-turn permitted#Wrong way; turn around and go back#Hairpin curve ahead"},
{"testId":641,"qid":27812,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Driving Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003051752141237.jpg","correctAnswer":29961,"isBookmarked":0,"aid":"28857#29961#29962#29963","text":"Speed up to get through the signal before it turns red#Stop if it is safe to do so; the signal will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":641,"qid":27813,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Driving Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":641,"qid":27814,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles approach at an uncontrolled junction at the same moment, the vehicle turning left is required to yield to any incoming traffic. As a result, Car A must yield here. [Indiana Code, IC 9-21-8-30]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#23401#23523#29964","text":"Car A must yield because it is turning left#Car B must yield because it is traveling straight through the intersection#Car A can go first because it is turning#The two drivers must decide which of them will yield"},
{"testId":641,"qid":27815,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles arrive at an all-way stop at the same moment, the vehicle on the left must yield to the vehicle on the right. As a result, Car A must yield; Car B has the right-of-way and may go first. [Four Way Stops, Rules for Safe and Legal Turning, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002181211246194.jpg","correctAnswer":29966,"isBookmarked":0,"aid":"29964#29965#29966#29967","text":"The two drivers must decide which of them will yield#Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car A, because it is turning left"},
{"testId":641,"qid":27816,"description":"This roadway's centre is delineated by solid and broken yellow lines. What is this area's function?","explanation":"This is a two-way left-turn lane with a shared center turning lane. This lane allows vehicles driving in either direction to make a left turn. Use care while turning left here; keep an eye out for incoming vehicles. This lane should never be used for passing. [Turning Left from Specially Designated Center Lanes, Rules for Safe and Legal Turning, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001301806403967.jpg","correctAnswer":29969,"isBookmarked":0,"aid":"15536#15537#29968#29969","text":"This is a central passing lane#This is a central parallel parking area#This is a central divider, which can only be used for emergency stopping#This is a center turning lane"},
{"testId":641,"qid":27817,"description":"Car B has entered an intersection to make a right turn on a red light. Following that, Car A has reached a green light and want to go straight through the junction. Which of the following statements is correct? :","explanation":"Even if the signal is green, you must yield to all pedestrians and cars already at the junction. As a result, since Car B has already reached the junction, Car A must yield to Car B here. [Driving Through an Intersection, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202001271450464240.jpg","correctAnswer":29970,"isBookmarked":0,"aid":"15540#15541#23403#29970","text":"Car B should stop and let Car A through#Car A should speed up to pass Car B#None of the above are true#Car A must yield to Car B, because Car B is already in the intersection"},
{"testId":641,"qid":27818,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction or crossing, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Red Flashing Lights, Traffic Signals, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#29331","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":641,"qid":27819,"description":"Which vehicle is not properly using the roundabout?","explanation":"In a multilane roundabout or any other form of crossroads, you must always obey traffic signs and pavement markings and stay in the proper lane. Turn left out of the left lane, right out of the right lane, then drive straight through utilizing either lane. Car C has made an improper left turn from the right lane. [Roundabouts, Rules for Safe and Legal Turning, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#17889","text":"Car A#Car B#Car C#None"},
{"testId":641,"qid":27820,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. If you notice a train approaching, prepare to halt. You must also come to a complete stop if you see a set of red flashing lights, a sign, a traffic cop, or a lowered gate. [Cross Bucks, Railroad Crossings, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":641,"qid":27821,"description":"What should you do if you see a flashing yellow light?","explanation":"You must slow down, give way to oncoming vehicles and pedestrians, and continue cautiously while a flashing yellow light is present. [Indiana Driver's Manual, Yellow Flashing Lights, Traffic Signals, Chapter 4: Traffic Signs and Signals]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":641,"qid":27822,"description":"Is the vehicle legally parked when it is parked near the fire hydrant?","explanation":"You are not permitted to park within 15 feet of a fire hydrant or in fire lanes. The fire department must have constant access to the hydrant. [Illegal Parking Areas, Parking and Reversing, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":641,"qid":27823,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In an uncontrolled crossing, each automobile must yield to the one on its right after taking into account pedestrians. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Indiana Driver's Manual, Pedestrian Safety, Chapter 5: Safe Vehicle Operation]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":641,"qid":27824,"description":"What is the meaning of this sign?","explanation":"This sign advises that a small bridge is ahead. Slow down and take your time. [Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202002101245562248.jpg","correctAnswer":28073,"isBookmarked":0,"aid":"28073#29971#29972#29973","text":"There is a narrow bridge ahead#There is a tunnel ahead#There is an underpass ahead#The river crossing ahead is prone to flooding"},
{"testId":641,"qid":27825,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"The motorist plans to turn right. [Signaling Your Intention to Turn, Rules for Safe and Legal Turning, Chapter 5: Safe Vehicle Operation, Indiana Driver's Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":641,"qid":27826,"description":"You parked next to the curb, with your vehicle facing uphill. Which way should you turn your vehicle's front wheels if your brakes fail to stop it from moving?","explanation":"When parking uphill, keep your car away from the curb. If your automobile starts rolling, the rear of your front curbside tire will contact the curb and halt it. For the same reason, if you are heading downhill, you should aim your wheels towards the curb. [Parking and Reversing, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002070945089828.jpg","correctAnswer":15114,"isBookmarked":0,"aid":"15113#15114#15115#15116","text":"Steer towards the curb#Steer away from the curb#Keep your wheels straight#It makes no difference"},
{"testId":641,"qid":27827,"description":"You want to turn, but there are no other vehicles in the area. Is it still necessary to signal?","explanation":"In Indiana, you must signal for at least 200 feet if your speed is less than 50 mph, and at least 300 feet if your speed is 50 mph or above. (Keep in mind that at 50 mph, your car will go 300 feet in 4.1 seconds.) It should also be noted that the legislation in certain other states vary. Always verify the local traffic rules while going out of state.) Even if you cannot see people or other cars, you must indicate. The most deadly car on the road can be one you don't see. [Signaling Your Intention to Turn, Rules for Safe and Legal Turning, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001302245403150.jpg","correctAnswer":29978,"isBookmarked":0,"aid":"29975#29976#29977#29978","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal, but there is no set minimum time#Yes, you must signal for at least 200 feet#Yes, you must signal for at least 200 feet under 50 mph or at least 300 feet at 50 mph or higher"},
{"testId":641,"qid":27828,"description":"The turn and brake signals on this vehicle have failed, so the driver is employing a hand signal instead. This motorist intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Signaling Your Intention to Turn, Rules for Safe and Legal Turning, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002181152514351.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6365#6368#6476#29974","text":"Turn left#Turn right#Slow down#increase speed"},
{"testId":641,"qid":27829,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation, Four Way [sic] Stops, Rules for Safe and Legal Turning]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":641,"qid":27830,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. Cars moving immediately behind or behind a truck are often entirely obscured to the driver. Car A should be visible through the truck's windshield, and Car C should be visible in the truck's right side mirror in this case. Car B, on the other hand, is unlikely to be seen to the truck driver. Remember that if you can't see the driver, the motorist can't probably see you. [Blind Spots, Tractor-Trailer Sharing, Chapter 5: Safe Vehicle Operation, Indiana Driver's Manual]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":641,"qid":27831,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":641,"qid":27832,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a marked or unmarked crossing, you must always yield to a pedestrian. [Indiana Driver's Manual, Pedestrian Safety, Chapter 5: Safe Vehicle Operation]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#23543#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to avoid the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":641,"qid":27833,"description":"Which of the following statements concerning rainy driving is FALSE?","explanation":"When driving in the rain, you should take the following precautions to ensure your safety. First and foremost, you should calm down. On wet roads, your ability to maneuver and stop safely will be compromised. The roads are often slickest during the first 10 minutes of rain, when the downpour loosens oil and other fluids that have accumulated on the surface. This state will last until further rain washes away the fluids. Hydroplaning happens when your tires skim over the surface of the water. At speeds as low as 35 mph, you may begin to hydroplane. If you hydroplane, you should immediately cease speeding and prevent braking. Allow the car to slow down on its own. You should not use your cruise control because it may spin the wheels in an effort to maintain a consistent pace if your car loses traction. This may exacerbate or even cause a skid. Finally, switch on your headlights to increase your visibility to other cars. [Rain, Driving in Uncertain Weather Conditions, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002061923072507.jpg","correctAnswer":28548,"isBookmarked":0,"aid":"23544#23545#28548#29979","text":"Roads are often most slippery during the first few minutes of rain#You shouldn't use cruise control on wet roads#If you start to hydroplane, you should apply your brakes and slow down quickly#In the rain, you should turn on your headlights to improve visibility"},
{"testId":641,"qid":27834,"description":"You come to a railroad crossing. A bell is ringing and red lights are flashing. Which of the following statements is correct?","explanation":"When a train is coming, the crossing's signals are activated, or the gate is not completely raised, you must not access the crossing. At a crossing with simply a stop sign, you must halt and advance only after certain that no train is coming. Because the train cannot stop for you, you must constantly defer to it. [Railroad Crossings, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202003161136554337.jpg","correctAnswer":29980,"isBookmarked":0,"aid":"23503#29980#29981#29982","text":"You can cross as soon as the train has passed#You must not attempt to cross#You can cross if there is no train approaching#If there is a gate, you can drive through it as long as it is not completely lowered"},
{"testId":641,"qid":27835,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead. [School Zone Signs, Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202003151742324658.jpg","correctAnswer":29984,"isBookmarked":0,"aid":"15594#15597#29983#29984","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a pedestrian crosswalk ahead; prepare to stop#There is a school zone ahead; children may be crossing the road"},
{"testId":641,"qid":27836,"description":"You arrive at a traffic signal intersection with all of its lights turned off. Which of the following should you do?","explanation":"When the traffic lights are out, you must stop, yield to cross traffic, and continue only when it is safe to do so (with great care). [Approaching a Non-Operating Intersection, Traffic Signals, Chapter 4: Traffic Signs and Signals, Indiana Driver's Manual]","imageName":"202003051805205845.jpg","correctAnswer":29985,"isBookmarked":0,"aid":"23416#29346#29347#29985","text":"Stop at the intersection as if you had a red light#Treat the intersection as an uncontrolled intersection#Proceed through the intersection as if you had a green light#Stop and then yield to cross traffic"},
{"testId":641,"qid":27837,"description":"What is the meaning of this sign?","explanation":"A significant drop or downhill awaits. Keep an eye out for vehicles that are gently descending. To decrease brake wear, check your brakes, slow down, then change into a low gear. [Warning Signs, Indiana Driver's Manual, Chapter 4: Traffic Signs and Signals]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"15602#15689#26841#29986","text":"Only trucks can use the road ahead; find another route#There is a runaway truck ramp ahead#There is a steep descent ahead; proceed with caution#Trucks will be entering from a steep side road or driveway; proceed with caution"},
{"testId":641,"qid":27838,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a traffic officer's instructions, even if it means violating other traffic devices or laws. For example, if a traffic official waves you through a red light or a stop sign, you should proceed. [Indiana Driver's Manual, Traffic Control Officers and Official Processions, Sharing the Road with Other Vehicles, Chapter 5: Safe Vehicle Operation]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#2529#14886#15196","text":"A stop sign#A traffic signal#A traffic officer#A prohibitory sign"},
{"testId":641,"qid":27839,"description":"Here are two flashing boards (panels) that you might see in a construction zone. Drivers are directed to merge or pass to the right by Sign A. What exactly does Sign B mean?","explanation":"You should continue with care if you see this flashing board (panel). [Work Zones, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"1367604090_t1q44.png","correctAnswer":28148,"isBookmarked":0,"aid":"28148#29987#29988#29989","text":"Proceed with caution#You must stop ahead#Merge or pass to either side#Road ends; turn around and go back"},
{"testId":641,"qid":27840,"description":"Which of the following is true about keeping track of distance?","explanation":"When following another vehicle, you should always stay at least two to three seconds behind it. This is the recommended minimum following distance. Maintain a higher following distance in bad weather, poor visibility, or heavy traffic. The bigger the following distance, the more time you will have to brake or maneuver if the car in front of you comes to a halt. [Braking and Following Distances, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202001302249003541.jpg","correctAnswer":29992,"isBookmarked":0,"aid":"29990#29991#29992#29993","text":"You should follow a vehicle close enough to make polite conversation with the driver#You should remain 40 feet behind the vehicle ahead at all times#You should remain at least two to three seconds behind the vehicle ahead#You should remain at least six seconds behind the vehicle ahead"},
{"testId":641,"qid":27841,"description":"You approach a school bus that has stopped to pick up passengers. What should you do?","explanation":"You are not permitted to pass a school bus that has stopped for passengers, turned on flashing red lights, or extended its stop arm. This guideline applies regardless of which way the bus is going. [School Buses, Sharing the Road with Other Vehicles, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202003051658529900.jpg","correctAnswer":29996,"isBookmarked":0,"aid":"15611#29994#29995#29996","text":"You must slow down to 10 mph and pass with caution#You must come to a complete stop and pass at 20 mph or less once there are no children on the road#You must stop if you are traveling in the same direction as the bus#You must stop, no matter which direction you are traveling in"},
{"testId":641,"qid":27842,"description":"You've pulled over to the side of the road, looking upward. There isn't one. Which way should your front wheels be pointed?","explanation":"When parking uphill without a curb, keep your back to the road. If your automobile begins to roll, it will slide off the road and away from traffic. [Parking and Reversing, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002181226366215.jpg","correctAnswer":25342,"isBookmarked":0,"aid":"9906#25321#25341#25342","text":"Straight#In any direction#Toward the road#Away from the road"},
{"testId":641,"qid":27843,"description":"Which of the following statements concerning the speed restrictions in Indiana is FALSE?","explanation":"You must keep up with traffic while without exceeding the maximum speed restriction [9-21-5-7]. Although the specified minimum speed limit is enforced, you may drive slower if required by another legislation or a safety problem such as fog, severe weather, or heavy traffic [9-21-5-8]. As a result, regardless of the official speed restriction, you must drive at a slower pace when circumstances call for it. A twisting road, the peak of a high hill, severe rain, or fog would all need you to slow down. [9-21-5-4]. Vehicles going at a slower speed than the flow of traffic shall remain in the right lane [9-21-5-9]. [Indiana Code, IC 9-21-5]","imageName":"202003151746465721.jpg","correctAnswer":29998,"isBookmarked":0,"aid":"29997#29998#29999#30000","text":"If you are on a multilane road and you cannot keep up with the flow of traffic, you must use the right lane#You must never drive any slower than the minimum speed limit#You may be legally required to drive slower than the maximum speed limit during times of poor weather or visibility#You may be legally required to drive at a reduced speed on winding or hilly roads, despite the posted speed limit"},
{"testId":641,"qid":27844,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus with red flashing lights or a stop arm. This law, however, does not apply if you are on the other side of a split highway. However, you should continue with care. A shared center turning lane (also known as a two-way left-turn lane) is not regarded a barrier for this purpose. [School Buses, Sharing the Road with Other Vehicles, Indiana Driver's Manual, Chapter 5: Safe Vehicle Operation]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#30001#30002","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 10 mph or less and proceed with caution#Yes, you must stop, but then you may proceed when it is safe to do so"},
{"testId":641,"qid":27845,"description":"On a four-lane highway, you are on the right lane. You see an emergency vehicle stopped with its lights flashing in front of you. What should you do?","explanation":"Always attempt to leave an empty lane between yourself and a stopped emergency vehicle with its lights flashing. If you can't do it safely, you should slow down and take your time. [Indiana Driver's Manual, Emergency Vehicles, Sharing the Road with Other Vehicles, Chapter 5: Safe Vehicle Operation]","imageName":"202001241703386758.jpg","correctAnswer":30004,"isBookmarked":0,"aid":"30003#30004#30005#30006","text":"Maintain your speed but proceed with caution#Leave an empty lane between your vehicle and the emergency vehicle if you can do so safely. Otherwise, slow down and proceed with caution#Slow down and prepare to stop#Leave an empty lane between your vehicle and the emergency vehicle and reduce your speed by 20 mph"},
{"testId":662,"qid":27846,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":662,"qid":27847,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":662,"qid":27848,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Before entering the roundabout, slow down and ready to yield to traffic. [Oregon Driver Manual, 3. Turns and Intersections, Roundabouts]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#39441#39442#39443","text":"There is a roundabout ahead#U-turns are allowed ahead#Wrong way; turn around here#Only left turns are allowed ahead"},
{"testId":662,"qid":27849,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the one on the right. Car A must yield in this situation. [Intersections, Oregon Driver Manual, 3. Turns and Intersections]","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23403#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#None of the above are true#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":662,"qid":27850,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":662,"qid":27851,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the center of a junction and an emergency vehicle arrives, do not block the intersection. Instead, go through the junction and immediately pull over. [4. Sharing the Road, Oregon Driver Manual, Emergency Vehicles]","imageName":"202001302235285631.jpg","correctAnswer":39445,"isBookmarked":0,"aid":"15493#31034#39444#39445","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and possibly dangerous#Immediately pull over to the right#Once you are clear of any intersection, immediately pull over to the right"},
{"testId":662,"qid":27852,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Oregon Driver Manual, Warning Signs, 1. Signs and Traffic Signals]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":662,"qid":27853,"description":"When do high-beam headlights need to be dimmed under Oregon law?","explanation":"When you are near 500 feet of an incoming vehicle or within 350 feet of a vehicle you are following in Oregon, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Oregon Driver Manual, Using Lights, Hazardous Conditions, 7. Safe and Responsible Driving]","imageName":"202002211503506612.jpg","correctAnswer":39446,"isBookmarked":0,"aid":"29949#29950#39446#39447","text":"When you are within 500 feet of another vehicle#When you are within 1,000 feet of another vehicle#When you are within 500 feet of an oncoming vehicle or following another vehicle within 350 feet#When you are within 350 feet of an oncoming vehicle or following another vehicle within 500 feet"},
{"testId":662,"qid":27854,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":662,"qid":27855,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Oregon unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#38859#39448","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you will have the right of way#No. In Oregon, you cannot turn right at a red signal"},
{"testId":662,"qid":27856,"description":"Which of the following is most accurate regarding safety belts in Oregon?","explanation":"Every passenger of a vehicle in Oregon is required by law to wear a seat belt or be fastened in a qualified child restraint or booster seat. 7. Safe and Responsible Driving, Oregon Driver Manual], [ORS 811] .210]","imageName":"202003151755527296.jpg","correctAnswer":39450,"isBookmarked":0,"aid":"23513#39449#39450#39451","text":"Only the driver must wear a safety belt#Only the driver and front-seat passengers must wear safety belts#The driver and all passengers must wear safety belts#Safety belts are optional"},
{"testId":662,"qid":27857,"description":"Which of the following things about headlights is not true?","explanation":"From sundown until daybreak and anytime visibility is poor at 1,000 feet, you must use your headlights in Oregon. During the day, you may also utilize your headlights to make your car more apparent to other drivers. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. Driving at night or in poor weather with just your parking lights on is banned in Oregon. [Oregon Driver Manual, Using Lights, Hazardous Conditions, 7. Safe and Responsible Driving]","imageName":"202003161149128995.jpg","correctAnswer":36019,"isBookmarked":0,"aid":"30154#36019#39452#39453","text":"You must use your headlights between sunset and sunrise#You may drive with your parking lights on whenever visibility is poor during the day#You must use your headlights whenever visibility is poor at 1,000 feet#During the day, you can use your headlights to make your vehicle more visible to others"},
{"testId":662,"qid":27858,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":662,"qid":27859,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before the indicated crossing. Otherwise, pull over before the unmarked crosswalk to avoid entering the junction. [Intersections, Oregon Driver Manual, 3. Turns and Intersections]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":662,"qid":27861,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [Oregon Driver Manual, Turns, 3. Turns and Intersections]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":662,"qid":27862,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":662,"qid":27863,"description":"Is it legal to turn left at a green light?","explanation":"After yielding to pedestrians, incoming cars, and vehicles already at the junction, you may continue on a green light. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"39455#39456#39457#39458","text":"No, you may only turn left on a green arrow#No, not unless there is a Left Turns Allowed sign posted#Yes, this is a \"protected\" turn, so you'll have the right of way#Yes, but you must first yield to pedestrians and oncoming vehicles"},
{"testId":662,"qid":27864,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right of way. [Left Turns, Turns, Turns, 3. Oregon Driver Manual, Turns and Intersections]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#26796#36008#39459","text":"Car A must yield because it is turning left#Car A must yield because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":662,"qid":27865,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Intersections, Oregon Driver Manual, 3. Turns and Intersections]","imageName":"202002181211246194.jpg","correctAnswer":39461,"isBookmarked":0,"aid":"15353#39460#39461#39462","text":"Car A, because it is turning left#Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Whichever car that the drivers decide should go first"},
{"testId":662,"qid":27866,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"In Oregon, a two-way left-turn lane (known as a special left-turn lane) is designated for making left turns from either direction. Unlike in some other states, automobiles in Oregon are permitted to turn left onto the highway in this lane and subsequently merge into another lane. You are not permitted to drive, park, or pass through this area. Use care in this lane since cars driving in the other way may use it as well. [Pavement Markings, 2. Lane Travel, Oregon Driver Manual]","imageName":"202001301806403967.jpg","correctAnswer":30214,"isBookmarked":0,"aid":"8967#30214#36189#39463","text":"passing#making left turns#parking#emergency stops"},
{"testId":662,"qid":27867,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Intersections, Oregon Driver Manual, 3. Turns and Intersections]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":662,"qid":27868,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#39464#39465","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Stop and remain stopped until the red light stops flashing#Proceed with caution; this signal is broken"},
{"testId":662,"qid":27869,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Oregon Driver Manual, 3. Turns and Intersections, Roundabouts]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":662,"qid":27870,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Oregon Driver Manual, Warning Signs, Signs, 1. Signs and Traffic Signals]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":662,"qid":27871,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":662,"qid":27872,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 10 feet of a fire hydrant is illegal in Oregon. This limitation will allow the fire department to reach the hydrant. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Oregon Driver Manual, No Parking, 6. Parking and Stopping]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":662,"qid":27873,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. In the absence of signs or signals, each vehicle must yield to the vehicle on its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Intersections, Oregon Driver Manual, 3. Turns and Intersections]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":662,"qid":27874,"description":"Which of the following is true for Oregon's construction zones?","explanation":"In a work zone, drive slowly and follow the imposed speed restrictions. Speeding fines may be increased whether or not employees are present. Road workers must always be given the right of way. Failure to do so might result in a citation and a $525 fine. [ORS 153.020], [ORS 811] .233]","imageName":"202002101222518967.jpg","correctAnswer":39466,"isBookmarked":0,"aid":"39466#39467#39468#39469","text":"In a work zone, fines for speeding are doubled#In a work zone, fines for speeding are doubled only when workers are present#Road workers in work zones must follow the same right-of-way rules as everyone else#You do not have to obey reduced work zone speed limits at night or in poor weather"},
{"testId":662,"qid":27875,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Oregon Driver Manual, Turn Signals, 3. Turns and Intersections]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":662,"qid":27876,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Oregon Driver Manual, 6. Parking and Stopping, Parking on Hills]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":662,"qid":27877,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to all incoming traffic before turning left. You must also yield to pedestrians before turning while crossing a sidewalk or sidewalk area to enter or leave a highway. [Oregon Driver Manual, Turns, 3. Turns and Intersections], [Oregon Driver Manual, Pedestrians, 4. Sharing the Road]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":662,"qid":27878,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand-and-arm gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Oregon Driver Manual, Turn Signals, 3. Turns and Intersections]","imageName":"202002181152514351.jpg","correctAnswer":6476,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":662,"qid":27879,"description":"Three vehicles are sequentially approaching this four-way stop. Which vehicle should go first?","explanation":"At a junction with an all-way stop, you must stop and then give way to any cars that came there before you. In other words, traffic should go forward in the order it came. Here, Car B may go in front of Car A, Car B, and Car C. Each vehicle must halt before moving forward (of course). [Turns and Intersections, Chapter 3, Oregon Driver Manual]","imageName":"202002181231057295.jpg","correctAnswer":39472,"isBookmarked":0,"aid":"39460#39461#39472#39473","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car B, because it arrived at the intersection first#Car C, because it is to the right of Car A and Car B"},
{"testId":662,"qid":27880,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":"A vehicle on a T-terminating intersection's road must yield to all vehicles on the through (main) route. Car A must yield to Cars B and C in this situation. Car C, on the other hand, must yield to oncoming traffic since it is turning left at a junction. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [Intersections, 3. Turns and Intersections, Oregon Driver Manual], [Turns, 3. Turns and Intersections, Oregon Driver Manual].","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":662,"qid":27881,"description":"What is the meaning of this sign?","explanation":"This sign alerts you to the impending crossroads. Prepare for the junction by slowing down. [Oregon Driver Manual, Warning Signs, Signs, 1. Signs and Traffic Signals]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":662,"qid":27882,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. Furthermore, under Oregon law, you must stop for pedestrians crossing the street inside any designated or unmarked crosswalk. It makes no difference whether the pedestrian is crossing against a DO NOT WALK signal or a red light (as may be the case here). You must stop for a pedestrian as long as they are inside a crosswalk. [Oregon Driver Manual, Pedestrians, 4. Sharing the Road], [ORS 811] .028]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":662,"qid":27883,"description":"Which of the following assertions concerning rainy driving conditions is correct?","explanation":"Rain reduces your ability to see ahead and increases the distance you must travel to stop your car. When driving on wet roads, use the brakes earlier and more softly than normal. Roads are often slickest within the first 10 to 15 minutes of rain. Rainwater loosens and combines with dirt, oil, and other fluids that have accumulated on the pavement, resulting in a slippery surface. This slick situation will last until further rain washes away those compounds. Slow down to reduce the possibility of skidding or hydroplaning. Turn on your headlights anytime you need to use your windshield wipers in severe weather to boost your visibility to other drivers. On wet, slippery, or snowy conditions, avoid using cruise control. If your car loses traction on a slick surface, the cruise control may spin the drive wheels to keep the vehicle moving. This may exacerbate or even cause a skid. [Oregon Driver Manual, Rain, Hazardous Conditions, 7. Safe and Responsible Driving]","imageName":"202002061923072507.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#23545#39474#39475","text":"All of the above are true#You shouldn't use cruise control on wet roads#Roads are often slipperiest during the first 15 minutes of rain#You should turn on your headlights to increase your visibility to other motorists"},
{"testId":662,"qid":27884,"description":"You come to a train crossing. There are flashing red lights and a bell at the crossing. You can see that there's a train coming. Which of the following is true?","explanation":"Any of the following at a railroad crossing requires you to stop: a flashing red light, a lowered crossing gate, a stop sign, a flagger signalling your halt, or (of course) an oncoming train. If you have to stop, do so ahead of the stop line. You must stop between 15 and 50 feet from the closest rail if there is no stop line. (You don't want to get too near; trains are at least six feet wider than the rails they operate on.) Never attempt to outrun a train. You cannot get the right of way from trains. A train moving at 60 mph may need more than a mile to stop entirely. ORS 811: \"Railroad Crossings; 5. Railroad Crossings, Light Rail and Street Cars\" .455]","imageName":"202003161136554337.jpg","correctAnswer":39476,"isBookmarked":0,"aid":"39476#39477#39478#39479","text":"Don't try to cross the tracks#You can cross the tracks if you don't see any trains approaching#You can cross the tracks if you can do so before the crossing gates are completely lowered#If there is no stop line, you must stop no closer than 5 feet from the nearest rail"},
{"testId":662,"qid":27885,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone or crossing. Slow down and continue with care as schoolchildren may be crossing the road ahead. Follow the instructions of the school crossing guards. In Oregon, the school zone speed restriction is 20 mph. [Oregon Driver Manual, Warning Signs, Signs, 1. Signs and Traffic Signals]","imageName":"202003151742324658.jpg","correctAnswer":39480,"isBookmarked":0,"aid":"15594#15597#36023#39480","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a crosswalk ahead; prepare to stop#There is a school zone or school crossing ahead; slow down and proceed with caution"},
{"testId":662,"qid":27886,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic signals in Oregon are faulty or not working, each driver must stop and yield to pedestrians and traffic as if there were a stop sign displayed. When it is safe to do so, he or she may continue (with utmost care). [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"23414#25300#33517#39481","text":"Continue through as if you had a green light#Treat the intersection like an all-way stop#Stop as if you had a red light#Treat the intersection as if there were no signals at all"},
{"testId":662,"qid":27887,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Oregon Driver Manual, Warning Signs, Signs, 1. Signs and Traffic Signals]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":662,"qid":27888,"description":"In which of the following circumstances is a U-turn permitted?","explanation":"U-turns are prohibited at the following intersections: (1) at a traffic signalized intersection; (2) between city intersections; (3) in an urban area where your vehicle is not visible for 500 feet in either direction; (4) in a rural area where your vehicle is not visible for 1,000 feet in either direction; and (5) wherever signs or roadway markings prohibit U-turns. [Oregon Driver Manual, U-Turns, Turns, 3. Turns and Intersections]","imageName":"202002061625343169.jpg","correctAnswer":39484,"isBookmarked":0,"aid":"39482#39483#39484#39485","text":"At an intersection controlled by traffic signals#Between intersections in a city#In an urban area where your vehicle is clearly visible only within 600 feet in either direction#In a rural area where your vehicle is clearly visible only within 600 feet in either direction"},
{"testId":662,"qid":27889,"description":"Two cars are parked in front of a fire station. Which of the following statements is the most correct?","explanation":"Fire engines may need a lot of space to turn. In Oregon, you must not park or halt within 15 feet of a fire station's driveway entry or within 75 feet on the other side of the road. Car A must be at least 15 feet away from the driveway, while Car B must be at least 75 feet away. [Oregon Driver Manual, No Parking, 6. Parking and Stopping]","imageName":"202002070948543186.jpg","correctAnswer":39486,"isBookmarked":0,"aid":"36038#39486#39487#39488","text":"Car A can park anywhere as long as it doesn't block the driveway; Car B can park anywhere#Car A must be parked at least 15 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 15 feet from the driveway#Car A must be parked at least 15 feet from the driveway; Car B can park anywhere"},
{"testId":662,"qid":27890,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Oregon?","explanation":"At speeds up to 30 mph, the Oregon Driver Manual recommends a following distance of two to four seconds. Allow at least four seconds of following distance over 30 mph. [Oregon Driver Manual, Following Distance, Maintaining a Space Cushion, 2. Lane Travel]","imageName":"202001302249003541.jpg","correctAnswer":39489,"isBookmarked":0,"aid":"7370#19117#33894#39489","text":"40 feet#Six seconds#One car length for every 10 mph of speed#Two to four seconds"},
{"testId":662,"qid":27891,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"When approaching a school bus with flashing red lights on an undivided route, you must come to a full stop, regardless of which direction the bus is heading. You must halt until the bus driver switches off the flashing red lights. The same regulations apply to church buses and worker transportation buses that have flashing red lights. [School Buses; School, Church, or Worker Buses; Sharing the Road; Oregon Driver Manual]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34195#34196#36032#39490","text":"Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in#Slow down to 10 mph and proceed with caution#Stop and then pass the bus at 20 mph or less"},
{"testId":662,"qid":27892,"description":"You've parked on the side of the road, facing downhill. There is no curb. Which way should your front wheels be pointed?","explanation":"If you're parking on a slope without a curb, aim your front wheels toward the road's edge. If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque. [Oregon Driver Manual, 6. Parking and Stopping, Parking on Hills]","imageName":"202002181226366215.jpg","correctAnswer":39492,"isBookmarked":0,"aid":"9906#25321#39491#39492","text":"Straight#In any direction#Away from the edge of the road#Toward the edge of the road"},
{"testId":662,"qid":27893,"description":"Which of the following is NOT true about speed limits in Oregon?","explanation":"You must never exceed the maximum speed limit in Oregon, and you must drive at a reasonable and cautious pace for present driving circumstances, even if that speed is substantially below the maximum speed limit. Slow down significantly below the speed limit, for example, while driving on a snowy road. Even if you are not breaking the speed limit, you might be charged for driving too fast for the circumstances. If you are traveling slower than the rest of the traffic, you must stay right unless you are about to make a left turn, according to Oregon law. [Speed Limits, 2. Lane Travel, Oregon Driver Handbook]","imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#39493#39494#39495","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#The speed limit in business districts is 20 mph except where otherwise posted#You must drive at a speed that is reasonable and safe for current driving conditions#If you are driving slower than the rest of traffic, you must use the right lane unless you are preparing to make a left turn"},
{"testId":662,"qid":27894,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. Do you have to come to a stop here?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There is one exemption in Oregon: You are not required to stop if the school bus is stopped on the other side of a split highway and the roadway is separated by an unpaved median strip or barrier. A painted median strip or a two-way left turn lane is not considered a barrier. (Note: This exemption is not available in all states.) Other exclusions are provided by certain states. Always verify the local driving rules while leaving Oregon.) [School Buses; School, Church, or Worker Buses; Sharing the Road; Oregon Driver Manual]","imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#35929#35931#36039","text":"No#No, but you must slow down to 10 mph as you pass the school bus#Yes, you must stop until the flashing red lights have been turned off#Yes, but then you can proceed when it is safe to do so"},
{"testId":662,"qid":27895,"description":"You're on a four-lane highway. You notice an emergency vehicle stopped ahead with its lights blazing. What should you do?","explanation":"To pass an emergency vehicle that is stopped with its lights flashing, you must move into a non-adjacent lane if feasible, leaving at least one unoccupied lane between you and the emergency vehicle, according to Oregon's Move Over legislation. If this is difficult or dangerous, you must slow down to no more than 5 mph below the speed limit. Note 1: As of January 1, 2018, this law was amended to include any motor vehicle that is stopped with emergency warning systems (such as flares). Note 2: Although every state in the United States currently has its own Move Over statute, some of them demand drivers to perform various things. Always verify the local driving rules while leaving Oregon. [ORS 811], [Emergency Vehicles, 4. Sharing the Road, Oregon Driver Manual] .147]","imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#30005#35047#39336","text":"Proceed with caution#Slow down and prepare to stop#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":630,"qid":27896,"description":"You come to a junction and see this sign. Which of the following should you do?","explanation":"You must completely stop before the stop line, crosswalk, or junction at a stop sign, depending on where you reach first. When the junction is clear and there are no incoming cars that may pose a threat, you should then yield to pedestrians and other vehicles. Only then should you go forward.","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#30391#39086","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":630,"qid":27897,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Additional signs may be erected to govern minimum or midnight speed restrictions.","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph"},
{"testId":630,"qid":27898,"description":"At 40 mph, you're nearing an intersection. You intend to turn at the intersection but cannot see any other vehicles. Is it necessary to signal the turn?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":43123,"isBookmarked":0,"aid":"15480#23505#43123","text":"No, turn signals are only required when there are other vehicles around#No, turn signals are optional in Arkansas#Yes, you must activate your turn signals at least 100 feet before your turn"},
{"testId":630,"qid":27899,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield in this situation.","imageName":"202007120521353376.jpg","correctAnswer":31031,"isBookmarked":0,"aid":"23401#23508#31031","text":"Car B must yield because it is traveling straight through the intersection#Car B must yield because it is on Car A's right#Car A must yield because it is to the left of Car B"},
{"testId":630,"qid":27900,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary.","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop"},
{"testId":630,"qid":27901,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must stop. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over.","imageName":"202001302235285631.jpg","correctAnswer":37684,"isBookmarked":0,"aid":"15493#37684#38647","text":"Slow down#Pull over to the edge of the road and stop#Maintain your speed and direction"},
{"testId":630,"qid":27902,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area.","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph"},
{"testId":630,"qid":27903,"description":"When are high-beam headlights need to be dimmed in Arkansas?","explanation":"When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following in Arkansas, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate.","imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 500 feet of any other vehicle"},
{"testId":630,"qid":27904,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light.","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25288","text":"You are free to proceed#You cannot proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":630,"qid":27905,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Arkansas unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars.","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#43125","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No. In Arkansas, you cannot turn right at a red signal"},
{"testId":630,"qid":27906,"description":"Under Arkansas law, which occupants of a vehicle must wear seat belts or suitable child restraints?","explanation":"Arkansas law requires that the driver, every front-seat passenger, and every passenger under the age of 15 use a seat belt, proper child restraint, or booster seat. Make sure that every person of your car is securely fastened when the vehicle is in motion whenever you drive.","imageName":"202003151755527296.jpg","correctAnswer":43079,"isBookmarked":0,"aid":"16365#39029#43079","text":"The driver and all passengers#The driver and all front-seat passengers#The driver, all front-seat passengers, and all passengers under 15 years of age"},
{"testId":630,"qid":27907,"description":"Which of the following is not TRUE about headlights in Arkansas?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":42814,"isBookmarked":0,"aid":"15516#31432#42814","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You must use your headlights whenever visibility is difficult at 500 feet#To make your vehicle more visible, you may drive with your parking lights on"},
{"testId":630,"qid":27908,"description":"What is the meaning of this sign?","explanation":"This sign instructs cars to stay to the right of an impending island or obstruction.","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"19246#23583#35482","text":"Keep right#A roundabout is ahead#Left turns are prohibited ahead"},
{"testId":630,"qid":27909,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before approaching a designated or unmarked crossing. If there is no crosswalk, you must come to a complete stop before approaching the crossing. Yield to approaching cars and pedestrians. When it is safe to do so, move gently through the junction.","imageName":"202001301744345845.jpg","correctAnswer":44536,"isBookmarked":0,"aid":"14279#14822#44536","text":"Before the crosswalk#Before the stop line#Whichever of the above you reach first"},
{"testId":630,"qid":27910,"description":"When is it permitted to turn left at a red light in Arkansas?","explanation":"When going from one one-way street to another one-way street in Arkansas, you may turn left on a red light unless there is a sign prohibiting so. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state.","imageName":"202007120412393828.jpg","correctAnswer":26726,"isBookmarked":0,"aid":"6334#26726#29958","text":"Never#Only when you are turning from a one-way street onto another one-way street#At any time, unless otherwise posted"},
{"testId":630,"qid":27911,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane.","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15983","text":"Car A#Car B#Both cars"},
{"testId":630,"qid":27912,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care.","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead"},
{"testId":630,"qid":27913,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction.","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles"},
{"testId":630,"qid":27914,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15534#15535#26796","text":"Car B must yield to turning vehicles#Car A must yield because it is turning left#Car A must yield because it is turning"},
{"testId":630,"qid":27915,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation.","imageName":"202002181211246194.jpg","correctAnswer":39461,"isBookmarked":0,"aid":"15353#39460#39461","text":"Car A, because it is turning left#Car A, because it is to the left of Car B#Car B, because it is to the right of Car A"},
{"testId":630,"qid":27916,"description":"A middle lane with a solid yellow line and a dashed yellow line on each side is designated for:","explanation":"A shared center turn lane (also known as a two-way left-turn lane) is designated for making left turns (and, where authorized, U-turns) and may be utilized by cars driving in either direction. In Arkansas, unlike in several other states, cars may turn left into this lane before merging right into another lane. A shared center turn lane is denoted by a solid yellow line and a dashed yellow line on either side. This lane should never be utilized for passing or navigating through traffic. Use care in this lane since cars heading in the other way may be utilizing it as well.","imageName":"202001301806403967.jpg","correctAnswer":30214,"isBookmarked":0,"aid":"8967#30214#31256","text":"passing#making left turns#making right turns"},
{"testId":630,"qid":27917,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"When you have a green light, you may continue after yielding to all oncoming cars and pedestrians at the junction. Car A should yield to Car B in this situation.","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B"},
{"testId":630,"qid":27918,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so.","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#37531#39464","text":"Stop and yield as you would at a stop sign#Yield as you would at a yield sign#Stop and remain stopped until the red light stops flashing"},
{"testId":630,"qid":27919,"description":"Which vehicles are concealed in the truck's blind areas (\"No-Zones\")?","explanation":"No-Zones are enormous blind zones on heavy vehicles and buses. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you.","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862","text":"Car A#Car B#Car C"},
{"testId":630,"qid":27920,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross.","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15550#27259","text":"There is a crossroad ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":630,"qid":27921,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal.","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so"},
{"testId":630,"qid":27922,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in Arkansas. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.)","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":630,"qid":27923,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction, each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian.","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14863","text":"Car A#Car B#The pedestrian"},
{"testId":630,"qid":27924,"description":"Which of the following should be adhered to above all others?","explanation":"You must always obey police officers, even if it means violating other traffic devices or norms. For example, if a police officer waves you through a red light or a stop sign, you should proceed.","imageName":"202002211500121915.jpg","correctAnswer":16212,"isBookmarked":0,"aid":"2316#2529#16212","text":"A stop sign#A traffic signal#A police officer"},
{"testId":630,"qid":27925,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight.","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#43127","text":"The divided highway ends ahead#A divided highway starts ahead#A shared center turning lane is ahead"},
{"testId":630,"qid":27926,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission).","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638","text":"Away from the curb#Straight#Toward the curb"},
{"testId":630,"qid":27927,"description":"You must yield to _______ before turning left into a driveway.","explanation":"You must yield to oncoming traffic before turning left into an alley or driveway. And, if you drive into or out of a driveway or alley, you must come to a complete stop before crossing the sidewalk and yield to pedestrians.","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles"},
{"testId":630,"qid":27928,"description":"Which of the following statement concerning parking is correct?","explanation":null,"imageName":"202002061627578233.jpg","correctAnswer":23538,"isBookmarked":0,"aid":"23536#23537#23538","text":"The parking brake only needs to be set when you are parking on a hill#You should always leave your car in either neutral or park#You must never leave children or animals unattended in a car, even for a short while"},
{"testId":630,"qid":27929,"description":"Three vehicles are sequentially approaching this four-way stop. Which vehicle should go first?","explanation":"When many cars arrive at an all-way stop at the same moment, they should advance in the sequence in which they arrived. Car B arrived first, thus it should take the lead after halting. Car A should go first, followed by Car C. Of course, before proceeding, each automobile must come to a full stop and yield to pedestrians and oncoming traffic.","imageName":"202002181231057295.jpg","correctAnswer":39472,"isBookmarked":0,"aid":"39460#39461#39472","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Car B, because it arrived at the intersection first"},
{"testId":630,"qid":27930,"description":"Which of the following claims concerning work zones is true in Arkansas?","explanation":null,"imageName":"202002061102364394.jpg","correctAnswer":42054,"isBookmarked":0,"aid":"40111#42054#43128","text":"Fines for speeding are doubled when workers are present#Both of the above statements are true#If there are no reduced speed limits posted, you should obey the normal posted speed limit"},
{"testId":630,"qid":27931,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down.","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead"},
{"testId":630,"qid":27932,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"Before proceeding through a red light, you must come to a complete stop and yield to any oncoming cars and pedestrians in the crosswalk.","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#23543","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to avoid the pedestrian"},
{"testId":630,"qid":27933,"description":"Which of the following claims regarding driving in the rain is true in Arkansas?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":42054,"isBookmarked":0,"aid":"23545#39474#42054","text":"You shouldn't use cruise control on wet roads#Roads are often slipperiest during the first 15 minutes of rain#Both of the above statements are true"},
{"testId":630,"qid":27934,"description":"Which of the following statement about parking near a railroad crossing is correct?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":15590,"isBookmarked":0,"aid":"15590#42517#43131","text":"You must not park within 50 feet of the tracks#You must not park within 100 feet of the tracks#You must be at least 50 feet away on the approaching side of the tracks or 15 feet away on the departing side of the tracks"},
{"testId":630,"qid":27935,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone or crossing. Slow down and continue with care as schoolchildren may be crossing the road ahead. Follow the instructions of the school crossing guards. (Please keep in mind that newer school zone and crossing signs have a neon yellow-green backdrop.)","imageName":"202003151742324658.jpg","correctAnswer":33890,"isBookmarked":0,"aid":"15594#33890#36023","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a school zone ahead; slow down and proceed with caution#There is a crosswalk ahead; prepare to stop"},
{"testId":630,"qid":27936,"description":"Which of the following steps is not required when changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Motorcyclists are often forced to avoid vehicles who have failed to check their blind areas. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you may fail to see the car in front of you until it is too late.","imageName":"202001302245403150.jpg","correctAnswer":44747,"isBookmarked":0,"aid":"16513#42828#44747","text":"Checking your mirrors#Signaling#None of the above actions are optional"},
{"testId":630,"qid":27937,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automated transmission, change into a low gear to avoid fading (losing effectiveness) of your brakes due to misuse.","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#42521","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":630,"qid":27938,"description":"A portion of the curbs on this road have been painted red and yellow. What does this mean?","explanation":"Colored curb markings may be used in addition to or instead of regulation signs to limit parking. A yellow curb denotes a loading zone in Arkansas. As with a No Parking sign, you may only stop for the purpose of picking up or dropping off merchandise or persons. A red curb signifies a fire zone. You are not permitted to park or halt there in order to maintain it clear for emergency usage. (Please keep in mind that there is no national standard for curb colors.) Curb colors are used differently in each state, and others do not use them at all. Always verify the local traffic rules while going out of state.)","imageName":"1367937027_t1q43.png","correctAnswer":43135,"isBookmarked":0,"aid":"43132#43133#43135","text":"The yellow curb is a taxi stand, and the red curb is a no stopping zone#The yellow curb is a no parking zone, and the red curb is a no standing zone#The yellow curb is a loading zone, and the red curb is a fire zone"},
{"testId":630,"qid":27939,"description":"Two cars are parked in front of a fire station. Which of the following statements is the most correct?","explanation":null,"imageName":"202002070948543186.jpg","correctAnswer":36035,"isBookmarked":0,"aid":"36035#36036#36037","text":"Car A must be parked at least 20 feet from the driveway, and Car B must be parked at least 75 feet from the driveway#Car A must be parked at least 75 feet from the driveway, and Car B must be parked at least 20 feet from the driveway#Car A must be parked at least 20 feet from the driveway; Car B can park anywhere"},
{"testId":630,"qid":27940,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Arkansas?","explanation":"According to the Arkansas Driver License Study Guide, a following distance of at least two seconds behind the car in front is recommended. Increase your following distance even further under risky driving circumstances.","imageName":"202001302249003541.jpg","correctAnswer":10881,"isBookmarked":0,"aid":"10879#10881#33894","text":"Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":630,"qid":27941,"description":"On an open road, you notice a school bus stopped ahead, its red lights flashing and its stop arm outstretched. What are your options?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34196#34205#36032","text":"Stop, no matter which direction you are traveling in#Stop, yield to pedestrians, and then proceed with caution#Slow down to 10 mph and proceed with caution"},
{"testId":630,"qid":27942,"description":"You've parked on the side of the road, facing downhill. There is no curb. Which way should your front wheels be pointed?","explanation":"If you're parking on a slope without a curb, aim your front wheels toward the road's edge. If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque.","imageName":"202002181226366215.jpg","correctAnswer":39492,"isBookmarked":0,"aid":"9906#39491#39492","text":"Straight#Away from the edge of the road#Toward the edge of the road"},
{"testId":630,"qid":27943,"description":"Which of the following statements concerning the speed restrictions in Arkansas is FALSE?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":43136,"isBookmarked":0,"aid":"40416#43136#43137","text":"If you cannot meet a road's minimum speed limit, find another route#On a straightaway, you can always drive at the maximum speed limit unless visibility falls below 500 feet#Depending on driving conditions, you can be cited for driving at the maximum speed limit"},
{"testId":630,"qid":27944,"description":"You're on a divided highway with a large central median. On the opposite side of the highway, you notice a school bus stopped with its red lights blinking. Are you required to come to a halt here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":43139,"isBookmarked":0,"aid":"42525#43139#43140","text":"Yes, you must stop and yield to any pedestrians on the road before you proceed#No, but you must proceed with caution#No, but you must slow to 15 mph"},
{"testId":630,"qid":27945,"description":"You are on a multi-lane highway. You notice an emergency vehicle with its lights flashing ahead of you. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":43010,"isBookmarked":0,"aid":"28148#43010#43012","text":"Proceed with caution#Move into the farthest lane from the emergency vehicle if possible; otherwise slow down#Reduce your speed by 25 mph and proceed with caution"},
{"testId":627,"qid":27946,"description":"You come to an intersection and see this sign. Which of the following should you do?","explanation":"You must completely stop before the stop line, crosswalk, or junction at a stop sign, depending on where you reach first. When the junction is clear, continue while giving way to pedestrians and other cars. [Alabama Driver Manual, Chapter 5: Signs, Signals, and Road Markings, Regulatory Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":627,"qid":27947,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Additional signs may be erected to govern minimum or midnight speed restrictions. [Regulatory Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":627,"qid":27948,"description":"At 40 mph, you're nearing an intersection. You intend to turn at the intersection but cannot see any other vehicles. Is it necessary to signal the turn?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":15483,"isBookmarked":0,"aid":"15480#15481#15483#42807","text":"No, turn signals are only required when there are other vehicles around#No, turn signals are optional in Alabama#Yes, you must activate your turn signals at least 100 feet before you turn#Yes, you must use your turn signals even if only briefly"},
{"testId":627,"qid":27949,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#15487#42808","text":"Car A must yield to its right#Car B must yield to its left#Car A has the right-of-way because it is turning onto a new road#The right-of-way should be decided by the drivers"},
{"testId":627,"qid":27950,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Slow down as you approach a yield sign and ready to yield to pedestrians and traffic ahead. Be prepared to halt if necessary. [Regulatory Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":627,"qid":27951,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"If an emergency vehicle with flashing red or blue lights and a siren approaches from either direction, you must pull over to the curb or side of the road and come to a full stop. [Stopping, Chapter 6: Traffic Laws, Alabama Driver Manual]","imageName":"202001302235285631.jpg","correctAnswer":28519,"isBookmarked":0,"aid":"15493#28519#38647#42809","text":"Slow down#Pull over to the side of the road and stop#Maintain your speed and direction#Speed up and find some place to turn off out of the way"},
{"testId":627,"qid":27952,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":627,"qid":27953,"description":"When are high-beam headlights need to be dimmed in Alabama?","explanation":"When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following in Alabama, you must lower your high lights. Avoid shining your high beams on any other vehicle that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Alabama Driver Manual, Night Driving, Chapter 7: Adjust to Driving Conditions]","imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":627,"qid":27954,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Signals; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003070857473536.jpg","correctAnswer":42810,"isBookmarked":0,"aid":"15504#15505#25287#42810","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed after you yield to any traffic already in the intersection"},
{"testId":627,"qid":27955,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#39805#42811","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you'll have the right-of-way#No. In Alabama, you cannot turn right at a red signal"},
{"testId":627,"qid":27956,"description":"Under Alabama law, which occupants of a vehicle must wear safety belts or suitable child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":42813,"isBookmarked":0,"aid":"16365#26667#42812#42813","text":"The driver and all passengers#The driver and front-seat passengers#The driver and passengers under 15 years of age#The driver, front-seat passengers, and passengers under 15 years of age"},
{"testId":627,"qid":27957,"description":"Which of the following is not TRUE about headlights?","explanation":"When you can't see clearly or other drivers can't see you, turn on your headlights. You must use your headlights from half an hour after sundown to half an hour before daybreak, and anytime visibility is poor at 500 feet, according to Alabama law. In addition, whether the wipers are set to intermittent or \"mist,\" you must use your headlights anytime you use your windshield wipers in inclement weather. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Alabama Driver Manual, Night Driving, Chapter 7: Adjust to Driving Conditions]","imageName":"202003161149128995.jpg","correctAnswer":42814,"isBookmarked":0,"aid":"15516#42814#42815#42816","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#To make your vehicle more visible, you may drive with your parking lights on#You must use your headlights whenever visibility is 500 feet or less#You must use your headlights whenever you use your windshield wipers continuously in bad weather"},
{"testId":627,"qid":27958,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the split roadway is coming to an end ahead. The road will be converted to a two-way street. Keep to the right and keep an eye out for incoming traffic. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002101249362358.jpg","correctAnswer":8873,"isBookmarked":0,"aid":"8873#19179#19180#22656","text":"The divided highway ends ahead#Traffic is merging ahead#One-way traffic is ahead#A divided highway starts ahead"},
{"testId":627,"qid":27961,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [Alabama Driver Manual, Proper Turning Rules, On the Road, Chapter 3: The Driving Task]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":627,"qid":27962,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Traffic Signals; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":627,"qid":27963,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. [Traffic Signals; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458#39805","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":627,"qid":27964,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15534#15535#26796#36008","text":"Car B must yield to turning vehicles#Car A must yield because it is turning left#Car A must yield because it is turning#The drivers should decide which of them should go first"},
{"testId":627,"qid":27965,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202002181211246194.jpg","correctAnswer":39461,"isBookmarked":0,"aid":"39460#39461#39462#42817","text":"Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Whichever car that the drivers decide should go first#Car A, because it is turning onto a new road"},
{"testId":627,"qid":27966,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a shared center turning lane with a two-way left turn lane. This lane is for cars moving in either direction making a left turn. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be utilized to pass. [Pavement Markings; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202001301806403967.jpg","correctAnswer":30214,"isBookmarked":0,"aid":"8967#30214#36189#39463","text":"passing#making left turns#parking#emergency stops"},
{"testId":627,"qid":27967,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"When you have a green light, you may continue after yielding to all oncoming cars and pedestrians at the junction. Car A should yield to Car B in this situation. [Traffic Signals; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":627,"qid":27968,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a STOP sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Stopping, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202003051722347868.jpg","correctAnswer":15543,"isBookmarked":0,"aid":"15543#39464#39465#42818","text":"Stop and yield as you would at a STOP sign#Stop and remain stopped until the red light stops flashing#Proceed with caution; this signal is broken#Yield as you would at a YIELD sign"},
{"testId":627,"qid":27969,"description":"Which vehicles are concealed in the truck's blind areas (\"No-Zones\")?","explanation":"No-Zones are enormous blind zones on heavy vehicles and buses. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [Alabama Driver Manual, Chapter 3: The Driving Task, Sharing the Road with Large Vehicles]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#15441","text":"Car A#Car B#Car C#Cars A and B"},
{"testId":627,"qid":27970,"description":"What is the meaning of this sign?","explanation":"This sign alerts you that traffic from the right lane is going to merge into your lane. Adjust your speed and location to enable merging cars to safely combine. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002101239016186.jpg","correctAnswer":3126,"isBookmarked":0,"aid":"3123#3126#9460#28614","text":"Merging from the left#Merging from the right#Two-way traffic#The end of a divided highway"},
{"testId":627,"qid":27971,"description":"What is the meaning of this sign?","explanation":"This sign warns of a traffic island or other impending hazard. It may be passed on either the left or right side. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002101234545593.jpg","correctAnswer":42819,"isBookmarked":0,"aid":"22656#32664#42819#42820","text":"A divided highway starts ahead#There is a Y-intersection ahead#There is an obstacle ahead; pass to the left or right#Turn either left or right at the intersection ahead"},
{"testId":627,"qid":27972,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 15 feet of a fire hydrant is forbidden in Alabama. (There are several more sites where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Alabama Driver Manual, Chapter 3: The Driving Task, Parking]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":627,"qid":27973,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction (one without signs or signals), each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Alabama Driver Manual, Chapter 6: Traffic Laws, Pedestrians]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":627,"qid":27974,"description":"Which of the following should be adhered to above all others?","explanation":null,"imageName":"","correctAnswer":16212,"isBookmarked":0,"aid":"2529#15195#15196#16212","text":"A traffic signal#A STOP sign#A prohibitory sign#A police officer"},
{"testId":627,"qid":27975,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#42821","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#A two-way left-turn lane starts ahead"},
{"testId":627,"qid":27976,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Alabama Driver Manual, Parking on a Hill, Parking, Chapter 3: The Driving Task]","imageName":"202005131343083048.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":627,"qid":27977,"description":"You must yield to _______ before turning left into a driveway.","explanation":"You must yield to oncoming traffic before turning left into an alley or driveway. And, if you drive into or out of a driveway or alley, you must come to a complete stop before crossing the sidewalk and yield to pedestrians. [Stopping, Alabama Driver Manual, Chapter 6: Traffic Laws], [Pedestrians, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":627,"qid":27978,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This hand gesture denotes a turn to the right. [Changing Lanes, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":627,"qid":27979,"description":"This symbol denotes:","explanation":"This sign alerts you that you are approaching an underpass with a vertical clearance of 13'6\" \" (13 feet 6 inches). Trucks in Alabama may lawfully be up to 13'6\" long \"height. Trucks may lawfully be up to 14 feet tall in several Western states (and in a few Western states, even taller). [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002061128193125.jpg","correctAnswer":30561,"isBookmarked":0,"aid":"19351#19352#19353#30561","text":"a median ahead; the width of the road is 13 feet 6 inches#a parking area ahead; the width of the road is 13 feet 6 inches#a bridge ahead; the width of the road is 13 feet 6 inches#an underpass ahead; vertical clearance is 13 feet 6 inches"},
{"testId":627,"qid":27980,"description":"Which of the following is true about railroad crossings?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#42822#42823#42824","text":"All of the above are true#You must stop at a flashing red railroad signal until it is safe#You may drive through a railroad crossing gate only if it is completely raised#When you have to stop at a railroad crossing, you must stop between 15 and 50 feet of the tracks"},
{"testId":627,"qid":27981,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a fire station nearby. Keep an eye out for fire engines entering this route. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002101253183254.jpg","correctAnswer":42825,"isBookmarked":0,"aid":"34068#34069#34071#42825","text":"Farm vehicles may enter the roadway#Trucks carrying hazardous materials may enter the roadway#Heavy trucks may enter the roadway#Fire trucks may enter the roadway"},
{"testId":627,"qid":27982,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all pedestrians and oncoming vehicles before turning at a red signal. [Alabama Driver Manual, Chapter 6: Traffic Laws, Pedestrians]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":627,"qid":27983,"description":"Which of the following statement concerning hydroplaning is correct?","explanation":"When a car strikes standing water at a high enough speed, its tires hop over the water's surface, this is known as hydroplaning. Because the tires will not be in touch with the road, traction will be minimal. At speeds as low as 35 mph, a vehicle might begin to hydroplane. The tires may lose all touch with the road at 55 mph. A minor turn or simply a blast of wind at this moment might put the car into an uncontrolled skid. Make sure your tires have enough tread to assist avoid hydroplaning. Don't brake if you discover yourself hydroplaning. Instead, let your car slow down by taking your foot off the pedal. [Alabama Driver Manual, Chapter 7: Adjust to Driving Conditions]","imageName":"202002061923072507.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"15589#23029#42826#42827","text":"A gust of wind may be enough to send a hydroplaning car out of control#All of the above are true#If you start to hydroplane, you should let your vehicle slow down#Your tires can start to hydroplane at speeds as low as 35 mph"},
{"testId":627,"qid":27984,"description":"Is this car parked legally?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":27280,"isBookmarked":0,"aid":"27280#27282#33553#39824","text":"No, you must not park within 50 feet of a railroad crossing#Yes, you can park near a railroad crossing as long as you stay behind the stop line#Yes, you can park near a railroad crossing as long as you're not on the tracks#No, you must not park within 100 feet of a railroad crossing"},
{"testId":627,"qid":27985,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone or crossing. Slow down and continue with care as schoolchildren may be crossing the road ahead. Follow the instructions of the school crossing guards. (Please keep in mind that newer school zone and crossing signs have a neon yellow-green backdrop.) [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202003151742324658.jpg","correctAnswer":39480,"isBookmarked":0,"aid":"15594#15597#36023#39480","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a crosswalk ahead; prepare to stop#There is a school zone or school crossing ahead; slow down and proceed with caution"},
{"testId":627,"qid":27986,"description":"Which of the following steps is not required when changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Motorcyclists are often forced to avoid vehicles who have failed to check their blind areas. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you may fail to see the car in front of you until it is too late. [Changing Lanes, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#16513#16514#42828","text":"None of the above#Checking your mirrors#Looking over your shoulder#Signaling"},
{"testId":627,"qid":27987,"description":"What is the meaning of this sign?","explanation":"This lane direction sign is a regulatory sign. Cars in the left lane must turn left, while vehicles in the right lane may drive straight or turn right. [Regulatory Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202004071522592483.jpg","correctAnswer":31538,"isBookmarked":0,"aid":"31535#31536#31538#39333","text":"Vehicles in the left lane must turn left, and vehicles in the right lane must turn right#Vehicles in the left lane must turn left, and vehicles in the right lane must go straight#Vehicles in the left lane must turn left, and vehicles in the right lane may go straight or turn right#Vehicles in the left lane must turn left, and vehicles in the right lane must merge right"},
{"testId":627,"qid":27988,"description":"This symbol denotes:","explanation":"This sign denotes the presence of a double bend ahead. The road turns to the right and then to the left ahead. (A winding road sign would be placed instead if there was a triple bend coming.) Slow down, stay to the right, and do not pass. [Warning Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202002061305216978.jpg","correctAnswer":27018,"isBookmarked":0,"aid":"19534#27018#41735#42829","text":"a winding road ahead#a double curve ahead#a curve to the right ahead#a sharp right turn ahead"},
{"testId":627,"qid":27989,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Changing Lanes, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":627,"qid":27990,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Alabama?","explanation":"According to the Alabama Driver Manual, a following distance of at least two seconds behind the vehicle in front is recommended. Increase your following distance even further under risky driving circumstances. [How to Avoid Rear End Collisions, Alabama Driver Manual, Chapter 4: The Driver]","imageName":"202001302249003541.jpg","correctAnswer":10881,"isBookmarked":0,"aid":"7370#10879#10881#33894","text":"40 feet#Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":627,"qid":27991,"description":"On an open road, you notice a school bus stopped ahead, its red lights flashing and its stop arm outstretched. What are your options?","explanation":"If you see a school bus stopped with its red lights flashing on an undivided highway in Alabama, you must stop before approaching the bus, whether it is on your side or the opposing side. You must stay stopped until the stop arm retracts and the flashing red lights go off. [Stopping, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34196#34205#34928#36032","text":"Stop, no matter which direction you are traveling in#Stop, yield to pedestrians, and then proceed with caution#Stop if you are traveling in the same direction as the school bus#Slow down to 10 mph and proceed with caution"},
{"testId":627,"qid":27992,"description":"You parked your vehicle on a hill without a curb. Which way should the front wheels face?","explanation":"If you park on a slope without a curb, turn your front wheels toward the road's edge (i.e., away from traffic). If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque. [Alabama Driver Manual, Parking on a Hill, Parking, Chapter 3: The Driving Task]","imageName":"202005131347154851.jpg","correctAnswer":39492,"isBookmarked":0,"aid":"9906#25321#39491#39492","text":"Straight#In any direction#Away from the edge of the road#Toward the edge of the road"},
{"testId":627,"qid":27993,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"The use of two motorbikes in a single lane is not prohibited under Utah law. Sharing a lane with another motorbike is still dangerous (or any other motor vehicle). To navigate safely, each automobile, motorbike, and truck needed a full lane width. To avoid a danger, you may need to use the whole width of the lane.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#15618#42830","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#One driver must wave the other driver through#The first car to start moving gets the right-of-way; the other car must then yield"},
{"testId":627,"qid":27994,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This motorist wants to make a left turn. [Changing Lanes, Alabama Driver Manual, Chapter 6: Traffic Laws]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":627,"qid":27995,"description":"You are in the right lane of a four-lane highway with a marked 50 mph speed limit. You see an emergency vehicle with flashing lights halted in front of you. What ought you to do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":42833,"isBookmarked":0,"aid":"28148#42831#42832#42833","text":"Proceed with caution#Slow down to 25 mph and proceed with caution#Slow down to 35 mph or less; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down to 35 mph or less"},
{"testId":628,"qid":27996,"description":"You come to a junction and see this sign. Which of the following should you do?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. Next, give way to pedestrians and other traffic, and only go forward when it's safe to do so. [Driver's Guide for the State of Alaska, Stop Sign, Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":628,"qid":27997,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Other signs may specify nighttime or minimum speed restrictions. [State of Alaska Driver Manual, Speed Laws]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":628,"qid":27998,"description":"You're about to cross a junction and want to turn left. There are no other vehicles visible. Is it still necessary to signal?","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn or change lanes, you must begin signaling at least 100 feet in advance, according to Alaska law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. A rapid turn or lane change might also catch pedestrians or other drivers off guard. [Signaling, Alaska Driver Handbook]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"15624#29975#31404#42498","text":"No, turn signals are optional in Alaska#No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 100 feet before you turn#Yes, you must signal for at least one second before you turn"},
{"testId":628,"qid":27999,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles arrive at an uncontrolled junction about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield in this situation. [Right of Way Law, Intersections, Alaska Driver Handbook], [13 AAC 02] .120]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#15487#42499","text":"Car A must yield to its right#Car B must yield to its left#Car A has the right-of-way because it is turning onto a new road#The right-of-way should be decided by the drivers themselves"},
{"testId":628,"qid":28000,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Yield Sign, Signs, Alaska Driver Handbook]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":628,"qid":28001,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [Emergency Vehicles, Driver Manual for the State of Alaska]","imageName":"202001302235285631.jpg","correctAnswer":35998,"isBookmarked":0,"aid":"15493#35998#38647#39803","text":"Slow down#Pull over, no matter which direction the emergency vehicle is traveling in#Maintain your speed and direction#Pull over if the emergency vehicle is traveling in the same direction as you are"},
{"testId":628,"qid":28002,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Signs, Alaska Driver Handbook]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":628,"qid":28004,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Green Ball, Traffic Signals, Alaska Driver Handbook]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":628,"qid":28005,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Alaska unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars. [Red Ball, Traffic Signals, Alaska Driver Handbook]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#23423#42500","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, but not during rush hour#No. In Alaska, you cannot turn right at a red signal"},
{"testId":628,"qid":28006,"description":"Under Alaska law, which occupants of a vehicle must wear safety belts or suitable child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#42501#42502","text":"Only the driver#The driver and all passengers#The driver and all passengers under 16 years of age#The driver, all front-seat passengers, and all passengers under 16 years of age"},
{"testId":628,"qid":28007,"description":"Which of the following statements about Alaska is FALSE?","explanation":"When you can't see clearly or other drivers can't see you, turn on your headlights. You must use your headlights from half an hour after sundown to half an hour before daybreak, and anytime visibility is poor at 1,000 feet, according to Alaska law. When driving in rain, fog, snow, or dust, utilize your low-beam headlights. (High lights may generate glare by reflecting off precipitation.) Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Lighting Law, Lighting Equipment Use, Alaska Driver Manual]","imageName":"202003161149128995.jpg","correctAnswer":42503,"isBookmarked":0,"aid":"15516#42503#42504#42505","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You may use your parking lights when driving in bad weather#You must use your headlights whenever visibility is 1,000 feet or less#You should use low-beam headlights when driving in rain, fog, snow, or dust"},
{"testId":628,"qid":28008,"description":"What is the meaning of this sign?","explanation":"This sign instructs cars to stay to the right of an impending island or obstruction. [Signs, Alaska Driver Handbook]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":628,"qid":28009,"description":"You've arrived at a stop sign-controlled crossroads. Where must you stop?","explanation":"When approaching a junction regulated by a stop sign, you must come to a complete stop before crossing the stop line. If there is no stop line, you must come to a complete stop before approaching a designated or unmarked crossing. If there is no crosswalk, you must come to a complete stop before approaching the crossing. After stopping and ceding to pedestrians, drive up to the intersection's edge for a better look and continue when safe. [13 AAC 02 .130]","imageName":"202001301744345845.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#14279#14822#14823","text":"All of the above#Before the crosswalk#Before the stop line#Before entering the intersection"},
{"testId":628,"qid":28010,"description":"When you reach at a crossroads, you notice that the traffic lights are not working; they are entirely dark. What should you do?","explanation":"In Alaska, a junction with faulty traffic lights must be treated as an all-way stop (i.e., as if there were a stop sign at each approach to the intersection). That is, you must come to a complete stop before approaching the junction, yield according to stop sign right-of-way standards, and then continue (with great care) when it is safe to do so. [Unlit Signal Head, Traffic Signals, Alaska Driver Handbook]","imageName":"202003051805205845.jpg","correctAnswer":42506,"isBookmarked":0,"aid":"25302#40635#42506#42507","text":"Proceed as if you had a green signal#Assume you have the right-of-way if you're on the busiest road#Stop and yield; proceed with caution when safe#Yield; proceed with caution when safe"},
{"testId":628,"qid":28012,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Yellow Ball, Traffic Signals, Alaska Driver Handbook]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":628,"qid":28013,"description":"Is it legal to turn left at a steady green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Green Ball, Traffic Signals, Alaska Driver Handbook]","imageName":"202001301833114961.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"25289#39458#41298#42508","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a \"Left Turn Allowed\" sign posted, and you must first yield to pedestrians and oncoming vehicles#No, you may turn left only at a green arrow signal"},
{"testId":628,"qid":28014,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A vehicle making a left turn must yield to approaching traffic. As a result, Car B has the right of way here. [13 AAC 02 .125]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#26796#36008#39459","text":"Car A must yield because it is turning left#Car A must yield because it is turning#The drivers should decide which of them should go first#Car B must yield because it is going straight"},
{"testId":628,"qid":28015,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Before entering the roundabout, slow down and ready to yield to traffic. [Signs, Alaska Driver Handbook]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#39441#39442#42509","text":"There is a roundabout ahead#U-turns are allowed ahead#Wrong way; turn around here#A shared center lane starts ahead"},
{"testId":628,"qid":28016,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a two-way left-turn lane with a shared center turning lane. This lane is for vehicles driving in either direction to make left turns. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for driving or passing. [Marking, Alaska Driver Handbook]","imageName":"202001301806403967.jpg","correctAnswer":40607,"isBookmarked":0,"aid":"16863#37840#40607#42510","text":"Passing#Emergency vehicles#Left turns#Car pools"},
{"testId":628,"qid":28017,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [State of Alaska Driver Manual, Traffic Signals]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":628,"qid":28018,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Flashing Red Ball, Traffic Signals, Alaska Driver Handbook]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"19471#23528#34494#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Stop and remain stopped until the signal stops flashing#Yield as you would at a yield sign"},
{"testId":628,"qid":28019,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are enormous blind zones on heavy vehicles and buses. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [State of Alaska Driver Manual for Large Trucks and Buses]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#15441","text":"Car A#Car B#Car C#Cars A and B"},
{"testId":628,"qid":28020,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [State of Alaska Driver Manual, Railroad Crossings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":628,"qid":28021,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts and Intersections, Alaska Driver Manual]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":628,"qid":28022,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 15 feet of a fire hydrant is illegal in Alaska. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [State of Alaska Driver Manual, Parking]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":628,"qid":28023,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction, each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Marking, Alaska Driver Handbook]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":628,"qid":28024,"description":"Which of the following should be adhered to above all others?","explanation":"You must always obey police officers, even if it means violating other traffic devices or norms. For example, if a police officer waves you past a red light or a stop sign, you should proceed. [Unlit Signal Head, Traffic Signals, Alaska Driver Handbook]","imageName":"","correctAnswer":16212,"isBookmarked":0,"aid":"2316#2529#15196#16212","text":"A stop sign#A traffic signal#A prohibitory sign#A police officer"},
{"testId":628,"qid":28025,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Keep to the right of the median. [Signs, Alaska Driver Handbook]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#30423#41158","text":"The divided highway ends ahead#A divided highway starts ahead#There is a shared center turning lane ahead#There is a chicane ahead"},
{"testId":628,"qid":28026,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [State of Alaska Driver Manual, Parking]","imageName":"202005131343083048.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":628,"qid":28027,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to pedestrians and approaching cars before turning left into an alley or driveway. They have right of way. [13 AAC 02 .125]","imageName":"202001241717155576.jpg","correctAnswer":42511,"isBookmarked":0,"aid":"33885#39470#39471#42511","text":"neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians#both pedestrians and oncoming vehicles"},
{"testId":628,"qid":28028,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This motorist wants to make a right turn. [Signaling, Alaska Driver Handbook]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":628,"qid":28029,"description":"On an open road, you notice a school bus stopped ahead, its red lights flashing and its stop arm outstretched. What should you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":38677,"isBookmarked":0,"aid":"34928#38677#40953#42512","text":"Stop if you are traveling in the same direction as the school bus#Stop no matter which direction you are traveling in#Slow to 10 mph and pass with caution#Stop and then pass at 20 mph or less as soon as there are no more children on the road"},
{"testId":628,"qid":28030,"description":"Which of the following is true about railroad crossings?","explanation":"If the railroad crossing lights and gates warn of an impending train, or if you notice one approaching, you must come to a complete stop between 15 and 50 feet from the closest rail. Even after the train has past, use cautious and keep an eye on the signals, gates, and tracks. There might be another train following this one, perhaps from the other way. Only until the signals have ceased and the gates have been fully lifted may you continue to cross the tracks. [13 AAC 02], [Railroad Crossings, Railroad Crossings, State of Alaska Driver Manual] .240]","imageName":"202003161136554337.jpg","correctAnswer":42513,"isBookmarked":0,"aid":"23029#42513#42514#42515","text":"All of the above are true#If the railroad crossing signals are flashing, you must stop until it is safe to proceed#You may drive through a crossing gate before it is completely raised#If there is no crossing gate, you can proceed immediately after the train has passed"},
{"testId":628,"qid":28031,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Signs, Alaska Driver Handbook]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":628,"qid":28032,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"Before turning right at a red light, you must first stop and cede the right-of-way to pedestrians and cross traffic. [State of Alaska Driver Manual, Pedestrians, Sharing the Road]","imageName":"202002181109457082.jpg","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":628,"qid":28033,"description":"What does it mean if you see the following sign above your lane ?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. This white two-way left-turn arrow indicates that cars going in both directions may only turn left from this lane. Use this lane with care since incoming traffic may be utilizing it as well. This lane should never be used for driving or passing. [Lane Control Signals, Traffic Signals, Alaska Driver Handbook]","imageName":"202005121139121942.jpg","correctAnswer":39820,"isBookmarked":0,"aid":"15680#39820#39821#42516","text":"Merge left out of this lane as soon as it is safe#Vehicles traveling in both directions may turn left from this lane#There is an obstacle ahead; vehicles traveling in both directions must keep left#This lane is for left or right turns only"},
{"testId":628,"qid":28034,"description":"Which of the following statement about parking near a railroad crossing is correct?","explanation":"Parking is not permitted within 50 feet of the closest rail of a railroad crossing in Alaska. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [State of Alaska Driver Manual Parking], [13 AAC 02] .340]","imageName":"202002181114128699.jpg","correctAnswer":15590,"isBookmarked":0,"aid":"15590#42517#42518#42519","text":"You must not park within 50 feet of the tracks#You must not park within 100 feet of the tracks#You can park near a railroad crossing as long as you're not on the tracks#You can park anywhere before the stop line"},
{"testId":628,"qid":28035,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone. Slow down and continue with care as schoolchildren may be crossing the road ahead. Follow the instructions of the school crossing guards. In Alaska, the school zone speed restriction is 20 mph. (Please keep in mind that newer school zone signs have a neon yellow-green backdrop.) [School Sign, Signs, Alaska Driver Handbook]","imageName":"202003151742324658.jpg","correctAnswer":33890,"isBookmarked":0,"aid":"15594#15597#33890#36023","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a school zone ahead; slow down and proceed with caution#There is a crosswalk ahead; prepare to stop"},
{"testId":628,"qid":28036,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind areas wide enough to conceal a motorbike or perhaps another vehicle in the lane next to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Motorcyclists often have to avoid vehicles who fail to look over their shoulder before merging. [Controlled Access Multi-laned Driver Manual, State of Alaska]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#16513#16514#42520","text":"None of the above#Checking your mirrors#Looking over your shoulder#Using your hand signals or turn signals"},
{"testId":628,"qid":28037,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automated transmission, change into a low gear to avoid fading (losing effectiveness) of your brakes due to misuse. [Signs, Alaska Driver Handbook]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"15602#31340#31341#42521","text":"Only trucks can use the road ahead; find another route#There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":628,"qid":28038,"description":"What is the meaning of this sign?","explanation":"This sign may be seen at the end of various T-intersections. It involves slowing down, yielding the right-of-way, and turning right or left onto the through route. [Signs, Alaska Driver Handbook]","imageName":"202003301727587672.jpg","correctAnswer":41563,"isBookmarked":0,"aid":"22656#41563#42522#42523","text":"A divided highway starts ahead#You must yield and then turn right or left#You must bypass the obstacle ahead on either side#Passing is prohibited ahead"},
{"testId":628,"qid":28039,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Signaling, Alaska Driver Handbook]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":628,"qid":28040,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Alaska?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"7370#10879#10881#33894","text":"40 feet#Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":628,"qid":28041,"description":"You're on a four-lane highway. You approach a school bus that is stopped on the opposite side of the road for passengers. Do you have to come to a halt here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#42524#42525#42526","text":"No#No, but you must slow to 25 mph or less#Yes, you must stop and yield to any pedestrians on the road before you proceed#Yes, you must stop until the bus has switched off its red signals, retracted its stop arm, and started moving"},
{"testId":628,"qid":28042,"description":"You parked your vehicle on a hill without a curb. Which way should the front wheels face?","explanation":"If you're parking on a slope without a curb, aim your front wheels toward the road's edge. If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque. [State of Alaska Driver Manual, Parking]","imageName":"202005131347154851.jpg","correctAnswer":39492,"isBookmarked":0,"aid":"9906#25321#39491#39492","text":"Straight#In any direction#Away from the edge of the road#Toward the edge of the road"},
{"testId":628,"qid":28043,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Normally, you should turn left from the furthest lane.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#15618#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#One driver must wave the other driver through#The car in the busier lane has the right-of-way"},
{"testId":628,"qid":28044,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This motorist wants to make a left turn. [Signaling, Alaska Driver Handbook]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":628,"qid":28045,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#35047#39336#42527","text":"Proceed with caution#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down#Slow to 25 mph and proceed with caution"},
{"testId":629,"qid":28046,"description":"You come to a junction and see this sign. Which of the following should you do?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. When the junction is clean and there aren't any incoming cars that may pose a threat, you can continue. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Regulatory Signs, Traffic Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":629,"qid":28047,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Additional signs may be erected to govern minimum and evening speed restrictions. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Regulatory Signs, Traffic Signs]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":629,"qid":28048,"description":"You're about to cross a junction and want to turn left. There are no other vehicles visible. Is it still necessary to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"23565#29975#31404#43616","text":"No, turn signals are optional in Arizona#No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 100 feet before you turn#Yes, you must signal even if only briefly"},
{"testId":629,"qid":28049,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct according to the Arizona Driver License Manual?","explanation":"When two cars approach an uncontrolled junction at about the same moment, the vehicle on the left must yield to the one on the right. In addition, an uncontrolled junction should be considered as an all-way stop, according to the Arizona Driver License Manual. That implies that Car A and Car B must come to a complete stop, and then Car A must yield to Car B. [Uncontrolled Intersections, Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge]","imageName":"202007120521353376.jpg","correctAnswer":43618,"isBookmarked":0,"aid":"43617#43618#43619#43620","text":"Car B can proceed without stopping. Car A must stop and yield to Car B#Both cars must stop. Car A must yield to Car B#Both cars must stop. Car B must yield to Car A#Car A can proceed without stopping. Car B must stop and yield to Car A"},
{"testId":629,"qid":28050,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. As you approach a yield sign, slow down and prepare to yield to oncoming traffic. Be prepared to halt if necessary. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Regulatory Signs, Traffic Signs]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31032#31033#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic, and then proceed#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Maintain your speed because all traffic ahead must yield to you"},
{"testId":629,"qid":28051,"description":"You notice an emergency vehicle approaching with its lights blazing as you drive along the two-lane road. Which of the following should you do?","explanation":"If an emergency vehicle approaches you on a two-lane road, you must shift to the right side of the road and come to a complete stop. On a route with two or more lanes heading in your direction, move into a lane that will not impede the emergency vehicle and slow down to allow it to pass. [Emergency Vehicles, Section 3: Roadway and Vehicle Knowledge, Arizona Driver License Manual]","imageName":"202001302235285631.jpg","correctAnswer":35998,"isBookmarked":0,"aid":"15493#35998#38647#39803","text":"Slow down#Pull over, no matter which direction the emergency vehicle is traveling in#Maintain your speed and direction#Pull over if the emergency vehicle is traveling in the same direction as you are"},
{"testId":629,"qid":28052,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":629,"qid":28053,"description":"When do you have to dim your high beams in Arizona?","explanation":"You must lower your high lights if you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, according to Arizona law. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Headlight Use]","imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":629,"qid":28054,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":42810,"isBookmarked":0,"aid":"15504#15505#25287#42810","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed after you yield to any traffic already in the intersection"},
{"testId":629,"qid":28055,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#25355#43621","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a 鈥減rotected鈥 turn, so you will have the right-of-way#No. In Arizona, you cannot turn right at a red signal"},
{"testId":629,"qid":28056,"description":"Under Arizona law, which occupants of a vehicle must wear safety belts or suitable child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":43622,"isBookmarked":0,"aid":"4420#16365#26667#43622","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and passengers under 16 years of age"},
{"testId":629,"qid":28057,"description":"Which of the following is not TRUE about headlights?","explanation":"When you can't see clearly or other drivers can't see you, turn on your headlights. Arizona law requires you to use your headlights from dusk to dawn and anytime visibility is less than 500 feet. When driving in rain, fog, snow, or dust, utilize your low-beam headlights. (High lights may generate glare by reflecting off precipitation.) Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Arizona Driver License Manual, Section 5: Actively Avoiding Crashes, Headlight Use]","imageName":"202003161149128995.jpg","correctAnswer":42503,"isBookmarked":0,"aid":"23578#42503#43623#43624","text":"You must use your headlights from sunset to sunrise#You may use your parking lights when driving in bad weather#Dim your headlights when driving in rain, fog, snow, or dust#You should turn on your headlights whenever you think your vehicle may be difficult to see"},
{"testId":629,"qid":28058,"description":"What is the meaning of this sign?","explanation":"This sign instructs cars to stay to the right of an impending island or obstruction. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202003151752201841.jpg","correctAnswer":40261,"isBookmarked":0,"aid":"38076#40261#41842#43261","text":"You must turn right#You must keep right#You must not turn right#You must not turn left"},
{"testId":629,"qid":28059,"description":"When approaching a school crossing in Arizona, the speed limit is:","explanation":"When approaching a school crossing in Arizona, the speed restriction is 15 mph. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Speed Limits]","imageName":"202004281807142908.jpg","correctAnswer":22200,"isBookmarked":0,"aid":"2#4#9968#22200","text":"5 mph#10 mph#20 mph#15 mph"},
{"testId":629,"qid":28060,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"15646#25300#25302#40635","text":"Yield to your right and proceed with caution when safe#Treat the intersection like an all-way stop#Proceed as if you had a green signal#Assume you have the right-of-way if you're on the busiest road"},
{"testId":629,"qid":28061,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane or right from the right lane and into the next lane of traffic travelling in the proper direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":629,"qid":28062,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"23581#28524#28857#28858","text":"Yield to your right#Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead"},
{"testId":629,"qid":28063,"description":"Is it legal to turn left at a steady green light?","explanation":null,"imageName":"202001301833114961.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"25289#39458#41298#42508","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a \"Left Turn Allowed\" sign posted, and you must first yield to pedestrians and oncoming vehicles#No, you may turn left only at a green arrow signal"},
{"testId":629,"qid":28064,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct according to the Arizona Driver License Manual?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle turning left must yield to incoming traffic. In addition, an uncontrolled junction should be considered as an all-way stop, according to the Arizona Driver License Manual. That implies that Car A and Car B must come to a complete stop, and then Car A must yield to Car B. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Left Turn]","imageName":"202007120513316836.jpg","correctAnswer":43618,"isBookmarked":0,"aid":"43617#43618#43619#43620","text":"Car B can proceed without stopping. Car A must stop and yield to Car B#Both cars must stop. Car A must yield to Car B#Both cars must stop. Car B must yield to Car A#Car A can proceed without stopping. Car B must stop and yield to Car A"},
{"testId":629,"qid":28065,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Before entering the roundabout, slow down and ready to yield to traffic. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202002101237092503.jpg","correctAnswer":23583,"isBookmarked":0,"aid":"23583#31589#39441#42821","text":"A roundabout is ahead#You must make a U-turn#U-turns are allowed ahead#A two-way left-turn lane starts ahead"},
{"testId":629,"qid":28066,"description":"Three vehicles arrive at an uncontrolled T-junction (i.e., one without signs or signals). In what order should they go?","explanation":"A vehicle on a T-terminating intersection's road must yield to all vehicles on the through (main) route. Car A must yield to Cars B and C in this situation. Car C, on the other hand, must yield to oncoming traffic since it is turning left at a junction. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [Uncontrolled Intersections, Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#38877","text":"A, B, C#C, B, A#B, C, A#C, A, B"},
{"testId":629,"qid":28067,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":629,"qid":28068,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously when it is safe to do so. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Flashing Red Lights]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#40943","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed; you do not have to stop or yield at a flashing red signal"},
{"testId":629,"qid":28069,"description":"Which vehicles are concealed in the truck's blind areas (\"No-Zones\")?","explanation":"No-Zones are enormous blind zones on heavy vehicles and buses. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [Arizona Driver License Manual, Sharing the Road with a Truck, Section 4: Sharing the Road with Other Vehicles]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#15441","text":"Car A#Car B#Car C#Cars A and B"},
{"testId":629,"qid":28070,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Take it easy. Prepare to come to a complete stop if you see or hear a train coming. You must also come to a complete stop if urged to do so by a set of flashing red lights, a sign, or a lowered gate. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":629,"qid":28071,"description":"Which vehicles are not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Arizona Driver License Manual, Roundabouts, Entering/Crossing Traffic, Section 2: Safe Driving Practices]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":629,"qid":28072,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in Arizona. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [Arizona Driver License Manual, Section 2: Safe Driving Practices, Prohibited Parking]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#26729","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within ten minutes"},
{"testId":629,"qid":28073,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction, each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Pedestrians]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":629,"qid":28074,"description":"When is it permitted to turn left at a red light in Arizona?","explanation":"When going from one one-way street to another one-way street in Arizona, you may turn left on a red light unless there is a \"No Turn on Red\" sign displayed. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Left Turns]","imageName":"202007120412393828.jpg","correctAnswer":29959,"isBookmarked":0,"aid":"6334#16495#29959#43262","text":"Never#When you are turning onto a one-way street#When you are turning from a one-way street onto another one-way street#At any time unless otherwise signed"},
{"testId":629,"qid":28075,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#34502#43625","text":"The divided highway ends ahead#A divided highway starts ahead#Stay in your lane#A two-way left-turn lane is ahead"},
{"testId":629,"qid":28076,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Arizona Driver License Manual, Section 2: Safe Driving Practices, Parking on a Hill]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":629,"qid":28077,"description":"You must yield to _______ before turning left into a driveway.","explanation":"You must yield to approaching traffic before turning left. And, if you drive into or out of a driveway or alley, you must come to a complete stop before crossing the sidewalk and yield to pedestrians. As a result, you must yield to all pedestrians and incoming cars in this situation. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Alleys and Driveways]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":629,"qid":28078,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This motorist wants to make a right turn. [Arizona Driver License Manual, Section 2: Safe Driving Practices, Signaling, Entering/Crossing Traffic]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":629,"qid":28079,"description":"On an open road, you notice a school bus stopped ahead, its red lights flashing and its stop arm outstretched. What should you do?","explanation":"If you see a school bus stopped with its red lights flashing on an unrestricted route in Arizona, you must stop before you approach the bus. You must stay stopped until the bus starts motion or the red lights cease blinking and the stop arm retracts. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, School Buses and School Zones]","imageName":"202003051658529900.jpg","correctAnswer":38677,"isBookmarked":0,"aid":"34928#38677#40953#43626","text":"Stop if you are traveling in the same direction as the school bus#Stop no matter which direction you are traveling in#Slow to 10 mph and pass with caution#Stop and then pass at 20 mph or less after all children have left the road"},
{"testId":629,"qid":28080,"description":"Which of the following statement about railroad crossings is true?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":43627,"isBookmarked":0,"aid":"23029#23596#43627#43628","text":"All of the above are true#You can drive through a gate before it is completely raised#If you have to stop, you must do so at least 15 feet from the tracks#If there is no gate, you can cross the tracks as soon as the train has passed"},
{"testId":629,"qid":28081,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":629,"qid":28082,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all oncoming cars and pedestrians in crosswalks before turning at a red signal. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Pedestrians]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":629,"qid":28083,"description":"You wish to make a left turn at an intersection 100 feet away. Where should you begin signalling?","explanation":null,"imageName":"202002181158183785.jpg","correctAnswer":43630,"isBookmarked":0,"aid":"43629#43630#43631#43632","text":"(A) Where you are now#(B) In the middle of the first intersection#(C) Just after the first intersection#(D) Just before the turn"},
{"testId":629,"qid":28084,"description":"Which of the following statement about parking near a railroad crossing is correct?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":15590,"isBookmarked":0,"aid":"15590#15592#42517#42519","text":"You must not park within 50 feet of the tracks#You must be at least 50 feet away on the approaching side of the tracks and 15 feet away on the departing side of the tracks#You must not park within 100 feet of the tracks#You can park anywhere before the stop line"},
{"testId":629,"qid":28085,"description":"What is the meaning of this sign?","explanation":"This sign alerts drivers that a school zone is ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead. Keep an eye out for and respect any warning indications. Please keep in mind that older school zone signs have a yellow backdrop. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Speed Limits]","imageName":"202003151740461521.jpg","correctAnswer":23549,"isBookmarked":0,"aid":"15594#23549#23603#23604","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a school zone ahead#There is a crosswalk ahead#There is a park or playground ahead"},
{"testId":629,"qid":28086,"description":"What steps must you take before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. Your car, on the other hand, has blind spots wide enough to conceal another vehicle in the lane next to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you may fail to see the car in front of you until it is too late. Motorcyclists often have to avoid vehicles who fail to look over their shoulder before merging. [Arizona Driver License Manual, Section 2: Safe Driving Practices, Cushion of Space Around Your Vehicle]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":629,"qid":28087,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if you have an automatic gearbox, change into a low gear to decrease brake wear. [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Warning Signs]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"15602#31340#31341#42521","text":"Only trucks can use the road ahead; find another route#There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":629,"qid":28088,"description":"The curbs on this road are painted in sections. Which of the following statements is correct?","explanation":"Colored curb markings may be used in addition to or instead of regulation signs to limit parking. A yellow curb is equivalent to a No Parking sign. You may only stop at a yellow curb to load or unload persons or luggage. You may only stop near a white curb to load or unload persons, not freight. You are not permitted to stop, stand, or park near to a red curb. A red curb usually denotes a fire lane. (Please keep in mind that there is no national standard for curb colors.) Each state has the option of determining how to employ curb colors. Always verify the local traffic rules while going out of state.) [Painted Curbs, Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge]","imageName":"202005121043081400.jpg","correctAnswer":43633,"isBookmarked":0,"aid":"43633#43634#43635#43636","text":"You may load passengers at a white curb and cargo at a yellow curb#You may park at the white curb but not at the red curb#You may load or unload cargo at the white curb#The white curb is for metered parking, and the yellow curb is for loading cargo"},
{"testId":629,"qid":28089,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Arizona Driver License Manual, Section 2: Safe Driving Practices, Signaling, Entering/Crossing Traffic]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":629,"qid":28090,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Arizona?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":43637,"isBookmarked":0,"aid":"7370#33894#39489#43637","text":"40 feet#One car length for every 10 mph of speed#Two to four seconds#Three to six seconds"},
{"testId":629,"qid":28091,"description":"You're on a divided highway with a large central median. On the opposite side of the highway, you notice a school bus stopped with its red lights blinking. Are you required to come to a halt here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":26739,"isBookmarked":0,"aid":"26739#43638#43639#43640","text":"No, but you should proceed with caution#No, but you must slow to 15 mph and proceed with caution#Yes, you must stop and yield to all pedestrians on the road before you proceed#Yes, you must stop until the bus has either started moving or has switched off its red lights and retracted its stop arm"},
{"testId":629,"qid":28092,"description":"You've parked on the side of the road, facing downhill. There is no curb. Which way should your front wheels be pointed?","explanation":"If you're parking on a slope without a curb, aim your front wheels toward the road's edge. If your brakes fail, your automobile will roll toward the road's edge rather than into traffic. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. Shift into Park if you have an automatic gearbox. When heading downhill, change into reverse, then when facing upwards, move into first gear for maximum forward torque. [Arizona Driver License Manual, Section 2: Safe Driving Practices, Parking on a Hill]","imageName":"202002181226366215.jpg","correctAnswer":39492,"isBookmarked":0,"aid":"9906#25321#39491#39492","text":"Straight#In any direction#Away from the edge of the road#Toward the edge of the road"},
{"testId":629,"qid":28093,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct according to the Arizona Driver License Manual?","explanation":"To cross railroad tracks, it is usually safer to ride straight inside your lane. Turning to cross the tracks head-on (at a 90-degree angle) is riskier since your route may take you into another lane of traffic. If there are tracks, ruts, or road seams parallel to your path, move far enough away from them to cross them at an angle of at least 45 degrees.","imageName":"202007120530048918.jpg","correctAnswer":43618,"isBookmarked":0,"aid":"43617#43618#43619#43620","text":"Car B can proceed without stopping. Car A must stop and yield to Car B#Both cars must stop. Car A must yield to Car B#Both cars must stop. Car B must yield to Car A#Car A can proceed without stopping. Car B must stop and yield to Car A"},
{"testId":629,"qid":28094,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your vehicle's traditional lit turn and stop signals. This motorist wants to make a left turn. [Arizona Driver License Manual, Section 2: Safe Driving Practices, Signaling, Entering/Crossing Traffic]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":629,"qid":28095,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":"When you approach any vehicle (emergency vehicle, tow truck, etc.) that has pulled over with its flashing lights on, you must move into a non-adjacent lane whenever feasible, leaving at least one unoccupied lane between you and the vehicle, according to Arizona's Move Over Law. If this is difficult or dangerous (no alternative lanes in your direction), you must slow down until you have past the car. (Note: Although every state in the United States currently has its own Move Over Law, some of them compel drivers to perform various things. Always verify the local traffic rules while going out of state.) [Arizona Driver License Manual, Section 3: Roadway and Vehicle Knowledge, Emergency Vehicles]","imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#35047#39336#43438","text":"Proceed with caution#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down#Reduce your speed by 20 mph and proceed with caution"},
{"testId":632,"qid":28096,"description":"You come to an intersection and see this sign. Which of the following should you do?","explanation":"You must come to a full stop and yield to any oncoming vehicles or pedestrians. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#15474#15475","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here"},
{"testId":632,"qid":28097,"description":"What is the meaning of this sign?","explanation":"This sign informs drivers that the maximum speed restriction is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. Additional signs may be erected to govern minimum and evening speed restrictions. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":632,"qid":28098,"description":"You want to make a left turn as you approach a junction. There are no other vehicles visible in the area. Are your turn signals necessary?","explanation":"You must signal for at least 100 feet in urban or metropolitan regions. On a four-lane highway with a speed restriction more than 40 mph, you must signal for at least 200 feet. [10 Rules of the Road, 10.4 Turning, 10. Signaling, Colorado Driver Handbook]","imageName":"202001291433312120.jpg","correctAnswer":25278,"isBookmarked":0,"aid":"15480#25276#25277#25278","text":"No, turn signals are only required when there are other vehicles around#No, turn signals are optional in Colorado#Yes, you must always signal for 100 feet#Yes, in urban or metropolitan areas, you must signal for 100 feet, and on four-lane highways where the speed limit exceeds 40 mph, you must signal for 200 feet"},
{"testId":632,"qid":28099,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at a junction at the same moment, the vehicle on the left must yield to the one on the right. [10 Rules of the Road, 10.2 Right of Way, 10. Uncontrolled Intersections, Colorado Driver Handbook]","imageName":"202007120521353376.jpg","correctAnswer":25279,"isBookmarked":0,"aid":"15618#25279#25280#25281","text":"One driver must wave the other driver through#Car A must yield to the right#Car B must yield to the left#Any turning vehicle must yield"},
{"testId":632,"qid":28100,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"Slow down and ready to yield to oncoming pedestrians and cars. Be prepared to halt if necessary. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25282#25283","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Slow down and prepare to yield to traffic already on the road ahead#Maintain your speed since all traffic ahead must yield to you"},
{"testId":632,"qid":28101,"description":"You notice an emergency vehicle approaching with its lights blazing as you drive along the two-lane road. Which of the following should you do?","explanation":"When you see an emergency vehicle approaching with its lights flashing, pull over and stop to let it pass. [10 Rules of the Road, 10.2 Right of Way, 10. Emergency Vehicles, Colorado Driver Handbook]","imageName":"202001302235285631.jpg","correctAnswer":25285,"isBookmarked":0,"aid":"15493#23570#25284#25285","text":"Slow down#Maintain speed and direction; changing lanes or stopping is unpredictable and dangerous#Speed up until you can find a suitable place to pull over#Pull over and stop as soon as possible"},
{"testId":632,"qid":28102,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising vehicles not to exceed 35 mph. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":632,"qid":28103,"description":"You are driving with your high-beam headlights on at night. A vehicle is seen ahead. When must high beams be dimmed?","explanation":"When you are within 500 feet of an approaching vehicle, you must decrease your high lights. When you are fewer than 200 feet behind another vehicle, you must also reduce your high lights. [11.10 Night Driving, 11. Driving Safety Tips, Colorado Driver Handbook]","imageName":"202002211503506612.jpg","correctAnswer":15500,"isBookmarked":0,"aid":"15500#15501#15502#25286","text":"When you are 500 feet from an approaching vehicle or 200 feet behind another vehicle#When you are 200 feet from an approaching vehicle or 500 feet behind another vehicle#When you are 500 feet away from another vehicle#When you are 1000 feet away from another vehicle"},
{"testId":632,"qid":28104,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"On a green light, you may continue. You must, however, yield to anybody who is already in the junction. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":632,"qid":28105,"description":"Is it legal to turn right at a red light?","explanation":"You may turn right on a red light if you come to a full stop and yield to any pedestrians or cars. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003112014364009.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#15510#25289","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right at a red light#Yes, this is a \"protected\" turn, so you will have the right-of-way"},
{"testId":632,"qid":28106,"description":"Which of the following about safety belts is most accurate?","explanation":"Any front seat passenger, as well as every passenger under the age of 16, must wear an appropriate safety belt, booster seat, or child restraint. You should ensure that every passenger in your vehicle is wearing a seat belt or a kid restraint. [7.3 Colorado's Safety Belt Laws (C.R.S. 42-4-237 and 42-2-105.5), Colorado Driver Handbook]","imageName":"202003151755527296.jpg","correctAnswer":25291,"isBookmarked":0,"aid":"23513#25290#25291#25292","text":"Only the driver must wear a safety belt#The driver and every front seat passenger must wear their safety belts#The driver, every front seat passenger, and every passenger under 16 must wear a suitable restraint#Safety belts are optional in Colorado"},
{"testId":632,"qid":28107,"description":"Which of the following about work zones is NOT true?","explanation":"Even if there are no employees present, you must follow work zone signs. Work zones may see heightened police presence, and any fines issued there may be doubled. The majority of vehicle accidents in work zones are rear-end crashes, so drivers must be alert to traffic and avoid becoming distracted by the work being done. [11.14 Construction Zones, 11. Driving Safety Tips, Colorado Driver Handbook]","imageName":"202002101222518967.jpg","correctAnswer":25294,"isBookmarked":0,"aid":"25293#25294#25295#25296","text":"Any fine you receive in a work zone may be doubled#You don鈥檛 need to slow down for a work zone if there are no workers present#Work zones can have increased police activity#You should be mindful of other traffic; rear-end collisions are the most common type of vehicle accident in work zones"},
{"testId":632,"qid":28108,"description":"What is the meaning of this sign?","explanation":"This sign directs motorists to remain to the right of an impending island or obstruction. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":632,"qid":28109,"description":"Which of the following is true about driving when it's raining?","explanation":"In rainy conditions, you are legally compelled to slow down. You should turn off your cruise control since it increases the likelihood and severity of sliding and hydroplaning. If you hydroplane, let off of the pedal and avoid braking or turning; let the vehicle slow down on its own. [11.11 Inclement Weather, 11. Safe Driving Tips, Colorado Driver Handbook]","imageName":"202002061923072507.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#25297#25298#25299","text":"All of the above are true#You should not use your cruise control on wet roads#If you hydroplane, release the accelerator and avoid braking or turning if possible#You should slow down to avoid hydroplaning and loss of traction"},
{"testId":632,"qid":28110,"description":"You arrive at an intersection only to discover that the traffic signals are entirely dark. Which of the following should you do?","explanation":"An junction with an unlit set of traffic lights should be treated as an all-way stop. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#25301#25302#25303","text":"Treat the intersection like an all-way stop#Yield to your right and proceed with caution when it is safe#Proceed as if you had a green signal#Vehicles should proceed through the intersection in the order in which they arrived"},
{"testId":632,"qid":28111,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless otherwise stated, you should always turn left from the left lane and right from the right lane. You must always turn into the nearest lane and never change lanes in the middle of a turn. Car B has taken the furthest lane here. Car A has into the nearest lane, which is correct. [Turning in the Right Direction, 10.4 Turning, 10. Rules of the Road, Colorado Driver Handbook]","imageName":"202002181106054384.jpg","correctAnswer":15523,"isBookmarked":0,"aid":"15521#15522#15523#15524","text":"Both cars are turning correctly#Neither car is turning correctly#Car A is turning correctly#Car B is turning correctly"},
{"testId":632,"qid":28112,"description":"What should you do when you see a solid yellow traffic light?","explanation":"The signal will quickly become red. If you haven't already, you should come to a complete halt. Slow down cautiously and keep an eye out for any cars behind you. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003051752141237.jpg","correctAnswer":25304,"isBookmarked":0,"aid":"15527#23581#25304#25305","text":"Drive with caution as there may be a hazard ahead#Yield to your right#Stop unless you are already in the intersection#Speed up to get through the intersection before the signal turns red"},
{"testId":632,"qid":28113,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must yield to all cars and pedestrians approaching the junction, since they have the right-of-way. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202001301833114961.jpg","correctAnswer":15532,"isBookmarked":0,"aid":"15529#15532#25306#25307","text":"No, you need a green arrow to turn left#Yes, you can turn left, but you must yield to pedestrians and oncoming traffic first#Yes, but only if there is a LEFT TURN PERMITTED sign posted#Yes, you can turn left because this is a \"protected\" turn; no other cars are allowed to enter the intersection"},
{"testId":632,"qid":28114,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Turning Left, 10.2 Right of Way, 10.3 Speed Limit] Colorado Driver Handbook, Rules of the Road]","imageName":"202007120513316836.jpg","correctAnswer":25310,"isBookmarked":0,"aid":"25308#25309#25310#25311","text":"This is an uncontrolled intersection; one driver must wave the other one through#Car B must yield to all vehicles turning across its path#Car A must yield to all pedestrians and oncoming vehicles#The car on the busier road has the right-of-way"},
{"testId":632,"qid":28115,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. Prepare to yield to any existing traffic in the roundabout. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15652#15653#15654#25312","text":"U-turns are permitted at the intersection ahead#A central turning lane starts ahead#There is a roundabout ahead#There is a traffic island ahead; pass on the right only"},
{"testId":632,"qid":28116,"description":"Drivers can get directions from a wide range of resources. Which one of the following should be obeyed the most?","explanation":"First and foremost, you must heed a traffic officer's orders, even if it means breaking another traffic regulation. [10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#14884#14886#15196","text":"A stop sign#A red traffic signal#A traffic officer#A prohibitory sign"},
{"testId":632,"qid":28117,"description":"Car B has entered an intersection to make a right turn on a red light. Car A then approaches a green light. Which of the following statements is true? :","explanation":"When the light turns green, you may continue, but only after yielding to all cars and pedestrians already at the junction. As a result, Car A should yield to Car B here. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":632,"qid":28118,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light should be treated as a stop sign. That is, you must come to a full stop before moving and then yield to traffic and pedestrians. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003051722347868.jpg","correctAnswer":25315,"isBookmarked":0,"aid":"23452#23589#25315#25316","text":"Drive with caution because the traffic light is broken#Stop; you cannot proceed until the signal stops flashing#Stop and yield, as you would at a stop sign#Proceed; you do not need to stop or yield at a flashing red signal"},
{"testId":632,"qid":28119,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. Cars moving immediately behind or behind a truck are often entirely obscured to the driver. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. Remember, if you can't see the truck driver, he or she can't see you either. [12.1 Large Trucks and Buses, 12. Sharing the Road]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":632,"qid":28120,"description":"What is the meaning of this sign?","explanation":"This sign warns of a railroad crossing ahead. Keep an eye out for warning lights and oncoming trains. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":632,"qid":28121,"description":"Three vehicles are sequentially approaching at an intersection. Which sequence should these vehicles follow?","explanation":"Cars that arrive at a four-way stop one after the other go in the sequence in which they came. Car B may go first, followed by Car A and Car C. [4-Way Stop, 10.2 Right of Way, 10, Colorado Driver Handbook]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16255#16256#16258#23455","text":"A, B, C#C, B, A#B, A, C#A, C, B"},
{"testId":632,"qid":28122,"description":"Is the vehicle legally parked when it is parked near the fire hydrant?","explanation":"You are not permitted to park within 15 feet of a fire hydrant. This provides ample space for the fire department to approach the hydrant if necessary. [10.5 Parking, 10. Road Rules, Colorado Driver Handbook]","imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":632,"qid":28123,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a crosswalk, you must always yield to pedestrians. [10 Rules of the Road, Pedestrians, 10.2 Right of Way, Colorado Driver Handbook]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":632,"qid":28125,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. The opposing lanes will be separated by a median. Keep to the right of the median. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202002101247507801.jpg","correctAnswer":15563,"isBookmarked":0,"aid":"15560#15561#15562#15563","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#A central shared turning lane is ahead#A divided highway is ahead"},
{"testId":632,"qid":28126,"description":"You've parked against the curb, uphill. What direction should your front wheels be pointed?","explanation":"Point your front wheels away from the curb while parking uphill. If your automobile begins to roll, the front wheels will roll backward onto the curb, bringing the vehicle to a halt. [10.5 Parking, 10. Road Rules, Colorado Driver Handbook]","imageName":"202005121029148797.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":632,"qid":28127,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"You must yield to all pedestrians and approaching cars while turning left. [10 Rules of the Road, Pedestrians, 10.2 Right of Way, Colorado Driver Handbook]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25322#25323#25324","text":"No one#Oncoming vehicles#Pedestrians#Pedestrians and oncoming vehicles"},
{"testId":632,"qid":28128,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of LED turn and stop signals on your vehicle. This motorist wants to make a right turn. [10 Rules of the Road, 10.4 Turning, 10. Signaling, Colorado Driver Handbook]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":632,"qid":28129,"description":"A school bus with flashing red lights stops ahead of you. What should you do?","explanation":"Before approaching any school bus with flashing red lights, you must come to a full stop. This is true for automobiles moving in both directions. You must remain at least 20 feet away from the bus until the flashing lights are turned off. [Colorado Driver Handbook, 10.2 Right of Way, 10. Rules of the Road]","imageName":"202003051658529900.jpg","correctAnswer":25326,"isBookmarked":0,"aid":"15611#15612#15613#25326","text":"You must slow down to 10 mph and pass with caution#You must come to a complete stop and pass at 20 mph or less when there are no children on the road#If you are traveling in the same direction as the bus, you must come to a complete stop#You must come to a complete stop regardless of which direction you are traveling in"},
{"testId":632,"qid":28130,"description":"A lane is designated with double broken yellow lines on each side. What kind of lane is this?","explanation":"This is a lane that can be used both ways. During the day, the direction of traffic in this lane may vary. To advise drivers of the current traffic direction, the lane should be properly designated. [10.1c Pavement Markings, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202004281848169040.jpg","correctAnswer":25329,"isBookmarked":0,"aid":"25327#25328#25329#25330","text":"This is a central turning lane, used for left turns only#This is a High Occupancy Vehicle (HOV) lane, used for vehicles with multiple passengers#This is a reversible lane, in which the direction of traffic may change during the day#This is an emergency stopping lane for broken-down vehicles"},
{"testId":632,"qid":28131,"description":"What is the meaning of this sign?","explanation":"This sign alerts cars that a crossroads is coming. Drivers should reduce their speed and prepare for the junction. [Colorado Driver Handbook, 12.2 Railroad Crossing, 12. Sharing the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":632,"qid":28132,"description":"You are turning on a red light at an intersection. Simultaneously, a pedestrian has begun to cross the street. What are your options?","explanation":"Before proceeding through a red light, you must yield to all pedestrians and traffic. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#15679","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to pass the pedestrian"},
{"testId":632,"qid":28133,"description":"You've parked your car in a downhill position. If you have a manual transmission, which gear should you leave it in?","explanation":"When parking facing downhill, if your automobile is automatic, leave it in park; if it is manual, leave it in reverse. This reduces the possibility of your automobile sliding downhill. If you park a manual automobile facing uphill, keep it in first gear. [10.5 Parking, 10. Road Rules, Colorado Driver Handbook]","imageName":"202002071736477410.jpg","correctAnswer":15805,"isBookmarked":0,"aid":"15803#15804#15805#15806","text":"First gear#Top gear#Reverse#Neutral"},
{"testId":632,"qid":28134,"description":"Which of the following statement about parking near a railroad crossing is correct?","explanation":"At a railroad crossing, you cannot park within 30 feet of the rails. [10.5 Parking, 10. Road Rules, Colorado Driver Handbook]","imageName":"202002181114128699.jpg","correctAnswer":25331,"isBookmarked":0,"aid":"23548#23602#25331#25332","text":"You can park anywhere behind the stop line#You must not park within 65 feet of the tracks#You must not park within 30 feet of the tracks#You must be at least 30 feet away on the approaching side of the tracks, and at least 15 feet away on the departing side of the tracks"},
{"testId":632,"qid":28135,"description":"What is the meaning of this sign?","explanation":"This sign alerts drivers that a school crossing is ahead. Keep an eye out for and yield to any people crossing. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003151740461521.jpg","correctAnswer":25333,"isBookmarked":0,"aid":"15594#23603#23604#25333","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a crosswalk ahead#There is a park or playground ahead#There is a school crossing ahead"},
{"testId":632,"qid":28136,"description":"Which of the following steps is not required when changing lanes?","explanation":"To ensure the road is free, you must signal, check your mirrors, and physically glance over your shoulder while changing lanes. A motorcycle rider often has to avoid a car who neglected to check over his or her shoulder before merging. Your vehicle's blind area is big enough to conceal another vehicle in the adjacent lane. The road may be clear in your mirror, but the road may not be. [11.7 Changing Lanes, 11. Driving Safety Tips, Colorado Driver Handbook]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#25334","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check that the road is clear"},
{"testId":632,"qid":28137,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, and use a low gear to prevent brake wear. [10.1b Traffic Signs, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202002101212538783.jpg","correctAnswer":25336,"isBookmarked":0,"aid":"15602#15689#25335#25336","text":"Only trucks can use the road ahead; find another route#There is a runaway truck ramp ahead#Use caution; trucks will be entering from a steep side road or driveway#Steep descent; drive carefully"},
{"testId":632,"qid":28138,"description":"On each side, you see a lane with solid and fractured yellow lines. Which of the following statements about this lane is correct?","explanation":"This lane is reserved for left turns. This lane must be used for all left turns from the road. Traffic heading in either direction may utilize this lane. Cars entering the road may use this lane to come to a halt and wait for an opportunity to join into traffic. This lane, however, is not to be utilized for acceleration. [10.1c Pavement Markings, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202001301806403967.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#25337#25338#25339","text":"All of the above are true#You can pull into this lane when entering the road#You can only turn left using this lane#This lane is accessible to traffic moving in both directions"},
{"testId":632,"qid":28139,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your car's LED turn and stop signals. This motorist plans to slow down or come to a complete stop. [10 Rules of the Road, 10.4 Turning, 10. Signaling, Colorado Driver Handbook]","imageName":"202002181152514351.jpg","correctAnswer":15572,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":632,"qid":28140,"description":"You are following the vehicle in front of you, but you might be too close. Which of the following about tailgating is true?","explanation":"Maintain a three-second following distance behind the vehicle in front of you. When the road conditions are excellent, the minimum following distance is three seconds. Increase your following distance when circumstances are unfavorable (due to weather, traffic, visibility, etc.). [11.3, 11. Safe Driving Tips, Colorado Driver Handbook]","imageName":"202001302249003541.jpg","correctAnswer":25340,"isBookmarked":0,"aid":"15608#15610#15693#25340","text":"You should remain 40 feet behind at all times#You should remain at least six seconds behind#You should follow close enough to make polite conversation with the driver ahead#You should remain at least three seconds behind"},
{"testId":632,"qid":28141,"description":"You're on a divided highway with a large central median. On the opposite side of the highway, you notice a school bus stopped with its red lights blinking. Are you required to come to a halt here?","explanation":"You cannot normally pass a school bus that has stopped for passengers. When you are on the other side of a split roadway, you do not need to stop. It is important to note that a center turning lane or a painted median are insufficient; the roadway must be physically separated. [10 Rules of the Road, 10.2 Right of Way, 10. Emergency Vehicles, Colorado Driver Handbook]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#15698#15699#23611","text":"No, you do not need to stop here#Yes, you must briefly stop and yield to any pedestrians on the road#Yes, you must stop until the bus has switched off its red signals, retracted its STOP arm, and started moving#No, but you must slow down to 25 mph or less and proceed with care"},
{"testId":632,"qid":28142,"description":"You parked on a steep incline with no curb. In the event that your brakes fail, which way should your front wheels be pointed?","explanation":"When parking on a slope without a curb, keep your eyes on the road. If your vehicle rolls, it will roll off the road and away from traffic. [10.5 Parking, 10. Road Rules, Colorado Driver Handbook]","imageName":"202002181226366215.jpg","correctAnswer":25342,"isBookmarked":0,"aid":"9906#25341#25342#25343","text":"Straight#Toward the road#Away from the road#Any direction"},
{"testId":632,"qid":28143,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Turning Left, 10.2 Right of Way, 10.3 Speed Limit] Colorado Driver Handbook, Rules of the Road]","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#25344","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way here"},
{"testId":632,"qid":28144,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"Hand signals may be used in place of your car's LED turn and stop signals. This motorist wants to make a left turn. [10 Rules of the Road, 10.4 Turning, 10. Signaling, Colorado Driver Handbook]","imageName":"202002181155395841.jpg","correctAnswer":14579,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":632,"qid":28145,"description":"You are driving along a section of the road. You see an emergency vehicle with flashing lights pulled over in front of you. Which of the following is true?","explanation":"Allow at least one lane between your vehicle and a halted emergency vehicle with flashing lights. Otherwise, reduce your speed to a reasonable level and continue with care. [10 Rules of the Road, 10.2 Right of Way, 10. Emergency Vehicles, Colorado Driver Handbook]","imageName":"202001241703386758.jpg","correctAnswer":25347,"isBookmarked":0,"aid":"15621#25345#25346#25347","text":"You must reduce your speed by 25 mph and proceed with caution#You can proceed as usual, but drive with care#You must leave at least one lane between your car and the stopped emergency vehicle#You must leave at least one lane between your car and the stopped emergency vehicle if possible; otherwise, slow down to a safe speed"},
{"testId":633,"qid":28146,"description":"You come to a junction and see this sign. Which of the following should you do?","explanation":"You must completely stop before the stop line, crosswalk, or junction at a stop sign, depending on where you reach first. Then stop and give way to incoming cars and pedestrians. [State of Connecticut Driver's Manual, Chapter 5: Know the Road, Regulatory Signs, Traffic Signs]","imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":633,"qid":28147,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction here is 50 mph in optimum driving circumstances, according to this sign. Slow down below this maximum speed limit in difficult driving conditions. [State of Connecticut Driver's Manual, Chapter 4: Road Safety, Speed Limits]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":633,"qid":28148,"description":"You are about to turn at a junction where you cannot see any other vehicles. Is it still necessary to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":43946,"isBookmarked":0,"aid":"25348#29975#43946#43947","text":"No, turn signals are optional in Connecticut#No, you are only required to signal when other vehicles are present#Yes, you should try to signal for at least three seconds before you turn#Yes, before you turn, you must signal for 100 feet at less than 40 mph or for 200 feet at 40 mph or more"},
{"testId":633,"qid":28149,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#15618#25311","text":"Car A must yield to its right#Car B must yield to its left#One driver must wave the other driver through#The car on the busier road has the right-of-way"},
{"testId":633,"qid":28150,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [State of Connecticut Driver's Manual, Chapter 5: Know the Road, Regulatory Signs, Traffic Signs]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"31726#35007#35008#35013","text":"Maintain your speed because all traffic ahead must yield to you#Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection"},
{"testId":633,"qid":28151,"description":"You see an emergency vehicle arriving on this two-lane road with its flashing lights on. What ought you to do?","explanation":"When an emergency vehicle with flashing lights or a siren approaches you from any direction, you must pull over to the right and come to a complete stop. Continue to stand still until the emergency vehicle has past. [State of Connecticut Driver's Manual, Emergency Vehicles, Sharing the Road with Others, Chapter 3: Driving Behaviors]","imageName":"202001302235285631.jpg","correctAnswer":43949,"isBookmarked":0,"aid":"15493#38647#43948#43949","text":"Slow down#Maintain your speed and direction#Pull over to the right and stop if the emergency vehicle is traveling in your direction#Pull over to the right and stop, no matter which direction the emergency vehicle is traveling in"},
{"testId":633,"qid":28152,"description":"What is the meaning of this sign?","explanation":"This is an advisory sign advising cars not to exceed 35 mph in this area. These indicators are often placed with other warning signs. [State of Connecticut Driver's Manual, Chapter 5: Know the Road, Traffic Signs]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":633,"qid":28153,"description":"Which kind of sign is this?","explanation":"Pentagonal school zone and school crossing signs (five sided). Slow down and ready to yield to pedestrians if you see one of these signs. [Shapes, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#30402#40762","text":"A school zone sign#A work zone sign#A No Passing Zone sign#A pedestrian crosswalk sign"},
{"testId":633,"qid":28154,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202003070857473536.jpg","correctAnswer":42810,"isBookmarked":0,"aid":"15504#15505#25287#42810","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed after you yield to any traffic already in the intersection"},
{"testId":633,"qid":28155,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Connecticut unless there are signs banning it. However, before turning, you must come to a full stop and yield to pedestrians and cars. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#39805#43950","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you'll have the right-of-way#No. In Connecticut, you cannot turn right at a red signal"},
{"testId":633,"qid":28156,"description":"Which occupants of a vehicle are required by Connecticut law to use seat belts or proper child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":43951,"isBookmarked":0,"aid":"4420#16365#26667#43951","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and all passengers aged 16 and under"},
{"testId":633,"qid":28157,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#17019#43952","text":"Car A#Car B#Whichever car is on the busier road#Neither (one driver must wave the other driver through)"},
{"testId":633,"qid":28158,"description":"What is the meaning of this sign?","explanation":"This sign instructs motorists to remain to the right of an impending traffic island or obstruction. [State of Connecticut Driver's Manual, Chapter 5: Know the Road, Regulatory Signs, Traffic Signs]","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"15563#19246#38076#43261","text":"A divided highway is ahead#Keep right#You must turn right#You must not turn left"},
{"testId":633,"qid":28159,"description":"Which of the following assertions regarding driving in the rain is correct?","explanation":"In Connecticut, you are compelled by law to slow down in rainy conditions. (At greater speeds, skidding or hydroplaning is more probable.) You must also use your headlights anytime you use your windshield wipers in severe weather. Finally, avoid using cruise control on wet, ice, or snow-covered roadways. If your car loses traction on a slick surface, the cruise control may spin the drive wheels to keep the vehicle moving. This may exacerbate or even cause a skid. [State of Connecticut Driver's Manual, Chapter 3: Driving Behaviors, Water on the Roadway]","imageName":"202002061923072507.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"25365#28163#31434#43953","text":"You should not use cruise control on wet roads#All of the above statements are true#You must use your headlights whenever you need to use your windshield wipers#You should slow down to avoid hydroplaning or skidding"},
{"testId":633,"qid":28160,"description":"Which kind of sign is this?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is placed on the left side of the road and bears the words \"NO PASSING ZONE\" in black characters on a yellow backdrop. [Shapes, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202003151738521917.jpg","correctAnswer":30402,"isBookmarked":0,"aid":"15824#30402#35271#43954","text":"A work zone sign#A No Passing Zone sign#A Keep Right sign#A Low Clearance sign"},
{"testId":633,"qid":28161,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless signs or road markings indicate otherwise, you should cross as few lanes as possible while turning. That is, turn from the nearest lane into the nearest lane. Car B has appropriately entered the nearest lane. Car A has made an erroneous turn into the furthest lane. [Turning, State of Connecticut Driver's Manual, Chapter 3: Driving Behaviors]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":633,"qid":28162,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":633,"qid":28163,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. They have right of way. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"37700#39455#39458#39805","text":"Yes, but only if there is a Left Turns Allowed sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":633,"qid":28164,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"When two vehicles approach at a junction about the same time, the vehicle on the left must yield to the one on the right. In the absence of this regulation, the motorist turning left must yield to approaching traffic. Car A must yield to Car B in this situation. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15618#25309#25311","text":"Car A must yield because it is turning left#One driver must wave the other driver through#Car B must yield to all vehicles turning across its path#The car on the busier road has the right-of-way"},
{"testId":633,"qid":28165,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Before entering the roundabout, slow down and ready to yield to traffic. [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#39441#39442#39443","text":"There is a roundabout ahead#U-turns are allowed ahead#Wrong way; turn around here#Only left turns are allowed ahead"},
{"testId":633,"qid":28166,"description":"Which of the following should be adhered to above all others?","explanation":"You must always obey a law enforcement officer's instructions, even if it means violating other traffic devices or laws. For example, if a police officer waves you through a red light or a stop sign, you should proceed. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202002211500121915.jpg","correctAnswer":15666,"isBookmarked":0,"aid":"2316#14884#15666#16371","text":"A stop sign#A red traffic signal#A law enforcement officer#A regulatory sign"},
{"testId":633,"qid":28167,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":633,"qid":28168,"description":"What must you do when a red traffic light is flashing?","explanation":"Consider a flashing red light to be a stop sign. That is, before approaching the junction, you must come to a full stop, yield to vehicles and pedestrians, and then continue with care when it is safe to do so. [Flashing Lights, Traffic Signals, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#29330#39464#40943","text":"Stop and yield as you would at a stop sign#Proceed with caution; the traffic signal is broken#Stop and remain stopped until the red light stops flashing#Proceed; you do not have to stop or yield at a flashing red signal"},
{"testId":633,"qid":28169,"description":"Which vehicles are most likely to be concealed in the truck's blind areas (No-Zones)?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [No-Zone, Truck Sharing, Chapter 3: Driving Behaviors, State of Connecticut Driver's Manual]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#15441","text":"Car A#Car B#Car C#Cars A and B"},
{"testId":633,"qid":28170,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":633,"qid":28171,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [State of Connecticut Driver's Manual, Right-of-Way, Chapter 4: Road Safety]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16255#16256#16258#23455","text":"A, B, C#C, B, A#B, A, C#A, C, B"},
{"testId":633,"qid":28172,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":"Parking within 10 feet of a fire hydrant is illegal in Connecticut. (There are several more sites where you are not permitted to park.) Check for signs prohibiting or limiting parking.) [State of Connecticut Driver's Manual, Chapter 4: Road Safety, No-Parking Zones]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":633,"qid":28173,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a marked or unmarked crossing, a vehicle must yield to pedestrians. Furthermore, in an uncontrolled junction (one without signs or signals), each vehicle must yield to the car to its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":633,"qid":28174,"description":"Is it legal in Connecticut to turn left at a persistent red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":633,"qid":28175,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#15667#22656#41158","text":"The divided highway ends ahead#There is a central shared turning lane ahead#A divided highway starts ahead#There is a chicane ahead"},
{"testId":633,"qid":28176,"description":"Which of the following car is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. Unless otherwise indicated by signage or pavement markings, you should turn left from the left lane, right from the right lane, and go straight through using either lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts/Rotaries/Circular Intersections, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":633,"qid":28177,"description":"You must yield to _________ before turning left into a driveway.","explanation":"You must yield to oncoming traffic before turning left into an alley or driveway. In addition, anytime you enter or exit a driveway or alley, you must yield to pedestrians on the sidewalk. [Right-of-Way, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":633,"qid":28178,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Turn Signals, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":633,"qid":28179,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34196#34928#43427#43428","text":"Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 10 mph and proceed with caution#Come to a complete stop and then pass at 20 mph or less once all children have left the road"},
{"testId":633,"qid":28180,"description":"On each side of this lane, there is a double dashed yellow line. What kind of lane is this?","explanation":"This is a lane that can be used both ways. At times, traffic moves in one way and then in the other direction. Keep an eye out for overhead or roadside signs or signals indicating the current permitted direction of traffic in this lane. [Reversible Lanes, Pavement Markings, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202004281848169040.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#23498#28541#31430","text":"A reversible lane#An emergency stopping lane#A high-occupancy vehicle (HOV) lane#A shared center left-turn lane"},
{"testId":633,"qid":28181,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":633,"qid":28182,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. Furthermore, at a crossing, you must always yield to a pedestrian. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15586#15679#35038","text":"Stop and let the pedestrian cross#Use your horn to alert the pedestrian to your presence#Turn into the far lane to pass the pedestrian#Speed up to pass the pedestrian"},
{"testId":633,"qid":28183,"description":"Which of the following statements concerning Connecticut's speed limits is correct?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#43137#43955#43956","text":"All of the above are true#Depending on driving conditions, you can be cited for driving at the maximum speed limit#If several vehicles are following you, you should pull over to let them pass as soon as it is safe to do so#If you can't keep up with the lawful flow of traffic, you should find another route"},
{"testId":633,"qid":28185,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign warns of an impending school zone or crossing. Slow down and continue with care as schoolchildren may be crossing the road ahead. Follow the instructions of the school crossing guards. (Please keep in mind that older school zone and school crossing signs have a yellow backdrop.) [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202003151740461521.jpg","correctAnswer":33890,"isBookmarked":0,"aid":"15594#15597#33890#36023","text":"There is a shared section of road ahead; slow down and watch out for pedestrians#There is a park or playground ahead; slow down and watch for pedestrians#There is a school zone ahead; slow down and proceed with caution#There is a crosswalk ahead; prepare to stop"},
{"testId":633,"qid":28186,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. Your car, on the other hand, has blind spots wide enough to conceal another vehicle in the lane next to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you may fail to see the car in front of you until it is too late. Motorcyclists often have to avoid vehicles who fail to look over their shoulder before merging. [Chapter 3: Driving Behaviors, State of Connecticut Driver's Manual, Changing Lanes]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":633,"qid":28187,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automated transmission, change into a low gear to avoid fading (losing effectiveness) of your brakes due to misuse. [Warning Signs, Traffic Signs, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"15602#31340#31341#42521","text":"Only trucks can use the road ahead; find another route#There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":633,"qid":28188,"description":"The center lane of this road is divided from the other lanes by solid and fractured yellow lines. Which of the following statements about this lane is true?","explanation":"This is a two-way left-turn lane with a shared center lane. This lane is for cars moving in either direction making a left turn (and U-turns where permitted). Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing. During rush hour, certain shared center lanes become reversible lanes, so keep an eye out for any applicable signs or signals. [Shared Center Lane, Pavement Markings, State of Connecticut Driver's Manual, Chapter 5: Know the Road]","imageName":"202001301806403967.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#25339#43434#43959","text":"All of the above are true#This lane is accessible to traffic moving in both directions#The flow of traffic may reverse in this lane; watch for relevant signs or signals#This lane is only for left turns and possibly U-turns"},
{"testId":633,"qid":28189,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Turn Signals, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":633,"qid":28190,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Connecticut?","explanation":"Under normal driving circumstances, the \"three-second rule\" is recommended by the Connecticut Driver's Manual. That is, maintain a three-second following distance behind the car in front of you. Increase your following distance to at least four seconds when driving conditions are bad or when you may require additional room to stop. [State of Connecticut Driver's Manual, Chapter 3: Driving Behaviors, Space Ahead, Maintaining a Space Cushion]","imageName":"202001302249003541.jpg","correctAnswer":10880,"isBookmarked":0,"aid":"7370#10880#19117#33894","text":"40 feet#Three seconds#Six seconds#One car length for every 10 mph of speed"},
{"testId":633,"qid":28191,"description":"You're on a four-lane highway. You approach a school bus that is stopped on the opposite side of the road for passengers. Do you have to come to a halt here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#33563#43436#43960","text":"No#No, but you must slow down to 20 mph or less and proceed with caution#Yes, you must briefly stop and yield to any pedestrians on the road before you proceed#Yes, you must stop until the flashing red lights have been turned off and all children have left the roadway"},
{"testId":633,"qid":28192,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [State of Connecticut Driver's Manual, Traffic Lights and Signals, Traffic Signals, Chapter 5: Know the Road]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#25392#25395#37531","text":"Slow down and proceed with caution#Stop if it is safe; the light will soon turn red#Stop if it is safe; the light will soon flash red#Yield as you would at a yield sign"},
{"testId":633,"qid":28193,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"To notify that a train is coming or passing, several signals (flashing lights, bells, or gates) are utilized. You must never disregard these indications and cross anyhow.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#15618#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#One driver must wave the other driver through#The car in the busier lane has the right-of-way"},
{"testId":633,"qid":28194,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a left turn. [Turn Signals, State of Connecticut Driver's Manual, Chapter 4: Road Safety]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":633,"qid":28195,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"28148#35047#39336#43438","text":"Proceed with caution#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down#Reduce your speed by 20 mph and proceed with caution"},
{"testId":634,"qid":28196,"description":"You come to a junction and see this sign. Which of the following should you do?","explanation":null,"imageName":"202001301744345845.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#15475#30391#39086","text":"Slow down and only proceed if the intersection is clear#Find another route; you cannot proceed through here#Come to a complete stop and yield to traffic before proceeding#Come to a complete stop and then proceed"},
{"testId":634,"qid":28197,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15479#23395","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The minimum speed limit is 50 mph#The speed limit during the day is 50 mph"},
{"testId":634,"qid":28198,"description":"You are about to turn at a junction where you cannot see any other vehicles. Is it still necessary to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":43421,"isBookmarked":0,"aid":"26744#29975#31404#43421","text":"No, turn signals are optional in Delaware#No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 100 feet before you turn#Yes, you must signal for at least 300 feet before you turn"},
{"testId":634,"qid":28199,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#15618#25311","text":"Car A must yield to its right#Car B must yield to its left#One driver must wave the other driver through#The car on the busier road has the right-of-way"},
{"testId":634,"qid":28200,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"31726#35007#35008#35013","text":"Maintain your speed because all traffic ahead must yield to you#Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection"},
{"testId":634,"qid":28201,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":23572,"isBookmarked":0,"aid":"15493#23572#26747#38647","text":"Slow down#Pull over to the right and stop#Speed up to find somewhere safe to pull over#Maintain your speed and direction"},
{"testId":634,"qid":28202,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":634,"qid":28203,"description":"Which kind of sign is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#30402#40762","text":"A school zone sign#A work zone sign#A No Passing Zone sign#A pedestrian crosswalk sign"},
{"testId":634,"qid":28204,"description":"You come to a crossroad where the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":42810,"isBookmarked":0,"aid":"15504#15505#25287#42810","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed after you yield to any traffic already in the intersection"},
{"testId":634,"qid":28205,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#39805#43422","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you'll have the right-of-way#No. In Delaware, you cannot turn right at a red signal"},
{"testId":634,"qid":28206,"description":"Which occupants of a vehicle are required by Delaware law to wear safety belts or proper child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#43423","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and all passengers under 18 years of age"},
{"testId":634,"qid":28207,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202002181211246194.jpg","correctAnswer":39461,"isBookmarked":0,"aid":"15353#39460#39461#39462","text":"Car A, because it is turning left#Car A, because it is to the left of Car B#Car B, because it is to the right of Car A#Whichever car that the drivers decide should go first"},
{"testId":634,"qid":28208,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"15563#19246#38076#41842","text":"A divided highway is ahead#Keep right#You must turn right#You must not turn right"},
{"testId":634,"qid":28209,"description":"You've parked with your back to a steep hill. If there is a curb, where should you turn your wheels?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":634,"qid":28210,"description":"You come to an intersection with a flashing red left arrow. Can you make a left here?","explanation":null,"imageName":"1369048185_t1q15.png","correctAnswer":39096,"isBookmarked":0,"aid":"26759#26760#39096#43424","text":"Yes, but only if you are on a one-way street#No, you must wait for a green arrow#Yes, but first you must stop and then yield#Yes, this is a \"protected\" turn; oncoming and crossing vehicles are stopped"},
{"testId":634,"qid":28211,"description":"How close to a train crossing can you park?","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":26812,"isBookmarked":0,"aid":"26811#26812#43425#43426","text":"No closer than 20 feet from the tracks#No closer than 50 feet from the tracks#As close as you want as long as you're not on the tracks#No closer than five feet from the stop line, crossing gate, or signal"},
{"testId":634,"qid":28212,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#36007","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield to traffic, and proceed when it is safe to do so"},
{"testId":634,"qid":28213,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"36001#39455#39458#39805","text":"Yes, but only if there is a Left Turn Permitted sign present#No, you may only turn left on a green arrow#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":634,"qid":28214,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15618#25311#39459","text":"Car A must yield because it is turning left#One driver must wave the other driver through#The car on the busier road has the right-of-way#Car B must yield because it is going straight"},
{"testId":634,"qid":28215,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#39441#39442#39443","text":"There is a roundabout ahead#U-turns are allowed ahead#Wrong way; turn around here#Only left turns are allowed ahead"},
{"testId":634,"qid":28216,"description":"Which of the following should be adhered to above all others?","explanation":null,"imageName":"202002211500121915.jpg","correctAnswer":16212,"isBookmarked":0,"aid":"2316#14884#15196#16212","text":"A stop sign#A red traffic signal#A prohibitory sign#A police officer"},
{"testId":634,"qid":28217,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":634,"qid":28218,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#37531#39464#39465","text":"Stop and yield as you would at a stop sign#Yield as you would at a yield sign#Stop and remain stopped until the red light stops flashing#Proceed with caution; this signal is broken"},
{"testId":634,"qid":28219,"description":"Which vehicles are most likely to be found in the truck's blind spots?","explanation":null,"imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#15441","text":"Car A#Car B#Car C#Cars A and B"},
{"testId":634,"qid":28220,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":634,"qid":28221,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16255#16256#16258#23455","text":"A, B, C#C, B, A#B, A, C#A, C, B"},
{"testId":634,"qid":28222,"description":"Is the vehicle legally parked when it is parked infront of fire hydrant?","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":15557,"isBookmarked":0,"aid":"15557#15558#21234#23533","text":"No, you cannot park within 15 feet of a fire hydrant#No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes"},
{"testId":634,"qid":28223,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":null,"imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":634,"qid":28225,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#41158#41461","text":"The divided highway ends ahead#A divided highway starts ahead#There is a chicane ahead#A reversible lane starts ahead"},
{"testId":634,"qid":28226,"description":"Which of the following car is not following the rules of the roundabout?","explanation":null,"imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":634,"qid":28227,"description":"You must yield to _________ before turning left into a driveway.","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"33884#33885#39470#39471","text":"pedestrians and oncoming vehicles#neither pedestrians nor oncoming vehicles#pedestrians but not oncoming vehicles#oncoming vehicles but not pedestrians"},
{"testId":634,"qid":28228,"description":"This driver is signaling with his hand and arm. The driver plans to:","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":634,"qid":28229,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"34196#34928#43427#43428","text":"Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 10 mph and proceed with caution#Come to a complete stop and then pass at 20 mph or less once all children have left the road"},
{"testId":634,"qid":28230,"description":"On each side of this lane, there is a double broken yellow line. What kind of lane is this?","explanation":null,"imageName":"202004281848169040.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#23498#28541#31430","text":"A reversible lane#An emergency stopping lane#A high-occupancy vehicle (HOV) lane#A shared center left-turn lane"},
{"testId":634,"qid":28231,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#23471","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a helipad ahead"},
{"testId":634,"qid":28232,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15586#15679#35038","text":"Stop and let the pedestrian cross#Use your horn to alert the pedestrian to your presence#Turn into the far lane to pass the pedestrian#Speed up to pass the pedestrian"},
{"testId":634,"qid":28233,"description":"When are you obligated by Delaware law to dim your high-beam headlights?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":634,"qid":28234,"description":"Which of the following claims is FALSE in Delaware?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":43429,"isBookmarked":0,"aid":"30758#39452#43429#43430","text":"You must use your headlights whenever you use your windshield wipers in bad weather#You must use your headlights whenever visibility is poor at 1,000 feet#You may use your parking lights when driving in dim light#You should use low-beam headlights when driving in fog, rain, or snow"},
{"testId":634,"qid":28235,"description":"You reach a traffic light in Delaware and find that it is entirely dark. What should you do here?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":43432,"isBookmarked":0,"aid":"23581#43431#43432#43433","text":"Yield to your right#Come to a complete stop, yield, and proceed when it is safe to do so#Slow down and yield to vehicles that are in or approaching the intersection#Slow down and yield to other vehicles unless you are on the busiest road"},
{"testId":634,"qid":28236,"description":"Which of the following must you perform before changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":634,"qid":28237,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"15602#31340#31341#42521","text":"Only trucks can use the road ahead; find another route#There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep driveway or side road ahead"},
{"testId":634,"qid":28239,"description":"This driver is signaling with his hand and arm. The driver plans to:","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":634,"qid":28240,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Delaware?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":10880,"isBookmarked":0,"aid":"7370#10880#19117#33894","text":"40 feet#Three seconds#Six seconds#One car length for every 10 mph of speed"},
{"testId":634,"qid":28241,"description":"You're on a four-lane highway. You approach a school bus that is stopped on the opposite side of the road for passengers. Do you have to come to a halt here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#43435#43436#43437","text":"No#No, but you must slow down to less than 20 mph and proceed with caution#Yes, you must briefly stop and yield to any pedestrians on the road before you proceed#Yes, you must stop until the flashing red lights have been turned off and the stop arm has been retracted"},
{"testId":634,"qid":28242,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26784#26787#37531","text":"Slow down and proceed with caution#Stop if safe; the light will soon turn red#Stop if safe; the light will soon flash red#Yield as you would at a yield sign"},
{"testId":634,"qid":28243,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#15618#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#One driver must wave the other driver through#The car in the busier lane has the right-of-way"},
{"testId":634,"qid":28244,"description":"This driver is signaling with his hand and arm. The driver plans to:","explanation":null,"imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":634,"qid":28245,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#35047#39336#43438","text":"Proceed with caution#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down#Reduce your speed by 20 mph and proceed with caution"},
{"testId":635,"qid":28246,"description":"You come to an intersection and see this sign. Which of the following should you do?","explanation":"You must come to an immediate halt. After yielding to all cross traffic and pedestrians, you may continue. [District of Columbia Driving Manual, Octagon: Stop, Sign Shapes, Signals, Signs and Markings]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15473#15474#15475#26790","text":"Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here#Slow down and then proceed when the intersection is clear"},
{"testId":635,"qid":28247,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You must not exceed 50 mph in this area. [District of Columbia Driving Manual, Speed Limit Signs, Speed Signs]","imageName":"202003151749335643.jpg","correctAnswer":26791,"isBookmarked":0,"aid":"15476#15478#15479#26791","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal maximum speed limit is 50 mph"},
{"testId":635,"qid":28248,"description":"Is it necessary to activate your turn signals if there are no other vehicles in the area?","explanation":"Before turning a corner or changing lanes, you must signal for at least 100 feet or 300 feet. At faster speeds, you should signal even farther ahead. Even if you can't see any other vehicles, you must always use your turn signals. The most hazardous driver may be the one you don't notice. [Signaling, Driving Rules, District of Columbia Driving Manual]","imageName":"202001291433312120.jpg","correctAnswer":26795,"isBookmarked":0,"aid":"26792#26793#26794#26795","text":"No, because there is no one to signal to#No, turn signals are always optional in the District of Columbia#Yes, you should signal for at least three seconds#Yes, you must signal for 100 feet before turning or 300 feet before changing lanes"},
{"testId":635,"qid":28250,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"Slow down and ready to yield to oncoming pedestrians and cars. Be prepared to halt if necessary. [District of Columbia Driving Manual, Triangle: Yield, Sign Shapes, Signals, Signs and Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#26797","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to traffic already in the intersection"},
{"testId":635,"qid":28251,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"You must pull over to the side of the road and stop for any emergency vehicle with flashing lights. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202001302235285631.jpg","correctAnswer":26799,"isBookmarked":0,"aid":"15493#26747#26798#26799","text":"Slow down#Speed up to find somewhere safe to pull over#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the curb and stop"},
{"testId":635,"qid":28252,"description":"What is the meaning of this sign?","explanation":"This cautionary sign suggests that you do not exceed 35 mph on this stretch of road. While this is not enforced, if you exceed this speed and are involved in an accident, you may be charged for driving at a dangerous pace. [Advisory (Recommended) Speed Signs, Traffic Rules, District of Columbia Driving Manual]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15498#15499#26800","text":"The recommended speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph#The legal maximum speed limit is 35 mph"},
{"testId":635,"qid":28253,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless otherwise stated, you should always turn left from the left lane and right from the right lane. You must always turn into the nearest lane and never change lanes in the middle of a turn. Car B has taken the furthest lane here. Car A has into the nearest lane, which is correct. Turns, Driving Rules, and the District of Columbia Driver's Manual","imageName":"202002181106054384.jpg","correctAnswer":15523,"isBookmarked":0,"aid":"15521#15522#15523#15524","text":"Both cars are turning correctly#Neither car is turning correctly#Car A is turning correctly#Car B is turning correctly"},
{"testId":638,"qid":28253,"description":"Two cars are turning onto a split highway. Which car is turning properly?","explanation":"Unless otherwise stated, you should always turn left from the left lane and right from the right lane. You must always turn into the nearest lane and never change lanes in the middle of a turn. Car B has taken the furthest lane here. Car A has into the nearest lane, which is correct. Turns, Driving Rules, and the District of Columbia Driver's Manual","imageName":"202002181106054384.jpg","correctAnswer":15523,"isBookmarked":0,"aid":"15521#15522#15523#15524","text":"Both cars are turning correctly#Neither car is turning correctly#Car A is turning correctly#Car B is turning correctly"},
{"testId":635,"qid":28254,"description":"You get to a crossroads and see that the light is green. You want to go through the intersection straight on. Which of the following is true?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [District of Columbia Driving Manual, Signals, Signs, and Markings, Consistent Green Signal]","imageName":"202003070857473536.jpg","correctAnswer":25288,"isBookmarked":0,"aid":"15504#15505#25287#25288","text":"You are free to proceed#You cannot proceed#You must briefly stop and yield, and then you can proceed#You are free to proceed, but you must first yield to any traffic already in the intersection"},
{"testId":635,"qid":28255,"description":"Is it legal to turn right at a red light?","explanation":"On a red light, you are permitted to turn right. You must, however, come to a full stop before yielding to all pedestrians and vehicles. [District of Columbia Driving Manual, Signals, Signs, and Markings, Constant Red Signal]","imageName":"202003112014364009.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26801#26802","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right on a red signal#Yes, this is a \"protected\" turn, so you have the right-of-way"},
{"testId":635,"qid":28256,"description":"Who is required to wear a safety belt?","explanation":"You cannot drive a vehicle in the District of Columbia unless you and everyone else in it are wearing seat belts. Not only is not wearing a seat belt hazardous, but you may also be pulled over, penalized, and have points added to your driving record for failing to buckle up. [District of Columbia Driving Manual, Seat Belt Law/Click It or Ticket]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"4420#15900#16007#26803","text":"Only the driver#Only the driver, every front seat passenger, and every passenger under 16#The driver and every passenger#No one (seat belts are optional in the District of Columbia)"},
{"testId":635,"qid":28257,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles come to an all-way stop at the same moment, the vehicle on the left must yield to the vehicle on the right. Automobile B is the car on the right in this example. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26804#26805","text":"Car A#Car B#Whichever car goes first#The car on the busier road"},
{"testId":635,"qid":28258,"description":"What is the meaning of this sign?","explanation":"This regulation sign instructs cars to keep to the right of an impending island or obstruction. [District of Columbia Driving Manual, Signals, Signs, and Markings, Keep to the Right]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":635,"qid":28259,"description":"When parking facing uphill against the curb, which direction should the front wheels point?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [District of Columbia Driving Manual, Parking Rules, Parking on a Hill]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":635,"qid":28260,"description":"What is the meaning of this sign?","explanation":"The speed limit for the bend ahead is 35 mph. [District of Columbia Driving Manual, Advisory Speed, Signals, Signs, and Markings]","imageName":"202002061303124131.jpg","correctAnswer":26807,"isBookmarked":0,"aid":"26806#26807#26808#26809","text":"There is a 35-degree curve ahead#The recommended speed limit for this curve is 35 mph#The maximum speed limit for this curve is 35 mph#There is a right turn in 35 yards"},
{"testId":635,"qid":28261,"description":"How close can you park to a railroad crossing?","explanation":"At a railroad crossing, you cannot park within 50 feet of the rails. [Prohibited Stopping, Standing, and Parking, Parking Rules, District of Columbia Driving Manual]","imageName":"202002181114128699.jpg","correctAnswer":26812,"isBookmarked":0,"aid":"16204#26810#26811#26812","text":"As close as you want#No closer than 5 feet from the tracks, gate, stop line, or signal#No closer than 20 feet from the tracks#No closer than 50 feet from the tracks"},
{"testId":635,"qid":28262,"description":"What should you do when you see a solid yellow traffic light?","explanation":"The signal will quickly become red. If you haven't already, you should come to a complete halt. Slow down cautiously and keep an eye out for any cars behind you. [District of Columbia Driving Manual, Signals, Signs, and Markings, Constant Yellow Signal]","imageName":"202003051752141237.jpg","correctAnswer":25304,"isBookmarked":0,"aid":"23581#25304#25305#26813","text":"Yield to your right#Stop unless you are already in the intersection#Speed up to get through the intersection before the signal turns red#Proceed with caution as there may be a hazard ahead"},
{"testId":635,"qid":28263,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must yield to any cars and pedestrians already at the junction since they have the right-of-way. [District of Columbia Driving Manual, Signals, Signs, and Markings, Consistent Green Signal]","imageName":"202003070857473536.jpg","correctAnswer":15532,"isBookmarked":0,"aid":"15529#15532#25307#26814","text":"No, you need a green arrow to turn left#Yes, you can turn left, but you must yield to pedestrians and oncoming traffic first#Yes, you can turn left because this is a \"protected\" turn; no other cars are allowed to enter the intersection#Yes, but only if there is a \"Left Turn Permitted\" sign posted"},
{"testId":635,"qid":28264,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202007120513316836.jpg","correctAnswer":25310,"isBookmarked":0,"aid":"25308#25309#25310#25311","text":"This is an uncontrolled intersection; one driver must wave the other one through#Car B must yield to all vehicles turning across its path#Car A must yield to all pedestrians and oncoming vehicles#The car on the busier road has the right-of-way"},
{"testId":635,"qid":28265,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"A pennant-shaped sign informs that no passing is permitted in this region. [District of Columbia Driving Manual, No Passing Pennant, Signals, Signs, and Markings]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":635,"qid":28266,"description":"Drivers can get directions from a wide range of resources. Which one of the following should be obeyed the most?","explanation":"You must follow a traffic officer's directions, even if it means disregarding other traffic devices or laws. [Knowledge Exam, Driver License Exam Procedure, District of Columbia Driving Manual]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#14884#14886#15196","text":"A stop sign#A red traffic signal#A traffic officer#A prohibitory sign"},
{"testId":635,"qid":28267,"description":"Car B has entered an intersection to make a right turn on a red light. Car A then approaches a green light. Which of the following statements is true? :","explanation":"When the light turns green, you may continue, but only after yielding to all cars and pedestrians already at the junction. As a result, Car A should yield to Car B here. [District of Columbia Driving Manual, Signals, Signs, and Markings, Consistent Green Signal]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":635,"qid":28268,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light should be treated as a stop sign. That is, you must come to a full stop before moving and then yield to traffic and pedestrians. [District of Columbia Driving Manual, Flashing Red Signal, Signals, Signs, and Markings]","imageName":"202003051722347868.jpg","correctAnswer":25315,"isBookmarked":0,"aid":"23589#25315#25316#26815","text":"Stop; you cannot proceed until the signal stops flashing#Stop and yield, as you would at a stop sign#Proceed; you do not need to stop or yield at a flashing red signal#Proceed with caution because the traffic signal is broken"},
{"testId":635,"qid":28269,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. Cars moving immediately behind or behind a truck are often entirely obscured to the driver. You may be driving right next to a truck and not see it. Remember that if you can't see the driver's face in the driver's side mirror, the driver can't see you either. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. [Driver Information, District of Columbia Driving Manual] [Do Not Hand Out in No-Zone/Side No Zones, Driver Information]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":635,"qid":28270,"description":"What is the meaning of this sign?","explanation":"This sign alerts vehicles to the presence of a railroad crossing ahead. Keep an eye out for warning lights and oncoming trains. [Round: Railroad Warning, Sign Shapes, Signals, Signs and Markings, Driving Manual of the District of Columbia]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15548#15550#23456#26816","text":"There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back#There is a crossroads ahead"},
{"testId":635,"qid":28271,"description":"Three vehicles are sequentially approaching at an intersection. Which sequence should these vehicles follow?","explanation":"Cars that come one after the other at a four-way stop go forward in that sequence. Here, Car B may go in front of Car A, Car B, and Car C. [Driver Information, Right-of-Way, District of Columbia Driving Manual]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16255#16256#16258#23455","text":"A, B, C#C, B, A#B, A, C#A, C, B"},
{"testId":635,"qid":28272,"description":"Is the vehicle legally parked when it is parked near the fire hydrant?","explanation":"Parking is not permitted within 10 feet of a fire hydrant. This might prohibit the fire crew from reaching the hydrant in the case of an emergency. [Prohibited Stopping, Standing, and Parking, Stopping and Parking Rules, District of Columbia Driving Manual]","imageName":"202001290916213788.jpg","correctAnswer":25378,"isBookmarked":0,"aid":"15558#21234#23533#25378","text":"No, you cannot park within 30 feet of a fire hydrant#Yes#Yes, as long as it is moved within 10 minutes#No, you cannot park within 10 feet of a fire hydrant"},
{"testId":635,"qid":28273,"description":"Which of the following about speed limits in the District of Columbia is true?","explanation":"You must not go faster than the stated speed limit. Even if you do not exceed the stated speed limit, you may be fined for failing to slow down in bad traffic, bad weather, or bad road conditions. You must also keep up with other traffic to avoid creating a problem. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#26817#26818#26819","text":"All of the above are true#You cannot exceed the posted speed limit#You can receive a ticket for speeding, even at speeds below the posted speed limit#You should try to keep up with the lawful flow of traffic"},
{"testId":635,"qid":28274,"description":"Is it permissible to turn left at a red signal?","explanation":"In many places, turning left on red from a one-way street into another one-way street is legal. But not in the District of Columbia. [District of Columbia Driving Manual, Signals, Signs, and Markings, Constant Red Signal]","imageName":"202003112014364009.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#25318#26820#26821","text":"No#Yes, unless it is prohibited by a sign#Yes, but only from a one-way street onto another one-way street#Yes, but only when you are turning onto a one-way street from any other type of street"},
{"testId":635,"qid":28275,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. The opposing lanes will be separated by a median. Keep to the right of the median.","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15561#15654#15667#15668","text":"Stay in your current lane; do not merge left or right#There is a roundabout ahead#There is a central shared turning lane ahead#There is a divided highway ahead"},
{"testId":635,"qid":28276,"description":"Are any of these cars navigating the roundabout incorrectly?","explanation":"Unless otherwise indicated, you should turn right from the right lane, left from the left lane, and straight using either lane. Car C is turning left from the right lane. [District of Columbia Driving Manual, Multiple Lane Roundabout, Roundabouts, Driver Information]","imageName":"202001290908474628.jpg","correctAnswer":26824,"isBookmarked":0,"aid":"18118#26822#26823#26824","text":"No#Yes, Car A#Yes, Car B#Yes, Car C"},
{"testId":635,"qid":28277,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"You must yield to all pedestrians and approaching cars while turning left. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25322#25323#25324","text":"No one#Oncoming vehicles#Pedestrians#Pedestrians and oncoming vehicles"},
{"testId":635,"qid":28278,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [District of Columbia Driving Manual, Signals, Signs, and Markings, Turn Signals and Emergency Signals]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":635,"qid":28279,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"You must stop at least 20 feet from a halted school bus with flashing red lights, regardless of whether you are heading in the same direction as the school bus. This is true for automobiles moving in both directions. (The only exception is if the school bus is on the other side of a split roadway; you may continue.) After the bus has turned off its red lights, continue with care. [School Vehicle Stopping, Driver Information, District of Columbia Driving Manual]","imageName":"202003051658529900.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#26825#26826#26827","text":"No#Yes, but you must first slow down to 15 mph and yield to pedestrians#Yes, but you must first stop and yield to any pedestrians#Yes, but not if you are traveling in the same direction as the school bus"},
{"testId":635,"qid":28280,"description":"What is the meaning of this sign?","explanation":"This school crossing sign is pentagonal (five-sided). Proceed with caution and give way to pedestrians. [From the District of Columbia Driving Manual, the 5-Sided Sign is used to warn of schools and school crossings, Signals, Signs, and Markings]","imageName":"202003151740461521.jpg","correctAnswer":25333,"isBookmarked":0,"aid":"25333#26828#26829#26830","text":"There is a school crossing ahead#There is a pedestrian crossing ahead#There is an unprotected area of sidewalk ahead#No vehicles are allowed ahead"},
{"testId":635,"qid":28281,"description":"What is the meaning of this sign?","explanation":"This sign alerts cars that a crossroads is coming. Drivers should reduce their speed and prepare for the junction. [District of Columbia Driving Manual, Crossroads, Signals, Signs, and Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":635,"qid":28282,"description":"You are turning right on a red light at an intersection. Simultaneously, a pedestrian has begun to cross the road you are about to enter. What are your options?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a crosswalk, you should always yield to a pedestrian. [District of Columbia Driving Manual, Signals, Signs, and Markings, Constant Red Signal]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15586#15679","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Use your horn to alert the pedestrian to your presence#Turn into the far lane to pass the pedestrian"},
{"testId":635,"qid":28283,"description":"When is it necessary to dim your high beams?","explanation":"When you are within 500 feet of any approaching cars or within 300 feet of any vehicles you are following, turn your headlights to low brightness. It is worth noting that if your car and an incoming vehicle are both moving at 60 mph, the two vehicles will cover a 500-foot gap in under three seconds. That gives the other motorist very little time to adjust to being dazzled by your bright lights. [Driving Rules, Using Your Headlights, District of Columbia Driving Manual]","imageName":"202002211503506612.jpg","correctAnswer":26832,"isBookmarked":0,"aid":"26831#26832#26833#26834","text":"When you are 300 feet from an oncoming car or 500 feet behind another car#When you are 500 feet from an oncoming car or 300 feet behind another car#When you are 300 feet from any car#When you are 500 feet from any car"},
{"testId":635,"qid":28285,"description":"You come to a broken traffic light, where none of the lights are on. What do you need to do?","explanation":"If the traffic lights are out, consider the junction as an all-way stop. You must come to a complete stop, yield, and only continue when it is safe to do so. [District of Columbia Driving Manual, Traffic Signals, Signals, Signs, and Markings]","imageName":"202003051805205845.jpg","correctAnswer":26838,"isBookmarked":0,"aid":"23581#26780#26838#26839","text":"Yield to your right#Slow down and yield to any cars approaching or already in the intersection#Treat the broken signal like a stop sign: Stop, yield, and proceed when it is safe to do so#Slow down and yield unless you are on the busiest road at the intersection"},
{"testId":635,"qid":28286,"description":"Which of the following steps is not required when changing lanes?","explanation":"To change lanes, you must first signal, check your mirrors, and glance behind you. Your vehicle has blind spots big enough to totally obscure another vehicle in the lane into which you want to shift. Looking over your shoulder is the only method to check for blind spots. [Interstate Highway Lane Change and Passing, Driver Information, District of Columbia Driving Manual]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#25334","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check that the road is clear"},
{"testId":635,"qid":28287,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, and use a low gear to prevent brake wear. [District of Columbia Driving Manual, Signals, Signs, and Markings, Steep Hill Ahead]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"15602#25335#26840#26841","text":"Only trucks can use the road ahead; find another route#Use caution; trucks will be entering from a steep side road or driveway#There is a truck escape ramp ahead#There is a steep descent ahead; proceed with caution"},
{"testId":635,"qid":28288,"description":"What is the speed limit in a school zone in the District of Columbia?","explanation":"The speed restriction in a school zone in the District of Columbia is 15 mph. [District of Columbia Driving Manual, Speed Restrictions, Traffic Rules]","imageName":"202004071515004176.jpg","correctAnswer":520,"isBookmarked":0,"aid":"520#522#523#2429","text":"15 mph#25 mph#20 mph#10 mph"},
{"testId":635,"qid":28289,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [District of Columbia Driving Manual, Signals, Signs, and Markings, Turn Signals and Emergency Signals]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":635,"qid":28290,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"Maintain a four-second following gap between your car and the vehicle in front of you under favorable circumstances. When the weather is bad, you should increase your following distance. [Driver Information, District of Columbia Driving Manual, Following Distance]","imageName":"202001302249003541.jpg","correctAnswer":15927,"isBookmarked":0,"aid":"15926#15927#19915#26842","text":"At least two seconds#At least four seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":635,"qid":28291,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"You cannot normally pass a school bus that has stopped for passengers. When you are on the other side of a split roadway, you do not need to stop. A road with a center turning lane does not qualify as a divided highway. [School Vehicle Stopping, Driver Information, District of Columbia Driving Manual]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#15698#26843#26844","text":"No, you do not need to stop here#Yes, you must briefly stop and yield to any pedestrians on the road#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must stop until the school bus has switched off its red lights and started moving"},
{"testId":635,"qid":28292,"description":"What does a yellow flashing traffic light indicate?","explanation":"Through a yellow flashing signal, you should slow down and continue with care. [District of Columbia Driving Manual, Flashing Yellow Signal, Signals, Signs, and Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26845#26846#26847","text":"Slow down and proceed with caution#The signal will soon turn red; stop if it is safe to do so#Yield; treat this signal like a yield sign#You cannot turn here; go straight"},
{"testId":635,"qid":28293,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202007120530048918.jpg","correctAnswer":26849,"isBookmarked":0,"aid":"25311#26848#26849#26850","text":"The car on the busier road has the right-of-way#This is an uncontrolled intersection, one driver must wave the other driver through#Car A must yield since it is turning left#Car B must yield since it is turning right"},
{"testId":635,"qid":28294,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a left turn. [District of Columbia Driving Manual, Signals, Signs, and Markings, Turn Signals and Emergency Signals]","imageName":"202002181155395841.jpg","correctAnswer":6365,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":638,"qid":28296,"description":"You come to an intersection and see this sign. Which of the following should you do?","explanation":"You must come to an immediate halt. You may continue after yielding to any oncoming cars and pedestrians. [Regulatory Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#15474#15475","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here"},
{"testId":638,"qid":28297,"description":"What is the meaning of this sign?","explanation":"The enforced maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Regulatory Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":638,"qid":28298,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Even if there are no other cars in the area, you must signal for at least 100 feet before turning. At faster speeds, you should signal even farther ahead. Even if you can't see any other vehicles, you must always use your turn signals. The most hazardous driver may be the one you don't notice. [Required Signals, Official Hawaii Driver's Manual, Chapter V: Traffic Laws & Regulations]","imageName":"202001291433312120.jpg","correctAnswer":28516,"isBookmarked":0,"aid":"28513#28514#28515#28516","text":"No, you are only required to signal when there are other vehicles present#No, turn signals are optional in Hawaii#Yes, you must signal for at least 50 feet#Yes, you must signal for at least 100 feet"},
{"testId":638,"qid":28299,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"Car A must yield to Car B. As a result, Car B has the right-of-way here. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#15618#28517","text":"Car A must yield to its right#Car B must yield to its left#One driver must wave the other driver through#Car A must yield because turning vehicles never have the right-of-way"},
{"testId":638,"qid":28300,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"Slow down and ready to yield to oncoming pedestrians and cars. Be prepared to halt if necessary. [Regulatory Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#28518","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to any traffic already in the intersection"},
{"testId":638,"qid":28301,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"When an emergency vehicle approaches, pull over to the side of the road and stop to allow it to pass. If you are in traffic or in the center of an intersection, continue and pull over whenever feasible. [Emergency Vehicles, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202001302235285631.jpg","correctAnswer":28519,"isBookmarked":0,"aid":"15493#26747#26798#28519","text":"Slow down#Speed up to find somewhere safe to pull over#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the side of the road and stop"},
{"testId":638,"qid":28302,"description":"What is the meaning of this sign?","explanation":"In favorable circumstances, the recommended safe speed for this portion of highway is 35 mph, according to this advisory sign. Proceed with care and, if required, slow down. [Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003151720547901.jpg","correctAnswer":15496,"isBookmarked":0,"aid":"15496#15497#15498#15499","text":"The recommended speed limit is 35 mph#The maximum speed limit is 35 mph#The speed limit during the day is 35 mph#The speed limit at night is 35 mph"},
{"testId":638,"qid":28304,"description":"You reach a junction with a green light. Which of the following statements is correct?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003070857473536.jpg","correctAnswer":28520,"isBookmarked":0,"aid":"15504#25287#26322#28520","text":"You are free to proceed#You must briefly stop and yield, and then you can proceed#You must stop#You can proceed after yielding to all traffic and pedestrians already in the intersection"},
{"testId":638,"qid":28305,"description":"Is it legal to turn right at a red light?","explanation":"On a red light, you are permitted to turn right. You must, however, come to a full stop before yielding to all pedestrians and vehicles. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003112014364009.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26801#26802","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right on a red signal#Yes, this is a \"protected\" turn, so you have the right-of-way"},
{"testId":638,"qid":28306,"description":"Who is required to wear a safety belt?","explanation":"Any person riding in a car, including the driver and all passengers, must use a safety belt or an appropriate child restraint. [Official Hawaii Driver's Manual, Safety Belts, Vehicle Condition and Maintenance, Chapter II: Vehicle Equipment and Inspection]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"4420#16007#28521#28522","text":"Only the driver#The driver and every passenger#No one (safety belts are optional in Hawaii)#The driver, every front-seat passenger, and every passenger who is no older than 17"},
{"testId":638,"qid":28307,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The automobile on the left must yield to the one on the right. Car A must yield to Car B in this situation. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26804#26805","text":"Car A#Car B#Whichever car goes first#The car on the busier road"},
{"testId":638,"qid":28308,"description":"What is the meaning of this sign?","explanation":"This regulation sign instructs motorists to stay to the right of an impending island or obstruction. [Regulatory Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#15166#15187","text":"Divided highway ahead#Keep right#No left turn ahead#Roundabout ahead"},
{"testId":638,"qid":28309,"description":"Turn the wheels _____, whether you are parking uphill or downhill, with or without a curb.","explanation":"Turn the wheels to the right whether you're parking uphill or downhill. This will guarantee that if the car becomes loose, it will roll away from traffic. [Parking on a Hill, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202005121029148797.jpg","correctAnswer":28061,"isBookmarked":0,"aid":"6527#26268#28060#28061","text":"straight#in any direction#to the left#to the right"},
{"testId":638,"qid":28310,"description":"What is the meaning of this sign?","explanation":"This is a car symbol that moves slowly. This sign must be shown on the back of any vehicle with a peak speed of 25 mph or less, such as a farm tractor or some construction equipment. Slow down and approach with caution if you notice this warning. [Slow Moving Vehicle Emblem, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#15946#15948","text":"Stop#Slow-moving vehicle#Dead end#Road work zone"},
{"testId":638,"qid":28311,"description":"Which of the following locations allows parallel parking?","explanation":"Parallel parking must be no more than 12 inches from the curb. You must not park anyplace that might be dangerous, such as on a bridge, or anywhere that could pose a hazard, such as on a sidewalk. You must not obstruct fire hydrant access. The distance you may park from a fire hydrant varies from state to state. If you're not sure how near you can park in your neighborhood, remain 15 feet away from the hydrant for safety. [Parking, Official Hawaii Driver's Manual, Chapter V: Traffic Laws & Regulations]","imageName":"202003241433015085.jpg","correctAnswer":15953,"isBookmarked":0,"aid":"15951#15952#15953#28523","text":"B) On a bridge#C) On the sidewalk#D) Within 12 inches of the curb#A) 5 feet from a fire hydrant"},
{"testId":638,"qid":28312,"description":"What should you do when you see a solid yellow traffic light?","explanation":"The signal will quickly become red. If it is safe to do so, you must stop. Before you brake, consider any cars that may be behind you. If you are unable to stop safely, continue with care through the junction. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"23581#25305#28524#28525","text":"Yield to your right#Speed up to get through the intersection before the signal turns red#Stop if it is safe to do so#Proceed with caution since there may be a hazard ahead"},
{"testId":638,"qid":28313,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must first yield to any cars and pedestrians already at the junction since they have the right-of-way. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003070857473536.jpg","correctAnswer":15532,"isBookmarked":0,"aid":"15529#15532#25307#26814","text":"No, you need a green arrow to turn left#Yes, you can turn left, but you must yield to pedestrians and oncoming traffic first#Yes, you can turn left because this is a \"protected\" turn; no other cars are allowed to enter the intersection#Yes, but only if there is a \"Left Turn Permitted\" sign posted"},
{"testId":638,"qid":28314,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A vehicle turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":638,"qid":28315,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This sign alerts drivers that they have entered a no-passing zone. [Regulatory Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":638,"qid":28316,"description":"When must you come to a complete stop at a railroad crossing?","explanation":"When there is a red flashing light, a lowered gate, or a train coming near, you must come to a complete stop at a railroad crossing. You must not cross if a train blasts its horn. You must come to a complete stop at least 15 feet away from the closest rail. [Railroad Crossings, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202003161136554337.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"15955#15956#22962#28527","text":"When there is a flashing red signal#When there is a lowered gate#In all of the above situations#When a train approaches closely or sounds its horn"},
{"testId":638,"qid":28317,"description":"Car B has entered an intersection to make a right turn on a red light. Following that, Car A has reached a green light and want to go straight through the junction. Which of the following statements is correct? :","explanation":"When the light turns green, you may continue, but only after yielding to all cars and pedestrians already at the junction. As a result, Car A should yield to Car B here. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":638,"qid":28318,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light should be treated as a stop sign. That is, you must come to a full stop before moving and then yield to traffic and pedestrians. [Flashing Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#23589#25316#26815","text":"Stop and yield as you would at a stop sign#Stop; you cannot proceed until the signal stops flashing#Proceed; you do not need to stop or yield at a flashing red signal#Proceed with caution because the traffic signal is broken"},
{"testId":638,"qid":28319,"description":"When is it permissible to cross a pair of double solid yellow lines?","explanation":"When turning left into or out of a private road, driveway, or alley, you may cross a set of double solid yellow lines. [Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202001290916213788.jpg","correctAnswer":16057,"isBookmarked":0,"aid":"6334#15959#15961#16057","text":"Never#When you need to turn or pass another vehicle#Only when you must because of an unusual situation#When you need to turn left into a driveway or alley"},
{"testId":638,"qid":28320,"description":"What is the meaning of this sign?","explanation":"This sign warns of a railroad crossing ahead. Keep an eye out for warning lights and oncoming trains. [Railroad Crossing Markings, Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":638,"qid":28321,"description":"As seen, three cars arrive at an intersection around the same time. Who has the right-of-way in this situation?","explanation":"In a crosswalk, you must always yield to any pedestrian. The pedestrian has the right of way here. As a result, Car C is required to yield to the pedestrian. Car B must then surrender to Car C, and Car A must eventually yield to Car B. [Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":638,"qid":28322,"description":"You've parked your car and need to get out for a few moments. Which of the following assertions concerning leaving a child under the age of nine in a car alone is TRUE?","explanation":"It is not only unlawful, but also irresponsible, to leave a young kid unsupervised in a car for an extended period of time. A youngster under the age of nine cannot be left alone in a motor vehicle for more than five minutes in Hawaii. Even with the windows open, a vehicle may soon become lethally hot inside. A young kid or animal cannot live in such circumstances for long. [WARNING! Do Not Leave Children Unattended, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202002061627578233.jpg","correctAnswer":44503,"isBookmarked":0,"aid":"44500#44501#44502#44503","text":"You may leave a child in your vehicle as long as you have the windows open for air circulation#You may leave a child alone in a vehicle only if the child is asleep#By law, it is allowed to leave a small child in a vehicle for 5 minutes#Leaving a small child for 5 minutes or longer is prohibited by law"},
{"testId":638,"qid":28323,"description":"Which of the following statements concerning Hawaii's speed limits is correct?","explanation":"You must not go faster than the maximum speed limit. In terrible weather, road, and traffic circumstances, you must slow down at a fair pace. You must also not drive slower than the speed limit. You should pull over or find another route if you are driving slowly enough to obstruct the legitimate flow of traffic. [Speed Restrictions, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#28533#28534#28535","text":"All of the above are true#You must drive faster than the minimum speed limit, but you must not exceed the maximum speed limit#You should drive below the posted speed limit in certain situations#You should keep up with the lawful flow of traffic"},
{"testId":638,"qid":28325,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. The opposing lanes will be separated by a median or barrier. Continue straight. [Warning Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15561#15654#15668#28539","text":"Stay in your current lane; do not merge left or right#There is a roundabout ahead#There is a divided highway ahead#There is a center two-way left turn lane ahead"},
{"testId":638,"qid":28326,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a two-way turn lane. This lane is reserved for left turns. It is open to traffic travelling in both directions, so proceed with caution. [Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202001301806403967.jpg","correctAnswer":28540,"isBookmarked":0,"aid":"15973#15974#28540#28541","text":"A central parallel parking area#A bus lane#A shared center turning lane (a center two-way left turn lane)#A high-occupancy vehicle (HOV) lane"},
{"testId":638,"qid":28327,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"A vehicle turning left must yield to approaching traffic and pedestrians. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25322#25323#25324","text":"No one#Oncoming vehicles#Pedestrians#Pedestrians and oncoming vehicles"},
{"testId":638,"qid":28328,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Required Signals, Official Hawaii Driver's Manual, Chapter V: Traffic Laws & Regulations]","imageName":"202002181150078740.jpg","correctAnswer":14582,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":638,"qid":28329,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"You must not drive within 20 feet of a stopped school bus with flashing red lights, regardless of which way you are heading. (The only exception is if the school bus has come to a halt on the other side of a split roadway.) Once the bus has turned off its red lights and started moving, continue with care. [Stopping for a School Bus, Official Hawaii Driver's Manual, Chapter XII: Pedestrian Safety]","imageName":"202003051658529900.jpg","correctAnswer":28544,"isBookmarked":0,"aid":"26825#28542#28543#28544","text":"Yes, but you must first slow down to 15 mph and yield to pedestrians#Yes, but you must first stop and yield to any pedestrians crossing the roadway#Yes, but not if you are traveling in the same direction as the bus#No, you must stop no matter which direction you are traveling in"},
{"testId":638,"qid":28330,"description":"What is the meaning of this sign?","explanation":"This pentagonal (five-sided) sign indicates a school zone. Proceed with caution and give way to pedestrians. [Warning Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003151740461521.jpg","correctAnswer":23549,"isBookmarked":0,"aid":"23549#26828#26829#26830","text":"There is a school zone ahead#There is a pedestrian crossing ahead#There is an unprotected area of sidewalk ahead#No vehicles are allowed ahead"},
{"testId":638,"qid":28331,"description":"What is the meaning of this sign?","explanation":"This sign alerts cars that a crossroads is coming. Drivers should reduce their speed and prepare for the junction. [Warning Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":638,"qid":28332,"description":"Pedestrian crossing the street you want to enter just as you're about to turn right on a red light. What should you do here?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a crosswalk, you should always yield to a pedestrian. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":638,"qid":28333,"description":"When is it permissible to cross a pair of double solid white lines?","explanation":"Two lanes of traffic traveling in the same direction are separated by two solid white lines. On highways, they are often used to divide a high-occupancy vehicle (HOV) lane from other lanes. It is forbidden to cross double solid white lines. You must wait until you reach an area of road with a single dashed (broken) white line before crossing. [Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003202134094720.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#15959#15960#28546","text":"Never#When you need to turn or pass another vehicle#When you need to turn left off the road#When you must do it because of an unusual situation"},
{"testId":638,"qid":28334,"description":"Which of the following about driving in the rain is NOT true?","explanation":"The initial 10 minutes of rain, when the water combines with the oil already on the road's surface, are sometimes the most treacherous. Hydroplaning happens when your tires skim over the surface of the water. At speeds as low as 35 mph, you may begin to hydroplane. If you hydroplane, you should immediately cease speeding and prevent braking. Allow the car to slow down on its own. You should not use your cruise control because it may spin the wheels in an effort to maintain a consistent pace if your car loses traction. This may exacerbate or even cause a skid. [Driving in Inclement Weather, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202002061923072507.jpg","correctAnswer":28548,"isBookmarked":0,"aid":"28547#28548#28549#28550","text":"Cars often start to hydroplane at speeds as low as 35 mph#If you start to hydroplane, you should apply your brakes and slow down quickly#Roads can be slipperiest just after it starts to rain#Cruise control should be switched off in wet weather"},
{"testId":638,"qid":28336,"description":"Which of the following steps is not required when changing lanes?","explanation":"When changing lanes, you must signal, check your mirrors, and glance behind you. Your vehicle has blind spots big enough to totally obscure another vehicle in the lane next to you. Looking over your shoulder is the only method to check for blind spots. [Laziness, Driving Habits, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#25334","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check that the road is clear"},
{"testId":638,"qid":28337,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car is automated, choose low gear to decrease brake wear. [Warning Signs, Traffic Signs, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26840#26841#28551#28552","text":"There is a truck escape ramp ahead#There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route"},
{"testId":638,"qid":28338,"description":"A single solid white line separates two lanes of traffic. When is it OK to cross this line?","explanation":"You can only cross a solid white line if you are in an extraordinary scenario. If you must cross, go with great care. [Pavement Markings, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202004281851466397.jpg","correctAnswer":28553,"isBookmarked":0,"aid":"15989#15990#28553#28554","text":"When you need to pass another vehicle#When the speed limit is below 20 mph#When unusual circumstances require you to cross it#When you are traveling below 20 mph"},
{"testId":638,"qid":28339,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Required Signals, Official Hawaii Driver's Manual, Chapter V: Traffic Laws & Regulations]","imageName":"202002181152514351.jpg","correctAnswer":15572,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":638,"qid":28340,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"It is advised that you keep a two-second separation at all times. This is the recommended minimum following distance. You might consider keeping a greater following distance. When the weather is bad, you should increase your following distance. [Vehicle Speed, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#15992#19915#26842","text":"At least two seconds#At least six seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":638,"qid":28341,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Normally, you must come to a complete stop before approaching a school bus that has stopped for passengers. When you are on the other side of a split roadway, you do not need to stop. It is important to note that a road with a center turning lane (a two-way left turn lane) does not qualify as a split highway. [Stopping for a School Bus, Official Hawaii Driver's Manual, Chapter XII: Pedestrian Safety]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#15698#25391#26843","text":"No, you do not need to stop here#Yes, you must briefly stop and yield to any pedestrians on the road#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution"},
{"testId":638,"qid":28342,"description":"What does a yellow flashing traffic light indicate?","explanation":"A flashing yellow light requires you to slow down and continue with care. [Flashing Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#28555#28556#28557","text":"Slow down and proceed with caution#Stop if it is safe to do so; the signal will soon turn steady red#Yield (treat this signal like a yield sign)#Stop if it is safe to do so; the signal will soon be flashing red"},
{"testId":638,"qid":28343,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [The Rules of Right-of-Way, Official Hawaii Driver's Manual, Chapter V: Traffic Laws and Regulations]","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way"},
{"testId":638,"qid":28344,"description":"What is the significance of this hand signal?","explanation":"This is a hand gesture for a left turn. [Required Signals, Official Hawaii Driver's Manual, Chapter V: Traffic Laws & Regulations]","imageName":"202002181155395841.jpg","correctAnswer":28559,"isBookmarked":0,"aid":"28559#28560#28561#28562","text":"The driver is about to turn left#The driver is about to turn right#The driver is about to slow down or stop#Traffic may pass safely now"},
{"testId":639,"qid":28346,"description":"You come to an intersection and see this sign. Which of the following should you do?","explanation":"You must come to an immediate halt. After yielding to all cross traffic and pedestrians, you may continue. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Regulatory Signs]","imageName":"202001301744345845.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15473#15474#15475#28842","text":"Come to a complete stop and yield to any traffic before proceeding#Come to a complete stop and then go#Find another route; you cannot proceed through here#Slow down and proceed when the intersection is clear"},
{"testId":639,"qid":28347,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Regulatory Signs]","imageName":"202003151749335643.jpg","correctAnswer":26791,"isBookmarked":0,"aid":"15476#15478#15479#26791","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal maximum speed limit is 50 mph"},
{"testId":639,"qid":28348,"description":"Is it necessary to activate your turn signals if there are no other vehicles in the area?","explanation":"Before turning, you must signal for at least 100 feet. You must signal for at least five seconds if you are on a highway or expressway. Even if you assume there are no other automobiles in the area, you should always signal. The most deadly automobile on the road may be one you don't see. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202001291433312120.jpg","correctAnswer":28845,"isBookmarked":0,"aid":"28516#28843#28844#28845","text":"Yes, you must signal for at least 100 feet#No, there is no one to signal to#No, turn signals are optional in Idaho#Yes, you must signal for at least five seconds on a freeway and 100 feet everywhere else"},
{"testId":639,"qid":28349,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two automobiles approach at an uncontrolled junction at the same moment, the left car must yield to the right car. Car A must yield to Car B. As a result, Car B has the right of way here. [According to Other Drivers, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":639,"qid":28350,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"Slow down and ready to yield to oncoming pedestrians and cars. Be prepared to halt if necessary. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Regulatory Signs]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#26797","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to traffic already in the intersection"},
{"testId":639,"qid":28351,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"When you notice an emergency vehicle coming, pull over and stop to let it pass. However, do not stop or pull over in the midst of an intersection. Continue past the junction, then pull over and come to a complete stop. [The Driving Task, Emergency Vehicles, Idaho Driver's Handbook]","imageName":"202001302235285631.jpg","correctAnswer":28847,"isBookmarked":0,"aid":"15493#26747#26798#28847","text":"Slow down#Speed up to find somewhere safe to pull over#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Move toward the edge of the road and stop"},
{"testId":639,"qid":28352,"description":"What should you do before crossing a street from an alley, driveway, or private road?","explanation":"Before approaching a road from a non-road location, you must always come to a complete stop. Any driveway, private road, or alley is included. Before advancing, you must yield to all vehicles and pedestrians. [Stopping, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202001291438598251.jpg","correctAnswer":28848,"isBookmarked":0,"aid":"23466#28148#28848#28849","text":"Yield to pedestrians and traffic#Proceed with caution#Stop and yield to pedestrians and traffic#Sound your horn and enter when safe"},
{"testId":639,"qid":28353,"description":"This sign is pentagonal (five-sided). What exactly is this sign?","explanation":"Pentagonal school zone and crossing signs are used (five-sided). [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15823#15824#15825","text":"A school zone sign#A no passing sign#A work zone sign#A warning sign"},
{"testId":639,"qid":28354,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202003070857473536.jpg","correctAnswer":28852,"isBookmarked":0,"aid":"21234#28850#28851#28852","text":"Yes#No, you must stop#No, you must briefly stop and yield before proceeding#Yes, you can proceed after yielding to all traffic and pedestrians already in the intersection"},
{"testId":639,"qid":28355,"description":"Is it legal to turn right at a red light?","explanation":"On a red light, you are permitted to turn right. You must, however, come to a full stop before yielding to all pedestrians and vehicles. [Stopping, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#28853#28854","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right at a red signal#Yes, this is a \"protected\" turn so you have the right-of-way"},
{"testId":639,"qid":28356,"description":"Who is required to wear a safety belt?","explanation":"The driver and every passenger must use an appropriate safety belt or kid restraint. [Seat Belts and Shoulder Straps, Vehicle Equipment and Safety, Idaho Driver's Handbook]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"4420#16007#28855#28856","text":"Only the driver#The driver and every passenger#No one (safety belts are optional in Idaho)#The driver, every front-seat passenger, and every passenger 17 or younger"},
{"testId":639,"qid":28357,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [According to Other Drivers, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26804#26805","text":"Car A#Car B#Whichever car goes first#The car on the busier road"},
{"testId":639,"qid":28358,"description":"Are any of these cars navigating the roundabout incorrectly?","explanation":"Unless otherwise indicated, you should turn right from the right lane, left from the left lane, and straight using either lane. Car C is turning left from the right lane, which is wrong. [Idaho Driver's Handbook, Intersections, Highway and Vehicle Knowledge]","imageName":"202001290908474628.jpg","correctAnswer":26824,"isBookmarked":0,"aid":"18118#26822#26823#26824","text":"No#Yes, Car A#Yes, Car B#Yes, Car C"},
{"testId":639,"qid":28359,"description":"When parking facing uphill against the curb, which direction should the front wheels point?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Idaho Driver's Handbook, Parking on a Hill or Incline, Traffic Laws and Rules of the Road]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":639,"qid":28360,"description":"What is the meaning of this sign?","explanation":"This is a slow-moving vehicle symbol that is displayed on the back of any vehicle with a maximum speed of 25 miles per hour or below. If you notice this symbol on a car ahead of you, you should slow down immediately. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Regulatory Signs]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#15946#15948","text":"Stop#Slow-moving vehicle#Dead end#Road work zone"},
{"testId":639,"qid":28361,"description":"Which of the following locations allows parallel parking?","explanation":"Parallel parking must be no more than 18 inches from the kerb. You must not park someplace that is dangerous (such as a bridge) or where parking may create a hazard (such as on a sidewalk). Parking is not permitted within 15 feet of a fire hydrant. [Idaho Driver's Handbook, Parking, Traffic Laws, and Road Rules]","imageName":"202003241433015085.jpg","correctAnswer":16009,"isBookmarked":0,"aid":"15951#15952#16009#28523","text":"B) On a bridge#C) On the sidewalk#D) Within 18 inches of the curb#A) 5 feet from a fire hydrant"},
{"testId":639,"qid":28362,"description":"What should you do when you see a solid yellow traffic light?","explanation":"The signal will quickly become red. Before approaching the junction, you must stop if you can do so safely. When you come to a halt, be aware of any cars that may be following you. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"23581#28524#28857#28858","text":"Yield to your right#Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead"},
{"testId":639,"qid":28363,"description":"You come to a crossroads. You want to go left, and there's a green light. Can you move on?","explanation":"On a green light, you may turn left, but you must yield to pedestrians at crosswalks and approaching cars since they have the right of way. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202001301833114961.jpg","correctAnswer":15532,"isBookmarked":0,"aid":"15529#15532#25307#28859","text":"No, you need a green arrow to turn left#Yes, you can turn left, but you must yield to pedestrians and oncoming traffic first#Yes, you can turn left because this is a \"protected\" turn; no other cars are allowed to enter the intersection#Yes, but only if there is a Left Turn Permitted sign posted"},
{"testId":639,"qid":28364,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"Traffic accessing a major road from a private road or driveway must yield to main road vehicles. (Note: In certain states, incoming cars must come to a full stop before yielding to vehicles on the main road. Always verify the local traffic rules while going out of state.)","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":639,"qid":28365,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"A pennant-shaped sign informs that no passing is permitted in this region. [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":639,"qid":28366,"description":"Which of the following statements concerning railroad crossings is correct?","explanation":"At a flashing red railroad signal, you must come to a full stop until you are certain it is safe to go. You may not drive around a crossing gate or beneath a partly raised gate. If you must stop at a railroad crossing, you must do so 15 to 50 feet away from the rails. [Idaho Driver's Handbook, Intersections, Highway and Vehicle Knowledge]","imageName":"202003161136554337.jpg","correctAnswer":28862,"isBookmarked":0,"aid":"23029#28860#28861#28862","text":"All of the above are true#You only need to stop for a flashing red signal when you see a train approaching#You can drive under or around crossing gates when there are no trains present#When you stop at a railroad crossing, you must do so 15 to 50 feet from the tracks"},
{"testId":639,"qid":28367,"description":"Car B has entered an intersection to make a right turn on a red light. Car A then approaches a green light. Which of the following statements is true? :","explanation":"When the light turns green, you may continue, but only after yielding to all cars and pedestrians already at the junction. As a result, Car A should yield to Car B in this situation. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":639,"qid":28368,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red signal should be treated as a stop sign: stop, yield, and continue only when it is safe to do so. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#25316#28863#28864","text":"Stop and yield as you would at a stop sign#Proceed; you do not need to stop or yield at a flashing red signal#Drive with caution; the traffic signal is broken#Stop and do not proceed until the signal stops flashing"},
{"testId":639,"qid":28369,"description":"When is it permissible to cross double solid yellow lines?","explanation":"Only when turning left into a driveway or the entrance to a business can you cross double solid yellow lines. However, no passing is permitted. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Pavement Markings]","imageName":"202001290916213788.jpg","correctAnswer":16014,"isBookmarked":0,"aid":"6334#15959#15961#16014","text":"Never#When you need to turn or pass another vehicle#Only when you must because of an unusual situation#When you need to turn left into a driveway"},
{"testId":639,"qid":28370,"description":"What is the meaning of this sign?","explanation":"This sign warns of a railroad crossing ahead. Keep an eye out for warning lights and oncoming trains. [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":639,"qid":28371,"description":"Who has the right-of-way in this situation?","explanation":"In a crosswalk, a vehicle must yield to pedestrians. After taking into account pedestrians, each vehicle must yield to the vehicle on its right. As a result, Car A must yield to Car B, who must yield to Car C, who must surrender to the pedestrian. [Pedestrians and Right-of-Way, Traffic Laws and Road Rules, Idaho Driver's Handbook]","imageName":"202001241712298025.jpg","correctAnswer":14863,"isBookmarked":0,"aid":"14814#14815#14862#14863","text":"Car A#Car B#Car C#The pedestrian"},
{"testId":639,"qid":28372,"description":"Which vehicles are turning left correctly here?","explanation":"Unless otherwise indicated, turn left from the left lane and right from the right lane. Turn into the nearest lane of traffic heading in the right direction. Car A has made an erroneous turn into the far lane. Car B has made the proper turn into the nearest lane. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202001302242405745.jpg","correctAnswer":28866,"isBookmarked":0,"aid":"15982#15983#28865#28866","text":"Neither car#Both cars#Car A only#Car B only"},
{"testId":639,"qid":28373,"description":"It is prohibited to drive below the speed limit ________.","explanation":"Driving so slowly that you disturb the usual flow of traffic is unlawful. You must drive at a speed that does not hinder the usual flow of traffic, but not faster than the maximum speed limit. You must also drive in accordance with the present circumstances; for example, in rainy weather, the legal speed limit may be too fast. Finally, if you are holding up three or more cars in a rural region or on a two-lane highway, you must move over to the side of the road where it is safe for them to pass. [Idaho Driver's Handbook, Speed Limits, Traffic Laws, and Road Rules]","imageName":"202003151746465721.jpg","correctAnswer":44787,"isBookmarked":0,"aid":"44784#44785#44786#44787","text":"if you are slow due to harsh weather conditions#only if you are staying in the city#only if you are on rural roads#if you are impeding the flow of other traffic traveling at a lawful rate of speed"},
{"testId":639,"qid":28374,"description":"Is it permissible to turn left at a red signal?","explanation":"Only when going from one one-way street into another one-way street in Idaho may you turn left on a red light, unless there are signs banning the move. You must still come to a full stop and give way to oncoming vehicles and pedestrians. Check the regulations of the places you're visiting; some jurisdictions outright ban turning left on a red light. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202003112014364009.jpg","correctAnswer":26820,"isBookmarked":0,"aid":"25318#26820#28870#28871","text":"Yes, unless it is prohibited by a sign#Yes, but only from a one-way street onto another one-way street#No, not in Idaho#Yes, but only onto a one-way street from any type of street"},
{"testId":639,"qid":28375,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15561#15654#15667#15668","text":"Stay in your current lane; do not merge left or right#There is a roundabout ahead#There is a central shared turning lane ahead#There is a divided highway ahead"},
{"testId":639,"qid":28376,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a shared middle lane. This lane is exclusively for making left turns (and U-turns where permitted). This lane may be utilized by cars traveling in either way, so take care. [Idaho Driver's Handbook, Highway and Vehicle Knowledge, Pavement Markings]","imageName":"202001301806403967.jpg","correctAnswer":28872,"isBookmarked":0,"aid":"15973#15974#28541#28872","text":"A central parallel parking area#A bus lane#A high-occupancy vehicle (HOV) lane#A shared center turning lane"},
{"testId":639,"qid":28377,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"Before turning left, you must yield to any pedestrians and incoming cars. [According to Other Drivers, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":639,"qid":28378,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202002181150078740.jpg","correctAnswer":14582,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":639,"qid":28379,"description":"Can you pass a school bus that is stopped and flashing its red lights?","explanation":"You must come to a complete stop before approaching a school bus with flashing red lights or a stop arm extended. [Stopping, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202003051658529900.jpg","correctAnswer":28544,"isBookmarked":0,"aid":"26825#28542#28543#28544","text":"Yes, but you must first slow down to 15 mph and yield to pedestrians#Yes, but you must first stop and yield to any pedestrians crossing the roadway#Yes, but not if you are traveling in the same direction as the bus#No, you must stop no matter which direction you are traveling in"},
{"testId":639,"qid":28380,"description":"Three vehicles arrive at a T-junction. In what order should they go?","explanation":"Cars on the through (main) route, like B and C in this instance, have the right of way at a T-intersection, hence Car A is required to give way to B and C. Car C must yield to Car B because cars turning left must give way to approaching traffic. As a result, the automobiles are in B, C, and A. [Idaho Driver's Handbook, Yielding to Other Drivers, Traffic Laws and the Rules of the Road]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":639,"qid":28381,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":639,"qid":28382,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"Before turning right on a red signal, you must stop and yield to all cars and pedestrians. In a crosswalk, you should always yield to a pedestrian. [Pedestrians and Right-of-Way, Traffic Laws and Road Rules, Idaho Driver's Handbook]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":639,"qid":28383,"description":"Which of the following statements are true?","explanation":"From sundown till dawn, and if visibility goes below 500 feet, you must use your headlights. You must not drive with just your parking lights on under these situations. [Other Important Laws, Vehicle Equipment and Safety, Idaho Driver's Handbook]","imageName":"202003161149128995.jpg","correctAnswer":28878,"isBookmarked":0,"aid":"28878#37208#37209#37210","text":"D) Both (A) and (B) are correct#(A) You must use your headlights from sunset to sunrise#(B) You must use your headlights whenever visibility falls below 500 feet#(C) When it is overcast, you can drive with your parking lights on to make your car more visible"},
{"testId":639,"qid":28384,"description":"You come to a broken traffic light, where none of the lights are on. What do you need to do?","explanation":"When the traffic lights are out, treat the junction as if it were a four-way stop. You must stop, yield, and advance only when it is safe to do so (with utmost care). [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202003051805205845.jpg","correctAnswer":29322,"isBookmarked":0,"aid":"28881#28882#29322#44660","text":"Proceed through the intersection if you are on the busier road; otherwise yield#Proceed through as if you had a green light#Treat the intersection as a four-way stop#Treat the intersection as a four-way yield"},
{"testId":639,"qid":28386,"description":"When changing lanes, which of the following steps is critical?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to totally obscure another vehicle in the lane next to you. Looking over your shoulder is the only method to check for these blind spots. [Idaho Driver's Handbook, Changing Lanes, Traffic Laws, and the Rules of the Road]","imageName":"202001302245403150.jpg","correctAnswer":203,"isBookmarked":0,"aid":"203#15460#15687#44665","text":"All of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check your blind spot"},
{"testId":639,"qid":28387,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if you drive an automatic, choose a low gear to avoid brake wear. [Idaho Driver's Handbook, Warning Signs, Highway and Vehicle Knowledge]","imageName":"202002101212538783.jpg","correctAnswer":28885,"isBookmarked":0,"aid":"28551#28552#28884#28885","text":"Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route#There is an escape ramp ahead#Steep descent ahead; proceed with caution"},
{"testId":639,"qid":28388,"description":"You can increase your night vision by using your high beams. But when should you dim your high beams?","explanation":"When following another vehicle within 200 feet or within 500 feet of any incoming vehicle, you must lower your high lights. [Other Important Laws, Vehicle Equipment and Safety, Idaho Driver's Handbook]","imageName":"202002211503506612.jpg","correctAnswer":28886,"isBookmarked":0,"aid":"28886#28887#28888#28889","text":"When you are within 500 feet of an oncoming vehicle or 200 feet behind another vehicle#When you are within 200 feet of an oncoming vehicle or 500 feet behind another vehicle#When you are within 200 feet of any vehicle#When you are within 500 feet of any vehicle"},
{"testId":639,"qid":28389,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202002181152514351.jpg","correctAnswer":15572,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":639,"qid":28390,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"It is advised that you keep a three-second separation at all times. When driving conditions are bad, you should increase your following distance. [Idaho Driver's Handbook, Following Distances, Traffic Laws, and Road Rules]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15992#16038#19915#26842","text":"At least six seconds#At least three seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":639,"qid":28391,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Most highways require cars moving in either direction to stop for a school bus with red flashing lights or a stop arm. In Idaho, however, you are not required to stop if you are on the opposite side of a roadway with two or more lanes of traffic in each direction. (Note: Different rules may apply in other areas.) Only if the roadway is split, with a median or barrier separating opposing lanes of traffic, do you need to stop in certain jurisdictions.) [Stopping, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#15698#25391#26843","text":"No, you do not need to stop here#Yes, you must briefly stop and yield to any pedestrians on the road#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution"},
{"testId":639,"qid":28392,"description":"What does a yellow flashing traffic light indicate?","explanation":"When you see a flashing yellow light, you should slow down and continue with care. [Idaho Driver's Handbook, Traffic Signals, Highway and Vehicle Knowledge]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26845#26846#28890","text":"Slow down and proceed with caution#The signal will soon turn red; stop if it is safe to do so#Yield; treat this signal like a yield sign#The signal will soon flash red; stop if it is safe to do so"},
{"testId":639,"qid":28393,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [According to Other Drivers, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202007120530048918.jpg","correctAnswer":26849,"isBookmarked":0,"aid":"25308#26849#26850#28558","text":"This is an uncontrolled intersection; one driver must wave the other one through#Car A must yield since it is turning left#Car B must yield since it is turning right#The car in the busier lane has the right-of-way"},
{"testId":639,"qid":28394,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist is indicating a left turn. [Turns, Traffic Laws, and Road Rules, Idaho Driver's Handbook]","imageName":"202002181155395841.jpg","correctAnswer":14579,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":639,"qid":28395,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":"If you notice an emergency vehicle pulled over ahead with flashing lights, you should slow down. If practicable and safe, you should also leave an empty lane between your car and the emergency vehicle. [The Driving Task, Emergency Vehicles, Idaho Driver's Handbook]","imageName":"202001241703386758.jpg","correctAnswer":28893,"isBookmarked":0,"aid":"15493#28891#28892#28893","text":"Slow down#Stay in your lane and proceed with caution#Leave an empty lane between your vehicle and the stopped emergency vehicle if it is safe to do so#Slow down and leave an empty lane between your vehicle and the emergency vehicle if it is safe to do so"},
{"testId":640,"qid":28396,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"It's a STOP sign. The word \"STOP\" is written in white letters on a red backdrop on a STOP sign. You must come to a complete stop before crossing the stop line or approaching a crosswalk or junction. Before proceeding, you must yield to pedestrians and traffic. [Sign Shapes, Chapter 9: Roadway Signs, Illinois Road Rules]","imageName":"202003151731031579.jpg","correctAnswer":29312,"isBookmarked":0,"aid":"15472#28148#29312#29313","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to all traffic before proceeding#Proceed straight through when it is safe to do so; left and right turns are prohibited here"},
{"testId":640,"qid":28397,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Regulation, Chapter 9: Roadway Signs, Illinois Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":640,"qid":28398,"description":"Is it necessary to activate your turn signals if there are no other vehicles in the area?","explanation":"Before turning in a commercial or residential area, you must signal for at least 100 feet. Everywhere else, you must signal for at least 200 feet. Even if you can't see anybody else, you must signal. The most deadly car on the road may be one you don't see. [Signaling, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202001291433312120.jpg","correctAnswer":29316,"isBookmarked":0,"aid":"28843#29314#29315#29316","text":"No, there is no one to signal to#No, turn signals are optional in Illinois#Yes, you must signal for at least 100 feet or four seconds in rural areas#Yes, you must signal for 100 feet in a business or residential area and 200 feet elsewhere"},
{"testId":640,"qid":28399,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two automobiles approach at an uncontrolled junction at the same moment, the left car must yield to the right car. Car A must yield to Car B. As a result, Car B has the right of way in this situation. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":640,"qid":28400,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"When approaching a YIELD sign, slow down and ready to yield to pedestrians and oncoming vehicles. Be prepared to halt if necessary. [Yield, Regulatory, Illinois Rules of the Road, Chapter 9: Roadway Signs]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#26797","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to traffic already in the intersection"},
{"testId":640,"qid":28401,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"When you see an emergency vehicle coming with its flashing lights on, you must stop and let it pass. [Emergency/Maintenance Vehicles, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202001302235285631.jpg","correctAnswer":29317,"isBookmarked":0,"aid":"15493#26747#26798#29317","text":"Slow down#Speed up to find somewhere safe to pull over#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the edge of the road and stop if necessary"},
{"testId":640,"qid":28402,"description":"What should you do before crossing a street from an alley, driveway, or private road?","explanation":"Before entering the road from an alley, driveway, building, or private road, you must come to a complete stop. Before entering the sidewalk, you must come to a full stop. If there is no sidewalk, you must come to a complete stop before crossing the street. Before advancing, you must yield to all vehicles and pedestrians. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202001291438598251.jpg","correctAnswer":28848,"isBookmarked":0,"aid":"23466#28148#28848#29318","text":"Yield to pedestrians and traffic#Proceed with caution#Stop and yield to pedestrians and traffic#Sound your horn and enter when it is safe to do so"},
{"testId":640,"qid":28403,"description":"This sign is pentagonal (five-sided). What exactly is this sign?","explanation":"Pentagonal school zone and crossing signs are used (five-sided). [Sign Shapes, Chapter 9: Roadway Signs, Illinois Road Rules]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15823#15824#15825","text":"A school zone sign#A no passing sign#A work zone sign#A warning sign"},
{"testId":640,"qid":28404,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Steady Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":28852,"isBookmarked":0,"aid":"21234#28850#28851#28852","text":"Yes#No, you must stop#No, you must briefly stop and yield before proceeding#Yes, you can proceed after yielding to all traffic and pedestrians already in the intersection"},
{"testId":640,"qid":28405,"description":"Is it legal to turn right at a red light?","explanation":"On a red light, you are permitted to turn right. You must, however, come to a full stop before yielding to all pedestrians and vehicles. [Steady Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#28853#29319","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#No, you can never turn right at a red signal#Yes, this is a \"protected\" turn, so you have the right of way"},
{"testId":640,"qid":28406,"description":"On Illinois roadways, who must wear a seat belt or use a child restraint system?","explanation":"The driver and every passenger must use an appropriate safety belt or kid restraint. [Safety Belt Law, Chapter 4: Traffic Laws, Illinois Rules of the Road]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"4420#15944#16007#29320","text":"Only the driver#The driver, every front seat passenger, and every passenger 17 or under#The driver and every passenger#No one (safety belts are optional in Illinois)"},
{"testId":640,"qid":28407,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars come to a complete stop at an all-way stop, the automobile on the right has the right of way. Car A must yield to Car B in this situation. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26804#26805","text":"Car A#Car B#Whichever car goes first#The car on the busier road"},
{"testId":640,"qid":28408,"description":"What is the speed limit in a school zone in Illinois?","explanation":"On school days when children are present, the legal speed limit in an Illinois school zone is 20 mph between 7 a.m. and 4 p.m. [School Zones, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202004281807142908.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#522#523#2429","text":"15 mph#25 mph#20 mph#10 mph"},
{"testId":640,"qid":28409,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking, Chapter 4: Traffic Laws, Illinois Rules of the Road, Hill Parking]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":640,"qid":28410,"description":"What is the meaning of this sign?","explanation":"This is a slow-moving vehicle symbol that is displayed on the back of any vehicle with a maximum speed of 20 miles per hour or below. If you notice this symbol on a car ahead of you, you should slow down immediately. [Slow-Moving Vehicles, Illinois Rules of the Road, Chapter 5: Sharing the Road]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#15946#15948","text":"Stop#Slow-moving vehicle#Dead end#Road work zone"},
{"testId":640,"qid":28411,"description":"Which of the following locations allows parallel parking?","explanation":"Parallel parking must be no more than 12 inches from the curb. You must not park someplace that is dangerous (such as on a bridge) or that may create a hazard (such as on a sidewalk). Parking is not permitted within 15 feet of a fire hydrant. As a result, D) is the right answer here. [Prohibited Stopping, Standing, and Parking, Chapter 4: Traffic Laws, Illinois Rules of the Road], [Parallel Parking, Chapter 4: Traffic Laws, Illinois Rules of the Road]","imageName":"202003241433015085.jpg","correctAnswer":15953,"isBookmarked":0,"aid":"15951#15952#15953#29321","text":"B) On a bridge#C) On the sidewalk#D) Within 12 inches of the curb#A) 10 feet from a fire hydrant"},
{"testId":640,"qid":28412,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic signal indicates that the light will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. [Steady Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#29322","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as a four-way stop"},
{"testId":640,"qid":28413,"description":"Which of the following is true about work zones on the highway?","explanation":"Within a highway construction zone, you must slow down and yield to all highway employees and vehicles. You are not permitted to use a mobile phone while driving through a construction zone. Allow a lane to be left open around employees and yield to them as they cross or join the road. [Construction Zones, Construction Zones, Emergency Vehicles, School Zones, and Funeral Processions, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202002061102364394.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#29323#29324#29325","text":"All of the above are true#You should slow down as you drive through a work zone#You must not use your cell phone in a work zone#You must leave a vacant lane between your vehicle and the highway workers if possible"},
{"testId":640,"qid":28414,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"Motorcyclists and passengers in Utah are not required to wear eye protection. Even though your motorbike has a windshield, you should wear eye protection at all times. Your eyes must be shielded from the wind and the particles it carries. A windscreen DOES NOT replace a face shield or goggles. Most windshields do not screen your eyes from the wind.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#28526#29326","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#Car B must yield to all vehicles turning left#The car on the busier road has the right of way"},
{"testId":640,"qid":28415,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The \"No Passing Zone\" sign is designated with a pennant form. This yellow warning sign alerts motorists that they have entered a no-passing zone. [Sign Shapes, Chapter 9: Roadway Signs, Illinois Road Rules]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":640,"qid":28416,"description":"You come to a railroad crossing where a gate is down and red lights are flashing. Where do you have to stop?","explanation":"If you must stop at a railroad crossing, you should do so between 15 and 50 feet away. [Railroad Crossings, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003161136554337.jpg","correctAnswer":29328,"isBookmarked":0,"aid":"16052#29327#29328#29329","text":"Immediately in front of the gate#15 feet from the crossing#Between 15 and 50 feet from the crossing#Between 50 and 100 feet from the crossing"},
{"testId":640,"qid":28417,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"Even if you have a green light, you must yield to all cars and pedestrians currently at the junction. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":640,"qid":28418,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red traffic light is often used to notify cars of the presence of a STOP sign. As a result, you should regard a flashing red light as a STOP sign. That is, you must come to a complete stop and yield to oncoming traffic. [Flashing Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003051722347868.jpg","correctAnswer":15543,"isBookmarked":0,"aid":"15543#28864#29330#29331","text":"Stop and yield as you would at a STOP sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":640,"qid":28420,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. [Sign Shapes, Chapter 9: Roadway Signs, Illinois Road Rules]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":640,"qid":28421,"description":"Which of the following statement concerning legal parking is correct?","explanation":"Parking is not permitted within 30 feet of any STOP sign, YIELD sign, or traffic signal. You are also not permitted to park within 50 feet of a railroad crossing's closest rail. [Prohibited Stopping, Standing, and Parking, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"1369826678_t1q26.png","correctAnswer":29332,"isBookmarked":0,"aid":"29332#29333#29334#29335","text":"You cannot park within 50 feet of any railroad crossing or 30 feet of any STOP sign#You cannot park within 30 feet of any railroad crossing or 50 feet of any STOP sign#You cannot park within 30 feet of any railroad crossing or STOP sign#You cannot park within 50 feet of any railroad crossing or STOP sign"},
{"testId":640,"qid":28422,"description":"Which vehicle is turning left correctly here?","explanation":"Unless otherwise indicated, turn left from the left lane and right from the right lane. You should merge into the nearest lane of traffic traveling in the correct direction. Car A has made an erroneous turn into the far lane. Car B has made the proper turn into the nearest lane. [Left Turns, Turning, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202001302242405745.jpg","correctAnswer":28866,"isBookmarked":0,"aid":"15982#15983#28865#28866","text":"Neither car#Both cars#Car A only#Car B only"},
{"testId":640,"qid":28423,"description":"Which of these is true about the speed limits in Illinois?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a speed that does not obstruct the regular flow of legitimate traffic. When driving conditions are bad, you must slow down. [Speed Limits, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#29336#29337#29338","text":"All of the above are true#You should keep up with the flow of traffic, but you must never exceed the legal speed limit#You should never drive so slowly as to impede the flow of traffic#You are only allowed to drive at the posted speed limit under safe conditions"},
{"testId":640,"qid":28425,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. [Warning Signs, Chapter 9: Roadway Signs, Illinois Rules of the Road]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15561#15654#15668#29341","text":"Stay in your current lane; do not merge left or right#There is a roundabout ahead#There is a divided highway ahead#There is a two-way left turn lane ahead"},
{"testId":640,"qid":28426,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a shared center turning lane with a two-way left turn lane. You may enter or exit this lane by turning left. Where authorized, this lane may also be used for U-turns. This lane may be utilized by cars traveling in either way, so take care. [Two-Way Left Turn Lanes, Pavement Markings, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202001301806403967.jpg","correctAnswer":16066,"isBookmarked":0,"aid":"15973#16066#16067#28541","text":"A central parallel parking area#A two-way left turn lane#A painted median for emergency stopping only#A high-occupancy vehicle (HOV) lane"},
{"testId":640,"qid":28427,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"Before turning left, you must yield to any pedestrians and incoming cars. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":640,"qid":28428,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Signaling, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202002181150078740.jpg","correctAnswer":14582,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":640,"qid":28429,"description":"Can you pass a school bus that has stopped to pick up students?","explanation":"A school bus that has stopped for passengers on either side of the road cannot be passed. You must stop until the bus's flashing red lights have been turned off and the stop signal arm has been retracted. You may proceed if the bus driver indicates you to do so. [School Buses, Special Stops, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202003051658529900.jpg","correctAnswer":28544,"isBookmarked":0,"aid":"28544#29342#29343#29344","text":"No, you must stop no matter which direction you are traveling in#Yes, but you must slow down to 15 mph and yield to pedestrians#Yes, but you must first stop and yield to any crossing pedestrians#No, you must stop if you are traveling in the same direction as the bus"},
{"testId":640,"qid":28430,"description":"Three vehicles arrive at a T-junction. In what order should they go?","explanation":"Cars on the through (main) route, like B and C in this instance, have the right of way at a T-intersection, thus Car A must yield to B and C. Car C must yield to Car B because cars turning left must give way to approaching traffic. As a result, the automobiles are in B, C, and A. [Right of Way, Chapter 4: Illinois Rules of the Road, Traffic Laws]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":640,"qid":28431,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending crossroads. Prepare for the junction by slowing down. [Warning Signs, Intersections Ahead, Chapter 9: Roadway Signs, Illinois Rules of the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":640,"qid":28432,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a crosswalk, you must always yield to a pedestrian. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":640,"qid":28433,"description":"When do you need to use your headlights?","explanation":"Between dusk and morning, you must use your headlights. When visibility goes below 1,000 feet and you need to use your windshield wipers, you must also activate your headlights. [Headlights, Required Equipment, Chapter 12: Safe Driving Equipment, Illinois Rules of the Road]","imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"15047#16068#22962#29345","text":"Whenever you need to use your windshield wipers#From sunset to sunrise#In all of the above situations#Whenever visibility falls below 1,000 feet"},
{"testId":640,"qid":28434,"description":"You come to a broken traffic light, where none of the lights are on. What do you need to do?","explanation":"If the traffic lights are out, consider the junction as an all-way stop. You must stop, yield, and advance only when it is safe to do so (with utmost care). [Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003051805205845.jpg","correctAnswer":28880,"isBookmarked":0,"aid":"28880#28881#29346#29347","text":"Treat the intersection as an all-way stop#Proceed through the intersection if you are on the busier road; otherwise yield#Treat the intersection as an uncontrolled intersection#Proceed through the intersection as if you had a green light"},
{"testId":640,"qid":28435,"description":"When are posted construction zone speed limits in effect?","explanation":"Construction zone speed limitations are in effect 24 hours a day, seven days a week, whether or not employees are present. Speed restrictions in construction zones may be enforced using radar or photography. [Construction and Maintenance Signs, Illinois Rules of the Road, Chapter 9: Roadway Signs]","imageName":"202002101222518967.jpg","correctAnswer":16071,"isBookmarked":0,"aid":"16071#16072#16073#29348","text":"Always#Only when workers are present#Only when workers or machinery are present#Only in the daytime"},
{"testId":640,"qid":28436,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to totally obscure another vehicle in the lane next to yours. Looking over your shoulder is the only method to check for these blind spots.","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":640,"qid":28437,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if you drive an automatic, choose a low gear to avoid brake wear. [Downgrade, Warning Signs, Chapter 9: Roadway Signs, Illinois Road Rules]","imageName":"202002101212538783.jpg","correctAnswer":28885,"isBookmarked":0,"aid":"28551#28552#28885#29349","text":"Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route#Steep descent ahead; proceed with caution#There is a runaway emergency truck ramp ahead"},
{"testId":640,"qid":28438,"description":"You can increase your night vision by using your high beams. But when should you dim your high beams?","explanation":"When you are within 300 feet of another vehicle or are within 500 feet of an incoming vehicle, you must decrease your high lights. [Headlights, Required Equipment, Chapter 12: Safe Driving Equipment, Illinois Rules of the Road]","imageName":"202002211503506612.jpg","correctAnswer":29350,"isBookmarked":0,"aid":"29350#29351#29352#29353","text":"When you are less than 500 feet from an oncoming vehicle or 300 feet behind another vehicle#When you are less than 300 feet from an oncoming vehicle or 500 feet behind another vehicle#When you are 300 feet from any vehicle#When you are 500 feet from any vehicle"},
{"testId":640,"qid":28439,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Signaling, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202002181152514351.jpg","correctAnswer":15572,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":640,"qid":28440,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"At all times, you should leave at least a three-second space. This is the recommended minimum following distance. When driving conditions are bad, you should increase your following distance. [Following Distances, Chapter 11: Safe Driving Hints, Illinois Road Rules]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15992#16038#19915#26842","text":"At least six seconds#At least three seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":640,"qid":28441,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus with red flashing lights or a stop signal arm. This restriction does not apply, however, if you are on the other side of a four-lane roadway. However, you should continue with care. [School Buses, Special Stops, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":640,"qid":28442,"description":"What does a yellow flashing traffic light indicate?","explanation":"When you see a flashing yellow light, you should slow down and continue with care. [Flashing Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26845#28890#29355","text":"Slow down and proceed with caution#The signal will soon turn red; stop if it is safe to do so#The signal will soon flash red; stop if it is safe to do so#Yield; treat this signal like a YIELD sign"},
{"testId":640,"qid":28443,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Right of Way, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202007120530048918.jpg","correctAnswer":26849,"isBookmarked":0,"aid":"25308#26849#26850#29356","text":"This is an uncontrolled intersection; one driver must wave the other one through#Car A must yield since it is turning left#Car B must yield since it is turning right#The car in the busier lane has the right of way"},
{"testId":640,"qid":28444,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This driver intends to make a left turn. [Signaling, Chapter 4: Traffic Laws, Illinois Road Rules]","imageName":"202002181155395841.jpg","correctAnswer":14579,"isBookmarked":0,"aid":"14579#14582#15572#15573","text":"Turn left#Turn right#Slow down or stop#Slow down to let traffic pass"},
{"testId":640,"qid":28445,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":"Leave an empty lane between your car and a stopped emergency or maintenance vehicle with flashing lights. Slow down and continue with care if this is harmful or not practicable. [Emergency/Maintenance Vehicles, Illinois Rules of the Road, Chapter 4: Traffic Laws]","imageName":"202001241703386758.jpg","correctAnswer":29358,"isBookmarked":0,"aid":"19471#29357#29358#29359","text":"Slow down and proceed with caution#Maintain your speed and proceed with caution#If possible, leave an empty lane between your vehicle and the emergency vehicle; otherwise, slow down#Slow down to 20 mph below the speed limit and pass with your emergency flashers on"},
{"testId":642,"qid":28446,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a red light. The word \"STOP\" is written in white letters on a red backdrop on a stop sign. You must come to a complete stop before approaching a crosswalk, stop line, or junction, and then yield to pedestrians and traffic before proceeding. [Iowa Driver's Manual, Standard Shapes, Traffic Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30392","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when it is safe to do so; no left or right turns are allowed here"},
{"testId":642,"qid":28447,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Iowa Driver's Manual, Speed Limit Signs, Regulation Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":642,"qid":28448,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"In Iowa, if the speed limit is 45 mph or below, you must signal for at least 100 feet. Where the speed limit exceeds 45 mph, you must signal for at least 300 feet. (Keep in mind that at 45 mph, your car will move 100 feet in 1.5 seconds.) It should also be noted that the legislation in certain other states vary. Always verify the local traffic rules while going out of state.) Even if you cannot see people or other cars, you must indicate. The most deadly car on the road may be one you don't see. [Iowa Driver's Manual, Turn Signals, Proper Turning Techniques, 3. Safe Driving Tips], [321.315, 2013 Iowa Code]","imageName":"202001291433312120.jpg","correctAnswer":30394,"isBookmarked":0,"aid":"29315#29975#30393#30394","text":"Yes, you must signal for at least 100 feet or four seconds in rural areas#No, you are only required to signal when other vehicles are present#No, turn signals are optional in Iowa#Yes, you must signal for 300 feet where the speed limit exceeds 45 mph, and 100 feet everywhere else"},
{"testId":642,"qid":28449,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"For uncontrolled junctions, there are two fundamental right-of-way rules: yield to your right and yield to approaching traffic while turning left. Car A must yield to its right here. As a result, Car B has the right-of-way. [Intersections, Right-of-Way Yielding, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":642,"qid":28450,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"When approaching a yield sign, slow down and ready to yield to pedestrians and oncoming vehicles. Be prepared to halt if necessary. [Iowa Driver's Manual, Yield Sign, Regulation Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#26797","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to traffic already in the intersection"},
{"testId":642,"qid":28451,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"When an emergency vehicle's flashing lights or siren is activated, you must move over to the right and let it to pass. Except for cars moving in the opposite direction on a split highway, vehicles traveling in both directions must pull over. [Iowa Driver's Manual, Emergency Vehicles, When to Yield the Right-of-Way, 2. Traffic Signs and Rules of the Road]","imageName":"202001302235285631.jpg","correctAnswer":30396,"isBookmarked":0,"aid":"15493#26798#30395#30396","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the right if you are traveling in the same direction as the emergency vehicle#Pull over to the right, no matter which direction you are traveling in"},
{"testId":642,"qid":28452,"description":"In Iowa, you can't park closer to a fire hydrant than __.","explanation":"Parking is prohibited within 5 feet of a fire hydrant in Iowa. (Note: In some states, parking is prohibited within 10 feet; in others, it is prohibited within 15 feet.) Always verify the local traffic rules while going out of state.) [No Parking, Parking, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202001290916213788.jpg","correctAnswer":1591,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":642,"qid":28453,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five-sided). [Iowa Driver's Manual, Standard Shapes, Traffic Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202003151728559496.jpg","correctAnswer":30401,"isBookmarked":0,"aid":"15824#15825#30401#30402","text":"A work zone sign#A warning sign#A school crossing sign#A No Passing Zone sign"},
{"testId":642,"qid":28454,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Green, Iowa Driver's Manual, Traffic Signals, 2. Traffic Signs and Rules of the Road]","imageName":"202003070857473536.jpg","correctAnswer":28852,"isBookmarked":0,"aid":"21234#28850#28851#28852","text":"Yes#No, you must stop#No, you must briefly stop and yield before proceeding#Yes, you can proceed after yielding to all traffic and pedestrians already in the intersection"},
{"testId":642,"qid":28455,"description":"Is it legal to turn right at a red light?","explanation":"On a red light, you are permitted to turn right. You must first come to a full stop and then yield to all pedestrians and vehicles. [Iowa Driver's Manual, Turns on Red, Proper Turning Techniques, 3. Safe Driving Tips]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#28853","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No, you can never turn right at a red signal"},
{"testId":642,"qid":28456,"description":"On Iowa's roads, who is required to wear a safety belt or child restraint system?","explanation":"According to Iowa law, the driver, all front-seat passengers, and all passengers under the age of 18 must use a safety belt or appropriate child restraint. It is, nevertheless, strongly advised that every passenger in the vehicle be properly strapped. [321.445, Iowa Code of 2013], [321.446, Iowa Code of 2013], [Seat Belts and Child Restraints, 5. Be in Shape to Drive, Iowa Driver's Manual]","imageName":"202003151755527296.jpg","correctAnswer":30405,"isBookmarked":0,"aid":"16091#30403#30404#30405","text":"The driver and every passenger under 18#No one (safety belts are optional in Iowa)#The driver and every front-seat passenger#The driver, every front-seat passenger, and every passenger under 18"},
{"testId":642,"qid":28457,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The vehicle on the left must give way to the vehicle on the right at an all-way stop. At this point, Car A must let Car B go. [Iowa Driver's Manual, Section 2. Traffic Signs and Rules of the Road, 2. When to Yield the Right-of-Way]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":642,"qid":28458,"description":"What is the legal speed limit in a business district in Iowa?","explanation":"Unless otherwise posted, the speed limit in a commercial district in Iowa is 20 mph. [Iowa Driver's Manual, Appropriate Speed, 3. Safe Driving Tips]","imageName":"202003151746465721.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#522#523#2429","text":"15 mph#25 mph#20 mph#10 mph"},
{"testId":642,"qid":28459,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Iowa Driver's Manual, 2. Traffic Signs and Rules of the Road]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":642,"qid":28460,"description":"What is the meaning of this sign?","explanation":"This is a slow-moving vehicle symbol that is displayed on the back of any vehicle with a maximum speed of 35 miles per hour or below. If you notice this symbol on a car ahead of you, you should slow down immediately. [2. Traffic Signs and Rules of the Road, Iowa Driver's Manual, Slow-Moving Vehicle Sign]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#15946#15948","text":"Stop#Slow-moving vehicle#Dead end#Road work zone"},
{"testId":642,"qid":28461,"description":"You're following a school bus with yellow lights that are flashing. Which of the following is true?","explanation":"When following a school bus with flashing yellow lights, you must reduce your speed to 20 mph or less and ready to stop. You are unable to pass the bus. The school bus will shortly stop for passengers, and you must stop no closer than 15 feet from it. [Iowa Driver's Manual, School Buses, 2. Traffic Signs and Rules of the Road]","imageName":"202003051644415234.jpg","correctAnswer":30409,"isBookmarked":0,"aid":"30407#30408#30409#30410","text":"This is your last chance to pass the bus before it stops for passengers#You cannot pass the bus; get ready to stop#You cannot pass the bus; you must slow down to 20 mph and prepare to stop#The bus is about to stop for passengers; you must stop immediately"},
{"testId":642,"qid":28462,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Yellow, Traffic Signals, 2. Traffic Signs and Road Rules, Iowa Driver's Manual]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#29322","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as a four-way stop"},
{"testId":642,"qid":28464,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Before beginning a turn in Utah and most other states, you must continually indicate. Keep both hands on the wheel as you approach and execute the maneuver for safety. [Utah Code 41-6a-804]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":642,"qid":28465,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This yellow sign alerts motorists that they have entered a no-passing zone. [Iowa Driver's Manual, Standard Shapes, Traffic Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":642,"qid":28466,"description":"You come to a railroad crossing where a gate is down and red lights are flashing. Where do you have to stop?","explanation":"When approaching a railroad crossing, halt between 15 and 50 feet from the closest rail. [321.341, Iowa Code of 2013]","imageName":"202003161136554337.jpg","correctAnswer":30413,"isBookmarked":0,"aid":"16052#30412#30413#30414","text":"Immediately in front of the gate#15 feet from the tracks#Between 15 and 50 feet from the tracks#Between 50 and 100 feet from the tracks"},
{"testId":642,"qid":28467,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"Even if you have a green light, you must yield to all cars and pedestrians currently at the junction. [Green, Iowa Driver's Manual, Traffic Signals, 2. Traffic Signs and Rules of the Road]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":642,"qid":28468,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction or crossing, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Iowa Driver's Manual, Flashing Red, Traffic Signals, 2. Traffic Signs and Rules of the Road]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#29331","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":642,"qid":28469,"description":"Which vehicles are making proper use of this roundabout?","explanation":"Both automobiles are following the rules of the roundabout. Car B is about to make a left turn. Car A seems to have missed its exit the first time, but its driver is doing the right thing by traveling all the way around the roundabout to try again. [Iowa Driver's Manual, Single-Lane Roundabout, Roundabouts, 3. Safe Driving Tips]","imageName":"1369316322_t1q40.v2.png","correctAnswer":15983,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":642,"qid":28470,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual] [Advance Warning Signs and Pavement Markings, Railroad Crossings, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":642,"qid":28471,"description":"Which of the following statement concerning legal parking is correct?","explanation":"Parking is not permitted within 10 feet of any stop sign, yield sign, or traffic signal. Parking is also prohibited within 50 feet of any railroad crossing. [No Parking, Parking, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"1369826678_t1q26.png","correctAnswer":30415,"isBookmarked":0,"aid":"30415#30416#30417#30418","text":"You cannot park within 50 feet of any railroad crossing or 10 feet of any stop sign#You cannot park within 10 feet of any railroad crossing or 50 feet of any stop sign#You cannot park within 10 feet of any railroad crossing or stop sign#You cannot park within 50 feet of any railroad crossing or stop sign"},
{"testId":642,"qid":28472,"description":"Which vehicles are turning left correctly here?","explanation":"Unless otherwise indicated, turn left from the left lane and right from the right lane. You should merge into the nearest lane of traffic traveling in the correct direction. Car A has made an erroneous turn into the far lane. Car B has made the proper turn into the nearest lane. [Iowa Driver's Manual, Left Turn, Proper Turning Techniques, 3. Safe Driving Tips]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":642,"qid":28473,"description":"Which of these is true about the speed limits in Iowa?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a speed that does not obstruct the regular flow of legitimate traffic. When driving conditions are terrible, you must slow down below the speed limit. [Iowa Driver's Manual, Appropriate Speed, 3. Safe Driving Tips]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30419#30420#30421","text":"All of the above are true#You must not exceed the posted speed limit#You must not drive so slowly as to impede the flow of traffic#You should drive at the posted speed limit only under safe conditions"},
{"testId":642,"qid":28474,"description":"Is it permissible to turn left at a red signal?","explanation":"Turning left on a red light from the left lane of a one-way street into another one-way street is legal in Iowa. You must come to a full stop before making the left turn and then yield to pedestrians and oncoming cars. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.) [Iowa Driver's Manual, Turns on Red, Proper Turning Techniques, 3. Safe Driving Tips]","imageName":"202003112014364009.jpg","correctAnswer":26820,"isBookmarked":0,"aid":"25318#26820#29340#30422","text":"Yes, unless it is prohibited by a sign#Yes, but only from a one-way street onto another one-way street#Yes, but only onto a one-way street from any other street#No, not in Iowa"},
{"testId":642,"qid":28475,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. [Beginning of a Divided Highway, Warning Signs, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15560#15561#15668#30423","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#There is a divided highway ahead#There is a shared center turning lane ahead"},
{"testId":642,"qid":28476,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a two-way left-turn lane with a shared center turning lane. Vehicles moving in either direction may perform a left turn (or, if allowed, a U-turn) from this lane. Proceed with care while turning left here; keep an eye out for approaching vehicles. [Iowa Driver's Manual, Shared Center Lane, Pavement Markings and Other Lane Controls, 2. Traffic Signs and Rules of the Road]","imageName":"202001301806403967.jpg","correctAnswer":28872,"isBookmarked":0,"aid":"15973#16067#28872#30411","text":"A central parallel parking area#A painted median for emergency stopping only#A shared center turning lane#A High-Occupancy Vehicle (HOV) lane"},
{"testId":642,"qid":28477,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"Before turning left, you must yield to any pedestrians and incoming cars. [Intersections, Right-of-Way Yielding, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":642,"qid":28478,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Hand Signals, 3. Driving Safety Tips, Iowa Driver's Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":642,"qid":28479,"description":"What do you have to do when a school bus with flashing red lights stops in front of you?","explanation":"A school bus will flash its red lights or extend its stop arm to alert drivers that it is stopping for passengers. You must halt at least 15 feet away from a school bus that has stopped for passengers, regardless of which direction the vehicle is headed. [Iowa Driver's Manual, School Buses, 2. Traffic Signs and Rules of the Road]","imageName":"202003051658529900.jpg","correctAnswer":29996,"isBookmarked":0,"aid":"29995#29996#30424#30425","text":"You must stop if you are traveling in the same direction as the bus#You must stop, no matter which direction you are traveling in#You must slow down to 15 mph and yield to pedestrians#You must pass the bus quickly"},
{"testId":642,"qid":28480,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at an all-way stop at various times must continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [Intersections, Right-of-Way Yielding, 2. Traffic Signs and Rules of the Road, Iowa Driver's Manual]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":642,"qid":28481,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Iowa Driver's Manual, Intersection/Crossroad, Warning Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":642,"qid":28482,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. In a marked or unmarked crossing, you must always yield to a pedestrian. [Iowa Driver's Manual, Intersections, When to Yield the Right-of-Way, 2. Traffic Signs and Rules of the Road], [321.257a, 2013 Iowa Code]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":642,"qid":28483,"description":"What is the meaning of this sign?","explanation":"This symbol denotes a service. The letter \"H\" symbolizes a local hospital. [Iowa Driver's Manual, Service Signs, 2. Traffic Signs and Rules of the Road]","imageName":"202003151737026825.jpg","correctAnswer":9573,"isBookmarked":0,"aid":"9573#29218#29219#29220","text":"A hospital#A heliport#A help line#A health center"},
{"testId":642,"qid":28484,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"At each junction, you must always obey all signs and surface markings. You should normally turn left from the left lane, right from the right lane, and drive straight through using either lane. Car C has made an improper left turn from the right lane. [Roundabouts, 3. Driving Safety Tips, Iowa Driver's Manual]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"897#14814#14815#14862","text":"None of the above#Car A#Car B#Car C"},
{"testId":642,"qid":28486,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to totally obscure another vehicle in the lane next to yours. Looking over your shoulder is the only method to check for these blind spots. [Changing Lanes, 3. Driving Safety Tips, Iowa Driver's Manual]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":642,"qid":28487,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if you drive an automatic, choose a low gear to avoid brake wear. [Hill, Traffic Signs and Rules of the Road, Iowa Driver's Manual, Warning Signs]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#28552#29349","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route#There is a runaway emergency truck ramp ahead"},
{"testId":642,"qid":28488,"description":"You can increase your night vision by using your high beams. But when should you dim your high beams?","explanation":"When you are within 400 feet of the vehicle you are following or 1,000 feet of an incoming vehicle, you must decrease your high lights. [Night Driving, 3. Tips for Safe Driving, Iowa Driver's Manual]","imageName":"202002211503506612.jpg","correctAnswer":30430,"isBookmarked":0,"aid":"28889#30430#30431#30432","text":"When you are within 500 feet of any vehicle#When you are within 1,000 feet of an oncoming vehicle or less than 400 feet behind another vehicle#When you are within 400 feet of an oncoming vehicle or less than 1,000 feet behind another vehicle#When you are within 1,000 feet of any vehicle"},
{"testId":642,"qid":28489,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist plans to slow down or come to a complete stop. [Hand Signals, 3. Driving Safety Tips, Iowa Driver's Manual]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#22345#25325","text":"Turn left#Turn right#slow down or stop#slow down to let traffic pass"},
{"testId":642,"qid":28490,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"The \"two-second rule\" dictates that you must not follow another vehicle within two seconds. This is the shortest following distance possible under perfect driving circumstances. If the driving conditions deteriorate, you should increase your following distance. [Iowa Driver's Manual, Following Another Vehicle, Driving Safely in Traffic, 3. Safe Driving Tips]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#15992#19915#26842","text":"At least two seconds#At least six seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":642,"qid":28491,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus with red flashing lights or a stop arm. This restriction does not apply, however, if you are on the other side of a four-lane roadway. [Iowa Driver's Manual, School Buses, 2. Traffic Signs and Rules of the Road]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":642,"qid":28492,"description":"What does a yellow flashing traffic light indicate?","explanation":"You must slow down, give way to oncoming vehicles and pedestrians, and continue cautiously while a flashing yellow light is present. [Iowa Driver's Manual, Flashing Yellow, Traffic Signals, 2. Traffic Signs and Rules of the Road]","imageName":"202003070823014609.jpg","correctAnswer":30433,"isBookmarked":0,"aid":"29961#30433#30434#30435","text":"Stop if it is safe to do so; the signal will soon turn red#Slow down, yield, and proceed with caution#Proceed with caution at your usual speed#Proceed straight through with caution; no left or right turns are allowed here"},
{"testId":642,"qid":28493,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"A automobile turning left must yield to approaching traffic and pedestrians. Car A must yield in this situation. [Intersections, Yielding the Right-of-Way, 2. Iowa Driver's Manual, Traffic Signs and Rules of the Road]","imageName":"202007120530048918.jpg","correctAnswer":26849,"isBookmarked":0,"aid":"25308#26849#26850#28558","text":"This is an uncontrolled intersection; one driver must wave the other one through#Car A must yield since it is turning left#Car B must yield since it is turning right#The car in the busier lane has the right-of-way"},
{"testId":642,"qid":28494,"description":"What is the significance of this hand signal?","explanation":"This motorist wants to turn left. [Hand Signals, 3. Driving Safety Tips, Iowa Driver's Manual]","imageName":"202002181155395841.jpg","correctAnswer":28559,"isBookmarked":0,"aid":"28559#28560#28561#28562","text":"The driver is about to turn left#The driver is about to turn right#The driver is about to slow down or stop#Traffic may pass safely now"},
{"testId":642,"qid":28495,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":"Allow an empty lane between you and a halted emergency vehicle with flashing lights. Slow down to a reasonable speed and ready to stop if this is banned, hazardous, or otherwise impossible. [Iowa Driver's Manual, Approaching Stationary Emergency or Maintenance Vehicles, 2. Traffic Signs and Rules of the Road]","imageName":"202001241703386758.jpg","correctAnswer":30436,"isBookmarked":0,"aid":"29359#30436#31890#31891","text":"Slow down to 20 mph below the speed limit and pass with your emergency flashers on#Leave a vacant lane next to the emergency vehicle if possible; otherwise, prepare to stop#Speed up and pass as quickly as possible#Continue with the same speed"},
{"testId":643,"qid":28496,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop behind the stop line or crosswalk if there is one at a stop sign. Then concede and advance only if it is safe to do so. [Stop Sign, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202003151731031579.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#28148#30729","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Proceed with caution#Proceed straight through when safe; no turns are allowed here"},
{"testId":643,"qid":28497,"description":"What is the meaning of this sign?","explanation":"This regulatory sign indicates that the legal speed limit is 50 miles per hour. That means, you are not permitted to exceed 50 mph in this area. [Regulatory Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":643,"qid":28498,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You must signal for at least 100 feet or 3 to 5 seconds before turning in metropolitan areas at speeds up to 40 mph. In all other cases, you must indicate for at least 200 feet (3 to 5 seconds) before making a turn. Even if you cannot see people or other cars, you must indicate. The most deadly car on the road may be one you don't see. (Note: Some states have differing laws on this.) Always verify the local traffic rules while going out of state.) [Communication, Kansas Driving Handbook, Chapter 7: Safe Driving Tips]","imageName":"202001291433312120.jpg","correctAnswer":30732,"isBookmarked":0,"aid":"29975#30730#30731#30732","text":"No, you are only required to signal when other vehicles are present#No, turn signals are optional in Kansas#Yes, you must signal for at least 50 feet before you turn#Yes, in urban areas at speeds up to 40 mph, you must signal for at least 100 feet before you turn and for at least 200 feet otherwise"},
{"testId":643,"qid":28499,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"For uncontrolled junctions, there are two main right-of-way rules: Yield to the right and to approaching traffic while turning left. Car A must yield to its right here. As a result, Car B has the right-of-way. [Right-of-Way, Section 6: Road Rules, Kansas Driving Handbook]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":643,"qid":28500,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"When approaching a yield sign, slow down and ready to yield to pedestrians and oncoming vehicles. Be prepared to halt if necessary. [Yield Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15489#25283#26797","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield if necessary, and then proceed#Maintain your speed since all traffic ahead must yield to you#Slow down and prepare to yield to traffic already in the intersection"},
{"testId":643,"qid":28501,"description":"You notice an ambulance approaching with flashing lights. What should you do?","explanation":"When an emergency vehicle's flashing lights or siren is activated, you must move over to the right and let it to pass. Vehicles moving in both directions must pull over. [Kansas Driving Handbook, Section 9: Sharing the Road, Emergency Vehicles]","imageName":"202001302235285631.jpg","correctAnswer":30396,"isBookmarked":0,"aid":"15493#26798#30395#30396","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the right if you are traveling in the same direction as the emergency vehicle#Pull over to the right, no matter which direction you are traveling in"},
{"testId":643,"qid":28502,"description":"How near to a fire hydrant can you park?","explanation":"Parking is prohibited within 15 feet of a fire hydrant in Kansas. (Note: In some states, parking is prohibited within 5 feet; in others, it is prohibited within 10 feet.) Always verify the local traffic rules while going out of state.) [Kansas Driving Handbook, Section 2: State/Provincial/Territory Laws and Rules of the Road]","imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#1591#8416#30400","text":"10 feet#5 feet#15 feet#As close as you wish"},
{"testId":643,"qid":28503,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and school crossing signs (five sided). [Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202003151728559496.jpg","correctAnswer":30401,"isBookmarked":0,"aid":"15824#15825#30401#30402","text":"A work zone sign#A warning sign#A school crossing sign#A No Passing Zone sign"},
{"testId":643,"qid":28504,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202003070857473536.jpg","correctAnswer":28852,"isBookmarked":0,"aid":"21234#28850#28852#30733","text":"Yes#No, you must stop#Yes, you can proceed after yielding to all traffic and pedestrians already in the intersection#Yes, but only after you briefly stop and yield"},
{"testId":643,"qid":28505,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. You must first come to a full stop and then yield to all pedestrians and vehicles. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#30734","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No. In Kansas, you cannot turn right at a red signal"},
{"testId":643,"qid":28506,"description":"Who is required to wear a seat belt (or child restraint)?","explanation":"According to Kansas law, every passenger, including the driver, must use an appropriate seat belt or child restraint. [Kansas Driving Handbook, Section 2: State/Provincial/Territory Laws and Rules of the Road, Seat Belts/Child Restraints]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"16007#16091#30405#30735","text":"The driver and every passenger#The driver and every passenger under 18#The driver, every front-seat passenger, and every passenger under 18#No one (safety belts are optional in Kansas)"},
{"testId":643,"qid":28507,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The vehicle on the left must give way to the vehicle on the right at an all-way stop. At this point, Car A must let Car B go. [Right-of-Way, Section 6: Kansas Driver's Handbook, Rules of the Road]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":643,"qid":28508,"description":"What is the legal speed limit in Kansas's urban areas?","explanation":"Unless otherwise posted, the speed limit in Kansas' urban areas is 30 mph. [Kansas Driving Handbook, Section 2: State/Provincial/Territory Laws and Rules of the Road]","imageName":"202003151746465721.jpg","correctAnswer":521,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":643,"qid":28509,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking with a Curb Uphill, Parking, Section 6: Rules of the Road, Kansas Driving Handbook]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":643,"qid":28510,"description":"What is the meaning of this sign?","explanation":"This is a slow-moving vehicle insignia that is displayed on the back of any vehicle with a peak speed of less than 25 miles per hour. If you notice this symbol on a car in front of you, you should slow down. [Slow Moving Vehicles, Kansas Driving Handbook, Section 9: Sharing the Road]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#15946#15948","text":"Stop#Slow-moving vehicle#Dead end#Road work zone"},
{"testId":643,"qid":28511,"description":"In a business district, you are about to turn from an alley onto a road. What do you have to do before you can go?","explanation":"Before entering a road from an alley, driveway, or building, you must stop and yield. This guideline is applicable in any commercial or residential area. [Stopping, Kansas Driving Handbook, Section 2: State/Provincial/Territory Laws and Rules of the Road]","imageName":"202001291438598251.jpg","correctAnswer":30737,"isBookmarked":0,"aid":"23465#30736#30737#30738","text":"Yield to traffic#Yield to traffic and pedestrians#Stop and yield to traffic and pedestrians#Sound your horn and proceed when it is safe to do so"},
{"testId":643,"qid":28512,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#28880","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop"},
{"testId":643,"qid":28513,"description":"A pedestrian with a white cane steps out onto the road ahead of you. What should you do?","explanation":"A walker using a white cane or accompanied by a guiding dog is most likely sight impaired. Pedestrians who are blind or visually challenged always have the right of way. If a blind pedestrian enters the road, you must come to a full stop and stay halted until the person is safely off the road. You should not blow your horn since it may frighten the person or the guiding dog. [Kansas Driving Handbook, Section 6: Rules of the Road, Pedestrians]","imageName":"202002061105552801.jpg","correctAnswer":30740,"isBookmarked":0,"aid":"30739#30740#30741#30742","text":"Sound your horn to warn the pedestrian#Come to a complete stop. Proceed only after the pedestrian is off the road#Come to a complete stop. Proceed when the pedestrian vacates your lane#Drive around the pedestrian"},
{"testId":643,"qid":28514,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"ABS letters are not needed to be shown on the steering wheel or anywhere else in a vehicle. Because it might be difficult to detect whether a car has ABS, you should know how to look for wheel speed sensor cables coming from the rear of the brakes.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":643,"qid":28515,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This yellow sign alerts motorists that they have entered a no-passing zone. [Kansas Driving Handbook, Section 6: Rules of the Road, No Passing Zone]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":643,"qid":28516,"description":"You come to a railroad crossing where a gate is down and red lights are flashing. Where do you have to stop?","explanation":"When approaching a railroad crossing, halt between 15 and 50 feet from the closest rail. [K.S.A. 8-1551]","imageName":"202003161136554337.jpg","correctAnswer":30413,"isBookmarked":0,"aid":"16052#30412#30413#30414","text":"Immediately in front of the gate#15 feet from the tracks#Between 15 and 50 feet from the tracks#Between 50 and 100 feet from the tracks"},
{"testId":643,"qid":28517,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"Even if you have a green light, you must yield to all cars and pedestrians currently at the junction. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":643,"qid":28518,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction or crossing, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#29331","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":643,"qid":28520,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. [Railroad Crossing Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":643,"qid":28521,"description":"Which of the following statement concerning legal parking is correct?","explanation":"Parking is not permitted within 30 feet of any traffic control signal or stop sign. At a railroad crossing, you also cannot park within 50 feet of the closest rail. [Kansas Driving Handbook, Section 2: State/Provincial/Territory Laws and Rules of the Road]","imageName":"1369826678_t1q26.png","correctAnswer":30743,"isBookmarked":0,"aid":"30418#30743#30744#30745","text":"You cannot park within 50 feet of any railroad crossing or stop sign#You cannot park within 50 feet of any railroad crossing or 30 feet of any stop sign#You cannot park within 30 feet of any railroad crossing or 50 feet of any stop sign#You cannot park within 30 feet of any railroad crossing or stop sign"},
{"testId":643,"qid":28522,"description":"Which of the following statement concerning hydroplaning is correct?","explanation":"Even if you have decent tires, your car may begin to hydroplane at speeds as low as 35 mph. (At speeds as low as 25 mph, poorly worn tires might cause the car to hydroplane.) Avoid braking if your vehicle begins to hydroplane; instead, let the automobile slow down on its own. On rainy roads, avoid using your cruise control. If your car loses traction, the cruise control may try to maintain a steady speed by spinning the wheels. This may exacerbate or even cause a skid. [Adjusting to Roadway Conditions, Speed Management, Kansas Driving Handbook, Section 7: Safe Driving Tips]","imageName":"202002061923072507.jpg","correctAnswer":30746,"isBookmarked":0,"aid":"30746#30747#30748#30749","text":"Hydroplaning can start at speeds as low as 35 mph#The condition of your tires does not affect your likelihood of hydroplaning#If you hydroplane, you should steer straight and brake firmly#Cruise control is safe to use on wet roads"},
{"testId":643,"qid":28523,"description":"Which of the following is true about the speed limits in Kansas?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a speed that does not obstruct the regular flow of legitimate traffic. When driving conditions are terrible, you must slow down below the speed limit. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signs]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30419#30420#30421","text":"All of the above are true#You must not exceed the posted speed limit#You must not drive so slowly as to impede the flow of traffic#You should drive at the posted speed limit only under safe conditions"},
{"testId":643,"qid":28524,"description":"Is it legal to turn left at a red light?","explanation":"Turning left on a red light from a one-way street into another one-way street is legal in Kansas, unless signage forbid it. You must come to a full stop before making the left turn and then yield to pedestrians and oncoming cars. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.) [K.S.A. 8-1508]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"25289#30750#30751#30752","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only from a one-way street#Yes, but only when turning from a one-way street onto another one-way street#No, not in Kansas"},
{"testId":643,"qid":28525,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. [Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15560#15561#15668#30423","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#There is a divided highway ahead#There is a shared center turning lane ahead"},
{"testId":643,"qid":28526,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a shared center turning lane with a two-way left turn lane. This lane allows vehicles driving in either direction to make a left turn. Proceed with care and keep an eye out for oncoming vehicles while turning left here. This lane should never be used for passing. [Other Lane Controls, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202001301806403967.jpg","correctAnswer":30753,"isBookmarked":0,"aid":"15973#16067#30411#30753","text":"A central parallel parking area#A painted median for emergency stopping only#A High-Occupancy Vehicle (HOV) lane#A two-way left-turn lane"},
{"testId":643,"qid":28527,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"Before turning left, you must yield to any pedestrians and incoming cars. [Right-of-Way, Section 6: Road Rules, Kansas Driving Handbook]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":643,"qid":28528,"description":"Can you signal with hand signals instead of turn signals in Kansas?","explanation":"If your vehicle is equipped with turn signals, you must use them to indicate a turn. Certain automobiles, such as those produced before 1953, are not required by law to have turn signals. Hand signals may be used while driving such a vehicle. The motorist in the image is indicating a right turn. [K.S.A. 8-1549-8-1550-8-1708]","imageName":"202002181150078740.jpg","correctAnswer":30754,"isBookmarked":0,"aid":"18118#21234#30754#30755","text":"No#Yes#Yes, but only when you are driving a vehicle that is legally exempt from having turn signals#Yes, but only during daylight hours when visibility is more than 1,000 feet"},
{"testId":643,"qid":28529,"description":"A school bus with flashing red lights stops ahead of you. What should you do?","explanation":"You are not permitted to pass a school bus that has stopped for passengers, turned on flashing red lights, or extended its stop arm. This guideline applies regardless of which way the bus is going. [K.S.A. 8-1556; Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202003051658529900.jpg","correctAnswer":29996,"isBookmarked":0,"aid":"29994#29995#29996#30424","text":"You must come to a complete stop and pass at 20 mph or less once there are no children on the road#You must stop if you are traveling in the same direction as the bus#You must stop, no matter which direction you are traveling in#You must slow down to 15 mph and yield to pedestrians"},
{"testId":643,"qid":28530,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [Right-of-Way, Section 6: Road Rules, Kansas Driving Handbook]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":643,"qid":28531,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":643,"qid":28532,"description":"On a red light, you are turning right. Simultaneously, a pedestrian begins to cross the road you are going to enter. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. [Kansas Driving Handbook, Section 6: Rules of the Road, Traffic Signals]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":643,"qid":28533,"description":"Which of the statements below is false?","explanation":"When visibility is poor at 1,000 feet and from dusk until daybreak, you must use your headlights. You must not drive with just your parking lights on. [K.S.A. 8-1703]","imageName":"202003161149128995.jpg","correctAnswer":30757,"isBookmarked":0,"aid":"30154#30756#30757#30758","text":"You must use your headlights between sunset and sunrise#You must use your headlights whenever visibility is difficult at 1,000 feet#You can drive with just your parking lights on to make your car more visible during the day#You must use your headlights whenever you use your windshield wipers in bad weather"},
{"testId":643,"qid":28534,"description":"Which vehicle is not properly using the roundabout?","explanation":"In a multilane roundabout or any other form of crossroads, you must always obey traffic signs and pavement markings and stay in the proper lane. Turn left from the left lane, right from the right lane, and drive straight through utilizing either lane in a two-lane roundabout. Car C has made an improper left turn from the right lane. [Other Lane Controls, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"897#14814#14815#14862","text":"None of the above#Car A#Car B#Car C"},
{"testId":643,"qid":28535,"description":"What is the meaning of this sign?","explanation":"This sign advises that a small bridge is ahead. Slow down and take your time. [Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202002101245562248.jpg","correctAnswer":28073,"isBookmarked":0,"aid":"28073#29971#29973#31819","text":"There is a narrow bridge ahead#There is a tunnel ahead#The river crossing ahead is prone to flooding#There is an overpass ahead"},
{"testId":643,"qid":28536,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to totally obscure another vehicle in the lane next to yours. Looking over your shoulder is the only method to check for these blind spots. [Visual Search, Kansas Driving Handbook, Section 7: Safe Driving Tips]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":643,"qid":28537,"description":"What is the meaning of this sign?","explanation":"A severe fall is ahead. Keep an eye out for falling vehicles like trucks. Even if you drive an automatic, choose a low gear to avoid brake wear. [Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road; and Visual Search, Kansas Driving Handbook, Section 7: Safe Driving Tips]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#28552#30759","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route#There is an emergency runaway truck ramp ahead"},
{"testId":643,"qid":28538,"description":"When driving at night, high beams might help you see better. But when is it necessary to dim the lights?","explanation":"When you are within 500 feet of an approaching vehicle, you must decrease your high lights. When you are fewer than 300 feet behind another vehicle, you must also reduce your high lights. High beams should not be used in well-lit areas. [K.S.A. 8-1725]","imageName":"202002211503506612.jpg","correctAnswer":30760,"isBookmarked":0,"aid":"29352#29353#30760#30761","text":"When you are 300 feet from any vehicle#When you are 500 feet from any vehicle#When you are within 500 feet of an oncoming vehicle, or less than 300 feet behind another vehicle#When you are within 300 feet of an oncoming vehicle, or less than 500 feet behind another vehicle"},
{"testId":643,"qid":28539,"description":"At a railroad crossing, which of the following must you do?","explanation":"You must come to a complete stop at a flashing red railroad crossing signal and continue only after the lights have stopped blinking. You must not cross while the crossing gate is down; instead, wait for it to fully raise before proceeding. Before crossing the rails, shift into the proper gear. Shifting gears while driving on the racetrack may cause your car to stall. [Railroad Crossing Warning Signs, Traffic Signs, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202002101119357461.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"23000#30762#30763#30764","text":"Do all of the above#Stop at a flashing signal and wait for the lights to stop flashing before you proceed#Wait for the crossing gate to rise completely before you proceed#Avoid shifting gears while crossing the tracks"},
{"testId":643,"qid":28540,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"The \"two-second rule\" dictates that you must not follow another vehicle within two seconds. Under perfect circumstances, this is the shortest distance to follow. When driving conditions deteriorate, use the \"four-second rule.\" [Kansas Driving Handbook, Section 7: Safe Driving Tips, Space Management]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#15992#19915#26842","text":"At least two seconds#At least six seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":643,"qid":28541,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop for a school bus with red flashing lights or a stop arm. This law, however, does not apply if you are on the other side of a split highway. It should be noted that neither a painted median nor a shared center turning lane (also known as a two-way left-turn lane) is considered a divider for this purpose. [K.S.A. 8-1556; Other Lane Controls, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":643,"qid":28542,"description":"What should you do if you see a flashing yellow light?","explanation":"You must slow down, give way to oncoming vehicles and pedestrians, and continue cautiously while a flashing yellow light is present. (From Kansas Driving Handbook, Traffic Signals, Section 6: Rules of the Road)","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30157#30765","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here#Stop if safe; the signal will soon turn red"},
{"testId":643,"qid":28543,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Cars entering a public roadway or highway from a private road or driveway must stop and yield to vehicles on a public street or highway.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way"},
{"testId":643,"qid":28544,"description":"You're travelling on a highway with a speed restriction of 65 miles per hour. It starts to rain heavily. How fast should you drive?","explanation":"For ideal circumstances, speed restrictions are posted. However, you must always drive at a safe and fair pace for the present road conditions. You might be penalized for driving too quickly for the conditions. In standing water, your car may begin to hydroplane at speeds more than 35 mph. As a result, amid severe rain, you should slow down. [Speed Limit Signs, Regulatory Signs, Kansas Driving Handbook, Section 6: Rules of the Road; Adjusting to Roadway Conditions, Speed Management, Kansas Driving Handbook, Section 7: Safe Driving Tips]","imageName":"202002061923072507.jpg","correctAnswer":30766,"isBookmarked":0,"aid":"684#1676#2135#30766","text":"65 mph#35 mph#50 mph#A speed that is safe and reasonable"},
{"testId":643,"qid":28545,"description":"On a four-lane highway, you are on the right lane. You see an emergency vehicle stopped with its lights flashing in front of you. What should you do?","explanation":"You must leave an empty lane next to any stopped emergency vehicle with flashing lights. If changing lanes is dangerous or impossible, you must slow down and drive cautiously. [Kansas Driving Handbook, Section 9: Sharing the Road, Emergency Vehicles]","imageName":"202001241703386758.jpg","correctAnswer":30004,"isBookmarked":0,"aid":"30003#30004#30005#30006","text":"Maintain your speed but proceed with caution#Leave an empty lane between your vehicle and the emergency vehicle if you can do so safely. Otherwise, slow down and proceed with caution#Slow down and prepare to stop#Leave an empty lane between your vehicle and the emergency vehicle and reduce your speed by 20 mph"},
{"testId":645,"qid":28546,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop behind the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003151731031579.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#28148#30729","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Proceed with caution#Proceed straight through when safe; no turns are allowed here"},
{"testId":645,"qid":28547,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":645,"qid":28548,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31403#31404","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#No, turn signals are optional in Louisiana#Yes, you must signal for at least 100 feet before you turn"},
{"testId":645,"qid":28549,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield here, and Car B has the right of way. [Right-of-Way, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:121; RS 32:122]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":645,"qid":28550,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Slow down as you approach a yield sign and ready to yield to pedestrians and traffic ahead. Be prepared to halt if necessary. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#15627#31033#31405","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Maintain your speed as all traffic ahead must yield to you#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed"},
{"testId":645,"qid":28551,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must stop regardless of which way the emergency vehicle is heading. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over immediately. [Right-of-Way, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202001302235285631.jpg","correctAnswer":31407,"isBookmarked":0,"aid":"15493#26798#31406#31407","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the right if you are traveling in the same direction as the ambulance#Pull over to the right, regardless of which direction you are traveling in"},
{"testId":645,"qid":28552,"description":"Where is it legal to park?","explanation":"You are not permitted to park on the sidewalk, a bridge, or within 15 feet of a fire hydrant. When parallel parking, keep your right wheels within 18 inches of the right-hand curb. As a result, only the vehicle in Figure D is lawfully parked. [Parking, Louisiana Class D & E Driver's Guide, Chapter 5: Safe Driving Habits]","imageName":"202003241433015085.jpg","correctAnswer":16166,"isBookmarked":0,"aid":"15951#15952#16166#29321","text":"B) On a bridge#C) On the sidewalk#D) 12 inches from the curb#A) 10 feet from a fire hydrant"},
{"testId":645,"qid":28553,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and school crossing signs (five sided). [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#25354","text":"A school zone sign#A work zone sign#A warning sign#A no-passing sign"},
{"testId":645,"qid":28554,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":31408,"isBookmarked":0,"aid":"21234#28850#30733#31408","text":"Yes#No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after yielding to all traffic and pedestrians already in the intersection"},
{"testId":645,"qid":28555,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. You must first come to a full stop and then yield to all pedestrians and vehicles. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#31409","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No. In Louisiana, you cannot turn right at a red signal"},
{"testId":645,"qid":28556,"description":"Who is required to wear a seat belt (or child restraint)?","explanation":"According to Louisiana law, every passenger, including the driver, must use an appropriate seat belt or child restraint. [RS 32:295.1; RS 32:295]","imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"16007#31410#31411#31412","text":"The driver and every passenger#No one (safety belts are optional in Louisiana)#The driver and every passenger under 13 years of age#The driver, every front-seat passenger, and every passenger under 13 years of age"},
{"testId":645,"qid":28557,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The vehicle on the left must give way to the vehicle on the right at an all-way stop. At this point, Car A must let Car B go. [Right-of-Way, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:123]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":645,"qid":28558,"description":"Which of the following is true about the right-of-way rules in Louisiana?","explanation":"Louisiana law specifies who must surrender the right-of-way to another road user, but it does not provide the right-of-way to anybody. If pedestrians or other cars are not obeying the regulations, cede the right-of-way to them rather than risking a collision. [Right-of-Way, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202007120454477868.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31413#31414#31415#31416","text":"You should claim the right-of-way whenever you safely can#The right-of-way rules are optional#You can go first whenever you have the right-of-way#The right-of-way is to be given, not taken"},
{"testId":645,"qid":28559,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your front wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Chapter 4: Basic Driving Safety, Louisiana Class D & E Driver's Guide, Parking on Hills]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":645,"qid":28560,"description":"What is the meaning of this sign?","explanation":"This sign alerts vehicles that the bridge ahead is small. Slow down and take your time. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202002101245562248.jpg","correctAnswer":28073,"isBookmarked":0,"aid":"15582#28073#31417#31418","text":"There is a low bridge ahead#There is a narrow bridge ahead#There is no shoulder on the road ahead#The bridge or causeway ahead is prone to flooding"},
{"testId":645,"qid":28561,"description":"You notice a road with red reflectors down the center. What exactly are these red reflectors mean?","explanation":"Red reflectors indicate that the highway is restricted to cars traveling in your direction. In other words, if you continue driving on this route, you will be travelling in the other direction. As a result, do not enter here. These reflectors will most likely display clear, yellow, or white to cars traveling in the proper direction (i.e., in the opposite direction). [Lane Markings, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202004281820399643.jpg","correctAnswer":31421,"isBookmarked":0,"aid":"15550#31419#31420#31421","text":"There is a railroad crossing ahead#This route is for emergency vehicles only#This route is for trucks and buses only#Do not enter"},
{"testId":645,"qid":28562,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#28880","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop"},
{"testId":645,"qid":28563,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"Trucks have extensive blind areas, dubbed No-Zones. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. But Car B is unlikely to be seen to the truck driver. Remember, if you can't see the truck driver, he or she probably can't see you. [Sharing the Road with Big Trucks, Chapter 5: Safe Driving Habits, Louisiana Class D & E Driver's Guide]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":645,"qid":28564,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"Vehicle registrations and license plates must be renewed annually or biennially, on or before the birthdate of the first owner specified on the registration form.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":645,"qid":28565,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This sign indicates that passing other cars is not permitted here. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":645,"qid":28566,"description":"You come to a railroad crossing where a gate is down and red lights are flashing. Where do you have to stop?","explanation":"You must stop between 15 and 50 feet from the closest rail while approaching a railroad crossing. [Railroad Grade Crossing, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202003161136554337.jpg","correctAnswer":30413,"isBookmarked":0,"aid":"16052#30412#30413#30414","text":"Immediately in front of the gate#15 feet from the tracks#Between 15 and 50 feet from the tracks#Between 50 and 100 feet from the tracks"},
{"testId":645,"qid":28567,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"Even if you have a green light, you must yield to all cars and pedestrians currently at the junction. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":645,"qid":28568,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#29331","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through; you cannot turn left or right at a flashing red signal"},
{"testId":645,"qid":28569,"description":"This driver is signaling with his hand. What is the driver's intention in this situation?","explanation":"This motorist plans to slow down or come to a complete stop. This hand gesture is analogous to a vehicle's brake lights. [Signaling, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002181152514351.jpg","correctAnswer":31425,"isBookmarked":0,"aid":"31423#31424#31425#31426","text":"The driver intends to turn left#The driver intends to turn right#The driver intends to slow down or stop#The driver is letting us know that it is safe to pass him or her"},
{"testId":645,"qid":28570,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":645,"qid":28571,"description":"Parking is not permitted within _______ feet of a railroad crossing.","explanation":"You are not permitted to park within 50 feet of a railroad crossing. [Parking, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"214#1026#1027#8416","text":"100 feet#50 feet#25 feet#15 feet"},
{"testId":645,"qid":28572,"description":"Which of the following statement concerning hydroplaning is correct?","explanation":"When you drive too rapidly over standing water, you essentially ski over its surface. Even if you have decent tires, your car may begin to hydroplane at speeds as low as 35 mph. (At speeds as low as 25 mph, poorly worn tires might cause the car to hydroplane.) This might cause your automobile to slide. Your tires may lose all contact with the road at 55 mph, leading in complete loss of control. If you hydroplane, try not to accelerate, steer, or brake; instead, let the automobile slow down on its own. Do not use your cruise control on wet roads. If your car loses traction, the cruise control may try to maintain a steady speed by spinning the wheels. This may exacerbate or even cause a skid. [Hydroplaning, Louisiana Class D & E Driver's Guide, Chapter 4: Basic Driving and Safety]","imageName":"202002061923072507.jpg","correctAnswer":30746,"isBookmarked":0,"aid":"30746#30747#30748#30749","text":"Hydroplaning can start at speeds as low as 35 mph#The condition of your tires does not affect your likelihood of hydroplaning#If you hydroplane, you should steer straight and brake firmly#Cruise control is safe to use on wet roads"},
{"testId":645,"qid":28573,"description":"Which of the following is true about the speed limits in Louisiana?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a safe and reasonable speed for the present road, traffic, and weather circumstances. When driving conditions are bad, slow down. You might be penalized for driving too quickly for the conditions. [Speed, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30419#30421#31427","text":"All of the above are true#You must not exceed the posted speed limit#You should drive at the posted speed limit only under safe conditions#You should not drive so slowly as to impede the flow of traffic"},
{"testId":645,"qid":28574,"description":"Is it legal to turn left at a red light?","explanation":"Unless otherwise posted, turning left on a red light from a one-way street into another one-way street is legal in Louisiana. You must come to a full stop before making the left turn and then yield to pedestrians and oncoming cars. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while going out of state.) [RS 32:232 (3)(c)]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"25289#30751#31428#31429","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning from a one-way street#No, not in Louisiana"},
{"testId":645,"qid":28575,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15560#15561#15668#30423","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#There is a divided highway ahead#There is a shared center turning lane ahead"},
{"testId":645,"qid":28576,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a shared center turning lane with a two-way left turn lane. This lane allows vehicles driving in either direction to make a left turn. Proceed with care and keep an eye out for oncoming vehicles while turning left here. This lane should never be used for passing. [Shared Center Left Turn Lane, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202001301806403967.jpg","correctAnswer":31430,"isBookmarked":0,"aid":"15973#16067#30411#31430","text":"A central parallel parking area#A painted median for emergency stopping only#A High-Occupancy Vehicle (HOV) lane#A shared center left-turn lane"},
{"testId":645,"qid":28577,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":"There are two right-of-way regulations that apply here: Turning left must yield to incoming traffic. At Louisiana, automobiles must always yield to pedestrians, even if not in a crosswalk. As a result, when turning left into a driveway, you must yield to all pedestrians and oncoming traffic. [Right-of-Way, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:219]","imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":645,"qid":28578,"description":"This driver is signaling with his hand. What is the driver's intention in this situation?","explanation":"This motorist wants to make a right turn. This hand gesture is analogous to a vehicle's right turn signals. [Signaling, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002181150078740.jpg","correctAnswer":31424,"isBookmarked":0,"aid":"31423#31424#31425#31426","text":"The driver intends to turn left#The driver intends to turn right#The driver intends to slow down or stop#The driver is letting us know that it is safe to pass him or her"},
{"testId":645,"qid":28579,"description":"A school bus with flashing red lights stops ahead of you. What should you do?","explanation":"Vehicles moving on either side of the road must come to a complete stop before approaching a school bus that has stopped for passengers. To inform cars, the bus driver will activate stop signs and flashing red lights. [Stopped School Buses, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations; RS 32:80 A; RS 32:318]","imageName":"202003051658529900.jpg","correctAnswer":29996,"isBookmarked":0,"aid":"29995#29996#30424#31431","text":"You must stop if you are traveling in the same direction as the bus#You must stop, no matter which direction you are traveling in#You must slow down to 15 mph and yield to pedestrians#You must stop and yield to any pedestrians crossing the road"},
{"testId":645,"qid":28580,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [RS 32:123 C]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":645,"qid":28581,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":645,"qid":28582,"description":"You're turning right on a red light. A pedestrian begins to cross the road you wish to enter just as you are about to turn. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":645,"qid":28583,"description":"Which of the statements below is false?","explanation":"When visibility is poor at 500 feet and from dusk until daybreak, you must use your headlights. When using your windshield wipers, you must also utilize your headlights. Your parking lights are just for parking purposes. You must not drive with just your parking lights on. [Night Driving, Section 5: Safe Driving Habits, Louisiana Class D & E Driver's Guide; RS 32:326 C]","imageName":"202003161149128995.jpg","correctAnswer":31433,"isBookmarked":0,"aid":"30154#31432#31433#31434","text":"You must use your headlights between sunset and sunrise#You must use your headlights whenever visibility is difficult at 500 feet#In the daytime, you can make your car easier to see by driving with your parking lights on#You must use your headlights whenever you need to use your windshield wipers"},
{"testId":645,"qid":28584,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic lights are out, consider the junction as an all-way stop. You must stop, yield, and advance only when it is safe to do so (with utmost care). [Traffic Signals, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003051805205845.jpg","correctAnswer":28880,"isBookmarked":0,"aid":"28880#29346#31435#31436","text":"Treat the intersection as an all-way stop#Treat the intersection as an uncontrolled intersection#Drive slowly and cautiously through the intersection#If you are on the busier road, assume you have the right-of-way; otherwise yield"},
{"testId":645,"qid":28585,"description":"What is the meaning of this sign?","explanation":"This sign denotes that there is a school zone ahead. Slow down and continue with care as schoolchildren may be crossing the road ahead. At Louisiana, you must always yield to pedestrians, regardless of whether they are in a crosswalk or an intersection. [Traffic Signs, Louisiana Class D & E Driver's Guide, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003151740461521.jpg","correctAnswer":23549,"isBookmarked":0,"aid":"23549#23603#31437#31438","text":"There is a school zone ahead#There is a crosswalk ahead#There is a public library ahead#There is a shared section of road ahead"},
{"testId":645,"qid":28586,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for these blind spots. [Chapter 6: Traffic Laws and Regulations, Louisiana Class D & E Driver's Guide, Lane Changing]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":645,"qid":28587,"description":"What is the meaning of this sign?","explanation":"There is a sharp slope ahead. Check your brakes. Keep an eye out for falling vehicles such as trucks. Even if you operate an automatic, choose low gear to avoid brake wear. [Traffic Signs, Chapter 3: Signs, Traffic Lights, and Pavement Markings, Louisiana Class D & E Driver's Guide]","imageName":"202002101212538783.jpg","correctAnswer":26841,"isBookmarked":0,"aid":"26841#28551#28552#31341","text":"There is a steep descent ahead; proceed with caution#Trucks are entering from a steep side road ahead; proceed with caution#This is a truck route#There is an emergency truck escape ramp ahead"},
{"testId":645,"qid":28588,"description":"When driving at night, high beams might help you see better. But when is it necessary to dim the lights?","explanation":"When you are within 500 feet of an incoming vehicle, you must decrease your high lights. When you are fewer than 200 feet behind another vehicle, you must also reduce your high lights. Avoid flashing your high lights on any other car that is occupied. [Night Driving, Louisiana Class D & E Driver's Guide, Chapter 5: Safe Driving Habits]","imageName":"202002211503506612.jpg","correctAnswer":31439,"isBookmarked":0,"aid":"29353#31439#31440#31441","text":"When you are 500 feet from any vehicle#When you are within 500 feet of an oncoming vehicle, or less than 200 feet behind another vehicle#When you are within 200 feet of an oncoming vehicle, or less than 500 feet behind another vehicle#When you are 200 feet from any vehicle"},
{"testId":645,"qid":28589,"description":"At a railroad crossing, which of the following must you do?","explanation":"You must come to a complete stop at a flashing red railroad crossing signal and continue only after the lights have stopped blinking. You must not cross while the crossing gate is down; instead, wait for it to fully raise before proceeding. Before crossing the rails, shift into the proper gear. Shifting gears while driving on the racetrack may cause your car to stall. [Railroad Grade Crossing, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002101119357461.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"23000#30762#30763#30764","text":"Do all of the above#Stop at a flashing signal and wait for the lights to stop flashing before you proceed#Wait for the crossing gate to rise completely before you proceed#Avoid shifting gears while crossing the tracks"},
{"testId":645,"qid":28590,"description":"You're following another vehicle. How far behind should you stay to be safe and legal?","explanation":"The \"three-second rule\" dictates that you must not follow another vehicle within three seconds. Under ideal circumstances, this is the minimum recommended following distance. If driving conditions deteriorate, use the \"four-second rule.\" [Following Distances, Louisiana Class D & E Driver's Guide, Chapter 5: Safe Driving Habits]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15992#16038#19915#26842","text":"At least six seconds#At least three seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":645,"qid":28591,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":"Vehicles moving in either direction must normally stop at least 30 feet away from a school bus that has stopped to pick up passengers. This law, however, does not apply if the school bus is on the other side of a split highway (a roadway with a non-drivable median or barrier). It should be noted that neither a painted median nor a shared center left-turn lane is considered a divider for this purpose. [Stopped School Buses, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":645,"qid":28592,"description":"What should you do if you see a flashing yellow light?","explanation":"You must slow down, give way to oncoming vehicles and pedestrians, and continue cautiously while a flashing yellow light is present. [Louisiana Class D & E Driver's Guide, Traffic Signals, Chapter 3: Signs, Traffic Lights, and Pavement Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30765#31848","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Stop if safe; the signal will soon turn red#Go straight when safe; you cannot turn here"},
{"testId":645,"qid":28593,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Vehicle registrations and license plates must be renewed annually or biennially, on or before the birthdate of the first owner specified on the registration form.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way"},
{"testId":645,"qid":28594,"description":"You're travelling on a highway with a speed restriction of 65 miles per hour. It starts to rain heavily. How fast should you drive?","explanation":"For ideal circumstances, speed restrictions are posted. However, you must always drive at a safe and fair pace for the present road conditions. You might be penalized for driving too quickly for the conditions. In standing water, your car may begin to hydroplane at speeds more than 35 mph. As a result, amid severe rain, you should slow down. [Speed, Louisiana Class D & E Driver's Guide, Chapter 6: Traffic Laws and Regulations]","imageName":"202002061923072507.jpg","correctAnswer":30766,"isBookmarked":0,"aid":"684#1676#2135#30766","text":"65 mph#35 mph#50 mph#A speed that is safe and reasonable"},
{"testId":645,"qid":28595,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":"To pass a stopped emergency vehicle, go into a non-adjacent lane if practicable and safe, leaving at least one empty lane between you and the emergency vehicle. Slow down to a relatively safe speed if this cannot be done safely. [RS 32:125]","imageName":"202001241703386758.jpg","correctAnswer":31442,"isBookmarked":0,"aid":"29357#29359#30005#31442","text":"Maintain your speed and proceed with caution#Slow down to 20 mph below the speed limit and pass with your emergency flashers on#Slow down and prepare to stop#Leave a vacant lane next to the emergency vehicle if possible; otherwise, slow down"},
{"testId":646,"qid":28596,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop behind the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Traffic Signs, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30729","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when safe; no turns are allowed here"},
{"testId":646,"qid":28597,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [State of Maine Motorist Handbook and Study Guide, Speed Limits, Adjusting Your Speed to Conditions, Part IV: Operating a Motor Vehicle]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":646,"qid":28598,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#31725","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Maine"},
{"testId":646,"qid":28599,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield to the right here, and Car B has the right-of-way. [Right-of-Way, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#26796#28846","text":"Car A must yield to its right#Car B must yield to its left#Car A must yield because it is turning#One driver must wave the other one through"},
{"testId":646,"qid":28600,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Slow down as you approach a yield sign and ready to yield to pedestrians and traffic ahead. Be prepared to halt if necessary. [Traffic Signs, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":646,"qid":28601,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over immediately. [Right-of-Way, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001302235285631.jpg","correctAnswer":30396,"isBookmarked":0,"aid":"15493#26798#30396#31406","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over to the right, no matter which direction you are traveling in#Pull over to the right if you are traveling in the same direction as the ambulance"},
{"testId":646,"qid":28602,"description":"Some municipal ordinances in Maine prohibit parking within _______ of a fire hydrant.","explanation":"Some municipal regulations in Maine prohibit parking within 10 feet of a fire hydrant. (Note: In some places, the distance might be 15 feet or anything else.) Always verify the local traffic rules while going out of state.) Rules of the Road, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001290916213788.jpg","correctAnswer":1028,"isBookmarked":0,"aid":"1028#1591#4582#31727","text":"10 feet#5 feet#20 feet#inches"},
{"testId":646,"qid":28603,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and school crossing signs (five sided). Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#25354","text":"A school zone sign#A work zone sign#A warning sign#A no-passing sign"},
{"testId":646,"qid":28604,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"21234#28850#30733#31728","text":"Yes#No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after you yield to all traffic and pedestrians already in the intersection"},
{"testId":646,"qid":28605,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. You must first come to a full stop and then yield to all pedestrians and vehicles. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#31729","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No. In Maine, you cannot turn right at a red signal"},
{"testId":646,"qid":28606,"description":"Who is required to wear a seat belt (or child restraint)?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16007,"isBookmarked":0,"aid":"16007#31730#31731#31732","text":"The driver and every passenger#No one (safety belts are optional in Maine)#The driver and every passenger under 18 years of age#The driver, every front-seat passenger, and every passenger under 18 years of age"},
{"testId":646,"qid":28607,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The car on the left must give way to the car on the right at an all-way stop. At this point, Car A must let Car B go. [State of Maine Motorist Handbook and Study Guide, Part IV: Operating a Motor Vehicle, Right-of-Way, Rules of the Road]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":646,"qid":28608,"description":"What is the meaning of this sign?","explanation":"A triangular orange insignia on the back of a car designates it as a slow-moving vehicle, which is defined in Maine as a vehicle with a maximum speed of less than 25 mph. Farm tractors, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. [How Fast Is Traffic Moving?, Adjusting Your Speed to Conditions, Part IV: Operating a Motor Vehicle, Maine Motorist Handbook and Study Guide]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"148#627#629#1075","text":"Construction zone ahead#Stop#Slow-moving vehicle#No passing"},
{"testId":646,"qid":28609,"description":"You are facing uphill and parking next to the curb. Which way should the front wheels face?","explanation":"When parking facing uphill, angle your front wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. If your brakes fail, the curb will prevent your vehicle from moving. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear. [Parking, Road Rules, Part IV: Operating a Motor Vehicle, Maine Motorist Handbook and Study Guide]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":646,"qid":28610,"description":"Which vehicles are turning left correctly here?","explanation":"Unless otherwise indicated by signage, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made an erroneous turn into the far lane. Car B has made the proper turn into the nearest lane. [Lane Restrictions, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":646,"qid":28611,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a police officer's instructions, even if it means violating other traffic devices or laws. For example, if a police officer waves you past a red light or a stop sign, you should proceed. [Traffic Signs, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002211500121915.jpg","correctAnswer":16212,"isBookmarked":0,"aid":"2316#2529#15196#16212","text":"A stop sign#A traffic signal#A prohibitory sign#A police officer"},
{"testId":646,"qid":28612,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#28880","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop"},
{"testId":646,"qid":28613,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's No-Zones. Remember, if you can't see the truck driver, he or she can't see you either. [Hazardous Driving Conditions, Part V: Handling Emergencies, State of Maine Motorist Handbook and Study Guide]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":646,"qid":28614,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"Generally, you should stay in the right lane. The left lane is used to pass or turn left.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25308#25311#28526","text":"Car A must yield because it is turning left#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left"},
{"testId":646,"qid":28615,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This sign indicates that passing other cars is not permitted here. Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":646,"qid":28616,"description":"You come to a railroad crossing with a gate down and red lights flashing. How close may you stop to the crossing?","explanation":"You must come to a complete stop at least 10 feet away from any railroad crossing with a lowered gate or flashing red lights. (Please keep in mind that some states may ask you to stop farther away.) Always verify the local traffic rules while going out of state.) [Railroad Crossings, Driving Vision, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003161136554337.jpg","correctAnswer":31733,"isBookmarked":0,"aid":"16052#31733#31734#31735","text":"Immediately in front of the gate#10 feet from the crossing#20 feet from the crossing#50 feet from the crossing"},
{"testId":646,"qid":28617,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any cars and pedestrians already present. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":646,"qid":28618,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#31850","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through when safe; no left or right turns are allowed here"},
{"testId":646,"qid":28619,"description":"This driver is signaling with his hand. What is the driver's intention in this situation?","explanation":"This motorist plans to slow down or come to a complete stop. This hand gesture is analogous to a vehicle's brake lights. [Hand Signals, Communicating with Others, Part IV: Driving, State of Maine Motorist Handbook and Study Guide]","imageName":"202002181152514351.jpg","correctAnswer":31425,"isBookmarked":0,"aid":"31423#31424#31425#31736","text":"The driver intends to turn left#The driver intends to turn right#The driver intends to slow down or stop#The driver is letting others know that it is safe to pass him or her"},
{"testId":646,"qid":28620,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Typically, these signs are posted 350 to 500 feet ahead of a railroad crossing. Keep an eye out for warning lights and oncoming trains. Slow down and prepare to come to a halt. [Railroad Crossings, Driving Vision, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":646,"qid":28621,"description":"Unless otherwise stated, the speed limit throughout Maine's business and residential areas is :","explanation":"Unless otherwise marked, the speed limit in Maine's commercial districts, residential districts, and all other built-up areas is 25 mph. [State of Maine Motorist Handbook and Study Guide, Speed Limits, Adjusting Your Speed to Conditions, Part IV: Operating a Motor Vehicle]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"522#523#1676#1677","text":"25 mph#20 mph#35 mph#45 mph"},
{"testId":646,"qid":28622,"description":"Which of the following statements is true about hydroplaning?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":31737,"isBookmarked":0,"aid":"30747#30748#30749#31737","text":"The condition of your tires does not affect your likelihood of hydroplaning#If you hydroplane, you should steer straight and brake firmly#Cruise control is safe to use on wet roads#Hydroplaning can start at speeds as low as 30 mph"},
{"testId":646,"qid":28623,"description":"Which of the following assertions about the speed limits in Maine is true?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a safe and reasonable speed for the present road, traffic, and weather circumstances. When driving conditions are bad, slow down. Even if you are not breaking the posted speed limit, you may be charged for driving too fast for the circumstances. [Adjusting Your Speed to Conditions, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30419#30421#31427","text":"All of the above are true#You must not exceed the posted speed limit#You should drive at the posted speed limit only under safe conditions#You should not drive so slowly as to impede the flow of traffic"},
{"testId":646,"qid":28624,"description":"Is it legal to turn left at a red light?","explanation":"Many states allow you to turn left on a red light in specific circumstances, often from one one-way street to another one-way one. However, in Maine, turning left on a red light is always banned. Seven additional states, as well as the District of Columbia, prohibit left turns on red. Unless indications indicate otherwise, so does New York City. Always verify the local driving regulations while going out of state. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003112014364009.jpg","correctAnswer":31738,"isBookmarked":0,"aid":"26802#30751#31428#31738","text":"Yes, this is a \"protected\" turn, so you have the right-of-way#Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning from a one-way street#No, not in Maine"},
{"testId":646,"qid":28625,"description":"What is the meaning of this sign?","explanation":"Ahead, the road divides into a divided highway. A median or a barrier will be used to divide opposing lanes. Continue straight. Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15560#15561#15668#30423","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#There is a divided highway ahead#There is a shared center turning lane ahead"},
{"testId":646,"qid":28626,"description":"You see a pedestrian out into the road with a white cane. What are your duties?","explanation":"A walker with a white cane or a guiding dog is probably blind or visually handicapped. Such pedestrians must always be given the right of way. Furthermore, in Maine, if a visually impaired pedestrian is in the road, you must stop at least 10 feet away and wait until the walker is no longer in danger. Avoid using your horn because it may surprise a pedestrian or guide dog. [Stop Lines, Driving Vision, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002061105552801.jpg","correctAnswer":31739,"isBookmarked":0,"aid":"28545#31739#31740#31741","text":"Sound your horn to alert the pedestrian to your presence#Stop at least 10 feet away and wait until the pedestrian is out of danger#Stop at least 10 feet away until the pedestrian has vacated your side of the road#Stop and yield to the pedestrian"},
{"testId":646,"qid":28627,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"15739#25324#28873#28874","text":"No one#Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only"},
{"testId":646,"qid":28628,"description":"This driver is signaling with his hand. What is the driver's intention in this situation?","explanation":"This motorist wants to make a right turn. This hand gesture is analogous to a vehicle's right turn signals. [Hand Signals, Communicating with Others, Part IV: Driving, State of Maine Motorist Handbook and Study Guide]","imageName":"202002181150078740.jpg","correctAnswer":31424,"isBookmarked":0,"aid":"31423#31424#31425#31736","text":"The driver intends to turn left#The driver intends to turn right#The driver intends to slow down or stop#The driver is letting others know that it is safe to pass him or her"},
{"testId":646,"qid":28629,"description":"A school bus with flashing red lights stops ahead of you. What should you do?","explanation":"Vehicles on both sides of the road must stop for a school bus that has stopped to pick up passengers. To inform cars, the bus driver will activate stop signs and flashing red lights. [School Buses, Giving Problem Drivers a Space Cushion, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003051658529900.jpg","correctAnswer":38925,"isBookmarked":0,"aid":"30424#38923#38924#38925","text":"You must slow down to 15 mph and yield to pedestrians#You must pass with caution at the same speed#You must stop only if you are traveling in the same direction as the bus#You must stop and remain stopped, no matter which direction you are traveling in, until the flashing red lights are off"},
{"testId":646,"qid":28630,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [Right-of-Way, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":646,"qid":28631,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":646,"qid":28632,"description":"You're turning right on a red light. A pedestrian begins to cross the road you wish to enter just as you are about to turn. What should you do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. [Traffic Lights, Road Rules, Part IV: Driving a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":646,"qid":28633,"description":"Which of the following is true about using headlights?","explanation":"From nightfall till morning, you must use your headlights. You must also use your headlights when vision is poor at 1,000 feet or your windshield wipers are always in use. [Using Headlights, Communicating with Others, Part IV: Driving a Car, State of Maine Motorist Handbook and Study Guide]","imageName":"202003161149128995.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30756#31742#32067","text":"All of the above are true#You must use your headlights whenever visibility is difficult at 1,000 feet#You must use your headlights whenever your windshield wipers are in constant use#You must use your headlights from dusk to dawn"},
{"testId":646,"qid":28635,"description":"What is the speed limit in Maine when there are children present around the school?","explanation":"You must not exceed 15 mph while passing a school during recess or when children are going to or from school during the school's opening or closing hours, unless stated differently. Of course, it is impossible to predict when these intervals will occur. When in doubt, proceed with utmost care. [State of Maine Motorist Handbook and Study Guide, Speed Limits, Adjusting Your Speed to Conditions, Part IV: Operating a Motor Vehicle]","imageName":"202004281807142908.jpg","correctAnswer":520,"isBookmarked":0,"aid":"520#522#523#2429","text":"15 mph#25 mph#20 mph#10 mph"},
{"testId":646,"qid":28636,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for blind spots. Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":646,"qid":28637,"description":"Some municipal rules in Maine prohibit parking within ________ of a crosswalk.","explanation":"Some municipal regulations in Maine prohibit parking within 15 feet of a crosswalk. Rules of the Road, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003241454204243.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#1591#8416#8417","text":"10 feet#5 feet#15 feet#30 feet"},
{"testId":646,"qid":28638,"description":"When driving at night, high beams might help you see better. But when is it necessary to dim the lights?","explanation":"When you are within 500 feet of an incoming vehicle, you must decrease your high lights. When you are fewer than 300 feet behind another vehicle, you must also reduce your high lights. Avoid flashing your high lights on any other car that is occupied. [Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide, Seeing Well At Night, Seeing Well While Driving]","imageName":"202002211503506612.jpg","correctAnswer":30760,"isBookmarked":0,"aid":"29352#29353#30760#30761","text":"When you are 300 feet from any vehicle#When you are 500 feet from any vehicle#When you are within 500 feet of an oncoming vehicle, or less than 300 feet behind another vehicle#When you are within 300 feet of an oncoming vehicle, or less than 500 feet behind another vehicle"},
{"testId":646,"qid":28639,"description":"Which of the following is true about railroad crossings?","explanation":"When crossing a railroad track, go slowly and be cautious. Buses, school buses, tank trucks, and vehicles transporting hazardous chemicals are required to stop at all railroad crossings, so keep an eye out for them. Reduce your speed and scan both directions for oncoming trains even if the warning signs are not blinking. (The signals may not be functioning correctly.) If a crossing gate is not entirely raised, never drive under it. Never attempt to drive around a gate that has been lowered. [State of Maine Motorist Handbook and Study Guide, Part IV: Operating a Motor Vehicle, Railroad Crossings, Seeing Well While Driving]","imageName":"202002101119357461.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#31743#31744#31745","text":"All of the above are true#Be alert for buses because they must stop at all railroad crossings#Never drive under a crossing gate unless it is completely raised#Even if the warning signals are not flashing, slow down and look both ways for approaching trains"},
{"testId":646,"qid":28640,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Maine?","explanation":"The \"two-second rule\" is recommended by the Maine Motorist Handbook and Study Guide: do not follow another vehicle within two seconds. Under ideal circumstances, this is the minimum recommended following distance. If the driving conditions deteriorate, you should maintain a three- to four-second following gap. [Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide, Keeping a Cushion Ahead, Maintaining a Safe Cushion]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#15992#19915#26842","text":"At least two seconds#At least six seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":646,"qid":28641,"description":"You get close to a school bus that has stopped on the other side of a divided highway. It has flashing red lights. Do you have to stop here?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#25391#26843#29354","text":"No, you do not need to stop here#Yes, you must stop until the bus has switched off its red signals#No, but you must slow down to 25 mph or less and proceed with caution#Yes, you must briefly stop and yield to pedestrians on the road"},
{"testId":646,"qid":28642,"description":"What should you do if you see a flashing yellow light?","explanation":"Flashing yellow signals are intended to alert vehicles of possible risks at junctions and construction zones. When you see a flashing yellow light, you must slow down, yield to oncoming vehicles and pedestrians, and continue with care. [Flashing Lights, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30765#31848","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Stop if safe; the signal will soon turn red#Go straight when safe; you cannot turn here"},
{"testId":646,"qid":28643,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Right-of-way is given to vehicles already at a junction. A traffic circle, often known as a rotary, is a circular junction. Cars approaching a traffic circle or rotary must surrender to vehicles already inside the circle.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way"},
{"testId":646,"qid":28645,"description":"Which vehicles are making proper use of this roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Unless otherwise indicated, turn left from the left lane, right from the right lane, and drive straight through using either lane in a two-lane roundabout. Car C has made an improper left turn from the right lane. [Rules of the Road, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide, Traffic Circles and Roundabouts]","imageName":"202001290908474628.jpg","correctAnswer":15441,"isBookmarked":0,"aid":"14815#15441#16385#16543","text":"Car B#Cars A and B#Cars B and C#Cars A and C"},
{"testId":647,"qid":28646,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"The stop sign is the only octagonal (eight-sided) sign. The word \"STOP\" is displayed in white on a red backdrop on stop signs. When approaching a junction with a stop sign, you must come to a complete stop before the stop line, crosswalk, or intersection. After yielding to vehicles and pedestrians, you may continue. [3. Regulatory Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003151731031579.jpg","correctAnswer":15473,"isBookmarked":0,"aid":"15472#15473#28148#32282","text":"Slow down and only proceed if the intersection is clear#Come to a complete stop and yield to any traffic before proceeding#Proceed with caution#Proceed straight through when safe; there are no left or right turns allowed here"},
{"testId":647,"qid":28647,"description":"What is the meaning of this sign?","explanation":"This sign indicates that the legal maximum speed restriction is 50 miles per hour. You are not permitted to exceed 50 mph in this area. [3. Regulatory Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":647,"qid":28648,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#32283","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Maryland"},
{"testId":647,"qid":28649,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two vehicles approach at an uncontrolled junction about the same time, the vehicle on the left must yield. Car A must yield to the right here, and Car B has the right-of-way. [B. Right-of-Way, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32284","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should act first and take the right-of-way"},
{"testId":647,"qid":28650,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Before continuing, you must slow down and ready to yield to pedestrians and vehicles ahead of you as you approach a yield sign. You may need to stop for them as well, so be prepared. [3. Regulatory Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":647,"qid":28651,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over immediately. [B. Emergency Vehicles, Maryland Driver's Manual, Section VII - Sharing the Road]","imageName":"202001302235285631.jpg","correctAnswer":32286,"isBookmarked":0,"aid":"15493#26798#32285#32286","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Speed up and find somewhere to pull over#Pull over to the edge of the road"},
{"testId":647,"qid":28652,"description":"Which of the following statement about right-of-way is true?","explanation":"The right-of-way is to be granted rather than taken. Allow the right-of-way to the individual who fails to yield to you. If you need to give up the right-of-way to be safe, do so. Even if it's your time to leave, go with care. [B. Right-of-Way, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202007120454477868.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31414#31416#32287#32288","text":"The right-of-way rules are optional#The right-of-way is to be given, not taken#You should claim the right-of-way wherever you safely can#All vehicles will yield to you if you have the right-of-way"},
{"testId":647,"qid":28653,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Driving around these warnings should be done with care. Keep an eye out for young pedestrians. [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#30402","text":"A school zone sign#A work zone sign#A warning sign#A No Passing Zone sign"},
{"testId":647,"qid":28654,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [3. Consistent Green Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"21234#28850#30733#31728","text":"Yes#No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after you yield to all traffic and pedestrians already in the intersection"},
{"testId":647,"qid":28655,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. You must first come to a full stop and then yield to all pedestrians and vehicles. [A. Traffic Signals, Maryland Driver's Manual, Section IV - Signals, Signs, and Pavement Markings]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#32289","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No. In Maryland, you cannot turn right at a red signal"},
{"testId":647,"qid":28656,"description":"Who is required to wear a seat belt (or child restraint)?","explanation":"The driver and all passengers in a motor vehicle must wear a seat belt or be restrained in a child safety seat, according to Maryland Motor Vehicle Law. Seat belts have been shown in studies to save lives. [Maryland Driver's Manual, Section X - Other Important Information]","imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16365#32258#32290#45804","text":"The driver and all passengers#Only the driver and the front-seat passenger next to the door#No one (safety belts are optional in Maryland)#Only the driver and all passengers under the age of 16"},
{"testId":647,"qid":28657,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The car on the left must make way for the vehicle on the right at an all-way stop. At this point, Car A must let Car B go. (From Maryland Driver's Manual, 1. Steady Red Signal, B. Right-of-Way, Section III - Basic Driving)","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":647,"qid":28658,"description":"What is the meaning of this sign?","explanation":"A triangular orange insignia on the back of a car marks it as a slow-moving vehicle, which is defined in Maryland as one that does not exceed 25 miles per hour. Farm tractors, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. [L. Slow Moving Vehicles, Maryland Driver's Manual, Section V - Driving Situations and Conditions]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"148#627#629#1075","text":"Construction zone ahead#Stop#Slow-moving vehicle#No passing"},
{"testId":647,"qid":28659,"description":"Three vehicles arrive at a T-junction. In what order should they go?","explanation":"A vehicle turning left at a T-intersection must surrender to all traffic on the main (through) route. Car A must yield to both Cars B and C in this situation. And, since Car C is turning left, it must yield to incoming Car B, allowing Car B to drive first, followed by Car C, and then Car A. [B. Right-of-Way, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#16258","text":"A, B, C#C, B, A#B, C, A#B, A, C"},
{"testId":647,"qid":28660,"description":"Which of the following is NOT TRUE about passing on the right?","explanation":"When there are two or more open lanes heading in your direction, you may pass an automobile on the right. (This may happen on a multilane freeway or a one-way street.) You may also pass a vehicle turning left on the right. You cannot, however, drive on the shoulder to pass (or for any other reason except an emergency). [I. Passing, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202002181218288995.jpg","correctAnswer":32295,"isBookmarked":0,"aid":"32294#32295#32296#32297","text":"You can pass a vehicle on the right if it is turning left#You can drive on the shoulder to pass a vehicle on the right if it is turning left#You can pass on the right when there are at least two lanes moving in your direction#You can pass on the right on a one-way road that has at least two lanes"},
{"testId":647,"qid":28661,"description":"What is the meaning of this sign?","explanation":"This regulation sign instructs cars to stay to the right. On the right, there will be an obstruction or island to navigate. [3. Regulatory Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003151752201841.jpg","correctAnswer":19246,"isBookmarked":0,"aid":"15668#19246#29870#32298","text":"There is a divided highway ahead#Keep right#Left turns are prohibited#The road narrows ahead"},
{"testId":647,"qid":28662,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [2. Steady Yellow Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003051752141237.jpg","correctAnswer":32299,"isBookmarked":0,"aid":"28857#28858#28880#32299","text":"Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop#Stop before the intersection if you can do it safely"},
{"testId":647,"qid":28663,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"Trucks have extensive blind areas, dubbed No-Zones. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. A automobile travelling next or slightly behind a truck's cab might easily slip into one of these blind areas. The truck's side mirrors only allow tiny arcs of view along the sides. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's No-Zones. Remember, if you can't see the truck driver, he or she probably can't see you. [No-Zones, C. Large Trucks, Section VII - Sharing the Road, Maryland Driver's Manual]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":647,"qid":28664,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"To cross railroad tracks, it is usually safer to ride straight inside your lane. Turning to take tracks head-on (at a 90-degree angle) is riskier since your course may lead you into another lane of traffic. If there are tracks, ruts, or road seams parallel to your path, move far enough away from them to cross them at an angle of at least 45 degrees.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":647,"qid":28665,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The No Passing Zone sign is designated with a pennant form. This sign indicates that passing other cars is not permitted here. [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":647,"qid":28666,"description":"When you reach at a crossroads, you notice that the traffic lights are not working; they are entirely dark. What should you do?","explanation":"When the traffic lights are down, you must stop, yield to pedestrians and cross traffic, and continue only when it is safe to do so (with great care). [Maryland Driver's Manual, Section IV - Signals, Signs, and Pavement Markings]","imageName":"202003051805205845.jpg","correctAnswer":32303,"isBookmarked":0,"aid":"32302#32303#32304#32305","text":"Slow down and yield to all traffic before proceeding#Stop and yield to all traffic before proceeding#Yield to all traffic, then drive straight through if you are on the busier road#Drive straight through"},
{"testId":647,"qid":28667,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any cars and pedestrians already present. [3. Consistent Green Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":647,"qid":28668,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [7. Flashing Red Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual, A. Traffic Signals]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28864#29330#31850","text":"Stop and yield as you would at a stop sign#Stop and do not proceed until the signal stops flashing#Proceed with caution; the traffic signal is broken#Proceed straight through when safe; no left or right turns are allowed here"},
{"testId":647,"qid":28669,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending roundabout. Slow down and be ready to yield to cars already in the roundabout. [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202002101237092503.jpg","correctAnswer":15187,"isBookmarked":0,"aid":"15187#16864#32306#32307","text":"Roundabout ahead#Left turns only#Shared left-turn lane ahead#270-degree freeway ramp ahead"},
{"testId":647,"qid":28670,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. Slow down and prepare to come to a halt. [G. Highway-Railroad Crossings, Maryland Driver's Manual, Section V - Driving Situations and Conditions]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":647,"qid":28672,"description":"Which of the following statements is true about hydroplaning?","explanation":"When you drive too rapidly over standing water, you essentially ski over its surface. Even if you have decent tires, your car may begin to hydroplane at speeds as low as 35 mph. (At speeds as low as 25 mph, poorly worn tires might cause the car to hydroplane.) This may cause your car to slide. Your tires may lose all contact with the road at 55 mph, leading in complete loss of control. If you hydroplane, try not to accelerate, steer, or brake; instead, let the automobile slow down on its own. Do not use your cruise control on wet roads. If your car loses traction, the cruise control may try to maintain a steady speed by spinning the wheels. This may exacerbate or even cause a skid. [F. Hydroplaning, Maryland Driver's Manual, Section V - Driving Situations and Conditions]","imageName":"202002061923072507.jpg","correctAnswer":30746,"isBookmarked":0,"aid":"30746#30748#30749#32312","text":"Hydroplaning can start at speeds as low as 35 mph#If you hydroplane, you should steer straight and brake firmly#Cruise control is safe to use on wet roads#The condition of your tires does not affect your chances of hydroplaning"},
{"testId":647,"qid":28673,"description":"Which of the following statements concerning Maryland's speed limits is correct?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. You should drive at a safe and reasonable speed for the present road, traffic, and weather circumstances. When driving conditions are bad, slow down. Even if you are not breaking the posted speed limit, you may be charged for driving too fast for the circumstances. [C. Understanding Vehicle Speed, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#30419#30421#31427","text":"All of the above are true#You must not exceed the posted speed limit#You should drive at the posted speed limit only under safe conditions#You should not drive so slowly as to impede the flow of traffic"},
{"testId":647,"qid":28674,"description":"Is it legal to turn left at a red light?","explanation":"In Maryland, if you are going from one one-way street into another one-way street and there are no signs barring it, you may make a left turn at a red light. Before turning, you must first come to a full stop and then yield to all vehicles. (Note: Some jurisdictions have various regulations regarding left turns on red.) For example, the District of Columbia outright outlaws left turns on red. Always verify the local traffic rules while going out of state.) [1. Steady Red Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"26802#30751#31428#32313","text":"Yes, this is a \"protected\" turn, so you have the right-of-way#Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning from a one-way street#No, not in Maryland"},
{"testId":647,"qid":28675,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a barrier or median. Continue straight. [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15560#15561#15668#30423","text":"There is a traffic circle ahead#Stay in your current lane; do not merge left or right#There is a divided highway ahead#There is a shared center turning lane ahead"},
{"testId":647,"qid":28676,"description":"A white cane-wielding pedestrian steps into the road ahead. Which of the following statements is correct?","explanation":"Blind pedestrians may use a white cane or be escorted by a guide dog. You must defer to blind pedestrians and use extra care around them. If they begin to cross, stop and wait until they are out of danger. Sounding your horn may just surprise the pedestrian or guide dog. [1. Right-of-Way for Blind or Deaf Pedestrians or Mobility Impaired Individuals at Crossings, A. Pedestrian Right-of-Way, Section VII - Sharing the Road, Maryland Driver's Manual]","imageName":"202002061105552801.jpg","correctAnswer":32314,"isBookmarked":0,"aid":"32314#32315#32316#32317","text":"This pedestrian is blind; you must yield#This pedestrian is blind; sound your horn to alert the person to your presence#This pedestrian is not blind; sound your horn and proceed#Drive around the pedestrian and continue on your way"},
{"testId":647,"qid":28677,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"25324#28873#28874#32318","text":"Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only#No one (you have the right-of-way)"},
{"testId":647,"qid":28678,"description":"You are on a road with two lanes. Between the two lanes, there are two solid white lines. Which of the following is true?","explanation":"White lines are used to divide traffic lanes traveling in the same direction. On roads, double solid white lines are often used to distinguish a high-occupancy vehicle (HOV) lane from other lanes driving in the same direction. A double solid white line should never be crossed. [1. Line Marking Types, C. Highway Pavement Markings, Section IV - Signals, Signs, and Pavement Markings of the Maryland Driver's Manual]","imageName":"202003202134094720.jpg","correctAnswer":32319,"isBookmarked":0,"aid":"32308#32309#32319#32320","text":"You are free to change lanes#You should avoid changing lanes if possible#You must not cross a double solid white line#You can only cross a double solid white line when you are turning left"},
{"testId":647,"qid":28679,"description":"A school bus with flashing red lights has come to a halt ahead. What should you do?","explanation":"Vehicles moving in both directions must come to a complete stop at least 20 feet away from the school bus. No vehicle may continue until the bus's flashing red lights have been turned off or motion has been restarted. [D. School Vehicles, Maryland Driver's Manual, Section VII - Sharing the Road]","imageName":"202003051658529900.jpg","correctAnswer":32323,"isBookmarked":0,"aid":"28148#32321#32322#32323","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop at least 20 feet away if you are traveling in the same direction as the bus#Stop at least 20 feet away, no matter which direction you are traveling in"},
{"testId":647,"qid":28680,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"Cars arriving at various times at an all-way stop should continue in the order in which they arrived. Before going, each automobile must come to a full stop. Because the automobiles came in the sequence B, A, C, they should continue in the same order. [B. Right-of-Way, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":647,"qid":28681,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":647,"qid":28682,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning right on a red signal. [1. Steady Red Signal, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":647,"qid":28683,"description":"Which of the following is true about using headlights?","explanation":"You must use your headlights at night, anytime vision gets problematic at 1,000 feet, and whenever you use your windshield wipers in bad weather, according to Maryland law. [Maryland Driver's Manual, Section V - Driving Situations and Conditions, Headlight Use, A. Driving in Reduced Visibility]","imageName":"202003161149128995.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"28163#30756#32324#32325","text":"All of the above statements are true#You must use your headlights whenever visibility is difficult at 1,000 feet#You must use your headlights whenever it is dark, foggy, or stormy#You must use your headlights whenever you use your windshield wipers in poor weather"},
{"testId":647,"qid":28684,"description":"Which of the following is NOT true about snow and ice?","explanation":"When driving in the snow or ice, you must slow down. Even with four-wheel drive, driving at normal speeds in such circumstances is dangerous. Asphalt roads are prone to the creation of black ice, which is a thin and very slippery coating of ice that is difficult to detect on the road's surface. Also, keep in mind that particular places, such as ramps and bridges, may ice first. [Maryland Driver's Manual, Special Attention for Driving on Snow/Ice, D. Driving in Inclement Weather, Section V - Driving Situations and Conditions]","imageName":"202002061110137554.jpg","correctAnswer":32328,"isBookmarked":0,"aid":"32326#32327#32328#32329","text":"Asphalt roads can often hide thin patches of black ice#Ramps and bridges often freeze first#In snowy conditions, four-wheel drive lets you drive safely at normal speeds#You should slow down when driving through snow and ice"},
{"testId":647,"qid":28685,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending steep fall. Reduce your speed and keep an eye out for cars that are gently descending. Reduce the need for braking by driving in a low gear (even if you have an automatic gearbox). Excessive braking over an extended period of time may result in brake fade (loss of braking effectiveness). [5. Traffic Warning Signs, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"29349#31340#32330#32331","text":"There is a runaway emergency truck ramp ahead#There is a steep descent ahead#Trucks frequently enter from a steep side road ahead#Trucks frequently cross ahead"},
{"testId":647,"qid":28686,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for blind spots. [I. Passing, Maryland Driver's Manual, Section III - Basic Driving], [E. Motorcycles, Maryland Driver's Manual, Section VII - Sharing the Road]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":647,"qid":28687,"description":"You notice a line of triangles pointing toward you in your lane. What does it imply?","explanation":"This triangle marking line is a yield line. You must stay behind the yield line while yielding to pedestrians or other vehicles. [2. Other Pavement Markings, C. Highway Pavement Markings, Maryland Driver's Manual, Section IV - Signals, Signs, and Pavement Markings]","imageName":"202003202136149229.jpg","correctAnswer":32332,"isBookmarked":0,"aid":"32332#32333#32334#32335","text":"This is a yield line; yield here for a yield sign#This is a stop line; stop here for a stop sign or red traffic signal#This is the beginning of a school zone; slow down and proceed with caution#This is the beginning of a no-passing zone"},
{"testId":647,"qid":28688,"description":"When driving at night, high beams might help you see better. But when is it necessary to dim the lights?","explanation":"When you are within 500 feet of an incoming vehicle, you must decrease your high lights. When you are fewer than 300 feet behind another vehicle, you must also reduce your high lights. Avoid flashing your high lights on any other car that is occupied. [High Beam, A. Driving in Low Visibility, Maryland Driver's Manual, Section V - Driving Situations and Conditions]","imageName":"202002211503506612.jpg","correctAnswer":30760,"isBookmarked":0,"aid":"29352#29353#30760#30761","text":"When you are 300 feet from any vehicle#When you are 500 feet from any vehicle#When you are within 500 feet of an oncoming vehicle, or less than 300 feet behind another vehicle#When you are within 300 feet of an oncoming vehicle, or less than 500 feet behind another vehicle"},
{"testId":647,"qid":28689,"description":"On each side of this lane, there is a double dashed yellow line. What kind of lane is this?","explanation":"This is a lane that can be used both ways. The flow of traffic in this lane may alter at any moment. Keep an eye out for overhead or roadside signs or signals indicating the lane's current direction of traffic. [1. Line Marking Types, C. Highway Pavement Markings, Section IV - Signals, Signs, and Pavement Markings of the Maryland Driver's Manual]","imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16307#28541#32336","text":"A reversible lane#An emergency lane#A high-occupancy vehicle (HOV) lane#A shared left-turn lane"},
{"testId":647,"qid":28690,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Maryland?","explanation":"You may calculate your following distance by noting the time it takes between when the car in front of you passes an item and when your vehicle arrives at the same thing. Under ideal driving circumstances, you should always keep at least three seconds between yourself and the car in front of you. If the weather is bad, you should increase your following distance. [D. Following Distance, Maryland Driver's Manual, Section III - Basic Driving]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#26842","text":"At least two seconds#At least three seconds#At least 20 feet#Being able to see the brake lights of the vehicle ahead"},
{"testId":647,"qid":28691,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":"Vehicles moving in either direction must normally stop for a school bus that has stopped to pick up passengers. In Maryland, however, motorists are not required to stop for a school bus that is stopped on the other side of a split highway (a roadway with a non-drivable median or barrier). (Please keep in mind that the legislation in certain other jurisdictions vary.) Even if the school bus is on the other side of a split highway, you must stop in New York State. Always verify the local traffic rules while going out of state.) [D. School Vehicles, Section VII - Sharing the Road, Maryland Driver's Manual] [Drivers must stop for school vehicles.","imageName":"202002010826098089.jpg","correctAnswer":18128,"isBookmarked":0,"aid":"18128#32321#32322#32323","text":"Keep going#Slow down to 15 mph and proceed with caution#Stop at least 20 feet away if you are traveling in the same direction as the bus#Stop at least 20 feet away, no matter which direction you are traveling in"},
{"testId":647,"qid":28692,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. When you see a flashing yellow light, you must slow down, yield to oncoming vehicles and pedestrians, and continue with care. [8, A. Traffic Signals, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30765#31848","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Stop if safe; the signal will soon turn red#Go straight when safe; you cannot turn here"},
{"testId":647,"qid":28693,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"You should usually pass other cars on the left. However, there are a few exceptions when you may pass a vehicle on the right. If there are two or more lanes moving in your direction, you may pass on the right. This may happen on a multilane freeway or a one-way street. You may also pass on the right if a vehicle is making or about to make a left turn. If a car on the right is preparing to turn right, you may not pass it. This increases the likelihood of a collision.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#28558","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car in the busier lane has the right-of-way"},
{"testId":647,"qid":28695,"description":"You are approaching an emergency vehicle with flashing lights that are parked on the side of the road. What must you do?","explanation":"You should leave a lane open next to an emergency vehicle that is stopped and flashing its lights. If changing lanes is dangerous or impracticable, you should reduce your speed to a safe and acceptable level and continue with care. [B. Emergency Vehicles, Maryland Driver's Manual, Section VII - Sharing the Road]","imageName":"202001241703386758.jpg","correctAnswer":32338,"isBookmarked":0,"aid":"15493#28148#32337#32338","text":"Slow down#Proceed with caution#Leave a vacant lane between you and the emergency vehicle if possible; otherwise, slow to 20 mph#Leave a vacant lane between you and the emergency vehicle if possible; otherwise, slow to a safe speed"},
{"testId":648,"qid":28696,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop behind the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Regulatory Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30729","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when safe; no turns are allowed here"},
{"testId":648,"qid":28697,"description":"What is the meaning of this sign?","explanation":"The legal maximum speed restriction is shown on this sign. You are not permitted to exceed 50 mph in this area. [Regulatory Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":648,"qid":28698,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#33133","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Michigan"},
{"testId":648,"qid":28699,"description":"Which of the following statement is correct?","explanation":"When two vehicles arrive at an uncontrolled junction or an all-way stop about the same time, the vehicle on the left must yield to the one on the right. Car A must yield to the right here, and Car B has the right-of-way. [Right of Way, Traffic Laws, What Every Driver Should Know]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#33134","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver must take the right-of-way and proceed first"},
{"testId":648,"qid":28700,"description":"You approach an intersection and notice this sign. What must you do?","explanation":"This is a yield symbol. Before continuing, you must slow down and ready to yield to pedestrians and vehicles ahead of you as you approach a yield sign. Be prepared to halt if necessary. [Regulatory Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":648,"qid":28701,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. However, if you are in the midst of an intersection and an emergency vehicle arrives, do not stop. Instead, go through the junction and then pull over immediately. [5 Sharing the Road Safely - Be Courteous!, What Every Driver Should Know]","imageName":"202001302235285631.jpg","correctAnswer":33136,"isBookmarked":0,"aid":"15493#26798#33135#33136","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if you are traveling in the same direction as the ambulance#Pull over, no matter which direction you are traveling in"},
{"testId":648,"qid":28702,"description":"When there is a double solid white line to your left,:","explanation":"[Solid White Lines, Pavement Markings, 5 Signs, Pavement Markings, and Signals, What Every Driver Must Know] A double solid white line marks the edge of a traffic lane where travel in the same direction is permitted on both sides of the line but crossing the line is prohibited. Double solid white lines are frequently used on freeways to separate a high-occupancy vehicle (HOV) lane from other lanes traveling in the same direction.","imageName":"202003202134094720.jpg","correctAnswer":33139,"isBookmarked":0,"aid":"33137#33138#33139#33140","text":"you are free to change lanes here#you should try to avoid changing lanes here#changing lanes here is prohibited#you can only cross these lines to turn left"},
{"testId":648,"qid":28703,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and school crossing signs (five sided). Proceed with care in the vicinity of these signs. Keep an eye out for youngsters and other pedestrians. [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#25354","text":"A school zone sign#A work zone sign#A warning sign#A no-passing sign"},
{"testId":648,"qid":28704,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"21234#28850#30733#31728","text":"Yes#No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after you yield to all traffic and pedestrians already in the intersection"},
{"testId":648,"qid":28705,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal unless there are signs banning it. However, you must first come to a full stop before yielding to all pedestrians and other vehicles. [Traffic Signals, Chapter 5: What Every Driver Should Know About Signs, Pavement Markings, and Signals]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#26802#33141","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn, so you have the right-of-way#No. In Michigan, you cannot turn right at a red signal"},
{"testId":648,"qid":28706,"description":"Who is required to wear a seat belt or kid restraint in Michigan?","explanation":"According to Michigan law, the driver, all front-seat passengers, and all passengers under the age of 16 must wear a seat belt or use an appropriate child restraint. However, for their own safety, all passengers of your car should be fastened up. Seat belts have been shown in studies to save lives. [Buckle Up - It's the Law!, What Every Driver Should Know About 2 Seat Belts, Safety Seats, and Air Bags]","imageName":"202003151755527296.jpg","correctAnswer":33143,"isBookmarked":0,"aid":"16319#16320#33142#33143","text":"Just the driver#The driver and all passengers under 16#No one (seat belts are optional in Michigan)#The driver, all front-seat passengers, and all passengers under 16"},
{"testId":648,"qid":28707,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"The vehicle on the left must make way for the one on the right at an uncontrolled junction or an all-way stop. At this point, Car A must let Car B go. Traffic Laws, What Every Driver Must Know, Chapter 4: Right of Way at Intersections","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":648,"qid":28708,"description":"What is the meaning of this sign?","explanation":"A triangular orange insignia on the back of a car designates it as a slow-moving vehicle, which in Michigan is classified as a vehicle that does not exceed 25 mph. Farm tractors, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"148#627#629#1075","text":"Construction zone ahead#Stop#Slow-moving vehicle#No passing"},
{"testId":648,"qid":28709,"description":"Three vehicles arrive at a T-junction. In what order should they go?","explanation":"A vehicle turning left at a T-intersection must surrender to all traffic on the main (through) route. Car A must yield to Cars B and C in this situation. In addition, since it is turning left at a junction, Car C must yield to oncoming traffic. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [Right of way at junctions, Chapter 4: Traffic Laws, What Every Driver Should Know], [Section 257.650, Michigan Compilation of Statutes]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#16258","text":"A, B, C#C, B, A#B, C, A#B, A, C"},
{"testId":648,"qid":28710,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a shared center turning lane with a two-way left turn lane. This lane allows vehicles driving in either direction to make a left turn. Proceed with care and keep an eye out for oncoming vehicles while turning left here. This lane should never be used for passing. [Center Lane, Only Left Turn, Pavement Markings, 5 Signs, Pavement Markings, and Signals Every Driver Should Know]","imageName":"202001301806403967.jpg","correctAnswer":32336,"isBookmarked":0,"aid":"16098#16322#30411#32336","text":"A reversible lane#A central parallel parking lane#A High-Occupancy Vehicle (HOV) lane#A shared left-turn lane"},
{"testId":648,"qid":28711,"description":"What is the meaning of this sign?","explanation":"This regulation sign warns the presence of a traffic island or other impediment ahead. Continue straight. [Regulatory Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003151752201841.jpg","correctAnswer":14994,"isBookmarked":0,"aid":"1353#14994#16263#16264","text":"Divided highway ahead#Keep right#Chicane ahead#Left turns prohibited"},
{"testId":648,"qid":28712,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003051752141237.jpg","correctAnswer":29961,"isBookmarked":0,"aid":"28857#29961#29962#29963","text":"Speed up to get through the signal before it turns red#Stop if it is safe to do so; the signal will soon turn red#Proceed with caution; there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":648,"qid":28713,"description":"Which of the following is true about construction zones on highways?","explanation":"Construction personnel in a work zone have the ability to guide traffic around risks and through the work zone. Their instructions take precedence over any traffic signs or signals in the area. You must follow the instructions of the construction personnel. Otherwise, respect all work zone signs and speed limitations. Moving offenses are doubled in a work zone. [Construction Zones, Traffic Laws, What Every Driver Should Know]","imageName":"202002101222518967.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#33144#33145#33146","text":"All of the above are true#A construction worker's directions have priority over regulatory signs or traffic signals#You must always obey work zone signs#There are strict penalties for moving violations in work zones"},
{"testId":648,"qid":28714,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":"To cross tracks, it is usually safer to ride inside your lane. Crossing tracks at a 90-degree angle may send you into another lane of traffic. To cross parallel railroad lines, ruts, or pavement seams, travel far enough away from them to cross at an angle of at least 45 degrees.","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#33147","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should take the right-of-way and proceed first"},
{"testId":648,"qid":28715,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"The \"No Passing Zone\" sign is designated with a pennant form. This sign indicates that passing other cars is not permitted here. [Regulatory Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":648,"qid":28716,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red light prevents vehicles from continuing straight through the junction, whilst the green arrow permits cars to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. As a result, you have the right of way to turn left. You must still yield to cars already at the junction and pedestrians crossing the street. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003070834498321.jpg","correctAnswer":33148,"isBookmarked":0,"aid":"33148#33149#33150#33151","text":"Yes, this is a \"protected\" turn#Yes, but you do not have the right-of-way#No, you must wait for the red signal to change#No, these lights are out of order"},
{"testId":648,"qid":28717,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green signal, you may proceed through the intersection after yielding to all vehicles and pedestrians already present. Despite having a green light, Car A must yield to Car B since Car B is already in the intersection. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#25313#25314","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B because Car B is already in the intersection#Car A should line up behind Car B in the intersection"},
{"testId":648,"qid":28718,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003051722347868.jpg","correctAnswer":33669,"isBookmarked":0,"aid":"33669#45296#45297#45298","text":"Come to a complete stop, yield, and only proceed when safe#Reduce your speed and proceed with caution#Stop and do not proceed until the signal becomes flashing green#Only left or right turns are allowed here"},
{"testId":648,"qid":28719,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending roundabout. You should slow down and prepare to yield to oncoming vehicles at the junction. [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#33152#33153#33154","text":"There is a roundabout ahead#Only left turns are allowed here#There is a shared left-turn lane ahead#There is a 270-degree freeway ramp ahead"},
{"testId":648,"qid":28720,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Keep an eye out for warning lights and oncoming trains. Slow down and prepare to come to a halt. [Railroad Crossings: 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":648,"qid":28721,"description":"What should you do if you come upon a railroad crossing?","explanation":"If the lights are flashing red, the bell is ringing, or the gate is down, you must come to a complete stop. Cross only once the signals have stopped or the crossing gate has fully raised and no oncoming trains are visible. Keep in mind that buses and vehicles transporting hazardous items must stop at all crossings. Never try to cross the rails unless there is space on the other side for your car. [Railroad Crossings, Chapter 4: What Every Driver Should Know]","imageName":"202003161136554337.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"23000#33155#33156#33157","text":"Do all of the above#Follow buses and trucks carrying hazardous materials carefully because they must stop at every railroad crossing#If the crossing signals are flashing red, you must stop#Cross the tracks only if there is enough room for your vehicle on the opposite side"},
{"testId":648,"qid":28722,"description":"You come to a railroad crossing with gates, bells, flashing lights, or signs with messages. What must you do?","explanation":"Active railroad control systems warn motorists of the presence of a train. These systems may consist of a mix of gates, flashing lights, messaging signs, and bells or other auditory warning devices. Stop when the bells ring, the lights flash, or the gate is down or in motion, regardless of the active control system you meet. Do not drive through, around, or under any railroad crossing system that has been triggered. Once the train has passed, do not continue until the gates are opened, the lights have stopped blinking, the bells have stopped wailing, and all tracks are clear. [Active railroad crossing control systems, Traffic Laws, What Every Driver Should Know]","imageName":"202003161136554337.jpg","correctAnswer":45720,"isBookmarked":0,"aid":"45718#45719#45720#45721","text":"Stop only when the bells are ringing#Stop only if the lights are flashing#Stop no matter what active control system you encounter#Stop only if the gate is down or in motion"},
{"testId":648,"qid":28723,"description":"Which of the following is true about the speed limits in Michigan?","explanation":"You must not go faster than the specified maximum speed limit. Under present driving circumstances, Michigan law requires you to drive at a \"careful and sensible\" speed. That is, you must drive at a pace that allows you to safely stop under present road conditions. You must slow down in severe weather, high traffic, or other dangerous road conditions. Even if you are not breaking the official speed limit, you might be penalized for driving too fast for the circumstances. [Speed Control, 3 Basic Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#33160#33161#33162","text":"All of the above are true#You must never exceed the posted speed limit#You must drive at a speed that is careful and prudent#You can be ticketed for driving too fast even if you haven't exceeded the posted speed limit"},
{"testId":648,"qid":28725,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Maintain your position to the right of the median. [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202002101247507801.jpg","correctAnswer":17099,"isBookmarked":0,"aid":"1107#1613#15187#17099","text":"Divided highway ends ahead#Traffic signal ahead#Roundabout ahead#Divided highway begins ahead"},
{"testId":648,"qid":28726,"description":"You notice a pedestrian walking out onto the road with a white cane. What should you do?","explanation":"A person holding a white cane or accompanied by a guiding dog is almost certainly blind or visually challenged. Always slow down and yield to blind pedestrians. If you blow your horn or rev your engine near a blind pedestrian, you may shock the walker or obscure crucial audio clues that the blind pedestrian depends on. [Blind Pedestrians, Tips for Driving While Sharing the Road, 5 Sharing the Road Safely - Be Courteous!, What Every Driver Should Know]","imageName":"202002061105552801.jpg","correctAnswer":31741,"isBookmarked":0,"aid":"28545#31741#33166#33167","text":"Sound your horn to alert the pedestrian to your presence#Stop and yield to the pedestrian#Sound your horn and proceed slowly#Drive around the pedestrian and proceed at your usual speed"},
{"testId":648,"qid":28727,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. This hand gesture is analogous to a vehicle's right turn signals. [3 Basic Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#32533#33168","text":"Turn left#Turn right#stop or slow down#let you pass the driver"},
{"testId":648,"qid":28728,"description":"Parking is not permitted within _______ feet of a railroad crossing.","explanation":"At a railroad crossing, you are not permitted to park within 50 feet of the closest rail. [Never Park Your Vehicle, Parking Your Vehicle, 3 Basic Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202002181114128699.jpg","correctAnswer":7571,"isBookmarked":0,"aid":"5447#5449#6425#7571","text":"15#5#25#50"},
{"testId":648,"qid":28729,"description":"A school bus with flashing red lights has come to a halt ahead. What should you do?","explanation":"Vehicles on both sides of the road must stop at least 20 feet away from a school bus that has stopped with its flashing red lights on. They must stay halted until the bus's flashing red lights have been turned off or the bus has resumed its move. [School Buses: What Do the Flashing Lights Mean?, 5 Sharing the Road Safely - Be Courteous!, What Every Driver Must Know]","imageName":"202003051658529900.jpg","correctAnswer":32323,"isBookmarked":0,"aid":"28148#32322#32323#33169","text":"Proceed with caution#Stop at least 20 feet away if you are traveling in the same direction as the bus#Stop at least 20 feet away, no matter which direction you are traveling in#Slow to 15 mph and proceed with caution"},
{"testId":648,"qid":28730,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must give way to cars that arrived at an uncontrolled junction or an all-way stop before you. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. What Every Driver Must Know About Yielding the Right-of-Way, 3 Basic Skills, Laws, and Safety","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":648,"qid":28731,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a fork on the road ahead. Prepare for the junction by slowing down. [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":648,"qid":28732,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"Before proceeding through a red light, you must come to a complete stop and yield to all cars and pedestrians. [How to Turn Right at a Red Light, 3 Basic Skills, Laws, and Safety That Every Driver Should Know]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":648,"qid":28733,"description":"Which of the following statements is true?","explanation":"From half an hour after dusk to half an hour before daybreak, you must use your headlights. You must also use your headlights anytime visibility is poor at 500 feet or in bad weather. When headlights are necessary, do not use parking lights. [How to Use Headlights, 6 Emergencies and Special Situations Every Driver Should Know]","imageName":"202003161149128995.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"15516#23029#31432#33170","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#All of the above are true#You must use your headlights whenever visibility is difficult at 500 feet#You should use your headlights whenever it is foggy, snowy, or rainy"},
{"testId":648,"qid":28734,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":"A flashing yellow turn arrow allows you to turn left. You, however, do not have the right-of-way. Oncoming traffic has a green light, thus you must yield to them before turning left. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003070851013728.jpg","correctAnswer":45802,"isBookmarked":0,"aid":"33171#33172#33174#45802","text":"You can turn left; this is a \"protected\" turn#The signal will soon turn red; stop if you can do so safely#You are not allowed to turn left; find another route#Slow down and turn left when the way is clear of oncoming traffic"},
{"testId":648,"qid":28735,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of an impending steep fall. Examine your brakes. Reduce your speed and keep an eye out for falling cars. Reduce the requirement for braking by using a low gear (even if you have an automatic gearbox). Overuse of your brakes for an extended period of time may result in brake fade (loss of braking effectiveness). [Warning Signs, Signs, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#32330#32331","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks frequently enter from a steep side road ahead#Trucks frequently cross ahead"},
{"testId":648,"qid":28736,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for blind spots. [How to Prepare to Drive, 3 Fundamental Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":648,"qid":28737,"description":"A line of white triangles crosses your lane. What do they imply?","explanation":"A yield line is a line of white triangles that runs across a lane. You must be prepared to stop and yield to pedestrians and other cars here. Roundabouts and mid-block crosswalks are common locations for yield lines. [White Triangular Symbols, Pavement Markings, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003202136149229.jpg","correctAnswer":33175,"isBookmarked":0,"aid":"33175#33176#33177#33178","text":"This is a yield line. Prepare to yield, stopping if necessary#This is a stop line. You must stop here before proceeding#This is the start of a school zone. Slow down and proceed with caution#This is the start of a no-passing zone. You cannot pass other vehicles here"},
{"testId":648,"qid":28738,"description":"When driving at night, high beams might help you see better. When, on the other hand, are you legally compelled to dim your headlights?","explanation":"When you are within 500 feet of an incoming vehicle, you are obligated by law to lower your high lights. You should also avoid following another vehicle closely with your high lights turned on. Always lower your headlights before shining them on an occupied vehicle, bike, or pedestrian. [How to Use Headlights, 7 Emergencies and Special Situations Every Driver Should Know]","imageName":"202002211503506612.jpg","correctAnswer":33180,"isBookmarked":0,"aid":"6334#33179#33180#33181","text":"Never#When you are within 300 feet of an oncoming vehicle#When you are within 500 feet of an oncoming vehicle#When you are within 500 feet of a vehicle you are following"},
{"testId":648,"qid":28739,"description":"Which of the following places may you park legally?","explanation":"Parking is prohibited within 15 feet of a fire hydrant, on a bridge, or on the sidewalk. You may parallel park up to 12 inches from the curb as long as you do not block traffic movement. [Never Park Your Vehicle, Parking Your Vehicle, 3 Basic Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":648,"qid":28740,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Michigan?","explanation":"According to the Michigan guide \"What Every Driver Should Know,\" a three-second distance between you and the car in front of you is recommended. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [Stopping distances, What Every Driver Should Know, Chapter 7: Emergencies and Special Situations]","imageName":"202001302249003541.jpg","correctAnswer":45736,"isBookmarked":0,"aid":"15926#19915#33183#45736","text":"At least two seconds#At least 20 feet#Far enough to see the brake lights of the vehicle ahead of you#At least three-four seconds"},
{"testId":648,"qid":28741,"description":"You are on a highway with two lanes. You get close to a school bus with flashing red lights that has stopped on the other side of the highway. Should you stop here?","explanation":"When the red lights on a school bus flash, cars moving in either direction must stop. There is, however, one exception to this rule. Vehicles are not required to stop for a school bus with flashing red lights on the other side of a split highway (a roadway with a non-drivable median or barrier). [School Buses: What Do the Flashing Lights Mean?, 5 Sharing the Road Safely - Be Courteous!, What Every Driver Must Know]","imageName":"202002010826098089.jpg","correctAnswer":15696,"isBookmarked":0,"aid":"15696#33184#33186#40002","text":"No, you do not need to stop here#No, but you must slow down to 15 mph and proceed with caution#Yes, you must stop, no matter which direction you are traveling in#Yes, you must stop, yield to pedestrians, and then proceed with caution"},
{"testId":648,"qid":28742,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, yield to vehicles and pedestrians, and continue with care if you observe a flashing yellow light at a junction. [Traffic Control Signals and Signals, 5 Signs, Pavement Markings, and Signals Every Driver Should Understand]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#26784#29963#31848","text":"Slow down and proceed with caution#Stop if safe; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight when safe; you cannot turn here"},
{"testId":648,"qid":28743,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Before moving into the correct lane, vehicles approaching a highway via an entry ramp must cede the right-of-way to traffic already on the roadway.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25308#25311","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#This is an uncontrolled intersection; one driver must wave the other one through#The car on the busier road has the right-of-way"},
{"testId":648,"qid":28744,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"You must always obey regulation signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Avoid changing lanes inside a multilane roundabout as well. Unless otherwise indicated, turn left from the left lane, right from the right lane, and drive straight through using either lane in a two-lane roundabout. Car C has made an improper left turn from the right lane. [What Every Driver Should Know About Roundabouts, 3 Basic Skills, Laws, and Safety]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33187","text":"Car A#Car B#Car C#None (i.e., all cars are using the roundabout correctly)"},
{"testId":651,"qid":28746,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":null,"imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30729","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when safe; no turns are allowed here"},
{"testId":651,"qid":28747,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":29939,"isBookmarked":0,"aid":"15476#15478#15479#29939","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal speed limit is 50 mph"},
{"testId":651,"qid":28748,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":34163,"isBookmarked":0,"aid":"29975#34161#34162#34163","text":"No, you are only required to signal when other vehicles are present#No, turn signals are optional in Missouri#Yes, you should signal for at least 50 feet before you turn#Yes, you should signal for at least 100 feet before you turn"},
{"testId":651,"qid":28749,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":651,"qid":28750,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":651,"qid":28751,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":651,"qid":28752,"description":"Which of the following statement about right-of-way is true?","explanation":null,"imageName":"202007120454477868.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31413#31416#34166#34167","text":"You should claim the right-of-way whenever you safely can#The right-of-way is to be given, not taken#The right-of-way is indisputable#You are free to proceed whenever you have the right-of-way"},
{"testId":651,"qid":28753,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":651,"qid":28754,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"21234#28850#30733#31728","text":"Yes#No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after you yield to all traffic and pedestrians already in the intersection"},
{"testId":651,"qid":28755,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#34169#34170","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn; approaching traffic is stopped#No. In Missouri, you cannot turn right at a red signal"},
{"testId":651,"qid":28756,"description":"Which occupants of the car must wear seat belts if the driver has an intermediate license?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#26667#34171","text":"Just the driver#The driver and all passengers#The driver and front-seat passengers#No one (seat belts are optional in Missouri)"},
{"testId":651,"qid":28757,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":651,"qid":28758,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"148#627#629#1075","text":"Construction zone ahead#Stop#Slow-moving vehicle#No passing"},
{"testId":651,"qid":28759,"description":"Which vehicles are turning left correctly here?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":651,"qid":28760,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":null,"imageName":"202001301806403967.jpg","correctAnswer":16786,"isBookmarked":0,"aid":"16098#16322#16786#28541","text":"A reversible lane#A central parallel parking lane#A shared center lane#A high-occupancy vehicle (HOV) lane"},
{"testId":651,"qid":28761,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"25324#28873#28874#32318","text":"Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only#No one (you have the right-of-way)"},
{"testId":651,"qid":28762,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#28880","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop"},
{"testId":651,"qid":28763,"description":"Which of the following should be adhered to above all others?","explanation":null,"imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#2529#14886#16371","text":"A stop sign#A traffic signal#A traffic officer#A regulatory sign"},
{"testId":651,"qid":28764,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":651,"qid":28765,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":null,"imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":651,"qid":28766,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":33148,"isBookmarked":0,"aid":"33148#33150#34174#34175","text":"Yes, this is a \"protected\" turn#No, you must wait for the red signal to change#Yes, but you must yield to oncoming vehicles before turning#No, these lights are clearly out of order"},
{"testId":651,"qid":28767,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":25313,"isBookmarked":0,"aid":"15541#15656#23403#25313","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B because Car B is already in the intersection"},
{"testId":651,"qid":28768,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":34176,"isBookmarked":0,"aid":"28148#34176#34177#34178","text":"Proceed with caution#Stop as if it were a stop sign#Yield as if it were a yield sign#Stop until the signal stops flashing"},
{"testId":651,"qid":28769,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#33152#33154#34179","text":"There is a roundabout ahead#Only left turns are allowed here#There is a 270-degree freeway ramp ahead#There is a two-way left-turn lane ahead"},
{"testId":651,"qid":28770,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":651,"qid":28771,"description":"What should you do if you come upon a railroad crossing?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"23000#34180#34181#34182","text":"Do all of the above#Follow school buses carefully because they must stop at every railroad crossing#Always stop at a set of flashing red crossing signals#Cross only if you have a clear path across the tracks"},
{"testId":651,"qid":28772,"description":"You come to a railroad crossing with red flashing lights and warning bells. It's clear that a train is coming. Which of the following is true?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":30413,"isBookmarked":0,"aid":"16334#30413#33158#33159","text":"Immediately in front of the tracks#Between 15 and 50 feet from the tracks#10 feet from the tracks#50 feet from the tracks"},
{"testId":651,"qid":28773,"description":"Which of the following about speed limits in Missouri is true?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#34183#34184#34185","text":"All of the above are true#You must not exceed the speed limit#You must slow down below the minimum speed limit when conditions demand it#You can be driving too fast even if you aren't exceeding the speed limit"},
{"testId":651,"qid":28774,"description":"Is it legal to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":34186,"isBookmarked":0,"aid":"26802#30751#33164#34186","text":"Yes, this is a \"protected\" turn, so you have the right-of-way#Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#No, not in Missouri"},
{"testId":651,"qid":28775,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15654#15668#34060#34187","text":"There is a roundabout ahead#There is a divided highway ahead#There is a traffic signal ahead#Stay in your current lane"},
{"testId":651,"qid":28776,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":null,"imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#34188#34189#34190","text":"Never#If the vehicle is turning left#If you are about to make a right turn at a busy intersection#If the shoulder is at least 12 feet wide and clearly marked"},
{"testId":651,"qid":28777,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":651,"qid":28778,"description":"Unless otherwise posted, the speed limit in a Missouri village, town, or city is :","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":651,"qid":28779,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34195#34196","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":651,"qid":28780,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":651,"qid":28781,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":651,"qid":28782,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":651,"qid":28783,"description":"Which of the following statements is true?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"15516#28163#32325#34197","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#All of the above statements are true#You must use your headlights whenever you use your windshield wipers in poor weather#Never drive so fast that you wouldn't be able to stop within the range of your headlights"},
{"testId":651,"qid":28784,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":null,"imageName":"202003070851013728.jpg","correctAnswer":34199,"isBookmarked":0,"aid":"33172#34198#34199#34200","text":"The signal will soon turn red; stop if you can do so safely#You can turn left here; this is a \"protected\" turn#You can turn left here, but you must first yield to oncoming traffic#You cannot turn left here; find another route"},
{"testId":651,"qid":28785,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":651,"qid":28786,"description":"Which of the following steps is not required when changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":651,"qid":28787,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":null,"imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":651,"qid":28788,"description":"When do your high-beam headlights need to be dimmed?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":34201,"isBookmarked":0,"aid":"29949#34201#34202#34203","text":"When you are within 500 feet of another vehicle#When you are within 500 feet of an oncoming vehicle or following a vehicle within 300 feet#When you are within 300 feet of an oncoming vehicle or following a vehicle within 500 feet#When you are within 300 feet of another vehicle"},
{"testId":651,"qid":28789,"description":"Which of the following parking lots are you permitted to use?","explanation":null,"imageName":"1371158818_t1q44.png","correctAnswer":16391,"isBookmarked":0,"aid":"15951#15952#16390#16391","text":"B) On a bridge#C) On the sidewalk#A) Next to a fire hydrant#D) None of the above"},
{"testId":651,"qid":28790,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Missouri?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#33183","text":"At least two seconds#At least three seconds#At least 20 feet#Far enough to see the brake lights of the vehicle ahead of you"},
{"testId":651,"qid":28791,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#34206","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the bus driver waves you on or the bus starts moving again"},
{"testId":651,"qid":28792,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#29963#30157#30765","text":"Slow down and proceed with caution#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here#Stop if safe; the signal will soon turn red"},
{"testId":651,"qid":28793,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":651,"qid":28794,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":651,"qid":28795,"description":"When you go by a stopped emergency vehicle with red or red and blue lights on, you must :","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34092,"isBookmarked":0,"aid":"6476#24427#28783#34092","text":"Slow down#increase your speed and change lanes#stop and move toward the right edge of the road#move into a non-adjacent lane if possible; otherwise, slow down"},
{"testId":652,"qid":28796,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":null,"imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30729","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when safe; no turns are allowed here"},
{"testId":652,"qid":28797,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":26791,"isBookmarked":0,"aid":"15476#15478#15479#26791","text":"The recommended speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph#The legal maximum speed limit is 50 mph"},
{"testId":652,"qid":28798,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":34486,"isBookmarked":0,"aid":"29975#31404#34485#34486","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Montana#Yes, you must signal for at least 100 feet in town or 300 feet on rural roads before you turn"},
{"testId":652,"qid":28799,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":652,"qid":28800,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31033#31405#31726","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Slow down and prepare to yield to traffic ahead, but you will not need to stop#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you"},
{"testId":652,"qid":28801,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":652,"qid":28802,"description":"Which of the following statement about right-of-way is true?","explanation":null,"imageName":"202003151700371291.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31413#31416#34166#34167","text":"You should claim the right-of-way whenever you safely can#The right-of-way is to be given, not taken#The right-of-way is indisputable#You are free to proceed whenever you have the right-of-way"},
{"testId":652,"qid":28803,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":652,"qid":28804,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"28850#30733#31728#34487","text":"No, you must stop#Yes, but only after you briefly stop and yield#Yes, but only after you yield to all traffic and pedestrians already in the intersection#Yes, right away"},
{"testId":652,"qid":28805,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#34169#34488","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn; approaching traffic is stopped#No. In Montana, you cannot turn right at a red signal"},
{"testId":652,"qid":28806,"description":"Which vehicle occupants in Montana are required to use seat belts or appropriate kid restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#33143#34489","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 16#No one (seat belts are optional in Montana)"},
{"testId":652,"qid":28807,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":652,"qid":28808,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101245562248.jpg","correctAnswer":45365,"isBookmarked":0,"aid":"45365#45366#45367#45368","text":"The bridge ahead may be too narrow to pass another vehicle safely, proceed with caution#Trucks are allowed if the bridge is narrow#Only one-way traffic is allowed on the bridge ahead#Only one car is allowed on the bridge ahead"},
{"testId":652,"qid":28809,"description":"Which vehicles are turning properly here?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":652,"qid":28811,"description":"You're going to turn to the left into a driveway. Who do you have to give way to here?","explanation":null,"imageName":"202001241717155576.jpg","correctAnswer":25324,"isBookmarked":0,"aid":"25324#28873#28874#32318","text":"Pedestrians and oncoming vehicles#Oncoming vehicles only#Pedestrians only#No one (you have the right-of-way)"},
{"testId":652,"qid":28812,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#28880","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Treat the intersection as an all-way stop"},
{"testId":652,"qid":28813,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202003241433015085.jpg","correctAnswer":16166,"isBookmarked":0,"aid":"15951#15952#16166#29321","text":"B) On a bridge#C) On the sidewalk#D) 12 inches from the curb#A) 10 feet from a fire hydrant"},
{"testId":652,"qid":28814,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":652,"qid":28815,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":null,"imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":652,"qid":28816,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34491#34492","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming traffic before turning#No, you must wait for the red light to change"},
{"testId":652,"qid":28817,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":652,"qid":28818,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":34176,"isBookmarked":0,"aid":"28148#34176#34177#34494","text":"Proceed with caution#Stop as if it were a stop sign#Yield as if it were a yield sign#Stop and remain stopped until the signal stops flashing"},
{"testId":652,"qid":28820,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":652,"qid":28821,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":28880,"isBookmarked":0,"aid":"28880#29346#34495#34496","text":"Treat the intersection as an all-way stop#Treat the intersection as an uncontrolled intersection#Yield to traffic on the busier or main road#Sound your horn and drive through"},
{"testId":652,"qid":28822,"description":"As you come to a railroad crossing, a train approaches. Where must you stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":30412,"isBookmarked":0,"aid":"16907#30412#33158#34497","text":"Right in front of the tracks#15 feet from the tracks#10 feet from the tracks#30 feet from the tracks"},
{"testId":652,"qid":28823,"description":"Which of the following about the speed limits in Montana is true?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#27663#34498#34499","text":"All of the above are true#You can drive unlawfully fast without exceeding the posted speed limit#You may exceed the speed limit by 10 mph to safely pass another vehicle on a two-lane road#Legal maximum speed limits are for ideal conditions"},
{"testId":652,"qid":28825,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15654#15668#34060#34502","text":"There is a roundabout ahead#There is a divided highway ahead#There is a traffic signal ahead#Stay in your lane"},
{"testId":652,"qid":28826,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":null,"imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#34188#34189#34190","text":"Never#If the vehicle is turning left#If you are about to make a right turn at a busy intersection#If the shoulder is at least 12 feet wide and clearly marked"},
{"testId":652,"qid":28827,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":652,"qid":28829,"description":"What must you do if you approach or overtake a school bus from either direction that is stopped on an undivided route with flashing red lights?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":45390,"isBookmarked":0,"aid":"45387#45388#45389#45390","text":"Stop and do not proceed until you feel safe to do so#Stop at least 30 feet from the bus and proceed only when you see all children have loaded or unloaded#Stop at least 30 feet from the bus and do not proceed until the bus starts moving again#Stop at least 30 feet from the bus and do not proceed until the red lights stop flashing"},
{"testId":652,"qid":28830,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":652,"qid":28831,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061027163210.jpg","correctAnswer":3983,"isBookmarked":0,"aid":"3983#3984#3985#31598","text":"A safe speed indication for a curve ahead to the right#A minimum suggested speed on a highway#The posted speed limit for a sharp right turn#The posted speed limit for an S-shaped curve"},
{"testId":652,"qid":28832,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":652,"qid":28833,"description":"Which of the statements below is false?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":34506,"isBookmarked":0,"aid":"15516#31432#34506#34507","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You must use your headlights whenever visibility is difficult at 500 feet#You can drive with just your parking lights on to make your car easier to see#You must dim your high beams when you are within 1,000 feet of an oncoming vehicle"},
{"testId":652,"qid":28834,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":null,"imageName":"202003070851013728.jpg","correctAnswer":34199,"isBookmarked":0,"aid":"34198#34199#34200#34412","text":"You can turn left here; this is a \"protected\" turn#You can turn left here, but you must first yield to oncoming traffic#You cannot turn left here; find another route#The signal will soon turn red; you can turn left only if you cannot stop safely"},
{"testId":652,"qid":28835,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":652,"qid":28836,"description":"Which of the following steps is not required when changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":652,"qid":28837,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":null,"imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16385","text":"Car A#Car B#Car C#Cars B and C"},
{"testId":652,"qid":28838,"description":"When do your high-beam headlights need to be dimmed?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":34508,"isBookmarked":0,"aid":"29949#29950#34508#34509","text":"When you are within 500 feet of another vehicle#When you are within 1,000 feet of another vehicle#When you are within 1,000 feet of an oncoming vehicle or following a vehicle within 500 feet#When you are within 500 feet of an oncoming vehicle or following a vehicle within 1,000 feet"},
{"testId":652,"qid":28840,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Montana?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#34511","text":"At least two seconds#At least three seconds#At least 20 feet#About 5 feet (enough to see the brake lights of the vehicle ahead of you)"},
{"testId":652,"qid":28841,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#34512","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the red lights have been turned off"},
{"testId":652,"qid":28842,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#30157#30765#34513","text":"Slow down and proceed with caution#Go straight; you cannot turn here#Stop if safe; the signal will soon turn red#Stop, yield, and proceed only when it is safe to do so"},
{"testId":652,"qid":28843,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":652,"qid":28844,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":652,"qid":28845,"description":"What must you do when you pass a stopped emergency vehicle whose lights are flashing or moving?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34516,"isBookmarked":0,"aid":"19471#34514#34515#34516","text":"Slow down and proceed with caution#Try to move into a non-adjacent lane#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down and try to move into a non-adjacent lane"},
{"testId":653,"qid":28846,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":null,"imageName":"202003151731031579.jpg","correctAnswer":30391,"isBookmarked":0,"aid":"15472#28148#30391#30729","text":"Slow down and only proceed if the intersection is clear#Proceed with caution#Come to a complete stop and yield to traffic before proceeding#Proceed straight through when safe; no turns are allowed here"},
{"testId":653,"qid":28847,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":653,"qid":28848,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#34764","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Nebraska"},
{"testId":653,"qid":28849,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":653,"qid":28850,"description":"You approach an intersection and notice this sign. What must you do?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":15488,"isBookmarked":0,"aid":"15488#31405#31726#34765","text":"Slow down and prepare to yield to pedestrians and traffic ahead#Come to a complete stop, yield to pedestrians and traffic ahead, and then proceed#Maintain your speed because all traffic ahead must yield to you#Slow down and line up behind any vehicles already in the intersection"},
{"testId":653,"qid":28851,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":653,"qid":28852,"description":"Unless otherwise indicated, the speed limit in Nebraska's residential districts is.","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":653,"qid":28853,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":653,"qid":28854,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"28850#31728#34487#34766","text":"No, you must stop#Yes, but only after you yield to all traffic and pedestrians already in the intersection#Yes, right away#Yes, but only after you briefly stop"},
{"testId":653,"qid":28855,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#34169#34767","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, this is a \"protected\" turn; approaching traffic is stopped#No. In Nebraska, you cannot turn right at a red signal"},
{"testId":653,"qid":28856,"description":"Which occupants of a vehicle must wear seat belts or proper child restraints if the driver has a Provisional Operator's Permit (POP)?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#34768#34769","text":"Just the driver#The driver and all passengers#None (seat belts are optional in Nebraska)#The driver, all front-seat passengers, and all passengers under 18"},
{"testId":653,"qid":28857,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":653,"qid":28858,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#1075#16692","text":"Stop#Slow-moving vehicle#No passing#Work zone ahead"},
{"testId":653,"qid":28859,"description":"Which vehicles are turning properly here?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":653,"qid":28860,"description":"You see a lane with a double broken yellow line on both sides. What kind of a lane is this?","explanation":null,"imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16322#28541#32336","text":"A reversible lane#A central parallel parking lane#A high-occupancy vehicle (HOV) lane#A shared left-turn lane"},
{"testId":653,"qid":28861,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16385","text":"Car A#Car B#Car C#Cars B and C"},
{"testId":653,"qid":28862,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"25300#28524#28857#28858","text":"Treat the intersection like an all-way stop#Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead"},
{"testId":653,"qid":28863,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":653,"qid":28864,"description":"Two cars approach a road junction at around the same time. Which of the following statements is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":653,"qid":28865,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":null,"imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":653,"qid":28866,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34491#34492","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming traffic before turning#No, you must wait for the red light to change"},
{"testId":653,"qid":28867,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#23403#34493","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#None of the above are true#Car A must yield to Car B"},
{"testId":653,"qid":28868,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":34770,"isBookmarked":0,"aid":"28148#30736#34178#34770","text":"Proceed with caution#Yield to traffic and pedestrians#Stop until the signal stops flashing#Come to a complete stop and then yield to traffic and pedestrians"},
{"testId":653,"qid":28869,"description":"Which vehicles are making proper use of this roundabout?","explanation":null,"imageName":"202002181235214704.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":653,"qid":28870,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#23456","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#Wrong way; go back"},
{"testId":653,"qid":28871,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#34495#34496#34771","text":"Treat the intersection like an all-way stop#Yield to traffic on the busier or main road#Sound your horn and drive through#Treat the intersection like an uncontrolled intersection"},
{"testId":653,"qid":28872,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#34773","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#30 to 60 feet from the tracks"},
{"testId":653,"qid":28873,"description":"What should you do before crossing the street from an alley?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":34774,"isBookmarked":0,"aid":"34774#34775#34776#34777","text":"Stop and yield before crossing the sidewalk#Stop and yield after crossing the sidewalk#Yield before crossing the sidewalk#Yield after crossing the sidewalk"},
{"testId":653,"qid":28874,"description":"Is it legal to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#34500#34778","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, this is a \"protected\" turn; you have the right-of-way#No, not in Nebraska"},
{"testId":653,"qid":28875,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":15668,"isBookmarked":0,"aid":"15654#15668#34060#34502","text":"There is a roundabout ahead#There is a divided highway ahead#There is a traffic signal ahead#Stay in your lane"},
{"testId":653,"qid":28876,"description":"Unless otherwise indicated, the speed limit in Nebraska's business areas is.","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":653,"qid":28877,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":653,"qid":28878,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202005071742529326.jpg","correctAnswer":34505,"isBookmarked":0,"aid":"16391#34503#34504#34505","text":"D) None of the above#A) Facing against the flow of traffic#B) 35 feet from a railroad crossing#C) 35 feet from a stop sign"},
{"testId":653,"qid":28879,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34195#34196","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":653,"qid":28880,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":653,"qid":28881,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101146277756.jpg","correctAnswer":15547,"isBookmarked":0,"aid":"15547#15550#15581#15582","text":"There is a crossroad ahead#There is a railroad crossing ahead#There is a hospital ahead#There is a low bridge ahead"},
{"testId":653,"qid":28882,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15584#15679#28545","text":"Stop and let the pedestrian cross#Speed up and pass the pedestrian#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence"},
{"testId":653,"qid":28883,"description":"Which of the statements below is false?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":34780,"isBookmarked":0,"aid":"23578#34779#34780#34781","text":"You must use your headlights from sunset to sunrise#You should use your headlights whenever you use your windshield wipers#In the daytime, you can drive with just your parking lights on to make your car easier to see#In rain or fog, you should dim your headlights to low beam"},
{"testId":653,"qid":28884,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":null,"imageName":"202003070851013728.jpg","correctAnswer":34199,"isBookmarked":0,"aid":"34198#34199#34200#34782","text":"You can turn left here; this is a \"protected\" turn#You can turn left here, but you must first yield to oncoming traffic#You cannot turn left here; find another route#The signal will soon turn red; turn left only if you cannot stop safely"},
{"testId":653,"qid":28885,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":653,"qid":28886,"description":"Which of the following steps is not required when changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#15687#28883","text":"None of the above#Using your turn signals#Checking your mirrors to see if the road is clear#Looking over your shoulder to double-check whether the road is clear"},
{"testId":653,"qid":28887,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":null,"imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":653,"qid":28888,"description":"Which vehicle is not properly using the roundabout?","explanation":null,"imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":653,"qid":28889,"description":"When is it permissible to cross a double solid yellow line?","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":34785,"isBookmarked":0,"aid":"34783#34784#34785#34786","text":"To pass another vehicle#To pass a vehicle turning right#To make a left turn#To pass a slow-moving vehicle"},
{"testId":653,"qid":28890,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Nebraska?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#34787","text":"At least two seconds#At least three seconds#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":653,"qid":28891,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#34512","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the red lights have been turned off"},
{"testId":653,"qid":28892,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#30729#30765#34513","text":"Slow down and proceed with caution#Proceed straight through when safe; no turns are allowed here#Stop if safe; the signal will soon turn red#Stop, yield, and proceed only when it is safe to do so"},
{"testId":653,"qid":28893,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":653,"qid":28894,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":653,"qid":28895,"description":"You're on a controlled-access highway. A emergency vehicle with bright lights has halted ahead. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34790,"isBookmarked":0,"aid":"15493#34788#34789#34790","text":"Slow down#Nothing; proceed as usual#Pull over to the right#Move one lane away if possible; otherwise, slow down"},
{"testId":654,"qid":28896,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":654,"qid":28897,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction is shown by this sign. You are not permitted to exceed 50 mph in this area. It should be noted that this does not imply that driving at 50 mph is safe in this area; this will depend on the current driving circumstances. Slow down below the maximum speed limit in difficult driving conditions. [Signs; Signs, Signals, and Markings; Chapter 3: Driving Safely; Nevada Driver's Handbook]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":654,"qid":28898,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You must signal for at least 100 feet in commercial or residential sectors and at least 300 feet elsewhere in Nevada. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [Signaling; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202001291433312120.jpg","correctAnswer":35012,"isBookmarked":0,"aid":"29975#35010#35011#35012","text":"No, you are only required to signal when other vehicles are present#No, turn signals are optional in Nevada#Yes, you must signal for at least 300 feet in urban areas and at least 100 feet elsewhere#Yes, you must signal for at least 100 feet in urban areas and at least 300 feet elsewhere"},
{"testId":654,"qid":28899,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to the right here, and Car B has the right-of-way. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":654,"qid":28900,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":654,"qid":28901,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":654,"qid":28902,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202003151700371291.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31413#31416#34167#35015","text":"You should claim the right-of-way whenever you safely can#The right-of-way is to be given, not taken#You are free to proceed whenever you have the right-of-way#The right-of-way is a legal right"},
{"testId":654,"qid":28903,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and keep an eye out for pedestrians. Follow the instructions of the school crossing guards. [School Zones; Signs, Signals, and Markings; Chapter 3: Safe Driving; Nevada Driver's Handbook]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":654,"qid":28904,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003070857473536.jpg","correctAnswer":31728,"isBookmarked":0,"aid":"28850#31728#34487#35016","text":"No, you must stop#Yes, but only after you yield to all traffic and pedestrians already in the intersection#Yes, right away#Yes, but only after you stop first"},
{"testId":654,"qid":28905,"description":"Is it legal to turn right at a red light?","explanation":"Unless there are indications banning it, you may turn right at a red light. However, before turning, you must come to a full stop and yield to all pedestrians and vehicles. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#15509#35017#35018","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, but only if a sign permits you to do so#Yes, but only after yielding to traffic and pedestrians#No. In Nevada, you cannot turn right at a red signal"},
{"testId":654,"qid":28906,"description":"Which vehicle occupants in Nevada are required to wear seat belts or appropriate kid restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#34769#35019","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#None; seat belts are optional in Nevada"},
{"testId":654,"qid":28907,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":654,"qid":28908,"description":"Which of the following Nevada speed limit statements is FALSE?","explanation":"Even while passing another vehicle, you must not exceed the legal speed limit. Nevada law also requires you to travel at a \"reasonable and appropriate\" speed given the current driving circumstances. In bad weather, you must drive slower than the stated speed limit. Otherwise, you risk being fined for driving too fast for the circumstances. (A conviction will result in two points being added to your driving record.) Driving too slowly, on the other hand, may be harmful since it may tempt other cars to pass you even under risky situations. If you are driving so slowly that five or more other cars are stranded behind you, Nevada law requires you to pull over as soon as it is safe to do so and let them pass. [Controlling Speed, Nevada Driver's Handbook, Chapter 3: Driving Safely], [NRS 484B] .630]","imageName":"202003151746465721.jpg","correctAnswer":35020,"isBookmarked":0,"aid":"35020#35021#35022#35023","text":"You can exceed the speed limit by 10 mph when passing on a two-lane highway#It can be unsafe to drive too slowly#You can violate Nevada's speed laws even if you're not exceeding the posted speed limit#You must not drive faster than 15 mph in a school zone or 25 mph in a school crossing zone when school is in session"},
{"testId":654,"qid":28909,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [Turning; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":654,"qid":28910,"description":"You can see a lane marked by solid and broken yellow lines. What sort of a lane is this?","explanation":"This is a turn lane in the middle. Vehicles moving in either direction may turn left into or out of this lane. Proceed with care and keep an eye out for oncoming vehicles while turning left here. Never drive in this lane for more than 200 feet and never pass in it. [Highway Markings; Signs, Signals, and Markings; Chapter 3: Safe Driving; Nevada Driver's Handbook]","imageName":"202001301806403967.jpg","correctAnswer":35024,"isBookmarked":0,"aid":"16098#16478#28541#35024","text":"A reversible lane#A central parking area#A high-occupancy vehicle (HOV) lane#A center turn lane"},
{"testId":654,"qid":28911,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"When approaching a crossroads, a motorist must give the right-of-way to cross traffic before advancing. Cars A and C have both passed yield signs, hence Car B (cross traffic) has the right of way. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16385","text":"Car A#Car B#Car C#Cars B and C"},
{"testId":654,"qid":28912,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop or you'll get rear-ended. Before you brake, always consider the cars behind you. If you cannot safely stop, drive through the junction with care. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003051752141237.jpg","correctAnswer":35025,"isBookmarked":0,"aid":"25300#28857#28858#35025","text":"Treat the intersection like an all-way stop#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop before the intersection if it is safe to do so"},
{"testId":654,"qid":28913,"description":"Which of the following places may you park legally?","explanation":"You may not parallel park or angle park within 15 feet of a fire hydrant or within 20 feet of a fire hydrant. Parking on a bridge, on the sidewalk, or more than 18 inches from the curb is prohibited. (There are also additional areas where parking is prohibited.) Look for signs or colored curb lines prohibiting or limiting parking.) The automobile in response D) has parked lawfully 10 inches from the curb. [Parallel Parking, Parking, Nevada Driver's Handbook, Chapter 3: Driving Safely], [No Parking Allowed, Parking, Nevada Driver's Handbook],","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":654,"qid":28914,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":654,"qid":28915,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":654,"qid":28916,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to any cars already at the junction and any crossing pedestrians. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34491#34492","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming traffic before turning#No, you must wait for the red light to change"},
{"testId":654,"qid":28917,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Right-of-Way, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":654,"qid":28918,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#35028#35029","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#This signal is out of order; proceed with caution#Yield to all vehicles unless you are on the busier road"},
{"testId":654,"qid":28919,"description":"Which vehicles are making proper use of this roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Car A is doing a U-turn here by beginning from the left lane. Car B is performing a left turn after beginning in the wrong lane. [Roundabouts, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202002181235214704.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":654,"qid":28920,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. [Signs, Signals, and Markings; Chapter 3: Driving Safely; Nevada Driver's Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#27259#35030#35031","text":"There is a railroad crossing ahead#You are going the wrong way; go back#There is a four-way stop ahead#There is an uncontrolled intersection (i.e., one without signs or signals) ahead"},
{"testId":654,"qid":28921,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"In Nevada, a broken signal must be treated as a stop sign. That is, you must stop, surrender, and advance only when it is safe to do so (with great care). If none of the junction's lights are functional, regard the intersection as an all-way stop. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#34495#34496#35032","text":"Treat the intersection like an all-way stop#Yield to traffic on the busier or main road#Sound your horn and drive through#Treat the intersection like an uncontrolled intersection (i.e., one without signs or signals)"},
{"testId":654,"qid":28922,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":"When you come to a complete stop at a railroad crossing, you must do so at the stop line. If no stop line exists, you must stop between 15 and 50 feet from the rails. Trains are at least six feet wider than the tracks they operate on, so stay away from them. [NRS 484B] [Railroad Crossings; Signs, Signals, and Markings; Chapter 3: Driving Safely; Nevada Driver's Handbook] .553]","imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#34773","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#30 to 60 feet from the tracks"},
{"testId":654,"qid":28923,"description":"Which vehicles are making proper use of this roundabout?","explanation":"Car A is following the rules of the roundabout. Car A seems to have missed its exit the first time, but it is behaving appropriately by traveling all the way around the roundabout to try again. Car B has entered the left lane from the right, which is wrong. [Roundabouts, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202003202132152250.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":654,"qid":28926,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"At no point is it permitted to drive on the shoulder to pass another car. [Passing Another Vehicle; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#9435#34188#35035","text":"Never#At any time#If the vehicle is turning left#If the posted speed limit is 25 mph or less"},
{"testId":654,"qid":28927,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This motorist is indicating a right turn. [Signaling; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":654,"qid":28929,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If a school bus stops with its red lights flashing in Nevada, cars moving in either direction must stop and stay halted until the flashing red lights are turned off. [School Buses, Nevada Driver's Handbook, Chapter 5: Sharing the Road]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34196#34928","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":654,"qid":28930,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must give way to cars that arrived at the junction ahead of you during an all-way stop. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. [Nevada Driver's Handbook, Chapter 3: Driving Safely, Right-of-Way]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":654,"qid":28931,"description":"Unless otherwise indicated, the speed limit in residential zones in Nevada is .","explanation":"Unless otherwise marked, the speed limit in commercial and residential zones in Nevada is 25 mph. A moped is officially classified as a vehicle with a max speed of 30 miles per hour. [Controlling Speed, Nevada Driver's Handbook, Chapter 3: Driving Safely], [NRS 486] .038]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":654,"qid":28932,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":654,"qid":28933,"description":"Which of the statements below is false?","explanation":"In Nevada, you must use your headlights from 30 minutes after nightfall to 30 minutes before dawn. At 1,000 feet, you must also use your headlights anytime you cannot see cars or pedestrians clearly. You may temporarily flash your headlights as a \"visual horn\" in Nevada (but not in some other states) to notify another vehicle that you plan to pass. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Night Driving, Nevada Driver's Handbook, Chapter 4: Special Driving Conditions]","imageName":"202003161149128995.jpg","correctAnswer":34780,"isBookmarked":0,"aid":"30756#34780#35039#35040","text":"You must use your headlights whenever visibility is difficult at 1,000 feet#In the daytime, you can drive with just your parking lights on to make your car easier to see#You must use your headlights from a half hour after sunset to a half hour before sunrise#In the daytime, you can flash your headlights to warn another driver that you intend to pass"},
{"testId":654,"qid":28934,"description":"You come to a stop at a traffic light with a flashing yellow arrow. What does it mean?","explanation":"A flashing yellow arrow indicates a left turn. However, you do not have the right-of-way since this is not a \"protected\" turn. Because oncoming traffic has a green light, you must yield before turning. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003070851013728.jpg","correctAnswer":34199,"isBookmarked":0,"aid":"34198#34199#34200#34782","text":"You can turn left here; this is a \"protected\" turn#You can turn left here, but you must first yield to oncoming traffic#You cannot turn left here; find another route#The signal will soon turn red; turn left only if you cannot stop safely"},
{"testId":654,"qid":28935,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking on a Hill, Parking, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":654,"qid":28936,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. Your vehicle, on the other hand, has blind zones on both sides that are big enough to conceal another vehicle. Looking over your shoulder is the only method to check for blind spots. [Lane Changes; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":654,"qid":28937,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Nevada Driver's Handbook, Commercial Vehicles, Chapter 5: Sharing the Road]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":654,"qid":28938,"description":"Which vehicle is not properly using the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":654,"qid":28939,"description":"When do your high-beam headlights need to be dimmed?","explanation":"When you are near 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following in Nevada, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. Bright lights may bounce off the mirrors of other vehicles, blinding the driver. [Night Driving, Nevada Driver's Handbook, Chapter 4: Special Driving Conditions]","imageName":"202002211503506612.jpg","correctAnswer":35041,"isBookmarked":0,"aid":"35041#35042#35043#35044","text":"Whenever you are within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following#Whenever you are within 300 feet of an oncoming vehicle or within 500 feet of a vehicle you are following#Whenever you are within 500 feet of another vehicle#Whenever you are within 1,000 feet of another vehicle"},
{"testId":654,"qid":28940,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Nevada?","explanation":"According to the Nevada Driver's Handbook, you should maintain a following distance of at least two seconds behind the car in front of you. (Some authors suggest a three- to four-second following distance.) You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [Nevada Driver's Handbook, Chapter 3: Driving Safely, Defensive Driving Tips]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#16519#19915#34787","text":"At least two seconds#At least one second#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":654,"qid":28941,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There is one exemption to this regulation in Nevada: you do not need to stop if you are on the opposite side of a split highway. [School Buses, Nevada Driver's Handbook, Chapter 5: Sharing the Road]","imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#35045","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the flashing red lights have been turned off"},
{"testId":654,"qid":28942,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and take your time. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#34177#35009#35027","text":"Slow down and proceed with caution#Yield as if it were a yield sign#Go straight when it is safe to do so; you cannot turn here#Stop and yield as if it were a stop sign"},
{"testId":654,"qid":28943,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"To cross railroad tracks, it is usually safer to ride straight inside your lane. Turning to take tracks head-on (at a 90-degree angle) is riskier since your course may lead you into another lane of traffic. Move far enough away from tracks that run parallel to your path to cross them at an angle of at least 45 degrees. At a lower angle, your tires may grab on the tracks.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":654,"qid":28944,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist is indicating a need to slow down or halt. [Signaling; Signaling, Turning, Lane Changes, and Passing; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":654,"qid":28945,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":"To pass a stopped emergency vehicle in Nevada, you must slow down to a reasonable and appropriate pace that is less than the stated speed limit. You must also move into a non-adjacent lane if feasible, leaving at least one lane between you and the emergency vehicle. This is true for all emergency vehicles, including tow trucks. (Note: All US states now have their own Move Over statutes, however some demand various actions from drivers.) Always verify the local traffic rules while going out of state.) [Approaching a Stopped Emergency Vehicle, Nevada Driver's Handbook, Chapter 4: Special Driving Conditions]","imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"33565#34515#35046#35047","text":"Proceed as usual#Move into a non-adjacent lane if possible; otherwise, slow down#Pull over#Slow down; also move into a non-adjacent lane if possible"},
{"testId":655,"qid":28946,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Stop Signs, Signs, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":655,"qid":28947,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction is shown by this sign. You are not permitted to exceed 50 mph in this area. It is important to note that this does not imply that driving at 50 mph is safe in this area; it is dependent on the present driving circumstances. Slow down below the maximum speed limit under dangerous driving circumstances. [State of New Hampshire Driver's Manual, Part Nine - Speed]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":655,"qid":28948,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You must signal for at least 500 feet on the highway and at least 100 feet anywhere else in New Hampshire. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [State of New Hampshire Driver's Manual, Turning, Part Six - Turning and Signaling]","imageName":"202001291433312120.jpg","correctAnswer":35465,"isBookmarked":0,"aid":"29975#35463#35464#35465","text":"No, you are only required to signal when other vehicles are present#No, turn signals are optional in New Hampshire#Yes, you must signal for at least 100 feet on the highway and at least 500 feet everywhere else#Yes, you must signal for at least 500 feet on the highway and at least 100 feet everywhere else"},
{"testId":655,"qid":28949,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":655,"qid":28950,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [Yield Signs, Signs, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":655,"qid":28951,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with bright lights or a siren, you must pull over, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":655,"qid":28952,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003151700371291.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31416#35015#35256#35466","text":"The right-of-way is to be given, not taken#The right-of-way is a legal right#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever possible"},
{"testId":655,"qid":28953,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":655,"qid":28954,"description":"Which of the following should be adhered to above all others?","explanation":"You must obey a traffic officer's instructions, even if it means violating other traffic devices or laws. For example, if a traffic official waves you through a red light or a stop sign, you should proceed. [State of New Hampshire Driver's Manual, Part Five - Rules of the Road]","imageName":"202002211500121915.jpg","correctAnswer":14886,"isBookmarked":0,"aid":"2316#2529#14886#16371","text":"A stop sign#A traffic signal#A traffic officer#A regulatory sign"},
{"testId":655,"qid":28955,"description":"After stopping at a red light and yielding to pedestrians and other cars, you may turn right, but only if:","explanation":"Unlike many other states in the United States, New Hampshire allows you to make a right turn at a red arrow pointing right if (a) there is no sign prohibiting it; (b) pedestrians have a steady DON'T WALK signal if the intersection has one; and (c) you first come to a complete stop and yield to pedestrians and other vehicles. However, like in other US states, you may not turn left at a red arrow pointing left while the red arrow signal is on. [Red, Traffic Lights, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"1371994483_t1q10.png","correctAnswer":37211,"isBookmarked":0,"aid":"37211#37212#37213#37214","text":"(a) a steady DON'T WALK signal is displayed#(b) it's a solid red signal#(c) it's a red arrow signal#(a) and (b) are both true"},
{"testId":655,"qid":28956,"description":"Which car occupants in New Hampshire are obliged to wear seat belts or appropriate kid restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":35473,"isBookmarked":0,"aid":"35471#35472#35473#35474","text":"None (seat belts are optional)#All front-seat occupants#All occupants under 18 years of age#All occupants"},
{"testId":655,"qid":28957,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":655,"qid":28958,"description":"Which of the following statements about the speed restrictions in New Hampshire is FALSE?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#35476#35477#35478","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#The minimum speed on an interstate highway is legally enforceable#You should drive at the posted speed limit only under ideal conditions#The maximum speed limit in a business district is 30 mph"},
{"testId":655,"qid":28959,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [State of New Hampshire Driver's Manual, Turning, Part Six - Turning and Signaling]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":655,"qid":28960,"description":"When must you drive 10 mph slower than the official speed limit in a school zone?","explanation":"In a school zone, you must travel 10 mph slower than the official speed limit for the 45 minutes before school and 45 minutes after school. [State of New Hampshire Driver's Manual, Part Nine - Speed Limits]","imageName":"202003151740461521.jpg","correctAnswer":35479,"isBookmarked":0,"aid":"16539#35479#35480#35481","text":"During daylight hours#During the 45 minutes before the school opening and the 45 minutes after the school closing#From the school opening to the school closing#Within 30 minutes of the school opening and closing"},
{"testId":655,"qid":28961,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"When approaching a crossroads, a motorist must give the right-of-way to cross traffic before advancing. Cars A and C have both passed yield signs, hence Car B (cross traffic) has the right of way. [Yield Signs, Signs, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":655,"qid":28962,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Yellow, Traffic Lights, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003051752141237.jpg","correctAnswer":35025,"isBookmarked":0,"aid":"25300#28857#28858#35025","text":"Treat the intersection like an all-way stop#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop before the intersection if it is safe to do so"},
{"testId":655,"qid":28963,"description":"Which of the following places may you park legally?","explanation":"You may not park within 15 feet of a fire hydrant or more than 12 inches from the curb in New Hampshire. You are not permitted to park on a bridge or on the sidewalk. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) The automobile in response D) has parked lawfully 10 inches from the curb. [Illegal Parking, State of New Hampshire Driver's Manual, Part Ten - Parking and Backing], [Move as Far Away from Traffic as Possible, ibid.]","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":655,"qid":28964,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":655,"qid":28965,"description":"You notice a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. [Standard Shapes and Signs, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":655,"qid":28966,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34492#35260","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn"},
{"testId":655,"qid":28967,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Right-of-Way, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":655,"qid":28968,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Flashing Red, Traffic Lights, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#35028#35029","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#This signal is out of order; proceed with caution#Yield to all vehicles unless you are on the busier road"},
{"testId":655,"qid":28969,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Keep to the right of the median. [General Notice, Appendix, New Hampshire Driver's Manual]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"22419#22656#35482#35483","text":"A divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":655,"qid":28970,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Standard Shapes and Signs, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15550#27259#35030","text":"There is a crossroad ahead#There is a railroad crossing ahead#You are going the wrong way; go back#There is a four-way stop ahead"},
{"testId":655,"qid":28972,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":"When you come to a complete stop at a railroad crossing, you must do so at the stop line. If there is no stop line, you must come to a halt within 15 to 50 feet of the closest rail. Trains are at least six feet wider than the tracks they operate on, so stay away from them. [State of New Hampshire Driver's Manual, Railroad Crossings, Part Five - Rules of the Road]","imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#34773","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#30 to 60 feet from the tracks"},
{"testId":655,"qid":28973,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [State of New Hampshire Driver's Manual, Hills, Part Eleven - Hazardous Driving Conditions], [General Warning, Appendix, ibid.]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":655,"qid":28974,"description":"When is it legal to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":35390,"isBookmarked":0,"aid":"16495#16560#29959#35390","text":"When you are turning onto a one-way street#When you are turning from a one-way street#When you are turning from a one-way street onto another one-way street#Only if a sign permits it"},
{"testId":655,"qid":28975,"description":"What's the interstate minimum speed limit?","explanation":"Under ideal circumstances, the minimum speed for an interstate highway is 45 mph. This minimum speed is legally binding. However, if the driving circumstances are especially bad or dangerous, you may travel slower than this minimal speed for your own protection. [State of New Hampshire Driver's Manual, The Slow Driver, Part Nine - Speed]","imageName":"202003301803164994.jpg","correctAnswer":1677,"isBookmarked":0,"aid":"897#1676#1677#1745","text":"None of the above#35 mph#45 mph#40 mph"},
{"testId":655,"qid":28976,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"At no point is it permitted to drive on the shoulder to pass another car. [State of New Hampshire Driver's Manual] [Passing on the Right; Passing; Part Seven - Following, Passing, and Lane Usage]","imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#9435#34188#35035","text":"Never#At any time#If the vehicle is turning left#If the posted speed limit is 25 mph or less"},
{"testId":655,"qid":28977,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This motorist is indicating a right turn. [Signaling, State of New Hampshire Driver's Manual, Part Six - Turning and Signaling]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":655,"qid":28979,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"Vehicles moving in either direction must stop at least 25 feet from a school bus that has stopped with its red lights flashing in New Hampshire. They must stay halted until the flashing red lights are turned off or the bus resumes its journey. [School Bus, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34196#34928","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":655,"qid":28980,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must give way to cars that arrived at the junction ahead of you during an all-way stop. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. [State of New Hampshire Driver's Manual, Right of Way, Part Five - Rules of the Road]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":655,"qid":28981,"description":"Unless otherwise specified, the maximum speed limit in New Hampshire's residential districts is :","explanation":"Except as otherwise marked, the maximum speed restriction in New Hampshire's urban residential districts is 30 mph. Except as otherwise marked, the maximum speed restriction in New Hampshire's rural residential districts is 35 mph. [State of New Hampshire Driver's Manual, Part Nine - Speed Limits]","imageName":"202003151746465721.jpg","correctAnswer":16566,"isBookmarked":0,"aid":"521#1676#16566#16567","text":"30 mph#35 mph#35 mph in rural areas and 30 mph in urban areas#30 mph in rural areas and 35 mph in urban areas"},
{"testId":655,"qid":28982,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. In addition, in New Hampshire, you cannot turn right at a pedestrian signal junction unless a steady DON'T WALK signal is shown. [Red, Traffic Lights, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":655,"qid":28983,"description":"When should you switch on your headlights?","explanation":"From half an hour after dusk to half an hour before daybreak, you must use your headlights. When vision is poor at 1,000 feet, you must additionally use your headlights. Use your headlights to increase your visibility to other drivers, particularly in bad weather. [Night Vision, Night Driving, State of New Hampshire Driver's Manual, Part Eleven - Hazardous Driving Conditions]","imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"22962#35275#35487#35488","text":"In all of the above situations#Whenever visibility is difficult at 1,000 feet#From one-half hour after sunset to one-half hour before sunrise#Whenever the weather is poor"},
{"testId":655,"qid":28984,"description":"What is the meaning of this sign?","explanation":"A slow-moving vehicle is identified by a triangular orange insignia on the back. Typically, this refers to a vehicle that cannot move faster than 25 mph. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. Don't get frustrated if you find yourself stuck behind one of these automobiles. The motorist may slow down to let you to pass. [Sharing the Road with Slow Moving Vehicles, State of New Hampshire Driver's Manual, Part Eighteen - Sharing the Road], [Slow Moving Vehicles, Appendix, ibid.]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#1075#35258","text":"Stop#Slow-moving vehicle#No passing#Work zone"},
{"testId":655,"qid":28985,"description":"You parked next to a curb and are now facing uphill. Which way should the front wheels face?","explanation":"When parallel parking uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [State of New Hampshire Driver's Manual, Parking on a Hill, Part Ten - Parking and Backing]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":655,"qid":28986,"description":"Which of the following must you complete before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. Your vehicle, on the other hand, has blind zones on both sides that are big enough to conceal another vehicle. Looking over your shoulder is the only method to check for blind spots. [Changing Lanes; Lane Position; Part Seven - Following, Passing, and Lane Usage; Driver's Manual for the State of New Hampshire]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":655,"qid":28987,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Sharing the Road with Trucks, State of New Hampshire Driver's Manual, Part Eighteen - Sharing the Road]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":655,"qid":28988,"description":"Which of the following statements about work zone speed limitations is correct?","explanation":null,"imageName":"202002101222518967.jpg","correctAnswer":35490,"isBookmarked":0,"aid":"31038#35489#35490#35491","text":"Work zone speed limits are not enforced#The maximum fine for speeding in a work zone is $250#The minimum fine for speeding in a work zone is $250#You may receive a $250 fine for speeding in a work zone whether or not workers are present"},
{"testId":655,"qid":28989,"description":"When are your high-beam headlights have to be dimmed under New Hampshire law?","explanation":"When you are within 150 feet of an incoming vehicle or a vehicle you are pursuing, New Hampshire law requires you to lower your headlights. High-beam headlights often have a range of more than 150 feet. As a result, anytime your high lights shine on another occupied car, you should decrease them. [Night Driving Tips, Night Driving, Part Eleven - Hazardous Driving Conditions, Driver's Manual of the State of New Hampshire]","imageName":"202002211503506612.jpg","correctAnswer":35492,"isBookmarked":0,"aid":"35492#35493#35494#35495","text":"Whenever you are within 150 feet of an oncoming vehicle or within 150 feet of a vehicle you are following#Whenever you are within 300 feet of an oncoming vehicle or within 300 feet of a vehicle you are following#Whenever you are within 300 feet of an oncoming vehicle or within 150 feet of a vehicle you are following#Whenever you are within 150 feet of an oncoming vehicle or within 300 feet of a vehicle you are following"},
{"testId":655,"qid":28990,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in New Hampshire?","explanation":"The State of New Hampshire Driver's Manual suggests keeping a four-second following distance from other vehicles. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [Following Distance; State of New Hampshire Driver's Manual; Part Seven - Following, Passing, and Lane Usage]","imageName":"202001302249003541.jpg","correctAnswer":15927,"isBookmarked":0,"aid":"15926#15927#19915#34787","text":"At least two seconds#At least four seconds#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":655,"qid":28991,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#35496","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the flashing red lights have been turned off or the bus has resumed its motion"},
{"testId":655,"qid":28992,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and take your time. [Flashing Yellow, Traffic Lights, Part Five - Road Rules, New Hampshire Driver's Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#34177#35009#35027","text":"Slow down and proceed with caution#Yield as if it were a yield sign#Go straight when it is safe to do so; you cannot turn here#Stop and yield as if it were a stop sign"},
{"testId":655,"qid":28993,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"To cross railroad tracks, it is usually safer to ride straight inside your lane. Turning to take tracks head-on (at a 90-degree angle) is riskier since your course may lead you into another lane of traffic. Move far enough away from railroad lines that run parallel to your path to cross them at an angle of at least 45 degrees. At a lower angle, your tires may grab on the tracks.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#35497","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#One driver must let the other driver through"},
{"testId":655,"qid":28994,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist is indicating a need to slow down or halt. [Signaling, State of New Hampshire Driver's Manual, Part Six - Turning and Signaling]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":655,"qid":28995,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"33565#34515#35047#35498","text":"Proceed as usual#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Speed up and pass the vehicle as quickly as possible"},
{"testId":657,"qid":28996,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":657,"qid":28997,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction is shown by this sign. You are not permitted to exceed 50 mph in this area. It is important to note that this does not imply that driving at 50 mph is safe in this area; it is dependent on the present driving circumstances. Slow down below the maximum speed limit under dangerous driving circumstances. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":657,"qid":28998,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Before turning in New Mexico, you must signal for at least 100 feet. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [Informing Others of Your Activities, Communicating, New Mexico Driver Manual]","imageName":"202001291433312120.jpg","correctAnswer":28516,"isBookmarked":0,"aid":"28515#28516#29975#37510","text":"Yes, you must signal for at least 50 feet#Yes, you must signal for at least 100 feet#No, you are only required to signal when other vehicles are present#No, turn signals are optional in New Mexico"},
{"testId":657,"qid":28999,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":657,"qid":29000,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":657,"qid":29001,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must move over to the right side of the road, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":657,"qid":29002,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202003151700371291.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31416#35015#35256#35466","text":"The right-of-way is to be given, not taken#The right-of-way is a legal right#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever possible"},
{"testId":657,"qid":29003,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and be prepared to yield to pedestrians. Follow the instructions of the school crossing guards. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":657,"qid":29004,"description":"What must you do before entering a public road from an alley or driveway?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":34774,"isBookmarked":0,"aid":"34774#34775#34776#34777","text":"Stop and yield before crossing the sidewalk#Stop and yield after crossing the sidewalk#Yield before crossing the sidewalk#Yield after crossing the sidewalk"},
{"testId":657,"qid":29005,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in New Mexico, unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [Traffic Lights, Traffic Control Devices, Road Rules, New Mexico Driver Manual]","imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#25289#35017#37511","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after yielding to traffic and pedestrians#No, not in New Mexico"},
{"testId":657,"qid":29006,"description":"Which vehicle occupants in New Mexico are required to wear safety belts or appropriate kid restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16365#35237#37512#37513","text":"The driver and all passengers#The driver, front-seat passengers, and passengers under 18#None (seat belts are optional in New Mexico)#The driver and passengers under 18"},
{"testId":657,"qid":29007,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two cars approach an all-way stop at about the same moment, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202002181211246194.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":657,"qid":29008,"description":"Which of the following statements concerning the speed restrictions in New Mexico is FALSE?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. Both restrictions are legally binding. Speed restrictions, on the other hand, are established for optimum driving circumstances. When driving circumstances are bad or dangerous, reduce your speed to less than the speed limit. Under certain situations, a few US states allow you to exceed the speed limit in order to pass another vehicle, however New Mexico does not. [New Mexico Driver Manual] [Speed Limits, Rules of the Road], [Keeping Pace with Traffic, Adjusting to Traffic, Communicating]","imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#35476#37514#37515","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#The minimum speed on an interstate highway is legally enforceable#You should drive at the posted speed limit only under good driving conditions#The maximum speed limit in a business district is 30 mph unless posted otherwise"},
{"testId":657,"qid":29009,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [Turning, General Rules, Road Rules, New Mexico Driver Manual]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":657,"qid":29010,"description":"When there are children present, what is the speed limit in a school zone?","explanation":null,"imageName":"202004071515004176.jpg","correctAnswer":520,"isBookmarked":0,"aid":"520#522#523#2429","text":"15 mph#25 mph#20 mph#10 mph"},
{"testId":657,"qid":29011,"description":"As seen, three cars arrive at an intersection with YIELD signs. Who has the right-of-way in this situation?","explanation":"At a junction, STOP and YIELD signs may offer particular highways priority right-of-way. Cars A and C are both facing YIELD signs in this situation, hence Car B has the right-of-way. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":657,"qid":29012,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Traffic Lights, Traffic Control Devices, Road Rules, New Mexico Driver Manual]","imageName":"202003051752141237.jpg","correctAnswer":37516,"isBookmarked":0,"aid":"25300#28857#28858#37516","text":"Treat the intersection like an all-way stop#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop before entering the intersection if it is safe to do so"},
{"testId":657,"qid":29013,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":657,"qid":29014,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":657,"qid":29015,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":657,"qid":29016,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"33150#34174#34175#34490","text":"No, you must wait for the red signal to change#Yes, but you must yield to oncoming vehicles before turning#No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped"},
{"testId":657,"qid":29018,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Lights, Traffic Control Devices, Road Rules, New Mexico Driver Manual]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#35029#37517","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier road#Proceed with caution; this signal is out of order"},
{"testId":657,"qid":29019,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Continue straight. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"22419#22656#35482#35483","text":"A divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":657,"qid":29020,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35030#37518#37519","text":"There is a railroad crossing ahead#There is a four-way stop ahead#There is an uncontrolled intersection ahead#You are going the wrong way; turn around and go back"},
{"testId":657,"qid":29021,"description":"You see a lane with a double broken yellow line on both sides. What kind of a lane is this?","explanation":"This is a lane that can be used both ways. A reversible lane transports traffic in one direction at times and the other way at others. Typically, reversible lanes are utilized to improve capacity during morning and evening peak hours. Keep an eye out for signs or signals indicating if the reversible lane is available in your direction. [Reversible Lanes, Additional Lane Controls, Road Rules, New Mexico Driver Manual]","imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16322#16786#28541","text":"A reversible lane#A central parallel parking lane#A shared center lane#A high-occupancy vehicle (HOV) lane"},
{"testId":657,"qid":29022,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#34773","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#30 to 60 feet from the tracks"},
{"testId":657,"qid":29023,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":657,"qid":29025,"description":"You must yield to _________ before turning left into a driveway.","explanation":"Because you're turning left, you must yield to incoming traffic. Because you are crossing the sidewalk, you must yield to pedestrians. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202001241717155576.jpg","correctAnswer":33884,"isBookmarked":0,"aid":"14207#33884#37520#37521","text":"pedestrians only#pedestrians and oncoming vehicles#no one (i.e., you have the right-of-way)#oncoming vehicles only"},
{"testId":657,"qid":29026,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"Driving on the shoulder to pass another vehicle or for any other reason is not permitted. [General Driving, General Rules, Road Rules, New Mexico Driver Handbook]","imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#9435#34188#35035","text":"Never#At any time#If the vehicle is turning left#If the posted speed limit is 25 mph or less"},
{"testId":657,"qid":29029,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":37524,"isBookmarked":0,"aid":"28148#32321#37523#37524","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop at least 10 feet away if you are traveling in the same direction as the bus#Stop at least 10 feet away, no matter which direction you are traveling in"},
{"testId":657,"qid":29030,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. Right-of-Way, Traffic Regulations, and New Mexico Driver's Manual","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":657,"qid":29031,"description":"Unless otherwise specified, the maximum speed limit in New Mexico's residential districts is :","explanation":"The maximum speed restriction in New Mexico's commercial and residential zones is 30 mph unless when specifically indicated. [Maximum, Speed Limits, Road Rules, New Mexico Driver Manual]","imageName":"202003151746465721.jpg","correctAnswer":521,"isBookmarked":0,"aid":"521#522#1676#1745","text":"30 mph#25 mph#35 mph#40 mph"},
{"testId":657,"qid":29032,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. At addition, whether marked or unmarked, you must yield to pedestrians in a crossing. [Traffic Lights, Traffic Control Devices, Road Rules, New Mexico Driver Manual]","imageName":"202002181109457082.jpg","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":657,"qid":29033,"description":"Which of the following statement is NOT TRUE?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":37525,"isBookmarked":0,"aid":"15516#31432#37525#37526","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You must use your headlights whenever visibility is difficult at 500 feet#You may drive with your parking lights on when visibility is poor during the day#Don't use high beams in fog, rain, or snow"},
{"testId":657,"qid":29034,"description":"What is the meaning of this sign?","explanation":"A triangular orange insignia on the back of a car identifies it as a slow-moving vehicle, which New Mexico defines as one that drives at less than 25 mph on a regular basis. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. [Traffic Signs, Traffic Control Devices, Road Rules, New Mexico Driver Handbook]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"627#629#1075#35258","text":"Stop#Slow-moving vehicle#No passing#Work zone"},
{"testId":657,"qid":29036,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for blind spots. [Scanning, Basic Driving, Driving Safety Tips, New Mexico Driver Manual]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":657,"qid":29037,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is unlikely to be seen to the truck driver. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Blind Spots in Trucks, Large Trucks and RVs, Sharing the Road, New Mexico Driver Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":657,"qid":29038,"description":"A white cane-wielding pedestrian enters the road ahead. What are your options?","explanation":"A person holding a white cane or accompanied by a guiding dog is almost certainly blind or visually challenged. Always give way to blind pedestrians. If you blow your horn or rev your engine near a blind pedestrian, you may shock the walker or obscure crucial audio clues that the blind pedestrian depends on. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202002061105552801.jpg","correctAnswer":37528,"isBookmarked":0,"aid":"30742#33080#37527#37528","text":"Drive around the pedestrian#Sound your horn#Stop and sound your horn#Yield to the pedestrian"},
{"testId":657,"qid":29039,"description":"When do high-beam headlights need to be dimmed under New Mexico law?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":37529,"isBookmarked":0,"aid":"6334#28888#37529#37530","text":"Never#When you are within 200 feet of any vehicle#When you are within 500 feet of an oncoming vehicle or following a vehicle within 200 feet#When you are within 200 feet of an oncoming vehicle or following a vehicle within 500 feet"},
{"testId":657,"qid":29040,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in New Mexico?","explanation":"According to the New Mexico Driver Manual, you should maintain a following distance of at least three seconds behind the vehicle in front of you. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [New Mexico Driver Manual, Sharing Space, Communicating]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#34787","text":"At least two seconds#At least three seconds#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":657,"qid":29041,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":34204,"isBookmarked":0,"aid":"32321#34204#34205#34512","text":"Slow down to 15 mph and proceed with caution#Proceed; you don't have to stop#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the red lights have been turned off"},
{"testId":657,"qid":29042,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you encounter a flashing yellow light at a junction. [Traffic Lights, Traffic Control Devices, Road Rules, New Mexico Driver Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":657,"qid":29043,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Cars already at a junction have the right of way over incoming vehicles. A traffic circle, often known as a roundabout, is a circular junction. Cars entering a roundabout must give way to vehicles already in the roundabout.","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":657,"qid":29044,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":657,"qid":29045,"description":"An emergency vehicle with bright lights has halted ahead. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"33565#34515#35047#35498","text":"Proceed as usual#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Speed up and pass the vehicle as quickly as possible"},
{"testId":658,"qid":29046,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":null,"imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":658,"qid":29047,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":658,"qid":29048,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":38646,"isBookmarked":0,"aid":"29975#38644#38645#38646","text":"No, you are only required to signal when other vehicles are present#No, turn signals are optional in North Carolina#Yes, you must signal for at least 50 feet in urban areas and at least 100 feet in rural areas#Yes, you must signal for at least 100 feet if the speed limit is under 45 mph and at least 200 feet otherwise"},
{"testId":658,"qid":29049,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":658,"qid":29050,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":658,"qid":29051,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":658,"qid":29052,"description":"Which of the following statements is true about the right-of-way?","explanation":null,"imageName":"202003151700371291.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31416#35015#35256#35466","text":"The right-of-way is to be given, not taken#The right-of-way is a legal right#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever possible"},
{"testId":658,"qid":29053,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#15825#34168","text":"A school zone sign#A work zone sign#A warning sign#A No Passing sign"},
{"testId":658,"qid":29054,"description":"What should you do before entering a road from a private driveway?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":34774,"isBookmarked":0,"aid":"34774#34775#34776#34777","text":"Stop and yield before crossing the sidewalk#Stop and yield after crossing the sidewalk#Yield before crossing the sidewalk#Yield after crossing the sidewalk"},
{"testId":658,"qid":29055,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":15508,"isBookmarked":0,"aid":"15508#25289#35017#38648","text":"Yes, but only after coming to a stop and yielding to traffic and pedestrians#Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after yielding to traffic and pedestrians#No, not in North Carolina"},
{"testId":658,"qid":29056,"description":"Which car occupants in North Carolina are obliged to wear seat belts or appropriate kid restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16365#38528#38649#38650","text":"The driver and all passengers#The driver and passengers under 16#None (seat belts are optional in North Carolina)#The driver, front-seat passengers, and passengers under 16"},
{"testId":658,"qid":29057,"description":"Which of the following statements concerning rainy driving is FALSE?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":38653,"isBookmarked":0,"aid":"38651#38652#38653#38654","text":"Switch on your headlights and windshield wipers#Slow down below the maximum speed limit#You can use your cruise control#Be especially cautious during the first 15 minutes of rain"},
{"testId":658,"qid":29058,"description":"Which of the following statements concerning the speed restrictions in North Carolina is FALSE?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#38655#38656#38657","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#Driving too slowly can be dangerous#Sometimes you should drive below the speed limit#Slower vehicles should drive in the right lane and let other vehicles pass wherever possible"},
{"testId":658,"qid":29059,"description":"Which vehicles are turning properly here?","explanation":null,"imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":658,"qid":29060,"description":"Which of the following statements are true about passing?","explanation":null,"imageName":"202002181239175594.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#38658#38659#38660","text":"All of the above are true#When you're being passed, help the driver of the passing vehicle pass you safely#When you're passing another vehicle, sound your horn to warn the driver#Check your mirrors and blind spots before passing"},
{"testId":658,"qid":29061,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":658,"qid":29062,"description":"What should you do when you see a solid yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":37516,"isBookmarked":0,"aid":"25300#28857#28858#37516","text":"Treat the intersection like an all-way stop#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop before entering the intersection if it is safe to do so"},
{"testId":658,"qid":29063,"description":"Is it permissible to turn left at a green signal?","explanation":null,"imageName":"left-turn-signal-head-green.png","correctAnswer":34490,"isBookmarked":0,"aid":"18118#34490#38661#38662","text":"No#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming vehicles before you turn#No, not unless an oncoming driver lets you through"},
{"testId":658,"qid":29064,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":658,"qid":29065,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":null,"imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":658,"qid":29066,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34492#38661","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#No, you must wait for the red light to change#Yes, but you must yield to oncoming vehicles before you turn"},
{"testId":658,"qid":29067,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":null,"imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":658,"qid":29068,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"28148#34177#35027#38663","text":"Proceed with caution#Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier roadway"},
{"testId":658,"qid":29069,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#35483","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":658,"qid":29070,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35030#35262#37519","text":"There is a railroad crossing ahead#There is a four-way stop ahead#There is a train station ahead#You are going the wrong way; turn around and go back"},
{"testId":658,"qid":29072,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#34773","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#30 to 60 feet from the tracks"},
{"testId":658,"qid":29073,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":658,"qid":29074,"description":"Is it legal in North Carolina to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38648,"isBookmarked":0,"aid":"35269#38648#38668#38669","text":"Yes, unless it's prohibited by a sign#No, not in North Carolina#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":658,"qid":29075,"description":"How near can this car legally park to a fire hydrant?","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":658,"qid":29076,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":658,"qid":29077,"description":"What should you do if the traffic lights at an intersection are broken?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#38670#38671#38672","text":"Treat the intersection like an all-way stop#Treat the intersection like an all-way yield#Treat the intersection as if there were no signs or signals#Assume that vehicles on the busier roadway have the right-of-way"},
{"testId":658,"qid":29078,"description":"What actions are advised if you hydroplane?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":38675,"isBookmarked":0,"aid":"38673#38674#38675#38676","text":"Steer straight and brake gently#Accelerate to drive out of the skid#Take your foot off the gas pedal and steer straight#Wait for your vehicle to stop hydroplaning on its own"},
{"testId":658,"qid":29079,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003070809524366.jpg","correctAnswer":38677,"isBookmarked":0,"aid":"28148#32321#34195#38677","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop no matter which direction you are traveling in"},
{"testId":658,"qid":29080,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":658,"qid":29081,"description":"Unless otherwise specified, the maximum speed limit in New Carolina's residential districts is :","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":1676,"isBookmarked":0,"aid":"521#522#1676#1745","text":"30 mph#25 mph#35 mph#40 mph"},
{"testId":658,"qid":29082,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":658,"qid":29083,"description":"When are headlights required in North Carolina?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"16068#22962#38678#38679","text":"From sunset to sunrise#In all of the above situations#Whenever visibility is difficult at 400 feet#Whenever you use your windshield wipers continuously in poor weather"},
{"testId":658,"qid":29084,"description":"Which of the following statement concerning signalling is correct?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":38680,"isBookmarked":0,"aid":"38680#38681#38682#38683","text":"Hand signals are more effective than turn signals when the sun is low in the sky#Hand signals are more effective than turn signals at night#The driver of any vehicle may use hand signals#Your passengers can make hand signals for you"},
{"testId":658,"qid":29085,"description":"Is it permissible to turn left at a flashing yellow arrow signal?","explanation":null,"imageName":"202003241444031322.jpg","correctAnswer":38661,"isBookmarked":0,"aid":"18118#34490#38661#38684","text":"No#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming vehicles before you turn#No, unless it would unsafe to stop here"},
{"testId":658,"qid":29086,"description":"Which of the following steps is not required when changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":658,"qid":29087,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":null,"imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":658,"qid":29088,"description":"When should your high beams be dimmed?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":38667,"isBookmarked":0,"aid":"38667#38685#38686#38687","text":"(a) or (b)#(a) Whenever they shine on another occupied vehicle#(b) When you're driving in urban areas#(c) When you're driving on rural interstate highways"},
{"testId":658,"qid":29089,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":null,"imageName":"202003161214084893.jpg","correctAnswer":38691,"isBookmarked":0,"aid":"38688#38689#38690#38691","text":"(a) Flash your high beams once#(b) Switch on your high beams#(c) Slow down and look at the right edge of the road#(a) and (c)"},
{"testId":658,"qid":29090,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in North Carolina?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#16519#19915#34787","text":"At least two seconds#At least one second#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":658,"qid":29091,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#32321#34205#38692","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop, yield to pedestrians, and then proceed with caution#Stop and remain stopped until the red lights have been turned off and the bus resumes its motion"},
{"testId":658,"qid":29092,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":658,"qid":29093,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#25311#35497","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right-of-way#One driver must let the other driver through"},
{"testId":658,"qid":29094,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":658,"qid":29095,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34515,"isBookmarked":0,"aid":"15493#34515#35047#37416","text":"Slow down#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Proceed at your usual speed"},
{"testId":659,"qid":29096,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is, of course, a stop sign, the only octagonal sort of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [North Dakota Noncommercial Drivers License Manual; Regulatory Signs; Signs, Signals, and Road Markings]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":659,"qid":29097,"description":"What is the meaning of this sign?","explanation":"The maximum speed limit under normal driving conditions is indicated by this regulatory sign. You are not permitted to exceed 50 mph in this area. It is important to note that this does not imply that driving at 50 mph is safe in this area; it is dependent on the present driving circumstances. Slow down below the maximum speed limit under dangerous driving circumstances. [North Dakota Noncommercial Drivers License Manual; Regulatory Signs; Signs, Signals, and Road Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":659,"qid":29098,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#38846","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in North Dakota"},
{"testId":659,"qid":29099,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#29326#38847","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right of way#One driver should proceed first and take the right of way"},
{"testId":659,"qid":29100,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [North Dakota Noncommercial Drivers License Manual; Regulatory Signs; Signs, Signals, and Road Markings]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":659,"qid":29101,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must move over to the right side of the road, regardless of which way the emergency vehicle is heading. If you are in the midst of an intersection when the emergency vehicle arrives, go through the intersection and immediately pull over. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":659,"qid":29102,"description":"Which of the following statement about right of way is true?","explanation":"The law specifies who must cede the right of way; it does not provide the right of way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, it is preferable to relinquish the right of way rather than risk a collision. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202003151700371291.jpg","correctAnswer":38850,"isBookmarked":0,"aid":"38848#38849#38850#38851","text":"Once you have the right of way, you may proceed immediately#You should claim the right of way whenever possible#The right of way is to be given, not taken#The right of way is a legal right"},
{"testId":659,"qid":29103,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and be prepared to yield to pedestrians. Follow the instructions of the school crossing guards. [North Dakota Noncommercial Drivers License Manual; School Signs; Warning Signs; Signs, Signals, and Road Markings]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#34168#38852","text":"A school zone sign#A work zone sign#A No Passing sign#A guide sign"},
{"testId":659,"qid":29104,"description":"What must you do before entering a road from an alley in a commercial district?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":38855,"isBookmarked":0,"aid":"38853#38854#38855#38856","text":"Follow the usual right-of-way rules for an uncontrolled intersection#Yield to all traffic and pedestrians#Stop and yield to all traffic and pedestrians#Speed up to merge with traffic"},
{"testId":659,"qid":29105,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in North Dakota unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"38857#38858#38859#38860","text":"Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#Yes, this is a \"protected\" turn, so you will have the right of way#No, not in North Dakota"},
{"testId":659,"qid":29106,"description":"Which car occupants in North Dakota are obliged to wear seat belts or appropriate child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16678,"isBookmarked":0,"aid":"4420#16678#35254#39233","text":"Only the driver#The driver, all passengers under 18, and all passengers in the front seat#The driver and all passengers under 18#None (seat belts are optional in North Dakota)"},
{"testId":659,"qid":29107,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202003070857473536.jpg","correctAnswer":38861,"isBookmarked":0,"aid":"18118#34487#38861#38862","text":"No#Yes, right away#Yes, but only after you yield to pedestrians and vehicles already in the intersection#Yes, but only after you yield to cross traffic"},
{"testId":659,"qid":29108,"description":"Which of the following is NOT true about the speed limits in North Dakota?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#38863#38864#38865","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#The posted minimum speed on a highway is legally enforceable#Sometimes you should drive below the posted maximum speed limit#The maximum speed limit on a paved and divided multilane highway is 70 mph except where otherwise posted"},
{"testId":659,"qid":29109,"description":"Which car is correctly turning left here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, turn from the nearest lane into the nearest lane. Car B has appropriately entered the nearest lane. Car A has made an erroneous turn into the furthest lane. [Turning, Rules of the Road, Noncommercial Drivers License Manual of North Dakota]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":659,"qid":29110,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"You must always obey traffic signs and pavement markings while driving through a multilane roundabout or any other sort of crossroads. Turn left (or do a U-turn) from the left lane, right from the right lane, then drive straight through using either lane. Car C seems to have ignored the painted arrows and went left from the right lane. [Roundabouts, Rules of the Road, Noncommercial Drivers License Manual of North Dakota]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":659,"qid":29111,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":659,"qid":29112,"description":"When there are children present, what is the speed limit surrounding a school?","explanation":null,"imageName":"202003151740461521.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":659,"qid":29113,"description":"Is it permissible to turn left at a green signal?","explanation":"The green arrow indicates that you may turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must yield to any pedestrians or vehicles already at the junction. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202003051809194011.jpg","correctAnswer":38866,"isBookmarked":0,"aid":"18118#34487#38866#38867","text":"No#Yes, right away#Yes, but only after you yield to vehicles already in the intersection#Yes, but only after you yield to oncoming vehicles"},
{"testId":659,"qid":29114,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right of way. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202007120513316836.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#28526#29326#38847","text":"Car A must yield because it is turning left#Car B must yield to all vehicles turning left#The car on the busier road has the right of way#One driver should proceed first and take the right of way"},
{"testId":659,"qid":29115,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. [North Dakota Noncommercial Drivers License Manual; Regulatory Signs; Signs, Signals, and Road Markings]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":659,"qid":29116,"description":"Can you turn from left here?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to any pedestrians or vehicles already at the junction. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202003070834498321.jpg","correctAnswer":38868,"isBookmarked":0,"aid":"33150#34175#38868#38869","text":"No, you must wait for the red signal to change#No, these lights are clearly out of order#Yes, but only after you yield to any traffic already in the intersection#Yes, but only after you yield to all oncoming vehicles"},
{"testId":659,"qid":29117,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":659,"qid":29118,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [North Dakota Noncommercial Drivers License Manual; Flashing Indications; Signals; Signs, Signals, and Road Markings]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#35029#37517","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier road#Proceed with caution; this signal is out of order"},
{"testId":659,"qid":29119,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Continue straight. [North Dakota Noncommercial Drivers License Manual; Warning Signs; Signs, Signals, and Road Markings]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#35483","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":659,"qid":29120,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [North Dakota Noncommercial Drivers License Manual; Special Warning Signs; Warning Signs; Signs, Signals, and Road Markings]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35030#35262#37519","text":"There is a railroad crossing ahead#There is a four-way stop ahead#There is a train station ahead#You are going the wrong way; turn around and go back"},
{"testId":659,"qid":29121,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [North Dakota Noncommercial Drivers License Manual, Parking on a Hill, Parking, Rules of the Road]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":659,"qid":29122,"description":"You come to a train crossing that doesn't have a stop line. There's a train coming. Where do you have to stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":30412,"isBookmarked":0,"aid":"16907#30412#33158#38870","text":"Right in front of the tracks#15 feet from the tracks#10 feet from the tracks#25 feet from the tracks"},
{"testId":659,"qid":29123,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":38871,"isBookmarked":0,"aid":"35258#38871#38872#38873","text":"Work zone#Low-speed vehicle#Sharp curve or crest#Slippery or rough section of road"},
{"testId":659,"qid":29125,"description":"You are not permitted to park within ______ of a fire hydrant.","explanation":"You may not park within 10 feet of a fire hydrant in North Dakota. [North Dakota Noncommercial Drivers License Manual, Do Not Park, Parking, Rules of the Road]","imageName":"202001290916213788.jpg","correctAnswer":1028,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":659,"qid":29126,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This motorist wants to make a right turn. [Hand Signals; Signs, Signals, and Road Markings; Noncommercial Drivers License Manual for North Dakota]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":659,"qid":29127,"description":"What should you do if the traffic lights at an intersection are broken?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":38874,"isBookmarked":0,"aid":"38671#38874#38875#38876","text":"Treat the intersection as if there were no signs or signals#Stop and yield as if you were at a stop sign#Yield as if you were at a yield sign#Assume that vehicles on the busier roadway have the right of way"},
{"testId":659,"qid":29128,"description":"Three vehicles arrive at a T-junction. In what order should they go?","explanation":"A vehicle on a T-terminating intersection's road must yield to all vehicles on the through (main) route. Car A must yield to Cars B and C in this situation. In addition, since it is turning left at a junction, Car C must yield to oncoming traffic. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16257#16258#23455#38877","text":"B, C, A#B, A, C#A, C, B#C, A, B"},
{"testId":659,"qid":29129,"description":"You are driving on an undivided road. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"Vehicles going in either direction must stop in North Dakota if a school bus has stopped with its red lights flashing. They must stay stopped until either 1) the bus begins to move; 2) the bus driver indicates for cars to pass; or 3) the red lights cease to blink. [North Dakota Noncommercial Drivers License Manual, Rules of the Road]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34196#38878","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop, but only if you are traveling in the same direction as the bus"},
{"testId":659,"qid":29130,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. Here, Car B may go in front of Car A, Car B, and Car C. (Evidently, each vehicle must halt before moving forward.) [Manual for North Dakota Noncommercial Drivers License: Right of Way, Rules of the Road]","imageName":"202002181231057295.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":659,"qid":29131,"description":"Unless otherwise posted, the speed limit throughout North Dakota's residential and business districts is:","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"521#522#1676#1745","text":"30 mph#25 mph#35 mph#40 mph"},
{"testId":659,"qid":29132,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"1372603791_t1q37.png","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":659,"qid":29133,"description":"When are headlights required in North Dakota?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"16068#22962#38879#38880","text":"From sunset to sunrise#In all of the above situations#Whenever visibility is difficult at 1,000 feet because of smoke or bad weather#Whenever visibility is difficult at 1,000 feet because of insufficient light"},
{"testId":659,"qid":29134,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":null,"imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#34188#35035#38881","text":"Never#If the vehicle is turning left#If the posted speed limit is 25 mph or less#If traffic is at a standstill"},
{"testId":659,"qid":29135,"description":"You may not park on or within ______ of a crosswalk at an intersection in North Dakota.","explanation":"You may not park on or within 10 feet of a crosswalk at a junction in North Dakota. [North Dakota Noncommercial Drivers License Manual, Parking, Rules of the Road]","imageName":"202002181141488961.jpg","correctAnswer":1028,"isBookmarked":0,"aid":"1028#1591#4582#8417","text":"10 feet#5 feet#20 feet#30 feet"},
{"testId":659,"qid":29136,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder is the only method to check for blind spots. [North Dakota Noncommercial Drivers License Manual, Lane Changes, Rules of the Road]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":659,"qid":29137,"description":"Which vehicle(s) is/are unlikely to be seen by the truck driver?","explanation":"Trucks have extensive blind areas, dubbed No-Zones. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle that is just behind the truck or to the side of the truck, just behind the cab may be in a No-Zone. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. But Car B is unlikely to be seen to the truck driver. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [A Trucker's Blind Spots (the \"No-Zone\"), Sharing the Road with Trucks, Driving Skills and Emergency Situations, North Dakota Noncommercial Drivers License Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#32300","text":"Car A#Car B#Car C#All three cars"},
{"testId":659,"qid":29138,"description":"When do high-beam headlights need to be dimmed under North Dakota law?","explanation":"You must lower your high lights if you are within 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following, according to North Dakota law. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those drivers may reciprocate. [North Dakota Noncommercial Drivers License Manual, Night Driving, Rules of the Road]","imageName":"202002211503506612.jpg","correctAnswer":38882,"isBookmarked":0,"aid":"6334#38882#38883#38884","text":"Never#Whenever you are within 500 feet of an oncoming vehicle or following a vehicle within 300 feet#Whenever you are within 300 feet of an oncoming vehicle or following a vehicle within 500 feet#Whenever you are within 300 feet of any vehicle"},
{"testId":659,"qid":29139,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"If an oncoming vehicle's headlights have not been muted, inform the driver by flashing your high beams once. If the motorist continues to fail to reduce his or her headlights, do not respond with your own high beams. Instead, slow down, remain right, turn away from the oncoming vehicle, and steer along the right edge of the road until it passes. [North Dakota Noncommercial Drivers License Manual, Night Driving, Rules of the Road]","imageName":"202003161214084893.jpg","correctAnswer":38690,"isBookmarked":0,"aid":"38690#38730#38885#38886","text":"(c) Slow down and look at the right edge of the road#(b) and (c)#(a) Speed up to pass the vehicle quicker#(b) Slow down and switch on your high beams"},
{"testId":659,"qid":29140,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in North Dakota?","explanation":"The \"three-second rule\" is recommended by the North Dakota Noncommercial Drivers License Manual: Maintain a three-second following distance behind the car in front of you. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [North Dakota Noncommercial Drivers License Manual, Rules of the Road, Following and Stopping Distances]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"15926#16038#19915#34787","text":"At least two seconds#At least three seconds#At least 20 feet#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)"},
{"testId":659,"qid":29141,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#32321#38887#38888","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop before you reach the bus and remain stopped until the flashing red lights have been turned off, the bus resumes its motion, or the bus driver signals you to proceed#Stop before reaching the bus, yield if necessary, and then proceed"},
{"testId":659,"qid":29142,"description":"What should you do if you see a flashing yellow light?","explanation":"Flashing yellow signals function similarly to warning signs in that they alert vehicles to possible risks. Slow down and continue with care if you encounter a flashing yellow light at a junction. [North Dakota Noncommercial Drivers License Manual; Flashing Indications; Signals; Signs, Signals, and Road Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":659,"qid":29143,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"Seat belts or proper child restraints are required by Utah law for the driver and all passengers. If you or any passenger in your car is not wearing a seat belt or an adequate child restraint, you might be penalized and fined $45. [Violations of Seat Belts; Section 5: Before You Drive; Utah Driver Handbook]","imageName":"202007120530048918.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#29326#35497","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car on the busier road has the right of way#One driver must let the other driver through"},
{"testId":659,"qid":29144,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Hand Signals; Signs, Signals, and Road Markings; Noncommercial Drivers License Manual for North Dakota]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":659,"qid":29145,"description":"On a rural multilane highway, you spot an emergency vehicle with flashing lights ahead. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":34515,"isBookmarked":0,"aid":"15493#34515#35047#37416","text":"Slow down#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Proceed at your usual speed"},
{"testId":661,"qid":29146,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [STOP; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":661,"qid":29147,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":661,"qid":29148,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Before turning or changing lanes in Oklahoma, you must signal for at least 100 feet (one-third of a block). Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. Turning Without Signaling is Illegal, Turning, Chapter 7: Lane Usage and Maneuvers, Oklahoma Driver's Manual]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#41665","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Oklahoma"},
{"testId":661,"qid":29149,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":661,"qid":29150,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [YIELD; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":661,"qid":29151,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":661,"qid":29152,"description":"Which of the following places may you park legally?","explanation":"You may not park within 20 feet of an intersection crosswalk, 50 feet of the closest rail at a railroad crossing, or 30 feet of a stop sign, traffic signal, or flashing beacon in Oklahoma. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) In Scenario A, the automobile is far enough away from the crossing to park lawfully. [Unlawful Parking, Oklahoma Driver's Manual, Chapter 10: Parking]","imageName":"202003202116529567.jpg","correctAnswer":40071,"isBookmarked":0,"aid":"16391#40071#40072#40073","text":"D) None of the above#A) 25 feet from a crosswalk at an intersection#B) 25 feet from a railroad crossing#C) 25 feet from a stop sign"},
{"testId":661,"qid":29153,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#34168#40074","text":"A school zone sign#A work zone sign#A No Passing sign#A Detour sign"},
{"testId":661,"qid":29154,"description":"What must you do before entering a public road from an alley or driveway?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":40425,"isBookmarked":0,"aid":"40425#40426#41666#41667","text":"Stop and yield to all vehicles and pedestrians#Yield to all vehicles and pedestrians#Follow the usual right-of-way rules for an intersection without signs or signals#Stop only if pedestrians are present"},
{"testId":661,"qid":29155,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Oklahoma unless there are signs banning it. You must first come to a full stop and then yield to pedestrians and traffic. [Red Light: Stop! ; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#41668","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Oklahoma"},
{"testId":661,"qid":29156,"description":"Which car occupants in Oklahoma are obliged to wear seat belts or appropriate child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":32883,"isBookmarked":0,"aid":"4420#32883#41669#41670","text":"Only the driver#The driver, all passengers under 13, and all passengers in the front seat#None (seat belts are optional in Oklahoma)#The driver and all passengers under 13"},
{"testId":661,"qid":29157,"description":"You get to an intersection where the light is green. Can you keep going?","explanation":"After yielding to all pedestrians and cars already at the junction, you may continue on a green light. If you want to turn left, you must first yield to incoming traffic. [Green Light: Go; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003070857473536.jpg","correctAnswer":38861,"isBookmarked":0,"aid":"18118#34487#38861#38862","text":"No#Yes, right away#Yes, but only after you yield to pedestrians and vehicles already in the intersection#Yes, but only after you yield to cross traffic"},
{"testId":661,"qid":29158,"description":"Which of the following statements concerning the speed restrictions in Oklahoma is FALSE?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. Both restrictions are legally binding. Speed restrictions, on the other hand, are established for optimum driving circumstances. When driving circumstances are bad or dangerous, reduce your speed to less than the speed limit. It is illegal in Oklahoma to exceed the speed limit in order to pass another vehicle. [Speed, Expressway Driving, Oklahoma Driver's Manual, Chapter 7: Lane Usage and Maneuvers], [Chapter 8: Speed Limits, Oklahoma Driver's Manual]","imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#38864#41671#41672","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#Sometimes you should drive below the posted maximum speed limit#Do not drive on a turnpike or controlled-access highway if you cannot obey its minimum speed limit#The maximum speed limit on a county road is 55 mph except where otherwise posted"},
{"testId":661,"qid":29159,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, turn from the nearest lane into the nearest lane. Car B has appropriately entered the nearest lane. Car A has made an erroneous turn into the furthest lane. [Making a Left Turn, Turning, Oklahoma Driver's Manual, Chapter 7: Lane Usage and Maneuvers]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":661,"qid":29160,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"19403#32664#32667#40083","text":"Traffic may flow on both sides#There is a Y-intersection ahead#There is a new lane ahead#There is a passing lane ahead"},
{"testId":661,"qid":29161,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":661,"qid":29162,"description":"What is the meaning of this sign?","explanation":"At a junction, this sign is usually located underneath a stop sign. It denotes an all-way stop at this junction. That is, on every road leading up to this crossroads, there is a stop sign with an All Way or 4-Way sign below it. At an all-way stop, there are regulations that govern who has the right of way. [4-WAY or ALL-WAY; Oklahoma Driver's Manual, Chapter 5: Signs, Signals, and Markings]","imageName":"202004142331335982.jpg","correctAnswer":41673,"isBookmarked":0,"aid":"40423#41673#41674#41675","text":"You can turn either left or right at a red traffic signal#Every road at this intersection has a stop sign#Every road at this intersection has a stop or yield sign#You must yield to all traffic at this intersection"},
{"testId":661,"qid":29163,"description":"You're approaching an intersection with a constant yellow traffic light. You have to:","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Yellow Light: Consistent; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003051752141237.jpg","correctAnswer":37516,"isBookmarked":0,"aid":"28148#28857#37516#41676","text":"Proceed with caution#Speed up to get through the signal before it turns red#Stop before entering the intersection if it is safe to do so#Proceed after yielding to all vehicles and pedestrians"},
{"testId":661,"qid":29164,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":661,"qid":29165,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":null,"imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":661,"qid":29166,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Green Arrows: Turn; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"33150#34175#34490#35260","text":"No, you must wait for the red signal to change#No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming traffic before you turn"},
{"testId":661,"qid":29167,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Green Light: Go; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"1373203826_t1q22.png","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":661,"qid":29168,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Flashing Red Light: Stop! ; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"34177#35027#35029#37517","text":"Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier road#Proceed with caution; this signal is out of order"},
{"testId":661,"qid":29169,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#35483","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":661,"qid":29170,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35030#35262#37519","text":"There is a railroad crossing ahead#There is a four-way stop ahead#There is a train station ahead#You are going the wrong way; turn around and go back"},
{"testId":661,"qid":29171,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [How to Park on a Hill, Parking and Starting on a Hill, Oklahoma Driver's Manual, Chapter 10: Parking]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":661,"qid":29172,"description":"As you come to a railroad crossing, a train approaches. Where must you stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#33158#34772#41677","text":"Right in front of the tracks#10 feet from the tracks#15 to 50 feet from the tracks#25 to 60 feet from the tracks"},
{"testId":661,"qid":29173,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#35258#38872#38873","text":"Slow-moving vehicle#Work zone#Sharp curve or crest#Slippery or rough section of road"},
{"testId":661,"qid":29175,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202003241439594485.jpg","correctAnswer":16745,"isBookmarked":0,"aid":"16742#16745#38289#40092","text":"Car A because it is turning left#Car B because it is to the right of Car A#Car B because it is going straight#Car A because it is to the left of Car B"},
{"testId":661,"qid":29176,"description":"This driver employs an arm signal. Which of the following statements is true?","explanation":"This motorist wants to make a right turn. [Arm Signals, Turning, Oklahoma Driver's Manual, Chapter 7: Lane Usage and Maneuvers]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#41678","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid arm signal"},
{"testId":661,"qid":29177,"description":"In a shared middle lane, which of the following maneuvers may you perform?","explanation":null,"imageName":"202001301806403967.jpg","correctAnswer":41679,"isBookmarked":0,"aid":"41454#41679#41680#41681","text":"(a) and (b)#(a) Left turns#(b) Passing#(c) Parking"},
{"testId":661,"qid":29178,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002061056128575.jpg","correctAnswer":41683,"isBookmarked":0,"aid":"40084#41682#41683#41684","text":"The road ahead is subject to flooding#Don't discard cigarettes; they are a fire hazard#The pavement ahead changes to a dirt surface#The road ends ahead"},
{"testId":661,"qid":29179,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34195#34196","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":661,"qid":29180,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":661,"qid":29181,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101210401827.jpg","correctAnswer":38592,"isBookmarked":0,"aid":"26840#38592#38593#41685","text":"There is a truck escape ramp ahead#Trucks may enter or cross this roadway#Trucks may not enter or cross this roadway#Trucks carrying hazardous materials may not use this roadway"},
{"testId":661,"qid":29182,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. [Red Light: Stop! ; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"1372603791_t1q37.png","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":661,"qid":29183,"description":"When should you switch on your headlights?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"22962#35275#41686#41687","text":"In all of the above situations#Whenever visibility is difficult at 1,000 feet#From 30 minutes after sunset to 30 minutes before sunrise#Whenever you think your vehicle may be difficult to see"},
{"testId":661,"qid":29184,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":null,"imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#9435#40098#40099","text":"Never#At any time#Only if the vehicle is turning left#Only if traffic is at a standstill"},
{"testId":661,"qid":29185,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, on a sidewalk, or more than 18 inches from the curb in Oklahoma. Parking is not permitted within 15 feet of a fire hydrant. There are also additional areas where parking is prohibited. Look for signs or pavement markings prohibiting or limiting parking. The automobile in Situation D) has parked lawfully 10 inches from the curb. [Unlawful Parking, Oklahoma Driver's Manual, Chapter 10: Parking], [Parallel Parking, Oklahoma Driver's Manual]","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":661,"qid":29186,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. [Changing Lanes, Oklahoma Driver's Manual, Chapter 7: Lane Usage and Maneuvers]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":661,"qid":29187,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"Trucks have a lot of blind areas. A automobile in a blind zone may be entirely obscured from vision of the truck driver. (The truck's side mirrors only allow tiny arcs of view along the sides.) A car just behind or to the side of the truck, immediately behind the cab, may be in a blind area. Car B is now in one of the truck's blind zones. It is hard to avoid a truck's blind areas entirely, so do not stay in them for any longer than absolutely necessary. [Truck Blind Spots, Truck Sharing, Chapter 11: Sharing the Road, Oklahoma Driver's Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":661,"qid":29188,"description":"When do high-beam headlights need to be dimmed under Oklahoma law?","explanation":"When you are within 1,000 feet of an incoming vehicle or within 600 feet of a vehicle you are following in Oklahoma, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Using High and Low Headlight Beams Properly, Oklahoma Driver's Manual, Chapter 12: Driving Tips]","imageName":"202002211503506612.jpg","correctAnswer":41688,"isBookmarked":0,"aid":"34201#34202#41688#41689","text":"When you are within 500 feet of an oncoming vehicle or following a vehicle within 300 feet#When you are within 300 feet of an oncoming vehicle or following a vehicle within 500 feet#When you are within 1,000 feet of an oncoming vehicle or following a vehicle within 600 feet#When you are within 600 feet of an oncoming vehicle or following a vehicle within 1,000 feet"},
{"testId":661,"qid":29189,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"28552#31340#35485#41690","text":"This is a truck route#There is a steep descent ahead#There is an emergency escape ramp ahead#Trucks may enter from a steep side road ahead"},
{"testId":661,"qid":29190,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Oklahoma?","explanation":"Following distance of at least three seconds behind the vehicle in front of you is recommended by the Oklahoma Driver's Manual. You may calculate your following distance by noting when the car in front of you passes a stationary item and then calculating the seconds until you arrive at the same thing. If the weather is bad, you should increase your following distance. [Oklahoma Driver's Manual, Chapter 9: Stopping and Following]","imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"16038#16519#34787#35281","text":"At least three seconds#At least one second#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)#At least 25 feet"},
{"testId":661,"qid":29191,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#32321#38888#41691","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop before reaching the bus, yield if necessary, and then proceed#Stop before you reach the bus. Remain stopped until the flashing red lights have been turned off, the bus resumes its motion, or the bus driver signals you to proceed"},
{"testId":661,"qid":29192,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you encounter a flashing yellow light at a junction. [Caution! Flashing Yellow Light; Traffic Signals; Chapter 5: Signs, Signals, and Markings; Oklahoma Driver's Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":661,"qid":29193,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"1373203826_t1q48.png","correctAnswer":15535,"isBookmarked":0,"aid":"15535#15617#28558#34207","text":"Car A must yield because it is turning left#Car B must yield because it is turning right#The car in the busier lane has the right-of-way#This is an uncontrolled intersection; one driver must let the other driver through"},
{"testId":661,"qid":29194,"description":"This driver employs an arm signal. Which of the following statements is true?","explanation":"This arm signal is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Arm Signals, Turning, Oklahoma Driver's Manual, Chapter 7: Lane Usage and Maneuvers]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#41678","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid arm signal"},
{"testId":661,"qid":29195,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":41693,"isBookmarked":0,"aid":"15493#37416#41692#41693","text":"Slow down#Proceed at your usual speed#Slow down; also leave a vacant lane next to the emergency vehicle if possible#Leave a vacant lane next to the emergency vehicle if possible; otherwise slow down"},
{"testId":664,"qid":29196,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Stop signs are the only octagonal (eight-sided) traffic signs. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. You should pause for at least three seconds to gaze to the left and right. Then, yield to pedestrians and other cars and advance only when it is safe. [Regulatory Signs, Signs, Rhode Island Driver's Manual], [Stopping, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":664,"qid":29197,"description":"What is the meaning of this sign?","explanation":"The maximum speed limit under normal driving conditions is indicated by this regulatory sign. You are not permitted to exceed 50 mph in this area. It is important to note that this does not imply that driving at 50 mph is safe in this area; it is dependent on the present driving circumstances. Slow down below the maximum speed limit under dangerous driving circumstances. [M. Highway Safety, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#35462","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed is 50 mph"},
{"testId":664,"qid":29198,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Always strive to allow other drivers enough time to adjust to a turn or lane change. According to Rhode Island law, you must indicate at least 100 feet in advance of making a turn or pulling out from a parking place. Even if you don't see any other cars nearby, you still need to signal. The one you don't notice can turn out to be the most hazardous. [Rhode Island Driver's Manual, Chapters A. Signals, IV. Signals and Roadway Markings]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#40070","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Rhode Island"},
{"testId":664,"qid":29199,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [R. I. G. L. 31-17-1]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":664,"qid":29200,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and yield to oncoming vehicles and pedestrians before continuing. You may need to stop for them as well, so be prepared. [Signs, Regulatory Signs, Rhode Island Driver's Manual]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":664,"qid":29201,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"When an emergency vehicle approaches you with flashing lights or a siren, you must move over to the right side of the road, regardless of which way the emergency vehicle is heading. However, even if an emergency vehicle is coming, never block a junction. If you are in the midst of a junction and an emergency vehicle arrives, go through the intersection and immediately pull over. Always follow an emergency vehicle at least 500 feet. [M. Highway Safety, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#26798#34164#34165","text":"Slow down#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in"},
{"testId":664,"qid":29203,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and be prepared to yield to pedestrians. Follow the instructions of the school crossing guards. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#34168#40074","text":"A school zone sign#A work zone sign#A No Passing sign#A Detour sign"},
{"testId":664,"qid":29204,"description":"Which of the following statement about right-of-way is true?","explanation":"The right-of-way is to be granted rather than taken. The right-of-way is granted by law, but the other motorist may not. In such instances, it is preferable to yield to the opposing vehicle rather than risk a collision. [M. Highway Safety, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual]","imageName":"202003151700371291.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35256#35466#40075#40076","text":"Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever possible#You should always be prepared to yield the right-of-way#The right-of-way is about getting to your destination as quickly as possible"},
{"testId":664,"qid":29205,"description":"Is it legal to turn right at a red light?","explanation":"Unless there are signs preventing it, you may turn right on a red light in Rhode Island. You must first come to a full stop and then yield to pedestrians and traffic. [Rhode Island Driver's Manual, Red Light, D. Traffic Lights/Signals, IV. Signals and Roadway Markings]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#40077","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Rhode Island"},
{"testId":664,"qid":29206,"description":"In Rhode Island, which occupants of a vehicle have to wear a seat belt or an appropriate child restraint?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":35474,"isBookmarked":0,"aid":"16319#35254#35474#40078","text":"Just the driver#The driver and all passengers under 18#All occupants#The driver, all passengers under 18, and all front-seat passengers"},
{"testId":664,"qid":29207,"description":"Is it legal to turn left at a green light?","explanation":"If there are no indications banning it, you may turn right or left on a continuous green light. However, you must first yield to pedestrians in the crosswalk as well as cars already in the junction. If you're turning left, you must also yield to approaching traffic before making the turn. [R. I. G. L. 31-13-6]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#34487#39458#40079","text":"No#Yes, right away#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted"},
{"testId":664,"qid":29208,"description":"Which of the following is NOT true about the speed limits in Rhode Island?","explanation":"You must not exceed the maximum permitted speed limit. Speed restrictions, on the other hand, are established for optimum driving circumstances. The Basic Speed Law in Rhode Island mandates you to travel no faster than is safe for present road conditions, even if this means going slower than the official speed limit. Slower cars in Rhode Island must stay right unless passing or turning left. Under certain situations, a few US states allow you to temporarily exceed the speed limit in order to pass another vehicle, however Rhode Island does not. When schools are open in Rhode Island, the speed restriction in a school zone is 20 mph. [R. I. G. L. 31-14-2], [R. I. G. L. 31-15-2], [M. Safe Driving on the Highway, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual]","imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#40080#40081#40082","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#Sometimes you must drive below the posted maximum speed limit#If you are driving slower than the rest of the traffic, you must keep right except when passing or making a left turn#The speed limit in a school zone is 20 mph whenever schools are open"},
{"testId":664,"qid":29209,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or road markings indicate otherwise, you should cross as few lanes as possible while turning. That is, turn from the nearest lane into the nearest lane. Car B has appropriately entered the nearest lane. Car A has made an erroneous turn into the furthest lane. [Rhode Island Driver's Manual, D. How to Turn, III. Basic Driving Skills and Safety Rules]","imageName":"202001302242405745.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":664,"qid":29210,"description":"What is the meaning of this sign?","explanation":"This sign warns of a traffic island or other impending hazard. It may be passed on either side. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"19403#32664#32667#40083","text":"Traffic may flow on both sides#There is a Y-intersection ahead#There is a new lane ahead#There is a passing lane ahead"},
{"testId":664,"qid":29211,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop and yield signs may grant a certain highway preferred right-of-way. Cars A and C are both facing yield signals, hence they must both yield to Car B. [R. I. G. L. 31-17-4]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":664,"qid":29212,"description":"What is the meaning of this sign?","explanation":"This notice forewarns of a small bridge ahead. Slow down, remain in your lane, and drive cautiously. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202002101245562248.jpg","correctAnswer":28073,"isBookmarked":0,"aid":"15550#28073#29834#40084","text":"There is a railroad crossing ahead#There is a narrow bridge ahead#There is a hill ahead#The road ahead is subject to flooding"},
{"testId":664,"qid":29213,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you must stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Rhode Island Driver's Manual, Yellow Light, D. Traffic Lights/Signals, IV. Signals and Roadway Markings]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"19471#28857#40085#40086","text":"Slow down and proceed with caution#Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely"},
{"testId":664,"qid":29214,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [R. I. G. L. 31-17-2]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":664,"qid":29215,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is well marked on the left side of the road. [ii. A. Signs, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"814#1075#15906#15907","text":"Right turn ahead#No passing#Keep right in work zone#No parking"},
{"testId":664,"qid":29216,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Rhode Island Driver's Manual, Traffic Light with Green Arrow, D. Traffic Lights/Signals, IV. Signals and Roadway Markings]","imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"33151#34174#34490#34492","text":"No, these lights are out of order#Yes, but you must yield to oncoming vehicles before turning#Yes, this is a \"protected\" turn; oncoming traffic is stopped#No, you must wait for the red light to change"},
{"testId":664,"qid":29217,"description":"What should you do in Rhode Island when there is a stop sign at an intersection?","explanation":"If you approach a stop sign first, you must stop before the junction, crosswalk, or stop line. For at least three seconds, pause so that you may turn and gaze to the left and right. Next, give way to pedestrians and other traffic, and only go forward when it's safe to do so. [Rhode Island Driver's Manual, Stopping, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202001301744345845.jpg","correctAnswer":40090,"isBookmarked":0,"aid":"40087#40088#40089#40090","text":"(a) Stop for at least three seconds#(b) Stop before the stop line, crosswalk, or intersection#(c) Stop at least 15 feet from the intersection#Do both (a) and (b)"},
{"testId":664,"qid":29218,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Rhode Island Driver's Manual, D. Traffic Lights/Signals, IV. Signals and Roadway Markings]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"19471#23528#37531#40091","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Yield as you would at a yield sign#Yield to all traffic unless you are on the busier roadway"},
{"testId":664,"qid":29219,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Continue straight. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#35483","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":664,"qid":29220,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15548#15550#27259","text":"There is a crossroad ahead#There is a river crossing ahead#There is a railroad crossing ahead#You are going the wrong way; go back"},
{"testId":664,"qid":29221,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Rhode Island Driver's Manual, How to Park on Hills, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":664,"qid":29222,"description":"Except when otherwise posted, the speed limit in residential areas of Rhode Island is:","explanation":"Except when otherwise marked, the speed limit in commercial and residential areas in Rhode Island is 25 mph. [R. I. G. L. 31-14-2], [M. Safe Driving on the Highway, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual].","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":664,"qid":29223,"description":"What is the meaning of this sign?","explanation":"This insignia on the back of a car designates it as a slow-moving vehicle, which is defined by Rhode Island law as a vehicle meant to move at 25 mph or less. Farm tractors and road maintenance trucks are examples of slow-moving vehicles. Slow down and continue with care if you come across one of these cars. Keep your cool; the car may pull over to let you through. [R. I. G. L. 31-23-47], [Warning Signs, Signs, Rhode Island Driver's Manual]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#35258#38872#38873","text":"Slow-moving vehicle#Work zone#Sharp curve or crest#Slippery or rough section of road"},
{"testId":664,"qid":29224,"description":"Is it legal to make a left turn at a red light in Rhode Island?","explanation":"In some circumstances, forty-two US states permit left turns on red. Seven other states, including Rhode Island, do not. In Washington, DC, left turns on red are likewise prohibited. [R. I. G. L. 31-13-6], [D. Traffic Lights/Signals, IV. Signals and Roadway Markings, Rhode Island Driver's Manual].","imageName":"202003112014364009.jpg","correctAnswer":18118,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":664,"qid":29225,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles approach at a four-way stop or an uncontrolled junction (one without signs or signals) about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to its right here, giving Car B the right-of-way. [RI Driver's Manual, Four-Way Stops, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202003241439594485.jpg","correctAnswer":16745,"isBookmarked":0,"aid":"16742#16745#38289#40092","text":"Car A because it is turning left#Car B because it is to the right of Car A#Car B because it is going straight#Car A because it is to the left of Car B"},
{"testId":664,"qid":29226,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This motorist is indicating a right turn. [Rhode Island Driver's Manual, Hand Signals, A. Signals, IV. Signals, and Roadway Markings]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":664,"qid":29227,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a turn lane that is shared by two vehicles (called a two-way left-turn lane in Rhode Island law). This lane may be used by vehicles moving in either direction to perform left turns (and U-turns where permitted). Unlike in several other states, automobiles in Rhode Island are permitted to turn left onto the highway in this lane and subsequently merge into another lane. In this lane, you may not drive, park, or pass another vehicle. Within 200 feet of entering the shared turn lane, you must make a left turn. Use care in this lane since cars heading in the other way may be utilizing it as well. You may not enter the lane if another vehicle coming in the other direction has already entered it to perform a left turn. B. Roadway Markings, IV. Signals and Roadway Markings, Rhode Island Driver's Manual], [R. I. G. L. 31-15-19]","imageName":"202001301806403967.jpg","correctAnswer":30214,"isBookmarked":0,"aid":"8967#30214#36189#40093","text":"passing#making left turns#parking#making emergency stops"},
{"testId":664,"qid":29228,"description":"In which of the following scenarios is it permissible to pass on the right?","explanation":"In Rhode Island, you may pass a vehicle on the right only if it is 1) making or about to make a left turn, or 2) there are two or more lanes of traffic travelling in the same direction as you. (This might apply to a multilane motorway or a one-way street.) You may not, however, drive off the pavement or the main traveled area of the road to pass a motor vehicle on the left or right. Please keep in mind that the other car may not anticipate to be passed on the right, so act with caution. [R. I. G. L. 31-15-5], [Passing a Vehicle on the Right, J. Passing Another Vehicle, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual].","imageName":"202002181218288995.jpg","correctAnswer":34005,"isBookmarked":0,"aid":"203#6769#34005#40094","text":"All of the above#In heavy traffic#If the vehicle is making or about to make a left turn#If it's a slow-moving vehicle"},
{"testId":664,"qid":29229,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If you see a school bus stopped with its red lights flashing, you must stop before approaching the bus, whether it is on your side of the road or the opposing side. You must stay halted until the flashing red lights go out or the bus continues its journey. [R. I. G. L. 31-20-12], [E. School Buses, VIII. Sharing the Road Safely, Rhode Island Driver's Manual].","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34195#34196","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":664,"qid":29230,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Rhode Island Driver's Manual, Four-Way Stops, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":664,"qid":29231,"description":"You see a lane with a double broken yellow line on both sides. What kind of a lane is this?","explanation":"This is a lane that can be used both ways. A reversible lane transports traffic in one direction at times and the other way at others. Typically, reversible lanes are utilized to improve capacity during morning and evening peak hours. Keep an eye out for signs or signals indicating if the reversible lane is available in your direction. [Rhode Island Driver's Manual, B. Roadway Markings, IV. Signals and Roadway Markings]","imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16787#16788#40095","text":"A reversible lane#A central parking lane#A reserved lane#A shared turn lane"},
{"testId":664,"qid":29232,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning right at a red light. [Rhode Island Driver's Manual, Red Light, D. Traffic Lights/Signals, IV. Signals and Roadway Markings]","imageName":"1372603791_t1q37.png","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":664,"qid":29233,"description":"In Rhode Island, headlights are required while driving:","explanation":"You must use your headlights from sunset to dawn, anytime visibility is poor at 500 feet, or while using your windshield wipers in inclement weather, according to Rhode Island law. When inclement weather reduces your visibility, you must also use your headlights throughout the day. [Rhode Island Driver's Manual, Using Your Headlights, B. Night Driving, VIII. Sharing the Road Safely]","imageName":"202003161149128995.jpg","correctAnswer":24131,"isBookmarked":0,"aid":"24131#34292#40096#40097","text":"In all of the above situations#from sunset to sunrise#whenever visibility is difficult at 500 feet#whenever you're using your windshield wipers in bad weather"},
{"testId":664,"qid":29234,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"Driving off the pavement or major trafficked area of the route to pass another vehicle on the right is not permitted. Driving on the shoulder is strictly prohibited in Rhode Island. [R. I. G. L. 31-15-5], [R. I. G. L. 31-15-16], [Passing a Vehicle on the Right, J. Passing Another Vehicle, III. Basic Driving Skills and Safety Rules, Rhode Island Driver's Manual].","imageName":"202002181233013852.jpg","correctAnswer":6334,"isBookmarked":0,"aid":"6334#9435#40098#40099","text":"Never#At any time#Only if the vehicle is turning left#Only if traffic is at a standstill"},
{"testId":664,"qid":29235,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, on a sidewalk, or more than 12 inches from the curb in Rhode Island. In addition, you are not permitted to park within 8 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Look for signs or markings on the road that ban or limit parking.) In this case, the automobile in Situation A) has lawfully parked 10 feet away from the fire hydrant. (Note: Varying states may have different minimum parking distances.) Always verify the local traffic rules while going out of state.) [Rhode Island Driver's Manual, Where You May Not Park or Stop, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202003241433015085.jpg","correctAnswer":29321,"isBookmarked":0,"aid":"15951#15952#29321#40100","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 14 inches from the curb"},
{"testId":664,"qid":29236,"description":"Which of the following steps is not required when changing lanes?","explanation":"You must signal and check your mirrors while changing lanes. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. The only method to check for blind spots is to glance over your shoulder and look straight at them. [RI Driver's Manual, J. Passing Another Vehicle, III. Basic Driving Skills and Safety Rules]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":664,"qid":29237,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [A. Driving with Large Trucks, VIII. Driving Safely, Rhode Island Driver's Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":664,"qid":29238,"description":"When do high-beam headlights need to be dimmed under Rhode Island law?","explanation":"You must lower your high lights if you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following, according to Rhode Island law. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Rhode Island Driver's Manual, Dimming Your Headlights: 500/200 Rule of Dimming Headlights, B. Night Driving, VIII. Sharing the Road Safely]","imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"35043#35044#40101#40102","text":"Whenever you are within 500 feet of another vehicle#Whenever you are within 1,000 feet of another vehicle#Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet"},
{"testId":664,"qid":29239,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Check your brakes, drive slowly, and keep an eye on your speed. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Rhode Island Driver's Manual, Warning Signs]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35485","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is an emergency escape ramp ahead"},
{"testId":664,"qid":29240,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Rhode Island?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":16038,"isBookmarked":0,"aid":"7370#15926#16038#33894","text":"40 feet#At least two seconds#At least three seconds#One car length for every 10 mph of speed"},
{"testId":664,"qid":29241,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There are two exceptions to this provision in Rhode Island. (1) The school bus has come to a halt on the other side of a split roadway. (A physical barrier, such as a Jersey barrier, guardrail, grass, or trees, must be used as the separator.) (2) The school bus has come to a halt in a loading zone near to a limited-access roadway, and pedestrians are not permitted to cross it. [R. I. G. L. 31-20-13], [E. School Buses, VIII. Sharing the Road Safely, Rhode Island Driver's Manual].","imageName":"202002010826098089.jpg","correctAnswer":40103,"isBookmarked":0,"aid":"32321#38888#40103#40104","text":"Slow down to 15 mph and proceed with caution#Stop before reaching the bus, yield if necessary, and then proceed#Proceed#Stop before you reach the bus. Remain stopped until the flashing red lights are turned off or the bus resumes its motion"},
{"testId":664,"qid":29242,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [Rhode Island Driver's Manual, D. Traffic Lights/Signals, IV. Signals and Roadway Markings, Flashing Yellow Light]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#35009#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when it is safe to do so; you cannot turn here#Yield as you would at a yield sign"},
{"testId":664,"qid":29243,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"Headlights with high beams can be blinding. If an approaching vehicle's headlights have not been muted, inform the driver by flashing your high beams once. If the motorist continues to fail to reduce his or her headlights, do not respond with your own high beams. Instead, slow down, stay to the right, keep your eyes away from the incoming car, and steer by the right edge of the road until the vehicle has past. [Rhode Island Driver's Manual, Approaching Headlights, B. Night Driving, VIII. Sharing the Road Safely]","imageName":"202003161214084893.jpg","correctAnswer":40106,"isBookmarked":0,"aid":"40105#40106#40107#40108","text":"Speed up to pass the vehicle sooner#Watch the right edge of the roadway#Watch the left edge of the roadway#Turn on your own high beams"},
{"testId":664,"qid":29244,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Rhode Island Driver's Manual, Hand Signals, A. Signals, IV. Signals, and Roadway Markings]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":664,"qid":29245,"description":"Which of the following statements about work zones in Rhode Island is most accurate?","explanation":"Lower speed restrictions are posted in many work zones. These speed limitations are legally binding. In Rhode Island, whether or not employees are present, penalties for speeding in a work zone are quadrupled. When entering or driving through a work zone, use care, obey flagpersons (flaggers) or police officers, and avoid unexpected movements. Do not switch lanes. Do not tailgate; the majority of accidents in work zones are rear-end collisions. Keep an eye out for prospective night work zones. [XVII. Appendices, Rhode Island Driver's Manual, C. Construction and Work Zone Safety Requirements]","imageName":"202002061102364394.jpg","correctAnswer":40112,"isBookmarked":0,"aid":"40109#40110#40111#40112","text":"Work zone speed limits do not apply at night#You may safely change lanes unless signs prohibit it#Fines for speeding are doubled when workers are present#Fines for speeding are doubled whether or not workers are present"},
{"testId":665,"qid":29246,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [South Carolina Driver's Manual; Regulatory Signs; Traffic Signs, Signals, and Markings]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":665,"qid":29247,"description":"What is the meaning of this sign?","explanation":"Under typical driving circumstances, this sign indicates the maximum speed restriction. Slow down below the maximum speed limit under dangerous driving circumstances. [South Carolina Driver's Manual; Regulatory Signs; Traffic Signs, Signals, and Markings]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":665,"qid":29248,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Always strive to give other drivers plenty advance notice before changing lanes. Before making a turn or lane change in South Carolina, you must signal for at least 100 feet. Even if you don't see any other cars nearby, you still need to signal. The one you don't notice can turn out to be the most hazardous. [South Carolina Driver's Manual; Cars: The Road Test; Signals for Stops, Lane Changes, and Turns]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#40410","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in South Carolina"},
{"testId":665,"qid":29249,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Cars: The Road Test, South Carolina Driver's Manual, Yielding Right of Way]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":665,"qid":29250,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [South Carolina Driver's Manual; Regulatory Signs; Traffic Signs, Signals, and Markings]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":665,"qid":29251,"description":"You see an ambulance arriving with bright lights on a two-lane road. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":665,"qid":29252,"description":"In which of the following situations is the vehicle permitted to be parked?","explanation":"You may not park on an interstate highway, a sidewalk, or in front of a driveway in South Carolina. You may not park within 15 feet of a fire hydrant, 20 feet of an intersection crosswalk, 30 feet of a stop sign or traffic signal, or 50 feet of a railroad crossing. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) In case A, the automobile has lawfully parked 25 feet from a crosswalk at an intersection. [Places to Avoid Parking, General Information, South Carolina Driver's Handbook]","imageName":"202003202116529567.jpg","correctAnswer":40071,"isBookmarked":0,"aid":"16391#40071#40072#40411","text":"D) None of the above#A) 25 feet from a crosswalk at an intersection#B) 25 feet from a railroad crossing#C) 25 feet from a STOP sign"},
{"testId":665,"qid":29253,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and be prepared to yield to pedestrians. Follow the instructions of the school crossing guards. [Warning Signs; Traffic Signs, Signals, and Markings; Driver's Manual for South Carolina]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#34168#38852","text":"A school zone sign#A work zone sign#A No Passing sign#A guide sign"},
{"testId":665,"qid":29254,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. [Cars: The Road Test, South Carolina Driver's Manual, Yielding Right of Way]","imageName":"202003151700371291.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35015#35466#40075#40412","text":"The right-of-way is a legal right#You should claim the right-of-way whenever possible#You should always be prepared to yield the right-of-way#You can always proceed if you have the right-of-way"},
{"testId":665,"qid":29255,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in South Carolina unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#40413","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in South Carolina"},
{"testId":665,"qid":29256,"description":"Which car occupants in South Carolina are obliged to wear seat belts or appropriate child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#16678#35254","text":"Just the driver#The driver and all passengers#The driver, all passengers under 18, and all passengers in the front seat#The driver and all passengers under 18"},
{"testId":665,"qid":29257,"description":"Is it legal to turn left at a green light?","explanation":"On a green light, you may turn left after yielding to pedestrians, incoming cars, and vehicles already at the junction. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#39456#39457#39458","text":"No#No, not unless there is a Left Turns Allowed sign posted#Yes, this is a \"protected\" turn, so you'll have the right of way#Yes, but you must first yield to pedestrians and oncoming vehicles"},
{"testId":665,"qid":29258,"description":"Which of the following statements concerning the speed restrictions in South Carolina is FALSE?","explanation":"You must never exceed the maximum speed limit in South Carolina, and you must drive at a reasonable and sensible pace for present driving circumstances, even if that speed is less than the maximum speed limit. Slow down significantly below the speed limit, for example, while driving on a snowy road. Even if you are not breaking the speed limit, you might be charged for driving too fast for the circumstances. Under normal driving circumstances, it is illegal to drive on a road at less than the stated minimum speed. [Driving Too Slowly, General Information, South Carolina Driver's Manual], [Speed, General Information, South Carolina Driver's Manual]","imageName":"202003151746465721.jpg","correctAnswer":35475,"isBookmarked":0,"aid":"35475#40414#40415#40416","text":"On a two-lane road, you may exceed the speed limit by 10 mph to pass another vehicle safely#You can be cited for driving too fast even if you're not exceeding the speed limit#The maximum speed limit in an urban district is 30 mph except where otherwise posted#If you cannot meet a road's minimum speed limit, find another route"},
{"testId":665,"qid":29259,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [Cars: The Road Test, South Carolina Driver's Manual, Turning Your Vehicle]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":665,"qid":29260,"description":"What does it mean if you see the following sign before the curve in South Carolina?","explanation":null,"imageName":"202002061027163210.jpg","correctAnswer":40420,"isBookmarked":0,"aid":"40417#40418#40419#40420","text":"The curve is moderately sharp#The minimum speed on the curve is 35 mph#It is recommended that you do not exceed 35 mph on the curve#You must not exceed 35 mph on the curve"},
{"testId":665,"qid":29261,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing yield signals, hence they must both yield to Car B. [Cars: The Road Test, South Carolina Driver's Manual]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":665,"qid":29262,"description":"If you see this sign at the intersection, What does it mean?","explanation":"At a junction, this sign is usually located underneath a stop sign. It denotes an all-way stop at this junction. That is, on every road leading up to this crossroads, there is a stop sign with an All Way or 4-Way sign underneath it. At an all-way stop, there are regulations that govern who has the right of way. [Cars: The Road Test, South Carolina Driver's Manual, Four-Way or Multi-Way Stops]","imageName":"202004142331335982.jpg","correctAnswer":40421,"isBookmarked":0,"aid":"40421#40422#40423#40424","text":"Every roadway approaching this intersection has a stop sign#Every roadway approaching this intersection has a stop or yield sign#You can turn either left or right at a red traffic signal#If you turn here, you will enter a two-way road"},
{"testId":665,"qid":29263,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#28858#40085#40086","text":"Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Drive through if you can do so safely#Stop if you can do so safely"},
{"testId":665,"qid":29264,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Cars: The Road Test, South Carolina Driver's Manual, Yielding Right of Way]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":665,"qid":29265,"description":"What must you do before entering a public road from an alley or driveway?","explanation":null,"imageName":"202001291438598251.jpg","correctAnswer":40425,"isBookmarked":0,"aid":"40425#40426#40427#40428","text":"Stop and yield to all vehicles and pedestrians#Yield to all vehicles and pedestrians#Proceed as you would at an intersection without signs or signals#Sound your horn to alert any pedestrians ahead and proceed with caution"},
{"testId":665,"qid":29266,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202003070834498321.jpg","correctAnswer":39805,"isBookmarked":0,"aid":"33151#34492#35260#39805","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":665,"qid":29267,"description":"What is the maximum rural speed limit in South Carolina, unless otherwise indicated?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":682,"isBookmarked":0,"aid":"682#684#1745#3852","text":"55 mph#65 mph#40 mph#75 mph"},
{"testId":665,"qid":29268,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"28148#34177#35027#35029","text":"Proceed with caution#Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier road"},
{"testId":665,"qid":29269,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Warning Signs; Traffic Signs, Signals, and Markings; Driver's Manual for South Carolina]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#35483","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#An extra lane forms ahead"},
{"testId":665,"qid":29270,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Warning Signs; Traffic Signs, Signals, and Markings; Driver's Manual for South Carolina]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#37519#40429","text":"There is a railroad crossing ahead#There is a train station ahead#You are going the wrong way; turn around and go back#There is a four-way intersection ahead"},
{"testId":665,"qid":29271,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking, Cars: The Road Test, Driver's Manual for South Carolina]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":665,"qid":29272,"description":"Unless otherwise posted, the speed limit in South Carolina's residential zones is:","explanation":"Unless otherwise indicated, the speed limit in residential and urban areas of South Carolina is 30 mph. [South Carolina Driver's Manual, Speed Limits, General Information]","imageName":"202003151746465721.jpg","correctAnswer":521,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":665,"qid":29273,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":"Pedestrians crossing the street legally should be given the right of way. You must yield to all legally crossing pedestrians before turning at a green light. You may use your horn to alert pedestrians who are not crossing the street legally (jaywalkers). Even if they ignore the warning, you must still stop for them. [Pedestrians on the Road, Sharing the Road, General Information, South Carolina Driver's Manual]","imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40432#40433","text":"You must yield#The pedestrian must yield#You must yield, but only if the pedestrian is crossing with a WALK signal#Neither of you must yield; you and the pedestrian should work it out"},
{"testId":665,"qid":29274,"description":"Is it legal to make a left turn at a red light in South Carolina?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"35269#38668#38669#40413","text":"Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street#No, not in South Carolina"},
{"testId":665,"qid":29275,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles approach at a four-way stop or an uncontrolled junction (one without signs or signals) about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to its right here, giving Car B the right-of-way. [Cars: The Road Test, South Carolina Driver's Manual, Four-Way or Multi-Way Stops]","imageName":"202003241439594485.jpg","correctAnswer":16745,"isBookmarked":0,"aid":"16742#16745#38289#40092","text":"Car A because it is turning left#Car B because it is to the right of Car A#Car B because it is going straight#Car A because it is to the left of Car B"},
{"testId":665,"qid":29276,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Stop, Lane Change, and Turn Signals; Cars: The Road Test; South Carolina Driver's Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":665,"qid":29277,"description":"What is the meaning of this sign?","explanation":"This sign notifies cars that there is a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Before entering the roundabout, slow down and ready to yield to traffic. [South Carolina Driver's Manual; Pavement Markings; Traffic Signs, Signals, and Markings]","imageName":"202002101237092503.jpg","correctAnswer":15654,"isBookmarked":0,"aid":"15654#39441#39442#39443","text":"There is a roundabout ahead#U-turns are allowed ahead#Wrong way; turn around here#Only left turns are allowed ahead"},
{"testId":665,"qid":29278,"description":"In which of the following situations is it permissible to pass on the right?","explanation":null,"imageName":"202002181218288995.jpg","correctAnswer":34005,"isBookmarked":0,"aid":"203#6769#34005#40434","text":"All of the above#In heavy traffic#If the vehicle is making or about to make a left turn#If it's a slow-moving vehicle in the rightmost lane"},
{"testId":665,"qid":29279,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If you see a school bus stopped with its red lights flashing on a two-lane road in South Carolina, you must stop before approaching the bus, whether it is on your side or the opposing side. You must stay halted until the flashing red lights go out or the bus continues its journey. However, on a multilane road, you must only stop if you are driving in the same direction as the bus. (Note: Some states have varying regulations about when you must stop for a halted school bus. Always verify the local traffic rules while going out of state.) [School Bus Stopped, General Information, South Carolina Driver's Manual]","imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#32321#34195#34196","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":665,"qid":29280,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Cars: The Road Test, South Carolina Driver's Manual, Four-Way or Multi-Way Stops]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":665,"qid":29281,"description":"Which vehicle is not following the rules of the roundabout?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Car C seems to have ignored the painted arrows and went left from the right lane. [South Carolina Driver's Manual; Pavement Markings; Traffic Signs, Signals, and Markings]","imageName":"202001290908474628.jpg","correctAnswer":14862,"isBookmarked":0,"aid":"14814#14815#14862#33544","text":"Car A#Car B#Car C#None (i.e., all three cars are using the roundabout correctly)"},
{"testId":665,"qid":29282,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. Wait for the pedestrians to cross at least your side of the street. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"1372603791_t1q37.png","correctAnswer":40435,"isBookmarked":0,"aid":"15679#28545#35038#40435","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Stop and let the pedestrian cross your side of the roadway"},
{"testId":665,"qid":29283,"description":"In South Carolina, you must have your headlights switched on:","explanation":"Headlights are required in South Carolina from half an hour after nightfall to half an hour before daybreak. You must also activate your headlights when vision is poor at 500 feet and while running your windshield wipers constantly (rather than the intermittent \"mist\" setting) in bad weather. [South Carolina Driver's Manual, Driving at Night, General Information]","imageName":"202003161149128995.jpg","correctAnswer":24131,"isBookmarked":0,"aid":"24131#31574#40096#40436","text":"In all of the above situations#from half an hour after sunset to half an hour before sunrise#whenever visibility is difficult at 500 feet#whenever you're using your windshield wipers continuously in poor weather"},
{"testId":665,"qid":29284,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two automobiles approach from opposing directions at a four-way stop or an uncontrolled junction (i.e., one without signs or signals) at roughly the same time, the car turning left must yield. Car A must yield to Car B in this situation. Each vehicle at a four-way stop must come to a full stop before advancing. [Cars: The Road Test, South Carolina Driver's Manual, Four-Way or Multi-Way Stops]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40437#40438","text":"Car A#Car B#The car on the busiest approach to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":665,"qid":29285,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, on a sidewalk, or more than 18 inches from the curb in South Carolina. Parking is not permitted within 15 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) The automobile in Situation D) has parked lawfully 10 inches from the curb. [General Information, South Carolina Driver's Manual], [Parking and Leaving a Vehicle, General Information, South Carolina Driver's Manual]","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":665,"qid":29286,"description":"Which of the following steps is not required when changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. [Interstate Driving, Cars: The Road Test, South Carolina Driver's Manual]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":665,"qid":29287,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Road Sharing with Commercial Vehicles, Road Sharing, General Information, South Carolina Driver's Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":665,"qid":29288,"description":"When do high-beam headlights need to be dimmed under South Carolina law?","explanation":"When you are within 500 feet of an incoming vehicle or within 200 feet of a vehicle you are following in South Carolina, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [South Carolina Driver's Manual, Driving at Night, General Information]","imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"35043#35044#40101#40102","text":"Whenever you are within 500 feet of another vehicle#Whenever you are within 1,000 feet of another vehicle#Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet"},
{"testId":665,"qid":29289,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Warning Signs; Traffic Signs, Signals, and Markings; Driver's Manual for South Carolina]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#35273","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This route is for trucks only"},
{"testId":665,"qid":29290,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in South Carolina?","explanation":"The South Carolina Driver's Manual advises a four-second following distance behind the car in front of you. You should increase your following distance even more under difficult driving circumstances. [Cars: The Road Test, South Carolina Driver's Manual, Following Other Cars]","imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"7370#10879#10881#33894","text":"40 feet#Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":665,"qid":29291,"description":"You're driving on a four-lane divided highway. You approach a school bus that has stopped on the opposite side of the highway with flashing red lights and an extended stop arm. What should you do?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. There are two exceptions to this provision in South Carolina. (1) You are not required to stop if the school bus is stopped on the other side of a four-lane highway. (2) You are not required to stop for a school bus parked in an off-road loading zone where pedestrians are not permitted to cross the highway. (Note: Such exclusions are not permitted in all states.) Always verify the local traffic rules while going out of state.) [School Bus Stopped, General Information, South Carolina Driver's Manual]","imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#32321#38888#40440","text":"Proceed with caution#Slow down to 15 mph and proceed with caution#Stop before reaching the bus, yield if necessary, and then proceed#Stop before you reach the bus. Remain stopped until the flashing red lights have been turned off or the bus resumes its motion"},
{"testId":665,"qid":29292,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you encounter a flashing yellow light at a junction. [South Carolina Driver's Manual; Traffic Signs, Signals, and Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":665,"qid":29293,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"If an oncoming vehicle's headlights have not been muted, inform the driver by flashing your high beams once. If the motorist continues to fail to reduce his or her headlights, do not respond with your own high beams. Instead, slow down, remain right, turn away from the oncoming vehicle, and steer along the right edge of the road until it passes. [South Carolina Driver's Manual, Driving at Night, General Information]","imageName":"202003161214084893.jpg","correctAnswer":40441,"isBookmarked":0,"aid":"40105#40441#40442#40443","text":"Speed up to pass the vehicle sooner#Watch the right edge of the road#Watch the left edge of the road#Switch on your own high beams"},
{"testId":665,"qid":29294,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Stop, Lane Change, and Turn Signals; Cars: The Road Test; South Carolina Driver's Manual]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":665,"qid":29295,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"15493#35047#37416#39336","text":"Slow down#Slow down; also move into a non-adjacent lane if possible#Proceed at your usual speed#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":666,"qid":29296,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, which is the only sort of sign that is octagonal (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":666,"qid":29297,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sign indicates the maximum speed restriction for a segment of road. Slow down below the maximum speed limit under dangerous driving circumstances. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":666,"qid":29298,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"You should always signal long enough for other vehicles to be aware of your intentions. Before turning in South Dakota, you must signal for at least 100 feet. Consider signaling for four to five seconds at greater speeds. (A car traveling at 45 mph will traverse 100 feet in 1.5 seconds.) Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [South Dakota Driver License Manual, Scanning, Seeing Well, Safe Driving Tips]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#40600","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in South Dakota"},
{"testId":666,"qid":29299,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":666,"qid":29300,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":666,"qid":29301,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":666,"qid":29302,"description":"You are not permitted to park within ______ of a railroad crossing.","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":666,"qid":29303,"description":"What is the meaning of this sign?","explanation":"A school zone is indicated by this pentagonal (five-sided) sign. Slow down, be cautious, and keep an eye out for students. Schoolchildren have the right of way in a school crossing. Please keep in mind that an earlier version of this sign has a yellow backdrop. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202003151740461521.jpg","correctAnswer":638,"isBookmarked":0,"aid":"638#5650#9573#33265","text":"A school zone#A pedestrian crosswalk#A hospital#A bus stop"},
{"testId":666,"qid":29304,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide anybody the right-of-way. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003151700371291.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35015#35466#40075#40412","text":"The right-of-way is a legal right#You should claim the right-of-way whenever possible#You should always be prepared to yield the right-of-way#You can always proceed if you have the right-of-way"},
{"testId":666,"qid":29305,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in South Dakota unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#40601","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in South Dakota"},
{"testId":666,"qid":29306,"description":"Which passengers in a car are needed to use proper child restraints or seat belts in South Dakota?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":34769,"isBookmarked":0,"aid":"16319#16365#34769#35254","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#The driver and all passengers under 18"},
{"testId":666,"qid":29307,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You, however, do not have the right-of-way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202001301833114961.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#34487#39458#40079","text":"No#Yes, right away#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted"},
{"testId":666,"qid":29308,"description":"Which of the following statements concerning the speed limits in South Dakota is FALSE?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":40604,"isBookmarked":0,"aid":"40416#40602#40603#40604","text":"If you cannot meet a road's minimum speed limit, find another route#On certain roads, you may exceed the speed limit by 10 mph to pass another vehicle safely#You can be cited for driving too fast for current conditions even if you're not exceeding the speed limit#The maximum speed limit in an urban area is 35 mph except where otherwise posted"},
{"testId":666,"qid":29309,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [General Rules, Traffic Rules, South Dakota Driver License Manual]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":666,"qid":29310,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#16692#16834#16857","text":"Slow-moving vehicle#Work zone ahead#Dead end ahead#No passing ahead"},
{"testId":666,"qid":29311,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing yield signals, hence they must both yield to Car B. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":666,"qid":29312,"description":"If you see this sign at the intersection, What does it mean?","explanation":"At a junction, this sign is usually located underneath a stop sign. It denotes an all-way stop at this junction. That is, on every road leading up to this crossroads, there is a stop sign with an All Way or 4-Way sign underneath it. At an all-way stop, there are regulations that govern who has the right of way. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202004142331335982.jpg","correctAnswer":40421,"isBookmarked":0,"aid":"40421#40422#40423#40424","text":"Every roadway approaching this intersection has a stop sign#Every roadway approaching this intersection has a stop or yield sign#You can turn either left or right at a red traffic signal#If you turn here, you will enter a two-way road"},
{"testId":666,"qid":29313,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a complete stop and risk being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#28858#40085#40086","text":"Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Drive through if you can do so safely#Stop if you can do so safely"},
{"testId":666,"qid":29314,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":666,"qid":29315,"description":"How much longer does it take to stop at 60 mph than at 30 mph?","explanation":"Stopping at 60 mph takes three times as long as stopping at 30 mph. The stopping distance is calculated as the sum of the perception distance (the distance your vehicle travels before your brain notices a hazard), the reaction distance (the distance your vehicle travels before your brain moves your foot to the brake pedal and begins to brake), and the braking distance (how far your vehicle travels until the brakes bring it to a stop). The braking distance is the only one that is related to the square of the speed. That instance, the braking distance at 60 mph is around four times that at 30 mph. [Speed Adjustment, Driving Safety Tips, South Dakota Driver License Manual]","imageName":"202003151746465721.jpg","correctAnswer":32023,"isBookmarked":0,"aid":"32023#32026#40605#40606","text":"Over three times the distance#Over four times the distance#More than twice the distance#The same distance"},
{"testId":666,"qid":29316,"description":"Is it permissible to turn left at a green signal?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202003070834498321.jpg","correctAnswer":39805,"isBookmarked":0,"aid":"33150#33151#35260#39805","text":"No, you must wait for the red signal to change#No, these lights are out of order#Yes, but you must yield to oncoming traffic before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way"},
{"testId":666,"qid":29317,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a shared middle lane. This lane may be used by vehicles moving in either direction to perform left turns (and U-turns where permitted). Use care in this lane since cars heading in the other way may be utilizing it as well. During rush hour, several shared center lanes may become reversible lanes. That is, the lane is available to traffic in one way during rush hour in the morning and in the other direction during rush hour in the evening. Before attempting to enter this lane, look for signs or signals indicating if you are permitted to do so. [Sharing the Center Lane, Other Lane Controls, Road Rules, South Dakota Driver License Manual]","imageName":"202001301806403967.jpg","correctAnswer":40607,"isBookmarked":0,"aid":"16863#16865#40607#40608","text":"Passing#Car pooling#Left turns#Emergency stops"},
{"testId":666,"qid":29318,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202003051722347868.jpg","correctAnswer":35027,"isBookmarked":0,"aid":"28148#34177#35027#35029","text":"Proceed with caution#Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Yield to all vehicles unless you are on the busier road"},
{"testId":666,"qid":29319,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is placed on the left side of the road and bears the words \"NO PASSING ZONE\" in black characters on a yellow backdrop. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"1075#9459#14994#16692","text":"No passing#Divided highway begins#Keep right#Work zone ahead"},
{"testId":666,"qid":29320,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#37519#40429","text":"There is a railroad crossing ahead#There is a train station ahead#You are going the wrong way; turn around and go back#There is a four-way intersection ahead"},
{"testId":666,"qid":29321,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking, Traffic Rules, South Dakota Driver License Manual]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":666,"qid":29322,"description":"You notice a section of white curb. Is it legal for you to park here?","explanation":"Colored curb markings may be used in addition to or instead of regulation signs to limit parking. In South Dakota, a yellow curb signifies a loading zone: You may only stop there to pick up or drop off persons or goods. A white-painted curb is stricter: you may only stop there to pick up or drop off persons, not freight. (Please keep in mind that there is no national standard for curb colors.) Curb colors are used differently in each state, and others do not use them at all. Always verify the local traffic rules while going out of state.) [Parking, Traffic Rules, South Dakota Driver License Manual]","imageName":"202005121046039920.jpg","correctAnswer":40610,"isBookmarked":0,"aid":"18118#40609#40610#40611","text":"No#Yes, but only to pick up or drop off passengers or cargo#Yes, but only to pick up or drop off passengers#Yes, this is a metered parking area"},
{"testId":666,"qid":29323,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":666,"qid":29324,"description":"When may you turn left at a red light in South Dakota?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":40617,"isBookmarked":0,"aid":"40614#40615#40616#40617","text":"(a) Only when you're turning from a one-way street onto another one-way street#(b) Only in certain jurisdictions#When either (a) or (b) is true#When both (a) and (b) are true"},
{"testId":666,"qid":29325,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles approach at a four-way stop or an uncontrolled junction (one without signs or signals) about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to its right here, giving Car B the right-of-way. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003241439594485.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#16831","text":"Car A#Car B#Neither car#Whichever car proceeds first"},
{"testId":666,"qid":29326,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This arm symbol denotes a turn to the right. [South Dakota Driver License Manual, Scanning, Seeing Well, Safe Driving Tips]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#6476","text":"Turn left#Turn right#Speed up#Slow down"},
{"testId":666,"qid":29328,"description":"When is it permissible to drive on the road's shoulder to pass another vehicle?","explanation":"Unless directed otherwise by a traffic official, you are not permitted to drive on the shoulder. [General Rules, Traffic Rules, South Dakota Driver License Manual]","imageName":"1373963096_t1q33.png","correctAnswer":6334,"isBookmarked":0,"aid":"6334#34188#35035#38881","text":"Never#If the vehicle is turning left#If the posted speed limit is 25 mph or less#If traffic is at a standstill"},
{"testId":666,"qid":29329,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If you come across a school bus stopped with its red lights flashing on a two-lane road in South Dakota, you must stop at least 15 feet away from the bus, whether it is on your side or the opposing side. You must stay halted until the flashing red lights turn off. However, if the route has two or more lanes in each direction, you must only stop if you are heading in the same direction as the school bus. (Note: Some states have varying regulations about when you must stop for a halted school bus. Always verify the local traffic rules while going out of state.) [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#34196#34928#40618","text":"Proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 20 mph and proceed with caution"},
{"testId":666,"qid":29330,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [South Dakota Driver License Manual: Right-of-Way, Rules of the Road]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":666,"qid":29331,"description":"A pedestrian ahead of you raises a white cane into the air. What exactly does this mean?","explanation":"A white cane may only be carried by a blind or visually impaired walker. When a blind or visually challenged pedestrian lifts his or her cane in South Dakota, it indicates that the individual wants to cross the street. You must come to a complete stop for the pedestrian and wait for him or her to cross securely. Sounding your horn may surprise the pedestrian or cancel out aural indications that he or she depends on to travel safely. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003202129395393.jpg","correctAnswer":40623,"isBookmarked":0,"aid":"40621#40622#40623#40624","text":"The pedestrian is angrily gesturing to another pedestrian or motorist; keep your distance#The pedestrian is flagging down a taxi or bus; proceed with caution#The pedestrian is about to cross the street; prepare to yield#The pedestrian will cross the street after you pass"},
{"testId":666,"qid":29332,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":666,"qid":29333,"description":"Which of the following things about headlights is not true?","explanation":"When you can't see clearly or when other drivers can't see you, use your headlights. In fog, utilize your low-beam headlights and, if your vehicle has them, your fog lights. In South Dakota, you must use your headlights from one-half hour after sunset to one-half hour before daybreak, and anytime you cannot see a person on the road from 200 feet away. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. It is unlawful in South Dakota to utilize your parking lights when your headlights are necessary. [Informing Others, Communicating, Safe Driving Tips, South Dakota Driver License Manual]","imageName":"202003161149128995.jpg","correctAnswer":40626,"isBookmarked":0,"aid":"15516#40625#40626#40627","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#You must use your headlights whenever it's difficult to see a person 200 feet ahead#You may drive with your parking lights on to make your vehicle more visible during the day#Use your low beams when driving in fog"},
{"testId":666,"qid":29334,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two automobiles approach from opposing directions at a four-way stop or an uncontrolled junction (i.e., one without signs or signals) at roughly the same time, the car turning left must yield. Car A must yield to Car B in this situation. Each vehicle at a four-way stop must come to a full stop before advancing. [Right-of-Way, Road Rules, South Dakota Driver License Manual]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40437#40438","text":"Car A#Car B#The car on the busiest approach to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":666,"qid":29335,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, on a sidewalk, or more than two feet from the curb in South Dakota. Parking is not permitted within 15 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) The automobile in Situation D) has parked lawfully 12 inches from the curb. [Parking, Traffic Rules, South Dakota Driver License Manual]","imageName":"202003241433015085.jpg","correctAnswer":16166,"isBookmarked":0,"aid":"15951#15952#16166#29321","text":"B) On a bridge#C) On the sidewalk#D) 12 inches from the curb#A) 10 feet from a fire hydrant"},
{"testId":666,"qid":29336,"description":"Which of the following steps is not required when changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. [South Dakota Driver License Manual, Scanning, Seeing Well, Safe Driving Tips]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":666,"qid":29337,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Points to Remember, Sharing the Road with Large Trucks and Buses, South Dakota Driver License Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":666,"qid":29338,"description":"When should your high beams be dimmed?","explanation":null,"imageName":"1373963096_t1q43.png","correctAnswer":11220,"isBookmarked":0,"aid":"11220#40628#40629#40630","text":"In any of the above situations#When you are meeting or following another vehicle#When you are driving on a well-lit road#In fog, rain, or snow"},
{"testId":666,"qid":29339,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [South Dakota Driver License Manual, Traffic Signs, Traffic Control Devices, Rules of the Road]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#40631","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#This road is a truck route"},
{"testId":666,"qid":29340,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in South Dakota?","explanation":"The \"three-second rule\" is recommended by the South Dakota Driver License Manual: maintain a following distance of at least three seconds behind the vehicle in front of you. When driving in bad or dangerous circumstances, you should increase your following distance to four or more seconds. [How Well Can You See, Driving Safety Tips, South Dakota Driver License Manual]","imageName":"202001302249003541.jpg","correctAnswer":10880,"isBookmarked":0,"aid":"7370#10880#10881#33894","text":"40 feet#Three seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":666,"qid":29341,"description":"You're driving on a four-lane divided highway. You approach a school bus that has stopped on the opposite side of the highway with flashing red lights and an extended stop arm. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#40618#40632#40633","text":"Proceed with caution#Slow to 20 mph and proceed with caution#Stop at least 15 feet before the bus. Remain stopped until the flashing red lights have been turned off#Stop at least 15 feet before the bus, yield if necessary, and then proceed"},
{"testId":666,"qid":29342,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care if you observe a flashing yellow signal. [South Dakota Driver License Manual, Traffic Control Devices, Rules of the Road]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":666,"qid":29343,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"An incoming vehicle's bright lights might be blinding. If an oncoming vehicle's headlights have not been muted, inform the driver by flashing your high beams once. If the motorist continues to fail to reduce his or her headlights, do not respond with your own high beams. Instead, slow down, remain right, turn away from the oncoming vehicle, and steer along the right edge of the road until it passes. [How to Use Your Lights, How to See Well, Safe Driving Tips, South Dakota Driver License Manual]","imageName":"202003161214084893.jpg","correctAnswer":40441,"isBookmarked":0,"aid":"40105#40441#40442#40443","text":"Speed up to pass the vehicle sooner#Watch the right edge of the road#Watch the left edge of the road#Switch on your own high beams"},
{"testId":666,"qid":29344,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This arm signal is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [South Dakota Driver License Manual, Scanning, Seeing Well, Safe Driving Tips]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":666,"qid":29345,"description":"You come to a broken traffic light, where none of the lights are on. What do you need to do?","explanation":null,"imageName":"202003051805205845.jpg","correctAnswer":40636,"isBookmarked":0,"aid":"28148#40634#40635#40636","text":"Proceed with caution#Yield as if the intersection had no traffic signals#Assume you have the right-of-way if you're on the busiest road#Stop as if you were at a stop sign"},
{"testId":667,"qid":29346,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, which is the only sort of sign that is octagonal (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Tennessee Comprehensive Driver License Manual, Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":667,"qid":29347,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sign indicates the maximum speed restriction for a segment of road. Slow down below the maximum speed limit under dangerous driving circumstances. [Tennessee Speed Laws, Some Basic Rules Are, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual], [Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals, Tennessee Comprehensive Driver License Manual]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":667,"qid":29348,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"When you wish to turn, explain your purpose ahead of time. If you want to turn, Tennessee law requires you to begin signaling at least 50 feet before the turn. (Note: At the speeds that today's automobiles and roads are capable of, 50 feet may not be sufficient. At greater speeds, you should begin signaling sooner.) Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [B. Turning, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202001291433312120.jpg","correctAnswer":30731,"isBookmarked":0,"aid":"29975#30731#40760#40761","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#No, turn signals are optional in Tennessee#Yes, you must signal for at least 25 feet before you turn"},
{"testId":667,"qid":29349,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":667,"qid":29350,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [Tennessee Comprehensive Driver License Manual, Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":667,"qid":29351,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":667,"qid":29352,"description":"You cannot park _____ or less from a railroad crossing in Tennessee.","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":667,"qid":29353,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. In a school zone, always yield to pedestrians and keep an eye out for youngsters on the road. [Tennessee Comprehensive Driver License Manual, B-3. Traffic Signs and Signals, Warning Signs - Diamond Shape]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#38852#40762","text":"A school zone sign#A work zone sign#A guide sign#A pedestrian crosswalk sign"},
{"testId":667,"qid":29354,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide anybody the right-of-way. Unfortunately, you may come across cars or pedestrians that do not obey the regulations. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. Always prioritize safety above speed. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202003151700371291.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35015#35466#40075#40412","text":"The right-of-way is a legal right#You should claim the right-of-way whenever possible#You should always be prepared to yield the right-of-way#You can always proceed if you have the right-of-way"},
{"testId":667,"qid":29355,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Tennessee unless there are signs banning it. You must first come to a full stop and then yield to pedestrians and traffic. [Tennessee Comprehensive Driver License Manual, Red, Traffic Signals, B-3. Traffic Signs and Signals]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#40763","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Tennessee"},
{"testId":667,"qid":29356,"description":"Who must wear a seatbelt or child safety seat in Tennessee if the driver has an intermediate license?","explanation":"Seatbelts save people's lives. Every occupant of a vehicle operated by an intermediate license holder in Tennessee is obliged to wear a seatbelt or an appropriate child restraint. Rear-seat passengers who are at least 17 years old are not obliged to wear seatbelts if the driver has a normal driving license. [B-2. Tennessee Safety Belt Laws, Tennessee Comprehensive Driver License Manual]","imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#40764#40765","text":"Just the driver#The driver and all passengers#The driver and all passengers under 17#The driver, all front-seat passengers, and all passengers under 17"},
{"testId":667,"qid":29357,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Tennessee Comprehensive Driver License Manual, Green, Traffic Signals, B-3. Traffic Signs and Signals]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#34487#39458#40766","text":"No#Yes, right away#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles"},
{"testId":667,"qid":29358,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#30005#35047#39336","text":"Proceed with caution#Slow down and prepare to stop#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":667,"qid":29359,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [Turning, B-4. Road Rules, Tennessee Comprehensive Driver License Manual]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":667,"qid":29360,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#16692#16834#16857","text":"Slow-moving vehicle#Work zone ahead#Dead end ahead#No passing ahead"},
{"testId":667,"qid":29361,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing a yield sign, hence they must both yield to Car B. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":667,"qid":29362,"description":"If you see this sign at the intersection, What does it mean?","explanation":"At a junction, this sign is usually located underneath a stop sign. It denotes an all-way stop at this junction. That is, on every road leading up to this crossroads, there is a stop sign with an All Way or 4 Way sign underneath it. At an all-way stop, there are regulations that govern who has the right of way. [Tennessee Comprehensive Driver License Manual, Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals]","imageName":"202004142331335982.jpg","correctAnswer":40421,"isBookmarked":0,"aid":"40421#40422#40423#40424","text":"Every roadway approaching this intersection has a stop sign#Every roadway approaching this intersection has a stop or yield sign#You can turn either left or right at a red traffic signal#If you turn here, you will enter a two-way road"},
{"testId":667,"qid":29363,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. (A yellow light in Tennessee may become red in as little as three seconds.) If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Tennessee Comprehensive Driver License Manual, Yellow, Traffic Signals, B-3. Traffic Signs and Signals]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#40085#40086#40767","text":"Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely#Drive through after yielding to traffic"},
{"testId":667,"qid":29364,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, Intersections, B-4. Tennessee Comprehensive Driver License Manual, Rules of the Road]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":667,"qid":29365,"description":"On primary and secondary state and federal highways in Tennessee, the speed limit is _____ unless it says otherwise.","explanation":"Unless otherwise marked, the speed limit on principal and secondary state and federal roads in Tennessee is 55 mph. [Tennessee Speed Laws, Basic Rules of the Road, B-4, Tennessee Comprehensive Driver License Manual]","imageName":"202003151746465721.jpg","correctAnswer":682,"isBookmarked":0,"aid":"682#683#684#685","text":"55 mph#60 mph#65 mph#70 mph"},
{"testId":667,"qid":29366,"description":"Is it permissible to turn left at a green signal?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#35260#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":667,"qid":29367,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a shared turn lane in the middle (also known as a two-way left-turn lane). This lane is for vehicles driving in either direction on the route to make left turns. Vehicles in Tennessee may also make left turns onto the highway in this lane. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing. In Tennessee, you are not permitted to drive more than 300 feet in this lane. [4. Traffic Lanes and Lane Usage, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202001301806403967.jpg","correctAnswer":40769,"isBookmarked":0,"aid":"16863#16865#40769#40770","text":"Passing#Car pooling#Turning left#Reversing traffic flow"},
{"testId":667,"qid":29368,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [B-3. Traffic Signs and Signals, Tennessee Comprehensive Driver License Manual, Flashing Red]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"23528#28148#35029#37531","text":"Stop and yield as you would at a stop sign#Proceed with caution#Yield to all vehicles unless you are on the busier road#Yield as you would at a yield sign"},
{"testId":667,"qid":29369,"description":"You come across a pennant-shaped sign. What exactly does this sign mean?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is placed on the left side of the road and bears the words \"NO PASSING ZONE\" in black characters on a yellow backdrop. [Tennessee Comprehensive Driver License Manual, Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals]","imageName":"202003151738521917.jpg","correctAnswer":1075,"isBookmarked":0,"aid":"1075#9459#14994#16692","text":"No passing#Divided highway begins#Keep right#Work zone ahead"},
{"testId":667,"qid":29370,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Tennessee Comprehensive Driver License Manual, Sign Colors and Shapes, Traffic Signs, B-3. Traffic Signs and Signals]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":667,"qid":29371,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [2. Follow These Rules When Parking on a Hill, Backing and Parking, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":667,"qid":29372,"description":"You notice a section of white curb. Is it legal for you to park here?","explanation":"Colored curb markings may be used in addition to or instead of regulation signs to limit parking. In Tennessee, a yellow curb indicates that you may only stop there to load or unload merchandise or persons. A white curb indicates that you may only stop there to load or unload persons and not freight. You must be with your car when it is being loaded or unloaded. A red curb indicates that you may not stop at all. (Please keep in mind that there is no national standard for curb colors.) Curb colors are used differently in each state, and others do not use them at all. Always verify the local traffic rules while going out of state.) [3. No Parking Zones, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202005121046039920.jpg","correctAnswer":40773,"isBookmarked":0,"aid":"18118#40611#40772#40773","text":"No#Yes, this is a metered parking area#Yes, but only long enough to load or unload cargo or passengers#Yes, but only long enough to pick up or drop off passengers"},
{"testId":667,"qid":29373,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":667,"qid":29375,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles approach at a four-way stop or an uncontrolled junction (one without signs or signals) about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to its right here, giving Car B the right-of-way. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202003241439594485.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#16831","text":"Car A#Car B#Neither car#Whichever car proceeds first"},
{"testId":667,"qid":29376,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture denotes a turn to the right. [A. Turning and Hand Signal Tips, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":667,"qid":29377,"description":"As you come to a railroad crossing, a train approaches. Where must you stop?","explanation":"When approaching a railroad crossing in Tennessee, you must stop between 15 and 50 feet from the closest rail. Never attempt to outrun a train across a crossing. A train cannot give you the right-of-way. [Stops Required by Law, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#34772#40774#40775","text":"Right in front of the tracks#15 to 50 feet from the tracks#25 to 75 feet from the tracks#50 to 100 feet from the tracks"},
{"testId":667,"qid":29378,"description":"When is it legal to drive in a lane with this sign?","explanation":"Vehicles with numerous passengers use high-occupancy vehicle (HOV) lanes. This sign indicates that this lane is an HOV 2+ lane, which needs a driver and at least one passenger in each vehicle. A HOV 3+ lane requires at least three occupants: a driver and two passengers. [6. Traffic Lanes and Lane Usage, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202003051709096729.jpg","correctAnswer":40776,"isBookmarked":0,"aid":"9435#37522#40776#40777","text":"At any time#Never (this lane is for buses and trucks)#If you have at least one passenger#If you have at least two passengers"},
{"testId":667,"qid":29379,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#33169#34196#34928","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":667,"qid":29380,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Tennessee Comprehensive Driver License Manual, B-4. Rules of the Road, Right-of-Way Procedures, Intersections]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":667,"qid":29381,"description":"A white cane-wielding pedestrian steps into the road ahead. What are your options?","explanation":"A pedestrian who is blind or visually challenged is holding a white cane or utilizing a guide dog. Always give such a pedestrian the right-of-way. According to the Tennessee Comprehensive Driver License Manual, you should pull over at least 10 feet away from him or her. Sounding your horn may surprise the pedestrian or cancel the aural indications that the pedestrian depends on to navigate. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202003202129395393.jpg","correctAnswer":40779,"isBookmarked":0,"aid":"28545#30742#40778#40779","text":"Sound your horn to alert the pedestrian to your presence#Drive around the pedestrian#Stop in front of the pedestrian#Stop at least 10 feet from the pedestrian"},
{"testId":667,"qid":29382,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":667,"qid":29383,"description":"Which of the following is true about using headlights?","explanation":"When you can't see clearly or other drivers can't see you, turn on your headlights. Tennessee law requires you to use your headlights from half an hour after nightfall to half an hour before daybreak, and anytime vision at 200 feet is problematic. When using your windshield wipers in bad weather, you must also utilize your headlights. [B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual, Use of Headlights]","imageName":"202003161149128995.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"15516#28163#32325#40780","text":"You must use your headlights from half an hour after sunset to half an hour before sunrise#All of the above statements are true#You must use your headlights whenever you use your windshield wipers in poor weather#You must use your headlights whenever visibility is difficult at 200 feet"},
{"testId":667,"qid":29384,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two automobiles approach from opposing directions at a four-way stop or an uncontrolled junction (i.e., one without signs or signals) at roughly the same time, the car turning left must yield. Car A must yield to Car B in this situation. Each vehicle at a four-way stop must come to a full stop before advancing. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40437#40438","text":"Car A#Car B#The car on the busiest approach to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":667,"qid":29385,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, on a sidewalk, or more than 18 inches from the curb in Tennessee. Parking is not permitted within 15 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) The automobile in Situation D) has parked lawfully 12 inches from the curb. [3. No Parking Zones, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202003241433015085.jpg","correctAnswer":16166,"isBookmarked":0,"aid":"15951#15952#16166#29321","text":"B) On a bridge#C) On the sidewalk#D) 12 inches from the curb#A) 10 feet from a fire hydrant"},
{"testId":667,"qid":29386,"description":"Which of the following steps is not required when changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [7. Traffic Lanes and Lane Usage, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202001302245403150.jpg","correctAnswer":897,"isBookmarked":0,"aid":"897#15460#16513#16514","text":"None of the above#Using your turn signals#Checking your mirrors#Looking over your shoulder"},
{"testId":667,"qid":29387,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Tennessee Comprehensive Driver License Manual, Section C-3 Sharing the Road Safely, Sharing the Road with Large Trucks and Buses]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":667,"qid":29388,"description":"When do high-beam headlights need to be dimmed under Tennessee law?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":40784,"isBookmarked":0,"aid":"40781#40782#40783#40784","text":"Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 300 feet#Whenever you are within 300 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":667,"qid":29389,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#35482#40785","text":"The divided highway ends ahead#A divided highway starts ahead#Left turns are prohibited ahead#A new lane is added ahead"},
{"testId":667,"qid":29390,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Tennessee?","explanation":"The \"two-second rule\" is recommended by the Tennessee Comprehensive Driver License Manual: maintain a following distance of at least two seconds behind the vehicle in front of you. When driving in dangerous situations, such as on a high-speed freeway or at night, you should increase your following distance to four seconds. [Tennessee Comprehensive Driver License Manual, B-4. Rules of the Road, The Two-Second Rule, Braking, Following, and Stopping Distances]","imageName":"202001302249003541.jpg","correctAnswer":10881,"isBookmarked":0,"aid":"7370#10880#10881#33894","text":"40 feet#Three seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":667,"qid":29391,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":667,"qid":29392,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care through the junction if you observe a flashing yellow light. [B-3. Traffic Signs and Signals, Tennessee Comprehensive Driver License Manual, Flashing Yellow]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":667,"qid":29393,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":"A vehicle on a T-terminating intersection's (ending) route must yield to all vehicles on the through (main) road. Car A must yield to Cars B and C in this situation. Car C, on the other hand, must yield to oncoming traffic since it is turning left at a junction. Car C must yield to Car B in this situation. As a result, Car B may proceed first, followed by Car C, and finally by Car A. [Tennessee Comprehensive Driver License Manual, Right-of-Way Procedures, Intersections, B-4. Rules of the Road]","imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":667,"qid":29394,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [A. Turning and Hand Signal Tips, B-4. Rules of the Road, Tennessee Comprehensive Driver License Manual]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":667,"qid":29395,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic lights at a junction fail in Tennessee, the intersection must be treated as an all-way stop (i.e., as if there were a stop sign at each approach to the intersection). That is, you must come to a complete stop before approaching the junction, yield according to stop sign right-of-way standards, and then continue (with great care) when it is safe to do so. [B-3. Traffic Signs and Signals, Tennessee Comprehensive Driver License Manual, Malfunctioning Traffic Light]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#40634#40635#40788","text":"Treat the intersection like an all-way stop#Yield as if the intersection had no traffic signals#Assume you have the right-of-way if you're on the busiest road#Proceed with caution as soon as the way is clear"},
{"testId":669,"qid":29396,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, which is the only sort of sign that is octagonal (eight-sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Regulatory Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":669,"qid":29397,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sign indicates the maximum speed restriction for a segment of road. Slow down below the maximum speed limit under dangerous driving circumstances. [Utah Driver Handbook, Section 7: Rules of the Road], [Speed, Section 6: Getting Started, Utah Driver Handbook]","imageName":"202003151749335643.jpg","correctAnswer":15477,"isBookmarked":0,"aid":"15476#15477#15478#15479","text":"The recommended speed limit is 50 mph#The maximum speed limit is 50 mph#The speed limit at night is 50 mph#The minimum speed limit is 50 mph"},
{"testId":669,"qid":29398,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn or change lanes, you must begin signaling at least two seconds in advance, according to Utah law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. A rapid turn or lane change might also catch pedestrians or other drivers off guard. [Signaling, Utah Driver Handbook, Section 6: Getting Started]","imageName":"202001291433312120.jpg","correctAnswer":41088,"isBookmarked":0,"aid":"29975#31404#41087#41088","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Utah#Yes, you must signal for at least two seconds before you turn"},
{"testId":669,"qid":29399,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":669,"qid":29400,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [Regulatory Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":669,"qid":29401,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":669,"qid":29402,"description":"You are not permitted to park within ______ of a railroad crossing.","explanation":"Parking within 50 feet of the closest rail at a railroad crossing is forbidden in Utah. [Utah Driver Handbook, Section 8: General Driving]","imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":669,"qid":29403,"description":"Which kind of sign is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. In a school zone, always yield to pedestrians and keep an eye out for youngsters on the road. [Warning Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16896#40762","text":"A school zone sign#A work zone sign#A lane control sign#A pedestrian crosswalk sign"},
{"testId":669,"qid":29404,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide anybody the right-of-way. Unfortunately, you may come across cars or pedestrians that do not obey the regulations. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. Always prioritize safety above speed. [Yielding Right-of-Way, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202003151700371291.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35015#35257#40075#40412","text":"The right-of-way is a legal right#You should claim the right-of-way whenever you can#You should always be prepared to yield the right-of-way#You can always proceed if you have the right-of-way"},
{"testId":669,"qid":29405,"description":"Is it legal to turn right at a red light?","explanation":"Turning right on a red light is legal in Utah unless there are signs preventing it. You must first come to a full stop and then yield to pedestrians and traffic. [Traffic Signals and Controls, Section 7: Road Rules, Utah Driver Handbook]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#41089","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Utah"},
{"testId":669,"qid":29406,"description":"Which passengers in a vehicle are required to use safety belts or child restraints in Utah?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#41090#41091","text":"Only the driver#The driver and all passengers#The driver and all passengers under 19#The driver, all front-seat passengers, and all passengers under 19"},
{"testId":669,"qid":29407,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Traffic Signals and Controls, Section 7: Road Rules, Utah Driver Handbook]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#34487#39458#40766","text":"No#Yes, right away#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles"},
{"testId":669,"qid":29408,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"19471#34515#35047#41031","text":"Slow down and proceed with caution#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Drive at 20 mph below the speed limit and prepare to stop"},
{"testId":669,"qid":29409,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [Utah Driver Handbook, Section 8: General Driving]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":669,"qid":29410,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#1075#15946#35258","text":"Slow-moving vehicle#No passing#Dead end#Work zone"},
{"testId":669,"qid":29411,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing a yield sign, hence they must both yield to Car B. [Regulatory Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":669,"qid":29413,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you cannot safely stop, drive through the junction with care. [Traffic Signals and Controls, Section 7: Road Rules, Utah Driver Handbook]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28148#28524#28857#41036","text":"Proceed with caution#Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed after yielding to traffic"},
{"testId":669,"qid":29414,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":669,"qid":29415,"description":"Except as otherwise posted, the maximum speed limit in Utah's commercial and residential areas is:","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"521#522#523#1676","text":"30 mph#25 mph#20 mph#35 mph"},
{"testId":669,"qid":29416,"description":"Is it permissible to turn left at a green signal?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Traffic Signals and Controls, Section 7: Road Rules, Utah Driver Handbook]","imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#35260#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":669,"qid":29417,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a two-way left-turn lane. This lane is for vehicles driving in either direction to make left turns. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing. In Utah, you are not permitted to drive more than 500 feet in this lane. [Other Lane Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202001301806403967.jpg","correctAnswer":40769,"isBookmarked":0,"aid":"16863#16865#40769#40770","text":"Passing#Car pooling#Turning left#Reversing traffic flow"},
{"testId":669,"qid":29418,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Traffic Controls and Signals, Section 7: Rules of the Road, Utah Driver Handbook], [Crosswalks and Stop Lines, Section 7: Rules of the Road, Utah Driver Handbook]","imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"19471#23528#35029#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Yield to all vehicles unless you are on the busier road#Yield as you would at a yield sign"},
{"testId":669,"qid":29419,"description":"What is the speed limit near schools in Utah when there are children present?","explanation":null,"imageName":"202004281807142908.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":669,"qid":29420,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Railroad Crossings, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":669,"qid":29421,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Utah Driver Handbook, Section 8: General Driving, Parking on a Hill]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":669,"qid":29422,"description":"You are not permitted to park within ______ of a crosswalk.","explanation":"You may not park within 20 feet of a crosswalk in Utah. (There are several more sites where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Utah Driver Handbook, Section 8: General Driving]","imageName":"202003241454204243.jpg","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":669,"qid":29423,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":"You must yield to any pedestrians who are legally in a crosswalk or the intersection before turning at a green light. [Traffic Signals, Traffic Controls, Section 7: Rules of the Road, Utah Driver Handbook], [Pedestrians, Section 11: Sharing the Road, Utah Driver Handbook]","imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":669,"qid":29424,"description":"Is it legal to make a left turn at a red light in Utah?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":669,"qid":29425,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two vehicles approach at a four-way stop or an uncontrolled junction (one without signs or signals) about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to its right here, giving Car B the right-of-way. [Yielding Right-of-Way, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202003241439594485.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#16831","text":"Car A#Car B#Neither car#Whichever car proceeds first"},
{"testId":669,"qid":29426,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This motorist wants to make a right turn. [Utah Driver Handbook, Hand Signals for Stops and Turns, Signaling, Section 6: Getting Started]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":669,"qid":29427,"description":"As you come to a railroad crossing, a train approaches. Where must you stop?","explanation":"When approaching a railroad crossing in Utah, you must stop between 15 and 50 feet from the closest rail. Never attempt to outrun a train across a crossing. A train cannot give you the right-of-way. [Stopping, Utah Driver Handbook, Section 6: Getting Started]","imageName":"202003161136554337.jpg","correctAnswer":34772,"isBookmarked":0,"aid":"16907#34772#40774#40775","text":"Right in front of the tracks#15 to 50 feet from the tracks#25 to 75 feet from the tracks#50 to 100 feet from the tracks"},
{"testId":669,"qid":29428,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Warning Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":669,"qid":29429,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If you approach a school bus on a two-lane road with alternating flashing red light signals, you must come to a full stop immediately before approaching the bus. Continue to stand still until the flashing red lights go off. [Utah Driver Handbook, Section 8: General Driving, School Bus Rules]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#34196#34928#40618","text":"Proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 20 mph and proceed with caution"},
{"testId":669,"qid":29430,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":669,"qid":29431,"description":"Who is present in a crosswalk?","explanation":null,"imageName":"202005071748161699.jpg","correctAnswer":16942,"isBookmarked":0,"aid":"16940#16941#16942#16943","text":"Pedestrian A#Pedestrian B#Both pedestrians#Neither pedestrian"},
{"testId":669,"qid":29432,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":669,"qid":29433,"description":"Which of the following is true about railroad crossings?","explanation":"Never attempt to outrun a train to a railroad crossing. A train cannot give you the right-of-way. Railroad crossing lights are installed for your protection. If the lights flash, the bell rings, the gates are lowered, or a train sounds its horn, it denotes that the train is approaching. You must come to a complete stop between 15 and 50 feet away from the closest rail and wait for the signals to cease. The railroad crossing signals may not stop even after the train has passed. This might indicate that a second train is on its way. Even if the signals do not indicate an impending train, they may be faulty and failing to alert you of one. You should still proceed with care and watch in all directions for an incoming train before crossing the tracks. [Utah Driver Handbook, Section 12: Special Driving Challenges, Railroad Crossings]","imageName":"202003161136554337.jpg","correctAnswer":41095,"isBookmarked":0,"aid":"23029#41093#41094#41095","text":"All of the above are true#Even if the railroad crossing signals are flashing, you can cross if you don't see a train approaching#You can drive around lowered crossing gates and cross if you don't see a train approaching#If a train sounds its horn, you should stop at the crossing"},
{"testId":669,"qid":29434,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two automobiles approach from opposing directions at a four-way stop or an uncontrolled junction (i.e., one without signs or signals) at roughly the same time, the car turning left must yield. Car A must yield to Car B in this situation. Each vehicle at a four-way stop must come to a full stop before advancing. [Yielding Right-of-Way, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#16839#40438","text":"Car A#Car B#The car at the busiest entrance to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":669,"qid":29435,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park on a bridge, a sidewalk, or more than 12 inches from the curb in Utah. Parking is not permitted within 15 feet of a fire hydrant. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) The automobile in Situation D) has parked lawfully 10 inches from the curb. [Utah Driver Handbook, Section 8: General Driving]","imageName":"202003241433015085.jpg","correctAnswer":33182,"isBookmarked":0,"aid":"15951#15952#29321#33182","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 10 inches from the curb"},
{"testId":669,"qid":29436,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [Utah Driver Handbook, Section 8: General Driving, Changing Lanes]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":669,"qid":29437,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. A automobile moving behind or to the side of the truck, right behind the truck's cab, may be in a No-Zone. Car A should be visible through the windshield of the truck, and Car C should be visible in the truck's right side mirror. Car B, on the other hand, is in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember, if you can't see the driver in one of the truck's mirrors, chances are the driver can't see you too. [Large Vehicles, Section 11: Sharing the Road, Utah Driver Handbook]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":669,"qid":29438,"description":"When do high-beam headlights need to be dimmed under Utah law?","explanation":"When you are near 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following in Utah, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Utah Driver Handbook, Section 12: Special Driving Challenges, Night Driving]","imageName":"202002211503506612.jpg","correctAnswer":40781,"isBookmarked":0,"aid":"40781#40782#40783#40784","text":"Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 300 feet#Whenever you are within 300 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":669,"qid":29439,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Warning Signs, Traffic Controls, Utah Driver Handbook, Section 7: Rules of the Road]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#40785#41158","text":"The divided highway ends ahead#A divided highway starts ahead#A new lane is added ahead#There is a chicane ahead"},
{"testId":669,"qid":29440,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Utah?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":10881,"isBookmarked":0,"aid":"7370#10881#10882#33894","text":"40 feet#Two seconds#One second#One car length for every 10 mph of speed"},
{"testId":669,"qid":29441,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":669,"qid":29442,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care through the junction if you observe a flashing yellow light. [Traffic Signals and Controls, Section 7: Road Rules, Utah Driver Handbook]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":669,"qid":29443,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":null,"imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":669,"qid":29444,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Utah Driver Handbook, Hand Signals for Stops and Turns, Signaling, Section 6: Getting Started]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":669,"qid":29445,"description":"You approach at an intersection with no working traffic lights; none of the lights are illuminated. What should you do?","explanation":"In Utah, a broken traffic signal must be treated as a stop sign. That is, you must stop, yield to pedestrians and other cars, and advance only when it is safe to do so (with great care). If none of the junction's lights are functional, regard the intersection as an all-way stop. [Utah Driver Handbook, Section 7: Rules of the Road, Special Situations at Traffic Lights, Traffic Signals, and Traffic Controls]","imageName":"202003051805205845.jpg","correctAnswer":38874,"isBookmarked":0,"aid":"38874#40634#40635#40788","text":"Stop and yield as if you were at a stop sign#Yield as if the intersection had no traffic signals#Assume you have the right-of-way if you're on the busiest road#Proceed with caution as soon as the way is clear"},
{"testId":671,"qid":29446,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, the only octagonal form of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or intersecting highway, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Octagon (Stop); Traffic Signs; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":671,"qid":29447,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sort of sign indicates the maximum speed restriction (50 mph in this example) for a segment of highway. Slow down below the maximum speed limit under dangerous driving circumstances. [Speed Limit; Regulatory or Guide Signs; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"15476#35244#35246#41292","text":"The recommended speed limit is 50 mph#You must not exceed 50 mph here#You must always drive at 50 mph here#You must drive slower than 50 mph here"},
{"testId":671,"qid":29448,"description":"At 40 mph, you're nearing an intersection. You intend to turn at the intersection but cannot see any other vehicles. Is it necessary to signal the turn?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#41293","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Virginia"},
{"testId":671,"qid":29449,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Virginia Driver's Manual, Section 3: Safe Driving, Yielding the Right-of-Way]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":671,"qid":29450,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [Triangle (Yield); Traffic Signs; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":671,"qid":29451,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"If an emergency vehicle with flashing lights or a siren approaches from either direction, you must cede the right-of-way, pull over to the right, and allow the emergency vehicle to pass safely, according to Virginia law. (The only exception is when the emergency vehicle is on the other side of a split roadway.) You are not required to pull over in this scenario.) However, if you're in the midst of an intersection and an emergency vehicle arrives, don't stop. Instead, go through the junction and immediately pull over. When an emergency vehicle's lights are flashing, never go closer than 500 feet. [From the Virginia Driver's Manual, Section 3: Safe Driving, Yielding to Vehicles with Flashing Lights]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":671,"qid":29452,"description":"You are not permitted to park within ______ of a railroad crossing.","explanation":"At a railroad crossing in Virginia, you may not park within 50 feet of the closest rail. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Virginia Driver's Manual, Section 3: Safe Driving]","imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":671,"qid":29453,"description":"Which kind of sign is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. In a school zone, always yield to pedestrians and keep an eye out for youngsters on the road. [School Zone/School Crossing Pentagon; Traffic Signs; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16896#40762","text":"A school zone sign#A work zone sign#A lane control sign#A pedestrian crosswalk sign"},
{"testId":671,"qid":29454,"description":"You notice that white triangles have been painted across your lane. What does this sign on the sidewalk mean?","explanation":"A yield line is a line of white triangles that runs across a lane. You must yield to pedestrians and other vehicles at this pavement marker. Roundabouts and mid-block crosswalks are common locations for yield lines. [Yield Line; Pavement Markings; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"1374830164_t1q9.png","correctAnswer":41295,"isBookmarked":0,"aid":"19471#41294#41295#41296","text":"Slow down and proceed with caution#Do not pass other vehicles here#Yield here#Stop here and then yield"},
{"testId":671,"qid":29455,"description":"Is it legal to turn right at a red light?","explanation":"In Virginia, unless a \"No Turn on Red\" sign is present, you may turn right on a continuous red light. However, before turning, you must come to a full stop and yield to pedestrians and cars. On a red arrow signal, you may not turn right. [Right Turn on Red; Traffic Signals; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#41297","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Virginia"},
{"testId":671,"qid":29456,"description":"Which occupants of a motor vehicle are required by Virginia law to wear safety belts or proper child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":34769,"isBookmarked":0,"aid":"16319#16365#34769#35254","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#The driver and all passengers under 18"},
{"testId":671,"qid":29457,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Green Light or Arrow; Traffic Signals; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#25289#39458#41298","text":"No#Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a \"Left Turn Allowed\" sign posted, and you must first yield to pedestrians and oncoming vehicles"},
{"testId":671,"qid":29458,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"28148#30005#35047#39336","text":"Proceed with caution#Slow down and prepare to stop#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":671,"qid":29459,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane or right from the right lane and into the next lane of traffic travelling in the proper direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [To Turn Left, Section 3: Safe Driving, Virginia Driver's Manual]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":671,"qid":29460,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":2198,"isBookmarked":0,"aid":"2198#16692#16834#16857","text":"Slow-moving vehicle ahead#Work zone ahead#Dead end ahead#No passing ahead"},
{"testId":671,"qid":29461,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":671,"qid":29462,"description":"A white triangle is painted on the road front of you. What is the significance of this pavement marking?","explanation":"A yield sign ahead is indicated by a white triangle pointing toward oncoming vehicles. When you notice this pavement marker, begin to slow down so that you may safely yield at the yield sign. [Federal Highway Administration; May 2012; Section 3B.20: Pavement Word, Symbol, and Arrow Markings; Manual on Uniform Traffic Control Devices (MUTCD)]","imageName":"1374830164_t1q17.png","correctAnswer":648,"isBookmarked":0,"aid":"648#34377#41299#41300","text":"Yield sign ahead#Wrong way#High-occupancy vehicle (HOV) lane#Bus lane"},
{"testId":671,"qid":29463,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Traffic Signals; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#40085#40086#40767","text":"Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely#Drive through after yielding to traffic"},
{"testId":671,"qid":29464,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":671,"qid":29465,"description":"Unless otherwise indicated, the maximum speed limit in a residential area in Virginia is :","explanation":"Unless otherwise marked, the maximum speed restriction in a commercial or residential area in Virginia is 25 mph. When children are on their way to or from school, or when school is in session, the maximum speed restriction in a school zone is likewise 25 mph. [Virginia Driver's Manual, Section 3: Safe Driving, Speed Limits]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"521#522#523#1676","text":"30 mph#25 mph#20 mph#35 mph"},
{"testId":671,"qid":29466,"description":"Is it permissible to turn left at a green signal?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#38661#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming vehicles before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":671,"qid":29467,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a left-turn lane with two lanes (also known as a shared center turning lane). This lane is for vehicles driving in either direction to make left turns. Use care in this lane since cars heading in the other way may be utilizing it as well. This lane should never be used for passing. In Virginia, you are not permitted to drive more than 150 feet in this lane. [Pavement Markings; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202001301806403967.jpg","correctAnswer":40769,"isBookmarked":0,"aid":"16863#16865#40769#40770","text":"Passing#Car pooling#Turning left#Reversing traffic flow"},
{"testId":671,"qid":29468,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then advance only when it is safe to do so. You must also stop and surrender when you see a flashing red arrow pointing in the direction you want to go. [Traffic Signals; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003051722347868.jpg","correctAnswer":41301,"isBookmarked":0,"aid":"19471#30736#35029#41301","text":"Slow down and proceed with caution#Yield to traffic and pedestrians#Yield to all vehicles unless you are on the busier road#Come to a complete stop and yield to traffic and pedestrians"},
{"testId":671,"qid":29469,"description":"When is it legal to drive in a lane with this sign?","explanation":"Buses, vanpools, carpools, other high-occupancy vehicles, motorcyclists, and some clean fuel vehicles are permitted to utilize high-occupancy vehicle (HOV) lanes. This sign indicates that this lane is an HOV 2+ lane, which needs a driver and at least one passenger in each vehicle. (A HOV 3+ lane requires at least three occupants: a driver and two passengers.) [Pavement Markings; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"1374830164_t1q24.png","correctAnswer":40776,"isBookmarked":0,"aid":"9435#37522#40776#40777","text":"At any time#Never (this lane is for buses and trucks)#If you have at least one passenger#If you have at least two passengers"},
{"testId":671,"qid":29470,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Railroad Overpass; Warning Signs; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":671,"qid":29471,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Virginia Driver's Manual, Section 3: Safe Driving, Parking on a Hill]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":671,"qid":29472,"description":"You are not permitted to park within ______ of an intersection.","explanation":"You may not park within 20 feet of an intersection in Virginia. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Virginia Driver's Manual, Section 3: Safe Driving]","imageName":"202002181141488961.jpg","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":671,"qid":29473,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":671,"qid":29474,"description":"Is it legal in Virginia to turn left at a red light?","explanation":"When going from one one-way street to another one-way street in Virginia, you may turn left on a red light unless there is a \"No Turn on Red\" sign displayed. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Check for less apparent vehicles including motorcycles, bicycles, and mopeds. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Left Turn on Red; Traffic Signals; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":671,"qid":29475,"description":"You are not permitted to park within ______ of a fire hydrant.","explanation":"You may not park within 15 feet of a fire hydrant in Virginia. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [Virginia Driver's Manual, Section 3: Safe Driving]","imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#4582#8416#35270","text":"10 feet#20 feet#15 feet#6 feet"},
{"testId":671,"qid":29476,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture denotes a turn to the right. [Virginia Driver's Manual, Section 3: Safe Driving, Turn Signals]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":671,"qid":29477,"description":"Is it permissible to turn left at a flashing yellow arrow signal?","explanation":"Turn left when you see a flashing yellow arrow. You will not, however, have the right of way. Before turning, exercise care and yield to pedestrians and approaching traffic. [Traffic Signals; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003241444031322.jpg","correctAnswer":38661,"isBookmarked":0,"aid":"18118#34490#38661#41302","text":"No#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming vehicles before you turn#Yes, but only if you cannot stop safely before the intersection"},
{"testId":671,"qid":29478,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Hill; Warning Signs; Virginia Driver's Manual; Section 2: Signals, Signs, and Pavement Markings]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":671,"qid":29479,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If you see a school bus stopped with its red lights flashing on an undivided road in Virginia, you must stop before approaching the bus, whether it is on your side or the opposing side. You must stay stopped until all pedestrians have exited the highway and the school bus has resumed normal operation. [Virginia Driver's Manual, Section 3: Safe Driving, Stopping for School Buses]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#33169#34196#34928","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":671,"qid":29480,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"At a junction that isn't regulated by signs or signals, such as an all-way stop, you have to stop and then give way to all approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Virginia Driver's Manual, Yielding the Right-of-Way, Section 3: Safe Driving]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":671,"qid":29481,"description":"When do you need to use your headlights?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"14655#22962#39823#41303","text":"Between sunset and sunrise#In all of the above situations#Whenever you use your windshield wipers in bad weather#Whenever visibility is difficult at 500 feet"},
{"testId":671,"qid":29482,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":671,"qid":29483,"description":"Which of the following is true about railroad crossings?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":41304,"isBookmarked":0,"aid":"23029#41093#41094#41304","text":"All of the above are true#Even if the railroad crossing signals are flashing, you can cross if you don't see a train approaching#You can drive around lowered crossing gates and cross if you don't see a train approaching#School buses must stop at every crossing even if no trains are approaching"},
{"testId":671,"qid":29484,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40438#41305","text":"Car A#Car B#Neither car (i.e., one driver must let the other driver through)#The car at the busiest approach to the intersection"},
{"testId":671,"qid":29485,"description":"At a crossroads, a school bus has stopped for passengers. Which cars are required to stop?","explanation":null,"imageName":"202005121050179358.jpg","correctAnswer":41307,"isBookmarked":0,"aid":"324#25317#41306#41307","text":"C#A and B#B and C#A, B, and C"},
{"testId":671,"qid":29486,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [From the Virginia Driver's Manual, Section 3: Safe Driving]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":671,"qid":29487,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [Trucks, tractor-trailers, buses, and recreational vehicles; Road Sharing; Section 3: Safe Driving; Virginia Driver's Manual]","imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":671,"qid":29488,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"If the traffic lights at a junction fail in Virginia, the intersection must be treated as an all-way stop. That is, you must come to a complete stop before approaching the junction, yield according to stop sign right-of-way standards, and then continue (with great care) when it is safe to do so. [Traffic Signals; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#40635#41308#41309","text":"Treat the intersection like an all-way stop#Assume you have the right-of-way if you're on the busiest road#Yield as if you were facing a yield sign#Treat the intersection as if there were no traffic signals"},
{"testId":671,"qid":29489,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Beginning of Divisional Highway; Warning Signs; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#40785#41158","text":"The divided highway ends ahead#A divided highway starts ahead#A new lane is added ahead#There is a chicane ahead"},
{"testId":671,"qid":29490,"description":"You want to proceed straight through this roundabout. What lane should you take?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Before entering or exiting a roundabout or changing lanes inside one, always look for other cars. [Virginia Driver's Manual, Section 3: Safe Driving, Roundabouts]","imageName":"202004281857224780.jpg","correctAnswer":41313,"isBookmarked":0,"aid":"41310#41311#41312#41313","text":"You cannot go straight here#Use the left lane#Use the right lane#Use either the left or right lane"},
{"testId":671,"qid":29491,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":671,"qid":29492,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care through the junction if you observe a flashing yellow light. [Traffic Signals; Section 2: Signals, Signs, and Pavement Markings; Virginia Driver's Manual]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":671,"qid":29493,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":null,"imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":671,"qid":29494,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Virginia Driver's Manual, Section 3: Safe Driving, Turn Signals]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":671,"qid":29495,"description":"When do high-beam headlights need to be dimmed under Virginia law?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"40101#40102#40783#40784","text":"Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":672,"qid":29496,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, the only octagonal form of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or intersecting highway, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Stop Signs, Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":672,"qid":29497,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sort of sign indicates the maximum speed restriction (50 mph in this example) for a segment of highway. Slow down below the maximum speed limit under dangerous driving circumstances. [Speed Limit Signs, Traffic Signs, Driving Rules, Washington Driver Guide]","imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"35243#35244#35246#41446","text":"The recommended speed limit is 50 mph here#You must not exceed 50 mph here#You must always drive at 50 mph here#You must drive at 50 mph or faster here"},
{"testId":672,"qid":29498,"description":"You are about to turn at a junction where you cannot see any other vehicles. Is it still necessary to signal?","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn or change lanes, you must begin signaling at least 100 feet in advance, according to Washington State law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. A rapid turn or lane change might also catch pedestrians or other drivers off guard. [How to Change Direction, Let Others Know What You're Doing, Safe Driving Tips, Washington Driver Guide]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#41447","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Washington State"},
{"testId":672,"qid":29499,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, Road Rules, Washington Driver Guide]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":672,"qid":29500,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When approaching a yield sign, slow down and yield to all pedestrians and vehicles before proceeding. You may need to stop for them as well, so be prepared. [Yield Signs, Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":672,"qid":29501,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"Under Washington State law, if an emergency vehicle with flashing lights or sounding a siren is approaching, you must yield the right-of-way, pull over to the right, and remain stopped until the emergency vehicle has passed. However, if you're in the midst of an intersection and an emergency vehicle arrives, don't stop. Instead, go through the junction and immediately pull over. [Right-of-Way, Rules of the Road, Washington Driver Guide]","imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":672,"qid":29502,"description":"In the state of Washington, it is illegal to park within ______ of a railroad crossing.","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":672,"qid":29503,"description":"Which kind of sign is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. In a school zone, always yield to pedestrians and keep an eye out for youngsters on the road. [Warning Signs, Traffic Signs, Traffic Rules, Washington Driver Guide]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16896#40762","text":"A school zone sign#A work zone sign#A lane control sign#A pedestrian crosswalk sign"},
{"testId":672,"qid":29504,"description":"Which of the following claims about the speed limits in Washington State is correct?","explanation":"Speed limitations must always be followed. You may not travel faster than the specified maximum speed restriction or slower than the official minimum speed limit unless dangerous driving circumstances require it. You should also endeavor to keep up with the legitimate traffic flow. Driving considerably faster or slower than other traffic is dangerous. [Adjusting to Traffic, Safe Driving Tips, Washington Driver Guide]; [Speed Limit Signs, Traffic Signs, Rules of the Road, Washington Driver Guide]; [Adjusting to Traffic, Safe Driving Tips, Washington Driver Guide]; [Adjusting to Traffic,","imageName":"202003151746465721.jpg","correctAnswer":40416,"isBookmarked":0,"aid":"23029#40416#41449#43704","text":"All of the above are true#If you cannot meet a road's minimum speed limit, find another route#It is safer to drive slower than the other traffic#You must follow the posted speed limit at all times"},
{"testId":672,"qid":29505,"description":"Is it legal to turn right at a red light?","explanation":"In Washington, you may turn right on a continuous red light unless a \"No Turn on Red\" sign is present. However, before turning, you must come to a full stop and yield to pedestrians and cars. Unlike in most other jurisdictions, the same laws apply to a red arrow signal pointing right in Washington State. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#41450","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Washington State"},
{"testId":672,"qid":29506,"description":"Which occupants of a passenger vehicle are required by Washington State law to use seat belts or suitable child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#34769#37513","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#The driver and passengers under 18"},
{"testId":672,"qid":29507,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#39458#40766#41030","text":"No#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles#Yes, you have the right-of-way"},
{"testId":672,"qid":29508,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"19471#30005#35047#39336","text":"Slow down and proceed with caution#Slow down and prepare to stop#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":672,"qid":29509,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane or right from the right lane and into the next lane of traffic travelling in the proper direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [Turning, General Rules, Road Rules, Washington Driver Guide]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":672,"qid":29510,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":2198,"isBookmarked":0,"aid":"2198#16692#16834#16857","text":"Slow-moving vehicle ahead#Work zone ahead#Dead end ahead#No passing ahead"},
{"testId":672,"qid":29511,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing a yield sign, hence they must both yield to Car B. [Yield Signs, Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":672,"qid":29512,"description":"Unless otherwise posted, the speed limit throughout Washington State's cities and municipalities is:","explanation":"Except as otherwise marked, the speed limit in Washington State's cities and municipalities is 25 mph. [Speed Limits and Speeding, Safe Driving Tips, Washington Driver Guide]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":672,"qid":29513,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#40085#40086#40767","text":"Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely#Drive through after yielding to traffic"},
{"testId":672,"qid":29514,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When turning left at a junction, you must first yield to approaching traffic and crossing pedestrians. Only advance if you can safely complete your turn without generating a danger. Car A must yield to Car B in this situation. [Right-of-Way, Road Rules, Washington Driver Guide]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":672,"qid":29515,"description":"What is the speed limit in a school zone in Washington State?","explanation":null,"imageName":"1375082770_t1q20.png","correctAnswer":523,"isBookmarked":0,"aid":"521#522#523#1676","text":"30 mph#25 mph#20 mph#35 mph"},
{"testId":672,"qid":29516,"description":"Is it permissible to turn left at a green signal?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#38661#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming vehicles before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":672,"qid":29517,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"A two-way left-turn lane (also known as a shared center turn lane) is designated for making left turns (and, where authorized, U-turns) and may be utilized by cars driving in either direction. (Vehicles may also turn left into this lane from another route in Washington State.) A solid yellow line and a dashed yellow line designate the lane on either side. This lane should never be utilized for passing or navigating through traffic. (In Washington State, you are not permitted to drive more than 300 feet in this lane.) Use care in this lane since cars heading in the other way may be utilizing it as well. [Two-Way Left Turn Lanes, Road Rules, Washington Driver Guide],","imageName":"202001301806403967.jpg","correctAnswer":41452,"isBookmarked":0,"aid":"41451#41452#41453#41454","text":"(a) Passing#(b) Turning left#(c) Car pooling and public transportation#(a) and (b)"},
{"testId":672,"qid":29518,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then advance only when it is safe to do so. The same restrictions apply in Washington State to a red arrow pointing in your desired direction of travel. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003051722347868.jpg","correctAnswer":41301,"isBookmarked":0,"aid":"19471#30736#35029#41301","text":"Slow down and proceed with caution#Yield to traffic and pedestrians#Yield to all vehicles unless you are on the busier road#Come to a complete stop and yield to traffic and pedestrians"},
{"testId":672,"qid":29519,"description":"When is it legal to drive in a lane with this sign?","explanation":"The high-occupancy vehicle (HOV) lanes in Washington State are allocated for buses, vanpools, carpools, and motorcyclists. This sign indicates that this lane is an HOV 2+ lane, which implies that each vehicle (other than a motorbike) must have at least two occupants: a driver and at least one passenger. (At least three persons are required in an HOV 3+ lane: a driver and at least two passengers.) Motorcycles are always permitted in HOV lanes, even if they are not carrying people. [Reserved Lanes, Traffic Rules, Washington Driver Guide]","imageName":"1374830164_t1q24.png","correctAnswer":40776,"isBookmarked":0,"aid":"9435#40776#40777#41455","text":"At any time#If you have at least one passenger#If you have at least two passengers#Never (passenger cars are prohibited)"},
{"testId":672,"qid":29520,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Railroad Crossing Warning Signs, Traffic Signs, Road Rules, Washington Driver Handbook]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":672,"qid":29521,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking on a Hill, Road Rules, Washington Driver Guide]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":672,"qid":29523,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":672,"qid":29524,"description":"Is it legal in Washington State to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38668,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":672,"qid":29525,"description":"In the state of Washington, it is illegal to park within ______ of a fire hydrant.","explanation":"You may not park within 15 feet of a fire hydrant in Washington State. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [No Parking Zones, Parking, Traffic Rules, Washington Driver Guide]","imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#4582#8416#35270","text":"10 feet#20 feet#15 feet#6 feet"},
{"testId":672,"qid":29526,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture denotes a turn to the right. [How to Change Direction, Let Others Know What You're Doing, Safe Driving Tips, Washington Driver Guide]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":672,"qid":29527,"description":"Is it permissible to turn left at a flashing yellow arrow signal?","explanation":"You have the right-of-way if you turn left at a flashing yellow arrow signal. Before turning, exercise care and yield to pedestrians and approaching traffic. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003241444031322.jpg","correctAnswer":38661,"isBookmarked":0,"aid":"18118#34490#38661#41302","text":"No#Yes, this is a \"protected\" turn; oncoming traffic is stopped#Yes, but you must yield to oncoming vehicles before you turn#Yes, but only if you cannot stop safely before the intersection"},
{"testId":672,"qid":29528,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":672,"qid":29529,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#33169#34196#34928","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":672,"qid":29530,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Right-of-Way, Driving in Washington, Rules of the Road]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":672,"qid":29531,"description":"When should you switch on your headlights?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"16838#22962#35275#41456","text":"From half an hour after sunset to half an hour before sunrise#In all of the above situations#Whenever visibility is difficult at 1,000 feet#Whenever you're driving away from a setting sun"},
{"testId":672,"qid":29532,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":672,"qid":29533,"description":"Which of the following is true about railroad crossings?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":41457,"isBookmarked":0,"aid":"23029#41093#41094#41457","text":"All of the above are true#Even if the railroad crossing signals are flashing, you can cross if you don't see a train approaching#You can drive around lowered crossing gates and cross if you don't see a train approaching#When you have to stop at a railroad crossing, you must stop between 15 and 50 feet from the nearest rail"},
{"testId":672,"qid":29534,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"A automobile turning left must yield to oncoming traffic at a four-way stop or uncontrolled junction (i.e., one without signs or signals). At this point, Car A must let Car B go. Each car at a four-way stop must stop completely before moving forward. [Right-of-Way, Driving in Washington, Rules of the Road]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40438#41305","text":"Car A#Car B#Neither car (i.e., one driver must let the other driver through)#The car at the busiest approach to the intersection"},
{"testId":672,"qid":29535,"description":"At a crossroads, a school bus has stopped for passengers. Which cars are required to stop?","explanation":null,"imageName":"202005121050179358.jpg","correctAnswer":41307,"isBookmarked":0,"aid":"324#25317#41307#41458","text":"C#A and B#A, B, and C#A and C"},
{"testId":672,"qid":29536,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [Changing Lanes, Scanning, Driving Safety Tips, Washington Driver Guide]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":672,"qid":29537,"description":"Which of the following is a safe following distance behind the car in front of you?","explanation":"At speeds greater than 30 mph, the Washington Driver Guide suggests using the four-second rule to maintain a safe following distance behind the vehicle ahead of you. Allow at least four seconds of following space behind the car in front of you. (A three-second following distance may be acceptable at speeds of 30 mph or less.) Increase your following distance even further under risky driving circumstances. [Space Ahead, Driving Safety Tips, Washington Driver Guide]","imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10882#33894#41459","text":"Four seconds#One second#One car length for every 10 mph of speed#Far enough to clearly see the taillights of the vehicle ahead of you"},
{"testId":672,"qid":29538,"description":"You come to an intersection where none of the lights is on because none of the traffic lights is working. What should you do?","explanation":"Though a traffic signal fails in Washington State, come to a full stop, yield to pedestrians and traffic as if at an all-way stop, and then continue (with great care) when it is safe to do so. [Traffic Control Devices, Road Rules, Washington Driver Guide]","imageName":"202003051805205845.jpg","correctAnswer":25300,"isBookmarked":0,"aid":"25300#35032#37531#41460","text":"Treat the intersection like an all-way stop#Treat the intersection like an uncontrolled intersection (i.e., one without signs or signals)#Yield as you would at a yield sign#Assume you have the right-of-way if you're on the busiest road; otherwise yield"},
{"testId":672,"qid":29539,"description":"What is the meaning of this sign?","explanation":"This sign cautions that the road ahead will be divided into a divided highway. Opposing lanes will be separated by a physical barrier or median. Continue straight. [Warning Signs, Traffic Signs, Traffic Rules, Washington Driver Guide]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#41158#41461","text":"The divided highway ends ahead#A divided highway starts ahead#There is a chicane ahead#A reversible lane starts ahead"},
{"testId":672,"qid":29541,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":"On a two-lane road, you must stop for a stopped school bus whose red lights are flashing, no matter which direction it is traveling in. In Washington State, however, you don't have to stop if the school bus is stopped on the opposite side of a divided highway. (The separator must be more than simply pavement lines; it must be a physical barrier.) You also don't have to stop if the school bus is stopped on the opposite side of a roadway with at least three lanes. You should, however, proceed with care. Children are unlikely to understand the laws of the road, therefore they may do something unexpected. [Right-of-Way, Rules of the Road, Washington Driver Guide]","imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":672,"qid":29542,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care through the junction if you observe a flashing yellow light. [Traffic Signs, Road Rules, Washington Driver Guide]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":672,"qid":29543,"description":"Which vehicle must yield?","explanation":"When two cars approach a four-way stop or an uncontrolled junction (one without signs or signals) at about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to Car B in this situation. Both cars must come to a full stop at a four-way stop before proceeding. [Right-of-Way, Road Rules, Washington Driver Guide]","imageName":"202003241439594485.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#17019#41463","text":"Car A#Car B#Whichever car is on the busier road#Neither car (i.e., one car must let the other car through)"},
{"testId":672,"qid":29544,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [How to Change Direction, Let Others Know What You're Doing, Safe Driving Tips, Washington Driver Guide]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":672,"qid":29545,"description":"When do high-beam headlights need to be dimmed under Washington State law?","explanation":"Under Washington State law, you must dim your headlights to low beam whenever you're within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following. Avoid shining your high beams on any other occupied vehicle. If you dim your high beams before they can shine on other vehicles, those other drivers may extend you the same courtesy. [Use Your Lights, Safe Driving Tips, Washington Driver Guide]","imageName":"202002211503506612.jpg","correctAnswer":40781,"isBookmarked":0,"aid":"40781#40782#40783#40784","text":"Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 300 feet#Whenever you are within 300 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":673,"qid":29546,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, the only octagonal form of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Octagon, Single Message Shaped Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":673,"qid":29547,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sort of sign indicates the maximum speed restriction (50 mph in this example) for a segment of highway. Slow down below the maximum speed limit under dangerous driving circumstances. [State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices, Speed Limit, Square & Rectangular Signs]","imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"35243#35244#35245#35246","text":"The recommended speed limit is 50 mph here#You must not exceed 50 mph here#You must not drive slower than 50 mph here#You must always drive at 50 mph here"},
{"testId":673,"qid":29548,"description":"You intend to turn at a junction and cannot see any other vehicles in the area. Is it still necessary to signal?","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn or change lanes, you must begin signaling at least 100 feet in advance, according to West Virginia law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. A rapid turn or lane change might also catch pedestrians or other drivers off guard. [Driving Signals, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#41577","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in West Virginia"},
{"testId":673,"qid":29549,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Right-of-Way, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":673,"qid":29550,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. Before proceeding, you must slow down and yield to all pedestrians and oncoming vehicles when you come to a yield sign. You may need to stop for them as well, so be prepared. [Triangle (Upside Down), Single Message Shaped Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":673,"qid":29551,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"If an emergency vehicle with flashing lights and a siren approaches from either direction, you must exit the intersection, yield the right-of-way, pull over to the right, and allow the emergency vehicle to pass safely. Keep your foot on the brake so the driver of the emergency vehicle can see that you have stopped. Check to see if any additional emergency vehicles are approaching before proceeding. [Right-of-Way, Chapter VI: Traffic Laws and Rules of the Road, State of West Virginia Driver's Licensing Handbook], [Operation of Vehicles and Streetcars on Approach of Authorized Emergency Vehicles, Section 17C-9-5, West Virginia Code]","imageName":"202001302235285631.jpg","correctAnswer":23572,"isBookmarked":0,"aid":"15493#23572#35249#38647","text":"Slow down#Pull over to the right and stop#Speed up and turn off the road as soon as possible#Maintain your speed and direction"},
{"testId":673,"qid":29552,"description":"You are not permitted to park within ______ of a railroad crossing.","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1591#8416#8417","text":"50 feet#5 feet#15 feet#30 feet"},
{"testId":673,"qid":29553,"description":"Which kind of sign is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. Expect to slow down and give way to pedestrians. [Pentagon, Single Message Shaped Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16896#40762","text":"A school zone sign#A work zone sign#A lane control sign#A pedestrian crosswalk sign"},
{"testId":673,"qid":29554,"description":"Which of the following assertions concerning the speed limits in West Virginia is correct?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":41578,"isBookmarked":0,"aid":"23029#35057#41448#41578","text":"All of the above are true#The posted minimum speed limit is not enforceable#On a two-lane road, you may exceed the speed limit to pass another vehicle safely#You can be cited for driving at the maximum speed limit"},
{"testId":673,"qid":29555,"description":"Is it legal to turn right at a red light?","explanation":"In West Virginia, unless a \"No Turn on Red\" sign is displayed, you may turn right on a continuous red light. However, before turning, you must come to a full stop and yield to pedestrians and vehicles. [Stay Circular Red / STOP, Traffic Lights, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#41579#41580","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only when permitted by a sign#No, not in West Virginia"},
{"testId":673,"qid":29556,"description":"Which occupants of a motor vehicle are required by West Virginia law to use safety belts or child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":35237,"isBookmarked":0,"aid":"16365#26667#35237#37513","text":"The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and passengers under 18#The driver and passengers under 18"},
{"testId":673,"qid":29557,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Circular Green / GO, Traffic Lights, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#25289#39458#41298","text":"No#Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a \"Left Turn Allowed\" sign posted, and you must first yield to pedestrians and oncoming vehicles"},
{"testId":673,"qid":29558,"description":"Which of the following about the right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide anybody the right-of-way. You may come across cars or pedestrians who are breaking the rules. In such instances, you should give the right-of-way rather than risk a collision. You must always do everything possible to prevent an accident. [Right-of-Way, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202007120454477868.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"23029#35256#35257#40075","text":"All of the above are true#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever you can#You should always be prepared to yield the right-of-way"},
{"testId":673,"qid":29559,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane or right from the right lane and into the next lane of traffic travelling in the proper direction. Car A has made the proper turn into the nearest lane. Car B has made an erroneous turn into the furthest lane. [Rules for Turns, Turning Maneuvers, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":673,"qid":29560,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":2198,"isBookmarked":0,"aid":"2198#16692#16834#16857","text":"Slow-moving vehicle ahead#Work zone ahead#Dead end ahead#No passing ahead"},
{"testId":673,"qid":29561,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing a yield sign, hence they must both yield to Car B. [Right-of-Way, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":673,"qid":29562,"description":"Unless otherwise indicated, the maximum speed limit in a business or residential district in West Virginia is:","explanation":"Unless otherwise marked, the maximum speed restriction in a commercial or residential area in West Virginia is 25 mph. [Speed Limits, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":673,"qid":29563,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Traffic Lights, Chapter V: Traffic Control Devices, State of West Virginia Driver's Licensing Handbook]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#40085#40086#40767","text":"Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely#Drive through after yielding to traffic"},
{"testId":673,"qid":29564,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":673,"qid":29565,"description":"What is the speed limit in a school zone in West Virginia when children are present?","explanation":"When there are children present, the speed restriction in a school zone in West Virginia is 15 mph. A school zone in West Virginia is defined as any route that runs for 125 feet in each direction next to a school. [Speed Limits, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202003151740461521.jpg","correctAnswer":520,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":673,"qid":29566,"description":"Is it permissible to turn left at a green signal?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Green Arrow / LEFT TURN, Traffic Lights, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#38661#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming vehicles before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":673,"qid":29567,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a left-turn lane in the middle. Vehicles moving in either direction may use this lane to turn left. You must not use this lane for any other reason. [State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices, Center Left-Turn Lanes]","imageName":"202001301806403967.jpg","correctAnswer":41581,"isBookmarked":0,"aid":"41451#41454#41581#41582","text":"(a) Passing#(a) and (b)#(b) Left turns#(c) Car pooling"},
{"testId":673,"qid":29568,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously only when it is safe to do so. [Intersection Flashers, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003051722347868.jpg","correctAnswer":41301,"isBookmarked":0,"aid":"19471#30736#35029#41301","text":"Slow down and proceed with caution#Yield to traffic and pedestrians#Yield to all vehicles unless you are on the busier road#Come to a complete stop and yield to traffic and pedestrians"},
{"testId":673,"qid":29569,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A persistent yellow \"X\" above your lane indicates that traffic in your way is soon to be diverted elsewhere. The indicator will then shift to a constant red \"X.\" Before that occurs, you must change lanes. [Lane Use Control Signals, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003051705034327.jpg","correctAnswer":39691,"isBookmarked":0,"aid":"28148#34510#39691#41583","text":"Proceed with caution#This lane is only for making turns#Change lanes as soon as it is safe to do so#This lane is closed in your direction"},
{"testId":673,"qid":29570,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Round, Single Message Shaped Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":673,"qid":29571,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front tire rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking on Hills, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":673,"qid":29572,"description":"Who is present in a crosswalk?","explanation":null,"imageName":"202005071748161699.jpg","correctAnswer":16942,"isBookmarked":0,"aid":"16940#16941#16942#16943","text":"Pedestrian A#Pedestrian B#Both pedestrians#Neither pedestrian"},
{"testId":673,"qid":29573,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":673,"qid":29575,"description":"You are not permitted to park within ______ of a fire hydrant.","explanation":null,"imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#4582#8416#35270","text":"10 feet#20 feet#15 feet#6 feet"},
{"testId":673,"qid":29576,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture denotes a turn to the right. [Driving Signals, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":673,"qid":29577,"description":"Which kind of sign is this?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is placed on the left side of the road and bears the words \"NO PASSING ZONE\" in black characters on a yellow backdrop. [Pennant, Single Message Shaped Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003151738521917.jpg","correctAnswer":30402,"isBookmarked":0,"aid":"4369#15824#30402#35271","text":"A route marker#A work zone sign#A No Passing Zone sign#A Keep Right sign"},
{"testId":673,"qid":29578,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car is automated, move into a low gear to keep your brakes from fading (losing their effectiveness). [Diamond & Warning Signs, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":673,"qid":29579,"description":"A school bus with flashing red lights has pulled over to the side of the road ahead. What should you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#33169#34196#34928","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus"},
{"testId":673,"qid":29580,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"This scenario does not seem to be addressed by West Virginia law. In reality, however, most drivers adhere to the common-sense approach employed in the majority of other states: stop and then yield to cars that arrived at the junction before you. In other words, cars should move in the order they came. That would be Car B in this case, followed by Car A and finally Car C. Of course, each vehicle must come to a complete stop before continuing.","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":673,"qid":29581,"description":"When are headlights required in West Virginia?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"14655#22962#41303#41584","text":"Between sunset and sunrise#In all of the above situations#Whenever visibility is difficult at 500 feet#Under conditions of fog, rain, or smoke"},
{"testId":673,"qid":29582,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":673,"qid":29583,"description":"Which of the following is true about railroad crossings?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":41585,"isBookmarked":0,"aid":"23029#41094#41585#41586","text":"All of the above are true#You can drive around lowered crossing gates and cross if you don't see a train approaching#When railroad crossing signals are flashing, you must stop even if you don't see a train approaching#After a train has passed, you may cross immediately"},
{"testId":673,"qid":29584,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"A automobile turning left must yield to oncoming traffic at a four-way stop or uncontrolled junction (i.e., one without signs or signals). At this point, Car A must let Car B go. Each car at a four-way stop must stop completely before moving forward. [State of West Virginia Driver's Licensing Handbook, Right-of-Way, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#40438#41305","text":"Car A#Car B#Neither car (i.e., one driver must let the other driver through)#The car at the busiest approach to the intersection"},
{"testId":673,"qid":29585,"description":"You are not permitted to park within ______ of a crosswalk at an intersection.","explanation":null,"imageName":"202002181141488961.jpg","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8417","text":"10 feet#5 feet#20 feet#30 feet"},
{"testId":673,"qid":29586,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [Chapter VI: Traffic Laws and Rules of the Road, State of West Virginia Driver's Licensing Handbook, Changing Lanes]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":673,"qid":29587,"description":"Which of the following is a safe following distance behind the car ahead of you?","explanation":"The most prevalent cause of rear-end crashes is following too closely (tailgating). The three-to-four-second guideline is recommended by the State of West Virginia Driver's Licensing Handbook for maintaining a safe following distance behind the vehicle ahead of you. Allow at least three seconds of following space behind the car in front of you. Increase your following distance even further under more risky driving circumstances. [State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10881#33894#41587","text":"Four seconds#Two seconds#One car length for every 10 mph of speed#Far enough for you to clearly see the vehicle's taillights"},
{"testId":673,"qid":29588,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [The \"No-Zone,\" State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":673,"qid":29590,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"Headlights with high beams can be blinding. Do not respond with your own bright beams if an incoming car has not lowered its headlights. Instead, slow down, stay to the right, keep your eyes away from the incoming car, and steer by the right edge of the road until the vehicle has past. [State of West Virginia Driver's Licensing Handbook, Chapter VIII: Defensive Driving, Light Conditions]","imageName":"202003161214084893.jpg","correctAnswer":40106,"isBookmarked":0,"aid":"40105#40106#40107#40108","text":"Speed up to pass the vehicle sooner#Watch the right edge of the roadway#Watch the left edge of the roadway#Turn on your own high beams"},
{"testId":673,"qid":29591,"description":"You're on a divided roadway with controlled access. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":673,"qid":29592,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down and continue with care through the junction if you observe a flashing yellow light. [Intersection Flashers, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#35286#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Stop and wait for the light to change#Yield as you would at a yield sign"},
{"testId":673,"qid":29593,"description":"Which vehicle must yield?","explanation":"When two cars approach a four-way stop or an uncontrolled junction (one without signs or signals) at about the same time, the driver on the left must yield to the motorist on the right. Car A must yield to Car B in this situation. Both cars must come to a full stop at a four-way stop before proceeding. [Right-of-Way, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202003241439594485.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#17019#41463","text":"Car A#Car B#Whichever car is on the busier road#Neither car (i.e., one car must let the other car through)"},
{"testId":673,"qid":29594,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Driving Signals, State of West Virginia Driver's Licensing Handbook, Chapter VI: Traffic Laws and Rules of the Road]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":673,"qid":29595,"description":"When do high-beam headlights need to be dimmed under West Virginia State law?","explanation":null,"imageName":"202002211503506612.jpg","correctAnswer":40102,"isBookmarked":0,"aid":"6334#40101#40102#40784","text":"Never#Whenever you are within 200 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 200 feet#Whenever you are within 500 feet of any other vehicle"},
{"testId":674,"qid":29596,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"This is a stop sign, the only octagonal form of sign (eight sided). The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. At a stop sign, you must come to a complete stop before the stop line, crosswalk, or junction, whichever comes first. Then, yield to pedestrians and other cars and advance only when it is safe. [Stop Sign, Regulatory Signs, Traffic Signs, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":674,"qid":29597,"description":"What is the meaning of this sign?","explanation":"Under normal driving circumstances, this sort of sign indicates the maximum speed restriction (50 mph in this example) for a segment of highway. Slow down below the maximum speed limit under dangerous driving circumstances. [Wisconsin Department of Transportation Motorists' Handbook, Speed Limit Signs, Regulatory Signs, Traffic Signs]","imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"35243#35244#35245#35246","text":"The recommended speed limit is 50 mph here#You must not exceed 50 mph here#You must not drive slower than 50 mph here#You must always drive at 50 mph here"},
{"testId":674,"qid":29598,"description":"You intend to turn at a junction and cannot see any other vehicles in the area. Is it still necessary to signal?","explanation":"Always indicate your desire to turn or change lanes ahead of time. If you want to turn, you must begin signaling at least 100 feet in advance, according to Wisconsin law. Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. A rapid turn or lane change might also catch pedestrians or other drivers off guard. [Signal When Changing Direction, Informing Others What You Are Doing, Communicating, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#43769","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Wisconsin"},
{"testId":674,"qid":29599,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Intersections, Right-of-Way, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":674,"qid":29600,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down, keep an eye out for traffic, and yield to oncoming cars before proceeding. You may need to stop for them as well, so be prepared. [Yield Sign, Regulatory Signs, Traffic Signs, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":674,"qid":29601,"description":"On an open road, you notice an ambulance approaching with flashing lights. What should you do?","explanation":"If an emergency vehicle with a siren, an air horn, or a red or blue flashing light approaches from either direction, you must clear the intersection if you're in one, yield the right-of-way, pull over to the right, and allow the emergency vehicle to pass safely. However, if the emergency vehicle is on the opposite side of a divided highway, you are not required to stop. You must also not follow an emergency vehicle within 500 feet. [Emergency Vehicles, Right-of-Way, Wisconsin Department of Transportation Motorists' Handbook]","imageName":"202001302235285631.jpg","correctAnswer":43771,"isBookmarked":0,"aid":"35249#38647#43770#43771","text":"Speed up and turn off the road as soon as possible#Maintain your speed and direction#Pull over to the right and stop if the ambulance is on your side of the road#Pull over to the right and stop, no matter which side of the road the ambulance is on"},
{"testId":674,"qid":29602,"description":"In Wisconsin, parking is prohibited within _____ of a railroad crossing.","explanation":"At a railroad crossing in Wisconsin, you may not park within 25 feet of the closest rail. (Note 1: There are several additional areas where you are not permitted to park. Look for any signs or surface markings that ban or limit parking. Note 2: In most other states, you are not permitted to park within 50 feet of a rail.) [No Parking Zones, Parking, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181114128699.jpg","correctAnswer":1027,"isBookmarked":0,"aid":"1026#1027#1591#8416","text":"50 feet#25 feet#5 feet#15 feet"},
{"testId":674,"qid":29603,"description":"Which kind of sign is this?","explanation":"This is a school zone warning sign. These signs alert motorists when they are approaching a school zone or crossing. Expect to slow down and give way to pedestrians. [Wisconsin Department of Transportation Motorists' Handbook, School Crossing Signs, Warning Signs, Traffic Signs]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16895#16896","text":"A school zone sign#A work zone sign#A pedestrian crossing sign#A lane control sign"},
{"testId":674,"qid":29604,"description":"Which of the following is true about the speed limits in Wisconsin?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":43772,"isBookmarked":0,"aid":"23029#35057#41448#43772","text":"All of the above are true#The posted minimum speed limit is not enforceable#On a two-lane road, you may exceed the speed limit to pass another vehicle safely#Under certain conditions, you can be cited for driving at the maximum speed limit"},
{"testId":674,"qid":29605,"description":"Is it legal to turn right at a red light?","explanation":"In Wisconsin, you may turn right on a persistent red light unless there is a sign banning the move. However, before turning, you must come to a full stop and yield to pedestrians and vehicles. (Note: In Wisconsin, unlike most other states, a red arrow signal pointing right equals a red traffic light. That is, you are permitted to turn right after stopping and yielding.) [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"38857#41579#43773#43774","text":"Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only when permitted by a sign#Yes, and you will have the right-of-way#No, not in Wisconsin"},
{"testId":674,"qid":29606,"description":"Which occupants of a motor vehicle are required by Wisconsin law to wear safety belts or appropriate child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16365#26667#35237#37513","text":"The driver and all passengers#The driver and front-seat passengers#The driver, front-seat passengers, and passengers under 18#The driver and passengers under 18"},
{"testId":674,"qid":29607,"description":"Is it legal to turn left at a green light?","explanation":"Except if forbidden by a sign, you may turn left at a green light. You will not, however, have the right of way. Before turning, you must yield to oncoming traffic and crossing pedestrians. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#39458#40766#41030","text":"No#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles#Yes, you have the right-of-way"},
{"testId":674,"qid":29608,"description":"Which of the following about the right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide anybody the right-of-way. You may come across cars or pedestrians who are breaking the rules. In such instances, you should give the right-of-way rather than risk a collision. To prevent an accident, you must always do everything possible, including ceding the right-of-way if required. [Right-of-Way, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202007120454477868.jpg","correctAnswer":40075,"isBookmarked":0,"aid":"35015#35256#35257#40075","text":"The right-of-way is a legal right#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever you can#You should always be prepared to yield the right-of-way"},
{"testId":674,"qid":29609,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, if there is a turn lane, you should utilize it. Otherwise, take a left from the left lane and a right from the right lane. Then merge into the nearest lane of traffic traveling in the correct direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [Wisconsin Department of Transportation Motorists' Handbook, Left Turns, Turning]","imageName":"202004281905132227.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":674,"qid":29610,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#1075#15946#35258","text":"Slow-moving vehicle#No passing#Dead end#Work zone"},
{"testId":674,"qid":29611,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"At a junction, stop or yield signs may grant a certain highway priority right-of-way. Cars A and C are both facing a yield sign, hence they must both yield to Car B. [Yield Sign, Regulatory Signs, Traffic Signs, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":674,"qid":29612,"description":"Unless otherwise indicated, the maximum speed limit in a business or residential district in Wisconsin is :","explanation":"Unless otherwise marked, the maximum speed restriction in a commercial or residential area in Wisconsin is 25 mph. However, the maximum speed restriction in certain outskirts of towns or villages might be 35 mph. [Wisconsin Department of Transportation Motorists' Handbook, Speed Limits]","imageName":"202003151746465721.jpg","correctAnswer":522,"isBookmarked":0,"aid":"520#522#523#1676","text":"15 mph#25 mph#20 mph#35 mph"},
{"testId":674,"qid":29613,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If you can do so safely, you should stop. (Never attempt to \"race the light\" by speeding up before it turns red.) But don't come to a quick halt and risk sliding or being rear-ended by the car in front of you. If you are unable to safely stop, continue with care into the junction. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003051752141237.jpg","correctAnswer":40086,"isBookmarked":0,"aid":"28857#40085#40086#40767","text":"Speed up to get through the signal before it turns red#Drive through if you can do so safely#Stop if you can do so safely#Drive through after yielding to traffic"},
{"testId":674,"qid":29614,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B in this situation. [Wisconsin Department of Transportation Motorists' Handbook, Left Turns/U-Turns, Right-of-Way]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":674,"qid":29615,"description":"What is the speed limit in Wisconsin school zones while children are present?","explanation":"Unless otherwise indicated, the speed restriction in a school zone while children or school crossing guards are present is 15 mph. [Wisconsin Department of Transportation Motorists' Handbook, Speed Limits]","imageName":"202004071515004176.jpg","correctAnswer":520,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":674,"qid":29616,"description":"Is it permissible to turn left at a green signal?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003070834498321.jpg","correctAnswer":43775,"isBookmarked":0,"aid":"33151#34492#38661#43775","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming vehicles before you turn#Yes, and you'll have the right-of-way over oncoming traffic"},
{"testId":674,"qid":29617,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":"This is a shared middle lane (also known as a two-way left-turn lane). Vehicles driving in either direction utilize this lane to make left turns (and U-turns where permitted). When entering this lane, exercise care since cars moving in the other way may also be utilizing it. This lane should never be utilized to pass. [Shared Center Lane, Pavement Markings, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202001301806403967.jpg","correctAnswer":41581,"isBookmarked":0,"aid":"41451#41454#41581#43263","text":"(a) Passing#(a) and (b)#(b) Left turns#(c) Car pooling or public transportation"},
{"testId":674,"qid":29618,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, you must come to a complete stop before crossing the junction, yield to oncoming vehicles and pedestrians, and then continue cautiously only when it is safe to do so. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003051722347868.jpg","correctAnswer":41301,"isBookmarked":0,"aid":"19471#30736#38663#41301","text":"Slow down and proceed with caution#Yield to traffic and pedestrians#Yield to all vehicles unless you are on the busier roadway#Come to a complete stop and yield to traffic and pedestrians"},
{"testId":674,"qid":29619,"description":"A white cane-wielding pedestrian enters the road ahead. What are your options?","explanation":null,"imageName":"202003202129395393.jpg","correctAnswer":43776,"isBookmarked":0,"aid":"28545#30742#43776#43777","text":"Sound your horn to alert the pedestrian to your presence#Drive around the pedestrian#Stop 10 feet away and wait for the pedestrian to cross#Stop and wait until the pedestrian is out of your lane"},
{"testId":674,"qid":29620,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. If a train is coming, do not attempt to cross. [Wisconsin Department of Transportation Motorists' Handbook, Railroad Crossing Warning Signs, Regulatory Signs, Traffic Signs]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":674,"qid":29621,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, position your wheels away from the curb and roll back slightly such that the back section of the curbside front tire rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [How to Park on a Hill, Parking, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":674,"qid":29622,"description":"When is it legal to drive in a lane with this sign?","explanation":"High-occupancy vehicle (HOV) lanes are designated for carpools and cars carrying more than one passenger. Signs specify the number of people that each vehicle must have, as well as the days and hours that this applies. This sign indicates that the lane is an HOV 2+ lane, which requires each vehicle to have at least two people (the driver and at least one passenger). At least two passengers per car are required in an HOV 3+ lane. [Reserved Lanes, Other Lane Controls, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"1374830164_t1q24.png","correctAnswer":43778,"isBookmarked":0,"aid":"9435#41455#43778#43779","text":"At any time#Never (passenger cars are prohibited)#Only if you have at least one passenger#Only if you have at least two passengers"},
{"testId":674,"qid":29623,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"19471#30005#35047#39336","text":"Slow down and proceed with caution#Slow down and prepare to stop#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":674,"qid":29625,"description":"In Wisconsin, it is prohibited to park within _____ feet of a fire hydrant.","explanation":"You may not park within 10 feet of a fire hydrant in Wisconsin. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [No Parking Zones, Parking, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202001290916213788.jpg","correctAnswer":1028,"isBookmarked":0,"aid":"1028#4582#8416#35270","text":"10 feet#20 feet#15 feet#6 feet"},
{"testId":674,"qid":29626,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"You should utilize your vehicle's turn signals in most instances. If your turn signals fail, use hand signals instead. In addition, if harsh sunshine makes it difficult for other drivers to see your flashing turn signals, consider utilizing hand gestures as well. This hand gesture denotes a turn to the right. [Hand Signals, Let Others Know What You're Doing, Communicating, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":674,"qid":29627,"description":"Which kind of sign is this?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign indicates that passing other cars is not permitted here. It is placed on the left side of the road and bears the words \"NO PASSING ZONE\" in black characters on a yellow backdrop. Even if it looks safe, never cross through a no-passing zone. There may be risks that you cannot see from your current location. [No Passing Zone Sign, Regulatory Signs, Traffic Signs, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202003151738521917.jpg","correctAnswer":30402,"isBookmarked":0,"aid":"4369#15824#30402#35271","text":"A route marker#A work zone sign#A No Passing Zone sign#A Keep Right sign"},
{"testId":674,"qid":29628,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for vehicles that are slowly descending, such as trucks. Even if your car is automated, move into a low gear to keep your brakes from fading (losing their effectiveness). [Wisconsin Department of Transportation Motorists' Handbook, Warning Signs, Traffic Signs]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":674,"qid":29629,"description":"A school bus with flashing red lights has pulled over to the side of the road ahead. What should you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":43781,"isBookmarked":0,"aid":"19471#28148#43780#43781","text":"Slow down and proceed with caution#Proceed with caution#Stop if you are on the same side of the road as the school bus#Stop, no matter which side of the road you are on"},
{"testId":674,"qid":29630,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"You must come to a complete stop at an all-way stop and then give way to any approaching cars. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. Each vehicle must obviously halt before moving forward. [Wisconsin Department of Transportation Motorists' Handbook: Intersections, Right-of-Way]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":674,"qid":29631,"description":"When should you switch on your headlights?","explanation":"When you can't see clearly or other drivers can't see you, turn on your headlights. Wisconsin law requires you to use your headlights from half an hour after sunset to half an hour before daybreak, as well as anytime you cannot see a person or vehicle at 500 feet. When driving away from the rising or setting sun, you should also utilize your headlights. Sun glare may make it harder for approaching cars to see your vehicle. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Wisconsin Department of Transportation Motorists' Handbook, Use Your Lights, Seeing Well], [Wisconsin Department of Transportation Motorists' Handbook, Use Headlights, Let Others Know You Are There, Communicating]","imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"16838#22962#35276#41303","text":"From half an hour after sunset to half an hour before sunrise#In all of the above situations#Whenever you think other drivers may find it difficult to see you#Whenever visibility is difficult at 500 feet"},
{"testId":674,"qid":29632,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"Before turning at a red light, you must first stop and then cede the right-of-way to oncoming vehicles and legally crossing pedestrians. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":674,"qid":29633,"description":"Is it legal to turn left when you see a flashing yellow arrow?","explanation":"You have the right-of-way if you turn left at a flashing yellow arrow signal. Before turning, exercise care and yield to crossing pedestrians and incoming cars. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003070851013728.jpg","correctAnswer":43783,"isBookmarked":0,"aid":"18118#43782#43783#43784","text":"No#Yes, and you should have the right-of-way#Yes, but you must yield to oncoming vehicles and crossing pedestrians before you turn#Yes, but only if you cannot stop safely before entering the intersection"},
{"testId":674,"qid":29634,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"A automobile turning left must yield to oncoming traffic at a four-way stop or uncontrolled junction (i.e., one without signs or signals). At this point, Car A must let Car B go. Each car at a four-way stop must stop completely before moving forward. [Wisconsin Department of Transportation Motorists' Handbook: Left Turns/U-Turns, Right-of-Way]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#16839#40438","text":"Car A#Car B#The car at the busiest entrance to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":674,"qid":29636,"description":"Which of the following must you perform before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. However, your vehicle has blind spots wide enough to conceal another vehicle in the lane adjacent to yours. Looking over your shoulder before changing lanes is the only method to check for blind spots. Otherwise, you can miss the automobile in front of you until it's too late. [Wisconsin Department of Transportation Motorists' Handbook], [Signal When You Change Direction, Let Others Know What You Are Doing, Communicating, Wisconsin Department of Transportation Motorists' Handbook]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":674,"qid":29637,"description":"Which of the following is a safe following distance behind the vehicle in front of you?","explanation":"The most prevalent cause of rear-end crashes is following too closely (tailgating). The Wisconsin Department of Transportation Motorists' Handbook suggests leaving at least four seconds of following distance behind the car ahead of you to maintain a safe following distance. Increase your following distance even further under risky driving circumstances. [Wisconsin Department of Transportation Motorists' Handbook, Space Ahead]","imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10881#33894#41587","text":"Four seconds#Two seconds#One car length for every 10 mph of speed#Far enough for you to clearly see the vehicle's taillights"},
{"testId":674,"qid":29638,"description":"Which cars are in the blind spots of the truck?","explanation":"No-Zones are big blind zones on trucks. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. A vehicle just behind or to the side of the truck, immediately behind the cab, may be in a No-Zone. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. Remember that if you can't see the truck driver directly or via one of the truck's mirrors, the truck driver can't see you. [Inform Others That You Are Present, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181228375127.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":674,"qid":29640,"description":"What should you do if a vehicle approaches you with its high lights turned on?","explanation":"Headlights with high beams can be blinding. If an approaching vehicle's headlights have not been muted, flash your high beams to inform the driver. If the motorist continues to fail to reduce the headlights, do not respond with your own bright beams. Instead, slow down, stay to the right, keep your eyes away from the incoming car, and steer by the right edge of the road until the vehicle has past. [Wisconsin Department of Transportation Motorists' Handbook, Use Your Lights, Seeing Well]","imageName":"202003161214084893.jpg","correctAnswer":43786,"isBookmarked":0,"aid":"40105#40108#43786#43787","text":"Speed up to pass the vehicle sooner#Turn on your own high beams#Flash your high beams and watch the right edge of the roadway#Flash your high beams and watch the left edge of the roadway"},
{"testId":674,"qid":29641,"description":"On the opposite side of a divided roadway, you notice a school bus stopped with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#43788#43789","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop 20 feet before the bus and remain stopped until the red lights stop flashing or the bus resumes its motion#Stop 20 feet before the bus, yield if necessary, and then proceed"},
{"testId":674,"qid":29642,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, look for traffic and any dangers, and continue with care through the junction if you notice a flashing yellow light. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#35286#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Stop and wait for the light to change#Yield as you would at a yield sign"},
{"testId":674,"qid":29643,"description":"Which vehicle must yield?","explanation":"When two cars approach a four-way stop or an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. Both cars must come to a full stop at a four-way stop before proceeding. [Intersections, Right-of-Way, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202003241439594485.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#17019#41463","text":"Car A#Car B#Whichever car is on the busier road#Neither car (i.e., one car must let the other car through)"},
{"testId":674,"qid":29644,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist plans to slow down or come to a complete stop. [Hand Signals, Let Others Know What You're Doing, Communicating, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":674,"qid":29645,"description":"When are you required by Wisconsin law to dim your high-beam headlights?","explanation":"Wisconsin law requires you to lower your high lights anytime you are within 500 feet of an incoming vehicle or a vehicle you are pursuing. In heavy traffic, you should also decrease your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Wisconsin Department of Transportation Motorists' Handbook, Use Your Lights, Seeing Well]","imageName":"202002211503506612.jpg","correctAnswer":43792,"isBookmarked":0,"aid":"43790#43791#43792#43793","text":"Whenever you are within 1,000 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 1,000 feet#Whenever you are within 500 feet of any other moving vehicle#Whenever you are within 1,000 feet of any other moving vehicle"},
{"testId":675,"qid":29646,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":"Of fact, this is a stop sign, the only sort of sign with an octagonal (eight-sided) form. The word \"STOP\" is inscribed in white on a red backdrop on a stop sign. You must stop before the stop line or crosswalk if there is one at a stop sign. Then, yield to pedestrians and other cars and advance only when it is safe. [WY Stat 31-5-222] [Octagon (Eight Sides), Regulatory Signs, Traffic Signs, Wyoming Rules of the Road]","imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35242","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Watch out for schoolchildren crossing the road"},
{"testId":675,"qid":29647,"description":"What is the meaning of this sign?","explanation":"The maximum speed restriction is shown by this sign. You are not permitted to exceed 50 mph in this area. It should be noted that this does not imply that driving at 50 mph is safe in this area; this is dependent on current driving circumstances. Slow down below the maximum speed limit in difficult driving conditions. [WY Stat 31-5-301] [Rectangles, Regulatory Signs, Traffic Signs, Wyoming Rules of the Road]","imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"35243#35244#35245#35246","text":"The recommended speed limit is 50 mph here#You must not exceed 50 mph here#You must not drive slower than 50 mph here#You must always drive at 50 mph here"},
{"testId":675,"qid":29648,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":"Before turning in Wyoming, you must signal for at least 100 feet. (At 45 mph, a car will go 100 feet in 1.5 seconds.) Consider signaling four or five seconds ahead rather than simply 100 feet ahead while travelling at greater speeds.) Even if you don't see any other cars, you must signal. The car you don't see might be the most harmful. [Turn Signaling, Turning, Road Rules, Wyoming Road Rules], [WY Stat 31-5-217]","imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#35247","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Wyoming"},
{"testId":675,"qid":29649,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"When two cars approach an uncontrolled junction (one without signs or signals) at about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to the right here, and Car B has the right-of-way. [Right-of-Way at Intersections, Right-of-Way, Road Rules, Wyoming Road Rules]","imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":675,"qid":29650,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":"This is a yield sign, which is the only form of sign that has a downward-pointing triangular shape. When you get to a yield sign, you must slow down and surrender to oncoming vehicles and pedestrians before proceeding. You may need to stop for them as well, so be prepared. [Down Triangle, Regulatory Signs, Traffic Signs, Wyoming Rules of the Road]","imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35014#35248","text":"Slow down and yield to traffic#Stop and yield to traffic#Yield only if you are turning#Line up behind the vehicles already in the intersection"},
{"testId":675,"qid":29651,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":"If an emergency vehicle approaching from behind with flashing lights or a siren, you must pull over to the right side of the road and stop. However, if you're in the midst of an intersection and an emergency vehicle arrives, don't stop. Instead, go through the junction and immediately pull over. When you come to a complete stop, maintain your foot on the brake so the emergency driver can see you. [Right-of-Way Involving Emergency Vehicles, Right-of-Way, Road Rules, Wyoming Road Rules]","imageName":"202001302235285631.jpg","correctAnswer":23572,"isBookmarked":0,"aid":"15493#23572#26798#35249","text":"Slow down#Pull over to the right and stop#Maintain your speed and direction; changing lanes or stopping is unpredictable and dangerous#Speed up and turn off the road as soon as possible"},
{"testId":675,"qid":29652,"description":"Wyoming prohibits parking within _____ of a railroad crossing.","explanation":"Parking within 50 feet of the closest rail at a railroad crossing is forbidden in Wyoming. [Wyoming Statute 31-5-504]","imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1027#1028#8416","text":"50 feet#25 feet#10 feet#15 feet"},
{"testId":675,"qid":29653,"description":"This symbol is pentagonal (five sided). What kind of symbol is this?","explanation":"Pentagonal school zone and crossing signs are used (five sided). Slow down, be cautious, and keep an eye out for pedestrians. Follow the instructions of the school crossing guards. [Pentagon, Warning Signs, Traffic Signs, Wyoming Road Rules]","imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16895#16896","text":"A school zone sign#A work zone sign#A pedestrian crossing sign#A lane control sign"},
{"testId":675,"qid":29654,"description":"Which of the following statements concerning Wyoming's speed limits is correct?","explanation":"You must not drive faster than the legal maximum speed limit or slower than the legal minimum speed limit. Both restrictions are legally binding. Wyoming law requires you to drive at a \"reasonable and sensible\" pace for the present road, traffic, and weather circumstances, even if it means going below the maximum speed limit. When driving conditions are bad, slow down. In a school zone, the speed restriction is 20 mph. [Speed, Road Rules, Wyoming Road Rules], [WY Stat 31-5-301], [WY Stat 31-5-304]","imageName":"202003151746465721.jpg","correctAnswer":30421,"isBookmarked":0,"aid":"23029#30421#35056#35057","text":"All of the above are true#You should drive at the posted speed limit only under safe conditions#The speed limit in a school zone is 30 mph#The posted minimum speed limit is not enforceable"},
{"testId":675,"qid":29655,"description":"Is it legal to turn right at a red light?","explanation":"Unless there are indications banning it, you may turn right at a red light. However, before turning, you must come to a full stop and yield to all pedestrians and vehicles. [Red Light, Traffic Signals, Definitions, Wyoming Road Rules]","imageName":"202007120400221658.jpg","correctAnswer":35250,"isBookmarked":0,"aid":"15509#35250#35251#35252","text":"Yes, but only if a sign permits you to do so#Yes, but only after you stop and yield to traffic and pedestrians#Yes, but only after you yield to traffic and pedestrians#No, not in Wyoming"},
{"testId":675,"qid":29656,"description":"Which vehicle occupants in Wyoming are required to use seat belts or appropriate child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16365#34769#35253#35254","text":"The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#No one (seat belts are optional in Wyoming)#The driver and all passengers under 18"},
{"testId":675,"qid":29657,"description":"Is it permissible to turn left at a green signal?","explanation":"Except if forbidden by a sign, you may turn left at a green light. However, before turning, you must first yield to pedestrians and approaching cars. [WY Stat 31-5-403] [Green Light, Traffic Signals, Definitions, Wyoming Rules of the Road]","imageName":"202003070857473536.jpg","correctAnswer":35255,"isBookmarked":0,"aid":"18118#33518#33859#35255","text":"No#Yes, but only if there is a \"Left Turn Permitted\" sign present#Yes, you can turn left because this is a \"protected\" turn; oncoming traffic is stopped#Yes, but only after you yield to pedestrians and oncoming vehicles"},
{"testId":675,"qid":29658,"description":"Which of the following statement about right-of-way is true?","explanation":"The law specifies who must cede the right-of-way; it does not provide the right-of-way to anybody. You may come across cars or pedestrians who are breaking the rules. In such cases, give the right-of-way rather than risking a collision that might lead to an accident. Even if you believe you have the right-of-way, check to see whether pedestrians and other cars will yield to you before proceeding. [Right-of-Way, Road Rules, Wyoming Rules of the Road]","imageName":"202007120454477868.jpg","correctAnswer":31416,"isBookmarked":0,"aid":"31416#35015#35256#35257","text":"The right-of-way is to be given, not taken#The right-of-way is a legal right#Once you have the right-of-way, you may proceed immediately#You should claim the right-of-way whenever you can"},
{"testId":675,"qid":29659,"description":"Which vehicles are turning properly here?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane and right from the right lane, and then into the next lane of traffic travelling in the appropriate direction. Car B has made the proper turn into the nearest lane. Car A has made an erroneous turn into the furthest lane. [Proper Turning Rules, Turns, Road Rules, Wyoming Road Rules], [WY Stat 31-5-215]","imageName":"202004281905132227.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":675,"qid":29660,"description":"What is the meaning of this sign?","explanation":"A triangular orange insignia on the back of a car marks it as a slow-moving vehicle, which Wyoming defines as one that moves no faster than 25 miles per hour. Farm machinery, animal-drawn carts, and road maintenance vehicles are examples of slow-moving vehicles. Slow down and be patient if you come across one of these cars. Slow-moving vehicles have the legal right to utilize the road. [Farm and Slow-Moving Vehicles, Safe Road Sharing, Wyoming Road Rules]","imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#1075#15946#35258","text":"Slow-moving vehicle#No passing#Dead end#Work zone"},
{"testId":675,"qid":29661,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":"Before continuing, a vehicle that meets a yield sign at a junction must give the right-of-way to cross traffic. Cars A and C have both passed yield signs, hence Car B (cross traffic) has the right of way. [Intersections with \"YIELD\" signs, right-of-way, road rules, Wyoming road rules]","imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":675,"qid":29662,"description":"Unless otherwise marked, the speed limit throughout Wyoming's business and residential areas is :","explanation":"Unless otherwise marked, the speed limit in Wyoming is 30 mph in commercial and residential zones. [Legal Speed Limits, Speed, Road Rules, Wyoming Road Rules]","imageName":"202003151746465721.jpg","correctAnswer":521,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":675,"qid":29664,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":"A motorist turning left at a junction must first yield to oncoming traffic and crossing pedestrians. Car A must yield to Car B, who has the right-of-way. [Right-of-Way at Intersections, Wyoming Rules of the Road], [WY Stat 31-5-221]","imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":675,"qid":29665,"description":"Unless otherwise indicated, the speed limit in school zones in Wyoming is :","explanation":"Unless otherwise indicated, the speed limit in school zones in Wyoming is 20 mph. [Pentagon, Warning Signs, Traffic Signs, Wyoming Road Rules]","imageName":"202004071515004176.jpg","correctAnswer":523,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":675,"qid":29666,"description":"You come to a crossroads with this signal. Can you go to the left?","explanation":"The red signal prevents motorists from continuing straight through the crossing, while the green arrow permits them to turn left. This is a \"protected\" turn, which means that incoming traffic is halted at a red light, while the green arrow is illuminated for you. However, before turning, you must still yield to cars already at the junction and crossing pedestrians. [Turn Signals, Traffic Signals, Definitions, Wyoming Road Rules]","imageName":"202003070834498321.jpg","correctAnswer":34490,"isBookmarked":0,"aid":"34175#34490#34492#35260","text":"No, these lights are clearly out of order#Yes, this is a \"protected\" turn; oncoming traffic is stopped#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn"},
{"testId":675,"qid":29667,"description":"What must you do before entering a public road from an alley or driveway?","explanation":"When exiting an alley, driveway, or parking lot to reach a street, slow down and stop before driving onto a sidewalk or sidewalk area. Allow pedestrians and approaching cars the right of way. Stop and yield to oncoming vehicles while approaching a street from a driveway, alley, or private road. [Required Stops, Braking/Stopping, Road Rules, Wyoming Road Rules], [WY Stat 31-5-506]","imageName":"202001291438598251.jpg","correctAnswer":34774,"isBookmarked":0,"aid":"34774#34775#34776#34777","text":"Stop and yield before crossing the sidewalk#Stop and yield after crossing the sidewalk#Yield before crossing the sidewalk#Yield after crossing the sidewalk"},
{"testId":675,"qid":29668,"description":"What must you do when a red traffic light is flashing?","explanation":"A flashing red light must be treated as a stop sign. That is, before approaching the junction, you must stop, yield to vehicles and pedestrians, and then continue when it is safe to do so. [Flashing Red Lights, Traffic Signals, Definitions, Wyoming Traffic Rules]","imageName":"202003051722347868.jpg","correctAnswer":34770,"isBookmarked":0,"aid":"28148#30736#34770#35261","text":"Proceed with caution#Yield to traffic and pedestrians#Come to a complete stop and then yield to traffic and pedestrians#Yield to traffic unless you are on the busier roadway"},
{"testId":675,"qid":29669,"description":"Who is present in a crosswalk?","explanation":"A designated crosswalk is a section of road where people may cross safely. A crosswalk is defined under Wyoming law as the designated or unmarked space that connects the ends of two pieces of sidewalk at a junction. In this case, pedestrian A is in a designated crosswalk whereas pedestrian B is in an unmarked crosswalk. [WY Stat 31-5-102] [Definitions, Wyoming Rules of the Road], [Pedestrians, Sharing the Road Safely, Wyoming Rules of the Road],","imageName":"202005071748161699.jpg","correctAnswer":16942,"isBookmarked":0,"aid":"16940#16941#16942#16943","text":"Pedestrian A#Pedestrian B#Both pedestrians#Neither pedestrian"},
{"testId":675,"qid":29670,"description":"What is the meaning of this sign?","explanation":"This sign warns of an impending train crossing. Slow down and keep an eye out for warning lights and oncoming trains. Prepare to come to a halt if required. Never attempt to cross while a train is approaching. [Railroad Crossings, Traffic Signs, Signals, and Road Markings, Wyoming Road Rules]","imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15547#15550#27259#35262","text":"There is a crossroad ahead#There is a railroad crossing ahead#You are going the wrong way; go back#There is a train station ahead"},
{"testId":675,"qid":29671,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":"When parallel parking uphill, angle your wheels away from the curb and then gently roll back such that the back section of the right front wheel rests against the curb. The curb will prevent your automobile from rolling backward if your brakes fail. Make certain that your parking brake is still engaged and that your vehicle is in the proper gear (Park for an automatic transmission or first gear for maximum forward torque with a manual transmission). [Parking on Hills, Parking, Road Rules, Wyoming Road Rules]","imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":675,"qid":29672,"description":"Which of the following statements regarding driving on ice or snow is correct?","explanation":"Proceed with utmost care on slippery or snow-covered roadways. Because your vehicle's stopping distance will increase, you should leave at least six seconds between turns on snowy roads and eight seconds on ice roads. Shaded places and bridges are more likely to ice than other parts of the road. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road. On wet, slippery, or snowy conditions, avoid using cruise control. When your car loses traction on a slick surface, the cruise control may spin the drive wheels in an attempt to maintain a consistent speed. This may exacerbate or even cause a skid. [Wyoming Rules of the Road, Snow or Ice, Weather Conditions, Special Driving Conditions]","imageName":"202002061110137554.jpg","correctAnswer":23029,"isBookmarked":0,"aid":"23029#35263#35264#35265","text":"All of the above are true#Bridges and shady areas often freeze first#Don't use cruise control on icy or snowy roads#You should remain at least six to eight seconds behind the vehicle ahead of you"},
{"testId":675,"qid":29673,"description":"You are driving on a road with more than one lane. A vehicle with flashing lights has stopped in front of you. What must you do?","explanation":"To pass a stopped emergency vehicle employing flashing lights or a siren, you must move into the lane furthest from the emergency vehicle, unless otherwise authorized by a police officer, according to Wyoming's Move Over legislation. If you are traveling on a two-lane, two-way road (with no other lanes in your direction), you must slow down to 20 mph below the legal speed limit. (Note: All US states now have their own Move Over laws, however some of them demand various things of drivers. Always verify the local traffic rules while going out of state.) [WY Stat 31-5-224] [Emergency Vehicles, Sharing the Road Safely, Wyoming Rules of the Road]","imageName":"202001241703386758.jpg","correctAnswer":35266,"isBookmarked":0,"aid":"28148#35266#35267#35268","text":"Proceed with caution#Move into the lane farthest from the emergency vehicle#Slow down to 20 mph below the speed limit#Stop behind the emergency vehicle"},
{"testId":675,"qid":29674,"description":"In Wyoming, is it permitted to turn left on a red light?","explanation":"When going from one one-way roadway to another one-way street in Wyoming, unless signs prevent it, you may turn left on a red light. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Michigan), you may also turn left on red from a two-way street into a one-way street. Other states, such as South Dakota, outright ban left turns on red. Always verify the local driving regulations while going out of state. [Red Light, Traffic Signals, Definitions, Wyoming Road Rules]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35252#35269","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#No, not in Wyoming#Yes, unless it's prohibited by a sign"},
{"testId":675,"qid":29675,"description":"Wyoming prohibits parking within _____ feet of fire hydrants.","explanation":"You may not park within 15 feet of a fire hydrant in Wyoming. (There are also additional areas where parking is prohibited.) Look for signs or colored curb lines prohibiting or limiting parking.) [Parking Restrictions, Parking, Road Rules, Wyoming Road Rules]","imageName":"202001290916213788.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1028#4582#8416#35270","text":"10 feet#20 feet#15 feet#6 feet"},
{"testId":675,"qid":29676,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This motorist is indicating a right turn. [Two Ways for Drivers to Signal, Turning, Rules of the Road, Wyoming Rules of the Road]","imageName":"202002181150078740.jpg","correctAnswer":34192,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":675,"qid":29677,"description":"You see a sign in the shape of a flag. What does this sign say?","explanation":"Only No Passing Zone signs are pennant-shaped. This sign denotes the beginning of a no-passing zone, a portion of road where you are not permitted to pass. [Pennant, Warning Signs, Traffic Signs, Wyoming Road Rules]","imageName":"202003151738521917.jpg","correctAnswer":34168,"isBookmarked":0,"aid":"4369#15824#34168#35271","text":"A route marker#A work zone sign#A No Passing sign#A Keep Right sign"},
{"testId":675,"qid":29678,"description":"What is the meaning of this sign?","explanation":"This sign forewarns of a precipitous descent ahead. Examine your brakes. Keep an eye out for falling vehicles like trucks. Even if your car has an automatic gearbox, use low gear to avoid brake wear. [Diamond, Warning Signs, Traffic Signs, Wyoming Road Rules]","imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#35272#35273#35274","text":"There is a steep descent ahead#Trucks are entering from a steep side road ahead#This route is for trucks only#There is a weigh station ahead"},
{"testId":675,"qid":29679,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":"If a school bus stops with its red lights flashing in Wyoming, cars moving in either direction must come to a complete stop before approaching the school bus. They must stay halted until the red lights are turned off or the school bus resumes its journey. [Required Stops, Braking/Stopping, Road Rules, Wyoming Road Rules]","imageName":"202003070809524366.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"19471#28148#34195#34196","text":"Slow down and proceed with caution#Proceed with caution#Stop if you are traveling in the same direction as the bus#Stop, no matter which direction you are traveling in"},
{"testId":675,"qid":29680,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":"It's polite to give way to cars that arrived at the junction ahead of you at an all-way stop. In other words, traffic should go forward in the order it came. In this case, Car B would come first, then Car A, and finally Car C. [Four-way stop intersections, right-of-way, traffic laws, Wyoming traffic laws]","imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":675,"qid":29681,"description":"When should you switch on your headlights?","explanation":"From half an hour after dusk to half an hour before daybreak, you must use your headlights. When vision is poor at 1,000 feet, you must additionally use your headlights. Use your headlights to increase your visibility to other vehicles. Your parking lights are intended for parking only, not driving. If you need extra light when driving, turn on your headlights rather than your parking lights. [Reduced Lighting, Special Driving Conditions, Wyoming Road Rules]","imageName":"202003161149128995.jpg","correctAnswer":22962,"isBookmarked":0,"aid":"16838#22962#35275#35276","text":"From half an hour after sunset to half an hour before sunrise#In all of the above situations#Whenever visibility is difficult at 1,000 feet#Whenever you think other drivers may find it difficult to see you"},
{"testId":675,"qid":29682,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":"You must stop and yield to all cars and pedestrians before turning at a red light. [Right-of-Way to Pedestrians, Right-of-Way, Road Rules, Wyoming Road Rules]","imageName":"1372603791_t1q37.png","correctAnswer":15583,"isBookmarked":0,"aid":"15583#15679#28545#35038","text":"Stop and let the pedestrian cross#Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian"},
{"testId":675,"qid":29683,"description":"Which of the following is true about driving on roads that are wet?","explanation":"Proceed with care on wet roads. Because your vehicle's stopping distance will rise, extend your customary spacing cushion to at least four seconds. The first half hour of rain generally makes a road the most slippery. This is due to the fact that the first rain combines with dirt, oil, and other slick substances. This debris will remain until it is washed away by additional rain. Partial hydroplaning may occur at speeds as low as 35 mph while driving across water. Your tires will lose traction on the road and begin to skim over the surface of the water. Don't attempt to stop or accelerate if you hydroplane. Allow your automobile to slow down on its own. On wet, slippery, or snowy conditions, avoid using cruise control. When your car loses traction on a slick surface, the cruise control may spin the drive wheels in an attempt to maintain a consistent speed. This may exacerbate or even cause a skid. [Rain, Weather, Special Driving Conditions, Wyoming Driving Rules]","imageName":"202002061923072507.jpg","correctAnswer":35277,"isBookmarked":0,"aid":"23029#35277#35278#35279","text":"All of the above are true#A road is usually slipperiest during the first half hour of rain#Cruise control can improve your safety on wet roads#If you start to hydroplane, apply your brakes to slow down"},
{"testId":675,"qid":29684,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"A vehicle turning left at an all-way stop must first yield to incoming traffic. Car B has the right of way in this situation, and Car A must yield to it. However, because to the four-way stop, neither car may go forward until it has stopped completely. Right-of-way, rules of the road, Wyoming Rules of the Road, intersections with four-way stops, [WY Stat 31-5-221]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#26805#30406","text":"Car A#Car B#The car on the busier road#Whichever car chooses to go first"},
{"testId":675,"qid":29685,"description":"In Wyoming, it is prohibited to park within _____ feet of a crosswalk.","explanation":"You may not park within 20 feet of a crosswalk in Wyoming. (There are also additional areas where parking is prohibited.) Look for signs or colored curb lines prohibiting or limiting parking.) [Wyoming Statute 31-5-504]","imageName":"202002181141488961.jpg","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":675,"qid":29686,"description":"Which of the following must you complete before changing lanes?","explanation":"Before changing lanes, indicate and check your mirrors. Your vehicle, on the other hand, has blind zones on both sides that are big enough to conceal another vehicle. Looking over your shoulder is the only method to check for blind spots. [Interstate Driving, Highway Rules, Wyoming Highway Rules]","imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":675,"qid":29687,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Wyoming?","explanation":"The Wyoming Rules of the Road guide suggests leaving at least two seconds between you and the car in front of you. (Some sources suggest a three- to four-second following distance.) You may calculate your following distance by noting when the car in front of you reaches a stationary item and then calculating the seconds that pass until you reach the same thing. If the road conditions are bad, you should increase your following distance. [Isolate Your Vehicle in Traffic, Sharing the Road Safely, Wyoming Rules of the Road]","imageName":"202001302249003541.jpg","correctAnswer":15926,"isBookmarked":0,"aid":"15926#16519#34787#35281","text":"At least two seconds#At least one second#About 5 feet (far enough to see the brake lights of the vehicle ahead of you)#At least 25 feet"},
{"testId":675,"qid":29688,"description":"Which of the following vehicles is in one of the truck's blind spots?","explanation":"No-Zones are enormous blind zones on trucks and other heavy vehicles. In a No-Zone, an automobile may be totally obscured from the truck driver's vision. The truck's side mirrors only allow tiny arcs of view along the sides. Car B is now in one of the truck's No-Zones. It is hard to consistently avoid a truck's No-Zones, so don't stay in them for any longer than necessary. [Rules of the Road, Wyoming Rules of the Road, Passing Heavy Vehicles]","imageName":"1375598482_t1q43.png","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#17889","text":"Car A#Car B#Car C#None"},
{"testId":675,"qid":29689,"description":"What is the meaning of this sign?","explanation":"This sign advises cars that the road ahead will be divided into a divided highway. Between opposing lanes, a physical barrier or median will arise. Keep to the right of the median. [Warning Signs, Traffic Signs, Wyoming Road Rules]","imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"15654#22656#34060#35282","text":"There is a roundabout ahead#A divided highway starts ahead#There is a traffic signal ahead#A shared center turn lane starts ahead"},
{"testId":675,"qid":29690,"description":"Which of the following is true about railroad crossings?","explanation":"You must stop and stay stopped at a railroad crossing if the red lights begin to flash or the crossing gate is lowered until the red lights cease flashing and the crossing gate is completely raised. Wait until there is space for your car on the other side of the tracks before attempting to cross the tracks. Because no train can yield to you, you are legally compelled to yield to all trains. (A train running at 60 mph may halt completely after more than a mile.) If there are many tracks, ensure sure they are all free of traffic before you cross. (There might be another train coming in the other way.) Wyoming's rules of the road include \"Railroad Crossings (Highway/Rail Intersections), Special Driving Conditions,\" and \"Railroad Crossings, Traffic Signs, Signals, and Road Markings.\"","imageName":"202003161136554337.jpg","correctAnswer":35285,"isBookmarked":0,"aid":"28163#35283#35284#35285","text":"All of the above statements are true#You must stop at a flashing red signal only if you see a train approaching#You can drive around a lowered crossing gate if you don鈥檛 see a train approaching#If there is more than one track, make sure that all tracks are clear before you cross"},
{"testId":675,"qid":29691,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":"Normally, you must stop for a halted school bus with flashing red lights, regardless of which way it is driving. In Wyoming, however, there is one exemption to this rule: if there is a physical barrier or different highways between your car and the school bus, you may drive with great caution. [Required Stops, Braking/Stopping, Road Rules, Wyoming Road Rules]","imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"15489#28148#32321#35058","text":"Come to a complete stop, yield if necessary, and then proceed#Proceed with caution#Slow down to 15 mph and proceed with caution#Stop before reaching the bus's position"},
{"testId":675,"qid":29692,"description":"What should you do if you see a flashing yellow light?","explanation":"Drivers are warned of possible risks by flashing yellow lights. Slow down, keep an eye out for any risks, and continue with care. [Amber Lights, Traffic Signals, Definitions, Wyoming Road Rules]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#34177#35027#35286","text":"Slow down and proceed with caution#Yield as if it were a yield sign#Stop and yield as if it were a stop sign#Stop and wait for the light to change"},
{"testId":675,"qid":29693,"description":"Two cars arrive at the same moment at a road junction. Which car must yield?","explanation":"When two cars come to an all-way stop about the same time, the vehicle on the left must yield to the vehicle on the right. Car A must yield to Car B in this situation. [Intersections with four-way stops, right-of-way, road rules, Wyoming road rules]","imageName":"202003241439594485.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#35287#35288","text":"Car A must yield to its right#Car B must yield to its left#One driver should let the other driver go first#The car on the least busy road must yield"},
{"testId":675,"qid":29694,"description":"This driver is signaling with his hand. Which of the following statements is true?","explanation":"This hand gesture is analogous to the driver's vehicle's brake lights. This motorist is indicating a need to slow down or halt. [Two Ways for Drivers to Signal, Turning, Rules of the Road, Wyoming Rules of the Road]","imageName":"202002181152514351.jpg","correctAnswer":34193,"isBookmarked":0,"aid":"34191#34192#34193#34194","text":"This driver intends to turn left#This driver intends to turn right#This driver intends to slow down or stop#This is not a valid hand signal"},
{"testId":675,"qid":29695,"description":"When do your high-beam headlights need to be dimmed?","explanation":"When you are near 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following in Wyoming, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. Bright lights may bounce off the mirrors of other vehicles, blinding the driver. Wyoming Statute 31-5-924, [Reduced Light, Special Driving Conditions, Wyoming Rules of the Road]","imageName":"202002211503506612.jpg","correctAnswer":35041,"isBookmarked":0,"aid":"35041#35042#35043#35044","text":"Whenever you are within 500 feet of an oncoming vehicle or within 300 feet of a vehicle you are following#Whenever you are within 300 feet of an oncoming vehicle or within 500 feet of a vehicle you are following#Whenever you are within 500 feet of another vehicle#Whenever you are within 1,000 feet of another vehicle"},
{"testId":670,"qid":29696,"description":"At an intersection, you see a sign with eight sides. What do you have to do here?","explanation":null,"imageName":"202003151731031579.jpg","correctAnswer":35008,"isBookmarked":0,"aid":"28148#35007#35008#35009","text":"Proceed with caution#Slow down and yield to traffic#Stop and yield to traffic#Go straight when it is safe to do so; you cannot turn here"},
{"testId":670,"qid":29697,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003151749335643.jpg","correctAnswer":35244,"isBookmarked":0,"aid":"35243#35244#35245#35246","text":"The recommended speed limit is 50 mph here#You must not exceed 50 mph here#You must not drive slower than 50 mph here#You must always drive at 50 mph here"},
{"testId":670,"qid":29698,"description":"You are making a turn at an intersection and can't see any other vehicles. Do you still have to signal?","explanation":null,"imageName":"202001291433312120.jpg","correctAnswer":31404,"isBookmarked":0,"aid":"29975#30731#31404#41028","text":"No, you are only required to signal when other vehicles are present#Yes, you must signal for at least 50 feet before you turn#Yes, you must signal for at least 100 feet before you turn#No, turn signals are optional in Vermont"},
{"testId":670,"qid":29699,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":null,"imageName":"202007120521353376.jpg","correctAnswer":15485,"isBookmarked":0,"aid":"15485#15486#25311#32301","text":"Car A must yield to its right#Car B must yield to its left#The car on the busier road has the right-of-way#One driver should proceed first and take the right-of-way"},
{"testId":670,"qid":29700,"description":"This sign appears at an intersection. What are you supposed to do here?","explanation":null,"imageName":"202007120502165773.jpg","correctAnswer":35007,"isBookmarked":0,"aid":"35007#35008#35013#35014","text":"Slow down and yield to traffic#Stop and yield to traffic#Line up behind any vehicles already in the intersection#Yield only if you are turning"},
{"testId":670,"qid":29701,"description":"You notice an ambulance arriving with its lights flashing. What should you do?","explanation":null,"imageName":"202001302235285631.jpg","correctAnswer":34165,"isBookmarked":0,"aid":"15493#34164#34165#38647","text":"Slow down#Pull over if the ambulance is approaching you from behind#Pull over, no matter which direction the ambulance is traveling in#Maintain your speed and direction"},
{"testId":670,"qid":29703,"description":"Which kind of sign is this?","explanation":null,"imageName":"202003151728559496.jpg","correctAnswer":4828,"isBookmarked":0,"aid":"4828#15824#16896#40762","text":"A school zone sign#A work zone sign#A lane control sign#A pedestrian crosswalk sign"},
{"testId":670,"qid":29704,"description":"A line of white triangles is painted across your lane. What does this road sign mean?","explanation":null,"imageName":"202003202136149229.jpg","correctAnswer":33175,"isBookmarked":0,"aid":"33175#33176#33177#33178","text":"This is a yield line. Prepare to yield, stopping if necessary#This is a stop line. You must stop here before proceeding#This is the start of a school zone. Slow down and proceed with caution#This is the start of a no-passing zone. You cannot pass other vehicles here"},
{"testId":670,"qid":29705,"description":"Is it legal to turn right at a red light?","explanation":null,"imageName":"202007120400221658.jpg","correctAnswer":38857,"isBookmarked":0,"aid":"25289#38857#38858#41029","text":"Yes, this is a \"protected\" turn, so you will have the right-of-way#Yes, but only after you stop and yield to all vehicles and pedestrians#Yes, but only after you yield to all vehicles and pedestrians#No, not in Vermont"},
{"testId":670,"qid":29706,"description":"Which occupants of a passenger vehicle are required by Vermont law to wear safety belts or suitable child restraints?","explanation":null,"imageName":"202003151755527296.jpg","correctAnswer":16365,"isBookmarked":0,"aid":"16319#16365#34769#37513","text":"Just the driver#The driver and all passengers#The driver, all front-seat passengers, and all passengers under 18#The driver and passengers under 18"},
{"testId":670,"qid":29707,"description":"Is it legal to turn left at a green light?","explanation":null,"imageName":"202003070857473536.jpg","correctAnswer":39458,"isBookmarked":0,"aid":"18118#39458#40766#41030","text":"No#Yes, but you must first yield to pedestrians and oncoming vehicles#Yes, but only if there is a Left Turns Allowed sign posted, and you must first yield to pedestrians and oncoming vehicles#Yes, you have the right-of-way"},
{"testId":670,"qid":29708,"description":"You notice an emergency vehicle stopped ahead with its lights flashing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":35047,"isBookmarked":0,"aid":"19471#34515#35047#41031","text":"Slow down and proceed with caution#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down; also move into a non-adjacent lane if possible#Drive at 20 mph below the speed limit and prepare to stop"},
{"testId":670,"qid":29709,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":null,"imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":670,"qid":29710,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202003181417388899.jpg","correctAnswer":629,"isBookmarked":0,"aid":"629#1075#15946#35258","text":"Slow-moving vehicle#No passing#Dead end#Work zone"},
{"testId":670,"qid":29711,"description":"Three cars arrive at an intersection. Two of them have yield signs. Which car has the right of way?","explanation":null,"imageName":"202002181237497175.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":670,"qid":29712,"description":"Which of the following is true about the speed limits in Vermont?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":41032,"isBookmarked":0,"aid":"41032#41033#41034#41035","text":"Never drive faster than is reasonably safe#Always drive at the posted speed limit#Always drive slower than the posted speed limit#Never exceed the speed limit except when passing"},
{"testId":670,"qid":29713,"description":"What should you do if you come up on a steady yellow traffic light?","explanation":null,"imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28148#28524#28857#41036","text":"Proceed with caution#Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed after yielding to traffic"},
{"testId":670,"qid":29714,"description":"Two cars arrive at the same moment at a road junction. Which of the following is correct?","explanation":null,"imageName":"202003161155525651.jpg","correctAnswer":15535,"isBookmarked":0,"aid":"15535#25311#28526#32301","text":"Car A must yield because it is turning left#The car on the busier road has the right-of-way#Car B must yield to all vehicles turning left#One driver should proceed first and take the right-of-way"},
{"testId":670,"qid":29715,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101242114628.jpg","correctAnswer":28070,"isBookmarked":0,"aid":"54#15575#28070#31641","text":"A one-way road is ahead#A narrow bridge is ahead#The right lane ends ahead#The left lane ends ahead"},
{"testId":670,"qid":29716,"description":"Is it permissible to turn left at a green signal?","explanation":null,"imageName":"202003070834498321.jpg","correctAnswer":40768,"isBookmarked":0,"aid":"33151#34492#35260#40768","text":"No, these lights are out of order#No, you must wait for the red light to change#Yes, but you must yield to oncoming traffic before you turn#Yes, this is a \"protected\" turn, so you'll have the right-of-way over oncoming traffic"},
{"testId":670,"qid":29717,"description":"This road's center lane is separated from the other lanes by solid and broken yellow lines. This lane is intended for:","explanation":null,"imageName":"202001301806403967.jpg","correctAnswer":40769,"isBookmarked":0,"aid":"16863#16865#40769#40770","text":"Passing#Car pooling#Turning left#Reversing traffic flow"},
{"testId":670,"qid":29718,"description":"What must you do when a red traffic light is flashing?","explanation":null,"imageName":"202003051722347868.jpg","correctAnswer":23528,"isBookmarked":0,"aid":"19471#23528#35029#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Yield to all vehicles unless you are on the busier road#Yield as you would at a yield sign"},
{"testId":670,"qid":29719,"description":"Which of the following assertions concerning rainy driving conditions is correct?","explanation":null,"imageName":"202002061923072507.jpg","correctAnswer":41147,"isBookmarked":0,"aid":"23029#41146#41147#41148","text":"All of the above are true#The road will usually get more slippery after the first 15 minutes of rain#Avoid using cruise control on wet roads#A four-wheel drive vehicle can safely handle a road covered in water"},
{"testId":670,"qid":29720,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202001282004545744.jpg","correctAnswer":15550,"isBookmarked":0,"aid":"15550#35262#40429#40771","text":"There is a railroad crossing ahead#There is a train station ahead#There is a four-way intersection ahead#You are going the wrong way; turn around"},
{"testId":670,"qid":29721,"description":"You parked next to a curb facing up a steep hill. Which way should the front wheels face?","explanation":null,"imageName":"202002070945089828.jpg","correctAnswer":9713,"isBookmarked":0,"aid":"9713#9906#14638#25321","text":"Away from the curb#Straight#Toward the curb#In any direction"},
{"testId":670,"qid":29723,"description":"At a green signal, you turn right. Simultaneously, a pedestrian begins to cross the road you are going to enter. Who must yield?","explanation":null,"imageName":"202003202127165845.jpg","correctAnswer":40430,"isBookmarked":0,"aid":"40430#40431#40612#40613","text":"You must yield#The pedestrian must yield#You must yield only if the pedestrian is crossing with a WALK signal#You must yield only if the pedestrian ignores your horn"},
{"testId":670,"qid":29725,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":null,"imageName":"202003241439594485.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#15982#16831","text":"Car A#Car B#Neither car#Whichever car proceeds first"},
{"testId":670,"qid":29726,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181150078740.jpg","correctAnswer":6368,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":670,"qid":29727,"description":"As you come to a railroad crossing, a train approaches. Where must you stop?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":41149,"isBookmarked":0,"aid":"16907#41149#41150#41151","text":"Right in front of the tracks#At least 15 feet from the tracks#At least 5 feet from the tracks#At least 25 feet from the tracks"},
{"testId":670,"qid":29728,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101212538783.jpg","correctAnswer":31340,"isBookmarked":0,"aid":"31340#31341#35272#41092","text":"There is a steep descent ahead#There is an emergency truck escape ramp ahead#Trucks are entering from a steep side road ahead#There is a truck scale ahead"},
{"testId":670,"qid":29729,"description":"You are on a road with two lanes. A school bus with flashing red lights is stopped in front of you. What must you do?","explanation":null,"imageName":"202003051658529900.jpg","correctAnswer":34196,"isBookmarked":0,"aid":"28148#34196#34928#40618","text":"Proceed with caution#Stop, no matter which direction you are traveling in#Stop if you are traveling in the same direction as the school bus#Slow to 20 mph and proceed with caution"},
{"testId":670,"qid":29730,"description":"Three vehicles are sequentially approaching this four-way stop. Which sequence should these vehicles follow?","explanation":null,"imageName":"202003181421341222.jpg","correctAnswer":16258,"isBookmarked":0,"aid":"16256#16257#16258#23455","text":"C, B, A#B, C, A#B, A, C#A, C, B"},
{"testId":670,"qid":29731,"description":"Which of the following is true about using headlights?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":28163,"isBookmarked":0,"aid":"28163#41152#41153#41154","text":"All of the above statements are true#You must use your headlights from 30 minutes after sunset to 30 minutes before sunrise#You should not use your parking lights when your vehicle is in motion#You must use your headlights whenever visibility is poor at 500 feet"},
{"testId":670,"qid":29732,"description":"You are preparing to turn right against a red light. A person is beginning to cross the road you want to enter. What ought you to do?","explanation":null,"imageName":"1372603791_t1q37.png","correctAnswer":37528,"isBookmarked":0,"aid":"15679#28545#35038#37528","text":"Turn into the far lane to pass the pedestrian#Sound your horn to alert the pedestrian to your presence#Speed up to pass the pedestrian#Yield to the pedestrian"},
{"testId":670,"qid":29733,"description":"Which of the following is true about railroad crossings?","explanation":null,"imageName":"202003161136554337.jpg","correctAnswer":41095,"isBookmarked":0,"aid":"23029#41093#41094#41095","text":"All of the above are true#Even if the railroad crossing signals are flashing, you can cross if you don't see a train approaching#You can drive around lowered crossing gates and cross if you don't see a train approaching#If a train sounds its horn, you should stop at the crossing"},
{"testId":670,"qid":29734,"description":"At a four-way stop, two cars arrive at about the same time. Which car has the right-of-way?","explanation":"When two automobiles approach from opposing directions at a four-way stop or an uncontrolled junction (i.e., one without signs or signals) at roughly the same time, the car turning left must yield. Car A must yield to Car B in this situation. Each vehicle at a four-way stop must come to a full stop before advancing. [Right-of-Way, Driving Safely, Chapter 7 - Driving Safely and Preparing for Your Permit, Vermont Driver's Manual]","imageName":"202003202108424295.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#16839#40438","text":"Car A#Car B#The car at the busiest entrance to the intersection#Neither car (i.e., one driver must let the other driver through)"},
{"testId":670,"qid":29735,"description":"In which of the following scenarios is it allowed to park the car?","explanation":null,"imageName":"1375962901_t1q40.png","correctAnswer":29321,"isBookmarked":0,"aid":"15951#15952#29321#40100","text":"B) On a bridge#C) On the sidewalk#A) 10 feet from a fire hydrant#D) 14 inches from the curb"},
{"testId":670,"qid":29736,"description":"Which of the following must you perform before changing lanes?","explanation":null,"imageName":"202001302245403150.jpg","correctAnswer":23000,"isBookmarked":0,"aid":"14187#23000#31138#35280","text":"Look over your shoulder#Do all of the above#Use your turn signals#Check your mirrors"},
{"testId":670,"qid":29737,"description":"Which vehicles are concealed in the truck's blind spots?","explanation":null,"imageName":"202003070847119255.jpg","correctAnswer":14815,"isBookmarked":0,"aid":"14814#14815#14862#16543","text":"Car A#Car B#Car C#Cars A and C"},
{"testId":670,"qid":29738,"description":"Which of the following assertions regarding bikers in Vermont is true?","explanation":null,"imageName":"202002061115354267.jpg","correctAnswer":41155,"isBookmarked":0,"aid":"23029#41155#41156#41157","text":"All of the above are true#When passing a bicyclist, you must leave at least four feet of clearance#Bicyclists don't have the same rights as motor vehicle operators#When turning left at an intersection, you don't have to yield to oncoming bicyclists"},
{"testId":670,"qid":29739,"description":"What is the meaning of this sign?","explanation":null,"imageName":"202002101247507801.jpg","correctAnswer":22656,"isBookmarked":0,"aid":"8873#22656#40785#41158","text":"The divided highway ends ahead#A divided highway starts ahead#A new lane is added ahead#There is a chicane ahead"},
{"testId":670,"qid":29740,"description":"You are driving behind another vehicle. What is the suggested shortest distance to follow the vehicle in front of you in Vermont?","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":10879,"isBookmarked":0,"aid":"7370#10879#10881#33894","text":"40 feet#Four seconds#Two seconds#One car length for every 10 mph of speed"},
{"testId":670,"qid":29741,"description":"You're driving on a divided roadway. You approach a school bus that has stopped on the opposite side of the roadway with flashing red lights. What should you do?","explanation":null,"imageName":"202002010826098089.jpg","correctAnswer":28148,"isBookmarked":0,"aid":"28148#33169#40786#40787","text":"Proceed with caution#Slow to 15 mph and proceed with caution#Stop before you reach the bus#Stop before you reach the bus, yield if necessary, and then proceed"},
{"testId":670,"qid":29742,"description":"What should you do if you see a flashing yellow light?","explanation":null,"imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23528#31848#37531","text":"Slow down and proceed with caution#Stop and yield as you would at a stop sign#Go straight when safe; you cannot turn here#Yield as you would at a yield sign"},
{"testId":670,"qid":29743,"description":"Three cars approach a T-intersection without any signs or signals, In what order should they go?","explanation":null,"imageName":"202001241707055555.jpg","correctAnswer":16257,"isBookmarked":0,"aid":"16255#16256#16257#23455","text":"A, B, C#C, B, A#B, C, A#A, C, B"},
{"testId":670,"qid":29744,"description":"This driver is communicating with other drivers via hand signals. The driver intends to:","explanation":null,"imageName":"202002181152514351.jpg","correctAnswer":22345,"isBookmarked":0,"aid":"6365#6368#6475#22345","text":"Turn left#Turn right#Speed up#slow down or stop"},
{"testId":670,"qid":29745,"description":"You should face _____ to prevent being temporarily blinded by an oncoming vehicle's headlights.","explanation":null,"imageName":"202003161214084893.jpg","correctAnswer":10120,"isBookmarked":0,"aid":"10120#24945#41131#41132","text":"the right edge of the road#the center of the road#the left edge of the road#the driver of the oncoming vehicle"},
{"testId":730,"qid":29984,"description":"Which of these should you watch out for when you first get close to your vehicle?","explanation":"Before doing anything with your vehicle, you should check for all potential hazards in the vicinity.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17358#29498#29499","text":"All of the above#People in the vicinity#Low-hanging wires#Low-hanging tree limbs"},
{"testId":730,"qid":29985,"description":"Which of the following should NOT influence your choice of speed when starting a long downgrade?","explanation":"You should manage the downgrading in accordance with the circumstances, not your timetable.","imageName":"","correctAnswer":29500,"isBookmarked":0,"aid":"25887#29500#29501#29502","text":"The road and weather conditions#Your schedule#The steepness of the downgrade#The weight of your vehicle plus its cargo"},
{"testId":730,"qid":29986,"description":"When can you safely remove the radiator cap?","explanation":"Don't take any risks when it comes to the radiator cap. If you open it while it is still hot, you may be hit with a powerful rush of steam, which might result in severe harm.","imageName":"","correctAnswer":17368,"isBookmarked":0,"aid":"17365#17366#17367#17368","text":"As soon as you've stopped#Anytime, as long as the engine isn't overheated#Whenever it is cold outside#When the cap is cool enough to touch with a bare hand"},
{"testId":730,"qid":29987,"description":"What should you do as you approach a work crew on the freeway?","explanation":"Road workers may have their backs to you and be working heavy equipment, making it difficult for them to hear you. Slow down and be ready if any of the employees pose a risk.","imageName":"","correctAnswer":6476,"isBookmarked":0,"aid":"6476#29503#29504#29505","text":"Slow down#Sound your horn#Flash your lights#Rev your engine"},
{"testId":730,"qid":29988,"description":"Which of the following about a spare tire is FALSE?","explanation":"A spare tire must be the correct size, in excellent shape, and inflated to the proper pressure. It does not, however, have to be from the same company.","imageName":"","correctAnswer":29507,"isBookmarked":0,"aid":"29506#29507#29508#29509","text":"It must be the correct size#It must come from the same manufacturer as the truck's tires#It must be in good condition#It must be properly inflated"},
{"testId":730,"qid":29989,"description":"What is the bare minimum for tires other than front tires tread depth?","explanation":"The tread depth is limited to 2/32 inch. If it's close to the limit, it'll need to be modified shortly.","imageName":"","correctAnswer":20784,"isBookmarked":0,"aid":"20783#20784#20791#20792","text":"4/32 inch#2/32 inch#1/32 inch#3/32 inch"},
{"testId":730,"qid":29990,"description":"Why could your brakes lose effectiveness when descending?","explanation":"Your brakes may become mushy and less efficient as they warm up. Because of this, it's crucial to use engine brakes. If you just use your brakes, you could soon discover that you have none left at all.","imageName":"","correctAnswer":17382,"isBookmarked":0,"aid":"17380#17382#17383#29510","text":"Because they've picked up gravel from the road#Because they are overheating#Because your momentum is increasing#Because of increasing centrifugal force"},
{"testId":730,"qid":29991,"description":"When inspecting your vehicle's load, ensure that it complies with:","explanation":"You are responsible for following the laws of any country through which you drive. Following just the requirements of your departure or destination is inadequate; you may stop in a state or city with different restrictions.","imageName":"","correctAnswer":29514,"isBookmarked":0,"aid":"29511#29512#29513#29514","text":"the local regulations of the jurisdiction you are starting from#all federal regulations only#the state regulations of your destination#all local, state, and federal regulations that apply to your journey"},
{"testId":730,"qid":29992,"description":"A GPS unit:","explanation":"Always use a GPS system built specifically for truck navigation. A truck-enabled GPS device has information regarding truck-restricted highways and specified routes, and it directs your vehicle based on its size (length, weight, height, and load kinds). A truck-enabled GPS gadget will not take you into routes that are unsafe for your truck or are restricted for trucks.","imageName":"","correctAnswer":29518,"isBookmarked":0,"aid":"29515#29516#29517#29518","text":"should have audible as well as visual warnings#should have a screen larger than 4 inches#should be made in the USA#should be designed for truck navigation"},
{"testId":730,"qid":29993,"description":"What is the safest technique to slow down on a downhill?","explanation":"Your brakes will be unable to perform the job on extremely lengthy downgrades. Engine braking, on the other hand, will slow your car if you pick the suitable low gear. This will enable you to conserve your brakes for really steep regions instead of using them all the time and having them fade.","imageName":"","correctAnswer":29519,"isBookmarked":0,"aid":"29519#29520#29521#29522","text":"Use engine braking#Use your brakes at all times#Coast in neutral#Swing wide around curves"},
{"testId":730,"qid":29994,"description":"Which of the following should cause you to refuse a vehicle until it is repaired?","explanation":"Tires of varying sizes might result in major imbalances, steering issues, and braking difficulties.","imageName":"","correctAnswer":29523,"isBookmarked":0,"aid":"17396#17398#17399#29523","text":"Tires from different manufacturers#Tires that are retreads#Tires that have different tread patterns#Tires of different sizes"},
{"testId":730,"qid":29995,"description":"What should you do if you smell exhaust fumes in the cab?","explanation":"Exhaust gases pouring into the cab may be lethal, either killing you or making you sleepy, increasing your chances of an accident. Stop immediately and do not resume until the issue has been identified and remedied.","imageName":"","correctAnswer":17332,"isBookmarked":0,"aid":"17332#29524#29525#29526","text":"Stop immediately#Check at the next stop#Make a note on your post-trip report#Open the windows"},
{"testId":730,"qid":29996,"description":"How much following distance should you allow between you and the vehicle in front if you're driving a 30-foot vehicle at less than 40 miles per hour?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. This comes out to 3 seconds for a 30-foot truck traveling at less than 40 mph. This is the bare minimum; other considerations like as visibility and traffic conditions may need additional space.","imageName":"","correctAnswer":7322,"isBookmarked":0,"aid":"426#427#7321#7322","text":"4 seconds#5 seconds#2 seconds#3 seconds"},
{"testId":730,"qid":29997,"description":"What exactly are blocking and bracing?","explanation":"Blocking is the act of firmly wrapping cloth around your goods to prevent movement. Bracing consists of struts going from the top of the cargo to the cargo compartment's floor or walls to prevent movement.","imageName":"","correctAnswer":29527,"isBookmarked":0,"aid":"4725#17404#17406#29527","text":"An emergency stop#A driving technique#A form of coupling#A way to keep cargo secure"},
{"testId":730,"qid":29998,"description":"What is the primary reason for your pre-trip inspection?","explanation":"The vehicle's safety is always first and foremost.","imageName":"","correctAnswer":17407,"isBookmarked":0,"aid":"17407#17409#17410#29528","text":"To make sure that the vehicle is safe#To make sure you've got the right paperwork#To make sure you've signed for the right cargo#To make sure you won't be blamed for any existing damage"},
{"testId":730,"qid":29999,"description":"Who is responsible for ensuring the cleanliness of all lights and mirrors?","explanation":"Even if the mirrors and lights have previously been cleaned, they may get dusty before you drive the car or go on your vacation. Always have a clean cloth or sponge on available to maintain them in good shape.","imageName":"","correctAnswer":29530,"isBookmarked":0,"aid":"17413#17414#29529#29530","text":"Whoever drove the vehicle previously#The company maintenance staff#The vehicle's owner#The vehicle's driver"},
{"testId":730,"qid":30000,"description":"Where should you stop if your car catches fire?","explanation":"Maintain a safe distance between a burning car and anything else that might catch fire. Avoid going near a service station in particular; the truck fire might ignite gasoline.","imageName":"","correctAnswer":29532,"isBookmarked":0,"aid":"17418#29531#29532#29533","text":"Near other vehicles#At a service station#In an open area#Near a building"},
{"testId":730,"qid":30001,"description":"Why is it important to signal before you turn?","explanation":"Inform the cars behind you that you are going to make a turn. They may attempt to pass you on the inside if you do not indicate in time.","imageName":"","correctAnswer":29537,"isBookmarked":0,"aid":"29537#39242#39243#39244","text":"To discourage other drivers from trying to pass you#To encourage other drivers to pass you#To encourage other drivers to pull into your lane in front of you#To avoid using the four-way emergency flashers when you are driving very slowly"},
{"testId":730,"qid":30002,"description":"Which of the following will NOT stop people from driving while distracted?","explanation":"Before you begin your journey, you should customize your taxi, however eating and drinking should take place during rest breaks.","imageName":"","correctAnswer":29538,"isBookmarked":0,"aid":"17423#17424#17426#29538","text":"Preprogramming your favorite radio stations#Preplanning your route#Adjusting all your mirrors before you start#Pre-loosening the lid of your coffee cup"},
{"testId":730,"qid":30003,"description":"What should you do if you start to feel sleepy while driving?","explanation":"Sleeping is the only method to overcome sleep debt. Don't take any risks, and definitely don't attempt to fight nature with medications or tablets.","imageName":"","correctAnswer":29541,"isBookmarked":0,"aid":"29539#29540#29541#29542","text":"Open the windows to get some fresh air#Stop and get some exercise#Stop and sleep#Drink plenty of coffee"},
{"testId":730,"qid":30004,"description":"Why are warning placards required on vehicles transporting hazardous materials?","explanation":"If you are wounded in an accident, you may not be able to notify rescue personnel what kind of burden you are carrying. As a result, a vehicle transporting hazardous items must be prominently labeled as such. This will assist emergency personnel in correctly assessing the situation.","imageName":"","correctAnswer":29544,"isBookmarked":0,"aid":"17432#17434#29543#29544","text":"So traffic cameras can track you#To remind you to take extra care#To warn other drivers to keep their distance#To let emergency services know what they are dealing with in case of an accident"},
{"testId":730,"qid":30005,"description":"When _____, your vehicle has the shortest stopping distance.","explanation":"It may not be visible, but when the vehicle is completely loaded, the stopping distance is lowest. This is because the vehicle was built to perform best with a full load. When driving an empty or half full truck, keep in mind that stopping distances may be greater.","imageName":"","correctAnswer":29547,"isBookmarked":0,"aid":"21609#29545#29546#29547","text":"empty#two-thirds full#two-thirds empty#fully loaded"},
{"testId":730,"qid":30006,"description":"Where can the maximum weight load for a specific tyre pressure be found?","explanation":"Maximum weight loadings for various tire pressures should be written on the tire wall.","imageName":"","correctAnswer":29548,"isBookmarked":0,"aid":"17442#29548#29549#40501","text":"On your manifest#On the tire#On the dashboard#In the CDL manual"},
{"testId":730,"qid":30007,"description":"How long into your journey should you do your first cargo inspection?","explanation":"Discovering that your cargo was improperly secured might help you avoid a severe calamity. You must examine your cargo's security within the first 50 miles and then every 150 miles or three hours (whichever comes first) after that. [2.1.6- Trip Inspection; Section 2: Safe Driving; Illinois Commercial Drivers License Study Guide]","imageName":"","correctAnswer":17445,"isBookmarked":0,"aid":"17443#17444#17445#17446","text":"At your first rest stop#Within 25 miles#Within 50 miles#Within 100 miles"},
{"testId":730,"qid":30008,"description":"Which of the following should NOT worry you when you check your tires?","explanation":"It is not necessary for the tyres to be made by the same company as long as their sizes match, they are adequately inflated, and they are in excellent condition.","imageName":"","correctAnswer":17396,"isBookmarked":0,"aid":"17396#17447#17448#17449","text":"Tires from different manufacturers#Cuts in the tire wall#Canvas showing through the rubber#Low tire pressure"},
{"testId":730,"qid":30009,"description":"How should you inspect hydraulic brakes for leaks?","explanation":"When you press the brake pedal, you should feel resistance. Then hold the brake pedal for 5 seconds without experiencing any weakening or give.","imageName":"","correctAnswer":29553,"isBookmarked":0,"aid":"29550#29551#29552#29553","text":"Drive forward, then slam on the brakes hard#Depress the pedal and listen for leaks#Hold the brake pedal down for 5 seconds, then pump it 3 times#Pump the brake pedal 3 times, then hold it down for 5 seconds"},
{"testId":730,"qid":30010,"description":"If there are two lanes for making a left turn available to you, you should:","explanation":"Use the left turn lane on the right if there are two. This will give you more space to swing around the curve and allow you to view traffic on your left more easily.","imageName":"","correctAnswer":29555,"isBookmarked":0,"aid":"29554#29555#29556#29557","text":"use the one on the left#use the one on the right#use either#straddle the two lanes"},
{"testId":730,"qid":30011,"description":"Before proceeding to Step 2 (Check Engine Compartment) of the seven-step inspection, ensure that:","explanation":"Before doing anything physical with the vehicle, make sure that it can't move and endanger you or others.","imageName":"","correctAnswer":29558,"isBookmarked":0,"aid":"29558#29559#29560#29561","text":"the parking brakes are on and/or the wheels are chocked#the engine starts#all lights are working#the passenger door is locked"},
{"testId":730,"qid":30012,"description":"How do you figure out the distance between your car and the one in front of you?","explanation":"Count the seconds between when the car in front of you passes an item and when you arrive at that same object. This is the simplest and most accurate method for doing this computation.","imageName":"","correctAnswer":29562,"isBookmarked":0,"aid":"29562#29563#29564#29565","text":"Count the seconds from when the vehicle ahead of you passes some object to when you reach that same object#Judge it by instinct#Estimate the length of the vehicle in front, then estimate how many vehicle lengths there are between it and you#Use your odometer to measure a specific distance and divide it by the time it took you to reach there"},
{"testId":730,"qid":30013,"description":"You must be in the proper gear before descending a hill. For the reason that:","explanation":"When descending a slope, engine braking is required, which means you must be in a low gear before beginning downward. Shifting gears may become difficult or impossible once the wheels begin to accelerate.","imageName":"","correctAnswer":29569,"isBookmarked":0,"aid":"29566#29567#29568#29569","text":"it will help you speed up when you get to the bottom#it's illegal to change gears on a hill#it will help extend the life of the transmission#you won't be able to downshift after you start descending"},
{"testId":730,"qid":30014,"description":"How can I find out what another driver is doing?","explanation":"Drivers may forget to signal, but they almost never forget to watch where they're going. If you get near enough to another driver to monitor his or her head and body motions, you may be able to pick up on important hints regarding the driver's next move.","imageName":"","correctAnswer":29570,"isBookmarked":0,"aid":"29570#29571#29572#29573","text":"Watch the driver's head and body movements#Watch the speed of the driver's vehicle#Watch the rear lights of the driver's vehicle#Guess what you would do in that driver's situation"},
{"testId":730,"qid":30015,"description":"It is NOT a good idea to use your low-beam headlights:","explanation":"Low beams may be useful in low-light or low-visibility situations. When driving through areas of shade, you don't need them. In fact, switching them on and off may confuse or disturb other drivers.","imageName":"","correctAnswer":29577,"isBookmarked":0,"aid":"29574#29575#29576#29577","text":"at dawn#at dusk#in rain#when driving through tree shadows"},
{"testId":730,"qid":30016,"description":"Which side should you back up toward when backing up?","explanation":"When given the option, always return to the left side since you can see more on that side.","imageName":"","correctAnswer":29579,"isBookmarked":0,"aid":"17479#29578#29579#29580","text":"Either side#The right side#The left side#Neither side (go back straight)"},
{"testId":730,"qid":30017,"description":"How many times should you shift gears when crossing railroad lines?","explanation":"Shifting gears when crossing railroad lines is prohibited because it increases the probability of a stall. Before you start crossing, make sure you're in the appropriate gear.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"11498#11499#17482#17889","text":"Once#Twice#As many times as you think necessary#None"},
{"testId":730,"qid":30018,"description":"How frequently should you inspect your gauges (air pressure, temperature, and so on)?","explanation":"Your gauges are the next best method to keep track of the condition of your car after your senses. Examine them often.","imageName":"","correctAnswer":29581,"isBookmarked":0,"aid":"17483#17484#17485#29581","text":"At the beginning and end of each trip#At every rest stop#Every time the vehicle is stationary#As often as is practical"},
{"testId":730,"qid":30019,"description":"What should you do if you sense a hazard ahead?","explanation":"A danger is nothing more than a possible emergency. Don't act in a panic, but instead prepare ahead of time what you'll do if things worsen.","imageName":"","correctAnswer":29585,"isBookmarked":0,"aid":"29582#29583#29584#29585","text":"Speed up to pass it as quickly as possible#Come to a dead stop#Alert others by pointing and sounding your horn#Start planning what to do if the hazard becomes an emergency"},
{"testId":730,"qid":30020,"description":"What should you do if you can't get into the next gear when double clutching?","explanation":"If you're having trouble shifting into the next gear, don't attempt to push it. Return to neutral, let off of the clutch, raise the engine speed to meet the road pace, and try again.","imageName":"","correctAnswer":29589,"isBookmarked":0,"aid":"29586#29587#29588#29589","text":"Force the lever until you get into gear#Drop back to the previous gear#Rev the engine to jolt the transmission#Return to neutral, increase the engine speed to match road speed, and try again"},
{"testId":730,"qid":30021,"description":"What care should you take for conscious accident victims?","explanation":"If there is a risk of fire, explosion, or passing vehicles, do not transfer accident victims. Give them no food, beverages, or medicines, since this may aggravate their symptoms or injuries. If someone is bleeding profusely, attempt to stop it with pressure. Otherwise, just keep them warm until medical assistance comes.","imageName":"","correctAnswer":29591,"isBookmarked":0,"aid":"29590#29591#29592#29593","text":"Give them hot drinks#Keep them warm#Get them to walk around if they can#Give them painkillers"},
{"testId":730,"qid":30022,"description":"What is your most significant tool for checking the status of your vehicle when it is on the road?","explanation":"Learn all there is to know about your car so that you can spot any changes right away. To find issues, use your senses (look, listen, smell, and feel).","imageName":"","correctAnswer":29595,"isBookmarked":0,"aid":"17500#17501#29594#29595","text":"The truck manual#Comments from other truckers#The gauges#Your senses (eyesight, hearing, smell, feel)"},
{"testId":730,"qid":30023,"description":"There should be one tie-down for every ___ feet of cargo.","explanation":"One tie-down is required for every 10 feet of goods. However, every cargo, no matter how little, must have at least two tie-downs.","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5448#5449","text":"10#15#20#5"},
{"testId":730,"qid":30024,"description":"You should NOT use water to extinguish a ______ fire.","explanation":"Pouring water on burning gasoline will just spread the flames across a larger region. Instead, use a fire extinguisher rated at least class B (flammable liquids).","imageName":"","correctAnswer":13072,"isBookmarked":0,"aid":"13072#29596#29597#29598","text":"gasoline#tire#wood#paper"},
{"testId":730,"qid":30025,"description":"If there is snow or ice on the road, you should:","explanation":"Excessive acceleration or braking, particularly in snow or ice, may induce wheelspin and skids. This may be avoided with careful, slow, and mild acceleration.","imageName":"","correctAnswer":29599,"isBookmarked":0,"aid":"29599#29600#29601#29602","text":"accelerate very gradually and slowly#quickly accelerate to your desired speed#let the vehicle start to slide before applying the accelerator#keep the brake partially depressed as you pull away"},
{"testId":730,"qid":30026,"description":"When approaching on- and off-ramps, keep in mind:","explanation":"The posted speed restrictions are a guideline, not a requirement. Take on- and off-ramps at speeds substantially below legal limits, particularly if your vehicle or cargo has a reasonably high center of gravity. (In fact, tests have proven that tankers may roll over near curve speed limits.)","imageName":"","correctAnswer":29606,"isBookmarked":0,"aid":"29603#29604#29605#29606","text":"to maintain your highway speed#to brake sharply#that the length of the ramp may be too short#that the posted speed limit may be too high for your vehicle"},
{"testId":730,"qid":30027,"description":"Before a travel, both federal and state law require _________ to examine a commercial motor vehicle.","explanation":"You are solely responsible for your vehicle's safety and roadworthiness.","imageName":"","correctAnswer":3406,"isBookmarked":0,"aid":"3406#20657#29607#29608","text":"the driver#the owner#a qualified engineer#the driver and a qualified engineer"},
{"testId":730,"qid":30028,"description":"Why should you keep your vehicle in the center of your lane?","explanation":"Many roads feature drop-offs (sharp elevation changes) at their edges. These might cause steering problems or tip your car to the side of the road, where it may collide with overhanging trees or wires. In fact, research has shown that a 2 inch dip in the pavement may cause a motorist to lose control while attempting to return to the middle of the lane. Allow enough room from the road's edge to avoid slipping into a drop-off.","imageName":"","correctAnswer":17522,"isBookmarked":0,"aid":"17522#29609#29610#29611","text":"To avoid drop-offs#To keep other vehicles from passing you#To let other drivers get the best view of you#To leave the most space for a turn"},
{"testId":730,"qid":30029,"description":"Which of following substances has the potential to damage your ability to drive safely?","explanation":"Some over-the-counter and prescription cold medicines can make you sleepy. Check the label every time. Ask your pharmacist or doctor before taking them if you are still undecided.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17526#29612#31813","text":"All of the above#Some cold medicines#Alcoholic drinks#Some prescription medications"},
{"testId":730,"qid":30030,"description":"Downshifting is advised before:","explanation":"You may use engine braking by downshifting before beginning down a slope. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve will slow you down and provide you with the necessary power to accelerate out of the curve.","imageName":"","correctAnswer":29617,"isBookmarked":0,"aid":"29616#29617#29618#29619","text":"turning at a junction#entering a curve or downgrade#approaching a bridge#approaching a tunnel"},
{"testId":730,"qid":30031,"description":"How frequently should you examine your tires in extreme heat?","explanation":"If you can't touch your tires with your bare hands, they're too hot to drive safely on. Allow them to cool down, which will naturally return them to their proper pressure. Don't be tempted to let air out since the pressure will drop too low once the tires cool.","imageName":"","correctAnswer":29621,"isBookmarked":0,"aid":"29620#29621#29622#29623","text":"Every hour or every 50 miles#Every 2 hours or every 100 miles#Every 3 hours or every 150 miles#Every 4 hours or every 200 miles"},
{"testId":730,"qid":30032,"description":"What will be the clock positions of your hand on the steering wheel?","explanation":"Keeping your hands opposite each other on the steering wheel allows you to maintain control if anything, such as a pothole, attempts to yank the wheel from your grip.","imageName":"","correctAnswer":17538,"isBookmarked":0,"aid":"17537#17538#17539#17540","text":"4 and 8#3 and 9#10 and 2#11 and 1"},
{"testId":731,"qid":30034,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":731,"qid":30035,"description":"If you are stopped by the police and refuse to submit to a drug or alcohol test,:","explanation":"If you refuse to submit to drug or alcohol testing, your CDL will be suspended for at least one year unless you seek a hearing and successfully explain your case.","imageName":"","correctAnswer":36545,"isBookmarked":0,"aid":"36545#36546#36547#36548","text":"you will lose your CDL for at least one year#the police will let you off with a warning#you will receive a summons in the mail#the police will follow you for a mile to check whether you're able to drive safely"},
{"testId":731,"qid":30036,"description":"Which of the following is the best way to use the brake pedal when going down a steep hill?","explanation":"The best way to descend is to analyze the slope and circumstances and choose a safe pace. Once you've reached this speed, slowly brake until you're travelling 5 miles per hour slower, then resume your normal pace. Brake again when you reach your safe speed, and so on. This is much less likely to overheat your brakes than attempting to maintain a constant pace with the brakes always half-on.","imageName":"202007171658332757.jpg","correctAnswer":21182,"isBookmarked":0,"aid":"21182#21183#21184#36515","text":"Release the brake when you are 5 mph below your safe speed and let your speed come back up to your safe speed. Then repeat braking to 5 mph below your safe speed#Apply stronger pressure as the vehicle goes downhill#Apply light, steady pressure#Brake harder when you are 15 mph above your safe speed"},
{"testId":731,"qid":30037,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Texas Commercial Motor Vehicle Drivers Handbook suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":731,"qid":30038,"description":null,"explanation":null,"imageName":"","correctAnswer":36551,"isBookmarked":0,"aid":"36549#36550#36551#36552","text":"about 100 yards#about 100 feet#about one block#about 3 blocks"},
{"testId":731,"qid":30039,"description":"Driving at 55 mph rather than 65 mph might increase your fuel economy by :","explanation":"Today's tractor-trailers get roughly 6.5 miles per gallon of diesel fuel. Driving at 55 mph instead of 65 mph may boost fuel efficiency by up to one mile per gallon. That comes up to nearly two gallons of gasoline saved for every 100 miles driven. That may add up to big savings over the course of a year of driving.","imageName":"","correctAnswer":36553,"isBookmarked":0,"aid":"36553#36554#36555#36556","text":"a mile per gallon#a gallon every 100 miles#a gallon per trip#20 gallons per year"},
{"testId":731,"qid":30040,"description":"In Texas, a vehicle or trailer's mud flaps must be:","explanation":"There are currently no federal rules governing the usage of splash guards (mud flaps). Each state is allowed to make its own rules. In Texas, mud flaps on a vehicle or trailer must be 8 inches from the road.","imageName":"","correctAnswer":36558,"isBookmarked":0,"aid":"36557#36558#36559#36560","text":"within 12 inches of the roadway#within 8 inches of the roadway#within 6 inches of the roadway#within 4 inches of the roadway"},
{"testId":731,"qid":30041,"description":"Which of the following must stop entirely at a rural railroad crossing in Texas?","explanation":"Heavy equipment (such as bulldozers, steam shovels, and steamrollers) and vehicles carrying explosives must stop at all railroad crossings in Texas. School buses and transit or coach buses must stop at all railroad crossings outside of commercial or residential zones while transporting passengers. Learn if your kind of vehicle is required to stop at railroad crossings. A violation may result in a 60-day disqualification.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14242#17571#36561","text":"All of the above#School buses#Trucks carrying explosives#Heavy equipment (e.g., steamrollers)"},
{"testId":731,"qid":30042,"description":"Inside the cab, what should you perform during your pre-trip inspection?","explanation":"Step 6 of the seven-step pre-trip check includes getting in the cab and making sure it's ready. Turn off any superfluous lights, double-check your paperwork, and secure any loose things. Then fire up your engine.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#36467#36468#36537","text":"Do all of the above#Check that you have all your required papers#Secure all loose items#Turn off unnecessary lights"},
{"testId":731,"qid":30043,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#36562","text":"Four times as much#Three times as much#Twice as much#50 percent more"},
{"testId":731,"qid":30045,"description":"How often must you stop after your initial stop to ensure the safety of your cargo?","explanation":"While the laws differ by jurisdiction, the Texas Commercial Motor Vehicle Drivers Handbook specifies that you should examine your cargo during the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":18733,"isBookmarked":0,"aid":"18733#19148#36563#36564","text":"Every 3 hours or 150 miles#Every hour or 50 miles#Every 2 hours or 100 miles#Every 4 hours or 200 miles"},
{"testId":731,"qid":30046,"description":"Rust streaks on a lug nut mean that:","explanation":"Shiny threads, streaks, or trails of rust indicate a loose lug nut. If so, tighten the lug nut to the required tension for your car using a manual torque wrench.","imageName":"","correctAnswer":36566,"isBookmarked":0,"aid":"36565#36566#36567#36568","text":"it's in danger of shearing#it's loose#it's frozen or stuck#it's all right"},
{"testId":731,"qid":30047,"description":"Keep in mind when passing a motorcycle that:","explanation":"Depending on the relative speeds of the vehicles, your huge truck exerts air suction that may draw the motorbike two or three feet toward you. As a result, always allow at least six feet between your car and the motorbike.","imageName":"","correctAnswer":36571,"isBookmarked":0,"aid":"36569#36570#36571#36572","text":"it may be able to accelerate much faster than you can#motorcyclists can be reckless#your vehicle can drag a motorcycle off course#it can swerve more sharply than a car"},
{"testId":731,"qid":30048,"description":"In Texas, you cannot ______ while driving on a downgrade.","explanation":null,"imageName":"","correctAnswer":36575,"isBookmarked":0,"aid":"36573#36574#36575#36576","text":"cross a broken line#drive faster than 10 mph below the posted speed limit#coast downhill#sound your horn at a curve"},
{"testId":731,"qid":30050,"description":"When you start the engine, the oil pressure should return to normal within:","explanation":"When the engine starts, the oil pressure should quickly return to normal. If it does not, immediately turn off the engine and check the oil level. Running the engine without oil can quickly harm it.","imageName":"","correctAnswer":36577,"isBookmarked":0,"aid":"36577#36578#36579#36580","text":"seconds#minutes#a mile鈥檚 drive#the first hour"},
{"testId":731,"qid":30051,"description":"If you are pulling another vehicle, the tow link must be no more than :","explanation":"When one vehicle is pulling another, the tow link between the two vehicles cannot be more than 15 feet long.","imageName":"","correctAnswer":14177,"isBookmarked":0,"aid":"14176#14177#14178#36581","text":"10 feet#15 feet#20 feet#25 feet"},
{"testId":731,"qid":30052,"description":"What should you do if you're towing a trailer and a fire breaks out inside?","explanation":"If a fire breaks out inside a trailer, drive over to the side of the road and park in an open place away from anything that may catch fire. Keep the trailer doors shut. By opening the doors, additional oxygen would enter the fire, allowing it to spread.","imageName":"","correctAnswer":36583,"isBookmarked":0,"aid":"36582#36583#36584#36585","text":"Keep driving until you reach help#Keep the trailer's doors closed#Put the fire out with a fire extinguisher#Ask bystanders for help"},
{"testId":731,"qid":30053,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":null,"imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":731,"qid":30054,"description":"If you are passing through an underpass where the road has recently been repaved,:","explanation":"Don't take the vertical clearances displayed on bridge and overpass signage at face value. Since the notices were placed, repaving or compacted snow may have lowered the clearances.","imageName":"","correctAnswer":36589,"isBookmarked":0,"aid":"36586#36587#36588#36589","text":"you can drive through it faster than you used to#you should slow down to check the new road surface#the repaving won't make any difference#the vertical clearance may be less than it used to be"},
{"testId":731,"qid":30058,"description":"A bridge formula limits the axle weight for axles that _____ to prevent overloading bridges.","explanation":"The axle weight is the most weight that a single axle can sustain. When the axles of a vehicle are close together, a large axle weight concerns overloading bridges and highways. A bridge formula is often used to set a lower maximum axle weight for axles that are closer together to prevent overloading bridges. The US Department of Transportation use a bridge calculation of this kind to determine the maximum axle weights on interstate routes.","imageName":"","correctAnswer":36592,"isBookmarked":0,"aid":"36590#36591#36592#36593","text":"are farther apart#are in need of repair#are closer together#don鈥檛 have dual tires"},
{"testId":731,"qid":30060,"description":"If your cargo extends more than 4 feet beyond the rear of your vehicle in Texas, you must have:","explanation":"If your cargo extends beyond the back of your vehicle by more than four feet, you must perform the following: (1) Obtain a special permission from the Permit Office of the Texas Department of Transportation. (2) When traveling during the day, attach a 12-inch-square red flag to the end of your cargo. (3) When traveling at night, attach a red light visible for 500 feet to the end of your cargo. There are, however, a few exceptions to these rules. For further information, see sections 14.3 and 14.4 of the Texas Commercial Motor Vehicle Drivers Handbook.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#36594#36595#36596","text":"All of the above#a red flag on the end of the load in the daytime#a red light on the end of the load at night#a special permit"},
{"testId":731,"qid":30061,"description":"If you are caught driving a commercial vehicle with a blood alcohol content (BAC) that is greater than zero but less than 0.04 percent, you will be arrested.","explanation":"You will risk a penalty if you are detected operating a commercial motor vehicle with any level of alcohol in your blood. You will be ordered out of duty for 24 hours if your blood alcohol content (BAC) is less than 0.04 percent. If this is your first violation and you are convicted of having a BAC of 0.04 percent or more, you will lose your CDL for at least one year.","imageName":"","correctAnswer":36599,"isBookmarked":0,"aid":"36597#36598#36599#36600","text":"you will be allowed to keep driving#you will have to pay a small fine if you're convicted#you will be ordered out of service for 24 hours#you will be arrested"},
{"testId":731,"qid":30063,"description":"Which of the following cannot be transported in an open truck in Texas?","explanation":"In Texas, you may not carry any loose material that has the potential to blow or spill unless (a) the truck bed is entirely enclosed on all sides and (b) the cargo is totally enclosed or the top of the load is covered to prevent any of it from blowing or spilling.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17642#17643#36601","text":"All of the above#Gravel#Sand#Wood chips"},
{"testId":731,"qid":30064,"description":null,"explanation":"You must be a legal resident of Texas to receive a Texas CDL. According to 49 CFR 383.21, you may only have one CDL at a time. If you move to Texas from another state where you obtained a CDL, you must exchange it for a Texas CDL within 90 days of becoming a legal resident of Texas.","imageName":"","correctAnswer":10546,"isBookmarked":0,"aid":"10544#10545#10546#10547","text":"Three#Four#One#Two"},
{"testId":731,"qid":30065,"description":"What are the two most typical reasons for tire fires?","explanation":"Overheated tires may catch fire. Underinflation causes the tire to flex more, which raises tire temperature. Due to friction, dual tires that come into contact with each other or other elements of the vehicle might overheat.","imageName":"","correctAnswer":36602,"isBookmarked":0,"aid":"17647#36602#36603#36604","text":"Mismatched tires and loose lug nuts#Underinflation and dual tires that touch#Overinflation and dual tires that touch#Loose lug nuts and dual tires that touch"},
{"testId":779,"qid":30065,"description":"What are the two most typical reasons for tire fires?","explanation":"Overheated tires may catch fire. Underinflation causes the tire to flex more, which raises tire temperature. Due to friction, dual tires that come into contact with each other or other elements of the vehicle might overheat.","imageName":"","correctAnswer":36602,"isBookmarked":0,"aid":"17647#36602#36603#36604","text":"Mismatched tires and loose lug nuts#Underinflation and dual tires that touch#Overinflation and dual tires that touch#Loose lug nuts and dual tires that touch"},
{"testId":731,"qid":30067,"description":"What can you do to assist an accident victim who is bleeding profusely and there is no competent expert available?","explanation":"If no competent specialists are on the scene yet, do everything you can to assist the wounded until they arrive. Apply direct pressure on the wound until the bleeding stops to halt excessive bleeding. If required, use gauze from a first-aid kit, a piece of clothing, or even your hand.","imageName":"","correctAnswer":36608,"isBookmarked":0,"aid":"36605#36606#36607#36608","text":"Nothing; wait for qualified professionals to arrive#Wind a tourniquet above the wound#Try to bandage the wound#Apply direct pressure to the wound"},
{"testId":731,"qid":30068,"description":"What type of warning devices should you use in Texas if you have to stop on the road or the shoulder of the road during the day?","explanation":null,"imageName":"","correctAnswer":36609,"isBookmarked":0,"aid":"11639#36609#36610#36611","text":"Nothing#Red flags#Flares, fusees, or reflective triangles#Your vehicle's four-way emergency flashers"},
{"testId":731,"qid":30072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#36612","text":"Coffee#Fresh air#Time#Water"},
{"testId":731,"qid":30073,"description":"How many reflective warning triangles should you keep on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid flares, or six fusees must be included in your vehicle's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":731,"qid":30074,"description":"What should you do if you have to go off the road onto the shoulder to prevent a head-on collision with another vehicle?","explanation":"If you must drive off the road onto the shoulder, take numerous precautions to ensure your safety on the looser shoulder surface. Allow your car to slow down; do not brake until your speed is less than 20 mph, and then only softly. Keep one pair of your wheels on the ground. If at all possible, wait until your car has stopped before returning to the road.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#36613#36614#36615","text":"Do all of the above#Avoid braking#Keep one set of wheels on the pavement#Stay on the shoulder until you've stopped"},
{"testId":731,"qid":30075,"description":"In which of the following scenarios is it inappropriate to use your horn?","explanation":"Use your horn to alert others of potential danger. Aggressive driving is characterized by the use of the horn to indicate irritation or rage.","imageName":"","correctAnswer":36616,"isBookmarked":0,"aid":"17680#17682#17683#36616","text":"To alert another driver to your presence#To alert another driver to a hazard#To warn a pedestrian who may not have seen you#To express your annoyance at someone"},
{"testId":779,"qid":30075,"description":"In which of the following scenarios is it inappropriate to use your horn?","explanation":"Use your horn to alert others of potential danger. Aggressive driving is characterized by the use of the horn to indicate irritation or rage.","imageName":"","correctAnswer":36616,"isBookmarked":0,"aid":"17680#17682#17683#36616","text":"To alert another driver to your presence#To alert another driver to a hazard#To warn a pedestrian who may not have seen you#To express your annoyance at someone"},
{"testId":731,"qid":30076,"description":"What should you do if strong fog decreases vision to near zero?","explanation":"Accidents are often caused by fog. In 2016, there were nearly 2000 fog-related collisions in Texas, with 45 fatalities. Slow down, use your low-beam headlights and fog lights, and drive with utmost care while driving in fog. If visibility is approaching nil, pull off the road into a truck stop or rest area and wait until visibility improves. Remember, you can't drive if you can't see. [Texas Department of Transportation, \"Weather Conditions for Crashes,\" http://ftp.dot.state.tx.us/pub/txdot-info/trf/crash statistics/2016/23 .pdf]","imageName":"","correctAnswer":36617,"isBookmarked":0,"aid":"36617#36618#36619#36620","text":"Pull off the road and stop#Speed up to get through it quicker#Slow down and use your high beams#Slow down and use your low beams and fog lights"},
{"testId":731,"qid":30077,"description":"In Texas, a single vehicle or a combination vehicle can't weigh more than _______, including its cargo.","explanation":"Unless you get an oversized/overweight permission from the Texas Department of Transportation Permit Office, the maximum weight for a single or combination vehicle plus its cargo in Texas is 80,000 pounds. There are additional size and axle weight restrictions that must be followed. For further information, contact the Texas Department of Motor Vehicles. Texas Department of Motor Vehicles, \"Texas Size and Weight Limits,\" http://txdmv.gov/motor-carriers/oversize-overweight-permits/texas-size-weight-limits/32-motor-carriers/main-menu","imageName":"","correctAnswer":36624,"isBookmarked":0,"aid":"36621#36622#36623#36624","text":"50,000 pounds#60,000 pounds#70,000 pounds#80,000 pounds"},
{"testId":731,"qid":30078,"description":"A B:C grade fire extinguisher is appropriate for:","explanation":"A B:C fire extinguisher is electrically non-conductive and suited for a class B fire (which covers practically all volatile liquids) (hence its C rating). It should not, however, be used on burning items that can be cleaned with plain water, such as wood, paper, and fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":36625,"isBookmarked":0,"aid":"36625#36626#36627#36628","text":"electrical fires and burning liquids#wood fires and paper fires#tire fires#cloth fires and wood fires"},
{"testId":731,"qid":30080,"description":"Objects seen in a curved (convex) mirror may appear:","explanation":"Curved (convex) mirrors provide a broader field of vision, but also distort things, making them look smaller and further away than they are. Keep this in mind while calculating distances.","imageName":"","correctAnswer":36632,"isBookmarked":0,"aid":"36629#36630#36631#36632","text":"closer than they really are#larger than they really are#faster than they really are#farther away than they really are"},
{"testId":731,"qid":30081,"description":"How many tie-downs are needed to secure a 17-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 17 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":731,"qid":30083,"description":"To earn a CDL, you must certify that:","explanation":"Certain medical issues might impair your ability to properly operate commercial motor vehicles (CMVs). You may be regarded medically unqualified to operate CMVs under federal rule 49 CFR 391.41 if you have specific medical disorders like as drug or alcohol addiction, cardiovascular disease, epilepsy, insulin-dependent diabetic mellitus, psychological illness, and so on. An exemption (medical variation) may be granted if you can demonstrate that your medical condition will not have a major impact on your ability to operate CMVs. For further information, contact the Federal Motor Carrier Safety Administration.","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24643#36633#36634#36635","text":"all of the above are true#you are not addicted to alcohol#you are not addicted to drugs#you are not an insulin-dependent diabetic"},
{"testId":767,"qid":30084,"description":"Which of the following wheel or rim issues is potentially dangerous?","explanation":"When inspecting the wheels and rims, look for missing clamps, spacers, studs, or lugs, mismatched, bent, or cracked lock rings, and welding repairs. Any of these issues might be harmful.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17710#17711#17712","text":"All of the above#Bent lock rings#Missing clamps#Welding repairs"},
{"testId":769,"qid":30084,"description":"Which of the following wheel or rim issues is potentially dangerous?","explanation":"When inspecting the wheels and rims, look for missing clamps, spacers, studs, or lugs, mismatched, bent, or cracked lock rings, and welding repairs. Any of these issues might be harmful.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17710#17711#17712","text":"All of the above#Bent lock rings#Missing clamps#Welding repairs"},
{"testId":767,"qid":30085,"description":"Why should you consistently do your vehicle inspection tasks in the same order?","explanation":"You should complete a car inspection in the same manner each time so that you can learn all of the stages and are less likely to forget anything.","imageName":"","correctAnswer":36397,"isBookmarked":0,"aid":"17713#36396#36397#36398","text":"Because it's the law#Because it will go faster#Because you鈥檒l be less likely to forget something#Because it's written in that order in the manual"},
{"testId":767,"qid":30086,"description":"You hear a noise that sounds like a tire blowout while operating a vehicle with twin tires. However, the handling of your vehicle seems unaltered. What ought you to do?","explanation":null,"imageName":"","correctAnswer":36401,"isBookmarked":0,"aid":"36399#36400#36401#36402","text":"Keep driving as usual#Check your tires at the next stop#Stop and check your tires now#Look in your mirrors to try to spot trouble"},
{"testId":767,"qid":30091,"description":"Why should you look underneath the vehicle during a vehicle inspection?","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":36404,"isBookmarked":0,"aid":"17737#17739#36403#36404","text":"To check for broken bottles#To measure your clearance#To check for live animals#To check for leaks"},
{"testId":767,"qid":30094,"description":"To drive a single vehicle with a gross vehicle weight rating (GVWR) of _____, you must have a CDL.","explanation":"To operate a single vehicle with a gross vehicle weight rating (GVWR) of 26,001 pounds or greater, you must have a CDL. If the GVWR of the cars being towed exceeds 10,000 pounds, you must also have a CDL to operate a combination vehicle with a GCWR of 26,001 pounds or more.","imageName":"","correctAnswer":36407,"isBookmarked":0,"aid":"36405#36406#36407#36408","text":"20,001 pounds or more#23,001 pounds or more#26,001 pounds or more#30,001 pounds or more"},
{"testId":767,"qid":30096,"description":"You need to back into a warehouse, but you can't back toward the driver's side. What should you do?","explanation":"Even if you have to walk around the block to get your car in this position, you should always reverse toward the driver's side. Backing toward the driver's side allows you to maintain an eye on the vehicle's rear from your side window.","imageName":"","correctAnswer":36410,"isBookmarked":0,"aid":"36409#36410#36411#36412","text":"Back in anyway#Go around the block to get into the right position#Refuse to enter the warehouse#Drive forward into the warehouse"},
{"testId":767,"qid":30097,"description":"If your brakes become damp, they may :","explanation":"If your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be harmful. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#36413","text":"Do all of the above#apply unevenly#fall off the vehicle#start to rust"},
{"testId":769,"qid":30097,"description":"If your brakes become damp, they may :","explanation":"If your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be harmful. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#36413","text":"Do all of the above#apply unevenly#fall off the vehicle#start to rust"},
{"testId":767,"qid":30098,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#36414","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#\"Turn left.\""},
{"testId":767,"qid":30099,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"2#18967#18968#36196","text":"5 mph#one-quarter#one-third#10 percent"},
{"testId":767,"qid":30102,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#36415","text":"Coffee#Fresh air#Time#Lots of water"},
{"testId":731,"qid":30107,"description":"Why should you be extra cautious around tourist drivers?","explanation":"Drivers who are disoriented pose a risk. They often abruptly shift course or halt without notice. Tourists who are unfamiliar with the region might be quite dangerous. Tourists are led astray by car-top baggage and out-of-state registration plates. When driving near such cars, use extreme care.","imageName":"","correctAnswer":36417,"isBookmarked":0,"aid":"36416#36417#36418#36419","text":"Because they aren't as smart as local drivers#Because they may be unfamiliar with the area#Because they won't know the local radio stations#Because they are likely to be tired"},
{"testId":767,"qid":30107,"description":"Why should you be extra cautious around tourist drivers?","explanation":"Drivers who are disoriented pose a risk. They often abruptly shift course or halt without notice. Tourists who are unfamiliar with the region might be quite dangerous. Tourists are led astray by car-top baggage and out-of-state registration plates. When driving near such cars, use extreme care.","imageName":"","correctAnswer":36417,"isBookmarked":0,"aid":"36416#36417#36418#36419","text":"Because they aren't as smart as local drivers#Because they may be unfamiliar with the area#Because they won't know the local radio stations#Because they are likely to be tired"},
{"testId":767,"qid":30111,"description":"When should you reconsider hauling your vehicle's full legal load?","explanation":"A vehicle that is highly loaded will have a longer stopping distance and will be more difficult to control on downgrades. As a result, if you must travel in inclement weather or in the mountains, keep the load substantially below the maximum weight restriction.","imageName":"","correctAnswer":36423,"isBookmarked":0,"aid":"36420#36421#36422#36423","text":"If you need to get the trip done sooner#If you're worried about theft#If you need to save fuel#If you're traveling in the mountains"},
{"testId":767,"qid":30112,"description":"During a vehicle inspection, when you check the brakes, you should look for:","explanation":"Brakes are vital, so properly inspect them. Check for cracked drums, oil, grease, or braking fluid on shoes or pads, or shoes that are dangerously thin, missing, or broken.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#36424#36425#36426","text":"All of the above#cracked drums#shoes or pads with fluids on them#worn or broken shoes"},
{"testId":767,"qid":30115,"description":"You've delivered your load and are currently returning on the same road with your empty truck. What do you need to be aware of?","explanation":"Your truck's height may have increased dramatically now that it is empty. You may be unable to pass through an underpass that you were able to pass through when your vehicle was fully loaded. Please keep in mind that the vertical clearance indications located at underpasses are not always exact. Since the signs were placed, repaving or compacted snow may have lowered the clearances.","imageName":"","correctAnswer":36430,"isBookmarked":0,"aid":"36427#36428#36429#36430","text":"The sun will be in a different position#The wind may be coming from a different direction#Speed cameras will be pointing in a different direction#Your truck may be taller"},
{"testId":767,"qid":30118,"description":"Driving at 55 mph in good driving conditions results in a total stopping distance of:","explanation":"Your overall stopping distance at 55 mph in ideal driving circumstances will be at least 419 feet: 142 feet for perception distance, 61 feet for response distance, and 216 feet for braking. (A football field is 360 feet long when the two end zones are included.) The total stopping distance can potentially be greater under bad driving conditions.","imageName":"","correctAnswer":36433,"isBookmarked":0,"aid":"33262#36431#36432#36433","text":"at least 300 feet#at least 236 feet#at least 353 feet#at least 419 feet"},
{"testId":769,"qid":30118,"description":"Driving at 55 mph in good driving conditions results in a total stopping distance of:","explanation":"Your overall stopping distance at 55 mph in ideal driving circumstances will be at least 419 feet: 142 feet for perception distance, 61 feet for response distance, and 216 feet for braking. (A football field is 360 feet long when the two end zones are included.) The total stopping distance can potentially be greater under bad driving conditions.","imageName":"","correctAnswer":36433,"isBookmarked":0,"aid":"33262#36431#36432#36433","text":"at least 300 feet#at least 236 feet#at least 353 feet#at least 419 feet"},
{"testId":767,"qid":30120,"description":"What is the most likely thing to start a tire fire?","explanation":"Underinflated tires and dual tires that contact each other or portions of the car are common sources of tire fires.","imageName":"","correctAnswer":36434,"isBookmarked":0,"aid":"17731#17839#17840#36434","text":"Driving over the speed limit#Different makes of tires on different wheels#Excessive braking#Dual tires that touch"},
{"testId":769,"qid":30120,"description":"What is the most likely thing to start a tire fire?","explanation":"Underinflated tires and dual tires that contact each other or portions of the car are common sources of tire fires.","imageName":"","correctAnswer":36434,"isBookmarked":0,"aid":"17731#17839#17840#36434","text":"Driving over the speed limit#Different makes of tires on different wheels#Excessive braking#Dual tires that touch"},
{"testId":767,"qid":30121,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#36435","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#the key could get lost"},
{"testId":767,"qid":30123,"description":"Which of the following obstacles can be driven over instead of avoided?","explanation":"You have no idea what may be inside an apparently harmless-looking item on the road. Even cardboard boxes, paper or cloth bags, or plastic containers may hold a solid or hefty item that might cause harm. Always scan the roads at least 12 to 15 seconds ahead to detect such obstructions in time to safely avoid them.","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17850#17851#17852","text":"None of the above#Cardboard boxes#Paper sacks#Plastic containers"},
{"testId":767,"qid":30124,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of another vehicle, the Oklahoma Commercial Driver License Manual suggests that you lower your headlights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#17251#18694#18695","text":"Emergency flashers#Interior lights#Low beams#High beams"},
{"testId":767,"qid":30126,"description":"Which of the following beverages has the most alcohol?","explanation":"The same quantity of alcohol may be found in a 12-ounce can of 5% beer, a 5-ounce glass of 12% wine, or a 1.5-ounce shot of 80-proof whiskey. After two such drinks, an average 180-pound guy will have a blood alcohol level (BAC) of 0.04 percent before his body begins to break down the alcohol (at a rate of 0.01 percent  every 40 minutes). Driving with a BAC of 0.04 percent or above is banned for a CDL holder.","imageName":"","correctAnswer":36439,"isBookmarked":0,"aid":"36436#36437#36438#36439","text":"A 12-ounce glass of 5% beer#A 5-ounce glass of 12% wine#A 1.5-ounce shot of 80-proof liquor#None (i.e., they all contain the same amount of alcohol)"},
{"testId":767,"qid":30128,"description":"What do you look for when you check the wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":17870,"isBookmarked":0,"aid":"17867#17868#17869#17870","text":"Gasoline#Dirt#Discoloration#Leaks"},
{"testId":767,"qid":30129,"description":"Which of the following are some of the most common causes of truck fires?","explanation":"A car fire may be started by a driver smoking, underinflated tires, dual tires that contact, electrical short circuits, and spilt gasoline after an accident.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17871#17872#17873","text":"All of the above#Driver smoking#Underinflated tires#Electrical short circuits"},
{"testId":767,"qid":30132,"description":"Why should a post-trip inspection report be completed?","explanation":"Each vehicle you operate should be inspected after each journey. Make a note of any safety issues you discovered while driving the car, as well as any repairs you feel are required. The inspection report will inform the next driver if there are any safety issues. It will also notify the motor carrier if any safety-related repairs are required. According to federal rules, an inspection report must be prepared before the conclusion of the day's usage of the vehicle. [49 CFR 396 .11]","imageName":"","correctAnswer":36441,"isBookmarked":0,"aid":"17883#36440#36441#36442","text":"To prove you finished your trip#So that you can't be blamed for any subsequent damage#To alert others to safety problems requiring repair#To impress your boss"},
{"testId":767,"qid":30133,"description":"What should you do first if you see a hazard ahead?","explanation":null,"imageName":"","correctAnswer":36444,"isBookmarked":0,"aid":"14201#36443#36444#36445","text":"Call emergency services#Come to a complete stop as soon as it is safe to do so#Make a contingency plan#Do nothing until you get closer to it"},
{"testId":767,"qid":30134,"description":"How many beers can you safely have during a rest break throughout your journey?","explanation":"Never drink and drive. You may be arrested as a commercial driver if you have a blood alcohol content (BAC) of 0.04 percent or higher. Even if you don't achieve this threshold after only one beer, you might be suspended for 24 hours if there is any measurable quantity of alcohol in your blood.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"10544#10546#10547#17889","text":"Three#One#Two#None"},
{"testId":763,"qid":30135,"description":"What should you check when you inspect each tire?","explanation":"Tires are essential. Examine the tires for any signs of wear (inadequate tread depth or fabric showing through the sidewalls), fractured valve stems, and cuts or other damage. Section 2.1.3 of the New York State Commercial Driver's Manual has a comprehensive list of inspections to do.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#36163#36164#36165","text":"All of the above#If you can see any fabric through the sidewall#If the valve stem is cracked#If there are any cuts in the tire"},
{"testId":763,"qid":30136,"description":"What colors do your side marker lights need to be?","explanation":"Your back side marker lights are red, while the rest are amber.","imageName":"","correctAnswer":17897,"isBookmarked":0,"aid":"17894#17895#17896#17897","text":"All amber#All red#Red at front, others amber#Red at rear, others amber"},
{"testId":763,"qid":30137,"description":"If you are found guilty of violating the railroad grade crossing regulations twice within a period of three years, you are supposed to receive:","explanation":"Violations at railroad grade crossings include failing to stop as a train approaches and failing to observe traffic-control signals. If you are guilty of a railroad grade crossing infraction, you will be barred from commercial driving for 60 days for the first offense, 120 days for the second offense within three years, and one year for the third offense within three years.","imageName":"","correctAnswer":36167,"isBookmarked":0,"aid":"36166#36167#36168#36169","text":"a $400 fine#a 120-day disqualification#a 60-day disqualification#a one-year disqualification"},
{"testId":763,"qid":30139,"description":"When driving a highly loaded vehicle on __________, pay close attention to the space beneath your vehicle.","explanation":"When your car is fully loaded, there may not be much room below it. This is often an issue on dirt roads and in unpaved yards. Don't risk being disconnected. When driving a low-slung vehicle, such as a lowboy or moving van, through an elevated railroad grade crossing, it is also possible to get trapped.","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#13758#36170#36171","text":"Any of the above#a dirt road#an unpaved yard#a railroad grade crossing"},
{"testId":763,"qid":30140,"description":"You will lose your CDL for at least one year, if you are found guilty of the following violation for the first time:","explanation":"If you are convicted of one of these significant offenses, you will lose your CDL for at least a year if it is your first offense. (If you were driving a hazardous materials placarded CMV at the time of the crime, you will lose your CDL for at least three years.) If you are convicted a second time, you will lose your CDL for the rest of your life.","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#36172#36173#36174","text":"Any of the above#refusing to undergo blood alcohol testing#committing a felony involving the use of a vehicle#driving a vehicle under the influence of a controlled substance"},
{"testId":763,"qid":30142,"description":"When should you use the low range on your vehicle's automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has an automatic gearbox, set it to a low range to prevent the transmission from moving up beyond the preset gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":17916,"isBookmarked":0,"aid":"17914#17916#36175#36176","text":"Before entering a curve#Before entering a downgrade#When approaching an intersection#Before entering an upgrade"},
{"testId":763,"qid":30145,"description":"On a 17-foot load, you have two tiedowns. How many more tiedowns are you going to need?","explanation":"You should have one tiedown for every 10 feet of cargo and at least two regardless of length. So two tiedowns are plenty for a 17-foot load.","imageName":"","correctAnswer":17889,"isBookmarked":0,"aid":"10544#10546#10547#17889","text":"Three#One#Two#None"},
{"testId":763,"qid":30146,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the New York State Commercial Driver's Manual advises that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":763,"qid":30147,"description":"To put out a fire with a fire extinguisher, you should:","explanation":"In a fire, your first concern should be your own and the safety of others. To use a fire extinguisher to put out a fire, stand upwind and at a safe distance from the flames. Allow the wind to transport the extinguisher from you to the flames. Aim the extinguisher towards the fire's origin or base, not up in the flames. Persist until the burning substance has cooled enough to prevent the fire from resuming.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#36177#36178#36179","text":"Do all of the above#stand upwind from the fire#stay as far away from the fire as possible#aim at the source or base of the fire, not up in the flames"},
{"testId":763,"qid":30148,"description":"What is a proper way to test your parking brake?","explanation":"Stop your car and just use the parking brake you wish to test (power unit or trailer). Choose a low gear. Gently pull forward on the parking brake to ensure it is engaged.","imageName":"","correctAnswer":36180,"isBookmarked":0,"aid":"36180#36181#36182#36183","text":"Stop your vehicle, set the parking brake, and gently pull against it in low gear#Stop your vehicle, set the parking brake, and push the power unit from the outside#Set the parking brake while driving at 10 mph#Face your vehicle downhill and apply the parking brake"},
{"testId":763,"qid":30151,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. Even worse is driving aggressively in an effort to threaten, try, or carry out a violent act. Road rage often includes crimes, whereas aggressive driving typically entails traffic violations.","imageName":"","correctAnswer":33646,"isBookmarked":0,"aid":"203#33644#33645#33646","text":"All of the above#The level of anger#The outcome of the situation#Whether it's intentional"},
{"testId":763,"qid":30152,"description":"How many seconds of following space should you leave if you're doing 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#7321#7322#7690","text":"4 seconds#2 seconds#3 seconds#1 second"},
{"testId":763,"qid":30154,"description":"The perception distance is the distance traveled by your vehicle:","explanation":null,"imageName":"","correctAnswer":36186,"isBookmarked":0,"aid":"36184#36185#36186#36187","text":"from when you apply the brakes to when your vehicle comes to a stop#from when your eyes see a hazard to when you apply the brakes#from when your eyes see a hazard to when your brain recognizes the hazard#from when your eyes see a hazard to when your vehicle comes to a stop"},
{"testId":763,"qid":30155,"description":"You must notify your employer within 30 days of any traffic convictions except:","explanation":"Except for parking offenses, you must tell your employer within 30 days if you are convicted of a traffic violation. This is true whether you were driving a commercial vehicle or another kind of vehicle at the time.","imageName":"","correctAnswer":36189,"isBookmarked":0,"aid":"27183#36188#36189#36190","text":"speeding#reckless driving#parking#railroad crossing violations"},
{"testId":763,"qid":30159,"description":"If you get two serious traffic tickets in three years while driving a commercial motor vehicle (CMV), you will lose your CDL for at least 60 days. Which of the following are examples of such violations?","explanation":"If you are convicted of two significant traffic infractions involving a CMV within three years, you will lose your CDL for at least 60 days. You will lose your CDL for at least 120 days if you commit three significant traffic offences within three years. Violations include exceeding the speed limit by 15 miles per hour or more, driving recklessly, making inappropriate or irregular lane changes, tailgating, and texting or chatting on a handheld mobile phone.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#36116#36191","text":"All of the above#Tailgating#Speeding at 15 mph or more above the speed limit#Using a handheld cell phone"},
{"testId":763,"qid":30161,"description":"If you are convicted of operating a commercial motor vehicle (CMV) that has been ordered out of operation by federal or state inspectors,:","explanation":"If a federal or state inspection determines that a CMV is hazardous, it will be ordered out of operation. If you are found guilty of driving it anyhow, you will be barred from commercial driving for 90 days to one year if it is your first offense.","imageName":"","correctAnswer":36195,"isBookmarked":0,"aid":"36192#36193#36194#36195","text":"you will have to pay a $100 fine#you will have to pay a $500 fine#you will be disqualified from commercial driving for at least 30 days#you will be disqualified from commercial driving for at least 90 days"},
{"testId":763,"qid":30162,"description":"When driving on packed snow, you should slow down by at least :","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed or less on compacted snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18966,"isBookmarked":0,"aid":"18966#18967#18968#36196","text":"one-half#one-quarter#one-third#10 percent"},
{"testId":763,"qid":30164,"description":"To receive a New York CDL, you must be :","explanation":"To receive a New York CDL, you must be a New York State resident. In addition, you may only have one license at a time. If you relocate to New York from another state, you must exchange your out-of-state CDL for a New York CDL within 30 days of becoming a legal New York resident.","imageName":"","correctAnswer":36197,"isBookmarked":0,"aid":"8626#36197#36198#36199","text":"None of the above#a resident of New York State#employed in New York State#the holder of a CDL from another state"},
{"testId":763,"qid":30169,"description":"You must have all of the following EXCEPT if you are carrying an oversized load :","explanation":"If you're planning to transport an enormous cargo, be sure that all needed signs (flags, lights, and reflectors) are securely and correctly attached, and that you have all necessary permissions. Some big cargoes may also need a police escort or the use of pilot cars equipped with warning signs or flashing lights.","imageName":"","correctAnswer":36203,"isBookmarked":0,"aid":"36200#36201#36202#36203","text":"required permits#required signs#warning flags or lamps#cargo covers for the whole load"},
{"testId":763,"qid":30170,"description":"When may you carry hazardous goods without a CDL endorsement for hazardous materials (HazMat)?","explanation":"To drive a placarded hazardous materials vehicle, you must have a hazardous materials (HazMat) endorsement on your CDL. However, placarding your vehicle is not required for all hazardous material shipments. Small amounts of consumer-grade chemicals, such as drain openers and bleaches, may be excluded. In this regard, New York State follows federal rules. [49 CFR 172.500] and [17 NYCRR Part 820] are two federal regulations that govern the use of firearms.","imageName":"","correctAnswer":36207,"isBookmarked":0,"aid":"36204#36205#36206#36207","text":"If you're only driving across town#If you're just backing into the street#If your employer's usual HazMat drivers are unavailable#If your vehicle doesn't require placarding"},
{"testId":763,"qid":30171,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid flares, or six fusees must be included in your vehicle's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":763,"qid":30172,"description":"What specific danger is involved with transporting livestock?","explanation":"These massive animals may lean on bends, altering your vehicle's center of gravity and increasing the likelihood of a rollover. On severe bends, such as on-ramps and off-ramps, slow down and use care.","imageName":"","correctAnswer":36208,"isBookmarked":0,"aid":"36208#36209#36210#36211","text":"They can change your vehicle's center of gravity#Their noise may be distracting#Their smell can be distracting#They can try to escape"},
{"testId":731,"qid":30173,"description":"What specific danger is involved with transporting hanging meat?","explanation":"Hanging beef carcasses have a high center of gravity and may swing on bends, altering your vehicle's handling characteristics. On severe bends, such as on-ramps and off-ramps, slow down and use care.","imageName":"","correctAnswer":36214,"isBookmarked":0,"aid":"36212#36213#36214#36215","text":"Its smell may make it difficult to find places to park#You may have to drive extra fast to get it delivered fresh#It may change your vehicle's handling characteristics#You may be confronted by animal rights protesters"},
{"testId":763,"qid":30173,"description":"What specific danger is involved with transporting hanging meat?","explanation":"Hanging beef carcasses have a high center of gravity and may swing on bends, altering your vehicle's handling characteristics. On severe bends, such as on-ramps and off-ramps, slow down and use care.","imageName":"","correctAnswer":36214,"isBookmarked":0,"aid":"36212#36213#36214#36215","text":"Its smell may make it difficult to find places to park#You may have to drive extra fast to get it delivered fresh#It may change your vehicle's handling characteristics#You may be confronted by animal rights protesters"},
{"testId":763,"qid":30175,"description":"During very hot conditions, tar \"bleeds\" through the pavement. What impact may this have?","explanation":"In very hot weather, tar on the road pavement regularly rises to the surface. The areas where tar \"bleeds\" to the surface are very slippery. Avoid these areas whenever possible, and be prepared to resist a skid.","imageName":"","correctAnswer":36220,"isBookmarked":0,"aid":"36219#36220#36221#36222","text":"It may slow you down#It may make the road more slippery#It may get in your engine#It may spatter on your windshield"},
{"testId":777,"qid":30175,"description":"During very hot conditions, tar \"bleeds\" through the pavement. What impact may this have?","explanation":"In very hot weather, tar on the road pavement regularly rises to the surface. The areas where tar \"bleeds\" to the surface are very slippery. Avoid these areas whenever possible, and be prepared to resist a skid.","imageName":"","correctAnswer":36220,"isBookmarked":0,"aid":"36219#36220#36221#36222","text":"It may slow you down#It may make the road more slippery#It may get in your engine#It may spatter on your windshield"},
{"testId":763,"qid":30178,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities required for safe operation of a commercial motor vehicle are accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the New York State Commercial Driver's Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":763,"qid":30179,"description":"When there is a hazard ahead, it is nearly always faster to ________ than to come to a complete stop.","explanation":"Stopping distances are considerable for large trucks. If you see a danger ahead and don't have enough space to halt, attempt to steer away from it. Turning away is frequently faster than coming to a complete halt. (Top-heavy trucks like tractors with many trailers, on the other hand, may turn over.)","imageName":"","correctAnswer":36223,"isBookmarked":0,"aid":"4814#36223#36224#36225","text":"sound your horn#steer clear of it#downshift#go straight through the hazard"},
{"testId":763,"qid":30185,"description":"When should retarders NOT be used?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid on slick roads. As a result, anytime the road is wet, ice, or snow-covered, you should switch off your brakes.","imageName":"","correctAnswer":36226,"isBookmarked":0,"aid":"36226#36227#36228#36229","text":"If your drive wheels have poor traction#When you're slowing down from a high speed#When you're driving in traffic#When you plan to immediately speed up again"},
{"testId":763,"qid":30186,"description":null,"explanation":"A trailer's front-end header board is placed at the front of the vehicle. In the case of an accident or abrupt halt, the header board stops goods from sliding forward (and potentially hurting you). Make that the header board is secure, upright, and undamaged (no dents, no corrosion, no missing rivets, etc.).","imageName":"","correctAnswer":36233,"isBookmarked":0,"aid":"36230#36231#36232#36233","text":"A slang term for a license plate#Another term for the rear door of a trailer#Another term for a dashboard#A safety device behind you"},
{"testId":736,"qid":30238,"description":"Which of the following will result in a one-year suspension of your CDL for the first offense?","explanation":"In California, you may lose your CDL for a year for a variety of reasons, including drinking, felonies, and significant traffic offences such as hit and run.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18225#25399#25400","text":"All of the above#Committing a felony that involves your use of a CMV#Driving a commercial motor vehicle (CMV) when your blood alcohol content (BAC) is .04% or higher#Refusing to undergo blood alcohol testing when asked to do so"},
{"testId":736,"qid":30239,"description":"Which of the following is not a possible penalty for possessing multiple licenses?","explanation":"Having more than one license is a major offense. If you are convicted for the first offense, you will be fined, put to prison, or have your CDL revoked.","imageName":"","correctAnswer":25403,"isBookmarked":0,"aid":"18226#25401#25402#25403","text":"Being put in jail#Being fined up to $5,000#Having your California CDL canceled#Warning for a first offense"},
{"testId":736,"qid":30240,"description":"The state of California considers you to be engaged in interstate trade UNLESS the cargo you are transporting:","explanation":"Only freight originating in California and remaining in the state does not qualify as interstate commerce.","imageName":"","correctAnswer":25405,"isBookmarked":0,"aid":"25404#25405#25406#25407","text":"originates out of state#originates in the state and is headed for a location in the state#consists of waste or other hazardous substances#is headed for a location out of state"},
{"testId":736,"qid":30241,"description":"You may drive for a total of _____ hours in a seven-day period.","explanation":"You may only be on duty for 60 hours in a seven-day period. You are not eligible to work beyond 70 hours in eight days if your company works seven days a week. Then you must take 34 or more consecutive hours off work.","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"7571#8141#9656#9657","text":"50#60#70#65"},
{"testId":736,"qid":30242,"description":"What should you do if you are involved in a collision that results in death, injury, or property damage in excess of $1,000?","explanation":"If such an occurrence occurs, you must make an official report, Form SR-1, within 10 days. Your organization will have its own policies regarding corporate reporting and truck driving.","imageName":"","correctAnswer":25410,"isBookmarked":0,"aid":"23000#25409#25410#37885","text":"Do all of the above#Stop driving your vehicle for 10 days#File an official report on form SR-1 within 10 days#Surrender your CDL"},
{"testId":736,"qid":30243,"description":"How long do you have to notify your employer of any traffic offenses (excluding parking)?","explanation":"If you commit a traffic offense while driving any vehicle (including your own car), you must tell your employer within 30 days.","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6477#8141#11784","text":"10#30#60#14"},
{"testId":736,"qid":30244,"description":"What is the most significant and evident reason for checking your vehicle?","explanation":"The most critical factor is safety. Inspections may assist you in avoiding expensive and perhaps deadly breakdowns and accidents. Federal and state inspectors will examine your car, so be sure it's safe; otherwise, it may be impounded for infractions.","imageName":"","correctAnswer":18241,"isBookmarked":0,"aid":"18240#18241#25411#25412","text":"Employer liability#Safety#To see if your vehicle needs a wash#To make sure your paint is not chipped"},
{"testId":736,"qid":30245,"description":"The seven-step inspection method includes which of the following?","explanation":"The Seven-Step Inspection Method consists of the following steps: 1. Vehicle Overview; 2. Check Engine Compartment; 3. Start Engine and Inspect Inside the Cab; 4. Turn Off Engine and Check Lights; and 5. 5. Perform a walk-around inspection; 6. Check the signal lights; and 7. Start and check the engine.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18246#25413#27752","text":"All of the above#Step 6: Check the Signal Lights#Step 1: Vehicle Overview#Step 5: Do a Walkaround Inspection"},
{"testId":736,"qid":30246,"description":"Which of the following should you perform as part of your en-route check during a trip?","explanation":"During a voyage, you should keep a close eye on the situation, utilizing your senses and gauges to remain awake, and inspecting vital objects during rest breaks.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#25414#25415#25416","text":"Do all of the above#Watch your gauges to check for trouble#Pay attention with your senses to possible problems#Check critical items when you stop, such as brakes, tires, and lights"},
{"testId":736,"qid":30247,"description":"Which of the following is NOT part of the seven-step inspection of engine compartment?","explanation":"Checking the fluid levels in your engine compartment is part of the process. In a separate phase, the oil pressure gauge is examined.","imageName":"","correctAnswer":25419,"isBookmarked":0,"aid":"25417#25418#25419#25420","text":"The engine oil level#The coolant level in the radiator#The oil pressure gauge#The battery fluid level"},
{"testId":736,"qid":30248,"description":"You inspect your safety belt to ensure that:","explanation":"Inspect your seat belt to ensure it is firmly fastened, is not torn or frayed, and latches correctly. Color and other aesthetic considerations are unimportant, and six yards is much too much slack.","imageName":"","correctAnswer":25421,"isBookmarked":0,"aid":"25421#25422#25423#25424","text":"it is securely mounted#it matches the color of the rest of your cab#it is original equipment#it has six yards of slack"},
{"testId":736,"qid":30249,"description":"Checking your signal lights involves the following:","explanation":"The first step in evaluating your signal lights is to switch off all lights. Then, press the hand brake or have your companion apply the brake pedal to activate your stop lights. Then activate the turn signal lights on the left.","imageName":"","correctAnswer":25425,"isBookmarked":0,"aid":"25425#25426#25427#25428","text":"Turn off all lights, turn on the stop lights, and turn on the left turn signal lights#Turn on all lights, turn on the stop lights, and turn on the left turn signal lights#Turn off all lights, turn on the left turn signal lights, and turn on the stop lights#Turn on the stop lights, turn off all lights, and turn on the left turn signal lights"},
{"testId":736,"qid":30250,"description":"Which of the following is NOT a safe way to back up?","explanation":"Backing safely entails going slowly and keeping an eye on your surroundings, your route, and your mirrors. You should also pay heed to your helper's cues. Never should you have to back up while accelerating.","imageName":"","correctAnswer":25431,"isBookmarked":0,"aid":"25429#25430#25431#25432","text":"Back slowly while using your mirrors#Make use of a helper as often as possible#Accelerate slightly to complete the backing process quickly#Combine backing and turning toward the driver's side whenever you can"},
{"testId":736,"qid":30251,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":23630,"isBookmarked":0,"aid":"23627#23629#23630#25433","text":"Release the clutch#Release the clutch and press the accelerator at the same time#Accelerate while pressing the clutch and turning toward the driver's side#Push in the clutch and shift into a higher gear at the same time"},
{"testId":736,"qid":30252,"description":"According to the Handbook, how far ahead do the majority of good drivers look?","explanation":"A good driver looks ahead for 12 to 15 seconds, or the distance that the car will go in that time. At highway speeds, it may be a quarter-mile.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":736,"qid":30253,"description":"Retarders:","explanation":"Many kinds of retarders distribute braking force straight to the drive wheels, reducing the need for you to engage your brakes as often.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25434#25435#25436","text":"Do all of the above#reduce wear and tear on the brakes#apply braking power to the drive wheels#reduce the need to apply the brakes to slow the vehicle"},
{"testId":736,"qid":30255,"description":"Which of the following is NOT a good way to get someone's attention?","explanation":"It is not required to flash your turn signals. Simply follow the rules: signal early and continually, then cancel the signal when the move is completed. Unnecessary flashing lights are particularly harmful at night.","imageName":"","correctAnswer":25438,"isBookmarked":0,"aid":"25437#25438#25439#26997","text":"Signal early#Flash your signals to draw more attention#Turn your signal off after completing the maneuver#Signal continuously until you have completed your turn"},
{"testId":736,"qid":30256,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends.","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":736,"qid":30257,"description":"Which of the following is the same as the total distance to stop?","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18680,"isBookmarked":0,"aid":"18678#18680#25441#25442","text":"Reaction distance + braking distance#Perception distance + reaction distance + braking distance#Perception distance + reaction distance#Reaction distance + braking distance + signal distance"},
{"testId":736,"qid":30258,"description":"What is the safest speed to drive in heavy traffic?","explanation":"When you drive at the pace of other traffic, you don't have to continually pass or be passed by others, which reduces the likelihood of accidents. You should not, however, try to keep up with drivers who exceed the speed limit for commercial motor vehicles (CMVs).","imageName":"","correctAnswer":18288,"isBookmarked":0,"aid":"18287#18288#18289#18290","text":"The posted speed limit#The speed of other traffic (if not illegal or unsafe)#15 mph below the speed of other traffic#As fast as possible, to get out of the way"},
{"testId":736,"qid":30259,"description":"When being tailgated, which of the following should you NOT do?","explanation":"The fact that the car ahead is breaking the speed limit does not dissuade most tailgaters. Avoid the temptation to increase your speed to avoid or placate a tailgater. Simply maintain a safe pace and, if feasible, shift into another lane to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":25445,"isBookmarked":0,"aid":"6239#25443#25444#25445","text":"Increase your following distance#Avoid quick changes#Avoid tricks such as flashing your brake lights#Speed up"},
{"testId":736,"qid":30260,"description":"Which of the following statements concerning the relationship between cargo and height is correct?","explanation":"Cargo raises the height of a vehicle, reducing overhead clearance. If you previously carried goods and now have an empty vehicle, it will ride higher, not lower, therefore you may not be able to cross underpasses that you previously did.","imageName":"","correctAnswer":25446,"isBookmarked":0,"aid":"18296#25446#25447#25448","text":"An empty van is lower than a loaded one#The weight of the cargo changes a vehicle's height#The weight of the cargo does not change a vehicle's height#The height of an empty van is the same as that of a loaded one"},
{"testId":736,"qid":30261,"description":"Which of the following is not an issue associated with other drivers while driving at night?","explanation":"Other drivers may have eyesight, glare, and exhaustion issues that make them dangerous on the road, but hunger is not considered a concern for American drivers.","imageName":"","correctAnswer":25452,"isBookmarked":0,"aid":"25449#25450#25451#25452","text":"Impaired vision#Temporary blindness from glare#Lack of alertness from fatigue#Loss of concentration from hunger"},
{"testId":736,"qid":30262,"description":"Which of the following things are signs that a driver is drunk?","explanation":"Drivers under the influence behave like a drunk buddy at a party: they can't remain in their lane, they abruptly halt, and they slow down or speed up for no apparent reason.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#25453#25454#25455","text":"All of the above#Trouble staying in his or her own lane#Difficulty maintaining his or her speed#Stopping suddenly for no apparent reason"},
{"testId":736,"qid":30263,"description":"Which of the following activities should you NOT engage in while driving at night?","explanation":"As long as you are not within 500 feet of an oncoming vehicle, use your high lights to see farther.","imageName":"","correctAnswer":25458,"isBookmarked":0,"aid":"203#25456#25457#25458","text":"All of the above#Avoid blinding other drivers#Avoid glare from the headlights of oncoming vehicles#Avoid using your high beams, just in case"},
{"testId":736,"qid":30264,"description":"Which of the following should you NOT do if your tire fails?","explanation":"While it's natural to want to take control of the situation by braking, slamming on the brakes may lead you to lose control of your car, so brake softly once you've recovered control.","imageName":"","correctAnswer":25459,"isBookmarked":0,"aid":"19085#19086#23635#25459","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Apply the brakes hard and immediately"},
{"testId":736,"qid":30265,"description":"An antilock braking system (ABS) will:","explanation":"ABS only works to keep the wheels from locking up when you hit the brakes hard. ABS has no effect on how you regularly brake. It does not take the place of cautious braking, defensive driving, adequate brakes, and regular maintenance.","imageName":"","correctAnswer":25461,"isBookmarked":0,"aid":"18971#18973#25460#25461","text":"let you drive faster#shorten your stopping distance#increase a vehicle's stopping power#not change the way you brake normally"},
{"testId":736,"qid":30266,"description":"Which of the following should NOT be performed during a drive wheel skid?","explanation":"Large cars have a propensity to keep turning right, which means you may find yourself sliding in the other direction unless you countersteer.","imageName":"202001241342104104.jpg","correctAnswer":25464,"isBookmarked":0,"aid":"25462#25463#25464#25465","text":"Stop braking#Turn quickly#Steer into the skid#Countersteer"},
{"testId":736,"qid":30267,"description":"If you are in an accident, you should:","explanation":"When engaged in an accident, you should take many measures, including acquiring information for the required report, assisting the wounded, and informing the authorities.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25466#25467#25468","text":"Do all of the above#notify the proper authorities#do the best you can to care for injured persons#gather appropriate information for a collision report"},
{"testId":736,"qid":30268,"description":"Which of the following does NOT often start fires in vehicle?","explanation":"When correctly fitted, halogen lamps are not hazardous. However, combustible cargo, underinflated tires, and electrical system faults may all cause fires.","imageName":"","correctAnswer":18323,"isBookmarked":0,"aid":"17872#18323#18324#25469","text":"Underinflated tires#Halogen headlights#Flammable cargo#Problems in the electrical system"},
{"testId":736,"qid":30269,"description":"Which types of flames can be extinguished by an A: B: C type fire extinguisher?","explanation":"The B: C fire extinguisher is intended to extinguish electrical fires and burning liquids. The A: B: C type is intended for use in the combustion of wood, paper, and fabric. A specialist D type fire extinguisher is required to fight a fire in flammable metals.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17867#18432#25470","text":"All of the above#Gasoline#Electrical#Wood, paper, and cloth"},
{"testId":736,"qid":30270,"description":"To stay alert and safe while driving, you should:","explanation":"Medication labels that warn you they may make you sleepy should be taken seriously. Speak to your doctor if you have questions regarding any prescription drugs. Avoid attempting to combat tiredness with coffee, fresh air, or alcohol in particular. Sleeping until you are fully refreshed is the only way to combat fatigue.","imageName":"","correctAnswer":25471,"isBookmarked":0,"aid":"18976#18977#18978#25471","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications whose warning labels mention drowsiness"},
{"testId":736,"qid":30271,"description":"Which of the following is NOT a major worry when driving in hot weather?","explanation":"When it's excessively hot, you should drive cautiously, check your vehicle's belts for the water pump and fan, and prevent \"bleed.\" Seat belt discomfort is less of an issue.","imageName":"","correctAnswer":18333,"isBookmarked":0,"aid":"18332#18333#18334#25472","text":"Tar bubbling up and causing slippery roadways#Your seat belt causing you to sweat#Loose belts possibly causing overheating#Driving slowly enough to prevent overheating"},
{"testId":736,"qid":30272,"description":"What should you do when your vehicle is stuck on a railroad track?","explanation":"If your car becomes trapped on railroad tracks, your safety is paramount. Get out of the car as soon as possible. Don't put your life in danger to rescue your things, documents, cargo, or even the vehicle.","imageName":"","correctAnswer":25473,"isBookmarked":0,"aid":"25473#25474#25475#25476","text":"Immediately get out of the cab and move away from the tracks#Try to save some of the cargo if you don't see a train yet#Gather up your belongings and paperwork and then leave the cab#Keep trying to move your vehicle until you see the train"},
{"testId":736,"qid":30273,"description":"Children, according to the Handbook, present a hazard because:","explanation":"Most youngsters who are permitted to play outdoors are old enough to recognize the dangers of huge cars. However, they often do not look for traffic before racing into the street.","imageName":"","correctAnswer":25477,"isBookmarked":0,"aid":"25477#25478#25479#25480","text":"they move quickly without checking traffic#they often have grudges against truck drivers#they don't know how to drive#they don't know that trucks are dangerous"},
{"testId":736,"qid":30274,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":25481,"isBookmarked":0,"aid":"25481#25482#25483#25484","text":"preset your favorite radio stations#have all your emotionally difficult conversations in your first hour of driving#smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you"},
{"testId":736,"qid":30275,"description":"Step 3 of the seven-step method for checking a cab is to start the engine and look inside the cab. Each of the following things must be done in this step, EXCEPT:","explanation":"When doing this section of the examination, you must shift into neutral, start the engine, and listen for any strange sounds. After you've completed the engine inspection, double-check all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":736,"qid":30276,"description":"Your safety gear must include:","explanation":"Spare electrical fuses are only necessary if your car lacks circuit breakers. Brake drums and axles may need to be changed from time to time, but they are not a standard component of your safety equipment.","imageName":"","correctAnswer":25486,"isBookmarked":0,"aid":"25485#25486#25487#25488","text":"Spare electrical fuses, but only if your vehicle has circuit breakers#three red reflective triangles to be used as warning devices#Spare brake drums#A spare front axle"},
{"testId":736,"qid":30277,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":737,"qid":30277,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":736,"qid":30278,"description":"Which of the following options should not be part of your vehicle's suspension check?","explanation":"Checking the suspension include inspecting the springs, axles, shock absorbers, torque rods or arms, and other components. The tires are on a separate checklist.","imageName":"","correctAnswer":25491,"isBookmarked":0,"aid":"25489#25490#25491#25492","text":"The shock absorbers#The air suspension components#The tires#The axle positioning parts"},
{"testId":736,"qid":30279,"description":"When testing the service brakes' stopping power, at what speed should you do so?","explanation":"To test the stopping power of your service brakes, drive at around 5 mph and firmly press the brake pedal. If the car pulls to one side or stops more slowly than usual, this might indicate a problem.","imageName":"","correctAnswer":15720,"isBookmarked":0,"aid":"520#2429#15720#18362","text":"15 mph#10 mph#5 mph#2 mph"},
{"testId":736,"qid":30280,"description":"Which of these is NOT an important skill for driving a commercial vehicle?","explanation":"Driving abilities such as steering and backing are required, yet no one can multitask and drive successfully. Unfortunately, many individuals incorrectly believed they could, which is why there have been so many texting and driving tragedies.","imageName":"","correctAnswer":25493,"isBookmarked":0,"aid":"18363#18364#18365#25493","text":"Ability to accelerate#Ability to steer#Ability to back the vehicle safely#Ability to multitask while driving"},
{"testId":736,"qid":30281,"description":"When you back your vehicle with a trailer, you should:","explanation":"While backing up, try to keep everything straight. This entails correcting drift as soon as it happens and backing as straight as possible. You must also spin the wheel in the opposite direction that you wish to travel. Once the trailer begins to spin, turn the wheel the opposite way. Pull forward as frequently as necessary to adjust and realign.","imageName":"","correctAnswer":25497,"isBookmarked":0,"aid":"25494#25495#25496#25497","text":"turn the steering wheel in the same direction in which you want to go#not bother to correct drift#not pull forward once you have started backing#back in as straight a line as possible"},
{"testId":736,"qid":30282,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When checking hoses during your pre-trip inspection, look for indicators of leaks and cracks, such as pools on the ground and fluids flowing on the bottom of the engine or gearbox. Also, inspect hoses for leaks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":736,"qid":30283,"description":"Following your pre-trip check, you should inspect your cargo:","explanation":"Within the first 50 miles of the voyage, inspect the cargo and its fastening mechanisms again. Make any necessary changes.","imageName":"","correctAnswer":25500,"isBookmarked":0,"aid":"25499#25500#25501#25502","text":"within 25 miles of the start of the trip#within 50 miles of the start of the trip#within an hour after starting the trip#within 10 miles of the start of the trip"},
{"testId":736,"qid":30284,"description":"A top-heavy load is hazardous since it can cause:","explanation":"A top-heavy load elevates your vehicle's center of gravity, increasing your chances of rolling over on a curve or swerving to escape a danger. A top-heavy load is an imbalanced load that may harm your vehicle's axles and other components.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#25503#25504#25505","text":"All of the above#a rollover on a curve#a rollover if you swerve around a hazard#damage to the axles or steering"},
{"testId":730,"qid":30285,"description":"Your cargo tiedowns' aggregate working load limit must be at least _____ times the weight of the cargo.","explanation":"According to the Federal Motor Carrier Safety Administration (FMCSA), the aggregate working load limit of any securement system used to secure an article or group of articles against movement must be at least one-half the weight of the article or group of articles.","imageName":"","correctAnswer":10698,"isBookmarked":0,"aid":"2753#2754#10698#25506","text":"Two#Four#one-half#two-and-a-half"},
{"testId":736,"qid":30285,"description":"Your cargo tiedowns' aggregate working load limit must be at least _____ times the weight of the cargo.","explanation":"According to the Federal Motor Carrier Safety Administration (FMCSA), the aggregate working load limit of any securement system used to secure an article or group of articles against movement must be at least one-half the weight of the article or group of articles.","imageName":"","correctAnswer":10698,"isBookmarked":0,"aid":"2753#2754#10698#25506","text":"Two#Four#one-half#two-and-a-half"},
{"testId":736,"qid":30286,"description":"Projecting loads that extends ______ must have flags or lights.","explanation":"A projecting load is one that projects 4 feet or more beyond the rear body of the vehicle and so needs a 12-inch-square red or bright orange flag during the day and two illuminated red lights visible for 500 feet at night.","imageName":"","correctAnswer":25507,"isBookmarked":0,"aid":"25507#25508#25509#25510","text":"4 or more feet beyond the rear body#3 or more feet beyond the rear body#6 or more feet beyond the rear body#56 inches or more beyond the rear body"},
{"testId":736,"qid":30287,"description":"How much does the whole car weigh?","explanation":"The weight of a single vehicle plus the weight of the cargo it is transporting is referred to as the GVW.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"234#18389#19011#25511","text":"Any of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, including the load and any trailers"},
{"testId":736,"qid":30288,"description":"Legal maximum weight restrictions may not be safe in all of the following, EXCEPT:","explanation":"Maximum weight limitations are not always safe, particularly in inclement weather or while driving on steep mountain roads. However, traffic is a reality of life.","imageName":"","correctAnswer":25515,"isBookmarked":0,"aid":"25512#25513#25514#25515","text":"mountain driving#winter#a storm#traffic"},
{"testId":769,"qid":30289,"description":"How should a curve be approached?","explanation":"Your car may slide or flip over if you attempt to take a curve too quickly. Braking on a curve, on the other hand, might lock the wheels and produce a skid. Instead, take it easy before the bend. When you're in the curve, choose a gear that allows you to accelerate somewhat. This will assist you in maintaining control.","imageName":"","correctAnswer":36455,"isBookmarked":0,"aid":"36454#36455#36456#36457","text":"Brake in the curve as needed#Slow down before the curve and accelerate slightly in the curve#Slow down before the curve and stay at that speed from then on#Speed up before the curve and slow down in the curve"},
{"testId":769,"qid":30291,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":773,"qid":30291,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":769,"qid":30292,"description":"If you must stop on a road or the side of a road, you must set out your warning devices within:","explanation":"Federal law requires you to place emergency warning equipment (such as reflective triangles) within 10 minutes after stopping. Federal requirements additionally specify the location of your warning devices in relation to your vehicle. Details may be found in Section 2.5.2 of the Commonwealth of Pennsylvania Commercial Driver's Manual. [49 CFR 392 .22]","imageName":"","correctAnswer":36459,"isBookmarked":0,"aid":"36458#36459#36460#36461","text":"5 minutes#10 minutes#15 minutes#20 minutes"},
{"testId":769,"qid":30293,"description":"What time should you plan your trip?","explanation":"It is estimated that driver weariness plays a role in at least 15% of accidents involving large trucks. Prior to a journey, you should obtain enough rest, carefully plan your route to determine the overall distance, rest stops, and other practical concerns, arrange travels during hours when you are ordinarily awake rather than in the middle of the night, and avoid drugs that make you drowsy.","imageName":"","correctAnswer":36464,"isBookmarked":0,"aid":"36462#36463#36464#36465","text":"In the middle of the night, when there is little traffic#In the middle of the day, when there is the most daylight#For the hours during which you are normally awake#When it best fits your social life"},
{"testId":769,"qid":30294,"description":"Inside the cab, what should you perform during your pre-trip inspection?","explanation":"Step 6 of the seven-step pre-trip check includes getting in the cab and making sure it's ready. Turn off any superfluous lights, double-check your paperwork, and secure any loose things. Then fire up your engine.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#36467#36468#36537","text":"Do all of the above#Check that you have all your required papers#Secure all loose items#Turn off unnecessary lights"},
{"testId":769,"qid":30295,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"23631#33637#36469#36470","text":"4/32 inch#2/32 inch#6/32 inch#5/32 inch"},
{"testId":769,"qid":30296,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities required for the safe operation of a commercial motor vehicle are accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the Commonwealth of Pennsylvania Commercial Driver's Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":769,"qid":30300,"description":"How much does the Gross Combination Weight (GCW) weigh?","explanation":"A vehicle's Gross Combination Weight (GCW) is the total weight of the power unit, trailers, and cargo. Make certain you understand how to apply this topic. For example, if a bridge has an eight-ton weight restriction, a loaded tractor-trailer must weigh no more than eight tons to legally and securely cross that bridge.","imageName":"","correctAnswer":36474,"isBookmarked":0,"aid":"36471#36472#36473#36474","text":"The combined weight of the power unit and trailers#The weight of a trailer plus its cargo#The weight of the power unit plus the cargo#The combined weight of the power unit, trailers, and cargo"},
{"testId":769,"qid":30301,"description":"Which of the following should NOT be attempted to be extinguished with water?","explanation":"Never use water to extinguish an electrical fire. Water is capable of conducting electricity. You may suffer a deadly electrical shock if you are carrying a fire extinguisher that is spraying water on an electrical fire. Instead, use a fire extinguisher with a nonconducting rating of at least C, such as carbon dioxide or dry chemical.","imageName":"","correctAnswer":18432,"isBookmarked":0,"aid":"18431#18432#18433#18434","text":"Wood#Electrical#Tire#Paper"},
{"testId":769,"qid":30302,"description":"Are empty trucks able to stop faster than loaded truck?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#36475","text":"No#Yes#Yes, but only on wet surfaces#It depends on the cargo"},
{"testId":769,"qid":30303,"description":"If you are convicted of knowingly operating a commercial motor vehicle (CMV) to carry illegal narcotics, you will be barred from driving commercially for a period of:","explanation":null,"imageName":"","correctAnswer":32620,"isBookmarked":0,"aid":"26672#29895#32619#32620","text":"5 years#10 years#20 years#Life"},
{"testId":769,"qid":30305,"description":"The maximum height of a commercial motor vehicle (CMV) in Pennsylvania is:","explanation":"For CMVs, there is no Federal vehicle height limitation. Each state has the authority to impose its own height limitations. In Pennsylvania, the maximum height of a CMV, whether loaded or not, is 13 feet 6 inches.","imageName":"","correctAnswer":36477,"isBookmarked":0,"aid":"14177#36476#36477#36478","text":"15 feet#16 feet 6 inches#13 feet 6 inches#12 feet"},
{"testId":769,"qid":30306,"description":"How far can you see when using high beams while it's completely dark outside?","explanation":null,"imageName":"","correctAnswer":1296,"isBookmarked":0,"aid":"400#565#1296#4432","text":"300 feet#800 feet#500 feet#1,000 feet"},
{"testId":769,"qid":30309,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Commonwealth of Pennsylvania Commercial Driver's Manual indicates that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":769,"qid":30311,"description":"When parking your vehicle, you should search for an available parking spot:","explanation":"When parking your car, search for a place that you can walk straight through rather than back out of. Take note of the layout of the parking lot and the position of your parking spot inside it so you don't have trouble departing the parking space later.","imageName":"","correctAnswer":36481,"isBookmarked":0,"aid":"36479#36480#36481#36482","text":"into which your vehicle will fit snugly#with enough room to let you back out#that you can pull straight through#that is as close as possible to your destination"},
{"testId":769,"qid":30312,"description":"Crossing a double railroad track requires extra caution because:","explanation":"A train on one track of a double railroad track may obscure a train on the other track. Check both ways to make sure there are no trains on the opposite track before you begin crossing the railroad crossing after the train you can see has passed through. This is particularly crucial at crossings without additional train warning signs, gates, or flagmen.","imageName":"","correctAnswer":36484,"isBookmarked":0,"aid":"36483#36484#36485#36486","text":"it's wider than a single track#there may be more than one train#trains may be more frequent#there will probably be a flag person there"},
{"testId":769,"qid":30313,"description":"When you drive through an underpass, your vertical clearance may be affected by:","explanation":"Don't take the vertical clearance signs at underpasses at face value. Since the signs were posted, repaving or compacted snow may have elevated the effective height of the road surface, lowering clearance. In addition, without the weight of goods, an empty vehicle may be substantially higher than a full one, reducing vertical clearance.","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#36487#36488#36489","text":"Any of the above#the weight of your load#packed snow on the ground#repaving of the roadway"},
{"testId":769,"qid":30314,"description":"The axle weight means:","explanation":"The axle weight is the most weight that a single axle can sustain. When the axles of a vehicle are close together, a large axle weight concerns overloading bridges and highways. Furthermore, cars with large axle weights may cause much greater damage to the road surface. Axle weights on federally sponsored roadways are now limited to 20,000 pounds on a single axle and 34,000 pounds on a tandem axle.","imageName":"","correctAnswer":36493,"isBookmarked":0,"aid":"36490#36491#36492#36493","text":"What each axle weighs by itself#The combined weight of the power unit's axles only#The combined weight of all the axles of your vehicle#The maximum weight that can be supported by one axle"},
{"testId":769,"qid":30315,"description":"Why should a post-trip inspection report be completed?","explanation":"Each vehicle you used should be inspected after the trip. Make a note of any safety issues you discovered while driving the car, as well as any repairs you feel are required. The inspection report will inform the next driver if there are any safety issues. It will also notify the motor carrier if any safety-related repairs are required. According to federal rules, an inspection report must be prepared before the conclusion of the day's usage of the vehicle. [49 CFR 396 .11]","imageName":"","correctAnswer":36441,"isBookmarked":0,"aid":"17883#36440#36441#36442","text":"To prove you finished your trip#So that you can't be blamed for any subsequent damage#To alert others to safety problems requiring repair#To impress your boss"},
{"testId":769,"qid":30316,"description":"How much play should your steering wheel have at the most?","explanation":"More than 10 degrees of steering wheel motion might make steering more difficult. Ten degrees of play corresponds to around two inches of motion at the rim of a 20-inch steering wheel.","imageName":"","correctAnswer":36494,"isBookmarked":0,"aid":"36494#36495#36496#36497","text":"10 degrees#5 degrees#2 degrees#15 degrees"},
{"testId":769,"qid":30321,"description":"The vehicle will be taken out of service if _______ or more of the leaves in any leaf spring are broken or missing.","explanation":"The vehicle will be taken out of service if one-fourth or more of the leaves in any leaf spring are damaged or missing.","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10514#10698#11884#33470","text":"One#one-half#one-fourth#one-third"},
{"testId":779,"qid":30321,"description":"The vehicle will be taken out of service if _______ or more of the leaves in any leaf spring are broken or missing.","explanation":"The vehicle will be taken out of service if one-fourth or more of the leaves in any leaf spring are damaged or missing.","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10514#10698#11884#33470","text":"One#one-half#one-fourth#one-third"},
{"testId":769,"qid":30322,"description":"Place the heaviest items in your load:","explanation":"Load your vehicle in such a way that its center of gravity is as low as feasible. Even if the truck is not exceeding the posted speed limit for the curve, a truck with a high center of gravity is more likely to roll over.","imageName":"","correctAnswer":36499,"isBookmarked":0,"aid":"36498#36499#36500#36501","text":"as high as possible#as low as possible#to one side#whichever way is most convenient"},
{"testId":769,"qid":30323,"description":"What should you do first if you see a hazard ahead?","explanation":null,"imageName":"","correctAnswer":36444,"isBookmarked":0,"aid":"14201#36443#36444#36445","text":"Call emergency services#Come to a complete stop as soon as it is safe to do so#Make a contingency plan#Do nothing until you get closer to it"},
{"testId":779,"qid":30323,"description":"What should you do first if you see a hazard ahead?","explanation":null,"imageName":"","correctAnswer":36444,"isBookmarked":0,"aid":"14201#36443#36444#36445","text":"Call emergency services#Come to a complete stop as soon as it is safe to do so#Make a contingency plan#Do nothing until you get closer to it"},
{"testId":769,"qid":30327,"description":"Define: Hazard:","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":731,"qid":30331,"description":"You hear a noise that sounds like a tire blowout while operating a vehicle with twin tires. However, the handling of your vehicle seems unaltered. What ought you to do?","explanation":null,"imageName":"","correctAnswer":36401,"isBookmarked":0,"aid":"36399#36400#36401#36402","text":"Keep driving as usual#Check your tires at the next stop#Stop and check your tires now#Look in your mirrors to try to spot trouble"},
{"testId":769,"qid":30331,"description":"You hear a noise that sounds like a tire blowout while operating a vehicle with twin tires. However, the handling of your vehicle seems unaltered. What ought you to do?","explanation":null,"imageName":"","correctAnswer":36401,"isBookmarked":0,"aid":"36399#36400#36401#36402","text":"Keep driving as usual#Check your tires at the next stop#Stop and check your tires now#Look in your mirrors to try to spot trouble"},
{"testId":741,"qid":30340,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":36217,"isBookmarked":0,"aid":"33647#36216#36217#36218","text":"To check the cleanliness of your vehicle#To make sure the paint is not chipped#To make sure your vehicle is safe#For good public relations"},
{"testId":763,"qid":30340,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":36217,"isBookmarked":0,"aid":"33647#36216#36217#36218","text":"To check the cleanliness of your vehicle#To make sure the paint is not chipped#To make sure your vehicle is safe#For good public relations"},
{"testId":773,"qid":30340,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":36217,"isBookmarked":0,"aid":"33647#36216#36217#36218","text":"To check the cleanliness of your vehicle#To make sure the paint is not chipped#To make sure your vehicle is safe#For good public relations"},
{"testId":779,"qid":30340,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":36217,"isBookmarked":0,"aid":"33647#36216#36217#36218","text":"To check the cleanliness of your vehicle#To make sure the paint is not chipped#To make sure your vehicle is safe#For good public relations"},
{"testId":741,"qid":30341,"description":"Which of the following is NOT a critical component of steering?","explanation":"Of course, each axle is an essential component of your car. It is, however, not a critical component of the steering system, which also comprises the steering column, the arm and knuckle, the hydraulic fluid reservoir, the Pitman arm, and the spindle.","imageName":"","correctAnswer":18403,"isBookmarked":0,"aid":"18401#18402#18403#18404","text":"Tie rod#Steering wheel#Axle#Gear box"},
{"testId":741,"qid":30342,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":741,"qid":30343,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid burning flares, or six fusees must be included in your truck's emergency kit.","imageName":"","correctAnswer":10544,"isBookmarked":0,"aid":"10544#10545#11417#11592","text":"Three#Four#Five#Six"},
{"testId":741,"qid":30344,"description":"What must be included in your vehicle's safety equipment?","explanation":"Warning devices (such as reflective triangles) and a fully charged and rated fire extinguisher must be included in your vehicle's safety equipment. You must also have extra electrical fuses if your car use fuses rather than circuit breakers.","imageName":"","correctAnswer":18945,"isBookmarked":0,"aid":"18945#25485#25487#25488","text":"A fire extinguisher#Spare electrical fuses, but only if your vehicle has circuit breakers#Spare brake drums#A spare front axle"},
{"testId":741,"qid":30345,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":741,"qid":30346,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":25525,"isBookmarked":0,"aid":"25525#36414#41941#41942","text":"\"Stop.\"#\"Turn left.\"#鈥淕o forward.鈥#鈥淕o backward.鈥"},
{"testId":741,"qid":30347,"description":"Which of the following is true about slow learners?","explanation":"Retarders (of which there are various sorts) assist your car slow down, decreasing the need to brake. They may, however, increase the likelihood of a skid in bad weather or when your wheels have insufficient grip. As a result, in bad weather, you should switch off your retarder.","imageName":"","correctAnswer":23029,"isBookmarked":0,"aid":"23029#41943#41944#41945","text":"All of the above are true#Retarders can reduce brake wear#Retarders apply braking power to the drive wheels#Retarders make a skid more likely when traction is poor"},
{"testId":741,"qid":30348,"description":"When downgrading in a vehicle equipped with an automatic gearbox, you should:","explanation":"If you just maintain braking to regulate your speed on a steep downhill, the brakes may fade (lose their effectiveness). Instead, if your car has an automatic gearbox, set it to a low range to prevent the transmission from moving up beyond the preset gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":41946,"isBookmarked":0,"aid":"41946#41947#41948#41949","text":"select a low range#select a high range#change your range in the middle of the downgrade#just keep applying your brakes"},
{"testId":741,"qid":30349,"description":"To upshift, let off the gas, depress the clutch, move the gear selector into neutral, and then:","explanation":"To shift up with double clutching, release the accelerator, press the clutch and shift into neutral simultaneously, release the clutch, let the engine and gears slow to the required rpm for the next gear, press the clutch and shift into a higher gear simultaneously, then release the clutch and press the accelerator simultaneously.","imageName":"","correctAnswer":33643,"isBookmarked":0,"aid":"33643#41950#41951#41952","text":"Release the clutch#press the accelerator#push in the clutch again#shift"},
{"testId":741,"qid":30350,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#33656#33657","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + reaction distance#reaction distance + braking distance + signal distance"},
{"testId":741,"qid":30351,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (This is also addressed in section 2.5.2 of the Florida CDL Handbook.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":741,"qid":30352,"description":"How far ahead should you glance while driving?","explanation":"The Florida CDL Handbook recommends that you anticipate the distance that your CMV will go in 12 to 15 seconds. At city driving speeds, your CMV will go one block in around 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":741,"qid":30353,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#41953","text":"Four times as much#Three times as much#Twice as much#1.5 times as much"},
{"testId":741,"qid":30354,"description":"Why should you maintain correct tire inflation?","explanation":"Maintain correct tire inflation. (To acquire an exact reading, use a tire pressure gauge.) Tires that are overinflated are more likely to fail prematurely. Low tire pressures result in quicker tire wear, lower cornering ability, and increased hydroplaning. Underinflation also causes higher tire flexing, which raises tire temperature. Overheated tires may catch fire. Even properly inflated tires, however, are no guarantee against skids.","imageName":"","correctAnswer":41955,"isBookmarked":0,"aid":"41954#41955#41956#41957","text":"You will be driving a lot faster#Your tires will last longer#You will never go into a skid#It will make the ride quieter"},
{"testId":741,"qid":30355,"description":"What is the best technique to inspect the area behind and to the sides of your vehicle?","explanation":"Check your mirrors often when driving and even more frequently in unique circumstances to know what's going on behind and to the sides of your car. Adjust your mirrors before you start driving so that each mirror displays a different portion of your car.","imageName":"","correctAnswer":21091,"isBookmarked":0,"aid":"21091#27314#34276#36113","text":"Check your mirrors regularly#Check your reflection in other vehicles#Stop every 50 miles and look around#Turn your head to check your blind spots"},
{"testId":761,"qid":30355,"description":"What is the best technique to inspect the area behind and to the sides of your vehicle?","explanation":"Check your mirrors often when driving and even more frequently in unique circumstances to know what's going on behind and to the sides of your car. Adjust your mirrors before you start driving so that each mirror displays a different portion of your car.","imageName":"","correctAnswer":21091,"isBookmarked":0,"aid":"21091#27314#34276#36113","text":"Check your mirrors regularly#Check your reflection in other vehicles#Stop every 50 miles and look around#Turn your head to check your blind spots"},
{"testId":773,"qid":30355,"description":"What is the best technique to inspect the area behind and to the sides of your vehicle?","explanation":"Check your mirrors often when driving and even more frequently in unique circumstances to know what's going on behind and to the sides of your car. Adjust your mirrors before you start driving so that each mirror displays a different portion of your car.","imageName":"","correctAnswer":21091,"isBookmarked":0,"aid":"21091#27314#34276#36113","text":"Check your mirrors regularly#Check your reflection in other vehicles#Stop every 50 miles and look around#Turn your head to check your blind spots"},
{"testId":741,"qid":30356,"description":"True or false: \"A truck's braking distance is less when empty than when fully loaded.\":","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer braking distances than loaded trucks.","imageName":"","correctAnswer":41959,"isBookmarked":0,"aid":"41958#41959#41960#41961","text":"True#False#It cannot be determined without more information about the cargo#It cannot be determined without more information about the truck"},
{"testId":741,"qid":30357,"description":"Which of the following situations is most conducive to hydroplaning?","explanation":"When there is standing water on a highway, hydroplaning happens. At greater speeds, the tires' ability to channel water away deteriorates, and your tires may lose touch with the road and ride over the water like a pair of water skis. This might result in complete braking and steering control loss. Slow down to avoid the beginnings of hydroplaning. Icy surfaces may also cause skids, although not by hydroplaning.","imageName":"","correctAnswer":41962,"isBookmarked":0,"aid":"41962#41963#41964#41965","text":"When there is standing water on the road#When you're in a shady area the morning after a freeze#When you are on a bridge right before a freeze is forecast#When the temperature is below freezing but the road looks wet"},
{"testId":741,"qid":30358,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options entail hazardous distractions or do not function at all. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":741,"qid":30359,"description":"Why should you avoid swinging left before turning right?","explanation":"If you swing wide to the left before turning right, a car on your right may attempt to pass you while you are turning, which might result in an accident. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":41966,"isBookmarked":0,"aid":"27320#41966#41967#41968","text":"It's against the law#Another vehicle may try to pass you on your right#You may move into an opposing lane#It won't help you through the intersection"},
{"testId":741,"qid":30360,"description":"If someone is following you too closely, you must:","explanation":"Avoid abrupt shifts or gimmicks, such as flashing your brake lights, if you are being tailgated. Increase your following distance instead to provide both you and the tailgater more room to respond to risks. This may entice the tailgater to pass you. Don't try to outrun or outpace the tailgater by driving faster. Some tailgaters believe that no speed is too fast.","imageName":"202006041818594397.jpg","correctAnswer":6920,"isBookmarked":0,"aid":"6475#6920#7707#41969","text":"Speed up#Increase your following distance#decrease your following distance#hit the brakes"},
{"testId":741,"qid":30362,"description":"How many seconds of following space should you leave if you're doing 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#427#428#7322","text":"4 seconds#5 seconds#6 seconds#3 seconds"},
{"testId":741,"qid":30366,"description":"Getting only ______ hours of sleep the night before your travel increases your risk of an accident by thrice.","explanation":"Getting just 6 hours of sleep or fewer the night before your journey increases your chance of an accident by thrice. Adults need 8 to 9 hours of sleep every night to be awake.","imageName":"","correctAnswer":18592,"isBookmarked":0,"aid":"18590#18591#18592#18816","text":"4 hours#2 hours#6 hours#8 hours"},
{"testId":741,"qid":30368,"description":"Which of the following is most likely to become stuck on elevated railroad crossings?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with landing gear configured to accept a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":41970,"isBookmarked":0,"aid":"41970#41971#41972#41973","text":"A car carrier#A single-axle tractor pulling a trailer whose landing gear is set to accommodate it#A single-axle bobtail tractor#A straight truck"},
{"testId":741,"qid":30370,"description":"Which of the following should you perform first when approaching a severe downgrade?","explanation":"Shift into a low gear and apply engine brakes to decelerate your car as you approach a steep descent. Don't attempt to downshift after your speed has increased. You will be unable to change to a lower gear. You may be unable to shift back into any gear, and all engine braking action will be gone.","imageName":"","correctAnswer":41974,"isBookmarked":0,"aid":"21669#33641#33643#41974","text":"Release the brakes#Accelerate#Release the clutch#Shift into a low gear"},
{"testId":741,"qid":30371,"description":"An antilock braking system (ABS) will:","explanation":"ABS has little impact on your usual driving. It has no effect on your braking abilities and does not necessarily lessen your stopping distance. It simply assists you in maintaining control of your car by triggering when your wheels are likely to lock up.","imageName":"","correctAnswer":41977,"isBookmarked":0,"aid":"23697#41975#41976#41977","text":"shortens your stopping distance#lets you drive faster#increases your vehicle's stopping power#does not change the way you normally brake"},
{"testId":741,"qid":30374,"description":"Which types of flames can be extinguished by an A: B: C type fire extinguisher?","explanation":"When putting out a fire, use the proper sort of extinguisher. Ordinary combustibles such as wood, paper, or fabric are used in Class A fires. Class B fires are caused by greasy substances like grease or gasoline. Electrical equipment is involved in Class C fires. Combustible metals such as magnesium are used in Class D fires.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#17867#41978#41979","text":"All of the above#Gasoline#Ordinary combustibles#Electrical equipment"},
{"testId":741,"qid":30379,"description":"All of these are signs of an intoxicated driver EXCEPT:","explanation":"A driver's coordination, judgement, and response time may all be negatively impacted by alcohol. As a consequence, the motorist may drive dangerously quickly or (in an effort to maintain control) extremely slowly. They may also fail to respect traffic regulations, cross across several lanes, or weave between them.","imageName":"","correctAnswer":41982,"isBookmarked":0,"aid":"41980#41981#41982#41983","text":"straddling two lanes#running stop signs or red lights#reacting quickly to hazards#driving too fast or too slowly"},
{"testId":741,"qid":30380,"description":"If you are convicted of drugged driving that causes an accident in Florida, you may face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35315#41984","text":"All of the above#A fine#A jail sentence#Loss of commercial driving privileges"},
{"testId":741,"qid":30383,"description":"If there is insufficient weight on the front axle,:","explanation":"Less weight may result in less road grip. Inadequate weight on the front axle may make it more difficult to steer safely. Underloading the driving axles increases the likelihood of the drive wheels spinning.","imageName":"","correctAnswer":27327,"isBookmarked":0,"aid":"27327#27329#39396#41985","text":"you may not be able to steer safely#you may experience tire failure#all of the above may occur#your drive wheels may spin"},
{"testId":741,"qid":30385,"description":"You must inspect the security of your cargo within _____ of starting your journey.","explanation":"Once you begin your journey, you must examine the cargo's security within 50 miles and then every 3 hours or 150 miles thereafter.","imageName":"","correctAnswer":41987,"isBookmarked":0,"aid":"41986#41987#41988#41989","text":"25 miles#50 miles#an hour#10 miles"},
{"testId":741,"qid":30386,"description":"Why should cargo be covered?","explanation":null,"imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":741,"qid":30387,"description":"To pass an emergency vehicle stopped on the side of a multilane highway in Florida, you must move into a non-adjacent lane if it is safe to do so. Otherwise, you must reduce your speed to:","explanation":"When you come across an emergency vehicle, sanitation vehicle, utility vehicle, or tow truck that is stopped with its flashing lights on, you must shift into a non-adjacent lane if feasible, leaving at least one unoccupied lane between you and the vehicle, according to Florida's Move Over Law. If this is difficult or dangerous (for example, there are no alternative lanes in your direction), you must slow down until you have passed the car. If the listed speed limit is more than 20 miles per hour, you must slow down to 20 miles per hour; otherwise, you must slow down to 5 miles per hour. (Note: Although every state in the United States currently has its own Move Over Law, some of them compel drivers to perform various things. Always verify the local traffic rules while going out of state.)","imageName":"","correctAnswer":41990,"isBookmarked":0,"aid":"2#4#41990#41991","text":"5 mph#10 mph#20 mph below the speed limit#15 mph below the speed limit"},
{"testId":741,"qid":30388,"description":"Which of the following drivers is required by Florida law to have a CDL?","explanation":"Military drivers, emergency workers, farmers, and farm employees (but not those hired to drive agricultural vehicles) are exempt from the CDL requirement. In addition, anybody who drives a recreational vehicle or straight truck for noncommercial reasons (e.g., to transfer the driver's own personal goods) is free from the CDL requirement.","imageName":"","correctAnswer":41994,"isBookmarked":0,"aid":"203#41992#41993#41994","text":"All of the above#A driver of a military vehicle#A farmer carrying fresh produce to the market#A long-haul trucker carrying fresh produce"},
{"testId":741,"qid":30389,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18654#18655#18656#41995","text":"Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo#Ensuring the cargo's safety"},
{"testId":766,"qid":30410,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Ohio Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":766,"qid":30411,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Ohio Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":766,"qid":30414,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Ohio Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":766,"qid":30436,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":769,"qid":30436,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":766,"qid":30439,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the Ohio Commercial Driver License Manual advises that you should inspect your cargo during the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":742,"qid":30440,"description":"You should evaluate the front of your vehicle for _________ during a walk-around inspection.","explanation":"At the front of your car, you should inspect the axle, steering system, windshield, and any lights and reflectors.","imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#27964#27965#27966","text":"All of the above#the condition of your lights#damage to the windshield#loose or worn parts in the steering system"},
{"testId":742,"qid":30441,"description":"Which of the following situations should you choose a low range if you have an automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has a manual gearbox, downshift; if it has an automatic transmission, choose a low range that prohibits the transmission from moving up beyond the specified gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":34280,"isBookmarked":0,"aid":"34279#34280#34281#34282","text":"If you start to get drowsy#If you're going down a steep downgrade#If you feel you're driving too fast#When your engine speed increases"},
{"testId":742,"qid":30442,"description":"If you must stop on a road or the side of a road, you must set out your warning devices within:","explanation":"If you have to pull over on the side of the road or on the shoulder of any road, you must lay out your emergency warning equipment within ten minutes.","imageName":"","correctAnswer":39266,"isBookmarked":0,"aid":"35430#39266#39267#39268","text":"fifteen minutes#ten minutes#five minutes#twenty minutes"},
{"testId":742,"qid":30443,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18660#18767#19091","text":"Tie rod#Leaf spring#Steering shaft#Gearbox"},
{"testId":742,"qid":30445,"description":"Which of the following should you perform as part of your en-route check during a trip?","explanation":null,"imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#25414#44077#44078","text":"Do all of the above#Watch your gauges to check for trouble#Use your other senses to detect trouble#Regularly check safety-critical items"},
{"testId":742,"qid":30449,"description":"On a four-lane roadway, you are in the right lane. You notice an emergency vehicle pulled over ahead with its lights blazing. What should you do?","explanation":null,"imageName":"","correctAnswer":39336,"isBookmarked":0,"aid":"15493#35047#35268#39336","text":"Slow down#Slow down; also move into a non-adjacent lane if possible#Stop behind the emergency vehicle#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":742,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":756,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":761,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":763,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":767,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":773,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":776,"qid":30450,"description":"How do you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":731,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":742,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":761,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":767,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":769,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":773,"qid":30451,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, you should have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":17856,"isBookmarked":0,"aid":"17713#17856#17857#36112","text":"Because it's the law#Because every truck has blind spots#To make sure you don't damage your truck#To check your style of driving"},
{"testId":742,"qid":30452,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":25525,"isBookmarked":0,"aid":"25524#25525#44079#44080","text":"\"Faster.\"#\"Stop.\"#\"Go forward.\"#\"Keep backing.\""},
{"testId":742,"qid":30455,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#33656#33657","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + reaction distance#reaction distance + braking distance + signal distance"},
{"testId":773,"qid":30455,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#33656#33657","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + reaction distance#reaction distance + braking distance + signal distance"},
{"testId":742,"qid":30456,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These scenarios are also described in Georgia Commercial Drivers Manual section 2.5.2.)","imageName":"","correctAnswer":26865,"isBookmarked":0,"aid":"26865#26866#26867#26868","text":"10 feet, 100 feet, and 200 feet toward the approaching traffic#50 feet, 100 feet, and 150 feet toward the approaching traffic#20 feet, 50 feet, and 100 feet toward the approaching traffic#100 feet, 200 feet, and 300 feet toward the approaching traffic"},
{"testId":742,"qid":30459,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":742,"qid":30460,"description":"Which of the following are safe methods of indicating your presence?","explanation":"There are various methods to express your presence and intentions in a secure manner. If it is permissible, softly touch your horn as you approach a passing zone. Flash your headlights from low beam to high beam and back again at night. Use your headlights at night and in low-light situations, such as in severe weather. Turn on your four-way emergency flashers whenever you pull off the road and halt. This is particularly critical at night.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33658#33659#36114","text":"All of the above#Tapping your horn when it's legal and you're about to pass#Flashing your headlights at night when you're about to pass#Using your headlights in bad weather"},
{"testId":761,"qid":30460,"description":"Which of the following are safe methods of indicating your presence?","explanation":"There are various methods to express your presence and intentions in a secure manner. If it is permissible, softly touch your horn as you approach a passing zone. Flash your headlights from low beam to high beam and back again at night. Use your headlights at night and in low-light situations, such as in severe weather. Turn on your four-way emergency flashers whenever you pull off the road and halt. This is particularly critical at night.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33658#33659#36114","text":"All of the above#Tapping your horn when it's legal and you're about to pass#Flashing your headlights at night when you're about to pass#Using your headlights in bad weather"},
{"testId":773,"qid":30460,"description":"Which of the following are safe methods of indicating your presence?","explanation":"There are various methods to express your presence and intentions in a secure manner. If it is permissible, softly touch your horn as you approach a passing zone. Flash your headlights from low beam to high beam and back again at night. Use your headlights at night and in low-light situations, such as in severe weather. Turn on your four-way emergency flashers whenever you pull off the road and halt. This is particularly critical at night.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33658#33659#36114","text":"All of the above#Tapping your horn when it's legal and you're about to pass#Flashing your headlights at night when you're about to pass#Using your headlights in bad weather"},
{"testId":742,"qid":30461,"description":"How far ahead should you glance while driving?","explanation":"The Georgia Commercial Drivers Manual states that you should anticipate the distance that your CMV will go in 12 to 15 seconds. At city driving speeds, your CMV will go one block in around 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":742,"qid":30462,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":10879,"isBookmarked":0,"aid":"10879#10880#19117#19118","text":"Four seconds#Three seconds#Six seconds#Seven seconds"},
{"testId":742,"qid":30465,"description":"Which of the following should you NOT do if you are being followed by someone too closely?","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":25445,"isBookmarked":0,"aid":"6239#25443#25444#25445","text":"Increase your following distance#Avoid quick changes#Avoid tricks such as flashing your brake lights#Speed up"},
{"testId":742,"qid":30467,"description":"Aggressive driving vs. road rage: what's the difference?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":18757,"isBookmarked":0,"aid":"203#18755#18757#33645","text":"All of the above#Level of anger#Intent to do harm or physical assault#The outcome of the situation"},
{"testId":742,"qid":30468,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25482#25484#30113#35129","text":"have all your emotionally difficult conversations in your first hour of driving#read maps or use your phone only when there are no other vehicles around you#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":742,"qid":30469,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give them what they want. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":742,"qid":30472,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Georgia Commercial Drivers Manual suggests dimming your headlights if you are within 500 feet of another vehicle.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":742,"qid":30473,"description":"If your brakes become damp, they may :","explanation":"If your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be harmful. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":773,"qid":30473,"description":"If your brakes become damp, they may :","explanation":"If your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be harmful. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":779,"qid":30473,"description":"If your brakes become damp, they may :","explanation":"If your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be harmful. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":742,"qid":30476,"description":"Which of the following should you perform first when approaching a severe downgrade?","explanation":"When you're ready to begin descending a steep incline, let off of the throttle pedal, change into a low gear, and use engine braking to slow your car. Don't attempt to downshift after your speed has increased. You will be unable to change into a lower gear at that point. You may be unable to shift back into any gear, and all engine braking action will be gone. You may harm an automatic gearbox if you attempt to downshift after your speed has already built up.","imageName":"","correctAnswer":44081,"isBookmarked":0,"aid":"23627#27325#27326#44081","text":"Release the clutch#Accelerate#Release the brakes#Shift into low gear"},
{"testId":742,"qid":30487,"description":"How many hours of sleep does the average individual require per night, according to the Georgia Commercial Drivers Manual?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. The Georgia Commercial Drivers Manual states that the typical individual need seven to eight hours of sleep per 24 hours.","imageName":"","correctAnswer":19131,"isBookmarked":0,"aid":"19131#19132#19133#19134","text":"Seven to eight hours#Six to seven hours#Eight to nine hours#Six to eight hours"},
{"testId":773,"qid":30490,"description":"If you get two serious traffic tickets in three years while driving a commercial motor vehicle (CMV), you will lose your CDL for at least 60 days. Which of the following are examples of such violations?","explanation":"If you are convicted of two significant traffic infractions involving a CMV within three years, you will lose your CDL for at least 60 days. You will lose your CDL for at least 120 days if you commit three significant traffic offences within three years. Violations include exceeding the speed limit by 15 miles per hour or more, driving recklessly, making inappropriate or irregular lane changes, tailgating, and (in places such as Tennessee) texting on a handheld mobile phone.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#36116#36538","text":"All of the above#Tailgating#Speeding at 15 mph or more above the speed limit#Texting on a handheld cell phone"},
{"testId":773,"qid":30491,"description":"Except for the front tires, the minimum tire tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":33637,"isBookmarked":0,"aid":"23631#33637#33638#33639","text":"4/32 inch#2/32 inch#8/32 inch#1 inch"},
{"testId":773,"qid":30497,"description":"Which of the following is NOT a component of the suspension system?","explanation":"Parts of the suspension system like bearing plates and leaf springs might deteriorate from driving over humps. The lock rings and pitman arm both function as components of the steering mechanism.","imageName":"","correctAnswer":18661,"isBookmarked":0,"aid":"203#18661#18771#33652","text":"All of the above#Bearing plate#Pitman arm#Lock ring"},
{"testId":773,"qid":30499,"description":"Always try to back up toward the driver's side because:","explanation":"Even if you have to walk around the block to get your car in this position, you should always reverse toward the driver's side. You will be able to see things lot better if you back toward the driver's side. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. Of course, there may be risks on each side of your car.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"24643#31805#33653#36539","text":"all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side#there are always more hazards on the passenger side"},
{"testId":773,"qid":30504,"description":"When should you use the low range on your vehicle's automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has an automatic gearbox, set it to a low range to prevent the transmission from moving up beyond the preset gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":36540,"isBookmarked":0,"aid":"34279#36540#36541#36542","text":"If you start to get drowsy#When you're about to enter a downgrade#If you feel that you're driving too fast#When you're about to enter an upgrade"},
{"testId":779,"qid":30504,"description":"When should you use the low range on your vehicle's automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has an automatic gearbox, set it to a low range to prevent the transmission from moving up beyond the preset gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":36540,"isBookmarked":0,"aid":"34279#36540#36541#36542","text":"If you start to get drowsy#When you're about to enter a downgrade#If you feel that you're driving too fast#When you're about to enter an upgrade"},
{"testId":773,"qid":30515,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Tennessee Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":773,"qid":30521,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Increase your following distance instead, and if feasible, drive into another lane to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":773,"qid":30524,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":36543,"isBookmarked":0,"aid":"203#33644#33645#36543","text":"All of the above#The level of anger#The outcome of the situation#Intent to do harm"},
{"testId":779,"qid":30524,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":36543,"isBookmarked":0,"aid":"203#33644#33645#36543","text":"All of the above#The level of anger#The outcome of the situation#Intent to do harm"},
{"testId":773,"qid":30531,"description":"Which of the following should you perform first when approaching a severe downgrade?","explanation":"Shift into a low gear and apply engine brakes to decelerate your car as you approach a steep descent. Don't attempt to downshift after your speed has increased. You will be unable to change to a lower gear. You may be unable to shift back into any gear, and all engine braking action will be gone.","imageName":"","correctAnswer":36544,"isBookmarked":0,"aid":"23627#27325#27326#36544","text":"Release the clutch#Accelerate#Release the brakes#Shift into a low gear"},
{"testId":753,"qid":30540,"description":"Which of the following is true about how mirrors are used?","explanation":"You should check your mirrors on a frequent basis, but keep in mind that there are blind areas that your mirrors cannot reveal. A assistant can examine your blind areas for you while you're backing up.","imageName":"","correctAnswer":33237,"isBookmarked":0,"aid":"33236#33237#33238#33239","text":"Check them twice for a lane change#There are blind spots that they cannot show you#Convex mirrors make things look larger and closer than they are#You should look at your mirrors several times a second"},
{"testId":753,"qid":30541,"description":"Which of the following should you NOT do when driving on slick pavement?","explanation":"On a slippery surface, applying retarders or your brakes may cause you to lose control because your wheels will have less traction. Make sure your retarder is turned off, and apply the brakes cautiously.","imageName":"","correctAnswer":33240,"isBookmarked":0,"aid":"33240#33241#33242#33243","text":"Use your retarder#Use a light touch on your brakes and accelerator#Make sure there is adequate space all around your vehicle#Make necessary turns as gently as you can"},
{"testId":753,"qid":30542,"description":"If a hazardous materials situation occurs, you should:","explanation":"If you have an accident with hazardous materials, you should perform the following: 1) Keep people away from the site; 2) alert others, particularly emergency professionals; and 3) use a fire extinguisher to put out a fire or prevent it from spreading.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#33244#33245#33246","text":"Do all of the above#keep people away#avoid smoking and keep open flames away#warn people of the danger"},
{"testId":753,"qid":30543,"description":"Which part of the kingpin should the locking jaws close around?","explanation":"For a solid, secure connection, ensure that the fifth wheel jaws seal around the shank.","imageName":"","correctAnswer":18790,"isBookmarked":0,"aid":"897#18788#18789#18790","text":"None of the above#Base#Head#Shank"},
{"testId":753,"qid":30562,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, you should use your high lights as much as possible to broaden your field of view, as long as it is safe and legal and you will not blind other drivers. When you are within 500 feet of an incoming vehicle in Michigan, you must lower your headlights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":753,"qid":30563,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Michigan Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":753,"qid":30564,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":753,"qid":30565,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#23000#30116#33247","text":"Put on the parking brake whenever necessary#Do all of the above#Apply the trailer brake hand valve#Partly engage the clutch before removing your foot from the brake"},
{"testId":753,"qid":30566,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Michigan Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":753,"qid":30570,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":753,"qid":30571,"description":"When you start going down a long downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":753,"qid":30578,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":755,"qid":30578,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":753,"qid":30584,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'll need two for 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":753,"qid":30589,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Michigan Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":745,"qid":30590,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and does not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":745,"qid":30591,"description":"What is the penalty if you are convicted of your first DUI offense?","explanation":"If you are convicted of DUI, you will lose your CDL for one year, regardless of whether you were driving a CMV or your own car. You will lose your CDL for three years if you were carrying hazardous chemicals at the time. A second DUI conviction will result in the loss of your CDL for life.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30100#30101#30102","text":"All of the above#Lose CDL for 1 year, if DUI while driving a CMV#Lose CDL for 1 year, if DUI while driving your personal vehicle#Lose CDL for 3 years, if DUI while transporting hazardous materials"},
{"testId":745,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":747,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":750,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":751,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":752,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":755,"qid":30592,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":745,"qid":30593,"description":"According to \"Operation Lifesaver,\":","explanation":"The rail safety nonprofit Operation Lifesaver promotes safety at railroad grade crossings and railroad rights-of-way. It advises drivers to anticipate trains to approach at any time of day or night, to watch both ways for a second train after the first one has passed, to never transfer gears on a railroad crossing, and to never race a train to a railroad crossing.","imageName":"","correctAnswer":30104,"isBookmarked":0,"aid":"30103#30104#30105#30106","text":"you should not operate a boat while drinking alcoholic beverages#you should follow several steps to avoid accidents at railroad grade crossings#you should obey new speed limits to avoid harming law enforcement officers#you should do all of the above"},
{"testId":745,"qid":30595,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":747,"qid":30595,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":748,"qid":30595,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":745,"qid":30598,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":747,"qid":30598,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":745,"qid":30599,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":747,"qid":30599,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":748,"qid":30599,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":745,"qid":30600,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While you cannot examine sealed loads, it is still your obligation to verify that your axle weight limitations and gross weight restrictions are not exceeded, therefore double-check them both.","imageName":"","correctAnswer":19006,"isBookmarked":0,"aid":"8615#19004#19005#19006","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight and axle weight limits"},
{"testId":745,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":748,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":752,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":753,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":754,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":761,"qid":30601,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":745,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":746,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":747,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":748,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":749,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":750,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":751,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":752,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":753,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":754,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":755,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":756,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":757,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":761,"qid":30603,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":745,"qid":30605,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, Section 3.1 of the Indiana CDL Manual indicates that you should inspect your cargo within the first 50 miles of your journey, then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":745,"qid":30607,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":748,"qid":30607,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":749,"qid":30607,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":745,"qid":30611,"description":"Which of the following should influence your choice of speed when starting a long downgrade?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":746,"qid":30611,"description":"Which of the following should influence your choice of speed when starting a long downgrade?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":745,"qid":30615,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":745,"qid":30618,"description":"As part of the walk-around inspection, when you look at the front of your vehicle, you do NOT check which of the following?","explanation":"As part of step 5, \"Do Walkaround Inspection,\" you do not examine the temperature and pressure gauges. In step 3, \"Start Engine and Inspect Inside the Cab,\" you should have checked those gauges. Before taking your CDL knowledge exam, review the seven-step inspection technique to get acquainted with what is checked in each phase.","imageName":"","correctAnswer":30110,"isBookmarked":0,"aid":"203#18798#30109#30110","text":"All of the above#The condition of your front axle#The steering mechanism for possible looseness#The air pressure gauge"},
{"testId":745,"qid":30620,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour (one ounce of hard liquor, one-half glass of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":746,"qid":30620,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour (one ounce of hard liquor, one-half glass of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":747,"qid":30620,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour (one ounce of hard liquor, one-half glass of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":748,"qid":30620,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour (one ounce of hard liquor, one-half glass of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":749,"qid":30620,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one standard drink every hour (one ounce of hard liquor, one-half glass of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":745,"qid":30621,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you detect a danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":745,"qid":30622,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you'd have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":747,"qid":30622,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you'd have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":750,"qid":30622,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you'd have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":745,"qid":30625,"description":"To avoid tiredness before a trip, you should:","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than six hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30111#30112","text":"Do all of the above#avoid medications that cause drowsiness#schedule trips for the daytime hours#get 8鈥9 hours of sleep"},
{"testId":746,"qid":30625,"description":"To avoid tiredness before a trip, you should:","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than six hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30111#30112","text":"Do all of the above#avoid medications that cause drowsiness#schedule trips for the daytime hours#get 8鈥9 hours of sleep"},
{"testId":753,"qid":30625,"description":"To avoid tiredness before a trip, you should:","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than six hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30111#30112","text":"Do all of the above#avoid medications that cause drowsiness#schedule trips for the daytime hours#get 8鈥9 hours of sleep"},
{"testId":745,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":747,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":749,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":750,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":752,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":753,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":754,"qid":30627,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25483#25484#25540#30113","text":"smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination"},
{"testId":745,"qid":30629,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#25558","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#Any of the above might happen"},
{"testId":746,"qid":30629,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#25558","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#Any of the above might happen"},
{"testId":745,"qid":30631,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":748,"qid":30631,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":749,"qid":30631,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":751,"qid":30631,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":753,"qid":30631,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":745,"qid":30632,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":746,"qid":30632,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":745,"qid":30633,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18654#18655#18656#30114","text":"Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo#Checking the cargo"},
{"testId":745,"qid":30635,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30115,"isBookmarked":0,"aid":"19085#19086#23635#30115","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Immediately brake hard"},
{"testId":745,"qid":30636,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Indiana CDL Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":745,"qid":30639,"description":"Select the appropriate option for the common medication for colds.","explanation":"Unfortunately, many OTC cold drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV drivers. These negative effects, however, may not apply to all cold medications. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor.","imageName":"","correctAnswer":30119,"isBookmarked":0,"aid":"30118#30119#30120#30121","text":"are perfectly safe and legal#have warning labels indicating they shouldn't be used while you are driving a CMV#are fine, as long as you use them carefully and drink plenty of coffee#are illegal to use while you are operating a CMV"},
{"testId":764,"qid":30640,"description":"If you have multiple driver's licenses,:","explanation":"It is illegal to hold more than one license. You cannot acquire a North Carolina CDL until all previous licenses are surrendered. If you are convicted of possessing multiple licenses, you will be fined at least $250 for the first violation and $500 for each consecutive infraction.","imageName":"","correctAnswer":36250,"isBookmarked":0,"aid":"36249#36250#36251#36252","text":"you will be better prepared#you will be fined at least $250 if it's your first offense#you will receive a warning if it's your first offense#you will be fined at least $500 if it's your first offense"},
{"testId":764,"qid":30641,"description":"Which of the following convictions may result in you being barred from driving a commercial motor vehicle (CMV)?","explanation":"If you are convicted of using a CMV to commit a crime or are repeatedly convicted of certain major traffic infractions, such as excessive speeding, you will be barred from driving CMVs at least temporarily. Even if you are convicted of drunk driving in your own vehicle and your license is canceled, suspended, or revoked, you will be barred from operating CMVs for one year.","imageName":"","correctAnswer":36253,"isBookmarked":0,"aid":"234#18809#36253#36254","text":"Any of the above#Using your personal vehicle to commit a felony#Driving your personal vehicle while impaired by alcohol (DWI)#Speeding while driving your personal vehicle"},
{"testId":764,"qid":30644,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The North Carolina Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":764,"qid":30649,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":766,"qid":30649,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":764,"qid":30652,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the North Carolina Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":764,"qid":30660,"description":"Why is it necessary to use a helper when backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":764,"qid":30678,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the North Carolina Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":764,"qid":30682,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the North Carolina Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":764,"qid":30688,"description":"Any traffic violation, except parking, must be reported to your employer within:","explanation":"If you are convicted of a traffic offense, other than parking, you must report your employer within 30 days, whether the offence happened in your CMV or your personal car.","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23884#23886#27364","text":"10 days#15 days#30 days#45 days"},
{"testId":764,"qid":30689,"description":"Employers are required by federal law to test their commercial drivers for drugs and alcohol. If you test positive for drugs, your employer is required to report it to:","explanation":null,"imageName":"","correctAnswer":36258,"isBookmarked":0,"aid":"36255#36256#36257#36258","text":"the police#all your other employers#the North Carolina State Highway Patrol#the Division of Motor Vehicles"},
{"testId":754,"qid":30690,"description":"According to federal law,You may not consume alcohol for ______ hours before reporting for duty.","explanation":"You may not drink alcohol or be under the influence of alcohol within four hours of reporting for duty as a CMV driver, according to 49 CFR 392.5.","imageName":"","correctAnswer":2754,"isBookmarked":0,"aid":"2754#2756#5000#5001","text":"Four#twelve#Six#eight"},
{"testId":754,"qid":30694,"description":"What can you do to start moving without rolling backward?","explanation":"If your car has a manual gearbox, engage the clutch partially before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":755,"qid":30694,"description":"What can you do to start moving without rolling backward?","explanation":"If your car has a manual gearbox, engage the clutch partially before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":754,"qid":30695,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than 6 hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33629#33630","text":"Do all of the above#avoid medications that cause drowsiness#schedule your trip for the daytime hours#get 8鈥9 hours of sleep beforehand"},
{"testId":754,"qid":30696,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid burning flares, or six fusees must be included in your truck's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":756,"qid":30696,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid burning flares, or six fusees must be included in your truck's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":761,"qid":30696,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid burning flares, or six fusees must be included in your truck's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":773,"qid":30696,"description":"How many reflective warning triangles do you need to have on hand in case of an emergency?","explanation":"Three reflective red triangles, three liquid burning flares, or six fusees must be included in your truck's emergency kit.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":754,"qid":30698,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'll need two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":756,"qid":30698,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'll need two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":757,"qid":30698,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'll need two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":754,"qid":30699,"description":"If your brakes become damp, they may :","explanation":"When your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be disastrous. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":756,"qid":30699,"description":"If your brakes become damp, they may :","explanation":"When your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be disastrous. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":761,"qid":30699,"description":"If your brakes become damp, they may :","explanation":"When your brakes get wet, they may apply unevenly, perform ineffectively, or grip, all of which may be disastrous. Maintain modest pressure on the brakes for a short distance to warm and dry them.","imageName":"","correctAnswer":27987,"isBookmarked":0,"aid":"10656#27987#27988#27989","text":"Do all of the above#apply unevenly#fall off the vehicle#rust immediately"},
{"testId":754,"qid":30700,"description":"How should you check for leaks in a vehicle's hydraulic brakes?","explanation":"To check for brake leaks in a vehicle equipped with hydraulic brakes, first pump the brakes three times. Next, push firmly on the brake pedal for five seconds. If it moves, the brakes may be leaking or have another issue.","imageName":"","correctAnswer":33631,"isBookmarked":0,"aid":"33631#33632#33633#33634","text":"Pump the brakes 3 times. Then apply firm pressure for 5 seconds#Pump the brakes 5 times. Then apply firm pressure for 3 seconds#Pump the brakes 3 times. Then apply firm pressure for 10 seconds#Apply firm pressure for 5 seconds. Then pump the brakes 3 times"},
{"testId":754,"qid":30703,"description":"When is it appropriate to downshift?","explanation":"If you continue to use the brakes to manage your speed on a steep downhill, the brakes will fade (lose their effectiveness). Instead, let out of the throttle and move into a lower gear (even with an automatic transmission). This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":18738,"isBookmarked":0,"aid":"18737#18738#18739#18740","text":"When you start to get drowsy#When you're going down steep grades#When you feel you're driving too fast#When you have trouble going fast enough"},
{"testId":754,"qid":30704,"description":"In which of these instances does a Minnesota farm truck driver not require a CDL?","explanation":"A farm truck driver does not require a CDL in some circumstances, such as if the driver is the farmer himself or an intimate relative of the farmer. For a comprehensive list, see the Minnesota Department of Public Safety or read the section \"Who is Exempt from CDL Licensing in Minnesota?\" in the Minnesota Commercial Driver's Manual.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18817#33635#33636","text":"All of the above#If the driver is the farmer#If the driver is an immediate relative of the farmer#If the driver is an employee of the farmer but not strictly contracted to drive commercial vehicles"},
{"testId":754,"qid":30708,"description":"Except for the front tires, the minimum tire tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":33637,"isBookmarked":0,"aid":"23631#33637#33638#33639","text":"4/32 inch#2/32 inch#8/32 inch#1 inch"},
{"testId":756,"qid":30708,"description":"Except for the front tires, the minimum tire tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":33637,"isBookmarked":0,"aid":"23631#33637#33638#33639","text":"4/32 inch#2/32 inch#8/32 inch#1 inch"},
{"testId":754,"qid":30715,"description":"When descending a high incline, you should:","explanation":"If you continue to use the brakes to manage your speed on a steep downhill, the brakes will fade (lose their effectiveness). Instead, let out of the throttle and move into a lower gear (even with an automatic transmission). This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":33360,"isBookmarked":0,"aid":"33360#33641#33642#33643","text":"downshift into a lower gear#Accelerate#just keep applying the brakes firmly#Release the clutch"},
{"testId":754,"qid":30716,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. Driving recklessly with the purpose to injure someone or physically attacking someone inside their car are both worse than road rage.","imageName":"","correctAnswer":33646,"isBookmarked":0,"aid":"203#33644#33645#33646","text":"All of the above#The level of anger#The outcome of the situation#Whether it's intentional"},
{"testId":754,"qid":30717,"description":"What is the primary reason for your pre-trip inspection?","explanation":"The pre-trip check is used to verify whether the vehicle is safe to drive. Every motorist in Minnesota is required by law to do a pre-trip inspection every day.","imageName":"","correctAnswer":18241,"isBookmarked":0,"aid":"18240#18241#25412#33647","text":"Employer liability#Safety#To make sure your paint is not chipped#To check the cleanliness of your vehicle"},
{"testId":754,"qid":30720,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These conditions are also addressed in Minnesota Commercial Driver's Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":754,"qid":30724,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":755,"qid":30724,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":754,"qid":30725,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":756,"qid":30725,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":761,"qid":30725,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":767,"qid":30725,"description":"Which of the following is NOT a critical component of steering?","explanation":"The steering system includes the tie rod, gear box, and steering shaft. The suspension system includes a leaf spring.","imageName":"","correctAnswer":18660,"isBookmarked":0,"aid":"18401#18404#18660#18767","text":"Tie rod#Gear box#Leaf spring#Steering shaft"},
{"testId":754,"qid":30726,"description":"Which of the following is NOT part of the basic skills test for driving a car?","explanation":"The examiner may instruct you to reverse your car in a straight line, to the right or left, or to imitate backing into an alley. The examiner will not ask you to reverse your car down a steep downhill, and it is not suggested in real life.","imageName":"","correctAnswer":33651,"isBookmarked":0,"aid":"33648#33649#33650#33651","text":"Straight-line backing#Alley dock#Offset backing#Steep downgrade backing"},
{"testId":754,"qid":30727,"description":"Which of the following is NOT a component of the suspension system?","explanation":"Parts of the suspension system like bearing plates and leaf springs might deteriorate from driving over humps. Pitman arms, lock rings, and other steering system components work together to keep the wheels secure.","imageName":"","correctAnswer":18661,"isBookmarked":0,"aid":"203#18661#18771#33652","text":"All of the above#Bearing plate#Pitman arm#Lock ring"},
{"testId":756,"qid":30727,"description":"Which of the following is NOT a component of the suspension system?","explanation":"Parts of the suspension system like bearing plates and leaf springs might deteriorate from driving over humps. Pitman arms, lock rings, and other steering system components work together to keep the wheels secure.","imageName":"","correctAnswer":18661,"isBookmarked":0,"aid":"203#18661#18771#33652","text":"All of the above#Bearing plate#Pitman arm#Lock ring"},
{"testId":761,"qid":30727,"description":"Which of the following is NOT a component of the suspension system?","explanation":"Parts of the suspension system like bearing plates and leaf springs might deteriorate from driving over humps. Pitman arms, lock rings, and other steering system components work together to keep the wheels secure.","imageName":"","correctAnswer":18661,"isBookmarked":0,"aid":"203#18661#18771#33652","text":"All of the above#Bearing plate#Pitman arm#Lock ring"},
{"testId":754,"qid":30729,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18768#18769","text":"All of the above#Mirrors#Cargo covers#Pressure gauges"},
{"testId":754,"qid":30731,"description":"If someone follows you too closely, DO NOT:","explanation":"Some tailgaters believe that no speed is too fast. Don't attempt to outrun the traffic or please a tailgater. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":754,"qid":30732,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#33656#33657","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#perception distance + reaction distance#reaction distance + braking distance + signal distance"},
{"testId":754,"qid":30733,"description":"Which of the following are safe modes of communication?","explanation":"There are various methods to express your presence and intentions in a secure manner. If it is permissible, softly touch your horn as you approach a passing zone. Flash your headlights from low beam to high beam and back again at night. When you're preparing to slow down, softly press your brake pedal to flash your brake lights.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33658#33659#33660","text":"All of the above#Tapping your horn when it's legal and you're about to pass#Flashing your headlights at night when you're about to pass#Flashing your brake lights when you're about to slow down"},
{"testId":756,"qid":30733,"description":"Which of the following are safe modes of communication?","explanation":"There are various methods to express your presence and intentions in a secure manner. If it is permissible, softly touch your horn as you approach a passing zone. Flash your headlights from low beam to high beam and back again at night. When you're preparing to slow down, softly press your brake pedal to flash your brake lights.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33658#33659#33660","text":"All of the above#Tapping your horn when it's legal and you're about to pass#Flashing your headlights at night when you're about to pass#Flashing your brake lights when you're about to slow down"},
{"testId":754,"qid":30734,"description":"Which of the following are serious traffic offenses that could lead to you losing your CDL?","explanation":"Tailgating, speeding (15 mph or more above the statutory speed limit), reckless driving, and operating a commercial motor vehicle (CMV) without a CDL are all serious traffic offences. You will lose your CDL for at least 60 days if you incur two such significant traffic offences within three years. You will lose your CDL for at least 120 days if you incur three such offenses during a three-year period.","imageName":"","correctAnswer":33661,"isBookmarked":0,"aid":"203#18762#33661#33662","text":"All of the above#Listening to your music too loudly#Traveling at 15 mph or more above the posted speed limit#Talking on a cell phone"},
{"testId":754,"qid":30736,"description":"When backing, it's important to have a helper:","explanation":"When backing, it is critical to have someone assist you since you may not be able to notice what is in your blind areas. Before you begin, you should both agree on a series of hand signals for \"Go\" and, importantly, \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":756,"qid":30743,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":756,"qid":30744,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18768#18769","text":"All of the above#Mirrors#Cargo covers#Pressure gauges"},
{"testId":761,"qid":30744,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18768#18769","text":"All of the above#Mirrors#Cargo covers#Pressure gauges"},
{"testId":767,"qid":30744,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18768#18769","text":"All of the above#Mirrors#Cargo covers#Pressure gauges"},
{"testId":773,"qid":30744,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18768#18769","text":"All of the above#Mirrors#Cargo covers#Pressure gauges"},
{"testId":756,"qid":30745,"description":"What are the consequences of operating a commercial motor vehicle (CMV) without a CDL?","explanation":"You cannot operate a commercial motor vehicle (CMV) without a CDL. For violating this regulation, a judge may punish you up to $5,000 or imprison you.","imageName":"","correctAnswer":34272,"isBookmarked":0,"aid":"34270#34271#34272#34273","text":"A fine of up to $1,000 and possible jail time#A fine of up to $2,000 and mandatory jail time#A fine of up to $5,000 or possible jail time#A fine of at least $5,000 and possible jail time"},
{"testId":756,"qid":30747,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33629#33630","text":"Do all of the above#avoid medications that cause drowsiness#schedule your trip for the daytime hours#get 8鈥9 hours of sleep beforehand"},
{"testId":756,"qid":30749,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":756,"qid":30752,"description":"If you are convicted of a second DUI conviction, how long will you lose your CDL privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first transgression serves as a wake-up call and an opportunity to seek aid, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19112#34274","text":"Life#Five years minimum#Ten years minimum#Three years minimum"},
{"testId":756,"qid":30762,"description":"What is the best technique to inspect the area behind and to the sides of your vehicle?","explanation":"Mirrors are your most significant instruments for seeing what's going on around you. Before you go, make any necessary adjustments. Check them often throughout your vacation.","imageName":"","correctAnswer":34275,"isBookmarked":0,"aid":"27314#34275#34276#34277","text":"Check your reflection in other vehicles#Adjust your mirrors before you start your trip and check them regularly#Stop every 50 miles and look around#Just turn your head and look at your blind spots"},
{"testId":756,"qid":30767,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":18241,"isBookmarked":0,"aid":"18240#18241#25412#33647","text":"Employer liability#Safety#To make sure your paint is not chipped#To check the cleanliness of your vehicle"},
{"testId":761,"qid":30767,"description":"What is the primary reason for a vehicle inspection?","explanation":"The primary goal of a vehicle inspection is to establish whether or not your vehicle is safe to drive. This covers your safety as well as the safety of other road users. Furthermore, detecting flaws during a vehicle inspection might assist to avoid an expensive or even deadly breakdown on the road.","imageName":"","correctAnswer":18241,"isBookmarked":0,"aid":"18240#18241#25412#33647","text":"Employer liability#Safety#To make sure your paint is not chipped#To check the cleanliness of your vehicle"},
{"testId":756,"qid":30769,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#25542#31165#34278","text":"let you drive faster#increase your vehicle's stopping power#keep your wheels from locking when you brake hard#increase your stopping distance"},
{"testId":757,"qid":30769,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#25542#31165#34278","text":"let you drive faster#increase your vehicle's stopping power#keep your wheels from locking when you brake hard#increase your stopping distance"},
{"testId":758,"qid":30769,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#25542#31165#34278","text":"let you drive faster#increase your vehicle's stopping power#keep your wheels from locking when you brake hard#increase your stopping distance"},
{"testId":756,"qid":30773,"description":"When should you downshift in a vehicle with an automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has a manual gearbox, downshift; if it has an automatic transmission, choose a low range that prohibits the transmission from moving up beyond the specified gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":34280,"isBookmarked":0,"aid":"34279#34280#34281#34282","text":"If you start to get drowsy#If you're going down a steep downgrade#If you feel you're driving too fast#When your engine speed increases"},
{"testId":761,"qid":30773,"description":"When should you downshift in a vehicle with an automatic transmission?","explanation":"The brakes will fade if you continue to use them to manage your speed on a steep downhill (lose their effectiveness). Instead, if your car has a manual gearbox, downshift; if it has an automatic transmission, choose a low range that prohibits the transmission from moving up beyond the specified gear. This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":34280,"isBookmarked":0,"aid":"34279#34280#34281#34282","text":"If you start to get drowsy#If you're going down a steep downgrade#If you feel you're driving too fast#When your engine speed increases"},
{"testId":756,"qid":30774,"description":"Try to move the vehicle toward the driver's side because:","explanation":"To be in the proper position, you should always turn and back toward the driver's side, even if it takes traveling around the block. You'll be able to see things much better this way. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#34283","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the passenger side"},
{"testId":756,"qid":30775,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":33646,"isBookmarked":0,"aid":"203#33644#33645#33646","text":"All of the above#The level of anger#The outcome of the situation#Whether it's intentional"},
{"testId":761,"qid":30775,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":33646,"isBookmarked":0,"aid":"203#33644#33645#33646","text":"All of the above#The level of anger#The outcome of the situation#Whether it's intentional"},
{"testId":767,"qid":30775,"description":"What are the primary differences between aggressive driving and road rage?","explanation":"Driving aggressively involves being demanding or selfish, which is already risky enough. The worst forms of road rage include hitting someone while driving or physically attacking someone inside their car.","imageName":"","correctAnswer":33646,"isBookmarked":0,"aid":"203#33644#33645#33646","text":"All of the above#The level of anger#The outcome of the situation#Whether it's intentional"},
{"testId":756,"qid":30776,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Missouri Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":756,"qid":30778,"description":"Which of the following are major traffic offences that could result in the loss of your CDL?","explanation":"Tailgating, speeding (15 mph or more above the statutory speed limit), reckless driving, and operating a commercial motor vehicle (CMV) without a CDL are all serious traffic offences. You will lose your CDL for at least 60 days if you incur two such significant traffic offences within three years. You will lose your CDL for at least 120 days if you incur three such offenses during a three-year period.","imageName":"","correctAnswer":33661,"isBookmarked":0,"aid":"203#33661#33662#34284","text":"All of the above#Traveling at 15 mph or more above the posted speed limit#Talking on a cell phone#Playing music too loudly"},
{"testId":756,"qid":30779,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":756,"qid":30780,"description":"What is the first step in the right way to brake when going down a steep slope?","explanation":"If you continue to use the brakes to manage your speed on a steep downhill, the brakes will fade (lose their effectiveness). Instead, let out of the throttle and move into a lower gear (even with an automatic transmission). This will provide a braking effect, known as engine braking, to slow the car. The stronger the engine braking impact, the lower the gear. Only use the brakes when engine braking is inadequate or you wish to come to a complete stop.","imageName":"","correctAnswer":21821,"isBookmarked":0,"aid":"21821#23627#27325#27326","text":"Downshift#Release the clutch#Accelerate#Release the brakes"},
{"testId":756,"qid":30782,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":34286,"isBookmarked":0,"aid":"34285#34286#34287#34288","text":"Reaction distance + braking distance#Perception distance + reaction distance + braking distance#Perception distance + reaction distance#Reaction distance + braking distance + signal distance"},
{"testId":756,"qid":30786,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":757,"qid":30786,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":758,"qid":30786,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":761,"qid":30801,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the New Jersey Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":761,"qid":30802,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#34287#34288","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#Perception distance + reaction distance#Reaction distance + braking distance + signal distance"},
{"testId":761,"qid":30807,"description":"If you are convicted of a second DUI conviction, for what duration will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"11285#11341#11760#18830","text":"2 years#5 years#10 years#Life"},
{"testId":767,"qid":30807,"description":"If you are convicted of a second DUI conviction, for what duration will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"11285#11341#11760#18830","text":"2 years#5 years#10 years#Life"},
{"testId":761,"qid":30810,"description":"Which of the following commercial vehicles is most likely to become caught at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":766,"qid":30810,"description":"Which of the following commercial vehicles is most likely to become caught at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":761,"qid":30811,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":763,"qid":30811,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":767,"qid":30811,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":769,"qid":30811,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Instead, extend your following distance and, if feasible, remain to the right to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":761,"qid":30825,"description":"Which of the following should you perform first when approaching a severe downgrade?","explanation":"Shift into a low gear and apply engine brakes to decelerate your car as you approach a steep descent. Don't attempt to downshift after your speed has increased. You will be unable to change to a lower gear. You may be unable to shift back into any gear, and all engine braking action will be gone.","imageName":"","correctAnswer":36115,"isBookmarked":0,"aid":"23627#27325#27326#36115","text":"Release the clutch#Accelerate#Release the brakes#Shift your transmission into a low gear"},
{"testId":779,"qid":30825,"description":"Which of the following should you perform first when approaching a severe downgrade?","explanation":"Shift into a low gear and apply engine brakes to decelerate your car as you approach a steep descent. Don't attempt to downshift after your speed has increased. You will be unable to change to a lower gear. You may be unable to shift back into any gear, and all engine braking action will be gone.","imageName":"","correctAnswer":36115,"isBookmarked":0,"aid":"23627#27325#27326#36115","text":"Release the clutch#Accelerate#Release the brakes#Shift your transmission into a low gear"},
{"testId":761,"qid":30828,"description":"If you get two serious traffic tickets in three years while driving a commercial motor vehicle (CMV), you will lose your CDL for at least 60 days. Which of the following are examples of such violations?","explanation":"If you are convicted of two significant traffic infractions involving a CMV within three years, you will lose your CDL for at least 60 days. You will lose your CDL for at least 120 days if you commit three significant traffic offences within three years. Violations include exceeding the speed limit by 15 miles per hour or more, driving recklessly, making inappropriate or irregular lane changes, tailgating, and texting or chatting on a handheld mobile phone.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#864#36116#36117","text":"All of the above#Tailgating#Speeding at 15 mph or more above the speed limit#Making improper lane changes"},
{"testId":761,"qid":30829,"description":"Always try to back up toward the driver's side because:","explanation":"Even if you have to walk around the block to get your car in this position, you should always reverse toward the driver's side. You will be able to see things lot better if you back toward the driver's side. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. Of course, there may be risks on each side of your car.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"24643#31805#33653#36118","text":"all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side#there are always more hazards on the left"},
{"testId":763,"qid":30829,"description":"Always try to back up toward the driver's side because:","explanation":"Even if you have to walk around the block to get your car in this position, you should always reverse toward the driver's side. You will be able to see things lot better if you back toward the driver's side. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. Of course, there may be risks on each side of your car.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"24643#31805#33653#36118","text":"all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side#there are always more hazards on the left"},
{"testId":779,"qid":30840,"description":"How many tie-downs must a flatbed load have at the very least?","explanation":"The norm is that one tiedown should be used for every 10 feet of cargo, but at least two must be used per load, regardless of length.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":779,"qid":30842,"description":"The two most important things to keep an eye out for while driving are _____.","explanation":"Large, heavy trucks have extensive stopping distances, requiring you to respond to risks with more time. Look ahead far enough to respond to traffic lights and signs. For example, if a traffic light has remained green for an extended period of time, it may be ready to turn yellow and eventually red. Begin to slow down and prepare to halt. Also, keep an eye out for cars entering your lane or vehicles ahead of you that begin to slow down. If required, adjust your speed or change lanes.","imageName":"","correctAnswer":36741,"isBookmarked":0,"aid":"10435#36739#36740#36741","text":"overpasses and tunnels#detours and emergency personnel#gas stations and rest stops#other vehicles and traffic signs"},
{"testId":779,"qid":30844,"description":"Which of the following statements regarding excessive loads is correct?","explanation":"Special transit permissions are required for cargo that are too long, too wide, or too heavy. Pilot vehicles, a police escort, or specific equipment like as flags or \"Wide Load\" signs may also be required for big loads. Each state may establish its own rules and processes. Visit the Wisconsin Department of Transportation's \"Oversize-Overweight Permits\" homepage at http://wisconsindot.gov/Pages/dmv/com-drv-vehs/mtr-car-trkr/osowgeneral.aspx to learn more about Wisconsin's regulations or to apply for a transit permit.","imageName":"","correctAnswer":36742,"isBookmarked":0,"aid":"23029#36742#36743#36744","text":"All of the above are true#An oversized load requires a special transit permit#An oversized load can only be transported in the daytime#An oversized load must be escorted by the police"},
{"testId":779,"qid":30845,"description":"Which of the following is a critical component of the vehicle's steering system?","explanation":"The steering system's essential components are the gear box (also known as the steering box), tie rod, and pitman arm. Except for the lug nuts, rims, and tires, the other options are suspension system components.","imageName":"","correctAnswer":36746,"isBookmarked":0,"aid":"36745#36746#36747#36748","text":"Lug nuts, rims, and tires#Gear box, pitman arm, and tie rod#Leaf spring, torque rod, and vehicle frame#Axle, main spring, and spring shackle"},
{"testId":779,"qid":30846,"description":"If you are convicted of a second OWI (DUI) conviction in a CMV or your personal vehicle, for what duration will you lose your CDL driving privileges?","explanation":"If you are convicted for the second time of operating while intoxicated (OWI or DUI), you will lose your CDL privileges for life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":779,"qid":30847,"description":"If you must stop on a road or the side of a road, you must set out your warning devices within:","explanation":"Federal law requires you to place emergency warning equipment (such as reflective triangles) within 10 minutes after stopping. Federal requirements additionally specify the location of your warning devices in relation to your vehicle. For further information, see Section 2.5.2 of the Wisconsin Commercial Driver's Manual. [49 CFR 392 .22]","imageName":"","correctAnswer":36459,"isBookmarked":0,"aid":"36458#36459#36460#36461","text":"5 minutes#10 minutes#15 minutes#20 minutes"},
{"testId":779,"qid":30852,"description":"Which of the following should you do to keep awake while driving?","explanation":"Driving for lengthy periods of time on the highway without stopping may result in highway hypnosis. This is a drowsy or unconscious state caused by monotony, the noises of the wind and tires on the pavement, and the continuous hum of the motor. Stop and take a rest break every two hours or 100 miles to avoid sleepiness.","imageName":"","correctAnswer":36751,"isBookmarked":0,"aid":"36749#36750#36751#36752","text":"Keep the cab warm and quiet#Schedule trips for the hours at which you're normally asleep#Take short breaks before you start to feel drowsy#Drink coffee"},
{"testId":779,"qid":30853,"description":"What should you inspect a sealed load before transporting it?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment. Check that your load does not cause your vehicle to exceed the gross vehicle weight or axle weight restrictions.","imageName":"","correctAnswer":36753,"isBookmarked":0,"aid":"36753#36754#36755#36756","text":"Whether the load is properly balanced and secured in the vehicle#Whether the seal on the load is secure#Whether the seal on the load is authentic#Whether the seal's numbers match the placard's numbers"},
{"testId":779,"qid":30856,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, its initial effects are on your judgement and self-control. As a consequence, you may not be aware of how inebriated you are becoming and can make risky choices.","imageName":"","correctAnswer":36759,"isBookmarked":0,"aid":"10667#36757#36758#36759","text":"Reaction time#sense of balance#coordination and muscle control#judgment and self-control"},
{"testId":779,"qid":30857,"description":"What is the best technique to inspect the area behind and to the sides of your vehicle?","explanation":"Check your mirrors often when driving and even more frequently in unique circumstances to know what's going on behind and to the sides of your car. Adjust your mirrors before you start driving so that each mirror displays a different portion of your car.","imageName":"","correctAnswer":21091,"isBookmarked":0,"aid":"21091#34276#36113#36760","text":"Check your mirrors regularly#Stop every 50 miles and look around#Turn your head to check your blind spots#Look out your window"},
{"testId":779,"qid":30858,"description":"Where should you be looking ahead of your vehicle while driving?","explanation":"Look 12 to 15 seconds ahead to see potential risks, but don't dismiss anything closer or to the side. Good drivers continually change their focus between near and distance. They also check their mirrors on a regular basis.","imageName":"","correctAnswer":36761,"isBookmarked":0,"aid":"18885#18888#36761#36762","text":"Toward the right side of the roadway#Toward the left side of the roadway#Back and forth, near and far#Straight ahead"},
{"testId":779,"qid":30859,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":20916,"isBookmarked":0,"aid":"20916#36763#36764#36765","text":"Before starting down a hill and before entering a curve#Before starting down a hill and before going up a hill#Before starting up a hill and before entering a curve#Before starting up a hill and before entering a tunnel"},
{"testId":779,"qid":30860,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee or fresh air. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"234#18631#18894#36766","text":"Any of the above#Time#Cold, fresh air#A cup of coffee"},
{"testId":779,"qid":30862,"description":"According to Wisconsin's Implied Consent Law,:","explanation":"By driving on Wisconsin's public highways, you imply permission to chemical testing for alcohol and drugs at the request of a law enforcement officer, according to Wisconsin's Implied Consent Law. If this is your first rejection, you will be barred from driving commercial motor vehicles (CMVs) for a year. This is the same sentence you would face if you are convicted for the first time of operating while intoxicated (OWI).","imageName":"","correctAnswer":36768,"isBookmarked":0,"aid":"36767#36768#36769#36770","text":"drivers under 21 are not allowed to drink and drive#drivers must consent to alcohol or drug testing if asked to do so by a police officer#drivers receive mandatory jail time after a second conviction for operating while intoxicated (OWI)#drivers must consent to a search of their vehicle if asked by a police officer"},
{"testId":779,"qid":30863,"description":"If it was your first OWI conviction, you'll lose your CDL 聽driving privileges聽for聽:","explanation":"If you are convicted of OWI (DUI) for the first time, you will be barred from driving a commercial motor vehicle for one year. It makes no difference whether you were driving a CMV or another vehicle at the time of the crime. If you are convicted of OWI for the second time, you will be barred from driving for the rest of your life.","imageName":"","correctAnswer":18901,"isBookmarked":0,"aid":"14019#18899#18901#18918","text":"life#4 years minimum#1 year minimum#2 years minimum"},
{"testId":779,"qid":30866,"description":"On a lengthy or steep downhill, once you've reached your \"safe\" speed, brake until you're moving:","explanation":"On a steep downgrade, don't keep applying your brakes continuously because this might cause them to fade (become less effective) (become less effective). Instead, apply your brakes sparingly as a supplement to engine braking. Choose a \"safe\" speed. Allow your car to achieve this speed. Brake gently until you are going 5 mph slower. Then gradually raise your speed back to the \"safe\" level. Continue adjusting your speed up and down in this manner.","imageName":"202005130503379933.jpg","correctAnswer":36774,"isBookmarked":0,"aid":"36771#36772#36773#36774","text":"20 mph slower#15 mph slower#10 mph slower#5 mph slower"},
{"testId":779,"qid":30867,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#427#428#7322","text":"4 seconds#5 seconds#6 seconds#3 seconds"},
{"testId":779,"qid":30870,"description":"If someone follows you too closely, DO NOT:","explanation":"Don't attempt to outrun the traffic or please a tailgater. Some tailgaters believe that no speed is too fast. Increase your following distance instead, and if feasible, drive into another lane to let the tailgater to pass.","imageName":"202006041818594397.jpg","correctAnswer":6475,"isBookmarked":0,"aid":"6475#6920#33654#33655","text":"Speed up#Increase your following distance#avoid quick changes#avoid tricks such as flashing your brake lights"},
{"testId":779,"qid":30872,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have difficult conversations while driving. Preprogram your favorite radio stations and GPS navigation routes so you don't have to fiddle with these settings while driving. Avoid using your cell phone until you are finished driving for the day.","imageName":"","correctAnswer":25481,"isBookmarked":0,"aid":"25481#25482#25484#35129","text":"preset your favorite radio stations#have all your emotionally difficult conversations in your first hour of driving#read maps or use your phone only when there are no other vehicles around you#eat, drink, and smoke only on straight portions of the road"},
{"testId":779,"qid":30875,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Wisconsin Commercial Driver's Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":779,"qid":30882,"description":"How should you brake in a vehicle equipped with antilock brakes (ABS)?","explanation":"In typical conditions, use ABS to brake the same way you always have. In an emergency, though, you may completely engage the brakes. ABS prevents the wheels from locking.","imageName":"","correctAnswer":36775,"isBookmarked":0,"aid":"36775#36776#36777#36778","text":"Brake as you always have, except that you can fully apply the brakes in an emergency stop#Brake as you always have, except that you do not fully apply the brakes in an emergency stop#Brake more gently than you would when driving a vehicle without ABS#Brake harder than you would when driving a vehicle without ABS"},
{"testId":779,"qid":30887,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These scenarios are also described in Wisconsin Commercial Driver's Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":779,"qid":30889,"description":"Which of the following escape ramp assertions is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look find and utilize one of these escape ramps, which may save lives, equipment, and cargo regardless of your speed.","imageName":"","correctAnswer":36780,"isBookmarked":0,"aid":"36779#36780#36781#36782","text":"Signs along your route show where they are located#They are of no use if you're traveling too fast#They may be made of loose, soft material#They may turn uphill"},
{"testId":746,"qid":30890,"description":"Truck drivers are in a great position to observe and report human trafficking occurring at truck stops and rest areas. Which of the following trafficking red signs should you check for?","explanation":"Victims of human trafficking are often relocated. They may be victims of physical, sexual, or forced substance abuse, as well as forced prostitution. DO NOT approach any potential traffickers. Instead, consider persons, cars, times, dates, and places. If possible, take photographs. Then dial 1-888-3737-888 to reach the National Hotline (US).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30532#30533#30534","text":"All of the above#An individual who lacks knowledge of his or her location or community#An individual not in control of his or her own travel documents, such as a passport or driver's license#An individual who seems tense, afraid, nervous, or submissive"},
{"testId":746,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":748,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":749,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":752,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":766,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":769,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":777,"qid":30892,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":746,"qid":30903,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":746,"qid":30907,"description":"Which of the following can start a vehicle fire?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18616#18618#30535#30536","text":"Flammable cargo with proper ventilation#Short circuits and loose connections#Smoking in a rest area#Spilled fuel that is cleaned up quickly and properly"},
{"testId":746,"qid":30912,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. Although having a first-aid certificate can be useful at some time in your career, it is not one of the four fundamental skills.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":746,"qid":30915,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":746,"qid":30916,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause the car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30115,"isBookmarked":0,"aid":"19085#19086#23635#30115","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Immediately brake hard"},
{"testId":746,"qid":30922,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. When required, use the parking brake to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#30537","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Put on the parking brake when necessary"},
{"testId":746,"qid":30929,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you detect a danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":747,"qid":30929,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you detect a danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":746,"qid":30930,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Iowa Commercial Driver's License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":746,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":747,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":748,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":749,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":750,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":751,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":752,"qid":30931,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":746,"qid":30933,"description":"Which of the following is NOT a basic part of shifting up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. Acceleration is not used until the very end, and never when squeezing the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":746,"qid":30936,"description":"If you are convicted of a second DUI in a CMV, how long will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a commercial motor vehicle twice, you will lose your Iowa CDL privileges for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent from the alcohol in two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#18901#18918#18919","text":"Life#1 year minimum#2 years minimum#5 years minimum"},
{"testId":746,"qid":30937,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":749,"qid":30937,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":752,"qid":30941,"description":"To avoid tiredness before a journey, you should :","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than 6 hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25876#25878#30112","text":"Do all of the above#schedule trips during the daytime hours#avoid medications that cause drowsiness#get 8鈥9 hours of sleep"},
{"testId":752,"qid":30942,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25460#31165","text":"let you drive faster#shorten your stopping distance#increase a vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":752,"qid":30946,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":752,"qid":30960,"description":"If you are guilty of a second OUI conviction in a CMV or your own vehicle, for what duration will you lose your CDL driving privileges?","explanation":"If you are convicted of OUI for the second time in either a CMV or your own vehicle, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV qualifies as OUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you acquire an OUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#18918#18919#18921","text":"Life#2 years minimum#5 years minimum#10 years minimum"},
{"testId":752,"qid":30967,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#32746","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police on your cell phone if you can do it safely"},
{"testId":752,"qid":30970,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#32747","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to get the following distance"},
{"testId":752,"qid":30982,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Massachusetts Commercial Driver's Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":752,"qid":30983,"description":"Which of the following is NOT one of the four skill categories required for commercial vehicle operation?","explanation":"The four fundamental abilities required for safe operation of a commercial vehicle are accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the Massachusetts Commercial Driver's Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":752,"qid":30984,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, section 2.1.6 of the Massachusetts Commercial Driver's Manual indicates that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":752,"qid":30986,"description":"How long will you lose your CDL if you're convicted of using your own car to commit a crime involving the transportation, distribution, or manufacturing of prohibited substances?","explanation":"You will lose your CDL for life if you are convicted of using any vehicle to conduct a crime involving the transport, distribution, or manufacturing of prohibited drugs. Don't join in the illegal drug trade, no matter how much money you're offered. It might be your final truck driving job.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#18901#18919#18921","text":"Life#1 year minimum#5 years minimum#10 years minimum"},
{"testId":752,"qid":30989,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":777,"qid":30995,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, according to the Washington State DOL Commercial Driver Guide, you should inspect your cargo within the first 50 miles of your journey and then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":777,"qid":31009,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the Washington State DOL Commercial Driver Guide. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":777,"qid":31016,"description":"You don't want to drive while distracted, so you:","explanation":null,"imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":777,"qid":31017,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Washington State DOL Commercial Driver Guide, Section 2.11, suggests dimming your headlights anytime you are within 500 feet of an oncoming vehicle, which is the law in Washington State. You must also lower your headlights if you are within 300 feet of a vehicle you are following, according to Washington State law. [RCW 46.37 .23]","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":777,"qid":31024,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5 of the Washington State DOL Commercial Driver Guide also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":777,"qid":31029,"description":"When you practice to prepare for the CDL skills test, do not:","explanation":"You are not permitted to practice at any official CDL skills test location. Try practicing in other places that you feel are comparable.","imageName":"","correctAnswer":36730,"isBookmarked":0,"aid":"36730#36731#36732#36733","text":"practice at official CDL skills test sites#practice as often as possible#practice at locations that you believe are similar to CDL skills test sites#practice the procedures and maneuvers that you believe may be required on the CDL skills test"},
{"testId":777,"qid":31037,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states. A load of dirt, sand, or gravel that may spill or otherwise escape from the truck bed must be covered under Washington State law unless there is at least six inches between the top of the truck bed and where the load hits the interior of the bed. [RCW 46.61 .655]","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":777,"qid":31039,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted. It's worth noting that Washington State has an especially harsh distracted-driving statute. You may not participate in any activity that is unrelated to driving and interferes with safe driving while operating a motor vehicle. This might involve a heated argument that distracts the motorist to the point of impairing safe driving. [RCW 46.61 .673]","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":732,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":733,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":734,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":735,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":739,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":762,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":764,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":765,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":766,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":768,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":770,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":771,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":772,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":774,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":775,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":776,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":777,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":778,"qid":31040,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":732,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":733,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":734,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":735,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":738,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":739,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":754,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":755,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":757,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":758,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":759,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":760,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":762,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":764,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":765,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":766,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":768,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":770,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":771,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":772,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":774,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":775,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":776,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":777,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":778,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":780,"qid":31041,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#24643#31805#33653","text":"it's more comfortable for turning your neck#all of the above are true#you can see better, watching the vehicle's rear from the side window#your truck will probably pull toward the driver's side"},
{"testId":731,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":732,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":733,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":734,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":735,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":738,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":739,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":740,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":742,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":760,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":761,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":762,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":764,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":765,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":766,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":767,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":768,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":770,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":771,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":772,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":774,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":775,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":776,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":777,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":778,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":779,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":780,"qid":31042,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":731,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":732,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":733,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":734,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":735,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":738,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":739,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":741,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":742,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":743,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":744,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":745,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":746,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":747,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":748,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":749,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":750,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":751,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":752,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":753,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":754,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":755,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":756,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":757,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":758,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":759,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":760,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":761,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":762,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":763,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":764,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":765,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":766,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":768,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":769,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":770,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":771,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":772,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":773,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":774,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":775,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":777,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":778,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":779,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":780,"qid":31043,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leakage, so look for oil on the wheels and any apparent signs of fractures or leaks.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":732,"qid":31044,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices such as flares or reflective triangles, and extra electrical fuses are also required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":734,"qid":31044,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices such as flares or reflective triangles, and extra electrical fuses are also required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":735,"qid":31044,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices such as flares or reflective triangles, and extra electrical fuses are also required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":742,"qid":31044,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices such as flares or reflective triangles, and extra electrical fuses are also required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":773,"qid":31044,"description":"Which of the following will not be required in an emergency kit?","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices such as flares or reflective triangles, and extra electrical fuses are also required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":732,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":758,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":759,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":760,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":762,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":763,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":764,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":765,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":766,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":767,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":768,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":769,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":770,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":771,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":772,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":775,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":776,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":777,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":779,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":780,"qid":31045,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see the hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":732,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":733,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":734,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":738,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":739,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":757,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":758,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":759,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":760,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":762,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":763,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":765,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":766,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":768,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":770,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":771,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":772,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":775,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":776,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":777,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":778,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":780,"qid":31046,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":732,"qid":31047,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#18918#18919#18921","text":"Life#2 years minimum#5 years minimum#10 years minimum"},
{"testId":732,"qid":31048,"description":"How many hours of sleep does the average individual require per night, according to the Alabama CDL Manual?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. The Alabama Commercial Driver License Manual states that the typical human need seven to eight hours of sleep per 24 hours.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":731,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":732,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":733,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":734,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":735,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":738,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":739,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":759,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":760,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":761,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":762,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":764,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":765,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":766,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":767,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":768,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":769,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":770,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":771,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":772,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":773,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":774,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":775,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":776,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":778,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":780,"qid":31049,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#35129","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#eat, drink, and smoke only on straight portions of the road"},
{"testId":732,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":733,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":734,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":735,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":738,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":739,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":741,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":742,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":754,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":755,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":756,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":757,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":758,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":759,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":760,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":761,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":762,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":763,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":764,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":765,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":766,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":767,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":768,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":770,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":771,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":772,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":773,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":774,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":775,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":776,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":777,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":778,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":779,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":780,"qid":31050,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#33640","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your trip sooner"},
{"testId":731,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":732,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":733,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":734,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":735,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":738,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":739,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":741,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":742,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":762,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":763,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":764,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":765,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":766,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":768,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":770,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":771,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":772,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":773,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":774,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":775,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":776,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":777,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":778,"qid":31051,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":732,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":733,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":734,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":735,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":738,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":739,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":741,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":742,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":750,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":751,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":752,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":753,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":754,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":755,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":756,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":757,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":758,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":759,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":760,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":761,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":762,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":764,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":765,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":766,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":768,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":770,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":771,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":772,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":773,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":774,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":775,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":777,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":778,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":780,"qid":31052,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but shouldn't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":732,"qid":31053,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the Alabama Commercial Driver License Manual advises that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":732,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":733,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":734,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":735,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":738,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":741,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":748,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":749,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":750,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":752,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":753,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":754,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":755,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":756,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":757,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":758,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":759,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":760,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":761,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":762,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":764,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":765,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":766,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":767,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":768,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":770,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":771,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":772,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":773,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":774,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":775,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":776,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":777,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":778,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":779,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":780,"qid":31054,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":732,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":733,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":734,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":735,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":738,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":739,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":740,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":743,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":744,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":745,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":746,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":747,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":749,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":750,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":751,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":752,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":753,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":755,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":757,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":758,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":759,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":760,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":762,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":763,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":764,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":765,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":766,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":768,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":770,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":771,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":772,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":774,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":775,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":776,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":777,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":778,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":780,"qid":31055,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. The other three options are suspension system components.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":732,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":733,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":734,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":735,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":738,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":739,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":742,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":762,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":764,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":765,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":766,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":767,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":768,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":770,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":771,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":772,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":774,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":775,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":776,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":777,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":778,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":779,"qid":31056,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options are either ineffective or entail risky diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":732,"qid":31057,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or a vehicle you are pursuing, you should lower your headlights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"18694#18695#18696#42551","text":"Low beams#High beams#Novelty lights#Four-way flashers"},
{"testId":732,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":733,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":735,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":739,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":742,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":753,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":754,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":755,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":757,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":758,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":760,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":761,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":762,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":764,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":765,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":766,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":767,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":768,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":769,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":770,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":771,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":772,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":773,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":774,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":775,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":776,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":777,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":778,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":780,"qid":31058,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":732,"qid":31059,"description":"How far ahead should you look while driving, as per the Alabama CDL Manual?","explanation":"The Alabama Commercial Driver License Manual states that you should anticipate the distance that your CMV will drive in 12 to 15 seconds. At city driving speeds, your CMV will go one block in around 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":732,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":734,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":735,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":738,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":739,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":741,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":742,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":760,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":762,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":763,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":764,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":765,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":766,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":768,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":770,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":771,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":772,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":774,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":775,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":776,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":777,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":778,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":779,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":780,"qid":31060,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":732,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":733,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":734,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":735,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":738,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":739,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":741,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":742,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":759,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":760,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":761,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":762,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":763,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":764,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":765,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":766,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":768,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":769,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":770,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":771,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":772,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":773,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":774,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":775,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":777,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":778,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":780,"qid":31061,"description":"When you start going down a steep downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#33248","text":"All of the above#The steepness of the grade#The road conditions#The total weight of your vehicle and its cargo"},
{"testId":732,"qid":31062,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Alabama Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":731,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":732,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":733,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":734,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":735,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":739,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":757,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":758,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":759,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":760,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":761,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":762,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":764,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":765,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":766,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":768,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":770,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":771,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":772,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":773,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":774,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":775,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":776,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":777,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":778,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":780,"qid":31063,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. Set the parking brake whenever required to prevent the vehicle from rolling back. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18964#23000#30116#34595","text":"Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve#Set the parking brake whenever necessary"},
{"testId":732,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":733,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":734,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":738,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":739,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":758,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":759,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":760,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":762,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":765,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":768,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":770,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":771,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":772,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":774,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":775,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":776,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":778,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":780,"qid":31064,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":732,"qid":31065,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely include accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Alabama Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":731,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":732,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":733,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":739,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":742,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":754,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":755,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":756,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":759,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":760,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":761,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":762,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":764,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":765,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":766,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":767,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":768,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":769,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":770,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":771,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":772,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":773,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":774,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":775,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":777,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":778,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":779,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":780,"qid":31066,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":732,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":733,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":734,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":735,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":738,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":739,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":742,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":757,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":758,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":759,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":760,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":761,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":762,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":764,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":765,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":766,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":767,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":768,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":770,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":771,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":772,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":773,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":774,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":775,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":777,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":778,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":780,"qid":31067,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":732,"qid":31068,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you should have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":765,"qid":31068,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you should have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":768,"qid":31068,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you should have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":773,"qid":31068,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you should have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":780,"qid":31068,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you should have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":732,"qid":31069,"description":"Which of the following is true about people who are slow?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":746,"qid":31069,"description":"Which of the following is true about people who are slow?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":732,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":733,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":734,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":735,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":738,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":739,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":742,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":762,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":763,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":764,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":765,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":766,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":768,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":769,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":770,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":771,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":772,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":773,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":774,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":775,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":776,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":777,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":778,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":779,"qid":31070,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":732,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":733,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":735,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":738,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":739,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":760,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":762,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":763,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":765,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":767,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":768,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":770,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":771,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":772,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":774,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":775,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":776,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":778,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":780,"qid":31071,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and over-the-counter drugs may produce drowsiness, sleepiness, or decreased coordination, making CMV operators unsafe. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":732,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":760,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":761,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":762,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":763,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":764,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":765,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":766,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":768,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":769,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":770,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":771,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":772,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":773,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":774,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":775,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":776,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":777,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":778,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":780,"qid":31072,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is often one standard drink every hour, with a standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":732,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":741,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":764,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":766,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":773,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":776,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":777,"qid":31073,"description":"Which of the following is most likely to start a fire in a vehicle?","explanation":"Hazards include smoking, combustible cargo, and gasoline spills. However, when controlled properly, they shouldn't cause a fire unless there are exceptional conditions, such a short circuit or loose connection.","imageName":"","correctAnswer":18618,"isBookmarked":0,"aid":"18615#18616#18617#18618","text":"Driver smoking in a rest area#Flammable cargo with proper ventilation#Spilled fuel cleaned up quickly and properly#Short circuits and loose connections"},
{"testId":732,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":733,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":738,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":739,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":741,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":742,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":747,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":750,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":752,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":757,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":758,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":759,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":760,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":762,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":764,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":765,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":766,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":767,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":768,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":769,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":770,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":771,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":772,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":774,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":775,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":778,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":780,"qid":31074,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"Unless you have been wounded, it is your obligation to ensure that others at the accident site can see your car. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":732,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":733,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":734,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":735,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":738,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":757,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":758,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":759,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":760,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":762,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":765,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":768,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":770,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":771,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":772,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":774,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":775,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":776,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":777,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":778,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":780,"qid":31075,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":732,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":733,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":734,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":735,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":738,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":739,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":742,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":749,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":750,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":751,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":752,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":753,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":754,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":755,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":756,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":757,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":758,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":759,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":760,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":761,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":762,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":763,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":764,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":765,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":766,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":767,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":768,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":770,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":771,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":772,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":773,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":774,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":775,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":777,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":778,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":779,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":780,"qid":31076,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, perform a right turn with your vehicle's rear as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":732,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":733,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":734,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":735,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":738,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":739,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":759,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":760,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":762,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":763,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":765,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":768,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":769,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":770,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":771,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":772,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":774,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":775,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":776,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":777,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":778,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":780,"qid":31077,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"During your pre-trip inspection, check for evidence of leaks, such as pools on the ground or fluids spilling on the bottom of the engine or gearbox. Also, inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":732,"qid":31078,"description":"When approached by an aggressive motorist, which of the following should you do?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":732,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":733,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":734,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":735,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":738,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":739,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":742,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":745,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":747,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":748,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":749,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":750,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":751,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":752,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":753,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":754,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":755,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":756,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":757,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":758,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":759,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":760,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":761,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":762,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":764,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":765,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":766,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":768,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":770,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":771,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":772,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":773,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":774,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":775,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":776,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":777,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":778,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":780,"qid":31079,"description":"In which of the two situations below should you downshift?","explanation":"Downshifting before heading down a slope allows you to use engine brakes. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":732,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":733,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":734,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":735,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":738,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":739,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":745,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":747,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":748,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":749,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":750,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":751,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":752,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":753,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":754,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":755,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":756,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":757,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":758,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":759,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":760,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":761,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":762,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":764,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":765,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":766,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":768,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":769,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":770,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":771,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":772,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":773,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":774,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":775,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":777,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":778,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":780,"qid":31080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature falls below freezing, certain portions of the road will begin to ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":732,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":733,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":734,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":735,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":738,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":739,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":759,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":760,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":762,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":763,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":765,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":766,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":768,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":770,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":771,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":772,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":774,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":775,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":776,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":777,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":778,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":780,"qid":31081,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":732,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":733,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":734,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":735,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":738,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":739,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":742,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":756,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":757,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":758,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":759,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":760,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":761,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":762,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":764,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":765,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":766,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":768,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":769,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":770,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":771,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":772,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":773,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":774,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":775,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":776,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":777,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":778,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":779,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":780,"qid":31082,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":732,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":733,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":734,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":735,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":738,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":739,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":742,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":772,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":773,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":774,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":775,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":776,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":777,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":778,"qid":31083,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":732,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":733,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":734,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":735,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":738,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":739,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":741,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":748,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":750,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":751,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":752,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":753,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":755,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":757,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":758,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":759,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":760,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":762,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":764,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":765,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":766,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":768,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":770,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":771,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":772,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":774,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":775,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":776,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":778,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":780,"qid":31084,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":732,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":733,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":734,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":735,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":738,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":739,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":759,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":760,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":762,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":764,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":765,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":766,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":768,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":769,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":770,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":772,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":773,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":774,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":776,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":777,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":778,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":780,"qid":31085,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":732,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":733,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":734,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":735,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":739,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":740,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":743,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":744,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":746,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":747,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":748,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":749,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":750,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":751,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":752,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":754,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":755,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":757,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":758,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":759,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":760,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":761,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":762,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":763,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":765,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":767,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":768,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":770,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":771,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":772,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":774,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":775,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":777,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":778,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":780,"qid":31086,"description":"How much does the whole car weigh?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":732,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":733,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":738,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":739,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":740,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":742,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":743,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":744,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":745,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":746,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":747,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":749,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":750,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":752,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":753,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":754,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":755,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":756,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":757,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":758,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":759,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":760,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":762,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":763,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":764,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":765,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":766,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":768,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":770,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":771,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":772,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":773,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":774,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":775,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":777,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":778,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":779,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":780,"qid":31087,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with regular water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":732,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":734,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":741,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":742,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":745,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":746,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":748,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":749,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":752,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":753,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":754,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":756,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":761,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":764,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":766,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":773,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":776,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":777,"qid":31088,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#30117","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open the windows for some fresh air"},
{"testId":732,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":742,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":764,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":773,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":776,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":777,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":779,"qid":31089,"description":"Which of the following could become entangled at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with its landing gear modified to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":735,"qid":31091,"description":"How many hours of sleep do you need to keep yourself alert while driving?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. You should acquire enough sleep before your vacation. Adults need 8 to 9 hours of sleep every night to be awake.","imageName":"","correctAnswer":19133,"isBookmarked":0,"aid":"19131#19132#19133#19134","text":"Seven to eight hours#Six to seven hours#Eight to nine hours#Six to eight hours"},
{"testId":735,"qid":31095,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":735,"qid":31100,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely include accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Arkansas Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":735,"qid":31104,"description":"What exactly is a \"pull-up?\":","explanation":"When backing a vehicle, particularly a tractor-trailer, you may need to pull forward to straighten or reposition the vehicle at a better angle. A pull-up is a kind of corrective technique. However, you should be able to successfully back your car without too many such modifications. Your examiner may punish you for doing too many pull-ups during your CDL skills test.","imageName":"","correctAnswer":21284,"isBookmarked":0,"aid":"19016#21283#21284#43992","text":"Pulling up to a curb to pick up a load#A maneuver performed by a tow truck to pick up an overturned truck#Pulling forward while backing a trailer to reposition it#When pulling off the shoulder, pulling forward until the trailer is straight"},
{"testId":735,"qid":31115,"description":"Which of the following drivers might not need a CDL in Arkansas to drive a CMV?","explanation":"CDLs are not required in Arkansas for the following types of drivers: persons driving farm vehicles to and from a farm within 150 miles; drivers of emergency or fire equipment; active-duty military drivers operating military vehicles; and certain government employees driving snowplows or vehicles to sand or salt roadways. Check with the Arkansas Department of Transportation if you feel you may be eligible for a CDL exemption.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43993#43994#43995","text":"All of the above#Active-duty military personnel driving military vehicles#Persons driving farm vehicles to and from a farm#Firefighters driving fire trucks"},
{"testId":735,"qid":31117,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These scenarios are also discussed in Arkansas Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":735,"qid":31126,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of another vehicle, the Arkansas Commercial Driver License Manual suggests reducing your headlights to low beam.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":735,"qid":31130,"description":"How far ahead should you glance when driving?","explanation":"The Arkansas Commercial Driver License Manual states that you should anticipate the distance that your CMV will drive in 12 to 15 seconds. At city driving speeds, your CMV will go one block in around 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":749,"qid":31145,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial vehicle include properly accelerating, steering, stopping, and backing up, as detailed in section 2.2 of the Louisiana CDL Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":749,"qid":31149,"description":"To renew your Louisiana CDL, you must have had a background check with your fingerprints done within the last:","explanation":"To renew your Louisiana CDL, you must have had your fingerprint background check done within the last 180 days.","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24049#25858#31511","text":"30 days#90 days#180 days#365 days"},
{"testId":749,"qid":31155,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Louisiana CDL Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":749,"qid":31160,"description":"Which of the following should you NOT check while driving?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":749,"qid":31165,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, section 3.1 of the Louisiana CDL Manual indicates that you should inspect your cargo within the first 50 miles of your journey, then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":749,"qid":31178,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a commercial or noncommercial vehicle for the second time, you will lose your CDL rights for life. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":749,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":750,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":751,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":753,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":754,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":755,"qid":31184,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might cause a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":749,"qid":31188,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":750,"qid":31188,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":751,"qid":31188,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":752,"qid":31188,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":755,"qid":31188,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":748,"qid":31191,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'd have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":749,"qid":31191,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'd have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":752,"qid":31191,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you'd have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":748,"qid":31195,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":760,"qid":31195,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":761,"qid":31195,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":780,"qid":31195,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#18973#25542#31165","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your wheels from locking when you brake hard"},
{"testId":748,"qid":31203,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of the truck that designate the cargo's danger class for those who need to know, such as emergency responders and people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":749,"qid":31203,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of the truck that designate the cargo's danger class for those who need to know, such as emergency responders and people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":751,"qid":31203,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of the truck that designate the cargo's danger class for those who need to know, such as emergency responders and people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":748,"qid":31209,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, section 3.1 of the Kentucky CDL Manual indicates that you should inspect your cargo within the first 50 miles of your journey, then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":748,"qid":31212,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":26887,"isBookmarked":0,"aid":"25556#26887#26888#31166","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it"},
{"testId":748,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":749,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":750,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":751,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":752,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":753,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":755,"qid":31215,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance your vehicle travels between when you see a hazard and when your brain processes it), reaction distance (the distance your vehicle travels between when your brain orders your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":748,"qid":31216,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These scenarios are also addressed in Kentucky CDL Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":748,"qid":31218,"description":"Which lights should you use as much as possible while driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and does not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":748,"qid":31219,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26876#31167","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Accelerating while pressing the clutch and turning toward the driver's side#Pressing the clutch and shifting into a higher gear at the same time"},
{"testId":749,"qid":31219,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26876#31167","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Accelerating while pressing the clutch and turning toward the driver's side#Pressing the clutch and shifting into a higher gear at the same time"},
{"testId":748,"qid":31225,"description":"Why is it crucial to use an assistance while backing?","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":18699,"isBookmarked":0,"aid":"18699#18700#18701#26881","text":"Because you have blind spots#Because people feel more comfortable when you do#Because you are providing a job for someone else#For all of the above reasons"},
{"testId":748,"qid":31229,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":750,"qid":31229,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":748,"qid":31234,"description":"When you start going down a long downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":749,"qid":31234,"description":"When you start going down a long downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":750,"qid":31234,"description":"When you start going down a long downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":751,"qid":31234,"description":"When you start going down a long downgrade, your speed should be based on which of the following?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":748,"qid":31236,"description":"In Kentucky, which of the following drivers is not required to have a CDL?","explanation":"Military drivers, emergency drivers, RV drivers, and agricultural drivers are all exempt in Kentucky, as long as they satisfy specific standards. For a complete list of exclusions, contact the Kentucky Transportation Cabinet.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#19024#19025#19026","text":"All of the above#Drivers of emergency vehicles#Drivers of recreational vehicles used for camping#Drivers of farm vehicles used within the Commonwealth of Kentucky"},
{"testId":751,"qid":31241,"description":"When you apply for a CDL, you'll need to tell the MVA about your driving record over the past ____ years.","explanation":"When you apply for a CDL, you must supply the MVA with information about your driving history for the last ten years, including license numbers, residences, and state licenses. The MVA will look for anything that might prohibit you from driving a commercial vehicle (CMV).","imageName":"","correctAnswer":11760,"isBookmarked":0,"aid":"1319#11285#11341#11760","text":"year#2 years#5 years#10 years"},
{"testId":751,"qid":31246,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These scenarios are also addressed in Maryland CDL Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":751,"qid":31247,"description":"How far ahead should you glance while driving?","explanation":"You should consider how far your CMV will fly in 12 to 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":751,"qid":31248,"description":"Which of the following is most likely to become immobilized at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":752,"qid":31248,"description":"Which of the following is most likely to become immobilized at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":755,"qid":31248,"description":"Which of the following is most likely to become immobilized at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":756,"qid":31248,"description":"Which of the following is most likely to become immobilized at a raised railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most likely to get trapped at an elevated railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":751,"qid":31255,"description":"To reduce travel-related sleepiness, you should:","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than 6 hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 8 to 9 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30112#32409","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep#schedule the trip for the daytime hours"},
{"testId":751,"qid":31257,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial vehicle include properly accelerating, steering, stopping, and backing up, as detailed in section 2.2 of the Maryland CDL Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":751,"qid":31261,"description":"What should you do if you become drowsy while driving?","explanation":"If you are sleepy, cold air, fresh air, or caffeinated drinks may temporarily increase your alertness. The only true answer is to get off the road and relax as soon as possible.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#25522","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open your vehicle's windows for some fresh air"},
{"testId":751,"qid":31275,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, section 3.1 of the Maryland CDL Manual indicates that you should inspect your cargo within the first 50 miles of your voyage and then every 3 hours or 150 miles afterwards, as well as after each break.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":751,"qid":31277,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#18901#18919#18921","text":"Life#1 year minimum#5 years minimum#10 years minimum"},
{"testId":734,"qid":31301,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely include accelerating, steering, safely stopping, and backing up, as detailed in section 2.2 of the Arizona Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":731,"qid":31303,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace. Find out where the escape ramps are on your route ahead of time.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":734,"qid":31303,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace. Find out where the escape ramps are on your route ahead of time.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":735,"qid":31303,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace. Find out where the escape ramps are on your route ahead of time.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":742,"qid":31303,"description":"Which of the following statements concerning an emergency exit is FALSE?","explanation":"To slow down cars that have brake failure on a downhill, escape ramps are provided with soft gravel, improvements, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace. Find out where the escape ramps are on your route ahead of time.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":734,"qid":31308,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Arizona Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":734,"qid":31318,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most prone to get trapped at a railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":735,"qid":31318,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most prone to get trapped at a railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":746,"qid":31318,"description":"What is most likely to get stuck at a railroad crossing?","explanation":"Low-slung units (car carriers, lowboys, moving vans, etc.) and single-axle tractors towing a long trailer with the landing gear configured to suit a tandem-axle tractor are the two kinds of combination vehicles most prone to get trapped at a railroad crossing. When driving such vehicles near railroad crossings, use extreme caution.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#20199#20200#29665","text":"All of the above#Car carrier#Moving van#Lowboy"},
{"testId":734,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":738,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":739,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":761,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":762,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":764,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":766,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":767,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":770,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":771,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":772,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":777,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":779,"qid":31319,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":734,"qid":31323,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Arizona Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":734,"qid":31325,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18671#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":735,"qid":31325,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18671#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":739,"qid":31325,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18671#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":742,"qid":31325,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18671#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":734,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":735,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":745,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":746,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":748,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":749,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":751,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":753,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":764,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":766,"qid":31330,"description":"Distracted driving symptoms include:","explanation":"Whether the communication is face-to-face or by mobile phone, text messaging, or CB radio, the driver's concentration is still required and the individual may be distracted.","imageName":"","correctAnswer":23619,"isBookmarked":0,"aid":"23616#23619#30107#30108","text":"vehicles exiting the roadway#drivers having conversations with passengers#vehicles constantly traveling above the speed limit#drivers listening to overly loud music"},
{"testId":734,"qid":31332,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Arizona Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":734,"qid":31338,"description":"If a CDL holder from another state relocates to Arizona, he or she must ______ to exchange his or her CDL for an Arizona CDL.","explanation":"If a CDL holder from another state moves to Arizona, he or she will have 30 days to exchange his or her CDL for an Arizona CDL.","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#740#742#1020","text":"90 days#15 days#30 days#60 days"},
{"testId":734,"qid":31339,"description":"How far ahead should you glance when driving?","explanation":"The Arizona Commercial Driver License Manual recommends that you anticipate the distance that your CMV will go in 12 to 15 seconds. At city driving speeds, your CMV will go one block in around 15 seconds. Your CMV will go a quarter mile (1,320 feet) in 15 seconds at 60 mph.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":737,"qid":31340,"description":"Why should your shipment be protected?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. It will also assist you in complying with rules in many states.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18643#18644#18645","text":"All of the above#To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it"},
{"testId":737,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":740,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":743,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":744,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":745,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":746,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":747,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":749,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":750,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":751,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":752,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":753,"qid":31341,"description":"Releasing air from heated tires:","explanation":"When tires heat up, the air pressure rises. However, releasing that excess pressure may result in too little air pressure when the tires cool again, and the tires may catch fire or blow out. If your tires are too hot to touch, come to a complete stop until they have cooled down.","imageName":"","correctAnswer":25517,"isBookmarked":0,"aid":"25516#25517#25518#25519","text":"is a good idea because the extra pressure will be relieved#is a bad idea because when the tires cool off, the pressure will be too low#will have no effect at all#will cool them down. You will be able to continue your journey sooner"},
{"testId":737,"qid":31342,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. If your vehicle pulls in either way, it requires servicing, and the comfort of your neck should not influence your safety choice.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":740,"qid":31342,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. If your vehicle pulls in either way, it requires servicing, and the comfort of your neck should not influence your safety choice.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":737,"qid":31343,"description":"The minimum tread depth for front tires is:","explanation":null,"imageName":"","correctAnswer":18935,"isBookmarked":0,"aid":"18935#18936#18937#18938","text":"4/32-inch#3/8-inch#1/2-inch#1/32-inch"},
{"testId":737,"qid":31344,"description":"Which of the following should NOT be checked during a trip?","explanation":"During your travel, keep an eye on all of your vehicle's important systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you put your phone away for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":737,"qid":31345,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the requirements differ depending on jurisdiction, it's a good idea to inspect your cargo during the first 50 miles of your voyage. While you should check it as needed, the handbook suggests checking it every 3 hours or 150 miles as a general guideline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":737,"qid":31346,"description":"What you must inspect a sealed load before transferring it is?","explanation":"Although sealed loads cannot be inspected, it is your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions.","imageName":"","correctAnswer":19006,"isBookmarked":0,"aid":"8615#19004#19005#19006","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight and axle weight limits"},
{"testId":737,"qid":31347,"description":"What should you do if you feel sleepy while driving?","explanation":"Drowsiness cannot be eliminated by drinking coffee or other caffeinated drinks or by obtaining some fresh air. They may temporarily increase your alertness, but the only genuine answer is to get off the road and relax.","imageName":"","correctAnswer":19014,"isBookmarked":0,"aid":"19012#19014#19015#25522","text":"Drink a cup of coffee#Pull off the road and get some sleep#Drink energy drinks#Open your vehicle's windows for some fresh air"},
{"testId":737,"qid":31348,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking may cause the car to lose control, so brake softly only when it has slowed down.","imageName":"","correctAnswer":19087,"isBookmarked":0,"aid":"19085#19086#19087#23635","text":"Hold the steering wheel firmly#Stay off the brake pedal#Engage the brakes hard and immediately#Be aware that a tire has failed"},
{"testId":740,"qid":31348,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking may cause the car to lose control, so brake softly only when it has slowed down.","imageName":"","correctAnswer":19087,"isBookmarked":0,"aid":"19085#19086#19087#23635","text":"Hold the steering wheel firmly#Stay off the brake pedal#Engage the brakes hard and immediately#Be aware that a tire has failed"},
{"testId":737,"qid":31349,"description":"What will assist an alcoholic to sober up?","explanation":"There is no rapid method to eliminate alcohol from your system. Coffee and fresh air will not suffice. You must wait until you are sober before driving, otherwise you risk losing your CDL.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":737,"qid":31350,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The requirement is to have one tie-down for every 10 feet of cargo and at least two tie-downs per load, regardless of length. So you'd have two for 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":737,"qid":31351,"description":"Which of the following tells you how fast you can go down a steep hill safely?","explanation":"To choose a safe speed for descending a steep descent, consider its steepness, length, road and weather conditions, and the weight of your vehicle and load.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18594#18595#18596","text":"All of the above#The total weight of the vehicle and cargo#The steepness of the grade#The road conditions"},
{"testId":737,"qid":31353,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends.","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":737,"qid":31354,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"You and your assistant must agree on a \"stop\" hand gesture so that you may halt what you're doing before an issue arises.","imageName":"","correctAnswer":25525,"isBookmarked":0,"aid":"25523#25524#25525#25526","text":"\"Go.\"#\"Faster.\"#\"Stop.\"#\"Turn up the music.\""},
{"testId":737,"qid":31355,"description":"How long does it take a standard tractor-trailer to clear a double track?","explanation":"A regular tractor-trailer will take more than 15 seconds to clear a double track and 14 seconds to safely cross a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":737,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":738,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":740,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":743,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":744,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":745,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":747,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":748,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":749,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":750,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":752,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":756,"qid":31356,"description":"What can you do to start moving without rolling backward?","explanation":"If you have a manual gearbox, partially engage the clutch before removing your right foot from the brake. To avoid rolling back, use the parking brake whenever possible. Only let off of the parking brake until you've supplied enough engine power to prevent the vehicle from rolling back. To prevent rolling back on a tractor-trailer equipped with a trailer brake hand valve, use the hand valve.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18963#18964#23000#30116","text":"Put on the parking brake whenever necessary#Engage the clutch before removing your foot from the brake#Do all of the above#Apply the trailer brake hand valve"},
{"testId":737,"qid":31357,"description":"In which of the following two specific cases should you downshift?","explanation":"You may use engine braking by downshifting before beginning down a slope. You should downshift to the gear necessary to climb or descend the slope. Downshifting before a curve increases stability and ensures you have enough power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":737,"qid":31358,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your retarder.","imageName":"","correctAnswer":119,"isBookmarked":0,"aid":"119#18669#18670#18970","text":"All of these answers are correct#Retarders help slow a vehicle, reducing the need for using your brakes#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered"},
{"testId":737,"qid":31359,"description":"What is the definition of Gross Vehicle Weight (GVW)?","explanation":"The weight of a single vehicle and the cargo it is transporting is referred to as the Gross Vehicle Weight (GVW).","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":737,"qid":31360,"description":"Which of the following is a critical component of steering?","explanation":"The gear box is an essential component of the steering system. All of the other options are critical components of the suspension system.","imageName":"","correctAnswer":18404,"isBookmarked":0,"aid":"18404#18660#18661#18662","text":"Gear box#Leaf spring#Bearing plate#Torque rod"},
{"testId":737,"qid":31362,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Check the hoses for leaks and other issues as well.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#18993","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#low windshield washer fluid level"},
{"testId":737,"qid":31363,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18955#18957#25527#25528","text":"Check for yellow ABS malfunction lamps on the instrument panel#Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work"},
{"testId":737,"qid":31364,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":25529,"isBookmarked":0,"aid":"23627#23628#23629#25529","text":"Release the clutch#Push in the clutch and shift into higher gear at the same time#Release the clutch and press the accelerator at the same time#Accelerate while pressing the clutch and turn toward the driver's side"},
{"testId":737,"qid":31365,"description":"What does the word \"hazard\" mean?","explanation":"A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":25530,"isBookmarked":0,"aid":"18515#18516#18517#25530","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that could present a possible danger"},
{"testId":737,"qid":31366,"description":"Which of the following statements concerning an escape ramp is NOT correct?","explanation":"To slow down cars that have brake failure on a drop, escape ramps are created with soft gravel, turning uphill, or both. Look for one of these escape ramps, which may save lives, equipment, and cargo regardless of your pace.","imageName":"","correctAnswer":25532,"isBookmarked":0,"aid":"25531#25532#25533#25534","text":"It may be located a few miles from the top of a downgrade#It is of no use if you are traveling too fast#It may be made of soft gravel#It may turn uphill"},
{"testId":737,"qid":31367,"description":"If you're driving a 30-foot vehicle at 55 mph, you should leave __ seconds of following distance.","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":740,"qid":31367,"description":"If you're driving a 30-foot vehicle at 55 mph, you should leave __ seconds of following distance.","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":743,"qid":31367,"description":"If you're driving a 30-foot vehicle at 55 mph, you should leave __ seconds of following distance.","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":744,"qid":31367,"description":"If you're driving a 30-foot vehicle at 55 mph, you should leave __ seconds of following distance.","explanation":"The following distance calculation is one second for ten feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6389#6390#6916#7559","text":"3#4#6#7"},
{"testId":737,"qid":31368,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#25536#25537#25538","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver what he is carrying#Signs that help tax collectors determine how much to tax hazardous cargo#Signs that warn the public to stay at least 1000 feet away from the vehicle"},
{"testId":737,"qid":31369,"description":"To keep from being a distracted driver,:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":25539,"isBookmarked":0,"aid":"25483#25539#25540#25541","text":"smoke, eat, and drink on straight portions of the road#turn off your cell phone if possible until you reach your destination#have all your emotionally difficult conversations in the first hour of driving#read maps or use your phone only when there are no cars around you"},
{"testId":737,"qid":31370,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":18974,"isBookmarked":0,"aid":"18971#18973#18974#25542","text":"let you drive faster#shorten your stopping distance#keep your brakes from locking up when you brake hard#increase your vehicle's stopping power"},
{"testId":740,"qid":31370,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":18974,"isBookmarked":0,"aid":"18971#18973#18974#25542","text":"let you drive faster#shorten your stopping distance#keep your brakes from locking up when you brake hard#increase your vehicle's stopping power"},
{"testId":737,"qid":31371,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"A second DUI conviction, regardless of the vehicle, will result in the loss of your CDL for life. Consider your first DUI a wake-up call and an opportunity to get treatment, since a second offense will result in job loss in your chosen field.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#25543#25544#25545","text":"Life#At least 5 years#At least 1 year#At least 10 years"},
{"testId":737,"qid":31372,"description":"The temperature has suddenly dipped below zero. Which of the following locations is likely to be slick?","explanation":"When the temperature drops below freezing, certain portions of the road, beginning with shady areas and bridges, will begin to ice. A road that seems moist may contain a covering of treacherous black ice.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#25546","text":"All of the above#A shaded area#A bridge#A road that looks wet"},
{"testId":737,"qid":31373,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous condition in which sleep deprivation accumulates and you risk falling asleep behind the wheel until you are completely refreshed. People are often astonished to learn that having fewer than six hours of sleep every night triples your chance of an accident. To avoid fatigue before a journey, the driver should obtain enough sleep (adults require 8 to 9 hours to stay awake); thoroughly plan the route to determine total mileage, halting spots, and other logistical factors; and Plan travels during times when you are generally awake, not in the middle of the night. Drive with a passenger; avoid drowsy drugs; visit your doctor if you suffer from daytime sleepiness, difficulties sleeping at night, or take frequent naps; include exercise into your everyday life to boost your energy levels.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25876#25877#25878","text":"Do all of the above#schedule trips during the daytime hours#get 8-9 hours of sleep#avoid medications that cause drowsiness"},
{"testId":744,"qid":31373,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous condition in which sleep deprivation accumulates and you risk falling asleep behind the wheel until you are completely refreshed. People are often astonished to learn that having fewer than six hours of sleep every night triples your chance of an accident. To avoid fatigue before a journey, the driver should obtain enough sleep (adults require 8 to 9 hours to stay awake); thoroughly plan the route to determine total mileage, halting spots, and other logistical factors; and Plan travels during times when you are generally awake, not in the middle of the night. Drive with a passenger; avoid drowsy drugs; visit your doctor if you suffer from daytime sleepiness, difficulties sleeping at night, or take frequent naps; include exercise into your everyday life to boost your energy levels.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25876#25877#25878","text":"Do all of the above#schedule trips during the daytime hours#get 8-9 hours of sleep#avoid medications that cause drowsiness"},
{"testId":748,"qid":31373,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous condition in which sleep deprivation accumulates and you risk falling asleep behind the wheel until you are completely refreshed. People are often astonished to learn that having fewer than six hours of sleep every night triples your chance of an accident. To avoid fatigue before a journey, the driver should obtain enough sleep (adults require 8 to 9 hours to stay awake); thoroughly plan the route to determine total mileage, halting spots, and other logistical factors; and Plan travels during times when you are generally awake, not in the middle of the night. Drive with a passenger; avoid drowsy drugs; visit your doctor if you suffer from daytime sleepiness, difficulties sleeping at night, or take frequent naps; include exercise into your everyday life to boost your energy levels.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25876#25877#25878","text":"Do all of the above#schedule trips during the daytime hours#get 8-9 hours of sleep#avoid medications that cause drowsiness"},
{"testId":749,"qid":31373,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous condition in which sleep deprivation accumulates and you risk falling asleep behind the wheel until you are completely refreshed. People are often astonished to learn that having fewer than six hours of sleep every night triples your chance of an accident. To avoid fatigue before a journey, the driver should obtain enough sleep (adults require 8 to 9 hours to stay awake); thoroughly plan the route to determine total mileage, halting spots, and other logistical factors; and Plan travels during times when you are generally awake, not in the middle of the night. Drive with a passenger; avoid drowsy drugs; visit your doctor if you suffer from daytime sleepiness, difficulties sleeping at night, or take frequent naps; include exercise into your everyday life to boost your energy levels.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25876#25877#25878","text":"Do all of the above#schedule trips during the daytime hours#get 8-9 hours of sleep#avoid medications that cause drowsiness"},
{"testId":737,"qid":31374,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance practically quadruples (i.e., four times as much). Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":737,"qid":31375,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":740,"qid":31375,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":743,"qid":31375,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":744,"qid":31375,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":737,"qid":31376,"description":"You do not need to include _________ in your emergency kit.","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":748,"qid":31376,"description":"You do not need to include _________ in your emergency kit.","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":749,"qid":31376,"description":"You do not need to include _________ in your emergency kit.","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":751,"qid":31376,"description":"You do not need to include _________ in your emergency kit.","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":752,"qid":31376,"description":"You do not need to include _________ in your emergency kit.","explanation":"While a jacket may keep you warm, it is not a necessary element of your emergency pack. A functional fire extinguisher, warning devices, and extra electrical fuses are required (unless your vehicle has circuit breakers).","imageName":"","correctAnswer":18944,"isBookmarked":0,"aid":"18943#18944#18945#18946","text":"Warning devices#A spare jacket#A fire extinguisher#Spare electrical fuses"},
{"testId":737,"qid":31377,"description":"It is essential to use an assistance while backing up :","explanation":"It is critical to have a helper while backing up since you will be dealing with blind areas that you cannot see. Before you begin, you should both agree on a series of hand signals, particularly \"stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":737,"qid":31378,"description":"Which of the vehicles listed below can be driven without a CDL?","explanation":"There are several cars that can be driven without a CDL, but only under specified situations. For further information, see the Colorado Commercial Driver License Manual or contact the Colorado DMV.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#19029#25551#25552","text":"All of the above#Emergency snow plowing vehicles, used during emergency snow removal situations#Military vehicles operated by active duty personnel#Farm vehicles, if operated by a farmer within 150 miles of the individual's farm"},
{"testId":737,"qid":31379,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an object after the car ahead of you has passed it. \"One thousand and one, one thousand and two,...\" is a way to count seconds. The other three options will either not work or will involve dangerous distractions. Keep in mind that in heavy traffic, bad weather, with heavy vehicles, or at higher speeds, you should increase your following distance.","imageName":"","correctAnswer":25553,"isBookmarked":0,"aid":"25553#25554#25555#25556","text":"Wait until a vehicle passes a shadow or landmark and start to count the seconds until you reach it#Get 1/4 closer to the car in front of you and then back off again. Multiply how long this has taken you by four#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time"},
{"testId":737,"qid":31380,"description":"According to the manual, how far ahead do the majority of good drivers look while driving?","explanation":"Good drivers anticipate the distance that your vehicle will go in 12 to 15 seconds. This is around 1/4 mile at highway speeds.","imageName":"","correctAnswer":18272,"isBookmarked":0,"aid":"5136#18271#18272#18273","text":"10 to 12 seconds#5 to 10 seconds#12 to 15 seconds#7 to 12 seconds"},
{"testId":737,"qid":31381,"description":"Which of the following types of flames should NOT be extinguished with a B:C fire extinguisher?","explanation":"A B:C fire extinguisher should not be used on burning materials that can be extinguished with ordinary water, such as wood, paper, or fabric. These need the use of an A or A:B:C fire extinguisher.","imageName":"","correctAnswer":18431,"isBookmarked":0,"aid":"17867#18431#18432#25557","text":"Gasoline#Wood#Electrical#Grease"},
{"testId":737,"qid":31382,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide, a car on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This gives vehicles on the right no space to overtake you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#25558","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#Any of the above might happen"},
{"testId":737,"qid":31383,"description":"You should keep the starter switch key in your pocket while doing the pre-trip inspection because:","explanation":"You don't want someone who doesn't know you're beneath the vehicle to start the engine and move the truck while you're completing the pre-trip check.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#25559","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#all of the above could happen"},
{"testId":737,"qid":31384,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, you should use your high lights as much as possible to increase your field of view, as long as it is safe and legal and you will not blind other drivers. As a general guideline, utilize high beams if there are no incoming cars within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":737,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":738,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":740,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":743,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":744,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":746,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":747,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":749,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":750,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":759,"qid":31385,"description":"Which of the following do you NOT need to do to make sure the cargo gets to its destination safely?","explanation":"You are not responsible for the condition of sealed goods and are not permitted to check it. You are solely responsible for the cargo's safety, ensuring that it is balanced, secured, and not overloaded, and that it does not obstruct emergency equipment.","imageName":"","correctAnswer":18656,"isBookmarked":0,"aid":"18653#18654#18655#18656","text":"Inspecting the cargo#Recognizing possible overloads#Making sure that cargo is properly secured#Ensuring the freshness of sealed cargo"},
{"testId":737,"qid":31386,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as they were intended: to carry a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":737,"qid":31387,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leaking, so look for grease on the wheels.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":740,"qid":31387,"description":"For what should you inspect wheel bearing seals?","explanation":"The most common issue with wheel bearing seals is leaking, so look for grease on the wheels.","imageName":"","correctAnswer":21004,"isBookmarked":0,"aid":"18939#18940#18941#21004","text":"Broken leaf springs#Twisted axles#Tears#Leaks"},
{"testId":737,"qid":31388,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"When executing this portion of the examination, you must shift into neutral, start the engine, and listen for any strange sounds. After you've completed the engine inspection, double-check all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":737,"qid":31389,"description":"To stay alert and safe while driving, you should:","explanation":"Medication labels that warn you they may make you sleepy should be taken seriously. Consult your doctor if you have any questions regarding the drugs that are being recommended to you. Avoid attempting to combat tiredness with coffee, fresh air, or alcohol in particular. Sleeping till you feel refreshed is the only \"treatment\" for being weary.","imageName":"","correctAnswer":25471,"isBookmarked":0,"aid":"18976#18977#18978#25471","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications whose warning labels mention drowsiness"},
{"testId":740,"qid":31389,"description":"To stay alert and safe while driving, you should:","explanation":"Medication labels that warn you they may make you sleepy should be taken seriously. Consult your doctor if you have any questions regarding the drugs that are being recommended to you. Avoid attempting to combat tiredness with coffee, fresh air, or alcohol in particular. Sleeping till you feel refreshed is the only \"treatment\" for being weary.","imageName":"","correctAnswer":25471,"isBookmarked":0,"aid":"18976#18977#18978#25471","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications whose warning labels mention drowsiness"},
{"testId":743,"qid":31389,"description":"To stay alert and safe while driving, you should:","explanation":"Medication labels that warn you they may make you sleepy should be taken seriously. Consult your doctor if you have any questions regarding the drugs that are being recommended to you. Avoid attempting to combat tiredness with coffee, fresh air, or alcohol in particular. Sleeping till you feel refreshed is the only \"treatment\" for being weary.","imageName":"","correctAnswer":25471,"isBookmarked":0,"aid":"18976#18977#18978#25471","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications whose warning labels mention drowsiness"},
{"testId":744,"qid":31389,"description":"To stay alert and safe while driving, you should:","explanation":"Medication labels that warn you they may make you sleepy should be taken seriously. Consult your doctor if you have any questions regarding the drugs that are being recommended to you. Avoid attempting to combat tiredness with coffee, fresh air, or alcohol in particular. Sleeping till you feel refreshed is the only \"treatment\" for being weary.","imageName":"","correctAnswer":25471,"isBookmarked":0,"aid":"18976#18977#18978#25471","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications whose warning labels mention drowsiness"},
{"testId":738,"qid":31392,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up carefully. These abilities are covered in depth in section 2.2 of the Connecticut Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":738,"qid":31405,"description":"The examiner may ask you to perform an alley dock maneuver as part of your skills test (i.e., back your vehicle into an alley). You must park your vehicle within _______ of the alley's back end without touching any boundary lines or cones.","explanation":"The examiner may ask you to execute an alley dock manoeuvre as part of your skills test (i.e., back your vehicle into an alley). You have to back into the alley three feet from the back without hitting the cones or boundary lines. Once you've finished, make sure your car is facing straight into the alley.","imageName":"","correctAnswer":44049,"isBookmarked":0,"aid":"818#4921#39693#44049","text":"12 inches#18 inches#24 inches#36 inches"},
{"testId":738,"qid":31412,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18671#25562#28446","text":"All of the above statements are correct#You should turn the retarder off whenever the road is wet, icy, or snow covered#When your drive wheels have poor traction, the retarders may cause them to skid#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":738,"qid":31413,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, the system should have totally cooled, and even then, proceed with caution since there may be significant pressure and harmful steam and fluids.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":738,"qid":31415,"description":"You must park on the shoulder of a level, straight, two-lane road. Where should the reflecting triangles be placed?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on other kinds of routes, such as one-way streets and bends. (Some of these scenarios are also covered in the Connecticut Commercial Driver License Manual.) It does not, however, address the issue of putting warning devices on a one-way or divided roadway. The majority of CDL guides released by other states address this issue.)","imageName":"","correctAnswer":21256,"isBookmarked":0,"aid":"21255#21256#44050#44051","text":"One about 50 feet to the rear of the vehicle, one about 100 feet to the rear, and one about 100 feet in front of the vehicle#One about 10 feet to the rear of the vehicle, one about 100 feet to the rear, and one about 100 feet in front of the vehicle#One about 10 feet to the rear of the vehicle, one about 100 feet to the rear, and one about 200 feet to the rear#One about 10 feet to the rear of the vehicle, one about 50 feet to the rear, and one about 100 feet to the rear"},
{"testId":738,"qid":31419,"description":"If you are convicted of a second DUI violation in either a CMV or your own car, how long will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":738,"qid":31424,"description":"What does GVW, or gross vehicle weight, mean?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting.","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"203#18389#19011#19090","text":"All of the above#The total weight of a single vehicle and its load#A vehicle's maximum weight rating, specified by its manufacturer#The total weight of the vehicle, towed vehicles, and the load"},
{"testId":738,"qid":31428,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. When you are within 500 feet of another vehicle, the Connecticut Commercial Driver License Manual suggests that you lower your headlights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":738,"qid":31429,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the Connecticut Commercial Driver License Manual advises that you should inspect your cargo during the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":738,"qid":31435,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"The following distance calculation is one second for 10 feet of car, plus one additional second if you are moving faster than 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":747,"qid":31440,"description":"Kansas does not have a signed farm vehicle reciprocity agreement with which of the following states?","explanation":"Kansas has inked reciprocity agreements with Missouri, Nebraska, and Oklahoma, but not with California, to extend farm vehicle CDL exemptions. Visit the Kansas Department of Revenue website for further information.","imageName":"","correctAnswer":19037,"isBookmarked":0,"aid":"19035#19036#19037#19038","text":"Missouri#Oklahoma#California#Nebraska"},
{"testId":747,"qid":31441,"description":"Which of the following should influence your choice of speed when starting down a long downgrade?","explanation":"Consider the steepness, length, road and weather conditions, and weight of your vehicle and load when determining a safe speed for driving down a steep decline.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":747,"qid":31456,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and does not blind other cars. When you are within 500 feet of an incoming vehicle or following another vehicle within 500 feet, you should lower your lights.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":747,"qid":31458,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":747,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":748,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":749,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":750,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":751,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":752,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":753,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":754,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":755,"qid":31461,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":747,"qid":31463,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI for the second time in a commercial motor vehicle or your personal car, you will lose your Kansas CDL privileges for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (An average 180-pound guy will have a BAC of 0.04 percent after just two 12-ounce cans of beer before his liver begins to break it down.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":747,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":748,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":749,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":750,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":751,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":753,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":754,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":755,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":756,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":757,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":758,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":759,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":780,"qid":31464,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. So, for a 30-foot car traveling at 55 mph, you should allow a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":747,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":748,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":749,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":750,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":752,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":755,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":757,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":758,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":759,"qid":31469,"description":"To stay alert and safe while driving, you should:","explanation":"Unfortunately, several prescription and OTC drugs may induce drowsiness, sleepiness, or impaired coordination, making them unsafe for CMV operators. If you have any questions regarding the side effects of a medicine, consult the warning label or your pharmacist or doctor. Never use coffee, fresh air, or alcohol to combat sleepiness. The only \"cure\" for tiredness is to sleep until you are refreshed.","imageName":"","correctAnswer":30838,"isBookmarked":0,"aid":"18976#18977#18978#30838","text":"drink coffee if you get drowsy#roll down your windows to get fresh air#have a whiskey to brace yourself#avoid medications that can cause drowsiness"},
{"testId":747,"qid":31472,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":29014,"isBookmarked":0,"aid":"18971#18973#25542#29014","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your brakes from locking when you brake hard"},
{"testId":747,"qid":31478,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, section 3.1 of the Kansas CDL Manual indicates that you should inspect your cargo within the first 50 miles of your journey and then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":747,"qid":31480,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":748,"qid":31480,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":750,"qid":31480,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":751,"qid":31480,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":752,"qid":31480,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the trip.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":747,"qid":31481,"description":"Which of the following should you NOT do if your tire fails?","explanation":"While it's natural to want to take control of the situation by braking, slamming on your brakes may cause your car to skid, so brake softly once you've recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":747,"qid":31482,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":30841,"isBookmarked":0,"aid":"30840#30841#30842#30843","text":"Someone may try to pass you on your left#Someone may try to pass you on your right#You may damage your leaf springs#All of the above may happen"},
{"testId":747,"qid":31483,"description":"How many hours of sleep does the average person require per night to prevent sleep debt?","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than six hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 7 to 8 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":747,"qid":31484,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These scenarios are also described in Kansas CDL Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":755,"qid":31491,"description":"Which of the following should you NOT check while driving?","explanation":"Throughout your journey, keep an eye on all of your vehicle's main systems, including the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you leave your phone at home for the duration of the vacation.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":755,"qid":31492,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of 80-proof whiskey, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":756,"qid":31492,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of 80-proof whiskey, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":757,"qid":31492,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of 80-proof whiskey, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":755,"qid":31493,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":755,"qid":31497,"description":"How many hours of sleep does the average person require per night in order to avoid \"sleep debt\"?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. Getting fewer than 6 hours of sleep every night increases the chance of an accident by thrice. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":18727,"isBookmarked":0,"aid":"18725#18726#18727#33975","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#5 to 6 hours"},
{"testId":755,"qid":31503,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities required for the safe operation of a commercial vehicle are accelerating, steering, stopping, and backing properly. These abilities are covered in depth in section 2.2 of the Mississippi Professional Driver's Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":755,"qid":31507,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These conditions are also addressed in Mississippi Professional Driver's Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":755,"qid":31508,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that you should have one tiedown for every ten feet of cargo and at least two per load, regardless of length. So you'll need two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":755,"qid":31518,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard might cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":757,"qid":31518,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard might cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":758,"qid":31518,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard might cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":759,"qid":31518,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard might cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":780,"qid":31518,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard might cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":755,"qid":31530,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#33976","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#smoke, eat, and drink only on straight portions of the road"},
{"testId":756,"qid":31530,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#33976","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#smoke, eat, and drink only on straight portions of the road"},
{"testId":757,"qid":31530,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#33976","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#smoke, eat, and drink only on straight portions of the road"},
{"testId":758,"qid":31530,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction. Do not eat, drink, smoke, text, read, or have tough conversations while driving. Avoid using your mobile phone until you have finished driving for the day.","imageName":"","correctAnswer":30113,"isBookmarked":0,"aid":"25484#25540#30113#33976","text":"read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving#avoid using your cell phone until you reach your destination#smoke, eat, and drink only on straight portions of the road"},
{"testId":755,"qid":31531,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the wording in the question: \"EXCEPT!\" When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":755,"qid":31537,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Mississippi Professional Driver's Manual indicates that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":755,"qid":31538,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (A typical 180-pound male will have a BAC of more than 0.04 percent after just two 12-ounce cans of beer before his liver begins to break down the alcohol.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":757,"qid":31538,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (A typical 180-pound male will have a BAC of more than 0.04 percent after just two 12-ounce cans of beer before his liver begins to break down the alcohol.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":758,"qid":31540,"description":"Which of the following is NOT a part of the fundamental shifting method?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":758,"qid":31544,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (A typical 180-pound guy will have a BAC of more than 0.04 percent after just two 12-ounce cans of beer before his body begins to break down the alcohol.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":759,"qid":31544,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV is considered DUI. (A typical 180-pound guy will have a BAC of more than 0.04 percent after just two 12-ounce cans of beer before his body begins to break down the alcohol.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":758,"qid":31546,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the Nebraska Commercial Driver License Manual advises that you should inspect your cargo during the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":758,"qid":31548,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of 80-proof whiskey, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":759,"qid":31548,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of 80-proof whiskey, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":758,"qid":31549,"description":"When backing, it's important to have a helper:","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":758,"qid":31557,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These scenarios are also discussed in Nebraska Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":758,"qid":31560,"description":"Truck drivers are in a great position to observe and report human trafficking occurring at truck stops and rest areas. Which of the following trafficking red signs should you check for?","explanation":"Victims of human trafficking are often relocated. They may be victims of physical, sexual, or forced substance abuse, as well as forced prostitution. DO NOT approach any potential traffickers. Instead, consider persons, cars, times, dates, and places. If possible, take photographs. Then dial 1-888-3737-888 to reach the National Hotline (US).","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#30532#30534#34831","text":"All of the above#An individual who lacks knowledge of his or her location or community#An individual who seems tense, afraid, nervous, or submissive#An individual not in control of his or her own travel documents such as a passport or driver's license"},
{"testId":758,"qid":31562,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Nebraska Commercial Driver License Manual recommends dimming your headlights when you are within 500 feet of an oncoming vehicle or when you are following another vehicle within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":758,"qid":31566,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Nebraska Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":758,"qid":31579,"description":"To avoid falling asleep throughout your travel, you should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30111#33630","text":"Do all of the above#avoid medications that cause drowsiness#schedule trips for the daytime hours#get 8鈥9 hours of sleep beforehand"},
{"testId":758,"qid":31589,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":768,"qid":31591,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Oregon Commercial Driver Manual indicates that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":768,"qid":31594,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Oregon Commercial Driver Manual suggests dimming your headlights if you are within 500 feet of an incoming vehicle under Section 2.11.5. It also states that you must lower your headlights if you are within 350 feet of a vehicle you are following under Oregon law.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":768,"qid":31604,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Oregon Commercial Driver Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":768,"qid":31617,"description":"What should you do to safely cross the Siskiyou Pass?","explanation":"The Siskiyou Pass is a mountain pass in California's Siskiyou Mountains. From October to April, one six-mile section of road is especially dangerous, with a steep 6% downhill; hairpin turns; and black ice, fog, or snow. To cross the pass safely, you need take the following precautions: Know where the escape ramps are along the journey in case you need them. Make sure you have warning triangles on hand just in case. Make sure you are rested and attentive, not tired, before you begin. Check your brakes and lights, and decline using engine braking. Keep an eye out for black ice, fog, or snow. Consider utilizing tire chains on ice or snow.","imageName":"","correctAnswer":36453,"isBookmarked":0,"aid":"36450#36451#36452#36453","text":"Before you start, make sure that your lights are working#Consider using tire chains on ice or snow#Check your brakes before you enter the downgrade#Do all of the above (and more)"},
{"testId":768,"qid":31618,"description":"How many hours of sleep does the average adult require per night?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. Getting fewer than six hours of sleep every night increases the likelihood of an accident by thrice. At least 15% of heavy truck accidents are thought to be caused by driver weariness. You should acquire enough sleep before your vacation. To stay attentive, the typical human need seven to eight hours of sleep per 24 hours.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":773,"qid":31618,"description":"How many hours of sleep does the average adult require per night?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. Getting fewer than six hours of sleep every night increases the likelihood of an accident by thrice. At least 15% of heavy truck accidents are thought to be caused by driver weariness. You should acquire enough sleep before your vacation. To stay attentive, the typical human need seven to eight hours of sleep per 24 hours.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":768,"qid":31637,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle are accelerating, steering, safely stopping, and backing up, as detailed in section 2.2 of the Oregon Commercial Driver Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":771,"qid":31649,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the South Carolina Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":771,"qid":31661,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The South Carolina Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":771,"qid":31673,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the South Carolina Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":771,"qid":31678,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":25525,"isBookmarked":0,"aid":"25525#28449#28450#28452","text":"\"Stop.\"#鈥淕o.鈥#鈥淔aster.鈥#鈥淭urn up the music.鈥"},
{"testId":771,"qid":31682,"description":"Which of the following is not subject to Jacob's Law?","explanation":"Jacob's Law requires that any vehicle that carries kids to and from school on a regular basis fulfill all federal school bus safety requirements. Furthermore, you must have a CDL with a passenger endorsement in order to operate any vehicle that can carry 16 or more people, including yourself. The statute was named after Jacob Strebler, a six-year-old boy who was murdered in 1994 when the side of the school van in which he was travelling was struck by a vehicle. The vehicle did not fulfill federal school bus side-impact protection criteria.","imageName":"","correctAnswer":36502,"isBookmarked":0,"aid":"19043#36502#36503#36504","text":"The way that vehicles must approach railroad crossings#Requirements for vehicles that transport students to or from school#The penalties for driving under the influence (DUI) for a second time#The rules for distinguishing excepted commerce from non-excepted commerce"},
{"testId":771,"qid":31686,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the South Carolina Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":774,"qid":31690,"description":"It is illegal in Utah to use a portable cell phone to _________ while driving.","explanation":null,"imageName":"","correctAnswer":23817,"isBookmarked":0,"aid":"1784#23817#36702#36703","text":"send a text message#do any of the above#dial a telephone number#access the Internet"},
{"testId":774,"qid":31693,"description":"During your trip, you should check which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18657#18658#18769","text":"All of the above#Mirrors#Cargo and cargo covers#Pressure gauges"},
{"testId":774,"qid":31713,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely include accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Utah Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":774,"qid":31728,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While rules vary by jurisdiction, the Utah Commercial Driver License Manual states that you should check your cargo within the first 50 miles of your trip, then every 3 hours or 150 miles thereafter, as well as after each break.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":774,"qid":31733,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Utah Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle under Section 2.11.5.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":774,"qid":31735,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Utah Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":778,"qid":31740,"description":"What is the Driver License Compact's purpose?","explanation":"Each Driver License Compact member state communicates information about a motorist's moving offenses with the state that issued the motorist's driver's license. Each Non-Resident Violator Compact member state enables a driver who gets a ticket to continue on his or her journey, even if the motorist is a legal resident of another state. Furthermore, the other state promises to suspend the motorist's driver's license if the motorist does not comply with the citation. It should be noted, however, that none of these compacts includes every state in the United States. The Driver License Compact now includes 44 states, including West Virginia, and the Non-Resident Violator Compact includes 44 states, including West Virginia.","imageName":"","correctAnswer":36736,"isBookmarked":0,"aid":"203#36736#36737#36738","text":"All of the above#To share information about a motorist's moving violations with the state that issued the motorist's driver's license#To allow a motorist from out of state to accept a traffic citation and continue on his or her way#To enable police to check a motorist's driver's license"},
{"testId":778,"qid":31743,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the AAMVA Commercial Driver's License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":778,"qid":31757,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the AAMVA Commercial Driver's License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":778,"qid":31771,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the AAMVA Commercial Driver's License Manual advises that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":778,"qid":31785,"description":"Which lights should be used as often as possible when driving at night?","explanation":null,"imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":744,"qid":31790,"description":"Which of the following items can't be transported legally within Idaho without a DOT medical card?","explanation":"A CDL holder may carry some commodities inside the state of Idaho without a DOT medical card. These commodities include Christmas trees, fresh fruit, and bees, but not a preserved fruit such as canned pineapple.","imageName":"","correctAnswer":19056,"isBookmarked":0,"aid":"19053#19054#19055#19056","text":"Christmas trees#Fresh fruit#Bees#Canned pineapple"},
{"testId":744,"qid":31796,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load regardless of length. So you'd have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":746,"qid":31796,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load regardless of length. So you'd have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":744,"qid":31797,"description":"What you must inspect a sealed load before transferring it is?","explanation":"While sealed loads cannot be inspected, it is still your obligation to ensure that you do not exceed your axle weight limitations or gross weight restrictions, therefore double-check them both.","imageName":"","correctAnswer":19006,"isBookmarked":0,"aid":"8615#19004#19005#19006","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight and axle weight limits"},
{"testId":744,"qid":31800,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":29014,"isBookmarked":0,"aid":"18971#18973#25542#29014","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your brakes from locking when you brake hard"},
{"testId":745,"qid":31800,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":29014,"isBookmarked":0,"aid":"18971#18973#25542#29014","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your brakes from locking when you brake hard"},
{"testId":746,"qid":31800,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":29014,"isBookmarked":0,"aid":"18971#18973#25542#29014","text":"let you drive faster#shorten your stopping distance#increase your vehicle's stopping power#keep your brakes from locking when you brake hard"},
{"testId":744,"qid":31805,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":744,"qid":31817,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause the car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":19087,"isBookmarked":0,"aid":"19085#19086#19087#23635","text":"Hold the steering wheel firmly#Stay off the brake pedal#Engage the brakes hard and immediately#Be aware that a tire has failed"},
{"testId":744,"qid":31818,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your position to start your car and hurt you while you are doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19001#19002#19003#26884","text":"someone could steal the truck#someone could start and move the truck#it could damage the starting mechanism#any of the above could occur"},
{"testId":744,"qid":31820,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"A second DUI conviction in either a CMV or non-CMV vehicle will result in the loss of your CDL for life under federal drunk driving rules. (See the Federal Motor Carrier Safety Administration's (FMCSA) Federal Motor Carrier Safety Regulations Part 383.51). Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":744,"qid":31821,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature dips below freezing, certain parts of the road will ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":746,"qid":31821,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":"When the temperature dips below freezing, certain parts of the road will ice before others. Shaded locations and bridges will be the first to freeze. If the road seems wet, it might be coated with black ice, which is a thin coating of slippery ice that allows you to see the road.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":744,"qid":31823,"description":"It is essential to use an assistance while backing up :","explanation":null,"imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":744,"qid":31836,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (Section 2.5.2 of the Idaho Commercial Driver's Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":744,"qid":31837,"description":"Which lights should you use as much as possible when driving at night?","explanation":"When driving at night, you should use your high lights as much as possible to increase your field of view, as long as it is safe and legal and you will not blind other drivers. As a general guideline, utilize high beams if there are no incoming cars within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":744,"qid":31838,"description":"How frequently must you stop on the road to inspect your cargo?","explanation":"While the requirements differ by jurisdiction, in Idaho, you must inspect your cargo's security within the first 50 miles of your travel and then every three hours or 150 miles afterwards, whichever comes first.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":750,"qid":31840,"description":"Which of the following conditions must you achieve in order to obtain a military CDL skills test waiver?","explanation":"The Federal Military Skills Exam Waiver Program allows a military veteran to substitute two years of experience driving trucks or buses equal to civilian commercial vehicles for the CDL skills test. States may impose extra criteria. To apply for a military waiver for your CDL skills exam in Maine, you may only have one license (except a military driver's license) and must be on active service or have been released within the last year.","imageName":"","correctAnswer":31802,"isBookmarked":0,"aid":"31800#31801#31802#31803","text":"For at least 3 years, you must have operated a CDL of the same type and class as the license you are applying for#You must not have more than two convictions for offenses such as tailgating and reckless driving#Your military service must have occurred within the past year#You can only hold a license in two jurisdictions: Maine and your state of record"},
{"testId":750,"qid":31845,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These scenarios are also described in Maine CDL Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":750,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":751,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":752,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":753,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":754,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":755,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":756,"qid":31846,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":750,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":751,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":753,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":754,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":755,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":756,"qid":31847,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":750,"qid":31850,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#18934#24643#31805","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear from the side window"},
{"testId":751,"qid":31850,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#18934#24643#31805","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear from the side window"},
{"testId":752,"qid":31850,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#18934#24643#31805","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear from the side window"},
{"testId":753,"qid":31850,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":31805,"isBookmarked":0,"aid":"18932#18934#24643#31805","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear from the side window"},
{"testId":750,"qid":31853,"description":"How many hours of sleep does the average person require per night to prevent sleep debt?","explanation":"If you don't get any rest, your lack of sleep might become so severe that you run the risk of falling asleep at the wheel. The fact that having fewer than 6 hours of sleep every night triples the chance of an accident frequently astounds people. Get enough sleep the night before your journey (adults require 7 to 8 hours to be awake); thoroughly plan your route to determine the overall distance, rest stops, and other logistical factors; and Schedule travels at times when you are ordinarily awake, not in the middle of the night; drive with a passenger; avoid drugs that make you drowsy; speak with your doctor if you often take naps; and include exercise into your regular routine to give yourself more energy.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":750,"qid":31878,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":751,"qid":31878,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":752,"qid":31878,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":753,"qid":31878,"description":"What will assist an alcoholic to sober up?","explanation":"Your liver degrades alcohol at a consistent pace. This is typically one normal drink every hour (1.5 ounces of hard liquor, 5 ounces of wine, or one 12-ounce can of beer). That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":750,"qid":31882,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, section 3.1 of the Maine CDL Manual indicates that you should inspect your cargo within the first 50 miles of your voyage and then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#18733#25521","text":"All of the above#Within the first 50 miles#Every 3 hours or 150 miles#After each break you take"},
{"testId":750,"qid":31884,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the Maine CDL Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":759,"qid":31890,"description":"Which of the following may result in your commercial driving license being suspended?","explanation":"If you are convicted twice within three years of breaching state law on texting while driving a CMV, you will be barred from operating commercially for 60 days under the Federal Motor Carrier Safety Regulations (FMCSR). Texting and driving is also prohibited in Nevada. Put your phone down or use a hands-free or voice-activated device to send texts while driving.","imageName":"","correctAnswer":19063,"isBookmarked":0,"aid":"234#19061#19062#19063","text":"Any of the above#Petty theft#Parking tickets#Texting while driving"},
{"testId":759,"qid":31892,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to prevent the wheels from locking, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":31165,"isBookmarked":0,"aid":"18971#25542#31165#34278","text":"let you drive faster#increase your vehicle's stopping power#keep your wheels from locking when you brake hard#increase your stopping distance"},
{"testId":759,"qid":31899,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While requirements differ by jurisdiction, the Nevada Commercial Driver License Manual advises that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":759,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":760,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":761,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":762,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":764,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":765,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":766,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":768,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":770,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":771,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":780,"qid":31900,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":null,"imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":759,"qid":31910,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":760,"qid":31910,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":761,"qid":31910,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":773,"qid":31910,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":780,"qid":31910,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#31166#31804#35130","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it#Get 1/4 closer to the vehicle in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":759,"qid":31919,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These conditions are also addressed in Nevada Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":759,"qid":31926,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Nevada Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":759,"qid":31929,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Nevada Commercial Driver License Manual suggests dimming your headlights when you are within 500 feet of an incoming vehicle or when you are following another vehicle within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":759,"qid":31936,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":760,"qid":31936,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":760,"qid":31940,"description":"To pass the vision screening test, you must have _____ or greater.","explanation":"Before you can take the CDL knowledge exams, you must first get your vision checked. Your visual acuity must be 20/40 or greater to pass. If you need to wear eyeglasses or contact lenses to pass the test, your CDL will have a limitation B requiring you to wear such corrective lenses at all times while driving.","imageName":"","correctAnswer":19064,"isBookmarked":0,"aid":"19064#19065#19066#19067","text":"20/40 vision#20/20 vision#20/60 vision#20/10 vision"},
{"testId":760,"qid":31943,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI in a CMV or your own vehicle for the second time, you will lose your CDL rights for life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":760,"qid":31945,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":761,"qid":31945,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":773,"qid":31945,"description":"How many seconds  should you leave if you are traveling at 55 mph in a 30-foot vehicle?","explanation":"Following distance is calculated as one second every ten feet of vehicle, plus one additional second if you are moving at or above 40 mph. As a result, for a 30-foot vehicle driving at 55 mph, you should leave a 4-second following gap.","imageName":"","correctAnswer":426,"isBookmarked":0,"aid":"426#428#429#7322","text":"4 seconds#6 seconds#7 seconds#3 seconds"},
{"testId":760,"qid":31972,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The New Hampshire CDL Driver's Guide suggests dimming your headlights if you are within 500 feet of another vehicle.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":760,"qid":31981,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (This is also covered in section 2.5.2 of the New Hampshire CDL Driver's Guide.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":760,"qid":31985,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, according to the New Hampshire CDL Driver's Guide, you should inspect your cargo within the first 50 miles of your journey and then every 3 hours or 150 miles afterwards, as well as after any break you take.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":760,"qid":31986,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle are accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the New Hampshire CDL Driver's Guide. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":762,"qid":31990,"description":"When you first apply for a Commercial Learner Permit or a CDL, you don't have to give which of the following?","explanation":"To apply for your first Commercial Learner Permit or CDL, you must present the following documentation: your Social Security card, your New Mexico driving license, your DOT medical certification, two proofs of New Mexico domicile, and evidence of your US citizenship or legal presence in the US. This final point may be shown by a state birth certificate, a US passport, a consular report of your birth abroad, a naturalization certificate, or other papers.","imageName":"","correctAnswer":23126,"isBookmarked":0,"aid":"23126#36160#36161#36162","text":"Your passport#Your Social Security card#Two proofs of residency in New Mexico#Your valid New Mexico driver license"},
{"testId":762,"qid":32005,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the New Mexico Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":762,"qid":32007,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the New Mexico Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":762,"qid":32028,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the New Mexico Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":762,"qid":32034,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The New Mexico Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":743,"qid":32040,"description":"What is the minimum age in Hawaii for obtaining a CDL?","explanation":"To get a CDL in Hawaii, you must be at least 21 years old.","imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#11374","text":"25#18#21#17"},
{"testId":743,"qid":32041,"description":"Which of the following is true about people who are slow?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your retarder.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18970#26890#28446","text":"All of the above statements are correct#You should turn the retarder off whenever the road is wet, icy, or snow-covered#If your drive wheels have poor traction, the retarder may cause them to skid#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":744,"qid":32041,"description":"Which of the following is true about people who are slow?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your retarder.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18970#26890#28446","text":"All of the above statements are correct#You should turn the retarder off whenever the road is wet, icy, or snow-covered#If your drive wheels have poor traction, the retarder may cause them to skid#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":743,"qid":32045,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":18951,"isBookmarked":0,"aid":"18951#25483#25484#25540","text":"turn off your cell phone until you reach your destination#smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving"},
{"testId":744,"qid":32045,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":18951,"isBookmarked":0,"aid":"18951#25483#25484#25540","text":"turn off your cell phone until you reach your destination#smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving"},
{"testId":746,"qid":32045,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":18951,"isBookmarked":0,"aid":"18951#25483#25484#25540","text":"turn off your cell phone until you reach your destination#smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you#have all your emotionally difficult conversations in the first hour of driving"},
{"testId":743,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":744,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":745,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":746,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":747,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":748,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":749,"qid":32049,"description":"Always try to back up toward the driver's side because:","explanation":"You should always drive with your back to the driver's side since you will be able to see things much better. For example, you may keep an eye on the back of the car by looking out the side window. Your vehicle need servicing if it pulls in either direction. The comfort of your neck should not influence your safety selection.","imageName":"","correctAnswer":25520,"isBookmarked":0,"aid":"18932#18934#24643#25520","text":"it's more comfortable for turning your neck#your truck will naturally pull toward the driver's side#all of the above are true#you can see better, watching the vehicle's rear out the side window"},
{"testId":743,"qid":32050,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide, a car on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#25558","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#Any of the above might happen"},
{"testId":744,"qid":32050,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide, a car on your right may attempt to pass you while you are turning. Instead, make the turn with your vehicle's back end as near to the curb as feasible and turn wide as you finish the turn. This will prevent cars on the right from passing you.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#25558","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#Any of the above might happen"},
{"testId":743,"qid":32054,"description":"An antilock braking system (ABS) will:","explanation":"ABS is only used to protect the wheels from locking up, which might result in a skid. ABS has no effect on how you brake the rest of the time. It is not a replacement for cautious braking, defensive driving, adequate brakes, or routine maintenance.","imageName":"","correctAnswer":18974,"isBookmarked":0,"aid":"18971#18973#18974#25460","text":"let you drive faster#shorten your stopping distance#keep your brakes from locking up when you brake hard#increase a vehicle's stopping power"},
{"testId":743,"qid":32056,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking may cause the car to lose control, so only brake softly once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":19087,"isBookmarked":0,"aid":"19085#19086#19087#23635","text":"Hold the steering wheel firmly#Stay off the brake pedal#Engage the brakes hard and immediately#Be aware that a tire has failed"},
{"testId":743,"qid":32057,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's vital components, such as the instruments, gauges, tires, voltmeter, mirrors, and load. Make sure you put your phone aside for the duration of the travel. Keep it just for emergencies.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":744,"qid":32057,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's vital components, such as the instruments, gauges, tires, voltmeter, mirrors, and load. Make sure you put your phone aside for the duration of the travel. Keep it just for emergencies.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":746,"qid":32057,"description":"Which of the following should NOT be checked during a trip?","explanation":"Throughout your journey, keep an eye on all of your vehicle's vital components, such as the instruments, gauges, tires, voltmeter, mirrors, and load. Make sure you put your phone aside for the duration of the travel. Keep it just for emergencies.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":743,"qid":32059,"description":"How many hours of sleep does the average person require per night to prevent sleep debt?","explanation":"Missed sleep accumulates until you risk falling asleep behind the wheel till you have completely refreshed. Surprisingly, research reveal that sleeping for six hours or less every night triples your chance of an accident.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":743,"qid":32061,"description":"How frequently must you stop on the road to inspect your cargo?","explanation":"While regulations differ by jurisdiction, in Hawaii, you must verify your cargo within the first 50 miles of your travel and then every three hours or 150 miles afterwards, whichever comes first.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":743,"qid":32064,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the 'unless' clause in the question! However, while doing this section of the examination, you must move into neutral, start the engine, and listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":743,"qid":32066,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to pass an object after the vehicle in front of you has done so. \"One thousand and one, one thousand and two,...\" is a way to count seconds. The other three options will either not work or will involve dangerous distractions. Keep in mind that in heavy traffic, bad weather, with heavy vehicles, or at higher speeds, you should increase your following distance.","imageName":"","correctAnswer":28447,"isBookmarked":0,"aid":"25555#25556#26888#28447","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you pass it"},
{"testId":744,"qid":32066,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to pass an object after the vehicle in front of you has done so. \"One thousand and one, one thousand and two,...\" is a way to count seconds. The other three options will either not work or will involve dangerous distractions. Keep in mind that in heavy traffic, bad weather, with heavy vehicles, or at higher speeds, you should increase your following distance.","imageName":"","correctAnswer":28447,"isBookmarked":0,"aid":"25555#25556#26888#28447","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you pass it"},
{"testId":743,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":744,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":745,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":746,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":747,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":748,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":750,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":751,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":753,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":757,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":758,"qid":32067,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool. Even so, you should proceed with caution since there may still be immense pressure and potentially harmful steam and liquid.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":743,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":744,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":745,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":746,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":747,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":748,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":749,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":750,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":751,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":752,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":753,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":754,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":755,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":756,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":757,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":758,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":759,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":760,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":761,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":780,"qid":32069,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18957#25527#25528#27991","text":"Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work#Check for a yellow ABS malfunction lamp on the instrument panel"},
{"testId":743,"qid":32074,"description":"In which of the two situations below should you downshift?","explanation":"You may use engine braking by downshifting before beginning down a slope. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":744,"qid":32074,"description":"In which of the two situations below should you downshift?","explanation":"You may use engine braking by downshifting before beginning down a slope. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":746,"qid":32074,"description":"In which of the two situations below should you downshift?","explanation":"You may use engine braking by downshifting before beginning down a slope. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":743,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":744,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":745,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":746,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":747,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":748,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":749,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":750,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":751,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":752,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":753,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":755,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":757,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":758,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":759,"qid":32075,"description":"The front tire's minimum tread depth is:","explanation":"Front tires must have a minimum tread depth of 4/32 inch. All other tires must have a minimum tread depth of 2/32 inch.","imageName":"","correctAnswer":23631,"isBookmarked":0,"aid":"21953#23631#23632#23634","text":"1/2 inch#4/32 inch#3/8 inch#1/32 inch"},
{"testId":743,"qid":32076,"description":"What you must inspect a sealed load before transferring it is?","explanation":"It is not possible to check sealed loads. It is still your job, though, to ensure that you do not exceed your axle weight limitations or gross weight restrictions, so double-check them both.","imageName":"","correctAnswer":28448,"isBookmarked":0,"aid":"8615#19004#19005#28448","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight or axle weight limits"},
{"testId":743,"qid":32079,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"It is your obligation, unless you have been wounded, to ensure that others can see your car at an accident site. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":744,"qid":32079,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"It is your obligation, unless you have been wounded, to ensure that others can see your car at an accident site. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":745,"qid":32079,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"It is your obligation, unless you have been wounded, to ensure that others can see your car at an accident site. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":746,"qid":32079,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"It is your obligation, unless you have been wounded, to ensure that others can see your car at an accident site. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":743,"qid":32080,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#18997","text":"All of the above#A shaded area#A bridge#A wet-looking road"},
{"testId":743,"qid":32086,"description":"What is the most crucial hand gesture on which you and your assistant should agree?","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":744,"qid":32086,"description":"What is the most crucial hand gesture on which you and your assistant should agree?","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":743,"qid":32087,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. Although having a first-aid certification could be useful at some time in your career, it is not one of the four fundamental skills.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":744,"qid":32087,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely include accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the CDL handbook. Although having a first-aid certification could be useful at some time in your career, it is not one of the four fundamental skills.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":757,"qid":32090,"description":"Which of the following is a prerequisite for obtaining a hazardous materials endorsement?","explanation":"A state cannot issue, renew, or transfer a hazardous materials endorsement unless the driver has satisfactorily completed the TSA's security threat assessment, according to the federal PATRIOT Act. To do so, you must go to a TSA application facility to be fingerprinted, provide identification paperwork, and pay a charge. The TSA will next conduct a criminal background check and an immigration status check on you. TSA sites may be found by contacting the Montana Department of Justice.","imageName":"","correctAnswer":34592,"isBookmarked":0,"aid":"203#19071#34592#34593","text":"All of the above#Two personal references#A TSA security threat assessment#Your income tax returns for the past five years"},
{"testId":757,"qid":32094,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":757,"qid":32106,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as two-way streets and bends. (These conditions are also addressed in Montana Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":757,"qid":32114,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18949#18950#34594","text":"reaction distance + braking distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance#braking distance + signal distance"},
{"testId":757,"qid":32115,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":758,"qid":32115,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach a stationary item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":31804,"isBookmarked":0,"aid":"25556#26888#31166#31804","text":"Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance#Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the vehicle in front of you has passed it#Wait until the vehicle ahead of you passes a shadow or landmark. Then count the seconds until you reach it"},
{"testId":757,"qid":32122,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Montana Commercial Driver Manual suggests dimming your headlights when you are within 500 feet of an incoming vehicle or when you are following another vehicle within 500 feet. However, Montana law requires you to dim your headlights when you are within 1,000 feet of an incoming vehicle or within 500 feet of another vehicle. [Montana Code Annotated, MCA 61-9-221,]","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":757,"qid":32127,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#30111#33630","text":"Do all of the above#avoid medications that cause drowsiness#schedule trips for the daytime hours#get 8鈥9 hours of sleep beforehand"},
{"testId":757,"qid":32128,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 184 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":758,"qid":32128,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your braking distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 184 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":757,"qid":32134,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Montana Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":757,"qid":32136,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Montana Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":757,"qid":32137,"description":"When backing, it's important to have a helper:","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you and your partner should decide on a set of hand signals indicating \"Go\" and (most crucially) \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":765,"qid":32140,"description":"The maximum height of a commercial motor vehicle (CMV) in North Dakota is:","explanation":"For CMVs, there is no Federal vehicle height limitation. Each state has the authority to impose its own height limitations. In North Dakota, the maximum height of a CMV, whether loaded or not, is 14 feet.","imageName":"","correctAnswer":36259,"isBookmarked":0,"aid":"14177#36259#36260#36261","text":"15 feet#14 feet#16 feet#18 feet"},
{"testId":765,"qid":32152,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the North Dakota Commercial Drivers License Manual specifies that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":765,"qid":32160,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the North Dakota Commercial Drivers License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":765,"qid":32163,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. According to Section 2.11.5 of the North Dakota Commercial Drivers License Manual, you should lower your headlights anytime you are 500 feet or less from an incoming vehicle or 300 feet from a vehicle you are following.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":765,"qid":32173,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the North Dakota Commercial Drivers License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":765,"qid":32181,"description":"How many hours of sleep per night do you need to keep yourself alert while driving?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. Getting fewer than six hours of sleep every night increases the likelihood of an accident by thrice. At least 15% of heavy truck accidents are thought to be caused by driver weariness. You should acquire enough sleep before your vacation. To stay attentive, the typical human need seven to eight hours of sleep per 24 hours.","imageName":"","correctAnswer":19131,"isBookmarked":0,"aid":"19131#19132#19133#19134","text":"Seven to eight hours#Six to seven hours#Eight to nine hours#Six to eight hours"},
{"testId":772,"qid":32190,"description":"Within _____ miles of the farm, a farmer does NOT need a CDL to drive a farm vehicle to bring or take away goods.","explanation":"Within a 150-mile radius of your farm, you do not need a CDL to move farm-related goods to or from it.","imageName":"","correctAnswer":12996,"isBookmarked":0,"aid":"6591#7570#7571#12996","text":"100#200#50#150"},
{"testId":772,"qid":32195,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the South Dakota Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":772,"qid":32223,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The South Dakota Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":772,"qid":32236,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These scenarios are also discussed in South Dakota Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":772,"qid":32237,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the South Dakota Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":780,"qid":32240,"description":"If you have a CDL from another state, you must obtain a Wyoming CDL within _______ of becoming a Wyoming resident.","explanation":"If you have a CDL from another state, you must get a Wyoming CDL within 30 days of becoming a Wyoming resident.","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"740#742#1020#11553","text":"15 days#30 days#60 days#45 days"},
{"testId":780,"qid":32248,"description":"How many hours of sleep per night do you need to keep yourself alert while driving?","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. You should acquire enough sleep before your vacation. Adults need 8 to 9 hours of sleep every night to be awake.","imageName":"","correctAnswer":19133,"isBookmarked":0,"aid":"19131#19132#19133#35336","text":"Seven to eight hours#Six to seven hours#Eight to nine hours#Five to six hours"},
{"testId":780,"qid":32253,"description":"Which lights should be used as often as possible when driving at night?","explanation":null,"imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":780,"qid":32258,"description":"If you are guilty of a second DWUI conviction in a CMV or your personal vehicle, for what duration will you lose your CDL driving privileges?","explanation":"If you are convicted of DWUI for the second time in a CMV or your own vehicle, you will lose your CDL for life. A blood alcohol content (BAC) of 0.04 percent while driving a CMV qualifies as DWUI. (A typical 180-pound guy will have a BAC of more than 0.04 percent after just two 12-ounce cans of beer before his body begins to break down the alcohol.) Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. Seek assistance if you receive a DWUI before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":780,"qid":32263,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities required for the safe operation of a commercial motor vehicle are accelerating, steering, stopping, and backing properly. These abilities are covered in depth in section 2.2 of the Wyoming Driver License Manual for Commercial & Heavy Vehicles. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":780,"qid":32278,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Wyoming Driver License Manual for Commercial & Heavy Vehicles specifies that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":780,"qid":32280,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Wyoming Driver License Manual for Commercial & Heavy Vehicles also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":733,"qid":32290,"description":"Which of the following individuals may be eligible for an Off-Highway CDL?","explanation":"A person from Alaska who lives in a small, isolated town may apply for an off-highway CDL. With an Off-Highway CDL, a resident may only operate a vehicle for business purposes on streets not linked to the state highway network and on streets not connected to a street with an average daily traffic flow of more than 499. An Off-Highway CDL may be obtained without passing a skills (road) exam, unlike a conventional CDL. This kind of licence is only available in Alaska, one of the US states. [Alaska Division of Motor Vehicles, \"Guide to Rural Driving Information,\" http://doa.alaska.gov/dmv/akol/rural .htm]","imageName":"","correctAnswer":42549,"isBookmarked":0,"aid":"203#19082#42549#42550","text":"All of the above#A farm employee in Anchorage#A resident of a remote rural community#An individual who is transporting food"},
{"testId":733,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":734,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":735,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":738,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":739,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":741,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":742,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":748,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":749,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":750,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":751,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":752,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":753,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":760,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":762,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":764,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":765,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":766,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":768,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":770,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":771,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":772,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":774,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":775,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":776,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":777,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":778,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":779,"qid":32295,"description":"Which of the following should you NOT do if your tire fails?","explanation":"Braking too hard may cause your car to skid, so brake softly only once the vehicle has slowed and you have recovered control.","imageName":"","correctAnswer":30839,"isBookmarked":0,"aid":"19085#19086#23635#30839","text":"Hold the steering wheel firmly#Stay off the brake pedal#Be aware that a tire has failed#Brake hard and immediately"},
{"testId":733,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":734,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":735,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":737,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":738,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":739,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":741,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":745,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":747,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":748,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":749,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":750,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":751,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":753,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":754,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":755,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":756,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":757,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":758,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":759,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":760,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":762,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":764,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":765,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":766,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":768,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":770,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":771,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":772,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":774,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":775,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":776,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":778,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":780,"qid":32298,"description":"What is the most frequent cause of tire fires?","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":17872,"isBookmarked":0,"aid":"203#17872#18610#19092","text":"All of the above#Underinflated tires#Cold tires#Overinflated tires"},
{"testId":733,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":747,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":748,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":749,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":750,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":751,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":752,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":754,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":755,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":757,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":758,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":759,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":760,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":761,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":762,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":765,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":766,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":767,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":768,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":769,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":770,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":771,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":772,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":773,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":774,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":775,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":776,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":777,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":778,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":780,"qid":32299,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your brakes.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18670#18970#28446","text":"All of the above statements are correct#When your drive wheels have poor traction, the retarder may cause them to skid#You should turn the retarder off whenever the road is wet, icy, or snow-covered#Retarders help slow a vehicle, reducing the need for braking"},
{"testId":731,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":733,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":734,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":735,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":738,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":739,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":750,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":751,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":752,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":753,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":755,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":756,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":757,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":759,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":760,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":761,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":762,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":763,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":764,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":765,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":766,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":767,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":768,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":769,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":770,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":771,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":772,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":773,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":774,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":775,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":776,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":777,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":778,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":779,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":780,"qid":32300,"description":"Which of the following is NOT part of the basic way to move up?","explanation":"You must simultaneously release the accelerator, push the clutch, and shift into neutral, then release the clutch, let the engine and gears slow to the needed rpm for the next gear, press the clutch, and shift into a higher gear, then release the clutch and press the accelerator. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":733,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":734,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":735,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":738,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":739,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":745,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":747,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":748,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":749,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":750,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":751,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":753,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":755,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":757,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":758,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":759,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":760,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":762,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":763,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":764,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":765,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":766,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":768,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":770,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":771,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":772,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":774,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":775,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":776,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":778,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":779,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":780,"qid":32303,"description":"Why should cargo be covered?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":733,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":734,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":735,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":738,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":739,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":774,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":778,"qid":32306,"description":"The total stopping distance is made up of the:","explanation":"Total stopping distance is the sum of your perception distance (the distance between when you perceive the danger and when your brain interprets it), response distance (the distance between when your brain directs your foot to take action and when your foot actually begins to brake), and braking distance (how far the vehicle continues to travel once you apply the brake).","imageName":"","correctAnswer":18949,"isBookmarked":0,"aid":"18947#18948#18949#18950","text":"reaction distance + braking distance#braking distance + stopping distance#perception distance + reaction distance + braking distance#reaction distance + viewing distance + braking distance"},
{"testId":733,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":734,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":735,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":739,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":762,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":765,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":768,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":770,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":771,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":772,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":774,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":775,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":776,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":778,"qid":32310,"description":"If you are convicted of a second DUI violation in either a CMV or your own vehicle, for what will you lose your CDL driving privileges?","explanation":"If you are convicted of DUI a second time, you will lose your CDL rights for the rest of your life. A blood alcohol content (BAC) of 0.04 percent or above while driving a CMV is considered DUI. Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":733,"qid":32312,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#35337","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet from the vehicle"},
{"testId":731,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":733,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":735,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":738,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":739,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":759,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":760,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":761,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":762,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":764,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":765,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":766,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":768,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":769,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":770,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":771,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":772,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":773,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":774,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":775,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":776,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":777,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":778,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":779,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":780,"qid":32313,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"If an aggressive motorist approaches you, do not give the offender the confrontation he or she desires. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"18994#18995#23000#35128","text":"Avoid eye contact#Ignore rude gestures and refuse to react negatively#Do all of the above#Call the police from your cell phone if you can do it safely"},
{"testId":733,"qid":32318,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Alaska Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":733,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":734,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":735,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":738,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":739,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":741,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":742,"qid":32324,"description":"What will assist an alcoholic to sober up?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":733,"qid":32328,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These scenarios are also covered in Alaska Commercial Driver License Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":733,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":734,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":738,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":739,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":741,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":742,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":751,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":758,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":759,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":760,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":761,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":762,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":764,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":766,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":767,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":769,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":770,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":771,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":772,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":774,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":775,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":776,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":777,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":778,"qid":32329,"description":"How many tie-downs are needed to secure a 20-foot load?","explanation":"The guideline is that one tie-down should be used for every 10 feet of cargo, and at least two should be used per load, regardless of length. So you should have two tie-downs for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":733,"qid":32333,"description":"To reduce travel-related sleepiness, you should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":774,"qid":32333,"description":"To reduce travel-related sleepiness, you should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":778,"qid":32333,"description":"To reduce travel-related sleepiness, you should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. People are sometimes astonished to learn that sleeping fewer than 6 hours each night triples the chance of an accident. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":733,"qid":32334,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. According to the Alaska Commercial Driver License Manual, you should lower your headlights if you are within 500 feet of an incoming vehicle or a vehicle you are following.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"18694#18695#18696#42551","text":"Low beams#High beams#Novelty lights#Four-way flashers"},
{"testId":733,"qid":32337,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Alaska Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":739,"qid":32340,"description":"Which of the following drivers may be able to get a special license to drive CMVs even though they don't have a CDL?","explanation":null,"imageName":"","correctAnswer":19138,"isBookmarked":0,"aid":"203#19138#19140#44061","text":"All of the above#Firefighters#For-hire situational drivers#Drivers engaged in interstate commerce"},
{"testId":739,"qid":32354,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle are accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the State of Delaware Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":739,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":742,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":745,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":746,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":747,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":751,"qid":32359,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":739,"qid":32366,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The State of Delaware Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle under Section 2.11.5.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":739,"qid":32371,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the State of Delaware Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":739,"qid":32373,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"Please take notice of the word \"EXCEPT\" in the question. When doing this section of the check, start the engine after shifting into neutral. Then, listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":44063,"isBookmarked":0,"aid":"18983#18984#44062#44063","text":"checking the air pressure gauge#checking the transmission controls#starting the engine and then listening for unusual noises#starting the engine and then putting the gearshift in neutral"},
{"testId":739,"qid":32383,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the State of Delaware Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":770,"qid":32390,"description":"Your CDL history for the past ______ will be checked when you submit an Affidavit for CDL History Check.","explanation":"You must provide an Affidavit for CDL History Check while applying for a Rhode Island CDL. Following that, a ten-year review of your CDL history will be performed. What they discover may have an impact on your eligibility for a Rhode Island CDL.","imageName":"","correctAnswer":1322,"isBookmarked":0,"aid":"1319#1320#1321#1322","text":"year#two years#five years#ten years"},
{"testId":770,"qid":32410,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Rhode Island Commercial Driver License Manual suggests dimming your headlights if you are within 500 feet of another vehicle (section 2.11.5).","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":770,"qid":32414,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Rhode Island Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":770,"qid":32423,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Rhode Island Commercial Driver License Manual recommends that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":770,"qid":32439,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for safe operation of a commercial motor vehicle include accelerating, steering, stopping, and backing up properly. These abilities are covered in depth in section 2.2 of the Rhode Island Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":775,"qid":32440,"description":"Which of the following drivers do not need a CDL in Vermont?","explanation":"A Vermont CDL is not required for an active-duty military member operating a military vehicle. Exempt is also a first responder driving an emergency vehicle. Farmers are exempt only if they travel within 150 miles of their farm. To transport bees commercially, a beekeeper must obtain a CDL. Transporting bees, on the other hand, is deemed excluded trade, thus he or she is not needed to have a current medical examiner's certificate on file.","imageName":"","correctAnswer":36729,"isBookmarked":0,"aid":"203#36727#36728#36729","text":"All of the above#A farmer transporting products 300 miles from his or her farm#A beekeeper transporting live bees#An active-duty military member driving a military truck"},
{"testId":775,"qid":32441,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, use your high lights to see further as long as it is safe and legal and will not blind other cars. The Vermont Commercial Driver's Manual suggests dimming your headlights anytime you are within 500 feet of an approaching vehicle under Section 2.11.5.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":775,"qid":32450,"description":"How frequently must you pull over to inspect your cargo while driving?","explanation":"While the laws differ by jurisdiction, the Vermont Commercial Driver's Manual indicates that you should inspect your cargo within the first 50 miles of your voyage, then every 3 hours or 150 miles afterwards, as well as after each stop.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":775,"qid":32456,"description":"The most crucial hand signal to agree upon with a helper is:","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":28451,"isBookmarked":0,"aid":"28449#28450#28451#28452","text":"鈥淕o.鈥#鈥淔aster.鈥#鈥淪top.鈥#鈥淭urn up the music.鈥"},
{"testId":775,"qid":32466,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Vermont Commercial Driver's Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":775,"qid":32474,"description":"To keep vigilance when driving during the trip, drivers should:","explanation":"Sleep debt is a perilous situation in which missed sleep accumulates to the point that you risk falling asleep behind the wheel unless you get some rest. Getting fewer than 6 hours of sleep every night increases the chance of an accident by thrice. At least 15% of heavy truck accidents are thought to be caused by driver weariness. Before a trip, you should get enough sleep (adults need 8 to 9 hours to maintain alertness); carefully plan your route to identify total distance, stopping points, and other logistical considerations; schedule trips during the hours when you are normally awake, not in the middle of the night; drive with a passenger; avoid medications that cause drowsiness; consult your physician if you suffer from daytime sleepiness, have difficulty sleeping at night, or take frequent medications that cause drowsiness.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#25878#33630#35131","text":"Do all of the above#avoid medications that cause drowsiness#get 8鈥9 hours of sleep beforehand#schedule your trips for the daytime hours"},
{"testId":775,"qid":32477,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (These issues are also addressed in Vermont Commercial Driver's Manual section 2.5.2.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":740,"qid":32490,"description":"Which of the following documents must you produce when applying for a CDL in the District of Columbia?","explanation":"You must have your medical certificate, valid driving license, and CDL learner permit with you when you take your CDL exam. But it isn't all. You must also provide your US DOT medical examination record, evidence of US citizenship, and an empty commercial vehicle of the sort for which you are applying. Check online before going to the exam to be sure you have everything you need.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#19144#19146#26872","text":"All of the above#Medical examiner certificate#Valid CDL learner permit#Valid District of Columbia driver license"},
{"testId":740,"qid":32491,"description":"What is the most crucial hand gesture on which you and your assistant should agree?","explanation":"Unfortunately, once an accident occurs, it is irreversible. That is why it is critical for you and your assistant to establish a clear hand signal for \"Stop.\" That way, you'll be able to stop what you're doing fast before an accident occurs.","imageName":"","correctAnswer":25525,"isBookmarked":0,"aid":"25523#25524#25525#25526","text":"\"Go.\"#\"Faster.\"#\"Stop.\"#\"Turn up the music.\""},
{"testId":740,"qid":32492,"description":"In which of the two situations below should you downshift?","explanation":"You may use engine braking by downshifting before beginning down a slope. Downshift to the needed gear, which is often lower than the gear required to ascend the slope. Downshifting before a curve increases stability and provides the necessary power to accelerate out of the bend.","imageName":"","correctAnswer":18708,"isBookmarked":0,"aid":"18708#18709#18710#18711","text":"Starting down a hill and entering a curve#Starting up a hill and finishing a curve#Starting down a hill and finishing a curve#Starting up a hill and entering a curve"},
{"testId":740,"qid":32493,"description":"Starting the engine and inspecting the cab includes all of the following except:","explanation":"When executing this portion of the examination, you must shift into neutral, start the engine, and listen for any strange sounds. Following the engine check, you will inspect all of your gauges and controls, including the air pressure gauge and gearbox controls.","imageName":"","correctAnswer":18986,"isBookmarked":0,"aid":"18983#18984#18985#18986","text":"checking the air pressure gauge#checking the transmission controls#starting the engine, then listening for unusual noises#starting the engine, then putting the gearshift in neutral"},
{"testId":740,"qid":32494,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":743,"qid":32494,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":744,"qid":32494,"description":"When you double your speed on the highway, your braking distance increases by:","explanation":"When you double your speed, your stopping distance climbs to almost four times what it was before. Increasing your speed from 15 to 30 mph, for example, will increase your stopping distance from 46 to 148 feet.","imageName":"","correctAnswer":18682,"isBookmarked":0,"aid":"18682#18683#18684#18685","text":"Four times as much#Three times as much#Twice as much#Five times as much"},
{"testId":740,"qid":32495,"description":"What could happen if you take a wide turn to the left before turning right?","explanation":"If you swing wide to the left, a vehicle may attempt to pass on your right as you approach the bend. Instead, make a right turn with the back of your car as near to the curb as feasible, then turn wide to give traffic on the right space to pass.","imageName":"","correctAnswer":18988,"isBookmarked":0,"aid":"18987#18988#18989#26873","text":"Someone might try to pass you on your left#Someone might try to pass you on your right#You might damage your leaf springs#All of the above might happen"},
{"testId":740,"qid":32496,"description":"The temperature has barely touched freezing. Which of the following locations might be slick?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18672#18673#26874","text":"All of the above#A shaded area#A bridge#A wet road"},
{"testId":740,"qid":32498,"description":"What you must inspect a sealed load before transferring it is?","explanation":"It is not possible to check sealed loads. However, it is still your duty to ensure that you do not exceed your axle and gross weight limitations, so double-check them both.","imageName":"","correctAnswer":19006,"isBookmarked":0,"aid":"8615#19004#19005#19006","text":"All of the above#A small sample of whatever is inside#A picture of whatever is inside#That you don't exceed gross weight and axle weight limits"},
{"testId":740,"qid":32499,"description":"Which of the following is NOT a basic part of shifting up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":743,"qid":32499,"description":"Which of the following is NOT a basic part of shifting up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":744,"qid":32499,"description":"Which of the following is NOT a basic part of shifting up?","explanation":"You must release the accelerator, push in the clutch, and shift into neutral all at once, then release the clutch, let the engine and gears slow to the required rpm for the next gear, push in the clutch, and shift into a higher gear all at once, then release the clutch and press the accelerator all at once. There is no acceleration until the very finish, and certainly not when pumping the clutch.","imageName":"","correctAnswer":26876,"isBookmarked":0,"aid":"19094#19096#26875#26876","text":"Releasing the clutch#Releasing the clutch and pressing the accelerator at the same time#Pushing in the clutch and shifting into a higher gear at the same time#Accelerating while pressing the clutch and turning toward the driver's side"},
{"testId":740,"qid":32500,"description":"Which of the following factors can influence the safe speed for descending a steep downgrade?","explanation":"The steepness, length, road and weather conditions, and weight of your vehicle and cargo are all elements that may impact your decision of a safe speed for travelling down a steep downhill.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":743,"qid":32500,"description":"Which of the following factors can influence the safe speed for descending a steep downgrade?","explanation":"The steepness, length, road and weather conditions, and weight of your vehicle and cargo are all elements that may impact your decision of a safe speed for travelling down a steep downhill.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":744,"qid":32500,"description":"Which of the following factors can influence the safe speed for descending a steep downgrade?","explanation":"The steepness, length, road and weather conditions, and weight of your vehicle and cargo are all elements that may impact your decision of a safe speed for travelling down a steep downhill.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18595#18596#26877","text":"All of the above#The steepness of the grade#The road conditions#The total weight of the vehicle and its cargo"},
{"testId":740,"qid":32501,"description":"If you are convicted of a second DUI conviction, for what duration will you lose your CDL driving privileges?","explanation":"A second DUI conviction in either a CMV or non-CMV vehicle will result in the loss of your CDL for life under federal drunk driving rules. (See the Federal Motor Carrier Safety Administration's (FMCSA) Federal Motor Carrier Safety Regulations Part 383.51). Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":743,"qid":32501,"description":"If you are convicted of a second DUI conviction, for what duration will you lose your CDL driving privileges?","explanation":"A second DUI conviction in either a CMV or non-CMV vehicle will result in the loss of your CDL for life under federal drunk driving rules. (See the Federal Motor Carrier Safety Administration's (FMCSA) Federal Motor Carrier Safety Regulations Part 383.51). Your first offense is a wake-up call and an opportunity to seek treatment, but your second infraction will result in unemployment. If you receive a DUI, get treatment immediately before you lose your job and your future.","imageName":"","correctAnswer":18830,"isBookmarked":0,"aid":"18830#19110#19111#19112","text":"Life#Five years minimum#One year minimum#Ten years minimum"},
{"testId":740,"qid":32502,"description":"You don't want to drive while distracted, so you:","explanation":"Even on a straightaway or an empty road, a distraction is still a distraction; do not eat, drink, smoke, text, read, or have tough conversations while driving; and, if feasible, switch off and leave your phone off until you are through driving for the day.","imageName":"","correctAnswer":18951,"isBookmarked":0,"aid":"18951#25482#25483#25484","text":"turn off your cell phone until you reach your destination#have all your emotionally difficult conversations in your first hour of driving#smoke, eat, and drink on straight portions of the road#read maps or use your phone only when there are no other vehicles around you"},
{"testId":740,"qid":32503,"description":"What will assist an alcoholic to sober up?","explanation":"Alcohol is broken down by your liver at a regular pace, usually one standard drink (one ounce of hard liquor or one-half glass of wine) each hour. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":743,"qid":32503,"description":"What will assist an alcoholic to sober up?","explanation":"Alcohol is broken down by your liver at a regular pace, usually one standard drink (one ounce of hard liquor or one-half glass of wine) each hour. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":744,"qid":32503,"description":"What will assist an alcoholic to sober up?","explanation":"Alcohol is broken down by your liver at a regular pace, usually one standard drink (one ounce of hard liquor or one-half glass of wine) each hour. That pace cannot be increased by coffee, fresh air, or water. You must wait till the alcohol has degraded.","imageName":"","correctAnswer":18631,"isBookmarked":0,"aid":"17525#18630#18631#18632","text":"Coffee#Fresh air#Time#A glass of water"},
{"testId":740,"qid":32504,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that one tiedown should be used for every 10 feet of cargo, and each load must have at least two tiedowns regardless of length. So you'd have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":743,"qid":32504,"description":"How many tiedowns are needed to secure a 20-foot load?","explanation":"The norm is that one tiedown should be used for every 10 feet of cargo, and each load must have at least two tiedowns regardless of length. So you'd have two tiedowns for every 20 feet.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":740,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":743,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":744,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":745,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":746,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":747,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":750,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":752,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":753,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":755,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":757,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":758,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":759,"qid":32505,"description":"What are the placards for hazardous materials?","explanation":"Hazardous materials placards are four regulated signs on the exterior of a truck that designate the cargo's danger class for those who need to know, such as emergency responders or people who load and unload freight.","imageName":"","correctAnswer":25535,"isBookmarked":0,"aid":"25535#26878#26879#26880","text":"Signs on the outside of the vehicle that identify the hazard class of the cargo#Signs on the inside of the vehicle that remind the driver of what he or she is carrying#Signs that help tax collectors determine how much to tax a hazardous cargo#Signs that warn the public to stay at least 1,000 feet away from the vehicle"},
{"testId":740,"qid":32506,"description":"Why should your shipment be protected?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":743,"qid":32506,"description":"Why should your shipment be protected?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":744,"qid":32506,"description":"Why should your shipment be protected?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":746,"qid":32506,"description":"Why should your shipment be protected?","explanation":"Covering your goods serves numerous purposes, including safeguarding other individuals and the cargo itself. Furthermore, it will assist you in complying with rules in several states.","imageName":"","correctAnswer":26881,"isBookmarked":0,"aid":"18643#18644#18645#26881","text":"To protect individuals from any spilled cargo#To protect your cargo from bad weather#Because many states require it#For all of the above reasons"},
{"testId":740,"qid":32507,"description":"Is it absolutely safe to remove the radiator cap if the engine is not overheated?","explanation":"It is not enough that the engine does not overheat. Before attempting to remove the radiator cap, let the cooling system to totally cool.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#26882#26883","text":"No#Yes#Yes, as long as there is no overflow#Yes, as long as the radiator isn't damaged"},
{"testId":740,"qid":32508,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your location to start your car and inadvertently damage you while doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":743,"qid":32508,"description":"While doing the pre-trip inspection, keep the starter switch key in your pocket because:","explanation":"You do not want someone (such as a co-driver) who is ignorant of your location to start your car and inadvertently damage you while doing the pre-trip examination.","imageName":"","correctAnswer":19002,"isBookmarked":0,"aid":"19002#19003#26884#33984","text":"someone could start and move the truck#it could damage the starting mechanism#any of the above could occur#it could damage the steering mechanism"},
{"testId":740,"qid":32509,"description":"_____ are frequently the cause of tyre fires.","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":26885,"isBookmarked":0,"aid":"8615#21263#26885#26886","text":"All of the above#Overinflated tires#Underinflated tires#Cold tires"},
{"testId":743,"qid":32509,"description":"_____ are frequently the cause of tyre fires.","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":26885,"isBookmarked":0,"aid":"8615#21263#26885#26886","text":"All of the above#Overinflated tires#Underinflated tires#Cold tires"},
{"testId":744,"qid":32509,"description":"_____ are frequently the cause of tyre fires.","explanation":"The most prevalent causes of tire fires are underinflated tires and dual tires that contact.","imageName":"","correctAnswer":26885,"isBookmarked":0,"aid":"8615#21263#26885#26886","text":"All of the above#Overinflated tires#Underinflated tires#Cold tires"},
{"testId":740,"qid":32510,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":26887,"isBookmarked":0,"aid":"25555#25556#26887#26888","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":745,"qid":32510,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":26887,"isBookmarked":0,"aid":"25555#25556#26887#26888","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":746,"qid":32510,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":26887,"isBookmarked":0,"aid":"25555#25556#26887#26888","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":747,"qid":32510,"description":"What is the best method for calculating the number of seconds of following distance you have?","explanation":"Count the number of seconds it takes you to reach an item after the car in front of you has passed it. \"One thousand and one, one thousand and two,...\" The other three options below will either not work or will require harmful diversions. Keep in mind that you should increase your following distance in heavy traffic, poor weather, with big trucks, or at greater speeds.","imageName":"","correctAnswer":26887,"isBookmarked":0,"aid":"25555#25556#26887#26888","text":"Use the stopwatch on your cell phone to determine how long it takes you to reach a mile marker after the car in front of you has passed it#Send a text message to a friend to text you back in ten seconds. See how far you have traveled in that time#Wait until the vehicle ahead of you passes a shadow or landmark. Then start to count the seconds until you reach it#Get 1/4 closer to the car in front of you, then back off again. Multiply how long this took you by four to give the following distance"},
{"testId":740,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":743,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":744,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":745,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":746,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":747,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":748,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":749,"qid":32512,"description":"What does the word \"hazard\" mean?","explanation":"A hazard is a road user or a road situation that poses a risk. A danger is anything that may go wrong but probably won't if you're careful.","imageName":"","correctAnswer":26889,"isBookmarked":0,"aid":"18515#18516#18517#26889","text":"Something you can easily avoid#Something you must stop for#Something you can safely ignore#A road user or road condition that presents a possible danger"},
{"testId":740,"qid":32516,"description":"Which of the following is true about people with retardation?","explanation":"While retarders might assist lessen the need for braking, they can also increase the likelihood of a skid in bad weather or when your wheels have inadequate grip. As a result, in bad weather, you should always switch off your retarder.","imageName":"","correctAnswer":10975,"isBookmarked":0,"aid":"10975#18669#18970#26890","text":"All of the above statements are correct#Retarders help slow a vehicle, reducing the need for using your brakes#You should turn the retarder off whenever the road is wet, icy, or snow-covered#If your drive wheels have poor traction, the retarder may cause them to skid"},
{"testId":740,"qid":32518,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, you should use your high lights as much as possible to increase your field of view, as long as it is safe and legal and you will not blind other drivers. As a general guideline, utilize high beams if there are no incoming cars within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":743,"qid":32518,"description":"Which lights should be used as often as possible when driving at night?","explanation":"When driving at night, you should use your high lights as much as possible to increase your field of view, as long as it is safe and legal and you will not blind other drivers. As a general guideline, utilize high beams if there are no incoming cars within 500 feet.","imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":740,"qid":32523,"description":"How can you know if your vehicle has an anti-lock braking system (ABS)?","explanation":"Most ABS-equipped cars now include a light on the instrument panel that illuminates momentarily when the vehicle is started to notify you to the ABS. You may also look for cables from your brakes.","imageName":"","correctAnswer":25528,"isBookmarked":0,"aid":"18955#18957#25527#25528","text":"Check for yellow ABS malfunction lamps on the instrument panel#Look for wheel speed sensor wires that are coming from the rear of your brakes#Check if your vehicle was manufactured after March 1, 1998. (Such vehicles are required to have the panel light.)#All of the above can work"},
{"testId":740,"qid":32524,"description":"What can you do at the scene of an accident to help prevent another one?","explanation":"It is your obligation, unless you have been wounded, to ensure that others can see your car at an accident site. Set up your warning systems as soon as possible to prevent more mishaps like a pile-up.","imageName":"","correctAnswer":26891,"isBookmarked":0,"aid":"18979#18981#18982#26891","text":"Stay in your vehicle and do nothing until help arrives#Leave your vehicle, do nothing, and get to higher ground#Have a drink to calm your nerves#Set out warning devices to keep other vehicles from running into the accident scene"},
{"testId":740,"qid":32525,"description":"How frequently must you stop on the road to inspect your cargo?","explanation":"While regulations differ by jurisdiction, in DC, you must verify your cargo within the first 50 miles of your voyage and then every three hours or 150 miles afterwards, whichever comes first.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18731#19116#25521","text":"All of the above#Within the first 50 miles#Every three hours or 150 miles#After each break you take"},
{"testId":740,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":743,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":744,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":746,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":747,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":748,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":749,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":750,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":751,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":752,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":755,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":757,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":758,"qid":32528,"description":"When you and your instructor inspect the hoses for your pre-trip test, you should be on the lookout for:","explanation":"When examining hoses during your pre-trip inspection, look for indicators of leaks, such as pools on the ground or fluids leaking on the bottom of the engine or gearbox. Inspect the hoses for cracks or other issues.","imageName":"","correctAnswer":18990,"isBookmarked":0,"aid":"18990#18991#18992#25498","text":"puddles on the ground#the location of the dipstick#frays in the water pump belt#a low windshield washer fluid level"},
{"testId":740,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":743,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":744,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":745,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":746,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":747,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":748,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":750,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":751,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":753,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":754,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":755,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":756,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":757,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":758,"qid":32529,"description":"What should you do if you're confronted by an aggressive driver?","explanation":"When an aggressive motorist attempts to approach you, do not give the individual what he or she wants. Instead, look for law enforcement, tranquility, and safety.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26892#26893#26894","text":"Do all of the above#Avoid eye contact#Ignore rude gestures and refuse to react negatively#Call the police from your cell phone if you can do it safely"},
{"testId":740,"qid":32530,"description":"Which of the following should you NOT check while driving?","explanation":"Throughout your journey, keep an eye on all of your vehicle's critical systems, such as the instruments, gauges, tires, voltmeter, mirrors, and cargo. Make sure you put your phone aside for the duration of the travel. Keep it just for emergencies.","imageName":"","correctAnswer":18659,"isBookmarked":0,"aid":"8088#18657#18658#18659","text":"Tires#Mirrors#Cargo and cargo covers#Text messages"},
{"testId":740,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":743,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":744,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":746,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":747,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":748,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":749,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":750,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":751,"qid":32531,"description":"Reduce your speed by _____ on wet roads.","explanation":"When the road is wet, the stopping distance increases, and you should slow down by one-third. Slow down to half your normal speed while driving on snow. Slow down and stop driving as soon as you can safely do so on ice roads.","imageName":"","correctAnswer":18968,"isBookmarked":0,"aid":"18966#18967#18968#18969","text":"one-half#one-quarter#one-third#60 percent"},
{"testId":740,"qid":32533,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you should both agree on a series of hand signals, particularly \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":743,"qid":32533,"description":"It is essential to use an assistance while backing up :","explanation":"When backing, it is critical to have someone assist you since you will be dealing with blind areas that you cannot see. Before you begin, you should both agree on a series of hand signals, particularly \"Stop.\"","imageName":"","correctAnswer":25547,"isBookmarked":0,"aid":"25547#25548#25549#25550","text":"because you have blind spots#because people feel more comfortable when you do#because you are providing a job for someone else#for all of the above reasons"},
{"testId":740,"qid":32534,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":743,"qid":32534,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":744,"qid":32534,"description":"How long does a standard tractor-trailer take to clear a double railroad track?","explanation":"A typical tractor-trailer will take more than 15 seconds to clear a double track and at least 14 seconds to clear a single track.","imageName":"","correctAnswer":18602,"isBookmarked":0,"aid":"985#1012#18601#18602","text":"10 seconds#14 seconds#More than 30 seconds#More than 15 seconds"},
{"testId":740,"qid":32536,"description":"How many hours of sleep does the average person require per night to prevent sleep debt?","explanation":"Missed sleep accumulates until you risk falling asleep behind the wheel till you have completely refreshed. Surprisingly, research reveal that sleeping for six hours or less every night triples your chance of an accident.","imageName":"","correctAnswer":18725,"isBookmarked":0,"aid":"18725#18726#18727#18728","text":"7 to 8 hours#6 to 7 hours#8 to 9 hours#6 to 8 hours"},
{"testId":740,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":743,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":744,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":745,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":746,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":747,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":748,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":749,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":750,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":751,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":752,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":753,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":754,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":755,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":756,"qid":32537,"description":"Is it true that empty vehicles have the greatest braking?","explanation":"Trucks brake best when utilized as intended: to transport a well balanced load. Because they have less traction, empty vehicles have longer stopping distances than loaded trucks.","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#21234#25560#25561","text":"No#Yes#Yes, but only on wet surfaces#Yes, but only if the trucks are newer than 1998"},
{"testId":740,"qid":32538,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends.","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":743,"qid":32538,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"This is mandated under the Federal Motor Carrier Safety Administration's Federal Motor Carrier Safety Regulations Part 392.22. (FMCSA). These laws also stipulate where warning devices should be placed on various kinds of routes, such as two-way streets and bends.","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":740,"qid":32539,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial vehicle safely are accelerating, steering, stopping, and backing properly, as detailed in section 2.2 of the handbook. Although having a first aid certification could be useful at some point in your career, it is not one of the four fundamental skills.","imageName":"","correctAnswer":26895,"isBookmarked":0,"aid":"7673#18705#26895#26896","text":"Accelerating#Steering#First aid#Backing safely"},
{"testId":776,"qid":32540,"description":"How frequently should you examine your tires while driving in very hot weather?","explanation":"With rising temperatures, air pressure rises. Check the tyres every two hours or 100 miles while travelling in very hot conditions. To acquire a precise measurement, use a tyre pressure gauge. The pressure will drop too low as the tyres cool, therefore even if they are overinflated due to the heat, do not let air out. Keep coming to a halt if a tyre is too hot to touch. If not, it may explode or catch fire.","imageName":"","correctAnswer":32952,"isBookmarked":0,"aid":"29620#32952#36704#36705","text":"Every hour or every 50 miles#Every two hours or every 100 miles#Every three hours or every 150 miles#Every four hours or every 200 miles"},
{"testId":776,"qid":32541,"description":"What exactly is gross vehicle weight (GVW)?","explanation":"The simplest of the vehicle weight phrases is GVW, which is defined as the weight of a single vehicle and the load it is transporting. The weight of a combination vehicle (including the power unit and its trailers) and its payload is referred to as the gross combination weight (GCW).","imageName":"","correctAnswer":18389,"isBookmarked":0,"aid":"18389#19090#36706#36707","text":"The total weight of a single vehicle and its load#The total weight of the vehicle, towed vehicles, and the load#The weight of the cargo carried by a vehicle#The total weight of a single unfueled vehicle and its load"},
{"testId":776,"qid":32542,"description":"Which of the following should you do if the road is coated in ice?","explanation":"Even at a leisurely pace, there is a considerable likelihood of a skid on black ice. Reduce your speed to a crawl and increase your following distance. Then, as soon as you may safely do so, get off the slippery road.","imageName":"","correctAnswer":36708,"isBookmarked":0,"aid":"36708#36709#36710#36711","text":"Drive at a crawl if at all#Reduce your speed by one-quarter#Reduce your speed by one-third#Reduce your speed by one-half"},
{"testId":776,"qid":32543,"description":"Which of the following is NOT one of the four basic abilities required to operate a commercial vehicle?","explanation":"The four fundamental abilities for operating a commercial motor vehicle safely are accelerating, steering, stopping, and backing safely. These abilities are covered in depth in section 2.2 of the Virginia Commercial Driver License Manual. First-aid training is not one of the four fundamental talents, despite the fact that it could be useful at some time in your career.","imageName":"","correctAnswer":28453,"isBookmarked":0,"aid":"7673#18705#26896#28453","text":"Accelerating#Steering#Backing safely#First-aid certification"},
{"testId":776,"qid":32546,"description":"You uncover a safety-related issue during your pre-trip examination of your vechicle. What should you do?","explanation":"Operating a commercial motor vehicle that you believe is hazardous is a violation of both federal and state law. Before you go, make sure that any safety issues are resolved.","imageName":"","correctAnswer":36714,"isBookmarked":0,"aid":"36712#36713#36714#36715","text":"Drive with caution until it can be fixed#Have it fixed as soon as your trip is over#Have it fixed before you start your trip#Don't worry about it"},
{"testId":776,"qid":32547,"description":"In which of the following conditions is jackknifing most likely?","explanation":"When your car is lightly loaded, the tight suspension springs and powerful brakes provide insufficient traction. This increases the likelihood of the wheels locking and the trailer swinging out.","imageName":"","correctAnswer":36716,"isBookmarked":0,"aid":"19165#19166#36716#36717","text":"When you are driving in especially windy conditions#When you are driving on soft gravel#When your trailer is empty or lightly loaded#When your trailer is fully loaded"},
{"testId":776,"qid":32548,"description":"To turn left at an intersection, you should:","explanation":"Do not begin your left turn until you have reached the intersection's center. Off-tracking causes your vehicle's rear wheels to go a shorter distance than the front wheels. If you begin your left turn before reaching the intersection's center, the left side of your car may collide with another vehicle. Another tip: If there are two accessible left-turn lanes, choose the one on the right. Without swinging right, you may not have enough clearance to turn left from the leftmost turn lane. If you swing to the right, another car may attempt to pass you on the left as you turn.","imageName":"","correctAnswer":36720,"isBookmarked":0,"aid":"8956#36718#36719#36720","text":"use your horn#flash your high beams#start your turn before you reach the center of the intersection#start your turn when you reach the center of the intersection"},
{"testId":776,"qid":32549,"description":"It is your responsibility to inform your employer within ______聽after being found guilty of a moving traffic violation.","explanation":"Except for parking offenses, you must tell your employer within 30 days if you are convicted of a traffic violation. This is true whether you were driving a commercial vehicle or another kind of vehicle at the time.","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#35988#35989","text":"30 days#60 days#7 days#14 days"},
{"testId":776,"qid":32550,"description":"You need to be at least _____ to transport hazardous materials.","explanation":"To carry hazardous goods, you must be at least 21 years old, according to federal law. To carry any form of merchandise over state borders or operate a CMV outside the state that awarded your CDL, you must also be at least 21 years old.","imageName":"","correctAnswer":36722,"isBookmarked":0,"aid":"36721#36722#36723#36724","text":"25 years old#21 years old#20 years old#18 years old"},
{"testId":776,"qid":32557,"description":"Which lights should be used as often as possible when driving at night?","explanation":null,"imageName":"","correctAnswer":18695,"isBookmarked":0,"aid":"4328#18694#18695#18696","text":"Emergency flashers#Low beams#High beams#Novelty lights"},
{"testId":776,"qid":32581,"description":"Which of the following statement regarding using mirrors is correct?","explanation":"You should inspect your mirrors on a regular basis. There are, however, blind areas that your mirrors cannot reveal. Allow a helper to stand at the back of your car and see things that you cannot.","imageName":"","correctAnswer":21522,"isBookmarked":0,"aid":"21522#33239#36725#36726","text":"There are blind spots that your mirrors cannot show you#You should look at your mirrors several times a second#The best way to detect the failure of a dual tire is to look in your mirrors#Convex mirrors make things look closer than they are"},
{"testId":776,"qid":32589,"description":"Where should you put your warning lights if you have to stop at night on a one-way street or a highway with two lanes?","explanation":"The Federal Motor Carrier Safety Regulation 49 CFR 392.22 requires this. This law also defines where warning devices should be placed on various kinds of routes, such as undivided two-way streets and bends. (Section 2.5.2 of the Virginia Commercial Driver License Manual also addresses these scenarios.)","imageName":"","correctAnswer":18279,"isBookmarked":0,"aid":"18279#18281#18697#18698","text":"10 feet, 100 feet, and 200 feet toward approaching traffic#20 feet, 50 feet, and 100 feet toward approaching traffic#50 feet, 100 feet, and 150 feet toward approaching traffic#100 feet, 200 feet, and 300 feet toward approaching traffic"},
{"testId":10,"qid":32622,"description":"Which of the following lines on the road separates two lanes that are going the same way?","explanation":"Two lanes heading in the same direction are separated by a white line. You may cross a broken line to pass or change lanes. If the line is straight, you should normally remain in your lane.","imageName":"202004281831455951.jpg","correctAnswer":897,"isBookmarked":0,"aid":"322#323#324#897","text":"A#B#C#None of the above"},
{"testId":20,"qid":32652,"description":"This constant red X across a traffic lane implies that:","explanation":"Lane-use control signals are unique overhead signals that allow or disallow the usage of specified streets or highway lanes. A continuous red X over a lane denotes that you must not drive in that lane.","imageName":"202003051729142462.jpg","correctAnswer":19361,"isBookmarked":0,"aid":"19358#19359#19360#19361","text":"you must drive only in this lane#you may use this lane#you must stop in this lane#you must not drive in this lane"},
{"testId":16,"qid":32660,"description":"What is the meaning of this sign?","explanation":"This symbol denotes a service. It suggests that phone service will be available shortly.","imageName":"202003151712528763.jpg","correctAnswer":19388,"isBookmarked":0,"aid":"19355#19388#19389#19390","text":"A hospital is ahead#Telephone service is available ahead#A gas station is ahead#A rest area is ahead"},
{"testId":22,"qid":32664,"description":"What do these two arrows represent?","explanation":"This sign indicates that traffic may flow on both sides of the road.","imageName":"202002101234545593.jpg","correctAnswer":19403,"isBookmarked":0,"aid":"40#19324#19402#19403","text":"Divided highway ends#Two-way traffic is ahead#Divided highway begins#Traffic may flow on both sides"},
{"testId":45,"qid":32678,"description":"What is the meaning of this sign?","explanation":"No is represented as a red circle with a diagonal red slash. The red slash across the truck denotes that trucks are not permitted on this route. Truck drivers must seek other routes.","imageName":"202003301723251644.jpg","correctAnswer":34045,"isBookmarked":0,"aid":"34044#34045#40576#40577","text":"Only large trucks are allowed on this road#No trucks are allowed on this road#Only high-occupancy vehicles (HOVs) are allowed on this road#Trucks may not park here"},
{"testId":38,"qid":32698,"description":"What is the meaning of this sign?","explanation":"This sign denotes the presence of a roundabout ahead. A roundabout is a circular crossroads where traffic flows anticlockwise around a central island. Prepare to slow down and yield to traffic on the roundabout.","imageName":"202002101237092503.jpg","correctAnswer":19500,"isBookmarked":0,"aid":"19497#19498#19500#39294","text":"There is a traffic merging area ahead; stop before merging#There is a one-way road ahead; proceed with caution#There is a roundabout ahead; prepare to yield to traffic#U-turns are allowed here; slow down"},
{"testId":31,"qid":32702,"description":"What is the meaning of this sign?","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign indicates that pedestrians are not permitted to cross here.","imageName":"202004071600168996.jpg","correctAnswer":34714,"isBookmarked":0,"aid":"19507#34714#34715#34716","text":"Pedestrians ahead; no entry for vehicles#Pedestrians must not cross#Use extreme caution and slow down#Stop for pedestrians"},
{"testId":39,"qid":32707,"description":"This symbol indicates that:","explanation":"This sign indicates that you are nearing a junction with US Route 22. The United States Routes are a network of roads and highways that were built decades before the Interstate Highway System. One of the oldest, U.S. Route 22, connects Cincinnati, Ohio, and Newark, New Jersey.","imageName":"202004071612159987.jpg","correctAnswer":38799,"isBookmarked":0,"aid":"19521#19523#38798#38799","text":"exit 22 is ahead#mile marker 22 is ahead#the next exit is 22 miles ahead#an intersection with U.S. Route 22 is ahead"},
{"testId":42,"qid":32717,"description":"What is the meaning of this sign?","explanation":"This sign is located beside a route marker sign. It signifies you'll have to turn right shortly to enter or continue on that road.","imageName":"202003301755379492.jpg","correctAnswer":19552,"isBookmarked":0,"aid":"19550#19551#19552#19553","text":"A sharp left turn is ahead#A sharp U-turn is ahead#You are approaching a right turn#You are approaching a left turn"},
{"testId":10,"qid":32723,"description":"What is the meaning of this sign?","explanation":"This sign advises that there is a low spot ahead on the road. Slow down for your own comfort and control. Proceed with care and be prepared to halt if the dip becomes flooded.","imageName":"202002101230287150.jpg","correctAnswer":40863,"isBookmarked":0,"aid":"19570#19572#40862#40863","text":"There is a detour ahead#There is a work zone ahead#There is a bump in the road ahead#There is a low place in the road ahead"},
{"testId":44,"qid":32723,"description":"What is the meaning of this sign?","explanation":"This sign advises that there is a low spot ahead on the road. Slow down for your own comfort and control. Proceed with care and be prepared to halt if the dip becomes flooded.","imageName":"202002101230287150.jpg","correctAnswer":40863,"isBookmarked":0,"aid":"19570#19572#40862#40863","text":"There is a detour ahead#There is a work zone ahead#There is a bump in the road ahead#There is a low place in the road ahead"},
{"testId":44,"qid":32724,"description":"This symbol denotes:","explanation":"This sign signifies a sharp left turn. Slow down (to the recommended speed of 25 mph in this instance), maintain right when you turn, and do not pass.","imageName":"202002061047481721.jpg","correctAnswer":40869,"isBookmarked":0,"aid":"19639#40860#40868#40869","text":"a detour#an emergency stop#a crossover#a sharp turn"},
{"testId":46,"qid":32727,"description":"This symbol indicates that:","explanation":"A red slash inside a red circle denotes \"no.\" This regulation sign reads \"No Parking.\" Parking is not permitted at the sign.","imageName":"202004071538382619.jpg","correctAnswer":29815,"isBookmarked":0,"aid":"19280#19584#29815#33094","text":"U-turns are not allowed#the road ahead is closed permanently#parking is not allowed#parking is allowed only for people with disabilities"},
{"testId":40,"qid":32729,"description":"What is the meaning of this sign?","explanation":"This is a regulation sign directing you to continue straight or turn right.","imageName":"202004071533482418.jpg","correctAnswer":32509,"isBookmarked":0,"aid":"19283#30133#32509#32510","text":"You must make a right turn#You must go straight#You may go straight or turn right#You may go straight or turn left"},
{"testId":767,"qid":36831,"description":"In order to avoid a rollover when operating a combined vehicle, you should:","explanation":"Truck rollovers account for more than half of all truck driver fatalities in accidents. The higher the center of gravity of the vehicle, the more probable it is to flip over. If the cargo is pushed to one side, the trailer will slant, increasing the likelihood of a rollover. Keep your goods centered and as low as possible to assist avoid a rollover, and drive gently around corners.","imageName":"","correctAnswer":39163,"isBookmarked":0,"aid":"36128#36130#39163#39164","text":"keep the cargo near the center of the vehicle and drive slowly on the highway#keep the cargo high off the ground and drive slowly around turns#keep the cargo as low as possible and drive slowly around turns#keep the cargo as low as possible and drive slowly on the highway"},
{"testId":1031,"qid":37173,"description":"A resident must be at least ___ years old to get a license to operate a motor scooter or other motor-driven cycle.","explanation":null,"imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":1031,"qid":37177,"description":"For littering from your motorcycle on public roads, you may be fined up to ______.","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24977#24978","text":"$500#$1,000#$200#$100"},
{"testId":1031,"qid":37180,"description":"A motor-driven-cycle license in Alabama is a motorcycle license with the restriction code:","explanation":null,"imageName":"","correctAnswer":42748,"isBookmarked":0,"aid":"42747#42748#42749#42750","text":"\"A.\"#\"B.\"#\"D.\"#\"Y.\""},
{"testId":1031,"qid":37181,"description":"Which of the following penalties could be imposed on you if you are found guilty of operating a motorcycle without proper insurance?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18218#26643#42836","text":"All of the above#Jail#A fine#Impoundment of your motorcycle"},
{"testId":1031,"qid":37204,"description":"Which type of operator's licence do you need to ride a motorcycle?","explanation":null,"imageName":"","correctAnswer":42837,"isBookmarked":0,"aid":"322#324#42837#42838","text":"A#C#M#V"},
{"testId":1031,"qid":37206,"description":"____ points will be added to your driving record if you are found guilty of lane splitting with your motorcycle.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1031,"qid":37207,"description":"If you are found guilty of attempting to share a lane with another vehicle, you will receive ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1031,"qid":37208,"description":"Your motorcycle liability insurance policy must contain at least ______ in coverage for one person's physical injury or death in any one accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1031,"qid":37209,"description":"Your motorcycle liability insurance policy must have at least ______ in property damage coverage for every single collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1031,"qid":37210,"description":"Your motorcycle liability insurance policy must contain at least ______ in coverage for two or more person's physical injury or death in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12048#12194","text":"$50,000#$30,000#$25,000#$40,000"},
{"testId":1031,"qid":37211,"description":"If a motorcycle rider under the age of 18 violates any of the restrictions on motorcycle riding,:","explanation":null,"imageName":"","correctAnswer":42841,"isBookmarked":0,"aid":"42839#42840#42841#42842","text":"the motorcyclist will have to pay a fine#all restrictions will be extended by 3 months or until the motorcyclist turns 18#all restrictions will be extended by 6 months or until the motorcyclist turns 18#the motorcyclist's license will be suspended for 3 months or until he or she turns 18"},
{"testId":1031,"qid":37212,"description":"When may a rider under the age of 18 ride a motorbike between the hours of midnight and 6:00 a.m.?","explanation":null,"imageName":"","correctAnswer":11220,"isBookmarked":0,"aid":"11220#42843#42844#42845","text":"In any of the above situations#If he or she is accompanied by a responsible adult#If he or she is traveling to or from his or her place of employment#If he or she is traveling to or from a school-sponsored event"},
{"testId":1031,"qid":37213,"description":"A rider under the age of 18 may not ride a motorcycle while holding.","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#42793#42794#42795","text":"Any of the above#a cell phone#a pager#a tablet"},
{"testId":1031,"qid":37214,"description":"To earn an Alabama motorcycle license or endorsement, you must:","explanation":null,"imageName":"","correctAnswer":42848,"isBookmarked":0,"aid":"42846#42847#42848#42849","text":"(a) pass the official knowledge exam#(b) pass an approved Basic Rider Course#do either (a) or (b)#do both (a) and (b)"},
{"testId":1031,"qid":37216,"description":"How loud can a motorcycle's exhaust system may legally produce ?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1031,"qid":37217,"description":"If a motorcycle rider under the age of 18 is convicted of a four-point traffic offense, the rider's license will be revoked for ______ , or until the rider reaches the age of 18.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1031,"qid":37218,"description":"If a motorcycle rider under the age of 18 gets convicted of a traffic offense for the second time, the rider's license will be suspended for _______, or until the rider reaches the age of 18.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1031,"qid":37221,"description":"As part of the Alabama Motorcycle Safety Program's Basic Rider Course, you must bring :","explanation":"A motorbike and DOT-approved helmet will be given for riding practice as part of the Alabama Motorcycle Safety Program's Basic Rider Course (although you can bring your own DOT-approved helmet if you wish). Bring a long-sleeved shirt, strong slacks, full-fingered gloves, eye protection, boots or shoes with low heels that cover the ankles, and rain gear for rainy days.","imageName":"","correctAnswer":42850,"isBookmarked":0,"aid":"8615#42850#42851#42852","text":"All of the above#your own eye protection#your own DOT-approved helmet#your own motorcycle"},
{"testId":1031,"qid":37222,"description":"According to the Alabama Motorcycle Manual, ____ of motorcycle crashes involving substance addiction result in injury.","explanation":"In traffic accidents involving drug misuse, 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive incidents. Every year, 2,000 motorcyclists are killed and around 50,000 are critically wounded in this sort of incident. [Riding in Shape; Alabama Motorcycle Manual]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1032,"qid":37223,"description":"For much duration a motorcycle instruction permit is valid?","explanation":"The two-year non-commercial instruction permit for Alaska may only be renewed once. [State of Alaska Driver Manual, Non-Commercial Instruction Permit, Licenses and Permits]","imageName":"","correctAnswer":26651,"isBookmarked":0,"aid":"23254#23255#26651#31351","text":"Six months#Nine months#Two years#Four years"},
{"testId":1032,"qid":37224,"description":"Before applying for an Alaska motorcycle license, an underage motorcyclist must first get an Alaska motorcycle instruction permit and hold it for atleast:","explanation":"A rider under the age of 18 must possess an Alaska motorcycle instruction permit for at least six months before submitting an application for an Alaska motorcycle licence. State of Alaska Motorcycle Operator Manual, Preface","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23152#23153#26650","text":"9 months#3 months#6 months#One year"},
{"testId":1032,"qid":37225,"description":"To earn an Alaska Class M1 motorcycle license, you must be at least:","explanation":"You may drive bikes with any engine displacement if you have an Alaska Class M1 motorcycle licence (including over 50 cc). A Class M1 motorbike licence cannot be obtained unless the applicant is at least 16 years old. Additionally, you must pass the knowledge and road examinations for motorcycles or finish the Motorcycle Safety Foundation course. Additionally, you must have had an Alaska motorcycle instruction permit for at least six months if you are under the age of 18. State of Alaska Motorcycle Operator Manual, Preface","imageName":"","correctAnswer":34641,"isBookmarked":0,"aid":"34639#34641#36724#42542","text":"15 years old#16 years old#18 years old#17 years old"},
{"testId":1032,"qid":37226,"description":"While you have a motorcycle instruction permit in Alaska,:","explanation":"While you have an Alaska motorcycle instruction permit, you must ride under the supervision of a licensed motorcyclist who is at least 21 years old at all times. He or she must accompany you and be within sight range at all times. You must also wear a helmet, ride only during the day, and cannot transport people. [\"Motorcycle License,\" Alaska Division of Motor Vehicles, http://doa.alaska.gov/dmv/akol/motorcycle/motorcycle license.html] .htm]","imageName":"","correctAnswer":24643,"isBookmarked":0,"aid":"24643#40962#42543#42544","text":"all of the above are true#you may not carry passengers#your riding must be supervised by a licensed motorcyclist who is at least 21 years old#you may not ride at night"},
{"testId":1032,"qid":37227,"description":"While sitting on a motorcycle with handlebars that are higher than your shoulders, how many points will be added to your driving record if you are found guilty?","explanation":"It is unlawful in Alaska to ride a motorbike with handlebars that are higher than your shoulders when properly seated. (These handlebars are sometimes referred to as \"ape hangers.\") A breach of this statute is classified as a correctable equipment infraction, which carries a $75 fine but no points. (If you show the police evidence that you have fixed the offense, the citation will be dropped entirely.) [13 AAC 04], [Vehicle and Traffic Offenses Booklet, Alaska Court System, Anchorage AK, May 2019, http://www.courtrecords.alaska.gov/webdocs/forms/pub-131.pdf] .345]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#6388#6389#6390","text":"None of the above#2#3#4"},
{"testId":1032,"qid":37228,"description":"In Alaska, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for all persons involved in a single collision.","explanation":"In Alaska, your automobile liability insurance policy must provide at least $50,000 in bodily injury or death coverage for one person in any one accident, $100,000 in bodily injury or death coverage for all individuals in any one accident, and $25,000 in property damage coverage in any one accident. These regulations apply to both motorbikes and automobiles. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":12841,"isBookmarked":0,"aid":"3196#12048#12841#42424","text":"$50,000#$25,000#$100,000#$250,000"},
{"testId":1032,"qid":37229,"description":"If you ________________, you will immediately fail your motorcycle road test test.","explanation":"Several scenarios, such as breaking any traffic regulations, failing to comply with the test examiner, being involved in a traffic accident, and driving carelessly, constitute automatic failure of the road test. [\"Road Test,\" Alaska Division of Motor Vehicles, http://doa.alaska.gov/dmv/akol/roadtest], [\"Driving, Examinations, State of Alaska Driver Manual] .htm]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#42425#42426#42427","text":"do any of the above#violate any traffic laws#fail to cooperate with your test examiner#have a traffic accident"},
{"testId":1032,"qid":37236,"description":"If you are involved in an accident that results in death or injury, you must submit the DMV with proof of vehicle liability insurance within 30 days.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":23884,"isBookmarked":0,"aid":"23884#23886#35988#40739","text":"15 days#30 days#7 days#5 days"},
{"testId":1032,"qid":37237,"description":"If you are involved in an accident that causes death or injury and are uninsured, your license will be suspended for at least one year.","explanation":"If you were engaged in a traffic collision that resulted in more than $501 in death, injury, or property damage, you must provide evidence of vehicle liability insurance to the DMV within 15 days. If you do not, your license will be suspended for 90 days for the first offense and for one year for the second. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#27364","text":"30 days#60 days#90 days#45 days"},
{"testId":1032,"qid":37240,"description":"If you were in an accident, you must ________ unless the accident was investigated by a police officer.","explanation":null,"imageName":"","correctAnswer":42444,"isBookmarked":0,"aid":"20669#20671#20672#42444","text":"call local authorities for instructions#collect the other drivers' information#take photos of the damage#file a crash report with the DMV"},
{"testId":1032,"qid":37247,"description":"Except for_______, all of the following offences is worth 10 point penalty.","explanation":null,"imageName":"","correctAnswer":42545,"isBookmarked":0,"aid":"42545#42546#42547#42548","text":"carrying a passenger on a motorcycle that lacks handgrips and footrests for the passenger#carrying a passenger on a motorcycle that lacks a seat for the passenger#failing to keep both hands on the handlebars while riding a motorcycle#riding a motorcycle with both legs on the same side of the motorcycle"},
{"testId":1032,"qid":37248,"description":"If a licensed motorcycle rider accumulates ___ points in a year, he or she may be compelled to take a driving improvement course.","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6390#6916#11342","text":"5#4#6#9"},
{"testId":1032,"qid":37255,"description":"In Alaska, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for one person involved in a single collision.","explanation":"In Alaska, your automobile liability insurance policy must provide at least $50,000 in bodily injury or death coverage for one person in any one accident, $100,000 in bodily injury or death coverage for all individuals in any one accident, and $25,000 in property damage coverage in any one accident. These regulations apply to both motorbikes and automobiles. [Alaska Driver Manual, Financial Responsibility Laws]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12047#12048#12841","text":"$50,000#$15,000#$25,000#$100,000"},
{"testId":1032,"qid":37271,"description":"According to the Alaska Motorcycle Operator Manual, about ____ of motorcyclists killed in traffic accidents had consumed alcohol.","explanation":"According to the Alaska Motorcycle Operator Manual, over 40% of motorcyclists killed in traffic incidents had consumed alcohol. [Alcohol; Being Fit to Ride; Alaska Motorcycle Operator Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11667#11939#11940#12162","text":"50%#40%#20%#30%"},
{"testId":1033,"qid":37274,"description":"Which of the following is the categorization of a motorcycle license?","explanation":"To ride a motorbike or motorized cycle in Arizona, you must have a Class M motorcycle license or endorsement. [Class M, Arizona Driver License, Licensing Information for Arizona, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":42837,"isBookmarked":0,"aid":"322#325#11689#42837","text":"A#D#G#M"},
{"testId":1033,"qid":37275,"description":"For much duration a motorcycle instruction permit is valid?","explanation":null,"imageName":"","correctAnswer":43651,"isBookmarked":0,"aid":"20789#23153#25169#43651","text":"9 months#6 months#12 months#7 months"},
{"testId":1033,"qid":37276,"description":"You may NOT ________ while you have a motorcycle instruction permit.","explanation":"While you have a motorcycle instruction permit, you are not permitted to take passengers, ride on controlled-access roadways like as freeways or interstates, ride between sunset and dawn, and ride anytime visibility is poor at 500 feet. [Instruction Permits, Arizona Driver Licensing Information, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#39148#43454#43652","text":"do any of the above#carry passengers#ride between sunset and sunrise#ride on controlled-access highways"},
{"testId":1033,"qid":37277,"description":"What kind of Arizona driver's license can the MVD add a motorcycle endorsement to?","explanation":"Other than M (of course), the MVD provides motorcycle endorsements on any type of operator license: A, B, or C (commercial); D (noncommercial); or G (general) (noncommercial graduated). [License Types, Arizona Driver License, Licensing Information, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":43655,"isBookmarked":0,"aid":"43653#43654#43655#43656","text":"D only#D or G only#Any except M#C, D, or G"},
{"testId":1033,"qid":37278,"description":"____ points will be added to your driving record if you are found guilty of lane splitting with your motorcycle.","explanation":"The penalties for breaking Arizona's motorcycle riding rules are not quantified in terms of specific points. As a result, they get two points each under the category \"All other moving offences.\" [Arizona Driver License Manual, Suspension and Traffic School, Driver Improvement, Section 5: Actively Avoiding Crashes]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1033,"qid":37279,"description":"Before a person under the age of 18 can apply for a motorcycle license or endorsement, he or she must first obtain and maintain a motorcycle instruction permit and hold it for at least:","explanation":"Before a person under 18 years of age may apply for a motorcycle licence or motorcycle endorsement, he or she must get a motorcycle instruction permit and retain it for at least six months. [Class M, Arizona's Driver License, Information on Arizona Licensing, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1033,"qid":37280,"description":"Motorcycle accidents involving substance usage result in ____ injuries.","explanation":"In traffic accidents involving drug misuse, 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive incidents. Every year, 2,000 motorcyclists are killed and around 50,000 are critically wounded in this sort of incident. [The Importance of This Information, Getting in Shape to Ride, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1033,"qid":37281,"description":"A motorcycle is NOT needed________ in Arizona.","explanation":null,"imageName":"","correctAnswer":43657,"isBookmarked":0,"aid":"43657#43658#43659#43660","text":"turn signals#a headlight#a mirror#a horn"},
{"testId":1033,"qid":37282,"description":"In Arizona, your vehicle liability insurance policy must have at least ______ in property damage coverage for any single accident.","explanation":"In Arizona, your car liability insurance policy must contain at least $10,000 in property damage coverage for every one collision. This rule applies to all vehicles, including motorcyclists. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11704#12047","text":"$5,000#$10,000#$7,500#$15,000"},
{"testId":1033,"qid":37283,"description":"What is the greatest amount of noise that a modern motorcycle can make at a distance of 50 feet in Arizona?","explanation":"The maximum noise levels that a motorbike made after 1980 may create at a distance of 50 feet in Arizona are 76 dBA at 35 mph or less, 80 dBA at more than 35 mph but less than or equal to 45 mph, and 83 dBA at more than 45 mph. Older bikes are permitted to create more noise. [R17-4-510 located at 17 AAC 4]","imageName":"","correctAnswer":43662,"isBookmarked":0,"aid":"43661#43662#43663#43664","text":"80 dBA#83 dBA#86 dBA#88 dBA"},
{"testId":1033,"qid":37284,"description":"A learner biker under the age of 18 must either complete an MVD-approved motorcycle rider education program or log at least _______ hours of motorcycle riding experience before applying for a motorcycle license or endorsement.","explanation":"Before applying for a motorcycle licence or endorsement, a learner motorcyclist under 18 years of age must either complete an MVD-approved motorcycle rider education programme or record at least 30 hours of motorcycle riding experience. The driver's parent or legal guardian must attest in writing that the biker has obtained this much riding experience. Also, the trainee biker must possess his or her motorcycle teaching permit for at least six months. [Class M, Arizona's Driver License, Information on Arizona Licensing, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":11484,"isBookmarked":0,"aid":"11483#11484#11694#11695","text":"15 hours#30 hours#20 hours#40 hours"},
{"testId":1033,"qid":37286,"description":"Before you can apply for a Class M motorcycle license or endorsement, you must be at least ____ years old.","explanation":"Before applying for a Class M motorcycle license or endorsement, you must be at least 16 years old. You may get a motorbike endorsement on your Class G graduated driving license. [Class M, Arizona Driver License, Licensing Information for Arizona, Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1033,"qid":37287,"description":"In Arizona, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for two or more persons involved in a single collision.","explanation":"In Arizona, your automobile liability insurance policy must contain at least $30,000 in bodily injury coverage for two or more people in a single collision. This rule applies to all vehicles, including motorcyclists. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3196#3198#11659#12048","text":"$50,000#$30,000#$20,000#$25,000"},
{"testId":1033,"qid":37289,"description":"In Arizona, the bodily injury liability portion of your vehicle insurance policy must cover at least ________ for one person involved in a single collision.","explanation":"In Arizona, your auto liability insurance policy must provide at least $15,000 in bodily injury coverage for one individual in any one collision. This rule applies to all vehicles, including motorcyclists. [Arizona Driver License, Vehicle Insurance, Arizona Driver License Manual]","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11704#12047#12048","text":"$10,000#$7,500#$15,000#$25,000"},
{"testId":1033,"qid":37291,"description":"The MVD has authorized some third-party motorcycle training schools to:","explanation":null,"imageName":"","correctAnswer":43665,"isBookmarked":0,"aid":"10656#43572#43573#43665","text":"Do all of the above#give you a discount on vehicle liability insurance#remove traffic convictions from your driving record#let you waive the MVD motorcycle rider skill test"},
{"testId":1033,"qid":37294,"description":"It is unlawful in Arizona to keep an open container of an alcoholic beverage:","explanation":null,"imageName":"","correctAnswer":23137,"isBookmarked":0,"aid":"23136#23137#23138#43580","text":"on the driver's seat#anywhere in the passenger compartment#in the console#on the rear seat"},
{"testId":1033,"qid":37295,"description":"If your vehicle lacks a trunk, you may lawfully keep an open container of an alcoholic beverage :","explanation":null,"imageName":"","correctAnswer":43581,"isBookmarked":0,"aid":"23138#43581#43582#43583","text":"in the console#behind the last row of seats#under your seat#under the last row of seats"},
{"testId":1033,"qid":37298,"description":"The Arizona Motorcycle Operator Manual states that approximately ______ of motorcyclists killed in traffic accidents were legally inebriated at the time.","explanation":"According to the Arizona Motorcycle Operator Manual, 29 percent of motorcycle riders killed in traffic accidents had a BAC of 0.08 percent or above, indicating that they were legally inebriated at the time. An additional 8% had a lower BAC, indicating that they had also consumed alcohol. [The Importance of This Information; Getting in Shape to Ride; Arizona Motorcycle Operator Manual]","imageName":"","correctAnswer":12162,"isBookmarked":0,"aid":"11664#11939#11940#12162","text":"10%#40%#20%#30%"},
{"testId":1033,"qid":37305,"description":"If you are engaged in a traffic collision, which of the following information must you exchange with the other drivers involved?","explanation":"If you are engaged in a traffic collision, you must disclose the following information to any other parties involved: Your license number, contact information, the name and policy number of your insurance provider, and the license plate number of your car. If you know of any witnesses to the accident, you must submit their contact information as well. [Arizona Driver License Manual, Section 6: Handling Emergencies, Reporting Crashes]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23169#23170#43600","text":"All of the above#Contact information#Insurance company name and policy number#Driver license number"},
{"testId":1033,"qid":37307,"description":"If you are in a car accident, you are compelled by law to:","explanation":null,"imageName":"","correctAnswer":43604,"isBookmarked":0,"aid":"23175#34082#43604#43605","text":"call 911#call your insurer#identify yourself#call the MVD"},
{"testId":1033,"qid":37316,"description":"If convicted of DUI for the first time, you may face which of the following minimal penalties?","explanation":null,"imageName":"","correctAnswer":11767,"isBookmarked":0,"aid":"11766#11767#43613#43614","text":"10 days in jail#10 days in jail and a fine of $1,250#7 days in jail#7 days in jail and a fine of $500"},
{"testId":1033,"qid":37318,"description":"If convicted of extreme DUI, you will receive the minimum penalty listed below for a first offense.","explanation":null,"imageName":"","correctAnswer":11772,"isBookmarked":0,"aid":"11771#11772#11774#43615","text":"30 days in jail#30 days in jail and a fine of $2,500#15 days in jail and a fine of $1,250#A fine of $2,500"},
{"testId":1034,"qid":37323,"description":"You must be at least ___ years old to apply for a motorcycle instruction permit.","explanation":"An Arkansas citizen must be at least 14 years old and complete certain additional conditions in order to receive a motorcycle instruction permit. [Instruction Permit, Graduated Driver License Types, The Driver's License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5447#11361#11783#11784","text":"15#16#13#14"},
{"testId":1034,"qid":37324,"description":"Which of the following is required for a motorcycle instruction permit applicant under the age of 18?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43048#43049#43050","text":"All of the above#A consent form signed by his or her parent or legal guardian#School verification that the applicant is maintaining at least a \"C\" average (2.0 GPA)#School verification that the person is enrolled in school"},
{"testId":1034,"qid":37325,"description":"If you are convicted of DWI for the first time and have a passenger under the age of 16, you will be sentenced to jail for at least:","explanation":null,"imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23886#34746#35988#35989","text":"30 days#3 days#7 days#14 days"},
{"testId":1034,"qid":37326,"description":"A motor-driven cycle is defined as a vehicle with no more than three wheels and an engine:","explanation":null,"imageName":"","correctAnswer":43152,"isBookmarked":0,"aid":"38667#41795#43152#43153","text":"(a) or (b)#(a) and (b)#(a) displaces 250 cc or less#(b) produces 5 brake horsepower or less"},
{"testId":1034,"qid":37327,"description":" After ______, a person with a Class MD motor-driven cycle license may apply for a Class M motorcycle license.","explanation":null,"imageName":"","correctAnswer":43155,"isBookmarked":0,"aid":"38667#41795#43154#43155","text":"(a) or (b)#(a) and (b)#passes an on-cycle skills test on a motorcycle with more than 250 cc engine displacement#reaches the age of 16"},
{"testId":1034,"qid":37328,"description":"Which classification is the unrestricted Arkansas motorcycle license offered to motorcyclists beyond the age of 16?","explanation":"Class M is the unrestricted Arkansas motorcycle license awarded to motorcyclists above the age of 16. Class D is for automobile drivers. Motor-driven bikes are classified as Class MD, whereas motorized bicycles are classified as Class MB. [Arkansas Driver License Study Guide, Class M (Motorcycle) License, Graduated Drivers Licenses, The Drivers License]","imageName":"","correctAnswer":42837,"isBookmarked":0,"aid":"325#42837#43156#43157","text":"D#M#MB#MD"},
{"testId":1034,"qid":37329,"description":"Which of the following can result in the loss of your license?","explanation":"You might lose your license due to a multitude of reasons. These include lying on your license application, failing to tell the DFA of a reportable accident in which you were involved, and convictions for major criminal crimes such as DWI, fleeing the scene of an accident (hit and run), and using a motor vehicle to commit a felony. [The Drivers License, Arkansas Driver License Study Guide, Keeping the Drivers License]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#39787#43058#43059","text":"Any of the above#Leaving the scene of an accident (hit and run)#Supplying false information on your application for the license#A conviction for DWI"},
{"testId":1034,"qid":37330,"description":"Which of the following could lead to the suspension of your driver's license?","explanation":"You may lose your driving privileges if you are found guilty of permitting someone else to use your driver's license for any purpose. [The Drivers License, Arkansas Driver License Study Guide, Keeping the Drivers License]","imageName":"","correctAnswer":43060,"isBookmarked":0,"aid":"897#18625#26680#43060","text":"None of the above#Speeding#Running a red light#Allowing someone else to use your license"},
{"testId":1034,"qid":37331,"description":"According to the Arkansas Motorcycle Operator Manual, approximately ____ of motorcyclists killed in traffic accidents had alcohol in their systems.","explanation":"According to the Arkansas Motorcycle Operator Manual, over 40% of motorcycle riders killed in traffic accidents had consumed alcohol. [Alcohol; Riding Fitness; Arkansas Motorcycle Operator Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11667#11939#11940#12162","text":"50%#40%#20%#30%"},
{"testId":1034,"qid":37332,"description":"If convicted of DWI, you may face which implications?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14642#23188#43061","text":"All of the above#Jail time#Fines#Higher insurance rates"},
{"testId":1034,"qid":37333,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. It usually takes around an hour to break down the alcohol in one \"normal\" drink. (A typical drink is defined as 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer.) That rate cannot be increased by coffee, exercise, or a cold shower. You must wait until your body has processed all of the alcohol that you have ingested. [Body Metabolism, Driving Under the Influence of Alcohol, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":40345,"isBookmarked":0,"aid":"17525#18113#25081#40345","text":"Coffee#Exercise#A cold shower#The passage of time"},
{"testId":1034,"qid":37334,"description":"Which of the following are acceptable substitutes for drinking and driving?","explanation":"Taking a cab, utilising public transit, or travelling with a designated driver are alternatives to driving after drinking. How about a walk? The danger of an accident for a pedestrian really rises while they are drinking and walking. According to the National Highway Traffic Safety Administration (NHTSA), just 13 percent of the drivers involved in fatal traffic accidents had a blood alcohol content (BAC) of 0.08 percent or higher, but 37 percent of pedestrians did. [\"Traffic Safety Facts: Pedestrians\"; National Highway Traffic Safety Administration; August 2013; https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/811748]] and [\"Body Metabolism, Drinking and Driving, Arkansas Driver License Study Guide\"]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23211#23212#23213","text":"All of the above#Public transportation#A taxi#A designated driver"},
{"testId":1034,"qid":37335,"description":"If you are involved in a traffic collision and your blood alcohol concentration (BAC) is _____ or above, you may be charged with DWI.","explanation":"DWI is usually charged if your blood alcohol content (BAC) is 0.08 percent or above. However, if you are involved in a traffic accident or commit a significant traffic crime and your blood alcohol content (BAC) is 0.04 percent or more, you may be charged with DWI. [Alcohol and the Law, Driving Under the Influence, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":3281,"isBookmarked":0,"aid":"3281#4251#4253#4653","text":"0.04%#0.02%#0.03%#0.01%"},
{"testId":1034,"qid":37336,"description":"Under Arkansas law, a driver must _____ when asked by a police officer.","explanation":null,"imageName":"","correctAnswer":43062,"isBookmarked":0,"aid":"38667#41795#43062#43063","text":"(a) or (b)#(a) and (b)#(a) submit to a chemical test for alcohol or drugs#(b) pay a citation at the time of the traffic stop"},
{"testId":1034,"qid":37337,"description":"Who decides what type of blood alcohol concentration test the driver must take?","explanation":null,"imageName":"","correctAnswer":23218,"isBookmarked":0,"aid":"9808#23218#43064#43065","text":"The driver#The law enforcement officer#A local hospital#The driver's lawyer"},
{"testId":1034,"qid":37338,"description":"First-time DWI offenders may face which penalities?","explanation":null,"imageName":"","correctAnswer":23223,"isBookmarked":0,"aid":"23223#43066#43067#43068","text":"$1,000 fine, one year in jail, and license suspension for 120 days#$500 fine, one month in jail, and license suspension for 30 days#$500 fine, six months in jail, and license suspension for 90 days#$1,000 fine, 18 months in jail, and license suspension for 120 days"},
{"testId":1034,"qid":37339,"description":"A driver under the age of 21 in Arkansas can be arrested and prosecuted with Underage DUI if his or her blood alcohol content (BAC) is _____ or higher.","explanation":"In Arkansas, a motorist under the age of 21 who has a blood alcohol content (BAC) of at least 0.02 percent but less than 0.08 percent may be arrested and prosecuted with Underage DUI. (Most individuals will have a BAC of 0.02 percent or more after only one alcoholic drink.) DWI will be charged if the driver's BAC is 0.08 percent or above. [Alcohol and the Law, Driving Under the Influence, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"3281#4251#4253#4653","text":"0.04%#0.02%#0.03%#0.01%"},
{"testId":1034,"qid":37340,"description":"Which of the following punishments may be imposed on a motorist under the age of 21 who is convicted of Underage DUI?","explanation":null,"imageName":"","correctAnswer":43071,"isBookmarked":0,"aid":"43069#43070#43071#43072","text":"A $1,000 fine and 30 days in jail#A $1,000 fine and a one-year license suspension#A $2,000 fine and license revocation until he or she turns 21#A $1,000 fine and license suspension until he or she turns 21"},
{"testId":1034,"qid":37341,"description":"Drivers under the age of ___ who are convicted of purchasing or carrying alcohol will lose their license.","explanation":null,"imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":1034,"qid":37342,"description":"In the event of an accident, you are required to file a report with the DFA if the total estimated damage to any person's property exceeds:","explanation":"If the accident included injury or death, or damage to any person's property, you must submit an accident report with the DFA's Office of Driver Services within 30 days. [Traffic Accidents, Emergencies, and Arkansas Driver License Preparation]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24978#33102","text":"$500#$1,000#$100#$300"},
{"testId":1034,"qid":37343,"description":"If you are engaged in an accident in which someone is hurt, you must ________ right away.","explanation":"If you are engaged in a traffic collision in which someone is hurt or killed or property damage exceeds $1,000, you must call the police immediately. You must stay at the accident site until the police come and gather all necessary information. [Traffic Accidents, Emergencies, and Arkansas Driver License Preparation]","imageName":"","correctAnswer":1806,"isBookmarked":0,"aid":"1806#6618#43073#43074","text":"notify the police#Do all of the above#call your insurer#administer first aid"},
{"testId":1034,"qid":37344,"description":"If you are obligated to file an accident report for a collision, you must do so within ______ days after the collision.","explanation":"If you are obligated to file an accident report for a collision, you must do it within 30 days after the incident with the DFA's Office of Driver Services. [Record Crash Data, Traffic Crashes, Emergencies, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"739#740#742#1272","text":"10 days#15 days#30 days#6 months"},
{"testId":1034,"qid":37345,"description":"If you are required to file an accident report following a collision, you must do so.","explanation":"If you are obligated to file an accident report for a collision, you must do it within 30 days after the incident with the DFA's Office of Driver Services. [Record Crash Data, Traffic Crashes, Emergencies, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43077,"isBookmarked":0,"aid":"43075#43076#43077#43078","text":"the Arkansas State Police#the local police department#the Office of Driver Services#your insurer"},
{"testId":1034,"qid":37346,"description":"The law forbids you from carrying a child passenger under the age of eight on your motorcycle. Which of the following penalties will you face if you are found guilty of breaking this law?","explanation":null,"imageName":"","correctAnswer":43159,"isBookmarked":0,"aid":"43158#43159#43160#43161","text":"A fine of up to $25, a jail sentence of up to 14 days, or both#A fine of up to $50, a jail sentence of up to 30 days, or both#A fine of up to $100, a jail sentence of up to 30 days, or both#A fine of up to $200, a jail sentence of up to 90 days, or both"},
{"testId":1034,"qid":37347,"description":"If you fail to stop for a stopped school bus and hit and kill a kid pedestrian, you will be charged with:","explanation":null,"imageName":"","correctAnswer":43083,"isBookmarked":0,"aid":"36188#43081#43082#43083","text":"reckless driving#assault in the third degree#battery in the third degree#negligent homicide"},
{"testId":1034,"qid":37348,"description":"To receive an Arkansas motorcycle instruction permit, you must meet which of the following requirements?","explanation":"A motorcycle training permit will enable you to learn to ride a motorbike while being supervised by an adult. To qualify for an instruction permit, you must be at least 14 years old, pass a vision test to demonstrate that you can see well enough to operate a motorbike, pass a car driver's knowledge exam on road laws, and pass a motorcycle knowledge exam on the fundamentals of riding. [Instruction Permit, Graduated Driver License Types, The Driver's License, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43086,"isBookmarked":0,"aid":"22575#43085#43086#43162","text":"None of the above apply#You must attempt the required vision, knowledge, and skills tests#You must pass the required vision and knowledge tests#You must be old enough to be eligible for a motorcycle license"},
{"testId":1034,"qid":37349,"description":"If you have completed a Motorcycle Safety Foundation RiderCourse within the last______, you may be exempt from the on-cycle skills exam for a motorcycle license.","explanation":"You will earn a certificate of completion after successfully completing a Motorcycle Safety Foundation RiderCourse. If you submit the certificate to an Arkansas State Police examiner within the following 90 days, you may avoid taking the on-cycle skills test for a motorcycle license. [Types of Graduated Drivers Licenses, Arkansas Driver License Study Guide, Class M (Motorcycle) License]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1034,"qid":37350,"description":"According to the Arkansas Motorcycle Operator Manual, ____ of motorcycle crashes involving substance abuse result in injury.","explanation":"In traffic accidents involving substance misuse (alcohol or drugs), 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive crashes. Every year, 2,000 motorcyclists are killed and around 50,000 are critically wounded in this sort of incident. [Riding Fitness; Arkansas Motorcycle Operator Manual]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1034,"qid":37351,"description":"How loud can a motorcycle's exhaust be?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1034,"qid":37352,"description":"A motorcyclist who is at least 18 years old but under 21 years old and has a Class M motorcycle license:","explanation":null,"imageName":"","correctAnswer":43164,"isBookmarked":0,"aid":"43163#43164#43165#43166","text":"may send and receive text messages while operating a motorcycle#may not use a hand-held cell phone while operating a motorcycle#may not use a hands-free cell phone while operating a motorcycle#may not use a hand-held or hands-free cell phone while operating a motorcycle"},
{"testId":1034,"qid":37353,"description":"When a driver under the age of ___ operates a vehicle, the parent or legal guardian accepts all legal responsibility for the driver.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1034,"qid":37354,"description":"If found guilty of transporting a passenger on a motorcycle that is not designed to transport passengers, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1034,"qid":37355,"description":"Under Arkansas law, you can't smoke in your car if you're transporting someone younger than _____ years old.","explanation":"If you are carrying a passenger under the age of 14, Arkansas law prohibits you from smoking in your vehicle. [AR Code 20-27-1903] [Protection from Secondhand Smoke for Children Act, Arkansas Seat Belt Law, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43097,"isBookmarked":0,"aid":"36508#43096#43097#43098","text":"10#12#14#15"},
{"testId":1034,"qid":37356,"description":"Which of the following punishments could you face for a FIRST offense if you are found guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":43101,"isBookmarked":0,"aid":"43099#43100#43101#43102","text":"A $500 fine#A $500 fine and eight hours of community service#A $1,000 fine and eight hours of community service#A $2,000 fine and 24 hours of community service"},
{"testId":1034,"qid":37357,"description":"Which of the following punishments could you face for a SECOND offense if you are found guilty of littering from your vehicle?","explanation":null,"imageName":"","correctAnswer":43102,"isBookmarked":0,"aid":"43101#43102#43103#43104","text":"A $1,000 fine and eight hours of community service#A $2,000 fine and 24 hours of community service#A $1,000 fine and 48 hours in jail#A $2,000 fine and 48 hours in jail"},
{"testId":1034,"qid":37358,"description":"Your license could be suspended for _____, if you are found guilty of failing to yield at a yield sign.","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1034,"qid":37359,"description":"Under Arkansas' Move Over Law, you must move into the lane farthest from a stopped emergency vehicle, leaving at least one lane between you and it. If convicted, you'll face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":38667,"isBookmarked":0,"aid":"38667#41795#43105#43106","text":"(a) or (b)#(a) and (b)#(a) A fine#(b) A jail sentence"},
{"testId":1034,"qid":37360,"description":"Using someone else's disability parking certificate may result in fines of up to _____ for a first-time offender.","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#11349#11832#12395","text":"$500#$50#$100#$250"},
{"testId":1034,"qid":37361,"description":"If you are caught using another person's disability parking permit for the second time, you could face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12374#26643#43107","text":"All of the above#License suspension#A fine#Vehicle impoundment"},
{"testId":1034,"qid":37362,"description":"A handicapped parking certificate must be returned to the Arkansas DMV within _____ days if a motorist from the state has moved out of the state.","explanation":"The disability parking certificate must be returned to the Arkansas Department of Motor Vehicles within 30 days after leaving the state. [April 2007, Arkansas Driver License Study Guide] [AR Governor's Commission on People with Disabilities]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#8141#11784","text":"90#30#60#14"},
{"testId":1034,"qid":37363,"description":"If you are caught riding a motorcycle without eye protection, you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1034,"qid":37364,"description":"To pass the Arkansas vision exam with corrective lenses, an applicant for a permit or license must have a visual acuity of _____ or greater.","explanation":"You must have uncorrected visual acuity of 20/40 or better or corrected visual acuity of 20/70 or better to pass the vision test. If you need corrective lenses (eyeglasses or contact lenses) to pass the vision exam, your license will require you to use them every time you drive. [Driving Fitness, Vision, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43108,"isBookmarked":0,"aid":"14053#14054#43108#43109","text":"20/20#20/40#20/70#20/30"},
{"testId":1034,"qid":37365,"description":"A person who fails the vision test while applying for an instruction permit or license:","explanation":"If you fail the Arkansas vision test, you will be given a form to take to your preferred eye doctor. The eye doctor must undertake an examination and decide if your vision impairments can be addressed in order for you to satisfy Arkansas's vision criteria. [Driving Fitness, Vision, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":43110,"isBookmarked":0,"aid":"43110#43111#43112#43113","text":"will be required to visit an eye doctor#may attempt it again the following day#will be issued a restricted permit or license#will be allowed to drive only in the daytime"},
{"testId":1034,"qid":37366,"description":"What are some of the consequences of drinking alcohol?","explanation":"Drinking alcohol lengthens response times, reduces visual acuity, dulls judgement, and lowers attentiveness. These are all necessary abilities for driving. You may not be aware of how inebriated you are or how the alcohol is influencing your ability to drive since your judgement is clouded. [Arkansas Driver License Study Guide: Alcohol Impairment, Drinking and Driving]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43114#43115#43116","text":"All of the above#Slower reflexes#Decreased alertness#Impaired visual acuity"},
{"testId":1034,"qid":37367,"description":"After how many drinks does your body start to feel the effects of the alcohol?","explanation":"One alcoholic drink per hour may be broken down by your body. That rate cannot be increased by coffee, exercise, or a cold shower. [Arkansas Driver License Study Guide, Drinking and Driving]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1034,"qid":37368,"description":"Which of these can make it hard for you to ride a motorcycle?","explanation":"Many legal medicines may produce sleepiness, sedation, or poor coordination, making it difficult to ride a motorbike safely. Several prescription pain relievers, tranquilizers, and antidepressants are examples, as are some over-the-counter sleep aids, allergy drugs, and cold treatments. You may be charged with DWI if a police officer witnesses you driving recklessly and you acknowledge to the officer that you have taken such medications. If you are unclear about the side effects of a medicine, consult the warning label or your pharmacist or doctor. [Other Drugs and Driving, Driving While Drunk, Arkansas Driver License Study Guide]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23288#25099#43040","text":"All of the above#Over-the-counter cold medicines#Marijuana#Prescription tranquilizers"},
{"testId":1034,"qid":37369,"description":"Drivers who use _________ make more driving mistakes than other drivers, according to the Arkansas Driver License Study Guide.","explanation":null,"imageName":"","correctAnswer":11901,"isBookmarked":0,"aid":"11901#11902#11903#43117","text":"marijuana#corrective lenses#seat belts#cigarettes"},
{"testId":1034,"qid":37370,"description":"If you've had an epileptic seizure in Arkansas, you must be seizure-free for _______ before your driving privileges can be given or resumed.","explanation":"In Arkansas, if you have had an epileptic seizure, you must be under the supervision of a doctor for one year and be seizure free before your driving privileges may be restored or renewed. It should be noted that the regulations governing driving when epileptic differ from state to state. [Arkansas Driver License Study Guide, Epilepsy, Health, Drinking and Driving]","imageName":"","correctAnswer":230,"isBookmarked":0,"aid":"230#959#4374#42477","text":"one year#one month#six months#three years"},
{"testId":1034,"qid":37371,"description":"If you come across an accident site with emergency personnel on the scene, you should:","explanation":"You are only obligated to stop legally if you were involved in the collision. Otherwise, if emergency workers have already arrived, concentrate on your driving and continue on your way. Rubbernecking (slowing down, being distracted, or gazing) might result in another collision. If emergency services have not come and you are familiar with first aid, consider stopping, dialing 911 for emergency assistance, and providing aid. Your prompt action might save a life. [Traffic Accidents, Emergencies, and Arkansas Driver License Preparation]","imageName":"","correctAnswer":28716,"isBookmarked":0,"aid":"28716#43118#43119#43120","text":"proceed at your usual speed#stop to render aid#stop to direct traffic#slow down to take a better look"},
{"testId":1034,"qid":37372,"description":"Under Arkansas law, you must _____, if you hit a parked vehicle and can't find the driver or the owner.","explanation":null,"imageName":"","correctAnswer":41013,"isBookmarked":0,"aid":"34082#41013#43121#43122","text":"call your insurer#Leave a note identifying yourself#wait until the driver or owner returns#call 911 and proceed"},
{"testId":1035,"qid":37374,"description":"If a California driver's license has been suspended for ten months due to multiple DUI offenses, which of the following can allow that person to restore driving privileges?","explanation":"A motorist may be forced to install an ignition interlock device (IID) on every car he or she controls in order to be released from a 10-month driving license suspension after numerous DUI convictions. It will not start the car if the BAC reading from the driver's breath exceeds a predetermined limit. (If the driver is unable to put an IID on his or her motorbike, he or she must refrain from riding it throughout the installation time.) [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":24958,"isBookmarked":0,"aid":"24957#24958#24959","text":"A letter to the governor#Installation of an ignition interlock device (IID)#Completion of a driver safety course"},
{"testId":1035,"qid":37375,"description":"Is the DMV motorcycle skills test required to earn a Class M1 motorcycle license?","explanation":"If you successfully complete a California Motorcyclist Safety Program (CMSP) training course, you will be exempt from taking the DMV motorcycle skills exam for a Class M1 license. If you are under the age of 21, you must finish a CMSP course. You must be at least 21 years old to take the skills exam or finish a CMSP course. [Training Course for California Motorcyclist Safety Program, License Requirements, California Motorcycle Handbook]","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#42338#42339","text":"No#Only if you're at least 21 years of age#Only if you're under 21 years of age"},
{"testId":1035,"qid":37377,"description":"You are NOT permitted to operate_________, if you have a Class M2 license.","explanation":null,"imageName":"","correctAnswer":42340,"isBookmarked":0,"aid":"42340#42341#42342","text":"a motor-driven cycle (less than 150 cc engine displacement)#a moped#a motor scooter"},
{"testId":1035,"qid":37379,"description":"What is the maximum number of vehicle(s), a California driver with standard Class M1 motorcycle license is allowed to tow?","explanation":"A normal Class M1 motorbike license in California permits you to tow just one car. Use a motorcycle trailer to reduce the amount of weight on the back of your motorbike. [DL 648, California Department of Motor Vehicles, \"Recreational Vehicles and Trailers Handbook\"]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6469","text":"2#3#1"},
{"testId":1035,"qid":37380,"description":"Your vehicle liability insurance policy must provide at least _______ in coverage for the bodily injury or death of two or more people in the event of an accident in California.","explanation":"In California, your car liability insurance policy must provide at least $30,000 in bodily injury or death coverage for two or more people in any one collision. This regulation applies to both bikes and automobiles. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11659#12048","text":"$30,000#$20,000#$25,000"},
{"testId":1035,"qid":37382,"description":"Your vehicle liability insurance policy must provide at least _______ in coverage for the bodily injury or death of a single person in the event of an accident in California.","explanation":"In California, your car liability insurance policy must provide at least $15,000 in bodily injury or death coverage for one individual in any one collision. This regulation applies to both bikes and automobiles. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11659#12047","text":"$10,000#$20,000#$15,000"},
{"testId":1035,"qid":37383,"description":"While you have a motorcycle instruction permit in California,:","explanation":"You may not take passengers, travel on a highway, or ride at night while holding a Class M1 instruction permit. [Motorcycle M1 or M2 License Requirements, License Requirements, California Motorcycle Handbook]","imageName":"","correctAnswer":35668,"isBookmarked":0,"aid":"35668#42343#42344","text":"both of the above are true#you may not ride a motorcycle on a freeway#you may not ride a motorcycle at night"},
{"testId":1035,"qid":37386,"description":"If you are found guilty of operating a motorcycle with a suspended license due to an excessive number of points on your record, you will serve at least ______ in prison.","explanation":null,"imageName":"","correctAnswer":12685,"isBookmarked":0,"aid":"742#12685#18239","text":"30 days#5 days#14 days"},
{"testId":1035,"qid":37387,"description":"If you give your disability placard or license plate to a non-disabled driver, you may face which penalty?","explanation":null,"imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#26643#35315","text":"Both of the above#A fine#A jail sentence"},
{"testId":1035,"qid":37389,"description":"If found guilty of transporting a passenger on a motorcycle that is not designed to transport passengers, you might face a fine of up to:","explanation":"To take a passenger on your motorbike in California, it must have footrests and sitting space for the passenger. The basic punishment for breaching this legislation is $25 (but the total amount you'll have to pay may be more than $100 once different fees and surcharges are included). [Carrying Passengers and Cargo, Ride Within Your Limitations, California Motorcycle Handbook], [\"Uniform Bail and Penalty Schedules,\" Judicial Council of California, 2017]","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#41338","text":"$25#$50#$70"},
{"testId":1035,"qid":37390,"description":"If you are found guilty of breaching any of the conditions on your California motorcycle instruction permit, you will be fined.","explanation":"You may not take passengers, travel on a highway, or ride at night while holding a Class M1 instruction permit. The initial fee for breaching any of these limitations is $25 (but the total amount you'll have to pay may be more than $100 once other levies and surcharges are included). [\"Uniform Bail and Penalty Schedules,\" Judicial Council of California, 2017], [\"Requirements for a Motorcycle M1 or M2 License, License Requirements, California Motorcycle Handbook].","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24993#24994#41338","text":"$25#$50#$70"},
{"testId":1035,"qid":37392,"description":"You are not permitted to ride a motorcycle made after 1985 that makes more than _____ of noise in California.","explanation":null,"imageName":"","correctAnswer":42345,"isBookmarked":0,"aid":"42345#42346#42347","text":"80 dbA#83 dbA#86 dbA"},
{"testId":1035,"qid":37394,"description":"If you are involved in an accident while uninsured, your license will be suspended for at least :","explanation":"If you are involved in an accident while uninsured, your license will be suspended for up to four years. However, after one year, your license may be restored provided you receive and submit an SR-22 Proof of Financial Responsibility certificate to the DMV. (Note: Because SR-22 insurance is considered high-risk insurance, your insurance premiums will be more than normal.) [Collisions Are Not Accidents, California Driver Handbook, Handling Emergencies]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#23183","text":"2 years#1 year#3 years"},
{"testId":1035,"qid":37395,"description":"If you accumulate ___ points in a 12-month period, you will lose your driver's license under California's Negligent Operator Treatment System (NOTS).","explanation":"You will lose your driving license if you get 4 points in 12 months, 6 points in 24 months, or 8 points in 36 months under California's Negligent Operator Treatment System (NOTS). At-fault crashes and most common traffic offenses are considered one-point violations. DUI, reckless driving, fleeing the scene of an accident (hit and run), and driving when your license is suspended or revoked are all two-point offences. [Administrative Driver Record Points, California Driver Handbook], [\"What is a 'Point? \", California Department of Motor Vehicles, https://www.dmv.ca.gov/portal/dmv/detail/teenweb/more btn6/points/]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916","text":"5#4#6"},
{"testId":1035,"qid":37397,"description":"On your first violation, you might be fined up to _____ for operating a motorcycle that exceeds the California's聽noise limit.","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994","text":"$100#$25#$50"},
{"testId":1035,"qid":37398,"description":"Littering is punishable by a _______ fine in California. You might also be asked to _________.","explanation":"If you are convicted of littering, you will be obliged to pay a fine of $1,000. You may also be expected to clean up trash. Things Not to Do, Additional Driving Laws/Rules, California Driver Handbook], [CA PEN 374] .4]","imageName":"","correctAnswer":42306,"isBookmarked":0,"aid":"24996#42305#42306","text":"$50; spend 3 days in jail#$500; pick up litter#$1,000; pick up litter"},
{"testId":1035,"qid":37399,"description":"The California Compulsory Financial Responsibility Law says that every person who drives a car must have:","explanation":"All California vehicle operators are required to maintain financial responsibility (liability coverage) at all times under the Compulsory Financial Responsibility Law. Most vehicle operators prefer to obtain a vehicle insurance coverage to satisfy this need. However, there are three alternative options for being financially responsible: Deposit $35,000 with the DMV, secure a $35,000 surety bond, or receive a self-insurance certificate from the DMV. This legislation applies to both motorbikes and automobiles. [Administrative, Financial Responsibility, California Driver Handbook]","imageName":"","correctAnswer":25001,"isBookmarked":0,"aid":"25001#25002#42307","text":"proof of financial responsibility#a job#a vehicle insurance policy"},
{"testId":1035,"qid":37400,"description":"Under California's Compulsory Financial Responsibility Law, which of the following can be used instead of liability insurance?","explanation":"If you do not want to have your car insured, the Compulsory Financial Responsibility Law permits you to deposit $35,000 with the DMV, secure a $35,000 surety bond from a California-licensed corporation, or receive a self-insurance certificate from the DMV. This legislation applies to both motorbikes and automobiles. [Administrative, Financial Responsibility, California Driver Handbook]","imageName":"","correctAnswer":9350,"isBookmarked":0,"aid":"9350#25004#25005","text":"Both of the above#A deposit of $35,000 with the DMV#A surety bond of $35,000 with a California-licensed company"},
{"testId":1035,"qid":37401,"description":"What is the minimum amount of coverage for property damage that a California motorcycle insurance policy must have?","explanation":"Each vehicle operated in California must be insured by a car insurance policy that includes $5,000 in property damage coverage, according to California's Compulsory Financial Responsibility Law. This legislation applies to both motorbikes and automobiles. [Administrative Insurance Requirements, California Driver Handbook]","imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"3195#11658#12047","text":"$5,000#$10,000#$15,000"},
{"testId":1035,"qid":37402,"description":"If you are involved in an accident that causes more than $1,000 in property damage, you must report it.","explanation":"If you are involved in an accident that causes death, injury, or property damage of more than $1,000, you must submit an accident report with the DMV within 10 days. If you do not comply, your license will be suspended. [Collisions Are Not Accidents, California Driver Handbook], [Collisions on Your Record, Administrative, California Driver Handbook]","imageName":"","correctAnswer":42348,"isBookmarked":0,"aid":"25008#34082#42348","text":"swap vehicles with the other driver#call your insurer#report the accident to the DMV"},
{"testId":1035,"qid":37403,"description":"If you are involved in an accident while uninsured, your license will be suspended for up to :","explanation":"If you are involved in an accident while uninsured, your license will be suspended for up to four years. However, after one year, your license may be restored provided you receive and submit an SR-22 Proof of Financial Responsibility certificate to the DMV. (Note: Because SR-22 insurance is considered high-risk insurance, your insurance premiums will be more than normal.) [Collisions Are Not Accidents, California Driver Handbook, Handling Emergencies]","imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23183","text":"2 years#4 years#3 years"},
{"testId":1035,"qid":37404,"description":"The state of California's drunk driving law is also a _______ driving law.","explanation":"The drunk driving statute in California also applies to drugged driving. [Driving Under the Influence of Alcohol or Drugs is Dangerous, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":11976,"isBookmarked":0,"aid":"11975#11976#42309","text":"morning#drugged#reckless"},
{"testId":1035,"qid":37405,"description":"In California, it is against the law to ride a motorcycle while your driving abilities are impaired by:","explanation":"It is prohibited to take any medication that affects your ability to drive safely. It makes no difference if the medicine is prescribed, over-the-counter, or illicit. If you are unsure if a medicine may impair your driving, see your doctor or pharmacist or read the warning label. [Carrying Alcohol in a Vehicle, Driving While Under the Influence of Alcohol, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":42311,"isBookmarked":0,"aid":"8833#42310#42311","text":"Prescription drugs#over-the-counter drugs#any of the above"},
{"testId":1035,"qid":37406,"description":"You are at least 21 years old and have just acquired a motorcycle instruction permit. Before obtaining a Class M1 or Class M2 license, Which of the following is true?","explanation":"Before becoming eligible for a motorcycle license in California, every prospective biker, regardless of age, must get a motorcycle instruction permit. However, if you are at least 21 years old, you are not needed to keep the permission for any certain period of time. As soon as you are able, you should enroll in a California Motorcyclist Safety Program (CMSP) training course or take the DMV motorcycle skills exam. [Training Course for California Motorcyclist Safety Program, License Requirements, California Motorcycle Handbook]","imageName":"","correctAnswer":42351,"isBookmarked":0,"aid":"42349#42350#42351","text":"You must hold the permit for at least 3 months#You must hold the permit for at least 6 months#Neither of the above is true"},
{"testId":1035,"qid":37407,"description":"After consuming __ alcoholic drink, a 140-pound woman will get legally inebriated (s). After consuming __ alcoholic drint, a 180-pound male will get legally inebriated.","explanation":"An average 140-pound lady will get legally inebriated after ingesting 2 alcoholic drinks. An average 180-pound male will get legally inebriated after ingesting 3 alcoholic drinks. (1 alcoholic drink = 1.5 ounces of 80-proof whiskey, 12 ounces of 5 percent beer, or 5 ounces of 12 percent wine.) [Get a DUI - Lose Your License, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":25018,"isBookmarked":0,"aid":"13745#25018#25019","text":"1; 2#2; 3#3; 4"},
{"testId":1035,"qid":37408,"description":"If you have two drinks in an hour, at the end of that hour, :","explanation":"The quicker you drink, the more alcohol accumulates in your body. If you ingest two beverages in an hour, at least one of them will stay in your bloodstream at the end of that hour. [Blood Alcohol Concentration, Getting in Shape to Ride, California Motorcycle Handbook]","imageName":"","correctAnswer":45548,"isBookmarked":0,"aid":"45548#45549#45550","text":"at least one drink will remain in your bloodstream#you will be completely sober and safe to ride#the breath test will come back negative for BAC"},
{"testId":1035,"qid":37410,"description":"In California, Who can be cited and fined if you are transporting a sixteen-year-old passenger who is not wearing a helmet?","explanation":null,"imageName":"","correctAnswer":40929,"isBookmarked":0,"aid":"9642#34753#40929","text":"You#The passenger#Both you and the passenger"},
{"testId":1035,"qid":37417,"description":"First-time DUI convictions result in license suspension for _____聽for adult drivers.","explanation":"A California court will suspend an adult driver's license for at least six months for his or her first DUI conviction. [Convictions for DUI in Court, Alcohol and Drugs, California Driver Handbook]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886","text":"6 months#1 year#30 days"},
{"testId":1036,"qid":37425,"description":"You must be at least ___ years old to receive a Colorado motorcycle instruction permit.","explanation":"You must be at least 15 years old to receive a Colorado motorcycle instruction permit. Before you can get a motorbike endorsement on your driver's license, you must have it for a year and be at least 16 years old. [1. Colorado Motorcycle Operator's Handbook, Motorcycle Operator Licensing]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":1036,"qid":37426,"description":"The supervising adult for a trainee motorcyclist with a motorcycle instruction permit in Colorado must:","explanation":"A supervising adult must be at least 21 years old and have a motorcycle endorsement on his or her Colorado driver's license for a learner motorcyclist with a Colorado motorcycle instruction permit. Furthermore, the supervising adult must be a Motorcycle Operator Skills Training (MOST) teacher, a parent or guardian who signed the Affidavit of Liability, or another adult authorized by the parent or guardian. When a novice motorcyclist is driving a motorbike, he or she must be under the immediate, proximate supervision of a supervising adult. [1. Colorado Motorcycle Operator's Handbook, Motorcycle Operator Licensing]","imageName":"","correctAnswer":25049,"isBookmarked":0,"aid":"25049#26962#45749#45750","text":"comply with all of the above#be at least 21 years old#hold a Colorado motorcycle endorsement#keep the motorcyclist under his or her immediate supervision"},
{"testId":1036,"qid":37427,"description":"Who may supervise a beginner motorcyclist under 18 with a motorcycle instruction permit?","explanation":"A supervising adult must be at least 21 years old and have a motorcycle endorsement on his or her Colorado driver's license for a learner motorcyclist with a Colorado motorcycle instruction permit. Furthermore, the supervising adult must be a Motorcycle Operator Skills Training (MOST) teacher, a parent or guardian who signed the Affidavit of Liability, or another adult authorized by the parent or guardian. When a novice motorcyclist is driving a motorbike, he or she must be under the immediate, proximate supervision of a supervising adult. [1. Colorado Motorcycle Operator's Handbook, Motorcycle Operator Licensing]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#45751#45752#45753","text":"All of the above#Parent or guardian who signed the Affidavit of Liability#MOST instructor#Adult to whom the parent or guardian has given permission"},
{"testId":1036,"qid":37428,"description":"To acquire a motorcycle endorsement on your Colorado driver's license, you must either _____ or finish a MOST course.","explanation":"There are two ways to earn a Colorado motorcycle endorsement: (1) Pass a vision exam, a motorcycle written test, a motorcycle instruction permit, and a motorcycle skills test; or (2) complete a Motorcycle Operator Skills Training (MOST) course, either the Basic RiderCourse or the Advanced RiderCourse. If you successfully finish the course, you will be eligible to earn a motorcycle endorsement without first passing the motorcycle written exam or the motorcycle skills test. To earn a motorbike endorsement if you are between the ages of 15 and 16, you must complete a MOST course. [1. Colorado Motorcycle Operator's Handbook, Motorcycle Operator Licensing]","imageName":"","correctAnswer":6618,"isBookmarked":0,"aid":"6618#45754#45755#45756","text":"Do all of the above#pass a motorcycle written test#obtain a motorcycle instruction permit#pass a motorcycle skills test"},
{"testId":1036,"qid":37429,"description":"To earn a motorcycle endorsement on your Colorado driver's license if you are under ___ years of age, you must first pass a MOST course.","explanation":"To acquire a motorcycle endorsement on your Colorado driver's license, you must complete a Motorcycle Operator Skills Training (MOST) course if you are between the ages of 15 and 16. [1. Colorado Motorcycle Operator's Handbook, Motorcycle Operator Licensing]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1036,"qid":37442,"description":"Is it mandatory for car owners to have liability insurance on their vehicles?","explanation":"You must carry evidence of insurance with you at all times and maintain liability insurance coverage on any vehicle you possess. This regulation applies to both motorbikes and vehicles and trucks. This card or document must contain the name of the insurance company, contact information, policy number, and information about the vehicle covered by the policy. These regulations apply to both motorbikes and vehicles and trucks. [The Colorado Driver Handbook, Understanding Colorado's Motor Vehicle Laws, and the Motor Vehicle Insurance Data Base Law]","imageName":"","correctAnswer":21234,"isBookmarked":0,"aid":"18118#21234#45757#45758","text":"No#Yes#Yes, but only for cars and trucks, not motorcycles#Yes, but only for cars, trucks, and motorcycles with at least 400 cc displacement"},
{"testId":1036,"qid":37443,"description":"What is the minimum property damage coverage necessary for Colorado liability insurance policies?","explanation":"A Colorado driver must maintain a liability insurance policy on his or her vehicle that includes property damage coverage of at least $15,000. The policy must also include coverage of at least $25,000 for a single bodily injury or death and $50,000 for multiple bodily injuries or deaths, and these requirements apply to both cars and motorcycles.","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1036,"qid":37446,"description":"When riding a motorcycle in Colorado without a windshield, which of the following people is required by law to wear eye protection?","explanation":"Unless the motorbike has a windshield, both the operator and any passengers are obliged by Colorado law to wear eye protection while riding a motorcycle. [C.R.S. 42-4-232]","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1036,"qid":37470,"description":"Which of the following individuals is required by law to wear a helmet when riding a motorcycle in Colorado?","explanation":"Operators and passengers under the age of 18 are obliged by Colorado law to wear a helmet while riding a motorbike. [C.R.S. 42-4-1502]","imageName":"","correctAnswer":45761,"isBookmarked":0,"aid":"45761#45762#45763#45764","text":"Operators under 18 and passengers under 18#Passengers under 18 and all operators#Operators under 18 and all passengers#All operators and all passengers"},
{"testId":1037,"qid":37474,"description":"Motorcycle accidents involving substance usage result in ____ injuries.","explanation":"In traffic accidents involving drug misuse, 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive incidents. Every year, 2,000 motorcyclists are killed and around 50,000 are critically wounded in this sort of incident. [The Importance of This Information, Getting in Shape to Ride, Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1037,"qid":37475,"description":"A parent or legal guardian _______ if the motorcycle driving license or learner's permit applicant is under the age of 18.","explanation":"A motorbike learner's permit is not required in Connecticut. A person under the age of 18 must present a Certificate of Parental Consent (Form 2D) prepared and signed by his or her parent or guardian when applying for a motorbike learner's permit. The same is true if a person under the age of 18 applies for a motorbike endorsement without first obtaining a motorcycle learner's permit. [Getting Your Connecticut Endorsement, Connecticut Motorcycle Operator Manual, Motorcycle Learner's Permit]","imageName":"","correctAnswer":43970,"isBookmarked":0,"aid":"43969#43970#43971#43972","text":"must teach the applicant how to ride a motorcycle#must sign a consent form#must purchase a motorcycle for the applicant to ride#must pay the applicant's application fee"},
{"testId":1037,"qid":37476,"description":"Which of the following restrictions apply to your motorcycle riding while you have a motorcycle learner's permit?","explanation":"A motorbike learner's permit is not required in Connecticut. If you receive one, you must obey the following motorcycle riding restrictions: (1) You must always wear a helmet while riding. (2) You are not permitted to travel on a limited-access roadway. (3) You are not permitted to bike at night. (4) You are not permitted to transport people. (5) You are not permitted to ride out of state. [Getting Your Connecticut Endorsement, Connecticut Motorcycle Operator Manual, Motorcycle Learner's Permit]","imageName":"","correctAnswer":33508,"isBookmarked":0,"aid":"32742#32744#33508#35331","text":"You may not carry passengers#You may not ride at night#All of the above restrictions apply#You may not ride on a limited-access highway"},
{"testId":1037,"qid":37477,"description":"Except for employment, school, religious activities, or emergencies, a licensed driver under the age of 18 may not drive ________","explanation":"A licensed biker under the age of 18 may not operate a motorbike after 11:00 p.m. and 5:00 a.m. unless while at work, school, religious activity, or in an emergency The same curfew is in effect for licensed drivers under the age of 18. [Hour Restrictions (Curfew), 16- and 17-Year-Old Driver Restrictions, Driver Education Requirements, 1. Obtaining a Connecticut Driver's License, Connecticut Driver's Manual], [Motorcycling and Connecticut Law, Obtaining Your Connecticut Endorsement, Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":41826,"isBookmarked":0,"aid":"36064#41826#41827#43054","text":"between midnight and 6:00 a.m#between 11:00 p.m. and 5:00 a.m#between 11:00 p.m. and 6:00 a.m#between 10:00 p.m. and 3:00 a.m"},
{"testId":1037,"qid":37479,"description":"A motorcyclist who is at least 18 years old may not take passengers for _______ after earning a motorcycle endorsement.","explanation":"A biker above the age of 18 may not transport any passengers for the first 90 days after receiving a motorbike endorsement. [Motorcycling and Connecticut Law, Obtaining Your Connecticut License, Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1037,"qid":37480,"description":"A motorcyclist who is under 18 years old may not take passengers for _______ after earning a motorcycle endorsement.","explanation":"For the first six months after acquiring a motorcycle endorsement, a biker under the age of 18 may not transport any passengers. [Motorcycling and Connecticut Law, Obtaining Your Connecticut License, Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":23254,"isBookmarked":0,"aid":"23253#23254#26650#27897","text":"Three months#Six months#One year#one month"},
{"testId":1037,"qid":37483,"description":"If you fail to keep your vehicle insured,:","explanation":null,"imageName":"","correctAnswer":27717,"isBookmarked":0,"aid":"27714#27715#27717#43894","text":"you will have to pay an insurance compliance fine#it will be considered a violation#all of the above will happen#your insurer will inform the DMV"},
{"testId":1037,"qid":37484,"description":"Which of the following is NOT required to get a motorcycle endorsement on your driver's license?","explanation":null,"imageName":"","correctAnswer":43975,"isBookmarked":0,"aid":"43973#43974#43975#43976","text":"Pass a DMV vision test#Pass a DMV motorcycle knowledge test#Pass a DMV on-cycle skills test#Complete a DMV-approved novice motorcycle safety course"},
{"testId":1037,"qid":37485,"description":"If a biker under the age of 18 is found guilty of riding a motorcycle without a helmet, the rider will face a fine of at least:","explanation":null,"imageName":"","correctAnswer":43978,"isBookmarked":0,"aid":"33510#43977#43978#43979","text":"$30#$60#$90#$120"},
{"testId":1037,"qid":37494,"description":"In Connecticut, your motorcycle liability insurance policy must cover damage to property in an accident by at least:","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11659#12047#12048","text":"$5,000#$20,000#$15,000#$25,000"},
{"testId":1037,"qid":37495,"description":"According to the Connecticut Motorcycle Operator Manual, approximately ____ of motorcyclists killed in traffic accidents were intoxicated.","explanation":"According to the Connecticut Motorcycle Operator Manual, over 40% of motorcycle riders killed in collisions had consumed alcohol. [The Importance of This Information; Getting in Shape to Ride; Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11664#11939#11940#12162","text":"10%#40%#20%#30%"},
{"testId":1037,"qid":37499,"description":"Which of the following punishments may be imposed on a driver under the age of 21 who is found to be driving under the influence of alcohol or drugs?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35315#43903","text":"All of the above#A fine#A jail sentence#Required installation of an ignition interlock device (IID)"},
{"testId":1037,"qid":37500,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.02 percent or higher, he or she must install and maintain an ignition interlock device (IID) for at least:","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"25031#25032#25169#27027","text":"18 months#24 months#12 months#36 months"},
{"testId":1037,"qid":37505,"description":"If you were in an accident that resulted in ________, you must notify the police.","explanation":"If you were involved in an accident that resulted in injuries, fatalities, or property damage, you must notify the police. [Report the Accident, Traffic Collisions, Vehicle Emergencies, Connecticut Driver's Manual]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#12171#43911#43912","text":"Any of the above#property damage#fatalities#injuries"},
{"testId":1037,"qid":37509,"description":"For how much duration a Connecticut motorcycle intruction permit is valid?","explanation":"A Connecticut motorbike learner's permit is valid for 60 days and may only be renewed once per calendar year.","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"24048#24049#25857#25858","text":"60 days#90 days#120 days#180 days"},
{"testId":1037,"qid":37510,"description":"To apply for a motorcycle learner's permit, you must be at least ___ years old.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":1037,"qid":37511,"description":"How loud can a contemporary motorbike be from a distance of 50 feet in the state of Connecticut?","explanation":null,"imageName":"","correctAnswer":43981,"isBookmarked":0,"aid":"43980#43981#43982#43983","text":"86 dB#84 dB#80 dB#78 dB"},
{"testId":1037,"qid":37514,"description":"What is the categorization of a Connecticut motorcycle endorsement?","explanation":"A Connecticut Class D driver's license allows you to operate any motor vehicle except a commercial motor vehicle. Classes A, B, and C are commercial driver license (CDL) classes. After meeting certain requirements, a driver who holds any of these classes of licenses may obtain a \"M\" motorcycle endorsement on it.","imageName":"","correctAnswer":42837,"isBookmarked":0,"aid":"322#324#325#42837","text":"A#C#D#M"},
{"testId":1037,"qid":37515,"description":"A driver with a Connecticut class D license is not permitted to drive:","explanation":"A Connecticut Class D driver's license allows you to operate any motor vehicle except a commercial motor vehicle. Classes A, B, and C are commercial driver license (CDL) classes. After meeting certain requirements, a driver who holds any of these classes of licenses may obtain a \"M\" motorcycle endorsement on it.","imageName":"","correctAnswer":43933,"isBookmarked":0,"aid":"9855#43933#43934#43935","text":"Any of the above#a commercial vehicle#a vehicle with a manual transmission#a pickup truck"},
{"testId":1037,"qid":37516,"description":"In Connecticut, your motorcycle liability insurance policy must offer at least ______ in bodily injury coverage for one person in any single collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1037,"qid":37517,"description":"In Connecticut, your motorcycle liability insurance policy must cover all people hurt in an accident with at least ______ in medical costs.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12194#12195","text":"$50,000#$30,000#$40,000#$35,000"},
{"testId":1037,"qid":37520,"description":"If a person under the age of 18 desires to apply for a motorcycle endorsement without first obtaining a motorcycle learner's permit, he or she must:","explanation":"A motorbike learner's permit is not required in Connecticut. A person under the age of 18 must present a Certificate of Parental Consent (Form 2D) prepared and signed by his or her parent or guardian when applying for a motorbike learner's permit. The same is true if a person under the age of 18 applies for a motorbike endorsement without first obtaining a motorcycle learner's permit. [How to Get a Connecticut Endorsement, Connecticut Motorcycle Operator Manual]","imageName":"","correctAnswer":43941,"isBookmarked":0,"aid":"10656#43941#43942#43943","text":"Do all of the above#obtain consent from a parent or legal guardian#have a medical examination#maintain at least a \"C\" average in secondary school"},
{"testId":1037,"qid":37521,"description":"Your motorcycle's handlebars _____ in Connecticut.","explanation":null,"imageName":"","correctAnswer":41466,"isBookmarked":0,"aid":"41464#41465#41466#43984","text":"must not be higher than 15 inches above the seat#must not be higher than 30 inches above the seat#must not be higher than your shoulders#may be of any height"},
{"testId":1038,"qid":37523,"description":"A person under the age of 18 does not have to _____聽to be able to take a state-approved Motorcycle Rider Education Course.","explanation":"Before you may attend a Motorcycle Rider Education Course, you must first finish a certified driver education course, get a full Class D driver's license, and receive permission from your parent or guardian. Then, present your course completion card to the DMV, pass an eye-screening test, and pay the required cost, and you will be awarded a motorcycle endorsement. [\"Motorcycle Rider Course Information,\" Delaware Division of Motor Vehicles, https://services.dmv.de.gov/services/driver services/driving courses/dr crs motorcycle], [\"Motorcycle Rider Course Information,\" Delaware Division of Motor Vehicles, https://services.dmv.de.gov/services/driver services/driving courses/dr crs motorcycle .shtml]","imageName":"","correctAnswer":43447,"isBookmarked":0,"aid":"43447#43448#43449#43450","text":"pass an eye-screening examination#hold a full Class D driver's license#obtain permission from his or her parent or guardian#complete a certified driver education course"},
{"testId":1038,"qid":37531,"description":"If you are caught riding a motorcycle without eye protection, you will receive ___ points on your license.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1038,"qid":37532,"description":"You can apply for a temporary motorbike learner's permit if you are at least _____ years old.","explanation":"You must pass an eye-screening test and pay the required cost to acquire a motorbike endorsement on your driver's license. Additional criteria apply depending on your age. If you are under the age of 18, you must acquire parental or guardian consent and finish a state-approved Motorcycle Rider Education Course. If you are above the age of 18, you have two choices: (1) Pass a motorcycle rider education course certified by the state. (2) Pass a road sign exam as well as a motorcycle knowledge test in order to get a temporary motorcycle learner's permit. Then, using your learner's permit, practice riding until you are ready to take the on-cycle skill exam for a motorcycle endorsement. [Delaware Motorcycle Operator Manual, How to Obtain a Motorcycle Endorsement on a Delaware Driver License]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":1038,"qid":37533,"description":"____ points will be added to your driving record if you transport a passenger on a motorcycle not equipped to do so.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1038,"qid":37534,"description":"In Delaware, your vehicle liability insurance policy must offer at least ______ in bodily injury or death coverage for one individual in any one collision.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1038,"qid":37535,"description":"You will be exempt from which of the following if you successfully complete the state-approved Motorcycle Rider Education Course?","explanation":"You will be exempt from the DMV motorcycle knowledge exam, the DMV motorcycle learner's permit, and the DMV on-cycle competence test if you successfully complete the state-approved Motorcycle Rider Education Course. Simply take the course's Student Completion Card to the DMV, pass an eye-screening test, and pay the required cost, and you will be awarded a motorcycle endorsement. Successful completion of the Motorcycle Rider Education Course is required for candidates under the age of 18 and optional for applicants above the age of 18. [Delaware Motorcycle Operator Manual, How to Obtain a Motorcycle Endorsement on a Delaware Driver License]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#43451#43452#43453","text":"All of the above#The DMV motorcycle knowledge test#The DMV on-cycle skill test#The DMV motorcycle learner's permit"},
{"testId":1038,"qid":37536,"description":"While an adult motorcyclist has a temporary motorcycle learner's licence, he or she is may not _______.","explanation":"While holding a temporary motorcycle learner's permit, a motorcyclist must wear a helmet and eye protection at all times while riding. Furthermore, the trainee motorcyclist is not permitted to transport people, travel between the hours of dusk and morning, or ride on interstate routes. After holding the permission for at least 10 days, the rider may take the on-cycle competency test for a motorcycle endorsement, keeping in mind that the permit expires in six months. [Delaware Motorcycle Operator Manual, How to Obtain a Motorcycle Endorsement on a Delaware Driver License]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#39147#39148#43454","text":"do any of the above#ride on interstate highways#carry passengers#ride between sunset and sunrise"},
{"testId":1038,"qid":37537,"description":"If you are found operating a motorbike while carrying a package that restricts you from having both hands on the handlebars, you will be assessed __ points toward the total number of points you have on your license.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1038,"qid":37544,"description":"If convicted of DUI for the first time, you may face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#35315#43405","text":"All of the above#A fine#A jail sentence#Mandatory license revocation"},
{"testId":1039,"qid":37604,"description":"If you are involved in an accident, you are required by law to:","explanation":"If you are in a collision, you must stop, assist wounded motorists, submit insurance information, and stay at the site until first responders come. [Accidents, Driver Information, Driving Manual of the District of Columbia]","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#26683#26684#26685","text":"Do all of the above#stop to provide aid#provide insurance information#remain at the scene until first responders arrive"},
{"testId":1040,"qid":37624,"description":"Which of the following is the non-commercial driver license class?","explanation":"The Class E driving license is intended for non-commercial vehicle operators. The letter \"O\" will appear in the endorsement area on the front of the Class E license for a \"Motorcycle Only\" license, and the phrase \"O-MTRCL Only\" will appear in the endorsement field on the reverse. The letter \"A\" will show in the endorsement area on the front of the Class E license for a motorcycle endorsement, and the words \"A-MTRCL Also\" will appear in the endorsement field on the rear. Commercial driver licenses are classified into three classes: A, B, and C. (CDLs). [Florida Driver License Classification, 10. Obtaining Your License or ID Card, Official Florida Driver's Handbook] [\"Florida's NEW Driver License and ID Card,\" Florida Department of Highway Safety and Motor Vehicles, https://www.flhsmv.gov/driver-licenses-id-cards/newdl/]","imageName":"","correctAnswer":12378,"isBookmarked":0,"aid":"322#323#324#12378","text":"A#B#C#E"},
{"testId":1040,"qid":37628,"description":"If you are engaged in an accident that causes bodily harm or property damage and do not have motorcycle insurance,:","explanation":null,"imageName":"","correctAnswer":39396,"isBookmarked":0,"aid":"39393#39396#41874#41875","text":"your license may be suspended#all of the above may occur#your motorcycle's registration may be suspended#you may have a civil judgment rendered against you in court"},
{"testId":1040,"qid":37629,"description":"According to Florida's No-Fault Law, every motorcycle registered in the state must carry which of the following types of insurance?","explanation":null,"imageName":"","correctAnswer":41877,"isBookmarked":0,"aid":"37191#41792#41876#41877","text":"Both (a) and (b)#(a) Personal Injury Protection#(b) Property Damage Liability#Neither (a) nor (b)"},
{"testId":1040,"qid":37632,"description":"Motorcyclists who ride with headlight off in the daytime will have___points being aded to their driving license.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":37633,"description":"How many points will be added to your driving record if you are caught riding a motorcycle without wearing eye protection?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#6389#6390#6916","text":"None of the above#3#4#6"},
{"testId":1040,"qid":37635,"description":"When do you need Bodily Injury Liability (BIL) insurance?","explanation":null,"imageName":"","correctAnswer":41800,"isBookmarked":0,"aid":"41797#41798#41799#41800","text":"If you were convicted of DUI#If your license was suspended for excessive points#If you caused an accident resulting in injury#If any of the above occurred"},
{"testId":1040,"qid":37637,"description":"Which of the following is NOT true about the Florida Rider Training Program (FRTP) Basic Rider Course?","explanation":null,"imageName":"","correctAnswer":41878,"isBookmarked":0,"aid":"41878#41879#41880#41881","text":"You must be at least 16 years old#You must hold an automobile learner's license or a Class E driver license#You must be able to ride a bicycle#You must wear protective motorcycle clothing and gear"},
{"testId":1040,"qid":37639,"description":"While sitting on a motorcycle with handlebars that are higher than your shoulders, how many points will be added to your driving record if you are found guilty?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#6389#6390#6916","text":"None of the above#3#4#6"},
{"testId":1040,"qid":37640,"description":"Before you can get a motorcycle license or endorsement, you must be at least ___ years old.","explanation":"A Class E driving license may only be obtained if you are at least 16 years old. The letter \"O\" will appear in the endorsement area on the front of the Class E license for a \"Motorcycle Only\" license, and the phrase \"O-MTRCL Only\" will appear in the endorsement field on the reverse. The letter \"A\" will show in the endorsement area on the front of the Class E license for a motorcycle endorsement, and the words \"A-MTRCL Also\" will appear in the endorsement field on the rear. [\"Florida's NEW Driver License and ID Card,\" Florida Department of Highway Safety and Motor Vehicles, https://www.flhsmv.gov/driver-licenses-id-cards/newdl/] [\"Florida's NEW Driver License and ID Card,\" Florida Department of Highway Safety and Motor Vehicles, https://www.flhsmv.gov/driver-licenses-id-cards/newdl/]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11360#11361","text":"15#18#21#16"},
{"testId":1040,"qid":37641,"description":"A learner motorcyclist under the age of 18 must possess an automotive learner's license for at least _______ or until the age of 18, whichever comes first, before being given a \"Motorcycle Only\" license.","explanation":"A learner motorcycle rider under the age of 18 must possess an automotive learner's licence for at least a year or until they reach 18, whichever comes first, before they may be granted a \"Motorcycle Only\" licence. [Official Florida Driver's Handbook, Requirements for Class E Driver License, 10. Getting Your License or ID Card]","imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"1272#1273#1453#11301","text":"6 months#12 months#3 months#18 months"},
{"testId":1040,"qid":37642,"description":"____ points will be added to your driving record if you transport a passenger on a motorcycle not equipped to do so.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":37643,"description":"In Florida, a street-legal motorbike must have at least which of the following?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#6063#24749#31918","text":"All of the above#Front and rear brakes#Headlight, taillight, brake light, and turn signals#Horn and one mirror"},
{"testId":1040,"qid":37646,"description":"Which of the following offenses will not result in the suspension of your license in Florida?","explanation":null,"imageName":"","correctAnswer":41816,"isBookmarked":0,"aid":"27198#41814#41816#41882","text":"Failure to pay child support#Use of tobacco if you're under 18#Talking on a hand-held cell phone while driving#Dropping out of school if you're under 18"},
{"testId":1040,"qid":37655,"description":"Motorcyclists who ride with both the legs on the same side of the motorcycle will have___points being aded to their driving license.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":37666,"description":"A motorcyclist over the age of 21 is not required to wear a helmet if he or she has at least ______ in insurance coverage for injuries sustained in an accident.","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11659#12047","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":1040,"qid":37667,"description":"________ points will be added to your driving record if you are found guilty of riding your motorcycle in the middle of two lanes of traffic.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":37672,"description":"If convicted of DUI for the third time, you will have your license revoked for at least:","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23154#25858#26672#31873","text":"1 year#180 days#5 years#200 days"},
{"testId":1041,"qid":37673,"description":"What is the categorization of a Georgia motorcycle instructional permit?","explanation":null,"imageName":"","correctAnswer":43307,"isBookmarked":0,"aid":"42837#43305#43306#43307","text":"M#CP#CLP#MP"},
{"testId":1041,"qid":37674,"description":"What is the classification of a Georgia motorcycle license?","explanation":null,"imageName":"","correctAnswer":42837,"isBookmarked":0,"aid":"322#324#325#42837","text":"A#C#D#M"},
{"testId":1041,"qid":37675,"description":"For how much duration a Georgia motorcycle license is valid?","explanation":"Classes C and M driver's licences in Georgia are all valid for eight years. [Georgia Drivers Manual, Classes of Licenses, Section 1: General Licensing Information]","imageName":"","correctAnswer":43222,"isBookmarked":0,"aid":"26652#27049#31351#43222","text":"Five years#Ten years#Four years#Eight years"},
{"testId":1041,"qid":37676,"description":"If you pass the Basic Rider Course offered by the Georgia Motorcycle Safety Program (GMSP), you are entitled to waive:","explanation":null,"imageName":"","correctAnswer":39539,"isBookmarked":0,"aid":"38489#39539#43308#43309","text":"neither (a) nor (b)#both (a) and (b)#(a) the DDS motorcycle knowledge exam#(b) the DDS on-cycle skills test"},
{"testId":1041,"qid":37677,"description":"Before applying for a Class MP motorcycle instructional permit, you must be at least ___ years old.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1041,"qid":37678,"description":"A motorcycle license or motorcycle instructional permit applicant under the age of 18 must have:","explanation":"A parent, legal guardian, or other responsible adult must provide written consent before a minor may apply for a Georgia motorcycle licence or motorcycle instructional permit. Current Georgia Residents, Section 2: Obtaining a License, Permit, or Identification Card, Georgia Drivers Manual, Class C Instructional Permit (CP)","imageName":"","correctAnswer":27883,"isBookmarked":0,"aid":"8615#27883#43226#43310","text":"All of the above#written consent from a parent or legal guardian#his or her own liability insurance#a motorcycle with less than 250 cc displacement"},
{"testId":1041,"qid":37679,"description":"For how much duration a Georgia motorcycle instructional permit is valid?","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#25031#25032#25169","text":"6 months#18 months#24 months#12 months"},
{"testId":1041,"qid":37680,"description":"Before a 16 year old applicant becomes elligible for a motorcycle instructional permit, he or she must:","explanation":null,"imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#43311#43312#43313","text":"Do all of the above#pass a vision exam#pass a motorcycle knowledge exam#complete a driver education course"},
{"testId":1041,"qid":37681,"description":"Which of the following restrictions does NOT apply to your riding when you have a Class MP motorcycle instructional permit?","explanation":null,"imageName":"","correctAnswer":35124,"isBookmarked":0,"aid":"32742#32744#35124#40133","text":"You may not carry passengers#You may not ride at night#You may not carry cargo#You may not ride on limited-access highways"},
{"testId":1041,"qid":37682,"description":"____ points will be added to your driving record if you transport a passenger on a motorcycle not equipped to do so.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":1041,"qid":37683,"description":"In Georgia, how loud can your motorcycle's exhaust system get without breaking the law?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1041,"qid":37684,"description":"According to the National Highway Traffic Safety Administration (NHTSA), alcohol impaired ____ of motorcyclists who died in single-vehicle crashes in 2015.","explanation":null,"imageName":"","correctAnswer":43316,"isBookmarked":0,"aid":"43314#43315#43316#43317","text":"22%#32%#42%#52%"},
{"testId":1041,"qid":37690,"description":"An applicant under the age of ___ must present documentation of enrollment in or graduation from high school or the equivalent in order to acquire a motorcycle instructional permit or motorcycle license (GED or homeschooling).","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1041,"qid":37702,"description":"A driver under the age of 21 who is convicted of DUI for the second time in ten years time will have his or her license suspended for a period of at least :","explanation":null,"imageName":"","correctAnswer":25031,"isBookmarked":0,"aid":"25031#25032#25169#27027","text":"18 months#24 months#12 months#36 months"},
{"testId":1041,"qid":37703,"description":"After 120 days, a driver under the age of 21 whose license has been suspended for a second DUI conviction will be eligible to drive________","explanation":null,"imageName":"","correctAnswer":43241,"isBookmarked":0,"aid":"43240#43241#43242#43243","text":"under supervision by a licensed driver#with an ignition interlock device#a vehicle that weighs less than 2,000 pounds#with a special certificate from the DDS"},
{"testId":1041,"qid":37705,"description":"Motorcyclists who ride with their handgrips above 25 inches higher than seat will have___points being aded to their driving license.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1041,"qid":37706,"description":"Which of the following is FALSE in order for you to be eligible to take the Georgia Motorcycle Safety Program (GMSP) Basic Rider Course?","explanation":null,"imageName":"","correctAnswer":43318,"isBookmarked":0,"aid":"41880#43318#43319#43320","text":"You must be able to ride a bicycle#You must be at least 17 years old#You must already know the rules of the road#You must wear protective motorcycle clothing to the course"},
{"testId":1041,"qid":37707,"description":"____ points will be added to your driving record if you are found guilty of lane splitting with your motorcycle.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1041,"qid":37708,"description":"If you are found guilty of riding without eye protection on a motorcycle without a windshield in Georgia, you may face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35315#43234#43235","text":"All of the above#A jail sentence#Points added to your driving record#A large fine"},
{"testId":1041,"qid":37709,"description":"If you are caught riding a motorcycle without a helmet, you will receive _____ points on your license.","explanation":null,"imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1042,"qid":37753,"description":"In Hawaii, children between the ages of four and ______ must be strapped in the back seat of a vehicle.","explanation":"All children between the ages of four and seventeen must be strapped in the back seat of a vehicle in the state of Hawaii. [Official Hawaii Driver's Manual, Safety Belts, Vehicle Condition and Maintenance, Chapter 2 Vehicle Equipment and Inspection]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"5446#6478#11374#11784","text":"10#12#17#14"},
{"testId":1042,"qid":37756,"description":"A road accident that causes ______ or more in property damage must be reported to the police as soon as possible.","explanation":"Any accident that causes $3,001 or more in property damage, including death, must be reported to the police right away. Chapter 7 of the Official Hawaii Driver's Manual addresses accident procedures.","imageName":"","correctAnswer":12001,"isBookmarked":0,"aid":"290#1799#11832#12001","text":"$500#$1,000#$100#$3,000"},
{"testId":1043,"qid":37783,"description":null,"explanation":null,"imageName":"","correctAnswer":45768,"isBookmarked":0,"aid":"45765#45766#45767#45768","text":"under 21 years of age#21鈥30 years of age#31鈥40 years of age#over 40 years of age"},
{"testId":1043,"qid":37812,"description":"For much days an motorcycle instruction permit is valid?","explanation":"An Idaho instruction permit allows a motorcycle operator to practise riding for 180 days. This permit may be renewed once without requiring the permit holder to retake and pass the motorcycle knowledge test, provided the previous knowledge test was taken within the past 12 months. [Motorcycle Instruction Permit, Earning Your Motorcycle Endorsement, Idaho Motorcycle Operator's Manual]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"6355#6477#6802#12168","text":"90#30#180#360"},
{"testId":1043,"qid":37813,"description":"You must be at least ___ years old to obtain an Idaho motorcycle instruction permit.","explanation":"You must hold a driver's license and pass a motorcycle knowledge exam to receive a motorcycle teaching permit. To receive a Class D license in Idaho, you must be at least 15 years old. [Idaho Motorcycle Operator's Manual, Motorcycle Instruction Permit, Earning Your Motorcycle Endorsement]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1043,"qid":37814,"description":"In Idaho, which of the following individuals is required by law to wear a helmet when riding a motorcycle?","explanation":null,"imageName":"","correctAnswer":45761,"isBookmarked":0,"aid":"45761#45762#45763#45764","text":"Operators under 18 and passengers under 18#Passengers under 18 and all operators#Operators under 18 and all passengers#All operators and all passengers"},
{"testId":1043,"qid":37815,"description":"Which of the following may you be exempt from if you complete an Idaho STAR motorcycle training course?","explanation":"If you finish a Skills Training Advantage for Riders (STAR) motorcycle rider training course and get your motorcycle endorsement within two years of taking the course, the DMV will remove the necessity for a riding skills exam. However, before the DMV can award you a motorcycle endorsement, you must pass the DMV motorcycle knowledge exam. [\"Idaho STAR: Skills Training Advantage for Riders,\" https://idahostar.org/], [\"Motorcycle Rider Training Course, Earning Your Motorcycle Endorsement, Idaho Motorcycle Operator's Manual]","imageName":"","correctAnswer":45770,"isBookmarked":0,"aid":"37206#37207#45769#45770","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The DMV motorcycle knowledge test#(b) The DMV riding skills test"},
{"testId":1043,"qid":37816,"description":"A person under the age of 21 is not required to聽_____ to have a motorcycle endorsement in Idaho.","explanation":"Before obtaining a motorcycle endorsement on an Idaho driver's license, everyone, regardless of age, must pass a DMV motorcycle knowledge exam. Instead of completing the DMV riding skills exam, Idaho law requires anybody under the age of 21 to complete an approved state motorcycle safety program, such as an Idaho STAR rider course.","imageName":"","correctAnswer":45772,"isBookmarked":0,"aid":"45771#45772#45773#45774","text":"pass the DMV motorcycle knowledge test#pass the DMV riding skills test#pass an approved Idaho motorcycle safety course#hold a valid Idaho driver's license"},
{"testId":1043,"qid":37817,"description":"In Idaho, which of the following individuals is required by law to wear eye protection when riding a motorcycle without a windshield?","explanation":"When riding a motorbike in Idaho, neither the operator nor a passenger is required to wear eye protection, regardless of whether the motorcycle has a windshield.","imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1043,"qid":37818,"description":"You may ride a motorcycle if you have an Idaho motorcycle instruction permit:","explanation":null,"imageName":"","correctAnswer":45776,"isBookmarked":0,"aid":"6350#38792#45775#45776","text":"at night#On a freeway#with a passenger aboard#in the rain"},
{"testId":1043,"qid":37819,"description":"If an Idaho motorcycle instruction permit holder fails to follow the permit's requirements, :","explanation":null,"imageName":"","correctAnswer":45779,"isBookmarked":0,"aid":"45777#45778#45779#45780","text":"(a) the permit will be canceled#(b) he or she will have to reapply for a new permit#both (a) and (b) will take place#neither (a) nor (b) will take place"},
{"testId":1043,"qid":37820,"description":"Motorcyclists are wounded in approximately _____ of motorcycle crashes involving substance usage.","explanation":"In traffic accidents involving drug misuse, over 90% of motorcycle collisions result in injuries, compared to 33% of automotive crashes. Every year, 2,000 motorcyclists are killed and around 50,000 are critically wounded in this sort of incident. [The Importance of This Information, Getting in Shape to Ride, Idaho Motorcycle Operator's Manual]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1043,"qid":37821,"description":"According to the Idaho Motorcycle Operator's Manual, _____ of all motorcycle crash fatalities involved alcohol.","explanation":"According to the Idaho Motorcycle Operator's Manual, alcohol was consumed by 40 to 45 percent of the motorcyclists died in motorcycle accidents. One-third of these motorcyclists had a blood alcohol content (BAC) that was higher than the legal limit. The remainder had smaller amounts of alcohol in their systems, but it's possible that this was enough to damage their riding abilities. [The Importance of This Information, Getting in Shape to Ride, Idaho Motorcycle Operator's Manual]","imageName":"","correctAnswer":43812,"isBookmarked":0,"aid":"43810#43811#43812#45136","text":"10鈥15%#20鈥25%#40鈥45%#50鈥55%"},
{"testId":1044,"qid":37823,"description":"If you have never ridden a motorcycle with a displacement of at least 150cc, you may apply for an Illinois motorcycle instruction permit if you are at least ___ years old.","explanation":"You must be at least 18 years old to apply for an Illinois instruction permit to ride a motorbike with a displacement of at least 150cc. You may apply for an Illinois instruction permit to operate a motor-driven bike if you are 16 or 17 years old (i.e., with under 150cc displacement). You may apply for a motorcycle instruction permit if you are 16 or 17 years old and have completed an authorized driving education course as well as an IDOT Motorcycle Rider Education Course.","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":1044,"qid":37824,"description":"For how much duration is a motorcycle instruction permit valid for an applicant whose age is 16 or 17?","explanation":"An Illinois motorcycle or motor-driven cycle instruction permit is valid for two years if the applicant is age 16 or 17. Persons age 18 or older are issued a 12-month motorcycle instruction permit after they have met all the requirements for it.","imageName":"","correctAnswer":1274,"isBookmarked":0,"aid":"1272#1273#1274#11301","text":"6 months#12 months#24 months#18 months"},
{"testId":1044,"qid":37825,"description":"Which of the following Driver Services examinations may be waived if you are 16 or 17 years old and successfully complete an IDOT Motorcycle Rider Education Course?","explanation":"It is optional to take an IDOT Motorcycle Rider Education Course if you are at least 18 years old. If you successfully finish a Basic Rider Course or a Basic Rider Course 2, you will not be needed to pass the motorcycle written exam or the rider skills test. You must finish an IDOT Motorcycle Rider Education Course and pass both the motorcycle written exam and the rider skills test if you are 16 or 17 years old.","imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#45788#45789","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The motorcycle written test#(b) The rider skills test"},
{"testId":1044,"qid":37826,"description":"If you're 18 and complete an IDOT Motorcycle Rider Education Course, you can skip which Driver Services test?","explanation":"It is optional to take an IDOT Motorcycle Rider Education Course if you are at least 18 years old. If you successfully finish a Basic Rider Course or a Basic Rider Course 2, you will not be needed to pass the motorcycle written exam or the rider skills test. You must finish an IDOT Motorcycle Rider Education Course and pass both the motorcycle written exam and the rider skills test if you are 16 or 17 years old.","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#45788#45789","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The motorcycle written test#(b) The rider skills test"},
{"testId":1044,"qid":37827,"description":"While you possess a motorcycle instruction permit, you must ride under the supervision of a person who satisfies which of the following criteria?","explanation":"While you have a motorcycle instruction permit, you must ride with someone who is 21 or older, has a valid motorcycle license, and has at least one year of motorcycle driving experience.","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#29242#29243#29244","text":"All of the above#21 years of age or older#Licensed to drive the vehicle#At least one year of driving experience"},
{"testId":1044,"qid":37828,"description":"If you have an Illinois Class L license, you can ride any motorcycle with a displacement of less than :","explanation":"If you have an Illinois Class L license, you may ride any motor-driven cycle, which Illinois defines as a motor scooter or motorbike with a displacement of less than 150cc. To lawfully ride a motorbike with a displacement of 150cc or larger, you must have a Class M license. [625 ILCS 5/1-145 .001]","imageName":"","correctAnswer":45790,"isBookmarked":0,"aid":"45790#45791#45792#45793","text":"150cc#180cc#200cc#250cc"},
{"testId":1044,"qid":37829,"description":"Which of the following individuals must by law wear eye protection when operating a motorbike without a windshield in Illinois?","explanation":"Unless the motorbike is fitted with a windshield, both the operator and any passengers must wear eye protection while riding a motorcycle in Illinois. [625 ILCS 5/11-1404]","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1044,"qid":37830,"description":"You may operate a motorcycle if you have an Illinois motorcycle instruction permit under which of the following conditions?","explanation":"An Illinois motorcycle instruction permit allows driving only during daylight hours, under the direct supervision of a licenced motorcycle operator age 21 or older with at least one year of driving experience. [Instruction Permits, Overview, Illinois Motorcycle Operator Manual]","imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#41317#45794#45795","text":"Both (a) and (b)#Either (a) or (b)#(a) Only during daylight hours#(b) Only under the direct supervision of a licensed adult motorcyclist"},
{"testId":1044,"qid":37832,"description":"To take an IDOT Motorcycle Basic Rider Course, you must have all of the following EXCEPT :","explanation":"To enroll in an IDOT Motorcycle Basic Rider Course or Basic Rider Course 2, you must first complete the following steps: (1) possess a valid driver's license or instruction permit; (2) wear appropriate clothes, including protective eyewear, full-hand gloves, and sturdy, over-the-ankle boots; and (3) produce a completed IDOT Waiver or Release of Liability. (If you are under the age of 18, you must bring your parent or guardian to the first class to sign the waiver.) As part of the training, you will be given a helmet and a motorbike. You may, however, bring your own DOT-approved helmet if you choose. [Illinois Department of Transportation, \"Training Courses,\" http://www.idot.illinois.gov/transportation-system/safety/roadway/ssm/training-courses]","imageName":"","correctAnswer":38715,"isBookmarked":0,"aid":"10843#38715#45796#45797","text":"protective clothing#a helmet#a valid driver's license or instruction permit#a Waiver or Release of Liability"},
{"testId":1044,"qid":37835,"description":"A driver under the age of 21 who is sentenced to court supervision for an alcohol-related offense will have his or her license suspended for:","explanation":"A motorist under the age of 21 will have his or her license suspended for three months if he or she is put under court supervision for an alcohol-related violation.","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#25170#27363","text":"3 months#6 months#1 month#2 months"},
{"testId":1044,"qid":37837,"description":"Which of the following individuals is required to wear a helmet when riding a motorcycle in Illinois?","explanation":"The state of Illinois no longer requires motorcycle riders to wear helmets. The state Supreme Court ruled in 1969 that the state's helmet legislation was unconstitutional, and no new helmet laws have been adopted since.","imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1044,"qid":37838,"description":"Illinois requires every vehicle driven inside the state to be insured by an insurance policy that contains at least _______ of property damage coverage.","explanation":"Every vehicle operated in Illinois must be insured by an insurance policy that contains at least $20,000 in property damage coverage.","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12047","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":1044,"qid":37839,"description":"Every vehicle driven in Illinois must be covered by an insurance policy that provides at least _______ of coverage for the injury or death of more than one person in the event of a crash.","explanation":"Every vehicle operated in Illinois must be covered by an insurance policy that contains at least $50,000 in coverage for multiple severe injuries or fatalities in the event of an accident.","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12048#12195","text":"$50,000#$30,000#$25,000#$35,000"},
{"testId":1044,"qid":37840,"description":"Which of the following may occur if you do not have the required automobile insurance coverage in Illinois?","explanation":"If you do not have the appropriate insurance coverage, you will be fined and your operator's license and vehicle registration will be stopped until you present evidence of insurance.","imageName":"","correctAnswer":29265,"isBookmarked":0,"aid":"29263#29264#29265#45798","text":"Your driver's license will be suspended#Your vehicle's registration will be suspended#All of the above will take place#You will have to pay a fine"},
{"testId":1044,"qid":37849,"description":"When you are arrested for DUI, the officer will take your driver's license and give you a temporary receipt that will allow you to continue driving for _______ until the suspension period begins.","explanation":"When you are detained for a DUI, the police will take your licence and provide you a temporary receipt that will let you drive for 45 days before the suspension period starts. On the 46th day, the suspension period will start. This will allow you some extra time to plan your transportation before the suspension period starts.","imageName":"","correctAnswer":11553,"isBookmarked":0,"aid":"11553#12291#12685#12686","text":"45 days#4 months#5 days#5 months"},
{"testId":1044,"qid":37855,"description":"First-time DUI offenders with suspended licenses may use which of the following to operate a vehicle?","explanation":"If you are a first-time DUI offender with a suspended license, you may request the installation of a Breath Alcohol Ignition Interlock Device (BAIID). A BAIID uses a breath test to determine your driver's blood alcohol content (BAC). If your BAC exceeds a certain threshold, the device will prohibit your vehicle's engine from starting and will immediately notify the Illinois Secretary of State's office. If your application is accepted, you should be able to drive a vehicle throughout the suspension time by installing this device (at your own cost). Please keep in mind that if you are unable to install a BAIID in your motorbike, you will be unable to ride it for the length of the suspension period.","imageName":"","correctAnswer":12696,"isBookmarked":0,"aid":"897#12696#12697#12698","text":"None of the above#Breath Alcohol Ignition Interlock Device#Breath Analyzing Ignition Interlock Device#Blood Alcohol Ignition Interlock Device"},
{"testId":1044,"qid":37856,"description":"What must a person do to regain driving privileges if he or she has two or three prior DUI convictions but has never participated in the BAIID program?","explanation":"If a motorist has two or three previous DUI convictions but has never participated in the state's BAIID program, he or she will be forced to install a Breath Alcohol Ignition Interlock Device (BAIID) in all cars registered in his or her name in order to restore his or her driving privileges. Note: If the individual is unable to install a BAIID in his or her motorbike, he or she must refrain from riding it for the length of the suspension term.","imageName":"","correctAnswer":29287,"isBookmarked":0,"aid":"29285#29286#29287#29288","text":"Purchase a safer vehicle#Complete a driver education class#Install a Breath Alcohol Ignition Interlock Device#Supply the IL Department of Transportation with weekly blood alcohol concentration (BAC) readings"},
{"testId":1044,"qid":37865,"description":"If you are involved in an accident that causes death, bodily harm, or property damage of more than ________, you must file a crash report.","explanation":"You must submit a crash report if you are involved in an accident that causes death, bodily harm, or more than $1,500 in property damage.","imageName":"","correctAnswer":23890,"isBookmarked":0,"aid":"23890#25033#25034#27889","text":"$1,500#$2,000#$3,000#$2,500"},
{"testId":1044,"qid":37866,"description":"If you are involved in a collision that includes an uninsured vehicle and causes property damage of at least ______, you must file a crash report.","explanation":"You must submit a crash report if you are involved in a collision with an uninsured vehicle that causes at least $500 in property damage.","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":1044,"qid":37871,"description":"Your driver's license may be suspended if you fail to pay for ___ or more parking tickets in any municipality.","explanation":"If you do not pay for ten or more parking tickets issued by any municipality, your license may be suspended.","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5449#6389#7559","text":"10#5#3#7"},
{"testId":1045,"qid":37899,"description":"How many points will be added to your driving record if you fail to yield to another vehicle when obligated to do so?","explanation":"If you fail to yield to another vehicle when necessary, you will get 6 points on your driving record.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1045,"qid":37916,"description":"In the event of an accident, you must:","explanation":"Any driver involved in an accident is required by Indiana law to immediately stop at the scene of the accident or as close to the scene as possible; provide other parties with contact information; provide assistance to each person injured in the accident, as directed by a law enforcement officer, medical personnel, or 911 telephone operator; and provide notice of the accident as soon as possible.","imageName":"","correctAnswer":10656,"isBookmarked":0,"aid":"10656#29928#29929#29930","text":"Do all of the above#immediately stop at the scene of the accident or as close to the accident as possible#provide assistance to each person injured in the accident, as directed by a law enforcement officer, medical personnel, or 911 telephone operator#give notice of the accident as soon as possible"},
{"testId":1045,"qid":37917,"description":"You must file an accident report within ____ days if a crash causes injury, death, or property damage exceeding $1,000.","explanation":"You must submit an accident report within 10 days if a collision causes injury, death, or property damage in excess of $1,000.","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5448#6477#11513","text":"10#20#30#40"},
{"testId":1045,"qid":37922,"description":"For how much duration聽is a motorcycle learner's permit valid in Indiana?","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#11285#11306#11341","text":"1 year#2 years#3 years#5 years"},
{"testId":1046,"qid":37944,"description":"The maximum number of years your driving privileges may be suspended for accumulating excess points in a 6-year period is:","explanation":"Your driving privileges may be suspended for up to 6 years if you accumulate too many points in a 6-year period.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1046,"qid":37962,"description":"If you are involved in an accident that causes bodily harm, death, or more than $1,500 in property damage, you must file an accident report as soon as possible.","explanation":"If you are involved in an accident that causes injury, death, or property damage of more than $1,500, you must submit an accident report within 72 hours. Your license may be suspended if you do not comply. However, if a peace officer examines the accident and files a report, you will not be required to file.","imageName":"","correctAnswer":20755,"isBookmarked":0,"aid":"20753#20755#23883#23886","text":"24 hours#72 hours#10 days#30 days"},
{"testId":1046,"qid":37963,"description":"Iowa's financial responsibility law says that every vehicle must have at least _____ worth of property damage liability coverage on its insurance policy.","explanation":"According to Iowa's financial responsibility law, each vehicle must be insured with at least $15,000 in property damage liability coverage. This regulation applies to both bikes and automobiles.","imageName":"","correctAnswer":12047,"isBookmarked":0,"aid":"11659#12047#12048#12194","text":"$20,000#$15,000#$25,000#$40,000"},
{"testId":1046,"qid":37966,"description":"In Iowa, which of the following individuals is required by law to wear a helmet when riding a motorcycle?","explanation":"When riding a motorbike in Iowa, neither the operator nor a passenger is required to wear a helmet. Furthermore, whether the motorbike has a windshield or not, Iowa law does not require either the operator or a passenger to wear eye protection while riding a motorcycle.","imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1046,"qid":37967,"description":"Which of the following may be waived if you successfully finish the Iowa DOT Basic Rider Course?","explanation":"If you successfully complete the Iowa DOT Basic Rider Course, the DOT may waive the DOT riding skills exam requirement. However, before the DOT can grant you a Class M motorcycle license, you must pass the DOT motorcycle written exam. If you are under the age of 18, you must finish the Iowa DOT Basic Rider Course before the DOT can grant you a Class M motorcycle license. It is optional to complete the Basic Rider Course if you are at least 18 years old. [\"Motorcycle Rider Education in Iowa: Questions and Answers,\" Iowa Department of Transportation, http://165.206.203.6/mvd/motorcycle/MCsafety.html], A.B.A.T.E. of Iowa, https://abateiowa.org/rider-education/],","imageName":"","correctAnswer":45782,"isBookmarked":0,"aid":"37206#37207#45781#45782","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The Iowa DOT motorcycle written test#(b) The Iowa DOT riding skills test"},
{"testId":1046,"qid":37968,"description":"A normal motorcycle license in Iowa is Class:","explanation":"A Class M license in Iowa permits you to ride motorbikes. A Class M license may be obtained without any other licensing class, or it can be added to an existing license class (i.e., to act as a motorcycle endorsement on that existing license).","imageName":"","correctAnswer":45783,"isBookmarked":0,"aid":"20282#20284#30386#45783","text":"A#C#D#M"},
{"testId":1046,"qid":37969,"description":"In Iowa, which of the following individuals is required by law to wear eye protection when riding a motorcycle without a windshield?","explanation":"When riding a motorbike in Iowa, neither the operator nor a passenger is required to wear a helmet. Furthermore, whether the motorbike has a windshield or not, Iowa law does not require either the operator or a passenger to wear eye protection while riding a motorcycle.","imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#45759#45760","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The operator#(b) The passenger"},
{"testId":1046,"qid":37970,"description":"Your riding must be supervised by one of the following licensed individuals if you are at least 18 years old and hold a motorcycle instruction permit:","explanation":"If you are at least 18 years old and have a motorcycle instruction permit, you must ride with one of the following: (1) a parent or guardian, (2) an immediate family member who is at least 21 years old, or (3) an adult who is at least 25 years old. The individual must have a valid driver's license and a motorbike endorsement. If you are under the age of 18 and have a motorcycle instruction permit, your riding must be supervised by (1) a parent or guardian, (2) an immediate family member over the age of 21, or (3) an adult over the age of 25 who has written consent from your parent or guardian to oversee your riding. The individual must have a valid driver's license and a motorbike endorsement.","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#42805#45784#45785","text":"Any of the above#A parent or guardian#An immediate family member who is at least 21 years old#An adult who is at least 25 years old"},
{"testId":1046,"qid":37971,"description":"Which of the following do you need to enroll in the Iowa DOT Basic Rider Course?","explanation":"You must be at least 14 years old and have one of the following to be eligible to take an Iowa DOT Basic Rider Course: an Iowa driver's license, an Iowa driving instruction permit, an Iowa motorcycle license, or an Iowa instruction permit.","imageName":"","correctAnswer":40958,"isBookmarked":0,"aid":"37207#40958#45786#45787","text":"Neither (a) nor (b)#Either (a) or (b)#(a) An Iowa driver's license or driver instruction permit#(b) An Iowa motorcycle license or motorcycle instruction permit"},
{"testId":1047,"qid":37979,"description":"A Kansas instructional permit enables you to drive under the supervision of a licensed driver who is:","explanation":"If you have an instructional permit, you may drive at any time as long as you are supervised by a licensed driver who is 21 or older. [The Driver's License, Kansas Driving Handbook, Driver License Restrictions]","imageName":"","correctAnswer":30676,"isBookmarked":0,"aid":"30676#31816#31817#31818","text":"21 years of age or older#a parent (even if they don't have a valid license)#18 years of age or older#a friend with a license"},
{"testId":1047,"qid":37982,"description":"Which of the following could cause you to lose your driving privileges?","explanation":"Your driver's license may be revoked if you are convicted of vehicular murder, leaving the scene of an accident, or trying to evade an official, among other offenses. [You may have your driving privileges suspended or revoked. Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#23204#27916#30679","text":"Any of the above#Leaving the scene of an accident#Vehicular homicide#Attempting to elude a police officer"},
{"testId":1047,"qid":37983,"description":"Which of the following will NOT result in your driver's license being revoked immediately?","explanation":"A single ticket for failing to yield normally does not result in license revocation. [The Driver's License, Kansas Driving Handbook] [Your Driving Privileges May Be Suspended or Revoked]","imageName":"","correctAnswer":24984,"isBookmarked":0,"aid":"234#11621#24984#30680","text":"Any of the above#Reckless driving#Failure to yield#Vehicular battery"},
{"testId":1047,"qid":37988,"description":"Which of the following will force you to lose your license?","explanation":"Your driver's license will be suspended if you are convicted of driving under the influence of drugs or carrying an open container of any alcoholic beverage. [K.S.A. 8-1567; Suspended or Revoked Driving Privileges, Kansas Driving Handbook, Driver's License]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#30683#30684#30685","text":"Any of the above#Transporting an open container of liquor#Transporting an open container of beer#Driving under the influence of drugs"},
{"testId":1047,"qid":37996,"description":"Which of the following can lead to a DUI charge?","explanation":"Illegal substances, certain prescription medicines, some OTC medications, alcohol, and marijuana may all impair your ability to drive safely. In Kansas, using any of these may result in a DUI arrest. If you have any questions regarding the side effects of a prescription or over-the-counter medicine, read the warning label or see your pharmacist or doctor. [Kansas Driving Handbook, Alcohol, Other Drugs, and Driving, Chapter 3: Be in Shape to Drive]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8071#9803#29384","text":"Any of the above#Alcohol#Over-the-counter medications#Prescription medications"},
{"testId":1047,"qid":38021,"description":"When ________ requests it, you must show your driver's license.","explanation":"For whatever reason, a court, police officer, driver's license examiner, sheriff, or constable has the authority to inspect your driver's license. [The Kansas Driving Handbook, The Driver's License]","imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#2253#12979#30728","text":"Any of the above#a police officer#a judge#a driver's license examiner"},
{"testId":1048,"qid":38050,"description":"For reckless driving, driving on the wrong side of the road, or following too closely (tailgating), you will receive ____ points on your driving record.","explanation":"If you are guilty of reckless driving, driving on the wrong side of the road, tailgating, failing to yield to an emergency vehicle, or changing drivers while your vehicle is in motion, you will get four points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"202006041812161292.jpg","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":38064,"description":"If __________, you may be obliged to attend the State Traffic School Program.","explanation":"If you have been put on probation or convicted of a moving traffic offence that does not warrant obligatory license suspension, you may be compelled to attend the State Driving School Program. [The Kentucky State Traffic School Program, Keeping Your Driver's License Safe, and the Kentucky Driver Manual]","imageName":"","correctAnswer":31840,"isBookmarked":0,"aid":"31838#31839#31840#31841","text":"you have been convicted of a moving traffic violation that does not require mandatory license suspension#you have been placed on probation#either of the above is true#neither of the above is true"},
{"testId":1051,"qid":38209,"description":"What information should you exchange with other drivers if you are involved in a car accident that does not result in injury?","explanation":"If you are involved in a minor car accident, you should share the following information with the other drivers: insurance; the driver's name, address, phone number, and license; and the make, model, license plate, and registration of the vehicle. Record the weather and road conditions. Except for your insurance and the police, do not acknowledge blame or discuss the circumstances of the accident with anybody. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#216#32248#32249","text":"All of the above#Driver's license#Insurance#Personal"},
{"testId":1051,"qid":38210,"description":"If you are engaged in a car accident, you must call the police.","explanation":"If you are involved in a collision and someone is wounded, a motorist does not have a valid license, a driver looks to be inebriated, a driver tries to flee the scene, or public property is destroyed, you must notify the police. [A. Crashes, Maryland Driver's Manual, Section 8: Crashes and Traffic Stops]","imageName":"","correctAnswer":24846,"isBookmarked":0,"aid":"24846#32250#32251#32252","text":"any of the above occurs#someone is injured#a driver does not have a valid license#a driver leaves or tries to leave the scene"},
{"testId":1052,"qid":38233,"description":"If an MA driver is found to be more than ____ at fault in an accident, his or her driving record will reflect this.","explanation":"If a Massachusetts motorist is found to be more than 50% at blame in an accident, the at-fault accident will be recorded on his or her driving record. Accidents in which you are at fault are grounds for license suspension. [At-Fault Accidents; Motor Vehicle Infractions and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":11667,"isBookmarked":0,"aid":"11665#11667#13496#32606","text":"25%#50%#75%#90%"},
{"testId":1052,"qid":38236,"description":"Within a three-year period, ______ surchargeable offences will result in an automatic 60-day license suspension.","explanation":"If a Massachusetts driver is convicted of seven or more surchargeable infractions within three years, his or her license is immediately suspended for 60 days. [Surchargeable Events; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":26628,"isBookmarked":0,"aid":"10545#11417#11592#26628","text":"Four#Five#Six#Seven"},
{"testId":1052,"qid":38248,"description":"If your Massachusetts driver's license was suspended due to a bad check, you must pay the original amount, a license reinstatement charge, and ______ to clear your account and restore your license.","explanation":"If you had your MA license suspended because you wrote a bad check to the RMV, you must pay the original amount, a license reinstatement charge, and a $15 fee to clear your account and have your license restored. [Other Reasons for License Suspension; Suspension or Revocation of Your License; Chapter 2: Keeping Your License; Massachusetts RMV Driver's Manual]","imageName":"","correctAnswer":13053,"isBookmarked":0,"aid":"11349#11351#13053#13054","text":"$50#$10#$15#$20"},
{"testId":1052,"qid":38252,"description":"Which of the following statement about motorcycle permit holders is/are true?","explanation":null,"imageName":"","correctAnswer":14386,"isBookmarked":0,"aid":"14386#33674#33675#33676","text":"All of the above#They are only authorized to operate during daylight hours#They are prohibited from carrying passengers#If they fail the motorcycle road test twice, they must successfully complete an approved rider training program"},
{"testId":1053,"qid":38274,"description":"During a traffic stop, which of the following documents must be shown to a police officer?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#217#218#33515","text":"All of the above#Proof of insurance#Vehicle registration#Driver's license and/or motorcycle TIP"},
{"testId":1054,"qid":38365,"description":"If you do not pass the motorcycle skills test,:","explanation":"If you fail the skills exam, you may repeat it once you have improved your riding abilities. You will be allocated practice time that you must complete before your next skills exam. [Skills Test for Motorcycle Endorsement; Motorcycles; Minnesota Motorcycle and Motorized Bicycle Manual]","imageName":"","correctAnswer":33683,"isBookmarked":0,"aid":"33682#33683#33684#33685","text":"you must pay a fee of $10 to attempt again on the same day#you will be assigned practice time that must be completed before your next skills test is given#you may retake it for free on the same day#none of the above applies"},
{"testId":1054,"qid":38366,"description":"To receive a motorcycle instruction permit if you are under ____ years , you must also provide a certificate of completion of a state-approved motorcycle rider training course.","explanation":"To receive a motorcycle instruction permit if you are under the age of 18, you must additionally provide a certificate of completion of a state-approved motorcycle rider training course. [Process for Obtaining a Motorcycle Permit; Motorcycles; Minnesota Motorcycle and Motorized Bicycle Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"5448#6425#11359#11360","text":"20#25#18#21"},
{"testId":1054,"qid":38367,"description":"Which of the following statement is/are correct?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33686#33687#33688","text":"All of the above#Operators may not ride a motorcycle between lanes of moving or stationary vehicles headed in the same direction#The operator must have the headlight on at all times when riding in Minnesota#Passengers may not ride on a motorcycle unless they can reach the footrests on each side of the motorcycle with both feet while seated"},
{"testId":1054,"qid":38368,"description":"Which of the following limitations apply to a newly licensed driver under the age of 18?","explanation":null,"imageName":"","correctAnswer":33508,"isBookmarked":0,"aid":"32744#33508#33680#33681","text":"You may not ride at night#All of the above restrictions apply#You must wear a helmet and eye protection approved by the federal Department of Transportation (DOT)#You may not carry passengers or ride on any interstate highway"},
{"testId":1055,"qid":38373,"description":"A Mississippi motorist is found guilty of driving with a BAC of 0.08 percent or greater. What are the consequences for a first offense?","explanation":"Driving with a blood alcohol content (BAC) of 0.08 percent or above may result in a driving under the influence conviction (DUI). A first DUI violation carries a license suspension of 120 days, a fine of $250 to $1,000, and up to 48 hours in prison. If the driver completes the Mississippi Alcohol Education and Safety Program, the penalties may be lessened (MASEP). In addition, instead of the license suspension, the court may order the installation of an ignition-interlock device (IID) in the driver's car for 120 days. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Driving While Intoxicated & Implied Consent, Mississippi Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#33826#33827#33828","text":"All of the above#A 120-day license suspension#A fine of at least $250#Up to 48 hours in jail"},
{"testId":1055,"qid":38405,"description":"If it involves_________, you must notify the police.","explanation":"If there are deaths, major physical injuries, or property damage in excess of $500, you must report the accident to the appropriate law enforcement authorities. If you are unable to report the accident because you have been wounded, one of your passengers should do it on your behalf. [Mississippi Driver's Manual, In Case of an Accident, Rules of the Road, and Safe Driving]","imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#25171#33845#33846","text":"Any of the above#a fatality#a serious injury#property damage exceeding $500"},
{"testId":1055,"qid":38407,"description":"If you are involved in an accident that causes death, injury, or at least $1,000 in property damage, you will have ____ days to demonstrate that you can pay for all of the damages through insurance or other means.","explanation":"If you are involved in an accident that causes death, injury, or at least $1,000 in property damage, you will have 60 days to demonstrate that you can pay for all of the losses via insurance or other means. Your driving privileges will be threatened otherwise. [Mississippi Driver's Manual, Proof of Insurance & Safety Responsibility]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#6389#6477#8141","text":"10#3#30#60"},
{"testId":1055,"qid":38411,"description":"Which of the following occupants of a car must use a suitable child restraint or wear a seat belt?","explanation":null,"imageName":"","correctAnswer":16365,"isBookmarked":0,"aid":"4420#16365#26667#33848","text":"Only the driver#The driver and all passengers#The driver and front-seat passengers#No one (seat belts are optional)"},
{"testId":1055,"qid":38412,"description":"Which motorcycle riders are required to wear helmets?","explanation":null,"imageName":"","correctAnswer":33969,"isBookmarked":0,"aid":"33967#33968#33969#33970","text":"The rider (operator) only#The passenger only#Both the rider and the passenger#Neither the rider nor the passenger"},
{"testId":1055,"qid":38413,"description":"You may not ride a motorcycle_______ while you have a motorcycle learner's permit.","explanation":null,"imageName":"","correctAnswer":33974,"isBookmarked":0,"aid":"33971#33972#33973#33974","text":"from 8:00 p.m. to 8:00 a.m#from 8:00 p.m. to 6:00 a.m#from 6:00 p.m. to 6:00 a.m. on weekdays or from 8:00 p.m. to 6:00 a.m. on weekends#from 6:00 p.m. to 6:00 a.m"},
{"testId":1055,"qid":38416,"description":"In recent years, around _____ % of motorcyclists killed in single-vehicle accidents were legally inebriated.","explanation":"In 2013, for example, about 40% of motorcyclists killed in single-vehicle collisions had a BAC of 0.08 percent or above, which is beyond the legal limit in all US states. [National Highway Traffic Safety Administration, Traffic Safety Facts, May 2015, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/812148]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6477#11361#11513#12040","text":"30#16#40#24"},
{"testId":1055,"qid":38420,"description":"You must be supervised at all times while riding with a motorcycle learner's permit by a licensed motorcyclist who is at least ____ years old.","explanation":null,"imageName":"","correctAnswer":11360,"isBookmarked":0,"aid":"5448#6425#11359#11360","text":"20#25#18#21"},
{"testId":1056,"qid":38449,"description":"If you've been convicted of DWI more than ___ times, you must install an ignition interlock device in every vehicle you operate聽before you can drive again.","explanation":null,"imageName":"","correctAnswer":11671,"isBookmarked":0,"aid":"13#15#11671#11672","text":"four times#three times#once#twice"},
{"testId":1056,"qid":38450,"description":"After your license is reinstated, you must keep an ignition interlock device (IID) fitted in each vehicle you operate for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#26672","text":"6 months#1 year#30 days#5 years"},
{"testId":1056,"qid":38472,"description":"If you need to file an accident report in Missouri, you must do so within ___ days after the accident.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#5447#6389#6477","text":"10#15#3#30"},
{"testId":1057,"qid":38479,"description":"If a driver under the age of 21 is convicted for the third time of driving with a blood alcohol content (BAC) of 0.02 percent or more, his or her driver license will be suspended for:","explanation":"If a motorist under the age of 21 is convicted of driving with a BAC of 0.02 percent or more for the third time, his or her driver license will be revoked for one year. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1057,"qid":38485,"description":"In Montana, which of the following will NOT result in a one-year license suspension?","explanation":null,"imageName":"","correctAnswer":34442,"isBookmarked":0,"aid":"34442#34443#34444#34445","text":"Two convictions for speeding within one year#Perjury relating to the ownership or operation of a motor vehicle#Leaving the scene of a fatal motor accident#Negligent vehicular assault"},
{"testId":1057,"qid":38486,"description":"If you accumulate _____ demerit points in  three-year, your license will be suspended.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5447#5448#6425#6477","text":"15#20#25#30"},
{"testId":1057,"qid":38493,"description":"How many points a conviction for DUI will add to your Montana driving recors?","explanation":"If a Montana motorist is guilty of driving under the influence of alcoholic beverages, opioids, or drugs, 10 points will be added to his or her driving record. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1057,"qid":38499,"description":"You must have ________ with you whenever you ride a motorcycle in Montana.","explanation":null,"imageName":"","correctAnswer":34576,"isBookmarked":0,"aid":"34450#34451#34452#34576","text":"proof of liability insurance#your birth certificate#your Social Security card#your motorcycle endorsement"},
{"testId":1057,"qid":38500,"description":"Who is required to wear a helmet under Montana's helmet law?","explanation":"Riders and passengers under the age of 18 must wear helmets in Montana, according to state law. Nonetheless, bikers and passengers of all ages should wear helmets. In a mishap, an unhelmeted motorcycle rider is 40% more likely to have a fatal head injury and 15% more likely to sustain a nonfatal injury than a helmeted rider. Motorcycle Safety, Vision Zero, Montana Department of Transportation, http://www.mdt.mt.gov/visionzero/people/motorcycles .shtml]","imageName":"","correctAnswer":34579,"isBookmarked":0,"aid":"34577#34578#34579#34580","text":"Riders and passengers#Passengers but not riders#Riders under 18 and passengers under 18#Passengers under 18 but not riders under 18"},
{"testId":1057,"qid":38501,"description":"In the event that you are found guilty of breaking the state's helmet law, you will be required to pay a fine of:","explanation":"If you are found in violation of Montana's helmet legislation, you will be fined $5. [Montana Code Annotated, MCA 61-9-518]","imageName":"","correctAnswer":13035,"isBookmarked":0,"aid":"11349#11351#13035#13054","text":"$50#$10#$5#$20"},
{"testId":1057,"qid":38502,"description":"Montana law mandates the use of eye protection by :","explanation":"Riders and passengers in Montana are not required to wear eye protection. Wearing eye protection is still a good idea to protect your eyes from the wind and particles carried by the wind. In fact, wearing face protection that protects your eyes as well as the rest of your face is an excellent idea.","imageName":"","correctAnswer":34584,"isBookmarked":0,"aid":"34581#34582#34583#34584","text":"riders and passengers#passengers but not riders#riders but not passengers#neither riders nor passengers"},
{"testId":1057,"qid":38503,"description":"If it's your first offense, you may have to pay a _____fine for operating an excessively聽noisy motorcycle in Montana.","explanation":"Each motorbike in Montana must be fitted with noise-cancelling equipment such as an exhaust muffler. Late-model bikes must produce no louder than 70 dB on the standard A scale while operating on public highways. If you are convicted for the first time of breaching this legislation, you will be fined between $10 and $100. [Montana Code Annotated, MCA 61-9-418 and MCA 61-9-518]","imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"5446#5448#6591#7571","text":"10#20#100#50"},
{"testId":1057,"qid":38507,"description":"What must you do when you pass a stopped emergency vehicle whose lights are flashing or moving?","explanation":"Slow down and continue with care if you notice an emergency vehicle stopped with its flashing or rotating lights turned on, according to Montana's Move Over legislation. You must slow down by at least 20 mph on a roadway with a stated speed restriction of at least 50 mph. If feasible, move into a non-adjacent lane, leaving at least one empty lane between you and the emergency vehicle. (Note: All states now have their own Move Over statutes, however some demand various actions from drivers.) Always verify the local traffic rules while going out of state.) [Montana Motorcycle Endorsements, Montana Motorcycle Supplement; MCA 61-8-346, Montana Code Annotated]","imageName":"","correctAnswer":34516,"isBookmarked":0,"aid":"19471#34514#34515#34516","text":"Slow down and proceed with caution#Try to move into a non-adjacent lane#Move into a non-adjacent lane if possible; otherwise, slow down#Slow down and try to move into a non-adjacent lane"},
{"testId":1057,"qid":38508,"description":"If this is your second offense, you could be required to pay a fine of up to ___ if you are found guilty of riding a motorcycle that makes an excessive amount of noise in violation of the law in Montana.","explanation":"Each motorbike in Montana must be fitted with noise-cancelling equipment such as an exhaust muffler. Late-model bikes must produce no louder than 70 dB on the standard A scale while operating on public highways. If you are found guilty of breaching this statute for the second time, you will be fined between $25 and $200. [Montana Code Annotated, MCA 61-9-418 and MCA 61-9-518]","imageName":"","correctAnswer":7570,"isBookmarked":0,"aid":"6591#7570#12770#14369","text":"100#200#500#400"},
{"testId":1057,"qid":38512,"description":"How much will you have to pay if you break Montana's Open Container Law?","explanation":"The Open Container Law in Montana forbids all occupants of a motor vehicle from having alcoholic drinks within reach. If you are found guilty of breaking the law, you will be fined $100. [Montana's Open Container Law, Vision Zero, Montana Department of Transportation, http://www.mdt.mt.gov/visionzero/people/open container law.html] .shtml]","imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"6591#7570#9975#14369","text":"100#200#300#400"},
{"testId":1057,"qid":38517,"description":"To earn a motorcycle endorsement, which of the following is NOT required?","explanation":null,"imageName":"","correctAnswer":34585,"isBookmarked":0,"aid":"34585#34586#34587#34588","text":"Holding a full-privilege (adult) driver license#Passing a motorcycle written test#Passing a motorcycle skills test or a rider safety course#Paying a fee"},
{"testId":1057,"qid":38518,"description":"Except _______, all of the following require a motorcycle endorsement in Montana.","explanation":"It is prohibited in Montana to ride a motor scooter, motorbike, or any other motor-driven machine on a public road unless you have a valid driving license with a motorcycle endorsement. A moped or powered bicycle is not considered a motor-driven cycle under Montana law. Rather, Montana law defines a moped as a two- or three-wheeled vehicle having both foot pedals and a power source capable of moving the moped at more than 30 mph on a flat surface and restricted to 2 brake horsepower. [Montana Motorcyclist Endorsements, Montana Motorcyclist Supplement; MCA 61-8-102, Montana Code Annotated]","imageName":"","correctAnswer":34589,"isBookmarked":0,"aid":"27030#34589#34590#34591","text":"motorcycles#mopeds#motor scooters#motor-driven cycles"},
{"testId":1057,"qid":38519,"description":"What is the legal limit for the amount of alcohol in an adult driver's blood?","explanation":"Adults have a legal blood alcohol content (BAC) limit of less than 0.08 percent. (In Montana, the BAC limit for drivers under 21 is less than 0.02 percent.) If your BAC is 0.08 percent or more, you are deemed legally inebriated and may face DUI charges. (Note: Three 1.5-ounce shots of 80-proof whiskey, three 12-ounce cans of beer, or three 5-ounce glasses of wine will give an average 180-pound guy a BAC of 0.10 percent before his body begins to break down the alcohol.) [Alcohol and the Law, Rider Fitness, Montana Motorcycle Supplement]","imageName":"","correctAnswer":10236,"isBookmarked":0,"aid":"3168#3171#10236#11768","text":"0.08%#0.09%#Less than 0.08%#0.10%"},
{"testId":1057,"qid":38520,"description":"Impaired (drunk or drugged) driving accounts for around _______ of all fatalities on Montana's highways.","explanation":"In 2015, impaired driving was responsible for 58 percent of all deaths on Montana's highways. This was an increase from 47% in 2014. [Montana Department of Transportation, Traffic Safety Programs: Impaired Driving, http://www.mdt.mt.gov/visionzero/plans/impaired .shtml]","imageName":"","correctAnswer":10698,"isBookmarked":0,"aid":"10698#11884#33469#33470","text":"one-half#one-fourth#two-thirds#one-third"},
{"testId":1057,"qid":38521,"description":"If you have a motorcycle learner's permit, you must always be with a licenced motorcyclist who stays within ____ feet of you at all times.聽:","explanation":"While you have a motorcycle learner license instruction permit), you must ride a motorcycle under the supervision of a licensed operator or chauffeur with a motorcycle endorsement who can see you and is within 300 feet of you. [23.3.153 Motorcycle Endorsement Instruction Permit, Montana Administrative Rules]","imageName":"","correctAnswer":9975,"isBookmarked":0,"aid":"6591#9975#12770#13871","text":"100#300#500#1,000"},
{"testId":1057,"qid":38522,"description":"If you're convicted of riding a motorcycle without a license, you'll get ____ points.","explanation":"If you are convicted of riding a motorbike without a motorcycle endorsement, you will get two points on your driving record. Understanding the Montana Driving Record, Montana Department of Justice, https://media.dojmt.gov/wp-content/uploads/MV-Understanding-Montana-Driving-Record.pdf .pdf]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1058,"qid":38523,"description":"If you fail an alcohol test and a police officer seizes your driver's license, you will be given a temporary license that will expire in ___ days.","explanation":"If you fail an alcohol test and your license is confiscated by a police officer, you will be awarded a temporary license that will expire in 15 days. [Nebraska Driver's Manual, 1E-7 Administrative License Revocation, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5446#5447#6389#6477","text":"10#15#3#30"},
{"testId":1058,"qid":38524,"description":"Which of the following may result in the termination of driving privileges in Nebraska?","explanation":"In Nebraska, a driver's license may be suspended, cancelled, or seized if he or she is guilty of driving under the influence of alcohol or drugs, leaving the scene of an accident, or several traffic offences, among other things. Some offences that may result in the loss of your driving privileges are not linked to traffic, such as failing to pay child support. [1E-1 When Your Driver's License Could Be Suspended, Revoked, or Impounded, Suspensions, revocations, impoundments, and forfeitures, Nebraska Driver's Manual]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#23204#24985#34733","text":"All of the above#Leaving the scene of an accident#Driving under the influence (DUI)#Repeated traffic violations"},
{"testId":1058,"qid":38525,"description":"A driver under the age of 21 who accumulates at least ___ points on his or her driving record in a year must undergo a driver improvement course.","explanation":"A motorist under the age of 21 who has a Provisional Operator's Permit (POP) or license and earns at least six points on his or her driving record in a year must successfully complete a driver improvement course. If the motorist does not comply within three months, his or her POP or license will be revoked. [1B-13 General Permit, License, and State Identification (ID) Card Information, Nebraska Driver's Manual, 1B Types of Permits and Licenses], [1E-1 When Your License May Be Suspended, Revoked, or Impounded, Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6916#7559#7620#11342","text":"6#7#8#9"},
{"testId":1058,"qid":38526,"description":"If you acquire at least 12 points in two years, your license will be suspended for:","explanation":"If you acquire at least 12 points in a two-year period, your license will be suspended for six months. [1E-2 Point Revocation Procedures, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1058,"qid":38528,"description":"For a reinstatement of your driving privileges after having them suspended for too many points, you must complete a state-approved driver's education and training program of at least ______ hours' duration.","explanation":"If your license is revoked due to a high number of points, you must complete an authorized education and training course with at least eight hours of teaching before your driving privileges may be restored. You must pay for the course on your own. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1058,"qid":38529,"description":"If your license is suspended for point accumulation, you must preserve proof of financial responsibility for _____ year(s) after the suspension is lifted.","explanation":"If your license is canceled for accruing too many points, you must keep evidence of financial responsibility for three years after the revocation expires. Your insurer's Form SR-22 normally serves as proof of financial obligation. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1058,"qid":38530,"description":"If your license is revoked twice in five years for excessive point accumulation, you will be unable to obtain a new license for ___ years.","explanation":"If your license is revoked twice in five years for excessive point accumulation, you will be unable to acquire a new license for three years. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-2 Point Revocations Procedures]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1058,"qid":38539,"description":"If you refuse to take a chemical test for alcohol or drugs when asked by a police officer, ___ point(s) will be added to your driving record.","explanation":"If you decline to take a chemical test for alcohol or drugs when asked by a police officer, just one point will be added to your driving record. However, your license may be suspended for a year. [2A Alcohol, Drugs, and Driving, 2 Important Driver Information, Nebraska Driver's Manual], [1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1058,"qid":38544,"description":"If you are caught driving with an expired license, you will receive ___ point(s) on your driving record.","explanation":"Driving with an expired license results in just one point being added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1058,"qid":38545,"description":"When determining how many points to assign to a driver's licence, what date is used?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":27213,"isBookmarked":0,"aid":"27213#27215#34738#34824","text":"The date of violation#The date of conviction#The license's expiration date#The date of birth"},
{"testId":1058,"qid":38551,"description":"Driver's licenses are cancelled for ____________ if a person is guilty of driving with a seized license.","explanation":"If a person is guilty of driving while his or her license is confiscated by the court, the license will be revoked for a year. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-5 Court Ordered Impoundment Procedures]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#23886","text":"6 months#1 year#3 years#30 days"},
{"testId":1058,"qid":38555,"description":"If a driver younger than 21 is found guilty of driving while his or her license is impounded, the court will revoke the driver's license for an extended period of :","explanation":"If a person under the age of 21 is convicted of driving while his or her license is suspended, the court will revoke the license for six months. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24048#25857","text":"6 months#1 year#60 days#120 days"},
{"testId":1058,"qid":38556,"description":"The Administrative License Revocation Law empowers law enforcement to instantly remove your driver's license if:","explanation":"If a driver fails an alcohol test or rejects a drug or alcohol test, Nebraska's Administrative License Revocation statute allows law enforcement to seize the driver's license and grant a temporary license valid for 15 days. After then, unless the operator asks a hearing to fight the revocation, the license will be canceled. [Nebraska Driver's Manual, 1E-7 Administrative License Revocation, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":37202,"isBookmarked":0,"aid":"37200#37201#37202#37203","text":"(a) you refuse to submit to chemical testing for alcohol or drugs#(b) you fail a test for alcohol#either (a) or (b)#neither (a) nor (b)"},
{"testId":1058,"qid":38557,"description":"If a minor under the age of 18 is convicted in juvenile court of any infraction, the court may suspend his or her permit or license for:","explanation":"If a minor under the age of 18 is convicted in juvenile court of any infraction, the court may imprison his or her permission or license for 30 days. [1E-15 Juvenile Court Offenses (17 and Under), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1058,"qid":38558,"description":"Your driver's license will be revoked for a period of up to______if you are found guilty of fleeing the scene of an accident without providing necessary assistance.","explanation":"If you are convicted of fleeing the scene of an accident, you may have your license suspended for up to a year. [Nebraska Driver's Manual, 1E-12 Leaving the Scene, 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24048#26672","text":"6 months#1 year#60 days#5 years"},
{"testId":1058,"qid":38559,"description":"If a vehicle operator under the age of 21 is convicted for the first time of carrying or drinking alcohol, the court will revoke his or her permission or license for a period of:","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1058,"qid":38560,"description":"If a vehicle operator under the age of 21 is convicted of possessing or drinking alcohol for the second time, the court will seize his or her permit or license for a period of:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1058,"qid":38561,"description":"If a driver under the age of 18 is convicted of a drug offense for the first time, the court will revoke his or her license or permission for:","explanation":"If a driver under the age of 18 is guilty of a drug offense for the first time, the court will imprison his or her license or permission for 30 days. A second drug conviction means a 90-day impoundment. A third drug conviction means a one-year impoundment. [Nebraska Driver's Manual, 1E-14 Juvenile Court Drug-Related Offenses (17 and Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1058,"qid":38562,"description":"If you operate a motor vehicle in Nebraska, you grant your approval to _________ upon the request of a law enforcement officer under Nebraska's Implied Consent law.","explanation":"If you drive a motor vehicle in Nebraska, you provide your assent to chemical testing for alcohol or drugs at the request of a law enforcement official under Nebraska's Implied Consent statute. [2A Alcohol, Drugs, and Driving, 2 Important Driver Information, Nebraska Driver's Manual, Implied Consent Law]","imageName":"","correctAnswer":13824,"isBookmarked":0,"aid":"763#13824#34751#34752","text":"all of the above#chemical testing for alcohol or drugs#having your home searched#the release of your medical records"},
{"testId":1058,"qid":38564,"description":"Who is required to wear a helmet under Nebraska's motorcycle helmet law?","explanation":"Nebraska's motorcycle helmet legislation mandates all motorcycle riders and passengers, regardless of age, to wear helmets that meet or exceed the US Department of Transportation's criteria for motorcycle helmets. Nebraska Motorcycle Operator Manual, B Motorcycle Laws","imageName":"","correctAnswer":34577,"isBookmarked":0,"aid":"34577#34578#34579#34580","text":"Riders and passengers#Passengers but not riders#Riders under 18 and passengers under 18#Passengers under 18 but not riders under 18"},
{"testId":1058,"qid":38565,"description":"In the event that you are found guilty of breaking the state's helmet law, you will be required to pay a fine of:","explanation":"Nebraska's motorcycle helmet legislation mandates all motorcycle riders and passengers, regardless of age, to wear helmets that meet or exceed the US Department of Transportation's criteria for motorcycle helmets. If you are found in violation of Nebraska's helmet legislation, you will be fined $50. Nebraska Motorcycle Operator Manual, B Motorcycle Laws","imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11349#11351#11832#13054","text":"$50#$10#$100#$20"},
{"testId":1058,"qid":38566,"description":"When riding a motorcycle in Nebraska, ______must wear eye protection.","explanation":"When riding a motorbike in Nebraska, neither the rider nor the passenger is required to wear eye protection. Wearing eye protection is still a good idea to protect your eyes from the wind and particles carried by the wind. In fact, wearing face protection that protects your eyes as well as the rest of your face is an excellent idea.","imageName":"","correctAnswer":34584,"isBookmarked":0,"aid":"34581#34582#34583#34584","text":"riders and passengers#passengers but not riders#riders but not passengers#neither riders nor passengers"},
{"testId":1058,"qid":38567,"description":"If you are found guilty of texting while driving, you will have to pay a minimum of _____ in fine.","explanation":"It is illegal in Nebraska to read or transmit written messages on a portable wireless device. A fine of between $200 and $500 is imposed for violations. [2D Handheld Wireless Communication Device, Nebraska Driver's Manual, 2 Important Driver Information]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#24995","text":"$200#$100#$50#$150"},
{"testId":1058,"qid":38569,"description":"Which of the following must be true in order to get a School Permit (SCP)?","explanation":null,"imageName":"","correctAnswer":34759,"isBookmarked":0,"aid":"34759#34825#34826#34827","text":"All of the above must be true#You must be at least 14 years and 2 months of age#You must live at least 1.5 miles from your school#Either you must live outside a city of at least 5,000 or the school must be located outside a city of at least 5,000"},
{"testId":1058,"qid":38570,"description":"An aspiring motorcycle driver with a learner's permit must be supervised while driving. Which of the following requirements does NOT apply to driving supervisor?","explanation":null,"imageName":"","correctAnswer":34830,"isBookmarked":0,"aid":"34760#34828#34829#34830","text":"He or she must be at least 21 years of age#He or she must be within visual contact of the motorcyclist#He or she must be a licensed motorcyclist#He or she must own his or her own motorcycle"},
{"testId":1058,"qid":38571,"description":"A provisional operator's permit (POP) allows a driver over the age of 16 to drive alone from ______ to_____ .","explanation":"A POP permits a motor vehicle operator who is at least 16 years old to drive a vehicle unattended between the hours of 6 a.m. and midnight. Between midnight and 6 a.m., the individual may drive a motor vehicle unattended only if he or she is going to or from school or work. [1B-5 Provisional Operator's Permit (POP), 1B Types of Permits and Licenses, Nebraska Driver's Manual, Nebraska License to Drive]","imageName":"","correctAnswer":13195,"isBookmarked":0,"aid":"13193#13194#13195#13196","text":"7 a.m.; 10 p.m#6 a.m.; 10 p.m#6 a.m.; midnight#8 a.m.; midnight"},
{"testId":1058,"qid":38572,"description":"Before applying for a license, Nebraska citizens under the age of ___ must hold a provisional operator's permit (POP) for at least one year.","explanation":"A Nebraska resident under the age of 18 who has had a POP for at least one year and has accumulated less than three points in the previous twelve months is eligible for a license. [1B-6 Operator's License (Class O), 1B Types of Permits and Licenses, Nebraska Driver's Manual, Nebraska License to Drive]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1059,"qid":38573,"description":"In Nevada, every motor vehicle you drive must have at least _______ in bodily injury or death insurance coverage for one person in any one collision.","explanation":"Each motor vehicle's liability insurance policy must contain at least $25,000 in coverage for the bodily injury or death of one person in any one accident, according to Nevada law. This is true for both bikes and automobiles. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility], [\"Motorcycles & Trimobiles,\" Nevada Department of Motor Vehicles, http://dmvnv.com/motorcycle.htm], [NRS 485]. .185]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1059,"qid":38574,"description":"Every vehicle you drive in Nevada must have at least _______ in insurance coverage for the bodily injury or death of two or more people in any one accident.","explanation":"According to Nevada law, each motor vehicle's liability insurance policy must contain at least $50,000 in coverage for the bodily injury or death of two or more people in a single collision. This is true for both bikes and automobiles. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility], [\"Motorcycles & Trimobiles,\" Nevada Department of Motor Vehicles, http://dmvnv.com/motorcycle.htm], [NRS 485]. .185]","imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#11659#12048#12195","text":"$50,000#$20,000#$25,000#$35,000"},
{"testId":1059,"qid":38575,"description":"Every motor vehicle you operate in Nevada must be insured for at least _______ of property damage.","explanation":"Nevada law mandates that each liability insurance policy for a motor vehicle have at least $20,000 in property damage coverage. This is true for both bikes and automobiles. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility], [\"Motorcycles & Trimobiles,\" Nevada Department of Motor Vehicles, http://dmvnv.com/motorcycle.htm], [NRS 485]. .185]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"1799#3195#11658#11659","text":"$1,000#$5,000#$10,000#$20,000"},
{"testId":1059,"qid":38577,"description":"If you are involved in an accident that results in bodily harm or at least $750 in estimated property damage and is not investigated by law enforcement, you must file an accident report with the Nevada DMV within 30 days.","explanation":"You must submit an accident report with the Nevada DMV within ten days if you are involved in an accident that involves injury or at least $750 in estimated property damage and is not investigated by law enforcement. If you do not comply, your driver's license or car registration may be revoked. [Nevada Driver's Handbook, Chapter 7: Insurance and Financial Responsibility]","imageName":"","correctAnswer":23883,"isBookmarked":0,"aid":"23154#23883#25170#34746","text":"1 year#10 days#1 month#3 days"},
{"testId":1059,"qid":38586,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":"If you are guilty of tailgating another vehicle, four points will be added to your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"202006041812161292.jpg","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1059,"qid":38618,"description":"A motorcycle instruction permit holder must ride under the supervision of another person who:","explanation":"A motorcycle instruction permit holder must be supervised at all times when riding. The riding supervisor must be at least 21 years old, have at least one year of licensed riding experience, and maintain eye contact with the biker at all times. While monitoring the biker, the riding supervisor may not be in a vehicle or truck. (In reality, this means that the riding supervisor must travel on public roads while monitoring the motorcyclist.) [Instruction Permits, Nevada Driver's Handbook, Chapter 1: Obtaining Your Nevada Driver's License]","imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35002#35003#35005#35120","text":"is at least 21 years of age#has held a license for at least one year#meets all of the above requirements#stays within visual contact of the motorcyclist"},
{"testId":1059,"qid":38619,"description":"Which of the following violations will result in the addition of two points to your driving record?","explanation":"If you are guilty of any of the following offenses, you will get two points on your driving record: Riding a motorcycle without a helmet; riding a motorcycle without eye protection if the motorcycle does not have a windshield; riding a motorcycle without keeping at least one hand on the handlebars; sharing a lane with a motor vehicle that is not a motorcycle; and sharing a lane with more than two other motorcycles (i.e., riding three abreast in a single lane). Each of these infractions is a misdemeanor, and the court may impose sanctions. [\"Demerit Point System,\" Nevada Department of Motor Vehicles, http://www.dmvnv.com/dlpoints/dlpoints/dlpoints/dlpoints/dlpoints/dlpoints/ .htm]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35121#35122#35123","text":"All of the above#Riding a motorcycle without keeping a hand on the handlebars#Not wearing a helmet#Sharing a lane with a car"},
{"testId":1059,"qid":38621,"description":"Which of the following restrictions does NOT apply to your riding if you have a motorcycle instruction permit?","explanation":"If you have a motorcycle instruction permit, you will be limited to the following routes: (1) While riding, you must be supervised. (2) You are not permitted to transport people. (3) You are not permitted to travel on a limited-access highway or expressway. (4) You are not permitted to bike at night. [Introduction, Nevada Motorcycle Operator Manual, Motorcycle Instruction Permit]","imageName":"","correctAnswer":35124,"isBookmarked":0,"aid":"32742#32744#35124#35125","text":"You may not carry passengers#You may not ride at night#You may not carry cargo#You may not ride on a limited-access highway or freeway"},
{"testId":1059,"qid":38622,"description":"Unless the motorcycle has a windscreen, _________ must wear eye protection.","explanation":"Unless the motorbike is fitted with a windshield, Nevada law requires both the rider and the passenger to wear eye protection. Even though your motorbike has a windshield, you should consider wearing eye protection. Goggles or a face shield will protect your eyes from the wind better than a windshield. [Introduction to Motorcycle Traffic Laws, Nevada Motorcycle Operator Manual]","imageName":"","correctAnswer":33969,"isBookmarked":0,"aid":"33969#33970#35126#35127","text":"Both the rider and the passenger#Neither the rider nor the passenger#Only the rider#Only the passenger"},
{"testId":1060,"qid":38632,"description":null,"explanation":null,"imageName":"","correctAnswer":35540,"isBookmarked":0,"aid":"35537#35538#35539#35540","text":"Only the knowledge test#Only the skills test#Neither the knowledge test nor the skills test#Both the knowledge test and the skills test"},
{"testId":1060,"qid":38633,"description":"If you are convicted of riding a motorcycle whose engine emits more than 92 decibels of noise at idle in New Hampshire, you must pay a fine of at least:","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1060,"qid":38634,"description":"Which of the following is NOT against the law in New Hampshire?","explanation":null,"imageName":"","correctAnswer":35541,"isBookmarked":0,"aid":"35123#35541#35542#35543","text":"Sharing a lane with a car#Sharing a lane with another motorcycle#Passing a vehicle in the same lane#Riding between lanes of traffic"},
{"testId":1060,"qid":38643,"description":"If you are involved in an accident that causes death, injury, or property damage of more than $1,000, you must file an accident report within 24 hours.","explanation":"If you are involved in an accident that causes death, injury, or property damage of more than $1,000, you must submit an accident report within 15 days. [Part Sixteen, Insurance, Accidents, and Financial Responsibility, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23884,"isBookmarked":0,"aid":"23883#23884#23885#34746","text":"10 days#15 days#20 days#3 days"},
{"testId":1060,"qid":38644,"description":"Is it necessary to obtain liability insurance for your motorcycle?","explanation":"There is no mandated insurance requirement in New Hampshire. Even if you may lawfully drive an uninsured car, it might pose a significant financial risk to you. If you are involved in an accident, one or more of the other parties may hold you liable for the payment of their injuries and losses. Medical costs, in particular, may be exceedingly costly. Your license may be suspended until you can pay your debts. In addition, New Hampshire may compel you to insure your car for the following several years. [State of New Hampshire Driver's Manual, Part Sixteen, Reporting the Accident, Accidents and Financial Responsibility]","imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#35442#35544#35545","text":"No#Yes, if you're under 25 years of age#Yes, if the motorcycle is less than 10 years old#Yes, if the motorcycle is valued at over $6,000"},
{"testId":1060,"qid":38645,"description":"If you are compelled to acquire motorcycle liability insurance due to a past accident, the policy must have at least ______ in property damage coverage.","explanation":"If you are in an accident, you may be compelled to have insurance for a period of time. Property damage coverage of at least $25,000 must be included in the insurance policy. [Part Sixteen, Insurance, Accidents, and Financial Responsibility, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12048#12842","text":"$50,000#$10,000#$25,000#$75,000"},
{"testId":1060,"qid":38646,"description":"If you are found guilty of riding a motorcycle without the proper endorsement, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1060,"qid":38665,"description":"If you are convicted of operating a motorcycle whose engine emits more than 92 decibels of noise at idle in New Hampshire, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"24977#24978#24994#33102","text":"$200#$100#$50#$300"},
{"testId":1060,"qid":38666,"description":"Which of the following is prohibited by New Hampshire law?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#35546#35547#35548","text":"All of the above#Carrying a passenger on a motorcycle that is not designed to carry a passenger#Riding with both legs on the same side of the motorcycle#Carrying a package that prevents you from keeping both hands on the handlebars"},
{"testId":1060,"qid":38667,"description":"A _____ fine will be imposed if you are found guilty of riding with both legs on the same side of the motorcycle.","explanation":null,"imageName":"","correctAnswer":27902,"isBookmarked":0,"aid":"24978#24994#27902#35549","text":"$100#$50#$75#$130"},
{"testId":1060,"qid":38671,"description":"Who is required to wear a helmet under New Hampshire's motorcycle helmet law?","explanation":null,"imageName":"","correctAnswer":34579,"isBookmarked":0,"aid":"34577#34578#34579#34580","text":"Riders and passengers#Passengers but not riders#Riders under 18 and passengers under 18#Passengers under 18 but not riders under 18"},
{"testId":1060,"qid":38672,"description":"When riding a motorcycle without a windshield in New Hampshire, ______ must wear eye protection.","explanation":null,"imageName":"","correctAnswer":35550,"isBookmarked":0,"aid":"35550#35551#35552#35553","text":"only the rider#only the passenger#both the rider and the passenger#neither the rider nor the passenger"},
{"testId":1061,"qid":38675,"description":"A motorist's likelihood of causing an accident ________ if his or her blood alcohol concentration (BAC) is somewhat more than 0.05 percent.","explanation":"According to the New Jersey Driver Manual, a motorist's chances of having an accident treble if his or her BAC is marginally more than 0.05 percent. It should be noted that this figure is lower than the legal limit for adults. [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":35971,"isBookmarked":0,"aid":"35971#35972#35973#35974","text":"doubles#triples#increases by 50%#increases by 25%"},
{"testId":1061,"qid":38676,"description":"A motorist with a blood alcohol concentration (BAC) of 0.15 percent is ___ times more likely to cause an accident.","explanation":"A driver with a BAC of 0.15 percent is 25 times more likely to cause an accident, according to the New Jersey Driver Manual. [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#5448#6425#7571","text":"10#20#25#50"},
{"testId":1061,"qid":38677,"description":"Which of the following punishments will you face if you refuse to undergo a breath test in New Jersey?","explanation":"Refusing to submit to chemical testing carries the same penalties as a first DUI offense with a BAC of 0.10 percent. (The legal limit for adults is less than 0.08 percent.) These penalties include license revocation for seven months to one year (N.J.S.A. 39:4-50.4a) as well as a $1,000 per year violation fee for three years (N.J.S.A. 17:29A-35). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#26643#26994#45596","text":"All of the above#A fine#Loss of driving privileges#Required installation of an ignition interlock device in your vehicle"},
{"testId":1061,"qid":38678,"description":"Your driver's license may be suspended for up to ________, if you refuse to submit to a chemical test.","explanation":"Refusing to submit to chemical testing carries the same penalties as a first DUI offense with a BAC of 0.10 percent. (The legal limit for adults is less than 0.08 percent.) These penalties include license revocation for seven months to one year (N.J.S.A. 39:4-50.4a) as well as a $1,000 per year violation fee for three years (N.J.S.A. 17:29A-35). [How Much Is Too Much?, The New Jersey Driver Manual, Chapter 6: Drinking, Drugs, and Health]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1061,"qid":38686,"description":"If you are convicted of DUI for the first time, your license will be suspended for up to:","explanation":"If you are convicted of your first DUI with a BAC of at least 0.08 percent but less than 0.10 percent, your license will be suspended for three months. Your license will be suspended for seven months to one year if your BAC was 0.10 percent or above. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Mandatory Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1061,"qid":38704,"description":"By driving on New Jersey's public highways, you imply assent to_______, the Implied Consent Law.","explanation":"The state of New Jersey has an implied consent legislation. This implies that by driving on New Jersey roads, you have implicitly consented to submit to a breath test administered by law police or hospital personnel after your arrest for drunk driving. If you refuse to undergo a breath test, you will be detained and sent to a hospital, where a blood sample may be collected. You will also suffer consequences. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Breath Test]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":1061,"qid":38705,"description":"First-time DUI offenders may be required to install an ignition interlock device (IID) for at least:","explanation":"If you are convicted of DUI for the first time, the court may require you to place an ignition interlock device (IID) on each car you drive for at least six months, beginning when your license is restored. Automobiles, motorcycles, and even autocycles are included. If your blood alcohol content (BAC) was 0.15 percent or greater at the time of the violation, you must have an IID installed. If your BAC exceeds a certain level, the IID will prohibit your car from starting. If you cannot find an installer to install an IID in your motorbike, you must cease riding it for the length of the mandatory IID installation time. [\"Ignition Interlock Device FAQs,\" New Jersey MVC, http://www.state.nj.us/mvc/pdf/violations/interlock-faq], [\"Ignition Interlock Device FAQs,\" New Jersey MVC, http://www.state.nj.us/mvc/pdf/violations/interlock-faq] .pdf]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":1061,"qid":38707,"description":"It is unlawful to leave a motor vehicle on a limited-access roadway for _______ hours or more.","explanation":null,"imageName":"","correctAnswer":18590,"isBookmarked":0,"aid":"12827#13916#18590#18591","text":"24 hours#12 hours#4 hours#2 hours"},
{"testId":1061,"qid":38708,"description":"If convicted of carrying a passenger on an unequipped motorcycle, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1061,"qid":38709,"description":"If you carry a passenger on your motorcycle who isn't wearing a helmet, you could be fined up to:","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1061,"qid":38710,"description":"First-time hit-and-run offenders may face which of the following panelties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#13608#35994#35995","text":"All of the above#180 days in jail#A fine of up to $5,000#License suspension for one year"},
{"testId":1061,"qid":38711,"description":"Which of the following scenarios could result in a teen's driver license being suspended or postponed?","explanation":null,"imageName":"","correctAnswer":44220,"isBookmarked":0,"aid":"897#23116#33112#44220","text":"None of the above#Suspension from school#Poor grades in school#Using a handheld or hands-free cellular telephone, when behind the wheel"},
{"testId":1061,"qid":38715,"description":"If you are found guilty of racing on a public road in the state of New Jersey, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":38718,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":"If you are guilty of tailgating, you will get five points on your license. The maximum amount of points you may get for a single transgression is five. [The New Jersey Driver Manual, Chapter 7: Driver Privileges and Penalties, Moving Violation Point Chart]","imageName":"202006041812161292.jpg","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1062,"qid":38723,"description":"A driver's license in New Mexico is valid for four or _______ years.","explanation":"Normally, a New Mexico operator license is good for four years. You do, however, have the option of obtaining an eight-year license if you are neither younger than 21 nor older than 70 years old. You must renew your license every year if you are at least 79 years old. [The Driver License, New Mexico Driver Manual, Driver License Renewal]","imageName":"","correctAnswer":5001,"isBookmarked":0,"aid":"2755#2756#5000#5001","text":"ten#twelve#Six#eight"},
{"testId":1062,"qid":38724,"description":"Your New Mexico driver's license can be renewed up to ____ days before or ____ days after it expires.","explanation":"A New Mexico operator license may be renewed 90 days before or 30 days after it expires. [The Driver License, New Mexico Driver Manual, Driver License Renewal]","imageName":"","correctAnswer":13612,"isBookmarked":0,"aid":"13018#13020#13612#13613","text":"60; 90#30; 120#90; 30#60; 30"},
{"testId":1062,"qid":38726,"description":"Which of the following would NOT normally result in the loss of your driving privileges?","explanation":"Giving false information on your application for an operator licence, using someone else's operator licence to buy alcohol, and allowing someone else to use your operator licence are just a few of the many offences that may result in you losing your driving rights. Your driving privileges won't be suspended for a single speeding ticket. However, up to eight points may be added to your driving record depending on where you were speeding and how fast you were going. Your operator licence will be suspended if you get 12 or more points in a calendar year. Traffic Infractions, Rules of the Road, and Maintaining Your Driver's License are all topics covered in the New Mexico Driver Manual.","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#37457#37458#37459","text":"Speeding#Providing false information on your application for a driver license#Using someone else's driver license to purchase alcohol#Letting someone else use your driver license"},
{"testId":1062,"qid":38727,"description":"The turning radius of a street-legal motorcycle in New Mexico must not exceed:","explanation":null,"imageName":"","correctAnswer":36259,"isBookmarked":0,"aid":"14176#36259#36261#36478","text":"10 feet#14 feet#18 feet#12 feet"},
{"testId":1062,"qid":38728,"description":"As a result of your conviction for riding a motorcycle that is not street legal, you might face which of the following penalties?","explanation":null,"imageName":"","correctAnswer":37206,"isBookmarked":0,"aid":"37206#37207#37554#37555","text":"Both (a) and (b)#Neither (a) nor (b)#(a) A $300 fine#(b) Jail for 90 days"},
{"testId":1062,"qid":38730,"description":"A person can become legally intoxicated with as few as _____ alcoholic drinks.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5002#6209","text":"Two#Four#Five#Three"},
{"testId":1062,"qid":38735,"description":"Your operator's license (car or motorbike) will be suspended for _______ if you refuse a chemical test for alcohol or drugs when requested by law authorities.","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"23153#23883#23886#26650","text":"6 months#10 days#30 days#One year"},
{"testId":1062,"qid":38736,"description":"First-time DWI offenders face which penalties?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#18218#26643#26994","text":"All of the above#Jail#A fine#Loss of driving privileges"},
{"testId":1062,"qid":38737,"description":"Which of the following has the power to suspend your driver's licence in the event that you are found guilty of DWI?","explanation":"All license revocations and suspensions in New Mexico are handled by the Motor Vehicle Division (MVD). Revocations and suspensions are administrative sanctions rather than criminal consequences. Whether or whether you are convicted in court, the MVD has the ability to cancel your license. [Alcohol and the Law, Drinking and Driving, Driving in Shape, New Mexico Driver Manual]","imageName":"","correctAnswer":37466,"isBookmarked":0,"aid":"34469#37466#37467#37468","text":"The courts#The Motor Vehicle Division (MVD)#The state police#Your automobile insurer"},
{"testId":1062,"qid":38740,"description":"Before obtaining a driver's license, a person with epilepsy must be seizure-free for ___ months.","explanation":"Before obtaining an operator license, a person with epilepsy must be stable and seizure free for six months. [Health, Driving Fitness, New Mexico Driver Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1062,"qid":38741,"description":"Is it legal in New Mexico to use a portable cell phone while driving if you have an unrestricted operator's license (car or motorcycle)?","explanation":null,"imageName":"","correctAnswer":37472,"isBookmarked":0,"aid":"21234#37470#37471#37472","text":"Yes#No, it's prohibited by state law#Yes, except under poor driving conditions#Yes, except in certain cities"},
{"testId":1062,"qid":38743,"description":"Who is required to carry vehicle liability insurance or the equivalent in New Mexico?","explanation":null,"imageName":"","correctAnswer":37477,"isBookmarked":0,"aid":"37477#37478#37479#37480","text":"All vehicle owners#Only vehicle owners under the age of 25#Only vehicle owners under the age of 18#Only the owners of vehicles that are less than ten years old"},
{"testId":1062,"qid":38747,"description":"Which of the following infractions results in fewer than six points?","explanation":null,"imageName":"","correctAnswer":37557,"isBookmarked":0,"aid":"11621#37496#37556#37557","text":"Reckless driving#Passing a stopped school bus that is picking up or discharging passengers#Drag racing on a public road#Speeding at 40 mph in a school zone where the speed limit is 15 mph"},
{"testId":1062,"qid":38748,"description":"While you possess a motorcycle instruction permit, you must ride under the supervision of an adult who:","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35002#35005#37558#37559","text":"is at least 21 years of age#meets all of the above requirements#is a licensed motorcyclist#follows you as you ride"},
{"testId":1062,"qid":38749,"description":"To apply for an instructional permit or operator license if you are under the age of _____, you must obtain signed approval from your parent or guardian.","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"11359#11360#11361#11696","text":"18#21#16#19"},
{"testId":1062,"qid":38750,"description":"Who is required to wear a helmet under New Mexico's motorcycle helmet law?","explanation":null,"imageName":"","correctAnswer":34579,"isBookmarked":0,"aid":"34577#34578#34579#34580","text":"Riders and passengers#Passengers but not riders#Riders under 18 and passengers under 18#Passengers under 18 but not riders under 18"},
{"testId":1062,"qid":38751,"description":"When riding a motorcycle without a windshield in New Mexico, _________ must wear eye protection.","explanation":null,"imageName":"","correctAnswer":35550,"isBookmarked":0,"aid":"35550#35551#35552#35553","text":"only the rider#only the passenger#both the rider and the passenger#neither the rider nor the passenger"},
{"testId":1062,"qid":38752,"description":"To earn a motorcycle endorsement, which of the following is NOT required?","explanation":null,"imageName":"","correctAnswer":37560,"isBookmarked":0,"aid":"37560#37561#37562#37563","text":"Holding an unrestricted (adult) automobile driver license#Being at least 13 years of age#Passing a motorcycle road test or the MSF Basic RiderCourse#Providing proof of your identity"},
{"testId":1062,"qid":38753,"description":"You are not permitted to transport a passenger on your motorcycle if:","explanation":"You may not carry a passenger on your motorbike in New Mexico if you have either (a) an instructional permit with a motorcycle endorsement or (b) an initial licensee (i.e., you hold a license that you obtained when you were younger than 18). [\"Chapter 2: Non-Commercial License,\" New Mexico Division of Motor Vehicles, http://www.mvd.newmexico.gov/driver-procedures-manual.aspx?1f7fcb5548ee4e17a47ff4e27e571a01blogPostId=ad2b66b92cf04b93a71b9c884a3dd3c5#/","imageName":"","correctAnswer":37566,"isBookmarked":0,"aid":"37564#37565#37566#37567","text":"(a) you hold an instructional permit with a motorcycle endorsement#(b) you hold a car or motorcycle license that you obtained when you were younger than 18#either (a) or (b) is true#neither (a) nor (b) is true"},
{"testId":1062,"qid":38754,"description":"Which of the following requires a motorcycle license or endorsement in New Mexico?","explanation":null,"imageName":"","correctAnswer":37207,"isBookmarked":0,"aid":"37206#37207#37568#37569","text":"Both (a) and (b)#Neither (a) nor (b)#(a) Autocycles#(b) Mopeds"},
{"testId":1062,"qid":38755,"description":"Which of the following is NOT necessary on a street-legal motorcycle in New Mexico?","explanation":null,"imageName":"","correctAnswer":1424,"isBookmarked":0,"aid":"1424#37570#37571#37572","text":"Turn signals#Mirror#Headlight#Brake light"},
{"testId":1062,"qid":38756,"description":"Second-time DWI offenders face up to _____聽in jail.","explanation":null,"imageName":"","correctAnswer":37488,"isBookmarked":0,"aid":"37487#37488#37489#37490","text":"729 days#364 days#179 days#89 days"},
{"testId":1062,"qid":38757,"description":"In recent years, around _____ % of motorcyclists killed in single-vehicle accidents were legally inebriated.","explanation":"In 2015, for example, over 42% of motorcyclists killed in single-vehicle collisions had a blood alcohol concentration (BAC) of 0.08 percent or more, which is beyond the legal limit in all US states. In the same year, 27 percent of all motorcycle fatalities had a BAC of 0.08 percent or above. [National Highway Traffic Safety Administration, Traffic Safety Facts, May 2015, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/812353]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6477#11361#11513#12040","text":"30#16#40#24"},
{"testId":1062,"qid":38758,"description":"The Department of Transportation may send you a warning letter if you acquire at least ___ points.","explanation":"If you acquire at least six points, the Department of Transportation may issue you a warning letter. If you acquire twelve or more points in a twelve-month period, your operator license will be suspended for one year. [NMAC 18.19.5 .53]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1062,"qid":38759,"description":"Which of the following infractions has the fewest points?","explanation":"Overloading a vehicle with freight or people results in a two-point penalty. Each of the other three options below is worth three points. [New Mexico Driver Manual], [Traffic Violations, Rules of the Road], [NMAC 18.19.5] .52]","imageName":"","correctAnswer":37573,"isBookmarked":0,"aid":"37573#37574#37575#37576","text":"Overloading your vehicle with too much cargo#Failing to yield the right-of-way when required to do so#Passing in a no-passing zone#Speeding at 36 mph in a residential area where the speed limit is 30 mph"},
{"testId":1062,"qid":38761,"description":"If you drive with a suspended or revoked license, you face up to ____聽in jail.","explanation":null,"imageName":"","correctAnswer":37488,"isBookmarked":0,"aid":"23886#24049#37488#37489","text":"30 days#90 days#364 days#179 days"},
{"testId":1062,"qid":38762,"description":"You must file a written accident report if you are involved in an accident that causes at least $500 in property damage.","explanation":null,"imageName":"","correctAnswer":37498,"isBookmarked":0,"aid":"37498#37499#37500#37501","text":"within 5 days#within 10 days#within 30 days#within 45 days"},
{"testId":1062,"qid":38763,"description":"If you are involved in an accident that causes bodily harm to another person, you must tell the authorities.","explanation":null,"imageName":"","correctAnswer":26708,"isBookmarked":0,"aid":"26708#37498#37499#37500","text":"immediately#within 5 days#within 10 days#within 30 days"},
{"testId":1062,"qid":38764,"description":"Third-time DWI offenders must spend a minimum______聽in jail.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#35988#35989#37577","text":"30 days#7 days#14 days#4 days"},
{"testId":1062,"qid":38765,"description":"Your license will be suspended for ______, if you accumulate 12 or more points within a one-year period on your driving record.","explanation":"If you acquire twelve or more points in a twelve-month period, your operator license will be suspended for one year. [Traffic Violations, Road Rules, New Mexico Driver Manual]","imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"20788#23152#23153#26650","text":"2 years#3 months#6 months#One year"},
{"testId":1062,"qid":38767,"description":"Your driving record would聽have points added聽if you are found guilty of the following infraction:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#11944#37505#37578","text":"Any of the above#Speeding#failing to yield the right-of-way#giving the wrong signal before turning"},
{"testId":1063,"qid":38773,"description":"Which of the following drugs may impair your driving abilities?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#9802#25099#45070","text":"All of the above#Prescription drugs#Marijuana#Cocaine"},
{"testId":1063,"qid":38774,"description":"You should ________, if you are taking any over-the-counter medications.","explanation":null,"imageName":"","correctAnswer":45072,"isBookmarked":0,"aid":"45072#45133#45134#45135","text":"read the label about the side effects of the medication#ride below the speed limit#ride only during the daylight hours#avoid riding a motorcycle"},
{"testId":1063,"qid":38777,"description":"If you receive a traffic penalty in Connecticut or New Jersey,:","explanation":"The state of New York is a signatory to the Non-Resident Violator Compact. You may return to New York State without posting bond if you get a traffic penalty in another member state and deal with the charge later. If you do not respond to the out-of-state citation, New York State may suspend your driving license until you do. The Non-Resident Violator Compact includes all US states except Alaska, California, Michigan, Montana, Oregon, and Wisconsin.","imageName":"","correctAnswer":38316,"isBookmarked":0,"aid":"38316#38317#38318#38319","text":"you may return to New York State and answer the citation later#your vehicle may be impounded until you answer the citation#your license may be confiscated until you answer the citation#you may have to post bail until you answer the citation"},
{"testId":1063,"qid":38780,"description":"What does chemical test measure?","explanation":"A chemical test is done to determine your blood alcohol level (BAC).","imageName":"","correctAnswer":45064,"isBookmarked":0,"aid":"10667#21613#45064#45065","text":"Reaction time#Speed#Blood alcohol content#Driving ability"},
{"testId":1063,"qid":38781,"description":"Riding under the influence of alcohol or drugs may result in ___________ in New York State.","explanation":null,"imageName":"","correctAnswer":971,"isBookmarked":0,"aid":"971#40054#45081#45082","text":"any or all of the above#a fine#loss of your driver license#a jail sentence"},
{"testId":1063,"qid":38782,"description":"Which of the following offenses can result in arrest in New York State?","explanation":"You can be arrested in New York State for any of the following offenses: aggravated driving while intoxicated (Agg-DWI), driving while intoxicated (DWI), driving with a blood alcohol content (BAC) of 0.08 percent or more (.08 BAC), driving while ability impaired by a drug (DWAI-drug), driving while ability impaired by alcohol (DWAI), or driving under the combined influence of alcohol and drugs. You may be arrested for DWAI if your BAC is more than 0.05 percent but less than 0.07 percent, even though that amount is inadequate to be charged with DWI.","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#38327#38328#38329","text":"Any of the above#Driving with a blood alcohol content (BAC) of 0.08% or more#Driving while intoxicated (DWI)#Driving while ability impaired (DWAI)"},
{"testId":1063,"qid":38784,"description":"A Canadian traffic ticket won't affect your driving record unless it was issued in:","explanation":null,"imageName":"","correctAnswer":45083,"isBookmarked":0,"aid":"45083#45084#45085#45086","text":"Ontario or Quebec#New Brunswick or Quebec#Newfoundland or Ontario#Nova Scotia or Quebec"},
{"testId":1063,"qid":38785,"description":"You've had a few drinks and are ready to go home. What should you do?","explanation":null,"imageName":"","correctAnswer":45087,"isBookmarked":0,"aid":"45087#45088#45089#45090","text":"Ask a friend who hasn't been drinking to give you a ride#Have two cups of coffee before you drive#Wait a half hour before you drive#Drive slower than the posted speed limit"},
{"testId":1063,"qid":38786,"description":"If you're found guilty of breaking the state's helmet law:","explanation":null,"imageName":"","correctAnswer":38488,"isBookmarked":0,"aid":"38486#38487#38488#38489","text":"(a) you may have to pay a fine#(b) you may have to go to jail#(a) or (b)#neither (a) nor (b)"},
{"testId":1063,"qid":38787,"description":"If you are engaged in a traffic accident, you must file an accident report with the DMV if any one person's property was damaged for at least .","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":1063,"qid":38791,"description":"In New York, the handlebars of your motorcycle must be no higher than:","explanation":"Handlebars that raise your hands over shoulder height (\"ape hangers\") are illegal in New York State. [New York State DMV Motorcycle Manual] [Motorcycle Licenses, Ownership, and Special Rules]","imageName":"","correctAnswer":35291,"isBookmarked":0,"aid":"35289#35290#35291#35292","text":"elbow height#chest height#shoulder height#waist height"},
{"testId":1063,"qid":38792,"description":"As per the New York State DMV Motorcycle Manual, _____ of all motorcycle crash fatalities involved alcohol.","explanation":"According to the New York State Motorcycle Manual, alcohol was consumed by 40 to 45 percent of all motorcyclists died in motorcycle accidents. One-third of these motorcyclists had a blood alcohol content (BAC) that was higher than the legal limit. [New York State DMV Motorcycle Manual, In Shape to Ride]","imageName":"","correctAnswer":43812,"isBookmarked":0,"aid":"43810#43811#43812#45136","text":"10鈥15%#20鈥25%#40鈥45%#50鈥55%"},
{"testId":1063,"qid":38793,"description":"You're driving on an expressway with the intention of exiting. When should you begin to indicate your plan to leave?","explanation":null,"imageName":"","correctAnswer":45094,"isBookmarked":0,"aid":"45094#45095#45096#45097","text":"At least 100 feet before the exit ramp#At least 200 feet before the exit ramp#At the exit ramp#At least 50 feet before the exit ramp"},
{"testId":1063,"qid":38794,"description":"When operating a motorcycle in New York State, ______ must wear eye protection.","explanation":"Even if the motorbike has a windshield, all motorcycle operators (but not their passengers) are required by New York State law to wear authorized eye protection. Eye protection in New York State includes goggles, face shields, prescription glasses, and made-to-order safety glasses. [New York State DMV Motorcycle Manual] [Motorcycle Licenses, Ownership, and Special Rules]","imageName":"","correctAnswer":45137,"isBookmarked":0,"aid":"45137#45138#45139#45140","text":"the motorcyclist but not the passenger#the passenger but not the motorcyclist#both the motorcyclist and the passenger#neither the motorcyclist nor the passenger"},
{"testId":1063,"qid":38796,"description":"Which of the following statements concerning driving impaired is true?","explanation":null,"imageName":"","correctAnswer":28163,"isBookmarked":0,"aid":"28163#45141#45142#45143","text":"All of the above statements are true#Every impaired rider is a threat to the safety of others#An impaired rider poses a risk to his own life#Safe riding is impossible if the rider is under the influence of alcohol or drugs"},
{"testId":1063,"qid":38797,"description":"Which of the following is NOT TRUE about minimum speeds?","explanation":null,"imageName":"","correctAnswer":45106,"isBookmarked":0,"aid":"45106#45107#45108#45109","text":"You do not have to obey minimum speed limit signs#Driving below the posted minimum speed limit can be as dangerous as exceeding the posted maximum speed limit#Driving below the posted minimum speed limit can interfere with the flow of traffic#You can get a ticket for driving below the posted minimum speed limit"},
{"testId":1063,"qid":38799,"description":"After only _____, your blood alcohol content (BAC) can reach 0.02%.","explanation":"After just one alcoholic drink, you may attain a BAC of 0.02 percent. Your BAC rises when you lose weight.","imageName":"","correctAnswer":38355,"isBookmarked":0,"aid":"38354#38355#38356#38357","text":"three drinks#one drink#two drinks#four drinks"},
{"testId":1063,"qid":38801,"description":"You are not permitted to park within ______ of a fire hydrant a licensed motorcyclist remains with the motorcycle.","explanation":null,"imageName":"","correctAnswer":8416,"isBookmarked":0,"aid":"1027#4582#8416#8417","text":"25 feet#20 feet#15 feet#30 feet"},
{"testId":1063,"qid":38802,"description":"If you pass a stopped school bus_______ or more times聽in three years, your license will be withdrawn for at least聽six months.","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1063,"qid":38804,"description":"Is it legal to park in a crosswalk?","explanation":null,"imageName":"","correctAnswer":18118,"isBookmarked":0,"aid":"18118#32812#45144#45145","text":"No#Yes, but only in rural areas#Yes, but only with your headlight on#Yes, but only if there are no pedestrians around"},
{"testId":1063,"qid":38806,"description":"If your motorcycle's liability insurance policy has expired, you must:","explanation":null,"imageName":"","correctAnswer":38493,"isBookmarked":0,"aid":"38371#38493#38494#38495","text":"hand over the keys to a police officer#stop riding the motorcycle#return the motorcycle's license plate to the DMV#only ride the motorcycle late at night"},
{"testId":1063,"qid":38807,"description":"In the event of a traffic collision, if you do not file an accident report, your driving privileges will be suspended:","explanation":null,"imageName":"","correctAnswer":38375,"isBookmarked":0,"aid":"38372#38373#38374#38375","text":"for at least one year#for at least three months#until you contact the other drivers#until you file it"},
{"testId":1063,"qid":38810,"description":"The number of points you receive for a traffic violation depends on:","explanation":null,"imageName":"","correctAnswer":38384,"isBookmarked":0,"aid":"38384#38385#38386#38387","text":"the date of the violation#the date that you receive your court notice#the date of conviction#the first day of the month in which the violation occurred"},
{"testId":1063,"qid":38813,"description":"If you are found to be riding an uninsured motorcycle, your license will be suspended for at least one year.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23154#25170#27363","text":"2 years#1 year#1 month#2 months"},
{"testId":1063,"qid":38814,"description":"Which of the following is NOT a way to keep from getting tired on long trips?","explanation":null,"imageName":"","correctAnswer":45055,"isBookmarked":0,"aid":"45052#45055#45146#45147","text":"Get enough sleep beforehand#Take medications to stay awake#Stop for a break every two hours#Ride no more than six hours per day"},
{"testId":1063,"qid":38815,"description":"You've come to a halt at a red traffic light, and it appears that your motorcycle has not activated the light sensor. When are you legally permitted to proceed?","explanation":"Over a dozen US states have already implemented \"dead red\" rules, which enable motorcyclists to ride through a red light if the light sensor on their motorbike fails to activate. Regrettably, New York State is not one of them.","imageName":"","correctAnswer":38460,"isBookmarked":0,"aid":"37206#38458#38459#38460","text":"Both (a) and (b)#(a) After waiting for 120 seconds#(b) After waiting for two light cycles#If some other vehicle triggers the light sensor for you"},
{"testId":1063,"qid":38817,"description":"Motorcycle accidents involving substance usage result in ____ injuries.","explanation":"In traffic accidents involving drug misuse, 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive incidents. Every year, over 2,100 motorcyclists are killed and another 50,000 are badly wounded in this sort of incident. [New York State DMV Motorcycle Manual, In Shape to Ride]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1063,"qid":38818,"description":"To register a vehicle in New York State, you must be at least ____ years old.","explanation":null,"imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":1063,"qid":38819,"description":"Which of the following is required as proof of insurance in New York State?","explanation":"When you get liability insurance for your automobile, the insurer will provide you two insurance identification cards. Each card includes your name, vehicle identification number, insurance policy number, and expiry date. When you register your vehicle, submit one card to the DMV and keep the other with you at all times.","imageName":"","correctAnswer":38397,"isBookmarked":0,"aid":"234#38396#38397#45148","text":"Any of the above#A receipt from your insurance agent#An insurance identification card#Your license"},
{"testId":1063,"qid":38820,"description":"Which of the following must be true in New York State in order to transport a passenger on your motorcycle?","explanation":"A passenger of any age may ride a motorbike in New York State as long as he or she can wear an appropriate helmet. It should be noted, however, that if a very small passenger cannot reach the foot pegs with his or her legs, the youngster should not ride. [New York State DMV Motorcycle Manual], [\"Child Passengers on Motorcycles,\" New York State Department of Motor Vehicles, http://nysdmv.custhelp.com/app/answers/detail/a id/830//child-passengers-on-motorcycles]","imageName":"","correctAnswer":45150,"isBookmarked":0,"aid":"37206#37207#45149#45150","text":"Both (a) and (b)#Neither (a) nor (b)#(a) He or she must be at least 12 years old#(b) He or she must wear a helmet"},
{"testId":1063,"qid":38821,"description":"Your motorcycle's registration must be renewed:","explanation":"Motorcycles, unlike most other vehicles, are registered for one year rather than two, and all motorbike registrations expire on April 30. You may renew your license by mail or in person at a DMV location. If you haven't changed your postal address in the recent two years, you may renew your registration online at the DMV's \"Renew a registration\" website, https://dmv.ny.gov/registration/renew-registration.","imageName":"","correctAnswer":28051,"isBookmarked":0,"aid":"28051#32049#35461#38496","text":"every year#every five years#every two years#when your motorcycle license expires"},
{"testId":1063,"qid":38822,"description":"What is the most common cause of motorcycle accidents involving other vehicles?","explanation":null,"imageName":"","correctAnswer":45098,"isBookmarked":0,"aid":"45098#45099#45100#45101","text":"A vehicle entering the motorcycle's right-of-way#A vehicle tailgating the motorcycle#A vehicle being tailgated by the motorcycle#A vehicle driving too fast for the conditions"},
{"testId":1064,"qid":38830,"description":"Your automobile liability insurance policy in North Carolina must have at least _______ in coverage for injuries to one person in any one accident.","explanation":null,"imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11659#12047#12925","text":"$30,000#$20,000#$15,000#$60,000"},
{"testId":1064,"qid":38833,"description":"If you're convicted of DWI twice in three years, your license will be revoked for _____ years.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1064,"qid":38837,"description":"You may not drive with a blood alcohol concentration (BAC) of ____ or higher after regaining your driving privileges following your second DWI conviction.","explanation":null,"imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23318#27697#27698#31711","text":"0.02%#0.06%#0.04%#Zero"},
{"testId":1064,"qid":38838,"description":"If you are convicted of DWI with a BAC of 0.15 percent or above, you will be obliged to:","explanation":null,"imageName":"","correctAnswer":38631,"isBookmarked":0,"aid":"10656#38629#38630#38631","text":"Do all of the above#purchase additional liability insurance#be supervised whenever you drive#install an ignition interlock device"},
{"testId":1064,"qid":38851,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":null,"imageName":"202006041812161292.jpg","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1064,"qid":38867,"description":"Who is required to wear a helmet while riding a motorcycle in North Carolina?","explanation":null,"imageName":"","correctAnswer":34577,"isBookmarked":0,"aid":"34577#34578#38725#38726","text":"Riders and passengers#Passengers but not riders#Riders under 21 and passengers under 21#Passengers under 21 but not riders under 21"},
{"testId":1064,"qid":38868,"description":"If it is established in court that the passenger with whom you were riding was not wearing a helmet,:","explanation":null,"imageName":"","correctAnswer":38728,"isBookmarked":0,"aid":"38727#38728#38729#38730","text":"(a) your passenger will have to pay a fine#(b) you will have to pay a fine#(c) two points will be added to your driving record#(b) and (c)"},
{"testId":1064,"qid":38869,"description":"According to North Carolina law, ___________ must wear eye protection.","explanation":"Eye protection is not required by North Carolina law for either riders or passengers. Wearing eye protection (such as goggles) to protect your eyes from the wind and particles carried by the wind is still a good idea. In fact, wearing facial protection (such as a shatter-resistant face shield) will protect your eyes as well as the rest of your face.","imageName":"","correctAnswer":34584,"isBookmarked":0,"aid":"34581#34582#34583#34584","text":"riders and passengers#passengers but not riders#riders but not passengers#neither riders nor passengers"},
{"testId":1065,"qid":38879,"description":"If you successfully complete an approved driver improvement course, you will have ___ points deducted from your driving record.","explanation":"Three points will be deducted from your driving record if you successfully complete an authorized driver improvement course. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1065,"qid":38883,"description":"As per the North Dakota Motorcycle Operator Manual, about _____ of motorcyclists killed in traffic accidents had consumed alcohol before to the crashes.","explanation":"According to the North Dakota Motorcycle Operator Manual, 29 percent of fatally injured motorcyclists had alcohol concentrations (ACs) more than 0.08 percent, indicating that they were legally under the influence of alcohol at the time. An additional 8% had lower AC levels, suggesting that even little doses of alcohol had a role in their accidents. [The Importance of This Information; Getting in Shape to Ride; North Dakota Motorcycle Operator Manual]","imageName":"","correctAnswer":38914,"isBookmarked":0,"aid":"38911#38912#38913#38914","text":"13%#17%#27%#37%"},
{"testId":1065,"qid":38887,"description":"What effect does alcohol have on your riding abilities?","explanation":"Alcohol slows down your reflexes, blurs your eyesight, clouds your judgement, hinders concentration, and gives you a false feeling of self-assurance. Your ability to safely ride a motorbike may be hampered by all of these side effects. [Manual for Noncommercial Drivers in North Dakota; Effects of Alcohol; Drinking and Driving]","imageName":"","correctAnswer":26434,"isBookmarked":0,"aid":"26434#38818#38819#38820","text":"It does all of the above#It slows your reaction time#It impairs your vision#It dulls your judgment"},
{"testId":1065,"qid":38888,"description":"By riding on public highways in North Dakota, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8626#29272#35227#35436","text":"None of the above#a search of your home#release of your medical records#chemical testing for alcohol or drugs"},
{"testId":1065,"qid":38889,"description":"If you refuse to submit to chemical testing when a law enforcement officer requests it, your license will be suspended for at least :","explanation":"If you refuse to submit to alcohol or drug testing, your license could be suspended for 180 days to three years. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"20788#23154#24049#25858","text":"2 years#1 year#90 days#180 days"},
{"testId":1065,"qid":38890,"description":"If you refuse to submit to chemical testing at the request of a law enforcement officer, your license will be canceled for the duration of _______for the investigation.","explanation":"If you refuse to submit to alcohol or drug testing, your license could be suspended for 180 days to three years. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1065,"qid":38917,"description":"________ points will be added to your driving record if you are found guilty of riding your motorcycle in the middle of two lanes of traffic.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1065,"qid":38918,"description":"You may renew your North Dakota driver's license without penalty up to ___ months before the expiration date.","explanation":"You may renew your North Dakota driver's license up to ten months before it expires without losing any time left on your current license. [North Dakota Noncommercial Drivers License Manual; Renewals]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6389#6916#11342","text":"10#3#6#9"},
{"testId":1065,"qid":38919,"description":"If you're convicted of carrying a minor without a helmet, you'll get __ points in your record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1065,"qid":38920,"description":"If you are involved in a traffic accident that causes total damages of ______ or more, you must notify law police promptly.","explanation":"If you are engaged in a traffic collision that causes injury, death, or total damages of $1,000 or more, you must notify police authorities promptly. [North Dakota Noncommercial Drivers License Manual; Crash Reports]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11986#12395","text":"$500#$1,000#$750#$250"},
{"testId":1065,"qid":38921,"description":"All motorcyclists in North Dakota are required to have :","explanation":"Everyone who uses a motor vehicle in North Dakota is required by law to carry a valid liability insurance coverage. You may be punished and your license may be revoked if you are convicted of driving without liability insurance. Note: The national Affordable Care Act (ACA) required all Americans under the age of 65 to obtain health insurance or face a penalty, but the Tax Cuts and Jobs Act of 2017 eliminated this requirement. [North Dakota Noncommercial Drivers License Manual; Crash Reports]","imageName":"","correctAnswer":38844,"isBookmarked":0,"aid":"8615#29253#38844#38845","text":"All of the above#health insurance#liability vehicle insurance#life insurance"},
{"testId":1065,"qid":38922,"description":"If you are convicted of driving without liability insurance for the second time in North Dakota, you must pay a fine of :","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"23887#23889#24978#33102","text":"$500#$1,000#$100#$300"},
{"testId":1066,"qid":38923,"description":"When riding a motorcycle without a windscreen in Ohio, you must wear eye protection. If you are convicted of breaking this statute, you could be fined ____ if you haven't had any previous traffic offenses in the last year.","explanation":null,"imageName":"","correctAnswer":12357,"isBookmarked":0,"aid":"11348#11349#11832#12357","text":"$25#$50#$100#$150"},
{"testId":1066,"qid":38924,"description":"When riding a motorcycle without a windscreen in Ohio, you must wear eye protection. If you are convicted of breaking this law after being convicted of another traffic crime within the previous year, you could face a fine of:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24978#24995#27888#33103","text":"$100#$150#$250#$350"},
{"testId":1066,"qid":38925,"description":"Every motorcyclist under the age of 18 in Ohio is required by law to wear a helmet. If a motorcyclist under the age of 18 is guilty of breaching this legislation, he or she may be fined ____ plus court costs if there have been no prior traffic offenses in the previous year.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11349#11832#12357#12398","text":"$50#$100#$150#$75"},
{"testId":1066,"qid":38926,"description":"If you're caught with your motorcycle's handlebars higher than your shoulders, you may be fined:","explanation":null,"imageName":"","correctAnswer":24995,"isBookmarked":0,"aid":"24978#24994#24995#27902","text":"$100#$50#$150#$75"},
{"testId":1066,"qid":38929,"description":"It is prohibited to drive with a blood alcohol content (BAC) of _____ or higher.","explanation":"If your BAC is 0.08 percent or more, your license will be suspended. You will face criminal consequences if you are convicted of OVI. [Operating a Motor Vehicle While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1066,"qid":38931,"description":"If you are caught for suspected OVI and your BAC is 0.08 percent or more, the Registrar of Motor Vehicles will suspend your driver's license for at least :","explanation":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or above, the Registrar of Motor Vehicles will suspend your license for up to three years. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1066,"qid":38932,"description":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or higher, the Registrar of Motor Vehicles may suspend your license for :","explanation":"If you are pulled over for suspected OVI and your blood alcohol content (BAC) is 0.08 percent or above, the Registrar of Motor Vehicles will suspend your license for up to three years. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1066,"qid":38933,"description":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driver's license for at least:","explanation":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your license for one to five years, even if you are later cleared of OVI in court. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Digest of Ohio Motor Vehicle Laws], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/sites/default/files/pdfs/ovichart.pdf], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/ .pdf]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1066,"qid":38934,"description":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driver's license for up to :","explanation":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your license for one to five years, even if you are later cleared of OVI in court. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Digest of Ohio Motor Vehicle Laws], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/sites/default/files/pdfs/ovichart.pdf], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/ .pdf]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"26672#29894#29895#39071","text":"5 years#7 years#10 years#9 years"},
{"testId":1066,"qid":38955,"description":"If you lend your license to a minor to buy alcohol, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#34985","text":"$500#$1,000#$2,000#$1,200"},
{"testId":1066,"qid":38956,"description":"You may be fined of ________, if you are found guilty of breaking the state's noise regulations for motorcycles.","explanation":null,"imageName":"","correctAnswer":24995,"isBookmarked":0,"aid":"24978#24993#24994#24995","text":"$100#$25#$50#$150"},
{"testId":1066,"qid":38960,"description":"Which of the following licenses allows you to lawfully ride a moped in Ohio?","explanation":"In Ohio, you must have a driving license (class A, B, C, or D), a motorcycle-only license, or a motorized bicycle license to ride a motorized bicycle or moped. [Motorcycles; 4 - Motorcycle/Scooter & Motorized Bicycle Regulations; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#37412#39144#39145","text":"Any of the above#Driver license#Motorcycle-only license#Motorized bicycle license"},
{"testId":1066,"qid":38963,"description":"You must have held a motorcycle TIPIC for at least ______ before taking the motorcycle skills exam if you want to get a motorcycle-only license.","explanation":"Before taking the motorcycle skills exam, you must have had a motorcycle TIPIC for at least six months if you want to get a motorcycle-only license. [How to Apply for a Motorcycle License; 4 - Motorcycle/Scooter & Motorized Bicycle Regulations; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"739#742#1021#1272","text":"10 days#30 days#1 year#6 months"},
{"testId":1066,"qid":38964,"description":"You may NOT________ ,while holding a motorcycle TIPIC.","explanation":"A motorbike TIPIC permits you to ride a motorcycle under the following conditions: (1) You are required to wear a helmet. (2) You must use protective eyewear. (3) You may only ride during the day. (4) You are not permitted to ride on interstate highways or highly used roads. (5) You are not permitted to transport people. [How to Apply for a Motorcycle License; 4 - Motorcycle/Scooter & Motorized Bicycle Regulations; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#39146#39147#39148","text":"do any of the above#ride at night#ride on interstate highways#carry passengers"},
{"testId":1066,"qid":38965,"description":"If you are at least 18 years old, you can skip taking which of the following if you finish the Motorcycle Ohio Basic Rider Skills course?","explanation":"On the first day of the Motorcycle Ohio Basic Rider Skills course, you must provide a valid TIPIC. Your teacher will provide you a course completion card after you have successfully completed the course. If you are at least 18 years old, bring the card to any BMV deputy registrar licensing agency within 60 days and pay the required price to receive a motorcycle license or endorsement. You will not be required to pass the BMV motorcycle skills exam. [\"ODPS Motorcycle Ohio Basic Rider Skills,\" Ohio Office of Criminal Justice Services, http://www.motorcycle.ohio.gov/basic rider skills], [\"ODPS Motorcycle Ohio Basic Rider Skills,\" Ohio Office of Criminal Justice Services, http://www.motorcycle.ohio.gov/basic rider skills] .stm]","imageName":"","correctAnswer":39149,"isBookmarked":0,"aid":"37206#37207#39149#39150","text":"Both (a) and (b)#Neither (a) nor (b)#(a) The motorcycle skills test#(b) The motorcycle knowledge test"},
{"testId":1066,"qid":38966,"description":"Your vehicle liability insurance policy in Ohio must offer at least ______ in coverage for injuries to one person in any one accident.","explanation":"Your car liability insurance coverage in Ohio must contain the following: (1) at least $25,000 in coverage for one person's injuries in any one accident, (2) at least $50,000 in coverage for all people' injuries in any one accident, and (3) at least $25,000 in property damage coverage in any one accident. All of this applies to both bikes and automobiles. [\"Mandatory Insurance,\" Ohio Bureau of Motor Vehicles, http://bmv.ohio.gov/dl-mandatory-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1066,"qid":38967,"description":"In recent years, around _____ % of motorcyclists killed in single-vehicle accidents were legally inebriated.","explanation":"In 2015, for example, over 42 percent of motorcyclists killed in single-vehicle collisions worldwide had a BAC of 0.08 percent or above, which is beyond the legal limit in most US states, including Ohio. In the same year, 27 percent of all motorcycle fatalities had a BAC of 0.08 percent or above. [National Highway Traffic Safety Administration, Traffic Safety Facts, May 2015, https://crashstats.nhtsa.dot.gov/Api/Public/ViewPublication/812353]","imageName":"","correctAnswer":11513,"isBookmarked":0,"aid":"6477#11361#11513#12040","text":"30#16#40#24"},
{"testId":1066,"qid":38968,"description":"Before taking the motorcycle skills test, a motorcycle TIPIC holder under the age of 18 must complete ___ hours of supervised riding practice.","explanation":"A motorcycle TIPIC holder who is under 18 must complete 50 hours of supervised riding practise before they may take the motorcycle skills exam. His or her parent or guardian must provide a copy of the Fifty Hour Affidavit (Form BMV 5791), which must be duly filled out, signed, and notarized. [Fifty Hour Affidavit, Ohio BMV, http://publicsafety.ohio.gov/links/bmv5791], [Applying for Your Motorcycle License; 4 - Motorcycle/Scooter & Motorized Bicycle Regulations; Digest of Ohio Motor Vehicle Laws] .pdf]","imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"5448#6477#7571#9656","text":"20#30#50#70"},
{"testId":1066,"qid":38969,"description":"Usually, your Ohio driver's license will expire on your _____ birthday after the date it was issued.","explanation":"Your Ohio driver's license will generally expire four years after it was issued. [1 - Driver Licensing & Vehicle Registration (in state); Digest of Ohio Motor Vehicle Laws; Renewing Your License]","imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1066,"qid":38970,"description":"Your Ohio driver's license will be extended to _____ years if you renew it more than 90 days in advance of its expiration.","explanation":"If you renew your Ohio license more than 90 days before it expires, it will expire in three years rather than the normal four. [1 - Driver Licensing & Vehicle Registration (in state); Digest of Ohio Motor Vehicle Laws; Renewing Your License]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5446#5449#6388#6389","text":"10#5#2#3"},
{"testId":1066,"qid":38971,"description":"If you accumulate _____ demerit points in  two-year, your license will be suspended.","explanation":"If you acquire twelve or more penalty points in a two-year period, your license will be suspended for six months, along with severe consequences. [Point Law; 6 - Traffic Laws; Ohio Motor Vehicle Law Digest]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11784","text":"10#12#8#14"},
{"testId":1066,"qid":38972,"description":"Your vehicle liability insurance policy in Ohio must offer at least ______ in property damage coverage in any one accident.","explanation":"Your car liability insurance coverage in Ohio must contain the following: (1) at least $25,000 in coverage for one person's injuries in any one accident, (2) at least $50,000 in coverage for all people' injuries in any one accident, and (3) at least $25,000 in property damage coverage in any one accident. All of this applies to both bikes and automobiles. [\"Mandatory Insurance,\" Ohio Bureau of Motor Vehicles, http://bmv.ohio.gov/dl-mandatory-insurance .aspx]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1067,"qid":38974,"description":"The National Highway Road Safety Administration (NHTSA) reports that around _______ of all motorcyclists killed in traffic accidents in the United States were under the influence of alcohol at the time.","explanation":"According to the National Highway Traffic Safety Administration, about one-fourth of all motorcyclists engaged (killed or survived) in fatal traffic incidents in the United States in 2016 had a blood alcohol level (BAC) of 0.08 percent or above. This suggests they were legally drunk at the time. Furthermore, 37% of motorcyclists killed in single-vehicle accidents were under the influence of alcohol. [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Motorcycles,\" February 2018, https://crashstats.nhtsa.dot.gov/Api/Public/Publication/812492]","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10698#11884#33470#39868","text":"one-half#one-fourth#one-third#three-fourths"},
{"testId":1069,"qid":38974,"description":"The National Highway Road Safety Administration (NHTSA) reports that around _______ of all motorcyclists killed in traffic accidents in the United States were under the influence of alcohol at the time.","explanation":"According to the National Highway Traffic Safety Administration, about one-fourth of all motorcyclists engaged (killed or survived) in fatal traffic incidents in the United States in 2016 had a blood alcohol level (BAC) of 0.08 percent or above. This suggests they were legally drunk at the time. Furthermore, 37% of motorcyclists killed in single-vehicle accidents were under the influence of alcohol. [National Highway Traffic Safety Administration, \"Traffic Safety Facts: Motorcycles,\" February 2018, https://crashstats.nhtsa.dot.gov/Api/Public/Publication/812492]","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10698#11884#33470#39868","text":"one-half#one-fourth#one-third#three-fourths"},
{"testId":1067,"qid":38977,"description":"A driver's license is revoked after being charged with DUI. However, he or she is later cleared in court of the accusation. What happens to the driver's license?","explanation":"A driver's license will be revoked if he or she is charged with DUI. This is a civil punishment, not a criminal penalty. The court's ruling has no bearing on it. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":41644,"isBookmarked":0,"aid":"41644#41645#41646#41647","text":"The license will remain revoked#The license will be reinstated#The revocation period will be shortened#None of the above will happen"},
{"testId":1067,"qid":38979,"description":"If you refuse to submit to chemical testing for alcohol or drugs when a law enforcement officer requests it, your license will be suspended for at least :","explanation":"If you decline to be tested for drugs or alcohol under Oklahoma's Implied Consent statute, your license will be suspended for 180 days to three years. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":1067,"qid":38980,"description":"If you refuse to submit to chemical testing for alcohol or drugs when a law enforcement officer requests it, your license will be suspended for up to :","explanation":"If you decline to be tested for drugs or alcohol under Oklahoma's Implied Consent statute, your license will be suspended for 180 days to three years. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23154#23183#41648","text":"2 years#1 year#3 years#270 days"},
{"testId":1067,"qid":39003,"description":"If you are convicted of using a forged driver's license, you might face up to ______  in state jail.","explanation":null,"imageName":"","correctAnswer":12296,"isBookmarked":0,"aid":"1323#11306#12296#41652","text":"15 years#3 years#7 years#11 years"},
{"testId":1067,"qid":39004,"description":"In the event that you are found guilty of lane-splitting, you might face a fine of up to _______.","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1067,"qid":39006,"description":"If you let a minor ride your motorcycle without a helmet, you could be fined up to ___ for a first offense.","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#11832#12395","text":"$500#$1,000#$100#$250"},
{"testId":1067,"qid":39007,"description":"For which of the following reasons may you be required to attend a driver compliance hearing?","explanation":"If you exhibit risky driving practices, you may be summoned to a Driver Compliance hearing. Careless driving, driving while impaired by or under the influence of alcohol or drugs, and a history of repeated traffic violations are all examples of unsafe driving habits. [Oklahoma Driver's Manual; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#31014#34733#41699","text":"Any of the above#Careless driving#Repeated traffic violations#Riding under the influence of alcohol (DUI)"},
{"testId":1067,"qid":39016,"description":"_______ points will be added to your driving record if you are convicted of operating a motorcycle with a broken taillight.","explanation":"If you are convicted of operating a faulty motorbike, you will get just one point on your driving record. A damaged or otherwise malfunctioning light is an example of such a flaw. [\"Oklahoma Motor Vehicle Violation Codes,\" Department of Public Safety, 1 November 2017, https://ok.gov/dps/documents/VCB 11 2017], [\"Oklahoma Mandatory Point System; Chapter 14: Violations and Penalties; Section 4 Violations and Penalties; Oklahoma Driver's Manual] .pdf]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1067,"qid":39018,"description":"If you allow a passenger under 18 to ride your motorcycle without a helmet twice in a year, you face up to ___ in jail.","explanation":null,"imageName":"","correctAnswer":741,"isBookmarked":0,"aid":"739#741#742#12360","text":"10 days#20 days#30 days#7 days"},
{"testId":1067,"qid":39019,"description":"If you are caught failing to maintain liability insurance on your car, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"24977#24978#24994#27888","text":"$200#$100#$50#$250"},
{"testId":1067,"qid":39020,"description":"If you are found guilty of driving without adequate liability insurance on your vehicle, you face the possibility of receiving a jail sentence of up to .","explanation":null,"imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"227#229#742#18239","text":"180 days#90 days#30 days#14 days"},
{"testId":1067,"qid":39021,"description":"Your vehicle liability insurance policy in Oklahoma must have at least ______ in property damage coverage for any single accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12047#12048","text":"$50,000#$10,000#$15,000#$25,000"},
{"testId":1067,"qid":39022,"description":"Your vehicle liability insurance policy in Oklahoma must offer at least ______ in coverage for the injuries or deaths of two or more people in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12841#12842#13748","text":"$50,000#$100,000#$75,000#$200,000"},
{"testId":1068,"qid":39039,"description":"If you do not file an accident report with the DMV as required, your license will be suspended for up to :","explanation":"According to Oregon law, if the accident resulted in death, injury, property damage over $2,500, or damage to a vehicle surpassing $2,500 and some car being towed, you must submit an accident report with the DMV within 72 hours. If you do not submit the report, your license will be suspended for 5 years or until you file the report, whichever comes first. [Your Duties; Traffic Crashes; Other Important Information; Oregon Driver Manual], [ORS 809.417], [ORS 811.720], [ORS 811] .725]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23154#23183#26672","text":"2 years#1 year#3 years#5 years"},
{"testId":1068,"qid":39040,"description":"When riding a motorcycle without a windshield, _________ is required by Oregon law to wear eye protection.","explanation":"Riders and passengers in Oregon are not required to wear eye protection. Wearing eye protection (such as goggles) to protect your eyes from the wind and particles carried by the wind is still a good idea. In fact, wearing facial protection (such as a shatter-resistant face shield) will protect your eyes as well as the rest of your face.","imageName":"","correctAnswer":35553,"isBookmarked":0,"aid":"35552#35553#39511#39512","text":"both the rider and the passenger#neither the rider nor the passenger#the rider but not the passenger#the passenger but not the rider"},
{"testId":1068,"qid":39041,"description":"Fines of up to _______ may be imposed on anyone who are guilty of riding a motorcycle with an exhaust system that is in violation of state's noise regulations.","explanation":"The amount of noise that a motorcycle's exhaust system may lawfully emit is limited by Oregon law. The following are the restrictions at a distance of 25 feet from the motorcycle: If the motorbike was constructed before 1976, it was 94 dBA, 91 dBA in 1976, and 89 dBA after 1976. Exceeding these restrictions is a Class C offense, punishable by a $165 presumptive punishment or a $500 maximum fine. [OR.153.018], [OR.153.019], [OR.815] .250]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#39536","text":"$500#$1,000#$250#$125"},
{"testId":1068,"qid":39042,"description":"If you are transporting a passenger who is not wearing a helmet,:","explanation":"Motorcycle operators and passengers in Oregon are required by law to wear US DOT-compliant helmets. If your passenger does not wear a helmet, he or she may face a charge of \"failure of a motorcycle passenger to wear a motorcycle helmet.\" You may also be prosecuted with \"endangering a motorbike passenger.\" Both of these infractions are classified as Class D traffic offences, with a probable punishment of $115 and a maximum fine of $250. [ORS 153.018], [ORS 153.019], [ORS 814.275], [ORS 814.275], [ORS 814.275] .280]","imageName":"","correctAnswer":39539,"isBookmarked":0,"aid":"38489#39537#39538#39539","text":"neither (a) nor (b)#(a) you can be fined#(b) your passenger can be fined#both (a) and (b)"},
{"testId":1068,"qid":39047,"description":"Your license will be suspended for an additional _____聽if you fail to install an IID after a first DUII conviction.","explanation":"If you are convicted of your first DUII, the court may order you to install an ignition interlock device (IID) in each car you drive for a year after your driving privileges are restored. (If you are unable to put an IID on your motorbike, you must refrain from riding it throughout the installation time.) If you are convicted of neglecting to install it, your license will be suspended for another year. ORS 813.602 and 813 .620]","imageName":"","correctAnswer":27048,"isBookmarked":0,"aid":"20788#23153#23183#27048","text":"2 years#6 months#3 years#year"},
{"testId":1068,"qid":39048,"description":"After a first DUII conviction, if you are guilty of tampering with an ignition interlock device (IID), your license will be revoked for an extra period of a _____.","explanation":"If you are convicted of your first DUII, the court may order you to install an ignition interlock device (IID) in each car you drive for a year after your driving privileges are restored. (If you are unable to put an IID on your motorbike, you must refrain from riding it throughout the installation time.) If you are convicted of tampering with it after you have installed it, your license will be suspended for another year. ORS 813.602 and 813 .620]","imageName":"","correctAnswer":27048,"isBookmarked":0,"aid":"20788#23153#23183#27048","text":"2 years#6 months#3 years#year"},
{"testId":1068,"qid":39049,"description":"Which of the following offenses has the highest maximum fine?","explanation":"Missing rear-view mirrors, non-working headlights or other lights, and noncompliant exhaust systems are often considered Class C traffic offenses for motorcycles. Class B offenses often include cycling between lanes of traffic (lane splitting) and failing to maintain both hands on the handlebars. The following are the assumed penalty for traffic offences. Class A costs $440, Class B costs $265, Class C costs $165, and Class D costs $115. The following are the maximum penalties for traffic offences. $2,000 for Class A; $1,000 for Class B; $500 for Class C; and $250 for Class D. [ORS 153.018], [ORS 153.019], [ORS 814.240], [ORS 814.240], [ORS 815.235], [ORS 815.250], [ORS 816.320], [ORS 816.320], [ORS 816.320], [ORS 816.320], .330]","imageName":"","correctAnswer":39543,"isBookmarked":0,"aid":"39540#39541#39542#39543","text":"Riding a motorcycle without rear-view mirrors#Riding a motorcycle whose only headlight is broken#Riding a motorcycle whose exhaust system violates Oregon's legal noise limits#Carrying a package with one hand and keeping only the other hand on the handlebars"},
{"testId":1068,"qid":39053,"description":"If you are involved in a crash that results in a vehicle being towed and more than ______  in vehicle damage, you must file an accident report with the DMV.","explanation":"If you are involved in a crash that results in a vehicle being towed and more than $2,500 in vehicle damage, you must file an accident report with the DMV within 72 hours. If there is more than $2,500 in property damage or someone is hurt or killed, you must additionally submit an accident report. [Congestion; Your Responsibilities; Oregon Driver Manual]","imageName":"","correctAnswer":11670,"isBookmarked":0,"aid":"290#11668#11670#39412","text":"$500#$1,500#$2,500#$3,500"},
{"testId":1068,"qid":39057,"description":"Your motorcycle liability insurance policy in Oregon must offer at least ______ in coverage for physical injuries to one person in any one collision.","explanation":"Your motorbike liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; and (4) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. Personal injury protection coverage is necessary for automobiles but not for motorcyclists. [Oregon Driver Manual; Other Information; Mandatory Insurance], [ORS 742] .520]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11658#12047#12048","text":"$5,000#$10,000#$15,000#$25,000"},
{"testId":1068,"qid":39058,"description":"Your motorcycle liability insurance policy in Oregon must have at least ______ in property damage coverage for every single collision.","explanation":"Your motorbike liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; and (4) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. Personal injury protection coverage is necessary for automobiles but not for motorcyclists. [Oregon Driver Manual; Other Information; Mandatory Insurance], [ORS 742] .520]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12048","text":"$5,000#$10,000#$20,000#$25,000"},
{"testId":1068,"qid":39059,"description":"Which of the following penalties may you face if you cause an accident while driving uninsured?","explanation":"Driving without insurance is a Class B traffic offence in Oregon, punishable by a fine of up to $1,000. A police officer may also order the impoundment of your car until you provide evidence of financial responsibility to the DMV. (For the majority of drivers, \"financial responsibility\" takes the form of liability insurance.) If you are involved in an accident while driving uninsured, your license will be revoked until you provide the DMV with evidence of financial responsibility. ORS 806.010, 809.417, and 809.418 .720]","imageName":"","correctAnswer":32601,"isBookmarked":0,"aid":"32601#39418#39419#39420","text":"All of the above may occur#Your vehicle may be impounded#You may have to pay a fine#Your license may be suspended"},
{"testId":1068,"qid":39063,"description":"You will be required to _______, for riding a motorcycle without a motorcycle endorsement.","explanation":"Riding a motorbike without a motorcycle endorsement is a Class A infraction in Oregon, punishable by a fine of up to $2,000. If you are convicted of this violation, the court will temporarily suspend the fine and set a hearing date for 120 days later. The hearing will be held to evaluate whether you have successfully finished an authorized motorcycle education course and acquired a motorcycle endorsement by that time. If you have, the court will dismiss the fine; otherwise, you will be fined. ORS 153.018 and 807. .010]","imageName":"","correctAnswer":37218,"isBookmarked":0,"aid":"37217#37218#39544#39545","text":"do both (a) and (b)#do either (a) or (b)#(a) pay a fine of up to $2,000#(b) obtain a motorcycle endorsement within 120 days"},
{"testId":1068,"qid":39064,"description":"Your motorcycle liability insurance policy in Oregon must have uninsured motorist coverages of at least _________ for bodily harm.","explanation":"Your motorbike liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; and (4) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. Personal injury protection coverage is necessary for automobiles but not for motorcyclists. [Oregon Driver Manual; Other Information; Mandatory Insurance], [ORS 742] .520]","imageName":"","correctAnswer":39426,"isBookmarked":0,"aid":"39424#39425#39426#39427","text":"$10,000 per person and $20,000 per crash#$15,000 per person and $30,000 per crash#$25,000 per person and $50,000 per crash#$20,000 per person and $50,000 per crash"},
{"testId":1068,"qid":39065,"description":"If you are in an accident that causes bodily harm or death, you must file an accident report with the DMV.","explanation":"If you must file an accident report with the DMV, you must do it within 72 hours after the collision. Filing a police report DOES NOT absolve you of the need to submit your own accident report. [Traffic Accidents; Other Information; Oregon Driver Handbook]","imageName":"","correctAnswer":39428,"isBookmarked":0,"aid":"37206#37207#39428#39429","text":"Both (a) and (b)#Neither (a) nor (b)#(a) within 72 hours of the accident#(b) unless a police officer files a police report"},
{"testId":1069,"qid":39073,"description":"To preserve your driving privileges, you must pass a special written point examination if you acquire at least ___ points for the first time.","explanation":"If you acquire at least six points for the first time, PennDOT will need you to pass a special written point assessment. The test will assess your understanding of safe driving habits, departmental punishments, and associated safety problems. If you do not pass the test within 30 days, your license will be suspended until you do. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS .pdf]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1069,"qid":39074,"description":"PennDOT will ________聽if you have accumulated at least six points for the second time.","explanation":null,"imageName":"","correctAnswer":39780,"isBookmarked":0,"aid":"10656#39780#39781#39863","text":"Do all of the above#require you to attend a departmental hearing#require you to pass a special written point examination to keep your driving privileges#suspend your license"},
{"testId":1069,"qid":39075,"description":"A driver under the age of 18 will have his or her driving license suspended if he or she gets at least ___ points.","explanation":"A motorist under the age of 18 will have his or her license suspended for 90 days if he or she gets at least six points. [The Pennsylvania Point System; Chapter 4: Information on Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1069,"qid":39079,"description":"If you're convicted of driving without corrective lenses (eyeglasses or contact lenses) when your license requires them, ___ points will be added to your record.","explanation":"If you are convicted of breaching your license limitations (such as the need to wear corrective glasses), two points will be added to your driving record. The fewest amount of points for any one infraction is two. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1069,"qid":39093,"description":"If you are guilty of exceeding the posted speed limit by ___ mph, PennDOT will summon you to a departmental hearing.","explanation":null,"imageName":"","correctAnswer":13709,"isBookmarked":0,"aid":"5447#11360#13412#13709","text":"15#21#26#31"},
{"testId":1069,"qid":39102,"description":"Which motorcyclists in Pennsylvania are required to have vehicle liability insurance on their registered vehicles?","explanation":"Every vehicle operator in Pennsylvania is required by law to have vehicle liability insurance on his or her registered motor vehicles at all times. If PennDOT discovers that your vehicle was uninsured for more than 31 days, your registration will be suspended for three months. In addition, if PennDOT discovers that you drove the uninsured vehicle for 31 days or more, your license will be suspended for three months. [Driving Without Insurance; The Pennsylvania Point System; Chapter 4: Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":39867,"isBookmarked":0,"aid":"39864#39865#39866#39867","text":"Only motorcyclists under the age of 21#Only motorcyclists under the age of 18#Only motorcyclists operating vehicles that are less than 5 years old#All motorcyclists"},
{"testId":1069,"qid":39103,"description":"If PennDOT discovers that a driver have been riding a motorcycle while it is uninsured, the driver's license will be suspended for:","explanation":"Every vehicle operator in Pennsylvania is required by law to have vehicle liability insurance on his or her registered motor vehicles at all times. If PennDOT discovers that your vehicle was uninsured for more than 31 days, your registration will be suspended for three months. If PennDOT discovers that you drove an uninsured car, your license will be suspended for three months. [Driving Without Insurance; The Pennsylvania Point System; Chapter 4: Driving Records; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#27363#27897","text":"3 months#6 months#2 months#one month"},
{"testId":1069,"qid":39105,"description":"A motorcyclist who is at least 21 years old and has a blood alcohol content (BAC) of ______ or more may be charged with driving while intoxicated (DUI).","explanation":"A driver who is at least 21 years old in Pennsylvania may be charged with driving under the influence of alcohol (DUI) if his or her blood alcohol content (BAC) is 0.08 percent or greater. Even if he or she has a lesser BAC, he or she might still be charged with DUI if stopped by police for driving recklessly. [Driving Under the Influence (DUI); Driving While Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#4251#4252#4653","text":"0.08%#0.02%#0.05%#0.01%"},
{"testId":1069,"qid":39106,"description":"A motorcyclist under the age of 21 who is found to have a blood alcohol content (BAC) of ______ or more may be prosecuted with driving while intoxicated (DUI).","explanation":"A vehicle operator under the age of 21 in Pennsylvania may be prosecuted with driving under the influence of alcohol (DUI) if his or her blood alcohol concentration (BAC) is 0.02 percent or greater. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (One normal drink equals roughly 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) [Driving Under the Influence (DUI); Driving While Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#13791#35229","text":"0.02%#0.01%#0.008%#Zero"},
{"testId":1069,"qid":39107,"description":"To keep your blood alcohol concentration (BAC) from reaching the legal limit of 0.08%, you shouldn't drink more than ____ drink(s) per hour.","explanation":"According to the Pennsylvania Driver's Manual, it takes at least one hour for a person of average weight to digest the alcohol in one normal alcoholic drink. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) As a result, you should not consume more than one drink per hour to keep your blood alcohol concentration (BAC) below the legal limit of 0.08 percent. It should be noted that this does not imply that it is safe to drive after ingesting one drink per hour. Even if your BAC is below the legal limit, your ability to drive safely may be hindered. Furthermore, the lighter you are, the more likely you are to be impacted by a particular quantity of alcohol. [Driving Under the Influence of Alcohol, Driver Factors, Chapter 3: Learning to Drive; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1069,"qid":39108,"description":"In accordance with Pennsylvania's Implied Agree statute, whenever you driving a vehicle in Pennsylvania, you implicitly consent to:","explanation":"By driving a vehicle in Pennsylvania, you imply permission to chemical testing for alcohol and drugs at the request of a police officer, according to Pennsylvania's Implied Consent legislation. [The Implied Consent Law in Pennsylvania (Chemical Testing for Alcohol or Drugs); Driving Under the Influence of Alcohol or a Controlled Substance; Chapter 4: Driving Record Information; Pennsylvania Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1069,"qid":39114,"description":"If a motorcycle rider under the age of 21 is convicted of purchasing, consuming, or possessing alcohol  for the first time, his or her driver's license will be suspended for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":1069,"qid":39115,"description":"If a motorcycle driver under the age of 21 is convicted of buying, consuming, or possessing alcohol for the second time, his or her license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1069,"qid":39116,"description":"A motorcycle driver under 21 convicted of buying, consuming, or possessing alcohol three times will have his or her license revoked for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1069,"qid":39117,"description":"A motorcyclist under the age of 21 who is convicted of purchasing, consuming, or possessing alcohol for the first time may face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#23890#33102","text":"$500#$1,000#$1,500#$300"},
{"testId":1069,"qid":39119,"description":"If you receive a traffic penalty while riding your motorcycle in New Jersey or New York State,:","explanation":null,"imageName":"","correctAnswer":39798,"isBookmarked":0,"aid":"38319#39798#39799#39869","text":"you may have to post bail until you answer the citation#you may return to Pennsylvania and then answer the citation#you will receive an additional citation from Pennsylvania#your motorcycle may be impounded until you answer the citation"},
{"testId":1069,"qid":39120,"description":"If a biker, irrespective of his or her age, accumulates 11 points on his or her driving record for the first time, his or her license will be suspended for a period of:","explanation":"If a driver of any age acquires 11 or more points on his or her driving record for the first time, his or her license will be immediately suspended for five days each point. The timeframe for a second such ban will be 10 days per point. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":39800,"isBookmarked":0,"aid":"23886#24048#27364#39800","text":"30 days#60 days#45 days#55 days"},
{"testId":1069,"qid":39121,"description":"If you commit certain offenses in another state, your Pennsylvania driver's license will be suspended or revoked as if the offense was committed in Pennsylvania. Among these offenses are:","explanation":null,"imageName":"","correctAnswer":8615,"isBookmarked":0,"aid":"8615#30353#39801#39802","text":"All of the above#vehicular manslaughter#driving under the influence (DUI)#hit-and-run accident resulting in injury"},
{"testId":1069,"qid":39122,"description":"Which of the following statements is correct under Pennsylvania law?","explanation":null,"imageName":"","correctAnswer":39871,"isBookmarked":0,"aid":"37206#37207#39870#39871","text":"Both (a) and (b)#Neither (a) nor (b)#(a) Every motorcyclist must wear a helmet while riding a motorcycle#(b) Every motorcyclist must wear eye protection while riding a motorcycle"},
{"testId":1070,"qid":39127,"description":"A motorcycle driver under the age of 18 who is guilty of using a cell phone while riding faces a fine of _____ for the first offense.","explanation":"A person under the age of 18 who is guilty of using a mobile phone (even a hands-free cell phone) while driving will be fined $100 for the first offense. [R. I. G. L. 31-22-11 .9]","imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11832#12357#12358#12395","text":"$100#$150#$200#$250"},
{"testId":1070,"qid":39128,"description":"The National Highway Road Safety Administration (NHTSA) reports that around _______ of all motorcyclists killed in traffic accidents in the United States were under the influence of alcohol at the time.","explanation":"According to the National Highway Traffic Safety Administration, about one-fourth of all motorcyclists engaged (killed or survived) in fatal traffic incidents in the United States in 2016 had a blood alcohol content (BAC) of 0.08 percent or above. This suggests they were legally drunk at the time. Furthermore, 37% of motorcyclists killed in single-vehicle accidents were under the influence of alcohol. [\"Traffic Safety Facts: Motorcycles,\" National Highway Traffic Safety Administration; https://crashstats.nhtsa.dot.gov/Api/Public/Publication/812492]","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10698#11884#33470#39868","text":"one-half#one-fourth#one-third#three-fourths"},
{"testId":1070,"qid":39129,"description":"Which of the following can damage your driving ability?","explanation":"Drugs other than alcohol might also make it difficult for you to drive a car. Marijuana, several prescription drugs, and some over-the-counter medicines may all make it difficult for you to drive a car. You may be charged with DUI if a chemical test reveals that you have any quantity of a restricted drug in your blood (DUI). Rhode Island Driver's Manual, Chapter X, Drinking and Driving, [A. Effect of Alcohol and Other Drugs on the Body], [R. I. G. L. 31-27-2]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#14544#25099#28289","text":"All of the above#Diet pills#Marijuana#Over-the-counter cold medications"},
{"testId":1070,"qid":39131,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"A person of any age is deemed legally inebriated if their blood alcohol content (BAC) is 0.08 percent or greater. If the individual is driving at the moment, he or she may be prosecuted with Driving Under the Influence (DUI). [B. Blood Alcohol Concentration; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4252#11768","text":"0.08%#0.09%#0.05%#0.10%"},
{"testId":1070,"qid":39132,"description":"A driver under the age of 21 who has a blood alcohol content (BAC) of at least ____ but less than ____ will be charged with Driving While Impaired (DWI).","explanation":"A driver under the age of 21 who has a blood alcohol content (BAC) of at least 0.02 percent but less than 0.08 percent will be charged with Driving While Impaired (DWI). It should be noted that most persons will have a BAC of 0.02 percent or more after just one alcoholic drink. As a result, a legal BAC limit of 0.02 percent corresponds to practical zero tolerance. [B. Blood Alcohol Concentration; Rhode Island Driver's Manual; Chapter X. Drinking and Driving]","imageName":"","correctAnswer":5972,"isBookmarked":0,"aid":"5972#40051#40052#40053","text":"0.02%; 0.08%#0.02%; 0.10%#0.04%; 0.12%#0.10%; 0.12%"},
{"testId":1070,"qid":39133,"description":"In accordance with Rhode Island's Implied Submit statute, if you operate a motor vehicle in Rhode Island, you implicitly consent to :","explanation":"By driving a motor vehicle in Rhode Island, you imply permission to chemical testing for alcohol and drugs at the request of a police officer, according to the state's Implied Consent legislation. [C. Implied Consent; Chapter X. Driving While Drunk; Rhode Island Driver's Handbook]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1070,"qid":39134,"description":"With a blood alcohol content (BAC) of at least ___ but less than 0.08%, a motor vehicle operator at least 21 years old may be convicted of driving while impaired (DWI) if there is further proof showing he or she was operating a motor vehicle while impaired.","explanation":"A motorist over the age of 21 who has a blood alcohol content (BAC) of 0.08 percent or above may be guilty of driving under the influence (DUI). Even if the BAC is less than 0.08 percent, the driver may be convicted of driving while intoxicated (DWI) if there is further proof indicating he or she was operating a motor vehicle while under the influence of alcohol. [C. Implied Consent; Chapter X. Driving While Drunk; Rhode Island Driver's Handbook]","imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3170#3282#4251#4252","text":"0.07%#0.06%#0.02%#0.05%"},
{"testId":1070,"qid":39135,"description":"If you refuse a chemical test like a Breathalyzer for the first time, your license will be revoked for at least:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#26650#27363","text":"3 months#6 months#One year#2 months"},
{"testId":1070,"qid":39136,"description":"If you refuse a chemical test for the first time, you may face all of the following penalties. EXCEPT:","explanation":null,"imageName":"","correctAnswer":28960,"isBookmarked":0,"aid":"24892#28960#40054#40055","text":"community service#jail#a fine#alcohol or drug treatment"},
{"testId":1070,"qid":39137,"description":"If you refuse a chemical test like a Breathalyzer for the first time, you could get up to __ hours of community service.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6477#7571#8141#11513","text":"30#50#60#40"},
{"testId":1070,"qid":39139,"description":"If you refuse a chemical test for the third time, you will be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":26650,"isBookmarked":0,"aid":"20789#23152#23153#26650","text":"9 months#3 months#6 months#One year"},
{"testId":1070,"qid":39140,"description":"If you refuse a chemical test for the third time, you must do at least聽___ hours of community service.","explanation":null,"imageName":"","correctAnswer":6591,"isBookmarked":0,"aid":"6356#6591#12996#31870","text":"120#100#150#160"},
{"testId":1070,"qid":39142,"description":"If you refuse a chemical test three times, you could face a fine of:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":1070,"qid":39143,"description":"If a vehicle operator under the age of 18 is convicted of DWI for the first time, his or her license will be suspended for a period of up to:","explanation":"If a driver under the age of 18 is convicted of DWI for the first time, his or her license will be revoked for six months to one year. His or her license will be revoked indefinitely unless he or she completes a specific course on driving while drunk and submits to alcohol or drug treatment. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23883#23886#25169","text":"6 months#10 days#30 days#12 months"},
{"testId":1070,"qid":39144,"description":"If a vehicle operator under the age of 18 gets convicted of DWI for the second time, his or her license will be suspended:","explanation":"If a motorist under the age of 18 gets convicted of DWI for the second time, his or her license will be revoked until the person reaches the age of 21. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":40057,"isBookmarked":0,"aid":"25104#40056#40057#40058","text":"for 2 years#until he or she turns 18#until he or she turns 21#for up to 18 months"},
{"testId":1070,"qid":39145,"description":"If a motor vehicle operator under the age of 21 is convicted of DWI for the first time, he or she will be fined ____ and ordered to perform ___ hours of community service.","explanation":"If a motor vehicle operator under the age of 21 is convicted of DWI for the first time, he or she will be fined $250 and ordered to conduct 30 hours of community service. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":13905,"isBookmarked":0,"aid":"13903#13905#40059#40060","text":"$100; 30#$250; 30#$100; 10#$250; 10"},
{"testId":1070,"qid":39146,"description":"An adult driver who is found guilty of DUI and has a blood alcohol content (BAC) of less than 0.10% may receive a first-time sentence of up to ______ in prison.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1070,"qid":39147,"description":"An adult driver's license may be suspended for up to ______ for a first offense if they are found guilty of DUI with a blood alcohol content (BAC) under 0.10%.","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1070,"qid":39148,"description":"An adult driver's license may be suspended for up to ______ days for a first offense if they are found guilty of DUI with a blood alcohol content (BAC) of at least 0.10% but less than 0.15%.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#20789#23154#26672","text":"2 years#9 months#1 year#5 years"},
{"testId":1070,"qid":39149,"description":"For a first offense, an adult driver of a motor vehicle who is found guilty of DUI and has a blood alcohol content (BAC) of at least 0.15 percent shall be fined:","explanation":null,"imageName":"","correctAnswer":290,"isBookmarked":0,"aid":"290#1799#11669#11832","text":"$500#$1,000#$2,000#$100"},
{"testId":1070,"qid":39150,"description":"For the second time in five years, an adult driver who is guilty of DUI will be punished to up to ______ in prison.","explanation":"If an adult driver is convicted of DUI for the second time, he or she will be sentenced to prison. If the person's blood alcohol content (BAC) was less than 0.15 percent at the time of the crime, the punishment will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1070,"qid":39151,"description":"An adult driver convicted of DUI for the second time in five years will sentenced to jail for at least ______ if his or her BAC was less than 0.15%.","explanation":"If an adult driver is convicted of DUI for the second time, he or she will be sentenced to prison. If the person's blood alcohol content (BAC) was less than 0.15 percent at the time of the crime, the punishment will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#12360#18239","text":"10 days#30 days#7 days#14 days"},
{"testId":1070,"qid":39152,"description":"An adult driver convicted of DUI for the second time in five years will sentenced to jail for at least ______ if his or her BAC was at least 0.15%.","explanation":"If an adult driver is convicted of DUI for the second time, he or she will be sentenced to prison. If the person's blood alcohol content (BAC) was less than 0.15 percent at the time of the crime, the punishment will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23152#23153#26650","text":"9 months#3 months#6 months#One year"},
{"testId":1070,"qid":39155,"description":"If an adult driver is guilty of DUI for the third time in five years, he or she must serve at least ______ in jail if his or her BAC was less than 0.15%.","explanation":"A third DUI in five years is classified as a felony. If the person's blood alcohol concentration (BAC) was less than 0.15 percent at the time of the crime, the penalty is one to three years in jail, or three to five years in prison if the person's BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":1070,"qid":39156,"description":"If an adult driver is guilty of DUI for the third time in five years, he or she must serve at least ______ in jail if his or her BAC was at least 0.15%.","explanation":"A third DUI in five years is classified as a felony. If the person's blood alcohol concentration (BAC) was less than 0.15 percent at the time of the crime, the penalty is one to three years in jail, or three to five years in prison if the person's BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":11341,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":1070,"qid":39157,"description":"If an adult driver is convicted of DUI for the third time in five years with a BAC of 0.15 percent or above, the fine is up to :","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25036#27889#31397","text":"$1,000#$5,000#$2,500#$10,000"},
{"testId":1070,"qid":39162,"description":"If convicted of carrying a passenger who isn't wearing a helment, you may face a fine of:","explanation":"Every motorbike passenger, regardless of age, is required by law in Rhode Island to wear a helmet. If you are found to be in violation of this legislation, you will be fined $85. [RIG L. 31-10.1-6], [RIG L. 31-41.1-4]","imageName":"","correctAnswer":31885,"isBookmarked":0,"aid":"24978#24993#24994#31885","text":"$100#$25#$50#$85"},
{"testId":1070,"qid":39163,"description":"A motorist under the age of 21 who is guilty of having alcoholic beverages will have his or her license suspended for a period of at least ___ days for a first offense.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1070,"qid":39165,"description":"For the first time, you'll be fined _____ if you're caught riding a motorcycle without eye protection.","explanation":"Every biker, regardless of age, is required by Rhode Island law to wear eye protection such as a face shield, safety goggles, or even safety spectacles. If you violate this legislation, you will be penalized $25 for the first offense, $50 for the second, and $100 for the third and subsequent crimes. [R. I. G. L. 31-23-57].","imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24978#24993#24994#31884","text":"$100#$25#$50#$40"},
{"testId":1070,"qid":39166,"description":"Motorcyclists who ride with their handgrips above their shoulders will be fined:","explanation":"It is forbidden in Rhode Island to ride a motorbike with handlebars that extend more than 15 inches above the seat while you are seated on it. If you are found guilty of breaking this statute, you will be fined $85. [RIG L. 31-10.1-5], [RIG L. 31-41.1-4]","imageName":"","correctAnswer":31885,"isBookmarked":0,"aid":"24978#24993#24994#31885","text":"$100#$25#$50#$85"},
{"testId":1070,"qid":39169,"description":"What is the minimum age to receive a motorcycle permit?","explanation":"To be eligible for a motorcycle permit, you must successfully complete the Community College of Rhode Island's Motorcycle Rider Safety Course. To be eligible for the training, you must have either a provisional (teen) or a full (adult) driver's license. You must also be at least 16 years old to be eligible for a provisional driver's license. [A. Before Obtaining a Limited Instructional Permit; Chapter I. Applying for a Rhode Island Driver's License; Rhode Island Driver's Manual], [\"Motorcycles\"; Rhode Island Division of Motor Vehicles; http://www.dmv.ri.gov/licenses/motorcycles/index.php], [\"Program and Safety Requirements\"; Community College of Rhode Island; https://www.ccri.edu/workforce/pdfs/ .pdf]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11361#11374#13366","text":"15#16#17#15.5"},
{"testId":1070,"qid":39170,"description":"Which of the following restrictions apply to your riding when you have a Rhode Island motorcycle permit?","explanation":"You must be supervised at all times when riding with a Rhode Island motorcycle permit by a licensed motorcyclist who is at least 18 years old. You may not transport people (other than your riding supervisor) or ride on limited-access roadways. [R. I. G. L. 31-10.1-2]","imageName":"","correctAnswer":40134,"isBookmarked":0,"aid":"32742#40132#40133#40134","text":"You may not carry passengers#Your riding must be supervised at all times#You may not ride on limited-access highways#All of the above apply"},
{"testId":1070,"qid":39171,"description":"When riding a motorcycle in Rhode Island, you must wear a helmet if:","explanation":"If you are under the age of 21, Rhode Island law mandates you to wear a helmet. The legislation also mandates you to wear a helmet for one year after receiving your first motorcycle endorsement or license. Every passenger, whatever of age, must, however, wear a helmet. If you are found guilty of infringing any of these rules, you will be fined $85. [R. I. G. L. 31-10.1-4], [R. I. G. L. 31-10.1-6], and [R. I. G. L. 31-41.1-4] are the rules of the road.","imageName":"","correctAnswer":37566,"isBookmarked":0,"aid":"37566#40135#40136#40137","text":"either (a) or (b) is true#(a) you're under 21 years of age#(b) you've held a motorcycle endorsement no longer than one year#both (a) and (b) are true"},
{"testId":1070,"qid":39172,"description":"You must have a motorcycle permit for at least _________ before applying for a motorcycle endorsement on your driver's license.","explanation":"A motorbike permit in Rhode Island is valid for 18 months. Take your motorbike permit and your driver's license to any DMV office location after you've had it for at least 30 days. You will be issued a motorbike endorsement by the DMV. [\"Motorcycles\"; Division of Motor Vehicles of Rhode Island; http://www.dmv.ri.gov/licenses/motorcycles/index.php] ]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23153#23886#26650#35989","text":"6 months#30 days#One year#14 days"},
{"testId":1071,"qid":39173,"description":"You can be charged with driving under the influence (DUI) if your ability to drive your motorcycle safely has been hurt by using:","explanation":null,"imageName":"","correctAnswer":9855,"isBookmarked":0,"aid":"9855#33479#35439#35440","text":"Any of the above#illegal drugs#prescription medications#over-the-counter cold medications"},
{"testId":1071,"qid":39174,"description":"By riding on public highways in South Carolina, you expressly agree to:","explanation":"By driving a motor vehicle on South Carolina's public highways, you imply permission to chemical testing for alcohol and drugs at the request of a law enforcement officer, according to the state's Implied Consent legislation. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1071,"qid":39178,"description":"A motorcycle rider under the age of 21 whose blood alcohol concentration (BAC) is ______ or more will have his or her license suspended.","explanation":"If a motorcyclist under the age of 21 is found guilty of driving with a BAC of 0.02 percent or greater, his or her license will be revoked for three months for the first offense and six months for the second offense within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the motorcyclist's BAC at the time of the violation was 0.08 percent or greater, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"4251#4653#13791#35229","text":"0.02%#0.01%#0.008%#Zero"},
{"testId":1071,"qid":39179,"description":"A motorcyclist under 21 convicted first time聽of driving with a BAC above the legal limit will have their license suspended for:","explanation":"If a motorcyclist under the age of 21 is found guilty of driving with a BAC of 0.02 percent or greater, his or her license will be revoked for three months for the first offense and six months for the second offense within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the motorcyclist's BAC at the time of the violation was 0.08 percent or greater, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1453,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1071,"qid":39180,"description":"For a second conviction within five years, a motorcyclist under 21 will have his or her license suspended for聽:","explanation":"If a motorcyclist under the age of 21 is found guilty of driving with a BAC of 0.02 percent or greater, his or her license will be revoked for three months for the first offense and six months for the second offense within a five-year period. After only one normal alcoholic drink, most persons will have a BAC of 0.02 percent or greater. (A regular drink is about 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof liquor.) Nota bene: If the motorcyclist's BAC at the time of the violation was 0.08 percent or greater, he or she will be prosecuted under South Carolina DUI statutes. Conviction may result in a fine, a prison term, or both. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1071,"qid":39181,"description":"If a motorcyclist under 21 refuses drug or alcohol testing, his or her license is suspended immediately for:","explanation":"If a biker of any age refuses to submit to chemical testing for alcohol or drugs when asked, his or her license will be suspended for six months immediately. [Alcohol Is America's Most Abused Drug; Drugs and Driving; South Carolina Motorcyclist's Handbook]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23883#23886#24048","text":"6 months#10 days#30 days#60 days"},
{"testId":1071,"qid":39184,"description":"If a motorcycle rider under the age of 21 has a past DUI conviction and refuses to submit to a chemical test for drugs or alcohol at the request of a law enforcement official, his or her license will be suspended for a period of:","explanation":"If a biker under the age of 21 refuses to undergo the intoxication test and it is his or her first violation, he or she will lose his or her driving rights for six months. If there is a second or subsequent DUI, Felony DUI, Implied Consent, or a BAC of 0.02 percent or higher within the previous five years of the first offense, the driver's license will be suspended for one year. [Alcohol and the Law; Section 3 Be in Good Driving Shape; South Carolina Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23152#23154#23886#26672","text":"3 months#1 year#30 days#5 years"},
{"testId":1071,"qid":39187,"description":"Your license will be suspended for three months if you accumulate ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6478#7620#11361","text":"10#12#8#16"},
{"testId":1071,"qid":39188,"description":"Your license will be suspended for __________, if you acquire 20 points on your driving record.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23152#23153#25031#25169","text":"3 months#6 months#18 months#12 months"},
{"testId":1071,"qid":39190,"description":"After _____ , the points for a traffic ticket will be taken off your record by themselves.","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#24049#26672","text":"2 years#1 year#90 days#5 years"},
{"testId":1071,"qid":39192,"description":"A biker convicted of breaching South Carolina's helmet legislation faces imprisonment up to:","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#35988#35989","text":"30 days#60 days#7 days#14 days"},
{"testId":1071,"qid":39193,"description":"Every time you ride your motorcycle in South Carolina, the headlight must be turned on. If you are convicted of breaking this legislation, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1071,"qid":39194,"description":"If you put a highway worker in danger, the SCDMV will send you a warning letter.","explanation":null,"imageName":"","correctAnswer":17127,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":1071,"qid":39199,"description":"If you are found guilty of running a red light, you will receive ___ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1071,"qid":39200,"description":"Which of the following convictions will cause a South Carolina driver's record to go up by four points?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#25886#31718#40402","text":"Any of the above#Driving on the wrong side of the road#Making an illegal U-turn#Unlawful passing"},
{"testId":1071,"qid":39201,"description":"Which of the following convictions will NOT cause a South Carolina driver's record to get four points?","explanation":null,"imageName":"","correctAnswer":40467,"isBookmarked":0,"aid":"864#40400#40402#40467","text":"Tailgating#Failing to yield the right-of-way#Unlawful passing#Riding a motorcycle with a broken taillight"},
{"testId":1071,"qid":39202,"description":"If you are guilty of exceeding the prescribed speed limit by more than 10 mph but less than _______, four points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":522,"isBookmarked":0,"aid":"520#521#522#523","text":"15 mph#30 mph#25 mph#20 mph"},
{"testId":1071,"qid":39203,"description":"_____ points will be added to your driving record if you are guilty of weaving from lane to lane.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1071,"qid":39204,"description":"__ points will be added to your driving record for failing to dim your headlight when required.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1071,"qid":39205,"description":"If you are guilty of exceeding the stated speed limit by ______ miles per hour or less, two points will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":2429,"isBookmarked":0,"aid":"520#523#2429#15720","text":"15 mph#20 mph#10 mph#5 mph"},
{"testId":1071,"qid":39206,"description":"To receive a motorcycle license or a motorcycle beginner's permit, you must be at least ___ years old.","explanation":"To receive a motorcycle license or a motorcycle beginner's permit in South Carolina, you must be at least 15 years old. [How to Get a Motorcycle License or Permit; Motorcycle Rider Licensing Section 1; South Carolina Motorcycle & Moped Operator's Manual]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11359#11361#11784","text":"15#18#16#14"},
{"testId":1071,"qid":39213,"description":"The penalties for a second offense of driving under the influence (DUI) are substantially higher than those for the first violation.","explanation":null,"imageName":"","correctAnswer":17126,"isBookmarked":0,"aid":"17126#17127","text":"True#False"},
{"testId":1071,"qid":39214,"description":"Which of the following is required for a street-legal motorbike in South Carolina?","explanation":"A street-legal motorcycle in South Carolina must have a headlight, taillight, brake light, license plate light, muffler, horn, at least one brake, at least one mirror, turn signals (unless constructed before 1974), and an exhaust system that fulfills South Carolina's emissions and noise regulations. [South Carolina Motorcycle & Moped Operator's Manual; Street-Legal Motorcycle; Section 1 Motorcycle Rider Licensing]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#1424#31918#37543","text":"All of the above#Turn signals#Horn and one mirror#Headlight, taillight, and brake light"},
{"testId":1071,"qid":39215,"description":"In South Carolina, your motorbike didn't activate a red light's sensor. Nobody walks or drives. When can you legally procced the red light?","explanation":"If you are stopped at a \"stuck\" red light in South Carolina, you may move only if all of the following requirements are met: (1) you have waited at least two full minutes; (2) you take reasonable care as required by law; and (3) you consider the stuck red light as a stop sign. At the time of writing, less than half of the US states have implemented such \"dead red\" legislation, which enable motorcycles to pass through stopped red lights under specific situations. And the criteria enforced differ across different states. Always verify the local driving regulations while going out of state. [Specific Motorcycle Rules of the Road; Section 1 Motorcycle Rider Licensing; South Carolina Motorcycle and Moped Operator's Manual]","imageName":"","correctAnswer":44857,"isBookmarked":0,"aid":"40958#41106#41108#44857","text":"Either (a) or (b)#(a) After you've waited for two full light cycles#Immediately#(b) After you've waited for two full minutes"},
{"testId":1071,"qid":39217,"description":"To earn a motorcycle license, you won't have to pass the SCDMV motorcycle skills test if you passed a Motorcycle Safety Foundation skills test within the past:","explanation":"In order to receive a motorbike license, you must typically pass the SCDMV motorcycle skills exam. However, if you were tested by a SCDMV certified and contracted third-party tester within 30 days of applying, the SCDMV will accept a Motorcycle Safety Foundation skills test certificate instead. [How to Get a Motorcycle License or Permit; Motorcycle Rider Licensing Section 1; South Carolina Motorcycle & Moped Operator's Manual]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1071,"qid":39218,"description":"In 2017, _____ of motorcyclists killed in single-vehicle crashes had a BAC of 0.08 percent or above.","explanation":"According to the National Highway Traffic Safety Administration (NHTSA), 43 percent of motorcyclists killed in single-vehicle accidents in 2017 were under the influence of alcohol. (A blood alcohol content (BAC) of 0.08 percent or more is considered \"alcohol impaired\" in this context.) Furthermore, motorcycles were shown to have the greatest rate of alcohol-impaired drivers of any vehicle type involved in fatal collisions (27 percent for motorcycles, 21 percent for passenger cars, 20 percent for light trucks, and 3 percent for large trucks). [National Highway Traffic Safety Administration, \"Traffic Safety Facts: 2017 Data,\" August 2019]","imageName":"","correctAnswer":44860,"isBookmarked":0,"aid":"44858#44859#44860#44861","text":"23%#33%#43%#53%"},
{"testId":1071,"qid":39222,"description":"When will you be able to retake your SCDMV motorcycle skills test if you failed it the third time at a SCDMV branch?","explanation":null,"imageName":"","correctAnswer":6334,"isBookmarked":0,"aid":"6334#44862#44863#44864","text":"Never#After 7 days#After 14 days#After 30 days"},
{"testId":1072,"qid":39223,"description":"Motorcycle licenses in South Dakota are valid for _____ years.","explanation":"A motorbike license in South Dakota is valid for the same period of time as a driver's license. That is, if you are above the age of 21, your South Dakota motorbike license is valid for five years. If you are under the age of 21, your license will expire 30 days after you turn 21. [Operator's License; Types of Driver Licenses; South Dakota Driver License Manual]; [Motorcycle Operator's License; Types of Driver Licenses; South Dakota Driver License Manual]; [Motorcycle Operator's License; Types of Driver Licenses; South Dakota Driver License Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1072,"qid":39224,"description":"A rider over the age of 21 may renew his or her South Dakota motorcycle license up to ___ days before it expires.","explanation":"South Dakota motorcycle license renewal regulations are the same as those for driver's licenses. That is, unless you are under the age of 21, you may renew your South Dakota motorcycle license up to 180 days before it expires. In that instance, it is scheduled to expire 30 days after your 21st birthday, and you may only renew it during those 30 days. [Examination Procedures; Exams; Driver License Types; South Dakota Driver License Manual]","imageName":"","correctAnswer":6802,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":1072,"qid":39225,"description":"Which of the following can lead to the revocation of your motorcycle license?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40580#40656#40657","text":"Any of the above#Accumulating excess points#A conviction for a drug offense while riding a motorcycle#Riding a motorcycle while your license is suspended"},
{"testId":1072,"qid":39226,"description":"After your license has been suspended, you must pay a reinstatement fee of at least ____ before it can be restored.","explanation":null,"imageName":"","correctAnswer":11349,"isBookmarked":0,"aid":"11348#11349#11351#11832","text":"$25#$50#$10#$100"},
{"testId":1072,"qid":39227,"description":"For those who are found guilty of riding a motorcycle when your license is suspended, the suspension time will be:","explanation":null,"imageName":"","correctAnswer":40404,"isBookmarked":0,"aid":"14101#40404#40405#40581","text":"quadrupled#doubled#tripled#quintupled"},
{"testId":1072,"qid":39228,"description":"If a motorcycle restricted minor's permit holder under the age of 16 is convicted of a traffic infringement, his or her permit will be suspended for ____ days for a first offense.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1072,"qid":39229,"description":"If a motorcycle restricted minor's permit holder under the age of 16 gets convicted of a traffic offense for the second time, his or her permission will be suspended for ____ days or until he or she reaches 16 years, whichever is longer.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6477#6802","text":"10#90#30#180"},
{"testId":1072,"qid":39230,"description":"A motorcycle purchased in another state must be registered in the purchaser's home state within:","explanation":"A car acquired outside of the state must be registered within 90 days after purchase. [Vehicle Registration and Titles; License Revocation and Suspension; South Dakota Driver License Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24049#34746#35988","text":"30 days#90 days#3 days#7 days"},
{"testId":1072,"qid":39240,"description":"In the event that you are found guilty of racing on a public road, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1072,"qid":39242,"description":"__ points will be added to your driving record for improper passing or riding on the wrong side.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1072,"qid":39244,"description":"You will be punished if you are caught texting on a portable cell phone while riding a motorcycle.","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24994#27902#39536","text":"$100#$50#$75#$125"},
{"testId":1072,"qid":39252,"description":"If you are caught riding your motorcycle between two lanes of traffic (lane splitting), you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24977#24978","text":"$500#$1,000#$200#$100"},
{"testId":1072,"qid":39253,"description":"Which of you may face jail time if you let a friend use your driver's license to commit fraud?","explanation":null,"imageName":"","correctAnswer":40594,"isBookmarked":0,"aid":"40591#40592#40593#40594","text":"Just you#Just your friend#Neither you nor your friend#Both you and your friend"},
{"testId":1072,"qid":39254,"description":"Which of the following will help someone who has been drinking to \"sober up\"?","explanation":"Your body degrades alcohol at a consistent pace. This is typically one standard drink every hour, with one standard drink equaling 1.5 ounces of hard liquor, 5 ounces of wine, or 12 ounces of beer. That pace cannot be increased by coffee, fresh air, or food. You must wait until the alcohol has been broken down after being inebriated. [Being in Shape to Drive; South Dakota Driver License Manual]","imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#17525#25081#35427","text":"None of the above#Coffee#A cold shower#Food"},
{"testId":1072,"qid":39257,"description":"What is the minimum value of blood alcohol concentration is an evidence that the motorcyclist under the age of 21 was driving under the influence of alcohol?","explanation":"If chemical testing finds that a vehicle operator under the age of 21 has a blood alcohol content (BAC) of 0.02 percent or greater, he or she will be charged with driving under the influence of alcohol. After only one alcoholic drink, the majority of individuals will have a BAC of 0.02 percent or above. As a result, a BAC limit of less than 0.02 percent is practically zero tolerance. [Alcohol and the Law; Driving Fitness; South Dakota Driver License Manual]","imageName":"","correctAnswer":4251,"isBookmarked":0,"aid":"1396#4251#4653#35229","text":"None of the above#0.02%#0.01%#Zero"},
{"testId":1072,"qid":39258,"description":"By riding on public highways in South Dakota, you expressly agree to:","explanation":null,"imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1072,"qid":39260,"description":"First-time DUI convictions for motorcyclists under 21 result in a _____ license suspension.","explanation":"A vehicle operator under the age of 21 who is convicted of driving under the influence (i.e., breaking Zero Tolerance) shall have his or her license suspended for 30 days on the first conviction. A fine will also be imposed on him or her. [Zero Tolerance; Alcohol and the Law; Driving Fitness; South Dakota Driver License Handbook]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":1072,"qid":39261,"description":"For a second or subsequent DUI conviction, a motorcyclist under 21 will have their license suspended for聽:","explanation":"A vehicle operator under the age of 21 who is convicted of driving under the influence (i.e., breaking Zero Tolerance) will have his or her license suspended for 180 days for a second or subsequent conviction. A fine will also be imposed on him or her. [Zero Tolerance; Alcohol and the Law; Driving Fitness; South Dakota Driver License Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#12630","text":"180 days#90 days#30 days#360 days"},
{"testId":1072,"qid":39264,"description":"A ______ or subsequent DWI charge is considered a felony.","explanation":null,"imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#12586","text":"Second#Third#Fourth#Fifth"},
{"testId":1072,"qid":39268,"description":"Riding a motorcycle when your license is suspended due to a second DWI offense will result in a minimum of ______ months in prison time upon conviction.","explanation":null,"imageName":"","correctAnswer":12361,"isBookmarked":0,"aid":"739#12361#12685#40595","text":"10 days#3 days#5 days#2 days"},
{"testId":1072,"qid":39269,"description":"There is a strict zero-tolerance policy for DUI on motorcycles, and you can be arrested if your ability to ride safely has been compromised by:","explanation":null,"imageName":"","correctAnswer":848,"isBookmarked":0,"aid":"848#11901#12638#13431","text":"Any of the above#marijuana#over-the-counter medications#prescription medications"},
{"testId":1072,"qid":39270,"description":"Riding a motorcycle when your license is suspended due to a third DWI offense will result in a minimum of ______ months in prison time upon conviction.","explanation":null,"imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#12360#12361#12685","text":"10 days#7 days#3 days#5 days"},
{"testId":1072,"qid":39272,"description":"First and second DWI offenses are classified as _________ for adult motorcyclist.","explanation":null,"imageName":"","correctAnswer":40596,"isBookmarked":0,"aid":"40596#40597#40598#40599","text":"Class 1 misdemeanors#Class 2 misdemeanors#Class 3 misdemeanors#felonies"},
{"testId":1073,"qid":39275,"description":"It is illegal to operate a motor vehicle with a BAC of ______ or above in the vast majority of states, including Tennessee.","explanation":"Most states, including Tennessee, make it unlawful to drive if your blood alcohol content (BAC) is 0.08 percent or above. If you are discovered operating a motor vehicle with your BAC is this high in Tennessee, you may be prosecuted with driving under the influence (DUI). [What is BAC? ; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1073,"qid":39276,"description":"Around _____ of drivers are thought to be drunk between the hours of 10:00 p.m. and 2:00 a.m., according to researchers.","explanation":"Researchers believe that 10% of drivers are inebriated between 10:00 p.m. and 2:00 a.m., suggesting their driving is seriously compromised. Keep this in mind if you're riding a motorbike in traffic during those hours. [Alcohol and You; Drinking and Driving; Important Drug and Alcohol Information; Tennessee Motorcycle Operator Manual]","imageName":"","correctAnswer":11664,"isBookmarked":0,"aid":"11664#31667#40812#40813","text":"10%#5%#15%#2%"},
{"testId":1073,"qid":39277,"description":"A driver with a blood alcohol content (BAC) of 0.10 percent is _____ times more likely to cause a crash than a sober driver.","explanation":"A motorist with a blood alcohol content (BAC) of 0.10 percent is seven times more likely to cause an accident than a sober driver. (If your BAC is 0.08 percent or more, you are deemed inebriated.) [Alcohol and Traffic Crash Relationship; Drinking and Driving; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":10878,"isBookmarked":0,"aid":"5002#6209#10878#13823","text":"Five#Three#Seven#nine"},
{"testId":1073,"qid":39278,"description":"Just one drink within 15 minutes might increase your blood alcohol content (BAC) to ______.","explanation":"One drink within 15 minutes can boost your blood alcohol content (BAC) to between 0.01 and 0.03 percent, depending on variables such as body weight. It should be noted that a driver under the age of 21 who is discovered to have a BAC of 0.02 percent or above would be prosecuted with underage driving while impaired. B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27700,"isBookmarked":0,"aid":"23192#23194#27699#27700","text":"0.05%#0.08%#0.01%#0.03%"},
{"testId":1073,"qid":39279,"description":"Which of the following drugs can result in a DUI conviction in Tennessee when used while riding a motorcycle?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#8070#25099#29384","text":"Any of the above#Illegal drugs#Marijuana#Prescription medications"},
{"testId":1073,"qid":39280,"description":"Even if your blood alcohol concentration (BAC) is as low as _______, you might be charged with DUI.","explanation":"Even if you haven't drank any alcohol, other substances you've used may impair your ability to ride a motorbike. Even if your BAC is zero, you may still be charged with DUI if a law enforcement officer obtains additional proof that your use of certain substances has rendered you incapable of safely driving a motor vehicle. B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23192#23194#23318#31711","text":"0.05%#0.08%#0.02%#Zero"},
{"testId":1073,"qid":39281,"description":"By riding on public highways in Tennessee, you expressly agree to:","explanation":"By driving a motor vehicle on Tennessee's public highways, you imply permission to chemical testing for alcohol and drugs at the request of a law enforcement officer, according to Tennessee's Implied Consent legislation. [Implied Consent Law; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#40814","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your motorcycle"},
{"testId":1073,"qid":39284,"description":"If convicted of DUI for the first time, you'll spend at least ___ in jail.","explanation":null,"imageName":"","correctAnswer":40738,"isBookmarked":0,"aid":"34746#37577#40738#40739","text":"3 days#4 days#2 days#5 days"},
{"testId":1073,"qid":39285,"description":"If you're convicted of a first DUI and your BAC was 0.18 percent or higher, you will be sentenced to jail for at least:","explanation":null,"imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23883#23886#34746#35988","text":"10 days#30 days#3 days#7 days"},
{"testId":1073,"qid":39286,"description":"First Time DUI offenders may be fined up to:","explanation":null,"imageName":"","correctAnswer":23890,"isBookmarked":0,"aid":"23887#23890#24978#27888","text":"$500#$1,500#$100#$250"},
{"testId":1073,"qid":39287,"description":"If convicted of DUI for the first time, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1073,"qid":39288,"description":"If convicted of DUI for the second time, you will be sentenced to at least _____  in prison.","explanation":null,"imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23886#24048#27364#34746","text":"30 days#60 days#45 days#3 days"},
{"testId":1073,"qid":39289,"description":"If  convicted of DUI for the second time, you will face a fine of at least:","explanation":null,"imageName":"","correctAnswer":23888,"isBookmarked":0,"aid":"23888#23889#24977#31696","text":"$600#$1,000#$200#$400"},
{"testId":1073,"qid":39290,"description":"If convicted of DUI for the second time, you will have your license revoked for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23183#25858#26672","text":"2 years#3 years#180 days#5 years"},
{"testId":1073,"qid":39292,"description":"If convicted of DUI for the third time, you could face a fine of up to:","explanation":null,"imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"23887#23889#25036#31397","text":"$500#$1,000#$5,000#$10,000"},
{"testId":1073,"qid":39293,"description":"If convicted of DUI for the third time, you will be sentenced to at least_____ in prison.","explanation":null,"imageName":"","correctAnswer":25857,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1073,"qid":39294,"description":"If convicted of DUI for the third time, your license will be suspended for a period of up to:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"20788#23183#26672#29895","text":"2 years#3 years#5 years#10 years"},
{"testId":1073,"qid":39295,"description":"A ______ or subsequent DUI conviction is treated as a felony in Tennessee.","explanation":null,"imageName":"","correctAnswer":12585,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1073,"qid":39296,"description":"If convicted of DUI for the fourth time, you will face a fine of at least:","explanation":null,"imageName":"","correctAnswer":25034,"isBookmarked":0,"aid":"23887#23889#25034#31397","text":"$500#$1,000#$3,000#$10,000"},
{"testId":1073,"qid":39297,"description":"If convicted of DUI for the fourth time, you will have your license revoked for at least :","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1073,"qid":39298,"description":"If you are convicted of DUI while carrying a minor passenger under the age of 18, you will face additional penalties of at least ___ days in jail and a fine of at least____.","explanation":"Driving under the influence (DUI) while transporting a kid under the age of 18 is considered a kind of child endangerment. If convicted, you will face the standard DUI penalty, plus 30 days in prison and a fine of at least $1,000. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":26614,"isBookmarked":0,"aid":"13825#26614#26615#40742","text":"10; $500#30; $1,000#60; $2,000#120; $5,000"},
{"testId":1073,"qid":39299,"description":"How many drinks would you have left in your system at the end of three hours if you had seven drinks in three hours?","explanation":null,"imageName":"","correctAnswer":45542,"isBookmarked":0,"aid":"45539#45540#45541#45542","text":"Zero (7 - 7 = 0)#One drink (7 - 6 = 1)#At least seven drinks (7 - 0 = 7)#At least four drinks (7 鈥 3 = 4)"},
{"testId":1073,"qid":39300,"description":"First-time DUI offenders may be required to install an ignition interlock device (IID) for a periof of:","explanation":"If you are convicted of DUI for the first time, the court may order you to place an ignition interlock device (IID) in every motor vehicle you drive for six months to a year. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. Please keep in mind that if you are unable to install an IID on your motorbike, you will be unable to ride it for the length of the installation time. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":40746,"isBookmarked":0,"aid":"40746#40747#40748#40749","text":"6 months to 1 year#1 to 2 years#2 to 3 years#3 to 10 years"},
{"testId":1073,"qid":39301,"description":"If convicted of DUI for the second time, the court will order you to install an ignition interlock device (IID) in every car you operate for a period of up to:","explanation":"If you are convicted of DUI for the second time, the court will order you to place an ignition interlock device (IID) in every car you drive for a period of up to three years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. Please keep in mind that if you are unable to install an IID on your motorbike, you will be unable to ride it for the length of the installation time. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1073,"qid":39302,"description":"If you're convicted of DUI for the ______ time, the court will require you to install an IID for up to ten years on each motor vehicle you operate.","explanation":"If you get a third or subsequent DUI, the court will order you to place an ignition interlock device (IID) in every car you drive for up to 10 years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. Please keep in mind that if you are unable to install an IID on your motorbike, you will be unable to ride it for the length of the installation time. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":1073,"qid":39304,"description":"If a licensed biker between the ages of 18 and 20 is convicted of purchasing or having alcohol for the first time, his or her driver's license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24048","text":"6 months#1 year#30 days#60 days"},
{"testId":1073,"qid":39305,"description":"If a motorcyclist between the ages of 18 and 20 is convicted of purchasing or possessing alcohol for the second time, his or her driving privileges will be terminated for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1073,"qid":39306,"description":"Underage motorcyclists convicted of DUI will be fined:","explanation":"A motor vehicle operator under the age of 21 who is convicted of underage driving while intoxicated faces a $250 fine and a one-year license suspension. The court may also order the individual to undertake community service. [Under 21 BAC; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":27888,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1073,"qid":39307,"description":"A motorcyclist under 21 convicted of underage driving while impaired聽will have his or her license suspended for:","explanation":"A motor vehicle operator under the age of 21 who is convicted of underage driving while intoxicated faces a $250 fine and a one-year license suspension. The court may also order the individual to undertake community service. [Under 21 BAC; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#26672","text":"6 months#1 year#30 days#5 years"},
{"testId":1073,"qid":39311,"description":"Alcohol has many various effects on the body, but it starts with your:","explanation":"Although alcohol has many diverse effects on the body, it initially clouds your judgement. You may not be aware of how drunk you are. Even if you haven't yet seen additional impacts, you could make risky choices (such as speeding around curves, passing other vehicles without an adequate space cushion, or even showing off on the road). Avoiding Risks; Underage Drinking Laws; Young Driver Risks and Laws; Driving While Intoxicated (The \"DUI\" Law); B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual","imageName":"","correctAnswer":10401,"isBookmarked":0,"aid":"10401#10402#10404#10667","text":"judgment#vision#balance#Reaction time"},
{"testId":1073,"qid":39312,"description":"Which of the following might lead to licence suspension or revocation?","explanation":"In Tennessee, your license can be suspended or revoked for a number of reasons, including a DUI conviction, leaving the scene of an accident without stopping (hit and run), unlawful or fraudulent use of your license, and medical conditions that impair your ability to operate a motor vehicle safely, among others. [Losing Your Driving Privilege; B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#40757#40758#40815","text":"Any of the above#A conviction for leaving the scene of an accident without stopping (hit and run)#A conviction for letting someone else use your license for identification#Medical conditions that impair your ability to operate a motor vehicle safely"},
{"testId":1073,"qid":39315,"description":"If you are caught riding a motorcycle without a helmet, you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1073,"qid":39319,"description":"Driving while suspended or revoked will result in __ points being added to your driving record if proven guilty.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5449#6916#7559#7620","text":"5#6#7#8"},
{"testId":1073,"qid":39320,"description":"____ points will be added to your driving record if you are found guilty of reckless driving.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1073,"qid":39321,"description":"________ points will be added to your driving record if you are convicted of fleeing the police on a motorcycle.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6389#6916#7620","text":"10#3#6#8"},
{"testId":1074,"qid":39323,"description":"What is the loudest sound your motorcycle's exhaust system can make in Texas?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1074,"qid":39324,"description":"In Texas, the bodily injury liability portion of your motorcycle liability insurance policy must cover at least _______ per person.","explanation":null,"imageName":"","correctAnswer":3198,"isBookmarked":0,"aid":"3198#11659#12048#12194","text":"$30,000#$20,000#$25,000#$40,000"},
{"testId":1074,"qid":39325,"description":"According to the Texas Motorcycle Operator's Manual, approximately ____ of all motorcyclists died in motorcycle accidents were under the influence of alcohol.","explanation":"According to the Texas Motorcycle Operator's Manual, investigations have shown that over 40% of all motorcyclists killed in motorcycle accidents had consumed alcohol. [The Importance of This Information; Getting in Shape to Ride; Texas Motorcycle Operator's Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11665#11939#12162#40812","text":"25%#40%#30%#15%"},
{"testId":1074,"qid":39326,"description":"Which of the following may result in a conviction for driving while impaired(DWI)?","explanation":null,"imageName":"","correctAnswer":234,"isBookmarked":0,"aid":"234#9803#25099#29384","text":"Any of the above#Over-the-counter medications#Marijuana#Prescription medications"},
{"testId":1074,"qid":39330,"description":"An adult biker who is convicted of DWI for the first time will lose his or her license for up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1074,"qid":39331,"description":"An adult biker who is convicted of DWI for the first time will lose his or her license for at least:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#24048#24049#25858","text":"10 days#60 days#90 days#180 days"},
{"testId":1074,"qid":39332,"description":"A second DWI conviction can result in a fine of up to:","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25035,"isBookmarked":0,"aid":"23887#23889#25035#40916","text":"$500#$1,000#$4,000#$6,000"},
{"testId":1074,"qid":39333,"description":"A second DWI conviction can result in at least ____ in prison.","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"742#1020#12361#18239","text":"30 days#60 days#3 days#14 days"},
{"testId":1074,"qid":39334,"description":"A second DWI conviction can result in up to ____ in prison.","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":1074,"qid":39335,"description":"A second DWI conviction can result in license suspension for at least:","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#12630","text":"180 days#90 days#30 days#360 days"},
{"testId":1074,"qid":39336,"description":"An adult driver guilty of DWI will lose his or her driving privileges for up to ________ for a second conviction.","explanation":"If an adult driver is convicted of DWI for the second time, he or she faces a $4,000 fine and a prison term of 30 days to one year. His or her license will also be suspended for a period ranging from 180 days to two years. [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":1074,"qid":39338,"description":"A third DWI conviction can result in a fine of up to:","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"1799#3195#11658#12001","text":"$1,000#$5,000#$10,000#$3,000"},
{"testId":1074,"qid":39339,"description":"A third DWI conviction can result in at least ____ in prison.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"229#1021#1272#11285","text":"90 days#1 year#6 months#2 years"},
{"testId":1074,"qid":39340,"description":"A third DWI conviction can result in up to ____ in prison.","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23154#24049#26672#29895","text":"1 year#90 days#5 years#10 years"},
{"testId":1074,"qid":39341,"description":"A third DWI conviction can result in license suspension for at least:","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":1074,"qid":39342,"description":"If you're convicted of DWI for the first time and a passenger less than 15 years聽was on your motorcycle, you can be fined up to:","explanation":"If you are convicted of DWI for the first time, the penalties are substantially severe if you have a passenger under the age of 15 in your car at the time of the violation. You will be fined up to $10,000 and sentenced to 180 days to two years in state prison. Your license will also be suspended for 90 days to one year (if you are under the age of 21) or 90 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"25034#25036#31397#33847","text":"$3,000#$5,000#$10,000#$15,000"},
{"testId":1074,"qid":39343,"description":"If you're convicted of DWI for the first time and a passenger less than 15 years was on your motorcycle, you can be sentenced to state jail for at least:","explanation":"If you are convicted of DWI for the first time, the penalties are substantially severe if you have a passenger under the age of 15 in your car at the time of the violation. You will be fined up to $10,000 and sentenced to 180 days to two years in state prison. Your license will also be suspended for 90 days to one year (if you are under the age of 21) or 90 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1074,"qid":39345,"description":"A motorcyclist under 21 convicted of DUI (not DWI) will be fined up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1074,"qid":39346,"description":"A motorcyclist under 21 convicted of DUI (not DWI) for the first time聽must perform up to _______ of community service.","explanation":null,"imageName":"","correctAnswer":11695,"isBookmarked":0,"aid":"11482#11484#11694#11695","text":"10 hours#30 hours#20 hours#40 hours"},
{"testId":1074,"qid":39347,"description":"If a minor motorcycle driver under the age of 18 is convicted of DUI (not DWI) for the first time,:","explanation":null,"imageName":"","correctAnswer":27717,"isBookmarked":0,"aid":"27717#40990#40991#40992","text":"all of the above will happen#the motorcyclist's parent may be required to attend an alcohol awareness course along with him or her#the motorcyclist's license will be suspended for 60 days#the motorcyclist will be fined up to $500"},
{"testId":1074,"qid":39348,"description":"A motorcyclist under 21 convicted of DUI (not DWI) for a second time must perform up to _______ of community service.","explanation":null,"imageName":"","correctAnswer":13917,"isBookmarked":0,"aid":"11694#11695#13917#40925","text":"20 hours#40 hours#60 hours#80 hours"},
{"testId":1074,"qid":39349,"description":"If a motorcyclist under the age of 21 but at least 17 year is convicted of DUI (not DWI) for the third time, he or she may face a fine of up to.","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23889#25033#25034#25035","text":"$1,000#$2,000#$3,000#$4,000"},
{"testId":1074,"qid":39350,"description":"A 17- to 21-year-old motorcyclist convicted of DUI (not DWI) for the third time may be jailed for up to:","explanation":null,"imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23883#23886#24049#25858","text":"10 days#30 days#90 days#180 days"},
{"testId":1074,"qid":39353,"description":"You are legally intoxicated if your blood alcohol content (BAC) is _____ or above.","explanation":"In Texas, a blood alcohol content (BAC) of 0.08 percent or more is considered legally inebriated and may result in charges of driving while intoxicated (DWI). [Know Your Legal Limit; Chapter 10: The Effects of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":3168,"isBookmarked":0,"aid":"3168#3171#4251#4252","text":"0.08%#0.09%#0.02%#0.05%"},
{"testId":1074,"qid":39354,"description":"A motorcyclist under the age of 21 might be charged with DUI if his or her blood alcohol content (BAC) is higher than:","explanation":"Minors are treated with zero tolerance in Texas. If a driver under the age of 21 has any measurable level of alcohol in his or her system, he or she might be prosecuted with driving under the influence (DUI). [Texas Tough Alcohol-Related Laws for Minors; Chapter 10: The Influence of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":31711,"isBookmarked":0,"aid":"23192#23318#27698#31711","text":"0.05%#0.02%#0.04%#Zero"},
{"testId":1074,"qid":39355,"description":"What should you do if you are stopped by a police officer?","explanation":"If you are pulled over by police authorities, pull to the right side of the road and turn off the engine. If your motorbike is equipped with danger lights (flashers), turn them on. You should probably remove the stand as well since the traffic stop may take some time. Maintain your seat on your motorbike. Keep your hands visible to the police unless the officer instructs you differently. You'll probably have to show the police your license and proof of insurance, but tell him where they are before you reach for them. Don't fight with the police if he gives you a citation. A citation is not evidence of guilt. If you feel you are not guilty of the accusation, you will be able to dispute it later. [When Stopped by Cops; Chapter 14: Additional Safety Tips; Texas Driver Handbook]","imageName":"","correctAnswer":23000,"isBookmarked":0,"aid":"23000#34789#40926#40993","text":"Do all of the above#Pull over to the right#Turn the engine off#Remain seated on your motorcycle"},
{"testId":1074,"qid":39356,"description":"If you are found to have violated Texas's motorcycle helmet legislation, you might face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":1074,"qid":39357,"description":"You cannot ride a motorbike in Texas with a passenger who is under the age of:","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#7559#11342","text":"10#5#7#9"},
{"testId":1074,"qid":39358,"description":"If found guilty of transporting a passenger on a motorcycle that is not designed to transport passengers, you might face a fine of up to:","explanation":null,"imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1074,"qid":39359,"description":"If you cause $200 or less in property damage in a hit-and-run collision, you might be penalized up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#24978#24994#27888","text":"$500#$100#$50#$250"},
{"testId":1074,"qid":39360,"description":"If you were in an accident that caused at least _______ in property damage (including your own), you must file a Driver's Crash Report.","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":1074,"qid":39361,"description":"If you cause $200 or more in property damage in a hit-and-run collision, you might be penalized up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":1074,"qid":39362,"description":"If you cause $200 or more in property damage in a hit-and-run accident, you might go to prison for up to:","explanation":"If your vehicle strikes an unattended car or other unattended property in Texas, you must stop and leave a notice for the property owner. The note must include your name, address, and the license plate number of the car you were driving. If you are found guilty of failing to do so and the accident caused less than $200 in property damage, you will face a fine of up to $500 for a first offense. If the collision caused $200 or more in damages, you might face a $2,000 fine, up to 180 days in prison, or both. [Penalties for Failure to Comply with Damages; Crash Resulting in Fixture, Landscaping, or Structure Damage; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23883#23886#24048#25858","text":"10 days#30 days#60 days#180 days"},
{"testId":1074,"qid":39363,"description":"You should NOT drive if you are involved in an accident involving other people.","explanation":"If you are involved in an accident in Texas, you must stop at the site. For help, contact emergency services. Move your car off the road if it is drivable to help avoid another collision. Exchange your driver's license, registration, and proof of insurance with the other parties. Give wounded people fair aid. However, other than your insurance or the police, do not acknowledge blame or reveal the details of the collision. [Crash causing bodily harm or death; Chapter 11: Motor Vehicle Crashes; Texas Driver Handbook]","imageName":"","correctAnswer":40932,"isBookmarked":0,"aid":"34083#38982#40931#40932","text":"move your vehicle off the roadway#stop at the accident scene#exchange contact and insurance information with them#discuss the nature of the accident with them"},
{"testId":1074,"qid":39368,"description":"To be eligible to participate in a TxDPS-approved motorcycle safety course, a person under the age of 18 must:","explanation":null,"imageName":"","correctAnswer":22471,"isBookmarked":0,"aid":"22471#40994#40995#40996","text":"do any of the above#complete the classroom phase of a driver education course#hold a Class C learner license#hold a Class C provisional license"},
{"testId":1074,"qid":39369,"description":"If you transport a passenger too young to ride a motorcycle in state, you might be fined up to:","explanation":null,"imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"23887#23889#24977#31696","text":"$500#$1,000#$200#$400"},
{"testId":1074,"qid":39370,"description":"Riding a motorcycle while your license is suspended or revoked can result in a fine of up to:","explanation":"If you are found guilty of driving a motor vehicle while your license is canceled, suspended, or revoked, you might face a $500 fine. There are a few exceptions where the penalty may be heavier. [Driving While License Is Invalid (DWLI); Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#24978#27888","text":"$500#$1,000#$100#$250"},
{"testId":1074,"qid":39371,"description":"If you are caught riding a motorcycle without a license, you could face a fine of up to $1,000.","explanation":"If you are found guilty of driving a motor vehicle without a license, you may be fined up to $200. There are a few exceptions where the penalty may be heavier. [Penalties for Driving While Unlicensed; Chapter 1: Your Driving License; Texas Driver Handbook]","imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24993#24994","text":"$200#$100#$25#$50"},
{"testId":1074,"qid":39372,"description":"If an unlicensed and uninsured motorist causes a collision that results in significant bodily harm or death, he or she faces a fine of up to ________.","explanation":null,"imageName":"","correctAnswer":25035,"isBookmarked":0,"aid":"23889#25033#25034#25035","text":"$1,000#$2,000#$3,000#$4,000"},
{"testId":1075,"qid":39376,"description":"Studies have shown that almost _____ of all motorcyclists who died in motorcycle accidents across the country were legally drunk at the time.","explanation":"According to the Utah Motorcycle Operator Manual, research reveal that 29 percent of motorcycle riders killed in collisions countrywide had a blood alcohol content (BAC) of 0.08 percent or above. This suggests they were legally drunk at the time. [The Importance of This Information; Getting in Shape to Ride; Utah Motorcycle Operator Manual]","imageName":"","correctAnswer":12162,"isBookmarked":0,"aid":"11664#11939#11940#12162","text":"10%#40%#20%#30%"},
{"testId":1075,"qid":39379,"description":"Under Utah's Implied Assent Law, driving on Utah's public highways constitutes implied consent to :","explanation":"By driving a motor vehicle in Utah, you have implicitly accepted to being tested for alcohol or drugs at the request of a law enforcement official, according to Utah's Implied Consent Law. [The Drinking Driver/Drug Use; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":25218,"isBookmarked":0,"aid":"25218#29272#35227#35228","text":"chemical testing for alcohol and drugs#a search of your home#release of your medical records#a search of your vehicle"},
{"testId":1075,"qid":39380,"description":"Which of the following convictions does NOT usually lead to a driver's license suspension?","explanation":"Your licence might be suspended if you're found guilty of driving under the influence or arrested for doing so. A conviction for a non-traffic charge involving failure to pay child support may also result in the suspension of a driver's licence. A fine is often imposed as the result of a speeding ticket. (However, depending on how fast you were going at the time, each conviction for speeding will result in 35 to 75 points being added to your driving record. Your licence may be suspended if you accrue enough points.) [Utah Driver Handbook: When Your Right to Drive Might Be Suspended; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records]","imageName":"","correctAnswer":18625,"isBookmarked":0,"aid":"18625#27198#41117#41118","text":"Speeding#Failure to pay child support#Your first DUI#Driving uninsured"},
{"testId":1075,"qid":39387,"description":"Under Utah's \"Not a Drop\" Law, if a driver under 21 years of age is found guilty of driving with a blood alcohol concentration (BAC) greater than __________, his or her license will be suspended.","explanation":"Under Utah's \"Not a Drop\" Law, a minor may not operate a motor vehicle while he or she has any measurable amount of alcohol in his or her system. If a juvenile is convicted for the first time of breaking this statute, his or her license will be revoked for six months or until the minor completes an alcohol evaluation and any recommendations given by an accredited substance addiction practitioner, whichever comes first. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":35229,"isBookmarked":0,"aid":"3281#4251#4252#35229","text":"0.04%#0.02%#0.05%#Zero"},
{"testId":1075,"qid":39388,"description":"If a driver under the age of 21 breaches Utah's \"Not a Drop\" law, his or her driving license will be suspended for at least:","explanation":"Under Utah's \"Not a Drop\" Law, a minor may not operate a motor vehicle while he or she has any measurable amount of alcohol in his or her system. If a juvenile is convicted for the first time of breaking this statute, his or her license will be revoked for six months or until the minor completes an alcohol evaluation and any recommendations given by an accredited substance addiction practitioner, whichever comes first. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":1075,"qid":39389,"description":"An adult motorist convicted of DUI for the first time will be forced to install an ignition interlock device (IID) in his or her car for:","explanation":"An ignition interlock device (IID) must be installed in any vehicle an adult driver runs for the next 18 months after receiving a first-time DUI conviction. The IID will keep track of the driver's blood alcohol content (BAC) and stop the engine from starting if it rises beyond a certain threshold. The rider must cease using the motorbike for the length of the IID installation time if they are unable to put an IID in it. [Utah Driver Handbook: Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Ignition Interlock Restricted Driver Suspension;]","imageName":"","correctAnswer":25031,"isBookmarked":0,"aid":"23152#23153#25031#25169","text":"3 months#6 months#18 months#12 months"},
{"testId":1075,"qid":39390,"description":"For how much of a period of time will a driver under 21 years of age be required to install an ignition interlock device (IID) if he or she is convicted of DUI?","explanation":"A motorist under the age of 21 who is convicted of DUI must install an ignition interlock device (IID) in any car he or she runs for three years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. If the driver is unable to install an IID in his or her motorbike, he or she must refrain from riding the vehicle for the length of the IID installation time. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1075,"qid":39391,"description":"A driver who is convicted of DUI for the second time must install an ignition interlock device (IID) in his or her car for a period of______ time.","explanation":"If a motorist is convicted of DUI for the second time, he or she must install an ignition interlock device (IID) in every car they operate for three years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. If the driver is unable to install an IID in his or her motorbike, he or she must refrain from riding the vehicle for the length of the IID installation time. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1075,"qid":39392,"description":"A driver convicted of felony DUI must install an ignition interlock device (IID) in his or her vehicle for:","explanation":"DUI is often classified as a Class B or Class A misdemeanor in Utah. DUI, on the other hand, will be regarded a third-degree felony under the following circumstances: (1) DUI causes a major accident, (2) two previous DUI convictions within the last 10 years, or (3) a prior conviction for criminal DUI or vehicular murder. A third-degree felony is punished by a $5,000 fine, up to five years in prison, or both. A motorist convicted of felony DUI must additionally install an ignition interlock device (IID) in any car he or she controls for six years. If the driver's blood alcohol content (BAC) exceeds a predetermined level, the IID will prohibit the car from starting. If the driver is unable to install an IID in his or her motorbike, he or she must refrain from riding the vehicle for the length of the IID installation time. [Restricted Driver Suspension with Ignition Interlock; Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"20790#26672#29881#29894","text":"4 years#5 years#6 years#7 years"},
{"testId":1075,"qid":39403,"description":"__ points will be added to your driving record for tailgating.","explanation":"If you are guilty of tailgating (following another vehicle too closely), you will get 60 points on your license. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"202006041812161292.jpg","correctAnswer":8141,"isBookmarked":0,"aid":"6591#8141#11513#12359","text":"100#60#40#80"},
{"testId":1075,"qid":39412,"description":"Without insurance or evidence of financial responsibility, you'll be fined at least ______ for driving a motorcycle without it.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"24978#27888#31696#33102","text":"$100#$250#$400#$300"},
{"testId":1075,"qid":39416,"description":"If you are found to have violated Utah's motorcycle helmet legislation, you might face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":29301,"isBookmarked":0,"aid":"23887#23889#29301#33103","text":"$500#$1,000#$750#$350"},
{"testId":1075,"qid":39417,"description":"What is the maximum permissible volume level for a motorcycle's exhaust system in Utah?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1075,"qid":39418,"description":"In Utah, your motorcycle liability insurance policy must provide at least __________ in bodily injury coverage for one person per accident, or $80,000 in total coverage for all bodily injuries and property damage per accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12048#12194","text":"$30,000#$20,000#$25,000#$40,000"},
{"testId":1075,"qid":39419,"description":"If you are convicted of DUI and were carrying a passenger under the age of 16 at the time of the violation, you might face up to ______  in jail.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1075,"qid":39421,"description":"Before taking the motorcycle skill test, an aspiring biker under the age of 19 must:","explanation":"An prospective biker must receive and retain a motorcycle learner permit for at least two months before he or she may take the motorcycle competence test for the motorcycle endorsement. However, this requirement may be removed if the rider completes an authorised Basic Rider Course (BRC) initially designed by the Motorcycle Safety Foundation.","imageName":"","correctAnswer":37218,"isBookmarked":0,"aid":"37217#37218#41200#41201","text":"do both (a) and (b)#do either (a) or (b)#(a) hold a motorcycle learner permit for at least two months#(b) complete an approved Basic Rider Course (BRC)"},
{"testId":1076,"qid":39423,"description":"A driver under the age of 21 who is found guilty of carrying or consuming alcohol may be fined _____ for the first offense.","explanation":null,"imageName":"","correctAnswer":11833,"isBookmarked":0,"aid":"11349#11832#11833#12358","text":"$50#$100#$300#$200"},
{"testId":1076,"qid":39428,"description":"It is possible to be penalized as much as _____ if you are found guilty of lying on your motorcycle learner's permit or endorsement application.","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#33102","text":"$500#$1,000#$2,000#$300"},
{"testId":1076,"qid":39429,"description":"Your driving privileges will be revoked for ____ days if you are found guilty of lying on your motorcycle learner's permit or endorsement application.","explanation":null,"imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"5446#6355#6389#6477","text":"10#90#3#30"},
{"testId":1076,"qid":39437,"description":"_____ points will be added to your driving record if you are found guilty of carrying an item that prohibits you from retaining both hands on the handlebars.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":39440,"description":"Just _____ alcoholic drink(s) may impair driving.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1076,"qid":39451,"description":"If you are found guilty of running a red light, you will receive __ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1076,"qid":39452,"description":"If you are found guilty of running a stop sign, you will receive __ points on your driving record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1076,"qid":39459,"description":"Who in Vermont needs to have liability insurance or something similar?","explanation":null,"imageName":"","correctAnswer":10424,"isBookmarked":0,"aid":"10424#41059#41060#41061","text":"All drivers#Only drivers under 21 years of age#Only drivers under 18 years of age#Only drivers of vehicles that are less than ten years old"},
{"testId":1076,"qid":39460,"description":"Your motorcycle liability insurance policy in Vermont must provide at least __________ in coverage for the death or injury of a single person in the event of an accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3196#11658#12047#12048","text":"$50,000#$10,000#$15,000#$25,000"},
{"testId":1076,"qid":39461,"description":"Your motorcycle liability insurance policy in Vermont must offer at least ______ in coverage for the deaths or injuries of two or more people in a single accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#12047#12048#12194","text":"$50,000#$15,000#$25,000#$40,000"},
{"testId":1076,"qid":39462,"description":"In Vermont, your motorcycle liability insurance policy must include at least __________ in property damage coverage per accident.","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1076,"qid":39465,"description":"What is the maximum permissible decibel level for a motorcycle's exhaust system in Vermont?","explanation":null,"imageName":"","correctAnswer":897,"isBookmarked":0,"aid":"897#40987#40988#40989","text":"None of the above#89 dBA at a distance of 25 feet#82 dBA at a distance of 50 feet#86 dBA at over 35 mph and 82 dBA at 35 mph or less, both at a distance of 50 feet"},
{"testId":1076,"qid":39466,"description":"According to the Vermont Motorcycle Manual, approximately ____ of all motorcyclists died in motorcycle accidents were under the influence of alcohol.","explanation":"According to the Vermont Motorcycle Manual, investigations have shown that 37 percent of the motorcyclists killed in motorcycle accidents had consumed alcohol. [The Importance of This Information; Getting in Shape to Ride; Vermont Motorcycle Manual]","imageName":"","correctAnswer":11939,"isBookmarked":0,"aid":"11665#11939#12162#40812","text":"25%#40%#30%#15%"},
{"testId":1076,"qid":39467,"description":"____ points will be added to your driving record if you are found guilty of lane splitting with your motorcycle.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":39469,"description":"If you are caught riding without eye protection on a motorcycle without a windshield, you will receive __ points on your license.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":39470,"description":"Which of the following restrictions does NOT apply to your riding if you have a Vermont motorcycle learner's permit?","explanation":"A Vermont motorbike learner's permit is only valid in Vermont. You may not transport passengers or ride at night if you have a Vermont motorbike learner's permit. Any violation of these limitations will result in the revocation of the permission. [How to Get a Motorcycle Learner Permit in Vermont, Vermont Motorcycle Manual]","imageName":"","correctAnswer":35124,"isBookmarked":0,"aid":"32742#32744#35124#41062","text":"You may not carry passengers#You may not ride at night#You may not carry cargo#You may not ride outside of Vermont"},
{"testId":1076,"qid":39471,"description":"If you are involved in a significant traffic collision, you must file a crash report with the DMV as soon as possible.","explanation":null,"imageName":"","correctAnswer":20755,"isBookmarked":0,"aid":"20754#20755#41063#41064","text":"48 hours#72 hours#four days#one week"},
{"testId":1076,"qid":39472,"description":"To receive a motorcycle endorsement, you must be at least ___ years old.","explanation":"A motorbike endorsement may be obtained with either a junior or regular driver's license. To receive a junior driver's license, you must be at least 16 years old, and to obtain a normal driver's license, you must be at least 18 years old. Vermont Department of Motor Vehicles; \"Motorcycle Endorsement\"; https://dmv.vermont.gov/licenses/types-of-licenses-ids/motorcycle-endorsement]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11359#11361#11374","text":"15#18#16#17"},
{"testId":1077,"qid":39473,"description":"Someone else owns the motorcycle you are riding. A police officer pulls you over because the exhaust system on your motorcycle is making too much noise. Who can get a fine if they are found guilty?","explanation":null,"imageName":"","correctAnswer":41336,"isBookmarked":0,"aid":"40928#41335#41336#41337","text":"Only you#Only the owner#Both you and the owner#Either you or the owner, depending on which of you takes responsibility"},
{"testId":1077,"qid":39474,"description":"You will be fined ______ if you are found guilty of lane splitting with your motorcycle.","explanation":null,"imageName":"","correctAnswer":24978,"isBookmarked":0,"aid":"24978#24994#24995#27902","text":"$100#$50#$150#$75"},
{"testId":1077,"qid":39475,"description":"You will be punished if you are caught riding a motorcycle without a working headlight.","explanation":"Every motorbike in Virginia must have at least one functional headlight. If you are found to have broken this legislation, you will be fined $30. [Rule 3B:2. Uniform Fine Schedule; Virginia Supreme Court Rules, Part Three B: Traffic Infractions and Uniform Fine Schedule; July 1, 2017]","imageName":"","correctAnswer":33510,"isBookmarked":0,"aid":"24978#24994#33510#41338","text":"$100#$50#$30#$70"},
{"testId":1077,"qid":39500,"description":"You will be fined _______ for riding a motorcycle without a helmet.","explanation":null,"imageName":"","correctAnswer":24993,"isBookmarked":0,"aid":"24978#24993#24994#27902","text":"$100#$25#$50#$75"},
{"testId":1077,"qid":39518,"description":"A Virginia learner's permit allows you to ride a motorcycle while being supervised by an adult who:","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#41289#41339#41340","text":"meets all of the above requirements#is at least 21 years old if he or she is unrelated to you#is licensed to operate a motorcycle#is riding along with you in another motor vehicle"},
{"testId":1078,"qid":39523,"description":"As per the Washington Traffic Safety Commission, ____ of motorcycle fatalities in Washington State include a rider who is under the influence of drugs or alcohol.","explanation":"According to the Washington Traffic Safety Commission, drugs or alcohol are involved in 57 percent of motorcycle deaths in Washington State. [Washington Traffic Safety Commission, \"Motorcycles,\" https://wtsc.wa.gov/programs-priorities/motorcycles/]","imageName":"","correctAnswer":41497,"isBookmarked":0,"aid":"38914#41496#41497#41498","text":"37%#47%#57%#67%"},
{"testId":1078,"qid":39524,"description":"Which of the following consequences will you face if you are found guilty of operating a motorcycle while intoxicated (DUI)?","explanation":null,"imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#12374#18218#26643","text":"All of the above#License suspension#Jail#A fine"},
{"testId":1078,"qid":39532,"description":"In accordance with Washington State's Implied Agree Law, by driving a motor vehicle in Washington State, you implicitly consent to :","explanation":"Under Washington State's Implied Consent Law, by operating a motor vehicle in Washington State, you have implicitly consented to being tested for alcohol or drugs upon the request of a law enforcement officer. [Alcohol/Drugs and the Law; In Shape to Drive; Washington Driver Guide]","imageName":"","correctAnswer":35436,"isBookmarked":0,"aid":"8615#35227#35228#35436","text":"All of the above#release of your medical records#a search of your vehicle#chemical testing for alcohol or drugs"},
{"testId":1078,"qid":39535,"description":"First-time DUI arrests result in license suspensions of:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1078,"qid":39536,"description":"If a driver over the age of 21 gets arrested for DUI for the second time, his or her license will be suspended for:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1078,"qid":39539,"description":"If you are convicted of DUI for the first time, you will be fined up to:","explanation":null,"imageName":"","correctAnswer":3195,"isBookmarked":0,"aid":"290#1799#3195#11670","text":"$500#$1,000#$5,000#$2,500"},
{"testId":1078,"qid":39541,"description":"If a rider fails to secure a cargo and causes property damage, he or she will be prosecuted with :","explanation":"A rider who causes property damage with unsecured objects may be prosecuted with a misdemeanor. [Maria's Law; Washington Driver Guide; Before You Drive]","imageName":"","correctAnswer":12674,"isBookmarked":0,"aid":"12260#12674#45533#45534","text":"reckless driving#misdemeanor#gross misdemeanor#negligent driving"},
{"testId":1078,"qid":39543,"description":"You will be punished if you are caught riding a motorcycle without a valid motorcycle endorsement on your driver's license.","explanation":null,"imageName":"","correctAnswer":41501,"isBookmarked":0,"aid":"41499#41500#41501#41502","text":"$98#$73#$48#$33"},
{"testId":1078,"qid":39544,"description":"First-time DUI offenders with a minor passenger must install an ignition interlock device for an additional:","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#25032#25169","text":"9 months#6 months#24 months#12 months"},
{"testId":1078,"qid":39545,"description":"In the state of Washington, it is illegal to transport a passenger under the age of _____ on a motorcycle.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5449#7559#11342","text":"10#5#7#9"},
{"testId":1078,"qid":39554,"description":"If you are found guilty of using a handheld cell phone while driving, you will be fined at least ____ for the first offense.","explanation":null,"imageName":"","correctAnswer":41429,"isBookmarked":0,"aid":"41427#41428#41429#41430","text":"$86#$116#$136#$156"},
{"testId":1078,"qid":39555,"description":"For littering from your motorcycle on public roads, you may be fined up to ______.","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24994#27902#39536","text":"$100#$50#$75#$125"},
{"testId":1078,"qid":39556,"description":"If you're convicted of DUI with a minor passenger,聽:","explanation":null,"imageName":"","correctAnswer":34981,"isBookmarked":0,"aid":"34981#41431#41432#41433","text":"all of the above may happen#the ignition interlock device (IID) installation period will be increased#your jail sentence will be increased#child protective services may be notified"},
{"testId":1078,"qid":39559,"description":"Motorcyclists who ride with their handgrips above 30 inches higher than seat will be fined:","explanation":null,"imageName":"","correctAnswer":41501,"isBookmarked":0,"aid":"41499#41500#41501#41502","text":"$98#$73#$48#$33"},
{"testId":1078,"qid":39560,"description":"Which of the following statements about motorcycle riding are true while you have a Washington State motorcycle instruction permit?","explanation":null,"imageName":"","correctAnswer":23029,"isBookmarked":0,"aid":"23029#32742#41503#41504","text":"All of the above are true#You may not carry passengers#You must have your motorcycle instruction permit and driver license with you#You may not ride when it's dark"},
{"testId":1078,"qid":39562,"description":"To receive a motorcycle instruction permit, you must be at least ____ years old.","explanation":"You must have an intermediate or basic driver's license to receive an instruction permit (and meet some other requirements). You must be at least 16 years old to participate. [How to Get a Driver's License in Washington]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11361#32240#32241","text":"15#16#16 1/2#15 1/2"},
{"testId":1078,"qid":39563,"description":"Which of the following medical issues can make riding a motorcycle dangerous?","explanation":"Certain medical issues might make driving risky since they can make you faint or fall. Diabetes, epilepsy, and numerous cardiac diseases are among them. During the medical examination required to get a driving licence in the state of Washington, you are questioned about the existence of any such conditions. If this is the case, the DOL could ask for a medical expert's examination and certification that your health condition is being effectively handled. [Getting a Driver License: Medical and Vision Screening; Washington State Department of Licensing; https://www.dol.wa.gov/driverslicense/medicalvision], [Health; In Shape to Drive; Washington Driver Guide] .html]","imageName":"","correctAnswer":203,"isBookmarked":0,"aid":"203#32216#41436#41437","text":"All of the above#Epilepsy#Heart disease#Diabetes"},
{"testId":1078,"qid":39564,"description":"Before you can get a motorcycle instruction permit, your parent or guardian must sign a Parental Authorization Affidavit if you are under the age of:","explanation":null,"imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6425#11359#11360#11361","text":"25#18#21#16"},
{"testId":1078,"qid":39565,"description":"For how much duration a Washington State motorcycle instruction permit is valid?","explanation":"Motorcycle licenses are valid for 180 days and may be renewed once every five years. [Instruction Permit, Permit/Endorsement Obtaining, Washington Motorcycle Operator Manual]","imageName":"","correctAnswer":25858,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":1078,"qid":39566,"description":"In the state of Washington, the maximum permissible decibel levels for a motorcycle, as measured from 50 feet away, are:","explanation":null,"imageName":"","correctAnswer":41506,"isBookmarked":0,"aid":"41505#41506#41507#41508","text":"72 dBA at over 45 mph and 68 dBA at 45 mph or less#82 dBA at over 45 mph and 78 dBA at 45 mph or less#92 dBA at over 45 mph and 88 dBA at 45 mph or less#102 dBA at over 45 mph and 98 dBA at 45 mph or less"},
{"testId":1078,"qid":39567,"description":"If you are engaged in a traffic collision that necessitates the filing of an accident report, you must do so within four days or face penalties.","explanation":null,"imageName":"","correctAnswer":39393,"isBookmarked":0,"aid":"34981#39393#39395#41509","text":"all of the above may happen#your license may be suspended#you may be sentenced to jail#you may be fined"},
{"testId":1078,"qid":39568,"description":"If you receive a traffic penalty while riding your motorcycle in Idaho,:","explanation":null,"imageName":"","correctAnswer":41424,"isBookmarked":0,"aid":"38319#39869#41423#41424","text":"you may have to post bail until you answer the citation#your motorcycle may be impounded until you answer the citation#you will receive an additional citation from Washington State#you may return to Washington State and then answer the citation"},
{"testId":1078,"qid":39569,"description":"If you are caught riding a motorcycle without a helmet, you will be fined.","explanation":null,"imageName":"","correctAnswer":41501,"isBookmarked":0,"aid":"41499#41500#41501#41502","text":"$98#$73#$48#$33"},
{"testId":1078,"qid":39570,"description":"The fine for a first violation for riding a motorbike that exceeds Washington State's maximum noise restriction is聽:","explanation":null,"imageName":"","correctAnswer":41511,"isBookmarked":0,"aid":"41510#41511#41512#41513","text":"$33#$53#$73#$93"},
{"testId":1079,"qid":39573,"description":"The National Highway Road Safety Administration (NHTSA) reports that around _______ of all motorcyclists killed in traffic accidents in the United States were under the influence of alcohol at the time.","explanation":"According to the National Highway Traffic Safety Administration, about one-fourth of all motorcyclists engaged (killed or survived) in fatal traffic incidents in the United States in 2016 had a blood alcohol content (BAC) of 0.08 percent or above. This suggests they were legally drunk at the time. Furthermore, 37% of motorcyclists killed in single-vehicle accidents were under the influence of alcohol. [\"Traffic Safety Facts: Motorcycles,\" National Highway Traffic Safety Administration; https://crashstats.nhtsa.dot.gov/Api/Public/Publication/812492]","imageName":"","correctAnswer":11884,"isBookmarked":0,"aid":"10698#11884#33470#41593","text":"one-half#one-fourth#one-third#one-fifth"},
{"testId":1079,"qid":39592,"description":"If a minor was riding your motorcycle when you were convicted of DUI, your license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23884#24048#24049#27364","text":"15 days#60 days#90 days#45 days"},
{"testId":1079,"qid":39596,"description":"If a minor was riding your motorcycle when you were convicted of DUI, you could face up to _______聽in jail.","explanation":null,"imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"20789#23152#23153#25169","text":"9 months#3 months#6 months#12 months"},
{"testId":1079,"qid":39607,"description":"In West Virginia, your vehicle liability insurance policy must provide at least __________ in coverage for the physical injury or death of a single person in the event of an accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1079,"qid":39608,"description":"Your vehicle liability insurance policy in West Virginia must offer at least ______ in coverage for the physical injuries or deaths of two or more people in any one accident.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#11659#12047","text":"$50,000#$30,000#$20,000#$15,000"},
{"testId":1079,"qid":39609,"description":"Your vehicle liability insurance policy in West Virginia must offer at least ______ in property damage coverage in any one accident.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3198#11659#12047#12048","text":"$30,000#$20,000#$15,000#$25,000"},
{"testId":1079,"qid":39617,"description":"If you are caught riding a motorcycle without a helmet, you will be fined up to _____  on the first offense.","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"290#11349#11832#12358","text":"$500#$50#$100#$200"},
{"testId":1080,"qid":39627,"description":"By completing the Basic Rider Course or the Advanced Rider Course, you can remove ___ demerit points for motorcycle offences from your driving record.","explanation":"By completing the Basic Rider Course or the Advanced Rider Course, you may eliminate three demerit points for motorcycle offences from your driving record. To locate a training site near you, go to the Wisconsin Department of Transportation's \"Training Locations\" page (https://wisconsindot.gov/Pages/dmv/motorcycles/mc-how-aply/training-loc.aspx). [Wisconsin Motorcyclists' Handbook; Motorcycle Rider Courses]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1080,"qid":39639,"description":"According to the Wisconsin Motorcyclists' Handbook, _____ of all motorcyclists killed in motorcycle accidents had consumed alcohol.","explanation":"According to the Wisconsin Motorcyclists' Handbook, alcohol was consumed by 40 to 45 percent of the motorcyclists died in motorcycle accidents. One-third of these riders had an alcohol content (AC) that was higher than the legal limit. [Wisconsin Motorcyclists' Handbook; Getting in Shape to Ride]","imageName":"","correctAnswer":43812,"isBookmarked":0,"aid":"43810#43811#43812#43813","text":"10鈥15%#20鈥25%#40鈥45%#50-55%"},
{"testId":1080,"qid":39652,"description":"You must be at least _____ years old to apply for a Wisconsin Cycle Instruction Permit (CYCI).","explanation":"You must be at least 16 years old and complete additional conditions to get a Wisconsin Cycle Instruction Permit (CYCI). [Wisconsin Motorists' Handbook; Class M License in Four Steps]","imageName":"","correctAnswer":11361,"isBookmarked":0,"aid":"5447#11361#43757#43758","text":"15#16#14陆#15陆"},
{"testId":1080,"qid":39653,"description":"While in possession of a Wisconsin Cycle Instruction Permit (CYCI), your nighttime riding must be supervised by a person who :","explanation":null,"imageName":"","correctAnswer":35005,"isBookmarked":0,"aid":"35005#43392#43814#43815","text":"meets all of the above requirements#is at least 25 years old#holds a Class M license#has at least 2 years' licensed riding experience"},
{"testId":1080,"qid":39655,"description":"If you are caught riding without eye protection on a motorcycle without an approved windscreen, you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#33102","text":"$200#$100#$50#$300"},
{"testId":1080,"qid":39657,"description":"You have to tell someone about an accident that causes property damage of at least ____","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#11832","text":"$500#$1,000#$2,000#$100"},
{"testId":1080,"qid":39661,"description":"Motorcycle accidents involving substance usage result in ____ injuries.","explanation":"In traffic accidents involving drug misuse, 90 percent of motorcycle collisions result in injuries, compared to 33 percent of automotive incidents. Every year, over 2,100 motorcyclists are killed and another 50,000 are badly wounded in this sort of incident. [Wisconsin Motorcyclists' Handbook; Getting in Shape to Ride]","imageName":"","correctAnswer":32606,"isBookmarked":0,"aid":"11667#13619#32606#42853","text":"50%#60%#90%#70%"},
{"testId":1080,"qid":39662,"description":"Studies have shown that being awake for 18 hours can make you as dangerous to drive as if you had___(AC).","explanation":"Driver weariness is a major cause of car accidents. According to studies, staying up for 18 hours might impair your driving as much as having an alcohol content (AC) of 0.05. [Never Drive If Tired; Fatigue; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":2244,"isBookmarked":0,"aid":"2243#2244#2245#5401","text":"0.08#0.05#0.02#0.04"},
{"testId":1080,"qid":39663,"description":"Every ____ rise in blood alcohol concentration(BAC) almost doubles the probability of a fatal accident.","explanation":"Every 0.02 rise in alcohol content (AC) about doubles the chance of a fatal accident. This link seems to hold true for both bikes and automobiles. [Alcohol Content; Drinking and Driving; Motorists' Handbook; Wisconsin Department of Transportation], [\"Motorcycle Safety and Alcohol\"; Nicholas J. Ward; 2014 Motorcycle Safety Forum; Des Moines, IA]","imageName":"","correctAnswer":2245,"isBookmarked":0,"aid":"2244#2245#5401#14075","text":"0.05#0.02#0.04#0.01"},
{"testId":1080,"qid":39667,"description":"If you are found guilty of transmitting text messages (texting) on a handheld cell phone while driving, you could face a fine of up to $1,000.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"24977#24978#24994#31696","text":"$200#$100#$50#$400"},
{"testId":1080,"qid":39669,"description":"If you transport a minor without a helmet, you might be fined up to:","explanation":null,"imageName":"","correctAnswer":24977,"isBookmarked":0,"aid":"24977#24978#24994#33102","text":"$200#$100#$50#$300"},
{"testId":1080,"qid":39670,"description":"Motorcyclists who ride with both the legs on the same side of the motorcycle will be fined up to:","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"24977#24978#31696#33102","text":"$200#$100#$400#$300"},
{"testId":1080,"qid":39671,"description":"If caught riding a motorcycle during the day without turning on your headlight, you might face a fine of up to _______.","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"24977#24978#31696#33102","text":"$200#$100#$400#$300"},
{"testId":1081,"qid":39681,"description":"In Wyoming, you are required to carry at least _______ in bodily injury or death coverage per accident for every motor vehicle you drive.","explanation":null,"imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"11658#12047#12048#12194","text":"$10,000#$15,000#$25,000#$40,000"},
{"testId":1081,"qid":39682,"description":"In Wyoming, if you drive a motor vehicle, you must have at least _______ in bodily injury or death coverage for any collision involving two or more people.","explanation":null,"imageName":"","correctAnswer":3196,"isBookmarked":0,"aid":"3196#3198#12194#12925","text":"$50,000#$30,000#$40,000#$60,000"},
{"testId":1081,"qid":39684,"description":"First-time DWUI convictions result in聽license suspension for:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1081,"qid":39685,"description":"A DWUI offense is considered the second offense for a driver who is at least 21 years old, if it happens within____ years of its first offense.","explanation":"The punishment for a future DWUI conviction is determined by the driver's 10-year history of such infractions. [Driving While Intoxicated; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":1081,"qid":39686,"description":"If you are convicted of DWUI violation for the second time, your license will be revoked for:","explanation":"A second DWUI conviction results in a one-year ban. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#24049#26672","text":"6 months#1 year#90 days#5 years"},
{"testId":1081,"qid":39687,"description":"Third or subsequent DWUI convictions result in 聽license suspension for:","explanation":"A third or subsequent DWUI conviction results in a three-year license suspension. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1081,"qid":39693,"description":"Every motor vehicle you operate in Wyoming must be insured for a minimum of _______ property damage.","explanation":null,"imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12048","text":"$5,000#$10,000#$20,000#$25,000"},
{"testId":1081,"qid":39706,"description":"If you have no insurance and are involved in an accident, you can have your license reinstated if you pay a cash deposit for the total value of property damage plus _______ per injury.","explanation":"Provided you have no insurance and are involved in an accident, you may have your license restored if you submit a cash deposit for the entire amount of property damage plus $25,000 per injury. If there have been no judgements against you in the meantime, your monetary deposit may be repaid to you one year after the accident. [Uninsured Accident Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":12048,"isBookmarked":0,"aid":"3195#11658#12047#12048","text":"$5,000#$10,000#$15,000#$25,000"},
{"testId":1081,"qid":39718,"description":"Which of the following restrictions does NOT apply to you if you have a motorcycle instruction permit?","explanation":null,"imageName":"","correctAnswer":35331,"isBookmarked":0,"aid":"35328#35329#35330#35331","text":"You may not ride for more than 90 days unless you pass an on-cycle skills test#You may not ride unsupervised between 11:00 p.m. and 5:00 a.m. unless you are at least 17 years old#You may not carry a passenger#You may not ride on a limited-access highway"},
{"testId":1081,"qid":39719,"description":"If you pass the Wyoming Department of Transportation's Basic Rider Safety Education course, you will be allowed to skip _________ for a motorcycle endorsement.","explanation":"If you pass the WYDOT Basic Rider safety education course, you will get a course completion card. If you produce this card to Wyoming Driver Services while applying for a motorcycle endorsement, you will be entitled to waive both the motorcycle written exam and the on-cycle skills test. The card will be valid for two years after the course is completed. [Motorcycle Safety Education Courses; Motorcycle Equipment; Wyoming Rules of the Road for Motorcyclists], [Wyoming Department of Transportation, \"Motorcycle Safety,\" http://www.dot.state.wy.us/basic-rider]","imageName":"","correctAnswer":35334,"isBookmarked":0,"aid":"35332#35333#35334#35335","text":"only the written test#only the on-cycle skills test#both the written test and the on-cycle skills test#neither the written test nor the on-cycle skills test"},
{"testId":1081,"qid":39720,"description":"Who is required to wear a helmet under Wyoming motorcycle laws?","explanation":null,"imageName":"","correctAnswer":34579,"isBookmarked":0,"aid":"34577#34578#34579#34580","text":"Riders and passengers#Passengers but not riders#Riders under 18 and passengers under 18#Passengers under 18 but not riders under 18"},
{"testId":1081,"qid":39722,"description":"When riding a motorcycle in Wyoming, _______ are required to wear eye protection.","explanation":"When riding a motorbike in Wyoming, neither the rider nor the passenger is required to wear eye protection. Wearing eye protection is still a good idea to protect your eyes from the wind and particles carried by the wind. In fact, wearing face protection that protects your eyes as well as the rest of your face is an excellent idea.","imageName":"","correctAnswer":34584,"isBookmarked":0,"aid":"34581#34582#34583#34584","text":"riders and passengers#passengers but not riders#riders but not passengers#neither riders nor passengers"},
{"testId":1,"qid":81290,"description":"You're driving on a four-lane divided highway. You approach a school bus that has stopped on the opposite side of the highway with flashing red lights and an extended stop arm. What should you do?","explanation":"Normally, you must stop for a halted school or church bus with flashing red lights, regardless of which way it is driving. There are two exceptions to this provision in Alabama. (1) The school or church bus is parked on the opposite side of a four-lane divided roadway. (2) The school or church bus is stopped in a loading zone near to a four-lane divided highway, and pedestrians are not permitted to cross the roadway there. You do not have to halt in these cases, but you should continue with care. Because children may not understand the laws of the road, they may do something unexpected.","imageName":"","correctAnswer":28148,"isBookmarked":0,"aid":"19471#28148#42629#42630","text":"Slow down and proceed with caution#Proceed with caution#Stop and remain stopped until the red lights stop flashing and the stop arm is retracted#Stop, yield if necessary, and then proceed"},
{"testId":564,"qid":81295,"description":null,"explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"24048#24049#25858#26650","text":"60 days#90 days#180 days#One year"},
{"testId":1031,"qid":81295,"description":null,"explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"24048#24049#25858#26650","text":"60 days#90 days#180 days#One year"},
{"testId":564,"qid":81296,"description":"_______ is required to reinstate a suspended or revoked driver's license.","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11349#11832#12357#12398","text":"$50#$100#$150#$75"},
{"testId":1031,"qid":81296,"description":"_______ is required to reinstate a suspended or revoked driver's license.","explanation":null,"imageName":"","correctAnswer":11832,"isBookmarked":0,"aid":"11349#11832#12357#12398","text":"$50#$100#$150#$75"},
{"testId":564,"qid":81297,"description":"Which of the following convictions will result in the revocation of your license?","explanation":null,"imageName":"","correctAnswer":11603,"isBookmarked":0,"aid":"203#11603#33831#42760","text":"All of the above#Vehicular manslaughter#Habitual reckless driving#Attempting to flee a police officer"},
{"testId":1031,"qid":81297,"description":"Which of the following convictions will result in the revocation of your license?","explanation":null,"imageName":"","correctAnswer":11603,"isBookmarked":0,"aid":"203#11603#33831#42760","text":"All of the above#Vehicular manslaughter#Habitual reckless driving#Attempting to flee a police officer"},
{"testId":564,"qid":81298,"description":"After _____ , the points for a traffic ticket will be taken off your record by themselves.","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#23183#26672","text":"2 years#1 year#3 years#5 years"},
{"testId":1031,"qid":81298,"description":"After _____ , the points for a traffic ticket will be taken off your record by themselves.","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#23183#26672","text":"2 years#1 year#3 years#5 years"},
{"testId":564,"qid":81299,"description":"If you run a red light, you will receive ______  on your driving record.","explanation":null,"imageName":"","correctAnswer":4657,"isBookmarked":0,"aid":"4656#4657#4658#4659","text":"2 points#3 points#4 points#1 point"},
{"testId":1031,"qid":81299,"description":"If you run a red light, you will receive ______  on your driving record.","explanation":null,"imageName":"","correctAnswer":4657,"isBookmarked":0,"aid":"4656#4657#4658#4659","text":"2 points#3 points#4 points#1 point"},
{"testId":627,"qid":81303,"description":"You've arrived at an intersection with a STOP sign. Where must you stop?","explanation":"When approaching a junction controlled by a STOP sign, you must come to a complete stop before the stop line. If there is no stop line, you must come to a complete stop before approaching a designated or unmarked crossing. If there is no crosswalk, you must come to a complete stop before approaching the crossing. Yield to approaching cars and pedestrians. When it is safe to do so, move gently through the junction. [Regulatory Signs; Alabama Driver Manual; Chapter 5: Signs, Signals, and Road Markings]","imageName":"202001301744345845.jpg","correctAnswer":15438,"isBookmarked":0,"aid":"14279#14822#14823#15438","text":"Before the crosswalk#Before the stop line#Before entering the intersection#Whichever of the above you reach first"},
{"testId":627,"qid":81304,"description":"When is it permitted to turn left at a red light in Alabama?","explanation":null,"imageName":"202007120412393828.jpg","correctAnswer":26726,"isBookmarked":0,"aid":"6334#15970#26726#29958","text":"Never#Only when you are turning onto a one-way street from any other street#Only when you are turning from a one-way street onto another one-way street#At any time, unless otherwise posted"},
{"testId":565,"qid":81313,"description":"The number of points that are given for each type of violation depends on:","explanation":"Higher point values are allocated to violations that are more likely to contribute to crashes. For example, reckless driving is worth ten points, yet failing to indicate a turn 100 feet ahead of time is only worth two. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":42454,"isBookmarked":0,"aid":"42451#42452#42453#42454","text":"frequency#severity#likelihood of causing death or injury#likelihood of contributing to a crash"},
{"testId":1032,"qid":81313,"description":"The number of points that are given for each type of violation depends on:","explanation":"Higher point values are allocated to violations that are more likely to contribute to crashes. For example, reckless driving is worth ten points, yet failing to indicate a turn 100 feet ahead of time is only worth two. [A Few Words on \"Points,\" Alaska Driver Manual]","imageName":"","correctAnswer":42454,"isBookmarked":0,"aid":"42451#42452#42453#42454","text":"frequency#severity#likelihood of causing death or injury#likelihood of contributing to a crash"},
{"testId":565,"qid":81314,"description":"If you have been labeled a \"problem driver\" due to multiple moving violations, you may be required to ____________.","explanation":null,"imageName":"","correctAnswer":42460,"isBookmarked":0,"aid":"22471#31028#42459#42460","text":"do any of the above#surrender your license#drive supervised for a specified period#attend a DMV driver improvement interview"},
{"testId":1032,"qid":81314,"description":"If you have been labeled a \"problem driver\" due to multiple moving violations, you may be required to ____________.","explanation":null,"imageName":"","correctAnswer":42460,"isBookmarked":0,"aid":"22471#31028#42459#42460","text":"do any of the above#surrender your license#drive supervised for a specified period#attend a DMV driver improvement interview"},
{"testId":565,"qid":81315,"description":"How can you restore your driving privileges after the suspension period ends, when your license has been suspended?","explanation":"Pay the reinstatement fee and apply for a duplicate license at any DMV field office to recover your driving privileges once the suspension time has expired. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42472,"isBookmarked":0,"aid":"11616#42472#42473#42474","text":"Apply for a new license#Request a duplicate copy of your license from a DMV office#Ask the DMV for your old license back#Carry a passport"},
{"testId":1032,"qid":81315,"description":"How can you restore your driving privileges after the suspension period ends, when your license has been suspended?","explanation":"Pay the reinstatement fee and apply for a duplicate license at any DMV field office to recover your driving privileges once the suspension time has expired. [Alaska Driver Manual, Suspensions and Revocations]","imageName":"","correctAnswer":42472,"isBookmarked":0,"aid":"11616#42472#42473#42474","text":"Apply for a new license#Request a duplicate copy of your license from a DMV office#Ask the DMV for your old license back#Carry a passport"},
{"testId":565,"qid":81316,"description":"If you were engaged in an accident in which someone was gravely hurt, a law enforcement official may order you to submit to :","explanation":"A law enforcement official may request a sample of your blood or urine if you were involved in a collision in which someone was killed or badly wounded. The goal is to see whether you were driving while under the influence of alcohol or drugs. The Supreme Court concluded in Birchfield v. North Dakota (2016) that state Implied Consent rules cannot oblige you to submit to a blood test without a search warrant. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42488,"isBookmarked":0,"aid":"35227#42484#42485#42488","text":"release of your medical records#a chemical sweat test#a chemical breath test#a chemical blood or urine test"},
{"testId":1032,"qid":81316,"description":"If you were engaged in an accident in which someone was gravely hurt, a law enforcement official may order you to submit to :","explanation":"A law enforcement official may request a sample of your blood or urine if you were involved in a collision in which someone was killed or badly wounded. The goal is to see whether you were driving while under the influence of alcohol or drugs. The Supreme Court concluded in Birchfield v. North Dakota (2016) that state Implied Consent rules cannot oblige you to submit to a blood test without a search warrant. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42488,"isBookmarked":0,"aid":"35227#42484#42485#42488","text":"release of your medical records#a chemical sweat test#a chemical breath test#a chemical blood or urine test"},
{"testId":565,"qid":81317,"description":"If you have been arrested for DUI but refuse to submit to an alcohol chemistry test,:","explanation":"If you are caught for DUI but refuse to take a chemical test for alcohol, you will be charged with both DUI and Refusal. These charges might be dealt with individually by the court. If you are found guilty of both offenses, you may face different punishments. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42490,"isBookmarked":0,"aid":"29914#42489#42490#42491","text":"your vehicle will be impounded#you will be jailed#you will be charged with Refusal as well as DUI#all of the above will occur"},
{"testId":1032,"qid":81317,"description":"If you have been arrested for DUI but refuse to submit to an alcohol chemistry test,:","explanation":"If you are caught for DUI but refuse to take a chemical test for alcohol, you will be charged with both DUI and Refusal. These charges might be dealt with individually by the court. If you are found guilty of both offenses, you may face different punishments. [Implied Consent Law Allows, Implied Consent, Alaska Driver Manual]","imageName":"","correctAnswer":42490,"isBookmarked":0,"aid":"29914#42489#42490#42491","text":"your vehicle will be impounded#you will be jailed#you will be charged with Refusal as well as DUI#all of the above will occur"},
{"testId":628,"qid":81318,"description":"When are high-beam headlights need to be dimmed?","explanation":"When you are near 500 feet of an incoming vehicle or within 300 feet of a vehicle you are following in Alaska, you must lower your high lights. Avoid flashing your high lights on any other car that is occupied. If you decrease your high lights before they shine on other cars, those other drivers may reciprocate. [Lighting Law, Lighting Equipment Use, Alaska Driver Manual]","imageName":"202002211503506612.jpg","correctAnswer":40781,"isBookmarked":0,"aid":"40781#40782#40783#40784","text":"Whenever you are within 500 feet of an oncoming vehicle or following another vehicle within 300 feet#Whenever you are within 300 feet of an oncoming vehicle or following another vehicle within 500 feet#Whenever you are within 1,000 feet of any other vehicle#Whenever you are within 500 feet of any other vehicle"},
{"testId":628,"qid":81319,"description":"Two cars are turning left onto a split highway. Which car is turning properly?","explanation":"Unless signs or pavement markings indicate otherwise, you should cross as few lanes as possible while turning. That is, you should turn left from the left lane or right from the right lane and into the next lane of traffic travelling in the proper direction. Car A has appropriately turned into the nearest lane and then merged into the distant lane. Car B has made an erroneous turn into the furthest lane. [Left Turn, Alaska Driver Handbook]","imageName":"202002181106054384.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":3,"qid":81320,"description":"If you want to go through an intersection with a turn, you should:","explanation":"If you want to turn beyond an intersection, wait until you are in the junction to signal (drivers in the intersection may pull into your path). If you signal before entering the junction, another car may believe you want to turn.","imageName":"202007210445346932.jpg","correctAnswer":43495,"isBookmarked":0,"aid":"22535#38227#40708#43495","text":"not give any turn signal#signal just before you make the turn#signal before you enter the intersection#not signal until you are in the intersection"},
{"testId":7,"qid":81322,"description":"Tires that are underinflated or not properly balanced may have:","explanation":"When the road is wet, worn or bald tires may increase stopping distance and make turning more difficult. Unbalanced tires or low tire pressures may result in quicker tire wear and poorer fuel efficiency, as well as making the vehicle more difficult to steer and stop.","imageName":"202006141750589683.jpg","correctAnswer":8848,"isBookmarked":0,"aid":"8615#8848#8849#37183","text":"All of the above#faster tire wear#decreased stopping distance#improved fuel economy"},
{"testId":566,"qid":81327,"description":"If a graduated driver license holder is convicted of a third traffic offense, which of the following additional penalties will he or she face?","explanation":"If a graduated driver license holder is convicted of a third traffic infringement, his or her license will be suspended for six months and the offense will be recorded on his or her driving record. This suspension is in addition to any penalty imposed for the traffic offence. [Arizona Driver License Manual, Penalties, Graduated License (Class G), License Classes]","imageName":"","correctAnswer":43560,"isBookmarked":0,"aid":"43559#43560#43561#43562","text":"A three-month suspension of driving privileges#A six-month suspension of driving privileges#A twelve-month suspension of driving privileges#Suspension of driving privileges until the driver turns 18"},
{"testId":566,"qid":81328,"description":"Second-time offenders convicted of knowingly supplying alcohol to minors face a license suspension for up to:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#1272#1273#1454","text":"90 days#6 months#12 months#9 months"},
{"testId":1033,"qid":81328,"description":"Second-time offenders convicted of knowingly supplying alcohol to minors face a license suspension for up to:","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"229#1272#1273#1454","text":"90 days#6 months#12 months#9 months"},
{"testId":566,"qid":81329,"description":"If a driver under the age of 21 is convicted of driving while under the influence of alcohol, his or her driver license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1033,"qid":81329,"description":"If a driver under the age of 21 is convicted of driving while under the influence of alcohol, his or her driver license will be suspended for a period of:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":566,"qid":81330,"description":"If you fail to stop at a minor accident in which you were involved, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1033,"qid":81330,"description":"If you fail to stop at a minor accident in which you were involved, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":566,"qid":81331,"description":"If you fail to stop at an accident in which you were involved and there were no injuries but damage to vehicles, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1033,"qid":81331,"description":"If you fail to stop at an accident in which you were involved and there were no injuries but damage to vehicles, your license will be revoked for:","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":4,"qid":81336,"description":"Use the ________ to ensure you leave adequate distance in front of your vehicle while following another vehicle in excellent driving conditions.","explanation":"Under normal driving circumstances, employ the two-second rule to prevent following another vehicle too closely. Consider following the four-second rule while driving in bad or dangerous circumstances.","imageName":"","correctAnswer":532,"isBookmarked":0,"aid":"531#532#533","text":"three-second rule#two-second rule#four-second rule"},
{"testId":4,"qid":81337,"description":"A middle lane with a solid yellow line and a dashed yellow line on either side is allocated for ________ and can be used by cars driving in either direction.","explanation":"A shared center turn lane (also known as a two-way left-turn lane) is designated for making left turns (and, where authorized, U-turns) and may be utilized by cars driving in either direction. In Arkansas, unlike in several other states, cars may turn left into this lane before merging right into another lane. A shared center turn lane is denoted by a solid yellow line and a dashed yellow line on either side. This lane should never be utilized for passing or navigating through traffic. Use care in this lane since cars heading in the other way may be utilizing it as well.","imageName":"","correctAnswer":578,"isBookmarked":0,"aid":"576#578#579","text":"making right turns#making left turns#passing"},
{"testId":568,"qid":81355,"description":"If a California driver is guilty of escaping an on-duty peace officer without causing physical harm, he or she may face which of the following penalties?","explanation":"Any driver who knowingly tries to elude or run from a peace officer while doing their responsibilities while driving a motor vehicle is guilty of a misdemeanour punished by up to one year in a county jail. [Rules; Administrative; California Driver Handbook; Evading a Peace Officer]","imageName":"","correctAnswer":42296,"isBookmarked":0,"aid":"9350#41831#42296","text":"Both of the above#A fine of up to $1,000#Up to one year in jail"},
{"testId":1035,"qid":81355,"description":"If a California driver is guilty of escaping an on-duty peace officer without causing physical harm, he or she may face which of the following penalties?","explanation":"Any driver who knowingly tries to elude or run from a peace officer while doing their responsibilities while driving a motor vehicle is guilty of a misdemeanour punished by up to one year in a county jail. [Rules; Administrative; California Driver Handbook; Evading a Peace Officer]","imageName":"","correctAnswer":42296,"isBookmarked":0,"aid":"9350#41831#42296","text":"Both of the above#A fine of up to $1,000#Up to one year in jail"},
{"testId":568,"qid":81356,"description":"If you've been convicted of careless driving that resulted in someone else's injuries, you might face up to_____in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#42315","text":"6 months#1 year#8 months"},
{"testId":1035,"qid":81356,"description":"If you've been convicted of careless driving that resulted in someone else's injuries, you might face up to_____in prison.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#42315","text":"6 months#1 year#8 months"},
{"testId":568,"qid":81357,"description":"If you hurt someone seriously while trying to get away from the police, you could get a fine of at least _____.","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25034#25035","text":"$2,000#$3,000#$4,000"},
{"testId":1035,"qid":81357,"description":"If you hurt someone seriously while trying to get away from the police, you could get a fine of at least _____.","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"25033#25034#25035","text":"$2,000#$3,000#$4,000"},
{"testId":6,"qid":81365,"description":"Which one of the following is correct?","explanation":"Before making a turn or changing lanes in a metropolitan or urban region, cars must signal continuously for 100 feet.","imageName":"","correctAnswer":23929,"isBookmarked":0,"aid":"23926#23927#23928#23929","text":"In urban areas, you must signal continuously for 200 feet before making a turn#In metropolitan areas, you must signal continuously for 50 feet before making a turn#At speeds above 55 mph, you must signal continuously for 100 feet before making a lane change#In metropolitan areas, you must signal continuously for 100 feet before making a turn"},
{"testId":569,"qid":81370,"description":"In Colorado, minor drivers between the ages of 18 and 21 who accumulate __ points on their driver's license will face immediate suspension.","explanation":"A Colorado driver between the ages of 18 and 21 who accumulates 14 or more points on his or her driver's license during the term of the license will have his or her license suspended immediately. [Minor Driver Between the Ages of 18 and 21, Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":1036,"qid":81370,"description":"In Colorado, minor drivers between the ages of 18 and 21 who accumulate __ points on their driver's license will face immediate suspension.","explanation":"A Colorado driver between the ages of 18 and 21 who accumulates 14 or more points on his or her driver's license during the term of the license will have his or her license suspended immediately. [Minor Driver Between the Ages of 18 and 21, Colorado Point System, The Driver License, Colorado Driver Handbook]","imageName":"","correctAnswer":11784,"isBookmarked":0,"aid":"5446#6478#11361#11784","text":"10#12#16#14"},
{"testId":569,"qid":81371,"description":"A CO driver's license will be revoked for how much duration if he or she is convicted of driving under the influence (DUI) for the first time.","explanation":"The first DUI conviction for an adult motorist in Colorado results in a 9-month licence suspension. [Possible Penalties for Driving While Intoxicated or Under the Influence of Drugs, Alcohol, or Other Substances, Colorado Driver Handbook]","imageName":"","correctAnswer":25091,"isBookmarked":0,"aid":"11612#11614#25090#25091","text":"for 6 months#indefinitely#for 3 months#for 9 months"},
{"testId":1036,"qid":81371,"description":"A CO driver's license will be revoked for how much duration if he or she is convicted of driving under the influence (DUI) for the first time.","explanation":"The first DUI conviction for an adult motorist in Colorado results in a 9-month licence suspension. [Possible Penalties for Driving While Intoxicated or Under the Influence of Drugs, Alcohol, or Other Substances, Colorado Driver Handbook]","imageName":"","correctAnswer":25091,"isBookmarked":0,"aid":"11612#11614#25090#25091","text":"for 6 months#indefinitely#for 3 months#for 9 months"},
{"testId":569,"qid":81372,"description":"In Colorado, a driver's license will be suspended for a period of _______, if he or she refuses a chemical test.","explanation":"If a person refuses to take the test or does not follow the testing protocol, their driving license will be suspended for 60 days and they will be compelled to install an interlock device on each car they drive for two years. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23153#24048#31866#32934","text":"6 months#60 days#300 days#60 months"},
{"testId":1036,"qid":81372,"description":"In Colorado, a driver's license will be suspended for a period of _______, if he or she refuses a chemical test.","explanation":"If a person refuses to take the test or does not follow the testing protocol, their driving license will be suspended for 60 days and they will be compelled to install an interlock device on each car they drive for two years. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23153#24048#31866#32934","text":"6 months#60 days#300 days#60 months"},
{"testId":569,"qid":81373,"description":"______ are not permitted in driving under the influence(DUI) prosecutions underlaw.","explanation":"Plea bargains are not permitted in DUI prosecutions under Colorado law. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":12076,"isBookmarked":0,"aid":"848#12075#12076#12077","text":"Any of the above#Reduced sentences#Plea bargains#Rescheduled court dates"},
{"testId":1036,"qid":81373,"description":"______ are not permitted in driving under the influence(DUI) prosecutions underlaw.","explanation":"Plea bargains are not permitted in DUI prosecutions under Colorado law. [Express Consent Law, Drunk Driving, Colorado Driver Handbook]","imageName":"","correctAnswer":12076,"isBookmarked":0,"aid":"848#12075#12076#12077","text":"Any of the above#Reduced sentences#Plea bargains#Rescheduled court dates"},
{"testId":569,"qid":81374,"description":"Underage drivers with a blood alcohol concentration (BAC) of _______ to ________ face mandatory license revocation.","explanation":"A Colorado motorist under the age of 21 who has a BAC level of at least.02 percent but less than.08 percent while driving a motor vehicle will have his or her driver's license revoked.","imageName":"","correctAnswer":12083,"isBookmarked":0,"aid":"12082#12083#12084#12085","text":".02%; .05%#.02%; .08%#.05%; .08%#.00%; .05%"},
{"testId":1036,"qid":81374,"description":"Underage drivers with a blood alcohol concentration (BAC) of _______ to ________ face mandatory license revocation.","explanation":"A Colorado motorist under the age of 21 who has a BAC level of at least.02 percent but less than.08 percent while driving a motor vehicle will have his or her driver's license revoked.","imageName":"","correctAnswer":12083,"isBookmarked":0,"aid":"12082#12083#12084#12085","text":".02%; .05%#.02%; .08%#.05%; .08%#.00%; .05%"},
{"testId":632,"qid":81375,"description":"Is it permissible to turn left at a red signal?","explanation":"On a red light from one one-way street to another one-way street, you may turn left. Keep an eye out for any warning symptoms. [10.1a Traffic Signals, 10.1 Traffic Controls, 10. Traffic Rules, Colorado Driver Handbook]","imageName":"202003112014364009.jpg","correctAnswer":25319,"isBookmarked":0,"aid":"25224#25318#25319#25320","text":"No, never#Yes, unless it is prohibited by a sign#Only from a one-way street onto another one-way street#Only when you are turning onto a one-way street from any other street"},
{"testId":7,"qid":81381,"description":"If you fail a BAC chemical test, your driver's license will be suspended for at least :","explanation":"If you are caught for driving while intoxicated, the police will request that you submit to a chemical test to determine your blood alcohol concentration (BAC). If you fail this exam, you will have your driver's license suspended for at least 45 days. For at least six months, you will also be required to install and maintain an ignition interlock device (IID) in each car you drive. If your breath alcohol concentration (BrAC) exceeds a predetermined level, the IID will prohibit your car from starting.","imageName":"","correctAnswer":27364,"isBookmarked":0,"aid":"23886#24048#24049#27364","text":"30 days#60 days#90 days#45 days"},
{"testId":7,"qid":81382,"description":"Which of the statements below is false?","explanation":"You must never exceed the maximum speed limit in Connecticut. Some limited-access split roads, such as interstates, have posted speed restrictions. You may be penalized for driving slower than this minimal speed unless it is required for your safety due to dangerous driving circumstances. You must always drive at a reasonable and sensible pace for the present driving circumstances, even if such speed is less than the maximum speed limit. Slow down significantly below the speed limit, for example, while driving on a snowy or slippery road. Even if you are not breaking the speed limit, you might be charged for driving too fast for the circumstances.","imageName":"","correctAnswer":41448,"isBookmarked":0,"aid":"41448#41578#43863#43864","text":"On a two-lane road, you may exceed the speed limit to pass another vehicle safely#You can be cited for driving at the maximum speed limit#Driving much slower than the other vehicles can be as bad as speeding#Posted minimum speed limits are not enforceable"},
{"testId":23,"qid":81383,"description":"A solid yellow line usually denotes ________ of a four-lane divided highway or a one-way road.","explanation":"A solid yellow line generally denotes the left border of the pavement on a four-lane divided highway or a one-way road.","imageName":"202003161910051809.jpg","correctAnswer":22478,"isBookmarked":0,"aid":"9155#22476#22477#22478","text":"The center of the roadway#the right edge of the pavement#the end of the roadway#the left edge of the pavement"},
{"testId":7,"qid":81385,"description":"Make sure there is no traffic approaching _______ before turning right at an intersection.","explanation":"Make sure there is no traffic arriving from the left and no incoming traffic turning left into your path before turning right at a junction. Also, give way to people crossing the road you're about to enter.","imageName":"202003070857473536.jpg","correctAnswer":22190,"isBookmarked":0,"aid":"22190#22191#24349#24350","text":"from your left and no oncoming traffic turning left into your path#from your right and no oncoming traffic turning right into your path#from your right and no oncoming traffic turning left into your path#from your left and no oncoming traffic turning right into your path"},
{"testId":633,"qid":81387,"description":"Which of the following statements about Connecticut is FALSE?","explanation":null,"imageName":"202003161149128995.jpg","correctAnswer":43429,"isBookmarked":0,"aid":"35039#43429#43957#43958","text":"You must use your headlights from a half hour after sunset to a half hour before sunrise#You may use your parking lights when driving in dim light#You must use your headlights whenever visibility is poor#You should use low-beam headlights when driving in fog, heavy rain, or heavy snow"},
{"testId":8,"qid":81395,"description":"You should use _____ to avoid driving faster than the distance you can see ahead of you.","explanation":null,"imageName":"","correctAnswer":43336,"isBookmarked":0,"aid":"43334#43335#43336#43337","text":"the two-second sight distance rule#the three-second sight distance rule#the four-second sight distance rule#the one-second sight distance rule"},
{"testId":8,"qid":81396,"description":"The areas surrounding trucks and buses where accidents are most likely to occur are referred to as :","explanation":"Do not congregate in a No-Zone! No-Zones are vast regions surrounding trucks, buses, and other big vehicles where automobiles might vanish into blind spots or come so near that the truck driver's ability to stop or move safely is hampered. There are no-zones in the vehicle's front, back, and sides. It is hard to totally avoid the No-Zones of a heavy vehicle. However, do not stay in a No-Zone for any longer than is absolutely required to safely pass the vehicle. Never, ever tailgate a truck.","imageName":"","correctAnswer":26081,"isBookmarked":0,"aid":"26081#43345#43346#43347","text":"No-Zones#Danger Zones#Zero-zones#Stop zones"},
{"testId":571,"qid":81401,"description":"Your driving privileges may be suspended for up to _______,if you are found to be a persistent offender.","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20790#23183#26672#29894","text":"4 years#3 years#5 years#7 years"},
{"testId":1038,"qid":81401,"description":"Your driving privileges may be suspended for up to _______,if you are found to be a persistent offender.","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20790#23183#26672#29894","text":"4 years#3 years#5 years#7 years"},
{"testId":571,"qid":81402,"description":"If you are guilty of _____ traffic infractions in _____ years, you will be labelled a habitual offender.","explanation":null,"imageName":"","correctAnswer":12254,"isBookmarked":0,"aid":"12254#12255#12256#43406","text":"10; 3#5; 2#8; 3#12; 6"},
{"testId":1038,"qid":81402,"description":"If you are guilty of _____ traffic infractions in _____ years, you will be labelled a habitual offender.","explanation":null,"imageName":"","correctAnswer":12254,"isBookmarked":0,"aid":"12254#12255#12256#43406","text":"10; 3#5; 2#8; 3#12; 6"},
{"testId":571,"qid":81403,"description":"If you are found guilty of exceeding the posted speed limit by 1 to 9 mph, _____ point(s) will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1038,"qid":81403,"description":"If you are found guilty of exceeding the posted speed limit by 1 to 9 mph, _____ point(s) will be added to your driving record.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":571,"qid":81404,"description":"Your driver's license will be suspended for _____ if the computed point value on your driving record rises to 14 in the next two years.","explanation":null,"imageName":"","correctAnswer":39797,"isBookmarked":0,"aid":"23153#23886#24048#39797","text":"6 months#30 days#60 days#4 months"},
{"testId":1038,"qid":81404,"description":"Your driver's license will be suspended for _____ if the computed point value on your driving record rises to 14 in the next two years.","explanation":null,"imageName":"","correctAnswer":39797,"isBookmarked":0,"aid":"23153#23886#24048#39797","text":"6 months#30 days#60 days#4 months"},
{"testId":571,"qid":81405,"description":"Your driver's license will be suspended for one year if the calculated point value on your driving record reaches ___ within two years.","explanation":null,"imageName":"","correctAnswer":12292,"isBookmarked":0,"aid":"5448#11359#11361#12292","text":"20#18#16#22"},
{"testId":1038,"qid":81405,"description":"Your driver's license will be suspended for one year if the calculated point value on your driving record reaches ___ within two years.","explanation":null,"imageName":"","correctAnswer":12292,"isBookmarked":0,"aid":"5448#11359#11361#12292","text":"20#18#16#22"},
{"testId":571,"qid":81406,"description":"If your driver's license has been suspended due to a high number of points,:","explanation":null,"imageName":"","correctAnswer":43410,"isBookmarked":0,"aid":"31384#39396#43410#43411","text":"you will be fined#all of the above may occur#you will have to complete a driver improvement course#you will have to drive under supervision"},
{"testId":1038,"qid":81406,"description":"If your driver's license has been suspended due to a high number of points,:","explanation":null,"imageName":"","correctAnswer":43410,"isBookmarked":0,"aid":"31384#39396#43410#43411","text":"you will be fined#all of the above may occur#you will have to complete a driver improvement course#you will have to drive under supervision"},
{"testId":571,"qid":81407,"description":"If you are found guilty of exceeding the posted speed limit by 1 to 14 mph, NO points will be added to your driving record if this is your first traffic ticket of the year.","explanation":null,"imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26651#31350#31351#43416","text":"Two years#Three years#Four years#a year"},
{"testId":1038,"qid":81407,"description":"If you are found guilty of exceeding the posted speed limit by 1 to 14 mph, NO points will be added to your driving record if this is your first traffic ticket of the year.","explanation":null,"imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26651#31350#31351#43416","text":"Two years#Three years#Four years#a year"},
{"testId":634,"qid":81408,"description":"Is it legal in Delaware to turn left at a persistent red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":634,"qid":81409,"description":"The center lane of this road is divided from the other lanes by solid and fractured yellow lines. Which of the following statements about this lane is true?","explanation":null,"imageName":"202001301806403967.jpg","correctAnswer":6172,"isBookmarked":0,"aid":"6172#25339#26782#43434","text":"All of the above are correct#This lane is accessible to traffic moving in both directions#This lane is only for left turns and U-turns#The flow of traffic may reverse in this lane; watch for relevant signs or signals"},
{"testId":572,"qid":81418,"description":"If you have a GRAD learner permit and accumulate ____ or more points on your driving record, your permission will be suspended for 90 days.","explanation":"If you have a GRAD learner permit and acquire 8 or more points on your driving record, your learner permit will be suspended and you will be required to pay a reinstatement fee to restore driving privileges. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Learner Permit Stage]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#5449#6478#7620","text":"10#5#12#8"},
{"testId":1039,"qid":81418,"description":"If you have a GRAD learner permit and accumulate ____ or more points on your driving record, your permission will be suspended for 90 days.","explanation":"If you have a GRAD learner permit and acquire 8 or more points on your driving record, your learner permit will be suspended and you will be required to pay a reinstatement fee to restore driving privileges. [Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual, Learner Permit Stage]","imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#5449#6478#7620","text":"10#5#12#8"},
{"testId":572,"qid":81419,"description":"If you have a provisional license and earn ___ points or more, your license will be suspended.","explanation":"If you have a provisional license and accrue 10 or more points on your driving record, it will be suspended. [Provisional License Stage, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":1039,"qid":81419,"description":"If you have a provisional license and earn ___ points or more, your license will be suspended.","explanation":"If you have a provisional license and accrue 10 or more points on your driving record, it will be suspended. [Provisional License Stage, Gradual Rearing of Adult Drivers (GRAD) Program, Types of Driver Licenses and Permits, District of Columbia Driving Manual]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#6478#6916#7620","text":"10#12#6#8"},
{"testId":572,"qid":81420,"description":"Your license will be suspended for ______, if you accumulate 10 or 11 points on your driving record.","explanation":"Your license will be suspended for 90 days if you acquire 10 or 11 points on your driving record. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":1039,"qid":81420,"description":"Your license will be suspended for ______, if you accumulate 10 or 11 points on your driving record.","explanation":"Your license will be suspended for 90 days if you acquire 10 or 11 points on your driving record. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23154#24048#24049#25858","text":"1 year#60 days#90 days#180 days"},
{"testId":572,"qid":81421,"description":"Your driver license will be withdrawn for _________, if you accumulate 12 or more points.","explanation":"If you get 12 or more points, your license will be suspended for 6 months. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":1039,"qid":81421,"description":"Your driver license will be withdrawn for _________, if you accumulate 12 or more points.","explanation":"If you get 12 or more points, your license will be suspended for 6 months. [District of Columbia Driving Manual, Important Things to Know]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":635,"qid":81422,"description":"Two cars arrive at the same moment at an uncontrolled junction(i.e. one without signs or signals). Which of the following is correct?","explanation":"When two automobiles approach at an uncontrolled junction at the same moment, the left car must yield to the right car. [Right-of-Way, Driver Information, Driving Manual of the District of Columbia]","imageName":"202007120521353376.jpg","correctAnswer":25279,"isBookmarked":0,"aid":"15618#25279#25280#26796","text":"One driver must wave the other driver through#Car A must yield to the right#Car B must yield to the left#Car A must yield because it is turning"},
{"testId":635,"qid":81423,"description":"Which of the following about using your headlights is NOT true?","explanation":"When visibility is poor at 500 feet or less, you should use your headlights from half an hour after dusk to half an hour before daybreak. You must also turn on your headlights while entering a tunnel and when using your windshield wipers. You must not drive with just your parking lights on. [Driving Rules, Using Your Headlights, District of Columbia Driving Manual]","imageName":"202003161149128995.jpg","correctAnswer":25387,"isBookmarked":0,"aid":"25387#26835#26836#26837","text":"You can use your parking lights to make your car more visible to other drivers#You must use your headlights whenever you turn on your windshield wipers#You must use your headlights when visibility is poor at 500 feet or less#You must use your headlights when you are driving through a tunnel"},
{"testId":635,"qid":81424,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Lane usage signals regulate traffic flow on lanes whose direction changes during the day. A flashing yellow light above your lane signals that it is solely for left turns. Use care in this lane; motorists moving in the other way may also use it for left turns. [District of Columbia Driving Manual, Lane Use Signals, Signals, Signs, and Markings]","imageName":"202003051707075416.jpg","correctAnswer":15682,"isBookmarked":0,"aid":"15682#26851#26852#26853","text":"This lane is for left turns only#You can drive in this lane#You must proceed with caution if you drive in this lane#You must exit this lane; the signal will soon turn red and the lane will close"},
{"testId":10,"qid":81425,"description":"This symbol denotes:","explanation":"This sign denotes the presence of a double bend ahead. The road turns to the right and then to the left ahead. (A winding road sign would be placed instead if there was a triple bend coming.) Slow down, stay to the right, and do not pass.","imageName":"202002061305216978.jpg","correctAnswer":27018,"isBookmarked":0,"aid":"19534#22299#27018#41735","text":"a winding road ahead#a right turn ahead#a double curve ahead#a curve to the right ahead"},
{"testId":10,"qid":81426,"description":"When riding a bike on a one-way street with two or more lanes, you can ride :","explanation":null,"imageName":"","correctAnswer":38488,"isBookmarked":0,"aid":"38488#41747#41748#41920","text":"(a) or (b)#(a) on the left-hand side of the roadway#(b) near the right edge of the roadway#(c) in the center of the roadway"},
{"testId":573,"qid":81432,"description":"How many points will be added to your driving record, if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":81432,"description":"How many points will be added to your driving record, if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":573,"qid":81433,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1040,"qid":81433,"description":"Conviction for the careless driving will add____points to driving record.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":574,"qid":81443,"description":"How many non-relatives under the age of 21 will the driver be permitted to transport after the first year of holding a provisional driver's license?","explanation":"After the first year of obtaining a provisional driver's license, a motorist may transport immediate family members and up to three non-immediate family members under the age of 21. \"Immediate family members\" are defined as family members who live at the driver's home. [Conditions of a Class D Provisional License, Georgia Drivers Manual, Section 2: Obtaining a License, Permit, or Identification Card]","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":574,"qid":81444,"description":"_____ points may be added to your driving record for hindering traffic by driving too slowly in the passing lane on multilane roads.","explanation":"Georgia has a law known as the \"Slowpoke Law,\" which states that if you are impeding cars behind you who want to travel quicker, you must move out of the passing lane. If you are convicted of breaking this statute, you might face a $1,000 fine or three points on your license. [Traffic Laws, Section 5: Traveling Speed, Georgia Drivers Manual]","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":1041,"qid":81444,"description":"_____ points may be added to your driving record for hindering traffic by driving too slowly in the passing lane on multilane roads.","explanation":"Georgia has a law known as the \"Slowpoke Law,\" which states that if you are impeding cars behind you who want to travel quicker, you must move out of the passing lane. If you are convicted of breaking this statute, you might face a $1,000 fine or three points on your license. [Traffic Laws, Section 5: Traveling Speed, Georgia Drivers Manual]","imageName":"","correctAnswer":6209,"isBookmarked":0,"aid":"2753#2754#6209#10514","text":"Two#Four#Three#One"},
{"testId":574,"qid":81445,"description":"If you're caught driving with an open alcoholic beverage container, your driving record will be added with____ points.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":1041,"qid":81445,"description":"If you're caught driving with an open alcoholic beverage container, your driving record will be added with____ points.","explanation":null,"imageName":"","correctAnswer":2753,"isBookmarked":0,"aid":"2753#2754#5000#6209","text":"Two#Four#Six#Three"},
{"testId":574,"qid":81446,"description":"_____ points will be added to your driving record if you are convicted of speeding 34 mph or more above the posted speed limit.","explanation":"If you are guilty of exceeding the legal speed limit by 34 mph or more, you will be fined and six points will be added to your driving record. The maximum number of points for a single infringement in Georgia is six. If you are under the age of 21, a single four- or six-point conviction will result in your license being suspended for six months for a first offense. [The Points System, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":1041,"qid":81446,"description":"_____ points will be added to your driving record if you are convicted of speeding 34 mph or more above the posted speed limit.","explanation":"If you are guilty of exceeding the legal speed limit by 34 mph or more, you will be fined and six points will be added to your driving record. The maximum number of points for a single infringement in Georgia is six. If you are under the age of 21, a single four- or six-point conviction will result in your license being suspended for six months for a first offense. [The Points System, Georgia Drivers Manual, Section 10: Losing Your Driving Privileges]","imageName":"","correctAnswer":5000,"isBookmarked":0,"aid":"2754#5000#5002#6209","text":"Four#Six#Five#Three"},
{"testId":637,"qid":81447,"description":"What should you do if you see a flashing yellow light?","explanation":"When a yellow traffic light is flashing, you should slow down and drive cautiously. There may be a danger up ahead. [Georgia Drivers Manual, Traffic Signals and Signs, Section 7: Signs, Signals, and Markings]","imageName":"202003070823014609.jpg","correctAnswer":19471,"isBookmarked":0,"aid":"19471#23457#29963#30157","text":"Slow down and proceed with caution#Prepare to stop; the light will soon turn red#Stop, yield, and proceed when it is safe to do so#Go straight; you cannot turn here"},
{"testId":637,"qid":81448,"description":"What does it mean if you notice a steady yellow \"X\" above your lane?","explanation":"Lane control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A persistent yellow \"X\" above your lane indicates that it will soon shut to traffic in your direction. The indicator will then shift to a constant red \"X.\" Before this occurs, you must change lanes. [Lane Control Signals, Traffic Signals, and Signs; Georgia Drivers Manual, Section 7: Signs, Signals, and Markings]","imageName":"202003051705034327.jpg","correctAnswer":39691,"isBookmarked":0,"aid":"15682#28148#39691#41583","text":"This lane is for left turns only#Proceed with caution#Change lanes as soon as it is safe to do so#This lane is closed in your direction"},
{"testId":637,"qid":81449,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Lane control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A flashing yellow \"X\" across your lane indicates that you may turn left here. A persistent yellow \"X\" above your lane indicates that it will soon shut to traffic in your direction. [Lane Control Signals, Traffic Signals, and Signs; Georgia Drivers Manual, Section 7: Signs, Signals, and Markings]","imageName":"202003051707075416.jpg","correctAnswer":43280,"isBookmarked":0,"aid":"28148#34681#41583#43280","text":"Proceed with caution#You must change lanes#This lane is closed in your direction#This lane is only for making left turns"},
{"testId":575,"qid":81461,"description":"A Hawaii driver's license will be automatically suspended for a period of ______ if his or her blood alcohol content (BAC) is 0.08 percent or higher.","explanation":"When a driver's blood alcohol level reaches 0.08 percent or more, the state of Hawaii will revoke his or her license administratively. The driver's license will be suspended for thirty days, and a temporary driver's permit will be given. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Administrative License Revocation]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":1042,"qid":81461,"description":"A Hawaii driver's license will be automatically suspended for a period of ______ if his or her blood alcohol content (BAC) is 0.08 percent or higher.","explanation":"When a driver's blood alcohol level reaches 0.08 percent or more, the state of Hawaii will revoke his or her license administratively. The driver's license will be suspended for thirty days, and a temporary driver's permit will be given. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Administrative License Revocation]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#24049","text":"10 days#30 days#60 days#90 days"},
{"testId":575,"qid":81462,"description":"If a Hawaii driver who already has a long record refuses a BAC test, the maximum license revocation duration is:","explanation":"If a Hawaii driver with many previous offenses rejects a BAC test, his or her license might be banned for up to four years. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1042,"qid":81462,"description":"If a Hawaii driver who already has a long record refuses a BAC test, the maximum license revocation duration is:","explanation":"If a Hawaii driver with many previous offenses rejects a BAC test, his or her license might be banned for up to four years. [The Law, Chapter 9: Alcohol and Other Drugs, Official Hawaii Driver's Manual, Zero Tolerance]","imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":575,"qid":81463,"description":"All HI vehicles must be insured. What is the bare minimum for property damage coverage?","explanation":"Every vehicle operated inside the state of Hawaii is required to have property damage liability coverage of at least $10,000 per accident. [Chapter 8: Official Hawaii Driver's Manual: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11659#12047","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":1042,"qid":81463,"description":"All HI vehicles must be insured. What is the bare minimum for property damage coverage?","explanation":"Every vehicle operated inside the state of Hawaii is required to have property damage liability coverage of at least $10,000 per accident. [Chapter 8: Official Hawaii Driver's Manual: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"3195#11658#11659#12047","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":575,"qid":81464,"description":"All HI vehicles must be insured. What is the bare minimum liability coverage per person?","explanation":"The state of Hawaii mandates that all automobiles be insured with at least $20,000 in liability coverage per person. [Official Hawaii Driver's Manual, Motor Vehicle Insurance Law, Chapter 8: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"11658#11659#12048#12194","text":"$10,000#$20,000#$25,000#$40,000"},
{"testId":1042,"qid":81464,"description":"All HI vehicles must be insured. What is the bare minimum liability coverage per person?","explanation":"The state of Hawaii mandates that all automobiles be insured with at least $20,000 in liability coverage per person. [Official Hawaii Driver's Manual, Motor Vehicle Insurance Law, Chapter 8: Motor Vehicle Safety Responsibility Law]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"11658#11659#12048#12194","text":"$10,000#$20,000#$25,000#$40,000"},
{"testId":575,"qid":81465,"description":"Unlicensed residents who are convicted of DUI must wait at least ______ before seeking for a driver's license.","explanation":"A citizen without a licence who is found guilty of DUI must wait at least two years before applying for a licence. [Official Hawaii Driver's Manual, Who Cannot Be Licensed, Chapter 1: Licensing of Drivers]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"742#1021#11285#11341","text":"30 days#1 year#2 years#5 years"},
{"testId":1042,"qid":81465,"description":"Unlicensed residents who are convicted of DUI must wait at least ______ before seeking for a driver's license.","explanation":"A citizen without a licence who is found guilty of DUI must wait at least two years before applying for a licence. [Official Hawaii Driver's Manual, Who Cannot Be Licensed, Chapter 1: Licensing of Drivers]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"742#1021#11285#11341","text":"30 days#1 year#2 years#5 years"},
{"testId":638,"qid":81467,"description":"Is it permissible to turn left at a red signal?","explanation":"You may turn left on a red light from a one-way street into another one-way street unless a sign prevents it. Before turning, you must come to a full stop and yield to oncoming vehicles and pedestrians. [Standard Three-Color Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003112014364009.jpg","correctAnswer":26820,"isBookmarked":0,"aid":"26820#28536#28537#28538","text":"Yes, but only from a one-way street onto another one-way street#Yes, unless a sign prohibits it#No, not in Hawaii#Yes, but only when you are turning onto a one-way street from any other street"},
{"testId":638,"qid":81468,"description":"Which vehicles are misusing the roundabout?","explanation":"Car B is driving inappropriately at this roundabout: it should have utilized the left lane since it has gone more than halfway around the roundabout to make a left turn. Car A did not break any regulations by traveling around the roundabout to attempt again after missing its exit. On a roundabout, you should never stop or reverse. [Roundabout, Official Hawaii Driver's Manual, Chapter X: Safe Driving Techniques]","imageName":"202003202132152250.jpg","correctAnswer":28866,"isBookmarked":0,"aid":"14814#15982#15983#28866","text":"Car A#Neither car#Both cars#Car B only"},
{"testId":638,"qid":81469,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Lane usage signals regulate traffic flow on lanes whose direction changes during the day. A flashing yellow light above your lane signals that it is solely for left turns. Use care in this lane; motorists moving in the other way may also use it for left turns. [Lane Control Signals, Traffic Signals, Official Hawaii Driver's Manual, Chapter VI: Traffic Signs, Signals, and Markings]","imageName":"202003051707075416.jpg","correctAnswer":15682,"isBookmarked":0,"aid":"15682#26851#26852#26853","text":"This lane is for left turns only#You can drive in this lane#You must proceed with caution if you drive in this lane#You must exit this lane; the signal will soon turn red and the lane will close"},
{"testId":13,"qid":81470,"description":"Before encountering another vehicle, you must dim your headlights 500 feet and _________ before overtaking another vehicle.","explanation":"You must lower your headlights 500 feet before approaching another vehicle and 200 feet before passing another car. When traveling at night, utilize headlights rather than parking lights.","imageName":"","correctAnswer":399,"isBookmarked":0,"aid":"214#399#400#1397","text":"100 feet#200 feet#300 feet#150 feet"},
{"testId":576,"qid":81475,"description":"A driver's license will be suspended for ____ days if he or she is guilty of three or more moving traffic offences while under the age of 17.","explanation":"If an Idaho driver under the age of 17 is convicted of three or more moving traffic violations, his or her license will be suspended for 60 days. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6802#8141#12168","text":"90#180#60#360"},
{"testId":1043,"qid":81475,"description":"A driver's license will be suspended for ____ days if he or she is guilty of three or more moving traffic offences while under the age of 17.","explanation":"If an Idaho driver under the age of 17 is convicted of three or more moving traffic violations, his or her license will be suspended for 60 days. [Graduated Driver's License (GDL) Penalties, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6802#8141#12168","text":"90#180#60#360"},
{"testId":576,"qid":81476,"description":"A driver's license will be suspended for ______ if he or she accumulates between 18 and 23 points in a 24-month-period.","explanation":"An Idaho driver will have his or her licence suspended for 90 days upon accumulation of 18 to 23 points within a 24-month period. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":1043,"qid":81476,"description":"A driver's license will be suspended for ______ if he or she accumulates between 18 and 23 points in a 24-month-period.","explanation":"An Idaho driver will have his or her licence suspended for 90 days upon accumulation of 18 to 23 points within a 24-month period. [Suspension and Point Violation System, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23886#24048#24049","text":"6 months#30 days#60 days#90 days"},
{"testId":576,"qid":81477,"description":"A driver in state will be compelled to provide proof of financial responsibility for three years if the state's insurance requirement is broken twice or more in the space of ____ years.","explanation":"If an Idaho driver violates the state's insurance mandate two or more times in five years, he or she will be required to provide proof of financial responsibility for three years. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1043,"qid":81477,"description":"A driver in state will be compelled to provide proof of financial responsibility for three years if the state's insurance requirement is broken twice or more in the space of ____ years.","explanation":"If an Idaho driver violates the state's insurance mandate two or more times in five years, he or she will be required to provide proof of financial responsibility for three years. [Getting Your Driving Privileges Back, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":576,"qid":81478,"description":"After _____ DUI convictions within ten years, the installation of an ignition interlock device becomes compulsory.","explanation":"Every DUI conviction, even a first offense, necessitates the installation of an ignition interlock device (IID). If the driver's blood alcohol content (BAC) is at or above a certain threshold, this device will prohibit the car from starting. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2754#5002#6209#10514","text":"Four#Five#Three#One"},
{"testId":1043,"qid":81478,"description":"After _____ DUI convictions within ten years, the installation of an ignition interlock device becomes compulsory.","explanation":"Every DUI conviction, even a first offense, necessitates the installation of an ignition interlock device (IID). If the driver's blood alcohol content (BAC) is at or above a certain threshold, this device will prohibit the car from starting. [Idaho Driver's Handbook, Driving Under the Influence, What Affects Your Driving Privilege And Record]","imageName":"","correctAnswer":10514,"isBookmarked":0,"aid":"2754#5002#6209#10514","text":"Four#Five#Three#One"},
{"testId":576,"qid":81479,"description":"If you were caught driving recklessly and had your license taken away, you must show proof of financial responsibility for:","explanation":"If your license was suspended for certain significant violations (reckless driving, driving under the influence, etc.), you must produce evidence that you can satisfy financial responsibilities resulting from any collision for three years. [How to Restore Your Driving Privileges, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":1043,"qid":81479,"description":"If you were caught driving recklessly and had your license taken away, you must show proof of financial responsibility for:","explanation":"If your license was suspended for certain significant violations (reckless driving, driving under the influence, etc.), you must produce evidence that you can satisfy financial responsibilities resulting from any collision for three years. [How to Restore Your Driving Privileges, What Affects Your Driving Privilege And Record, Idaho Driver's Handbook]","imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":576,"qid":81480,"description":"A driver with a Supervised Instruction Permit issued through the GDL program must complete at least ___ hours of supervised driving time.","explanation":null,"imageName":"","correctAnswer":7571,"isBookmarked":0,"aid":"6477#7571#8141#11513","text":"30#50#60#40"},
{"testId":639,"qid":81482,"description":"Which vehicles are misusing the roundabout?","explanation":"Car B is driving inappropriately at this roundabout: it should have utilized the left lane since it has gone more than halfway around the roundabout to make a left turn. Car A did not break any regulations by traveling around the roundabout to attempt again after missing its exit. On a roundabout, you should never stop or reverse. [Idaho Driver's Handbook, Intersections, Highway and Vehicle Knowledge]","imageName":"202003202132152250.jpg","correctAnswer":30142,"isBookmarked":0,"aid":"30139#30140#30141#30142","text":"Neither A nor B#Both A and B#A only#B only"},
{"testId":577,"qid":81487,"description":"The Graduated Driver Licensing Program (GDL) in Illinois applies to drivers between the ages :","explanation":"To guarantee that drivers between the ages of 15 and 20 get sufficient training, Illinois uses a Graduated Driver Licensing (GDL) Program.","imageName":"","correctAnswer":29246,"isBookmarked":0,"aid":"29245#29246#29247#29248","text":"15鈥18#15鈥20#16鈥18#16鈥21"},
{"testId":577,"qid":81488,"description":"If a driver under the age of 21 is convicted of ____ or more moving offences within two years, his or her license will be suspended for at least 30 days.","explanation":"If a motorist under the age of 21 gets convicted of two or more driving infractions within two years, his or her license will be revoked for at least 30 days.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1044,"qid":81488,"description":"If a driver under the age of 21 is convicted of ____ or more moving offences within two years, his or her license will be suspended for at least 30 days.","explanation":"If a motorist under the age of 21 gets convicted of two or more driving infractions within two years, his or her license will be revoked for at least 30 days.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":577,"qid":81489,"description":"A driver under the age of 21 who is sentenced to court supervision for an alcohol-related offense will have his or her license suspended for:","explanation":"A motorist under the age of 21 will have his or her license suspended for three months if he or she is put under court supervision for an alcohol-related violation.","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#25170#27363","text":"3 months#6 months#1 month#2 months"},
{"testId":577,"qid":81490,"description":"Your license will be suspended for _________ if you refuse to submit to chemical testing.","explanation":"Your license will be suspended for one year if you refuse to submit to chemical testing at the request of a law enforcement officer.","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":1044,"qid":81490,"description":"Your license will be suspended for _________ if you refuse to submit to chemical testing.","explanation":"Your license will be suspended for one year if you refuse to submit to chemical testing at the request of a law enforcement officer.","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":577,"qid":81491,"description":"A driver's license will be suspended for one year if he or she is convicted of a second DUI violation within ___ years.","explanation":"If an Illinois driver is convicted of a second DUI charge within five years, his or her license will be suspended for one year.","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1044,"qid":81491,"description":"A driver's license will be suspended for one year if he or she is convicted of a second DUI violation within ___ years.","explanation":"If an Illinois driver is convicted of a second DUI charge within five years, his or her license will be suspended for one year.","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":577,"qid":81492,"description":"If a driver who has previously been convicted of DUI or refusal during the last five years refuses a chemical test, his or her driver's license will be suspended for ___ years.","explanation":"A driver's license will be revoked for three years if he or she rejects a chemical test and has a previous DUI or refusal offense within the last five years.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1044,"qid":81492,"description":"If a driver who has previously been convicted of DUI or refusal during the last five years refuses a chemical test, his or her driver's license will be suspended for ___ years.","explanation":"A driver's license will be revoked for three years if he or she rejects a chemical test and has a previous DUI or refusal offense within the last five years.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":577,"qid":81493,"description":"After how many DUI convictions, you will lose your license for life?","explanation":"You will lose your license for life if you are convicted of four or more DUIs.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1044,"qid":81493,"description":"After how many DUI convictions, you will lose your license for life?","explanation":"You will lose your license for life if you are convicted of four or more DUIs.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":577,"qid":81494,"description":"If a driver under the age of 21 refuses to submit to chemical testing, his or her license will be suspended for ______.","explanation":"If a minor driver refuses to submit to a chemical test when requested, their licence will be suspended for six months.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":1044,"qid":81494,"description":"If a driver under the age of 21 refuses to submit to chemical testing, his or her license will be suspended for ______.","explanation":"If a minor driver refuses to submit to a chemical test when requested, their licence will be suspended for six months.","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#20789#23153#23154","text":"2 years#9 months#6 months#1 year"},
{"testId":640,"qid":81495,"description":"When is it permissible to cross a pair of double solid yellow lines?","explanation":"When turning left into or out of an alley, driveway, private road, or street, you may cross a set of solid yellow lines. [Yellow Center Lines, Pavement Markings, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202001290916213788.jpg","correctAnswer":16057,"isBookmarked":0,"aid":"6334#15959#15961#16057","text":"Never#When you need to turn or pass another vehicle#Only when you must because of an unusual situation#When you need to turn left into a driveway or alley"},
{"testId":640,"qid":81496,"description":"Is it permissible to turn left at a red signal?","explanation":"On a red light from one one-way street to another one-way street, you may turn left. Before making the left turn, you must come to a full stop and then yield to all pedestrians and oncoming vehicles. (Note: Some states have various regulations regarding left turns on red.) Some states also permit left turns from a two-way roadway into a one-way street on red. Other states, on the other hand, outright ban left turns on red. Always verify the local traffic rules while driving to another location.) [Steady Lights, Traffic Signals, Illinois Rules of the Road, Chapter 10: Traffic Signals and Pavement Markings]","imageName":"202003112014364009.jpg","correctAnswer":26820,"isBookmarked":0,"aid":"25318#26820#29339#29340","text":"Yes, unless it is prohibited by a sign#Yes, but only from a one-way street onto another one-way street#No, not in Illinois#Yes, but only onto a one-way street from any other street"},
{"testId":15,"qid":81500,"description":"You must signal your plan to change lanes _______ in advance on a multilane route with a speed restriction of 50 mph or higher.","explanation":"On a multilane highway with a speed limit of less than 50 mph, you must indicate your plan to change lanes at least 200 feet ahead of time. If the speed limit is 50 miles per hour or higher, you must communicate your intention at least 300 feet ahead of time. At 50 mph, your car will go 300 feet in roughly 4 seconds.","imageName":"","correctAnswer":2029,"isBookmarked":0,"aid":"2026#2027#2028#2029","text":"at least 100 feet#at least 50 feet#at least 200 feet#at least 300 feet"},
{"testId":578,"qid":81504,"description":"If you refuse to submit to chemical testing when a law enforcement officer requests it, your license will be taken and suspended for up to :","explanation":"If you refuse to submit to chemical testing when a law enforcement official requests it, your license will be seized and suspended for up to two years.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#23886#26672","text":"2 years#1 year#30 days#5 years"},
{"testId":1045,"qid":81504,"description":"If you refuse to submit to chemical testing when a law enforcement officer requests it, your license will be taken and suspended for up to :","explanation":"If you refuse to submit to chemical testing when a law enforcement official requests it, your license will be seized and suspended for up to two years.","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23154#23886#26672","text":"2 years#1 year#30 days#5 years"},
{"testId":578,"qid":81505,"description":"After having your license suspended for failing to provide proof of financial responsibility, you will be required to maintain financial responsibility insurance for:","explanation":"If your driver's license is suspended for failing to submit evidence of financial responsibility, you must maintain financial responsibility insurance (SR22) for three years after your license is reinstated.","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1045,"qid":81505,"description":"After having your license suspended for failing to provide proof of financial responsibility, you will be required to maintain financial responsibility insurance for:","explanation":"If your driver's license is suspended for failing to submit evidence of financial responsibility, you must maintain financial responsibility insurance (SR22) for three years after your license is reinstated.","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":578,"qid":81506,"description":"Under Indiana's habitual traffic offender program, a driver convicted of _____ or more serious traffic offences in ten years will have his or her license suspended for ten years.","explanation":"A motorist who is convicted of three or more significant traffic crimes during a 10-year period will have his or her license suspended for ten years under Indiana's Habitual Violator program.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1045,"qid":81506,"description":"Under Indiana's habitual traffic offender program, a driver convicted of _____ or more serious traffic offences in ten years will have his or her license suspended for ten years.","explanation":"A motorist who is convicted of three or more significant traffic crimes during a 10-year period will have his or her license suspended for ten years under Indiana's Habitual Violator program.","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":578,"qid":81507,"description":"How many points will be added to your driving record if you fail to yield to an emergency vehicle when mandated to do so?","explanation":"If you fail to yield to an emergency vehicle when necessary, you will get 6 points on your driving record.","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":578,"qid":81508,"description":"The BMV聽will require you to complete a defensive driving course if you are found guilty of________聽 or more traffic violations in the previous 12 months.","explanation":"The Bureau of Motor Vehicles will compel you to attend a defensive driving course if you are convicted of two or more traffic offenses in one year.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1045,"qid":81508,"description":"The BMV聽will require you to complete a defensive driving course if you are found guilty of________聽 or more traffic violations in the previous 12 months.","explanation":"The Bureau of Motor Vehicles will compel you to attend a defensive driving course if you are convicted of two or more traffic offenses in one year.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":579,"qid":81520,"description":"If a driver's license was obtained fraudulently or improperly, it will be:","explanation":"An Iowa driver's licence will be cancelled if it was obtained illegally or unlawfully. This includes providing incorrect information or a false name on the application for the driver's licence.","imageName":"","correctAnswer":12006,"isBookmarked":0,"aid":"11281#11284#12006#12787","text":"revoked#suspended#cancelled#barred"},
{"testId":1046,"qid":81520,"description":"If a driver's license was obtained fraudulently or improperly, it will be:","explanation":"An Iowa driver's licence will be cancelled if it was obtained illegally or unlawfully. This includes providing incorrect information or a false name on the application for the driver's licence.","imageName":"","correctAnswer":12006,"isBookmarked":0,"aid":"11281#11284#12006#12787","text":"revoked#suspended#cancelled#barred"},
{"testId":579,"qid":81521,"description":"After how many moving violation(s), the Iowa DOT may suspend a minor's driver's license.","explanation":"A motorist under the age of 18 may have his or her license suspended if convicted of only one traffic offence.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1046,"qid":81521,"description":"After how many moving violation(s), the Iowa DOT may suspend a minor's driver's license.","explanation":"A motorist under the age of 18 may have his or her license suspended if convicted of only one traffic offence.","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":579,"qid":81522,"description":"Driving with a聽suspended license for reasons other than alcohol or drugs will add聽__ points to your record.","explanation":"If you are convicted of driving while your license is suspended or revoked for reasons other than alcohol or drugs, you will get two points on your driving record.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1046,"qid":81522,"description":"Driving with a聽suspended license for reasons other than alcohol or drugs will add聽__ points to your record.","explanation":"If you are convicted of driving while your license is suspended or revoked for reasons other than alcohol or drugs, you will get two points on your driving record.","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":579,"qid":81523,"description":"DUI convictions add ___ points to a driver's record.","explanation":"If you are convicted of driving under the influence (DUI), you will get four points on your license.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1046,"qid":81523,"description":"DUI convictions add ___ points to a driver's record.","explanation":"If you are convicted of driving under the influence (DUI), you will get four points on your license.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":579,"qid":81524,"description":"If you reach too many points total, your driving privileges could be聽:","explanation":"If you accrue too many points on your driving record, you may be deemed a chronic offender and your driving privileges may be revoked.","imageName":"","correctAnswer":30345,"isBookmarked":0,"aid":"11436#11439#30344#30345","text":"revoked#suspended#cancelled#barred"},
{"testId":1046,"qid":81524,"description":"If you reach too many points total, your driving privileges could be聽:","explanation":"If you accrue too many points on your driving record, you may be deemed a chronic offender and your driving privileges may be revoked.","imageName":"","correctAnswer":30345,"isBookmarked":0,"aid":"11436#11439#30344#30345","text":"revoked#suspended#cancelled#barred"},
{"testId":579,"qid":81525,"description":"If you accumulate 10 to 12 points in six years, your driving privileges may be suspended for ___ years.","explanation":"If you acquire 10 to 12 points in a 6-year period, your driving rights may be suspended for 4 years.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1046,"qid":81525,"description":"If you accumulate 10 to 12 points in six years, your driving privileges may be suspended for ___ years.","explanation":"If you acquire 10 to 12 points in a 6-year period, your driving rights may be suspended for 4 years.","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":579,"qid":81526,"description":"If you fail your BAC test, you will be awarded a temporary license that is valid for ________ before the revocation or suspension term begins.","explanation":"The peace officer will take your license if you fail a blood alcohol content (BAC) test. The officer will grant you a temporary license that is good for 10 days before the suspension or revocation term begins. This will allow you to make suitable plans for your impending loss of driving privileges.","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#1020#12361","text":"10 days#30 days#60 days#3 days"},
{"testId":1046,"qid":81526,"description":"If you fail your BAC test, you will be awarded a temporary license that is valid for ________ before the revocation or suspension term begins.","explanation":"The peace officer will take your license if you fail a blood alcohol content (BAC) test. The officer will grant you a temporary license that is good for 10 days before the suspension or revocation term begins. This will allow you to make suitable plans for your impending loss of driving privileges.","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#1020#12361","text":"10 days#30 days#60 days#3 days"},
{"testId":579,"qid":81527,"description":"In Iowa, the maximum penalties for driving while your license is suspended is :","explanation":"If you are convicted of driving while your license is revoked, you might face a $1,000 fine.","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23889#23890#25033#27889","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":1046,"qid":81527,"description":"In Iowa, the maximum penalties for driving while your license is suspended is :","explanation":"If you are convicted of driving while your license is revoked, you might face a $1,000 fine.","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23889#23890#25033#27889","text":"$1,000#$1,500#$2,000#$2,500"},
{"testId":642,"qid":81528,"description":"On each side of this lane, there is a double dashed yellow line. What kind of lane is this?","explanation":"This is a lane that can be used both ways. The flow of traffic in this lane may alter at any moment. Keep an eye out for overhead or roadside signs or signals indicating the lane's current direction of traffic. [Iowa Driver's Manual, Reversible Lanes, Pavement Markings, and Other Lane Controls, 2. Traffic Signs and Rules of the Road]","imageName":"202004281848169040.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16099#28872#30411","text":"A reversible lane#A central painted median for parking#A shared center turning lane#A High-Occupancy Vehicle (HOV) lane"},
{"testId":642,"qid":81529,"description":"A bright yellow \"X\" appears above your lane. What does it imply?","explanation":"Lane control signals are often used in conjunction with reversible lanes. A green arrow indicates that you may use the lane. A red \"X\" indicates that the lane is closed. A constant yellow \"X\" indicates that the lane is going to shut. A flashing yellow \"X\" on the other hand indicates that the lane is strictly for turning. [Iowa Driver's Manual, Reversible Lanes, Pavement Markings, and Other Lane Controls, 2. Traffic Signs and Rules of the Road]","imageName":"202003051707075416.jpg","correctAnswer":30426,"isBookmarked":0,"aid":"30426#30427#30428#30429","text":"This is a turning lane#This lane is about to close#This lane is open, but proceed with caution#This lane is open; proceed at your usual speed"},
{"testId":580,"qid":81535,"description":"To obtain an unrestricted driver's license in Kansas, a driver must be at least _____ years old.","explanation":"To get an unrestricted driver's license in Kansas, a driver must be at least 17 years old. Restrictions unrelated to age or experience may still be enforced, such as the necessity to wear corrective glasses. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":1047,"qid":81535,"description":"To obtain an unrestricted driver's license in Kansas, a driver must be at least _____ years old.","explanation":"To get an unrestricted driver's license in Kansas, a driver must be at least 17 years old. Restrictions unrelated to age or experience may still be enforced, such as the necessity to wear corrective glasses. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":11374,"isBookmarked":0,"aid":"11359#11360#11361#11374","text":"18#21#16#17"},
{"testId":580,"qid":81536,"description":"A 15-year-old Kansas driver must have completed at least _____ hours of driving experience before applying for a limited driver's license.","explanation":"A 15-year-old Kansas driver must complete at least 25 hours of driving experience before applying for a limited driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#6425#7571#8141","text":"10#25#50#60"},
{"testId":1047,"qid":81536,"description":"A 15-year-old Kansas driver must have completed at least _____ hours of driving experience before applying for a limited driver's license.","explanation":"A 15-year-old Kansas driver must complete at least 25 hours of driving experience before applying for a limited driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":6425,"isBookmarked":0,"aid":"5446#6425#7571#8141","text":"10#25#50#60"},
{"testId":580,"qid":81537,"description":"What is the minimum age for a Kansas motorist to apply for a limited driver's license?","explanation":"At the age of 15, a Kansas resident may apply for a limited driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":1047,"qid":81537,"description":"What is the minimum age for a Kansas motorist to apply for a limited driver's license?","explanation":"At the age of 15, a Kansas resident may apply for a limited driver's license. [Kansas Driving Handbook, Types of Driver's Licenses and Permits, Chapter 1: The Driver's License]","imageName":"","correctAnswer":5447,"isBookmarked":0,"aid":"5447#11361#11374#11784","text":"15#16#17#14"},
{"testId":643,"qid":81538,"description":"Which vehicles are making proper use of this roundabout?","explanation":"Car A is following the rules of the roundabout. Car A seems to have missed its exit the first time, but its driver is doing the right thing by traveling all the way around the roundabout to try again. Car B should have made a left turn in the left lane. [Other Lane Controls, Kansas Driving Handbook, Section 6: Rules of the Road]","imageName":"202003202132152250.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":581,"qid":81548,"description":null,"explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1048,"qid":81548,"description":null,"explanation":null,"imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":81549,"description":"Which of the following violations will add 5 points to your driving record?","explanation":"If you are convicted of improper passing, you will get 5 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31011,"isBookmarked":0,"aid":"897#11621#31011#31012","text":"None of the above#Reckless driving#Improper passing#Speeding 26 mph or more over the speed limit"},
{"testId":1048,"qid":81549,"description":"Which of the following violations will add 5 points to your driving record?","explanation":"If you are convicted of improper passing, you will get 5 points on your driving record. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":31011,"isBookmarked":0,"aid":"897#11621#31011#31012","text":"None of the above#Reckless driving#Improper passing#Speeding 26 mph or more over the speed limit"},
{"testId":581,"qid":81550,"description":"For reckless driving, driving on the wrong side of the road, or following too closely (tailgating), you will receive ____ points on your driving record.","explanation":"If you are guilty of reckless driving, driving on the wrong side of the road, tailgating, failing to yield to an emergency vehicle, or changing drivers while your vehicle is in motion, you will get four points on your license. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":581,"qid":81551,"description":"For how much duration the points added to your driving record for conviction will remain in your driving record?","explanation":"Conviction points expire after two years, but the conviction itself stays on your driving record for five years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":1048,"qid":81551,"description":"For how much duration the points added to your driving record for conviction will remain in your driving record?","explanation":"Conviction points expire after two years, but the conviction itself stays on your driving record for five years. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23154#26672#29895","text":"2 years#1 year#5 years#10 years"},
{"testId":581,"qid":81552,"description":"If a Kentucky driver under the age of 18 accumulates ____ or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining the potential consequences.","explanation":"If a Kentucky driver under the age of 18 earns four or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining the potential consequences if the motorist accumulates more than six points before reaching the age of 18. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1048,"qid":81552,"description":"If a Kentucky driver under the age of 18 accumulates ____ or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining the potential consequences.","explanation":"If a Kentucky driver under the age of 18 earns four or more points in a two-year period, the Transportation Cabinet will issue the driver a warning letter outlining the potential consequences if the motorist accumulates more than six points before reaching the age of 18. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":581,"qid":81553,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her driver's license for a period of _______ for a third or subsequent accumulation of points during the two-year term.","explanation":"If a motorist aged 18 or older earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for two years for a third or subsequent accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11301","text":"1 year#6 months#2 years#18 months"},
{"testId":1048,"qid":81553,"description":"If a motorist over the age of 18 earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her driver's license for a period of _______ for a third or subsequent accumulation of points during the two-year term.","explanation":"If a motorist aged 18 or older earns 12 or more points in a two-year period, the Transportation Cabinet may suspend his or her license for two years for a third or subsequent accumulation of points during the two-year term. [Traffic Violations and Their Point Values, Driving Safety, Kentucky Driver Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11301","text":"1 year#6 months#2 years#18 months"},
{"testId":581,"qid":81554,"description":"DUI offenders must:","explanation":"If you are convicted of DUI, you will be evaluated by a Cabinet for Human Resources-licensed drug addiction program (CHR). Based on this evaluation, you will be expected to attend and complete an authorized CHR alcohol education or treatment program before your driving privileges may be restored. [Kentucky Driver Manual, Driver Substance Abuse Education Program, Protecting Your Driver's License]","imageName":"","correctAnswer":31027,"isBookmarked":0,"aid":"10656#31026#31027#31028","text":"Do all of the above#remain in jail for three days#receive an assessment in a licensed program#surrender your license"},
{"testId":1048,"qid":81554,"description":"DUI offenders must:","explanation":"If you are convicted of DUI, you will be evaluated by a Cabinet for Human Resources-licensed drug addiction program (CHR). Based on this evaluation, you will be expected to attend and complete an authorized CHR alcohol education or treatment program before your driving privileges may be restored. [Kentucky Driver Manual, Driver Substance Abuse Education Program, Protecting Your Driver's License]","imageName":"","correctAnswer":31027,"isBookmarked":0,"aid":"10656#31026#31027#31028","text":"Do all of the above#remain in jail for three days#receive an assessment in a licensed program#surrender your license"},
{"testId":644,"qid":81555,"description":"On a four-lane highway, you are on the right lane. You see an emergency vehicle stopped with its lights flashing in front of you. What should you do?","explanation":"If you come across an emergency vehicle with its lights flashing on the side of the road, you must move into a non-adjacent lane if it is safe to do so, leaving an empty lane between your vehicle and the emergency vehicle. If this is not feasible, continue slowly and with care. (Note: The relevant passage in the Kentucky Driver Manual is ambiguous on whether you must slow down even if you go into a non-adjacent lane.) You do not, according to KRS 189.930.) [Allowing a Defensive Driving Space Cushion, Keeping a Defensive Driving Space Cushion, Kentucky Driver Manual; KRS 189] .930]","imageName":"202001241703386758.jpg","correctAnswer":31084,"isBookmarked":0,"aid":"28148#31084#31085#31086","text":"Proceed with caution#Leave an empty lane between your vehicle and the emergency vehicle if possible; if not, slow down. Proceed with caution#Slow down. Leave an empty lane between your vehicle and the emergency vehicle if possible. Proceed with caution#Leave an empty lane between your vehicle and the emergency vehicle only when the speed limit exceeds 40 mph; otherwise, slow down. Proceed with caution"},
{"testId":19,"qid":81560,"description":"What does this number represent?","explanation":"You must follow the broken yellow centerline to the right. You may cross this line when it is safe to do so in order to pass another vehicle or turn.","imageName":"202002211536409341.jpg","correctAnswer":22589,"isBookmarked":0,"aid":"19312#22589#22590#22591","text":"Vehicles are not allowed to cross the broken yellow line to pass or turn#Vehicles may cross the broken yellow line to pass or turn if it is safe to do so#Vehicles are allowed to cross the broken yellow line only to pass if it is safe to do so#Vehicles are allowed to cross the broken yellow line only to turn if it is safe to do so"},
{"testId":19,"qid":81561,"description":"Large flashing arrow panels may be used in work zones to help drivers get into:","explanation":"Large flashing or sequential arrow panels may be used in construction zones both during the day and at night to direct cars into certain lanes and to notify them that a portion of the road or street ahead is blocked.","imageName":"202007210439127901.jpg","correctAnswer":35,"isBookmarked":0,"aid":"35#31178#31179#31180","text":"Guide drivers into certain traffic lanes#direct drivers through work zones#guide drivers through an intersection#stop drivers near work zones"},
{"testId":19,"qid":81562,"description":"Before making the turn, you must continuously signal for:","explanation":"Before making a turn, you must continually signal for at least 100 feet. When traveling at fast speeds or in heavy traffic, you should signal for a longer distance. (At 50 mph, your car will move 100 feet in under two seconds.)","imageName":"","correctAnswer":2011,"isBookmarked":0,"aid":"2011#2012#2013#2014","text":"not less than 100 feet#less than 100 feet#not less than 50 feet#not less than 75 feet"},
{"testId":582,"qid":81569,"description":"An adult convicted of DWI for the second time will have his or her license suspended for up to:","explanation":"If an adult is found guilty of DWI a second time, they might lose their licence for up to two years. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":26651,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":1049,"qid":81569,"description":"An adult convicted of DWI for the second time will have his or her license suspended for up to:","explanation":"If an adult is found guilty of DWI a second time, they might lose their licence for up to two years. [Latina Class D & E Driver's Guide, Chapter 9: Driving Offenses, Penalty for Driving While Intoxicated (DWI)]","imageName":"","correctAnswer":26651,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":582,"qid":81570,"description":"How long do you have to stay in jail if you get a fourth DWI in Louisiana?","explanation":"In Louisiana, the obligatory minimum prison term for a fourth DWI conviction is 30 days. The maximum period is 30 years. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1021","text":"90 days#10 days#30 days#1 year"},
{"testId":1049,"qid":81570,"description":"How long do you have to stay in jail if you get a fourth DWI in Louisiana?","explanation":"In Louisiana, the obligatory minimum prison term for a fourth DWI conviction is 30 days. The maximum period is 30 years. [Penalty for Driving While Intoxicated (DWI), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#739#742#1021","text":"90 days#10 days#30 days#1 year"},
{"testId":582,"qid":81571,"description":"If a 21-year-old or older Louisiana driver is cought with a blood alcohol content (BAC) of 0.08 percent or above, his or her license will be suspended for ____ days.","explanation":"If a 21-year-old or older Louisiana motorist is found to have a BAC of 0.08 percent or above, his or her license will be revoked for 90 days. [Administrative Per Se (Submit or Refuse Alcohol Analysis Test), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1049,"qid":81571,"description":"If a 21-year-old or older Louisiana driver is cought with a blood alcohol content (BAC) of 0.08 percent or above, his or her license will be suspended for ____ days.","explanation":"If a 21-year-old or older Louisiana motorist is found to have a BAC of 0.08 percent or above, his or her license will be revoked for 90 days. [Administrative Per Se (Submit or Refuse Alcohol Analysis Test), Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":6355,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":582,"qid":81572,"description":"How long will your driver's license be suspended if you are convicted of a second offense of driving with a blood alcohol concentration (BAC) of 0.08 or higher?","explanation":"A second conviction for a BAC of 0.08 percent or above will result in a one-year license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":1049,"qid":81572,"description":"How long will your driver's license be suspended if you are convicted of a second offense of driving with a blood alcohol concentration (BAC) of 0.08 or higher?","explanation":"A second conviction for a BAC of 0.08 percent or above will result in a one-year license suspension. [Losing Your Driving Privileges, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":582,"qid":81573,"description":"If you are found guilty of causing harm to another person by careless driving, you will be imprisoned for__________.","explanation":"If you are convicted of vehicular negligent injury, you might face up to six months in jail. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#25032#25169#27027","text":"6 months#24 months#12 months#36 months"},
{"testId":1049,"qid":81573,"description":"If you are found guilty of causing harm to another person by careless driving, you will be imprisoned for__________.","explanation":"If you are convicted of vehicular negligent injury, you might face up to six months in jail. [Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#25032#25169#27027","text":"6 months#24 months#12 months#36 months"},
{"testId":582,"qid":81574,"description":"If convicted of first-degree vehicular negligent injury, you face jail for聽up to:","explanation":"If you are convicted of first-degree vehicular negligent harm, you might face up to five years in jail. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":1049,"qid":81574,"description":"If convicted of first-degree vehicular negligent injury, you face jail for聽up to:","explanation":"If you are convicted of first-degree vehicular negligent harm, you might face up to five years in jail. [First Degree Vehicle Negligent Injury, Louisiana Class D & E Driver's Guide, Chapter 9: Driving Offenses]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":583,"qid":81581,"description":"If you refuse to submit to drug or alcohol testing, your driver's license will be suspended for up to :","explanation":null,"imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"23153#23154#23886#29881","text":"6 months#1 year#30 days#6 years"},
{"testId":1050,"qid":81581,"description":"If you refuse to submit to drug or alcohol testing, your driver's license will be suspended for up to :","explanation":null,"imageName":"","correctAnswer":29881,"isBookmarked":0,"aid":"23153#23154#23886#29881","text":"6 months#1 year#30 days#6 years"},
{"testId":583,"qid":81582,"description":"If you are convicted of OUI with a BAC of 0.15 percent or above, you will be sentenced to at least ________ in prison.","explanation":"If you are convicted of OUI with a BAC of 0.15 percent or above, you will be imprisoned for at least 48 hours. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"12827#12828#12840#13002","text":"24 hours#48 hours#72 hours#96 hours"},
{"testId":1050,"qid":81582,"description":"If you are convicted of OUI with a BAC of 0.15 percent or above, you will be sentenced to at least ________ in prison.","explanation":"If you are convicted of OUI with a BAC of 0.15 percent or above, you will be imprisoned for at least 48 hours. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":12828,"isBookmarked":0,"aid":"12827#12828#12840#13002","text":"24 hours#48 hours#72 hours#96 hours"},
{"testId":583,"qid":81583,"description":"If you're convicted of OUI with a BAC of 0.15 percent聽or higher after refusing a chemical test, you'll spend at least ___ in jail.","explanation":"If you are convicted of OUI with a BAC of 0.15 percent or above after refusing a chemical test, you will serve at least 96 hours in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13002,"isBookmarked":0,"aid":"742#12360#12840#13002","text":"30 days#7 days#72 hours#96 hours"},
{"testId":1050,"qid":81583,"description":"If you're convicted of OUI with a BAC of 0.15 percent聽or higher after refusing a chemical test, you'll spend at least ___ in jail.","explanation":"If you are convicted of OUI with a BAC of 0.15 percent or above after refusing a chemical test, you will serve at least 96 hours in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":13002,"isBookmarked":0,"aid":"742#12360#12840#13002","text":"30 days#7 days#72 hours#96 hours"},
{"testId":583,"qid":81584,"description":"You will serve at least ____ days in jail for your second OUI conviction.","explanation":"A second OUI conviction will result in a minimum of 7 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#6477#6478#7559","text":"10#30#12#7"},
{"testId":1050,"qid":81584,"description":"You will serve at least ____ days in jail for your second OUI conviction.","explanation":"A second OUI conviction will result in a minimum of 7 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":7559,"isBookmarked":0,"aid":"5446#6477#6478#7559","text":"10#30#12#7"},
{"testId":583,"qid":81585,"description":"If you refused to submit to chemical testing at the time of your second OUI conviction, you will spend at least ____ days in jail.","explanation":"If you refused to submit to chemical testing at the time of your second OUI conviction, you will serve at least 12 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6477#6478#7559","text":"10#30#12#7"},
{"testId":1050,"qid":81585,"description":"If you refused to submit to chemical testing at the time of your second OUI conviction, you will spend at least ____ days in jail.","explanation":"If you refused to submit to chemical testing at the time of your second OUI conviction, you will serve at least 12 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6478,"isBookmarked":0,"aid":"5446#6477#6478#7559","text":"10#30#12#7"},
{"testId":583,"qid":81586,"description":"If convicted of OUI for the third time, you'll spend at least ________ in jail.","explanation":"If you are convicted of OUI for the third time, you will face a minimum of 30 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":1050,"qid":81586,"description":"If convicted of OUI for the third time, you'll spend at least ________ in jail.","explanation":"If you are convicted of OUI for the third time, you will face a minimum of 30 days in prison. [If You Are Convicted of Driving While Intoxicated or With an Excessive Alcohol Level, Alcohol and Other Drugs, Part 3: Keeping Fit to Drive, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"229#742#1021#1272","text":"90 days#30 days#1 year#6 months"},
{"testId":583,"qid":81587,"description":"If you successfully finish Maine's Driving Dynamics Course, you will lose ____ points from your driving record.","explanation":"If you pass Maine's Driving Dynamics Course, three points will be taken from your driving record, but only once every twelve months. [Continuing Driver Education, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1050,"qid":81587,"description":"If you successfully finish Maine's Driving Dynamics Course, you will lose ____ points from your driving record.","explanation":"If you pass Maine's Driving Dynamics Course, three points will be taken from your driving record, but only once every twelve months. [Continuing Driver Education, Part 1: Maine Driver's License, State of Maine Motorist Handbook and Study Guide]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":646,"qid":81588,"description":"You approach at a crossroads with red and yellow traffic lights. What exactly does this mean?","explanation":null,"imageName":"202005121141262255.jpg","correctAnswer":31853,"isBookmarked":0,"aid":"31851#31852#31853#31854","text":"The signals are broken; proceed as if the intersection were uncontrolled#The signals are broken; proceed as if the intersection were an all-way stop#The intersection is currently open to pedestrians only; do not enter it#Stop and then proceed with caution"},
{"testId":646,"qid":81589,"description":"A flashing yellow \"X\" signal over a lane on a motorway indicates that:","explanation":"Lane usage control signals are customized overhead signals that indicate which highway lanes may be utilized at certain times. A flashing yellow \"X\" symbol above a lane indicates that it is solely for left turns. [Lane Use Control Lights, Part IV: Operating a Motor Vehicle, State of Maine Motorist Handbook and Study Guide]","imageName":"202003051707075416.jpg","correctAnswer":15682,"isBookmarked":0,"aid":"15682#27272#30427#31746","text":"This lane is for left turns only#This is a high-occupancy vehicle (HOV) lane#This lane is about to close#This lane can be used with caution"},
{"testId":584,"qid":81591,"description":"You must possess a provisional license for at least ___ months before applying for a Maryland driver's license.","explanation":"You must possess a provisional license for at least 18 months before applying for a Maryland driver's license. [D. Driver's License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6389#6916#11342#11359","text":"3#6#9#18"},
{"testId":1051,"qid":81591,"description":"You must possess a provisional license for at least ___ months before applying for a Maryland driver's license.","explanation":"You must possess a provisional license for at least 18 months before applying for a Maryland driver's license. [D. Driver's License, Maryland Driver's Manual, Section 2: Licensing Requirements/Process]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6389#6916#11342#11359","text":"3#6#9#18"},
{"testId":584,"qid":81592,"description":"During the first _________ with a provisional license, a driver may not transport unrelated minors without adult supervision.","explanation":"In Maryland, a motorist with a provisional license may not carry any unrelated passengers under the age of 18 unless supervised by a licensed adult driver for the first five months. [A. Restrictions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":12686,"isBookmarked":0,"aid":"742#1272#1453#12686","text":"30 days#6 months#3 months#5 months"},
{"testId":1051,"qid":81592,"description":"During the first _________ with a provisional license, a driver may not transport unrelated minors without adult supervision.","explanation":"In Maryland, a motorist with a provisional license may not carry any unrelated passengers under the age of 18 unless supervised by a licensed adult driver for the first five months. [A. Restrictions, Maryland Driver's Manual, Section 9: Other Restrictions, Violations, and Penalties]","imageName":"","correctAnswer":12686,"isBookmarked":0,"aid":"742#1272#1453#12686","text":"30 days#6 months#3 months#5 months"},
{"testId":647,"qid":81593,"description":"You are on a road with two lanes. There is a white line between the two lanes. Which of the following is true?","explanation":"Two lanes of traffic traveling in the same direction are separated by a solid white line. Still, if at all feasible, avoid crossing a solid white line. Crossing one should be done with care. [1. Line Marking Types, C. Highway Pavement Markings, Section IV - Signals, Signs, and Pavement Markings of the Maryland Driver's Manual]","imageName":"202004281851466397.jpg","correctAnswer":32309,"isBookmarked":0,"aid":"32308#32309#32310#32311","text":"You are free to change lanes#You should avoid changing lanes if possible#You can never cross a solid white line#You can only cross the line to pass another vehicle; watch out for oncoming traffic"},
{"testId":647,"qid":81594,"description":"A flashing yellow \"X\" signal over a lane on a motorway indicates that:","explanation":"Lane usage control signals are customized overhead signals that show which highway lanes may be utilized at different times. A flashing yellow \"X\" symbol above a lane indicates that it is solely for left turns. Be warned that this lane will most likely be available to both directions of traffic. [10, A. Traffic Signals, Section IV - Signals, Signs, and Pavement Markings, Maryland Driver's Manual]","imageName":"202003051707075416.jpg","correctAnswer":15682,"isBookmarked":0,"aid":"15682#27272#28174#30427","text":"This lane is for left turns only#This is a high-occupancy vehicle (HOV) lane#This lane is closed#This lane is about to close"},
{"testId":585,"qid":81608,"description":"If an 18-year-old or older motorist accummulates three or more speeding violations in a single year, his or her license will be suspended for ________.","explanation":"A Massachusetts motorist who is 18 years of age or older who collects three or more speeding charges in a one-year period will have his or her license suspended for 30 days. When the 30-day term expires, he or she must pay a $100 charge to have the license reactivated. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1052,"qid":81608,"description":"If an 18-year-old or older motorist accummulates three or more speeding violations in a single year, his or her license will be suspended for ________.","explanation":"A Massachusetts motorist who is 18 years of age or older who collects three or more speeding charges in a one-year period will have his or her license suspended for 30 days. When the 30-day term expires, he or she must pay a $100 charge to have the license reactivated. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":585,"qid":81609,"description":"If you are found to be a habitual offender, your license will be suspended for one year.","explanation":"Your license will be suspended for four years if you are found to be a habitual offender. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":1052,"qid":81609,"description":"If you are found to be a habitual offender, your license will be suspended for one year.","explanation":"Your license will be suspended for four years if you are found to be a habitual offender. [Driving Records; Motor Vehicle Violations and Penalties; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":20790,"isBookmarked":0,"aid":"20788#20790#23154#23183","text":"2 years#4 years#1 year#3 years"},
{"testId":585,"qid":81610,"description":"If a driver is charged but not convicted of selling fraudulent licenses, the Registrar of Motor Vehicles may suspend his or her license for a period of:","explanation":"If a driver is accused with selling fraudulent licenses, the Registrar of Motor Vehicles has the authority to suspend the driver's license for six months, even if the driver is not convicted. If the driver is convicted, the Registrar has the authority to suspend his or her license for a year. [Reasons for License Suspension; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1052,"qid":81610,"description":"If a driver is charged but not convicted of selling fraudulent licenses, the Registrar of Motor Vehicles may suspend his or her license for a period of:","explanation":"If a driver is accused with selling fraudulent licenses, the Registrar of Motor Vehicles has the authority to suspend the driver's license for six months, even if the driver is not convicted. If the driver is convicted, the Registrar has the authority to suspend his or her license for a year. [Reasons for License Suspension; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":585,"qid":81611,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. If he or she is guilty of driving without adult supervision on the first violation, his or her permit will be suspended for ____ days.","explanation":"A Junior Operator with a learner's permit who is guilty of driving without adult supervision will have his or her permit suspended for 60 days. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1052,"qid":81611,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit must always be accompanied by a licensed driver over the age of 21. If he or she is guilty of driving without adult supervision on the first violation, his or her permit will be suspended for ____ days.","explanation":"A Junior Operator with a learner's permit who is guilty of driving without adult supervision will have his or her permit suspended for 60 days. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":585,"qid":81612,"description":"A Junior motorist (i.e., someone under the age of 18) with a learner's permit is not permitted to drive unattended between 12:00 a.m. and 5:00 a.m. If motorist is convicted for the third time of driving unsupervised between 12:00 a.m. and 5:00 a.m., motorist's driver's permit will be suspeded for:","explanation":"A Junior Operator's learner's permit will be suspended for 60 days if he or she is guilty of driving unattended between 12:00 a.m. and 5:00 a.m. [Mandatory Permit Suspensions; License Suspension or Revocation; Chapter 2: Keeping Your License; RMV Driver's Manual for Massachusetts]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":626,"qid":81614,"description":"Which of the following is true about areas that say \"No Parking\"?","explanation":"You may momentarily stop in a No Parking zone to load or unload people or products. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003151716212915.jpg","correctAnswer":32633,"isBookmarked":0,"aid":"32633#32634#32635#32636","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop here to obey another rule of the road#You can stop here at any time"},
{"testId":626,"qid":81615,"description":"What should you do when you see a solid yellow traffic light?","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, you should attempt to quit. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Traffic Signals, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202003051752141237.jpg","correctAnswer":28524,"isBookmarked":0,"aid":"28524#28857#28858#29963","text":"Stop if it is safe to do so#Speed up to get through the signal before it turns red#Proceed with caution because there may be a hazard ahead#Stop, yield, and proceed when it is safe to do so"},
{"testId":626,"qid":81616,"description":"Which of the following No Standing Zone statements is correct?","explanation":"You may stop momentarily to load or unload people but not products in a No Standing zone. [Parking, Commonwealth of Massachusetts Driver's Manual, Chapter 4: Rules of the Road]","imageName":"202004180814232263.jpg","correctAnswer":32634,"isBookmarked":0,"aid":"32633#32634#32635#32636","text":"You can only stop temporarily to load or unload passengers or merchandise#You can only stop temporarily to load or unload passengers#You can only stop here to obey another rule of the road#You can stop here at any time"},
{"testId":586,"qid":81623,"description":"If you're caught driving with an open alcoholic beverage container, your driving record will be added with____ points.","explanation":"If you are guilty of driving with an open container of an alcoholic beverage in your car, you will get two points on your license. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1053,"qid":81623,"description":"If you're caught driving with an open alcoholic beverage container, your driving record will be added with____ points.","explanation":"If you are guilty of driving with an open container of an alcoholic beverage in your car, you will get two points on your license. [Chapter 7: Traffic Violations and Michigan's Point System] What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":586,"qid":81624,"description":"If a driver under the age of 21 refuses to take a preliminary breath test, he or she will receive ___ points on his or her driving record.","explanation":"Refusing to take a preliminary breath test will result in two points being added to a minor's driving record. (It should be noted that refusing to submit to a chemical alcohol test will result in a six-point penalty on a driver's record.) [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":1053,"qid":81624,"description":"If a driver under the age of 21 refuses to take a preliminary breath test, he or she will receive ___ points on his or her driving record.","explanation":"Refusing to take a preliminary breath test will result in two points being added to a minor's driving record. (It should be noted that refusing to submit to a chemical alcohol test will result in a six-point penalty on a driver's record.) [Traffic Violations and Michigan's Point System, Chapter 7 What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":586,"qid":81627,"description":"If a newly licensed Michigan motorist gets convicted of several driving infractions while on probation,:","explanation":"If a newly licensed Michigan driver on probation is convicted of several moving offenses, a Department of State officer may contact the individual to address his or her bad driving habits. [Driver Reexaminations, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":33123,"isBookmarked":0,"aid":"27717#33122#33123#33124","text":"all of the above will happen#he or she will be fined#he or she will be contacted by the Department of State#his or her license will be canceled"},
{"testId":1053,"qid":81627,"description":"If a newly licensed Michigan motorist gets convicted of several driving infractions while on probation,:","explanation":"If a newly licensed Michigan driver on probation is convicted of several moving offenses, a Department of State officer may contact the individual to address his or her bad driving habits. [Driver Reexaminations, Chapter 7: What Every Driver Should Know About Your Michigan Driving Record]","imageName":"","correctAnswer":33123,"isBookmarked":0,"aid":"27717#33122#33123#33124","text":"all of the above will happen#he or she will be fined#he or she will be contacted by the Department of State#his or her license will be canceled"},
{"testId":648,"qid":81629,"description":"Is it legal to turn left at a red light?","explanation":"You may turn left on a red light while approaching a one-way street from another one-way street unless a \"No Turn on Red\" sign is present, but you must first stop and yield to pedestrians and cross traffic. A left turn from a two-way street into a one-way street is also possible, but you must first stop and yield to pedestrians, cross traffic, and oncoming cars. A red arrow, on the other hand, cannot be turned on at all. (Note: Some states have differing laws on this.) Some states make it illegal to make a left turn on red from a two-way street into a one-way street. Other states outright ban left turns on red. Always verify the local driving rules while leaving Michigan.) [Some Red Light Left Turns, Turning on a Red Light, 3 Basic Skills, Laws, and Safety, What Every Driver Should Know]","imageName":"202003112014364009.jpg","correctAnswer":33164,"isBookmarked":0,"aid":"30751#33163#33164#33165","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, this is a \"protected\" turn in which you will have the right-of-way#Yes, but only when turning onto a one-way street#No, not in Michigan"},
{"testId":648,"qid":81630,"description":"What should you do if you come across an emergency vehicle parked on the side of the road with its flashing lights on?","explanation":"You must maintain a clear lane next to any stopped emergency vehicle with flashing lights. If changing lanes is dangerous or impracticable, you must reduce your speed to a safe and reasonable level and continue with care. Nota bene: The MI driver's manual, \"What Every Driver Must Know,\" seems to imply that you must slow down whether you go across a lane or not. That is most likely a typo since it violates Michigan Compiled Laws 搂 257.653a, which specifies that you must slow down if you cannot go across a lane. See also: https://www.michigan.gov/documents/MDOT Vehicle Caution Law 116834 7.pdf, which is in accordance with Michigan law. [When You See an Emergency Vehicle, Emergency Vehicles, 5 Sharing the Road Safely - Be Courteous!, What Every Driver Should Know]","imageName":"202001241703386758.jpg","correctAnswer":31442,"isBookmarked":0,"aid":"15493#28148#31442#33188","text":"Slow down#Proceed with caution#Leave a vacant lane next to the emergency vehicle if possible; otherwise, slow down#Slow down and leave a vacant lane next to the emergency vehicle if possible"},
{"testId":587,"qid":81639,"description":"Driving over 100 mph will result in at least ______ 聽license suspension.","explanation":"If you are convicted of exceeding 100 mph while driving, your license will be suspended for at least six months. [Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation, Speed Limits and Fines]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1054,"qid":81639,"description":"Driving over 100 mph will result in at least ______ 聽license suspension.","explanation":"If you are convicted of exceeding 100 mph while driving, your license will be suspended for at least six months. [Minnesota Driver's Manual, Chapter 3 Traffic Laws and Vehicle Operation, Speed Limits and Fines]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":587,"qid":81640,"description":"Refusing a chemical test for alcohol or drugs will result in a license suspension of at least:","explanation":"If you refuse to submit to chemical testing for alcohol or drug use, your driver's license will be suspended for one to six years, depending on how many past infractions you have. [Implied Consent Law, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1054,"qid":81640,"description":"Refusing a chemical test for alcohol or drugs will result in a license suspension of at least:","explanation":"If you refuse to submit to chemical testing for alcohol or drug use, your driver's license will be suspended for one to six years, depending on how many past infractions you have. [Implied Consent Law, Chapter 8 of the Minnesota Driver's Manual, Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":587,"qid":81641,"description":"A fourth DWI conviction within ten years may result in the loss of driving privileges for up to ___ years.","explanation":"A fourth DWI conviction within 10 years is considered a crime. A felony DWI may result in a four-year license suspension, up to seven years in jail, and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1054,"qid":81641,"description":"A fourth DWI conviction within ten years may result in the loss of driving privileges for up to ___ years.","explanation":"A fourth DWI conviction within 10 years is considered a crime. A felony DWI may result in a four-year license suspension, up to seven years in jail, and/or a $14,000 fine. [Felony DWI, Penalties, Minnesota Driver's Manual, Chapter 8 Driving Under the Influence of Alcohol or Drugs]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":587,"qid":81642,"description":"If a Minnesota driver under the age of 21 is convicted of DWI, his or her license will be suspended for ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":1054,"qid":81642,"description":"If a Minnesota driver under the age of 21 is convicted of DWI, his or her license will be suspended for ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6477#6802#8141","text":"90#30#180#60"},
{"testId":587,"qid":81643,"description":"The fee to restore your driver's license is _____ if you lost it for any reason other than driving while intoxicated or operating a vehicle criminally.","explanation":"The reinstatement cost is $30 if you lost your driver's license for a reason other than DWI or felony vehicular operation. [Reinstatement Fees, Minnesota Driver's Manual, Chapter 1 Your License to Drive]","imageName":"","correctAnswer":33510,"isBookmarked":0,"aid":"24978#24994#33509#33510","text":"$100#$50#$20#$30"},
{"testId":1054,"qid":81643,"description":"The fee to restore your driver's license is _____ if you lost it for any reason other than driving while intoxicated or operating a vehicle criminally.","explanation":"The reinstatement cost is $30 if you lost your driver's license for a reason other than DWI or felony vehicular operation. [Reinstatement Fees, Minnesota Driver's Manual, Chapter 1 Your License to Drive]","imageName":"","correctAnswer":33510,"isBookmarked":0,"aid":"24978#24994#33509#33510","text":"$100#$50#$20#$30"},
{"testId":649,"qid":81646,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A flashing yellow \"X\" denotes that this lane is solely for left turns. Use care since incoming cars turning left may also use this lane. [Lane Use Control Signals, Minnesota Driver's Manual, Chapter 5 Signs, Signals, and Pavement Markings]","imageName":"202003051707075416.jpg","correctAnswer":33548,"isBookmarked":0,"aid":"28148#28174#33547#33548","text":"Proceed with caution#This lane is closed#Move into another lane as soon as it is safe to do so#You can only make a left turn from this lane"},
{"testId":588,"qid":81654,"description":"A Mississippi underage driver's license will be suspended for ___ days for his or her first DUI offense.","explanation":"A Mississippi minor driver's license will be banned for 120 days for his or her first DUI violation, exactly as if he or she were at least 21 years old. However, instead of the license suspension, the court may order the installation of an ignition-interlock device (IID) in the driver's car for 120 days. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1055,"qid":81654,"description":"A Mississippi underage driver's license will be suspended for ___ days for his or her first DUI offense.","explanation":"A Mississippi minor driver's license will be banned for 120 days for his or her first DUI violation, exactly as if he or she were at least 21 years old. However, instead of the license suspension, the court may order the installation of an ignition-interlock device (IID) in the driver's car for 120 days. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":6356,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":588,"qid":81655,"description":"What is the maximum fine for a second DUI conviction within 5 years by a Mississippi driver under 21 years of age?","explanation":"The maximum penalties for a second DUI conviction for a Mississippi motorist under the age of 21 is $500. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":1055,"qid":81655,"description":"What is the maximum fine for a second DUI conviction within 5 years by a Mississippi driver under 21 years of age?","explanation":"The maximum penalties for a second DUI conviction for a Mississippi motorist under the age of 21 is $500. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23887,"isBookmarked":0,"aid":"23887#23889#27888#29301","text":"$500#$1,000#$250#$750"},
{"testId":588,"qid":81656,"description":"If a Mississippi driver under the age of 21 gets convicted of a third DUI conviction in five years, his or her license will be suspended for:","explanation":"If a Mississippi driver under 21 is convicted of a third DUI conviction, his or her license will be suspended for three years. However, instead of the three-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for three years. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1055,"qid":81656,"description":"If a Mississippi driver under the age of 21 gets convicted of a third DUI conviction in five years, his or her license will be suspended for:","explanation":"If a Mississippi driver under 21 is convicted of a third DUI conviction, his or her license will be suspended for three years. However, instead of the three-year license suspension, the court may mandate the installation of an ignition-interlock device (IID) in the driver's car for three years. If the driver has recently ingested alcohol, the IID will prohibit the car from starting. [Mississippi Driver's Manual, Zero Tolerance DUI Penalties, Driving Under the Influence & Implied Consent]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":589,"qid":81671,"description":"If you are convicted for the second time of speeding or passing in a construction or work zone, you will be fined at least :","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"31696#33102#33103#34142","text":"$400#$300#$350#$325"},
{"testId":1056,"qid":81671,"description":"If you are convicted for the second time of speeding or passing in a construction or work zone, you will be fined at least :","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"31696#33102#33103#34142","text":"$400#$300#$350#$325"},
{"testId":589,"qid":81672,"description":"First-time DUI arrests result in license suspensions of:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1056,"qid":81672,"description":"First-time DUI arrests result in license suspensions of:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":589,"qid":81673,"description":"If convicted for DWI offense for the 3rd time, your driving privileges will be revoked for:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23153#26672#28011#29895","text":"6 months#5 years#15 years#10 years"},
{"testId":1056,"qid":81673,"description":"If convicted for DWI offense for the 3rd time, your driving privileges will be revoked for:","explanation":null,"imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23153#26672#28011#29895","text":"6 months#5 years#15 years#10 years"},
{"testId":589,"qid":81674,"description":"If you were told to put an IID (ignition interlock device) in your vehicle and then caught driving without it a second time, your license will be taken away for:","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#20790#23154#26672","text":"2 years#4 years#1 year#5 years"},
{"testId":1056,"qid":81674,"description":"If you were told to put an IID (ignition interlock device) in your vehicle and then caught driving without it a second time, your license will be taken away for:","explanation":null,"imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"20788#20790#23154#26672","text":"2 years#4 years#1 year#5 years"},
{"testId":589,"qid":81675,"description":"If you aacumulat 8 points in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1056,"qid":81675,"description":"If you aacumulat 8 points in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":589,"qid":81676,"description":"If you aacumulat 8 points for the second time in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":1056,"qid":81676,"description":"If you aacumulat 8 points for the second time in an 18-month period, your driver license will be banned for ____ days.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"6355#6356#6477#8141","text":"90#120#30#60"},
{"testId":589,"qid":81677,"description":"If you are guilty of driving without insurance, for the third time, your license or plates may be suspended for __ days and you'll need insurance proof and a ____ fee to reinstate them.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"23887#24977#31696#33102","text":"$500#$200#$400#$300"},
{"testId":1056,"qid":81677,"description":"If you are guilty of driving without insurance, for the third time, your license or plates may be suspended for __ days and you'll need insurance proof and a ____ fee to reinstate them.","explanation":null,"imageName":"","correctAnswer":31696,"isBookmarked":0,"aid":"23887#24977#31696#33102","text":"$500#$200#$400#$300"},
{"testId":27,"qid":81680,"description":"Make sure there is no traffic approaching _______ before turning right at an intersection.","explanation":"Make sure there is no traffic arriving from the left and no incoming traffic turning left into your path before turning right at a junction. Yield to people crossing the road you are about to enter.","imageName":"202003070857473536.jpg","correctAnswer":22190,"isBookmarked":0,"aid":"22190#22191#24349#24350","text":"from your left and no oncoming traffic turning left into your path#from your right and no oncoming traffic turning right into your path#from your right and no oncoming traffic turning left into your path#from your left and no oncoming traffic turning right into your path"},
{"testId":27,"qid":81681,"description":"When you drive on highways, you should strive to glance at least ________ ahead.","explanation":"When driving, strive to keep at least 10 seconds ahead of you. In the city, 10 seconds equals about one block. On the highway, 10 seconds is equivalent to four city blocks or a quarter mile.","imageName":"","correctAnswer":499,"isBookmarked":0,"aid":"496#497#498#499","text":"one city block#two city blocks#three city blocks#four city blocks"},
{"testId":590,"qid":81684,"description":"The penalties for an underage driver convicted of Minor in Possession of Alcohol (MIP) include ________.","explanation":null,"imageName":"","correctAnswer":34434,"isBookmarked":0,"aid":"9855#34434#34435#34436","text":"Any of the above#confiscation of his or her driver license#a requirement to attend a driving course#permanent forfeiture of his or her driver license"},
{"testId":1057,"qid":81684,"description":"The penalties for an underage driver convicted of Minor in Possession of Alcohol (MIP) include ________.","explanation":null,"imageName":"","correctAnswer":34434,"isBookmarked":0,"aid":"9855#34434#34435#34436","text":"Any of the above#confiscation of his or her driver license#a requirement to attend a driving course#permanent forfeiture of his or her driver license"},
{"testId":590,"qid":81685,"description":"What is the fundamental reason drinking and driving is so dangerous?","explanation":null,"imageName":"","correctAnswer":45459,"isBookmarked":0,"aid":"45457#45458#45459#45460","text":"Alcohol worsens your coordination#Alcohol affects your vision#Alcohol affects your judgment and skill#Alcohol dulls your reflexes"},
{"testId":1057,"qid":81685,"description":"What is the fundamental reason drinking and driving is so dangerous?","explanation":null,"imageName":"","correctAnswer":45459,"isBookmarked":0,"aid":"45457#45458#45459#45460","text":"Alcohol worsens your coordination#Alcohol affects your vision#Alcohol affects your judgment and skill#Alcohol dulls your reflexes"},
{"testId":590,"qid":81686,"description":"What is the leading cause of fatality on Montana's highways?","explanation":null,"imageName":"","correctAnswer":31346,"isBookmarked":0,"aid":"18625#31346#45467#45468","text":"Speeding#Drunk driving#Roads being constantly under construction#Poor visibility / adverse weather conditions"},
{"testId":590,"qid":81687,"description":"Driving with a suspended or revoked license will result in ____ points being added to your driving record.","explanation":"If you are convicted of driving while your license is suspended or revoked, you will get six points on your driving record. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1057,"qid":81687,"description":"Driving with a suspended or revoked license will result in ____ points being added to your driving record.","explanation":"If you are convicted of driving while your license is suspended or revoked, you will get six points on your driving record. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":590,"qid":81688,"description":"Your driving record will be penalized with __ points if you are found guilty of fleeing the scene of an accident that caused property damage of at least $250 but did not result in any injuries to any of the parties involved.","explanation":"If you are guilty of leaving the scene of an accident with at least $250 in property damage but no injuries, you will get four points on your driving record. If there were injuries or fatalities as a result of the accident, eight points will be added instead. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1057,"qid":81688,"description":"Your driving record will be penalized with __ points if you are found guilty of fleeing the scene of an accident that caused property damage of at least $250 but did not result in any injuries to any of the parties involved.","explanation":"If you are guilty of leaving the scene of an accident with at least $250 in property damage but no injuries, you will get four points on your driving record. If there were injuries or fatalities as a result of the accident, eight points will be added instead. [Montana Code Annotated, MCA 61-11-203]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":590,"qid":81689,"description":"A fourth DUI conviction might result in up to _____ fine.","explanation":"A fourth DUI conviction in Montana is considered a crime. A fine of $5,000 to $10,000, as well as a prison term, are potential consequences. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"25036#31397#31402#33847","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":1057,"qid":81689,"description":"A fourth DUI conviction might result in up to _____ fine.","explanation":"A fourth DUI conviction in Montana is considered a crime. A fine of $5,000 to $10,000, as well as a prison term, are potential consequences. [Montana Department of Justice, Driver License Suspension, https://dojmt.gov/driving/driver-license-sanctions/#suspension]","imageName":"","correctAnswer":31397,"isBookmarked":0,"aid":"25036#31397#31402#33847","text":"$5,000#$10,000#$20,000#$15,000"},
{"testId":652,"qid":81690,"description":"You see a lane with a double broken yellow line on both sides. What kind of a lane is this?","explanation":null,"imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#16322#16786#28541","text":"A reversible lane#A central parallel parking lane#A shared center lane#A high-occupancy vehicle (HOV) lane"},
{"testId":652,"qid":81691,"description":"Which vehicles are making proper use of this roundabout?","explanation":null,"imageName":"202002181235214704.jpg","correctAnswer":14814,"isBookmarked":0,"aid":"14814#14815#15982#15983","text":"Car A#Car B#Neither car#Both cars"},
{"testId":652,"qid":81692,"description":"Is it legal to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#34500#34501","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, this is a \"protected\" turn; you have the right-of-way#No, not in Montana"},
{"testId":652,"qid":81693,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202005071742529326.jpg","correctAnswer":34505,"isBookmarked":0,"aid":"16391#34503#34504#34505","text":"D) None of the above#A) Facing against the flow of traffic#B) 35 feet from a railroad crossing#C) 35 feet from a stop sign"},
{"testId":652,"qid":81694,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":null,"imageName":"202003051707075416.jpg","correctAnswer":34510,"isBookmarked":0,"aid":"28148#28174#33547#34510","text":"Proceed with caution#This lane is closed#Move into another lane as soon as it is safe to do so#This lane is only for making turns"},
{"testId":591,"qid":81698,"description":"Your license will be suspended for _________, if you acquire 12 in a two-year period.","explanation":"If you acquire at least 12 points in a two-year period, your driver's license will be suspended for six months. [1E-2 Point Revocation Procedures, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":591,"qid":81699,"description":"First-time DUI (driving under the influence of alcohol or drugs) 聽convictions result in____ points being added聽on a driver's record.","explanation":"If you are convicted of DUI for the first time, you will get six points on your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1058,"qid":81699,"description":"First-time DUI (driving under the influence of alcohol or drugs) 聽convictions result in____ points being added聽on a driver's record.","explanation":"If you are convicted of DUI for the first time, you will get six points on your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6916,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":591,"qid":81700,"description":"How many points a conviction for careless driving will add to your driving record?","explanation":"Careless driving will result in four points being added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":1058,"qid":81700,"description":"How many points a conviction for careless driving will add to your driving record?","explanation":"Careless driving will result in four points being added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6916#7620","text":"2#4#6#8"},
{"testId":591,"qid":81701,"description":"If you refuse to take a chemical test for alcohol or drugs when asked by a police officer, ___ point(s) will be added to your driving record.","explanation":"If you decline to take a chemical test for alcohol or drugs when asked by a police officer, just one point will be added to your driving record. However, your driver's license may be suspended for a year. [2A Alcohol, Drugs, and Driving, 2 Important Driver Information, Nebraska Driver's Manual], [1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":591,"qid":81702,"description":"If you are guilty of exceeding the stated speed limit by 34 miles per hour inside city boundaries, _____ points will be added to your driving record.","explanation":"If you are guilty of exceeding the legal speed limit by more than 10 mph but no more than 35 mph inside city boundaries, three points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1058,"qid":81702,"description":"If you are guilty of exceeding the stated speed limit by 34 miles per hour inside city boundaries, _____ points will be added to your driving record.","explanation":"If you are guilty of exceeding the legal speed limit by more than 10 mph but no more than 35 mph inside city boundaries, three points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":591,"qid":81703,"description":"If you are guilty of exceeding the legal speed limit within city boundaries by more than 35 mph, _______ points will be added to your driving record.","explanation":"If you are guilty of exceeding the stated speed limit within city boundaries by more than 35 mph, four points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1058,"qid":81703,"description":"If you are guilty of exceeding the legal speed limit within city boundaries by more than 35 mph, _______ points will be added to your driving record.","explanation":"If you are guilty of exceeding the stated speed limit within city boundaries by more than 35 mph, four points will be added to your driving record. [Nebraska Driver's Manual, 1E-3 The Nebraska \"Point System,\" 1E Suspensions, Revocations, Impoundments, and Confiscations]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":591,"qid":81704,"description":"In Nebraska, how long do points stay on a person's driving record?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11341#11760","text":"1 year#2 years#5 years#10 years"},
{"testId":1058,"qid":81704,"description":"In Nebraska, how long do points stay on a person's driving record?","explanation":"When you are guilty of a violation, points are added to your driving record as of the date of the offense. The conviction is recorded on your driving record for five years from the date of conviction, however the points are only recorded for two years from the date of violation. [1E-3 The \"Point System\" in Nebraska, 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11341#11760","text":"1 year#2 years#5 years#10 years"},
{"testId":591,"qid":81705,"description":"Driver's licenses are cancelled for ____________ if a person is guilty of driving with a seized license.","explanation":"If a person is convicted of driving while his or her driver's license is confiscated by the court, the license will be suspended for a year. [Nebraska Driver's Manual, 1E Suspensions, Revocations, Impoundments, and Confiscations, 1E-5 Court Ordered Impoundment Procedures]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23183#23886","text":"6 months#1 year#3 years#30 days"},
{"testId":591,"qid":81706,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.02 percent or higher, the conviction will remain on driver's driving record for:","explanation":"A motorist under the age of 21 who is convicted of driving with a BAC of 0.02 percent or more will have the conviction recorded on his or her driving record for 90 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23154#24049#26672","text":"6 months#1 year#90 days#5 years"},
{"testId":1058,"qid":81706,"description":"If a driver under the age of 21 is convicted of driving with a BAC of 0.02 percent or higher, the conviction will remain on driver's driving record for:","explanation":"A motorist under the age of 21 who is convicted of driving with a BAC of 0.02 percent or more will have the conviction recorded on his or her driving record for 90 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23153#23154#24049#26672","text":"6 months#1 year#90 days#5 years"},
{"testId":591,"qid":81707,"description":"If a driver under the age of 21 refuses to submit to chemical testing for alcohol or drugs, his or her license will be seized for:","explanation":"A motorist under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended for 90 days. The refusal conviction will stay on his or her driving record for 120 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":1058,"qid":81707,"description":"If a driver under the age of 21 refuses to submit to chemical testing for alcohol or drugs, his or her license will be seized for:","explanation":"A motorist under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended for 90 days. The refusal conviction will stay on his or her driving record for 120 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":591,"qid":81708,"description":"If a driver under 21 is found guilty of refusing to submit to an alcohol or drug test, the conviction will stay on their driving record for:","explanation":"A motorist under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended for 90 days. The refusal conviction will stay on his or her driving record for 120 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":25857,"isBookmarked":0,"aid":"24049#25856#25857#25858","text":"90 days#360 days#120 days#180 days"},
{"testId":1058,"qid":81708,"description":"If a driver under 21 is found guilty of refusing to submit to an alcohol or drug test, the conviction will stay on their driving record for:","explanation":"A motorist under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended for 90 days. The refusal conviction will stay on his or her driving record for 120 days. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":25857,"isBookmarked":0,"aid":"24049#25856#25857#25858","text":"90 days#360 days#120 days#180 days"},
{"testId":591,"qid":81709,"description":"If a driver under the age of 21 is guilty of driving while his or her license is impound, the court will revoke the driver's license for:","explanation":"If a motorist under the age of 21 is convicted of driving while his or her license is impounded, the license will be revoked for six months by the court. [1E-6.02 percent Underage Drinking and Driving (Age 20 or Younger), 1E Suspensions, Revocations, Impoundments, and Confiscations, Nebraska Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24048#25857","text":"6 months#1 year#60 days#120 days"},
{"testId":592,"qid":81716,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":"If you are convicted of violating a traffic signal or stop sign, you will get four points on your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1059,"qid":81716,"description":"________ points will be added to your driving record if you are found guilty of running a stop sign.","explanation":"If you are convicted of violating a traffic signal or stop sign, you will get four points on your driving record. [Demerit Point System, Nevada Driver's Handbook, Chapter 8: Your Driving Record]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":654,"qid":81717,"description":"Is it legal to turn left at a red light?","explanation":"When going from one one-way street to another one-way street in Nevada, you may turn left on a red signal. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Michigan), you may also turn left on red from a two-way street into a one-way street. Other states, such as South Dakota, outright ban left turns on red. Always verify the local driving regulations while going out of state. [Signs; Signs, Signals, and Markings; Nevada Driver's Handbook; Chapter 3: Driving Safely]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35033#35034","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, but only if a sign specifically permits it#No, not in Nevada"},
{"testId":654,"qid":81718,"description":"How far can you drive before turning left in a center turn lane?","explanation":"You may only travel 200 feet in a center turn lane before turning left. In addition, after turning left from another route into a center turn lane, you may go no more than 50 feet. Proceed with care since cars from the other direction may join the lane. [Highway Markings; Signs, Signals, and Markings; Chapter 3: Safe Driving; Nevada Driver's Handbook]","imageName":"202004281900539347.jpg","correctAnswer":399,"isBookmarked":0,"aid":"214#399#1026#1397","text":"100 feet#200 feet#50 feet#150 feet"},
{"testId":654,"qid":81719,"description":"Which of the following places may you park legally?","explanation":"Parking is prohibited within 20 feet of a crosswalk, 50 feet of a railroad crossing, or 30 feet of a traffic signal. (There are also additional areas where parking is prohibited.) Look for signs or colored curb lines prohibiting or limiting parking.) In Scenario C, the automobile is far enough away from the traffic light to park legally. [No Parking Allowed, Parking, Nevada Driver's Handbook, Chapter 3: Driving Safely]","imageName":"nevada-prohibited-parking.png","correctAnswer":35037,"isBookmarked":0,"aid":"16391#34504#35036#35037","text":"D) None of the above#B) 35 feet from a railroad crossing#A) 15 feet from a crosswalk#C) 35 feet from a traffic signal"},
{"testId":593,"qid":81726,"description":"If this is your second offense for violating New Hampshire's Open Container Law, your license may be suspended for up to:","explanation":"If you break New Hampshire's Open Container Law for the first time, your driving privileges may be banned for 60 days. Your driving privileges may be revoked for up to a year if this is your second or subsequent violation. [Part Fourteen, Alcohol, Drugs, and Driving, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#26672","text":"2 years#6 months#1 year#5 years"},
{"testId":593,"qid":81727,"description":"If you refuse to submit to chemical testing for the first time, your driver's license will be suspended for _______ .","explanation":"If you refuse to submit to chemical testing for the first time, your driving license will be suspended for 180 days. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":1060,"qid":81727,"description":"If you refuse to submit to chemical testing for the first time, your driver's license will be suspended for _______ .","explanation":"If you refuse to submit to chemical testing for the first time, your driving license will be suspended for 180 days. This administrative penalty cannot be imposed in addition to any criminal consequences you may face if you are convicted of any wrongdoing. [Implied Consent; Alcohol, Drugs, and Driving - Part Fourteen, New Hampshire Driver's Handbook]","imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":593,"qid":81728,"description":"Driving while in possession of a controlled drug will result in driver's聽license suspension for up to:","explanation":"If you are convicted of driving while under the influence of a controlled substance, your license will be suspended for 60 days to two years. [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances], [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances] .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1060,"qid":81728,"description":"Driving while in possession of a controlled drug will result in driver's聽license suspension for up to:","explanation":"If you are convicted of driving while under the influence of a controlled substance, your license will be suspended for 60 days to two years. [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances], [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances] .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":593,"qid":81729,"description":"Driving while in possession of a controlled drug will result in driver's聽license suspension for at least:","explanation":"If you are convicted of driving while under the influence of a controlled substance, your license will be suspended for 60 days to two years. [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances], [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances] .htm]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23153#23154#24048#24049","text":"6 months#1 year#60 days#90 days"},
{"testId":1060,"qid":81729,"description":"Driving while in possession of a controlled drug will result in driver's聽license suspension for at least:","explanation":"If you are convicted of driving while under the influence of a controlled substance, your license will be suspended for 60 days to two years. [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances], [\"Controlled Substances,\" New Hampshire Department of Health and Human Services, http://www2.dhhs.nh.gov/dcbcs/bdas/controlledsubstances] .htm]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23153#23154#24048#24049","text":"6 months#1 year#60 days#90 days"},
{"testId":593,"qid":81730,"description":"Driving without first obtaining a driver's license will add ___ points to your driving record.","explanation":null,"imageName":"","correctAnswer":6469,"isBookmarked":0,"aid":"6388#6389#6390#6469","text":"2#3#4#1"},
{"testId":593,"qid":81731,"description":"__ points will be added to your driving record if you let an underage or unlicensed person drive.","explanation":"Two points will be added to your driving record if you are convicted of permitting an underage or unlicensed person to drive a motor vehicle. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1060,"qid":81731,"description":"__ points will be added to your driving record if you let an underage or unlicensed person drive.","explanation":"Two points will be added to your driving record if you are convicted of permitting an underage or unlicensed person to drive a motor vehicle. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":593,"qid":81732,"description":"_____ points will be added to your driving record if you are convicted of speeding 33 mph above the posted speed limit.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1060,"qid":81732,"description":"_____ points will be added to your driving record if you are convicted of speeding 33 mph above the posted speed limit.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":593,"qid":81733,"description":"To pass the vehicle in front of you on a two-lane road, you must switch lanes. If you are convicted of disregarding incoming traffic in that lane, you will receive _____ points on your license.聽:","explanation":"If you are convicted of improper passing, you will get four points on your driving record. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":1060,"qid":81733,"description":"To pass the vehicle in front of you on a two-lane road, you must switch lanes. If you are convicted of disregarding incoming traffic in that lane, you will receive _____ points on your license.聽:","explanation":"If you are convicted of improper passing, you will get four points on your driving record. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6389#6390#6916","text":"2#3#4#6"},
{"testId":593,"qid":81734,"description":"If a motorist under the age of 18 accumulates six points in twelve months, his or her license may be suspended for a period of up to:","explanation":"A motorist under the age of 18 will have his or her license suspended for up to 90 days if he or she earns six points in a calendar year. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#23154#23886","text":"3 months#6 months#1 year#30 days"},
{"testId":1060,"qid":81734,"description":"If a motorist under the age of 18 accumulates six points in twelve months, his or her license may be suspended for a period of up to:","explanation":"A motorist under the age of 18 will have his or her license suspended for up to 90 days if he or she earns six points in a calendar year. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23152,"isBookmarked":0,"aid":"23152#23153#23154#23886","text":"3 months#6 months#1 year#30 days"},
{"testId":593,"qid":81735,"description":"If a motorist under the age of 18 earns 18 points in three consecutive years, his or her license may be suspended for a period of up to:","explanation":"A motorist under the age of 18 will have his or her license suspended for up to a year if he or she collects 18 points in three consecutive years. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":1060,"qid":81735,"description":"If a motorist under the age of 18 earns 18 points in three consecutive years, his or her license may be suspended for a period of up to:","explanation":"A motorist under the age of 18 will have his or her license suspended for up to a year if he or she collects 18 points in three consecutive years. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#24049","text":"2 years#6 months#1 year#90 days"},
{"testId":593,"qid":81736,"description":"If a driver between the ages of 18 and 20 accumulates ___ points in a year, his or her license may be suspended for up to three months.","explanation":"If a driver between the ages of 18 and 20 earns nine points in a year, his or her license may be suspended for up to three months. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":11342,"isBookmarked":0,"aid":"5446#7559#7620#11342","text":"10#7#8#9"},
{"testId":1060,"qid":81736,"description":"If a driver between the ages of 18 and 20 accumulates ___ points in a year, his or her license may be suspended for up to three months.","explanation":"If a driver between the ages of 18 and 20 earns nine points in a year, his or her license may be suspended for up to three months. Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual] [Demerit Points Assessed After Court Conviction (depending on date of violation), Accidents and Financial Responsibility - Part Sixteen, State of New Hampshire Driver's Manual]","imageName":"","correctAnswer":11342,"isBookmarked":0,"aid":"5446#7559#7620#11342","text":"10#7#8#9"},
{"testId":655,"qid":81737,"description":"You want to turn left 100 feet away at an intersection. Where should you start your signal?","explanation":null,"imageName":"202002181158183785.jpg","correctAnswer":16553,"isBookmarked":0,"aid":"16553#16554#16555#35484","text":"B) In the middle of the first intersection#C) Just after the first intersection#D) Just before you start to turn#A) 100 feet from the intersection"},
{"testId":655,"qid":81738,"description":"Which of the following places may you park legally?","explanation":"You may not park within 20 feet of a crosswalk at an intersection, 50 feet of the closest rail at a railroad crossing, or 30 feet of a stop sign, yield sign, or traffic signal in New Hampshire. (There are also additional areas where parking is prohibited.) Look for signs or pavement markings prohibiting or limiting parking.) In Scenario C, the automobile is far enough away from the stop sign to park lawfully. [State of New Hampshire Driver's Manual, Illegal Parking, Part Ten - Parking and Backing]","imageName":"202003202116529567.jpg","correctAnswer":34505,"isBookmarked":0,"aid":"16391#34504#34505#35486","text":"D) None of the above#B) 35 feet from a railroad crossing#C) 35 feet from a stop sign#A) 15 feet from a crosswalk at an intersection"},
{"testId":594,"qid":81743,"description":"If you are convicted of DUI violation for the second time, you will face a fine of up to ___ and a jail sentence of up to:","explanation":null,"imageName":"","correctAnswer":44226,"isBookmarked":0,"aid":"44225#44226#44227#44228","text":"$500; 30 days#$1,000; 90 days#$1,500; 60 days#$2,000; 120 days"},
{"testId":1061,"qid":81743,"description":"If you are convicted of DUI violation for the second time, you will face a fine of up to ___ and a jail sentence of up to:","explanation":null,"imageName":"","correctAnswer":44226,"isBookmarked":0,"aid":"44225#44226#44227#44228","text":"$500; 30 days#$1,000; 90 days#$1,500; 60 days#$2,000; 120 days"},
{"testId":594,"qid":81744,"description":"For the first conviction for careless driving you may be sent to jail for___","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":1061,"qid":81744,"description":"For the first conviction for careless driving you may be sent to jail for___","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":594,"qid":81745,"description":"Driving on the shoulder while passing another vehicle will add _____ points to driving record.","explanation":"A conviction for unsafe passing will result in four points being added to your driving record. [Moving Violation Point Chart, Chapter 7: Driver Privileges and Penalties, The New Jersey Driver Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1061,"qid":81745,"description":"Driving on the shoulder while passing another vehicle will add _____ points to driving record.","explanation":"A conviction for unsafe passing will result in four points being added to your driving record. [Moving Violation Point Chart, Chapter 7: Driver Privileges and Penalties, The New Jersey Driver Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":595,"qid":81762,"description":"Is it legal in New Mexico to use a portable cell phone while driving if you have an unrestricted operator's license (car or motorcycle)?","explanation":null,"imageName":"","correctAnswer":37472,"isBookmarked":0,"aid":"21234#37470#37471#37472","text":"Yes#No, it's prohibited by state law#Yes, except under poor driving conditions#Yes, except in certain cities"},
{"testId":595,"qid":81763,"description":"In New Mexico, your car liability insurance policy must have at least _______ in property damage coverage for any one accident.","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":1062,"qid":81763,"description":"In New Mexico, your car liability insurance policy must have at least _______ in property damage coverage for any one accident.","explanation":null,"imageName":"","correctAnswer":11658,"isBookmarked":0,"aid":"11658#11659#12047#12048","text":"$10,000#$20,000#$15,000#$25,000"},
{"testId":595,"qid":81764,"description":"If you are under the age of 18, you must first obtain a temporary license for _______  before applying for an unrestricted driver's license.","explanation":null,"imageName":"","correctAnswer":1273,"isBookmarked":0,"aid":"229#1272#1273#11285","text":"90 days#6 months#12 months#2 years"},
{"testId":657,"qid":81765,"description":"Car B has approached an intersection to make a right turn on a red signal. Car A has a green signal and wishes to continue through the junction straight. Which of the following statements is correct? :","explanation":"If you have a green light, you may go across the junction after yielding to any pedestrians and vehicles currently present. Because Car B has already reached the junction, Car A must yield to Car B. [Right-of-Way, Road Rules, New Mexico Driver Manual]","imageName":"202001271450464240.jpg","correctAnswer":34493,"isBookmarked":0,"aid":"15541#15656#34493#35026","text":"Car A should speed up to pass Car B#Car B must stop and let Car A through#Car A must yield to Car B#Car A should enter the intersection and line up behind Car B"},
{"testId":657,"qid":81766,"description":"Is it legal in New Mexico to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35269#37511","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, unless it's prohibited by a sign#No, not in New Mexico"},
{"testId":657,"qid":81767,"description":"When is it legal to drive in a lane with this sign?","explanation":"High-occupancy vehicle (HOV) lanes are intended for cars that carry numerous persons. This sign indicates that this HOV lane is an HOV 2+ lane, which means that each vehicle must have at least two people. That is, there must be a driver and at least one passenger. A driver and at least two passengers are required for an HOV 3+ lane. [Reserved Lanes, Other Lane Controls, Road Rules, New Mexico Driver Manual]","imageName":"202003051709096729.jpg","correctAnswer":16971,"isBookmarked":0,"aid":"9435#16971#16972#37522","text":"At any time#When you have at least one passenger#When you have at least two passengers#Never (this lane is for buses and trucks)"},
{"testId":657,"qid":81768,"description":"Which of the following places may you park legally?","explanation":null,"imageName":"202003202116529567.jpg","correctAnswer":34505,"isBookmarked":0,"aid":"16391#34504#34505#35486","text":"D) None of the above#B) 35 feet from a railroad crossing#C) 35 feet from a stop sign#A) 15 feet from a crosswalk at an intersection"},
{"testId":657,"qid":81769,"description":"A bright yellow \"X\" appears above your lane. What does it imply?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. In New Mexico, a flashing yellow \"X\" indicates that this lane is reserved for turning. Utilize care since incoming cars may use this lane to make turns. [Reversible Lanes, Additional Lane Controls, Road Rules, New Mexico Driver Manual]","imageName":"202003051707075416.jpg","correctAnswer":15682,"isBookmarked":0,"aid":"15682#27272#30427#30428","text":"This lane is for left turns only#This is a high-occupancy vehicle (HOV) lane#This lane is about to close#This lane is open, but proceed with caution"},
{"testId":596,"qid":81774,"description":"Which of the following is NOT a way to keep from getting tired on long trips?","explanation":null,"imageName":"","correctAnswer":45055,"isBookmarked":0,"aid":"45052#45053#45054#45055","text":"Get enough sleep beforehand#Stop for a break every 100 miles or 2 hours#Drive with a companion#Take medications to stay awake"},
{"testId":596,"qid":81777,"description":"What does chemical test measure?","explanation":null,"imageName":"","correctAnswer":45064,"isBookmarked":0,"aid":"10667#21613#45064#45065","text":"Reaction time#Speed#Blood alcohol content#Driving ability"},
{"testId":596,"qid":81778,"description":"The blood alcohol concentration (BAC) is proportional to:","explanation":null,"imageName":"","correctAnswer":45067,"isBookmarked":0,"aid":"45066#45067#45068#45069","text":"the type of alcohol you've been drinking#the amount of alcohol you've consumed#your fitness level#your height"},
{"testId":1063,"qid":81778,"description":"The blood alcohol concentration (BAC) is proportional to:","explanation":null,"imageName":"","correctAnswer":45067,"isBookmarked":0,"aid":"45066#45067#45068#45069","text":"the type of alcohol you've been drinking#the amount of alcohol you've consumed#your fitness level#your height"},
{"testId":597,"qid":81787,"description":"If you're convicted of DWI twice in three years, your license will be revoked for _____ years.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":81788,"description":"How log will your license be suspended for the first time because of excessive points?","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":1064,"qid":81788,"description":"How log will your license be suspended for the first time because of excessive points?","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#25857","text":"30 days#60 days#90 days#120 days"},
{"testId":597,"qid":81789,"description":"How log will your license be suspended for the second time because of excessive points?","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":1064,"qid":81789,"description":"How log will your license be suspended for the second time because of excessive points?","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#24049#25169#25857","text":"6 months#90 days#12 months#120 days"},
{"testId":597,"qid":81790,"description":"______ points will be added to your driving record if you are found guilty of tailgating.","explanation":null,"imageName":"202001302249003541.jpg","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":597,"qid":81791,"description":"If you pass where signs or pavement markings prohibit it, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1064,"qid":81791,"description":"If you pass where signs or pavement markings prohibit it, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":597,"qid":81792,"description":"In the event that you are found guilty of speeding in a school zone, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1064,"qid":81792,"description":"In the event that you are found guilty of speeding in a school zone, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":597,"qid":81793,"description":"Your license will be suspended for 聽____聽if you're convicted of drag racing in North Carolina.","explanation":null,"imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":1064,"qid":81793,"description":"Your license will be suspended for 聽____聽if you're convicted of drag racing in North Carolina.","explanation":null,"imageName":"","correctAnswer":31350,"isBookmarked":0,"aid":"26650#26651#31350#31351","text":"One year#Two years#Three years#Four years"},
{"testId":597,"qid":81794,"description":"A driver under the age of 18 who is convicted of a second moving infraction within a year may have his or her provisional license suspended for a period of:","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23884#23886#24048#24049","text":"15 days#30 days#60 days#90 days"},
{"testId":658,"qid":81795,"description":"In a two-way left-turn lane, what can you do?","explanation":null,"imageName":"202001301806403967.jpg","correctAnswer":38664,"isBookmarked":0,"aid":"38664#38665#38666#38667","text":"(a) Turn left to leave the roadway#(b) Turn left to enter the roadway#(c) Pass other vehicles#(a) or (b)"},
{"testId":598,"qid":81806,"description":"Your driver license will be withdrawn for _________, if you accumulate 12  points.","explanation":"If you have twelve points on your license, your license will be suspended for seven days. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23886#24049#35988#35989","text":"30 days#90 days#7 days#14 days"},
{"testId":1065,"qid":81806,"description":"Your driver license will be withdrawn for _________, if you accumulate 12  points.","explanation":"If you have twelve points on your license, your license will be suspended for seven days. [North Dakota Noncommercial Drivers License Manual; Point System]","imageName":"","correctAnswer":35988,"isBookmarked":0,"aid":"23886#24049#35988#35989","text":"30 days#90 days#7 days#14 days"},
{"testId":598,"qid":81807,"description":"The option to take an approved driver improvement course instead of getting points is only available for offenses that don't add up to more than:","explanation":null,"imageName":"","correctAnswer":38809,"isBookmarked":0,"aid":"31809#31810#38809#38810","text":"3 points#4 points#5 points#6 points"},
{"testId":1065,"qid":81807,"description":"The option to take an approved driver improvement course instead of getting points is only available for offenses that don't add up to more than:","explanation":null,"imageName":"","correctAnswer":38809,"isBookmarked":0,"aid":"31809#31810#38809#38810","text":"3 points#4 points#5 points#6 points"},
{"testId":598,"qid":81808,"description":"If you refuse to submit to chemical testing at the request of a law enforcement officer, your driver's license will be suspended for the duration of :","explanation":"Your driver's license will be suspended for 180 days to three years if you refuse to submit to chemical testing for alcohol or drugs. [Implied Consent; Driving Under the Influence of Alcohol or Drugs; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":598,"qid":81809,"description":"If you are convicted of a DUI offense for the second time within seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the offense, your driver's license will be suspended for:","explanation":"If you are convicted of a second DUI violation within seven years, your license is normally suspended for 365 days. Your license will be suspended for two years if your AC was 0.18 percent or higher at the time of the violation. You will also face criminal charges, including a ten-day prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23183#25031#26672","text":"2 years#3 years#18 months#5 years"},
{"testId":1065,"qid":81809,"description":"If you are convicted of a DUI offense for the second time within seven years and have an alcohol content (AC) of 0.18 percent or more at the time of the offense, your driver's license will be suspended for:","explanation":"If you are convicted of a second DUI violation within seven years, your license is normally suspended for 365 days. Your license will be suspended for two years if your AC was 0.18 percent or higher at the time of the violation. You will also face criminal charges, including a ten-day prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23183#25031#26672","text":"2 years#3 years#18 months#5 years"},
{"testId":598,"qid":81810,"description":"If it's your third DUI in seven years, your license will be suspended for:","explanation":"Your driver's license will be suspended for two years if you are convicted of a third or subsequent DUI violation within seven years. In addition, you will face criminal sanctions, including a required prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1065,"qid":81810,"description":"If it's your third DUI in seven years, your license will be suspended for:","explanation":"Your driver's license will be suspended for two years if you are convicted of a third or subsequent DUI violation within seven years. In addition, you will face criminal sanctions, including a required prison term. [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving], [\"North Dakota Penalties for Driving Impaired,\" North Dakota Department of Transportation, https://www.dot.nd.gov/divisions/safety/penaltiesdrinkingdriving .htm]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":598,"qid":81811,"description":"If you drive with a suspended or revoked license for an alcohol-related offense, you'll spend ____ days in jail.","explanation":"The consequences for driving with a suspended or revoked license for an alcohol-related infraction include a required minimum prison term of four days and a fine of up to $1,000. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6477#7559","text":"2#4#30#7"},
{"testId":1065,"qid":81811,"description":"If you drive with a suspended or revoked license for an alcohol-related offense, you'll spend ____ days in jail.","explanation":"The consequences for driving with a suspended or revoked license for an alcohol-related infraction include a required minimum prison term of four days and a fine of up to $1,000. [Driving While Impaired; Drinking and Driving; North Dakota Noncommercial Drivers License Manual]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"6388#6390#6477#7559","text":"2#4#30#7"},
{"testId":598,"qid":81812,"description":"If you are guilty of a DUI crime that results in significant bodily harm, you will be sentenced to at least ______ days in jail.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":1065,"qid":81812,"description":"If you are guilty of a DUI crime that results in significant bodily harm, you will be sentenced to at least ______ days in jail.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"1021#1272#1453#11285","text":"1 year#6 months#3 months#2 years"},
{"testId":598,"qid":81813,"description":"If you are convicted of driving without liability insurance for the second time in North Dakota, you must pay a fine of :","explanation":null,"imageName":"","correctAnswer":33102,"isBookmarked":0,"aid":"23887#23889#24978#33102","text":"$500#$1,000#$100#$300"},
{"testId":659,"qid":81814,"description":"Is it legal in North Dakota to turn left at a red light?","explanation":"When going from one one-way roadway to another one-way street in North Dakota, unless signs prevent it, you may turn left on a red light. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Idaho), you may also turn left on red from a two-way street into a one-way street. Other states, such as North Carolina, outright ban left turns on red. Always verify the local driving regulations while going out of state. [North Dakota Noncommercial Drivers License Manual; Traffic Signals; Signals; Signs, Signals, and Road Markings]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35269#38860","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, unless it's prohibited by a sign#No, not in North Dakota"},
{"testId":36,"qid":81815,"description":"On one-way streets, solid yellow lines are used as:","explanation":"A solid yellow line represents the left edge of a one-way street, whereas a solid white line shows the right border. The same is true for divided highways, where the \"left edge\" of the roadway is defined as the space between the leftmost lane and the divider.","imageName":"","correctAnswer":22691,"isBookmarked":0,"aid":"8789#22689#22690#22691","text":"stop lines#right-edge lines#center lines#left-edge lines"},
{"testId":38,"qid":81815,"description":"On one-way streets, solid yellow lines are used as:","explanation":"A solid yellow line represents the left edge of a one-way street, whereas a solid white line shows the right border. The same is true for divided highways, where the \"left edge\" of the roadway is defined as the space between the leftmost lane and the divider.","imageName":"","correctAnswer":22691,"isBookmarked":0,"aid":"8789#22689#22690#22691","text":"stop lines#right-edge lines#center lines#left-edge lines"},
{"testId":599,"qid":81819,"description":"If you are pulled over for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driver's license for at least:","explanation":"If you are stopped for suspected OVI and refuse to submit to chemical testing, the Registrar of Motor Vehicles will suspend your driving license for one to five years, even if you are later found not guilty of OVI in court. If you are found guilty of OVI, the court will impose further suspensions as well as criminal consequences. [Administrative License Suspension/Refusal; Operating a Vehicle While Impaired (OVI); 6 - Traffic Laws; Digest of Ohio Motor Vehicle Laws], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/sites/default/files/pdfs/ovichart.pdf], [Ohio Impaired Driving Law Chart, Garfield Heights Municipal Court, https://www.ghmc.org/ .pdf]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":599,"qid":81820,"description":"First-time OVI conviction can result in maximum of _____聽license suspension.","explanation":"If you are convicted of OVI for the first time, the court will suspend your license for a minimum of six months and a maximum of three years, according to the Digest of Ohio Motor Vehicle Laws. This is in addition to any previous administrative suspension. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":1066,"qid":81820,"description":"First-time OVI conviction can result in maximum of _____聽license suspension.","explanation":"If you are convicted of OVI for the first time, the court will suspend your license for a minimum of six months and a maximum of three years, according to the Digest of Ohio Motor Vehicle Laws. This is in addition to any previous administrative suspension. [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"23153#23154#23183#24049","text":"6 months#1 year#3 years#90 days"},
{"testId":599,"qid":81822,"description":"Your name and address will be added to Ohio's Habitual OVI/OMVI Offender Registry if you are convicted of a ______ OVI violation since September 30, 2008.","explanation":"If you are convicted of a fifth OVI violation in Ohio since September 30, 2008, your name will be added to the state's Habitual OVI/OMVI Offender Registry. This record contains the names, residences, and charges of drivers convicted of OVI five times or more. The Registry is available online at https://services.dps.ohio.gov/OMVI/SearchAndList.aspx. (Note: Previously, the acronym \"OMVI\" stood for \"Operating a Motor Vehicle While Impaired.\") The legislation has subsequently been amended. Today, you may be prosecuted with OVI for driving any vehicle while intoxicated, even a bicycle.) [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":12586,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":1066,"qid":81822,"description":"Your name and address will be added to Ohio's Habitual OVI/OMVI Offender Registry if you are convicted of a ______ OVI violation since September 30, 2008.","explanation":"If you are convicted of a fifth OVI violation in Ohio since September 30, 2008, your name will be added to the state's Habitual OVI/OMVI Offender Registry. This record contains the names, residences, and charges of drivers convicted of OVI five times or more. The Registry is available online at https://services.dps.ohio.gov/OMVI/SearchAndList.aspx. (Note: Previously, the acronym \"OMVI\" stood for \"Operating a Motor Vehicle While Impaired.\") The legislation has subsequently been amended. Today, you may be prosecuted with OVI for driving any vehicle while intoxicated, even a bicycle.) [Penalties; Driving While Impaired (OVI); 6 - Traffic Laws; Ohio Motor Vehicle Laws Digest]","imageName":"","correctAnswer":12586,"isBookmarked":0,"aid":"12584#12585#12586#13337","text":"Third#Fourth#Fifth#Sixth"},
{"testId":600,"qid":81835,"description":"If you refuse to submit to chemical testing for alcohol or drugs when requested by a law enforcement officer, your driver's license will be suspended for up to :","explanation":"If you decline to be tested for drugs or alcohol under Oklahoma's Implied Consent statute, your driving license will be suspended for 180 days to three years. [Oklahoma Driver's Manual; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23154#23183#41648","text":"2 years#1 year#3 years#270 days"},
{"testId":600,"qid":81836,"description":"If you are found guilty of driving while impaired for the second time in the past ten years, the Department of Public Safety will suspend your license for:","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for six months if this is your second conviction in 10 years. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":1067,"qid":81836,"description":"If you are found guilty of driving while impaired for the second time in the past ten years, the Department of Public Safety will suspend your license for:","explanation":"You may be prosecuted with impaired driving in Oklahoma if you are detected driving with a blood alcohol concentration (BAC) of more than 0.05 percent but less than 0.08 percent. If you are convicted, your license will be suspended for six months if this is your second conviction in 10 years. You'll also face criminal charges. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23152#23153#23154","text":"2 years#3 months#6 months#1 year"},
{"testId":600,"qid":81837,"description":"If you are convicted of DUI for the first time, you might face up to ______ in prison.","explanation":"A first DUI is classified as a misdemeanor. If you are found guilty, you will be fined up to $1,000 and imprisoned to 10 days to one year in prison. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":1067,"qid":81837,"description":"If you are convicted of DUI for the first time, you might face up to ______ in prison.","explanation":"A first DUI is classified as a misdemeanor. If you are found guilty, you will be fined up to $1,000 and imprisoned to 10 days to one year in prison. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":600,"qid":81838,"description":"A second DUI conviction carries a maximum fine of:","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":27889,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":1067,"qid":81838,"description":"A second DUI conviction carries a maximum fine of:","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":27889,"isBookmarked":0,"aid":"23887#23889#25036#27889","text":"$500#$1,000#$5,000#$2,500"},
{"testId":600,"qid":81839,"description":"A second DUI violation is penalized by up to ________ in jail.","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":1067,"qid":81839,"description":"A second DUI violation is penalized by up to ________ in jail.","explanation":"A second DUI violation is a felony punishable by a fine of up to $2,500 and a prison term of one to five years. [Oklahoma Driver's Manual; Criminal Evidence and Penalties; Chapter 13: Alcohol, Drugs, and Driving; Section 4 Violations and Penalties]","imageName":"","correctAnswer":26672,"isBookmarked":0,"aid":"23153#23154#23183#26672","text":"6 months#1 year#3 years#5 years"},
{"testId":600,"qid":81840,"description":"A driver under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended:","explanation":"If a motorist under 21 refuses to submit to chemical testing for alcohol or drugs, his or her license will be suspended for six months. (This is the same length of revocation as for a DUI conviction.) He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":11612,"isBookmarked":0,"aid":"11612#22069#23185#40057","text":"for 6 months#for one year#for 30 days#until he or she turns 21"},
{"testId":1067,"qid":81840,"description":"A driver under the age of 21 who refuses to submit to chemical testing for alcohol or drugs will have his or her license suspended:","explanation":"If a motorist under 21 refuses to submit to chemical testing for alcohol or drugs, his or her license will be suspended for six months. (This is the same length of revocation as for a DUI conviction.) He or she may also face criminal charges. [\"Zero Tolerance\" for Drivers Under 21; Section 4 Violations and Penalties; Oklahoma Driver's Manual]","imageName":"","correctAnswer":11612,"isBookmarked":0,"aid":"11612#22069#23185#40057","text":"for 6 months#for one year#for 30 days#until he or she turns 21"},
{"testId":661,"qid":81841,"description":"Is it legal in Oklahoma to turn left at a red light?","explanation":"When going from one one-way street to another one-way street in Oklahoma, unless signs prevent it, you may turn left on a red light. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. It should be noted that the legislation in certain other states vary. In a few places (for example, Idaho), you may also turn left on red from a two-way street into a one-way street. Other states, such as South Dakota, outright ban left turns on red. Always verify the local driving regulations while going out of state. [Red Light: Stop! ; Traffic Signals; Oklahoma Driver's Manual; Chapter 5: Signs, Signals, and Markings]","imageName":"202003112014364009.jpg","correctAnswer":30751,"isBookmarked":0,"aid":"30751#33164#35269#41668","text":"Yes, but only when turning from a one-way street onto another one-way street#Yes, but only when turning onto a one-way street#Yes, unless it's prohibited by a sign#No, not in Oklahoma"},
{"testId":601,"qid":81848,"description":"If you refuse to take an alcohol breath test, the DMV will suspend your license for :","explanation":"If you refuse to take an alcohol breath test, the DMV will suspend your license for a year. If you take the exam and fail for the first time, the DMV will suspend your license for 90 days, which is a significantly shorter term. [\"Oregon Suspension/Revocation/Cancellation Guide,\" Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#24049#25858","text":"1 year#30 days#90 days#180 days"},
{"testId":1068,"qid":81848,"description":"If you refuse to take an alcohol breath test, the DMV will suspend your license for :","explanation":"If you refuse to take an alcohol breath test, the DMV will suspend your license for a year. If you take the exam and fail for the first time, the DMV will suspend your license for 90 days, which is a significantly shorter term. [\"Oregon Suspension/Revocation/Cancellation Guide,\" Oregon DMV; https://www.oregon.gov/ODOT/DMV/docs/oregon suspension guide.pdf] .pdf]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23154#23886#24049#25858","text":"1 year#30 days#90 days#180 days"},
{"testId":601,"qid":81849,"description":"If you do not file an accident report with the DMV as required, your license will be suspended for up to :","explanation":"According to Oregon law, if the accident resulted in death, injury, property damage over $2,500, or damage to a vehicle surpassing $2,500 and some car being towed, you must submit an accident report with the DMV within 72 hours. If you do not submit the report, your license will be suspended for 5 years or until you file the report, whichever comes first. [Your Duties; Traffic Crashes; Other Important Information; Oregon Driver Manual], [ORS 809.417], [ORS 811.720], [ORS 811] .725]","imageName":"202006141611292838.jpg","correctAnswer":26672,"isBookmarked":0,"aid":"20788#23154#23183#26672","text":"2 years#1 year#3 years#5 years"},
{"testId":601,"qid":81850,"description":"A 13- to 20-year-old Oregonian convicted of buying or possessing alcohol will have his or her license suspended:","explanation":"A resident under the age of 21 is prohibited from purchasing or possessing alcoholic drinks under Oregon's Minor in Possession legislation. If a resident between the ages of 13 and 20 is convicted of breaking this legislation, his or her license will be suspended for one year or until the individual reaches the age of 17, whichever comes first. If the individual does not already hold a license, he or she will be barred from applying for one year or until the age of 17, whichever comes first. [ORS 471.430, 809.260, and 809] .280]","imageName":"","correctAnswer":39400,"isBookmarked":0,"aid":"39400#39401#39402#39403","text":"for one year or until he or she turns 17, whichever is longer#for one year or until he or she turns 18, whichever is longer#for one year or until he or she turns 21, whichever is longer#until he or she turns 25"},
{"testId":1068,"qid":81850,"description":"A 13- to 20-year-old Oregonian convicted of buying or possessing alcohol will have his or her license suspended:","explanation":"A resident under the age of 21 is prohibited from purchasing or possessing alcoholic drinks under Oregon's Minor in Possession legislation. If a resident between the ages of 13 and 20 is convicted of breaking this legislation, his or her license will be suspended for one year or until the individual reaches the age of 17, whichever comes first. If the individual does not already hold a license, he or she will be barred from applying for one year or until the age of 17, whichever comes first. [ORS 471.430, 809.260, and 809] .280]","imageName":"","correctAnswer":39400,"isBookmarked":0,"aid":"39400#39401#39402#39403","text":"for one year or until he or she turns 17, whichever is longer#for one year or until he or she turns 18, whichever is longer#for one year or until he or she turns 21, whichever is longer#until he or she turns 25"},
{"testId":601,"qid":81851,"description":"Your vehicle liability insurance policy in Oregon must offer at least ______ in coverage for property damage in any one accident.","explanation":"Your car liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; (4) at least $15,000 per person in personal injury protection; and (5) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12048","text":"$5,000#$10,000#$20,000#$25,000"},
{"testId":601,"qid":81852,"description":"Driving while your license is suspended for DUII will cost you at least:","explanation":"Driving while your license is suspended for DUII is a Class A misdemeanor in Oregon, punishable by up to 364 days in prison, a fine of $1,000 to $6,250, or both. [ORS 161.615, 161.635, and 811] .182]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":1068,"qid":81852,"description":"Driving while your license is suspended for DUII will cost you at least:","explanation":"Driving while your license is suspended for DUII is a Class A misdemeanor in Oregon, punishable by up to 364 days in prison, a fine of $1,000 to $6,250, or both. [ORS 161.615, 161.635, and 811] .182]","imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#25033#27888","text":"$500#$1,000#$2,000#$250"},
{"testId":601,"qid":81853,"description":"Your vehicle liability insurance policy in Oregon must have at least ______ in coverage for personal injury protection in an accident.","explanation":"Your car liability insurance coverage in Oregon must contain the following: (1) at least $25,000 in bodily injury coverage for one person in any one accident; (2) at least $50,000 in bodily injury coverage for two or more people in any one accident; (3) at least $20,000 in property damage coverage in any one accident; (4) at least $15,000 per person in personal injury protection; and (5) uninsured motorist coverage of at least $25,000 in bodily injury coverage for one person and $50,000 in bodily injury coverage for two or more people. [Oregon Driver Manual; Mandatory Insurance; Other Information]","imageName":"","correctAnswer":25251,"isBookmarked":0,"aid":"25248#25249#25250#25251","text":"$10,000 per person#$20,000 per person#$18,000 per person#$15,000 per person"},
{"testId":601,"qid":81854,"description":"If someone was critically hurt in the accident and you are found guilty of fleeing the scene of the accident without stopping (often known as \"hit and run\"), you may be accused of spending up to ___ in jail.","explanation":"The related criminal charge in Oregon is \"failure to execute responsibilities of driver to wounded individuals.\" If you are convicted of this crime, your license will be suspended for at least three years. More significantly, this conduct is classified as a Class C felony, punishable by up to five years in jail, a $125,000 fine, or both. However, if someone was gravely hurt or killed in the accident, the crime will be classified as a Class B felony, punishable by a five-year license suspension plus up to 10 years in jail, a $250,000 fine, or both. [ORS 161.605, 161.625, and 811] .705]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23183#26672#29895#32619","text":"3 years#5 years#10 years#20 years"},
{"testId":1068,"qid":81854,"description":"If someone was critically hurt in the accident and you are found guilty of fleeing the scene of the accident without stopping (often known as \"hit and run\"), you may be accused of spending up to ___ in jail.","explanation":"The related criminal charge in Oregon is \"failure to execute responsibilities of driver to wounded individuals.\" If you are convicted of this crime, your license will be suspended for at least three years. More significantly, this conduct is classified as a Class C felony, punishable by up to five years in jail, a $125,000 fine, or both. However, if someone was gravely hurt or killed in the accident, the crime will be classified as a Class B felony, punishable by a five-year license suspension plus up to 10 years in jail, a $250,000 fine, or both. [ORS 161.605, 161.625, and 811] .705]","imageName":"","correctAnswer":29895,"isBookmarked":0,"aid":"23183#26672#29895#32619","text":"3 years#5 years#10 years#20 years"},
{"testId":601,"qid":81855,"description":"The DMV will revoke your license after __ traffic violations within 5 years.","explanation":"If you are convicted of 20 or more traffic offences within five years, the DMV will revoke your license as a chronic offender for five years. DUII, careless driving, and leaving the scene of an accident without stopping are some of the most severe charges (hit and run). If you are guilty of three of the more severe charges within a five-year period, the DMV will revoke your license. [\"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx] ]","imageName":"","correctAnswer":5448,"isBookmarked":0,"aid":"5446#5448#6425#6477","text":"10#20#25#30"},
{"testId":1068,"qid":81855,"description":"The DMV will revoke your license after __ traffic violations within 5 years.","explanation":"If you are convicted of 20 or more traffic offences within five years, the DMV will revoke your license as a chronic offender for five years. DUII, careless driving, and leaving the scene of an accident without stopping are some of the most severe charges (hit and run). If you are guilty of three of the more severe charges within a five-year period, the DMV will revoke your license. [\"Suspensions, Revocations, and Cancellations\"; Oregon DMV; http://www.oregon.gov/ODOT/DMV/pages/driverid/suspreasons.aspx] ]","imageName":"","correctAnswer":5448,"isBookmarked":0,"aid":"5446#5448#6425#6477","text":"10#20#25#30"},
{"testId":662,"qid":81856,"description":"When may you turn left at a red light in Oregon?","explanation":"Unless otherwise indicated, you may turn left on a steady red light, red bicycle signal, or even a red arrow signal in Oregon to enter a one-way street. Before turning, you must first come to a complete stop and yield to pedestrians and traffic. It should be noted that the legislation in many other states vary in this regard. Many states make it illegal to turn on a red arrow. Some states make it illegal to make a left turn on red from a two-way street into a one-way street. Other states outright ban left turns on red. Always verify the local driving rules while leaving Oregon. [Oregon Driver Manual, Traffic Signals, 1. Signs and Traffic Signals]","imageName":"202007120412393828.jpg","correctAnswer":15970,"isBookmarked":0,"aid":"6334#15970#26726#39454","text":"Never#Only when you are turning onto a one-way street from any other street#Only when you are turning from a one-way street onto another one-way street#At any time, unless signs prohibit it"},
{"testId":602,"qid":81862,"description":"__ points will be added to your driving record for disobeying a traffic officer.","explanation":"If you are convicted of failing to obey a traffic officer, two points will be added to your driving record. The fewest amount of points for any one infraction is two. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":81862,"description":"__ points will be added to your driving record for disobeying a traffic officer.","explanation":"If you are convicted of failing to obey a traffic officer, two points will be added to your driving record. The fewest amount of points for any one infraction is two. [\"The Pennsylvania Point System Fact Sheet,\" Pennsylvania Department of Transportation, November 2013, http://www.dot.state.pa.us/public/DVSPubsForms/BDL/BDL Fact Sheets/FS-PS] .pdf]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":81863,"description":"__ points will be added to your driving record if you pass on a hill with a blocked view.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":81863,"description":"__ points will be added to your driving record if you pass on a hill with a blocked view.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":81864,"description":"For how many days will your license be suspended if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5447#6355#6477#8141","text":"15#90#30#60"},
{"testId":1069,"qid":81864,"description":"For how many days will your license be suspended if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5447#6355#6477#8141","text":"15#90#30#60"},
{"testId":602,"qid":81865,"description":"How many points will be added to your driving record if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":81865,"description":"How many points will be added to your driving record if you fail to stop for a stopped school bus with flashing red lights?","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":81866,"description":"If you're caught driving 50 mph in a 30-mph zone, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1069,"qid":81866,"description":"If you're caught driving 50 mph in a 30-mph zone, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":602,"qid":81867,"description":"If you're caught driving 60 mph in a 30-mph zone, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":1069,"qid":81867,"description":"If you're caught driving 60 mph in a 30-mph zone, you'll get __ points.","explanation":null,"imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6390#6916#7559","text":"5#4#6#7"},
{"testId":602,"qid":81868,"description":"In the event that you are found guilty of speeding in a school zone, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":1069,"qid":81868,"description":"In the event that you are found guilty of speeding in a school zone, the following number of points will be added to your driving record:","explanation":null,"imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6389#6390#6916","text":"5#3#4#6"},
{"testId":603,"qid":81879,"description":"Among other things, you will be forced to pay an assessment of ____ in support of the state's chemical testing programs if you are found guilty of refusing a chemical test.","explanation":"If you are convicted of rejecting a chemical test, you must pay a $200 assessment to fund the state's chemical testing services. This is in addition to any fines or other penalties imposed for the violation. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11349#11832#12358","text":"$500#$50#$100#$200"},
{"testId":1070,"qid":81879,"description":"Among other things, you will be forced to pay an assessment of ____ in support of the state's chemical testing programs if you are found guilty of refusing a chemical test.","explanation":"If you are convicted of rejecting a chemical test, you must pay a $200 assessment to fund the state's chemical testing services. This is in addition to any fines or other penalties imposed for the violation. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#11349#11832#12358","text":"$500#$50#$100#$200"},
{"testId":603,"qid":81880,"description":"A driver under the age of 18 who is convicted of DWI for the first time will have his or her license suspended for a period of up to:","explanation":"A motorist under the age of 18 who is convicted of DWI for the first time will have his or her license suspended for six months to one year. His or her license will be revoked indefinitely unless he or she completes a specific course on driving while drunk and submits to alcohol or drug treatment. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":25169,"isBookmarked":0,"aid":"23153#23883#23886#25169","text":"6 months#10 days#30 days#12 months"},
{"testId":603,"qid":81881,"description":"For a first offense, an adult driver convicted of DUI with a blood alcohol content (BAC) less than 0.10 percent faces up to ______ in jail.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"742#1021#1272#11285","text":"30 days#1 year#6 months#2 years"},
{"testId":603,"qid":81882,"description":"For a first offense, an adult driver convicted of DUI with a blood alcohol content (BAC) less than 0.10 percent faces up to ______ in jail.","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#229#742#1020","text":"180 days#90 days#30 days#60 days"},
{"testId":603,"qid":81883,"description":"For the second time in five years, an adult motorist convicted of DUI will be punished to at least ______ in jail if his or her blood alcohol content was at least 0.15%.","explanation":"If an adult motorist is convicted of DUI for the second time, he or she will face prison time. If the driver's blood alcohol content (BAC) was less than 0.15 percent at the time of the violation, the penalty will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":739,"isBookmarked":0,"aid":"739#742#12360#18239","text":"10 days#30 days#7 days#14 days"},
{"testId":603,"qid":81884,"description":"For the second time in five years, an adult motorist convicted of DUI will be punished to at least ______ in jail if his or her blood alcohol content was at least 0.15%.","explanation":"If an adult motorist is convicted of DUI for the second time, he or she will face prison time. If the driver's blood alcohol content (BAC) was less than 0.15 percent at the time of the violation, the penalty will be between ten days and one year, or between six months and one year if the BAC was at least 0.15 percent. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23152#23153#26650","text":"9 months#3 months#6 months#One year"},
{"testId":603,"qid":81885,"description":"For the second time in five years, an adult motorist convicted of DUI will be punished to at least ______ in jail if his or her blood alcohol content was at least 0.15%.","explanation":null,"imageName":"","correctAnswer":25036,"isBookmarked":0,"aid":"23889#25036#27889#31397","text":"$1,000#$5,000#$2,500#$10,000"},
{"testId":603,"qid":81886,"description":"If a driver under the age of 21 is convicted of transporting opened or unopened alcohol containers in a motor vehicle, his or her driving privileges will be revoked for up to ___ days on the first offense.","explanation":"If a motorist under the age of 21 is convicted for the first time of carrying opened or unopened containers of alcohol in a motor vehicle, he or she faces a $250 fine and a 30-day suspension of driving privileges. [E. Penalties; Chapter X. Driving While Intoxicated; Rhode Island Driver's Manual], [R. I. G. L. 3-8-9]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":603,"qid":81887,"description":"A motorist under the age of 21 who is guilty of having alcoholic beverages will have his or her driving license suspended for at least ___ days for a first offense.","explanation":null,"imageName":"","correctAnswer":8141,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":603,"qid":81888,"description":"You'll be fined ____ 聽if you carry a passenger over 18 without a seat belt.","explanation":"You will be fined $40 if you are guilty of not wearing a seat belt. If you are found guilty of transporting a passenger under the age of 18 who is not wearing a seat belt, you will be fined the same amount. [R. I. G. L. 31-22-22], [R. I. G. L. 31-41.1-4], [Chapter III. Basic Driving Skills and Safety Rules; Rhode Island Driver's Manual],","imageName":"","correctAnswer":31884,"isBookmarked":0,"aid":"24978#24993#24994#31884","text":"$100#$25#$50#$40"},
{"testId":664,"qid":81889,"description":"In which of the following scenarios is it allowed to park the car?","explanation":"You may not park within 20 feet of a crosswalk at an intersection, 50 feet of the closest rail at a railroad crossing, or 30 feet of a stop sign, traffic signal, or flashing red or yellow light in Rhode Island. (There are also additional areas where parking is prohibited.) Look for signs or markings on the road that ban or limit parking.) In Scenario A, the automobile is far enough away from the crossing to park lawfully. Other states may have differing minimum parking distances. Always verify the local driving regulations while going out of state. [Rhode Island Driver's Manual, Where You May Not Park or Stop, F. Stopping and Parking, III. Basic Driving Skills and Safety Rules]","imageName":"202003202116529567.jpg","correctAnswer":40071,"isBookmarked":0,"aid":"16391#40071#40072#40073","text":"D) None of the above#A) 25 feet from a crosswalk at an intersection#B) 25 feet from a railroad crossing#C) 25 feet from a stop sign"},
{"testId":605,"qid":81911,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are guilty of failing to cede the right-of-way, you will get four points on your license. Depending on the circumstances of the violation, you may potentially face a fine, a prison term, or both. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1072,"qid":81911,"description":"__ points will be added to your driving record for failing to yield.","explanation":"If you are guilty of failing to cede the right-of-way, you will get four points on your license. Depending on the circumstances of the violation, you may potentially face a fine, a prison term, or both. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":605,"qid":81912,"description":"________ points will be added to your driving record if you are found guilty of running a red light or stop sign.","explanation":"If you are convicted of failing to obey a red light or stop sign, you will get three points on your driving record. A fine will also be imposed. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1072,"qid":81912,"description":"________ points will be added to your driving record if you are found guilty of running a red light or stop sign.","explanation":"If you are convicted of failing to obey a red light or stop sign, you will get three points on your driving record. A fine will also be imposed. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":605,"qid":81913,"description":"The first time your license is suspended for having too many points, the suspension will last up to :","explanation":"The first time your license is suspended for having too many points, the suspension might last up to 60 days. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1072,"qid":81913,"description":"The first time your license is suspended for having too many points, the suspension will last up to :","explanation":"The first time your license is suspended for having too many points, the suspension might last up to 60 days. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":605,"qid":81914,"description":"If your license is suspended for the second time for having too many points, the suspension will last up to :","explanation":"If your license is suspended for the second time for having too many points, the suspension might last up to six months. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#23154#24049","text":"9 months#6 months#1 year#90 days"},
{"testId":1072,"qid":81914,"description":"If your license is suspended for the second time for having too many points, the suspension will last up to :","explanation":"If your license is suspended for the second time for having too many points, the suspension might last up to six months. [South Dakota Driver License Manual; Revocation and Suspension of License; South Dakota Point System]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20789#23153#23154#24049","text":"9 months#6 months#1 year#90 days"},
{"testId":605,"qid":81915,"description":"Your likelihood of being in a collision might be significantly increased by having a blood alcohol concentration (BAC) as low as:","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":1072,"qid":81915,"description":"Your likelihood of being in a collision might be significantly increased by having a blood alcohol concentration (BAC) as low as:","explanation":null,"imageName":"","correctAnswer":4252,"isBookmarked":0,"aid":"3168#3170#4251#4252","text":"0.08%#0.07%#0.02%#0.05%"},
{"testId":605,"qid":81916,"description":"A first DWI conviction can result in up to ____ in prison.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":1072,"qid":81916,"description":"A first DWI conviction can result in up to ____ in prison.","explanation":null,"imageName":"","correctAnswer":1021,"isBookmarked":0,"aid":"227#229#742#1021","text":"180 days#90 days#30 days#1 year"},
{"testId":605,"qid":81917,"description":"A third DWI conviction can result in up to ____ in prison.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":1072,"qid":81917,"description":"A third DWI conviction can result in up to ____ in prison.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#1272#11285#11341","text":"1 year#6 months#2 years#5 years"},
{"testId":605,"qid":81918,"description":"If convicted of driving while license revoked for a second DWI, you'll spend at least ___ in jail.","explanation":null,"imageName":"","correctAnswer":12361,"isBookmarked":0,"aid":"739#12361#12685#40595","text":"10 days#3 days#5 days#2 days"},
{"testId":666,"qid":81919,"description":"You're driving down a two-lane street when you come across a school bus with flashing amber lights. What must you do?","explanation":"On a two-lane or private road, whenever you approach a school bus with flashing amber lights, you must slow to 20 mph and proceed with caution regardless of which side of the road the bus is on. [Right-of-Way, Rules of the Road, South Dakota Driver License Manual]","imageName":"202003070814106045.jpg","correctAnswer":40618,"isBookmarked":0,"aid":"26363#40618#40619#40620","text":"Stop#Slow to 20 mph and proceed with caution#Slow to 20 mph and proceed with caution, but only if you're traveling in the same direction as the school bus#Stop only if you're traveling in the same direction as the school bus"},
{"testId":606,"qid":81922,"description":"If convicted of DUI for the fourth time, you will face a fine of at least:","explanation":null,"imageName":"","correctAnswer":25034,"isBookmarked":0,"aid":"23887#23889#25034#31397","text":"$500#$1,000#$3,000#$10,000"},
{"testId":606,"qid":81923,"description":"If convicted of DUI for the second time, the court will order you to install an ignition interlock device (IID) in every car you operate for a period of up to:","explanation":"If you are convicted of DUI for the second time, the court will order you to place an ignition interlock device (IID) in every car you drive for a period of up to three years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":606,"qid":81924,"description":"If you're convicted of DUI for the ______ time, the court will require you to install an IID for up to ten years on each motor vehicle you operate.","explanation":"If you get a third or subsequent DUI, the court will order you to place an ignition interlock device (IID) in every car you drive for up to 10 years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [DUI Penalties; The \"DUI\" Law; B-7. Alcohol, Other Drugs, and Driving; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":12584,"isBookmarked":0,"aid":"12583#12584#12585#13064","text":"Second#Third#Fourth#First"},
{"testId":606,"qid":81925,"description":"If you are found guilty of exceeding the posted speed limit by 16 to 25 mph in a construction zone, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the legal speed limit by 16 to 25 mph, four points will be added to your driving record. However, if you are caught speeding in a work zone, you will get one additional point for violations that ordinarily carry up to five points. (For crimes that typically carry six points apiece, two more points will be added.) B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1073,"qid":81925,"description":"If you are found guilty of exceeding the posted speed limit by 16 to 25 mph in a construction zone, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the legal speed limit by 16 to 25 mph, four points will be added to your driving record. However, if you are caught speeding in a work zone, you will get one additional point for violations that ordinarily carry up to five points. (For crimes that typically carry six points apiece, two more points will be added.) B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":606,"qid":81926,"description":"If you are found guilty of exceeding the posted speed limit by 6 to 15 miles per hour, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the legal speed limit by 6 to 15 mph, three points will be added to your driving record. B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1073,"qid":81926,"description":"If you are found guilty of exceeding the posted speed limit by 6 to 15 miles per hour, ___ points will be added to your driving record.","explanation":"If you are found guilty of exceeding the legal speed limit by 6 to 15 mph, three points will be added to your driving record. B-8. Driving Responsibilities; Tennessee Comprehensive Driver License Manual]","imageName":"","correctAnswer":6389,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":667,"qid":81927,"description":"Is it legal to make a left turn at a red light in Tennessee?","explanation":"When going from one one-way street to another one-way street in Tennessee, unless signs prevent it, you may turn left on a red light. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [B. Turns on Red, Intersections, Tennessee Driver Manual, Section B-4 Rules of the Road]","imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":607,"qid":81938,"description":"A driver under the age of 21 who is convicted of DWI while in possession of an open container of alcohol faces a jail sentence of at least ______ for a first offense.","explanation":null,"imageName":"","correctAnswer":40914,"isBookmarked":0,"aid":"739#40595#40913#40914","text":"10 days#2 days#4 days#6 days"},
{"testId":607,"qid":81939,"description":"If an adult driver is found guilty of DWI for the first time, he or she will lose the right to drive for up to:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":607,"qid":81940,"description":"If an adult driver is found guilty of DWI for the first time, he or she will lose the right to drive for at least:","explanation":"If you are convicted of DWI for the first time, you will face a fine of up to $2,000 and a prison term of 72 hours to 180 days. Your license will also be suspended for 365 days (if you are under the age of 21) or for a period of 90 days to 365 days (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23883#24048#24049#25858","text":"10 days#60 days#90 days#180 days"},
{"testId":607,"qid":81941,"description":"For a second offense, a driver convicted of DWI faces a prison sentence of at least___.","explanation":"If you are convicted of DWI for the second time, you will be fined up to $4,000 and imprisoned to 30 days to one year in jail. Your license will also be suspended for 180 days to 18 months (if you are under the age of 21) or 180 days to two years (if you are at least 21 years of age). [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":742,"isBookmarked":0,"aid":"742#1020#12361#18239","text":"30 days#60 days#3 days#14 days"},
{"testId":607,"qid":81942,"description":"An adult driver guilty of DWI will lose his or her driving privileges for up to ________ for a second conviction.","explanation":"If an adult driver is convicted of DWI for the second time, he or she faces a $4,000 fine and a prison term of 30 days to one year. His or her license will also be suspended for a period ranging from 180 days to two years. [Penalties for DWI and DUI using alcohol or drugs; Chapter 10: The Impact of Alcohol and Drugs on Driving Ability; Texas Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":607,"qid":81943,"description":"A driver who is convicted of DWI for the third time will lose his or her driving privileges for at least_____","explanation":null,"imageName":"","correctAnswer":227,"isBookmarked":0,"aid":"227#1021#11285#11341","text":"180 days#1 year#2 years#5 years"},
{"testId":607,"qid":81944,"description":"If a driver under the age of 21 is convicted of DUI (not DWI) for the third time, he or she may face a fine of up to:","explanation":null,"imageName":"","correctAnswer":25033,"isBookmarked":0,"aid":"23889#25033#25034#25035","text":"$1,000#$2,000#$3,000#$4,000"},
{"testId":607,"qid":81945,"description":"If you were in an accident that caused at least _______ in property damage (including your own), you must file a Driver's Crash Report.","explanation":null,"imageName":"202006141611292838.jpg","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":668,"qid":81946,"description":"You're on a four-lane highway. You notice an emergency vehicle stopped ahead with its lights blazing. What should you do?","explanation":null,"imageName":"202001241703386758.jpg","correctAnswer":39336,"isBookmarked":0,"aid":"15493#28148#35047#39336","text":"Slow down#Proceed with caution#Slow down; also move into a non-adjacent lane if possible#Move into a non-adjacent lane if possible; otherwise slow down"},
{"testId":608,"qid":81953,"description":"If a driver under the age of 21 breaches Utah's \"Not a Drop\" law, his or her driving license will be suspended for at least:","explanation":"Under Utah's \"Not a Drop\" Law, a minor may not drive with any measurable amount of alcohol in his or her system. If a juvenile is convicted for the first time of breaking this statute, his or her license will be revoked for six months or until the minor completes an alcohol evaluation and any recommendations given by an accredited substance addiction practitioner, whichever comes first. [Unlawful Consumption of Alcohol; Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":608,"qid":81954,"description":"A driver under the age of 21 will be required to appear for a hearing if he or she accumulates _________ or more points within three-year period.","explanation":"A motorist under the age of 21 who has acquired 70 or more points in the previous three years will be obliged to appear for a hearing. The hearing is held to decide what remedial steps, such as suspending the driver's license, should be implemented. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":9656,"isBookmarked":0,"aid":"6591#9656#12359#12996","text":"100#70#80#150"},
{"testId":1075,"qid":81954,"description":"A driver under the age of 21 will be required to appear for a hearing if he or she accumulates _________ or more points within three-year period.","explanation":"A motorist under the age of 21 who has acquired 70 or more points in the previous three years will be obliged to appear for a hearing. The hearing is held to decide what remedial steps, such as suspending the driver's license, should be implemented. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":9656,"isBookmarked":0,"aid":"6591#9656#12359#12996","text":"100#70#80#150"},
{"testId":608,"qid":81955,"description":"If a driver less than 21 years old earns excessive points for offenses, his or her driving license may be suspended for at least:","explanation":"If a motorist under the age of 21 collects 70 or more points in three years, his or her license may be suspended for 30 days to one year, depending on the severity of his or her driving record. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":1075,"qid":81955,"description":"If a driver less than 21 years old earns excessive points for offenses, his or her driving license may be suspended for at least:","explanation":"If a motorist under the age of 21 collects 70 or more points in three years, his or her license may be suspended for 30 days to one year, depending on the severity of his or her driving record. [Appendix F: License Revocation and Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23886#24049#25857#25858","text":"30 days#90 days#120 days#180 days"},
{"testId":608,"qid":81956,"description":"Conviction for the careless driving will add____points to driving record.","explanation":"If you are convicted of careless driving, you will get 80 points on your license. As a consequence, even one such conviction by a motorist under the age of 21 would result in a hearing and possibly license suspension. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":12359,"isBookmarked":0,"aid":"7571#8141#9656#12359","text":"50#60#70#80"},
{"testId":1075,"qid":81956,"description":"Conviction for the careless driving will add____points to driving record.","explanation":"If you are convicted of careless driving, you will get 80 points on your license. As a consequence, even one such conviction by a motorist under the age of 21 would result in a hearing and possibly license suspension. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":12359,"isBookmarked":0,"aid":"7571#8141#9656#12359","text":"50#60#70#80"},
{"testId":608,"qid":81957,"description":"All points on your driving record will be deleted if you drive for _______ without a traffic conviction.","explanation":"All points on your driving record will be deleted if you keep a clean driving record for two years. Individual conviction points are automatically deleted from your record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":1075,"qid":81957,"description":"All points on your driving record will be deleted if you drive for _______ without a traffic conviction.","explanation":"All points on your driving record will be deleted if you keep a clean driving record for two years. Individual conviction points are automatically deleted from your record three years after the infraction date. [Appendix F: License Revocation & Suspension, Alcohol, Point System, Driving Records; Utah Driver Handbook]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":669,"qid":81958,"description":"What is required under Utah's basic speed law?","explanation":null,"imageName":"202003151746465721.jpg","correctAnswer":41032,"isBookmarked":0,"aid":"41032#41033#41034#41035","text":"Never drive faster than is reasonably safe#Always drive at the posted speed limit#Always drive slower than the posted speed limit#Never exceed the speed limit except when passing"},
{"testId":609,"qid":81968,"description":"A driver under the age of 21 who is found guilty of carrying or consuming alcohol may have his or her license suspended for ___ days for the first offense.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1076,"qid":81968,"description":"A driver under the age of 21 who is found guilty of carrying or consuming alcohol may have his or her license suspended for ___ days for the first offense.","explanation":null,"imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":609,"qid":81969,"description":"A driver less than 18 years old who is found guilty of presenting false identification in an attempt to acquire tobacco may face a fine of:","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#41027","text":"$100#$25#$50#$15"},
{"testId":1076,"qid":81969,"description":"A driver less than 18 years old who is found guilty of presenting false identification in an attempt to acquire tobacco may face a fine of:","explanation":null,"imageName":"","correctAnswer":24994,"isBookmarked":0,"aid":"24978#24993#24994#41027","text":"$100#$25#$50#$15"},
{"testId":609,"qid":81970,"description":"First Time DUI offenders may be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1076,"qid":81970,"description":"First Time DUI offenders may be sentenced to jail for up to:","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":609,"qid":81971,"description":"Your license will be suspended for at least ________ if you refuse to submit to a chemical test for alcohol or drugs when requested to do so by a law enforcement officer.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":1076,"qid":81971,"description":"Your license will be suspended for at least ________ if you refuse to submit to a chemical test for alcohol or drugs when requested to do so by a law enforcement officer.","explanation":null,"imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23886#24049","text":"6 months#1 year#30 days#90 days"},
{"testId":609,"qid":81972,"description":"If you are found guilty of speeding, up to __ points will be added to your driving record, depending on your speed.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":1076,"qid":81972,"description":"If you are found guilty of speeding, up to __ points will be added to your driving record, depending on your speed.","explanation":null,"imageName":"","correctAnswer":7620,"isBookmarked":0,"aid":"5446#6390#6916#7620","text":"10#4#6#8"},
{"testId":609,"qid":81973,"description":"Driving without a valid聽license will add __ points to your record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1076,"qid":81973,"description":"Driving without a valid聽license will add __ points to your record.","explanation":null,"imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":609,"qid":81974,"description":"A driver under the age of 18 must have a clean driving record for the previous _____ in order to receive a learner's permit.","explanation":null,"imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#23153#23886#27048","text":"2 years#6 months#30 days#year"},
{"testId":609,"qid":81975,"description":"A motorist under the age of 18 with a learner's permit must keep a clean driving record for ______ before applying for a junior driver's license.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"742#1021#1272#1454","text":"30 days#1 year#6 months#9 months"},
{"testId":609,"qid":81976,"description":"As a first-time offender, you might face up to______聽in prison if convicted of careless driving.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1076,"qid":81976,"description":"As a first-time offender, you might face up to______聽in prison if convicted of careless driving.","explanation":null,"imageName":"","correctAnswer":23154,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":670,"qid":81977,"description":"In Vermont, parking is prohibited within ___ of a railroad crossing.","explanation":null,"imageName":"202002181114128699.jpg","correctAnswer":1026,"isBookmarked":0,"aid":"1026#1028#8416#8417","text":"50 feet#10 feet#15 feet#30 feet"},
{"testId":670,"qid":81978,"description":"In Vermont, it is prohibited to park within _____ of a crosswalk.","explanation":null,"imageName":"202003241454204243.jpg","correctAnswer":4582,"isBookmarked":0,"aid":"1028#1591#4582#8416","text":"10 feet#5 feet#20 feet#15 feet"},
{"testId":670,"qid":81979,"description":"Is it legal in Vermont to turn left at a red light?","explanation":null,"imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":610,"qid":81983,"description":"If the DMV orders you to attend a driver improvement clinic due to traffic offenses and you do not complete it within ______ days, your driving privileges will be suspended.","explanation":"If you are required by the DMV to attend a driver improvement clinic due to traffic offences and fail to do so within 90 days, your driving privileges will be terminated. [Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":1077,"qid":81983,"description":"If the DMV orders you to attend a driver improvement clinic due to traffic offenses and you do not complete it within ______ days, your driving privileges will be suspended.","explanation":"If you are required by the DMV to attend a driver improvement clinic due to traffic offences and fail to do so within 90 days, your driving privileges will be terminated. [Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":229,"isBookmarked":0,"aid":"229#739#742#1020","text":"90 days#10 days#30 days#60 days"},
{"testId":610,"qid":81984,"description":"If an underage driver is convicted of a second traffic offence, his or her license will be suspended for _______.","explanation":"If a motorist under the age of 18 gets convicted for the second time of a traffic offense or a violation of Virginia's safety belt or child restraint rules, his or her driving rights are terminated for 90 days. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1077,"qid":81984,"description":"If an underage driver is convicted of a second traffic offence, his or her license will be suspended for _______.","explanation":"If a motorist under the age of 18 gets convicted for the second time of a traffic offense or a violation of Virginia's safety belt or child restraint rules, his or her driving rights are terminated for 90 days. [Under the Age of 18; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":610,"qid":81985,"description":"If a motorist over the age of 18 accumulates ___ demerit points in a year period, his or her license will be revoked for 90 days.","explanation":"If a motorist under the age of 18 earns 18 demerit points in a 12-month period or 24 demerit points in a 24-month period, his or her license will be suspended for 90 days. He or she will also have to attend a driving improvement clinic. [Age 18 or Older; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":1077,"qid":81985,"description":"If a motorist over the age of 18 accumulates ___ demerit points in a year period, his or her license will be revoked for 90 days.","explanation":"If a motorist under the age of 18 earns 18 demerit points in a 12-month period or 24 demerit points in a 24-month period, his or her license will be suspended for 90 days. He or she will also have to attend a driving improvement clinic. [Age 18 or Older; Other DMV Requirements, Suspensions, and Revocations; Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":11359,"isBookmarked":0,"aid":"6478#11359#11361#11784","text":"12#18#16#14"},
{"testId":610,"qid":81986,"description":"If you are arrested for DUI for the second time, your driving privileges will be immediately revoked for _______ or until your trial date, whichever comes first.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"228#229#742#1020","text":"120 days#90 days#30 days#60 days"},
{"testId":1077,"qid":81986,"description":"If you are arrested for DUI for the second time, your driving privileges will be immediately revoked for _______ or until your trial date, whichever comes first.","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"228#229#742#1020","text":"120 days#90 days#30 days#60 days"},
{"testId":610,"qid":81987,"description":"Your driving privileges will be suspended for the duration of your trial or for _______ if you are charged with the second offense of refusing a breath test. :","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":1077,"qid":81987,"description":"Your driving privileges will be suspended for the duration of your trial or for _______ if you are charged with the second offense of refusing a breath test. :","explanation":null,"imageName":"","correctAnswer":1020,"isBookmarked":0,"aid":"229#742#1020#12360","text":"90 days#30 days#60 days#7 days"},
{"testId":610,"qid":81988,"description":"If you're convicted of DUI with a child passenger in the car, you will face an additional fine of at least:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":1077,"qid":81988,"description":"If you're convicted of DUI with a child passenger in the car, you will face an additional fine of at least:","explanation":null,"imageName":"","correctAnswer":23889,"isBookmarked":0,"aid":"23887#23889#24978#25033","text":"$500#$1,000#$100#$2,000"},
{"testId":610,"qid":81989,"description":"If you are caught driving while your license is suspended or revoked for an alcohol-related violation, your car will be confiscated immediately.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#35988","text":"10 days#30 days#60 days#7 days"},
{"testId":1077,"qid":81989,"description":"If you are caught driving while your license is suspended or revoked for an alcohol-related violation, your car will be confiscated immediately.","explanation":null,"imageName":"","correctAnswer":23886,"isBookmarked":0,"aid":"23883#23886#24048#35988","text":"10 days#30 days#60 days#7 days"},
{"testId":610,"qid":81990,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, your car may be impounded for an additional:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":1077,"qid":81990,"description":"If you drive while your license is suspended or revoked for an alcohol or drug offense, your car may be impounded for an additional:","explanation":null,"imageName":"","correctAnswer":24049,"isBookmarked":0,"aid":"23886#24048#24049#25858","text":"30 days#60 days#90 days#180 days"},
{"testId":610,"qid":81991,"description":"If you are caught driving without a license after previously been convicted, your vehicle will be seized for up to six months.","explanation":"If you are caught driving without a license after previously been convicted, your car will be confiscated for three days or until you receive a license, whichever comes first. Six penalty points will be added to your driving record as well. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015] [Vehicle Impoundment; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":34746,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":1077,"qid":81991,"description":"If you are caught driving without a license after previously been convicted, your vehicle will be seized for up to six months.","explanation":"If you are caught driving without a license after previously been convicted, your car will be confiscated for three days or until you receive a license, whichever comes first. Six penalty points will be added to your driving record as well. [\"Moving Violations and Point Assessments\" (DMV 115); Virginia Department of Motor Vehicles; July 1, 2015] [Vehicle Impoundment; Alcohol and the Law; Section 5: Penalties; Virginia Driver's Manual]","imageName":"","correctAnswer":34746,"isBookmarked":0,"aid":"23886#24048#24049#34746","text":"30 days#60 days#90 days#3 days"},
{"testId":610,"qid":81992,"description":"If you are caught driving an uninsured car and have not paid the uninsured motor vehicle fee, you will be fined.","explanation":"If you are caught driving an uninsured motor vehicle and have not paid the $500 uninsured motor vehicle charge, your license will be revoked until you get liability insurance for the vehicle or pay the $500 cost. [Requirements for Insurance; Section 7: Other Important Information; Virginia Driver's Manual]","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"27717#29912#29914#41291","text":"all of the above will happen#your license will be suspended#your vehicle will be impounded#you will be fined $1,000"},
{"testId":1077,"qid":81992,"description":"If you are caught driving an uninsured car and have not paid the uninsured motor vehicle fee, you will be fined.","explanation":"If you are caught driving an uninsured motor vehicle and have not paid the $500 uninsured motor vehicle charge, your license will be revoked until you get liability insurance for the vehicle or pay the $500 cost. [Requirements for Insurance; Section 7: Other Important Information; Virginia Driver's Manual]","imageName":"","correctAnswer":29912,"isBookmarked":0,"aid":"27717#29912#29914#41291","text":"all of the above will happen#your license will be suspended#your vehicle will be impounded#you will be fined $1,000"},
{"testId":611,"qid":82001,"description":"If a driver fails to properly secure a cargo and causes an injury or death to another person, he or she will:","explanation":"A person who causes an injury or death by failing to securely secure a load faces a gross misdemeanor charge and a potential punishment of one year in prison and a $5,000 fine. A motorist who causes property damage with unsecured objects may face misdemeanor charges. [Maria's Law; Washington Driver Guide; Before You Drive]","imageName":"","correctAnswer":45532,"isBookmarked":0,"aid":"45529#45530#45531#45532","text":"be charged with gross misdemeanor#have to spend up to 1 year in jail#have to pay up to $5,000 fine#have to face all of the above consequences"},
{"testId":1078,"qid":82001,"description":"If a driver fails to properly secure a cargo and causes an injury or death to another person, he or she will:","explanation":"A person who causes an injury or death by failing to securely secure a load faces a gross misdemeanor charge and a potential punishment of one year in prison and a $5,000 fine. A motorist who causes property damage with unsecured objects may face misdemeanor charges. [Maria's Law; Washington Driver Guide; Before You Drive]","imageName":"","correctAnswer":45532,"isBookmarked":0,"aid":"45529#45530#45531#45532","text":"be charged with gross misdemeanor#have to spend up to 1 year in jail#have to pay up to $5,000 fine#have to face all of the above consequences"},
{"testId":611,"qid":82002,"description":"In work zones, traffic tickets are:","explanation":"In work zones, traffic penalties are doubled, and irresponsibly harming employees, other motorists, or property may result in a 60-day license suspension. [Traffic Adjustment; Safe Driving Tips; Washington Driver Guide]","imageName":"","correctAnswer":5340,"isBookmarked":0,"aid":"5340#5343#12272#45535","text":"doubled#the same#tripled#decreased"},
{"testId":1078,"qid":82002,"description":"In work zones, traffic tickets are:","explanation":"In work zones, traffic penalties are doubled, and irresponsibly harming employees, other motorists, or property may result in a 60-day license suspension. [Traffic Adjustment; Safe Driving Tips; Washington Driver Guide]","imageName":"","correctAnswer":5340,"isBookmarked":0,"aid":"5340#5343#12272#45535","text":"doubled#the same#tripled#decreased"},
{"testId":611,"qid":82003,"description":"If a driver between the ages of 13 and 17 is convicted of alcohol possession, a drug crime, or a firearm-related violation, his or her license will be suspended for at least ______ for a second conviction.","explanation":"If a motorist aged 13 to 17 is convicted of possession of alcohol, a drug crime, or a firearm-related violation for the second time, his or her license will be suspended for two years or until the age of 18, whichever comes first. [In Shape to Drive; Washington Driver Guide; Juvenile Alcohol/Drug/Firearms Violations]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":1078,"qid":82003,"description":"If a driver between the ages of 13 and 17 is convicted of alcohol possession, a drug crime, or a firearm-related violation, his or her license will be suspended for at least ______ for a second conviction.","explanation":"If a motorist aged 13 to 17 is convicted of possession of alcohol, a drug crime, or a firearm-related violation for the second time, his or her license will be suspended for two years or until the age of 18, whichever comes first. [In Shape to Drive; Washington Driver Guide; Juvenile Alcohol/Drug/Firearms Violations]","imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":611,"qid":82004,"description":"If someone with an intermediate license gets in another traffic accident, their license could be taken away for up to:","explanation":"If an intermediate license holder commits a second traffic violation or a second violation of the license's restrictions, the license will be suspended for six months or until the driver reaches the age of 18, whichever comes first. [Obtaining Your Driver's License; Washington Driver Guide]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":1078,"qid":82004,"description":"If someone with an intermediate license gets in another traffic accident, their license could be taken away for up to:","explanation":"If an intermediate license holder commits a second traffic violation or a second violation of the license's restrictions, the license will be suspended for six months or until the driver reaches the age of 18, whichever comes first. [Obtaining Your Driver's License; Washington Driver Guide]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#23883#23886","text":"6 months#1 year#10 days#30 days"},
{"testId":672,"qid":82005,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Lane usage control signals are unique overhead signals that indicate which lanes of a highway may be utilized in various directions at different times. A flashing yellow \"X\" signifies that this lane is solely for turning. A steady yellow \"X\" indicates that the lane in your direction is going to shut, at which point the signal will change to a steady red \"X.\" [Reversible Lanes, Road Rules, Washington Driver Guide]","imageName":"202003051707075416.jpg","correctAnswer":34510,"isBookmarked":0,"aid":"28148#28174#34510#38402","text":"Proceed with caution#This lane is closed#This lane is only for making turns#You must move out of this lane as soon as possible"},
{"testId":672,"qid":82006,"description":"You want to proceed straight through this roundabout. What lane should you take?","explanation":"Always obey traffic signs and pavement markings while driving through a two-lane roundabout or any other sort of junction. In general, turn left from the left lane, right from the right lane, and drive straight through from any lane. Before entering or exiting a roundabout or changing lanes inside one, always look for other cars. [Roundabouts, Traffic Rules, Washington Driver Guide]","imageName":"202004281857224780.jpg","correctAnswer":41462,"isBookmarked":0,"aid":"41310#41311#41312#41462","text":"You cannot go straight here#Use the left lane#Use the right lane#Use either the left or the right lane"},
{"testId":612,"qid":82015,"description":"For a first offense, an adult motorist convicted of DUI with a BAC less than 0.15 percent faces up to ______ in jail.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1453#11317#18239","text":"6 months#3 months#1 month#14 days"},
{"testId":1079,"qid":82015,"description":"For a first offense, an adult motorist convicted of DUI with a BAC less than 0.15 percent faces up to ______ in jail.","explanation":null,"imageName":"","correctAnswer":1272,"isBookmarked":0,"aid":"1272#1453#11317#18239","text":"6 months#3 months#1 month#14 days"},
{"testId":612,"qid":82016,"description":"For a first offense, an adult motorist who is found guilty of DUI and has a blood alcohol content (BAC) of under 0.15% will have their motorist license revoked for at least:","explanation":null,"imageName":"","correctAnswer":740,"isBookmarked":0,"aid":"740#742#1020#11553","text":"15 days#30 days#60 days#45 days"},
{"testId":1079,"qid":82016,"description":"For a first offense, an adult motorist who is found guilty of DUI and has a blood alcohol content (BAC) of under 0.15% will have their motorist license revoked for at least:","explanation":null,"imageName":"","correctAnswer":740,"isBookmarked":0,"aid":"740#742#1020#11553","text":"15 days#30 days#60 days#45 days"},
{"testId":612,"qid":82017,"description":"If you're convicted of DUI with a minor in the car, your license will be suspended for at least:","explanation":null,"imageName":"","correctAnswer":24048,"isBookmarked":0,"aid":"23884#24048#24049#27364","text":"15 days#60 days#90 days#45 days"},
{"testId":612,"qid":82018,"description":"For a first offense, an adult motorist convicted of DUI with a BAC of 0.15 percent or more faces a fine of up to:","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11668#11986","text":"$500#$1,000#$1,500#$750"},
{"testId":1079,"qid":82018,"description":"For a first offense, an adult motorist convicted of DUI with a BAC of 0.15 percent or more faces a fine of up to:","explanation":null,"imageName":"","correctAnswer":1799,"isBookmarked":0,"aid":"290#1799#11668#11986","text":"$500#$1,000#$1,500#$750"},
{"testId":612,"qid":82019,"description":"Adult drivers whose BAC is 0.15 percent or more are subject to an automatic license suspension for a period of ______ upon a first conviction for DUI.","explanation":null,"imageName":"","correctAnswer":11553,"isBookmarked":0,"aid":"740#742#1020#11553","text":"15 days#30 days#60 days#45 days"},
{"testId":1079,"qid":82019,"description":"Adult drivers whose BAC is 0.15 percent or more are subject to an automatic license suspension for a period of ______ upon a first conviction for DUI.","explanation":null,"imageName":"","correctAnswer":11553,"isBookmarked":0,"aid":"740#742#1020#11553","text":"15 days#30 days#60 days#45 days"},
{"testId":612,"qid":82020,"description":"DUI resulting in death is now always a felony, as of June 2015. If you are guilty of this offence, you will be sentenced to jail for at least ______ for a first offense, according to the West Virginia Driver's Licensing Handbook.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":1079,"qid":82020,"description":"DUI resulting in death is now always a felony, as of June 2015. If you are guilty of this offence, you will be sentenced to jail for at least ______ for a first offense, according to the West Virginia Driver's Licensing Handbook.","explanation":null,"imageName":"","correctAnswer":11285,"isBookmarked":0,"aid":"1021#11285#11306#11432","text":"1 year#2 years#3 years#4 years"},
{"testId":612,"qid":82021,"description":"A second DUI conviction for an adult driver may result in a fine of up to:","explanation":null,"imageName":"","correctAnswer":12001,"isBookmarked":0,"aid":"1799#3195#11669#12001","text":"$1,000#$5,000#$2,000#$3,000"},
{"testId":1079,"qid":82021,"description":"A second DUI conviction for an adult driver may result in a fine of up to:","explanation":null,"imageName":"","correctAnswer":12001,"isBookmarked":0,"aid":"1799#3195#11669#12001","text":"$1,000#$5,000#$2,000#$3,000"},
{"testId":612,"qid":82022,"description":"An adult driver convicted of DUI on a third or subsequent violation faces up to _______ in jail.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":1079,"qid":82022,"description":"An adult driver convicted of DUI on a third or subsequent violation faces up to _______ in jail.","explanation":null,"imageName":"","correctAnswer":11306,"isBookmarked":0,"aid":"11285#11306#11341#11432","text":"2 years#3 years#5 years#4 years"},
{"testId":673,"qid":82023,"description":"Is it legal in West Virginia to turn left at a red light?","explanation":"When moving from one one-way street to another one-way street in West Virginia, you may turn left on a red light unless there is a \"No Turn on Red\" sign displayed. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Stay Circular Red / STOP, Traffic Lights, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":673,"qid":82024,"description":"You see a lane with a double broken yellow line on both sides. What kind of a lane is this?","explanation":"This is a lane that can be used both ways. A reversible lane transports traffic in one direction at times and the other way at others. Typically, reversible lanes are utilized to improve capacity during morning and evening peak hours. Keep an eye out for signage or overhead lane usage signals that indicate if the reversible lane is available in your direction. It should be noted that this is the only situation in which you may drive for any length of time to the left of a yellow line. [Reversible Lanes, Pavement Markings, State of West Virginia Driver's Licensing Handbook, Chapter V: Traffic Control Devices]","imageName":"202003202113431892.jpg","correctAnswer":16098,"isBookmarked":0,"aid":"16098#28541#41588#41589","text":"A reversible lane#A high-occupancy vehicle (HOV) lane#A center left-turn lane#A passing lane"},
{"testId":613,"qid":82027,"description":"The DMV will consider you a habitual offender if you are convicted of ___ or more significant traffic offences within a five-year period.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1080,"qid":82027,"description":"The DMV will consider you a habitual offender if you are convicted of ___ or more significant traffic offences within a five-year period.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":6390,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":82028,"description":"If you are declared a persistent offender by the DMV, your license will be suspended for ___ years.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1080,"qid":82028,"description":"If you are declared a persistent offender by the DMV, your license will be suspended for ___ years.","explanation":"The DMV will consider you a chronic offender if during a five-year period, you have been convicted of 4 or more major traffic infractions, 12 or more minor traffic violations, or any combination of major and minor offenses totalling 12. Major infractions include major crimes such as careless driving, operating a motor vehicle while intoxicated (OWI), abandoning the scene of an accident (hit and run), and escaping a police officer. Once you're branded a habitual offender, your license will be revoked for five years in addition to the penalty for all the individual violations. [Habitual Offender; Keeping the Driver License; Wisconsin Department of Transportation Motorists' Handbook], [\"Wisconsin's Habitual Traffic Offender (HTO) Law\" (BDS 102); Wisconsin Department of Transportation; https://wisconsindot.gov/Documents/dmv/shared/bds102.pdf] .pdf]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":82029,"description":"If you are declared a persistent offender by the DMV, you will be ineligible for an occupational license for ___ years.","explanation":"If you are declared a persistent offender by the DMV, you may be eligible to apply for an occupational license after two years. An occupational license allows you to drive for a maximum of 12 hours per day and 60 hours per week. You are only permitted to drive to and from job, school, or church, or for essential domestic necessities such as obtaining food or prescriptions or attending medical appointments. [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx], [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx ]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":1080,"qid":82029,"description":"If you are declared a persistent offender by the DMV, you will be ineligible for an occupational license for ___ years.","explanation":"If you are declared a persistent offender by the DMV, you may be eligible to apply for an occupational license after two years. An occupational license allows you to drive for a maximum of 12 hours per day and 60 hours per week. You are only permitted to drive to and from job, school, or church, or for essential domestic necessities such as obtaining food or prescriptions or attending medical appointments. [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx], [\"Occupational License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/occ-license.aspx ]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5449#6388#6389#6390","text":"5#2#3#4"},
{"testId":613,"qid":82030,"description":"To recover your driving privileges after your license has been suspended or revoked, Wisconsin may ask you to present proof of financial responsibility for:","explanation":"Wisconsin may require you to present evidence of financial responsibility for three years following the date of restoration in order to resume your driving privileges if your license has been suspended or revoked. A car liability insurance policy or other methods of paying for damages and injuries in the case of an accident may serve as proof of financial responsibility. [\"Reinstate a Revoked or Suspended Driver License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/reinstate.aspx], [\"Reinstate a Revoked or Suspended Driver License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/ ]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":1080,"qid":82030,"description":"To recover your driving privileges after your license has been suspended or revoked, Wisconsin may ask you to present proof of financial responsibility for:","explanation":"Wisconsin may require you to present evidence of financial responsibility for three years following the date of restoration in order to resume your driving privileges if your license has been suspended or revoked. A car liability insurance policy or other methods of paying for damages and injuries in the case of an accident may serve as proof of financial responsibility. [\"Reinstate a Revoked or Suspended Driver License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/license-drvs/susp-or-rvkd/reinstate.aspx], [\"Reinstate a Revoked or Suspended Driver License\"; Wisconsin Department of Transportation; https://wisconsindot.gov/Pages/dmv/ ]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#23153#23154#23183","text":"2 years#6 months#1 year#3 years"},
{"testId":613,"qid":82031,"description":"You must report any accident that causes _____ or more in damage to government property other than a vehicle.","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":1080,"qid":82031,"description":"You must report any accident that causes _____ or more in damage to government property other than a vehicle.","explanation":"You must notify law enforcement if an accident results in death, injury, at least $1,000 in property damage, or at least $200 in damage to government property other than a vehicle. [Report the Crash; Crashes; Motorists' Handbook of the Wisconsin Department of Transportation]","imageName":"","correctAnswer":12358,"isBookmarked":0,"aid":"290#1799#11669#12358","text":"$500#$1,000#$2,000#$200"},
{"testId":613,"qid":82032,"description":"Studies have shown that being awake for 18 hours can make you as dangerous to drive as if you had___(AC).","explanation":"Driver weariness is a major cause of car accidents. According to studies, staying up for 18 hours might impair your driving as much as having an alcohol content (AC) of 0.05. This indicates that if you've been up for 18 hours, you're eight times more likely to get in an accident than if you've had adequate sleep. [Never Drive If Tired; Fatigue; Wisconsin Department of Transportation Motorists' Handbook]","imageName":"","correctAnswer":2244,"isBookmarked":0,"aid":"2243#2244#2245#5401","text":"0.08#0.05#0.02#0.04"},
{"testId":674,"qid":82033,"description":"In Wisconsin, a driver under the age of 21 who has a blood alcohol level more than _____ can be arrested.","explanation":"In Wisconsin, unless a notice preventing the turn is placed, you may turn left on a red light while going from one one-way street into another one-way street. Before turning, you must still come to a full stop and yield to all pedestrians and traffic. Some states have varied regulations regarding turning left on red. Always verify the local driving regulations while going out of state. [Wisconsin Department of Transportation Motorists' Handbook, Traffic Signals]","imageName":"202003112014364009.jpg","correctAnswer":38669,"isBookmarked":0,"aid":"18118#35269#38668#38669","text":"No#Yes, unless it's prohibited by a sign#Yes, but only when you're turning onto a one-way street#Yes, but only when you're turning from a one-way street onto another one-way street"},
{"testId":674,"qid":82034,"description":"In Wisconsin, it is prohibited to park within _____ feet of a crosswalk.","explanation":"You may not park within 15 feet of a crosswalk or crossroads in Wisconsin. (Note: There are several more areas where you are not permitted to park.) Look for signs or pavement markings prohibiting or limiting parking.) [No Parking Zones, Parking, Motorists' Handbook, Wisconsin Department of Transportation]","imageName":"202002181141488961.jpg","correctAnswer":8416,"isBookmarked":0,"aid":"1027#1028#1591#8416","text":"25 feet#10 feet#5 feet#15 feet"},
{"testId":674,"qid":82035,"description":"Above your lane, you notice a flashing yellow \"X.\" What does it imply?","explanation":"Reversible lanes are intended to transport traffic in one direction at times and in the opposite direction at others. Lane usage control signals are unique overhead signals that indicate which reversible lanes may be utilized in various directions at different times. A flashing yellow \"X\" signifies that this lane is solely for turning. A steady yellow \"X\" indicates that the lane in your direction is going to shut, at which point the signal will change to a steady red \"X.\" [Wisconsin Department of Transportation Motorists' Handbook, Reversible Lanes, Other Lane Controls]","imageName":"202003051707075416.jpg","correctAnswer":34510,"isBookmarked":0,"aid":"30427#31746#34510#43785","text":"This lane is about to close#This lane can be used with caution#This lane is only for making turns#This lane is only for car pooling"},
{"testId":614,"qid":82045,"description":"A DWUI offense is considered the second offense for a driver who is at least 21 years old, if it happens within____ years of its first offense.","explanation":"A DWUI offense is considered a second offense for a driver who is at least 21 years old if it happens within 10 years of the first violation. A second DWUI crime is also deemed a third offense if it happens within 10 years of the first. [Driving While Intoxicated; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":5446,"isBookmarked":0,"aid":"5446#5447#5449#6389","text":"10#15#5#3"},
{"testId":614,"qid":82046,"description":"Third or subsequent DWUI convictions result in 聽license suspension for:","explanation":"If you are convicted of a third or subsequent DWUI conviction, you will have your driving license revoked (not suspended) for three years. [Administrative Per Se Suspension; License Suspensions, Cancellations, and Revocations; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23183,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":614,"qid":82047,"description":"A DWUI offense is considered the second offense for a driver under the age of 21 if it happens within____ years of its first offense.","explanation":"A DWUI offense is deemed a second offense for a driver under the age of 21 if it happens within two years of the first violation. [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5446#5449#6388#6389","text":"10#5#2#3"},
{"testId":1081,"qid":82047,"description":"A DWUI offense is considered the second offense for a driver under the age of 21 if it happens within____ years of its first offense.","explanation":"A DWUI offense is deemed a second offense for a driver under the age of 21 if it happens within two years of the first violation. [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":6388,"isBookmarked":0,"aid":"5446#5449#6388#6389","text":"10#5#2#3"},
{"testId":614,"qid":82048,"description":"For how many months will a driver's license be suspended after a second or subsequent DWUI offense by any driver under the age of 21?","explanation":"A second or subsequent DWUI violation will result in a six-month license suspension for a motorist under the age of 21. [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":1081,"qid":82048,"description":"For how many months will a driver's license be suspended after a second or subsequent DWUI offense by any driver under the age of 21?","explanation":"A second or subsequent DWUI violation will result in a six-month license suspension for a motorist under the age of 21. [Youthful Driver Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"20788#23153#23154#23886","text":"2 years#6 months#1 year#30 days"},
{"testId":614,"qid":82049,"description":"Every motor vehicle in Wyoming must be insured for a minimum of _______ in property damage.","explanation":null,"imageName":"","correctAnswer":11659,"isBookmarked":0,"aid":"3195#11658#11659#12048","text":"$5,000#$10,000#$20,000#$25,000"},
{"testId":614,"qid":82050,"description":"If a reckless driving offense happens within ___ years of the first offense, it is deemed a second offense.","explanation":"If the reckless driving violation happens within five years of the first offense, it is deemed a second offense. [Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5448#5449#6389","text":"10#20#5#3"},
{"testId":1081,"qid":82050,"description":"If a reckless driving offense happens within ___ years of the first offense, it is deemed a second offense.","explanation":"If the reckless driving violation happens within five years of the first offense, it is deemed a second offense. [Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":5449,"isBookmarked":0,"aid":"5446#5448#5449#6389","text":"10#20#5#3"},
{"testId":614,"qid":82051,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the second time.","explanation":"A second reckless driving conviction within five years will result in a six-month license suspension. [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":1081,"qid":82051,"description":"Your driver's license will be revoked for______聽if you are found guilty of driving recklessly for the second time.","explanation":"A second reckless driving conviction within five years will result in a six-month license suspension. [Reckless Driving Suspension; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":23153,"isBookmarked":0,"aid":"23153#23154#24049#25857","text":"6 months#1 year#90 days#120 days"},
{"testId":614,"qid":82052,"description":"If a driver less than 21 years old is convicted of driving while his or her license is suspended or revoked, the license will be suspended for an additional ___ days.","explanation":"If a motorist under the age of 21 is convicted of driving while his or her license is suspended or revoked, the license will be suspended for an additional 30 days. [Driving While Suspended, Revoked, or Cancelled; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":1081,"qid":82052,"description":"If a driver less than 21 years old is convicted of driving while his or her license is suspended or revoked, the license will be suspended for an additional ___ days.","explanation":"If a motorist under the age of 21 is convicted of driving while his or her license is suspended or revoked, the license will be suspended for an additional 30 days. [Driving While Suspended, Revoked, or Cancelled; Other Types of Suspension; Loss of Driving Privilege; Wyoming Rules of the Road]","imageName":"","correctAnswer":6477,"isBookmarked":0,"aid":"5446#6355#6477#8141","text":"10#90#30#60"},
{"testId":614,"qid":82053,"description":"Third-time DWUI offenders must install an ignition interlock device for:","explanation":"If you are convicted of DWUI for the third time, you must install an ignition interlock device (IID) for a period of two years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":1081,"qid":82053,"description":"Third-time DWUI offenders must install an ignition interlock device for:","explanation":"If you are convicted of DWUI for the third time, you must install an ignition interlock device (IID) for a period of two years. If your blood alcohol content (BAC) exceeds a certain level, the IID will prohibit your car from starting. [Ignition Interlock Devices; Other Types of Suspension; Loss of Driving Privilege; Wyoming Road Rules]","imageName":"","correctAnswer":20788,"isBookmarked":0,"aid":"20788#20790#23183#26672","text":"2 years#4 years#3 years#5 years"},
{"testId":675,"qid":82054,"description":"You must ______ as you approach a steady yellow traffic signal.","explanation":"A persistent yellow traffic light indicates that the signal will shortly turn red. If it is safe to do so, stop. However, do not come to an abrupt halt; always consider the cars behind you before braking. If you cannot safely stop, drive through the junction with care. [Amber Lights, Traffic Signals, Definitions, Wyoming Road Rules]","imageName":"202003051752141237.jpg","correctAnswer":30666,"isBookmarked":0,"aid":"8214#30666#30667#35259","text":"proceed with caution#stop if it is safe to do so#speed up to get through the signal before it turns red#yield and then proceed if it is safe to do so"},
];
export default QUESTIONS;