import QUESTIONS from "../json-data/questions";
import TESTS from "../json-data/tests";
// export async function getQuestions(test) {
//     return new Promise(resolve => {
//       db.transaction(tx => {
//         tx.executeSql(
//           'select q.id qid,description,explanation,imageName,correctAnswer,isBookmarked,a.id aid,a.text from question q,test_question tq,answer a,question_answer qa, question_alias qalias where tq.t_id=? and qa.q_id=q.id and a.id=qa.a_id and tq.q_id = qalias.q_id and qalias.c_id= q.id order by q.id,a.id',
//           [test.id],
//           (tx1, results) => {
//             var questions = results.rows.raw();
//             resolve(questions);
//           },
//         );
//       });
//     });
//   }

  export async function getQuestions(test) {
  const questions = QUESTIONS.filter(ques => ques.testId === test.testId);
   return questions;
  }
  
  export async function getQuestionsOfMockTest(test,id) {
    let testList = [];
    // const questions = [];
    if(id === 1001){
     // console.log(`get questions 1001 ${test.numberOfQuestions}`)
      testList = TESTS.filter(particularTest => {
        if(particularTest.stateId === test.stateId && (particularTest.type === 10 || particularTest.type === 11 || particularTest.type === 12)){
          return particularTest;
        }
      });
      //console.log(`TestList 1001 ${JSON.stringify(testList)}`)
    }else if(id === 2001){
    // console.log(`get questions 2001`)
      testList = TESTS.filter(particularTest => {
        if(particularTest.stateId === test.stateId && (particularTest.type === 30 || particularTest.type === 31 || particularTest.type === 32)){
          return particularTest;
        }
      });
    }
   // console.log(`testlist length ${testList.length}`)
    let questions = [];
    for(let testIndex = 0; testIndex < testList.length; testIndex++){
    // console.log(`AAA - Test ${JSON.stringify(testList[testIndex].testId)}`)
      const tmpQuestions = QUESTIONS.filter(ques => ques.testId === testList[testIndex]["testId"]);
      questions = [...questions ,...tmpQuestions];
    }
   // console.log(`Questions ${JSON.stringify(questions)}`)
   // Shuffle array
  const shuffledQuestions = questions.sort(() => 0.5 - Math.random());

// Get sub-array of first n elements after shuffled
  let selectedQuestions = shuffledQuestions.slice(0, test.numberOfQuestions);
  //console.log(`Selected Questions ${JSON.stringify(selectedQuestions)}`)
    return selectedQuestions;

   }
   
  