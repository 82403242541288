import {
    AppStoreButton,
    GooglePlayButton,
    ButtonsContainer,
  } from "react-mobile-app-button";
  
  const StoreLinks = (props) => {
    const IOSUrl = props.stateData.ios;
    const AndroidURL = props.stateData.android;

    return (
      // <ButtonsContainer>
      //   <AppStoreButton
      //     url={IOSUrl}
      //   theme={"dark"}
      //     className={"custom-style"}
      //   />
      //   <GooglePlayButton
      //     url={AndroidURL}
      //     theme={"dark"}
      //     className={"custom-style"}
      //     width={GooglePlayButton}
      //   />
      // <ButtonsContainer>
      <div className="container">
      <ButtonsContainer>
      <div >
        <AppStoreButton
          url={IOSUrl}
        theme={"dark"}
          className={"custom-style"}
        />
        <div style={{paddingBottom:'10px'}}></div>
        <GooglePlayButton
          url={AndroidURL}
          theme={"dark"}
          className={"custom-style"}
          width={GooglePlayButton}
        />
        </div>
        </ButtonsContainer>
        </div>
    );
  };

  export default StoreLinks;