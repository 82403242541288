import React, { useEffect, useState } from "react";

import STATES from "../../json-data/state";
import CategoryList from "../CategoryList/CategoryList";
// import TestsList from "../TestLists/TestsList";
// import Quiz from "../Quiz.jsx";

import "./MainPage.css"; // Create a CSS file for styling

function MainPage(props) {
  const [currentComponents, SetCurrentCompoents] = useState(1);
  const [isState,setIsState] = useState(props?.isState);
  let isShowState = props?.isState;
  const onClickSetComponent = (props) => {
   
    if (props.component === "subject") {
      SetCurrentCompoents(2);
    }else if (props.component === "category") {
      SetCurrentCompoents(3);
    } else if (props.component === "test") {
      SetCurrentCompoents(4);
    }
    else if (props.component === "quiz") {
      SetCurrentCompoents(5);
    }
    setIsState(false)
  };

  useEffect(()=>{
    setIsState((prev)=>prev=true)
  },[]);
  
  return (
    <>
      {isState && (
        <div className='disable-copy-element-text'>
        <div><h1 className="font-weight-light">State</h1></div>
          <div className="main-page">
            {STATES.map((state) => (
              <button
                key={state.id}
                onClick={() =>
                  onClickSetComponent({ component: "subject", id: state.id })
                }
              >
                {state.name}
              </button>
            ))}
          </div>
        </div>
      )}
      {currentComponents === 2 && (
      <CategoryList onSelectCategory={onClickSetComponent} />
      )}
      {currentComponents === 3 && 
      (
        <div>Test</div>
      )}
      {currentComponents === 4 && 
      (
        <div>Quiz</div>
      )}
    </>
  );
}

export default MainPage;
