import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import QRCode from "react-qr-code";
import { isMobile, MobileView, BrowserView } from "react-device-detect";
import STATES from "../json-data/state";
const StroeLinksWithQRCodes = (props) => {
  const IOSUrl = props.stateData.ios;
  const AndroidURL = props.stateData.android;

  return (
    <div className="alignCenterDiv">
      <BrowserView>
        <div className="row">
          <div>
            <h3>Download the {props.stateData.name} {STATES[props.stateData.id-1].governmentAgency} Practice Test app to access all of its features</h3>
            <div class="container">
              <div class="row">
                <div class="col-sm">
                  <ul>
                    <li>
                      <h6>Extensive Question Bank</h6>
                    </li>
                    <li>
                      <h6>Flexibility During Tests</h6>
                    </li>
                    <li>
                      <h6>Study Guide and Practice tests</h6>
                    </li>
                    <li>
                      <h6>Road Sign Recognition</h6>
                    </li>
                    <li>
                      <h6>Bookmark questions</h6>
                    </li>
                    <li>
                      <h6>Resume and Restart the test</h6>
                    </li>
                  </ul>
                </div>
                <div class="col-sm">
                  <ul>
                    <li>
                      <h6>Progress Tracking</h6>
                    </li>
                    <li>
                      <h6>List of Weak/Wrong Questions for Improvement</h6>
                    </li>
                    <li>
                      <h6>Review Previous tests</h6>
                    </li>
                    <li>
                      <h6>Reset All Data</h6>
                    </li>
                    <li>
                      <h6>Appearance Settings (Auto,Light,Dark)</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div
              className="card text-center align-items-center"
              style={{ width: "18rem" }}
            >
              <div className="card-body">
                <h5 className="card-title">GET THE IOS APP</h5>

                <AppStoreButton
                  url={IOSUrl}
                  theme={"dark"}
                  className={"custom-style"}
                />
                <div style={{ height: "10px" }}></div>
                <h3 className="card-text">OR</h3>

                <h5 className="card-title">Scan QR Code</h5>
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 100,
                    width: "100%",
                  }}
                >
                  <QRCode
                    title={"IOS"}
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={IOSUrl}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div
              className="card text-center align-items-center"
              style={{ width: "18rem" }}
            >
              <div className="card-body">
                <h5 className="card-title">GET THE ANDROID APP</h5>

                <GooglePlayButton
                  url={AndroidURL}
                  theme={"dark"}
                  className={"custom-style"}
                  width={GooglePlayButton}
                />
                <div style={{ height: "10px" }}></div>
                <h3 className="card-text">OR</h3>

                <h5 className="card-title">Scan QR Code</h5>
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 100,
                    width: "100%",
                  }}
                >
                  <QRCode
                    title={"Android"}
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={AndroidURL}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        {/* <div className="row alignCenterDiv"> */}
        <div className="container">
          <div
            className="card text-center align-items-center"
            style={{ width: "18rem" }}
          >
            <div className="card-body">
              <h5 className="card-title">GET THE IOS APP</h5>

              <AppStoreButton
                url={IOSUrl}
                theme={"dark"}
                className={"custom-style"}
              />
              <div style={{ height: "10px" }}></div>
              <h3 className="card-text">OR</h3>

              <h5 className="card-title">Scan QR Code</h5>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 100,
                  width: "100%",
                }}
              >
                <QRCode
                  title={"IOS"}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={IOSUrl}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div
            className="card text-center align-items-center"
            style={{ width: "18rem" }}
          >
            <div className="card-body">
              <h5 className="card-title">GET THE ANDROID APP</h5>

              <GooglePlayButton
                url={AndroidURL}
                theme={"dark"}
                className={"custom-style"}
                width={GooglePlayButton}
              />
              <div style={{ height: "10px" }}></div>
              <h3 className="card-text">OR</h3>

              <h5 className="card-title">Scan QR Code</h5>
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 100,
                  width: "100%",
                }}
              >
                <QRCode
                  title={"Android"}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={AndroidURL}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            style={{ width: "18rem" , marginTop:'15px'}}
          >
            <div>
              <h5>Download the app to access all of its features.</h5>
              <ul>
                <li>
                  <h6>Extensive Question Bank</h6>
                </li>
                <li>
                  <h6>Flexibility During Tests</h6>
                </li>
                <li>
                  <h6>Study Guide and Practice tests</h6>
                </li>
                <li>
                  <h6>Road Sign Recognition</h6>
                </li>
                <li>
                  <h6>Bookmark questions</h6>
                </li>
                <li>
                  <h6>Resume and Restart the test</h6>
                </li>
                <li>
                  <h6>Progress Tracking</h6>
                </li>
                <li>
                  <h6>List of Weak/Wrong Questions for Improvement</h6>
                </li>
                <li>
                  <h6>Review Previous tests</h6>
                </li>
                <li>
                  <h6>Reset All Data</h6>
                </li>
                <li>
                  <h6>Appearance Settings (Auto,Light,Dark)</h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* </div> */}
      </MobileView>
    </div>
  );
};

export default StroeLinksWithQRCodes;

/*
return (
    <div className="col">
      <div>
        <AppStoreButton
          url={IOSUrl}
          theme={"dark"}
          className={"custom-style"}
        />
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 100,
            width: "100%",
          }}
        >
          <QRCode
            title={"IOS"}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={IOSUrl}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <div>
        <div
          style={{
            height: "auto",
            margin: "0 auto",
            maxWidth: 100,
            width: "100%",
          }}
        >
          <QRCode
            title={"Android"}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={AndroidURL}
            viewBox={`0 0 256 256`}
          />
        </div>
        <GooglePlayButton
          url={AndroidURL}
          theme={"dark"}
          className={"custom-style"}
          width={GooglePlayButton}
        />
      </div>
      <table></table>
    </div>
  );

  */
