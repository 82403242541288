import React, { useEffect, useState } from "react";
import { images } from "../../constants/images";
import "./CategoryList.css";
import Fade from "react-reveal/Fade";
import STATES from "../../json-data/state";

function TestTypeList({ onSelectTestType, stateId, vehicleCategory, selectedTestType }) {
  const [homeList, setHomeList] = useState([]);
  const [typeKey, setTypeKey] = useState(selectedTestType?.id ? selectedTestType?.id : "");
  const prepareHomeList = async (stateId, vehicleCategory) => {
    try {
      let tmpHomeList = [];
      setHomeList(tmpHomeList);
      const listVehicleCategoryCar = [
        {
          id: 1001,
          colors: "#FF8552",
          categoryName: "Exam Simulator",
          endorsementCode: "",
          categoryType: 14,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "testIcon",
        },
        {
          id: 1002,
          colors: "#FF8552",
          categoryName: "Practice Tests",
          endorsementCode: "",
          categoryType: 10,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "practiceIcon",
        },
        {
          id: 1003,
          colors: "#FF8552",
          categoryName: "Road Signs",
          endorsementCode: "",
          categoryType: 11,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "roadIcon",
        },
        {
          id: 1004,
          colors: "#FF8552",
          categoryName: "Fine & Limits",
          endorsementCode: "",
          categoryType: 12,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "limitIcon",
        },
      ];

      const listVehicleCategoryMoto = [
        {
          id: 2001,
          colors: "#FF8552",
          categoryName: "Exam Simulator",
          endorsementCode: "",
          categoryType: 34,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "testIcon",
        },
        {
          id: 2002,
          colors: "#FF8552",
          categoryName: "Practice Test 1",
          endorsementCode: "",
          categoryType: 30,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "practiceIcon",
        },
        {
          id: 2003,
          colors: "#FF8552",
          categoryName: "Practice Test 2",
          endorsementCode: "",
          categoryType: 31,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "testIcon2",
        },
        {
          id: 2004,
          colors: "#FF8552",
          categoryName: "Fine & Limits",
          endorsementCode: "",
          categoryType: 32,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "limitIcon",
        },
      ];

      const listVehicleCategoryCDL = [
        {
          id: 3001,
          colors: "#FF8552",
          categoryName: "General Knowledge",
          endorsementCode: "GK",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "gkIcon",
        },
        {
          id: 3002,
          colors: "#FF8552",
          categoryName: "Hazmat",
          endorsementCode: "HM",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "hazmatIcon",
        },
        {
          id: 3003,
          colors: "#FF8552",
          categoryName: "School Bus",
          endorsementCode: "SB",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "busIcon",
        },
        {
          id: 3004,
          colors: "#FF8552",
          categoryName: "Passenger Vehicles",
          endorsementCode: "PV",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "vehicleIcon",
        },
        {
          id: 3005,
          colors: "#FF8552",
          categoryName: "Air Brakes",
          endorsementCode: "AB",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "brakesIcon",
        },
        {
          id: 3006,
          colors: "#FF8552",
          categoryName: "Double/Triples",
          endorsementCode: "DT",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "triplesIcon",
        },
        {
          id: 3007,
          colors: "#FF8552",
          categoryName: "Combination Vehicle",
          endorsementCode: "CV",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "combinationIcon",
        },
        {
          id: 3008,
          colors: "#FF8552",
          categoryName: "Tankers",
          endorsementCode: "TV",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "tankerIcon",
        },
        {
          id: 3009,
          colors: "#FF8552",
          categoryName: "Pre-Trip",
          endorsementCode: "PT",
          categoryType: 0,
          vehicleCategoryId: vehicleCategory.id,
          imageIconName: "pretripIcon",
        },
      ];

      switch (vehicleCategory.id) {
        case 1:
          tmpHomeList.push(...listVehicleCategoryCar);
          break;
        case 2:
          tmpHomeList.push(...listVehicleCategoryMoto);
          break;
        case 3:
          tmpHomeList.push(...listVehicleCategoryCDL);
          break;

        default:
          break;
      }
      setHomeList(tmpHomeList);
    } catch (error) {
      console.log(error);
    }
  };

  const onCickTestType = ({ testType }) => {
    //  onSelectTestType({currentPage:'testlist', testType})
    setTypeKey(testType.id);
    onSelectTestType({ testType });
  };

  const initialize = async () => {
    await prepareHomeList(stateId, vehicleCategory);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="disable-copy-element-text">
      <div className=" text-center align-items-center"> 
          <h2>{STATES[stateId - 1].name} {STATES[stateId - 1].governmentAgency} {vehicleCategory.name} Test Type</h2></div>
      <h3>Select Test Type</h3>
      {/* <div
        className="category-type-scrolling-list-flexbox"
        style={{ marginBottom: "20px" }}
      >
        {homeList.map((testType) => {
          return (
            <Fade top>
              <button
                key={testType.id}
                onClick={() => onCickTestType({ testType })}
                className="card"
                style={{
                  backgroundColor: typeKey === testType.id ? "lightblue" : "",
                }}
              >
                <div style={{ justifyContent: "center" }}>
                  <div style={{ marginTop: "10px" }}>
                    <img
                      width={200}
                      height={145}
                      src={images[`${testType.imageIconName}.png`]}
                      //src={images[`car.png`]}
                      alt=""
                      style={{ padding: "10px" }}
                    />{" "}
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <h5>{testType.categoryName}</h5>
                  </div>
                </div>
              </button>
            </Fade>
          );
        })}
      </div> */}

      <div
            className="category-type-scrolling-list-flexbox"
            style={{ marginBottom: "20px", marginLeft:'40px', }}
          >
            {homeList.map((testType) => {
              return (
                <Fade top>
                <button
                key={testType.id}
                onClick={() => onCickTestType({ testType })}
                className="card"
                style={{
                  backgroundColor: typeKey === testType.id ? "lightblue" : "",
                }}
              >
                  <div style={{ justifyContent: "center" , width:'11.7rem', height:'12rem'}}>
                    <div>
                    <img
                      width={185}
                      height={145}
                      src={images[`${testType.imageIconName}.png`]}
                      //src={images[`car.png`]}
                      alt=""
                      style={{ padding: "15px" }}
                    />{" "}
                  </div>
                    <h4>{testType.categoryName}</h4>
                  </div>
                </button>
                </Fade>
              );
            })}
           
          </div>
      {/* <h1>Test Type</h1>
    <div className="common-list" >
      {homeList.map((testType) => {
        return(
        <button key={testType.id} onClick={() => onCickTestType({testType})}className="common-list-item">
          {testType.categoryName}
        </button>
      )})}
    </div> */}

      {/* <div className="card" style={{width: "18rem"}}>
  <div className="card-header">
    Featured
  </div>
  <ul className="list-group list-group-flush">
    {homeList.map((testType) => {
        return(
        <li key={testType.id} onClick={() => onCickTestType({testType})} className="list-group-item">
          {testType.categoryName}
        </li>
      )})}
  </ul>
</div> */}
    </div>
  );
}

export default TestTypeList;
