import React, { useEffect, useState } from "react";
import { images } from "../../constants/images";
import { Button } from "react-bootstrap";
import { CloseCircle, CheckmarkCircle, Ellipse } from "react-ionicons";
import Fade from "react-reveal/Fade";

export default function ReviewAnswers(props) {
  const [isLoading, setisLoading] = useState(true);
  const testDetail = props.completedTestData;
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

 

  const tabData = [
    { title: "All (" + testDetail.questions.length + ")" },
    {
      title:
        "Incorrect (" +
        (testDetail.questions.length - testDetail.corrects) +
        ")",
    },
    { title: "Correct (" + testDetail.corrects + ")" },
  ];
  useEffect(() => {
    //console.log(`props review ${JSON.stringify(testDetail.questions.length)}`);
    setQuestions(testDetail.questions);
    setAnswers(testDetail.answers);
    setisLoading(false);
    return () => {};
  }, []);

  function Item(props) {
   // console.log(`item index ${props.index}`);
    return <li>{renderQuestion({ item: props.value, index: props.index })}</li>;
  }

  const callLoadQuestion = ({ questions, index }) => {
    //console.log(`callLoadQuestion ${questions.length}`)

    // for(let qIndex=0; qIndex< questions.length; qIndex++){
    //   console.log(`Qindex : ${qIndex}`)
    //   renderQuestion({item:questions[qIndex],index:qIndex})
    // }

    renderQuestion({ item: questions.qid, index });
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    filterQuestions(tabNumber-1);
  };

  const showEmptyListView = () => {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <h3>
          No questions found
        </h3>
      </div>
    );
  };

  const filterQuestions = (selectedButtonIndex) => {
    if (selectedButtonIndex === 0) {
      setisLoading(true);
      setQuestions(testDetail.questions);
      setAnswers(testDetail.answers);
      setisLoading(false);
    } else if (selectedButtonIndex === 1) {
      setisLoading(true);
      let tempq = [];
      let tempa = [];
      for (let i = 0; i < testDetail.questions.length; i++) {
        let userAnswer = testDetail.answers.find(
          (item) => item.qid === testDetail.questions[i].qid
        );
        // if (testDetail.questions[i].correctAnswer !== testDetail.answers[i]) {
        if (
          !userAnswer ||
          testDetail.questions[i].correctAnswer.toString() !== userAnswer?.uaid
        ) {
          tempq.push(testDetail.questions[i]);
          tempa.push(testDetail.answers[i]);
        }
      }
      setQuestions(tempq);
      setAnswers(tempa);
      setisLoading(false);
    } else if (selectedButtonIndex === 2) {
      setisLoading(true);
      let tempq = [];
      let tempa = [];
      for (let i = 0; i < testDetail.questions.length; i++) {
        let userAnswer = testDetail.answers.find(
          (item) => item.qid === testDetail.questions[i].qid
        );
        // if (testDetail.questions[i].correctAnswer === testDetail.answers[i]) {
        if (
          testDetail.questions[i].correctAnswer.toString() === userAnswer?.uaid
        ) {
          tempq.push(testDetail.questions[i]);
          tempa.push(testDetail.answers[i]);
        }
      }
      setQuestions(tempq);
      setAnswers(tempa);
      //console.log(`Questions ${JSON.stringify(tempq)}`)
      setisLoading(false);
    }
  };
  const renderQuestion = ({ item, index }) => {
    let userAnswer = testDetail.answers.find(
      (item) => item.qid === questions[index].qid
    );

    // if (!userAnswer) {
    //   return;
    // }

    let trueAnswerOption = questions[index].options.find(
      (item) => item.aid === questions[index].correctAnswer.toString()
    );
    let userAnswerOption = questions[index].options.find(
      (item) => item.aid === userAnswer?.uaid
    );
    return (
      <>
        <div>
          <div
            className="d-flex justify-content-between"
            style={{ marginLeft: "-20px" }}
          >
            <div>
              <h5>Question : {index + 1}</h5>
            </div>
            <div>
              {/* {item.correctAnswer !== answers[index] ? ( */}
              {userAnswer ? (
                item.correctAnswer.toString() !== userAnswer?.uaid ? (
                  <CloseCircle color={"red"} height="20px" width="20px" />
                ) : (
                  <CheckmarkCircle color={"green"} height="20px" width="20px" />
                )
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h5 style={{ color: "black" }}>Unattempted</h5>
                  <div style={{ width: "15px" }}></div>
                  <CloseCircle color={"red"} height="20px" width="20px" />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              paddingHorizontal: "15px",
              paddingVertical: "10px",
            }}
          >
            <h5>{item.description}</h5>
            {item.imageName != "" && item.imageName != null ? (
              <div className="text-center" style={{ marginBottom: "5px" }}>
                {/* <img
                      src={images[questions[index].imageName]}
                      alt=""
                      height={200}
                      width={'70%'}
                    /> */}
                {/* <Image src={images[questions[index].imageName]} rounded /> */}
                <img
                  src={images[questions[index].imageName]}
                  className="img-fluid"
                  alt=""
                  width={"70%"}
                ></img>
              </div>
            ) : null}
          </div>
          {/* {item.correctAnswer !== answers[index] ? ( */}
          {userAnswer && item.correctAnswer.toString() !== userAnswer?.uaid ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CloseCircle color={"red"} height="20px" width="20px" />
              <div style={{ width: "15px" }}></div>
              <h5>
                {/* {item[`a${answers[index]}`]} */}
                {`${userAnswerOption.text}`}
              </h5>
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "row" }}>
            {userAnswer ? (
              <CheckmarkCircle color={"green"} height="20px" width="20px" />
            ) : (
              <Ellipse color={"green"} height="20px" width="20px" />
            )}
            <div style={{ width: "15px" }}></div>
            <h5>
              {/* {item[`a${item.correctAnswer}`]} */}
              {`${trueAnswerOption?.text}`}
            </h5>
          </div>

          <div
            style={{
              flex: 1,
              backgroundColor: "gray",
              height: "1px",
              marginLeft: "-30px",
              marginRight: "0px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>
        </div>
      </>
    );
  };

  const onClickDone = () => {
    props.onClickDone();
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {/* <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div> */}
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="disable-copy-element-text">
      <div>
        <h4 className="text-center">Review Test</h4>
      </div>
      <div style={{height:"10px"}}></div>
    
      <div>
        {/* <TabSelectorAnimation
          onChangeTab={idTab => filterQuestions(idTab)}
          style={stylesTab.tabContainer}
          backgroundColor={'lightgray'Color}
          styleTab={stylesTab.tabSelector}
          tabs={tabData}
        /> */}
        {/* <FlatList
          data={questions}
          keyExtractor={item => item.qid.toString()}
          renderItem={renderQuestion}
          ListEmptyComponent={showEmptyListView}
        /> */}
        <div className="d-flex justify-content-center">
        {/* <div className="tabs-container"> */}
      {/* <div className="tab-buttons"> */}
     
        <button
          className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
          style={{borderRadius: '10px', width: "9rem"}}
        >
          {tabData[0].title}
        </button>
        <button
          className={`border-radius: 4px tab-button ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
          style={{borderRadius: '10px', width: "9rem"}}
        >
          {tabData[1].title}
        </button>
        <button
          className={`border-radius: 4px tab-button ${activeTab === 3 ? 'active' : ''}`}
          onClick={() => handleTabClick(3)}
          style={{borderRadius: '10px', width: "9rem"}}
        >
          {tabData[2].title}
        </button>
      
      {/* </div> */}

      {/* <div className="tab-content">
        {activeTab === 1 && <p>Content for Tab 1</p>}
        {activeTab === 2 && <p>Content for Tab 2</p>}
        {activeTab === 3 && <p>Content for Tab 3</p>}
      </div> */}
    {/* </div> */}
    </div>
    <div style={{height:"10px"}}></div>
        <ul style={{ listStyleType: "none" }}>
        <Fade>
          {/* {questions.map((item,index) => {return(renderQuestion({item,index}))})} */}
          <>
          {questions.length > 0 ? questions.map((item, index) => (
            <Item key={item.qid} value={item} index={index} />
          )) : showEmptyListView()}
          {/* {questions.map((item, index) => (
            <Item key={item.qid} value={item} index={index} />
          ))} */}
          </>
          </Fade>
          {/* {
        callLoadQuestion(questions)
        renderQuestion({item:questions.qid,index})
        RenderQuestion key={item.qid} item={item} />
      } */}
        </ul>
        <div
          className="d-flex justify-content-center"
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            width: "100%",
            marginLeft: "0%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className=" btn-lg btn-primary"
            style={{ width: "150px" }}
            onClick={() => onClickDone()}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}
