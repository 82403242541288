import React, { useState } from "react";
import StatePage from "./MainPage/StatePage";
import CategoryList from "./CategoryList/CategoryList";
import TestTypeList from "./CategoryList/TestTypeList";
import TestList from "./CategoryList/TestList";
import PracticeTest from "./CategoryList/PracticeTest";
import StroeLinksWithQRCodes from "./StroeLinksWithQRCodes";
import STATESAPPLinks from "../json-data/stateAppLinks";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CommonHeader from "./Header";
import PrivacyPolicy from "./PrivacyPolicy";
import Contact from "./Contact";

const Home = (props) => {
  const [page, setPage] = useState(props.page);
  const [state, setState] = useState();
  const [category, setCategory] = useState();
  const [testType, setTestType] = useState();
  const [test, setTest] = useState();

  const onSetCurrentPage = (props) => {
    if (props.currentPage === "state") {
    } else if (props.currentPage === "category") {
      // console.log(`on category page ${JSON.stringify(props)}`)
      setState(props.state);
    } else if (props.currentPage === "testtype") {
      // console.log(`on testType page ${JSON.stringify(props)}`)
      setCategory(props.category);
    } else if (props.currentPage === "testlist") {
      //  console.log(`on test list page ${JSON.stringify(props)}`)
      setTestType(props.testType);
    } else if (props.currentPage === "practicetest") {
      // console.log(`on practice test page ${JSON.stringify(props)}`);
      setCategory(props.category);
      setTestType(props.testType);
      setTest(props.test);
    } else if (props.currentPage === "downloadapp") {
      // console.log(`on practice test page ${JSON.stringify(props)}`);
      if (props?.test) {
        setCategory(props.category);
        setTestType(props.testType);
        setTest(props.test);
      } else {
        setState(props.state);
      }
    }
    setPage(props.currentPage);
  };

  return (
    <>
    {page !== 'practicetest' ? <CommonHeader /> : null}
    
      <div className="home disable-copy-element-text">
        {/* <div className="container"> */}
        <div className="">
          <div className="align-items-center my-0 py-3">
            {/* <CookieConsent location="bottom" buttonText="Okay" declineButtonText="Decline" cookieName="myAppCookieConsent" style={{ background: "#242424", color: "#FFF" }} buttonStyle={{ color: "#4e503b", fontSize: "14px" }} declineButtonStyle={{ fontSize: "14px" }} expires={150}>
       We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic.
      </CookieConsent> */}
            {page === "state" && <StatePage onSelectState={onSetCurrentPage} />}
            {page === "category" && (
              <CategoryList
                onSelectCategory={onSetCurrentPage}
                stateId={state.id}
              />
            )}
            {page === "testtype" && (
              <TestTypeList
                onSelectTestType={onSetCurrentPage}
                stateId={state.id}
                vehicleCategory={category}
              />
            )}
            {page === "testlist" && (
              <TestList
                onSelectTest={onSetCurrentPage}
                stateId={state.id}
                vehicleCategory={category}
                testType={testType}
              />
            )}
            {page === "practicetest" && (
              <div className="container">
              <PracticeTest
                onSelectTest={onSetCurrentPage}
                stateId={state.id}
                vehicleCategory={category}
                testType={testType}
                test={test}
              />
              </div>
            )}
            {page === "downloadapp" && (
              <StroeLinksWithQRCodes stateData={STATESAPPLinks[state.id - 1]} />
            )}
            {/* {page === "privacy" && <PrivacyPolicy />} */}
          </div>
          {/* <Button onClick={() => onSetCurrentPage({ currentPage: "state" })}>
          ON Click Reset to Home
        </Button> */}
          <div style={{marginBottom:'55px'}}></div>
        </div>
      </div>
    </>
  );
};

export default Home;

/*
return (
    <div className="home">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-5">
            <h1 className="font-weight-light">State</h1>
            <MainPage />
          </div>
        </div>
      </div>
    </div>
  );
  */

/*
  {page === "downloadapp" && (
            <div className="container justify-content-center" style={{width:'50%'}}>
            <StroeLinksWithQRCodes stateData={STATESAPPLinks[state.id - 1]} />
            </div>
          )}
          */
