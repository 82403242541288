import { Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { images } from "../../constants/images";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';


const Navigationbar = () => {
  return (
    <Navbar className="navbar navbar-expand-sm collapseOnSelect navbar-light bg-light disable-copy-element-text">
      <div className="container">
        {/* <NavLink className="navbar-brand" to="/">
      <div>
                  <img
                    width={40}
                    height={40}
                    src={images[`flagIcon.png`]}
                    alt=''
                  />{'  DMV Test '}
                </div>
      </NavLink> */}
        <a className="navbar-brand" href="/">
          {/* <div>
            <img width={40} height={40} src={images[`flagIcon.png`]} alt="" />
            {"  DMV Test "}
          </div> */}

          <div className="d-flex text-center align-items-center" style={{ marginLeft: "10px" }}>
            <img
              src={images[`flagIcon.png`]}
              className=""
              alt=""
              width={30}
              height={30}
            ></img>
            <div style={{ marginLeft: "10px" }}>DMV Test</div>
          </div>
        </a>      
      </div>
     
       <Navbar.Toggle
          aria-controls="navbarScroll"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
        />
        <Navbar.Collapse id="navbarScroll" className="navbar-right">
          <Nav>
            {/* <NavLink className="nav-link"  to="/" activeClassName="active">
              Home
            </NavLink> */}
            <li className="nav-item ">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <NavLink className="nav-link" to="/contact" activeClassName="active">
              Contact
            </NavLink>
            <NavLink className="nav-link" to="/privacy" activeClassName="active">
              Privacy
            </NavLink>
          </Nav>
        </Navbar.Collapse> 
        
       
      {/*
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span className="ti-menu"></span>
        </button>
        <div className="collapse navbar-collapse navbar-right" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            
            <li className="nav-item ">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li>   
            <li className="nav-item">
              <a className="nav-link" href="/privacy">
                Privacy
              </a>
            </li>       
          </ul>
        </div>
        */}
      
    </Navbar>
  );
};

export default Navigationbar;
/*
const Navigationbar = () => {
  return (
    <Navbar className="navbar navbar-expand-sm collapseOnSelect navbar-light bg-light" >
    <div className="container">
      <NavLink className="navbar-brand" to="/">
      <div>
                  <img
                    width={40}
                    height={40}
                    src={images[`flagIcon.png`]}
                    alt=''
                  />{'  DMV Test '}
                </div>
      </NavLink>
      <Navbar.Toggle
        aria-controls="navbarScroll"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
      />
      <Navbar.Collapse id="navbarScroll" className="navbar-right">
        <Nav>
          <NavLink className="nav-link" to="/" >
            Home
          </NavLink>
          <NavLink className="nav-link" to="/contact">
            Contact
          </NavLink>
        </Nav>
      </Navbar.Collapse>
      
      </div>
    </Navbar>
  );
};
*/

/*
const Navigationbar = () => {
  return (
    // <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" fixed="top">
    <nav className="navbar main-nav navbar-expand-lg px-2 px-sm-0 py-2 py-lg-0">
      <div className="container">
        <a className="navbar-brand" href="/">
          <div>
            <img width={40} height={40} src={images[`flagIcon.png`]} alt="" />
            {"  DMV Test "}
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span className="ti-menu"></span>
        </button>
        <div className="collapse navbar-collapse navbar-right" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item active">
              <a className="nav-link active" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
*/
