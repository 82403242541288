const STATES = [
         {"id":1,"name":"Alabama","shortName":"AL","governmentAgency":"DMV"},
         {"id":2,"name":"Alaska","shortName":"AK","governmentAgency":"DMV"},
         {"id":3,"name":"Arizona","shortName":"AZ","governmentAgency":"MVD"},
         {"id":4,"name":"Arkansas","shortName":"AR","governmentAgency":"DMV"},
         {"id":5,"name":"California","shortName":"CA","governmentAgency":"DMV"},
         {"id":6,"name":"Colorado","shortName":"CO","governmentAgency":"DMV"},
         {"id":7,"name":"Connecticut","shortName":"CT","governmentAgency":"DMV"},
         {"id":8,"name":"Delaware","shortName":"DE","governmentAgency":"DMV"},
         {"id":9,"name":"DC","shortName":"DC","governmentAgency":"DMV"},
         {"id":10,"name":"Florida","shortName":"FL","governmentAgency":"DMV"},
         {"id":11,"name":"Georgia","shortName":"GA","governmentAgency":"DDS"},
         {"id":12,"name":"Hawaii","shortName":"HI","governmentAgency":"DMV"},
         {"id":13,"name":"Idaho","shortName":"ID","governmentAgency":"DMV"},
         {"id":14,"name":"Illinois","shortName":"IL","governmentAgency":"DMV"},
         {"id":15,"name":"Indiana","shortName":"IN","governmentAgency":"BMV"},
         {"id":16,"name":"Iowa","shortName":"IA","governmentAgency":"DOT"},
         {"id":17,"name":"Kansas","shortName":"KS","governmentAgency":"DMV"},
         {"id":18,"name":"Kentucky","shortName":"KY","governmentAgency":"KSP"},
         {"id":19,"name":"Louisiana","shortName":"LA","governmentAgency":"OMV"},
         {"id":20,"name":"Maine","shortName":"ME","governmentAgency":"DMV"},
         {"id":21,"name":"Maryland","shortName":"MD","governmentAgency":"MVA"},
         {"id":22,"name":"Massachusetts","shortName":"MA","governmentAgency":"RMV"},
         {"id":23,"name":"Michigan","shortName":"MI","governmentAgency":"DOS"},
         {"id":24,"name":"Minnesota","shortName":"MN","governmentAgency":"DVS"},
         {"id":25,"name":"Mississippi","shortName":"MS","governmentAgency":"DPS"},
         {"id":26,"name":"Missouri","shortName":"MO","governmentAgency":"DOR"},
         {"id":27,"name":"Montana","shortName":"MT","governmentAgency":"MVD"},
         {"id":28,"name":"Nebraska","shortName":"NE","governmentAgency":"DMV"},
         {"id":29,"name":"Nevada","shortName":"NV","governmentAgency":"DMV"},
         {"id":30,"name":"New Hampshire","shortName":"NH","governmentAgency":"DMV"},
         {"id":31,"name":"New Jersey","shortName":"NJ","governmentAgency":"MVC"},
         {"id":32,"name":"New Mexico","shortName":"NM","governmentAgency":"MVD"},
         {"id":33,"name":"New York","shortName":"NY","governmentAgency":"DMV"},
         {"id":34,"name":"North Carolina","shortName":"NC","governmentAgency":"DMV"},
         {"id":35,"name":"North Dakota","shortName":"ND","governmentAgency":"DOT"},
         {"id":36,"name":"Ohio","shortName":"OH","governmentAgency":"BMV"},
         {"id":37,"name":"Oklahoma","shortName":"OK","governmentAgency":"DPS"},
         {"id":38,"name":"Oregon","shortName":"OR","governmentAgency":"DMV"},
         {"id":39,"name":"Pennsylvania","shortName":"PA","governmentAgency":"DOT"},
         {"id":40,"name":"Rhode Island","shortName":"RI","governmentAgency":"DMV"},
         {"id":41,"name":"South Carolina","shortName":"SC","governmentAgency":"DMV"},
         {"id":42,"name":"South Dakota","shortName":"SD","governmentAgency":"DMV"},
         {"id":43,"name":"Tennessee","shortName":"TN","governmentAgency":"DMV"},
         {"id":44,"name":"Texas","shortName":"TX","governmentAgency":"DMV"},
         {"id":45,"name":"Utah","shortName":"UT","governmentAgency":"DLD"},
         {"id":46,"name":"Vermont","shortName":"VT","governmentAgency":"DMV"},
         {"id":47,"name":"Virginia","shortName":"VA","governmentAgency":"DMV"},
         {"id":48,"name":"Washington","shortName":"WA","governmentAgency":"DOL"},
         {"id":49,"name":"West Virginia","shortName":"WV","governmentAgency":"DMV"},
         {"id":50,"name":"Wisconsin","shortName":"WI","governmentAgency":"DMV"},
         {"id":51,"name":"Wyoming","shortName":"WY","governmentAgency":"DOT"},
    ];

     export default STATES;

     /*
      {"id":9,"name":"District of Columbia","shortName":"DC","governmentAgency":"DMV"},
      */