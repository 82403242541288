const STATESAPPLinks = [
  {
    id: 1,
    name: "Alabama",
    ios: "https://apps.apple.com/us/app/alabama-dmv-practice-test-al/id6453518357",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.alabama.dmv",
  },
  {
    id: 2,
    name: "Alaska",
    ios: "https://apps.apple.com/us/app/alaska-dmv-practice-test-ak/id6456450243",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.alaska.dmv",
  },
  {
    id: 3,
    name: "Arizona",
    ios: "https://apps.apple.com/us/app/arizona-mvd-practice-test-az/id6456451254",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.arizona.dmv",
  },
  {
    id: 4,
    name: "Arkansas",
    ios: "https://apps.apple.com/us/app/arkansas-dmv-practice-test-ar/id6456451754",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.arkansas.dmv",
  },
  {
    id: 5,
    name: "California",
    ios: "https://apps.apple.com/us/app/california-dmv-test-prep-ca/id6458152904",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.california.dmv",
  },
  {
    id: 6,
    name: "Colorado",
    ios: "https://apps.apple.com/us/app/colorado-dmv-practice-test-co/id6458394897",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.colorado.dmv",
  },
  {
    id: 7,
    name: "Connecticut",
    ios: "https://apps.apple.com/us/app/connecticut-dmv-test-prep-ct/id6458395040",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.connecticut.dmv",
  },
  {
    id: 8,
    name: "Delaware",
    ios: "https://apps.apple.com/us/app/delaware-dmv-practice-test-de/id6459881026",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.delaware.dmv",
  },
  {
    id: 9,
    name: "District of Columbia",
    ios: "https://apps.apple.com/us/app/dc-dmv-practice-test/id6460819273",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.dc.dmv",
  },
  {
    id: 10,
    name: "Florida",
    ios: "https://apps.apple.com/us/app/florida-dhsmv-practice-test-fl/id6461347744",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.florida.dmv",
  },
  {
    id: 11,
    name: "Georgia",
    ios: "https://apps.apple.com/us/app/georgia-dds-practice-test-ga/id6461349592",
    android: "https://play.google.com/store/apps/details?id=com.jjinfoways.georgia.dmv",
  },
  {
    id: 12,
    name: "Hawaii",
    ios: "https://apps.apple.com/us/app/hawaii-dmv-practice-test-hi/id6461379004",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.hawaii.dmv",
  },
  {
    id: 13,
    name: "Idaho",
    ios: "https://apps.apple.com/us/app/idaho-dmv-practice-test-id/id6461379959",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.idaho.dmv",
  },
  {
    id: 14,
    name: "Illinois",
    ios: "https://apps.apple.com/us/app/illinois-dmv-practice-test-il/id6461419329",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.illinois.dmv",
  },
  {
    id: 15,
    name: "Indiana",
    ios: "https://apps.apple.com/us/app/indiana-bmv-practice-test-in/id6461419141",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.indiana.dmv",
  },
  {
    id: 16,
    name: "Iowa",
    ios: "https://apps.apple.com/us/app/iowa-dmv-practice-test-ia/id6461419545",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.iowa.dmv",
  },
  {
    id: 17,
    name: "Kansas",
    ios: "https://apps.apple.com/us/app/kansas-dmv-practice-test-ks/id6463868059",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.kansas.dmv",
  },
  {
    id: 18,
    name: "Kentucky",
    ios: "https://apps.apple.com/us/app/kentucky-dmv-practice-test-ky/id6463869233",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.kentucky.dmv",
  },
  {
    id: 19,
    name: "Louisiana",
    ios: "https://apps.apple.com/us/app/louisiana-omv-practice-test-la/id6464049790",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.louisiana.dmv",
  },
  {
    id: 20,
    name: "Maine",
    ios: "https://apps.apple.com/us/app/maine-bmv-practice-test-me/id6464050111",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.maine.dmv",
  },
  {
    id: 21,
    name: "Maryland",
    ios: "https://apps.apple.com/us/app/maryland-mva-practice-test-md/id6464111650",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.maryland.dmv",
  },
  {
    id: 22,
    name: "Massachusetts",
    ios: "https://apps.apple.com/us/app/ma-rmv-practice-test-prep/id6464112705",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.massachusetts.dmv",
  },
  {
    id: 23,
    name: "Michigan",
    ios: "https://apps.apple.com/us/app/michigan-sos-practice-test-mi/id6464265983",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.michigan.dmv",
  },
  {
    id: 24,
    name: "Minnesota",
    ios: "https://apps.apple.com/us/app/minnesota-dmv-practice-test-mn/id6464271844",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.minnesota.dmv",
  },
  {
    id: 25,
    name: "Mississippi",
    ios: "https://apps.apple.com/us/app/mississippi-dmv-practice-test/id6464309112",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.mississippi.dmv",
  },
  {
    id: 26,
    name: "Missouri",
    ios: "https://apps.apple.com/us/app/missouri-dor-practice-test-mo/id6464309773",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.missouri.dmv",
  },
  {
    id: 27,
    name: "Montana",
    ios: "https://apps.apple.com/us/app/montana-mvd-practice-test-mt/id6464360548",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.montana.dmv",
  },
  {
    id: 28,
    name: "Nebraska",
    ios: "https://apps.apple.com/us/app/nebraska-dmv-practice-test-ne/id6464362190",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.nebraska.dmv",
  },
  {
    id: 29,
    name: "Nevada",
    ios: "https://apps.apple.com/us/app/nevada-dmv-practice-test-nv/id6464372109",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.nevada.dmv",
  },
  {
    id: 30,
    name: "New Hampshire",
    ios: "https://apps.apple.com/us/app/nh-dmv-practice-test/id6464383282",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.newhampshire.dmv",
  },
  {
    id: 31,
    name: "New Jersey",
    ios: "https://apps.apple.com/us/app/nj-mvc-practice-test/id6464454700",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.nevjersey.dmv",
  },
  {
    id: 32,
    name: "New Mexico",
    ios: "https://apps.apple.com/us/app/nm-mvd-practice-test/id6464456821",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.newmexico.dmv",
  },
  {
    id: 33,
    name: "New York",
    ios: "https://apps.apple.com/us/app/new-york-dmv-practice-test-ny/id6465845032",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.newyork.dmv",
  },
  {
    id: 34,
    name: "North Carolina",
    ios: "https://apps.apple.com/us/app/nc-dmv-practice-test/id6466153728",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.northcarolina.dmv",
  },
  {
    id: 35,
    name: "North Dakota",
    ios: "https://apps.apple.com/us/app/nd-dot-practice-test/id6466167438",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.northdakota.dmv",
  },
  {
    id: 36,
    name: "Ohio",
    ios: "https://apps.apple.com/us/app/ohio-bmv-practice-test-oh/id6466249434",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.ohio.dmv",
  },
  {
    id: 37,
    name: "Oklahoma",
    ios: "https://apps.apple.com/us/app/oklahoma-dps-practice-test-ok/id6466252977",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.oklahoma.dmv",
  },
  {
    id: 38,
    name: "Oregon",
    ios: "https://apps.apple.com/us/app/oregon-dmv-practice-test-or/id6466277604",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.oregon.dmv",
  },
  {
    id: 39,
    name: "Pennsylvania",
    ios: "https://apps.apple.com/us/app/penndot-pa-dmv-practice-test/id6466403316",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.pennsylvania.dmv",
  },
  {
    id: 40,
    name: "Rhode Island",
    ios: "https://apps.apple.com/us/app/ri-dmv-practice-test/id6466408749",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.rhodeisland.dmv",
  },
  {
    id: 41,
    name: "South Carolina",
    ios: "https://apps.apple.com/us/app/sc-dmv-practice-test/id6466424869",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.southcarolina.dmv",
  },
  {
    id: 42,
    name: "South Dakota",
    ios: "https://apps.apple.com/us/app/sd-dmv-practice-test/id6466594677",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.southdakota.dmv",
  },
  {
    id: 43,
    name: "Tennessee",
    ios: "https://apps.apple.com/us/app/tennessee-dos-practice-test-tn/id6466598454",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.tennessee.dmv",
  },
  {
    id: 44,
    name: "Texas",
    ios: "https://apps.apple.com/us/app/texas-dmv-practice-test-tx/id6466629798",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.texas.dmv",
  },
  {
    id: 45,
    name: "Utah",
    ios: "https://apps.apple.com/us/app/utah-dmv-practice-test-ut/id6466640164",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.utah.dmv",
  },
  {
    id: 46,
    name: "Vermont",
    ios: "https://apps.apple.com/us/app/vermont-dmv-practice-test-vt/id6466713775",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.vermont.dmv",
  },
  {
    id: 47,
    name: "Virginia",
    ios: "https://apps.apple.com/us/app/virginia-dmv-practice-test-va/id6466733821",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.virginia.dmv",
  },
  {
    id: 48,
    name: "Washington",
    ios: "https://apps.apple.com/us/app/wa-dol-practice-test-wa-dmv/id6466779640",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.washington.dmv",
  },
  {
    id: 49,
    name: "West Virginia",
    ios: "https://apps.apple.com/us/app/arkansas-dmv-practice-test-ar/id6456451754",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.westvirginia.dmv",
  },
  {
    id: 50,
    name: "Wisconsin",
    ios: "https://apps.apple.com/us/app/wisconsin-dmv-practice-test-wi/id6466780022",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.wisconsin.dmv",
  },
  {
    id: 51,
    name: "Wyoming",
    ios: "https://apps.apple.com/us/app/wyoming-dot-practice-test-wy/id6466780671",
    android:
      "https://play.google.com/store/apps/details?id=com.jjinfoways.wyoming.dmv",
  },
];

export default STATESAPPLinks;
