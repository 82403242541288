import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Navigationbar from "./components/navigationBar/NavigationBar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage/MainPage";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";

import "./App.css";
import CommonHeader from "./components/Header";
import { privacyPolicyURL } from "../src/constants/Globals/Globals";

const App = () => {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click behavior
  };

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); // Empty dependency array ensures that the effect runs once when the component mounts

  return (
    <div className="disable-copy-element-text">
      <Navigationbar />
      {/* <CommonHeader /> */}
      <Routes>
        {/* <Route exact path="/" Component={Home} /> */}
        <Route index element={<Home page="state" />} />
        <Route path="/" element={<Home page="state" />} />
        {/* <Route path="/About" Component={About} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/contact" Component={()=><Contact page="contact" />} /> */}
        {/* <Route path="/MainPage" Component={MainPage} /> */}
        <Route path="/privacy" element={<PrivacyPolicy />} />
        {/* <Route path="/privacy" Component={() => <Home page="privacy"/>} /> */}
      </Routes>

      <Footer />
      {/*
<CookieConsent location="bottom" buttonText="Okay" declineButtonText="Decline" style={{ background:'black' , color: "#FFF" }} buttonStyle={{ color: "#000", backgroundColor:'#fff', fontSize: "14px" }} declineButtonStyle={{ fontSize: "14px" }} expires={150}>
       We use cookies to personalize content and ads, and to analyze our traffic. See our <a href="/privacy">privacy policy</a> for more.
      </CookieConsent>

      */}
    </div>
  );
};

export default App;
