import React from "react";

const CommonHeader = () => {
  return (
     <div className="container-fluid bg-info disable-copy-element-text" style={{width:'100%', textAlign: 'center' , marginTop:'0px', marginBottom:'0px'}}>
     {/* <div className="container-fluid pt-4" style={{backgroundColor:'#2B1560'}}> */}
            <div className="container text-center align-items-center">
              <div className="row">
                <h1
                  className="text-light fw-bold"
                  style={{display: 'inline', width:'100%', textAlign: 'center'}}
                >
                 Drivers Practice Test Study Guide
                </h1>
              </div>
              {/* <div className="row">
                <h3
                  className="text-light fw-bold"
                  style={{display: 'inline', width:'100%', textAlign: 'center'}}
                >
                 Road Test Practice
                </h3>
              </div> */}
            </div>
          </div>
  );
}

export default CommonHeader;