
const darkThemeBlackColor = '#1A1A1A';

export const colorsDarkTheme = {
  shadowColor: darkThemeBlackColor,
  primary: darkThemeBlackColor,
  background: darkThemeBlackColor,
  success: '#28a745',
  fail: '#dc3545',
  lightFail: '#E55451',
  lightSuccess: '#90EE90',
  itemBgColor: '#ecf2ff',
  itemSelectionBgColor: '#C5E5F7',
  defaultHeaderBgColor: '#4679fa',
  black: '#fff',
  white: '#fff',
  option1: darkThemeBlackColor,
  optionCorrect: '#21BA45',
  optionWrong: '#DA2828',
  optionTextColor: '#fff',
  selectedOptionTextColor: '#fff',
  grey: '#fff',
  listItemBackground: '#2c2c2c',
  cardOuterColor: '#2c2c2c',
  cardGradiantColor1: '#323237',
  cardGradiantColor2: '#2f2f31',
  cardGradiantRoundColor: darkThemeBlackColor,
  lineColor: '#D6D6D6',
  background1: '#EFEDF3',
  optionColorCode1: '#FE7D13',
  optionColorCode2: '#0C69F6',
  optionColorCode3: '#0E5EEB',
  optionColorCode4: '#ED0C41',
  optionColorCode5: '#2BC646',
  optionColorCode6: '#2BC646',
  optionColorCode7: '#FA2228',
  optionColorCode8: '#4038D4',
};

export const colorsDefaults = {
  shadowColor: '#DCDCDC',
  primary: '#2B1560', //rgba(43, 21, 96, 1)
  background: '#FFF',
  success: '#28a745',
  fail: '#dc3545',
  lightFail: '#E55451',
  lightSuccess: '#90EE90',
  itemBgColor: '#ecf2ff',
  itemSelectionBgColor: '#C5E5F7',
  defaultHeaderBgColor: '#4679fa',
  black: '#000',
  white: '#fff',
  option1: '#fff',
  optionCorrect: '#21BA45',
  optionWrong: '#DA2828',
  optionTextColor: '#000',
  selectedOptionTextColor: '#fff',
  grey: 'grey',
  listItemBackground: '#2c2c2c',
  cardOuterColor: '#F2F2F2',
  cardGradiantColor1: '#FFFFFF',
  cardGradiantColor2: '#F2F2F2',
  cardGradiantRoundColor: '#2B1560',
  lineColor: '#D6D6D6',
  background1: '#EFEDF3',
  optionColorCode1: '#FE7D13',
  optionColorCode2: '#0C69F6',
  optionColorCode3: '#0E5EEB',
  optionColorCode4: '#ED0C41',
  optionColorCode5: '#2BC646',
  optionColorCode6: '#2BC646',
  optionColorCode7: '#FA2228',
  optionColorCode8: '#4038D4',
};

export const AppUrlIOS =
  'https://apps.apple.com/us/developer/jignasha-joshi/id1699384056';

export const AppUrlAndroid =
'https://play.google.com/store/apps/developer?id=J+Joshi';

export const privacyPolicyURL = 'https://jjinfoways.blogspot.com/p/privacy-policy.html';