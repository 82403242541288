
import React, {useEffect, useState} from 'react';
import { Button } from "react-bootstrap";
import Lottie from "lottie-react";
import ReviewAnswers from './ReviewAnswers';

const PracticeScore = props => {
  // const {test, questions, answers, corrects, wrongs, testCategory} =
  // props.completedTestData;
  const {test, questions, corrects} =
  props.completedTestData;
  const [isLoading, setisLoading] = useState(true);
  const [isShowReviewTest, setIsShowReviewTest] = useState(false);

  const ifPass = () => {
    //if ((wrongs > test.allowedMistakes) {
    if (questions.length - corrects > test.allowedMistakes) {
      return false;
    } else {
      return true;
    }
  };

  const reviewTest = () => {
    setIsShowReviewTest(true)
  };

  const closeReviewTest = () => {
    setIsShowReviewTest(false)
  };

  const onClickRestartTest = () => {
    props.restartTest();
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  })

  if (isLoading) {
    return (
      <div className='disable-copy-element-text'
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    );
  }

  return (
    <>
      {isShowReviewTest ? <ReviewAnswers onClickDone= {closeReviewTest} completedTestData={props.completedTestData} /> : 
      <div
      style={{
        flex: 1,
        
      }}>
     <div>
      <h3 className='text-center'>Test Outcome</h3>
      <div style={{flexDirection: 'column', padding: '15px'}}>
      
        <div
          style={{
            // justifyContent: 'center',
            // alignItems: 'center',
            marginHorizontal: '10px',
          }}>
          
          {ifPass() ? (
            <div
              style={{
                height: '200px',
                alignItems: 'center',
                alignContent: 'center',
              }}>
              {/* <Lottie
                style={{height: '100%'}}
                source={require('../../lottie/amazing.json')}
                autoPlay
              /> */}
              <Lottie style={{height: '100%', width:'100%'}} animationData={require('../../lottie/amazing.json')} loop={true} />
            </div>
          ) : (
            <div
              style={{
                height: '200px',
                alignItems: 'center',
                alignContent: 'center',
              }}>
              {/* <Lottie
                style={{height: '100%'}}
                source={require('../../lottie/nevergiveup.json')}
                autoPlay
              /> */}
              <Lottie style={{height: '100%', width:'100%'}} animationData={require('../../lottie/nevergiveup.json')} loop={true} />
            </div>
          )}
          <h4
            style={{
              fontWeight: 'bold',
              fontSize: '25px',
              textAlign: 'center',
              marginBottom: '7px',
              color: ifPass() ? 'green' : 'red',
            }}>
            {ifPass() ? 'Amazing!' : 'Never give up!'}
          </h4>
          <div style={{height: '10px'}}></div>
          <h4
            style={{
              fontSize: '16px',
              textAlign: 'center',
              color: 'black',
              marginBottom: '10px',
              // fontWeight: 'bold',
            }}>
            {ifPass()
              ? 'Congratulations! You answered correctly ' + corrects + ' out of a total of ' + questions.length + ' questions in the test.'
              : 'The test had ' + questions.length + ' questions, and ' + corrects + ' of them were correct. Only ' + test.allowedMistakes + ' mistakes are permitted in the test.'}
          </h4>
          <h4
            style={{
              fontWeight: 'bold',
              fontSize: '25px',
              textAlign: 'center',
              marginBottom: '7px',
              color: 'black',
            }}>
            {`Percentage : ${(
              (corrects / questions.length) *
              100
            ).toFixed()} %`}
          </h4>
          <div style={{height: '20px'}}></div>
        </div>
        {/* <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: '10px',
            marginBottom: '30px',
          }}>
          <button
            onPress={() => reviewTest()}
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: '10px',
              backgroundColor: 'white',
              borderWidth: 2,
            }}>
            <h4
              style={{
                fontSize: '17px',
                color: 'black',
                fontWeight: 'bold',
              }}>
              Review Test
            </h4>
          </button>
          <div style={{width: '15px'}}></div>
          <button
            onPress={() => {
             
             // navigation.navigate('Home');
            }}
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: '10px',
              backgroundColor: 'white',
              borderWidth: 2,
            }}>
            <h4
              style={{
                fontSize: '17px',
                color: 'black',
                fontWeight: 'bold',
              }}>
              Go to home
            </h4>
          </button>
        </div> */}
        <div
                className="d-flex justify-content-around"
                style={{
                  marginTop: "10px",
                  marginBottom: "15px",
                  width: "100%",
                  marginLeft: "0%",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className=" btn-lg btn-primary"
                  style={{ width: "150px" }}
                  onClick={() => reviewTest()}
                >
                  Review Test
                </Button>
                {/* <Button
                  className="previous-next-question btn-lg"
                  style={{ width: "150px" }}
                  onClick={() => onClickGoToHome(true)}
                >
                  Go to home
                </Button> */}
                
                <Button
                  className=" btn-lg btn-primary"
                  style={{ width: "150px" }}
                  onClick={() => onClickRestartTest()}
                >
                 Restart Test
                </Button>
              </div>
      </div>
      </div>
      </div>
      }
    </>
  );

};

export default PracticeScore;

