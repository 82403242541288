import React from "react";
import STATES from "../../json-data/state";
import { isMobile, MobileView, BrowserView } from "react-device-detect";
import MainPage from "./MainPage";
import "./MainPage.css"; // Create a CSS file for styling
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import { images } from "../../constants/images";
import {
  AppUrlIOS,
  AppUrlAndroid,
  colorsDefaults,
} from "../../constants/Globals/Globals";
import QRCode from "react-qr-code";

function StatePage(props) {
  const onClickState = ({ state }) => {
    if (!isMobile) {
      props.onSelectState({ currentPage: "category", state });
    } else {
      props.onSelectState({ currentPage: "downloadapp", state });
    }
  };

  return (
    <>
      <div className="text-center align-items-center disable-copy-element-text">
        <h1>DMV Practice Test</h1>
      </div>
      <BrowserView>
      <div className="text-center align-items-center disable-copy-element-text">
        <h3 className="font-weight-light">
          To practice the DMV Written Test Select Your State
        </h3>
      </div>
      </BrowserView>
      <BrowserView>
        <div className="main-page">
          {STATES.map((state) => (
            <Fade top>
              <button
                key={state.id}
                onClick={() => onClickState({ state })}
                className="container"
              >
                <div className="d-flex text-center align-items-center">
                  <img
                    src={images[`${state.id}.png`]}
                    className="rounded-circle"
                    alt=""
                    width={"25px"}
                    height={"25px"}
                    style={{ backgroundColor: "white" }}
                  ></img>
                  <div style={{ marginLeft: "10px" }}>{state.name}</div>
                </div>
              </button>
            </Fade>
          ))}
        </div>
      </BrowserView>
      <MobileView>
        <div className="text-center align-items-center">
          <h2 className="font-weight-light">Select Your State</h2>
        </div>
        <div className="main-page-mobile">
          {STATES.map((state) => (
            <button key={state.id} onClick={() => onClickState({ state })}>
              {state.name}
            </button>
          ))}
        </div>
      </MobileView>
      <div
        className="text-center align-items-center disable-copy-element-text my-2"
        style={{
          backgroundColor: "#ACDDDE",
          paddingLeft: isMobile ? "5x" : "20%",
          paddingRight: isMobile ? "5x" : "20%",
        }}
      >
        <br />
        <div
        className="text-center align-items-center disable-copy-element-text mx-2">
        <h4>
          The DMV Practice Test may assist you in passing your official DMV
          Permit Test. Perform a practice exam. Review the test. Learn, study,
          give practice tests, and examine your results to see how far you have
          come. 
        </h4>
        </div>
        <br />
      </div>

      <div
        className="text-center align-items-center disable-copy-element-text my-2"
        style={{
          backgroundColor: "#E4E4E4",
          paddingLeft: isMobile ? "ox" : "20%",
          paddingRight: isMobile ? "5x" : "20%",
        }}
      >
        <br />
        <h2>Why Practice with US? </h2>
        <div className="alignCenterDiv py-4" style={{}}>
       

        <div class="container">
          <div class="row">
            <div class="col-sm">
              <div
                style={{
                  justifyContent: "center",
                  width: isMobile ? '100%' : "11.7rem",
                }}
              >
                <div>
                  <h3>Simulator Test</h3>
                  <img
                    width={185}
                    height={145}
                    src={images[`study.png`]}
                    //src={images[`car.png`]}
                    alt=""
                    style={{ padding: "15px" }}
                  />{" "}
                  <h6>Test Simulator for Real Time DMV Test experience</h6>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div
                style={{
                  justifyContent: "center",
                  width: isMobile ? '100%' : "11.7rem",
                }}
              >
                <div>
                  <h3>Practice Test</h3>
                  <img
                    width={185}
                    height={145}
                    src={images[`practiceIcon.png`]}
                    //src={images[`car.png`]}
                    alt=""
                    style={{ padding: "15px" }}
                  />{" "}
                  <h6>Various Practice Test to prepare for DMV Written Exam</h6>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div
                style={{
                  justifyContent: "center",
                  width: isMobile ? '100%' : "11.7rem",
                }}
              >
                <div>
                  <h3>Success</h3>
                  <img
                    width={185}
                    height={145}
                    src={images[`smile.png`]}
                    //src={images[`car.png`]}
                    alt=""
                    style={{ padding: "15px" }}
                  />{" "}
                  <h6>
                    Gain the knowledge, apply it to get your Drivers licence
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>        
        <br />
      </div>

      <div className="alignCenterDiv py-4" style={{}}>
        <BrowserView>
          <div className="row">
            <div>
              <h3>DMV Practice Test App provide features</h3>
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <ul>
                      <li>
                        <h6>Extensive Question Bank</h6>
                      </li>
                      <li>
                        <h6>Flexibility During Tests</h6>
                      </li>
                      <li>
                        <h6>Study Guide and Practice tests</h6>
                      </li>
                      <li>
                        <h6>Road Sign Recognition</h6>
                      </li>
                      <li>
                        <h6>Bookmark questions</h6>
                      </li>
                      <li>
                        <h6>Resume and Restart the test</h6>
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm">
                    <ul>
                      <li>
                        <h6>Progress Tracking</h6>
                      </li>
                      <li>
                        <h6>List of Weak/Wrong Questions for Improvement</h6>
                      </li>
                      <li>
                        <h6>Review Previous tests</h6>
                      </li>
                      <li>
                        <h6>Reset All Data</h6>
                      </li>
                      <li>
                        <h6>Appearance Settings (Auto,Light,Dark)</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div
                className="card text-center align-items-center"
                style={{ width: "18rem" }}
              >
                <div className="card-body">
                  <h5 className="card-title">IOS APPs</h5>
                  <h5 className="card-title">Scan QR Code</h5>
                  <div
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 100,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      title={"IOS"}
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={AppUrlIOS}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div
                className="card text-center align-items-center"
                style={{ width: "18rem" }}
              >
                <div className="card-body">
                  <h5 className="card-title">ANDROID APPs</h5>
                  <h5 className="card-title">Scan QR Code</h5>
                  <div
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 100,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      title={"Android"}
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={AppUrlAndroid}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BrowserView>
      </div>
    </>
  );
}

export default StatePage;
