import React, { useEffect, useState } from "react";
import TESTS from "../../json-data/tests";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

function TestList({ onSelectTest, stateId, vehicleCategory, testType }) {
  const [testsList, setTestsList] = useState([]);
  const onCickTest = ({ test }) => {
    // onSelectTest({ currentPage: "practicetest", test });
    //console.log(`testLIST-- ${JSON.stringify(test)}`)
    onSelectTest({test});
  };
     const getCategoryAllTests = async (stateId, testType) => {
      try {
        let testsList;
        switch (testType.vehicleCategoryId) {
          case 1:
            // testsList = await getCategoryTestList(
            //   stateId,
            //   testType.categoryType,
            // );
            testsList = TESTS.filter((item) => {
              return item.stateId === stateId && item.type == testType.categoryType;
            });
            break;
          case 2:
            // testsList = await getCategoryTestList(
            //   stateId,
            //   testCategory.categoryType,
            // );
            testsList = TESTS.filter((item) => {
              return item.stateId === stateId && item.type == testType.categoryType;
            });

            break;
          case 3:
            // testsList = await getCDLCategoryTestList(
            //   stateId,
            //   testType.endorsementCode,
            // );
            testsList = TESTS.filter((item) => {
               return item.stateId === stateId && item.endorsementCode == testType.endorsementCode;
             });
            break;
          default:
            break;
        }
        setTestsList(testsList);
      } catch (error) {
        console.log(error);
      }
    };

  const getCategoryMockTests = async (stateId, testType) => {
    try {
      // let testsList = await getCategoryMockTestList(
      //   state_id,
      //   testCategory.categoryType,
      // );
      let testsList = TESTS.filter((item) => {
        return item.stateId === stateId && item.type == testType.categoryType;
      });
      setTestsList(testsList);
    } catch (error) {
      console.log(error);
    }
  };

  const initialize = async () => {
    if (testType.id === 1001 || testType.id === 2001) {
      await getCategoryMockTests(stateId, testType);
    } else {
      // await getCategoryAllTests(
      //   state.id,
      //   props.route.params.testCategory,
      // );
      await getCategoryAllTests(stateId, testType);
    }
  };

  useEffect(() => {
    initialize();
  }, []);
  // return (
  //   <div>
  //     <h1>Test List</h1>
  //     <div className="common-list">
  //       {testsList.map((test) => {
  //         return (
  //           <button
  //             key={test.testId}
  //             onClick={() => onCickTest({ test })}
  //             className="common-list-item"
  //           >
  //             {test.title}
  //           </button>
  //         );
  //       })}
  //     </div>
  //   </div>
  // );
  return (
    <div className="disable-copy-element-text" style={{marginLeft:'0px'}}>
    <div className=" text-center align-items-center"> 
          <h2>Test List</h2></div>
      <h3>Select Test </h3>
      <div className="scrolling-testlist-flexbox justify-content-center"  style={{marginLeft:'40px'}}>
      {/* <div className="d-flex scrolling-testlist-flexbox" > */}
        {testsList.map((test) => {
          return (
            <Fade top>
            <button
              key={test.testId}
              onClick={() => onCickTest({ test })}
              className="card"
            >
            <div className="align-middle" style={{marginTop:'5px',marginBottom:'5px',justifyContent:'center',width: '15rem'}}>
            {/* <div className="d-flex row" style={{marginTop:'5px',marginBottom:'5px',justifyContent:'center', width:'100%'}}> */}
              <div style={{justifyContent:'center', height:'4rem',}}>
              <h4 style={{marginTop:'5px', fontWeight: 'bold'}}>{test.title}</h4>
              </div>
              <div style={{ marginTop:'3px'}}>

              <h5 style={{color:'black', marginTop:'3px',}}>Total Questions: <span style={{color:'#21BA45'}}>{test.numberOfQuestions}</span></h5>
              <h5 style={{color:'black', marginTop:'3px'}}> Mistakes Allowed: <span style={{color:'#DA2828'}}>{test.allowedMistakes}</span></h5>
             
              {/* <h6 style={{color:'darkgray', marginTop:'5px'}}>Total Questions: </h6> <h3 style={{color:'darkgray'}}>{test.numberOfQuestions}</h3>
              <h6 style={{color:'darkgray', marginTop:'5px'}}> Mistakes Allowed:</h6> <h3 style={{color:'darkgray'}}> {test.allowedMistakes}</h3> */}
              {/* <h6 style={{color:'black', marginTop:'5px'}}>Total Questions: {test.numberOfQuestions}</h6>
              <h6 style={{color:'black', marginTop:'5px'}}> Mistakes Allowed: {test.allowedMistakes}</h6> */}
              </div>
              
              {/* <div className="justify-content-center">
              <table>
                <th style={{textAlign: 'center'}}><h3 style={{marginTop:'10px'}}>{test.title}</h3></th>
                <tr><h6 style={{color:'darkgray', marginTop:'10px'}}>Total Questions:</h6></tr>
                <tr><h3 style={{color:'darkgray'}}>{test.numberOfQuestions}</h3></tr>
                <tr><h6 style={{color:'darkgray', marginTop:'10px'}}> Mistakes Allowed:</h6></tr>
                <tr><h3 style={{color:'darkgray'}}> {test.allowedMistakes}</h3></tr>
              </table>
              </div> */}
               
               
              </div>
             
            </button>
            </Fade>
          );
        })}
      </div>
    </div>
  );
}

export default TestList;
