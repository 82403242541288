import React, { useState } from "react";
import Lottie from "lottie-react";
import { isMobile } from "react-device-detect";

const Contact = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [subject, setSubject] = useState("");
  // const [message, setMessage] = useState("");
  // const [responseData, setResponseData] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSendSucessfully, setSendSucessfully] = useState(false);
  const [isSendError, setSendError] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    subject: "",
    message: "",
  });
  const accessTokenMail = process.env.REACT_APP_ACCESSTOKEN_SEND_MAIL;
  const sendMessageUrl =
    process.env.REACT_APP_WEB_BASE_URL +
    "/" +
    process.env.REACT_APP_SEND_MESSAGE_FILENAME;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSending(true);
      const response = await fetch(sendMessageUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenMail}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      setTimeout(() => {
        setIsSending(false);
        setSendSucessfully(true);
        setFormData({
          userName: "",
          userEmail: "",
          subject: "",
          message: "",
        });
        setTimeout(() => {
          setSendSucessfully(false);
        }, 3000);
      }, 1000);
      console.log("Server response:", result);
    } catch (error) {
      setIsSending(false);
      setSendSucessfully(false);
      setSendError(true);
      setTimeout(() => {
        setSendError(false);
      }, 3000);
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact disable-copy-element-text">
    <div className="container-fluid bg-info disable-copy-element-text" style={{width:'100%', textAlign: 'center' , marginTop:'0px', marginBottom:'10px'}}>
     {/* <div className="container-fluid pt-4" style={{backgroundColor:'#2B1560'}}> */}
            <div className="container text-center align-items-center">
              <div className="row">
                <h1
                  className="text-light fw-bold"
                  style={{display: 'inline', width:'100%', textAlign: 'center'}}
                >
                 Send us your comments
                </h1>
              </div>
              {/* <div className="row">
                <h3
                  className="text-light fw-bold"
                  style={{display: 'inline', width:'100%', textAlign: 'center'}}
                >
                 Road Test Practice
                </h3>
              </div> */}
            </div>
          </div>
      <section className="contact-form section pt-0">
        <div className="container" style={{ width: isMobile ? "85%" : "60%" }}>
          <div className="row">
            {/* <div className="col-12">
              <h1 className="mb-3 text-center">Send us your comments </h1>
            </div> */}
            {isSendSucessfully ? (
              <>
                <div
                  style={{
                    height: "200px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Lottie
                    style={{ height: "100%", width: "100%" }}
                    animationData={require("../lottie/amazing.json")}
                    loop={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h4>Thank you for your valuable feedback</h4>
                </div>
              </>
            ) : null}
            
                {!isSendSucessfully ? 
            <div className="col-12">
                <form onSubmit={handleSubmit}>
                  {/* <form method="post" action="testMail.php"> */}
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <input
                        className="form-control main"
                        type="text"
                        name="userName"
                        placeholder="Name"
                        value={formData.userName}
                        onChange={handleChange}
                        required
                        disabled={isSending}
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please enter name")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                      />
                    </div>
                    <div className="col-md-6 mb-2">
                      <input
                        className="form-control main"
                        type="email"
                        name="userEmail"
                        placeholder="Your Email Address"
                        value={formData.userEmail}
                        onChange={handleChange}
                        required
                        disabled={isSending}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Please enter valid email address"
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                      />
                    </div>
                    {/* <div className="col-md-4 mb-2">
                          <input
                            className="form-control main"
                            type="mobile"
                            name="mobile"
                            placeholder="Your Mobile Number"
                          />
                        </div> */}
                    <div className="col-md-12 mb-2">
                      <input
                        className="form-control main"
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                        disabled={isSending}
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please enter subject")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                      />
                    </div>
                    <div className="col-md-12 mb-2">
                      <textarea
                        className="form-control main"
                        name="message"
                        rows="10"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        disabled={isSending}
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Please enter message")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                      ></textarea>
                    </div>

                    <div className="col-12 text-center mb-2">
                      {/* <button className="btn btn-primary">Submit</button> */}
                      <button
                        className="btn btn-primary send-button"
                        id="submit"
                        type="submit"
                        disabled={isSending}
                      >
                        {isSending ? (
                          <div
                            className="col-12 text-center "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div> */}
                            <div className="spinner-border " role="status">
                              <span className="visually-hidden">
                                Sending...
                              </span>
                            </div>
                            <span style={{ marginLeft: "5px" }}>
                              {" "}
                              Sending...{" "}
                            </span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div> 
              : null }

              {isSendError ? <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h4 style={{color:'red'}}>Error in sendig message. Please send it again.</h4>
                </div> : null}
          </div>
        </div>
      </section>
    </div>

    // </div>
    // <div className="column" style={{ width: "20%" }}></div>
    // </div>
  );
};

export default Contact;
